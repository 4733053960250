import type {MachineUrl, PriceItemUrl, ProductUrl} from "@co-common-libs/resources";

export function checkMachineUseFuelSurcharge<
  Specification extends Record<string, unknown>,
  SpecificationEntry extends Record<string, unknown>,
  Use extends Record<string, unknown>,
>(
  matches: ReadonlyMap<
    MachineUrl,
    {
      readonly fuelConsumptionLiterPerHour: number | null;
      readonly specification: Specification | null;
      readonly specificationEntry: SpecificationEntry | null;
      readonly use: Use;
    }
  >,
): {
  issues: Map<
    MachineUrl,
    {
      fuelConsumptionLiterPerHour: number | null;
      specification: Specification;
      specificationEntry: SpecificationEntry | null;
      use: Use;
    }
  >;
  withFuelSurcharge: Map<
    MachineUrl,
    {
      fuelConsumptionLiterPerHour: number;
      specification: Specification;
      specificationEntry: SpecificationEntry;
      use: Use;
    }
  >;
  withoutFuelSurcharge: Map<
    MachineUrl,
    {
      use: Use;
    }
  >;
} {
  const issues = new Map<
    MachineUrl,
    {
      fuelConsumptionLiterPerHour: number | null;
      specification: Specification;
      specificationEntry: SpecificationEntry | null;
      use: Use;
    }
  >();
  const withFuelSurcharge = new Map<
    MachineUrl,
    {
      fuelConsumptionLiterPerHour: number;
      specification: Specification;
      specificationEntry: SpecificationEntry;
      use: Use;
    }
  >();
  const withoutFuelSurcharge = new Map<MachineUrl, {use: Use}>();
  for (const [machineUrl, match] of matches) {
    const {fuelConsumptionLiterPerHour, specification, specificationEntry, use} = match;
    if (!specification) {
      withoutFuelSurcharge.set(machineUrl, {use});
    } else if (specificationEntry && fuelConsumptionLiterPerHour) {
      withFuelSurcharge.set(machineUrl, {
        fuelConsumptionLiterPerHour,
        specification,
        specificationEntry,
        use,
      });
    } else {
      issues.set(machineUrl, {
        fuelConsumptionLiterPerHour,
        specification,
        specificationEntry,
        use,
      });
    }
  }
  return {issues, withFuelSurcharge, withoutFuelSurcharge};
}

export function checkPriceItemProductUseFuelSurcharge<
  Specification extends Record<string, unknown>,
  SpecificationEntry extends Record<string, unknown>,
  Use extends Record<string, unknown>,
>(
  matches: ReadonlyMap<
    PriceItemUrl | ProductUrl,
    {
      readonly specification: Specification | null;
      readonly specificationEntry: SpecificationEntry | null;
      readonly use: Use;
    }
  >,
): {
  issues: Map<
    PriceItemUrl | ProductUrl,
    {
      specification: Specification;
      use: Use;
    }
  >;
  withFuelSurcharge: Map<
    PriceItemUrl | ProductUrl,
    {
      specification: Specification;
      specificationEntry: SpecificationEntry;
      use: Use;
    }
  >;
  withoutFuelSurcharge: Map<
    PriceItemUrl | ProductUrl,
    {
      use: Use;
    }
  >;
} {
  const issues = new Map<
    PriceItemUrl | ProductUrl,
    {
      specification: Specification;
      use: Use;
    }
  >();
  const withFuelSurcharge = new Map<
    PriceItemUrl | ProductUrl,
    {
      specification: Specification;
      specificationEntry: SpecificationEntry;
      use: Use;
    }
  >();
  const withoutFuelSurcharge = new Map<PriceItemUrl | ProductUrl, {use: Use}>();
  for (const [url, match] of matches) {
    const {specification, specificationEntry, use} = match;
    if (!specification) {
      withoutFuelSurcharge.set(url, {use});
    } else if (specificationEntry) {
      withFuelSurcharge.set(url, {
        specification,
        specificationEntry,
        use,
      });
    } else {
      issues.set(url, {
        specification,
        use,
      });
    }
  }
  return {issues, withFuelSurcharge, withoutFuelSurcharge};
}
