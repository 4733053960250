import {Patch, Task} from "@co-common-libs/resources";
import {actions, getCustomerSettings, getMachineArray} from "@co-frontend-libs/redux";
import React, {useCallback, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {PriceItemTable} from "./price-item-table";

interface TaskPriceItemTableProps {
  readonly?: boolean;
  readonlyPriceItems?: ReadonlySet<string> | undefined;
  task: Task;
}

export const TaskPriceItemTable = React.memo(function TaskPriceItemTable(
  props: TaskPriceItemTableProps,
): JSX.Element {
  const {readonly, readonlyPriceItems, task} = props;

  const machineArray = useSelector(getMachineArray);
  const customerSettings = useSelector(getCustomerSettings);
  const dispatch = useDispatch();

  const {priceItemUses, url} = task;

  const handleCountChange = useCallback(
    (identifier: string, value: number | null): void => {
      const patch: Patch<Task> = [{path: ["priceItemUses", identifier, "count"], value}];
      dispatch(actions.update(url, patch));
    },
    [dispatch, url],
  );

  const handleNotesChange = useCallback(
    (identifier: string, value: string): void => {
      const patch: Patch<Task> = [{path: ["priceItemUses", identifier, "notes"], value}];
      dispatch(actions.update(url, patch));
    },
    [dispatch, url],
  );

  const activeSmallMachineUrls = useMemo(
    () =>
      new Set(
        machineArray
          .filter((machine) => machine.active && machine.smallMachine)
          .map((machine) => machine.url),
      ),
    [machineArray],
  );

  const smallMachineEntryIdentifiers = useMemo(() => {
    if (!activeSmallMachineUrls.size) {
      return undefined;
    }
    const smallMachineEntries = Object.entries(priceItemUses || {}).filter(
      ([_identifier, priceItemUse]) =>
        priceItemUse.machine && activeSmallMachineUrls.has(priceItemUse.machine),
    );
    if (smallMachineEntries.length) {
      return new Set(smallMachineEntries.map(([identifier, _priceItemUse]) => identifier));
    } else {
      return undefined;
    }
  }, [activeSmallMachineUrls, priceItemUses]);

  return (
    <PriceItemTable
      hiddenEntryIdentifiers={smallMachineEntryIdentifiers}
      priceItemUses={priceItemUses || {}}
      readonly={readonly}
      readonlyPriceItems={readonlyPriceItems}
      showNotes={customerSettings.enableMaterialNoteFields}
      onCountChange={handleCountChange}
      onNotesChange={handleNotesChange}
    />
  );
});
