import {SettingID} from "@co-common-libs/config";
import {DeleteDialog, IntegerField, ResponsiveDialog} from "@co-frontend-libs/components";
import {
  actions,
  getCurrentUserURL,
  getSettingsEntryLookupByIdentifier,
} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {
  Button,
  DialogContent,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import _ from "lodash";
import DeleteIcon from "mdi-react/DeleteIcon";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

function EnterRangesRow({
  from,
  index,
  onFromValueChange,
  onRequestDelete,
  onToValueChange,
  to,
}: {
  from: number | null;
  index: number;
  onFromValueChange: (index: number, value: number | null) => void;
  onRequestDelete: (index: number) => void;
  onToValueChange: (index: number, value: number | null) => void;
  to: number | null;
}): JSX.Element {
  const handleFromValueChange = useCallback(
    (newValue: number | null) => {
      onFromValueChange(index, newValue);
    },
    [index, onFromValueChange],
  );

  const handleToValueChange = useCallback(
    (newValue: number | null) => {
      onToValueChange(index, newValue);
    },
    [index, onToValueChange],
  );

  const handleDeleteClick = useCallback(() => {
    onRequestDelete(index);
  }, [index, onRequestDelete]);

  return (
    <TableRow>
      <TableCell>
        <IntegerField
          fullWidth
          errorText={
            from != null && to != null && from > to ? (
              <FormattedMessage
                defaultMessage="Fra værdien må ikke være større end til værdien"
                id="number-range-dialog.error"
              />
            ) : undefined
          }
          margin="dense"
          value={from}
          onChange={handleFromValueChange}
        />
      </TableCell>
      <TableCell>
        <IntegerField fullWidth margin="dense" value={to} onChange={handleToValueChange} />
      </TableCell>
      <TableCell>
        <IconButton onClick={handleDeleteClick}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

function AddRangesDialog({
  onCancel,
  onOk,
  open,
}: {
  onCancel: () => void;
  onOk: (from: number, to: number) => void;
  open: boolean;
}): JSX.Element {
  const [from, setFrom] = useState<number | null>(null);

  const [to, setTo] = useState<number | null>(null);

  const handleOk = useCallback(() => {
    if (from != null && to != null) {
      onOk(from, to);
    }
  }, [onOk, from, to]);
  return (
    <ResponsiveDialog
      okDisabled={from == null || to == null || from > to}
      open={open}
      title={<FormattedMessage defaultMessage="Tilføj ny" id="add-ranges-dialog.title" />}
      onCancel={onCancel}
      onOk={handleOk}
    >
      <DialogContent>
        <IntegerField
          fullWidth
          errorText={
            from != null && to != null && from > to ? (
              <FormattedMessage
                defaultMessage="Fra værdien må ikke være større end til værdien"
                id="number-range-dialog.error"
              />
            ) : undefined
          }
          label={<FormattedMessage defaultMessage="Fra" id="add-ranges-dialog.from" />}
          margin="dense"
          value={from}
          onChange={setFrom}
        />

        <IntegerField
          fullWidth
          label={<FormattedMessage defaultMessage="Til" id="add-ranges-dialog.to" />}
          margin="dense"
          value={to}
          onChange={setTo}
        />
      </DialogContent>
    </ResponsiveDialog>
  );
}

interface NumberRangeDialogProps {
  onClose: () => void;
  open: boolean;
  settingID: SettingID;
}

export function NumberRangeDialog(props: NumberRangeDialogProps): JSX.Element {
  const {onClose, open, settingID} = props;

  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const settingEntry = settingsEntryLookupByIdentifier(settingID);
  const ranges: readonly (readonly [number, number])[] | null = useMemo(
    () => settingEntry?.data || null,
    [settingEntry?.data],
  );
  const [values, setValues] = useState<readonly (readonly [number | null, number | null])[] | null>(
    _.orderBy(ranges, (range) => range[0]),
  );

  const [radioValue, setRadioValue] = useState<"all" | "none" | "ranges">(
    ranges === null ? "all" : ranges.length ? "ranges" : "none",
  );

  useEffect(() => {
    if (open) {
      setValues(_.orderBy(ranges, (range) => range[0]));
      setRadioValue(ranges === null ? "all" : ranges.length ? "ranges" : "none");
    }
  }, [open, ranges]);

  const dispatch = useDispatch();
  const currentUserURL = useSelector(getCurrentUserURL);
  const handleOk = useCallback(() => {
    let newValues = values;
    if (radioValue === "none") {
      newValues = [];
    } else if (radioValue === "all") {
      newValues = null;
    }

    if (settingEntry) {
      dispatch(
        actions.update(settingEntry.url, [
          {member: "changedBy", value: currentUserURL},
          {member: "data", value: newValues},
        ]),
      );
    }
    onClose();
  }, [currentUserURL, dispatch, onClose, radioValue, settingEntry, values]);

  const handleFromValueChange = useCallback(
    (index: number, value: number | null) => {
      const newValues = [...(values || [])];
      const [, to] = newValues[index];
      newValues[index] = [value, to];
      setValues(newValues);
    },
    [values],
  );

  const handleToValueChange = useCallback(
    (index: number, value: number | null) => {
      const newValues = [...(values || [])];
      const [from] = newValues[index];
      newValues[index] = [from, value];
      setValues(newValues);
    },
    [values],
  );

  const [newEnterRangesDialogOpen, setNewEnterRangesDialogOpen] = useState(false);
  const setNewEnterRangesDialogOpenTrue = useCallWithTrue(setNewEnterRangesDialogOpen);
  const setNewEnterRangesDialogOpenFalse = useCallWithFalse(setNewEnterRangesDialogOpen);

  const diableOk = !!(
    radioValue === "ranges" &&
    (!values?.length || values.some(([from, to]) => from == null || to == null || from > to))
  );

  const [deleteDialogOpenFor, setDeleteDialogOpenFor] = useState<number | null>(null);

  const handleDeleteClick = useCallback((index: number) => {
    setDeleteDialogOpenFor(index);
  }, []);

  const handleDeleteDialogOk = useCallback(() => {
    setDeleteDialogOpenFor(null);
    if (deleteDialogOpenFor === null) {
      return;
    }
    const newValues = [...(values || [])];
    newValues.splice(deleteDialogOpenFor, 1);
    setValues(newValues);
  }, [deleteDialogOpenFor, values]);

  const handleDeleteDialogCancel = useCallback(() => {
    setDeleteDialogOpenFor(null);
  }, []);

  const deleteDialogOpen = deleteDialogOpenFor !== null;

  const handleNewEnterRangesDialogOk = useCallback(
    (start: number, end: number) => {
      setNewEnterRangesDialogOpen(false);
      const newValues = [...(values || []).map((value) => [...value]), [start, end]] as [
        number | null,
        number | null,
      ][];
      setValues(newValues);
    },
    [values],
  );

  const handleChange = useCallback((_event: unknown, value: string) => {
    setRadioValue(value as "all" | "none" | "ranges");
  }, []);

  const intl = useIntl();

  return (
    <>
      <ResponsiveDialog
        fullWidth
        maxWidth="sm"
        okDisabled={diableOk}
        open={open && !newEnterRangesDialogOpen && !deleteDialogOpen}
        title={<FormattedMessage defaultMessage="Rediger værdier" id="enter-ranges.edit-ranges" />}
        onCancel={onClose}
        onOk={handleOk}
      >
        <DialogContent>
          <FormControl component="fieldset" style={{width: "100%"}}>
            <RadioGroup value={radioValue} onChange={handleChange}>
              <FormControlLabel
                control={<Radio />}
                label={intl.formatMessage({defaultMessage: "Intervaller"})}
                value="ranges"
              />
              {radioValue === "ranges" ? (
                <div>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <FormattedMessage defaultMessage="Fra" id="enter-ranges.from" />
                        </TableCell>
                        <TableCell>
                          <FormattedMessage defaultMessage="Til" id="enter-ranges.to" />
                        </TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {values?.map(([from, to], index) => {
                        return (
                          <EnterRangesRow
                            key={index}
                            from={from}
                            index={index}
                            to={to}
                            onFromValueChange={handleFromValueChange}
                            onRequestDelete={handleDeleteClick}
                            onToValueChange={handleToValueChange}
                          />
                        );
                      })}
                    </TableBody>
                  </Table>
                  <Button onClick={setNewEnterRangesDialogOpenTrue}>
                    <FormattedMessage defaultMessage="Tilføj interval" />
                  </Button>
                </div>
              ) : null}
              <FormControlLabel
                control={<Radio />}
                label={intl.formatMessage({defaultMessage: "Alle"})}
                value="all"
              />
              <FormControlLabel
                control={<Radio />}
                label={intl.formatMessage({defaultMessage: "Ingen"})}
                value="none"
              />
            </RadioGroup>
          </FormControl>
        </DialogContent>
      </ResponsiveDialog>
      <AddRangesDialog
        open={newEnterRangesDialogOpen}
        onCancel={setNewEnterRangesDialogOpenFalse}
        onOk={handleNewEnterRangesDialogOk}
      />
      <DeleteDialog
        open={deleteDialogOpen}
        onCancel={handleDeleteDialogCancel}
        onOk={handleDeleteDialogOk}
      >
        <FormattedMessage
          defaultMessage="Hvis du sletter denne mens den er i brug, kan du ødelægge opsætningen!"
          id="key-value-setting.delete-message"
        />
      </DeleteDialog>
    </>
  );
}
