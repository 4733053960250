import {
  KrPerLiterFuelSurchargeSpecification,
  KrPerLiterFuelSurchargeSpecificationUrl,
} from "@co-common-libs/resources";
import {KrPerLiterFuelSurchargeSpecificationDialog} from "@co-frontend-libs/components";
import {AppState, getKrPerLiterFuelSurchargeSpecificationArray} from "@co-frontend-libs/redux";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";

interface KrPerLiterFuelSurchargeSpecificationDialogStateProps {
  krPerLiterFuelSurchargeSpecificationArray: readonly KrPerLiterFuelSurchargeSpecification[];
}

interface KrPerLiterFuelSurchargeSpecificationDialogOwnProps {
  onCancel(): void;
  onNone?(): void;
  onOk(url: KrPerLiterFuelSurchargeSpecificationUrl): void;
  open: boolean;
}

export const ConnectedKrPerLiterFuelSurchargeSpecificationDialog: React.ComponentType<KrPerLiterFuelSurchargeSpecificationDialogOwnProps> =
  connect<
    KrPerLiterFuelSurchargeSpecificationDialogStateProps,
    object,
    KrPerLiterFuelSurchargeSpecificationDialogOwnProps,
    AppState
  >(
    createStructuredSelector<AppState, KrPerLiterFuelSurchargeSpecificationDialogStateProps>({
      krPerLiterFuelSurchargeSpecificationArray: getKrPerLiterFuelSurchargeSpecificationArray,
    }),
    {},
  )(KrPerLiterFuelSurchargeSpecificationDialog);
