import {
  CustomerUrl,
  PriceItem,
  PriceItemUrl,
  PriceItemUse,
  PricePercentDefaultFuelSurchargeUse,
  PricePercentFuelSurchargeSpecification,
  PricePercentFuelSurchargeSpecificationUrl,
  PricePercentMachineFuelSurchargeUse,
  PricePercentWorkTypeFuelSurchargeUse,
  Task,
} from "@co-common-libs/resources";
import {findFuelSurchargeUseAndSpecification} from "./find-fuel-surcharge-use-and-specification";

type PricePercentFuelSurchargeSpecificationPart = Pick<
  PricePercentFuelSurchargeSpecification,
  "active"
>;
type PricePercentMachineFuelSurchargeUsePart = Pick<
  PricePercentMachineFuelSurchargeUse,
  "customer" | "fuelSurcharge" | "machine" | "variant"
>;
type PricePercentWorkTypeFuelSurchargeUsePart = Pick<
  PricePercentWorkTypeFuelSurchargeUse,
  "customer" | "fuelSurcharge" | "variant" | "workType"
>;
type PricePercentDefaultFuelSurchargeUsePart = Pick<
  PricePercentDefaultFuelSurchargeUse,
  "customer" | "fuelSurcharge"
>;

export function getPriceItemUseMatches<
  Specification extends PricePercentFuelSurchargeSpecificationPart,
  MachineFuelSurchargeUse extends PricePercentMachineFuelSurchargeUsePart,
  WorkTypeFuelSurchargeUse extends PricePercentWorkTypeFuelSurchargeUsePart,
  DefaultFuelSurchargeUse extends PricePercentDefaultFuelSurchargeUsePart,
>(
  task: Pick<Task, "priceGroup" | "workType"> & {
    readonly priceItemUses: {
      readonly [identifier: string]: Pick<
        PriceItemUse,
        "dangling" | "machine" | "priceGroup" | "priceItem" | "workType"
      >;
    };
  },
  customerUrl: CustomerUrl | null,
  priceItemLookup: (
    url: PriceItemUrl,
  ) => Pick<PriceItem, "contributesToFuelSurchargeSalesPrice"> | undefined,
  fuelSurchargeSpecificationLookup: (
    url: PricePercentFuelSurchargeSpecificationUrl,
  ) => Specification | undefined,
  machineFuelSurchargeUseArray: readonly MachineFuelSurchargeUse[],
  workTypeFuelSurchargeUseArray: readonly WorkTypeFuelSurchargeUse[],
  defaultFuelSurchargeUseArray: readonly DefaultFuelSurchargeUse[],
): Map<
  PriceItemUrl,
  {
    specification: Specification | null;
    use: DefaultFuelSurchargeUse | MachineFuelSurchargeUse | WorkTypeFuelSurchargeUse;
  }
> {
  const result = new Map<
    PriceItemUrl,
    {
      specification: Specification | null;
      use: DefaultFuelSurchargeUse | MachineFuelSurchargeUse | WorkTypeFuelSurchargeUse;
    }
  >();

  if (task.priceItemUses) {
    for (const priceItemUse of Object.values(task.priceItemUses)) {
      if (priceItemUse.dangling) {
        continue;
      }
      const priceItem = priceItemLookup(priceItemUse.priceItem);
      if (!priceItem || !priceItem.contributesToFuelSurchargeSalesPrice) {
        continue;
      }
      const [machineUrl, machinePriceGroupUrl] = priceItemUse.machine
        ? [priceItemUse.machine, priceItemUse.priceGroup]
        : [null, null];
      const [workTypeUrl, workTypePriceGroupUrl] = priceItemUse.workType
        ? [priceItemUse.workType, priceItemUse.priceGroup]
        : [task.workType, task.priceGroup];
      const match = findFuelSurchargeUseAndSpecification(
        {
          customerUrl,
          machinePriceGroupUrl,
          machineUrl,
          workTypePriceGroupUrl,
          workTypeUrl,
        },
        machineFuelSurchargeUseArray,
        workTypeFuelSurchargeUseArray,
        defaultFuelSurchargeUseArray,
        fuelSurchargeSpecificationLookup,
      );
      if (match) {
        result.set(priceItemUse.priceItem, match);
      }
    }
  }
  return result;
}
