import {PropTypes} from "@material-ui/core";
import React from "react";
import {MultiFabSpeedDial} from "./multi-fab-speeddial";
import {SingleFloatingActionButton} from "./single-floating-action-button";
import {FloatingActionButtonData} from "./types";

export interface FloatingActionButtonsProps {
  buttons: readonly FloatingActionButtonData[];
  color?: PropTypes.Color;
  disabled?: boolean | undefined;
  forcedOpen?: boolean;
  name: string;
  // The component variant requires parent to have overflow set to visible for
  // SpeedDial be visible in case the containing component is smaller than the
  // space required to display FABs when activated.
  variant: "component" | "page";
}

export const FloatingActionButtons = React.memo(function FloatingActionButtons(
  props: FloatingActionButtonsProps,
): JSX.Element | null {
  const {buttons, color, disabled, forcedOpen, name, variant} = props;

  if (!buttons.length) {
    return null;
  }

  return buttons.length === 1 ? (
    <SingleFloatingActionButton
      button={buttons[0]}
      color={color}
      disabled={disabled}
      variant={variant}
    />
  ) : (
    <MultiFabSpeedDial
      ariaLabel={name}
      buttons={buttons}
      color={color}
      disabled={disabled}
      forcedOpen={forcedOpen || false}
      variant={variant}
    />
  );
});
