import {HALF_MINUTE_MILLISECONDS, MINUTE_MILLISECONDS} from "@co-common-libs/utils";
import {splitSingleIntervalOnMidnight} from "./split-on-midnights";
import {TaskInterval, TaskIntervalWithUnregistered} from "./types";

/** Inject breaks for unregistered periods between
 * `unregisteredBreakAfterMinutes` and `splitThresholdMinutes`.
 * Inject "active" intervals for unregistered periods between half a minute
 * and `unregisteredBreakAfterMinutes` --- though still a break if between
 * breaks. */
export function injectUnregistered(
  intervals: readonly TaskInterval[],
  unregisteredBreakAfterMinutes: number,
  splitThresholdMinutes: number,
): TaskIntervalWithUnregistered[] {
  const result: TaskIntervalWithUnregistered[] = [];
  if (!intervals.length) {
    return result;
  }
  const unregisteredBreakAfterMilliseconds = unregisteredBreakAfterMinutes * MINUTE_MILLISECONDS;
  const splitThresholdMilliseconds = splitThresholdMinutes * MINUTE_MILLISECONDS;
  let previousInterval = intervals[0];
  result.push(Object.assign({unregistered: false}, previousInterval));
  for (let i = 1; i < intervals.length; i += 1) {
    const interval = intervals[i];
    const unregisteredFromDate = new Date(previousInterval.toTimestamp);
    const unregisteredToDate = new Date(interval.fromTimestamp);
    const unregisteredMilliseconds = unregisteredToDate.valueOf() - unregisteredFromDate.valueOf();
    let unregisteredInterval: TaskIntervalWithUnregistered | undefined;
    if (
      unregisteredMilliseconds > unregisteredBreakAfterMilliseconds &&
      unregisteredMilliseconds < splitThresholdMilliseconds
    ) {
      unregisteredInterval = {
        fromTimestamp: previousInterval.toTimestamp,
        isBreak: true,
        taskData: [],
        toTimestamp: interval.fromTimestamp,
        unregistered: true,
      };
    } else if (
      unregisteredMilliseconds > HALF_MINUTE_MILLISECONDS &&
      unregisteredMilliseconds <= unregisteredBreakAfterMilliseconds &&
      unregisteredMilliseconds <= splitThresholdMilliseconds
    ) {
      // Short unregistered interval is break if between two breaks.
      const isBreak = previousInterval.isBreak && interval.isBreak;
      unregisteredInterval = {
        fromTimestamp: previousInterval.toTimestamp,
        isBreak,
        taskData: [],
        toTimestamp: interval.fromTimestamp,
        unregistered: true,
      };
    }
    if (unregisteredInterval) {
      if (
        unregisteredFromDate.getFullYear() === unregisteredToDate.getFullYear() &&
        unregisteredFromDate.getMonth() === unregisteredToDate.getMonth() &&
        unregisteredFromDate.getDate() === unregisteredToDate.getDate()
      ) {
        result.push(unregisteredInterval);
      } else {
        result.push(...splitSingleIntervalOnMidnight(unregisteredInterval));
      }
    }
    result.push({...interval, unregistered: false});
    previousInterval = interval;
  }
  return result;
}
