import {Timer} from "@co-common-libs/resources";
import {getNormalisedDeviceTimestamp} from "@co-common-libs/resources-utils";
import {SECOND_MILLISECONDS, formatDate, formatTime, getMinuteString} from "@co-common-libs/utils";
import {Check, Query, makeQuery} from "@co-frontend-libs/db-resources";
import {
  actions,
  getPathName,
  getPositionArray,
  getSyncedState,
  getTimerLookup,
  getTimerStartLookup,
  getUserUserProfileLookup,
  makePathParameterGetter,
} from "@co-frontend-libs/redux";
import {
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {PageLayout} from "app-components";
import {useQueryParameter} from "app-utils";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import CrosshairsGpsIcon from "mdi-react/CrosshairsGpsIcon";
import React, {useEffect, useMemo, useState} from "react";
import {FormattedMessage, FormattedNumber, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

interface PositionRowProps {
  latitude: number;
  longitude: number;
  precission: number;
  timer?: Timer | undefined;
  timestamp: string;
}

const PositionRow = React.memo(function PositionRow(props: PositionRowProps): JSX.Element {
  const {latitude, longitude, precission, timer, timestamp} = props;
  const mapsURL = `http://maps.google.com/maps?q=${latitude},${longitude}&hl=da`;
  return (
    <TableRow>
      <TableCell>{latitude}</TableCell>
      <TableCell>{longitude}</TableCell>
      <TableCell>
        <FormattedNumber maximumFractionDigits={2} value={precission} />
      </TableCell>
      <TableCell>
        {timer?.label || (
          <FormattedMessage defaultMessage="Stop/Auto" id="position-list.label.stop-auto" />
        )}
      </TableCell>
      <TableCell>{formatTime(timestamp)}</TableCell>
      <TableCell>{formatDate(timestamp)}</TableCell>
      <TableCell>
        <IconButton color="primary" href={mapsURL} target="_blank">
          <CrosshairsGpsIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
});

interface PositionTableProps {
  positions:
    | {
        latitude: number;
        longitude: number;
        precission: number;
        timer?: Timer | undefined;
        timestamp: string;
        url: string;
      }[]
    | undefined;
}

export const PositionTable = React.memo(function PositionTable(
  props: PositionTableProps,
): JSX.Element {
  const {positions} = props;
  return (
    <Table size="small" style={{backgroundColor: "#fff"}}>
      <TableHead>
        <TableRow>
          <TableCell>
            <FormattedMessage
              defaultMessage="Breddegrad"
              id="position-list.table-header.latitude"
            />
          </TableCell>
          <TableCell>
            <FormattedMessage
              defaultMessage="Længdegrad"
              id="position-list.table-header.longitude"
            />
          </TableCell>
          <TableCell>
            <FormattedMessage
              defaultMessage="Nøjagtighed, m"
              id="position-list.table-header.accuracy"
            />
          </TableCell>
          <TableCell>
            <FormattedMessage defaultMessage="Knap" id="position-list.table-header.timer" />
          </TableCell>
          <TableCell>
            <FormattedMessage defaultMessage="Tid" id="position-list.table-header.time" />
          </TableCell>
          <TableCell>
            <FormattedMessage defaultMessage="Dato" id="position-list.table-header.date" />
          </TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {positions &&
          positions.map((position) => (
            <PositionRow
              key={position.url}
              latitude={position.latitude}
              longitude={position.longitude}
              precission={position.precission}
              timer={position.timer}
              timestamp={position.timestamp}
            />
          ))}
      </TableBody>
    </Table>
  );
});

interface PositionListProps {
  onMenuButton: (event: React.MouseEvent) => void;
}

const TEMPORARY_QUERIES_KEY = "PositionList";

export const PositionList = React.memo(function PositionList(
  _props: PositionListProps,
): JSX.Element {
  const [query, setQuery] = useState<Query | null>(null);

  const dispatch = useDispatch();

  const intl = useIntl();

  const timerStartLookup = useSelector(getTimerStartLookup);
  const timerLookup = useSelector(getTimerLookup);
  const positionArray = useSelector(getPositionArray);
  const userUserProfileLookup = useSelector(getUserUserProfileLookup);

  const pathName = useSelector(getPathName);
  const fromDateTime = useQueryParameter("fromDateTime");
  const toDateTime = useQueryParameter("toDateTime");
  const machineOperatorId = useSelector(makePathParameterGetter("machineOperatorId"));

  const getPositionSyncedState = useMemo(() => getSyncedState.bind(null, "position"), []);
  const positionSyncedState = useSelector(getPositionSyncedState);

  const [now, setNow] = useState(new Date());
  const minuteString = getMinuteString(now);

  useEffect(() => {
    const positionCheck: Check = {
      checks: [
        {
          memberName: "employee",
          type: "memberEq",
          value: instanceURL("user", machineOperatorId),
        },
        {
          memberName: "deviceTimestamp",
          type: "memberGte",
          value: fromDateTime,
        },
        {
          memberName: "deviceTimestamp",
          type: "memberLte",
          value: toDateTime,
        },
      ],
      type: "and",
    };
    const positionQuery = makeQuery({
      check: positionCheck,
      filter: {
        anticache: minuteString,
        fromDateTime,
        machineOperator: machineOperatorId,
        toDateTime,
      },
      independentFetch: true,
      resourceName: "position",
    });
    const timerStartQuery = makeQuery({
      check: {
        check: positionCheck,
        fromResource: "position",
        memberName: "timerStart",
        type: "targetOfForeignKey",
      },
      independentFetch: false,
      resourceName: "timerStart",
    });
    dispatch(
      actions.temporaryQueriesRequestedForPath(
        [positionQuery, timerStartQuery],
        pathName,
        TEMPORARY_QUERIES_KEY,
      ),
    );
    setQuery(positionQuery);
  }, [dispatch, fromDateTime, machineOperatorId, minuteString, pathName, toDateTime]);

  useEffect(() => {
    const markerRefreshIntervalSeconds = 10;

    const refreshPositions = (): void => {
      setNow(new Date());
    };
    const intervalID = window.setInterval(
      refreshPositions,
      markerRefreshIntervalSeconds * SECOND_MILLISECONDS,
    );
    return () => {
      window.clearInterval(intervalID);
    };
  }, []);

  const machineOperatorURL = instanceURL("user", machineOperatorId);
  const machineOperatorProfile = userUserProfileLookup(machineOperatorURL);

  let message: string | undefined;
  let spinner: JSX.Element | undefined;

  if (query) {
    const querySyncedState = positionSyncedState.get(query.keyString);
    if (!querySyncedState || querySyncedState.queryState.currentlyFullFetching) {
      message = intl.formatMessage({defaultMessage: "Henter fra arkiv"});
      spinner = <CircularProgress />;
    } else {
      const lastTimestamp = querySyncedState.queryState.fullFetchDataComputedAtTimestamp as string;
      const {lastErrorTimestamp} = querySyncedState.queryState;
      if (lastErrorTimestamp && lastErrorTimestamp > lastTimestamp) {
        message = intl.formatMessage({
          defaultMessage: "Fejl ved adgang til arkiv",
        });
      }
    }
  }

  const positions = _.sortBy(
    positionArray.filter((p) => {
      const deviceTimestamp = getNormalisedDeviceTimestamp(p);
      return (
        p.employee === machineOperatorURL &&
        deviceTimestamp >= fromDateTime &&
        deviceTimestamp <= toDateTime
      );
    }),
    getNormalisedDeviceTimestamp,
  ).map((position) => {
    const timerStart = position.timerStart ? timerStartLookup(position.timerStart) : null;
    const timer = timerStart?.timer ? timerLookup(timerStart.timer) : undefined;
    return {
      latitude: position.latitude,
      longitude: position.longitude,
      precission: position.accuracy,
      timer,
      timestamp: position.deviceTimestamp,
      url: position.url,
    };
  });

  return (
    <PageLayout
      withBottomScrollPadding
      toolbar={intl.formatMessage(
        {defaultMessage: "GPS Positioner for {initials}"},
        {
          initials: machineOperatorProfile ? machineOperatorProfile.alias : "",
        },
      )}
    >
      <PositionTable positions={positions} />
      {message ? (
        <div style={{padding: 8, textAlign: "center"}}>
          <div>{message}</div>
          {spinner}
        </div>
      ) : null}
    </PageLayout>
  );
});
