import {Project, ProjectAccess} from "@co-common-libs/resources";
import {CoinOutlineIcon} from "@co-frontend-libs/components";
import BlockHelperIcon from "mdi-react/BlockHelperIcon";
import CheckIcon from "mdi-react/CheckIcon";
import EyeOffIcon from "mdi-react/EyeOffIcon";
import React from "react";
import {match} from "ts-pattern";

interface ProjectAccessIconProps {
  project: Pick<Project, "access" | "remoteUrl">;
}

export const ProjectAccessIcon = React.memo(function ProjectAccessIcon({
  project,
}: ProjectAccessIconProps): JSX.Element | null {
  return match(project)
    .returnType<JSX.Element | null>()
    .with({access: ProjectAccess.Open}, () => <CheckIcon />)
    .with({access: ProjectAccess.Hidden}, () => <EyeOffIcon />)
    .with({access: ProjectAccess.Closed}, () => <CoinOutlineIcon />)
    .with({access: ProjectAccess.Barred}, () => <BlockHelperIcon />)
    .otherwise(() => null);
});
