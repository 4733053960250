import {ImportPreviewProductGroup} from "@co-common-libs/resources-utils";
import {memoizeForceReuse} from "@co-frontend-libs/utils";
import React, {useMemo} from "react";
import {useIntl} from "react-intl";
import {GenericMultiSelectionSearchDialog} from "../../../search-dialog";
import {computePreviewProductGroupsBaseChoices} from "./compute-preview-product-groups-base-choices";

// TODO(mr): next integration - make independent of e-conomic - dynamically generate messages based on passed-in settings
interface ImportProductGroupsPreviewDialogProps {
  error: string | undefined;
  importType: "PRODUCT_GROUP" | "WORK_TYPE";
  includeSelectAll?: boolean;
  onCancel(): void;
  onOk(identifiers: ReadonlySet<string>): void;
  open: boolean;
  previewProductGroups: readonly ImportPreviewProductGroup[] | null;
}

export const ImportProductGroupsPreviewDialog = React.memo(
  function ImportProductGroupsPreviewDialog(props: ImportProductGroupsPreviewDialogProps) {
    const {error, importType, includeSelectAll, onCancel, onOk, open, previewProductGroups} = props;
    const intl = useIntl();

    const [doComputeBaseChoices, reuseBaseChoices] = useMemo(
      () => memoizeForceReuse(computePreviewProductGroupsBaseChoices, []),
      [],
    );

    const getBaseChoices = open ? doComputeBaseChoices : reuseBaseChoices;
    const data = previewProductGroups ? getBaseChoices(previewProductGroups) : null;

    const importTypeIsWorkType = importType === "WORK_TYPE";
    return (
      <GenericMultiSelectionSearchDialog<string>
        data={data}
        error={error}
        includeSelectAll={includeSelectAll}
        listEmptyMessage={
          importTypeIsWorkType
            ? intl.formatMessage({
                defaultMessage:
                  "Alle varegrupper i e-conomic er allerede importeret til CustomOffice som områder",
              })
            : intl.formatMessage({
                defaultMessage:
                  "Alle varegrupper i e-conomic er allerede importeret til CustomOffice",
              })
        }
        mobilePrimaryLines={1}
        mobileSearchPrimaryLines={1}
        mobileSearchSecondaryLines={1}
        mobileSecondaryLines={1}
        open={open}
        searchTitle={
          importTypeIsWorkType
            ? intl.formatMessage({defaultMessage: "Søg arbejdsområder"})
            : intl.formatMessage({defaultMessage: "Søg varegrupper"})
        }
        sorting="SECONDARY_TEXT"
        title={
          importTypeIsWorkType
            ? intl.formatMessage({
                defaultMessage: "Importer arbejdsområder",
              })
            : intl.formatMessage({
                defaultMessage: "Importer varegrupper",
              })
        }
        onCancel={onCancel}
        onOk={onOk}
      />
    );
  },
);
