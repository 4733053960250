import {NullableStringPair} from "@co-common-libs/config";
import {useTheme} from "@material-ui/core";
import React from "react";
import {FormattedMessage} from "react-intl";
import {SettingValueDisplayProps} from "../types";

export function AllWeekDaysTimeRanges(props: SettingValueDisplayProps): JSX.Element | null {
  const {settingEntry} = props;

  const theme = useTheme();

  if (!settingEntry || !settingEntry.data) {
    return null;
  }

  const data = settingEntry.data as [
    NullableStringPair,
    NullableStringPair,
    NullableStringPair,
    NullableStringPair,
    NullableStringPair,
    NullableStringPair,
    NullableStringPair,
  ];

  return (
    <div>
      <span
        style={{
          backgroundColor: theme.palette.grey[200],
          padding: 2,
        }}
      >
        <FormattedMessage
          defaultMessage="Mandag {mondayFromTime}-{mondayToTime}, tirsdag {tuesdayFromTime}-{tuesdayToTime}, onsdag {wednesdayFromTime}-{wednesdayToTime}, torsdag {thursdayFromTime}-{thursdayToTime}, fredag {fridayFromTime}-{fridayToTime}, lørdag {saturdayFromTime}-{saturdayToTime}, søndag {sundayFromTime}-{sundayToTime}"
          id="system-setup.label.food-booking-notification-time"
          values={{
            fridayFromTime: data[5]?.[0],
            fridayToTime: data[5]?.[1],
            mondayFromTime: data[1]?.[0],
            mondayToTime: data[1]?.[1],
            saturdayFromTime: data[6]?.[0],
            saturdayToTime: data[6]?.[1],
            sundayFromTime: data[0]?.[0],
            sundayToTime: data[0]?.[1],
            thursdayFromTime: data[4]?.[0],
            thursdayToTime: data[4]?.[1],
            tuesdayFromTime: data[2]?.[0],
            tuesdayToTime: data[2]?.[1],
            wednesdayFromTime: data[3]?.[0],
            wednesdayToTime: data[3]?.[1],
          }}
        />
      </span>
    </div>
  );
}
