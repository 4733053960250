import {ResponsiveDialog} from "@co-frontend-libs/components";
import {List} from "@material-ui/core";
import React, {useCallback, useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import {ColumnListItem} from "./column-list-item";

interface ColumnSelectionDialogProps<ColumnChoice extends string> {
  columns: readonly ColumnChoice[];
  onClose: () => void;
  onSave: (selected: readonly ColumnChoice[]) => void;
  open: boolean;
  selected?: readonly ColumnChoice[] | undefined;
}

export function ColumnSelectionDialog<ColumnChoice extends string>(
  props: ColumnSelectionDialogProps<ColumnChoice>,
): JSX.Element {
  const {columns, onClose, onSave, open} = props;
  const [selected, setSelected] = useState(props.selected || []);
  useEffect(() => {
    if (open) {
      setSelected(props.selected || []);
    }
  }, [props.selected, open]);
  const handleSave = useCallback(() => {
    if (!selected) {
      return;
    }
    onSave(selected);
  }, [onSave, selected]);
  const handleToggle = useCallback(
    (column: ColumnChoice) => {
      if (selected.includes(column)) {
        setSelected(selected.filter((x) => x !== column));
      } else {
        setSelected([...selected, column]);
      }
    },
    [selected],
  );
  return (
    <ResponsiveDialog
      fullscreen
      okLabel={<FormattedMessage defaultMessage="Vælg" id="setting-dialog.label.select" />}
      open={open}
      title={
        <FormattedMessage
          defaultMessage="Vælg kolonner"
          id="system-setup.dialog-title.select-columns"
        />
      }
      onCancel={onClose}
      onOk={handleSave}
    >
      <List>
        {columns.map((column) => (
          <ColumnListItem<ColumnChoice>
            key={column}
            checked={selected.includes(column)}
            column={column}
            onClick={handleToggle}
          />
        ))}
      </List>
    </ResponsiveDialog>
  );
}
