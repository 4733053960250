import {Checkbox, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import React, {useCallback} from "react";
import {useIntl} from "react-intl";
import {LocationPart} from "./utils";

interface DialogFieldListItemProps {
  checked: boolean;
  location: LocationPart;
  onToggle: (location: LocationPart) => void;
}

export function DialogFieldListItem(props: DialogFieldListItemProps): JSX.Element {
  const {checked, location, onToggle} = props;

  const {formatNumber} = useIntl();

  const handleToggle = useCallback(() => {
    onToggle(location);
  }, [location, onToggle]);

  const areaHaString = location.fieldAreaHa ? ` (${formatNumber(location.fieldAreaHa)} ha)` : "";
  return (
    <ListItem button onClick={handleToggle}>
      <ListItemIcon>
        <Checkbox
          disableRipple
          checked={checked}
          edge="start"
          tabIndex={-1}
          onChange={handleToggle}
        />
      </ListItemIcon>
      {location.fieldBlock ? (
        <ListItemText
          primary={`${location.fieldNumber}. (${location.fieldBlock}), ${
            location.fieldCrop
          }${areaHaString}${areaHaString ? ", " : ""}Jnr.: ${location.fieldJournalNumber}`}
        />
      ) : (
        <ListItemText primary={`${location.fieldNumber}${areaHaString}`} />
      )}
    </ListItem>
  );
}
