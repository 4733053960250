import {Config} from "@co-common-libs/config";
import {
  Patch,
  PathPatchOperation,
  Product,
  ProductGroup,
  ProductGroupUrl,
  ProductUrl,
  ProductUse,
  ProductUseWithOrder,
  ProductUsesDict,
  Task,
  UserUrl,
} from "@co-common-libs/resources";
import _ from "lodash";
import {v4 as uuid} from "uuid";
import {sortProductUseListByCatalogNumber, updateProductUseEntriesOrder} from "./materials-sorting";

const productUseWithOrderMembers = [
  "addedBy",
  "correctedCount",
  "count",
  "notes",
  "ours",
  "product",
  "order",
] as const;

/*
We want to iterate over all members of ProductUseWithOrder -- but we cannot
extract the list of members from a TypeScript interface to a JS value...

... so instead, we make a normal array with the (expected) member names; and
then do some type-hacks to validate that this matches the actual members:

* With `as const`, the type of the array is an array with those exact elements.
* ProductUseWithOrderMembers is the ProductUseWithOrder keys/members;
  ProductUseWithOrderArrayMembers is the type of elements from the array.
* ProductUseWithOrderMembersMatch is a type which may hold only the value
  `true` if the aforementioned types are equal; otherwise, it may only
  hold the value `false`.
* We assign `true` to a variable with type ProductUseWithOrderMembersMatch
  to get a type error if the types don't match; i.e. the array elements does
  not match the actual interface members...
* The JS-code involved in this check, which could otherwise become part of the
  build-output, is guarded by a NODE_ENV check.
*/
type ProductUseWithOrderMembers = keyof ProductUseWithOrder;
type ProductUseWithOrderArrayMembers = (typeof productUseWithOrderMembers)[number];

type ProductUseWithOrderMembersMatch =
  ProductUseWithOrderMembers extends ProductUseWithOrderArrayMembers
    ? ProductUseWithOrderArrayMembers extends ProductUseWithOrderMembers
      ? true
      : false
    : false;

if (process.env.NODE_ENV !== "production") {
  const test: ProductUseWithOrderMembersMatch = true;
  if (!test) {
    // pretend variable is used
  }
}

export function patchFromProductUsesChange(
  oldProductUses: ProductUsesDict,
  newProductUses: ProductUsesDict,
): PathPatchOperation[] {
  const patch: PathPatchOperation[] = [];
  Object.keys(oldProductUses).forEach((identifier) => {
    if (!newProductUses[identifier]) {
      // removed
      patch.push({path: ["productUses", identifier], value: undefined});
    }
  });
  Object.entries(newProductUses).forEach(([identifier, productUse]) => {
    const existing = oldProductUses[identifier];
    if (!existing) {
      // added
      patch.push({path: ["productUses", identifier], value: productUse});
    } else if (productUse !== existing) {
      // may have changes
      productUseWithOrderMembers.forEach((member) => {
        const newValue = productUse[member];
        if (newValue === undefined) {
          return;
        }
        if (newValue !== existing[member]) {
          patch.push({
            path: ["productUses", identifier, member],
            value: newValue,
          });
        }
      });
    }
  });

  return patch;
}

export function getAutoProductsMapping(
  productUses: ProductUsesDict,
  productLookup: (url: ProductUrl) => Product | undefined,
  productGroupLookup: (url: ProductGroupUrl) => ProductGroup | undefined,
  customerSettings: Pick<Config, "allowDuplicateProductUses" | "autoSupplementingProducts">,
): {
  autoLinesToLines: Map<string, string[]> | null;
  linesToAutoLines: Map<string, string[]> | null;
} {
  const {allowDuplicateProductUses, autoSupplementingProducts} = customerSettings;
  if (!autoSupplementingProducts) {
    return {autoLinesToLines: null, linesToAutoLines: null};
  }
  const linesToAutoLines = new Map<string, string[]>();
  const autoLinesToLines = new Map<string, string[]>();
  const sortedProductUses = _.sortBy(
    Object.entries(productUses),
    ([_identifier, productUse]) => productUse.order,
  );
  for (const [baseProductUseIdentifier, baseProductUse] of sortedProductUses) {
    console.assert(!linesToAutoLines.has(baseProductUseIdentifier));
    if (autoLinesToLines.has(baseProductUseIdentifier)) {
      // we don't support recursive auto-lines
      continue;
    }
    const product = productLookup(baseProductUse.product);
    if (!product || !product.group) {
      continue;
    }
    const productGroup = productGroupLookup(product.group);
    if (!productGroup || !productGroup.remoteUrl) {
      continue;
    }
    const eConomicProductGroupPrefix = "https://restapi.e-conomic.com/product-groups/";
    const productGroupID = productGroup.remoteUrl.startsWith(eConomicProductGroupPrefix)
      ? productGroup.remoteUrl.substring(eConomicProductGroupPrefix.length)
      : productGroup.remoteUrl;
    const autoProductIdentifiers = autoSupplementingProducts[productGroupID];
    if (!autoProductIdentifiers || !autoProductIdentifiers.length) {
      continue;
    }
    const remainingAutoProductIdentifiers = new Set(autoProductIdentifiers);
    for (const [otherProductUseIdentifier, otherProductUse] of sortedProductUses) {
      if (otherProductUseIdentifier === baseProductUseIdentifier) {
        continue;
      }
      if (allowDuplicateProductUses && autoLinesToLines.has(otherProductUseIdentifier)) {
        // auto-added product use for a *different* entry;
        // and with allowDuplicateProductUses it won't be "shared"
        continue;
      }
      const otherProduct = productLookup(otherProductUse.product);
      if (!otherProduct) {
        continue;
      }
      if (!remainingAutoProductIdentifiers.has(otherProduct.catalogNumber)) {
        continue;
      }
      const existingBaseToAuto = linesToAutoLines.get(baseProductUseIdentifier);
      if (existingBaseToAuto) {
        existingBaseToAuto.push(otherProductUseIdentifier);
      } else {
        linesToAutoLines.set(baseProductUseIdentifier, [otherProductUseIdentifier]);
      }
      const existingAutoToBase = autoLinesToLines.get(otherProductUseIdentifier);
      if (existingAutoToBase) {
        existingAutoToBase.push(baseProductUseIdentifier);
      } else {
        autoLinesToLines.set(otherProductUseIdentifier, [baseProductUseIdentifier]);
      }
      remainingAutoProductIdentifiers.delete(otherProduct.catalogNumber);
      if (!remainingAutoProductIdentifiers.size) {
        break;
      }
    }
  }
  if (autoLinesToLines.size && linesToAutoLines.size) {
    return {autoLinesToLines, linesToAutoLines};
  } else {
    return {autoLinesToLines: null, linesToAutoLines: null};
  }
}

export function addToProductUsesHelper(
  productUses: ProductUsesDict,
  urlOrURLs: ProductUrl | ReadonlySet<ProductUrl>,
  productArray: readonly Product[],
  productLookup: (url: ProductUrl) => Product | undefined,
  productGroupLookup: (url: ProductGroupUrl) => ProductGroup | undefined,
  customerSettings: Pick<
    Config,
    | "allowDuplicateProductUses"
    | "autoCopyMaterialNoteToSupplementingProductNote"
    | "autoSupplementingProducts"
  >,
  currentUserURL: UserUrl | null,
): ProductUsesDict {
  const allowDuplicates = !!(customerSettings && customerSettings.allowDuplicateProductUses);
  const urls = typeof urlOrURLs === "string" ? [urlOrURLs] : Array.from(urlOrURLs);
  const currentProductUseValues = Object.values(productUses);
  const currentURLs = new Set(currentProductUseValues.map((entry) => entry.product));

  const newEntries: ProductUse[] = [];
  let autoProductsAdded = false;
  for (const url of urls) {
    if (!allowDuplicates && currentURLs.has(url)) {
      continue;
    }
    newEntries.push({
      addedBy: currentUserURL,
      correctedCount: null,
      count: null,
      notes: "",
      ours: true,
      product: url,
    });
    currentURLs.add(url);
    if (customerSettings.autoSupplementingProducts) {
      const product = productLookup(url);
      const productGroupURL = product && product.group;
      const productGroup = productGroupURL && productGroupLookup(productGroupURL);
      if (productGroup && productGroup.remoteUrl) {
        const eConomicProductGroupPrefix = "https://restapi.e-conomic.com/product-groups/";
        const productGroupID = productGroup.remoteUrl.startsWith(eConomicProductGroupPrefix)
          ? productGroup.remoteUrl.substring(eConomicProductGroupPrefix.length)
          : productGroup.remoteUrl;
        const autoProductIdentifiers = customerSettings.autoSupplementingProducts[productGroupID];
        // eslint-disable-next-line max-depth
        if (autoProductIdentifiers && autoProductIdentifiers.length) {
          // eslint-disable-next-line max-depth
          for (let j = 0; j < autoProductIdentifiers.length; j += 1) {
            const productID = autoProductIdentifiers[j];
            const supplementingProduct = productArray.find(
              (p) => p.active && p.catalogNumber === productID,
            );
            // eslint-disable-next-line max-depth
            if (supplementingProduct) {
              const productURL = supplementingProduct.url;
              // eslint-disable-next-line max-depth
              if (allowDuplicates || !currentURLs.has(productURL)) {
                newEntries.push({
                  addedBy: currentUserURL,
                  correctedCount: null,
                  count: null,
                  notes: "",
                  ours: true,
                  product: productURL,
                });
                currentURLs.add(productURL);
                autoProductsAdded = true;
              }
            }
          }
        }
      }
    }
  }

  const oldMaxOrder = _.max(currentProductUseValues.map((productUse) => productUse.order));

  let nextOrder = oldMaxOrder !== undefined ? oldMaxOrder + 1 : 0;
  const unsortedNewValue = {...productUses};
  for (const productUse of newEntries) {
    const newId = uuid();
    unsortedNewValue[newId] = {...productUse, order: nextOrder};
    nextOrder += 1;
  }
  const sortedNewValue = sortProductUseListByCatalogNumber(unsortedNewValue, productLookup);
  if (!customerSettings.autoSupplementingProducts || !allowDuplicates) {
    return sortedNewValue;
  }
  // With allowDuplicates, we may have multiple auto-lines of the same type,
  // and in sucha a way that an existing auto-entry becomes associated with a
  // new "normal" entry and the new auto-entry becomes associated with an
  // existing "normal" entry -- so we recompute
  const {autoLinesToLines, linesToAutoLines} = getAutoProductsMapping(
    sortedNewValue,
    productLookup,
    productGroupLookup,
    customerSettings,
  );
  if (!autoLinesToLines || !linesToAutoLines) {
    // impossible?
    return sortedNewValue;
  }
  if (autoProductsAdded) {
    // recompute auto-lines; values on auto-lines should match values on
    // lines adding them in order...
    autoLinesToLines.forEach((sourceLineIdentifiers, autoLineIdentifier) => {
      // we don't support changing allowDuplicates on the fly;
      // allowDuplicates implies that each source line would have
      // separate auto lines
      console.assert(sourceLineIdentifiers.length === 1);
      const [sourceLineIndex] = sourceLineIdentifiers;
      const autoLine = sortedNewValue[autoLineIdentifier];
      const sourceLine = sortedNewValue[sourceLineIndex];
      sortedNewValue[autoLineIdentifier] = {
        ...autoLine,
        count: sourceLine.count,
        notes: customerSettings.autoCopyMaterialNoteToSupplementingProductNote
          ? sourceLine.notes
          : "",
      };
    });
  }
  // allowDuplicates implies that each source line would have
  // separate auto lines, rather than auto lines being shared;
  // this is when we care about order to indicate the association
  const entriesWithAutoLinesInlined = Object.entries(sortedNewValue).flatMap((entry) => {
    const [identifier /* productUse */] = entry;
    if (autoLinesToLines.has(identifier)) {
      console.assert(autoLinesToLines.get(identifier)?.length === 1);
      return [];
    }
    const autoLineIdentifiers = linesToAutoLines.get(identifier);
    if (!autoLineIdentifiers) {
      return [entry];
    }
    const associatedAutoLines = autoLineIdentifiers.map(
      (autoIdentifier) => [autoIdentifier, sortedNewValue[autoIdentifier]] as const,
    );
    const sortedAssociatedAutoLines = _.sortBy(
      associatedAutoLines,
      ([_identifier, autoProductUse]) => autoProductUse.order,
    );
    return [entry, ...sortedAssociatedAutoLines];
  });
  updateProductUseEntriesOrder(entriesWithAutoLinesInlined);
  const newValueWithAutoLines = Object.fromEntries(entriesWithAutoLinesInlined);
  return newValueWithAutoLines;
}

export function addToProductUses(
  productUses: ProductUsesDict,
  urlOrURLs: ProductUrl | ReadonlySet<ProductUrl>,
  productArray: readonly Product[],
  productLookup: (url: ProductUrl) => Product | undefined,
  productGroupLookup: (url: ProductGroupUrl) => ProductGroup | undefined,
  customerSettings: Pick<
    Config,
    | "allowDuplicateProductUses"
    | "autoCopyMaterialNoteToSupplementingProductNote"
    | "autoSupplementingProducts"
  >,
  currentUserURL: UserUrl | null,
): Patch<Task> {
  return patchFromProductUsesChange(
    productUses,
    addToProductUsesHelper(
      productUses,
      urlOrURLs,
      productArray,
      productLookup,
      productGroupLookup,
      customerSettings,
      currentUserURL,
    ),
  );
}

export function getProductGroupsWithAutoSupplementingProducts(
  autoSupplementingProducts: Config["autoSupplementingProducts"],
  productGroupArray: readonly Pick<ProductGroup, "remoteUrl" | "url">[],
): Set<ProductGroupUrl> {
  if (!autoSupplementingProducts) {
    return new Set<ProductGroupUrl>();
  }
  const autoProductSourceRemoteUrls = new Set(
    Object.keys(autoSupplementingProducts).flatMap((identifier) => [
      identifier,
      // + with e-conomic product group prefix
      `https://restapi.e-conomic.com/product-groups/${identifier}`,
    ]),
  );
  const productGroupUrls = new Set(
    productGroupArray
      .filter((productGroup) => autoProductSourceRemoteUrls.has(productGroup.remoteUrl))
      .map(({url}) => url),
  );
  return productGroupUrls;
}

export function getAutoProductsForProductGroup(
  autoSupplementingProducts: Config["autoSupplementingProducts"],
  productGroup: ProductGroup,
  productArray: readonly Product[],
): Set<ProductUrl> {
  if (!autoSupplementingProducts || !productGroup.remoteUrl) {
    return new Set<ProductUrl>();
  }
  const eConomicProductGroupPrefix = "https://restapi.e-conomic.com/product-groups/";
  const productGroupID = productGroup.remoteUrl.startsWith(eConomicProductGroupPrefix)
    ? productGroup.remoteUrl.substring(eConomicProductGroupPrefix.length)
    : productGroup.remoteUrl;
  const autoProductIdentifiers = autoSupplementingProducts[productGroupID];
  if (!autoProductIdentifiers || autoProductIdentifiers.length === 0) {
    return new Set<ProductUrl>();
  }
  return new Set<ProductUrl>(
    productArray
      .filter((product) => product.active && autoProductIdentifiers.includes(product.catalogNumber))
      .map(({url}) => url),
  );
}
