import {emphasizeSubstrings} from "@co-frontend-libs/utils";
import {
  Checkbox,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  useTheme,
} from "@material-ui/core";
import {Match} from "app-utils";
import React, {useCallback} from "react";
import {areEqual} from "react-window";
import {ColorBox} from "../../color-box";
import {typedMemo} from "../../types";
import {EntryCategoryCode, entryCategoryIcons} from "../types";

function buildMatchesElement(matches: readonly Match[]): JSX.Element {
  const matchElements = matches.map((entry, index) => {
    const {label, matching, text} = entry;
    const textWithMatches = emphasizeSubstrings(text, matching);
    return (
      <span key={index}>
        <em>{label}:</em> {textWithMatches}{" "}
      </span>
    );
  });
  const matchElement = <span>{matchElements}</span>;
  return matchElement;
}

interface MultiSelectionListItemProps<Identifier extends string> {
  category: EntryCategoryCode;
  color?: string | undefined;
  conflict?: boolean | undefined;
  currentlySelected: boolean;
  disabled?: boolean | undefined;
  itemClass?: string | undefined;
  matches?: readonly Match[] | undefined;
  onSelect(identifier: Identifier, selected: boolean): void;
  primaryText: string;
  primaryTextClass?: string | undefined;
  secondaryText?: string | undefined;
  secondaryTextClass?: string | undefined;
  style?: React.CSSProperties;
  value: Identifier;
  withIcons: boolean;
}

const emptyStyle: React.CSSProperties = {};

export const MultiSelectionListItem = typedMemo(function MultiSelectionListItem<
  Identifier extends string,
>(props: MultiSelectionListItemProps<Identifier>) {
  const {
    category,
    color,
    conflict,
    currentlySelected,
    disabled,
    itemClass,
    matches,
    onSelect,
    primaryText,
    primaryTextClass,
    secondaryText,
    secondaryTextClass,
    style = emptyStyle,
    value,
    withIcons,
  } = props;
  const Icon = entryCategoryIcons[category];
  const handleClick = useCallback(
    () => onSelect(value, !currentlySelected),
    [currentlySelected, onSelect, value],
  );
  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>): void => {
      if (event.key === "Enter") {
        onSelect(value, !currentlySelected);
      }
    },
    [currentlySelected, onSelect, value],
  );

  const primaryString =
    matches && secondaryText ? `${primaryText} – ${secondaryText}` : primaryText;
  const secondary = matches ? buildMatchesElement(matches) : secondaryText;

  const primary = color ? (
    <span>
      <span
        style={{
          display: "inline-block",
          height: 16,
          paddingRight: 10,
          verticalAlign: "text-top",
        }}
      >
        <ColorBox inline color={color} />
      </span>
      {primaryString}
    </span>
  ) : (
    primaryString
  );

  const theme = useTheme();
  const textClasses: {
    primary?: string;
    secondary?: string;
  } = {};
  if (primaryTextClass) {
    textClasses.primary = primaryTextClass;
  }
  if (secondaryTextClass) {
    textClasses.secondary = secondaryTextClass;
  }

  if (withIcons && Icon) {
    return (
      <ListItem
        button
        className={itemClass || ""}
        ContainerComponent="div"
        ContainerProps={{style}}
        disabled={disabled || false}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
      >
        <ListItemIcon>
          <Checkbox disableRipple checked={currentlySelected} edge="start" tabIndex={-1} />
        </ListItemIcon>
        <ListItemText
          classes={textClasses}
          primary={primary}
          secondary={secondary}
          style={conflict ? {color: theme.palette.error.main} : {}}
        />
        {withIcons && Icon ? (
          <ListItemSecondaryAction>
            <Icon />
          </ListItemSecondaryAction>
        ) : null}
      </ListItem>
    );
  } else {
    return (
      <ListItem
        button
        className={itemClass || ""}
        disabled={disabled || false}
        style={style}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
      >
        <ListItemIcon>
          <Checkbox disableRipple checked={currentlySelected} edge="start" tabIndex={-1} />
        </ListItemIcon>
        <ListItemText
          classes={textClasses}
          primary={primary}
          secondary={secondary}
          style={conflict ? {color: theme.palette.error.main} : {}}
        />
      </ListItem>
    );
  }
}, areEqual);
