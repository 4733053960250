import {TableCell, TableCellProps} from "@material-ui/core";
import {bind} from "bind-decorator";
import _ from "lodash";
import React from "react";
import {ColumnSpecification, RowData} from "./types";

interface GenericTableCellProps<
  FieldID extends string,
  KeyType extends string = string,
  DataType extends RowData<FieldID, KeyType> = RowData<FieldID, KeyType>,
> {
  column: ColumnSpecification<FieldID, KeyType, DataType>;
  data: DataType;
  rowStyle?: React.CSSProperties;
}

export class GenericTableCell<
  FieldID extends string,
  KeyType extends string = string,
  DataType extends RowData<FieldID, KeyType> = RowData<FieldID, KeyType>,
> extends React.Component<GenericTableCellProps<FieldID, KeyType, DataType>> {
  shouldComponentUpdate(nextProps: GenericTableCellProps<FieldID, KeyType, DataType>): boolean {
    return (
      nextProps.column !== this.props.column ||
      !_.isEqual(nextProps.data, this.props.data) ||
      !_.isEqual(nextProps.rowStyle, this.props.rowStyle)
    );
  }
  @bind
  handleClick(): void {
    const {column, data} = this.props;
    const {onClick} = column;
    if (onClick) {
      onClick(data.key);
    }
  }
  render(): JSX.Element {
    const {column, data, rowStyle} = this.props;
    let style: React.CSSProperties = {};
    const columnStyle = column.style;
    if (columnStyle) {
      if (typeof columnStyle === "function") {
        style = {...style, ...columnStyle(data)};
      } else {
        style = {...style, ...columnStyle};
      }
    }
    if (column.width != null) {
      style = {...style, width: column.width};
    }
    const columnData: DataType[FieldID] = data[column.field];
    if (column.onClick) {
      style = {...style, cursor: "pointer"};
    }
    if (rowStyle) {
      style = {...rowStyle, ...style};
    }
    const content = column.render ? column.render(data) : columnData;
    const tableCellClickProps: Partial<TableCellProps> = {};
    if (column.onClick) {
      tableCellClickProps.onClick = this.handleClick;
    }
    return (
      <TableCell style={style} {...tableCellClickProps}>
        {content}
      </TableCell>
    );
  }
}
