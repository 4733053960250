import {Config} from "@co-common-libs/config";
import {
  ResourceTypeUnion,
  Task,
  TaskUrl,
  Timer,
  TimerStart,
  TimerUrl,
  User,
  UserProfile,
  UserUrl,
  WorkType,
  WorkTypeUrl,
  urlToId,
} from "@co-common-libs/resources";
import {
  AppState,
  PathTemplate,
  actions,
  getCurrentUserActiveTaskURLArray,
  getCustomerSettings,
  getDeviceConfigKey,
  getPathName,
  getTaskLookup,
  getTimerArray,
  getTimerLookup,
  getUserId,
  getUserLookup,
  getUserUserProfileLookup,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {Button, IconButton} from "@material-ui/core";
import {bind} from "bind-decorator";
import bowser from "bowser";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import TimerOutlineIcon from "mdi-react/TimerOutlineIcon";
import React from "react";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {v4 as uuid} from "uuid";

const THIS_DEVICE_UUID = (window as any).device ? ((window as any).device.uuid as string) : "";

interface TimerButtonStateProps {
  currentUserActiveTaskURLArray: readonly string[];
  customerSettings: Config;
  pathName: string;
  taskLookup: (url: TaskUrl) => Task | undefined;
  timerArray: readonly Timer[];
  timerLookup: (url: TimerUrl) => Timer | undefined;
  useMode: string;
  userID: string | null;
  userLookup: (url: UserUrl) => User | undefined;
  userUserProfileLookup: (url: UserUrl) => UserProfile | undefined;
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
}

interface TimerButtonDispatchProps {
  create: (instance: ResourceTypeUnion) => void;
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
}

type TimerButtonProps = TimerButtonDispatchProps & TimerButtonStateProps;

class TimerButton extends React.Component<TimerButtonProps> {
  changeTimer(timerURL: TimerUrl, taskURL: TaskUrl, userURL: UserUrl, timestamp: string): void {
    const id = uuid();
    const url = instanceURL("timerStart", id);
    const newInstanceData: TimerStart = {
      deviceTimestamp: timestamp,
      deviceUuid: THIS_DEVICE_UUID,
      employee: userURL,
      id,
      task: taskURL,
      timer: timerURL,
      url,
    };
    this.props.create(newInstanceData);
  }

  @bind
  handleGoToActive(): void {
    const {currentUserActiveTaskURLArray} = this.props;
    if (currentUserActiveTaskURLArray.length === 0) {
      return;
    }
    if (currentUserActiveTaskURLArray.length === 1) {
      const taskID = urlToId(currentUserActiveTaskURLArray[0]);
      const path = `/task/${taskID}`;
      if (path !== this.props.pathName) {
        this.props.go("/task/:id", {id: taskID});
      }
      return;
    }
    const currentIndex = currentUserActiveTaskURLArray.findIndex((taskURL) => {
      const taskID = urlToId(taskURL);
      const path = `/task/${taskID}`;
      return this.props.pathName === path;
    });
    // currentIndex is -1 on "not found", and in this case we
    // actually want to go to index 0...
    let nextIndex = currentIndex + 1;
    // wrap around
    if (nextIndex >= currentUserActiveTaskURLArray.length) {
      nextIndex = 0;
    }
    const taskID = urlToId(currentUserActiveTaskURLArray[nextIndex]);
    const path = `/task/${taskID}`;
    if (path !== this.props.pathName) {
      this.props.go("/task/:id", {id: taskID});
    }
  }
  render(): JSX.Element {
    const {currentUserActiveTaskURLArray} = this.props;
    const userURL = instanceURL("user", this.props.userID as string);
    const showTimerButton = !!currentUserActiveTaskURLArray.length;
    const {useMode} = this.props;
    const showInitials = useMode === "machine" && !bowser.mobile;
    let timerBlock;
    let initialsBlock;
    if (showTimerButton) {
      timerBlock = (
        <IconButton onClick={this.handleGoToActive}>
          <TimerOutlineIcon color="#fff" />
        </IconButton>
      );
    }
    if (showInitials) {
      const userProfile = this.props.userUserProfileLookup(userURL);
      initialsBlock = (
        <Button color="inherit" variant="text" onClick={this.handleGoToActive}>
          {userProfile ? userProfile.alias : ""}
        </Button>
      );
    }
    return (
      <>
        {initialsBlock}
        {timerBlock}
      </>
    );
  }
}

const WrappedTimerButton = connect<
  TimerButtonStateProps,
  TimerButtonDispatchProps,
  object,
  AppState
>(
  createStructuredSelector<AppState, TimerButtonStateProps>({
    currentUserActiveTaskURLArray: getCurrentUserActiveTaskURLArray,
    customerSettings: getCustomerSettings,
    pathName: getPathName,
    taskLookup: getTaskLookup,
    timerArray: getTimerArray,
    timerLookup: getTimerLookup,
    useMode: getDeviceConfigKey("useMode"),
    userID: getUserId,
    userLookup: getUserLookup,
    userUserProfileLookup: getUserUserProfileLookup,
    workTypeLookup: getWorkTypeLookup,
  }),
  {
    create: actions.create,
    go: actions.go,
  },
)(TimerButton);

export {WrappedTimerButton as TimerButton};
