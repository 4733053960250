import {CustomerUrl, LocationUrl} from "@co-common-libs/resources";
import {OfflineAwareAppBar, SlideUpTransition, TrimTextField} from "@co-frontend-libs/components";
import {
  AppState,
  getCustomerLookup,
  getCustomerSettings,
  getFieldLocationArray,
  getJournalArray,
  getLocationLookup,
  makeSelectCustomerActiveFields,
} from "@co-frontend-libs/redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  IconButton,
  InputBase,
  Toolbar,
} from "@material-ui/core";
import {computeFieldMatchesData} from "app-utils";
import bowser from "bowser";
import CloseIcon from "mdi-react/CloseIcon";
import MapIcon from "mdi-react/MapIcon";
import SearchIcon from "mdi-react/SearchIcon";
import ViewListIcon from "mdi-react/ViewListIcon";
import React, {useCallback, useEffect, useMemo, useReducer, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {useDebounce} from "use-debounce";
import {mapListOther} from "../reducer";
import {SelectionMap} from "../selection-map";
import {
  computeFilteredSortedFieldArray,
  getLocationJournalMap,
  useFieldDialogStyles,
} from "../utils";
import {FieldMultiSelectionList} from "./field-multi-selection-list";

export interface BaseFieldMultiSelectionDialogProps {
  customerURL?: CustomerUrl | null | undefined;
  lastUsedLocations?: ReadonlySet<LocationUrl> | undefined;
  multiSelectMax?: number | undefined;
  onCancel: () => void;
  onOk: () => void;
  onSelect: (identifier: LocationUrl, isSelected: boolean) => void;
  onSelectMultiple: (identifiers: ReadonlySet<LocationUrl>, isSelected: boolean) => void;
  onToggleSelected: (identifier: LocationUrl) => void;
  open: boolean;
  readonlySet?: ReadonlySet<LocationUrl> | undefined;
  selected: ReadonlySet<LocationUrl>;
}

export function BaseFieldMultiSelectionDialog(
  props: BaseFieldMultiSelectionDialogProps,
): JSX.Element {
  const {
    customerURL = null,
    lastUsedLocations,
    multiSelectMax,
    onCancel,
    onOk,
    onSelect,
    onSelectMultiple,
    onToggleSelected,
    open,
    readonlySet,
    selected,
  } = props;
  const intl = useIntl();
  const title = intl.formatMessage({defaultMessage: "Vælg marker"});

  const customerSettings = useSelector(getCustomerSettings);

  const customerLookup = useSelector(getCustomerLookup);
  const locationLookup = useSelector(getLocationLookup);
  const selectCustomerActiveFields = useMemo(makeSelectCustomerActiveFields, []);
  const customerFields = useSelector((state: AppState) =>
    selectCustomerActiveFields(state, customerURL),
  );
  const fieldArray = useSelector(getFieldLocationArray);

  const customerHasFields = customerFields.length > 0;

  const [displayAllFields, setDisplayAllFields] = useState(
    customerSettings.addEditLogLocationSkipsCustomerSelection && !customerHasFields,
  );

  const filteredSortedFieldArray = useMemo(() => {
    if (displayAllFields) {
      return computeFilteredSortedFieldArray(fieldArray, lastUsedLocations);
    } else {
      const displayFields = fieldArray.filter(
        (field) => selected.has(field.url) || field.customer === customerURL,
      );
      return computeFilteredSortedFieldArray(displayFields, lastUsedLocations);
    }
  }, [customerURL, displayAllFields, fieldArray, lastUsedLocations, selected]);

  const searchTitle = intl.formatMessage(
    {defaultMessage: "Søg blandt {amount, number} marker"},
    {amount: filteredSortedFieldArray.length},
  );

  const withIcons = !!lastUsedLocations?.size;

  const [filterString, setFilterString] = useState("");
  useEffect(() => {
    if (!open) {
      setFilterString("");
    }
  }, [open]);

  const handleFilterFieldChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setFilterString(event.target.value);
    },
    [],
  );

  const debounceMilliseconds = 200;
  const [debouncedFilterString] = useDebounce(filterString, debounceMilliseconds);

  const trimmedFilterString = debouncedFilterString.trim();

  const fieldMatchesData = useMemo(
    () =>
      computeFieldMatchesData(
        filteredSortedFieldArray,
        trimmedFilterString,
        intl,
        {
          customerLookup,
          getLocationActiveTasks: null,
          getLocationProducts: null,
          orderLookup: null,
          priceGroupLookup: null,
          workTypeLookup: null,
        },
        customerSettings.fieldDialogOrFiltering ? "OR" : "AND",
      ),
    [
      customerLookup,
      customerSettings.fieldDialogOrFiltering,
      filteredSortedFieldArray,
      intl,
      trimmedFilterString,
    ],
  );

  useEffect((): void => {
    if (open) {
      setDisplayAllFields(
        customerSettings.addEditLogLocationSkipsCustomerSelection && customerFields.length === 0,
      );
    }
  }, [customerFields.length, customerSettings.addEditLogLocationSkipsCustomerSelection, open]);

  const handleFilterFieldKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>): void => {
      if (event.key !== "Enter") {
        return;
      }
      if (fieldMatchesData.length === 1) {
        event.preventDefault();
        onToggleSelected(fieldMatchesData[0].field.url);
      } else if (trimmedFilterString) {
        const lowerFilterString = trimmedFilterString.trim().toLocaleLowerCase();
        const dataMatch = fieldMatchesData.find(
          (entry) => entry.field.fieldNumber.toLocaleLowerCase() === lowerFilterString,
        );
        if (dataMatch) {
          event.preventDefault();
          onToggleSelected(dataMatch.field.url);
        }
      }
    },
    [fieldMatchesData, onToggleSelected, trimmedFilterString],
  );

  const classes = useFieldDialogStyles();

  const fullscreenLayout = !!(bowser.mobile || bowser.tablet);

  const journalArray = useSelector(getJournalArray);
  const locationJournalMap = useMemo(
    () => getLocationJournalMap(journalArray, customerURL),
    [customerURL, journalArray],
  );

  const bottomLeft: React.CSSProperties = {
    bottom: 23,
    left: 23,
    position: "fixed",
  };

  const [dialogMode, handleMapTypeChange] = useReducer(mapListOther, "MAP");

  useEffect(() => {
    if (open && customerURL && customerSettings.addEditLogLocationSkipsCustomerSelection) {
      for (const locationUrl of Array.from(selected)) {
        const location = locationLookup(locationUrl);
        if (location?.geojson && location.customer !== customerURL) {
          setDisplayAllFields(true);
          break;
        }
      }
    }
    // only auto-change when dialog changes from closed to open;
    // not on changes while open
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleDisplayAllFieldsChange = customerSettings.addEditLogLocationSkipsCustomerSelection
    ? setDisplayAllFields
    : undefined;

  if (fullscreenLayout) {
    return (
      <Dialog fullScreen open={open} TransitionComponent={SlideUpTransition} onClose={onCancel}>
        <OfflineAwareAppBar className={classes.appBar}>
          <Toolbar>
            <IconButton color="inherit" edge="start" onClick={onCancel}>
              <CloseIcon />
            </IconButton>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                classes={{
                  input: classes.inputInput,
                  root: classes.inputRoot,
                }}
                placeholder={searchTitle}
                value={filterString}
                onChange={handleFilterFieldChange}
                onKeyDown={handleFilterFieldKeyDown}
              />
            </div>
            <Button color="inherit" onClick={onOk}>
              <FormattedMessage defaultMessage="OK" />
            </Button>
          </Toolbar>
        </OfflineAwareAppBar>
        <Toolbar />
        {dialogMode === "LIST" ? (
          <FieldMultiSelectionList
            fullscreenLayout
            displayAllFields={displayAllFields}
            entries={fieldMatchesData}
            isSearchResult={!!trimmedFilterString}
            lastUsedLocations={lastUsedLocations}
            locationJournalMap={locationJournalMap}
            multiSelectMax={multiSelectMax}
            readonlySet={readonlySet}
            selected={selected}
            withIcons={withIcons}
            onDisplayAllFieldsChange={handleDisplayAllFieldsChange}
            onSelect={onSelect}
            onSelectMultiple={onSelectMultiple}
          />
        ) : (
          <SelectionMap
            fullscreenLayout
            showTotalSelected
            customerHasFields={customerHasFields}
            displayAllFields={displayAllFields}
            entries={fieldMatchesData}
            readonlySet={readonlySet}
            selected={selected}
            onDisplayAllFieldsChange={handleDisplayAllFieldsChange}
            onSelect={onSelect}
          />
        )}
        <Fab style={bottomLeft} onClick={handleMapTypeChange}>
          {dialogMode === "LIST" ? <MapIcon /> : <ViewListIcon />}
        </Fab>
      </Dialog>
    );
  } else {
    return (
      <Dialog fullWidth maxWidth="md" open={open} scroll="paper" onClose={onCancel}>
        <DialogTitle>
          {title}
          <TrimTextField
            autoFocus
            fullWidth
            margin="dense"
            placeholder={searchTitle}
            value={filterString}
            variant="outlined"
            onChange={setFilterString}
            onKeyDown={handleFilterFieldKeyDown}
          />
        </DialogTitle>
        <DialogContent dividers className={classes.noPadding}>
          {dialogMode === "LIST" ? (
            <FieldMultiSelectionList
              displayAllFields={displayAllFields}
              entries={fieldMatchesData}
              isSearchResult={!!trimmedFilterString}
              lastUsedLocations={lastUsedLocations}
              locationJournalMap={locationJournalMap}
              multiSelectMax={multiSelectMax}
              readonlySet={readonlySet}
              selected={selected}
              withIcons={withIcons}
              onDisplayAllFieldsChange={handleDisplayAllFieldsChange}
              onSelect={onSelect}
              onSelectMultiple={onSelectMultiple}
            />
          ) : (
            <SelectionMap
              showTotalSelected
              customerHasFields={customerHasFields}
              displayAllFields={displayAllFields}
              entries={fieldMatchesData}
              fullscreenLayout={false}
              readonlySet={readonlySet}
              selected={selected}
              onDisplayAllFieldsChange={handleDisplayAllFieldsChange}
              onSelect={onSelect}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Fab size="small" style={{left: 5, position: "absolute"}} onClick={handleMapTypeChange}>
            {dialogMode === "LIST" ? <MapIcon /> : <ViewListIcon />}
          </Fab>
          <Button color="primary" onClick={onCancel}>
            <FormattedMessage defaultMessage="Fortryd" />
          </Button>
          <Button color="primary" onClick={onOk}>
            <FormattedMessage defaultMessage="OK" />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
