import {UserUrl} from "@co-common-libs/resources";
import {
  getCurrentRole,
  getUserLookup,
  getUserRoleLookup,
  getUserUserProfileLookup,
} from "@co-frontend-libs/redux";
import {Grid} from "@material-ui/core";
import bowser from "bowser";
import React from "react";
import {useSelector} from "react-redux";
import {ConsultantCard} from "./consultant-card";
import {RoleCard} from "./role-card";
import {SetupCard} from "./setup-card";

export const SetupTab = React.memo(function SetupTab({
  userUrl,
}: {
  userUrl: UserUrl;
}): JSX.Element | null {
  const padding = bowser.mobile || bowser.tablet ? "1em 11px 1em 11px" : "1em";
  const userLookup = useSelector(getUserLookup);
  const userUserProfileLookup = useSelector(getUserUserProfileLookup);
  const userRoleLookup = useSelector(getUserRoleLookup);
  const currentRole = useSelector(getCurrentRole);
  const user = userLookup(userUrl);
  const userProfile = userUserProfileLookup(userUrl);
  const userRoles = userRoleLookup(userUrl);
  const userIsConsultant = !!(currentRole && currentRole.consultant);

  if (!user || !userProfile || !userRoles) {
    return null;
  }
  return (
    <div style={{padding}}>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <SetupCard user={user} userProfile={userProfile} userRoles={userRoles} />
        </Grid>
        {userIsConsultant ? (
          <Grid item sm={6} xs={12}>
            <ConsultantCard userProfile={userProfile} userRoles={userRoles} />
          </Grid>
        ) : null}
        <Grid item sm={6} xs={12}>
          <RoleCard userProfile={userProfile} userRoles={userRoles} />
        </Grid>
      </Grid>
    </div>
  );
});
