import {getCommitDB, getOfflineDB} from "@co-frontend-libs/db-resources";
import {
  closeAuthenticationDatabase,
  closeDeviceConfigDatabase,
  deleteAuthenticationDatabase,
  deleteDeviceConfigDatabase,
} from "@co-frontend-libs/redux";
import {clearAppJS} from "@co-frontend-libs/utils";
import {BroadcastChannel} from "broadcast-channel";
import {sendCommitQueueToSentry} from "./send-to-sentry";

const channel = window.cordova ? null : new BroadcastChannel("clear-app");

const CLEAR_START = "CLEAR_START";
const CLEAR_DONE = "CLEAR_DONE";

if (channel) {
  channel.onmessage = async (msg) => {
    if (msg === CLEAR_START) {
      // eslint-disable-next-line no-console
      console.log("Clear from other tab...");
      // eslint-disable-next-line promise/catch-or-return
      await getCommitDB().then((commitDB) => commitDB._closeConnection());
      await closeAuthenticationDatabase();
      await closeDeviceConfigDatabase();
      // eslint-disable-next-line promise/catch-or-return
      await getOfflineDB().then((offlineDB) => offlineDB._closeConnection());
    } else if (msg === CLEAR_DONE) {
      // eslint-disable-next-line no-console
      console.log("Clear from other tab...done");
      window.location.reload();
    } else {
      // eslint-disable-next-line no-console
      console.warn(`Unknown message: ${msg}`);
    }
  };
}

export async function clearApp(): Promise<void> {
  if (channel) {
    channel.postMessage(CLEAR_START);
  }
  sendCommitQueueToSentry("request clear with data", "warning");

  const commitDB = await getCommitDB();
  // eslint-disable-next-line no-console
  console.log("closing commit database");
  await commitDB._closeConnection();
  // eslint-disable-next-line no-console
  console.log("deleting commit database");
  await commitDB._deleteDatabase();
  await deleteAuthenticationDatabase();
  await deleteDeviceConfigDatabase();
  const offlineDB = await getOfflineDB();
  // eslint-disable-next-line no-console
  console.log("closing offline database");
  await offlineDB._closeConnection();
  // eslint-disable-next-line no-console
  console.log("deleting offline database");
  await offlineDB._deleteDatabase();
  if (window.cordova) {
    await clearAppJS();
  }
  if (channel) {
    channel.postMessage(CLEAR_DONE);
  }
  // eslint-disable-next-line require-atomic-updates
  window.location.href = window.location.href.split("#")[0];
  window.location.reload();
}
