import {
  Patch,
  PatchUnion,
  PriceGroup,
  PriceGroupUrl,
  PriceItem,
  PriceItemUrl,
  Role,
  Settings,
  Unit,
  UnitUrl,
  WorkType,
  WorkTypeUrl,
  urlToId,
} from "@co-common-libs/resources";
import {getUnitCode, getWorkTypeString} from "@co-common-libs/resources-utils";
import {setsSameMembers} from "@co-common-libs/utils";
import {
  MultipleWorkTypesDialog,
  VerticalStackingFloatingActionButton,
} from "@co-frontend-libs/components";
import {
  AppState,
  PathTemplate,
  actions,
  getCurrentRole,
  getPriceGroupLookup,
  getPriceItemLookup,
  getSettingsArray,
  getUnitLookup,
  getWorkTypeArray,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {jsonFetch} from "@co-frontend-libs/utils";
import {CircularProgress, Paper} from "@material-ui/core";
import {MenuToolbar, PageLayout} from "app-components";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import {globalConfig, instanceURL} from "frontend-global-config";
import _ from "lodash";
import FilterIcon from "mdi-react/FilterIcon";
import React from "react";
// Allowed for existing code...
// eslint-disable-next-line deprecate/import
import {Cell, Grid} from "react-flexr";
import {IntlContext, defineMessages} from "react-intl";
import {connect} from "react-redux";
import {Cell as ChartCell, Label, Legend, Pie, PieChart, ResponsiveContainer} from "recharts";
import {createStructuredSelector} from "reselect";

const messages = defineMessages({
  completed: {
    defaultMessage: "Udført",
    id: "work-type-status-entry.label.completed",
  },
  missing: {
    defaultMessage: "Mangler",
    id: "work-type-status-entry.label.missing",
  },
  notPlanned: {
    defaultMessage: "Udført, ej budgetlagt",
    id: "work-type-status-entry.label.not-planned",
  },
  onlineArchiveError: {
    defaultMessage: "Fejl ved adgang til arkiv",
    id: "customer-instance.label.archive-error",
  },
  onlineArchiveWaiting: {
    defaultMessage: "Henter fra arkiv",
    id: "customer-instance.label.archive-waiting",
  },
  total: {
    defaultMessage: "TOTAL",
    id: "work-type-status-entry.label.total",
  },
  worktypeStatus: {
    defaultMessage: "Områdestatus",
    id: "work-type-status-entry.label.worktypeStatus",
  },
});

const RADIAN = Math.PI / 180;

interface LabelFormatterProps {
  cx?: number;
  cy?: number;
  index?: number;
  innerRadius?: number;
  midAngle?: number;
  outerRadius?: number;
  percent?: number;
  unit: string;
  value?: number;
}

class LabelFormatter extends PureComponent<LabelFormatterProps> {
  render(): JSX.Element {
    const {cx, cy, innerRadius, midAngle, outerRadius, percent, unit, value} = this.props;
    const radius = (innerRadius || 0) + 10 + ((outerRadius || 0) - (innerRadius || 0)) * 1;
    const x = (cx || 0) + radius * Math.cos(-(midAngle || 0) * RADIAN);
    const y = (cy || 0) + radius * Math.sin(-(midAngle || 0) * RADIAN);

    const realPercent = (percent || 0) * 100;

    return (
      <text
        dominantBaseline="central"
        fill="#000"
        textAnchor={x > (cx || 0) ? "start" : "end"}
        x={x}
        y={y}
      >
        <tspan x={x}>
          {Math.round(value || 0)} {unit}
        </tspan>
        <tspan dy={15} x={x}>
          {Math.round(realPercent)} %
        </tspan>
      </text>
    );
  }
}

interface ContentFormatterProps {
  expected?: number;
  unit?: string;
  viewBox?: {cx?: number; cy?: number};
}

class ContentFormatter extends PureComponent<ContentFormatterProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {expected, unit, viewBox} = this.props;
    const cx = (viewBox && viewBox.cx) || 0;
    const cy = (viewBox && viewBox.cy) || 0;

    return (
      <text dominantBaseline="central" fill="#000" textAnchor={"middle"} x={cx} y={cy - 10}>
        <tspan x={cx}>{formatMessage(messages.total)}</tspan>
        <tspan dy={15} x={cx}>
          {Math.round(expected || 0)} {unit}
        </tspan>
      </text>
    );
  }
}

interface WorkTypeGaugeCardProps {
  completed: number;
  expected: number;
  onClick: (worktype: string, unitId: string) => void;
  overBudget: number;
  title: string;
  unit: string;
  unitId: string;
  worktype: string;
}

class WorkTypeGaugeCard extends PureComponent<WorkTypeGaugeCardProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  @bind
  handleClick(): void {
    this.props.onClick(this.props.worktype, this.props.unitId);
  }

  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {completed, expected, overBudget, title, unit} = this.props;
    const missing = expected - completed;

    const decimals = 2;

    const pieData = [
      {
        name: formatMessage(messages.completed),
        value: _.round(completed, decimals),
      },
      {
        name: formatMessage(messages.missing),
        value: _.round(missing, decimals),
      },
    ];

    if (overBudget > 0) {
      pieData.push({
        name: formatMessage(messages.notPlanned),
        value: _.round(overBudget, decimals),
      });
    }

    return (
      <Paper
        style={{
          cursor: "pointer",
          margin: 5,
          padding: 10,
        }}
        onClick={this.handleClick}
      >
        <h4 style={{textAlign: "center"}}>{title}</h4>
        <ResponsiveContainer debounce={200} height={250} minWidth={200} width="100%">
          <PieChart height={250} width={200}>
            <Pie
              data={pieData}
              dataKey="value"
              innerRadius={40}
              label={<LabelFormatter unit={unit} />}
              labelLine={false}
              outerRadius={60}
            >
              <ChartCell fill="#73af4a" />
              <ChartCell fill="#c0c0c0" />
              <ChartCell fill="yellow" />
              <Label
                content={
                  <ContentFormatter
                    expected={_.round(expected + overBudget, decimals)}
                    unit={unit}
                  />
                }
                position="center"
              />
            </Pie>
            <Legend height={36} verticalAlign="bottom" />
          </PieChart>
        </ResponsiveContainer>
      </Paper>
    );
  }
}

function getWorkTypesFromSetting(settingsArray: readonly Settings[]): Set<WorkTypeUrl> {
  const settings = settingsArray ? settingsArray[0] : null;
  const nextFilter = settings ? settings.workTypeStatusFilter : null;
  return nextFilter ? new Set(nextFilter) : new Set();
}

interface WorkTypeStatusStateProps {
  currentRole: Role | null;
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined;
  settingsArray: readonly Settings[];
  unitLookup: (url: UnitUrl) => Unit | undefined;
  workTypeArray: readonly WorkType[];
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
}

interface WorkTypeStatusDispatchProps {
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  update: (url: string, patch: PatchUnion) => void;
}

interface WorkTypeStatusOwnProps {
  onMenuButton: (event: React.MouseEvent) => void;
}

type WorkTypeStatusProps = WorkTypeStatusDispatchProps &
  WorkTypeStatusOwnProps &
  WorkTypeStatusStateProps;

interface WorkTypeStatusState {
  fetching: boolean;
  graphData: {
    [workTypeIdAndUnitId: string]: {
      [customerId: string]: {
        amount: number;
        completed: boolean;
        customer: string;
        expected: number;
        oexpectedAmount: string;
        unit: string;
        worktype: string;
      };
    };
  };
  workTypeDialogOpen: boolean;
  workTypes: ReadonlySet<WorkTypeUrl>;
}

class WorkTypeStatus extends PureComponent<WorkTypeStatusProps, WorkTypeStatusState> {
  state: WorkTypeStatusState = {
    fetching: false,
    graphData: {},
    workTypeDialogOpen: false,
    workTypes: getWorkTypesFromSetting(this.props.settingsArray),
  };

  UNSAFE_componentWillMount(): void {
    this.fetch(new Date().getFullYear(), this.state.workTypes);
  }

  UNSAFE_componentWillReceiveProps(nextProps: WorkTypeStatusProps): void {
    const nextFilter = getWorkTypesFromSetting(nextProps.settingsArray);

    if (!setsSameMembers(nextFilter, this.state.workTypes)) {
      this.setState({workTypes: nextFilter});
      this.fetch(new Date().getFullYear(), nextFilter);
    }
  }

  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  fetch(year: number, workTypes: ReadonlySet<string>): void {
    const {baseURL} = globalConfig.resources;
    const url = `${baseURL}work_type_status`;
    this.setState({fetching: true});
    jsonFetch(url, "POST", {
      workTypes: Array.from(workTypes).map((workTypeURL) => urlToId(workTypeURL)),
      year,
    })
      .then((response) => {
        this.setState({
          fetching: false,
          graphData: response.data,
        });
        return;
      })
      .catch(() => {
        this.setState({
          fetching: false,
        });
      });
  }

  @bind
  handleFabButton(): void {
    this.setState({workTypeDialogOpen: true});
  }

  @bind
  handleWorkTypeDialogCancel(): void {
    this.setState({workTypeDialogOpen: false});
  }

  @bind
  handleWorkTypeDialogOk(workTypes: ReadonlySet<WorkTypeUrl>): void {
    this.setState({workTypeDialogOpen: false, workTypes});
    const settings = this.props.settingsArray[0];
    const {workTypeStatusFilter} = settings;
    if (!setsSameMembers(workTypes, new Set(workTypeStatusFilter))) {
      this.fetch(new Date().getFullYear(), workTypes);
      const patch: Patch<Settings> = [
        {
          member: "workTypeStatusFilter",
          value: Array.from(workTypes),
        },
      ];
      this.props.update(settings.url, patch);
    }
  }

  @bind
  handleClick(worktype: string, unit: string): void {
    this.props.go("/worktypeStatus/:id/:unit", {id: worktype, unit});
  }

  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {currentRole, unitLookup} = this.props;
    const userIsManager = !!(currentRole && currentRole.manager);

    let content;
    if (this.state.fetching) {
      content = (
        <Cell palm="12/12">
          <div style={{padding: 8, textAlign: "center"}}>
            <div>{formatMessage(messages.onlineArchiveWaiting)}</div>
            <CircularProgress />
          </div>
        </Cell>
      );
    } else {
      const data: {
        [graphIdentifier: string]: {
          completed: number;
          expected: number;
          overBudget: number;
          title: string;
          unit: string;
          unitId: string;
          worktype: string;
        };
      } = {};
      Object.keys(this.state.graphData).forEach((identifier) => {
        const expectedAmounts = this.state.graphData[identifier];

        let worktypeName: string | undefined;
        let unit: string | undefined;
        Object.keys(expectedAmounts).forEach((customerId) => {
          const expectedAmount = expectedAmounts[customerId];
          if (!worktypeName) {
            const worktypeURL = instanceURL("workType", expectedAmount.worktype);
            const worktype = this.props.workTypeLookup(worktypeURL);
            worktypeName = getWorkTypeString(worktype);
          }
          if (!unit) {
            const unitInstance = this.props.unitLookup(instanceURL("unit", expectedAmount.unit));
            unit = (unitInstance && unitInstance.name) || "";
          }
          const graphIdentifier = `${worktypeName}-${unit}`;
          let overBudget = !expectedAmount.completed
            ? expectedAmount.amount - expectedAmount.expected
            : 0;
          overBudget = overBudget > 0 ? overBudget : 0;

          const completed = expectedAmount.amount - overBudget;

          const expected = expectedAmount.completed ? completed : expectedAmount.expected;

          if (data[graphIdentifier] === undefined) {
            data[graphIdentifier] = {
              completed,
              expected,
              overBudget,
              title: worktypeName,
              unit,
              unitId: expectedAmount.unit,
              worktype: expectedAmount.worktype,
            };
          } else {
            data[graphIdentifier].completed += completed;
            data[graphIdentifier].expected += expected;
            data[graphIdentifier].overBudget += overBudget;
          }
        });
      });
      content = [];
      const dataKeys = Object.keys(data);
      dataKeys.sort();
      dataKeys.forEach((key) => {
        const expectedAmount = data[key];
        if (
          !this.state.workTypes.size ||
          this.state.workTypes.has(instanceURL("workType", expectedAmount.worktype))
        ) {
          content.push(
            <Cell key={key} desk="4/12" lap="4/12" palm="12/12">
              <WorkTypeGaugeCard
                completed={expectedAmount.completed}
                expected={expectedAmount.expected}
                overBudget={expectedAmount.overBudget}
                title={expectedAmount.title}
                unit={expectedAmount.unit}
                unitId={expectedAmount.unitId}
                worktype={expectedAmount.worktype}
                onClick={this.handleClick}
              />
            </Cell>,
          );
        }
      });
    }

    const validWorktypes = this.props.workTypeArray.filter(
      (workType) =>
        workType.active &&
        workType.pricegroups.some((selectedPriceGroupURL) => {
          const priceGroup = this.props.priceGroupLookup(selectedPriceGroupURL);

          return (
            priceGroup &&
            priceGroup.priceGroupItemSet.some((priceGroupItem) => {
              const priceItem = this.props.priceItemLookup(priceGroupItem.priceItem);

              if (!priceItem) {
                return false;
              }
              const unit = getUnitCode(priceItem, unitLookup);
              return !unit.toLowerCase().includes("tim");
            })
          );
        }),
    );
    const workTypeDialog = (
      <MultipleWorkTypesDialog
        key="work-type-dialog"
        currentUserURL=""
        disabledWorkTypes={[]}
        includeSelectAll={false}
        open={!!this.state.workTypeDialogOpen}
        selected={this.state.workTypes}
        suggestRecentlyUsedWorkTypes={false}
        taskArray={[]}
        workTypeArray={validWorktypes}
        onCancel={this.handleWorkTypeDialogCancel}
        onOk={this.handleWorkTypeDialogOk}
      />
    );

    let action;
    if (userIsManager) {
      action = (
        <VerticalStackingFloatingActionButton stackIndex={0} onClick={this.handleFabButton}>
          <FilterIcon />
        </VerticalStackingFloatingActionButton>
      );
    }

    return (
      <PageLayout
        withBottomScrollPadding
        dialogs={workTypeDialog}
        floatingActionButton={action}
        toolbar={
          <MenuToolbar
            title={formatMessage(messages.worktypeStatus)}
            onMenuButton={this.props.onMenuButton}
          />
        }
      >
        <Grid>{content}</Grid>
      </PageLayout>
    );
  }
}

const ConnectedWorkTypeStatus: React.ComponentType<WorkTypeStatusOwnProps> = connect<
  WorkTypeStatusStateProps,
  WorkTypeStatusDispatchProps,
  WorkTypeStatusOwnProps,
  AppState
>(
  createStructuredSelector<AppState, WorkTypeStatusStateProps>({
    currentRole: getCurrentRole,
    priceGroupLookup: getPriceGroupLookup,
    priceItemLookup: getPriceItemLookup,
    settingsArray: getSettingsArray,
    unitLookup: getUnitLookup,
    workTypeArray: getWorkTypeArray,
    workTypeLookup: getWorkTypeLookup,
  }),
  {
    go: actions.go,
    update: actions.update,
  },
)(WorkTypeStatus);

export {ConnectedWorkTypeStatus as WorkTypeStatus};
