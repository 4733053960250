import {DeleteDialog, SingleTextFieldDialog, useModal} from "@co-frontend-libs/components";
import {actions, getShareToken} from "@co-frontend-libs/redux";
import {IconButton, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import DeleteIcon from "mdi-react/DeleteIcon";
import FileIcon from "mdi-react/FileIcon";
import PencilIcon from "mdi-react/PencilIcon";
import React, {useCallback} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {InstanceClickIconButton} from "../instance-click-icon-button";
import {File, FileWithVisibleToAll} from "./types";
import {VisibleToAllCheckbox} from "./visible-to-all-checkbox";

function hasVisibleToAll(file: File): boolean {
  return Object.hasOwn(file, "visibleToAll");
}

const ICON_WIDTH = 48;
const PADDING = 24;
const VISIBLE_TO_ALL_COLUMN_WIDTH = 120;

interface FileTableRowProps {
  editMode: boolean;
  file: File;
  onDeleteClicked: (file: File) => void;
  onRenameClicked: (file: File) => void;
  showVisibleToAllColumn: boolean;
}

export function FileTableRow(props: FileTableRowProps): JSX.Element {
  const {editMode, file, onDeleteClicked, onRenameClicked, showVisibleToAllColumn} = props;

  const ICON_COLUMN_WIDTH = ICON_WIDTH + 2 * PADDING + (editMode ? 2 * ICON_WIDTH : 0);

  const shareToken = useSelector(getShareToken);
  const handleDelete = useCallback(() => onDeleteClicked(file), [onDeleteClicked, file]);

  const handleRename = useCallback(() => {
    onRenameClicked(file);
  }, [onRenameClicked, file]);

  return (
    <TableRow key={file.url}>
      <TableCell>{file.name}</TableCell>
      {editMode && showVisibleToAllColumn ? (
        <TableCell style={{width: VISIBLE_TO_ALL_COLUMN_WIDTH}}>
          {hasVisibleToAll(file) && <VisibleToAllCheckbox file={file as FileWithVisibleToAll} />}
        </TableCell>
      ) : null}
      <TableCell style={{width: ICON_COLUMN_WIDTH}}>
        {editMode ? (
          <>
            <InstanceClickIconButton instance={file.url} onClick={handleRename}>
              <PencilIcon />
            </InstanceClickIconButton>
            <InstanceClickIconButton instance={file.url} onClick={handleDelete}>
              <DeleteIcon />
            </InstanceClickIconButton>
          </>
        ) : null}
        <IconButton color="primary" href={`${file.download}?token=${shareToken}`} target="_blank">
          <FileIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

interface FileTableProps {
  currentUserCanManageFiles: boolean;
  editMode: boolean;
  files: File[];
}

export function FileTable(props: FileTableProps): React.JSX.Element | null {
  const dispatch = useDispatch();
  const intl = useIntl();

  const {files} = props;
  const editMode = props.editMode && props.currentUserCanManageFiles;
  const [deleteDialog, promptForDelete] = useModal(DeleteDialog);
  const [nameDialog, promptForRename] = useModal(SingleTextFieldDialog);

  const handleRename = useCallback(
    async (file: File) => {
      const newName = await promptForRename({
        label: <FormattedMessage defaultMessage="Navn" />,
        title: intl.formatMessage({defaultMessage: "Omdøb fil"}),
        value: file.name,
      });
      if (newName !== undefined) {
        dispatch(actions.update(file.url, [{member: "name", value: newName}]));
      }
    },
    [promptForRename, dispatch, intl],
  );

  const handleDelete = useCallback(
    async (file: File) => {
      const confirmed = await promptForDelete({
        children: <FormattedMessage defaultMessage="Sikker på at du vil slette filen?" />,
      });
      if (confirmed) {
        dispatch(actions.remove(file.url));
      }
    },
    [promptForDelete, dispatch],
  );

  if (!files.length) {
    if (editMode) {
      return (
        <div style={{padding: 8, textAlign: "center"}}>
          <FormattedMessage defaultMessage="Ingen filer" />
        </div>
      );
    } else {
      return null;
    }
  }

  const showVisibleToAllColumn = editMode || (files.length > 0 && files.some(hasVisibleToAll));

  const tableRowList = files.map((file) => {
    return (
      <FileTableRow
        key={file.url}
        editMode={editMode}
        file={file}
        showVisibleToAllColumn={showVisibleToAllColumn}
        onDeleteClicked={handleDelete}
        onRenameClicked={handleRename}
      />
    );
  });

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage defaultMessage="Navn" />
            </TableCell>
            {showVisibleToAllColumn ? (
              <TableCell style={{width: VISIBLE_TO_ALL_COLUMN_WIDTH}}>
                <FormattedMessage defaultMessage="Synlig for alle" />
              </TableCell>
            ) : null}
            <TableCell style={{textAlign: "end"}}>
              <FormattedMessage defaultMessage="Download" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{tableRowList}</TableBody>
      </Table>
      {deleteDialog}
      {nameDialog}
    </>
  );
}
