import {intervalsFromTimerStarts} from "./intervals-from-timerstarts";
import {SimpleTimerStart, TimerInterval} from "./types";

function dropNoTimerIntervals(intervals: readonly TimerInterval[]): readonly TimerInterval[] {
  return intervals.filter((interval) => interval.timer);
}

/**
 * Combine several `TimerInterval`-sequences that may overlap such that,
 * in case of overlap, the entry for the first sequence containing the
 * overlapping time applies, and the output sequence has no overlaps.
 *
 * @param {...ReadonlyArray<TimerInterval>[]} intervalsInOverrideOrder
 *   Interval sequences to combine.
 */
export function mergeIntervals(
  ...intervalsInOverrideOrderArgs: readonly (readonly TimerInterval[])[]
): readonly TimerInterval[] {
  const intervalsInOverrideOrder = intervalsInOverrideOrderArgs.filter(
    (intervals) => intervals.length > 0,
  );
  if (intervalsInOverrideOrder.length === 0) {
    return [];
  } else if (intervalsInOverrideOrder.length === 1) {
    return dropNoTimerIntervals(intervalsInOverrideOrder[0]);
  }
  const timestampSet = new Set<string>();
  for (let j = 0; j < intervalsInOverrideOrder.length; j += 1) {
    const intervals = intervalsInOverrideOrder[j];
    for (let i = 0; i < intervals.length; i += 1) {
      const interval = intervals[i];
      timestampSet.add(interval.fromTimestamp);
      timestampSet.add(interval.toTimestamp);
    }
  }
  const timestampArray = Array.from(timestampSet);
  timestampArray.sort();

  const timerStarts: SimpleTimerStart[] = timestampArray.map((timestamp) => {
    for (let j = 0; j < intervalsInOverrideOrder.length; j += 1) {
      // any overlapping manager corrections?
      // any overlapping machine operator corrections?
      // any everlapping computed intervals?
      const intervals = intervalsInOverrideOrder[j];
      for (let i = 0; i < intervals.length; i += 1) {
        const interval = intervals[i];
        if (interval.toTimestamp <= timestamp) {
          continue;
        }
        console.assert(interval.toTimestamp > timestamp);
        if (interval.fromTimestamp <= timestamp) {
          return {deviceTimestamp: timestamp, timer: interval.timer};
        } else {
          break;
        }
      }
    }
    // nothing here; this must match a "stop"
    return {deviceTimestamp: timestamp, timer: null};
  });
  return intervalsFromTimerStarts(timerStarts);
}
