import {Customer} from "@co-common-libs/resources";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {useResettingState} from "@co-frontend-libs/utils";
import {Box, DialogContent, FormControlLabel, Grid, Radio, RadioGroup} from "@material-ui/core";
import {useEventTargetValueCallback} from "app-utils";
import {SPACING} from "frontend-global-config";
import React, {useCallback} from "react";
import {FormattedMessage, useIntl} from "react-intl";

enum Choice {
  CHANGE = "change",
  MERGE = "merge",
}

interface ChangeOrMergeCustomerQuestionDialogProps {
  allowChangeCurrentTaskOnly: boolean;
  fromCustomer: Customer;
  onCancel: () => void;
  onChange: () => void;
  onMerge: () => void;
  open: boolean;
  taskCount: number;
  toCustomer: Customer;
}

export const ChangeOrMergeCustomerQuestionDialog = React.memo(
  function ChangeOrMergeCustomerQuestionDialog(
    props: ChangeOrMergeCustomerQuestionDialogProps,
  ): JSX.Element {
    const {
      allowChangeCurrentTaskOnly,
      fromCustomer,
      onCancel,
      onChange,
      onMerge,
      open,
      taskCount,
      toCustomer,
    } = props;

    const [choice, setChoice] = useResettingState<Choice>(Choice.MERGE, open);

    const intl = useIntl();

    const handleChange = useEventTargetValueCallback(
      (value: string) => {
        setChoice(value as Choice);
      },
      [setChoice],
    );

    const handleOk = useCallback(() => {
      if (choice === Choice.MERGE) {
        onMerge();
      } else {
        onChange();
      }
    }, [choice, onChange, onMerge]);

    return (
      <ResponsiveDialog
        okLabel={intl.formatMessage({defaultMessage: "Skift kunde"})}
        open={open}
        title={intl.formatMessage({defaultMessage: "Skift kunde"})}
        onCancel={onCancel}
        onOk={handleOk}
      >
        <DialogContent>
          <Grid container direction="column" spacing={SPACING.SMALL}>
            <Grid container item direction="row">
              <Grid item xs style={{maxWidth: 50}}>
                <Box fontWeight="fontWeightBold">
                  <FormattedMessage defaultMessage="Fra:" />
                </Box>
              </Grid>
              <Grid item xs>
                {fromCustomer.name}
              </Grid>
            </Grid>
            <Grid container item direction="row">
              <Grid item xs style={{maxWidth: 50}}>
                <Box fontWeight="fontWeightBold">
                  <FormattedMessage defaultMessage="Til:" />
                </Box>
              </Grid>
              <Grid item xs>
                {toCustomer.name}
              </Grid>
            </Grid>
            <Grid item xs>
              <FormattedMessage
                defaultMessage="Kunden ({name}) bruges på flere opgaver. Hvad skal der skiftes kunde på?"
                values={{name: fromCustomer.name}}
              />
            </Grid>
            <Grid item xs>
              <RadioGroup name="change-or-merge-customer" value={choice} onChange={handleChange}>
                <Grid item xs style={{flexShrink: 1}}>
                  <FormControlLabel
                    control={<Radio />}
                    label={intl.formatMessage(
                      {defaultMessage: "Alle opgaver ({taskCount})"},
                      {taskCount},
                    )}
                    value={Choice.MERGE}
                  />
                </Grid>
                <Grid item xs style={{flexShrink: 1}}>
                  <FormControlLabel
                    control={<Radio />}
                    disabled={!allowChangeCurrentTaskOnly}
                    label={intl.formatMessage({
                      defaultMessage: "Kun aktuelle",
                    })}
                    value={Choice.CHANGE}
                  />
                </Grid>
              </RadioGroup>
            </Grid>
          </Grid>
        </DialogContent>
      </ResponsiveDialog>
    );
  },
);
