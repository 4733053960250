import {Command} from "@co-common-libs/resources";
import {getFrontendSentry} from "@co-frontend-libs/utils";
import {Draft} from "@reduxjs/toolkit";
import _ from "lodash";
import {loadCommit} from "../actions";
import {ResourcesState} from "../types";

export function handleLoadCommitFulfilled(
  state: Draft<ResourcesState>,
  action: ReturnType<typeof loadCommit.fulfilled>,
): void {
  console.assert(!state.commitLoaded);
  console.assert(state.commitQueue.every((entry) => entry.id === 0 && entry.status === "UNSAVED"));
  state.commitLoaded = true;
  const sortedPayload = _.sortBy(action.payload, (entry) => entry.id);
  const pendingIdOffset = (_.last(sortedPayload)?.id || 0) + 1;
  state.commitQueue.forEach((entry, index) => {
    entry.id = index + pendingIdOffset;
  });
  state.commitQueue.unshift(
    ...sortedPayload.map(
      ({apiVersion, command, error, errorTimestamp, frontendVersion, id}) =>
        ({
          apiVersion,
          command: command as Draft<Command>,
          error,
          errorTimestamp,
          frontendVersion,
          id,
          requestId: null,
          status: "SAVED_LOCALLY",
        }) as const,
    ),
  );
}

export function handleLoadCommitRejected(
  state: Draft<ResourcesState>,
  action: ReturnType<typeof loadCommit.rejected>,
): void {
  console.assert(!state.commitLoaded);
  console.assert(!state.commitQueue.length);
  const sentry = getFrontendSentry();
  sentry.withScope((scope) => {
    scope.setTag("action", "handleLoadCommitRejected");
    sentry.captureMessage("Local DB Error", "error");
  });
  state.localDBError = action.payload || (action.error as any);
}
