import {Config} from "@co-common-libs/config";
import {
  DaysAbsence,
  DaysAbsenceUrl,
  HoursAbsence,
  HoursAbsenceUrl,
  Role,
} from "@co-common-libs/resources";
import ImmutableDate from "bloody-immutable-date";
import React from "react";
import {AbsenceBlockNote} from "./absence-block-note";
import {AbsenceBlockTitle} from "./absence-block-title";
import {USER_COLUMN_WIDTH} from "./constants";
import {calculateYPosition} from "./utils";

interface AbsenceBlockProps {
  absence: DaysAbsence | HoursAbsence;
  calendarFromTimestamp: ImmutableDate;
  calendarToTimestamp: ImmutableDate;
  currentRole: Role | null;
  currentUserURL: string | null;
  customerSettings: Config;
  fromTimestamp: ImmutableDate;
  onEdit?: ((url: DaysAbsenceUrl | HoursAbsenceUrl) => void) | undefined;
  toTimestamp: ImmutableDate;
}

export const AbsenceBlock = React.memo(function AbsenceBlock(props: AbsenceBlockProps) {
  const {
    absence,
    calendarFromTimestamp,
    calendarToTimestamp,
    currentRole,
    currentUserURL,
    customerSettings,
    fromTimestamp,
    onEdit,
    toTimestamp,
  } = props;

  const startY = calculateYPosition(calendarFromTimestamp, calendarToTimestamp, fromTimestamp);
  const endY = calculateYPosition(calendarFromTimestamp, calendarToTimestamp, toTimestamp);

  const backgroundColor =
    customerSettings.absenceTypeColors[absence.absenceType] ||
    customerSettings.absenceCalendarBlockColor;

  const containerStyle: Readonly<React.CSSProperties> = {
    backgroundColor,
    cursor: "pointer",
    height: endY - startY,
    overflow: "hidden",
    padding: 2,
    pointerEvents: "none",
    position: "absolute",
    top: startY,
    width: USER_COLUMN_WIDTH,
  };

  return (
    <div style={containerStyle}>
      <AbsenceBlockTitle
        absence={absence}
        currentRole={currentRole}
        customerSettings={customerSettings}
        onEdit={onEdit}
      />
      <AbsenceBlockNote
        absence={absence}
        currentRole={currentRole}
        currentUserURL={currentUserURL}
      />
    </div>
  );
});
