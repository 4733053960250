import {CsvImportSpecification} from "@co-common-libs/csv-import-specifications";
import {Card, CardContent, Checkbox, FormControlLabel} from "@material-ui/core";
import React, {useCallback, useState} from "react";
import {useIntl} from "react-intl";
import {ImportColumnConfiguration} from "../types";
import {ImportConfigurationCardActions} from "./import-configuration-card-actions";
import {ImportConfigurationTable} from "./import-configuration-table";

export function ImportConfigurationCard(props: {
  headers: readonly string[];
  importColumnConfigurations: ReadonlyMap<string, ImportColumnConfiguration>;
  rows: readonly (readonly string[])[];
  selectedImportSpecification: CsvImportSpecification;
  setImportColumnConfigurations: (value: ReadonlyMap<string, ImportColumnConfiguration>) => void;
}): JSX.Element {
  const {
    headers,
    importColumnConfigurations,
    rows,
    selectedImportSpecification,
    setImportColumnConfigurations,
  } = props;

  const intl = useIntl();

  const [createNotExisting, setCreateNotExisting] = useState(true);

  const [validatedStatus, setValidatedStatus] = useState<boolean>(false);

  const handleCreateNotExistingChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      event.stopPropagation();
      setCreateNotExisting(event.target.checked);
    },
    [],
  );

  return (
    <Card style={{minWidth: "auto", overflow: "auto", width: "100%"}}>
      <CardContent>
        <FormControlLabel
          control={
            <Checkbox
              checked={createNotExisting}
              color="primary"
              name="createNotExisting"
              onChange={handleCreateNotExistingChange}
            />
          }
          label={intl.formatMessage({
            defaultMessage: "Opret nye",
          })}
        />
      </CardContent>
      <ImportConfigurationTable
        createNotExisting={createNotExisting}
        headers={headers}
        importColumnConfigurations={importColumnConfigurations}
        rows={rows}
        selectedImportSpecification={selectedImportSpecification}
        setImportColumnConfigurations={setImportColumnConfigurations}
        setValidatedStatus={setValidatedStatus}
      />

      <ImportConfigurationCardActions
        createNotExisting={createNotExisting}
        importColumnConfigurations={importColumnConfigurations}
        rows={rows}
        selectedImportSpecification={selectedImportSpecification}
        validatedStatus={validatedStatus}
      />
    </Card>
  );
}
