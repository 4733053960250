import _ from "lodash";
import React from "react";
import {FormattedMessage, FormattedNumber} from "react-intl";

export type JournalSummarizedDataByProductEntry = {
  decrease: number;
  decreaseCount: number;
  increase: number;
  increaseCount: number;
  product: string;
  unit: string;
};

interface StorageSummarizedByProductJournalTableProps {
  data: readonly Readonly<JournalSummarizedDataByProductEntry>[];
}

export function StorageSummarizedByProductJournalTable(
  props: StorageSummarizedByProductJournalTableProps,
): JSX.Element {
  const {data} = props;

  const sortedData = _.sortBy(data, [(entry) => entry.product, (entry) => entry.unit]);

  const extraLeftPadding = "10pt";

  const rows = sortedData.map(
    ({decrease, decreaseCount, increase, increaseCount, product, unit}, index) => {
      return (
        <tr key={index} style={{borderTop: "1px solid black"}}>
          <td>{product}</td>
          <td style={{textAlign: "right"}}>
            <FormattedNumber maximumFractionDigits={2} minimumFractionDigits={2} value={increase} />
          </td>
          <td style={{textAlign: "right"}}>
            <FormattedNumber maximumFractionDigits={2} minimumFractionDigits={2} value={decrease} />
          </td>
          <td style={{paddingLeft: extraLeftPadding}}>{unit}</td>
          <td style={{textAlign: "right"}}>
            <FormattedNumber value={increaseCount} />
          </td>
          <td style={{textAlign: "right"}}>
            <FormattedNumber value={decreaseCount} />
          </td>
        </tr>
      );
    },
  );

  return (
    <table style={{borderCollapse: "collapse", width: "50%"}}>
      <thead>
        <tr>
          <th style={{textAlign: "left"}}>
            <FormattedMessage defaultMessage="Vare" />
          </th>
          <th style={{textAlign: "right"}}>
            <FormattedMessage defaultMessage="Tilgang" />
          </th>
          <th style={{textAlign: "right"}}>
            <FormattedMessage defaultMessage="Afgang" />
          </th>
          <th style={{paddingLeft: extraLeftPadding, textAlign: "left"}}>
            <FormattedMessage defaultMessage="Enhed" />
          </th>
          <th style={{textAlign: "right"}}>
            <FormattedMessage defaultMessage="Antal tilgange" />
          </th>
          <th style={{textAlign: "right"}}>
            <FormattedMessage defaultMessage="Antal afgange" />
          </th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
}
