import {
  Machine,
  PriceGroup,
  Product,
  ProductUrl,
  Unit,
  UnitUrl,
  WorkType,
} from "@co-common-libs/resources";
import {
  EntryCategoryCode,
  MultipleProductDialog,
  ProductDialog,
  SingleProductDialog,
} from "@co-frontend-libs/components";
import {
  AppState,
  getActiveProductArray,
  getCustomerSettings,
  getUnitLookup,
} from "@co-frontend-libs/redux";
import {BarcodeFormat} from "@co-frontend-libs/utils";
import {connect} from "react-redux";
import {createSelector, createStructuredSelector} from "reselect";

interface SingleProductDialogStateProps {
  barcodeScannerFormats: readonly BarcodeFormat[] | null;
  materialUseAlternativeText: boolean;
  productArray: readonly Product[];
  unitLookup: (url: UnitUrl) => Unit | undefined;
}

interface SingleProductDialogOwnProps {
  machines?: readonly Machine[];
  onCancel(): void;
  onOk(url: ProductUrl): void;
  open: boolean;
  preferredCategory?: EntryCategoryCode;
  preferredProductURLs?: readonly ProductUrl[] | undefined;
  priceGroups?: readonly PriceGroup[] | undefined;
  workType?: WorkType | undefined;
}

const getMaterialUseAlternativeText: (state: AppState) => boolean = createSelector(
  getCustomerSettings,
  (customerSettings) => customerSettings.materialUseAlternativeText,
);

const getBarcodeScannerFormats: (state: AppState) => readonly BarcodeFormat[] | null =
  createSelector(
    getCustomerSettings,
    (customerSettings) => customerSettings.barcodeScannerProductDialog,
  );

export const ConnectedSingleProductDialog: React.ComponentType<SingleProductDialogOwnProps> =
  connect<SingleProductDialogStateProps, object, SingleProductDialogOwnProps, AppState>(
    createStructuredSelector<AppState, SingleProductDialogStateProps>({
      barcodeScannerFormats: getBarcodeScannerFormats,
      materialUseAlternativeText: getMaterialUseAlternativeText,
      productArray: getActiveProductArray,
      unitLookup: getUnitLookup,
    }),
    {},
  )(SingleProductDialog);

interface MultipleProductDialogStateProps {
  barcodeScannerFormats: readonly BarcodeFormat[] | null;
  materialUseAlternativeText: boolean;
  productArray: readonly Product[];
  unitLookup: (url: UnitUrl) => Unit | undefined;
}

interface MultipleProductDialogOwnProps {
  machines?: readonly Machine[];
  onCancel(): void;
  onOk(urls: Set<ProductUrl>): void;
  open: boolean;
  preferredCategory?: EntryCategoryCode;
  preferredProductURLs?: readonly ProductUrl[];
  priceGroups?: readonly PriceGroup[];
  selected?: ReadonlySet<ProductUrl>;
  workType?: WorkType;
}

export const ConnectedMultipleProductDialog: React.ComponentType<MultipleProductDialogOwnProps> =
  connect<MultipleProductDialogStateProps, object, MultipleProductDialogOwnProps, AppState>(
    createStructuredSelector<AppState, MultipleProductDialogStateProps>({
      barcodeScannerFormats: getBarcodeScannerFormats,
      materialUseAlternativeText: getMaterialUseAlternativeText,
      productArray: getActiveProductArray,
      unitLookup: getUnitLookup,
    }),
    {},
  )(MultipleProductDialog);

interface ProductDialogStateProps {
  autoSupplementingProducts: {
    readonly [productGroup: string]: readonly string[] | undefined;
  } | null;
  barcodeScannerFormats: readonly BarcodeFormat[] | null;
  materialUseAlternativeText: boolean;
  productArray: readonly Product[];
  productMultiSelect: boolean;
  showMaterialAutoLinesToEmployees: boolean;
  unitLookup: (url: UnitUrl) => Unit | undefined;
}

interface ProductDialogOwnProps {
  machines?: readonly Machine[] | undefined;
  onCancel(): void;
  onOk(urlOrURLs: ProductUrl | Set<ProductUrl>): void;
  open: boolean;
  preferredCategory?: EntryCategoryCode;
  preferredProductURLs?: readonly ProductUrl[] | undefined;
  priceGroups?: readonly PriceGroup[];
  selected?: ReadonlySet<ProductUrl>;
  workType?: WorkType | undefined;
}

const getAutoSupplementingProducts: (state: AppState) => {
  readonly [productGroup: string]: readonly string[] | undefined;
} | null = createSelector(
  getCustomerSettings,
  (customerSettings) => customerSettings.autoSupplementingProducts,
);

const getProductMultiSelect: (state: AppState) => boolean = createSelector(
  getCustomerSettings,
  (customerSettings) => customerSettings.productMultiSelect,
);

const getShowMaterialAutoLinesToEmployees: (state: AppState) => boolean = createSelector(
  getCustomerSettings,
  (customerSettings) => customerSettings.showMaterialAutoLinesToEmployees,
);

export const ConnectedProductDialog: React.ComponentType<ProductDialogOwnProps> = connect<
  ProductDialogStateProps,
  object,
  ProductDialogOwnProps,
  AppState
>(
  createStructuredSelector<AppState, ProductDialogStateProps>({
    autoSupplementingProducts: getAutoSupplementingProducts,
    barcodeScannerFormats: getBarcodeScannerFormats,
    materialUseAlternativeText: getMaterialUseAlternativeText,
    productArray: getActiveProductArray,
    productMultiSelect: getProductMultiSelect,
    showMaterialAutoLinesToEmployees: getShowMaterialAutoLinesToEmployees,
    unitLookup: getUnitLookup,
  }),
  {},
)(ProductDialog);
