import {
  EconomicCustomerIssue,
  EconomicIssue,
  EconomicMachinesIssue,
  EconomicProductsIssue,
  EconomicProjectIssue,
  EconomicTaskIssue,
  EconomicUserProfileIssue,
  EconomicUserProfilesIssue,
  MultiInstanceIssue,
  SingleInstanceIssue,
} from "./types";

export function isEconomicSingleInstanceIssue(issue: EconomicIssue): issue is SingleInstanceIssue {
  return Object.hasOwn(issue, "instance");
}

export function isEconomicMultiInstanceIssue(issue: EconomicIssue): issue is MultiInstanceIssue {
  return Object.hasOwn(issue, "instances");
}

export function isEconomicTaskIssue(issue: EconomicIssue): issue is EconomicTaskIssue {
  return issue.resourceName === "task";
}

export function isEconomicCustomerIssue(issue: EconomicIssue): issue is EconomicCustomerIssue {
  return issue.resourceName === "customer" && isEconomicSingleInstanceIssue(issue);
}

export function isEconomicProductsIssue(issue: EconomicIssue): issue is EconomicProductsIssue {
  return issue.resourceName === "product" && isEconomicMultiInstanceIssue(issue);
}

export function isEconomicProjectIssue(issue: EconomicIssue): issue is EconomicProjectIssue {
  return issue.resourceName === "project" && isEconomicSingleInstanceIssue(issue);
}

export function isEconomicMachinesIssue(issue: EconomicIssue): issue is EconomicMachinesIssue {
  return issue.resourceName === "machine" && isEconomicMultiInstanceIssue(issue);
}

export function isAnyEconomicUserProfileIssue(
  issue: EconomicIssue,
): issue is EconomicUserProfileIssue | EconomicUserProfilesIssue {
  return issue.resourceName === "userProfile";
}
export function isEconomicUserProfileIssue(
  issue: EconomicIssue,
): issue is EconomicUserProfileIssue {
  return isAnyEconomicUserProfileIssue(issue) && isEconomicSingleInstanceIssue(issue);
}
export function isEconomicUserProfilesIssue(
  issue: EconomicIssue,
): issue is EconomicUserProfilesIssue {
  return isAnyEconomicUserProfileIssue(issue) && isEconomicMultiInstanceIssue(issue);
}
