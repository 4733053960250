import type {SerializedFile, SerializedImage} from "@co-common-libs/resources";
import dataURLToBlob from "dataurl-to-blob";

export const IMAGE_DEFAULT_MAX_SIZE = 3072;

export const THUMBNAIL_WIDTH = 260;
export const THUMBNAIL_HEIGHT = 260;

export const IMAGE_DISPLAY_WIDTH = 3072;
export const IMAGE_DISPLAY_HEIGHT = 3072;

export {dataURLToBlob};

export const readFileData = (file: Blob): Promise<string> =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      const dataURL = reader.result as string;
      resolve(dataURL);
    };
    reader.readAsDataURL(file);
  });

const makeImageElementWith = (dataURL: string): Promise<HTMLImageElement> =>
  new Promise<HTMLImageElement>((resolve, reject) => {
    const img = document.createElement("img");
    img.onload = () => {
      resolve(img);
    };
    img.onerror = reject;
    img.src = dataURL;
  });

const makeCanvasWithScaledImage = (
  img: HTMLImageElement,
  width: number,
  height: number,
): HTMLCanvasElement => {
  const canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
  ctx.drawImage(img, 0, 0, width, height);
  return canvas;
};

export const getScaledWidthHeight = (
  inputWidth: number,
  inputHeight: number,
  maxWidth: number,
  maxHeight: number,
): {height: number; width: number} => {
  if (inputWidth <= maxWidth && inputHeight <= maxHeight) {
    return {
      height: inputHeight,
      width: inputWidth,
    };
  }
  const widthRatio = maxWidth / inputWidth;
  const heightRatio = maxHeight / inputHeight;
  if (widthRatio < heightRatio) {
    return {
      height: Math.round(inputHeight * widthRatio),
      width: maxWidth,
    };
  } else {
    return {
      height: maxHeight,
      width: Math.round(inputWidth * heightRatio),
    };
  }
};

const scaleImageDataHelper = (
  dataURL: string,
  mimeType: string,
  maxWidth: number,
  maxHeight: number,
): Promise<{data: string; height: number; width: number}> =>
  makeImageElementWith(dataURL).then((img: HTMLImageElement) => {
    const inputWidth = img.width;
    const inputHeight = img.height;
    if (inputWidth <= maxWidth && inputHeight <= maxHeight) {
      return {
        data: dataURL,
        height: inputHeight,
        width: inputWidth,
      };
    }
    console.assert(inputWidth > maxWidth || inputHeight > maxHeight);
    const {height, width} = getScaledWidthHeight(inputWidth, inputHeight, maxWidth, maxHeight);
    console.assert(width <= maxWidth);
    console.assert(height <= maxHeight);
    const canvas = makeCanvasWithScaledImage(img, width, height);
    const resultDataURL = canvas.toDataURL(mimeType);
    return {
      data: resultDataURL,
      height,
      width,
    };
  });

export const scaleImageData = (
  dataURL: string,
  mimeType: string,
  maxWidth?: number,
  maxHeight?: number,
): Promise<{data: string; height: number; width: number}> => {
  const maxW = maxWidth || IMAGE_DEFAULT_MAX_SIZE;
  const maxH = maxHeight || maxW;
  return scaleImageDataHelper(dataURL, mimeType, maxW, maxH);
};

export const serializeFile = (file: File): Promise<SerializedFile> => {
  const {name} = file;
  return readFileData(file).then((data) => ({
    data,
    name,
  }));
};

export const deserializeFile = (fileData: SerializedFile): {blob: Blob; name: string} => {
  const {data, name} = fileData;
  const blob = dataURLToBlob(data);
  return {blob, name};
};

export const serializeImage = (
  file: File,
  maxWidth?: number,
  maxHeight?: number,
): Promise<SerializedImage> => {
  const {name, type} = file;
  return readFileData(file)
    .then((data) => scaleImageData(data, type, maxWidth, maxHeight))
    .then(({data, height, width}) => ({
      data,
      height,
      name,
      width,
    }));
};
