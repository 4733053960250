import {Timer} from "@co-common-libs/resources";
import {ConnectedDepartmentDialog} from "@co-frontend-libs/connected-components";
import {
  actions,
  getCurrentUserURL,
  getCustomerSettings,
  getSettingsEntryLookupByIdentifier,
} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {Button} from "@material-ui/core";
import {instanceURL} from "frontend-global-config";
import React, {useCallback, useMemo, useState} from "react";
import {FormattedMessage, defineMessages, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuid} from "uuid";
import {DeletableChip} from "../../../reports/deletable-chip";

const messages = defineMessages({
  selectDepartmentButton: {
    defaultMessage: "Vælg afdeling",
    id: "timer-entry.label.select-department",
  },
  unknownDepartment: {
    defaultMessage: "Ukendt afdeling: {departmentID}",
    id: "timer-entry.label.unknown-department",
  },
});

interface TimerDepartmentsBlockProps {
  timer: Timer;
}

export function TimerDepartmentsBlock(props: TimerDepartmentsBlockProps): JSX.Element {
  const {timer} = props;

  const [departmentDialogOpen, setDepartmentDialogOpen] = useState(false);
  const setDepartmentDialogOpenTrue = useCallWithTrue(setDepartmentDialogOpen);
  const setDepartmentDialogOpenFalse = useCallWithFalse(setDepartmentDialogOpen);

  const dispatch = useDispatch();
  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const currentUserURL = useSelector(getCurrentUserURL);
  const customerSettings = useSelector(getCustomerSettings);

  const {formatMessage} = useIntl();

  const handleDepartmentDialogOk = useCallback(
    (department: string) => {
      setDepartmentDialogOpen(false);
      const settingID = "departmentExtraTimers";
      const settingsEntry = settingsEntryLookupByIdentifier(settingID);
      const oldValue: {readonly [department: string]: readonly string[]} =
        settingsEntry?.data || {};
      const oldValueForDepartment: readonly string[] = oldValue[department] || [];
      const timerID = timer.identifier;
      if (oldValueForDepartment.includes(timerID)) {
        return;
      }
      const newValueForDepartment = [...oldValueForDepartment, timerID];
      const newValue = {...oldValue, [department]: newValueForDepartment};
      if (settingsEntry) {
        dispatch(
          actions.update(settingsEntry.url, [
            {member: "changedBy", value: currentUserURL},
            {member: "data", value: newValue},
          ]),
        );
      } else {
        const id = uuid();
        const url = instanceURL("settingEntry", id);
        dispatch(
          actions.create({
            changedBy: currentUserURL,
            data: newValue,
            key: settingID,
            url,
          }),
        );
      }
    },
    [currentUserURL, dispatch, settingsEntryLookupByIdentifier, timer.identifier],
  );

  const handleRemoveDepartment = useCallback(
    (department: string) => {
      const settingID = "departmentExtraTimers";
      const settingsEntry = settingsEntryLookupByIdentifier(settingID);
      const oldValue: {readonly [department: string]: readonly string[]} =
        settingsEntry?.data || {};
      const oldValueForDepartment: readonly string[] = oldValue[department] || [];
      const timerID = timer.identifier;
      if (!oldValueForDepartment.includes(timerID)) {
        return;
      }
      const newValueForDepartment = oldValueForDepartment.filter((id) => id !== timerID);
      const newValue = {...oldValue};
      if (newValueForDepartment.length) {
        newValue[department] = newValueForDepartment;
      } else {
        delete newValue[department];
      }
      if (settingsEntry) {
        dispatch(
          actions.update(settingsEntry.url, [
            {member: "changedBy", value: currentUserURL},
            {member: "data", value: newValue},
          ]),
        );
      } else {
        const id = uuid();
        const url = instanceURL("settingEntry", id);
        dispatch(
          actions.create({
            changedBy: currentUserURL,
            data: newValue,
            key: settingID,
            url,
          }),
        );
      }
    },
    [currentUserURL, dispatch, settingsEntryLookupByIdentifier, timer.identifier],
  );

  const departments = useMemo(
    () =>
      Object.entries(customerSettings.departmentExtraTimers)
        .filter(([_department, timers]) => timers.includes(timer.identifier))
        .map(([department, _timers]) => department),
    [customerSettings.departmentExtraTimers, timer.identifier],
  );

  return (
    <>
      <div>
        <h3>
          <FormattedMessage defaultMessage="Afdelinger" id="timers.header.departments" />
        </h3>
        <div>
          {departments.map((departmentID) => {
            const label =
              customerSettings.departments[departmentID] ||
              formatMessage(messages.unknownDepartment, {departmentID});
            return (
              <DeletableChip
                key={departmentID}
                deletionId={departmentID}
                label={label}
                onDelete={handleRemoveDepartment}
              />
            );
          })}
        </div>
        <Button color="secondary" variant="contained" onClick={setDepartmentDialogOpenTrue}>
          {formatMessage(messages.selectDepartmentButton)}
        </Button>
      </div>
      <ConnectedDepartmentDialog
        open={departmentDialogOpen}
        onCancel={setDepartmentDialogOpenFalse}
        onOk={handleDepartmentDialogOk}
      />
    </>
  );
}
