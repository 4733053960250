import {RouteTaskUrl} from "@co-common-libs/resources";
import {formatTime} from "@co-common-libs/utils";
import {IconButton, TableCell, TableRow} from "@material-ui/core";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import PencilIcon from "mdi-react/PencilIcon";
import React from "react";
import {FormattedNumber} from "react-intl";
import {
  BUTTON_COLUMN_WIDTH,
  COUNT_COLUMN_WIDTH,
  LOG_ROW_COLUMN_COMMON_STYLE,
  TIME_COLUMN_WIDTH,
  UNIT_COLUMN_WIDTH,
} from "./constants";

interface LogRowProps {
  activity?: string;
  address?: string;
  completed?: string | undefined;
  decimalPlaces?: number;
  editDisabled: boolean;
  fullBottomBorder: boolean;
  notesFromTaskAssignee?: string;
  onEditClick: (routeTaskUrl: RouteTaskUrl) => void;
  quantity?: number | undefined;
  resultDescription?: string;
  routeTaskStartStop: boolean;
  routeTaskURL?: RouteTaskUrl;
  started?: string | undefined;
  unitString?: string;
  workplaceName?: string;
}

export class LogRow extends PureComponent<LogRowProps> {
  @bind
  handleClick(_event: unknown): void {
    const {onEditClick, routeTaskURL} = this.props;
    if (routeTaskURL) {
      onEditClick(routeTaskURL);
    }
  }
  render(): JSX.Element {
    const {
      activity,
      address,
      completed,
      decimalPlaces,
      editDisabled,
      fullBottomBorder,
      notesFromTaskAssignee,
      quantity,
      resultDescription,
      routeTaskStartStop,
      routeTaskURL,
      started,
      unitString,
      workplaceName,
    } = this.props;

    const cellWithOptionalBottomBorderStyle: React.CSSProperties = fullBottomBorder
      ? {...LOG_ROW_COLUMN_COMMON_STYLE}
      : {...LOG_ROW_COLUMN_COMMON_STYLE, borderBottom: 0};

    return (
      <TableRow style={fullBottomBorder ? {} : {borderBottom: 0}}>
        {routeTaskStartStop ? (
          <TableCell
            style={{
              width: TIME_COLUMN_WIDTH,
              ...cellWithOptionalBottomBorderStyle,
            }}
          >
            {started ? formatTime(started) : ""}
          </TableCell>
        ) : null}
        <TableCell
          style={{
            width: TIME_COLUMN_WIDTH,
            ...cellWithOptionalBottomBorderStyle,
          }}
        >
          {completed ? formatTime(completed) : ""}
        </TableCell>

        <TableCell style={cellWithOptionalBottomBorderStyle}>
          {workplaceName ? `${workplaceName}, ${address}` : address}
        </TableCell>
        <TableCell style={cellWithOptionalBottomBorderStyle}>
          <h4>{activity}</h4>
          {notesFromTaskAssignee}
        </TableCell>
        <TableCell style={LOG_ROW_COLUMN_COMMON_STYLE}>{resultDescription}</TableCell>
        <TableCell
          style={{
            width: COUNT_COLUMN_WIDTH,
            ...LOG_ROW_COLUMN_COMMON_STYLE,
          }}
        >
          {quantity ? (
            decimalPlaces != null ? (
              <FormattedNumber maximumFractionDigits={decimalPlaces} value={quantity} />
            ) : (
              <FormattedNumber value={quantity} />
            )
          ) : null}
        </TableCell>
        <TableCell
          style={{
            width: UNIT_COLUMN_WIDTH,
            ...LOG_ROW_COLUMN_COMMON_STYLE,
          }}
        >
          {unitString}
        </TableCell>
        <TableCell
          style={{
            padding: 0,
            width: BUTTON_COLUMN_WIDTH,
            ...LOG_ROW_COLUMN_COMMON_STYLE,
          }}
        >
          {routeTaskURL ? (
            <IconButton disabled={editDisabled} onClick={this.handleClick}>
              <PencilIcon />
            </IconButton>
          ) : null}
        </TableCell>
      </TableRow>
    );
  }
}
