import {SettingID} from "@co-common-libs/config";
import {EntryData, GenericSingleSelectionSearchDialog} from "@co-frontend-libs/components";
import {
  actions,
  getCurrentUserURL,
  getSettingsEntryLookupByIdentifier,
} from "@co-frontend-libs/redux";
import {ResponseWithData, jsonFetch, useConditionalMemo} from "@co-frontend-libs/utils";
import {globalConfig} from "frontend-global-config";
import React, {useCallback, useEffect, useState} from "react";
import {IntlShape, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

interface EconomicDefaultPaymentTermsDialogProps {
  onClose: () => void;
  open: boolean;
  settingID: SettingID;
}

interface PaymentTermsData {
  readonly id: number;
  readonly name: string;
}

type FetchState =
  | {data: readonly PaymentTermsData[]; state: "FETCHED"}
  | {error: string; state: "ERROR"}
  | {state: "FETCHING"}
  | {state: "INITIAL"};

function computeChoices(
  data: readonly PaymentTermsData[],
  intl: IntlShape,
): readonly EntryData<string>[] {
  const idLabel = intl.formatMessage({defaultMessage: "ID"});
  const nameLabel = intl.formatMessage({defaultMessage: "Navn"});
  return data.map(
    ({id, name}): EntryData<string> => ({
      category: "standard",
      exactMatchValue: `${id}`,
      identifier: `${id}`,
      primaryText: name,
      searchFields: [
        {label: idLabel, priority: 10, text: `${id}`},
        {label: nameLabel, priority: 5, text: name},
      ],
      secondaryText: `${id}`,
    }),
  );
}

export function EconomicDefaultPaymentTermsDialog(
  props: EconomicDefaultPaymentTermsDialogProps,
): JSX.Element {
  const {onClose, open, settingID} = props;

  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const settingEntry = settingsEntryLookupByIdentifier(settingID);

  const [fetchState, setFetchState] = useState<FetchState>({state: "INITIAL"});

  const data: number | null | undefined = settingEntry?.data;

  const currentUserURL = useSelector(getCurrentUserURL);

  const dispatch = useDispatch();

  const intl = useIntl();

  useEffect(() => {
    if (open) {
      let abortController: AbortController | null = new AbortController();
      setFetchState({state: "FETCHING"});
      const url = `${globalConfig.baseURL}/api/economic/payment_terms/`;
      jsonFetch(url, "GET", null, abortController.signal)
        .then((response: ResponseWithData) => {
          abortController = null;
          setFetchState({data: response.data, state: "FETCHED"});
          return;
        })
        .catch((fetchError: Error) => {
          abortController = null;
          setFetchState({error: `${fetchError}`, state: "ERROR"});
        });
      return () => {
        if (abortController) {
          abortController.abort();
        }
      };
    } else {
      return undefined;
    }
  }, [open]);

  const handleSelection = useCallback(
    (stringValue: string) => {
      const newValue = parseInt(stringValue);
      if (settingEntry && newValue !== data) {
        dispatch(
          actions.update(settingEntry.url, [
            {member: "changedBy", value: currentUserURL},
            {member: "data", value: newValue},
          ]),
        );
      }
      onClose();
    },
    [currentUserURL, data, dispatch, onClose, settingEntry],
  );

  const selectionData = useConditionalMemo(
    () => computeChoices(fetchState.state === "FETCHED" ? fetchState.data : [], intl),
    [fetchState, intl],
    open,
    [],
  );

  return (
    <GenericSingleSelectionSearchDialog
      data={fetchState.state === "FETCHED" ? selectionData : null}
      error={fetchState.state === "ERROR" ? fetchState.error : undefined}
      mobilePrimaryLines={1}
      mobileSearchPrimaryLines={1}
      mobileSearchSecondaryLines={1}
      mobileSecondaryLines={1}
      open={open}
      searchTitle={intl.formatMessage({
        defaultMessage: "Søg betalingsbetingelser",
      })}
      sorting="SECONDARY_IDENTIFIER"
      title={intl.formatMessage({
        defaultMessage: "Vælg betalingsbetingelser",
      })}
      onCancel={onClose}
      onOk={handleSelection}
    />
  );
}
