import {Patch, PriceGroup, Task} from "@co-common-libs/resources";
import {
  computeLogLegalPriceGroups,
  getExpectedLogSpecification,
  logChangeLegal,
  recomputePriceGroup,
  workTypePotentialPriceGroups,
} from "@co-common-libs/resources-utils";
import {ProvisionaryCommand} from "../../resources/actions";
import {
  getMachineLookup,
  getOrderLookup,
  getPriceGroupLookup,
  getReportingSpecificationLookup,
  getWorkTypeLookup,
} from "../../resources/selectors";
import {ResourcesAuthenticationMiddlewareAPI} from "../types";

export function autoWorkTypePriceGroup(
  newTask: Task | null,
  oldTask: Task | undefined,
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  _command: ProvisionaryCommand,
): Patch<Task> | null {
  if (!newTask) {
    return null;
  }
  if (
    newTask.recordedInC5 ||
    newTask.archivable ||
    !newTask.workType ||
    newTask.workType === oldTask?.workType ||
    (newTask.priceGroup && newTask.priceGroup !== oldTask?.priceGroup)
  ) {
    // archivable, or
    // worktype cleared or unchanged, or
    // pricegroup explicitly changed
    return null;
  }

  const state = middlewareApi.getState();

  const orderLookup = getOrderLookup(state);
  const order = newTask.order ? orderLookup(newTask.order) : undefined;

  if (order?.routePlan) {
    return null;
  }

  const workTypeLookup = getWorkTypeLookup(state);
  const priceGroupLookup = getPriceGroupLookup(state);

  const potentialPriceGroups = workTypePotentialPriceGroups(
    newTask.workType,
    order?.customer || null,
    workTypeLookup,
    priceGroupLookup,
  );

  let logLegalPriceGroups: PriceGroup[];

  if (newTask.reportingSpecification && !logChangeLegal(newTask)) {
    const machineLookup = getMachineLookup(state);
    const reportingSpecificationLookup = getReportingSpecificationLookup(state);
    const logSpecificationFromWorkTypeOrMachines = getExpectedLogSpecification(
      {
        machineuseSet: newTask.machineuseSet,
        priceGroup: null,
        workType: newTask.workType,
      },
      {
        machineLookup,
        priceGroupLookup,
        reportingSpecificationLookup,
        workTypeLookup,
      },
    );
    const reportingSpecificationRequired =
      logSpecificationFromWorkTypeOrMachines?.url !== newTask.reportingSpecification;
    const logLegalPriceGroupUrls = computeLogLegalPriceGroups(
      potentialPriceGroups,
      newTask.reportingSpecification,
      reportingSpecificationRequired,
    );
    logLegalPriceGroups = potentialPriceGroups.filter(({url}) => logLegalPriceGroupUrls.has(url));
  } else {
    logLegalPriceGroups = potentialPriceGroups;
  }

  const currentPriceGroup = newTask.priceGroup ? priceGroupLookup(newTask.priceGroup) : null;

  const priceGroup = recomputePriceGroup(logLegalPriceGroups, currentPriceGroup || null);
  const priceGroupURL = priceGroup?.url || null;

  if (priceGroupURL === newTask.priceGroup) {
    return null;
  }

  return [{member: "priceGroup", value: priceGroupURL}];
}
