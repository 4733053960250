import {Config} from "@co-common-libs/config";
import {
  CustomerUrl,
  EmployeeGroupUrl,
  Order,
  OrderUrl,
  WorkTypeUrl,
  emptyOrder,
  urlToId,
} from "@co-common-libs/resources";
import {
  AppbarSearchField,
  VerticalStackingFloatingActionButton,
} from "@co-frontend-libs/components";
import {
  actions,
  getContactArray,
  getCurrentUserURL,
  getExtendedCustomerSettings,
  getOrderLookup,
  getTaskArray,
} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {Tab, Tabs} from "@material-ui/core";
import {
  ConnectedOrderTable,
  CustomerSelectCreateDialog,
  FilterBar,
  FilterButton,
  MenuToolbar,
  PageLayout,
} from "app-components";
import {useDeviceConfig, useEventTargetValueCallback, useQueryParameterState} from "app-utils";
import bowser from "bowser";
import {instanceURL} from "frontend-global-config";
import MergeIcon from "mdi-react/MergeIcon";
import PlusIcon from "mdi-react/PlusIcon";
import React, {useCallback, useState} from "react";
import {defineMessages, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import type {Writable} from "ts-essentials";
import {v4 as uuid} from "uuid";
import {OrderArchive} from "./order-archive";
import {OrderMergeDialog} from "./order-merge-dialog";

const MOBILE = bowser.mobile;
const MINIMUM_SELECTED_ORDERS_FOR_MERGE = 2;

const TAB_NAMES = ["open", "validation", "readyForBilling", "archive", "drafts"] as const;

type TabName = (typeof TAB_NAMES)[number];

const messages = defineMessages({
  all: {
    defaultMessage: "Alle",
    id: "order-list.tab-label.all",
  },
  archive: {
    defaultMessage: "Arkiv",
    id: "order-list.tab-label.archive",
  },
  completed: {
    defaultMessage: "Fuldførte",
    id: "order-list.tab-label.completed",
  },
  drafts: {
    defaultMessage: "Kladder",
    id: "order-list.tab-label.drafts",
  },
  open: {
    defaultMessage: "Åbne",
    id: "order-list.tab-label.open",
  },
  orders: {
    defaultMessage: "Ordre",
  },
  readyForBilling: {
    defaultMessage: "Fakturering",
    id: "order-list.tab-label.ready-for-billing",
  },
  readyForBillingHandheld: {
    defaultMessage: "Fak.",
    id: "order-list.tab-label.ready-for-billing-handheld",
  },
  validation: {
    defaultMessage: "Godkendelse",
    id: "order-list.tab-label.validation",
  },
  validationHandheld: {
    defaultMessage: "Godk.",
    id: "order-list.tab-label.validation-handheld",
  },
});

function getDefaultTab(customerSettings: Config): TabName {
  if (customerSettings.orderDrafts && customerSettings.orderDraftsStartOnDraft) {
    return "drafts";
  }
  return "open";
}

export const OrderList = React.memo(function OrderList({
  onMenuButton,
}: {
  onMenuButton: (event: React.MouseEvent) => void;
}): JSX.Element {
  const [customerDialogOpen, setCustomerDialogOpen] = useState(false);
  const setCustomerDialogOpenFalse = useCallWithFalse(setCustomerDialogOpen);

  const [orderMergeDialogOpen, setOrderMergeDialogOpen] = useState(false);
  const setOrderMergeDialogOpenTrue = useCallWithTrue(setOrderMergeDialogOpen);
  const setOrderMergeDialogOpenFalse = useCallWithFalse(setOrderMergeDialogOpen);

  const [selectedCustomer, setSelectedCustomer] = useState<CustomerUrl>();
  const [selectedOrders, setSelectedOrders] = useState<OrderUrl[]>([]);

  const [orderListVisibleWorkTypes, setOrderListVisibleWorkTypes] = useDeviceConfig<WorkTypeUrl[]>(
    "orderListVisibleWorkTypes",
  );
  const [orderListVisibleDepartments, setOrderListVisibleDepartments] = useDeviceConfig<string[]>(
    "orderListVisibleDepartments",
  );
  const [orderListVisibleEmployeeGroup, setOrderListVisibleEmployeeGroups] = useDeviceConfig<
    EmployeeGroupUrl[]
  >("orderListVisibleEmployeeGroups");

  const [selectedWorkTypeURLSet, setSelectedWorkTypeURLSet] = useState<ReadonlySet<WorkTypeUrl>>(
    new Set(orderListVisibleWorkTypes || []),
  );
  const [selectedEmployeeGroupURLSet, setSelectedEmployeeGroupURLSet] = useState<
    ReadonlySet<EmployeeGroupUrl>
  >(new Set(orderListVisibleEmployeeGroup || []));

  const [selectedDepartmentIdentifierSet, setSelectedDepartmentIdentifierSet] = useState<
    ReadonlySet<string>
  >(new Set(orderListVisibleDepartments || []));

  const currentUserURL = useSelector(getCurrentUserURL);
  const customerSettings = useSelector(getExtendedCustomerSettings);
  const contactArray = useSelector(getContactArray);
  const orderLookup = useSelector(getOrderLookup);
  const taskArray = useSelector(getTaskArray);

  const {
    transferTask: {rules: transferTaskRules},
  } = customerSettings;

  const [q, setQ] = useQueryParameterState<string>("q", "");
  const handleFilterStringChange = useEventTargetValueCallback(setQ, [setQ]);
  const dispatch = useDispatch();

  const {formatMessage} = useIntl();

  const [activeTab, setActiveTab] = useQueryParameterState<TabName>(
    "tab",
    getDefaultTab(customerSettings),
  );
  const handleTabChange = useCallback(
    (_event: React.ChangeEvent<unknown>, value: string) => {
      console.assert(TAB_NAMES.includes(value as TabName));
      setActiveTab(value as TabName);
    },
    [setActiveTab],
  );

  const handleFabButton = useCallback(() => {
    const draft =
      customerSettings.orderDrafts &&
      (customerSettings.orderDraftsAllwaysCreateDraft || activeTab === "drafts");
    if (customerSettings.orderEntryAutoOpenCustomerSelection) {
      setCustomerDialogOpen(true);
    } else {
      const userURL = currentUserURL || undefined;
      const id = uuid();
      const url = instanceURL("order", id);
      const order: Writable<Order> = {
        ...emptyOrder,
        draft,
        durationDays: 1,
        id,
        url,
      };
      if (userURL) {
        order.createdBy = userURL;
      }
      dispatch(actions.create(order));
      window.setTimeout(() => {
        dispatch(actions.go("/orderEntry/:id", {id}));
      }, 0);
    }
  }, [
    activeTab,
    currentUserURL,
    customerSettings.orderDrafts,
    customerSettings.orderDraftsAllwaysCreateDraft,
    customerSettings.orderEntryAutoOpenCustomerSelection,
    dispatch,
  ]);

  const handleCustomerDialogOk = useCallback(
    (customerURL: CustomerUrl) => {
      setCustomerDialogOpen(false);
      const draft =
        customerSettings.orderDrafts &&
        (customerSettings.orderDraftsAllwaysCreateDraft || activeTab === "drafts");
      const userURL = currentUserURL || undefined;
      const id = uuid();
      const url = instanceURL("order", id);
      let contactURL = null;
      if (customerURL) {
        const contact = contactArray.find((instance) => {
          return instance.customer === customerURL && instance.defaultContact;
        });
        contactURL = contact ? contact.url : null;
      }
      const order: Writable<Order> = {
        ...emptyOrder,
        contact: contactURL,
        customer: customerURL,
        draft,
        durationDays: 1,
        id,
        url,
      };
      if (userURL) {
        order.createdBy = userURL;
      }
      dispatch(actions.create(order));
      window.setTimeout(() => {
        dispatch(actions.go("/orderEntry/:id", {id}));
      }, 0);
    },
    [
      activeTab,
      contactArray,
      currentUserURL,
      customerSettings.orderDrafts,
      customerSettings.orderDraftsAllwaysCreateDraft,
      dispatch,
    ],
  );

  const handleOrderTableClick = useCallback(
    (url: string) => {
      dispatch(actions.go("/orderEntry/:id", {id: urlToId(url)}));
    },
    [dispatch],
  );

  const handleCheckedChanged = useCallback(
    (url: OrderUrl, checked: boolean) => {
      const order = orderLookup(url);
      let newSelectedOrders: OrderUrl[];
      if (checked) {
        newSelectedOrders = [...selectedOrders, url];
      } else {
        newSelectedOrders = selectedOrders.filter((oldCustomerURL) => oldCustomerURL !== url);
      }
      let newSelectedCustomer: CustomerUrl | undefined = selectedCustomer;
      if (!newSelectedCustomer && checked) {
        newSelectedCustomer = order?.customer ?? undefined;
      } else if (!newSelectedOrders.length) {
        newSelectedCustomer = undefined;
      }

      setSelectedCustomer(newSelectedCustomer);
      setSelectedOrders(newSelectedOrders);
    },
    [orderLookup, selectedCustomer, selectedOrders],
  );

  const handleOrderMergeDialogOk = useCallback(
    (selectedOrder: OrderUrl) => {
      const ordersToMerge = selectedOrders.filter((order) => order !== selectedOrder);
      const tasksToMove = taskArray.filter((task) => {
        if (task.order) {
          return ordersToMerge.includes(task.order);
        }
        return false;
      });

      tasksToMove.forEach((task) => {
        dispatch(actions.update(task.url, [{member: "order", value: selectedOrder}]));
      });

      ordersToMerge.forEach((orderURL) => dispatch(actions.remove(orderURL)));
      setOrderMergeDialogOpen(false);
      setSelectedCustomer(undefined);
      setSelectedOrders([]);
    },
    [dispatch, selectedOrders, taskArray],
  );

  const handleWorkTypeFilterChange = useCallback(
    (selected: ReadonlySet<WorkTypeUrl>) => {
      setSelectedWorkTypeURLSet(selected);
      setOrderListVisibleWorkTypes([...selected]);
    },
    [setOrderListVisibleWorkTypes],
  );

  const handleEmployeeGroupFilterChange = useCallback(
    (selected: ReadonlySet<EmployeeGroupUrl>) => {
      setSelectedEmployeeGroupURLSet(selected);
      setOrderListVisibleEmployeeGroups([...selected]);
    },
    [setOrderListVisibleEmployeeGroups],
  );

  const handleDepartmentFilterChange = useCallback(
    (selected: ReadonlySet<string>) => {
      setSelectedDepartmentIdentifierSet(selected);
      setOrderListVisibleDepartments([...selected]);
    },
    [setOrderListVisibleDepartments],
  );

  const handleFilterClear = useCallback(() => {
    setSelectedWorkTypeURLSet(new Set());
    setSelectedDepartmentIdentifierSet(new Set());
    setSelectedEmployeeGroupURLSet(new Set());
    setOrderListVisibleWorkTypes([]);
    setOrderListVisibleDepartments([]);
    setOrderListVisibleEmployeeGroups([]);
  }, [
    setOrderListVisibleDepartments,
    setOrderListVisibleEmployeeGroups,
    setOrderListVisibleWorkTypes,
  ]);

  let filterButton;
  if (
    !bowser.mobile &&
    (customerSettings.enableOrderTaskDepartmentFilter ||
      customerSettings.enableOrderTaskWorkTypeFilter ||
      customerSettings.useEmployeeGroups) &&
    activeTab !== "archive"
  ) {
    filterButton = (
      <span style={{display: "inline-block", verticalAlign: "top"}}>
        <FilterButton
          selectedDepartmentIdentifierSet={selectedDepartmentIdentifierSet}
          selectedEmployeeGroupUrlSet={selectedEmployeeGroupURLSet}
          selectedWorkTypeURLSet={selectedWorkTypeURLSet}
          onSelectedDepartmentIdentifierSetChange={handleDepartmentFilterChange}
          onSelectedEmployeeGroupUrlSetChange={handleEmployeeGroupFilterChange}
          onSelectedWorkTypeURLSetChange={handleWorkTypeFilterChange}
        />
      </span>
    );
  }

  const useOrderApproval =
    transferTaskRules.requireOrderApproval ||
    transferTaskRules.requireOrderApprovalForWorkTypes.length;

  const right =
    activeTab !== "archive" ? (
      <>
        {filterButton}
        <AppbarSearchField value={q} onChange={handleFilterStringChange} />
      </>
    ) : undefined;
  const floatingActionButtons = customerSettings.adminCanCreateCustomerTask
    ? [
        <VerticalStackingFloatingActionButton
          key="add-order"
          stackIndex={0}
          onClick={handleFabButton}
        >
          <PlusIcon />
        </VerticalStackingFloatingActionButton>,
      ]
    : [];
  if (customerSettings.enableOrderMerge && activeTab !== "archive") {
    floatingActionButtons.push(
      <VerticalStackingFloatingActionButton
        key="merge"
        disabled={selectedOrders.length < MINIMUM_SELECTED_ORDERS_FOR_MERGE}
        stackIndex={1}
        onClick={setOrderMergeDialogOpenTrue}
      >
        <MergeIcon />
      </VerticalStackingFloatingActionButton>,
    );
  }
  const dialogs = (
    <CustomerSelectCreateDialog
      key="customer-dialog"
      open={customerDialogOpen}
      onCancel={setCustomerDialogOpenFalse}
      onOk={handleCustomerDialogOk}
    />
  );
  let tabs;
  if (useOrderApproval) {
    tabs = [
      <Tab key="open" label={formatMessage(messages.open)} value="open" />,
      <Tab
        key="validation"
        label={
          MOBILE ? formatMessage(messages.validationHandheld) : formatMessage(messages.validation)
        }
        value="validation"
      />,
      !customerSettings.hideTasklistBillingTab ? (
        <Tab
          key="readyForBilling"
          label={
            MOBILE
              ? formatMessage(messages.readyForBillingHandheld)
              : formatMessage(messages.readyForBilling)
          }
          value="readyForBilling"
        />
      ) : null,
      <Tab key="archive" label={formatMessage(messages.archive)} value="archive" />,
    ];
  } else {
    tabs = [
      <Tab key="open" label={formatMessage(messages.open)} value="open" />,
      <Tab key="validation" label={formatMessage(messages.completed)} value="validation" />,
      <Tab key="archive" label={formatMessage(messages.archive)} value="archive" />,
    ];
  }
  if (customerSettings.orderDrafts) {
    tabs.unshift(<Tab key="drafts" label={formatMessage(messages.drafts)} value="drafts" />);
  }
  const [dataLoaded, setDataLoaded] = useState(activeTab !== "archive");
  const setDataLoadedTrue = useCallWithTrue(setDataLoaded);

  let filteringBlock: JSX.Element | null = null;
  if (
    selectedWorkTypeURLSet.size ||
    selectedDepartmentIdentifierSet.size ||
    selectedEmployeeGroupURLSet.size
  ) {
    filteringBlock = (
      <FilterBar
        selectedDepartmentIdentifierSet={selectedDepartmentIdentifierSet}
        selectedEmployeeGroupURLSet={selectedEmployeeGroupURLSet}
        selectedWorkTypeURLSet={selectedWorkTypeURLSet}
        onRequestFilterClear={handleFilterClear}
      />
    );
  }

  return (
    <PageLayout
      withBottomScrollPadding
      dialogs={dialogs}
      floatingActionButton={floatingActionButtons}
      performScrolling={dataLoaded}
      tabs={
        <Tabs value={activeTab} onChange={handleTabChange}>
          {tabs}
        </Tabs>
      }
      toolbar={
        <MenuToolbar
          rightElement={right}
          title={formatMessage(messages.orders)}
          onMenuButton={onMenuButton}
        />
      }
    >
      {activeTab === "archive" ? (
        <OrderArchive onDataLoaded={setDataLoadedTrue} />
      ) : (
        <>
          {filteringBlock}
          <ConnectedOrderTable
            filterString={q}
            selectedCustomer={selectedCustomer}
            selectedDepartmentIdentifierSet={selectedDepartmentIdentifierSet}
            selectedEmployeeGroupsURLSet={selectedEmployeeGroupURLSet}
            selectedOrders={selectedOrders}
            selectedWorkTypeURLSet={selectedWorkTypeURLSet}
            tab={activeTab as any}
            onCheckedChanged={handleCheckedChanged}
            onClick={handleOrderTableClick}
          />
        </>
      )}
      <OrderMergeDialog
        customer={selectedCustomer}
        open={orderMergeDialogOpen}
        orderList={selectedOrders}
        onCancel={setOrderMergeDialogOpenFalse}
        onOk={handleOrderMergeDialogOk}
      />
    </PageLayout>
  );
});
