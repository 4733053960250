import {MachineAnalysisAccountsTable, MenuToolbar, PageLayout} from "app-components";
import React from "react";
import {useIntl} from "react-intl";

interface MachineAnalysisSettingsPageProps {
  onMenuButton: (event: React.MouseEvent) => void;
}

export const MachineAnalysisSettingsPage = React.memo(function MachineAnalysisSettingsPage(
  props: MachineAnalysisSettingsPageProps,
): JSX.Element {
  const intl = useIntl();

  return (
    <PageLayout
      withBottomScrollPadding
      toolbar={
        <MenuToolbar
          title={intl.formatMessage({defaultMessage: "Maskinanalyse"})}
          onMenuButton={props.onMenuButton}
        />
      }
    >
      <MachineAnalysisAccountsTable fabPositioning="fixed" />
    </PageLayout>
  );
});
