import {Config} from "@co-common-libs/config";
import {
  CalculatorSpecification,
  CalculatorSpecifications,
  Product,
  ProductUrl,
  ProductUseWithOrder,
  ProductUsesDict,
  Unit,
  UnitUrl,
} from "@co-common-libs/resources";
import {notUndefined} from "@co-common-libs/utils";
import _ from "lodash";

export interface ProductUseWithData {
  autoProduct: boolean;
  calculator: CalculatorSpecification | null;
  conversionUnit: Unit | null;
  editable: boolean;
  identifier: string;
  notesEditable: boolean;
  product: Product;
  productUse: ProductUseWithOrder;
  removable: boolean;
  switchable: boolean;
  unit: Unit | null;
}

export const makeSortedProductUsesWithData = (
  productUses: ProductUsesDict,
  productLookup: (url: ProductUrl) => Product | undefined,
  unitLookup: (url: UnitUrl) => Unit | undefined,
  autoProductUses: Set<string> | null,
  readonly: boolean | undefined,
  readonlyProducts: ReadonlySet<string> | undefined,
  customerSettings: Config,
  productsWithLogData: ReadonlySet<ProductUrl> | undefined,
  calculators: CalculatorSpecifications | undefined,
): ProductUseWithData[] => {
  return _.sortBy(
    Object.entries(productUses)
      .map(([identifier, productUse]) => {
        const productUrl = productUse.product;
        const product = productLookup(productUrl);
        if (!product) {
          return undefined;
        }
        const unit = product.relatedUnit ? unitLookup(product.relatedUnit) || null : null;
        const conversionUnit =
          product.conversionUnit && product.conversionFactor
            ? unitLookup(product.conversionUnit) || null
            : null;
        const autoProduct = autoProductUses?.has(identifier) || false;
        const editable = !readonly && !readonlyProducts?.has(productUrl) && !autoProduct;
        const notesEditable =
          !readonly &&
          (!autoProduct || !customerSettings.autoCopyMaterialNoteToSupplementingProductNote);
        const removable =
          !readonly &&
          !autoProduct &&
          customerSettings.enableAddProducts &&
          productUse.count === null &&
          !productsWithLogData?.has(productUrl);
        const switchable = !readonly && !autoProduct && customerSettings.enableAddProducts;
        const calculator =
          calculators && unit ? calculators[unit.name.toLocaleLowerCase()] || null : null;
        return {
          autoProduct,
          calculator,
          conversionUnit,
          editable,
          identifier,
          notesEditable,
          product,
          productUse,
          removable,
          switchable,
          unit,
        };
      })
      .filter(notUndefined),
    (entry) => entry.productUse.order,
  );
};
