import {Patch, PriceItemUsesDict, Task} from "@co-common-libs/resources";
import {getTaskTimersWithTime} from "@co-common-libs/resources-utils";
import {notNull} from "@co-common-libs/utils";
import {patchFromPriceItemUsesChange, recomputePriceItemUses} from "@co-frontend-libs/utils";
import _ from "lodash";
import {getExtendedCustomerSettings} from "../../complex-selectors";
import {ProvisionaryCommand} from "../../resources/actions";
import {
  getPriceGroupLookup,
  getPriceItemLookup,
  getTimerLookup,
  getTimerStartArray,
  getUnitLookup,
} from "../../resources/selectors";
import {ResourcesAuthenticationMiddlewareAPI} from "../types";

// Timer price item special case: May only be removed on stop corrections that
// remove all relevant timer time -- if added via timer that we don't have time
// for here, assume that we're missing context.
export function updatePriceItemUses(
  newTask: Task | null,
  oldTask: Task | undefined,
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  command: ProvisionaryCommand,
): Patch<Task> | null {
  if (!newTask) {
    return null;
  }

  if (newTask.recordedInC5 || newTask.archivable || !newTask.order) {
    return null;
  }

  if (
    _.isEqual(newTask.machineuseSet, oldTask?.machineuseSet) &&
    newTask.priceGroup === oldTask?.priceGroup &&
    _.isEqual(newTask.machineOperatorTimeCorrectionSet, oldTask.machineOperatorTimeCorrectionSet) &&
    _.isEqual(newTask.managerTimeCorrectionSet, oldTask.managerTimeCorrectionSet) &&
    _.isEqual(newTask.department, oldTask.department)
  ) {
    // no price group or department properties touched; so no changes to price groups
    return null;
  }

  if (
    !_.isEqual(newTask.priceItemUses, oldTask?.priceItemUses) &&
    !((!oldTask && command.action === "CREATE_OR_UPDATE") || command.action === "CREATE")
  ) {
    // priceitemuseSet is provided, assume that it is correct; don't recompute
    // ... except on create; so we don't have to be all that careful wrt.
    // taskCopyFields...
    return null;
  }
  const state = middlewareApi.getState();

  if (
    oldTask &&
    _.isEqual(newTask.machineOperatorTimeCorrectionSet, oldTask.machineOperatorTimeCorrectionSet) &&
    _.isEqual(newTask.managerTimeCorrectionSet, oldTask.managerTimeCorrectionSet) &&
    newTask.priceGroup === oldTask.priceGroup &&
    newTask.department === oldTask.department &&
    _.isEqual(
      (newTask.machineuseSet || []).map((m) => m.priceGroup).filter(notNull),
      (oldTask.machineuseSet || []).map((m) => m.priceGroup).filter(notNull),
    )
  ) {
    // no changes to price groups
    return null;
  }
  const priceGroupLookup = getPriceGroupLookup(state);
  const timerLookup = getTimerLookup(state);
  const priceItemLookup = getPriceItemLookup(state);
  const unitLookup = getUnitLookup(state);

  const timerStarteArray = getTimerStartArray(state);
  const taskURL = command.url;
  const taskTimerStartArray = timerStarteArray.filter((timerStart) => timerStart.task === taskURL);

  const timersWithTime = getTaskTimersWithTime(newTask, taskTimerStartArray);

  // allow removal of price items for timers whose time was removed
  // by adding/removing time correction...
  const removedTimers = new Set<string>();
  if (
    oldTask &&
    (!_.isEqual(
      newTask.machineOperatorTimeCorrectionSet,
      oldTask.machineOperatorTimeCorrectionSet,
    ) ||
      !_.isEqual(newTask.managerTimeCorrectionSet, oldTask.managerTimeCorrectionSet))
  ) {
    const oldTimersWithTime = getTaskTimersWithTime(oldTask, taskTimerStartArray);
    for (const timerURL of oldTimersWithTime) {
      if (!timersWithTime.has(timerURL)) {
        removedTimers.add(timerURL);
      }
    }
  }

  const customerSettings = getExtendedCustomerSettings(state);
  const priceItemUses = recomputePriceItemUses(
    newTask as Task & {
      readonly priceItemUses: PriceItemUsesDict;
    },
    timersWithTime,
    timerLookup,
    priceGroupLookup,
    priceItemLookup,
    unitLookup,
    customerSettings,
    removedTimers,
  );
  if (priceItemUses === newTask.priceItemUses) {
    return null;
  }
  const patch = patchFromPriceItemUsesChange(newTask.priceItemUses || {}, priceItemUses);
  if (!patch.length) {
    return null;
  }
  return patch;
}
