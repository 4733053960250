import {PriceGroupUrl, Timer} from "@co-common-libs/resources";
import {ConnectedPriceGroupDialog} from "@co-frontend-libs/connected-components";
import {
  actions,
  getCurrentUserURL,
  getCustomerSettings,
  getPriceGroupArray,
  getPriceGroupLookup,
  getSettingsEntryLookupByIdentifier,
} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {Chip, IconButton} from "@material-ui/core";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import PlusIcon from "mdi-react/PlusIcon";
import React, {useCallback, useMemo, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuid} from "uuid";

interface DeleteChipProps {
  identifier: string;
  label: string;
  onDelete: (identifier: string) => void;
}

function DeleteChip(props: DeleteChipProps): JSX.Element {
  const {identifier, label, onDelete} = props;
  const handleDelete = useMemo(() => () => onDelete(identifier), [identifier, onDelete]);
  return <Chip label={label} onDelete={handleDelete} />;
}

interface PriceGroupHideTimersProps {
  timer: Timer;
}

export function PriceGroupHideTimers(props: PriceGroupHideTimersProps): JSX.Element {
  const {timer} = props;
  const timerID = timer.identifier;

  const dispatch = useDispatch();

  const customerSettings = useSelector(getCustomerSettings);
  const priceGroupArray = useSelector(getPriceGroupArray);
  const priceGroupLookup = useSelector(getPriceGroupLookup);
  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const currentUserURL = useSelector(getCurrentUserURL);

  const {priceGroupHideTimers} = customerSettings;

  const [priceGroupDialogOpen, setPriceGroupDialogOpen] = useState(false);
  const setPriceGroupDialogOpenTrue = useCallWithTrue(setPriceGroupDialogOpen);
  const setPriceGroupDialogOpenFalse = useCallWithFalse(setPriceGroupDialogOpen);

  const priceGroupIDs = useMemo(
    () =>
      Object.entries(priceGroupHideTimers)
        .filter(([_priceGroup, timers]) => timers.includes(timerID))
        .map(([priceGroup, _timers]) => priceGroup),
    [priceGroupHideTimers, timerID],
  );

  const getPriceGroupLabel = useCallback(
    (priceGroupID: string) => {
      const priceGroup = priceGroupArray.find((p) => p.identifier === priceGroupID);
      if (priceGroup) {
        return `${priceGroupID}: ${priceGroup.name}`;
      } else {
        return priceGroupID;
      }
    },
    [priceGroupArray],
  );

  const updateSetting = useCallback(
    (value: any): void => {
      const settingID = "priceGroupHideTimers";
      const settingsEntry = settingsEntryLookupByIdentifier(settingID);
      if (settingsEntry) {
        if (!_.isEqual(value, settingsEntry.data)) {
          dispatch(
            actions.update(settingsEntry.url, [
              {member: "changedBy", value: currentUserURL},
              {member: "data", value},
            ]),
          );
        }
      } else {
        const id = uuid();
        const url = instanceURL("settingEntry", id);
        dispatch(
          actions.create({
            changedBy: currentUserURL,
            data: value,
            key: settingID,
            url,
          }),
        );
      }
    },
    [currentUserURL, dispatch, settingsEntryLookupByIdentifier],
  );

  const handleRemovePriceGroup = useCallback(
    (identifier: string) => {
      const oldTimerArray = priceGroupHideTimers[identifier] || [];
      const newTimerArray = oldTimerArray.filter((x) => x !== timerID);
      const newValue = {...priceGroupHideTimers};
      if (newTimerArray.length) {
        newValue[identifier] = newTimerArray;
      } else {
        delete newValue[identifier];
      }
      updateSetting(newValue);
    },
    [priceGroupHideTimers, timerID, updateSetting],
  );

  const handleAddPriceGroup = useCallback(
    (url: PriceGroupUrl) => {
      setPriceGroupDialogOpen(false);
      const priceGroup = priceGroupLookup(url);
      if (!priceGroup || priceGroupIDs.includes(priceGroup.identifier)) {
        return;
      }
      const {identifier} = priceGroup;
      const oldTimerArray = priceGroupHideTimers[identifier] || [];
      const newTimerArray = _.uniq([...oldTimerArray, timerID]);
      const newValue = {...priceGroupHideTimers};
      if (newTimerArray.length) {
        newValue[identifier] = newTimerArray;
      } else {
        delete newValue[identifier];
      }
      updateSetting(newValue);
    },
    [priceGroupHideTimers, priceGroupIDs, priceGroupLookup, timerID, updateSetting],
  );

  return (
    <>
      <div>
        <h3>
          <FormattedMessage
            defaultMessage="Skjult for varianter"
            id="timers.header.hidden-for-variants"
          />
        </h3>
        <div>
          {priceGroupIDs.map((priceGroupID) => (
            <DeleteChip
              key={priceGroupID}
              identifier={priceGroupID}
              label={getPriceGroupLabel(priceGroupID)}
              onDelete={handleRemovePriceGroup}
            />
          ))}
          <IconButton onClick={setPriceGroupDialogOpenTrue}>
            <PlusIcon />
          </IconButton>
        </div>
      </div>
      <ConnectedPriceGroupDialog
        open={priceGroupDialogOpen}
        onCancel={setPriceGroupDialogOpenFalse}
        onOk={handleAddPriceGroup}
      />
    </>
  );
}
