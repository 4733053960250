import {Config} from "@co-common-libs/config";
import {Role, UserProfile} from "@co-common-libs/resources";
import {dateToString} from "@co-common-libs/utils";
import {DateField, ResponsiveDialog, TimeField, TrimTextField} from "@co-frontend-libs/components";
import {Checkbox, DialogContent, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import {PureComponent, getAbsenceTypeLabel} from "app-utils";
import {bind} from "bind-decorator";
import _ from "lodash";
import React from "react";
// Allowed for existing code...
// eslint-disable-next-line deprecate/import
import {Cell, Grid} from "react-flexr";
import {IntlContext, defineMessages} from "react-intl";

const messages = defineMessages({
  cancel: {
    defaultMessage: "Fortryd",
    id: "dialog.label.cancel",
  },
  employeeReason: {
    defaultMessage: "Medarbejder note",
    id: "register-absence.label.employeeReason",
  },
  fromDate: {
    defaultMessage: "Fra dato",
    id: "register-absence.label.from-date",
  },
  fromTime: {
    defaultMessage: "Fra klokken",
    id: "register-absence.label.from-time",
  },
  note: {
    defaultMessage: "Note",
    id: "register-absence.label.note",
  },
  ok: {
    defaultMessage: "OK",
    id: "dialog.label.ok",
  },
  onlyWeekdays: {
    defaultMessage: "Kun hverdage",
    id: "register-absence.label.only-weekdays",
  },
  registerAbsence: {
    defaultMessage: "Registrer fravær",
    id: "register-absence.label.register-absence",
  },
  registerAbsenceDialogTitle: {
    defaultMessage: "Registrer fravær for {initials}",
    id: "register-absence.dialog-title.register-absence",
  },
  toDate: {
    defaultMessage: "Til dato",
    id: "register-absence.label.to-date",
  },
  toTime: {
    defaultMessage: "Til klokken",
    id: "register-absence.label.to-time",
  },
});

function absenceTypeUsesDateRange(customerSettings: Config, absenceType: string | null): boolean {
  if (!absenceType) {
    return false;
  }
  return (
    !customerSettings.hoursAbsenceTypes.includes(absenceType) &&
    !customerSettings.singleDayAbsenceTypes.includes(absenceType)
  );
}

function absenceTypeUsesTimeSelection(
  customerSettings: Config,
  absenceType: string | null,
): boolean {
  if (!absenceType) {
    return false;
  }
  return customerSettings.hoursAbsenceTypes.includes(absenceType);
}

interface RegisterAbsenceDialogProps {
  currentRole?: Role | undefined;
  currentUserURL?: string | undefined;
  customerSettings: Config;
  initialFromDate?: string | undefined;
  onCancel: () => void;
  onOk: (result: {
    absenceType: string;
    employeeReason: string;
    fromDate: string;
    fromTime: string | null;
    note: string;
    onlyWeekdays: boolean | undefined;
    toDate: string;
    toTime: string | null;
  }) => void;
  open: boolean;
  userProfile: UserProfile | undefined;
}

interface RegisterAbsenceDialogState {
  absenceType: string | null;
  employeeReason: string;
  fromDate: string;
  fromTime: string | null;
  note: string;
  onlyWeekdays: boolean;
  toDate: string;
  toTime: string | null;
}

export class RegisterAbsenceDialog extends PureComponent<
  RegisterAbsenceDialogProps,
  RegisterAbsenceDialogState
> {
  state: RegisterAbsenceDialogState = {
    absenceType: null,
    employeeReason: "",
    fromDate: this.props.initialFromDate ?? dateToString(new Date()),
    fromTime: null,
    note: "",
    onlyWeekdays: true,
    toDate: dateToString(new Date()),
    toTime: null,
  };
  UNSAFE_componentWillReceiveProps(nextProps: RegisterAbsenceDialogProps): void {
    if (nextProps.open !== this.props.open) {
      this.setState({
        absenceType: null,
        employeeReason: "",
        fromDate: nextProps.initialFromDate ?? dateToString(new Date()),
        fromTime: null,
        note: "",
        onlyWeekdays: true,
        toDate: nextProps.initialFromDate ?? dateToString(new Date()),
        toTime: null,
      });
    }
  }
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  @bind
  handleRadioButtonChange(_event: React.ChangeEvent<HTMLInputElement>, value: string): void {
    const {customerSettings} = this.props;
    const onlyWeekdaysAllowed = customerSettings.onlyWeekdaysAllowedAbsenceTypes.includes(value);
    this.setState({absenceType: value});
    if (onlyWeekdaysAllowed) {
      this.setState({onlyWeekdays: true});
    }
    if (!absenceTypeUsesDateRange(customerSettings, value)) {
      this.setState({
        toDate: this.state.fromDate,
      });
    }
  }
  @bind
  handleFromTimeChange(newValue: string | null): void {
    this.setState({fromTime: newValue});
  }
  @bind
  handleToTimeChange(newValue: string | null): void {
    this.setState({toTime: newValue});
  }
  @bind
  handleFromDateChange(newValue: string | null): void {
    const {customerSettings} = this.props;
    const {absenceType} = this.state;
    const newDate = newValue || "";
    if (
      !absenceTypeUsesDateRange(customerSettings, absenceType) ||
      !this.state.toDate ||
      (newValue && newValue > this.state.toDate)
    ) {
      // * overwrite toDate if not using date range
      // * overwrite toDate as "suggestion" if toDate blank
      // * overwrite toDate as "suggestion" if date range otherwise invalid
      this.setState({
        fromDate: newDate,
        toDate: newDate,
      });
    } else {
      this.setState({fromDate: newDate});
    }
  }
  @bind
  handleToDateChange(newValue: string | null): void {
    this.setState({toDate: newValue || ""});
  }
  @bind
  handleWeekdaysCheck(_event: React.ChangeEvent<unknown>, newValue: boolean): void {
    this.setState({onlyWeekdays: newValue});
  }
  @bind
  handleNoteChange(value: string): void {
    this.setState({note: value});
  }
  @bind
  handleEmployeeReasonChange(value: string): void {
    this.setState({employeeReason: value});
  }
  @bind
  handleOk(): void {
    const {absenceType, employeeReason, fromDate, fromTime, note, onlyWeekdays, toDate, toTime} =
      this.state;
    if (!absenceType) {
      return;
    }
    const {customerSettings} = this.props;
    const result = {
      absenceType,
      employeeReason,
      fromDate,
      fromTime,
      note,
      onlyWeekdays: !absenceTypeUsesDateRange(customerSettings, absenceType)
        ? undefined
        : onlyWeekdays,
      toDate,
      toTime,
    };
    this.props.onOk(result);
  }
  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {customerSettings} = this.props;
    const {open, userProfile} = this.props;
    const {absenceType, fromDate, fromTime, onlyWeekdays, toDate, toTime} = this.state;
    const usesTimeSelection = absenceTypeUsesTimeSelection(customerSettings, absenceType);
    const usesDateRange = absenceTypeUsesDateRange(customerSettings, absenceType);
    const okDisabled =
      !absenceType ||
      !fromDate ||
      !toDate ||
      fromDate > toDate ||
      (usesTimeSelection && (!fromTime || !toTime || (fromDate === toDate && fromTime > toTime)));

    let absenceTypeList = customerSettings.hoursAbsenceTypes.concat(
      customerSettings.daysAbsenceTypes,
      customerSettings.singleDayAbsenceTypes,
    );
    const role = this.props.currentRole;
    const userIsManager = role && role.manager;
    const {absenceTypesAccessibleToEmployees} = customerSettings;
    if (!userIsManager && absenceTypesAccessibleToEmployees != null) {
      absenceTypeList = absenceTypeList.filter((name) =>
        absenceTypesAccessibleToEmployees.includes(name),
      );
    }
    const radioButtons = _.orderBy(
      absenceTypeList.map((name) => ({
        label: getAbsenceTypeLabel(name, customerSettings.absenceTypeLabels, formatMessage),
        name,
      })),
      ({label}) => label,
    ).map(({label, name}) => {
      return <FormControlLabel key={name} control={<Radio />} label={label} value={name} />;
    });

    const initials = userProfile?.alias;
    const userIsCurrentUser =
      this.props.currentUserURL !== undefined && this.props.currentUserURL === userProfile?.user;

    let noteField;
    let employeeReasonField;
    let employeeReasonFieldTitle = formatMessage(messages.note);
    if (userIsManager) {
      noteField = (
        <TrimTextField
          fullWidth
          multiline
          label={formatMessage(messages.note)}
          margin="dense"
          maxRows={30}
          minRows={2}
          value={this.state.note}
          variant="outlined"
          onChange={this.handleNoteChange}
        />
      );
      employeeReasonFieldTitle = formatMessage(messages.employeeReason);
    }
    if (userIsCurrentUser || userIsManager) {
      employeeReasonField = (
        <TrimTextField
          fullWidth
          multiline
          disabled={!userIsCurrentUser}
          label={employeeReasonFieldTitle}
          margin="dense"
          maxRows={30}
          minRows={2}
          value={this.state.employeeReason}
          variant="outlined"
          onChange={this.handleEmployeeReasonChange}
        />
      );
    }

    let timeFields;
    if (customerSettings.hoursAbsenceTypes.length) {
      timeFields = (
        <Grid>
          <Cell palm="12/12">
            <TimeField
              fullWidth
              disabled={!usesTimeSelection}
              label={formatMessage(messages.fromTime)}
              margin="dense"
              value={this.state.fromTime != null ? this.state.fromTime : undefined}
              onChange={this.handleFromTimeChange}
            />
          </Cell>
          <Cell palm="12/12">
            <TimeField
              fullWidth
              disabled={!usesTimeSelection}
              label={formatMessage(messages.toTime)}
              margin="dense"
              value={this.state.toTime != null ? this.state.toTime : undefined}
              onChange={this.handleToTimeChange}
            />
          </Cell>
        </Grid>
      );
    }

    const onlyWeekdaysAllowed = !!(
      absenceType && customerSettings.onlyWeekdaysAllowedAbsenceTypes.includes(absenceType)
    );
    return (
      <ResponsiveDialog
        fullWidth
        maxWidth="md"
        okDisabled={okDisabled}
        open={open}
        title={formatMessage(messages.registerAbsenceDialogTitle, {
          initials,
        })}
        onCancel={this.props.onCancel}
        onOk={this.handleOk}
      >
        <DialogContent>
          <Grid>
            <Cell palm="12/12">
              <RadioGroup
                name="absenceType"
                value={this.state.absenceType}
                onChange={this.handleRadioButtonChange}
              >
                {radioButtons}
              </RadioGroup>
            </Cell>
            <Cell palm="12/12">
              <Grid>
                <Cell palm="12/12">
                  <DateField
                    autoOk
                    fullWidth
                    label={formatMessage(messages.fromDate)}
                    margin="dense"
                    value={this.state.fromDate}
                    onChange={this.handleFromDateChange}
                  />
                </Cell>
                <Cell palm="12/12">
                  <DateField
                    autoOk
                    fullWidth
                    disabled={!usesDateRange}
                    label={formatMessage(messages.toDate)}
                    margin="dense"
                    value={this.state.toDate}
                    onChange={this.handleToDateChange}
                  />
                </Cell>
              </Grid>
              <Grid>
                <Cell>
                  <FormControlLabel
                    checked={!usesDateRange || onlyWeekdaysAllowed || onlyWeekdays}
                    control={<Checkbox />}
                    disabled={!usesDateRange || onlyWeekdaysAllowed}
                    label={formatMessage(messages.onlyWeekdays)}
                    onChange={this.handleWeekdaysCheck}
                  />
                </Cell>
              </Grid>
              {timeFields}
            </Cell>
          </Grid>
          {noteField}
          {employeeReasonField}
        </DialogContent>
      </ResponsiveDialog>
    );
  }
}
