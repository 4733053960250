import {Location, LocationTypeUrl, LocationUrl} from "@co-common-libs/resources";
import {LocationDialog} from "@co-frontend-libs/components";
import {
  actions,
  getCurrentUserURL,
  getCustomerLookup,
  getCustomerSettings,
  getLocationArray,
  getLocationUseLogArray,
} from "@co-frontend-libs/redux";
import {createLocation} from "app-utils";
import React, {useCallback, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {FieldMultiSelectionDialog} from "../../customer-field-dialog";
import {LocationCreateEditDialog} from "../../location-create-edit-dialog";
import {
  LocationFieldSelectionEvent,
  LocationFieldSelectionState,
} from "./location-field-selection-state-machine";

interface LocationFieldSelectionDisplayProps {
  send: (event: LocationFieldSelectionEvent) => void;
  state: LocationFieldSelectionState;
}

export function LocationFieldSelectionDisplay(
  props: LocationFieldSelectionDisplayProps,
): JSX.Element {
  const {send, state} = props;

  const customerSettings = useSelector(getCustomerSettings);
  const customerLookup = useSelector(getCustomerLookup);
  const locationArray = useSelector(getLocationArray);
  const filteredCustomerLocationArray = locationArray.filter(
    (location) =>
      location.active && location.customer === state.context.customer && !location.geojson,
  );

  const dispatch = useDispatch();

  const handleCancel = useCallback(() => {
    send({type: "CANCEL"});
  }, [send]);

  const handleLocationAdd = useCallback(
    (searchText: string) => {
      send({searchText, type: "ADD_LOCATION"});
    },
    [send],
  );

  const handleLocationDialogNone = useCallback(() => {
    send({type: "NONE"});
  }, [send]);

  const handleLocationDialogOk = useCallback(
    (url: LocationUrl) => {
      send({type: "LOCATION_SELECTED", url});
    },
    [send],
  );

  const handleLocationAddDialogOk = useCallback(
    (data: {
      active: boolean;
      address: string;
      attention: string;
      city: string;
      coordinatesFromAddress: boolean;
      favorite: boolean;
      latitude: number | null;
      locationType: LocationTypeUrl | null;
      logOnlyLocation: boolean;
      longitude: number | null;
      name: string;
      phone: string;
      postalCode: string;
      workplaceOnlyLocation: boolean;
    }) => {
      if (!state.context.customer) {
        return;
      }
      const {
        address,
        attention,
        city,
        coordinatesFromAddress,
        favorite,
        latitude,
        locationType,
        logOnlyLocation,
        longitude,
        name,
        phone,
        postalCode,
        workplaceOnlyLocation,
      } = data;
      const instance: Location = createLocation({
        active: true,
        address,
        attention,
        city,
        coordinatesFromAddress,
        customer: state.context.customer,
        favorite,
        geojson: null,
        latitude,
        locationType,
        logOnlyLocation,
        longitude,
        name,
        phone,
        postalCode,
        workplaceOnlyLocation,
      });
      dispatch(actions.create(instance));
      send({type: "CREATED", url: instance.url});
    },
    [dispatch, send, state.context.customer],
  );

  const handleFieldDialogOk = useCallback(
    (urls: ReadonlySet<LocationUrl>) => {
      send({type: "FIELDS_SELECTED", urls});
    },
    [send],
  );

  const locationUseLogArray = useSelector(getLocationUseLogArray);
  const machineOperator = useSelector(getCurrentUserURL);
  const lastUsedLocations = useMemo(() => {
    if (state.context.customer && machineOperator) {
      const locationUse = locationUseLogArray.find(
        (l) => l.customer === state.context.customer && l.user === machineOperator,
      );
      return new Set(locationUse?.locations);
    }
    return undefined;
  }, [locationUseLogArray, machineOperator, state.context.customer]);

  return (
    <>
      <LocationDialog
        includeWorkplaceOnlyLocations
        customerLookup={customerLookup}
        customerURL={state.context.customer}
        includeLogOnlyLocations={false}
        lastUsedLocations={lastUsedLocations}
        locationArray={filteredCustomerLocationArray}
        locationCrossCustomerSelectionEnabled={
          customerSettings.locationCrossCustomerSelectionEnabled
        }
        locationFavoritesEnabled={customerSettings.locationFavoritesEnabled}
        open={state.matches("selectLocation")}
        titleVariant="WORKPLACE"
        onAdd={state.context.locationCreationAllowed ? handleLocationAdd : undefined}
        onCancel={handleCancel}
        onNone={handleLocationDialogNone}
        onOk={handleLocationDialogOk}
      />
      <LocationCreateEditDialog
        initialSearch={state.context.locationCreateInitialAddress ?? undefined}
        locationFavoritesEnabled={customerSettings.locationFavoritesEnabled}
        logOnlyLocation={false}
        open={state.matches("createNewLocation")}
        workplaceOnlyLocation={customerSettings.setWorkplaceOnlyLocationOnCreate}
        onCancel={handleCancel}
        onOk={handleLocationAddDialogOk}
      />
      <FieldMultiSelectionDialog
        customerURL={state.context.customer}
        open={state.matches("selectFields")}
        onCancel={handleCancel}
        onOk={handleFieldDialogOk}
      />
    </>
  );
}
