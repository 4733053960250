import {Config} from "@co-common-libs/config";
import {Machine} from "@co-common-libs/resources";
import {convertResourceArrayToMap} from "@co-common-libs/utils";
import {createSelector} from "reselect";
import {getCustomerSettings, getMachineArray} from "../resources/selectors";
import {mapToLookupSelector} from "../resources/selectors/resources-helpers";
import {AppState} from "../root-reducer";

function filterOutDisabledAndInactiveMachines(
  machineArray: readonly Machine[],
  customerSettings: Config,
): readonly Machine[] {
  return customerSettings.disabledMachines
    ? machineArray.filter(
        (machine) =>
          !customerSettings.disabledMachines.includes(machine.c5_machine) && machine.active,
      )
    : machineArray.filter((machine) => machine.active);
}

export const getFilteredMachineArray: (state: AppState) => readonly Machine[] = createSelector(
  getMachineArray,
  getCustomerSettings,
  filterOutDisabledAndInactiveMachines,
);

/** @deprecated */
const getFilteredMachineMap: (state: AppState) => ReadonlyMap<string, Machine> = createSelector(
  getFilteredMachineArray,
  convertResourceArrayToMap,
);

export const getFilteredMachineLookup = mapToLookupSelector(getFilteredMachineMap);
