import {Config} from "@co-common-libs/config";
import {
  CalculatorSpecification,
  CalculatorSpecifications,
  PriceItem,
  PriceItemUrl,
  PriceItemUse,
  Product,
  ProductGroup,
  ProductGroupUrl,
  ProductUrl,
  ProductUse,
  Role,
  Unit,
  UnitUrl,
} from "@co-common-libs/resources";
import {formatUnitString, getUnitCode, priceItemIsVisible} from "@co-common-libs/resources-utils";
import {notNull} from "@co-common-libs/utils";
import {ThrottledTextField} from "@co-frontend-libs/components";
import {
  AppState,
  getPriceItemLookup,
  getProductGroupLookup,
  getProductLookup,
  getUnitLookup,
} from "@co-frontend-libs/redux";
import {
  FormControlLabel,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {PureComponent, legacyGetAutoProductsMapping} from "app-utils";
import {bind} from "bind-decorator";
import bowser from "bowser";
import _ from "lodash";
import DeleteIcon from "mdi-react/DeleteIcon";
import PlusIcon from "mdi-react/PlusIcon";
import memoizeOne from "memoize-one";
import React from "react";
// Allowed for existing code.
// eslint-disable-next-line deprecate/import
import {Cell, Grid} from "react-flexr";
import {FormattedMessage, IntlContext, defineMessages} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {CalculatorButton} from "./calculator-button";
import {MaterialField} from "./material-field";
import {MobileMaterialEntry} from "./mobile-material-entry";

const MOBILE = bowser.mobile;

const messages = defineMessages({
  notesLabel: {
    defaultMessage: "Noter",
    id: "product-table.label.notes",
  },
  oursLabel: {
    defaultMessage: "Vores",
    id: "product-table.label.ours",
  },
});

const INPUT_FIELD_WIDTH = 96;
const TABLE_PADDING = 48;
const UNIT_COLUMN_WIDTH = 78;
const DELETE_COLUMN_WIDTH = 72;
const CONVERSION_UNIT_COLUMN_WIDTH = 86;
const COUNT_COLUMN_WIDTH = INPUT_FIELD_WIDTH + TABLE_PADDING;
const TOGGLE_COLUMN_WIDTH = 124;
const BUTTON_COLUMN_STYLE = {paddingLeft: 0, paddingRight: 0, width: 48};

interface ProductEntryProps {
  addedBy: string | null;
  baseUnit?: string | undefined;
  baseValue?: number | undefined;
  calculator?: CalculatorSpecification | undefined;
  currentUserURL: string | null;
  customerSettings: Config;
  deleteDisabled?: boolean;
  disabled: boolean;
  hasConversions: boolean;
  index: number;
  isAutoLine: boolean;
  isManager: boolean;
  notes: string;
  onChange: (index: number, value: number | null) => void;
  onDeleteClick: (index: number) => void;
  onDetailButtonClick?:
    | ((
        notes: string,
        ours: boolean,
        name: string,
        disabled: boolean,
        index: number,
        onDelete: () => void,
        isBlank: boolean,
      ) => void)
    | undefined;
  onNotesChange: (index: number, value: string) => void;
  onOursChange: (event: React.FormEvent<unknown>, index: number, value: boolean) => void;
  ours: boolean;
  product: Product;
  showIconColumn: boolean;
  showNotes?: boolean | undefined;
  unit: string;
  usesConversion: boolean;
  value?: number | undefined;
}

class ProductEntry extends PureComponent<ProductEntryProps> {
  static defaultProps = {
    showNotes: true,
  };
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  @bind
  handleRelationChange(value: number | null): void {
    if (value == null) {
      return;
    }
    const {baseValue} = this.props;
    if (baseValue == null) {
      return;
    }
    const resultValue = _.round(baseValue * value, this.props.customerSettings.materialDecimals);
    this.handleChange(resultValue);
  }

  @bind
  handleChange(value: number | null): void {
    const {index, onChange} = this.props;
    onChange(index, value);
  }

  @bind
  handlePlusClick(_event: React.MouseEvent<unknown, MouseEvent>): void {
    const {value} = this.props;
    const newValue = value != null ? value + 1 : 0;
    this.handleChange(newValue);
  }

  @bind
  handleNotesChange(value: string): void {
    const {index, onNotesChange} = this.props;
    onNotesChange(index, value);
  }

  @bind
  handleOursChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const {checked} = event.target;
    const {index, onOursChange} = this.props;
    onOursChange(event, index, checked);
  }

  @bind
  handleDeleteClick(): void {
    const {index, onDeleteClick} = this.props;
    if (onDeleteClick) {
      onDeleteClick(index);
    }
  }

  @bind
  handleDetailButtonClick(): void {
    const {
      deleteDisabled,
      disabled,
      index,
      isAutoLine,
      notes,
      onDetailButtonClick,
      ours,
      product,
      value,
    } = this.props;
    if (onDetailButtonClick) {
      const name = `${product.name} (${product.catalogNumber})`;
      onDetailButtonClick(
        notes,
        ours,
        name,
        disabled,
        index,
        this.handleDeleteClick,
        !isAutoLine && !value && !deleteDisabled,
      );
    }
  }

  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {
      addedBy,
      baseUnit,
      baseValue,
      calculator,
      currentUserURL,
      customerSettings,
      deleteDisabled,
      disabled,
      hasConversions,
      isAutoLine,
      isManager,
      notes,
      ours,
      product,
      showIconColumn,
      showNotes,
      unit,
      value,
    } = this.props;
    const {showOursToggle} = this.props.customerSettings;
    const productString = `${product.name} (${product.catalogNumber})`;

    if (MOBILE) {
      return (
        <MobileMaterialEntry
          baseUnit={baseUnit}
          baseValue={baseValue}
          calculator={calculator}
          decimalPlaces={this.props.customerSettings.materialDecimals}
          disabled={disabled}
          hasConversions={hasConversions}
          includeNoteInput={
            customerSettings.alternativeMaterialsMobileLayout &&
            customerSettings.enableMaterialNoteFields
          }
          name={productString}
          notes={notes}
          unit={unit}
          value={value}
          onChange={this.handleChange}
          onDetailButtonClick={
            customerSettings.enableMaterialNoteFields || customerSettings.showOursToggle
              ? this.handleDetailButtonClick
              : undefined
          }
          onNotesChange={this.handleNotesChange}
          onPlusClick={this.handlePlusClick}
          onRelationChange={this.handleRelationChange}
        />
      );
    } else {
      const deleteable = !value;
      const inputField = (
        <MaterialField
          customerSettings={this.props.customerSettings}
          disabled={disabled}
          unit={unit}
          value={value}
          onChange={this.handleChange}
        />
      );
      let notesColumn;
      if (showNotes) {
        notesColumn = (
          <TableCell>
            <ThrottledTextField
              fullWidth
              multiline
              disabled={isAutoLine}
              label={formatMessage(messages.notesLabel)}
              margin="dense"
              value={notes}
              variant="outlined"
              onChange={this.handleNotesChange}
            />
          </TableCell>
        );
      }
      let oursToggleColumn;
      if (showOursToggle) {
        oursToggleColumn = (
          <TableCell style={{width: TOGGLE_COLUMN_WIDTH}}>
            <FormControlLabel
              control={<Switch checked={ours} onChange={this.handleOursChange} />}
              disabled={disabled}
              label={formatMessage(messages.oursLabel)}
              labelPlacement="end"
            />
          </TableCell>
        );
      }
      const deleteButton = isAutoLine ? null : (
        <IconButton
          disabled={deleteDisabled || !deleteable}
          style={{float: "right"}}
          onClick={this.handleDeleteClick}
        >
          <DeleteIcon />
        </IconButton>
      );

      const plusButton = (
        <IconButton disabled={disabled} onClick={this.handlePlusClick}>
          <PlusIcon />
        </IconButton>
      );
      let conversionInputField;
      let conversionUnit;
      if (hasConversions && baseUnit) {
        let conversionValue;
        const decimalPlaces = this.props.customerSettings.conversionFieldDecimals;
        if (baseValue) {
          if (value != null) {
            const computedValue = value / baseValue;
            conversionValue = _.round(computedValue, decimalPlaces);
          }
        }
        conversionUnit = `${unit}/${baseUnit}`;
        conversionInputField = (
          <MaterialField
            customerSettings={this.props.customerSettings}
            decimalPlaces={decimalPlaces}
            disabled={disabled || !baseValue}
            unit={conversionUnit}
            value={conversionValue}
            onChange={this.handleRelationChange}
          />
        );
      }
      let conversionColumns;
      if (hasConversions) {
        conversionColumns = [];
        conversionColumns.push(
          <TableCell key="conversion-field" style={{width: COUNT_COLUMN_WIDTH}}>
            {conversionInputField}
          </TableCell>,
        );
        conversionColumns.push(
          <TableCell key="conversion-unit" style={{width: CONVERSION_UNIT_COLUMN_WIDTH}}>
            {conversionUnit}
          </TableCell>,
        );
      }
      let iconColumn;
      if (showIconColumn) {
        iconColumn = (
          <TableCell style={BUTTON_COLUMN_STYLE}>
            {calculator ? (
              <CalculatorButton
                disabled={disabled}
                specification={calculator}
                unit={unit}
                onInsertValue={this.handleChange}
              />
            ) : (
              plusButton
            )}
          </TableCell>
        );
      }
      return (
        <TableRow>
          <TableCell>{productString}</TableCell>
          <TableCell style={{width: COUNT_COLUMN_WIDTH}}>{inputField}</TableCell>
          {iconColumn}
          <TableCell style={{width: UNIT_COLUMN_WIDTH}}>{unit}</TableCell>
          {conversionColumns}
          {oursToggleColumn}
          {notesColumn}
          <TableCell style={{width: DELETE_COLUMN_WIDTH}}>
            {(isManager || (currentUserURL && addedBy === currentUserURL)) && deleteButton}
          </TableCell>
        </TableRow>
      );
    }
  }
}

function computeNotesChange(
  productUseList: readonly ProductUse[],
  index: number,
  value: string,
  linesToAutoLines: ReadonlyMap<number, readonly number[]>,
  autoLinesToLines: ReadonlyMap<number, readonly number[]>,
  autoCopyMaterialNoteToSupplementingProductNote: boolean,
): ProductUse[] {
  const result = productUseList.slice();
  result[index] = {...result[index], notes: value};
  if (autoCopyMaterialNoteToSupplementingProductNote && linesToAutoLines.has(index)) {
    const autoLines = linesToAutoLines.get(index) as number[];
    autoLines.forEach((autoLineIndex) => {
      const linesForAuto = autoLinesToLines.get(autoLineIndex) as number[];
      console.assert(linesForAuto);
      console.assert(linesForAuto.includes(index));
      if (linesForAuto.length > 1) {
        const values = linesForAuto.map((i) => result[i].notes).filter(Boolean);
        if (values.length) {
          const combined = values.join(" ");
          result[autoLineIndex] = {...result[autoLineIndex], notes: combined};
        } else {
          result[autoLineIndex] = {...result[autoLineIndex], notes: value};
        }
      } else {
        result[autoLineIndex] = {...result[autoLineIndex], notes: value};
      }
    });
  }
  return result;
}

function computeCountChange(
  productUseList: readonly ProductUse[],
  index: number,
  value: number | null,
  linesToAutoLines: ReadonlyMap<number, readonly number[]>,
  autoLinesToLines: ReadonlyMap<number, readonly number[]>,
): ProductUse[] {
  const result = productUseList.slice();
  result[index] = {...result[index], count: value};
  if (linesToAutoLines.has(index)) {
    const autoLines = linesToAutoLines.get(index) as number[];
    autoLines.forEach((autoLineIndex) => {
      const linesForAuto = autoLinesToLines.get(autoLineIndex) as number[];
      console.assert(linesForAuto);
      console.assert(linesForAuto.includes(index));
      if (linesForAuto.length > 1) {
        const values = linesForAuto.map((i) => result[i].count).filter(notNull);
        if (values.length) {
          const sum = values.reduce((acc, val) => acc + val, 0);
          result[autoLineIndex] = {...result[autoLineIndex], count: sum};
        } else {
          result[autoLineIndex] = {...result[autoLineIndex], count: value};
        }
      } else {
        result[autoLineIndex] = {...result[autoLineIndex], count: value};
      }
    });
  }
  return result;
}

function computeDelete(
  productUseList: readonly ProductUse[],
  index: number,
  linesToAutoLines: ReadonlyMap<number, readonly number[]>,
  autoLinesToLines: ReadonlyMap<number, readonly number[]>,
): ProductUse[] {
  const result = productUseList.slice();
  const toDelete = new Set([index]);
  if (linesToAutoLines.has(index)) {
    const autoLines = linesToAutoLines.get(index) as number[];
    autoLines.forEach((autoLineIndex) => {
      const linesForAuto = autoLinesToLines.get(autoLineIndex) as number[];
      console.assert(linesForAuto);
      console.assert(linesForAuto.includes(index));
      if (linesForAuto.length <= 1) {
        toDelete.add(autoLineIndex);
      } else {
        const countValues = linesForAuto
          .map((i) => result[i].count)
          .filter(notNull)
          .filter((_v, i) => !toDelete.has(i));
        if (countValues.length) {
          const sum = countValues.reduce((acc, val) => acc + val, 0);
          result[autoLineIndex] = {...result[autoLineIndex], count: sum};
        } else {
          result[autoLineIndex] = {...result[autoLineIndex], count: null};
        }
        const notesValues = linesForAuto
          .map((i) => result[i].notes)
          .filter((v, i) => !!v && !toDelete.has(i));
        if (notesValues.length) {
          const combined = notesValues.join(" ");
          result[autoLineIndex] = {...result[autoLineIndex], notes: combined};
        } else {
          result[autoLineIndex] = {...result[autoLineIndex], notes: ""};
        }
      }
    });
  }
  return result.filter((_value, i) => !toDelete.has(i));
}

interface LegacyProductTableStateProps {
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined;
  productGroupLookup: (url: ProductGroupUrl) => ProductGroup | undefined;
  productLookup: (url: ProductUrl) => Product | undefined;
  unitLookup: (url: UnitUrl) => Unit | undefined;
}

interface LegacyProductTableOwnProps {
  baseUnit?: string | undefined;
  baseValue?: number | undefined;
  calculators?: CalculatorSpecifications;
  currentRole: Role | null;
  currentUserURL: string | null;
  customerSettings: Config;
  deleteDisabled?: boolean;
  onChange: (productUseList: ProductUse[]) => void;
  onDetailButtonClick?: (
    notes: string,
    ours: boolean,
    name: string,
    disabled: boolean,
    index: number,
    onDelete: () => void,
    isBlank: boolean,
  ) => void;
  priceItemUseList: readonly PriceItemUse[];
  productUseList: readonly ProductUse[];
  readonly: boolean;
  readonlyUnits: ReadonlySet<string> | null;
  showEvenIfEmpty?: boolean;
  showNotes?: boolean;
}

type LegacyProductTableProps = LegacyProductTableOwnProps & LegacyProductTableStateProps;

class LegacyProductTable extends PureComponent<LegacyProductTableProps> {
  static defaultProps = {
    showNotes: true,
  };
  getAutoProductsMapping = memoizeOne(legacyGetAutoProductsMapping);

  @bind
  handleProductUseCountChange(index: number, value: number | null): void {
    const {customerSettings, onChange, productGroupLookup, productLookup, productUseList} =
      this.props;
    const productUse = productUseList[index];
    if (!productUse) {
      return;
    }
    const productURL = productUse.product;
    const product = productLookup(productURL);
    if (!product) {
      return;
    }
    let roundedValue;
    if (value) {
      let baseValue = value;
      const conversionUnitURL = product.conversionUnit;
      const {conversionFactor} = product;
      if (conversionUnitURL && conversionFactor) {
        baseValue = value * conversionFactor;
      }
      const maxStoredDecimals = 5;
      roundedValue = _.round(baseValue, maxStoredDecimals);
    } else {
      // zero or null/clear
      console.assert(!value);
      roundedValue = value;
    }
    const {autoLinesToLines, linesToAutoLines} = this.getAutoProductsMapping(
      productUseList,
      productLookup,
      productGroupLookup,
      customerSettings,
    );
    const newProductUseList = computeCountChange(
      productUseList,
      index,
      roundedValue,
      linesToAutoLines,
      autoLinesToLines,
    );
    onChange(newProductUseList);
  }

  @bind
  handleProductNotesChange(index: number, value: string): void {
    const {customerSettings, onChange, productGroupLookup, productLookup, productUseList} =
      this.props;
    const productUse = productUseList[index];
    if (!productUse) {
      return;
    }
    const {autoLinesToLines, linesToAutoLines} = this.getAutoProductsMapping(
      productUseList,
      productLookup,
      productGroupLookup,
      customerSettings,
    );
    const newProductUseList = computeNotesChange(
      productUseList,
      index,
      value,
      linesToAutoLines,
      autoLinesToLines,
      customerSettings.autoCopyMaterialNoteToSupplementingProductNote,
    );
    onChange(newProductUseList);
  }

  @bind
  handleDeleteClick(index: number): void {
    const {customerSettings, onChange, productGroupLookup, productLookup, productUseList} =
      this.props;
    const productUse = productUseList[index];
    if (!productUse) {
      return;
    }
    const {autoLinesToLines, linesToAutoLines} = this.getAutoProductsMapping(
      productUseList,
      productLookup,
      productGroupLookup,
      customerSettings,
    );
    const newProductUseList = computeDelete(
      productUseList,
      index,
      linesToAutoLines,
      autoLinesToLines,
    );
    onChange(newProductUseList);
  }

  @bind
  handleOursChange(_event: unknown, index: number, value: boolean): void {
    const {onChange, productUseList} = this.props;
    const newProductUseList = productUseList.slice();
    newProductUseList[index] = {...newProductUseList[index], ours: value};
    onChange(newProductUseList);
  }

  render(): JSX.Element | null {
    if (this.props.customerSettings.noProducts) {
      return null;
    }
    const {
      calculators,
      customerSettings,
      deleteDisabled,
      productGroupLookup,
      productLookup,
      readonlyUnits,
      showEvenIfEmpty,
      showNotes,
      unitLookup,
    } = this.props;
    const role = this.props.currentRole;
    const isManager = role && role.manager;
    const {productUseList} = this.props;
    if ((!productUseList || !productUseList.length) && !showEvenIfEmpty) {
      return null;
    }
    const {autoLinesToLines} = this.getAutoProductsMapping(
      productUseList,
      productLookup,
      productGroupLookup,
      customerSettings,
    );

    const hasConversions = !!Object.keys(
      this.props.customerSettings.priceItemRelationUnitConversionHelpers,
    ).length;
    const outputToInputConversionMapping: {[unit: string]: string[]} = {};
    if (hasConversions) {
      Object.entries(this.props.customerSettings.priceItemRelationUnitConversionHelpers).forEach(
        ([baseUnit, unitList]) => {
          if (unitList) {
            unitList.forEach((unit) => {
              if (!outputToInputConversionMapping[unit]) {
                outputToInputConversionMapping[unit] = [baseUnit];
              } else {
                outputToInputConversionMapping[unit].push(baseUnit);
              }
            });
          }
        },
      );
    }
    let showConversionColumns = false;
    const productList = productUseList
      .map((productUse, index) => {
        const url = productUse.product;
        const product = productLookup(url);
        if (!product) {
          return null;
        }
        const {count} = productUse;
        const conversionUnitURL = product.conversionUnit;
        const {conversionFactor} = product;
        let unitName;
        let unitSymbol;
        const usesConversion =
          !!(conversionUnitURL && conversionFactor) && !autoLinesToLines.has(index);
        if (usesConversion) {
          const conversionUnit = unitLookup(conversionUnitURL);
          unitName = conversionUnit?.name || "";
          unitSymbol = formatUnitString(conversionUnit);
        } else {
          const unit = product.relatedUnit ? unitLookup(product.relatedUnit) : undefined;
          unitName = unit?.name || "";
          unitSymbol = formatUnitString(unit);
        }
        const value =
          usesConversion && count
            ? _.round(
                count / (conversionFactor as number),
                this.props.customerSettings.materialDecimals,
              )
            : count;
        const {addedBy, notes, ours} = productUse;
        let baseValue;
        let baseUnit;
        if (outputToInputConversionMapping[unitName]) {
          if (this.props.baseUnit !== undefined && this.props.baseValue !== undefined) {
            ({baseUnit, baseValue} = this.props);
          } else {
            const taskPriceItemUseList = this.props.priceItemUseList || [];
            const {priceItemLookup} = this.props;
            const baseUnitArray = outputToInputConversionMapping[unitName];
            for (let i = 0; i < baseUnitArray.length; i += 1) {
              const baseUnitCandidate = baseUnitArray[i];
              // find entry with base unit
              const basePriceItemUse = taskPriceItemUseList.find((basePriceItemUseCandidate) => {
                const basePriceItemCandidateURL = basePriceItemUseCandidate.priceItem;
                const basePriceItemCandidate = priceItemLookup(basePriceItemCandidateURL);
                return (
                  basePriceItemCandidate &&
                  getUnitCode(basePriceItemCandidate, unitLookup) === baseUnitCandidate &&
                  priceItemIsVisible(
                    basePriceItemCandidate,
                    false,
                    taskPriceItemUseList,
                    unitLookup,
                    priceItemLookup,
                  )
                );
              });
              if (basePriceItemUse) {
                baseUnit = baseUnitCandidate;
                baseValue = basePriceItemUse.count || 0;
                break;
              }
            }
          }
          if (baseValue !== undefined && baseUnit) {
            showConversionColumns = true;
          }
        }
        return {
          addedBy,
          baseUnit,
          baseValue,
          index,
          notes,
          ours,
          product,
          unit: unitSymbol,
          usesConversion,
          value,
        };
      })
      .filter(notNull);
    const showIconColumn = !!(
      calculators && productList.some(({unit}) => !!calculators[unit.toLocaleLowerCase()])
    );
    const productRows = productList
      .map(
        ({
          addedBy,
          baseUnit,
          baseValue,
          index,
          notes,
          ours,
          product,
          unit,
          usesConversion,
          value,
        }) => {
          if (
            !isManager &&
            !this.props.customerSettings.showMaterialAutoLinesToEmployees &&
            autoLinesToLines.has(index)
          ) {
            return null;
          }
          const readonly = !!(
            this.props.readonly ||
            (readonlyUnits && readonlyUnits.has(unit.toLowerCase())) ||
            autoLinesToLines.has(index)
          );
          return (
            <ProductEntry
              key={index}
              addedBy={addedBy}
              baseUnit={baseUnit}
              baseValue={baseValue}
              calculator={calculators ? calculators[unit.toLocaleLowerCase()] : undefined}
              currentUserURL={this.props.currentUserURL}
              customerSettings={customerSettings}
              deleteDisabled={deleteDisabled || readonly}
              disabled={readonly}
              hasConversions={showConversionColumns}
              index={index}
              isAutoLine={autoLinesToLines.has(index)}
              isManager={!!isManager}
              notes={notes}
              ours={ours}
              product={product}
              showIconColumn={showIconColumn}
              showNotes={showNotes}
              unit={unit}
              usesConversion={usesConversion}
              value={value != null ? value : undefined}
              onChange={this.handleProductUseCountChange}
              onDeleteClick={this.handleDeleteClick}
              onDetailButtonClick={this.props.onDetailButtonClick}
              onNotesChange={this.handleProductNotesChange}
              onOursChange={this.handleOursChange}
            />
          );
        },
      )
      .filter(notNull);

    if (MOBILE) {
      return (
        <Grid>
          <Cell size="12/12">{productRows}</Cell>
        </Grid>
      );
    } else {
      let conversionColumns;
      if (showConversionColumns) {
        conversionColumns = [];
        conversionColumns.push(
          <TableCell key="conversion-count" style={{width: COUNT_COLUMN_WIDTH}}>
            Antal
          </TableCell>,
        );
        conversionColumns.push(
          <TableCell key="conversion-unit" style={{width: CONVERSION_UNIT_COLUMN_WIDTH}}>
            Enhed
          </TableCell>,
        );
      }
      let iconColumn;
      if (showIconColumn) {
        iconColumn = <TableCell style={BUTTON_COLUMN_STYLE} />;
      }

      let oursToggleColumn;
      if (this.props.customerSettings.showOursToggle) {
        oursToggleColumn = <TableCell style={{width: TOGGLE_COLUMN_WIDTH}} />;
      }
      return (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Produkt"
                  id="product-table.table-header.product"
                />
              </TableCell>
              <TableCell style={{width: COUNT_COLUMN_WIDTH}}>
                <FormattedMessage defaultMessage="Antal" id="product-table.table-header.quantity" />
              </TableCell>
              {iconColumn}
              <TableCell style={{width: UNIT_COLUMN_WIDTH}}>
                <FormattedMessage defaultMessage="Enhed" id="product-table.table-header.unit" />
              </TableCell>
              {conversionColumns}
              {oursToggleColumn}
              {showNotes ? (
                <TableCell>
                  <FormattedMessage defaultMessage="Noter" id="product-table.table-header.notes" />
                </TableCell>
              ) : null}
              <TableCell style={{width: DELETE_COLUMN_WIDTH}} />
            </TableRow>
          </TableHead>
          <TableBody>{productRows}</TableBody>
        </Table>
      );
    }
  }
}

const ConnectedLegacyProductTable: React.ComponentType<LegacyProductTableOwnProps> = connect<
  LegacyProductTableStateProps,
  object,
  LegacyProductTableOwnProps,
  AppState
>(
  createStructuredSelector<AppState, LegacyProductTableStateProps>({
    priceItemLookup: getPriceItemLookup,
    productGroupLookup: getProductGroupLookup,
    productLookup: getProductLookup,
    unitLookup: getUnitLookup,
  }),
  {},
)(LegacyProductTable);

export {ConnectedLegacyProductTable as LegacyProductTable};
