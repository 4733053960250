import {PathTemplate} from "@co-frontend-libs/redux";
import {ListItem, ListItemIcon, ListItemText, SvgIcon} from "@material-ui/core";
import ArrowBackIcon from "mdi-react/ArrowBackIcon";
import React, {useCallback} from "react";
import {useIntl} from "react-intl";
import {useAppBarHeight} from "../../use-appbar-height";

interface LeaveSettingsListItemProps {
  onRequestChange: (route: PathTemplate) => void;
}

export const LeaveSettingsListItem = React.memo(function LeaveSettingsListItem(
  props: LeaveSettingsListItemProps,
): JSX.Element {
  const {onRequestChange} = props;

  const intl = useIntl();

  const appBarHeight = useAppBarHeight();

  const handleClick = useCallback(
    (event: React.MouseEvent<unknown, MouseEvent>): void => {
      event.preventDefault();
      onRequestChange("/");
    },
    [onRequestChange],
  );

  return (
    <ListItem
      button
      style={{
        minHeight: appBarHeight,
      }}
      onClick={handleClick}
    >
      <ListItemIcon>
        <SvgIcon>
          <ArrowBackIcon />
        </SvgIcon>
      </ListItemIcon>
      <ListItemText primary={intl.formatMessage({defaultMessage: "Tilbage"})} />
    </ListItem>
  );
});
