import {
  PriceItem,
  PriceItemUrl,
  Product,
  ProductUrl,
  resourceNameFor,
} from "@co-common-libs/resources";
import {getPriceItemLookup, getProductLookup, getUnitLookup} from "@co-frontend-libs/redux";
import {
  Card,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {DisplaySharedTotalsRow} from "./display-shared-totals-row";

interface DisplaySharedTotalsTableProps {
  allDeliveryCounts: ReadonlyMap<PriceItemUrl | ProductUrl, number>;
  allLoadsPerProduct: ReadonlyMap<string, number> | undefined;
  allPickupCounts: ReadonlyMap<PriceItemUrl | ProductUrl, number>;
  deliveryCounts: ReadonlyMap<PriceItemUrl | ProductUrl, number> | undefined;
  loadsPerProduct: ReadonlyMap<string, number> | undefined;
  pickupCounts: ReadonlyMap<PriceItemUrl | ProductUrl, number> | undefined;
}

export function DisplaySharedTotalsTable({
  allDeliveryCounts,
  allLoadsPerProduct,
  allPickupCounts,
  deliveryCounts,
  loadsPerProduct,
  pickupCounts,
}: DisplaySharedTotalsTableProps): JSX.Element {
  const priceItemLookup = useSelector(getPriceItemLookup);
  const productLookup = useSelector(getProductLookup);
  const unitLookup = useSelector(getUnitLookup);
  const rows: JSX.Element[] = [];
  const sharedRows: JSX.Element[] = [];
  const showLoads = !!(loadsPerProduct || allLoadsPerProduct);
  if (pickupCounts !== undefined && deliveryCounts !== undefined) {
    pickupCounts.forEach((count, url) => {
      const isProduct = resourceNameFor(url) === "product";

      const instance: PriceItem | Product | undefined = isProduct
        ? productLookup(url as ProductUrl)
        : priceItemLookup(url as PriceItemUrl);
      if (!instance) {
        return;
      }

      rows.push(
        <DisplaySharedTotalsRow
          key={url}
          count={count}
          deliveryCount={deliveryCounts.get(url)}
          instance={instance}
          isProduct={isProduct}
          loads={loadsPerProduct?.get(url)}
          showLoads={showLoads}
          unitLookup={unitLookup}
        />,
      );
    });

    deliveryCounts.forEach((count, url) => {
      if (pickupCounts.has(url)) {
        return;
      }
      const isProduct = resourceNameFor(url) === "product";

      const instance: PriceItem | Product | undefined = isProduct
        ? productLookup(url as ProductUrl)
        : priceItemLookup(url as PriceItemUrl);
      if (!instance) {
        return;
      }
      rows.push(
        <DisplaySharedTotalsRow
          key={url}
          deliveryCount={count}
          instance={instance}
          isProduct={isProduct}
          loads={loadsPerProduct?.get(url)}
          showLoads={showLoads}
          unitLookup={unitLookup}
        />,
      );
    });
  }

  allPickupCounts.forEach((count, url) => {
    const isProduct = resourceNameFor(url) === "product";

    const instance: PriceItem | Product | undefined = isProduct
      ? productLookup(url as ProductUrl)
      : priceItemLookup(url as PriceItemUrl);
    if (!instance) {
      return;
    }

    sharedRows.push(
      <DisplaySharedTotalsRow
        key={url}
        count={count}
        deliveryCount={allDeliveryCounts.get(url)}
        instance={instance}
        isProduct={isProduct}
        loads={allLoadsPerProduct?.get(url)}
        showLoads={showLoads}
        unitLookup={unitLookup}
      />,
    );
  });

  allDeliveryCounts.forEach((count, url) => {
    if (allPickupCounts.has(url)) {
      return;
    }
    const isProduct = resourceNameFor(url) === "product";

    const instance: PriceItem | Product | undefined = isProduct
      ? productLookup(url as ProductUrl)
      : priceItemLookup(url as PriceItemUrl);
    if (!instance) {
      return;
    }

    sharedRows.push(
      <DisplaySharedTotalsRow
        key={url}
        deliveryCount={count}
        instance={instance}
        isProduct={isProduct}
        loads={allLoadsPerProduct?.get(url)}
        showLoads={showLoads}
        unitLookup={unitLookup}
      />,
    );
  });

  return (
    <Card style={{margin: "1em"}}>
      <CardHeader
        title={
          <FormattedMessage defaultMessage="Total" id="transport-totals-table.card-title.total" />
        }
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>
              <FormattedMessage
                defaultMessage="Afhentet"
                id="transport-totals-table.table-header.pickedup"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                defaultMessage="Leveret"
                id="transport-totals-table.table-header.delivered"
              />
            </TableCell>
            {showLoads ? (
              <TableCell>
                <FormattedMessage defaultMessage="Læs" />
              </TableCell>
            ) : null}
            <TableCell>
              <FormattedMessage
                defaultMessage="På vej"
                id="transport-totals-table.table-header.on-road"
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {deliveryCounts !== undefined && pickupCounts !== undefined ? (
            <TableRow>
              <TableCell>
                <strong>
                  <FormattedMessage defaultMessage="Min opgave" id="totals-table.my-task" />
                </strong>
              </TableCell>
              <TableCell />
              <TableCell />
              {showLoads ? <TableCell /> : null}
              <TableCell />
            </TableRow>
          ) : null}
          {rows}
          <TableRow>
            <TableCell>
              <strong>
                <FormattedMessage defaultMessage="Samlet ordre" id="totals-table.total-order" />
              </strong>
            </TableCell>
            <TableCell />
            <TableCell />
            {showLoads ? <TableCell /> : null}
            <TableCell />
          </TableRow>
          {sharedRows}
        </TableBody>
      </Table>
    </Card>
  );
}
