import {
  Machine,
  MachineUrl,
  PriceGroup,
  PriceGroupUrl,
  ReportingSpecification,
  ReportingSpecificationUrl,
  Task,
  WorkType,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import _ from "lodash";
import {getExpectedLogSpecificationWithCriteria} from "./get-expected-log-specification-with-criteria";

export function getExpectedLogSpecification(
  task: Pick<Task, "machineuseSet" | "priceGroup" | "workType">,
  data: {
    machineLookup: (url: MachineUrl) => Machine | undefined;
    priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
    reportingSpecificationLookup: (
      url: ReportingSpecificationUrl,
    ) => ReportingSpecification | undefined;
    workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
  },
): ReportingSpecification | null {
  const {machineLookup, priceGroupLookup, reportingSpecificationLookup, workTypeLookup} = data;
  return (
    _.first(
      getExpectedLogSpecificationWithCriteria(
        task,
        priceGroupLookup,
        workTypeLookup,
        machineLookup,
        reportingSpecificationLookup,
      ),
    )?.reportingSpecification ?? null
  );
}
