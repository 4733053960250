import {ReadonlyConfig} from "@co-common-libs/config";
import {Role} from "@co-common-libs/resources";
import {roleIsManager} from "@co-common-libs/utils";
import {
  ActiveEconomySystemIntegrationKind,
  EconomySystemIntegrationKindPart,
  getEconomySystemIntegrationKind,
} from "../get-economy-system-integration-kind";

export type ExtendedCustomerSettingsPart = Pick<ReadonlyConfig, "createCustomer"> &
  EconomySystemIntegrationKindPart;

export interface ExtendedCustomerSettings {
  canManage: boolean;
}

export function extendedCustomerSettings(
  config: ExtendedCustomerSettingsPart,
  role: Pick<Role, "manager" | "projectManager"> | null,
): ExtendedCustomerSettings {
  const {createCustomer} = config;
  const economySystemIntegrationKind = getEconomySystemIntegrationKind(config);

  const roleCanManage =
    createCustomer === "all" || (createCustomer === "manager" && roleIsManager(role));
  const integrationAllowsManage =
    economySystemIntegrationKind !== ActiveEconomySystemIntegrationKind.IMPORT_ONLY;

  return {
    canManage: integrationAllowsManage && roleCanManage,
  };
}
