import {getCurrentlyFetching} from "@co-frontend-libs/redux";
import {LinearProgress} from "@material-ui/core";
import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";

const PROGRESS_MINIMUM_VISIBLE_MILLISECONDS = 1000;

export function FetchingProgress(): JSX.Element | null {
  const currentlyFetching = useSelector(getCurrentlyFetching);
  const [visible, setVisible] = useState(currentlyFetching);
  const timerRef = useRef<number>();

  useEffect(() => {
    if (currentlyFetching) {
      setVisible(true);
    } else {
      timerRef.current = window.setTimeout(() => {
        setVisible(false);
      }, PROGRESS_MINIMUM_VISIBLE_MILLISECONDS);
    }
    return () => {
      if (timerRef.current) {
        window.clearTimeout(timerRef.current);
        timerRef.current = undefined;
      }
    };
  }, [currentlyFetching]);

  if (!visible) {
    return null;
  }

  return (
    <LinearProgress
      style={{
        left: 0,
        position: "absolute",
        top: 0,
        width: "100%",
        zIndex: 9999,
      }}
    />
  );
}
