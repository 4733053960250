import {ResourceInstance, ResourceName} from "@co-common-libs/resources";
import {Check} from "@co-frontend-libs/db-resources";
import {Draft} from "@reduxjs/toolkit";
import {
  GenericInstanceRecord,
  ResourceInstanceRecords,
  ResourceURLArrays,
  ResourcesState,
} from "../../types";
import {buildCheckFunction, partialEntriesForEach} from "../../utils";

function updateResourceInstancesFromDeletedOnServer(
  state: Draft<ResourcesState>,
  resourceName: ResourceName,
  deletedURLs: readonly string[],
): void {
  const persistedForResource = state.persistedData[resourceName];
  const temporaryForResource = state.temporaryData[resourceName];
  deletedURLs.forEach((url): void => {
    delete persistedForResource[url];
    delete temporaryForResource[url];
  });
}

export function updateInstancesFromDeletedOnServer(
  state: Draft<ResourcesState>,
  deletedOnServer: Partial<ResourceURLArrays>,
): void {
  // Anything known to be deleted on the server should be removed from the
  // persisted and temporary data sets, wherever it is present.
  partialEntriesForEach(deletedOnServer, (resourceName, deletedURLs) => {
    updateResourceInstancesFromDeletedOnServer(state, resourceName, deletedURLs);
  });
}

function updateResourceInstancesFromUpdatedOnServer(
  state: Draft<ResourcesState>,
  currentState: ResourcesState,
  resourceName: ResourceName,
  updatedInstanceRecords: GenericInstanceRecord,
  potentialData: ResourceInstanceRecords,
  persistedResourceCheckMapping: {readonly [N in ResourceName]: Check},
  temporaryResourceCheckMapping: {readonly [N in ResourceName]: Check},
): void {
  const persistedForResourceDraft = state.persistedData[resourceName];
  const temporaryForResourceDraft = state.temporaryData[resourceName];

  const resourcePersistedCheck = persistedResourceCheckMapping[resourceName];
  const resourceTemporaryCheck = temporaryResourceCheckMapping[resourceName];

  if (resourcePersistedCheck.type === "alwaysOk") {
    // optimise for the "everything synced and persisted" case;
    // which we use for several resources
    partialEntriesForEach(
      updatedInstanceRecords as GenericInstanceRecord,
      (url, instance): void => {
        persistedForResourceDraft[url] = instance as ResourceInstance as Draft<any>;
      },
    );
  } else if (resourceTemporaryCheck.type === "neverOk") {
    // optimise for the "no relevant temp-query/archive-lookup active" case;
    // which will apply for most other data...
    const persistedCheckFunction = buildCheckFunction(resourcePersistedCheck, potentialData);
    const persistedForResource: GenericInstanceRecord = currentState.persistedData[resourceName];
    partialEntriesForEach(
      updatedInstanceRecords as GenericInstanceRecord,
      (url, updatedInstance): void => {
        const currentInstance = persistedForResourceDraft[url];
        if (
          !currentInstance ||
          !currentInstance.lastChanged ||
          !updatedInstance.lastChanged ||
          currentInstance.lastChanged < updatedInstance.lastChanged
        ) {
          // only use "updated" data if actually newer than current
          if (persistedCheckFunction(updatedInstance)) {
            persistedForResourceDraft[url] = updatedInstance as ResourceInstance as Draft<any>;
          } else {
            if (persistedForResource[url]) {
              delete persistedForResourceDraft[url];
            }
          }
        }
      },
    );
  } else {
    // the general case; there might be both temporary and persisted queries
    // (or, in theory, only temporary...)
    const persistedCheckFunction = buildCheckFunction(resourcePersistedCheck, potentialData);
    const temporaryCheckFunction = buildCheckFunction(resourceTemporaryCheck, potentialData);
    const persistedForResource: GenericInstanceRecord = currentState.persistedData[resourceName];
    const temporaryForResource: GenericInstanceRecord = currentState.temporaryData[resourceName];
    partialEntriesForEach(
      updatedInstanceRecords as GenericInstanceRecord,
      (url, updatedInstance): void => {
        const currentInstance = persistedForResourceDraft[url] || temporaryForResourceDraft[url];
        if (
          !currentInstance ||
          !currentInstance.lastChanged ||
          !updatedInstance.lastChanged ||
          currentInstance.lastChanged < updatedInstance.lastChanged
        ) {
          // only use "updated" data if actually newer than current
          if (persistedCheckFunction(updatedInstance)) {
            persistedForResourceDraft[url] = updatedInstance as ResourceInstance as Draft<any>;
            if (temporaryForResource[url]) {
              delete temporaryForResourceDraft[url];
            }
          } else if (temporaryCheckFunction(updatedInstance)) {
            temporaryForResourceDraft[url] = updatedInstance as ResourceInstance as Draft<any>;
            if (persistedForResource[url]) {
              delete persistedForResourceDraft[url];
            }
          } else {
            if (persistedForResource[url]) {
              delete persistedForResourceDraft[url];
            }
            if (temporaryForResource[url]) {
              delete temporaryForResourceDraft[url];
            }
          }
        }
      },
    );
  }
}

export function updateInstancesFromUpdatedOnServer(
  state: Draft<ResourcesState>,
  updatedOnServer: Partial<ResourceInstanceRecords>,
  currentState: ResourcesState,
  potentialData: ResourceInstanceRecords,
  persistedResourceCheckMapping: {readonly [N in ResourceName]: Check},
  temporaryResourceCheckMapping: {readonly [N in ResourceName]: Check},
): void {
  // Updated data may update either persisted or temporary sets.
  // ... it may also move data from the temporary set to the persisted set...
  partialEntriesForEach(updatedOnServer, (resourceName, updatedInstanceRecords) => {
    updateResourceInstancesFromUpdatedOnServer(
      state,
      currentState,
      resourceName,
      updatedInstanceRecords,
      potentialData,
      persistedResourceCheckMapping,
      temporaryResourceCheckMapping,
    );
  });
}
