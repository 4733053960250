import {ResourceName} from "@co-common-libs/resources";

export type Check =
  | {
      readonly check: Check;
      readonly fromResource: ResourceName;
      readonly memberName: string;
      readonly type: "targetOfForeignKey";
    }
  | {
      readonly check: Check;
      readonly memberName: string;
      readonly targetType: ResourceName;
      readonly type: "hasForeignKey";
    }
  | {
      readonly memberName: string;
      readonly type: "memberEq";
      readonly value: boolean | number | string | null;
    }
  | {
      readonly memberName: string;
      readonly type: "memberGt";
      readonly value: number | string;
    }
  | {
      readonly memberName: string;
      readonly type: "memberGte";
      readonly value: number | string;
    }
  | {
      readonly memberName: string;
      readonly type: "memberIn";
      readonly values: readonly (number | string | null)[];
    }
  | {
      readonly memberName: string;
      readonly type: "memberLt";
      readonly value: number | string;
    }
  | {
      readonly memberName: string;
      readonly type: "memberLte";
      readonly value: number | string;
    }
  | {readonly checks: readonly Check[]; readonly type: "and"}
  | {readonly checks: readonly Check[]; readonly type: "or"}
  | {readonly memberName: string; readonly type: "memberFalsy"}
  | {readonly memberName: string; readonly type: "memberTruthy"}
  | {readonly type: "alwaysOk"}
  | {readonly type: "neverOk"};

// Typescript typecheck validates that all paths return...
// eslint-disable-next-line consistent-return
function stringifyCheck(check: Check): string {
  switch (check.type) {
    case "alwaysOk":
      return "1";
    case "neverOk":
      return "0";
    case "memberTruthy":
      return `${check.memberName}~=1`;
    case "memberFalsy":
      return `${check.memberName}~=0`;
    case "memberEq":
      return `${check.memberName}=${JSON.stringify(check.value)}`;
    case "memberLt":
      return `${check.memberName}<${JSON.stringify(check.value)}`;
    case "memberLte":
      return `${check.memberName}<=${JSON.stringify(check.value)}`;
    case "memberGt":
      return `${check.memberName}>${JSON.stringify(check.value)}`;
    case "memberGte":
      return `${check.memberName}>=${JSON.stringify(check.value)}`;
    case "memberIn":
      return `${check.memberName} in ${JSON.stringify(check.values)}`;
    case "or":
      return check.checks.map(stringifyCheck).join("||");
    case "and":
      return check.checks.map(stringifyCheck).join("&&");
    case "hasForeignKey":
      return `${check.memberName}->${check.targetType}(${stringifyCheck(check.check)})`;
    case "targetOfForeignKey":
      return `${check.fromResource}.${check.memberName}(${stringifyCheck(check.check)})`;
  }
}

export interface QueryParams {
  readonly check: Check;
  readonly filter?: {readonly [field: string]: string};
  readonly independentFetch: boolean;
  readonly instance?: string | null;
  readonly limit?: number;
  readonly offset?: number;
  readonly resourceName: ResourceName;
  readonly sortBy?: readonly string[];
  readonly timeout?: number | null;
}

interface QueryPart {
  readonly filter: {readonly [field: string]: string};
  readonly instance: string | null;
  readonly limit: number;
  readonly offset: number;
  readonly resourceName: ResourceName;
  readonly sortBy: readonly string[];
  readonly timeout: number | null;
}
/**
 * A `Query` instance describes data to be fetched from the backend for a
 * single resource type. `filter`, `offset` and others specify other parameters
 * for the server to be used in the query string.
 *
 * `checks` specify parameters for client-side filtering -- used to determine
 * whether data can be "safely" discarded from RAM or local DB.
 */
export interface Query extends QueryPart {
  readonly check: Check;
  readonly independentFetch: boolean;
  readonly keyString: string;
  readonly queryString: string;
}

function encodeQueryString(queryData: QueryPart): string {
  const params: string[] = [];
  Object.entries(queryData.filter).forEach(([key, rawValue]) => {
    if (rawValue) {
      const encodedValue = encodeURIComponent(rawValue);
      params.push(`${key}=${encodedValue}`);
    } else {
      params.push(`${key}`);
    }
  });
  if (queryData.limit || queryData.offset) {
    params.push(`limit=${queryData.limit}`);
    params.push(`offset=${queryData.offset}`);
    queryData.sortBy.forEach((value) => {
      params.push(`sortBy[]=${value}`);
    });
  }
  if (queryData.instance) {
    params.push(`instance=${queryData.instance}`);
  }
  return params.join("&");
}

export function makeQuery(params: QueryParams): Query {
  const {check, filter, independentFetch, instance, limit, offset, resourceName, sortBy, timeout} =
    params;
  const resultPart = {
    filter: filter ? {...filter} : {},
    instance: instance || null,
    limit: limit || 0,
    offset: offset || 0,
    resourceName,
    sortBy: sortBy ? sortBy.slice() : [],
    timeout: timeout != null ? timeout : null,
  };
  if (process.env.NODE_ENV !== "production") {
    console.assert(resultPart.resourceName);
    if (resultPart.filter.size) {
      Object.entries(resultPart.filter).forEach(([k, v]) => {
        console.assert(typeof v === "string", `${k}: ${v}`);
        console.assert(typeof k === "string", k);
        console.assert(k !== "limit", k);
        console.assert(k !== "offset", k);
        console.assert(k !== "sortBy", k);
        console.assert(k !== "instance", k);
        console.assert(k === encodeURIComponent(k));
      });
    }
    console.assert(resultPart.limit >= 0);
    console.assert(resultPart.offset >= 0);
    if (resultPart.sortBy.length) {
      resultPart.sortBy.forEach((v) => console.assert(v === encodeURIComponent(v)));
    }
    if (resultPart.limit !== 0 || resultPart.offset !== 0) {
      console.assert(resultPart.limit);
      console.assert(resultPart.sortBy.length);
    }
  }
  const queryString = encodeQueryString(resultPart);
  const checkString = stringifyCheck(check);
  const keyString = `${resourceName}/${
    timeout != null ? timeout : ""
  }/${queryString}/${checkString}`;

  return {
    ...resultPart,
    check,
    independentFetch,
    keyString,
    queryString,
  };
}

// abstraction for common interface for IndexedDB/SQLite implementations...
export class SQLError extends Error {
  override cause: Error;
  constructor(cause: Error, message: string) {
    super(message);
    this.cause = cause;
  }
}

export const wrapSQLError =
  (fnname: string): ((e: Error) => never) =>
  (e) => {
    if (e && e.message) {
      throw new SQLError(e, `${fnname}: ${e.message}`);
    } else {
      throw e;
    }
  };

export type QueryIDStruct = {readonly id: number; readonly query: Query};

export type QueryTimestampsStruct = {
  readonly fullTimestamp: string | null;
  readonly query: Query;
  readonly updateTimestamp: string | null;
};
