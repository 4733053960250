import {
  PricePercentFuelSurchargeBasis,
  PricePercentFuelSurchargeSpecification,
  PricePercentFuelSurchargeSpecificationUrl,
} from "@co-common-libs/resources";
import {findFuelSurchargeBasis} from "@co-common-libs/resources-utils";
import {dateToString} from "@co-common-libs/utils";
import {ColumnSpecifications, GenericTable, RowData} from "@co-frontend-libs/components";
import {
  actions,
  getPricePercentFuelSurchargeBasisArray,
  getPricePercentFuelSurchargeSpecificationArray,
  getTableSortingState,
} from "@co-frontend-libs/redux";
import _ from "lodash";
import CheckIcon from "mdi-react/CheckIcon";
import React, {useCallback, useMemo} from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

const TABLE_SORTING_IDENTIFIER = "PricePercentFuelSurchargeSpecificationTable";

type PricePercentFuelSurchargeSpecificationTableColumnId =
  | "active"
  | "description"
  | "fuelCostSharePercent"
  | "name";

type PricePercentFuelSurchargeSpecificationTableFieldId =
  | "active"
  | "description"
  | "fuelCostSharePercent"
  | "name";

interface PricePercentFuelSurchargeSpecificationTableDataType
  extends RowData<
    PricePercentFuelSurchargeSpecificationTableFieldId,
    PricePercentFuelSurchargeSpecificationUrl
  > {
  active: boolean;
  fuelCostSharePercent: number;
  name: string;
}

function renderActive(data: PricePercentFuelSurchargeSpecificationTableDataType): JSX.Element {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return data.active ? <CheckIcon /> : <></>;
}

function buildColumnSpecifications(
  onClick: ((fuelSurchargeSpecification: string) => void) | undefined,
): ColumnSpecifications<
  PricePercentFuelSurchargeSpecificationTableFieldId,
  PricePercentFuelSurchargeSpecificationTableColumnId,
  PricePercentFuelSurchargeSpecificationUrl,
  PricePercentFuelSurchargeSpecificationTableDataType
> {
  return {
    active: {
      field: "active",
      label: <FormattedMessage defaultMessage="Aktiv" />,
      onClick,
      render: renderActive,
    },
    description: {
      field: "description",
      label: <FormattedMessage defaultMessage="Beskrivelse" />,
      onClick,
    },
    fuelCostSharePercent: {
      field: "fuelCostSharePercent",
      label: <FormattedMessage defaultMessage="Omkostning %" />,
      onClick,
    },
    name: {
      field: "name",
      label: <FormattedMessage defaultMessage="Navn" />,
      onClick,
    },
  };
}

function buildRowData(
  fuelSurchargSpecificationArray: readonly PricePercentFuelSurchargeSpecification[],
  pricePercentFuelSurchargeBasisArray: readonly PricePercentFuelSurchargeBasis[],
  today: string,
): PricePercentFuelSurchargeSpecificationTableDataType[] {
  return fuelSurchargSpecificationArray.map((specification) => {
    const basis = findFuelSurchargeBasis(
      pricePercentFuelSurchargeBasisArray,
      specification.url,
      today,
    );
    const fuelCostSharePercent = basis?.fuelCostSharePercent ?? 0;
    const {active, description, name, url} = specification;
    return {active, description, fuelCostSharePercent, key: url, name};
  });
}

interface PricePercentFuelSurchargeSpecificationTableProps {
  filterString: string;
  onClick: (specificationUrl: string) => void;
  showInactive: boolean;
}

export function PricePercentFuelSurchargeSpecificationTable(
  props: PricePercentFuelSurchargeSpecificationTableProps,
): JSX.Element {
  const {filterString, onClick, showInactive} = props;

  const pricePercentFuelSurchargeSpecificationArray = useSelector(
    getPricePercentFuelSurchargeSpecificationArray,
  );

  const pricePercentFuelSurchargeBasisArray = useSelector(getPricePercentFuelSurchargeBasisArray);

  const dispatch = useDispatch();

  const sortedFilteredSpecifications = useMemo(
    () =>
      _.sortBy(
        showInactive
          ? pricePercentFuelSurchargeSpecificationArray
          : pricePercentFuelSurchargeSpecificationArray.filter(({active}) => active),
        [({name}) => name, ({url}) => url],
      ),
    [pricePercentFuelSurchargeSpecificationArray, showInactive],
  );

  const columnSpecifications = useMemo(() => buildColumnSpecifications(onClick), [onClick]);

  const today = dateToString(new Date());

  const rowData = useMemo(
    () => buildRowData(sortedFilteredSpecifications, pricePercentFuelSurchargeBasisArray, today),
    [pricePercentFuelSurchargeBasisArray, sortedFilteredSpecifications, today],
  );

  const visibleColumns =
    useMemo((): readonly PricePercentFuelSurchargeSpecificationTableColumnId[] => {
      if (showInactive) {
        return ["name", "description", "fuelCostSharePercent", "active"];
      } else {
        return ["name", "description", "fuelCostSharePercent"];
      }
    }, [showInactive]);

  const sortingStateSelector = useMemo(
    () => getTableSortingState(TABLE_SORTING_IDENTIFIER, "c5_machine", "ASC"),
    [],
  );
  const {sortDirection, sortKey} = useSelector(sortingStateSelector);

  const handleHeaderClick = useCallback(
    (key: PricePercentFuelSurchargeSpecificationTableColumnId): void => {
      let direction: "ASC" | "DESC" = "ASC";
      if (sortKey === key && sortDirection === "ASC") {
        direction = "DESC";
      }
      const action = actions.putTableSortingState(TABLE_SORTING_IDENTIFIER, key, direction);
      dispatch(action);
    },
    [dispatch, sortKey, sortDirection],
  );

  return (
    <GenericTable
      columns={columnSpecifications}
      entries={rowData}
      filterString={filterString}
      sortBy={sortKey as any}
      sortDirection={sortDirection}
      visibleColumns={visibleColumns}
      onHeaderClick={handleHeaderClick}
    />
  );
}
