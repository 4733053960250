import {CsvExportSpecification} from "@co-common-libs/csv-export-specifications";
import {Card, CardContent} from "@material-ui/core";
import React from "react";
import {ExportSelector} from "./export-selector";

export function ExportSelectorCard(props: {
  availableExportSpecifications: readonly CsvExportSpecification[];
  disabled: boolean;
  handleExportSelectorChange: (newSelectedExport: string) => void;
  selectedExportSpecification: CsvExportSpecification | undefined;
}): JSX.Element {
  const {
    availableExportSpecifications,
    disabled,
    handleExportSelectorChange,
    selectedExportSpecification,
  } = props;

  return (
    <Card style={{height: "100%"}}>
      <CardContent>
        <ExportSelector
          availableExportSpecifications={availableExportSpecifications}
          disabled={disabled}
          selected={selectedExportSpecification?.name}
          onSelectorChange={handleExportSelectorChange}
        />
      </CardContent>
    </Card>
  );
}
