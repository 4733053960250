import {formatDateTime} from "@co-common-libs/utils";
import React from "react";
import {FormattedMessage} from "react-intl";
import {EconomicDownloadButton} from "./economic-download-button";
import {EconomicDownloadErrorBlock} from "./economic-download-error-block";

export const EconomicDownloadTab = ({
  downloadDoneTimestamp,
  downloadError,
  downloading,
  downloadStartTimestamp,
  onDownloadFromEconomic,
}: {
  downloadDoneTimestamp: string | null;
  downloadError: Error | null;
  downloading: boolean;
  downloadStartTimestamp: string | null;
  onDownloadFromEconomic: () => void;
}): JSX.Element => {
  return (
    <div style={{textAlign: "center"}}>
      <EconomicDownloadButton
        downloading={downloading}
        style={{minWidth: 260, width: 260}}
        onClick={onDownloadFromEconomic}
      >
        <FormattedMessage defaultMessage="Hent data fra e-conomic" />
      </EconomicDownloadButton>
      {downloadStartTimestamp && downloadDoneTimestamp ? (
        <FormattedMessage
          defaultMessage="Sidste download startet: {start}, fuldført: {done}"
          tagName="div"
          values={{
            done: formatDateTime(downloadDoneTimestamp),
            start: formatDateTime(downloadStartTimestamp),
          }}
        />
      ) : null}
      {downloadStartTimestamp && !downloadDoneTimestamp ? (
        <FormattedMessage
          defaultMessage="Sidste download startet: {start}"
          tagName="div"
          values={{start: formatDateTime(downloadStartTimestamp)}}
        />
      ) : null}
      {downloadError ? <EconomicDownloadErrorBlock downloadError={downloadError} /> : null}
    </div>
  );
};
