import {danishHolidaysForYear} from "./danish-holidays";

const holidayCalendars = {
  "Helligdage i Danmark": danishHolidaysForYear,
} as const;

export type HolidayCalendarLabel = keyof typeof holidayCalendars;

export function holidaysForYear(
  selectedCalendars: readonly HolidayCalendarLabel[],
  year: number,
): Map<string, string[]> {
  const resultsPerCalendar = selectedCalendars.map((calendarLabel) =>
    holidayCalendars[calendarLabel](year),
  );

  const dates = new Set(
    resultsPerCalendar.flatMap((calendarResult) => Array.from(calendarResult.keys())),
  );

  const result = new Map<string, string[]>();

  for (const date of dates) {
    const dateValues: string[] = [];
    for (const calendarResult of resultsPerCalendar) {
      const value = calendarResult.get(date);
      if (value !== undefined) {
        dateValues.push(value);
      }
    }
    result.set(date, dateValues);
  }

  return result;
}
