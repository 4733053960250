import {parse} from "date-fns";
import {z} from "zod";

export function transformDate(arg: string, ctx: z.RefinementCtx): Date {
  try {
    const localDate = parse(arg, "dd.MM.yyyy", new Date());
    const utcDate = new Date(0);
    utcDate.setUTCFullYear(localDate.getFullYear(), localDate.getMonth(), localDate.getDate());
    return utcDate;
  } catch {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "invalid date",
    });
    return z.NEVER;
  }
}
