import _ from "lodash";
import React, {useCallback} from "react";
import {SettingValueDisplayProps} from "../types";

export function DisplayLink(props: SettingValueDisplayProps): JSX.Element {
  const data: string = props.settingEntry?.data || "";
  const handleClick = useCallback((event: React.MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation();
  }, []);
  return (
    <div>
      <a href={data} rel="noreferrer" target="_blank" onClick={handleClick}>
        {data}
      </a>
    </div>
  );
}
