import {MINUTE_MILLISECONDS, dateToString} from "@co-common-libs/utils";
import {Rate, TaskIntervalWithRate, TaskIntervalWithUnregisteredAndPaid, WorkPeriod} from "./types";

/** Split sequences of intervals such that periods of "inactivity",
 * either absent from the input sequence or breaks, for longer than
 * `splitThresholdMinutes` starts new sequences in output.
 * @param intervals Input interval sequence.
 * @param splitThresholdMinutes Threshold for considering something separate
 *   sequences.
 * @param breakTimerURL URL of the "break" timer; to identify breaks as
 *   "inactivity".
 *  */
export function groupWorkPeriods(
  intervals: readonly TaskIntervalWithUnregisteredAndPaid[],
  splitThresholdMinutes: number,
  usingPunchedInOut: boolean,
): TaskIntervalWithUnregisteredAndPaid[][] {
  const result: TaskIntervalWithUnregisteredAndPaid[][] = [];
  if (!intervals.length) {
    return result;
  }
  const splitThresholdMilliseconds = splitThresholdMinutes * MINUTE_MILLISECONDS;
  console.assert(intervals.length >= 1);

  // HACK: when using punch in/out, consider !isPaid, i.e. "punched out",
  // to be "unpaid breaks"...
  const isUnpaidBreak: (interval: TaskIntervalWithUnregisteredAndPaid) => boolean =
    usingPunchedInOut
      ? (interval) => !interval.isPaid
      : (interval) => interval.isBreak && !interval.isPaid;
  const isNotUnpaidBreak: (interval: TaskIntervalWithUnregisteredAndPaid) => boolean = (interval) =>
    !isUnpaidBreak(interval);

  let i = 0;
  result[i] = [intervals[0]];
  let previousTo = new Date(intervals[0].toTimestamp);
  // intentionally skipping the first in iteration; just handled
  for (let j = 1; j < intervals.length; j += 1) {
    const interval = intervals[j];
    const intervalFrom = new Date(interval.fromTimestamp);
    const intervalTo = new Date(interval.toTimestamp);
    const difference = intervalFrom.valueOf() - previousTo.valueOf();
    if (difference < splitThresholdMilliseconds) {
      result[i].push(interval);
      // unpaid breaks should not increase "range" of current period
      if (isNotUnpaidBreak(interval) && intervalTo > previousTo) {
        previousTo = intervalTo;
      }
    } else {
      i += 1;
      result[i] = [interval];
      // no special case for unpaid breaks necessary...
      previousTo = intervalTo;
    }
  }
  return result;
}

function workBreaksIntervals(intervals: readonly TaskIntervalWithUnregisteredAndPaid[]): {
  breaks: TaskIntervalWithRate[];
  work: TaskIntervalWithRate[];
} {
  const work: TaskIntervalWithRate[] = [];
  const breaks: TaskIntervalWithRate[] = [];
  for (let i = 0; i < intervals.length; i += 1) {
    const interval = intervals[i];
    if (interval.isPaid) {
      work.push(Object.assign({rate: Rate.NORMAL}, interval));
    } else {
      breaks.push(Object.assign({rate: Rate.UNPAID}, interval));
    }
  }
  return {breaks, work};
}

/** Translate already group intervals into `WorkPeriod` instances.
 * @param workPeriodIntervals Array of arrays intervals, one array of
 *   intervals per `WorkPeriod`.
 * @param breakTimerURL URL of the "break" timer; used to separate intervals
 *   into `work` and `breaks` per resulting `WorkPeriod`.
 */
export function toWorkPeriods(
  workPeriodIntervals: readonly (readonly TaskIntervalWithUnregisteredAndPaid[])[],
): WorkPeriod[] {
  return workPeriodIntervals.map((intervals) => {
    const {breaks, work} = workBreaksIntervals(intervals);
    const firstFromTimestamp = intervals[0].fromTimestamp;
    const lastToTimestamp = intervals[intervals.length - 1].toTimestamp;
    const dateString = dateToString(new Date(firstFromTimestamp));
    return {
      breaks,
      date: dateString,
      firstFromTimestamp,
      lastToTimestamp,
      work,
    };
  });
}
