import {PunchWorkPeriod} from "@co-frontend-libs/redux";

export function findOverlappingPunchInOutPeriodsPart(
  employeePunchInOutPeriods: readonly PunchWorkPeriod[],
  fromTimestampString: string,
  toTimestampString: string,
): readonly PunchWorkPeriod[] {
  if (!employeePunchInOutPeriods || employeePunchInOutPeriods.length === 0) {
    return [];
  }
  let overlapStartIndex;
  for (let i = 0; i < employeePunchInOutPeriods.length; i += 1) {
    const entry = employeePunchInOutPeriods[i];
    if (!entry.punchOut || entry.punchOut > fromTimestampString) {
      if (entry.punchIn < toTimestampString) {
        overlapStartIndex = i;
      }
      break;
    }
  }
  if (overlapStartIndex == null) {
    return [];
  }
  let overlapEndIndex;
  for (let i = overlapStartIndex; i < employeePunchInOutPeriods.length; i += 1) {
    const entry = employeePunchInOutPeriods[i];
    if (entry.punchIn >= toTimestampString) {
      overlapEndIndex = i;
      break;
    }
  }
  if (overlapEndIndex != null) {
    return employeePunchInOutPeriods.slice(overlapStartIndex, overlapEndIndex);
  } else {
    return employeePunchInOutPeriods.slice(overlapStartIndex);
  }
}
