import {getTimerArray} from "@co-frontend-libs/redux";
import {Menu, MenuProps} from "@material-ui/core";
import {sortBy} from "lodash";
import React from "react";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {Alert} from "./alert";
import {AlertMenuItem} from "./alert-menu-item";

interface AlertMenuProps extends Pick<MenuProps, "anchorEl" | "onClose" | "open"> {
  alerts: readonly Alert[];
  onAlertClick(alert: Alert): void;
}

export function AlertMenu(props: AlertMenuProps): JSX.Element {
  const {alerts, anchorEl, onAlertClick, onClose, open} = props;

  const timerArray = useSelector(getTimerArray);
  const notifyUsersOnStartTimerLabels = sortBy(
    timerArray
      .filter(({active, notifyUsersOnStart}) => active && notifyUsersOnStart)
      .map(({label}) => label),
  );
  const notifyUsersOnStartTimerLabelsString = notifyUsersOnStartTimerLabels.join(", ");
  return (
    <Menu anchorEl={anchorEl} open={open} onClose={onClose}>
      {alerts.length > 0 ? (
        alerts.map((alert, index) => (
          <AlertMenuItem key={index} alert={alert} onClick={onAlertClick} />
        ))
      ) : (
        <div style={{padding: 6}}>
          <FormattedMessage
            defaultMessage={"Ingen aktive tidsregisteringer på: {labels}"}
            values={{labels: notifyUsersOnStartTimerLabelsString}}
          />
        </div>
      )}
    </Menu>
  );
}
