import {
  PriceGroup,
  Product,
  ProductUrl,
  ProductUseWithOrder,
  WorkType,
} from "@co-common-libs/resources";
import {ErrorColorButton, ResponsiveDialog} from "@co-frontend-libs/components";
import {ConnectedSingleProductDialog} from "@co-frontend-libs/connected-components";
import {
  getCurrentRole,
  getCurrentUserURL,
  getCustomerSettings,
  getProductLookup,
} from "@co-frontend-libs/redux";
import {
  Button,
  DialogContent,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import {useEventTargetValueCallback} from "app-utils";
import React, {useCallback, useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useSelector} from "react-redux";

interface ProductUseDetailsDialogProps {
  autoProduct: boolean;
  editable: boolean;
  notesEditable: boolean;
  onCancel: () => void;
  onDeleteClick: (() => void) | null;
  onOk: (changed: {notes?: string; ours?: boolean}) => void;
  open: boolean;
  product?: Product | undefined;
  productDialogPreferredProductURLs: readonly ProductUrl[] | undefined;
  productDialogPriceGroups: readonly PriceGroup[] | undefined;
  productDialogWorkType: WorkType | undefined;
  productUse?: ProductUseWithOrder | undefined;
  removable: boolean;
  showNotes: boolean;
  switchable: boolean;
  withSwitchProduct: boolean;
}

export const ProductUseDetailsDialog = React.memo(function ProductUseDetailsDialog(
  props: ProductUseDetailsDialogProps,
): JSX.Element | null {
  const {
    autoProduct,
    editable,
    notesEditable,
    onCancel,
    onDeleteClick,
    onOk,
    open,
    productDialogPreferredProductURLs,
    productDialogPriceGroups,
    productDialogWorkType,
    productUse,
    removable,
    showNotes,
    switchable,
    withSwitchProduct,
  } = props;
  const customerSettings = useSelector(getCustomerSettings);
  const productLookup = useSelector(getProductLookup);

  const [ours, setOurs] = useState(productUse?.ours !== false);
  const [notes, setNotes] = useState(productUse?.notes || "");
  const [productUrl, setProductUrl] = useState(productUse?.product || null);

  const product = productUrl ? productLookup(productUrl) : undefined;

  useEffect(() => {
    if (open) {
      setOurs(productUse?.ours !== false);
    }
  }, [open, productUse?.ours]);

  useEffect(() => {
    if (open) {
      setNotes(productUse?.notes || "");
    }
  }, [open, productUse?.notes]);

  useEffect(() => {
    if (open) {
      setProductUrl(productUse?.product || null);
    }
  }, [open, productUse?.product]);

  const handleOursChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setOurs(event.target.value === "true");
    },
    [setOurs],
  );
  const handleNotesChange = useEventTargetValueCallback(setNotes, [setNotes]);

  const handleOk = useCallback(() => {
    const changed: {notes?: string; ours?: boolean; product?: ProductUrl} = {};
    if (notes !== productUse?.notes || "") {
      changed.notes = notes;
    }
    if ((ours !== productUse?.ours) !== false) {
      changed.ours = ours;
    }
    if (productUrl && productUrl !== productUse?.product) {
      changed.product = productUrl;
    }
    onOk(changed);
  }, [notes, onOk, ours, productUrl, productUse?.notes, productUse?.ours, productUse?.product]);

  const intl = useIntl();

  const currentRole = useSelector(getCurrentRole);
  const isManager = !!currentRole?.manager;
  const currentUserURL = useSelector(getCurrentUserURL);

  const [switchProductDialogOpen, setSwitchProductDialogOpen] = useState(false);

  const handleSwitchProductDialogOk = useCallback((newProductUrl: ProductUrl): void => {
    setProductUrl(newProductUrl);
    setSwitchProductDialogOpen(false);
  }, []);

  const handleSwitchProductDialogCancel = useCallback((): void => {
    setSwitchProductDialogOpen(false);
  }, []);

  const handleSwitchProductClick = useCallback((): void => {
    setSwitchProductDialogOpen(true);
  }, []);

  if (!productUse || !product) {
    return null;
  }

  const switchProductButton =
    withSwitchProduct && !autoProduct ? (
      <Button
        fullWidth
        color="primary"
        disabled={!switchable}
        variant="contained"
        onClick={handleSwitchProductClick}
      >
        <FormattedMessage defaultMessage="Skift vare" />
      </Button>
    ) : null;

  const deleteProductButton =
    onDeleteClick &&
    !autoProduct &&
    (isManager || (currentUserURL && productUse.addedBy === currentUserURL)) ? (
      <ErrorColorButton fullWidth disabled={!removable} variant="contained" onClick={onDeleteClick}>
        <FormattedMessage defaultMessage="Slet vare" />
      </ErrorColorButton>
    ) : null;

  return (
    <>
      <ResponsiveDialog
        open={open}
        title={
          customerSettings.materialUseAlternativeText
            ? intl.formatMessage({defaultMessage: "Materiale detaljer"})
            : intl.formatMessage({defaultMessage: "Materiel detaljer"})
        }
        onCancel={onCancel}
        onOk={handleOk}
      >
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              {product.name} ({product.catalogNumber})
            </Grid>
            {customerSettings.showOursToggle && !autoProduct ? (
              <Grid item xs={12}>
                <RadioGroup name="ours" value={ours ? "true" : "false"} onChange={handleOursChange}>
                  <FormControlLabel
                    control={<Radio />}
                    disabled={!editable}
                    label={intl.formatMessage({
                      defaultMessage: "Varen tilhører os og skal faktureres til kunden",
                    })}
                    value="true"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    disabled={!editable}
                    label={intl.formatMessage({
                      defaultMessage: "Varen tilhører kunden og skal ikke faktureres",
                    })}
                    value="false"
                  />
                </RadioGroup>
              </Grid>
            ) : null}
            {showNotes ? (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  disabled={!notesEditable}
                  id="notes-field"
                  margin="dense"
                  placeholder={intl.formatMessage({defaultMessage: "Noter"})}
                  value={notes}
                  variant="outlined"
                  onChange={handleNotesChange}
                />
              </Grid>
            ) : null}
            {switchProductButton || deleteProductButton ? (
              <Grid item xs={12}>
                <Grid container spacing={2} style={{marginTop: 24}}>
                  {switchProductButton ? (
                    <Grid item xs={12}>
                      {switchProductButton}
                    </Grid>
                  ) : null}
                  {deleteProductButton ? (
                    <Grid item xs={12}>
                      {deleteProductButton}
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </DialogContent>
      </ResponsiveDialog>
      <ConnectedSingleProductDialog
        open={switchProductDialogOpen}
        preferredCategory="recentlyUsed"
        preferredProductURLs={productDialogPreferredProductURLs}
        priceGroups={productDialogPriceGroups}
        workType={productDialogWorkType}
        onCancel={handleSwitchProductDialogCancel}
        onOk={handleSwitchProductDialogOk}
      />
    </>
  );
});
