import {SettingID, settings} from "@co-common-libs/config";
import {emptyTask} from "@co-common-libs/resources";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {
  actions,
  getCurrentUserURL,
  getSettingsEntryLookupByIdentifier,
} from "@co-frontend-libs/redux";
import {
  Checkbox,
  DialogContent,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {useEventTargetCheckedCallback} from "app-utils";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

export const taskCopyExcludes = [
  "archivable",
  "calledIn",
  "cancelled",
  "completed",
  "completedAsInternal",
  "computedTimeSet",
  "continuationTask",
  "machineOperatorTimeCorrectionSet",
  "managerTimeCorrectionSet",
  "recordedInC5",
  "reportApproved",
  "validatedAndRecorded",
  "workFromTimestamp",
  "workToTimestamp",
];

type TaskCopyFieldsMember = keyof typeof emptyTask;
type TaskCopyFields = {[member in TaskCopyFieldsMember]: boolean};

const TaskCopyFieldRow = ({
  fieldName,
  onChange,
  value,
}: {
  fieldName: TaskCopyFieldsMember;
  onChange: (value: boolean, field: TaskCopyFieldsMember) => void;
  value: boolean;
}): JSX.Element => {
  const handeEnableChanged = useCallback(
    (checked: boolean) => {
      onChange(checked, fieldName);
    },
    [fieldName, onChange],
  );

  return (
    <TableRow>
      <TableCell style={{width: 200}}>{fieldName}</TableCell>
      <TableCell>
        <FormControl>
          <Checkbox
            checked={value}
            onChange={useEventTargetCheckedCallback(handeEnableChanged, [handeEnableChanged])}
          />
        </FormControl>
      </TableCell>
    </TableRow>
  );
};

interface GeolocationDialogDialogProps {
  onClose: () => void;
  open: boolean;
  settingID: SettingID;
}

export function TaskCopyFieldsDialog(props: GeolocationDialogDialogProps): JSX.Element {
  const {onClose, open, settingID} = props;

  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const settingEntry = settingsEntryLookupByIdentifier(settingID);

  const settingMeta = settings[settingID];

  const initialTaskCopyFields: TaskCopyFields = useMemo(() => {
    const data: TaskCopyFieldsMember[] = settingEntry?.data || settingMeta.schema.default;
    const newTaskCopyFields: Partial<TaskCopyFields> = {};
    const taskKeys = Object.keys(emptyTask);
    data.forEach((key) => {
      if (!taskKeys.includes(key)) {
        // eslint-disable-next-line no-console
        console.error(`'${key}' not in emptyTask object`);
      }
    });
    taskKeys.sort();
    taskKeys.forEach((key) => {
      if (!taskCopyExcludes.includes(key)) {
        newTaskCopyFields[key as TaskCopyFieldsMember] = data.includes(key as TaskCopyFieldsMember);
      }
    });
    return newTaskCopyFields as TaskCopyFields;
  }, [settingEntry?.data, settingMeta.schema.default]);

  const [taskCopyFields, setTaskCopyFields] = useState(initialTaskCopyFields);
  useEffect(() => {
    if (open) {
      setTaskCopyFields(initialTaskCopyFields);
    }
  }, [initialTaskCopyFields, open]);

  const dispatch = useDispatch();
  const currentUserURL = useSelector(getCurrentUserURL);

  const handleSave = useCallback(() => {
    const newValues: TaskCopyFieldsMember[] = [];
    Object.entries(taskCopyFields).forEach(([field, value]) => {
      if (value) {
        newValues.push(field as TaskCopyFieldsMember);
      }
    });
    if (settingEntry) {
      dispatch(
        actions.update(settingEntry.url, [
          {member: "changedBy", value: currentUserURL},
          {member: "data", value: newValues},
        ]),
      );
    }
    onClose();
  }, [currentUserURL, dispatch, onClose, settingEntry, taskCopyFields]);

  const handeEnableChanged = useCallback(
    (value: boolean, field: TaskCopyFieldsMember) => {
      setTaskCopyFields(() => ({...taskCopyFields, [field]: value}));
    },
    [taskCopyFields],
  );

  return (
    <ResponsiveDialog
      fullWidth
      okLabel={<FormattedMessage defaultMessage="Gem" id="setting-dialog.label.save" />}
      open={open}
      title={
        <FormattedMessage
          defaultMessage="Vælg felter"
          id="system-setup.dialog-title.select-fields"
        />
      }
      onCancel={onClose}
      onOk={handleSave}
    >
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{width: 200}}>
                <FormattedMessage defaultMessage="Navn" id="system-setup.dialog-title.name" />
              </TableCell>
              <TableCell>
                <FormattedMessage defaultMessage="Kopieres" id="system-setup.dialog-title.copy" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(Object.entries(taskCopyFields) as [TaskCopyFieldsMember, boolean][]).map(
              ([field, value]) => (
                <TaskCopyFieldRow
                  key={field}
                  fieldName={field}
                  value={value}
                  onChange={handeEnableChanged}
                />
              ),
            )}
          </TableBody>
        </Table>
      </DialogContent>
    </ResponsiveDialog>
  );
}
