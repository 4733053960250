import {useTheme} from "@material-ui/core";
import SlashForwardIcon from "mdi-react/SlashForwardIcon";
import React from "react";

export const ColorBox = ({
  color,
  inline,
  showSlashOnEmpty,
}: {
  color: string | null;
  inline?: boolean;
  showSlashOnEmpty?: boolean;
}): JSX.Element => {
  const theme = useTheme();
  if (!color && !showSlashOnEmpty) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }
  return color ? (
    <div
      style={{
        backgroundColor: color,
        border: "1px solid #000",
        display: inline ? "inline-block" : "block",
        height: 16,
        width: 16,
      }}
    />
  ) : (
    <div
      style={{
        border: "1px solid #000",
        display: inline ? "inline-block" : "block",
        height: 16,
        width: 16,
      }}
    >
      <SlashForwardIcon
        style={{
          color: theme.palette.error.main,
          height: 15,
          width: 15,
        }}
      />
    </div>
  );
};
