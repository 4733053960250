import {
  LocationUrl,
  PatchOperation,
  ReportingLocation,
  ReportingLocations,
  ReportingLog,
  ReportingSpecification,
  Task,
} from "@co-common-libs/resources";
import {getUpdatedFieldUseList} from "@co-common-libs/resources-utils";
import {
  actions,
  getLocationLookup,
  getLocationUseLogArray,
  getOrderLookup,
} from "@co-frontend-libs/redux";
import _ from "lodash";
import React, {useCallback, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuid} from "uuid";
import {FieldMultiSelectionDialog} from "../customer-field-dialog";
import {ValuesState} from "./edit-reporting-location-dialog";
import {MultiFieldWithLogDataDialog} from "./field-with-log-data-dialogs";

function getLocationWithLogRegistrations(
  reportingLocations: ReportingLocations,
  reportingLog: ReportingLog,
): Set<LocationUrl> {
  const usedFieldsUrls = new Set<LocationUrl>();

  for (const logEntry of Object.values(reportingLog)) {
    const logLocation = reportingLocations[logEntry.location];
    if (logLocation && logLocation.location) {
      usedFieldsUrls.add(logLocation.location);
    }
  }
  return usedFieldsUrls;
}

interface SelectTaskFieldsProps {
  logSpecification: ReportingSpecification | undefined;
  onClose: () => void;
  open: boolean;
  task: Task;
}

export const SelectTaskFields = React.memo(function SelectTaskFields(
  props: SelectTaskFieldsProps,
): JSX.Element {
  const {logSpecification, onClose, open, task} = props;

  const locationUseLogArray = useSelector(getLocationUseLogArray);
  const orderLookup = useSelector(getOrderLookup);
  const locationLookup = useSelector(getLocationLookup);

  const dispatch = useDispatch();

  const order = task.order ? orderLookup(task.order) : undefined;
  const customerUrl = order?.customer;

  const lastUsedLocations = useMemo(() => {
    const machineOperatorUrl = task.machineOperator;
    if (customerUrl && machineOperatorUrl) {
      const locationUse = locationUseLogArray.find(
        (l) => l.customer === customerUrl && l.user === machineOperatorUrl,
      );
      if (locationUse?.locations?.length) {
        return new Set<LocationUrl>(locationUse.locations);
      }
    }
    return undefined;
  }, [customerUrl, locationUseLogArray, task.machineOperator]);

  const fieldLogType = useMemo((): ReportingLocation["type"] | undefined => {
    const fieldsUsedFor = logSpecification?.fieldsUsedFor;
    if (fieldsUsedFor === "unused") {
      return undefined;
    } else {
      return fieldsUsedFor;
    }
  }, [logSpecification?.fieldsUsedFor]);

  const mustKeepLocations = useMemo((): ReadonlySet<LocationUrl> => {
    if (fieldLogType) {
      return getLocationWithLogRegistrations(task.reportingLocations, task.reportingLog);
    } else {
      return new Set();
    }
  }, [fieldLogType, task.reportingLocations, task.reportingLog]);

  const logLocationArray = useMemo(
    () => Object.values(task.reportingLocations),
    [task.reportingLocations],
  );

  const locationDataMap = useMemo((): ReadonlyMap<LocationUrl, ValuesState> => {
    return new Map<LocationUrl, ValuesState>(
      // sort to "best match" -- right type, early order -- *last*
      _.sortBy(
        logLocationArray.filter(
          (entry: ReportingLocation): entry is ReportingLocation & {location: LocationUrl} =>
            entry.location !== undefined,
        ),
        [
          ({type: locationType}) => locationType !== fieldLogType,
          ({order: locationOrder}) => -locationOrder,
        ],
      ).map(({location, values}) => [location, values || {}]),
    );
  }, [fieldLogType, logLocationArray]);

  const handleFieldWithLogDataDialogOk = useCallback(
    (data: ReadonlyMap<LocationUrl, ValuesState>): void => {
      if (!fieldLogType) {
        // impossible
        return;
      }
      // Add new based on fieldLogType.
      // Remove absent from new selection if legal.
      // Possibly update data -- possible on deselection followed by selection.
      //
      // UI will disallow adding same location as same type multiple times;
      // possible from concurrent updates, but not specifically supported here.
      // Same location as different types -- pickup + delivery -- legal.
      // For simplicity, UI does not allow removal if any instance of location
      // in use with log registration.
      const patch: PatchOperation<Task>[] = [];

      const processedLocations = new Set<LocationUrl>();

      for (const [identifier, entry] of Object.entries(task.reportingLocations)) {
        if (entry.location && mustKeepLocations.has(entry.location)) {
          // should be present and not removable, so no change possible
          continue;
        }
        const newValues = entry.location && data.get(entry.location);
        if (!entry.location || newValues === undefined) {
          // removed
          patch.push({
            path: ["reportingLocations", identifier],
            value: undefined,
          });
        } else if (_.isEqual(newValues, locationDataMap.get(entry.location))) {
          // no change from dialog; assume no change intended
          processedLocations.add(entry.location);
        } else if (entry.type !== fieldLogType) {
          // change, so deselect + select; assume only other type, for which
          // data was entered, is intended
          patch.push({
            path: ["reportingLocations", identifier],
            value: undefined,
          });
        } else {
          // change, so deselect + select; but right type, so update data
          // for entry, rather than remove + add
          patch.push({
            path: ["reportingLocations", identifier, "values"],
            value: newValues,
          });
          processedLocations.add(entry.location);
        }
      }

      // some of those may be removed shortly, but skipping some numbers is OK
      const currentMaxOrder = _.max(
        logLocationArray.map(({order: locationOrder}) => locationOrder),
      );
      let nextOrder = currentMaxOrder !== undefined ? currentMaxOrder + 1 : 0;

      for (const [locationUrl, values] of data.entries()) {
        if (mustKeepLocations.has(locationUrl) || processedLocations.has(locationUrl)) {
          // already present (and not removed)
          continue;
        }
        const location = locationLookup(locationUrl);
        const customer = location?.customer || customerUrl;
        if (!customer) {
          continue;
        }
        const newEntry: ReportingLocation = {
          customer,
          location: locationUrl,
          order: nextOrder,
          productUses: {},
          type: fieldLogType,
          values,
        };
        nextOrder += 1;
        const newIdentifier = uuid();

        patch.push({
          path: ["reportingLocations", newIdentifier],
          value: newEntry,
        });
      }

      if (patch.length) {
        dispatch(actions.update(task.url, patch));
      }
      onClose();
    },
    [
      customerUrl,
      dispatch,
      fieldLogType,
      locationDataMap,
      locationLookup,
      logLocationArray,
      mustKeepLocations,
      onClose,
      task.reportingLocations,
      task.url,
    ],
  );

  const handleFieldWithoutLogDialogOk = useCallback(
    (fieldURLSet: ReadonlySet<LocationUrl>): void => {
      const oldSelected = task.fielduseSet;
      const fieldUseList = getUpdatedFieldUseList(fieldURLSet, oldSelected, locationLookup);
      dispatch(actions.update(task.url, [{member: "fielduseSet", value: fieldUseList}]));

      onClose();
    },
    [task.fielduseSet, task.url, locationLookup, dispatch, onClose],
  );

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {fieldLogType ? (
        <MultiFieldWithLogDataDialog
          customerUrl={customerUrl || null}
          lastUsedLocations={lastUsedLocations}
          locationDataMap={locationDataMap}
          logSpecification={logSpecification}
          multiSelectMax={50}
          open={open}
          readonlySet={mustKeepLocations}
          type={fieldLogType}
          onCancel={onClose}
          onOk={handleFieldWithLogDataDialogOk}
        />
      ) : (
        <FieldMultiSelectionDialog
          customerURL={customerUrl}
          lastUsedLocations={lastUsedLocations}
          open={open}
          readonlySet={undefined}
          selected={task.fielduseSet}
          onCancel={onClose}
          onOk={handleFieldWithoutLogDialogOk}
        />
      )}
    </>
  );
});
