import {MenuToolbar, PageLayout} from "app-components";
import React, {useState} from "react";
import {useIntl} from "react-intl";
import {ShowInactiveToggle} from "../shared/show-inactive-toggle";
import {UnitsList} from "./units/units-list";

interface UnitsSettingsPageProps {
  onMenuButton: (event: React.MouseEvent) => void;
}

export const UnitsSettingsPage = React.memo(function UnitsSettingsPage(
  props: UnitsSettingsPageProps,
): JSX.Element {
  const intl = useIntl();

  const [showInactive, setShowInactive] = useState(false);

  return (
    <PageLayout
      withBottomScrollPadding
      toolbar={
        <MenuToolbar
          rightElement={
            <ShowInactiveToggle setShowInactive={setShowInactive} showInactive={showInactive} />
          }
          title={intl.formatMessage({defaultMessage: "Enheder"})}
          onMenuButton={props.onMenuButton}
        />
      }
    >
      <UnitsList showInactive={showInactive} />
    </PageLayout>
  );
});
