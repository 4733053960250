import {SettingID, settings} from "@co-common-libs/config";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {
  actions,
  getCurrentUserURL,
  getCustomerSettings,
  getSettingEntryArray,
} from "@co-frontend-libs/redux";
import {Checkbox, DialogContent, FormControl} from "@material-ui/core";
import {useEventTargetCheckedCallback} from "app-utils";
import {theme} from "frontend-global-config";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {ErrorsBlock} from "../errors-block";
import {checkConflictsWith, checkRequiresAllOf, checkRequiresOneOf, getValidator} from "../utils";

export interface UseApproveReportDialogProps {
  nonBlankSettings: ReadonlySet<SettingID>;
  onClose: () => void;
  open: boolean;
  settingID: SettingID;
}

export function UseApproveReportDialog(props: UseApproveReportDialogProps): JSX.Element | null {
  const {nonBlankSettings, onClose, open, settingID} = props;

  const settingEntryArray = useSelector(getSettingEntryArray);
  const settingEntry = useMemo(
    () => settingEntryArray.find((entry) => entry.key === settingID),
    [settingEntryArray, settingID],
  );

  const currentUserURL = useSelector(getCurrentUserURL);
  const settingMetaData = settings[settingID];

  const {schema} = settingMetaData;

  const savedValue: boolean = settingEntry?.data ?? settingMetaData.defaultValue;

  const [value, setValue] = useState(savedValue);

  useEffect(() => {
    if (open) {
      setValue(savedValue);
    }
  }, [open, savedValue]);

  const dispatch = useDispatch();

  const handleSave = useCallback(() => {
    if (value !== undefined) {
      if (settingEntry) {
        dispatch(
          actions.update(settingEntry.url, [
            {member: "changedBy", value: currentUserURL},
            {member: "data", value},
          ]),
        );
      }
    }
    onClose();
  }, [currentUserURL, dispatch, value, onClose, settingEntry]);

  const validator = useMemo(() => (schema ? getValidator(schema) : null), [schema]);
  const schemaErrors = useMemo(() => (validator ? validator(value) : null), [validator, value]);

  const handleCheckedChange = useEventTargetCheckedCallback(setValue, [setValue]);

  const customerSettings = useSelector(getCustomerSettings);

  const {description} = settingMetaData;

  const conflictsWithIssue = checkConflictsWith(settingMetaData, nonBlankSettings, value);
  const requiresAllOfIssue = checkRequiresAllOf(settingMetaData, nonBlankSettings, value);
  const requiresOneOfIssue = checkRequiresOneOf(settingMetaData, nonBlankSettings, value);

  const settingsConflict =
    value && customerSettings.recordInternalTasks && customerSettings.economicSync;

  return (
    <ResponsiveDialog
      fullWidth
      okDisabled={
        !!schemaErrors ||
        !!conflictsWithIssue ||
        !!requiresAllOfIssue ||
        !!requiresOneOfIssue ||
        settingsConflict
      }
      okLabel={<FormattedMessage defaultMessage="Gem" />}
      open={open}
      title={description}
      onCancel={onClose}
      onOk={handleSave}
    >
      <DialogContent>
        <FormControl>
          <Checkbox checked={value} onChange={handleCheckedChange} />
        </FormControl>
        {settingsConflict ? (
          <div style={{color: theme.palette.error.main}}>
            <FormattedMessage defaultMessage="Du kan ikke aktivere to-trinsgodkendelse når der synkronisering med e-conomic er slået til, før du har slået overførsel af interne opgaver fra." />
          </div>
        ) : null}
        <ErrorsBlock
          nonBlankSettings={nonBlankSettings}
          settingMetaData={settingMetaData}
          value={value}
        />
      </DialogContent>
    </ResponsiveDialog>
  );
}
