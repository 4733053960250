import {
  KrPerLiterFuelSurchargeSpecification,
  KrPerLiterFuelSurchargeSpecificationEntry,
  MachineUrl,
  PriceItemUrl,
  PricePercentFuelSurchargeSpecification,
  PricePercentFuelSurchargeSpecificationEntry,
  ProductUrl,
} from "@co-common-libs/resources";
import {findFuelSurchargeSpecificationEntry} from "./find-fuel-surcharge-specification-entry";

type KrPerLiterFuelSurchargeSpecificationPart = Pick<KrPerLiterFuelSurchargeSpecification, "url">;

type KrPerLiterFuelSurchargeSpecificationEntryPart = Pick<
  KrPerLiterFuelSurchargeSpecificationEntry,
  "fromDate" | "specification" | "toDate"
>;

type PricePercentFuelSurchargeSpecificationPart = Pick<
  PricePercentFuelSurchargeSpecification,
  "url"
>;

type PricePercentFuelSurchargeSpecificationEntryPart = Pick<
  PricePercentFuelSurchargeSpecificationEntry,
  "fromDate" | "specification" | "toDate"
>;

export function getMachineUseMatchesSpecificationEntries<
  Specification extends KrPerLiterFuelSurchargeSpecificationPart,
  Entry extends KrPerLiterFuelSurchargeSpecificationEntryPart,
  Use extends Record<string, unknown>,
>(
  entryArray: readonly Entry[],
  date: string,
  matches: ReadonlyMap<
    MachineUrl,
    {
      readonly specification: Specification | null;
      readonly use: Use;
    }
  >,
): Map<
  MachineUrl,
  {
    specification: Specification | null;
    specificationEntry: Entry | null;
    use: Use;
  }
> {
  return new Map(
    Array.from(matches).map(([machineUrl, match]) => {
      if (match) {
        const {specification, use} = match;
        const specificationEntry = specification
          ? findFuelSurchargeSpecificationEntry(specification.url, entryArray, date)
          : null;
        return [machineUrl, {specification, specificationEntry, use}];
      } else {
        return [machineUrl, match];
      }
    }),
  );
}

export function getPriceItemProductUseMatchesSpecificationEntries<
  Specification extends PricePercentFuelSurchargeSpecificationPart,
  Entry extends PricePercentFuelSurchargeSpecificationEntryPart,
  Use extends Record<string, unknown>,
>(
  entryArray: readonly Entry[],
  date: string,
  matches: ReadonlyMap<
    PriceItemUrl | ProductUrl,
    {
      readonly specification: Specification | null;
      readonly use: Use;
    }
  >,
): Map<
  PriceItemUrl | ProductUrl,
  {
    specification: Specification | null;
    specificationEntry: Entry | null;
    use: Use;
  }
> {
  return new Map(
    Array.from(matches).map(([priceItemProductUseId, match]) => {
      if (match) {
        const {specification, use} = match;
        const specificationEntry = specification
          ? findFuelSurchargeSpecificationEntry(specification.url, entryArray, date)
          : null;
        return [priceItemProductUseId, {specification, specificationEntry, use}];
      } else {
        return [priceItemProductUseId, match];
      }
    }),
  );
}
