import {
  PriceItem,
  PriceItemUrl,
  PriceItemUse,
  Timer,
  WorkType,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {mapSome, setFilter, setFind} from "@co-common-libs/utils";
import _ from "lodash";

const simpleMemoize = <T, U>(fn: (arg: T) => U): ((arg: T) => U) => {
  let oldArg: T | undefined;
  let value: U | undefined;
  return (arg: T): U => {
    if (arg !== oldArg) {
      value = fn(arg);
      oldArg = arg;
    }
    return value as U;
  };
};

const isActive = (timer: Timer): boolean => {
  return timer.active;
};

const isBreak = (timer: Timer): boolean => {
  return timer.isBreak;
};

function arrayFrom<T>(
  arrayOrMapOrSet: ReadonlyMap<any, T> | ReadonlySet<T> | readonly T[],
): readonly T[] {
  if (Array.isArray(arrayOrMapOrSet)) {
    return arrayOrMapOrSet;
  } else {
    console.assert(arrayOrMapOrSet instanceof Map || arrayOrMapOrSet instanceof Set);
    return Array.from(arrayOrMapOrSet.values());
  }
}

/** Find the "break" timer instance from timer map/list/... */
export const getBreakTimer = simpleMemoize(
  (timerIterable: ReadonlyMap<string, Timer> | ReadonlySet<Timer> | readonly Timer[]) => {
    return arrayFrom(timerIterable).filter(isActive).find(isBreak);
  },
);

const isGenericEffectiveTime = (timer: Timer): boolean => {
  return timer.isGenericEffectiveTime;
};

export const getGenericPrimaryTimer = simpleMemoize(
  (timerIterable: ReadonlyMap<string, Timer> | ReadonlySet<Timer> | readonly Timer[]) => {
    return arrayFrom(timerIterable).filter(isActive).find(isGenericEffectiveTime);
  },
);

export const getGenericEffectiveTimer = getGenericPrimaryTimer;

const forInternal = (timer: Timer): boolean => {
  return timer.includeForInternalTask;
};

export const getInternalTimers = simpleMemoize(
  (timerIterable: ReadonlyMap<string, Timer> | ReadonlySet<Timer> | readonly Timer[]) => {
    return new Set(
      _.sortBy(
        _.sortBy(arrayFrom(timerIterable).filter(isActive).filter(forInternal), (t) => t.label),
        (t) => t.identifier,
      ),
    );
  },
);

export const getInternalSecondaryTimers = simpleMemoize(
  (timerIterable: ReadonlyMap<string, Timer> | ReadonlySet<Timer> | readonly Timer[]) => {
    return setFilter(getInternalTimers(timerIterable), (x) => !isGenericEffectiveTime(x));
  },
);

const forExternal = (timer: Timer): boolean => {
  return timer.includeForExternalTask;
};

export const getExternalTimers = simpleMemoize(
  (timerIterable: ReadonlyMap<string, Timer> | ReadonlySet<Timer> | readonly Timer[]) => {
    return new Set(
      _.sortBy(
        _.sortBy(arrayFrom(timerIterable).filter(isActive).filter(forExternal), (t) => t.label),
        (t) => t.identifier,
      ),
    );
  },
);

export const getExternalSecondaryTimers = simpleMemoize(
  (timerIterable: ReadonlyMap<string, Timer> | ReadonlySet<Timer> | readonly Timer[]) => {
    return new Set(
      Array.from(getExternalTimers(timerIterable)).filter((x) => !isGenericEffectiveTime(x)),
    );
  },
);

export const hasSecondaryInvoicedTime = (
  secondaryTimers: ReadonlySet<Timer>,
  timerMinutesMap: ReadonlyMap<string, number>,
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined,
  timersContributingToEffectiveTime: readonly string[],
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined,
  priceItemUseSet: readonly Pick<PriceItemUse, "priceItem" | "timer">[],
): boolean => {
  console.assert(secondaryTimers instanceof Set);
  console.assert(timerMinutesMap instanceof Map);
  return mapSome(timerMinutesMap, (minutes, timerURL) => {
    if (!minutes) {
      return false;
    }
    const timer = setFind(secondaryTimers, (t) => t.url === timerURL);
    if (!timer) {
      return false;
    }
    if (
      timersContributingToEffectiveTime.length &&
      timersContributingToEffectiveTime.includes(timer.identifier)
    ) {
      return true;
    }

    const priceItemUses = priceItemUseSet.filter((use) => use.timer === timerURL);

    if (
      priceItemUses.some((priceItemUse) => {
        const priceItem = priceItemUse.priceItem && priceItemLookup(priceItemUse.priceItem);
        return priceItem && priceItem.billable;
      })
    ) {
      return true;
    }
    const workType = timer.workType && workTypeLookup(timer.workType);
    if (workType && workType.productive) {
      return true;
    }
    return false;
  });
};
