import {frontendVersion} from "@co-common-libs/frontend-version";
import {getUpdateReady} from "@co-frontend-libs/redux";
import {LinearProgress} from "@material-ui/core";
import {ClearAppButton} from "app-components";
import {updateAutoReloadCallback} from "app-utils";
import React, {useEffect} from "react";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";

function FetchingUserData(): JSX.Element {
  const updateReady = useSelector(getUpdateReady);

  useEffect(() => {
    if (updateReady) {
      updateAutoReloadCallback();
    }
  }, [updateReady]);

  return (
    <div style={{alignItems: "center", display: "flex", height: "100vh"}}>
      <div style={{textAlign: "center", width: "100%"}}>
        <FormattedMessage
          defaultMessage="Henter initielle data"
          id="fetching-user-data.label.fetching-initial-data"
        />
        <LinearProgress variant="indeterminate" />
        <br />
        <span style={{fontSize: 12, margin: 16, opacity: 0.4}}>v. {frontendVersion}</span>
      </div>
      <div
        style={{
          bottom: 10,
          left: 10,
          position: "fixed",
        }}
      >
        <ClearAppButton />
      </div>
    </div>
  );
}

export default FetchingUserData;
