import {
  Location,
  LocationUrl,
  PriceItem,
  PriceItemUrl,
  Product,
  ProductUrl,
  Spray,
  SprayLocation,
  SprayLog,
  Task,
  Unit,
  UnitUrl,
} from "@co-common-libs/resources";
import {getUnitCode, getUnitString, priceItemIsVisible} from "@co-common-libs/resources-utils";
import {formatDateNumeric, identifierComparator, sortByOrderMember} from "@co-common-libs/utils";
import {FilePdfIcon} from "@co-frontend-libs/components";
import {
  AppState,
  getLocationLookup,
  getPriceItemLookup,
  getProductLookup,
  getSprayArray,
  getSprayLocationArray,
  getSprayLogArray,
  getToken,
  getUnitLookup,
} from "@co-frontend-libs/redux";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {AjaxDownloadButton} from "app-components";
import {PureComponent} from "app-utils";
import {globalConfig} from "frontend-global-config";
import _ from "lodash";
import React from "react";
import {FormattedMessage, FormattedNumber, IntlContext, defineMessages} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";

const messages = defineMessages({
  downloadPDF: {
    defaultMessage: "Download PDF",
    id: "spray-log-list.download-pdf",
  },
  filename: {
    defaultMessage: "Sprøjtelog_{fromDate}---{toDate}_{customerName}.pdf",
    id: "spray-log-list.filename",
  },
  sprayLog: {
    defaultMessage: "Sprøjtelog",
    id: "spray-log-list.label.spraylog",
  },
});

interface SprayRowProps {
  area: number | null;
  count: number;
  crop: string;
  field: string;
  nozzleType: string;
  product: string;
  timestamp: string;
  unit: string;
}

class SprayRow extends PureComponent<SprayRowProps> {
  render(): JSX.Element {
    const {area, count, crop, field, nozzleType, product, timestamp, unit} = this.props;

    return (
      <TableRow>
        <TableCell>{timestamp}</TableCell>
        <TableCell>{field}</TableCell>
        <TableCell>{crop}</TableCell>
        <TableCell>{product}</TableCell>
        <TableCell>{area} ha</TableCell>
        <TableCell>
          {area ? <FormattedNumber value={count / area} /> : null} {unit}/ha
        </TableCell>
        <TableCell>
          {count} {unit}
        </TableCell>
        <TableCell>{nozzleType}</TableCell>
      </TableRow>
    );
  }
}

const fieldComparer = (a: {address: string}, b: {address: string}): number => {
  const fieldA = a.address;
  const fieldB = b.address;
  return identifierComparator(fieldA, fieldB);
};

interface SprayLogListStateProps {
  locationLookup: (url: LocationUrl) => Location | undefined;
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined;
  productLookup: (url: ProductUrl) => Product | undefined;
  sprayArray: readonly Spray[];
  sprayLocationArray: readonly SprayLocation[];
  sprayLogArray: readonly SprayLog[];
  token: string | null;
  unitLookup: (url: UnitUrl) => Unit | undefined;
}

interface SprayLogListOwnProps {
  customerName: string;
  fromDate: string;
  taskList: readonly Task[];
  toDate: string;
}

type SprayLogListProps = SprayLogListOwnProps & SprayLogListStateProps;

class SprayLogList extends PureComponent<SprayLogListProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {
      customerName,
      fromDate,
      locationLookup,
      priceItemLookup,
      productLookup,
      sprayArray,
      sprayLocationArray,
      sprayLogArray,
      toDate,
      unitLookup,
    } = this.props;
    const rowArray: JSX.Element[] = [];
    const sprayArrayData: {
      address: string;
      area: number | null;
      crop: string;
      locationSprayList: {
        count: number;
        dose: number;
        name: string;
        note: any;
        nozzleType: string;
        unit: string;
      }[];
      timestamp: string;
    }[] = [];

    this.props.taskList.forEach((task) => {
      const sprayLog = sprayLogArray.find((log) => log.task === task.url);
      if (!sprayLog) {
        return;
      }
      const sprayLogURL = sprayLog.url;
      const nozzleType = sprayLog.nozzleType || "";
      const taskPriceItemUseList = sortByOrderMember(Object.values(task.priceItemUses || {}));
      sprayLocationArray
        .filter((p) => p.spraylog === sprayLogURL)
        .forEach((sprayLocation) => {
          const location = sprayLocation.relatedLocation
            ? locationLookup(sprayLocation.relatedLocation)
            : null;
          const address = location ? location.name || location.address : "";
          const {crop} = sprayLocation;
          const sprayLocationURL = sprayLocation.url;

          let {area} = sprayLocation;
          const locationSprayList: {
            count: number;
            dose: number;
            name: string;
            note: string;
            nozzleType: string;
            unit: string;
          }[] = [];
          const spray = sprayArray.find((p) => p.location === sprayLocationURL);

          if (!spray) {
            return;
          }

          spray.priceitemuseSet.forEach((priceItemUse) => {
            const priceItem = priceItemLookup(priceItemUse.priceItem);
            if (
              !priceItem ||
              !priceItemIsVisible(
                priceItem,
                false,
                taskPriceItemUseList,
                unitLookup,
                priceItemLookup,
              )
            ) {
              return;
            }
            const unit = getUnitCode(priceItem, unitLookup).toLowerCase();
            const count = priceItemUse.count || 0;
            if (unit === "ha" || unit === "ha.") {
              area = count;
            } else {
              const {name} = priceItem;
              const existing = locationSprayList.find((item) => item.name === name);
              const dose = area ? count / area : 0;

              const {notes} = priceItemUse;
              if (existing) {
                existing.count += count;
                existing.note += notes ? ` ${notes}` : "";
              } else {
                const item = {
                  count,
                  dose,
                  name: priceItem.name,
                  note: priceItemUse.notes || "",
                  nozzleType,
                  unit: getUnitString(priceItem, unitLookup),
                };
                locationSprayList.push(item);
              }
            }
          });
          spray.productuseSet.forEach((productUse) => {
            const product = productLookup(productUse.product);
            if (!product) {
              return;
            }
            const {name} = product;
            const {notes} = productUse;
            const existing = locationSprayList.find((item) => item.name === name);
            const count = productUse.count || 0;
            const decimals = 2;
            if (existing) {
              existing.count += count;
              existing.note += notes ? ` ${notes}` : "";
            } else {
              const item = {
                count,
                dose: area ? _.round(count / area, decimals) : 0,
                name: product.name,
                note: productUse.notes || "",
                nozzleType,
                unit: getUnitString(product, unitLookup),
              };
              locationSprayList.push(item);
            }
          });
          sprayArrayData.push({
            address,
            area,
            crop,
            locationSprayList,
            timestamp: formatDateNumeric(spray.deviceTimestamp as string),
          });
        });
    });
    sprayArrayData.sort(fieldComparer);
    sprayArrayData.forEach((sprayLocation, locationIndex) => {
      sprayLocation.locationSprayList.forEach((spray, index) => {
        rowArray.push(
          <SprayRow
            key={`${locationIndex}-${index}`}
            area={sprayLocation.area}
            count={spray.count}
            crop={sprayLocation.crop}
            field={sprayLocation.address}
            nozzleType={spray.nozzleType}
            product={spray.name}
            timestamp={sprayLocation.timestamp}
            unit={spray.unit}
          />,
        );
      });
    });

    const pdfUrl = `${globalConfig.baseURL}/download/spraylog_report/pdf`;
    const pdfData = {
      customerName,
      data: sprayArrayData,
      deviceTime: Date.now(),
      fromDateString: formatDateNumeric(fromDate),
      toDateString: formatDateNumeric(toDate),
    };

    return (
      <div>
        <AjaxDownloadButton
          data={pdfData}
          downloadURL={pdfUrl}
          filename={formatMessage(messages.filename, {
            customerName: this.props.customerName,
            fromDate,
            toDate,
          })}
          Icon={FilePdfIcon}
          label={formatMessage(messages.downloadPDF)}
          token={this.props.token}
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <FormattedMessage defaultMessage="Dato" id="customer-instance.table-header.date" />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Marknr."
                  id="customer-instance.table-header.field"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Afgrøde"
                  id="customer-instance.table-header.crop"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Middel"
                  id="customer-instance.table-header.product"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage defaultMessage="Areal" id="customer-instance.table-header.area" />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Dosering"
                  id="customer-instance.table-header.dose"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Mængde"
                  id="customer-instance.table-header.count"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Dysetype"
                  id="customer-instance.table-header.nozzle-type"
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{rowArray}</TableBody>
        </Table>
      </div>
    );
  }
}

const ConnectedSprayLogList: React.ComponentType<SprayLogListOwnProps> = connect<
  SprayLogListStateProps,
  object,
  SprayLogListOwnProps,
  AppState
>(
  createStructuredSelector<AppState, SprayLogListStateProps>({
    locationLookup: getLocationLookup,
    priceItemLookup: getPriceItemLookup,
    productLookup: getProductLookup,
    sprayArray: getSprayArray,
    sprayLocationArray: getSprayLocationArray,
    sprayLogArray: getSprayLogArray,
    token: getToken,
    unitLookup: getUnitLookup,
  }),
  {},
)(SprayLogList);

export {ConnectedSprayLogList as SprayLogList};
