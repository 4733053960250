import {Config} from "@co-common-libs/config";
import {DecimalField} from "@co-frontend-libs/components";
import {actions} from "@co-frontend-libs/redux";
import {TableCell, TableRow} from "@material-ui/core";
import React, {useCallback} from "react";
import {FormattedNumber} from "react-intl";
import {useDispatch} from "react-redux";
import {CORRECTED_COLUMN_WIDTH, COUNT_COLUMN_WIDTH, UNIT_COLUMN_WIDTH} from "./constants";

interface RouteTaskResultRowProps {
  corrected?: number | undefined;
  customerSettings: Config;
  decimalPlaces: number;
  maxDigits: number;
  priceItemName: string;
  quantity?: number | undefined;
  readonly: boolean;
  unitString: string;
  url: string;
}

export const RouteTaskResultRow = React.memo(function RouteTaskResultRow(
  props: RouteTaskResultRowProps,
): JSX.Element {
  const {corrected, decimalPlaces, maxDigits, priceItemName, quantity, readonly, unitString, url} =
    props;

  const dispatch = useDispatch();

  const handleCorrectedChange = useCallback(
    (value: number | null): void => {
      dispatch(actions.update(url, [{member: "corrected", value}]));
    },
    [dispatch, url],
  );

  return (
    <TableRow>
      <TableCell>&nbsp;&nbsp;&nbsp;&nbsp; {priceItemName}</TableCell>
      <TableCell style={{width: COUNT_COLUMN_WIDTH}}>
        {quantity != null ? (
          <FormattedNumber maximumFractionDigits={decimalPlaces} value={quantity} />
        ) : null}
      </TableCell>
      <TableCell style={{width: CORRECTED_COLUMN_WIDTH}}>
        <DecimalField
          decimalPlaces={decimalPlaces}
          disabled={readonly}
          margin="dense"
          maxDigits={maxDigits}
          value={corrected != null ? corrected : null}
          onChange={handleCorrectedChange}
        />
      </TableCell>
      <TableCell style={{width: UNIT_COLUMN_WIDTH}}>{unitString}</TableCell>
    </TableRow>
  );
});
