import {RoutePlan, RoutePlanUrl, WorkType, WorkTypeUrl} from "@co-common-libs/resources";
import {RoutePlanDialog} from "@co-frontend-libs/components";
import {AppState, getActiveRoutePlanArray, getWorkTypeLookup} from "@co-frontend-libs/redux";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";

interface RoutePlanDialogStateProps {
  routePlanArray: readonly RoutePlan[];
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
}

interface RoutePlanDialogOwnProps {
  onCancel(): void;
  onOk(url: RoutePlanUrl): void;
  open: boolean;
}

export const ConnectedRoutePlanDialog: React.ComponentType<RoutePlanDialogOwnProps> = connect<
  RoutePlanDialogStateProps,
  object,
  RoutePlanDialogOwnProps,
  AppState
>(
  createStructuredSelector<AppState, RoutePlanDialogStateProps>({
    routePlanArray: getActiveRoutePlanArray,
    workTypeLookup: getWorkTypeLookup,
  }),
  {},
)(RoutePlanDialog);
