import {PriceItem, PriceItemUrl, Unit, UnitUrl} from "@co-common-libs/resources";
import {MultiplePriceItemsDialog} from "@co-frontend-libs/components";
import {AppState, getPriceItemArray, getUnitLookup} from "@co-frontend-libs/redux";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";

interface MultiplePriceItemsDialogStateProps {
  priceItemArray: readonly PriceItem[];
  unitLookup: (url: UnitUrl) => Unit | undefined;
}

interface MultiplePriceItemsDialogOwnProps {
  onCancel(): void;
  onOk(urls: Set<PriceItemUrl>): void;
  open: boolean;
  selected?: ReadonlySet<PriceItemUrl>;
}

export const ConnectedMultiplePriceItemsDialog: React.ComponentType<MultiplePriceItemsDialogOwnProps> =
  connect<MultiplePriceItemsDialogStateProps, object, MultiplePriceItemsDialogOwnProps, AppState>(
    createStructuredSelector<AppState, MultiplePriceItemsDialogStateProps>({
      priceItemArray: getPriceItemArray,
      unitLookup: getUnitLookup,
    }),
    {},
  )(MultiplePriceItemsDialog);
