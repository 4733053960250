import {SvgIcon} from "@material-ui/core";
import {grey} from "@material-ui/core/colors";
import AlertIcon from "mdi-react/AlertIcon";
import ArchiveIcon from "mdi-react/ArchiveIcon";
import SyncAlertIcon from "mdi-react/SyncAlertIcon";
import SyncIcon from "mdi-react/SyncIcon";
import React from "react";

export interface StatusBarProps {
  backgroundColor?: string;
  iconColor?: string;
  status?: "alert" | "archive" | "sync-alert" | "sync" | undefined;
  text?: string | undefined;
  textColor?: string;
}

export const StatusBar = React.memo(function StatusBar(props: StatusBarProps): JSX.Element | null {
  const {
    backgroundColor = grey[900],
    iconColor = grey[100],
    status,
    text,
    textColor = grey[100],
  } = props;
  const style: React.CSSProperties = {
    backgroundColor,
    bottom: 0,
    color: textColor,
    fontSize: "small",
    left: 0,
    paddingLeft: "1ex",
    paddingRight: "1ex",
    position: "fixed",
    right: 0,
    textAlign: "right",
    width: "100%",
    zIndex: 2000,
  };
  let left;
  let right;
  if (text) {
    left = <span style={{float: "left"}}>{text}</span>;
  }
  if (status) {
    const iconMap = {
      alert: AlertIcon,
      archive: ArchiveIcon,
      sync: SyncIcon,
      "sync-alert": SyncAlertIcon,
    };
    const Icon = iconMap[status];
    const iconClassName = status === "sync" ? "rotate" : undefined;
    right = (
      <SvgIcon className={iconClassName || ""} style={{height: 13, marginTop: 3, width: 13}}>
        <Icon color={iconColor} />
      </SvgIcon>
    );
  }
  if (left || right) {
    return (
      <div style={style}>
        {left}
        {right}
      </div>
    );
  } else {
    return null;
  }
});
