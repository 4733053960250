import {getFrontendSentry} from "@co-frontend-libs/utils";
import {Checkbox, TextField} from "@material-ui/core";
import React, {useCallback} from "react";

export function BackendConfigurationInputElement({
  name,
  onChange,
  type,
  value,
}: {
  name: string;
  onChange: (name: string, value: any) => void;
  type: string;
  value: any;
}): JSX.Element | null {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.type === "checkbox" ? event.target.checked : event.target.value;
      const parsedValue = event.target.type === "number" ? Number(newValue) : newValue;
      onChange(name, parsedValue);
    },
    [onChange, name],
  );

  if (type === "string") {
    return (
      <TextField fullWidth property={name} type="text" value={value} onChange={handleChange} />
    );
  }
  if (type === "number") {
    return (
      <TextField fullWidth property={name} type="number" value={value} onChange={handleChange} />
    );
  }
  if (type === "boolean") {
    return <Checkbox checked={value} property={name} onChange={handleChange} />;
  }

  const sentry = getFrontendSentry();
  sentry.captureMessage(`Unknown backend setting type: ${type}`);

  return null;
}
