import {Task, urlToId} from "@co-common-libs/resources";
import {AwaitingBackendRequest, AwaitingBackendRequestProps} from "@co-frontend-libs/components";
import {sendTasksUrl} from "api-endpoint-urls";
import React, {useMemo} from "react";
import {useIntl} from "react-intl";

export interface ExecuteCreateSalesLinesForTaskProps
  extends Pick<AwaitingBackendRequestProps, "execute" | "onErrorDialogDismiss" | "onSuccess"> {
  tasks: readonly Pick<Task, "url">[];
}

export function ExecuteSendTask(props: ExecuteCreateSalesLinesForTaskProps): React.JSX.Element {
  const {tasks} = props;

  const intl = useIntl();

  // TODO(mr): should get errorTitle and loadingTitle from props
  return (
    <AwaitingBackendRequest
      apiUrl={sendTasksUrl}
      data={useMemo(
        () => ({
          taskIds: tasks.map(({url}) => url).map(urlToId),
        }),
        [tasks],
      )}
      errorTitle={intl.formatMessage({defaultMessage: "Godkendelse fejlede"})}
      loadingTitle={intl.formatMessage({defaultMessage: "Godkender opgave"})}
      method="POST"
      {...props}
    />
  );
}
