import {LocationType, LocationTypeUrl} from "@co-common-libs/resources";
import {memoizeForceReuse} from "@co-frontend-libs/utils";
import React, {useMemo} from "react";
import {FormattedMessage, defineMessages, useIntl} from "react-intl";
import {
  EntryData,
  GenericMultiSelectionSearchDialog,
  GenericSingleSelectionSearchDialog,
} from "../search-dialog";

const messages = defineMessages({
  search: {
    defaultMessage: "Søg stedtyper",
    id: "location-type.dialog.search-location-types",
  },
});

function computeBaseChoices(
  locationTypeArray: readonly LocationType[],
  showFieldType: boolean,
  defaultFieldType: string,
): readonly EntryData<LocationTypeUrl>[] {
  const data: EntryData<LocationTypeUrl>[] = [];
  locationTypeArray.forEach((instance) => {
    if (!showFieldType && instance.identifier === defaultFieldType) {
      return;
    }
    const text = instance.name || instance.identifier;
    const entry: EntryData<LocationTypeUrl> = {
      category: "standard",
      exactMatchValue: text,
      identifier: instance.url,
      primaryText: text,
      searchFields: [{label: "Navn", priority: 5, text}],
    };
    data.push(entry);
  });
  return data;
}

interface LocationTypeDialogProps {
  defaultFieldType: string;
  locationTypeArray: readonly LocationType[];
  onCancel(): void;
  onOk(url: LocationTypeUrl | null): void;
  open: boolean;
  showFieldType: boolean;
}

export function LocationTypeDialog(props: LocationTypeDialogProps): JSX.Element {
  const {defaultFieldType, locationTypeArray, onCancel, onOk, open, showFieldType} = props;
  const [doComputeBaseChoices, reuseBaseChoices] = useMemo(
    () => memoizeForceReuse(computeBaseChoices, []),
    [],
  );
  const getBaseChoices = open ? doComputeBaseChoices : reuseBaseChoices;
  const data = getBaseChoices(locationTypeArray, showFieldType, defaultFieldType);
  const {formatMessage} = useIntl();

  return (
    <GenericSingleSelectionSearchDialog<LocationTypeUrl>
      data={data}
      mobilePrimaryLines={1}
      mobileSearchPrimaryLines={1}
      mobileSearchSecondaryLines={1}
      mobileSecondaryLines={1}
      open={open}
      searchTitle={formatMessage(messages.search)}
      title={
        <FormattedMessage
          defaultMessage="Vælg stedtype"
          id="location-type.dialog.select-location-type"
        />
      }
      onCancel={onCancel}
      onOk={onOk}
    />
  );
}

interface MultipleLocationTypeDialogProps {
  defaultFieldType: string;
  includeSelectAll?: boolean;
  locationTypeArray: readonly LocationType[];
  onCancel(): void;
  onOk(urls: ReadonlySet<LocationTypeUrl>): void;
  open: boolean;
  selected?: ReadonlySet<LocationTypeUrl>;
  showFieldType: boolean;
}

export function MultipleLocationTypeDialog(props: MultipleLocationTypeDialogProps): JSX.Element {
  const {
    defaultFieldType,
    includeSelectAll,
    locationTypeArray,
    onCancel,
    onOk,
    open,
    selected,
    showFieldType,
  } = props;
  const {formatMessage} = useIntl();
  const [doComputeBaseChoices, reuseBaseChoices] = useMemo(
    () => memoizeForceReuse(computeBaseChoices, []),
    [],
  );
  const getBaseChoices = open ? doComputeBaseChoices : reuseBaseChoices;
  const data = getBaseChoices(locationTypeArray, showFieldType, defaultFieldType);

  return (
    <GenericMultiSelectionSearchDialog<LocationTypeUrl>
      data={data}
      includeSelectAll={includeSelectAll}
      mobilePrimaryLines={1}
      mobileSearchPrimaryLines={1}
      mobileSearchSecondaryLines={1}
      mobileSecondaryLines={1}
      open={open}
      searchTitle={formatMessage(messages.search)}
      selected={selected}
      title={
        <FormattedMessage
          defaultMessage="Vælg stedtyper"
          id="location-type.dialog.select-location-types"
        />
      }
      onCancel={onCancel}
      onOk={onOk}
    />
  );
}
