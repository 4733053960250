import {ResourceName, resourceNameFor, urlToId} from "@co-common-libs/resources";
import {Query} from "@co-frontend-libs/db-resources";
import {GenericInstanceRecord, ResourceInstanceRecords} from "../types";
import {
  buildCheckFunction,
  buildRelationCheckMappings,
  buildResourceCheckMapping,
  entriesForEach,
  partialEntriesForEach,
  partialValuesForEach,
} from "../utils";

export function computeInstancesToFetchFromAddedQueries(
  allData: ResourceInstanceRecords,
  addedQueries: readonly Query[],
  forceReload: boolean,
): {
  toFetchByID: Partial<{
    [N in ResourceName]: Set<string>;
  }>;
  toFetchByRelation: Partial<{
    [N in ResourceName]: Partial<{
      [memberName: string]: Set<string>;
    }>;
  }>;
} {
  const toFetchByID: Partial<{
    [N in ResourceName]: Set<string>;
  }> = {};
  const toFetchByRelation: Partial<{
    [N in ResourceName]: Partial<{
      [memberName: string]: Set<string>;
    }>;
  }> = {};
  const newResourceCheckMapping = buildResourceCheckMapping(addedQueries);
  const {fkRules, reverseFkRules} = buildRelationCheckMappings(newResourceCheckMapping);
  entriesForEach(allData, (resourceName, resourceInstances) => {
    const resourceFkRules = fkRules[resourceName];
    const resourceReverseFkRules = reverseFkRules[resourceName];
    if (resourceFkRules) {
      resourceFkRules.forEach(({checkedResourceName, memberName, targetCheck}) => {
        const checkFunction = buildCheckFunction(targetCheck, allData);
        partialEntriesForEach(resourceInstances as GenericInstanceRecord, (url, instance) => {
          if (!checkFunction(instance)) {
            // does not fulfill criteria
            return;
          }
          const id = urlToId(url);
          const resourceToFetchByRelation = toFetchByRelation[checkedResourceName];
          if (resourceToFetchByRelation) {
            const resourceMemberToFetchByRelation = resourceToFetchByRelation[memberName];
            if (resourceMemberToFetchByRelation) {
              resourceMemberToFetchByRelation.add(id);
            } else {
              resourceToFetchByRelation[memberName] = new Set([id]);
            }
          } else {
            toFetchByRelation[checkedResourceName] = {
              [memberName]: new Set([id]),
            };
          }
        });
      });
    }
    if (resourceReverseFkRules) {
      resourceReverseFkRules.forEach(({checkedResourceName, memberName, sourceCheck}) => {
        const checkFunction = buildCheckFunction(sourceCheck, allData);
        partialValuesForEach(resourceInstances as GenericInstanceRecord, (instance) => {
          const memberValue = (instance as any)[memberName] as string | undefined;
          if (typeof memberValue !== "string") {
            // no FK
            return;
          }
          const fkTargetResourceName = resourceNameFor(memberValue);
          // in a sensible configuration, types match
          console.assert(checkedResourceName === fkTargetResourceName);
          if (allData[fkTargetResourceName][memberValue] && !forceReload) {
            // target already present, *and* we let existing instances
            // fulfill request
            return;
          }
          if (!checkFunction(instance)) {
            // does not fulfill criteria to follow FK
            return;
          }
          const id = urlToId(memberValue);
          const resourceToFetchByID = toFetchByID[fkTargetResourceName];
          if (resourceToFetchByID) {
            resourceToFetchByID.add(id);
          } else {
            toFetchByID[fkTargetResourceName] = new Set([id]);
          }
        });
      });
    }
  });
  return {toFetchByID, toFetchByRelation};
}
