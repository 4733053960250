import {ImportColumnConfiguration} from "../types";

export const blankColumnConfiguration: ImportColumnConfiguration = {
  updateExisting: false,
  valueType: "none",
} as const;

export function getColumnConfiguration(
  importColumnConfigurations: ReadonlyMap<string, ImportColumnConfiguration>,
  name: string,
): ImportColumnConfiguration {
  return importColumnConfigurations.get(name) ?? blankColumnConfiguration;
}
