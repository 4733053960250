import {Location, LocationUrl, Product, Task} from "@co-common-libs/resources";
import {getLocationProductNonZeroCounts} from "@co-common-libs/resources-utils";
import {notNull, notUndefined} from "@co-common-libs/utils";
import {
  getCurrentRole,
  getCustomerArray,
  getCustomerLookup,
  getCustomerSettings,
  getLocationArray,
  getLocationStorageAdjustmentArray,
  getLocationStorageChangeArray,
  getLocationStorageStatusArray,
  getLocationTypeArray,
  getLocationTypeLookup,
  getOrderLookup,
  getPriceGroupLookup,
  getProductLookup,
  getTaskArray,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {CircularProgress} from "@material-ui/core";
import {GoogleMap, computeFieldMatchesData, useDeviceConfig, useLoadGoogleMaps} from "app-utils";
import _ from "lodash";
import HomeMapMarkerIcon from "mdi-react/HomeMapMarkerIcon";
import LayersOutlineIcon from "mdi-react/LayersOutlineIcon";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {defineMessages, useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {isLocationWithGeoJson} from "../field-polygons";
import {MapFilterDialog} from "../map-filter-dialog";
import {ActiveLocations} from "./active-locations";
import {ActiveTaskMarkers} from "./active-task-markers";
import {CurrentLocationButton} from "./current-location-button";
import {CurrentLocationMarker} from "./current-location-marker";
import {Fields} from "./fields";
import {HomeMarker} from "./home-marker";
import {LocationMarkersWithMenu} from "./location-markers-with-menu";
import {MapButton} from "./map-button";

const messages = defineMessages({
  employeeMarkers: {
    defaultMessage: "Medarbejdere",
    id: "geolocation-map.marker-type.employees",
  },
});

const EMPLOYEES_MARKER_TYPE = "employees";
const TASKS_MARKER_TYPE = "tasks";

type GeolocationMapState = {
  lat: number;
  lng: number;
  zoom: number;
};

const mapContainerStyle: React.CSSProperties = {height: "100%", width: "100%"};

const mapOptions: google.maps.MapOptions = {
  clickableIcons: false,
  mapTypeControl: true,
  mapTypeControlOptions: {
    mapTypeIds: ["hybrid", "roadmap", "satellite"],
  },
  streetViewControl: true,
  styles: [
    {featureType: "poi", stylers: [{visibility: "off"}]},
    {featureType: "transit", stylers: [{visibility: "off"}]},
  ],
};

function addToSetMap<K, V>(map: Map<K, Set<V>>, key: K, extraValue: V): void {
  const existing = map.get(key);
  if (existing) {
    existing.add(extraValue);
  } else {
    map.set(key, new Set([extraValue]));
  }
}

function getLocationActiveTasksMap(
  taskArray: readonly Task[],
): ReadonlyMap<LocationUrl, ReadonlySet<Task>> {
  const locationTasksMap = new Map<LocationUrl, Set<Task>>();
  for (const task of taskArray) {
    if (task.completed) {
      continue;
    }
    if (task.relatedWorkplace) {
      addToSetMap(locationTasksMap, task.relatedWorkplace, task);
    }
    if (task.relatedPickupLocation) {
      addToSetMap(locationTasksMap, task.relatedPickupLocation, task);
    }
    task.fielduseSet.forEach((fieldUse) => {
      addToSetMap(locationTasksMap, fieldUse.relatedField, task);
    });
    if (task.reportingLocations) {
      for (const entry of Object.values(task.reportingLocations)) {
        if (entry.location) {
          addToSetMap(locationTasksMap, entry.location, task);
        }
      }
    }
  }
  return locationTasksMap;
}

interface GeolocationMapProps {
  searchString?: string;
}

export function GeolocationMap(props: GeolocationMapProps): JSX.Element {
  const {searchString} = props;
  const intl = useIntl();
  const customerSettings = useSelector(getCustomerSettings);
  const locationTypeArray = useSelector(getLocationTypeArray);
  const locationArray = useSelector(getLocationArray);
  const locationTypeLookup = useSelector(getLocationTypeLookup);
  const customerLookup = useSelector(getCustomerLookup);
  const productLookup = useSelector(getProductLookup);

  const [geolocationMapState, setGeolocationMapState, resetGeolocationMapState] =
    useDeviceConfig<GeolocationMapState>("geolocationMap");
  const [filter, setFilter] = useDeviceConfig<readonly string[]>("geolocationMapFilter");
  const [mapTypeId, setMapTypeId] = useDeviceConfig<string>("geolocationMapTypeId", "roadmap");

  const {lat, lng, zoom} = geolocationMapState || {};
  const homeLatitude = customerSettings.geolocation.homeMarkerLatitude;
  const homeLongitude = customerSettings.geolocation.homeMarkerLongitude;
  const centerLatitude = lat ?? customerSettings.geolocation.initialPositionLatitude;
  const centerLongitude = lng ?? customerSettings.geolocation.initialPositionLongitude;
  const mapZoom = zoom ?? customerSettings.geolocation.initialZoom;
  const fieldLocationType = customerSettings.fieldDefaultLocationType || "field";
  const {showEmployeesAndTasksLayersToMachineOperators} = customerSettings;
  const currentRole = useSelector(getCurrentRole);
  const userIsManager = !!currentRole && currentRole.manager;
  const showEmployeesAndTasksLayers =
    showEmployeesAndTasksLayersToMachineOperators || userIsManager;
  const selectedMarkerTypes = useMemo(
    () =>
      filter ||
      [
        showEmployeesAndTasksLayers ? EMPLOYEES_MARKER_TYPE : null,
        customerSettings.showFieldsOnGeolocationMap ? fieldLocationType : null,
      ].filter(notNull),
    [
      showEmployeesAndTasksLayers,
      customerSettings.showFieldsOnGeolocationMap,
      fieldLocationType,
      filter,
    ],
  );

  const mapStateIsInitial =
    centerLatitude === customerSettings.geolocation.initialPositionLatitude &&
    centerLongitude === customerSettings.geolocation.initialPositionLongitude &&
    mapZoom === customerSettings.geolocation.initialZoom;

  const {isLoaded /* loadError */} = useLoadGoogleMaps();

  const usedLocationTypeURLs = useMemo(() => {
    const result = new Set<string>();
    locationArray.forEach((location) => {
      if (location.locationType) {
        result.add(location.locationType);
      }
    });
    return result;
  }, [locationArray]);

  const usedLocationTypes = useMemo(
    () => locationTypeArray.filter((locationType) => usedLocationTypeURLs.has(locationType.url)),
    [locationTypeArray, usedLocationTypeURLs],
  );

  const markerTypes: readonly {
    readonly label: string;
    readonly value: string;
  }[] = useMemo(() => {
    const result: {
      readonly label: string;
      readonly value: string;
    }[] = showEmployeesAndTasksLayers
      ? [
          {
            label: intl.formatMessage(messages.employeeMarkers),
            value: EMPLOYEES_MARKER_TYPE,
          },
          {
            label: intl.formatMessage({defaultMessage: "Opgaver"}),
            value: TASKS_MARKER_TYPE,
          },
        ]
      : [];
    usedLocationTypes.forEach((locationType) => {
      result.push({
        label: locationType.name || locationType.identifier,
        value: locationType.identifier,
      });
    });
    return result;
  }, [showEmployeesAndTasksLayers, intl, usedLocationTypes]);

  const [mapFilterDialogOpen, setMapFilterDialogOpen] = useState<boolean>(false);

  const [mapInstance, setMapInstance] = useState<google.maps.Map | null>(null);

  const saveWait = 500;
  const debouncedSaveConfig = useMemo(
    () => _.debounce(setGeolocationMapState, saveWait),
    [setGeolocationMapState],
  );

  const handleResetMapClick = useCallback((): void => {
    debouncedSaveConfig.cancel();
    resetGeolocationMapState();

    if (!mapInstance) {
      return;
    }
    const {initialPositionLatitude, initialPositionLongitude, initialZoom} =
      customerSettings.geolocation;
    mapInstance.setCenter({
      lat: initialPositionLatitude,
      lng: initialPositionLongitude,
    });
    mapInstance.setZoom(initialZoom);
  }, [customerSettings.geolocation, debouncedSaveConfig, mapInstance, resetGeolocationMapState]);

  const handleMapFilterDialogOk = useCallback(
    (selected: readonly string[]): void => {
      setMapFilterDialogOpen(false);
      setFilter(selected);
    },
    [setFilter],
  );

  const handleMapFilterClick = useCallback((): void => {
    setMapFilterDialogOpen(true);
  }, []);

  const handleMapFilterDialogCancel = useCallback((): void => {
    setMapFilterDialogOpen(false);
  }, []);

  const handleMapChanged = useCallback((): void => {
    if (mapInstance) {
      const latLng = mapInstance.getCenter();
      const zoomLevel = mapInstance.getZoom();
      if (latLng && zoomLevel) {
        debouncedSaveConfig({
          lat: latLng.lat(),
          lng: latLng.lng(),
          zoom: zoomLevel,
        });
      }
    }
  }, [debouncedSaveConfig, mapInstance]);

  const handleMapTypeIdChanged = useCallback(() => {
    const id = mapInstance?.getMapTypeId();
    if (id) {
      setMapTypeId(id);
    }
  }, [mapInstance, setMapTypeId]);

  const handleLoad = useCallback(
    (map: google.maps.Map) => {
      map.setCenter({lat: centerLatitude, lng: centerLongitude});
      map.setZoom(mapZoom);
      map.setMapTypeId(mapTypeId);
      setMapInstance(map);
    },
    [centerLatitude, centerLongitude, mapTypeId, mapZoom],
  );

  useEffect(() => {
    if (mapInstance) {
      const centerChangedListener = mapInstance.addListener("center_changed", handleMapChanged);
      const zoomChangedListener = mapInstance.addListener("zoom_changed", handleMapChanged);
      return () => {
        centerChangedListener.remove();
        zoomChangedListener.remove();
      };
    } else {
      return undefined;
    }
  }, [handleMapChanged, mapInstance]);

  useEffect(() => {
    if (mapInstance) {
      const listeren = mapInstance.addListener("maptypeid_changed", handleMapTypeIdChanged);
      return () => {
        listeren.remove();
      };
    } else {
      return undefined;
    }
  }, [handleMapTypeIdChanged, mapInstance]);

  const locationStorageStatusArray = useSelector(getLocationStorageStatusArray);
  const locationStorageChangeArray = useSelector(getLocationStorageChangeArray);
  const locationStorageAdjustmentArray = useSelector(getLocationStorageAdjustmentArray);

  const locationProductCounts = useMemo(
    () =>
      getLocationProductNonZeroCounts(
        locationStorageStatusArray,
        locationStorageAdjustmentArray,
        locationStorageChangeArray,
      ),
    [locationStorageAdjustmentArray, locationStorageChangeArray, locationStorageStatusArray],
  );

  const taskArray = useSelector(getTaskArray);
  const workTypeLookup = useSelector(getWorkTypeLookup);
  const priceGroupLookup = useSelector(getPriceGroupLookup);
  const orderLookup = useSelector(getOrderLookup);
  const locationTasksMap = useMemo(
    () =>
      selectedMarkerTypes.includes(TASKS_MARKER_TYPE)
        ? getLocationActiveTasksMap(taskArray)
        : new Map<LocationUrl, ReadonlySet<Task>>(),
    [selectedMarkerTypes, taskArray],
  );

  const locationTaskUrlsMap = useMemo(
    () =>
      new Map(
        Array.from(locationTasksMap).map(([locationUrl, tasks]) => [
          locationUrl,
          new Set(Array.from(tasks).map(({url}) => url)),
        ]),
      ),
    [locationTasksMap],
  );

  const customerArray = useSelector(getCustomerArray);

  const activeCustomerUrls = useMemo(() => {
    const result = new Set<string>();
    customerArray.forEach((customer) => {
      if (customer.active) {
        result.add(customer.url);
      }
    });
    return result;
  }, [customerArray]);

  const activeLocationArray = useMemo(
    () =>
      locationArray.filter(
        (location) =>
          locationTasksMap.has(location.url) ||
          (location.active && (!location.customer || activeCustomerUrls.has(location.customer))),
      ),
    [activeCustomerUrls, locationArray, locationTasksMap],
  );

  const trimmedSearchString = searchString?.trim();

  const getLocationProducts = useCallback(
    (location: Location): readonly Product[] => {
      const productCounts = locationProductCounts.get(location.url);
      if (!productCounts) {
        return [];
      }
      const locationTypeUrl = location.locationType;
      if (!locationTypeUrl) {
        return [];
      }
      const locationType = locationTypeLookup(locationTypeUrl);
      if (!locationType) {
        return [];
      }
      if (locationType.products?.length) {
        const locationTypeProductURLs = locationType.products;
        return Array.from(productCounts.keys())
          .filter((productUrl) => locationTypeProductURLs.includes(productUrl))
          .map(productLookup)
          .filter(notUndefined);
      } else {
        return [];
      }
    },
    [locationProductCounts, locationTypeLookup, productLookup],
  );

  const getLocationActiveTasks = useCallback(
    (location: Location): readonly Task[] => {
      const taskSet = locationTasksMap.get(location.url);
      if (taskSet) {
        return Array.from(taskSet);
      } else {
        return [];
      }
    },
    [locationTasksMap],
  );

  const searchFilteredLocationArray = useMemo(() => {
    if (trimmedSearchString) {
      const fieldMatchesData = computeFieldMatchesData(
        activeLocationArray,
        trimmedSearchString,
        intl,
        {
          customerLookup,
          getLocationActiveTasks,
          getLocationProducts,
          orderLookup,
          priceGroupLookup,
          workTypeLookup,
        },
        "AND",
      );
      return fieldMatchesData.map(({field}) => field);
    }
    return activeLocationArray;
  }, [
    trimmedSearchString,
    activeLocationArray,
    intl,
    customerLookup,
    getLocationActiveTasks,
    getLocationProducts,
    orderLookup,
    priceGroupLookup,
    workTypeLookup,
  ]);

  const selectedLocationTypeIDs = useMemo(
    () =>
      new Set(
        usedLocationTypes
          .filter((locationType) => selectedMarkerTypes.includes(locationType.identifier))
          .map((locationType) => locationType.url),
      ),
    [selectedMarkerTypes, usedLocationTypes],
  );

  const bounds = useMemo(() => {
    if (isLoaded && searchString) {
      const latLngBounds = new google.maps.LatLngBounds();
      searchFilteredLocationArray.forEach((location) => {
        if (!location.locationType || !selectedLocationTypeIDs.has(location.locationType)) {
          return;
        }
        const bbox = location.geojson?.geometry?.bbox;
        if (bbox) {
          const [x1, y1, x2, y2] = bbox;
          latLngBounds.extend({lat: y1, lng: x1});
          latLngBounds.extend({lat: y2, lng: x2});
        } else if (location.latitude != null && location.longitude != null) {
          latLngBounds.extend({
            lat: location.latitude,
            lng: location.longitude,
          });
        }
      });
      if (!latLngBounds.isEmpty()) {
        // As string to have equality check for triggering useEffect behave.
        return JSON.stringify(latLngBounds);
      }
    }
    return null;
  }, [isLoaded, searchFilteredLocationArray, searchString, selectedLocationTypeIDs]);

  useEffect(() => {
    if (isLoaded && mapInstance && bounds) {
      mapInstance.fitBounds(JSON.parse(bounds));
    }
  }, [bounds, isLoaded, mapInstance]);

  const [activeLocations, notActiveLocations] = useMemo(
    () =>
      locationTasksMap.size
        ? _.partition(searchFilteredLocationArray, (location) => locationTasksMap.has(location.url))
        : [[] as readonly Location[], searchFilteredLocationArray],
    [locationTasksMap, searchFilteredLocationArray],
  );

  const fields = useMemo(
    () => notActiveLocations.filter(isLocationWithGeoJson),
    [notActiveLocations],
  );

  if (isLoaded) {
    return (
      <>
        <MapFilterDialog
          markerTypes={markerTypes}
          open={mapFilterDialogOpen}
          selected={selectedMarkerTypes}
          onCancel={handleMapFilterDialogCancel}
          onOk={handleMapFilterDialogOk}
        />
        <GoogleMap
          id="map"
          mapContainerStyle={mapContainerStyle}
          options={mapOptions}
          onLoad={handleLoad}
        >
          {mapInstance ? (
            <>
              <div style={{position: "absolute", right: 0}}>
                <MapButton variant="contained" onClick={handleMapFilterClick}>
                  <LayersOutlineIcon />
                </MapButton>
                <CurrentLocationButton googleMap={mapInstance} />

                {geolocationMapState && !mapStateIsInitial ? (
                  <MapButton variant="contained" onClick={handleResetMapClick}>
                    <HomeMapMarkerIcon />
                  </MapButton>
                ) : null}
              </div>
              <HomeMarker lat={homeLatitude} lng={homeLongitude} map={mapInstance} />
              {showEmployeesAndTasksLayers &&
              selectedMarkerTypes.includes(EMPLOYEES_MARKER_TYPE) ? (
                <ActiveTaskMarkers map={mapInstance} />
              ) : null}
              <CurrentLocationMarker map={mapInstance} />

              <LocationMarkersWithMenu
                locationArray={notActiveLocations}
                locationProductCounts={locationProductCounts}
                map={mapInstance}
                selectedMarkerTypes={selectedMarkerTypes}
                usedLocationTypes={usedLocationTypes}
              />
              {selectedMarkerTypes.includes(fieldLocationType) ? (
                <Fields fields={fields} map={mapInstance} />
              ) : undefined}
              {showEmployeesAndTasksLayers ? (
                <ActiveLocations
                  locationProductCounts={locationProductCounts}
                  locations={activeLocations}
                  locationTaskUrlsMap={locationTaskUrlsMap}
                  locationTypes={usedLocationTypes}
                  map={mapInstance}
                  markerTypes={markerTypes.map(({value}) => value)}
                />
              ) : null}
            </>
          ) : null}
        </GoogleMap>
      </>
    );
  } else {
    return (
      <div style={{marginTop: 16, textAlign: "center"}}>
        <CircularProgress />
      </div>
    );
  }
}
