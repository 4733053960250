import {Draft} from "@reduxjs/toolkit";
import {clearCommitErrorTimestamps} from "../actions";
import {ResourcesState} from "../types";

export function handleClearCommitErrorTimestamps(
  state: Draft<ResourcesState>,
  _action: ReturnType<typeof clearCommitErrorTimestamps>,
): void {
  state.commitQueue.forEach((entry) => {
    if (entry.errorTimestamp) {
      entry.errorTimestamp = null;
    }
  });
}
