import {CsvExportSpecification} from "@co-common-libs/csv-export-specifications";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import _ from "lodash";
import React, {ChangeEvent, useCallback} from "react";
import {useIntl} from "react-intl";

export function ExportSelector(props: {
  availableExportSpecifications: readonly CsvExportSpecification[];
  disabled: boolean;
  onSelectorChange: (value: string) => void;
  selected: string | undefined;
}): JSX.Element {
  const {availableExportSpecifications, disabled, onSelectorChange, selected} = props;

  const intl = useIntl();

  const handleChange = useCallback(
    ({target: {value}}: ChangeEvent<{value: unknown}>): void => {
      if (typeof value === "string") {
        onSelectorChange(value);
      }
    },
    [onSelectorChange],
  );

  const menuItems = availableExportSpecifications.map((exportSpecification) => (
    <MenuItem key={exportSpecification.name} value={exportSpecification.name}>
      {exportSpecification.label}
    </MenuItem>
  ));

  const label = intl.formatMessage({defaultMessage: "Vælg kartotek"});
  return (
    <FormControl fullWidth margin="dense" variant="outlined">
      <InputLabel>{label}</InputLabel>
      <Select disabled={disabled} label={label} value={selected || ""} onChange={handleChange}>
        {menuItems}
      </Select>
    </FormControl>
  );
}
