import {PatchOperation, ProductUrl, Task, User} from "@co-common-libs/resources";
import {dateToString} from "@co-common-libs/utils";
import {
  actions,
  getContactLookup,
  getCurrentRole,
  getCustomerLookup,
  getCustomerSettings,
  getDeliveryArray,
  getDeliveryLocationArray,
  getLocationArray,
  getLocationLookup,
  getLocationStorageAdjustmentArray,
  getLocationStorageChangeArray,
  getLocationStorageStatusArray,
  getLocationTypeLookup,
  getMachineArray,
  getMachineLookup,
  getOrderLookup,
  getPickupArray,
  getPickupLocationArray,
  getPriceGroupLookup,
  getPriceItemArray,
  getPriceItemLookup,
  getProductGroupLookup,
  getProductLookup,
  getProjectArray,
  getProjectLookup,
  getReportingSpecificationLookup,
  getRouteTaskActivityOptionLookup,
  getRouteTaskArray,
  getRouteTaskLookup,
  getRouteTaskResultArray,
  getTaskFileArray,
  getTaskLookup,
  getTaskPhotoArray,
  getTimerArray,
  getTimerLookup,
  getTimerStartArray,
  getTransportLogArray,
  getUnitLookup,
  getUserLookup,
  getWorkTypeLookup,
  getWorkshopChecklistArray,
  getWorkshopChecklistItemArray,
} from "@co-frontend-libs/redux";
import {ValidationDialog} from "app-components";
import {
  ErrorEntry,
  InlinedTask,
  getBreakTimer,
  getErrors,
  getGenericPrimaryTimer,
  getInternalTaskErrors,
  getReadonlyProductsFromTask,
  getTaskSecondaryTimerList,
  getWarnings,
  inlineTaskData,
  inlineTransportLogData,
  useBookkeepingTasks,
  willTaskBeRecorded,
} from "app-utils";
import _ from "lodash";
import React, {useCallback} from "react";
// Allowed for existing code.
// eslint-disable-next-line deprecate/import
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

interface UserDailyReportValidationDialogProps {
  date: Date;
  onClose: () => void;
  open: boolean;
  user: User;
}

export function UserDailyReportValidationDialog(
  props: UserDailyReportValidationDialogProps,
): JSX.Element {
  const contactLookup = useSelector(getContactLookup);
  const currentRole = useSelector(getCurrentRole);
  const customerLookup = useSelector(getCustomerLookup);
  const customerSettings = useSelector(getCustomerSettings);
  const deliveryArray = useSelector(getDeliveryArray);
  const deliveryLocationArray = useSelector(getDeliveryLocationArray);
  const locationArray = useSelector(getLocationArray);
  const locationLookup = useSelector(getLocationLookup);
  const locationStorageAdjustmentArray = useSelector(getLocationStorageAdjustmentArray);
  const locationStorageChangeArray = useSelector(getLocationStorageChangeArray);
  const locationStorageStatusArray = useSelector(getLocationStorageStatusArray);
  const locationTypeLookup = useSelector(getLocationTypeLookup);
  const machineArray = useSelector(getMachineArray);
  const machineLookup = useSelector(getMachineLookup);
  const orderLookup = useSelector(getOrderLookup);
  const pickupArray = useSelector(getPickupArray);
  const pickupLocationArray = useSelector(getPickupLocationArray);
  const priceGroupLookup = useSelector(getPriceGroupLookup);
  const priceItemArray = useSelector(getPriceItemArray);
  const priceItemLookup = useSelector(getPriceItemLookup);
  const productLookup = useSelector(getProductLookup);
  const productGroupLookup = useSelector(getProductGroupLookup);
  const projectArray = useSelector(getProjectArray);
  const projectLookup = useSelector(getProjectLookup);
  const reportingSpecificationLookup = useSelector(getReportingSpecificationLookup);
  const routeTaskActivityOptionLookup = useSelector(getRouteTaskActivityOptionLookup);
  const routeTaskLookup = useSelector(getRouteTaskLookup);
  const routeTaskResultArray = useSelector(getRouteTaskResultArray);
  const routeTaskArray = useSelector(getRouteTaskArray);
  const taskFileArray = useSelector(getTaskFileArray);
  const taskLookup = useSelector(getTaskLookup);
  const taskPhotoArray = useSelector(getTaskPhotoArray);
  const timerArray = useSelector(getTimerArray);
  const timerLookup = useSelector(getTimerLookup);
  const timerStartArray = useSelector(getTimerStartArray);
  const transportLogArray = useSelector(getTransportLogArray);
  const unitLookup = useSelector(getUnitLookup);
  const userLookup = useSelector(getUserLookup);
  const workshopChecklistArray = useSelector(getWorkshopChecklistArray);
  const workshopChecklistItemArray = useSelector(getWorkshopChecklistItemArray);
  const workTypeLookup = useSelector(getWorkTypeLookup);

  const dispatch = useDispatch();
  const intl = useIntl();

  const {date, onClose, open, user} = props;

  const breakTimer = getBreakTimer(timerArray);

  const approveOrReportApproveTask = useCallback(
    (field: "reportApproved" | "validatedAndRecorded", taskList?: readonly InlinedTask[]) => {
      if (!taskList) {
        return;
      }
      taskList.forEach((task) => {
        const patch: PatchOperation<Task>[] = [{member: field, value: true}];
        const {order} = task;
        if (
          !willTaskBeRecorded(
            task,
            order || undefined,
            customerSettings,
            timerStartArray,
            breakTimer?.url,
          )
        ) {
          patch.push({member: "archivable", value: true});
        }
        dispatch(actions.update(task.url, patch));
      });
    },
    [breakTimer?.url, customerSettings, dispatch, timerStartArray],
  );

  const handleReportApprovedOk = useCallback(
    (taskList: readonly InlinedTask[]) => {
      if (customerSettings.economicSync) {
        approveOrReportApproveTask("reportApproved", taskList);
      } else {
        taskList.forEach((task) => {
          dispatch(actions.update(task.url, [{member: "reportApproved", value: true}]));
        });
      }
      onClose();
    },
    [approveOrReportApproveTask, customerSettings.economicSync, dispatch, onClose],
  );
  const handleValidatedOk = useCallback(
    (taskList?: readonly InlinedTask[]) => {
      approveOrReportApproveTask("validatedAndRecorded", taskList);
      onClose();
    },
    [approveOrReportApproveTask, onClose],
  );

  const {completedTasksStartedToday, extraTasks, someIncomplete} = useBookkeepingTasks({
    employeeUrl: user.url,
    selectedDate: dateToString(date),
  });

  const combinedTasks = (completedTasksStartedToday as Task[]).concat(extraTasks);

  const getInternalErrors = useCallback(
    (inlinedTask: InlinedTask, now: Date): readonly ErrorEntry[] => {
      const task = taskLookup(inlinedTask.url);
      if (!task) {
        return [];
      }
      return getInternalTaskErrors(
        {
          customerSettings,
          department: inlinedTask.department,
          finalIntervals: inlinedTask._intervals,
          machineOperator: task.machineOperator ? userLookup(task.machineOperator) : undefined,
          machineUseList: inlinedTask.machineuseSet,
          onlyCompletedChecks: false,
          primaryWorkType: inlinedTask.workType || undefined,
          task,
          taskPhotoArray,
          workshopChecklistAnswers: task.workshopchecklistanswerSet,
          workshopChecklistItemArray,
          workshopChecklists: workshopChecklistArray.filter((checklist) => {
            if (!checklist.draft && checklist.active) {
              const checklistMachines = checklist.machines;
              return task.machineuseSet.some((machineUse) =>
                machineUse.machine ? checklistMachines.includes(machineUse.machine) : false,
              );
            } else {
              return false;
            }
          }),
          workTypeLookup,
        },
        now,
        intl,
      );
    },
    [
      customerSettings,
      intl,
      taskLookup,
      taskPhotoArray,
      userLookup,
      workTypeLookup,
      workshopChecklistArray,
      workshopChecklistItemArray,
    ],
  );

  const now = new Date();

  let taskIssueMap:
    | Map<
        string,
        {
          errors: readonly ErrorEntry[];
          warnings: readonly ErrorEntry[];
        }
      >
    | undefined;

  let taskWithDataList: InlinedTask[] | undefined;
  if (open) {
    const taskDataLookups = {
      contactLookup,
      customerLookup,
      machineLookup,
      orderLookup,
      priceGroupLookup,
      priceItemLookup,
      productLookup,
      projectLookup,
      reportingSpecificationLookup,
      timerLookup,
      workTypeLookup,
    };
    taskWithDataList = _.sortBy(
      combinedTasks.filter(
        (task) =>
          !task.validatedAndRecorded || (customerSettings.useApproveReport && !task.reportApproved),
      ),
      (task) => task.workFromTimestamp,
    ).map((task) => inlineTaskData(task, taskDataLookups));
    const taskURLSet = new Set(combinedTasks.map((task) => task.url));
    const transportLogWithDataList = transportLogArray
      .filter((transportLog) => taskURLSet.has(transportLog.task))
      .map((transportLog) =>
        inlineTransportLogData(transportLog, {
          deliveryArray,
          deliveryLocationArray,
          pickupArray,
          pickupLocationArray,
        }),
      );
    const genericPrimaryTimer = getGenericPrimaryTimer(timerArray);

    taskIssueMap = new Map();
    const taskIssueMapNotUndefined = taskIssueMap;
    taskWithDataList.forEach((task) => {
      const productsWithLogData: Set<ProductUrl> = new Set();
      const readonlyProducts: Set<ProductUrl> = getReadonlyProductsFromTask(
        task,
        productLookup,
        unitLookup,
        reportingSpecificationLookup,
      );

      const taskURL = task.url;
      const transportLog = transportLogWithDataList.find((t) => t.task === taskURL);
      const secondaryTimers = new Set(
        getTaskSecondaryTimerList(task, customerSettings, {
          machineLookup,
          priceGroupLookup,
          timerArray,
          workTypeLookup,
        }),
      );
      const userIsManager = !!(currentRole && currentRole.manager);

      const warnings: ErrorEntry[] = getWarnings(
        {
          genericPrimaryTimer,
          locationArray,
          locationLookup,
          locationStorageAdjustmentArray,
          locationStorageChangeArray,
          locationStorageStatusArray,
          locationTypeLookup,
          machineArray,
          machineLookup,
          priceGroupLookup,
          priceItemArray,
          priceItemLookup,
          productGroupLookup,
          productLookup,
          productsWithLogData,
          projectArray,
          readonlyProducts,
          routeTaskArray,
          secondaryTimers,
          task,
          timerArray,
          transportLog,
          unitLookup,
          userIsManager,
          workTypeLookup,
        },
        customerSettings,
        intl,
      );
      const errors: readonly ErrorEntry[] = task.order
        ? getErrors(
            {
              genericPrimaryTimer,
              locationArray,
              machineArray,
              priceGroupLookup,
              priceItemArray,
              priceItemLookup,
              productGroupLookup,
              productLookup,
              productsWithLogData,
              projectArray,
              readonlyProducts,
              routeTaskActivityOptionLookup,
              routeTaskArray,
              routeTaskLookup,
              routeTaskResultArray,
              secondaryTimers,
              task,
              taskFileArray,
              taskPhotoArray,
              transportLog,
              unitLookup,
              userIsManager,
              workTypeLookup,
            },
            customerSettings,
            intl,
            false,
            task.completedAsInternal,
          )
        : getInternalErrors(task, now);
      taskIssueMapNotUndefined.set(taskURL, {errors, warnings});
    });
  }

  if (customerSettings.useApproveReport) {
    return (
      <ValidationDialog
        key="report-approved"
        open={open}
        someIncomplete={someIncomplete}
        taskIssueMap={taskIssueMap}
        taskList={taskWithDataList}
        onCancel={onClose}
        onOk={handleReportApprovedOk}
      />
    );
  } else {
    return (
      <ValidationDialog
        key="validate"
        open={open}
        someIncomplete={someIncomplete}
        taskIssueMap={taskIssueMap}
        taskList={taskWithDataList}
        onCancel={onClose}
        onOk={handleValidatedOk}
      />
    );
  }
}
