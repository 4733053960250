import {Config} from "@co-common-libs/config";
import {
  ReportingInputSpecification,
  ReportingLocations,
  ReportingLog,
} from "@co-common-libs/resources";
import {getValue} from "./get-value";

export function computeLogSums(
  customerSettings: Config,
  logData: ReportingLog,
  reportingLocations: ReportingLocations,
  summedIdentifierLabels: ReadonlyMap<string, string>,
  inputSpecificationsMap: ReadonlyMap<string, ReportingInputSpecification>,
): Map<string, number> {
  const sums = new Map<string, number>();
  for (const data of Object.values(logData)) {
    const locationID = data.location;
    const locationData = reportingLocations[locationID];
    if (!locationData) {
      continue;
    }
    const {values} = data;
    const locationValues = locationData.values;
    const valueMaps = locationValues ? [values, locationValues] : [values];
    const localGetValue = getValue.bind(null, customerSettings, valueMaps, inputSpecificationsMap);

    summedIdentifierLabels.forEach((_label: string, identifier: string) => {
      const value = localGetValue(identifier);
      if (typeof value === "number") {
        sums.set(identifier, (sums.get(identifier) || 0) + value);
      }
    });
  }
  return sums;
}
