import {createAction} from "@reduxjs/toolkit";

export const showDialog = createAction(
  "notification-dialog/showDialog",
  (title: string, content: string) => ({
    payload: {content, title},
  }),
);

export const closeDialog = createAction("notification-dialog/closeDialog");
