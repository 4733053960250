import {Config} from "@co-common-libs/config";
import {
  DaysAbsence,
  DaysAbsenceUrl,
  HoursAbsence,
  HoursAbsenceUrl,
  Role,
} from "@co-common-libs/resources";
import {dateFromDateAndTime} from "app-utils";
import ImmutableDate from "bloody-immutable-date";
import React from "react";
import {AbsenceBlock} from "./absence-block";

interface AbsenceBlockProps {
  calendarFromTimestamp: ImmutableDate;
  calendarToTimestamp: ImmutableDate;
  currentRole: Role | null;
  currentUserURL: string | null;
  customerSettings: Config;
  daysAbsenceList: readonly DaysAbsence[];
  hoursAbsenceList: readonly HoursAbsence[];
  onAbsenceClick?: ((url: DaysAbsenceUrl | HoursAbsenceUrl) => void) | undefined;
}

export const AbsenceBlocks = React.memo(function AbsenceBlocks(
  props: AbsenceBlockProps,
): JSX.Element {
  const {
    calendarFromTimestamp,
    calendarToTimestamp,
    currentRole,
    currentUserURL,
    customerSettings,
    daysAbsenceList,
    hoursAbsenceList,
    onAbsenceClick,
  } = props;

  return (
    <div>
      {daysAbsenceList.map((daysAbsence) => (
        <AbsenceBlock
          key={daysAbsence.url}
          absence={daysAbsence}
          calendarFromTimestamp={calendarFromTimestamp}
          calendarToTimestamp={calendarToTimestamp}
          currentRole={currentRole}
          currentUserURL={currentUserURL}
          customerSettings={customerSettings}
          fromTimestamp={new ImmutableDate(dateFromDateAndTime(daysAbsence.fromDate, "00:00"))}
          toTimestamp={new ImmutableDate(dateFromDateAndTime(daysAbsence.toDate, "24:00"))}
          onEdit={onAbsenceClick}
        />
      ))}
      {hoursAbsenceList.map((hoursAbsence) => (
        <AbsenceBlock
          key={hoursAbsence.url}
          absence={hoursAbsence}
          calendarFromTimestamp={calendarFromTimestamp}
          calendarToTimestamp={calendarToTimestamp}
          currentRole={currentRole}
          currentUserURL={currentUserURL}
          customerSettings={customerSettings}
          fromTimestamp={new ImmutableDate(hoursAbsence.fromTimestamp)}
          toTimestamp={new ImmutableDate(hoursAbsence.toTimestamp)}
          onEdit={onAbsenceClick}
        />
      ))}
    </div>
  );
});
