import {PriceItem, PriceItemUseWithOrder, Unit} from "@co-common-libs/resources";
import {Grid} from "@material-ui/core";
import React, {useCallback, useMemo, useState} from "react";
import {DisplayGridItemWithEntryCallbacks} from "./display-grid-item";
import {PriceItemUseNotesDialog} from "./price-item-use-notes-dialog";

interface DisplayGridProps {
  conversionRelatedValues: {
    readonly [unit: string]: number | null;
  } | null;
  onCountChange: (identifier: string, value: number | null) => void;
  onNotesChange?: ((identifier: string, value: string) => void) | undefined;
  readonly: boolean;
  readonlyPriceItems: ReadonlySet<string> | null;
  showNotes: boolean;
  showRelationConversionColumns: boolean;
  sortedPriceItemUses: readonly {
    readonly conversionUnit: Unit | null;
    readonly identifier: string;
    readonly priceItem: PriceItem;
    readonly priceItemUse: PriceItemUseWithOrder;
    readonly unit: Unit | null;
  }[];
}

export const DisplayGrid = React.memo(function DisplayGrid(props: DisplayGridProps): JSX.Element {
  const {
    conversionRelatedValues,
    onCountChange,
    onNotesChange,
    readonly,
    readonlyPriceItems,
    showNotes,
    showRelationConversionColumns,
    sortedPriceItemUses,
  } = props;

  const [detailsDialogPriceItemUseIdentifier, setDetailsDialogPriceItemUseIdentifier] = useState<
    string | null
  >(null);
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);

  const detailsDialogData = useMemo(() => {
    if (!detailsDialogPriceItemUseIdentifier) {
      return null;
    }
    const entry = sortedPriceItemUses.find(
      (priceItemUseWithData) =>
        priceItemUseWithData.identifier === detailsDialogPriceItemUseIdentifier,
    );
    if (!entry) {
      return null;
    }
    return entry;
  }, [detailsDialogPriceItemUseIdentifier, sortedPriceItemUses]);

  const handleNotesButtonClick = useCallback((identifier: string): void => {
    setDetailsDialogPriceItemUseIdentifier(identifier);
    setDetailsDialogOpen(true);
  }, []);

  const handleDetailsDialogCancel = useCallback((): void => {
    setDetailsDialogOpen(false);
  }, []);

  const handleDetailsDialogOk = useCallback(
    (changed: {notes?: string}): void => {
      if (!detailsDialogPriceItemUseIdentifier) {
        return;
      }
      const {notes} = changed;
      if (onNotesChange && notes !== undefined) {
        onNotesChange(detailsDialogPriceItemUseIdentifier, notes);
      }
      setDetailsDialogOpen(false);
    },
    [detailsDialogPriceItemUseIdentifier, onNotesChange],
  );

  const items = sortedPriceItemUses.map(
    ({conversionUnit, identifier, priceItem, priceItemUse, unit}) => (
      <DisplayGridItemWithEntryCallbacks
        key={identifier}
        conversionRelatedValues={conversionRelatedValues}
        conversionUnit={conversionUnit}
        identifier={identifier}
        notesReadonly={readonly}
        priceItem={priceItem}
        priceItemUse={priceItemUse}
        readonly={readonly || readonlyPriceItems?.has(priceItemUse.priceItem) || false}
        showNotes={showNotes}
        showRelationConversionColumns={showRelationConversionColumns}
        unit={unit}
        onCountChange={onCountChange}
        onNotesButtonClick={showNotes ? handleNotesButtonClick : null}
        onNotesChange={onNotesChange}
      />
    ),
  );
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {items}
        </Grid>
      </Grid>
      {showNotes ? (
        <PriceItemUseNotesDialog
          open={detailsDialogOpen}
          priceItem={detailsDialogData?.priceItem}
          priceItemUse={detailsDialogData?.priceItemUse}
          readonly={readonly}
          onCancel={handleDetailsDialogCancel}
          onOk={handleDetailsDialogOk}
        />
      ) : null}
    </>
  );
});
