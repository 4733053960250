import {ResponsiveDialog, TrimTextField} from "@co-frontend-libs/components";
import {DialogContent} from "@material-ui/core";
import React, {useCallback, useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";

interface RenameDialogProps {
  currentName?: string;
  onCancel: () => void;
  onOk: (name: string) => void;
  open: boolean;
}

export const RenameDialog = React.memo(function RenameDialog(
  props: RenameDialogProps,
): JSX.Element {
  const {currentName = "", onCancel, onOk, open} = props;

  const [name, setName] = useState<string>(currentName);

  useEffect(() => {
    if (open) {
      setName(currentName);
    }
  }, [currentName, open]);

  const handleOk = useCallback(() => {
    onOk(name);
  }, [name, onOk]);

  return (
    <ResponsiveDialog
      open={open}
      title={
        <FormattedMessage
          defaultMessage="Omnavngiv"
          id="customer-file-list-card.dialog-title.reanme"
        />
      }
      onCancel={onCancel}
      onOk={handleOk}
    >
      <DialogContent>
        <TrimTextField
          autoFocus
          label={
            <FormattedMessage
              defaultMessage="Navn"
              id="customer-file-list-card.label.rename-name"
            />
          }
          margin="dense"
          value={name}
          variant="outlined"
          onChange={setName}
        />
      </DialogContent>
    </ResponsiveDialog>
  );
});
