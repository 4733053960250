import {ResponsiveDialog} from "@co-frontend-libs/components";
import {Checkbox, DialogContent, FormControlLabel} from "@material-ui/core";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import React from "react";
import {IntlContext, defineMessages} from "react-intl";
import {PrivacyPolicy} from "./privacy-policy";

const messages = defineMessages({
  approve: {
    defaultMessage: "Godkend",
    id: "processor-agreement-dialog.label.approve",
  },
  termsRead: {
    defaultMessage: "Jeg har læst og er indforstået med privatlivspolitikken",
    id: "processor-agreement-dialog.label.termsRead",
  },
  title: {
    defaultMessage: "Godkend privatlivspolitikken",
    id: "processor-agreement-dialog.label.title",
  },
});

interface PrivacyPolicyDialogProps {
  onOk: () => void;
  open: boolean;
}

export class PrivacyPolicyDialog extends PureComponent<PrivacyPolicyDialogProps> {
  state = {
    confirmed: false,
  };
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  @bind
  handleTermsReadChanged(event: React.ChangeEvent<HTMLInputElement>): void {
    const {checked} = event.target;
    this.setState({confirmed: checked});
  }
  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {onOk, open} = this.props;
    const {confirmed} = this.state;

    return (
      <ResponsiveDialog
        okDisabled={!confirmed}
        okLabel={formatMessage(messages.approve)}
        open={open}
        title={formatMessage(messages.title)}
        onOk={onOk}
      >
        <DialogContent>
          <PrivacyPolicy />
          <FormControlLabel
            control={<Checkbox checked={confirmed} onChange={this.handleTermsReadChanged} />}
            label={formatMessage(messages.termsRead)}
          />
        </DialogContent>
      </ResponsiveDialog>
    );
  }
}
