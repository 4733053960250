import {ImportPreviewProductGroup} from "@co-common-libs/resources-utils";
import {ImportProductGroupPreviewDialog} from "@co-frontend-libs/components";
import {jsonFetch, translateNetworkError} from "@co-frontend-libs/utils";
import {useAsyncAbortable} from "@react-hookz/web";
import {globalConfig} from "frontend-global-config";
import React, {useCallback, useEffect} from "react";
import {useIntl} from "react-intl";

interface ImportProductGroupDialogProps {
  onCancel(): void;
  onOk(productGroup: ImportPreviewProductGroup): void;
  open: boolean;
  remoteUrlFavorites: ReadonlySet<string>;
}

export const ImportProductGroupDialog = React.memo(function ImportProductGroupDialog(
  props: ImportProductGroupDialogProps,
): React.JSX.Element {
  const {open} = props;

  const {baseURL} = globalConfig.resources;
  const [state, actions] = useAsyncAbortable<readonly ImportPreviewProductGroup[] | null>(
    (signal) =>
      jsonFetch(`${baseURL}economic/product_groups/`).then(({data}) =>
        signal.aborted ? null : data,
      ),
    null,
  );

  useEffect(() => {
    if (open) {
      actions.execute();
    } else {
      actions.reset();
    }
  }, [actions, open]);

  const handleOk = useCallback(
    (identifier: string) => {
      const selectedProductGroup = (state.result || []).find(
        (productGroup) => productGroup.identifier === identifier,
      );
      if (selectedProductGroup) {
        props.onOk(selectedProductGroup);
      }
    },
    [state.result, props],
  );

  const intl = useIntl();

  return (
    <ImportProductGroupPreviewDialog
      {...props}
      error={state.status === "error" ? translateNetworkError(state.error, intl) : undefined}
      previewProductGroups={state.result}
      onOk={handleOk}
    />
  );
});
