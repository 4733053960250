import type {
  FuelSurchargePricePercentInvoiceData,
  PriceItemUrl,
  PricePercentDefaultFuelSurchargeUse,
  PricePercentFuelSurchargeBasis,
  PricePercentFuelSurchargeSpecification,
  PricePercentFuelSurchargeSpecificationEntry,
  PricePercentMachineFuelSurchargeUse,
  PricePercentWorkTypeFuelSurchargeUse,
  Product,
  ProductUrl,
} from "@co-common-libs/resources";
import {dateFromString} from "@co-common-libs/utils";
import {IntlShape} from "@formatjs/intl";
import _ from "lodash";
import {findFuelSurchargeBasis} from "./find-fuel-surcharge-basis";
import {formatPricePercentString} from "./format-price-percent-string";

type PricePercentFuelSurchargeSpecificationPart = Pick<
  PricePercentFuelSurchargeSpecification,
  | "conversionFactor"
  | "invoiceLineProduct"
  | "invoiceLineText"
  | "name"
  | "url"
  | "zeroSurchargeInvoiceLines"
>;

type PricePercentFuelSurchargeSpecificationEntryPart = Pick<
  PricePercentFuelSurchargeSpecificationEntry,
  "fromDate" | "priceKrPerLiter" | "toDate"
>;

type PricePercentFuelSurchargeBasisPart = Pick<
  PricePercentFuelSurchargeBasis,
  | "basePriceKrPerLiter"
  | "fromDate"
  | "fuelCostSharePercent"
  | "priceKrPerLiterIncreaseThreshold"
  | "specification"
  | "truncateNegativeToZero"
>;

type PricePercentFuelSurchargeUsePart = Partial<
  Pick<PricePercentMachineFuelSurchargeUse, "customer" | "machine" | "variant">
> &
  Partial<Pick<PricePercentWorkTypeFuelSurchargeUse, "customer" | "variant" | "workType">> &
  Pick<PricePercentDefaultFuelSurchargeUse, "customer">;

type ProductPart = Pick<Product, "name">;

function getWithFuelSurchargeEntryData(
  intl: Pick<IntlShape, "formatMessage">,
  productLookup: (url: ProductUrl) => ProductPart | undefined,
  fuelSurchargeBasisArray: readonly PricePercentFuelSurchargeBasisPart[],
  taskDate: string,
  specification: PricePercentFuelSurchargeSpecificationPart,
  specificationEntry: PricePercentFuelSurchargeSpecificationEntryPart,
  use: PricePercentFuelSurchargeUsePart,
): FuelSurchargePricePercentInvoiceData[PriceItemUrl | ProductUrl] {
  const basis = findFuelSurchargeBasis(fuelSurchargeBasisArray, specification.url, taskDate);
  if (!basis) {
    throw new Error(`Basis not found for specification ${specification.url} at ${taskDate}`);
  }
  const priceIncrease = specificationEntry.priceKrPerLiter - basis.basePriceKrPerLiter;
  const priceIncreaseOverThreshold =
    priceIncrease > 0
      ? Math.max(priceIncrease - basis.priceKrPerLiterIncreaseThreshold, 0)
      : priceIncrease;
  const truncatedPriceIncreaseOverThreshold =
    basis.truncateNegativeToZero && priceIncreaseOverThreshold < 0 ? 0 : priceIncreaseOverThreshold;
  const relativePriceIncrease = truncatedPriceIncreaseOverThreshold / basis.basePriceKrPerLiter;
  const fractionDigits = 2;
  const resultingPercent = _.round(
    relativePriceIncrease * basis.fuelCostSharePercent,
    fractionDigits,
  );

  const relativeCostPriceIncrease = priceIncrease / basis.basePriceKrPerLiter;
  const costResultingPercent = relativeCostPriceIncrease * basis.fuelCostSharePercent;

  const productUrl = specification.invoiceLineProduct;
  const product = productUrl ? productLookup(productUrl) : null;
  const name = product ? product.name : specification.name;
  const text = formatPricePercentString(
    intl,
    specification.invoiceLineText,
    name,
    dateFromString(specificationEntry.fromDate) as Date,
    dateFromString(specificationEntry.toDate) as Date,
    resultingPercent,
    specificationEntry.priceKrPerLiter,
  );
  return {
    costResultingPercent,
    product: productUrl,
    resultingPercent,
    specification: {
      ..._.pick(specification, ["conversionFactor", "zeroSurchargeInvoiceLines"]),
      basis: _.pick(basis, [
        "fromDate",
        "basePriceKrPerLiter",
        "fuelCostSharePercent",
        "priceKrPerLiterIncreaseThreshold",
        "truncateNegativeToZero",
      ]),
      entry: _.pick(specificationEntry, ["fromDate", "toDate", "priceKrPerLiter"]),
    },
    text,
    useRule: {
      customer: use.customer,
      machine: use.machine || null,
      variant: use.variant || null,
      workType: use.workType || null,
    },
  };
}

function getWithoutFuelSurchargeEntryData(
  intl: Pick<IntlShape, "formatMessage">,
  use: PricePercentFuelSurchargeUsePart,
): FuelSurchargePricePercentInvoiceData[PriceItemUrl | ProductUrl] {
  const text = intl.formatMessage({
    defaultMessage: "Ingen brændstoftillæg",
  });
  return {
    costResultingPercent: 0,
    product: null,
    resultingPercent: 0,
    specification: null,
    text,
    useRule: {
      customer: use.customer,
      machine: use.machine || null,
      variant: use.variant || null,
      workType: use.workType || null,
    },
  };
}

export function formatFuelSurchargePricePercentInvoiceData(
  intl: Pick<IntlShape, "formatMessage">,
  productLookup: (url: ProductUrl) => ProductPart | undefined,
  fuelSurchargeBasisArray: readonly PricePercentFuelSurchargeBasisPart[],
  taskDate: string,
  withFuelSurcharge: ReadonlyMap<
    PriceItemUrl | ProductUrl,
    {
      readonly specification: PricePercentFuelSurchargeSpecificationPart;
      readonly specificationEntry: PricePercentFuelSurchargeSpecificationEntryPart;
      readonly use: PricePercentFuelSurchargeUsePart;
    }
  >,
  withoutFuelSurcharge: ReadonlyMap<
    PriceItemUrl | ProductUrl,
    {
      readonly use: PricePercentFuelSurchargeUsePart;
    }
  >,
): FuelSurchargePricePercentInvoiceData {
  const entries: [
    PriceItemUrl | ProductUrl,
    FuelSurchargePricePercentInvoiceData[PriceItemUrl | ProductUrl],
  ][] = [];
  withFuelSurcharge.forEach(({specification, specificationEntry, use}, key) => {
    const data = getWithFuelSurchargeEntryData(
      intl,
      productLookup,
      fuelSurchargeBasisArray,
      taskDate,
      specification,
      specificationEntry,
      use,
    );
    entries.push([key, data]);
  });
  withoutFuelSurcharge.forEach(({use}, key) => {
    const data = getWithoutFuelSurchargeEntryData(intl, use);
    entries.push([key, data]);
  });
  return Object.fromEntries(entries);
}
