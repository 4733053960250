import {FieldUse, Location} from "@co-common-libs/resources";
import {ThrottledTextField} from "@co-frontend-libs/components";
import {green} from "@material-ui/core/colors";
import useMergedRef from "@react-hook/merged-ref";
import DragHorizontalVariantIcon from "mdi-react/DragHorizontalVariantIcon";
import React, {useCallback} from "react";
import {DragSourceMonitor, DropTargetMonitor, useDrag, useDrop} from "react-dnd";
import {useIntl} from "react-intl";
import {Linkify} from "./linkify";

export interface FieldUseWithField extends FieldUse {
  field: Location;
}

interface SelectedFieldLineProps {
  extraNotes?: ReadonlyMap<string, string> | undefined;
  fieldUse: FieldUseWithField;
  onDrop: (sourceField: FieldUseWithField, targetField: FieldUseWithField) => void;
  onNotesChange: (fieldUse: FieldUseWithField) => void;
}

const SelectedFieldLine = React.memo(function SelectedFieldLine(
  props: SelectedFieldLineProps,
): JSX.Element {
  const {extraNotes, fieldUse, onDrop, onNotesChange} = props;

  const handleNotesChange = useCallback(
    (value: string): void => {
      onNotesChange({...fieldUse, notes: value});
    },
    [fieldUse, onNotesChange],
  );

  const [dropCollectedProps, drop] = useDrop({
    accept: "field",
    collect: (monitor: DropTargetMonitor) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
    }),
    drop: (item: any, _monitor: DropTargetMonitor) => {
      onDrop(item.fieldUse, fieldUse);
    },
  });
  const {canDrop, isOver} = dropCollectedProps;

  const [dragCollectedProps, drag, preview] = useDrag({
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: {
      fieldUse,
    },
    type: "field",
  });
  const {isDragging} = dragCollectedProps;

  // except for backgroundColor, copied from Material-UI <ListItem> css
  const style: React.CSSProperties = {
    backgroundColor: "white",
    position: "relative",
  };
  const dragHandleStyle: React.CSSProperties = {
    cursor: "pointer",
    display: "block",
    height: "24px",
    margin: "12px",
    position: "absolute",
    right: "4px",
    top: "0px",
    width: "24px",
  };
  if (isDragging) {
    style.opacity = 0.2;
  }
  if (isOver && canDrop) {
    style.backgroundColor = green[100];
  }

  const {formatNumber} = useIntl();
  const code = fieldUse.field.fieldNumber;
  const area = formatNumber(fieldUse.field.fieldAreaHa || 0, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
  const crop = fieldUse.field.fieldCrop;
  const {notes} = fieldUse;
  const fieldField = fieldUse.field;
  const url = fieldField && fieldField.url;
  const fieldExtraNotes = extraNotes && extraNotes.get(url);

  let extraNotesLine;
  if (fieldExtraNotes) {
    extraNotesLine = (
      <span>
        <br />
        <Linkify>{fieldExtraNotes}</Linkify>
      </span>
    );
  }

  const multiRef = useMergedRef<HTMLDivElement>(drop, preview);

  return (
    <div key={url} ref={multiRef} style={style}>
      {code} ({area} ha): {crop}
      <ThrottledTextField
        fullWidth
        label="Noter"
        margin="dense"
        value={notes}
        variant="outlined"
        onChange={handleNotesChange}
      />
      <div ref={drag} style={dragHandleStyle}>
        <DragHorizontalVariantIcon />
      </div>
      {extraNotesLine}
    </div>
  );
});

interface FieldsNotesReorderingProps {
  extraNotes?: ReadonlyMap<string, string> | undefined;
  fieldWithNotesList: readonly FieldUseWithField[];
  onDrop: (sourceField: FieldUseWithField, targetField: FieldUseWithField) => void;
  onNotesChange: (fieldUse: FieldUseWithField) => void;
  ordered?: boolean;
}

export const FieldsNotesReordering = React.memo(function FieldsNotesReordering(
  props: FieldsNotesReorderingProps,
): JSX.Element | null {
  const {extraNotes, fieldWithNotesList, onDrop, onNotesChange, ordered} = props;
  if (!fieldWithNotesList.length) {
    return null;
  }
  const fieldList = fieldWithNotesList.map((fieldUse) => {
    return (
      <SelectedFieldLine
        key={fieldUse.field.url}
        extraNotes={extraNotes}
        fieldUse={fieldUse}
        onDrop={onDrop}
        onNotesChange={onNotesChange}
      />
    );
  });
  if (ordered) {
    return <div>{fieldList}</div>;
  }
  return <div>{fieldList}</div>;
});
