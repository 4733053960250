import {Contact} from "@co-common-libs/resources";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {
  actions,
  diffResourceInstanceProperties,
  getExtendedCustomerSettings,
} from "@co-frontend-libs/redux";
import {DialogContent} from "@material-ui/core";
import {simpleObjectReducer} from "app-utils";
import React, {useCallback, useEffect, useMemo, useReducer} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {CustomerContactForm, createFormContactValidation} from "./customer-contact-form";

interface EditCustomerContactDialogProps {
  contact: Contact;
  fullscreen?: boolean;
  onCancel: () => void;
  onOk: () => void;
  open: boolean;
}
export const EditCustomerContactDialog = React.memo(function EditCustomerContactDialog(
  props: EditCustomerContactDialogProps,
): JSX.Element {
  const {contact: initialContact, onOk, open, ...other} = props;
  const [contact, reducerDispatch] = useReducer(simpleObjectReducer<Contact>, initialContact);

  const reduxDispatch = useDispatch();
  const handleSaveClick = useCallback(() => {
    const updatedProperties = diffResourceInstanceProperties(contact, initialContact, [
      "name",
      "cellphone",
      "phone",
      "email",
      "fax",
    ]);
    if (updatedProperties) {
      reduxDispatch(actions.update(contact.url, updatedProperties));
    }
    onOk();
  }, [reduxDispatch, onOk, contact, initialContact]);

  useEffect(() => {
    if (open) {
      reducerDispatch({type: "reset", value: initialContact});
    }
  }, [open, reducerDispatch, initialContact]);

  const intl = useIntl();
  const formContactSchema = useMemo(() => createFormContactValidation(intl), [intl]);

  const validationResult = formContactSchema.safeParse(contact);
  const errors = validationResult.success ? null : validationResult.error;

  const {
    contacts: {contactsMayHaveCellphone, contactsMayHaveFax},
  } = useSelector(getExtendedCustomerSettings);

  return (
    <ResponsiveDialog
      autoFocusCancel
      cancelLabel={intl.formatMessage({defaultMessage: "Fortryd"})}
      okDisabled={!validationResult.success}
      okLabel={intl.formatMessage({defaultMessage: "Gem"})}
      open={open}
      title={intl.formatMessage({defaultMessage: "Redigér kontakt"})}
      onOk={handleSaveClick}
      {...other}
    >
      <DialogContent>
        <CustomerContactForm
          contact={contact}
          contactsMayHaveCellphone={contactsMayHaveCellphone}
          contactsMayHaveFax={contactsMayHaveFax}
          dispatch={reducerDispatch}
          validationError={errors}
        />
      </DialogContent>
    </ResponsiveDialog>
  );
});
