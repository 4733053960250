import {Timer} from "@co-common-libs/resources";

export function simpleMemoize<T, U>(fn: (param: T) => U): (param: T) => U {
  let oldArg: T;
  let value: U;
  return (arg) => {
    if (arg !== oldArg) {
      value = fn(arg);
      oldArg = arg;
    }
    return value;
  };
}

export const getGenericPrimaryTimer = simpleMemoize(
  (timerMap: ReadonlyMap<string, Timer>): Timer => {
    for (const timer of timerMap.values()) {
      if (timer.active && timer.isGenericEffectiveTime) {
        return timer;
      }
    }
    // HACK; should never be reached...
    console.assert(false, "no effective timer?");
    return undefined as any as Timer;
  },
);

export const getBreakTimer = simpleMemoize((timerMap: ReadonlyMap<string, Timer>): Timer => {
  for (const timer of timerMap.values()) {
    if (timer.active && timer.isBreak) {
      return timer;
    }
  }
  // HACK; should never be reached...
  console.assert(false, "no break timer?");
  return undefined as any as Timer;
});

// Django/PostgreSQL includes microseconds in timestamps...
export const TIMESTAMP_WITH_MICROSECONDS_LENGTH = "2018-07-23T08:33:50.780000Z".length;
export const TIMESTAMP_WITH_MILLISECONDS_LENGTH = "2018-07-23T08:33:50.780Z".length;
export const TIMESTAMP_WITHOUT_MILLISECONDS_LENGTH = "2018-07-23T08:33:50Z".length;
export const NORMALISED_TIMESTAMP_STRING_LENGTH = TIMESTAMP_WITH_MILLISECONDS_LENGTH;

export const normalisedTimestamp = (timestamp: string): string => {
  if (timestamp.length === NORMALISED_TIMESTAMP_STRING_LENGTH) {
    return timestamp;
  } else {
    const {length} = timestamp;
    console.assert(
      length === TIMESTAMP_WITH_MICROSECONDS_LENGTH ||
        length === TIMESTAMP_WITHOUT_MILLISECONDS_LENGTH,
    );
    console.assert(timestamp[length - 1] === "Z");
    if (length === TIMESTAMP_WITH_MICROSECONDS_LENGTH) {
      const MICROSECONDS_DIGITS = 3;
      return `${timestamp.substr(0, length - (MICROSECONDS_DIGITS + 1))}Z`;
    }
    return `${timestamp.substr(0, length - 1)}.000Z`;
  }
};
