import {ResourceName, resourceNameFor, urlToId} from "@co-common-libs/resources";
import {GenericInstanceRecord, ResourceInstanceRecords} from "../../types";
import {
  FkRules,
  FkRulesForTarget,
  ReverseFkRules,
  ReverseFkRulesForSource,
  buildCheckFunction,
  partialEntriesForEach,
} from "../../utils";

// for each updated instance;
// if it fulfills criteria;
// and it previously did not exist or did not fulfill the criteria,
// then add the reverse-lookup to fetch-list...
function updateResourceInstancesToFetchFromUpdatedAndFkRules(
  oldData: ResourceInstanceRecords,
  potentialData: ResourceInstanceRecords,
  resourceName: ResourceName,
  resourceUpdatedInstances: GenericInstanceRecord,
  resourceFkRules: FkRulesForTarget,
  toFetchByRelation: Partial<{
    [N in ResourceName]: Partial<{
      [memberName: string]: Set<string>;
    }>;
  }>,
): void {
  const resourceInstances = oldData[resourceName] as GenericInstanceRecord;
  resourceFkRules.forEach(({checkedResourceName, memberName, targetCheck}) => {
    const newCheckFunction = buildCheckFunction(targetCheck, potentialData);
    const oldCheckFunction = buildCheckFunction(targetCheck, oldData);
    partialEntriesForEach(resourceUpdatedInstances, (url, newInstance) => {
      if (!newCheckFunction(newInstance)) {
        // does not fulfill criteria
        return;
      }
      const oldInstance = resourceInstances[url];
      if (oldInstance && oldCheckFunction(oldInstance)) {
        // was previously fulfilled; so no change
        return;
      }
      const id = urlToId(url);
      const resourceToFetchByRelation = toFetchByRelation[checkedResourceName];
      if (resourceToFetchByRelation) {
        const resourceMemberToFetchByRelation = resourceToFetchByRelation[memberName];
        if (resourceMemberToFetchByRelation) {
          resourceMemberToFetchByRelation.add(id);
        } else {
          resourceToFetchByRelation[memberName] = new Set([id]);
        }
      } else {
        toFetchByRelation[checkedResourceName] = {
          [memberName]: new Set([id]),
        };
      }
    });
  });
}

// for each updated instance;
// if it has a FK in the relevant field;
// and the resource instance pointed to is not currently present;
// and the updated instance fulfills the criteria to follow that FK;
// and it previously did not exist or had a different value in the field or did not fulfill the criteria;
// then add the FK target to fetch-list...
function updateResourceInstancesToFetchFromUpdatedAndReverseFkRules(
  oldData: ResourceInstanceRecords,
  potentialData: ResourceInstanceRecords,
  resourceName: ResourceName,
  resourceUpdatedInstances: GenericInstanceRecord,
  resourceReverseFkRules: ReverseFkRulesForSource,
  toFetchByID: Partial<{
    [N in ResourceName]: Set<string>;
  }>,
): void {
  const resourceInstances = oldData[resourceName] as GenericInstanceRecord;
  resourceReverseFkRules.forEach(({checkedResourceName, memberName, sourceCheck}) => {
    const newCheckFunction = buildCheckFunction(sourceCheck, potentialData);
    const oldCheckFunction = buildCheckFunction(sourceCheck, oldData);
    partialEntriesForEach(resourceUpdatedInstances, (url, newInstance) => {
      const oldInstance = resourceInstances[url];
      const newMemberValue = (newInstance as any)[memberName] as string | undefined;
      if (typeof newMemberValue !== "string") {
        // no FK
        return;
      }
      const fkTargetResourceName = resourceNameFor(newMemberValue);
      // in a sensible configuration, types match
      console.assert(checkedResourceName === fkTargetResourceName);
      if (potentialData[fkTargetResourceName][newMemberValue]) {
        // target already present
        return;
      }
      if (!newCheckFunction(newInstance)) {
        // does not fulfill criteria to follow FK
        return;
      }
      if (
        oldInstance &&
        (oldInstance as any)[memberName] === newMemberValue &&
        oldCheckFunction(oldInstance)
      ) {
        // was previously fulfilled; so no change
        return;
      }
      const id = urlToId(newMemberValue);
      const resourceToFetchByID = toFetchByID[fkTargetResourceName];
      if (resourceToFetchByID) {
        resourceToFetchByID.add(id);
      } else {
        toFetchByID[fkTargetResourceName] = new Set([id]);
      }
    });
  });
}

export function computeInstancesToFetchFromUpdated(
  oldData: ResourceInstanceRecords,
  potentialData: ResourceInstanceRecords,
  updatedInstances: Partial<ResourceInstanceRecords>,
  persistedFkRules: FkRules,
  persistedReverseFkRules: ReverseFkRules,
  temporaryFkRules: FkRules,
  temporaryReverseFkRules: ReverseFkRules,
): {
  persistedFetchByID: Partial<{
    readonly [N in ResourceName]: ReadonlySet<string>;
  }>;
  persistedFetchByRelation: Partial<{
    [N in ResourceName]: Partial<{
      readonly [memberName: string]: ReadonlySet<string>;
    }>;
  }>;
  temporaryFetchByID: Partial<{
    readonly [N in ResourceName]: ReadonlySet<string>;
  }>;
  temporaryFetchByRelation: Partial<{
    [N in ResourceName]: Partial<{
      readonly [memberName: string]: ReadonlySet<string>;
    }>;
  }>;
} {
  const persistedFetchByID: Partial<{
    [N in ResourceName]: Set<string>;
  }> = {};
  const temporaryFetchByID: Partial<{
    [N in ResourceName]: Set<string>;
  }> = {};
  const persistedFetchByRelation: Partial<{
    [N in ResourceName]: Partial<{
      [memberName: string]: Set<string>;
    }>;
  }> = {};
  const temporaryFetchByRelation: Partial<{
    [N in ResourceName]: Partial<{
      [memberName: string]: Set<string>;
    }>;
  }> = {};
  partialEntriesForEach(updatedInstances, (resourceName, resourceUpdatedInstances) => {
    const resourcePersistedFkRules = persistedFkRules[resourceName];
    const resourcePersistedReverseFkRules = persistedReverseFkRules[resourceName];
    const resourceTemporaryFkRules = temporaryFkRules[resourceName];
    const resourceTemporaryReverseFkRules = temporaryReverseFkRules[resourceName];
    if (resourcePersistedFkRules) {
      updateResourceInstancesToFetchFromUpdatedAndFkRules(
        oldData,
        potentialData,
        resourceName,
        resourceUpdatedInstances,
        resourcePersistedFkRules,
        persistedFetchByRelation,
      );
    }
    if (resourceTemporaryFkRules) {
      updateResourceInstancesToFetchFromUpdatedAndFkRules(
        oldData,
        potentialData,
        resourceName,
        resourceUpdatedInstances,
        resourceTemporaryFkRules,
        temporaryFetchByRelation,
      );
    }
    if (resourcePersistedReverseFkRules) {
      updateResourceInstancesToFetchFromUpdatedAndReverseFkRules(
        oldData,
        potentialData,
        resourceName,
        resourceUpdatedInstances,
        resourcePersistedReverseFkRules,
        persistedFetchByID,
      );
    }
    if (resourceTemporaryReverseFkRules) {
      updateResourceInstancesToFetchFromUpdatedAndReverseFkRules(
        oldData,
        potentialData,
        resourceName,
        resourceUpdatedInstances,
        resourceTemporaryReverseFkRules,
        temporaryFetchByID,
      );
    }
  });
  return {
    persistedFetchByID,
    persistedFetchByRelation,
    temporaryFetchByID,
    temporaryFetchByRelation,
  };
}
