import {Project, ProjectUrl, Task} from "@co-common-libs/resources";
import {
  EconomicProjectSyncEnabledCustomerSettings,
  economicProjectImportEnabled,
} from "@co-common-libs/utils";
import {EconomicProjectIssue} from "../types";

export function economicProjectIssues({
  customerSettings,
  projectLookup,
  task,
}: {
  customerSettings: EconomicProjectSyncEnabledCustomerSettings;
  projectLookup: (url: ProjectUrl) => Project | undefined;
  task: Pick<Task, "project">;
}): readonly EconomicProjectIssue[] {
  const project = task.project && projectLookup(task.project);
  if (!project || !economicProjectImportEnabled(customerSettings)) {
    return [];
  }

  const issues: EconomicProjectIssue[] = [];
  if (project.access === "closed") {
    issues.push({
      category: "error",
      instance: project,
      issueType: project.access,
      resourceName: "project",
    });
  }

  if (project.remoteUrl) {
    if (project.access === "barred") {
      issues.push({
        category: "error",
        instance: project,
        issueType: "barred",
        resourceName: "project",
      });
    }
  } else {
    issues.push({
      category: "error",
      instance: project,
      issueType: project.access === "barred" ? "deleted" : "missingRemoteUrl",
      resourceName: "project",
    });
  }

  if (!project.projectNumber) {
    issues.push({
      category: "error",
      instance: project,
      issueType: "missingRemoteIdentifier",
      resourceName: "project",
    });
  }
  return issues;
}
