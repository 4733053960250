import {Timer, TimerUrl} from "@co-common-libs/resources";
import {TrimTextField} from "@co-frontend-libs/components";
import {bind} from "bind-decorator";
import React from "react";
import {IntlContext, defineMessages} from "react-intl";
import {TimeButton} from "./time-button";

const messages = defineMessages({
  timerNote: {
    defaultMessage: "Note *",
    id: "task-instance.label.timer-note",
  },
});

interface TimerButtonWithNotesProps {
  activeTimer?: TimerUrl | undefined;
  allCorrectionsDisabled: boolean;
  allTimersDisabled: boolean;
  onNotesChange: (timerURL: TimerUrl, note: string) => void;
  onTimerButton: (timerURL: TimerUrl) => void;
  startDisabled: boolean;
  timer: Timer;
  timerMinutes: ReadonlyMap<string, number>;
  timerNote?: string | undefined;
}

export class TimerButtonWithNotes extends React.Component<TimerButtonWithNotesProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  @bind
  handleNotesChange(value: string): void {
    this.props.onNotesChange(this.props.timer.url, value);
  }
  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {
      activeTimer,
      allCorrectionsDisabled,
      allTimersDisabled,
      onTimerButton,
      startDisabled,
      timer,
      timerMinutes,
      timerNote,
    } = this.props;
    const timerURL = timer.url;
    const minutes = timerMinutes.get(timerURL) || 0;
    const isActive = activeTimer === timerURL;
    const requiresNotes = !!(timer.requiresNotes && (minutes || isActive));
    let noteField;
    if (requiresNotes) {
      noteField = (
        <TrimTextField
          fullWidth
          disabled={allCorrectionsDisabled}
          label={formatMessage(messages.timerNote)}
          margin="dense"
          value={timerNote}
          variant="outlined"
          onChange={this.handleNotesChange}
        />
      );
    }
    return (
      <>
        <TimeButton
          correctionDisabled={allCorrectionsDisabled}
          isActive={isActive}
          minutes={minutes}
          requiresNotes={requiresNotes}
          startDisabled={startDisabled}
          timer={timer}
          timerDisabled={allTimersDisabled}
          onTimerButton={onTimerButton}
        />
        {noteField}
      </>
    );
  }
}
