export function getOrInsertDefault<K, V>(map: Map<K, V>, key: K, defaultFactory: () => V): V {
  const existing = map.get(key);
  if (existing !== undefined) {
    return existing;
  } else {
    const newValue = defaultFactory();
    map.set(key, newValue);
    return newValue;
  }
}
