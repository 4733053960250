import type {
  PathPatchOperation,
  PriceItemUseWithOrder,
  PriceItemUsesDict,
} from "@co-common-libs/resources";

const priceItemUseWithOrderMembers = [
  "correctedCount",
  "count",
  "dangling",
  "machine",
  "notes",
  "priceGroup",
  "priceItem",
  "timer",
  "workType",
  "order",
] as const;

type PriceItemUseWithOrderMembers = keyof PriceItemUseWithOrder;
type PriceItemUseWithOrderArrayMembers = (typeof priceItemUseWithOrderMembers)[number];
type PriceItemUseWithOrderMembersMatch =
  PriceItemUseWithOrderMembers extends PriceItemUseWithOrderArrayMembers
    ? PriceItemUseWithOrderArrayMembers extends PriceItemUseWithOrderMembers
      ? true
      : false
    : false;

if (process.env.NODE_ENV !== "production") {
  const test: PriceItemUseWithOrderMembersMatch = true;
  if (!test) {
    // pretend variable is used
  }
}

export function patchFromPriceItemUsesChange(
  oldPriceItemUses: PriceItemUsesDict,
  newPriceItemUses: PriceItemUsesDict,
): PathPatchOperation[] {
  const patch: PathPatchOperation[] = [];
  Object.keys(oldPriceItemUses).forEach((identifier) => {
    if (!newPriceItemUses[identifier]) {
      // removed
      patch.push({path: ["priceItemUses", identifier], value: undefined});
    }
  });
  Object.entries(newPriceItemUses).forEach(([identifier, priceItemUse]) => {
    const existing = oldPriceItemUses[identifier];
    if (!existing) {
      // added
      patch.push({path: ["priceItemUses", identifier], value: priceItemUse});
    } else if (priceItemUse !== existing) {
      // may have changes
      priceItemUseWithOrderMembers.forEach((member) => {
        const newValue = priceItemUse[member];
        if (newValue === undefined) {
          return;
        }
        if (newValue !== existing[member]) {
          patch.push({
            path: ["priceItemUses", identifier, member],
            value: newValue,
          });
        }
      });
    }
  });

  return patch;
}
