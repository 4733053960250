import {formatAddress} from "@co-common-libs/utils";
import {Typography} from "@material-ui/core";
import React from "react";
import {MapsButton} from "../maps-button";

export function AddressLine({
  address,
}: {
  address:
    | string
    | {
        address: string;
        city: string;
        postalCode: string;
      };
}): JSX.Element {
  const formattedAddress = typeof address !== "string" ? formatAddress(address) : address;
  return (
    <div>
      <MapsButton address={formattedAddress} />
      <Typography component="span" variant="body2">
        {formattedAddress}
      </Typography>
    </div>
  );
}
