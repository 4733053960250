import {PriceItem, PriceItemUseWithOrder} from "@co-common-libs/resources";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {getCustomerSettings} from "@co-frontend-libs/redux";
import {DialogContent, Grid, TextField} from "@material-ui/core";
import {useEventTargetValueCallback} from "app-utils";
import React, {useCallback, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";

interface PriceItemUseNotesDialogProps {
  onCancel: () => void;
  onOk: (changed: {notes?: string; ours?: boolean}) => void;
  open: boolean;
  priceItem?: PriceItem | undefined;
  priceItemUse?: PriceItemUseWithOrder | undefined;
  readonly: boolean;
}

export const PriceItemUseNotesDialog = React.memo(function PriceItemUseNotesDialog(
  props: PriceItemUseNotesDialogProps,
): JSX.Element | null {
  const {onCancel, onOk, open, priceItem, priceItemUse, readonly} = props;
  const customerSettings = useSelector(getCustomerSettings);
  const showNotes = customerSettings.enableMaterialNoteFields;

  const [notes, setNotes] = useState(priceItemUse?.notes || "");

  useEffect(() => {
    if (open) {
      setNotes(priceItemUse?.notes || "");
    }
  }, [open, priceItemUse?.notes]);

  const handleNotesChange = useEventTargetValueCallback(setNotes, [setNotes]);

  const handleOk = useCallback(() => {
    const changed: {notes?: string} = {};
    if (notes !== priceItemUse?.notes || "") {
      changed.notes = notes;
    }
    onOk(changed);
  }, [notes, onOk, priceItemUse?.notes]);

  const intl = useIntl();

  if (!priceItemUse || !priceItem) {
    return null;
  }

  return (
    <ResponsiveDialog
      open={open}
      title={
        customerSettings.materialUseAlternativeText
          ? intl.formatMessage({defaultMessage: "Materiale detaljer"})
          : intl.formatMessage({defaultMessage: "Materiel detaljer"})
      }
      onCancel={onCancel}
      onOk={handleOk}
    >
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            {priceItem.name}
          </Grid>
          {showNotes ? (
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                disabled={readonly}
                id="notes-field"
                margin="dense"
                placeholder={intl.formatMessage({defaultMessage: "Noter"})}
                value={notes}
                variant="outlined"
                onChange={handleNotesChange}
              />
            </Grid>
          ) : null}
        </Grid>
      </DialogContent>
    </ResponsiveDialog>
  );
});
