import {User, UserProfile} from "@co-common-libs/resources";
import React from "react";
import {useIntl} from "react-intl";
import {UserEditData, UserProfileData} from "./types";
import {UserCreateEditDialog} from "./user-create-edit-dialog";

interface UserEditDialogProps {
  data: {
    user: Readonly<User>;
    userProfile: Readonly<UserProfile>;
  };
  onCancel: () => void;
  onOk: (userData: UserEditData, userProfileData: UserProfileData) => void;
  open: boolean;
}

export const UserEditDialog = React.memo(function UserEditDialog(props: UserEditDialogProps) {
  const {data, onCancel, onOk, open} = props;

  const intl = useIntl();

  return (
    <UserCreateEditDialog
      data={data}
      open={open}
      title={intl.formatMessage({defaultMessage: "Rediger medarbejder"})}
      onCancel={onCancel}
      onOk={onOk}
    />
  );
});
