import {
  Machine,
  MachineUrl,
  PriceGroup,
  PriceGroupUrl,
  ReportingSpecification,
  ReportingSpecificationUrl,
  Task,
  WorkType,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {getExpectedLogSpecificationWithCriteria} from "./get-expected-log-specification-with-criteria";

export function willRemovalChangeLogSpecification(
  task: Task,
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined,
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined,
  machineLookup: (url: MachineUrl) => Machine | undefined,
  reportingSpecificationLookup: (
    url: ReportingSpecificationUrl,
  ) => ReportingSpecification | undefined,
  toBeRemovedUrl: string,
): boolean {
  const logSpecificationsWithCriteria = getExpectedLogSpecificationWithCriteria(
    task,
    priceGroupLookup,
    workTypeLookup,
    machineLookup,
    reportingSpecificationLookup,
  );
  if (!logSpecificationsWithCriteria.length) {
    // no log specification
    return false;
  }
  if (logSpecificationsWithCriteria[0].reasonUrl !== toBeRemovedUrl) {
    // overridden by other criteria anyway
    return false;
  }
  if (logSpecificationsWithCriteria[0].reportingSpecification.url !== task.reportingSpecification) {
    // we somedow don't use the specified log specification now
    return false;
  }
  if (
    logSpecificationsWithCriteria.length > 1 &&
    logSpecificationsWithCriteria[1].reportingSpecification ===
      logSpecificationsWithCriteria[0].reportingSpecification
  ) {
    // there is a next criteria which gives the same result
    return false;
  }
  return true;
}
