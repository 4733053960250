import {Config} from "@co-common-libs/config";
import {Machine, Order, Task, UserProfile, WorkType, urlToId} from "@co-common-libs/resources";
import {
  formatDuration,
  getMachinesWorkTypeString,
  getWorkTypeString,
  timerMayBeBilled,
} from "@co-common-libs/resources-utils";
import {formatDate} from "@co-common-libs/utils";
import {
  actions,
  getCustomerSettings,
  getMachineLookup,
  getPriceGroupLookup,
  getPriceItemLookup,
  getTimerArray,
  getTimerLookup,
  getUserUserProfileLookup,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {TaskStatusIcon} from "app-components";
import {completedTaskIntervals, computeIntervalSums, getGenericPrimaryTimer} from "app-utils";
import _ from "lodash";
import React, {useCallback, useMemo} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

const dateColumnStyle: React.CSSProperties = {
  paddingRight: 0,
  textAlign: "right",
  width: 150,
};
const timeColumnStyle: React.CSSProperties = {
  width: 86,
};
const initialsColumnStyle: React.CSSProperties = {
  width: 80,
};
const statusIconColumnStyle: React.CSSProperties = {
  width: 72,
};

interface OrderRowProps {
  order: Order;
}

const OrderRow = React.memo(function OrderRow(props: OrderRowProps): JSX.Element {
  const {order} = props;
  const orderURL = order.url;

  const dispatch = useDispatch();

  const handleClick = useCallback((): void => {
    const id = urlToId(orderURL);
    dispatch(actions.go("/orderEntry/:id", {id}));
  }, [dispatch, orderURL]);

  return (
    <TableRow key={orderURL} style={{cursor: "pointer"}} onClick={handleClick}>
      <TableCell style={{fontWeight: "bold"}}>
        <FormattedMessage defaultMessage="Ordre:" id="customer-instance.label.order" />
      </TableCell>
      <TableCell style={initialsColumnStyle} />
      <TableCell style={timeColumnStyle} />
      <TableCell style={dateColumnStyle}>
        <strong>{formatDate(order.date)}</strong>
      </TableCell>
      <TableCell style={statusIconColumnStyle} />
    </TableRow>
  );
});

interface TaskRowProps {
  customerSettings: Config;
  machineList: readonly Machine[];
  machineOperatorProfile: UserProfile | undefined;
  task: Task;
  workType?: WorkType | undefined;
}

const TaskRow = React.memo(function TaskRow(props: TaskRowProps): JSX.Element {
  const {customerSettings, machineList, machineOperatorProfile, task, workType} = props;
  const taskURL = task.url;

  const timerArray = useSelector(getTimerArray);
  const timerLookup = useSelector(getTimerLookup);
  const workTypeLookup = useSelector(getWorkTypeLookup);
  const priceGroupLookup = useSelector(getPriceGroupLookup);
  const priceItemLookup = useSelector(getPriceItemLookup);

  const dispatch = useDispatch();

  const intl = useIntl();

  const handleClick = useCallback((): void => {
    const id = urlToId(taskURL);
    dispatch(actions.go("/task/:id", {id}));
  }, [dispatch, taskURL]);

  let workTypeString;
  if (workType) {
    workTypeString = getWorkTypeString(workType);
  } else if (customerSettings.enableExternalTaskDepartmentField && task.department === "E") {
    workTypeString = intl.formatMessage({
      defaultMessage: "Entreprenørarbejde",
    });
  } else {
    workTypeString = getMachinesWorkTypeString(machineList);
  }
  const machineOperatorInitials = machineOperatorProfile ? machineOperatorProfile.alias : "";
  const external = task.order;
  let externalPrimaryMinutes: number | undefined;
  if (task.completed && external) {
    const intervals = completedTaskIntervals(task);
    const timerMinutes = computeIntervalSums(intervals);
    const primaryTimer = getGenericPrimaryTimer(timerArray);

    console.assert(primaryTimer, "Missing primary timer");
    if (primaryTimer) {
      externalPrimaryMinutes = _.sumBy(
        Array.from(timerMinutes).filter(([timerUrl, _minutes]) =>
          timerMayBeBilled(
            timerUrl,
            primaryTimer.url,
            timerLookup,
            workTypeLookup,
            priceGroupLookup,
            priceItemLookup,
          ),
        ),
        ([_timerUrl, minutes]) => minutes,
      );
    }
  }
  return (
    <TableRow key={task.url} style={{cursor: "pointer"}} onClick={handleClick}>
      <TableCell>{workTypeString}</TableCell>
      <TableCell style={initialsColumnStyle}>{machineOperatorInitials}</TableCell>
      <TableCell style={timeColumnStyle}>
        {externalPrimaryMinutes
          ? formatDuration(props.customerSettings.durationFormat, externalPrimaryMinutes)
          : ""}
      </TableCell>
      <TableCell style={dateColumnStyle}>{formatDate(task.date)}</TableCell>
      <TableCell style={statusIconColumnStyle}>
        <TaskStatusIcon task={task} timerStartArray={[]} />
      </TableCell>
    </TableRow>
  );
});

interface OrderListProps {
  orderList: readonly Order[];
  taskArray: readonly Task[];
}

const OrderList = React.memo(function OrderList(props: OrderListProps): JSX.Element {
  const {orderList, taskArray} = props;

  const customerSettings = useSelector(getCustomerSettings);
  const workTypeLookup = useSelector(getWorkTypeLookup);
  const userUserProfileLookup = useSelector(getUserUserProfileLookup);
  const machineLookup = useSelector(getMachineLookup);

  const orderTaskArrayMap = useMemo(() => {
    const result = new Map<string, Task[]>();
    taskArray.forEach((task) => {
      const orderURL = task.order;
      if (orderURL) {
        const orderTaskArray = result.get(orderURL);
        if (orderTaskArray) {
          orderTaskArray.push(task);
        } else {
          result.set(orderURL, [task]);
        }
      }
    });
    return result;
  }, [taskArray]);

  const rowArray: JSX.Element[] = [];
  orderList.forEach((order) => {
    const orderURL = order.url;
    rowArray.push(<OrderRow key={orderURL} order={order} />);
    const now = new Date();
    now.setUTCMilliseconds(0);
    let taskList = _.sortBy(
      orderTaskArrayMap.get(orderURL) || [],
      (task) => task.workFromTimestamp,
    );
    if (customerSettings.customerOrderListNewestFirst) {
      taskList = taskList.reverse();
    }
    taskList.forEach((task) => {
      const workTypeURL = task.workType;
      const workType = typeof workTypeURL === "string" ? workTypeLookup(workTypeURL) : workTypeURL;
      const machineOperatorProfile = task.machineOperator
        ? userUserProfileLookup(task.machineOperator)
        : undefined;
      const machineList = (task.machineuseSet || [])
        .map((machineUse) => {
          const machineURL = machineUse.machine;
          return typeof machineURL === "string" ? machineLookup(machineURL) : machineURL;
        })
        .filter(Boolean) as Machine[];
      rowArray.push(
        <TaskRow
          key={task.url}
          customerSettings={customerSettings}
          machineList={machineList}
          machineOperatorProfile={machineOperatorProfile || undefined}
          task={task}
          workType={workType || undefined}
        />,
      );
    });
  });
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <FormattedMessage
              defaultMessage="Område"
              id="customer-instance.table-header.work-type"
            />
          </TableCell>
          <TableCell style={initialsColumnStyle}>
            <FormattedMessage
              defaultMessage="Medarb."
              id="customer-instance.table-header.employee"
            />
          </TableCell>
          <TableCell style={timeColumnStyle}>
            <FormattedMessage
              defaultMessage="Eff. tid"
              id="customer-instance.table-header.effective-time"
            />
          </TableCell>
          <TableCell style={dateColumnStyle}>
            <FormattedMessage defaultMessage="Dato" id="customer-instance.table-header.date" />
          </TableCell>
          <TableCell style={statusIconColumnStyle} />
        </TableRow>
      </TableHead>
      <TableBody>{rowArray}</TableBody>
    </Table>
  );
});

export default OrderList;
