import {Config} from "@co-common-libs/config";
import {Task, WorkType, WorkTypeUrl} from "@co-common-libs/resources";

export const adjustMinutes = (
  setting:
    | "ADD_10"
    | "ADD_15"
    | "MIN_30_ROUND_NEAREST_15"
    | "MIN_30_ROUND_UP_15"
    | "NO_ADJUSTMENT"
    | "ROUND_NEAREST_15"
    | "ROUND_UP_15"
    | "ROUND_UP_30",
  minutes: number,
): number => {
  switch (setting) {
    case "ROUND_UP_15":
      return Math.ceil(minutes / 15) * 15;

    case "ROUND_UP_30":
      return Math.ceil(minutes / 30) * 30;

    case "ADD_10":
      return minutes + 10;

    case "ADD_15":
      return minutes + 15;

    case "MIN_30_ROUND_NEAREST_15":
      return minutes === 0 ? 0 : Math.max(adjustMinutes("ROUND_NEAREST_15", minutes), 30);

    case "MIN_30_ROUND_UP_15":
      return minutes === 0 ? 0 : Math.max(adjustMinutes("ROUND_UP_15", minutes), 30);

    case "ROUND_NEAREST_15":
      return Math.round(minutes / 15) * 15;

    case "NO_ADJUSTMENT":
      return minutes;

    default:
      console.assert(false, `Customer setting "adjustBilledMinutes: ${setting}" is not supported`);
      return minutes;
  }
};

export function concurrencyAllowedForTask(
  task: Task,
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined,
  customerSettings: Config,
): boolean {
  if (customerSettings.concurrentTasksAllowed) {
    return true;
  }
  if (
    customerSettings.concurrentTasksAllowedForDepartments.length &&
    customerSettings.concurrentTasksAllowedForDepartments.includes(task.department)
  ) {
    return true;
  }
  if (customerSettings.concurrentTasksAllowedForWorkTypes.length && task.workType) {
    const workType = workTypeLookup(task.workType);
    if (
      workType &&
      customerSettings.concurrentTasksAllowedForWorkTypes.includes(workType.identifier)
    ) {
      return true;
    }
  }
  return false;
}

export function sameMinute(a: Date | string, b: Date | string): boolean {
  return new Date(a).setUTCSeconds(0, 0) === new Date(b).setUTCSeconds(0, 0);
}
