import {createHref, getCurrentRole, getCustomerSettings} from "@co-frontend-libs/redux";
import bowser from "bowser";
import MapMarkerIcon from "mdi-react/MapMarkerIcon";
import React from "react";
import {useSelector} from "react-redux";

interface ActiveMarkerProps {
  color: string;
  customerNames: readonly string[];
  lat: number | undefined;
  lng: number | undefined;
  oldWarning: boolean;
  userTexts: {
    employeeAlias: string;
    machinesOrWorkType: string;
    taskID: string;
  }[];
  x: number;
  y: number;
}

export function ActiveMarker(props: ActiveMarkerProps): JSX.Element | null {
  const {color, customerNames, lat, lng, oldWarning, userTexts, x, y} = props;
  const currentUserRole = useSelector(getCurrentRole);
  const customerSettings = useSelector(getCustomerSettings);

  const googleMapsURL = `http://maps.google.com/?q=${lat},${lng}`;

  const currentUserIsMechanic = currentUserRole && currentUserRole.mechanic;
  const currentUserIsJobber = currentUserRole && currentUserRole.jobber;

  const userTextsLabels = userTexts.map(({employeeAlias, machinesOrWorkType, taskID}, i) => {
    return (
      <span key={taskID}>
        <b>{employeeAlias}</b>: {machinesOrWorkType}
        {i !== userTexts.length - 1 ? <br /> : null}
      </span>
    );
  });
  const customerNamesLabels = customerNames.map((customerName) => {
    return (
      <span key={customerName}>
        <br />
        {customerName}
      </span>
    );
  });

  const taskLinkURL =
    userTexts.length === 1 ? createHref("/task/:id", {id: userTexts[0].taskID}) : null;

  let labelURL = taskLinkURL;
  let labelTarget = "_self";
  if (
    (!currentUserRole?.manager && customerSettings.machineOperatorsCanOnlySeeTheirOwnTasks) ||
    currentUserIsJobber ||
    currentUserIsMechanic
  ) {
    labelURL = googleMapsURL;
    labelTarget = window.cordova && bowser.ios ? "_system" : "_blank";
  }

  const WARNING_OPACITY = 0.7;
  const iconSize = 36;

  const labelStyle: React.CSSProperties = {
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    borderRadius: 4,
    color: "#000",
    display: "inline-block",
    fontSize: 12,
    paddingLeft: 4,
    paddingRight: 4,
    textDecoration: "none",
  };

  const linkStyle: React.CSSProperties = {
    height: iconSize,
    stroke: "#000",
    width: iconSize,
  };
  if (oldWarning) {
    linkStyle.opacity = WARNING_OPACITY;
  }

  return (
    <>
      <span
        style={{
          display: "inline-block",

          left: x - iconSize / 2,
          position: "absolute",
          top: y - iconSize,
        }}
      >
        <a
          href={googleMapsURL}
          rel="noreferrer"
          style={linkStyle}
          target={window.cordova && bowser.ios ? "_system" : "_blank"}
        >
          <MapMarkerIcon color={color} size={iconSize} />
        </a>
      </span>
      <span
        style={{
          display: "inline-block",

          left: x + iconSize / 2 - 2,
          position: "absolute",

          top: y - iconSize + 2,
        }}
      >
        {labelURL ? (
          <a href={labelURL} style={labelStyle} target={labelTarget}>
            {userTextsLabels}
            {customerNamesLabels}
          </a>
        ) : (
          <span style={labelStyle}>
            {userTextsLabels}
            {customerNamesLabels}
          </span>
        )}
      </span>
    </>
  );
}
