import {RouteJournal as RenderedRouteJournal} from "@co-common-libs/report-rendering";
import {urlToId} from "@co-common-libs/resources";
import {notUndefined} from "@co-common-libs/utils";
import {
  getCurrentUserProfile,
  getCustomerLookup,
  getLocationLookup,
  getRoutePlanLookup,
  getShareToken,
} from "@co-frontend-libs/redux";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import {useEventTargetCheckedCallback} from "app-utils";
import {globalConfig} from "frontend-global-config";
import React, {useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {Configuration} from "./configuration";

export type Results = [
  {
    date: string;
    employeeAlias: string;
    geolocation: {latitude: number; longitude: number} | null;
    invoiceCustomerName: string;
    locationAddress: string;
    locationName: string;
    minutes: number;
    routePlanName: string;
    startTimestamp: string;
    stopTimestamp: string;
    variantIdentifier: string;
    variantName: string;
  },
];

interface DisplayResultsProps {
  configuration: Configuration;
  fromDate: string;
  results: Results;
  toDate: string;
}

export function DisplayResults(props: DisplayResultsProps): JSX.Element {
  const {configuration, fromDate, results, toDate} = props;
  const intl = useIntl();
  const [showFilteringCriteria, setShowFilteringCriteria] = useState<boolean>(true);
  const [showInvoiceCustomerColumn, setShowInvoiceCustomerColumn] = useState<boolean>(true);
  const filename = intl.formatMessage(
    {
      defaultMessage: "Rutejournal_{fromDate}_{toDate}.pdf",
    },
    {fromDate, toDate},
  );
  const resultsBaseURL = `${globalConfig.baseURL}/download/report/route_journal/pdf/${filename}`;
  const currentUserProfile = useSelector(getCurrentUserProfile);
  const printedBy = currentUserProfile?.name || currentUserProfile?.alias || "";
  const shareToken = useSelector(getShareToken);
  const routePlanFilterString = configuration.routePlanURLs
    .map((url) => `&routePlan=${urlToId(url)}`)
    .join("");
  const locationFilterString = configuration.locationURLs
    .map((url) => `&location=${urlToId(url)}`)
    .join("");
  const invoiceCustomerFilterString = configuration.invoiceCustomerURLs
    .map((url) => `&invoiceCustomer=${urlToId(url)}`)
    .join("");
  const parts = [
    `${resultsBaseURL}?token=${shareToken}&printedBy=${printedBy}`,
    `&fromDate=${fromDate}&toDate=${toDate}`,
    routePlanFilterString,
    locationFilterString,
    invoiceCustomerFilterString,
  ];
  if (showFilteringCriteria) {
    parts.push("&showFilteringCriteria");
  }
  if (showInvoiceCustomerColumn) {
    parts.push("&showInvoiceCustomerColumn");
  }
  const pdfURL = parts.join("");
  const routePlanLookup = useSelector(getRoutePlanLookup);
  const locationLookup = useSelector(getLocationLookup);
  const customerLookup = useSelector(getCustomerLookup);
  const filteringRoutePlanNames = configuration.routePlanURLs
    .map((url) => routePlanLookup(url)?.name)
    .filter(notUndefined)
    .sort();
  const filteringLocationNames = configuration.locationURLs
    .map((url) => {
      const location = locationLookup(url);
      return location?.name || location?.address;
    })
    .filter(notUndefined);
  const filteringInvoiceCustomerNames = configuration.invoiceCustomerURLs
    .map((url) => customerLookup(url)?.name)
    .filter(notUndefined);

  return (
    <Card>
      <CardContent>
        <FormControlLabel
          control={
            <Checkbox
              checked={showFilteringCriteria}
              onChange={useEventTargetCheckedCallback(setShowFilteringCriteria, [
                setShowFilteringCriteria,
              ])}
            />
          }
          label={
            <FormattedMessage
              defaultMessage="Vis filtreringskriterier"
              id="route-journal.label.show-filtering-criteria"
            />
          }
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={showInvoiceCustomerColumn}
              onChange={useEventTargetCheckedCallback(setShowInvoiceCustomerColumn, [
                setShowInvoiceCustomerColumn,
              ])}
            />
          }
          label={
            <FormattedMessage
              defaultMessage="Vis Fakturakunde-kolonne"
              id="route-journal.label.show-invoice-customer-column"
            />
          }
        />
      </CardContent>
      <CardContent>
        <div style={{border: "1px solid black", padding: "7mm"}}>
          <RenderedRouteJournal
            data={results}
            filteringInvoiceCustomerNames={filteringInvoiceCustomerNames}
            filteringLocationNames={filteringLocationNames}
            filteringRoutePlanNames={filteringRoutePlanNames}
            fromDate={fromDate}
            showFilteringCriteria={showFilteringCriteria}
            showInvoiceCustomerColumn={showInvoiceCustomerColumn}
            toDate={toDate}
          />
        </div>
      </CardContent>
      <CardActions>
        <Button color="primary" href={pdfURL} target="_blank" variant="contained">
          <FormattedMessage defaultMessage="Hent PDF" id="route-journal.label.fetch-pdf" />
        </Button>
      </CardActions>
    </Card>
  );
}
