import {Task, TaskUrl, Timer, TimerStart, TimerUrl} from "@co-common-libs/resources";
import {
  computeTimerstartIntervals,
  getNormalisedDeviceTimestamp,
} from "@co-common-libs/resources-utils";
import {notNull} from "@co-common-libs/utils";
import {
  AppState,
  getActiveTimerStartArray,
  getTaskLookup,
  getTimerLookup,
  getTimerStartArray,
} from "@co-frontend-libs/redux";
import {getFrontendSentry} from "@co-frontend-libs/utils";
import {sortBy} from "lodash";
import {createSelector} from "reselect";
import {Alert} from "./alert";

function filterAlertTriggeringTimerStarts(
  timerStarts: readonly TimerStart[],
  lookupTimer: (url: TimerUrl) => Timer | undefined,
): readonly TimerStart[] {
  return timerStarts.filter(
    (timerStart) => timerStart.timer && lookupTimer(timerStart.timer)?.notifyUsersOnStart,
  );
}

const getAlertTriggeringTimerStarts: (state: AppState) => readonly TimerStart[] = createSelector(
  getActiveTimerStartArray,
  getTimerLookup,
  filterAlertTriggeringTimerStarts,
);

function createCurrentTaskTimerAlert(
  timerStarts: readonly TimerStart[],
  lookupTimer: (url: TimerUrl) => Timer | undefined,
  lookupTask: (url: TaskUrl) => Task | undefined,
  alertTriggeringTimerStart: Readonly<TimerStart>,
): Alert | null {
  const {employee: employeeUrl, task: taskUrl, timer: timerUrl} = alertTriggeringTimerStart;
  const timer = timerUrl && lookupTimer(timerUrl);
  const task = lookupTask(taskUrl);

  if (!task || !timer || !timer.notifyUsersOnStart) {
    return null;
  }

  const taskTimerStarts = sortBy(
    timerStarts.filter((t) => t.task === taskUrl),
    getNormalisedDeviceTimestamp,
  );

  const now = new Date();
  const nowTimestamp = now.toISOString();
  const [, currentInterval] = computeTimerstartIntervals(taskTimerStarts, nowTimestamp);
  if (!currentInterval) {
    return null;
  }

  const {fromTimestamp} = currentInterval;
  if (timerUrl !== currentInterval.timer) {
    getFrontendSentry().captureMessage(
      `Unexpected timer mismatch from computeTimerstartIntervals, expected ${timerUrl} but got ${currentInterval.timer}`,
      "error",
    );
    return null;
  }

  const fromTime = new Date(fromTimestamp).getTime();
  const nowTime = now.getTime();
  const fiveMinutesInMillis = 5 * 60 * 1000;
  if (nowTime - fromTime < fiveMinutesInMillis) {
    return null;
  }

  return {
    fromTimestamp: new Date(fromTimestamp),
    task,
    timer,
    triggeredByUserUrl: employeeUrl,
  };
}

export const getAlerts: (state: AppState) => readonly Alert[] = createSelector(
  getTimerStartArray,
  getTimerLookup,
  getTaskLookup,
  getAlertTriggeringTimerStarts,
  (
    timerStarts: readonly TimerStart[],
    lookupTimer: (url: TimerUrl) => Timer | undefined,
    lookupTask: (url: TaskUrl) => Task | undefined,
    alertTriggeringTimerStarts: readonly TimerStart[],
  ) =>
    alertTriggeringTimerStarts
      .map(createCurrentTaskTimerAlert.bind(null, timerStarts, lookupTimer, lookupTask))
      .filter(notNull),
);
