import {Config} from "@co-common-libs/config";
import {
  CachedDailyAccumulatedCompensatory,
  Role,
  User,
  UserProfile,
  UserUrl,
} from "@co-common-libs/resources";
import {formatDuration} from "@co-common-libs/resources-utils";
import {formatDateTimeShort, initialComparator, simpleComparator} from "@co-common-libs/utils";
import {
  ColumnSpecifications,
  GenericTable,
  IconLinkButton,
  RowData,
  iconButtonColumnSpecification,
} from "@co-frontend-libs/components";
import {
  AppState,
  actions,
  getCachedDailyAccumulatedCompensatoryArray,
  getCurrentRole,
  getCustomerSettings,
  getTableSortingState,
  getUserArray,
  getUserLookup,
  getUserProfileArray,
  getUserRoleLookup,
  getUserUserProfileLookup,
} from "@co-frontend-libs/redux";
import {addDanishCountryPrefix, getRemunerationGroupLabels} from "app-utils";
import {bind} from "bind-decorator";
import bowser from "bowser";
import AlertCircleIcon from "mdi-react/AlertCircleIcon";
import CellphoneIcon from "mdi-react/CellphoneIcon";
import EmailIcon from "mdi-react/EmailIcon";
import memoize from "memoize-one";
import React from "react";
import {IntlContext, IntlShape, defineMessages} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";

const INITIALS_COLUMN_WIDTH = 88;
const USERNAME_COLUMN_WIDTH = 88;

const TABLE_SORTING_IDENTIFIER = "UserTable";

const messages = defineMessages({
  accumulatedCompensatory: {
    defaultMessage: "Opsparet afspadsering",
    id: "user-list.table-header.accumulated-compensatory",
  },
  averageHourlyCost: {
    defaultMessage: "Omk.",
  },
  cellphone: {
    defaultMessage: "Mobil",
    id: "user-list.table-header.cellphone",
  },
  cellphoneOne: {
    defaultMessage: "Mobil 1",
    id: "user-list.table-header.cellphone-one",
  },
  cellphoneTwo: {
    defaultMessage: "Mobil 2",
    id: "user-list.table-header.cellphone-two",
  },
  chauffeur: {
    defaultMessage: "Chauffør",
    id: "user-list.label.chauffeur",
  },
  email: {
    defaultMessage: "E-mail",
    id: "user-list.table-header.email",
  },
  employee: {
    defaultMessage: "Medarbejder",
    id: "user-list.label.employee",
  },
  initials: {
    defaultMessage: "Initialer",
    id: "user-list.table-header.initials",
  },
  jobber: {
    defaultMessage: "Løsarbejder",
    id: "user-list.label.jobber",
  },
  machineOperator: {
    defaultMessage: "Maskinfører",
    id: "user-list.label.machineOperator",
  },
  manager: {
    defaultMessage: "Administrator",
    id: "user-list.label.manager",
  },
  mechanic: {
    defaultMessage: "Mekaniker",
    id: "user-list.label.mekaniker",
  },
  name: {
    defaultMessage: "Navn",
    id: "user-list.table-header.name",
  },
  projectManager: {
    defaultMessage: "Projektleder",
    id: "user-list.label.project-manager",
  },
  remunerationGroup: {
    defaultMessage: "Løngruppe",
  },
  role: {
    defaultMessage: "Rolle",
    id: "user-list.table-header.role",
  },
  seniorChauffeur: {
    defaultMessage: "Senior Chauffør",
    id: "user-list.label.senior-chauffeur",
  },
  seniorEmployee: {
    defaultMessage: "Senior Medarbejder",
    id: "user-list.label.seniorEmployee",
  },
  seniorMachineOperator: {
    defaultMessage: "Senior Maskinfører",
    id: "user-list.label.seniorMachineOperator",
  },
  username: {
    defaultMessage: "Brugernavn",
    id: "user-list.table-header.username",
  },
});

type UserTableFieldID =
  | "accumulatedCompensatory"
  | "alias"
  | "averageHourlyCost"
  | "cellphone"
  | "cellphoneExtra"
  | "email"
  | "iceWarningIcon"
  | "name"
  | "remunerationGroup"
  | "role"
  | "username";

type UserTableColumnID =
  | "accumulatedCompensatory"
  | "alias"
  | "averageHourlyCost"
  | "cellphone"
  | "cellphoneExtra"
  | "cellphoneIcon"
  | "email"
  | "emailIcon"
  | "iceWarningIcon"
  | "name"
  | "remunerationGroup"
  | "role"
  | "username";

interface UserTableDataType extends RowData<UserTableFieldID, UserUrl> {
  accumulatedCompensatory: string;
  alias: string;
  averageHourlyCost: number | null;
  cellphone: string;
  email: string;
  iceWarningIcon: boolean;
  key: UserUrl;
  name: string;
  remunerationGroup: string;
  role: string;
  username: string;
}

function getRoleString(
  formatMessage: IntlShape["formatMessage"],
  employeeLabelVariant: "CHAUFFEUR" | "EMPLOYEE" | "MACHINEOPERATOR",
  role?: Role,
): string {
  if (!role) {
    return "";
  } else if (role.jobber) {
    return formatMessage(messages.jobber);
  } else if (role.mechanic) {
    return formatMessage(messages.mechanic);
  } else if (role.projectManager) {
    return formatMessage(messages.projectManager);
  } else if (role.seniorMachineOperator) {
    if (employeeLabelVariant === "MACHINEOPERATOR") {
      return formatMessage(messages.seniorMachineOperator);
    } else if (employeeLabelVariant === "EMPLOYEE") {
      return formatMessage(messages.seniorEmployee);
    } else {
      return formatMessage(messages.seniorChauffeur);
    }
  } else if (role.manager) {
    return formatMessage(messages.manager);
  } else if (role.machineOperator) {
    if (employeeLabelVariant === "MACHINEOPERATOR") {
      return formatMessage(messages.machineOperator);
    } else if (employeeLabelVariant === "EMPLOYEE") {
      return formatMessage(messages.employee);
    } else {
      return formatMessage(messages.chauffeur);
    }
  } else {
    return "";
  }
}

function renderCellphoneButton(data: UserTableDataType): JSX.Element {
  if (data.cellphone) {
    return (
      <IconLinkButton href={`tel:${addDanishCountryPrefix(data.cellphone)}`} Icon={CellphoneIcon} />
    );
  } else {
    return <IconLinkButton Icon={CellphoneIcon} />;
  }
}

function renderEmailButton(data: UserTableDataType): JSX.Element {
  if (data.email) {
    return <IconLinkButton href={`mailto:${data.email}`} Icon={EmailIcon} />;
  } else {
    return <IconLinkButton Icon={EmailIcon} />;
  }
}

function renderIceWarningIcon(data: UserTableDataType): JSX.Element {
  return data.iceWarningIcon ? (
    <div
      style={{
        color: "darkred",
      }}
    >
      <AlertCircleIcon />
    </div>
  ) : (
    <div />
  );
}

function compareAlias(a: UserTableDataType, b: UserTableDataType): number {
  return initialComparator(a.alias, b.alias);
}

function compareUsername(a: UserTableDataType, b: UserTableDataType): number {
  return initialComparator(a.username, b.username);
}

function buildColumnSpecifications(
  formatMessage: IntlShape["formatMessage"],
  onClick: (userURL: string) => void,
  userCellphoneExtra: boolean,
  currentUserIsManager: boolean,
): ColumnSpecifications<UserTableFieldID, UserTableColumnID, UserUrl, UserTableDataType> {
  return {
    accumulatedCompensatory: {
      field: "accumulatedCompensatory",
      label: formatMessage(messages.accumulatedCompensatory),
      onClick,
    },
    alias: {
      comparator: compareAlias,
      field: "alias",
      label: formatMessage(messages.initials),
      onClick,
      width: INITIALS_COLUMN_WIDTH,
    },
    averageHourlyCost: {
      field: "averageHourlyCost",
      label: formatMessage(messages.averageHourlyCost),
      onClick,
    },
    cellphone: {
      field: "cellphone",
      label:
        userCellphoneExtra && currentUserIsManager
          ? formatMessage(messages.cellphoneOne)
          : formatMessage(messages.cellphone),
      onClick,
    },
    cellphoneExtra: {
      field: "cellphoneExtra",
      label: formatMessage(messages.cellphoneTwo),
      onClick,
    },
    cellphoneIcon: iconButtonColumnSpecification({
      field: "cellphone",
      render: renderCellphoneButton,
    }),
    email: {field: "email", label: formatMessage(messages.email), onClick},
    emailIcon: iconButtonColumnSpecification({
      field: "email",
      render: renderEmailButton,
    }),
    iceWarningIcon: iconButtonColumnSpecification({
      field: "iceWarningIcon",
      render: renderIceWarningIcon,
    }),
    name: {field: "name", label: formatMessage(messages.name), onClick},
    remunerationGroup: {
      field: "remunerationGroup",
      label: formatMessage(messages.remunerationGroup),
      onClick,
    },
    role: {field: "role", label: formatMessage(messages.role), onClick},
    username: {
      comparator: compareUsername,
      field: "username",
      label: formatMessage(messages.username),
      onClick,
      width: USERNAME_COLUMN_WIDTH,
    },
  };
}

function checkAccumulatedCompensatoryInUse(customerSettings: Config): boolean {
  return Object.values(customerSettings.remunerationGroups).some(
    (override) => override.accumulateCompensatoryLimit,
  );
}

function getUserCachedDailyAccumulatedCompensatory(
  accumulatedCompensatoryInUse: boolean,
  cachedDailyAccumulatedCompensatorArray: readonly CachedDailyAccumulatedCompensatory[],
): ReadonlyMap<string, CachedDailyAccumulatedCompensatory> {
  const userCachedDailyAccumulatedCompensatory = new Map<
    string,
    CachedDailyAccumulatedCompensatory
  >();
  if (accumulatedCompensatoryInUse) {
    cachedDailyAccumulatedCompensatorArray.forEach((cachedDailyAccumulatedCompensatory) => {
      userCachedDailyAccumulatedCompensatory.set(
        cachedDailyAccumulatedCompensatory.employee,
        cachedDailyAccumulatedCompensatory,
      );
    });
  }
  return userCachedDailyAccumulatedCompensatory;
}

function buildRowData(
  userArray: readonly User[],
  userRoleLookup: (url: UserUrl) => Role | undefined,
  userUserProfileLookup: (url: UserUrl) => UserProfile | undefined,
  userCachedDailyAccumulatedCompensatory: ReadonlyMap<string, CachedDailyAccumulatedCompensatory>,
  active: boolean,
  customerSettings: Config,
  formatDateTime: (timestamp: string) => string,
  formatMessage: IntlShape["formatMessage"],
  employeeLabelVariant: "CHAUFFEUR" | "EMPLOYEE" | "MACHINEOPERATOR",
  remunerationDefaultGroup: string,
  groupLabels: ReadonlyMap<string, string>,
): readonly UserTableDataType[] {
  const result: UserTableDataType[] = [];
  userArray.forEach((user) => {
    if (user.active !== active) {
      return;
    }
    const {url} = user;
    const role = userRoleLookup(url);
    if (role && role.consultant) {
      return;
    }
    const profile = userUserProfileLookup(url);
    let accumulatedCompensatory = "";
    const cachedDailyAccumulatedCompensatory = userCachedDailyAccumulatedCompensatory.get(url);
    if (cachedDailyAccumulatedCompensatory) {
      const {minutes} = cachedDailyAccumulatedCompensatory;
      const minutesString = formatDuration(customerSettings.durationFormat, minutes);
      const computedAt = cachedDailyAccumulatedCompensatory.lastChanged
        ? formatDateTime(cachedDailyAccumulatedCompensatory.lastChanged)
        : "";
      accumulatedCompensatory = `${minutesString} (${computedAt})`;
    }

    const entry: UserTableDataType = {
      accumulatedCompensatory,
      alias: profile?.alias || "",
      averageHourlyCost: profile?.averageHourlyCost ?? null,
      cellphone: profile?.cellphone || "",
      cellphoneExtra: profile?.cellphoneExtra || "",
      email: profile?.email || "",
      iceWarningIcon:
        !profile?.iceContactLandline &&
        !profile?.iceContactMobile &&
        !profile?.iceContactWorkNumber,
      key: url,
      name: profile?.name || "",
      remunerationGroup:
        groupLabels.get(profile?.remunerationGroup || remunerationDefaultGroup) || "",
      role: getRoleString(formatMessage, employeeLabelVariant, role),
      username: user.loginIdentifier,
    };
    result.push(entry);
  });
  result.sort(
    (userA, userB) =>
      simpleComparator(userA.username, userB.username) || simpleComparator(userA.key, userB.key),
  );
  return result;
}

function computeVisibleColumns(
  mobile: boolean,
  tablet: boolean,
  isManager: boolean,
  userListColumns: {
    employee: {
      readonly desktop: readonly UserTableColumnID[];
      readonly mobile: readonly UserTableColumnID[];
      readonly tablet: readonly UserTableColumnID[];
    };
    manager: {
      readonly desktop: readonly UserTableColumnID[];
      readonly mobile: readonly UserTableColumnID[];
      readonly tablet: readonly UserTableColumnID[];
    };
  },
): readonly UserTableColumnID[] {
  const deviceType = mobile ? "mobile" : tablet ? "tablet" : "desktop";
  return userListColumns[isManager ? "manager" : "employee"][deviceType];
}

interface UserTableStateProps {
  cachedDailyAccumulatedCompensatorArray: readonly CachedDailyAccumulatedCompensatory[];
  currentRole: Role | null;
  customerSettings: Config;
  sortingState: {
    readonly sortDirection: "ASC" | "DESC";
    readonly sortKey: string | null;
  };
  userArray: readonly User[];
  userLookup: (url: UserUrl) => User | undefined;
  userProfileArray: readonly UserProfile[];
  userRoleLookup: (url: UserUrl) => Role | undefined;
  userUserProfileLookup: (url: UserUrl) => UserProfile | undefined;
}

interface UserTableDispatchProps {
  putTableSortingState: (
    identifier: string,
    sortKey: string,
    sortDirection: "ASC" | "DESC",
  ) => void;
}

interface UserTableOwnProps {
  active: boolean;
  filterString: string;
  onClick: (userURL: string) => void;
}

type UserTableProps = UserTableDispatchProps & UserTableOwnProps & UserTableStateProps;

class UserTable extends React.PureComponent<UserTableProps> {
  constructor(props: UserTableProps) {
    super(props);
    this.buildColumnSpecifications = memoize(buildColumnSpecifications);
    this.checkAccumulatedCompensatoryInUse = memoize(checkAccumulatedCompensatoryInUse);
    this.getUserCachedDailyAccumulatedCompensatory = memoize(
      getUserCachedDailyAccumulatedCompensatory,
    );
    this.computeVisibleColumns = memoize(computeVisibleColumns);
    this.buildRowData = memoize(buildRowData);
  }
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  private buildColumnSpecifications: (
    formatMessage: IntlShape["formatMessage"],
    onClick: (userURL: string) => void,
    cellphoneExtra: boolean,
    currentUserIsManager: boolean,
  ) => ColumnSpecifications<UserTableFieldID, UserTableColumnID, UserUrl, UserTableDataType>;
  private checkAccumulatedCompensatoryInUse: (customerSettings: Config) => boolean;
  private getUserCachedDailyAccumulatedCompensatory: (
    accumulatedCompensatoryInUse: boolean,
    cachedDailyAccumulatedCompensatorArray: readonly CachedDailyAccumulatedCompensatory[],
  ) => ReadonlyMap<string, CachedDailyAccumulatedCompensatory>;
  private computeVisibleColumns: (
    mobile: boolean,
    tablet: boolean,
    isManager: boolean,
    userListColumns: {
      employee: {
        readonly desktop: readonly UserTableColumnID[];
        readonly mobile: readonly UserTableColumnID[];
        readonly tablet: readonly UserTableColumnID[];
      };
      manager: {
        readonly desktop: readonly UserTableColumnID[];
        readonly mobile: readonly UserTableColumnID[];
        readonly tablet: readonly UserTableColumnID[];
      };
    },
  ) => readonly UserTableColumnID[];
  private buildRowData: (
    userArray: readonly User[],
    userRoleLookup: (url: UserUrl) => Role | undefined,
    userUserProfileLookup: (url: UserUrl) => UserProfile | undefined,
    userCachedDailyAccumulatedCompensatory: ReadonlyMap<string, CachedDailyAccumulatedCompensatory>,
    active: boolean,
    customerSettings: Config,
    formatDateTime: (timestamp: string) => string,
    formatMessage: IntlShape["formatMessage"],
    employeeLabelVariant: "CHAUFFEUR" | "EMPLOYEE" | "MACHINEOPERATOR",
    remunerationDefaultGroup: string,
    groupLabels: ReadonlyMap<string, string>,
  ) => readonly UserTableDataType[];

  @bind
  handleHeaderClick(key: UserTableColumnID): void {
    const {putTableSortingState, sortingState} = this.props;
    let direction: "ASC" | "DESC" = "ASC";
    if (sortingState.sortKey === key && sortingState.sortDirection === "ASC") {
      direction = "DESC";
    }
    putTableSortingState(TABLE_SORTING_IDENTIFIER, key, direction);
  }

  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {
      active,
      cachedDailyAccumulatedCompensatorArray,
      currentRole,
      customerSettings,
      filterString,
      onClick,
      sortingState,
      userArray,
      userLookup,
      userProfileArray,
      userRoleLookup,
      userUserProfileLookup,
    } = this.props;
    const columnSpecifications = this.buildColumnSpecifications(
      formatMessage,
      onClick,
      customerSettings.userCellphoneExtra,
      !!currentRole?.manager,
    );
    const accumulatedCompensatoryInUse = this.checkAccumulatedCompensatoryInUse(customerSettings);
    const userCachedDailyAccumulatedCompensatory = this.getUserCachedDailyAccumulatedCompensatory(
      accumulatedCompensatoryInUse,
      cachedDailyAccumulatedCompensatorArray,
    );
    const groupLabels = getRemunerationGroupLabels(
      customerSettings,
      formatMessage,
      userProfileArray,
      userLookup,
    );

    const data = this.buildRowData(
      userArray,
      userRoleLookup,
      userUserProfileLookup,
      userCachedDailyAccumulatedCompensatory,
      active,
      customerSettings,
      formatDateTimeShort,
      formatMessage,
      customerSettings.employeeLabelVariant,
      customerSettings.remunerationDefaultGroup,
      groupLabels,
    );

    const visibleColumns = this.computeVisibleColumns(
      !!bowser.mobile,
      !!bowser.tablet,
      !!currentRole?.manager,
      customerSettings.userListColumns,
    );

    return (
      <GenericTable
        columns={columnSpecifications}
        entries={data}
        filterString={filterString}
        sortBy={sortingState.sortKey as any}
        sortDirection={sortingState.sortDirection}
        visibleColumns={visibleColumns}
        onHeaderClick={this.handleHeaderClick}
      />
    );
  }
}

const ConnectedUserTable: React.ComponentType<UserTableOwnProps> = connect<
  UserTableStateProps,
  UserTableDispatchProps,
  UserTableOwnProps,
  AppState
>(
  createStructuredSelector<AppState, UserTableStateProps>({
    cachedDailyAccumulatedCompensatorArray: getCachedDailyAccumulatedCompensatoryArray,
    currentRole: getCurrentRole,
    customerSettings: getCustomerSettings,
    sortingState: getTableSortingState(TABLE_SORTING_IDENTIFIER, "username", "ASC"),
    userArray: getUserArray,
    userLookup: getUserLookup,
    userProfileArray: getUserProfileArray,
    userRoleLookup: getUserRoleLookup,
    userUserProfileLookup: getUserUserProfileLookup,
  }),
  {putTableSortingState: actions.putTableSortingState},
)(UserTable);

export {ConnectedUserTable as UserTable};
