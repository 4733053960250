import {ImportPreviewProductGroup} from "@co-common-libs/resources-utils";
import {memoizeForceReuse} from "@co-frontend-libs/utils";
import React, {useMemo} from "react";
import {useIntl} from "react-intl";
import {GenericSingleSelectionSearchDialog} from "../../../search-dialog";
import {computePreviewProductGroupsBaseChoices} from "./compute-preview-product-groups-base-choices";

// TODO(mr): next integration - make independent of e-conomic - dynamically generate messages based on passed-in settings
interface ImportProductGroupPreviewDialogProps {
  error: string | undefined;
  onCancel(): void;
  onOk(identifier: string): void;
  open: boolean;
  previewProductGroups: readonly ImportPreviewProductGroup[] | null;
  remoteUrlFavorites: ReadonlySet<string>;
}

export const ImportProductGroupPreviewDialog = React.memo(function ImportProductGroupPreviewDialog(
  props: ImportProductGroupPreviewDialogProps,
) {
  const {error, onCancel, onOk, open, previewProductGroups, remoteUrlFavorites} = props;
  const intl = useIntl();

  const [doComputeBaseChoices, reuseBaseChoices] = useMemo(
    () => memoizeForceReuse(computePreviewProductGroupsBaseChoices, []),
    [],
  );

  const getBaseChoices = open ? doComputeBaseChoices : reuseBaseChoices;
  const data = previewProductGroups
    ? getBaseChoices(previewProductGroups, remoteUrlFavorites)
    : null;

  return (
    <GenericSingleSelectionSearchDialog<string>
      data={data}
      error={error}
      mobilePrimaryLines={1}
      mobileSearchPrimaryLines={1}
      mobileSearchSecondaryLines={1}
      mobileSecondaryLines={1}
      open={open}
      searchTitle={intl.formatMessage({defaultMessage: "Søg varegrupper"})}
      sorting="SECONDARY_TEXT"
      title={intl.formatMessage({
        defaultMessage: "Vælg varegruppe",
      })}
      onCancel={onCancel}
      onOk={onOk}
    />
  );
});
