/* eslint-disable sort-keys-fix/sort-keys-fix */
import {
  CustomerUrl,
  LocationUrl,
  MachineUrl,
  PriceGroupUrl,
  ProjectUrl,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {StateMachine, assign, createMachine} from "@xstate/fsm";

interface CustomerTaskCreationContext {
  customer: CustomerUrl | null;
  customerHasFields: boolean | null;
  customerHasWorkPlaces: boolean | null;
  department: string | null;
  departmentsEnabled: boolean | null;
  fields: readonly LocationUrl[];
  location: LocationUrl | null;
  machines: readonly {
    machine: MachineUrl;
    priceGroup: PriceGroupUrl | null;
  }[];
  priceGroup: PriceGroupUrl | null;
  project: ProjectUrl | null;
  projectsEnabled: boolean | null;
  skipMachineChoice: boolean | null;
  workType: WorkTypeUrl | null;
  workTypesEnabled: boolean | null;
}

export type CustomerTaskCreationEvent =
  | {
      customer: CustomerUrl | null;
      department: string | null;
      departmentsEnabled: boolean;
      fields?: LocationUrl[];
      projectsEnabled: boolean;
      skipMachineChoice: boolean;
      type: "START";
      workTypesEnabled: boolean;
    }
  | {
      customer: CustomerUrl;
      customerHasFields: boolean;
      customerHasWorkPlaces: boolean;
      project: ProjectUrl | null;
      type: "CUSTOMER_PROJECT_SELECTED";
    }
  | {
      department: string;
      machines: readonly {
        machine: MachineUrl;
        priceGroup: PriceGroupUrl | null;
      }[];
      type: "MACHINES_SELECTED";
    }
  | {
      department: string;
      priceGroup: PriceGroupUrl | null;
      type: "WORK_TYPE_SELECTED";
      workType: WorkTypeUrl;
    }
  | {
      department: string;
      type: "DEPARTMENT_SELECTED";
    }
  | {
      fields: readonly LocationUrl[];
      location: LocationUrl | null;
      type: "LOCATION_FIELDS_SELECTED";
    }
  | {type: "CANCEL"};

type CustomerTaskCreationTypeState =
  | {
      context: CustomerTaskCreationContext & {
        customer: CustomerUrl;
        customerHasWorkPlaces: boolean;
        location: null;
        projectsEnabled: boolean;
      };
      value: "selectLocation";
    }
  | {
      context: CustomerTaskCreationContext & {
        customer: CustomerUrl;
        customerHasWorkPlaces: boolean;
        projectsEnabled: boolean;
      };
      value: "selectDepartment";
    }
  | {
      context: CustomerTaskCreationContext & {
        customer: CustomerUrl;
        customerHasWorkPlaces: boolean;
        projectsEnabled: boolean;
      };
      value: "selectMachines";
    }
  | {
      context: CustomerTaskCreationContext & {
        customer: CustomerUrl;
        customerHasWorkPlaces: boolean;
        projectsEnabled: boolean;
      };
      value: "selectWorkType";
    }
  | {
      context: CustomerTaskCreationContext & {
        customer: null;
        location: null;
        project: null;
        projectsEnabled: boolean;
      };
      value: "selectProjectOrCustomer";
    }
  | {
      context: CustomerTaskCreationContext;
      value: "initial";
    };

export type CustomerTaskCreationState = StateMachine.State<
  CustomerTaskCreationContext,
  CustomerTaskCreationEvent,
  CustomerTaskCreationTypeState
>;

export const customerTaskCreationStateMachine = createMachine<
  CustomerTaskCreationContext,
  CustomerTaskCreationEvent,
  CustomerTaskCreationTypeState
>({
  context: {
    customer: null,
    customerHasFields: null,
    customerHasWorkPlaces: null,
    department: null,
    departmentsEnabled: null,
    fields: [],
    location: null,
    machines: [],
    priceGroup: null,
    project: null,
    projectsEnabled: null,
    skipMachineChoice: null,
    workType: null,
    workTypesEnabled: null,
  },
  id: "taskCreation",
  initial: "initial",
  states: {
    initial: {
      on: {
        START: {
          target: "selectProjectOrCustomer",
          actions: assign({
            customer: (_context, event) => event.customer,
            projectsEnabled: (_context, event) => event.projectsEnabled,
            workTypesEnabled: (_context, event) => event.workTypesEnabled,
            skipMachineChoice: (_context, event) => event.skipMachineChoice,
            fields: (_context, event) => event.fields ?? [],
            location: (_context, _event) => null,
            departmentsEnabled: (_context, event) => event.departmentsEnabled,
            department: (_context, event) => event.department,
          }),
        },
      },
    },
    selectProjectOrCustomer: {
      on: {
        CUSTOMER_PROJECT_SELECTED: {
          actions: assign({
            customer: (_context, event) => event.customer,
            project: (_context, event) => event.project,
            customerHasWorkPlaces: (_context, event) => event.customerHasWorkPlaces,
            customerHasFields: (_context, event) => event.customerHasFields,
          }),
          target: "selectLocation",
        },
        CANCEL: {
          actions: "signalCancelled",
          target: "initial",
        },
      },
    },
    selectLocation: {
      on: {
        LOCATION_FIELDS_SELECTED: [
          {
            cond: (context, _event) => !!context.workTypesEnabled,
            actions: assign({
              location: (_context, event) => event.location,
              fields: (_context, event) => event.fields,
            }),
            target: "selectWorkType",
          },
          {
            cond: (context, _event) => !context.workTypesEnabled && !context.skipMachineChoice,
            actions: assign({
              location: (_context, event) => event.location,
              fields: (_context, event) => event.fields,
            }),
            target: "selectMachines",
          },
          {
            actions: [
              assign({
                location: (_context, event) => event.location,
                fields: (_context, event) => event.fields,
                workType: (_context, _event) => null,
                priceGroup: (_context, _event) => null,
                machines: (_context, _event) => [],
              }),
              "signalDone",
            ],
            target: "initial",
          },
        ],
        CANCEL: {
          actions: "signalCancelled",
          target: "initial",
        },
      },
    },
    selectWorkType: {
      on: {
        WORK_TYPE_SELECTED: [
          {
            cond: (context, event) =>
              !context.departmentsEnabled || !!(event.department || context.department),
            actions: [
              assign({
                workType: (_context, event) => event.workType,
                priceGroup: (_context, event) => event.priceGroup,
                machines: (_context, _event) => [],
                department: (context, event) => event.department || context.department,
              }),
              "signalDone",
            ],
            target: "initial",
          },
          {
            actions: [
              assign({
                workType: (_context, event) => event.workType,
                priceGroup: (_context, event) => event.priceGroup,
                machines: (_context, _event) => [],
                department: (context, event) => event.department || context.department,
              }),
            ],
            target: "selectDepartment",
          },
        ],
        CANCEL: {
          actions: "signalCancelled",
          target: "initial",
        },
      },
    },
    selectMachines: {
      on: {
        MACHINES_SELECTED: [
          {
            cond: (context, event) =>
              !context.departmentsEnabled || !!(event.department || context.department),
            actions: [
              assign({
                machines: (_context, event) => event.machines,
                workType: (_context, _event) => null,
                priceGroup: (_context, _event) => null,
                department: (context, event) => event.department || context.department,
              }),
              "signalDone",
            ],
            target: "initial",
          },
          {
            actions: [
              assign({
                machines: (_context, event) => event.machines,
                workType: (_context, _event) => null,
                priceGroup: (_context, _event) => null,
                department: (context, event) => event.department || context.department,
              }),
            ],
            target: "selectDepartment",
          },
        ],
        CANCEL: {
          actions: "signalCancelled",
          target: "initial",
        },
      },
    },
    selectDepartment: {
      on: {
        DEPARTMENT_SELECTED: {
          actions: [
            assign({
              department: (_context, event) => event.department,
            }),
            "signalDone",
          ],
          target: "initial",
        },
        CANCEL: {
          actions: "signalCancelled",
          target: "initial",
        },
      },
    },
  },
});
