import {
  CsvImportSpecification,
  getAvailableColumns,
} from "@co-common-libs/csv-import-specifications";
import {getCustomerSettings} from "@co-frontend-libs/redux";
import {TableCell, TableRow} from "@material-ui/core";
import React from "react";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {ImportColumnConfiguration, ImportColumnConfigurationWithoutUpdateExisting} from "../types";
import {getColumnConfiguration} from "./get-column-configuration";
import {ImportDropDown} from "./import-dropdown";

export function ImportDropDownRow(props: {
  headers: readonly string[];
  importColumnConfigurations: ReadonlyMap<string, ImportColumnConfiguration>;
  onImportColumnValueTypeChange: (
    columnName: string,
    configuration: ImportColumnConfigurationWithoutUpdateExisting,
  ) => void;
  selectedImportSpecification: CsvImportSpecification;
}): JSX.Element {
  const {
    headers,
    importColumnConfigurations,
    onImportColumnValueTypeChange,
    selectedImportSpecification,
  } = props;

  const customerSettings = useSelector(getCustomerSettings);

  return (
    <TableRow>
      <TableCell>
        <FormattedMessage defaultMessage="Indlæs fra:" />
      </TableCell>
      {getAvailableColumns(customerSettings, selectedImportSpecification).map((column, index) => (
        <TableCell key={column.name} style={{maxWidth: "200px"}}>
          <ImportDropDown
            columnName={column.name}
            headers={headers}
            isKeyColumn={index === 0}
            selected={getColumnConfiguration(importColumnConfigurations, column.name)}
            onChange={onImportColumnValueTypeChange}
          />
        </TableCell>
      ))}
      <TableCell />
    </TableRow>
  );
}
