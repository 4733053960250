import {Config} from "@co-common-libs/config";
import {Task, Unit, UnitUrl} from "@co-common-libs/resources";
import {PathTemplate} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {PureComponent, dateFromDateAndTime} from "app-utils";
import ImmutableDate from "bloody-immutable-date";
import _ from "lodash";
import React from "react";
import {NO_ESTIMATE_TASK_MINUTES} from "./constants";
import {DraggableTaskBlock} from "./draggable-task-block";
import {TaskWithRelations, plannedPeriod} from "./utils";

interface PlannedTasksProps {
  calendarFromTimestamp: ImmutableDate;
  calendarToTimestamp: ImmutableDate;
  completedTasks: readonly TaskWithRelations[];
  customerSettings: Config;
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  incompleteTasks: readonly TaskWithRelations[];
  normalFromTimestamp?: ImmutableDate | undefined;
  normalToTimestamp?: ImmutableDate | undefined;
  now: string;
  onLongPress: (
    event: React.TouchEvent<HTMLDivElement>,
    task: Task,
    taskBlock: HTMLDivElement,
  ) => void;
  onMouseDown: (
    event: React.MouseEvent<HTMLDivElement>,
    task: Task,
    taskBlock: HTMLDivElement,
  ) => void;
  onRequestTaskInfo?: ((position: {x: number; y: number}, task: Task) => void) | undefined;
  plannedTasks: readonly TaskWithRelations[];
  singleDateFocus?: boolean | undefined;
  unitLookup: (url: UnitUrl) => Unit | undefined;
}

export class PlannedTasks extends PureComponent<PlannedTasksProps> {
  render(): JSX.Element {
    const {
      calendarFromTimestamp,
      calendarToTimestamp,
      completedTasks,
      incompleteTasks,
      normalFromTimestamp,
      normalToTimestamp,
      now,
      onLongPress,
      onMouseDown,
      onRequestTaskInfo,
      plannedTasks,
      singleDateFocus,
      unitLookup,
    } = this.props;
    const completedIntervals = _.flatMap(completedTasks, (taskWithRelations) =>
      taskWithRelations.intervalsInPeriod.map((interval) => ({
        ...interval,
        task: taskWithRelations.task.url,
      })),
    );
    const incompleteIntervals = _.flatMap(incompleteTasks, (taskWithRelations) =>
      taskWithRelations.intervalsInPeriod.map((interval) => ({
        ...interval,
        task: taskWithRelations.task.url,
      })),
    );
    const intervals = _.sortBy(
      completedIntervals.concat(incompleteIntervals),
      (interval) => interval.fromTimestamp,
    ).map((interval) => {
      if (interval.toTimestamp) {
        return interval;
      } else {
        return {...interval, toTimestamp: now};
      }
    });
    const noEstimateTaskMinutes = NO_ESTIMATE_TASK_MINUTES;
    const taskBlocks = plannedTasks.map((taskWithRelations) => {
      const taskPeriod = plannedPeriod(
        taskWithRelations.task,
        calendarFromTimestamp,
        calendarToTimestamp,
        noEstimateTaskMinutes,
      );
      if (!taskPeriod) {
        return null;
      }
      const {fromTimestamp, toTimestamp} = taskPeriod;
      const {taskOverlapWarningAfterMinutes} = this.props.customerSettings;
      const afterFromTimestamp = new Date(fromTimestamp.valueOf());
      afterFromTimestamp.setUTCMinutes(
        afterFromTimestamp.getUTCMinutes() + taskOverlapWarningAfterMinutes,
      );
      const beforeToTimestamp = new Date(toTimestamp.valueOf());
      beforeToTimestamp.setUTCMinutes(
        beforeToTimestamp.getUTCMinutes() - taskOverlapWarningAfterMinutes,
      );
      const afterFromTimestampString = afterFromTimestamp.toISOString();
      const beforeToTimestampString = beforeToTimestamp.toISOString();
      const intervalToMatchIndex = intervals.findIndex(
        (interval) => interval.toTimestamp > afterFromTimestampString,
      );
      const overlapCandidates =
        intervalToMatchIndex !== -1 ? intervals.slice(intervalToMatchIndex) : [];
      const intervalFromMismatchIndex = overlapCandidates.findIndex(
        (interval) => interval.fromTimestamp >= beforeToTimestampString,
      );
      const overlappingIntervals =
        intervalFromMismatchIndex !== -1
          ? overlapCandidates.slice(0, intervalFromMismatchIndex)
          : overlapCandidates;
      const taskURL = taskWithRelations.task.url;
      const hasConflict = overlappingIntervals.some((interval) => interval.task !== taskURL);
      let displayToTimestamp = toTimestamp;
      if (normalFromTimestamp && normalToTimestamp && fromTimestamp && toTimestamp) {
        if (normalFromTimestamp < fromTimestamp && fromTimestamp < normalToTimestamp) {
          if (toTimestamp > normalToTimestamp) {
            displayToTimestamp = normalToTimestamp;
          }
        }
      }
      console.assert(taskWithRelations.task.date);
      return (
        <DraggableTaskBlock
          key={taskWithRelations.task.url}
          blockType="planned"
          calendarFromTimestamp={calendarFromTimestamp}
          calendarToTimestamp={calendarToTimestamp}
          customerSettings={this.props.customerSettings}
          fade={
            singleDateFocus &&
            dateFromDateAndTime(taskWithRelations.task.date as string, "00:00").valueOf() <
              calendarFromTimestamp.valueOf()
          }
          fromTimestamp={fromTimestamp}
          go={this.props.go}
          hasConflict={hasConflict}
          toTimestamp={displayToTimestamp}
          onRequestTaskInfo={onRequestTaskInfo}
          {...taskWithRelations}
          unitLookup={unitLookup}
          onDragHandleLongPress={onLongPress}
          onDragHandleMouseDown={onMouseDown}
        />
      );
    });
    return <div>{taskBlocks}</div>;
  }
}
