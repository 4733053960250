import {
  PriceGroup,
  PriceGroupUrl,
  PriceItem,
  PriceItemUrl,
  Timer,
  TimerUrl,
  WorkType,
  WorkTypeUrl,
} from "@co-common-libs/resources";

/**
 * The current criteria for whether time on a customer task with the
 * given timer should contribute to the misguided
 * "effective time"/"efficiency ratio" concepts on
 * the bookkeeping/time overview pages.
 */
export const timerMayBeBilled = (
  timerURL: TimerUrl,
  primaryTimerURL: TimerUrl,
  timerLookup: (url: TimerUrl) => Timer | undefined,
  workTypeLookup: (url: WorkTypeUrl) => Pick<WorkType, "productive"> | undefined,
  priceGroupLookup: (url: PriceGroupUrl) => Pick<PriceGroup, "priceGroupItemSet"> | undefined,
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined,
): boolean => {
  if (timerURL === primaryTimerURL) {
    return true;
  }
  const timer = timerLookup(timerURL);
  // "normal" extra buttons
  if (timer && timer.priceGroup) {
    const priceGroup = priceGroupLookup(timer.priceGroup);
    if (
      priceGroup &&
      priceGroup.priceGroupItemSet.some(
        (priceGroupItem) => priceItemLookup(priceGroupItem.priceItem)?.billable,
      )
    ) {
      return true;
    }
  }
  const workTypeURL = timer && timer.workType;
  // extra special extra buttons
  const workType = workTypeURL && workTypeLookup(workTypeURL);
  if (workType && workType.productive) {
    return true;
  }
  return false;
};
