import {Button, CircularProgress} from "@material-ui/core";
import React from "react";
import {FormattedMessage} from "react-intl";
import {PageLayout} from "./page-layout";

interface PendingInstanceProps {
  onTryAgainButton: (event: React.MouseEvent) => void;
  status: "FETCHING" | "MISSING" | "OFFLINE";
  title: JSX.Element | string;
}

export const PendingInstance = React.memo(function PendingInstance(
  props: PendingInstanceProps,
): JSX.Element {
  const {onTryAgainButton, status, title} = props;
  let message: JSX.Element | undefined;
  if (status === "FETCHING") {
    message = (
      <FormattedMessage defaultMessage="Henter fra arkiv" id="load-instance.label.fetching" />
    );
  } else if (status === "OFFLINE") {
    message = (
      <FormattedMessage
        defaultMessage="Kunne ikke forbinde til server"
        id="load-instance.label.offline"
      />
    );
  } else if (status === "MISSING") {
    message = (
      <FormattedMessage defaultMessage="Ikke fundet på server" id="load-instance.label.missing" />
    );
  }
  let tryAgainButton;
  if (status === "OFFLINE" || status === "MISSING") {
    tryAgainButton = (
      <Button color="primary" variant="contained" onClick={onTryAgainButton}>
        <FormattedMessage defaultMessage="Forsøg igen" id="load-instance.label.try-again" />
      </Button>
    );
  }
  let spinner: JSX.Element | undefined;
  if (status === "FETCHING") {
    spinner = <CircularProgress />;
  }
  return (
    <PageLayout withPadding toolbar={title}>
      <div style={{textAlign: "center"}}>
        <div>{message}</div>
        {tryAgainButton}
        {spinner}
      </div>
    </PageLayout>
  );
});
