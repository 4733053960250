import {WorkType, WorkTypeUrl} from "@co-common-libs/resources";
import {getWorkTypeString} from "@co-common-libs/resources-utils";
import {ConnectedInternalWorkTypeDialog} from "@co-frontend-libs/connected-components";
import {
  actions,
  getCurrentUserURL,
  getSettingsEntryLookupByIdentifier,
  getWorkTypeArray,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import React, {useCallback, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {SettingViewProps} from "../types";
import {DisplaySelected} from "./display-selected";

export function SelectInternalWorktype(props: SettingViewProps): JSX.Element {
  const {settingID, settingMetaData} = props;
  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const settingEntry = settingsEntryLookupByIdentifier(settingID);
  const workType: string | null = settingEntry?.data;
  const worktypeArray = useSelector(getWorkTypeArray);
  const worktypeLookup = useSelector(getWorkTypeLookup);
  const workTypeInstance = useMemo(() => {
    return worktypeArray.find((w) => w.identifier === workType);
  }, [workType, worktypeArray]) as WorkType;

  const workTypeName = useMemo(() => {
    return getWorkTypeString(workTypeInstance);
  }, [workTypeInstance]);

  const [workTypeDialogOpen, setWorkTypeDialogOpen] = useState(false);
  const setWorkTypeDialogOpenTrue = useCallWithTrue(setWorkTypeDialogOpen);
  const setWorkTypeDialogOpenFalse = useCallWithFalse(setWorkTypeDialogOpen);

  const dispatch = useDispatch();
  const currentUserURL = useSelector(getCurrentUserURL);
  const handleWorkTypeDialogOk = useCallback(
    (workTypeURL: WorkTypeUrl | null) => {
      setWorkTypeDialogOpen(false);
      const newValue = workTypeURL ? worktypeLookup(workTypeURL)?.identifier : null;
      if (settingEntry) {
        dispatch(
          actions.update(settingEntry.url, [
            {member: "changedBy", value: currentUserURL},
            {member: "data", value: newValue},
          ]),
        );
      }
    },
    [currentUserURL, dispatch, settingEntry, worktypeLookup],
  );

  const handleWorkTypeNone = useCallback(() => {
    handleWorkTypeDialogOk(null);
  }, [handleWorkTypeDialogOk]);

  return (
    <>
      <DisplaySelected
        currentValue={workTypeName}
        settingEntry={settingEntry}
        settingID={settingID}
        settingMetaData={settingMetaData}
        onClick={setWorkTypeDialogOpenTrue}
      />
      <ConnectedInternalWorkTypeDialog
        open={workTypeDialogOpen}
        onCancel={setWorkTypeDialogOpenFalse}
        onNone={handleWorkTypeNone}
        onOk={handleWorkTypeDialogOk}
      />
    </>
  );
}
