import {Product} from "@co-common-libs/resources";
import {getUnitString} from "@co-common-libs/resources-utils";
import {DecimalField} from "@co-frontend-libs/components";
import {getCustomerSettings, getUnitLookup} from "@co-frontend-libs/redux";
import {TableCell, TableRow} from "@material-ui/core";
import React, {useCallback} from "react";
import {FormattedNumber} from "react-intl";
import {useSelector} from "react-redux";
import {correctedCountColumnStyle, countColumnStyle, unitColumnStyle} from "./constants";

interface ProductUseLineProps {
  correctedCount?: number | undefined;
  count?: number | undefined;
  editDisabled: boolean;
  identifier: string;
  invoiceCorrectionEnabled: boolean;
  note?: string;
  onChange: (identifier: string, value: number | null) => void;
  product: Product;
  striped?: boolean;
}

export const ProductUseLine = React.memo(function ProductUseLine(
  props: ProductUseLineProps,
): JSX.Element {
  const {
    correctedCount,
    count,
    editDisabled,
    identifier,
    invoiceCorrectionEnabled,
    note,
    onChange,
    product,
    striped,
  } = props;

  const handleChange = useCallback(
    (value: number | null) => onChange(identifier, value),
    [identifier, onChange],
  );
  const unitLookup = useSelector(getUnitLookup);
  const customerSettings = useSelector(getCustomerSettings);

  const {catalogNumber, name} = product;
  const unit = getUnitString(product, unitLookup);
  const inputField = (
    <DecimalField
      fullWidth
      hideError
      decimalPlaces={customerSettings.materialDecimals}
      disabled={editDisabled}
      margin="dense"
      maxDigits={9}
      value={correctedCount != null ? correctedCount : null}
      onChange={handleChange}
    />
  );
  const tableRowColumnStyle: React.CSSProperties = striped
    ? {
        backgroundColor: "rgba(129, 212, 249, 0.4)",
      }
    : {};

  return (
    <TableRow>
      <TableCell style={tableRowColumnStyle}>
        {name} ({catalogNumber})
      </TableCell>
      <TableCell style={{...tableRowColumnStyle, ...countColumnStyle}}>
        <FormattedNumber value={count || 0} />
      </TableCell>
      {invoiceCorrectionEnabled ? (
        <TableCell style={{...tableRowColumnStyle, ...correctedCountColumnStyle}}>
          {inputField}
        </TableCell>
      ) : null}
      <TableCell style={{...tableRowColumnStyle, ...unitColumnStyle}}>{unit}</TableCell>
      {customerSettings.showNoteOnInvoiceLineTable ? (
        <TableCell style={tableRowColumnStyle}>{note}</TableCell>
      ) : null}
    </TableRow>
  );
});
