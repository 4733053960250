import {Config} from "@co-common-libs/config";
import {AppState, getCustomerSettings, getUpdateReady} from "@co-frontend-libs/redux";
import {common as commonColors} from "@material-ui/core/colors";
import {GeolocationMap} from "app-components";
import {updateAutoReloadCallback} from "app-utils";
import {globalConfig} from "frontend-global-config";
import React from "react";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";

interface FullscreenGeolocationMapStateProps {
  customerSettings: Config;
  updateReady: boolean;
}

type FullscreenGeolocationMapProps = FullscreenGeolocationMapStateProps;

class FullscreenGeolocationMap extends React.Component<FullscreenGeolocationMapProps> {
  componentDidUpdate(prevProps: FullscreenGeolocationMapProps, _prevState: unknown): void {
    if (this.props.updateReady && !prevProps.updateReady) {
      updateAutoReloadCallback();
    }
  }
  render(): JSX.Element | null {
    if (!this.props.customerSettings.geolocation.enabled) {
      return null;
    }
    const imageStyle: React.CSSProperties = {
      border: "solid #000",
      borderWidth: "1px 1px 0 0",
      bottom: 0,
      display: "block",
      height: "auto",
      maxHeight: 96,
      maxWidth: 256,
      position: "absolute",
      width: "auto",
      zIndex: 500,
    };
    const logo = <img src={globalConfig.logoURL} style={imageStyle} />;
    return (
      <div style={{backgroundColor: commonColors.white, height: "100%"}}>
        <div className="scrollable" style={{height: "100%", width: "100%"}}>
          <GeolocationMap />
        </div>
        {logo}
      </div>
    );
  }
}

const ConnectedFullscreenGeolocationMap = connect<
  FullscreenGeolocationMapStateProps,
  object,
  object,
  AppState
>(
  createStructuredSelector<AppState, FullscreenGeolocationMapStateProps>({
    customerSettings: getCustomerSettings,
    updateReady: getUpdateReady,
  }),
  {},
)(FullscreenGeolocationMap);

export default ConnectedFullscreenGeolocationMap;
