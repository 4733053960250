export function getNormalisedSearchParamsString(searchParams: URLSearchParams): string {
  const search = searchParams.toString();
  if (search && search.charAt(0) !== "?") {
    return `?${search}`;
  }
  return search;
}

export function getSearchObject(searchString: string): {[key: string]: string} {
  const searchObject: {[key: string]: string} = {};
  if (searchString) {
    new URLSearchParams(searchString).forEach((value, key) => {
      searchObject[key] = value;
    });
  }
  return searchObject;
}
