import {Patch, Task} from "@co-common-libs/resources";
import {
  getInputSpecificationsMap,
  getMaterialUsePatchFromLogEntries,
} from "@co-common-libs/resources-utils";
import _ from "lodash";
import {getCurrentUserURL} from "../../complex-selectors";
import {ProvisionaryCommand} from "../../resources/actions";
import {
  getCustomerSettings,
  getPriceItemLookup,
  getProductLookup,
  getReportingSpecificationLookup,
  getUnitLookup,
} from "../../resources/selectors";
import {ResourcesAuthenticationMiddlewareAPI} from "../types";

export function updatePriceItemProductUsesOnLogChanges(
  newTask: Task | null,
  oldTask: Task | undefined,
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  _command: ProvisionaryCommand,
): Patch<Task> | null {
  if (
    !newTask ||
    newTask.recordedInC5 ||
    newTask.archivable ||
    !newTask.reportingSpecification ||
    !newTask.reportingLog ||
    _.isEqual(newTask.reportingLog, oldTask?.reportingLog)
  ) {
    return null;
  }

  const state = middlewareApi.getState();

  const reportingSpecificationLookup = getReportingSpecificationLookup(state);
  const logSpecification = reportingSpecificationLookup(newTask.reportingSpecification);

  const customerSettings = getCustomerSettings(state);

  if (!logSpecification) {
    return null;
  }

  const inputSpecificationsMap = getInputSpecificationsMap(logSpecification);
  const priceItemLookup = getPriceItemLookup(state);
  const unitLookup = getUnitLookup(state);
  const productLookup = getProductLookup(state);
  const currentUserURL = getCurrentUserURL(state);

  const patch = getMaterialUsePatchFromLogEntries(
    logSpecification,
    customerSettings,
    newTask,
    undefined,
    inputSpecificationsMap,
    priceItemLookup,
    unitLookup,
    productLookup,
    currentUserURL,
  );

  if (patch.length) {
    return patch;
  } else {
    return null;
  }
}
