import {schema} from "@co-common-libs/config";
import _ from "lodash";
import React, {useMemo} from "react";
import {SettingValueDisplayProps} from "../types";

type CustomerRequiredField = (typeof schema.properties.customerRequiredFields.items.enum)[number];

export function DisplayCustomerRequiredFields(props: SettingValueDisplayProps): JSX.Element {
  const customerRequiredFields: CustomerRequiredField[] = useMemo(
    () => props.settingEntry?.data || [],
    [props.settingEntry?.data],
  );

  return <div>{_.sortBy(customerRequiredFields).join(", ")}</div>;
}
