import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {PureComponent} from "app-utils";
import React from "react";
import {FormattedMessage, FormattedNumber} from "react-intl";

export const capitalizeFirstLetter = (string: string): string => {
  if (!string) {
    return "";
  }
  return string[0].toUpperCase() + string.slice(1);
};

interface ReportOverviewRowProps {
  bold?: boolean;
  hourDecimals: number;
  hours: number;
  share?: number | undefined;
  title: string;
}

export class ReportOverviewRow extends PureComponent<ReportOverviewRowProps> {
  render(): JSX.Element {
    const {hourDecimals} = this.props;
    const title = this.props.bold ? (
      <strong>{capitalizeFirstLetter(this.props.title)}</strong>
    ) : (
      capitalizeFirstLetter(this.props.title)
    );
    const hours = this.props.bold ? (
      <strong>
        <FormattedNumber
          maximumFractionDigits={hourDecimals}
          minimumFractionDigits={hourDecimals}
          value={this.props.hours}
        />
      </strong>
    ) : (
      <FormattedNumber
        maximumFractionDigits={hourDecimals}
        minimumFractionDigits={hourDecimals}
        value={this.props.hours}
      />
    );
    let share;
    if (this.props.share !== undefined) {
      share = this.props.bold ? (
        <strong>
          {
            <span>
              <FormattedNumber
                maximumFractionDigits={1}
                minimumFractionDigits={1}
                value={this.props.share}
              />
              %
            </span>
          }
        </strong>
      ) : (
        <span>
          <FormattedNumber
            maximumFractionDigits={1}
            minimumFractionDigits={1}
            value={this.props.share}
          />
          %
        </span>
      );
    }
    return (
      <TableRow>
        <TableCell>{title}</TableCell>
        <TableCell style={{textAlign: "right"}}>{hours}</TableCell>
        <TableCell style={{textAlign: "right"}}>{share}</TableCell>
      </TableRow>
    );
  }
}

export class ReportOverviewTable extends PureComponent<object & {children: React.JSX.Element}> {
  render(): JSX.Element {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell style={{textAlign: "right"}}>
              <FormattedMessage defaultMessage="Timer" id="report-display-card.label.hours" />
            </TableCell>
            <TableCell style={{textAlign: "right"}}>
              <FormattedMessage defaultMessage="Andel" id="report-display-card.label.share" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{this.props.children}</TableBody>
      </Table>
    );
  }
}
