import {
  PriceItem,
  PriceItemUrl,
  ReportingSpecification,
  Task,
  Unit,
  UnitUrl,
} from "@co-common-libs/resources";
import {actions} from "@co-frontend-libs/redux";
import {Dispatch} from "@reduxjs/toolkit";
import {areReadOnlyMaterialsUsed} from "./are-read-only-materials-used";

export const skippedLogChange = (
  logSpecification: ReportingSpecification | null | undefined,
  checked: boolean,
  task: Task,
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined,
  unitLookup: (url: UnitUrl) => Unit | undefined,
  setMaterialWarningDialogOpen: (value: boolean) => void,
  dispatch: Dispatch,
): void => {
  if (logSpecification) {
    if (!checked) {
      const readOnlyMaterialsUsed = areReadOnlyMaterialsUsed(
        task,
        logSpecification,
        priceItemLookup,
        unitLookup,
      );
      if (readOnlyMaterialsUsed) {
        setMaterialWarningDialogOpen(true);
        return;
      }
    }
    dispatch(actions.update(task.url, [{member: "logSkipped", value: checked}]));
  }
};
