import {simpleArraysEqual} from "@co-common-libs/utils";

/**
 * Memoize one result/one set of arguments.
 * Return both a "normal" memoised function, and a function with the same
 * type signature which just returns the previous result.
 *
 * Intended for use in dialog implementations, where the component should be
 * kept mounted on transition from open to closed to show the closing
 * animation, but we don't want to spend time on computing content for closed
 * dialogs.
 *
 * @deprecated
 * `useConditionalMemo` provides a better interface for use in function
 * components.
 */
export function memoizeForceReuse<T extends any[], R>(
  fn: (...args: T) => R,
  initialResult: R,
  arraysEqual: (a: T, b: T) => boolean = simpleArraysEqual,
): [(...args: T) => R, (..._args: T) => R] {
  let oldArgs: T | undefined;
  let oldResult = initialResult;

  const memoisedFunction = (...args: T): R => {
    if (oldArgs && arraysEqual(args, oldArgs)) {
      return oldResult;
    }
    const result = fn(...args);
    oldArgs = args;
    oldResult = result;
    return result;
  };

  const forceReuseFunction = (..._args: T): R => {
    return oldResult;
  };

  return [memoisedFunction, forceReuseFunction];
}
