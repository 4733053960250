import {Task, UserProfile, UserUrl} from "@co-common-libs/resources";
import {
  EconomicProjectSyncEnabledCustomerSettings,
  economicProjectImportEnabled,
} from "@co-common-libs/utils";
import {EconomicUserProfileIssue} from "../types";

export function economicMachineOperatorIssues({
  customerSettings,
  task,
  userProfileLookup,
}: {
  customerSettings: EconomicProjectSyncEnabledCustomerSettings;
  task: Pick<Task, "machineOperator">;
  userProfileLookup: (url: UserUrl) => UserProfile | undefined;
}): readonly EconomicUserProfileIssue[] {
  const issues: EconomicUserProfileIssue[] = [];

  if (task.machineOperator && economicProjectImportEnabled(customerSettings)) {
    const userProfile = userProfileLookup(task.machineOperator);

    if (userProfile) {
      if (userProfile.barred) {
        issues.push({
          category: "error",
          instance: userProfile,
          issueType: "barred",
          resourceName: "userProfile",
        });
      }
      if (!userProfile.externalEconomicIdentifier) {
        issues.push({
          category: "error",
          instance: userProfile,
          issueType: "missingRemoteIdentifier",
          resourceName: "userProfile",
        });
      }
    }
  }

  return issues;
}
