import {
  LocationUrl,
  PatchOperation,
  ReportingLocation,
  ReportingLocations,
  ReportingSpecification,
  Task,
} from "@co-common-libs/resources";
import {notNull} from "@co-common-libs/utils";
import {
  actions,
  getLocationLookup,
  getLocationUseLogArray,
  getOrderLookup,
} from "@co-frontend-libs/redux";
import _ from "lodash";
import BarleyIcon from "mdi-react/BarleyIcon";
import LocationCircleIcon from "mdi-react/LocationCircleIcon";
import PlusIcon from "mdi-react/PlusIcon";
import React, {useCallback, useMemo, useState} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuid} from "uuid";
import {FloatingActionButtons} from "../floating-action-buttons";
import {ValuesState} from "./edit-reporting-location-dialog";
import {MultiFieldWithLogDataDialog} from "./field-with-log-data-dialogs";
import {MultiNonFieldLocationWithLogDataDialog} from "./non-field-location-with-log-data-dialogs";

function getLocationsWithLogLocations(
  reportingLocations: ReportingLocations,
  type: ReportingLocation["type"],
): Set<LocationUrl> {
  const usedFieldsUrls = new Set<LocationUrl>();

  for (const logLocation of Object.values(reportingLocations)) {
    if (logLocation.type === type && logLocation.location) {
      usedFieldsUrls.add(logLocation.location);
    }
  }
  return usedFieldsUrls;
}

interface AddReportingLocationsFabProps {
  disabled: boolean;
  logSpecification: ReportingSpecification;
  task: Task;
  type: ReportingLocation["type"];
}

export const AddReportingLocationsFab = React.memo(function AddReportingLocationsFab(
  props: AddReportingLocationsFabProps,
): JSX.Element {
  const {disabled, logSpecification, task, type} = props;

  const intl = useIntl();

  const locationUseLogArray = useSelector(getLocationUseLogArray);
  const orderLookup = useSelector(getOrderLookup);
  const locationLookup = useSelector(getLocationLookup);

  const dispatch = useDispatch();

  const showFieldButton = logSpecification.fieldsUsedFor !== "unused";

  const usedLocations = useMemo(
    () => getLocationsWithLogLocations(task.reportingLocations, type),
    [task.reportingLocations, type],
  );

  const order = task.order ? orderLookup(task.order) : undefined;
  const customerUrl = order?.customer;

  const lastUsedLocations = useMemo(() => {
    const machineOperatorUrl = task.machineOperator;
    if (customerUrl && machineOperatorUrl) {
      const locationUse = locationUseLogArray.find(
        (l) => l.customer === customerUrl && l.user === machineOperatorUrl,
      );
      if (locationUse?.locations?.length) {
        return new Set<LocationUrl>(locationUse.locations);
      }
    }
    return undefined;
  }, [customerUrl, locationUseLogArray, task.machineOperator]);

  const [fieldDialogOpen, setFieldDialogOpen] = useState(false);
  const [locationDialogOpen, setLocationDialogOpen] = useState(false);

  const typeFilteredLogLocationArray = useMemo(
    () =>
      Object.values(task.reportingLocations).filter(
        ({type: locationType}) => locationType === type,
      ),
    [task.reportingLocations, type],
  );

  const locationDataMap = useMemo((): ReadonlyMap<LocationUrl, ValuesState> => {
    return new Map<LocationUrl, ValuesState>(
      typeFilteredLogLocationArray
        .filter(({type: locationType}) => locationType === type)
        .filter(
          (entry: ReportingLocation): entry is ReportingLocation & {location: LocationUrl} =>
            entry.location !== undefined,
        )
        .map(({location, values}) => [location, values || {}]),
    );
  }, [typeFilteredLogLocationArray, type]);

  const handleFieldDialogRequest = useCallback(() => {
    setFieldDialogOpen(true);
  }, []);

  const handleLocationDialogRequest = useCallback(() => {
    setLocationDialogOpen(true);
  }, []);

  const handleSelectionDialogCancel = useCallback((): void => {
    setFieldDialogOpen(false);
    setLocationDialogOpen(false);
  }, []);

  const handleSelectionDialogOk = useCallback(
    (logData: ReadonlyMap<LocationUrl, {readonly [identifier: string]: unknown}>): void => {
      const currentLocations = new Set(typeFilteredLogLocationArray.map(({location}) => location));
      const currentMaxOrder = _.max(
        typeFilteredLogLocationArray.map(({order: locationOrder}) => locationOrder),
      );
      const nextOrder = currentMaxOrder !== undefined ? currentMaxOrder + 1 : 0;

      const patch = Array.from(logData)
        .map(([locationUrl, values], index): PatchOperation<Task> | null => {
          if (currentLocations.has(locationUrl)) {
            return null;
          }
          const location = locationLookup(locationUrl);
          const customer = location?.customer || customerUrl;
          if (!customer) {
            return null;
          }
          const newEntry: ReportingLocation = {
            customer,
            location: locationUrl,
            order: nextOrder + index,
            productUses: {},
            type,
            values,
          };
          const newIdentifier = uuid();

          const patchOperation: PatchOperation<Task> = {
            path: ["reportingLocations", newIdentifier],
            value: newEntry,
          };
          return patchOperation;
        })
        .filter(notNull);
      if (patch.length) {
        dispatch(actions.update(task.url, patch));
      }
      setFieldDialogOpen(false);
      setLocationDialogOpen(false);
    },
    [customerUrl, dispatch, locationLookup, typeFilteredLogLocationArray, task.url, type],
  );

  const buttons = useMemo(
    () =>
      showFieldButton
        ? [
            {
              buttonIcon: <LocationCircleIcon />,
              name: "add-location",
              onClick: handleLocationDialogRequest,
              tooltipTitle: intl.formatMessage({defaultMessage: "Sted"}),
            },
            {
              buttonIcon: <BarleyIcon />,
              name: "add-field",
              onClick: handleFieldDialogRequest,
              tooltipTitle: intl.formatMessage({defaultMessage: "Mark"}),
            },
          ]
        : [
            {
              buttonIcon: <PlusIcon />,
              name: "add-location",
              onClick: handleLocationDialogRequest,
              tooltipTitle: intl.formatMessage({defaultMessage: "Sted"}),
            },
          ],
    [handleFieldDialogRequest, handleLocationDialogRequest, intl, showFieldButton],
  );

  return (
    <>
      <FloatingActionButtons
        buttons={buttons}
        disabled={disabled}
        name="add-log-place-buttons"
        variant="component"
      />
      <MultiFieldWithLogDataDialog
        customerUrl={customerUrl || null}
        lastUsedLocations={lastUsedLocations}
        locationDataMap={locationDataMap}
        logSpecification={logSpecification}
        multiSelectMax={50}
        open={fieldDialogOpen}
        readonlySet={usedLocations}
        type={type}
        onCancel={handleSelectionDialogCancel}
        onOk={handleSelectionDialogOk}
      />
      <MultiNonFieldLocationWithLogDataDialog
        customerUrl={customerUrl || null}
        lastUsedLocations={lastUsedLocations}
        locationDataMap={locationDataMap}
        logSpecification={logSpecification}
        multiSelectMax={50}
        open={locationDialogOpen}
        readonlySet={usedLocations}
        type={type}
        onCancel={handleSelectionDialogCancel}
        onOk={handleSelectionDialogOk}
      />
    </>
  );
});
