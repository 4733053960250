import {PathParameters} from "./types";

export function getPathBuilder(
  pathTemplate: string,
): (parameters: {[key: string]: string}) => string | null {
  const pathParts = pathTemplate.split("/");
  // Build array where
  // - even positions represent string to use as-is and
  // - odd positions represent parameter names
  const interleaved: string[] = [""];
  for (let j = 0; j < pathParts.length; j += 1) {
    // Interleaved has an odd length except after processing the *last* path
    // part if that's a parameter...

    console.assert(j + 1 === pathParts.length || interleaved.length % 2 === 1);
    const part = pathParts[j];
    if (part.startsWith(":")) {
      interleaved.push(part.substring(1));
      if (j + 1 < pathParts.length) {
        interleaved.push("/");
      }
    } else {
      interleaved[interleaved.length - 1] += part;
      if (j + 1 < pathParts.length) {
        interleaved[interleaved.length - 1] += "/";
      }
    }

    console.assert(j + 1 === pathParts.length || interleaved.length % 2 === 1);
  }
  if (interleaved.length === 1) {
    console.assert(interleaved[0] === pathTemplate);
    return (_parameters: {[key: string]: string}) => pathTemplate;
  } else if (interleaved.length === 2) {
    const [basePath, parameterName] = interleaved;
    return (parameters: {[key: string]: string}) =>
      parameters[parameterName] != null ? basePath + parameters[parameterName] : null;
  } else {
    return (parameters: {[key: string]: string}) => {
      const result = interleaved.slice();

      for (let j = 1; j < result.length; j += 2) {
        // visit the "parameter name" entries only
        const key = result[j];
        const value = parameters[key];
        if (value !== undefined) {
          console.assert(typeof value === "string");
          result[j] = value;
        } else {
          return null;
        }
      }
      return result.join("");
    };
  }
}

export function createMultiPathBuilder<T extends string>(
  pathTemplates: readonly T[],
): (pathTemplate: T, parameters: PathParameters) => string | null {
  const pathBuilders = new Map<T, (parameters: {[key: string]: string}) => string | null>();
  for (let i = 0; i < pathTemplates.length; i += 1) {
    const pathTemplate = pathTemplates[i];
    pathBuilders.set(pathTemplate, getPathBuilder(pathTemplate));
  }
  return (pathTemplate: T, parameters: {[key: string]: string}) => {
    const pathBuilder = pathBuilders.get(pathTemplate);
    if (pathBuilder) {
      return pathBuilder(parameters);
    } else {
      return null;
    }
  };
}
