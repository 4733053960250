/* eslint-disable react/no-unescaped-entities */
import React from "react";

export class TermsOfService extends React.PureComponent {
  // eslint-disable-next-line class-methods-use-this
  render(): JSX.Element {
    return (
      <div>
        <h1 style={{lineHeight: "48px", overflowWrap: "break-word"}}>
          CustomOffice Abonnementsvilkår
        </h1>
        <h2 style={{lineHeight: "36px", overflowWrap: "break-word"}}>
          1. ACCEPT AF ABONNEMENT OG VILKÅR
        </h2>
        <p>
          1.1 Nærværende abonnementsvilkår (herefter "Vilkår") tiltrædes ved at acceptere en
          ordrebekræftelse på køb af abonnement for CustomOffice, ved at anvende applikationen eller
          services eller ved på anden måde at tilkendegive accept heraf, og gælder mellem
          CustomOffice ApS, CVR-nummer 37098329, Toldboden 3, 8800 Viborg (herefter "CustomOffice")
          og kunden (herefter "Kunden"). Hvis Kunden er en juridisk person accepteres disse vilkår
          på vegne af Kunden. Denne service er <em>business to business</em>.
        </p>
        <h2 style={{lineHeight: "36px", overflowWrap: "break-word"}}>
          2. ABONNEMENTETS VARIGHED OG FAKTURERING
        </h2>
        <p>
          2.1 Abonnementet træder i kraft ved bestilling og løber indtil det opsiges i
          overensstemmelse med disse Vilkår.
        </p>
        <p>
          2.2 Første faktureringsperiode løber fra bestillingsdatoen. Herefter faktureres månedsvis
          forud, med mindre andet følger af aftale eller vilkår for det specifikke produkt.
        </p>
        <p>2.3 Efter bestilling er der for nye kunder en gratis fortrydelsesret på 30 dage.</p>
        <h2 style={{lineHeight: "36px", overflowWrap: "break-word"}}>
          3. ABONNEMENTETS OMFANG OG ANVENDELSE
        </h2>
        <p>
          3.1 Kunden opnår i overensstemmelse med disse Vilkår en ikke-eksklusiv adgang til at
          anvende driftsstyringsprogrammet CustomOffice i valgte variant (herefter under et
          "Applikationen"), der stilles til rådighed online som <em>software as a service</em>.
          Kunden erhverver ikke Applikationen eller en kopi eller del heraf og opnår ikke licens til
          at afvikle Applikationen undtagen som <em>software as a service</em>.
        </p>
        <p>
          3.2 Kundens abonnement giver Kunden adgang til at bruge Applikationen til den variant, der
          fremgår af de specifikke produkter.
        </p>
        <p>
          3.3 På CustomOffices hjemmeside findes en oversigt over produktvarianter. For enkelte
          ydelser kan der være tilknyttet selvstændige betingelser, der skal accepteres i tillæg til
          disse Vilkår før de kan anvendes.
        </p>
        <p>
          3.4 Adgangen til at anvende Applikationen gælder udelukkende for Kunden og dennes
          rådgivere, og Applikationen må ikke benyttes for andre end Kunden eller til at foretage
          databehandling eller yde andre ydelser for andre end Kunden. Kunden indestår for og har
          det fulde ansvar for de tredjeparter, som Kunden giver adgang til Applikationen eller som
          bruger Kundens <em>log in</em> detaljer.
        </p>
        <p>
          3.5 Med undtagelse af det, der fremgår af punkt 3.4, er Kunden ikke berettiget til at
          overdrage abonnementet til tredjemand, hverken helt eller delvist, eller til at give
          adgang til Applikationen til tredjemand.
        </p>
        <p>
          3.6 Kunden skal sikre, at Applikationen ikke bliver anvendt på en måde, som kan skade
          CustomOffices navn, omdømme eller goodwill, eller som er i strid med relevant lovgivning
          eller anden regulering.
        </p>
        <h2 style={{lineHeight: "36px", overflowWrap: "break-word"}}>
          4. PRISER OG BETALINGSBETINGELSER
        </h2>
        <p>4.1 Betalingsbetingelser er 7 dage netto fra fakturadato.</p>
        <p>
          4.2 Såfremt betaling ikke er modtaget inden 10 dage efter rykker 2, spærres adgangen til
          Applikationen og fakturaen sendes til inkasso. Adgangen til Applikationen åbnes igen efter
          modtaget betaling, medmindre CustomOffice forinden har ophævet abonnementet.
        </p>
        <p>
          4.3 Kunden accepterer, at fakturaer og rykkere sendt pr. e-mail til den af Kunden opgivne
          e-mail-adresse skal anses for leverede, når de er afsendt af CustomOffice.
        </p>
        <p>
          4.4 De til enhver tid gældende priser kan findes på CustomOffices hjemmeside og kan ændres
          ved opslag på hjemmesiden med en måneds varsel til udgangen af et kvartal. Samme gælder
          for ændringer til sammensætning og indhold af abonnementstyper og varianter. Alle priser
          er ekskl. moms.
        </p>
        <h2 style={{lineHeight: "36px", overflowWrap: "break-word"}}>5. OPHØR</h2>
        <p>
          5.1 Kunden kan opsige abonnementet efter endt faktureringsperiode, dog minimum, løbende
          måned + 1 måned ved at skrive til salg@customoffice.dk.
        </p>
        <p>
          5.2 CustomOffice kan opsige abonnementet med 6 måneders varsel til udløbet af et
          kalenderkvartal, eller uden varsel ved Kundens væsentlige misligholdelse af disse Vilkår
          eller ved Kundens konkurs eller insolvens.
        </p>
        <h2 style={{lineHeight: "36px", overflowWrap: "break-word"}}>6. KUNDENS DATA</h2>
        <p>
          6.1 Parterne er enige om, at Kunden ejer og kan frit disponere over egne data i
          Applikationen.
        </p>
        <p>
          6.2 CustomOffice forbeholder sig ret til at slette Kundens data 90 dage efter
          abonnementets ophør uanset årsagen hertil, og CustomOffice har ingen forpligtelser til at
          opbevare data efter dette tidspunkt. Ønsker Kunden at få en kopi af al data skal dette
          oplyses af Kunden i forbindelse med opsigelsen. CustomOffice forbeholder sig retten til at
          tage betaling for en sådan service. Prisen vil bl.a. være afhængig af Kundens ønsker og
          mængden af data.
        </p>
        <p>
          6.3 CustomOffice er berettiget til at opbevare Kundens data efter ophør med henblik på at
          anvende disse i anonymiseret form til statistik og analyse.
        </p>
        <p>
          6.4 CustomOffice kan i ganske særlige tilfælde, hvor det efter CustomOffices vurdering
          skønnes forsvarligt og rimeligt, for eksempel ud fra en værditabsbetragtning, give
          tredjemand og myndigheder adgang til Kundes data, herunder i forbindelse med dom,
          myndighedskrav, Kundens konkurs, dødsfald eller lignende.
        </p>
        <h2 style={{lineHeight: "36px", overflowWrap: "break-word"}}>7. DRIFTSSTABILITET</h2>
        <p>
          7.1 CustomOffice tilstræber højst mulig driftsstabilitet, men er uden ansvar for nedbrud
          eller driftsforstyrrelser, herunder for driftsforstyrrelser forårsaget af faktorer uden
          for CustomOffices kontrol. Herved forstås bl.a. strømsvigt, fejl på udstyr,
          internetforbindelser, telekommunikationsforbindelser eller lignende. Applikationen og
          servicen leveres, som den er og forefindes, og CustomOffice fraskriver sig enhver garanti,
          tilsikring, indeståelse, anprisning eller andre vilkår, uanset direkte eller indirekte.
        </p>
        <p>
          7.2 I tilfælde af nedbrud eller forstyrrelser tilstræber CustomOffice i at genskabe normal
          drift hurtigst muligt.
        </p>
        <p>
          7.3 Planlagte afbrydelser vil fortrinsvis blive placeret i tidsrummet kl. 21.00-06.00 CET.
          Skulle det blive nødvendigt at afbryde adgangen til Applikationen uden for det anførte
          tidsrum, vil dette blive varslet forinden i muligt omfang.
        </p>
        <h2 style={{lineHeight: "36px", overflowWrap: "break-word"}}>8. ÆNDRINGER</h2>
        <p>
          8.1 CustomOffice er berettiget til løbende at foretage opdateringer og forbedringer af
          Applikationen. CustomOffice er ligeledes berettiget til at ændre sammensætning og
          opbygning af Applikationen og ydelser. Sådanne opdateringer, forbedringer og ændringer kan
          ske med eller uden varsel, og kan påvirke services, herunder informationer og data
          uploadet til eller afgivet af Applikationen.
        </p>
        <h2 style={{lineHeight: "36px", overflowWrap: "break-word"}}>
          9. IMMATERIELLE RETTIGHEDER
        </h2>
        <p>
          9.1 Applikationen og information, der afgives fra Applikationen, undtagen Kundens data, er
          beskyttet af ophavsret og andre immaterielle rettigheder og tilhører eller er licenseret
          til CustomOffice. Individuelt udarbejdet programmel tilhører ligeledes CustomOffice,
          medmindre andet skriftligt er aftalt. Kunden skal give CustomOffice meddelelse om enhver
          aktuel eller potentiel krænkelse af CustomOffices immaterielle rettigheder eller
          uautoriseret brug af Applikationen, som Kunden får kendskab til.
        </p>
        <p>9.2 Der sker ingen overdragelse af immaterielle rettigheder til Kunden.</p>
        <p>
          9.3 I forhold til materiale, der uploades af Kunden og alle Kundens data, giver Kunden
          CustomOffice, og dennes leverandører, en tilladelse og global licens, der er tilstrækkelig
          til at CustomOffice forsvarligt kan afvikle og drifte Applikationen og opfylde sine
          forpligtelser samt foretage markedsføring overfor Kunden. Kunden indestår for, at det
          materiale, der uploades, ikke krænker tredjemands rettigheder, og ikke indeholder
          materiale, der kan virke stødende eller er i strid med relevant lovgivning eller anden
          regulering.
        </p>
        <h2 style={{lineHeight: "36px", overflowWrap: "break-word"}}>10. OVERDRAGELSE</h2>
        <p>
          10.1 CustomOffice har ret til at overdrage sine rettigheder og forpligtelser overfor
          Kunden til koncernforbundet selskab eller tredjemand.
        </p>
        <p>
          10.2 Kunden accepterer, at CustomOffice er berettiget til at anvende underleverandører i
          alle forhold, herunder til afvikling og drift af Applikationen og til opbevaring af
          Kundens data.
        </p>
        <h2 style={{lineHeight: "36px", overflowWrap: "break-word"}}>11. CUSTOMOFFICES ANSVAR</h2>
        <p>
          11.1 CustomOffice fraskriver sig ethvert ansvar i relation til disse Vilkår, services
          eller brug af Applikationen, uanset om dette opstår i kontrakt eller udenfor kontrakt,
          herunder for driftstab, følgeskader eller andre indirekte tab, tab af data, tab begrundet
          i produktansvar eller tab, der er opstået som følge af simpel uagtsomhed.
        </p>
        <p>
          11.2 CustomOffice er ikke ansvarlig for de tredjepartsløsninger, der er tilgængelige
          og/eller integreres med Applikationen. CustomOffice kan således ikke holdes ansvarlig for
          rigtigheden, fuldstændigheden, kvaliteten og pålideligheden af informationerne og heller
          ikke resultaterne, som opnås gennem disse tredjepartsløsninger. Tilsvarende kan
          CustomOffice ikke holdes ansvarlig for tredjepartsløsningernes tilgængelighed, sikkerhed
          eller funktionalitet, herunder for mulige skader og/eller tab forårsaget af
          tredjepartsløsninger. Det påhviler Kunden at føre bevis for, at et af Kunden lidt tab ikke
          kan tilskrives tredjepartsløsninger.
        </p>
        <p>
          11.3 Uanset typen af tab eller ansvarsgrundlaget er CustomOffices samlede ansvar
          beløbsmæssigt begrænset til Kundens betaling i 12 måneder før det ansvarspådragende
          forholds indtræden, dog i alle tilfælde minimum DKK 10.000. Kunden påtager sig at friholde
          CustomOffice for produktansvarsskader, tredjemands tab og øvrige krav fra tredjemand som
          følge af Kundens brug af Applikationen.
        </p>
        <p>
          11.4 Kunden accepterer at skadesløsholde CustomOffice mod ethvert krav eller tab, der
          skyldes produktansvar, tab hos tredjeparter eller ansvar for tredjeparter, i det omfang
          det hidrører fra Kundens brug af Applikationen.
        </p>
        <h2 style={{lineHeight: "36px", overflowWrap: "break-word"}}>
          12. TAVSHEDSPLIGT OG DATASIKKERHED
        </h2>
        <p>
          12.1 CustomOffice behandler alene Kundens data, efter dennes instruks og således ikke til
          egne, uvedkommende formål.
        </p>
        <p>
          12.2 CustomOffice har tavshedspligt om alle informationer, CustomOffice måtte komme i
          besiddelse af om Kunden, og er ikke berettiget til at videregive sådanne informationer til
          tredjemand, medmindre sådan information er offentlig tilgængelig, eller hvor CustomOffice
          har fået informationen fra en tredjepart udenfor fortrolighed, eller hvor CustomOffice er
          forpligtet til at videregive informationen ifølge lovgivning eller efter pålæg fra en
          myndighed eller domstol.
        </p>
        <p>
          12.3 CustomOffice har truffet de fornødne tekniske og organisatoriske
          sikkerhedsforanstaltninger mod, at oplysninger i Applikationen hændeligt eller ulovligt
          tilintetgøres, fortabes eller forringes samt mod, at de kommer til uvedkommendes kendskab,
          misbruges eller i øvrigt behandles i strid med lov om behandling af personoplysninger.
        </p>
        <p>
          12.4 CustomOffice giver på Kundens anmodning – og mod betaling af CustomOffices til enhver
          tid gældende timetakster for sådant arbejde – tilstrækkelige oplysninger til, at Kunden
          kan påse, at de nævnte tekniske og organisatoriske sikkerhedsforanstaltninger er truffet.
        </p>
        <p>
          12.5 I det omfang Kunden anvender information, brugernavne eller kodeord der vedrører
          tredjeparts information eller services i forhold til CustomOffice, indestår Kunden for, at
          videregivelse af sådan information og CustomOffices behandling af sådan information ikke
          krænker rettigheder eller aftale med tredjemand. Kunden skal skadesløsholde CustomOffice
          for ethvert tab i forbindelse med denne bestemmelse
        </p>
        <h2 style={{lineHeight: "36px", overflowWrap: "break-word"}}>13. VILKÅRSÆNDRINGER</h2>
        <p>
          13.1 CustomOffice er berettiget til at ændre disse Vilkår i enhver henseende. De til
          enhver tid gældende Vilkår vil være tilgængelige på CustomOffices hjemmeside. CustomOffice
          tilsigter at give rimeligt varsel (1 måned) i forbindelse med enhver ændring, ved opslag
          på hjemmesiden og direkte meddelelse via e-mail. Brug af Applikationen efter en ændring af
          disse Vilkår udgør en accept af sådanne ændrede Vilkår.
        </p>
        <h2 style={{lineHeight: "36px", overflowWrap: "break-word"}}>14. TVISTER</h2>
        <p>
          14.1 Disse Vilkår er undergivet dansk ret og enhver tvist, der udspringer af abonnementet,
          herunder disse Vilkår, skal anlægges ved Retten i Aarhus.
        </p>
      </div>
    );
  }
}
