import {CustomerUrl, MachineUrl, PriceGroupUrl, WorkTypeUrl} from "@co-common-libs/resources";
import {getCustomerSettings} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {IconButton, TableCell, TableRow, useTheme} from "@material-ui/core";
import AlertCircleIcon from "mdi-react/AlertCircleIcon";
import InformationOutlineIcon from "mdi-react/InformationOutlineIcon";
import React, {useState} from "react";
import {FormattedNumber} from "react-intl";
import {useSelector} from "react-redux";
import {FuelSurchargePeriodMissingDialog} from "../invoice-line-table/fuel-surcharge-period-missing-dialog";
import {SurchargeReasonDialog} from "../invoice-line-table/surcharge-reason-dialog";
import {CORRECTED_COLUMN_WIDTH, COUNT_COLUMN_WIDTH} from "./constants";

interface RouteTaskPricePercentSurchargeRowProps {
  missingSpecificationEntry: boolean;
  text: string;
  uses: ReadonlySet<{
    readonly customer: CustomerUrl | null;
    readonly machine?: MachineUrl | null;
    readonly variant?: PriceGroupUrl | null;
    readonly workType?: WorkTypeUrl | null;
  }>;
  value: number | null;
}

export const RouteTaskPricePercentSurchargeRow = React.memo(
  function RouteTaskPricePercentSurchargeRow(
    props: RouteTaskPricePercentSurchargeRowProps,
  ): JSX.Element {
    const {missingSpecificationEntry, text, uses, value} = props;

    const customerSettings = useSelector(getCustomerSettings);

    const theme = useTheme();

    const [infoDialogOpen, setInfoDialogOpen] = useState(false);
    const setInfoDialogOpenTrue = useCallWithTrue(setInfoDialogOpen);
    const setInfoDialogOpenFalse = useCallWithFalse(setInfoDialogOpen);

    const [missingSpecificationDialogOpen, setMissingSpecificationDialogOpen] = useState(false);
    const setMissingSpecificationDialogOpenTrue = useCallWithTrue(
      setMissingSpecificationDialogOpen,
    );
    const setMissingSpecificationDialogOpenFalse = useCallWithFalse(
      setMissingSpecificationDialogOpen,
    );

    return (
      <TableRow>
        <TableCell>
          &nbsp;&nbsp;&nbsp;&nbsp; {text}
          {missingSpecificationEntry ? (
            <IconButton onClick={setMissingSpecificationDialogOpenTrue}>
              <AlertCircleIcon color={theme.palette.error.main} />
            </IconButton>
          ) : null}
          <IconButton onClick={setInfoDialogOpenTrue}>
            <InformationOutlineIcon />
          </IconButton>
        </TableCell>
        <TableCell style={{textAlign: "right", width: COUNT_COLUMN_WIDTH}}>
          {value !== null ? <FormattedNumber value={value} /> : null}
        </TableCell>
        <TableCell style={{width: CORRECTED_COLUMN_WIDTH}} />
        <TableCell>%</TableCell>
        {customerSettings.showNoteOnInvoiceLineTable ? <TableCell /> : null}
        <SurchargeReasonDialog open={infoDialogOpen} uses={uses} onClose={setInfoDialogOpenFalse} />
        <FuelSurchargePeriodMissingDialog
          open={missingSpecificationDialogOpen}
          onClose={setMissingSpecificationDialogOpenFalse}
        />
      </TableRow>
    );
  },
);
