import {
  ResourceTypeUnion,
  SprayLog,
  SprayLogReport,
  Task,
  TransportLog,
  TransportLogReport,
  UserUrl,
  YieldLog,
  YieldLogReport,
} from "@co-common-libs/resources";
import {instanceURL} from "frontend-global-config";
import {v4 as uuid} from "uuid";

export const buildTransportLogReport = (
  currentUserURL: UserUrl,
  task: Task,
  transportLogArray: readonly TransportLog[],
  create: (instance: ResourceTypeUnion) => void,
): void => {
  const taskURL = task.url;
  const transportLog = taskURL ? transportLogArray.find((t) => t.task === taskURL) : null;
  if (!transportLog) {
    return;
  }

  const id = uuid();
  const url = instanceURL("transportLogReport", id);
  const deviceTimestamp = new Date().toISOString();

  const newTransportLogReport: TransportLogReport = {
    createdBy: currentUserURL,
    deviceTimestamp,
    id,
    transportlog: transportLog.url,
    url,
  };
  create(newTransportLogReport);
};

export const buildSprayLogReport = (
  currentUserURL: UserUrl,
  task: Task,
  sprayLogArray: readonly SprayLog[],
  create: (instance: ResourceTypeUnion) => void,
): void => {
  const taskURL = task.url;
  const sprayLog = taskURL ? sprayLogArray.find((t) => t.task === taskURL) : null;
  if (!sprayLog) {
    return;
  }

  const id = uuid();
  const url = instanceURL("sprayLogReport", id);
  const deviceTimestamp = new Date().toISOString();
  const newSprayLogReport: SprayLogReport = {
    createdBy: currentUserURL,
    deviceTimestamp,
    id,
    spraylog: sprayLog.url,
    url,
  };
  create(newSprayLogReport);
};

export const buildYieldLogReport = (
  currentUserURL: UserUrl,
  task: Task,
  yieldLogArray: readonly YieldLog[],
  create: (instance: ResourceTypeUnion) => void,
): void => {
  const taskURL = task.url;
  const yieldLog = taskURL ? yieldLogArray.find((t) => t.task === taskURL) : null;
  if (!yieldLog) {
    return;
  }

  const id = uuid();
  const url = instanceURL("yieldLogReport", id);
  const deviceTimestamp = new Date().toISOString();
  const newTransportLogReport: YieldLogReport = {
    createdBy: currentUserURL,
    deviceTimestamp,
    id,
    url,
    yieldlog: yieldLog.url,
  };
  create(newTransportLogReport);
};
