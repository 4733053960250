import {BarcodeFormat, getBarcodeScanner} from "@co-frontend-libs/utils";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  InputAdornment,
  InputBase,
  Toolbar,
} from "@material-ui/core";
import {TransitionHandlerProps} from "@material-ui/core/transitions";
import bowser from "bowser";
import CloseIcon from "mdi-react/CloseIcon";
import SearchIcon from "mdi-react/SearchIcon";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDebounce} from "use-debounce";
import {BarcodeScannerIconButton} from "../../barcode-scanner-icon-button";
import {OfflineAwareAppBar} from "../../offline-aware-app-bar";
import {SlideUpTransition} from "../../slide-up-transition";
import {TrimTextField} from "../../trim-text-field";
import {typedMemo} from "../../types";
import {ErrorMessage} from "../error-message";
import {Loading} from "../loading";
import {useDialogStyles} from "../styles";
import {EntryData, FilterVariant, MaybeSearchResultEntryData, categoriesWithIcons} from "../types";
import {
  categoryComparator,
  filterData,
  getUniqueFilterResult,
  primaryIdentifierComparator,
  primaryTextComparator,
  secondaryIdentifierComparator,
  secondaryTextComparator,
} from "../utils";
import {SingleSelectionList} from "./single-selection-list";

export interface SingleSelectionDialogProps<Identifier extends string>
  extends TransitionHandlerProps {
  addLabel?: string;
  addNamedLabel?: string;
  addShortLabel?: string;
  addUnnamedLabel?: string;
  barcodeScannerFormats?: readonly BarcodeFormat[] | null | undefined;
  data: readonly EntryData<Identifier>[] | null;
  error?: string | undefined;
  filterVariant?: FilterVariant;
  fullscreen?: boolean | undefined;

  mobilePrimaryLines: 1 | 2;
  mobileSearchPrimaryLines: 1 | 2;
  mobileSearchSecondaryLines: 1 | 2;
  mobileSecondaryLines: 0 | 1 | 2;

  noneLabel?: string | undefined;
  onAdd?: ((searchString: string) => void) | undefined;
  onCancel: () => void;
  onNone?: (() => void) | undefined;
  onOk: (identifier: Identifier) => void;
  open: boolean;
  searchTitle: string;
  sorting?: "PRIMARY_IDENTIFIER" | "PRIMARY_TEXT" | "SECONDARY_IDENTIFIER" | "SECONDARY_TEXT";
  title: JSX.Element | string;
}

const SingleSelectionDialog = typedMemo(function SingleSelectionDialog<Identifier extends string>(
  props: SingleSelectionDialogProps<Identifier>,
): JSX.Element {
  const {formatMessage} = useIntl();

  const {
    addLabel,
    addNamedLabel,
    addShortLabel,
    addUnnamedLabel = formatMessage({defaultMessage: "Opret"}),
    barcodeScannerFormats,
    data,
    filterVariant = "AND",
    fullscreen,
    mobilePrimaryLines,
    mobileSearchPrimaryLines,
    mobileSearchSecondaryLines,
    mobileSecondaryLines,
    noneLabel = formatMessage({defaultMessage: "Ingen"}),
    onAdd,
    onCancel,
    onEnter,
    onEntered,
    onEntering,
    onExit,
    onExited,
    onExiting,
    onNone,
    onOk,
    open,
    searchTitle,
    sorting = "PRIMARY_TEXT",
    title,
  } = props;

  const withIcons = useMemo(
    () =>
      onAdd !== undefined ||
      onNone !== undefined ||
      data?.some((entry) => categoriesWithIcons.has(entry.category)) ||
      false,
    [data, onAdd, onNone],
  );

  const [filterString, setFilterString] = useState("");
  useEffect(() => {
    if (!open) {
      setFilterString("");
    }
  }, [open]);

  const handleFilterFieldChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setFilterString(event.target.value);
    },
    [],
  );

  const debounceMilliseconds = 200;
  const [debouncedFilterString] = useDebounce(filterString, debounceMilliseconds);

  const trimmedFilterString = debouncedFilterString.trim();

  const comparator =
    sorting === "PRIMARY_TEXT"
      ? primaryTextComparator
      : sorting === "SECONDARY_TEXT"
        ? secondaryTextComparator
        : sorting === "PRIMARY_IDENTIFIER"
          ? primaryIdentifierComparator
          : secondaryIdentifierComparator;

  const sortedData = useMemo(
    () => (data || []).slice().sort((a, b) => categoryComparator(a, b) || comparator(a, b)),
    [comparator, data],
  );

  const filteredData = useMemo(
    (): readonly MaybeSearchResultEntryData<Identifier>[] =>
      filterData(sortedData, filterVariant, trimmedFilterString),
    [filterVariant, sortedData, trimmedFilterString],
  );

  const handleFilterFieldKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>): void => {
      if (event.key !== "Enter") {
        return;
      }
      const entry = getUniqueFilterResult(filteredData, trimmedFilterString);
      if (entry && !entry.disabled) {
        event.preventDefault();
        onOk(entry.identifier);
      }
    },
    [filteredData, trimmedFilterString, onOk],
  );

  const handleAdd = useCallback(() => {
    if (onAdd) {
      onAdd(filterString);
    }
  }, [filterString, onAdd]);

  const handleBarcodeScannerResult = useCallback(
    (text: string) => {
      if (!text) {
        return;
      }
      const trimmedBarcodeText = text.trim();
      const barcodeScanResultFilteredData = filterData(
        sortedData,
        filterVariant,
        trimmedBarcodeText,
      );
      const entry = getUniqueFilterResult(barcodeScanResultFilteredData, trimmedBarcodeText);
      if (entry && !entry.disabled) {
        onOk(entry.identifier);
      } else {
        setFilterString(trimmedBarcodeText);
      }
    },
    [filterVariant, onOk, sortedData],
  );

  const classes = useDialogStyles();

  const enterExitCallbacks: Partial<DialogProps> = {};
  if (onEnter) {
    enterExitCallbacks.onEnter = onEnter;
  }
  if (onEntered) {
    enterExitCallbacks.onEntered = onEntered;
  }
  if (onEntering) {
    enterExitCallbacks.onEntering = onEntering;
  }
  if (onExit) {
    enterExitCallbacks.onExit = onExit;
  }
  if (onExited) {
    enterExitCallbacks.onExited = onExited;
  }
  if (onExiting) {
    enterExitCallbacks.onExiting = onExiting;
  }

  const fullscreenLayout = fullscreen ?? !!(bowser.mobile || bowser.tablet);
  const barcodeScanner = barcodeScannerFormats?.length
    ? getBarcodeScanner(barcodeScannerFormats)
    : null;
  const barcodeScannerInputEndAdornment = barcodeScanner ? (
    <InputAdornment position="end">
      <BarcodeScannerIconButton
        barcodeScanner={barcodeScanner}
        onScanResult={handleBarcodeScannerResult}
      />
    </InputAdornment>
  ) : null;
  if (fullscreenLayout) {
    return (
      <Dialog
        fullScreen
        open={open}
        TransitionComponent={SlideUpTransition}
        onClose={onCancel}
        {...enterExitCallbacks}
      >
        <OfflineAwareAppBar className={classes.appBar}>
          <Toolbar>
            <IconButton color="inherit" edge="start" onClick={onCancel}>
              <CloseIcon />
            </IconButton>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                autoFocus
                classes={{
                  input: classes.inputInput,
                  root: classes.inputRoot,
                }}
                endAdornment={barcodeScannerInputEndAdornment}
                placeholder={searchTitle}
                value={filterString}
                onChange={handleFilterFieldChange}
                onKeyDown={handleFilterFieldKeyDown}
              />
            </div>
            {onAdd ? (
              <Button color="inherit" onClick={handleAdd}>
                {addShortLabel || addLabel || formatMessage({defaultMessage: "Opret"})}
              </Button>
            ) : null}
          </Toolbar>
        </OfflineAwareAppBar>
        <Toolbar />
        {props.error ? (
          <ErrorMessage error={props.error} />
        ) : !data ? (
          <Loading />
        ) : (
          <SingleSelectionList
            fullscreenLayout
            addLabel="ADD-NOT-USED-NOW"
            entries={filteredData}
            isSearchResult={!!trimmedFilterString}
            mobilePrimaryLines={mobilePrimaryLines}
            mobileSearchPrimaryLines={mobileSearchPrimaryLines}
            mobileSearchSecondaryLines={mobileSearchSecondaryLines}
            mobileSecondaryLines={mobileSecondaryLines}
            noneLabel={noneLabel}
            withIcons={withIcons}
            onNone={onNone}
            onSelect={onOk}
          />
        )}
      </Dialog>
    );
  } else {
    return (
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        scroll="paper"
        onClose={onCancel}
        {...enterExitCallbacks}
      >
        <DialogTitle>
          {title}
          <TrimTextField
            autoFocus
            fullWidth
            InputProps={{
              endAdornment: barcodeScannerInputEndAdornment,
            }}
            margin="dense"
            placeholder={searchTitle}
            value={filterString}
            variant="outlined"
            onChange={setFilterString}
            onKeyDown={handleFilterFieldKeyDown}
          />
        </DialogTitle>
        <DialogContent dividers className={classes.noPaddingNoOverflow}>
          {props.error ? (
            <ErrorMessage error={props.error} />
          ) : !data ? (
            <Loading />
          ) : (
            <SingleSelectionList
              addLabel={
                addLabel ||
                (onAdd && trimmedFilterString
                  ? addNamedLabel ||
                    formatMessage({defaultMessage: 'Opret "{name}"'}, {name: trimmedFilterString})
                  : addUnnamedLabel)
              }
              entries={filteredData}
              isSearchResult={!!trimmedFilterString}
              mobilePrimaryLines={mobilePrimaryLines}
              mobileSearchPrimaryLines={mobileSearchPrimaryLines}
              mobileSearchSecondaryLines={mobileSearchSecondaryLines}
              mobileSecondaryLines={mobileSecondaryLines}
              noneLabel={noneLabel}
              withIcons={withIcons}
              onAdd={onAdd ? handleAdd : undefined}
              onNone={onNone}
              onSelect={onOk}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onCancel}>
            <FormattedMessage defaultMessage="Fortryd" id="dialog.label.cancel" />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
});

export {SingleSelectionDialog};
