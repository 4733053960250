import {AccumulatedCompensatory, User, UserProfile} from "@co-common-libs/resources";
import {HOUR_MINUTES, dateToString, formatDate, formatDateTime} from "@co-common-libs/utils";
import {
  DateField,
  DecimalField,
  DeleteDialog,
  ResponsiveDialog,
  ResponsiveInfoDialog,
} from "@co-frontend-libs/components";
import {actions, getCustomerSettings} from "@co-frontend-libs/redux";
import {useCallWithFalse} from "@co-frontend-libs/utils";
import {
  Card,
  CardHeader,
  DialogContent,
  Fab,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {getEmployeeRemunerationGroup} from "app-utils";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import DeleteIcon from "mdi-react/DeleteIcon";
import PlusIcon from "mdi-react/PlusIcon";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {FormattedMessage, FormattedNumber, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuid} from "uuid";

const MAX_ENTRIES = 10;

interface RoundingWarningDialogProps {
  onClose: () => void;
  open: boolean;
  resultingDecimalHours: number | undefined;
  resultingMinutes: number | undefined;
}

function RoundingWarningDialog(props: RoundingWarningDialogProps): JSX.Element {
  const {onClose, open, resultingDecimalHours, resultingMinutes} = props;
  const intl = useIntl();
  const hours = Math.floor((resultingMinutes || 0) / HOUR_MINUTES);
  const minutes = (resultingMinutes || 0) % HOUR_MINUTES;
  return (
    <ResponsiveInfoDialog
      open={open}
      title={intl.formatMessage({
        defaultMessage: "Værdi rundet af til minutter",
      })}
      onClose={onClose}
    >
      <DialogContent>
        <FormattedMessage
          defaultMessage="Værdi rundet af til {hours} timer og {minutes} minutter: {decimalHours} timer"
          values={{
            decimalHours: intl.formatNumber(resultingDecimalHours || 0, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }),
            hours: intl.formatNumber(hours),
            minutes: intl.formatNumber(minutes),
          }}
        />
      </DialogContent>
    </ResponsiveInfoDialog>
  );
}

interface AccumulatedCompensatoryCreateDialogProps {
  accumulatedHours: number | null;
  date: string | null;
  hours: number | null;
  onCancel: () => void;
  onOk: (date: string, hours: number, accumulatedHours?: number) => void;
  open: boolean;
  usesAccumulation: boolean;
}

function AccumulatedCompensatoryCreateDialog(
  props: AccumulatedCompensatoryCreateDialogProps,
): JSX.Element {
  const {
    accumulatedHours: propsAccumulatedHours,
    date: propsDate,
    hours: propsHours,
    onCancel,
    onOk,
    open,
    usesAccumulation,
  } = props;

  const intl = useIntl();

  const [date, setDate] = useState<string | null>(propsDate);
  const [hours, setHours] = useState<number | null>(propsHours);
  const [accumulatedHours, setAccumulatedHours] = useState<number | null>(propsAccumulatedHours);

  useEffect(() => {
    if (open) {
      setDate(propsDate);
      setHours(propsHours);
      setAccumulatedHours(propsAccumulatedHours);
    }
  }, [open, propsAccumulatedHours, propsDate, propsHours]);

  const handleOk = useCallback(() => {
    if (!open || !date || hours == null) {
      return;
    }
    if (!usesAccumulation) {
      onOk(date, hours);
    } else if (accumulatedHours != null) {
      onOk(date, hours, accumulatedHours);
    }
  }, [open, date, hours, usesAccumulation, accumulatedHours, onOk]);

  const GRID_FULL_WIDTH = 12;
  const GRID_HALF_WIDTH = 6;

  return (
    <ResponsiveDialog
      okDisabled={!date || hours == null || (usesAccumulation && accumulatedHours == null)}
      open={open}
      title={intl.formatMessage({defaultMessage: "Afspadseringssaldo"})}
      onCancel={onCancel}
      onOk={handleOk}
    >
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item sm={usesAccumulation ? GRID_FULL_WIDTH : GRID_HALF_WIDTH} xs={GRID_FULL_WIDTH}>
            <DateField fullWidth showButton margin="dense" value={date} onChange={setDate} />
          </Grid>
          <Grid item sm={usesAccumulation ? GRID_FULL_WIDTH : GRID_HALF_WIDTH} xs={GRID_FULL_WIDTH}>
            <DecimalField
              autoFocus
              fullWidth
              label={intl.formatMessage({defaultMessage: "Saldo, timer"})}
              margin="dense"
              value={hours}
              onChange={setHours}
            />
          </Grid>
          {usesAccumulation ? (
            <Grid item xs={GRID_FULL_WIDTH}>
              <DecimalField
                fullWidth
                label={intl.formatMessage({
                  defaultMessage: "Totalt indsat, timer",
                })}
                margin="dense"
                value={accumulatedHours}
                onChange={setAccumulatedHours}
              />
            </Grid>
          ) : null}
        </Grid>
      </DialogContent>
    </ResponsiveDialog>
  );
}

interface DeleteIconButtonProps {
  date: string;
  hours: number;
  url: string;
}

function DeleteIconButton(props: DeleteIconButtonProps): JSX.Element {
  const {date, hours, url} = props;

  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    setDeleteDialogOpen(true);
  }, []);

  const handleDeleteDialogOk = useCallback(() => {
    setDeleteDialogOpen(false);
    if (url) {
      dispatch(actions.remove(url));
    }
  }, [dispatch, url]);

  const handleDeleteDialogCancel = useCallback(() => {
    setDeleteDialogOpen(false);
  }, []);

  const {formatNumber} = useIntl();

  return (
    <>
      <IconButton onClick={handleClick}>
        <DeleteIcon />
      </IconButton>
      <DeleteDialog
        open={deleteDialogOpen}
        onCancel={handleDeleteDialogCancel}
        onOk={handleDeleteDialogOk}
      >
        <FormattedMessage
          defaultMessage="Slet saldo på {hours} timer med primo dato {date}?"
          id="user-profile.text.delete-dialog-question"
          values={{
            date: formatDate(date),
            hours: formatNumber(hours, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }),
          }}
        />
      </DeleteDialog>
    </>
  );
}

interface AccumulatedCompensatoryHistoryCardProps {
  user: User;
  userAccumulatedCompensatoryArray: readonly AccumulatedCompensatory[];
  userProfile: UserProfile;
}

export function AccumulatedCompensatoryHistoryCard(
  props: AccumulatedCompensatoryHistoryCardProps,
): JSX.Element {
  const {user, userAccumulatedCompensatoryArray, userProfile} = props;

  const dispatch = useDispatch();

  const customerSettings = useSelector(getCustomerSettings);

  const [createDialogOpenWithDate, setCreateDialogOpenWithDate] = useState<string | null>(null);

  const [roundingWarningData, setRoundingWarningData] = useState<{
    decimalHours: number;
    minutes: number;
  } | null>(null);

  const [roundingWarningOpen, setRoundingWarningOpen] = useState(false);
  const setRoundingWarningOpenFalse = useCallWithFalse(setRoundingWarningOpen);

  const employeeRemunerationGroup = getEmployeeRemunerationGroup(customerSettings, userProfile);

  const remunerationGroup = customerSettings.remunerationGroups[employeeRemunerationGroup] || {};

  const usesAccumulation =
    remunerationGroup.accumulateCompensatoryLimitType === "accumulatedAdditions" &&
    !!remunerationGroup.accumulateCompensatoryLimit;

  const handleFabClick = useCallback(() => {
    setCreateDialogOpenWithDate(dateToString(new Date()));
  }, []);

  const handleCreateDialogOk = useCallback(
    (date: string, hours: number, accumulatedHours?: number) => {
      if (!createDialogOpenWithDate) {
        return;
      }
      setCreateDialogOpenWithDate(null);

      const minutes = Math.round(hours * HOUR_MINUTES);
      const accumulatedAdditionsMinutes =
        accumulatedHours == null ? null : Math.round(accumulatedHours * HOUR_MINUTES);
      const id = uuid();
      const newURL = instanceURL("accumulatedCompensatory", id);
      const instance: AccumulatedCompensatory = {
        accumulatedAdditionsMinutes,
        date,
        employee: user.url,
        id,
        minutes,
        url: newURL,
      };
      dispatch(actions.create(instance));
      const decimals = 2;
      const resultingHours = _.round(minutes / HOUR_MINUTES, decimals);
      const desiredHours = _.round(hours, decimals);
      if (resultingHours !== desiredHours) {
        setRoundingWarningData({decimalHours: resultingHours, minutes});
        setRoundingWarningOpen(true);
      }
    },
    [createDialogOpenWithDate, dispatch, user.url],
  );

  const handleCreateDialogCancel = useCallback(() => {
    setCreateDialogOpenWithDate(null);
  }, []);

  const sorted = useMemo(
    () =>
      _.sortBy(userAccumulatedCompensatoryArray, [
        (entry) => [entry.date, entry.lastChanged],
      ]).reverse(),
    [userAccumulatedCompensatoryArray],
  );
  const limited = sorted.length > MAX_ENTRIES ? sorted.slice(0, MAX_ENTRIES) : sorted;

  const latestEntry: AccumulatedCompensatory | undefined = sorted[0];

  const rows = limited.map((entry) => {
    return (
      <TableRow key={entry.url}>
        <TableCell>{formatDate(entry.date)}</TableCell>
        <TableCell style={{textAlign: "right"}}>
          <FormattedNumber
            maximumFractionDigits={2}
            minimumFractionDigits={2}
            value={entry.minutes / HOUR_MINUTES}
          />
        </TableCell>
        {usesAccumulation ? (
          <TableCell style={{textAlign: "right"}}>
            {entry.accumulatedAdditionsMinutes != null ? (
              <FormattedNumber
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                value={entry.accumulatedAdditionsMinutes / HOUR_MINUTES}
              />
            ) : null}
          </TableCell>
        ) : null}
        <TableCell>{formatDateTime(entry.lastChanged)}</TableCell>
        <TableCell
          style={{
            padding: 0,
            verticalAlign: "center",
            width: 48,
          }}
        >
          <DeleteIconButton
            date={entry.date}
            hours={entry.minutes / HOUR_MINUTES}
            url={entry.url}
          />
        </TableCell>
      </TableRow>
    );
  });

  const normalColumns = 4;

  const entriesRemovedRow =
    sorted.length !== limited.length ? (
      <TableRow>
        <TableCell colSpan={normalColumns + (usesAccumulation ? 1 : 0)}>...</TableCell>
      </TableRow>
    ) : null;

  const decimals = 2;

  return (
    <>
      <Card>
        <div style={{position: "relative"}}>
          <Fab
            size="small"
            style={{
              position: "absolute",
              right: 16,
              top: 16,
            }}
            onClick={handleFabClick}
          >
            <PlusIcon />
          </Fab>
        </div>
        <CardHeader title={<FormattedMessage defaultMessage="Afspadseringssaldo, historik" />} />
        <Table size="small" style={{backgroundColor: "#fff"}}>
          <TableHead style={{whiteSpace: "nowrap"}}>
            <TableRow>
              <TableCell>
                <FormattedMessage defaultMessage="Ultimo" />
              </TableCell>
              <TableCell style={{textAlign: "right"}}>
                <FormattedMessage defaultMessage="Saldo, timer" />
              </TableCell>
              {usesAccumulation ? (
                <TableCell style={{textAlign: "right"}}>
                  <FormattedMessage defaultMessage="Totalt indsat, timer" />
                </TableCell>
              ) : null}
              <TableCell>
                <FormattedMessage defaultMessage="Oprettet" />
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows}
            {entriesRemovedRow}
          </TableBody>
        </Table>
      </Card>
      <AccumulatedCompensatoryCreateDialog
        accumulatedHours={
          latestEntry?.accumulatedAdditionsMinutes != null
            ? _.round(latestEntry.accumulatedAdditionsMinutes / HOUR_MINUTES, decimals)
            : null
        }
        date={createDialogOpenWithDate}
        hours={
          latestEntry?.minutes != null
            ? _.round(latestEntry.minutes / HOUR_MINUTES, decimals)
            : null
        }
        open={!!createDialogOpenWithDate}
        usesAccumulation={usesAccumulation}
        onCancel={handleCreateDialogCancel}
        onOk={handleCreateDialogOk}
      />
      <RoundingWarningDialog
        open={roundingWarningOpen}
        resultingDecimalHours={roundingWarningData?.decimalHours}
        resultingMinutes={roundingWarningData?.minutes}
        onClose={setRoundingWarningOpenFalse}
      />
    </>
  );
}
