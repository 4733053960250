import {ResponsiveDialog} from "@co-frontend-libs/components";
import {Checkbox, DialogContent, FormControlLabel} from "@material-ui/core";
import {useEventTargetCheckedCallback} from "app-utils";
import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";

interface MissingBreakDialogProps {
  onCancel: () => void;
  onOk: () => void;
  open: boolean;
}

export const MissingBreakDialog = React.memo(function MissingBreakDialog({
  onCancel,
  onOk,
  open,
}: MissingBreakDialogProps): JSX.Element {
  const intl = useIntl();
  const [noBreak, setNoBreak] = useState(false);
  useEffect(() => {
    if (open) {
      setNoBreak(false);
    }
  }, [open]);
  const handleNoBreakCheckboxChange = useEventTargetCheckedCallback(setNoBreak, [setNoBreak]);

  return (
    <ResponsiveDialog
      okDisabled={!noBreak}
      open={open}
      title={intl.formatMessage({
        defaultMessage: "Der er ikke registreret pause på opgaven?",
      })}
      onCancel={onCancel}
      onOk={onOk}
    >
      <DialogContent>
        <FormControlLabel
          control={
            <Checkbox
              checked={noBreak}
              id="no-break-checkbox"
              name="noBreak"
              onChange={handleNoBreakCheckboxChange}
            />
          }
          label={intl.formatMessage({
            defaultMessage: "Jeg har ikke afholdt pause under denne opgave.",
          })}
        />
      </DialogContent>
    </ResponsiveDialog>
  );
});
