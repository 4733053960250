import {Config} from "@co-common-libs/config";
import {Machine, MachineUse, PriceGroup, PriceGroupUrl, WorkType} from "@co-common-libs/resources";
import {MachineChip} from "@co-frontend-libs/components";
import {Button, Card, CardContent, Grid} from "@material-ui/core";
import {PureComponent, getDepartmentName} from "app-utils";
import React from "react";
import {FormattedMessage, IntlContext, defineMessages} from "react-intl";
import {allowMachineUseForWorktype} from "../order-instance/task-instance";

const messages = defineMessages({
  selectDepartmentButton: {
    defaultMessage: "Vælg",
    id: "order-instance.label.select-department",
  },
  selectMachine: {
    defaultMessage: "Tilføj",
  },
});

interface MachinesCardProps {
  allowMachineChange: boolean;
  completed?: boolean;
  currentDepartment?: string;
  customerSettings: Config;
  deletableMachineURLSet: ReadonlySet<string>;
  enableTaskDepartmentField: boolean;
  machineList: readonly Machine[];
  machineuseSet: readonly MachineUse[];
  onDepartmentSelectButton: () => void;
  onMachineSelectButton: () => void;
  onRemoveMachine: (index: number) => void;
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
  userIsOnlyMachineOperator?: boolean;
  userIsOtherMachineOperator?: boolean;
  validated?: boolean;
  workType?: WorkType | undefined;
}

class MachinesCard extends PureComponent<MachinesCardProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  render(): JSX.Element {
    const {
      allowMachineChange,
      completed,
      customerSettings,
      deletableMachineURLSet,
      machineList,
      machineuseSet,
      priceGroupLookup,
      userIsOnlyMachineOperator,
      userIsOtherMachineOperator,
      validated,
      workType,
    } = this.props;
    const {formatMessage} = this.context;
    let machineDiv = null;
    const renderedEntries = machineList.map((machine, index) => {
      const machineID = machine.c5_machine;
      const machineName = machine.name;
      const foundMachineUse = machineuseSet.find((machineUse) => {
        if (!machineUse) {
          return false;
        }
        if (machineUse.machine === machine.url) {
          return machineUse.priceGroup || false;
        }
        return false;
      });
      const priceGroupURL = foundMachineUse && (foundMachineUse.priceGroup || null);
      let priceGroupName = "";
      const priceGroup = priceGroupURL ? priceGroupLookup(priceGroupURL) : null;
      if (priceGroup) {
        priceGroupName = priceGroup.name;
      }
      const text =
        machineName +
        (machineID ? ` (${machineID})` : "") +
        (priceGroupName ? ` (${priceGroupName})` : "");
      const machineURL = machine.url;
      return (
        <MachineChip
          key={index}
          deletable={
            allowMachineChange &&
            !machine.smallMachine &&
            !completed &&
            deletableMachineURLSet.has(machineURL)
          }
          index={index}
          text={text}
          onDelete={this.props.onRemoveMachine}
        />
      );
    });
    machineDiv = <div style={{width: "100%"}}>{renderedEntries}</div>;
    let departmentBlock;
    if (this.props.enableTaskDepartmentField) {
      const departmentID = this.props.currentDepartment;
      const department = (
        <div>{departmentID && getDepartmentName(departmentID, customerSettings)}</div>
      );
      departmentBlock = (
        <Grid container>
          <Grid item xs={12}>
            <FormattedMessage
              defaultMessage="Afdeling"
              id="order-instance.header.department"
              tagName="h4"
            />
            <Button
              color="secondary"
              disabled={
                (completed && userIsOnlyMachineOperator) ||
                validated ||
                userIsOtherMachineOperator ||
                (customerSettings.onlyAdminCanChangeDepartment && userIsOnlyMachineOperator) ||
                false
              }
              style={{marginBottom: 8, width: "100%"}}
              variant="contained"
              onClick={this.props.onDepartmentSelectButton}
            >
              {formatMessage(messages.selectDepartmentButton)}
            </Button>
            {department}
          </Grid>
        </Grid>
      );
    }
    const allowMachineUse = allowMachineUseForWorktype(workType);
    return (
      <Card style={{margin: "1em"}}>
        <CardContent>
          <Grid container>
            <Grid item xs={12}>
              {customerSettings.machineLabelVariant === "MACHINE" ? (
                <FormattedMessage
                  defaultMessage="Maskine"
                  id="task-instance.header.machine"
                  tagName="h4"
                />
              ) : (
                <FormattedMessage
                  defaultMessage="Køretøj"
                  id="task-instance.header.vehicle"
                  tagName="h4"
                />
              )}
              <Button
                color="secondary"
                disabled={
                  !allowMachineChange ||
                  !allowMachineUse ||
                  (workType?.allowMaxOneMachine && machineList.length > 0) ||
                  completed ||
                  validated ||
                  userIsOtherMachineOperator ||
                  false
                }
                style={{marginBottom: 8, width: "100%"}}
                variant="contained"
                onClick={this.props.onMachineSelectButton}
              >
                {formatMessage(messages.selectMachine)}
              </Button>
              {machineDiv}
            </Grid>
          </Grid>
          {departmentBlock}
        </CardContent>
      </Card>
    );
  }
}

export default MachinesCard;
