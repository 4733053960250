import {
  CustomerUrl,
  Location,
  LocationTypeUrl,
  LocationUrl,
  PatchOperation,
  ReportingLocation,
  ReportingSpecification,
  Task,
} from "@co-common-libs/resources";
import {getInputSpecificationsMap} from "@co-common-libs/resources-utils";
import {formatAddress} from "@co-common-libs/utils";
import {ResponsiveDialog, TitleVariant} from "@co-frontend-libs/components";
import {ConnectedLocationDialog} from "@co-frontend-libs/connected-components";
import {
  actions,
  getContactArray,
  getCustomerLookup,
  getExtendedCustomerSettings,
  getJournalArray,
  getLocationArray,
  getLocationLookup,
  getLocationUseLogArray,
  getUnitLookup,
} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {Button, DialogContent} from "@material-ui/core";
import {createLocation, getLocationPhone} from "app-utils";
import _ from "lodash";
import React, {useCallback, useEffect, useMemo, useReducer, useState} from "react";
// Allowed for existing code...
// eslint-disable-next-line deprecate/import
import {Cell, Grid} from "react-flexr";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuid} from "uuid";
import {FieldSingleSelectionDialog} from "../../customer-field-dialog";
import {CustomerSelectCreateDialog} from "../../customer-select-create-dialog";
import {LocationCreateEditDialog} from "../../location-create-edit-dialog";
import {buildDialogFields} from "./build-dialog-fields";

type ValuesState = {readonly [identifier: string]: unknown};

type ValuesAction =
  | {identifier: string; type: "delete"}
  | {identifier: string; type: "put"; value: unknown}
  | {type: "replace"; value: ValuesState};

// TypeScript validates that all legal paths returns ValuesState
// eslint-disable-next-line consistent-return
function valuesReducer(state: ValuesState, action: ValuesAction): ValuesState {
  switch (action.type) {
    case "put":
      return {...state, [action.identifier]: action.value};
    case "delete":
      if (Object.prototype.hasOwnProperty.call(state, action.identifier)) {
        const newState = {...state};
        delete newState[action.identifier];
        return newState;
      } else {
        return state;
      }
    case "replace":
      return action.value;
  }
}

const JournalNote = React.memo(function JournalNote({
  location,
}: {
  location: Location;
}): JSX.Element | null {
  const customerJournalArray = useSelector(getJournalArray);
  const journalEntries = customerJournalArray.filter(
    (entry) => entry.reminder && entry.locations?.includes(location.url),
  );
  if (!journalEntries.length) {
    return null;
  }

  return (
    <div style={{whiteSpace: "pre-wrap"}}>
      <FormattedMessage defaultMessage="Note:" />
      {journalEntries.map((entry) => (
        <div key={entry.url} style={{fontWeight: "bold"}}>
          {entry.entry}
        </div>
      ))}
    </div>
  );
});

interface LocationDialogProps {
  deleteDisabled?: boolean;
  editingIdentifier: string | null;
  logSpecification: ReportingSpecification;
  onClose: () => void;
  open: boolean;
  orderCustomerUrl: CustomerUrl | null;
  task: Task;
  title: string;
  type: "delivery" | "pickup" | "workplace";
}

export const LocationDialog = React.memo(function LocationDialog(
  props: LocationDialogProps,
): JSX.Element {
  const {
    deleteDisabled = false,
    editingIdentifier,
    logSpecification,
    onClose,
    open,
    orderCustomerUrl,
    task,
    title,
    type,
  } = props;

  const inputSpecifications =
    logSpecification.workplaceData && logSpecification.workplaceData[type]?.inputs;

  const showFieldButton =
    logSpecification.fieldsUsedFor && logSpecification.fieldsUsedFor !== "unused";

  const editingReportingLocation =
    editingIdentifier && task.reportingLocations
      ? task.reportingLocations[editingIdentifier]
      : undefined;

  const [customerUrl, setCustomerUrl] = useState<CustomerUrl | null>(
    editingReportingLocation?.customer || orderCustomerUrl,
  );

  const [locationUrl, setLocationUrl] = useState<LocationUrl | null>(
    editingReportingLocation?.location || null,
  );

  const [values, dispatchValues] = useReducer(
    valuesReducer,
    editingReportingLocation?.values || {},
  );

  useEffect(() => {
    if (open) {
      setCustomerUrl(editingReportingLocation?.customer || orderCustomerUrl);
      setLocationUrl(editingReportingLocation?.location || null);
      dispatchValues({
        type: "replace",
        value: editingReportingLocation?.values || {},
      });
    }
  }, [
    editingReportingLocation?.customer,
    editingReportingLocation?.location,
    editingReportingLocation?.values,
    open,
    orderCustomerUrl,
  ]);

  const locationLookup = useSelector(getLocationLookup);
  const unitLookup = useSelector(getUnitLookup);
  const customerLookup = useSelector(getCustomerLookup);
  const locationArray = useSelector(getLocationArray);
  const locationUseLogArray = useSelector(getLocationUseLogArray);
  const customerSettings = useSelector(getExtendedCustomerSettings);
  const contactArray = useSelector(getContactArray);

  const {
    locations: {canCreateLocation},
  } = customerSettings;

  const dispatch = useDispatch();

  const intl = useIntl();

  const [customerDialogOpen, setCustomerDialogOpen] = useState(false);
  const setCustomerDialogOpenTrue = useCallWithTrue(setCustomerDialogOpen);
  const setCustomerDialogOpenFalse = useCallWithFalse(setCustomerDialogOpen);

  const handleCustomerSelected = useCallback(
    (url: CustomerUrl): void => {
      setCustomerUrl(url);
      if (locationUrl) {
        const location = locationLookup(locationUrl);
        if (location?.customer !== url) {
          setLocationUrl(null);
        }
      }
      setCustomerDialogOpen(false);
    },
    [locationLookup, locationUrl],
  );

  const [locationDialogOpen, setLocationDialogOpen] = useState(false);
  const setLocationDialogOpenTrue = useCallWithTrue(setLocationDialogOpen);
  const setLocationDialogOpenFalse = useCallWithFalse(setLocationDialogOpen);

  const [fieldDialogOpen, setFieldDialogOpen] = useState(false);
  const setFieldDialogOpenTrue = useCallWithTrue(setFieldDialogOpen);
  const setFieldDialogOpenFalse = useCallWithFalse(setFieldDialogOpen);

  const handleLocationSelected = useCallback(
    (url: LocationUrl): void => {
      setLocationUrl(url);
      const location = locationLookup(url);
      setCustomerUrl(location?.customer || null);
      if (inputSpecifications) {
        inputSpecifications.forEach((inputSpecification) => {
          if (inputSpecification.fieldAreaTarget) {
            const decimals = 2;
            if (location?.fieldAreaHa) {
              const value = _.floor(location.fieldAreaHa || 0, decimals);
              dispatchValues({
                identifier: inputSpecification.identifier,
                type: "put",
                value,
              });
            } else {
              dispatchValues({
                identifier: inputSpecification.identifier,
                type: "delete",
              });
            }
          } else if (inputSpecification.fieldCropTarget) {
            dispatchValues({
              identifier: inputSpecification.identifier,
              type: "put",
              value: location?.fieldCrop,
            });
          }
        });
      }
      setLocationDialogOpen(false);
      setFieldDialogOpen(false);
    },
    [inputSpecifications, locationLookup],
  );

  const inputSpecificationsMap = getInputSpecificationsMap(logSpecification);
  const valueMaps = [values];

  const handleFieldChange = useCallback((identifier: string, value: unknown): void => {
    dispatchValues({identifier, type: "put", value});
  }, []);

  const [locationCreateInitialAddress, setLocationCreateInitialAddress] = useState("");
  const [locationCreateDialogOpen, setLocationCreateDialogOpen] = useState(false);
  const setLocationCreateDialogOpenFalse = useCallWithFalse(setLocationCreateDialogOpen);

  const handleOnWorkPlaceAdd = useCallback((address: string): void => {
    setLocationCreateInitialAddress(address);
    setLocationCreateDialogOpen(true);
  }, []);

  const handleWorkPlaceCreate = useCallback(
    (data: {
      active: boolean;
      address: string;
      attention: string;
      city: string;
      coordinatesFromAddress: boolean;
      customer: CustomerUrl | null;
      favorite: boolean;
      latitude: number | null;
      locationType: LocationTypeUrl | null;
      logOnlyLocation: boolean;
      longitude: number | null;
      name: string;
      phone: string;
      postalCode: string;
      workplaceOnlyLocation: boolean;
    }): void => {
      if (!customerUrl && !data.customer) {
        return;
      }
      const instance: Location = createLocation({
        ...data,
        customer: data.customer || customerUrl,
      });
      dispatch(actions.create(instance));
      setLocationUrl(instance.url);
      setCustomerUrl(data.customer || customerUrl);
      setLocationCreateDialogOpen(false);
      setLocationDialogOpen(false);
    },
    [customerUrl, dispatch],
  );

  const handleDelete = useCallback((): void => {
    if (editingIdentifier && task.reportingLocations) {
      dispatch(
        actions.update(task.url, [
          {path: ["reportingLocations", editingIdentifier], value: undefined},
        ]),
      );
    }
    onClose();
  }, [dispatch, editingIdentifier, onClose, task.reportingLocations, task.url]);

  const handleOk = useCallback((): void => {
    if (!customerUrl || !locationUrl) {
      return;
    }
    const patch: PatchOperation<Task>[] = [];
    if (editingIdentifier) {
      if (customerUrl !== editingReportingLocation?.customer) {
        patch.push({
          path: ["reportingLocations", editingIdentifier, "customer"],
          value: customerUrl,
        });
      }
      if (locationUrl !== editingReportingLocation?.location) {
        patch.push({
          path: ["reportingLocations", editingIdentifier, "location"],
          value: locationUrl,
        });
      }
      Object.entries(values).forEach(([identifier, value]) => {
        if (value !== editingReportingLocation?.values?.[identifier]) {
          console.assert(value !== undefined);
          patch.push({
            path: ["reportingLocations", editingIdentifier, "values", identifier],
            value,
          });
        }
      });
      if (editingReportingLocation?.values) {
        Object.entries(editingReportingLocation?.values).forEach(([identifier, value]) => {
          console.assert(value !== undefined);
          if (values[identifier] === undefined) {
            // removed
            patch.push({
              path: ["reportingLocations", editingIdentifier, "values", identifier],
              value: undefined,
            });
          }
        });
      }
    } else {
      const currentMaxOrder = task.reportingLocations
        ? _.max(
            Object.values(task.reportingLocations).map(
              (reportingLocation) => reportingLocation.order,
            ),
          )
        : undefined;
      const nextOrder = currentMaxOrder !== undefined ? currentMaxOrder + 1 : 0;
      const newEntry: ReportingLocation = {
        customer: customerUrl,
        location: locationUrl,
        order: nextOrder,
        productUses: {},
        type,
        values,
      };
      const newIdentifier = uuid();
      patch.push({
        path: ["reportingLocations", newIdentifier],
        value: newEntry,
      });
    }
    if (patch.length) {
      dispatch(actions.update(task.url, patch));
    }
    onClose();
  }, [
    customerUrl,
    dispatch,
    editingIdentifier,
    editingReportingLocation?.customer,
    editingReportingLocation?.location,
    editingReportingLocation?.values,
    locationUrl,
    onClose,
    task.reportingLocations,
    task.url,
    type,
    values,
  ]);

  const {anyRequired, inputFields, okDisabled} = buildDialogFields(
    unitLookup,
    inputSpecifications || [],
    customerSettings,
    valueMaps,
    inputSpecificationsMap,
    handleFieldChange,
    values,
  );

  const requiredNote = anyRequired ? <FormattedMessage defaultMessage={"* krævet"} /> : null;

  const customer = customerUrl ? customerLookup(customerUrl) : undefined;
  const location = locationUrl ? locationLookup(locationUrl) : undefined;

  const customerHasActiveFields = useMemo(
    (): boolean =>
      locationArray.some(
        (field) => field.active && field.geojson && field.customer === customerUrl,
      ),
    [customerUrl, locationArray],
  );

  const lastUsedLocations = useMemo((): ReadonlySet<LocationUrl> | undefined => {
    if (customer && task.machineOperator) {
      const locationUse = locationUseLogArray.find(
        (l) => l.customer === customer?.url && l.user === task.machineOperator,
      );
      if (locationUse?.locations?.length) {
        return new Set(locationUse.locations);
      }
    }
    return undefined;
  }, [customer, locationUseLogArray, task.machineOperator]);

  const lastUsedFieldLocations = lastUsedLocations?.size
    ? new Set(
        [...lastUsedLocations].filter((locationsURL) => !!locationLookup(locationsURL)?.geojson),
      )
    : undefined;

  const locationDialogTitleVariant = useMemo((): TitleVariant => {
    if (type === "workplace") {
      return "WORKPLACE";
    } else if (type === "delivery") {
      return "DELIVERY";
    } else {
      console.assert(type === "pickup", `unknown type log location type: ${type}`);
      return "PICKUP";
    }
  }, [type]);

  return (
    <>
      <ResponsiveDialog
        fullWidth
        deleteDisabled={deleteDisabled}
        okDisabled={okDisabled || !locationUrl}
        open={open}
        title={title}
        onCancel={onClose}
        onDelete={
          customerSettings.enableTaskLogLocationChange && editingIdentifier
            ? handleDelete
            : undefined
        }
        onOk={handleOk}
      >
        <DialogContent>
          <Grid>
            <Cell palm="12/12">
              <div>
                <FormattedMessage
                  defaultMessage="Kunde"
                  id="location-dialog.label.customer"
                  tagName="h4"
                />
                {customerSettings.enableTaskLogLocationChange ? (
                  <Button color="secondary" variant="contained" onClick={setCustomerDialogOpenTrue}>
                    {intl.formatMessage({defaultMessage: "Vælg"})}
                  </Button>
                ) : null}
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <FormattedMessage
                          defaultMessage="Kunde:"
                          id="log-entry-dialog.label.customer"
                        />
                      </td>
                      <td>{customer?.name}</td>
                    </tr>
                    <tr>
                      <td>
                        <FormattedMessage
                          defaultMessage="Adresse:"
                          id="log-entry-dialog.label.address"
                        />
                      </td>
                      <td>{formatAddress(customer)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <div style={{fontWeight: "bold"}}>{title}</div>
                {customerSettings.enableTaskLogLocationChange ? (
                  <Button
                    color="secondary"
                    disabled={!customerUrl}
                    variant="contained"
                    onClick={setLocationDialogOpenTrue}
                  >
                    {intl.formatMessage({defaultMessage: "Sted"})}
                  </Button>
                ) : null}
                {customerSettings.enableTaskLogLocationChange && showFieldButton ? (
                  <Button
                    color="secondary"
                    disabled={!customerUrl || !customerHasActiveFields}
                    style={{marginLeft: 10}}
                    variant="contained"
                    onClick={setFieldDialogOpenTrue}
                  >
                    {intl.formatMessage({defaultMessage: "Mark"})}
                  </Button>
                ) : null}
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <FormattedMessage defaultMessage="Navn:" id="log-entry-dialog.label.name" />
                      </td>
                      <td>{location?.name}</td>
                    </tr>
                    <tr>
                      <td>
                        <FormattedMessage
                          defaultMessage="Adresse:"
                          id="log-entry-dialog.label.address"
                        />
                      </td>
                      <td>
                        {`${location?.address || ""}${
                          location?.postalCode || location?.city ? "," : ""
                        } ${location?.postalCode || ""} ${location?.city || ""}`.trim()}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <FormattedMessage
                          defaultMessage="Kontakt:"
                          id="log-entry-dialog.label.contact"
                        />
                      </td>
                      <td>{location?.attention}</td>
                    </tr>

                    <tr>
                      <td>
                        <FormattedMessage
                          defaultMessage="Telefon:"
                          id="log-entry-dialog.label.contact-phone"
                        />
                      </td>
                      <td>{getLocationPhone(location || null, contactArray, customerLookup)}</td>
                    </tr>
                  </tbody>
                </table>
                {location ? <JournalNote location={location} /> : null}
              </div>
            </Cell>
            <Cell palm="12/12">
              {inputFields}
              {requiredNote}
            </Cell>
          </Grid>
        </DialogContent>
      </ResponsiveDialog>
      <ConnectedLocationDialog
        hideFieldLocations
        includeLogOnlyLocations
        customerURL={customer?.url || null}
        includeWorkplaceOnlyLocations={false}
        lastUsedLocations={lastUsedLocations}
        open={locationDialogOpen}
        titleVariant={locationDialogTitleVariant}
        onAdd={canCreateLocation ? handleOnWorkPlaceAdd : undefined}
        onCancel={setLocationDialogOpenFalse}
        onOk={handleLocationSelected}
      />
      <LocationCreateEditDialog
        customerLookup={customerLookup}
        initialCustomer={customer?.url || null}
        initialSearch={locationCreateInitialAddress}
        open={locationCreateDialogOpen}
        workplaceOnlyLocation={false}
        onCancel={setLocationCreateDialogOpenFalse}
        onOk={handleWorkPlaceCreate}
      />
      <CustomerSelectCreateDialog
        open={customerDialogOpen}
        onCancel={setCustomerDialogOpenFalse}
        onOk={handleCustomerSelected}
      />
      <FieldSingleSelectionDialog
        customerURL={customer?.url || undefined}
        lastUsedLocations={lastUsedFieldLocations}
        open={fieldDialogOpen}
        onCancel={setFieldDialogOpenFalse}
        onOk={handleLocationSelected}
      />
    </>
  );
});
