import {Config} from "@co-common-libs/config";
import {Culture, PatchUnion} from "@co-common-libs/resources";
import {
  AppState,
  actions,
  getCultureLookup,
  getCustomerSettings,
  makeQueryParameterGetter,
} from "@co-frontend-libs/redux";
import {PartialNavigationKind} from "@co-frontend-libs/routing-sync-history";
import {Tab, Tabs} from "@material-ui/core";
import {DoLoadInstance, PageLayout} from "app-components";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import bowser from "bowser";
import React from "react";
import {IntlContext, defineMessages} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {CultureDetailsCard} from "./culture-details-card";
import {CultureStatisticsCard} from "./culture-statistics-card";

const messages = defineMessages({
  cultureLoadingTitle: {
    defaultMessage: "Kultur",
    id: "culture-entry.title.culture-loading",
  },
  cultureTitle: {
    defaultMessage: "Kultur: {name}",
    id: "culture-entry.title.culture",
  },
  detailsTab: {
    defaultMessage: "Detaljer",
    id: "culture-entry.tab-label.details",
  },
  statisticTab: {
    defaultMessage: "Statistik",
    id: "culture-entry.tab-label.statistic",
  },
});

interface CultureEntryContentStateProps {
  customerSettings: Config;
  fromDate: string | null;
  tab: string;
  toDate: string | null;
}

interface CultureEntryContentDispatchProps {
  putQueryKey: (key: string, value: string, navigationKind?: PartialNavigationKind) => void;
  update: (url: string, patch: PatchUnion) => void;
}

interface CultureEntryContentOwnProps {
  instance: Culture;
}

type CultureEntryContentProps = CultureEntryContentDispatchProps &
  CultureEntryContentOwnProps &
  CultureEntryContentStateProps;

class CultureEntryContent extends PureComponent<CultureEntryContentProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  @bind
  handleTabChange(_event: React.ChangeEvent<unknown>, value: string): void {
    this.props.putQueryKey("tab", value);
  }

  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {customerSettings, tab} = this.props;
    const culture = this.props.instance;

    let content;
    if (tab === "details") {
      content = (
        <div style={{padding: "1em"}}>
          <CultureDetailsCard
            culture={culture}
            customerSettings={customerSettings}
            update={this.props.update}
          />
        </div>
      );
    } else if (tab === "statistics") {
      content = (
        <div style={{padding: "1em"}}>
          <CultureStatisticsCard
            culture={culture}
            fromDate={this.props.fromDate}
            putQueryKey={this.props.putQueryKey}
            toDate={this.props.toDate}
          />
        </div>
      );
    }
    return (
      <PageLayout
        tabs={
          <Tabs
            value={tab}
            variant={bowser.mobile ? "fullWidth" : "standard"}
            onChange={this.handleTabChange}
          >
            <Tab label={formatMessage(messages.detailsTab)} value="details" />
            <Tab label={formatMessage(messages.statisticTab)} value="statistics" />
          </Tabs>
        }
        toolbar={formatMessage(messages.cultureTitle, {
          name: culture.name,
        })}
      >
        {content}
      </PageLayout>
    );
  }
}

const ConnectedCultureEntryContent: React.ComponentType<CultureEntryContentOwnProps> = connect<
  CultureEntryContentStateProps,
  CultureEntryContentDispatchProps,
  CultureEntryContentOwnProps,
  AppState
>(
  createStructuredSelector<AppState, CultureEntryContentStateProps>({
    customerSettings: getCustomerSettings,
    fromDate: makeQueryParameterGetter("fromDate"),
    tab: makeQueryParameterGetter("tab", "details"),
    toDate: makeQueryParameterGetter("toDate"),
  }),
  {
    putQueryKey: actions.putQueryKey,
    update: actions.update,
  },
)(CultureEntryContent);

export class CultureEntry extends React.Component<undefined> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  render(): JSX.Element {
    const {formatMessage} = this.context;
    return (
      <DoLoadInstance
        Component={ConnectedCultureEntryContent}
        loadingTitle={formatMessage(messages.cultureLoadingTitle)}
        lookupSelector={getCultureLookup}
        resourceName="culture"
      />
    );
  }
}
