import {Chip} from "@material-ui/core";
import React, {useCallback} from "react";

interface DeletableChipProps<T extends string> {
  deletionId: T;
  label: string;
  onDelete: (url: T) => void;
}

export function DeletableChip<T extends string>(props: DeletableChipProps<T>): JSX.Element {
  const {deletionId, label, onDelete} = props;

  const handleDelete = useCallback(() => {
    onDelete(deletionId);
  }, [onDelete, deletionId]);

  return <Chip label={label} onDelete={handleDelete} />;
}
