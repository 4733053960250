import {dateFromString} from "./dates";

export const getMidnights = (
  today: string,
): {
  endOfNextDay: string;
  startOfDay: string;
  startOfNextDay: string;
  startOfPreviousDay: string;
} => {
  const todayDate = dateFromString(today) as Date;
  const startOfDayDate = new Date(todayDate);
  startOfDayDate.setHours(0, 0, 0, 0);
  const startOfPreviousDayDate = new Date(startOfDayDate);
  startOfPreviousDayDate.setDate(startOfDayDate.getDate() - 1);
  const startOfNextDayDate = new Date(startOfDayDate);
  startOfNextDayDate.setDate(startOfDayDate.getDate() + 1);
  const endOfNextDayDate = new Date(startOfNextDayDate);
  endOfNextDayDate.setDate(startOfNextDayDate.getDate() + 1);
  const startOfPreviousDay = startOfPreviousDayDate.toISOString();
  const startOfDay = startOfDayDate.toISOString();
  const startOfNextDay = startOfNextDayDate.toISOString();
  const endOfNextDay = endOfNextDayDate.toISOString();
  return {endOfNextDay, startOfDay, startOfNextDay, startOfPreviousDay};
};
