import {Config} from "@co-common-libs/config";
import {
  Availability,
  DinnerBooking,
  Information,
  LunchBooking,
  PatchOperation,
  PatchUnion,
  ResourceTypeUnion,
  Role,
  Settings,
  Task,
  TaskUrl,
  TimerStart,
  User,
  UserProfile,
  UserUrl,
  urlToId,
} from "@co-common-libs/resources";
import {
  HOUR_MILLISECONDS,
  WEEKDAY_SATURDAY,
  dateToString,
  daySeconds,
  getHolidaySundayWeekday,
  hourMinutesStringDaySeconds,
  weekNumber,
} from "@co-common-libs/utils";
import {AvailabilityDialog, ResponsiveInfoDialog, WeekDay} from "@co-frontend-libs/components";
import {Check, Query, makeQuery} from "@co-frontend-libs/db-resources";
import {
  AppState,
  PathTemplate,
  actions,
  getAvailabilityArray,
  getCurrentRole,
  getCurrentUser,
  getCurrentUserActiveTaskURLArray,
  getCurrentUserProfile,
  getCurrentUserURL,
  getCustomerSettings,
  getDeviceConfigKey,
  getDinnerBookingArray,
  getExtraHalfHolidaysPerRemunerationGroup,
  getExtraHolidaysPerRemunerationGroup,
  getInformationArray,
  getLocalDbUpdateError,
  getLunchBookingArray,
  getNotificationDialogOpen,
  getSettingsArray,
  getTaskLookup,
  getTemporaryQueries,
  getTimerStartArray,
  getUserId,
} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {getFrontendSentry, jsonFetch} from "@co-frontend-libs/utils";
import {DialogContent, withStyles} from "@material-ui/core";
import {
  checkDoneSignal,
  closeDatabases,
  currentNewestSignal,
  getEmployeeHolidayCalendars,
  getExtraHolidaysForUser,
  performUpdateCheck,
  updateDinnerLunchBooking,
  updateFoundSignal,
  updateReadySignal,
} from "app-utils";
import {bind} from "bind-decorator";
import bowser from "bowser";
import type {default as BackgroundGeolocationPlugin} from "cordova-background-geolocation-lt";
import {
  AuthorizationStatus,
  getGeolocationTracker,
  getPushNotification,
  globalConfig,
  instanceURL,
} from "frontend-global-config";
import React from "react";
import {FormattedMessage, IntlContext, defineMessages} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import type {Writable} from "ts-essentials";
import {v4 as uuid} from "uuid";
import {BackgroundFetchManager} from "../background-fetch-manager";
import {BackgroundGeolocationManager} from "../background-geolocation-manager";
import {DinnerBookingDialog} from "../dinner-booking-dialog";
import {DRAWER_WIDTH} from "../drawer/constants";
import {MainMenu} from "../drawer/main-menu";
import {SettingsMenu} from "../drawer/settings-menu";
import {InformationReceivedDialog} from "../information-received-dialog";
import {NotificationDialog} from "../notification-dialog";
import {PrivacyPolicyDialog} from "../privacy-policy-dialog";
import {ProcessorAgreementDialog} from "../processor-agreement-dialog";
import {TaskReceivedDialog} from "../task-received-dialog";
import {TermsOfServiceDialog} from "../terms-of-service-dialog";
import {TimeoutDialog} from "../timeout-dialog";
import {TimerStartNotificationDialog} from "../timer-start-notification-dialog";
import {NotesChangedDialog} from "./notes-changed-dialog";
import {UpdateRequiredPopup} from "./update-required-popup";

export const informationVisibleToUser = (
  visibleTo: readonly string[],
  userURL: string,
): boolean => {
  return !visibleTo || visibleTo.length === 0 || visibleTo.includes(userURL);
};

declare const BackgroundGeolocation: typeof BackgroundGeolocationPlugin;

const messages = defineMessages({
  fetchingSoftware: {
    defaultMessage: "Henter opdatering.",
    id: "page-with-menu.label.fetching-software",
  },
  newestSoftwareRunning: {
    defaultMessage: "Ingen opdateringer.",
    id: "page-with-menu.label.newest-software-running",
  },
  updateDialogTitle: {
    defaultMessage: "Opdater?",
    id: "page-with-menu.title.update",
  },
});

const EMPTY_MAP = {};

const UPDATE_POPUP_DELAY_HOURS = 12;

function handleReloadClick(): void {
  window.location.reload();
}

interface PageWithMenuStateProps {
  availabilityArray: readonly Availability[];
  currentRole: Role | null;
  currentUser: User | null;
  currentUserActiveTaskURLArray: readonly TaskUrl[];
  currentUserProfile: UserProfile | null;
  currentUserURL: UserUrl | null;
  customerSettings: Config;
  dinnerBookingArray: readonly DinnerBooking[];
  extraHalfHolidaysPerRemunerationGroup: (
    remunerationGroup: string,
    date: string,
  ) => string | undefined;
  extraHolidaysPerRemunerationGroup: (
    remunerationGroup: string,
    date: string,
  ) => string | undefined;
  informationArray: readonly Information[];
  localDbUpdateError: Error | null;
  lunchBookingArray: readonly LunchBooking[];
  notificationDialogOpen: boolean;
  registrationId?: string;
  settingsArray: readonly Settings[];
  taskLookup: (url: TaskUrl) => Task | undefined;
  temporaryQueries: readonly Query[];
  timerStartArray: readonly TimerStart[];
  userId: string | null;
}

interface PageWithMenuDispatchProps {
  configPut: (params: {key: string; value: any}) => void;
  create: (instance: ResourceTypeUnion) => void;
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  setMessage: (message: string, timestamp: Date) => void;
  temporaryQueriesRequestedForKey: (queries: readonly Query[], key: string) => void;
  update: (url: string, patch: PatchUnion) => void;
}

interface PageWithMenuOwnProps {
  menu: "main" | "settings";
  Page: React.ComponentType<{onMenuButton: (event: React.MouseEvent) => void}>;
}

interface PageWithMenuStyleProps {
  classes: {
    root: string;
  };
}

type PageWithMenuProps = PageWithMenuDispatchProps &
  PageWithMenuOwnProps &
  PageWithMenuStateProps &
  PageWithMenuStyleProps;

interface PageWithMenuState {
  informationIdFromNotification: string | null;
  manualUpdateChecking: boolean;
  menuOpen: boolean;
  missingGPSAuthorizationDialogOpen: boolean;
  missingGPSAuthorizationStatus: {
    authorization: number;
    locationServicesEnabled: boolean;
  } | null;
  processorAgreementDialogDismissed: boolean;
  taskIdFromNotification: string | null;
  taskWithNotes: TaskUrl | null;
  termsOfServiceDialogDismissed: boolean;
  timerStartIdFromNotification: string | null;
  updateConfirmationDialogOpen: boolean;
  versionWarningDialogOpen: boolean;
}

class PageWithMenuImpl extends React.Component<PageWithMenuProps, PageWithMenuState> {
  state: PageWithMenuState = {
    informationIdFromNotification: null,
    manualUpdateChecking: false,
    menuOpen: false,
    missingGPSAuthorizationDialogOpen: false,
    missingGPSAuthorizationStatus: null,
    processorAgreementDialogDismissed: false,
    taskIdFromNotification: null,
    taskWithNotes: null,
    termsOfServiceDialogDismissed: false,
    timerStartIdFromNotification: null,
    updateConfirmationDialogOpen: false,
    versionWarningDialogOpen: false,
  };
  /*
  Not working
  UNSAFE_componentWillMount() {
    // hack to support iPhone X notch on IOS 11
    if (/(iphone|ipad) os 11/.test(navigator.userAgent.toLowerCase())) {
      document.body.style.paddingTop = "constant(safe-area-inset-top)";
      document.body.style.paddingLeft = "constant(safe-area-inset-left)";
      document.body.style.paddingRight = "constant(safe-area-inset-right)";
    }
  }*/

  componentDidMount(): void {
    updateReadySignal.subscribe(this.showUpdateDialogAfterDelay);
    currentNewestSignal.subscribe(this.checkCurrentNewestCallback);
    updateFoundSignal.subscribe(this.checkUpdateFoundCallback);
    updateReadySignal.subscribe(this.checkUpdateReadyCallback);

    if (typeof cordova !== "undefined") {
      if (this.props.currentUserProfile?.requireGps) {
        this.checkGeolocationAuthorization();
        document.addEventListener("resume", this.checkGeolocationAuthorization, false);
      }

      const appVersion = (window as any).APP_VERSION;

      const expectedAppVersion = bowser.ios
        ? (window as any).CURRENT_IOS_APP_VERSION
        : bowser.android
          ? (window as any).CURRENT_ANDROID_APP_VERSION
          : undefined;
      if (!appVersion || (appVersion && appVersion < expectedAppVersion)) {
        // const sentry = getSentry();
        // const userAlias = this.props.currentUserProfile?.alias || "N/A";
        // sentry.captureMessage(
        //   `User ${userAlias} running old app version. Got version ${
        //     appVersion || "N/A"
        //   }, expected ${expectedAppVersion}`,
        //   "warning",
        // );
        this.showAppVersionWarningDialog();
        document.addEventListener("resume", this.showAppVersionWarningDialog, false);
      }

      if (this.props.registrationId) {
        this.sendRegistrationToServer(this.props.registrationId);
      }

      const pushNotification = getPushNotification();
      if (pushNotification) {
        pushNotification.on("registration", this.deviceRegistration);
        pushNotification.on("notification", this.deviceNotification);
      }
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps: PageWithMenuProps): void {
    if (nextProps.taskLookup !== this.props.taskLookup) {
      const {currentRole, currentUserActiveTaskURLArray} = this.props;
      if (!currentRole?.manager && currentUserActiveTaskURLArray.length) {
        currentUserActiveTaskURLArray.forEach((taskURL) => {
          const newTask = nextProps.taskLookup(taskURL);
          const oldTask = this.props.taskLookup(taskURL);
          if (oldTask && newTask) {
            const newNotes = newTask.notesFromManager;
            const oldNotes = oldTask.notesFromManager;
            if (newNotes && newNotes !== oldNotes) {
              this.setState({
                taskWithNotes: taskURL,
              });
            }
          }
        });
      }
    }
  }
  componentWillUnmount(): void {
    updateReadySignal.unsubscribe(this.showUpdateDialogAfterDelay);
    currentNewestSignal.unsubscribe(this.checkCurrentNewestCallback);
    updateFoundSignal.unsubscribe(this.checkUpdateFoundCallback);
    updateReadySignal.unsubscribe(this.checkUpdateReadyCallback);
    checkDoneSignal.unsubscribe(this.manualUpdateCheckDone);
    if (typeof cordova !== "undefined") {
      document.removeEventListener("resume", this.checkGeolocationAuthorization, false);
      document.removeEventListener("resume", this.showAppVersionWarningDialog, false);
    }
    if (this.updateDialogDelay) {
      window.clearTimeout(this.updateDialogDelay);
      this.updateDialogDelay = null;
    }
  }
  private updateDialogDelay: number | null = null;

  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  @bind
  showAppVersionWarningDialog(): void {
    this.setState({versionWarningDialogOpen: true});
  }

  @bind
  handleVersionWarningDialogClose(): void {
    this.setState({versionWarningDialogOpen: false});
  }

  @bind
  geolocationAuthorizationCheckHandler(status: {
    authorization: AuthorizationStatus;
    locationServicesEnabled: boolean;
  }): void {
    if (
      !status.locationServicesEnabled ||
      status.authorization !== AuthorizationStatus.AUTHORIZED
    ) {
      this.setState({
        missingGPSAuthorizationDialogOpen: true,
        missingGPSAuthorizationStatus: {
          authorization: status.authorization,
          locationServicesEnabled: status.locationServicesEnabled,
        },
      });
    }
  }

  @bind
  handleMissingGPSAuthorizationDialogClose(): void {
    this.setState({
      missingGPSAuthorizationDialogOpen: false,
    });
  }

  @bind
  checkGeolocationAuthorization(): void {
    const appVersion = (window as any).APP_VERSION;
    const requiredAppVersion =
      globalConfig.cordova.pluginsRequiredAppVersion["cordova-background-geolocation-lt"];
    if (appVersion >= requiredAppVersion) {
      BackgroundGeolocation.getProviderState()
        .then((providerState) => {
          this.geolocationAuthorizationCheckHandler({
            authorization:
              providerState.status === BackgroundGeolocation.AUTHORIZATION_STATUS_ALWAYS
                ? AuthorizationStatus.AUTHORIZED
                : AuthorizationStatus.NOT_AUTHORIZED,
            locationServicesEnabled: providerState.enabled,
          });
          return;
        })
        .catch((error) => getFrontendSentry().captureException(error));
    } else {
      getGeolocationTracker()
        .then((geolocationTracker) => {
          if (geolocationTracker?.checkStatus) {
            geolocationTracker.checkStatus(this.geolocationAuthorizationCheckHandler);
          }
          return;
        })
        .catch((error) => getFrontendSentry().captureException(error));
    }
  }

  @bind
  checkUpdateReadyCallback(): void {
    if (!this.state.manualUpdateChecking) {
      return;
    }
    this.setState({updateConfirmationDialogOpen: true});
  }
  @bind
  checkUpdateFoundCallback(): void {
    if (!this.state.manualUpdateChecking) {
      return;
    }
    const intl = this.context;
    this.props.setMessage(intl.formatMessage(messages.fetchingSoftware), new Date());
  }
  @bind
  checkCurrentNewestCallback(): void {
    if (!this.state.manualUpdateChecking) {
      return;
    }
    const intl = this.context;
    this.props.setMessage(intl.formatMessage(messages.newestSoftwareRunning), new Date());
  }
  @bind
  manualUpdateCheckDone(): void {
    this.setState({manualUpdateChecking: false});
  }

  @bind
  showUpdateDialogAfterDelay(): void {
    if (!this.updateDialogDelay) {
      this.updateDialogDelay = window.setTimeout(() => {
        this.updateDialogDelay = null;
        this.setState({updateConfirmationDialogOpen: true});
      }, UPDATE_POPUP_DELAY_HOURS * HOUR_MILLISECONDS);
    }
  }

  // not using type from PhonegapPluginPush due to @babel/preset-typescript not supporting namespaces
  // causing ReferenceError: PhonegapPluginPush is not defined when
  // executing tests that import this file
  @bind
  deviceRegistration(
    data: {registrationId: string} /*PhonegapPluginPush.RegistrationEventResponse*/,
  ): void {
    const {registrationId} = data;
    this.sendRegistrationToServer(registrationId);
    this.props.configPut({key: "registrationId", value: registrationId});
  }
  sendRegistrationToServer(registrationId: string): void {
    const {baseURL} = globalConfig.resources;
    const url = `${baseURL}device_registration`;

    jsonFetch(url, "POST", {
      registrationId,
      userId: this.props.userId,
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error);
    });
  }

  // not using type from PhonegapPluginPush due to @babel/preset-typescript not supporting namespaces
  // causing ReferenceError: PhonegapPluginPush is not defined when
  // executing tests that import this file
  @bind
  deviceNotification(
    data: {additionalData: {[name: string]: any}} /*PhonegapPluginPush.NotificationEventResponse*/,
  ): void {
    const taskId = data.additionalData?.task;
    if (taskId) {
      const existing = this.props.temporaryQueries;
      const taskCheck: Check = {
        memberName: "url",
        type: "memberEq",
        value: instanceURL("task", taskId),
      };
      const taskQuery = makeQuery({
        check: taskCheck,
        independentFetch: true,
        instance: taskId,
        resourceName: "task",
      });
      if (!existing.some((existingEntry) => existingEntry.keyString === taskQuery.keyString)) {
        const queries = [taskQuery];
        const orderCheck: Check = {
          check: taskCheck,
          fromResource: "task",
          memberName: "order",
          type: "targetOfForeignKey",
        };
        const customerCheck: Check = {
          check: orderCheck,
          fromResource: "order",
          memberName: "customer",
          type: "targetOfForeignKey",
        };
        const relatedTaskCheck: Check = {
          check: taskCheck,
          memberName: "task",
          targetType: "task",
          type: "hasForeignKey",
        };
        const relatedTransportLogTaskCheck: Check = {
          check: relatedTaskCheck,
          memberName: "transportlog",
          targetType: "transportLog",
          type: "hasForeignKey",
        };
        const relatedYieldLogTaskCheck: Check = {
          check: relatedTaskCheck,
          memberName: "yieldlog",
          targetType: "yieldLog",
          type: "hasForeignKey",
        };
        const relatedSprayLogTaskCheck: Check = {
          check: relatedTaskCheck,
          memberName: "spraylog",
          targetType: "sprayLog",
          type: "hasForeignKey",
        };
        const relatedTaskRouteMemberCheck: Check = {
          check: taskCheck,
          memberName: "route",
          targetType: "task",
          type: "hasForeignKey",
        };
        const relatedRouteTaskTaskRouteMemberCheck: Check = {
          check: relatedTaskRouteMemberCheck,
          memberName: "routeTask",
          targetType: "routeTask",
          type: "hasForeignKey",
        };

        queries.push(
          makeQuery({
            check: orderCheck,
            independentFetch: false,
            resourceName: "order",
          }),
        );
        queries.push(
          makeQuery({
            check: relatedTaskCheck,
            independentFetch: false,
            resourceName: "timerStart",
          }),
        );
        queries.push(
          makeQuery({
            check: relatedTaskCheck,
            independentFetch: false,
            resourceName: "transportLog",
          }),
        );
        queries.push(
          makeQuery({
            check: relatedTransportLogTaskCheck,
            independentFetch: false,
            resourceName: "pickupLocation",
          }),
        );
        queries.push(
          makeQuery({
            check: relatedTransportLogTaskCheck,
            independentFetch: false,
            resourceName: "deliveryLocation",
          }),
        );
        queries.push(
          makeQuery({
            check: {
              check: relatedTransportLogTaskCheck,
              memberName: "location",
              targetType: "pickupLocation",
              type: "hasForeignKey",
            },
            independentFetch: false,
            resourceName: "pickup",
          }),
        );
        queries.push(
          makeQuery({
            check: {
              check: relatedTransportLogTaskCheck,
              memberName: "location",
              targetType: "deliveryLocation",
              type: "hasForeignKey",
            },
            independentFetch: false,
            resourceName: "delivery",
          }),
        );
        queries.push(
          makeQuery({
            check: relatedTransportLogTaskCheck,
            independentFetch: false,
            resourceName: "transportLogReport",
          }),
        );
        queries.push(
          makeQuery({
            check: customerCheck,
            independentFetch: false,
            resourceName: "customer",
          }),
        );
        queries.push(
          makeQuery({
            check: relatedTaskCheck,
            independentFetch: false,
            resourceName: "yieldLog",
          }),
        );
        queries.push(
          makeQuery({
            check: relatedYieldLogTaskCheck,
            independentFetch: false,
            resourceName: "yieldLogReport",
          }),
        );
        queries.push(
          makeQuery({
            check: relatedYieldLogTaskCheck,
            independentFetch: false,
            resourceName: "yieldPickupLocation",
          }),
        );
        queries.push(
          makeQuery({
            check: relatedYieldLogTaskCheck,
            independentFetch: false,
            resourceName: "yieldDeliveryLocation",
          }),
        );
        queries.push(
          makeQuery({
            check: {
              check: relatedYieldLogTaskCheck,
              memberName: "location",
              targetType: "yieldPickupLocation",
              type: "hasForeignKey",
            },
            independentFetch: false,
            resourceName: "yieldPickup",
          }),
        );
        queries.push(
          makeQuery({
            check: {
              check: relatedYieldLogTaskCheck,
              memberName: "location",
              targetType: "yieldDeliveryLocation",
              type: "hasForeignKey",
            },
            independentFetch: false,
            resourceName: "yieldDelivery",
          }),
        );
        queries.push(
          makeQuery({
            check: relatedTaskCheck,
            independentFetch: false,
            resourceName: "sprayLog",
          }),
        );
        queries.push(
          makeQuery({
            check: relatedSprayLogTaskCheck,
            independentFetch: false,
            resourceName: "sprayLocation",
          }),
        );
        queries.push(
          makeQuery({
            check: {
              check: relatedSprayLogTaskCheck,
              memberName: "location",
              targetType: "sprayLocation",
              type: "hasForeignKey",
            },
            independentFetch: false,
            resourceName: "spray",
          }),
        );
        queries.push(
          makeQuery({
            check: relatedSprayLogTaskCheck,
            independentFetch: false,
            resourceName: "sprayLogReport",
          }),
        );
        queries.push(
          makeQuery({
            check: relatedTaskCheck,
            independentFetch: false,
            resourceName: "taskPhoto",
          }),
        );
        queries.push(
          makeQuery({
            check: relatedTaskCheck,
            independentFetch: false,
            resourceName: "taskFile",
          }),
        );
        queries.push(
          makeQuery({
            check: relatedTaskCheck,
            independentFetch: false,
            resourceName: "reportingPrintout",
          }),
        );
        if (this.props.customerSettings.routesEnabled) {
          queries.push(
            makeQuery({
              check: relatedTaskRouteMemberCheck,
              independentFetch: false,
              resourceName: "routeTask",
            }),
          );

          queries.push(
            makeQuery({
              check: relatedRouteTaskTaskRouteMemberCheck,
              independentFetch: false,
              resourceName: "routeTaskResult",
            }),
          );

          queries.push(
            makeQuery({
              check: relatedRouteTaskTaskRouteMemberCheck,
              independentFetch: false,
              resourceName: "routeTaskActivityOption",
            }),
          );
          queries.push(
            makeQuery({
              check: relatedTaskRouteMemberCheck,
              independentFetch: false,
              resourceName: "routeLogReport",
            }),
          );
        }

        this.props.temporaryQueriesRequestedForKey(queries, "deviceNotification");
      }
      this.setState({taskIdFromNotification: taskId});
    }
    const informationId = data.additionalData?.information;
    if (informationId) {
      const existing = this.props.temporaryQueries;
      const newQuery = makeQuery({
        check: {
          memberName: "url",
          type: "memberEq",
          value: instanceURL("information", informationId),
        },
        independentFetch: true,
        instance: informationId,
        resourceName: "information",
      });
      if (!existing.some((existingEntry) => existingEntry.keyString === newQuery.keyString)) {
        this.props.temporaryQueriesRequestedForKey([newQuery], "deviceNotification");
      }

      this.setState({informationIdFromNotification: informationId});
    }
    const timerStartId = data.additionalData?.timerStart;
    if (timerStartId) {
      const timerStartCheck: Check = {
        memberName: "url",
        type: "memberEq",
        value: instanceURL("timerStart", timerStartId),
      };
      const taskCheck: Check = {
        check: timerStartCheck,
        fromResource: "timerStart",
        memberName: "task",
        type: "targetOfForeignKey",
      };
      const timerStartQuery = makeQuery({
        check: timerStartCheck,
        independentFetch: true,
        instance: timerStartId,
        resourceName: "timerStart",
      });
      const taskQuery = makeQuery({
        check: taskCheck,
        independentFetch: false,
        resourceName: "task",
      });
      this.props.temporaryQueriesRequestedForKey(
        [timerStartQuery, taskQuery],
        "deviceNotification",
      );

      this.setState({timerStartIdFromNotification: timerStartId});
    }
  }
  @bind
  handleMenuButton(): void {
    this.setState({menuOpen: true});
  }
  @bind
  handleMenuClose(): void {
    this.setState({menuOpen: false});
  }
  @bind
  handleDialogClose(): void {
    this.setState({
      taskWithNotes: null,
    });
  }
  @bind
  handleRequestCheckForUpdates(): void {
    this.setState({manualUpdateChecking: true}, () => {
      checkDoneSignal.one(this.manualUpdateCheckDone);
      performUpdateCheck();
    });
  }
  // eslint-disable-next-line class-methods-use-this
  @bind
  handleUpdateConfirmationDialogOk(): void {
    window.setTimeout(() => {
      // eslint-disable-next-line promise/catch-or-return
      closeDatabases().then(() => {
        window.location.reload();
        return;
      });
    }, 200);
  }
  @bind
  handleUpdateConfirmationDialogCancel(): void {
    this.setState({updateConfirmationDialogOpen: false});
  }
  @bind
  handleAvailabilityOk(values: ReadonlyMap<WeekDay, boolean>): void {
    const {currentUserURL} = this.props;
    const {week, year} = weekNumber(new Date());
    const savedAvailability = this.props.availabilityArray.find((entry) => {
      return entry.user === currentUserURL && entry.weekNumber === week && entry.year === year;
    });
    if (savedAvailability) {
      const patch: PatchOperation<Availability>[] = [];
      values.forEach((value, key) => {
        if (value !== savedAvailability[key]) {
          patch.push({member: key, value});
        }
      });
      if (patch.length) {
        this.props.update(savedAvailability.url, patch);
      }
    } else {
      const id = uuid();
      const url = instanceURL("availability", id);
      const obj: Writable<Availability> = {
        friday: null,
        id,
        monday: null,
        saturday: null,
        sunday: null,
        thursday: null,
        tuesday: null,
        url,
        user: currentUserURL as UserUrl,
        wednesday: null,
        weekNumber: week,
        year,
      };
      values.forEach((value, key) => {
        if (value != null) {
          obj[key] = value;
        }
      });
      this.props.create(obj);
    }
  }
  @bind
  handleDinnerBookingOk(
    date: string,
    employeeURL: UserUrl,
    count: number,
    lunchCount: number | null,
    dinnerLocation: string | null,
    lunchLocation: string | null,
  ): void {
    updateDinnerLunchBooking(
      this.props.customerSettings,
      this.props.dinnerBookingArray,
      this.props.lunchBookingArray,
      this.props.create,
      this.props.update,
      date,
      employeeURL,
      count,
      lunchCount,
      dinnerLocation,
      lunchLocation,
    );
  }

  @bind
  handleTaskReceivedDialogOk(): void {
    if (!this.state.taskIdFromNotification) {
      return;
    }
    this.props.go("/task/:id", {
      id: this.state.taskIdFromNotification,
    });
    // edge case: may have already opened task when notification arrives...?
    // setTimeout is used to avoid an ekstra page transition
    setTimeout(() => this.setState({taskIdFromNotification: null}));
  }
  @bind
  handleTaskReceivedDialogCancel(): void {
    this.setState({taskIdFromNotification: null});
  }

  @bind
  handleTimerStartNotificationDialogOk(url: string | null): void {
    if (url) {
      this.props.go("/task/:id", {
        id: urlToId(url),
      });
    }
    // setTimeout is used to avoid an ekstra page transition
    setTimeout(() => this.setState({timerStartIdFromNotification: null}));
  }
  @bind
  handleTimerStartNotificationDialogCancel(): void {
    this.setState({timerStartIdFromNotification: null});
  }

  @bind
  handleInformationReceivedDialogOk(): void {
    this.props.go("/information");
    // setTimeout is used to avoid an ekstra page transition
    setTimeout(() => this.setState({informationIdFromNotification: null}));
  }
  @bind
  handleInformationReceivedDialogCancel(): void {
    this.setState({informationIdFromNotification: null});
  }
  @bind
  handleProcessorAgreementDialogCancel(): void {
    this.setState({processorAgreementDialogDismissed: true});
  }

  @bind
  handleProcessorAgreementConfirmed(): void {
    const settings = this.props.settingsArray[0];
    const approvedTimestamp = new Date();
    this.props.update(settings.url, [
      {member: "policyApproved", value: approvedTimestamp.toISOString()},
      {member: "policyApprovedBy", value: this.props.currentUserURL},
    ]);
  }

  @bind
  handleTermsOfServiceDialogCancel(): void {
    this.setState({termsOfServiceDialogDismissed: true});
  }

  @bind
  handleTermsOfServiceConfirmed(): void {
    const settings = this.props.settingsArray[0];
    const approvedTimestamp = new Date();
    this.props.update(settings.url, [
      {
        member: "termsOfServiceApproved",
        value: approvedTimestamp.toISOString(),
      },
      {member: "termsOfServiceApprovedBy", value: this.props.currentUserURL},
    ]);
  }

  @bind
  handlePrivacyPolicyConfirmed(): void {
    const approvedTimestamp = new Date();
    this.props.update((this.props.currentUserProfile as UserProfile).url, [
      {member: "policyApproved", value: approvedTimestamp.toISOString()},
    ]);
  }

  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {
      availabilityWeekdays,
      availabilityWeekdaysAskFromDay,
      availabilityWeekdaysAskFromHour,
      availabilityWeekends,
      availabilityWeekendsAskFromDay,
      dinnerBookings,
      lunchBookings,
    } = this.props.customerSettings;

    let task: Task | undefined;
    if (this.state.taskWithNotes) {
      task = this.props.taskLookup(this.state.taskWithNotes);
    }
    const askAboutAvailability = !!this.props.currentUserProfile?.askAboutAvailability;
    let availabilityDialog;
    if (
      (availabilityWeekdays || availabilityWeekends) &&
      !(this.props.currentRole as Role).consultant &&
      (askAboutAvailability || askAboutAvailability == null)
    ) {
      const now = new Date();
      const dayNumber = now.getDay();
      let needsCheck = false;
      if (availabilityWeekdays) {
        if (dayNumber > availabilityWeekdaysAskFromDay && dayNumber < WEEKDAY_SATURDAY) {
          needsCheck = true;
        } else if (
          availabilityWeekdaysAskFromDay === dayNumber &&
          now.getHours() >= availabilityWeekdaysAskFromHour
        ) {
          needsCheck = true;
        }
      }
      if (availabilityWeekends) {
        if (dayNumber > availabilityWeekendsAskFromDay) {
          // Ask everyday that is later than availabilityWeekendsAskFromDay
          needsCheck = true;
        } else if (dayNumber === availabilityWeekendsAskFromDay && now.getHours() >= 12) {
          // Ask on the defined day after 12
          needsCheck = true;
        }
      }
      if (needsCheck) {
        const {currentUserURL} = this.props;
        const {week, year} = weekNumber(now);
        const savedAvailability = this.props.availabilityArray.find((entry) => {
          return entry.user === currentUserURL && entry.weekNumber === week && entry.year === year;
        });
        if (!savedAvailability) {
          availabilityDialog = (
            <AvailabilityDialog
              open
              initialValues={savedAvailability || EMPTY_MAP}
              weekdays={availabilityWeekdays || undefined}
              weekends={availabilityWeekends}
              onOk={this.handleAvailabilityOk}
            />
          );
        }
      }
    }
    let dinnerBookingDialog;
    let dinnerBookingsActive = false;
    let lunchBookingsActive = false;
    let policyApproved;
    let termsOfServiceApproved;
    const settings = this.props.settingsArray[0];
    if (settings) {
      ({dinnerBookingsActive, lunchBookingsActive, policyApproved, termsOfServiceApproved} =
        settings);
    }

    const askAboutDinner =
      dinnerBookings &&
      dinnerBookingsActive &&
      (this.props.currentUserProfile as UserProfile).showDinnerBookingPopup;
    const askAboutLunch =
      lunchBookings &&
      lunchBookingsActive &&
      (this.props.currentUserProfile as UserProfile).showLunchBookingPopup;
    if (
      (askAboutDinner || askAboutLunch) &&
      !availabilityDialog &&
      !(this.props.currentRole as Role).consultant
    ) {
      const extraHolidays = getExtraHolidaysForUser(
        this.props.customerSettings,
        this.props.currentUserProfile,
        this.props.extraHolidaysPerRemunerationGroup,
        this.props.extraHalfHolidaysPerRemunerationGroup,
      );

      const holidayCalendars = getEmployeeHolidayCalendars(
        this.props.customerSettings,
        this.props.currentUserProfile,
      );

      const now = new Date();
      const today = dateToString(now);
      const weekDay = getHolidaySundayWeekday(
        holidayCalendars,
        today,
        extraHolidays?.getUserHoliday,
      );
      const dayBookingSettings = dinnerBookings && dinnerBookings[weekDay];
      if (dayBookingSettings) {
        const [fromHours, toHours] = dayBookingSettings;
        const fromSeconds = hourMinutesStringDaySeconds(fromHours);
        const toSeconds = hourMinutesStringDaySeconds(toHours);
        const nowSeconds = daySeconds(now);
        const needsCheck = fromSeconds <= nowSeconds && nowSeconds < toSeconds;
        if (needsCheck) {
          const {currentUser, currentUserURL} = this.props;
          let userHasDinnerBooking;
          if (askAboutDinner) {
            userHasDinnerBooking = this.props.dinnerBookingArray.some((entry) => {
              return entry.user === currentUserURL && entry.date === today;
            });
          }
          let userHasLunchBooking;
          if (askAboutLunch) {
            userHasLunchBooking = this.props.lunchBookingArray.some((entry) => {
              return entry.user === currentUserURL && entry.date === today;
            });
          }
          if (
            (askAboutDinner && !userHasDinnerBooking) ||
            (askAboutLunch && !userHasLunchBooking)
          ) {
            dinnerBookingDialog = (
              <DinnerBookingDialog
                open
                customerSettings={this.props.customerSettings}
                date={today}
                employee={currentUser as User}
                showDinnerBooking={
                  (this.props.currentUserProfile as UserProfile).showDinnerBookingPopup &&
                  !!dinnerBookings &&
                  dinnerBookingsActive
                }
                showLunchBooking={
                  (this.props.currentUserProfile as UserProfile).showLunchBookingPopup &&
                  lunchBookings &&
                  lunchBookingsActive
                }
                onOk={this.handleDinnerBookingOk}
              />
            );
          }
        }
      }
    }

    const askForPolicyApproval =
      !this.state.processorAgreementDialogDismissed &&
      policyApproved == null &&
      (this.props.currentRole as Role).owner;
    let processorAgreementDialog;
    if (askForPolicyApproval) {
      processorAgreementDialog = (
        <ProcessorAgreementDialog
          open
          onCancel={this.handleProcessorAgreementDialogCancel}
          onOk={this.handleProcessorAgreementConfirmed}
        />
      );
    }

    const askForTermsOfServiceApproval =
      !this.state.termsOfServiceDialogDismissed &&
      termsOfServiceApproved == null &&
      (this.props.currentRole as Role).owner;
    let termsOfServiceDialog;
    if (askForTermsOfServiceApproval) {
      termsOfServiceDialog = (
        <TermsOfServiceDialog
          open
          onCancel={this.handleTermsOfServiceDialogCancel}
          onOk={this.handleTermsOfServiceConfirmed}
        />
      );
    }

    const askForPrivacyPolicyApproval =
      !(this.props.currentUserProfile as UserProfile).policyApproved &&
      !(this.props.currentRole as Role).owner;
    let privacyPolicyDialog;
    if (askForPrivacyPolicyApproval) {
      privacyPolicyDialog = <PrivacyPolicyDialog open onOk={this.handlePrivacyPolicyConfirmed} />;
    }

    let unreadInformationCount = 0;
    this.props.informationArray.forEach((info) => {
      if (
        info.published &&
        (!this.props.currentUserURL || !info.seenBy.includes(this.props.currentUserURL)) &&
        informationVisibleToUser(info.visibleTo, this.props.currentUserURL || "")
      ) {
        unreadInformationCount += 1;
      }
    });

    const {menu, Page} = this.props;

    return (
      <div className={this.props.classes.root}>
        {menu === "main" ? (
          <MainMenu
            open={this.state.menuOpen}
            unreadInformationCount={unreadInformationCount}
            onClose={this.handleMenuClose}
            onRequestCheckForUpdates={this.handleRequestCheckForUpdates}
          />
        ) : null}
        {menu === "settings" ? (
          <SettingsMenu open={this.state.menuOpen} onClose={this.handleMenuClose} />
        ) : null}
        <Page onMenuButton={this.handleMenuButton} />
        <BackgroundGeolocationManager />
        <BackgroundFetchManager />
        <NotesChangedDialog task={task} onRequestClose={this.handleDialogClose} />
        <UpdateRequiredPopup />
        <TimeoutDialog
          open={this.state.updateConfirmationDialogOpen}
          timeoutSeconds={120}
          title={formatMessage(messages.updateDialogTitle)}
          onCancel={this.handleUpdateConfirmationDialogCancel}
          onOk={this.handleUpdateConfirmationDialogOk}
        >
          <DialogContent>
            <FormattedMessage
              defaultMessage="Der er en opdatering; indlæs?"
              id="page-with-menu.label.new-software-available"
            />
          </DialogContent>
        </TimeoutDialog>
        <ResponsiveInfoDialog
          closeLabel={
            <FormattedMessage defaultMessage="Genstart" id="page-with-menu.label.reload" />
          }
          open={!!this.props.localDbUpdateError}
          title={
            <FormattedMessage defaultMessage="Ups!" id="page-with-menu.dialog-title.db-error" />
          }
          onClose={handleReloadClick}
        >
          <DialogContent>
            <FormattedMessage
              defaultMessage="En fejl har gjort at CustomOffice skal genstartes."
              id="page-with-menu.text.db-error"
            />
          </DialogContent>
        </ResponsiveInfoDialog>
        <TaskReceivedDialog
          open={!!this.state.taskIdFromNotification}
          onCancel={this.handleTaskReceivedDialogCancel}
          onOk={this.handleTaskReceivedDialogOk}
        />
        <TimerStartNotificationDialog
          open={!!this.state.timerStartIdFromNotification}
          timerStartId={this.state.timerStartIdFromNotification}
          onCancel={this.handleTimerStartNotificationDialogCancel}
          onOk={this.handleTimerStartNotificationDialogOk}
        />
        <InformationReceivedDialog
          open={!!this.state.informationIdFromNotification}
          onCancel={this.handleInformationReceivedDialogCancel}
          onOk={this.handleInformationReceivedDialogOk}
        />
        <ResponsiveInfoDialog
          closeLabel={<FormattedMessage defaultMessage="Luk" id="page-with-menu.label.close" />}
          open={
            this.state.missingGPSAuthorizationDialogOpen && !this.state.versionWarningDialogOpen
          }
          title={<FormattedMessage defaultMessage="Mangler adgang til GPS" />}
          onClose={this.handleMissingGPSAuthorizationDialogClose}
        >
          <DialogContent>
            {!this.state.missingGPSAuthorizationStatus?.locationServicesEnabled ? (
              <FormattedMessage
                defaultMessage="Tænd GPS på din telefon"
                id="page-with-menu.text.gps-service-error"
                tagName="div"
              />
            ) : null}
            {this.state.missingGPSAuthorizationStatus?.authorization ===
            AuthorizationStatus.NOT_AUTHORIZED ? (
              <FormattedMessage
                defaultMessage="Giv CustomOffice adgang til GPS"
                id="page-with-menu.text.gps-access-error"
              />
            ) : null}
            {this.state.missingGPSAuthorizationStatus?.authorization ===
            AuthorizationStatus.AUTHORIZED_FOREGROUND ? (
              <FormattedMessage
                defaultMessage='Tilladelse til GPS er sat til "Kun ved brug", den skal være sat til "Altid"'
                id="page-with-menu.text.gps-only-foreground-access-error"
              />
            ) : null}
          </DialogContent>
        </ResponsiveInfoDialog>

        <ResponsiveInfoDialog
          closeLabel={<FormattedMessage defaultMessage="Luk" id="page-with-menu.label.close" />}
          open={this.state.versionWarningDialogOpen}
          title={<FormattedMessage defaultMessage="Du skal opdatere din CustomOffice app" />}
          onClose={this.handleVersionWarningDialogClose}
        >
          <DialogContent>
            <FormattedMessage
              defaultMessage="Din telefon er ikke opdateret med den nyeste app"
              id="app.warning.updateText"
            />
            <br />
            <a
              href={
                bowser.ios
                  ? (window as any).GUEST_APP
                    ? "https://apps.apple.com/dk/app/customoffice/id1539402487?mt=8"
                    : "https://apps.apple.com/dk/app/customoffice/id1151413146?mt=8"
                  : (window as any).GUEST_APP
                    ? "http://play.google.com/store/apps/details?id=dk.customoffice.guestapp"
                    : "http://play.google.com/store/apps/details?id=dk.customoffice.app"
              }
              style={{fontWeight: "bold"}}
            >
              <FormattedMessage
                defaultMessage="Klik her for at opdatere"
                id="app.warning.updateLink"
              />
            </a>
          </DialogContent>
        </ResponsiveInfoDialog>
        <NotificationDialog
          open={
            this.props.notificationDialogOpen &&
            !availabilityDialog &&
            !dinnerBookingDialog &&
            !processorAgreementDialog &&
            !termsOfServiceDialog &&
            !privacyPolicyDialog
          }
        />
        {availabilityDialog}
        {dinnerBookingDialog}
        {processorAgreementDialog}
        {termsOfServiceDialog}
        {privacyPolicyDialog}
      </div>
    );
  }
}

const StyledPageWithMenu = withStyles((theme) => ({
  root:
    bowser.tablet || bowser.mobile
      ? {height: "100%", minHeight: "100%"}
      : {
          height: "100%",
          minHeight: "100%",
          [theme.breakpoints.up("md")]: {
            marginLeft: DRAWER_WIDTH,
          },
        },
}))(PageWithMenuImpl);

export const PageWithMenu: React.ComponentType<PageWithMenuOwnProps> = connect<
  PageWithMenuStateProps,
  PageWithMenuDispatchProps,
  PageWithMenuOwnProps,
  AppState
>(
  createStructuredSelector<AppState, PageWithMenuStateProps>({
    availabilityArray: getAvailabilityArray,
    currentRole: getCurrentRole,
    currentUser: getCurrentUser,
    currentUserActiveTaskURLArray: getCurrentUserActiveTaskURLArray,
    currentUserProfile: getCurrentUserProfile,
    currentUserURL: getCurrentUserURL,
    customerSettings: getCustomerSettings,
    dinnerBookingArray: getDinnerBookingArray,
    extraHalfHolidaysPerRemunerationGroup: getExtraHalfHolidaysPerRemunerationGroup,
    extraHolidaysPerRemunerationGroup: getExtraHolidaysPerRemunerationGroup,
    informationArray: getInformationArray,
    localDbUpdateError: getLocalDbUpdateError,
    lunchBookingArray: getLunchBookingArray,
    notificationDialogOpen: getNotificationDialogOpen,
    registrationId: getDeviceConfigKey("registrationId"),
    settingsArray: getSettingsArray,
    taskLookup: getTaskLookup,
    temporaryQueries: getTemporaryQueries,
    timerStartArray: getTimerStartArray,
    userId: getUserId,
  }),
  {
    configPut: actions.configPut,
    create: actions.create,
    // remove: actions.remove,
    go: actions.go,
    setMessage: actions.setMessage,
    temporaryQueriesRequestedForKey: actions.temporaryQueriesRequestedForKey,
    update: actions.update,
  },
)(StyledPageWithMenu);
