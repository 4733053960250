import {identifierComparator, makeContainsPredicate} from "@co-common-libs/utils";
import {TrimTextField} from "@co-frontend-libs/components";
import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  SvgIcon,
} from "@material-ui/core";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import React from "react";
import {IntlContext, defineMessages} from "react-intl";
import {MultiSelectableList} from "./multi-selectable-list";

const messages = defineMessages({
  onlySelected: {
    defaultMessage: "Vis kun valgte",
    id: "multi-selectable-list-card.label.show-only-selected",
  },
});

interface MultiSelectableListCardProps<T extends string> {
  data: readonly {
    readonly primaryText: string;
    readonly secondaryText: string;
    readonly value: T;
  }[];
  description?: string;
  emptyPrimaryText?: string | undefined;
  emptySecondaryText?: string;
  noFilter?: boolean;
  onChange: (event: React.SyntheticEvent<unknown>, value: ReadonlySet<T>) => void;
  selectedFilter?: boolean;
  subtitle?: string;
  title?: string;
  useRangeFilter?: boolean;
  value: ReadonlySet<T>;
}

interface MultiSelectableListCardState {
  filterString: string;
  onlySelected: boolean;
}

export class MultiSelectableListCard<T extends string> extends PureComponent<
  MultiSelectableListCardProps<T>,
  MultiSelectableListCardState
> {
  static defaultProps = {
    noFilter: false,
    selectedFilter: false,
    style: {
      height: "100%",
    },
    useRangeFilter: false,
  };
  state = {
    filterString: "",
    onlySelected: false,
  };
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  @bind
  handleFilterFieldChange(value: string): void {
    this.setState({filterString: value});
  }
  @bind
  handleFilterFieldKeyDown(event: React.KeyboardEvent<unknown>): void {
    if (event.key === "Enter") {
      const filteredData = this.getFilteredData();
      if (filteredData.length === 1) {
        const entry = filteredData[0];
        const itemValue = entry.value;
        const oldSet = this.props.value;
        const currentlyPresent = oldSet.has(itemValue);
        const newSet = new Set(oldSet);
        if (currentlyPresent) {
          newSet.delete(itemValue);
        } else {
          newSet.add(itemValue);
        }
        this.props.onChange(event, new Set(Array.from(newSet).sort()));
      }
    }
  }
  @bind
  handleOnlySelectedCheck(event: React.ChangeEvent<HTMLInputElement>): void {
    const {checked} = event.target;
    this.setState({onlySelected: checked});
  }
  getFilteredData(): readonly {
    readonly primaryText: string;
    readonly secondaryText?: string | undefined;
    readonly value: T;
  }[] {
    let {data} = this.props;
    let {filterString} = this.state;
    if (filterString) {
      const count = filterString.split(";").length - 1;
      if (count === 1 && this.props.useRangeFilter) {
        const filterRegexp = /(\d+);(\d+)/g;
        const match = filterRegexp.exec(filterString);
        if (match) {
          filterString = filterString.replace(match[0], "");
          data = data.filter(
            (x) =>
              identifierComparator(x.secondaryText, match[1]) >= 0 &&
              identifierComparator(x.secondaryText, match[2]) <= 0,
          );
        }
      }
      const checkString = makeContainsPredicate(filterString);
      return data.filter((x) => checkString(`${x.primaryText} ${x.secondaryText || ""}`));
    } else {
      return data;
    }
  }
  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {
      description,
      emptyPrimaryText,
      emptySecondaryText,
      noFilter,
      onChange,
      selectedFilter,
      subtitle,
      title,
      value,
    } = this.props;
    const {filterString} = this.state;
    let filteredData = this.getFilteredData();
    if (this.state.onlySelected) {
      filteredData = filteredData.filter((entry) => value.has(entry.value));
    }
    let descriptionBlock;
    if (description) {
      descriptionBlock = <CardContent>{description}</CardContent>;
    }
    let titleBlock;
    if (title || subtitle) {
      titleBlock = <CardHeader subheader={subtitle} title={title} />;
    }
    let filterBlock;
    if (!noFilter) {
      filterBlock = (
        <CardContent>
          <TrimTextField
            margin="dense"
            name={`filter-string-${title}`}
            style={{width: "calc(100% - 48px)"}}
            value={filterString}
            variant="outlined"
            onChange={this.handleFilterFieldChange}
            onKeyDown={this.handleFilterFieldKeyDown}
          />
          <SvgIcon style={{marginLeft: 12, marginTop: 12}}>
            <MagnifyIcon />
          </SvgIcon>
        </CardContent>
      );
    }
    let selectedFilterBlock;
    if (selectedFilter) {
      selectedFilterBlock = (
        <CardContent>
          <FormControlLabel
            control={
              <Checkbox checked={this.state.onlySelected} onChange={this.handleOnlySelectedCheck} />
            }
            label={formatMessage(messages.onlySelected)}
          />
        </CardContent>
      );
    }
    return (
      <Card
        style={{
          display: "inline-flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        }}
      >
        {titleBlock}
        {selectedFilterBlock}
        {filterBlock}
        {descriptionBlock}
        <MultiSelectableList
          data={filteredData}
          emptyPrimaryText={emptyPrimaryText}
          emptySecondaryText={emptySecondaryText}
          style={{flex: 1, overflowY: "scroll"}}
          value={value}
          onChange={onChange}
        />
      </Card>
    );
  }
}
