import {UserUrl} from "@co-common-libs/resources";
import {dateToString} from "@co-common-libs/utils";
import {DateField, ResponsiveDialog} from "@co-frontend-libs/components";
import {getCustomerSettings, getUserUserProfileLookup} from "@co-frontend-libs/redux";
import {DialogContent, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import {upperFirst} from "lodash";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {defineMessages, useIntl} from "react-intl";
import {useSelector} from "react-redux";

const messages = defineMessages({
  accommodationAllowanceDefaultLabel: {
    defaultMessage: "Diæt",
    id: "accomodation-allowance-dialog.label.accomodation-allowance-default",
  },
  fromDate: {
    defaultMessage: "Fra dato",
    id: "accomodation-allowance-dialog.label.from-date",
  },
  registerAccommodationAllowanceDialogTitle: {
    defaultMessage: "Registrer diæt/udetillæg",
    id: "accomodation-allowance-dialog.dialog-title.register-accomodation-allowance",
  },
  toDate: {
    defaultMessage: "Til dato",
    id: "accomodation-allowance-dialog.label.to-date",
  },
});

interface AccomodationAllowanceDialogProps {
  date?: string | undefined;
  employee?: UserUrl | undefined;
  onCancel: () => void;
  onOk: (fromDate: string, toDate: string, remunerationGroup: string, employee: UserUrl) => void;
  open: boolean;
}

export const AccomodationAllowanceDialog = React.memo(function AccomodationAllowanceDialog(
  props: AccomodationAllowanceDialogProps,
): JSX.Element {
  const {date, employee, onCancel, onOk, open} = props;
  const [fromDate, setFromDate] = useState<string | null>(date || dateToString(new Date()));
  const [toDate, setToDate] = useState<string | null>(fromDate);

  useEffect(() => {
    if (open) {
      const dateString = date || dateToString(new Date());
      setFromDate(dateString);
      setToDate(dateString);
    }
  }, [date, open]);

  const handleFromDateBlur = useCallback(() => {
    if (fromDate && (!toDate || fromDate > toDate)) {
      setToDate(fromDate);
    }
  }, [fromDate, toDate]);

  const handleToDateBlur = useCallback(() => {
    if (toDate && (!fromDate || fromDate > toDate)) {
      setFromDate(toDate);
    }
  }, [fromDate, toDate]);

  const customerSettings = useSelector(getCustomerSettings);

  const userUserProfileLookup = useSelector(getUserUserProfileLookup);

  const employeeDefaultRemunerationGroup = useMemo((): string => {
    const userProfile = employee != null ? userUserProfileLookup(employee) : undefined;
    return userProfile?.remunerationGroup || customerSettings.remunerationDefaultGroup;
  }, [customerSettings.remunerationDefaultGroup, employee, userUserProfileLookup]);

  const potentialRemunerationGroups = useMemo((): readonly string[] => {
    const defaultGroup = customerSettings.remunerationGroups[employeeDefaultRemunerationGroup];
    const employeeGroups =
      defaultGroup && defaultGroup.switchGroups && defaultGroup.switchGroups.length
        ? [employeeDefaultRemunerationGroup].concat(
            defaultGroup.switchGroups.map((entry) => entry.identifier),
          )
        : [employeeDefaultRemunerationGroup];
    return employeeGroups.filter(
      (identifier) => customerSettings.remunerationGroups[identifier]?.accomodationAllowance,
    );
  }, [customerSettings.remunerationGroups, employeeDefaultRemunerationGroup]);

  const [remunerationGroup, setRemunerationGroup] = useState(
    potentialRemunerationGroups.length ? potentialRemunerationGroups[0] : null,
  );

  const {formatMessage} = useIntl();

  const getRemunerationGroupAccomodationLabel = useCallback(
    (groupID: string): string =>
      upperFirst(
        customerSettings.remunerationGroups[groupID]?.accomodationAllowanceLabel ||
          formatMessage(messages.accommodationAllowanceDefaultLabel),
      ),
    [customerSettings.remunerationGroups, formatMessage],
  );

  const handleRemunerationGroupChange = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, value: string) => {
      if (potentialRemunerationGroups.includes(value)) {
        setRemunerationGroup(value);
      }
    },
    [potentialRemunerationGroups],
  );

  useEffect(() => {
    if (open) {
      setRemunerationGroup(potentialRemunerationGroups[0]);
    }
  }, [open, potentialRemunerationGroups]);

  const handleOk = useCallback(() => {
    if (fromDate && toDate && remunerationGroup && employee) {
      onOk(fromDate, toDate, remunerationGroup, employee);
    }
  }, [employee, fromDate, onOk, remunerationGroup, toDate]);

  const okDisabled = !fromDate || !toDate || fromDate > toDate;

  return (
    <ResponsiveDialog
      okDisabled={okDisabled}
      open={open}
      title={formatMessage(messages.registerAccommodationAllowanceDialogTitle)}
      onCancel={onCancel}
      onOk={handleOk}
    >
      <DialogContent>
        <DateField
          autoFocus
          autoOk
          fullWidth
          label={formatMessage(messages.fromDate)}
          margin="dense"
          value={fromDate}
          onBlur={handleFromDateBlur}
          onChange={setFromDate}
        />
        <DateField
          autoOk
          fullWidth
          label={formatMessage(messages.toDate)}
          margin="dense"
          value={toDate}
          onBlur={handleToDateBlur}
          onChange={setToDate}
        />
        {potentialRemunerationGroups.length > 1 ? (
          <RadioGroup
            name="accomodation-allowance-remuneration-group"
            value={remunerationGroup}
            onChange={handleRemunerationGroupChange}
          >
            {potentialRemunerationGroups.map((groupID) => (
              <FormControlLabel
                key={groupID}
                control={<Radio />}
                label={getRemunerationGroupAccomodationLabel(groupID)}
                value={groupID}
              />
            ))}
          </RadioGroup>
        ) : null}
      </DialogContent>
    </ResponsiveDialog>
  );
});
