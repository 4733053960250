import {DebouncedAppbarSearchField} from "@co-frontend-libs/components";
import {getCustomerSettings} from "@co-frontend-libs/redux";
import {MenuToolbar, PageLayout} from "app-components";
import {useQueryParameterState} from "app-utils";
import React, {useState} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {ShowInactiveToggle} from "../shared/show-inactive-toggle";
import {MachineList} from "./machine-list";

interface MachinesSettingsPageProps {
  onMenuButton: (event: React.MouseEvent) => void;
}

export const MachinesSettingsPage = React.memo(function MachinesSettingsPage(
  props: MachinesSettingsPageProps,
): JSX.Element {
  const intl = useIntl();
  const customerSettings = useSelector(getCustomerSettings);

  const [filter, setFilter] = useQueryParameterState<string>("q", "");
  const [showInactive, setShowInactive] = useState(false);

  const title =
    customerSettings.machineLabelVariant === "MACHINE"
      ? intl.formatMessage({defaultMessage: "Maskiner"})
      : intl.formatMessage({defaultMessage: "Køretøjer"});

  return (
    <PageLayout
      withBottomScrollPadding
      toolbar={
        <MenuToolbar
          rightElement={
            <>
              <ShowInactiveToggle setShowInactive={setShowInactive} showInactive={showInactive} />
              <DebouncedAppbarSearchField
                debounceTimeout={200}
                value={filter}
                onChange={setFilter}
              />
            </>
          }
          title={title}
          onMenuButton={props.onMenuButton}
        />
      }
    >
      <MachineList showInactive={showInactive} />
    </PageLayout>
  );
});
