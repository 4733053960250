import {simpleArraysEqual} from "@co-common-libs/utils";
import {DependencyList, useRef} from "react";

/**
 * Memoize computation, but reuse previous result unless
 * `condition` is `true` *and* `deps` have changed since previous computation.
 *
 * Intended for use in dialog implementations, where the component should be
 * kept mounted on transition from open to closed to show the closing
 * animation, but we don't want to spend time on computing/recomputing content
 * for closed dialogs.
 */
export function useConditionalMemo<R>(
  fn: () => R,
  deps: DependencyList,
  condition: boolean,
  initialResult: R,
): R {
  const cachedValue = useRef<R>(initialResult);
  const cachedDeps = useRef<DependencyList>();

  if (condition && (!cachedDeps.current || !simpleArraysEqual(cachedDeps.current, deps))) {
    cachedValue.current = fn();
    cachedDeps.current = deps;
  }

  return cachedValue.current;
}
