import _ from "lodash";

export function findFuelSurchargeBasis<
  SpecificationUrl extends string,
  Basis extends {
    readonly fromDate: string | null;
    readonly specification: SpecificationUrl;
  },
>(basisArray: readonly Basis[], specificationUrl: SpecificationUrl, date: string): Basis | null {
  const basisCandidates = basisArray.filter(
    (basis) =>
      basis.specification === specificationUrl && (!basis.fromDate || basis.fromDate <= date),
  );
  return _.maxBy(basisCandidates, (basis) => basis.fromDate || "0000-00-00") ?? null;
}
