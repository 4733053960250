import {IntlShape} from "react-intl";
import {EconomicProductsIssue} from "../types";

// eslint-disable-next-line consistent-return
export function economicProductIssueTranslation(
  issue: EconomicProductsIssue,
  intl: IntlShape,
): string {
  const {instances, issueType} = issue;
  const count = instances.length;
  const names = instances.map((product) => product.name).join(", ");

  switch (issueType) {
    case "missingRemoteUrl":
      return intl.formatMessage(
        {
          defaultMessage:
            "{count, plural, one {Følgende vare} other {# varer}} er ikke oprettet i e-conomic: {names}",
        },
        {count, names},
      );
    case "barred":
      return intl.formatMessage(
        {
          defaultMessage:
            "{count, plural, one {Følgende vare} other {# varer}} er blevet slettet eller spærret i e-conomic: {names}",
        },
        {count, names},
      );
    case "inactive":
      return intl.formatMessage(
        {
          defaultMessage:
            "{count, plural, one {Følgende vare} other {# varer}} er markeret inaktiv: {names}",
        },
        {count, names},
      );
  }
}
