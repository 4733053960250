import {SettingID, schema} from "@co-common-libs/config";
import React from "react";
import {useIntl} from "react-intl";
import {ListColumnsDialog} from "./list-columns-dialog";

interface TaskListColumnsDialogProps {
  onClose: () => void;
  open: boolean;
  settingID: SettingID;
}

export function TaskListColumnsDialog(props: TaskListColumnsDialogProps): JSX.Element {
  const intl = useIntl();
  const columnChoices = schema.definitions.taskListColumnsColumnChoices.items.enum;
  type ColumnChoice = (typeof columnChoices)[number];
  type ListIdentifier = keyof typeof schema.properties.taskListColumns.properties;
  const listIdentifiers = Object.keys(
    schema.properties.taskListColumns.properties,
  ) as ListIdentifier[];
  return (
    <ListColumnsDialog<ColumnChoice, ListIdentifier>
      title={intl.formatMessage({
        defaultMessage: "Kolonner på opgavelisterne",
      })}
      {...props}
      columnChoices={columnChoices}
      listIdentifiers={listIdentifiers}
    />
  );
}
