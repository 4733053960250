import {UnitUrl} from "@co-common-libs/resources";
import {caseAccentInsensitiveCollator} from "@co-common-libs/utils";
import {getUnitArray} from "@co-frontend-libs/redux";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import React, {useCallback} from "react";
// Allowed for existing code...
// eslint-disable-next-line deprecate/import
import {Cell} from "react-flexr";
import {defineMessages, useIntl} from "react-intl";
import {useSelector} from "react-redux";

const messages = defineMessages({
  unit: {
    defaultMessage: "Enhed",
    id: "unit-select.label.unit",
  },
  unitChoice: {
    defaultMessage: "Vælg enhed *",
    id: "unit-select.label.unit-choice",
  },
});

interface UnitSelectProps {
  onSelectUnitChange: (value: UnitUrl) => void;
  relatedUnit?: UnitUrl | null | undefined;
  useGrid?: boolean;
}

export const UnitSelect = ({
  onSelectUnitChange,
  relatedUnit,
  useGrid,
}: UnitSelectProps): JSX.Element => {
  const {formatMessage} = useIntl();

  const handleUnitChanged = useCallback(
    (
      event: React.ChangeEvent<{name?: string | undefined; value: unknown}>,
      _child: React.ReactNode,
    ): void => {
      const {value} = event.target;
      onSelectUnitChange(value as UnitUrl);
    },
    [onSelectUnitChange],
  );

  const unitArray = useSelector(getUnitArray);

  let unitSelect;
  const dropdownUnits = unitArray
    .filter((u) => u.active)
    .sort((a, b) => caseAccentInsensitiveCollator.compare(a.name || a.symbol, b.name || b.symbol));
  if (dropdownUnits.length) {
    unitSelect = (
      <FormControl fullWidth>
        <InputLabel id="unit-select-label">{formatMessage(messages.unitChoice)}</InputLabel>
        <Select
          id="unit-select"
          labelId="unit-select-label"
          value={relatedUnit ? relatedUnit : ""}
          onChange={handleUnitChanged}
        >
          {dropdownUnits.map((u) => (
            <MenuItem key={u.url} value={u.url}>
              {u.name || u.symbol}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  if (useGrid) {
    return <Cell palm="12/12">{unitSelect}</Cell>;
  } else {
    return <div>{unitSelect}</div>;
  }
};
