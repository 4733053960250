// TODO(mr): we can most likely toss these polyfills - investigate and remove if possible
// Polyfill; Intl API missing in Safari < 10 and IE < 11
// Load API polyfill before data...
import "@formatjs/intl-locale/polyfill";
import "@formatjs/intl-pluralrules/locale-data/da";
import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-relativetimeformat/locale-data/da";
import "@formatjs/intl-relativetimeformat/polyfill";
import {dateFromString} from "./dates";

export const locale = "da";

export const caseAccentInsensitiveCollator = new Intl.Collator(locale, {
  sensitivity: "base",
});

export const dateFormat = new Intl.DateTimeFormat(locale, {
  day: "2-digit",
  month: "2-digit",
  weekday: "short",
  year: "numeric",
});

export const numericDateFormat = new Intl.DateTimeFormat(locale, {
  day: "numeric",
  month: "numeric",
  year: "numeric",
});

export const shortDateFormat = new Intl.DateTimeFormat(locale, {
  day: "numeric",
  month: "numeric",
});

export const shortMonthDateFormat = new Intl.DateTimeFormat(locale, {
  month: "numeric",
  year: "numeric",
});

export const monthDateFormat = new Intl.DateTimeFormat(locale, {
  month: "long",
  year: "numeric",
});

export const timeFormat = new Intl.DateTimeFormat(locale, {
  hour: "2-digit",
  minute: "2-digit",
});

export const dateTimeFormat = new Intl.DateTimeFormat(locale, {
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  month: "2-digit",
  weekday: "short",
  year: "numeric",
});

export const shortDateTimeFormat = new Intl.DateTimeFormat(locale, {
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  month: "numeric",
});

export const dateTimeFormatWithoutWeekday = new Intl.DateTimeFormat(locale, {
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  month: "2-digit",
  year: "numeric",
});

export const shortDateWeekdayFormat = new Intl.DateTimeFormat(locale, {
  day: "numeric",
  month: "numeric",
  weekday: "short",
});

function toDate(date: Date | string): Date {
  if (typeof date === "string") {
    if (date.indexOf("T") !== -1) {
      return new Date(date);
    } else {
      return dateFromString(date) as Date;
    }
  }
  return date;
}

export const formatDate = (date?: Date | string | null): string => {
  return date ? dateFormat.format(toDate(date)) : "";
};

export const formatDateNumeric = (date?: Date | string | null): string => {
  return date ? numericDateFormat.format(toDate(date)) : "";
};

export const formatDateShort = (date?: Date | string | null): string => {
  return date ? shortDateFormat.format(toDate(date)) : "";
};

const yearMonthLength = "YYYY-MM".length;

function monthToDate(date: Date | string): Date {
  if (typeof date === "string") {
    if (date.indexOf("T") !== -1) {
      return new Date(date);
    } else if (date.length === yearMonthLength) {
      return dateFromString(`${date}-01`) as Date;
    } else {
      return dateFromString(date) as Date;
    }
  }
  return date;
}

export const formatMonthDateShort = (date?: Date | string): string => {
  return date ? shortMonthDateFormat.format(monthToDate(date)) : "";
};

export const formatMonthDate = (date?: Date | string): string => {
  return date ? monthDateFormat.format(monthToDate(date)) : "";
};

export const formatTime = (timestamp?: Date | string): string => {
  if (!timestamp) {
    return "";
  }
  if (typeof timestamp === "string") {
    if (timestamp.indexOf("T") !== -1) {
      // ISO-datetime-string; i.e. some variant of YYYY-MM-DDTHH:mm:ss.sssZ
      // (Number of decimals for seconds from backend varies...)
      return timeFormat.format(new Date(timestamp));
    } else {
      const date = new Date();
      const [hours, minutes] = timestamp.split(":");
      date.setHours(parseInt(hours), parseInt(minutes));
      return timeFormat.format(date);
    }
  } else {
    return timeFormat.format(timestamp);
  }
};

function timestampToDate(timestamp: Date | string): Date {
  if (typeof timestamp === "string") {
    return new Date(timestamp);
  } else {
    return timestamp;
  }
}

export const formatDateTime = (timestamp?: Date | string | null): string => {
  return timestamp ? dateTimeFormat.format(timestampToDate(timestamp)) : "";
};

export const formatDateTimeWithoutWeekday = (timestamp?: Date | string | null): string => {
  return timestamp ? dateTimeFormatWithoutWeekday.format(timestampToDate(timestamp)) : "";
};

export const formatDateTimeShort = (timestamp?: Date | string | null): string => {
  return timestamp ? shortDateTimeFormat.format(timestampToDate(timestamp)) : "";
};

export const formatDateWeekdayShort = (date: Date | string | null): string => {
  return date ? shortDateWeekdayFormat.format(toDate(date)) : "";
};

// component: this.context.intl.formatDate(options) ...
// locale is set; existing DateTimeFormat-options are reused when locale and options match...
// the memoization logic is not free, though; based on JSON-serialization of parameter list, with extra logic to use a copy of any option-object with sorted keys

// Dates with weekdays 0--6 / sun--sat
const week = [
  new Date(2000, 0, 2, 12),

  new Date(2000, 0, 3, 12),

  new Date(2000, 0, 4, 12),

  new Date(2000, 0, 5, 12),

  new Date(2000, 0, 6, 12),

  new Date(2000, 0, 7, 12),

  new Date(2000, 0, 8, 12),
];

const weekDayNarrowFormatter = new Intl.DateTimeFormat(locale, {
  weekday: "narrow",
});

export const dayLetters = week.map((date) => weekDayNarrowFormatter.format(date));
