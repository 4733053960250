import {PriceGroup, PriceGroupUrl, WorkType, WorkTypeUrl, urlToId} from "@co-common-libs/resources";
import {mapSet, mapUpdate, notNull, notUndefined, setAdd} from "@co-common-libs/utils";
import {PriceGroupDialog, WorkTypeDialog} from "@co-frontend-libs/components";
import {getPriceGroupLookup, getWorkTypeLookup} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {Button, useTheme} from "@material-ui/core";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import React, {useCallback, useMemo, useState} from "react";
import {FormattedMessage, defineMessages, useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {DeletableChip} from "../deletable-chip";
import {FilterOptions} from "../types";

const messages = defineMessages({
  allVariants: {
    defaultMessage: "Alle",
    id: "transport-report.label.all-work-type-variants",
  },
});

function getPotentialPriceGroupsForWorkType(
  options: FilterOptions["workTypes"],
  workTypeURL: WorkTypeUrl,
): readonly (PriceGroupUrl | null)[] {
  return options.find((entry) => entry.workType === workTypeURL)?.priceGroups || [];
}

const disabledWorkTypes = [] as const;

interface WorkTypeFilteringProps {
  onSelectedWorkTypesPriceGroupsChange: (
    newState: ReadonlyMap<WorkTypeUrl, ReadonlySet<PriceGroupUrl>>,
  ) => void;
  options: FilterOptions["workTypes"];
  selectedWorkTypesPriceGroups: ReadonlyMap<WorkTypeUrl, ReadonlySet<PriceGroupUrl>>;
}

export function WorkTypeFiltering(props: WorkTypeFilteringProps): JSX.Element {
  const {onSelectedWorkTypesPriceGroupsChange, options, selectedWorkTypesPriceGroups} = props;

  const priceGroupLookup = useSelector(getPriceGroupLookup);
  const workTypeLookup = useSelector(getWorkTypeLookup);

  const [workTypeDialogOpen, setWorkTypeDialogOpen] = useState(false);
  const setWorkTypeDialogOpenTrue = useCallWithTrue(setWorkTypeDialogOpen);
  const setWorkTypeDialogOpenFalse = useCallWithFalse(setWorkTypeDialogOpen);

  const [priceGroupDialogOpenForWorkType, setPriceGroupDialogOpenForWorkType] =
    useState<WorkTypeUrl | null>(null);
  const handleWorkTypeDialogOk = useCallback(
    (url: WorkTypeUrl) => {
      setWorkTypeDialogOpen(false);
      const potentialPriceGroups = getPotentialPriceGroupsForWorkType(options, url);
      if (potentialPriceGroups.length <= 1) {
        onSelectedWorkTypesPriceGroupsChange(
          mapSet(selectedWorkTypesPriceGroups, url, new Set(potentialPriceGroups.filter(notNull))),
        );
      } else {
        setPriceGroupDialogOpenForWorkType(url);
      }
    },
    [options, selectedWorkTypesPriceGroups, onSelectedWorkTypesPriceGroupsChange],
  );
  const handlePriceGroupsForWorkTypeDialogCancel = useCallback(() => {
    setPriceGroupDialogOpenForWorkType(null);
  }, []);
  const handlePriceGroupsForWorkTypeDialogOk = useCallback(
    (url: PriceGroupUrl | null) => {
      if (priceGroupDialogOpenForWorkType) {
        setPriceGroupDialogOpenForWorkType(null);
        if (url) {
          onSelectedWorkTypesPriceGroupsChange(
            mapUpdate(selectedWorkTypesPriceGroups, priceGroupDialogOpenForWorkType, (urls) =>
              setAdd(urls, url),
            ),
          );
        } else {
          onSelectedWorkTypesPriceGroupsChange(
            mapSet(selectedWorkTypesPriceGroups, priceGroupDialogOpenForWorkType, new Set()),
          );
        }
      }
    },
    [
      priceGroupDialogOpenForWorkType,
      selectedWorkTypesPriceGroups,
      onSelectedWorkTypesPriceGroupsChange,
    ],
  );
  const handlePriceGroupsForWorkTypeDialogNone = useCallback(() => {
    handlePriceGroupsForWorkTypeDialogOk(null);
  }, [handlePriceGroupsForWorkTypeDialogOk]);
  const potentialWorkTypes = useMemo(
    () => options.map((entry) => workTypeLookup(entry.workType)).filter(notUndefined),
    [options, workTypeLookup],
  );
  const potentialPriceGroupsForWorkType = useMemo(() => {
    if (priceGroupDialogOpenForWorkType) {
      return getPotentialPriceGroupsForWorkType(options, priceGroupDialogOpenForWorkType)
        .filter(notNull)
        .map(priceGroupLookup)
        .filter(notUndefined);
    } else {
      return [];
    }
  }, [options, priceGroupDialogOpenForWorkType, priceGroupLookup]);

  const handleDeleteWorkTypePriceGroup = useCallback(
    (workTypePriceGroupIdPair: string): void => {
      const [workTypeId, priceGroupId] = workTypePriceGroupIdPair.split(",");
      const workTypeURL = instanceURL("workType", workTypeId);
      const priceGroupURL = instanceURL("priceGroup", priceGroupId);
      const newSelectedWorkTypesPriceGroups = new Map(selectedWorkTypesPriceGroups);
      const oldWorkTypePriceGroups = newSelectedWorkTypesPriceGroups.get(workTypeURL);
      if (oldWorkTypePriceGroups) {
        const newWorkTypePriceGroups = new Set(oldWorkTypePriceGroups);
        newWorkTypePriceGroups.delete(priceGroupURL);
        if (newWorkTypePriceGroups.size) {
          newSelectedWorkTypesPriceGroups.set(workTypeURL, newWorkTypePriceGroups);
        } else {
          newSelectedWorkTypesPriceGroups.delete(workTypeURL);
        }
      }
      onSelectedWorkTypesPriceGroupsChange(newSelectedWorkTypesPriceGroups);
    },
    [selectedWorkTypesPriceGroups, onSelectedWorkTypesPriceGroupsChange],
  );

  const handleDeleteWorkType = useCallback(
    (workTypeId: string): void => {
      const newSelectedWorkTypesPriceGroups = new Map(selectedWorkTypesPriceGroups);
      newSelectedWorkTypesPriceGroups.delete(instanceURL("workType", workTypeId));
      onSelectedWorkTypesPriceGroupsChange(newSelectedWorkTypesPriceGroups);
    },
    [selectedWorkTypesPriceGroups, onSelectedWorkTypesPriceGroupsChange],
  );

  const {formatMessage} = useIntl();

  const theme = useTheme();

  return (
    <>
      <div>
        <h4>
          <FormattedMessage defaultMessage="Områder" id="transport-report.label.work-types" />
        </h4>
        <Button color="primary" variant="contained" onClick={setWorkTypeDialogOpenTrue}>
          <FormattedMessage
            defaultMessage="Tilføj område"
            id="transport-report.label.add-work-type"
          />
        </Button>
        <div>
          {_.sortBy(
            Array.from(selectedWorkTypesPriceGroups.entries())
              .map(
                ([workTypeURL, priceGroupURLs]):
                  | {priceGroups: PriceGroup[]; workType: WorkType}
                  | undefined => {
                  const workType = workTypeLookup(workTypeURL);
                  if (!workType) {
                    return undefined;
                  }
                  const priceGroups = _.sortBy(
                    Array.from(priceGroupURLs).map(priceGroupLookup).filter(notUndefined),
                    (priceGroup) => priceGroup.name,
                  );
                  return {priceGroups, workType};
                },
              )
              .filter(notUndefined),
            ({workType}) => workType.name,
          ).map(({priceGroups, workType}) => {
            if (priceGroups.length) {
              return priceGroups.map((priceGroup) => {
                const key = `${urlToId(workType.url)},${urlToId(priceGroup.url)}`;
                return (
                  <span
                    key={key}
                    style={{
                      marginRight: theme.spacing(0.5),
                    }}
                  >
                    <DeletableChip
                      deletionId={key}
                      label={`${workType.name} (${priceGroup.name})`}
                      onDelete={handleDeleteWorkTypePriceGroup}
                    />
                  </span>
                );
              });
            } else {
              const key = urlToId(workType.url);
              return (
                <span
                  key={key}
                  style={{
                    marginRight: theme.spacing(0.5),
                  }}
                >
                  <DeletableChip
                    key={key}
                    deletionId={key}
                    label={workType.name}
                    onDelete={handleDeleteWorkType}
                  />
                </span>
              );
            }
          })}
        </div>
      </div>
      <WorkTypeDialog
        currentUserURL={null}
        disabledWorkTypes={disabledWorkTypes}
        open={workTypeDialogOpen}
        suggestRecentlyUsedWorkTypes={false}
        workTypeArray={potentialWorkTypes}
        onCancel={setWorkTypeDialogOpenFalse}
        onOk={handleWorkTypeDialogOk}
      />
      <PriceGroupDialog
        noneLabel={formatMessage(messages.allVariants)}
        open={!!priceGroupDialogOpenForWorkType}
        priceGroupArray={potentialPriceGroupsForWorkType}
        onCancel={handlePriceGroupsForWorkTypeDialogCancel}
        onNone={handlePriceGroupsForWorkTypeDialogNone}
        onOk={handlePriceGroupsForWorkTypeDialogOk}
      />
    </>
  );
}
