import {UserUrl} from "@co-common-libs/resources";
import {dateToString, getDateStringSequence, getHoliday} from "@co-common-libs/utils";
import {getCustomerSettings, getUserUserProfileLookup} from "@co-frontend-libs/redux";
import {dateFromDateAndTime, getEmployeeHolidayCalendars} from "app-utils";
import ImmutableDate from "bloody-immutable-date";
import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import {USER_COLUMN_WIDTH} from "./constants";
import {calculateYPosition} from "./utils";

interface HolidayBlocksProps {
  calendarFromTimestamp: ImmutableDate;
  calendarToTimestamp: ImmutableDate;
  currentUserURL: string | null;
  getUserHalfHoliday?: ((date: string) => string | undefined) | undefined;
  getUserHoliday?: ((date: string) => string | undefined) | undefined;
  userUrl: UserUrl;
}

export const HolidayBlocks = React.memo(function HolidayBlocks(
  props: HolidayBlocksProps,
): JSX.Element {
  const {calendarFromTimestamp, calendarToTimestamp, getUserHalfHoliday, getUserHoliday, userUrl} =
    props;

  const customerSettings = useSelector(getCustomerSettings);
  const userUserProfileLookup = useSelector(getUserUserProfileLookup);
  const userProfile = userUserProfileLookup(userUrl);

  const holidayCalendars = useMemo(
    () => getEmployeeHolidayCalendars(customerSettings, userProfile),
    [customerSettings, userProfile],
  );

  const holidayBlocks: JSX.Element[] = [];

  const dates = getDateStringSequence(
    dateToString(calendarFromTimestamp),
    dateToString(calendarToTimestamp),
  );

  dates.forEach((date) => {
    const holiday = (getUserHoliday && getUserHoliday(date)) || getHoliday(holidayCalendars, date);
    const halfHoliday = getUserHalfHoliday && getUserHalfHoliday(date);
    if (!holiday && !halfHoliday) {
      return;
    }

    const fromTimestamp = dateFromDateAndTime(date, halfHoliday ? "12:00" : "00:00");
    const toTimestamp = dateFromDateAndTime(date, "24:00");

    const startY = calculateYPosition(calendarFromTimestamp, calendarToTimestamp, fromTimestamp);
    const endY = calculateYPosition(calendarFromTimestamp, calendarToTimestamp, toTimestamp);
    const containerStyle: React.CSSProperties = {
      backgroundColor: customerSettings.absenceCalendarBlockColor,
      cursor: "pointer",
      height: endY - startY,
      overflow: "hidden",
      padding: 2,
      position: "absolute",
      top: startY,
      width: USER_COLUMN_WIDTH,
    };
    const contentText: React.CSSProperties = {
      fontSize: "12px",
      fontWeight: 500,
      lineHeight: "14px",
      maxWidth: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      textTransform: "uppercase",
      whiteSpace: "nowrap",
    };

    holidayBlocks.push(
      <div key={date} className="gridBlock" style={containerStyle}>
        <div style={contentText}>{holiday ?? halfHoliday}</div>
      </div>,
    );
  });
  return <div>{holidayBlocks}</div>;
});
