import {Config} from "@co-common-libs/config";
import {
  DeliveryLocation,
  Location,
  LocationUrl,
  Machine,
  MachineUrl,
  MachineUse,
  PickupLocation,
  PriceGroup,
  PriceGroupUrl,
  Product,
  ProductUrl,
  ProductUseWithOrder,
  Project,
  ProjectUrl,
  ReportingSpecification,
  ReportingSpecificationUrl,
  SprayLocation,
  SprayLog,
  Task,
  TransportLog,
  WorkType,
  WorkTypeUrl,
  YieldDeliveryLocation,
  YieldLog,
  YieldPickupLocation,
  emptyTask,
  urlToId,
} from "@co-common-libs/resources";
import {
  getExpectedLogSpecification,
  getMachineString,
  getProjectString,
  translateLogLocations,
} from "@co-common-libs/resources-utils";
import {actions} from "@co-frontend-libs/redux";
import {instanceURL} from "frontend-global-config";
import {IntlShape} from "react-intl";
import {Dispatch} from "redux";
import type {Writable} from "ts-essentials";
import {v4 as uuid} from "uuid";
import {getLocationString} from "./locations";

export const copyTask = (
  task: Partial<Task>,
  copyFields: readonly string[],
  overrides: Partial<Task>,
  {
    locationLookup,
    machineLookup,
    priceGroupLookup,
    productLookup,
    projectLookup,
    reportingSpecificationLookup,
    workTypeLookup,
  }: {
    locationLookup: (url: LocationUrl) => Location | undefined;
    machineLookup: (url: MachineUrl) => Machine | undefined;
    priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
    productLookup: (url: ProductUrl) => Product | undefined;
    projectLookup: (url: ProjectUrl) => Project | undefined;
    reportingSpecificationLookup: (
      url: ReportingSpecificationUrl,
    ) => ReportingSpecification | undefined;
    workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
  },
  dispatch: Dispatch,
  intl: IntlShape,
  customerSettings: Config,
): Task => {
  const {projectLabelVariant} = customerSettings;
  const id = uuid();
  const url = instanceURL("task", id);
  const copy: Writable<Task> = {...emptyTask, id, url};
  const warnings: string[] = [];
  copyFields.forEach((fieldName) => {
    const value = task[fieldName as keyof Task];
    if (value !== undefined) {
      if (
        fieldName === "priceItemUses" ||
        fieldName === "priceitemuseSet" ||
        fieldName === "productuseSet"
      ) {
        // Not copied; should always be recomputed.
        // Middleware recomputes for new tasks in updatePriceItemUses().
      } else if (fieldName === "productUses") {
        const typedValue = value as Task["productUses"];
        const entries: [string, ProductUseWithOrder][] = [];
        Object.values(typedValue || {}).forEach((entry) => {
          const productURL = entry.product;
          const product = productLookup(productURL);
          if (product && !product.active) {
            warnings.push(
              intl.formatMessage(
                {
                  defaultMessage:
                    'Materiellet "{product}" er fjernet fra den nye opgave, da det ikke længere er aktivt.',
                },
                {product: product.name},
              ),
            );
          } else {
            entries.push([uuid(), {...entry, correctedCount: null, count: null}]);
          }
        });
        copy[fieldName] = Object.fromEntries(entries);
      } else if (fieldName === "machineuseSet") {
        const machineuseSet: MachineUse[] = [];
        (value as Task["machineuseSet"]).forEach((entry) => {
          const machineURL = entry.machine;
          const machine = machineLookup(machineURL);
          if (machine && !machine.smallMachine) {
            if (machine.active) {
              machineuseSet.push(entry);
            } else {
              warnings.push(
                intl.formatMessage(
                  {
                    defaultMessage:
                      'Maskinen "{machine}" er fjernet fra den nye opgave, da den ikke længere er aktiv.',
                  },
                  {machine: getMachineString(machine)},
                ),
              );
            }
          }
        });
        copy.machineuseSet = machineuseSet;
      } else if (fieldName === "relatedWorkplace") {
        const relatedWorkplaceUrl = value as Task["relatedWorkplace"];
        const relatedWorkplace = relatedWorkplaceUrl ? locationLookup(relatedWorkplaceUrl) : null;
        if (relatedWorkplace && !relatedWorkplace.active) {
          warnings.push(
            intl.formatMessage(
              {
                defaultMessage:
                  'Arbejdsstedet "{location}" er fjernet fra den nye opgave, da det ikke længere er aktivt.',
              },
              {location: getLocationString(relatedWorkplace)},
            ),
          );
        } else {
          copy.relatedWorkplace = relatedWorkplaceUrl;
        }
      } else if (fieldName === "relatedPickupLocation") {
        const relatedPickupLocationUrl = value as Task["relatedPickupLocation"];
        const relatedPickupLocation = relatedPickupLocationUrl
          ? locationLookup(relatedPickupLocationUrl)
          : null;
        if (relatedPickupLocation && !relatedPickupLocation.active) {
          warnings.push(
            intl.formatMessage(
              {
                defaultMessage:
                  'Afhentningsstedet "{location}" er fjernet fra den nye opgave, da det ikke længere er aktivt.',
              },
              {location: getLocationString(relatedPickupLocation)},
            ),
          );
        } else {
          copy.relatedPickupLocation = relatedPickupLocationUrl;
        }
      } else if (fieldName === "project") {
        const projectUrl = value as Task["project"];
        const project = projectUrl ? projectLookup(projectUrl) : null;
        if (project && (project.access === "barred" || project.access === "closed")) {
          warnings.push(
            projectLabelVariant === "PROJECT"
              ? intl.formatMessage(
                  {
                    defaultMessage:
                      'Projektet "{project}" er fjernet fra den nye opgave, da det ikke længere er aktivt.',
                  },
                  {project: getProjectString(project)},
                )
              : intl.formatMessage(
                  {
                    defaultMessage:
                      'Sagen "{project}" er fjernet fra den nye opgave, da den ikke længere er aktivt.',
                  },
                  {project: getProjectString(project)},
                ),
          );
        } else {
          copy.project = projectUrl;
        }
      } else {
        (copy as any)[fieldName] = value;
      }
    }
  });
  Object.keys(overrides).forEach((fieldName) => {
    (copy as any)[fieldName] = (overrides as any)[fieldName];
  });
  const reportingSpecUrl = copy.reportingSpecification;
  if (reportingSpecUrl) {
    const reportingSpecification = getExpectedLogSpecification(copy, {
      machineLookup,
      priceGroupLookup,
      reportingSpecificationLookup,
      workTypeLookup,
    });
    copy.reportingSpecification = reportingSpecification?.url || null;
    copy.reportingData = {};
    copy.reportingLog = {};

    if (reportingSpecification && reportingSpecification.url !== reportingSpecUrl) {
      const oldReportingSpecification = reportingSpecificationLookup(reportingSpecUrl);

      const newReportingLocations = translateLogLocations(
        locationLookup,
        copy.reportingLocations,
        reportingSpecification,
        oldReportingSpecification,
      );
      const oldLocationCount = Object.keys(copy.reportingLocations).length;
      const newLocationCount = Object.keys(newReportingLocations).length;
      if (oldLocationCount && !newLocationCount) {
        warnings.push(
          intl.formatMessage({
            defaultMessage:
              "Stederne på loggen kunne ikke kopieres med over da der er blevet ændret i loggens opsætning.",
          }),
        );
      }
      copy.reportingLocations = newReportingLocations;
    }
  }

  if (warnings.length) {
    dispatch(actions.showDialog(intl.formatMessage({defaultMessage: "Kopi"}), warnings.join("\n")));
  }

  return copy;
};

const copyTaskTransportLogParts = (
  originalTask: Task,
  taskCopy: Task,
  {
    deliveryLocationArray,
    pickupLocationArray,
    transportLogArray,
  }: {
    deliveryLocationArray?: readonly DeliveryLocation[] | undefined;
    pickupLocationArray?: readonly PickupLocation[] | undefined;
    transportLogArray: readonly TransportLog[];
  },
): (DeliveryLocation | PickupLocation | TransportLog)[] => {
  const result: (DeliveryLocation | PickupLocation | TransportLog)[] = [];
  const originalTaskURL = originalTask.url;
  const transportLog = transportLogArray.find((log) => log.task === originalTaskURL);
  if (transportLog) {
    const taskCopyURL = taskCopy.url;
    const taskCopyID = urlToId(taskCopyURL);
    const transportLogURL = transportLog.url;
    const newTransportLogURL = instanceURL("transportLog", taskCopyID);
    const newTransportLog: TransportLog = {
      ...transportLog,
      id: taskCopyID,
      task: taskCopyURL,
      url: newTransportLogURL,
    };
    result.push(newTransportLog);
    if (pickupLocationArray) {
      pickupLocationArray.forEach((pickupLocation) => {
        if (pickupLocation.transportlog !== transportLogURL) {
          return;
        }
        const id = uuid();
        const url = instanceURL("pickupLocation", id);
        const newPickupLocation: PickupLocation = {
          ...pickupLocation,
          id,
          task: taskCopyURL,
          transportlog: newTransportLogURL,
          url,
        };
        result.push(newPickupLocation);
      });
    }
    if (deliveryLocationArray) {
      deliveryLocationArray.forEach((deliveryLocation) => {
        if (deliveryLocation.transportlog !== transportLogURL) {
          return;
        }
        const id = uuid();
        const url = instanceURL("deliveryLocation", id);
        const newDeliveryLocation: DeliveryLocation = {
          ...deliveryLocation,
          id,
          task: taskCopyURL,
          transportlog: newTransportLogURL,
          url,
        };
        result.push(newDeliveryLocation);
      });
    }
  }
  return result;
};

const copyTaskYieldLogParts = (
  originalTask: Task,
  taskCopy: Task,
  {
    yieldDeliveryLocationArray,
    yieldLogArray,
    yieldPickupLocationArray,
  }: {
    yieldDeliveryLocationArray?: readonly YieldDeliveryLocation[] | undefined;
    yieldLogArray: readonly YieldLog[];
    yieldPickupLocationArray?: readonly YieldPickupLocation[] | undefined;
  },
): (YieldDeliveryLocation | YieldLog | YieldPickupLocation)[] => {
  const result: (YieldDeliveryLocation | YieldLog | YieldPickupLocation)[] = [];
  const originalTaskURL = originalTask.url;
  const yieldLog = yieldLogArray.find((log) => log.task === originalTaskURL);
  if (yieldLog) {
    const taskCopyURL = taskCopy.url;
    const taskCopyID = urlToId(taskCopyURL);
    const yieldLogURL = yieldLog.url;
    const newYieldLogURL = instanceURL("yieldLog", taskCopyID);
    const newYieldLog: YieldLog = {
      ...yieldLog,
      id: taskCopyID,
      task: taskCopyURL,
      url: newYieldLogURL,
    };
    result.push(newYieldLog);
    if (yieldPickupLocationArray) {
      yieldPickupLocationArray.forEach((pickupLocation) => {
        if (pickupLocation.yieldlog !== yieldLogURL) {
          return;
        }
        const id = uuid();
        const url = instanceURL("yieldPickupLocation", id);
        const newPickupLocation: YieldPickupLocation = {
          ...pickupLocation,
          id,
          url,
          yieldlog: newYieldLogURL,
        };
        result.push(newPickupLocation);
      });
    }
    if (yieldDeliveryLocationArray) {
      yieldDeliveryLocationArray.forEach((deliveryLocation) => {
        if (deliveryLocation.yieldlog !== yieldLogURL) {
          return;
        }
        const id = uuid();
        const url = instanceURL("yieldDeliveryLocation", id);
        const newDeliveryLocation: YieldDeliveryLocation = {
          ...deliveryLocation,
          id,
          url,
          yieldlog: newYieldLogURL,
        };
        result.push(newDeliveryLocation);
      });
    }
  }
  return result;
};

const copyTaskSprayLogParts = (
  originalTask: Task,
  taskCopy: Task,
  {
    sprayLocationArray,
    sprayLogArray,
  }: {
    sprayLocationArray?: readonly SprayLocation[] | undefined;
    sprayLogArray: readonly SprayLog[];
  },
): (SprayLocation | SprayLog)[] => {
  const result: (SprayLocation | SprayLog)[] = [];
  const originalTaskURL = originalTask.url;
  const sprayLog = sprayLogArray.find((log) => log.task === originalTaskURL);
  if (sprayLog) {
    const taskCopyURL = taskCopy.url;
    const taskCopyID = urlToId(taskCopyURL);
    const sprayLogURL = sprayLog.url;
    const newSprayLogURL = instanceURL("sprayLog", taskCopyID);
    const newSprayLog = {
      ...sprayLog,
      id: taskCopyID,
      task: taskCopyURL,
      url: newSprayLogURL,
    };
    result.push(newSprayLog);
    if (sprayLocationArray) {
      sprayLocationArray.forEach((sprayLocation) => {
        if (sprayLocation.spraylog !== sprayLogURL) {
          return;
        }
        const id = uuid();
        const url = instanceURL("sprayLocation", id);
        const newSprayLocation = {
          ...sprayLocation,
          id,
          spraylog: newSprayLogURL,
          task: taskCopyURL,
          url,
        };
        result.push(newSprayLocation);
      });
    }
  }
  return result;
};

const copyTaskLegacyLogParts = (
  originalTask: Task,
  taskCopy: Task,
  {
    deliveryLocationArray,
    pickupLocationArray,
    sprayLocationArray,
    sprayLogArray,
    transportLogArray,
    yieldDeliveryLocationArray,
    yieldLogArray,
    yieldPickupLocationArray,
  }: {
    deliveryLocationArray?: readonly DeliveryLocation[];
    pickupLocationArray?: readonly PickupLocation[];
    sprayLocationArray?: readonly SprayLocation[];
    sprayLogArray: readonly SprayLog[];
    transportLogArray: readonly TransportLog[];
    yieldDeliveryLocationArray?: readonly YieldDeliveryLocation[];
    yieldLogArray: readonly YieldLog[];
    yieldPickupLocationArray?: readonly YieldPickupLocation[];
  },
  enableTransportlog: boolean,
): (
  | DeliveryLocation
  | PickupLocation
  | SprayLocation
  | SprayLog
  | TransportLog
  | YieldDeliveryLocation
  | YieldLog
  | YieldPickupLocation
)[] => {
  const transportLogParts = enableTransportlog
    ? copyTaskTransportLogParts(originalTask, taskCopy, {
        deliveryLocationArray,
        pickupLocationArray,
        transportLogArray,
      })
    : [];
  const yieldLogParts = copyTaskYieldLogParts(originalTask, taskCopy, {
    yieldDeliveryLocationArray,
    yieldLogArray,
    yieldPickupLocationArray,
  });
  const sprayLogParts = copyTaskSprayLogParts(originalTask, taskCopy, {
    sprayLocationArray,
    sprayLogArray,
  });
  return [...transportLogParts, ...yieldLogParts, ...sprayLogParts];
};

export const copyTaskWithLogs = (
  task: Task,
  copyFields: readonly string[],
  overrides: Partial<Task>,
  {
    locationLookup,
    machineLookup,
    priceGroupLookup,
    productLookup,
    projectLookup,
    reportingSpecificationLookup,
    sprayLogArray,
    transportLogArray,
    workTypeLookup,
    yieldLogArray,
  }: {
    locationLookup: (url: LocationUrl) => Location | undefined;
    machineLookup: (url: MachineUrl) => Machine | undefined;
    priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
    productLookup: (url: ProductUrl) => Product | undefined;
    projectLookup: (url: ProjectUrl) => Project | undefined;
    reportingSpecificationLookup: (
      url: ReportingSpecificationUrl,
    ) => ReportingSpecification | undefined;
    sprayLogArray: readonly SprayLog[];
    transportLogArray: readonly TransportLog[];
    workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
    yieldLogArray: readonly YieldLog[];
  },
  dispatch: Dispatch,
  intl: IntlShape,
  customerSettings: Config,
): /* eslint-disable @typescript-eslint/naming-convention */
(
  | DeliveryLocation
  | PickupLocation
  | SprayLocation
  | SprayLog
  | Task
  | TransportLog
  | YieldDeliveryLocation
  | YieldLog
  | YieldPickupLocation
)[] & {0: Task} =>
  /* eslint-enable @typescript-eslint/naming-convention */
  {
    const {enableTransportLog} = customerSettings;
    const taskCopy = copyTask(
      task,
      copyFields,
      overrides,
      {
        locationLookup,
        machineLookup,
        priceGroupLookup,
        productLookup,
        projectLookup,
        reportingSpecificationLookup,
        workTypeLookup,
      },
      dispatch,
      intl,
      customerSettings,
    );
    const logParts = copyTaskLegacyLogParts(
      task,
      taskCopy,
      {
        sprayLogArray,
        transportLogArray,
        yieldLogArray,
      },
      enableTransportLog,
    );
    return [taskCopy, ...logParts];
  };

export const copyTaskWithLogsLocations = (
  task: Task,
  copyFields: readonly string[],
  overrides: Partial<Task>,
  {
    deliveryLocationArray,
    locationLookup,
    machineLookup,
    pickupLocationArray,
    priceGroupLookup,
    productLookup,
    projectLookup,
    reportingSpecificationLookup,
    sprayLocationArray,
    sprayLogArray,
    transportLogArray,
    workTypeLookup,
    yieldDeliveryLocationArray,
    yieldLogArray,
    yieldPickupLocationArray,
  }: {
    deliveryLocationArray: readonly DeliveryLocation[];
    locationLookup: (url: LocationUrl) => Location | undefined;
    machineLookup: (url: MachineUrl) => Machine | undefined;
    pickupLocationArray: readonly PickupLocation[];
    priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
    productLookup: (url: ProductUrl) => Product | undefined;
    projectLookup: (url: ProjectUrl) => Project | undefined;
    reportingSpecificationLookup: (
      url: ReportingSpecificationUrl,
    ) => ReportingSpecification | undefined;
    sprayLocationArray: readonly SprayLocation[];
    sprayLogArray: readonly SprayLog[];
    transportLogArray: readonly TransportLog[];
    workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
    yieldDeliveryLocationArray: readonly YieldDeliveryLocation[];
    yieldLogArray: readonly YieldLog[];
    yieldPickupLocationArray: readonly YieldPickupLocation[];
  },
  dispatch: Dispatch,
  intl: IntlShape,
  customerSettings: Config,
): /* eslint-disable @typescript-eslint/naming-convention */
(
  | DeliveryLocation
  | PickupLocation
  | SprayLocation
  | SprayLog
  | Task
  | TransportLog
  | YieldDeliveryLocation
  | YieldLog
  | YieldPickupLocation
)[] & {0: Task} =>
  /* eslint-enable @typescript-eslint/naming-convention */
  {
    const {enableTransportLog} = customerSettings;
    const taskCopy = copyTask(
      task,
      copyFields,
      overrides,
      {
        locationLookup,
        machineLookup,
        priceGroupLookup,
        productLookup,
        projectLookup,
        reportingSpecificationLookup,
        workTypeLookup,
      },
      dispatch,
      intl,
      customerSettings,
    );
    const logParts = copyTaskLegacyLogParts(
      task,
      taskCopy,
      {
        deliveryLocationArray,
        pickupLocationArray,
        sprayLocationArray,
        sprayLogArray,
        transportLogArray,
        yieldDeliveryLocationArray,
        yieldLogArray,
        yieldPickupLocationArray,
      },
      enableTransportLog,
    );
    return [taskCopy, ...logParts];
  };
