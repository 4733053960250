import {identifierComparator} from "@co-common-libs/utils";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {
  Checkbox,
  DialogContent,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import React, {useCallback, useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import {DialogFieldListItem} from "./dialog-field-list-item";
import {LocationPart, getKey} from "./utils";

interface ZipFieldsDialogProps {
  fullscreen?: boolean;
  locations: LocationPart[];
  onCancel: () => void;
  onOk: (locations: LocationPart[]) => void;
  open: boolean;
}

export function ZipFieldsDialog(props: ZipFieldsDialogProps): JSX.Element {
  const {fullscreen, locations, onCancel, onOk, open} = props;
  const [selected, setSelected] = useState(new Set<string>());

  useEffect(() => {
    if (!open) {
      setSelected(new Set<string>());
    }
  }, [open]);

  const handleOk = useCallback(() => {
    onOk(locations.filter((location) => selected.has(getKey(location))));
  }, [locations, onOk, selected]);

  const handleToggle = useCallback(
    (location: LocationPart) => {
      const key = getKey(location);
      const newSelected = new Set(selected);
      if (selected.has(key)) {
        newSelected.delete(key);
      } else {
        newSelected.add(key);
      }
      setSelected(newSelected);
    },
    [selected],
  );

  const listItems = locations
    .slice()
    .sort((a, b) => identifierComparator(a.fieldNumber, b.fieldNumber))
    .map((location) => {
      const key = getKey(location);
      return (
        <DialogFieldListItem
          key={key}
          checked={selected.has(key)}
          location={location}
          onToggle={handleToggle}
        />
      );
    });

  const handleToggleAll = useCallback(() => {
    if (selected.size === locations.length) {
      setSelected(new Set<string>());
    } else {
      setSelected(new Set(locations.map((field) => getKey(field))));
    }
  }, [locations, selected.size]);

  return (
    <ResponsiveDialog
      fullscreen={fullscreen}
      okDisabled={!selected.size}
      open={open}
      title={
        <FormattedMessage
          defaultMessage="Importer marker fra ZIP-fil"
          id="field-list.dialog-title.import-fields-from-zip"
        />
      }
      onCancel={onCancel}
      onOk={handleOk}
    >
      <DialogContent dividers style={{padding: 0}}>
        <List>
          <ListItem button onClick={handleToggleAll}>
            <ListItemIcon>
              <Checkbox
                disableRipple
                checked={selected.size === locations.length}
                edge="start"
                indeterminate={selected.size > 0 && selected.size < locations.length}
                tabIndex={-1}
                onChange={handleToggleAll}
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <FormattedMessage defaultMessage="Vælg alle" id="field-list.label.select-all" />
              }
            />
          </ListItem>
          <Divider />
          {listItems}
        </List>
      </DialogContent>
    </ResponsiveDialog>
  );
}
