import React, {useCallback} from "react";
import {defineMessages, useIntl} from "react-intl";
import {CustomTextField, TextFieldPropsWithoutOnChange} from "./custom-text-field";

// import bowser from "bowser";

const messages = defineMessages({
  provideDecimal: {
    defaultMessage: "Indtast et decimaltal",
    id: "decimal-field.error.provide-decimal",
  },
  provideDecimalDigits: {
    defaultMessage:
      "Indtast et decimaltal med max {normalDigits, number} cifre før komma og {decimalDigits, number} efter",
    id: "decimal-field.error.provide-decimal-digits",
  },
});

interface DecimalFieldProps extends TextFieldPropsWithoutOnChange {
  decimalPlaces?: number | undefined;
  errorText?: string;
  hideError?: boolean;
  maxDigits?: number | undefined;
  onChange: (value: number | null) => void;
  readonly?: boolean | undefined;
  step?: string;
  value: number | null;
}

const DEFAULT_MAX_DIGITS = 5;
const DEFAULT_DECIMAL_PLACES = 2;
const DECIMAL_MARKER = ",";

export function DecimalField(props: DecimalFieldProps): JSX.Element {
  // const mobile = !!bowser.mobile || !!bowser.tablet;
  const mobile = false;
  const {
    decimalPlaces = DEFAULT_DECIMAL_PLACES,
    maxDigits = DEFAULT_MAX_DIGITS,
    step = mobile ? "any" : undefined,
    type = mobile ? "number" : "text",
    ...other
  } = props;

  const {formatMessage} = useIntl();

  const validationError = useCallback(
    (text: string, browserValid?: boolean): string | undefined => {
      if (mobile && browserValid === false) {
        return formatMessage(messages.provideDecimal);
      }
      if (text) {
        const noWhitespaceText = text.replace(/\s/g, "");
        const decimalDigits = decimalPlaces;
        const normalDigits = maxDigits - decimalDigits;
        const checkDigits = new RegExp(
          `^[+-]?\\d{1,${normalDigits}}(?:[.,]\\d{0,${decimalDigits}})?$`,
        );
        if (!checkDigits.test(noWhitespaceText)) {
          const checkDecimal = new RegExp("^[+-]?\\d+(?:[.,]\\d*)?$");
          if (!checkDecimal.test(noWhitespaceText)) {
            // simplified message if it's not even a decimal number...
            return formatMessage(messages.provideDecimal);
          } else {
            return formatMessage(messages.provideDecimalDigits, {
              decimalDigits,
              normalDigits,
            });
          }
        }
      }
      return undefined;
    },
    [decimalPlaces, formatMessage, maxDigits, mobile],
  );

  const valueFrom = useCallback(
    (text: string): number | null => {
      if (text && !validationError(text)) {
        const noWhitespaceText = text.replace(/\s/g, "");
        const base = noWhitespaceText.replace(DECIMAL_MARKER, ".");
        return parseFloat(base);
      } else {
        return null;
      }
    },
    [validationError],
  );

  const textFrom = useCallback(
    (value: number | null): string => {
      if (!value && value !== 0) {
        return "";
      }
      if (mobile) {
        return `${value}`;
      } else {
        return `${value}`.replace(".", DECIMAL_MARKER);
      }
    },
    [mobile],
  );

  const extra = {step, type} as any;

  return (
    <CustomTextField<number>
      {...other}
      inputProps={{inputMode: "decimal"}}
      textFrom={textFrom}
      validationError={validationError}
      valueFrom={valueFrom}
      {...extra}
    />
  );
}
