import {
  ReportingLocations,
  ReportingLog,
  ReportingSpecification,
  Task,
} from "@co-common-libs/resources";
import {getInputSpecificationsMap} from "@co-common-libs/resources-utils";
import {getCustomerSettings, getLocationLookup} from "@co-frontend-libs/redux";
import {Card, CardContent, CardHeader, Fab} from "@material-ui/core";
import bowser from "bowser";
import _ from "lodash";
import PlusIcon from "mdi-react/PlusIcon";
import SwapVerticalIcon from "mdi-react/SwapVerticalIcon";
import React, {useMemo} from "react";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {AddReportingLocationsFab} from "../../reporting-locations/add-reporting-locations-fab";
import {LocationButton} from "./location-button";

interface WorkplaceLocationsBlockProps {
  completedWorkplaceLocations: ReadonlySet<string>;
  fieldNotesPerLocation?: ReadonlyMap<string, [string | undefined, string | undefined]> | undefined;
  locationCounts: ReadonlyMap<string, number>;
  logSpecification: ReportingSpecification;
  onEditLocationButtonClick: (
    locationEntryType: "delivery" | "pickup" | "workplace",
    locationEntryIdentifier: string,
  ) => void;
  onLocationButtonClick?: (type: "delivery" | "pickup" | "workplace", identifier: string) => void;
  onReorderWorkplaceLocations: (fromIdentifier: string, toIdentifier: string) => void;
  onRequestAddWorkplaceLocation: () => void;
  onSwapButtonClick: () => void;
  readonly: boolean;
  reportingLocations: ReportingLocations;
  reportingLog: ReportingLog;
  swappingLocations: boolean;
  task: Task;
}

export const WorkplaceLocationsBlock = React.memo(function WorkplaceLocationsBlock(
  props: WorkplaceLocationsBlockProps,
): JSX.Element {
  const {
    completedWorkplaceLocations,
    fieldNotesPerLocation,
    locationCounts,
    logSpecification,
    onEditLocationButtonClick,
    onLocationButtonClick,
    onReorderWorkplaceLocations,
    onRequestAddWorkplaceLocation,
    onSwapButtonClick,
    readonly,
    reportingLocations,
    reportingLog,
    swappingLocations,
    task,
  } = props;

  const locationLookup = useSelector(getLocationLookup);

  const customerSettings = useSelector(getCustomerSettings);

  const inputSpecificationsMap = useMemo(
    () => getInputSpecificationsMap(logSpecification),
    [logSpecification],
  );

  const sortedWorkplaceEntries = useMemo(
    () =>
      _.sortBy(
        Object.entries(reportingLocations).filter(
          ([_identifier, reportingLocation]) => reportingLocation.type === "workplace",
        ),
        ([_identifier, reportingLocation]) => reportingLocation.order,
      ),
    [reportingLocations],
  );

  return (
    <div style={{padding: "1em"}}>
      <Card style={{overflow: "visible"}}>
        {customerSettings.enableTaskLogLocationChange ? (
          <div style={{position: "relative"}}>
            {!bowser.mobile && !bowser.tablet ? (
              <Fab
                disabled={readonly}
                size="small"
                style={{
                  position: "absolute",
                  right: 64,
                  top: 16,
                }}
                onClick={onSwapButtonClick}
              >
                <SwapVerticalIcon />
              </Fab>
            ) : null}
            {customerSettings.addEditLogLocationSkipsCustomerSelection ? (
              <AddReportingLocationsFab
                disabled={readonly}
                logSpecification={logSpecification}
                task={task}
                type="workplace"
              />
            ) : (
              <Fab
                disabled={readonly}
                size="small"
                style={{
                  position: "absolute",
                  right: 16,
                  top: 16,
                }}
                onClick={onRequestAddWorkplaceLocation}
              >
                <PlusIcon />
              </Fab>
            )}
          </div>
        ) : null}
        <CardHeader
          title={
            <FormattedMessage
              defaultMessage="Arbejdssted"
              id="workplace-location-block.header.work-place"
            />
          }
        />
        <CardContent style={{paddingBottom: 0}}>
          {sortedWorkplaceEntries.map(([identifier, entry], index) => (
            <LocationButton
              key={identifier}
              count={locationCounts.get(identifier) || 0}
              customerSettings={customerSettings}
              disabled={completedWorkplaceLocations.has(identifier) || readonly}
              editable={!readonly}
              fieldNotesPerLocation={fieldNotesPerLocation}
              index={index}
              inputSpecificationsMap={inputSpecificationsMap}
              location={entry.location && locationLookup(entry.location)}
              locationData={
                logSpecification.showLocationMaterialSums
                  ? Object.values(reportingLog).filter((data) => data.location === identifier)
                  : []
              }
              logSpecification={logSpecification}
              reportingLocation={entry}
              reportingLocationIdentifier={identifier}
              swappingLocations={swappingLocations}
              task={task}
              type="workplace"
              onClick={onLocationButtonClick}
              onDragDrop={onReorderWorkplaceLocations}
              onEditLocationButtonClick={onEditLocationButtonClick}
            />
          ))}
        </CardContent>
      </Card>
    </div>
  );
});
