import {LocationTypeIcon, locationTypeIcons} from "@co-common-libs/resources";
import {getFrontendSentry} from "@co-frontend-libs/utils";
import {useTheme} from "@material-ui/core";
import {MdiReactIconProps} from "mdi-react";
import AccountHardHatIcon from "mdi-react/AccountHardHatIcon";
import BarleyIcon from "mdi-react/BarleyIcon";
import BarnIcon from "mdi-react/BarnIcon";
import BulldozerIcon from "mdi-react/BulldozerIcon";
import CarEstateIcon from "mdi-react/CarEstateIcon";
import CarHatchbackIcon from "mdi-react/CarHatchbackIcon";
import CheckIcon from "mdi-react/CheckIcon";
import ContentCopyIcon from "mdi-react/ContentCopyIcon";
import CurrencyUsdIcon from "mdi-react/CurrencyUsdIcon";
import DatabaseIcon from "mdi-react/DatabaseIcon";
import EmoticonPoopIcon from "mdi-react/EmoticonPoopIcon";
import ExcavatorIcon from "mdi-react/ExcavatorIcon";
import FactoryIcon from "mdi-react/FactoryIcon";
import FlagVariantIcon from "mdi-react/FlagVariantIcon";
import GasStationIcon from "mdi-react/GasStationIcon";
import HammerWrenchIcon from "mdi-react/HammerWrenchIcon";
import HockeyPuckIcon from "mdi-react/HockeyPuckIcon";
import MapMarkerCircleIcon from "mdi-react/MapMarkerCircleIcon";
import PigVariantIcon from "mdi-react/PigVariantIcon";
import PineTreeIcon from "mdi-react/PineTreeIcon";
import SemanticWebIcon from "mdi-react/SemanticWebIcon";
import SiloIcon from "mdi-react/SiloIcon";
import SnowflakeIcon from "mdi-react/SnowflakeIcon";
import SprinklerVariantIcon from "mdi-react/SprinklerVariantIcon";
import SproutIcon from "mdi-react/SproutIcon";
import SummitIcon from "mdi-react/SummitIcon";
import TerrainIcon from "mdi-react/TerrainIcon";
import TextureBoxIcon from "mdi-react/TextureBoxIcon";
import TractorIcon from "mdi-react/TractorIcon";
import TruckIcon from "mdi-react/TruckIcon";
import VanUtilityIcon from "mdi-react/VanUtilityIcon";
import ViewAgendaIcon from "mdi-react/ViewAgendaIcon";
import WarehouseIcon from "mdi-react/WarehouseIcon";
import WaterPumpIcon from "mdi-react/WaterPumpIcon";
import WheelBarrowIcon from "mdi-react/WheelBarrowIcon";
import React, {useCallback} from "react";

export interface LocationIconProps extends MdiReactIconProps {
  icon: LocationTypeIcon;
}

export const LocationIcon = React.memo(function LocationIcon({
  icon,
  ...rest
}: LocationIconProps): JSX.Element {
  switch (icon) {
    case "account-hard-hat":
      return <AccountHardHatIcon {...rest} />;
    case "agenda":
      return <ViewAgendaIcon {...rest} />;
    case "barley":
      return <BarleyIcon {...rest} />;
    case "content-copy":
      return <ContentCopyIcon {...rest} />;
    case "database":
      return <DatabaseIcon {...rest} />;
    case "emoticon-poop":
      return <EmoticonPoopIcon {...rest} />;
    case "excavator":
      return <ExcavatorIcon {...rest} />;
    case "flag-variant":
      return <FlagVariantIcon {...rest} />;
    case "hockey-puck":
      return <HockeyPuckIcon {...rest} />;
    case "map-marker-circle":
      return <MapMarkerCircleIcon {...rest} />;
    case "pine-tree":
      return <PineTreeIcon {...rest} />;
    case "semantic":
      return <SemanticWebIcon {...rest} />;
    case "silo":
      return <SiloIcon {...rest} />;
    case "snowflake":
      return <SnowflakeIcon {...rest} />;
    case "terrain":
      return <TerrainIcon {...rest} />;
    case "texture-box":
      return <TextureBoxIcon {...rest} />;
    case "warehouse":
      return <WarehouseIcon {...rest} />;
    case "gas-station":
      return <GasStationIcon {...rest} />;
    case "sprout":
      return <SproutIcon {...rest} />;
    case "tractor":
      return <TractorIcon {...rest} />;
    case "truck":
      return <TruckIcon {...rest} />;
    case "pig":
      return <PigVariantIcon {...rest} />;
    case "barn":
      return <BarnIcon {...rest} />;
    case "hammer-wrench":
      return <HammerWrenchIcon {...rest} />;
    case "factory":
      return <FactoryIcon {...rest} />;
    case "currency-usd":
      return <CurrencyUsdIcon {...rest} />;
    case "car-hatchback":
      return <CarHatchbackIcon {...rest} />;
    case "car-estate":
      return <CarEstateIcon {...rest} />;
    case "van-utility":
      return <VanUtilityIcon {...rest} />;
    case "water-pump":
      return <WaterPumpIcon {...rest} />;
    case "wheel-barrow":
      return <WheelBarrowIcon {...rest} />;
    case "sprinkler-variant":
      return <SprinklerVariantIcon {...rest} />;
    case "bulldozer":
      return <BulldozerIcon {...rest} />;
    case "summit":
      return <SummitIcon {...rest} />;
    default:
      if (process.env.NODE_ENV !== "production") {
        throw new Error(`Icon "${icon}" not allowed on LocationType`);
      }
      getFrontendSentry().captureMessage(`Icon "${icon}" not allowed on LocationType`);
      return <MapMarkerCircleIcon {...rest} />;
  }
});
const IconTile = React.memo(function IconTile({
  icon,
  onIconClick,
  selected,
}: {
  icon: LocationTypeIcon;
  onIconClick: (icon: LocationTypeIcon) => void;
  selected?: boolean;
}): JSX.Element {
  const handleClick = useCallback(() => {
    onIconClick(icon);
  }, [onIconClick, icon]);
  const theme = useTheme();

  return (
    <div
      style={{
        cursor: "pointer",
        height: 50,
        position: "relative",
        width: 50,
      }}
      onClick={handleClick}
    >
      <div style={{position: "absolute"}}>
        <LocationIcon icon={icon} />
      </div>
      {selected ? (
        <div style={{position: "absolute", right: 10}}>
          <CheckIcon color={theme.palette.success.dark} size={20} />
        </div>
      ) : null}
    </div>
  );
});

export const IconPicker = React.memo(function IconPicker({
  onIconClick,
  selectedIcon,
}: {
  onIconClick: (icon: LocationTypeIcon) => void;
  selectedIcon?: LocationTypeIcon;
}): JSX.Element {
  return (
    <div style={{display: "flex", flexWrap: "wrap"}}>
      {locationTypeIcons.map((icon) => (
        <IconTile
          key={icon}
          icon={icon}
          selected={icon === selectedIcon}
          onIconClick={onIconClick}
        />
      ))}
    </div>
  );
});
