import {
  DAY_HOURS,
  DAY_MINUTES,
  DayTypeHoliday,
  HOUR_MINUTES,
  WEEKDAY_SUNDAY,
  WeekdayNumberType,
  dateToString,
  objectMerge,
  objectSet,
} from "@co-common-libs/utils";
import {
  DayHoursRatesSpecification,
  HolidayCheckFunction,
  HoursRatesFunction,
  MAX_OVERTIME_RATE,
  Rate,
  SimpleInterval,
  SimpleIntervalWithRate,
  TaskIntervalWithRate,
  WeekDayHoursRatesSpecification,
  WorkDay,
} from "./types";
import {mapWorkDaysWorkIntervals} from "./work-days";

function timeOnDate(date: Date, time: string): Date {
  const result = new Date(date);
  const [hoursString, minutesString] = time.split(":");
  const hours = parseInt(hoursString);
  console.assert(hours >= 0 && hours <= DAY_HOURS);
  const minutes = parseInt(minutesString);
  console.assert(minutes >= 0 && minutes < HOUR_MINUTES);
  result.setHours(hours, minutes, 0, 0);
  return result;
}

function isValidDayHoursRatesSpecification(x: DayHoursRatesSpecification): boolean {
  if (x.length < 1) {
    return true;
  }
  if (x[0][0] !== "00:00") {
    return false;
  }
  if (x[x.length - 1][1] !== "24:00") {
    return false;
  }
  for (let i = 0; i < x.length; i += 1) {
    const entry = x[i];
    const fromTime = entry[0];
    const toTime = entry[1];
    const rate = entry[2];
    if (rate < Rate.UNPAID || rate > MAX_OVERTIME_RATE || !Number.isInteger(rate)) {
      return false;
    }
    if (fromTime >= toTime) {
      return false;
    }
  }
  for (let i = 0; i < x.length - 1; i += 1) {
    const entry = x[i];
    const toTime = entry[1];
    const nextEntry = x[i + 1];
    const nextFromTime = nextEntry[0];
    if (toTime !== nextFromTime) {
      return false;
    }
  }
  return true;
}

function splitIntersectingTime<T extends SimpleInterval>(
  interval: T,
  date: Date,
  fromTime: string,
  toTime: string,
): {after?: T; before?: T; intersecting?: T} {
  const start = timeOnDate(date, fromTime).toISOString();
  const end = timeOnDate(date, toTime).toISOString();

  const result: {after?: T; before?: T; intersecting?: T} = {};

  if (interval.toTimestamp <= start) {
    result.before = interval;
  } else if (interval.fromTimestamp >= end) {
    result.after = interval;
  } else if (interval.fromTimestamp >= start && interval.toTimestamp <= end) {
    result.intersecting = interval;
  } else {
    let intersectingFromtimestamp = interval.fromTimestamp;
    let intersectingToTimestamp = interval.toTimestamp;
    if (interval.fromTimestamp < start) {
      result.before = objectSet(interval, "toTimestamp", start);
      intersectingFromtimestamp = start;
    }
    if (interval.toTimestamp > end) {
      result.after = objectSet(interval, "fromTimestamp", end);
      intersectingToTimestamp = end;
    }
    const intersectingInterval: SimpleInterval = {
      fromTimestamp: intersectingFromtimestamp,
      toTimestamp: intersectingToTimestamp,
    };
    result.intersecting = objectMerge(interval, intersectingInterval as Partial<T>);
  }
  return result;
}

export function setWorkIntervalsHoursRates<T extends SimpleIntervalWithRate>(
  workIntervals: readonly T[],
  getDateHoursRates: (date: Date) => DayHoursRatesSpecification,
): T[] {
  const work: T[] = [];
  const inputIntervals = workIntervals;
  for (let i = 0; i < inputIntervals.length; i += 1) {
    const interval = inputIntervals[i];
    console.assert(interval.rate <= Rate.NORMAL);
    if (interval.rate !== Rate.NORMAL) {
      console.assert(interval.rate === Rate.UNPAID || interval.rate === Rate.SPECIAL_START_RATE);
      work.push(interval);
    } else {
      const fromDate = new Date(interval.fromTimestamp);
      const dayHoursRates = getDateHoursRates(fromDate);
      console.assert(
        isValidDayHoursRatesSpecification(dayHoursRates),
        `Not a valid DayHoursRatesSpecification: ${JSON.stringify(dayHoursRates)}`,
      );
      let remaining: T | undefined = interval;
      for (let j = 0; remaining && j < dayHoursRates.length; j += 1) {
        const entry = dayHoursRates[j];
        const fromTime = entry[0];
        const toTime = entry[1];
        const {after, before, intersecting}: {after?: T; before?: T; intersecting?: T} =
          splitIntersectingTime(remaining, fromDate, fromTime, toTime);
        if (before) {
          work.push(before);
        }
        if (intersecting) {
          const rate = entry[2];
          work.push(objectSet(intersecting, "rate", rate));
        }
        remaining = after;
      }
      if (remaining) {
        work.push(remaining);
      }
    }
  }
  return work;
}

export function setHoursRates(
  workDays: readonly WorkDay[],
  getDateHoursRates: (date: Date) => DayHoursRatesSpecification,
): WorkDay[] {
  function fn(work: readonly TaskIntervalWithRate[]): TaskIntervalWithRate[] {
    return setWorkIntervalsHoursRates(work, getDateHoursRates);
  }
  return mapWorkDaysWorkIntervals(workDays, fn);
}

export function combinedHoursRates(
  initialRates: DayHoursRatesSpecification,
  split: string,
  finalRates: DayHoursRatesSpecification,
): DayHoursRatesSpecification {
  const result: Readonly<[string, string, number]>[] = [];
  const FROM_INDEX = 0;
  const TO_INDEX = 1;
  const RATE_INDEX = 2;
  initialRates.forEach((entry) => {
    if (entry[TO_INDEX] <= split) {
      result.push(entry);
    } else if (entry[FROM_INDEX] < split) {
      result.push([entry[FROM_INDEX], split, entry[RATE_INDEX]]);
    }
  });
  finalRates.forEach((entry) => {
    if (entry[FROM_INDEX] >= split) {
      result.push(entry);
    } else if (entry[TO_INDEX] > split) {
      result.push([split, entry[TO_INDEX], entry[RATE_INDEX]]);
    }
  });
  return result;
}

export function getDateHoursRatesFunction(
  hoursRates: WeekDayHoursRatesSpecification,
  checkHoliday: HolidayCheckFunction,
  halfHolidaySundayAfterNoon: boolean,
  halfHolidayHoursRates?: DayHoursRatesSpecification,
): HoursRatesFunction {
  return (date: Date): DayHoursRatesSpecification => {
    const weekDay = date.getDay() as WeekdayNumberType;
    const dateString = dateToString(date);
    const dayType = checkHoliday(dateString);
    if (dayType === DayTypeHoliday.HOLIDAY || dayType === DayTypeHoliday.VALID_ABSENCE) {
      const dayNormalHours = hoursRates[WEEKDAY_SUNDAY];
      return dayNormalHours;
    } else if (dayType === DayTypeHoliday.HALF_HOLIDAY && halfHolidayHoursRates != null) {
      return halfHolidayHoursRates;
    } else if (dayType === DayTypeHoliday.HALF_HOLIDAY && halfHolidaySundayAfterNoon) {
      const dayHoursRates = hoursRates[weekDay];
      const sundayHoursRates = hoursRates[WEEKDAY_SUNDAY];
      const NOON = "12:00";
      return combinedHoursRates(dayHoursRates, NOON, sundayHoursRates);
    } else {
      const dayNormalHours = hoursRates[weekDay];
      return dayNormalHours;
    }
  };
}

function timeStringtoDayMinutes(time: string): number {
  const [hoursString, minutesString] = time.split(":");
  const hours = parseInt(hoursString);
  console.assert(hours >= 0 && hours <= DAY_HOURS);
  const minutes = parseInt(minutesString);
  console.assert(minutes >= 0 && minutes < HOUR_MINUTES);
  return hours * HOUR_MINUTES + minutes;
}

function timeIntervalMinutes(fromTime: string, toTime: string): number {
  console.assert(toTime > fromTime);
  return timeStringtoDayMinutes(toTime) - timeStringtoDayMinutes(fromTime);
}

export function normalMinutesFromHoursRates(hoursRates: DayHoursRatesSpecification): number {
  let remaining = DAY_MINUTES;
  for (let i = 0; i < hoursRates.length; i += 1) {
    const entry = hoursRates[i];
    const rate = entry[2];
    if (rate !== Rate.NORMAL) {
      const fromTime = entry[0];
      const toTime = entry[1];
      const minutes = timeIntervalMinutes(fromTime, toTime);
      remaining -= minutes;
    }
  }
  return remaining;
}
