import {alpha, useTheme} from "@material-ui/core";
import React, {CSSProperties} from "react";
import {LocationIcon, LocationIconProps} from "./icon-picker";

const activeIconBackgroundOpacity = 0.75;

interface LocationIconButtonProps extends LocationIconProps {
  active: boolean;
  href?: string;
  onClick?: () => void;
  size?: number;
  target?: string;
}

const PADDING = 6;
const BORDER_WIDTH = 2;
const ACTIVE_PADDING = PADDING - BORDER_WIDTH;
const DEFAULT_ICON_SIZE = 36;

export const LocationIconButton = React.memo(function LocationIconButton({
  active,
  href,
  onClick,
  target,
  ...iconProps
}: LocationIconButtonProps): JSX.Element {
  const size = (iconProps.size || DEFAULT_ICON_SIZE) + 2 * PADDING;
  let style: CSSProperties = {
    borderRadius: "50%",
    cursor: "pointer",
    display: "block",
    height: size,
    padding: PADDING,
    width: size,
  };
  const theme = useTheme();
  if (active) {
    style = {
      ...style,
      backgroundColor: alpha(theme.palette.primary.main, activeIconBackgroundOpacity),
      borderColor: theme.palette.common.black,
      borderStyle: "solid",
      borderWidth: BORDER_WIDTH,
      padding: ACTIVE_PADDING,
    };
  }

  if (href) {
    const optionalProps: React.AnchorHTMLAttributes<HTMLAnchorElement> = {};
    if (target) {
      optionalProps.target = target;
    }
    return (
      <a href={href} style={style} {...optionalProps}>
        <LocationIcon {...iconProps} />
      </a>
    );
  } else {
    const optionalProps: React.HTMLAttributes<HTMLDivElement> = {};
    if (onClick) {
      optionalProps.onClick = onClick;
    }
    return (
      <div style={style} {...optionalProps}>
        <LocationIcon {...iconProps} />
      </div>
    );
  }
});
