import {ResourceInstance, ResourceName} from "@co-common-libs/resources";
import {Draft, current} from "@reduxjs/toolkit";
import {GenericInstanceRecord, ResourcesState} from "../types";
import {
  buildCheckFunction,
  buildResourceCheckMapping,
  entriesForEach,
  partialEntriesForEach,
  partialValuesMap,
} from "../utils";
import {getAllResourcesData} from "./selectors";

export function purgeOldPersistedQueries(state: Draft<ResourcesState>): void {
  const {persistedQueries} = state;
  const potentiallyChangedResourceNames = new Set<ResourceName>();
  partialEntriesForEach(persistedQueries, (key, entry) => {
    if (entry.queryState.pendingRemoval) {
      delete persistedQueries[key];
      const {resourceName} = entry.query;
      potentiallyChangedResourceNames.add(resourceName);
    }
  });
  if (!potentiallyChangedResourceNames.size) {
    return;
  }
  const updatedState = current(state) as ResourcesState;
  const persistedResourceCheckMapping = buildResourceCheckMapping(
    partialValuesMap(updatedState.persistedQueries, ({query: q}) => q),
  );
  const temporaryResourceCheckMapping = buildResourceCheckMapping(
    partialValuesMap(updatedState.temporaryQueries, ({query}) => query),
  );
  const allData = getAllResourcesData(updatedState);

  // potential demote/remove
  entriesForEach(
    updatedState.persistedData,
    (resourceName, resourceInstanceRecord: GenericInstanceRecord) => {
      if (!potentiallyChangedResourceNames.has(resourceName)) {
        return;
      }
      if (!Object.keys(resourceInstanceRecord).length) {
        return;
      }
      const resourcePersistedCheck = persistedResourceCheckMapping[resourceName];
      if (resourcePersistedCheck.type === "alwaysOk") {
        return;
      }
      const resourceTemporaryCheck = temporaryResourceCheckMapping[resourceName];
      const temporaryEverything = resourceTemporaryCheck.type === "alwaysOk";
      const persistedCheckFunction = buildCheckFunction(resourcePersistedCheck, allData);
      const temporaryCheckFunction = buildCheckFunction(resourceTemporaryCheck, allData);
      partialEntriesForEach(resourceInstanceRecord, (url, instance) => {
        if (!persistedCheckFunction(instance)) {
          delete state.persistedData[resourceName][url];
          if (temporaryEverything || temporaryCheckFunction(instance)) {
            state.temporaryData[resourceName][url] = instance as ResourceInstance as Draft<any>;
          }
        }
      });
    },
  );
}
