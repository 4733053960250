import {simpleComparator} from "@co-common-libs/utils";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import memoize from "memoize-one";
import React from "react";
import {FormattedMessage} from "react-intl";
import {filterRows} from "./generic-table-filter-rows";
import {GenericTableHeaderCell} from "./generic-table-header-cell";
import {GenericTableRow} from "./generic-table-row";
import {sortRows} from "./generic-table-sort-rows";
import {ColumnSpecifications, RowData, SortDirection} from "./types";

function makeRows<
  FieldID extends string,
  ColumnID extends string,
  KeyType extends string = string,
  DataType extends RowData<FieldID, KeyType> = RowData<FieldID, KeyType>,
>(
  columns: ColumnSpecifications<FieldID, ColumnID, KeyType, DataType>,
  visibleColumns: readonly ColumnID[],
  rowStyle: React.CSSProperties | ((data: DataType) => React.CSSProperties) | undefined,
  entries: readonly (DataType & {children?: readonly DataType[] | undefined})[],
): JSX.Element[] {
  const result: JSX.Element[] = [];
  for (let i = 0; i < entries.length; i += 1) {
    const entry = entries[i];
    result.push(
      <GenericTableRow
        key={entry.key}
        columns={columns}
        data={entry}
        rowStyle={rowStyle}
        visibleColumns={visibleColumns}
      />,
    );
    if (entry.children && entry.children.length) {
      const childEntries = entry.children;
      for (let j = 0; j < childEntries.length; j += 1) {
        const childEntry = childEntries[j];
        result.push(
          <GenericTableRow
            key={childEntry.key}
            columns={columns}
            data={childEntry}
            rowStyle={rowStyle}
            visibleColumns={visibleColumns}
          />,
        );
      }
    }
  }
  return result;
}

function groupEntries<
  FieldID extends string,
  ColumnID extends string,
  KeyType extends string = string,
  DataType extends RowData<FieldID, KeyType> = RowData<FieldID, KeyType>,
>(
  groupByColumn: ColumnSpecifications<FieldID, ColumnID, KeyType, DataType>[ColumnID],
  entries: readonly (DataType & {children?: readonly DataType[] | undefined})[],
): readonly (readonly (DataType & {
  children?: readonly DataType[] | undefined;
})[])[] {
  const sortField = groupByColumn.sortField || groupByColumn.field;
  const grouped = new Map<boolean | number | string | null, DataType[]>();
  entries.forEach((entry) => {
    const key = entry[sortField];
    const existing = grouped.get(key);
    if (existing) {
      existing.push(entry);
    } else {
      grouped.set(key, [entry]);
    }
  });
  return Array.from(grouped.entries())
    .sort(([aKey, _aEntries], [bKey, _bEntries]) => simpleComparator(aKey, bKey))
    .map(([_key, groupedEntries]) => groupedEntries);
}

function makeGroupedRows<
  FieldID extends string,
  ColumnID extends string,
  KeyType extends string = string,
  DataType extends RowData<FieldID, KeyType> = RowData<FieldID, KeyType>,
>(
  groupByColumn: ColumnSpecifications<FieldID, ColumnID, KeyType, DataType>[ColumnID],
  columns: ColumnSpecifications<FieldID, ColumnID, KeyType, DataType>,
  visibleColumns: readonly ColumnID[],
  rowStyle: React.CSSProperties | ((data: DataType) => React.CSSProperties) | undefined,
  entries: readonly (DataType & {children?: readonly DataType[] | undefined})[],
  keyPart: string,
): JSX.Element[] {
  const result: JSX.Element[] = [];
  groupEntries(groupByColumn, entries).forEach((groupedEntries) => {
    const key = groupedEntries[0][groupByColumn.sortField || groupByColumn.field];
    const headerRow = (
      <TableRow key={`-- ${keyPart} -- ${key} --`}>
        <TableCell colSpan={visibleColumns.length} variant="head">
          {groupedEntries[0][groupByColumn.field]}
        </TableCell>
      </TableRow>
    );
    result.push(headerRow);
    result.push(...makeRows(columns, visibleColumns, rowStyle, groupedEntries));
  });
  return result;
}

export interface GenericTableProps<
  FieldID extends string,
  ColumnID extends string,
  KeyType extends string = string,
  DataType extends RowData<FieldID, KeyType> = RowData<FieldID, KeyType>,
> {
  columns: ColumnSpecifications<FieldID, ColumnID, KeyType, DataType>;
  entries: readonly (DataType & {children?: readonly DataType[] | undefined})[];
  entriesAlreadySortedAndFiltered?: boolean;
  filterString?: string;
  groupBy?: ColumnID | undefined;
  maxDisplayed?: number | undefined;
  onHeaderClick?: (key: ColumnID) => void;
  overrideSortBy?: ColumnID | null | undefined;
  rowStyle?: React.CSSProperties | ((data: DataType) => React.CSSProperties) | undefined;
  sortBy?: ColumnID | null;
  sortDirection: SortDirection;
  visibleColumns: readonly ColumnID[];
}

/**
 * Current "normal" usage pattern:
 * * Define types XTableFieldID, XTableColumnID, XTableDataType.
 * * Table setup:
 *   * Define memoized function `buildColumnSpecifications(formatMessage: InjectedIntl["formatMessage"], onClick: (key: string) => void): ColumnSpecifications<XTableFieldID, XTableColumnID, XTableDataType>`.
 *     Variations beyond using localisation and the provided `onClick` callback
 *     should be expressed with separate columns; so that the selection of what
 *     columns/fields to display based on settings can be expressed in
 *    `computeVisibleColumns`.
 *   * Define memoized function `computeVisibleColumns(...): readonly XTableColumnID[]`,
 *     determine the currently visible columns based on settings,
 *     active tab, user role and so on.
 *   * Define constant `TABLE_SORTING_IDENTIFIER`; use `getTableSortingState`
 *     to get current sorting parameters, and define `handleHeaderClick` calling
 *     `putTableSortingState` to update sorting state on clicks.
 * * Data processing:
 *   * Optional; memoized function `filterX(xArray: readonly X[], ...): readonly X[]`,
 *     filter data on current tab, user role, settings and so on.
 *   * Optional; memoized function `sortX(xArray: readonly X[], ...): readonly X[]`,
 *     sort data if/when input not sorted from/in Redux selector..
 *     Data *should* be sorted so that entries with the "same" value for the
 *     column we end up sorting on get a consistent order.
 *   * Define memoized function `buildRowData(xArray: readonly X[], ...): readonly (XTableDataType & {children?: readonly XTableDataType[]})[]`.
 *     The `children` part, being optional, need not be part of the declared type if unused.
 */
export class GenericTable<
  FieldID extends string,
  ColumnID extends string,
  KeyType extends string = string,
  DataType extends RowData<FieldID, KeyType> = RowData<FieldID, KeyType>,
> extends React.PureComponent<GenericTableProps<FieldID, ColumnID, KeyType, DataType>> {
  constructor(props: GenericTableProps<FieldID, ColumnID, KeyType, DataType>) {
    super(props);
    this.sortRows = memoize<typeof sortRows>(sortRows);
    this.filterRows = memoize<typeof filterRows>(filterRows);
  }
  sortRows: (
    columns: ColumnSpecifications<FieldID, ColumnID, KeyType, DataType>,
    data: readonly (DataType & {children?: readonly DataType[] | undefined})[],
    sortBy: ColumnID | null | undefined,
    sortDirection: SortDirection,
    overrideSortBy: ColumnID | null | undefined,
    groupBy: ColumnID | null | undefined,
  ) => readonly (DataType & {children?: readonly DataType[] | undefined})[];
  filterRows: (
    columns: ColumnSpecifications<FieldID, ColumnID, KeyType, DataType>,
    data: readonly (DataType & {children?: readonly DataType[] | undefined})[],
    filterString: string | undefined,
    visibleColumns: readonly ColumnID[],
  ) => readonly (DataType & {children?: readonly DataType[] | undefined})[];
  render(): JSX.Element {
    const {
      columns,
      entries,
      entriesAlreadySortedAndFiltered,
      filterString,
      groupBy,
      maxDisplayed,
      onHeaderClick,
      overrideSortBy,
      rowStyle,
      sortBy,
      sortDirection,
      visibleColumns,
    } = this.props;
    // there should be no repeated columns
    console.assert(
      new Set(visibleColumns).size === visibleColumns.length,
      "repetitions in visibleColumns",
    );
    const headerColumns = visibleColumns.map((columnID) => {
      const column = columns[columnID];
      return (
        <GenericTableHeaderCell
          key={columnID}
          column={column}
          columnID={columnID}
          sortDirection={sortBy === columnID ? sortDirection : undefined}
          onClick={onHeaderClick}
        />
      );
    });
    const sorted = entriesAlreadySortedAndFiltered
      ? entries
      : this.sortRows(columns, entries, sortBy, sortDirection, overrideSortBy, groupBy);
    const filtered = entriesAlreadySortedAndFiltered
      ? sorted
      : this.filterRows(columns, sorted, filterString, visibleColumns);
    const limited =
      maxDisplayed && filtered.length > maxDisplayed ? filtered.slice(0, maxDisplayed) : filtered;

    const dataRows: JSX.Element[] = [];

    const groupByColumn = groupBy ? columns[groupBy] : undefined;

    if (limited.length) {
      if (groupByColumn) {
        dataRows.push(
          ...makeGroupedRows(
            groupByColumn,
            columns,
            visibleColumns,
            rowStyle,
            limited,
            "GROUP-HEADER",
          ),
        );
      } else {
        dataRows.push(...makeRows(columns, visibleColumns, rowStyle, limited));
      }
    }
    let searchForMoreRow: JSX.Element | undefined;
    if (maxDisplayed && filtered.length > maxDisplayed) {
      console.assert(limited.length === maxDisplayed);
      searchForMoreRow = (
        <TableRow>
          <TableCell colSpan={visibleColumns.length}>
            <FormattedMessage
              defaultMessage="— Søg for at se flere —"
              id="generic-table.label.search-for-more"
            />
          </TableCell>
        </TableRow>
      );
    }
    return (
      <Table stickyHeader size="small" style={{backgroundColor: "#fff"}}>
        <TableHead style={{whiteSpace: "nowrap"}}>
          <TableRow>{headerColumns}</TableRow>
        </TableHead>
        <TableBody>
          {dataRows}
          {searchForMoreRow}
        </TableBody>
      </Table>
    );
  }
}
