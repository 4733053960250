import {SlideUpTransition} from "@co-frontend-libs/components";
import {Dialog} from "@material-ui/core";
import React from "react";
import {LocationMap} from "./location-map";

interface FullWindowLocationMapProps {
  centerLatitude: number;
  centerLongitude: number;
  latitude: number | null;
  longitude: number | null;
  mapLoaded: boolean;
  onClose: () => void;
  onLocationChange: (latitude: number | null, longitude: number | null) => void;
  open: boolean;
}

export function FullWindowLocationMap(props: FullWindowLocationMapProps): JSX.Element {
  const {onClose, open, ...rest} = props;
  return (
    <Dialog fullScreen open={open} TransitionComponent={SlideUpTransition} onClose={onClose}>
      <LocationMap onClose={onClose} {...rest} />
    </Dialog>
  );
}
