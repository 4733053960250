import {Command, ResourceInstance, ResourceName, ResourceTypes} from "@co-common-libs/resources";
import {Query, SerializableError} from "@co-frontend-libs/db-resources";

export type ResourceInstanceRecords = {
  readonly [N in ResourceName]: Partial<{
    readonly [url: string]: ResourceTypes[N];
  }>;
};

export type GenericInstanceRecord = Partial<{
  readonly [url: string]: ResourceInstance;
}>;

export type ResourceInstanceNullRecords = {
  readonly [N in ResourceName]: Readonly<Partial<Record<string, ResourceInstance | null>>>;
};

export type ResourceInstanceArrays = {
  readonly [N in ResourceName]: readonly Readonly<ResourceTypes[N]>[];
};

export type ResourceURLArrays = {
  readonly [N in ResourceName]: readonly string[];
};

export type CommitAction = "CREATE" | "DELETE" | "UPDATE";

export interface CreateCommandPromise {
  readonly action: "CREATE_PROMISE";
  readonly url: string;
}

export type CommandStatus = "SAVED_LOCALLY" | "SAVING_LOCALLY" | "SAVING_ONLINE" | "UNSAVED";

interface CommitEntryBase {
  readonly apiVersion: string | null;
  readonly error: SerializableError | null;
  readonly errorTimestamp: string | null;
  readonly frontendVersion: string | null;
  readonly id: number;
  readonly requestId: string | null;
}
interface NormalCommitEntry extends CommitEntryBase {
  readonly command: Command;
  readonly status: CommandStatus;
}
interface PromiseCommitEntry extends CommitEntryBase {
  readonly command: CreateCommandPromise;
  readonly status: "PROMISE";
}

export type CommitEntry = NormalCommitEntry | PromiseCommitEntry;

export type QueryState = {
  readonly currentlyFullFetching: boolean;
  /** Timestamp from backend; when was the (possibly cached)
   * full-fetch data computed? */
  readonly fullFetchDataComputedAtTimestamp: string | null;
  readonly lastError: SerializableError | null;
  readonly lastErrorTimestamp: string | null;
  readonly pendingRemoval: boolean;
  /** Timestamp from backend; when was the oldest changes-data that criteria
   * from this query were applied to computed? */
  readonly takenIntoAccountForChangesComputedAtTimestamp: string | null;
};

export const emptyQueryState: QueryState = {
  currentlyFullFetching: false,
  fullFetchDataComputedAtTimestamp: null,
  lastError: null,
  lastErrorTimestamp: null,
  pendingRemoval: false,
  takenIntoAccountForChangesComputedAtTimestamp: null,
};

export type QueryQueryStateStruct = {
  readonly query: Query;
  readonly queryState: QueryState;
};

export type ResourcesState = {
  commitLoaded: boolean;
  commitQueue: readonly CommitEntry[];
  currentlyFetchingChanges: boolean;
  fetchByIdActive: Partial<{
    readonly [N in ResourceName]: string;
  }>;
  fetchByRelationActive: Partial<{
    readonly [N in ResourceName]: string;
  }>;
  lastFetchChangesError: SerializableError | null;
  lastFetchChangesErrorTimestamp: string | null;
  lastFetchChangesTimestamp: string | null;
  localDBError: Error | null;
  offlineLoaded: boolean;
  persistedData: ResourceInstanceRecords;
  persistedFetchByID: Partial<{
    readonly [N in ResourceName]: readonly string[];
  }>;
  persistedFetchByRelation: Partial<{
    readonly [N in ResourceName]: Partial<{
      readonly [memberName: string]: readonly string[];
    }>;
  }>;
  persistedQueries: Partial<{
    readonly [queryIdentifier: string]: QueryQueryStateStruct;
  }>;
  temporaryData: ResourceInstanceRecords;
  temporaryFetchByID: Partial<{
    readonly [N in ResourceName]: readonly string[];
  }>;
  temporaryFetchByRelation: Partial<{
    readonly [N in ResourceName]: Partial<{
      readonly [memberName: string]: readonly string[];
    }>;
  }>;
  temporaryQueries: Partial<{
    readonly [queryIdentifier: string]: QueryQueryStateStruct;
  }>;
  // key -> query-ID
  temporaryQueriesForKeys: Partial<{
    readonly [key: string]: string[];
  }>;
  // pathName -> key (default key "") -> query-ID
  temporaryQueriesForPathNames: Partial<{
    readonly [pathName: string]: Partial<{
      readonly [key: string]: string[];
    }>;
  }>;
};

export interface StateWithResources {
  resources: ResourcesState;
}
