import {ResponsiveDialog, TrimTextField} from "@co-frontend-libs/components";
import {DialogContent} from "@material-ui/core";
import React, {useCallback, useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";

interface LocationTypeIdentifierDialogProps {
  identifier?: string;
  illegalIdentifiers?: ReadonlySet<string> | undefined;
  onCancel: () => void;
  onOk: (identifier: string) => void;
  open: boolean;
}

export function LocationTypeIdentifierDialog(
  props: LocationTypeIdentifierDialogProps,
): JSX.Element {
  const {identifier: propsIdentifier, illegalIdentifiers, onCancel, onOk, open} = props;
  const [identifier, setIdentifier] = useState(propsIdentifier || "");
  useEffect(() => {
    if (open) {
      setIdentifier(propsIdentifier || "");
    }
  }, [propsIdentifier, open]);

  const handleOk = useCallback(() => {
    onOk(identifier);
  }, [onOk, identifier]);
  const title =
    propsIdentifier === undefined ? (
      <FormattedMessage
        defaultMessage="Opret stedtype"
        id="location-types.dialog-title.create-location-type"
      />
    ) : (
      <FormattedMessage
        defaultMessage="Redigér stedtype"
        id="location-types.dialog-title.edit-location-type"
      />
    );
  const illegalIdentifier =
    !!identifier && !!(illegalIdentifiers && illegalIdentifiers.has(identifier));
  const okDisabled = !identifier || illegalIdentifier;
  return (
    <ResponsiveDialog
      okDisabled={okDisabled}
      open={open}
      title={title}
      onCancel={onCancel}
      onOk={handleOk}
    >
      <DialogContent>
        <TrimTextField
          autoFocus
          fullWidth
          error={illegalIdentifier}
          helperText={
            illegalIdentifier ? (
              <FormattedMessage
                defaultMessage="ID allerede i brug"
                id="location-types.error-text.identifier-crash"
              />
            ) : undefined
          }
          label={<FormattedMessage defaultMessage="ID" id="location-types.label.identifier" />}
          margin="dense"
          value={identifier}
          variant="outlined"
          onChange={setIdentifier}
        />
      </DialogContent>
    </ResponsiveDialog>
  );
}
