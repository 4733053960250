import {Location, LocationUrl} from "@co-common-libs/resources";

export type LocationGeoJson = Exclude<Location["geojson"], null>;

export type LocationGeoJsonWithUrl = LocationGeoJson & {
  readonly properties: {
    readonly crop: string;
    readonly disabled: boolean;
    readonly isSelected: boolean;
    readonly url: LocationUrl;
  };
};

export type LocationWithGeoJson = Location & {geojson: LocationGeoJson};

export function isLocationWithGeoJson(location: Location): location is LocationWithGeoJson {
  return !!location.geojson;
}
