// Based on Python stringold.py zfill().
// Old/deprecated in Python; implicity type conversion was/is considered
// problematic in Python, and the string type gained a zfill() method...
export const padZero = (x: unknown, width: number): string => {
  let s = `${x}`;
  const n = s.length;
  let sign = "";
  if (s[0] === "+" || s[0] === "-") {
    sign = s[0];
    s = s.substring(1);
  }
  let padding = "";
  while (padding.length < width - n) {
    padding += "0";
  }
  return sign + padding + s;
};
