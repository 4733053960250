import {SettingID, SettingsModuleID} from "@co-common-libs/config";
import {MachineAnalysisAccountsTable} from "app-components";
import React from "react";
import {BackendConfigurations} from "./customer-specific-settings/backend-configurations";
import {ReportingSpecificationList} from "./reporting";
import {AbsenceTypeColorsDialog} from "./settings-components/absence-type-colors-dialog";
import {AbsenceTypeLabels} from "./settings-components/absence-type-labels";
import {AbsenceTypeShortLabels} from "./settings-components/absence-type-short-labels";
import {AllWeekDaysNullableHoursRangeSettingDialog} from "./settings-components/all-week-days-nullable-hours-range-setting-dialog";
import {AllWeekDaysTimeRanges} from "./settings-components/all-week-days-time-ranges";
import {AutoSupplementingProducts} from "./settings-components/auto-supplementing-products";
import {AutoSupplementingProductsDialog} from "./settings-components/auto-supplementing-products-dialog";
import {BookkeepingListColumnsDialog} from "./settings-components/bookeeping-list-columns-dialog";
import {CustomerRequiredFieldsDialog} from "./settings-components/customer-required-fields-dialog";
import {DayEndRounding} from "./settings-components/day-end-rounding";
import {DayStartRounding} from "./settings-components/day-start-rounding";
import {Departments} from "./settings-components/departments";
import {DepartmentsSelect} from "./settings-components/departments-select";
import {AbsenceTypeColors} from "./settings-components/display-absence-type-colors";
import {DisplayAbsenceTypes} from "./settings-components/display-absence-types";
import {DisplayColor} from "./settings-components/display-color";
import {DisplayCustomerRequiredFields} from "./settings-components/display-customer-required-fields";
import {DisplayGeolocation} from "./settings-components/display-geolocation";
import {DisplayIndustries} from "./settings-components/display-industries";
import {DisplayLink} from "./settings-components/display-link";
import {DisplayNumberRanges} from "./settings-components/display-number-ranges";
import {DisplayOrderCopyFields} from "./settings-components/display-order-copy-fields";
import {DisplayPrimaryTimerLabels} from "./settings-components/display-primary-timer-labels";
import {DisplaySelectedCustomer} from "./settings-components/display-selected-customer";
import {DisplaySelectedUser} from "./settings-components/display-selected-employee";
import {DisplaySelectedProducts} from "./settings-components/display-selected-products";
import {SendValidatedGenericLogToCustomers} from "./settings-components/display-send-validated-generic-log-to-customers";
import {DisplayString} from "./settings-components/display-string";
import {DisplayTaskCopyFields} from "./settings-components/display-task-copy-fields";
import {DisplayUnitConversionHelpers} from "./settings-components/display-unit-conversion-helpers";
import {DisplayWorktypeAutoMachines} from "./settings-components/display-worktype-auto-machines";
import {DisplayWorkTypePrimaryTimerLabels} from "./settings-components/display-worktype-primary-timer-labels";
import {EconomicDefaultPaymentTermsDialog} from "./settings-components/economic-default-payment-terms-dialog";
import {EconomicSyncListColumnsDialog} from "./settings-components/economic-sync-list-columns-dialog";
import {EnableSmallMachinesDialog} from "./settings-components/enable-small-machines";
import {EnterIntegersSetting} from "./settings-components/enter-integers";
import {EnterStringsSetting} from "./settings-components/enter-strings";
import {GeolocationDialog} from "./settings-components/geolocation-dialog";
import {IndustriesDialog} from "./settings-components/industries-dialog";
import {MachineNumberOffsetDialog} from "./settings-components/machine-number-offset-dialog";
import {NullableHoursRangeSettingDialog} from "./settings-components/nullable-hours-range-setting-dialog";
import {NumberRangeDialog} from "./settings-components/number-range-dialog";
import {OrderCopyFieldsDialog} from "./settings-components/order-copy-fields-dialog";
import {OrderListColumnsDialog} from "./settings-components/order-list-columns-dialog";
import {PrimaryTimerLabelsDialog} from "./settings-components/primary-timer-labels-dialog";
import {SelectAbsenceTypes} from "./settings-components/select-absence-types";
import {SelectAbsenceTypesOrAll} from "./settings-components/select-absence-types-or-all";
import {SelectAllMachines} from "./settings-components/select-all-machines";
import {SelectAllWorktypes} from "./settings-components/select-all-worktypes";
import {SelectBarcodeFormats} from "./settings-components/select-barcode-formats";
import {SelectColorDialog} from "./settings-components/select-color-dialog";
import {SelectCustomerId} from "./settings-components/select-customer-id";
import {SelectCustomers} from "./settings-components/select-customers";
import {SelectDayAbsenceTypesDialog} from "./settings-components/select-day-absence-types-dialog";
import {SelectEmployee} from "./settings-components/select-employee";
import {SelectExternalWorktypes} from "./settings-components/select-external-worktypes";
import {SelectInternalWorktype} from "./settings-components/select-internal-worktype";
import {SelectInternalWorktypes} from "./settings-components/select-internal-worktypes";
import {SelectProducts} from "./settings-components/select-products";
import {SelectReportingSpecifications} from "./settings-components/select-reporting-specifications";
import {SelectTimers} from "./settings-components/select-timers";
import {SelectUnits} from "./settings-components/select-units";
import {SelectUnitsOrAll} from "./settings-components/select-units-or-all";
import {SelectWeekday} from "./settings-components/select-weekday";
import {SelectWeekdays} from "./settings-components/select-weekdays";
import {SendValidatedLogToCustomersDialog} from "./settings-components/send-validated-log-to-customers-dialog";
import {TaskCopyFieldsDialog} from "./settings-components/task-copy-fields-dialog";
import {TaskListColumnsDialog} from "./settings-components/task-list-columns-dialog";
import {UnitConversionHelpersDialog} from "./settings-components/unit-conversion-helpers-dialog";
import {UseApproveReportDialog} from "./settings-components/use-approve-report-dialog";
import {UserListColumnsDialog} from "./settings-components/user-list-columns-dialog";
import {WorkTypeAutoMachinesDialog} from "./settings-components/worktype-auto-machines-dialog";
import {WorkTypePrimaryTimerLabelsDialog} from "./settings-components/worktype-primary-timer-labels-dialog";
import {SettingJSON} from "./settings-json";
import {SettingDialogProps} from "./settings-view/setting-dialog";
import {settingValueDisplayComponentWrapper} from "./settings-view/setting-value-display-component-wrapper";
import {TimerList} from "./timers";
import {SettingValueDisplayProps, SettingViewProps} from "./types";

/** @deprecated
 * specify `valueDisplayOverrides` and/or `editDialogOverrides`  instead
 */
export const settingsOverrides: Partial<{
  [id in SettingID]: React.ComponentType<SettingViewProps>;
}> = {
  absenceTypeLabels: AbsenceTypeLabels,
  absenceTypesAccessibleToEmployees: SelectAbsenceTypesOrAll,
  absenceTypeShortLabels: AbsenceTypeShortLabels,
  absenceTypesVisibleOnFullscreenAbsenceCalendar: SelectAbsenceTypesOrAll,
  absenceWarningDisabledFor: SelectAbsenceTypes,
  allowMoreThanTwoMachinesForDepartments: DepartmentsSelect,
  autoAddSprayLogFor: SelectExternalWorktypes,
  autoAddTransportLogFor: SelectExternalWorktypes,
  autoAddYieldLogFor: SelectExternalWorktypes,
  availabilityWeekdays: SelectWeekdays,
  availabilityWeekdaysAskFromDay: SelectWeekday,
  availabilityWeekendsAskFromDay: SelectWeekday,
  barcodeScannerMachineDialog: SelectBarcodeFormats,
  barcodeScannerProductDialog: SelectBarcodeFormats,
  calendarShowMaterialsWithUnits: SelectUnits,
  concurrentTasksAllowedForDepartments: DepartmentsSelect,
  concurrentTasksAllowedForWorkTypes: SelectAllWorktypes,
  daysAbsenceTypes: SelectAbsenceTypes,
  departments: Departments,
  dinnerBookingLocations: EnterStringsSetting,
  disabledMachines: SelectAllMachines,
  disabledWorkTypes: SelectAllWorktypes,
  economicMaterialProductGroups: EnterIntegersSetting,
  economicProjectsMachineEmployeeGroups: EnterIntegersSetting,
  economicProjectsManHoursMachines: EnterStringsSetting,
  economicShowGenericTransportlogOnInvoice: SelectReportingSpecifications,
  economicSubtractDowntimeIdentifiers: SelectTimers,
  enableWorkshopChecklistsFor: SelectInternalWorktypes,
  googleMapsCountryRestrictions: EnterStringsSetting,
  hideInternalTaskListWorkTypes: SelectInternalWorktypes,
  hidePrimaryTimeButtonForExternalWorktypes: SelectExternalWorktypes,
  hidePrimaryTimeButtonForInternalWorktypes: SelectInternalWorktypes,
  hoursAbsenceTypes: SelectAbsenceTypes,
  invoiceAddGenericTransportLogs: SelectReportingSpecifications,
  invoiceExtraEffectiveTimerIdentifiers: SelectTimers,
  invoiceSubtractDowntimeTimers: SelectTimers,
  logRecipients: EnterStringsSetting,
  lunchBookingLocations: EnterStringsSetting,
  navTimersToTransferWithoutMachines: SelectTimers,
  onlyEnableProjectsForDepartments: DepartmentsSelect,
  onlyShowCalledInDialogForWorktypes: SelectAllWorktypes,
  onlyWeekdaysAllowedAbsenceTypes: SelectAbsenceTypes,
  orderValidationForWorkTypes: SelectExternalWorktypes,
  preparationWorkType: SelectInternalWorktype,
  remunerationAbsenceTypes: SelectAbsenceTypes,
  remunerationPaidTransportWorkType: SelectInternalWorktype,
  repairWorkType: SelectInternalWorktype,
  reportSupportedUnits: SelectUnitsOrAll,
  sendApprovedTransportlogToCustomers: SelectCustomers,
  singleDayAbsenceTypes: SelectAbsenceTypes,
  syncRecipients: EnterStringsSetting,
  timersContributingToEffectiveTime: SelectTimers,
  transportResponsibleEffectiveTimers: SelectTimers,
  treatTransportTimeAsEffectiveForDepartments: DepartmentsSelect,
  workshopWorkTypes: SelectInternalWorktypes,
};

export const valueDisplayOverrides: Partial<{
  [id in SettingID]: React.ComponentType<SettingValueDisplayProps>;
}> = {
  absenceCalendarBlockColor: DisplayColor,
  absenceTypeColors: AbsenceTypeColors,
  autoSupplementingProducts: AutoSupplementingProducts,
  consultantNotes: DisplayString,
  continuationCopyFields: DisplayTaskCopyFields,
  customerRequiredFields: DisplayCustomerRequiredFields,
  dayEndRounding: DayEndRounding,
  dayStartRounding: DayStartRounding,
  defaultTaskEmployee: DisplaySelectedUser,
  dinnerBookings: AllWeekDaysTimeRanges,
  economicPriceitemProductGroupRanges: DisplayNumberRanges,
  foodBookingNotificationTime: AllWeekDaysTimeRanges,
  geolocation: DisplayGeolocation,
  industries: DisplayIndustries,
  invoiceExcludeProducts: DisplaySelectedProducts,
  machineAnalysisAccounts: settingValueDisplayComponentWrapper(MachineAnalysisAccountsTable, {
    fabPositioning: "absolute",
  } as const),
  orderCopyFields: DisplayOrderCopyFields,
  ownCompanyId: DisplaySelectedCustomer,
  paidDayAbsenceTypes: DisplayAbsenceTypes,
  priceItemRelationUnitConversionHelpers: DisplayUnitConversionHelpers,
  productsExcludedFromInvoice: DisplaySelectedProducts,
  sendValidatedGenericLogToCustomers: SendValidatedGenericLogToCustomers,
  taskCopyFields: DisplayTaskCopyFields,
  website: DisplayLink,
  workTypeAutoMachines: DisplayWorktypeAutoMachines,
  workTypeGenericPrimaryTimerLabel: DisplayWorkTypePrimaryTimerLabels,
  workTypePriceGroupGenericPrimaryTimerLabel: DisplayPrimaryTimerLabels,
};

function editDialogNoop(_: SettingDialogProps): null {
  return null;
}

export const editDialogOverrides: Partial<{
  [id in SettingID]: React.ComponentType<SettingDialogProps>;
}> = {
  absenceCalendarBlockColor: SelectColorDialog,
  absenceTypeColors: AbsenceTypeColorsDialog,
  autoSupplementingProducts: AutoSupplementingProductsDialog,
  bookkeepingListColumns: BookkeepingListColumnsDialog,
  continuationCopyFields: TaskCopyFieldsDialog,
  customerRequiredFields: CustomerRequiredFieldsDialog,
  dayEndRounding: NullableHoursRangeSettingDialog,
  dayStartRounding: NullableHoursRangeSettingDialog,
  defaultTaskEmployee: SelectEmployee,
  dinnerBookings: AllWeekDaysNullableHoursRangeSettingDialog,
  economicDefaultPaymentTerms: EconomicDefaultPaymentTermsDialog,
  economicPriceitemProductGroupRanges: NumberRangeDialog,
  economicProjectsMachineEmployeeNumberOffset: MachineNumberOffsetDialog,
  economicSyncListColumns: EconomicSyncListColumnsDialog,
  enableSmallMachines: EnableSmallMachinesDialog,
  foodBookingNotificationTime: AllWeekDaysNullableHoursRangeSettingDialog,
  geolocation: GeolocationDialog,
  industries: IndustriesDialog,
  machineAnalysisAccounts: editDialogNoop,
  orderCopyFields: OrderCopyFieldsDialog,
  orderListColumns: OrderListColumnsDialog,
  ownCompanyId: SelectCustomerId,
  paidDayAbsenceTypes: SelectDayAbsenceTypesDialog,
  priceItemRelationUnitConversionHelpers: UnitConversionHelpersDialog,
  productsExcludedFromInvoice: SelectProducts,
  sendValidatedGenericLogToCustomers: SendValidatedLogToCustomersDialog,
  taskCopyFields: TaskCopyFieldsDialog,
  taskListColumns: TaskListColumnsDialog,
  useApproveReport: UseApproveReportDialog,
  userListColumns: UserListColumnsDialog,
  workTypeAutoMachines: WorkTypeAutoMachinesDialog,
  workTypeGenericPrimaryTimerLabel: WorkTypePrimaryTimerLabelsDialog,
  workTypePriceGroupGenericPrimaryTimerLabel: PrimaryTimerLabelsDialog,
};

export const settingsModulesOverrides: Partial<{
  [id in SettingsModuleID]: React.ComponentType;
}> = {
  backendConfiguration: BackendConfigurations,
  expert: SettingJSON,
  reporting: ReportingSpecificationList,
  timers: TimerList,
};
