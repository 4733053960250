import {ThrottledTextField} from "@co-frontend-libs/components";
import {getCustomerSettings} from "@co-frontend-libs/redux";
import {IconButton, TableCell, TableRow} from "@material-ui/core";
import {red} from "@material-ui/core/colors";
import PlusIcon from "mdi-react/PlusIcon";
import React from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {MaterialField} from "../material-field";
import {CONVERSION_UNIT_COLUMN_WIDTH, COUNT_COLUMN_WIDTH, UNIT_COLUMN_WIDTH} from "./constants";
import {EntryProps, withEntryCallbacks} from "./with-entry-callbacks";

export const DisplayTableRow = React.memo(function DisplayTableRow(props: EntryProps): JSX.Element {
  const {
    booleanSelection,
    countValue,
    dangling,
    inputUnitSymbol,
    notes,
    notesReadonly,
    onCountChange,
    onNotesChange,
    onPlusClick,
    onRelationCountChange,
    priceItemActive,
    priceItemNameAndIdentifier,
    readonly,
    relationConversionUnitSymbol,
    relationConversionValue,
    relationCountValue,
    showNotes,
    showRelationConversionColumns,
  } = props;

  const intl = useIntl();

  const customerSettings = useSelector(getCustomerSettings);

  const color = (dangling || !priceItemActive) && countValue ? red[500] : undefined;

  const cellCommonStyle: React.CSSProperties = color ? {color} : {};

  return (
    <TableRow>
      <TableCell style={cellCommonStyle}>{priceItemNameAndIdentifier}</TableCell>
      <TableCell style={{...cellCommonStyle, width: COUNT_COLUMN_WIDTH}}>
        <MaterialField
          booleanSelection={booleanSelection}
          customerSettings={customerSettings}
          disabled={readonly}
          unit={inputUnitSymbol}
          value={countValue ?? undefined}
          onChange={onCountChange}
        />
      </TableCell>
      <TableCell style={{...cellCommonStyle, paddingLeft: 0, paddingRight: 0, width: 48}}>
        {!booleanSelection ? (
          <IconButton disabled={readonly} onClick={onPlusClick}>
            {color ? <PlusIcon color={color} /> : <PlusIcon />}
          </IconButton>
        ) : null}
      </TableCell>
      <TableCell style={{...cellCommonStyle, width: UNIT_COLUMN_WIDTH}}>
        {!booleanSelection ? inputUnitSymbol : null}
      </TableCell>
      {showRelationConversionColumns ? (
        <TableCell style={{width: COUNT_COLUMN_WIDTH}}>
          {relationConversionUnitSymbol ? (
            <MaterialField
              customerSettings={customerSettings}
              disabled={readonly || !relationConversionValue}
              unit={relationConversionUnitSymbol}
              value={relationCountValue ?? undefined}
              onChange={onRelationCountChange}
            />
          ) : null}
        </TableCell>
      ) : null}
      {showRelationConversionColumns ? (
        <TableCell style={{width: CONVERSION_UNIT_COLUMN_WIDTH}}>
          {relationConversionUnitSymbol}
        </TableCell>
      ) : null}
      {showNotes ? (
        <TableCell>
          <ThrottledTextField
            fullWidth
            disabled={notesReadonly || !onNotesChange}
            label={intl.formatMessage({defaultMessage: "Noter"})}
            margin="dense"
            value={notes}
            variant="outlined"
            onChange={onNotesChange}
          />
        </TableCell>
      ) : null}
    </TableRow>
  );
});

export const DisplayTableRowWithEntryCallbacks = withEntryCallbacks(DisplayTableRow);
