import {Config} from "@co-common-libs/config";
import {DaysAbsence, HoursAbsence, resourceNameFor} from "@co-common-libs/resources";
import {formatDate, formatTime} from "@co-common-libs/utils";
import {ResponsiveDialog, TrimTextField} from "@co-frontend-libs/components";
import {getUserUserProfileLookup} from "@co-frontend-libs/redux";
import {DialogContent} from "@material-ui/core";
import _ from "lodash";
import React, {useCallback, useEffect, useState} from "react";
import {defineMessages, useIntl} from "react-intl";
import {useSelector} from "react-redux";

const messages = defineMessages({
  childsFirstSickDay: {
    defaultMessage: "Barns første sygedag",
  },
  compensatory: {
    defaultMessage: "Afspadsering",
  },
  daysOffWithoutPay: {
    defaultMessage: "Fridage uden løn",
  },
  employeeReason: {
    defaultMessage: "Medarbejder note",
  },
  floatingHoliday: {
    defaultMessage: "Ferie-fridage",
  },
  note: {
    defaultMessage: "Note",
  },
  save: {
    defaultMessage: "Gem",
  },
  sickLeave: {
    defaultMessage: "Sygefravær",
  },
  vacation: {
    defaultMessage: "Feriedage",
  },
});

interface EditAbsenceDialogProps {
  absenceInstance?: DaysAbsence | HoursAbsence | undefined;
  customerSettings: Config;
  onCancel: () => void;
  onDelete: (url: string) => void;
  onOk: (url: string, note: string) => void;
  open: boolean;
}

export const EditAbsenceDialog = React.memo(function EditAbsenceDialog(
  props: EditAbsenceDialogProps,
): JSX.Element | null {
  const intl = useIntl();
  const {absenceInstance, customerSettings, onCancel, onDelete, onOk, open} = props;
  const [note, setNote] = useState((absenceInstance && absenceInstance.note) || "");

  useEffect(() => {
    if (open) {
      setNote((absenceInstance && absenceInstance.note) || "");
    }
  }, [absenceInstance, open]);

  const handleDelete = useCallback(() => {
    if (absenceInstance) {
      onDelete(absenceInstance.url);
    }
  }, [absenceInstance, onDelete]);

  const handleOk = useCallback(() => {
    if (absenceInstance) {
      onOk(absenceInstance.url, note);
    }
  }, [absenceInstance, note, onOk]);

  const userUserProfileLookup = useSelector(getUserUserProfileLookup);
  const userUserProfile = absenceInstance?.user
    ? userUserProfileLookup(absenceInstance.user)
    : null;

  if (!open || !absenceInstance) {
    return null;
  }
  const resourceName = resourceNameFor(absenceInstance.url);
  let absenceString;
  if (resourceName === "hoursAbsence") {
    const hoursAbsence = absenceInstance as HoursAbsence;
    const {fromTimestamp} = hoursAbsence;
    const {toTimestamp} = hoursAbsence;
    const {absenceType} = hoursAbsence;
    const absenceTypeLabel =
      customerSettings.absenceTypeLabels[absenceType] ||
      ((messages as any)[absenceType]
        ? intl.formatMessage((messages as any)[absenceType])
        : absenceType);

    absenceString = `${formatTime(fromTimestamp)} - ${formatTime(
      toTimestamp,
    )}: ${absenceTypeLabel}`;
  } else if (resourceName === "daysAbsence") {
    const daysAbsence = absenceInstance as DaysAbsence;
    const {fromDate} = daysAbsence;
    const {toDate} = daysAbsence;
    const {absenceType} = daysAbsence;
    const absenceTypeLabel =
      customerSettings.absenceTypeLabels[absenceType] ||
      ((messages as any)[absenceType]
        ? intl.formatMessage((messages as any)[absenceType])
        : absenceType);
    absenceString = `${formatDate(fromDate)} - ${formatDate(toDate)}: ${absenceTypeLabel}`;
  }

  return (
    <ResponsiveDialog
      okLabel={intl.formatMessage(messages.save)}
      open={open}
      title={
        userUserProfile?.alias
          ? intl.formatMessage(
              {
                defaultMessage: "Rediger fravær for {alias}",
              },
              {alias: userUserProfile.alias},
            )
          : intl.formatMessage({
              defaultMessage: "Rediger fravær",
            })
      }
      onCancel={onCancel}
      onDelete={handleDelete}
      onOk={handleOk}
    >
      <DialogContent>
        {absenceString}
        <TrimTextField
          autoFocus
          fullWidth
          multiline
          label={intl.formatMessage(messages.note)}
          margin="dense"
          maxRows={30}
          minRows={2}
          value={note}
          variant="outlined"
          onChange={setNote}
        />
        <b>{intl.formatMessage(messages.employeeReason)}</b>
        <br />
        {absenceInstance.employeeReason}
      </DialogContent>
    </ResponsiveDialog>
  );
});
