import {EmployeeGroupUrl} from "@co-common-libs/resources";
import {getActiveEmployeeGroupArray} from "@co-frontend-libs/redux";
import {IntlShape} from "react-intl";
import {useSelector} from "react-redux";
import {z} from "zod";

// We want the return type from the Zod schema
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function getEditFormValidator(intl: IntlShape, url?: EmployeeGroupUrl | undefined) {
  return z.object({
    active: z.boolean(),
    name: z
      .string()
      .min(1, intl.formatMessage({defaultMessage: "Navn skal udfyldes"}))
      .refine(
        (name) =>
          url !== undefined &&
          !useSelector(getActiveEmployeeGroupArray).some(
            (employeeGroup) =>
              employeeGroup.url !== url && employeeGroup.name.toLowerCase() === name.toLowerCase(),
          ),
        intl.formatMessage({
          defaultMessage: "Der eksisterer allerede en medarbejdergruppe med det navn",
        }),
      ),
  });
}

export type EditFormSchema = ReturnType<typeof getEditFormValidator>;
