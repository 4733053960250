import {ReadonlyConfig} from "@co-common-libs/config";
import {Machine} from "@co-common-libs/resources";
import {
  ActiveEconomySystemIntegrationKind,
  EconomySystemIntegrationKindPart,
  getEconomySystemIntegrationKind,
} from "../get-economy-system-integration-kind";

export type ExtendedMachineSettingsPart = Pick<
  ReadonlyConfig,
  | "canCreateMachines"
  | "canEditMachines"
  | "economicEnableMachinesDepartmentSync"
  | "economicEnableMachinesProjectEmployeesSync"
  | "unicontaMachineDimension"
  | "unicontaVariantDME"
> &
  EconomySystemIntegrationKindPart;

export type EditFieldMachine = Pick<Machine, "barred" | "canPull" | "remoteUrl" | "selfPropelled">;

export interface ExtendedMachineSettings {
  canAccessDetailsPage: boolean;
  canCreate: boolean;
  canEditField: (machine: EditFieldMachine, field: keyof Machine) => boolean;
  canImport: boolean;
  canOpenEditDialog: boolean;
}

const externalDataMembers: ReadonlySet<keyof Machine> = new Set(["name", "active", "c5_machine"]);

export function extendedMachineSettings(
  config: ExtendedMachineSettingsPart,
): ExtendedMachineSettings {
  const {
    c5Sync,
    canCreateMachines,
    canEditMachines,
    economicEnableMachinesDepartmentSync,
    economicEnableMachinesProjectEmployeesSync,
    economicSync,
    navSync,
    navSyncProfile,
    unicontaMachineDimension,
    unicontaSync,
    unicontaVariantDME,
  } = config;
  const economySystemIntegrationKind = getEconomySystemIntegrationKind(config);

  const integrationSupportsCreateEdit =
    economySystemIntegrationKind === ActiveEconomySystemIntegrationKind.NONE ||
    economySystemIntegrationKind === ActiveEconomySystemIntegrationKind.LIVE_SYNC;

  const integrationSupportsImport =
    economySystemIntegrationKind === ActiveEconomySystemIntegrationKind.IMPORT_ONLY ||
    economySystemIntegrationKind === ActiveEconomySystemIntegrationKind.LIVE_SYNC;

  const hasMachineSyncEnabled =
    (economicSync &&
      (economicEnableMachinesDepartmentSync || economicEnableMachinesProjectEmployeesSync)) ||
    (unicontaSync && unicontaMachineDimension !== null);

  const systemSpecificExternalMembers: Set<keyof Machine> = new Set();

  if (c5Sync && navSyncProfile === "bejstrupm") {
    systemSpecificExternalMembers.add("canPull");
    systemSpecificExternalMembers.add("selfPropelled");
  } else if (navSync && navSyncProfile !== "dme" && navSyncProfile !== "vredodanmark") {
    systemSpecificExternalMembers.add("canPull");
    systemSpecificExternalMembers.add("selfPropelled");
  } else if (unicontaSync) {
    systemSpecificExternalMembers.add("canPull");
  }

  return {
    canAccessDetailsPage: canEditMachines,
    canCreate: canCreateMachines && integrationSupportsCreateEdit,
    canEditField: (machine: EditFieldMachine, field: keyof Machine) => {
      let passAdditionalFieldRequirements = true;

      if (field === "selfPropelled") {
        // see https://github.com/customoffice-dk/customoffice/issues/1150
        passAdditionalFieldRequirements = !(unicontaVariantDME && machine.canPull);
      }

      if (field === "hourlyTowingCostEffective" || field === "hourlyTowingCostTransport") {
        passAdditionalFieldRequirements = !machine.canPull && !machine.selfPropelled;
      }

      if (!machine.remoteUrl) {
        return passAdditionalFieldRequirements;
      } else {
        const isInternalField =
          !externalDataMembers.has(field) && !systemSpecificExternalMembers.has(field);
        return (
          !machine.barred &&
          (isInternalField || integrationSupportsCreateEdit) &&
          passAdditionalFieldRequirements
        );
      }
    },
    canImport: canEditMachines && hasMachineSyncEnabled && integrationSupportsImport,
    canOpenEditDialog: canEditMachines,
  };
}
