import {Task} from "@co-common-libs/resources";
import {createSelector} from "reselect";
import {getTaskArray} from "../resources/selectors";
import {AppState} from "../root-reducer";

function buildCompletedOrderMap(taskArray: readonly Task[]): Map<string, boolean> {
  const result = new Map<string, boolean>();
  taskArray.forEach((task) => {
    const orderURL = task.order;
    if (orderURL) {
      const currentValue = result.get(orderURL);
      if (currentValue !== false && currentValue !== !!task.completed) {
        result.set(orderURL, !!task.completed);
      }
    }
  });
  return result;
}

export const getCompletedOrderMap: (state: AppState) => ReadonlyMap<string, boolean> =
  createSelector(getTaskArray, buildCompletedOrderMap);
