// Django/PostgreSQL includes microseconds in timestamps...
export const TIMESTAMP_WITH_MICROSECONDS_LENGTH = "2018-07-23T08:33:50.780000Z".length;
export const TIMESTAMP_WITH_MILLISECONDS_LENGTH = "2018-07-23T08:33:50.780Z".length;
export const TIMESTAMP_WITHOUT_MILLISECONDS_LENGTH = "2018-07-23T08:33:50Z".length;
export const NORMALISED_TIMESTAMP_STRING_LENGTH = TIMESTAMP_WITH_MILLISECONDS_LENGTH;

export const normalisedTimestamp = (timestamp: string): string => {
  if (timestamp.length === NORMALISED_TIMESTAMP_STRING_LENGTH) {
    return timestamp;
  } else {
    const {length} = timestamp;
    console.assert(
      length === TIMESTAMP_WITH_MICROSECONDS_LENGTH ||
        length === TIMESTAMP_WITHOUT_MILLISECONDS_LENGTH,
    );
    console.assert(timestamp[length - 1] === "Z");
    if (length === TIMESTAMP_WITH_MICROSECONDS_LENGTH) {
      const MICROSECONDS_DIGITS = 3;
      return `${timestamp.substring(0, length - (MICROSECONDS_DIGITS + 1))}Z`;
    } else {
      return `${timestamp.substring(0, length - 1)}.000Z`;
    }
  }
};
