import {ProductGroup, ProductGroupUrl} from "@co-common-libs/resources";
import {SingleProductGroupDialog} from "@co-frontend-libs/components";
import {AppState, getProductGroupArray} from "@co-frontend-libs/redux";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";

interface SingleProductGroupDialogStateProps {
  productGroupArray: readonly ProductGroup[];
}

interface SingleProductGroupDialogOwnProps {
  onCancel(): void;
  onOk(url: ProductGroupUrl): void;
  open: boolean;
}

export const ConnectedSingleProductGroupDialog: React.ComponentType<SingleProductGroupDialogOwnProps> =
  connect<SingleProductGroupDialogStateProps, object, SingleProductGroupDialogOwnProps, AppState>(
    createStructuredSelector<AppState, SingleProductGroupDialogStateProps>({
      productGroupArray: getProductGroupArray,
    }),
    {},
  )(SingleProductGroupDialog);
