import {Config} from "@co-common-libs/config";
import {
  UserData,
  computeWorkdaysSimplifiedWithMeta,
  fourWeekPoolPeriodEndFunction,
  getCountBonusLabelFormatMap,
  getFourWeekPoolPeriodStartFunction,
  getTwoWeekPoolPeriodStartFunction,
  twoWeekPoolPeriodEndFunction,
} from "@co-common-libs/payroll";
import {
  AccomodationAllowance,
  DaysAbsence,
  HoursAbsence,
  Machine,
  MachineUrl,
  Order,
  PriceGroup,
  PriceGroupUrl,
  PriceItem,
  Project,
  PunchInOut,
  Role,
  Task,
  Timer,
  User,
  UserProfile,
  UserUrl,
  WorkType,
  WorkTypeUrl,
  urlToId,
} from "@co-common-libs/resources";
import {
  FOUR_WEEK_DAYS,
  TWO_WEEK_DAYS,
  WEEK_DAYS,
  dateFromString,
  dateToString,
  formatDateNumeric,
  formatDateShort,
  formatMonthDateShort,
  getEndOfDate,
  getStartOfDate,
  makeObjectFromMap,
  monthDates,
  weekDates,
  weekNumber,
  weekStart,
} from "@co-common-libs/utils";
import {Check, Query, makeQuery} from "@co-frontend-libs/db-resources";
import {
  AppState,
  QueryQueryStateStruct,
  actions,
  getAccomodationAllowanceArray,
  getCurrentRole,
  getCurrentUser,
  getCurrentUserProfile,
  getCurrentUserURL,
  getCustomerSettings,
  getDaysAbsenceArray,
  getHoursAbsenceArray,
  getMachineArray,
  getOrderArray,
  getPathName,
  getPriceGroupArray,
  getPriceItemArray,
  getProjectArray,
  getPunchInOutArrayForCurrentUser,
  getSyncedState,
  getTaskArray,
  getTimerArray,
  getUserUserProfileLookup,
  getWorkTypeArray,
  makeQueryParameterGetter,
} from "@co-frontend-libs/redux";
import {PartialNavigationKind} from "@co-frontend-libs/routing-sync-history";
import {CircularProgress, IconButton} from "@material-ui/core";
import {MenuToolbar, PageLayout, UserSalaryReportCard} from "app-components";
import {
  PureComponent,
  getEmployeeRemunerationGroup,
  getEmployeeRemunerationSettings,
} from "app-utils";
import {bind} from "bind-decorator";
import bowser from "bowser";
import {endOfDay, startOfDay} from "date-fns";
import _ from "lodash";
import ArrowLeftBoldCircleOutlineIcon from "mdi-react/ArrowLeftBoldCircleOutlineIcon";
import ArrowRightBoldCircleOutlineIcon from "mdi-react/ArrowRightBoldCircleOutlineIcon";
import React from "react";
import {IntlContext, defineMessages} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";

const messages = defineMessages({
  monthTitle: {
    defaultMessage: "Arbejdstid: {month}",
    id: "computed-salary-voucher.title.month-working-time",
  },
  onlineArchiveError: {
    defaultMessage: "Fejl ved adgang til arkiv",
    id: "computed-salary-voucher.label.archive-error",
  },
  onlineArchiveWaiting: {
    defaultMessage: "Henter fra arkiv",
    id: "computed-salary-voucher.label.archive-waiting",
  },
  shiftedMonthTitle: {
    defaultMessage: "Arbejdstid: {fromDate} – {toDate}",
    id: "computed-salary-voucher.title.shifted-month-working-time",
  },
  weeksTitle: {
    defaultMessage: "Arbejdstid: Uge {fromWeek}-{toWeek} {year}",
    id: "computed-salary-voucher.title.weeks-working-time",
  },
  weekTitle: {
    defaultMessage: "Arbejdstid: Uge {week} {year}",
    id: "computed-salary-voucher.title.week-working-time",
  },
});

const getPeriodType = (
  customerSettings: Config,
  userProfile?: UserProfile | null,
): "FOUR_WEEKS" | "MONTH" | "TWO_WEEKS" | "WEEK" => {
  const groupID = getEmployeeRemunerationGroup(customerSettings, userProfile || undefined);
  const remunerationPoolType = _.get(customerSettings.remunerationGroups, [
    groupID,
    "pools",
    "type",
  ]);
  if (remunerationPoolType === "week" || remunerationPoolType === "normalisedWeek") {
    return "WEEK";
  } else if (remunerationPoolType === "twoWeeks") {
    return "TWO_WEEKS";
  } else if (remunerationPoolType === "fourWeeks") {
    return "FOUR_WEEKS";
  } else {
    return "MONTH";
  }
};

const getPeriodStartDate = (customerSettings: Config, userProfile?: UserProfile | null): string => {
  const groupID = getEmployeeRemunerationGroup(customerSettings, userProfile || undefined);
  return _.get(customerSettings.remunerationGroups, [groupID, "pools", "startDate"]);
};

const MONTH_STRING_LENGTH = "YYYY-MM".length;

const getMonthString = (date: Date): string => dateToString(date).substring(0, MONTH_STRING_LENGTH);

const getWeekString = (date: Date): string => {
  const {week, year} = weekNumber(date);
  return `${year}-W${week}`;
};

const parseWeekString = (
  weekString: string,
): {
  week: number;
  year: number;
} => {
  const [yearString, weekNumberString] = weekString.split("-W");
  const year = parseInt(yearString);
  const week = parseInt(weekNumberString);
  return {week, year};
};

interface ComputedSalaryVoucherStateProps {
  accomodationAllowanceArray: readonly AccomodationAllowance[];
  currentRole: Role | null;
  currentUser: User | null;
  currentUserProfile: UserProfile | null;
  currentUserURL: UserUrl | null;
  customerSettings: Config;
  daysAbsenceArray: readonly DaysAbsence[];
  hoursAbsenceArray: readonly HoursAbsence[];
  machineArray: readonly Machine[];
  month: string;
  orderArray: readonly Order[];
  pathName: string;
  priceGroupArray: readonly PriceGroup[];
  priceItemArray: readonly PriceItem[];
  projectArray: readonly Project[];
  punchedInOutArray: readonly PunchInOut[];
  taskArray: readonly Task[];
  taskSyncedState: ReadonlyMap<string, QueryQueryStateStruct>;
  timerArray: readonly Timer[];
  userUserProfileLookup: (url: UserUrl) => UserProfile | undefined;
  week: string;
  workTypeArray: readonly WorkType[];
}

interface ComputedSalaryVoucherDispatchProps {
  putQueryKey: (key: string, value: string, navigationKind?: PartialNavigationKind) => void;
  temporaryQueriesRequestedForPath: (
    queries: readonly Query[],
    pathName: string,
    key: string,
  ) => void;
}

interface ComputedSalaryVoucherOwnProps {
  onMenuButton: (event: React.MouseEvent) => void;
}

type ComputedSalaryVoucherProps = ComputedSalaryVoucherDispatchProps &
  ComputedSalaryVoucherOwnProps &
  ComputedSalaryVoucherStateProps;

interface ComputedSalaryVoucherState {
  query: Query | null;
}

const TEMPORARY_QUERIES_KEY = "ComputedSalaryVoucher";

class ComputedSalaryVoucher extends PureComponent<
  ComputedSalaryVoucherProps,
  ComputedSalaryVoucherState
> {
  state: ComputedSalaryVoucherState = {
    query: null,
  };
  componentDidMount(): void {
    const {currentUserProfile, customerSettings, pathName} = this.props;
    const periodType = getPeriodType(customerSettings, currentUserProfile);
    if (periodType === "WEEK" || periodType === "TWO_WEEKS" || periodType === "FOUR_WEEKS") {
      const {putQueryKey, week} = this.props;
      if (!week) {
        const weekString = getWeekString(new Date());
        putQueryKey("week", weekString);
      } else {
        this.fetchFromArchive({week, weeks: periodType}, pathName);
      }
    } else {
      console.assert(periodType === "MONTH");
      const {month, putQueryKey} = this.props;
      if (!month) {
        const monthDate = new Date();
        const employeeDefaultRemunerationGroup = getEmployeeRemunerationGroup(
          customerSettings,
          currentUserProfile,
        );
        const startDay =
          customerSettings.remunerationGroups[employeeDefaultRemunerationGroup]
            ?.computedSalaryVoucherStartDay ||
          customerSettings.computedSalaryVoucherStartDay ||
          1;
        if (monthDate.getDate() < startDay) {
          monthDate.setMonth(monthDate.getMonth() - 1);
        }
        const monthString = getMonthString(monthDate);
        putQueryKey("month", monthString);
      } else {
        this.fetchFromArchive({month}, pathName);
      }
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps: ComputedSalaryVoucherProps): void {
    const {currentUserProfile, customerSettings} = this.props;
    const periodType = getPeriodType(customerSettings, currentUserProfile);
    if (periodType === "WEEK" || periodType === "TWO_WEEKS" || periodType === "FOUR_WEEKS") {
      if (nextProps.week !== this.props.week) {
        this.fetchFromArchive({week: nextProps.week, weeks: periodType}, nextProps.pathName);
      }
    } else {
      console.assert(periodType === "MONTH");
      if (nextProps.month !== this.props.month) {
        this.fetchFromArchive({month: nextProps.month}, nextProps.pathName);
      }
    }
  }
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  gotoWeek(increment: number): void {
    const periodType = getPeriodType(this.props.customerSettings, this.props.currentUserProfile);
    console.assert(periodType !== "MONTH");
    const {putQueryKey, week} = this.props;
    if (week) {
      const weekData = parseWeekString(week);
      let date: Date;
      let days: number;
      if (periodType === "WEEK") {
        date = weekStart(weekData.week, weekData.year);
        days = WEEK_DAYS;
      } else {
        const startDateString = getPeriodStartDate(
          this.props.customerSettings,
          this.props.currentUserProfile,
        );
        if (periodType === "TWO_WEEKS") {
          date = getTwoWeekPoolPeriodStartFunction(startDateString)(
            weekStart(weekData.week, weekData.year),
          );
          days = TWO_WEEK_DAYS;
        } else {
          date = getFourWeekPoolPeriodStartFunction(startDateString)(
            weekStart(weekData.week, weekData.year),
          );
          days = FOUR_WEEK_DAYS;
        }
      }
      date.setDate(date.getDate() + days * increment);
      const weekString = getWeekString(date);
      putQueryKey("week", weekString);
    }
  }
  gotoMonth(increment: number): void {
    console.assert(
      getPeriodType(this.props.customerSettings, this.props.currentUserProfile) === "MONTH",
    );
    const {month, putQueryKey} = this.props;
    if (month) {
      const fromDate = `${month}-01`;
      const date = dateFromString(fromDate) as Date;
      date.setMonth(date.getMonth() + increment);
      const monthString = getMonthString(date);
      putQueryKey("month", monthString);
    }
  }
  @bind
  handleGoToPrevious(): void {
    const {currentUserProfile, customerSettings} = this.props;
    const periodType = getPeriodType(customerSettings, currentUserProfile);
    if (periodType === "WEEK" || periodType === "TWO_WEEKS" || periodType === "FOUR_WEEKS") {
      this.gotoWeek(-1);
    } else {
      console.assert(periodType === "MONTH");
      this.gotoMonth(-1);
    }
  }
  @bind
  handleGoToNext(): void {
    const {currentUserProfile, customerSettings} = this.props;
    const periodType = getPeriodType(customerSettings, currentUserProfile);
    if (periodType === "WEEK" || periodType === "TWO_WEEKS" || periodType === "FOUR_WEEKS") {
      this.gotoWeek(+1);
    } else {
      console.assert(periodType === "MONTH");
      this.gotoMonth(+1);
    }
  }
  @bind
  monthDates(monthString: string): {fromDate: string; toDate: string} {
    const {currentUserProfile, customerSettings} = this.props;
    const employeeDefaultRemunerationGroup = getEmployeeRemunerationGroup(
      customerSettings,
      currentUserProfile,
    );
    const startDay =
      customerSettings.remunerationGroups[employeeDefaultRemunerationGroup]
        ?.computedSalaryVoucherStartDay ||
      customerSettings.computedSalaryVoucherStartDay ||
      1;
    if (startDay === 1) {
      return monthDates(monthString);
    }
    const numberWithoutPad = 10;
    const fromDayString = startDay < numberWithoutPad ? `0${startDay}` : startDay;
    const fromDate = `${monthString}-${fromDayString}`;
    const date = dateFromString(fromDate) as Date;
    date.setMonth(date.getMonth() + 1);
    date.setDate(date.getDate() - 1);
    const toDate = dateToString(date);
    return {fromDate, toDate};
  }
  fetchFromArchive(
    {
      month,
      week: weekString,
      weeks = "WEEK",
    }: {
      month?: string;
      week?: string;
      weeks?: "FOUR_WEEKS" | "TWO_WEEKS" | "WEEK";
    },
    pathName: string,
  ): void {
    const user = this.props.currentUser;
    const userProfile = this.props.currentUserProfile;
    if (!user || !userProfile) {
      return;
    }
    const {alias} = userProfile;
    let fromDate: string | undefined;
    let toDate: string | undefined;
    if (weekString) {
      const {week, year} = parseWeekString(weekString);
      if (weeks === "WEEK") {
        ({fromDate, toDate} = weekDates(week, year));
      } else if (weeks === "TWO_WEEKS") {
        const fromDateDate = weekStart(week, year);
        const toDateDate = new Date(fromDateDate);
        toDateDate.setDate(toDateDate.getDate() + TWO_WEEK_DAYS - 1);
        fromDate = dateToString(fromDateDate);
        toDate = dateToString(toDateDate);
      } else {
        console.assert(weeks === "FOUR_WEEKS");
        const fromDateDate = weekStart(week, year);
        const toDateDate = new Date(fromDateDate);
        toDateDate.setDate(toDateDate.getDate() + FOUR_WEEK_DAYS - 1);
        fromDate = dateToString(fromDateDate);
        toDate = dateToString(toDateDate);
      }
    } else if (month) {
      ({fromDate, toDate} = this.monthDates(month));
    }
    if (fromDate && toDate) {
      const beforeFromDate = dateFromString(fromDate) as Date;
      beforeFromDate.setDate(beforeFromDate.getDate() - 1);
      const afterToDate = dateFromString(toDate) as Date;
      afterToDate.setDate(afterToDate.getDate() + 1);

      const rangeStartString = startOfDay(beforeFromDate).toISOString();
      const rangeEndString = endOfDay(afterToDate).toISOString();

      const taskCheck: Check = {
        checks: [
          {
            memberName: "workToTimestamp",
            type: "memberGt",
            value: rangeStartString,
          },
          {
            memberName: "workFromTimestamp",
            type: "memberLt",
            value: rangeEndString,
          },
          {memberName: "completed", type: "memberTruthy"},
          {memberName: "machineOperator", type: "memberEq", value: user.url},
        ],
        type: "and",
      };

      const query = makeQuery({
        check: taskCheck,
        filter: {
          fromDate: dateToString(beforeFromDate),
          initials: alias,
          toDate: dateToString(afterToDate),
        },
        independentFetch: true,
        resourceName: "task",
      });
      this.props.temporaryQueriesRequestedForPath([query], pathName, TEMPORARY_QUERIES_KEY);
      this.setState({query});
    }
  }
  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {
      accomodationAllowanceArray,
      daysAbsenceArray,
      hoursAbsenceArray,
      machineArray,
      orderArray,
      priceGroupArray,
      priceItemArray,
      projectArray,
      taskArray,
      taskSyncedState,
      timerArray,
      workTypeArray,
    } = this.props;
    const userURL = this.props.currentUserURL;
    let message;
    let showSpinner = false;
    {
      const {query} = this.state;
      if (query) {
        const querySyncedState = taskSyncedState.get(query.keyString);
        if (!querySyncedState || querySyncedState.queryState.currentlyFullFetching) {
          message = formatMessage(messages.onlineArchiveWaiting);
          showSpinner = true;
        } else {
          const lastTimestamp = querySyncedState.queryState.fullFetchDataComputedAtTimestamp;
          const {lastErrorTimestamp} = querySyncedState.queryState;
          if (lastErrorTimestamp && lastTimestamp && lastErrorTimestamp > lastTimestamp) {
            message = formatMessage(messages.onlineArchiveError);
          }
        }
      }
    }
    const {currentUserProfile, customerSettings} = this.props;
    const periodType = getPeriodType(customerSettings, currentUserProfile);
    let fromDate: string, title: string, toDate: string;
    if (periodType === "WEEK") {
      const weekString = this.props.week || getWeekString(new Date());
      const {week, year} = parseWeekString(weekString);
      ({fromDate, toDate} = weekDates(week, year));
      if (bowser.mobile) {
        title = `${week} ${year}`;
      } else {
        title = formatMessage(messages.weekTitle, {week, year});
      }
    } else if (periodType === "TWO_WEEKS" || periodType === "FOUR_WEEKS") {
      const weekString = this.props.week || getWeekString(new Date());
      const {week, year} = parseWeekString(weekString);
      let periodFromDate: Date;
      let periodToDate: Date;
      const startDateString = getPeriodStartDate(
        this.props.customerSettings,
        this.props.currentUserProfile,
      );
      if (periodType === "TWO_WEEKS") {
        periodFromDate = getTwoWeekPoolPeriodStartFunction(startDateString)(weekStart(week, year));
        periodToDate = twoWeekPoolPeriodEndFunction(periodFromDate);
        periodToDate.setDate(periodToDate.getDate() - 1);
      } else {
        periodFromDate = getFourWeekPoolPeriodStartFunction(startDateString)(weekStart(week, year));
        periodToDate = fourWeekPoolPeriodEndFunction(periodFromDate);
        periodToDate.setDate(periodToDate.getDate() - 1);
      }
      fromDate = dateToString(periodFromDate);
      toDate = dateToString(periodToDate);
      const fromWeek = weekNumber(periodFromDate).week;
      const toWeek = weekNumber(periodToDate).week;
      if (bowser.mobile) {
        title = `${fromWeek}-${toWeek} ${year}`;
      } else {
        title = formatMessage(messages.weeksTitle, {fromWeek, toWeek, year});
      }
    } else {
      console.assert(periodType === "MONTH");
      const monthString = this.props.month || getMonthString(new Date());
      ({fromDate, toDate} = this.monthDates(monthString));
      const employeeDefaultRemunerationGroup = getEmployeeRemunerationGroup(
        customerSettings,
        currentUserProfile,
      );
      const startDay =
        customerSettings.remunerationGroups[employeeDefaultRemunerationGroup]
          ?.computedSalaryVoucherStartDay ||
        customerSettings.computedSalaryVoucherStartDay ||
        1;
      if (startDay > 1) {
        if (bowser.mobile) {
          title = `${formatDateShort(fromDate)} – ${formatDateShort(toDate)}`;
        } else {
          title = formatMessage(messages.shiftedMonthTitle, {
            fromDate: formatDateNumeric(fromDate),
            toDate: formatDateNumeric(toDate),
          });
        }
      } else {
        const formattedMonth = formatMonthDateShort(monthString);
        if (bowser.mobile) {
          title = formattedMonth;
        } else {
          title = formatMessage(messages.monthTitle, {month: formattedMonth});
        }
      }
    }
    const beforeFromDate = dateFromString(fromDate) as Date;
    beforeFromDate.setDate(beforeFromDate.getDate() - 1);
    const afterToDate = dateFromString(toDate) as Date;
    afterToDate.setDate(afterToDate.getDate() + 1);

    const beforeFromDateStart = getStartOfDate(dateToString(beforeFromDate));
    const afterToDateEnd = getEndOfDate(dateToString(afterToDate));

    const content: JSX.Element[] = [];
    if (message) {
      let spinner;
      if (showSpinner) {
        spinner = <CircularProgress />;
      }
      content.push(
        <div key="spinner" style={{padding: 8, textAlign: "center"}}>
          <div>{message}</div>
          {spinner}
        </div>,
      );
    } else if (userURL) {
      const userTaskList = taskArray.filter((task) => {
        if (task.machineOperator !== userURL) {
          return false;
        }
        if (!task.completed) {
          return false;
        }
        const {workFromTimestamp} = task;
        const {workToTimestamp} = task;
        if (!workFromTimestamp || !workToTimestamp) {
          return true;
        }
        return workFromTimestamp <= afterToDateEnd && beforeFromDateStart <= workToTimestamp;
      });
      let userDaysAbsenceList: DaysAbsence[] = [];
      let userHoursAbsenceList: HoursAbsence[] = [];
      if (this.props.customerSettings.registerAbsence) {
        userDaysAbsenceList = daysAbsenceArray.filter(
          (d) => d.fromDate <= toDate && d.toDate >= fromDate && d.user === userURL,
        );
        userHoursAbsenceList = hoursAbsenceArray.filter(
          (h) =>
            h.fromTimestamp <= afterToDateEnd &&
            h.toTimestamp >= beforeFromDateStart &&
            h.user === userURL,
        );
      }
      const tasks = userTaskList;
      const workTypeIDURLMapping: {
        [workTypeID: string]: WorkTypeUrl;
      } = {};
      workTypeArray.forEach((workType) => {
        workTypeIDURLMapping[workType.identifier] = workType.url;
      });
      const machineIDURLMapping: {[machineID: string]: MachineUrl} = {};
      machineArray.forEach((machine) => {
        machineIDURLMapping[machine.c5_machine] = machine.url;
      });
      const priceGroupIDURLMapping: {[priceGroupID: string]: PriceGroupUrl} = {};
      priceGroupArray.forEach((priceGroup) => {
        priceGroupIDURLMapping[priceGroup.identifier] = priceGroup.url;
      });
      const profile = userURL ? this.props.userUserProfileLookup(userURL) : undefined;
      const filteredAccomodationAllowanceArray = accomodationAllowanceArray.filter(
        (accomodationAllowance) =>
          accomodationAllowance.date <= toDate &&
          accomodationAllowance.date >= fromDate &&
          accomodationAllowance.employee === userURL,
      );

      const employeeDefaultRemunerationGroup = getEmployeeRemunerationGroup(
        customerSettings,
        profile,
      );

      const orderMap = new Map(orderArray.map((order) => [order.url, order]));
      const projectMap = new Map(projectArray.map((project) => [project.url, project]));
      const priceItemMap = new Map(priceItemArray.map((priceItem) => [priceItem.url, priceItem]));
      const timerMap = new Map(timerArray.map((timer) => [timer.url, timer]));

      const groupedData = computeWorkdaysSimplifiedWithMeta(
        tasks,
        customerSettings.usePunchInOut ? this.props.punchedInOutArray : null,
        timerMap,
        orderMap,
        workTypeIDURLMapping,
        machineIDURLMapping,
        priceGroupIDURLMapping,
        customerSettings,
        employeeDefaultRemunerationGroup,
        userDaysAbsenceList,
        userHoursAbsenceList,
        [], // dinnerBookingList
        [], // lunchBookingList
        profile && profile.normalTransportMinutes != null
          ? profile.normalTransportMinutes
          : undefined,
        filteredAccomodationAllowanceArray,
        projectMap,
        priceItemMap,
        profile && profile.normalTransportKilometers != null
          ? profile.normalTransportKilometers
          : undefined,
        fromDate,
        toDate,
      );

      groupedData.forEach((baseDates, groupID) => {
        const settings = getEmployeeRemunerationSettings(
          customerSettings,
          workTypeArray,
          machineArray,
          priceGroupArray,
          profile,
          groupID,
        );
        const dates = baseDates
          .filter((entry) => entry.date >= fromDate && entry.date <= toDate)
          .map((entry) => {
            return {
              ...entry,
              bonusMinutes: makeObjectFromMap(entry.bonusMinutes),
              calendarDayBonus: makeObjectFromMap(entry.calendarDayBonus),
              countBonus: makeObjectFromMap(entry.countBonus),
              daysAbsence: makeObjectFromMap(entry.daysAbsence),
              minutesAbsence: makeObjectFromMap(entry.minutesAbsence),
              rateMinutes: Object.fromEntries(Array.from(entry.rateMinutes.entries())) as {
                [key: string]: number;
              },
              taskBonus: makeObjectFromMap(entry.taskBonus),
            };
          });
        const groupOptions = customerSettings.remunerationGroups[groupID];
        if (!groupOptions) {
          return;
        }
        const {reportTitle} = groupOptions;

        const userData: UserData = {
          accomodationAllowance: groupOptions.accomodationAllowance ?? false,
          accomodationAllowanceIncludeHours: groupOptions.accomodationAllowanceIncludeHours ?? true,
          accomodationAllowanceLabel: groupOptions.accomodationAllowanceLabel || undefined,
          dates,
          employeeDefaultRemunerationGroup,
          employeeNumber: profile ? profile.employeeNumber : "",
          hasPoolPeriodMismatchIssue: false,
          initials: (profile && profile.alias) || "",
          name: profile ? profile.name : "",
          projectDistanceBonusLabel: groupOptions.projectDistanceBonusLabel ?? undefined,
          projectTravelTimeBonusLabel: groupOptions.projectTravelTimeBonusLabel ?? undefined,
          remunerationGroup: groupID,
          reportTitle,
          userId: urlToId(userURL),
        };

        const countBonusLabelFormatMap = getCountBonusLabelFormatMap(groupOptions);

        content.push(
          <UserSalaryReportCard
            key={groupID}
            calendarDayBonusLabels={customerSettings.remunerationCalendarDayBonusLabels}
            countBonusLabelFormatMap={countBonusLabelFormatMap}
            countBonusLabels={customerSettings.remunerationCountBonusLabels}
            currentRole={this.props.currentRole || undefined}
            customerSettings={this.props.customerSettings}
            intervalBonusLabels={customerSettings.remunerationIntervalBonusLabels}
            rateLabels={customerSettings.remunerationRateLabels || []}
            showBreaks={!groupOptions.paidBreaks || !!groupOptions.forcedUnpaidBreakMinutes}
            startRateLabel={customerSettings.remunerationStartRateLabel || undefined}
            taskBonusLabels={customerSettings.remunerationTaskBonusLabels}
            userData={userData}
            visibleBonusLabels={settings.visibleBonusLabels || undefined}
            visibleLabels={settings.visibleLabels || undefined}
            visibleRateLabels={settings.visibleRateLabels || undefined}
            workDayBonusLabels={customerSettings.remunerationWorkDayBonusLabels}
          />,
        );
      });
    }

    const previousButton = (
      <IconButton onClick={this.handleGoToPrevious}>
        <ArrowLeftBoldCircleOutlineIcon color="#fff" />
      </IconButton>
    );
    const nextButton = (
      <IconButton onClick={this.handleGoToNext}>
        <ArrowRightBoldCircleOutlineIcon color="#fff" />
      </IconButton>
    );

    const right = (
      <>
        {previousButton}
        {nextButton}
      </>
    );
    return (
      <PageLayout
        withPadding
        toolbar={
          <MenuToolbar rightElement={right} title={title} onMenuButton={this.props.onMenuButton} />
        }
      >
        {content}
      </PageLayout>
    );
  }
}

const ConnectedComputedSalaryVoucher: React.ComponentType<ComputedSalaryVoucherOwnProps> = connect<
  ComputedSalaryVoucherStateProps,
  ComputedSalaryVoucherDispatchProps,
  ComputedSalaryVoucherOwnProps,
  AppState
>(
  createStructuredSelector<AppState, ComputedSalaryVoucherStateProps>({
    accomodationAllowanceArray: getAccomodationAllowanceArray,
    currentRole: getCurrentRole,
    currentUser: getCurrentUser,
    currentUserProfile: getCurrentUserProfile,
    currentUserURL: getCurrentUserURL,
    customerSettings: getCustomerSettings,
    daysAbsenceArray: getDaysAbsenceArray,
    hoursAbsenceArray: getHoursAbsenceArray,
    machineArray: getMachineArray,
    month: makeQueryParameterGetter("month"),
    orderArray: getOrderArray,
    pathName: getPathName,
    priceGroupArray: getPriceGroupArray,
    priceItemArray: getPriceItemArray,
    projectArray: getProjectArray,
    punchedInOutArray: getPunchInOutArrayForCurrentUser,
    taskArray: getTaskArray,
    taskSyncedState: getSyncedState.bind(null, "task"),
    timerArray: getTimerArray,
    userUserProfileLookup: getUserUserProfileLookup,
    week: makeQueryParameterGetter("week"),
    workTypeArray: getWorkTypeArray,
  }),
  {
    putQueryKey: actions.putQueryKey,
    temporaryQueriesRequestedForPath: actions.temporaryQueriesRequestedForPath,
  },
)(ComputedSalaryVoucher);

export default ConnectedComputedSalaryVoucher;
