import {Config} from "@co-common-libs/config";
import {
  CsvImportColumnSpecification,
  CsvImportSpecification,
  getAvailableColumns,
} from "@co-common-libs/csv-import-specifications";
import _ from "lodash";
import {IntlShape} from "react-intl";
import {ValidationData} from "../parse-validate";
import {ImportColumnConfiguration} from "../types";
import {getColumnConfiguration} from "./get-column-configuration";
import {validateValues} from "./validate-single-import-column";

function validateKeyColumn(
  importColumnConfigurations: ReadonlyMap<string, ImportColumnConfiguration>,
  rows: readonly (readonly string[])[],
  column: CsvImportColumnSpecification,
  intl: IntlShape,
  data: ValidationData,
): string[] {
  const importColumnConfiguration = getColumnConfiguration(importColumnConfigurations, column.name);

  if (importColumnConfiguration.valueType !== "sourceColumn") {
    return [
      intl.formatMessage({
        defaultMessage: "Der skal angives en kolonne til indlæsning her",
      }),
    ];
  }

  const errors = validateValues(column, rows, importColumnConfigurations, intl, data);

  const valueCounts = new Map<string, number>();
  rows.forEach((row) => {
    const value = row[importColumnConfiguration.columnIndex];
    if (value) {
      valueCounts.set(value, (valueCounts.get(value) || 0) + 1);
    }
  });
  if (valueCounts.size < rows.length) {
    const recurringValues = Array.from(valueCounts.entries())
      .filter(([_value, count]) => count > 1)
      .map(([value, _count]) => value);
    if (recurringValues.length) {
      errors.push(
        intl.formatMessage(
          {
            defaultMessage:
              "Der er {length, number} {length, plural, one {dublet} other {dubletter}} i den valgte kolonne: {recurringValues}",
          },
          {
            length: recurringValues.length,
            recurringValues: recurringValues.join(", "),
          },
        ),
      );
    }
  }

  return errors;
}

function validateNonKeyColumn(
  importColumnConfigurations: ReadonlyMap<string, ImportColumnConfiguration>,
  rows: readonly (readonly string[])[],
  column: CsvImportColumnSpecification,
  createNotExisting: boolean,
  intl: IntlShape,
  data: ValidationData,
): string[] {
  const importColumnConfiguration = getColumnConfiguration(importColumnConfigurations, column.name);
  if (!createNotExisting && !importColumnConfiguration.updateExisting) {
    return [];
  }

  return validateValues(column, rows, importColumnConfigurations, intl, data);
}

export function validateImportColumns(
  customerSettings: Config,
  importColumnConfigurations: ReadonlyMap<string, ImportColumnConfiguration>,
  rows: readonly (readonly string[])[],
  selectedImportSpecification: CsvImportSpecification,
  createNotExisting: boolean,
  intl: IntlShape,
  data: ValidationData,
): Map<string, string[]> {
  const columnErrors = new Map<string, string[]>();

  const availableColumns = getAvailableColumns(customerSettings, selectedImportSpecification);

  const keyColumn = availableColumns[0];
  const otherColumns = availableColumns.slice(1);

  const keyColumnErrors = validateKeyColumn(
    importColumnConfigurations,
    rows,
    keyColumn,
    intl,
    data,
  );
  if (keyColumnErrors) {
    columnErrors.set(keyColumn.name, keyColumnErrors);
  }

  otherColumns.forEach((column) => {
    const otherColumnErrors = validateNonKeyColumn(
      importColumnConfigurations,
      rows,
      column,
      createNotExisting,
      intl,
      data,
    );
    if (otherColumnErrors) {
      columnErrors.set(column.name, otherColumnErrors);
    }
  });

  return columnErrors;
}
