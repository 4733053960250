import {DownloadButton} from "@co-frontend-libs/components";
import {AppState, getShareToken} from "@co-frontend-libs/redux";
import React from "react";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";

interface DownloadButtonStateProps {
  shareToken: string | null;
}

interface DownloadButtonOwnProps {
  downloadURL?: string | undefined;
  error?: string | undefined;
  Icon: React.ComponentType | React.ExoticComponent;
}

export const ConnectedDownloadButton: React.ComponentType<DownloadButtonOwnProps> = connect<
  DownloadButtonStateProps,
  object,
  DownloadButtonOwnProps,
  AppState
>(
  createStructuredSelector<AppState, DownloadButtonStateProps>({
    shareToken: getShareToken,
  }),
  {},
)(DownloadButton);
