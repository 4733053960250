import {makeContainsPredicate} from "@co-common-libs/utils";
import {TrimTextField} from "@co-frontend-libs/components";
import {Card, CardContent, CardHeader, SvgIcon} from "@material-ui/core";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import React from "react";
import {EntryWithSubEntries, SelectableList} from "./selectable-list";

interface SelectableListCardProps<EntryValue extends string> {
  data: readonly EntryWithSubEntries<EntryValue>[];
  description?: string;
  emptyPrimaryText?: string;
  emptySecondaryText?: string;
  noFilter?: boolean;
  onChange: (event: React.SyntheticEvent<unknown>, selected: EntryValue | null) => void;
  subtitle?: string;
  title?: string;
  topFab?: React.ReactNode;
  value?: string | undefined;
}

interface SelectableListCardState {
  filterString: string;
}

export class SelectableListCard<EntryValue extends string> extends PureComponent<
  SelectableListCardProps<EntryValue>,
  SelectableListCardState
> {
  static defaultProps = {
    noFilter: false,
    style: {height: "100%"},
  };
  state: SelectableListCardState = {
    filterString: "",
  };
  @bind
  handleFilterFieldChange(value: string): void {
    this.setState({filterString: value});
  }
  @bind
  handleFilterFieldKeyDown(event: React.KeyboardEvent<unknown>): void {
    if (event.key === "Enter") {
      const filteredData = this.getFilteredData();
      if (filteredData.length === 1) {
        const entry = filteredData[0];
        this.props.onChange(event, entry.value);
      }
    }
  }
  getFilteredData(): readonly EntryWithSubEntries<EntryValue>[] {
    const {data} = this.props;
    const {filterString} = this.state;
    if (filterString) {
      const checkString = makeContainsPredicate(filterString);
      return data.filter((x) => checkString(`${x.primaryText} ${x.secondaryText || ""}`));
    } else {
      return data;
    }
  }
  render(): JSX.Element {
    const {
      description,
      emptyPrimaryText,
      emptySecondaryText,
      noFilter,
      onChange,
      subtitle,
      title,
      topFab,
      value,
    } = this.props;
    const {filterString} = this.state;
    const filteredData = this.getFilteredData();
    let descriptionBlock;
    if (description) {
      descriptionBlock = <CardContent>{description}</CardContent>;
    }
    let fabBlock: JSX.Element | undefined;
    if (topFab) {
      fabBlock = <div style={{position: "relative"}}>{topFab}</div>;
    }
    let titleBlock;
    if (title || subtitle) {
      titleBlock = <CardHeader subheader={subtitle} title={title} />;
    }
    let filterBlock;
    if (!noFilter) {
      filterBlock = (
        <CardContent>
          <TrimTextField
            margin="dense"
            name={`filter-string-${title}`}
            style={{width: "calc(100% - 48px)"}}
            value={filterString}
            variant="outlined"
            onChange={this.handleFilterFieldChange}
            onKeyDown={this.handleFilterFieldKeyDown}
          />
          <SvgIcon style={{marginLeft: 12, marginTop: 12}}>
            <MagnifyIcon />
          </SvgIcon>
        </CardContent>
      );
    }
    return (
      <Card
        style={{
          display: "inline-flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        }}
      >
        {fabBlock}
        {titleBlock}
        {filterBlock}
        {descriptionBlock}
        <SelectableList<EntryValue>
          data={filteredData}
          emptyPrimaryText={emptyPrimaryText}
          emptySecondaryText={emptySecondaryText}
          style={{
            flex: 1,
            overflowY: "scroll",
          }}
          value={value}
          onChange={onChange}
        />
      </Card>
    );
  }
}
