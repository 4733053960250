import {
  CsvImportSpecification,
  getAvailableColumns,
} from "@co-common-libs/csv-import-specifications";
import {
  getCustomerArray,
  getCustomerSettings,
  getLocationTypeArray,
  getUnitArray,
} from "@co-frontend-libs/redux";
import {TableCell, TableRow} from "@material-ui/core";
import _ from "lodash";
import React, {useMemo} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {ValidationData} from "../parse-validate";
import {ImportColumnConfiguration} from "../types";
import {AlertMessage} from "./alert-message";
import {getColumnConfiguration} from "./get-column-configuration";
import {validateImportColumns} from "./validate-import-columns";

export function ValidationRow(props: {
  createNotExisting: boolean;
  importColumnConfigurations: ReadonlyMap<string, ImportColumnConfiguration>;
  rows: readonly (readonly string[])[];
  selectedImportSpecification: CsvImportSpecification;
}): JSX.Element {
  const {createNotExisting, importColumnConfigurations, rows, selectedImportSpecification} = props;

  const intl = useIntl();

  const customerSettings = useSelector(getCustomerSettings);
  const locationTypeArray = useSelector(getLocationTypeArray);
  const customerArray = useSelector(getCustomerArray);
  const unitArray = useSelector(getUnitArray);

  const validationData = useMemo(
    (): ValidationData => ({
      config: customerSettings,
      customers: customerArray,
      locationTypes: locationTypeArray,
      units: unitArray,
    }),
    [customerArray, customerSettings, locationTypeArray, unitArray],
  );

  const validationErrors = validateImportColumns(
    customerSettings,
    importColumnConfigurations,
    rows,
    selectedImportSpecification,
    createNotExisting,
    intl,
    validationData,
  );

  const columnIndexUsageCount = new Map<number, number>();

  importColumnConfigurations.forEach((importColumnConfiguration) => {
    if (importColumnConfiguration.valueType === "sourceColumn") {
      columnIndexUsageCount.set(
        importColumnConfiguration.columnIndex,
        (columnIndexUsageCount.get(importColumnConfiguration.columnIndex) || 0) + 1,
      );
    }
  });

  const tableCellColumns: JSX.Element[] = [];

  getAvailableColumns(customerSettings, selectedImportSpecification).forEach((column) => {
    const validationErrorsForColumn = validationErrors.get(column.name);

    const messages: JSX.Element[] = [];

    if (validationErrorsForColumn && validationErrorsForColumn.length) {
      validationErrorsForColumn.forEach((validationErrorForColumn, cardIndex) => {
        messages.push(
          <AlertMessage key={`error-${cardIndex}`} text={validationErrorForColumn} type="error" />,
        );
      });
    } else {
      const importColumnConfiguration = getColumnConfiguration(
        importColumnConfigurations,
        column.name,
      );
      if (
        ((importColumnConfiguration.valueType === "sourceColumn" &&
          columnIndexUsageCount.get(importColumnConfiguration.columnIndex)) ||
          0) > 1
      ) {
        messages.push(
          <AlertMessage
            key="reuse-warning"
            text={intl.formatMessage({
              defaultMessage: "Den valgte importkolonne bruges mere end ét sted.",
            })}
            type="warning"
          />,
        );
      }
    }

    tableCellColumns.push(
      <TableCell key={column.name} style={{verticalAlign: "top"}}>
        {messages}
      </TableCell>,
    );
  });

  return (
    <TableRow>
      <TableCell />
      {tableCellColumns}
      <TableCell />
    </TableRow>
  );
}
