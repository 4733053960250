import {
  CustomerUrl,
  PriceGroup,
  PriceGroupUrl,
  Task,
  WorkType,
  WorkTypeUrl,
  emptyTask,
} from "@co-common-libs/resources";
import {notUndefined} from "@co-common-libs/utils";
import {
  ConnectedExternalWorkTypeDialog,
  ConnectedLogLegalExternalWorkTypeDialog,
  ConnectedLogLegalPriceGroupDialog,
} from "@co-frontend-libs/connected-components";
import {getPriceGroupLookup, getWorkTypeLookup} from "@co-frontend-libs/redux";
import React, {useCallback} from "react";
import {useSelector} from "react-redux";
import {WorkTypeSelectionEvent, WorkTypeSelectionState} from "./work-type-selection-state-machine";

function getPotentialPriceGroups(
  workTypeURL: WorkTypeUrl,
  customerURL: CustomerUrl | null,
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined,
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined,
): PriceGroupUrl[] {
  const workType = workTypeLookup(workTypeURL);
  const priceGroupURLs = workType?.pricegroups;
  if (!priceGroupURLs || !priceGroupURLs.length) {
    return [];
  }
  const potentialPriceGroups = priceGroupURLs
    .map(priceGroupLookup)
    .filter(notUndefined)
    .filter((priceGroup) => priceGroup.active)
    .filter((priceGroup) => !priceGroup.onlyForExtraTimers);
  if (!potentialPriceGroups) {
    return [];
  }
  if (customerURL) {
    const customerSpecificPriceGroups = potentialPriceGroups.filter(
      (priceGroup) => priceGroup.customers && priceGroup.customers.includes(customerURL),
    );
    if (customerSpecificPriceGroups.length) {
      return customerSpecificPriceGroups.map((priceGroup) => priceGroup.url);
    }
  }
  const standardPriceGroups = potentialPriceGroups.filter(
    (priceGroup) => priceGroup.standardListing,
  );
  return standardPriceGroups.map((priceGroup) => priceGroup.url);
}

interface WorkTypeSelectionDisplayProps {
  send: (event: WorkTypeSelectionEvent) => void;
  state: WorkTypeSelectionState;
  task?: Task | undefined;
}

export function WorkTypeSelectionDisplay(props: WorkTypeSelectionDisplayProps): JSX.Element {
  const {send, state, task} = props;

  const handleCancel = useCallback(() => {
    send({type: "CANCEL"});
  }, [send]);

  const workTypeLookup = useSelector(getWorkTypeLookup);
  const priceGroupLookup = useSelector(getPriceGroupLookup);

  const handleWorkTypeDialogOk = useCallback(
    (url: WorkTypeUrl) => {
      const potentialPriceGroups = getPotentialPriceGroups(
        url,
        state.context.customer,
        workTypeLookup,
        priceGroupLookup,
      );
      send({
        potentialPriceGroups,
        priceGroup: potentialPriceGroups.length === 1 ? potentialPriceGroups[0] : null,
        type: "WORK_TYPE_SELECTED",
        workType: url,
      });
    },
    [priceGroupLookup, send, state.context.customer, workTypeLookup],
  );

  const handlePriceGroupDialogOk = useCallback(
    (url: PriceGroupUrl) => {
      send({type: "PRICE_GROUP_SELECTED", url});
    },
    [send],
  );
  return (
    <>
      {task ? (
        <ConnectedLogLegalExternalWorkTypeDialog
          customerUrl={state.context.customer}
          open={state.matches("selectWorkType")}
          task={task}
          onCancel={handleCancel}
          onOk={handleWorkTypeDialogOk}
        />
      ) : (
        <ConnectedExternalWorkTypeDialog
          open={state.matches("selectWorkType")}
          onCancel={handleCancel}
          onOk={handleWorkTypeDialogOk}
        />
      )}

      <ConnectedLogLegalPriceGroupDialog
        customerUrl={state.context.customer}
        open={state.matches("selectPriceGroup")}
        task={task || emptyTask}
        workTypeURL={state.context.workType ?? undefined}
        onCancel={handleCancel}
        onOk={handlePriceGroupDialogOk}
      />
    </>
  );
}
