import {PunchInOut} from "@co-common-libs/resources";
import {buildEmployeeTimeline} from "@co-common-libs/resources-utils";
import {TaskIntervalWithUnregistered, TaskIntervalWithUnregisteredAndPaid} from "./types";

function firstOf(a: string, b: string): string {
  return a < b ? a : b;
}

export function paidFromPunchedInOut(
  intervalsWithUnregistered: readonly TaskIntervalWithUnregistered[],
  punchedInOutArray: readonly PunchInOut[],
): TaskIntervalWithUnregisteredAndPaid[] {
  const result: TaskIntervalWithUnregisteredAndPaid[] = [];
  const punchInOutTimeline = buildEmployeeTimeline(punchedInOutArray).map((punchInOut) => {
    if (punchInOut.punchOut) {
      return punchInOut as {punchIn: string; punchOut: string};
    } else {
      return {
        punchIn: punchInOut.punchIn,
        punchOut: new Date().toISOString(),
      };
    }
  });
  // iterate over intervalsWithUnregistered and punchInOutTimeline
  // always process whichever starts first
  // for parts without overlap;
  // punchInOutTimeline gives paid entry without task data,
  // intervalsWithUnregistered gives unpaid entry with task data.

  let currentInterval: TaskIntervalWithUnregistered | null = null;
  let currentPunchInOut: {punchIn: string; punchOut: string} | null = null;
  let intervalsIndex = 0;
  let punchedInOutIndex = 0;
  if (intervalsWithUnregistered.length) {
    currentInterval = intervalsWithUnregistered[0];
  }
  if (punchInOutTimeline.length) {
    currentPunchInOut = punchInOutTimeline[0];
  }
  while (currentInterval && currentPunchInOut) {
    if (currentInterval.fromTimestamp < currentPunchInOut.punchIn) {
      const resultingToTimestamp = firstOf(currentInterval.toTimestamp, currentPunchInOut.punchIn);
      result.push(
        Object.assign({}, currentInterval, {
          isPaid: false,
          toTimestamp: resultingToTimestamp,
        }),
      );
      if (resultingToTimestamp < currentInterval.toTimestamp) {
        currentInterval = Object.assign({}, currentInterval, {
          fromTimestamp: resultingToTimestamp,
        });
      } else {
        intervalsIndex += 1;
        currentInterval = intervalsWithUnregistered[intervalsIndex];
      }
    } else if (currentPunchInOut.punchIn < currentInterval.fromTimestamp) {
      const resultingToTimestamp = firstOf(
        currentInterval.fromTimestamp,
        currentPunchInOut.punchOut,
      );
      result.push({
        fromTimestamp: currentPunchInOut.punchIn,
        isBreak: false,
        isPaid: true,
        taskData: [],
        toTimestamp: resultingToTimestamp,
        unregistered: true,
      });
      if (resultingToTimestamp < currentPunchInOut.punchOut) {
        currentPunchInOut = {
          punchIn: resultingToTimestamp,
          punchOut: currentPunchInOut.punchOut,
        };
      } else {
        punchedInOutIndex += 1;
        currentPunchInOut = punchInOutTimeline[punchedInOutIndex];
      }
    } else {
      console.assert(currentInterval.fromTimestamp === currentPunchInOut.punchIn);
      const resultingToTimestamp = firstOf(currentInterval.toTimestamp, currentPunchInOut.punchOut);
      result.push(
        Object.assign({}, currentInterval, {
          isPaid: true,
          toTimestamp: resultingToTimestamp,
        }),
      );
      if (resultingToTimestamp < currentInterval.toTimestamp) {
        currentInterval = Object.assign({}, currentInterval, {
          fromTimestamp: resultingToTimestamp,
        });
      } else {
        intervalsIndex += 1;
        currentInterval = intervalsWithUnregistered[intervalsIndex];
      }
      if (resultingToTimestamp < currentPunchInOut.punchOut) {
        currentPunchInOut = {
          punchIn: resultingToTimestamp,
          punchOut: currentPunchInOut.punchOut,
        };
      } else {
        punchedInOutIndex += 1;
        currentPunchInOut = punchInOutTimeline[punchedInOutIndex];
      }
    }
  }
  if (currentInterval) {
    result.push(Object.assign({isPaid: false}, currentInterval));
  }
  if (currentPunchInOut) {
    result.push({
      fromTimestamp: currentPunchInOut.punchIn,
      isBreak: false,
      isPaid: true,
      taskData: [],
      toTimestamp: currentPunchInOut.punchOut,
      unregistered: true,
    });
  }
  intervalsIndex += 1;
  for (; intervalsIndex < intervalsWithUnregistered.length; intervalsIndex += 1) {
    result.push(Object.assign({isPaid: false}, intervalsWithUnregistered[intervalsIndex]));
  }
  punchedInOutIndex += 1;
  for (; punchedInOutIndex < punchInOutTimeline.length; punchedInOutIndex += 1) {
    const {punchIn, punchOut} = punchInOutTimeline[punchedInOutIndex];
    result.push({
      fromTimestamp: punchIn,
      isBreak: false,
      isPaid: true,
      taskData: [],
      toTimestamp: punchOut,
      unregistered: true,
    });
  }
  return result;
}
