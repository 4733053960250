import {urlToId} from "@co-common-libs/resources";
import {actions, getCurrentUserURL} from "@co-frontend-libs/redux";
import {Badge, IconButton} from "@material-ui/core";
import {Theme, createStyles, makeStyles} from "@material-ui/core/styles";
import {useDeviceConfig} from "app-utils";
import {sortBy} from "lodash";
import BellIcon from "mdi-react/BellIcon";
import React, {useCallback, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Alert} from "./alert";
import {AlertMenu} from "./alert-menu";
import {getAlerts} from "./selectors";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconRed: {
      color: theme.palette.error.main,
    },
    iconWhite: {
      color: theme.palette.common.white,
    },
  }),
);

export const AlertBell = React.memo(function AlertBell(): JSX.Element | null {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const dispatch = useDispatch();
  const currentUserUrl = useSelector(getCurrentUserURL);
  const alerts = sortBy(useSelector(getAlerts), "fromTimestamp").reverse();

  const [newAlertTimestampCleared, setNewAlertTimestampCleared] = useDeviceConfig<string>(
    "newAlertTimestampCleared",
    new Date().toISOString(),
  );

  const alertTriggeredByOthers = alerts.filter(
    ({triggeredByUserUrl}) => triggeredByUserUrl !== currentUserUrl,
  );

  const alertClearDate = new Date(newAlertTimestampCleared);
  const newUnseenAlert = alertTriggeredByOthers.some(
    (alert) => alert.fromTimestamp > alertClearDate,
  );

  const handleIconClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    },
    [anchorEl],
  );

  const handleCloseMenu = useCallback((): void => {
    setAnchorEl(null);
    setNewAlertTimestampCleared(new Date().toISOString());
  }, [setNewAlertTimestampCleared]);

  const handleGoToTask = useCallback(
    (alert: Alert) => {
      handleCloseMenu();
      const taskId = urlToId(alert.task.url);
      dispatch(actions.go("/task/:id", {id: taskId}));
    },
    [dispatch, handleCloseMenu],
  );

  return (
    <>
      <Badge
        badgeContent={alertTriggeredByOthers.length}
        color={newUnseenAlert ? "error" : undefined}
        data-testid="alert-count"
        max={99}
        overlap="circular"
      >
        <IconButton color="inherit" onClick={handleIconClick}>
          <BellIcon className={newUnseenAlert ? classes.iconRed : classes.iconWhite} />
        </IconButton>
      </Badge>
      <AlertMenu
        alerts={alertTriggeredByOthers}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onAlertClick={handleGoToTask}
        onClose={handleCloseMenu}
      />
    </>
  );
});
