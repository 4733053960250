import React from "react";
import {useIntl} from "react-intl";
import {SettingViewProps} from "../types";
import {KeyValueSetting} from "./key-value-setting";

export function Departments(props: SettingViewProps): JSX.Element {
  const {settingID, settingMetaData} = props;
  const intl = useIntl();
  return (
    <KeyValueSetting
      keyLabel={intl.formatMessage({defaultMessage: "Afdelings-ID"})}
      settingID={settingID}
      settingMetaData={settingMetaData}
      title={intl.formatMessage({defaultMessage: "Afdelinger"})}
      valueLabel={intl.formatMessage({defaultMessage: "Afdelingsnavn"})}
    />
  );
}
