import {
  CustomerUrl,
  LocationUrl,
  PriceGroupUrl,
  ProjectUrl,
  RouteTaskUrl,
  TaskUrl,
} from "../resource-urls";
import {ResourceInstance} from "./base";

export interface RouteTask extends ResourceInstance<RouteTaskUrl> {
  readonly activity: PriceGroupUrl | null;
  readonly completed: string | null;
  readonly customer: CustomerUrl;
  readonly deadline: string | null;
  readonly description: string;
  readonly notesFromManager: string;
  readonly notesFromTaskAssignee: string;
  readonly order: number;
  readonly project: ProjectUrl | null;
  readonly relatedLocation: LocationUrl;
  readonly route: TaskUrl;
  readonly started: string | null;
}

export const emptyRouteTask: Omit<
  RouteTask,
  "customer" | "id" | "order" | "relatedLocation" | "route" | "url"
> = {
  activity: null,
  completed: null,
  deadline: null,
  description: "",
  notesFromManager: "",
  notesFromTaskAssignee: "",
  project: null,
  started: null,
};
