import {MINUTE_MILLISECONDS, objectSet} from "@co-common-libs/utils";
import {computeIntervalDurationMilliseconds} from "./duration";
import {Rate, TaskIntervalWithRate, WorkDay} from "./types";

export function splitInterval(
  interval: TaskIntervalWithRate,
  splitTimestamp: string,
): [TaskIntervalWithRate, TaskIntervalWithRate] {
  console.assert(interval.fromTimestamp < splitTimestamp);
  console.assert(splitTimestamp < interval.toTimestamp);
  const beforeSplit: TaskIntervalWithRate = objectSet(interval, "toTimestamp", splitTimestamp);
  console.assert(beforeSplit.fromTimestamp < beforeSplit.toTimestamp);
  const afterSplit: TaskIntervalWithRate = objectSet(interval, "fromTimestamp", splitTimestamp);
  console.assert(afterSplit.fromTimestamp < afterSplit.toTimestamp);
  return [beforeSplit, afterSplit];
}

function attachWorkDaySpecialStartRate(workDay: WorkDay, specialStartRateMinutes: number): WorkDay {
  let remainingSpecialStartRateMilliseconds = specialStartRateMinutes * MINUTE_MILLISECONDS;
  const workPeriods = workDay.workPeriods.map((workPeriod) => {
    if (!remainingSpecialStartRateMilliseconds) {
      return workPeriod;
    }
    const inputWorkIntervals = workPeriod.work;
    const work: TaskIntervalWithRate[] = [];
    for (let i = 0; i < inputWorkIntervals.length; i += 1) {
      const inputInterval = inputWorkIntervals[i];
      const inputIntervalDuration = computeIntervalDurationMilliseconds(inputInterval);
      if (remainingSpecialStartRateMilliseconds) {
        if (inputIntervalDuration <= remainingSpecialStartRateMilliseconds) {
          work.push(objectSet(inputInterval, "rate", Rate.SPECIAL_START_RATE));
          remainingSpecialStartRateMilliseconds -= inputIntervalDuration;
        } else {
          console.assert(inputIntervalDuration > remainingSpecialStartRateMilliseconds);
          const splitDate = new Date(inputInterval.fromTimestamp);
          splitDate.setUTCMilliseconds(
            splitDate.getUTCMilliseconds() + remainingSpecialStartRateMilliseconds,
          );
          const [beforeSplit, afterSplit] = splitInterval(inputInterval, splitDate.toISOString());
          work.push(objectSet(beforeSplit, "rate", Rate.SPECIAL_START_RATE));
          work.push(afterSplit);
          remainingSpecialStartRateMilliseconds = 0;
        }
      } else {
        work.push(inputInterval);
      }
    }
    return objectSet(workPeriod, "work", work);
  });
  return {
    ...workDay,
    workPeriods,
  };
}

/** Set the first `specialStartRateMinutes` of work, per work day, to have
 * rate `Rate.SPECIAL_START_RATE`.
 *
 * Some customers use a contract where e.g. the first 30 minutes of work
 * have a different (lower) rate than normal and should not be considered
 * when computing overtime.
 *
 * @param workDays The work days to work with.
 * @param specialStartRateMinutes The number of (daily) minutes that have
 *   the special rate.
 *  */
export function attachSpecialStartRate(
  workDays: readonly WorkDay[],
  specialStartRateMinutes: number,
): WorkDay[];
export function attachSpecialStartRate(
  workDays: readonly WorkDay[],
  specialStartRateMinutes: number,
): readonly WorkDay[] {
  return workDays.map((workDay) => attachWorkDaySpecialStartRate(workDay, specialStartRateMinutes));
}
