import {ResourceInstance, ResourceName, resourceNames, urlToId} from "@co-common-libs/resources";
import {setUnion} from "@co-common-libs/utils";
import {Check, Query, makeQuery} from "@co-frontend-libs/db-resources";
import {Draft, createNextState, current} from "@reduxjs/toolkit";
import {buildCommittingPerResource} from "../../selectors/resources-helpers";
import {
  GenericInstanceRecord,
  ResourceInstanceRecords,
  ResourceURLArrays,
  ResourcesState,
} from "../../types";
import {
  buildRelationCheckMappings,
  buildResourceCheckMapping,
  combineOfflineCommittingData,
  partialEntriesForEach,
  partialValuesMap,
  partialValuesSome,
} from "../../utils";
import {checkRemoveInstances} from "./check-remove-instances";
import {computeInstancesToCheckFromRemovals} from "./compute-instances-to-check-from-removals";
import {computeInstancesToCheckFromUpdated} from "./compute-instances-to-check-from-updated";
import {computeInstancesToFetchFromUpdated} from "./compute-instances-to-fetch-from-updated";
import {
  updateInstancesFromDeletedOnServer,
  updateInstancesFromUpdatedOnServer,
} from "./update-instances";

function applyPotentialChanges(
  currentOfflineData: ResourceInstanceRecords,
  deletedOnServer: Partial<ResourceURLArrays>,
  updatedOnServer: Partial<ResourceInstanceRecords>,
): ResourceInstanceRecords {
  return createNextState(currentOfflineData, (draft): void => {
    partialEntriesForEach(deletedOnServer, (resourceName, deletedURLs) => {
      const currentForResource = draft[resourceName];
      deletedURLs.forEach((url): void => {
        delete currentForResource[url];
      });
    });
    partialEntriesForEach(updatedOnServer, (resourceName, updatedInstanceRecords) => {
      const currentForResource = draft[resourceName];
      partialEntriesForEach(
        updatedInstanceRecords as GenericInstanceRecord,
        (url, updatedInstance): void => {
          const currentInstance = currentForResource[url];
          if (
            !currentInstance ||
            !currentInstance.lastChanged ||
            !updatedInstance.lastChanged ||
            currentInstance.lastChanged < updatedInstance.lastChanged
          ) {
            // only use "updated" data if actually newer than current
            currentForResource[url] = updatedInstance as ResourceInstance as Draft<any>;
          }
        },
      );
    });
  });
}

export function combineResourceFetchByID(
  oldEntries: readonly string[] | undefined,
  newEntries: ReadonlySet<string>,
): string[] {
  if (oldEntries) {
    const uuidLength = 36;
    oldEntries.forEach((id) => {
      console.assert(id.length === uuidLength, `not an UUID: ${id}`);
    });
    return [...new Set([...oldEntries, ...newEntries])];
  } else {
    const uuidLength = 36;
    [...newEntries].forEach((id) => {
      console.assert(id.length === uuidLength, `not an UUID: ${id}`);
    });
    return [...newEntries];
  }
}

export function combineResourceFetchByRelation(
  oldEntries:
    | Partial<{
        [memberName: string]: string[];
      }>
    | undefined,
  newEntries: Partial<{
    readonly [memberName: string]: ReadonlySet<string>;
  }>,
): Partial<{
  [memberName: string]: string[];
}> {
  const result = oldEntries ? {...oldEntries} : {};
  partialEntriesForEach(newEntries, (memberName, values) => {
    result[memberName] = combineResourceFetchByID(result[memberName], values);
  });
  return result;
}

export function updateFromReceivedData(
  state: Draft<ResourcesState>,
  currentOfflineData: ResourceInstanceRecords,
  unchangedOnServer: Partial<ResourceURLArrays>,
  updatedOnServer: Partial<ResourceInstanceRecords>,
  deletedOnServer?: Partial<ResourceURLArrays>,
  potentiallyRemovedOnServer?: Partial<ResourceURLArrays>,
  fullFetchQuery?: Query,
): void {
  const currentState = current(state) as ResourcesState;

  const offlineWithUncheckedChanges = applyPotentialChanges(
    currentOfflineData,
    deletedOnServer || {},
    updatedOnServer,
  );

  const committing = buildCommittingPerResource(currentState.commitQueue);

  const potentialData = combineOfflineCommittingData(offlineWithUncheckedChanges, committing);
  const oldData = combineOfflineCommittingData(currentOfflineData, committing);

  const persistedQueries = partialValuesMap(currentState.persistedQueries, ({query: q}) => q);
  const temporaryQueries = partialValuesMap(currentState.temporaryQueries, ({query: q}) => q);

  const replaceCurrentQueryCheck = fullFetchQuery
    ? (q: Query) => {
        if (q.keyString === fullFetchQuery.keyString) {
          const check: Check = {
            memberName: "url",
            type: "memberIn",
            values: Object.keys(updatedOnServer[q.resourceName] || {}),
          };
          return makeQuery({...q, check});
        } else {
          return q;
        }
      }
    : null;

  const persistedQueriesX = replaceCurrentQueryCheck
    ? persistedQueries.map(replaceCurrentQueryCheck)
    : persistedQueries;

  const temporaryQueriesX = replaceCurrentQueryCheck
    ? temporaryQueries.map(replaceCurrentQueryCheck)
    : temporaryQueries;

  const persistedResourceCheckMapping = buildResourceCheckMapping(persistedQueriesX);
  const temporaryResourceCheckMapping = buildResourceCheckMapping(temporaryQueriesX);

  const {fkRules: persistedFkRules, reverseFkRules: persistedReverseFkRules} =
    buildRelationCheckMappings(persistedResourceCheckMapping);

  const {fkRules: temporaryFkRules, reverseFkRules: temporaryReverseFkRules} =
    buildRelationCheckMappings(temporaryResourceCheckMapping);

  if (deletedOnServer) {
    updateInstancesFromDeletedOnServer(state, deletedOnServer);
  }

  if (partialValuesSome(updatedOnServer, (v: GenericInstanceRecord) => !!Object.keys(v).length)) {
    updateInstancesFromUpdatedOnServer(
      state,
      updatedOnServer,
      currentState,
      potentialData,
      persistedResourceCheckMapping,
      temporaryResourceCheckMapping,
    );
  }

  const instancesToCheckFromDeleted = deletedOnServer
    ? computeInstancesToCheckFromRemovals(
        oldData,
        deletedOnServer,
        persistedFkRules,
        persistedReverseFkRules,
        temporaryFkRules,
        temporaryReverseFkRules,
      )
    : null;

  const instancesToCheckFromUpdated = computeInstancesToCheckFromUpdated(
    oldData,
    updatedOnServer,
    persistedFkRules,
    persistedReverseFkRules,
    temporaryFkRules,
    temporaryReverseFkRules,
  );

  const instancesToCheck = Object.fromEntries(
    resourceNames.map((resourceName): [ResourceName, ReadonlySet<string>] => {
      const forResourceFromDeleted = instancesToCheckFromDeleted
        ? instancesToCheckFromDeleted[resourceName]
        : null;
      const forResourceFromUpdated = instancesToCheckFromUpdated[resourceName];
      const forResourcePotentiallyRemoved = potentiallyRemovedOnServer
        ? potentiallyRemovedOnServer[resourceName]
        : null;
      const result = forResourceFromDeleted
        ? setUnion(forResourceFromDeleted, forResourceFromUpdated)
        : new Set(forResourceFromUpdated);
      if (forResourcePotentiallyRemoved?.length) {
        forResourcePotentiallyRemoved.forEach((potentiallyRemoved) =>
          result.add(potentiallyRemoved),
        );
      }
      return [resourceName, result];
    }),
  ) as {[N in ResourceName]: ReadonlySet<string>};

  checkRemoveInstances(
    state,
    instancesToCheck,
    potentialData,
    persistedResourceCheckMapping,
    temporaryResourceCheckMapping,
    oldData,
    persistedFkRules,
    persistedReverseFkRules,
    temporaryFkRules,
    temporaryReverseFkRules,
  );

  const {
    persistedFetchByID,
    persistedFetchByRelation,
    temporaryFetchByID,
    temporaryFetchByRelation,
  } = computeInstancesToFetchFromUpdated(
    oldData,
    potentialData,
    updatedOnServer,
    persistedFkRules,
    persistedReverseFkRules,
    temporaryFkRules,
    temporaryReverseFkRules,
  );

  resourceNames.forEach((resourceName) => {
    const resourcePersistedFetchByID = persistedFetchByID[resourceName];
    if (resourcePersistedFetchByID?.size) {
      state.persistedFetchByID[resourceName] = combineResourceFetchByID(
        state.persistedFetchByID[resourceName],
        resourcePersistedFetchByID,
      );
      const fetchByIdForResource = state.persistedFetchByID[resourceName];
      if (fetchByIdForResource) {
        const uuidLength = 36;
        fetchByIdForResource.forEach((id) => {
          console.assert(id.length === uuidLength, `not an UUID: ${id}`);
        });
      }
    }
    const resourceTemporaryFetchByID = temporaryFetchByID[resourceName];
    if (resourceTemporaryFetchByID?.size) {
      state.temporaryFetchByID[resourceName] = combineResourceFetchByID(
        state.temporaryFetchByID[resourceName],
        resourceTemporaryFetchByID,
      );
      const fetchByIdForResource = state.temporaryFetchByID[resourceName];
      if (fetchByIdForResource) {
        const uuidLength = 36;
        fetchByIdForResource.forEach((id) => {
          console.assert(id.length === uuidLength, `not an UUID: ${id}`);
        });
      }
    }

    const resourceUpdatedOnServer = updatedOnServer[resourceName];
    const resourceUnchangedOnServer = unchangedOnServer[resourceName];
    const resourceDeletedOnServer = deletedOnServer && deletedOnServer[resourceName];
    if (resourceUpdatedOnServer || resourceUnchangedOnServer || resourceDeletedOnServer) {
      // Stack overflow on spread syntax
      const resourceUpdatedOrUnchangedOrDeletedOnServerURLArray = ([] as string[]).concat(
        resourceUpdatedOnServer ? Object.keys(resourceUpdatedOnServer) : [],
        resourceUnchangedOnServer ? resourceUnchangedOnServer : [],
        resourceDeletedOnServer ? resourceDeletedOnServer : [],
      );

      console.assert(
        resourceUpdatedOrUnchangedOrDeletedOnServerURLArray.every((val) => val.length !== 36),
      );
      const resourceUpdatedOrUnchangedOnServerIDs = new Set(
        resourceUpdatedOrUnchangedOrDeletedOnServerURLArray.map(urlToId),
      );
      const currentResourcePersistedFetchByID = state.persistedFetchByID[resourceName];
      if (currentResourcePersistedFetchByID) {
        const filteredResourcePersistedFetchByID = currentResourcePersistedFetchByID.filter(
          (id) => !resourceUpdatedOrUnchangedOnServerIDs.has(id),
        );
        if (filteredResourcePersistedFetchByID.length) {
          state.persistedFetchByID[resourceName] = filteredResourcePersistedFetchByID;
        } else {
          delete state.persistedFetchByID[resourceName];
        }
      }
      const currentResourceTemporaryFetchByID = state.temporaryFetchByID[resourceName];
      if (currentResourceTemporaryFetchByID) {
        const filteredResourceTemporaryFetchByID = currentResourceTemporaryFetchByID.filter(
          (id) => !resourceUpdatedOrUnchangedOnServerIDs.has(id),
        );
        if (filteredResourceTemporaryFetchByID.length) {
          state.temporaryFetchByID[resourceName] = filteredResourceTemporaryFetchByID;
        } else {
          delete state.temporaryFetchByID[resourceName];
        }
      }
    }

    const resourcePersistedFetchByRelation = persistedFetchByRelation[resourceName];
    if (resourcePersistedFetchByRelation) {
      state.persistedFetchByRelation[resourceName] = combineResourceFetchByRelation(
        state.persistedFetchByRelation[resourceName],
        resourcePersistedFetchByRelation,
      );
    }

    const resourceTemporaryFetchByRelation = temporaryFetchByRelation[resourceName];
    if (resourceTemporaryFetchByRelation) {
      state.temporaryFetchByRelation[resourceName] = combineResourceFetchByRelation(
        state.temporaryFetchByRelation[resourceName],
        resourceTemporaryFetchByRelation,
      );
    }
  });
}
