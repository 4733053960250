import {
  apiVersion as currentApiVersion,
  frontendVersion as currentFrontendVersion,
} from "@co-common-libs/frontend-version";
import {Command, CreateCommand, ResourceTypeUnion} from "@co-common-libs/resources";
import {Draft} from "@reduxjs/toolkit";
import _ from "lodash";
import {createPromise} from "../actions";
import {CreateCommandPromise, ResourcesState} from "../types";

export function handleCreatePromisePending(
  state: Draft<ResourcesState>,
  action: ReturnType<typeof createPromise.pending>,
): void {
  const {url} = action.meta.arg;
  const {commitQueue} = state;

  const id = state.commitLoaded ? (_.last(commitQueue)?.id || 0) + 1 : 0;

  const command: CreateCommandPromise = {action: "CREATE_PROMISE", url};

  commitQueue.push({
    apiVersion: currentApiVersion,
    command: command as Draft<CreateCommandPromise>,
    error: null,
    errorTimestamp: null,
    frontendVersion: currentFrontendVersion,
    id,
    requestId: null,
    status: "PROMISE",
  });
}

export function handleCreatePromiseFulfilled(
  state: Draft<ResourcesState>,
  action: ReturnType<typeof createPromise.fulfilled>,
): void {
  const {url} = action.meta.arg;
  const instance = action.payload;
  const {commitQueue} = state;
  const index = commitQueue.findIndex(
    (entry) =>
      entry.status === "PROMISE" &&
      entry.command.action === "CREATE_PROMISE" &&
      entry.command.url === url,
  );
  const command: CreateCommand = {
    action: "CREATE",
    instance: instance as ResourceTypeUnion,
    url,
  };
  commitQueue[index].command = command as Draft<Command>;
  commitQueue[index].status = "UNSAVED";
}

export function handleCreatePromiseRejected(
  state: Draft<ResourcesState>,
  action: ReturnType<typeof createPromise.rejected>,
): void {
  const {url} = action.meta.arg;
  const {commitQueue} = state;
  const index = commitQueue.findIndex(
    (entry) =>
      entry.status === "PROMISE" &&
      entry.command.action === "CREATE_PROMISE" &&
      entry.command.url === url,
  );
  commitQueue.splice(index, 1);
}
