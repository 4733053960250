import {IconButton, Typography} from "@material-ui/core";
import {addDanishCountryPrefix} from "app-utils";
import CellphoneIcon from "mdi-react/CellphoneIcon";
import FaxIcon from "mdi-react/FaxIcon";
import PhoneIcon from "mdi-react/PhoneIcon";
import React from "react";

type PhoneType = "cellphone" | "fax" | "landline";

function GetPhoneIcon(type: PhoneType): JSX.Element {
  switch (type) {
    case "cellphone":
      return <CellphoneIcon />;
    case "fax":
      return <FaxIcon />;
    case "landline":
      return <PhoneIcon />;
    default:
      throw new Error(`Unsupported phone type ${type}`);
  }
}

export function PhoneLine({phone, type}: {phone: string; type: PhoneType}): JSX.Element {
  return (
    <div>
      <IconButton color="primary" href={`tel:${addDanishCountryPrefix(phone)}`}>
        {GetPhoneIcon(type)}
      </IconButton>
      <Typography component="span" variant="body2">
        {phone}
      </Typography>
    </div>
  );
}
