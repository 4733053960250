import {getCustomerSettings, getMachineArray, getWorkTypeArray} from "@co-frontend-libs/redux";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import React from "react";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {SettingValueDisplayProps} from "../types";

export function DisplayWorktypeAutoMachines(_props: SettingValueDisplayProps): JSX.Element {
  const customerSettings = useSelector(getCustomerSettings);
  // eslint-disable-next-line prefer-destructuring
  const workTypeAutoMachines = customerSettings.workTypeAutoMachines;
  const workTypeArray = useSelector(getWorkTypeArray);
  const machineArray = useSelector(getMachineArray);
  return (
    <div>
      <i>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{width: 200}}>
                <FormattedMessage defaultMessage="Område" id="system-setup.absence-type" />
              </TableCell>
              <TableCell style={{width: 300}}>
                <FormattedMessage defaultMessage="Maskiner" id="system-setup.machines" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {workTypeAutoMachines
              ? Object.entries(workTypeAutoMachines).map(([worktypeIdentifier, machines]) => {
                  const workType = workTypeArray.find((w) => w.identifier === worktypeIdentifier);

                  return (
                    <TableRow key={`${worktypeIdentifier}`}>
                      <TableCell>
                        {worktypeIdentifier} {workType?.name}
                      </TableCell>
                      <TableCell>
                        {machines
                          ? machines.map((machineIdentifier) => {
                              const machine = machineArray.find(
                                (m) => m.c5_machine === machineIdentifier,
                              );
                              return (
                                <div key={machineIdentifier}>
                                  {machineIdentifier}: {machine?.name}
                                </div>
                              );
                            })
                          : null}
                      </TableCell>
                    </TableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
      </i>
    </div>
  );
}
