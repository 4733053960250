import {Location} from "@co-common-libs/resources";
import {getFieldBoundingBox, labelsVisible} from "@co-common-libs/resources-utils";
import {useMapContainer} from "@co-frontend-libs/utils";
import _ from "lodash";
import React from "react";
import ReactDOM from "react-dom";
import {defineMessages, useIntl} from "react-intl";
import {FieldLabel} from "./field-label";

const messages = defineMessages({
  noCode: {
    defaultMessage: "Intet marknr.",
    id: "fields-card.label.no-code",
  },
});

interface FieldLabelsProps {
  alwaysShow?: boolean;
  extraLabels?: ReadonlyMap<string, string> | undefined;
  fieldArray: readonly Location[];
  googleMapsLinks?: boolean;
  includeArea?: boolean;
  mapInstance: google.maps.Map;
}

export function FieldLabels(props: FieldLabelsProps): JSX.Element {
  const {alwaysShow, extraLabels, fieldArray, googleMapsLinks, includeArea, mapInstance} = props;

  const [containerElement, overlayView] = useMapContainer(mapInstance);

  const {formatMessage, formatNumber} = useIntl();

  if (containerElement && overlayView && mapInstance) {
    const mapCanvasProjection = overlayView.getProjection();
    const zoom = mapInstance.getZoom();
    const visible = alwaysShow || (zoom !== undefined ? labelsVisible(zoom) : false);

    const MIN_DISTANCE_PIXELS = 25;

    let fieldLabels: JSX.Element[] | undefined;

    if (visible) {
      const bounds = mapInstance.getBounds() as google.maps.LatLngBounds;
      const fieldPositions: {
        field: Location;
        latLng: google.maps.LatLng;
        x: number;
        y: number;
      }[] = [];

      fieldArray.forEach((field) => {
        const bbox = getFieldBoundingBox(field);
        const fieldCenter = new google.maps.LatLng(
          (bbox[3] + bbox[1]) / 2,

          (bbox[2] + bbox[0]) / 2,
        );
        if (bounds.contains(fieldCenter)) {
          const point = mapCanvasProjection.fromLatLngToDivPixel(fieldCenter);
          if (point) {
            const {x, y} = point;
            fieldPositions.push({field, latLng: fieldCenter, x, y});
          }
        }
      });
      fieldPositions.sort((a, b) => a.x - b.x || a.y - b.y);
      const labelClusters: {
        fields: {
          field: Location;
          latLng: google.maps.LatLng;
          x: number;
          y: number;
        }[];
        x: number;
        y: number;
      }[] = [];
      fieldPositions.forEach((fieldWithPosition) => {
        for (let i = 0; i < labelClusters.length; i += 1) {
          const labelCluster = labelClusters[i];
          const xDistance = labelCluster.x - fieldWithPosition.x;
          const yDistance = labelCluster.y - fieldWithPosition.y;
          const distance = Math.sqrt(xDistance * xDistance + yDistance * yDistance);
          if (distance < MIN_DISTANCE_PIXELS) {
            labelCluster.fields.push(fieldWithPosition);
            labelCluster.x =
              labelCluster.fields.reduce((acc, {x}) => acc + x, 0) / labelCluster.fields.length;
            labelCluster.y =
              labelCluster.fields.reduce((acc, {y}) => acc + y, 0) / labelCluster.fields.length;
            return;
          }
        }
        labelClusters.push({
          fields: [fieldWithPosition],
          x: fieldWithPosition.x,
          y: fieldWithPosition.y,
        });
      });
      fieldLabels = labelClusters.map((labelCluster) => {
        const {fields, x, y} = labelCluster;
        if (fields.length === 1) {
          const {field, latLng} = fields[0];
          const area = includeArea
            ? formatNumber(field.fieldAreaHa || 0, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })
            : undefined;
          return (
            <FieldLabel
              key={field.url}
              area={area}
              extraText={extraLabels?.get(field.url)}
              fieldRecordYear={field.fieldRecordYear}
              latLng={googleMapsLinks ? latLng : undefined}
              text={field.fieldNumber || formatMessage(messages.noCode)}
              x={x}
              y={y}
            />
          );
        } else {
          const text = fields
            .map(({field}) => field.fieldNumber)
            .filter(_.identity)
            .join(",\n");
          return <FieldLabel key={fields[0].field.url} text={text} x={x} y={y} />;
        }
      });
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return ReactDOM.createPortal(<>{fieldLabels}</>, containerElement);
  } else {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }
}
