import {ResourceInstance, ResourceTypes} from "@co-common-libs/resources";
import {Query} from "@co-frontend-libs/db-resources";
import {Draft, current} from "@reduxjs/toolkit";
import {computeNextFetchChangesTimestamp, fullFetchesPending} from "../../middleware/utils";
import {buildErrorObject} from "../../types";
import {performFullFetch} from "../actions";
import {
  GenericInstanceRecord,
  ResourceInstanceRecords,
  ResourceURLArrays,
  ResourcesState,
} from "../types";
import {partialEntriesForEach} from "../utils";
import {getOfflineData} from "./selectors";
import {updateFromReceivedData} from "./update-from-received-data";

export function handlePerformFullFetchPending(
  state: Draft<ResourcesState>,
  action: ReturnType<typeof performFullFetch.pending>,
): void {
  const {query} = action.meta.arg;
  const {keyString} = query;
  const queryQueryState = state.persistedQueries[keyString] || state.temporaryQueries[keyString];
  if (queryQueryState) {
    queryQueryState.queryState.currentlyFullFetching = true;
  }
}

function fullFetchResponseBaseChanges(
  allResourcesData: ResourceInstanceRecords,
  query: Query,
  data: readonly ResourceInstance[],
  timestamp: string,
): {
  deletedOnServer: Partial<ResourceURLArrays>;
  noLongerVisibleToQuery: Partial<ResourceURLArrays>;
  updatedOnServer: Partial<ResourceInstanceRecords>;
} {
  const deletedOnServer: Partial<{
    [P in keyof ResourceTypes]: string[];
  }> = {};
  const updatedOnServer: Partial<{
    [P in keyof ResourceTypes]: Partial<{
      [url: string]: Readonly<ResourceTypes[P]>;
    }>;
  }> = {};
  const noLongerVisibleToQuery: Partial<{
    [P in keyof ResourceTypes]: string[];
  }> = {};
  const {resourceName} = query;
  const currentData = allResourcesData[resourceName];
  const resourceUpdatedOnServer: Partial<{
    [url: string]: Readonly<ResourceInstance>;
  }> = {};
  const resourceNoLongerVisibleToQuery: string[] = [];
  if (data.length) {
    // For each entry, add to "potentially update" set.
    // For each existing, determine whether to remove.
    for (let i = 0; i < data.length; i += 1) {
      const instance = data[i];
      const {url} = instance;
      resourceUpdatedOnServer[url] = instance;
    }
    const survivingURLSet = new Set(Object.keys(resourceUpdatedOnServer));
    partialEntriesForEach(currentData as GenericInstanceRecord, (url, instance): void => {
      if (
        !survivingURLSet.has(url) &&
        // keep any instances *after* the "full" response was computed
        instance.lastChanged &&
        instance.lastChanged < timestamp
      ) {
        resourceNoLongerVisibleToQuery.push(url);
      }
    });
  }
  console.assert(!deletedOnServer[resourceName]);
  console.assert(!noLongerVisibleToQuery[resourceName]);
  if (resourceNoLongerVisibleToQuery.length) {
    if (
      resourceName === query.resourceName &&
      Object.keys(query.filter).length === 0 &&
      !query.limit &&
      !query.instance
    ) {
      // if this is an "everything for resource" query, then
      // "no longer visible" implies "deleted on server"
      deletedOnServer[resourceName] = resourceNoLongerVisibleToQuery;
    } else {
      noLongerVisibleToQuery[resourceName] = resourceNoLongerVisibleToQuery;
    }
  }
  console.assert(!updatedOnServer[resourceName]);
  if (Object.keys(resourceUpdatedOnServer).length) {
    updatedOnServer[resourceName] = resourceUpdatedOnServer as Partial<{
      [url: string]: Readonly<any>;
    }>;
  }
  return {deletedOnServer, noLongerVisibleToQuery, updatedOnServer};
}

export function handlePerformFullFetchFulfilled(
  state: Draft<ResourcesState>,
  action: ReturnType<typeof performFullFetch.fulfilled>,
): void {
  const {query} = action.meta.arg;
  const {keyString} = query;
  const responseContent = action.payload;
  const queryQueryState = state.persistedQueries[keyString] || state.temporaryQueries[keyString];
  const {results, timestamp} = responseContent;
  if (queryQueryState) {
    console.assert(
      queryQueryState.queryState.currentlyFullFetching,
      `not actually currently full-fetching: ${keyString}`,
    );
    queryQueryState.queryState.currentlyFullFetching = false;
    queryQueryState.queryState.fullFetchDataComputedAtTimestamp = timestamp;
  }

  const updatedState = current(state) as ResourcesState;
  const currentOfflineData = getOfflineData(updatedState);

  const {deletedOnServer, noLongerVisibleToQuery, updatedOnServer} = fullFetchResponseBaseChanges(
    currentOfflineData,
    query,
    results,
    timestamp,
  );

  updateFromReceivedData(
    state,
    currentOfflineData,
    {}, // even unchanged are included in updatedOnServer for full fetch...
    updatedOnServer,
    deletedOnServer,
    noLongerVisibleToQuery,
    query,
  );

  if (!fullFetchesPending(state)) {
    state.lastFetchChangesTimestamp = computeNextFetchChangesTimestamp(state);
  }
}

export function handlePerformFullFetchRejected(
  state: Draft<ResourcesState>,
  action: ReturnType<typeof performFullFetch.rejected>,
): void {
  const {query} = action.meta.arg;
  const {keyString} = query;

  const queryQueryState = state.persistedQueries[keyString] || state.temporaryQueries[keyString];
  if (queryQueryState) {
    queryQueryState.queryState.currentlyFullFetching = false;
    if (action.payload) {
      const {error, timestamp} = action.payload;
      queryQueryState.queryState.lastError = error;
      queryQueryState.queryState.lastErrorTimestamp = timestamp;
    } else {
      queryQueryState.queryState.lastError = buildErrorObject(action.error);
    }
  }
}
