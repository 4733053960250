import {Command} from "@co-common-libs/resources";
import idbReady from "safari-14-idb-fix";
import {CommitDBConnection, OldCommand, SerializableError} from "./types";
import {translateOldCommand} from "./utils";

const EMPTY_VERSION = 0;
const UNVERSIONED_DATA_VERSION = 1;
const BEFORE_JSON_PATCH = 2;
const CURRENT_VERSION = 3;

// FIXME: this can't be right wrt. async; no completion callbacks?
const createUpdateStores = (
  db: IDBDatabase,
  transaction: IDBTransaction,
  oldVersion: number,
  _newVersion: number | null,
): void => {
  if (oldVersion === EMPTY_VERSION) {
    // db.createObjectStore("commit", {keyPath: "id"});
    db.createObjectStore("commitpatchqueue", {keyPath: "id"});
  } else if (oldVersion === UNVERSIONED_DATA_VERSION || oldVersion === BEFORE_JSON_PATCH) {
    db.createObjectStore("commitpatchqueue", {keyPath: "id"});
    const oldStore = transaction.objectStore("commit");
    const translated: {
      apiVersion: string | null;
      command: Command;
      error: SerializableError | null;
      errorTimestamp: string | null;
      frontendVersion: string | null;
      id: number;
    }[] = [];
    if (hasGetAll(oldStore)) {
      (oldStore.getAll() as IDBRequest).onsuccess = (event) => {
        const entryArray = (event.target as any).result as any[] as {
          readonly apiVersion?: string | null;
          readonly command: OldCommand;
          readonly error: SerializableError | null;
          readonly errorTimestamp: string | null;
          readonly frontendVersion?: string | null;
          readonly id: number;
        }[];
        entryArray.forEach((entry) => {
          translated.push({
            apiVersion: entry.apiVersion || null,
            command: translateOldCommand(entry.command),
            error: entry.error,
            errorTimestamp: entry.errorTimestamp,
            frontendVersion: entry.frontendVersion || null,
            id: entry.id,
          });
        });
        if (translated.length) {
          const newStore = transaction.objectStore("commitpatchqueue");
          translated.forEach((entry) => {
            newStore.put(entry);
          });
        }
        db.deleteObjectStore("commit");
      };
    } else {
      oldStore.openCursor().onsuccess = (event) => {
        const cursor = (event.target as any).result as IDBCursorWithValue;
        if (cursor) {
          const entry = cursor.value as {
            readonly apiVersion?: string | null;
            readonly command: OldCommand;
            readonly error: SerializableError | null;
            readonly errorTimestamp: string | null;
            readonly frontendVersion?: string | null;
            readonly id: number;
          };
          translated.push({
            apiVersion: entry.apiVersion || null,
            command: translateOldCommand(entry.command),
            error: entry.error,
            errorTimestamp: entry.errorTimestamp,
            frontendVersion: entry.frontendVersion || null,
            id: entry.id,
          });
          cursor.continue();
        } else {
          if (translated.length) {
            const newStore = transaction.objectStore("commitpatchqueue");
            translated.forEach((entry) => {
              newStore.put(entry);
            });
          }
          db.deleteObjectStore("commit");
        }
      };
    }
  }
};

const openConnection = (indexedDB: IDBFactory, dbName: string): Promise<IDBDatabase> =>
  // Don't use hardcoded window.indexedDB, to support test with fakeIndexedDB.
  new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName, CURRENT_VERSION);
    request.onupgradeneeded = (event) => {
      const db = (event.target as any).result as IDBDatabase;
      const transaction = (event.target as any).transaction as IDBTransaction;
      const {oldVersion} = event;
      const {newVersion} = event;
      createUpdateStores(db, transaction, oldVersion, newVersion);
    };
    request.onsuccess = (event) => {
      const db = (event.target as any).result as IDBDatabase;
      resolve(db);
    };
    request.onerror = (event) => {
      reject(new Error(event.type));
    };
  });

// Wrapper to "trick" TypeScript into considering it possible that the value
// might be false.  In the type declaration, IDBObjectStore.getAll() exists;
// but on older browsers, it might not...
const hasGetAll = (store: IDBObjectStore): boolean => "getAll" in store;

class IndexedDBCommitDBConnection extends CommitDBConnection {
  private connection: IDBDatabase;
  private indexedDB: IDBFactory;
  private dbName: string;
  constructor(connection: IDBDatabase, indexedDB: IDBFactory, dbName: string) {
    super();
    this.connection = connection;
    this.indexedDB = indexedDB;
    this.dbName = dbName;
  }
  private pendingTransactions: IDBTransaction[] = [];
  private setPending(transaction: IDBTransaction): void {
    // Safari workaround; apparently, transactions may be lost without
    // callbacks called unless some reference prevents GC from taking them...
    // https://bugs.webkit.org/show_bug.cgi?id=186180
    this.pendingTransactions.push(transaction);
  }
  private clearPending(transaction: IDBTransaction): void {
    const idx = this.pendingTransactions.indexOf(transaction);
    this.pendingTransactions.splice(idx, 1);
  }
  getAll(): Promise<
    readonly {
      readonly apiVersion: string | null;
      readonly command: Command;
      readonly error: SerializableError | null;
      readonly errorTimestamp: string | null;
      readonly frontendVersion: string | null;
      readonly id: number;
    }[]
  > {
    return new Promise<
      {
        readonly apiVersion: string | null;
        readonly command: Command;
        readonly error: SerializableError | null;
        readonly errorTimestamp: string | null;
        readonly frontendVersion: string | null;
        readonly id: number;
      }[]
    >((resolve, reject) => {
      const result: {
        readonly apiVersion: string | null;
        readonly command: Command;
        readonly error: SerializableError | null;
        readonly errorTimestamp: string | null;
        readonly frontendVersion: string | null;
        readonly id: number;
      }[] = [];
      const transaction = this.connection.transaction(["commitpatchqueue"], "readonly");
      this.setPending(transaction);
      transaction.oncomplete = (_event) => {
        this.clearPending(transaction);
        result.sort((a, b) => a.id - b.id);
        resolve(result);
      };
      transaction.onerror = (event) => {
        this.clearPending(transaction);
        reject(new Error(event.type));
      };
      transaction.onabort = (event) => {
        this.clearPending(transaction);
        reject(new Error(event.type));
      };
      const store = transaction.objectStore("commitpatchqueue");
      if (hasGetAll(store)) {
        (store.getAll() as IDBRequest).onsuccess = (event) => {
          const entryArray = (event.target as any).result as any[];
          result.push(...entryArray);
        };
      } else {
        store.openCursor().onsuccess = (event) => {
          const cursor = (event.target as any).result as IDBCursorWithValue;
          if (cursor) {
            const entry = cursor.value;
            result.push(entry);
            cursor.continue();
          }
        };
      }
    });
  }
  put(id: number, command: Command, apiVersion: string, frontendVersion: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const transaction = this.connection.transaction(["commitpatchqueue"], "readwrite");
      this.setPending(transaction);
      transaction.oncomplete = (_event) => {
        this.clearPending(transaction);
        resolve();
      };
      transaction.onerror = (event) => {
        this.clearPending(transaction);
        reject(new Error(event.type));
      };
      transaction.onabort = (event) => {
        this.clearPending(transaction);
        reject(new Error(event.type));
      };
      const store = transaction.objectStore("commitpatchqueue");
      store.put({
        apiVersion,
        command,
        error: null,
        errorTimestamp: null,
        frontendVersion,
        id,
      });
    });
  }
  setError(id: number, error: SerializableError, errorTimestamp: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const transaction = this.connection.transaction(["commitpatchqueue"], "readwrite");
      this.setPending(transaction);
      transaction.oncomplete = (_event) => {
        this.clearPending(transaction);
        resolve();
      };
      transaction.onerror = (event) => {
        this.clearPending(transaction);
        reject(new Error(event.type));
      };
      transaction.onabort = (event) => {
        this.clearPending(transaction);
        reject(new Error(event.type));
      };
      const store = transaction.objectStore("commitpatchqueue");
      const getRequest = store.get(id);
      getRequest.onsuccess = (event) => {
        const {result} = event.target as any;
        console.assert(result.id === id);
        store.put({...result, error, errorTimestamp});
      };
    });
  }
  delete(id: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const transaction = this.connection.transaction(["commitpatchqueue"], "readwrite");
      this.setPending(transaction);
      transaction.oncomplete = (_event) => {
        this.clearPending(transaction);
        resolve();
      };
      transaction.onerror = (event) => {
        this.clearPending(transaction);
        reject(new Error(event.type));
      };
      transaction.onabort = (event) => {
        this.clearPending(transaction);
        reject(new Error(event.type));
      };
      const store = transaction.objectStore("commitpatchqueue");
      store.delete(id);
    });
  }
  // we override an abstract method, it's OK...
  // eslint-disable-next-line class-methods-use-this
  vacuum(): Promise<void> {
    return Promise.resolve();
  }
  close(): Promise<void> {
    this.connection.close();
    return Promise.resolve(undefined);
  }
  deleteDatabase(): Promise<void> {
    return new Promise((resolve, reject) => {
      const request = this.indexedDB.deleteDatabase(this.dbName);
      request.onsuccess = (_event) => {
        resolve();
      };
      request.onerror = (event) => {
        reject(new Error(event.type));
      };
    });
  }
}

export const getIndexedDBConnection = (
  indexedDB: IDBFactory,
  dbName: string,
): Promise<CommitDBConnection> =>
  // Workaround for Safari 14 IndexedDB
  idbReady()
    .then(() => openConnection(indexedDB, dbName))
    .then((connection) => new IndexedDBCommitDBConnection(connection, indexedDB, dbName));
