import {
  CustomerUrl,
  KrPerLiterDefaultFuelSurchargeUse,
  KrPerLiterFuelSurchargeSpecification,
  KrPerLiterFuelSurchargeSpecificationUrl,
  KrPerLiterMachineFuelSurchargeUse,
  KrPerLiterWorkTypeFuelSurchargeUse,
  MachineUrl,
  Task,
} from "@co-common-libs/resources";
import {findFuelSurchargeUseAndSpecification} from "./find-fuel-surcharge-use-and-specification";

type KrPerLiterFuelSurchargeSpecificationPart = Pick<
  KrPerLiterFuelSurchargeSpecification,
  "active"
>;
type KrPerLiterMachineFuelSurchargeUsePart = Pick<
  KrPerLiterMachineFuelSurchargeUse,
  "customer" | "fuelSurcharge" | "machine" | "variant"
>;
type KrPerLiterWorkTypeFuelSurchargeUsePart = Pick<
  KrPerLiterWorkTypeFuelSurchargeUse,
  "customer" | "fuelSurcharge" | "variant" | "workType"
>;
type KrPerLiterDefaultFuelSurchargeUsePart = Pick<
  KrPerLiterDefaultFuelSurchargeUse,
  "customer" | "fuelSurcharge"
>;

export function getMachineUseMatches<
  Specification extends KrPerLiterFuelSurchargeSpecificationPart,
  MachineFuelSurchargeUse extends KrPerLiterMachineFuelSurchargeUsePart,
  WorkTypeFuelSurchargeUse extends KrPerLiterWorkTypeFuelSurchargeUsePart,
  DefaultFuelSurchargeUse extends KrPerLiterDefaultFuelSurchargeUsePart,
>(
  task: Pick<Task, "machineuseSet" | "priceGroup" | "workType">,
  customerUrl: CustomerUrl | null,
  machineLookup: (url: MachineUrl) => unknown | undefined,
  fuelSurchargeSpecificationLookup: (
    url: KrPerLiterFuelSurchargeSpecificationUrl,
  ) => Specification | undefined,
  machineFuelSurchargeUseArray: readonly MachineFuelSurchargeUse[],
  workTypeFuelSurchargeUseArray: readonly WorkTypeFuelSurchargeUse[],
  defaultFuelSurchargeUseArray: readonly DefaultFuelSurchargeUse[],
): Map<
  MachineUrl,
  {
    specification: Specification | null;
    use: DefaultFuelSurchargeUse | MachineFuelSurchargeUse | WorkTypeFuelSurchargeUse;
  }
> {
  const result = new Map<
    MachineUrl,
    {
      specification: Specification | null;
      use: DefaultFuelSurchargeUse | MachineFuelSurchargeUse | WorkTypeFuelSurchargeUse;
    }
  >();

  for (const machineUse of task.machineuseSet) {
    const machine = machineLookup(machineUse.machine);
    if (!machine) {
      continue;
    }
    const match = findFuelSurchargeUseAndSpecification(
      {
        customerUrl,
        machinePriceGroupUrl: machineUse.priceGroup,
        machineUrl: machineUse.machine,
        workTypePriceGroupUrl: task.priceGroup,
        workTypeUrl: task.workType,
      },
      machineFuelSurchargeUseArray,
      workTypeFuelSurchargeUseArray,
      defaultFuelSurchargeUseArray,
      fuelSurchargeSpecificationLookup,
    );
    if (match) {
      result.set(machineUse.machine, match);
    }
  }
  return result;
}
