import {CustomerUrl, ExpectedAmount, ExpectedAmountUrl, urlToId} from "@co-common-libs/resources";
import {getWorkTypeString} from "@co-common-libs/resources-utils";
import {DeleteDialog} from "@co-frontend-libs/components";
import {actions, getUnitLookup, getWorkTypeLookup} from "@co-frontend-libs/redux";
import {useCallWithFalse} from "@co-frontend-libs/utils";
import {
  Card,
  CardHeader,
  Fab,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {ExpectedAmountDialog} from "app-components";
import DeleteIcon from "mdi-react/DeleteIcon";
import PencilIcon from "mdi-react/PencilIcon";
import PlusIcon from "mdi-react/PlusIcon";
import React, {useCallback, useState} from "react";
import {FormattedMessage, defineMessages, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

const DATE_COLUMN_STYLE = {
  width: 80,
};
const REMINDER_COLUMN_STYLE = {
  width: 80,
};
const EDIT_COLUMN_STYLE = {
  width: 80,
};

const messages = defineMessages({
  worktypeBudget: {
    defaultMessage: "Områdebudget",
    id: "customer-instance.card-title.worktype-budget",
  },
});

interface ExpectedAmountRowProps {
  amount?: number | undefined;
  completed?: boolean;
  onClick: (url: ExpectedAmountUrl) => void;
  onDeleteClick: (url: ExpectedAmountUrl) => void;
  unit?: string;
  URL: ExpectedAmountUrl;
  workType?: string;
  year?: number | undefined;
}

const ExpectedAmountRow = React.memo(function ExpectedAmountRow(
  props: ExpectedAmountRowProps,
): JSX.Element {
  const {amount, completed, onClick, onDeleteClick, unit, URL, workType, year} = props;
  const handleEditClick = useCallback(() => {
    onClick(URL);
  }, [URL, onClick]);

  const handleDeleteClick = useCallback(() => {
    onDeleteClick(URL);
  }, [URL, onDeleteClick]);

  let completedBlock;
  if (completed) {
    completedBlock = <FormattedMessage defaultMessage="Ja" />;
  } else {
    completedBlock = <FormattedMessage defaultMessage="Nej" />;
  }

  return (
    <TableRow>
      <TableCell style={DATE_COLUMN_STYLE}>{year}</TableCell>
      <TableCell>{workType}</TableCell>
      <TableCell>
        {amount} {unit}
      </TableCell>
      <TableCell style={REMINDER_COLUMN_STYLE}>{completedBlock}</TableCell>
      <TableCell style={EDIT_COLUMN_STYLE}>
        <IconButton onClick={handleEditClick}>
          <PencilIcon />
        </IconButton>
      </TableCell>
      <TableCell style={EDIT_COLUMN_STYLE}>
        <IconButton onClick={handleDeleteClick}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
});

interface ExpectedAmountCardProps {
  customerURL: CustomerUrl;
  expectedAmountList: readonly ExpectedAmount[];
}

export const ExpectedAmountCard = React.memo(function ExpectedAmountCard(
  props: ExpectedAmountCardProps,
): JSX.Element {
  const [deleteExpectedAmountURL, setDeleteExpectedAmountURL] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const setOpenFalse = useCallWithFalse(setOpen);

  const [selectedExpectedAmountURL, setSelectedExpectedAmountURL] =
    useState<ExpectedAmountUrl | null>(null);
  const dispatch = useDispatch();

  const workTypeLookup = useSelector(getWorkTypeLookup);
  const unitLookup = useSelector(getUnitLookup);

  const handleAddExpectedAmountButton = useCallback(() => {
    setOpen(true);
    setSelectedExpectedAmountURL(null);
  }, []);

  const handleEdit = useCallback((url: ExpectedAmountUrl) => {
    setSelectedExpectedAmountURL(url);
    setOpen(true);
  }, []);

  const handleDeleteCancel = useCallback(() => {
    setDeleteExpectedAmountURL(null);
  }, []);

  const handleDeleteOk = useCallback(() => {
    const url = deleteExpectedAmountURL;
    if (url) {
      dispatch(actions.remove(url));
      setDeleteExpectedAmountURL(null);
    }
  }, [deleteExpectedAmountURL, dispatch]);

  const {formatMessage} = useIntl();

  return (
    <div>
      <Card>
        <div style={{position: "relative"}}>
          <Fab
            size="small"
            style={{
              position: "absolute",
              right: 16,
              top: 16,
            }}
            onClick={handleAddExpectedAmountButton}
          >
            <PlusIcon />
          </Fab>
        </div>
        <CardHeader title={formatMessage(messages.worktypeBudget)} />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={DATE_COLUMN_STYLE}>
                <FormattedMessage defaultMessage="År" id="expectedAmount-entry.table-header.year" />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Område"
                  id="expectedAmount-entry.table-header.work-type"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Mængde"
                  id="expectedAmount-entry.table-header.amount"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Udført"
                  id="expectedAmount-entry.table-header.completed"
                />
              </TableCell>
              <TableCell style={EDIT_COLUMN_STYLE} />
              <TableCell style={EDIT_COLUMN_STYLE} />
            </TableRow>
          </TableHead>
          <TableBody>
            {props.expectedAmountList.map((expectedAmount) => {
              const workTypeURL = expectedAmount.workType;
              const worktypeString = workTypeURL
                ? getWorkTypeString(workTypeLookup(workTypeURL))
                : "";

              const unitURL = expectedAmount.unit;
              const unit = unitURL ? unitLookup(unitURL) : null;
              const unitString = unit ? unit.name : "";

              return (
                <ExpectedAmountRow
                  key={urlToId(expectedAmount.url)}
                  amount={expectedAmount.amount || undefined}
                  completed={expectedAmount.completed}
                  unit={unitString}
                  URL={expectedAmount.url}
                  workType={worktypeString}
                  year={expectedAmount.year ?? undefined}
                  onClick={handleEdit}
                  onDeleteClick={setDeleteExpectedAmountURL}
                />
              );
            })}
          </TableBody>
        </Table>
      </Card>
      <ExpectedAmountDialog
        customer={props.customerURL}
        expectedAmountURL={selectedExpectedAmountURL || undefined}
        open={open}
        onCancel={setOpenFalse}
        onOk={setOpenFalse}
      />
      <DeleteDialog
        open={!!deleteExpectedAmountURL}
        onCancel={handleDeleteCancel}
        onOk={handleDeleteOk}
      >
        <FormattedMessage
          defaultMessage="Er du sikker på at du vil slette områdebudgettet?"
          id="expectedAmount-entry.delete"
        />
      </DeleteDialog>
    </div>
  );
});
