import {SyncedResourceHandleKey, SyncedResourceName} from "integration-constants";
import {kebabCase} from "lodash";
import {Branded} from "shared-types";

export type ApiUrl = Branded<string, "ApiUrl">;

const INTEGRATION_BASE = "/api/integration";

export function importResourceUrl(resource: SyncedResourceName): ApiUrl {
  return `${INTEGRATION_BASE}/import/${kebabCase(resource)}` as ApiUrl;
}

export function importPreviewUrl(resourceHandle: SyncedResourceHandleKey): ApiUrl {
  return `${INTEGRATION_BASE}/import-preview/${kebabCase(resourceHandle)}` as ApiUrl;
}

export const sendTasksUrl = `${INTEGRATION_BASE}/send-tasks` as ApiUrl;
export const sendOrderUrl = `${INTEGRATION_BASE}/send-order` as ApiUrl;
