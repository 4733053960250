import {PathTemplate} from "@co-frontend-libs/redux";
import {Badge, ListItem, ListItemIcon, ListItemText, SvgIcon} from "@material-ui/core";
import {SPACING, theme} from "frontend-global-config";
import React, {useCallback} from "react";

interface NavListItemProps {
  badgeCount?: number;
  Icon?: React.ComponentType<object> | undefined;
  onRequestChange: (route: PathTemplate) => void;
  route: PathTemplate;
  selected?: boolean;
  spacingLeft?: SPACING;
  text: string;
}

export const NavListItem = React.memo(function NavListItem(props: NavListItemProps): JSX.Element {
  const {badgeCount, Icon, onRequestChange, route, selected, spacingLeft, text} = props;

  const handleClick = useCallback(
    (event: React.MouseEvent<unknown, MouseEvent>): void => {
      event.preventDefault();
      onRequestChange(route);
    },
    [onRequestChange, route],
  );

  const baseIcon = Icon ? (
    <SvgIcon>
      <Icon />
    </SvgIcon>
  ) : undefined;
  const icon =
    baseIcon && badgeCount ? (
      <Badge badgeContent={badgeCount} color="primary">
        {baseIcon}
      </Badge>
    ) : (
      baseIcon
    );

  const spacingLeftStyle: React.CSSProperties = spacingLeft
    ? {
        paddingLeft: theme.spacing(spacingLeft),
      }
    : {};

  return (
    <ListItem button selected={selected ?? false} style={spacingLeftStyle} onClick={handleClick}>
      {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
      <ListItemText primary={text} />
    </ListItem>
  );
});
