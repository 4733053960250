import {Config} from "@co-common-libs/config";
import {
  Customer,
  Project,
  Task,
  Timer,
  TimerStart,
  WorkType,
  urlToId,
} from "@co-common-libs/resources";
import {formatDuration, getNormalisedDeviceTimestamp} from "@co-common-libs/resources-utils";
import {mapFilter, mapSome, setFilter, setMap} from "@co-common-libs/utils";
import {
  PathTemplate,
  actions,
  getCustomerLookup,
  getCustomerSettings,
  getMachineLookup,
  getOrderLookup,
  getProjectLookup,
  getTimerArray,
  getTimerStartArray,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {colorMap} from "@co-frontend-libs/utils";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {red} from "@material-ui/core/colors";
import {
  PureComponent,
  computeIntervalSums,
  computeIntervalsTruncated,
  getBreakTimer,
  getExternalSecondaryTimers,
  mapSum,
  mergeIntervals,
} from "app-utils";
import {bind} from "bind-decorator";
import _ from "lodash";
import CheckIcon from "mdi-react/CheckIcon";
import React, {useCallback} from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

const COLUMN_WIDTHS = {
  date: 150,
  initials: 120,
  machines: 80,
  minutes: 56,
  time: 90,
} as const;

interface DisplayDifferentProps {
  computed?: number;
  final?: number;
}

const DisplayDifferent = React.memo(function DisplayDifferent(
  props: DisplayDifferentProps,
): JSX.Element | null {
  const customerSettings = useSelector(getCustomerSettings);
  const computed = props.computed || 0;
  const final = props.final || 0;
  if (final !== computed) {
    return (
      <span>
        <br />({formatDuration(customerSettings.durationFormat, computed)})
      </span>
    );
  } else {
    return null;
  }
});

interface TaskRowProps {
  customer?: Customer | undefined;
  customerSettings: Config;
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  machines?: string;
  project?: Project | undefined;
  task: Task;
  timerArray: readonly Timer[];
  timerStartArray: readonly TimerStart[];
  workType?: WorkType | undefined;
}

class TaskRow extends PureComponent<TaskRowProps> {
  @bind
  handleClick(): void {
    const {go, task} = this.props;
    const id = urlToId(task.url);
    go("/task/:id", {id});
  }
  getTimerStarts(): TimerStart[] {
    const taskURL = this.props.task.url;
    return _.sortBy(
      this.props.timerStartArray.filter((instance) => instance.task === taskURL),
      getNormalisedDeviceTimestamp,
    );
  }
  render(): JSX.Element {
    const {
      customer,
      customerSettings,
      go,
      machines,
      project,
      task,
      timerArray,
      timerStartArray,
      workType,
      ...others
    } = this.props;
    let workTypeString;
    if (workType) {
      workTypeString = workType.name;
    }
    const now = new Date();
    now.setUTCMilliseconds(0);
    const timerStarts = this.getTimerStarts();
    const computedIntervals = task.recordedInC5
      ? task.computedTimeSet
      : computeIntervalsTruncated(timerStarts);
    const correctionIntervals = task.machineOperatorTimeCorrectionSet || [];
    const managerCorrectionIntervals = task.managerTimeCorrectionSet || [];
    const intervals = mergeIntervals(
      computedIntervals,
      correctionIntervals,
      managerCorrectionIntervals,
    );
    const timerMinutes = computeIntervalSums(intervals, now);
    const computedTimerMinutes = computeIntervalSums(computedIntervals, now);

    const isInternal = !task.order;

    const breakTimer = getBreakTimer(timerArray);
    const breakTimerURL = breakTimer ? breakTimer.url : null;
    const externalSecondaryTimers = getExternalSecondaryTimers(timerArray);
    const externalSecondaryTimerURLs = setMap(externalSecondaryTimers, (w) => w.url);

    let timeDifferent = false;
    const breakMinutes = (breakTimerURL && timerMinutes.get(breakTimerURL)) || 0;
    const computedBreakMinutes = (breakTimerURL && computedTimerMinutes.get(breakTimerURL)) || 0;
    timeDifferent = timeDifferent || breakMinutes !== computedBreakMinutes;
    let internalMinutes;
    let computedInternalMinutes;
    let externalPrimaryMinutes;
    let computedExternalPrimaryMinutes;
    if (isInternal) {
      internalMinutes = mapSum(mapFilter(timerMinutes, (_value, key) => key !== breakTimerURL));
      computedInternalMinutes = mapSum(
        mapFilter(computedTimerMinutes, (_value, key) => key !== breakTimerURL),
      );
      timeDifferent = timeDifferent || internalMinutes !== computedInternalMinutes;
      externalPrimaryMinutes = computedExternalPrimaryMinutes = 0;
    } else {
      internalMinutes = computedInternalMinutes = 0;
      // consider the "extra" timers part of the "primary" for the purpose of this presentation...
      externalPrimaryMinutes = mapSum(
        mapFilter(timerMinutes, (_val, key) => !externalSecondaryTimerURLs.has(key)),
      );
      computedExternalPrimaryMinutes = mapSum(
        mapFilter(computedTimerMinutes, (_val, key) => !externalSecondaryTimerURLs.has(key)),
      );
      timeDifferent = timeDifferent || externalPrimaryMinutes !== computedExternalPrimaryMinutes;
      timeDifferent =
        timeDifferent ||
        mapSome(
          timerMinutes,
          (value, key) => (value || 0) !== (computedTimerMinutes.get(key) || 0),
        );
    }
    const columnPadding: React.CSSProperties = {
      paddingLeft: "6px",
      paddingRight: "6px",
    };
    const minutesStyle: React.CSSProperties = {
      textAlign: "right",
      width: COLUMN_WIDTHS.minutes,
      ...columnPadding,
    };
    const secondaryTimerColumns = Array.from(externalSecondaryTimers)
      .filter((t) => !t.isBreak)
      .map((w) => {
        const timerURL = w.url;
        const minutes = timerMinutes.get(timerURL) || 0;
        const computedMinutes = computedTimerMinutes.get(timerURL) || 0;
        return (
          <TableCell key={timerURL} style={minutesStyle}>
            {formatDuration(customerSettings.durationFormat, minutes)}
            <DisplayDifferent computed={computedMinutes} final={minutes} />
          </TableCell>
        );
      });
    const totalRegistered = mapSum(timerMinutes);

    const customerName = customer ? customer.name : null;
    let projectString;
    if (
      (customerSettings.showProjectNumberOnTimeOverviewPage ||
        customerSettings.showProjectNameOnTimeOverviewPage) &&
      project
    ) {
      const {alias, name, projectNumber} = project;
      const nameAndOrAlias = customerSettings.showProjectNameOnTimeOverviewPage
        ? customerSettings.showProjectAlias && name && alias
          ? `${name}, ${alias}`
          : name || alias
        : "";
      projectString =
        customerSettings.showProjectNumberOnTimeOverviewPage &&
        customerSettings.showProjectNameOnTimeOverviewPage
          ? `${projectNumber}: ${nameAndOrAlias}`
          : nameAndOrAlias;
      if (!projectString && customerSettings.showProjectNumberOnTimeOverviewPage) {
        projectString = projectNumber ? projectNumber : "";
      }
    }
    const check = task.validatedAndRecorded ? <CheckIcon /> : null;
    let rowColor;
    if (timeDifferent) {
      rowColor = {
        color: red[500],
      };
    }
    const workplace = task.address || "";

    return (
      <TableRow {...others} style={{...rowColor}} onClick={this.handleClick}>
        <TableCell style={minutesStyle}>
          <strong style={timeDifferent ? {} : {color: colorMap.ACTIVITY_EFFECTIVE}}>
            {formatDuration(customerSettings.durationFormat, externalPrimaryMinutes)}
          </strong>
          <DisplayDifferent
            computed={computedExternalPrimaryMinutes}
            final={externalPrimaryMinutes}
          />
        </TableCell>
        {secondaryTimerColumns}
        <TableCell style={minutesStyle}>
          {formatDuration(customerSettings.durationFormat, internalMinutes)}
          <DisplayDifferent computed={computedInternalMinutes} final={internalMinutes} />
        </TableCell>
        <TableCell style={minutesStyle}>
          {formatDuration(customerSettings.durationFormat, breakMinutes)}
          <DisplayDifferent computed={computedBreakMinutes} final={breakMinutes} />
        </TableCell>
        <TableCell style={minutesStyle}>
          {formatDuration(customerSettings.durationFormat, totalRegistered)}
        </TableCell>
        <TableCell style={columnPadding}>{workTypeString}</TableCell>
        <TableCell style={columnPadding}>{customerName}</TableCell>
        {customerSettings.enableProjects &&
        (customerSettings.showProjectNumberOnTimeOverviewPage ||
          customerSettings.showProjectNameOnTimeOverviewPage) ? (
          <TableCell style={columnPadding}>{projectString}</TableCell>
        ) : null}
        <TableCell style={columnPadding}>{workplace}</TableCell>
        <TableCell style={{width: 80, ...columnPadding}}>{machines}</TableCell>
        <TableCell style={{minWidth: 58, width: 58, ...columnPadding}}>{check}</TableCell>
      </TableRow>
    );
  }
}

interface TaskTableProps {
  tasks: readonly Task[];
}

export const TaskTable = React.memo(function TaskTable(props: TaskTableProps): JSX.Element {
  const {tasks} = props;
  const dispatch = useDispatch();

  const boundGo = useCallback(
    (
      pathTemplate: PathTemplate,
      pathParameters?: PathParameters,
      queryParameters?: QueryParameters,
      navigationKind?: PartialNavigationKind,
    ): void => {
      dispatch(actions.go(pathTemplate, pathParameters, queryParameters, navigationKind));
    },
    [dispatch],
  );

  const customerSettings = useSelector(getCustomerSettings);
  const customerLookup = useSelector(getCustomerLookup);
  const machineLookup = useSelector(getMachineLookup);
  const orderLookup = useSelector(getOrderLookup);
  const projectLookup = useSelector(getProjectLookup);
  const timerArray = useSelector(getTimerArray);
  const workTypeLookup = useSelector(getWorkTypeLookup);
  const timerStartArray = useSelector(getTimerStartArray);

  const sortedTasks = _.sortBy(tasks, (task) => task.workFromTimestamp);
  const rows = sortedTasks.map((task) => {
    const taskURL = task.url;
    const orderURL = task.order;
    const order = typeof orderURL === "string" ? orderLookup(orderURL) : orderURL;
    const customerURL = order ? order.customer : null;
    const customer = typeof customerURL === "string" ? customerLookup(customerURL) : customerURL;
    const project = task.project ? projectLookup(task.project) : null;
    const workTypeURL = task.workType;
    const workType = typeof workTypeURL === "string" ? workTypeLookup(workTypeURL) : workTypeURL;
    const machines = (task.machineuseSet || [])
      .map((machineUse) => {
        const machineURL = machineUse.machine;
        const machine = typeof machineURL === "string" ? machineLookup(machineURL) : machineURL;
        if (machine) {
          return machine.c5_machine;
        } else {
          return "";
        }
      })
      .join(", ");
    return (
      <TaskRow
        key={taskURL}
        customer={customer || undefined}
        customerSettings={customerSettings}
        go={boundGo}
        machines={machines}
        project={project || undefined}
        task={task}
        timerArray={timerArray}
        timerStartArray={timerStartArray}
        workType={workType || undefined}
      />
    );
  });
  const noRowsMessage = (
    <div style={{padding: 16}}>
      <FormattedMessage
        defaultMessage="Der er ingen fuldførte opgaver tilhørende denne dag"
        tagName="h4"
      />
    </div>
  );

  const columnPadding: React.CSSProperties = {
    paddingLeft: "6px",
    paddingRight: "6px",
  };
  const minutesStyle: React.CSSProperties = {
    textAlign: "right",
    width: COLUMN_WIDTHS.minutes,
    ...columnPadding,
  };

  const externalSecondaryTimers = setFilter(
    getExternalSecondaryTimers(timerArray),
    (w) => !w.isBreak,
  );
  const truncateStyle: React.CSSProperties = {
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };
  const externalSecondaryTimerHeaders = Array.from(externalSecondaryTimers)
    .filter((w) => !w.isBreak)
    .map((workType) => {
      return (
        <TableCell key={workType.url} style={{...truncateStyle, ...minutesStyle}}>
          {workType.label}
        </TableCell>
      );
    });
  const table = (
    <Table>
      <TableHead
        style={{
          borderColor: "rgb(224, 224, 224)",
          borderStyle: "solid",
          borderWidth: "1px 0px 0px 0px",
        }}
      >
        <TableRow>
          <TableCell style={minutesStyle}>
            <strong style={{color: colorMap.ACTIVITY_EFFECTIVE}}>
              <FormattedMessage defaultMessage="Effektiv" />
            </strong>
          </TableCell>
          {externalSecondaryTimerHeaders}
          <TableCell style={minutesStyle}>
            <FormattedMessage defaultMessage="Intern" />
          </TableCell>
          <TableCell style={minutesStyle}>
            <FormattedMessage defaultMessage="Pause" />
          </TableCell>
          <TableCell style={minutesStyle}>
            <FormattedMessage defaultMessage="Total" />
          </TableCell>
          <TableCell style={{...truncateStyle, ...columnPadding}}>
            <FormattedMessage defaultMessage="Opgave" />
          </TableCell>
          <TableCell style={{...truncateStyle, ...columnPadding}}>
            <FormattedMessage defaultMessage="Kunde" />
          </TableCell>
          {customerSettings.enableProjects &&
          (customerSettings.showProjectNumberOnTimeOverviewPage ||
            customerSettings.showProjectNameOnTimeOverviewPage) ? (
            <TableCell style={{...truncateStyle, ...columnPadding}}>
              {customerSettings.projectLabelVariant === "PROJECT" ? (
                <FormattedMessage defaultMessage="Projekt" />
              ) : (
                <FormattedMessage defaultMessage="Sag" />
              )}
            </TableCell>
          ) : null}
          <TableCell style={{...truncateStyle, ...columnPadding}}>
            <FormattedMessage defaultMessage="Arbejdssted" />
          </TableCell>
          <TableCell style={{width: 80, ...truncateStyle, ...columnPadding}}>
            {customerSettings.machineLabelVariant === "MACHINE" ? (
              <FormattedMessage defaultMessage="Maskiner" />
            ) : (
              <FormattedMessage defaultMessage="Køretøjer" />
            )}
          </TableCell>
          <TableCell style={{minWidth: 58, width: 58, ...columnPadding}}>Bogf.</TableCell>
        </TableRow>
      </TableHead>
      <TableBody style={{cursor: "pointer"}}>{rows}</TableBody>
    </Table>
  );
  return <div>{rows.length ? table : noRowsMessage}</div>;
});
