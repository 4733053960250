import {getCustomerLookup} from "@co-frontend-libs/redux";
import {useTheme} from "@material-ui/core";
import {instanceURL} from "frontend-global-config";
import React from "react";
import {useSelector} from "react-redux";
import {SettingValueDisplayProps} from "../types";

export function DisplaySelectedCustomer(props: SettingValueDisplayProps): JSX.Element | null {
  const {settingEntry} = props;

  const theme = useTheme();
  const customerId: string = settingEntry?.data || "";

  const customerLookup = useSelector(getCustomerLookup);
  const customer = customerId ? customerLookup(instanceURL("customer", customerId)) : null;

  if (!customer) {
    return null;
  }
  return (
    <div>
      <span
        style={{
          backgroundColor: theme.palette.grey[200],
          padding: 2,
        }}
      >
        {customer.name}
      </span>
    </div>
  );
}
