import {MachineUrl, Task} from "@co-common-libs/resources";
import {machinePotentialPriceGroups} from "@co-common-libs/resources-utils";
import {ConnectedMachineDialogWithoutSmallMachines} from "@co-frontend-libs/connected-components";
import {
  getMachineLookup,
  getOrderLookup,
  getPriceGroupLookup,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import React, {useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {PriceGroupDialog} from "./price-group-dialog";

interface MachinePriceGroupWizardProps {
  onCancel(): void;
  onOk(machineURL: string, priceGroupURL: string | null): void;
  open: boolean;
  task: Task;
}

export function MachinePriceGroupWizard(props: MachinePriceGroupWizardProps): JSX.Element {
  const {onCancel, onOk, open: propsOpen, task} = props;

  const workTypeLookup = useSelector(getWorkTypeLookup);
  const orderLookup = useSelector(getOrderLookup);
  const machineLookup = useSelector(getMachineLookup);
  const priceGroupLookup = useSelector(getPriceGroupLookup);

  const workType = task.workType ? workTypeLookup(task.workType) : undefined;
  const priceGroup = task.priceGroup ? priceGroupLookup(task.priceGroup) : undefined;
  const orderURL = task.order;
  const order = orderURL ? orderLookup(orderURL) : undefined;
  const customerURL = order?.customer;

  const [priceGroupSelectionMachineURL, setPriceGroupSelectionMachineURL] = useState<
    MachineUrl | undefined
  >();
  const [open, setOpen] = useState<boolean>(propsOpen);

  useEffect(() => {
    setOpen(propsOpen);
    if (propsOpen) {
      setPriceGroupSelectionMachineURL(undefined);
    }
  }, [propsOpen]);

  const machineDialogOpen = open && !priceGroupSelectionMachineURL;
  const priceGroupDialogOpen = open && !!priceGroupSelectionMachineURL;

  const handleMachineDialogOk = useCallback(
    (machineURL: MachineUrl): void => {
      if (
        task.machineuseSet &&
        task.machineuseSet.some((machineUse) => machineUse.machine === machineURL)
      ) {
        onCancel();
        return;
      }
      const potentialPriceGroups = machinePotentialPriceGroups(
        machineURL,
        customerURL || null,
        machineLookup,
        priceGroupLookup,
      );
      if (potentialPriceGroups.length === 0) {
        onOk(machineURL, null);
      } else if (potentialPriceGroups.length === 1) {
        onOk(machineURL, potentialPriceGroups[0].url);
      } else {
        console.assert(potentialPriceGroups.length > 1);
        setPriceGroupSelectionMachineURL(machineURL);
      }
    },
    [customerURL, machineLookup, onCancel, onOk, priceGroupLookup, task.machineuseSet],
  );

  const handlePriceGroupDialogOk = useCallback(
    (priceGroupURL: string): void => {
      if (priceGroupSelectionMachineURL) {
        onOk(priceGroupSelectionMachineURL, priceGroupURL);
      }
    },
    [onOk, priceGroupSelectionMachineURL],
  );

  return (
    <>
      <ConnectedMachineDialogWithoutSmallMachines
        open={machineDialogOpen}
        priceGroup={priceGroup}
        workType={workType}
        onCancel={onCancel}
        onOk={handleMachineDialogOk}
      />
      <PriceGroupDialog
        customerURL={customerURL || undefined}
        machineURL={priceGroupSelectionMachineURL}
        open={priceGroupDialogOpen}
        onCancel={onCancel}
        onOk={handlePriceGroupDialogOk}
      />
    </>
  );
}
