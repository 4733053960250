import {ResponsiveDialog} from "@co-frontend-libs/components";
import {getCustomerSettings} from "@co-frontend-libs/redux";
import {DialogContent} from "@material-ui/core";
import React from "react";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";

interface MissingMachineOperatorDialogProps {
  onOk: () => void;
  open: boolean;
}

export function MissingMachineOperatorDialog(
  props: MissingMachineOperatorDialogProps,
): JSX.Element {
  const {onOk, open} = props;
  const customerSettings = useSelector(getCustomerSettings);

  let title: JSX.Element;
  let content: JSX.Element;
  if (customerSettings.employeeLabelVariant === "MACHINEOPERATOR") {
    title = <FormattedMessage defaultMessage="Mangler maskinfører" />;
    content = (
      <FormattedMessage defaultMessage="Der er ikke maskinførere på en eller flere opgaver" />
    );
  } else if (customerSettings.employeeLabelVariant === "EMPLOYEE") {
    title = <FormattedMessage defaultMessage="Mangler medarbejder" />;
    content = (
      <FormattedMessage defaultMessage="Der er ikke medarbejdere på en eller flere opgaver" />
    );
  } else {
    title = <FormattedMessage defaultMessage="Mangler chauffør" />;
    content = <FormattedMessage defaultMessage="Der er ikke chaufføre på en eller flere opgaver" />;
  }
  return (
    <ResponsiveDialog open={open} title={title} onOk={onOk}>
      <DialogContent>{content}</DialogContent>
    </ResponsiveDialog>
  );
}
