import {ImportPreviewProductGroup} from "@co-common-libs/resources-utils";
import {
  ErrorDialog,
  SpinnerDialog,
  VerticalStackingFloatingActionButton,
} from "@co-frontend-libs/components";
import {
  actions,
  getCurrentlyFetchingChanges,
  getCustomerSettings,
  getProductGroupArray,
} from "@co-frontend-libs/redux";
import {
  jsonFetch,
  translateNetworkError,
  useCallWithFalse,
  useCallWithTrue,
} from "@co-frontend-libs/utils";
import {importResourceUrl} from "api-endpoint-urls";
import CloudDownloadIcon from "mdi-react/CloudDownloadIcon";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {ImportFabProps2} from "../import-fab-types";
import {ImportProductGroupsDialog} from "./import-product-groups-dialog";

export const ImportProductGroupsFab = React.memo(function ImportProductGroupsFab(
  props: ImportFabProps2,
): React.JSX.Element {
  const {stackIndex} = props;

  const [importDialogOpen, setImportDialogOpen] = useState(false);
  const setImportDialogOpenTrue = useCallWithTrue(setImportDialogOpen);
  const setImportDialogOpenFalse = useCallWithFalse(setImportDialogOpen);

  const intl = useIntl();

  const [spinnerDialogOpen, setSpinnerDialogOpen] = useState(false);
  const setSpinnerDialogOpenFalse = useCallWithFalse(setSpinnerDialogOpen);

  const currentlyFetching = useSelector(getCurrentlyFetchingChanges);
  const [refreshPendingTimeout, setRefreshPendingTimeout] = useState(false);
  const [saving, setSaving] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const {economicSync} = useSelector(getCustomerSettings);

  useEffect(() => {
    if (!currentlyFetching && !refreshPendingTimeout && spinnerDialogOpen && !saving) {
      setSpinnerDialogOpen(false);
    }
  }, [
    currentlyFetching,
    refreshPendingTimeout,
    saving,
    setSpinnerDialogOpenFalse,
    spinnerDialogOpen,
  ]);

  const handleOk = useCallback(
    async (productGroups: ImportPreviewProductGroup[]) => {
      setImportDialogOpen(false);
      setSpinnerDialogOpen(true);
      setSaving(true);

      try {
        // TODO(mr): refactor to `useAsync` (removes need for spinnerDialogOpen and errorMessage states)
        const response = await jsonFetch(importResourceUrl("productGroup"), "POST", {
          productGroups: productGroups.map((pg) => ({
            identifier: pg.identifier,
            remoteUrl: pg.remoteUrl,
          })),
        });

        // TODO(mr): econ require re-fetch until re-written as the endpoint does not return created products
        if (economicSync) {
          setRefreshPendingTimeout(true);
          window.setTimeout(() => {
            setRefreshPendingTimeout(false);
          }, 1000);
          dispatch(actions.requestChangesFetch());
        } else {
          dispatch(actions.addToOffline(response.data));
        }

        setSaving(false);
      } catch (error) {
        setSaving(false);
        setSpinnerDialogOpen(false);
        setErrorMessage(translateNetworkError(error, intl));
      }

      window.setTimeout(
        () => {
          setSpinnerDialogOpen(false);
        },
        economicSync ? 1000 : 0,
      );
    },
    [economicSync, dispatch, intl, setSpinnerDialogOpen],
  );

  const handleErrorMessageOk = useCallback(() => {
    setErrorMessage("");
    setSpinnerDialogOpen(false);
  }, [setSpinnerDialogOpen]);

  const productGroupArray = useSelector(getProductGroupArray);

  const existingProductGroupRemoteUrls = useMemo(
    () => new Set(productGroupArray.map((w) => w.remoteUrl)),
    [productGroupArray],
  );

  return (
    <>
      <VerticalStackingFloatingActionButton
        stackIndex={stackIndex}
        onClick={setImportDialogOpenTrue}
      >
        <CloudDownloadIcon />
      </VerticalStackingFloatingActionButton>
      <ImportProductGroupsDialog
        filter={existingProductGroupRemoteUrls}
        importType="PRODUCT_GROUP"
        open={importDialogOpen}
        onCancel={setImportDialogOpenFalse}
        onOk={handleOk}
      />
      <SpinnerDialog
        open={spinnerDialogOpen}
        title={intl.formatMessage({defaultMessage: "Importere varegrupper"})}
      />
      <ErrorDialog
        message={errorMessage}
        open={!!errorMessage}
        title={intl.formatMessage({defaultMessage: "Import fejlede"})}
        onOk={handleErrorMessageOk}
      />
    </>
  );
});
