import {Contact, Customer} from "@co-common-libs/resources";
import {formatAddress, makeMapFromArray, simpleCompare} from "@co-common-libs/utils";
import {createSelector} from "reselect";
import {getContactArray, getCustomerArray} from "../resources/selectors";
import {AppState} from "../root-reducer";
import {filterOnActive} from "./utils";

export const getCustomerURLToDefaultContactMap: (state: AppState) => ReadonlyMap<string, Contact> =
  createSelector(getContactArray, (contactArray) =>
    makeMapFromArray(
      contactArray.filter((contact) => contact.defaultContact),
      (contact) => contact.customer,
    ),
  );

function customerComparator(customerA: Customer, customerB: Customer): -1 | 0 | 1 {
  const aFavorite = customerA.favorite;
  const bFavorite = customerB.favorite;
  if (aFavorite && !bFavorite) {
    return -1;
  } else if (!aFavorite && bFavorite) {
    return 1;
  } else {
    return (
      simpleCompare(customerA.name, customerB.name) ||
      simpleCompare(formatAddress(customerA), formatAddress(customerB)) ||
      simpleCompare(customerA.url, customerB.url)
    );
  }
}

function sortedCustomerArray(customerArray: readonly Customer[]): readonly Customer[] {
  return customerArray.slice().sort(customerComparator);
}

function filterOnNotMerged(customerArray: readonly Customer[]): readonly Customer[] {
  return customerArray.filter(({mergedTo}) => !mergedTo);
}

const getSortedCustomerArray: (state: AppState) => readonly Customer[] = createSelector(
  getCustomerArray,
  sortedCustomerArray,
);

export const getSortedActiveAndInactiveCustomerArray: (state: AppState) => readonly Customer[] =
  createSelector(getSortedCustomerArray, filterOnNotMerged);

// merged customers are inactive, no need to filter on merged here
export const getSortedActiveCustomerArray: (state: AppState) => readonly Customer[] =
  createSelector(getSortedCustomerArray, filterOnActive);

// merged customers are inactive, no need to filter on merged here
export const getActiveCustomerArray: (state: AppState) => readonly Customer[] = createSelector(
  getCustomerArray,
  filterOnActive,
);

export const getActiveAndInactiveCustomerArray: (state: AppState) => readonly Customer[] =
  createSelector(getCustomerArray, filterOnNotMerged);
