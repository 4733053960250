import {MINUTE_MILLISECONDS, objectSet} from "@co-common-libs/utils";
import {computeIntervalDurationMilliseconds} from "./duration";
import {splitInterval} from "./special-start-rate";
import {Rate, TaskIntervalWithRate, WorkDay} from "./types";

export function processWorkDayPaidTransportSpecialCase(
  workDay: WorkDay,
  paidTransportWorkType: string,
  normalTransportMinutes: number,
): WorkDay {
  let remainingInitialUnpaidTransportTime = normalTransportMinutes * MINUTE_MILLISECONDS;
  const wordaysWithInitialUnpaidProcessed = workDay.workPeriods.map((workPeriod) => {
    if (!remainingInitialUnpaidTransportTime) {
      return workPeriod;
    }
    const inputWorkIntervals = workPeriod.work;
    const work: TaskIntervalWithRate[] = [];
    for (let i = 0; i < inputWorkIntervals.length; i += 1) {
      const inputInterval = inputWorkIntervals[i];
      if (
        remainingInitialUnpaidTransportTime &&
        inputInterval.taskData.some((taskData) => taskData.workTypeURL === paidTransportWorkType)
      ) {
        const inputIntervalDuration = computeIntervalDurationMilliseconds(inputInterval);
        if (inputIntervalDuration <= remainingInitialUnpaidTransportTime) {
          work.push(objectSet(inputInterval, "rate", Rate.UNPAID));
          remainingInitialUnpaidTransportTime -= inputIntervalDuration;
        } else {
          console.assert(inputIntervalDuration > remainingInitialUnpaidTransportTime);
          const splitDate = new Date(inputInterval.fromTimestamp);
          splitDate.setUTCMilliseconds(
            splitDate.getUTCMilliseconds() + remainingInitialUnpaidTransportTime,
          );
          const [beforeSplit, afterSplit] = splitInterval(inputInterval, splitDate.toISOString());
          work.push(objectSet(beforeSplit, "rate", Rate.UNPAID));
          work.push(afterSplit);
          remainingInitialUnpaidTransportTime = 0;
        }
      } else {
        remainingInitialUnpaidTransportTime = 0;
        work.push(inputInterval);
      }
    }
    return objectSet(workPeriod, "work", work);
  });
  let remainingFinalUnpaidTransportTime = normalTransportMinutes * MINUTE_MILLISECONDS;
  wordaysWithInitialUnpaidProcessed.reverse();
  // we process work periods in reverse order...
  const workPeriods = wordaysWithInitialUnpaidProcessed.map((workPeriod) => {
    if (!remainingFinalUnpaidTransportTime) {
      return workPeriod;
    }
    const inputWorkIntervals = workPeriod.work;
    const work: TaskIntervalWithRate[] = [];
    // we process intervals in reverse order...
    for (let i = inputWorkIntervals.length - 1; i >= 0; i -= 1) {
      const inputInterval = inputWorkIntervals[i];
      if (
        remainingFinalUnpaidTransportTime &&
        inputInterval.taskData.some((taskData) => taskData.workTypeURL === paidTransportWorkType)
      ) {
        const inputIntervalDuration = computeIntervalDurationMilliseconds(inputInterval);
        if (inputIntervalDuration <= remainingFinalUnpaidTransportTime) {
          work.push(objectSet(inputInterval, "rate", Rate.UNPAID));
          remainingFinalUnpaidTransportTime -= inputIntervalDuration;
        } else {
          console.assert(inputIntervalDuration > remainingFinalUnpaidTransportTime);
          const splitDate = new Date(inputInterval.toTimestamp);
          splitDate.setUTCMilliseconds(
            splitDate.getUTCMilliseconds() - remainingFinalUnpaidTransportTime,
          );
          const [beforeSplit, afterSplit] = splitInterval(inputInterval, splitDate.toISOString());
          work.push(objectSet(afterSplit, "rate", Rate.UNPAID));
          work.push(beforeSplit);
          remainingFinalUnpaidTransportTime = 0;
        }
      } else {
        remainingFinalUnpaidTransportTime = 0;
        work.push(inputInterval);
      }
    }
    work.reverse();
    return objectSet(workPeriod, "work", work);
  });
  workPeriods.reverse();
  return {
    ...workDay,
    workPeriods,
  };
}

export function processPaidTransportSpecialCase(
  workDays: readonly WorkDay[],
  paidTransportWorkType: string,
  normalTransportMinutes: number,
): readonly WorkDay[] {
  return workDays.map((workDay) =>
    processWorkDayPaidTransportSpecialCase(workDay, paidTransportWorkType, normalTransportMinutes),
  );
}
