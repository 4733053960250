import {SECOND_MILLISECONDS, formatDateTime} from "@co-common-libs/utils";
import {getCustomerSettings} from "@co-frontend-libs/redux";
import {NetworkError, jsonFetch} from "@co-frontend-libs/utils";
import {Button, SvgIcon} from "@material-ui/core";
import {MenuToolbar, PageLayout} from "app-components";
import {globalConfig} from "frontend-global-config";
import CloudDownloadIcon from "mdi-react/CloudDownloadIcon";
import CloudUploadIcon from "mdi-react/CloudUploadIcon";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {FormattedMessage, defineMessages, useIntl} from "react-intl";
import {useSelector} from "react-redux";

const messages = defineMessages({
  downloadFromNavision: {
    defaultMessage: "Hent data fra NAV",
    id: "navision-sync.label.download-from-navision",
  },
  pageTitle: {
    defaultMessage: "Navision-Sync",
    id: "navision-sync.title.navision-sync",
  },
  uploadToNavision: {
    defaultMessage: "Overfør til NAV",
    id: "navision-sync.label.upload-to-navision",
  },
});

const CHECK_SECONDS = 5;
const CHECK_MILLISECONDS = CHECK_SECONDS * SECOND_MILLISECONDS;

export const NavisionSync = React.memo(function NavisionSync({
  onMenuButton,
}: {
  onMenuButton: (event: React.MouseEvent) => void;
}): JSX.Element {
  const [downloadDoneTimestamp, setDownloadDoneTimestamp] = useState<string | null>(null);
  const [downloadError, setDownloadError] = useState<Error | null>(null);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [downloadStartTimestamp, setDownloadStartTimestamp] = useState<string | null>(null);

  const [uploadDoneTimestamp, setUploadDoneTimestamp] = useState<string | null>(null);
  const [uploadStartTimestamp, setUploadStartTimestamp] = useState<string | null>(null);
  const [uploadError, setUploadError] = useState<Error | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);

  const unmounting = useRef(false);
  const intl = useIntl();
  const customerSettings = useSelector(getCustomerSettings);

  useEffect(() => {
    return () => {
      unmounting.current = true;
    };
  }, []);

  useEffect(() => {
    let uploadCheckTimeoutID: number | null = null;
    const handleCheckUpload = (): void => {
      if (unmounting.current) {
        return;
      }
      const {baseURL} = globalConfig.resources;
      const url = `${baseURL}nav/request_upload/`;
      jsonFetch(url, "GET")
        .then((response) => {
          if (unmounting.current) {
            return;
          }
          const {data} = response;
          const {doneTimestamp, error, startTimestamp} = data;
          setUploadError(error);
          setUploadDoneTimestamp(doneTimestamp);
          setUploadStartTimestamp(startTimestamp);
          const stillUploading = !!startTimestamp && !doneTimestamp;
          setUploading(stillUploading);
          return;
        })
        .catch((reason) => {
          if (unmounting.current) {
            return;
          }
          setUploadError(reason);
          setUploading(false);
          setUploadDoneTimestamp(null);
          setUploadStartTimestamp(null);
          return;
        })
        .then(() => {
          if (!unmounting.current) {
            uploadCheckTimeoutID = window.setTimeout(handleCheckUpload, CHECK_MILLISECONDS);
          }
          return;
        })
        .catch(() => {
          // impossible
        });
    };
    handleCheckUpload();
    return () => {
      if (uploadCheckTimeoutID) {
        window.clearTimeout(uploadCheckTimeoutID);
      }
    };
  }, []);

  useEffect(() => {
    let downloadCheckTimeoutID: number | null = null;
    const handleCheckDownload = (): void => {
      const {baseURL} = globalConfig.resources;
      const url = `${baseURL}nav/request_download/`;
      jsonFetch(url, "GET")
        .then((response) => {
          if (unmounting.current) {
            return;
          }
          const {data} = response;
          const {doneTimestamp, error, startTimestamp} = data;
          setDownloadDoneTimestamp(doneTimestamp);
          setDownloadError(error);
          setDownloading(!!startTimestamp && !doneTimestamp);
          setDownloadStartTimestamp(startTimestamp);
          return;
        })
        .catch((reason) => {
          if (unmounting.current) {
            return;
          }
          setDownloadDoneTimestamp(null);
          setDownloadError(reason);
          setDownloading(false);
          setDownloadStartTimestamp(null);
          return;
        })
        .then(() => {
          if (!unmounting.current) {
            downloadCheckTimeoutID = window.setTimeout(handleCheckDownload, CHECK_MILLISECONDS);
          }
          return;
        })
        .catch(() => {
          // impossible
        });
    };
    handleCheckDownload();
    return () => {
      if (downloadCheckTimeoutID) {
        window.clearTimeout(downloadCheckTimeoutID);
      }
    };
  }, []);

  const handleUploadToNavision = useCallback(() => {
    const {baseURL} = globalConfig.resources;
    const url = `${baseURL}nav/request_upload/`;
    setUploadError(null);
    setUploading(true);
    jsonFetch(url, "POST")
      .then((response) => {
        if (unmounting.current) {
          return;
        }
        const {data} = response;
        const {doneTimestamp, error, startTimestamp} = data;

        setUploadError(error);
        setUploadDoneTimestamp(doneTimestamp);
        setUploadStartTimestamp(startTimestamp);
        const stillUploading = !!startTimestamp && !doneTimestamp;
        setUploading(stillUploading);

        return;
      })
      .catch((reason) => {
        if (unmounting.current) {
          return;
        }
        setUploadDoneTimestamp(null);
        setUploadStartTimestamp(null);
        setUploadError(reason);
        setUploading(false);
      });
  }, []);

  const handleDownloadFromNavision = useCallback(() => {
    const {baseURL} = globalConfig.resources;
    const url = `${baseURL}nav/request_download/`;
    setDownloadError(null);
    setDownloading(true);
    jsonFetch(url, "POST")
      .then((response) => {
        if (unmounting.current) {
          return;
        }
        const {data} = response;
        const {doneTimestamp, startTimestamp} = data;
        setDownloadError(null);
        setDownloading(!!startTimestamp && !doneTimestamp);
        setDownloadStartTimestamp(startTimestamp);
        setDownloadDoneTimestamp(doneTimestamp);
        return;
      })
      .catch((reason) => {
        if (unmounting.current) {
          return;
        }
        setDownloadDoneTimestamp(null);
        setDownloadError(reason);
        setDownloading(false);
        setDownloadStartTimestamp(null);
      });
  }, []);
  const uploadIconClassName = uploading ? "pulse" : "";
  const uploadIcon = (
    <SvgIcon className={uploadIconClassName}>
      <CloudUploadIcon />
    </SvgIcon>
  );
  const downloadIconClassName = downloading ? "pulse" : "";
  const downloadIcon = (
    <SvgIcon className={downloadIconClassName}>
      <CloudDownloadIcon />
    </SvgIcon>
  );
  let uploadErrorBlock;
  if (uploadError && uploadError instanceof NetworkError) {
    uploadErrorBlock = (
      <div>
        <FormattedMessage defaultMessage="Fejl ved upload: Kunne ikke forbinde til server." />
      </div>
    );
  } else if (uploadError) {
    uploadErrorBlock = (
      <div>
        <FormattedMessage defaultMessage="Fejl ved upload." />
      </div>
    );
  }
  let downloadErrorBlock;
  if (downloadError) {
    if (downloadError instanceof NetworkError) {
      downloadErrorBlock = (
        <div>
          <FormattedMessage defaultMessage="Fejl ved download: Kunne ikke forbinde til server." />
        </div>
      );
    } else {
      downloadErrorBlock = (
        <FormattedMessage
          defaultMessage="Fejl ved download: {error}."
          tagName="div"
          values={{error: downloadError.message}}
        />
      );
    }
  }

  let downloadStatusBlock;
  if (downloadStartTimestamp) {
    if (downloadDoneTimestamp) {
      downloadStatusBlock = (
        <FormattedMessage
          defaultMessage="Sidste download startet: {start}, fuldført: {done}"
          tagName="div"
          values={{
            done: formatDateTime(downloadDoneTimestamp),
            start: formatDateTime(downloadStartTimestamp),
          }}
        />
      );
    } else {
      downloadStatusBlock = (
        <FormattedMessage
          defaultMessage="Sidste download startet: {start}"
          tagName="div"
          values={{start: formatDateTime(downloadStartTimestamp)}}
        />
      );
    }
  }

  let uploadStatusBlock;
  if (uploadStartTimestamp) {
    if (uploadDoneTimestamp) {
      uploadStatusBlock = (
        <FormattedMessage
          defaultMessage="Sidste upload startet: {start}, fuldført: {done}"
          tagName="div"
          values={{
            done: formatDateTime(uploadDoneTimestamp),
            start: formatDateTime(uploadStartTimestamp),
          }}
        />
      );
    } else {
      uploadStatusBlock = (
        <FormattedMessage
          defaultMessage="Sidste upload startet: {start}"
          tagName="div"
          values={{start: formatDateTime(uploadStartTimestamp)}}
        />
      );
    }
  }

  return (
    <PageLayout
      withPadding
      toolbar={
        <MenuToolbar title={intl.formatMessage(messages.pageTitle)} onMenuButton={onMenuButton} />
      }
    >
      <div style={{marginBottom: 24, textAlign: "center"}}>
        <Button
          color="primary"
          disabled={downloading}
          startIcon={downloadIcon}
          style={{width: 200}}
          variant="contained"
          onClick={handleDownloadFromNavision}
        >
          {intl.formatMessage(messages.downloadFromNavision)}
        </Button>
        {downloadStatusBlock}
        {downloadErrorBlock}
      </div>
      {customerSettings.navSyncUploadEnabled &&
      (customerSettings.navSyncProfile === "dme" ||
        customerSettings.navSyncProfile === "maanssons") ? (
        <div style={{textAlign: "center"}}>
          <Button
            color="primary"
            disabled={uploading}
            startIcon={uploadIcon}
            style={{width: 200}}
            variant="contained"
            onClick={handleUploadToNavision}
          >
            {intl.formatMessage(messages.uploadToNavision)}
          </Button>
          {uploadErrorBlock}
          {uploadStatusBlock}
        </div>
      ) : null}
    </PageLayout>
  );
});
