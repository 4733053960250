import {PatchOperation, Task} from "@co-common-libs/resources";
import {actions, getCustomerSettings} from "@co-frontend-libs/redux";
import {useTheme} from "@material-ui/core";
import {green, grey} from "@material-ui/core/colors";
import {instanceURL} from "frontend-global-config";
import React, {useMemo} from "react";
import {DropTargetMonitor, useDrop} from "react-dnd";
import {useDispatch, useSelector} from "react-redux";

export const PlannedLineDrop = ({
  children,
  style,
}: {
  children?: JSX.Element;
  style: React.CSSProperties;
}): JSX.Element => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const customerSettings = useSelector(getCustomerSettings);
  const [taskDropCollectedProps, taskDrop] = useDrop({
    accept: "task",
    canDrop(_item: any, _monitor: DropTargetMonitor) {
      return true;
    },
    collect: (monitor: DropTargetMonitor) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
    }),
    drop(task: Task, _monitor: DropTargetMonitor) {
      const patch: PatchOperation<Task>[] = [];
      if (task.time) {
        patch.push({
          member: "time",
          value: null,
        });
      }
      if (task.date) {
        patch.push({
          member: "date",
          value: null,
        });
      }
      if (task.machineOperator) {
        const newValue = customerSettings.defaultTaskEmployee
          ? instanceURL("user", customerSettings.defaultTaskEmployee)
          : null;
        if (task.machineOperator !== newValue) {
          patch.push({
            member: "machineOperator",
            value: newValue,
          });
        }
      }
      if (patch.length) {
        dispatch(actions.update(task.url, patch));
      }
    },
  });

  const styleWithBackgroundColor = useMemo(() => {
    const {canDrop, isOver} = taskDropCollectedProps;
    const backgroundColor = canDrop && isOver ? green[100] : grey[200];
    return {
      ...style,
      backgroundColor,
      color: theme.palette.getContrastText(backgroundColor),
    };
  }, [style, taskDropCollectedProps, theme.palette]);

  return (
    <div ref={taskDrop} style={styleWithBackgroundColor}>
      {children}
    </div>
  );
};
