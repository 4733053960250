import {Role} from "@co-common-libs/resources";
import {
  ActiveEconomySystemIntegrationKind,
  getEconomySystemIntegrationKind,
} from "../get-economy-system-integration-kind";
import {ExtendedCustomerSettingsPart} from "./extended-customer-settings";

type ExtendedCsvSettingsPart = ExtendedCustomerSettingsPart;

export interface ExtendedCsvSettings {
  canExport: boolean;
  canImport: boolean;
}

export function extendedCsvSettings(
  config: ExtendedCsvSettingsPart,
  role: Pick<Role, "consultant"> | null,
): ExtendedCsvSettings {
  const {economicSync} = config;

  const economySystemIntegrationKind = getEconomySystemIntegrationKind(config);

  return {
    canExport: Boolean(role?.consultant),
    canImport: Boolean(
      role?.consultant &&
        (economySystemIntegrationKind === ActiveEconomySystemIntegrationKind.NONE || economicSync),
    ),
  };
}
