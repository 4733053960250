import {Config} from "@co-common-libs/config";
import {PatchUnion, Settings} from "@co-common-libs/resources";
import {AppState, actions, getCustomerSettings, getSettingsArray} from "@co-frontend-libs/redux";
import {FormControlLabel, Paper, Switch, SwitchProps} from "@material-ui/core";
import {PureComponent, checkedModelUpdaterFactoryFactory} from "app-utils";
import {bind} from "bind-decorator";
import bowser from "bowser";
import React from "react";
// Allowed for existing code...
// eslint-disable-next-line deprecate/import
import {Cell, Grid} from "react-flexr";
import {FormattedMessage, IntlContext, defineMessages} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";

const messages = defineMessages({
  dinnerBookingActive: {
    defaultMessage: "Aftensmadsbestilling",
    id: "dinner-booking-settings.option.dinnerbooking-active",
  },
  lunchBookingActive: {
    defaultMessage: "Frokostbestilling",
    id: "lunch-booking-settings.option.lunchbooking-active",
  },
});

interface FoodBookingsStateProps {
  customerSettings: Config;
  settingsArray: readonly Settings[];
}

interface FoodBookingsDispatchProps {
  update: (url: string, patch: PatchUnion) => void;
}

type FoodBookingsProps = FoodBookingsDispatchProps & FoodBookingsStateProps;

class FoodBookings extends PureComponent<FoodBookingsProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  makeCheckedModelUpdater = checkedModelUpdaterFactoryFactory<Settings>(this.props.update);

  @bind
  handleDinnerBookingChanged(event: React.ChangeEvent<HTMLInputElement>): void {
    const {checked} = event.target;
    const settings = this.props.settingsArray[0];
    if (checked) {
      this.props.update(settings.url, [{member: "dinnerBookingsActive", value: checked}]);
    } else {
      this.props.update(settings.url, [
        {member: "dinnerBookingsActive", value: checked},
        {member: "lunchBookingsActive", value: false},
      ]);
    }
  }

  render(): JSX.Element {
    const {formatMessage} = this.context;
    const settings = this.props.settingsArray[0];
    // Grid/Cell types do not accept style prop ...?
    const CellNoType = Cell as any;

    const lunchbookingSwitchOptionalHandleChangeProps: SwitchProps = {};
    if (settings.dinnerBookingsActive) {
      lunchbookingSwitchOptionalHandleChangeProps.onChange = this.makeCheckedModelUpdater(
        settings.url,
        "lunchBookingsActive",
      );
    }

    return (
      <Paper
        style={{
          height: bowser.mobile ? "auto" : "calc(100% - 64px)",
          margin: 5,
          padding: 10,
        }}
      >
        <Grid>
          <CellNoType palm="12/12" style={{paddingBottom: 11, paddingTop: 11}}>
            <FormControlLabel
              control={
                <Switch
                  checked={!!settings.dinnerBookingsActive}
                  onChange={this.handleDinnerBookingChanged}
                />
              }
              label={formatMessage(messages.dinnerBookingActive)}
            />
            {this.props.customerSettings.lunchBookings ? (
              <div>
                <FormControlLabel
                  control={
                    <Switch
                      checked={!!settings.lunchBookingsActive}
                      {...lunchbookingSwitchOptionalHandleChangeProps}
                    />
                  }
                  disabled={lunchbookingSwitchOptionalHandleChangeProps.disabled}
                  label={formatMessage(messages.lunchBookingActive)}
                />
                <FormattedMessage
                  defaultMessage="Frokostbestilling kan kun aktiveres hvis aftensmadsbestilling er aktiv"
                  id="lunch-booking-settings.option.message"
                  tagName="i"
                />
              </div>
            ) : null}
          </CellNoType>
        </Grid>
      </Paper>
    );
  }
}

const ConnectedFoodBookings = connect<
  FoodBookingsStateProps,
  FoodBookingsDispatchProps,
  object,
  AppState
>(
  createStructuredSelector<AppState, FoodBookingsStateProps>({
    customerSettings: getCustomerSettings,
    settingsArray: getSettingsArray,
  }),
  {
    update: actions.update,
  },
)(FoodBookings);

export {ConnectedFoodBookings as FoodBookings};
