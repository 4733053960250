import {SerializableError} from "@co-frontend-libs/db-resources";
import {NetworkError, StatusError} from "@co-frontend-libs/utils";

export function buildErrorObject(error: unknown): SerializableError {
  if (error instanceof Error) {
    const {message, name} = error;
    if (error instanceof StatusError) {
      return {
        data: error.data,
        message,
        name,
        statusCode: error.status,
        type: "StatusError",
      };
    } else if (error instanceof NetworkError) {
      return {message, name, type: "NetworkError"};
    } else {
      return {message, name, type: "OtherError"};
    }
  } else if (error && typeof error === "object") {
    const errorObject: Partial<Record<string, unknown>> = error;
    return {
      message: "message" in errorObject ? `${errorObject.message}` : "",
      name: "name" in errorObject ? `${errorObject.name}` : "",
      type: "OtherError",
    };
  } else {
    return {
      message: `${error}`,
      name: "",
      type: "OtherError",
    };
  }
}

const HTTP_400_BAD_REQUEST = 400;
const HTTP_500_INTERNAL_SERVER_ERROR = 500;

export function isClientError(error: SerializableError): boolean {
  return (
    error.type === "StatusError" &&
    error.statusCode >= HTTP_400_BAD_REQUEST &&
    error.statusCode < HTTP_500_INTERNAL_SERVER_ERROR
  );
}
