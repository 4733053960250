import {RouteTaskResult, RouteTaskUrl, Task} from "@co-common-libs/resources";
import {
  getCurrentRole,
  getCurrentUserURL,
  getCustomerSettings,
  getLocationLookup,
  getPriceGroupLookup,
  getPriceItemLookup,
  getRoutePlanTaskArray,
  getRouteTaskActivityOptionLookup,
  getRouteTaskArray,
  getRouteTaskResultArray,
  getShareToken,
  getUnitLookup,
} from "@co-frontend-libs/redux";
import {
  Card,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import _ from "lodash";
import React, {useMemo} from "react";
import {FormattedMessage, FormattedNumber, useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {computeSums} from "./compute-sums";
import {
  BUTTON_COLUMN_WIDTH,
  COUNT_COLUMN_WIDTH,
  LOG_ROW_COLUMN_COMMON_STYLE,
  TIME_COLUMN_WIDTH,
  UNIT_COLUMN_WIDTH,
} from "./constants";
import {getRouteTaskData} from "./get-route-task-data";
import {LogRow} from "./log-row";
import {ReportsCard} from "./report-card";

interface LogTabContentProps {
  hasActivity: boolean;
  onEditClick: (routeTaskUrl: RouteTaskUrl) => void;
  onRequestBuildReport: () => void;
  routePlanUrl?: string;
  task: Task;
}

export const LogTabContent = React.memo(function LogTabContent(
  props: LogTabContentProps,
): JSX.Element {
  const {hasActivity, onEditClick, onRequestBuildReport, routePlanUrl, task} = props;
  const customerSettings = useSelector(getCustomerSettings);

  const routePlanTaskArray = useSelector(getRoutePlanTaskArray);

  const routeTaskArray = useSelector(getRouteTaskArray);
  const routeTaskResultArray = useSelector(getRouteTaskResultArray);
  const locationLookup = useSelector(getLocationLookup);
  const routeTaskActivityOptionLookup = useSelector(getRouteTaskActivityOptionLookup);
  const priceGroupLookup = useSelector(getPriceGroupLookup);
  const priceItemLookup = useSelector(getPriceItemLookup);
  const unitLookup = useSelector(getUnitLookup);
  const shareToken = useSelector(getShareToken);

  const {routeTaskStartStop, transportLogDecimals} = customerSettings;
  const decimalPlaces = transportLogDecimals;

  const role = useSelector(getCurrentRole);
  const currentUserUrl = useSelector(getCurrentUserURL);
  const userIsManager = !!role && role.manager;
  const userIsOnlyMachineOperator = !!role && !role.manager;
  const userIsOther = !task || task.machineOperator !== currentUserUrl;
  const userIsSeniorMachineOperator = role && role.seniorMachineOperator;
  const allowSeniorToEdit = userIsSeniorMachineOperator && !hasActivity;
  const userIsOtherMachineOperator = userIsOther && userIsOnlyMachineOperator && !allowSeniorToEdit;

  const routePlanTaskList = useMemo(
    () =>
      routePlanUrl
        ? _.sortBy(
            routePlanTaskArray.filter((routePlanTask) => routePlanTask.routePlan === routePlanUrl),
            (routePlanTask) => routePlanTask.order,
          )
        : [],
    [routePlanTaskArray, routePlanUrl],
  );

  const taskURL = task.url;

  const routeTaskList = useMemo(
    () =>
      _.sortBy(
        routeTaskArray.filter((routeTask) => routeTask.route === taskURL),
        (routeTask) => routeTask.order,
      ),
    [routeTaskArray, taskURL],
  );

  const completedRouteTaskList = useMemo(
    () =>
      _.sortBy(
        routeTaskList.filter((routeTask) => routeTask.completed),
        (routeTask) => routeTask.completed,
      ),
    [routeTaskList],
  );

  const completedRouteTaskURLSet = useMemo(
    () => new Set(completedRouteTaskList.map((routeTask) => routeTask.url)),
    [completedRouteTaskList],
  );

  const routeTaskResultSet = useMemo(
    () =>
      new Set(
        routeTaskResultArray.filter((routeTaskResult) =>
          completedRouteTaskURLSet.has(routeTaskResult.routeTask),
        ),
      ),
    [completedRouteTaskURLSet, routeTaskResultArray],
  );

  const routeTaskResultsPerRouteTask = new Map<string, Set<RouteTaskResult>>();

  routeTaskResultSet.forEach((routeTaskResult) => {
    const key = routeTaskResult.routeTask;
    const resultsForTask = routeTaskResultsPerRouteTask.get(key);
    if (resultsForTask) {
      resultsForTask.add(routeTaskResult);
    } else {
      routeTaskResultsPerRouteTask.set(key, new Set([routeTaskResult]));
    }
  });

  const taskEntries = completedRouteTaskList.map((routeTask) =>
    getRouteTaskData(
      routeTask,
      locationLookup,
      routeTaskResultsPerRouteTask.get(routeTask.url) || new Set(),
      routeTaskActivityOptionLookup,
      priceGroupLookup,
      priceItemLookup,
      unitLookup,
      userIsManager,
    ),
  );
  const editDisabled =
    task.validatedAndRecorded ||
    userIsOtherMachineOperator ||
    (task.completed && userIsOnlyMachineOperator);

  const logEntries: JSX.Element[] = [];
  const sumEntries: JSX.Element[] = [];

  taskEntries.forEach(
    ({
      activity,
      address,
      completed,
      notesFromTaskAssignee,
      resultLines,
      routeTaskURL,
      started,
      workplaceName,
    }) => {
      if (resultLines.length) {
        const lastResultLine = resultLines.length === 1;
        const {quantity, resultDescription, routeTaskResultURL, unitString} = resultLines[0];
        logEntries.push(
          <LogRow
            key={routeTaskResultURL}
            activity={activity}
            address={address}
            completed={completed != null ? completed : undefined}
            decimalPlaces={decimalPlaces}
            editDisabled={editDisabled}
            fullBottomBorder={lastResultLine}
            notesFromTaskAssignee={notesFromTaskAssignee}
            quantity={quantity != null ? quantity : undefined}
            resultDescription={resultDescription}
            routeTaskStartStop={routeTaskStartStop}
            routeTaskURL={routeTaskURL}
            started={started != null ? started : undefined}
            unitString={unitString}
            workplaceName={workplaceName}
            onEditClick={onEditClick}
          />,
        );
      } else {
        const lastResultLine = true;
        logEntries.push(
          <LogRow
            key={routeTaskURL}
            activity={activity}
            address={address}
            completed={completed != null ? completed : undefined}
            decimalPlaces={decimalPlaces}
            editDisabled={editDisabled}
            fullBottomBorder={lastResultLine}
            notesFromTaskAssignee={notesFromTaskAssignee}
            routeTaskStartStop={routeTaskStartStop}
            routeTaskURL={routeTaskURL}
            started={started != null ? started : undefined}
            workplaceName={workplaceName}
            onEditClick={onEditClick}
          />,
        );
      }
      // first result merged with task data in <LogRow> above
      for (let i = 1; i < resultLines.length; i += 1) {
        const lastResultLine = i === resultLines.length - 1;
        const {quantity, resultDescription, routeTaskResultURL, unitString} = resultLines[i];
        logEntries.push(
          <LogRow
            key={routeTaskResultURL}
            decimalPlaces={decimalPlaces}
            editDisabled={editDisabled}
            fullBottomBorder={lastResultLine}
            quantity={quantity != null ? quantity : undefined}
            resultDescription={resultDescription}
            routeTaskStartStop={routeTaskStartStop}
            routeTaskURL={routeTaskURL}
            unitString={unitString}
            onEditClick={onEditClick}
          />,
        );
      }
    },
  );
  const sums = computeSums(taskEntries);

  _.sortBy(Array.from(sums.entries()), ([key, _value]) => key).forEach(([unitKey, unitSums]) => {
    _.sortBy(Array.from(unitSums.entries()), ([key, _value]) => key).forEach(([sumKey, entry]) => {
      sumEntries.push(
        <TableRow key={`${unitKey}-${sumKey}`}>
          <TableCell style={LOG_ROW_COLUMN_COMMON_STYLE}>{entry.text}</TableCell>
          <TableCell
            style={{
              width: COUNT_COLUMN_WIDTH,
              ...LOG_ROW_COLUMN_COMMON_STYLE,
            }}
          >
            <FormattedNumber maximumFractionDigits={decimalPlaces} value={entry.total} />
          </TableCell>
          <TableCell
            style={{
              width: UNIT_COLUMN_WIDTH,
              ...LOG_ROW_COLUMN_COMMON_STYLE,
            }}
          >
            {entry.unitString}
          </TableCell>
        </TableRow>,
      );
    });
  });

  const notDoneRoutePlanTasks = useMemo(
    () =>
      routePlanTaskList.filter(
        (routePlanTask) =>
          !routeTaskList.some(
            (r) =>
              r.customer === routePlanTask.customer &&
              r.project === routePlanTask.project &&
              r.relatedLocation === routePlanTask.relatedLocation &&
              r.description === routePlanTask.description &&
              r.order === routePlanTask.order &&
              r.completed,
          ),
      ),
    [routePlanTaskList, routeTaskList],
  );

  const notDoneRouteTasks = useMemo(
    () => routeTaskList.filter((routeTask) => !routeTask.completed),
    [routeTaskList],
  );

  let notDoneRows: JSX.Element[];
  if (routePlanUrl) {
    notDoneRows = notDoneRoutePlanTasks.map((routePlanTask) => (
      <TableRow key={routePlanTask.url}>
        <TableCell>{routePlanTask.description}</TableCell>
      </TableRow>
    ));
  } else {
    notDoneRows = notDoneRouteTasks.map((routeTask) => (
      <TableRow key={routeTask.url}>
        <TableCell>{routeTask.description}</TableCell>
      </TableRow>
    ));
  }

  const intl = useIntl();

  const logCardTitle =
    completedRouteTaskList.length === routeTaskList.length
      ? intl.formatMessage({defaultMessage: "Alle opgaver udført"})
      : intl.formatMessage(
          {defaultMessage: "{completed} ud af {total} opgaver udført"},
          {
            completed: completedRouteTaskList.length,
            total: routeTaskList.length,
          },
        );

  return (
    <div>
      <Card style={{margin: "1em", minWidth: 800}}>
        <CardHeader title={logCardTitle} />
        <Table>
          <TableHead>
            <TableRow>
              {routeTaskStartStop ? (
                <>
                  <TableCell style={{width: TIME_COLUMN_WIDTH}}>
                    <FormattedMessage
                      defaultMessage="Start"
                      id="route-task.table-header.started-time"
                    />
                  </TableCell>
                  <TableCell style={{width: TIME_COLUMN_WIDTH}}>
                    <FormattedMessage defaultMessage="Slut" />
                  </TableCell>
                </>
              ) : (
                <TableCell style={{width: TIME_COLUMN_WIDTH}}>
                  <FormattedMessage defaultMessage="Udf. kl." />
                </TableCell>
              )}

              <TableCell>
                <FormattedMessage defaultMessage="Sted" id="route-task.table-header.location" />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Aktivitet/Noter (maskf.)"
                  id="route-task.table-header.notes"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Resultat"
                  id="route-task.table-header.resultDescription"
                />
              </TableCell>
              <TableCell style={{width: COUNT_COLUMN_WIDTH}}>
                <FormattedMessage defaultMessage="Antal" id="route-task.table-header.count" />
              </TableCell>
              <TableCell style={{width: UNIT_COLUMN_WIDTH}}>
                <FormattedMessage defaultMessage="Enhed" id="route-task.table-header.unit" />
              </TableCell>
              <TableCell style={{width: BUTTON_COLUMN_WIDTH}} />
            </TableRow>
          </TableHead>
          <TableBody>{logEntries}</TableBody>
        </Table>
      </Card>
      <Card style={{margin: "1em", minWidth: 400}}>
        <CardHeader title={intl.formatMessage({defaultMessage: "Ikke udførte"})} />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <FormattedMessage defaultMessage="Navn" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{notDoneRows}</TableBody>
        </Table>
      </Card>
      <Card style={{margin: "1em", minWidth: 400}}>
        <CardHeader title={intl.formatMessage({defaultMessage: "Total"})} />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Resultat"
                  id="route-task.table-header.resultDescription"
                />
              </TableCell>
              <TableCell style={{width: COUNT_COLUMN_WIDTH}}>
                <FormattedMessage defaultMessage="Antal" id="route-task.table-header.count" />
              </TableCell>
              <TableCell style={{width: UNIT_COLUMN_WIDTH}}>
                <FormattedMessage defaultMessage="Enhed" id="route-task.table-header.unit" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{sumEntries}</TableBody>
        </Table>
      </Card>
      {userIsManager && task.completed && shareToken ? (
        <ReportsCard
          shareToken={shareToken}
          task={task}
          onRequestBuildReport={onRequestBuildReport}
        />
      ) : null}
    </div>
  );
});
