import {ThrottledTextField} from "@co-frontend-libs/components";
import {getCustomerSettings} from "@co-frontend-libs/redux";
import {Grid, IconButton, Paper} from "@material-ui/core";
import {red} from "@material-ui/core/colors";
import FileDocumentIcon from "mdi-react/FileDocumentIcon";
import PlusIcon from "mdi-react/PlusIcon";
import React from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {MaterialField} from "../material-field";
import {EntryProps, withEntryCallbacks} from "./with-entry-callbacks";

const NOTE_FIELD_CELL_PADDING = 12;

export const DisplayGridItem = React.memo(function DisplayGridItem(props: EntryProps): JSX.Element {
  const {
    booleanSelection,
    countValue,
    dangling,
    inputUnitSymbol,
    notes,
    notesReadonly,
    onCountChange,
    onNotesButtonClick,
    onNotesChange,
    onPlusClick,
    // priceItemActive,
    onRelationCountChange,
    priceItemNameAndIdentifier: priceItemName,
    readonly,
    relationConversionUnitSymbol,
    relationConversionValue,
    relationCountValue,
    showNotes,
    showRelationConversionColumns,
  } = props;

  const intl = useIntl();

  const customerSettings = useSelector(getCustomerSettings);

  const {alternativeMaterialsMobileLayout} = customerSettings;

  const paperStyle: React.CSSProperties = {
    backgroundColor: "rgb(247, 247, 247)",

    marginBottom: 2,
  };
  if (dangling) {
    paperStyle.color = red[500];
  }
  const priceItemGridItemStyle: React.CSSProperties = {
    overflow: "hidden",
    paddingBottom: 10,
    paddingTop: 12,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };
  if (showNotes && alternativeMaterialsMobileLayout) {
    priceItemGridItemStyle.paddingLeft = NOTE_FIELD_CELL_PADDING;
  }

  return (
    <Paper style={paperStyle}>
      <Grid container>
        {onNotesButtonClick && showNotes && !alternativeMaterialsMobileLayout ? (
          <Grid item style={{paddingBottom: 10, paddingTop: 10, width: 24}} xs="auto">
            <IconButton
              color="primary"
              style={{height: "auto", padding: 0, width: "auto"}}
              onClick={onNotesButtonClick}
            >
              <FileDocumentIcon />
            </IconButton>
          </Grid>
        ) : null}
        <Grid item xs style={priceItemGridItemStyle}>
          {priceItemName}
        </Grid>
        <Grid item xs={3}>
          <MaterialField
            hideLabel
            booleanSelection={booleanSelection}
            customerSettings={customerSettings}
            disabled={readonly}
            unit={inputUnitSymbol}
            value={countValue ?? undefined}
            onChange={onCountChange}
          />
        </Grid>
        <Grid item style={{paddingBottom: 10, paddingTop: 10, width: 24}} xs="auto">
          {!booleanSelection ? (
            <IconButton
              color="primary"
              disabled={readonly}
              style={{height: "auto", padding: 0, width: "auto"}}
              onClick={onPlusClick}
            >
              <PlusIcon />
            </IconButton>
          ) : null}
        </Grid>
        <Grid
          item
          style={{
            overflow: "hidden",
            paddingBottom: 10,
            paddingTop: 12,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
          xs={2}
        >
          {!booleanSelection ? inputUnitSymbol : null}
        </Grid>
      </Grid>
      {showRelationConversionColumns && relationConversionUnitSymbol ? (
        <Grid container>
          {onNotesButtonClick && showNotes && !alternativeMaterialsMobileLayout ? (
            <Grid item style={{width: 24}} xs="auto" />
          ) : null}
          <Grid item xs>
            {/* name */}
          </Grid>
          <Grid item xs={3}>
            <MaterialField
              hideLabel
              customerSettings={customerSettings}
              disabled={readonly || !relationConversionValue}
              unit={relationConversionUnitSymbol}
              value={relationCountValue ?? undefined}
              onChange={onRelationCountChange}
            />
          </Grid>
          <Grid item style={{width: 24}} xs="auto">
            {/* plus button*/}
          </Grid>
          <Grid item style={{paddingBottom: 10, paddingTop: 10}} xs={2}>
            {relationConversionUnitSymbol}
          </Grid>
        </Grid>
      ) : null}
      {showNotes && alternativeMaterialsMobileLayout ? (
        <Grid container>
          <Grid
            item
            xs
            style={{
              paddingLeft: NOTE_FIELD_CELL_PADDING,
              paddingRight: NOTE_FIELD_CELL_PADDING,
            }}
          >
            <ThrottledTextField
              fullWidth
              disabled={notesReadonly}
              margin="dense"
              placeholder={intl.formatMessage({defaultMessage: "Noter"})}
              value={notes}
              variant="outlined"
              onChange={onNotesChange}
            />
          </Grid>
        </Grid>
      ) : null}
    </Paper>
  );
});

export const DisplayGridItemWithEntryCallbacks = withEntryCallbacks(DisplayGridItem);
