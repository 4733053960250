import {Order} from "@co-common-libs/resources";
import {ResponsiveDialog, ResponsiveDialogProps} from "@co-frontend-libs/components";
import {useCallWithTrue, useResettingState} from "@co-frontend-libs/utils";
import {DialogContent} from "@material-ui/core";
import {SendOrder} from "feat-send-task-data";
import React from "react";
import {FormattedMessage, useIntl} from "react-intl";

interface ApproveOrderDialogProps
  extends Required<Pick<ResponsiveDialogProps, "onCancel" | "onOk" | "open">> {
  order: Pick<Order, "url">;
}

export const ApproveOrderDialog = React.memo(function ApproveOrderDialog(
  props: ApproveOrderDialogProps,
): React.JSX.Element {
  const {onCancel, onOk, open, order} = props;

  const intl = useIntl();

  const [createSalesLines, setCreateSalesLines] = useResettingState(false, open);
  const setCreateSalesLinesTrue = useCallWithTrue(setCreateSalesLines);

  return (
    <>
      <SendOrder
        execute={createSalesLines}
        order={order}
        onErrorDialogDismiss={onCancel}
        onSuccess={onOk}
      />
      <ResponsiveDialog
        key="order-validation-dialog"
        autoFocusOk
        open={open}
        title={intl.formatMessage({
          defaultMessage: "Godkend",
        })}
        onCancel={onCancel}
        onOk={setCreateSalesLinesTrue}
      >
        <DialogContent>
          <FormattedMessage defaultMessage="Godkend ordre?" />
        </DialogContent>
      </ResponsiveDialog>
    </>
  );
});
