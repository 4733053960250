// generated by script generate-import-types
import {z} from "zod";
import {transformDate} from "./date-helper";

export const createWorktypeexternalSchema = z.object({
  allowMaxOneMachine: z.boolean(),
  color: z
    .string()
    .regex(/^#?([a-fA-F0-9]{6})$/)
    .or(z.literal("")),
  disallowMachineUse: z.boolean(),
  identifier: z.string().min(1),
  name: z.string().min(1),
  noProductUsesOrAtLeastOneProductUseGreaterThanZero: z.boolean(),
  onlyForExtraTimers: z.boolean(),
  requireAtLeastOneOptionalPriceItemUseGreaterThanZero: z.boolean(),
  requireAtLeastOneProductUseGreaterThanZero: z.boolean(),
  requireAttachment: z.boolean(),
  requireEffectiveTime: z.boolean(),
  requireField: z.boolean(),
  requireMachineUse: z.boolean(),
  requireNotesFromMachineOperator: z.boolean(),
  requirePhotoOnTaskCompletion: z.boolean(),
  requireWorkplace: z.boolean(),
  salesAccount: z.number().int().nullable(),
  towingCostMultiplier: z.number().nullable(),
});

export type CreateWorktypeexternal = z.infer<typeof createWorktypeexternalSchema>;

export const updateWorktypeexternalSchema = z.object({
  allowMaxOneMachine: z.optional(z.boolean()),
  color: z.optional(
    z
      .string()
      .regex(/^#?([a-fA-F0-9]{6})$/)
      .or(z.literal("")),
  ),
  disallowMachineUse: z.optional(z.boolean()),
  identifier: z.string().min(1),
  name: z.optional(z.string().min(1)),
  noProductUsesOrAtLeastOneProductUseGreaterThanZero: z.optional(z.boolean()),
  onlyForExtraTimers: z.optional(z.boolean()),
  requireAtLeastOneOptionalPriceItemUseGreaterThanZero: z.optional(z.boolean()),
  requireAtLeastOneProductUseGreaterThanZero: z.optional(z.boolean()),
  requireAttachment: z.optional(z.boolean()),
  requireEffectiveTime: z.optional(z.boolean()),
  requireField: z.optional(z.boolean()),
  requireMachineUse: z.optional(z.boolean()),
  requireNotesFromMachineOperator: z.optional(z.boolean()),
  requirePhotoOnTaskCompletion: z.optional(z.boolean()),
  requireWorkplace: z.optional(z.boolean()),
  salesAccount: z.optional(z.number().int().nullable()),
  towingCostMultiplier: z.optional(z.number().nullable()),
});

export type UpdateWorktypeexternal = z.infer<typeof updateWorktypeexternalSchema>;

export const updateWorktypeexternalColumnSchema = z.union([
  z.literal("allowMaxOneMachine"),
  z.literal("color"),
  z.literal("disallowMachineUse"),
  z.literal("name"),
  z.literal("noProductUsesOrAtLeastOneProductUseGreaterThanZero"),
  z.literal("onlyForExtraTimers"),
  z.literal("requireAtLeastOneOptionalPriceItemUseGreaterThanZero"),
  z.literal("requireAtLeastOneProductUseGreaterThanZero"),
  z.literal("requireAttachment"),
  z.literal("requireEffectiveTime"),
  z.literal("requireField"),
  z.literal("requireMachineUse"),
  z.literal("requireNotesFromMachineOperator"),
  z.literal("requirePhotoOnTaskCompletion"),
  z.literal("requireWorkplace"),
  z.literal("salesAccount"),
  z.literal("towingCostMultiplier"),
]);

export type WorktypeexternalColumn = z.infer<typeof updateWorktypeexternalColumnSchema>;

export const createWorktypeinternalSchema = z.object({
  allowMaxOneMachine: z.boolean(),
  color: z
    .string()
    .regex(/^#?([a-fA-F0-9]{6})$/)
    .or(z.literal("")),
  disallowMachineUse: z.boolean(),
  identifier: z.string().min(1),
  internalTaskPrimary: z.boolean(),
  name: z.string().min(1),
  onlyForExtraTimers: z.boolean(),
  requireAttachment: z.boolean(),
  requireMachineUse: z.boolean(),
  requireNotesFromMachineOperator: z.boolean(),
  requirePhotoOnTaskCompletion: z.boolean(),
});

export type CreateWorktypeinternal = z.infer<typeof createWorktypeinternalSchema>;

export const updateWorktypeinternalSchema = z.object({
  allowMaxOneMachine: z.optional(z.boolean()),
  color: z.optional(
    z
      .string()
      .regex(/^#?([a-fA-F0-9]{6})$/)
      .or(z.literal("")),
  ),
  disallowMachineUse: z.optional(z.boolean()),
  identifier: z.string().min(1),
  internalTaskPrimary: z.optional(z.boolean()),
  name: z.optional(z.string().min(1)),
  onlyForExtraTimers: z.optional(z.boolean()),
  requireAttachment: z.optional(z.boolean()),
  requireMachineUse: z.optional(z.boolean()),
  requireNotesFromMachineOperator: z.optional(z.boolean()),
  requirePhotoOnTaskCompletion: z.optional(z.boolean()),
});

export type UpdateWorktypeinternal = z.infer<typeof updateWorktypeinternalSchema>;

export const updateWorktypeinternalColumnSchema = z.union([
  z.literal("allowMaxOneMachine"),
  z.literal("color"),
  z.literal("disallowMachineUse"),
  z.literal("internalTaskPrimary"),
  z.literal("name"),
  z.literal("onlyForExtraTimers"),
  z.literal("requireAttachment"),
  z.literal("requireMachineUse"),
  z.literal("requireNotesFromMachineOperator"),
  z.literal("requirePhotoOnTaskCompletion"),
]);

export type WorktypeinternalColumn = z.infer<typeof updateWorktypeinternalColumnSchema>;

export const createUnitSchema = z.object({
  name: z.string().min(1),
  symbol: z.string(),
});

export type CreateUnit = z.infer<typeof createUnitSchema>;

export const updateUnitSchema = z.object({
  name: z.string().min(1),
  symbol: z.optional(z.string()),
});

export type UpdateUnit = z.infer<typeof updateUnitSchema>;

export const updateUnitColumnSchema = z.literal("symbol");

export type UnitColumn = z.infer<typeof updateUnitColumnSchema>;

export const createCustomerSchema = z.object({
  address: z.string(),
  alias: z.string(),
  barred: z.boolean(),
  billingEmail: z.string().email().or(z.literal("")),
  c5Account: z.string().min(1),
  cellphone: z.string(),
  city: z.string(),
  createInvoices: z.boolean(),
  disallowTaskValidation: z.boolean(),
  favorite: z.boolean(),
  logEmail: z.string().email().or(z.literal("")),
  name: z.string().min(1),
  phone: z.string(),
  postalCode: z.string(),
  projectManagerName: z.string(),
  receiveLogMails: z.boolean(),
  vatNumber: z
    .string()
    .regex(/^\d{8}$/)
    .or(z.literal("")),
});

export type CreateCustomer = z.infer<typeof createCustomerSchema>;

export const updateCustomerSchema = z.object({
  address: z.optional(z.string()),
  alias: z.optional(z.string()),
  barred: z.optional(z.boolean()),
  billingEmail: z.optional(z.string().email().or(z.literal(""))),
  c5Account: z.string().min(1),
  cellphone: z.optional(z.string()),
  city: z.optional(z.string()),
  createInvoices: z.optional(z.boolean()),
  disallowTaskValidation: z.optional(z.boolean()),
  favorite: z.optional(z.boolean()),
  logEmail: z.optional(z.string().email().or(z.literal(""))),
  name: z.optional(z.string().min(1)),
  phone: z.optional(z.string()),
  postalCode: z.optional(z.string()),
  projectManagerName: z.optional(z.string()),
  receiveLogMails: z.optional(z.boolean()),
  vatNumber: z.optional(
    z
      .string()
      .regex(/^\d{8}$/)
      .or(z.literal("")),
  ),
});

export type UpdateCustomer = z.infer<typeof updateCustomerSchema>;

export const updateCustomerColumnSchema = z.union([
  z.literal("address"),
  z.literal("alias"),
  z.literal("barred"),
  z.literal("billingEmail"),
  z.literal("cellphone"),
  z.literal("city"),
  z.literal("createInvoices"),
  z.literal("disallowTaskValidation"),
  z.literal("favorite"),
  z.literal("logEmail"),
  z.literal("name"),
  z.literal("phone"),
  z.literal("postalCode"),
  z.literal("projectManagerName"),
  z.literal("receiveLogMails"),
  z.literal("vatNumber"),
]);

export type CustomerColumn = z.infer<typeof updateCustomerColumnSchema>;

export const createMachineSchema = z.object({
  c5Machine: z.number().int(),
  canPull: z.boolean(),
  department: z.string(),
  hourlyTowingCostEffective: z.number().nullable(),
  hourlyTowingCostTransport: z.number().nullable(),
  name: z.string().min(1),
  requireAtLeastOneProductUseGreaterThanZero: z.boolean(),
  requirePhotoOnTaskCompletion: z.boolean(),
  selfPropelled: z.boolean(),
  smallMachine: z.boolean(),
});

export type CreateMachine = z.infer<typeof createMachineSchema>;

export const updateMachineSchema = z.object({
  c5Machine: z.number().int(),
  canPull: z.optional(z.boolean()),
  department: z.optional(z.string()),
  hourlyTowingCostEffective: z.optional(z.number().nullable()),
  hourlyTowingCostTransport: z.optional(z.number().nullable()),
  name: z.optional(z.string().min(1)),
  requireAtLeastOneProductUseGreaterThanZero: z.optional(z.boolean()),
  requirePhotoOnTaskCompletion: z.optional(z.boolean()),
  selfPropelled: z.optional(z.boolean()),
  smallMachine: z.optional(z.boolean()),
});

export type UpdateMachine = z.infer<typeof updateMachineSchema>;

export const updateMachineColumnSchema = z.union([
  z.literal("canPull"),
  z.literal("department"),
  z.literal("hourlyTowingCostEffective"),
  z.literal("hourlyTowingCostTransport"),
  z.literal("name"),
  z.literal("requireAtLeastOneProductUseGreaterThanZero"),
  z.literal("requirePhotoOnTaskCompletion"),
  z.literal("selfPropelled"),
  z.literal("smallMachine"),
]);

export type MachineColumn = z.infer<typeof updateMachineColumnSchema>;

export const createUserSchema = z.object({
  address: z.string(),
  alias: z.string().min(1),
  alwaysVisible: z.boolean(),
  askAboutAvailability: z.boolean(),
  averageHourlyCost: z.number().nullable(),
  birthDate: z
    .string()
    .regex(/^\d{2}\.\d{2}\.\d{4}$/)
    .transform(transformDate)
    .nullable(),
  canReadFoodBookings: z.boolean(),
  canUnapproveTasks: z.boolean(),
  cellphone: z.string(),
  cellphoneExtra: z.string(),
  city: z.string(),
  email: z.string().email().or(z.literal("")),
  employmentDate: z
    .string()
    .regex(/^\d{2}\.\d{2}\.\d{4}$/)
    .transform(transformDate)
    .nullable(),
  loginIdentifier: z.string().min(1),
  name: z.string(),
  note: z.string(),
  owner: z.boolean(),
  password: z.string().min(1),
  postalCode: z.string(),
  receiveTimerStartNotifications: z.boolean(),
  remunerationGroup: z.string().min(1),
  requireGps: z.boolean(),
  role: z.union([
    z.literal("Løsarbejder"),
    z.literal("Mekaniker"),
    z.literal("Projektleder"),
    z.literal("Maskinfører"),
    z.literal("Senior Maskinfører"),
    z.literal("Administrator"),
    z.literal("Info, ikke angivet"),
    z.literal("Info, pauseskærm"),
    z.literal("Info, fuldskærmskort"),
    z.literal("Info, fuldskærmskalender"),
    z.literal("Info, fuldskærmskalender, side 1"),
    z.literal("Info, fuldskærmskalender, side 2"),
    z.literal("Info, fuldskærmsordrekalender"),
    z.literal("Info, fuldskærmsordrekalender (minimal)"),
    z.literal("Info, fuldskærmsfraværskalender"),
  ]),
  showDinnerBookingPopup: z.boolean(),
  showLunchBookingPopup: z.boolean(),
  showNotePopupOnTask: z.boolean(),
});

export type CreateUser = z.infer<typeof createUserSchema>;

export const updateUserSchema = z.object({
  address: z.optional(z.string()),
  alias: z.optional(z.string().min(1)),
  alwaysVisible: z.optional(z.boolean()),
  askAboutAvailability: z.optional(z.boolean()),
  averageHourlyCost: z.optional(z.number().nullable()),
  birthDate: z.optional(
    z
      .string()
      .regex(/^\d{2}\.\d{2}\.\d{4}$/)
      .transform(transformDate)
      .nullable(),
  ),
  canReadFoodBookings: z.optional(z.boolean()),
  canUnapproveTasks: z.optional(z.boolean()),
  cellphone: z.optional(z.string()),
  cellphoneExtra: z.optional(z.string()),
  city: z.optional(z.string()),
  email: z.optional(z.string().email().or(z.literal(""))),
  employmentDate: z.optional(
    z
      .string()
      .regex(/^\d{2}\.\d{2}\.\d{4}$/)
      .transform(transformDate)
      .nullable(),
  ),
  loginIdentifier: z.string().min(1),
  name: z.optional(z.string()),
  note: z.optional(z.string()),
  owner: z.optional(z.boolean()),
  password: z.optional(z.string().min(1)),
  postalCode: z.optional(z.string()),
  receiveTimerStartNotifications: z.optional(z.boolean()),
  remunerationGroup: z.optional(z.string().min(1)),
  requireGps: z.optional(z.boolean()),
  role: z.optional(
    z.union([
      z.literal("Løsarbejder"),
      z.literal("Mekaniker"),
      z.literal("Projektleder"),
      z.literal("Maskinfører"),
      z.literal("Senior Maskinfører"),
      z.literal("Administrator"),
      z.literal("Info, ikke angivet"),
      z.literal("Info, pauseskærm"),
      z.literal("Info, fuldskærmskort"),
      z.literal("Info, fuldskærmskalender"),
      z.literal("Info, fuldskærmskalender, side 1"),
      z.literal("Info, fuldskærmskalender, side 2"),
      z.literal("Info, fuldskærmsordrekalender"),
      z.literal("Info, fuldskærmsordrekalender (minimal)"),
      z.literal("Info, fuldskærmsfraværskalender"),
    ]),
  ),
  showDinnerBookingPopup: z.optional(z.boolean()),
  showLunchBookingPopup: z.optional(z.boolean()),
  showNotePopupOnTask: z.optional(z.boolean()),
});

export type UpdateUser = z.infer<typeof updateUserSchema>;

export const updateUserColumnSchema = z.union([
  z.literal("address"),
  z.literal("alias"),
  z.literal("alwaysVisible"),
  z.literal("askAboutAvailability"),
  z.literal("averageHourlyCost"),
  z.literal("birthDate"),
  z.literal("canReadFoodBookings"),
  z.literal("canUnapproveTasks"),
  z.literal("cellphone"),
  z.literal("cellphoneExtra"),
  z.literal("city"),
  z.literal("email"),
  z.literal("employmentDate"),
  z.literal("name"),
  z.literal("note"),
  z.literal("owner"),
  z.literal("password"),
  z.literal("postalCode"),
  z.literal("receiveTimerStartNotifications"),
  z.literal("remunerationGroup"),
  z.literal("requireGps"),
  z.literal("role"),
  z.literal("showDinnerBookingPopup"),
  z.literal("showLunchBookingPopup"),
  z.literal("showNotePopupOnTask"),
]);

export type UserColumn = z.infer<typeof updateUserColumnSchema>;

export const createLocationSchema = z.object({
  customerNumber: z.string().min(1),
  locationAddress: z.string(),
  locationCity: z.string(),
  locationContactCellphone: z.string(),
  locationContactEmail: z.string().email().or(z.literal("")),
  locationContactName: z.string(),
  locationContactPhone: z.string(),
  locationId: z.string(),
  locationLatitude: z.number().nullable(),
  locationLongitude: z.number().nullable(),
  locationName: z.string(),
  locationPostalCode: z.string(),
  locationTypeIdentifier: z.string().min(1),
  usage: z.union([
    z.literal("Kan bruges som både arbejdssted og logsted"),
    z.literal("Kan kun bruges som logsted"),
    z.literal("Kan kun bruges som arbejdssted"),
  ]),
});

export type CreateLocation = z.infer<typeof createLocationSchema>;

export const updateLocationSchema = z.object({
  customerNumber: z.optional(z.string().min(1)),
  locationAddress: z.optional(z.string()),
  locationCity: z.optional(z.string()),
  locationContactCellphone: z.optional(z.string()),
  locationContactEmail: z.optional(z.string().email().or(z.literal(""))),
  locationContactName: z.optional(z.string()),
  locationContactPhone: z.optional(z.string()),
  locationId: z.string(),
  locationLatitude: z.optional(z.number().nullable()),
  locationLongitude: z.optional(z.number().nullable()),
  locationName: z.optional(z.string()),
  locationPostalCode: z.optional(z.string()),
  locationTypeIdentifier: z.optional(z.string().min(1)),
  usage: z.optional(
    z.union([
      z.literal("Kan bruges som både arbejdssted og logsted"),
      z.literal("Kan kun bruges som logsted"),
      z.literal("Kan kun bruges som arbejdssted"),
    ]),
  ),
});

export type UpdateLocation = z.infer<typeof updateLocationSchema>;

export const updateLocationColumnSchema = z.union([
  z.literal("customerNumber"),
  z.literal("locationAddress"),
  z.literal("locationCity"),
  z.literal("locationContactCellphone"),
  z.literal("locationContactEmail"),
  z.literal("locationContactName"),
  z.literal("locationContactPhone"),
  z.literal("locationLatitude"),
  z.literal("locationLongitude"),
  z.literal("locationName"),
  z.literal("locationPostalCode"),
  z.literal("locationTypeIdentifier"),
  z.literal("usage"),
]);

export type LocationColumn = z.infer<typeof updateLocationColumnSchema>;

export const createProductgroupSchema = z.object({
  identifier: z.string().min(1),
  name: z.string().min(1),
  salesAccount: z.number().int().nullable(),
});

export type CreateProductgroup = z.infer<typeof createProductgroupSchema>;

export const updateProductgroupSchema = z.object({
  identifier: z.string().min(1),
  name: z.optional(z.string().min(1)),
  salesAccount: z.optional(z.number().int().nullable()),
});

export type UpdateProductgroup = z.infer<typeof updateProductgroupSchema>;

export const updateProductgroupColumnSchema = z.union([
  z.literal("name"),
  z.literal("salesAccount"),
]);

export type ProductgroupColumn = z.infer<typeof updateProductgroupColumnSchema>;

export const createProductSchema = z.object({
  catalogNumber: z.string().min(1),
  name: z.string().min(1),
  price: z.number().nullable(),
  productGroupIdentifier: z.string(),
  relatedUnitName: z.string(),
});

export type CreateProduct = z.infer<typeof createProductSchema>;

export const updateProductSchema = z.object({
  catalogNumber: z.string().min(1),
  name: z.optional(z.string().min(1)),
  price: z.optional(z.number().nullable()),
  productGroupIdentifier: z.optional(z.string()),
  relatedUnitName: z.optional(z.string()),
});

export type UpdateProduct = z.infer<typeof updateProductSchema>;

export const updateProductColumnSchema = z.union([
  z.literal("name"),
  z.literal("price"),
  z.literal("productGroupIdentifier"),
  z.literal("relatedUnitName"),
]);

export type ProductColumn = z.infer<typeof updateProductColumnSchema>;
