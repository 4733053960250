import {formatDateNumeric} from "@co-common-libs/utils";
import _ from "lodash";
import React from "react";
import {FormattedMessage, FormattedNumber} from "react-intl";

export interface MachineAnalysisResults {
  accountNames: {[accountNumber: string]: string};
  contributionMargin1: number;
  contributionMargin2: number;
  directCosts: {
    foreignWorkshop: number;
    other: number;
    spareParts: number;
    total: number;
  };
  economicDirectCosts: {[accountNumber: string]: number};
  economicIndirectCosts: {[accountNumber: string]: number};
  economicTurnover: {[accountNumber: string]: number};
  employeeWarnings: {alias: string; name: string}[];
  fromDateString: string;
  indirectCosts: {
    financing: number;
    insurance: number;
    other: number;
    total: number;
  };
  machine: {name: string; number: string; selfPropelled: boolean};
  nonProductive: {[label: string]: {salaryCost: number; salaryHours: number}};
  nonProductiveSalaryCostTotal: number;
  nonProductiveSalaryHoursTotal: number;
  otherCostsTotal: number;
  otherHoursTotal: number;
  perProductiveHour: {
    contributionMargin1: number;
    contributionMargin2: number;
    directCosts: number;
    indirectCosts: number;
    otherCosts: number;
    salaryCosts: number;
    turnover: number;
  };
  productive: {[label: string]: {salaryCost: number; salaryHours: number}};
  productiveSalaryCostTotal: number;
  productiveSalaryHoursTotal: number;
  pullerWarnings: {machineNumber: string; name: string}[];
  salaryCostsTotal: number;
  salaryHoursTotal: number;
  tasksWithExtraTimerIssues: number;
  tasksWithPullerIssues: number;
  toDateString: string;
  towing: {
    productiveTowingCost: number;
    productiveTowingHours: number;
    totalTowingCost: number;
    totalTowingHours: number;
    transportTowingCost: number;
    transportTowingHours: number;
  };
  turnover: {invoiced: number; total: number};
  turnoverTotalMinusSalaryCostsTotal: number;
}

export function MachineAnalysis(props: MachineAnalysisResults): JSX.Element {
  const {
    accountNames,
    contributionMargin1,
    contributionMargin2,
    directCosts,
    economicDirectCosts,
    economicIndirectCosts,
    economicTurnover,
    employeeWarnings,
    fromDateString,
    indirectCosts,
    machine,
    nonProductive,
    nonProductiveSalaryCostTotal,
    nonProductiveSalaryHoursTotal,
    otherCostsTotal,
    otherHoursTotal,
    perProductiveHour,
    productive,
    productiveSalaryCostTotal,
    productiveSalaryHoursTotal,
    pullerWarnings,
    salaryCostsTotal,
    salaryHoursTotal,
    tasksWithExtraTimerIssues,
    tasksWithPullerIssues,
    toDateString,
    towing,
    turnover,
    turnoverTotalMinusSalaryCostsTotal,
  } = props;
  const amountColumnWidth = "10em";
  const hoursColumnWidth = "10em";
  return (
    <div
      style={{
        fontFamily: "Linux Biolinum O, sans-serif",
        fontSize: "11pt",
        width: "100%",
      }}
    >
      <FormattedMessage
        defaultMessage="Maskinanalyse"
        id="machine-analysis.title.machine-analysis"
        tagName="h3"
      />
      <FormattedMessage
        defaultMessage="Periode: {fromDate} - {toDate}"
        id="machine-analysis.title.period"
        tagName="div"
        values={{
          fromDate: formatDateNumeric(fromDateString),
          toDate: formatDateNumeric(toDateString),
        }}
      />
      <FormattedMessage
        defaultMessage="Navn: {name}"
        id="machine-analysis.title.machine-name"
        tagName="div"
        values={{name: machine.name}}
      />
      <FormattedMessage
        defaultMessage="Nummer: {number}"
        id="machine-analysis.title.machine-number"
        tagName="div"
        values={{number: machine.number}}
      />
      <FormattedMessage
        defaultMessage="Selvkørende: {selfPropelled}"
        id="machine-analysis.title.self-propelled"
        tagName="div"
        values={{
          selfPropelled: machine.selfPropelled ? (
            <FormattedMessage defaultMessage="Ja" id="machine-analysis.text.yes" />
          ) : (
            <FormattedMessage defaultMessage="Nej" id="machine-analysis.text.no" />
          ),
        }}
      />
      {employeeWarnings.length || pullerWarnings.length || tasksWithPullerIssues ? (
        <div style={{marginTop: "2em"}}>
          <FormattedMessage
            defaultMessage="Advarsler"
            id="machine-analysis.header.warnings"
            tagName="h3"
          />
          {employeeWarnings.map(({alias, name}, index) => (
            <FormattedMessage
              key={`employee-warning-${index}`}
              defaultMessage="Medarbejder {alias} ({name}) mangler opsætning af omkostning"
              id="machine-analysis.employee-warning.text"
              tagName="div"
              values={{alias, name}}
            />
          ))}
          {pullerWarnings.map(({machineNumber, name}, index) => (
            <FormattedMessage
              key={`puller-warning-${index}`}
              defaultMessage="Maskine {machineNumber} ({name}) mangler opsætning af omkostning"
              id="machine-analysis.puller-warning.text"
              tagName="div"
              values={{machineNumber, name}}
            />
          ))}
          {tasksWithPullerIssues ? (
            // Note that this only happens in the case of tasks with
            // distribution table as the user would not otherwise be allowed to
            // mark that task completed if multiple pullers are assigned to the
            // task.
            <FormattedMessage
              defaultMessage="Der var {tasksWithPullerIssues} opgave(r) med fordelingstabel hvor bugsering ikke kunne beregnes"
              id="machine-analysis.tasks-with-puller-issues"
              tagName="div"
              values={{tasksWithPullerIssues}}
            />
          ) : null}
          {tasksWithExtraTimerIssues ? (
            // We do not support extra timer productive time if distribution table is used.
            <FormattedMessage
              defaultMessage="Der var {tasksWithExtraTimerIssues} opgave(r) med fordelingstabel hvor produktiv tid fra ekstra tidsknapper ikke kunne beregnes"
              id="machine-analysis.tasks-with-extra-timer-issues"
              tagName="div"
              values={{tasksWithExtraTimerIssues}}
            />
          ) : null}
        </div>
      ) : null}
      <table style={{borderCollapse: "collapse", marginTop: "2em", width: "100%"}}>
        <thead>
          <tr style={{borderBottom: "1px solid black"}}>
            <th style={{textAlign: "left"}}>
              <FormattedMessage
                defaultMessage="Omsætning"
                id="machine-analysis.table-header.turnover"
              />
            </th>
            <th style={{textAlign: "right"}}>
              <FormattedMessage defaultMessage="kr" id="machine-analysis.table-header.amount" />
            </th>
          </tr>
        </thead>
        <tbody>
          {turnover.invoiced ? (
            <tr>
              <td style={{textAlign: "left"}}>
                <FormattedMessage
                  defaultMessage="Faktureret"
                  id="machine-analysis.text.turnover-invoiced"
                />
              </td>
              <td style={{textAlign: "right"}}>
                <FormattedNumber
                  maximumFractionDigits={2}
                  minimumFractionDigits={2}
                  value={turnover.invoiced || 0}
                />
              </td>
            </tr>
          ) : null}
          {Object.entries(economicTurnover).map(([accountNumber, amount]) => (
            <tr key={accountNumber}>
              <td style={{textAlign: "left"}}>
                {accountNumber}: {accountNames[accountNumber]}
              </td>
              <td style={{textAlign: "right"}}>
                <FormattedNumber
                  maximumFractionDigits={2}
                  minimumFractionDigits={2}
                  value={amount || 0}
                />
              </td>
            </tr>
          ))}
          <tr style={{borderBottom: "1px solid black", fontWeight: "bold"}}>
            <td style={{textAlign: "left"}}>
              <FormattedMessage
                defaultMessage="Omsætning i alt"
                id="machine-analysis.text.turnover-total"
              />
            </td>
            <td style={{textAlign: "right"}}>
              <FormattedNumber
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                value={turnover.total || 0}
              />
            </td>
          </tr>
        </tbody>
      </table>

      <table style={{borderCollapse: "collapse", marginTop: "2em", width: "100%"}}>
        <colgroup>
          <col />
          <col style={{width: hoursColumnWidth}} />
          <col style={{width: amountColumnWidth}} />
        </colgroup>
        <thead>
          <tr style={{borderBottom: "1px solid black"}}>
            <th style={{textAlign: "left"}}>
              <FormattedMessage
                defaultMessage="Produktive lønomkostninger"
                id="machine-analysis.table-header.productive-salary-costs"
              />
            </th>
            <th style={{textAlign: "right"}}>
              <FormattedMessage defaultMessage="Timer" id="machine-analysis.table-header.hours" />
            </th>
            <th style={{textAlign: "right"}}>
              <FormattedMessage defaultMessage="kr" id="machine-analysis.table-header.amount" />
            </th>
          </tr>
        </thead>
        <tbody>
          {_.sortBy(Object.entries(productive), ([label]) => label).map(
            ([label, {salaryCost, salaryHours}]) => (
              <tr key={label}>
                <td style={{textAlign: "left"}}>{label}</td>
                <td style={{textAlign: "right"}}>
                  <FormattedNumber
                    maximumFractionDigits={2}
                    minimumFractionDigits={2}
                    value={salaryHours}
                  />
                </td>
                <td style={{textAlign: "right"}}>
                  <FormattedNumber
                    maximumFractionDigits={2}
                    minimumFractionDigits={2}
                    value={-salaryCost || 0}
                  />
                </td>
              </tr>
            ),
          )}
          <tr style={{borderBottom: "1px solid black", fontWeight: "bold"}}>
            <td style={{textAlign: "left"}}>
              <FormattedMessage
                defaultMessage="Produktive lønomkostninger i alt"
                id="machine-analysis.text.productive-salary-costs-total"
              />
            </td>
            <td style={{textAlign: "right"}}>
              <FormattedNumber
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                value={productiveSalaryHoursTotal}
              />
            </td>
            <td style={{textAlign: "right"}}>
              <FormattedNumber
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                value={-productiveSalaryCostTotal || 0}
              />
            </td>
          </tr>
        </tbody>
      </table>

      <table style={{borderCollapse: "collapse", marginTop: "2em", width: "100%"}}>
        <colgroup>
          <col />
          <col style={{width: hoursColumnWidth}} />
          <col style={{width: amountColumnWidth}} />
        </colgroup>
        <thead>
          <tr style={{borderBottom: "1px solid black"}}>
            <th style={{textAlign: "left"}}>
              <FormattedMessage
                defaultMessage="Interne lønomkostninger"
                id="machine-analysis.table-header.non-productive-salary-costs"
              />
            </th>
            <th style={{textAlign: "right"}}>
              <FormattedMessage defaultMessage="Timer" id="machine-analysis.table-header.hours" />
            </th>
            <th style={{textAlign: "right"}}>
              <FormattedMessage defaultMessage="kr" id="machine-analysis.table-header.amount" />
            </th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(nonProductive)
            .sort()
            .map(([label, {salaryCost, salaryHours}]) => (
              <tr key={label}>
                <td style={{textAlign: "left"}}>{label}</td>
                <td style={{textAlign: "right"}}>
                  <FormattedNumber
                    maximumFractionDigits={2}
                    minimumFractionDigits={2}
                    value={salaryHours}
                  />
                </td>
                <td style={{textAlign: "right"}}>
                  <FormattedNumber
                    maximumFractionDigits={2}
                    minimumFractionDigits={2}
                    value={-salaryCost || 0}
                  />
                </td>
              </tr>
            ))}
          <tr style={{borderBottom: "1px solid black", fontWeight: "bold"}}>
            <td style={{textAlign: "left"}}>
              <FormattedMessage
                defaultMessage="Interne lønomkostninger i alt"
                id="machine-analysis.text.non-productive-salary-costs-total"
              />
            </td>
            <td style={{textAlign: "right"}}>
              <FormattedNumber
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                value={nonProductiveSalaryHoursTotal}
              />
            </td>
            <td style={{textAlign: "right"}}>
              <FormattedNumber
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                value={-nonProductiveSalaryCostTotal || 0}
              />
            </td>
          </tr>
        </tbody>
      </table>

      <table style={{borderCollapse: "collapse", marginTop: "2em", width: "100%"}}>
        <colgroup>
          <col />
          <col style={{width: hoursColumnWidth}} />
          <col style={{width: amountColumnWidth}} />
        </colgroup>
        <tbody>
          <tr style={{borderBottom: "1px solid black", fontWeight: "bold"}}>
            <td style={{textAlign: "left"}}>
              <FormattedMessage
                defaultMessage="Lønomkostninger i alt"
                id="machine-analysis.text.salary-costs-total"
              />
            </td>
            <td style={{textAlign: "right"}}>
              <FormattedNumber
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                value={salaryHoursTotal}
              />
            </td>
            <td style={{textAlign: "right"}}>
              <FormattedNumber
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                value={-salaryCostsTotal || 0}
              />
            </td>
          </tr>
          <tr style={{borderBottom: "1px solid black", fontWeight: "bold"}}>
            <td style={{textAlign: "left"}}>
              <FormattedMessage
                defaultMessage="Omsætning efter lønomkostninger"
                id="machine-analysis.text.turnover-total-minus-salary-costs-total"
              />
            </td>
            <td />
            <td style={{textAlign: "right"}}>
              <FormattedNumber
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                value={turnoverTotalMinusSalaryCostsTotal || 0}
              />
            </td>
          </tr>
        </tbody>
      </table>

      <table style={{borderCollapse: "collapse", marginTop: "2em", width: "100%"}}>
        <thead>
          <tr style={{borderBottom: "1px solid black"}}>
            <th style={{textAlign: "left"}}>
              <FormattedMessage
                defaultMessage="Direkte omkostninger"
                id="machine-analysis.table-header.direct-costs"
              />
            </th>
            <th style={{textAlign: "right"}}>
              <FormattedMessage defaultMessage="kr" id="machine-analysis.table-header.amount" />
            </th>
          </tr>
        </thead>
        <tbody>
          {directCosts.spareParts ? (
            <tr>
              <td style={{textAlign: "left"}}>
                <FormattedMessage
                  defaultMessage="Reservedele"
                  id="machine-analysis.text.direct-costs-spare-parts"
                />
              </td>
              <td style={{textAlign: "right"}}>
                <FormattedNumber
                  maximumFractionDigits={2}
                  minimumFractionDigits={2}
                  value={-directCosts.spareParts || 0}
                />
              </td>
            </tr>
          ) : null}
          {directCosts.foreignWorkshop ? (
            <tr>
              <td style={{textAlign: "left"}}>
                <FormattedMessage
                  defaultMessage="Fremmed værksted"
                  id="machine-analysis.text.direct-costs-foreign-workshop"
                />
              </td>
              <td style={{textAlign: "right"}}>
                <FormattedNumber
                  maximumFractionDigits={2}
                  minimumFractionDigits={2}
                  value={-directCosts.foreignWorkshop || 0}
                />
              </td>
            </tr>
          ) : null}
          {Object.entries(economicDirectCosts).map(([accountNumber, amount]) => (
            <tr key={accountNumber}>
              <td style={{textAlign: "left"}}>
                {accountNumber}: {accountNames[accountNumber]}
              </td>
              <td style={{textAlign: "right"}}>
                <FormattedNumber
                  maximumFractionDigits={2}
                  minimumFractionDigits={2}
                  value={-amount || 0}
                />
              </td>
            </tr>
          ))}
          {directCosts.other ? (
            <tr>
              <td style={{textAlign: "left"}}>
                <FormattedMessage
                  defaultMessage="Andet"
                  id="machine-analysis.text.direct-costs-other"
                />
              </td>
              <td style={{textAlign: "right"}}>
                <FormattedNumber
                  maximumFractionDigits={2}
                  minimumFractionDigits={2}
                  value={-directCosts.other || 0}
                />
              </td>
            </tr>
          ) : null}
          <tr style={{borderBottom: "1px solid black", fontWeight: "bold"}}>
            <td style={{textAlign: "left"}}>
              <FormattedMessage
                defaultMessage="Direkte omkostninger i alt"
                id="machine-analysis.text.direct-costs-total"
              />
            </td>
            <td style={{textAlign: "right"}}>
              <FormattedNumber
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                value={-directCosts.total || 0}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <table style={{borderCollapse: "collapse", marginTop: "2em", width: "100%"}}>
        <colgroup>
          <col />
          <col style={{width: hoursColumnWidth}} />
          <col style={{width: amountColumnWidth}} />
        </colgroup>
        <thead>
          <tr style={{borderBottom: "1px solid black"}}>
            <th style={{textAlign: "left"}}>
              <FormattedMessage
                defaultMessage="Bugseringsomkostninger"
                id="machine-analysis.text.towing"
              />
            </th>
            <th style={{textAlign: "right"}}>
              <FormattedMessage defaultMessage="Timer" id="machine-analysis.table-header.hours" />
            </th>
            <th style={{textAlign: "right"}}>
              <FormattedMessage defaultMessage="kr" id="machine-analysis.table-header.amount" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{textAlign: "left"}}>
              <FormattedMessage
                defaultMessage="Produktiv"
                id="machine-analysis.text.towing-productive"
              />
            </td>
            <td style={{textAlign: "right"}}>
              <FormattedNumber
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                value={towing.productiveTowingHours}
              />
            </td>
            <td style={{textAlign: "right"}}>
              <FormattedNumber
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                value={-towing.productiveTowingCost || 0}
              />
            </td>
          </tr>
          <tr>
            <td style={{textAlign: "left"}}>
              <FormattedMessage
                defaultMessage="Transport"
                id="machine-analysis.text.towing-transport"
              />
            </td>
            <td style={{textAlign: "right"}}>
              <FormattedNumber
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                value={towing.transportTowingHours}
              />
            </td>
            <td style={{textAlign: "right"}}>
              <FormattedNumber
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                value={-towing.transportTowingCost || 0}
              />
            </td>
          </tr>
          <tr style={{borderBottom: "1px solid black", fontWeight: "bold"}}>
            <td style={{textAlign: "left"}}>
              <FormattedMessage
                defaultMessage="Bugsering i alt"
                id="machine-analysis.text.towing-costs-total"
              />
            </td>
            <td style={{textAlign: "right"}}>
              <FormattedNumber
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                value={otherHoursTotal}
              />
            </td>
            <td style={{textAlign: "right"}}>
              <FormattedNumber
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                value={-otherCostsTotal || 0}
              />
            </td>
          </tr>
          <tr style={{borderBottom: "2px solid black", fontWeight: "bold"}}>
            <td style={{textAlign: "left"}}>
              <FormattedMessage
                defaultMessage="Dækningsbidrag 1"
                id="machine-analysis.text.contribution-margin-1"
              />
            </td>
            <td />
            <td style={{textAlign: "right"}}>
              <FormattedNumber
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                value={contributionMargin1 || 0}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <table style={{borderCollapse: "collapse", marginTop: "2em", width: "100%"}}>
        <thead>
          <tr style={{borderBottom: "1px solid black"}}>
            <th style={{textAlign: "left"}}>
              <FormattedMessage
                defaultMessage="Indirekte omkostninger"
                id="machine-analysis.table-header.indirect-costs"
              />
            </th>
            <th style={{textAlign: "right"}}>
              <FormattedMessage defaultMessage="kr" id="machine-analysis.table-header.amount" />
            </th>
          </tr>
        </thead>
        <tbody>
          {indirectCosts.insurance ? (
            <tr>
              <td style={{textAlign: "left"}}>
                <FormattedMessage
                  defaultMessage="Forsikring"
                  id="machine-analysis.text.indirect-costs-insurance"
                />
              </td>
              <td style={{textAlign: "right"}}>
                <FormattedNumber
                  maximumFractionDigits={2}
                  minimumFractionDigits={2}
                  value={-indirectCosts.insurance || 0}
                />
              </td>
            </tr>
          ) : null}
          {indirectCosts.financing ? (
            <tr>
              <td style={{textAlign: "left"}}>
                <FormattedMessage
                  defaultMessage="Finansiering"
                  id="machine-analysis.text.indirect-costs-financing"
                />
              </td>
              <td style={{textAlign: "right"}}>
                <FormattedNumber
                  maximumFractionDigits={2}
                  minimumFractionDigits={2}
                  value={-indirectCosts.financing || 0}
                />
              </td>
            </tr>
          ) : null}
          {Object.entries(economicIndirectCosts).map(([accountNumber, amount]) => (
            <tr key={accountNumber}>
              <td style={{textAlign: "left"}}>
                {accountNumber}: {accountNames[accountNumber]}
              </td>
              <td style={{textAlign: "right"}}>
                <FormattedNumber
                  maximumFractionDigits={2}
                  minimumFractionDigits={2}
                  value={-amount || 0}
                />
              </td>
            </tr>
          ))}
          {indirectCosts.other ? (
            <tr>
              <td style={{textAlign: "left"}}>
                <FormattedMessage
                  defaultMessage="Andet"
                  id="machine-analysis.text.indirect-costs-other"
                />
              </td>
              <td style={{textAlign: "right"}}>
                <FormattedNumber
                  maximumFractionDigits={2}
                  minimumFractionDigits={2}
                  value={-indirectCosts.other || 0}
                />
              </td>
            </tr>
          ) : null}
          <tr style={{borderBottom: "1px solid black", fontWeight: "bold"}}>
            <td style={{textAlign: "left"}}>
              <FormattedMessage
                defaultMessage="Indirekte omkostninger i alt"
                id="machine-analysis.text.indirect-costs-total"
              />
            </td>
            <td style={{textAlign: "right"}}>
              <FormattedNumber
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                value={-indirectCosts.total || 0}
              />
            </td>
          </tr>
          <tr style={{borderBottom: "2px solid black", fontWeight: "bold"}}>
            <td style={{textAlign: "left"}}>
              <FormattedMessage
                defaultMessage="Dækningsbidrag 2"
                id="machine-analysis.text.contribution-margin-2"
              />
            </td>
            <td style={{textAlign: "right"}}>
              <FormattedNumber
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                value={contributionMargin2 || 0}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <table style={{borderCollapse: "collapse", marginTop: "2em", width: "100%"}}>
        <thead>
          <tr style={{borderBottom: "1px solid black"}}>
            <th style={{textAlign: "left"}}>
              <FormattedMessage
                defaultMessage="Opgørelse pr. produktiv time"
                id="machine-analysis.table-header.results-per-productive-hour"
              />
            </th>
            <th style={{textAlign: "right"}}>
              <FormattedMessage
                defaultMessage="kr / produktiv time"
                id="machine-analysis.table-header.amount-productive-hour"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{textAlign: "left"}}>
              <FormattedMessage defaultMessage="Omsætning" id="machine-analysis.text.turnover" />
            </td>
            <td style={{textAlign: "right"}}>
              <FormattedNumber
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                value={perProductiveHour.turnover || 0}
              />
            </td>
          </tr>
          <tr>
            <td style={{textAlign: "left"}}>
              <FormattedMessage
                defaultMessage="Lønomkostninger"
                id="machine-analysis.text.salary-costs"
              />
            </td>
            <td style={{textAlign: "right"}}>
              <FormattedNumber
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                value={-perProductiveHour.salaryCosts || 0}
              />
            </td>
          </tr>
          <tr>
            <td style={{textAlign: "left"}}>
              <FormattedMessage
                defaultMessage="Direkte omkostninger"
                id="machine-analysis.text.direct-costs"
              />
            </td>
            <td style={{textAlign: "right"}}>
              <FormattedNumber
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                value={-perProductiveHour.directCosts || 0}
              />
            </td>
          </tr>
          <tr>
            <td style={{textAlign: "left"}}>
              <FormattedMessage
                defaultMessage="Bugseringsomkostninger"
                id="machine-analysis.text.other-costs"
              />
            </td>
            <td style={{textAlign: "right"}}>
              <FormattedNumber
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                value={-perProductiveHour.otherCosts || 0}
              />
            </td>
          </tr>
          <tr>
            <td style={{textAlign: "left"}}>
              <FormattedMessage
                defaultMessage="Indirekte omkostninger"
                id="machine-analysis.text.indirect-costs"
              />
            </td>
            <td style={{textAlign: "right"}}>
              <FormattedNumber
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                value={-perProductiveHour.indirectCosts || 0}
              />
            </td>
          </tr>
          <tr>
            <td style={{textAlign: "left"}}>
              <FormattedMessage
                defaultMessage="Dækningsbidrag 1"
                id="machine-analysis.text.contribution-margin-1"
              />
            </td>
            <td style={{textAlign: "right"}}>
              <FormattedNumber
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                value={perProductiveHour.contributionMargin1 || 0}
              />
            </td>
          </tr>
          <tr>
            <td style={{textAlign: "left"}}>
              <FormattedMessage
                defaultMessage="Dækningsbidrag 2"
                id="machine-analysis.text.contribution-margin-2"
              />
            </td>
            <td style={{textAlign: "right"}}>
              <FormattedNumber
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                value={perProductiveHour.contributionMargin2 || 0}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
