import {DepartmentDialog, MultipleDepartmentsDialog} from "@co-frontend-libs/components";
import {AppState, getCustomerSettingsMember} from "@co-frontend-libs/redux";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";

interface DepartmentDialogStateProps {
  departments: {readonly [identifier: string]: string};
}

interface DepartmentDialogOwnProps {
  onCancel(): void;
  onOk(identifier: string): void;
  open: boolean;
  showBlankChoice?: boolean;
}

const getDepartments = getCustomerSettingsMember("departments");

export const ConnectedDepartmentDialog: React.ComponentType<DepartmentDialogOwnProps> = connect<
  DepartmentDialogStateProps,
  object,
  DepartmentDialogOwnProps,
  AppState
>(
  createStructuredSelector<AppState, DepartmentDialogStateProps>({
    departments: getDepartments,
  }),
  {},
)(DepartmentDialog);

interface MultipleDepartmentsDialogStateProps {
  departments: {readonly [identifier: string]: string};
}

interface MultipleDepartmentsDialogOwnProps {
  onCancel(): void;
  onOk(identifiers: ReadonlySet<string>): void;
  open: boolean;
  selected?: ReadonlySet<string>;
  showBlankChoice?: boolean;
}

export const ConnectedMultipleDepartmentsDialog: React.ComponentType<MultipleDepartmentsDialogOwnProps> =
  connect<MultipleDepartmentsDialogStateProps, object, MultipleDepartmentsDialogOwnProps, AppState>(
    createStructuredSelector<AppState, MultipleDepartmentsDialogStateProps>({
      departments: getDepartments,
    }),
    {},
  )(MultipleDepartmentsDialog);
