import {EmployeeGroupUrl, WorkTypeUrl} from "@co-common-libs/resources";
import {actions} from "@co-frontend-libs/redux";
import React, {useCallback} from "react";
import {useDispatch} from "react-redux";
import {BookkeepingTable} from "./bookkeeping-table";

interface BookkeepingNormalTabsProps {
  filterString: string;
  selectedDepartmentIdentifierSet: ReadonlySet<string>;
  selectedEmployeeGroupUrlSet: ReadonlySet<EmployeeGroupUrl>;
  selectedWorkTypeURLSet: ReadonlySet<WorkTypeUrl>;
  tab: "readyForBilling" | "readyForValidation";
}

export function BookkeepingNormalTabs(props: BookkeepingNormalTabsProps): JSX.Element {
  const {
    filterString,
    selectedDepartmentIdentifierSet,
    selectedEmployeeGroupUrlSet,
    selectedWorkTypeURLSet,
    tab,
  } = props;
  const dispatch = useDispatch();
  const handleTableClick = useCallback(
    (dateAndMachineOperator: string): void => {
      const [date, machineOperatorId] = dateAndMachineOperator.split(":");
      if (date && machineOperatorId) {
        dispatch(
          actions.go("/bookkeepingDay/:date/:machineOperatorId", {
            date,
            machineOperatorId,
          }),
        );
      }
    },
    [dispatch],
  );
  return (
    <BookkeepingTable
      filterString={filterString}
      selectedDepartmentIdentifierSet={selectedDepartmentIdentifierSet}
      selectedEmployeeGroupUrlSet={selectedEmployeeGroupUrlSet}
      selectedWorkTypeURLSet={selectedWorkTypeURLSet}
      tab={tab}
      onClick={handleTableClick}
    />
  );
}
