import {TaskUrl} from "@co-common-libs/resources";
import {EconomicIssue, EconomicIssueCategory} from "../types";

export function filterByCategory(
  taskIssues: ReadonlyMap<TaskUrl, readonly EconomicIssue[]>,
  category: EconomicIssueCategory,
): ReadonlyMap<TaskUrl, readonly EconomicIssue[]> {
  const taskIssuesFilteredByCategory = new Map<TaskUrl, EconomicIssue[]>();
  for (const [taskUrl, issues] of taskIssues.entries()) {
    const issuesMatchingCategory = issues.filter((issue) => issue.category === category);
    if (issuesMatchingCategory.length) {
      taskIssuesFilteredByCategory.set(taskUrl, issuesMatchingCategory);
    }
  }

  return taskIssuesFilteredByCategory;
}
