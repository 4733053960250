import {Config} from "@co-common-libs/config";
import {
  Customer,
  Delivery,
  DeliveryLocation,
  Location,
  LocationUrl,
  Order,
  OrderUrl,
  Pickup,
  PickupLocation,
  Task,
  TransportLog,
  Unit,
  UnitUrl,
  WorkType,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {getWorkTypeString} from "@co-common-libs/resources-utils";
import {
  formatDateNumeric,
  makeMapFromArray,
  makeObjectFromMap,
  mapMap,
} from "@co-common-libs/utils";
import {FilePdfIcon} from "@co-frontend-libs/components";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {AjaxDownloadButton} from "app-components";
import {PureComponent} from "app-utils";
import {globalConfig} from "frontend-global-config";
import React from "react";
import {FormattedMessage, FormattedNumber, IntlContext, defineMessages} from "react-intl";

const messages = defineMessages({
  downloadPDF: {
    defaultMessage: "Download PDF",
    id: "tranport-log-list.download-pdf",
  },
  noAddress: {
    defaultMessage: "Ingen adresse",
    id: "tranport-log-list.no-address",
  },
  noUnit: {
    defaultMessage: "Ingen enhed",
    id: "tranport-log-list.no-unit",
  },
  total: {
    defaultMessage: "Total",
    id: "tranport-log-list.total",
  },
});

interface WorkTypeRowProps {
  workType?: WorkType | undefined;
}

class WorkTypeRow extends PureComponent<WorkTypeRowProps> {
  render(): JSX.Element {
    const {workType} = this.props;
    return (
      <TableRow>
        <TableCell style={{fontWeight: "bold"}}>{getWorkTypeString(workType)}</TableCell>
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />
      </TableRow>
    );
  }
}

interface UnitRowProps {
  unit: string;
}

class UnitRow extends PureComponent<UnitRowProps> {
  render(): JSX.Element {
    const {unit} = this.props;
    return (
      <TableRow>
        <TableCell style={{fontWeight: "bold"}}>{unit}</TableCell>
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />
      </TableRow>
    );
  }
}

interface LocationSumRowProps {
  bold?: boolean;
  delivered: number;
  deliveryTrips?: number;
  location: string;
  pickedUp: number;
  pickupTrips?: number;
  unit: string;
}

class LocationSumRow extends PureComponent<LocationSumRowProps> {
  render(): JSX.Element {
    const {bold, delivered, deliveryTrips, location, pickedUp, pickupTrips, unit} = this.props;
    const extraStyles: React.CSSProperties = bold
      ? {borderBottom: "2px solid #999", fontWeight: "bold"}
      : {};
    return (
      <TableRow style={extraStyles}>
        <TableCell>{location}</TableCell>
        <TableCell>
          <FormattedNumber value={pickedUp} /> {unit}
        </TableCell>
        <TableCell>{pickupTrips}</TableCell>
        <TableCell>
          <FormattedNumber value={delivered} /> {unit}
        </TableCell>
        <TableCell>{deliveryTrips}</TableCell>
      </TableRow>
    );
  }
}

interface TransportLogListProps {
  customer: Customer;
  customerName: string;
  customerSettings: Config;
  deliveryArray: readonly Delivery[];
  deliveryLocationArray: readonly DeliveryLocation[];
  fromDate?: string | undefined;
  locationLookup: (url: LocationUrl) => Location | undefined;
  orderLookup: (url: OrderUrl) => Order | undefined;
  pickupArray: readonly Pickup[];
  pickupLocationArray: readonly PickupLocation[];
  taskList: readonly Task[];
  toDate?: string | undefined;
  token: string | null;
  transportLogArray: readonly TransportLog[];
  unitLookup: (url: UnitUrl) => Unit | undefined;
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
}

export class TransportLogList extends PureComponent<TransportLogListProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  updateLocationSumMap(
    pickupLocations: Map<string, PickupLocation>,
    deliveryLocations: Map<string, DeliveryLocation>,
    locationSumMap: Map<
      string,
      {
        delivered: number;
        deliveryTrips: number;
        pickedUp: number;
        pickupTrips: number;
      }
    >,
  ): void {
    const {formatMessage} = this.context;
    pickupLocations.forEach((pickupLocation) => {
      const pickupLocationUrl = pickupLocation.url;
      const pickups = this.props.pickupArray.filter((p) => p.location === pickupLocationUrl);
      const location = pickupLocation.relatedLocation
        ? this.props.locationLookup(pickupLocation.relatedLocation)
        : null;
      let address = location ? location.name || location.address : pickupLocation.address;

      if (!address) {
        address = formatMessage(messages.noAddress);
      }
      pickups.forEach((pickup) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const sumItemRow = locationSumMap.get(address!);
        if (!sumItemRow) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          locationSumMap.set(address!, {
            delivered: 0,
            deliveryTrips: 0,
            pickedUp: pickup.amount || 0,
            pickupTrips: 1,
          });
        } else {
          sumItemRow.pickedUp += pickup.amount || 0;
          sumItemRow.pickupTrips += 1;
        }
      });
    });
    deliveryLocations.forEach((deliveryLocation) => {
      const deliveryLocationUrl = deliveryLocation.url;
      const deliveries = this.props.deliveryArray.filter((p) => p.location === deliveryLocationUrl);
      const location = deliveryLocation.relatedLocation
        ? this.props.locationLookup(deliveryLocation.relatedLocation)
        : null;
      let address = location ? location.name || location.address : deliveryLocation.address;

      if (!address) {
        address = formatMessage(messages.noAddress);
      }
      deliveries.forEach((delivery) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const sumItemRow = locationSumMap.get(address!);
        if (!sumItemRow) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          locationSumMap.set(address!, {
            delivered: delivery.amount || 0,
            deliveryTrips: 1,
            pickedUp: 0,
            pickupTrips: 0,
          });
        } else {
          sumItemRow.delivered += delivery.amount || 0;
          sumItemRow.deliveryTrips += 1;
        }
      });
    });
  }

  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {
      customer,
      customerName,
      deliveryLocationArray,
      orderLookup,
      pickupLocationArray,
      transportLogArray,
      unitLookup,
      workTypeLookup,
    } = this.props;
    const rowArray: JSX.Element[] = [];
    const pdfSum = new Map<
      string,
      {
        heading: string;
        logs: Map<
          string,
          {
            delivered: number;
            deliveryTrips: number;
            pickedUp: number;
            pickupTrips: number;
          }
        >;
        sum: {
          delivered: number;
          deliveryTrips: number;
          pickedUp: number;
          pickupTrips: number;
        };
        unit: string;
      }
    >();
    const customerURL = customer.url;
    if (this.props.customerSettings.noExternalTaskWorkType) {
      const unitLocationMap = new Map<
        string,
        {
          deliveryLocations: Map<string, DeliveryLocation>;
          pickupLocations: Map<string, PickupLocation>;
          relatedUnit: Unit | null;
          unit: string;
        }
      >();
      const taskURLCustomerURLMapping = new Map<string, string | null>();
      this.props.taskList.forEach((task) => {
        const taskURL = task.url;
        const orderURL = task.order;
        const order = orderURL ? orderLookup(orderURL) : null;
        const orderCustomerURL = order ? order.customer : null;
        taskURLCustomerURLMapping.set(taskURL, orderCustomerURL);
      });
      transportLogArray.forEach((transportLog) => {
        const taskURL = transportLog.task;
        if (!taskURLCustomerURLMapping.has(taskURL)) {
          return;
        }
        const taskIsForTheCustomer = taskURLCustomerURLMapping.get(taskURL) === customerURL;

        const transportLogUrl = transportLog.url;
        if (this.props.customerSettings.transportLogUnitPerLocation) {
          const pickupLocations = pickupLocationArray.filter((l) => {
            return (
              l.transportlog === transportLogUrl &&
              (taskIsForTheCustomer || l.customer === customerURL)
            );
          });
          const deliveryLocations = deliveryLocationArray.filter((l) => {
            return (
              l.transportlog === transportLogUrl &&
              (taskIsForTheCustomer || l.customer === customerURL)
            );
          });

          pickupLocations.forEach((pickupLocation) => {
            const relatedUnitUrl = pickupLocation.relatedUnit;
            const relatedUnit = relatedUnitUrl ? unitLookup(relatedUnitUrl) : null;
            const unit = relatedUnit ? relatedUnit.name : pickupLocation.unit;
            const unitKey = unit;
            const unitTaskItem = unitLocationMap.get(unitKey);

            if (unitTaskItem) {
              unitTaskItem.pickupLocations.set(pickupLocation.url, pickupLocation);
            } else {
              unitLocationMap.set(unitKey, {
                deliveryLocations: new Map(),
                pickupLocations: new Map([[pickupLocation.url, pickupLocation]]),
                relatedUnit: relatedUnit || null,
                unit,
              });
            }
          });
          deliveryLocations.forEach((deliveryLocation) => {
            const relatedUnitUrl = deliveryLocation.relatedUnit;
            const relatedUnit = relatedUnitUrl ? unitLookup(relatedUnitUrl) : null;
            const unit = relatedUnit ? relatedUnit.name : deliveryLocation.unit;
            const unitKey = unit;
            const unitTaskItem = unitLocationMap.get(unitKey);

            if (unitTaskItem) {
              unitTaskItem.deliveryLocations.set(deliveryLocation.url, deliveryLocation);
            } else {
              unitLocationMap.set(unitKey, {
                deliveryLocations: new Map([[deliveryLocation.url, deliveryLocation]]),
                pickupLocations: new Map(),
                relatedUnit: relatedUnit || null,
                unit,
              });
            }
          });
        } else {
          const relatedUnitUrl = transportLog.relatedUnit;
          const relatedUnit = relatedUnitUrl ? unitLookup(relatedUnitUrl) : null;
          const unit = relatedUnit ? relatedUnit.name : transportLog.unit;
          const unitKey = unit;

          const pickupLocations = pickupLocationArray.filter((t) => {
            return (
              t.transportlog === transportLogUrl &&
              (taskIsForTheCustomer || t.customer === customerURL)
            );
          });
          const deliveryLocations = deliveryLocationArray.filter((t) => {
            return (
              t.transportlog === transportLogUrl &&
              (taskIsForTheCustomer || t.customer === customerURL)
            );
          });

          const unitTaskItem = unitLocationMap.get(unitKey);
          if (unitTaskItem) {
            pickupLocations.forEach((pickupLocation) => {
              unitTaskItem.pickupLocations.set(pickupLocation.url, pickupLocation);
            });
            deliveryLocations.forEach((deliveryLocation) => {
              unitTaskItem.deliveryLocations.set(deliveryLocation.url, deliveryLocation);
            });
          } else {
            unitLocationMap.set(unitKey, {
              deliveryLocations: makeMapFromArray(
                deliveryLocations,
                (deliveryLocation) => deliveryLocation.url,
              ),
              pickupLocations: makeMapFromArray(
                pickupLocations,
                (pickupLocation) => pickupLocation.url,
              ),
              relatedUnit: relatedUnit || null,
              unit,
            });
          }
        }
      });

      const unitLocationMapKeys = Array.from(unitLocationMap.keys());
      unitLocationMapKeys.sort();
      unitLocationMapKeys.forEach((unitKey) => {
        const sum = new Map<
          string,
          {
            delivered: number;
            deliveryTrips: number;
            pickedUp: number;
            pickupTrips: number;
          }
        >();
        const {deliveryLocations, pickupLocations, relatedUnit, unit} = unitLocationMap.get(
          unitKey,
        ) as {
          deliveryLocations: Map<string, DeliveryLocation>;
          pickupLocations: Map<string, PickupLocation>;
          relatedUnit: Unit | null;
          unit: string;
        };
        const unitString = relatedUnit ? relatedUnit.symbol || relatedUnit.name : unit;
        this.updateLocationSumMap(pickupLocations, deliveryLocations, sum);

        const sumKeys = Array.from(sum.keys());
        if (sumKeys.length > 0) {
          let pickedUpSum = 0;
          let deliveredSum = 0;
          let pickupTripsSum = 0;
          let deliveryTripsSum = 0;

          rowArray.push(<UnitRow key={unitKey} unit={unitString} />);

          sumKeys.sort();
          sumKeys.forEach((ident) => {
            const sumItem = sum.get(ident) as {
              delivered: number;
              deliveryTrips: number;
              pickedUp: number;
              pickupTrips: number;
            };
            pickedUpSum += sumItem.pickedUp;
            deliveredSum += sumItem.delivered;
            pickupTripsSum += sumItem.pickupTrips;
            deliveryTripsSum += sumItem.deliveryTrips;
            rowArray.push(
              <LocationSumRow
                key={`${ident}-${unitKey}`}
                delivered={sumItem.delivered}
                deliveryTrips={sumItem.deliveryTrips}
                location={ident}
                pickedUp={sumItem.pickedUp}
                pickupTrips={sumItem.pickupTrips}
                unit={unitString}
              />,
            );
          });
          pdfSum.set(unitKey, {
            heading: unitString ? unitString : formatMessage(messages.noUnit),
            logs: sum,
            sum: {
              delivered: deliveredSum,
              deliveryTrips: deliveryTripsSum,
              pickedUp: pickedUpSum,
              pickupTrips: pickupTripsSum,
            },
            unit: unitString,
          });
          rowArray.push(
            <LocationSumRow
              key={`sum-${unitKey}`}
              bold
              delivered={deliveredSum}
              deliveryTrips={deliveryTripsSum}
              location={formatMessage(messages.total)}
              pickedUp={pickedUpSum}
              pickupTrips={pickupTripsSum}
              unit={unitString}
            />,
          );
        }
      });
    } else {
      const workTypeLocationMap = new Map<
        string,
        {
          deliveryLocations: Map<string, DeliveryLocation>;
          pickupLocations: Map<string, PickupLocation>;
          relatedUnit: Unit | null;
          unit: string;
          workType: WorkType | undefined;
        }
      >();
      this.props.taskList.forEach((task) => {
        const order = task.order ? orderLookup(task.order) : null;
        const taskIsForTheCustomer = order && order.customer === customerURL;

        const workTypeURL = task.workType;
        const workType = workTypeURL ? workTypeLookup(workTypeURL) : undefined;
        const transportLog = transportLogArray.find((log) => log.task === task.url);

        if (!transportLog) {
          return;
        }

        const transportLogUrl = transportLog.url;
        if (this.props.customerSettings.transportLogUnitPerLocation) {
          const pickupLocations = pickupLocationArray.filter((l) => {
            return (
              l.transportlog === transportLogUrl &&
              (taskIsForTheCustomer || l.customer === customerURL)
            );
          });
          const deliveryLocations = deliveryLocationArray.filter((l) => {
            return (
              l.transportlog === transportLogUrl &&
              (taskIsForTheCustomer || l.customer === customerURL)
            );
          });

          pickupLocations.forEach((pickupLocation) => {
            const relatedUnitUrl = pickupLocation.relatedUnit;
            const relatedUnit = relatedUnitUrl ? unitLookup(relatedUnitUrl) : null;
            const unit = relatedUnit ? relatedUnit.name : pickupLocation.unit;
            const unitKey = unit;

            const workTypeKey = `${getWorkTypeString(workType)}-${unitKey}`;
            const workTypeTaskItem = workTypeLocationMap.get(workTypeKey);
            const pickupLocationUrl = pickupLocation.url;

            if (workTypeTaskItem) {
              workTypeTaskItem.pickupLocations.set(pickupLocationUrl, pickupLocation);
            } else {
              workTypeLocationMap.set(workTypeKey, {
                deliveryLocations: new Map(),
                pickupLocations: new Map([[pickupLocationUrl, pickupLocation]]),
                relatedUnit: relatedUnit || null,
                unit,
                workType,
              });
            }
          });
          deliveryLocations.forEach((deliveryLocation) => {
            const relatedUnitUrl = deliveryLocation.relatedUnit;
            const relatedUnit = relatedUnitUrl ? unitLookup(relatedUnitUrl) : null;
            const unit = relatedUnit ? relatedUnit.name : deliveryLocation.unit;
            const unitKey = unit;

            const workTypeKey = `${getWorkTypeString(workType)}-${unitKey}`;
            const workTypeTaskItem = workTypeLocationMap.get(workTypeKey);
            const deliveryLocationUrl = deliveryLocation.url;

            if (workTypeTaskItem) {
              workTypeTaskItem.deliveryLocations.set(deliveryLocationUrl, deliveryLocation);
            } else {
              workTypeLocationMap.set(workTypeKey, {
                deliveryLocations: new Map([[deliveryLocationUrl, deliveryLocation]]),
                pickupLocations: new Map(),
                relatedUnit: relatedUnit || null,
                unit,
                workType,
              });
            }
          });
        } else {
          const relatedUnitUrl = transportLog.relatedUnit;
          const relatedUnit = relatedUnitUrl ? unitLookup(relatedUnitUrl) : null;
          const unit = relatedUnit ? relatedUnit.name : transportLog.unit;
          const unitKey = unit;

          const pickupLocations = pickupLocationArray.filter((t) => {
            return (
              t.transportlog === transportLogUrl &&
              (taskIsForTheCustomer || t.customer === customerURL)
            );
          });
          const deliveryLocations = deliveryLocationArray.filter((t) => {
            return (
              t.transportlog === transportLogUrl &&
              (taskIsForTheCustomer || t.customer === customerURL)
            );
          });

          const workTypeKey = `${getWorkTypeString(workType)}-${unitKey}`;
          const workTypeTaskItem = workTypeLocationMap.get(workTypeKey);

          if (workTypeTaskItem) {
            pickupLocations.forEach((pickupLocation) => {
              workTypeTaskItem.pickupLocations.set(pickupLocation.url, pickupLocation);
            });
            deliveryLocations.forEach((deliveryLocation) => {
              workTypeTaskItem.deliveryLocations.set(deliveryLocation.url, deliveryLocation);
            });
          } else {
            workTypeLocationMap.set(workTypeKey, {
              deliveryLocations: makeMapFromArray(
                deliveryLocations,
                (deliveryLocation) => deliveryLocation.url,
              ),
              pickupLocations: makeMapFromArray(
                pickupLocations,
                (pickupLocation) => pickupLocation.url,
              ),
              relatedUnit: relatedUnit || null,
              unit,
              workType,
            });
          }
        }
      });

      const workTypeLocationMapKeys = Array.from(workTypeLocationMap.keys());
      workTypeLocationMapKeys.sort();

      workTypeLocationMapKeys.forEach((workTypeKey) => {
        const sum = new Map<
          string,
          {
            delivered: number;
            deliveryTrips: number;
            pickedUp: number;
            pickupTrips: number;
          }
        >();
        const {deliveryLocations, pickupLocations, relatedUnit, unit, workType} =
          workTypeLocationMap.get(workTypeKey) as {
            deliveryLocations: Map<string, DeliveryLocation>;
            pickupLocations: Map<string, PickupLocation>;
            relatedUnit: Unit | null;
            unit: string;
            workType: WorkType | undefined;
          };
        const unitString = relatedUnit ? relatedUnit.symbol || relatedUnit.name : unit;

        this.updateLocationSumMap(pickupLocations, deliveryLocations, sum);

        const sumKeys = Array.from(sum.keys());
        if (sumKeys.length > 0) {
          let pickedUpSum = 0;
          let deliveredSum = 0;
          let pickupTripsSum = 0;
          let deliveryTripsSum = 0;

          rowArray.push(<WorkTypeRow key={workTypeKey} workType={workType} />);

          sumKeys.sort();
          sumKeys.forEach((ident) => {
            const sumItem = sum.get(ident) as {
              delivered: number;
              deliveryTrips: number;
              pickedUp: number;
              pickupTrips: number;
            };
            pickedUpSum += sumItem.pickedUp;
            deliveredSum += sumItem.delivered;
            pickupTripsSum += sumItem.pickupTrips;
            deliveryTripsSum += sumItem.deliveryTrips;
            rowArray.push(
              <LocationSumRow
                key={`${ident}-${workTypeKey}`}
                delivered={sumItem.delivered}
                deliveryTrips={sumItem.deliveryTrips}
                location={ident}
                pickedUp={sumItem.pickedUp}
                pickupTrips={sumItem.pickupTrips}
                unit={unitString}
              />,
            );
          });
          pdfSum.set(workTypeKey, {
            heading: workType ? workType.name : "",
            logs: sum,
            sum: {
              delivered: deliveredSum,
              deliveryTrips: deliveryTripsSum,
              pickedUp: pickedUpSum,
              pickupTrips: pickupTripsSum,
            },
            unit: unitString,
          });
          rowArray.push(
            <LocationSumRow
              key={`sum-${workTypeKey}`}
              bold
              delivered={deliveredSum}
              deliveryTrips={deliveryTripsSum}
              location={formatMessage(messages.total)}
              pickedUp={pickedUpSum}
              pickupTrips={pickupTripsSum}
              unit={unitString}
            />,
          );
        }
      });
    }

    const pdfDataObject = makeObjectFromMap(
      mapMap(pdfSum, (entry) => ({
        ...entry,
        logs: makeObjectFromMap(entry.logs),
      })),
    );

    const pdfUrl = `${globalConfig.baseURL}/download/transportlog_report/pdf`;
    const pdfData = {
      customerName,
      data: pdfDataObject,
      deviceTime: Date.now(),
      fromDateString: formatDateNumeric(this.props.fromDate),
      toDateString: formatDateNumeric(this.props.toDate),
      withWorkType: !this.props.customerSettings.noExternalTaskWorkType,
    };

    return (
      <div>
        <AjaxDownloadButton
          data={pdfData}
          downloadURL={pdfUrl}
          filename="transportlog.pdf"
          Icon={FilePdfIcon}
          label={formatMessage(messages.downloadPDF)}
          token={this.props.token}
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Sted"
                  id="customer-instance.table-header.location"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Afhentet"
                  id="customer-instance.table-header.pickedUp"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Læs, afhentet"
                  id="customer-instance.table-header.pickupTrips"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Leveret"
                  id="customer-instance.table-header.delivered"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Læs, leveret"
                  id="customer-instance.table-header.deliveryTrips"
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{rowArray}</TableBody>
        </Table>
      </div>
    );
  }
}
