import {frontendVersion} from "@co-common-libs/frontend-version";
import {getFrontendSentry} from "@co-frontend-libs/utils";
import * as Sentry from "@sentry/browser";
import bowser from "bowser";
import {globalConfig} from "frontend-global-config";
import React from "react";
import {createRoot} from "react-dom/client";
import App from "./app";
import "./load-css";

const SENTRY_DENY_URLS = [/maps\.googleapis\.com\//i, /chrome-extension/i];

const sentry = getFrontendSentry();
const sentryOptions: Sentry.BrowserOptions = {
  attachStacktrace: true,
  autoSessionTracking: true,
  dsn: process.env.FRONTEND_SENTRY_DSN as string,
  enabled: process.env.NODE_ENV === "production",
  normalizeDepth: 0,
  release: frontendVersion,
};
if (sentry.SDK_VERSION < "7.0.0") {
  sentryOptions.denyUrls = SENTRY_DENY_URLS;
} else {
  (sentryOptions as any).blacklistUrls = SENTRY_DENY_URLS;
}
sentry.init(sentryOptions);
sentry.configureScope((scope) => {
  const {hostname} = new URL(globalConfig.baseURL);
  scope.setTag("hostname", hostname);
  if ((window as any).APP_VERSION) {
    scope.setTag("appVersion", (window as any).APP_VERSION);
  }
});

if (process.env.NODE_ENV === "production") {
  console.assert = (condition?: boolean, ...data: any[]): void => {
    if (!condition) {
      // eslint-disable-next-line no-console
      console.error(...data);
      sentry.captureMessage("console.assert", "error");
    }
  };
}

const manifest = window.localStorage && window.localStorage.getItem("manifest");
if (manifest) {
  localStorage.setItem("last_good_manifest", manifest);
}

(window as any).BOOTSTRAP_OK = true;
const isIpad = navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1;
bowser.tablet = bowser.tablet || isIpad;
bowser.mobile = !bowser.tablet && bowser.mobile;

// https://react.dev/blog/2022/03/08/react-18-upgrade-guide#updates-to-client-rendering-apis
const rootEl = document.getElementById("main");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(rootEl!); // createRoot(container!) if you use TypeScript
root.render(<App />);
