import {CustomerUrl, ResourceTypeUnion, Task} from "@co-common-libs/resources";
import {TranslatedError} from "@co-common-libs/utils";
import {actions} from "@co-frontend-libs/redux";
import {jsonFetch, translateNetworkError, useResettingState} from "@co-frontend-libs/utils";
import {useCustomerFromTask} from "app-utils";
import {globalConfig} from "frontend-global-config";
import React, {useCallback, useEffect} from "react";
import {useIntl} from "react-intl";
import {batch, useDispatch} from "react-redux";
import {ProcessingDialog} from "../processing-dialog";

interface MergeTaskCustomerProps {
  onFailure: () => void;
  onSuccess: () => void;
  open: boolean;
  task: Task;
  toCustomerUrl: CustomerUrl;
}

export const MergeTaskCustomer = React.memo(function MergeTaskCustomer(
  props: MergeTaskCustomerProps,
): JSX.Element | null {
  const {onFailure, onSuccess, open, task, toCustomerUrl} = props;

  const [mergePromise, setMergePromise] = useResettingState<Promise<ResourceTypeUnion[]> | null>(
    null,
    !open,
  );

  const dispatch = useDispatch();
  const intl = useIntl();

  const fromCustomer = useCustomerFromTask(task);

  useEffect(() => {
    if (open) {
      setMergePromise(async () => {
        if (!fromCustomer) {
          throw new TranslatedError(
            intl.formatMessage({
              defaultMessage: "Kunne ikke finde kunde på opgaven",
            }),
          );
        }

        const {baseURL} = globalConfig.resources;
        const url = `${baseURL}merge_customers`;

        try {
          const {data} = await jsonFetch(url, "POST", {
            customer: fromCustomer.url,
            mergeTo: toCustomerUrl,
          });
          return data;
        } catch (error) {
          throw new TranslatedError(translateNetworkError(error, intl));
        }
      });
    }
    // execute promise on open
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleProcessingSuccess = useCallback(
    (data: ResourceTypeUnion[]) => {
      batch(() => {
        dispatch(actions.addToOffline(data));
        onSuccess();
      });
    },
    [dispatch, onSuccess],
  );

  return mergePromise ? (
    <ProcessingDialog
      processPromise={mergePromise}
      title={intl.formatMessage({defaultMessage: "Skifter kunde"})}
      onFailure={onFailure}
      onSuccess={handleProcessingSuccess}
    />
  ) : null;
});
