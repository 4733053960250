import {ProductGroupUrl} from "../resource-urls";
import {ResourceInstance} from "./base";

export interface ProductGroup extends ResourceInstance<ProductGroupUrl> {
  readonly active: boolean;
  readonly barred: boolean;
  readonly c5_recid: number | null;
  readonly contributesToFuelSurchargeSalesPrice: boolean;
  readonly identifier: string;
  readonly name: string;
  readonly parent: ProductGroupUrl | null;
  readonly photoUrl: string;
  readonly remoteUrl: string;
  readonly salesAccount: number | null;
}

export const emptyProductGroup: Omit<ProductGroup, "id" | "url"> = {
  active: true,
  barred: false,
  c5_recid: null,
  contributesToFuelSurchargeSalesPrice: false,
  identifier: "",
  name: "",
  parent: null,
  photoUrl: "",
  remoteUrl: "",
  salesAccount: null,
};
