import {
  CustomerUrl,
  LocationUrl,
  MachineUrl,
  PriceGroupUrl,
  ProductUrl,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {getCustomerSettings} from "@co-frontend-libs/redux";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Grid,
} from "@material-ui/core";
import React from "react";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {LocationFiltering} from "../location-filtering";
import {ProductFiltering} from "../product-filtering";
import {FilterOptions} from "../types";
import {CustomerFiltering} from "./customer-filtering";
import {MachineFiltering} from "./machine-filtering";
import {WorkTypeFiltering} from "./work-type-filtering";

interface FilteringSetupProps {
  filteringOptions: FilterOptions;
  onOk: () => void;
  onRestrictToAndFromToLocationCustomersCheck?: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  onSelectedInvoiceCustomersChange: (value: ReadonlySet<CustomerUrl>) => void;
  onSelectedLocationCustomersChange: (value: ReadonlySet<CustomerUrl>) => void;
  onSelectedLocationsChange?: (value: ReadonlySet<LocationUrl>) => void;
  onSelectedMachinesPriceGroupsChange: (
    value: ReadonlyMap<MachineUrl, ReadonlySet<PriceGroupUrl>>,
  ) => void;
  onSelectedProductsChange?: (value: ReadonlySet<ProductUrl>) => void;
  onSelectedWorkTypesPriceGroupsChange: (
    value: ReadonlyMap<WorkTypeUrl, ReadonlySet<PriceGroupUrl>>,
  ) => void;
  restrictToAndFromToLocationCustomers?: boolean;
  selectedInvoiceCustomers: ReadonlySet<CustomerUrl>;
  selectedLocationCustomers: ReadonlySet<CustomerUrl>;
  selectedLocations?: ReadonlySet<LocationUrl>;
  selectedMachinesPriceGroups: ReadonlyMap<MachineUrl, ReadonlySet<PriceGroupUrl>>;
  selectedProducts?: ReadonlySet<ProductUrl>;
  selectedWorkTypesPriceGroups: ReadonlyMap<WorkTypeUrl, ReadonlySet<PriceGroupUrl>>;
}

export function FilteringSetup(props: FilteringSetupProps): JSX.Element {
  const {
    filteringOptions,
    onOk,
    onRestrictToAndFromToLocationCustomersCheck,
    onSelectedInvoiceCustomersChange,
    onSelectedLocationCustomersChange,
    onSelectedLocationsChange,
    onSelectedMachinesPriceGroupsChange,
    onSelectedProductsChange,
    onSelectedWorkTypesPriceGroupsChange,
    restrictToAndFromToLocationCustomers = false,
    selectedInvoiceCustomers,
    selectedLocationCustomers,
    selectedLocations,
    selectedMachinesPriceGroups,
    selectedProducts,
    selectedWorkTypesPriceGroups,
  } = props;

  const customerSettings = useSelector(getCustomerSettings);

  const gridSizing = {
    lg: 3,
    sm: 6,
    xs: 12,
  } as const;

  return (
    <Card>
      <CardHeader title={<FormattedMessage defaultMessage="Angiv filtrering" />} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item {...gridSizing}>
            <CustomerFiltering
              buttonLabel={<FormattedMessage defaultMessage="Vælg fakturakunder" />}
              potentialCustomers={filteringOptions.invoiceCustomers}
              selectedCustomers={selectedInvoiceCustomers}
              title={<FormattedMessage defaultMessage="Fakturakunder" />}
              onSelectedCustomersChange={onSelectedInvoiceCustomersChange}
            />
          </Grid>
          <Grid item {...gridSizing}>
            <CustomerFiltering
              buttonLabel={<FormattedMessage defaultMessage="Vælg stedkunder" />}
              potentialCustomers={filteringOptions.locationCustomers}
              selectedCustomers={selectedLocationCustomers}
              title={<FormattedMessage defaultMessage="Stedkunder" />}
              onSelectedCustomersChange={onSelectedLocationCustomersChange}
            />
            {onRestrictToAndFromToLocationCustomersCheck && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={restrictToAndFromToLocationCustomers}
                    onChange={onRestrictToAndFromToLocationCustomersCheck}
                  />
                }
                disabled={!selectedLocationCustomers.size}
                label={
                  <FormattedMessage
                    defaultMessage={
                      "Vis kun bevægelser hvor både til- og fra-stedet tilhører de " +
                      "udvalgte stedkunder"
                    }
                  />
                }
              />
            )}
          </Grid>
          {customerSettings.noExternalTaskWorkType ? null : (
            <Grid item {...gridSizing}>
              <WorkTypeFiltering
                options={filteringOptions.workTypes}
                selectedWorkTypesPriceGroups={selectedWorkTypesPriceGroups}
                onSelectedWorkTypesPriceGroupsChange={onSelectedWorkTypesPriceGroupsChange}
              />
            </Grid>
          )}
          <Grid item {...gridSizing}>
            <MachineFiltering
              options={filteringOptions.machines}
              selectedMachinesPriceGroups={selectedMachinesPriceGroups}
              onSelectedMachinesPriceGroupsChange={onSelectedMachinesPriceGroupsChange}
            />
          </Grid>
          {onSelectedProductsChange && filteringOptions.products && selectedProducts ? (
            <Grid item {...gridSizing}>
              <ProductFiltering
                options={filteringOptions.products}
                selected={selectedProducts}
                onSelectedChange={onSelectedProductsChange}
              />
            </Grid>
          ) : null}
          {onSelectedLocationsChange && filteringOptions.locations && selectedLocations ? (
            <Grid item {...gridSizing}>
              <LocationFiltering
                options={filteringOptions.locations}
                selected={selectedLocations}
                onSelectedChange={onSelectedLocationsChange}
              />
            </Grid>
          ) : null}
        </Grid>
      </CardContent>
      <CardActions>
        <Button color="primary" variant="contained" onClick={onOk}>
          <FormattedMessage defaultMessage="Hent" />
        </Button>
      </CardActions>
    </Card>
  );
}
