import {
  PriceItem,
  PriceItemUrl,
  ReportingSpecification,
  Task,
  Unit,
  UnitUrl,
} from "@co-common-libs/resources";
import {getReadonlyPriceItems} from "@co-common-libs/resources-utils";

export const areReadOnlyMaterialsUsed = (
  task: Task,
  logSpecification: ReportingSpecification,
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined,
  unitLookup: (url: UnitUrl) => Unit | undefined,
): boolean => {
  const productsReadonly = !!(
    logSpecification.workplaceData.workplace?.logProducts ||
    logSpecification.workplaceData.delivery?.logProducts
  );
  const readonlyPriceItems = task.priceItemUses
    ? getReadonlyPriceItems(
        logSpecification,
        Object.values(task.priceItemUses || {}),
        priceItemLookup,
        unitLookup,
      )
    : null;
  const readOnlyMaterialsUsed = !!(
    (productsReadonly &&
      task.productUses &&
      Object.values(task.productUses || {}).some((productUse) => productUse.count)) ||
    (readonlyPriceItems?.size &&
      task.priceItemUses &&
      Object.values(task.priceItemUses || {}).some(
        (priceItemUse) => priceItemUse.count && readonlyPriceItems.has(priceItemUse.priceItem),
      ))
  );

  return readOnlyMaterialsUsed;
};
