export function entriesForEach<T, K extends string>(
  record: Record<K, T>,
  callback: (key: K, value: T) => void,
): void {
  const keys = Object.keys(record) as K[];
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    const value = record[key];
    callback(key, value);
  }
}

export function partialEntriesForEach<T, K extends string>(
  record: Partial<Record<K, T>>,
  callback: (key: K, value: T) => void,
): void {
  const keys = Object.keys(record) as K[];
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    const value = record[key];
    if (value !== undefined) {
      callback(key, value as T);
    }
  }
}

export function partialValuesForEach<V>(
  record: Partial<Record<string, V>>,
  callback: (value: V) => void,
): void {
  const values = Object.values(record);
  for (let i = 0; i < values.length; i += 1) {
    const value = values[i];
    if (value !== undefined) {
      callback(value);
    }
  }
}

export function partialValuesMap<V, R>(
  record: Partial<Record<string, V>>,
  callback: (value: V) => R,
): R[] {
  const values = Object.values(record);
  const result: R[] = [];
  for (let i = 0; i < values.length; i += 1) {
    const value = values[i];
    if (value !== undefined) {
      result.push(callback(value));
    }
  }
  return result;
}

export function partialValuesSome<V>(
  record: Partial<Record<string, V>>,
  callback: (value: V) => boolean,
): boolean {
  const values = Object.values(record);
  for (let i = 0; i < values.length; i += 1) {
    const value = values[i];
    if (value !== undefined) {
      if (callback(value)) {
        return true;
      }
    }
  }
  return false;
}

export function partialValuesEvery<V>(
  record: Partial<Record<string, V>>,
  callback: (value: V) => boolean,
): boolean {
  const values = Object.values(record);
  for (let i = 0; i < values.length; i += 1) {
    const value = values[i];
    if (value !== undefined) {
      if (!callback(value as V)) {
        return false;
      }
    }
  }
  return true;
}
