import {useTheme} from "@material-ui/core";
import React from "react";
import {SettingValueDisplayProps} from "../types";

const JSON_INDENT = 2;

export function DefaultValueDisplayComponent(props: SettingValueDisplayProps): JSX.Element | null {
  const {settingEntry, settingMetaData} = props;

  const theme = useTheme();

  if (!settingEntry || settingMetaData.schema.type === "boolean") {
    return null;
  }

  const {data} = settingEntry;
  const {schema} = settingMetaData;
  if (
    schema.type === "array" &&
    schema.uniqueItems &&
    Array.isArray((schema as unknown as any)?.items?.enum) &&
    Array.isArray(data)
  ) {
    return (
      <div>
        {data.map((value, index) => (
          <div key={`${settingEntry.key}-${index}`}>
            <span
              style={{
                backgroundColor: theme.palette.grey[200],
                padding: 2,
              }}
            >
              {value}
            </span>
          </div>
        ))}
      </div>
    );
  } else {
    return (
      <div>
        <span style={{backgroundColor: theme.palette.grey[200], padding: 2}}>
          {JSON.stringify(data, null, JSON_INDENT)}
        </span>
      </div>
    );
  }
}
