import {Timer, TimerUrl} from "@co-common-libs/resources";
import {formatMinutes} from "@co-common-libs/resources-utils";
import {SECOND_MILLISECONDS} from "@co-common-libs/utils";
import {
  Button,
  ButtonGroup,
  Theme,
  createStyles,
  darken,
  lighten,
  makeStyles,
} from "@material-ui/core";
import TimerOutlineIcon from "mdi-react/TimerOutlineIcon";
import React, {useCallback, useEffect, useRef, useState} from "react";

interface TimeButtonInnerProps {
  disabled: boolean;
  isActive: boolean;
  onTimerButton: () => void;
  text: string;
  time: string;
  timerColor: string;
}

interface TimeButtonInnerStylingProps {
  disabled: boolean;
  isActive: boolean;
  timerColor: string;
}

const TIMING_LIGHTEN_FACTOR = 1.5;
const HOVER_DARKEN_FACTOR = 1.5;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonGroup: {
      "& $timingPart": {
        backgroundColor: (props: TimeButtonInnerStylingProps) =>
          props.disabled && props.timerColor
            ? lighten(
                props.timerColor,
                (typeof theme.palette.tonalOffset === "number"
                  ? theme.palette.tonalOffset
                  : theme.palette.tonalOffset[theme.palette.type]) * TIMING_LIGHTEN_FACTOR,
              )
            : undefined,
      },
      "&:hover": {
        "& $labelPart": {
          backgroundColor: (props: TimeButtonInnerStylingProps) =>
            props.disabled
              ? undefined
              : props.isActive
                ? theme.palette.primary.dark
                : theme.palette.secondary.dark,
        },
        "& $timingPart": {
          // coefficient from material-ui/src/styles/createPalette.js addLightOrDark
          backgroundColor: (props: TimeButtonInnerStylingProps) =>
            props.disabled
              ? undefined
              : darken(
                  props.timerColor,
                  (typeof theme.palette.tonalOffset === "number"
                    ? theme.palette.tonalOffset
                    : theme.palette.tonalOffset[theme.palette.type]) * HOVER_DARKEN_FACTOR,
                ),
        },
      },
      minWidth: 300,
    },
    labelPart: {
      // hack; otherwise, the function form wrt. "& $labelPart" above doesn't work
      width: (_props: TimeButtonInnerStylingProps) => "60%",
    },
    timingPart: {
      backgroundColor: (props: TimeButtonInnerStylingProps) => props.timerColor,
      color: (props: TimeButtonInnerStylingProps) =>
        props.isActive ? theme.palette.common.white : theme.palette.common.black,
      justifyContent: "start",
      width: "40%",
    },
  }),
);

function TimeButtonInner(props: TimeButtonInnerProps): JSX.Element {
  const {disabled, isActive, onTimerButton, text, time, timerColor} = props;
  const classes = useStyles({disabled, isActive, timerColor});

  return (
    <ButtonGroup fullWidth className={classes.buttonGroup} disabled={disabled} variant="contained">
      <Button
        className={classes.labelPart}
        color={isActive ? "primary" : "secondary"}
        onClick={onTimerButton}
      >
        {text}
      </Button>
      <Button
        className={classes.timingPart}
        startIcon={<TimerOutlineIcon className={isActive ? "pulse" : ""} color="#fff" />}
        onClick={onTimerButton}
      >
        {time}
      </Button>
    </ButtonGroup>
  );
}

const differenceFromStartToNowInSeconds = (startTime: number): number =>
  Math.floor((Date.now() - startTime) / SECOND_MILLISECONDS);

interface TimeButtonProps {
  correctionDisabled: boolean;
  isActive: boolean;
  label?: string;
  minutes: number;
  onTimerButton: (timerURL: TimerUrl) => void;
  requiresNotes?: boolean;
  startDisabled: boolean;
  timer: Timer;
  timerDisabled: boolean;
}

const THROTTLE_MS = 500;

export const TimeButton = React.memo(function TimeButton(props: TimeButtonProps): JSX.Element {
  const {
    // correctionDisabled,
    isActive,
    label,
    minutes,
    onTimerButton,
    // requiresNotes,
    startDisabled,
    timer,
    timerDisabled,
  } = props;

  const [seconds, setSeconds] = useState(0);
  const startTime = useRef(isActive ? Date.now() : null);

  useEffect(() => {
    if (isActive) {
      setSeconds(0);
      startTime.current = Date.now();
    } else {
      setSeconds(0);
      startTime.current = null;
    }
    // intentionally resets startTime.current on changes to props.minutes
  }, [isActive, minutes]);

  useEffect(() => {
    if (isActive) {
      const intervalId = window.setInterval(() => {
        if (startTime.current) {
          setSeconds(differenceFromStartToNowInSeconds(startTime.current));
        }
      }, SECOND_MILLISECONDS);
      return () => {
        window.clearInterval(intervalId);
      };
    } else {
      return undefined;
    }
  }, [isActive]);

  const throttleTimeout = useRef(0);
  useCallback(() => {
    return () => {
      if (throttleTimeout.current) {
        window.clearTimeout(throttleTimeout.current);
        throttleTimeout.current = 0;
      }
    };
  }, []);
  const handleTimerButton = useCallback(() => {
    if (throttleTimeout.current) {
      return;
    } else {
      throttleTimeout.current = window.setTimeout(() => {
        throttleTimeout.current = 0;
      }, THROTTLE_MS);
      onTimerButton(timer.url);
    }
  }, [onTimerButton, timer.url]);

  const text = (label || timer.label).toUpperCase();
  let time = formatMinutes(minutes);

  if (isActive) {
    const secondsDigits = 2;
    const formattedSeconds = `${seconds}`.padStart(secondsDigits, "0");
    time = `${time}:${formattedSeconds}`;
  }

  return (
    <TimeButtonInner
      disabled={timerDisabled || (startDisabled && !isActive)}
      isActive={isActive}
      text={text}
      time={time}
      timerColor={timer.color}
      onTimerButton={handleTimerButton}
    />
  );
});
