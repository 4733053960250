import {Culture, CultureUrl} from "@co-common-libs/resources";
import {CultureDialog, MultipleCulturesDialog} from "@co-frontend-libs/components";
import {AppState, getCultureArray} from "@co-frontend-libs/redux";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";

interface CultureDialogStateProps {
  cultureArray: readonly Culture[];
}

interface CultureDialogOwnProps {
  onCancel(): void;
  onOk(url: CultureUrl): void;
  open: boolean;
}

export const ConnectedCultureDialog: React.ComponentType<CultureDialogOwnProps> = connect<
  CultureDialogStateProps,
  object,
  CultureDialogOwnProps,
  AppState
>(
  createStructuredSelector<AppState, CultureDialogStateProps>({
    cultureArray: getCultureArray,
  }),
  {},
)(CultureDialog);

interface MultipleCulturesDialogStateProps {
  cultureArray: readonly Culture[];
}

interface MultipleCulturesDialogOwnProps {
  onCancel(): void;
  onOk(urls: ReadonlySet<CultureUrl>): void;
  open: boolean;
  selected?: ReadonlySet<CultureUrl>;
}

export const ConnectedMultipleCulturesDialog: React.ComponentType<MultipleCulturesDialogOwnProps> =
  connect<MultipleCulturesDialogStateProps, object, MultipleCulturesDialogOwnProps, AppState>(
    createStructuredSelector<AppState, MultipleCulturesDialogStateProps>({
      cultureArray: getCultureArray,
    }),
    {},
  )(MultipleCulturesDialog);
