import {Task} from "@co-common-libs/resources";
import {EconomicTaskIssue} from "../types";

export function economicTaskIssues(task: Task): EconomicTaskIssue | undefined {
  return task.lastTransferError
    ? {
        category: "error",
        errorMsg: task.lastTransferError,
        instance: task,
        issueType: "lastTransferError",
        resourceName: "task",
      }
    : undefined;
}
