import {Product, emptyProduct} from "@co-common-libs/resources";
import {instanceURL} from "frontend-global-config";
import {v4 as uuid} from "uuid";

export function createProduct(instance: Partial<Omit<Product, "id" | "url">>): Product {
  const id = uuid();
  const url = instanceURL("product", id);

  return {
    id,
    url,
    ...emptyProduct,
    ...instance,
  };
}
