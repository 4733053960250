import {Config, SettingID} from "@co-common-libs/config";
import {MachineUrl, PriceGroupUrl, Timer, WorkTypeUrl} from "@co-common-libs/resources";
import {
  ConnectedCombinedWorkTypesDialog,
  ConnectedMachineDialog,
  ConnectedPriceGroupDialog,
} from "@co-frontend-libs/connected-components";
import {
  actions,
  getCurrentUserURL,
  getCustomerSettings,
  getMachineArray,
  getMachineLookup,
  getPriceGroupArray,
  getPriceGroupLookup,
  getSettingsEntryLookupByIdentifier,
  getWorkTypeArray,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import PlusIcon from "mdi-react/PlusIcon";
import React, {useCallback, useMemo, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuid} from "uuid";

interface DeleteChipProps {
  index: number;
  label: string;
  onDelete?: ((index: number) => void) | undefined;
}

function DeleteChip(props: DeleteChipProps): JSX.Element {
  const {index, label, onDelete} = props;
  const handleDelete = useMemo(() => {
    if (onDelete) {
      return () => onDelete(index);
    } else {
      return undefined;
    }
  }, [index, onDelete]);
  if (handleDelete) {
    return <Chip label={label} onDelete={handleDelete} />;
  } else {
    return <Chip label={label} />;
  }
}

interface AddButtonProps {
  index: number;
  onClick: (index: number) => void;
}

function AddButton(props: AddButtonProps): JSX.Element {
  const {index, onClick} = props;
  const handleClick = useCallback((): void => {
    onClick(index);
  }, [index, onClick]);
  return (
    <IconButton onClick={handleClick}>
      <PlusIcon />
    </IconButton>
  );
}

function buildEntries(
  customerSettings: Config,
  timerID: string,
): readonly {
  readonly machine?: string;
  readonly priceGroup?: string;
  readonly workType?: string;
}[] {
  const result: {
    machine?: string;
    priceGroup?: string;
    workType?: string;
  }[] = [];
  const {
    machineExtraTimers,
    machinePriceGroupExtraTimers,
    workTypeExtraTimers,
    workTypeMachineExtraTimers,
    workTypePriceGroupExtraTimers,
  } = customerSettings;

  Object.entries(machineExtraTimers).forEach(([machine, timers]) => {
    if (timers.includes(timerID)) {
      result.push({machine});
    }
  });
  Object.entries(machinePriceGroupExtraTimers).forEach(([machine, priceGroupTimerMapping]) => {
    Object.entries(priceGroupTimerMapping).forEach(([priceGroup, timers]) => {
      if (timers.includes(timerID)) {
        result.push({machine, priceGroup});
      }
    });
  });
  Object.entries(workTypeExtraTimers).forEach(([workType, timers]) => {
    if (timers.includes(timerID)) {
      result.push({workType});
    }
  });
  Object.entries(workTypePriceGroupExtraTimers).forEach(([workType, priceGroupTimerMapping]) => {
    Object.entries(priceGroupTimerMapping).forEach(([priceGroup, timers]) => {
      if (timers.includes(timerID)) {
        result.push({priceGroup, workType});
      }
    });
  });
  Object.entries(workTypeMachineExtraTimers).forEach(([workType, machineTimerMapping]) => {
    Object.entries(machineTimerMapping).forEach(([machine, timers]) => {
      if (timers.includes(timerID)) {
        result.push({machine, workType});
      }
    });
  });

  return result;
}

function oneLevelAdd(
  data: {
    readonly [key: string]: readonly string[];
  },
  key: string,
  value: string,
): {readonly [key: string]: readonly string[]} {
  const oldForKey = data[key] || [];
  const newForKey = _.uniq([...oldForKey, value]);
  const newData = {...data, [key]: newForKey};
  return newData;
}

function oneLevelRemove(
  data: {
    readonly [key: string]: readonly string[];
  },
  key: string,
  value: string,
): {readonly [key: string]: readonly string[]} {
  const oldForKey = data[key] || [];
  const newForKey = oldForKey.filter((x) => x !== value);
  const newData = {...data};
  if (newForKey.length) {
    newData[key] = newForKey;
  } else {
    delete newData[key];
  }
  return newData;
}

function twoLevelAdd(
  data: {
    readonly [outerKey: string]: {
      readonly [innerKey: string]: readonly string[];
    };
  },
  outerKey: string,
  innerKey: string,
  value: string,
): {
  readonly [outerKey: string]: {
    readonly [innerKey: string]: readonly string[];
  };
} {
  const oldValueForOuterKey = data[outerKey] || {};
  const oldValueForInnerKey = oldValueForOuterKey[innerKey] || [];
  const newValueForInnerKey = _.uniq([...oldValueForInnerKey, value]);
  const newValueForOuterKey = {
    ...oldValueForOuterKey,
    [innerKey]: newValueForInnerKey,
  };
  const newData = {...data, [outerKey]: newValueForOuterKey};
  return newData;
}

function twoLevelRemove(
  data: {
    readonly [outerKey: string]: {
      readonly [innerKey: string]: readonly string[];
    };
  },
  outerKey: string,
  innerKey: string,
  value: string,
): {
  readonly [outerKey: string]: {
    readonly [innerKey: string]: readonly string[];
  };
} {
  const oldValueForOuterKey = data[outerKey] || {};
  const oldValueForInnerKey = oldValueForOuterKey[innerKey] || [];
  const newValueForInnerKey = oldValueForInnerKey.filter((x) => x !== value);
  const newValueForOuterKey = {
    ...oldValueForOuterKey,
  };
  if (newValueForInnerKey.length) {
    newValueForOuterKey[innerKey] = newValueForInnerKey;
  } else {
    delete newValueForOuterKey[innerKey];
  }
  const newData = {...data};
  if (Object.keys(newValueForOuterKey).length) {
    newData[outerKey] = newValueForOuterKey;
  } else {
    delete newData[outerKey];
  }
  return newData;
}

interface TimerWorkTypeMachinePriceGroupBlockProps {
  timer: Timer;
}

export function TimerWorkTypeMachinePriceGroupBlock(
  props: TimerWorkTypeMachinePriceGroupBlockProps,
): JSX.Element {
  const {timer} = props;
  const timerID = timer.identifier;

  const dispatch = useDispatch();

  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const currentUserURL = useSelector(getCurrentUserURL);
  const customerSettings = useSelector(getCustomerSettings);
  const machineLookup = useSelector(getMachineLookup);
  const workTypeLookup = useSelector(getWorkTypeLookup);
  const priceGroupLookup = useSelector(getPriceGroupLookup);
  const machineArray = useSelector(getMachineArray);
  const workTypeArray = useSelector(getWorkTypeArray);
  const priceGroupArray = useSelector(getPriceGroupArray);

  const [addMachineDialogOpenForEntry, setAddMachineDialogOpenForEntry] = useState<number | null>(
    null,
  );
  const [addWorkTypeDialogOpenForEntry, setAddWorkTypeDialogOpenForEntry] = useState<number | null>(
    null,
  );

  const [addPriceGroupDialogOpenForEntry, setAddPriceGroupDialogOpenForEntry] = useState<
    number | null
  >(null);

  const [addPriceGroupDialogOpenForMachine, setAddPriceGroupDialogOpenForMachine] =
    useState<MachineUrl>();
  const [addPriceGroupDialogOpenForWorkType, setAaddPriceGroupDialogOpenForWorkType] =
    useState<WorkTypeUrl>();

  const {
    machineExtraTimers,
    machinePriceGroupExtraTimers,
    workTypeExtraTimers,
    workTypeMachineExtraTimers,
    workTypePriceGroupExtraTimers,
  } = customerSettings;

  const [addMachineDialogOpen, setAddMachineDialogOpen] = useState(false);
  const setAddMachineDialogOpenTrue = useCallWithTrue(setAddMachineDialogOpen);
  const setAddMachineDialogOpenFalse = useCallWithFalse(setAddMachineDialogOpen);

  const [addWorkTypeDialogOpen, setAddWorkTypeDialogOpen] = useState(false);
  const setAddWorkTypeDialogOpenTrue = useCallWithTrue(setAddWorkTypeDialogOpen);
  const setAddWorkTypeDialogOpenFalse = useCallWithFalse(setAddWorkTypeDialogOpen);

  const entries = useMemo(
    () => buildEntries(customerSettings, timerID),
    [customerSettings, timerID],
  );

  const updateSetting = useCallback(
    (settingID: SettingID, value: any): void => {
      const settingsEntry = settingsEntryLookupByIdentifier(settingID);
      if (settingsEntry) {
        if (!_.isEqual(value, settingsEntry.data)) {
          dispatch(
            actions.update(settingsEntry.url, [
              {member: "changedBy", value: currentUserURL},
              {member: "data", value},
            ]),
          );
        }
      } else {
        const id = uuid();
        const url = instanceURL("settingEntry", id);
        dispatch(
          actions.create({
            changedBy: currentUserURL,
            data: value,
            key: settingID,
            url,
          }),
        );
      }
    },
    [currentUserURL, dispatch, settingsEntryLookupByIdentifier],
  );

  const addEntry = useCallback(
    (entry: {
      readonly machine?: string;
      readonly priceGroup?: string;
      readonly workType?: string;
    }): void => {
      const {machine, priceGroup, workType} = entry;
      if (machine && workType) {
        console.assert(!priceGroup);
        const newValue = twoLevelAdd(workTypeMachineExtraTimers, workType, machine, timerID);
        updateSetting("workTypeMachineExtraTimers", newValue);
      } else if (machine && priceGroup) {
        console.assert(!workType);
        const newValue = twoLevelAdd(machinePriceGroupExtraTimers, machine, priceGroup, timerID);
        updateSetting("machinePriceGroupExtraTimers", newValue);
      } else if (workType && priceGroup) {
        console.assert(!machine);
        const newValue = twoLevelAdd(workTypePriceGroupExtraTimers, workType, priceGroup, timerID);
        updateSetting("workTypePriceGroupExtraTimers", newValue);
      } else if (machine) {
        console.assert(!priceGroup);
        console.assert(!workType);
        const newValue = oneLevelAdd(machineExtraTimers, machine, timerID);
        updateSetting("machineExtraTimers", newValue);
      } else if (workType) {
        console.assert(!priceGroup);
        console.assert(!machine);
        const newValue = oneLevelAdd(workTypeExtraTimers, workType, timerID);
        updateSetting("workTypeExtraTimers", newValue);
      }
      console.assert(machine || workType || priceGroup);
    },
    [
      machineExtraTimers,
      machinePriceGroupExtraTimers,
      timerID,
      updateSetting,
      workTypeExtraTimers,
      workTypeMachineExtraTimers,
      workTypePriceGroupExtraTimers,
    ],
  );

  const removeEntry = useCallback(
    (entry: {
      readonly machine?: string;
      readonly priceGroup?: string;
      readonly workType?: string;
    }): void => {
      const {machine, priceGroup, workType} = entry;
      if (machine && workType) {
        console.assert(!priceGroup);
        const newValue = twoLevelRemove(workTypeMachineExtraTimers, workType, machine, timerID);
        updateSetting("workTypeMachineExtraTimers", newValue);
      } else if (machine && priceGroup) {
        console.assert(!workType);
        const newValue = twoLevelRemove(machinePriceGroupExtraTimers, machine, priceGroup, timerID);
        updateSetting("machinePriceGroupExtraTimers", newValue);
      } else if (workType && priceGroup) {
        console.assert(!machine);
        const newValue = twoLevelRemove(
          workTypePriceGroupExtraTimers,
          workType,
          priceGroup,
          timerID,
        );
        updateSetting("workTypePriceGroupExtraTimers", newValue);
      } else if (machine) {
        console.assert(!priceGroup);
        console.assert(!workType);
        const newValue = oneLevelRemove(machineExtraTimers, machine, timerID);
        updateSetting("machineExtraTimers", newValue);
      } else if (workType) {
        console.assert(!priceGroup);
        console.assert(!machine);
        const newValue = oneLevelRemove(workTypeExtraTimers, workType, timerID);
        updateSetting("workTypeExtraTimers", newValue);
      }
      console.assert(machine || workType || priceGroup);
    },
    [
      machineExtraTimers,
      machinePriceGroupExtraTimers,
      timerID,
      updateSetting,
      workTypeExtraTimers,
      workTypeMachineExtraTimers,
      workTypePriceGroupExtraTimers,
    ],
  );

  const handleAddMachineEntry = useCallback(
    (url: MachineUrl): void => {
      setAddMachineDialogOpen(false);
      const machine = machineLookup(url);
      if (!machine) {
        return;
      }
      addEntry({machine: machine.c5_machine});
    },
    [addEntry, machineLookup],
  );

  const handleAddWorkEntry = useCallback(
    (url: WorkTypeUrl): void => {
      setAddWorkTypeDialogOpen(false);
      const workType = workTypeLookup(url);
      if (!workType) {
        return;
      }
      addEntry({workType: workType.identifier});
    },
    [addEntry, workTypeLookup],
  );

  const handleRemoveMachine = useCallback(
    (index: number): void => {
      const entry = entries[index];
      const newEntry = {...entry};
      delete newEntry.machine;
      removeEntry(entry);
      if (Object.keys(newEntry).length) {
        addEntry(newEntry);
      }
    },
    [addEntry, entries, removeEntry],
  );
  const handleRemoveWorkType = useCallback(
    (index: number): void => {
      const entry = entries[index];
      const newEntry = {...entry};
      delete newEntry.workType;
      removeEntry(entry);
      if (Object.keys(newEntry).length) {
        addEntry(newEntry);
      }
    },
    [addEntry, entries, removeEntry],
  );
  const handleRemovePriceGroup = useCallback(
    (index: number): void => {
      const entry = entries[index];
      const newEntry = {...entry};
      delete newEntry.priceGroup;
      removeEntry(entry);
      console.assert(Object.keys(newEntry).length);
      addEntry(newEntry);
    },
    [addEntry, entries, removeEntry],
  );

  const handleAddMachineForEntryCancel = useCallback(() => {
    setAddMachineDialogOpenForEntry(null);
  }, []);

  const handleAddMachineForEntry = useCallback(
    (url: MachineUrl): void => {
      setAddMachineDialogOpenForEntry(null);
      const machine = machineLookup(url);
      if (!machine || addMachineDialogOpenForEntry === null) {
        return;
      }
      const entry = entries[addMachineDialogOpenForEntry];
      const newEntry = {...entry, machine: machine.c5_machine};
      removeEntry(entry);
      addEntry(newEntry);
    },
    [addEntry, addMachineDialogOpenForEntry, entries, machineLookup, removeEntry],
  );

  const handleAddMachineForEntryOpen = useCallback((index: number) => {
    setAddMachineDialogOpenForEntry(index);
  }, []);

  const handleAddWorkTypeForEntryCancel = useCallback(() => {
    setAddWorkTypeDialogOpenForEntry(null);
  }, []);

  const handleAddWorkTypeForEntry = useCallback(
    (url: WorkTypeUrl): void => {
      setAddWorkTypeDialogOpenForEntry(null);
      const workType = workTypeLookup(url);
      if (!workType || addWorkTypeDialogOpenForEntry === null) {
        return;
      }
      const entry = entries[addWorkTypeDialogOpenForEntry];
      const newEntry = {...entry, workType: workType.identifier};
      removeEntry(entry);
      addEntry(newEntry);
    },
    [addEntry, addWorkTypeDialogOpenForEntry, entries, removeEntry, workTypeLookup],
  );

  const handleAddWorkTypeForEntryOpen = useCallback((index: number): void => {
    setAddWorkTypeDialogOpenForEntry(index);
  }, []);

  const handleAddPriceGroupForEntryCancel = useCallback((): void => {
    setAddPriceGroupDialogOpenForEntry(null);
  }, []);

  const handleAddPriceGroupForEntry = useCallback(
    (url: PriceGroupUrl): void => {
      setAddPriceGroupDialogOpenForEntry(null);
      const priceGroup = priceGroupLookup(url);
      if (!priceGroup || addPriceGroupDialogOpenForEntry === null) {
        return;
      }
      const entry = entries[addPriceGroupDialogOpenForEntry];
      const newEntry = {...entry, priceGroup: priceGroup.identifier};
      removeEntry(entry);
      addEntry(newEntry);
    },
    [addEntry, addPriceGroupDialogOpenForEntry, entries, priceGroupLookup, removeEntry],
  );

  const handleAddPriceGroupForEntryOpen = useCallback(
    (index: number): void => {
      setAddPriceGroupDialogOpenForEntry(index);
      const entry = entries[index];
      setAddPriceGroupDialogOpenForMachine(undefined);
      setAaddPriceGroupDialogOpenForWorkType(undefined);
      if (entry.machine) {
        const identifier = entry.machine;
        const machine = machineArray.find((m) => m.c5_machine === identifier);
        if (machine) {
          setAddPriceGroupDialogOpenForMachine(machine.url);
        }
      }
      if (entry.workType) {
        const identifier = entry.workType;
        const workType = workTypeArray.find((w) => w.identifier === identifier);
        if (workType) {
          setAaddPriceGroupDialogOpenForWorkType(workType.url);
        }
      }
    },
    [entries, machineArray, workTypeArray],
  );

  const getMachineLabel = useCallback(
    (machineID: string) => {
      const machine = machineArray.find((m) => m.c5_machine === machineID);
      if (machine) {
        return `${machineID}: ${machine.name}`;
      } else {
        return machineID;
      }
    },
    [machineArray],
  );

  const getWorkTypeLabel = useCallback(
    (workTypeID: string) => {
      const workType = workTypeArray.find((w) => w.identifier === workTypeID);
      if (workType) {
        return `${workTypeID}: ${workType.name}`;
      } else {
        return workTypeID;
      }
    },
    [workTypeArray],
  );

  const getPriceGroupLabel = useCallback(
    (priceGroupID: string) => {
      const priceGroup = priceGroupArray.find((p) => p.identifier === priceGroupID);
      if (priceGroup) {
        return `${priceGroupID}: ${priceGroup.name}`;
      } else {
        return priceGroupID;
      }
    },
    [priceGroupArray],
  );

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage defaultMessage="Område" id="timer-entry.table-header.work-type" />
            </TableCell>
            <TableCell>
              <FormattedMessage defaultMessage="Variant" id="timer-entry.table-header.variant" />
            </TableCell>
            <TableCell>
              <FormattedMessage defaultMessage="Maskine" id="timer-entry.table-header.machine" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {entries.map(({machine, priceGroup, workType}, index) => (
            <TableRow key={index}>
              <TableCell>
                {workType ? (
                  <DeleteChip
                    index={index}
                    label={getWorkTypeLabel(workType)}
                    onDelete={priceGroup ? undefined : handleRemoveWorkType}
                  />
                ) : null}
                {!workType && !priceGroup ? (
                  <AddButton index={index} onClick={handleAddWorkTypeForEntryOpen} />
                ) : null}
              </TableCell>
              <TableCell>
                {priceGroup ? (
                  <DeleteChip
                    index={index}
                    label={getPriceGroupLabel(priceGroup)}
                    onDelete={handleRemovePriceGroup}
                  />
                ) : null}
                {!priceGroup && (!workType || !machine) ? (
                  <AddButton index={index} onClick={handleAddPriceGroupForEntryOpen} />
                ) : null}
              </TableCell>
              <TableCell>
                {machine ? (
                  <DeleteChip
                    index={index}
                    label={getMachineLabel(machine)}
                    onDelete={priceGroup ? undefined : handleRemoveMachine}
                  />
                ) : null}
                {!machine && !priceGroup ? (
                  <AddButton index={index} onClick={handleAddMachineForEntryOpen} />
                ) : null}
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell>
              <IconButton onClick={setAddWorkTypeDialogOpenTrue}>
                <PlusIcon />
              </IconButton>
            </TableCell>
            <TableCell />
            <TableCell>
              <IconButton onClick={setAddMachineDialogOpenTrue}>
                <PlusIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <ConnectedMachineDialog
        open={addMachineDialogOpen}
        onCancel={setAddMachineDialogOpenFalse}
        onOk={handleAddMachineEntry}
      />
      <ConnectedCombinedWorkTypesDialog
        open={addWorkTypeDialogOpen}
        onCancel={setAddWorkTypeDialogOpenFalse}
        onOk={handleAddWorkEntry}
      />
      <ConnectedMachineDialog
        open={addMachineDialogOpenForEntry !== null}
        onCancel={handleAddMachineForEntryCancel}
        onOk={handleAddMachineForEntry}
      />
      <ConnectedCombinedWorkTypesDialog
        open={addWorkTypeDialogOpenForEntry !== null}
        onCancel={handleAddWorkTypeForEntryCancel}
        onOk={handleAddWorkTypeForEntry}
      />
      <ConnectedPriceGroupDialog
        machineURL={addPriceGroupDialogOpenForMachine}
        open={addPriceGroupDialogOpenForEntry !== null}
        workTypeURL={addPriceGroupDialogOpenForWorkType}
        onCancel={handleAddPriceGroupForEntryCancel}
        onOk={handleAddPriceGroupForEntry}
      />
    </>
  );
}
