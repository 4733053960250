import {SettingID} from "@co-common-libs/config";
import {PriceGroupUrl, WorkType, WorkTypeUrl} from "@co-common-libs/resources";
import {ResponsiveDialog, TrimTextField} from "@co-frontend-libs/components";
import {
  ConnectedExternalWorkTypeDialog,
  ConnectedPriceGroupDialog,
} from "@co-frontend-libs/connected-components";
import {
  actions,
  getCurrentUserURL,
  getPriceGroupArray,
  getPriceGroupLookup,
  getSettingsEntryLookupByIdentifier,
  getWorkTypeArray,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {Button, Chip, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {WorkTypeChip} from "app-components";
import _ from "lodash";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {DeletableChip} from "../../reports/deletable-chip";

interface PrimaryTimerLabelsDialogDialogProps {
  onClose: () => void;
  open: boolean;
  settingID: SettingID;
}

const PriceGroupChip = ({
  label,
  onDeleteClick,
  priceGroupIdentifier,
  workTypeIdentifier,
}: {
  label: string;
  onDeleteClick: (workTypeIdentifier: string, priceGroupIdentifier: string) => void;
  priceGroupIdentifier: string;
  workTypeIdentifier: string;
}): JSX.Element => {
  const handleDelete = useCallback(() => {
    onDeleteClick(workTypeIdentifier, priceGroupIdentifier);
  }, [onDeleteClick, priceGroupIdentifier, workTypeIdentifier]);

  return <Chip label={label} onDelete={handleDelete} />;
};

const RowButton = ({
  identifier,
  label,
  onClick,
}: {
  identifier: string;
  label: JSX.Element;
  onClick: (identifier: string) => void;
}): JSX.Element => {
  const handleClick = useCallback(() => {
    onClick(identifier);
  }, [identifier, onClick]);

  return (
    <Button color="primary" onClick={handleClick}>
      {label}
    </Button>
  );
};

const RowField = ({
  onChange,
  priceGroupIdentifier,
  value,
  workTypeIdentifier,
}: {
  onChange: (workTypeIdentifier: string, priceGroupIdentifier: string, value: string) => void;
  priceGroupIdentifier: string;
  value: string;
  workTypeIdentifier: string;
}): JSX.Element => {
  const handleFieldChange = useCallback(
    (newValue: string) => {
      onChange(workTypeIdentifier, priceGroupIdentifier, newValue);
    },
    [onChange, priceGroupIdentifier, workTypeIdentifier],
  );

  return <TrimTextField fullWidth value={value} variant="outlined" onChange={handleFieldChange} />;
};

export function PrimaryTimerLabelsDialog(props: PrimaryTimerLabelsDialogDialogProps): JSX.Element {
  const {onClose, open, settingID} = props;

  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const settingEntry = settingsEntryLookupByIdentifier(settingID);
  const workTypeArray = useSelector(getWorkTypeArray);
  const priceGroupArray = useSelector(getPriceGroupArray);
  const workTypeLookup = useSelector(getWorkTypeLookup);
  const priceGroupLookup = useSelector(getPriceGroupLookup);
  const primaryTimerLabels: {
    [worktypeIdentifier: string]: {[priceGroupIdentifier: string]: string | undefined} | undefined;
  } = useMemo(() => settingEntry?.data || {}, [settingEntry?.data]);

  const [values, setValues] = useState(primaryTimerLabels);

  useEffect(() => {
    if (open) {
      setValues(primaryTimerLabels);
    }
  }, [open, primaryTimerLabels]);

  const dispatch = useDispatch();
  const currentUserURL = useSelector(getCurrentUserURL);

  const handleSave = useCallback(() => {
    if (settingEntry) {
      dispatch(
        actions.update(settingEntry.url, [
          {member: "changedBy", value: currentUserURL},
          {member: "data", value: values},
        ]),
      );
    }
    onClose();
  }, [currentUserURL, dispatch, onClose, settingEntry, values]);

  const [priceGroupDialogOpenedFor, setPriceGroupDialogOpenedFor] = useState<string | null>(null);
  const handleAddPriceGroupClick = useCallback((workTypeIdentifier: string) => {
    setPriceGroupDialogOpenedFor(workTypeIdentifier);
  }, []);

  const handlePriceGroupDialogCancel = useCallback(() => {
    setPriceGroupDialogOpenedFor(null);
  }, []);

  const handlePriceGroupDialogOk = useCallback(
    (url: PriceGroupUrl) => {
      setPriceGroupDialogOpenedFor(null);
      const priceGroup = priceGroupLookup(url);
      if (!priceGroup || !priceGroupDialogOpenedFor) {
        return;
      }
      const newValues = {
        ...values,
        [priceGroupDialogOpenedFor]: {
          ...values[priceGroupDialogOpenedFor],
          [priceGroup.identifier]: "",
        },
      };
      setValues(newValues);
    },
    [priceGroupDialogOpenedFor, priceGroupLookup, values],
  );

  const handleLabelChange = useCallback(
    (worktypeIdentifier: string, priceGroupIdentifier: string, value: string) => {
      const newValues = {
        ...values,
        [worktypeIdentifier]: {
          ...values[worktypeIdentifier],
          [priceGroupIdentifier]: value,
        },
      };
      setValues(newValues);
    },
    [values],
  );

  const [workTypeDialogOpen, setworkTypeDialogOpen] = useState(false);
  const setworkTypeDialogOpenTrue = useCallWithTrue(setworkTypeDialogOpen);
  const setworkTypeDialogOpenFalse = useCallWithFalse(setworkTypeDialogOpen);

  const handleWorkTypeDialogOk = useCallback(
    (url: WorkTypeUrl) => {
      setworkTypeDialogOpen(false);
      const workType = workTypeLookup(url);
      if (!workType) {
        return;
      }
      const newValues = {
        ...values,
        [workType.identifier]: {},
      };
      setValues(newValues);
    },
    [values, workTypeLookup],
  );

  const handleWorktypeDelete = useCallback(
    (workType: WorkType) => {
      setValues(_.omit(values, workType.identifier));
    },
    [values],
  );

  const handleNoneWorktypeIdDelete = useCallback(
    (identifier: string) => {
      setValues(_.omit(values, identifier));
    },
    [values],
  );

  const handlePriceGroupDelete = useCallback(
    (worktypeIdentifier: string, priceGroupIdentifier: string) => {
      const newValues = {
        ...values,
        [worktypeIdentifier]: _.omit(values[worktypeIdentifier], priceGroupIdentifier),
      };
      setValues(newValues);
    },
    [values],
  );

  const okDisabled = Object.values(values).some(
    (priceGroups) => priceGroups && Object.values(priceGroups).some((label) => !label),
  );

  const intl = useIntl();

  return (
    <>
      <ResponsiveDialog
        fullscreen
        okDisabled={okDisabled}
        okLabel={<FormattedMessage defaultMessage="Gem" id="setting-dialog.label.save" />}
        open={open}
        title={
          <FormattedMessage
            defaultMessage="Set timer labels"
            id="system-setup.dialog-title.timer-labels"
          />
        }
        onCancel={onClose}
        onOk={handleSave}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <FormattedMessage defaultMessage="Område" id="system-setup.work-type" />
              </TableCell>
              <TableCell>
                <FormattedMessage defaultMessage="Variant" id="system-setup.variant" />
              </TableCell>
              <TableCell>
                <FormattedMessage defaultMessage="Label" id="system-setup.label" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(values).map(([worktypeIdentifier, priceGroups]) => {
              const workType = workTypeArray.find((w) => w.identifier === worktypeIdentifier);
              const priceGroupEntries = priceGroups ? Object.entries(priceGroups) : null;
              if (!priceGroupEntries?.length) {
                return (
                  <TableRow key={worktypeIdentifier}>
                    <TableCell>
                      {workType ? (
                        <WorkTypeChip workType={workType} onDeleteClick={handleWorktypeDelete} />
                      ) : (
                        <DeletableChip
                          deletionId={worktypeIdentifier}
                          label={intl.formatMessage({
                            defaultMessage: "Ikke eksisterende område",
                          })}
                          onDelete={handleNoneWorktypeIdDelete}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      <RowButton
                        identifier={worktypeIdentifier}
                        label={<FormattedMessage defaultMessage="Tilføj" id="system-setup.add" />}
                        onClick={handleAddPriceGroupClick}
                      />
                    </TableCell>
                    <TableCell />
                  </TableRow>
                );
              } else {
                return (
                  <>
                    {priceGroupEntries.map(([priceGroupIdentifier, label], index) => {
                      const priceGroup = priceGroupArray.find(
                        (pg) => pg.identifier === priceGroupIdentifier,
                      );

                      return (
                        <TableRow key={`${worktypeIdentifier}-${priceGroupIdentifier}`}>
                          <TableCell style={{borderBottom: "none"}}>
                            {index === 0 ? (
                              workType ? (
                                <WorkTypeChip
                                  workType={workType}
                                  onDeleteClick={handleWorktypeDelete}
                                />
                              ) : (
                                <DeletableChip
                                  deletionId={worktypeIdentifier}
                                  label={intl.formatMessage({
                                    defaultMessage: "Ikke eksisterende område",
                                  })}
                                  onDelete={handleNoneWorktypeIdDelete}
                                />
                              )
                            ) : null}
                          </TableCell>
                          <TableCell>
                            <PriceGroupChip
                              label={`${priceGroupIdentifier} ${priceGroup?.name}`}
                              priceGroupIdentifier={priceGroupIdentifier}
                              workTypeIdentifier={worktypeIdentifier}
                              onDeleteClick={handlePriceGroupDelete}
                            />
                          </TableCell>
                          <TableCell>
                            <RowField
                              priceGroupIdentifier={priceGroupIdentifier}
                              value={label ?? ""}
                              workTypeIdentifier={worktypeIdentifier}
                              onChange={handleLabelChange}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    <TableRow key={`${worktypeIdentifier}-addnew`}>
                      <TableCell />
                      <TableCell>
                        <RowButton
                          identifier={worktypeIdentifier}
                          label={<FormattedMessage defaultMessage="Tilføj" id="system-setup.add" />}
                          onClick={handleAddPriceGroupClick}
                        />
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  </>
                );
              }
            })}
            <TableRow>
              <TableCell>
                <Button color="primary" onClick={setworkTypeDialogOpenTrue}>
                  <FormattedMessage defaultMessage="Tilføj" id="system-setup.add" />
                </Button>
              </TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableBody>
        </Table>
      </ResponsiveDialog>
      <ConnectedExternalWorkTypeDialog
        open={workTypeDialogOpen}
        onCancel={setworkTypeDialogOpenFalse}
        onOk={handleWorkTypeDialogOk}
      />
      <ConnectedPriceGroupDialog
        open={!!priceGroupDialogOpenedFor}
        onCancel={handlePriceGroupDialogCancel}
        onOk={handlePriceGroupDialogOk}
      />
    </>
  );
}
