import {ErrorObject} from "ajv";
import _ from "lodash";
import type {DeepReadonly} from "ts-essentials";
import {defaultValues} from "./defaults";
import {REMOVED_SETTINGS_WHITELIST} from "./removed-settings-whitelist";
import {Config} from "./types";
import validateFix from "./validate";

export {defaultValues} from "./defaults";

export type ReadonlyConfig = DeepReadonly<Config>;

export function loadCheckConfigHelper(data: object): {
  config: ReadonlyConfig;
  errors?: ErrorObject[];
} {
  const dataObject = _.omit(data, REMOVED_SETTINGS_WHITELIST);
  const result = _.cloneDeep(dataObject);
  validateFix(result);
  if (validateFix.errors) {
    return {
      config: result as ReadonlyConfig,
      errors: validateFix.errors,
    };
  } else {
    return {
      config: result as ReadonlyConfig,
    };
  }
}

export function unsafeLoadConfig(data: object): ReadonlyConfig {
  const dataObject = _.omit(data, REMOVED_SETTINGS_WHITELIST);
  return {...defaultValues, ...dataObject} as ReadonlyConfig;
}

export function loadCheckConfig(data: object): ReadonlyConfig {
  const {config, errors} = loadCheckConfigHelper(data);
  if (errors) {
    // eslint-disable-next-line no-console
    console.warn(errors);
  }
  return config;
}
