import {WorkType, WorkTypeUrl} from "@co-common-libs/resources";
import {getWorkTypeString} from "@co-common-libs/resources-utils";
import {ResponsiveDialog, TrimTextField, WorkTypeDialog} from "@co-frontend-libs/components";
import {ConnectedInternalWorkTypeDialog} from "@co-frontend-libs/connected-components";
import {
  getCurrentUserURL,
  getCustomerSettings,
  getExternalTaskPrimaryWorkTypeArray,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {Button, Chip, DialogContent} from "@material-ui/core";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useSelector} from "react-redux";

function isActive(obj: {active: boolean}): boolean {
  return obj.active;
}

interface NewTimerDialogProps {
  onCancel: () => void;
  onOk: (identifier: string, label: string, workType: WorkType) => void;
  open: boolean;
  otherTimerIdentifiers?: ReadonlySet<string>;
}

export function NewTimerDialog(props: NewTimerDialogProps): JSX.Element {
  const {onCancel, onOk, open, otherTimerIdentifiers} = props;
  const workTypeLookup = useSelector(getWorkTypeLookup);
  const customerSettings = useSelector(getCustomerSettings);
  const currentUserURL = useSelector(getCurrentUserURL);
  const [identifier, setIdentifier] = useState("");
  const [label, setLabel] = useState("");
  const [workType, setWorkType] = useState<WorkType | null>(null);
  useEffect(() => {
    if (open) {
      setIdentifier("");
      setLabel("");
      setWorkType(null);
    }
  }, [open]);

  const handleOk = useCallback(() => {
    if (workType) {
      onOk(identifier, label, workType);
    }
  }, [workType, onOk, identifier, label]);

  const identifierConflict =
    !!identifier && !!(otherTimerIdentifiers && otherTimerIdentifiers.has(identifier));
  const okDisabled = !identifier || identifierConflict || !workType;
  const intl = useIntl();

  const [externalWorkTypeDialogOpen, setExternalWorkTypeDialogOpen] = useState(false);
  const setExternalWorkTypeDialogOpenTrue = useCallWithTrue(setExternalWorkTypeDialogOpen);
  const setExternalWorkTypeDialogOpenFalse = useCallWithFalse(setExternalWorkTypeDialogOpen);

  const [internalWorkTypeDialogOpen, setInternalWorkTypeDialogOpen] = useState(false);
  const setInternalWorkTypeDialogOpenTrue = useCallWithTrue(setInternalWorkTypeDialogOpen);
  const setInternalWorkTypeDialogOpenFalse = useCallWithFalse(setInternalWorkTypeDialogOpen);

  const handleInternalWorkTypeDialogOk = useCallback(
    (url: WorkTypeUrl): void => {
      setInternalWorkTypeDialogOpen(false);
      const selectedWorktype = workTypeLookup(url);

      setWorkType(selectedWorktype || null);
    },
    [workTypeLookup],
  );

  const handleExternalWorkTypeDialogOk = useCallback(
    (url: WorkTypeUrl): void => {
      setExternalWorkTypeDialogOpen(false);
      const selectedWorktype = workTypeLookup(url);

      setWorkType(selectedWorktype || null);
    },
    [workTypeLookup],
  );

  const workTypeArray = useSelector(getExternalTaskPrimaryWorkTypeArray);

  const activeWorkTypeArray = useMemo(() => workTypeArray.filter(isActive), [workTypeArray]);

  return (
    <>
      <ResponsiveDialog
        okDisabled={okDisabled}
        open={open && !internalWorkTypeDialogOpen && !externalWorkTypeDialogOpen}
        title={intl.formatMessage({
          defaultMessage: "Ny timer",
        })}
        onCancel={onCancel}
        onOk={handleOk}
      >
        <DialogContent>
          <FormattedMessage defaultMessage="* Skal udfyldes" />
          <TrimTextField
            autoFocus
            fullWidth
            error={identifierConflict}
            helperText={
              identifierConflict
                ? intl.formatMessage({
                    defaultMessage: "ID allerede i brug",
                  })
                : undefined
            }
            label={intl.formatMessage({
              defaultMessage: "ID *",
            })}
            margin="dense"
            value={identifier}
            variant="outlined"
            onChange={setIdentifier}
          />
          <TrimTextField
            fullWidth
            label={intl.formatMessage({
              defaultMessage: "Label *",
            })}
            margin="dense"
            value={label}
            variant="outlined"
            onChange={setLabel}
          />
          <div style={{marginTop: "1em"}}>
            <Button color="primary" variant="contained" onClick={setInternalWorkTypeDialogOpenTrue}>
              <FormattedMessage defaultMessage="Vælg internt område" />
            </Button>
            {workType && workType.internalTaskPrimary ? (
              <Chip label={getWorkTypeString(workType)} style={{margin: 5}} />
            ) : null}
          </div>
          <div style={{marginTop: "1em"}}>
            <Button color="primary" variant="contained" onClick={setExternalWorkTypeDialogOpenTrue}>
              <FormattedMessage defaultMessage="Vælg eksternt område" />
            </Button>

            {workType && workType.externalTaskPrimary ? (
              <Chip label={getWorkTypeString(workType)} style={{margin: 5}} />
            ) : null}
          </div>
        </DialogContent>
      </ResponsiveDialog>
      <ConnectedInternalWorkTypeDialog
        open={internalWorkTypeDialogOpen}
        onCancel={setInternalWorkTypeDialogOpenFalse}
        onOk={handleInternalWorkTypeDialogOk}
      />
      <WorkTypeDialog
        currentUserURL={currentUserURL}
        disabledWorkTypes={customerSettings.disabledWorkTypes}
        open={externalWorkTypeDialogOpen}
        suggestRecentlyUsedWorkTypes={false}
        workTypeArray={activeWorkTypeArray}
        onCancel={setExternalWorkTypeDialogOpenFalse}
        onOk={handleExternalWorkTypeDialogOk}
      />
    </>
  );
}
