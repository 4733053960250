import {ComputedTime, Patch, PatchOperation, Task} from "@co-common-libs/resources";
import {computeTimerstartIntervals, mergeIntervals} from "@co-common-libs/resources-utils";
import _ from "lodash";
import {ProvisionaryCommand} from "../../resources/actions";
import {getTimerStartArray} from "../../resources/selectors";
import {ResourcesAuthenticationMiddlewareAPI} from "../types";

export function updateWorkFromTimestampFromCorrections(
  newTask: Task | null,
  oldTask: Task | undefined,
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  _command: ProvisionaryCommand,
): Patch<Task> | null {
  if (!newTask) {
    return null;
  }
  if (
    _.isEqual(
      newTask.machineOperatorTimeCorrectionSet,
      oldTask?.machineOperatorTimeCorrectionSet || [],
    ) &&
    _.isEqual(newTask.managerTimeCorrectionSet, oldTask?.managerTimeCorrectionSet || [])
  ) {
    return null;
  }

  const state = middlewareApi.getState();

  let computedIntervals: readonly ComputedTime[];
  if (newTask.recordedInC5) {
    computedIntervals = newTask.computedTimeSet;
  } else {
    const timerStartArray = getTimerStartArray(state);

    const taskUrl = newTask.url;

    const taskTimerStartArray = timerStartArray.filter(({task}) => task === taskUrl);
    const [computedIntervalsIncomplete, activeInterval] =
      computeTimerstartIntervals(taskTimerStartArray);
    if (activeInterval) {
      computedIntervals = [
        ...computedIntervalsIncomplete,
        {...activeInterval, toTimestamp: new Date().toISOString()},
      ];
    } else {
      computedIntervals = computedIntervalsIncomplete;
    }
  }

  const mergedIntervals = mergeIntervals(
    computedIntervals,
    newTask.machineOperatorTimeCorrectionSet,
    newTask.managerTimeCorrectionSet,
  );

  let workFromTimestamp: string | null = null;
  let workToTimestamp: string | null = null;

  if (mergedIntervals.length) {
    workFromTimestamp = mergedIntervals[0].fromTimestamp;
    if (newTask.completed) {
      workToTimestamp = mergedIntervals[mergedIntervals.length - 1].toTimestamp;
    }
  }

  const patch: PatchOperation<Task>[] = [];
  if (workFromTimestamp !== newTask.workFromTimestamp) {
    patch.push({member: "workFromTimestamp", value: workFromTimestamp});
  }
  if (workToTimestamp !== newTask.workToTimestamp) {
    patch.push({member: "workToTimestamp", value: workToTimestamp});
  }

  if (patch.length) {
    return patch;
  } else {
    return null;
  }
}
