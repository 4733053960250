interface PoolCompensatoryEntry {
  readonly depositedCompensatoryMinutes: number;
  readonly withdrawnCompensatoryMinutes: number;
}

interface PoolCompensatoryLimitResultEntry {
  aboveLimit: number;
  added: number;
  after: number;
  before: number;
  subtracted: number;
}

export function computePoolCompensatoryResults(
  poolCompensatoryEntries: readonly PoolCompensatoryEntry[],
  options: {
    accumulateCompensatoryLimit: number | null | undefined;
    accumulateCompensatoryLimitType: "accumulatedAdditions" | "balance";
    accumulatedCompensatoryBefore: number;
    allowNegativeAccumulateCompensatory: boolean | undefined;
    increaseOnlyAccumulatedCompensatoryBefore: number;
    periodCompensatoryLimit: number | null | undefined;
  },
): PoolCompensatoryLimitResultEntry[] {
  const {
    accumulateCompensatoryLimit,
    accumulateCompensatoryLimitType,
    accumulatedCompensatoryBefore,
    allowNegativeAccumulateCompensatory,
    increaseOnlyAccumulatedCompensatoryBefore,
    periodCompensatoryLimit,
  } = options;
  let after = accumulatedCompensatoryBefore;
  let increaseOnlyAfter = increaseOnlyAccumulatedCompensatoryBefore;

  return poolCompensatoryEntries.map(
    ({depositedCompensatoryMinutes, withdrawnCompensatoryMinutes}) => {
      const deposited = depositedCompensatoryMinutes;
      const withdrawn = withdrawnCompensatoryMinutes;
      const before = after;
      const increaseOnlyBefore = increaseOnlyAfter;

      let aboveLimit = 0;
      // check wrt. periodLimit
      if (periodCompensatoryLimit && deposited - withdrawn - aboveLimit > periodCompensatoryLimit) {
        aboveLimit += deposited - withdrawn - aboveLimit - periodCompensatoryLimit;
      }
      // check wrt. accumulateCompensatoryLimitType === "accumulatedAdditions"
      increaseOnlyAfter = increaseOnlyBefore + Math.max(deposited - withdrawn - aboveLimit, 0);
      if (
        accumulateCompensatoryLimit &&
        accumulateCompensatoryLimitType === "accumulatedAdditions" &&
        increaseOnlyAfter > accumulateCompensatoryLimit
      ) {
        aboveLimit += increaseOnlyAfter - accumulateCompensatoryLimit;
        increaseOnlyAfter = accumulateCompensatoryLimit;
      }
      // accumulateCompensatoryLimitType === "balance"
      after = before + deposited - withdrawn - aboveLimit;
      if (
        accumulateCompensatoryLimit &&
        accumulateCompensatoryLimitType === "balance" &&
        after > accumulateCompensatoryLimit
      ) {
        aboveLimit += after - accumulateCompensatoryLimit;
        after = accumulateCompensatoryLimit;
      }

      const added = deposited;
      let subtracted = withdrawn;
      if (!allowNegativeAccumulateCompensatory && after < 0) {
        if (subtracted >= -after) {
          subtracted -= -after;
          after = 0;
        } else {
          after += subtracted;
          subtracted = 0;
        }
      }

      return {
        aboveLimit,
        added,
        after,
        before,
        subtracted,
      };
    },
  );
}
