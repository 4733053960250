import {Unit, UnitUrl, WorkshopChecklistItem} from "@co-common-libs/resources";
import {caseAccentInsensitiveCollator} from "@co-common-libs/utils";
import {ResponsiveDialog, TrimTextField} from "@co-frontend-libs/components";
import {
  DialogContent,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@material-ui/core";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import _ from "lodash";
import React from "react";
import {FormattedMessage, IntlContext, defineMessages} from "react-intl";

const messages = defineMessages({
  addWorkshopChecklistItem: {
    defaultMessage: "Opret værkstedstjeklistepunkt",
    id: "workshopChecklistItem-entry.label.add-workshopChecklistItem",
  },
  boolean: {
    defaultMessage: "Ja / Nej",
    id: "workshopChecklistItem-entry.label.boolean",
  },
  decimal: {
    defaultMessage: "Tal",
    id: "workshopChecklistItem-entry.label.decimal",
  },
  editWorkshopChecklistItem: {
    defaultMessage: "Redigér værkstedstjeklistepunkt",
    id: "workshopChecklistItem-entry.label.edit-workshopChecklistItem",
  },
  name: {
    defaultMessage: "Navn",
    id: "workshopChecklistItem-entry.label.name",
  },
  type: {
    defaultMessage: "Type",
    id: "workshopChecklistItem-entry.label.type",
  },
  unit: {
    defaultMessage: "Enhed",
    id: "workshopChecklistItem-entry.label.unit",
  },
});

interface WorkshopChecklistItemDialogProps {
  checklistURL?: string;
  onCancel: () => void;
  onOk: (
    name: string,
    type: "boolean" | "decimal",
    unit: UnitUrl | null,
    workshopChecklistItem?: WorkshopChecklistItem,
  ) => void;
  open: boolean;
  unitArray: readonly Unit[];
  workshopChecklistItem?: WorkshopChecklistItem | undefined;
}

interface WorkshopChecklistItemDialogState {
  name: string;
  type: "boolean" | "decimal" | undefined;
  unit: UnitUrl | null;
}

const initialFormState: WorkshopChecklistItemDialogState = {
  name: "",
  type: undefined,
  unit: null,
};

export class WorkshopChecklistItemDialog extends PureComponent<
  WorkshopChecklistItemDialogProps,
  WorkshopChecklistItemDialogState
> {
  state = initialFormState;

  UNSAFE_componentWillReceiveProps(nextProps: WorkshopChecklistItemDialogProps): void {
    if (
      nextProps.open &&
      nextProps.workshopChecklistItem &&
      !_.isEqual(nextProps.workshopChecklistItem, this.props.workshopChecklistItem)
    ) {
      const {workshopChecklistItem} = nextProps;
      this.setState({
        name: workshopChecklistItem.name,
        type: workshopChecklistItem.type,
        unit: workshopChecklistItem.unit,
      });
    }
  }

  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  @bind
  handleOk(): void {
    const {name, type, unit} = this.state;
    const {onOk, workshopChecklistItem} = this.props;
    if (type !== undefined) {
      onOk(name, type, unit, workshopChecklistItem);
      this.setState(initialFormState);
    }
  }

  @bind
  handleCancel(): void {
    this.setState(initialFormState);
    this.props.onCancel();
  }

  @bind
  handleNameChanged(value: string): void {
    this.setState({name: value});
  }

  @bind
  handleTypeChanged(event: React.ChangeEvent<HTMLInputElement>): void {
    const {value} = event.target;
    const type = (value as "" | "boolean" | "decimal") || undefined;
    this.setState({type});
    if (type === "boolean" && this.state.unit) {
      this.setState({unit: null});
    }
  }

  @bind
  handleUnitChanged(
    event: React.ChangeEvent<{name?: string | undefined; value: unknown}>,
    _child: React.ReactNode,
  ): void {
    const {value} = event.target;
    this.setState({unit: (value as UnitUrl) || null});
  }

  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {open, unitArray, workshopChecklistItem} = this.props;
    const {name, type, unit} = this.state;

    const dropdownUnits = unitArray
      .slice()
      .sort((a, b) =>
        caseAccentInsensitiveCollator.compare(a.symbol || a.name, b.symbol || b.name),
      );
    const unitSelect = (
      <FormControl>
        <InputLabel id="product-unit-select-label">{formatMessage(messages.unit)}</InputLabel>
        <Select
          fullWidth
          disabled={!dropdownUnits.length}
          id="product-unit-select"
          labelId="product-unit-select-label"
          value={unit || ""}
          onChange={this.handleUnitChanged}
        >
          {dropdownUnits.map((instance) => (
            <MenuItem key={instance.url} value={instance.url}>
              {instance.symbol || instance.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );

    return (
      <ResponsiveDialog
        okDisabled={!name || !type || (type === "decimal" && !unit)}
        open={open}
        title={
          workshopChecklistItem
            ? formatMessage(messages.editWorkshopChecklistItem)
            : formatMessage(messages.addWorkshopChecklistItem)
        }
        onCancel={this.handleCancel}
        onOk={this.handleOk}
      >
        <DialogContent>
          <TrimTextField
            fullWidth
            id="name-field"
            label={formatMessage(messages.name)}
            margin="dense"
            name="name"
            value={name}
            variant="outlined"
            onChange={this.handleNameChanged}
          />
          <FormattedMessage
            defaultMessage="Type"
            id="expected-amount-dialog.label.type"
            tagName="p"
          />
          <RadioGroup name="type" value={type || ""} onChange={this.handleTypeChanged}>
            <FormControlLabel
              control={<Radio />}
              label={formatMessage(messages.decimal)}
              value="decimal"
            />
            <FormControlLabel
              control={<Radio />}
              label={formatMessage(messages.boolean)}
              value="boolean"
            />
          </RadioGroup>
          {type === "decimal" ? unitSelect : null}
          <FormattedMessage
            defaultMessage="Alle felter skal udfyldes"
            id="expected-amount-dialog.label.required"
            tagName="p"
          />
        </DialogContent>
      </ResponsiveDialog>
    );
  }
}
