import {MINUTE_MILLISECONDS} from "@co-common-libs/utils";
import {SimpleInterval} from "./types";

export function roundMillisecondsToMinutes(milliseconds: number): number {
  return Math.round(milliseconds / MINUTE_MILLISECONDS);
}

export function computeDurationMilliseconds(fromTimestamp: Date, toTimestamp: Date): number {
  return toTimestamp.valueOf() - fromTimestamp.valueOf();
}

export function computeRoundedDurationMinutes(fromTimestamp: Date, toTimestamp: Date): number {
  return roundMillisecondsToMinutes(computeDurationMilliseconds(fromTimestamp, toTimestamp));
}

export function computeIntervalDurationMilliseconds(interval: SimpleInterval): number {
  return computeDurationMilliseconds(
    new Date(interval.fromTimestamp),
    new Date(interval.toTimestamp),
  );
}

export function computeIntervalRoundedDurationMinutes(interval: SimpleInterval): number {
  return computeRoundedDurationMinutes(
    new Date(interval.fromTimestamp),
    new Date(interval.toTimestamp),
  );
}

/** Filter out intervals with rounded duration less than a minute. */
export function filterMinutesDuration<T extends SimpleInterval>(intervals: readonly T[]): T[] {
  return intervals.filter((interval) => {
    const durationMinutes = computeIntervalRoundedDurationMinutes(interval);
    return durationMinutes > 0;
  });
}
