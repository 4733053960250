import {
  LocationType,
  LocationTypeIcon,
  LocationTypeUrl,
  Product,
  ProductUrl,
  urlToId,
} from "@co-common-libs/resources";
import {notNull} from "@co-common-libs/utils";
import {
  ColumnSpecifications,
  GenericTable,
  LocationIcon,
  RowData,
  VerticalStackingFloatingActionButton,
} from "@co-frontend-libs/components";
import {
  actions,
  getCustomerSettings,
  getLocationTypeArray,
  getProductLookup,
  getTableSortingState,
} from "@co-frontend-libs/redux";
import {useQueryParameter} from "app-utils";
import {instanceURL} from "frontend-global-config";
import PlusIcon from "mdi-react/PlusIcon";
import React, {useCallback, useMemo, useState} from "react";
import {IntlShape, defineMessages, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuid} from "uuid";
import {LocationTypeIdentifierDialog} from "./location-type-identifier-dialog";

const TABLE_SORTING_IDENTIFIER = "LocationTypeTable";

const messages = defineMessages({
  identifier: {
    defaultMessage: "ID",
    id: "location-types.table-header.identifier",
  },
  name: {
    defaultMessage: "Navn",
    id: "location-types.table-header.name",
  },
  products: {
    defaultMessage: "Varer",
    id: "location-types.table-header.products",
  },
});

type LocationTypeTableFieldID = "color" | "icon" | "identifier" | "name" | "products";

type LocationTypeTableColumnID = "colorIcon" | "identifier" | "name" | "products";

interface LocationTypeTableDataType extends RowData<LocationTypeTableFieldID, LocationTypeUrl> {
  color: string;
  icon: LocationTypeIcon;
  identifier: string;
  name: string;
  products: string;
}

const COLOR_BLOCK_SIZE = 16;

function renderColorIconBlock(data: LocationTypeTableDataType): JSX.Element {
  return <LocationIcon color={data.color} icon={data.icon} />;
}

function renderProducts(data: LocationTypeTableDataType): JSX.Element {
  return <div style={{whiteSpace: "pre-line"}}>{data.products}</div>;
}

function buildColumnSpecifications(
  formatMessage: IntlShape["formatMessage"],
  onClick: (locationTypeURL: string) => void,
): ColumnSpecifications<
  LocationTypeTableFieldID,
  LocationTypeTableColumnID,
  LocationTypeUrl,
  LocationTypeTableDataType
> {
  return {
    colorIcon: {
      disableSorting: true,
      field: "color",
      label: "",
      onClick,
      render: renderColorIconBlock,
      width: COLOR_BLOCK_SIZE,
    },
    identifier: {
      field: "identifier",
      label: formatMessage(messages.identifier),
      onClick,
      width: 180,
    },
    name: {
      field: "name",
      label: formatMessage(messages.name),
      onClick,
    },
    products: {
      field: "products",
      label: formatMessage(messages.products),
      onClick,
      render: renderProducts,
    },
  };
}

function buildRowData(
  locationTypeArray: readonly LocationType[],
  productLookup: (url: ProductUrl) => Product | undefined,
  showFieldType: boolean,
  defaultFieldType: string,
): LocationTypeTableDataType[] {
  const locationsTypes: LocationTypeTableDataType[] = [];
  locationTypeArray.forEach((locationType) => {
    if (!showFieldType && locationType.identifier === defaultFieldType) {
      return;
    }
    locationsTypes.push({
      color: locationType.color,
      icon: locationType.icon,
      identifier: locationType.identifier,
      key: locationType.url,
      name: locationType.name,
      products: locationType.products
        .map((url) => {
          const product = productLookup(url);
          if (!product) {
            return null;
          }
          return `${product.catalogNumber}: ${product.name}`;
        })
        .filter(notNull)
        .sort()
        .join("\n"),
    });
  });
  return locationsTypes;
}

const defaultVisibleColumns = ["identifier", "colorIcon", "name"] as const;
const storageEnabledVisibleColumns = ["identifier", "colorIcon", "name", "products"] as const;

export function LocationTypes(): JSX.Element {
  const {formatMessage} = useIntl();
  const customerSettings = useSelector(getCustomerSettings);
  const dispatch = useDispatch();
  const locationTypeArray = useSelector(getLocationTypeArray);

  const usedIdentifiers = useMemo(
    () => new Set(locationTypeArray.map((locationType) => locationType.identifier)),
    [locationTypeArray],
  );
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const handleCreateDialogCancel = useCallback((): void => {
    setCreateDialogOpen(false);
  }, []);
  const handleCreateDialogOk = useCallback(
    (identifier: string): void => {
      if (!createDialogOpen) {
        return;
      }
      setCreateDialogOpen(false);
      const id = uuid();
      const url = instanceURL("locationType", id);
      const instance: LocationType = {
        color: "",
        icon: "map-marker-circle",
        id,
        identifier,
        name: "",
        products: [],
        url,
      };
      const action = actions.create(instance);
      dispatch(action);
    },
    [dispatch, createDialogOpen],
  );
  const handleFabClick = useCallback(() => setCreateDialogOpen(true), []);

  const handleClick = useCallback(
    (locationTypeURL: string): void => {
      const id = urlToId(locationTypeURL);
      dispatch(actions.go("/settings/locationType/:id", {id}));
    },
    [dispatch],
  );

  const columnSpecifications = useMemo(
    () => buildColumnSpecifications(formatMessage, handleClick),
    [formatMessage, handleClick],
  );
  const {customerFields, fieldDefaultLocationType} = customerSettings;
  const productLookup = useSelector(getProductLookup);
  const rowData = useMemo(
    () =>
      buildRowData(
        locationTypeArray,
        productLookup,
        customerFields,
        fieldDefaultLocationType || "",
      ),
    [customerFields, fieldDefaultLocationType, locationTypeArray, productLookup],
  );

  const filterString = useQueryParameter("q", "");

  const sortingStateSelector = useMemo(
    () => getTableSortingState(TABLE_SORTING_IDENTIFIER, "name", "ASC"),
    [],
  );
  const {sortDirection, sortKey} = useSelector(sortingStateSelector);

  const handleHeaderClick = useCallback(
    (key: LocationTypeTableColumnID): void => {
      let direction: "ASC" | "DESC" = "ASC";
      if (sortKey === key && sortDirection === "ASC") {
        direction = "DESC";
      }
      const action = actions.putTableSortingState(TABLE_SORTING_IDENTIFIER, key, direction);
      dispatch(action);
    },
    [dispatch, sortKey, sortDirection],
  );

  return (
    <>
      <GenericTable
        columns={columnSpecifications}
        entries={rowData}
        filterString={filterString}
        sortBy={sortKey as any}
        sortDirection={sortDirection}
        visibleColumns={
          customerSettings.enableLocationStorage
            ? storageEnabledVisibleColumns
            : defaultVisibleColumns
        }
        onHeaderClick={handleHeaderClick}
      />
      <VerticalStackingFloatingActionButton stackIndex={0} onClick={handleFabClick}>
        <PlusIcon />
      </VerticalStackingFloatingActionButton>
      <LocationTypeIdentifierDialog
        illegalIdentifiers={createDialogOpen ? usedIdentifiers : undefined}
        open={createDialogOpen}
        onCancel={handleCreateDialogCancel}
        onOk={handleCreateDialogOk}
      />
    </>
  );
}
