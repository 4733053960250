import {
  Customer,
  CustomerUrl,
  Location,
  LocationUrl,
  Order,
  OrderUrl,
} from "@co-common-libs/resources";
import {OrderDialog} from "@co-frontend-libs/components";
import {
  AppState,
  getCustomerLookup,
  getLocationLookup,
  getOrderArray,
} from "@co-frontend-libs/redux";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";

interface OrderDialogStateProps {
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  locationLookup: (url: LocationUrl) => Location | undefined;
  orderArray: readonly Order[];
}

interface OrderDialogOwnProps {
  onCancel(): void;
  onNewOrder: () => void;
  onOk(url: OrderUrl | null): void;
  open: boolean;
  preferredCustomer?: CustomerUrl | null;
  skipOrder?: OrderUrl;
}

export const ConnectedOrderDialog: React.ComponentType<OrderDialogOwnProps> = connect<
  OrderDialogStateProps,
  object,
  OrderDialogOwnProps,
  AppState
>(
  createStructuredSelector<AppState, OrderDialogStateProps>({
    customerLookup: getCustomerLookup,
    locationLookup: getLocationLookup,
    orderArray: getOrderArray,
  }),
  {},
)(OrderDialog);
