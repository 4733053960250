import {ReportingLocation, WorkplaceDataSpecification} from "@co-common-libs/resources";

export function logLocationMissingRequired(
  workplaceData: WorkplaceDataSpecification,
  reportingLocation: ReportingLocation,
): boolean {
  const inputs = workplaceData[reportingLocation.type]?.inputs;
  return (
    !!inputs &&
    inputs.some(
      (inputSpecification) =>
        inputSpecification.required &&
        reportingLocation.values?.[inputSpecification.identifier] === undefined,
    )
  );
}
