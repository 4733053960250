import {ResourceInstance, ResourceName} from "@co-common-libs/resources";
import {Query, QueryIDStruct, QueryParams, QueryTimestampsStruct} from "../types";

export abstract class ResourceDBConnection {
  // query ID -> query
  abstract readQueries(): Promise<{
    [queryID: number]: Partial<QueryParams> & Pick<QueryParams, "resourceName">;
  }>;
  abstract addQueries(
    queries: ReadonlySet<Query>,
    queryIdAssociation: ReadonlyMap<string, QueryIDStruct>,
  ): Promise<Map<string, QueryIDStruct>>;
  abstract deleteQueries(
    queries: ReadonlySet<Query>,
    queryIdAssociation: ReadonlyMap<string, QueryIDStruct>,
  ): Promise<Map<string, QueryIDStruct>>;
  abstract setQueryTimestamps(
    queriesFullFetchTimestamps: readonly QueryTimestampsStruct[],
    queryIdAssociation: ReadonlyMap<string, QueryIDStruct>,
  ): Promise<void>;
  abstract readQueryTimestamps(): Promise<{
    [queryID: number]: {fullTimestamp: string; updateTimestamp: string};
  }>;
  // instance URL -> instance
  abstract mergeData(instances: ReadonlyMap<string, ResourceInstance>): Promise<void>;
  abstract deleteData(urls: ReadonlySet<string>): Promise<void>;
  abstract readData(): Promise<ResourceInstance[]>;
  // resourceName -> ID-array
  abstract mergeIdFetch(ids: ReadonlyMap<ResourceName, ReadonlySet<string>>): Promise<void>;
  abstract deleteIdFetch(ids: ReadonlyMap<ResourceName, ReadonlySet<string>>): Promise<void>;
  abstract readIdFetch(): Promise<ReadonlyMap<ResourceName, ReadonlySet<string>>>;
  // resourceName -> memberName -> value-array
  abstract mergeRelatedFetch(
    values: ReadonlyMap<ResourceName, ReadonlyMap<string, ReadonlySet<string>>>,
  ): Promise<void>;
  abstract deleteRelatedFetch(
    values: ReadonlyMap<ResourceName, ReadonlyMap<string, ReadonlySet<string>>>,
  ): Promise<void>;
  abstract readRelatedFetch(): Promise<
    ReadonlyMap<ResourceName, ReadonlyMap<string, ReadonlySet<string>>>
  >;
  abstract getChangesTimestamp(): Promise<string | null>;
  abstract setChangesTimestamp(timestamp: string): Promise<void>;
  abstract vacuum(): Promise<void>;
  abstract close(): Promise<void>;
  abstract deleteDatabase(): Promise<void>;
}
