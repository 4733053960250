import {ReportSetup, ReportSetupUrl} from "@co-common-libs/resources";
import {ReportSetupDialog} from "@co-frontend-libs/components";
import {AppState, getReportSetupArray} from "@co-frontend-libs/redux";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";

interface ReportSetupDialogStateProps {
  reportSetupArray: readonly ReportSetup[];
}

interface ReportSetupDialogOwnProps {
  onCancel(): void;
  onOk(url: ReportSetupUrl): void;
  open: boolean;
}

export const ConnectedReportSetupDialog: React.ComponentType<ReportSetupDialogOwnProps> = connect<
  ReportSetupDialogStateProps,
  object,
  ReportSetupDialogOwnProps,
  AppState
>(
  createStructuredSelector<AppState, ReportSetupDialogStateProps>({
    reportSetupArray: getReportSetupArray,
  }),
  {},
)(ReportSetupDialog);
