import {CircularProgress, IconButton} from "@material-ui/core";
import AlertCircleIcon from "mdi-react/AlertCircleIcon";
import React from "react";

interface DownloadButtonProps {
  downloadURL?: string | undefined;
  error?: string | undefined;
  Icon: React.ElementType<object>;
  shareToken: string | null;
}

export const DownloadButton = React.memo(function DownloadButton(
  props: DownloadButtonProps,
): JSX.Element {
  const {downloadURL, error, Icon, shareToken} = props;
  if (downloadURL) {
    return (
      <IconButton color="primary" href={`${downloadURL}?token=${shareToken}`} target="_blank">
        <Icon />
      </IconButton>
    );
  } else if (error) {
    return (
      <div
        style={{
          display: "inline-block",
          height: 48,
          padding: 12,
          width: 48,
        }}
      >
        <AlertCircleIcon />
      </div>
    );
  } else {
    return (
      <div
        style={{
          display: "inline-block",
          height: 48,
          padding: 12,
          width: 48,
        }}
      >
        <CircularProgress size={24} />
      </div>
    );
  }
});
