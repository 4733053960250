import {Backdrop, useTheme} from "@material-ui/core";
import bowser from "bowser";
import CloudDownloadIcon from "mdi-react/CloudDownloadIcon";
import React, {useCallback, useEffect, useState} from "react";

interface DragAndDropUploadOverlayProps {
  children: React.ReactNode;
  onFiles: (files: File[]) => void;
}

export const DragAndDropUploadOverlay = React.memo(function DragAndDropUploadOverlay(
  props: DragAndDropUploadOverlayProps,
): JSX.Element {
  const {children, onFiles} = props;

  const theme = useTheme();
  const [show, setShow] = useState<boolean>(false);
  const [highlight, setHighlight] = useState<boolean>(false);

  const handleOverlayDragEnterOrDragOver = useCallback((event: React.DragEvent): void => {
    if (event.dataTransfer !== undefined) {
      // If you want to allow a drop, you must prevent the default behavior by cancelling both the dragenter and dragover events.
      // https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/Drag_operations#specifying_drop_targets
      event.preventDefault();
      setHighlight(true);
    }
  }, []);

  const handleOverlayDragLeave = useCallback((event: React.DragEvent): void => {
    if (event.dataTransfer !== undefined) {
      setHighlight(false);
    }
  }, []);

  const handleOverlayClicked = useCallback(() => {
    setHighlight(false);
    setShow(false);
  }, []);

  const handleOverlayDrop = useCallback(
    (event: React.DragEvent): void => {
      if (event.dataTransfer !== undefined) {
        event.preventDefault();

        const fileList = event.dataTransfer.files;
        const files: File[] = [];

        for (let i = 0; i < fileList.length; i = i + 1) {
          files.push(fileList[i]);
        }

        if (files.length) {
          onFiles(files);
        }
      }
    },
    [onFiles],
  );

  useEffect(() => {
    const handleDocumentDragEnter = (event: DragEvent): void => {
      if (event.dataTransfer !== undefined) {
        setShow(true);
      }
    };

    const handleDocumentDrop = (event: DragEvent): void => {
      if (event.dataTransfer !== undefined) {
        setHighlight(false);
        setShow(false);
      }
    };

    document.addEventListener("dragenter", handleDocumentDragEnter);
    document.addEventListener("drop", handleDocumentDrop);
    return () => {
      document.removeEventListener("dragenter", handleDocumentDragEnter);
      document.removeEventListener("drop", handleDocumentDrop);
    };
  }, []);

  const backdropContainerStyle: React.CSSProperties = {
    position: "relative",
  };

  const backdropStyle: React.CSSProperties = {
    overflow: "hidden",
    position: "absolute",
    zIndex: 99999,
  };

  // Drag events on the icon are propageted to the backdrop element but the
  // icon highlighting flickers when mouse enters/leaves icon. Setting pointer
  // events for the icon to none prevents this.
  let iconStyle: React.CSSProperties = {pointerEvents: "none"};
  if (highlight) {
    backdropStyle.border = `4px solid ${theme.palette.primary.main}`;
    iconStyle = {...iconStyle, color: "#ffffff", opacity: 1};
  } else {
    iconStyle = {...iconStyle, opacity: 0.5};
  }

  return (
    <div style={backdropContainerStyle}>
      {!(bowser.mobile || bowser.tablet) ? (
        <Backdrop
          open={show}
          style={backdropStyle}
          onClick={handleOverlayClicked}
          onDragEnter={handleOverlayDragEnterOrDragOver}
          onDragLeave={handleOverlayDragLeave}
          onDragOver={handleOverlayDragEnterOrDragOver}
          onDrop={handleOverlayDrop}
        >
          <CloudDownloadIcon size="120px" style={iconStyle} />
        </Backdrop>
      ) : null}
      {children}
    </div>
  );
});
