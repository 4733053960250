import {
  Product,
  ProductUrl,
  ReportingSpecification,
  ReportingSpecificationUrl,
  Task,
  Unit,
  UnitUrl,
} from "@co-common-libs/resources";
import {getReadonlyProductsFromUnits} from "@co-common-libs/resources-utils";
import {InlinedTask} from "./inline-data";

type InlinedTaskProperties = Pick<
  InlinedTask,
  "logSkipped" | "productUses" | "reportingSpecification"
>;
type TaskProperties = Pick<Task, "logSkipped" | "productUses" | "reportingSpecification">;

export const getReadonlyProductsFromTask = (
  task: InlinedTaskProperties | TaskProperties,
  productLookup: (url: ProductUrl) => Product | undefined,
  unitLookup: (url: UnitUrl) => Unit | undefined,
  reportingSpecificationLookup: (
    url: ReportingSpecificationUrl,
  ) => ReportingSpecification | undefined,
): Set<ProductUrl> => {
  const {logSkipped, productUses} = task;

  const reportingSpecification =
    task.reportingSpecification && !logSkipped
      ? reportingSpecificationLookup(
          typeof task.reportingSpecification === "string"
            ? task.reportingSpecification
            : task.reportingSpecification.url,
        )
      : undefined;

  if (reportingSpecification && !logSkipped) {
    if (
      reportingSpecification.workplaceData.delivery?.logProducts ||
      reportingSpecification.workplaceData.pickup?.logProducts ||
      reportingSpecification.workplaceData.workplace?.logProducts
    ) {
      return new Set(Object.values(productUses || {}).map((productUse) => productUse.product));
    } else {
      return getReadonlyProductsFromUnits(
        reportingSpecification,
        productUses || {},
        productLookup,
        unitLookup,
      );
    }
  }
  return new Set();
};
