import {Patch, Task} from "@co-common-libs/resources";
import {ProvisionaryCommand} from "../../resources/actions";
import {ResourcesAuthenticationMiddlewareAPI} from "../types";

export function resetLastTransferError(
  newTask: Task | null,
  oldTask: Task | undefined,
  _middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  _command: ProvisionaryCommand,
): Patch<Task> | null {
  if (!newTask || newTask.recordedInC5 || newTask.archivable) {
    return null;
  }

  if (newTask.reportApproved && !oldTask?.reportApproved && newTask.lastTransferError) {
    return [{member: "lastTransferError", value: ""}];
  }
  return null;
}
