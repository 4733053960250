import {Customer} from "@co-common-libs/resources";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {actions, diffResourceInstanceProperties} from "@co-frontend-libs/redux";
import {DialogContent} from "@material-ui/core";
import {simpleObjectReducer, useResetObjectOnCondition} from "app-utils";
import React, {useCallback, useReducer} from "react";
import {useIntl} from "react-intl";
import {useDispatch} from "react-redux";
import {CustomerFormWithCvrAutocomplete} from "../customer-form/customer-form-with-cvr-autocomplete";
import {hasMissingOrInvalidInputs} from "../customer-form/has-missing-or-invalid-inputs";
import {transformToCustomerFormPart} from "../customer-form/transform-to-customer-form-part";
import {CustomerFormPart} from "../customer-form/types";
import {useCustomerFormErrorsAndWarnings} from "../customer-form/use-customer-form-errors-and-warnings";
import {useCustomerFormInputDefinitions} from "../customer-form/use-customer-form-input-definitions";

interface CustomerEditDialogProps {
  customer: Customer;
  onCancel: () => void;
  onEditSuccess: () => void;
  open: boolean;
}

export const CustomerEditDialog = React.memo(function CustomerEditDialog(
  props: CustomerEditDialogProps,
): JSX.Element {
  const {customer, onCancel, onEditSuccess, open} = props;

  const [formCustomer, formDispatch] = useReducer(
    simpleObjectReducer<CustomerFormPart>,
    customer,
    transformToCustomerFormPart,
  );
  useResetObjectOnCondition(customer, formDispatch, open, transformToCustomerFormPart);
  const inputDefinitions = useCustomerFormInputDefinitions(customer, true);
  const errorsAndWarnings = useCustomerFormErrorsAndWarnings(
    formCustomer,
    customer,
    inputDefinitions,
  );

  const dispatch = useDispatch();
  const intl = useIntl();

  const handleOk = useCallback(() => {
    const {
      active,
      address,
      billingEmail,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      c5_account,
      city,
      name,
      phone,
      postalCode,
      vatNumber,
    } = formCustomer;

    const patch = diffResourceInstanceProperties(
      {
        active,
        address,
        billingEmail,
        c5_account,
        city,
        name,
        phone,
        postalCode,
        vatNumber,
      },
      customer,
    );

    if (patch.length) {
      dispatch(actions.update(customer.url, patch));
    }
    onEditSuccess();
  }, [customer, dispatch, formCustomer, onEditSuccess]);

  return (
    <ResponsiveDialog
      okDisabled={hasMissingOrInvalidInputs(formCustomer, inputDefinitions, errorsAndWarnings)}
      open={open}
      title={intl.formatMessage({defaultMessage: "Redigér kunde"})}
      onCancel={onCancel}
      onOk={handleOk}
    >
      <DialogContent>
        <CustomerFormWithCvrAutocomplete
          formCustomer={formCustomer}
          formDispatch={formDispatch}
          {...inputDefinitions}
          {...errorsAndWarnings}
        />
      </DialogContent>
    </ResponsiveDialog>
  );
});
