import {Config} from "@co-common-libs/config";
import {DecimalField, ResponsiveDialog} from "@co-frontend-libs/components";
import {AppState, getCustomerSettings} from "@co-frontend-libs/redux";
import {DialogContent, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import React from "react";
// Allowed for existing code...
// eslint-disable-next-line deprecate/import
import {Cell, Grid} from "react-flexr";
import {FormattedMessage, IntlContext, defineMessages} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";

const messages = defineMessages({
  cancel: {
    defaultMessage: "Fortryd",
    id: "dialog.label.cancel",
  },
  corn: {
    defaultMessage: "Majs",
    id: "yield-log-card.label.corn",
  },
  density: {
    defaultMessage: "vægtfylde",
  },
  grass: {
    defaultMessage: "Græs",
    id: "yield-log-card.label.grass",
  },
  mesurementMethod: {
    defaultMessage: "Målemetode",
    id: "yield-log-dialog.label.mesurement-method",
  },
  ok: {
    defaultMessage: "OK",
    id: "dialog.label.ok",
  },
  transportLog: {
    defaultMessage: "Opsætning udbyttelog",
    id: "yield-log-dialog.title.yield-log",
  },
  volume: {
    defaultMessage: "Rumfang (m³)",
    id: "yield-log-dialog.label.volume",
  },
  wagonWeight: {
    defaultMessage: "vognvægt",
    id: "yield-log-dialog.label.wagon-weight",
  },
  weight: {
    defaultMessage: "Vægt (ton)",
    id: "yield-log-dialog.label.weight",
  },
  wholecrop: {
    defaultMessage: "Helsæd",
    id: "yield-log-card.label.wholecrop",
  },
});

interface YieldLogDialogStateProps {
  customerSettings: Config;
}

interface YieldLogDialogOwnProps {
  crop: "" | "corn" | "grass" | "wholecrop";
  cuts?: number | undefined;
  density?: number | undefined;
  onCancel: () => void;
  onOk: (data: {
    crop: "" | "corn" | "grass" | "wholecrop";
    cuts: number | null;
    density: number | null;
    unit: "" | "m3" | "tonne";
    wagonWeight: number | null;
  }) => void;
  open: boolean;
  unit: "" | "m3" | "tonne";
  wagonWeight?: number | undefined;
}

type YieldLogDialogProps = YieldLogDialogOwnProps & YieldLogDialogStateProps;

interface YieldLogDialogState {
  crop: "" | "corn" | "grass" | "wholecrop";
  cuts: number | null;
  density: number | null;
  unit: "" | "m3" | "tonne";
  wagonWeight: number | null;
}

class YieldLogDialog extends PureComponent<YieldLogDialogProps, YieldLogDialogState> {
  state: YieldLogDialogState = {
    crop: this.props.crop,
    cuts: this.props.cuts || null,
    density: this.props.density || null,
    unit: this.props.unit,
    wagonWeight: this.props.wagonWeight || null,
  };
  UNSAFE_componentWillReceiveProps(nextProps: YieldLogDialogProps): void {
    if (nextProps.open && !this.props.open) {
      this.setState({
        crop: this.props.crop,
        cuts: this.props.cuts || null,
        density: this.props.density || null,
        unit: this.props.unit,
        wagonWeight: this.props.wagonWeight || null,
      });
    }
  }
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  @bind
  handleOk(): void {
    this.props.onOk({
      crop: this.state.crop,
      cuts: this.state.cuts,
      density: this.state.density,
      unit: this.state.unit,
      wagonWeight: this.state.wagonWeight,
    });
  }

  @bind
  handleCancel(): void {
    this.props.onCancel();
  }

  @bind
  handleDensityFieldChange(value: number | null): void {
    this.setState({density: value});
  }

  @bind
  handleWagonWeightFieldChange(value: number | null): void {
    this.setState({wagonWeight: value});
  }

  @bind
  handleUnitChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const unit = event.target.value as "" | "m3" | "tonne";
    this.setState({unit});
  }

  @bind
  handleCropChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const crop = event.target.value as "" | "corn" | "grass" | "wholecrop";
    this.setState({crop});
  }

  @bind
  handleCutsFieldChange(value: number | null): void {
    this.setState({cuts: value});
  }
  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {open} = this.props;
    const disableOk =
      (this.state.unit === "m3" && !this.state.density) ||
      (this.state.unit === "tonne" && this.state.wagonWeight === null) ||
      (this.state.crop === "grass" && !this.state.cuts);

    const dialogContent = (
      <div>
        <FormattedMessage
          defaultMessage="Målemetode:"
          id="yield-log-dialog.label.messurementMethod"
          tagName="h3"
        />
        <Grid>
          <Cell palm="6/6">
            <RadioGroup
              name="unit"
              value={this.state.unit || this.props.customerSettings.yieldLogDefaultUnit}
              onChange={this.handleUnitChange}
            >
              <FormControlLabel
                control={<Radio />}
                label={formatMessage(messages.volume)}
                value="m3"
              />
              <FormControlLabel
                control={<Radio />}
                label={formatMessage(messages.weight)}
                value="tonne"
              />
            </RadioGroup>
          </Cell>
          <Cell palm="6/6">
            <div>
              <FormattedMessage defaultMessage="Vægtfylde:" />
              <DecimalField
                autoFocus={this.state.unit === "m3"}
                decimalPlaces={2}
                disabled={this.state.unit !== "m3"}
                id="density"
                margin="dense"
                style={{height: "auto", marginLeft: 5, width: "3em"}}
                value={this.state.density}
                onChange={this.handleDensityFieldChange}
              />
              {this.state.unit === "m3" ? "*" : null}
            </div>
            <div>
              <FormattedMessage
                defaultMessage="Vognvægt:"
                id="yield-log-dialog.label.wagonWeight"
              />
              <DecimalField
                autoFocus={this.state.unit === "tonne"}
                decimalPlaces={2}
                disabled={this.state.unit !== "tonne"}
                id="wagonWeight"
                margin="dense"
                style={{
                  height: "auto",
                  marginLeft: 5,
                  marginRight: 5,
                  width: "3em",
                }}
                value={this.state.wagonWeight}
                onChange={this.handleWagonWeightFieldChange}
              />
              <FormattedMessage defaultMessage={"ton"} id={"yield-log-dialog.label.tonne"} />
              {this.state.unit === "tonne" ? "*" : null}
            </div>
          </Cell>
        </Grid>

        <Grid>
          <Cell palm="6/6">
            <RadioGroup
              name="crop"
              value={this.state.crop || this.props.customerSettings.yieldLogDefaultCrop}
              onChange={this.handleCropChange}
            >
              <FormControlLabel
                control={<Radio />}
                label={formatMessage(messages.grass)}
                value="grass"
              />
              <FormControlLabel
                control={<Radio />}
                label={formatMessage(messages.wholecrop)}
                value="wholecrop"
              />
              <FormControlLabel
                control={<Radio />}
                label={formatMessage(messages.corn)}
                value="corn"
              />
            </RadioGroup>
          </Cell>
          <Cell palm="6/6">
            <FormattedMessage defaultMessage="Slæt nr:" id="yield-log-dialog.label.cutNumber" />
            <DecimalField
              decimalPlaces={2}
              disabled={this.state.crop !== "grass"}
              id="cuts"
              margin="dense"
              style={{
                height: "auto",
                marginLeft: 5,
                marginRight: 5,
                width: "3em",
              }}
              value={this.state.cuts}
              onChange={this.handleCutsFieldChange}
            />
            {this.state.crop === "grass" ? "*" : null}
          </Cell>
        </Grid>

        <FormattedMessage defaultMessage="* Krævet" id="yield-log-dialog.label.required" />
      </div>
    );
    return (
      <ResponsiveDialog
        fullWidth
        maxWidth="sm"
        okDisabled={disableOk}
        open={open}
        title={formatMessage(messages.transportLog)}
        onCancel={this.handleCancel}
        onOk={this.handleOk}
      >
        <DialogContent>{dialogContent}</DialogContent>
      </ResponsiveDialog>
    );
  }
}

const ConnectedYieldLogDialog: React.ComponentType<YieldLogDialogOwnProps> = connect<
  YieldLogDialogStateProps,
  object,
  YieldLogDialogOwnProps,
  AppState
>(
  createStructuredSelector<AppState, YieldLogDialogStateProps>({
    customerSettings: getCustomerSettings,
  }),
  {},
)(YieldLogDialog);

export {ConnectedYieldLogDialog as YieldLogDialog};
