import {PriceItem, PriceItemUseWithOrder} from "@co-common-libs/resources";
import {getCustomerSettings} from "@co-frontend-libs/redux";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import React from "react";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {EntryRow} from "./entry-row";
import {TotalRow} from "./total-row";

interface TimeDistributionTableProps {
  disabled: boolean;
  expectedMinutes: number;
  taskUrl: string;
  timeEntries: readonly {
    readonly identifier: string;
    readonly priceItem: PriceItem;
    readonly priceItemUse: PriceItemUseWithOrder;
  }[];
}

export const TimeDistributionTable = React.memo(function TimeDistributionTable(
  props: TimeDistributionTableProps,
): JSX.Element {
  const {disabled, expectedMinutes, taskUrl, timeEntries} = props;
  const customerSettings = useSelector(getCustomerSettings);

  const distributedMinutes = timeEntries.reduce(
    (acc, entry) => acc + (entry.priceItemUse.count || 0),
    0,
  );

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            {customerSettings.machineLabelVariant === "MACHINE" ? (
              <FormattedMessage defaultMessage="Maskine/arbejdsomr." />
            ) : (
              <FormattedMessage defaultMessage="Køretøj/arbejdsomr." />
            )}
          </TableCell>
          <TableCell>
            <FormattedMessage defaultMessage="Timer" />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {timeEntries.map(({identifier, priceItem, priceItemUse}) => (
          <EntryRow
            key={identifier}
            count={priceItemUse.count}
            dangling={priceItemUse.dangling}
            disabled={disabled}
            identifier={identifier}
            name={priceItem.name}
            taskUrl={taskUrl}
          />
        ))}
        <TotalRow distributedMinutes={distributedMinutes} expectedMinutes={expectedMinutes} />
      </TableBody>
    </Table>
  );
});
