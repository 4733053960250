import {Customer, CustomerUrl, Location, LocationUrl, RouteTask} from "@co-common-libs/resources";
import {formatAddress} from "@co-common-libs/utils";
import {TableCell, TableRow} from "@material-ui/core";
import {PureComponent} from "app-utils";
import React from "react";
import {CORRECTED_COLUMN_WIDTH, COUNT_COLUMN_WIDTH, UNIT_COLUMN_WIDTH} from "./constants";

interface RouteTaskRowProps {
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  locationLookup: (url: LocationUrl) => Location | undefined;
  routeTask: RouteTask;
}

export class RouteTaskRow extends PureComponent<RouteTaskRowProps> {
  render(): JSX.Element {
    const {customerLookup, locationLookup, routeTask} = this.props;
    const workplaceURL = routeTask.relatedLocation;
    const workplace = locationLookup(workplaceURL);
    const workplaceName = workplace ? workplace.name : "";
    const address = formatAddress(workplace);
    const customerURL = routeTask && routeTask.customer;
    const customer = customerURL && customerLookup(customerURL);
    const customerName = customer ? customer.name : "null";
    return (
      <TableRow>
        <TableCell style={{textOverflow: "clip"}}>
          {customerName}
          {", "}
          {workplaceName ? `${workplaceName}, ${address}` : address}
        </TableCell>
        <TableCell style={{width: COUNT_COLUMN_WIDTH}} />
        <TableCell style={{width: CORRECTED_COLUMN_WIDTH}} />
        <TableCell style={{width: UNIT_COLUMN_WIDTH}} />
      </TableRow>
    );
  }
}
