import {PriceItem, PriceItemUrl, ProductUrl, ReportingLog} from "@co-common-libs/resources";

export const getTransportedSumsPerProduct = (
  reportingLog: ReportingLog,
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined,
): {
  deliveryCounts: Map<PriceItemUrl | ProductUrl, number>;
  pickupCounts: Map<PriceItemUrl | ProductUrl, number>;
} => {
  const pickupCounts = new Map<PriceItemUrl | ProductUrl, number>();
  const deliveryCounts = new Map<PriceItemUrl | ProductUrl, number>();
  Object.values(reportingLog).forEach((entry) => {
    if (entry.type === "pickup") {
      if (entry.priceItemUses) {
        Object.values(entry.priceItemUses).forEach((use) => {
          if (use.count == null) {
            return;
          }
          const priceItem = priceItemLookup(use.priceItem);
          if (!priceItem) {
            return;
          }
          const key = priceItem.product || priceItem.url;
          pickupCounts.set(key, (pickupCounts.get(key) || 0) + use.count);
        });
      }
      if (entry.productUses) {
        Object.values(entry.productUses).forEach((use) => {
          if (use.count == null) {
            return;
          }
          pickupCounts.set(use.product, (pickupCounts.get(use.product) || 0) + use.count);
        });
      }
    } else if (entry.type === "delivery") {
      if (entry.priceItemUses) {
        Object.values(entry.priceItemUses).forEach((use) => {
          if (use.count == null) {
            return;
          }
          const priceItem = priceItemLookup(use.priceItem);
          if (!priceItem) {
            return;
          }
          const key = priceItem.product || priceItem.url;
          deliveryCounts.set(key, (deliveryCounts.get(key) || 0) + use.count);
        });
      }
      if (entry.productUses) {
        Object.values(entry.productUses).forEach((use) => {
          if (use.count == null) {
            return;
          }
          deliveryCounts.set(use.product, (deliveryCounts.get(use.product) || 0) + use.count);
        });
      }
    }
  });
  return {deliveryCounts, pickupCounts};
};
