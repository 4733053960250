import {
  Machine,
  MachineUrl,
  PriceGroup,
  PriceGroupUrl,
  ReportingSpecification,
  ReportingSpecificationUrl,
  Task,
  WorkType,
  WorkTypeUrl,
} from "@co-common-libs/resources";

export function getExpectedLogSpecificationWithCriteria(
  task: Pick<Task, "machineuseSet" | "priceGroup" | "workType">,
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined,
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined,
  machineLookup: (url: MachineUrl) => Machine | undefined,
  reportingSpecificationLookup: (
    url: ReportingSpecificationUrl,
  ) => ReportingSpecification | undefined,
): {
  reasonUrl: MachineUrl | PriceGroupUrl | WorkTypeUrl;
  reportingSpecification: ReportingSpecification;
}[] {
  const result: {
    reasonUrl: MachineUrl | PriceGroupUrl | WorkTypeUrl;
    reportingSpecification: ReportingSpecification;
  }[] = [];
  if (task.priceGroup) {
    const priceGroup = priceGroupLookup(task.priceGroup);
    const reportingSpecification = priceGroup?.reportingSpecification
      ? reportingSpecificationLookup(priceGroup.reportingSpecification)
      : null;
    if (reportingSpecification?.active) {
      result.push({reasonUrl: task.priceGroup, reportingSpecification});
    }
  }
  if (task.workType) {
    const workType = workTypeLookup(task.workType);
    const reportingSpecification = workType?.reportingSpecification
      ? reportingSpecificationLookup(workType.reportingSpecification)
      : null;
    if (reportingSpecification?.active) {
      result.push({reasonUrl: task.workType, reportingSpecification});
    }
  }
  if (task.machineuseSet && task.machineuseSet.length) {
    for (const machineUse of task.machineuseSet) {
      if (machineUse.priceGroup) {
        const priceGroup = priceGroupLookup(machineUse.priceGroup);
        const reportingSpecification = priceGroup?.reportingSpecification
          ? reportingSpecificationLookup(priceGroup.reportingSpecification)
          : null;
        if (reportingSpecification?.active) {
          result.push({reasonUrl: machineUse.machine, reportingSpecification});
        }
      }
      const machine = machineLookup(machineUse.machine);
      const reportingSpecification = machine?.reportingSpecification
        ? reportingSpecificationLookup(machine.reportingSpecification)
        : null;
      if (reportingSpecification?.active) {
        result.push({reasonUrl: machineUse.machine, reportingSpecification});
      }
    }
  }
  return result;
}
