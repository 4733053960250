import {Position, Timer, TimerStart, TimerUrl, urlToId} from "@co-common-libs/resources";
import {getNormalisedDeviceTimestamp} from "@co-common-libs/resources-utils";
import {
  MINUTE_MILLISECONDS,
  SECOND_MILLISECONDS,
  getMinuteString,
  notNull,
  notUndefined,
} from "@co-common-libs/utils";
import {makeQuery} from "@co-frontend-libs/db-resources";
import {
  actions,
  getActiveTimerStartArray,
  getCurrentRole,
  getCustomerLookup,
  getCustomerSettings,
  getMachineLookup,
  getOrderLookup,
  getPathName,
  getPositionArray,
  getTaskLookup,
  getTimerLookup,
  getUserRoleLookup,
  getUserUserProfileLookup,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {useMapContainer} from "@co-frontend-libs/utils";
import {common as commonColors} from "@material-ui/core/colors";
import _ from "lodash";
import React, {useEffect, useMemo, useState} from "react";
import ReactDOM from "react-dom";
import {useDispatch, useSelector} from "react-redux";
import {ActiveMarker} from "./active-marker";

const POSITION_MAX_AGE_HOURS = 12;

const TEMPORARY_QUERIES_KEY = "GeolocationMap";

function getColor(
  timerStarts: readonly TimerStart[],
  timerLookup: (url: TimerUrl) => Timer | undefined,
): string {
  const timers = timerStarts
    .map((timerStart) => timerLookup(timerStart.timer as TimerUrl))
    .filter(notUndefined);
  const timerColors = timers.map((timer) => timer.color);
  if (timerColors.length === 1 || new Set(timerColors).size === 1) {
    return timerColors[0] || commonColors.white;
  } else {
    return commonColors.white;
  }
}

interface ActiveTaskMarkersProps {
  map: google.maps.Map;
}

export function ActiveTaskMarkers(props: ActiveTaskMarkersProps): JSX.Element {
  const {map} = props;

  const positionArray = useSelector(getPositionArray);
  const currentRole = useSelector(getCurrentRole);
  const userRoleLookup = useSelector(getUserRoleLookup);
  const timerLookup = useSelector(getTimerLookup);
  const pathName = useSelector(getPathName);
  const customerSettings = useSelector(getCustomerSettings);
  const taskLookup = useSelector(getTaskLookup);
  const userUserProfileLookup = useSelector(getUserUserProfileLookup);
  const machineLookup = useSelector(getMachineLookup);
  const workTypeLookup = useSelector(getWorkTypeLookup);
  const orderLookup = useSelector(getOrderLookup);
  const customerLookup = useSelector(getCustomerLookup);
  const activeTimerStarts = useSelector(getActiveTimerStartArray);

  const dispatch = useDispatch();

  const [now, setNow] = useState(new Date());
  const minuteString = getMinuteString(now);

  useEffect(() => {
    const positionMaxAgeDate = new Date(minuteString);
    positionMaxAgeDate.setUTCHours(positionMaxAgeDate.getUTCHours() - POSITION_MAX_AGE_HOURS);
    // FIXME: we request *everything* from server, no filtering,
    // on the expectation that the server will filter anyway...
    dispatch(
      actions.temporaryQueriesRequestedForPath(
        [
          makeQuery({
            check: {
              memberName: "deviceTimestamp",
              type: "memberGt",
              value: positionMaxAgeDate.toISOString(),
            },
            filter: {
              fromDateTime: positionMaxAgeDate.toISOString(),
            },
            independentFetch: true,
            resourceName: "position",
          }),
        ],
        pathName,
        TEMPORARY_QUERIES_KEY,
      ),
    );
    return () => {
      dispatch(actions.temporaryQueriesDiscardedForPath(pathName, TEMPORARY_QUERIES_KEY));
    };
  }, [dispatch, minuteString, pathName]);

  useEffect(() => {
    const markerRefreshIntervalSeconds = 10;

    const refreshMarkers = (): void => {
      setNow(new Date());
    };
    const intervalID = window.setInterval(
      refreshMarkers,
      markerRefreshIntervalSeconds * SECOND_MILLISECONDS,
    );
    return () => {
      window.clearInterval(intervalID);
    };
  }, []);
  // Consultant-timerstarts filtered out, unless current user is consultant.
  const filteredActiveTimerStarts = useMemo(() => {
    if (currentRole && currentRole.consultant) {
      return activeTimerStarts;
    } else {
      return activeTimerStarts.filter((timerStart) => {
        const userRole = userRoleLookup(timerStart.employee);
        return userRole && !userRole.consultant;
      });
    }
  }, [activeTimerStarts, currentRole, userRoleLookup]);

  const filteredActiveTimerStartsUrls = useMemo(
    () => new Map(filteredActiveTimerStarts.map((timerStart) => [timerStart.url, timerStart])),
    [filteredActiveTimerStarts],
  );

  const activePositions = useMemo(() => {
    const positionsPerUser = new Map<string, Position[]>();
    filteredActiveTimerStarts.forEach((timerStart) => {
      positionsPerUser.set(timerStart.employee, []);
    });
    positionArray.forEach((position) => {
      const userPositions = positionsPerUser.get(position.employee);
      if (
        userPositions &&
        position.timerStart &&
        filteredActiveTimerStartsUrls.has(position.timerStart)
      ) {
        userPositions.push(position);
      }
    });

    const result: Position[] = [];
    positionsPerUser.forEach((userPositions) => {
      if (userPositions.length) {
        const lastPosition = _.maxBy(userPositions, getNormalisedDeviceTimestamp) as Position;
        console.assert(lastPosition);
        result.push(lastPosition);
      }
    });
    return result;
  }, [filteredActiveTimerStarts, filteredActiveTimerStartsUrls, positionArray]);

  const [containerElement, overlayView] = useMapContainer(map);

  if (containerElement && overlayView) {
    const MIN_X_DISTANCE_PIXELS = 200;
    const MIN_Y_DISTANCE_PIXELS = 40;

    const mapCanvasProjection = overlayView.getProjection();
    const bounds = map.getBounds() as google.maps.LatLngBounds;
    const markerPositions: {
      position: Position;
      timerStart: TimerStart;
      x: number;
      y: number;
    }[] = [];
    activePositions.forEach((position) => {
      const latLng = new google.maps.LatLng(position.latitude, position.longitude);
      if (bounds.contains(latLng) && position.timerStart) {
        const timerStart = filteredActiveTimerStartsUrls.get(position.timerStart) as TimerStart;
        console.assert(timerStart);
        const point = mapCanvasProjection.fromLatLngToDivPixel(latLng);
        if (point) {
          const {x, y} = point;
          markerPositions.push({position, timerStart, x, y});
        }
      }
    });
    const markerClusters: {
      positionsWithTimerStarts: {
        position: Position;
        timerStart: TimerStart;
        x: number;
        y: number;
      }[];
      x: number;
      y: number;
    }[] = [];
    markerPositions.forEach((markerPosition) => {
      for (let i = 0; i < markerClusters.length; i += 1) {
        const markerCluster = markerClusters[i];
        const xDistance = Math.abs(markerCluster.x - markerPosition.x);
        const yDistance = Math.abs(markerCluster.y - markerPosition.y);
        if (xDistance < MIN_X_DISTANCE_PIXELS && yDistance < MIN_Y_DISTANCE_PIXELS) {
          markerCluster.positionsWithTimerStarts.push(markerPosition);
          markerCluster.x =
            markerCluster.positionsWithTimerStarts.reduce((acc, {x}) => acc + x, 0) /
            markerCluster.positionsWithTimerStarts.length;
          markerCluster.y =
            markerCluster.positionsWithTimerStarts.reduce((acc, {y}) => acc + y, 0) /
            markerCluster.positionsWithTimerStarts.length;
          return;
        }
      }
      markerClusters.push({
        positionsWithTimerStarts: [markerPosition],
        x: markerPosition.x,
        y: markerPosition.y,
      });
    });
    const warningThresholdInMinutes =
      customerSettings.geolocation.activeMarkerWarningThresholdInMinutes;
    const markers = markerClusters
      .map(({positionsWithTimerStarts, x, y}) => {
        const timerStarts = positionsWithTimerStarts.map((p) => p.timerStart);
        const color = getColor(timerStarts, timerLookup);
        const positions = positionsWithTimerStarts.map((p) => p.position);
        const newestPosition = _.maxBy(positions, getNormalisedDeviceTimestamp) as Position;
        const timestamp = new Date(newestPosition.deviceTimestamp);
        const ageInMinutes = (now.valueOf() - timestamp.valueOf()) / MINUTE_MILLISECONDS;
        const oldWarning = ageInMinutes > warningThresholdInMinutes;
        const userTexts: {
          employeeAlias: string;
          machinesOrWorkType: string;
          taskID: string;
        }[] = [];
        const customerNames: string[] = [];
        timerStarts.forEach((timerStart) => {
          const task = taskLookup(timerStart.task);
          if (!task || !task.machineOperator) {
            return;
          }
          const userProfile = userUserProfileLookup(task.machineOperator);
          if (!userProfile) {
            return;
          }
          let machinesOrWorkType = "";
          if (customerSettings.noExternalTaskWorkType || !task.workType) {
            machinesOrWorkType = (task.machineuseSet || [])
              .map((machineUse) => machineLookup(machineUse.machine))
              .filter(notUndefined)
              .map((machine) => machine.c5_machine)
              .join(", ");
          } else {
            const workType = workTypeLookup(task.workType);
            if (workType) {
              machinesOrWorkType = `${workType.identifier}, ${workType.name}`;
            }
          }
          userTexts.push({
            employeeAlias: userProfile.alias,
            machinesOrWorkType,
            taskID: urlToId(task.url),
          });
          const order = task.order && orderLookup(task.order);
          if (order && order.customer) {
            const customer = customerLookup(order.customer);
            if (customer) {
              const customerName = customer.name;
              if (!customerNames.includes(customerName)) {
                customerNames.push(customerName);
              }
            }
          }
        });
        userTexts.sort((a, b) => a.employeeAlias.localeCompare(b.employeeAlias));
        customerNames.sort();
        if (userTexts.length) {
          const latLng = mapCanvasProjection.fromDivPixelToLatLng(new google.maps.Point(x, y));
          return (
            <ActiveMarker
              key={userTexts[0].taskID}
              color={color}
              customerNames={customerNames}
              lat={latLng?.lat()}
              lng={latLng?.lng()}
              oldWarning={oldWarning}
              userTexts={userTexts}
              x={x}
              y={y}
            />
          );
        } else {
          return null;
        }
      })
      .filter(notNull);
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return ReactDOM.createPortal(<>{markers}</>, containerElement);
  } else {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }
}
