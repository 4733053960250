import {resourceNames} from "@co-common-libs/resources";
import * as actions from "../resources/actions";
import {QueryQueryStateStruct} from "../resources/types";
import {ResourcesAuthenticationMiddlewareAPI} from "./types";

const MAX_FETCH_IDS_PARAMETER = 100;

export function startMissingIdFetch(
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  baseURL: string,
): void {
  const {
    fetchByIdActive,
    fetchByRelationActive,
    persistedFetchByID,
    persistedQueries,
    temporaryFetchByID,
    temporaryQueries,
  } = middlewareApi.getState().resources;
  if (
    (Object.values(persistedQueries) as QueryQueryStateStruct[]).some(
      ({queryState}) => queryState.currentlyFullFetching,
    )
  ) {
    return;
  }
  if (
    (Object.values(temporaryQueries) as QueryQueryStateStruct[]).some(
      ({queryState}) => queryState.currentlyFullFetching,
    )
  ) {
    return;
  }
  if (Object.values(fetchByRelationActive).some(Boolean)) {
    return;
  }
  resourceNames.forEach((resourceName) => {
    if (fetchByIdActive[resourceName]) {
      return;
    }
    const persistedForResource = persistedFetchByID[resourceName];
    const temporaryForResourcu = temporaryFetchByID[resourceName];
    const parametersForResource = persistedForResource || temporaryForResourcu;
    if (parametersForResource) {
      console.assert(parametersForResource.length > 0);
      const ids = parametersForResource.slice(0, MAX_FETCH_IDS_PARAMETER);
      middlewareApi.dispatch(
        actions.performIdFetch({
          baseURL,
          ids,
          resourceName,
        }),
      );
    }
  });
}
