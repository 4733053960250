import {Config} from "@co-common-libs/config";
import {
  Customer,
  CustomerUrl,
  Machine,
  MachineUrl,
  Order,
  OrderUrl,
  Product,
  ProductUrl,
  Project,
  ProjectUrl,
  RouteTask,
  Task,
  TaskUrl,
  UserProfile,
  UserUrl,
} from "@co-common-libs/resources";
import {notUndefined} from "@co-common-libs/utils";
import {
  getCustomerLookup,
  getCustomerSettings,
  getMachineLookup,
  getOrderLookup,
  getProductLookup,
  getProjectLookup,
  getRouteTaskArray,
  getUserUserProfileLookup,
} from "@co-frontend-libs/redux";
import {useMemo} from "react";
import {useSelector} from "react-redux";
import {economicCustomerIssues} from "../issues/task-issues/economic-customer-issues";
import {economicMachineIssues} from "../issues/task-issues/economic-machine-issues";
import {economicMachineOperatorIssues} from "../issues/task-issues/economic-machine-operator-issues";
import {economicProductIssues} from "../issues/task-issues/economic-product-issues";
import {economicProjectIssues} from "../issues/task-issues/economic-project-issues";
import {economicTaskIssues} from "../issues/task-issues/economic-task-issues";
import {EconomicIssue, IssueContext} from "../issues/types";

function getAllEconomicIssuesForTask(
  {
    context,
    customerLookup,
    customerSettings,
    machineLookup,
    orderLookup,
    productLookup,
    projectLookup,
    routeTaskArray,
    userProfileLookup,
  }: {
    context: IssueContext;
    customerLookup: (url: CustomerUrl) => Customer | undefined;
    customerSettings: Config;
    machineLookup: (url: MachineUrl) => Machine | undefined;
    orderLookup: (url: OrderUrl) => Order | undefined;
    productLookup: (url: ProductUrl) => Product | undefined;
    projectLookup: (url: ProjectUrl) => Project | undefined;
    routeTaskArray: readonly RouteTask[];
    userProfileLookup: (url: UserUrl) => UserProfile | undefined;
  },
  task: Task,
): readonly EconomicIssue[] {
  const taskIssues: (EconomicIssue | readonly EconomicIssue[] | undefined)[] = [
    economicCustomerIssues({
      context,
      customerLookup,
      customerSettings,
      orderLookup,
      routeTaskArray,
      task,
    }),
    economicTaskIssues(task),
    economicMachineOperatorIssues({
      customerSettings,
      task,
      userProfileLookup,
    }),
    economicMachineIssues({
      customerSettings,
      machineLookup,
      task,
    }),
    economicProductIssues({productLookup, task}),
    economicProjectIssues({customerSettings, projectLookup, task}),
  ];

  return taskIssues.flat().filter(notUndefined);
}
export function useEconomicTasksIssues(
  tasks: readonly Task[],
  context: IssueContext,
): ReadonlyMap<TaskUrl, readonly EconomicIssue[]> {
  const customerSettings = useSelector(getCustomerSettings);

  const routeTaskArray = useSelector(getRouteTaskArray);

  const userProfileLookup = useSelector(getUserUserProfileLookup);
  const orderLookup = useSelector(getOrderLookup);
  const customerLookup = useSelector(getCustomerLookup);
  const machineLookup = useSelector(getMachineLookup);
  const productLookup = useSelector(getProductLookup);
  const projectLookup = useSelector(getProjectLookup);

  return useMemo(() => {
    const taskResults = new Map<TaskUrl, readonly EconomicIssue[]>();

    if (!customerSettings.economicSync) {
      return taskResults;
    }

    const getAllEconomicIssues = getAllEconomicIssuesForTask.bind(null, {
      context,
      customerLookup,
      customerSettings,
      machineLookup,
      orderLookup,
      productLookup,
      projectLookup,
      routeTaskArray,
      userProfileLookup,
    });

    tasks.forEach((task) => {
      if (!task.recordedInC5 && !task.archivable) {
        const taskIssues = getAllEconomicIssues(task);

        if (taskIssues.length) {
          taskResults.set(task.url, taskIssues);
        }
      }
    });

    return taskResults;
  }, [
    context,
    customerLookup,
    customerSettings,
    machineLookup,
    orderLookup,
    productLookup,
    projectLookup,
    routeTaskArray,
    tasks,
    userProfileLookup,
  ]);
}
