import _ from "lodash";
import {isEconomicCustomerIssue} from "../economic-issue-type-guards";
import {EconomicCustomerIssue, EconomicIssue} from "../types";

/**
 * Issues are evaluated per task, meaning that the tasks with the same customer will all repeat the same issue for that customer.
 *
 * Given the full list of task issues, this function returns a list of unique customer issues.
 *
 * Example:
 *   TaskA: CustomerA
 *   TaskB: CustomerA
 *   TaskC: CustomerA
 *   TaskD: CustomerB
 *
 *   CustomerA is barred and CustomerB inactive: [CustomerABarred, CustomerABarred, CustomerABarred, CustomerBInactive]
 *
 *   The result of this function will be a single instance of each issue:
 *
 *     input: [CustomerABarred, CustomerABarred, CustomerABarred, CustomerBInactive]
 *     output: [CustomerABarred, CustomerBInactive]
 */
export function uniqueCustomerIssues({
  issues,
}: {
  issues: readonly EconomicIssue[];
}): readonly EconomicCustomerIssue[] {
  const issuesMatchingResource = issues.filter(isEconomicCustomerIssue);

  const issuesGroupedByIssueType = _.groupBy(issuesMatchingResource, ({issueType}) => issueType);

  return Object.values(issuesGroupedByIssueType)
    .map((groupedIssues) => {
      const {category, issueType} = groupedIssues[0];
      const uniqueCustomers = _.uniq(groupedIssues.map(({instance}) => instance));

      const uniqueIssues: EconomicCustomerIssue[] = uniqueCustomers.map((instance) => ({
        category,
        instance,
        issueType,
        resourceName: "customer",
      }));

      return uniqueIssues;
    })
    .flat();
}
