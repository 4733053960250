import {IconButton} from "@material-ui/core";
import PencilIcon from "mdi-react/PencilIcon";
import React, {useCallback} from "react";
import {DragDropReorderIdentifierChips} from "../drag-drop-reorder-identifier-chips";
import {DeviceType, UserType} from "./types";

interface ColumnListEditProps<ColumnChoice extends string, ListIdentifier extends string> {
  deviceType: DeviceType;
  identifiers: readonly ColumnChoice[];
  listIdentifier: ListIdentifier;
  onChange: (
    reorderedIdentifiers: readonly ColumnChoice[],
    listIdenfier: ListIdentifier,
    userType: UserType,
    deviceType: DeviceType,
  ) => void;
  onEditClick: (listIdenfier: ListIdentifier, userType: UserType, deviceType: DeviceType) => void;
  userType: UserType;
}

export function ColumnListEdit<ColumnChoice extends string, ListIdentifier extends string>(
  props: ColumnListEditProps<ColumnChoice, ListIdentifier>,
): JSX.Element {
  const {deviceType, identifiers, listIdentifier, onChange, onEditClick, userType} = props;

  const handleEditClick = useCallback(() => {
    onEditClick(listIdentifier, userType, deviceType);
  }, [deviceType, listIdentifier, onEditClick, userType]);

  const handleChange = useCallback(
    (reorderedIdentifiers: readonly ColumnChoice[]): void => {
      onChange(reorderedIdentifiers, listIdentifier, userType, deviceType);
    },
    [deviceType, listIdentifier, onChange, userType],
  );

  return (
    <div>
      <DragDropReorderIdentifierChips
        identifiers={identifiers}
        itemType={`${listIdentifier}-${userType}-${deviceType}`}
        onChange={handleChange}
      />
      <IconButton onClick={handleEditClick}>
        <PencilIcon />
      </IconButton>
    </div>
  );
}
