import {Timer, TimerUrl} from "@co-common-libs/resources";
import {dateToString, formatDate, formatTime} from "@co-common-libs/utils";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {useResettingState} from "@co-frontend-libs/utils";
import {DialogContent, Grid} from "@material-ui/core";
import {padZero} from "app-utils";
import {SPACING} from "frontend-global-config";
import React, {useCallback} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {TimeCorrectionForm, TimeCorrectionFormProps} from "./time-correction-form";
import {dateFromDateAndTime, selectionCheck} from "./time-correction-utils";

const timeStringFromTimestamp = (timestamp: string): string | null => {
  const date = new Date(timestamp);
  const digits = 2;
  return `${padZero(date.getHours(), digits)}:${padZero(date.getMinutes(), digits)}:00`;
};
const dateStringFromTimestamp = (timestamp: string): string => {
  return dateToString(new Date(timestamp));
};

interface EditTimeCorrectionDialogProps
  extends Pick<
    TimeCorrectionFormProps,
    | "genericPrimaryTimer"
    | "genericPrimaryTimerLabel"
    | "hidePrimaryTimer"
    | "legalIntervals"
    | "secondaryTimers"
  > {
  correctionDisabled?: boolean;
  fromTimestamp: string | null;
  onCancel: () => void;
  onOk: (fromTimestamp: string, toTimestamp: string, timer: TimerUrl | null) => void;
  open: boolean;
  timer: Timer | null;
  toTimestamp: string | null;
}

export const EditTimeCorrectionDialog = React.memo(function EditTimeCorrectionDialog(
  props: EditTimeCorrectionDialogProps,
) {
  const {
    fromTimestamp,
    genericPrimaryTimerLabel,
    legalIntervals,
    onCancel,
    onOk,
    open,
    timer: timerFromProp,
    toTimestamp,
    ...other
  } = props;

  const intl = useIntl();

  const [fromDate, setFromDate] = useResettingState<string | null>(
    fromTimestamp ? dateStringFromTimestamp(fromTimestamp) : null,
    open,
  );
  const [toDate, setToDate] = useResettingState<string | null>(
    toTimestamp ? dateStringFromTimestamp(toTimestamp) : null,
    open,
  );

  const [fromTime, setFromTime] = useResettingState<string | null>(
    fromTimestamp ? timeStringFromTimestamp(fromTimestamp) : null,
    open,
  );
  const [toTime, setToTime] = useResettingState<string | null>(
    toTimestamp ? timeStringFromTimestamp(toTimestamp) : null,
    open,
  );

  const [timerUrl, setTimerUrl] = useResettingState<TimerUrl | null>(
    timerFromProp ? timerFromProp.url : null,
    open,
  );

  const {formValid} = selectionCheck({
    fromDate,
    fromTime,
    legalIntervals,
    timerUrl,
    toDate,
    toTime,
  });

  const [title] = useResettingState<string>(
    !timerFromProp
      ? intl.formatMessage({defaultMessage: "Slet tiden i perioden"})
      : timerFromProp.isGenericEffectiveTime
        ? genericPrimaryTimerLabel
        : timerFromProp.label,
    open,
  );

  const handleOk = useCallback(() => {
    if (fromDate && fromTime && toDate && toTime) {
      const fromDateTime = dateFromDateAndTime(fromDate, fromTime).toISOString();
      const toDateTime = dateFromDateAndTime(toDate, toTime).toISOString();
      onOk(fromDateTime, toDateTime, timerUrl);
    }
  }, [fromDate, fromTime, onOk, timerUrl, toDate, toTime]);

  return (
    <ResponsiveDialog
      okDisabled={!formValid}
      open={open}
      title={title}
      onCancel={onCancel}
      onOk={handleOk}
    >
      <DialogContent>
        <Grid container direction="column" spacing={SPACING.SMALL}>
          {fromTimestamp || toTimestamp ? (
            <Grid item>
              {fromTimestamp ? (
                <div>
                  <FormattedMessage
                    defaultMessage="Nuværende fra-tidspunkt: {date}, {time}"
                    values={{
                      date: formatDate(fromTimestamp),
                      time: formatTime(fromTimestamp),
                    }}
                  />
                </div>
              ) : null}
              {toTimestamp ? (
                <div>
                  <FormattedMessage
                    defaultMessage="Nuværende til-tidspunkt: {date}, {time}"
                    values={{
                      date: formatDate(toTimestamp),
                      time: formatTime(toTimestamp),
                    }}
                  />
                </div>
              ) : null}
            </Grid>
          ) : null}
          <Grid item>
            <TimeCorrectionForm
              fromDate={fromDate}
              fromTime={fromTime}
              genericPrimaryTimerLabel={genericPrimaryTimerLabel}
              legalIntervals={legalIntervals}
              selectedTimer={timerUrl}
              toDate={toDate}
              toTime={toTime}
              onFromDateChange={setFromDate}
              onFromTimeChange={setFromTime}
              onTimerUrlChange={setTimerUrl}
              onToDateChange={setToDate}
              onToTimeChange={setToTime}
              {...other}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </ResponsiveDialog>
  );
});
