export const sumReduction = (acc: number, val: number): number => acc + val;

export const sum = (iterable: readonly number[]): number => iterable.reduce(sumReduction, 0);

export function mapSum<T>(map: ReadonlyMap<T, number>): number {
  let result = 0;
  const addToResult = (n: number): void => {
    result += n;
  };
  map.forEach(addToResult);
  return result;
}
