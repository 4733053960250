import {SettingID, schema} from "@co-common-libs/config";
import {SimpleListColumnsDialog} from "./simple-list-columns-dialog";

interface EconomicSyncListColumnsDialogProps {
  onClose: () => void;
  open: boolean;
  settingID: SettingID;
}

export function EconomicSyncListColumnsDialog(
  props: EconomicSyncListColumnsDialogProps,
): JSX.Element {
  const columnChoices = schema.properties.economicSyncListColumns.items.enum;
  type ColumnChoice = (typeof columnChoices)[number];
  return SimpleListColumnsDialog<ColumnChoice>({
    ...props,
    columnChoices,
  });
}
