import {
  Customer,
  CustomerUrl,
  Location,
  Order,
  OrderUrl,
  PriceGroup,
  PriceGroupUrl,
  Product,
  Task,
  WorkType,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {getWorkTypeString} from "@co-common-libs/resources-utils";
import {normalizeSearchTextArray} from "@co-common-libs/utils";
import _ from "lodash";
import {IntlShape} from "react-intl";
import {findMatches} from "./find-matches";
import {Match, SearchField} from "./types";

export interface FieldEntry {
  readonly field: Location;
  readonly matches?: readonly Match[];
}

export interface SearchResultFieldEntry {
  readonly field: Location;
  readonly matches: readonly Match[];
}

interface FieldMatchDataLookups {
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  getLocationActiveTasks: ((location: Location) => readonly Task[]) | null;
  getLocationProducts: ((location: Location) => readonly Product[]) | null;
  orderLookup: ((url: OrderUrl) => Order | undefined) | null;
  priceGroupLookup: ((url: PriceGroupUrl) => PriceGroup | undefined) | null;
  workTypeLookup: ((url: WorkTypeUrl) => WorkType | undefined) | null;
}

export function computeFieldMatchesData(
  filteredSortedFieldArray: readonly Location[],
  trimmedFilterString: string,
  intl: IntlShape,
  lookups: FieldMatchDataLookups,
  matchType: "AND" | "OR",
): readonly FieldEntry[] {
  if (!trimmedFilterString) {
    return filteredSortedFieldArray.map((field) => ({field}));
  } else {
    const {
      customerLookup,
      getLocationActiveTasks,
      getLocationProducts,
      orderLookup,
      priceGroupLookup,
      workTypeLookup,
    } = lookups;
    const codeLabel = intl.formatMessage({defaultMessage: "Mark nr."});
    const cropLabel = intl.formatMessage({defaultMessage: "Afgrøde"});
    const fieldYear = intl.formatMessage({defaultMessage: "Ansøgningsår"});
    const nameLabel = intl.formatMessage({defaultMessage: "Navn"});
    const addressLabel = intl.formatMessage({defaultMessage: "Adresse"});
    const customerNameLabel = intl.formatMessage({defaultMessage: "Kundenavn"});
    const productNameLabel = intl.formatMessage({defaultMessage: "Varenavn"});
    const productCatalogNumberLabel = intl.formatMessage({
      defaultMessage: "Varenummer",
    });
    const taskWorkTypeLabel = intl.formatMessage({
      defaultMessage: "Opgave område",
    });
    const taskPriceGroupLabel = intl.formatMessage({
      defaultMessage: "Opgave variant",
    });
    const taskCustomerNameLabel = intl.formatMessage({
      defaultMessage: "Opgave kundenavn",
    });
    const taskCustomerAccountLabel = intl.formatMessage({
      defaultMessage: "Opgave kundekontonr.",
    });

    const needleArray = normalizeSearchTextArray(trimmedFilterString);
    const getScore = (match: Match): number => match.score;

    return filteredSortedFieldArray
      .map((field): SearchResultFieldEntry => {
        const searchFields: SearchField[] = [
          {label: nameLabel, priority: 5, text: field.name},
          {label: addressLabel, priority: 4, text: field.address},
        ];
        if (field.fieldNumber) {
          searchFields.push({
            label: codeLabel,
            priority: 5,
            text: field.fieldNumber,
          });
        }
        if (field.fieldCrop) {
          searchFields.push({
            label: cropLabel,
            priority: 10,
            text: field.fieldCrop,
          });
        }
        if (field.customer) {
          const customerName = customerLookup(field.customer)?.name;
          if (customerName) {
            searchFields.push({
              label: customerNameLabel,
              priority: 3,
              text: customerName,
            });
          }
        }
        if (field.fieldRecordYear != null) {
          searchFields.push({
            label: fieldYear,
            priority: 3,
            text: field.fieldRecordYear?.toString() || "",
          });
        }
        if (getLocationProducts) {
          const products = getLocationProducts(field);
          for (const product of products) {
            if (product.name) {
              searchFields.push({
                label: productNameLabel,
                priority: 1,
                text: product.name,
              });
            }
            if (product.catalogNumber) {
              searchFields.push({
                label: productCatalogNumberLabel,
                priority: 1,
                text: product.catalogNumber,
              });
            }
          }
        }
        if (getLocationActiveTasks) {
          const tasks = getLocationActiveTasks(field);
          for (const task of tasks) {
            if (workTypeLookup) {
              const workType = task.workType ? workTypeLookup(task.workType) : undefined;
              if (workType) {
                searchFields.push({
                  label: taskWorkTypeLabel,
                  priority: 1,
                  text: getWorkTypeString(workType),
                });
              }
            }
            if (priceGroupLookup) {
              const priceGroup = task.priceGroup ? priceGroupLookup(task.priceGroup) : null;
              if (priceGroup) {
                searchFields.push({
                  label: taskPriceGroupLabel,
                  priority: 1,
                  text: priceGroup.name,
                });
              }
              for (const machineUse of task.machineuseSet) {
                const machinePriceGroup = machineUse.priceGroup
                  ? priceGroupLookup(machineUse.priceGroup)
                  : undefined;
                if (machinePriceGroup) {
                  searchFields.push({
                    label: taskPriceGroupLabel,
                    priority: 1,
                    text: machinePriceGroup.name,
                  });
                }
              }
            }
            if (orderLookup) {
              const order = task.order ? orderLookup(task.order) : undefined;
              if (order) {
                const taskCustomer = order.customer ? customerLookup(order.customer) : undefined;
                if (taskCustomer) {
                  searchFields.push({
                    label: taskCustomerNameLabel,
                    priority: 1,
                    text: taskCustomer.name,
                  });
                  if (taskCustomer.c5_account != null) {
                    searchFields.push({
                      label: taskCustomerAccountLabel,
                      priority: 1,
                      text: taskCustomer.c5_account,
                    });
                  }
                }
              }
            }
          }
        }
        const matches = findMatches(matchType, needleArray, searchFields);
        return {field, matches};
      })
      .filter((entry) => entry.matches.length)
      .sort((a, b) => _.sumBy(b.matches, getScore) - _.sumBy(a.matches, getScore));
  }
}
