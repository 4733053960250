export const DAYS_BACK = 0;
// remember 1 for "today"
export const DAYS_FORWARD = 15;
export const TOTAL_DAYS = DAYS_BACK + DAYS_FORWARD;
export const ARCHIVE_DAYS = 7;
export const INITIALS_WIDTH = 45;
export const TIME_DURATION_WIDTH = 135;

export const MACHINES_WIDTH = 110;

export const HEADER_HEIGHT = 24;

export const DAY_HEIGHT = 48 + 4;
export const DAY_WIDTH = 300;
