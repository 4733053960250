import {Draft, createReducer} from "@reduxjs/toolkit";
import * as actions from "./actions";

export type ConnectivityState = {
  readonly offline: readonly string[];
  readonly online: readonly string[];
};

const initialState: ConnectivityState = {
  offline: [],
  online: [],
};

const RECORD_COUNT = 10;

function addRecord(result: Draft<string[]>, timestamp: string): void {
  result.unshift(timestamp);
  if (result.length > RECORD_COUNT) {
    result.slice(0, RECORD_COUNT);
  }
}

export const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(actions.online, (state, action) => addRecord(state.online, action.payload))
    .addCase(actions.offline, (state, action) => addRecord(state.offline, action.payload)),
);
