import {DebouncedAppbarSearchField} from "@co-frontend-libs/components";
import {actions, getCurrentRole, getCustomerSettings} from "@co-frontend-libs/redux";
import {Toolbar} from "@material-ui/core";
import {DrawerMarginAppBar, GeolocationMap, MenuToolbar} from "app-components";
import {useQueryParameter} from "app-utils";
import React, {useCallback} from "react";
import {defineMessages, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

const messages = defineMessages({
  search: {
    defaultMessage: "Søg",
    id: "geolocation-map.search",
  },
  title: {defaultMessage: "Kort", id: "geolocation-map.title"},
});

interface GeolocationMapPageProps {
  onMenuButton: (event: React.MouseEvent) => void;
}

const GeolocationMapPage = React.memo(function GeolocationMapPage(
  props: GeolocationMapPageProps,
): JSX.Element | null {
  const role = useSelector(getCurrentRole);
  const customerSettings = useSelector(getCustomerSettings);
  const isManager = role && role.manager;
  const {formatMessage} = useIntl();

  const searchString = useQueryParameter("q", "");
  const dispatch = useDispatch();

  const handleSearchStringChange = useCallback(
    (search: string) => {
      dispatch(actions.putQueryKey("q", search));
    },
    [dispatch],
  );
  if (!customerSettings.geolocation.enabled || (!isManager && !customerSettings.mapVisibleToAll)) {
    return null;
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      }}
    >
      <DrawerMarginAppBar>
        <MenuToolbar
          rightElement={
            customerSettings.customerFields || customerSettings.enableLocationStorage ? (
              <DebouncedAppbarSearchField
                debounceTimeout={200}
                placeholder={formatMessage(messages.search)}
                value={searchString}
                onChange={handleSearchStringChange}
              />
            ) : undefined
          }
          title={formatMessage(messages.title)}
          onMenuButton={props.onMenuButton}
        />
      </DrawerMarginAppBar>
      <Toolbar />
      <div style={{flex: 1}}>
        <GeolocationMap searchString={searchString} />
      </div>
    </div>
  );
});

export default GeolocationMapPage;
