import {WorkTypeUrl} from "@co-common-libs/resources";
import React from "react";
import {
  ConnectedMultipleWorkTypesDialog,
  ConnectedWorkTypeDialog,
} from "./connected-work-type-dialog";
import {
  getActiveInternalTaskPrimaryWorkshopWorkTypeArray,
  getFilteredActiveInternalTaskPrimaryWorkTypeArray,
} from "./selectors";

interface WorkTypeDialogProps {
  onCancel(): void;
  onNone?: () => void;
  onOk(url: WorkTypeUrl): void;
  open: boolean;
}

export const ConnectedInternalWorkTypeDialog = React.memo(function ConnectedInternalWorkTypeDialog(
  props: WorkTypeDialogProps,
): JSX.Element {
  return (
    <ConnectedWorkTypeDialog
      workTypeArraySelector={getFilteredActiveInternalTaskPrimaryWorkTypeArray}
      {...props}
    />
  );
});

/** Selection among internal workshop worktypes if setting workshopWorkTypes
 *  not empty; if setting workshopWorkTypes empty, then selection among all
 *  internal worktypes.
 */
export const ConnectedInternalWorkshopWorkTypeDialog = React.memo(
  function ConnectedInternalWorkshopWorkTypeDialog(props: WorkTypeDialogProps): JSX.Element {
    return (
      <ConnectedWorkTypeDialog
        workTypeArraySelector={getActiveInternalTaskPrimaryWorkshopWorkTypeArray}
        {...props}
      />
    );
  },
);

interface MultipleWorkTypesDialogProps {
  onCancel(): void;
  onOk(urls: ReadonlySet<WorkTypeUrl>): void;
  open: boolean;
  selected?: ReadonlySet<WorkTypeUrl>;
}

export const ConnectedMultipleInternalWorkTypesDialog = React.memo(
  function ConnectedMultipleInternalWorkTypesDialog(
    props: MultipleWorkTypesDialogProps,
  ): JSX.Element {
    return (
      <ConnectedMultipleWorkTypesDialog
        workTypeArraySelector={getFilteredActiveInternalTaskPrimaryWorkTypeArray}
        {...props}
      />
    );
  },
);
