import {EmployeeGroup, EmployeeGroupUrl} from "@co-common-libs/resources";
import {ColumnSpecifications, GenericTable, RowData} from "@co-frontend-libs/components";
import {actions, getTableSortingState} from "@co-frontend-libs/redux";
import CheckIcon from "mdi-react/CheckIcon";
import React, {useCallback, useMemo} from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

const TABLE_SORTING_IDENTIFIER = "EmployeeGroupTable";

type EmployeeGroupTableFieldID = "active" | "name";

type EmployeeGroupTableColumID = "active" | "name";

interface EmployeeGroupTableDataType extends RowData<EmployeeGroupTableFieldID, EmployeeGroupUrl> {
  active: boolean;
  name: string;
}

function renderActive(data: EmployeeGroupTableDataType): JSX.Element {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return data.active ? <CheckIcon /> : <></>;
}

function buildColumnSpecifications(
  onClick: ((employeeGroupURL: EmployeeGroupUrl) => void) | undefined,
): ColumnSpecifications<
  EmployeeGroupTableFieldID,
  EmployeeGroupTableColumID,
  EmployeeGroupUrl,
  EmployeeGroupTableDataType
> {
  return {
    active: {
      field: "active",
      label: <FormattedMessage defaultMessage="Aktiv" />,
      onClick,
      render: renderActive,
    },
    name: {
      field: "name",
      label: <FormattedMessage defaultMessage="Navn" />,
      onClick,
    },
  };
}

function buildRowData(employeeGroupArray: readonly EmployeeGroup[]): EmployeeGroupTableDataType[] {
  return employeeGroupArray.map((employeeGroup) => {
    const {active, name, url} = employeeGroup;
    return {
      active,
      key: url,
      name,
    };
  });
}
interface EmployeeGroupTableProps {
  employeeGroups: readonly EmployeeGroup[];
  filterString: string;
  onEditEmployeeGroup: (employeeGroupURL: EmployeeGroupUrl) => void;
  showInactive: boolean;
}

export function EmployeeGroupTable(props: EmployeeGroupTableProps): JSX.Element {
  const {employeeGroups, filterString, onEditEmployeeGroup, showInactive} = props;
  const dispatch = useDispatch();

  const columnSpecifications = useMemo(
    () => buildColumnSpecifications(onEditEmployeeGroup),
    [onEditEmployeeGroup],
  );
  const rowData = useMemo(() => buildRowData(employeeGroups), [employeeGroups]);

  const visibleColumns = useMemo((): readonly EmployeeGroupTableColumID[] => {
    return (showInactive ? ["name", "active"] : ["name"]) as EmployeeGroupTableColumID[];
  }, [showInactive]);

  const sortingStateSelector = useMemo(
    () => getTableSortingState(TABLE_SORTING_IDENTIFIER, "name", "ASC"),
    [],
  );
  const {sortDirection, sortKey} = useSelector(sortingStateSelector);

  const handleHeaderClick = useCallback(
    (key: EmployeeGroupTableColumID): void => {
      let direction: "ASC" | "DESC" = "ASC";
      if (sortKey === key && sortDirection === "ASC") {
        direction = "DESC";
      }
      const action = actions.putTableSortingState(TABLE_SORTING_IDENTIFIER, key, direction);
      dispatch(action);
    },
    [dispatch, sortKey, sortDirection],
  );

  return (
    <GenericTable
      columns={columnSpecifications}
      entries={rowData}
      filterString={filterString}
      sortBy={sortKey as any}
      sortDirection={sortDirection}
      visibleColumns={visibleColumns}
      onHeaderClick={handleHeaderClick}
    />
  );
}
