import {getCustomerSettings, getPriceGroupArray, getWorkTypeArray} from "@co-frontend-libs/redux";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import React from "react";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {SettingValueDisplayProps} from "../types";

export function DisplayPrimaryTimerLabels(_props: SettingValueDisplayProps): JSX.Element {
  const customerSettings = useSelector(getCustomerSettings);
  // eslint-disable-next-line prefer-destructuring
  const workTypePriceGroupGenericPrimaryTimerLabel: {
    [worktypeIdentifier: string]: {[priceGroupIdentifier: string]: string | undefined} | undefined;
  } = customerSettings.workTypePriceGroupGenericPrimaryTimerLabel;
  const workTypeArray = useSelector(getWorkTypeArray);
  const priceGroupArray = useSelector(getPriceGroupArray);
  return (
    <div>
      <i>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{width: 200}}>
                <FormattedMessage defaultMessage="Område" id="system-setup.absence-type" />
              </TableCell>
              <TableCell style={{width: 300}}>
                <FormattedMessage defaultMessage="Variant" id="system-setup.color" />
              </TableCell>
              <TableCell>
                <FormattedMessage defaultMessage="Label" id="system-setup.label" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(workTypePriceGroupGenericPrimaryTimerLabel).map(
              ([worktypeIdentifier, priceGroups]) => {
                const workType = workTypeArray.find((w) => w.identifier === worktypeIdentifier);
                const priceGroupEntries = priceGroups ? Object.entries(priceGroups) : null;
                if (!priceGroupEntries?.length) {
                  return (
                    <TableRow key={worktypeIdentifier}>
                      <TableCell>
                        {worktypeIdentifier} {workType?.name}
                      </TableCell>
                      <TableCell />
                      <TableCell />
                    </TableRow>
                  );
                } else {
                  return (
                    <React.Fragment key={worktypeIdentifier}>
                      {priceGroupEntries.map(([priceGroupIdentifier, label], index) => {
                        const priceGroup = priceGroupArray.find(
                          (pg) => pg.identifier === priceGroupIdentifier,
                        );
                        return (
                          <TableRow key={`${worktypeIdentifier}-${priceGroupIdentifier}`}>
                            <TableCell style={{borderBottom: "none"}}>
                              {index === 0 ? `${worktypeIdentifier} ${workType?.name}` : null}
                            </TableCell>
                            <TableCell>
                              {priceGroupIdentifier} {priceGroup?.name}
                            </TableCell>
                            <TableCell>{label}</TableCell>
                          </TableRow>
                        );
                      })}
                    </React.Fragment>
                  );
                }
              },
            )}
          </TableBody>
        </Table>
      </i>
    </div>
  );
}
