import {ReadonlyConfig} from "@co-common-libs/config";
import {ExtendedCustomerSettingsPart} from "./extended-customer-settings";

type ExtendedUnitSettingsPart = Pick<ReadonlyConfig, "canEditUnits"> & ExtendedCustomerSettingsPart;

export interface ExtendedUnitSettings {
  canCreate: boolean;
  canEdit: boolean;
}

export function extendedUnitSettings(config: ExtendedUnitSettingsPart): ExtendedUnitSettings {
  const {canEditUnits, unicontaSync} = config;

  return {
    canCreate: !unicontaSync,
    canEdit: canEditUnits && !unicontaSync,
  };
}
