import {MachineUrl} from "@co-common-libs/resources";
import {getMachineString} from "@co-common-libs/resources-utils";
import {notUndefined} from "@co-common-libs/utils";
import {MultipleMachinesDialog} from "@co-frontend-libs/components";
import {
  actions,
  getCurrentUserURL,
  getMachineArray,
  getMachineGroupLookup,
  getMachineLookup,
  getSettingsEntryLookupByIdentifier,
} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import React, {useCallback, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {SettingViewProps} from "../types";
import {DisplaySelected} from "./display-selected";

export function SelectAllMachines(props: SettingViewProps): JSX.Element {
  const {settingID, settingMetaData} = props;
  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const settingEntry = settingsEntryLookupByIdentifier(settingID);
  const machines: string[] = useMemo(() => settingEntry?.data || [], [settingEntry?.data]);
  const machineArray = useSelector(getMachineArray);
  const machineLookup = useSelector(getMachineLookup);
  const machineInstances = useMemo(() => {
    return machines
      .map((identifier) => machineArray.find((m) => m.c5_machine === identifier))
      .filter(notUndefined);
  }, [machines, machineArray]);

  const machineNames = useMemo(() => {
    const names = machineInstances.map((machine) => getMachineString(machine));
    names.sort();
    return names;
  }, [machineInstances]);

  const [machineDialogOpen, setMachineDialogOpen] = useState(false);
  const setMachineDialogOpenTrue = useCallWithTrue(setMachineDialogOpen);
  const setMachineDialogOpenFalse = useCallWithFalse(setMachineDialogOpen);

  const dispatch = useDispatch();
  const currentUserURL = useSelector(getCurrentUserURL);
  const handleMachineDialogOk = useCallback(
    (urls: ReadonlySet<MachineUrl>) => {
      setMachineDialogOpen(false);

      const newValue = [...urls].map((url) => machineLookup(url)?.c5_machine);
      if (settingEntry) {
        dispatch(
          actions.update(settingEntry.url, [
            {member: "changedBy", value: currentUserURL},
            {member: "data", value: newValue},
          ]),
        );
      }
    },
    [currentUserURL, dispatch, settingEntry, machineLookup],
  );

  const machineGroupLookup = useSelector(getMachineGroupLookup);

  return (
    <>
      <DisplaySelected
        currentValue={machineNames}
        settingEntry={settingEntry}
        settingID={settingID}
        settingMetaData={settingMetaData}
        onClick={setMachineDialogOpenTrue}
      />
      <MultipleMachinesDialog
        machineArray={machineArray}
        machineGroupLookup={machineGroupLookup}
        machineLabelVariant={"MACHINE"}
        open={machineDialogOpen}
        selected={new Set(machineInstances.map((machine) => machine?.url))}
        onCancel={setMachineDialogOpenFalse}
        onOk={handleMachineDialogOk}
      />
    </>
  );
}
