import {
  PriceItemUrl,
  RouteTaskActivityOptionUrl,
  RouteTaskResultUrl,
  RouteTaskUrl,
} from "../resource-urls";
import {ResourceInstance} from "./base";

export interface RouteTaskResult extends ResourceInstance<RouteTaskResultUrl> {
  readonly activityOption: RouteTaskActivityOptionUrl;
  readonly corrected: number | null;
  readonly order: number;
  readonly quantity: number | null;
  readonly quoted: number | null;
  readonly routeTask: RouteTaskUrl;
  readonly specification: PriceItemUrl;
}

export const emptyRouteTaskResult: Omit<
  RouteTaskResult,
  "activityOption" | "id" | "order" | "routeTask" | "specification" | "url"
> = {
  corrected: null,
  quantity: null,
  quoted: null,
};
