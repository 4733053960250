import {Availability, UserProfile, availabilityWeekdayFromDate} from "@co-common-libs/resources";
import {weekNumber} from "@co-common-libs/utils";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {actions} from "@co-frontend-libs/redux";
import {useResettingState} from "@co-frontend-libs/utils";
import {DialogContent, IconButton} from "@material-ui/core";
import {green, red} from "@material-ui/core/colors";
import {useFalseCallback, useTrueCallback} from "app-utils";
import {instanceURL} from "frontend-global-config";
import ThumbDownIcon from "mdi-react/ThumbDownIcon";
import ThumbDownOutlineIcon from "mdi-react/ThumbDownOutlineIcon";
import ThumbUpIcon from "mdi-react/ThumbUpIcon";
import ThumbUpOutlineIcon from "mdi-react/ThumbUpOutlineIcon";
import React, {useCallback} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch} from "react-redux";
import type {Writable} from "ts-essentials";
import {v4 as uuid} from "uuid";

interface UserAvailabilityChangeDialogProps {
  date: Date;
  instance?: Readonly<Availability> | undefined;
  onCancel: () => void;
  onOk: () => void;
  open: boolean;
  userProfile: Readonly<UserProfile>;
}

export function UserAvailabilityChangeDialog(
  props: UserAvailabilityChangeDialogProps,
): JSX.Element {
  const intl = useIntl();

  const {date, instance, onCancel, onOk, open, userProfile} = props;

  const [value, setValue] = useResettingState(
    instance ? instance[availabilityWeekdayFromDate(date)] : null,
    open,
  );

  const dispatch = useDispatch();

  const handleOk = useCallback(() => {
    const weekday = availabilityWeekdayFromDate(date);
    if (instance) {
      if (value !== instance[weekday]) {
        dispatch(actions.update(instance.url, [{member: weekday, value}]));
      }
    } else {
      const id = uuid();
      const url = instanceURL("availability", id);
      const {week, year} = weekNumber(date);
      const obj: Writable<Availability> = {
        friday: null,
        id,
        monday: null,
        saturday: null,
        sunday: null,
        thursday: null,
        tuesday: null,
        url,
        user: userProfile.user,
        wednesday: null,
        weekNumber: week,
        year,
      };
      obj[weekday] = value;
      dispatch(actions.create(obj));
    }
    onOk();
  }, [date, dispatch, instance, onOk, userProfile.user, value]);

  const baseSize = 48;
  const buttonStyle = {
    height: 2 * baseSize,
    padding: baseSize / 2,
    width: 2 * baseSize,
  };
  let thumbDownIcon, thumbUpIcon;
  if (value === true) {
    thumbUpIcon = <ThumbUpIcon color={green[300]} size={baseSize} />;
    thumbDownIcon = <ThumbDownOutlineIcon size={baseSize} />;
  } else if (value === false) {
    thumbUpIcon = <ThumbUpOutlineIcon size={baseSize} />;
    thumbDownIcon = <ThumbDownIcon color={red[300]} size={baseSize} />;
  } else {
    thumbUpIcon = <ThumbUpOutlineIcon size={baseSize} />;
    thumbDownIcon = <ThumbDownOutlineIcon size={baseSize} />;
  }
  const yesButtonStyle: React.CSSProperties = value
    ? {fill: green[300], ...buttonStyle}
    : buttonStyle;
  const noButtonStyle: React.CSSProperties = !value
    ? {fill: red[300], ...buttonStyle}
    : buttonStyle;
  return (
    <ResponsiveDialog
      open={open}
      title={intl.formatMessage({defaultMessage: "Rådighed"})}
      onCancel={onCancel}
      onOk={handleOk}
    >
      <DialogContent>
        <FormattedMessage
          defaultMessage="Angiv rådighed for {initials}"
          tagName="div"
          values={{initials: userProfile?.alias || ""}}
        />
        <IconButton style={yesButtonStyle} onClick={useTrueCallback(setValue, [setValue])}>
          {thumbUpIcon}
        </IconButton>
        <IconButton style={noButtonStyle} onClick={useFalseCallback(setValue, [setValue])}>
          {thumbDownIcon}
        </IconButton>
      </DialogContent>
    </ResponsiveDialog>
  );
}
