import {
  ResourceInstance,
  ResourceName,
  ResourceTypes,
  resourceNames,
} from "@co-common-libs/resources";
import {Draft, current} from "@reduxjs/toolkit";
import {buildErrorObject} from "../../types";
import {performChangesFetch} from "../actions";
import {ResourceInstanceRecords, ResourceURLArrays, ResourcesState} from "../types";
import {partialEntriesForEach, partialValuesForEach} from "../utils";
import {changeIsNewer} from "./change-is-newer";
import {getOfflineData} from "./selectors";
import {updateFromReceivedData} from "./update-from-received-data";

export function handlePerformChangesFetchPending(
  state: Draft<ResourcesState>,
  _action: ReturnType<typeof performChangesFetch.pending>,
): void {
  console.assert(!state.currentlyFetchingChanges);
  state.currentlyFetchingChanges = true;
}

function computeUpdatedUnchangedOnServer(
  currentOfflineResourcesData: ResourceInstanceRecords,
  changed: Partial<{
    readonly [resourceName in ResourceName]: readonly ResourceInstance[];
  }>,
): {
  unchangedOnServer: Partial<ResourceURLArrays>;
  updatedOnServer: Partial<ResourceInstanceRecords>;
} {
  const updatedOnServer: Partial<{
    [P in keyof ResourceTypes]: Partial<{
      [url: string]: Readonly<ResourceTypes[P]>;
    }>;
  }> = {};
  const unchangedOnServer: Partial<{
    [P in keyof ResourceTypes]: string[];
  }> = {};
  partialEntriesForEach(changed, (resourceName: ResourceName, changedForResource): void => {
    if (!resourceNames.includes(resourceName)) {
      // eslint-disable-next-line no-console
      console.warn(`change to unknown resource ${resourceName}`);
      return;
    }
    if (changedForResource && changedForResource.length) {
      const currentData = currentOfflineResourcesData[resourceName];
      const resourceUpdatedOnServer: Partial<{
        [url: string]: Readonly<ResourceInstance>;
      }> = {};
      const resourceUnchangedOnServer: string[] = [];
      // For each entry, add to "potentially update" set.
      for (let i = 0; i < changedForResource.length; i += 1) {
        const instance = changedForResource[i];
        const {url} = instance;
        if (changeIsNewer(instance, currentData[url])) {
          resourceUpdatedOnServer[url] = instance;
        } else {
          resourceUnchangedOnServer.push(url);
        }
      }
      console.assert(!updatedOnServer[resourceName]);
      if (Object.keys(resourceUpdatedOnServer).length) {
        updatedOnServer[resourceName] = resourceUpdatedOnServer as Partial<{
          [url: string]: Readonly<any>;
        }>;
      }
      if (resourceUnchangedOnServer.length) {
        unchangedOnServer[resourceName] = resourceUnchangedOnServer;
      }
    }
  });
  return {unchangedOnServer, updatedOnServer};
}

function computeDeletedOnServer(
  currentOfflineResourcesData: ResourceInstanceRecords,
  deleted: Partial<{
    readonly [resourceName in ResourceName]: readonly string[];
  }>,
): Partial<ResourceURLArrays> {
  const deletedOnServer: Partial<{
    [P in keyof ResourceTypes]: string[];
  }> = {};
  partialEntriesForEach(deleted, (resourceName: ResourceName, deletedForResource): void => {
    if (!resourceNames.includes(resourceName)) {
      // eslint-disable-next-line no-console
      console.warn(`deletion on unknown resource ${resourceName}`);
      return;
    }
    const currentData = currentOfflineResourcesData[resourceName];
    const resourceDeletedOnServer: string[] = [];
    if (deletedForResource) {
      // For each entry, check for presence before adding to
      // "no longer visible" set.
      deletedForResource.forEach((url): void => {
        if (currentData[url]) {
          resourceDeletedOnServer.push(url);
        }
      });
    }
    console.assert(!deletedOnServer[resourceName]);
    if (resourceDeletedOnServer.length) {
      deletedOnServer[resourceName] = resourceDeletedOnServer;
    }
  });
  return deletedOnServer;
}

function computeBaseChanges(
  currentOfflineResourcesData: ResourceInstanceRecords,
  data: {
    readonly changed: Partial<{
      readonly [resourceName in ResourceName]: readonly ResourceInstance[];
    }>;
    readonly deleted: Partial<{
      readonly [resourceName in ResourceName]: readonly string[];
    }>;
  },
): {
  deletedOnServer: Partial<ResourceURLArrays>;
  unchangedOnServer: Partial<ResourceURLArrays>;
  updatedOnServer: Partial<ResourceInstanceRecords>;
} {
  const deletedOnServer = computeDeletedOnServer(currentOfflineResourcesData, data.deleted);
  const {unchangedOnServer, updatedOnServer} = computeUpdatedUnchangedOnServer(
    currentOfflineResourcesData,
    data.changed,
  );
  return {deletedOnServer, unchangedOnServer, updatedOnServer};
}

export function handlePerformChangesFetchFulfilled(
  state: Draft<ResourcesState>,
  action: ReturnType<typeof performChangesFetch.fulfilled>,
): void {
  const responseContent = action.payload;
  const {results, timestamp} = responseContent;

  console.assert(state.currentlyFetchingChanges);
  state.currentlyFetchingChanges = false;

  const updatedState = current(state) as ResourcesState;
  const currentOfflineData = getOfflineData(updatedState);
  // deletedOnServer is filtered wrt. actuallly existing in currentOfflineData
  // updatedOnServer is filtered with changeIsNewer()-check
  const {deletedOnServer, unchangedOnServer, updatedOnServer} = computeBaseChanges(
    currentOfflineData,
    results,
  );

  updateFromReceivedData(
    state,
    currentOfflineData,
    unchangedOnServer,
    updatedOnServer,
    deletedOnServer,
  );

  if (state.lastFetchChangesTimestamp === action.meta.arg.lastFetchChangesTimestamp) {
    state.lastFetchChangesTimestamp = timestamp;
  }

  partialValuesForEach(state.persistedQueries, ({queryState}) => {
    if (
      !queryState.takenIntoAccountForChangesComputedAtTimestamp ||
      queryState.takenIntoAccountForChangesComputedAtTimestamp < timestamp
    ) {
      queryState.takenIntoAccountForChangesComputedAtTimestamp = timestamp;
    }
  });

  partialValuesForEach(state.temporaryQueries, ({queryState}) => {
    if (
      !queryState.takenIntoAccountForChangesComputedAtTimestamp ||
      queryState.takenIntoAccountForChangesComputedAtTimestamp < timestamp
    ) {
      queryState.takenIntoAccountForChangesComputedAtTimestamp = timestamp;
    }
  });
}

export function handlePerformChangesFetchRejected(
  state: Draft<ResourcesState>,
  action: ReturnType<typeof performChangesFetch.rejected>,
): void {
  console.assert(state.currentlyFetchingChanges);
  state.currentlyFetchingChanges = false;
  if (action.payload) {
    const {error, timestamp} = action.payload;
    state.lastFetchChangesError = error;
    state.lastFetchChangesErrorTimestamp = timestamp;
  } else {
    state.lastFetchChangesError = buildErrorObject(action.error);
  }
}
