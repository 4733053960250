import {
  MachineUrl,
  Order,
  PriceGroupUrl,
  Task,
  TaskUrl,
  Timer,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {objectMerge, objectSet} from "@co-common-libs/utils";
import {filterMinutesDuration} from "./duration";
import {mergeIntervals} from "./merge-intervals";
import {splitOnMidnights} from "./split-on-midnights";
import {SimpleInterval, TaskInterval, TimerInterval} from "./types";
import {
  NORMALISED_TIMESTAMP_STRING_LENGTH,
  getBreakTimer,
  getGenericPrimaryTimer,
  normalisedTimestamp,
} from "./utils";

export function minuteTruncatedTimestamp(timestamp: string): string {
  const date = new Date(timestamp);
  date.setUTCSeconds(0, 0);
  return date.toISOString();
}

export const normalisedTimerIntervals = (
  intervals: readonly TimerInterval[],
): readonly TimerInterval[] => {
  if (intervals.length === 0) {
    return intervals;
  }
  const result: TimerInterval[] = [];
  for (let i = 0; i < intervals.length; i += 1) {
    const interval = intervals[i];
    let {fromTimestamp, toTimestamp} = interval;
    if (
      fromTimestamp.length === NORMALISED_TIMESTAMP_STRING_LENGTH &&
      toTimestamp.length === NORMALISED_TIMESTAMP_STRING_LENGTH
    ) {
      result.push(interval);
    } else {
      fromTimestamp = normalisedTimestamp(fromTimestamp);
      console.assert(fromTimestamp.length === NORMALISED_TIMESTAMP_STRING_LENGTH);
      toTimestamp = normalisedTimestamp(toTimestamp);
      console.assert(toTimestamp.length === NORMALISED_TIMESTAMP_STRING_LENGTH);
      result.push(objectMerge(interval, {fromTimestamp, toTimestamp}));
    }
  }
  return result;
};

export const simpleTaskIntervals = (task: Task): readonly TimerInterval[] =>
  mergeIntervals(
    normalisedTimerIntervals(task.managerTimeCorrectionSet || []),
    normalisedTimerIntervals(task.machineOperatorTimeCorrectionSet || []),
    normalisedTimerIntervals(task.computedTimeSet || []),
  );

function uniqConcat<T>(arrayA: readonly T[], arrayB: readonly T[]): readonly T[] {
  if (arrayA.length === 0) {
    return arrayB;
  }
  if (arrayB.length === 0) {
    return arrayA;
  }
  const result: T[] = arrayA.slice();
  for (let i = 0; i < arrayB.length; i += 1) {
    const value = arrayB[i];
    let present = false;
    for (let j = 0; j < result.length; j += 1) {
      if (result[j] === value) {
        present = true;
        break;
      }
    }
    if (!present) {
      result.push(value);
    }
  }
  return result;
}

export function taskIntervals(
  task: Task,
  genericPrimaryTimerURL: string,
  breakTimerURL: string,
  timerMap: ReadonlyMap<string, Timer>,
  orderMap: ReadonlyMap<string, Order>,
): TaskInterval[] {
  const baseIntervals = simpleTaskIntervals(task);
  const filteredIntervals = filterMinutesDuration(baseIntervals);
  const taskURL: TaskUrl = task.url;
  const taskWorkTypeURL: WorkTypeUrl | undefined = task.workType || undefined;
  const taskPriceGroupURL: PriceGroupUrl | undefined = task.priceGroup || undefined;
  const department: string | undefined = task.department || undefined;
  const machineURLs: MachineUrl[] = [];
  const machineUsePriceGroupURLs: PriceGroupUrl[] = [];
  if (task.machineuseSet && task.machineuseSet.length) {
    task.machineuseSet.forEach((machineUse) => {
      const machineURL = machineUse.machine;
      if (machineURL && !machineURLs.includes(machineURL)) {
        machineURLs.push(machineURL);
      }
      const priceGroupURL = machineUse.priceGroup;
      if (priceGroupURL && !machineUsePriceGroupURLs.includes(priceGroupURL)) {
        machineUsePriceGroupURLs.push(priceGroupURL);
      }
    });
  }
  const order = task.order ? orderMap.get(task.order) : undefined;
  return filteredIntervals.map((interval) => {
    let workTypeURL: WorkTypeUrl | undefined;
    let priceGroupURL: PriceGroupUrl | undefined;
    const timerURL = interval.timer;
    if (timerURL === genericPrimaryTimerURL) {
      workTypeURL = taskWorkTypeURL;
      priceGroupURL = taskPriceGroupURL;
    } else if (timerURL) {
      const timer = timerMap.get(timerURL);
      if (timer) {
        workTypeURL = timer.workType || undefined;
        priceGroupURL = timer.priceGroup || undefined;
      }
    }
    const priceGroupURLs =
      priceGroupURL && machineUsePriceGroupURLs.length
        ? uniqConcat(machineUsePriceGroupURLs, [priceGroupURL])
        : priceGroupURL
          ? [priceGroupURL]
          : machineUsePriceGroupURLs;
    const result: TaskInterval = {
      fromTimestamp: interval.fromTimestamp,
      isBreak: timerURL === breakTimerURL,
      taskData: [
        {
          customerTask: !!task.order,
          department: department || null,
          effectiveTime: timerURL === genericPrimaryTimerURL,
          machineURLs,
          orderReferenceNumber: order ? order.referenceNumber : "",
          priceGroupURLs,
          projectURL: task.project,
          taskPriceGroupURL: taskPriceGroupURL || null,
          taskReferenceNumber: task.referenceNumber || "",
          taskURL,
          taskWorkTypeURL: taskWorkTypeURL || null,
          timerURL: interval.timer,
          workTypeURL: workTypeURL || null,
        },
      ],
      toTimestamp: interval.toTimestamp,
    };
    return result;
  });
}

export function compareFromTimestamp(a: SimpleInterval, b: SimpleInterval): -1 | 0 | 1 {
  const aFromTimestamp = a.fromTimestamp;
  const bFromTimestamp = b.fromTimestamp;
  if (aFromTimestamp < bFromTimestamp) {
    return -1;
  } else if (bFromTimestamp < aFromTimestamp) {
    return 1;
  } else {
    return 0;
  }
}

function mergeCompletelyOverlapping(a: TaskInterval, b: TaskInterval): TaskInterval {
  console.assert(a.fromTimestamp === b.fromTimestamp);
  console.assert(a.toTimestamp === b.toTimestamp);
  const isBreak = a.isBreak || b.isBreak;
  const result: TaskInterval = {
    fromTimestamp: a.fromTimestamp,
    isBreak,
    taskData: a.taskData.concat(b.taskData),
    toTimestamp: a.toTimestamp,
  };
  return result;
}

export function mergeOverlappingPart(inputA: TaskInterval, inputB: TaskInterval): TaskInterval[] {
  let a = inputA;
  let b = inputB;
  let beforeOverlap: TaskInterval | undefined;
  let afterOverlap: TaskInterval | undefined;
  console.assert(a.fromTimestamp <= b.fromTimestamp);
  if (a.fromTimestamp < b.fromTimestamp) {
    const splitTimestamp = b.fromTimestamp;
    beforeOverlap = objectSet(a, "toTimestamp", splitTimestamp);
    a = objectSet(a, "fromTimestamp", splitTimestamp);
  }
  if (a.toTimestamp > b.toTimestamp) {
    const splitTimestamp = b.toTimestamp;
    afterOverlap = objectSet(a, "fromTimestamp", splitTimestamp);
    a = objectSet(a, "toTimestamp", splitTimestamp);
  } else if (b.toTimestamp > a.toTimestamp) {
    const splitTimestamp = a.toTimestamp;
    afterOverlap = objectSet(b, "fromTimestamp", splitTimestamp);
    b = objectSet(b, "toTimestamp", splitTimestamp);
  }
  console.assert(a.fromTimestamp === b.fromTimestamp);
  console.assert(a.toTimestamp === b.toTimestamp);
  const result: TaskInterval[] = [];
  if (beforeOverlap) {
    result.push(beforeOverlap);
  }
  result.push(mergeCompletelyOverlapping(a, b));
  if (afterOverlap) {
    result.push(afterOverlap);
  }
  return result;
}

/** Combine attributes on overlap.  Any break "wins"/overrides;
 * "break" is a property of employee, not of task... */
export function combineOverlaps(intervals: TaskInterval[]): void {
  // Input needs to be sorted.
  // There *may* be overlaps.

  // If there are any overlaps, then (due to sorting) intervals with overlaps
  // will be next to each other --- though some might overlap with several of
  // the following, that may again overlap with those after that and so on...

  // If we have intervals [A, B, C], we might have
  // * A overlapping with B and A overlapping with C
  // * A overlapping with B and B overlapping with C
  // * A overlapping with B and A overlapping with C and B overlapping with C
  // * ... but *never* A overlapping with C without A also overlapping with B

  // Iterate to one before end, as we compare with the next position.
  // We modify the array when resolving conflicts; length may change...
  // The absolute minimum amount of work to do is to iterato once, checking;
  // if there are no overlaps, then no further work is done.
  for (let i = 0; i < intervals.length - 1; i += 1) {
    if (intervals[i].toTimestamp > intervals[i + 1].fromTimestamp) {
      // Found conflict.
      // Depending on whether this is a complete or partial overlap, the
      // result of the merge will be one to three intervals.
      const resolution = mergeOverlappingPart(intervals[i], intervals[i + 1]);
      const REPLACED_ELEMENT_COUNT = 2;
      const subsequentInterval: TaskInterval | undefined = intervals[i + REPLACED_ELEMENT_COUNT];
      intervals.splice(i, REPLACED_ELEMENT_COUNT, ...resolution);
      // If one or both of the conflicting elements also overlap with
      // subsequent elements, the result after resolving that conflict might
      // not be sorted.
      if (
        subsequentInterval &&
        subsequentInterval.fromTimestamp < resolution[resolution.length - 1].toTimestamp
      ) {
        // There is a conflict between some or all of the new elements and the next;
        // to be safe, sort and repeat check from index of first replacement.
        intervals.sort(compareFromTimestamp);
        i -= 1;
      }
    }
  }
}

/** Extract intervals and convert to `TaskInterval[]` for each task. */
export function normalisedTaskListIntervals(
  taskList: readonly Task[],
  timerMap: ReadonlyMap<string, Timer>,
  orderMap: ReadonlyMap<string, Order>,
): TaskInterval[] {
  const genericPrimaryTimer = getGenericPrimaryTimer(timerMap);
  const genericPrimaryTimerURL: string = genericPrimaryTimer.url;
  const breakTimer = getBreakTimer(timerMap);
  const breakTimerURL: string = breakTimer.url;
  const results: TaskInterval[][] = [];
  taskList.forEach((task) => {
    const intervals = taskIntervals(
      task,
      genericPrimaryTimerURL,
      breakTimerURL,
      timerMap,
      orderMap,
    );
    results.push(intervals);
  });
  const concatenated: TaskInterval[] = Array.prototype.concat.apply([], results);
  concatenated.sort(compareFromTimestamp);
  combineOverlaps(concatenated);
  const withMidnightSplits = splitOnMidnights(concatenated);
  return withMidnightSplits;
}
