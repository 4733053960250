import {
  Command,
  ResourceTypeUnion,
  SprayLocation,
  Task,
  YieldPickupLocation,
  instanceURL,
} from "@co-common-libs/resources";
import _ from "lodash";
import {v4 as uuid} from "uuid";
import {ProvisionaryCommand} from "../../resources/actions";
import {
  getCustomerSettings,
  getMachineLookup,
  getOrderLookup,
  getSprayLocationArray,
  getSprayLogArray,
  getWorkTypeLookup,
  getYieldLogArray,
  getYieldPickupLocationArray,
} from "../../resources/selectors";
import {ResourcesAuthenticationMiddlewareAPI} from "../types";
import {getBaseURL} from "./get-base-url";

// translation of old logic:
// * adds log locations; does not remove them
// * associates entry with order customer, not field/location customer
// ... doesn't seem entirely sensible, but this is legacy and we don't want
// to change the behavior for  existing customers
export function addLegacyLogLocationsFromFields(
  newTask: Task | null,
  oldTask: Task | undefined,
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  command: ProvisionaryCommand,
): {after?: Command[]; before?: Command[]} | null {
  if (!newTask) {
    return null;
  }

  const orderURL = newTask.order;
  if (
    newTask.recordedInC5 ||
    newTask.archivable ||
    !orderURL ||
    !newTask.fielduseSet ||
    !newTask.fielduseSet.length ||
    _.isEqual(newTask.fielduseSet, oldTask?.fielduseSet)
  ) {
    return null;
  }

  const state = middlewareApi.getState();
  const customerSettings = getCustomerSettings(state);

  if (!customerSettings.autoAddYieldLogFor.length && !customerSettings.autoAddSprayLogFor.length) {
    return null;
  }

  const identifiers: string[] = [];

  if (newTask.workType) {
    const workTypeLookup = getWorkTypeLookup(state);
    const workType = workTypeLookup(newTask.workType);
    if (workType?.identifier) {
      identifiers.push(workType.identifier);
    }
  }

  // logs may be auto-added based on machines, but only when work types disabled...
  if (
    customerSettings.noExternalTaskWorkType &&
    newTask.machineuseSet &&
    newTask.machineuseSet.length
  ) {
    const currentMachineURLs = newTask.machineuseSet.map((machineUse) => machineUse.machine);
    const oldMachineURLs = oldTask?.machineuseSet?.map((machineUse) => machineUse.machine);
    const addedMachineURLs = oldMachineURLs
      ? _.difference(currentMachineURLs, oldMachineURLs)
      : currentMachineURLs;
    if (addedMachineURLs.length) {
      const machineLookup = getMachineLookup(state);
      addedMachineURLs.forEach((machineURL) => {
        const machine = machineLookup(machineURL);
        if (machine?.c5_machine) {
          identifiers.push(machine.c5_machine);
        }
      });
    }
  }

  if (!identifiers.length) {
    return null;
  }

  const taskURL = command.url;
  const baseURL = getBaseURL(taskURL);

  const orderLookup = getOrderLookup(state);

  const newLogLocations: ResourceTypeUnion[] = [];

  if (
    customerSettings.autoAddYieldLogFor.length &&
    _.intersection(customerSettings.autoAddYieldLogFor, identifiers).length
  ) {
    const yieldLogArray = getYieldLogArray(state);
    const yieldLog = yieldLogArray.find((y) => y.task === taskURL);
    if (yieldLog) {
      // yieldLog exists -- if not, it will probably be autocreated *with fields* in the other logic
      const yieldLogURL = yieldLog.url;
      const yieldPickupLocationArray = getYieldPickupLocationArray(state);
      const yieldLogPickupLocations = yieldPickupLocationArray.filter(
        (p) => p.yieldlog === yieldLogURL,
      );
      let numberOfEntries = yieldLogPickupLocations.length;
      newTask.fielduseSet.forEach((fieldUse) => {
        const fieldURL = fieldUse.relatedField;
        if (!yieldLogPickupLocations.some((y) => y.relatedLocation === fieldURL)) {
          const id = uuid();
          const url = instanceURL(baseURL, "yieldPickupLocation", id);
          const newYieldPickupLocation: YieldPickupLocation = {
            customer: orderLookup(orderURL)?.customer || null,
            id,
            note: "",
            order: numberOfEntries,
            relatedLocation: fieldURL,
            url,
            yieldlog: yieldLogURL,
          };
          numberOfEntries += 1;
          newLogLocations.push(newYieldPickupLocation);
        }
      });
    }
  }

  if (
    customerSettings.autoAddSprayLogFor.length &&
    _.intersection(customerSettings.autoAddSprayLogFor, identifiers).length
  ) {
    const sprayLogArray = getSprayLogArray(state);
    const sprayLog = sprayLogArray.find((s) => s.task === taskURL);
    if (sprayLog) {
      // sprayLog exists -- if not, it will probably be autocreated *with fields* in the other logic
      const sprayLogURL = sprayLog.url;
      const sprayLocationArray = getSprayLocationArray(state);
      const sprayLogLocations = sprayLocationArray.filter((s) => s.spraylog === sprayLogURL);
      let numberOfEntries = sprayLogLocations.length;
      newTask.fielduseSet.forEach((fieldUse) => {
        const fieldURL = fieldUse.relatedField;
        if (!sprayLogLocations.some((s) => s.relatedLocation === fieldURL)) {
          const id = uuid();
          const url = instanceURL(baseURL, "sprayLocation", id);
          const digits = 2;
          const newYieldPickupLocation: SprayLocation = {
            area: fieldUse.fieldAreaHa != null ? _.round(fieldUse.fieldAreaHa, digits) : null,
            crop: fieldUse.fieldCrop,
            customer: orderLookup(orderURL)?.customer || null,
            id,
            note: "",
            order: numberOfEntries,
            relatedLocation: fieldURL,
            spraylog: sprayLogURL,
            url,
          };
          numberOfEntries += 1;
          newLogLocations.push(newYieldPickupLocation);
        }
      });
    }
  }

  if (!newLogLocations.length) {
    return null;
  }

  return {
    after: newLogLocations.map((logLocation) => ({
      action: "CREATE",
      instance: logLocation,
      url: logLocation.url,
    })),
  };
}
