import {
  CustomerUrl,
  PriceGroup,
  PriceGroupUrl,
  ReportingSpecificationUrl,
  Task,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {
  computeLogLegalPriceGroups,
  customerFilteredPriceGroups,
  getExpectedLogSpecification,
  logChangeLegal,
} from "@co-common-libs/resources-utils";
import {notUndefined} from "@co-common-libs/utils";
import {
  EntryData,
  GenericSingleSelectionSearchDialog,
  computeBaseChoicesForPriceGroups,
} from "@co-frontend-libs/components";
import {
  getMachineLookup,
  getPriceGroupLookup,
  getReportingSpecificationLookup,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {useConditionalMemo} from "@co-frontend-libs/utils";
import React, {useMemo} from "react";
import {IntlShape, useIntl} from "react-intl";
import {useSelector} from "react-redux";

function computeBaseChoicesWithSameLog(
  priceGroupArray: readonly PriceGroup[],
  reportingSpecification: ReportingSpecificationUrl | undefined,
  reportingSpecificationRequired: boolean,
  intl: IntlShape,
): readonly EntryData<PriceGroupUrl>[] {
  const baseResult = computeBaseChoicesForPriceGroups(priceGroupArray);
  if (!reportingSpecification) {
    return baseResult;
  }

  const legalPriceGroups = computeLogLegalPriceGroups(
    priceGroupArray,
    reportingSpecification,
    reportingSpecificationRequired,
  );

  const result = baseResult.map((entry) => {
    if (legalPriceGroups.has(entry.identifier)) {
      return entry;
    } else {
      return {
        ...entry,
        disabled: true,
        secondaryText: intl.formatMessage(
          {
            defaultMessage: "{identifier}; kan ikke anvendes med aktuel log på opgaven",
          },
          {identifier: entry.secondaryText},
        ),
      };
    }
  });
  return result;
}

interface ConnectedLogLegalPriceGroupDialogProps {
  customerUrl: CustomerUrl | null;
  onCancel(): void;
  onOk(url: PriceGroupUrl): void;
  open: boolean;
  task: Pick<
    Task,
    | "fielduseSet"
    | "machineuseSet"
    | "reportingLocations"
    | "reportingLog"
    | "reportingSpecification"
  >;
  workTypeURL: WorkTypeUrl | undefined;
}

export const ConnectedLogLegalPriceGroupDialog: React.FunctionComponent<ConnectedLogLegalPriceGroupDialogProps> =
  React.memo(function ConnectedLogLegalPriceGroupDialog(
    props: ConnectedLogLegalPriceGroupDialogProps,
  ): JSX.Element {
    const {customerUrl, onCancel, onOk, open, task, workTypeURL} = props;

    const workTypeLookup = useSelector(getWorkTypeLookup);
    const machineLookup = useSelector(getMachineLookup);
    const priceGroupLookup = useSelector(getPriceGroupLookup);
    const reportingSpecificationLookup = useSelector(getReportingSpecificationLookup);

    const {reportingSpecification, reportingSpecificationRequired} = useMemo(() => {
      if (task.reportingSpecification && !logChangeLegal(task)) {
        const logSpecificationFromWorkTypeOrMachines = getExpectedLogSpecification(
          {
            machineuseSet: task.machineuseSet,
            priceGroup: null,
            workType: workTypeURL ?? null,
          },
          {
            machineLookup,
            priceGroupLookup,
            reportingSpecificationLookup,
            workTypeLookup,
          },
        );
        return {
          reportingSpecification: task.reportingSpecification,
          reportingSpecificationRequired:
            logSpecificationFromWorkTypeOrMachines?.url !== task.reportingSpecification,
        };
      } else {
        return {
          reportingSpecification: undefined,
          reportingSpecificationRequired: false,
        };
      }
    }, [
      machineLookup,
      priceGroupLookup,
      reportingSpecificationLookup,
      task,
      workTypeLookup,
      workTypeURL,
    ]);

    const filteredPriceGroupArray = useMemo(() => {
      const limitedURLs = workTypeURL ? workTypeLookup(workTypeURL)?.pricegroups : undefined;
      const base = limitedURLs ? limitedURLs.map(priceGroupLookup).filter(notUndefined) : [];
      const active = customerFilteredPriceGroups(base, customerUrl);
      return active;
    }, [customerUrl, priceGroupLookup, workTypeLookup, workTypeURL]);

    const intl = useIntl();
    const title = intl.formatMessage({defaultMessage: "Vælg variant"});
    const searchTitle = intl.formatMessage({defaultMessage: "Søg variant"});

    const data = useConditionalMemo(
      () =>
        computeBaseChoicesWithSameLog(
          filteredPriceGroupArray,
          reportingSpecification,
          reportingSpecificationRequired,
          intl,
        ),
      [filteredPriceGroupArray, intl, reportingSpecification, reportingSpecificationRequired],
      open,
      [],
    );

    return (
      <GenericSingleSelectionSearchDialog
        data={data}
        mobilePrimaryLines={1}
        mobileSearchPrimaryLines={1}
        mobileSearchSecondaryLines={1}
        mobileSecondaryLines={1}
        open={open}
        searchTitle={searchTitle}
        sorting="SECONDARY_IDENTIFIER"
        title={title}
        onCancel={onCancel}
        onOk={onOk as (identifier: string) => void}
      />
    );
  });
