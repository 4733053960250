import {ResourceInstance} from "@co-common-libs/resources";
import {DeleteDialog, DeleteDialogProps} from "@co-frontend-libs/components";
import {actions} from "@co-frontend-libs/redux";
import React, {useCallback} from "react";
import {useDispatch} from "react-redux";

interface DeleteResourceDialogProps<T extends ResourceInstance<string>> extends DeleteDialogProps {
  children: React.ReactNode;
  instance: T;
}
export const DeleteResourceDialog = React.memo(function ConnectedDeleteDialog<
  T extends ResourceInstance<string>,
>(props: DeleteResourceDialogProps<T>) {
  const {children, instance, onOk, ...other} = props;

  const dispatch = useDispatch();
  const handleResourceDelete = useCallback(() => {
    dispatch(actions.update(instance.url, [{member: "active", value: false}]));
    onOk(true);
  }, [dispatch, instance, onOk]);

  return (
    <DeleteDialog {...other} onOk={handleResourceDelete}>
      {children}
    </DeleteDialog>
  );
});
