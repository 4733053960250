import {Order, Task} from "@co-common-libs/resources";
import {getJournalArray} from "@co-frontend-libs/redux";
import {Card, CardContent} from "@material-ui/core";
import {Linkify} from "app-components";
import {getJournalsForTask} from "app-utils";
import React from "react";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";

export const AdministrationNotesCard = React.memo(function AdministrationNotesCard({
  order,
  task,
}: {
  order: Order;
  task: Task;
}): JSX.Element | null {
  const journalArray = useSelector(getJournalArray);
  const {notesFromManager} = task;
  const orderNotes = order.notes;
  const journalNotes = getJournalsForTask(task, order, journalArray);
  if (!notesFromManager && !orderNotes && !journalNotes.length) {
    return null;
  }
  return (
    <Card style={{margin: "1em"}}>
      <CardContent>
        <div style={{whiteSpace: "pre-line"}}>
          <FormattedMessage defaultMessage="Noter fra administration:" tagName="h4" />
          <Linkify>
            {orderNotes ? <div>{orderNotes}</div> : null}
            {notesFromManager ? <div>{notesFromManager}</div> : null}
            {journalNotes.map((journal) => (
              <div key={journal.url}>{journal.entry}</div>
            ))}
          </Linkify>
        </div>
      </CardContent>
    </Card>
  );
});
