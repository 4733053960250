import {Task} from "@co-common-libs/resources";

export function allLogLocationsInFieldUses(
  task: Pick<Task, "fielduseSet" | "reportingLocations">,
): boolean {
  const fields = new Set(task.fielduseSet.map(({relatedField}) => relatedField));
  return Object.values(task.reportingLocations).every(
    (entry) => entry.location && fields.has(entry.location),
  );
}
