import {Draft} from "@reduxjs/toolkit";
import {ResourcesState} from "../types";
import {partialEntriesForEach, partialValuesForEach} from "../utils";

export function recomputeTemporaryQueriesPendingRemoval(state: Draft<ResourcesState>): void {
  const survivingKeyStrings = new Set<string>();
  const addToSurvivinKeyStrings = (keys: readonly string[]): void => {
    keys.forEach(survivingKeyStrings.add.bind(survivingKeyStrings));
  };
  partialValuesForEach(state.temporaryQueriesForPathNames, (keyKeyStringRecord) =>
    partialValuesForEach(keyKeyStringRecord, addToSurvivinKeyStrings),
  );
  partialValuesForEach(state.temporaryQueriesForKeys, addToSurvivinKeyStrings);
  partialEntriesForEach(state.temporaryQueries, (keyString, queryQueryState) => {
    if (!survivingKeyStrings.has(keyString)) {
      queryQueryState.queryState.pendingRemoval = true;
    }
  });
}
