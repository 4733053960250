import {ProjectAccess, ProjectAccessChoices} from "@co-common-libs/resources";
import {economicProjectImportEnabled} from "@co-common-libs/utils";
import {getCustomerSettings} from "@co-frontend-libs/redux";
import {MenuItem, TextField} from "@material-ui/core";
import React, {useCallback} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {ProjectAccessText} from "./project-access-text";

export interface ProjectAccessSelectProps {
  access: ProjectAccess;
  disabled?: boolean | undefined;
  onChange?: ((value: ProjectAccess) => void) | undefined;
}

export function ProjectAccessSelect({
  access,
  disabled,
  onChange,
}: ProjectAccessSelectProps): JSX.Element {
  const intl = useIntl();
  const customerSettings = useSelector(getCustomerSettings);
  const econProjectSync = economicProjectImportEnabled(customerSettings);

  const enabledChoices = ProjectAccessChoices.filter(
    (choice) =>
      !econProjectSync || choice === ProjectAccess.Open || choice === ProjectAccess.Hidden,
  );

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      if (onChange) {
        const {value} = event.target;
        onChange(value as ProjectAccess);
      }
    },
    [onChange],
  );

  const label = intl.formatMessage({defaultMessage: "Adgang"});

  return (
    <TextField
      fullWidth
      select
      disabled={disabled}
      id="select"
      label={label}
      margin="dense"
      value={access}
      variant="outlined"
      onChange={handleOnChange}
    >
      {ProjectAccessChoices.map((choice) => (
        <MenuItem key={choice} disabled={!enabledChoices.includes(choice)} value={choice}>
          <ProjectAccessText access={choice} />
        </MenuItem>
      ))}
    </TextField>
  );
}
