import {TaskUrl, TimerStart} from "@co-common-libs/resources";
import {timerStartNormalisedComparator} from "@co-common-libs/resources-utils";
import {createSelector} from "reselect";
import {getTimerStartArray} from "../resources/selectors";
import {AppState} from "../root-reducer";
import {getCurrentUserURL} from "./current-user";

function sortTimerStartArray(timerStartArray: readonly TimerStart[]): TimerStart[] {
  return timerStartArray.slice().sort(timerStartNormalisedComparator);
}

export const getSortedTimerStartArray: (state: AppState) => readonly TimerStart[] = createSelector(
  getTimerStartArray,
  sortTimerStartArray,
);

function filterOnEmployeeAndSort(
  currentUserURL: string | null,
  timerStartArray: readonly TimerStart[],
): readonly TimerStart[] {
  if (!currentUserURL) {
    return [];
  }
  return timerStartArray
    .filter((timerStart) => timerStart.employee === currentUserURL)
    .sort(timerStartNormalisedComparator);
}

export const getCurrentUserSortedTimerStartArray: (state: AppState) => readonly TimerStart[] =
  createSelector(getCurrentUserURL, getTimerStartArray, filterOnEmployeeAndSort);

function findActive(sortedTimerStartArray: readonly TimerStart[]): readonly TimerStart[] {
  const tasksLastTimerStarts = new Map<string, TimerStart>();
  sortedTimerStartArray.forEach((timerStart) => {
    // last seen per task "wins"
    tasksLastTimerStarts.set(timerStart.task, timerStart);
  });
  const activeTimerStarts: TimerStart[] = [];
  tasksLastTimerStarts.forEach((timerStart) => {
    if (timerStart.timer) {
      activeTimerStarts.push(timerStart);
    }
  });
  activeTimerStarts.sort(timerStartNormalisedComparator);
  return activeTimerStarts;
}

export const getCurrentUserActiveTimerStartArray: (state: AppState) => readonly TimerStart[] =
  createSelector(getCurrentUserSortedTimerStartArray, findActive);

export const getActiveTimerStartArray: (state: AppState) => readonly TimerStart[] = createSelector(
  getSortedTimerStartArray,
  findActive,
);

function activeTaskURLsSortedByFirstTimerStart(
  currentUserActiveTimerStartArray: readonly TimerStart[],
  currentUserSortedTimerStartArray: readonly TimerStart[],
): readonly TaskUrl[] {
  if (currentUserActiveTimerStartArray.length === 0) {
    return [];
  }
  if (currentUserActiveTimerStartArray.length === 1) {
    return [currentUserActiveTimerStartArray[0].task];
  }
  const taskURLs = new Set(currentUserActiveTimerStartArray.map((timerStart) => timerStart.task));
  // multiple active; then we order tasks by their *first* timerStart...
  const taskFirstTimerStarts = new Map<TaskUrl, TimerStart>();
  currentUserSortedTimerStartArray.forEach((timerStart) => {
    const taskURL = timerStart.task;
    if (!taskFirstTimerStarts.has(taskURL) && taskURLs.has(taskURL)) {
      taskFirstTimerStarts.set(taskURL, timerStart);
    }
  });
  const sortedFirstTimerStarts = Array.from(taskFirstTimerStarts.values()).sort(
    timerStartNormalisedComparator,
  );
  return sortedFirstTimerStarts.map((timerStart) => timerStart.task);
}

export const getCurrentUserActiveTaskURLArray: (state: AppState) => readonly TaskUrl[] =
  createSelector(
    getCurrentUserActiveTimerStartArray,
    getCurrentUserSortedTimerStartArray,
    activeTaskURLsSortedByFirstTimerStart,
  );

function takeFirstOrNull<T>(arr: readonly T[]): T | null {
  return arr.length ? arr[0] : null;
}

export const getCurrentUserActiveTimerStart: (state: AppState) => TimerStart | null =
  createSelector(getCurrentUserActiveTimerStartArray, takeFirstOrNull);
