import {ReportingSpecification} from "@co-common-libs/resources";

export function getAllTransferUnits(reportingSpecification: ReportingSpecification): Set<string> {
  const transferUnits = new Set<string>();
  for (const workplaceType of ["workplace", "pickup", "delivery"] as const) {
    const inputSpecifications =
      reportingSpecification?.workplaceData?.[workplaceType]?.logInputs || [];
    for (const inputSpecification of inputSpecifications) {
      const {transfer, transferToEntry, unit} = inputSpecification;
      if (unit && (transfer || transferToEntry)) {
        transferUnits.add(unit.toLowerCase());
      }
    }
  }
  return transferUnits;
}
