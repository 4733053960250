import {
  PricePercentFuelSurchargeSpecification,
  PricePercentFuelSurchargeSpecificationUrl,
} from "@co-common-libs/resources";
import {PricePercentFuelSurchargeSpecificationDialog} from "@co-frontend-libs/components";
import {AppState, getPricePercentFuelSurchargeSpecificationArray} from "@co-frontend-libs/redux";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";

interface PricePercentFuelSurchargeSpecificationDialogStateProps {
  pricePercentFuelSurchargeSpecificationArray: readonly PricePercentFuelSurchargeSpecification[];
}

interface PricePercentFuelSurchargeSpecificationDialogOwnProps {
  onCancel(): void;
  onNone?(): void;
  onOk(url: PricePercentFuelSurchargeSpecificationUrl): void;
  open: boolean;
}

export const ConnectedPricePercentFuelSurchargeSpecificationDialog: React.ComponentType<PricePercentFuelSurchargeSpecificationDialogOwnProps> =
  connect<
    PricePercentFuelSurchargeSpecificationDialogStateProps,
    object,
    PricePercentFuelSurchargeSpecificationDialogOwnProps,
    AppState
  >(
    createStructuredSelector<AppState, PricePercentFuelSurchargeSpecificationDialogStateProps>({
      pricePercentFuelSurchargeSpecificationArray: getPricePercentFuelSurchargeSpecificationArray,
    }),
    {},
  )(PricePercentFuelSurchargeSpecificationDialog);
