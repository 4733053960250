import {
  LocationUrl,
  PriceItemUsesDict,
  ProductUrl,
  ProductUseWithOrder,
  ProductUsesDict,
  ReportingInputSpecification,
  ReportingWorkplaceTypeDataSpecification,
  Task,
  TimerUrl,
} from "@co-common-libs/resources";
import {
  addToProductUsesHelper,
  getProductGroupsWithAutoSupplementingProducts,
  getValue,
  trackedStorageProductCounts,
} from "@co-common-libs/resources-utils";
import {notUndefined} from "@co-common-libs/utils";
import {ProductDialog} from "@co-frontend-libs/components";
import {
  getCurrentRole,
  getCustomerSettings,
  getLocationLookup,
  getLocationStorageAdjustmentArray,
  getLocationStorageChangeArray,
  getLocationStorageStatusArray,
  getLocationTypeLookup,
  getMachineLookup,
  getPriceGroupLookup,
  getProductArray,
  getProductGroupArray,
  getProductGroupLookup,
  getProductLookup,
  getTimerLookup,
  getUnitLookup,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {Button} from "@material-ui/core";
import {ProductGroupTreeDialog, ProductTable} from "app-components";
import {getPotentialTargetTransferProductUrls, getRelevantPriceGroupSet} from "app-utils";
import _ from "lodash";
import React, {useCallback, useMemo, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {ProductUsesAction} from "./utils";

interface ProductBlockProps {
  dispatchProductsUses: React.Dispatch<ProductUsesAction>;
  inputSpecificationsMap: Map<string, ReportingInputSpecification>;
  locationUrl: LocationUrl | undefined;
  priceItemUses: PriceItemUsesDict;
  productUses: ProductUsesDict;
  task: Task;
  timerMinutesMap: ReadonlyMap<TimerUrl, number>;
  valueMaps: readonly {
    readonly [identifier: string]: unknown;
  }[];
  workplaceTypeDataSpecification: ReportingWorkplaceTypeDataSpecification;
}

export function ProductBlock(props: ProductBlockProps): JSX.Element {
  const {
    dispatchProductsUses,
    inputSpecificationsMap,
    locationUrl,
    priceItemUses,
    productUses,
    task,
    timerMinutesMap,
    valueMaps,
    workplaceTypeDataSpecification,
  } = props;

  console.assert(
    workplaceTypeDataSpecification.logProducts,
    "Using ProductBlock without logProducts",
  );

  const locationLookup = useSelector(getLocationLookup);
  const machineLookup = useSelector(getMachineLookup);
  const priceGroupLookup = useSelector(getPriceGroupLookup);
  const productGroupLookup = useSelector(getProductGroupLookup);
  const productLookup = useSelector(getProductLookup);
  const timerLookup = useSelector(getTimerLookup);
  const unitLookup = useSelector(getUnitLookup);
  const workTypeLookup = useSelector(getWorkTypeLookup);

  const locationStorageAdjustmentArray = useSelector(getLocationStorageAdjustmentArray);
  const locationStorageChangeArray = useSelector(getLocationStorageChangeArray);
  const locationStorageStatusArray = useSelector(getLocationStorageStatusArray);
  const locationTypeLookup = useSelector(getLocationTypeLookup);
  const productArray = useSelector(getProductArray);
  const productGroupArray = useSelector(getProductGroupArray);

  const customerSettings = useSelector(getCustomerSettings);
  const currentRole = useSelector(getCurrentRole);

  const userIsManager = currentRole && currentRole.manager;
  const userIsOtherMachineOperator = !userIsManager && currentRole?.user !== task.machineOperator;

  const {
    calculators,
    logInputs: inputSpecifications,
    productConversion,
    requireAtLeastOneProduct,
  } = workplaceTypeDataSpecification;

  const sortedProductUses = useMemo(
    (): readonly {
      readonly identifier: string;
      readonly productUse: ProductUseWithOrder;
    }[] =>
      _.sortBy(
        Object.entries(productUses).map(([identifier, productUse]) => ({
          identifier,
          productUse,
        })),
        ({productUse}) => productUse.order,
      ),
    [productUses],
  );

  const productUseList = useMemo(
    (): readonly ProductUseWithOrder[] => sortedProductUses.map(({productUse}) => productUse),
    [sortedProductUses],
  );

  const productConversionDenominatorUnit = productConversion?.unit;
  const productDenominatorValue = useMemo(
    () =>
      productConversion
        ? getValue(customerSettings, valueMaps, inputSpecificationsMap, productConversion.field)
        : undefined,
    [customerSettings, inputSpecificationsMap, productConversion, valueMaps],
  );

  const extraConversionRelatedValues = useMemo(
    () =>
      productConversionDenominatorUnit && typeof productDenominatorValue === "number"
        ? {[productConversionDenominatorUnit]: productDenominatorValue}
        : undefined,
    [productConversionDenominatorUnit, productDenominatorValue],
  );

  const handleProductUseCountChange = useCallback(
    (identifier: string, count: number | null): void => {
      dispatchProductsUses({count, identifier, type: "set-count"});
    },
    [dispatchProductsUses],
  );

  const handleProductUseNotesChange = useCallback(
    (identifier: string, notes: string): void => {
      dispatchProductsUses({identifier, notes, type: "set-notes"});
    },
    [dispatchProductsUses],
  );

  const handleProductUseOursChange = useCallback(
    (identifier: string, ours: boolean): void => {
      dispatchProductsUses({identifier, ours, type: "set-ours"});
    },
    [dispatchProductsUses],
  );

  const handleDeleteClick = useCallback(
    (identifier: string): void => {
      dispatchProductsUses({identifier, type: "delete"});
    },
    [dispatchProductsUses],
  );

  const [productDialogOpen, setProductDialogOpen] = useState(false);
  const setProductDialogOpenTrue = useCallWithTrue(setProductDialogOpen);
  const setProductDialogOpenFalse = useCallWithFalse(setProductDialogOpen);

  const handleProductDialogOk = useCallback(
    (urlOrUrls: ProductUrl | ReadonlySet<ProductUrl>): void => {
      setProductDialogOpen(false);

      let urls = typeof urlOrUrls === "string" ? [urlOrUrls] : Array.from(urlOrUrls);

      // Don't support autoSupplementingProducts -- that would require us to
      // support duplicate products -- so don't allow products that *should*
      // auto-add some others (and would give wrong invoices without)
      // to be added...
      if (customerSettings.autoSupplementingProducts) {
        const autoProductSourceIdentifiers = new Set<string>();
        Object.keys(customerSettings.autoSupplementingProducts).forEach((identifier) => {
          autoProductSourceIdentifiers.add(identifier);
          // + with e-conomic product group prefix
          autoProductSourceIdentifiers.add(
            `https://restapi.e-conomic.com/product-groups/${identifier}`,
          );
        });
        const blockProductGroupUrls = new Set<string>();
        productGroupArray.forEach((productGroup) => {
          if (autoProductSourceIdentifiers.has(productGroup.remoteUrl)) {
            blockProductGroupUrls.add(productGroup.url);
          }
        });
        if (blockProductGroupUrls.size) {
          urls = urls.filter((productUrl) => {
            const product = productLookup(productUrl);
            return product?.group && !blockProductGroupUrls.has(product.group);
          });
        }
      }

      // Don't support duplicate products -- so filter out the current.
      const currentProducts = new Set(
        Object.values(productUses).map((productUse) => productUse.product),
      );
      if (currentProducts.size) {
        urls = urls.filter((url) => !currentProducts.has(url));
      }

      // We don't suppport allowDuplicateProductUses or
      // autoSupplementingProducts here.
      const value = addToProductUsesHelper(
        productUses,
        new Set(urls),
        productArray,
        productLookup,
        productGroupLookup,
        {
          allowDuplicateProductUses: false,
          autoCopyMaterialNoteToSupplementingProductNote: false,
          autoSupplementingProducts: null,
        },
        currentRole?.user || null,
      );
      dispatchProductsUses({type: "replace", value});
    },
    [
      currentRole?.user,
      customerSettings.autoSupplementingProducts,
      dispatchProductsUses,
      productArray,
      productGroupArray,
      productGroupLookup,
      productLookup,
      productUses,
    ],
  );

  let productDialog: JSX.Element;
  if (customerSettings.productImageSelection) {
    productDialog = (
      <ProductGroupTreeDialog
        open={productDialogOpen}
        onCancel={setProductDialogOpenFalse}
        onOk={handleProductDialogOk}
      />
    );
  } else {
    const location = locationUrl ? locationLookup(locationUrl) : undefined;
    let preferredProductUrls: ProductUrl[] | undefined;
    if (customerSettings.enableLocationStorage && location) {
      const productCounts = trackedStorageProductCounts(
        locationStorageStatusArray,
        locationStorageAdjustmentArray,
        locationStorageChangeArray,
        locationTypeLookup,
        location,
      );
      preferredProductUrls = Array.from(productCounts.keys());
    }
    const priceGroups = Array.from(
      getRelevantPriceGroupSet(task, {
        timerLookup,
        timerMinutesMap,
      }),
    )
      .map(priceGroupLookup)
      .filter(notUndefined);

    let filteredProductArray = productArray.filter((product) => product.active);
    // Don't support autoSupplementingProducts -- that would require us to
    // support duplicate products -- so don't allow products that *should*
    // auto-add some others (and would give wrong invoices without)
    // to be selected...
    if (customerSettings.autoSupplementingProducts) {
      const blockProductGroupUrls = getProductGroupsWithAutoSupplementingProducts(
        customerSettings.autoSupplementingProducts,
        productGroupArray,
      );
      if (blockProductGroupUrls.size) {
        filteredProductArray = filteredProductArray.filter(
          (product) => !product.group || !blockProductGroupUrls.has(product.group),
        );
      }
    }

    // Don't support duplicate products -- so filter out the current
    // from selection.
    const currentProducts = new Set(
      Object.values(productUses).map((productUse) => productUse.product),
    );
    if (currentProducts.size) {
      filteredProductArray = filteredProductArray.filter(
        (product) => !currentProducts.has(product.url),
      );
    }

    productDialog = (
      <ProductDialog
        autoSupplementingProducts={null}
        barcodeScannerFormats={customerSettings.barcodeScannerProductDialog}
        machines={task.machineuseSet
          .map((machineUse) => machineLookup(machineUse.machine))
          .filter(notUndefined)}
        materialUseAlternativeText={customerSettings.materialUseAlternativeText}
        open={productDialogOpen}
        preferredCategory="storage"
        preferredProductURLs={preferredProductUrls}
        priceGroups={priceGroups}
        productArray={filteredProductArray}
        productMultiSelect={customerSettings.productMultiSelect}
        showMaterialAutoLinesToEmployees={false}
        unitLookup={unitLookup}
        workType={task.workType ? workTypeLookup(task.workType) : undefined}
        onCancel={setProductDialogOpenFalse}
        onOk={handleProductDialogOk}
      />
    );
  }

  const allProductTransferTargets = useMemo(() => {
    const combinedProductTargets = new Set<string>();
    const productUrls = Object.values(productUses).map((productUse) => productUse.product);
    if (inputSpecifications) {
      inputSpecifications.forEach((inputSpecification) => {
        if (inputSpecification.transferToEntry && inputSpecification.unit) {
          const productTargets = getPotentialTargetTransferProductUrls(
            productUrls,
            inputSpecification.unit,
            productLookup,
            unitLookup,
          );
          if (productTargets.size) {
            productTargets.forEach((url) => combinedProductTargets.add(url));
          }
        }
      });
    }
    return combinedProductTargets;
  }, [inputSpecifications, productLookup, productUses, unitLookup]);

  // HACK: productsWithLogData actually means "cannot be removed"; don't allow
  // removal of those already/previously added to task productUses...
  const productsWithLogData = useMemo(
    () =>
      task.productUses
        ? new Set(Object.values(task.productUses).map(({product}) => product))
        : undefined,
    [task],
  );

  return (
    <>
      <ProductTable
        showEvenIfEmpty
        calculators={calculators}
        extraConversionRelatedValues={extraConversionRelatedValues}
        priceItemUses={priceItemUses}
        productsWithLogData={productsWithLogData}
        productUses={productUses}
        readonly={
          task.validatedAndRecorded ||
          (!userIsManager && (task.completed || userIsOtherMachineOperator))
        }
        readonlyProducts={allProductTransferTargets}
        showNotes={false}
        onCountChange={handleProductUseCountChange}
        onDeleteClick={handleDeleteClick}
        onNotesChange={handleProductUseNotesChange}
        onOursChange={handleProductUseOursChange}
      />
      {customerSettings.enableAddProducts ? (
        <>
          {requireAtLeastOneProduct && !productUseList.length ? (
            <div>
              <FormattedMessage
                defaultMessage="* Tilføj mindst et produkt"
                id="log-entry-dialog.text.at-least-one-product-is-required"
              />
            </div>
          ) : null}
          <Button
            color="secondary"
            disabled={
              task.validatedAndRecorded ||
              (!userIsManager && (task.completed || userIsOtherMachineOperator))
            }
            style={{marginTop: 5}}
            variant="contained"
            onClick={setProductDialogOpenTrue}
          >
            {customerSettings.materialUseAlternativeText ? (
              <FormattedMessage defaultMessage="Tilføj materiale" />
            ) : (
              <FormattedMessage defaultMessage="Tilføj materiel" />
            )}
          </Button>
          {productDialog}
        </>
      ) : null}
    </>
  );
}
