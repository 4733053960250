import {z} from "zod";

export const unknownReferenceData = z.object({
  column: z.string(),
  type: z.literal("unknown_reference"),
  values: z.array(z.string()),
});

export const inactiveExistsData = z.object({
  type: z.literal("inactive_exists"),
  values: z.array(z.string()),
});

export const economicSyncError = z.object({
  text: z.string(),
  type: z.literal("economic_sync"),
});

export const hiddenIdentifierData = z.object({
  type: z.literal("hidden_identifiers"),
  values: z.array(z.string()),
});

export const dataIssue = z.union([
  unknownReferenceData,
  inactiveExistsData,
  economicSyncError,
  hiddenIdentifierData,
]);

export type DataIssue = z.infer<typeof dataIssue>;

export const dataIssues = z.array(dataIssue);

export type DataIssues = z.infer<typeof dataIssues>;

export class DataError extends Error {
  issues: DataIssues;
  constructor(issues: DataIssue | DataIssues) {
    super();
    this.name = "DataError";
    this.issues = Array.isArray(issues) ? issues : [issues];
    this.message = `DataError: ${JSON.stringify(this.issues)}`;
  }
}
