import {Config} from "@co-common-libs/config";
import {
  Machine,
  MachineUrl,
  Patch,
  PatchUnion,
  ResourceTypeUnion,
  Unit,
  UnitUrl,
  WorkshopChecklistItem,
  WorkshopChecklistItemUrl,
  WorkshopChecklist as WorkshopChecklistResource,
  WorkshopChecklistUrl,
} from "@co-common-libs/resources";
import {ResponsiveDialog, TrimTextField} from "@co-frontend-libs/components";
import {ConnectedMultipleMachinesDialog} from "@co-frontend-libs/connected-components";
import {
  AppState,
  actions,
  getCustomerSettings,
  getMachineArray,
  getUnitArray,
  getWorkshopChecklistArray,
  getWorkshopChecklistItemArray,
  getWorkshopChecklistItemLookup,
  getWorkshopChecklistLookup,
} from "@co-frontend-libs/redux";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  DialogContent,
  Fab,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
} from "@material-ui/core";
import {green} from "@material-ui/core/colors";
import {SelectableListCard, WorkshopChecklistItemDialog} from "app-components";
import {PureComponent, checkedModelUpdaterFactoryFactory} from "app-utils";
import {bind} from "bind-decorator";
import bowser from "bowser";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import ContentCopyIcon from "mdi-react/ContentCopyIcon";
import DragHorizontalVariantIcon from "mdi-react/DragHorizontalVariantIcon";
import PencilIcon from "mdi-react/PencilIcon";
import PlusIcon from "mdi-react/PlusIcon";
import React from "react";
import {
  ConnectDragPreview,
  ConnectDragSource,
  ConnectDropTarget,
  DragSource,
  DragSourceMonitor,
  DropTarget,
  DropTargetMonitor,
} from "react-dnd";
// Allowed for existing code...
// eslint-disable-next-line deprecate/import
import {Cell, Grid} from "react-flexr";
import {FormattedMessage, IntlContext, defineMessages} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {v4 as uuid} from "uuid";

const messages = defineMessages({
  activate: {
    defaultMessage: "Aktivér tjekliste",
    id: "workshop-checklist-settings.label.activate",
  },
  active: {
    defaultMessage: "Aktiv",
    id: "workshop-checklist-setting.label.active",
  },
  close: {
    defaultMessage: "Luk",
    id: "workshop-checklist-settings.label.close",
  },
  draft: {
    defaultMessage: "Kladde",
    id: "workshop-checklist-setting.label.draft",
  },
  identifier: {
    defaultMessage: "Søgenavn",
    id: "workshop-checklist-settings.label.identifier",
  },
  machines: {
    defaultMessage: "Maskiner",
    id: "workshop-checklist-settings.label.machines",
  },
  name: {
    defaultMessage: "Navn",
    id: "workshop-checklist-setting.label.name",
  },
  newChecklist: {
    defaultMessage: "Ny værkstedstjekliste",
    id: "workshop-checklist-settings.header.new-checklist",
  },
  notActive: {
    defaultMessage: "Inaktiv",
    id: "workshop-checklist-setting.label.not-active",
  },
  required: {
    defaultMessage: "Skal udfyldes",
    id: "workshop-checklist-settings.label.required",
  },
  selectMachines: {
    defaultMessage: "Vælg maskiner",
    id: "workshop-checklist-settings.label.select-machines",
  },
  selectVehicles: {
    defaultMessage: "Vælg køretøjer",
    id: "workshop-checklist-settings.label.select-vehicles",
  },
  showMachines: {
    defaultMessage: "Se maskiner",
    id: "workshop-checklist-settings.label.show-machines",
  },
  showVehicles: {
    defaultMessage: "Se køretøjer",
    id: "workshop-checklist-settings.label.show-vehicles",
  },
  vehicles: {
    defaultMessage: "Køretøjer",
    id: "workshop-checklist-settings.label.vehicles",
  },
  workshopChecklists: {
    defaultMessage: "Værkstedstjeklister",
    id: "workshop-checklist-settings.header.workshopChecklist",
  },
});

const INITIAL = Math.pow(2, 28);

const INCREMENT = Math.pow(2, 14);

const THRESHOLD = Math.pow(2, -14);

const itemSource = {
  beginDrag(
    props: any,
    _monitor: DragSourceMonitor<{item: WorkshopChecklistItem}>,
    _component: any,
  ) {
    return {
      item: props.item,
    };
  },
  canDrag(props: any, _monitor: DragSourceMonitor) {
    return props.draft;
  },
};

const itemTarget = {
  canDrop(props: any, _monitor: DropTargetMonitor) {
    return !!props.item.checklist;
  },
  drop(props: any, monitor: DropTargetMonitor<{item: WorkshopChecklistItem}>, _component: any) {
    props.onDrop(monitor.getItem().item, props.item);
  },
};

interface InnerItemRowProps {
  canDrag: boolean;
  canDrop: boolean;
  connectDragPreview: ConnectDragPreview;
  connectDragSource: ConnectDragSource;
  connectDropTarget: ConnectDropTarget;
  draft: boolean;
  isDragging: boolean;
  isOver: boolean;
  item: WorkshopChecklistItem;
  onDrop?: (source: any, target: any) => void;
  onEditClick: (item: WorkshopChecklistItem) => void;
}

class InnerItemRow extends PureComponent<InnerItemRowProps> {
  @bind
  handleClick(): void {
    this.props.onEditClick(this.props.item);
  }

  render(): JSX.Element {
    const {
      canDrop,
      connectDragPreview,
      connectDragSource,
      connectDropTarget,
      draft,
      isDragging,
      isOver,
      item,
    } = this.props;

    const style: React.CSSProperties = {backgroundColor: "white"};
    if (isDragging) {
      style.opacity = 0.2;
    }
    if (isOver && canDrop) {
      style.backgroundColor = green[100];
    }
    if (draft) {
      return connectDragPreview(
        connectDropTarget(
          <div style={style}>
            <ListItem>
              <ListItemIcon>
                <IconButton onClick={this.handleClick}>
                  <PencilIcon />
                </IconButton>
              </ListItemIcon>
              <ListItemText primary={item.name} />
              <ListItemSecondaryAction>
                <IconButton edge="end">
                  {
                    connectDragSource(
                      <div style={{cursor: "pointer"}}>
                        <DragHorizontalVariantIcon />
                      </div>,
                    ) as JSX.Element
                  }
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </div>,
        ) as JSX.Element,
      ) as JSX.Element;
    } else {
      return (
        <div style={style}>
          <ListItem>
            <ListItemText primary={item.name} />
          </ListItem>
        </div>
      );
    }
  }
}

const ItemRowDropTarget = DropTarget("item", itemTarget, (dndConnect, monitor) => ({
  canDrop: monitor.canDrop(),
  connectDropTarget: dndConnect.dropTarget(),
  isOver: monitor.isOver(),
}))(InnerItemRow);

const ItemRow = DragSource("item", itemSource, (dndConnect, monitor) => ({
  connectDragPreview: dndConnect.dragPreview(),
  connectDragSource: dndConnect.dragSource(),
  isDragging: monitor.isDragging(),
}))(ItemRowDropTarget) as any;

interface WorkshopChecklistEditBlockProps {
  create: (instance: ResourceTypeUnion) => void;
  customerSettings: Config;
  machineArray: readonly Machine[];
  onCopy: (workshopChecklist: WorkshopChecklistResource) => void;
  unitArray: readonly Unit[];
  update: (url: string, patch: PatchUnion) => void;
  workshopChecklist: WorkshopChecklistResource;
  workshopChecklistItemLookup: (url: WorkshopChecklistItemUrl) => WorkshopChecklistItem | undefined;
  workshopChecklistItems: readonly WorkshopChecklistItem[];
}

interface WorkshopChecklistEditBlockState {
  itemDialogOpen: boolean;
  machineDialogOpen: boolean;
  showMachinesDialogOpen: boolean;
  workshopChecklistItemURL: WorkshopChecklistItemUrl | null;
}

class WorkshopChecklistEditBlock extends PureComponent<
  WorkshopChecklistEditBlockProps,
  WorkshopChecklistEditBlockState
> {
  state: WorkshopChecklistEditBlockState = {
    itemDialogOpen: false,
    machineDialogOpen: false,
    showMachinesDialogOpen: false,
    workshopChecklistItemURL: null,
  };

  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  makeCheckedModelUpdater = checkedModelUpdaterFactoryFactory<WorkshopChecklistResource>(
    this.props.update,
  );

  @bind
  handleMachineSelectButton(): void {
    this.setState({machineDialogOpen: true});
  }

  @bind
  handleMachineDialogOk(machines: ReadonlySet<MachineUrl>): void {
    this.setState({
      machineDialogOpen: false,
    });
    this.props.update(this.props.workshopChecklist.url, [
      {member: "machines", value: Array.from(machines)},
    ]);
  }

  @bind
  handleMachineDialogCancel(): void {
    this.setState({
      machineDialogOpen: false,
    });
  }

  @bind
  handleActivateButton(): void {
    this.props.update(this.props.workshopChecklist.url, [{member: "draft", value: false}]);
  }

  @bind
  handleAddItemClick(): void {
    this.setState({
      itemDialogOpen: true,
      workshopChecklistItemURL: null,
    });
  }

  @bind
  handleChecklistItemDialogCancel(): void {
    this.setState({
      itemDialogOpen: false,
      workshopChecklistItemURL: null,
    });
  }

  @bind
  handleChecklistItemSave(
    name: string,
    type: "boolean" | "decimal",
    unit: UnitUrl | null,
    workshopChecklistItem?: WorkshopChecklistItem,
  ): void {
    const {create, update, workshopChecklist, workshopChecklistItems} = this.props;

    const checklistURL = workshopChecklist.url;

    if (workshopChecklistItem) {
      const patch: Patch<WorkshopChecklistItem> = [
        {member: "name", value: name},
        {member: "type", value: type},
        {member: "unit", value: unit},
      ];
      update(workshopChecklistItem.url, patch);
    } else {
      const id = uuid();
      const url = instanceURL("workshopChecklistItem", id);
      let priority;

      if (!workshopChecklistItems.length) {
        priority = INITIAL;
      } else {
        const lastPriority = workshopChecklistItems[workshopChecklistItems.length - 1].priority;
        priority = lastPriority + INCREMENT;
      }

      const instance: WorkshopChecklistItem = {
        checklist: checklistURL,
        id,
        name,
        priority,
        type,
        unit,
        url,
      };

      create(instance);
    }
    this.setState({
      itemDialogOpen: false,
      workshopChecklistItemURL: null,
    });
  }

  @bind
  handleItemEdit(item: WorkshopChecklistItem): void {
    this.setState({
      itemDialogOpen: true,
      workshopChecklistItemURL: item.url,
    });
  }

  @bind
  handleShowMachinesDialogOpen(): void {
    this.setState({
      showMachinesDialogOpen: true,
    });
  }

  @bind
  handleShowMachinesDialogClose(): void {
    this.setState({
      showMachinesDialogOpen: false,
    });
  }

  @bind
  handleDrop(droppedItem: any, onItem: any): void {
    const {update, workshopChecklistItems} = this.props;
    const targetPriority = onItem.priority;
    const oldPriority = droppedItem.priority;
    const targetIndex = workshopChecklistItems.indexOf(onItem);
    console.assert(targetIndex > -1);
    let newPriority;
    if (oldPriority < targetPriority) {
      // Old position of moved object was before/above target;
      // move it down past it.
      const afterTarget = workshopChecklistItems[targetIndex + 1];
      if (afterTarget) {
        newPriority = (targetPriority + afterTarget.priority) / 2;
      } else {
        newPriority = targetPriority + INCREMENT;
      }
    } else {
      // Old position of moved object was after/below target;
      // move it up past it.
      const beforeTarget = targetIndex ? workshopChecklistItems[targetIndex - 1] : null;
      if (beforeTarget) {
        newPriority = (targetPriority + beforeTarget.priority) / 2;
      } else {
        newPriority = targetPriority / 2;
      }
    }
    if (Math.abs(targetPriority - newPriority) > THRESHOLD) {
      update(droppedItem.url, [{member: "priority", value: newPriority}]);
    } else {
      // renumbers everything now...
      const sourceIndex = workshopChecklistItems.indexOf(droppedItem);
      // NTS: removing sourceIndex will shift everything after it by one index
      // position --- meaning that for a targetIndex *after* the sourceIndex,
      // we insert *after* that element, but for a targetIndex *before* the
      // sourceIndex we insert *before* that element...
      const newOrder = workshopChecklistItems.map((item) => item.url);
      newOrder.splice(sourceIndex, 1);
      newOrder.splice(targetIndex, 0, droppedItem.url);
      // As normal array to simplify iterating in reverse order...
      // Renumbering happens because some entries got too close to each
      // other --- so the range of numbers represented after renumbering
      // is likely to be greater than before, so the state after updating
      // the N last items is more likely to be consistent than if we did
      // it from the other end...
      for (let i = newOrder.length - 1; i >= 0; i -= 1) {
        const url = newOrder[i];
        const priority = INITIAL + i * INCREMENT;
        update(url, [{member: "priority", value: priority}]);
      }
    }
  }

  @bind
  handleCopy(): void {
    this.props.onCopy(this.props.workshopChecklist);
  }

  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {customerSettings, workshopChecklist} = this.props;
    const workshopChecklistUrl = workshopChecklist.url;
    const isDraft = workshopChecklist.draft;
    const {machines} = workshopChecklist;

    return (
      <>
        <Card
          style={{
            display: "inline-flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
          }}
        >
          <div style={{position: "relative"}}>
            <Fab
              size="small"
              style={{
                position: "absolute",
                right: 16,
                top: 16,
              }}
              onClick={this.handleCopy}
            >
              <ContentCopyIcon />
            </Fab>
          </div>
          <CardHeader
            subheader={isDraft ? formatMessage(messages.draft) : ""}
            title={workshopChecklist.name}
          />

          <CardContent>
            <Button
              color="primary"
              variant="contained"
              onClick={isDraft ? this.handleMachineSelectButton : this.handleShowMachinesDialogOpen}
            >
              {isDraft
                ? customerSettings.machineLabelVariant === "MACHINE"
                  ? formatMessage(messages.selectMachines)
                  : formatMessage(messages.selectVehicles)
                : customerSettings.machineLabelVariant === "MACHINE"
                  ? formatMessage(messages.showMachines)
                  : formatMessage(messages.showVehicles)}
            </Button>
            {isDraft ? (
              <Button
                color="secondary"
                style={{marginLeft: 5}}
                variant="contained"
                onClick={this.handleActivateButton}
              >
                {formatMessage(messages.activate)}
              </Button>
            ) : (
              <FormControlLabel
                control={
                  <Switch
                    checked={!!workshopChecklist.active}
                    onChange={this.makeCheckedModelUpdater(workshopChecklistUrl, "active")}
                  />
                }
                label={formatMessage(messages.active)}
              />
            )}
            <FormControlLabel
              control={
                <Switch
                  checked={!!workshopChecklist.required}
                  onChange={this.makeCheckedModelUpdater(workshopChecklistUrl, "required")}
                />
              }
              label={formatMessage(messages.required)}
            />
            <hr style={{border: "none", borderTop: "1px double"}} />
            <div style={{position: "relative"}}>
              <span style={{fontSize: 24, lineHeight: "40px"}}>
                <FormattedMessage
                  defaultMessage="Punkter"
                  id="workshop-checklist-setting.label.point"
                />
              </span>
              <Fab
                disabled={!isDraft}
                size="small"
                style={{
                  position: "absolute",
                  right: 0,
                  top: 0,
                }}
                onClick={this.handleAddItemClick}
              >
                <PlusIcon />
              </Fab>
            </div>
          </CardContent>
          <List
            style={{
              flex: "1 1 0%",
              overflowY: "scroll",
            }}
          >
            {this.props.workshopChecklistItems.map((item) => {
              return (
                <ItemRow
                  key={item.url}
                  draft={isDraft}
                  item={item}
                  onDrop={this.handleDrop}
                  onEditClick={this.handleItemEdit}
                />
              );
            })}
          </List>
        </Card>
        <ConnectedMultipleMachinesDialog
          includeSelectAll={false}
          open={this.state.machineDialogOpen}
          selected={machines ? new Set(machines) : undefined}
          onCancel={this.handleMachineDialogCancel}
          onOk={this.handleMachineDialogOk}
        />
        <WorkshopChecklistItemDialog
          checklistURL={workshopChecklistUrl}
          open={this.state.itemDialogOpen}
          unitArray={this.props.unitArray}
          workshopChecklistItem={
            this.state.workshopChecklistItemURL
              ? this.props.workshopChecklistItemLookup(this.state.workshopChecklistItemURL)
              : undefined
          }
          onCancel={this.handleChecklistItemDialogCancel}
          onOk={this.handleChecklistItemSave}
        />
        <ShowMachinesDialog
          customerSettings={this.props.customerSettings}
          machines={
            machines
              ? _.sortBy(
                  this.props.machineArray.filter((m) => machines.includes(m.url)),
                  (machine) => {
                    const machineName = machine.name;
                    const machineID = machine.c5_machine;

                    return machineName + (machineID ? ` (${machineID})` : "");
                  },
                )
              : []
          }
          open={this.state.showMachinesDialogOpen}
          onRequestClose={this.handleShowMachinesDialogClose}
        />
      </>
    );
  }
}

const workshopChecklistCompare = (
  a: WorkshopChecklistResource,
  b: WorkshopChecklistResource,
): number => {
  const aActive = a.active;
  const bActive = b.active;
  if (aActive === bActive) {
    const aName = a.name;
    const bName = b.name;
    return aName.localeCompare(bName);
  } else if (aActive) {
    return -1;
  } else {
    return 1;
  }
};

interface WorkshopChecklistStateProps {
  customerSettings: Config;
  machineArray: readonly Machine[];
  unitArray: readonly Unit[];
  workshopChecklistArray: readonly WorkshopChecklistResource[];
  workshopChecklistItemArray: readonly WorkshopChecklistItem[];
  workshopChecklistItemLookup: (url: WorkshopChecklistItemUrl) => WorkshopChecklistItem | undefined;
  workshopChecklistLookup: (url: WorkshopChecklistUrl) => WorkshopChecklistResource | undefined;
}

interface WorkshopChecklistDispatchProps {
  create: (instance: ResourceTypeUnion) => void;
  update: (url: string, patch: PatchUnion) => void;
}

interface WorkshopChecklistOwnProps {
  onAddButtonClick: (event: React.MouseEvent) => void;
  selectedWorkshopChecklistURL?: WorkshopChecklistUrl | undefined;
}

type WorkshopChecklistProps = WorkshopChecklistDispatchProps &
  WorkshopChecklistOwnProps &
  WorkshopChecklistStateProps;

interface WorkshopChecklistState {
  selectedWorkshopChecklistURL: WorkshopChecklistUrl | undefined;
}

class WorkshopChecklist extends PureComponent<WorkshopChecklistProps, WorkshopChecklistState> {
  state: WorkshopChecklistState = {
    selectedWorkshopChecklistURL: this.props.selectedWorkshopChecklistURL,
  };

  UNSAFE_componentWillReceiveProps(nextProps: WorkshopChecklistProps): void {
    if (nextProps.selectedWorkshopChecklistURL !== this.props.selectedWorkshopChecklistURL) {
      this.setState({
        selectedWorkshopChecklistURL: nextProps.selectedWorkshopChecklistURL,
      });
    }
  }

  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  @bind
  handleWorkshopChecklistSelected(
    _event: React.SyntheticEvent<unknown>,
    workshopChecklistURL: WorkshopChecklistUrl | null,
  ): void {
    this.setState({
      selectedWorkshopChecklistURL: workshopChecklistURL || undefined,
    });
  }

  @bind
  handleWorkshopChecklistCopy(checklist: WorkshopChecklistResource): void {
    const copyID = uuid();
    const copyURL = instanceURL("workshopChecklist", copyID);
    const copy = {
      ...checklist,
      active: true,
      created: null,
      draft: true,
      id: copyID,
      url: copyURL,
    };

    this.props.create(copy);

    this.props.workshopChecklistItemArray
      .filter((i) => i.checklist === checklist.url)
      .forEach((item) => {
        const itemCopyID = uuid();
        const itemCopyURL = instanceURL("workshopChecklistItem", itemCopyID);
        const itemCopy = {
          ...item,
          checklist: copyURL,
          created: null,
          id: itemCopyID,
          url: itemCopyURL,
        };
        this.props.create(itemCopy);
      });
    this.setState({selectedWorkshopChecklistURL: copyURL});
  }

  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {selectedWorkshopChecklistURL} = this.state;
    const {workshopChecklistArray, workshopChecklistLookup} = this.props;

    const sortedWorkshopChecklists = workshopChecklistArray.slice().sort(workshopChecklistCompare);

    const workshopChecklistsData = sortedWorkshopChecklists.map((workshopChecklist) => {
      return {
        faded: !workshopChecklist.active,
        primaryText: workshopChecklist.name,
        secondaryText: workshopChecklist.draft ? formatMessage(messages.draft) : "",
        value: workshopChecklist.url,
      };
    });

    const selectedWorkshopChecklist = selectedWorkshopChecklistURL
      ? workshopChecklistLookup(selectedWorkshopChecklistURL)
      : null;
    const workshopChecklistItems = _.sortBy(
      this.props.workshopChecklistItemArray.filter(
        (i) => i.checklist === selectedWorkshopChecklistURL,
      ),
      (i) => i.priority,
    );
    let workshopChecklistEditBlock;
    if (selectedWorkshopChecklist) {
      workshopChecklistEditBlock = (
        <WorkshopChecklistEditBlock
          create={this.props.create}
          customerSettings={this.props.customerSettings}
          machineArray={this.props.machineArray}
          unitArray={this.props.unitArray}
          update={this.props.update}
          workshopChecklist={selectedWorkshopChecklist}
          workshopChecklistItemLookup={this.props.workshopChecklistItemLookup}
          workshopChecklistItems={workshopChecklistItems}
          onCopy={this.handleWorkshopChecklistCopy}
        />
      );
    }
    const mobileCellHeight = 360;
    // Grid/Cell types do not accept style prop ...?
    const GridNoType = Grid as any;
    const CellNoType = Cell as any;
    return (
      <GridNoType
        style={{
          height: bowser.mobile ? "auto" : "calc(100% - 64px)",
          margin: 5,
        }}
      >
        <CellNoType
          palm="12/12"
          style={{
            height: bowser.mobile ? mobileCellHeight : "100%",
            paddingBottom: 11,
            paddingTop: 11,
          }}
        >
          <SelectableListCard<WorkshopChecklistUrl>
            data={workshopChecklistsData}
            title={formatMessage(messages.workshopChecklists)}
            topFab={
              <Fab
                size="small"
                style={{
                  position: "absolute",
                  right: 16,
                  top: 16,
                }}
                onClick={this.props.onAddButtonClick}
              >
                <PlusIcon />
              </Fab>
            }
            value={this.state.selectedWorkshopChecklistURL}
            onChange={this.handleWorkshopChecklistSelected}
          />
        </CellNoType>
        <CellNoType palm="12/12" style={{paddingBottom: 11, paddingTop: 11}}>
          {workshopChecklistEditBlock}
        </CellNoType>
      </GridNoType>
    );
  }
}

const ConnectedWorkshopChecklist: React.ComponentType<WorkshopChecklistOwnProps> = connect<
  WorkshopChecklistStateProps,
  WorkshopChecklistDispatchProps,
  WorkshopChecklistOwnProps,
  AppState
>(
  createStructuredSelector<AppState, WorkshopChecklistStateProps>({
    customerSettings: getCustomerSettings,
    machineArray: getMachineArray,
    unitArray: getUnitArray,
    workshopChecklistArray: getWorkshopChecklistArray,
    workshopChecklistItemArray: getWorkshopChecklistItemArray,
    workshopChecklistItemLookup: getWorkshopChecklistItemLookup,
    workshopChecklistLookup: getWorkshopChecklistLookup,
  }),
  {
    create: actions.create,
    update: actions.update,
  },
)(WorkshopChecklist);

export {ConnectedWorkshopChecklist as WorkshopChecklist};

interface NewWorkshopChecklistDialogProps {
  onCancel?: () => void;
  onOk: (name: string) => void;
  open: boolean;
}

interface NewWorkshopChecklistDialogState {
  name: string;
}

export class NewWorkshopChecklistDialog extends PureComponent<
  NewWorkshopChecklistDialogProps,
  NewWorkshopChecklistDialogState
> {
  state: NewWorkshopChecklistDialogState = {
    name: "",
  };

  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  @bind
  handleNameChange(value: string): void {
    this.setState({name: value});
  }

  @bind
  handleOk(): void {
    const {name} = this.state;
    this.props.onOk(name);
  }

  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {onCancel, open} = this.props;
    const {name} = this.state;

    return (
      <ResponsiveDialog
        okDisabled={!name.trim()}
        open={open}
        title={formatMessage(messages.newChecklist)}
        onCancel={onCancel}
        onOk={this.handleOk}
      >
        <DialogContent>
          <TrimTextField
            autoFocus
            fullWidth
            label={formatMessage(messages.name)}
            margin="dense"
            value={name}
            variant="outlined"
            onChange={this.handleNameChange}
          />
        </DialogContent>
      </ResponsiveDialog>
    );
  }
}

interface ShowMachinesDialogProps {
  customerSettings: Config;
  machines: readonly Machine[];
  onRequestClose: () => void;
  open: boolean;
}

class ShowMachinesDialog extends PureComponent<ShowMachinesDialogProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {customerSettings, machines, onRequestClose, open} = this.props;

    return (
      <ResponsiveDialog
        okLabel={formatMessage(messages.close)}
        open={open}
        title={
          customerSettings.machineLabelVariant === "MACHINE"
            ? formatMessage(messages.machines)
            : formatMessage(messages.vehicles)
        }
        onOk={onRequestClose}
      >
        {!machines.length ? (
          <DialogContent>
            {customerSettings.machineLabelVariant === "MACHINE" ? (
              <FormattedMessage
                defaultMessage="Ingen maskiner valgt."
                id="ShowMachinesDialog.no-machines"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Ingen køretøjer valgt."
                id="ShowMachinesDialog.no-vehicles"
              />
            )}
          </DialogContent>
        ) : null}
        <List>
          {machines.map((machine) => {
            const machineName = machine.name;
            const machineID = machine.c5_machine;
            return (
              <ListItem key={machine.url}>
                <ListItemText primary={machineName + (machineID ? ` (${machineID})` : "")} />
              </ListItem>
            );
          })}
        </List>
      </ResponsiveDialog>
    );
  }
}
