import {DAY_MILLISECONDS} from "@co-common-libs/utils";
import {DateField} from "@co-frontend-libs/components";
import {Button, Card, CardContent} from "@material-ui/core";
import FileChartIcon from "mdi-react/FileChartIcon";
import React, {useCallback} from "react";
// Allowed for existing code...
// eslint-disable-next-line deprecate/import
import {Cell, Grid} from "react-flexr";
import {FormattedMessage, defineMessages, useIntl} from "react-intl";

const messages = defineMessages({
  calculate: {
    defaultMessage: "Beregn",
    id: "calculate-card.label.calculate",
  },
  periodEnd: {
    defaultMessage: "Til",
    id: "calculate-card.label.period-end",
  },
  periodStart: {
    defaultMessage: "Fra",
    id: "calculate-card.label.period-start",
  },
});

interface CalculateCardProps {
  fromDate: string | null;
  onFromDateChanged: (value: string | null) => void;
  onGenerateClick: () => void;
  onToDateChanged: (value: string | null) => void;
  toDate: string | null;
}

export const CalculateCard = React.memo(function CalculateCard(
  props: CalculateCardProps,
): JSX.Element {
  const {formatMessage} = useIntl();
  const {fromDate, onFromDateChanged, onGenerateClick, onToDateChanged, toDate} = props;

  const handleFromDateBlur = useCallback(() => {
    if (fromDate && (!toDate || fromDate > toDate)) {
      onToDateChanged(fromDate);
    }
  }, [fromDate, onToDateChanged, toDate]);

  const handlePeriodStartSelectedInDialog = useCallback(
    (date: string | null) => {
      if (date) {
        if (!toDate || date > toDate) {
          onFromDateChanged(date);
          onToDateChanged(date);
        } else {
          onFromDateChanged(date);
        }
      }
    },
    [onFromDateChanged, onToDateChanged, toDate],
  );

  let periodValid = false;
  let periodTooLong = false;
  if (fromDate && toDate) {
    if (toDate >= fromDate) {
      const fromDateObj = new Date(fromDate);
      const toDateObj = new Date(toDate);
      const daysPerLeapYear = 366;
      const millisecondsPerLeapYear = daysPerLeapYear * DAY_MILLISECONDS;
      periodTooLong = toDateObj.getTime() - fromDateObj.getTime() > millisecondsPerLeapYear;
      if (!periodTooLong) {
        periodValid = true;
      }
    }
  }

  const calculateDisabled = !periodValid;

  return (
    <Card>
      <CardContent>
        <Grid>
          <Cell>
            <DateField
              autoOk
              fullWidth
              label={formatMessage(messages.periodStart)}
              margin="dense"
              referenceDate={toDate || undefined}
              value={fromDate || null}
              yearSuggestions="DATE_BEFORE"
              onBlur={handleFromDateBlur}
              onChange={onFromDateChanged}
              onSelectedInDialog={handlePeriodStartSelectedInDialog}
            />
          </Cell>
          <Cell>
            <DateField
              autoOk
              fullWidth
              label={formatMessage(messages.periodEnd)}
              margin="dense"
              minDate={fromDate || undefined}
              referenceDate={fromDate || undefined}
              value={toDate || null}
              yearSuggestions={fromDate ? "DATE_AFTER" : "DATE_BEFORE"}
              onChange={onToDateChanged}
            />
          </Cell>
          <Cell>
            <Button
              disabled={calculateDisabled}
              startIcon={<FileChartIcon />}
              style={{float: "right", marginTop: 30}}
              onClick={onGenerateClick}
            >
              {formatMessage(messages.calculate)}
            </Button>
          </Cell>
        </Grid>
        {periodTooLong ? (
          <span style={{color: "red"}}>
            <FormattedMessage
              defaultMessage="Perioden må maksimum strække sig over 12 måneder"
              id="calculate-card.label.periodError"
            />
          </span>
        ) : null}
      </CardContent>
    </Card>
  );
});
