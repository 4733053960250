import {MemberPatchOperation, Patch, PatchUnion, ResourceInstance} from "@co-common-libs/resources";
import {actions} from "@co-frontend-libs/redux";
import {DependencyList, useCallback} from "react";
import {useDispatch} from "react-redux";

type StringMembersWithType<C, T> = NonNullable<
  {
    [K in keyof C]: T extends C[K] ? (K extends string ? K : never) : never;
  }[keyof C]
>;

export function valueModelUpdaterFactoryFactory<T extends ResourceInstance>(
  update: (url: string, patch: PatchUnion) => void,
): (
  url: string,
  field: StringMembersWithType<T, string>,
) => (event: React.ChangeEvent<HTMLInputElement>) => void {
  const cache = new Map<string, (event: React.ChangeEvent<HTMLInputElement>) => void>();
  return (url: string, field: StringMembersWithType<T, string>) => {
    const cacheKey = `${url}__${field}`;
    const cachedFn = cache.get(cacheKey);
    if (cachedFn) {
      return cachedFn;
    }
    const fn = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const {value} = event.target;
      const patchOperation: MemberPatchOperation<T> = {
        member: field as any,
        value: value as any,
      };
      const patch: Patch<T> = [patchOperation];
      update(url, patch as PatchUnion);
    };
    cache.set(cacheKey, fn);
    return fn;
  };
}

export function checkedModelUpdaterFactoryFactory<T extends ResourceInstance>(
  update: (url: string, patch: PatchUnion) => void,
): (
  url: string,
  field: StringMembersWithType<T, boolean>,
) => (event: React.ChangeEvent<HTMLInputElement>) => void {
  const cache = new Map<string, (event: React.ChangeEvent<HTMLInputElement>) => void>();
  return (url: string, field: StringMembersWithType<T, boolean>) => {
    const cacheKey = `${url}__${field}`;
    const cachedFn = cache.get(cacheKey);
    if (cachedFn) {
      return cachedFn;
    }
    const fn = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const {checked} = event.target;
      const patchOperation: MemberPatchOperation<T> = {
        member: field as any,
        value: checked as any,
      };
      const patch: Patch<T> = [patchOperation];
      update(url, patch as PatchUnion);
    };
    cache.set(cacheKey, fn);
    return fn;
  };
}

/**
 * Helper to make working with MUI TextField components less annoying by
 * unpacking `event.target.value` from a `ChangeEvent` and providing *that*
 * to a simpler event custom handler.
 *
 * @param fn Callback function
 * @param deps Callback function dependencies; should be auto-computed by
 *   eslint-plugin-react-hooks
 */
export function useEventTargetValueCallback(
  fn: (value: string) => void,
  deps: DependencyList,
): (event: React.ChangeEvent<HTMLInputElement>) => void {
  const wrappedFn = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const {value} = event.target;
      fn(value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps,
  );
  return wrappedFn;
}

/**
 * Helper to make working with MUI components less annoying by
 * unpacking `event.target.checked` from a `ChangeEvent` and providing *that*
 * to a simpler event custom handler.
 *
 * @param fn Callback function
 * @param deps Callback function dependencies; should be auto-computed by
 *   eslint-plugin-react-hooks
 */
export function useEventTargetCheckedCallback(
  fn: (checked: boolean) => void,
  deps: DependencyList,
): (event: React.ChangeEvent<HTMLInputElement>) => void {
  const wrappedFn = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const {checked} = event.target;
      fn(checked);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps,
  );
  return wrappedFn;
}

/**
 * Helper to construct simple resource field update callback functions
 *
 * @param url Resource instance URL
 * @param field Resource instance member field
 */
export function useFieldUpdater<T extends ResourceInstance, ValueType>(
  url: string,
  field: Exclude<StringMembersWithType<T, ValueType>, "id" | "url">,
): (value: ValueType) => void {
  const dispatch = useDispatch();
  const updater = useCallback(
    (value: ValueType): void => {
      const patchOperation = {member: field, value};
      const action = actions.update(url, [patchOperation] as PatchUnion);
      dispatch(action);
    },
    [dispatch, field, url],
  );
  return updater;
}

/**
 * Helper to construct simple resource field update callback functions
 * usable for MUI `TextField`/others change events.
 *
 * @param url Resource instance URL
 * @param field Resource instance member field
 */
export function useEventTargetValueUpdater<T extends ResourceInstance>(
  url: string,
  field: Exclude<StringMembersWithType<T, string>, "id" | "url">,
): (event: React.ChangeEvent<HTMLInputElement>) => void {
  const updater = useFieldUpdater<T, string>(url, field);
  const eventHandler = useEventTargetValueCallback(updater, [updater]);
  return eventHandler;
}

/**
 * Helper to construct simple resource field update callback functions
 * usable for MUI `CheckBox`/others change events.
 *
 * @param url Resource instance URL
 * @param field Resource instance member field
 */
export function useEventTargetCheckedUpdater<T extends ResourceInstance>(
  url: string,
  field: Exclude<StringMembersWithType<T, boolean>, "id" | "url">,
): (event: React.ChangeEvent<HTMLInputElement>) => void {
  const updater = useFieldUpdater<T, boolean>(url, field);
  const eventHandler = useEventTargetCheckedCallback(updater, [updater]);
  return eventHandler;
}

export function useTrueCallback(fn: (value: boolean) => void, deps: DependencyList): () => void {
  const wrappedFn = useCallback(
    (): void => {
      fn(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps,
  );
  return wrappedFn;
}

export function useFalseCallback(fn: (value: boolean) => void, deps: DependencyList): () => void {
  const wrappedFn = useCallback(
    (): void => {
      fn(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps,
  );
  return wrappedFn;
}
