import {getCommitDB} from "@co-frontend-libs/db-resources";
import {hasAuthToken} from "@co-frontend-libs/utils";
import {subMinutes} from "date-fns";
import * as actions from "../resources/actions";
import {ResourcesAuthenticationMiddlewareAPI} from "./types";

const RETRY_TIMEOUT_MINUTES = 2;

export function startOnlineSave(middlewareApi: ResourcesAuthenticationMiddlewareAPI): void {
  if (!hasAuthToken()) {
    return;
  }
  const {commitQueue} = middlewareApi.getState().resources;
  if (!commitQueue.length || commitQueue.some(({status}) => status === "SAVING_ONLINE")) {
    return;
  }
  const candidate = commitQueue.find(
    ({status}) => status === "SAVED_LOCALLY" || status === "PROMISE",
  );
  if (!candidate || candidate.status !== "SAVED_LOCALLY") {
    // nothing SAVED_LOCALLY, or some PROMISE before it in queue
    return;
  }
  if (
    candidate.error &&
    candidate.errorTimestamp &&
    candidate.errorTimestamp > subMinutes(new Date(), RETRY_TIMEOUT_MINUTES).toISOString()
  ) {
    return;
  }
  const {apiVersion, command, frontendVersion, id} = candidate;
  middlewareApi.dispatch(actions.saveOnline({apiVersion, command, frontendVersion, id}));
}

export function storeOnlineSaveError(
  action: ReturnType<typeof actions.saveOnline.rejected>,
): Promise<void> {
  const error = action.payload?.error || (action.error as any);
  const errorTimestamp = action.payload?.errorTimestamp || new Date().toISOString();
  return getCommitDB().then((commitDB) =>
    commitDB.setError(action.meta.arg.id, error, errorTimestamp),
  );
}
