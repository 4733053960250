import {Rate, SimpleInterval, TaskInterval, TaskIntervalWithRate, WorkDay} from "./types";

const lastOf = (a: string, b: string): string => (a > b ? a : b);

const firstOf = (a: string, b: string): string => (a < b ? a : b);

function processOneOtherGroup(
  otherGroupsIntervals: readonly TaskInterval[],
): (workDay: WorkDay) => WorkDay {
  console.assert(otherGroupsIntervals.length);

  const simplifiedOtherIntervals: SimpleInterval[] = [];

  let currentFrom = otherGroupsIntervals[0].fromTimestamp;
  let currentTo = otherGroupsIntervals[0].toTimestamp;

  otherGroupsIntervals.forEach(({fromTimestamp, toTimestamp}) => {
    console.assert(new Date(fromTimestamp).getSeconds() === 0);
    console.assert(new Date(toTimestamp).getSeconds() === 0);
    if (fromTimestamp > currentTo) {
      // time between intervals, so keep them separate
      simplifiedOtherIntervals.push({
        fromTimestamp: currentFrom,
        toTimestamp: currentTo,
      });
      currentFrom = fromTimestamp;
      currentTo = toTimestamp;
    } else {
      // no time between intervals, so combine them
      currentTo = toTimestamp;
    }
  });
  simplifiedOtherIntervals.push({
    fromTimestamp: currentFrom,
    toTimestamp: currentTo,
  });

  const otherFirstFromTimestamp = simplifiedOtherIntervals[0].fromTimestamp;
  const otherLastToTimestamp =
    simplifiedOtherIntervals[simplifiedOtherIntervals.length - 1].toTimestamp;
  return (workDay: WorkDay) => {
    if (!workDay.workPeriods.length) {
      return workDay;
    }
    const workDayFirstFrom = workDay.workPeriods[0].firstFromTimestamp;
    const workDayLastTo = workDay.workPeriods[workDay.workPeriods.length - 1].lastToTimestamp;
    if (workDayFirstFrom >= otherLastToTimestamp || workDayLastTo <= otherFirstFromTimestamp) {
      // no overlap possible
      return workDay;
    }
    const othersPotentiallyOverlappingDay = simplifiedOtherIntervals.filter(
      ({fromTimestamp, toTimestamp}) =>
        fromTimestamp < workDayLastTo && toTimestamp > workDayFirstFrom,
    );
    const workPeriods = workDay.workPeriods.map((workPeriod) => {
      const work = workPeriod.work.flatMap((interval) => {
        const {fromTimestamp: intervalFrom, toTimestamp: intervalTo} = interval;
        // brute force approach...
        const othersOverlappingInterval = othersPotentiallyOverlappingDay.filter(
          ({fromTimestamp, toTimestamp}) =>
            fromTimestamp < intervalTo && toTimestamp > intervalFrom,
        );
        if (!othersOverlappingInterval.length) {
          return interval;
        }
        console.assert(othersOverlappingInterval.length);
        const result: TaskIntervalWithRate[] = [];
        const firstOverlapping = othersOverlappingInterval[0];
        const lastOverlapping = othersOverlappingInterval[othersOverlappingInterval.length - 1];
        if (intervalFrom < firstOverlapping.fromTimestamp) {
          // non-overlapping before first overlapping
          result.push({
            ...interval,
            toTimestamp: firstOverlapping.fromTimestamp,
          });
        }
        othersOverlappingInterval.forEach(({fromTimestamp, toTimestamp}, index) => {
          result.push({
            ...interval,
            fromTimestamp: lastOf(fromTimestamp, intervalFrom),
            rate: Rate.UNPAID,
            toTimestamp: firstOf(toTimestamp, intervalTo),
          });
          const next = othersOverlappingInterval[index + 1];
          if (next) {
            // there's a next overlapping interval; so add time in between
            // as non-overlapping
            result.push({
              ...interval,
              fromTimestamp: toTimestamp,
              toTimestamp: next.fromTimestamp,
            });
          }
        });
        if (intervalTo > lastOverlapping.toTimestamp) {
          // non-overlapping after last overlapping
          result.push({
            ...interval,
            fromTimestamp: lastOverlapping.toTimestamp,
          });
        }
        console.assert(result.length);
        return result;
      });
      return {...workPeriod, work};
    });
    return {...workDay, workPeriods};
  };
}

function processOtherGroups(
  otherGroupsIntervals: readonly (readonly TaskInterval[])[],
): (workDay: WorkDay) => WorkDay {
  const groupHandlerFunctions = otherGroupsIntervals.map(processOneOtherGroup);
  return (inputWorkDay: WorkDay) =>
    groupHandlerFunctions.reduce(
      (workDay, groupIntervalsFunction) => groupIntervalsFunction(workDay),
      inputWorkDay,
    );
}

export function makeOtherGroupsIntervalsUnpaid(
  otherGroupsIntervals: readonly (readonly TaskInterval[])[],
  workDays: readonly WorkDay[],
): readonly WorkDay[] {
  return workDays.map(processOtherGroups(otherGroupsIntervals));
}
