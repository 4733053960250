import {Config} from "@co-common-libs/config";
import {ReportingInputSpecification, ReportingSpecification, Task} from "@co-common-libs/resources";
import {getValue} from "@co-common-libs/resources-utils";
import {IconButton} from "@material-ui/core";
import {PureComponent, addDanishCountryPrefix} from "app-utils";
import bowser from "bowser";
import _ from "lodash";
import MapIcon from "mdi-react/MapIcon";
import PencilIcon from "mdi-react/PencilIcon";
import PhoneIcon from "mdi-react/PhoneIcon";
import React from "react";
import {EditReportingLocationFab} from "../../reporting-locations/edit-reporting-location-fab";
import {ReportingValue} from "../reporting-value";

const CELL_STYLE = {
  paddingLeft: 2,
  paddingRight: 2,
};

function isStringArray(value: unknown): value is string[] {
  return Array.isArray(value) && value.every(_.isString);
}

interface LocationDataTableProps {
  customerSettings: Config;
  data: any;
  dataColumnPositions: ReadonlyMap<string, number>;
  disabled: boolean;
  entryCount: number;
  inputSpecificationsMap: ReadonlyMap<string, ReportingInputSpecification>;
  logLocationId: string;
  logSpecification: ReportingSpecification;
  mapsURL: string | null;
  onEditClick: () => void;
  phone: string | null;
  task: Task;
}

export class LocationDataTable extends PureComponent<LocationDataTableProps> {
  render(): JSX.Element | null {
    const {customerSettings} = this.props;
    const {
      data,
      dataColumnPositions,
      disabled,
      entryCount,
      inputSpecificationsMap,
      logLocationId,
      logSpecification,
      mapsURL,
      onEditClick,
      phone,
      task,
    } = this.props;
    if (!data) {
      return null;
    }
    const {values} = data;
    const dataColumns = [];
    const valueMaps = values ? [values] : [];

    dataColumnPositions.forEach((position, identifier) => {
      const value = getValue(customerSettings, valueMaps, inputSpecificationsMap, identifier);
      const inputSpecification = inputSpecificationsMap.get(identifier);
      if (!inputSpecification) {
        return;
      }
      const formatSpecification = inputSpecification.format;
      if (
        typeof value === "number" ||
        typeof value === "string" ||
        typeof value === "boolean" ||
        isStringArray(value)
      ) {
        let averagePart: JSX.Element | undefined;
        if (typeof value === "number") {
          if (inputSpecification.average && entryCount) {
            const decimalPlaces = customerSettings.materialDecimals;
            const averageValue = _.round(value / entryCount, decimalPlaces);
            averagePart = (
              <span>
                {" "}
                (
                <ReportingValue formatSpecification={formatSpecification} value={averageValue} />)
              </span>
            );
          }
        }
        const {unit} = inputSpecification;
        dataColumns[position] = (
          <td key={position} style={CELL_STYLE}>
            <ReportingValue formatSpecification={formatSpecification} value={value} />
            {averagePart}
            {unit ? ` ${unit}` : null}
          </td>
        );
      }
    });
    for (let i = 0; i < dataColumnPositions.size; i += 1) {
      if (!dataColumns[i]) {
        dataColumns[i] = <td key={i} style={CELL_STYLE} />;
      }
    }
    return (
      <table style={{width: "100%"}}>
        <tbody>
          <tr>
            {dataColumns}
            <td style={CELL_STYLE}>
              {customerSettings.addEditLogLocationSkipsCustomerSelection ? (
                <EditReportingLocationFab
                  withDelete
                  disabled={disabled}
                  logLocationId={logLocationId}
                  logSpecification={logSpecification}
                  style={{float: "right", height: 24, padding: 0, width: 24}}
                  task={task}
                />
              ) : (
                <IconButton
                  color="primary"
                  disabled={disabled}
                  style={{float: "right", height: 24, padding: 0, width: 24}}
                  onClick={onEditClick}
                >
                  <PencilIcon size={24} />
                </IconButton>
              )}
              <div style={{float: "right", paddingRight: 10}}>
                <IconButton
                  color="primary"
                  disabled={!mapsURL}
                  href={mapsURL || ""}
                  style={{height: 24, padding: 0, width: 24}}
                  target={bowser.ios ? "_system" : "_blank"}
                >
                  <MapIcon size={24} />
                </IconButton>
              </div>
              <div style={{float: "right", paddingRight: 10}}>
                <IconButton
                  color="primary"
                  disabled={!phone}
                  href={phone ? `tel:${addDanishCountryPrefix(phone)}` : ""}
                  style={{height: 24, padding: 0, width: 24}}
                  target={bowser.ios ? "_system" : "_blank"}
                >
                  <PhoneIcon size={24} />
                </IconButton>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}
