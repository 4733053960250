import {ReadonlyConfig} from "@co-common-libs/config";
import {HoursFromMinutesVariant} from "@co-common-libs/utils";

type TransferTaskRulesPart = Pick<
  ReadonlyConfig,
  | "orderValidation"
  | "orderValidationForWorkTypes"
  | "transferTaskRequireOrderApproval"
  | "transferTaskRequireOrderApprovalForWorkTypes"
  | "transferTaskUnApproveOnError"
  | "unsetValidatedAndRecordedOnError"
>;

type TransferTaskProjectPart = Pick<ReadonlyConfig, "useCorrectedCountOnCostLines">;

type TransferTaskInvoicePart = Pick<
  ReadonlyConfig,
  | "adjustBilledMinutes"
  | "billedBreaks"
  | "distributionTableAdjustTimeMethod"
  | "economicInvoicePullerAsDeparment"
  | "economicInvoicesForProjectTasks"
  | "economicInvoiceWorkplaceAddressReplaceName"
  | "economicSeperateNoteLines"
  | "economicShowGenericTransportlogOnInvoice"
  | "economicSubtractDowntimeIdentifiers"
  | "economicZeroBlank"
  | "invoiceAddGenericTransportLogs"
  | "invoiceAddTransportLogSum"
  | "invoiceExcludeProducts"
  | "invoiceExtraEffectiveTimerIdentifiers"
  | "invoicesAddTaskNotesToEffectiveTimeLine"
  | "invoiceSubtractDowntimeTimers"
  | "invoiceTaskNotesToEffectiveTimeLine"
  | "invoiceUseWorkplaceAddressAsName"
  | "materialDecimals"
  | "orderReferenceNumberLabel"
  | "productsExcludedFromInvoice"
  | "taskReferenceNumberLabel"
  | "timersContributingToEffectiveTime"
  | "transportLogDecimals"
  | "transportLogDistanceKmDecimalPlaces"
  | "transportResponsibleEffectiveTimers"
>;

type TransferTaskNotePart = Pick<
  ReadonlyConfig,
  | "economicAppendMaterialNotesToInvoiceLineDescription"
  | "economicCustomerDetailsOnTransportlogOnInvoice"
  | "economicInvoiceEmployeeNotes"
  | "economicInvoiceIncludeTotalTaskTime"
  | "economicInvoiceManagerNotes"
  | "economicPrefixCustomerContactNameWith"
  | "economicPrefixMachineOperatorNameOrAliasWith"
  | "economicRouteTaskStartStopOnInvoice"
  | "economicShowCustomerContactName"
  | "economicShowFieldUse"
  | "economicShowFieldUseCrop"
  | "economicShowMachineName"
  | "economicShowMachineOperatorAlias"
  | "economicShowMachineOperatorAliasOrNameWithoutLineBreak"
  | "economicShowMachineOperatorName"
  | "economicShowOrderReferenceNumber"
  | "economicShowPickupLocation"
  | "economicShowTaskReferenceNumber"
  | "economicShowTransportlogOnInvoice"
  | "economicShowWorkPlaceOrDeliveryLocation"
  | "invoiceNoteAddAdminNotes"
  | "invoiceNoteAddCustomerContactName"
  | "invoiceNoteAddCustomerDetailsOnTransportLog"
  | "invoiceNoteAddEmployeeAlias"
  | "invoiceNoteAddEmployeeName"
  | "invoiceNoteAddEmployeeNotes"
  | "invoiceNoteAddFieldUse"
  | "invoiceNoteAddFieldUseCrop"
  | "invoiceNoteAddMachineName"
  | "invoiceNoteAddManagerNotes"
  | "invoiceNoteAddOrderManagerNotes"
  | "invoiceNoteAddOrderReferenceNumber"
  | "invoiceNoteAddPickupLocation"
  | "invoiceNoteAddRouteTaskStartStop"
  | "invoiceNoteAddTaskReferenceNumber"
  | "invoiceNoteAddTotalTaskTime"
  | "invoiceNoteAddWorkPlaceOrDeliveryLocation"
  | "invoiceNotePrefixCustomerContactNameWith"
  | "invoiceNotePrefixEmployeeNameOrAliasWith"
  | "transferOrderAdminNotesToInvoice"
  | "transferOrderNotesToInvoice"
>;

export type TransferTaskConfigPart = Pick<ReadonlyConfig, "unicontaSync"> &
  TransferTaskProjectPart &
  TransferTaskRulesPart &
  TransferTaskInvoicePart &
  TransferTaskNotePart;

export interface TransferTaskSettings {
  invoice: {
    addGenericTransportLogs: readonly string[];
    addTransportLogSum: boolean;
    billedBreaks: boolean;
    billedHoursFromMinutesVariant: HoursFromMinutesVariant;
    createInvoicesForProjectTasks: boolean;
    distributionTableAdjustTimeMethod: HoursFromMinutesVariant;
    excludeProducts: readonly string[];
    extraEffectiveTimers: readonly string[];
    materialDecimals: number;
    orderReferenceNumberLabel: string | null;
    priceBlankInsteadOfZero: boolean;
    pullerAsDepartment: boolean;
    separateNoteLines: boolean;
    subtractDowntimeTimers: readonly string[];
    taskNotesToEffectiveTimeLine: boolean;
    taskReferenceNumberLabel: string | null;
    transportLogDecimals: number;
    transportLogDistanceKmDecimalPlaces: number;
    transportResponsibleEffectiveTimers: readonly string[];
    useWorkplaceAddressAsName: boolean;
  };
  note: {
    addAdminNotes: boolean;
    addCustomerContactName: boolean;
    addCustomerDetailsOnTransportLog: boolean;
    addEmployeeAlias: boolean;
    addEmployeeAliasOrNameWithoutLineBreak: boolean;
    addEmployeeName: boolean;
    addEmployeeNotes: boolean;
    addFieldUse: boolean;
    addFieldUseCrop: boolean;
    addMachineName: boolean;
    addManagerNotes: boolean;
    addOrderNotes: boolean;
    addOrderReferenceNumber: boolean;
    addPickupLocation: boolean;
    addRouteTaskStartStop: boolean;
    addTaskReferenceNumber: boolean;
    addTotalTaskTime: boolean;
    addWorkPlaceOrDeliveryLocation: boolean;
    appendMaterialNotesToInvoiceLineDescription: boolean;
    prefixCustomerContactNameWith: string;
    prefixEmployeeNameOrAliasWith: string;
  };
  project: {
    useCorrectedCountOnCostLines: boolean;
  };
  rules: {
    requireOrderApproval: boolean;
    requireOrderApprovalForWorkTypes: readonly string[];
    unApproveOnError: boolean;
  };
}

function selectNewOrPrevious<T>(
  config: Pick<ReadonlyConfig, "unicontaSync">,
  settings: {newConfig: T; previousConfig: T},
): T {
  const {newConfig, previousConfig} = settings;

  return config.unicontaSync ? newConfig : previousConfig;
}

export function transferTaskSettings(config: TransferTaskConfigPart): TransferTaskSettings {
  return {
    invoice: {
      addGenericTransportLogs: selectNewOrPrevious(config, {
        newConfig: config.invoiceAddGenericTransportLogs,
        previousConfig: config.economicShowGenericTransportlogOnInvoice,
      }),
      addTransportLogSum: selectNewOrPrevious(config, {
        newConfig: config.invoiceAddTransportLogSum,
        previousConfig: config.economicShowTransportlogOnInvoice,
      }),
      billedBreaks: config.billedBreaks,
      billedHoursFromMinutesVariant: config.adjustBilledMinutes,
      createInvoicesForProjectTasks: config.economicInvoicesForProjectTasks,
      distributionTableAdjustTimeMethod: config.distributionTableAdjustTimeMethod,
      excludeProducts: selectNewOrPrevious(config, {
        newConfig: config.invoiceExcludeProducts,
        previousConfig: config.productsExcludedFromInvoice,
      }),
      extraEffectiveTimers: selectNewOrPrevious(config, {
        newConfig: config.invoiceExtraEffectiveTimerIdentifiers,
        previousConfig: config.timersContributingToEffectiveTime,
      }),
      materialDecimals: config.materialDecimals,
      orderReferenceNumberLabel: config.orderReferenceNumberLabel,
      priceBlankInsteadOfZero: config.economicZeroBlank,
      pullerAsDepartment: selectNewOrPrevious(config, {
        newConfig: false,
        previousConfig: config.economicInvoicePullerAsDeparment,
      }),
      separateNoteLines: config.economicSeperateNoteLines,
      subtractDowntimeTimers: selectNewOrPrevious(config, {
        newConfig: config.invoiceSubtractDowntimeTimers,
        previousConfig: config.economicSubtractDowntimeIdentifiers,
      }),
      taskNotesToEffectiveTimeLine: selectNewOrPrevious(config, {
        newConfig: config.invoiceTaskNotesToEffectiveTimeLine,
        previousConfig: config.invoicesAddTaskNotesToEffectiveTimeLine,
      }),
      taskReferenceNumberLabel: config.taskReferenceNumberLabel,
      transportLogDecimals: config.transportLogDecimals,
      transportLogDistanceKmDecimalPlaces: config.transportLogDistanceKmDecimalPlaces,
      transportResponsibleEffectiveTimers: config.transportResponsibleEffectiveTimers,
      useWorkplaceAddressAsName: selectNewOrPrevious(config, {
        newConfig: config.invoiceUseWorkplaceAddressAsName,
        previousConfig: config.economicInvoiceWorkplaceAddressReplaceName,
      }),
    },
    note: {
      addAdminNotes: selectNewOrPrevious(config, {
        newConfig: config.invoiceNoteAddAdminNotes,
        previousConfig: config.transferOrderAdminNotesToInvoice,
      }),
      addCustomerContactName: selectNewOrPrevious(config, {
        newConfig: config.invoiceNoteAddCustomerContactName,
        previousConfig: config.economicShowCustomerContactName,
      }),
      addCustomerDetailsOnTransportLog: selectNewOrPrevious(config, {
        newConfig: config.invoiceNoteAddCustomerDetailsOnTransportLog,
        previousConfig: config.economicCustomerDetailsOnTransportlogOnInvoice,
      }),
      addEmployeeAlias: selectNewOrPrevious(config, {
        newConfig: config.invoiceNoteAddEmployeeAlias,
        previousConfig: config.economicShowMachineOperatorAlias,
      }),
      addEmployeeAliasOrNameWithoutLineBreak:
        config.economicShowMachineOperatorAliasOrNameWithoutLineBreak,
      addEmployeeName: selectNewOrPrevious(config, {
        newConfig: config.invoiceNoteAddEmployeeName,
        previousConfig: config.economicShowMachineOperatorName,
      }),
      addEmployeeNotes: selectNewOrPrevious(config, {
        newConfig: config.invoiceNoteAddEmployeeNotes,
        previousConfig: config.economicInvoiceEmployeeNotes,
      }),
      addFieldUse: selectNewOrPrevious(config, {
        newConfig: config.invoiceNoteAddFieldUse,
        previousConfig: config.economicShowFieldUse,
      }),
      addFieldUseCrop: selectNewOrPrevious(config, {
        newConfig: config.invoiceNoteAddFieldUseCrop,
        previousConfig: config.economicShowFieldUseCrop,
      }),
      addMachineName: selectNewOrPrevious(config, {
        newConfig: config.invoiceNoteAddMachineName,
        previousConfig: config.economicShowMachineName,
      }),
      addManagerNotes: selectNewOrPrevious(config, {
        newConfig: config.invoiceNoteAddManagerNotes,
        previousConfig: config.economicInvoiceManagerNotes,
      }),
      addOrderNotes: selectNewOrPrevious(config, {
        newConfig: config.invoiceNoteAddOrderManagerNotes,
        previousConfig: config.transferOrderNotesToInvoice,
      }),
      addOrderReferenceNumber: selectNewOrPrevious(config, {
        newConfig: config.invoiceNoteAddOrderReferenceNumber,
        previousConfig: config.economicShowOrderReferenceNumber,
      }),
      addPickupLocation: selectNewOrPrevious(config, {
        newConfig: config.invoiceNoteAddPickupLocation,
        previousConfig: config.economicShowPickupLocation,
      }),
      addRouteTaskStartStop: selectNewOrPrevious(config, {
        newConfig: config.invoiceNoteAddRouteTaskStartStop,
        previousConfig: config.economicRouteTaskStartStopOnInvoice,
      }),
      addTaskReferenceNumber: selectNewOrPrevious(config, {
        newConfig: config.invoiceNoteAddTaskReferenceNumber,
        previousConfig: config.economicShowTaskReferenceNumber,
      }),
      addTotalTaskTime: selectNewOrPrevious(config, {
        newConfig: config.invoiceNoteAddTotalTaskTime,
        previousConfig: config.economicInvoiceIncludeTotalTaskTime,
      }),
      addWorkPlaceOrDeliveryLocation: selectNewOrPrevious(config, {
        newConfig: config.invoiceNoteAddWorkPlaceOrDeliveryLocation,
        previousConfig: config.economicShowWorkPlaceOrDeliveryLocation,
      }),
      appendMaterialNotesToInvoiceLineDescription: selectNewOrPrevious(config, {
        newConfig: false,
        previousConfig: config.economicAppendMaterialNotesToInvoiceLineDescription,
      }),
      prefixCustomerContactNameWith: selectNewOrPrevious(config, {
        newConfig: config.invoiceNotePrefixCustomerContactNameWith,
        previousConfig: config.economicPrefixCustomerContactNameWith,
      }),
      prefixEmployeeNameOrAliasWith: selectNewOrPrevious(config, {
        newConfig: config.invoiceNotePrefixEmployeeNameOrAliasWith,
        previousConfig: config.economicPrefixMachineOperatorNameOrAliasWith,
      }),
    },
    project: {
      useCorrectedCountOnCostLines: config.useCorrectedCountOnCostLines,
    },
    rules: {
      requireOrderApproval: selectNewOrPrevious(config, {
        newConfig: config.transferTaskRequireOrderApproval,
        previousConfig: config.orderValidation,
      }),
      requireOrderApprovalForWorkTypes: selectNewOrPrevious(config, {
        newConfig: config.transferTaskRequireOrderApprovalForWorkTypes,
        previousConfig: config.orderValidationForWorkTypes,
      }),
      unApproveOnError: selectNewOrPrevious(config, {
        newConfig: config.transferTaskUnApproveOnError,
        previousConfig: config.unsetValidatedAndRecordedOnError,
      }),
    },
  };
}
