import {RoutePlanTaskUrl, RouteTaskUrl, Task} from "@co-common-libs/resources";
import {notUndefined} from "@co-common-libs/utils";
import {
  getCurrentUserURL,
  getLocationLookup,
  getRoutePlanTaskArray,
  getRouteTaskArray,
  getTimerStartArray,
} from "@co-frontend-libs/redux";
import {Card, CardContent} from "@material-ui/core";
import _ from "lodash";
import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import {RouteEntry} from "./route-entry";

interface RouteTabRoutePlanProps {
  onRouteTaskCompleteClick: (
    routePlanTaskUrl: RoutePlanTaskUrl,
    routeTaskUrl: RouteTaskUrl | undefined,
  ) => void;
  onStartClick: () => void;
  routePlanUrl: string;
  task: Task;
}

export function RouteTabRoutePlan(props: RouteTabRoutePlanProps): JSX.Element {
  const {onRouteTaskCompleteClick, onStartClick, routePlanUrl, task} = props;

  const taskURL = task.url;

  const locationLookup = useSelector(getLocationLookup);
  const routeTaskArray = useSelector(getRouteTaskArray);
  const routePlanTaskArray = useSelector(getRoutePlanTaskArray);
  const currentUserURL = useSelector(getCurrentUserURL);
  const timerStartArray = useSelector(getTimerStartArray);

  const routeTaskList = useMemo(
    () =>
      _.sortBy(
        routeTaskArray.filter((routeTask) => routeTask.route === taskURL),
        (routeTask) => routeTask.order,
      ),
    [routeTaskArray, taskURL],
  );

  const routePlanTaskList = useMemo(
    () =>
      _.sortBy(
        routePlanTaskArray.filter((routePlanTask) => routePlanTask.routePlan === routePlanUrl),
        (routePlanTask) => routePlanTask.order,
      ),
    [routePlanTaskArray, routePlanUrl],
  );

  const processedRouteTaskEntries = new Set<string>();

  const startedRouteTaskUrl = routeTaskList.find(
    (routeTask) => routeTask.started && !routeTask.completed,
  )?.url;

  const planEntries = routePlanTaskList
    .map((routePlanTask) => {
      const workplace = locationLookup(routePlanTask.relatedLocation);
      if (!workplace) {
        return undefined;
      }
      // both routePlanTaskList and routeTaskList are sorted;
      // so multiple equivalent entries will be matched in order...
      const routeTask = routeTaskList.find(
        (r) =>
          r.customer === routePlanTask.customer &&
          r.project === routePlanTask.project &&
          r.relatedLocation === routePlanTask.relatedLocation &&
          r.description === routePlanTask.description &&
          r.notesFromManager === routePlanTask.notesFromManager &&
          r.deadline === routePlanTask.deadline &&
          !processedRouteTaskEntries.has(r.url),
      );
      if (routeTask) {
        processedRouteTaskEntries.add(routeTask.url);
      }

      return (
        <RouteEntry
          key={routePlanTask.url}
          currentUserURL={currentUserURL}
          otherTaskStarted={!!(startedRouteTaskUrl && startedRouteTaskUrl !== routeTask?.url)}
          routePlanTask={routePlanTask}
          routeTask={routeTask}
          task={task}
          timerStartArray={timerStartArray}
          workplace={workplace}
          onRouteTaskCompleteClick={onRouteTaskCompleteClick}
          onStartClick={onStartClick}
        />
      );
    })
    .filter(notUndefined);

  const extraEntries =
    routeTaskList.length > processedRouteTaskEntries.size
      ? routeTaskList
          .map((routeTask) => {
            if (processedRouteTaskEntries.has(routeTask.url)) {
              return undefined;
            }
            const workplace = locationLookup(routeTask.relatedLocation);
            if (!workplace) {
              return undefined;
            }
            return (
              <RouteEntry
                key={routeTask.url}
                currentUserURL={currentUserURL}
                otherTaskStarted={!!(startedRouteTaskUrl && startedRouteTaskUrl !== routeTask.url)}
                routeTask={routeTask}
                task={task}
                timerStartArray={timerStartArray}
                workplace={workplace}
              />
            );
          })
          .filter(notUndefined)
      : [];

  return (
    <Card style={{margin: "1em"}}>
      <CardContent>
        {planEntries}
        {extraEntries}
      </CardContent>
    </Card>
  );
}
