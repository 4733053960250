import {Patch, PatchOperation, Task} from "@co-common-libs/resources";
import {getExpectedLogSpecification, translateLogLocations} from "@co-common-libs/resources-utils";
import _ from "lodash";
import {ProvisionaryCommand} from "../../resources/actions";
import {
  getLocationLookup,
  getMachineLookup,
  getPriceGroupLookup,
  getReportingSpecificationLookup,
  getSprayLogArray,
  getTransportLogArray,
  getWorkTypeLookup,
  getYieldLogArray,
} from "../../resources/selectors";
import {ResourcesAuthenticationMiddlewareAPI} from "../types";

export function updateGenericLog(
  newTask: Task | null,
  oldTask: Task | undefined,
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  _command: ProvisionaryCommand,
): Patch<Task> | null {
  if (!newTask) {
    return null;
  }

  if (!newTask.order) {
    return null;
  }

  if (!_.isEmpty(newTask.reportingLog)) {
    // keep current log if entries present (UI should not allow the change then)
    return null;
  }

  if (
    newTask.workType === (oldTask?.workType || null) &&
    newTask.priceGroup === (oldTask?.priceGroup || null) &&
    _.isEqual(newTask.machineuseSet, oldTask?.machineuseSet || []) &&
    (_.isEqual(newTask.reportingLog || {}, oldTask?.reportingLog || {}) ||
      (newTask.reportingLog && Object.keys(newTask.reportingLog).length))
  ) {
    // no change to properties that might set/change log;
    // log not explicitly cleared on this change, which might otherwise
    // unlock a previously blocked change
    return null;
  }

  if (
    !_.isEqual(newTask.reportingSpecification, oldTask?.reportingSpecification || null) ||
    !_.isEqual(newTask.reportingData, oldTask?.reportingData || {}) ||
    !_.isEqual(newTask.reportingLocations, oldTask?.reportingLocations || {}) ||
    (!_.isEqual(newTask.reportingLog, oldTask?.reportingLog || {}) &&
      newTask.reportingLog &&
      Object.keys(newTask.reportingLog).length)
  ) {
    // assume that whatever log-changes are provided are correct
    return null;
  }

  const state = middlewareApi.getState();

  const reportingSpecificationLookup = getReportingSpecificationLookup(state);
  const priceGroupLookup = getPriceGroupLookup(state);
  const workTypeLookup = getWorkTypeLookup(state);
  const machineLookup = getMachineLookup(state);

  const reportingSpecificationURL =
    getExpectedLogSpecification(newTask, {
      machineLookup,
      priceGroupLookup,
      reportingSpecificationLookup,
      workTypeLookup,
    })?.url || null;

  if (reportingSpecificationURL === newTask.reportingSpecification) {
    // no change
    return null;
  }

  // We *would* add generic log -- unless there is already a legacy log
  if (reportingSpecificationURL && !oldTask?.reportingSpecification) {
    const taskUrl = newTask.url;
    const transportLogArray = getTransportLogArray(state);
    if (transportLogArray.some((transportLog) => transportLog.task === taskUrl)) {
      return null;
    }
    const sprayLogArray = getSprayLogArray(state);
    if (sprayLogArray.some((sprayLog) => sprayLog.task === taskUrl)) {
      return null;
    }
    const yieldLogArray = getYieldLogArray(state);
    if (yieldLogArray.some((yieldLog) => yieldLog.task === taskUrl)) {
      return null;
    }
  }

  const result: PatchOperation<Task>[] = [
    {member: "reportingSpecification", value: reportingSpecificationURL},
  ];
  if (!_.isEmpty(newTask.reportingData)) {
    result.push({member: "reportingData", value: {}});
  }
  if (!_.isEmpty(newTask.reportingLocations)) {
    const reportingSpecification = reportingSpecificationURL
      ? reportingSpecificationLookup(reportingSpecificationURL)
      : undefined;
    const oldReportingSpecification = oldTask?.reportingSpecification
      ? reportingSpecificationLookup(oldTask.reportingSpecification)
      : undefined;
    const locationLookup = getLocationLookup(state);
    const newReportingLocations = reportingSpecification
      ? translateLogLocations(
          locationLookup,
          newTask.reportingLocations,
          reportingSpecification,
          oldReportingSpecification,
        )
      : {};
    if (!_.isEqual(newReportingLocations, newTask.reportingLocations)) {
      result.push({member: "reportingLocations", value: newReportingLocations});
    }
  }
  return result;
}
