import {Location, LocationUrl} from "@co-common-libs/resources";
import {
  caseAccentInsensitiveCollator,
  formatDateTime,
  identifierComparator,
} from "@co-common-libs/utils";
import {
  ColumnSpecifications,
  GenericTable,
  RowData,
  iconButtonColumnSpecification,
  iconColumnSpecification,
} from "@co-frontend-libs/components";
import {actions, getLocationArray, getTableSortingState} from "@co-frontend-libs/redux";
import {IconButton, SvgIcon} from "@material-ui/core";
import {useQueryParameter} from "app-utils";
import DeleteIcon from "mdi-react/DeleteIcon";
import EarthIcon from "mdi-react/EarthIcon";
import FileUploadIcon from "mdi-react/FileUploadIcon";
import React, {useCallback, useMemo} from "react";
import {FormattedMessage, FormattedNumber} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

const TABLE_SORTING_IDENTIFIER = "FieldTable";

const VISIBLE_COLUMNS = [
  "vatnumber",
  "fieldblock",
  "fieldJournalNumber",
  "fieldnumber",
  "area",
  "crop",
  "fieldDataChanged",
  "origin",
  "delete",
] as const;

type FieldTableColumnID =
  | "area"
  | "crop"
  | "delete"
  | "fieldblock"
  | "fieldDataChanged"
  | "fieldJournalNumber"
  | "fieldnumber"
  | "origin"
  | "vatnumber";

type FieldTableFieldID =
  | "area"
  | "crop"
  | "fieldblock"
  | "fieldDataChanged"
  | "fieldDataChangedRaw"
  | "fieldJournalNumber"
  | "fieldnumber"
  | "origin"
  | "vatnumber";

interface FieldTableDataType extends RowData<FieldTableFieldID, LocationUrl> {
  area: number | null;
  crop: string;
  fieldblock: string;
  fieldnumber: string;
  origin: "ONLINE" | "ZIP";
  vatnumber: string;
}

function renderOriginIcon(data: FieldTableDataType): JSX.Element {
  return (
    <SvgIcon color="disabled" style={{margin: 12}}>
      {data.origin === "ONLINE" ? <EarthIcon /> : <FileUploadIcon />}
    </SvgIcon>
  );
}

function renderDeleteButton(
  onDelete: (locationURL: string) => void,
  data: FieldTableDataType,
): JSX.Element {
  const handleDelete = (): void => {
    onDelete(data.key);
  };
  return (
    // eslint-disable-next-line react/jsx-no-bind
    <IconButton onClick={handleDelete}>
      <DeleteIcon />
    </IconButton>
  );
}

function renderArea(data: FieldTableDataType): JSX.Element | null {
  return data.area ? (
    <FormattedNumber maximumFractionDigits={2} minimumFractionDigits={2} value={data.area} />
  ) : null;
}

function compareFieldNumberIdentifier(a: FieldTableDataType, b: FieldTableDataType): number {
  return identifierComparator(a.fieldnumber, b.fieldnumber);
}

function buildColumnSpecifications(
  onDelete: (locationURL: string) => void,
): ColumnSpecifications<FieldTableFieldID, FieldTableColumnID, LocationUrl, FieldTableDataType> {
  return {
    area: {
      field: "area",
      label: <FormattedMessage defaultMessage="Areal (ha)" id="field-list.table-header.area" />,
      render: renderArea,
    },
    crop: {
      field: "crop",
      label: <FormattedMessage defaultMessage="Afgrøde" id="field-list.table-header.crop" />,
    },
    delete: iconButtonColumnSpecification({
      field: "fieldnumber",
      render: renderDeleteButton.bind(null, onDelete),
    }),
    fieldblock: {
      field: "fieldblock",
      label: (
        <FormattedMessage defaultMessage="Markblok" id="field-list.table-header.field-block" />
      ),
    },
    fieldDataChanged: {
      field: "fieldDataChanged",
      label: <FormattedMessage defaultMessage="Opdateret" />,
      sortField: "fieldDataChangedRaw",
    },
    fieldJournalNumber: {
      field: "fieldJournalNumber",
      label: <FormattedMessage defaultMessage="Journalnr." />,
    },
    fieldnumber: {
      comparator: compareFieldNumberIdentifier,
      field: "fieldnumber",
      label: <FormattedMessage defaultMessage="Marknr." />,
    },
    origin: iconColumnSpecification({
      field: "origin",
      render: renderOriginIcon,
    }),
    vatnumber: {
      field: "vatnumber",
      label: <FormattedMessage defaultMessage="CVR-nr." id="field-list.table-header.vatnumber" />,
    },
  };
}

function buildRowData(locationArray: readonly Location[]): FieldTableDataType[] {
  return locationArray
    .filter((location) => location.geojson && location.active)
    .map((location) => ({
      area: location.fieldAreaHa,
      crop: location.fieldCrop,
      fieldblock: location.fieldBlock,
      fieldDataChanged: formatDateTime(location.fieldDataChanged),
      fieldDataChangedRaw: location.fieldDataChanged,
      fieldJournalNumber: location.fieldJournalNumber,
      fieldnumber: location.fieldNumber,
      key: location.url,
      origin: (location.fieldFromUpload ? "ZIP" : "ONLINE") as "ONLINE" | "ZIP",
      vatnumber: location.fieldVatNumber,
    }))
    .sort(
      (a, b) =>
        caseAccentInsensitiveCollator.compare(a.fieldnumber, b.fieldnumber) ||
        caseAccentInsensitiveCollator.compare(a.fieldblock, b.fieldblock) ||
        caseAccentInsensitiveCollator.compare(a.key, b.key),
    );
}

interface FieldTableProps {
  customerURL: string | null;
  onDelete: (locationURL: string) => void;
}

export function FieldTable(props: FieldTableProps): JSX.Element {
  const {customerURL, onDelete} = props;
  const locationArray = useSelector(getLocationArray);
  const customerLocationArray = useMemo(
    () => locationArray.filter((location) => location.customer === customerURL),
    [customerURL, locationArray],
  );
  const columnSpecifications = useMemo(() => buildColumnSpecifications(onDelete), [onDelete]);

  const rowData = useMemo(() => buildRowData(customerLocationArray), [customerLocationArray]);

  const filterString = useQueryParameter("q", "");

  const sortingStateSelector = useMemo(
    () => getTableSortingState(TABLE_SORTING_IDENTIFIER, "name", "ASC"),
    [],
  );
  const {sortDirection, sortKey} = useSelector(sortingStateSelector);

  const dispatch = useDispatch();

  const handleHeaderClick = useCallback(
    (key: FieldTableColumnID): void => {
      let direction: "ASC" | "DESC" = "ASC";
      if (sortKey === key && sortDirection === "ASC") {
        direction = "DESC";
      }
      const action = actions.putTableSortingState(TABLE_SORTING_IDENTIFIER, key, direction);
      dispatch(action);
    },
    [dispatch, sortKey, sortDirection],
  );

  return (
    <GenericTable
      columns={columnSpecifications}
      entries={rowData}
      filterString={filterString}
      sortBy={sortKey as any}
      sortDirection={sortDirection}
      visibleColumns={VISIBLE_COLUMNS}
      onHeaderClick={handleHeaderClick}
    />
  );
}
