import {Task} from "@co-common-libs/resources";
import {SwitchGroupSpecification} from "./types";

function makeSwitchCheck(
  switchGroupSpecification: SwitchGroupSpecification,
): (task: Task) => string | null {
  const {departmentID, identifier, workTypeURL} = switchGroupSpecification;
  const switchCheck = (task: Task): string | null => {
    if (workTypeURL != null && task.workType !== workTypeURL) {
      return null;
    }
    if (departmentID != null && task.department !== departmentID) {
      return null;
    }
    return identifier;
  };
  return switchCheck;
}

export function getGroupFn(
  employeeDefaultRemunerationGroup: string,
  switchSpecifications: readonly SwitchGroupSpecification[],
): (task: Task) => string {
  const switchCheckFunctions = switchSpecifications.map(makeSwitchCheck);
  const fn = (task: Task): string => {
    for (let i = 0; i < switchCheckFunctions.length; i += 1) {
      const switchCheckFunction = switchCheckFunctions[i];
      const checkResult = switchCheckFunction(task);
      if (checkResult != null) {
        return checkResult;
      }
    }
    return employeeDefaultRemunerationGroup;
  };
  return fn;
}
