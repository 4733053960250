import {
  PricePercentDefaultFuelSurchargeUse,
  PricePercentFuelSurchargeSpecificationUrl,
} from "@co-common-libs/resources";
import {ConnectedPricePercentFuelSurchargeSpecificationDialog} from "@co-frontend-libs/connected-components";
import {
  actions,
  getPricePercentDefaultFuelSurchargeUseArray,
  getPricePercentFuelSurchargeSpecificationArray,
  getPricePercentFuelSurchargeSpecificationLookup,
} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {Button, IconButton, useTheme} from "@material-ui/core";
import {instanceURL} from "frontend-global-config";
import CloseIcon from "mdi-react/CloseIcon";
import React, {useCallback, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuid} from "uuid";

export const PricePercentDefaultFuelSurchargeBlock = React.memo(
  function PricePercentDefaultFuelSurchargeBlock(): JSX.Element {
    const pricePercentFuelSurchargeSpecificationLookup = useSelector(
      getPricePercentFuelSurchargeSpecificationLookup,
    );
    const pricePercentFuelSurchargeSpecificationArray = useSelector(
      getPricePercentFuelSurchargeSpecificationArray,
    );
    const pricePercentDefaultFuelSurchargeUseArray = useSelector(
      getPricePercentDefaultFuelSurchargeUseArray,
    );

    const dispatch = useDispatch();

    const theme = useTheme();

    const currentUse = pricePercentDefaultFuelSurchargeUseArray.find(
      (surchargeUse) => surchargeUse.customer === null,
    );

    const handleClearButton = useCallback(() => {
      if (currentUse) {
        dispatch(actions.remove(currentUse.url));
      }
    }, [currentUse, dispatch]);

    const [fuelSurchargeDialogOpen, setFuelSurchargeDialogOpen] = useState(false);
    const setFuelSurchargeDialogOpenTrue = useCallWithTrue(setFuelSurchargeDialogOpen);
    const setFuelSurchargeDialogOpenFalse = useCallWithFalse(setFuelSurchargeDialogOpen);

    const handleFuelSurchargeDialogOk = useCallback(
      (fuelSurchargeUrl: PricePercentFuelSurchargeSpecificationUrl): void => {
        setFuelSurchargeDialogOpen(false);
        if (currentUse && fuelSurchargeUrl === currentUse.fuelSurcharge) {
          return;
        } else if (currentUse) {
          dispatch(actions.remove(currentUse.url));
        }
        const id = uuid();
        const url = instanceURL("pricePercentDefaultFuelSurchargeUse", id);
        const instance: PricePercentDefaultFuelSurchargeUse = {
          customer: null,
          fuelSurcharge: fuelSurchargeUrl,
          id,
          url,
        };
        dispatch(actions.create(instance));
      },
      [currentUse, dispatch],
    );

    let text = "";
    let fuelSurchargeInactive = false;
    if (currentUse && currentUse.fuelSurcharge) {
      const fuelSurcharge = pricePercentFuelSurchargeSpecificationLookup(currentUse.fuelSurcharge);
      if (fuelSurcharge) {
        text = fuelSurcharge.name;
        fuelSurchargeInactive = !fuelSurcharge.active;
      }
    }

    return (
      <>
        <div style={{margin: "1em"}}>
          <div>
            <Button
              color="primary"
              disabled={
                !pricePercentFuelSurchargeSpecificationArray.some(
                  (specification) => specification.active,
                )
              }
              variant="contained"
              onClick={setFuelSurchargeDialogOpenTrue}
            >
              <FormattedMessage defaultMessage="Vælg" />
            </Button>
          </div>
          <div>
            {text}
            {currentUse ? (
              <IconButton onClick={handleClearButton}>
                <CloseIcon />
              </IconButton>
            ) : null}
            {fuelSurchargeInactive ? (
              <div style={{color: theme.palette.error.main}}>
                <FormattedMessage defaultMessage="Det valgte brændstoftillæg er inaktivt" />
              </div>
            ) : null}
          </div>
        </div>
        <ConnectedPricePercentFuelSurchargeSpecificationDialog
          open={fuelSurchargeDialogOpen}
          onCancel={setFuelSurchargeDialogOpenFalse}
          onOk={handleFuelSurchargeDialogOk}
        />
      </>
    );
  },
);
