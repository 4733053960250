import {Patch, Task} from "@co-common-libs/resources";
import {MinutesField} from "@co-frontend-libs/components";
import {actions} from "@co-frontend-libs/redux";
import {TableCell, TableRow, useTheme} from "@material-ui/core";
import React, {useCallback} from "react";
import {useDispatch} from "react-redux";

const COUNT_MAX_DIGITS = 7;

const COUNT_MAX_VALUE = 10 ** COUNT_MAX_DIGITS - 1;

interface EntryRowProps {
  count: number | null;
  dangling: boolean;
  disabled: boolean;
  identifier: string;
  name: string;
  taskUrl: string;
}

export function EntryRow(props: EntryRowProps): JSX.Element {
  const {count, dangling, disabled, identifier, name, taskUrl} = props;

  const dispatch = useDispatch();

  const handleCountChange = useCallback(
    (value: number | null): void => {
      const patch: Patch<Task> = [{path: ["priceItemUses", identifier, "count"], value}];
      dispatch(actions.update(taskUrl, patch));
    },
    [dispatch, identifier, taskUrl],
  );

  const theme = useTheme();

  const style: React.CSSProperties = dangling ? {color: theme.palette.error.main} : {};

  return (
    <TableRow>
      <TableCell style={style}>{name}</TableCell>
      <TableCell>
        <MinutesField
          fullWidth
          minQuarter
          disabled={disabled}
          label="timer/tt:mm"
          margin="dense"
          maxMinutes={COUNT_MAX_VALUE}
          value={count}
          onChange={handleCountChange}
        />
      </TableCell>
    </TableRow>
  );
}
