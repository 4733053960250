import {Config} from "@co-common-libs/config";
import {getSettingsEntryLookupByIdentifier} from "@co-frontend-libs/redux";
import {Checkbox, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import React, {useMemo} from "react";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {SettingValueDisplayProps} from "../types";

type Geolocation = Config["geolocation"];

export function DisplayGeolocation(props: SettingValueDisplayProps): JSX.Element {
  const {settingID} = props;

  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const settingEntry = settingsEntryLookupByIdentifier(settingID);

  const geolocation: Geolocation = useMemo(() => {
    const data = settingEntry?.data;
    return data
      ? {...(props.settingMetaData.schema.default as Geolocation), ...data}
      : props.settingMetaData.schema.default;
  }, [settingEntry?.data, props.settingMetaData.schema.default]);

  return (
    <div>
      <i>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{width: 400}}>
                <FormattedMessage defaultMessage="Navn" id="system-setup.dialog-title.name" />
              </TableCell>
              <TableCell>
                <FormattedMessage defaultMessage="Værdi" id="system-setup.dialog-title.value" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell style={{width: 400}}>
                <FormattedMessage
                  defaultMessage="Kort og GPS aktiveret"
                  id="geolocation-dialog.geolocation-enabled"
                />
              </TableCell>
              <TableCell>
                <Checkbox disabled checked={geolocation.enabled} />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{width: 400}}>
                <FormattedMessage
                  defaultMessage="Registrer position ved klik på tidsknap"
                  id="geolocation-dialog.register-position-on-timer-click"
                />
              </TableCell>
              <TableCell>
                <Checkbox disabled checked={geolocation.registerPositionOnTimerClick} />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{width: 400}}>
                <FormattedMessage
                  defaultMessage="Hjemme markering, breddegrad"
                  id="geolocation-dialog.home-marker-latitude"
                />
              </TableCell>
              <TableCell>{geolocation.homeMarkerLatitude}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{width: 400}}>
                <FormattedMessage
                  defaultMessage="Hjemme markering, længdegrad"
                  id="geolocation-dialog.home-marker-longitude"
                />
              </TableCell>
              <TableCell>{geolocation.homeMarkerLongitude}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{width: 400}}>
                <FormattedMessage
                  defaultMessage="Initiel kort zoom"
                  id="geolocation-dialog.initial-zoom"
                />
              </TableCell>
              <TableCell>{geolocation.initialZoom}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{width: 400}}>
                <FormattedMessage
                  defaultMessage="Initiel kort position, breddegrad"
                  id="geolocation-dialog.initial-position-latitude"
                />
              </TableCell>
              <TableCell>{geolocation.initialPositionLatitude}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{width: 400}}>
                <FormattedMessage
                  defaultMessage="Initiel kort position, længdegrad"
                  id="geolocation-dialog.initial-position-longitude"
                />
              </TableCell>
              <TableCell>{geolocation.initialPositionLongitude}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{width: 400}}>
                <FormattedMessage
                  defaultMessage="Vis advarselsmarking på kort efter antal minutter uden positionsdata"
                  id="geolocation-dialog.active-marker-warning-threshold-in-minutes"
                />
              </TableCell>
              <TableCell>{geolocation.activeMarkerWarningThresholdInMinutes}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </i>
    </div>
  );
}
