import {Patch, Task} from "@co-common-libs/resources";
import _ from "lodash";
import {ProvisionaryCommand} from "../../resources/actions";
import {getWorkTypeLookup} from "../../resources/selectors";
import {ResourcesAuthenticationMiddlewareAPI} from "../types";

export function disallowMachineUseForWorkTypes(
  newTask: Task | null,
  oldTask: Task | undefined,
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  _command: ProvisionaryCommand,
): Patch<Task> | null {
  if (!newTask) {
    return null;
  }
  if (
    newTask.recordedInC5 ||
    newTask.archivable ||
    !newTask.workType ||
    !newTask.machineuseSet ||
    !newTask.machineuseSet.length ||
    (newTask.workType === oldTask?.workType &&
      _.isEqual(newTask.machineuseSet, oldTask?.machineuseSet))
  ) {
    // archived, or
    // no work type, so blocking on work type irrelevant, or
    // no machines, so it's irrelevant, or
    // no changes to workType/machines, so nothing to potentially block/change
    return null;
  }
  const state = middlewareApi.getState();

  const workTypeLookup = getWorkTypeLookup(state);
  const workType = workTypeLookup(newTask.workType);
  if (workType?.disallowMachineUse) {
    // machines not allowed for this work type, so clear it
    return [{member: "machineuseSet", value: []}];
  } else {
    return null;
  }
}
