export const DRAG_HANDLE_CELL_WIDTH = 48;
export const WORK_TYPE_COLUMN_WIDTH = 72;
export const EMPLOYEE_COLUMN_WIDTH = 96;
export const NOTES_COLUMN_WIDTH = 144;
export const DURATION_COLUMN_WIDTH = 84;
export const MACHINES_COLUMN_WIDTH = 96;
export const MACHINES_NAME_COLUMN_WIDTH = 144;
export const VEHICLE_IDENTIFICATION_NUMBER_COLUMN_WIDTH = 144;
export const WORK_TYPE_MACHINE_COLUMN_WIDTH = 144;
export const STATUS_ICON_COLUMN_WIDTH = 28;
export const MOBILE_STATUS_COLUMN_WIDTH = 72;
export const PHOTO_ICON_COLUMN_WIDTH = 28;
export const DATE_COLUMN_WIDTH = 96;

export const TABLE_MIN_WIDTH = 600;
