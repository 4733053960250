import {TranslatedError} from "@co-common-libs/utils";
import {ResponsiveDialog, typedMemo} from "@co-frontend-libs/components";
import {useResettingState} from "@co-frontend-libs/utils";
import {CircularProgress, DialogContent, Typography} from "@material-ui/core";
import {useAsync, useTimeoutEffect} from "@react-hookz/web";
import React, {useCallback, useEffect} from "react";
import {FormattedMessage} from "react-intl";

const PROGRESS_MINIMUM_TIME_VISIBLE_MS = 2000;

interface ProcessingDialogProps<T> {
  onClose?: () => void;
  onFailure?: () => void;
  onSuccess?: (value: T) => void;
  processPromise: Promise<T>;
  title: string;
}

export const ProcessingDialog = typedMemo(function ProcessingDialog<T>(
  props: ProcessingDialogProps<T>,
): JSX.Element {
  const {onClose, onFailure, onSuccess, processPromise, title} = props;

  const [state, actions] = useAsync<T>(() => processPromise);
  const promiseStatus = state.status;

  const [minimumTimeVisibleExpired, setMinimumTimeVisibleExpired] = useResettingState<boolean>(
    false,
    promiseStatus === "loading",
  );
  const [open, setOpen] = useResettingState<boolean>(true, promiseStatus === "loading");

  const [cancelMinimumTimeExpired, resetMinimumTimeExpired] = useTimeoutEffect(() => {
    setMinimumTimeVisibleExpired(true);
  }, PROGRESS_MINIMUM_TIME_VISIBLE_MS);

  useEffect(() => {
    resetMinimumTimeExpired();
    actions.execute();
  }, [actions, resetMinimumTimeExpired]);

  const handleCloseDialog = useCallback(() => {
    cancelMinimumTimeExpired();
    setOpen(false);
    actions.reset();
    if (onClose) {
      onClose();
    }
  }, [actions, cancelMinimumTimeExpired, onClose, setOpen]);

  const handleCloseDialogSuccess = useCallback(() => {
    handleCloseDialog();
    if (onSuccess) {
      onSuccess(state.result as T);
    }
  }, [handleCloseDialog, onSuccess, state.result]);

  const handleCloseDialogFailure = useCallback(() => {
    handleCloseDialog();
    if (onFailure) {
      onFailure();
    }
  }, [handleCloseDialog, onFailure]);

  useEffect(() => {
    if (open) {
      if (promiseStatus === "success" && minimumTimeVisibleExpired) {
        handleCloseDialogSuccess();
      } else if (promiseStatus === "error") {
        cancelMinimumTimeExpired();
      }
    }
  }, [
    cancelMinimumTimeExpired,
    handleCloseDialogSuccess,
    minimumTimeVisibleExpired,
    open,
    promiseStatus,
  ]);

  return (
    <ResponsiveDialog
      okDisabled={promiseStatus !== "error"}
      open={open}
      title={title}
      onOk={handleCloseDialogFailure}
    >
      <DialogContent>
        {state.error ? (
          <Typography color="error" variant="body2">
            {state.error instanceof TranslatedError ? (
              `${state.error.message}`
            ) : (
              <FormattedMessage defaultMessage="Der skete en fejl, prøv igen senere eller kontakt support." />
            )}
          </Typography>
        ) : (
          <div style={{textAlign: "center"}}>
            <CircularProgress size={72} />
          </div>
        )}
      </DialogContent>
    </ResponsiveDialog>
  );
});
