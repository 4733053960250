import {MenuItem, Select} from "@material-ui/core";
import _ from "lodash";
import React, {useCallback} from "react";
import {FormattedMessage} from "react-intl";
import {ImportColumnConfiguration, ImportColumnConfigurationWithoutUpdateExisting} from "../types";

export function ImportDropDown(props: {
  columnName: string;
  headers: readonly string[];
  isKeyColumn: boolean;
  onChange: (
    columnName: string,
    configuration: ImportColumnConfigurationWithoutUpdateExisting,
  ) => void;
  selected: ImportColumnConfiguration;
}): JSX.Element {
  const {columnName, headers, isKeyColumn, onChange, selected} = props;

  const handleChange = useCallback(
    (
      event: React.ChangeEvent<{
        value: unknown;
      }>,
    ) => {
      event.stopPropagation();
      const {value} = event.target;
      if (typeof value === "number") {
        onChange(columnName, {columnIndex: value, valueType: "sourceColumn"});
      } else if (value === "staticValue") {
        onChange(columnName, {value: "", valueType: "staticValue"});
      } else {
        onChange(columnName, {valueType: "none"});
      }
    },
    [columnName, onChange],
  );

  const menuItems: JSX.Element[] = headers.map((header, index) => (
    <MenuItem key={index} value={index}>
      {header}
    </MenuItem>
  ));

  if (!isKeyColumn) {
    menuItems.push(
      <MenuItem key="none" value="none">
        <FormattedMessage defaultMessage="Ingen" />
      </MenuItem>,
    );
    menuItems.push(
      <MenuItem key="staticValue" value="staticValue">
        <FormattedMessage defaultMessage="Fast Værdi" />
      </MenuItem>,
    );
  }

  let selectedValue: number | string = "";

  if (selected === null || selected === undefined) {
    selectedValue = "";
  } else if (selected.valueType === "staticValue") {
    selectedValue = "staticValue";
  } else if (selected.valueType === "none" && !isKeyColumn) {
    selectedValue = "none";
  } else if (selected.valueType === "sourceColumn") {
    selectedValue = selected.columnIndex;
  }

  return (
    <Select
      fullWidth
      margin="dense"
      style={{minWidth: 150, wordBreak: "break-all"}}
      value={selectedValue}
      variant="outlined"
      onChange={handleChange}
    >
      {menuItems}
    </Select>
  );
}
