import {createSelector} from "reselect";
import {AppState} from "../root-reducer";

export const getOnlineHistory = (state: AppState): readonly string[] => state.connectivity.online;

export const getOfflineHistory = (state: AppState): readonly string[] => state.connectivity.offline;

const checkLatest = (listA: readonly string[], listB: readonly string[]): boolean | null => {
  const a: string | undefined = listA[0];
  const b: string | undefined = listB[0];
  if (a === undefined && b === undefined) {
    return null;
  } else if (b === undefined) {
    return true;
  } else if (a === undefined) {
    return false;
  }
  if (a === b) {
    return null;
  } else {
    return a > b;
  }
};

export const getOnline = createSelector(getOnlineHistory, getOfflineHistory, checkLatest);

export const getOffline = createSelector(getOfflineHistory, getOnlineHistory, checkLatest);
