import {frontendVersion} from "@co-common-libs/frontend-version";
import {LinearProgress} from "@material-ui/core";
import React from "react";
import {FormattedMessage} from "react-intl";

export function UpdatingLocalDB(): JSX.Element {
  return (
    <div style={{alignItems: "center", display: "flex", height: "100vh"}}>
      <div style={{textAlign: "center", width: "100%"}}>
        <FormattedMessage
          defaultMessage="Opdaterer lokal database"
          id="Initialized.label.updating-local-db"
        />
        <LinearProgress variant="indeterminate" />
        <br />
        <span style={{fontSize: 12, margin: 16, opacity: 0.4}}>v. {frontendVersion}</span>
      </div>
    </div>
  );
}
