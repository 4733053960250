import {getSettingsEntryLookupByIdentifier} from "@co-frontend-libs/redux";
import React from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {SettingViewProps} from "../types";
import {KeyValueSetting} from "./key-value-setting";

const defaultAbsenceTypeShortLabels = {
  childsFirstSickDay: "BS",
  compensatory: "A",
  daysOffWithoutPay: "FD",
  floatingHoliday: "FF",
  sickLeave: "SY",
  timeOff: "FRI",
  vacation: "FE",
};

export function AbsenceTypeShortLabels(props: SettingViewProps): JSX.Element {
  const {settingID, settingMetaData} = props;

  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const settingEntry = settingsEntryLookupByIdentifier(settingID);
  const absenceTypeShortLabels: {[label: string]: string} = settingEntry?.data || {};

  const usedAbsenceTypeShortLabels: {[key: string]: string} = {
    ...defaultAbsenceTypeShortLabels,
    ...absenceTypeShortLabels,
  };
  const intl = useIntl();
  return (
    <KeyValueSetting
      data={usedAbsenceTypeShortLabels}
      disableKeyEdit={Object.keys(defaultAbsenceTypeShortLabels)}
      keyLabel={intl.formatMessage({defaultMessage: "Fraværstype"})}
      settingID={settingID}
      settingMetaData={settingMetaData}
      title={intl.formatMessage({defaultMessage: "Fraværstyper"})}
      valueLabel={intl.formatMessage({defaultMessage: "Label"})}
    />
  );
}
