import {
  LocationUrl,
  ReportingInputSpecification,
  ReportingLocation,
  ReportingSpecification,
  ReportingWorkplaceInputSpecification,
} from "@co-common-libs/resources";
import {getInputSpecificationsMap} from "@co-common-libs/resources-utils";
import {formatAddress} from "@co-common-libs/utils";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {
  getContactArray,
  getCustomerLookup,
  getCustomerSettings,
  getJournalArray,
  getLocationLookup,
  getUnitLookup,
} from "@co-frontend-libs/redux";
import {DialogContent, Grid} from "@material-ui/core";
import {getLocationPhone} from "app-utils";
import _ from "lodash";
import React, {useCallback, useEffect, useMemo, useReducer} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {buildDialogFields} from "../reporting/dialogs/build-dialog-fields";

interface JournalNoteProps {
  locationUrl: LocationUrl;
}

const JournalNote = React.memo(function JournalNote(props: JournalNoteProps): JSX.Element | null {
  const {locationUrl} = props;
  const customerJournalArray = useSelector(getJournalArray);
  const journalEntries = customerJournalArray.filter(
    (entry) => entry.reminder && entry.locations?.includes(locationUrl),
  );
  if (!journalEntries.length) {
    return null;
  }

  return (
    <div style={{whiteSpace: "pre-wrap"}}>
      <FormattedMessage defaultMessage="Note:" />
      {journalEntries.map((entry) => (
        <div key={entry.url} style={{fontWeight: "bold"}}>
          {entry.entry}
        </div>
      ))}
    </div>
  );
});

export type ValuesState = {readonly [identifier: string]: unknown};

export type ValuesAction =
  | {identifier: string; type: "delete"}
  | {identifier: string; type: "put"; value: unknown}
  | {type: "replace"; values: ValuesState};

// TypeScript validates that all legal paths returns ValuesState
// eslint-disable-next-line consistent-return
export function valuesReducer(state: ValuesState, action: ValuesAction): ValuesState {
  switch (action.type) {
    case "put":
      return {...state, [action.identifier]: action.value};
    case "delete":
      return _.omit(state, action.identifier);
    case "replace":
      return action.values;
  }
}

interface EditReportingLocationProps {
  currentData: ValuesState;
  inputSpecifications: readonly ReportingWorkplaceInputSpecification[];
  locationUrl: LocationUrl;
  logSpecification: ReportingSpecification;
  onCancel: () => void;
  onOk: (data: ValuesState) => void;
  open: boolean;
  type: ReportingLocation["type"];
}

export const EditReportingLocation = React.memo(function EditReportingLocation(
  props: EditReportingLocationProps,
): JSX.Element {
  const {
    currentData,
    inputSpecifications,
    locationUrl,
    logSpecification,
    onCancel,
    onOk,
    open,
    type,
  } = props;

  const intl = useIntl();

  const customerSettings = useSelector(getCustomerSettings);
  const locationLookup = useSelector(getLocationLookup);
  const unitLookup = useSelector(getUnitLookup);
  const customerLookup = useSelector(getCustomerLookup);
  const contactArray = useSelector(getContactArray);

  const [values, dispatchValues] = useReducer(valuesReducer, {});

  const updateValue = useCallback((identifier: string, value: unknown): void => {
    dispatchValues({identifier, type: "put", value});
  }, []);

  useEffect(() => {
    if (open) {
      dispatchValues({type: "replace", values: currentData});
    }
  }, [open, currentData]);

  const location = locationUrl ? locationLookup(locationUrl) : undefined;

  const inputSpecificationsMap = useMemo(
    (): ReadonlyMap<string, ReportingInputSpecification> =>
      getInputSpecificationsMap(logSpecification),
    [logSpecification],
  );

  const {anyRequired, inputFields, okDisabled} = useMemo(() => {
    const valueMaps = [values];
    return buildDialogFields(
      unitLookup,
      inputSpecifications,
      customerSettings,
      valueMaps,
      inputSpecificationsMap,
      updateValue,
      values,
    );
  }, [
    customerSettings,
    inputSpecifications,
    inputSpecificationsMap,
    unitLookup,
    updateValue,
    values,
  ]);

  const handleOk = useCallback(() => {
    onOk(values);
  }, [onOk, values]);

  // TS validates that all possible parameters lead to `return`
  // eslint-disable-next-line consistent-return
  const title = useMemo(() => {
    switch (type) {
      case "delivery":
        return intl.formatMessage({defaultMessage: "Leveringssted"});
      case "pickup":
        return intl.formatMessage({defaultMessage: "Afhentningssted"});
      case "workplace":
        return intl.formatMessage({defaultMessage: "Arbejdssted"});
    }
  }, [intl, type]);

  return (
    <ResponsiveDialog
      fullWidth
      okDisabled={okDisabled || !locationUrl}
      open={open}
      title={title}
      onCancel={onCancel}
      onOk={handleOk}
    >
      <DialogContent>
        <Grid container direction="row" spacing={2} wrap="wrap">
          <Grid item md={6} xs={12}>
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <FormattedMessage defaultMessage="Navn:" />
                    </td>
                    <td>{location?.name}</td>
                  </tr>
                  <tr>
                    <td>
                      <FormattedMessage defaultMessage="Adresse:" />
                    </td>
                    <td>{formatAddress(location)}</td>
                  </tr>
                  <tr>
                    <td>
                      <FormattedMessage defaultMessage="Kontakt:" />
                    </td>
                    <td>{location?.attention}</td>
                  </tr>

                  <tr>
                    <td>
                      <FormattedMessage defaultMessage="Telefon:" />
                    </td>
                    <td>{getLocationPhone(location || null, contactArray, customerLookup)}</td>
                  </tr>
                </tbody>
              </table>
              {locationUrl ? <JournalNote locationUrl={locationUrl} /> : null}
            </div>
          </Grid>
          <Grid item md={6} xs={12}>
            {inputFields}
            {anyRequired ? <FormattedMessage defaultMessage="* krævet" /> : null}
          </Grid>
        </Grid>
      </DialogContent>
    </ResponsiveDialog>
  );
});
