import {TaskFile} from "@co-common-libs/resources";
import {formatDateShort} from "@co-common-libs/utils";
import {FilePdfIcon} from "@co-frontend-libs/components";
import {Checkbox, IconButton, ImageListItem, ImageListItemBar} from "@material-ui/core";
import {bind} from "bind-decorator";
import React from "react";

interface FileTileProps {
  readonly checked: boolean;
  readonly customerName: string;
  readonly employeeAlias: string;
  readonly fileInstance: TaskFile;
  readonly onCheck: (url: string, checked: boolean) => void;
  onTitleClick: (url: string) => void;
  shareToken: string;
  readonly taskDate: string;
}

export class FileTile extends React.PureComponent<FileTileProps> {
  @bind
  handleClick(): void {
    this.props.onCheck(this.props.fileInstance.url, !this.props.checked);
  }
  @bind
  handleTitleClick(): void {
    this.props.onTitleClick(this.props.fileInstance.task);
  }

  render(): JSX.Element {
    const {
      checked,
      customerName,
      employeeAlias,
      fileInstance,
      onCheck,
      onTitleClick,
      shareToken,
      taskDate,
      ...other
    } = this.props;
    const {created, note} = fileInstance;
    const createdDate = formatDateShort(created);
    return (
      // pass on whatever props <GridList> provides to child components
      <ImageListItem cols={1} rows={1} {...other}>
        <div style={{paddingTop: 50, textAlign: "center"}}>
          <IconButton
            color="primary"
            href={`${fileInstance.download}?token=${shareToken}`}
            target="_blank"
          >
            <FilePdfIcon size={60} />
          </IconButton>
        </div>
        <ImageListItemBar
          actionIcon={
            <Checkbox checked={checked} style={{color: "#fff"}} onClick={this.handleClick} />
          }
          actionPosition="right"
          position="bottom"
          subtitle={`${note}`}
          title={
            <span
              style={{cursor: "pointer", display: "inline-block"}}
              onClick={this.handleTitleClick}
            >
              {createdDate} {employeeAlias} {customerName}
            </span>
          }
        />
      </ImageListItem>
    );
  }
}
