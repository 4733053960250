import {ResourceInstance} from "@co-common-libs/resources";
import * as _ from "lodash";
import idbReady from "safari-14-idb-fix";
import {DBConnection} from "./db-connection";
import {getListURL} from "./utils";

interface ListResourceInstance extends ResourceInstance {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  __dbListURL?: string;
}

const createStores = (
  db: IDBDatabase,
  _oldVersion: number,
  _newVersion: number | null,
  storeNames: string[],
): void => {
  storeNames.forEach((storeName) => {
    const store = db.createObjectStore(storeName, {keyPath: "url"});
    store.createIndex("listURL", "__dbListURL");
  });
};

const VERSION = 1;

const openConnectionAndCreateTables = (
  indexedDB: IDBFactory,
  dbName: string,
  storeNames: string[],
): Promise<IDBDatabase> =>
  new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName, VERSION);
    request.onupgradeneeded = (event) => {
      const db = (event.target as any).result as IDBDatabase;
      const {oldVersion} = event;
      const {newVersion} = event;
      createStores(db, oldVersion, newVersion, storeNames);
    };
    request.onsuccess = (event) => {
      const db = (event.target as any).result as IDBDatabase;
      resolve(db);
    };
    request.onerror = (event) => {
      reject(new Error(event.type));
    };
  });

class IndexedDBDBConnection extends DBConnection {
  private connection: IDBDatabase;
  private indexedDB: IDBFactory;
  private dbName: string;
  private error: Error | null;
  constructor(connection: IDBDatabase, indexedDB: IDBFactory, dbName: string) {
    super();
    this.connection = connection;
    this.indexedDB = indexedDB;
    this.dbName = dbName;
    this.error = null;
    connection.onabort = (event) => {
      // eslint-disable-next-line no-console
      console.warn("connection abort");
      // eslint-disable-next-line no-console
      console.warn(event);
      this.error = new Error(event.type);
    };
    connection.onclose = (event) => {
      // eslint-disable-next-line no-console
      console.warn("connection close");
      // eslint-disable-next-line no-console
      console.warn(event);
      this.error = new Error(event.type);
    };
    connection.onerror = (event) => {
      // eslint-disable-next-line no-console
      console.warn("connection error");
      // eslint-disable-next-line no-console
      console.warn(event);
      this.error = new Error(event.type);
    };
  }
  private pendingTransactions: IDBTransaction[] = [];
  private setPending(transaction: IDBTransaction): void {
    // Safari workaround; apparently, transactions may be lost without
    // callbacks called unless some reference prevents GC from taking them...
    // https://bugs.webkit.org/show_bug.cgi?id=186180
    this.pendingTransactions.push(transaction);
  }
  private clearPending(transaction: IDBTransaction): void {
    const idx = this.pendingTransactions.indexOf(transaction);
    this.pendingTransactions.splice(idx, 1);
  }
  fetchInstance(storeName: string, url: string): Promise<ResourceInstance> {
    if (this.error) {
      return Promise.reject(this.error);
    }
    return new Promise((resolve, reject) => {
      // console.log("fetchInstance start");
      const transaction = this.connection.transaction(storeName);
      this.setPending(transaction);
      let instance: ListResourceInstance | null = null;
      transaction.oncomplete = () => {
        // console.log("fetchInstance complete");
        this.clearPending(transaction);
        if (instance) {
          delete instance.__dbListURL;
          resolve(instance);
        } else {
          reject(new Error(`no instance for ${url}`));
        }
      };
      transaction.onabort = (event) => {
        // eslint-disable-next-line no-console
        console.warn("fetchInstance abort");
        // eslint-disable-next-line no-console
        console.warn(event);
        this.clearPending(transaction);
        reject(new Error(event.type));
      };
      transaction.onerror = (event) => {
        // eslint-disable-next-line no-console
        console.warn("fetchInstance error");
        // eslint-disable-next-line no-console
        console.warn(event);
        this.clearPending(transaction);
        reject(new Error(event.type));
      };
      const objectStore = transaction.objectStore(storeName);
      const request = objectStore.get(url);
      request.onsuccess = (event) => {
        instance = (event.target as any).result as ListResourceInstance;
      };
    });
  }
  replaceInstance(storeName: string, instance: ResourceInstance): Promise<void> {
    if (this.error) {
      return Promise.reject(this.error);
    }
    const toPut: ListResourceInstance = _.cloneDeep(instance);
    toPut.__dbListURL = getListURL(instance.url);
    return new Promise((resolve, reject) => {
      // console.log("replaceInstance start");
      const transaction = this.connection.transaction(storeName, "readwrite");
      this.setPending(transaction);
      transaction.oncomplete = () => {
        // console.log("replaceInstance complete");
        this.clearPending(transaction);
        resolve();
      };
      transaction.onerror = (event) => {
        this.clearPending(transaction);
        reject(new Error(event.type));
      };
      transaction.onabort = (event) => {
        this.clearPending(transaction);
        reject(new Error(event.type));
      };
      const objectStore = transaction.objectStore(storeName);
      objectStore.put(toPut);
    }) as Promise<void>;
  }
  removeInstance(storeName: string, url: string): Promise<void> {
    if (this.error) {
      return Promise.reject(this.error);
    }
    return new Promise((resolve, reject) => {
      // console.log("removeInstance start");
      const transaction = this.connection.transaction(storeName, "readwrite");
      this.setPending(transaction);
      transaction.oncomplete = () => {
        // console.log("removeInstance complete");
        this.clearPending(transaction);
        resolve();
      };
      transaction.onerror = (event) => {
        this.clearPending(transaction);
        reject(new Error(event.type));
      };
      transaction.onabort = (event) => {
        this.clearPending(transaction);
        reject(new Error(event.type));
      };
      const objectStore = transaction.objectStore(storeName);
      objectStore.delete(url);
    }) as Promise<void>;
  }
  fetchAll(storeName: string): Promise<ResourceInstance[]> {
    if (this.error) {
      return Promise.reject(this.error);
    }
    return new Promise((resolve, reject) => {
      // console.log("fetchAll start");
      const transaction = this.connection.transaction(storeName);
      this.setPending(transaction);
      const result: ResourceInstance[] = [];
      transaction.oncomplete = () => {
        // console.log("fetchAll complete");
        this.clearPending(transaction);
        resolve(result);
      };
      transaction.onerror = (event) => {
        this.clearPending(transaction);
        reject(new Error(event.type));
      };
      transaction.onabort = (event) => {
        this.clearPending(transaction);
        reject(new Error(event.type));
      };
      const objectStore = transaction.objectStore(storeName);
      if ("getAll" in objectStore) {
        ((objectStore as IDBObjectStore).getAll() as IDBRequest).onsuccess = (event) => {
          const entryArray = (event.target as any).result as any[];
          for (let i = 0, len = entryArray.length; i < len; i += 1) {
            const instance = entryArray[i];
            delete instance.__dbListURL;
            result.push(instance);
          }
        };
      } else {
        (objectStore as IDBObjectStore).openCursor().onsuccess = (event) => {
          const cursor = (event.target as any).result as IDBCursorWithValue;
          if (cursor) {
            const instance = cursor.value;
            delete instance.__dbListURL;
            result.push(instance);
            cursor.continue();
          }
        };
      }
    });
  }
  close(): Promise<void> {
    if (this.error) {
      return Promise.reject(this.error);
    }
    this.connection.close();
    return Promise.resolve(undefined);
  }
  deleteDatabase(): Promise<void> {
    return new Promise((resolve, reject) => {
      const request = this.indexedDB.deleteDatabase(this.dbName);
      request.onsuccess = (_event) => {
        resolve();
      };
      request.onerror = (event) => {
        reject(new Error(event.type));
      };
    });
  }
}

export const getIndexedDBConnection = (
  indexedDB: IDBFactory,
  dbName: string,
  storeNames: string[],
): Promise<DBConnection> =>
  // Workaround for Safari 14 IndexedDB
  idbReady()
    .then(() => openConnectionAndCreateTables(indexedDB, dbName, storeNames))
    .then((connection) => new IndexedDBDBConnection(connection, indexedDB, dbName));
