import {Task, urlToId} from "@co-common-libs/resources";
import {dateFromString, formatDate} from "@co-common-libs/utils";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {
  actions,
  getCurrentUserURL,
  getRouteTaskArray,
  getTaskLookup,
} from "@co-frontend-libs/redux";
import {DialogContent} from "@material-ui/core";
import React, {useCallback} from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

export const TaskWarningDialogs = ({
  oldTaskTimerStart,
  oldTimerTimerStart,
  onActiveRouteWarningDialogCancel,
  onOldTaskDialogCancel,
  onOldTaskDialogOk,
  onOtherDateDialogCancel,
  onOtherDateDialogOk,
  onStopTimerDialogCancel,
  onStopTimerDialogOk,
  requestedActiveTimer,
  task,
}: {
  oldTaskTimerStart: boolean;
  oldTimerTimerStart: boolean;
  onActiveRouteWarningDialogCancel: () => void;
  onOldTaskDialogCancel: () => void;
  onOldTaskDialogOk: () => void;
  onOtherDateDialogCancel: () => void;
  onOtherDateDialogOk: () => void;
  onStopTimerDialogCancel: () => void;
  onStopTimerDialogOk: () => void;
  requestedActiveTimer: boolean;
  task?: Task;
}): JSX.Element => {
  const routeTaskArray = useSelector(getRouteTaskArray);
  const taskLookup = useSelector(getTaskLookup);
  const currentMachineOperatorURL = useSelector(getCurrentUserURL);
  const currentRouteTaskInstance = routeTaskArray.find((routeTask) => {
    if (!routeTask.started || routeTask.completed) {
      return false;
    }
    const instance = taskLookup(routeTask.route);
    return !!instance && instance.machineOperator === currentMachineOperatorURL;
  });
  const currentRouteTask = currentRouteTaskInstance?.route || null;

  const dispatch = useDispatch();
  const handleOk = useCallback(() => {
    if (!currentRouteTask) {
      return;
    }
    dispatch(actions.go("/task/:id", {id: urlToId(currentRouteTask)}, {tab: "route"}));
  }, [currentRouteTask, dispatch]);

  const activeRouteTaskDialogOpen =
    !!currentRouteTask && requestedActiveTimer && currentRouteTask !== task?.url;
  const stopTimerDialogOpen =
    !activeRouteTaskDialogOpen && oldTimerTimerStart && requestedActiveTimer;
  const oldTaskDialogOpen =
    !activeRouteTaskDialogOpen && !stopTimerDialogOpen && oldTaskTimerStart && requestedActiveTimer;
  const otherDateDialogOpen =
    !activeRouteTaskDialogOpen && !oldTimerTimerStart && !oldTaskTimerStart && requestedActiveTimer;
  return (
    <>
      <ResponsiveDialog
        okLabel={
          <FormattedMessage
            defaultMessage="Gå til opgaven"
            id="active-route-task-warning-dialog.go-to-task"
          />
        }
        open={activeRouteTaskDialogOpen}
        title={
          <FormattedMessage
            defaultMessage="Du har en igangværende ruteopgave"
            id="active-route-task-warning-dialog.active-route-task"
          />
        }
        onCancel={onActiveRouteWarningDialogCancel}
        onOk={handleOk}
      >
        <DialogContent>
          <FormattedMessage
            defaultMessage="Du har en igangværende ruteopgave. Du kan ikke starte en ny før den er afsluttet"
            id="active-route-task-warning-dialog.warning-message"
          />
        </DialogContent>
      </ResponsiveDialog>
      <ResponsiveDialog
        okLabel={<FormattedMessage defaultMessage="OK" id="active-route-task-warning-dialog.ok" />}
        open={stopTimerDialogOpen}
        title={
          <FormattedMessage
            defaultMessage="Afbryd anden opgave?"
            id="active-route-task-warning-dialog.cancel-other-task"
          />
        }
        onCancel={onStopTimerDialogCancel}
        onOk={onStopTimerDialogOk}
      >
        <DialogContent>
          <FormattedMessage
            defaultMessage="Du måler allerede tid på en anden opgave. Stop den gamle opgave og fortsæt?"
            id="task-instance.label.cancel-other-task-message"
          />
        </DialogContent>
      </ResponsiveDialog>
      <ResponsiveDialog
        cancelLabel={
          <FormattedMessage
            defaultMessage="Gå til gammel opgave"
            id="task-instance.label.go-to-old-task"
          />
        }
        open={oldTaskDialogOpen}
        title={<FormattedMessage defaultMessage="Gammel opgave ikke afsluttet" />}
        onCancel={onOldTaskDialogCancel}
        onOk={onOldTaskDialogOk}
      >
        <DialogContent>
          <FormattedMessage defaultMessage="Du har en gammel ikke fuldført opgave. Start opgaven alligevel?" />
        </DialogContent>
      </ResponsiveDialog>
      <ResponsiveDialog
        open={otherDateDialogOpen}
        title={
          <FormattedMessage
            defaultMessage="Opgaven er planlagt til anden dato"
            id="active-route-task-warning-dialog.task-planned-on-other-date"
          />
        }
        onCancel={onOtherDateDialogCancel}
        onOk={onOtherDateDialogOk}
      >
        <DialogContent>
          <FormattedMessage
            defaultMessage="Opgaven er planlagt til {plannedDate}, i dag er {todayDate}. Start opgaven alligevel?"
            id="task-instance.label.wrong-date-start-anyway"
            values={{
              plannedDate: task?.date && formatDate(dateFromString(task.date)),
              todayDate: formatDate(new Date()),
            }}
          />
        </DialogContent>
      </ResponsiveDialog>
    </>
  );
};
