import {UserUrl} from "@co-common-libs/resources";
import {UserDialog, useModal} from "@co-frontend-libs/components";
import {getUserArray, getUserUserProfileLookup} from "@co-frontend-libs/redux";
import {Button, IconButton, Typography} from "@material-ui/core";
import CloseIcon from "mdi-react/CloseIcon";
import React, {Ref, forwardRef, useCallback} from "react";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {InformationBlockPropsBase} from "./information-block-props-base";
import {UserInfo} from "./user-info";

interface UserBlockProps extends InformationBlockPropsBase {
  onUserChanged: (userUrl: UserUrl | null) => void;
  userUrl: UserUrl | null;
}

export const UserBlock = forwardRef(function UserBlock(
  {clearDisabled, hideButtons, onUserChanged, selectDisabled, userUrl}: UserBlockProps,
  selectButtonRef: Ref<HTMLButtonElement>,
): React.JSX.Element | null {
  const userProfileLookup = useSelector(getUserUserProfileLookup);
  const userProfile = userUrl && userProfileLookup(userUrl);

  const [modal, prompt] = useModal(UserDialog);

  const userArray = useSelector(getUserArray);

  const selectUserClicked = useCallback(async () => {
    const selectedContact = await prompt({userArray});
    if (selectedContact) {
      onUserChanged(selectedContact);
    }
  }, [userArray, prompt, onUserChanged]);

  const clearUserClicked = useCallback(() => {
    onUserChanged(null);
  }, [onUserChanged]);

  if (!userArray) {
    return null;
  }

  return (
    <>
      <Typography variant="body1">
        <FormattedMessage defaultMessage="Ansvarlig, intern" />
      </Typography>
      {!hideButtons && (
        <>
          <Button
            ref={selectButtonRef}
            color="secondary"
            disabled={selectDisabled}
            variant="contained"
            onClick={selectUserClicked}
          >
            <FormattedMessage defaultMessage="Vælg" />
          </Button>
          <IconButton disabled={clearDisabled} onClick={clearUserClicked}>
            <CloseIcon />
          </IconButton>
        </>
      )}
      {userProfile && <UserInfo user={userProfile} />}
      {modal}
    </>
  );
});
