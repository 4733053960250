import {HALF_MINUTE_MILLISECONDS} from "@co-common-libs/utils";
import {blue, common as commonColors, grey} from "@material-ui/core/colors";
import {useLastKnownPosition} from "app-utils";
import React, {useMemo} from "react";
import {MapMarker} from "../map-marker";

const iconBaseSize = 24;

interface CurrentLocationMarkerProps {
  map: google.maps.Map;
}

export const CurrentLocationMarker = React.memo(function CurrentLocationMarker(
  props: CurrentLocationMarkerProps,
): JSX.Element | null {
  const {map} = props;
  const lastKnownPosition = useLastKnownPosition();

  const now = new Date();

  const fillColor =
    lastKnownPosition &&
    now.getTime() <= new Date(lastKnownPosition.timestamp).getTime() + HALF_MINUTE_MILLISECONDS
      ? blue[500]
      : grey[500];

  const anchor = useMemo(() => new google.maps.Point(iconBaseSize / 2, iconBaseSize / 2), []);

  const icon = useMemo(
    (): google.maps.Symbol => ({
      anchor,
      fillColor,
      fillOpacity: 1,
      path: "M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z",
      scale: 0.8,
      strokeColor: commonColors.white,
      strokeOpacity: 1,
      strokeWeight: 1,
    }),
    [anchor, fillColor],
  );

  if (lastKnownPosition) {
    const {lat, lng} = lastKnownPosition;
    return <MapMarker icon={icon} map={map} position={{lat, lng}} />;
  } else {
    return null;
  }
});
