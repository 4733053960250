import {TimerUrl} from "@co-common-libs/resources";
import {dateAndTimeToTimestamp, dateFromString} from "@co-common-libs/utils";
import {PunchWorkPeriod} from "@co-frontend-libs/redux";

export function dateFromDateAndTime(dateString: string, timeString: string): Date {
  const date = dateFromString(dateString) as Date;
  console.assert(date);
  const [hours, minutes] = timeString.split(":");
  date.setHours(parseInt(hours), parseInt(minutes));
  return date;
}

export interface SelectionCheckParam {
  fromDate: string | null;
  fromTime: string | null;
  legalIntervals: readonly PunchWorkPeriod[] | undefined;
  timerUrl: TimerUrl | null | undefined;
  toDate: string | null;
  toTime: string | null;
}

export function selectionCheck(param: SelectionCheckParam): {
  formValid: boolean;
  fromAfterOrEqualToToDateTime: boolean;
  punchedInAtFrom: boolean | null;
  punchedInAtTo: boolean | null;
} {
  const {fromDate, fromTime, legalIntervals, timerUrl, toDate, toTime} = param;
  const fromAfterOrEqualToToDateTime =
    !!fromDate &&
    !!fromTime &&
    !!toDate &&
    !!toTime &&
    dateFromDateAndTime(fromDate, fromTime) >= dateFromDateAndTime(toDate, toTime);
  if (!fromDate || !fromTime || !toDate || !toTime || fromAfterOrEqualToToDateTime) {
    return {
      formValid: false,
      fromAfterOrEqualToToDateTime,
      punchedInAtFrom: null,
      punchedInAtTo: null,
    };
  } else if (timerUrl && legalIntervals) {
    let isFormValid = false;
    let isPunchedInAtFrom = false;
    let isPunchedInAtTo = false;
    const correctedFromTimestamp = dateAndTimeToTimestamp(fromDate, fromTime);
    const correctedToTimestamp = dateAndTimeToTimestamp(toDate, toTime);
    for (let i = 0; i < legalIntervals.length; i += 1) {
      const interval = legalIntervals[i];
      const {punchIn} = interval;
      let {punchOut} = interval;
      if (!punchOut) {
        punchOut = new Date().toISOString();
      }
      if (correctedFromTimestamp >= punchIn && correctedToTimestamp <= punchOut) {
        isFormValid = true;
        isPunchedInAtFrom = true;
        isPunchedInAtTo = true;
        break;
      }
      if (correctedFromTimestamp >= punchIn && correctedFromTimestamp <= punchOut) {
        isPunchedInAtFrom = true;
      }
      if (correctedToTimestamp >= punchIn && correctedToTimestamp <= punchOut) {
        isPunchedInAtTo = true;
      }
    }
    return {
      formValid: isFormValid,
      fromAfterOrEqualToToDateTime,
      punchedInAtFrom: isPunchedInAtFrom,
      punchedInAtTo: isPunchedInAtTo,
    };
  } else {
    return {
      formValid: true,
      fromAfterOrEqualToToDateTime,
      punchedInAtFrom: null,
      punchedInAtTo: null,
    };
  }
}
