import {List, ListItem, ListItemIcon, ListItemText, Popover, PopoverProps} from "@material-ui/core";
import SquareIcon from "mdi-react/SquareIcon";
import React from "react";
import {FormattedMessage} from "react-intl";

interface ColorListPopoverProps {
  anchorEl?: Element | ((element: Element) => Element) | null;
  colorMap: ReadonlyMap<string, string>;
  onClose: () => void;
  open: boolean;
}

export const ColorListPopover = React.memo(function ColorListPopover(
  props: ColorListPopoverProps,
): JSX.Element {
  const {anchorEl, colorMap, onClose, open} = props;
  const colorListItems: JSX.Element[] = [];
  colorMap.forEach((value, key) =>
    colorListItems.push(
      <ListItem key={key}>
        <ListItemIcon>
          <SquareIcon color={value} />
        </ListItemIcon>
        <ListItemText
          primary={
            key ? (
              key
            ) : (
              <FormattedMessage defaultMessage="Ikke angivet" id="field-dialog.label.na" />
            )
          }
        />
      </ListItem>,
    ),
  );
  const optionalAnchorProp: Partial<PopoverProps> = {};
  if (anchorEl) {
    optionalAnchorProp.anchorEl = anchorEl;
  }
  return (
    <Popover
      {...optionalAnchorProp}
      anchorOrigin={{horizontal: "left", vertical: "top"}}
      open={open}
      transformOrigin={{horizontal: "left", vertical: "top"}}
      onClose={onClose}
    >
      <List>{colorListItems}</List>
    </Popover>
  );
});
