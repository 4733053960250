import {settings} from "@co-common-libs/config";
import {IntegerField, ResponsiveDialog} from "@co-frontend-libs/components";
import {actions, getCurrentUserURL, getSettingEntryArray} from "@co-frontend-libs/redux";
import {DialogContent} from "@material-ui/core";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {ErrorsBlock} from "../errors-block";
import {SettingDialogProps} from "../settings-view/setting-dialog";
import {checkConflictsWith, checkRequiresAllOf, checkRequiresOneOf, getValidator} from "../utils";

export function MachineNumberOffsetDialog(props: SettingDialogProps): JSX.Element | null {
  const {nonBlankSettings, onClose, open, settingID} = props;
  const intl = useIntl();
  const settingEntryArray = useSelector(getSettingEntryArray);
  const settingEntry = useMemo(
    () => settingEntryArray.find((entry) => entry.key === settingID),
    [settingEntryArray, settingID],
  );

  const currentUserURL = useSelector(getCurrentUserURL);
  const settingMetaData = settings[settingID];

  const {schema} = settingMetaData;

  const savedValue: number = settingEntry?.data || settingMetaData.defaultValue;

  const [value, setValue] = useState<number | null>(savedValue);

  useEffect(() => {
    if (open) {
      setValue(savedValue);
    }
  }, [open, savedValue]);

  const dispatch = useDispatch();

  const handleSave = useCallback(() => {
    if (value !== undefined) {
      if (settingEntry) {
        dispatch(
          actions.update(settingEntry.url, [
            {member: "changedBy", value: currentUserURL},
            {member: "data", value},
          ]),
        );
      }
    }
    onClose();
  }, [currentUserURL, dispatch, value, onClose, settingEntry]);

  const validator = useMemo(() => (schema ? getValidator(schema) : null), [schema]);
  const schemaErrors = useMemo(() => (validator ? validator(value) : null), [validator, value]);

  const {description} = settingMetaData;

  const conflictsWithIssue = checkConflictsWith(settingMetaData, nonBlankSettings, value);
  const requiresAllOfIssue = checkRequiresAllOf(settingMetaData, nonBlankSettings, value);
  const requiresOneOfIssue = checkRequiresOneOf(settingMetaData, nonBlankSettings, value);

  return (
    <ResponsiveDialog
      fullWidth
      okDisabled={
        !!schemaErrors ||
        !!conflictsWithIssue ||
        !!requiresAllOfIssue ||
        !!requiresOneOfIssue ||
        savedValue === null
      }
      okLabel={intl.formatMessage({
        defaultMessage: "Gem",
      })}
      open={open}
      title={description}
      onCancel={onClose}
      onOk={handleSave}
    >
      <DialogContent>
        <IntegerField
          fullWidth
          inputProps={{
            maxLength: 6,
          }}
          label={intl.formatMessage({
            defaultMessage: "Offset",
          })}
          value={savedValue}
          onChange={setValue}
        />
        <ErrorsBlock
          nonBlankSettings={nonBlankSettings}
          settingMetaData={settingMetaData}
          value={value}
        />
      </DialogContent>
    </ResponsiveDialog>
  );
}
