import {
  configureStore,
  createResourceMiddleware,
  rootReducer,
  routingMiddleware,
} from "@co-frontend-libs/redux";
import type {AnyAction, MiddlewareArray} from "@reduxjs/toolkit";
import {ThunkMiddlewareFor} from "@reduxjs/toolkit/dist/getDefaultMiddleware";
import {globalConfig} from "./global";

const {baseURL} = globalConfig.resources;

export type RootState = ReturnType<typeof rootReducer>;

type MiddlewareType = MiddlewareArray<
  [
    ThunkMiddlewareFor<RootState>,
    ReturnType<typeof createResourceMiddleware>,
    typeof routingMiddleware,
  ]
>;

export const store = configureStore<RootState, AnyAction, MiddlewareType>({
  devTools: {
    stateSanitizer: (state) => ({
      ...state,
      resources: {
        ...(state as any).resources,
        persistedData: Object.fromEntries(
          Object.entries((state as any).resources.persistedData).map(([key, data]) => [
            key,
            `<<${Object.keys(data as any).length}>>`,
          ]),
        ),
        temporaryData: Object.fromEntries(
          Object.entries((state as any).resources.temporaryData).map(([key, data]) => [
            key,
            `<<${Object.keys(data as any).length}>>`,
          ]),
        ),
      },
    }),
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(createResourceMiddleware(baseURL), routingMiddleware),
  reducer: rootReducer,
});

export type AppDispatch = ReturnType<typeof configureStore>["dispatch"];
// export const useAppDispatch = () => useDispatch<AppDispatch>()
