import {formatDateTimeShort} from "@co-common-libs/utils";
import {IconButton} from "@material-ui/core";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import DeleteIcon from "mdi-react/DeleteIcon";
import React from "react";

interface TimeCorrectionRowProps {
  canDelete: boolean;
  fromTimestamp: string;
  onDelete: (fromTimestamp: string, toTimestamp: string, timerURL: string | null) => void;
  timerLabel: string;
  timerURL: string | null;
  toTimestamp: string;
}

export class TimeCorrectionRow extends PureComponent<TimeCorrectionRowProps> {
  @bind
  handleDeleteClick(): void {
    const {fromTimestamp, onDelete, timerURL, toTimestamp} = this.props;
    onDelete(fromTimestamp, toTimestamp, timerURL);
  }

  render(): JSX.Element {
    const {canDelete, fromTimestamp, timerLabel, toTimestamp} = this.props;

    return (
      <div>
        {timerLabel}: {formatDateTimeShort(fromTimestamp)} – {formatDateTimeShort(toTimestamp)}
        {canDelete ? (
          <IconButton onClick={this.handleDeleteClick}>
            <DeleteIcon />
          </IconButton>
        ) : null}
      </div>
    );
  }
}
