import type {SerializedImage} from "../other-types";
import {TaskPhotoUrl, TaskUrl, UserUrl} from "../resource-urls";
import {ResourceInstance} from "./base";

interface TaskPhotoBase extends ResourceInstance<TaskPhotoUrl> {
  readonly createdBy: UserUrl;
  readonly note: string;
  readonly taken: string;
  readonly task: TaskUrl;
}

export interface OnlineTaskPhoto extends TaskPhotoBase {
  readonly displayDownload: string;
  readonly displayHeight: number;
  readonly displayWidth: number;
  readonly fullsizeDownload: string;
  readonly originalHeight: number;
  readonly originalWidth: number;
  readonly thumbnailDownload: string;
  readonly thumbnailHeight: number;
  readonly thumbnailWidth: number;
}

export interface LocalTaskPhoto extends TaskPhotoBase {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly _file_original: SerializedImage;
}

export type TaskPhoto = LocalTaskPhoto | OnlineTaskPhoto;

export function isOnlineTaskPhoto(photo: TaskPhoto): photo is OnlineTaskPhoto {
  return !(photo as any)._file_original;
}
