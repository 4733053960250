import {
  KrPerLiterFuelSurchargeSpecificationUrl,
  KrPerLiterWorkTypeFuelSurchargeUse,
  WorkType,
} from "@co-common-libs/resources";
import {ConnectedKrPerLiterFuelSurchargeSpecificationDialog} from "@co-frontend-libs/connected-components";
import {
  actions,
  getKrPerLiterFuelSurchargeSpecificationLookup,
  getKrPerLiterWorkTypeFuelSurchargeUseArray,
} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {Button, IconButton} from "@material-ui/core";
import {instanceURL} from "frontend-global-config";
import CloseIcon from "mdi-react/CloseIcon";
import React, {useCallback, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuid} from "uuid";

interface WorkTypeKrPerLiterFuelSurchargeBlockProps {
  workType: WorkType;
}

export const WorkTypeKrPerLiterFuelSurchargeBlock = React.memo(
  function WorkTypeKrPerLiterFuelSurchargeBlock(
    props: WorkTypeKrPerLiterFuelSurchargeBlockProps,
  ): JSX.Element {
    const {workType} = props;
    const workTypeUrl = workType.url;

    const intl = useIntl();

    const krPerLiterFuelSurchargeSpecificationLookup = useSelector(
      getKrPerLiterFuelSurchargeSpecificationLookup,
    );
    const krPerLiterWorkTypeFuelSurchargeUseArray = useSelector(
      getKrPerLiterWorkTypeFuelSurchargeUseArray,
    );

    const dispatch = useDispatch();

    const currentUse = krPerLiterWorkTypeFuelSurchargeUseArray.find(
      (entry) =>
        entry.workType === workTypeUrl && entry.variant === null && entry.customer === null,
    );

    const handleClearButton = useCallback(() => {
      if (currentUse) {
        dispatch(actions.remove(currentUse.url));
      }
    }, [currentUse, dispatch]);

    const [fuelSurchargeDialogOpen, setFuelSurchargeDialogOpen] = useState(false);
    const setFuelSurchargeDialogOpenTrue = useCallWithTrue(setFuelSurchargeDialogOpen);
    const setFuelSurchargeDialogOpenFalse = useCallWithFalse(setFuelSurchargeDialogOpen);

    const handleFuelSurchargeDialogOk = useCallback(
      (fuelSurchargeUrl?: KrPerLiterFuelSurchargeSpecificationUrl): void => {
        setFuelSurchargeDialogOpen(false);
        const value = fuelSurchargeUrl ?? null;
        if (currentUse && value === currentUse.fuelSurcharge) {
          return;
        } else if (currentUse) {
          dispatch(actions.remove(currentUse.url));
        }
        const id = uuid();
        const url = instanceURL("krPerLiterWorkTypeFuelSurchargeUse", id);
        const instance: KrPerLiterWorkTypeFuelSurchargeUse = {
          customer: null,
          fuelSurcharge: value,
          id,
          url,
          variant: null,
          workType: workTypeUrl,
        };
        dispatch(actions.create(instance));
      },
      [currentUse, dispatch, workTypeUrl],
    );

    let text: string;
    if (currentUse) {
      if (!currentUse.fuelSurcharge) {
        text = intl.formatMessage({
          defaultMessage: "Ingen brændstoftillæg for område",
        });
      } else {
        const fuelSurcharge = krPerLiterFuelSurchargeSpecificationLookup(currentUse.fuelSurcharge);
        if (fuelSurcharge) {
          text = fuelSurcharge.name;
        } else {
          text = intl.formatMessage({defaultMessage: "Opslag fejlet"});
        }
      }
    } else {
      text = "";
    }

    return (
      <>
        <div>
          <FormattedMessage defaultMessage="Brændstoftillæg" tagName="h4" />
          <Button color="primary" variant="contained" onClick={setFuelSurchargeDialogOpenTrue}>
            <FormattedMessage defaultMessage="Vælg" />
          </Button>
          <br />
          {text}
          {currentUse ? (
            <IconButton onClick={handleClearButton}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </div>
        <ConnectedKrPerLiterFuelSurchargeSpecificationDialog
          open={fuelSurchargeDialogOpen}
          onCancel={setFuelSurchargeDialogOpenFalse}
          onNone={handleFuelSurchargeDialogOk}
          onOk={handleFuelSurchargeDialogOk}
        />
      </>
    );
  },
);
