import {ListItemIcon, MenuItem, Typography} from "@material-ui/core";
import ThumbsUpDownIcon from "mdi-react/ThumbsUpDownIcon";
import React from "react";
import {FormattedMessage} from "react-intl";

interface AvailabilityMenuItemProps {
  onClick: () => void;
}

export function AvailabilityMenuItem(props: AvailabilityMenuItemProps): JSX.Element {
  const {onClick} = props;
  return (
    <MenuItem key={"availability"} onClick={onClick}>
      <ListItemIcon>
        <ThumbsUpDownIcon fontSize="small" />
      </ListItemIcon>
      <Typography variant="inherit">
        <FormattedMessage defaultMessage="Rådighed" />
      </Typography>
    </MenuItem>
  );
}
