import {
  CsvImportSpecification,
  getAvailableColumns,
} from "@co-common-libs/csv-import-specifications";
import {getCustomerSettings} from "@co-frontend-libs/redux";
import {TableCell, TableRow} from "@material-ui/core";
import React from "react";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {ImportColumnConfiguration} from "../types";
import {getColumnConfiguration} from "./get-column-configuration";
import {UpdateExistingRowCheckbox} from "./update-existing-row-checkbox";

export function UpdateExistingRow(props: {
  handleImportColumnConfigurationChange: (
    columnName: string,
    configurationChange: {updateExisting: boolean},
  ) => void;
  importColumnConfigurations: ReadonlyMap<string, ImportColumnConfiguration>;
  selectedImportSpecification: CsvImportSpecification;
}): JSX.Element {
  const {
    handleImportColumnConfigurationChange,
    importColumnConfigurations,
    selectedImportSpecification,
  } = props;

  const customerSettings = useSelector(getCustomerSettings);

  return (
    <TableRow>
      <TableCell>
        <FormattedMessage defaultMessage="Opdatér eksisterende:" />
      </TableCell>
      {getAvailableColumns(customerSettings, selectedImportSpecification).map((column, index) => {
        const configuration = getColumnConfiguration(importColumnConfigurations, column.name);
        if (configuration) {
          const {updateExisting, valueType} = configuration;
          const disabled = valueType === "none";
          return (
            <TableCell key={column.name} align="center">
              {index !== 0 ? (
                <UpdateExistingRowCheckbox
                  columnName={column.name}
                  disabled={disabled}
                  isChecked={updateExisting}
                  onImportColumnConfigurationChange={handleImportColumnConfigurationChange}
                />
              ) : null}
            </TableCell>
          );
        } else {
          return null;
        }
      })}
      <TableCell />
    </TableRow>
  );
}
