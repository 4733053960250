import {MenuToolbar, PageLayout} from "app-components";
import React from "react";
import {useIntl} from "react-intl";
import {FoodBookings} from "./food-bookings";

interface FoodBookingSettingsPageProps {
  onMenuButton: (event: React.MouseEvent) => void;
}

export const FoodBookingSettingsPage = React.memo(function FoodBookingSettingsPage(
  props: FoodBookingSettingsPageProps,
): JSX.Element {
  const intl = useIntl();

  return (
    <PageLayout
      withBottomScrollPadding
      toolbar={
        <MenuToolbar
          title={intl.formatMessage({defaultMessage: "Madbestillinger"})}
          onMenuButton={props.onMenuButton}
        />
      }
    >
      <FoodBookings />
    </PageLayout>
  );
});
