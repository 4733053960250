import {IconButton} from "@material-ui/core";
import PencilIcon from "mdi-react/PencilIcon";
import React, {useCallback} from "react";
import {DragDropReorderIdentifierChips} from "../drag-drop-reorder-identifier-chips";
import {DeviceType} from "./types";

interface ColumnListEditProps<ColumnChoice extends string> {
  deviceType: DeviceType;
  identifiers: readonly ColumnChoice[];
  onChange: (reorderedIdentifiers: readonly ColumnChoice[], deviceType: DeviceType) => void;
  onEditClick: (deviceType: DeviceType) => void;
}

export function ColumnListEdit<ColumnChoice extends string>(
  props: ColumnListEditProps<ColumnChoice>,
): JSX.Element {
  const {deviceType, identifiers, onChange, onEditClick} = props;

  const handleEditClick = useCallback(() => {
    onEditClick(deviceType);
  }, [deviceType, onEditClick]);

  const handleChange = useCallback(
    (reorderedIdentifiers: readonly ColumnChoice[]): void => {
      onChange(reorderedIdentifiers, deviceType);
    },
    [deviceType, onChange],
  );

  return (
    <div>
      <DragDropReorderIdentifierChips
        identifiers={identifiers}
        itemType={deviceType}
        onChange={handleChange}
      />
      <IconButton onClick={handleEditClick}>
        <PencilIcon />
      </IconButton>
    </div>
  );
}
