import {ResponsiveDialog} from "@co-frontend-libs/components";
import {DialogContent} from "@material-ui/core";
import React from "react";
import {useIntl} from "react-intl";

export const RequestGPSPermissionDialog = React.memo(function RequestGPSPermissionDialog({
  onAllowGPS,
  onDisallowGPS,
  open,
}: {
  onAllowGPS: () => void;
  onDisallowGPS: () => void;
  open: boolean;
}): JSX.Element {
  const intl = useIntl();

  return (
    <ResponsiveDialog
      open={open}
      title={intl.formatMessage({defaultMessage: "Tillad GPS"})}
      onCancel={onDisallowGPS}
      onOk={onAllowGPS}
    >
      <DialogContent>
        <h3>CustomOffice har behov for adgang til GPS</h3>
        <p>
          Geolokation (GPS) anvendes af CustomOffice appen, også når appen er i baggrunden, til
          dokumentation og kvalitetssikring under udførsel af arbejde. Den mest anvendte funktion er
          registrering af position og tid ved start af arbejdet - og løbende gennem udførelse af
          arbejdsopgaven. Det er vigtigt at kunne dokumentere hvor og hvornår man har udført den
          specifikke arbejdsopgave.
        </p>
        <p>
          Kravet om anvendelse af geolokation, også når appen er i baggrunden, er opsat i
          CustomOffice efter aftale med jeres virksomhed.
        </p>
        <p>
          Vælger du GPS fra her, men senere ønsker at slå GPS til, så finder du muligheden for dette
          under din brugerprofil.
        </p>
      </DialogContent>
    </ResponsiveDialog>
  );
});
