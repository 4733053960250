import {createSelector} from "reselect";
import {AppState} from "../root-reducer";

export const getDeviceConfigAll = (state: AppState): {[key: string]: any} =>
  state.deviceConfig.data;

export const getDeviceConfigKey =
  (key: string): ((state: AppState) => any) =>
  (state) =>
    state.deviceConfig.data[key];

export function getTableSortingState(
  identifier: string,
  defaultKey: string | null = null,
  defaultDirection: "ASC" | "DESC" = "ASC",
): (state: AppState) => {
  readonly sortDirection: "ASC" | "DESC";
  readonly sortKey: string | null;
} {
  const key = `tableSort-${identifier}`;
  return createSelector(
    getDeviceConfigKey(key),
    (savedTableSortSetting) =>
      savedTableSortSetting || {
        sortDirection: defaultDirection,
        sortKey: defaultKey,
      },
  );
}

export function getTablePaginationState(
  identifier: string,
  defaultRowsPerPage: number,
): (state: AppState) => {
  readonly rowsPerPage: number;
} {
  const key = `tablePagination-${identifier}`;
  return createSelector(
    getDeviceConfigKey(key),
    (savedTablePaginationSetting) =>
      savedTablePaginationSetting || {
        rowsPerPage: defaultRowsPerPage,
      },
  );
}

export const getDeviceConfigLoaded = (state: AppState): boolean => state.deviceConfig.loaded;
