import {CustomerUrl} from "@co-common-libs/resources";
import {notNull, notUndefined, setRemove} from "@co-common-libs/utils";
import {MultipleCustomersDialog} from "@co-frontend-libs/components";
import {getContactArray, getCustomerLookup, getCustomerSettings} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {Button, useTheme} from "@material-ui/core";
import _ from "lodash";
import React, {useCallback, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {DeletableChip} from "../deletable-chip";

interface CustomerFilteringProps {
  buttonLabel: React.ReactNode;
  onSelectedCustomersChange: (urls: ReadonlySet<CustomerUrl>) => void;
  potentialCustomers: readonly (CustomerUrl | null)[];
  selectedCustomers: ReadonlySet<CustomerUrl>;
  title: React.ReactNode;
}

export function CustomerFiltering(props: CustomerFilteringProps): JSX.Element {
  const {buttonLabel, onSelectedCustomersChange, potentialCustomers, selectedCustomers, title} =
    props;

  const customerSettings = useSelector(getCustomerSettings);
  const customerLookup = useSelector(getCustomerLookup);
  const contactArray = useSelector(getContactArray);

  const [dialogOpen, setDialogOpen] = useState(false);
  const setDialogOpenTrue = useCallWithTrue(setDialogOpen);
  const setDialogOpenFalse = useCallWithFalse(setDialogOpen);

  const handleDialogOk = useCallback(
    (urls: ReadonlySet<CustomerUrl>) => {
      onSelectedCustomersChange(urls);
      setDialogOpen(false);
    },
    [onSelectedCustomersChange],
  );
  const potentialCustomerInstances = useMemo(
    () => potentialCustomers.filter(notNull).map(customerLookup).filter(notUndefined),
    [customerLookup, potentialCustomers],
  );

  const handleDelete = useCallback(
    (url: CustomerUrl): void => {
      onSelectedCustomersChange(setRemove(selectedCustomers, url));
    },
    [selectedCustomers, onSelectedCustomersChange],
  );

  const theme = useTheme();

  return (
    <>
      <div>
        <h4>{title}</h4>
        <Button color="primary" variant="contained" onClick={setDialogOpenTrue}>
          {buttonLabel}
        </Button>
        <div>
          {_.sortBy(
            Array.from(selectedCustomers).map(customerLookup).filter(notUndefined),
            (customer) => customer.name,
          ).map((customer) => (
            <span
              key={customer.url}
              style={{
                marginRight: theme.spacing(0.5),
              }}
            >
              <DeletableChip
                deletionId={customer.url}
                label={customer.name}
                onDelete={handleDelete}
              />
            </span>
          ))}
        </div>
      </div>
      <MultipleCustomersDialog
        contactArray={contactArray}
        customerArray={potentialCustomerInstances}
        customerSettings={customerSettings}
        includeSelectAll={false}
        open={dialogOpen}
        selected={selectedCustomers}
        onCancel={setDialogOpenFalse}
        onOk={handleDialogOk}
      />
    </>
  );
}
