import {ResourceInstance, ResourceName, resourceNames} from "@co-common-libs/resources";
import {Query, QueryTimestampsStruct, getOfflineDB} from "@co-frontend-libs/db-resources";
import _ from "lodash";
import {offlineDBError} from "../resources/actions";
import {ResourcesState} from "../resources/types";
import {partialEntriesForEach} from "../resources/utils";
import {ResourcesAuthenticationMiddlewareAPI} from "./types";

export async function updateOfflineDB(
  oldState: {resources: ResourcesState},
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
): Promise<void> {
  const newState = middlewareApi.getState();
  const oldQueries = oldState.resources.persistedQueries;
  const newQueries = newState.resources.persistedQueries;
  const oldData = oldState.resources.persistedData;
  const newData = newState.resources.persistedData;

  const dataDelete = new Set<string>();
  const dataMerge = new Map<string, ResourceInstance>();
  const queryAdd = new Set<Query>();
  const queryDelete = new Set<Query>();
  const queryTimestamps: QueryTimestampsStruct[] = [];

  if (newQueries !== oldQueries) {
    Object.entries(oldQueries).forEach(([keyString, queryQueryState]) => {
      if (!queryQueryState) {
        return;
      }
      const {query} = queryQueryState;
      console.assert(query.keyString === keyString);
      if (!newQueries[keyString]) {
        queryDelete.add(query);
      }
    });
    Object.entries(newQueries).forEach(([keyString, queryQueryState]) => {
      if (!queryQueryState) {
        return;
      }
      const {query, queryState} = queryQueryState;
      console.assert(query.keyString === keyString);
      if (!oldQueries[keyString]) {
        queryAdd.add(query);
      }
      const oldQueryState = oldQueries[keyString]?.queryState;
      const oldFullFetchDataComputedAtTimestamp =
        oldQueryState?.fullFetchDataComputedAtTimestamp || null;
      const oldTakenIntoAccountForChangesComputedAtTimestamp =
        oldQueryState?.takenIntoAccountForChangesComputedAtTimestamp || null;
      if (
        queryState.fullFetchDataComputedAtTimestamp !== oldFullFetchDataComputedAtTimestamp ||
        queryState.takenIntoAccountForChangesComputedAtTimestamp !==
          oldTakenIntoAccountForChangesComputedAtTimestamp
      ) {
        queryTimestamps.push({
          fullTimestamp: queryState.fullFetchDataComputedAtTimestamp,
          query,
          updateTimestamp: queryState.takenIntoAccountForChangesComputedAtTimestamp,
        });
      }
    });
  }
  if (newData !== oldData) {
    resourceNames.forEach((resourceName) => {
      const newDataForResource = newData[resourceName];
      const oldDataForResource = oldData[resourceName];
      if (newDataForResource === oldDataForResource) {
        return;
      }
      Object.keys(oldDataForResource).forEach((url) => {
        if (!newDataForResource[url]) {
          dataDelete.add(url);
        }
      });
      Object.entries(newDataForResource).forEach(([url, instance]: [string, ResourceInstance]) => {
        if (!_.isEqual(oldDataForResource[url], instance)) {
          dataMerge.set(url, instance);
        }
      });
    });
  }
  const offlineDB = await getOfflineDB();
  await offlineDB
    .update({
      dataDelete,
      dataMerge,
      queryAdd,
      queryDelete,
      queryTimestamps,
    })
    .catch((error) => {
      offlineDBError(error);
    });

  const idFetchDelete = new Map<ResourceName, Set<string>>();
  const idFetchMerge = new Map<ResourceName, Set<string>>();

  const oldFetchByID = oldState.resources.persistedFetchByID;
  const newFetchByID = newState.resources.persistedFetchByID;

  if (newFetchByID !== oldFetchByID) {
    resourceNames.forEach((resourceName) => {
      const oldResourceFetchByID = oldFetchByID[resourceName];
      const newResourceFetchByID = newFetchByID[resourceName];
      if (oldResourceFetchByID === newResourceFetchByID) {
        return;
      }
      const resourceIdFetchDelete = oldResourceFetchByID
        ? new Set(oldResourceFetchByID)
        : new Set<string>();
      const resourceIdFetchMerge = newResourceFetchByID
        ? new Set(newResourceFetchByID)
        : new Set<string>();

      resourceIdFetchDelete.forEach((value) => {
        if (resourceIdFetchMerge.has(value)) {
          resourceIdFetchDelete.delete(value);
          resourceIdFetchMerge.delete(value);
        }
      });
      if (resourceIdFetchDelete.size) {
        idFetchDelete.set(resourceName, resourceIdFetchDelete);
      }
      if (resourceIdFetchMerge.size) {
        idFetchMerge.set(resourceName, resourceIdFetchMerge);
      }
    });
  }

  const relatedFetchDelete = new Map<ResourceName, Map<string, Set<string>>>();
  const relatedFetchMerge = new Map<ResourceName, Map<string, Set<string>>>();

  const oldFetchByRelation = oldState.resources.persistedFetchByRelation;
  const newFetchByRelation = newState.resources.persistedFetchByRelation;
  if (newFetchByRelation !== oldFetchByRelation) {
    resourceNames.forEach((resourceName) => {
      const oldResourceFetchByRelation = oldFetchByRelation[resourceName];
      const newResourceFetchByRelation = newFetchByRelation[resourceName];
      if (oldResourceFetchByRelation === newResourceFetchByRelation) {
        return;
      }
      const resourceRelatedFetchDelete = new Map<string, Set<string>>();
      const resourceRelatedFetchMerge = new Map<string, Set<string>>();

      if (oldResourceFetchByRelation) {
        partialEntriesForEach(oldResourceFetchByRelation, (memberName, oldValues) => {
          console.assert(oldValues.length);
          const resourceMemberRelatedFetchDelete = new Set(oldValues);
          const newValues = newResourceFetchByRelation?.[memberName];
          if (newValues) {
            newValues.forEach((value) => resourceMemberRelatedFetchDelete.delete(value));
          }
          if (resourceMemberRelatedFetchDelete.size) {
            resourceRelatedFetchDelete.set(memberName, resourceMemberRelatedFetchDelete);
          }
        });
      }
      if (newResourceFetchByRelation) {
        partialEntriesForEach(newResourceFetchByRelation, (memberName, newValues) => {
          console.assert(newValues.length);
          const resourceMemberRelatedFetchMerge = new Set(newValues);
          const oldValues = oldResourceFetchByRelation?.[memberName];
          if (oldValues) {
            oldValues.forEach((value) => resourceMemberRelatedFetchMerge.delete(value));
          }
          if (resourceMemberRelatedFetchMerge.size) {
            resourceRelatedFetchMerge.set(memberName, resourceMemberRelatedFetchMerge);
          }
        });
      }
      if (resourceRelatedFetchDelete.size) {
        relatedFetchDelete.set(resourceName, resourceRelatedFetchDelete);
      }
      if (resourceRelatedFetchMerge.size) {
        relatedFetchMerge.set(resourceName, resourceRelatedFetchMerge);
      }
    });
  }

  await offlineDB
    .updateFetchBy({
      idFetchDelete,
      idFetchMerge,
      relatedFetchDelete,
      relatedFetchMerge,
    })
    .catch((error) => {
      offlineDBError(error);
    });
}
