import {ProductUrl} from "@co-common-libs/resources";
import _ from "lodash";

interface WithHas<K> {
  has(key: K): boolean;
}

interface ProductUsePart {
  correctedCount: unknown | null;
  notes: string;
  ours: boolean;
}

export function filterProductUsesFromLog<T extends ProductUsePart>(
  originalProductUses: readonly [string, T][],
  /** productUrl + \0 + ours .. */
  newProductUsesObserved: WithHas<string>,
  getEntryProductUrl: (entry: [string, T]) => ProductUrl,
): [string, T][] {
  // checking for "unnecessary" duplicates -- product may be present twice
  // due to ours/theirs variants; and just in case also if they hove notes as
  // we don't want to lose those...
  const groupedProductUses = _.groupBy(originalProductUses, getEntryProductUrl);
  const filteredProductUses = Object.entries(groupedProductUses).flatMap(
    ([productUrl, entries]): [string, T][] => {
      if (entries.length <= 1) {
        // only one entry for product; log logic will only remove duplicates
        return entries;
      }

      const oursKey = `${productUrl}\0${true}`;
      const theirsKey = `${productUrl}\0${false}`;
      const oursKeyPresent = newProductUsesObserved.has(oursKey);
      const theirsKeyPresent = newProductUsesObserved.has(theirsKey);

      if (oursKeyPresent && theirsKeyPresent && entries.length === 2) {
        // two entries present; two needed
        return entries;
      }

      const [kept, potentiallyKept] = _.partition(
        entries,
        ([_id, data]) => data.notes.trim().length > 0 || data.correctedCount != null,
      );
      if (kept.length > 1) {
        // several with notes and/or correctedCount; ours/theirs can't lead to more...
        return kept;
      }

      if (kept.length === 1) {
        if (!oursKeyPresent || !theirsKeyPresent) {
          // one with notes and/or correctedCount; and we don't have both "ours" and "theirs"
          return kept;
        }
        // one or both false handled above
        console.assert(oursKeyPresent && theirsKeyPresent);
        // total entries 1 and total entries 2 in combination with both present
        // already handled above
        console.assert(potentiallyKept.length > 1);
        const keptEntry = kept[0];
        const keptEntryOurs = keptEntry[1].ours;
        const otherOursMatch = potentiallyKept.find(([_id, data]) => data.ours !== keptEntryOurs);
        if (otherOursMatch) {
          // one ours, one theirs; one of them has notes/correctedCound...
          return [keptEntry, otherOursMatch];
        }
        // two entries; one of them has notes/correctedCound...
        return [keptEntry, potentiallyKept[0]];
      }

      // none kept from notes/correctedCount; need to find 1 or 2 from potentiallyKept (= entries)
      console.assert(kept.length === 0);
      console.assert(potentiallyKept.length === entries.length);
      // one entry handled at the start...
      console.assert(entries.length > 1);

      if (!oursKeyPresent && !theirsKeyPresent) {
        // not present on log, but keep one entry for product
        return [entries[0]];
      }

      const [oursEntries, theirsEntries] = _.partition(entries, ([_id, data]) => data.ours);

      if (oursKeyPresent && theirsKeyPresent) {
        if (oursEntries.length && theirsEntries.length) {
          // two entries; found matching on ours/theirs
          return [oursEntries[0], theirsEntries[0]];
        } else {
          // two entries; but not both ours and theirs found
          return [entries[0], entries[1]];
        }
      }
      // we've handled cases of neither and both
      console.assert((oursKeyPresent || theirsKeyPresent) && !(oursKeyPresent && theirsKeyPresent));
      if (oursKeyPresent && oursEntries) {
        console.assert(!theirsKeyPresent);
        return [oursEntries[0]];
      }
      if (theirsKeyPresent && theirsEntries) {
        console.assert(!oursKeyPresent);
        return [theirsEntries[0]];
      }
      // one entry; not matching
      return [entries[0]];
    },
  );

  return filteredProductUses;
}
