import {ImportPreviewWorkType} from "@co-common-libs/resources-utils";
import {EntryData} from "../../../search-dialog";

export function computePreviewWorkTypeBaseChoices(
  previewWorkTypes: readonly ImportPreviewWorkType[],
  remoteUrlFavorites?: ReadonlySet<string>,
): readonly EntryData<string>[] {
  return previewWorkTypes.map((instance) => {
    const {identifier, name} = instance;

    const entry: EntryData<string> = {
      category: remoteUrlFavorites?.has(instance.remoteUrl) ? "favorite" : "standard",
      exactMatchValue: identifier,
      identifier,
      primaryText: name,
      searchFields: [
        {label: "Nr", priority: 10, text: identifier},
        {label: "Navn", priority: 5, text: name},
      ],
      secondaryText: identifier,
    };
    return entry;
  });
}
