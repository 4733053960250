import {Button, DialogActions, DialogContent, List, ListItem, Typography} from "@material-ui/core";
import {TaskTableDataType} from "app-components";
import {useFalseCallback, useTrueCallback} from "app-utils";
import {theme} from "frontend-global-config";
import React from "react";
import {FormattedMessage, useIntl} from "react-intl";

interface ConfirmationDialogProps {
  onCancel: () => void;
  onOk: (result: boolean) => void;
  readonly tasks: readonly TaskTableDataType[];
}

export function DeleteConfirmationDialog({
  onOk,
  tasks = [],
}: ConfirmationDialogProps): React.JSX.Element {
  const intl = useIntl();

  return (
    <DialogContent>
      <Typography gutterBottom variant="h6">
        <FormattedMessage defaultMessage="Er du sikker du vil slette de valgte opgaver?" />
      </Typography>

      <Typography variant="body1">
        <FormattedMessage
          defaultMessage="Du er igang med at slette {count, plural, one {# opgave} other {# opgaver}}."
          values={{count: tasks.length}}
        />
      </Typography>

      <List>
        {tasks.map((task) => (
          <ListItem key={task.key}>
            {[task.workType, task.customer, task.date, task.machineOperatorName]
              .filter(Boolean)
              .join(", ")}
          </ListItem>
        ))}
      </List>

      <DialogActions>
        <Button color="primary" variant="text" onClick={useFalseCallback(onOk, [onOk])}>
          {intl.formatMessage({defaultMessage: "Fortryd"})}
        </Button>

        <Button
          style={{color: theme.palette.error.main}}
          variant="text"
          onClick={useTrueCallback(onOk, [onOk])}
        >
          {intl.formatMessage({defaultMessage: "Slet"})}
        </Button>
      </DialogActions>
    </DialogContent>
  );
}
