import {Task} from "@co-common-libs/resources";
import {Card, Paper} from "@material-ui/core";
import {RouteInvoiceLineTable} from "app-components";
import {PureComponent} from "app-utils";
import React from "react";
import {FormattedMessage} from "react-intl";

interface RouteInvoicingTabProps {
  completed: boolean;
  task: Task;
  validated: boolean;
}

export class RouteInvoicingTab extends PureComponent<RouteInvoicingTabProps> {
  render(): JSX.Element {
    const {completed, task, validated} = this.props;
    if (!completed) {
      return (
        <Paper style={{margin: 15, padding: 10}}>
          <h3>
            <FormattedMessage
              defaultMessage="Du kan først rette i faktureringslinjerne når opgaven er udført."
              id="route-invoicing-tab.label.not-completed"
            />
          </h3>
        </Paper>
      );
    }
    return (
      <Card style={{margin: "1em"}}>
        <RouteInvoiceLineTable readonly={validated} task={task} />
      </Card>
    );
  }
}
