import {Checkbox, Theme, withStyles} from "@material-ui/core";

export const WhiteCheckbox = withStyles((theme: Theme) => ({
  checked: {},
  root: {
    "&$checked": {
      color: theme.palette.common.white,
    },
    color: theme.palette.common.white,
  },
}))(Checkbox);
