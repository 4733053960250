import {ReportingInputSpecification} from "@co-common-libs/resources";
import {PureComponent} from "app-utils";
import React from "react";
import {ReportingValue} from "../reporting-value";

interface TaskDataTableProps {
  inputSpecifications: readonly ReportingInputSpecification[];
  values: any;
}

export class TaskDataTable extends PureComponent<TaskDataTableProps> {
  render(): JSX.Element {
    const {inputSpecifications, values} = this.props;
    const fields = inputSpecifications.map((inputSpecification) => {
      const {identifier, unit} = inputSpecification;
      let {label} = inputSpecification;
      const value = values[identifier];
      if (unit) {
        label = `${label}, ${unit}`;
      }
      return (
        <tr key={identifier}>
          <td>{label}</td>
          <td>
            <ReportingValue formatSpecification={inputSpecification.format} value={value} />
          </td>
        </tr>
      );
    });
    return (
      <table>
        <tbody>{fields}</tbody>
      </table>
    );
  }
}
