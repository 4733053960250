import {ResponsiveDialog} from "@co-frontend-libs/components";
import {DialogContent} from "@material-ui/core";
import React from "react";
import {FormattedMessage} from "react-intl";

interface RecordInC5DialogProps {
  onCancel: () => void;
  onOk: () => void;
  open: boolean;
}

export function RecordInC5Dialog(props: RecordInC5DialogProps): JSX.Element {
  const {onCancel, onOk, open} = props;
  const label = <FormattedMessage defaultMessage="Bogfør" id="record-in-c5-dialog.record" />;
  return (
    <ResponsiveDialog
      autoFocusCancel
      okLabel={label}
      open={open}
      title={label}
      onCancel={onCancel}
      onOk={onOk}
    >
      <DialogContent>
        <FormattedMessage
          defaultMessage="Er du sikker på at du vil markere denne opgave bogført dags dato?"
          id="record-in-c5-dialog.confirmation-message"
        />
      </DialogContent>
    </ResponsiveDialog>
  );
}
