import {Config} from "@co-common-libs/config";
import {Task, Unit, UnitUrl} from "@co-common-libs/resources";
import {MINUTE_MILLISECONDS} from "@co-common-libs/utils";
import {PathTemplate} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {PureComponent} from "app-utils";
import ImmutableDate from "bloody-immutable-date";
import _ from "lodash";
import React from "react";
import {CalendarEstimateBlock} from "./calendar-estimate-block";
import {TaskBlock} from "./task-block";
import {TaskWithRelations} from "./utils";

interface TrackedTasksProps {
  calendarFromTimestamp: ImmutableDate;
  calendarToTimestamp: ImmutableDate;
  completedTasks: readonly TaskWithRelations[];
  customerSettings: Config;
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  incompleteTasks: readonly TaskWithRelations[];
  now: string;
  onRequestTaskInfo?: ((position: {x: number; y: number}, task: Task) => void) | undefined;
  singleDateFocus?: boolean | undefined;
  unitLookup: (url: UnitUrl) => Unit | undefined;
}

export class TrackedTasks extends PureComponent<TrackedTasksProps> {
  render(): JSX.Element {
    const {
      calendarFromTimestamp,
      calendarToTimestamp,
      completedTasks,
      incompleteTasks,
      now,
      onRequestTaskInfo,
      singleDateFocus,
      unitLookup,
    } = this.props;
    const allIntervals = _.sortBy(
      _.flatMap(completedTasks.concat(incompleteTasks), (taskWithRelations) => {
        return taskWithRelations.intervalsInPeriod.map((interval) => ({
          ...interval,
          taskWithRelations,
        }));
      }),
      (interval) => interval.fromTimestamp,
    );
    if (!allIntervals.length) {
      return <div />;
    }
    const intervalToJS = (interval: {
      fromTimestamp: string;
      taskWithRelations: TaskWithRelations;
      toTimestamp?: string;
    }): {
      fromTimestamp: ImmutableDate;
      taskWithRelations: TaskWithRelations;
      toTimestamp: ImmutableDate;
    } => {
      return {
        fromTimestamp: new ImmutableDate(interval.fromTimestamp),
        taskWithRelations: interval.taskWithRelations,
        toTimestamp: new ImmutableDate(interval.toTimestamp || now),
      };
    };
    const simplifiedIntervals = [];
    let currentInterval = intervalToJS(allIntervals[0]);
    allIntervals.slice(1).forEach((immutableInterval) => {
      const interval = intervalToJS(immutableInterval);
      const {unregisteredBreakAfterMinutes} = this.props.customerSettings;
      const unregisteredBreakAfterMilliseconds =
        unregisteredBreakAfterMinutes * MINUTE_MILLISECONDS;
      if (
        _.isEqual(currentInterval.taskWithRelations, interval.taskWithRelations) &&
        interval.fromTimestamp.valueOf() - currentInterval.toTimestamp.valueOf() <
          unregisteredBreakAfterMilliseconds
      ) {
        if (interval.toTimestamp > currentInterval.toTimestamp) {
          currentInterval.toTimestamp = interval.toTimestamp;
        }
      } else {
        simplifiedIntervals.push(currentInterval);
        currentInterval = interval;
      }
    });
    if (currentInterval) {
      simplifiedIntervals.push(currentInterval);
    }
    const taskBlocks: JSX.Element[] = [];
    simplifiedIntervals.forEach(({fromTimestamp, taskWithRelations, toTimestamp}) => {
      let estimatedToTimestamp;
      if (this.props.customerSettings.enableDoneAtEstimateField) {
        const {doneAtEstimate} = taskWithRelations.task;
        if (doneAtEstimate) {
          const {workFromTimestamp} = taskWithRelations.task;
          const estimate = workFromTimestamp
            ? new Date(workFromTimestamp)
            : new Date(fromTimestamp.valueOf());
          const [estimateHours, estimateMinutes] = doneAtEstimate.split(":");
          estimate.setHours(parseInt(estimateHours), parseInt(estimateMinutes), 0, 0);
          if (estimate.valueOf() < fromTimestamp.valueOf()) {
            estimate.setDate(estimate.getDate() + 1);
          }
          estimatedToTimestamp = new ImmutableDate(estimate);
        }
      }
      taskBlocks.push(
        <TaskBlock
          key={`${taskWithRelations.task.url}#${fromTimestamp.toISOString()}`}
          blockType="tracked"
          calendarFromTimestamp={calendarFromTimestamp}
          calendarToTimestamp={calendarToTimestamp}
          customerSettings={this.props.customerSettings}
          fade={singleDateFocus && taskWithRelations.workStartedEarlier}
          fromTimestamp={fromTimestamp}
          go={this.props.go}
          toTimestamp={toTimestamp}
          onRequestTaskInfo={onRequestTaskInfo}
          {...taskWithRelations}
          hasPhoto={this.props.customerSettings.showPhotoOnCalendar && taskWithRelations.hasPhoto}
          unitLookup={unitLookup}
        />,
      );

      if (estimatedToTimestamp && estimatedToTimestamp > toTimestamp) {
        taskBlocks.push(
          <CalendarEstimateBlock
            key={`${taskWithRelations.task.url}#${fromTimestamp.toISOString()}#estimate`}
            calendarFromTimestamp={calendarFromTimestamp}
            calendarToTimestamp={calendarToTimestamp}
            fromTimestamp={toTimestamp}
            go={this.props.go}
            task={taskWithRelations.task}
            toTimestamp={estimatedToTimestamp}
          />,
        );
      }
    });
    return <div>{taskBlocks}</div>;
  }
}
