import {SettingsModuleID} from "@co-common-libs/config";
import {actions} from "@co-frontend-libs/redux";
import {Collapse, Divider, List, ListItem, ListItemText} from "@material-ui/core";
import {useQueryParameter} from "app-utils";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import ChevronUpIcon from "mdi-react/ChevronUpIcon";
import React, {useCallback} from "react";
import {useDispatch} from "react-redux";
import {GroupData} from "../types";
import {SettingsMenuGroupEntry} from "./settings-menu-group-entry";

interface SettingsMenuModuleEntryProps {
  moduleGroups: readonly GroupData[];
  moduleID: SettingsModuleID;
  moduleTitle: string;
  resetSearch?: boolean;
}

export const SettingsMenuModuleEntry = React.memo(function SettingsMenuModuleEntry(
  props: SettingsMenuModuleEntryProps,
): JSX.Element {
  const {moduleGroups, moduleID, moduleTitle} = props;
  const selectedModule = useQueryParameter("module");
  const dispatch = useDispatch();
  const handleModuleChange = useCallback((): void => {
    const queryKeys: {group: string; module: string; q?: string} = {
      group: "",
      module: moduleID,
    };
    if (props.resetSearch) {
      queryKeys.q = "";
    }
    dispatch(actions.putQueryKeys(queryKeys, "PUSH"));
  }, [dispatch, moduleID, props.resetSearch]);

  let sublist: JSX.Element | null = null;
  const expanded = selectedModule === moduleID;

  if (moduleGroups.length > 1) {
    sublist = (
      <Collapse unmountOnExit in={expanded} timeout="auto">
        <List disablePadding component="div">
          {moduleGroups.map(({groupID, groupTitle}) => (
            <SettingsMenuGroupEntry
              key={groupID}
              groupID={groupID}
              groupTitle={groupTitle}
              moduleID={moduleID}
            />
          ))}
        </List>
      </Collapse>
    );
  }
  return (
    <>
      <ListItem key={moduleID} button onClick={handleModuleChange}>
        <ListItemText primary={moduleTitle} />
        {moduleGroups.length > 1 ? expanded ? <ChevronUpIcon /> : <ChevronDownIcon /> : null}
      </ListItem>
      {sublist}
      <Divider />
    </>
  );
});
