import {Config} from "@co-common-libs/config";
import {
  PriceItemUse,
  ProductUse,
  ReportingInputSpecification,
  Unit,
  UnitUrl,
} from "@co-common-libs/resources";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import _ from "lodash";
import React from "react";
import {ReportingInputField} from "./reporting-input-field";

interface ReportingConversionInputFieldProps {
  customerSettings: Config;
  denominatorValue?: number;
  inputSpecification: ReportingInputSpecification;
  numeratorIdentifier: string;
  numeratorInputSpecification: ReportingInputSpecification;
  numeratorValue?: number;
  onNumeratorChange: (identifier: string, value: number | null) => void;
  onTransferOk?: ((value: number | null, url: string, unit: string) => void) | undefined;
  priceItemUseList?: readonly PriceItemUse[] | undefined;
  productUseList?: readonly ProductUse[] | undefined;
  unitLookup: (url: UnitUrl) => Unit | undefined;
}

export class ReportingConversionInputField extends PureComponent<ReportingConversionInputFieldProps> {
  @bind
  handleChange(numeratorIdentifier: string, value: unknown): void {
    if (value == null) {
      return;
    }
    const {denominatorValue} = this.props;
    if (denominatorValue == null) {
      return;
    }
    const numeratorFormatSpecification = this.props.numeratorInputSpecification.format;
    const decimalPlaces = numeratorFormatSpecification.decimalPlaces || 0;
    const numberValue = value as number;
    const resultValue = _.round(denominatorValue * numberValue, decimalPlaces);
    this.props.onNumeratorChange(numeratorIdentifier, resultValue);
  }
  render(): JSX.Element {
    const {
      denominatorValue,
      inputSpecification,
      numeratorIdentifier,
      numeratorValue,
      onTransferOk,
      priceItemUseList,
      productUseList,
      unitLookup,
    } = this.props;
    let value;
    let disabled;
    if (!denominatorValue) {
      disabled = true;
    } else {
      disabled = false;
      if (numeratorValue != null) {
        const computedValue = numeratorValue / denominatorValue;
        const decimalPlaces = this.props.customerSettings.materialDecimals;
        value = _.round(computedValue, decimalPlaces);
      }
    }
    return (
      <ReportingInputField
        disabled={disabled}
        identifier={numeratorIdentifier}
        inputSpecification={inputSpecification}
        priceItemUseList={priceItemUseList}
        productUseList={productUseList}
        unitLookup={unitLookup}
        value={value}
        onChange={this.handleChange}
        onTransferOk={onTransferOk}
      />
    );
  }
}
