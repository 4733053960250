import {MINUTE_MILLISECONDS} from "@co-common-libs/utils";
import {actions, getCommitQueue, getMessage, getTimestamp} from "@co-frontend-libs/redux";
import {Snackbar} from "@material-ui/core";
import React, {useCallback, useEffect, useState} from "react";
import {defineMessages, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

const messages = defineMessages({
  savingOnlineFailed: {
    defaultMessage: "Fejl ved synkronisering",
    id: "app.sync-status.saving-online-failed",
  },
});

const SYNC_MINUTES = 2;
const SYNC_ERROR_RESET_MINUTES = 5;
const SYNC_MILLISECONDS = SYNC_MINUTES * MINUTE_MILLISECONDS;
const SYNC_ERROR_RESET_MILLISECONDS = SYNC_ERROR_RESET_MINUTES * MINUTE_MILLISECONDS;

export const AppSnackbar = (): JSX.Element => {
  const [message, setMessage] = useState("");

  const snackbarMessage = useSelector(getMessage);
  const commitQueue = useSelector(getCommitQueue);
  const snackbarTimestamp = useSelector(getTimestamp);

  const savingOnlineFailed = commitQueue.some(
    (commitEntry) => commitEntry.error && commitEntry.status === "SAVED_LOCALLY",
  );

  const dispatch = useDispatch();

  const {formatMessage} = useIntl();

  useEffect(() => {
    // dispatch(actions.resetSyncErrors());
    dispatch(actions.startOnlineSaves());
    const syncIntervalID = window.setInterval(
      () => dispatch(actions.startOnlineSaves()),
      SYNC_MILLISECONDS,
    );
    const syncErrorIntervalID = window.setInterval(() => {
      // dispatch(actions.resetSyncErrors());
      dispatch(actions.startOnlineSaves());
    }, SYNC_ERROR_RESET_MILLISECONDS);
    return () => {
      window.clearInterval(syncIntervalID);
      window.clearInterval(syncErrorIntervalID);
    };
  }, [dispatch]);

  useEffect(() => {
    if (snackbarMessage && snackbarTimestamp) {
      setMessage(snackbarMessage);
    }
  }, [snackbarMessage, snackbarTimestamp]);

  useEffect(() => {
    if (savingOnlineFailed) {
      setMessage(formatMessage(messages.savingOnlineFailed));
    }
  }, [formatMessage, savingOnlineFailed]);

  const handleSnackbarRequestClose = useCallback(() => {
    setMessage("");
  }, []);

  return (
    <Snackbar
      autoHideDuration={5000}
      message={message}
      open={!!message}
      onClose={handleSnackbarRequestClose}
    />
  );
};
