import {SerializableError} from "@co-frontend-libs/db-resources";
import {createSelector} from "reselect";
import {AppState} from "../root-reducer";

export const getToken = (state: AppState): string | null => state.authentication.token;

export const getShareToken = (state: AppState): string | null => state.authentication.shareToken;

export const getUserId = (state: Pick<AppState, "authentication">): string | null =>
  state.authentication.userId;

export const getOrganisationId = (state: AppState): string | null =>
  state.authentication.organisationId;

export const getError = (state: AppState): SerializableError | null => state.authentication.error;

export const getAuthenticated: (state: AppState) => boolean = createSelector(
  getToken,
  (token) => !!token,
);

export const getExplicitLogout = (state: AppState): boolean | null =>
  state.authentication.explicitLogout;

export const getAuthenticationLoaded = (state: AppState): boolean => state.authentication.loaded;
