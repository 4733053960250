import {Config} from "@co-common-libs/config";
import {Culture} from "@co-common-libs/resources";
import {Button, Card, CardActions, CardContent} from "@material-ui/core";
import {PureComponent} from "app-utils";
import React from "react";
import {FormattedMessage, IntlContext, defineMessages} from "react-intl";

const messages = defineMessages({
  changeCulture: {
    defaultMessage: "Skift kultur",
    id: "task-instance.label.change-culture",
  },
});

interface CultureCardProps {
  completed: boolean;
  culture?: Culture | undefined;
  customerSettings: Config;
  onChangeCultureButton: () => void;
  userIsOnlyMachineOperator: boolean;
  userIsOtherMachineOperator: boolean;
  userIsSeniorMachineOperator: boolean;
  validated: boolean;
}

export class CultureCard extends PureComponent<CultureCardProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {
      completed,
      culture,
      onChangeCultureButton,
      userIsOnlyMachineOperator,
      userIsOtherMachineOperator,
      userIsSeniorMachineOperator,
      validated,
    } = this.props;
    const cultureName = culture ? culture.name : null;
    return (
      <Card style={{margin: "1em"}}>
        <CardContent>
          <FormattedMessage defaultMessage="Kultur" id="task-instance.label.culture" />
          <h1>{cultureName}</h1>
        </CardContent>
        <CardActions>
          <Button
            color="secondary"
            disabled={
              (userIsOtherMachineOperator && !userIsSeniorMachineOperator) ||
              (userIsOnlyMachineOperator && completed) ||
              validated
            }
            variant="contained"
            onClick={onChangeCultureButton}
          >
            {formatMessage(messages.changeCulture)}
          </Button>
        </CardActions>
      </Card>
    );
  }
}
