import {Config} from "@co-common-libs/config";
import {
  TaskIntervalWithUnregisteredAndPaid,
  applyDayEndRounding,
  applyDayStartRounding,
  buildTimeline,
  normalisedTaskListIntervals,
} from "@co-common-libs/payroll";
import {
  AccomodationAllowance,
  Availability,
  CachedDailyAccumulatedCompensatory,
  PatchOperation,
  ResourceTypeUnion,
  Settings,
  Task,
  TaskUrl,
  TimerStart,
  UserProfile,
  UserUrl,
} from "@co-common-libs/resources";
import {formatDuration, getUserAnniversaryMap} from "@co-common-libs/resources-utils";
import {
  HOUR_MINUTES,
  MINUTE_MILLISECONDS,
  dateFromString,
  dateToString,
  dateToTimeString,
  formatDate,
  formatDateTimeShort,
  formatMonthDate,
  formatTime,
  getHolidaySundayWeekday,
  getMidnights,
  monthDates,
  notUndefined,
  weekNumber,
} from "@co-common-libs/utils";
import {AppbarDateSelection, AvailabilityDialog, WeekDay} from "@co-frontend-libs/components";
import {Check, makeQuery} from "@co-frontend-libs/db-resources";
import {
  actions,
  getAccomodationAllowanceArray,
  getAnniversaryTypeArray,
  getAvailabilityArray,
  getCachedDailyAccumulatedCompensatoryArray,
  getCurrentRole,
  getCurrentUser,
  getCurrentUserProfile,
  getCurrentUserURL,
  getCustomerSettings,
  getDaysAbsenceArray,
  getDinnerBookingArray,
  getExtraHalfHolidaysPerRemunerationGroup,
  getExtraHolidaysPerRemunerationGroup,
  getHoursAbsenceArray,
  getLunchBookingArray,
  getMachineArray,
  getOrderArray,
  getPathName,
  getPriceGroupArray,
  getSettingsArray,
  getTaskArray,
  getTaskLookup,
  getTimerArray,
  getUserProfileArray,
  getWorkTypeArray,
} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {Button, Card, CardActions, CardContent, CardHeader, CardMedia} from "@material-ui/core";
import {
  AccomodationAllowanceDialog,
  Calendar,
  CompletedTasksSums,
  DinnerBookingDialog,
  Linkify,
  MenuToolbar,
  PageLayout,
  RegisterAbsenceDialog,
  TaskTable,
} from "app-components";
import {
  currentUserMayRegisterOwnAbsence,
  getAbsenceTypeLabel,
  getEmployeeHolidayCalendars,
  getEmployeeRemunerationSettings,
  getExtraHolidaysForUser,
  registerAbsence,
  updateDinnerLunchBooking,
  useBoundActions,
  useQueryParameterState,
  userCanRegisterAccommodation,
} from "app-utils";
import ImmutableDate from "bloody-immutable-date";
import {endOfDay, startOfDay} from "date-fns";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import React, {useCallback, useEffect, useMemo, useState} from "react";
// Allowed for existing code...
// eslint-disable-next-line deprecate/import
import {Cell, Grid} from "react-flexr";
import {FormattedMessage, defineMessages, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import type {Writable} from "ts-essentials";
import {v4 as uuid} from "uuid";

const messages = defineMessages({
  absenceFuture: {
    defaultMessage: "Fravær fremtid",
  },
  absenceSelectedDate: {
    defaultMessage: "Fravær på valgt dato",
  },
  accomodationAllowance: {
    defaultMessage: "Diæt/udetillæg for {month}",
  },
  accumulatedCompensatory: {
    defaultMessage: "Opsparet afspadsering",
  },
  availabilityForWeek: {
    defaultMessage: "Rådighed for uge {weekNumber}",
  },
  bookDinner: {
    defaultMessage: "Bestil aftensmad",
  },
  bookFood: {
    defaultMessage: "Bestil mad",
  },
  dinner: {
    defaultMessage: "Mad {date}",
  },
  registerAbsence: {
    defaultMessage: "Registrer fravær",
  },
  registerAccommodationAllowance: {
    defaultMessage: "Registrer diæt/udetillæg",
  },
  reportAvailability: {
    defaultMessage: "Meld rådighed",
  },
  timeOverview: {defaultMessage: "Min dagseddel"},
});

const ARCHIVE_DAYS = 7;

export const registerAccommodationAllowance = (
  fromDate: string,
  toDate: string,
  remunerationGroup: string,
  {
    accomodationAllowanceArray,
    create,
    userURL,
  }: {
    accomodationAllowanceArray: readonly AccomodationAllowance[];
    create: (instance: ResourceTypeUnion) => void;
    userURL: UserUrl;
  },
): void => {
  const userAccommodationAllowanceDates = new Set(
    accomodationAllowanceArray
      .filter(
        (accommodationAllowance) =>
          accommodationAllowance.employee === userURL &&
          accommodationAllowance.remunerationGroup === remunerationGroup,
      )
      .map((accommodationAllowance) => accommodationAllowance.date),
  );
  if (toDate >= fromDate) {
    const date = dateFromString(fromDate) as Date;
    console.assert(date);
    let dateString = dateToString(date);
    while (dateString <= toDate) {
      if (!userAccommodationAllowanceDates.has(dateString)) {
        const id = uuid();
        const url = instanceURL("accomodationAllowance", id);
        const instance: AccomodationAllowance = {
          date: dateString,
          employee: userURL,
          id,
          remunerationGroup,
          url,
        };
        create(instance);
      }
      date.setDate(date.getDate() + 1);
      dateString = dateToString(date);
    }
  }
};

function allowDinnerBooking(
  selectedDate: string,
  now: Date,
  settingsArray: readonly Settings[] | null,
  customerSettings: Config,
  currentUserProfile: UserProfile | null,
  extraHolidaysPerRemunerationGroup: (
    remunerationGroup: string,
    date: string,
  ) => string | undefined,
  extraHalfHolidaysPerRemunerationGroup: (
    remunerationGroup: string,
    date: string,
  ) => string | undefined,
): boolean {
  const settings = settingsArray?.length ? settingsArray[0] : null;
  const dinnerBookingsActive = !!(settings && settings.dinnerBookingsActive);
  if (!customerSettings.dinnerBookings || !dinnerBookingsActive) {
    return false;
  }
  const today = dateToString(now);
  const timeString = dateToTimeString(now);
  if (selectedDate !== today) {
    return false;
  }
  const holidayCalendars = getEmployeeHolidayCalendars(customerSettings, currentUserProfile);
  const extraHolidays = getExtraHolidaysForUser(
    customerSettings,
    currentUserProfile,
    extraHolidaysPerRemunerationGroup,
    extraHalfHolidaysPerRemunerationGroup,
  );
  const weekDay = getHolidaySundayWeekday(
    holidayCalendars,
    selectedDate,
    extraHolidays?.getUserHoliday,
  );
  const dinnerBooking = customerSettings.dinnerBookings[weekDay];
  return dinnerBooking !== null && timeString < dinnerBooking[1];
}

interface TimeOverviewProps {
  onMenuButton: (event: React.MouseEvent) => void;
}

const TEMPORARY_QUERIES_KEY = "TimeOverview";

export const TimeOverview = React.memo(function TimeOverview(
  props: TimeOverviewProps,
): JSX.Element | null {
  const intl = useIntl();
  const [availabilityDialogOpen, setAvailabilityDialogOpen] = useState(false);
  const setAvailabilityDialogOpenTrue = useCallWithTrue(setAvailabilityDialogOpen);
  const setAvailabilityDialogOpenFalse = useCallWithFalse(setAvailabilityDialogOpen);

  const [dinnerBookingDialogOpen, setDinnerBookingDialogOpen] = useState(false);
  const setDinnerBookingDialogOpenTrue = useCallWithTrue(setDinnerBookingDialogOpen);
  const setDinnerBookingDialogOpenFalse = useCallWithFalse(setDinnerBookingDialogOpen);

  const [registerAbsenceDialogOpen, setRegisterAbsenceDialogOpen] = useState(false);
  const setRegisterAbsenceDialogOpenTrue = useCallWithTrue(setRegisterAbsenceDialogOpen);
  const setRegisterAbsenceDialogOpenFalse = useCallWithFalse(setRegisterAbsenceDialogOpen);

  const [registerAccommodationAllowanceDialogOpen, setRegisterAccommodationAllowanceDialogOpen] =
    useState(false);
  const setRegisterAccommodationAllowanceDialogOpenTrue = useCallWithTrue(
    setRegisterAccommodationAllowanceDialogOpen,
  );
  const setRegisterAccommodationAllowanceDialogOpenFalse = useCallWithFalse(
    setRegisterAccommodationAllowanceDialogOpen,
  );

  const [selectedDate, setSelectedDate] = useQueryParameterState<string>(
    "date",
    dateToString(new Date()),
  );

  const dispatch = useDispatch();

  const accomodationAllowanceArray = useSelector(getAccomodationAllowanceArray);
  const anniversaryTypeArray = useSelector(getAnniversaryTypeArray);
  const availabilityArray = useSelector(getAvailabilityArray);
  const cachedDailyAccumulatedCompensatoryArray = useSelector(
    getCachedDailyAccumulatedCompensatoryArray,
  );
  const currentRole = useSelector(getCurrentRole);
  const currentUser = useSelector(getCurrentUser);
  const currentUserProfile = useSelector(getCurrentUserProfile);
  const currentUserURL = useSelector(getCurrentUserURL);
  const customerSettings = useSelector(getCustomerSettings);
  const daysAbsenceArray = useSelector(getDaysAbsenceArray);
  const dinnerBookingArray = useSelector(getDinnerBookingArray);
  const hoursAbsenceArray = useSelector(getHoursAbsenceArray);
  const lunchBookingArray = useSelector(getLunchBookingArray);
  const orderArray = useSelector(getOrderArray);
  const pathName = useSelector(getPathName);
  const settingsArray = useSelector(getSettingsArray);
  const taskArray = useSelector(getTaskArray);
  const taskLookup = useSelector(getTaskLookup);
  const timerArray = useSelector(getTimerArray);
  const userProfileArray = useSelector(getUserProfileArray);
  const extraHalfHolidaysPerRemunerationGroup = useSelector(
    getExtraHalfHolidaysPerRemunerationGroup,
  );
  const extraHolidaysPerRemunerationGroup = useSelector(getExtraHolidaysPerRemunerationGroup);
  const workTypeArray = useSelector(getWorkTypeArray);
  const machineArray = useSelector(getMachineArray);
  const priceGroupArray = useSelector(getPriceGroupArray);

  const [today, setToday] = useState(dateToString(new Date()));
  const [dinnerBookingAllowed, setDinnerBookingAllowed] = useState(
    allowDinnerBooking(
      selectedDate,
      new Date(),
      settingsArray,
      customerSettings,
      currentUserProfile,
      extraHolidaysPerRemunerationGroup,
      extraHalfHolidaysPerRemunerationGroup,
    ),
  );

  useEffect(() => {
    const updateState = (): void => {
      const now = new Date();
      setToday(dateToString(now));
      setDinnerBookingAllowed(
        allowDinnerBooking(
          selectedDate,
          now,
          settingsArray,
          customerSettings,
          currentUserProfile,
          extraHolidaysPerRemunerationGroup,
          extraHalfHolidaysPerRemunerationGroup,
        ),
      );
    };
    const intervalId = window.setInterval(updateState, MINUTE_MILLISECONDS);
    updateState();
    return () => {
      window.clearInterval(intervalId);
    };
  }, [
    currentUserProfile,
    customerSettings,
    extraHalfHolidaysPerRemunerationGroup,
    extraHolidaysPerRemunerationGroup,
    selectedDate,
    settingsArray,
  ]);

  const queries = useMemo(() => {
    const timeBackDateDate = new Date();
    timeBackDateDate.setDate(timeBackDateDate.getDate() - ARCHIVE_DAYS);
    const timeBackDate = dateToString(timeBackDateDate);
    const dateObject = dateFromString(selectedDate) as Date;
    dateObject.setDate(dateObject.getDate() - 1);
    const beforeSelectedDate = dateToString(dateObject);

    dateObject.setDate(dateObject.getDate() + 2);
    const afterSelectedDate = dateToString(dateObject);
    if (beforeSelectedDate <= timeBackDate && currentUser) {
      // archive relevant --- completed/recorded tasks for this date may not
      // be present in normal working set
      const rangeStartString = startOfDay(dateFromString(beforeSelectedDate) as Date).toISOString();
      const rangeEndString = endOfDay(dateFromString(afterSelectedDate) as Date).toISOString();
      const taskCheck: Check = {
        checks: [
          {
            memberName: "workFromTimestamp",
            type: "memberGte",
            value: rangeStartString,
          },
          {
            memberName: "workFromTimestamp",
            type: "memberLte",
            value: rangeEndString,
          },
        ],
        type: "and",
      };
      const orderCheck: Check = {
        check: taskCheck,
        fromResource: "task",
        memberName: "order",
        type: "targetOfForeignKey",
      };
      const relatedTaskCheck: Check = {
        check: taskCheck,
        memberName: "task",
        targetType: "task",
        type: "hasForeignKey",
      };
      const result = [
        makeQuery({
          check: taskCheck,
          filter: {
            bookkeepingDate: [beforeSelectedDate, selectedDate, afterSelectedDate].join(","),
            initials: currentUserProfile?.alias || "",
          },
          independentFetch: true,
          resourceName: "task",
        }),
        makeQuery({
          check: orderCheck,
          independentFetch: false,
          resourceName: "order",
        }),
        makeQuery({
          check: relatedTaskCheck,
          independentFetch: false,
          resourceName: "timerStart",
        }),
      ];
      return result;
    } else {
      return [];
    }
  }, [currentUser, currentUserProfile?.alias, selectedDate]);

  useEffect(() => {
    if (queries.length) {
      dispatch(actions.temporaryQueriesRequestedForPath(queries, pathName, TEMPORARY_QUERIES_KEY));
    }
    return () => {
      dispatch(actions.temporaryQueriesDiscardedForPath(pathName, TEMPORARY_QUERIES_KEY));
    };
  }, [dispatch, pathName, queries, selectedDate]);

  const {boundCreate, boundRemove, boundUpdate} = useBoundActions();

  const handleRegisterAbsenceDialogOk = useCallback(
    (data: {
      absenceType: string;
      employeeReason: string;
      fromDate: string;
      fromTime: string | null;
      note: string;
      onlyWeekdays: boolean | undefined;
      toDate: string;
      toTime: string | null;
    }) => {
      const {absenceType, employeeReason, fromDate, fromTime, note, onlyWeekdays, toDate, toTime} =
        data;

      if (!currentUserURL) {
        return;
      }
      setRegisterAbsenceDialogOpen(false);
      registerAbsence(
        {
          absenceType,
          employeeReason,
          fromDate,
          fromTime,
          note,
          onlyWeekdays,
          profile: currentUserProfile || undefined,
          toDate,
          toTime,
          user: currentUserURL,
        },
        {
          create: boundCreate,
          extraHalfHolidaysPerRemunerationGroup,
          extraHolidaysPerRemunerationGroup,
          remove: boundRemove,
          update: boundUpdate,
        },
        daysAbsenceArray,
        customerSettings,
      );
    },
    [
      boundCreate,
      boundRemove,
      boundUpdate,
      currentUserProfile,
      currentUserURL,
      customerSettings,
      daysAbsenceArray,
      extraHalfHolidaysPerRemunerationGroup,
      extraHolidaysPerRemunerationGroup,
    ],
  );

  const handleAvailabilityOk = useCallback(
    (values: ReadonlyMap<WeekDay, boolean>) => {
      setAvailabilityDialogOpen(false);
      if (!currentUserURL) {
        return;
      }
      const {week, year} = weekNumber(new Date());
      const savedAvailability = availabilityArray.find((entry) => {
        return entry.user === currentUserURL && entry.weekNumber === week && entry.year === year;
      });
      if (savedAvailability) {
        const patch: PatchOperation<Availability>[] = [];
        values.forEach((value, key) => {
          if (value !== savedAvailability[key]) {
            patch.push({member: key, value});
          }
        });
        if (patch.length) {
          dispatch(actions.update(savedAvailability.url, patch));
        }
      } else {
        const id = uuid();
        const url = instanceURL("availability", id);
        const obj: Writable<Availability> = {
          friday: null,
          id,
          monday: null,
          saturday: null,
          sunday: null,
          thursday: null,
          tuesday: null,
          url,
          user: currentUserURL,
          wednesday: null,
          weekNumber: week,
          year,
        };
        values.forEach((value, key) => {
          if (value != null) {
            obj[key] = value;
          }
        });
        dispatch(actions.create(obj));
      }
    },
    [availabilityArray, currentUserURL, dispatch],
  );

  const handleSelectedDateChanged = useCallback(
    (newDate: string | null) => setSelectedDate(newDate || dateToString(new Date())),
    [setSelectedDate],
  );

  const handleDinnerBookingOk = useCallback(
    (
      date: string,
      employeeURL: UserUrl,
      dinnerCount: number,
      lunchCount: number | null,
      dinnerLocation: string | null,
      lunchLocation: string | null,
    ) => {
      updateDinnerLunchBooking(
        customerSettings,
        dinnerBookingArray,
        lunchBookingArray,
        boundCreate,
        boundUpdate,
        date,
        employeeURL,
        dinnerCount,
        lunchCount,
        dinnerLocation,
        lunchLocation,
      );
      setDinnerBookingDialogOpen(false);
    },
    [boundCreate, boundUpdate, customerSettings, dinnerBookingArray, lunchBookingArray],
  );

  const handleRegisterAccomodationAllowanceOk = useCallback(
    (fromDate: string, toDate: string, remunerationGroup: string, employee: UserUrl) => {
      setRegisterAccommodationAllowanceDialogOpen(false);

      registerAccommodationAllowance(fromDate, toDate, remunerationGroup, {
        accomodationAllowanceArray,
        create: boundCreate,
        userURL: employee,
      });
    },
    [accomodationAllowanceArray, boundCreate],
  );

  const {
    availabilityWeekdays,
    availabilityWeekends,
    registerAbsence: registerAbsenceSetting,
  } = customerSettings;
  if (!currentUserURL) {
    return null;
  }

  const canRegisterAccommodation = currentUserProfile
    ? userCanRegisterAccommodation(customerSettings, currentUserProfile)
    : false;

  let daysAbsenceIntersectingDayList;
  let hoursAbsenceIntersectingDayList;
  let daysAbsenceFutureList;
  let hoursAbsenceFutureList;
  if (registerAbsenceSetting) {
    const userDaysAbsence = daysAbsenceArray.filter((d) => d.user === currentUserURL);
    const userHoursAbsence = hoursAbsenceArray.filter((h) => h.user === currentUserURL);
    const daysAbsenceIntersectingDay = userDaysAbsence.filter(
      (d) => d.fromDate <= selectedDate && d.toDate >= selectedDate,
    );
    daysAbsenceIntersectingDayList = _.sortBy(
      daysAbsenceIntersectingDay,
      (d) => d.fromDate + d.toDate + d.absenceType,
    );
    daysAbsenceFutureList = _.sortBy(
      userDaysAbsence.filter((d) => d.toDate > today),
      (d) => d.fromDate + d.toDate + d.absenceType,
    );
    const {startOfDay: startOfSelectedDay, startOfNextDay} = getMidnights(selectedDate);
    const hoursAbsenceIntersectingDay = userHoursAbsence.filter(
      (h) => h.fromTimestamp <= startOfNextDay && h.toTimestamp >= startOfSelectedDay,
    );
    hoursAbsenceIntersectingDayList = _.sortBy(
      hoursAbsenceIntersectingDay,
      (h) => h.fromTimestamp + h.toTimestamp + h.absenceType,
    );
    const midnights = getMidnights(today);
    const startOfTomorrow = midnights.startOfNextDay;
    hoursAbsenceFutureList = _.sortBy(
      userHoursAbsence.filter((h) => h.toTimestamp > startOfTomorrow),
      (h) => h.fromTimestamp + h.toTimestamp + h.absenceType,
    );
  }
  let accomodationAllowanceBlock;
  let accomodationAllowanceDialog;
  if (canRegisterAccommodation) {
    const yearMonthPartLength = 7;
    const {fromDate, toDate} = monthDates(selectedDate.substring(0, yearMonthPartLength));
    const userAccomodationAllowance = _.sortBy(
      accomodationAllowanceArray.filter(
        (accomodationAllowance) =>
          accomodationAllowance.employee === currentUserURL &&
          accomodationAllowance.date >= fromDate &&
          accomodationAllowance.date <= toDate,
      ),
      (accomodationAllowance) => accomodationAllowance.date,
    );
    accomodationAllowanceBlock = (
      <Card style={{marginBottom: 22}}>
        <CardHeader
          title={intl.formatMessage(messages.accomodationAllowance, {
            month: formatMonthDate(selectedDate),
          })}
        />
        <CardContent>
          <ul>
            {userAccomodationAllowance.map((accomodationAllowance) => {
              return (
                <li key={accomodationAllowance.url}>{formatDate(accomodationAllowance.date)}</li>
              );
            })}
          </ul>
        </CardContent>
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            onClick={setRegisterAccommodationAllowanceDialogOpenTrue}
          >
            {intl.formatMessage(messages.registerAccommodationAllowance)}
          </Button>
        </CardActions>
      </Card>
    );
    accomodationAllowanceDialog = (
      <AccomodationAllowanceDialog
        date={selectedDate}
        employee={currentUserURL || undefined}
        open={registerAccommodationAllowanceDialogOpen}
        onCancel={setRegisterAccommodationAllowanceDialogOpenFalse}
        onOk={handleRegisterAccomodationAllowanceOk}
      />
    );
  }
  let calendarDate,
    calendarFromTimestamp,
    calendarToTimestamp,
    intersectingCompletedTasks: Task[] = [],
    singleDateFocus,
    sortedTimerStarts: TimerStart[] | undefined;

  const taskSeq = taskArray.filter(
    (task) => task.machineOperator === currentUserURL && task.completed,
  );
  const employeeRemunerationSettings = getEmployeeRemunerationSettings(
    customerSettings,
    workTypeArray,
    machineArray,
    priceGroupArray,
    currentUserProfile,
  );
  const orderMap = new Map(orderArray.map((order) => [order.url, order]));
  const timerMap = new Map(timerArray.map((timer) => [timer.url, timer]));
  const {dayEndRounding, dayStartRounding} = employeeRemunerationSettings;
  const performDayStartRounding: (
    workPeriodIntervals: readonly (readonly TaskIntervalWithUnregisteredAndPaid[])[],
  ) => readonly (readonly TaskIntervalWithUnregisteredAndPaid[])[] = dayStartRounding
    ? (workPeriodIntervals) => applyDayStartRounding(workPeriodIntervals, dayStartRounding)
    : (workPeriodIntervals) => workPeriodIntervals;
  const performDayEndRounding: (
    workPeriodIntervals: readonly (readonly TaskIntervalWithUnregisteredAndPaid[])[],
  ) => readonly (readonly TaskIntervalWithUnregisteredAndPaid[])[] = dayEndRounding
    ? (workPeriodIntervals) => applyDayEndRounding(workPeriodIntervals, dayEndRounding)
    : (workPeriodIntervals) => workPeriodIntervals;
  const intervals = normalisedTaskListIntervals(taskSeq, timerMap, orderMap);
  const workDays = buildTimeline(
    intervals,
    null,
    employeeRemunerationSettings.unregisteredBreakAfterMinutes,
    employeeRemunerationSettings.periodSplitThresholdMinutes,
    employeeRemunerationSettings.workDaySplitThresholdMinutes,
    performDayStartRounding,
    performDayEndRounding,
    employeeRemunerationSettings.paidBreaks,
  );
  const workDay = workDays.find((w) => w.date === selectedDate);
  const taskURLSet = new Set<TaskUrl>();
  if (workDay) {
    const {workPeriods} = workDay;
    for (let i = 0; i < workPeriods.length; i += 1) {
      const workPeriod = workPeriods[i];
      const {work} = workPeriod;
      // TODO: time sum?
      for (let j = 0; j < work.length; j += 1) {
        const workInterval = work[j];
        const {taskData} = workInterval;
        // eslint-disable-next-line max-depth
        for (let k = 0; k < taskData.length; k += 1) {
          const {taskURL} = taskData[k];
          taskURLSet.add(taskURL);
        }
      }
    }
    const firstWorkPeriod = workPeriods[0];
    calendarFromTimestamp = new ImmutableDate(firstWorkPeriod.firstFromTimestamp).setUTCMinutes(
      0,
      0,
      0,
    );
    const lastWorkPeriod = workPeriods[workPeriods.length - 1];
    calendarToTimestamp = new ImmutableDate(lastWorkPeriod.lastToTimestamp).setUTCMinutes(
      HOUR_MINUTES,
      0,
      0,
    );
  } else {
    singleDateFocus = true;
    calendarDate = selectedDate;
  }
  const taskForDateArray = Array.from(taskURLSet)
    .map((taskURL) => taskLookup(taskURL))
    .filter(notUndefined);
  const displayedTasks = taskForDateArray.slice();
  intersectingCompletedTasks = displayedTasks;
  const userURLs = [currentUserURL];
  const dateTransitionMark = true;

  const right = <AppbarDateSelection value={selectedDate} onChange={handleSelectedDateChanged} />;
  let availabilityBlock;
  let availabilityDialog;
  if (availabilityWeekdays || availabilityWeekends) {
    const {week, year} = weekNumber(new Date());
    const savedAvailability = availabilityArray.find((entry) => {
      return entry.user === currentUserURL && entry.weekNumber === week && entry.year === year;
    });
    availabilityDialog = (
      <AvailabilityDialog
        initialValues={savedAvailability || {}}
        open={availabilityDialogOpen}
        weekdays={availabilityWeekdays || undefined}
        weekends={availabilityWeekends}
        onCancel={setAvailabilityDialogOpenFalse}
        onOk={handleAvailabilityOk}
      />
    );
    availabilityBlock = (
      <Card style={{marginBottom: 22}}>
        <CardHeader
          title={intl.formatMessage(messages.availabilityForWeek, {
            weekNumber: week,
          })}
        />
        <CardActions>
          <Button color="primary" variant="contained" onClick={setAvailabilityDialogOpenTrue}>
            {intl.formatMessage(messages.reportAvailability)}
          </Button>
        </CardActions>
      </Card>
    );
  }
  let dinnerBookingBlock;
  let dinnerBookingDialog;
  const settings = settingsArray?.length ? settingsArray[0] : null;
  const dinnerBookingsActive = !!(settings && settings.dinnerBookingsActive);
  if (customerSettings.dinnerBookings && dinnerBookingsActive) {
    const existingBooking = dinnerBookingArray.find((entry) => {
      return entry.user === currentUserURL && entry.date === selectedDate;
    });
    const count = existingBooking ? existingBooking.count : undefined;
    const dinnerLocation = existingBooking ? existingBooking.location : undefined;
    let dinnerCount;
    if (count != null) {
      dinnerCount = <FormattedMessage defaultMessage="Antal: {count}" values={{count}} />;
    } else {
      dinnerCount = <FormattedMessage defaultMessage="Ikke registreret" />;
    }
    let lunchCountMessage;
    let lunchCount;
    let lunchLocation;
    const lunchBookingsActive =
      settings && !!settings.lunchBookingsActive && customerSettings.lunchBookings;
    if (lunchBookingsActive) {
      const existingLunchBooking = lunchBookingArray.find((entry) => {
        return entry.user === currentUserURL && entry.date === selectedDate;
      });
      lunchCount = existingLunchBooking ? existingLunchBooking.count : undefined;
      lunchLocation = existingLunchBooking ? existingLunchBooking.location : null;
      if (lunchCount != null) {
        lunchCountMessage = (
          <FormattedMessage defaultMessage="Antal: {count}" values={{count: lunchCount}} />
        );
      } else {
        lunchCountMessage = <FormattedMessage defaultMessage="Ikke registreret" />;
      }
    }
    dinnerBookingBlock = (
      <Card style={{marginBottom: 22}}>
        <CardHeader
          title={intl.formatMessage(messages.dinner, {
            date: formatDate(selectedDate),
          })}
        />
        <CardContent>
          <div>
            <h3>
              <FormattedMessage defaultMessage="Aften" />
            </h3>
            {dinnerCount}
            {count && dinnerLocation ? <br /> : null}
            {count ? dinnerLocation : null}
          </div>
          {lunchBookingsActive ? (
            <div>
              <h3>
                <FormattedMessage defaultMessage="Frokost" />
              </h3>
              {lunchCountMessage}
              {lunchCount && lunchLocation ? <br /> : null}
              {lunchCount ? lunchLocation : null}
            </div>
          ) : null}
        </CardContent>
        <CardActions>
          <Button
            color="primary"
            disabled={!dinnerBookingAllowed}
            variant="contained"
            onClick={setDinnerBookingDialogOpenTrue}
          >
            {lunchBookingsActive
              ? intl.formatMessage(messages.bookFood)
              : intl.formatMessage(messages.bookDinner)}
          </Button>
        </CardActions>
      </Card>
    );
    if (currentUser) {
      dinnerBookingDialog = (
        <DinnerBookingDialog
          count={count}
          customerSettings={customerSettings}
          date={today}
          dinnerLocation={dinnerLocation}
          employee={currentUser}
          lunchCount={lunchCount}
          lunchLocation={lunchLocation != null ? lunchLocation : undefined}
          open={dinnerBookingDialogOpen}
          showDinnerBooking={dinnerBookingsActive}
          showLunchBooking={lunchBookingsActive != null ? lunchBookingsActive : undefined}
          onCancel={setDinnerBookingDialogOpenFalse}
          onOk={handleDinnerBookingOk}
        />
      );
    }
  }
  let registerAbsenceButton: JSX.Element | undefined;
  if (currentUserMayRegisterOwnAbsence(customerSettings, currentRole)) {
    registerAbsenceButton = (
      <Button color="primary" variant="contained" onClick={setRegisterAbsenceDialogOpenTrue}>
        {intl.formatMessage(messages.registerAbsence)}
      </Button>
    );
  }

  const accumulatedCompensatoryInUse = Object.values(customerSettings.remunerationGroups).some(
    (override) => override.accumulateCompensatoryLimit,
  );
  let cachedDailyAccumulatedCompensatory: CachedDailyAccumulatedCompensatory | undefined;
  if (accumulatedCompensatoryInUse) {
    cachedDailyAccumulatedCompensatory = cachedDailyAccumulatedCompensatoryArray.find(
      (x) => x.employee === currentUserURL,
    );
  }
  let accumulatedCompensatoryBlock: JSX.Element | undefined;
  if (cachedDailyAccumulatedCompensatory) {
    const {minutes} = cachedDailyAccumulatedCompensatory;
    const hours = formatDuration(customerSettings.durationFormat, minutes);
    const computedAt = formatDateTimeShort(cachedDailyAccumulatedCompensatory.lastChanged);
    accumulatedCompensatoryBlock = (
      <Card style={{marginBottom: 22}}>
        <CardHeader title={intl.formatMessage(messages.accumulatedCompensatory)} />
        <CardContent>
          <FormattedMessage
            defaultMessage="Timer/minutter: {hours} (beregnet {computedAt})"
            values={{computedAt, hours}}
          />
        </CardContent>
      </Card>
    );
  }

  const userAnniversaryMap = customerSettings.anniversariesEnabled
    ? getUserAnniversaryMap(
        userProfileArray,
        anniversaryTypeArray,
        dateFromString(selectedDate) as Date,
      )
    : null;

  const dialogs = (
    <>
      {currentRole && currentUserURL && currentUser ? (
        <RegisterAbsenceDialog
          currentRole={currentRole}
          currentUserURL={currentUserURL}
          customerSettings={customerSettings}
          open={registerAbsenceDialogOpen}
          userProfile={currentUserProfile ?? undefined}
          onCancel={setRegisterAbsenceDialogOpenFalse}
          onOk={handleRegisterAbsenceDialogOk}
        />
      ) : null}
      {accomodationAllowanceDialog}
      {availabilityDialog}
      {dinnerBookingDialog}
    </>
  );

  return (
    <PageLayout
      withPadding
      dialogs={dialogs}
      toolbar={
        <MenuToolbar
          rightElement={right}
          title={intl.formatMessage(messages.timeOverview)}
          onMenuButton={props.onMenuButton}
        />
      }
    >
      <Grid>
        <Cell>
          <Grid>
            <Cell>
              <Card style={{minWidth: 900}}>
                <CardContent>
                  <CompletedTasksSums tasks={displayedTasks} />
                </CardContent>
                <CardMedia>
                  <TaskTable tasks={displayedTasks} />
                </CardMedia>
              </Card>
            </Cell>
          </Grid>
          {registerAbsenceSetting ? (
            <Grid>
              <Cell>
                <Card>
                  <CardHeader title={intl.formatMessage(messages.absenceSelectedDate)} />
                  <CardContent>
                    <ul>
                      {hoursAbsenceIntersectingDayList &&
                        hoursAbsenceIntersectingDayList.map((hoursAbsence) => {
                          const {absenceType, employeeReason, fromTimestamp, toTimestamp} =
                            hoursAbsence;
                          const absenceTypeLabel = getAbsenceTypeLabel(
                            absenceType,
                            customerSettings.absenceTypeLabels,
                            intl.formatMessage,
                          );
                          return (
                            <li key={hoursAbsence.url}>
                              {formatTime(fromTimestamp)}–{formatTime(toTimestamp)}:{" "}
                              {absenceTypeLabel} <Linkify>{employeeReason}</Linkify>
                            </li>
                          );
                        })}
                      {daysAbsenceIntersectingDayList &&
                        daysAbsenceIntersectingDayList.map((daysAbsence) => {
                          const {absenceType, employeeReason, fromDate, toDate} = daysAbsence;
                          const absenceTypeLabel = getAbsenceTypeLabel(
                            absenceType,
                            customerSettings.absenceTypeLabels,
                            intl.formatMessage,
                          );
                          return (
                            <li key={daysAbsence.url}>
                              {formatDate(fromDate)}–{formatDate(toDate)}: {absenceTypeLabel}{" "}
                              <Linkify>{employeeReason}</Linkify>
                            </li>
                          );
                        })}
                    </ul>
                    {registerAbsenceButton}
                  </CardContent>
                </Card>
              </Cell>
            </Grid>
          ) : null}
          {registerAbsenceSetting ? (
            <Grid>
              <Cell>
                <Card>
                  <CardHeader title={intl.formatMessage(messages.absenceFuture)} />
                  <CardContent>
                    <ul>
                      {hoursAbsenceFutureList &&
                        hoursAbsenceFutureList.map((hoursAbsence) => {
                          const {absenceType, fromTimestamp, toTimestamp} = hoursAbsence;
                          const absenceTypeLabel = getAbsenceTypeLabel(
                            absenceType,
                            customerSettings.absenceTypeLabels,
                            intl.formatMessage,
                          );
                          return (
                            <li key={hoursAbsence.url}>
                              {formatDate(fromTimestamp)} {formatTime(fromTimestamp)}–
                              {formatTime(toTimestamp)}: {absenceTypeLabel}
                            </li>
                          );
                        })}
                      {daysAbsenceFutureList &&
                        daysAbsenceFutureList.map((daysAbsence) => {
                          const {fromDate} = daysAbsence;
                          const {toDate} = daysAbsence;
                          const {absenceType} = daysAbsence;
                          const absenceTypeLabel = getAbsenceTypeLabel(
                            absenceType,
                            customerSettings.absenceTypeLabels,
                            intl.formatMessage,
                          );
                          return (
                            <li key={daysAbsence.url}>
                              {formatDate(fromDate)}–{formatDate(toDate)}: {absenceTypeLabel}
                            </li>
                          );
                        })}
                    </ul>
                  </CardContent>
                </Card>
              </Cell>
            </Grid>
          ) : null}
          {availabilityBlock}
          {dinnerBookingBlock}
          {accumulatedCompensatoryBlock}
          {accomodationAllowanceBlock}
        </Cell>
        <Cell style={{maxWidth: 191, minWidth: 191}}>
          <Card>
            <CardContent style={{padding: 0}}>
              <Calendar
                date={calendarDate}
                dateTransitionMark={dateTransitionMark}
                fromTimestamp={calendarFromTimestamp}
                incompleteTasks={[]}
                intersectingCompletedTasks={intersectingCompletedTasks}
                intersectingPlannedTasks={[]}
                singleDateFocus={singleDateFocus}
                sortedTimerStarts={sortedTimerStarts}
                toTimestamp={calendarToTimestamp}
                userAnniversaryMap={userAnniversaryMap}
                userURLs={userURLs}
              />
            </CardContent>
          </Card>
        </Cell>
      </Grid>
    </PageLayout>
  );
});
