import {
  AnniversaryType,
  AnniversaryTypeUrl,
  PatchUnion,
  ResourceTypeUnion,
  Role,
  emptyAnniversaryType,
} from "@co-common-libs/resources";
import {isValidEmail} from "@co-common-libs/utils";
import {IntegerField, ResponsiveDialog, TrimTextField} from "@co-frontend-libs/components";
import {
  AppState,
  actions,
  getAnniversaryTypeArray,
  getAnniversaryTypeLookup,
  getCurrentRole,
} from "@co-frontend-libs/redux";
import {
  Card,
  CardContent,
  CardHeader,
  DialogContent,
  Fab,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import {SelectableListCard} from "app-components";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import bowser from "bowser";
import {instanceURL} from "frontend-global-config";
import {partition} from "lodash";
import CakeVariantIcon from "mdi-react/CakeVariantIcon";
import FlagVariantIcon from "mdi-react/FlagVariantIcon";
import MedalIcon from "mdi-react/MedalIcon";
import PlusIcon from "mdi-react/PlusIcon";
import React from "react";
// Allowed for existing code...
// eslint-disable-next-line deprecate/import
import {Cell, Grid} from "react-flexr";
import {FormattedMessage, IntlContext, defineMessages} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {v4 as uuid} from "uuid";

const messages = defineMessages({
  anniversaryTypes: {
    defaultMessage: "Mærkedagstyper",
    id: "workshop-checklist-settings.header.anniversaryType",
  },
  emailNotificationRecipients: {
    defaultMessage: "Påmindelses modtagere (Kommasepareret liste)",
    id: "workshop-checklist-setting.label.emailNotificationRecipients",
  },
  invalidEmails: {
    defaultMessage: "Følgende mailadresser er ugyldige: {invalid}",
  },
  label: {
    defaultMessage: "Label",
    id: "workshop-checklist-settings.label.label",
  },
  newAnniversaryType: {
    defaultMessage: "Ny Mærkedagstyper",
    id: "workshop-checklist-settings.header.new-anniversary-type",
  },
  reminderDays: {
    defaultMessage: "Påmindelsesdage",
    id: "workshop-checklist-setting.label.reminderDays",
  },
  years: {
    defaultMessage: "Hvor mange år fra start dato (Blank for hvert år)",
    id: "workshop-checklist-setting.label.years",
  },
});

export const validateEmailAdresses = (input: string): {invalid: string[]; valid: string[]} => {
  const strings = input.split(/[\s,]+/).filter(Boolean);
  const [valid, invalid] = partition(strings, isValidEmail);

  return {
    invalid,
    valid,
  };
};

interface AnniversaryTypeEditBlockProps {
  anniversaryType: AnniversaryType;
  update: (url: string, patch: PatchUnion) => void;
}

class AnniversaryTypeEditBlock extends PureComponent<AnniversaryTypeEditBlockProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  @bind
  handleYearsChange(value: number | null): void {
    const {anniversaryType, update} = this.props;
    update(anniversaryType.url, [{member: "years", value}]);
  }
  @bind
  handleCountFromChange(_event: unknown, value: string): void {
    const {anniversaryType, update} = this.props;
    update(anniversaryType.url, [
      {
        member: "countFrom",
        value: value as "BIRTH_DATE" | "EMPLOYMENT_DATE",
      },
    ]);
  }
  @bind
  handleReminderDaysChange(value: number | null): void {
    const {anniversaryType, update} = this.props;
    update(anniversaryType.url, [{member: "reminderDays", value: value || 0}]);
  }
  @bind
  handleIconChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const {value} = event.target;
    console.assert(value === "cake" || value === "flag" || value === "medal");
    const {anniversaryType, update} = this.props;
    update(anniversaryType.url, [{member: "icon", value: value as "cake" | "flag" | "medal"}]);
  }
  @bind
  handleLabelChange(value: string): void {
    const {anniversaryType, update} = this.props;
    update(anniversaryType.url, [{member: "label", value}]);
  }
  @bind
  handleEmailNotificationRecipientsChange(value: string): void {
    const {anniversaryType, update} = this.props;
    update(anniversaryType.url, [{member: "emailNotificationRecipients", value}]);
  }

  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {anniversaryType} = this.props;
    const {label} = anniversaryType;
    const emailCheck = validateEmailAdresses(anniversaryType.emailNotificationRecipients || "");

    const invalidMailsFound = !!emailCheck.invalid.length;
    const intl = this.context;
    return (
      <Card
        style={{
          display: "inline-flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        }}
      >
        <CardHeader title={label} />
        <CardContent>
          <TrimTextField
            fullWidth
            label={formatMessage(messages.label)}
            margin="dense"
            name="label"
            value={label}
            variant="outlined"
            onChange={this.handleLabelChange}
          />
          <IntegerField
            fullWidth
            label={formatMessage(messages.years)}
            margin="dense"
            name="years"
            value={anniversaryType.years}
            onChange={this.handleYearsChange}
          />
          <FormControl component="fieldset">
            <FormattedMessage defaultMessage="Tæl fra" tagName="b" />
            <RadioGroup
              name="countFrom"
              value={anniversaryType.countFrom}
              onChange={this.handleCountFromChange}
            >
              <FormControlLabel
                control={<Radio />}
                label={intl.formatMessage({defaultMessage: "Fødselsdag"})}
                value="BIRTH_DATE"
              />
              <FormControlLabel
                control={<Radio />}
                label={intl.formatMessage({defaultMessage: "Ansættelsesdato"})}
                value="EMPLOYMENT_DATE"
              />
            </RadioGroup>
          </FormControl>
          <br />
          <FormControl component="fieldset">
            <FormattedMessage defaultMessage="Ikon" tagName="b" />
            <RadioGroup name="icon" value={anniversaryType.icon} onChange={this.handleIconChange}>
              <FormControlLabel control={<Radio />} label={<CakeVariantIcon />} value="cake" />
              <FormControlLabel control={<Radio />} label={<FlagVariantIcon />} value="flag" />
              <FormControlLabel control={<Radio />} label={<MedalIcon />} value="medal" />
            </RadioGroup>
          </FormControl>
          <IntegerField
            fullWidth
            label={formatMessage(messages.reminderDays)}
            margin="dense"
            name="reminderDays"
            value={anniversaryType.reminderDays}
            onChange={this.handleReminderDaysChange}
          />
          <TrimTextField
            fullWidth
            multiline
            error={invalidMailsFound}
            helperText={
              invalidMailsFound
                ? formatMessage(messages.invalidEmails, {
                    invalid: emailCheck.invalid.join(", "),
                  })
                : undefined
            }
            label={formatMessage(messages.emailNotificationRecipients)}
            margin="dense"
            minRows={3}
            name="emailNotificationRecipients"
            value={anniversaryType.emailNotificationRecipients || ""}
            variant="outlined"
            onChange={this.handleEmailNotificationRecipientsChange}
          />
        </CardContent>
      </Card>
    );
  }
}

export const anniversaryTypeCompare = (a: AnniversaryType, b: AnniversaryType): number => {
  const aLabel = a.label;
  const bLabel = b.label;
  return aLabel.localeCompare(bLabel);
};

interface AnniversaryTypesStateProps {
  anniversaryTypeArray: readonly AnniversaryType[];
  anniversaryTypeLookup: (url: AnniversaryTypeUrl) => AnniversaryType | undefined;
  currentRole: Role | null;
}

interface AnniversaryTypeDispatchProps {
  create: (instance: ResourceTypeUnion) => void;
  update: (url: string, patch: PatchUnion) => void;
}

type AnniversaryTypeProps = AnniversaryTypeDispatchProps & AnniversaryTypesStateProps;

interface AnniversaryTypeState {
  newAnniversaryTypeDialogOpen: boolean;
  selectedAnniversaryTypeURL: AnniversaryTypeUrl | null;
}

class AnniversaryTypes extends PureComponent<AnniversaryTypeProps, AnniversaryTypeState> {
  state: AnniversaryTypeState = {
    newAnniversaryTypeDialogOpen: false,
    selectedAnniversaryTypeURL: null,
  };

  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  @bind
  handleAnniversaryTypeSelected(
    _event: React.SyntheticEvent<unknown>,
    anniversaryTypeURL: AnniversaryTypeUrl | null,
  ): void {
    this.setState({
      selectedAnniversaryTypeURL: anniversaryTypeURL,
    });
  }

  @bind
  handleAddButtonClick(_event: React.MouseEvent): void {
    this.setState({
      newAnniversaryTypeDialogOpen: true,
    });
  }

  @bind
  handleOk(label: string): void {
    this.setState({newAnniversaryTypeDialogOpen: false});
    const id = uuid();
    const url = instanceURL("anniversaryType", id);
    const data: AnniversaryType = {
      ...emptyAnniversaryType,
      countFrom: "",
      icon: "cake",
      id,
      label,
      reminderDays: 1,
      url,
    };
    this.props.create(data);
    this.setState({selectedAnniversaryTypeURL: url});
  }

  @bind
  handleCancel(): void {
    this.setState({newAnniversaryTypeDialogOpen: false});
  }

  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {selectedAnniversaryTypeURL} = this.state;
    const {anniversaryTypeArray, anniversaryTypeLookup} = this.props;

    const sortedAnniversaryTypes = anniversaryTypeArray.slice().sort(anniversaryTypeCompare);

    const anniversaryTypesData = sortedAnniversaryTypes.map((anniversaryType) => {
      return {
        primaryText: anniversaryType.label,
        value: anniversaryType.url,
      };
    });

    const selectedAnniversaryType = selectedAnniversaryTypeURL
      ? anniversaryTypeLookup(selectedAnniversaryTypeURL)
      : null;

    let anniversaryTypeEditBlock;
    if (selectedAnniversaryType) {
      anniversaryTypeEditBlock = (
        <AnniversaryTypeEditBlock
          anniversaryType={selectedAnniversaryType}
          update={this.props.update}
        />
      );
    }
    const mobileWidth = 360;
    // Grid/Cell types do not accept style prop ...?
    const GridNoType = Grid as any;
    const CellNoType = Cell as any;
    const allowAnniversaryCreation =
      this.props.currentRole &&
      (this.props.currentRole.consultant || this.props.currentRole.manager);
    return (
      <div>
        <GridNoType
          style={{
            height: bowser.mobile ? "auto" : "calc(100% - 64px)",
            margin: 5,
          }}
        >
          <CellNoType
            palm="12/12"
            style={{
              height: bowser.mobile ? mobileWidth : "100%",
              paddingBottom: 11,
              paddingTop: 11,
            }}
          >
            <SelectableListCard
              data={anniversaryTypesData}
              title={formatMessage(messages.anniversaryTypes)}
              topFab={
                allowAnniversaryCreation ? (
                  <Fab
                    size="small"
                    style={{
                      position: "absolute",
                      right: 16,
                      top: 16,
                    }}
                    onClick={this.handleAddButtonClick}
                  >
                    <PlusIcon />
                  </Fab>
                ) : null
              }
              value={this.state.selectedAnniversaryTypeURL || undefined}
              onChange={this.handleAnniversaryTypeSelected}
            />
          </CellNoType>
          <CellNoType palm="12/12" style={{paddingBottom: 11, paddingTop: 11}}>
            {anniversaryTypeEditBlock}
          </CellNoType>
        </GridNoType>
        <NewAnniversaryTypeDialog
          open={this.state.newAnniversaryTypeDialogOpen}
          onCancel={this.handleCancel}
          onOk={this.handleOk}
        />
      </div>
    );
  }
}

const ConnectedAnniversaryTypes = connect<
  AnniversaryTypesStateProps,
  AnniversaryTypeDispatchProps,
  object,
  AppState
>(
  createStructuredSelector<AppState, AnniversaryTypesStateProps>({
    anniversaryTypeArray: getAnniversaryTypeArray,
    anniversaryTypeLookup: getAnniversaryTypeLookup,
    currentRole: getCurrentRole,
  }),
  {
    create: actions.create,
    update: actions.update,
  },
)(AnniversaryTypes);

export {ConnectedAnniversaryTypes as AnniversaryTypes};

interface NewAnniversaryTypeDialogProps {
  onCancel?: () => void;
  onOk: (label: string) => void;
  open: boolean;
}

export class NewAnniversaryTypeDialog extends React.PureComponent<NewAnniversaryTypeDialogProps> {
  static defaultProps = {
    open: false,
  };

  state = {
    label: "",
  };

  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  @bind
  handleLabelChange(value: string): void {
    this.setState({label: value});
  }

  @bind
  handleOk(): void {
    const {label} = this.state;
    this.setState({label: ""});
    this.props.onOk(label);
  }

  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {onCancel, open} = this.props;
    const {label} = this.state;

    return (
      <ResponsiveDialog
        okDisabled={!label.trim()}
        open={open}
        title={formatMessage(messages.newAnniversaryType)}
        onCancel={onCancel}
        onOk={this.handleOk}
      >
        <DialogContent>
          <TrimTextField
            autoFocus
            fullWidth
            label={formatMessage(messages.label)}
            margin="dense"
            value={label}
            variant="outlined"
            onChange={this.handleLabelChange}
          />
        </DialogContent>
      </ResponsiveDialog>
    );
  }
}
