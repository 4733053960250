import {Config} from "@co-common-libs/config";
import {
  Machine,
  PriceGroup,
  PriceGroupUrl,
  PriceItem,
  PriceItemUrl,
  Unit,
  UnitUrl,
} from "@co-common-libs/resources";
import {getUnitString, priceItemIsVisible} from "@co-common-libs/resources-utils";
import {notUndefined} from "@co-common-libs/utils";
import {IntlShape} from "react-intl";
import {InlinedTask} from "../../inline-data";
import {getSmallMachinePriceItemURLs} from "../../task-helpers";

const EMPTY_SET: ReadonlySet<any> = new Set();

export const getPriceItemIssues = (
  customerSettings: Config,
  exceptTypes: ReadonlySet<number> | undefined = undefined,
  intl: IntlShape,
  machineArray: readonly Machine[],
  onlyTypes: ReadonlySet<number> | undefined = undefined,
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined,
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined,
  requireAtLeastOneOptionalPriceItemUseGreaterThanZero = false,
  requireAtLeastOneOptionalPriceItemUseGreaterThanZeroItemsByGroup: ReadonlyMap<
    PriceGroupUrl,
    readonly PriceItemUrl[]
  >,
  task: Pick<
    InlinedTask,
    "completed" | "logSkipped" | "order" | "priceitemuseSet" | "reportingSpecification"
  >,
  unitLookup: (url: UnitUrl) => Unit | undefined,
  logCheck: boolean = false,
): {logIssues: string[]; materialIssues: string[]} => {
  const priceItemLogIssues: string[] = [];
  const priceItemMaterialIssues: string[] = [];

  const optionalObserved: PriceItemUrl[] = [];
  const optionalNonZeroObserved: PriceItemUrl[] = [];

  const {logSkipped, priceitemuseSet, reportingSpecification} = task;

  const taskHasLog = !!reportingSpecification && !logSkipped;

  const materialZeroCausesError = customerSettings.materialZeroCausesError || [];

  const smallMachinePriceItemURLs = machineArray.some(
    (machine) => machine.active && machine.smallMachine,
  )
    ? getSmallMachinePriceItemURLs(machineArray, priceGroupLookup)
    : EMPTY_SET;
  priceitemuseSet.forEach((instance) => {
    const {count} = instance;
    if (instance.dangling) {
      return;
    }
    const {priceItem} = instance;
    if (!priceItem) {
      return;
    }
    const priceItemURL = priceItem.url;
    if (smallMachinePriceItemURLs.has(priceItemURL)) {
      return;
    }
    const {itemtype} = priceItem;
    if (onlyTypes && itemtype != null && !onlyTypes.has(itemtype)) {
      return;
    }
    if (exceptTypes && itemtype != null && exceptTypes.has(itemtype)) {
      return;
    }
    if (!priceItemIsVisible(priceItem, false, priceitemuseSet, unitLookup)) {
      return;
    }

    const requiredGreaterThanZero =
      (itemtype != null && materialZeroCausesError.includes(itemtype)) ||
      priceItem.requiredGreaterThanZero;
    const zeroAllowed = !requiredGreaterThanZero;
    // a priceItem with requiredGreaterThanZero true and required false should be considered required
    const required = requiredGreaterThanZero || priceItem.required;

    const absenceAllowed = required === false;
    if (absenceAllowed) {
      optionalObserved.push(priceItemURL);
      if (count) {
        optionalNonZeroObserved.push(priceItemURL);
      }
    }
    if (count != null && count > 0) {
      // nonzero, so fulfills any nonzero/required requirements
      return;
    }
    let countError: string | undefined;
    if (
      !task.order?.routePlan &&
      (zeroAllowed || count !== 0) &&
      (absenceAllowed || count != null)
    ) {
      const taskCompleted = task.completed;
      const correctedCountZeroError =
        !logCheck &&
        taskCompleted &&
        customerSettings.ifCountZeroRequireCorrectedCountZero &&
        count === 0 &&
        instance.correctedCount == null;
      if (correctedCountZeroError) {
        countError = intl.formatMessage(
          {
            defaultMessage:
              "Du skal taste 0 i korrigeringsfeltet for at godkende at {item} er 0 {unit}",
          },
          {
            item: priceItem.name,
            unit: getUnitString(priceItem, unitLookup),
          },
        );
      } else {
        return;
      }
    }
    const error = countError
      ? countError
      : `${priceItem.name}: ? ${getUnitString(priceItem, unitLookup)}`;

    if (taskHasLog && priceItem.includeInLogs) {
      priceItemLogIssues.push(error);
    } else {
      priceItemMaterialIssues.push(error);
    }
  });

  const priceItemMaterials = new Set<PriceItemUrl>();
  const priceItemLog = new Set<PriceItemUrl>();

  priceitemuseSet.forEach((priceItemUse) => {
    const {priceItem} = priceItemUse;

    if (priceItem) {
      if (taskHasLog && priceItem.includeInLogs) {
        priceItemLog.add(priceItem.url);
      } else {
        priceItemMaterials.add(priceItem.url);
      }
    }
  });

  const errorOptionalGreaterThanZeroRequiredMessage = customerSettings.materialUseAlternativeText
    ? intl.formatMessage({
        defaultMessage:
          "Mindst en af de valgfri materiale-linjer skal udfyldes med større end nul.",
      })
    : intl.formatMessage({
        defaultMessage: "Mindst en af de valgfri materiel-linjer skal udfyldes med større end nul.",
      });

  if (
    requireAtLeastOneOptionalPriceItemUseGreaterThanZero &&
    optionalObserved.length &&
    !optionalNonZeroObserved.length
  ) {
    if (optionalObserved.some((optional) => priceItemLog.has(optional))) {
      priceItemLogIssues.push(errorOptionalGreaterThanZeroRequiredMessage);
    }
    if (optionalObserved.some((optional) => priceItemMaterials.has(optional))) {
      priceItemMaterialIssues.push(errorOptionalGreaterThanZeroRequiredMessage);
    }
  }

  if (requireAtLeastOneOptionalPriceItemUseGreaterThanZeroItemsByGroup.size) {
    const filteredRequireAtLeastOneOptionalPriceItemUseGreaterThanZeroItemsByGroupArray =
      Array.from(requireAtLeastOneOptionalPriceItemUseGreaterThanZeroItemsByGroup.values()).filter(
        (optionalPriceItems) => optionalPriceItems.length,
      );

    const priceItemGoupsWithErrors =
      filteredRequireAtLeastOneOptionalPriceItemUseGreaterThanZeroItemsByGroupArray.filter(
        (optionalPriceItems) => {
          return !optionalPriceItems.some((item) => optionalNonZeroObserved.includes(item));
        },
      );

    if (priceItemGoupsWithErrors.length) {
      if (
        priceItemGoupsWithErrors.some((priceItemUrls) =>
          priceItemUrls.some((priceitemUrl) => priceItemLog.has(priceitemUrl)),
        )
      ) {
        priceItemLogIssues.push(errorOptionalGreaterThanZeroRequiredMessage);
      }
      if (
        priceItemGoupsWithErrors.some((priceItemUrls) =>
          priceItemUrls.some((priceitemUrl) => priceItemMaterials.has(priceitemUrl)),
        )
      ) {
        priceItemMaterialIssues.push(errorOptionalGreaterThanZeroRequiredMessage);
      }

      priceItemGoupsWithErrors.forEach((priceitems) => {
        priceitems
          .map((priceitemURL) => priceItemLookup(priceitemURL))
          .filter(notUndefined)
          .sort()
          .forEach((priceItem) => {
            if (taskHasLog && priceItem.includeInLogs && priceItemLog.has(priceItem.url)) {
              priceItemLogIssues.push(priceItem.name);
            } else if (priceItemMaterials.has(priceItem.url)) {
              priceItemMaterialIssues.push(priceItem.name);
            }
          });
      });
    }
  }

  return {
    logIssues: priceItemLogIssues,
    materialIssues: priceItemMaterialIssues,
  };
};
