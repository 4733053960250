import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import _ from "lodash";
import React from "react";
import {FormattedMessage} from "react-intl";
import {SettingValueDisplayProps} from "../types";

export function DisplayUnitConversionHelpers(props: SettingValueDisplayProps): JSX.Element | null {
  const {settingEntry} = props;

  if (!settingEntry || !settingEntry.data || !Object.keys(settingEntry.data).length) {
    return null;
  }
  const values: {[unit: string]: string[] | undefined} = settingEntry.data;
  const keys = Object.keys(values);
  keys.sort();
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <FormattedMessage defaultMessage="Enhed" id="display-unit-conversion-helpers.unit" />
          </TableCell>
          <TableCell>
            <FormattedMessage defaultMessage="Enheder" id="display-unit-conversion-helpers.units" />
          </TableCell>
          <TableCell>
            <FormattedMessage
              defaultMessage="Konvertering"
              id="display-unit-conversion-helpers.conversion"
            />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {keys.map((key) => {
          const units = values[key];
          return (
            <TableRow key={key}>
              <TableCell>{key}</TableCell>
              <TableCell>{units?.length ? units.join(", ") : null}</TableCell>
              <TableCell>
                {units?.length ? units.map((unit) => `${unit}/${key}`).join(", ") : null}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
