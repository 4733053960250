import {
  CustomerUrl,
  Machine,
  MachineUrl,
  PriceGroup,
  PriceGroupUrl,
  WorkType,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {notUndefined} from "@co-common-libs/utils";

export function customerFilteredPriceGroups(
  priceGroups: readonly PriceGroup[],
  customerURL: CustomerUrl | null,
  includeOnlyForExtraTimers?: boolean,
): PriceGroup[] {
  const potentialPriceGroups = includeOnlyForExtraTimers
    ? priceGroups.filter(({active}) => active)
    : priceGroups.filter(({active, onlyForExtraTimers}) => active && !onlyForExtraTimers);
  if (!potentialPriceGroups) {
    return [];
  }
  if (customerURL) {
    const customerSpecificPriceGroups = potentialPriceGroups.filter(
      (priceGroup) => priceGroup.customers && priceGroup.customers.includes(customerURL),
    );
    if (customerSpecificPriceGroups.length) {
      return customerSpecificPriceGroups;
    }
  }
  const standardPriceGroups = potentialPriceGroups.filter(
    (priceGroup) => priceGroup.standardListing,
  );
  return standardPriceGroups;
}

function workTypeOrMachinePotentialPriceGroups<UrlType>(
  url: UrlType,
  customerURL: CustomerUrl | null,
  lookup: (url: UrlType) => {readonly pricegroups: readonly PriceGroupUrl[]} | undefined,
  priceGroupLookup: (url: PriceGroupUrl) => Readonly<PriceGroup> | undefined,
): PriceGroup[] {
  const workTypeOrMachine = lookup(url);
  const priceGroupURLs = workTypeOrMachine?.pricegroups;
  if (!priceGroupURLs || !priceGroupURLs.length) {
    return [];
  }
  const potentialPriceGroups = priceGroupURLs.map(priceGroupLookup).filter(notUndefined);
  return customerFilteredPriceGroups(potentialPriceGroups, customerURL);
}

export const workTypePotentialPriceGroups: (
  workTypeURL: WorkTypeUrl,
  customerURL: CustomerUrl | null,
  workTypeLookup: (url: WorkTypeUrl) => Readonly<WorkType> | undefined,
  priceGroupLookup: (url: PriceGroupUrl) => Readonly<PriceGroup> | undefined,
) => PriceGroup[] = workTypeOrMachinePotentialPriceGroups<WorkTypeUrl>;

export const machinePotentialPriceGroups: (
  machineURL: MachineUrl,
  customerURL: CustomerUrl | null,
  machineLookup: (url: MachineUrl) => Readonly<Machine> | undefined,
  priceGroupLookup: (url: PriceGroupUrl) => Readonly<PriceGroup> | undefined,
) => PriceGroup[] = workTypeOrMachinePotentialPriceGroups<MachineUrl>;

const spaceRegEx = /\s/g;
export const normaliseName = (name: string): string => name.toLowerCase().replace(spaceRegEx, "");

export function recomputePriceGroup(
  potentialPriceGroups: readonly PriceGroup[],
  currentPriceGroup: PriceGroup | null,
): PriceGroup | null {
  if (potentialPriceGroups.length === 0) {
    // no price group possible
    return null;
  } else if (potentialPriceGroups.length === 1) {
    // only one price group possible
    return potentialPriceGroups[0];
  } else if (
    currentPriceGroup === null ||
    potentialPriceGroups.some((p) => p.url === currentPriceGroup.url)
  ) {
    // current choice OK
    return currentPriceGroup;
  } else {
    // current choice NOT OK, but there might be some
    // customer-specific/non-customer-specific with the same name...
    const currentNormalisedName = normaliseName(currentPriceGroup.name);
    const replacedBy = potentialPriceGroups.find(
      (p) => normaliseName(p.name) === currentNormalisedName,
    );
    if (replacedBy) {
      // found valid choice with same name
      return replacedBy;
    } else {
      // no valid choice with same name
      return null;
    }
  }
}
