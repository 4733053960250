import {ArchiveLink} from "@co-common-libs/resources";
import {ResponsiveDialog, TrimTextField} from "@co-frontend-libs/components";
import {actions, diffResourceInstanceProperties, getCurrentUserURL} from "@co-frontend-libs/redux";
import {DialogContent, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import {createArchiveLink, useEventTargetValueCallback} from "app-utils";
import React, {useCallback, useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

// https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url
function validUrl(str: string): boolean {
  let url;
  try {
    url = new URL(str);
  } catch {
    return false;
  }
  return (url.protocol === "http:" || url.protocol === "https:") && !url.hostname.includes("%");
}

export const ArchiveLinkCreateEditDialog = React.memo(function ArchiveLinkCreateEditDialog(props: {
  archiveLink: ArchiveLink | undefined;
  onCancel: () => void;
  onOk: (archiveLinkURL: string) => void;
  open: boolean;
}): JSX.Element {
  const {archiveLink, onOk, open} = props;
  const [name, setName] = useState(archiveLink?.name || "");
  const [linkUrl, setLinkUrl] = useState(archiveLink?.linkUrl || "");
  const [category, setCategory] = useState(archiveLink?.category || "");
  const handleCategoryChange = useEventTargetValueCallback(setCategory, [setCategory]);
  const currentUserUrl = useSelector(getCurrentUserURL);

  useEffect(() => {
    if (open) {
      setName(archiveLink?.name || "");
      setLinkUrl(archiveLink?.linkUrl || "");
      setCategory(archiveLink?.category || "");
    }
  }, [archiveLink?.category, archiveLink?.linkUrl, archiveLink?.name, open]);

  const dispatch = useDispatch();
  const handleOk = useCallback(() => {
    if (archiveLink) {
      const patch = diffResourceInstanceProperties({category, linkUrl, name}, archiveLink);
      if (patch.length) {
        dispatch(actions.update(archiveLink.url, patch));
      }
      onOk(archiveLink.url);
    } else {
      if (!currentUserUrl) {
        return;
      }
      const newArchiveLink = createArchiveLink({
        category,
        created: new Date().toISOString(),
        createdBy: currentUserUrl,
        linkUrl,
        name,
      });

      dispatch(actions.create(newArchiveLink));
      onOk(newArchiveLink.url);
    }
  }, [archiveLink, name, linkUrl, category, onOk, dispatch, currentUserUrl]);

  const intl = useIntl();

  const linkValid =
    !linkUrl ||
    validUrl(
      !linkUrl.startsWith("https://") && !linkUrl.startsWith("http://")
        ? `http://${linkUrl}`
        : linkUrl,
    );

  const handleLinkBlur = useCallback(() => {
    if (!linkUrl.startsWith("https://") && !linkUrl.startsWith("http://")) {
      setLinkUrl(`http://${linkUrl}`);
    }
  }, [linkUrl]);

  return (
    <ResponsiveDialog
      okDisabled={!name || !linkUrl || !linkValid}
      open={open}
      title={
        props.archiveLink ? (
          <FormattedMessage defaultMessage="Redigér link" />
        ) : (
          <FormattedMessage defaultMessage="Tilføj link" />
        )
      }
      onCancel={props.onCancel}
      onOk={handleOk}
    >
      <DialogContent>
        <TrimTextField
          fullWidth
          label={intl.formatMessage({
            defaultMessage: "Navn *",
          })}
          margin="dense"
          value={name}
          variant="outlined"
          onChange={setName}
        />

        <TrimTextField
          fullWidth
          error={!linkValid}
          helperText={
            !linkValid
              ? intl.formatMessage({
                  defaultMessage: "Linket er ikke gyldigt",
                })
              : undefined
          }
          label={intl.formatMessage({
            defaultMessage: "Link *",
          })}
          margin="dense"
          value={linkUrl}
          variant="outlined"
          onBlur={handleLinkBlur}
          onChange={setLinkUrl}
        />

        <RadioGroup name="category" value={category} onChange={handleCategoryChange}>
          <FormControlLabel
            control={<Radio />}
            label={intl.formatMessage({
              defaultMessage: "Sikkerhedsdatablade",
            })}
            value="safety"
          />
          <FormControlLabel
            control={<Radio />}
            label={intl.formatMessage({
              defaultMessage: "Medarbejderhåndbog/APV",
            })}
            value="handbook"
          />
          <FormControlLabel
            control={<Radio />}
            label={intl.formatMessage({defaultMessage: "Andet"})}
            value=""
          />
        </RadioGroup>

        <FormattedMessage defaultMessage="* Skal udfyldes" tagName="div" />
      </DialogContent>
    </ResponsiveDialog>
  );
});
