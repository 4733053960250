import {Task} from "@co-common-libs/resources";
import {useMemo} from "react";
import {EconomicIssue, IssueContext} from "../issues/types";
import {useEconomicTasksIssues} from "./use-economic-tasks-issues";

export function useEconomicTaskIssues(task: Task, context: IssueContext): readonly EconomicIssue[] {
  const taskArray = useMemo(() => [task], [task]);
  const taskResults = useEconomicTasksIssues(taskArray, context);
  return useMemo(() => taskResults.get(task.url) || [], [task.url, taskResults]);
}
