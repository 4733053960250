import {Drawer, Hidden, withStyles} from "@material-ui/core";
import bowser from "bowser";
import React from "react";
import {DRAWER_WIDTH} from "../constants";
import {SettingsDrawerContent} from "./settings-drawer-content";

const WideDrawer = withStyles({
  paper: {
    overflowY: "auto",
    width: DRAWER_WIDTH,
  },
})(Drawer);

// Drawer only passes its elevation prop to Paper on variant="temporary"...
const elevationOverride = {elevation: 8} as const;

interface SettingsMenuProps {
  onClose: () => void;
  open: boolean;
}

export function SettingsMenu(props: SettingsMenuProps): JSX.Element {
  const {onClose, open} = props;

  const drawerContent = <SettingsDrawerContent onClose={onClose} />;
  if (bowser.tablet || bowser.mobile) {
    // always "temporary" on mobile devices
    return (
      <WideDrawer
        ModalProps={{
          keepMounted: true,
        }}
        open={open}
        variant="temporary"
        onClose={onClose}
      >
        {drawerContent}
      </WideDrawer>
    );
  } else {
    // responsive logic for PC
    return (
      <>
        <Hidden mdUp implementation="css">
          <WideDrawer
            ModalProps={{
              keepMounted: true,
            }}
            open={open}
            variant="temporary"
            onClose={onClose}
          >
            {drawerContent}
          </WideDrawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <WideDrawer open PaperProps={elevationOverride} variant="permanent">
            {drawerContent}
          </WideDrawer>
        </Hidden>
      </>
    );
  }
}
