import {getKrPerLiterFuelSurchargeSpecificationLookup} from "@co-frontend-libs/redux";
import {DoLoadInstance} from "app-components";
import {LoadInstanceRelated} from "app-utils";
import React from "react";
import {useIntl} from "react-intl";
import {KrPerLiterFuelSurchargeSpecificationLoaded} from "./kr-per-liter-fuel-surcharge-specification-loaded";

const related: LoadInstanceRelated = [
  {
    memberName: "specification",
    resourceType: "krPerLiterFuelSurchargeSpecificationEntry",
    type: "hasForeignKey",
  },
] as const;

export function KrPerLiterFuelSurchargeSpecificationEdit(): JSX.Element {
  const intl = useIntl();

  return (
    <DoLoadInstance
      Component={KrPerLiterFuelSurchargeSpecificationLoaded}
      loadingTitle={intl.formatMessage({defaultMessage: "Brændstoftillæg"})}
      lookupSelector={getKrPerLiterFuelSurchargeSpecificationLookup}
      related={related}
      resourceName="krPerLiterFuelSurchargeSpecification"
    />
  );
}
