import {buildCommittingPerResource} from "../selectors/resources-helpers";
import {ResourceInstanceRecords, ResourcesState} from "../types";
import {combineAllResourceData, combineOfflineData} from "../utils";

export function getAllResourcesData(state: ResourcesState): ResourceInstanceRecords {
  const {commitQueue, persistedData, temporaryData} = state;
  const committing = buildCommittingPerResource(commitQueue);
  return combineAllResourceData(persistedData, temporaryData, committing);
}

export function getOfflineData(state: ResourcesState): ResourceInstanceRecords {
  const {persistedData, temporaryData} = state;
  return combineOfflineData(persistedData, temporaryData);
}
