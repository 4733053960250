import {Task} from "@co-common-libs/resources";
import {formatDuration, priceItemIsTime} from "@co-common-libs/resources-utils";
import {
  MINUTE_MILLISECONDS,
  formatTime,
  notUndefined,
  sortByOrderMember,
} from "@co-common-libs/utils";
import {
  getCustomerSettings,
  getPriceGroupLookup,
  getPriceItemLookup,
  getUnitLookup,
} from "@co-frontend-libs/redux";
import _ from "lodash";
import React from "react";
import {useSelector} from "react-redux";
import {IntervalWithTimer} from "./types";

interface IntervalRowProps {
  interval: IntervalWithTimer;
  task: Task;
}

function IntervalRow(props: IntervalRowProps): JSX.Element {
  const {interval, task} = props;
  const {fromTimestamp, timer, toTimestamp} = interval;
  const timerURL = timer.url;
  const timerPriceGroupUrl = timer.priceGroup;
  const customerSettings = useSelector(getCustomerSettings);
  const fromDate = new Date(fromTimestamp);
  const toDate = new Date(toTimestamp);
  const totalMinutes = Math.round((toDate.valueOf() - fromDate.valueOf()) / MINUTE_MILLISECONDS);

  const minutesParts: number[] = [];

  const priceItemLookup = useSelector(getPriceItemLookup);
  const unitLookup = useSelector(getUnitLookup);
  const priceGroupLookup = useSelector(getPriceGroupLookup);
  const timerPriceGroup = timerPriceGroupUrl ? priceGroupLookup(timerPriceGroupUrl) : undefined;

  if (timerPriceGroup?.timeAfterMinutesEffect === "per_interval") {
    const timePriceItemsWithTimer = sortByOrderMember(Object.values(task.priceItemUses || {}))
      .filter((priceItemUse) => priceItemUse.timer === timerURL)
      .map((priceItemUse) => priceItemLookup(priceItemUse.priceItem))
      .filter(notUndefined)
      .filter((priceItem) => priceItemIsTime(unitLookup, priceItem));

    if (timePriceItemsWithTimer.some((priceItem) => priceItem.timeAfterMinutes)) {
      const thresholds = new Set(
        timePriceItemsWithTimer.map((priceItem) => priceItem.timeAfterMinutes || 0),
      );
      // ensure an entry for the "free" part on "only billed after X" setup
      thresholds.add(0);
      // we process the largest threshold first
      const sortedThresholds = _.reverse(_.sortBy(Array.from(thresholds)));
      let remainingMinutes = totalMinutes;
      sortedThresholds.forEach((thresholdMinutes) => {
        const overThreshold = Math.max(remainingMinutes - thresholdMinutes, 0);
        // output array is build last-to-first
        minutesParts.unshift(overThreshold);
        remainingMinutes = Math.min(remainingMinutes, thresholdMinutes);
      });
    }
  }

  return (
    <tr>
      <td>{timer.label}:</td>
      <td>{formatTime(fromDate)}</td>
      <td>–</td>
      <td>{formatTime(toDate)}</td>
      <td>
        {formatDuration(customerSettings.durationFormat, totalMinutes)} (
        {minutesParts.length ? minutesParts.join("\u00A0+\u00A0") : totalMinutes})
      </td>
    </tr>
  );
}

interface TimelineTableProps {
  intervals: readonly IntervalWithTimer[];
  task: Task;
}

export function TimelineTable(props: TimelineTableProps): JSX.Element | null {
  const {intervals, task} = props;
  if (intervals.every((interval) => !interval.timer.showInTimelineTable)) {
    return null;
  }
  const displayedItervals = intervals.filter((interval) => interval.timer.showInTimelineTable);
  return (
    <table>
      <tbody>
        {displayedItervals.map((interval) => (
          <IntervalRow key={interval.fromTimestamp} interval={interval} task={task} />
        ))}
      </tbody>
    </table>
  );
}
