import {ReportingInputSpecification, ReportingSpecification} from "@co-common-libs/resources";

export function getInputSpecificationsMap(
  logSpecification: ReportingSpecification,
): Map<string, ReportingInputSpecification> {
  const inputSpecificationMap = new Map<string, ReportingInputSpecification>();
  const updateMap = (inputSpecificationList?: readonly ReportingInputSpecification[]): void => {
    if (inputSpecificationList) {
      inputSpecificationList.forEach((inputSpecification: ReportingInputSpecification) => {
        const {identifier} = inputSpecification;
        inputSpecificationMap.set(identifier, inputSpecification);
      });
    }
  };
  updateMap(logSpecification.taskData && logSpecification.taskData.inputs);
  (["workplace", "pickup", "delivery"] as const).forEach((locationType) => {
    const locationSpecification = logSpecification.workplaceData[locationType];
    if (locationSpecification) {
      updateMap(locationSpecification.inputs);
      updateMap(locationSpecification.logInputs);
    }
  });
  return inputSpecificationMap;
}
