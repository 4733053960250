import {SettingID} from "@co-common-libs/config";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {
  actions,
  getCurrentUserURL,
  getCustomerSettings,
  getSettingsEntryLookupByIdentifier,
} from "@co-frontend-libs/redux";
import {DialogContent, Grid} from "@material-ui/core";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import React, {useCallback, useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuid} from "uuid";
import {ColumnSelectionDialog} from "../column-selection-dialog";
import {ColumnListEdit} from "./column-list-edit";
import {DeviceType, UserType} from "./types";

interface ListColumnsDialogProps<ColumnChoice extends string, ListIdentifier extends string> {
  columnChoices: readonly ColumnChoice[];
  listIdentifiers: readonly ListIdentifier[];
  onClose: () => void;
  open: boolean;
  settingID: SettingID;
  title: string;
}

export function ListColumnsDialog<ColumnChoice extends string, ListIdentifier extends string>(
  props: ListColumnsDialogProps<ColumnChoice, ListIdentifier>,
): JSX.Element {
  const {columnChoices, listIdentifiers, onClose, open, settingID} = props;
  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const customerSettings = useSelector(getCustomerSettings);
  const settingsEntry = settingsEntryLookupByIdentifier(settingID);
  const currentUserURL = useSelector(getCurrentUserURL);
  const dispatch = useDispatch();

  type CurrentSettingValueType = {
    readonly [L in ListIdentifier]: {
      readonly [U in UserType]: {
        readonly [D in DeviceType]: readonly ColumnChoice[];
      };
    };
  };

  type WritableSettingValueType = {
    [L in ListIdentifier]: {
      [U in UserType]: {
        [D in DeviceType]: ColumnChoice[];
      };
    };
  };

  const [data, setData] = useState<CurrentSettingValueType>(
    customerSettings[settingID] as unknown as CurrentSettingValueType,
  );
  useEffect(() => {
    setData(customerSettings[settingID] as unknown as CurrentSettingValueType);
  }, [customerSettings, settingID]);

  const handleSave = useCallback(() => {
    if (settingsEntry) {
      dispatch(
        actions.update(settingsEntry.url, [
          {member: "changedBy", value: currentUserURL},
          {member: "data", value: data},
        ]),
      );
    } else {
      const id = uuid();
      const url = instanceURL("settingEntry", id);
      dispatch(
        actions.create({
          changedBy: currentUserURL,
          data,
          key: settingID,
          url,
        }),
      );
    }
    onClose();
  }, [settingsEntry, onClose, dispatch, currentUserURL, data, settingID]);

  const handleChange = useCallback(
    (
      reorderedIdentifiers: readonly ColumnChoice[],
      listIdentifier: ListIdentifier,
      userType: UserType,
      deviceType: DeviceType,
    ): void => {
      const newData = _.cloneDeep(data) as WritableSettingValueType;
      newData[listIdentifier][userType][deviceType] = reorderedIdentifiers.slice();
      setData(newData);
    },
    [data],
  );

  const [columnSelectionDialogOpenWithData, setColumnSelectionDialogOpenWithData] = useState<{
    deviceType: DeviceType;
    listIdentifier: ListIdentifier;
    selected: readonly ColumnChoice[];
    userType: UserType;
  } | null>(null);

  const handleColumnListEditButtonClick = useCallback(
    (listIdentifier: ListIdentifier, userType: UserType, deviceType: DeviceType) => {
      const selected = data[listIdentifier][userType][deviceType];
      setColumnSelectionDialogOpenWithData({
        deviceType,
        listIdentifier,
        selected,
        userType,
      });
    },
    [data],
  );

  const handleColumnSelectionDialogClose = useCallback(() => {
    setColumnSelectionDialogOpenWithData(null);
  }, []);

  const handleColumnSelectionDialogSave = useCallback(
    (selected: readonly ColumnChoice[]) => {
      if (columnSelectionDialogOpenWithData) {
        const newData = _.cloneDeep(data) as WritableSettingValueType;
        const {deviceType, listIdentifier, userType} = columnSelectionDialogOpenWithData;
        newData[listIdentifier][userType][deviceType] = selected.slice();
        setData(newData);
        handleColumnSelectionDialogClose();
      }
    },
    [columnSelectionDialogOpenWithData, data, handleColumnSelectionDialogClose],
  );

  const deviceTypes: DeviceType[] = ["mobile", "tablet", "desktop"];

  return (
    <>
      <ResponsiveDialog
        fullscreen
        okLabel={<FormattedMessage defaultMessage="Gem" id="setting-dialog.label.save" />}
        open={open && !columnSelectionDialogOpenWithData}
        title={props.title}
        onCancel={onClose}
        onOk={handleSave}
      >
        <DialogContent style={{padding: 32}}>
          {listIdentifiers.map((listIdentifier) => (
            <Grid key={listIdentifier} container spacing={8}>
              <Grid item xs={6}>
                {deviceTypes.map((deviceType) => (
                  <div key={`${listIdentifier}-manager-${deviceType}`}>
                    <FormattedMessage
                      defaultMessage="{listIdentifier} - admin - {deviceType}"
                      id="system-setup.header.list-identifier-manager-device-type-columns"
                      tagName="b"
                      values={{deviceType, listIdentifier}}
                    />
                    <ColumnListEdit<ColumnChoice, ListIdentifier>
                      deviceType={deviceType}
                      identifiers={data[listIdentifier].manager[deviceType]}
                      listIdentifier={listIdentifier}
                      userType="manager"
                      onChange={handleChange}
                      onEditClick={handleColumnListEditButtonClick}
                    />
                  </div>
                ))}
              </Grid>
              <Grid item xs={6}>
                {deviceTypes.map((deviceType) => (
                  <div key={`${listIdentifier}-employee-${deviceType}`}>
                    <FormattedMessage
                      defaultMessage="{listIdentifier} - medarbejder - {deviceType}"
                      id="system-setup.header.list-identifier-employee-device-type-columns"
                      tagName="b"
                      values={{deviceType, listIdentifier}}
                    />
                    <ColumnListEdit<ColumnChoice, ListIdentifier>
                      deviceType={deviceType}
                      identifiers={data[listIdentifier].employee[deviceType]}
                      listIdentifier={listIdentifier}
                      userType="employee"
                      onChange={handleChange}
                      onEditClick={handleColumnListEditButtonClick}
                    />
                  </div>
                ))}
              </Grid>
            </Grid>
          ))}
        </DialogContent>
      </ResponsiveDialog>
      <ColumnSelectionDialog<ColumnChoice>
        columns={columnChoices}
        open={!!columnSelectionDialogOpenWithData}
        selected={columnSelectionDialogOpenWithData?.selected}
        onClose={handleColumnSelectionDialogClose}
        onSave={handleColumnSelectionDialogSave}
      />
    </>
  );
}
