import {TaskFile, TaskPhoto} from "@co-common-libs/resources";
import {ImageList} from "@material-ui/core";
import _ from "lodash";
import React from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import {THUMBNAIL_HEIGHT, THUMBNAIL_WIDTH} from "./constants";
import {FileTile} from "./file-tile";
import {PhotoTile} from "./photo-tile";
import {PhotoTaskData} from "./types";

interface PhotosImageListProps {
  checkedPhotos: ReadonlySet<string>;
  data: readonly PhotoTaskData[];
  onCheckboxCheck: (id: string, checked: boolean) => void;
  onImageClick: (photoInstance: TaskPhoto) => void;
  onTitleClick: (url: string) => void;
  shareToken: string;
}

type PhotoWithTaskData = {
  customerName: string;
  employeeAlias: string;
  photoInstance: TaskPhoto;
  shareToken: string;
  taskDate: string;
  type: "photo";
};

type FileWithTaskData = {
  customerName: string;
  employeeAlias: string;
  fileInstance: TaskFile;
  shareToken: string;
  taskDate: string;
  type: "file";
};

type FileOrPhoto = FileWithTaskData | PhotoWithTaskData;

export class PhotosImageList extends React.PureComponent<PhotosImageListProps> {
  render(): JSX.Element {
    const {checkedPhotos, data, onCheckboxCheck, onImageClick, onTitleClick, shareToken} =
      this.props;

    const allPhotos = _.flatMap(data, (taskData: PhotoTaskData): FileOrPhoto[] => {
      const {customerName, date, employeeAlias, files, photos} = taskData;
      const currentPhotos = photos.map((photoInstance) => {
        return {
          customerName,
          employeeAlias,
          photoInstance,
          shareToken,
          taskDate: date,
          type: "photo",
        } as const;
      });
      const currentFiles = files.map((fileInstance) => {
        return {
          customerName,
          employeeAlias,
          fileInstance,
          shareToken,
          taskDate: date,
          type: "file",
        } as const;
      });
      return ([] as FileOrPhoto[]).concat(currentPhotos, currentFiles);
    });
    const tiles: JSX.Element[] = _.sortBy(allPhotos, (photo) =>
      photo.type === "photo" ? photo.photoInstance.taken : photo.fileInstance.created,
    ).map((taskPhoto) => {
      if (taskPhoto.type === "photo") {
        const {photoInstance, ...other} = taskPhoto;
        return (
          <PhotoTile
            key={photoInstance.url}
            checked={checkedPhotos.has(photoInstance.url)}
            photoInstance={photoInstance}
            onCheck={onCheckboxCheck}
            onImageClick={onImageClick}
            onTitleClick={onTitleClick}
            {...other}
          />
        );
      } else {
        const {fileInstance, ...other} = taskPhoto;
        return (
          <FileTile
            key={fileInstance.url}
            checked={checkedPhotos.has(fileInstance.url)}
            fileInstance={fileInstance}
            onCheck={onCheckboxCheck}
            onTitleClick={onTitleClick}
            {...other}
          />
        );
      }
    });
    return (
      <AutoSizer disableHeight>
        {({width}) => {
          const columns = Math.max(Math.floor(width / THUMBNAIL_WIDTH), 1);
          return (
            <div
              style={{
                width,
              }}
            >
              <ImageList cols={columns} rowHeight={THUMBNAIL_HEIGHT} style={{overflow: "auto"}}>
                {tiles}
              </ImageList>
            </div>
          );
        }}
      </AutoSizer>
    );
  }
}
