import {ReportingSpecification, ReportingSpecificationUrl} from "@co-common-libs/resources";
import {memoizeForceReuse} from "@co-frontend-libs/utils";
import React from "react";
import {defineMessages, useIntl} from "react-intl";
import {EntryData, GenericMultiSelectionSearchDialog} from "../search-dialog";

const messages = defineMessages({
  searchReportingSpecification: {
    defaultMessage: "Søg enhed",
    id: "uinit-dialog.dialog-title.search-uinit",
  },
  selectReportingSpecifications: {
    defaultMessage: "Vælg enheder",
    id: "uinit-dialog.dialog-title.select-uinit",
  },
});

function computeBaseChoices(
  reportingSpecificationArray: readonly ReportingSpecification[],
  includeOnlyReportingSpecificationsWithIdentifiers?: boolean,
): readonly EntryData<ReportingSpecificationUrl>[] {
  const result: EntryData<ReportingSpecificationUrl>[] = reportingSpecificationArray
    .filter(
      (instance) =>
        instance.active &&
        !(includeOnlyReportingSpecificationsWithIdentifiers && !instance.identifier),
    )
    .map((instance) => {
      const {identifier, name, url} = instance;
      const entry: EntryData<ReportingSpecificationUrl> = {
        category: "standard",
        exactMatchValue: name,
        identifier: url,
        primaryText: name,
        searchFields: [
          {label: "Navn", priority: 5, text: name},
          {label: "Identifier", priority: 10, text: identifier},
        ],
        secondaryText: identifier,
      };
      return entry;
    });
  return result;
}

interface MultipleReportingSpecificationDialogProps {
  includeOnlyReportingSpecificationsWithIdentifiers?: boolean;
  onCancel(): void;
  onOk(urls: ReadonlySet<ReportingSpecificationUrl>): void;
  open: boolean;
  reportingSpecificationArray: readonly ReportingSpecification[];
  selected?: ReadonlySet<ReportingSpecificationUrl>;
}

export const MultipleReportingSpecificationDialog = React.memo(
  function MultipleReportingSpecificationDialog(
    props: MultipleReportingSpecificationDialogProps,
  ): JSX.Element {
    const {formatMessage} = useIntl();
    const {
      includeOnlyReportingSpecificationsWithIdentifiers,
      onCancel,
      onOk,
      open,
      reportingSpecificationArray,
      selected,
    } = props;

    const [doComputeBaseChoices, reuseBaseChoices] = React.useMemo(
      () => memoizeForceReuse(computeBaseChoices, []),
      [],
    );
    const getBaseChoices = open ? doComputeBaseChoices : reuseBaseChoices;
    const data = getBaseChoices(
      reportingSpecificationArray,
      includeOnlyReportingSpecificationsWithIdentifiers,
    );

    const title = formatMessage(messages.selectReportingSpecifications);
    const searchTitle = formatMessage(messages.searchReportingSpecification);
    const selectedSet = selected;
    return (
      <GenericMultiSelectionSearchDialog<ReportingSpecificationUrl>
        data={data}
        mobilePrimaryLines={1}
        mobileSearchPrimaryLines={1}
        mobileSearchSecondaryLines={1}
        mobileSecondaryLines={1}
        open={open}
        searchTitle={searchTitle}
        selected={selectedSet}
        title={title}
        onCancel={onCancel}
        onOk={onOk}
      />
    );
  },
);
