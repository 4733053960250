export const MOBILE_TABLET_MAX_TASKS_DISPLAYED = 100;

export const COLUMN_WIDTHS = {
  date: 150, // max len for now: "man. 22/12/2016"
  dateMedium: 72,
  dateShort: 56,
  dateTime: 190,
  initials: 88,

  initialsShort: 80 - 24,
  time: 86,

  timeShort: 84 - 24,
};
