import {EmployeeGroup, EmployeeGroupUrl} from "@co-common-libs/resources";
import React, {useMemo} from "react";
import {useIntl} from "react-intl";
import {
  EntryData,
  GenericMultiSelectionSearchDialog,
  GenericMultiSelectionSearchDialogProps,
} from "../search-dialog";

export function employeeGroupsBaseChoices(
  employeeGroupArray: readonly EmployeeGroup[],
  nameLabel: string,
): readonly EntryData<EmployeeGroupUrl>[] {
  return employeeGroupArray.map((employeeGroup) => {
    const entry: EntryData<EmployeeGroupUrl> = {
      category: "standard",
      exactMatchValue: employeeGroup.name,
      identifier: employeeGroup.url,
      primaryText: employeeGroup.name,
      searchFields: [{label: nameLabel, priority: 5, text: employeeGroup.name}],
    };
    return entry;
  });
}

export interface MultipleEmployeeGroupDialogProps
  extends Pick<
    GenericMultiSelectionSearchDialogProps<EmployeeGroupUrl>,
    "includeSelectAll" | "onCancel" | "onOk" | "open" | "selected"
  > {
  employeeGroupArray: readonly EmployeeGroup[];
}

export const MultipleEmployeeGroupDialog = React.memo(function MultipleEmployeeGroupDialog(
  props: MultipleEmployeeGroupDialogProps,
) {
  const {employeeGroupArray, includeSelectAll, onCancel, onOk, open, selected} = props;

  const intl = useIntl();
  const title = intl.formatMessage({defaultMessage: "Vælg medarbejdergrupper"});
  const searchTitle = intl.formatMessage({
    defaultMessage: "Søg medarbejdergrupper",
  });
  const data = useMemo(() => {
    return employeeGroupsBaseChoices(employeeGroupArray, "Name");
  }, [employeeGroupArray]);

  return (
    <GenericMultiSelectionSearchDialog<EmployeeGroupUrl>
      data={data}
      includeSelectAll={includeSelectAll}
      mobilePrimaryLines={1}
      mobileSearchPrimaryLines={1}
      mobileSearchSecondaryLines={1}
      mobileSecondaryLines={1}
      open={open}
      searchTitle={searchTitle}
      selected={selected}
      sorting="SECONDARY_IDENTIFIER"
      title={title}
      onCancel={onCancel}
      onOk={onOk}
    />
  );
});
