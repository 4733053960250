import {
  PriceGroup,
  PricePercentFuelSurchargeSpecificationUrl,
  PricePercentWorkTypeFuelSurchargeUse,
  WorkType,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {ConnectedPricePercentFuelSurchargeSpecificationDialog} from "@co-frontend-libs/connected-components";
import {
  actions,
  getPricePercentFuelSurchargeSpecificationLookup,
  getPricePercentWorkTypeFuelSurchargeUseArray,
  getWorkTypeArray,
} from "@co-frontend-libs/redux";
import {
  Card,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import CloseIcon from "mdi-react/CloseIcon";
import PencilIcon from "mdi-react/PencilIcon";
import React, {useCallback, useMemo, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuid} from "uuid";

const iconButtonWidth = 48;
const cellPadding = 16;
const iconColumnWidth = cellPadding + iconButtonWidth + cellPadding;

interface WorkTypePriceGroupPricePercentFuelSurchargeRowProps {
  onEditClick: (workTypeUrl: WorkTypeUrl) => void;
  onRemoveClick: (surChargeUse: string) => void;
  surChargeUse: PricePercentWorkTypeFuelSurchargeUse | undefined;
  workType: WorkType;
}

function WorkTypePriceGroupPricePercentFuelSurchargeRow(
  props: WorkTypePriceGroupPricePercentFuelSurchargeRowProps,
): JSX.Element {
  const {onEditClick, onRemoveClick, surChargeUse, workType} = props;

  const surChargeUseUrl = surChargeUse?.url;
  const workTypeUrl = workType.url;

  const pricePercentFuelSurchargeSpecificationLookup = useSelector(
    getPricePercentFuelSurchargeSpecificationLookup,
  );

  const intl = useIntl();

  const handleEditClick = useCallback((): void => {
    onEditClick(workTypeUrl);
  }, [onEditClick, workTypeUrl]);

  const handleRemoveClick = useCallback((): void => {
    if (surChargeUseUrl) {
      onRemoveClick(surChargeUseUrl);
    }
  }, [onRemoveClick, surChargeUseUrl]);

  let surChargeUseText: string;
  if (surChargeUse) {
    if (!surChargeUse.fuelSurcharge) {
      surChargeUseText = intl.formatMessage({
        defaultMessage: "Ingen brændstoftillæg for område",
      });
    } else {
      const fuelSurcharge = pricePercentFuelSurchargeSpecificationLookup(
        surChargeUse.fuelSurcharge,
      );
      if (fuelSurcharge) {
        surChargeUseText = fuelSurcharge.name;
      } else {
        surChargeUseText = intl.formatMessage({
          defaultMessage: "Opslag fejlet",
        });
      }
    }
  } else {
    surChargeUseText = "";
  }

  return (
    <TableRow>
      <TableCell>{workType.name}</TableCell>
      <TableCell>
        {surChargeUseText}{" "}
        {surChargeUse ? (
          <IconButton onClick={handleRemoveClick}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </TableCell>
      <TableCell width={iconColumnWidth}>
        <IconButton onClick={handleEditClick}>
          <PencilIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

interface WorkTypePriceGroupPricePercentFuelSurchargeCardProps {
  priceGroup: PriceGroup;
}

export const WorkTypePriceGroupPricePercentFuelSurchargeCard = React.memo(
  function WorkTypePriceGroupPricePercentFuelSurchargeCard(
    props: WorkTypePriceGroupPricePercentFuelSurchargeCardProps,
  ): JSX.Element {
    const {priceGroup} = props;
    const priceGroupUrl = priceGroup.url;

    const workTypeArray = useSelector(getWorkTypeArray);
    const pricePercentWorkTypeFuelSurchargeUseArray = useSelector(
      getPricePercentWorkTypeFuelSurchargeUseArray,
    );

    const intl = useIntl();

    const dispatch = useDispatch();

    const priceGroupSurchargeUses = useMemo(
      () =>
        pricePercentWorkTypeFuelSurchargeUseArray.filter(
          (surchargeUse) =>
            surchargeUse.customer === null && surchargeUse.variant === priceGroupUrl,
        ),
      [priceGroupUrl, pricePercentWorkTypeFuelSurchargeUseArray],
    );

    const workTypesSurchargeUses = useMemo(
      () =>
        new Map(
          priceGroupSurchargeUses.map((surchargeUse) => [surchargeUse.workType, surchargeUse]),
        ),
      [priceGroupSurchargeUses],
    );

    const workTypes = _.sortBy(
      workTypeArray.filter(
        (workType) =>
          workTypesSurchargeUses.has(workType.url) || workType.pricegroups.includes(priceGroupUrl),
      ),
      [(workType) => workType.name, (workType) => workType.url],
    );

    const [fuelSurchargeDialogOpenForWorkType, setFuelSurchargeDialogOpenForWorkType] =
      useState<WorkTypeUrl>();

    const handleFuelSurchargeDialogCancel = useCallback(() => {
      setFuelSurchargeDialogOpenForWorkType(undefined);
    }, []);

    const handleRemoveClick = useCallback(
      (fuelSurchargeUseUrl: string): void => {
        dispatch(actions.remove(fuelSurchargeUseUrl));
      },
      [dispatch],
    );

    const handleFuelSurchargeDialogOk = useCallback(
      (fuelSurchargeUrl?: PricePercentFuelSurchargeSpecificationUrl): void => {
        if (!fuelSurchargeDialogOpenForWorkType) {
          return;
        }
        const workTypeUrl = fuelSurchargeDialogOpenForWorkType;
        setFuelSurchargeDialogOpenForWorkType(undefined);
        const currentUse = workTypesSurchargeUses.get(workTypeUrl);

        const value = fuelSurchargeUrl ?? null;
        if (currentUse && value === currentUse.fuelSurcharge) {
          return;
        } else if (currentUse) {
          dispatch(actions.remove(currentUse.url));
        }
        const id = uuid();
        const url = instanceURL("pricePercentWorkTypeFuelSurchargeUse", id);
        const instance: PricePercentWorkTypeFuelSurchargeUse = {
          customer: null,
          fuelSurcharge: value,
          id,
          url,
          variant: priceGroupUrl,
          workType: workTypeUrl,
        };
        dispatch(actions.create(instance));
      },
      [dispatch, fuelSurchargeDialogOpenForWorkType, priceGroupUrl, workTypesSurchargeUses],
    );

    const rows = workTypes.map((workType) => (
      <WorkTypePriceGroupPricePercentFuelSurchargeRow
        key={workType.url}
        surChargeUse={workTypesSurchargeUses.get(workType.url)}
        workType={workType}
        onEditClick={setFuelSurchargeDialogOpenForWorkType}
        onRemoveClick={handleRemoveClick}
      />
    ));

    return (
      <>
        <Card>
          <CardHeader
            title={intl.formatMessage({
              defaultMessage: "Brændstoftillæg for brug ved områder",
            })}
          />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage defaultMessage="Område" />
                </TableCell>
                <TableCell>
                  <FormattedMessage defaultMessage="Brændstoftillæg" />
                </TableCell>
                <TableCell width={iconColumnWidth} />
              </TableRow>
            </TableHead>
            <TableBody>{rows}</TableBody>
          </Table>
        </Card>
        <ConnectedPricePercentFuelSurchargeSpecificationDialog
          open={!!fuelSurchargeDialogOpenForWorkType}
          onCancel={handleFuelSurchargeDialogCancel}
          onNone={handleFuelSurchargeDialogOk}
          onOk={handleFuelSurchargeDialogOk}
        />
      </>
    );
  },
);
