import {PriceItem, Product, Unit, UnitUrl} from "@co-common-libs/resources";

export const getUnitString = (
  priceItemOrProduct?: PriceItem | Product,
  unitLookup?: (url: UnitUrl) => Unit | undefined,
): string => {
  const unitURL = priceItemOrProduct && priceItemOrProduct.relatedUnit;
  if (unitURL && unitLookup) {
    const unitInstance = unitLookup(unitURL);
    if (unitInstance) {
      const {symbol} = unitInstance;
      if (symbol) {
        return symbol;
      }
      const {name} = unitInstance;
      return name;
    }
  }
  return "";
};

export const getUnitCode = (
  priceItemOrProduct: PriceItem | Product,
  unitLookup: (url: UnitUrl) => Unit | undefined,
): string => {
  const unitURL = priceItemOrProduct && priceItemOrProduct.relatedUnit;
  if (unitURL && unitLookup) {
    const unitInstance = unitLookup(unitURL);
    if (unitInstance) {
      const {name} = unitInstance;
      return name;
    }
  }
  return "";
};

export const formatUnitString = (unit: Unit | undefined): string => {
  return unit?.symbol || unit?.name || "";
};
