import {CustomerUrl, MachineUrl, PriceGroupUrl, WorkTypeUrl} from "@co-common-libs/resources";
import {getCustomerSettings} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {IconButton, TableCell, TableRow, useTheme} from "@material-ui/core";
import AlertCircleIcon from "mdi-react/AlertCircleIcon";
import InformationOutlineIcon from "mdi-react/InformationOutlineIcon";
import React, {useState} from "react";
import {FormattedMessage, FormattedNumber} from "react-intl";
import {useSelector} from "react-redux";
import {FuelSurchargeConsumptionMissingDialog} from "../invoice-line-table/fuel-surcharge-consumption-missing-dialog";
import {FuelSurchargePeriodMissingDialog} from "../invoice-line-table/fuel-surcharge-period-missing-dialog";
import {SurchargeReasonDialog} from "../invoice-line-table/surcharge-reason-dialog";
import {CORRECTED_COLUMN_WIDTH, COUNT_COLUMN_WIDTH} from "./constants";

interface RouteTaskKrPerLiterSurchargeRowProps {
  missingFuelConsumptionLiterPerHour: boolean;
  missingSpecificationEntry: boolean;
  text: string;
  uses: ReadonlySet<{
    readonly customer: CustomerUrl | null;
    readonly machine?: MachineUrl | null;
    readonly variant?: PriceGroupUrl | null;
    readonly workType?: WorkTypeUrl | null;
  }>;
  value: number | null;
}

export const RouteTaskKrPerLiterSurchargeRow = React.memo(function RouteTaskKrPerLiterSurchargeRow(
  props: RouteTaskKrPerLiterSurchargeRowProps,
): JSX.Element {
  const {missingFuelConsumptionLiterPerHour, missingSpecificationEntry, text, uses, value} = props;

  const customerSettings = useSelector(getCustomerSettings);

  const theme = useTheme();

  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const setInfoDialogOpenTrue = useCallWithTrue(setInfoDialogOpen);
  const setInfoDialogOpenFalse = useCallWithFalse(setInfoDialogOpen);

  const [missingSpecificationDialogOpen, setMissingSpecificationDialogOpen] = useState(false);
  const setMissingSpecificationDialogOpenTrue = useCallWithTrue(setMissingSpecificationDialogOpen);
  const setMissingSpecificationDialogOpenFalse = useCallWithFalse(
    setMissingSpecificationDialogOpen,
  );

  const [missingFuelConsumptionDialogOpen, setMissingFuelConsumptionDialogOpen] = useState(false);
  const setMissingFuelConsumptionDialogOpenTrue = useCallWithTrue(
    setMissingFuelConsumptionDialogOpen,
  );
  const setMissingFuelConsumptionDialogOpenFalse = useCallWithFalse(
    setMissingFuelConsumptionDialogOpen,
  );

  return (
    <TableRow>
      <TableCell>
        &nbsp;&nbsp;&nbsp;&nbsp; {text}
        {missingSpecificationEntry ? (
          <IconButton onClick={setMissingSpecificationDialogOpenTrue}>
            <AlertCircleIcon color={theme.palette.error.main} />
          </IconButton>
        ) : null}
        {missingFuelConsumptionLiterPerHour ? (
          <IconButton onClick={setMissingFuelConsumptionDialogOpenTrue}>
            <AlertCircleIcon color={theme.palette.warning.main} />
          </IconButton>
        ) : null}
        <IconButton onClick={setInfoDialogOpenTrue}>
          <InformationOutlineIcon />
        </IconButton>
      </TableCell>
      <TableCell style={{textAlign: "right", width: COUNT_COLUMN_WIDTH}}>
        {value !== null ? <FormattedNumber value={value} /> : null}
      </TableCell>
      <TableCell style={{width: CORRECTED_COLUMN_WIDTH}} />
      <TableCell>
        <FormattedMessage defaultMessage="kr/L" />
      </TableCell>
      {customerSettings.showNoteOnInvoiceLineTable ? <TableCell /> : null}
      <SurchargeReasonDialog open={infoDialogOpen} uses={uses} onClose={setInfoDialogOpenFalse} />
      <FuelSurchargePeriodMissingDialog
        open={missingSpecificationDialogOpen}
        onClose={setMissingSpecificationDialogOpenFalse}
      />
      <FuelSurchargeConsumptionMissingDialog
        open={missingFuelConsumptionDialogOpen}
        onClose={setMissingFuelConsumptionDialogOpenFalse}
      />
    </TableRow>
  );
});
