type PromiseFactory<T> = () => Promise<T>;

export const sharePromiseFactory = <T>(fn: PromiseFactory<T>): PromiseFactory<T> => {
  if (typeof fn !== "function") {
    throw new TypeError(`parameter not a function: ${fn}`);
  }
  let instance: T | null = null;
  let started = false;
  let failed = false;
  let failedReason: any;
  type Resolve = (value: T) => void;
  type Reject = (reason: any) => void;
  let waiting: {reject: Reject; resolve: Resolve}[] = [];
  return (): Promise<T> => {
    if (instance) {
      return Promise.resolve(instance);
    } else if (failed) {
      return Promise.reject(failedReason);
    } else if (started) {
      return new Promise((resolve: Resolve, reject: Reject) => {
        waiting.push({reject, resolve});
      });
    } else {
      started = true;
      waiting = [];
      const promise: Promise<T> = new Promise((resolve, reject) => {
        waiting.push({reject, resolve});
      });
      const resolveWaiting = (value: T): void => {
        instance = value;
        const waitingAtResolve = waiting;
        waiting = [];
        waitingAtResolve.forEach(({resolve}) => {
          resolve(value);
        });
      };
      const rejectWaiting = (reason: any): void => {
        failed = true;
        failedReason = reason;
        const waitingAtReject = waiting;
        waiting = [];
        waitingAtReject.forEach(({reject}) => {
          reject(reason);
        });
      };
      fn()
        .then(resolveWaiting, rejectWaiting)
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        });
      return promise;
    }
  };
};
