import {CustomerUrl, JournalUrl, LocationUrl, WorkTypeUrl} from "../resource-urls";
import {ResourceInstance} from "./base";

export interface Journal extends ResourceInstance<JournalUrl> {
  readonly customer: CustomerUrl;
  readonly deviceTimestamp: string;
  readonly entry: string;
  readonly locations: readonly LocationUrl[] | undefined;
  readonly reminder: boolean;
  readonly serverTimestamp?: string;
  readonly workTypes: readonly WorkTypeUrl[];
}

export const emptyJournal: Omit<Journal, "customer" | "deviceTimestamp" | "id" | "url"> = {
  entry: "",
  locations: [],
  reminder: false,
  workTypes: [],
};
