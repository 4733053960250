import {Dispatch, useMemo} from "react";
import {SetObjectPropertyAction} from "./types";

class SimpleObjectPropertyHandleChangeDispatcher<T extends Record<string, any>> {
  private cache = new Map<keyof T, (value: any) => void>();

  constructor(private dispatch: Dispatch<SetObjectPropertyAction<T>>) {}

  handleChange<PropertyKey extends keyof T>(
    propertyName: PropertyKey,
  ): (value: T[PropertyKey]) => void {
    const cached = this.cache.get(propertyName);
    if (cached) {
      return cached;
    }

    const propertyHandler = (value: T[PropertyKey]): void =>
      this.dispatch({propertyName, type: "set", value});

    this.cache.set(propertyName, propertyHandler);
    return propertyHandler;
  }
}

export function useObjectPropertyOnChangeDispatcher<T extends Record<string, any>>(
  dispatch: Dispatch<SetObjectPropertyAction<T>>,
): SimpleObjectPropertyHandleChangeDispatcher<T> {
  return useMemo(() => new SimpleObjectPropertyHandleChangeDispatcher<T>(dispatch), [dispatch]);
}
