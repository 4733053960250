import {ConnectedMultipleDepartmentsDialog} from "@co-frontend-libs/connected-components";
import {
  actions,
  getCurrentUserURL,
  getCustomerSettings,
  getSettingsEntryLookupByIdentifier,
} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import React, {useCallback, useState} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {SettingViewProps} from "../types";
import {DisplaySelected} from "./display-selected";

export function DepartmentsSelect(props: SettingViewProps): JSX.Element {
  const {settingID, settingMetaData} = props;
  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const settingEntry = settingsEntryLookupByIdentifier(settingID);
  const selectedDepartments: string[] = settingEntry?.data || [];
  const customerSettings = useSelector(getCustomerSettings);
  const {departments} = customerSettings;
  const intl = useIntl();
  const departmentNames = selectedDepartments.map(
    (d) => departments[d] || intl.formatMessage({defaultMessage: "Afdeling eksisterer ikke"}),
  );

  const [departmentDialogOpen, setDepartmentDialogOpen] = useState(false);
  const setDepartmentDialogOpenTrue = useCallWithTrue(setDepartmentDialogOpen);
  const setDepartmentDialogOpenFalse = useCallWithFalse(setDepartmentDialogOpen);

  const dispatch = useDispatch();
  const currentUserURL = useSelector(getCurrentUserURL);
  const handleDepartmentDialogOk = useCallback(
    (urls: ReadonlySet<string>) => {
      setDepartmentDialogOpen(false);

      const newValue = [...urls];
      if (settingEntry) {
        dispatch(
          actions.update(settingEntry.url, [
            {member: "changedBy", value: currentUserURL},
            {member: "data", value: newValue},
          ]),
        );
      }
    },
    [currentUserURL, dispatch, settingEntry],
  );

  return (
    <>
      <DisplaySelected
        currentValue={departmentNames}
        settingEntry={settingEntry}
        settingID={settingID}
        settingMetaData={settingMetaData}
        onClick={setDepartmentDialogOpenTrue}
      />
      <ConnectedMultipleDepartmentsDialog
        open={departmentDialogOpen}
        selected={new Set(selectedDepartments)}
        onCancel={setDepartmentDialogOpenFalse}
        onOk={handleDepartmentDialogOk}
      />
    </>
  );
}
