import _ from "lodash";
import {ResourceTypeUnion} from "./resource-data-map";
import {ResourceTypes} from "./resource-names";
import {ResourceInstance} from "./resource-types";

export interface CreateCommand {
  readonly action: "CREATE";
  readonly instance: ResourceTypeUnion;
  readonly url: string;
}

// {member: X, value: Y} where Y has the right type for T[X]
export type MemberPatchOperation<T extends ResourceInstance> = {
  [M in keyof T]: {readonly member: M; readonly value: T[M]};
}[Exclude<keyof T, "id" | "url">];

export interface PathPatchOperation {
  readonly path: readonly string[];
  readonly value: unknown;
}

export type PatchOperation<T extends ResourceInstance> =
  | MemberPatchOperation<T>
  | PathPatchOperation;

export function isMemberPatchOperation<T extends ResourceInstance>(
  patchOperation: MemberPatchOperation<T> | PathPatchOperation,
): patchOperation is MemberPatchOperation<T> {
  console.assert(
    patchOperation !== undefined,
    "isMemberPatchOperation called with invalid value undefined",
  );
  return !!(patchOperation as Partial<MemberPatchOperation<T> & PathPatchOperation>).member;
}

export type Patch<T extends ResourceInstance> = readonly PatchOperation<T>[];

type PatchResourceHelper = {
  [Property in keyof ResourceTypes]: Patch<ResourceTypes[Property]>;
};

export type PatchUnion = PatchResourceHelper[keyof PatchResourceHelper];

export interface UpdateCommand {
  readonly action: "UPDATE";
  readonly patch: PatchUnion;
  readonly url: string;
}

export interface DeleteCommand {
  readonly action: "DELETE";
  readonly url: string;
}

export type Command = CreateCommand | DeleteCommand | UpdateCommand;

export function applyPatch<T extends ResourceInstance>(obj: T, patch: Patch<T>): T {
  const result = {...obj};
  for (const operation of patch) {
    const {value} = operation;
    if (isMemberPatchOperation(operation)) {
      const {member} = operation;
      _.set(result, member, value);
    } else {
      const {path} = operation;
      for (let i = 1; i < path.length; i += 1) {
        const pathPart = path.slice(0, i);
        const oldPathValue = _.get(result, pathPart);
        const newPathValue =
          oldPathValue != null ? {...oldPathValue} : typeof _.last(pathPart) === "number" ? [] : {};
        _.set(result, pathPart, newPathValue);
      }
      if (value !== undefined) {
        _.set(result, path, value);
      } else {
        _.unset(result, path);
      }
    }
  }
  return result;
}
