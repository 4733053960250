import {ResponsiveInfoDialog} from "@co-frontend-libs/components";
import {DialogContent} from "@material-ui/core";
import React from "react";
import {FormattedMessage, useIntl} from "react-intl";

interface FuelSurchargePeriodMissingDialogProps {
  onClose: () => void;
  open: boolean;
}

export const FuelSurchargePeriodMissingDialog = React.memo(
  function FuelSurchargePeriodMissingDialog(
    props: FuelSurchargePeriodMissingDialogProps,
  ): JSX.Element {
    const {onClose, open} = props;

    const intl = useIntl();

    return (
      <ResponsiveInfoDialog
        open={open}
        title={intl.formatMessage({defaultMessage: "Pris mangler for periode"})}
        onClose={onClose}
      >
        <DialogContent>
          <FormattedMessage defaultMessage="Tillæg kan ikke beregnes da brændstofpris på opgavens tidspunkt ikke er angivet" />
        </DialogContent>
      </ResponsiveInfoDialog>
    );
  },
);
