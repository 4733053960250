import {Config} from "@co-common-libs/config";
import {WorkType} from "@co-common-libs/resources";
import {IntlShape} from "react-intl";
import {InlinedTask} from "../../inline-data";

export const getRequiredProductUseNotFoundError = (
  customerOptions: Config,
  intl: IntlShape,
  task: Pick<InlinedTask, "productuseSet">,
  workType: WorkType,
  requireAtLeastOneProductUseGreaterThanZero: boolean | undefined,
): string | null => {
  const productUseList = task.productuseSet;
  const {identifier: workTypeIdentifier, name: workTypeName} = workType;

  if (
    requireAtLeastOneProductUseGreaterThanZero &&
    (!productUseList.length ||
      !productUseList.some((productUse) => productUse.count && productUse.count > 0))
  ) {
    if (customerOptions.materialUseAlternativeText) {
      return intl.formatMessage(
        {
          defaultMessage:
            "Der skal tilføjes mindst en materiale-linje, med værdi større end nul, for opgaver med arbejdsområde {workTypeName} ({workTypeIdentifier}).",
        },
        {workTypeIdentifier, workTypeName},
      );
    } else {
      return intl.formatMessage(
        {
          defaultMessage:
            "Der skal tilføjes mindst en materiel-linje, med værdi større end nul, for opgaver med arbejdsområde {workTypeName} ({workTypeIdentifier}).",
        },
        {workTypeIdentifier, workTypeName},
      );
    }
  }

  return null;
};
