import {actions, getTablePaginationState, getTableSortingState} from "@co-frontend-libs/redux";
import {usePrevious, useUnmountEffect, useUpdateEffect} from "@react-hookz/web";
import {useNumericQueryParameterState, useQueryParameterState} from "app-utils";
import {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";

type SortDirection = "ASC" | "DESC";

/**
 *
 * Hook to be used with `TableWithPagination` to manage pagination and sorting state.
 *
 * @param {string} saveSortingIdentifier - Identifier used as key to store pagination preferences for the current table.
 * @param {string} savePaginationIdentifier - Identifier used as key to store sorting preferences for the current table.
 * @param {ColumnID extends string} defaultSortKey - Fallback column if no query param or device state.
 * @param {SortDirection} defaultSortDirection - Fallback direction if no query param or device state.
 * @param {number} defaultRowsPerPage - Fallback rows per page if no query param or device state.
 * @param {Record<string, unknown> | null} filteringData - Data that should reset pagination to first page on changes.
 */
export function useTableWithPagination<ColumnID extends string>(
  saveSortingIdentifier: string,
  savePaginationIdentifier: string,
  defaultSortKey: ColumnID,
  defaultSortDirection: SortDirection,
  defaultRowsPerPage: number,
  filteringData: Record<string, unknown> | null,
): [
  currentPage: number,
  rowsPerPage: number,
  sortingKey: ColumnID,
  SortDirection: SortDirection,
  setCurrentPage: (page: number) => void,
  setRowsPerPage: (rows: number) => void,
  toggleSortingKey: (key: ColumnID) => void,
] {
  const dispatch = useDispatch();

  const tableSortState = useSelector(
    getTableSortingState(saveSortingIdentifier, defaultSortKey, defaultSortDirection),
  );

  const tablePaginationState = useSelector(
    getTablePaginationState(savePaginationIdentifier, defaultRowsPerPage),
  );

  const [sortDirection, setSortDirectionQueryParam] = useQueryParameterState<SortDirection>(
    "sortDirection",
    tableSortState.sortDirection,
    true,
  );

  const [sortingKey, setSortingKeyQueryParam] = useQueryParameterState<ColumnID>(
    "sortKey",
    tableSortState.sortKey as ColumnID,
    true,
  );

  const [rowsPerPage, setRowsPerPageQueryParam] = useNumericQueryParameterState(
    "rows",
    tablePaginationState.rowsPerPage,
    true,
  );

  const [currentPage, setCurrentPageQueryParam] = useNumericQueryParameterState("page", 0, true);

  const setPage = useCallback(
    (newPage: number) => {
      setCurrentPageQueryParam(newPage);
    },
    [setCurrentPageQueryParam],
  );

  const setRowsPerPage = useCallback(
    (newRowsPerPage: number) => {
      if (newRowsPerPage !== rowsPerPage) {
        setRowsPerPageQueryParam(newRowsPerPage);
        setPage(0);
      }

      if (newRowsPerPage !== tablePaginationState.rowsPerPage) {
        dispatch(actions.putTablePaginationState(savePaginationIdentifier, newRowsPerPage));
      }
    },
    [
      dispatch,
      rowsPerPage,
      savePaginationIdentifier,
      setPage,
      setRowsPerPageQueryParam,
      tablePaginationState.rowsPerPage,
    ],
  );

  const setSortingDirection = useCallback(
    (newDirection: SortDirection) => {
      if (newDirection !== sortDirection) {
        setSortDirectionQueryParam(newDirection);
      }
    },
    [setSortDirectionQueryParam, sortDirection],
  );

  const setSortingKey = useCallback(
    (newKey: ColumnID) => {
      if (newKey !== sortingKey) {
        setSortingKeyQueryParam(newKey);
      }
    },
    [setSortingKeyQueryParam, sortingKey],
  );

  const toggleSortingKey = useCallback(
    (newKey: ColumnID) => {
      let newDirection: SortDirection = "ASC";
      if (sortingKey === newKey && sortDirection === "ASC") {
        newDirection = "DESC";
      }

      setPage(0);
      setSortingKey(newKey);
      setSortingDirection(newDirection);

      if (newDirection !== tableSortState.sortDirection || newKey !== tableSortState.sortKey) {
        dispatch(actions.putTableSortingState(saveSortingIdentifier, newKey, newDirection));
      }
    },
    [
      dispatch,
      saveSortingIdentifier,
      setPage,
      setSortingDirection,
      setSortingKey,
      sortDirection,
      sortingKey,
      tableSortState.sortDirection,
      tableSortState.sortKey,
    ],
  );

  useUnmountEffect(() => {
    dispatch(actions.deleteQueryKey("sortKey"));
    dispatch(actions.deleteQueryKey("sortDirection"));
    dispatch(actions.deleteQueryKey("rows"));
    dispatch(actions.deleteQueryKey("page"));
  });

  useUpdateEffect(() => {
    setPage(0);
    setSortingKey(tableSortState.sortKey as ColumnID);
    setSortingDirection(tableSortState.sortDirection);
    setRowsPerPage(tablePaginationState.rowsPerPage);
    // reset table sorting and pagination on save identifier changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveSortingIdentifier, savePaginationIdentifier]);

  useUpdateEffect(() => {
    setPage(0);
    // reset table pagination page on filteringData change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteringData]);

  const previousSortingIdentifier = usePrevious(saveSortingIdentifier);
  const previousPaginationIdentifier = usePrevious(savePaginationIdentifier);
  const previousFilterData = usePrevious(filteringData);

  const filteringDataChanged =
    previousFilterData !== undefined && previousFilterData !== filteringData;
  const sortingIdentifierChanged =
    previousSortingIdentifier !== undefined && previousSortingIdentifier !== saveSortingIdentifier;
  const paginationIdentifierChanged =
    previousPaginationIdentifier !== undefined &&
    previousPaginationIdentifier !== savePaginationIdentifier;
  const saveIdentifierChanged = sortingIdentifierChanged || paginationIdentifierChanged;

  // When save identifier or filtering data changes then
  // return reset values immediately, *before* useEffect updates query param
  // and trigger another render.
  // Otherwise, the underlying table may attempt to display a non-existing page, or
  // sort on non-existing column.
  return [
    saveIdentifierChanged || filteringDataChanged ? 0 : currentPage,
    saveIdentifierChanged ? tablePaginationState.rowsPerPage : rowsPerPage,
    saveIdentifierChanged ? (tableSortState.sortKey as ColumnID) : sortingKey,
    saveIdentifierChanged ? tableSortState.sortDirection : sortDirection,
    setPage,
    setRowsPerPage,
    toggleSortingKey,
  ];
}
