import {CustomerUrl, FieldUse} from "@co-common-libs/resources";
import {getFieldBoundingBox} from "@co-common-libs/resources-utils";
import {notUndefined} from "@co-common-libs/utils";
import {FieldLabels} from "@co-frontend-libs/components";
import {
  AppState,
  getCropColorMap,
  getCustomerSettings,
  getLocationLookup,
  makeSelectCustomerActiveFields,
} from "@co-frontend-libs/redux";
import {CircularProgress} from "@material-ui/core";
import {grey} from "@material-ui/core/colors";
import {GoogleMap, useLoadGoogleMaps} from "app-utils";
import bowser from "bowser";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {FieldPolygons} from "../field-polygons";
import {CurrentLocationButton} from "../geolocation-map/current-location-button";
import {CurrentLocationMarker} from "../geolocation-map/current-location-marker";
import {FieldsInformation} from "../geolocation-map/fields-information";

const mapContainerStyle: React.CSSProperties = {height: 600, width: "100%"};

const baseMapOptions: google.maps.MapOptions = {
  clickableIcons: false,
  mapTypeControl: true,
  mapTypeControlOptions: {
    mapTypeIds: ["hybrid", "roadmap", "satellite"],
  },
  streetViewControl: false,
  styles: [
    {featureType: "poi", stylers: [{visibility: "off"}]},
    {featureType: "transit", stylers: [{visibility: "off"}]},
  ],
};

interface FieldsMapProps {
  customerURL: CustomerUrl | null;
  extraLabels?: ReadonlyMap<string, string> | undefined;
  fieldUseList: readonly FieldUse[];
}

export function FieldsMap(props: FieldsMapProps): JSX.Element {
  const {customerURL, extraLabels, fieldUseList} = props;

  const {isLoaded /* loadError */} = useLoadGoogleMaps();

  const locationLookup = useSelector(getLocationLookup);
  const selectCustomerActiveFields = useMemo(makeSelectCustomerActiveFields, []);
  const customerActiveFields = useSelector((state: AppState) =>
    selectCustomerActiveFields(state, customerURL),
  );
  const customerSettings = useSelector(getCustomerSettings);

  const selectedFields = useMemo(
    () =>
      fieldUseList.map((fieldUse) => locationLookup(fieldUse.relatedField)).filter(notUndefined),
    [fieldUseList, locationLookup],
  );

  const customerOtherFields = useMemo(
    () =>
      customerActiveFields.filter((field) =>
        fieldUseList.every((fieldUse) => fieldUse.relatedField !== field.url),
      ),
    [customerActiveFields, fieldUseList],
  );

  const cropColorMap = useSelector(getCropColorMap);

  const greyCropColorMap = useMemo(
    () => new Map(Array.from(cropColorMap.entries()).map(([key, _value]) => [key, grey[400]])),
    [cropColorMap],
  );

  const selectedFieldUrls = useMemo(
    () => new Set(selectedFields.map(({url}) => url)),
    [selectedFields],
  );

  const [mapInstance, setMapInstance] = useState<google.maps.Map | null>(null);

  const mapOptions = useMemo((): google.maps.MapOptions => {
    return {
      ...baseMapOptions,
      center: {
        lat: customerSettings.geolocation.initialPositionLatitude,
        lng: customerSettings.geolocation.initialPositionLongitude,
      },
      keyboardShortcuts: !bowser.mobile && !bowser.tablet,
      zoom: customerSettings.geolocation.initialZoom,
    };
  }, [
    customerSettings.geolocation.initialPositionLatitude,
    customerSettings.geolocation.initialPositionLongitude,
    customerSettings.geolocation.initialZoom,
  ]);

  const computedBounds = useMemo(() => {
    if (isLoaded) {
      const bounds = new google.maps.LatLngBounds();
      fieldUseList.forEach((fieldUse) => {
        const field = locationLookup(fieldUse.relatedField);
        if (field) {
          const bbox = getFieldBoundingBox(field);
          const [west, south, east, north] = bbox;
          bounds.extend({lat: south, lng: west});
          bounds.extend({lat: north, lng: east});
        }
      });
      if (!bounds.isEmpty()) {
        // As string to have equality check for triggering useEffect behave.
        return JSON.stringify(bounds);
      }
    }
    return "";
  }, [fieldUseList, isLoaded, locationLookup]);

  const handleLoad = useCallback((map: google.maps.Map) => {
    setMapInstance(map);
  }, []);

  useEffect(() => {
    if (isLoaded && mapInstance && computedBounds) {
      mapInstance.fitBounds(JSON.parse(computedBounds));
    }
  }, [computedBounds, isLoaded, mapInstance]);

  if (isLoaded) {
    return (
      <GoogleMap
        id="map"
        mapContainerStyle={mapContainerStyle}
        options={mapOptions}
        onLoad={handleLoad}
      >
        {mapInstance ? (
          <>
            <div style={{position: "absolute", right: 0}}>
              <CurrentLocationButton googleMap={mapInstance} />
            </div>
            <CurrentLocationMarker map={mapInstance} />
            <FieldPolygons
              clusterColor={grey[700]}
              clusterZIndex={0}
              cropColorMap={greyCropColorMap}
              locations={customerOtherFields}
              map={mapInstance}
              readonlySet={undefined}
              selected={undefined}
              onSelect={undefined}
            />
            <FieldPolygons
              clusterZIndex={1}
              cropColorMap={greyCropColorMap}
              locations={selectedFields}
              map={mapInstance}
              readonlySet={undefined}
              selected={selectedFieldUrls}
              onSelect={undefined}
            />
            {customerSettings.showStorageOnTaskFieldMap ? (
              <FieldsInformation googleMapsLinks fields={selectedFields} map={mapInstance} />
            ) : (
              <FieldLabels
                alwaysShow
                googleMapsLinks
                includeArea
                extraLabels={extraLabels}
                fieldArray={selectedFields}
                mapInstance={mapInstance}
              />
            )}
          </>
        ) : null}
      </GoogleMap>
    );
  } else {
    return (
      <div style={{marginTop: 16, textAlign: "center"}}>
        <CircularProgress />
      </div>
    );
  }
}
