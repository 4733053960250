import {RemunerationReportUrl, UserUrl} from "../resource-urls";
import {ResourceInstance} from "./base";

export interface RemunerationReport extends ResourceInstance<RemunerationReportUrl> {
  readonly createdBy: UserUrl;
  readonly csvDownload: string | null;
  readonly customerSettings: unknown;
  readonly dataUrl: string | null;
  readonly deviceTimestamp: string;
  readonly employeesWithUncompletedTasks: readonly UserUrl[] | undefined;
  readonly error: string;
  readonly fromDate: string;
  readonly includedEmployees: readonly UserUrl[] | undefined;
  readonly notValidatedPresentAtCreation: boolean;
  readonly onlyValidated: boolean;
  readonly pdfDownload: string | null;
  readonly remunerationGroup: string;
  readonly serverTimestamp?: string;
  readonly settingsUrl?: string;
  readonly syncDoneTimestamp: string | null;
  readonly syncError: any | null;
  readonly syncStartTimestamp: string | null;
  readonly title: string;
  readonly toDate: string;
  readonly useForSalaryVouchers: boolean;
}

export const emptyRemunerationReport: Omit<
  RemunerationReport,
  "createdBy" | "deviceTimestamp" | "fromDate" | "id" | "toDate" | "url"
> = {
  csvDownload: null,
  customerSettings: undefined,
  dataUrl: null,
  employeesWithUncompletedTasks: undefined,
  error: "",
  includedEmployees: undefined,
  notValidatedPresentAtCreation: false,
  onlyValidated: false,
  pdfDownload: null,
  remunerationGroup: "",
  syncDoneTimestamp: null,
  syncError: null,
  syncStartTimestamp: null,
  title: "",
  useForSalaryVouchers: false,
};
