import {Machine, MachineUrl} from "@co-common-libs/resources";

export function getMachineFuelConsumption<T extends Record<string, unknown>>(
  machineLookup: (url: MachineUrl) => Pick<Machine, "fuelConsumptionLiterPerHour"> | undefined,
  matches: ReadonlyMap<MachineUrl, T>,
): Map<
  MachineUrl,
  T & {
    fuelConsumptionLiterPerHour: number | null;
  }
> {
  return new Map(
    Array.from(matches).map(([machineUrl, match]) => {
      if (match) {
        const fuelConsumptionLiterPerHour =
          machineLookup(machineUrl)?.fuelConsumptionLiterPerHour ?? null;
        return [machineUrl, {fuelConsumptionLiterPerHour, ...match}];
      } else {
        return [machineUrl, match];
      }
    }),
  );
}
