import {
  Customer,
  CustomerUrl,
  Order,
  OrderUrl,
  Task,
  TimeCorrection,
  TimerStart,
} from "@co-common-libs/resources";
import {getNormalisedDeviceTimestamp} from "@co-common-libs/resources-utils";
import {WorkStatus} from "@co-common-libs/utils";

const EMPTY_TIME_CORRECTION_ARRAY: readonly TimeCorrection[] = [];

export const computeTaskStatus = (
  task: Task,
  timerStartArray: readonly TimerStart[],
  orderLookup: (url: OrderUrl) => Order | undefined,
  customerLookup: (url: CustomerUrl) => Customer | undefined,
  economicSync: boolean,
): WorkStatus => {
  let customerError = false;
  if (economicSync && task.order) {
    const order = orderLookup(task.order);
    customerError = !!(order?.customer && customerLookup(order.customer)?.active === false);
  }
  if (task.recordedInC5) {
    return "recorded";
  } else if (
    (task.completed || task.validatedAndRecorded || task.reportApproved) &&
    (task.lastTransferError || customerError)
  ) {
    return "error";
  } else if (task.validatedAndRecorded || task.reportApproved) {
    return "validated";
  } else if (task.completed) {
    return "completed";
  } else if (!task.workFromTimestamp) {
    return "pending";
  } else {
    const taskURL = task.url;
    let anyStartTimerStart = false;
    let latestTimerStart: TimerStart | null = null;
    let latestTimerStartTimestamp = "";
    for (let i = 0; i < timerStartArray.length; i += 1) {
      const timerStart = timerStartArray[i];
      if (timerStart.task !== taskURL) {
        continue;
      }
      const deviceTimestamp = getNormalisedDeviceTimestamp(timerStart);
      if (deviceTimestamp > latestTimerStartTimestamp) {
        latestTimerStartTimestamp = deviceTimestamp;
        latestTimerStart = timerStart;
      }
      if (!anyStartTimerStart && timerStart.timer) {
        anyStartTimerStart = true;
      }
    }
    const latestTimerStartIsStart = latestTimerStart && latestTimerStart.timer;
    if (latestTimerStartIsStart) {
      return "active";
    } else {
      const correctionIntervals =
        task.machineOperatorTimeCorrectionSet || EMPTY_TIME_CORRECTION_ARRAY;
      const managerCorrectionIntervals =
        task.managerTimeCorrectionSet || EMPTY_TIME_CORRECTION_ARRAY;
      if (anyStartTimerStart || correctionIntervals.length || managerCorrectionIntervals.length) {
        return "paused";
      } else {
        return "pending";
      }
    }
  }
};
