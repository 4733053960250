import {Config} from "@co-common-libs/config";
import {IntlShape} from "@formatjs/intl";
import _ from "lodash";
import {CsvImportColumnSpecification, CsvImportSpecification} from "./types";

// we want specific `name` values from type inference rather than declaration
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function getImportSpecifications(intl: Pick<IntlShape, "formatMessage">) {
  const specifications = [
    {
      columns: [
        {
          label: intl.formatMessage({defaultMessage: "Navn"}),
          name: "name",
          required: true,
          type: "string",
        },
        {
          label: intl.formatMessage({defaultMessage: "Symbol"}),
          name: "symbol",
          required: false,
          type: "string",
        },
      ],
      label: intl.formatMessage({defaultMessage: "Enheder"}),
      name: "unit",
    },
    {
      availableColumns: (config, columns) =>
        config.economicSync
          ? columns.map((column) => {
              if (column.name === "c5Account") {
                return {...column, type: "integer"};
              } else {
                return column;
              }
            })
          : columns,
      columns: [
        {
          label: intl.formatMessage({defaultMessage: "Nummer"}),
          name: "c5Account",
          required: true,
          type: "string",
        },
        {
          label: intl.formatMessage({defaultMessage: "Navn"}),
          name: "name",
          required: true,
          type: "string",
        },
        {
          label: intl.formatMessage({defaultMessage: "Søgenavn"}),
          name: "alias",
          required: false,
          type: "string",
        },
        {
          label: intl.formatMessage({defaultMessage: "CVR"}),
          name: "vatNumber",
          required: false,
          type: "cvr",
        },
        {
          label: intl.formatMessage({defaultMessage: "Adresse"}),
          name: "address",
          required: false,
          type: "string",
        },
        {
          label: intl.formatMessage({defaultMessage: "Postnummer"}),
          name: "postalCode",
          required: false,
          type: "string",
        },
        {
          label: intl.formatMessage({defaultMessage: "By"}),
          name: "city",
          required: false,
          type: "string",
        },
        {
          label: intl.formatMessage({defaultMessage: "Telefon"}),
          name: "phone",
          required: false,
          type: "string",
        },
        {
          label: intl.formatMessage({defaultMessage: "Mobil"}),
          name: "cellphone",
          required: false,
          type: "string",
        },
        {
          label: intl.formatMessage({defaultMessage: "E-mail"}),
          name: "billingEmail",
          required: false,
          type: "email",
        },
        {
          label: intl.formatMessage({defaultMessage: "Formand"}),
          name: "projectManagerName",
          required: false,
          type: "string",
        },
        {
          label: intl.formatMessage({defaultMessage: "Favorit"}),
          name: "favorite",
          required: true,
          type: "boolean",
        },
        {
          label: intl.formatMessage({defaultMessage: "Modtag log e-mails"}),
          name: "receiveLogMails",
          required: true,
          type: "boolean",
        },
        {
          label: intl.formatMessage({defaultMessage: "Log e-mail"}),
          name: "logEmail",
          required: false,
          type: "email",
        },
        {
          label: intl.formatMessage({defaultMessage: "Lav fakturakladder"}),
          name: "createInvoices",
          required: true,
          type: "boolean",
        },
        {
          label: intl.formatMessage({
            defaultMessage: "Opgaver til denne kunde kan ikke godkendes",
          }),
          name: "disallowTaskValidation",
          required: true,
          type: "boolean",
        },
        {
          label: intl.formatMessage({defaultMessage: "Spærret"}),
          name: "barred",
          required: true,
          type: "boolean",
        },
      ],
      label: intl.formatMessage({defaultMessage: "Kunder"}),
      name: "customer",
    },
    {
      availableColumns: (config, columns) =>
        config.economicSync
          ? columns.map((column) => {
              if (column.name === "department") {
                return {...column, type: "integer"};
              } else {
                return column;
              }
            })
          : columns,
      columns: [
        {
          label: intl.formatMessage({defaultMessage: "Maskinenummer"}),
          name: "c5Machine",
          required: true,
          type: "integer",
        },
        {
          label: intl.formatMessage({defaultMessage: "Navn"}),
          name: "name",
          required: true,
          type: "string",
        },
        {
          label: intl.formatMessage({defaultMessage: "Selvkørende"}),
          name: "selfPropelled",
          required: true,
          type: "boolean",
        },
        {
          label: intl.formatMessage({defaultMessage: "Trækker"}),
          name: "canPull",
          required: true,
          type: "boolean",
        },
        {
          label: intl.formatMessage({
            defaultMessage: "Omkostning per time, bugsering (effektiv)",
          }),
          name: "hourlyTowingCostEffective",
          required: false,
          type: "decimal",
        },
        {
          label: intl.formatMessage({
            defaultMessage: "Omkostning per time, bugsering (transport)",
          }),
          name: "hourlyTowingCostTransport",
          required: false,
          type: "decimal",
        },
        {
          label: intl.formatMessage({
            defaultMessage:
              "Kræv at der er tilføjet mindst én vare på opgaven og at denne er udfyldt med en værdi større end 0",
          }),
          name: "requireAtLeastOneProductUseGreaterThanZero",
          required: true,
          type: "boolean",
        },
        {
          label: intl.formatMessage({
            defaultMessage: "Kræv billede ved opgaveudførsel hvor denne maskine er anvendt",
          }),
          name: "requirePhotoOnTaskCompletion",
          required: true,
          type: "boolean",
        },
        {
          label: intl.formatMessage({defaultMessage: "Lille maskine"}),
          name: "smallMachine",
          required: true,
          type: "boolean",
        },
        {
          label: intl.formatMessage({defaultMessage: "Afdeling"}),
          name: "department",
          required: false,
          type: "string",
        },
      ],
      label: intl.formatMessage({defaultMessage: "Maskiner"}),
      name: "machine",
    },
    {
      availableColumns: (config, columns) =>
        config.economicSync
          ? columns.map((column) => {
              if (column.name === "salesAccount") {
                return {...column, required: true};
              } else if (column.name === "identifier") {
                return {...column, type: "integer"};
              } else {
                return column;
              }
            })
          : columns,
      columns: [
        {
          label: intl.formatMessage({defaultMessage: "Nummer"}),
          name: "identifier",
          required: true,
          type: "string",
        },
        {
          label: intl.formatMessage({defaultMessage: "Navn"}),
          name: "name",
          required: true,
          type: "string",
        },
        {
          label: intl.formatMessage({defaultMessage: "konto"}),
          name: "salesAccount",
          required: false,
          type: "integer",
        },
      ],
      label: intl.formatMessage({defaultMessage: "Varegrupper"}),
      name: "productGroup",
    },
    {
      columns: [
        {
          label: intl.formatMessage({defaultMessage: "Brugernavn"}),
          name: "loginIdentifier",
          required: true,
          type: "string",
        },
        {
          label: intl.formatMessage({defaultMessage: "Kodeord"}),
          name: "password",
          required: true,
          type: "string",
        },

        {
          label: intl.formatMessage({defaultMessage: "Navn"}),
          name: "name",
          required: false,
          type: "string",
        },
        {
          label: intl.formatMessage({defaultMessage: "Initialer"}),
          name: "alias",
          required: true,
          type: "string",
        },
        {
          label: intl.formatMessage({defaultMessage: "Adresse"}),
          name: "address",
          required: false,
          type: "string",
        },
        {
          label: intl.formatMessage({defaultMessage: "Postnummer"}),
          name: "postalCode",
          required: false,
          type: "string",
        },
        {
          label: intl.formatMessage({defaultMessage: "By"}),
          name: "city",
          required: false,
          type: "string",
        },
        {
          label: intl.formatMessage({defaultMessage: "E-mail"}),
          name: "email",
          required: false,
          type: "email",
        },
        {
          label: intl.formatMessage({defaultMessage: "Mobil"}),
          name: "cellphone",
          required: false,
          type: "string",
        },
        {
          label: intl.formatMessage({defaultMessage: "Mobil 2"}),
          name: "cellphoneExtra",
          required: false,
          type: "string",
        },
        {
          label: intl.formatMessage({defaultMessage: "Note"}),
          name: "note",
          required: false,
          type: "string",
        },
        {
          label: intl.formatMessage({defaultMessage: "Fødselsdato"}),
          name: "birthDate",
          required: false,
          type: "date",
        },
        {
          label: intl.formatMessage({defaultMessage: "Ansættelsesdato"}),
          name: "employmentDate",
          required: false,
          type: "date",
        },
        {
          label: intl.formatMessage({
            defaultMessage: "Altid synlig på kalender",
          }),
          name: "alwaysVisible",
          required: true,
          type: "boolean",
        },
        {
          label: intl.formatMessage({defaultMessage: "Spørg om rådighed"}),
          name: "askAboutAvailability",
          required: true,
          type: "boolean",
        },
        {
          label: intl.formatMessage({
            defaultMessage: "Vis aftensmadsbestillingspåmindelse",
          }),
          name: "showDinnerBookingPopup",
          required: true,
          type: "boolean",
        },
        {
          label: intl.formatMessage({
            defaultMessage: "Vis frokostbestillingspåmindelse",
          }),
          name: "showLunchBookingPopup",
          required: true,
          type: "boolean",
        },
        {
          label: intl.formatMessage({
            defaultMessage: "Kan se madbestillingslisten",
          }),
          name: "canReadFoodBookings",
          required: true,
          type: "boolean",
        },
        {
          label: intl.formatMessage({defaultMessage: "Kræv GPS"}),
          name: "requireGps",
          required: true,
          type: "boolean",
        },
        {
          label: intl.formatMessage({
            defaultMessage: "Vis notepopup på opgaver",
          }),
          name: "showNotePopupOnTask",
          required: true,
          type: "boolean",
        },
        {
          label: intl.formatMessage({
            defaultMessage: "Modtager notifikation ved start af tidsregistrering",
          }),
          name: "receiveTimerStartNotifications",
          required: true,
          type: "boolean",
        },
        {
          label: intl.formatMessage({defaultMessage: "Kan afgodkende opgaver"}),
          name: "canUnapproveTasks",
          required: true,
          type: "boolean",
        },
        {
          label: intl.formatMessage({defaultMessage: "Databehandler"}),
          name: "owner",
          required: true,
          type: "boolean",
        },
        {
          label: intl.formatMessage({defaultMessage: "Løngruppe id"}),
          name: "remunerationGroup",
          references: "remunerationGroupIdentifier",
          required: true,
          type: "string",
        },
        {
          label: intl.formatMessage({
            defaultMessage: "Gennemsnitlig omkostning pr. time",
          }),
          name: "averageHourlyCost",
          required: false,
          type: "decimal",
        },
        {
          choices: [
            "Løsarbejder",
            "Mekaniker",
            "Projektleder",
            "Maskinfører",
            "Senior Maskinfører",
            "Administrator",
            "Info, ikke angivet",
            "Info, pauseskærm",
            "Info, fuldskærmskort",
            "Info, fuldskærmskalender",
            "Info, fuldskærmskalender, side 1",
            "Info, fuldskærmskalender, side 2",
            "Info, fuldskærmsordrekalender",
            "Info, fuldskærmsordrekalender (minimal)",
            "Info, fuldskærmsfraværskalender",
          ],
          label: intl.formatMessage({defaultMessage: "Rolle"}),
          name: "role",
          required: true,
          type: "choices",
        },
      ],
      label: intl.formatMessage({defaultMessage: "Medarbejdere"}),
      name: "user",
    },
    {
      columns: [
        {
          label: intl.formatMessage({defaultMessage: "Nummer"}),
          name: "identifier",
          required: true,
          type: "string",
        },
        {
          label: intl.formatMessage({defaultMessage: "Navn"}),
          name: "name",
          required: true,
          type: "string",
        },
        {
          label: intl.formatMessage({defaultMessage: "Vis i lister"}),
          name: "internalTaskPrimary",
          required: true,
          type: "boolean",
        },
        {
          label: intl.formatMessage({defaultMessage: "Farve"}),
          name: "color",
          required: false,
          type: "color",
        },
        {
          label: intl.formatMessage({
            defaultMessage: "Kræv at notefeltet er udfyldt",
          }),
          name: "requireNotesFromMachineOperator",
          required: true,
          type: "boolean",
        },
        {
          label: intl.formatMessage({
            defaultMessage: "Kræver maskine",
          }),
          name: "requireMachineUse",
          required: true,
          type: "boolean",
        },
        {
          label: intl.formatMessage({
            defaultMessage: "Tillad kun én maskine",
          }),
          name: "allowMaxOneMachine",
          required: true,
          type: "boolean",
        },
        {
          label: intl.formatMessage({
            defaultMessage: "Tillader ikke maskiner",
          }),
          name: "disallowMachineUse",
          required: true,
          type: "boolean",
        },
        {
          label: intl.formatMessage({
            defaultMessage: "Kræv foto",
          }),
          name: "requirePhotoOnTaskCompletion",
          required: true,
          type: "boolean",
        },
        {
          label: intl.formatMessage({defaultMessage: "Kræv PDF/foto"}),
          name: "requireAttachment",
          required: true,
          type: "boolean",
        },
        {
          label: intl.formatMessage({
            defaultMessage: "Anvendes kun til ekstra-tidsknapper",
          }),
          name: "onlyForExtraTimers",
          required: true,
          type: "boolean",
        },
      ],
      label: intl.formatMessage({defaultMessage: "Arbejdsområder, interne"}),
      name: "workTypeInternal",
    },
    {
      availableColumns: (config, columns) =>
        config.economicSync
          ? columns.map((column) => {
              if (column.name === "price") {
                return {...column, required: true};
              } else if (column.name === "productGroupIdentifier") {
                return {...column, required: true, type: "integer"};
              } else {
                return column;
              }
            })
          : columns,
      columns: [
        {
          label: intl.formatMessage({defaultMessage: "Varenummer"}),
          name: "catalogNumber",
          required: true,
          type: "string",
        },
        {
          label: intl.formatMessage({defaultMessage: "Navn"}),
          name: "name",
          required: true,
          type: "string",
        },
        {
          label: intl.formatMessage({defaultMessage: "Pris"}),
          name: "price",
          required: false,
          type: "decimal",
        },
        {
          label: intl.formatMessage({defaultMessage: "Enhed"}),
          name: "relatedUnitName",
          references: "unitName",
          required: false,
          type: "string",
        },
        {
          label: intl.formatMessage({defaultMessage: "Varegruppe, nummer"}),
          name: "productGroupIdentifier",
          required: false,
          type: "string",
        },
      ],
      label: intl.formatMessage({defaultMessage: "Varer"}),
      name: "product",
    },
    {
      columns: [
        {
          label: intl.formatMessage({defaultMessage: "Id"}),
          name: "locationId",
          required: false,
          type: "string",
        },
        {
          label: intl.formatMessage({defaultMessage: "Stedtype id"}),
          name: "locationTypeIdentifier",
          references: "locationTypeIdentifier",
          required: true,
          type: "string",
        },
        {
          label: intl.formatMessage({defaultMessage: "Kunde nummer"}),
          name: "customerNumber",
          references: "customerNumber",
          required: true,
          type: "string",
        },
        {
          label: intl.formatMessage({defaultMessage: "Navn"}),
          name: "locationName",
          required: false,
          type: "string",
        },
        {
          label: intl.formatMessage({defaultMessage: "Adresse"}),
          name: "locationAddress",
          required: false,
          type: "string",
        },
        {
          label: intl.formatMessage({defaultMessage: "Postnummer"}),
          name: "locationPostalCode",
          required: false,
          type: "string",
        },
        {
          label: intl.formatMessage({defaultMessage: "By"}),
          name: "locationCity",
          required: false,
          type: "string",
        },
        {
          label: intl.formatMessage({defaultMessage: "GPS breddegrad"}),
          name: "locationLatitude",
          required: false,
          type: "decimal",
        },
        {
          label: intl.formatMessage({defaultMessage: "GPS længdegrad"}),
          name: "locationLongitude",
          required: false,
          type: "decimal",
        },
        {
          label: intl.formatMessage({defaultMessage: "Kontaktperson navn"}),
          name: "locationContactName",
          required: false,
          type: "string",
        },
        {
          label: intl.formatMessage({defaultMessage: "Kontaktperson telefon"}),
          name: "locationContactPhone",
          required: false,
          type: "string",
        },
        {
          label: intl.formatMessage({defaultMessage: "Kontaktperson mobil"}),
          name: "locationContactCellphone",
          required: false,
          type: "string",
        },
        {
          label: intl.formatMessage({defaultMessage: "Kontaktperson email"}),
          name: "locationContactEmail",
          required: false,
          type: "email",
        },
        {
          choices: [
            "Kan bruges som både arbejdssted og logsted",
            "Kan kun bruges som logsted",
            "Kan kun bruges som arbejdssted",
          ],
          label: intl.formatMessage({
            defaultMessage: "Anvendelse",
          }),
          name: "usage",
          required: true,
          type: "choices",
        },
      ],
      label: intl.formatMessage({defaultMessage: "Steder"}),
      name: "location",
    },
    {
      availableColumns: (config, columns) =>
        config.economicSync && config.economicEnableWorkTypeFromProductGroupImport
          ? columns.map((column) => {
              if (column.name === "salesAccount") {
                return {...column, required: true};
              } else if (column.name === "identifier") {
                return {...column, type: "integer"};
              } else {
                return column;
              }
            })
          : columns.filter((column) => column.name !== "salesAccount"),
      columns: [
        {
          label: intl.formatMessage({defaultMessage: "Nummer"}),
          name: "identifier",
          required: true,
          type: "string",
        },
        {
          label: intl.formatMessage({defaultMessage: "Navn"}),
          name: "name",
          required: true,
          type: "string",
        },
        {
          label: intl.formatMessage({defaultMessage: "Farve"}),
          name: "color",
          required: false,
          type: "color",
        },
        {
          label: intl.formatMessage({
            defaultMessage: "Multiplikator, bugseringsomkostning (effektiv)",
          }),
          name: "towingCostMultiplier",
          required: false,
          type: "decimal",
        },
        {
          label: intl.formatMessage({
            defaultMessage:
              "Kræv at mindst én valgfri prislinje er udfyldt med en værdi større end 0",
          }),
          name: "requireAtLeastOneOptionalPriceItemUseGreaterThanZero",
          required: true,
          type: "boolean",
        },
        {
          label: intl.formatMessage({
            defaultMessage:
              "Kræv at der er tilføjet mindst én vare på opgaven og at denne er udfyldt med en værdi større end 0",
          }),
          name: "requireAtLeastOneProductUseGreaterThanZero",
          required: true,
          type: "boolean",
        },
        {
          label: intl.formatMessage({
            defaultMessage:
              "Hvis der er varer på opgaven, så skal mindst én af disse være udfyldt med større end 0",
          }),
          name: "noProductUsesOrAtLeastOneProductUseGreaterThanZero",
          required: true,
          type: "boolean",
        },
        {
          label: intl.formatMessage({
            defaultMessage: "Kræv at notefeltet er udfyldt ifm. udførelse af opgaver",
          }),
          name: "requireNotesFromMachineOperator",
          required: true,
          type: "boolean",
        },
        {
          label: intl.formatMessage({
            defaultMessage: "Kræver maskine",
          }),
          name: "requireMachineUse",
          required: true,
          type: "boolean",
        },
        {
          label: intl.formatMessage({
            defaultMessage: "Tillad kun én maskine",
          }),
          name: "allowMaxOneMachine",
          required: true,
          type: "boolean",
        },
        {
          label: intl.formatMessage({
            defaultMessage: "Tillader ikke maskiner",
          }),
          name: "disallowMachineUse",
          required: true,
          type: "boolean",
        },
        {
          label: intl.formatMessage({
            defaultMessage: "Kræv foto",
          }),
          name: "requirePhotoOnTaskCompletion",
          required: true,
          type: "boolean",
        },
        {
          label: intl.formatMessage({defaultMessage: "Kræv PDF/foto"}),
          name: "requireAttachment",
          required: true,
          type: "boolean",
        },
        {
          label: intl.formatMessage({defaultMessage: "Kræv arbejdssted"}),
          name: "requireWorkplace",
          required: true,
          type: "boolean",
        },
        {
          label: intl.formatMessage({
            defaultMessage: "Anvendes kun til ekstra-tidsknapper",
          }),
          name: "onlyForExtraTimers",
          required: true,
          type: "boolean",
        },
        {
          label: intl.formatMessage({defaultMessage: "Kræv mark"}),
          name: "requireField",
          required: true,
          type: "boolean",
        },
        {
          label: intl.formatMessage({defaultMessage: "Kræv effektiv tid"}),
          name: "requireEffectiveTime",
          required: true,
          type: "boolean",
        },
        {
          label: intl.formatMessage({defaultMessage: "Konto"}),
          name: "salesAccount",
          required: false,
          type: "integer",
        },
      ],
      label: intl.formatMessage({defaultMessage: "Abejdsområde, eksterne"}),
      name: "workTypeExternal",
    },
  ] as const satisfies readonly CsvImportSpecification[];
  return _.sortBy(specifications, "label");
}

export function getAvailableColumns(
  config: Config,
  specification: CsvImportSpecification,
): readonly CsvImportColumnSpecification[] {
  if (specification.availableColumns) {
    return specification.availableColumns(config, specification.columns);
  } else {
    return specification.columns;
  }
}
