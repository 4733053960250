import {SettingID} from "@co-common-libs/config";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {
  actions,
  getCurrentUserURL,
  getCustomerSettings,
  getSettingsEntryLookupByIdentifier,
} from "@co-frontend-libs/redux";
import {DialogContent, Grid} from "@material-ui/core";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import React, {useCallback, useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuid} from "uuid";
import {ColumnSelectionDialog} from "../column-selection-dialog";
import {ColumnListEdit} from "./column-list-edit";
import {DeviceType} from "./types";

interface DeviceListColumnsDialogProps<ColumnChoice extends string> {
  columnChoices: readonly ColumnChoice[];
  onClose: () => void;
  open: boolean;
  settingID: SettingID;
  title: JSX.Element | string;
}

export function DeviceListColumnsDialog<ColumnChoice extends string>(
  props: DeviceListColumnsDialogProps<ColumnChoice>,
): JSX.Element {
  const {columnChoices, onClose, open, settingID} = props;
  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const customerSettings = useSelector(getCustomerSettings);
  const settingsEntry = settingsEntryLookupByIdentifier(settingID);
  const currentUserURL = useSelector(getCurrentUserURL);
  const dispatch = useDispatch();

  type CurrentSettingValueType = {
    readonly [D in DeviceType]: readonly ColumnChoice[];
  };

  type WritableSettingValueType = {
    [D in DeviceType]: ColumnChoice[];
  };

  const [data, setData] = useState<CurrentSettingValueType>(
    customerSettings[settingID] as CurrentSettingValueType,
  );
  useEffect(() => {
    setData(customerSettings[settingID] as CurrentSettingValueType);
  }, [customerSettings, settingID]);

  const handleSave = useCallback(() => {
    if (settingsEntry) {
      dispatch(
        actions.update(settingsEntry.url, [
          {member: "changedBy", value: currentUserURL},
          {member: "data", value: data},
        ]),
      );
    } else {
      const id = uuid();
      const url = instanceURL("settingEntry", id);
      dispatch(
        actions.create({
          changedBy: currentUserURL,
          data,
          key: settingID,
          url,
        }),
      );
    }
    onClose();
  }, [settingsEntry, onClose, dispatch, currentUserURL, data, settingID]);

  const handleChange = useCallback(
    (reorderedIdentifiers: readonly ColumnChoice[], deviceType: DeviceType): void => {
      const newData = _.cloneDeep(data) as WritableSettingValueType;
      newData[deviceType] = reorderedIdentifiers.slice();
      setData(newData);
    },
    [data],
  );

  const [columnSelectionDialogOpenWithData, setColumnSelectionDialogOpenWithData] = useState<{
    deviceType: DeviceType;
    selected: readonly ColumnChoice[];
  } | null>(null);

  const handleColumnListEditButtonClick = useCallback(
    (deviceType: DeviceType) => {
      const selected = data[deviceType];
      setColumnSelectionDialogOpenWithData({
        deviceType,
        selected,
      });
    },
    [data],
  );

  const handleColumnSelectionDialogClose = useCallback(() => {
    setColumnSelectionDialogOpenWithData(null);
  }, []);

  const handleColumnSelectionDialogSave = useCallback(
    (selected: readonly ColumnChoice[]) => {
      if (columnSelectionDialogOpenWithData) {
        const newData = _.cloneDeep(data) as WritableSettingValueType;
        const {deviceType} = columnSelectionDialogOpenWithData;
        newData[deviceType] = selected.slice();
        setData(newData);
        handleColumnSelectionDialogClose();
      }
    },
    [columnSelectionDialogOpenWithData, data, handleColumnSelectionDialogClose],
  );

  const deviceTypes: DeviceType[] = ["mobile", "tablet", "desktop"];

  return (
    <>
      <ResponsiveDialog
        fullscreen
        okLabel={<FormattedMessage defaultMessage="Gem" id="setting-dialog.label.save" />}
        open={open && !columnSelectionDialogOpenWithData}
        title={props.title}
        onCancel={onClose}
        onOk={handleSave}
      >
        <DialogContent style={{padding: 32}}>
          <Grid container spacing={8}>
            <Grid item xs={6}>
              {deviceTypes.map((deviceType) => (
                <div key={`${deviceType}`}>
                  <FormattedMessage
                    defaultMessage="{deviceType, select,
                        mobile {Mobil}
                        tablet {Tablet}
                        desktop {Pc}
                        other {Ukendt}
                    }"
                    id="system-setup.header.list-identifier-manager-device-type-columns"
                    tagName="b"
                    values={{deviceType}}
                  />
                  <ColumnListEdit<ColumnChoice>
                    deviceType={deviceType}
                    identifiers={data[deviceType]}
                    onChange={handleChange}
                    onEditClick={handleColumnListEditButtonClick}
                  />
                </div>
              ))}
            </Grid>
          </Grid>
        </DialogContent>
      </ResponsiveDialog>
      <ColumnSelectionDialog<ColumnChoice>
        columns={columnChoices}
        open={!!columnSelectionDialogOpenWithData}
        selected={columnSelectionDialogOpenWithData?.selected}
        onClose={handleColumnSelectionDialogClose}
        onSave={handleColumnSelectionDialogSave}
      />
    </>
  );
}
