import {SECOND_MILLISECONDS} from "@co-common-libs/utils";

const MAXIMUM_AGE_SECONDS = 10;
const TIMEOUT_SECONDS = 5;
const TIMEOUT_TOLERANCE_SECONDS = 1;

let existingUnsettledPromise: Promise<GeolocationPosition> | null = null;

export function getCurrentPosition(): Promise<GeolocationPosition> {
  if (typeof navigator.geolocation === "undefined") {
    return Promise.reject(new Error("navigator.geolocation not available"));
  }
  if (existingUnsettledPromise) {
    return existingUnsettledPromise;
  }
  const positionPromise = new Promise<GeolocationPosition>((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, {
      enableHighAccuracy: true,
      maximumAge: MAXIMUM_AGE_SECONDS * SECOND_MILLISECONDS,
      timeout: TIMEOUT_SECONDS * SECOND_MILLISECONDS,
    });
  });
  // eslint-disable-next-line promise/param-names
  const timeoutPromise = new Promise<GeolocationPosition>((_resolve, reject) => {
    window.setTimeout(
      reject,
      (TIMEOUT_SECONDS + TIMEOUT_TOLERANCE_SECONDS) * SECOND_MILLISECONDS,
      new Error("Timeout"),
    );
  });
  existingUnsettledPromise = new Promise((resolve, reject) => {
    Promise.race([positionPromise, timeoutPromise])
      .then((position) => {
        existingUnsettledPromise = null;
        resolve(position);
        return;
      })
      .catch((error) => {
        existingUnsettledPromise = null;
        reject(error);
      });
  });
  return existingUnsettledPromise;
}
