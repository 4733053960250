import {Config} from "@co-common-libs/config";
import {
  RemunerationReport,
  RemunerationReportUrl,
  ResourceTypeUnion,
  User,
  UserProfile,
  UserUrl,
  emptyRemunerationReport,
  urlToId,
} from "@co-common-libs/resources";
import {getNormalisedDeviceTimestamp} from "@co-common-libs/resources-utils";
import {VerticalStackingFloatingActionButton} from "@co-frontend-libs/components";
import {Query, makeQuery} from "@co-frontend-libs/db-resources";
import {
  AppState,
  PathTemplate,
  actions,
  getCurrentUserURL,
  getCustomerSettings,
  getPathName,
  getRemunerationReportArray,
  getRemunerationReportLookup,
  getUserArray,
  getUserProfileArray,
} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {jsonFetch} from "@co-frontend-libs/utils";
import {MenuToolbar, PageLayout} from "app-components";
import {bind} from "bind-decorator";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import PlusIcon from "mdi-react/PlusIcon";
import React from "react";
import {IntlContext, defineMessages} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {v4 as uuid} from "uuid";
import {REMUNERATION_REPORT_READY_CHECK_TIMEOUT} from "../remuneration-report";
import {NewDialog} from "./new-dialog";
import {RemunerationReportTable} from "./remuneration-report-table";

const messages = defineMessages({
  cancel: {
    defaultMessage: "Fortryd",
    id: "dialog.label.cancel",
  },
  generatingReport: {
    defaultMessage: "Genererer lønrapport",
    id: "remuneration-report-list.label.generating-report",
  },
  ok: {
    defaultMessage: "OK",
    id: "dialog.label.ok",
  },
  onlyValidated: {
    defaultMessage: "Kun godkendte opgaver",
    id: "remuneration-report-list.label.only-validated",
  },
  remunerationReports: {
    defaultMessage: "Lønrapporter",
    id: "remuneration-report-list.title.remuneration-reports",
  },
});

interface RemunerationReportListStateProps {
  currentUserURL: string | null;
  customerSettings: Config;
  pathName: string;
  remunerationReportArray: readonly RemunerationReport[];
  remunerationReportLookup: (url: RemunerationReportUrl) => RemunerationReport | undefined;
  userArray: readonly User[];
  userProfileArray: readonly UserProfile[];
}

interface RemunerationReportListDispatchProps {
  addToOffline: (instance: ResourceTypeUnion) => void;
  create: (instance: ResourceTypeUnion) => void;
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  putQueryKey: (key: string, value: string, navigationKind?: PartialNavigationKind) => void;
  putTableSortingState: (
    identifier: string,
    sortKey: string,
    sortDirection: "ASC" | "DESC",
  ) => void;
  temporaryQueriesRequestedForPath: (
    queries: readonly Query[],
    pathName: string,
    key: string,
  ) => void;
}

interface RemunerationReportListOwnProps {
  onMenuButton: (event: React.MouseEvent) => void;
}

type RemunerationReportListProps = RemunerationReportListDispatchProps &
  RemunerationReportListOwnProps &
  RemunerationReportListStateProps;

interface RemunerationReportListState {
  dialogOpen: boolean;
}

const TEMPORARY_QUERIES_KEY = "RemunerationReportList";

class RemunerationReportList extends React.Component<
  RemunerationReportListProps,
  RemunerationReportListState
> {
  state: RemunerationReportListState = {
    dialogOpen: false,
  };
  componentDidMount(): void {
    const {pathName, temporaryQueriesRequestedForPath} = this.props;
    const remunerationReportQuery = makeQuery({
      check: {type: "alwaysOk"},
      independentFetch: true,
      resourceName: "remunerationReport",
    });
    temporaryQueriesRequestedForPath([remunerationReportQuery], pathName, TEMPORARY_QUERIES_KEY);
  }
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  extraSync(id: string): void {
    const url = `/api/remunerationreports/${id}/`;
    setTimeout(() => {
      jsonFetch(url)
        .then((response) => {
          const idURL = instanceURL("remunerationReport", id);
          const existingInstance = this.props.remunerationReportLookup(idURL);
          if (existingInstance) {
            if (existingInstance.dataUrl || existingInstance.error) {
              return;
            }
          }
          const {data} = response;
          if (data) {
            if (data.dataUrl || data.error) {
              this.props.addToOffline(response.data);
            } else if (!data.dataUrl && !data.error) {
              // not ready, check again in a bit...
              this.extraSync(id);
            }
          }
          return;
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        });
    }, REMUNERATION_REPORT_READY_CHECK_TIMEOUT);
  }
  @bind
  handleFabButton(): void {
    this.setState({
      dialogOpen: true,
    });
  }
  @bind
  handleDialogCancel(): void {
    this.setState({
      dialogOpen: false,
    });
  }
  @bind
  handleDialogOk(
    fromDate: string,
    toDate: string,
    onlyValidated: boolean,
    title: string,
    remunerationGroup: string,
  ): void {
    if (!this.state.dialogOpen) {
      return;
    }

    this.setState({
      dialogOpen: false,
    });
    const {currentUserURL} = this.props;
    const id = uuid();
    const url = instanceURL("remunerationReport", id);
    const deviceTimestamp = new Date().toISOString();
    const newReport: RemunerationReport = {
      ...emptyRemunerationReport,
      createdBy: currentUserURL as UserUrl,
      customerSettings: this.props.customerSettings,
      deviceTimestamp,
      fromDate,
      id,
      onlyValidated,
      remunerationGroup,
      title,
      toDate,
      url,
    };
    this.props.create(newReport);
    this.extraSync(id);

    this.props.putQueryKey("sortDirection", "DESC");
    this.props.putQueryKey("sortKey", "createdTimestamp");

    this.props.putTableSortingState("RemunerationTable", "createdTimestamp", "DESC");
  }
  @bind
  handleTableClick(remunerationReportURL: RemunerationReportUrl): void {
    const report = this.props.remunerationReportLookup(remunerationReportURL);
    if (report) {
      this.props.go("/remunerationReport/:id", {id: urlToId(report.url)});
    }
  }
  getSalaryReports(): RemunerationReport[] {
    return _.sortBy(this.props.remunerationReportArray, (report) => {
      return `${report.fromDate}:${report.toDate}${getNormalisedDeviceTimestamp(report)}`;
    }).reverse();
  }
  render(): JSX.Element {
    const {formatMessage} = this.context;
    return (
      <PageLayout
        withBottomScrollPadding
        dialogs={
          <NewDialog
            customerSettings={this.props.customerSettings}
            open={this.state.dialogOpen}
            userArray={this.props.userArray}
            userProfileArray={this.props.userProfileArray}
            onCancel={this.handleDialogCancel}
            onOk={this.handleDialogOk}
          />
        }
        floatingActionButton={
          <VerticalStackingFloatingActionButton stackIndex={0} onClick={this.handleFabButton}>
            <PlusIcon />
          </VerticalStackingFloatingActionButton>
        }
        toolbar={
          <MenuToolbar
            title={formatMessage(messages.remunerationReports)}
            onMenuButton={this.props.onMenuButton}
          />
        }
      >
        <RemunerationReportTable onClick={this.handleTableClick} />
      </PageLayout>
    );
  }
}

const ConnectedRemunerationReportList: React.ComponentType<RemunerationReportListOwnProps> =
  connect<
    RemunerationReportListStateProps,
    RemunerationReportListDispatchProps,
    RemunerationReportListOwnProps,
    AppState
  >(
    createStructuredSelector<AppState, RemunerationReportListStateProps>({
      currentUserURL: getCurrentUserURL,
      customerSettings: getCustomerSettings,
      pathName: getPathName,
      remunerationReportArray: getRemunerationReportArray,
      remunerationReportLookup: getRemunerationReportLookup,
      userArray: getUserArray,
      userProfileArray: getUserProfileArray,
    }),
    {
      addToOffline: actions.addToOffline,
      create: actions.create,
      go: actions.go,
      putQueryKey: actions.putQueryKey,
      putTableSortingState: actions.putTableSortingState,
      temporaryQueriesRequestedForPath: actions.temporaryQueriesRequestedForPath,
    },
  )(RemunerationReportList);

export default ConnectedRemunerationReportList;
