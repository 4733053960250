import {
  CsvImportColumnSpecificationValidatePart,
  CsvImportColumnSpecificationValidateTypePart,
} from "@co-common-libs/csv-import-specifications";
import {IntlShape} from "react-intl";
import {ValidationData, getParseValidateFunction} from "../parse-validate";

function reportRequiredMissing(intl: IntlShape): string[] {
  return [
    intl.formatMessage({
      defaultMessage: "Denne kolonne kræver en værdi.",
    }),
  ];
}

// TypeScript validates that this always returns string arrays
// eslint-disable-next-line consistent-return
function reportWrongFormat(
  intl: IntlShape,
  column: CsvImportColumnSpecificationValidateTypePart,
): string[] {
  switch (column.type) {
    case "integer":
      return [
        intl.formatMessage({
          defaultMessage: "Denne kolonne skal have en heltalsværdi.",
        }),
      ];
    case "decimal":
      return [
        intl.formatMessage({
          defaultMessage: "Denne kolonne skal have en talværdi.",
        }),
      ];
    case "boolean":
      return [
        intl.formatMessage({
          defaultMessage: "Denne kolonne skal have en Sandt/Falsk værdi.",
        }),
      ];
    case "date":
      return [
        intl.formatMessage({
          defaultMessage: "Denne kolonne skal have en dato.",
        }),
      ];
    case "choices":
      return [
        intl.formatMessage(
          {
            defaultMessage: "Tilladte værdier er kun: {choices}.",
          },
          {
            choices: column.choices.join(", "),
          },
        ),
      ];
    case "color":
      return [
        intl.formatMessage({
          defaultMessage: "Denne kolonne skal have en hex-farve.",
        }),
      ];
    case "cvr":
      return [
        intl.formatMessage({
          defaultMessage: "Denne kolonne skal have et CVR-nummer (8 cifre).",
        }),
      ];
    case "email":
      return [
        intl.formatMessage({
          defaultMessage: "Denne kolonne skal have en e-mail-adresse.",
        }),
      ];
    case "string":
      throw new Error("WRONG_FORMAT should never occur for strings");
  }
}

function reportUnknownReference(intl: IntlShape): string[] {
  return [
    intl.formatMessage({
      defaultMessage: "Værdien er ikke blandt de mulige/tilladte.",
    }),
  ];
}

// Okay because typescript accepts
// eslint-disable-next-line consistent-return
export function validateStaticValue(
  column: CsvImportColumnSpecificationValidatePart,
  value: string,
  intl: IntlShape,
  data: ValidationData,
): string[] {
  const validationFn = getParseValidateFunction(column, data);

  const result = validationFn(value, column.required);
  if (result.success) {
    return [];
  } else {
    switch (result.error) {
      case "REQIRED_MISSING":
        return reportRequiredMissing(intl);
      case "WRONG_FORMAT":
        return reportWrongFormat(intl, column);
      case "UNKNOWN_REFERENCE":
        return reportUnknownReference(intl);
    }
  }
}
