import {GenericTable, GenericTableProps, RowData} from "@co-frontend-libs/components";
import {Paper, TablePagination, Theme, makeStyles} from "@material-ui/core";
import {TablePaginationProps} from "@material-ui/core/TablePagination/TablePagination";
import bowser from "bowser";
import React, {useCallback, useMemo} from "react";
import {useIntl} from "react-intl";
import type {MarkRequired} from "ts-essentials";
import {PaginationPageSize} from "./types";

const useStyles = makeStyles((_theme: Theme) => ({
  pagination: {
    "& .MuiTablePagination-spacer": {
      display: "none",
    },
  },
}));

type SelectedTableProperties =
  | "columns"
  | "entries"
  | "groupBy"
  | "onHeaderClick"
  | "overrideSortBy"
  | "rowStyle"
  | "sortBy"
  | "sortDirection"
  | "visibleColumns";

type SelectedTablePaginationProperties =
  | "count"
  | "onPageChange"
  | "onRowsPerPageChange"
  | "page"
  | "rowsPerPage";

export interface TableWithPaginationProps<
  FieldID extends string,
  ColumnID extends string,
  KeyType extends string = string,
  DataType extends RowData<FieldID, KeyType> = RowData<FieldID, KeyType>,
> extends MarkRequired<
      Pick<GenericTableProps<FieldID, ColumnID, KeyType, DataType>, SelectedTableProperties>,
      "onHeaderClick" | "sortBy"
    >,
    MarkRequired<
      Pick<TablePaginationProps, SelectedTablePaginationProperties>,
      "onPageChange" | "onRowsPerPageChange"
    > {}

export const TableWithPagination = <
  FieldID extends string,
  ColumnID extends string,
  KeyType extends string = string,
  DataType extends RowData<FieldID, KeyType> = RowData<FieldID, KeyType>,
>(
  props: TableWithPaginationProps<FieldID, ColumnID, KeyType, DataType>,
): JSX.Element | null => {
  const {
    columns,
    count: totalCount,
    entries,
    groupBy,
    onHeaderClick,
    onPageChange,
    onRowsPerPageChange,
    overrideSortBy,
    page,
    rowsPerPage,
    rowStyle,
    sortBy,
    sortDirection,
    visibleColumns,
  } = props;

  const intl = useIntl();
  const classes = useStyles();

  const rowsPerPageOptions = useMemo(
    () => [
      PaginationPageSize.SMALL,
      PaginationPageSize.MEDIUM,
      PaginationPageSize.LARGE,
      PaginationPageSize.XLARGE,
    ],
    [],
  );

  const labelDisplayedRows = useCallback(
    (values: {count: number; from: number; to: number}): string => {
      const {count} = values;
      if (bowser.mobile) {
        return intl.formatMessage(
          {
            defaultMessage: "{from, number}–{to, number}",
          },
          values,
        );
      } else if (count !== -1) {
        return intl.formatMessage(
          {
            defaultMessage: "{from, number}–{to, number} af {count, number}",
          },
          values,
        );
      } else {
        return intl.formatMessage(
          {
            defaultMessage: "{from, number}–{to, number} af mere end {to, number}",
          },
          values,
        );
      }
    },
    [intl],
  );

  const labelRowsPerPage = useMemo((): string => {
    if (bowser.mobile) {
      return intl.formatMessage({defaultMessage: "Rækker:"});
    } else {
      return intl.formatMessage({defaultMessage: "Rækker per side:"});
    }
  }, [intl]);

  const nextIconButtonText = useMemo(
    (): string => intl.formatMessage({defaultMessage: "Næste side"}),
    [intl],
  );
  const backIconButtonText = useMemo(
    (): string => intl.formatMessage({defaultMessage: "Forrige side"}),
    [intl],
  );

  return (
    <Paper>
      <GenericTable
        entriesAlreadySortedAndFiltered
        columns={columns}
        entries={entries}
        groupBy={groupBy}
        overrideSortBy={overrideSortBy}
        rowStyle={rowStyle}
        sortBy={sortBy}
        sortDirection={sortDirection}
        visibleColumns={visibleColumns}
        onHeaderClick={onHeaderClick}
      />
      <TablePagination
        backIconButtonText={backIconButtonText}
        className={classes.pagination}
        component="div"
        count={totalCount}
        labelDisplayedRows={labelDisplayedRows}
        labelRowsPerPage={labelRowsPerPage}
        nextIconButtonText={nextIconButtonText}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange || undefined}
      />
    </Paper>
  );
};
