import {Fab, FabProps, PropTypes, useTheme} from "@material-ui/core";
import {SPACING} from "frontend-global-config";
import React, {useMemo} from "react";
import {FloatingActionButtonData, isNormalFloatingActionButtonData} from "./types";

interface SingleFloatingActionButtonProps {
  button: FloatingActionButtonData;
  color?: PropTypes.Color | undefined;
  disabled?: boolean | undefined;
  variant: "component" | "page";
}

export const SingleFloatingActionButton = React.memo(function SingleSingleFloatingActionButton(
  props: SingleFloatingActionButtonProps,
): JSX.Element {
  const theme = useTheme();

  const {button, color, disabled, variant} = props;

  const NORMAL_SPACING = 3;

  const baseStyling = useMemo(
    (): React.CSSProperties =>
      variant === "component"
        ? {
            position: "absolute",
            right: theme.spacing(SPACING.SMALL),
            top: theme.spacing(SPACING.SMALL),
            zIndex: 10,
          }
        : {
            bottom: theme.spacing(NORMAL_SPACING),
            position: "fixed",
            right: theme.spacing(NORMAL_SPACING),
            zIndex: 10,
          },
    [theme, variant],
  );

  const baseFabProps: Partial<FabProps> = {
    disabled: disabled === true,
    size: variant === "component" ? "small" : "large",
    style: baseStyling,
  };

  if (color) {
    baseFabProps.color = color;
  }

  if (isNormalFloatingActionButtonData(button)) {
    const {onClick} = button;

    const normalFab = (
      <Fab {...baseFabProps} onClick={onClick}>
        {button.buttonIcon}
      </Fab>
    );

    return variant === "component" ? (
      <div style={{position: "relative"}}>{normalFab}</div>
    ) : (
      normalFab
    );
  } else {
    const {accept, id, onChange} = button;

    const inputFab = (
      <>
        <input
          multiple
          accept={accept}
          id={id}
          style={{display: "none"}}
          type="file"
          onChange={onChange}
        />
        <label htmlFor={id}>
          <Fab {...baseFabProps} component="span">
            {button.buttonIcon}
          </Fab>
        </label>
      </>
    );

    return variant === "component" ? (
      <div style={{position: "relative"}}>{inputFab}</div>
    ) : (
      inputFab
    );
  }
});
