import {CustomerUrl, PriceGroupUrl, Task, WorkTypeUrl} from "@co-common-libs/resources";
import {useMachine} from "@xstate/react/lib/fsm";
import React, {forwardRef, useImperativeHandle} from "react";
import {WorkTypeSelectionDisplay} from "./work-type-selection-display";
import {workTypeSelectionStateMachine} from "./work-type-selection-state-machine";

interface WorkTypeSelectionWrapperProps {
  customerURL: CustomerUrl | null;
  onWorkTypeSelection: (workType: WorkTypeUrl, priceGroup: PriceGroupUrl | null) => void;
  onWorkTypeSelectionCancelled?: () => void;
  task?: Task;
}

/**
 * Helper to use state machine and related UI from class-based components
 * in a somewhat hacky way via refs, without having to manage state machine
 * lifecycle in from the component lifecycle callbacks.
 *
 * To use from function components, do what this component does with
 * `useMachine()` and the related "display" component.
 */
export const WorkTypeSelectionWrapper = forwardRef(function WorkTypeSelectionWrapper(
  props: WorkTypeSelectionWrapperProps,
  ref: React.Ref<{start: () => void}>,
): JSX.Element {
  const {customerURL, onWorkTypeSelection, onWorkTypeSelectionCancelled, task} = props;
  const [state, send] = useMachine(workTypeSelectionStateMachine, {
    actions: {
      signalCancelled: (_context, _event) => {
        if (onWorkTypeSelectionCancelled) {
          onWorkTypeSelectionCancelled();
        }
      },
      signalDone: (context, _event) => {
        const {priceGroup, workType} = context;
        onWorkTypeSelection(workType as WorkTypeUrl, priceGroup);
      },
    },
  });

  useImperativeHandle(
    ref,
    () => ({
      start: () => {
        send({
          customer: customerURL,
          type: "START",
        });
      },
    }),
    [customerURL, send],
  );

  return <WorkTypeSelectionDisplay send={send} state={state} task={task} />;
});
