import {ImportPreviewMachine} from "@co-common-libs/resources-utils";
import {memoizeForceReuse} from "@co-frontend-libs/utils";
import React, {useMemo} from "react";
import {IntlShape, useIntl} from "react-intl";
import {EntryData, GenericMultiSelectionSearchDialog} from "../../search-dialog";

function computeBaseChoices(
  previewMachines: readonly ImportPreviewMachine[],
  intl: IntlShape,
): readonly EntryData<string>[] {
  return previewMachines.map((instance) => {
    const {name, nameConflict, number} = instance;
    const identifier = number.toString();
    return {
      category: "standard",
      conflict: !!nameConflict,
      exactMatchValue: identifier,
      identifier,
      primaryText: nameConflict
        ? intl.formatMessage(
            {
              defaultMessage:
                'e-conomic projekt medarbejderen "{name}" konflikter med e-conomic afdelingen: "{identifier}: {nameConflict}". Skal rettes i e-conomic, før der kan importeres',
            },
            {identifier, name, nameConflict},
          )
        : name,
      searchFields: [
        {label: "Nr", priority: 10, text: identifier},
        {label: "Navn", priority: 5, text: name},
      ],
      secondaryText: identifier,
    };
  });
}

interface ImportEconomicMachinesPreviewDialogProps {
  departmentImport: boolean;
  employeeImport: boolean;
  error: string | undefined;
  onCancel(): void;
  onOk(identifiers: ReadonlySet<string>): void;
  open: boolean;
  previewMachines: readonly ImportPreviewMachine[] | null;
}

export const ImportEconomicMachinesPreviewDialog = React.memo(
  function ImportEconomicMachinesPreviewDialog(props: ImportEconomicMachinesPreviewDialogProps) {
    const {departmentImport, employeeImport, error, onCancel, onOk, open, previewMachines} = props;
    const intl = useIntl();

    const [doComputeBaseChoices, reuseBaseChoices] = useMemo(
      () => memoizeForceReuse(computeBaseChoices, []),
      [],
    );

    const getBaseChoices = open ? doComputeBaseChoices : reuseBaseChoices;
    const data = previewMachines ? getBaseChoices(previewMachines, intl) : null;
    const listEmptyMessage =
      departmentImport && employeeImport
        ? intl.formatMessage({
            defaultMessage:
              "Alle afdelinger og medarbejdere i e-conomic er allerede importeret til CustomOffice",
          })
        : employeeImport
          ? intl.formatMessage({
              defaultMessage:
                "Alle medarbejdere i e-conomic er allerede importeret til CustomOffice",
            })
          : intl.formatMessage({
              defaultMessage: "Alle afdelinger i e-conomic er allerede importeret til CustomOffice",
            });

    return (
      <GenericMultiSelectionSearchDialog<string>
        data={data}
        error={error}
        listEmptyMessage={listEmptyMessage}
        mobilePrimaryLines={1}
        mobileSearchPrimaryLines={1}
        mobileSearchSecondaryLines={1}
        mobileSecondaryLines={1}
        open={open}
        searchTitle={
          departmentImport && employeeImport
            ? intl.formatMessage({
                defaultMessage: "Søg afdelinger og medarbejdere",
              })
            : employeeImport
              ? intl.formatMessage({
                  defaultMessage: "Søg medarbejdere",
                })
              : intl.formatMessage({
                  defaultMessage: "Søg afdelinger",
                })
        }
        sorting="SECONDARY_IDENTIFIER"
        title={
          departmentImport && employeeImport
            ? intl.formatMessage({
                defaultMessage: "Vælg afdelinger og medarbejdere der skal oprettes som maskiner",
              })
            : employeeImport
              ? intl.formatMessage({
                  defaultMessage: "Vælg medarbejdere der skal oprettes som maskiner",
                })
              : intl.formatMessage({
                  defaultMessage: "Vælg afdelinger der skal oprettes som maskiner",
                })
        }
        onCancel={onCancel}
        onOk={onOk}
      />
    );
  },
);
