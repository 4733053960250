import {Position, TaskUrl, TimerStartUrl, instanceURL} from "@co-common-libs/resources";
import {getCurrentPosition} from "@co-frontend-libs/utils";
import {Dispatch} from "redux";
import {v4 as uuid} from "uuid";
import {getCurrentUserURL, getCustomerSettings} from "../all-selectors";
import * as actions from "../resources/actions";
import {ResourcesAuthenticationMiddlewareAPI} from "./types";

function registerPosition(
  baseUrl: string,
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  timerStartUrl: TimerStartUrl | null,
  taskUrl: TaskUrl,
): void {
  const state = middlewareApi.getState();
  const customerSettings = getCustomerSettings(state);
  const currentUserUrl = getCurrentUserURL(state);
  if (!customerSettings.geolocation.enabled || !currentUserUrl) {
    return;
  }
  const geolocationPositionPromise = getCurrentPosition();
  const id = uuid();
  const url = instanceURL(baseUrl, "position", id);
  const instancePromise = geolocationPositionPromise.then(
    ({coords: {accuracy, latitude, longitude}, timestamp}) => {
      const instance: Position = {
        accuracy,
        deviceTimestamp: new Date(timestamp).toISOString(),
        employee: currentUserUrl,
        id,
        latitude,
        longitude,
        task: taskUrl,
        timerStart: timerStartUrl,
        url,
      };
      return instance;
    },
  );
  middlewareApi.dispatch(actions.createPromise({promise: instancePromise, url}));
}

export function registerTimerStartPosition(
  baseUrl: string,
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  next: Dispatch,
  action: ReturnType<typeof actions.registerTimerStartPosition>,
): ReturnType<typeof actions.registerTimerStartPosition> {
  const result = next(action);
  const {timerStart} = action.payload;
  registerPosition(baseUrl, middlewareApi, timerStart.url, timerStart.task);
  return result;
}

export function registerTaskPosition(
  baseUrl: string,
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  next: Dispatch,
  action: ReturnType<typeof actions.registerTaskPosition>,
): ReturnType<typeof actions.registerTaskPosition> {
  const result = next(action);
  const {taskUrl} = action.payload;
  registerPosition(baseUrl, middlewareApi, null, taskUrl);
  return result;
}
