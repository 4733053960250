// from mdi-react version 7.4.0
import {MdiReactIconProps} from "mdi-react";
import React, {FunctionComponent} from "react";

const DEFAULT_SIZE = 24;

export const CoinOutlineIcon: FunctionComponent<MdiReactIconProps> = React.memo(
  function CoinOutlineIcon({children, color = "currentColor", size = DEFAULT_SIZE, ...props}) {
    const className = `mdi-icon ${props.className || ""}`;
    return (
      <svg
        {...props}
        className={className}
        fill={color}
        height={size}
        viewBox="0 0 24 24"
        width={size}
      >
        <path d="M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4M11,17V16H9V14H13V13H10A1,1 0 0,1 9,12V9A1,1 0 0,1 10,8H11V7H13V8H15V10H11V11H14A1,1 0 0,1 15,12V15A1,1 0 0,1 14,16H13V17H11Z" />
      </svg>
    );
  },
);
