import {Query} from "@co-frontend-libs/db-resources";
import {Draft, current} from "@reduxjs/toolkit";
import {temporaryQueryPurged} from "../actions";
import {GenericInstanceRecord, ResourcesState} from "../types";
import {
  buildCheckFunction,
  buildResourceCheckMapping,
  partialEntriesForEach,
  partialValuesMap,
} from "../utils";
import {getAllResourcesData} from "./selectors";

function dataCleanupAfterTemporaryQueryPurged(
  state: Draft<ResourcesState>,
  purgedQuery: Query,
): void {
  const updatedState = current(state) as ResourcesState;
  const allData = getAllResourcesData(updatedState);
  const temporaryResourceCheckMapping = buildResourceCheckMapping(
    partialValuesMap(updatedState.temporaryQueries, ({query}) => query),
  );
  const {resourceName} = purgedQuery;
  const resourceInstanceRecord = updatedState.temporaryData[resourceName];

  if (!Object.keys(resourceInstanceRecord).length) {
    return;
  }
  const resourceTemporaryCheck = temporaryResourceCheckMapping[resourceName];
  if (resourceTemporaryCheck.type === "alwaysOk") {
    return;
  }
  const temporaryCheckFunction = buildCheckFunction(resourceTemporaryCheck, allData);
  partialEntriesForEach(resourceInstanceRecord as GenericInstanceRecord, (url, instance) => {
    if (!temporaryCheckFunction(instance)) {
      delete state.temporaryData[resourceName][url];
    }
  });
}

export function handleTemporaryQueryPurged(
  state: Draft<ResourcesState>,
  action: ReturnType<typeof temporaryQueryPurged>,
): void {
  const query = action.payload;
  const {keyString} = query;
  const existing = state.temporaryQueries[keyString];
  console.assert(existing && existing.queryState.pendingRemoval);
  if (existing && existing.queryState.pendingRemoval) {
    delete state.temporaryQueries[keyString];
    dataCleanupAfterTemporaryQueryPurged(state, query);
  }
}
