import {Timer, WorkTypeUrl} from "@co-common-libs/resources";
import {ConnectedCombinedWorkTypesDialog} from "@co-frontend-libs/connected-components";
import {
  actions,
  getCurrentUserURL,
  getCustomerSettings,
  getSettingsEntryLookupByIdentifier,
  getWorkTypeArray,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {Chip, IconButton} from "@material-ui/core";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import PlusIcon from "mdi-react/PlusIcon";
import React, {useCallback, useMemo, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuid} from "uuid";

interface DeleteChipProps {
  identifier: string;
  label: string;
  onDelete: (identifier: string) => void;
}

function DeleteChip(props: DeleteChipProps): JSX.Element {
  const {identifier, label, onDelete} = props;
  const handleDelete = useMemo(() => () => onDelete(identifier), [identifier, onDelete]);
  return <Chip label={label} onDelete={handleDelete} />;
}

interface WorkTypeHideTimersProps {
  timer: Timer;
}

export function WorkTypeHideTimers(props: WorkTypeHideTimersProps): JSX.Element {
  const {timer} = props;
  const timerID = timer.identifier;

  const dispatch = useDispatch();

  const customerSettings = useSelector(getCustomerSettings);
  const workTypeArray = useSelector(getWorkTypeArray);
  const workTypeLookup = useSelector(getWorkTypeLookup);
  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const currentUserURL = useSelector(getCurrentUserURL);

  const {workTypeHideTimers} = customerSettings;

  const [workTypeDialogOpen, setWorkTypeDialogOpen] = useState(false);
  const setWorkTypeDialogOpenTrue = useCallWithTrue(setWorkTypeDialogOpen);
  const setWorkTypeDialogOpenFalse = useCallWithFalse(setWorkTypeDialogOpen);

  const workTypeIDs = useMemo(
    () =>
      Object.entries(workTypeHideTimers)
        .filter(([_workType, timers]) => timers.includes(timerID))
        .map(([workType, _timers]) => workType),
    [workTypeHideTimers, timerID],
  );

  const getWorkTypeLabel = useCallback(
    (workTypeID: string) => {
      const workType = workTypeArray.find((p) => p.identifier === workTypeID);
      if (workType) {
        return `${workTypeID}: ${workType.name}`;
      } else {
        return workTypeID;
      }
    },
    [workTypeArray],
  );

  const updateSetting = useCallback(
    (value: any): void => {
      const settingID = "workTypeHideTimers";
      const settingsEntry = settingsEntryLookupByIdentifier(settingID);
      if (settingsEntry) {
        if (!_.isEqual(value, settingsEntry.data)) {
          dispatch(
            actions.update(settingsEntry.url, [
              {member: "changedBy", value: currentUserURL},
              {member: "data", value},
            ]),
          );
        }
      } else {
        const id = uuid();
        const url = instanceURL("settingEntry", id);
        dispatch(
          actions.create({
            changedBy: currentUserURL,
            data: value,
            key: settingID,
            url,
          }),
        );
      }
    },
    [currentUserURL, dispatch, settingsEntryLookupByIdentifier],
  );

  const handleRemoveWorkType = useCallback(
    (identifier: string) => {
      const oldTimerArray = workTypeHideTimers[identifier] || [];
      const newTimerArray = oldTimerArray.filter((x) => x !== timerID);
      const newValue = {...workTypeHideTimers};
      if (newTimerArray.length) {
        newValue[identifier] = newTimerArray;
      } else {
        delete newValue[identifier];
      }
      updateSetting(newValue);
    },
    [workTypeHideTimers, timerID, updateSetting],
  );

  const handleAddWorkType = useCallback(
    (url: WorkTypeUrl) => {
      setWorkTypeDialogOpen(false);
      const workType = workTypeLookup(url);
      if (!workType || workTypeIDs.includes(workType.identifier)) {
        return;
      }
      const {identifier} = workType;
      const oldTimerArray = workTypeHideTimers[identifier] || [];
      const newTimerArray = _.uniq([...oldTimerArray, timerID]);
      const newValue = {...workTypeHideTimers};
      if (newTimerArray.length) {
        newValue[identifier] = newTimerArray;
      } else {
        delete newValue[identifier];
      }
      updateSetting(newValue);
    },
    [workTypeHideTimers, workTypeIDs, workTypeLookup, timerID, updateSetting],
  );

  return (
    <>
      <div>
        <h3>
          <FormattedMessage
            defaultMessage="Skjult for områder"
            id="timers.header.hidden-for-work-types"
          />
        </h3>
        <div>
          {workTypeIDs.map((workTypeID) => (
            <DeleteChip
              key={workTypeID}
              identifier={workTypeID}
              label={getWorkTypeLabel(workTypeID)}
              onDelete={handleRemoveWorkType}
            />
          ))}
          <IconButton onClick={setWorkTypeDialogOpenTrue}>
            <PlusIcon />
          </IconButton>
        </div>
      </div>
      <ConnectedCombinedWorkTypesDialog
        open={workTypeDialogOpen}
        onCancel={setWorkTypeDialogOpenFalse}
        onOk={handleAddWorkType}
      />
    </>
  );
}
