import {Patch, Task} from "@co-common-libs/resources";
import _ from "lodash";
import {ProvisionaryCommand} from "../../resources/actions";
import {getMachineLookup, getWorkTypeLookup} from "../../resources/selectors";
import {ResourcesAuthenticationMiddlewareAPI} from "../types";

/*
Department priority:
1: provided department (unless blank on CREATE)
2: provided worktype if it has department
3: existing value *if* existing worktype has department -- not necessarily
    the same -- and worktype not provided
4: provided machines (if they agree/only one non-blank value observed)
5: existing value
*/

export function autoDepartment(
  newTask: Task | null,
  oldTask: Task | undefined,
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  command: ProvisionaryCommand,
): Patch<Task> | null {
  if (!newTask) {
    return null;
  }

  if (
    newTask.recordedInC5 ||
    newTask.archivable ||
    ((!newTask.workType || newTask.workType === oldTask?.workType) &&
      (!newTask.machineuseSet || _.isEqual(newTask.machineuseSet, oldTask?.machineuseSet)))
  ) {
    // nothing to process
    return null;
  }
  if (
    (newTask.department || (command.action === "UPDATE" && newTask.department === "")) &&
    newTask.department !== oldTask?.department
  ) {
    // department explicitly set or cleared; intentionally allowing
    // override of "explicitly set" blank value on CREATE
    return null;
  }
  console.assert(newTask.workType || newTask.machineuseSet);

  const state = middlewareApi.getState();

  const workTypeLookup = getWorkTypeLookup(state);

  if (newTask.workType && newTask.workType !== oldTask?.workType) {
    const workType = workTypeLookup(newTask.workType);
    if (workType && workType.department) {
      // ensure that department from newly set workType is used
      if (workType.department !== newTask.department) {
        return [{member: "department", value: workType.department}];
      } else {
        return null;
      }
    }
  } else {
    if (newTask.workType) {
      const workType = workTypeLookup(newTask.workType);
      if (workType && workType.department) {
        // workType set previously specified department -- don't overwrite
        // with auto-department from machine; keep whatever that was or
        // whatever it was manually replaced with...
        return null;
      }
    }
  }

  if (newTask.machineuseSet && !_.isEqual(newTask.machineuseSet, oldTask?.machineuseSet)) {
    const machineLookup = getMachineLookup(state);
    const departments = new Set<string>();
    newTask.machineuseSet.forEach((machineUse) => {
      const machine = machineLookup(machineUse.machine);
      if (machine?.department) {
        departments.add(machine.department);
      }
    });
    if (departments.size === 1) {
      const department = Array.from(departments)[0];
      if (department !== newTask.department) {
        return [{member: "department", value: department}];
      } else {
        return null;
      }
    }
  }

  return null;
}
