import {Machine, MachineUrl} from "@co-common-libs/resources";
import {PureComponent} from "app-utils";
import React from "react";
import {
  ConnectDragSource,
  ConnectDropTarget,
  DragSource,
  DropTarget,
  DropTargetMonitor,
} from "react-dnd";
import {DAY_HEIGHT, MACHINES_WIDTH} from "./constants";

interface MachineCellOwnProps {
  machine: Machine;
  machineOrderingMode: boolean;
  maxTasks: number;
  onDragDrop: (sourceMachineURL: MachineUrl, targetMachineURL: MachineUrl) => void;
}

interface MachineCellDragSourceProps {
  connectDragSource: ConnectDragSource;
  isDragging: boolean;
}

interface MachineCellDropTargetProps {
  canDrop: boolean;
  connectDropTarget: ConnectDropTarget;
  isOver: boolean;
}

type MachineCellProps = MachineCellDragSourceProps &
  MachineCellDropTargetProps &
  MachineCellOwnProps;

class MachineCell extends PureComponent<MachineCellProps> {
  render(): JSX.Element {
    const {connectDragSource, connectDropTarget, machine, maxTasks} = this.props;
    const height = (maxTasks || 1) * DAY_HEIGHT;
    const outerStyle: React.CSSProperties = {
      height,
      overflow: "hidden",
      padding: 1,
      width: MACHINES_WIDTH,
    };
    const boxStyle: React.CSSProperties = {
      borderColor: "#000",
      borderStyle: "solid",
      borderWidth: 1,

      height: DAY_HEIGHT - 2,
      overflow: "hidden",

      width: MACHINES_WIDTH - 2,
    };
    const innerStyle: React.CSSProperties = {
      height: (DAY_HEIGHT - 4) / 2,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",

      width: MACHINES_WIDTH - 4,
    };

    return connectDragSource(
      connectDropTarget(
        <div style={outerStyle}>
          <div style={boxStyle}>
            <div style={innerStyle}>{machine.name}</div>
            <div style={innerStyle}>({machine.c5_machine})</div>
          </div>
        </div>,
      ) as JSX.Element,
    ) as JSX.Element;
  }
}

const machineTarget = {
  canDrop(props: MachineCellOwnProps, monitor: DropTargetMonitor<{machineURL: MachineUrl}>) {
    return monitor.getItem().machineURL !== props.machine.url;
  },
  drop(props: MachineCellOwnProps, monitor: DropTargetMonitor<{machineURL: MachineUrl}>) {
    props.onDragDrop(monitor.getItem().machineURL, props.machine.url);
    return;
  },
};

const machineSource = {
  beginDrag(props: MachineCellOwnProps) {
    return {
      machineURL: props.machine.url,
    };
  },
  canDrag(props: MachineCellOwnProps) {
    return props.machineOrderingMode;
  },
};

const DropTargetMachineCell = DropTarget("machine", machineTarget, (connect, monitor) => ({
  canDrop: monitor.canDrop(),
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
}))(MachineCell);

const DragSourceDropTargetMachineCell = DragSource(
  "machine",
  machineSource,
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  }),
)(DropTargetMachineCell);

export {DragSourceDropTargetMachineCell as MachineCell};
