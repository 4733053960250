import {createAction} from "@reduxjs/toolkit";
import {createMultiPathBuilder} from "./path-builder";
import {PartialNavigationKind, PathParameters, QueryParameters} from "./types";

export const putQueryKey = createAction(
  "routing-sync-history/queryPutKey",
  (key: string, value: string, navigationKind: PartialNavigationKind = "REPLACE") => ({
    payload: {key, navigationKind, value},
  }),
);

export const putQueryKeys = createAction(
  "routing-sync-history/queryPutKeys",
  (update: QueryParameters, navigationKind: PartialNavigationKind = "REPLACE") => ({
    payload: {navigationKind, update},
  }),
);

export const deleteQueryKey = createAction(
  "routing-sync-history/queryDeleteKey",
  (key: string, navigationKind: PartialNavigationKind = "REPLACE") => ({
    payload: {key, navigationKind},
  }),
);

export const replaceQuery = createAction(
  "routing-sync-history/queryReplace",
  (queryParameters: QueryParameters, navigationKind: PartialNavigationKind = "REPLACE") => ({
    payload: {navigationKind, queryParameters},
  }),
);

export const navigate = createAction(
  "routing-sync-history/navigate",
  (
    pathname: string,
    queryParameters: QueryParameters = {},
    navigationKind: PartialNavigationKind = "PUSH",
  ) => ({payload: {navigationKind, pathname, queryParameters}}),
);

export const back = createAction(
  "routing-sync-history/back",
  (count: number = 1, fallback: string = "/") => ({payload: {count, fallback}}),
);

export const navigateFromBrowser = createAction(
  "routing-sync-history/navigateFromBrowser",
  (pathname: string, queryParameters: QueryParameters, position: number | null) => ({
    payload: {pathname, position, queryParameters},
  }),
);

export function createNavigationCreator<T extends string>(
  pathTemplates: readonly T[],
): (
  pathTemplate: T,
  pathParameters?: PathParameters,
  queryParameters?: QueryParameters,
  navigationKind?: PartialNavigationKind,
) => ReturnType<typeof navigate> {
  const getPathname = createMultiPathBuilder(pathTemplates);
  return (
    pathTemplate: T,
    pathParameters: PathParameters = {},
    queryParameters: QueryParameters = {},
    navigationKind: PartialNavigationKind = "PUSH",
  ) => {
    const pathname = getPathname(pathTemplate, pathParameters);
    if (!pathname) {
      throw new Error(`no matching path for: ${pathTemplate}, ${JSON.stringify(pathParameters)}`);
    }
    return navigate(pathname, queryParameters, navigationKind);
  };
}

export const backSkip = createAction(
  "routing-sync-history/backSkip",
  (skip: readonly string[], fallback: string = "/") => ({
    payload: {fallback, skip},
  }),
);

export const forwardBackSkip = createAction(
  "routing-sync-history/forwardBackSkip",
  (skip: readonly string[], fallback: string = "/") => ({
    payload: {fallback, skip},
  }),
);
