import {ResponsiveDialog} from "@co-frontend-libs/components";
import {DialogContent, TextField} from "@material-ui/core";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import bowser from "bowser";
import React from "react";
import {IntlContext, defineMessages} from "react-intl";

const messages = defineMessages({
  newPassword: {
    defaultMessage: "Ny adgangskode",
    id: "new-password-dialog.label.password",
  },
  title: {
    defaultMessage: "Skift adgangskode",
    id: "new-password-dialog.title",
  },
});

interface NewPasswordDialogProps {
  onCancel: () => void;
  onOk: (password: string) => void;
  open: boolean;
}

interface NewPasswordDialogState {
  password: string;
}

class NewPasswordDialog extends PureComponent<NewPasswordDialogProps, NewPasswordDialogState> {
  state: NewPasswordDialogState = {
    password: "",
  };
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  @bind
  handleCancel(): void {
    this.setState({password: ""});
    this.props.onCancel();
  }
  @bind
  handleOk(): void {
    this.props.onOk(this.state.password);
    this.setState({password: ""});
  }
  @bind
  handlePasswordFieldChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const {value} = event.target;
    this.setState({password: value});
  }
  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {open} = this.props;
    const fullscreenLayout =
      bowser.mobile || (bowser.tablet && window.innerHeight < window.innerWidth);

    return (
      <ResponsiveDialog
        fullscreen={!!fullscreenLayout}
        okDisabled={!this.state.password}
        open={open}
        title={formatMessage(messages.title)}
        onCancel={this.handleCancel}
        onOk={this.handleOk}
      >
        <DialogContent>
          <TextField
            autoFocus
            fullWidth
            autoComplete="new-password"
            label={formatMessage(messages.newPassword)}
            margin="dense"
            type="password"
            value={this.state.password}
            variant="outlined"
            onChange={this.handlePasswordFieldChange}
          />
        </DialogContent>
      </ResponsiveDialog>
    );
  }
}

export default NewPasswordDialog;
