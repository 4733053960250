import {makeQueryParameterGetter} from "@co-frontend-libs/redux";
import {useSelector} from "react-redux";

export function useQueryParameter<T extends string = string>(
  key: string,
  defaultValue: T = "" as T,
): T {
  const queryParameterValue = useSelector(makeQueryParameterGetter(key, defaultValue));
  return queryParameterValue as T;
}
