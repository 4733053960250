// This file generated by rebuild-instance-url.mjs
import {
  AccomodationAllowanceUrl,
  AccumulatedCompensatoryUrl,
  AnniversaryTypeUrl,
  ArchiveFileUrl,
  ArchiveLinkUrl,
  AvailabilityUrl,
  CachedDailyAccumulatedCompensatoryUrl,
  CalendarOrderingUrl,
  CalendarWorkHoursUrl,
  ContactUrl,
  CropUrl,
  CultureUrl,
  CustomerFileUrl,
  CustomerUrl,
  DaysAbsenceUrl,
  DeliveryLocationUrl,
  DeliveryUrl,
  DinnerBookingUrl,
  EmployeeGroupUrl,
  ExpectedAmountUrl,
  HoursAbsenceUrl,
  InformationUrl,
  JournalUrl,
  KrPerLiterDefaultFuelSurchargeUseUrl,
  KrPerLiterFuelSurchargeBasisUrl,
  KrPerLiterFuelSurchargeSpecificationEntryUrl,
  KrPerLiterFuelSurchargeSpecificationUrl,
  KrPerLiterMachineFuelSurchargeUseUrl,
  KrPerLiterWorkTypeFuelSurchargeUseUrl,
  LocationStorageAdjustmentUrl,
  LocationStorageChangeUrl,
  LocationStorageStatusUrl,
  LocationTypeUrl,
  LocationUrl,
  LocationUseLogUrl,
  LunchBookingUrl,
  MachineGroupUrl,
  MachineLastUsedUrl,
  MachineUrl,
  MachineUseLogUrl,
  OrderFileUrl,
  OrderUrl,
  PickupLocationUrl,
  PickupUrl,
  PositionUrl,
  PriceGroupUrl,
  PriceItemUrl,
  PricePercentDefaultFuelSurchargeUseUrl,
  PricePercentFuelSurchargeBasisUrl,
  PricePercentFuelSurchargeSpecificationEntryUrl,
  PricePercentFuelSurchargeSpecificationUrl,
  PricePercentMachineFuelSurchargeUseUrl,
  PricePercentWorkTypeFuelSurchargeUseUrl,
  ProductGroupUrl,
  ProductUrl,
  ProductUseLogUrl,
  ProjectFileUrl,
  ProjectUrl,
  PunchInOutUrl,
  RemunerationReportUrl,
  ReportSetupUrl,
  ReportingPrintoutUrl,
  ReportingSpecificationUrl,
  ResourceName,
  ResourceUrls,
  RoleUrl,
  RouteLogReportUrl,
  RoutePlanTaskActivityOptionUrl,
  RoutePlanTaskResultUrl,
  RoutePlanTaskUrl,
  RoutePlanUrl,
  RouteTaskActivityOptionUrl,
  RouteTaskResultUrl,
  RouteTaskUrl,
  SettingEntryUrl,
  SettingsUrl,
  SprayLocationUrl,
  SprayLogReportUrl,
  SprayLogUrl,
  SprayUrl,
  SyncLogUrl,
  TaskFileUrl,
  TaskPhotoUrl,
  TaskUrl,
  TasksPrintoutUrl,
  TimerStartUrl,
  TimerUrl,
  TransportLogReportUrl,
  TransportLogUrl,
  UnitUrl,
  UserFileUrl,
  UserPhotoUrl,
  UserProfileUrl,
  UserUrl,
  WorkTypeUrl,
  WorkshopChecklistItemUrl,
  WorkshopChecklistUrl,
  YieldDeliveryLocationUrl,
  YieldDeliveryUrl,
  YieldLogReportUrl,
  YieldLogUrl,
  YieldPickupLocationUrl,
  YieldPickupUrl,
  instanceURL as resourceInstanceURL,
} from "@co-common-libs/resources";
import {globalConfig} from "./global";

const {baseURL} = globalConfig.resources;

export function instanceURL(
  resourceName: "accomodationAllowance",
  id: string,
): AccomodationAllowanceUrl;
export function instanceURL(
  resourceName: "accumulatedCompensatory",
  id: string,
): AccumulatedCompensatoryUrl;
export function instanceURL(
  resourceName: "anniversaryType",
  id: string,
): AnniversaryTypeUrl;
export function instanceURL(
  resourceName: "archiveFile",
  id: string,
): ArchiveFileUrl;
export function instanceURL(
  resourceName: "archiveLink",
  id: string,
): ArchiveLinkUrl;
export function instanceURL(
  resourceName: "availability",
  id: string,
): AvailabilityUrl;
export function instanceURL(
  resourceName: "cachedDailyAccumulatedCompensatory",
  id: string,
): CachedDailyAccumulatedCompensatoryUrl;
export function instanceURL(
  resourceName: "calendarOrdering",
  id: string,
): CalendarOrderingUrl;
export function instanceURL(
  resourceName: "calendarWorkHours",
  id: string,
): CalendarWorkHoursUrl;
export function instanceURL(
  resourceName: "contact",
  id: string,
): ContactUrl;
export function instanceURL(
  resourceName: "crop",
  id: string,
): CropUrl;
export function instanceURL(
  resourceName: "culture",
  id: string,
): CultureUrl;
export function instanceURL(
  resourceName: "customer",
  id: string,
): CustomerUrl;
export function instanceURL(
  resourceName: "customerFile",
  id: string,
): CustomerFileUrl;
export function instanceURL(
  resourceName: "daysAbsence",
  id: string,
): DaysAbsenceUrl;
export function instanceURL(
  resourceName: "delivery",
  id: string,
): DeliveryUrl;
export function instanceURL(
  resourceName: "deliveryLocation",
  id: string,
): DeliveryLocationUrl;
export function instanceURL(
  resourceName: "dinnerBooking",
  id: string,
): DinnerBookingUrl;
export function instanceURL(
  resourceName: "employeeGroup",
  id: string,
): EmployeeGroupUrl;
export function instanceURL(
  resourceName: "expectedAmount",
  id: string,
): ExpectedAmountUrl;
export function instanceURL(
  resourceName: "hoursAbsence",
  id: string,
): HoursAbsenceUrl;
export function instanceURL(
  resourceName: "information",
  id: string,
): InformationUrl;
export function instanceURL(
  resourceName: "journal",
  id: string,
): JournalUrl;
export function instanceURL(
  resourceName: "krPerLiterDefaultFuelSurchargeUse",
  id: string,
): KrPerLiterDefaultFuelSurchargeUseUrl;
export function instanceURL(
  resourceName: "krPerLiterFuelSurchargeBasis",
  id: string,
): KrPerLiterFuelSurchargeBasisUrl;
export function instanceURL(
  resourceName: "krPerLiterFuelSurchargeSpecification",
  id: string,
): KrPerLiterFuelSurchargeSpecificationUrl;
export function instanceURL(
  resourceName: "krPerLiterFuelSurchargeSpecificationEntry",
  id: string,
): KrPerLiterFuelSurchargeSpecificationEntryUrl;
export function instanceURL(
  resourceName: "krPerLiterMachineFuelSurchargeUse",
  id: string,
): KrPerLiterMachineFuelSurchargeUseUrl;
export function instanceURL(
  resourceName: "krPerLiterWorkTypeFuelSurchargeUse",
  id: string,
): KrPerLiterWorkTypeFuelSurchargeUseUrl;
export function instanceURL(
  resourceName: "location",
  id: string,
): LocationUrl;
export function instanceURL(
  resourceName: "locationStorageAdjustment",
  id: string,
): LocationStorageAdjustmentUrl;
export function instanceURL(
  resourceName: "locationStorageChange",
  id: string,
): LocationStorageChangeUrl;
export function instanceURL(
  resourceName: "locationStorageStatus",
  id: string,
): LocationStorageStatusUrl;
export function instanceURL(
  resourceName: "locationType",
  id: string,
): LocationTypeUrl;
export function instanceURL(
  resourceName: "locationUseLog",
  id: string,
): LocationUseLogUrl;
export function instanceURL(
  resourceName: "lunchBooking",
  id: string,
): LunchBookingUrl;
export function instanceURL(
  resourceName: "machine",
  id: string,
): MachineUrl;
export function instanceURL(
  resourceName: "machineGroup",
  id: string,
): MachineGroupUrl;
export function instanceURL(
  resourceName: "machineLastUsed",
  id: string,
): MachineLastUsedUrl;
export function instanceURL(
  resourceName: "machineUseLog",
  id: string,
): MachineUseLogUrl;
export function instanceURL(
  resourceName: "order",
  id: string,
): OrderUrl;
export function instanceURL(
  resourceName: "orderFile",
  id: string,
): OrderFileUrl;
export function instanceURL(
  resourceName: "pickup",
  id: string,
): PickupUrl;
export function instanceURL(
  resourceName: "pickupLocation",
  id: string,
): PickupLocationUrl;
export function instanceURL(
  resourceName: "position",
  id: string,
): PositionUrl;
export function instanceURL(
  resourceName: "priceGroup",
  id: string,
): PriceGroupUrl;
export function instanceURL(
  resourceName: "priceItem",
  id: string,
): PriceItemUrl;
export function instanceURL(
  resourceName: "pricePercentDefaultFuelSurchargeUse",
  id: string,
): PricePercentDefaultFuelSurchargeUseUrl;
export function instanceURL(
  resourceName: "pricePercentFuelSurchargeBasis",
  id: string,
): PricePercentFuelSurchargeBasisUrl;
export function instanceURL(
  resourceName: "pricePercentFuelSurchargeSpecification",
  id: string,
): PricePercentFuelSurchargeSpecificationUrl;
export function instanceURL(
  resourceName: "pricePercentFuelSurchargeSpecificationEntry",
  id: string,
): PricePercentFuelSurchargeSpecificationEntryUrl;
export function instanceURL(
  resourceName: "pricePercentMachineFuelSurchargeUse",
  id: string,
): PricePercentMachineFuelSurchargeUseUrl;
export function instanceURL(
  resourceName: "pricePercentWorkTypeFuelSurchargeUse",
  id: string,
): PricePercentWorkTypeFuelSurchargeUseUrl;
export function instanceURL(
  resourceName: "product",
  id: string,
): ProductUrl;
export function instanceURL(
  resourceName: "productGroup",
  id: string,
): ProductGroupUrl;
export function instanceURL(
  resourceName: "productUseLog",
  id: string,
): ProductUseLogUrl;
export function instanceURL(
  resourceName: "project",
  id: string,
): ProjectUrl;
export function instanceURL(
  resourceName: "projectFile",
  id: string,
): ProjectFileUrl;
export function instanceURL(
  resourceName: "punchInOut",
  id: string,
): PunchInOutUrl;
export function instanceURL(
  resourceName: "remunerationReport",
  id: string,
): RemunerationReportUrl;
export function instanceURL(
  resourceName: "reportSetup",
  id: string,
): ReportSetupUrl;
export function instanceURL(
  resourceName: "reportingPrintout",
  id: string,
): ReportingPrintoutUrl;
export function instanceURL(
  resourceName: "reportingSpecification",
  id: string,
): ReportingSpecificationUrl;
export function instanceURL(
  resourceName: "role",
  id: string,
): RoleUrl;
export function instanceURL(
  resourceName: "routeLogReport",
  id: string,
): RouteLogReportUrl;
export function instanceURL(
  resourceName: "routePlan",
  id: string,
): RoutePlanUrl;
export function instanceURL(
  resourceName: "routePlanTask",
  id: string,
): RoutePlanTaskUrl;
export function instanceURL(
  resourceName: "routePlanTaskActivityOption",
  id: string,
): RoutePlanTaskActivityOptionUrl;
export function instanceURL(
  resourceName: "routePlanTaskResult",
  id: string,
): RoutePlanTaskResultUrl;
export function instanceURL(
  resourceName: "routeTask",
  id: string,
): RouteTaskUrl;
export function instanceURL(
  resourceName: "routeTaskActivityOption",
  id: string,
): RouteTaskActivityOptionUrl;
export function instanceURL(
  resourceName: "routeTaskResult",
  id: string,
): RouteTaskResultUrl;
export function instanceURL(
  resourceName: "settingEntry",
  id: string,
): SettingEntryUrl;
export function instanceURL(
  resourceName: "settings",
  id: string,
): SettingsUrl;
export function instanceURL(
  resourceName: "spray",
  id: string,
): SprayUrl;
export function instanceURL(
  resourceName: "sprayLocation",
  id: string,
): SprayLocationUrl;
export function instanceURL(
  resourceName: "sprayLog",
  id: string,
): SprayLogUrl;
export function instanceURL(
  resourceName: "sprayLogReport",
  id: string,
): SprayLogReportUrl;
export function instanceURL(
  resourceName: "syncLog",
  id: string,
): SyncLogUrl;
export function instanceURL(
  resourceName: "task",
  id: string,
): TaskUrl;
export function instanceURL(
  resourceName: "taskFile",
  id: string,
): TaskFileUrl;
export function instanceURL(
  resourceName: "taskPhoto",
  id: string,
): TaskPhotoUrl;
export function instanceURL(
  resourceName: "tasksPrintout",
  id: string,
): TasksPrintoutUrl;
export function instanceURL(
  resourceName: "timer",
  id: string,
): TimerUrl;
export function instanceURL(
  resourceName: "timerStart",
  id: string,
): TimerStartUrl;
export function instanceURL(
  resourceName: "transportLog",
  id: string,
): TransportLogUrl;
export function instanceURL(
  resourceName: "transportLogReport",
  id: string,
): TransportLogReportUrl;
export function instanceURL(
  resourceName: "unit",
  id: string,
): UnitUrl;
export function instanceURL(
  resourceName: "user",
  id: string,
): UserUrl;
export function instanceURL(
  resourceName: "userFile",
  id: string,
): UserFileUrl;
export function instanceURL(
  resourceName: "userPhoto",
  id: string,
): UserPhotoUrl;
export function instanceURL(
  resourceName: "userProfile",
  id: string,
): UserProfileUrl;
export function instanceURL(
  resourceName: "workType",
  id: string,
): WorkTypeUrl;
export function instanceURL(
  resourceName: "workshopChecklist",
  id: string,
): WorkshopChecklistUrl;
export function instanceURL(
  resourceName: "workshopChecklistItem",
  id: string,
): WorkshopChecklistItemUrl;
export function instanceURL(
  resourceName: "yieldDelivery",
  id: string,
): YieldDeliveryUrl;
export function instanceURL(
  resourceName: "yieldDeliveryLocation",
  id: string,
): YieldDeliveryLocationUrl;
export function instanceURL(
  resourceName: "yieldLog",
  id: string,
): YieldLogUrl;
export function instanceURL(
  resourceName: "yieldLogReport",
  id: string,
): YieldLogReportUrl;
export function instanceURL(
  resourceName: "yieldPickup",
  id: string,
): YieldPickupUrl;
export function instanceURL(
  resourceName: "yieldPickupLocation",
  id: string,
): YieldPickupLocationUrl;
export function instanceURL<Resource extends ResourceName>(resourceName: Resource, id: string): ResourceUrls[Resource];
export function instanceURL(resourceName: ResourceName, id: string): string {
  return resourceInstanceURL(baseURL, resourceName, id);
}
