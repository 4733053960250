import {IntlShape} from "react-intl";
import {isEconomicUserProfilesIssue} from "../economic-issue-type-guards";
import {EconomicUserProfileIssue, EconomicUserProfilesIssue} from "../types";

// eslint-disable-next-line consistent-return
export function economicUserProfileIssueTranslation(
  issue: EconomicUserProfileIssue | EconomicUserProfilesIssue,
  intl: IntlShape,
): string {
  const {issueType} = issue;
  const count = isEconomicUserProfilesIssue(issue) ? issue.instances.length : 1;
  const names = isEconomicUserProfilesIssue(issue)
    ? issue.instances.map(({alias}) => alias).join(", ")
    : issue.instance.alias;

  switch (issueType) {
    case "barred":
      return intl.formatMessage(
        {
          defaultMessage:
            "{count, plural, one {Følgende bruger} other {# brugere}} er slettet eller spærret i e-conomic: {names}",
        },
        {count, names},
      );
    case "missingRemoteIdentifier":
      return intl.formatMessage(
        {
          defaultMessage:
            "{count, plural, one {Følgende bruger} other {# brugere}} mangler medarbejdernummer: {names}",
        },
        {count, names},
      );
  }
}
