import {Location} from "@co-common-libs/resources";
import {dateToString} from "@co-common-libs/utils";
import {getLocationLookup, makePathParameterGetter} from "@co-frontend-libs/redux";
import {useCallWithTrue} from "@co-frontend-libs/utils";
import {Grid} from "@material-ui/core";
import {
  BackToolbar,
  DoLoadInstance,
  FetchAndDisplayStorageJournals,
  PageLayout,
  PeriodSelection,
} from "app-components";
import React, {useCallback, useMemo, useState} from "react";
import {FormattedMessage} from "react-intl";

const JOURNAL_INITIAL_MONTHS_BACK = 6;

interface SimpleStorageJournalProps {
  instance: Location;
}

function SimpleStorageJournal(props: SimpleStorageJournalProps): JSX.Element {
  const {instance: location} = props;
  const now = new Date();
  const initialFromDate = new Date(now);
  initialFromDate.setMonth(initialFromDate.getMonth() - JOURNAL_INITIAL_MONTHS_BACK);
  const initialFromDateString = dateToString(initialFromDate);
  const initialToDateString = dateToString(now);
  const [fromDateString, setFromDateString] = useState<string | null>(initialFromDateString);
  const [toDateString, setToDateString] = useState<string | null>(initialToDateString);

  const [periodOk, setPeriodOk] = useState(false);
  const setPeriodOkTrue = useCallWithTrue(setPeriodOk);

  const handleFromDateChange = useCallback((newFromDateString: string | null) => {
    setPeriodOk(false);
    setFromDateString(newFromDateString);
  }, []);

  const handleToDateChange = useCallback((newToDateString: string | null) => {
    setPeriodOk(false);
    setToDateString(newToDateString);
  }, []);

  const locationURLs = useMemo(() => [location.url], [location.url]);

  const locationName =
    location?.name && location?.address
      ? `${location.name}, ${location.address}`
      : location?.name || location?.address || "";

  return (
    <PageLayout
      toolbar={
        <BackToolbar
          title={
            <FormattedMessage
              defaultMessage="Lagerjournal"
              id="simple-storage-journal.title.storage-journal"
            />
          }
        />
      }
    >
      <div style={{padding: "1em"}}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormattedMessage
              defaultMessage="Sted: {name}"
              id="simple-storage-journal.card-header.selected-location"
              tagName="h1"
              values={{name: locationName}}
            />
          </Grid>
          <Grid item xs={12}>
            <PeriodSelection
              fromDate={fromDateString}
              toDate={toDateString}
              onFromDateChange={handleFromDateChange}
              onOk={setPeriodOkTrue}
              onToDateChange={handleToDateChange}
            />
          </Grid>
          {fromDateString && toDateString && periodOk ? (
            <FetchAndDisplayStorageJournals
              fromDate={fromDateString}
              locationUrls={locationURLs}
              toDate={toDateString}
            />
          ) : null}
        </Grid>
      </div>
    </PageLayout>
  );
}

function SimpleStorageJournalPage(): JSX.Element {
  const locationIdSelector = useMemo(() => makePathParameterGetter("locationId"), []);

  return (
    <DoLoadInstance
      Component={SimpleStorageJournal}
      idSelector={locationIdSelector}
      loadingTitle={
        <FormattedMessage
          defaultMessage="Lagerjournal"
          id="simple-storage-journal.title.storage-journal"
        />
      }
      lookupSelector={getLocationLookup}
      resourceName="location"
    />
  );
}

export {SimpleStorageJournalPage as SimpleStorageJournal};
