import {ResponsiveDialog} from "@co-frontend-libs/components";
import {Checkbox, DialogContent, FormControlLabel} from "@material-ui/core";
import React, {useCallback, useState} from "react";
import {FormattedMessage} from "react-intl";
import {TermsOfService} from "./terms-of-service";

interface TermsOfServiceDialogProps {
  onCancel: () => void;
  onOk: () => void;
  open: boolean;
}

export const TermsOfServiceDialog = React.memo(function TermsOfServiceDialog({
  onCancel,
  onOk,
  open,
}: TermsOfServiceDialogProps): JSX.Element {
  const [confirmed, setConfirmed] = useState(false);

  const handleTermsReadChanged = useCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
    const {checked} = event.target;
    setConfirmed(checked);
  }, []);

  const handleCancel = useCallback((): void => {
    onCancel();
    setConfirmed(false);
  }, [onCancel]);

  return (
    <ResponsiveDialog
      cancelLabel={
        <FormattedMessage defaultMessage="Luk" id="terms-of-service-dialog.label.close" />
      }
      okDisabled={!confirmed}
      okLabel={
        <FormattedMessage defaultMessage="Godkend" id="terms-of-service-dialog.label.approve" />
      }
      open={open}
      title={
        <FormattedMessage
          defaultMessage="Godkend abonnementsvilkår"
          id="terms-of-service-dialog.label.title"
        />
      }
      onCancel={handleCancel}
      onOk={onOk}
    >
      <DialogContent>
        <TermsOfService />
        <hr />
        <FormControlLabel
          control={<Checkbox checked={confirmed} onChange={handleTermsReadChanged} />}
          label={
            <FormattedMessage
              defaultMessage="Jeg har læst og accepterer med abonnementsvilkårene"
              id="terms-of-service-dialog.label.termsRead"
            />
          }
        />
      </DialogContent>
    </ResponsiveDialog>
  );
});
