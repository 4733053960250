// This file generated by rebuild-resources.py
import {
  AccomodationAllowance,
  AccomodationAllowanceUrl,
  AccumulatedCompensatory,
  AccumulatedCompensatoryUrl,
  AnniversaryType,
  AnniversaryTypeUrl,
  ArchiveFile,
  ArchiveFileUrl,
  ArchiveLink,
  ArchiveLinkUrl,
  Availability,
  AvailabilityUrl,
  CachedDailyAccumulatedCompensatory,
  CachedDailyAccumulatedCompensatoryUrl,
  CalendarOrdering,
  CalendarOrderingUrl,
  CalendarWorkHours,
  CalendarWorkHoursUrl,
  Contact,
  ContactUrl,
  Crop,
  CropUrl,
  Culture,
  CultureUrl,
  Customer,
  CustomerFile,
  CustomerFileUrl,
  CustomerUrl,
  DaysAbsence,
  DaysAbsenceUrl,
  Delivery,
  DeliveryLocation,
  DeliveryLocationUrl,
  DeliveryUrl,
  DinnerBooking,
  DinnerBookingUrl,
  EmployeeGroup,
  EmployeeGroupUrl,
  ExpectedAmount,
  ExpectedAmountUrl,
  HoursAbsence,
  HoursAbsenceUrl,
  Information,
  InformationUrl,
  Journal,
  JournalUrl,
  KrPerLiterDefaultFuelSurchargeUse,
  KrPerLiterDefaultFuelSurchargeUseUrl,
  KrPerLiterFuelSurchargeBasis,
  KrPerLiterFuelSurchargeBasisUrl,
  KrPerLiterFuelSurchargeSpecification,
  KrPerLiterFuelSurchargeSpecificationEntry,
  KrPerLiterFuelSurchargeSpecificationEntryUrl,
  KrPerLiterFuelSurchargeSpecificationUrl,
  KrPerLiterMachineFuelSurchargeUse,
  KrPerLiterMachineFuelSurchargeUseUrl,
  KrPerLiterWorkTypeFuelSurchargeUse,
  KrPerLiterWorkTypeFuelSurchargeUseUrl,
  Location,
  LocationStorageAdjustment,
  LocationStorageAdjustmentUrl,
  LocationStorageChange,
  LocationStorageChangeUrl,
  LocationStorageStatus,
  LocationStorageStatusUrl,
  LocationType,
  LocationTypeUrl,
  LocationUrl,
  LocationUseLog,
  LocationUseLogUrl,
  LunchBooking,
  LunchBookingUrl,
  Machine,
  MachineGroup,
  MachineGroupUrl,
  MachineLastUsed,
  MachineLastUsedUrl,
  MachineUrl,
  MachineUseLog,
  MachineUseLogUrl,
  Order,
  OrderFile,
  OrderFileUrl,
  OrderUrl,
  Pickup,
  PickupLocation,
  PickupLocationUrl,
  PickupUrl,
  Position,
  PositionUrl,
  PriceGroup,
  PriceGroupUrl,
  PriceItem,
  PriceItemUrl,
  PricePercentDefaultFuelSurchargeUse,
  PricePercentDefaultFuelSurchargeUseUrl,
  PricePercentFuelSurchargeBasis,
  PricePercentFuelSurchargeBasisUrl,
  PricePercentFuelSurchargeSpecification,
  PricePercentFuelSurchargeSpecificationEntry,
  PricePercentFuelSurchargeSpecificationEntryUrl,
  PricePercentFuelSurchargeSpecificationUrl,
  PricePercentMachineFuelSurchargeUse,
  PricePercentMachineFuelSurchargeUseUrl,
  PricePercentWorkTypeFuelSurchargeUse,
  PricePercentWorkTypeFuelSurchargeUseUrl,
  Product,
  ProductGroup,
  ProductGroupUrl,
  ProductUrl,
  ProductUseLog,
  ProductUseLogUrl,
  Project,
  ProjectFile,
  ProjectFileUrl,
  ProjectUrl,
  PunchInOut,
  PunchInOutUrl,
  RemunerationReport,
  RemunerationReportUrl,
  ReportSetup,
  ReportSetupUrl,
  ReportingPrintout,
  ReportingPrintoutUrl,
  ReportingSpecification,
  ReportingSpecificationUrl,
  Role,
  RoleUrl,
  RouteLogReport,
  RouteLogReportUrl,
  RoutePlan,
  RoutePlanTask,
  RoutePlanTaskActivityOption,
  RoutePlanTaskActivityOptionUrl,
  RoutePlanTaskResult,
  RoutePlanTaskResultUrl,
  RoutePlanTaskUrl,
  RoutePlanUrl,
  RouteTask,
  RouteTaskActivityOption,
  RouteTaskActivityOptionUrl,
  RouteTaskResult,
  RouteTaskResultUrl,
  RouteTaskUrl,
  SettingEntry,
  SettingEntryUrl,
  Settings,
  SettingsUrl,
  Spray,
  SprayLocation,
  SprayLocationUrl,
  SprayLog,
  SprayLogReport,
  SprayLogReportUrl,
  SprayLogUrl,
  SprayUrl,
  SyncLog,
  SyncLogUrl,
  Task,
  TaskFile,
  TaskFileUrl,
  TaskPhoto,
  TaskPhotoUrl,
  TaskUrl,
  TasksPrintout,
  TasksPrintoutUrl,
  Timer,
  TimerStart,
  TimerStartUrl,
  TimerUrl,
  TransportLog,
  TransportLogReport,
  TransportLogReportUrl,
  TransportLogUrl,
  Unit,
  UnitUrl,
  User,
  UserFile,
  UserFileUrl,
  UserPhoto,
  UserPhotoUrl,
  UserProfile,
  UserProfileUrl,
  UserUrl,
  WorkType,
  WorkTypeUrl,
  WorkshopChecklist,
  WorkshopChecklistItem,
  WorkshopChecklistItemUrl,
  WorkshopChecklistUrl,
  YieldDelivery,
  YieldDeliveryLocation,
  YieldDeliveryLocationUrl,
  YieldDeliveryUrl,
  YieldLog,
  YieldLogReport,
  YieldLogReportUrl,
  YieldLogUrl,
  YieldPickup,
  YieldPickupLocation,
  YieldPickupLocationUrl,
  YieldPickupUrl,
} from "@co-common-libs/resources";
import {Selector} from "reselect";
import {StateWithResources} from "../types";
import {getData} from "./get-data";
import {recordToArraySelector, recordToLookupSelector} from "./resources-helpers";

const getAccomodationAllowanceRecord = getData("accomodationAllowance");
export const getAccomodationAllowanceLookup: Selector<
  StateWithResources,
  (url: AccomodationAllowanceUrl) => AccomodationAllowance | undefined
> = recordToLookupSelector(getAccomodationAllowanceRecord);
export const getAccomodationAllowanceArray: Selector<
  StateWithResources,
  readonly AccomodationAllowance[]
> = recordToArraySelector(getAccomodationAllowanceRecord);

const getAccumulatedCompensatoryRecord = getData("accumulatedCompensatory");
export const getAccumulatedCompensatoryLookup: Selector<
  StateWithResources,
  (url: AccumulatedCompensatoryUrl) => AccumulatedCompensatory | undefined
> = recordToLookupSelector(getAccumulatedCompensatoryRecord);
export const getAccumulatedCompensatoryArray: Selector<
  StateWithResources,
  readonly AccumulatedCompensatory[]
> = recordToArraySelector(getAccumulatedCompensatoryRecord);

const getAnniversaryTypeRecord = getData("anniversaryType");
export const getAnniversaryTypeLookup: Selector<
  StateWithResources,
  (url: AnniversaryTypeUrl) => AnniversaryType | undefined
> = recordToLookupSelector(getAnniversaryTypeRecord);
export const getAnniversaryTypeArray: Selector<StateWithResources, readonly AnniversaryType[]> =
  recordToArraySelector(getAnniversaryTypeRecord);

const getArchiveFileRecord = getData("archiveFile");
export const getArchiveFileLookup: Selector<
  StateWithResources,
  (url: ArchiveFileUrl) => ArchiveFile | undefined
> = recordToLookupSelector(getArchiveFileRecord);
export const getArchiveFileArray: Selector<StateWithResources, readonly ArchiveFile[]> =
  recordToArraySelector(getArchiveFileRecord);

const getArchiveLinkRecord = getData("archiveLink");
export const getArchiveLinkLookup: Selector<
  StateWithResources,
  (url: ArchiveLinkUrl) => ArchiveLink | undefined
> = recordToLookupSelector(getArchiveLinkRecord);
export const getArchiveLinkArray: Selector<StateWithResources, readonly ArchiveLink[]> =
  recordToArraySelector(getArchiveLinkRecord);

const getAvailabilityRecord = getData("availability");
export const getAvailabilityLookup: Selector<
  StateWithResources,
  (url: AvailabilityUrl) => Availability | undefined
> = recordToLookupSelector(getAvailabilityRecord);
export const getAvailabilityArray: Selector<StateWithResources, readonly Availability[]> =
  recordToArraySelector(getAvailabilityRecord);

const getCachedDailyAccumulatedCompensatoryRecord = getData("cachedDailyAccumulatedCompensatory");
export const getCachedDailyAccumulatedCompensatoryLookup: Selector<
  StateWithResources,
  (url: CachedDailyAccumulatedCompensatoryUrl) => CachedDailyAccumulatedCompensatory | undefined
> = recordToLookupSelector(getCachedDailyAccumulatedCompensatoryRecord);
export const getCachedDailyAccumulatedCompensatoryArray: Selector<
  StateWithResources,
  readonly CachedDailyAccumulatedCompensatory[]
> = recordToArraySelector(getCachedDailyAccumulatedCompensatoryRecord);

const getCalendarOrderingRecord = getData("calendarOrdering");
export const getCalendarOrderingLookup: Selector<
  StateWithResources,
  (url: CalendarOrderingUrl) => CalendarOrdering | undefined
> = recordToLookupSelector(getCalendarOrderingRecord);
export const getCalendarOrderingArray: Selector<StateWithResources, readonly CalendarOrdering[]> =
  recordToArraySelector(getCalendarOrderingRecord);

const getCalendarWorkHoursRecord = getData("calendarWorkHours");
export const getCalendarWorkHoursLookup: Selector<
  StateWithResources,
  (url: CalendarWorkHoursUrl) => CalendarWorkHours | undefined
> = recordToLookupSelector(getCalendarWorkHoursRecord);
export const getCalendarWorkHoursArray: Selector<StateWithResources, readonly CalendarWorkHours[]> =
  recordToArraySelector(getCalendarWorkHoursRecord);

const getContactRecord = getData("contact");
export const getContactLookup: Selector<
  StateWithResources,
  (url: ContactUrl) => Contact | undefined
> = recordToLookupSelector(getContactRecord);
export const getContactArray: Selector<StateWithResources, readonly Contact[]> =
  recordToArraySelector(getContactRecord);

const getCropRecord = getData("crop");
export const getCropLookup: Selector<StateWithResources, (url: CropUrl) => Crop | undefined> =
  recordToLookupSelector(getCropRecord);
export const getCropArray: Selector<StateWithResources, readonly Crop[]> =
  recordToArraySelector(getCropRecord);

const getCultureRecord = getData("culture");
export const getCultureLookup: Selector<
  StateWithResources,
  (url: CultureUrl) => Culture | undefined
> = recordToLookupSelector(getCultureRecord);
export const getCultureArray: Selector<StateWithResources, readonly Culture[]> =
  recordToArraySelector(getCultureRecord);

const getCustomerRecord = getData("customer");
export const getCustomerLookup: Selector<
  StateWithResources,
  (url: CustomerUrl) => Customer | undefined
> = recordToLookupSelector(getCustomerRecord);
export const getCustomerArray: Selector<StateWithResources, readonly Customer[]> =
  recordToArraySelector(getCustomerRecord);

const getCustomerFileRecord = getData("customerFile");
export const getCustomerFileLookup: Selector<
  StateWithResources,
  (url: CustomerFileUrl) => CustomerFile | undefined
> = recordToLookupSelector(getCustomerFileRecord);
export const getCustomerFileArray: Selector<StateWithResources, readonly CustomerFile[]> =
  recordToArraySelector(getCustomerFileRecord);

const getDaysAbsenceRecord = getData("daysAbsence");
export const getDaysAbsenceLookup: Selector<
  StateWithResources,
  (url: DaysAbsenceUrl) => DaysAbsence | undefined
> = recordToLookupSelector(getDaysAbsenceRecord);
export const getDaysAbsenceArray: Selector<StateWithResources, readonly DaysAbsence[]> =
  recordToArraySelector(getDaysAbsenceRecord);

const getDeliveryRecord = getData("delivery");
export const getDeliveryLookup: Selector<
  StateWithResources,
  (url: DeliveryUrl) => Delivery | undefined
> = recordToLookupSelector(getDeliveryRecord);
export const getDeliveryArray: Selector<StateWithResources, readonly Delivery[]> =
  recordToArraySelector(getDeliveryRecord);

const getDeliveryLocationRecord = getData("deliveryLocation");
export const getDeliveryLocationLookup: Selector<
  StateWithResources,
  (url: DeliveryLocationUrl) => DeliveryLocation | undefined
> = recordToLookupSelector(getDeliveryLocationRecord);
export const getDeliveryLocationArray: Selector<StateWithResources, readonly DeliveryLocation[]> =
  recordToArraySelector(getDeliveryLocationRecord);

const getDinnerBookingRecord = getData("dinnerBooking");
export const getDinnerBookingLookup: Selector<
  StateWithResources,
  (url: DinnerBookingUrl) => DinnerBooking | undefined
> = recordToLookupSelector(getDinnerBookingRecord);
export const getDinnerBookingArray: Selector<StateWithResources, readonly DinnerBooking[]> =
  recordToArraySelector(getDinnerBookingRecord);

const getEmployeeGroupRecord = getData("employeeGroup");
export const getEmployeeGroupLookup: Selector<
  StateWithResources,
  (url: EmployeeGroupUrl) => EmployeeGroup | undefined
> = recordToLookupSelector(getEmployeeGroupRecord);
export const getEmployeeGroupArray: Selector<StateWithResources, readonly EmployeeGroup[]> =
  recordToArraySelector(getEmployeeGroupRecord);

const getExpectedAmountRecord = getData("expectedAmount");
export const getExpectedAmountLookup: Selector<
  StateWithResources,
  (url: ExpectedAmountUrl) => ExpectedAmount | undefined
> = recordToLookupSelector(getExpectedAmountRecord);
export const getExpectedAmountArray: Selector<StateWithResources, readonly ExpectedAmount[]> =
  recordToArraySelector(getExpectedAmountRecord);

const getHoursAbsenceRecord = getData("hoursAbsence");
export const getHoursAbsenceLookup: Selector<
  StateWithResources,
  (url: HoursAbsenceUrl) => HoursAbsence | undefined
> = recordToLookupSelector(getHoursAbsenceRecord);
export const getHoursAbsenceArray: Selector<StateWithResources, readonly HoursAbsence[]> =
  recordToArraySelector(getHoursAbsenceRecord);

const getInformationRecord = getData("information");
export const getInformationLookup: Selector<
  StateWithResources,
  (url: InformationUrl) => Information | undefined
> = recordToLookupSelector(getInformationRecord);
export const getInformationArray: Selector<StateWithResources, readonly Information[]> =
  recordToArraySelector(getInformationRecord);

const getJournalRecord = getData("journal");
export const getJournalLookup: Selector<
  StateWithResources,
  (url: JournalUrl) => Journal | undefined
> = recordToLookupSelector(getJournalRecord);
export const getJournalArray: Selector<StateWithResources, readonly Journal[]> =
  recordToArraySelector(getJournalRecord);

const getKrPerLiterDefaultFuelSurchargeUseRecord = getData("krPerLiterDefaultFuelSurchargeUse");
export const getKrPerLiterDefaultFuelSurchargeUseLookup: Selector<
  StateWithResources,
  (url: KrPerLiterDefaultFuelSurchargeUseUrl) => KrPerLiterDefaultFuelSurchargeUse | undefined
> = recordToLookupSelector(getKrPerLiterDefaultFuelSurchargeUseRecord);
export const getKrPerLiterDefaultFuelSurchargeUseArray: Selector<
  StateWithResources,
  readonly KrPerLiterDefaultFuelSurchargeUse[]
> = recordToArraySelector(getKrPerLiterDefaultFuelSurchargeUseRecord);

const getKrPerLiterFuelSurchargeBasisRecord = getData("krPerLiterFuelSurchargeBasis");
export const getKrPerLiterFuelSurchargeBasisLookup: Selector<
  StateWithResources,
  (url: KrPerLiterFuelSurchargeBasisUrl) => KrPerLiterFuelSurchargeBasis | undefined
> = recordToLookupSelector(getKrPerLiterFuelSurchargeBasisRecord);
export const getKrPerLiterFuelSurchargeBasisArray: Selector<
  StateWithResources,
  readonly KrPerLiterFuelSurchargeBasis[]
> = recordToArraySelector(getKrPerLiterFuelSurchargeBasisRecord);

const getKrPerLiterFuelSurchargeSpecificationRecord = getData(
  "krPerLiterFuelSurchargeSpecification",
);
export const getKrPerLiterFuelSurchargeSpecificationLookup: Selector<
  StateWithResources,
  (url: KrPerLiterFuelSurchargeSpecificationUrl) => KrPerLiterFuelSurchargeSpecification | undefined
> = recordToLookupSelector(getKrPerLiterFuelSurchargeSpecificationRecord);
export const getKrPerLiterFuelSurchargeSpecificationArray: Selector<
  StateWithResources,
  readonly KrPerLiterFuelSurchargeSpecification[]
> = recordToArraySelector(getKrPerLiterFuelSurchargeSpecificationRecord);

const getKrPerLiterFuelSurchargeSpecificationEntryRecord = getData(
  "krPerLiterFuelSurchargeSpecificationEntry",
);
export const getKrPerLiterFuelSurchargeSpecificationEntryLookup: Selector<
  StateWithResources,
  (
    url: KrPerLiterFuelSurchargeSpecificationEntryUrl,
  ) => KrPerLiterFuelSurchargeSpecificationEntry | undefined
> = recordToLookupSelector(getKrPerLiterFuelSurchargeSpecificationEntryRecord);
export const getKrPerLiterFuelSurchargeSpecificationEntryArray: Selector<
  StateWithResources,
  readonly KrPerLiterFuelSurchargeSpecificationEntry[]
> = recordToArraySelector(getKrPerLiterFuelSurchargeSpecificationEntryRecord);

const getKrPerLiterMachineFuelSurchargeUseRecord = getData("krPerLiterMachineFuelSurchargeUse");
export const getKrPerLiterMachineFuelSurchargeUseLookup: Selector<
  StateWithResources,
  (url: KrPerLiterMachineFuelSurchargeUseUrl) => KrPerLiterMachineFuelSurchargeUse | undefined
> = recordToLookupSelector(getKrPerLiterMachineFuelSurchargeUseRecord);
export const getKrPerLiterMachineFuelSurchargeUseArray: Selector<
  StateWithResources,
  readonly KrPerLiterMachineFuelSurchargeUse[]
> = recordToArraySelector(getKrPerLiterMachineFuelSurchargeUseRecord);

const getKrPerLiterWorkTypeFuelSurchargeUseRecord = getData("krPerLiterWorkTypeFuelSurchargeUse");
export const getKrPerLiterWorkTypeFuelSurchargeUseLookup: Selector<
  StateWithResources,
  (url: KrPerLiterWorkTypeFuelSurchargeUseUrl) => KrPerLiterWorkTypeFuelSurchargeUse | undefined
> = recordToLookupSelector(getKrPerLiterWorkTypeFuelSurchargeUseRecord);
export const getKrPerLiterWorkTypeFuelSurchargeUseArray: Selector<
  StateWithResources,
  readonly KrPerLiterWorkTypeFuelSurchargeUse[]
> = recordToArraySelector(getKrPerLiterWorkTypeFuelSurchargeUseRecord);

const getLocationRecord = getData("location");
export const getLocationLookup: Selector<
  StateWithResources,
  (url: LocationUrl) => Location | undefined
> = recordToLookupSelector(getLocationRecord);
export const getLocationArray: Selector<StateWithResources, readonly Location[]> =
  recordToArraySelector(getLocationRecord);

const getLocationStorageAdjustmentRecord = getData("locationStorageAdjustment");
export const getLocationStorageAdjustmentLookup: Selector<
  StateWithResources,
  (url: LocationStorageAdjustmentUrl) => LocationStorageAdjustment | undefined
> = recordToLookupSelector(getLocationStorageAdjustmentRecord);
export const getLocationStorageAdjustmentArray: Selector<
  StateWithResources,
  readonly LocationStorageAdjustment[]
> = recordToArraySelector(getLocationStorageAdjustmentRecord);

const getLocationStorageChangeRecord = getData("locationStorageChange");
export const getLocationStorageChangeLookup: Selector<
  StateWithResources,
  (url: LocationStorageChangeUrl) => LocationStorageChange | undefined
> = recordToLookupSelector(getLocationStorageChangeRecord);
export const getLocationStorageChangeArray: Selector<
  StateWithResources,
  readonly LocationStorageChange[]
> = recordToArraySelector(getLocationStorageChangeRecord);

const getLocationStorageStatusRecord = getData("locationStorageStatus");
export const getLocationStorageStatusLookup: Selector<
  StateWithResources,
  (url: LocationStorageStatusUrl) => LocationStorageStatus | undefined
> = recordToLookupSelector(getLocationStorageStatusRecord);
export const getLocationStorageStatusArray: Selector<
  StateWithResources,
  readonly LocationStorageStatus[]
> = recordToArraySelector(getLocationStorageStatusRecord);

const getLocationTypeRecord = getData("locationType");
export const getLocationTypeLookup: Selector<
  StateWithResources,
  (url: LocationTypeUrl) => LocationType | undefined
> = recordToLookupSelector(getLocationTypeRecord);
export const getLocationTypeArray: Selector<StateWithResources, readonly LocationType[]> =
  recordToArraySelector(getLocationTypeRecord);

const getLocationUseLogRecord = getData("locationUseLog");
export const getLocationUseLogLookup: Selector<
  StateWithResources,
  (url: LocationUseLogUrl) => LocationUseLog | undefined
> = recordToLookupSelector(getLocationUseLogRecord);
export const getLocationUseLogArray: Selector<StateWithResources, readonly LocationUseLog[]> =
  recordToArraySelector(getLocationUseLogRecord);

const getLunchBookingRecord = getData("lunchBooking");
export const getLunchBookingLookup: Selector<
  StateWithResources,
  (url: LunchBookingUrl) => LunchBooking | undefined
> = recordToLookupSelector(getLunchBookingRecord);
export const getLunchBookingArray: Selector<StateWithResources, readonly LunchBooking[]> =
  recordToArraySelector(getLunchBookingRecord);

const getMachineRecord = getData("machine");
export const getMachineLookup: Selector<
  StateWithResources,
  (url: MachineUrl) => Machine | undefined
> = recordToLookupSelector(getMachineRecord);
export const getMachineArray: Selector<StateWithResources, readonly Machine[]> =
  recordToArraySelector(getMachineRecord);

const getMachineGroupRecord = getData("machineGroup");
export const getMachineGroupLookup: Selector<
  StateWithResources,
  (url: MachineGroupUrl) => MachineGroup | undefined
> = recordToLookupSelector(getMachineGroupRecord);
export const getMachineGroupArray: Selector<StateWithResources, readonly MachineGroup[]> =
  recordToArraySelector(getMachineGroupRecord);

const getMachineLastUsedRecord = getData("machineLastUsed");
export const getMachineLastUsedLookup: Selector<
  StateWithResources,
  (url: MachineLastUsedUrl) => MachineLastUsed | undefined
> = recordToLookupSelector(getMachineLastUsedRecord);
export const getMachineLastUsedArray: Selector<StateWithResources, readonly MachineLastUsed[]> =
  recordToArraySelector(getMachineLastUsedRecord);

const getMachineUseLogRecord = getData("machineUseLog");
export const getMachineUseLogLookup: Selector<
  StateWithResources,
  (url: MachineUseLogUrl) => MachineUseLog | undefined
> = recordToLookupSelector(getMachineUseLogRecord);
export const getMachineUseLogArray: Selector<StateWithResources, readonly MachineUseLog[]> =
  recordToArraySelector(getMachineUseLogRecord);

const getOrderRecord = getData("order");
export const getOrderLookup: Selector<StateWithResources, (url: OrderUrl) => Order | undefined> =
  recordToLookupSelector(getOrderRecord);
export const getOrderArray: Selector<StateWithResources, readonly Order[]> =
  recordToArraySelector(getOrderRecord);

const getOrderFileRecord = getData("orderFile");
export const getOrderFileLookup: Selector<
  StateWithResources,
  (url: OrderFileUrl) => OrderFile | undefined
> = recordToLookupSelector(getOrderFileRecord);
export const getOrderFileArray: Selector<StateWithResources, readonly OrderFile[]> =
  recordToArraySelector(getOrderFileRecord);

const getPickupRecord = getData("pickup");
export const getPickupLookup: Selector<StateWithResources, (url: PickupUrl) => Pickup | undefined> =
  recordToLookupSelector(getPickupRecord);
export const getPickupArray: Selector<StateWithResources, readonly Pickup[]> =
  recordToArraySelector(getPickupRecord);

const getPickupLocationRecord = getData("pickupLocation");
export const getPickupLocationLookup: Selector<
  StateWithResources,
  (url: PickupLocationUrl) => PickupLocation | undefined
> = recordToLookupSelector(getPickupLocationRecord);
export const getPickupLocationArray: Selector<StateWithResources, readonly PickupLocation[]> =
  recordToArraySelector(getPickupLocationRecord);

const getPositionRecord = getData("position");
export const getPositionLookup: Selector<
  StateWithResources,
  (url: PositionUrl) => Position | undefined
> = recordToLookupSelector(getPositionRecord);
export const getPositionArray: Selector<StateWithResources, readonly Position[]> =
  recordToArraySelector(getPositionRecord);

const getPriceGroupRecord = getData("priceGroup");
export const getPriceGroupLookup: Selector<
  StateWithResources,
  (url: PriceGroupUrl) => PriceGroup | undefined
> = recordToLookupSelector(getPriceGroupRecord);
export const getPriceGroupArray: Selector<StateWithResources, readonly PriceGroup[]> =
  recordToArraySelector(getPriceGroupRecord);

const getPriceItemRecord = getData("priceItem");
export const getPriceItemLookup: Selector<
  StateWithResources,
  (url: PriceItemUrl) => PriceItem | undefined
> = recordToLookupSelector(getPriceItemRecord);
export const getPriceItemArray: Selector<StateWithResources, readonly PriceItem[]> =
  recordToArraySelector(getPriceItemRecord);

const getPricePercentDefaultFuelSurchargeUseRecord = getData("pricePercentDefaultFuelSurchargeUse");
export const getPricePercentDefaultFuelSurchargeUseLookup: Selector<
  StateWithResources,
  (url: PricePercentDefaultFuelSurchargeUseUrl) => PricePercentDefaultFuelSurchargeUse | undefined
> = recordToLookupSelector(getPricePercentDefaultFuelSurchargeUseRecord);
export const getPricePercentDefaultFuelSurchargeUseArray: Selector<
  StateWithResources,
  readonly PricePercentDefaultFuelSurchargeUse[]
> = recordToArraySelector(getPricePercentDefaultFuelSurchargeUseRecord);

const getPricePercentFuelSurchargeBasisRecord = getData("pricePercentFuelSurchargeBasis");
export const getPricePercentFuelSurchargeBasisLookup: Selector<
  StateWithResources,
  (url: PricePercentFuelSurchargeBasisUrl) => PricePercentFuelSurchargeBasis | undefined
> = recordToLookupSelector(getPricePercentFuelSurchargeBasisRecord);
export const getPricePercentFuelSurchargeBasisArray: Selector<
  StateWithResources,
  readonly PricePercentFuelSurchargeBasis[]
> = recordToArraySelector(getPricePercentFuelSurchargeBasisRecord);

const getPricePercentFuelSurchargeSpecificationRecord = getData(
  "pricePercentFuelSurchargeSpecification",
);
export const getPricePercentFuelSurchargeSpecificationLookup: Selector<
  StateWithResources,
  (
    url: PricePercentFuelSurchargeSpecificationUrl,
  ) => PricePercentFuelSurchargeSpecification | undefined
> = recordToLookupSelector(getPricePercentFuelSurchargeSpecificationRecord);
export const getPricePercentFuelSurchargeSpecificationArray: Selector<
  StateWithResources,
  readonly PricePercentFuelSurchargeSpecification[]
> = recordToArraySelector(getPricePercentFuelSurchargeSpecificationRecord);

const getPricePercentFuelSurchargeSpecificationEntryRecord = getData(
  "pricePercentFuelSurchargeSpecificationEntry",
);
export const getPricePercentFuelSurchargeSpecificationEntryLookup: Selector<
  StateWithResources,
  (
    url: PricePercentFuelSurchargeSpecificationEntryUrl,
  ) => PricePercentFuelSurchargeSpecificationEntry | undefined
> = recordToLookupSelector(getPricePercentFuelSurchargeSpecificationEntryRecord);
export const getPricePercentFuelSurchargeSpecificationEntryArray: Selector<
  StateWithResources,
  readonly PricePercentFuelSurchargeSpecificationEntry[]
> = recordToArraySelector(getPricePercentFuelSurchargeSpecificationEntryRecord);

const getPricePercentMachineFuelSurchargeUseRecord = getData("pricePercentMachineFuelSurchargeUse");
export const getPricePercentMachineFuelSurchargeUseLookup: Selector<
  StateWithResources,
  (url: PricePercentMachineFuelSurchargeUseUrl) => PricePercentMachineFuelSurchargeUse | undefined
> = recordToLookupSelector(getPricePercentMachineFuelSurchargeUseRecord);
export const getPricePercentMachineFuelSurchargeUseArray: Selector<
  StateWithResources,
  readonly PricePercentMachineFuelSurchargeUse[]
> = recordToArraySelector(getPricePercentMachineFuelSurchargeUseRecord);

const getPricePercentWorkTypeFuelSurchargeUseRecord = getData(
  "pricePercentWorkTypeFuelSurchargeUse",
);
export const getPricePercentWorkTypeFuelSurchargeUseLookup: Selector<
  StateWithResources,
  (url: PricePercentWorkTypeFuelSurchargeUseUrl) => PricePercentWorkTypeFuelSurchargeUse | undefined
> = recordToLookupSelector(getPricePercentWorkTypeFuelSurchargeUseRecord);
export const getPricePercentWorkTypeFuelSurchargeUseArray: Selector<
  StateWithResources,
  readonly PricePercentWorkTypeFuelSurchargeUse[]
> = recordToArraySelector(getPricePercentWorkTypeFuelSurchargeUseRecord);

const getProductRecord = getData("product");
export const getProductLookup: Selector<
  StateWithResources,
  (url: ProductUrl) => Product | undefined
> = recordToLookupSelector(getProductRecord);
export const getProductArray: Selector<StateWithResources, readonly Product[]> =
  recordToArraySelector(getProductRecord);

const getProductGroupRecord = getData("productGroup");
export const getProductGroupLookup: Selector<
  StateWithResources,
  (url: ProductGroupUrl) => ProductGroup | undefined
> = recordToLookupSelector(getProductGroupRecord);
export const getProductGroupArray: Selector<StateWithResources, readonly ProductGroup[]> =
  recordToArraySelector(getProductGroupRecord);

const getProductUseLogRecord = getData("productUseLog");
export const getProductUseLogLookup: Selector<
  StateWithResources,
  (url: ProductUseLogUrl) => ProductUseLog | undefined
> = recordToLookupSelector(getProductUseLogRecord);
export const getProductUseLogArray: Selector<StateWithResources, readonly ProductUseLog[]> =
  recordToArraySelector(getProductUseLogRecord);

const getProjectRecord = getData("project");
export const getProjectLookup: Selector<
  StateWithResources,
  (url: ProjectUrl) => Project | undefined
> = recordToLookupSelector(getProjectRecord);
export const getProjectArray: Selector<StateWithResources, readonly Project[]> =
  recordToArraySelector(getProjectRecord);

const getProjectFileRecord = getData("projectFile");
export const getProjectFileLookup: Selector<
  StateWithResources,
  (url: ProjectFileUrl) => ProjectFile | undefined
> = recordToLookupSelector(getProjectFileRecord);
export const getProjectFileArray: Selector<StateWithResources, readonly ProjectFile[]> =
  recordToArraySelector(getProjectFileRecord);

const getPunchInOutRecord = getData("punchInOut");
export const getPunchInOutLookup: Selector<
  StateWithResources,
  (url: PunchInOutUrl) => PunchInOut | undefined
> = recordToLookupSelector(getPunchInOutRecord);
export const getPunchInOutArray: Selector<StateWithResources, readonly PunchInOut[]> =
  recordToArraySelector(getPunchInOutRecord);

const getRemunerationReportRecord = getData("remunerationReport");
export const getRemunerationReportLookup: Selector<
  StateWithResources,
  (url: RemunerationReportUrl) => RemunerationReport | undefined
> = recordToLookupSelector(getRemunerationReportRecord);
export const getRemunerationReportArray: Selector<
  StateWithResources,
  readonly RemunerationReport[]
> = recordToArraySelector(getRemunerationReportRecord);

const getReportSetupRecord = getData("reportSetup");
export const getReportSetupLookup: Selector<
  StateWithResources,
  (url: ReportSetupUrl) => ReportSetup | undefined
> = recordToLookupSelector(getReportSetupRecord);
export const getReportSetupArray: Selector<StateWithResources, readonly ReportSetup[]> =
  recordToArraySelector(getReportSetupRecord);

const getReportingPrintoutRecord = getData("reportingPrintout");
export const getReportingPrintoutLookup: Selector<
  StateWithResources,
  (url: ReportingPrintoutUrl) => ReportingPrintout | undefined
> = recordToLookupSelector(getReportingPrintoutRecord);
export const getReportingPrintoutArray: Selector<StateWithResources, readonly ReportingPrintout[]> =
  recordToArraySelector(getReportingPrintoutRecord);

const getReportingSpecificationRecord = getData("reportingSpecification");
export const getReportingSpecificationLookup: Selector<
  StateWithResources,
  (url: ReportingSpecificationUrl) => ReportingSpecification | undefined
> = recordToLookupSelector(getReportingSpecificationRecord);
export const getReportingSpecificationArray: Selector<
  StateWithResources,
  readonly ReportingSpecification[]
> = recordToArraySelector(getReportingSpecificationRecord);

const getRoleRecord = getData("role");
export const getRoleLookup: Selector<StateWithResources, (url: RoleUrl) => Role | undefined> =
  recordToLookupSelector(getRoleRecord);
export const getRoleArray: Selector<StateWithResources, readonly Role[]> =
  recordToArraySelector(getRoleRecord);

const getRouteLogReportRecord = getData("routeLogReport");
export const getRouteLogReportLookup: Selector<
  StateWithResources,
  (url: RouteLogReportUrl) => RouteLogReport | undefined
> = recordToLookupSelector(getRouteLogReportRecord);
export const getRouteLogReportArray: Selector<StateWithResources, readonly RouteLogReport[]> =
  recordToArraySelector(getRouteLogReportRecord);

const getRoutePlanRecord = getData("routePlan");
export const getRoutePlanLookup: Selector<
  StateWithResources,
  (url: RoutePlanUrl) => RoutePlan | undefined
> = recordToLookupSelector(getRoutePlanRecord);
export const getRoutePlanArray: Selector<StateWithResources, readonly RoutePlan[]> =
  recordToArraySelector(getRoutePlanRecord);

const getRoutePlanTaskRecord = getData("routePlanTask");
export const getRoutePlanTaskLookup: Selector<
  StateWithResources,
  (url: RoutePlanTaskUrl) => RoutePlanTask | undefined
> = recordToLookupSelector(getRoutePlanTaskRecord);
export const getRoutePlanTaskArray: Selector<StateWithResources, readonly RoutePlanTask[]> =
  recordToArraySelector(getRoutePlanTaskRecord);

const getRoutePlanTaskActivityOptionRecord = getData("routePlanTaskActivityOption");
export const getRoutePlanTaskActivityOptionLookup: Selector<
  StateWithResources,
  (url: RoutePlanTaskActivityOptionUrl) => RoutePlanTaskActivityOption | undefined
> = recordToLookupSelector(getRoutePlanTaskActivityOptionRecord);
export const getRoutePlanTaskActivityOptionArray: Selector<
  StateWithResources,
  readonly RoutePlanTaskActivityOption[]
> = recordToArraySelector(getRoutePlanTaskActivityOptionRecord);

const getRoutePlanTaskResultRecord = getData("routePlanTaskResult");
export const getRoutePlanTaskResultLookup: Selector<
  StateWithResources,
  (url: RoutePlanTaskResultUrl) => RoutePlanTaskResult | undefined
> = recordToLookupSelector(getRoutePlanTaskResultRecord);
export const getRoutePlanTaskResultArray: Selector<
  StateWithResources,
  readonly RoutePlanTaskResult[]
> = recordToArraySelector(getRoutePlanTaskResultRecord);

const getRouteTaskRecord = getData("routeTask");
export const getRouteTaskLookup: Selector<
  StateWithResources,
  (url: RouteTaskUrl) => RouteTask | undefined
> = recordToLookupSelector(getRouteTaskRecord);
export const getRouteTaskArray: Selector<StateWithResources, readonly RouteTask[]> =
  recordToArraySelector(getRouteTaskRecord);

const getRouteTaskActivityOptionRecord = getData("routeTaskActivityOption");
export const getRouteTaskActivityOptionLookup: Selector<
  StateWithResources,
  (url: RouteTaskActivityOptionUrl) => RouteTaskActivityOption | undefined
> = recordToLookupSelector(getRouteTaskActivityOptionRecord);
export const getRouteTaskActivityOptionArray: Selector<
  StateWithResources,
  readonly RouteTaskActivityOption[]
> = recordToArraySelector(getRouteTaskActivityOptionRecord);

const getRouteTaskResultRecord = getData("routeTaskResult");
export const getRouteTaskResultLookup: Selector<
  StateWithResources,
  (url: RouteTaskResultUrl) => RouteTaskResult | undefined
> = recordToLookupSelector(getRouteTaskResultRecord);
export const getRouteTaskResultArray: Selector<StateWithResources, readonly RouteTaskResult[]> =
  recordToArraySelector(getRouteTaskResultRecord);

const getSettingEntryRecord = getData("settingEntry");
export const getSettingEntryLookup: Selector<
  StateWithResources,
  (url: SettingEntryUrl) => SettingEntry | undefined
> = recordToLookupSelector(getSettingEntryRecord);
export const getSettingEntryArray: Selector<StateWithResources, readonly SettingEntry[]> =
  recordToArraySelector(getSettingEntryRecord);

const getSettingsRecord = getData("settings");
export const getSettingsLookup: Selector<
  StateWithResources,
  (url: SettingsUrl) => Settings | undefined
> = recordToLookupSelector(getSettingsRecord);
export const getSettingsArray: Selector<StateWithResources, readonly Settings[]> =
  recordToArraySelector(getSettingsRecord);

const getSprayRecord = getData("spray");
export const getSprayLookup: Selector<StateWithResources, (url: SprayUrl) => Spray | undefined> =
  recordToLookupSelector(getSprayRecord);
export const getSprayArray: Selector<StateWithResources, readonly Spray[]> =
  recordToArraySelector(getSprayRecord);

const getSprayLocationRecord = getData("sprayLocation");
export const getSprayLocationLookup: Selector<
  StateWithResources,
  (url: SprayLocationUrl) => SprayLocation | undefined
> = recordToLookupSelector(getSprayLocationRecord);
export const getSprayLocationArray: Selector<StateWithResources, readonly SprayLocation[]> =
  recordToArraySelector(getSprayLocationRecord);

const getSprayLogRecord = getData("sprayLog");
export const getSprayLogLookup: Selector<
  StateWithResources,
  (url: SprayLogUrl) => SprayLog | undefined
> = recordToLookupSelector(getSprayLogRecord);
export const getSprayLogArray: Selector<StateWithResources, readonly SprayLog[]> =
  recordToArraySelector(getSprayLogRecord);

const getSprayLogReportRecord = getData("sprayLogReport");
export const getSprayLogReportLookup: Selector<
  StateWithResources,
  (url: SprayLogReportUrl) => SprayLogReport | undefined
> = recordToLookupSelector(getSprayLogReportRecord);
export const getSprayLogReportArray: Selector<StateWithResources, readonly SprayLogReport[]> =
  recordToArraySelector(getSprayLogReportRecord);

const getSyncLogRecord = getData("syncLog");
export const getSyncLogLookup: Selector<
  StateWithResources,
  (url: SyncLogUrl) => SyncLog | undefined
> = recordToLookupSelector(getSyncLogRecord);
export const getSyncLogArray: Selector<StateWithResources, readonly SyncLog[]> =
  recordToArraySelector(getSyncLogRecord);

const getTaskRecord = getData("task");
export const getTaskLookup: Selector<StateWithResources, (url: TaskUrl) => Task | undefined> =
  recordToLookupSelector(getTaskRecord);
export const getTaskArray: Selector<StateWithResources, readonly Task[]> =
  recordToArraySelector(getTaskRecord);

const getTaskFileRecord = getData("taskFile");
export const getTaskFileLookup: Selector<
  StateWithResources,
  (url: TaskFileUrl) => TaskFile | undefined
> = recordToLookupSelector(getTaskFileRecord);
export const getTaskFileArray: Selector<StateWithResources, readonly TaskFile[]> =
  recordToArraySelector(getTaskFileRecord);

const getTaskPhotoRecord = getData("taskPhoto");
export const getTaskPhotoLookup: Selector<
  StateWithResources,
  (url: TaskPhotoUrl) => TaskPhoto | undefined
> = recordToLookupSelector(getTaskPhotoRecord);
export const getTaskPhotoArray: Selector<StateWithResources, readonly TaskPhoto[]> =
  recordToArraySelector(getTaskPhotoRecord);

const getTasksPrintoutRecord = getData("tasksPrintout");
export const getTasksPrintoutLookup: Selector<
  StateWithResources,
  (url: TasksPrintoutUrl) => TasksPrintout | undefined
> = recordToLookupSelector(getTasksPrintoutRecord);
export const getTasksPrintoutArray: Selector<StateWithResources, readonly TasksPrintout[]> =
  recordToArraySelector(getTasksPrintoutRecord);

const getTimerRecord = getData("timer");
export const getTimerLookup: Selector<StateWithResources, (url: TimerUrl) => Timer | undefined> =
  recordToLookupSelector(getTimerRecord);
export const getTimerArray: Selector<StateWithResources, readonly Timer[]> =
  recordToArraySelector(getTimerRecord);

const getTimerStartRecord = getData("timerStart");
export const getTimerStartLookup: Selector<
  StateWithResources,
  (url: TimerStartUrl) => TimerStart | undefined
> = recordToLookupSelector(getTimerStartRecord);
export const getTimerStartArray: Selector<StateWithResources, readonly TimerStart[]> =
  recordToArraySelector(getTimerStartRecord);

const getTransportLogRecord = getData("transportLog");
export const getTransportLogLookup: Selector<
  StateWithResources,
  (url: TransportLogUrl) => TransportLog | undefined
> = recordToLookupSelector(getTransportLogRecord);
export const getTransportLogArray: Selector<StateWithResources, readonly TransportLog[]> =
  recordToArraySelector(getTransportLogRecord);

const getTransportLogReportRecord = getData("transportLogReport");
export const getTransportLogReportLookup: Selector<
  StateWithResources,
  (url: TransportLogReportUrl) => TransportLogReport | undefined
> = recordToLookupSelector(getTransportLogReportRecord);
export const getTransportLogReportArray: Selector<
  StateWithResources,
  readonly TransportLogReport[]
> = recordToArraySelector(getTransportLogReportRecord);

const getUnitRecord = getData("unit");
export const getUnitLookup: Selector<StateWithResources, (url: UnitUrl) => Unit | undefined> =
  recordToLookupSelector(getUnitRecord);
export const getUnitArray: Selector<StateWithResources, readonly Unit[]> =
  recordToArraySelector(getUnitRecord);

const getUserRecord = getData("user");
export const getUserLookup: Selector<StateWithResources, (url: UserUrl) => User | undefined> =
  recordToLookupSelector(getUserRecord);
export const getUserArray: Selector<StateWithResources, readonly User[]> =
  recordToArraySelector(getUserRecord);

const getUserFileRecord = getData("userFile");
export const getUserFileLookup: Selector<
  StateWithResources,
  (url: UserFileUrl) => UserFile | undefined
> = recordToLookupSelector(getUserFileRecord);
export const getUserFileArray: Selector<StateWithResources, readonly UserFile[]> =
  recordToArraySelector(getUserFileRecord);

const getUserPhotoRecord = getData("userPhoto");
export const getUserPhotoLookup: Selector<
  StateWithResources,
  (url: UserPhotoUrl) => UserPhoto | undefined
> = recordToLookupSelector(getUserPhotoRecord);
export const getUserPhotoArray: Selector<StateWithResources, readonly UserPhoto[]> =
  recordToArraySelector(getUserPhotoRecord);

const getUserProfileRecord = getData("userProfile");
export const getUserProfileLookup: Selector<
  StateWithResources,
  (url: UserProfileUrl) => UserProfile | undefined
> = recordToLookupSelector(getUserProfileRecord);
export const getUserProfileArray: Selector<StateWithResources, readonly UserProfile[]> =
  recordToArraySelector(getUserProfileRecord);

const getWorkTypeRecord = getData("workType");
export const getWorkTypeLookup: Selector<
  StateWithResources,
  (url: WorkTypeUrl) => WorkType | undefined
> = recordToLookupSelector(getWorkTypeRecord);
export const getWorkTypeArray: Selector<StateWithResources, readonly WorkType[]> =
  recordToArraySelector(getWorkTypeRecord);

const getWorkshopChecklistRecord = getData("workshopChecklist");
export const getWorkshopChecklistLookup: Selector<
  StateWithResources,
  (url: WorkshopChecklistUrl) => WorkshopChecklist | undefined
> = recordToLookupSelector(getWorkshopChecklistRecord);
export const getWorkshopChecklistArray: Selector<StateWithResources, readonly WorkshopChecklist[]> =
  recordToArraySelector(getWorkshopChecklistRecord);

const getWorkshopChecklistItemRecord = getData("workshopChecklistItem");
export const getWorkshopChecklistItemLookup: Selector<
  StateWithResources,
  (url: WorkshopChecklistItemUrl) => WorkshopChecklistItem | undefined
> = recordToLookupSelector(getWorkshopChecklistItemRecord);
export const getWorkshopChecklistItemArray: Selector<
  StateWithResources,
  readonly WorkshopChecklistItem[]
> = recordToArraySelector(getWorkshopChecklistItemRecord);

const getYieldDeliveryRecord = getData("yieldDelivery");
export const getYieldDeliveryLookup: Selector<
  StateWithResources,
  (url: YieldDeliveryUrl) => YieldDelivery | undefined
> = recordToLookupSelector(getYieldDeliveryRecord);
export const getYieldDeliveryArray: Selector<StateWithResources, readonly YieldDelivery[]> =
  recordToArraySelector(getYieldDeliveryRecord);

const getYieldDeliveryLocationRecord = getData("yieldDeliveryLocation");
export const getYieldDeliveryLocationLookup: Selector<
  StateWithResources,
  (url: YieldDeliveryLocationUrl) => YieldDeliveryLocation | undefined
> = recordToLookupSelector(getYieldDeliveryLocationRecord);
export const getYieldDeliveryLocationArray: Selector<
  StateWithResources,
  readonly YieldDeliveryLocation[]
> = recordToArraySelector(getYieldDeliveryLocationRecord);

const getYieldLogRecord = getData("yieldLog");
export const getYieldLogLookup: Selector<
  StateWithResources,
  (url: YieldLogUrl) => YieldLog | undefined
> = recordToLookupSelector(getYieldLogRecord);
export const getYieldLogArray: Selector<StateWithResources, readonly YieldLog[]> =
  recordToArraySelector(getYieldLogRecord);

const getYieldLogReportRecord = getData("yieldLogReport");
export const getYieldLogReportLookup: Selector<
  StateWithResources,
  (url: YieldLogReportUrl) => YieldLogReport | undefined
> = recordToLookupSelector(getYieldLogReportRecord);
export const getYieldLogReportArray: Selector<StateWithResources, readonly YieldLogReport[]> =
  recordToArraySelector(getYieldLogReportRecord);

const getYieldPickupRecord = getData("yieldPickup");
export const getYieldPickupLookup: Selector<
  StateWithResources,
  (url: YieldPickupUrl) => YieldPickup | undefined
> = recordToLookupSelector(getYieldPickupRecord);
export const getYieldPickupArray: Selector<StateWithResources, readonly YieldPickup[]> =
  recordToArraySelector(getYieldPickupRecord);

const getYieldPickupLocationRecord = getData("yieldPickupLocation");
export const getYieldPickupLocationLookup: Selector<
  StateWithResources,
  (url: YieldPickupLocationUrl) => YieldPickupLocation | undefined
> = recordToLookupSelector(getYieldPickupLocationRecord);
export const getYieldPickupLocationArray: Selector<
  StateWithResources,
  readonly YieldPickupLocation[]
> = recordToArraySelector(getYieldPickupLocationRecord);
