import {Config} from "@co-common-libs/config";
import {Customer, CustomerUrl, Location, LocationUrl} from "@co-common-libs/resources";
import {formatAddress} from "@co-common-libs/utils";
import {
  DecimalField,
  ErrorColorButton,
  ResponsiveDialog,
  TrimTextField,
} from "@co-frontend-libs/components";
import {
  AppState,
  getCustomerLookup,
  getCustomerSettings,
  getLocationLookup,
} from "@co-frontend-libs/redux";
import {Button, DialogContent} from "@material-ui/core";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import React from "react";
// Allowed for existing code...
// eslint-disable-next-line deprecate/import
import {Cell, Grid} from "react-flexr";
import {FormattedMessage, IntlContext, defineMessages} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {LocationSelectCreateDialog} from "./location-dialog";

const messages = defineMessages({
  address: {
    defaultMessage: "Arbejdssted",
    id: "spray-location-dialog.label.task-address",
  },
  area: {
    defaultMessage: "Areal (ha)",
    id: "spray-location-dialog.label.area",
  },
  cancel: {
    defaultMessage: "Fortryd",
    id: "dialog.label.cancel",
  },
  chooseAddFieldLocation: {
    defaultMessage: "Vælg eller tilføj mark",
    id: "customer-location-dialog.title.choose-spray-location",
  },
  crop: {
    defaultMessage: "Afgrøde",
    id: "spray-location-dialog.label.crop",
  },
  delete: {
    defaultMessage: "Slet",
    id: "spray-location-dialog.label.delete",
  },
  editSprayLocation: {
    defaultMessage: "Ret mark",
    id: "spray-location-dialog.title.edit-spray-location",
  },
  newSprayLocation: {
    defaultMessage: "Ny mark",
    id: "spray-location-dialog.title.new-spray-location",
  },
  note: {
    defaultMessage: "Note",
    id: "spray-location-dialog.label.note",
  },
  ok: {
    defaultMessage: "OK",
    id: "dialog.label.ok",
  },
  selectCustomer: {
    defaultMessage: "Vælg",
    id: "spray-location-dialog.label.select-customer",
  },
  selectSprayLocation: {
    defaultMessage: "Vælg mark",
    id: "spray-location-dialog.title.select-spray-location",
  },
});

interface SprayLocationDialogStateProps {
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  customerSettings: Config;
  locationLookup: (url: LocationUrl) => Location | undefined;
}

interface SprayLocationDialogOwnProps {
  address?: string;
  area?: number | undefined;
  canDelete?: boolean;
  crop?: string;
  customer?: Customer | undefined;
  isNew: boolean;
  location?: Location | undefined;
  note?: string;
  onCancel: () => void;
  onOk: (data: {
    address: string;
    area: number | null;
    crop: string;
    customer: Customer | null;
    location: Location | null;
    note: string;
  }) => void;
  onRequestCustomerDialog: (callback: (customer: Customer) => void) => void;
  onRequestDelete?: () => void;
  open: boolean;
}

interface SprayLocationDialogState {
  address: string | undefined;
  area: number | null | undefined;
  crop: string | undefined;
  customer: Customer | null | undefined;
  location: Location | null | undefined;
  locationDialogOpen: boolean;
  note: string | undefined;
}

type SprayLocationDialogProps = SprayLocationDialogOwnProps & SprayLocationDialogStateProps;

class SprayLocationDialog extends PureComponent<
  SprayLocationDialogProps,
  SprayLocationDialogState
> {
  state: SprayLocationDialogState = {
    address: this.props.address,
    area: this.props.area,
    crop: this.props.crop,
    customer: this.props.customer,
    location: this.props.location,
    locationDialogOpen: false,
    note: this.props.note,
  };
  UNSAFE_componentWillReceiveProps(nextProps: SprayLocationDialogProps): void {
    const members = ["address", "customer", "note", "crop", "area", "location"] as const;
    if (nextProps.open && !this.props.open) {
      const update: Partial<SprayLocationDialogState> = {};
      members.forEach((member) => {
        update[member] = nextProps[member] as any;
      });
      this.setState(update as any);
    } else {
      let changed = false;
      const update: Partial<SprayLocationDialogState> = {};
      members.forEach((member) => {
        if (nextProps[member] !== this.props[member]) {
          update[member] = nextProps[member] as any;
          changed = true;
        }
      });
      if (changed) {
        this.setState(update as any);
      }
    }
  }

  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  @bind
  handleCustomerSelectButton(): void {
    this.props.onRequestCustomerDialog(this.handleCustomerSelected);
  }
  @bind
  handleCustomerSelected(customer: Customer): void {
    this.setState({customer});
  }

  @bind
  handleOk(): void {
    const {address, area, crop, customer, location, note} = this.state;
    this.props.onOk({
      address: address || "",
      area: area != null ? area : null,
      crop: crop || "",
      customer: customer || null,
      location: location || null,
      note: note || "",
    });
  }
  @bind
  handleCancel(): void {
    this.props.onCancel();
  }
  @bind
  handleNoteChange(value: string): void {
    this.setState({note: value});
  }
  @bind
  handleCropChange(value: string): void {
    this.setState({crop: value});
  }
  @bind
  handleAreaChange(newValue: number | null): void {
    this.setState({area: newValue});
  }
  @bind
  handleLocationSelectButton(): void {
    this.setState({locationDialogOpen: true});
  }
  @bind
  handleLocationDialogCancel(): void {
    this.setState({locationDialogOpen: false});
  }
  @bind
  handleLocationDialogOk(url: LocationUrl): void {
    const location = this.props.locationLookup(url);
    const customer = location?.customer ? this.props.customerLookup(location.customer) : null;
    this.setState({
      customer: customer || null,
      location,
      locationDialogOpen: false,
    });
  }
  getTitle(): string {
    const {formatMessage} = this.context;
    const {isNew} = this.props;
    return isNew
      ? formatMessage(messages.newSprayLocation)
      : formatMessage(messages.editSprayLocation);
  }

  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {canDelete, isNew, open} = this.props;
    let customerBlock;
    if (this.state.customer) {
      const {customer} = this.state;
      customerBlock = (
        <div>
          <FormattedMessage
            defaultMessage="Navn: {name}"
            id="spray-location-dialog.label.customer-name"
            tagName="div"
            values={{name: customer.name}}
          />
          <FormattedMessage
            defaultMessage="Adresse: {address}"
            id="spray-location-dialog.label.customer-address"
            tagName="div"
            values={{address: formatAddress(customer)}}
          />
        </div>
      );
    }
    let deleteButton;
    if (!isNew && this.props.onRequestDelete) {
      deleteButton = (
        <div>
          <ErrorColorButton
            disabled={!canDelete}
            variant="contained"
            onClick={this.props.onRequestDelete}
          >
            {formatMessage(messages.delete)}
          </ErrorColorButton>
        </div>
      );
    }
    let locationAddress;
    if (this.state.location) {
      locationAddress = this.state.location.address || this.state.location.name;
    }
    const dialogContent = (
      <div>
        <Grid>
          <Cell palm="12/12">
            <div>
              <FormattedMessage
                defaultMessage="Kunde"
                id="spray-location-dialog.label.customer"
                tagName="h4"
              />
              <Button
                color="secondary"
                variant="contained"
                onClick={this.handleCustomerSelectButton}
              >
                {formatMessage(messages.selectCustomer)}
              </Button>
              {customerBlock}
            </div>
            <div>
              <FormattedMessage
                defaultMessage="Arbejdssted"
                id="order-instance.label.workplace"
                tagName="h4"
              />
              <div>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={this.handleLocationSelectButton}
                >
                  {formatMessage(messages.selectSprayLocation)}
                </Button>
                <div>{locationAddress}</div>
              </div>
            </div>
            {deleteButton}
          </Cell>
          <Cell palm="12/12">
            <DecimalField
              fullWidth
              decimalPlaces={2}
              id="area"
              label={formatMessage(messages.area)}
              margin="dense"
              value={this.state.area != null ? this.state.area : null}
              onChange={this.handleAreaChange}
            />
            <TrimTextField
              fullWidth
              label={formatMessage(messages.crop)}
              margin="dense"
              value={this.state.crop}
              variant="outlined"
              onChange={this.handleCropChange}
            />
            <TrimTextField
              fullWidth
              multiline
              label={formatMessage(messages.note)}
              margin="dense"
              maxRows={30}
              minRows={2}
              value={this.state.note}
              variant="outlined"
              onChange={this.handleNoteChange}
            />
          </Cell>
        </Grid>
      </div>
    );
    return (
      <>
        <ResponsiveDialog
          open={open}
          title={this.getTitle()}
          onCancel={this.handleCancel}
          onOk={this.handleOk}
        >
          <DialogContent>{dialogContent}</DialogContent>
        </ResponsiveDialog>
        <LocationSelectCreateDialog
          customerURL={this.state.customer?.url || null}
          includeWorkplaceOnlyLocations={false}
          logOnlyLocation={this.props.customerSettings.setLogOnlyLocationOnCreate}
          open={this.state.locationDialogOpen}
          titleVariant="WORKPLACE"
          onCancel={this.handleLocationDialogCancel}
          onOk={this.handleLocationDialogOk}
        />
      </>
    );
  }
}

const ConnectedSprayLocationDialog: React.ComponentType<SprayLocationDialogOwnProps> = connect<
  SprayLocationDialogStateProps,
  object,
  SprayLocationDialogOwnProps,
  AppState
>(
  createStructuredSelector<AppState, SprayLocationDialogStateProps>({
    customerLookup: getCustomerLookup,
    customerSettings: getCustomerSettings,
    locationLookup: getLocationLookup,
  }),
  {},
)(SprayLocationDialog);

export {ConnectedSprayLocationDialog as SprayLocationDialog};
