import {Checkbox, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import React, {useCallback} from "react";
import {CustomerRequiredFieldChoice} from "./types";

interface CustomerRequiredFieldListItemProps {
  checked: boolean;
  customerRequiredField: CustomerRequiredFieldChoice;
  onClick: (customerRequiredField: CustomerRequiredFieldChoice) => void;
}

export function CustomerRequiredFieldListItem(
  props: CustomerRequiredFieldListItemProps,
): JSX.Element {
  const {checked, customerRequiredField, onClick} = props;

  const handleClick = useCallback(() => {
    onClick(customerRequiredField);
  }, [customerRequiredField, onClick]);
  return (
    <ListItem key={customerRequiredField} button dense onClick={handleClick}>
      <ListItemIcon>
        <Checkbox checked={checked} edge="start" />
      </ListItemIcon>
      <ListItemText primary={customerRequiredField} />
    </ListItem>
  );
}
