import {TaskUrl} from "@co-common-libs/resources";
import {notUndefined} from "@co-common-libs/utils";
import {Card, CardContent, CardHeader, useTheme} from "@material-ui/core";
import {SPACING} from "frontend-global-config";
import React, {useMemo} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {EconomicDownloadButton} from "../economic-invoicing/economic-download-button";
import {economicIssuesEqual} from "./economic-issues-equal";
import {EconomicIssuesList} from "./economic-issues-list";
import {EconomicIssue, IssueContext, MultiInstanceIssue, SingleInstanceIssue} from "./types";
import {combineMachineIssues} from "./utils/combine-machine-issues";
import {combineUserProfileIssues} from "./utils/combine-user-profile-issues";
import {uniqueCustomerIssues} from "./utils/unique-customer-issues";

interface EconomicTaskIssuesCardProps {
  context: IssueContext;
  downloading: boolean;
  economicTaskIssues: ReadonlyMap<TaskUrl, readonly EconomicIssue[]>;
  onDownloadFromEconomic: () => void;
}

export const EconomicTaskIssuesCard = React.memo(function EconomicTaskIssuesCard(
  props: EconomicTaskIssuesCardProps,
): JSX.Element | null {
  const {context, downloading, economicTaskIssues, onDownloadFromEconomic} = props;

  const intl = useIntl();
  const theme = useTheme();

  const {listCombinedIssues, listTaskIssues} = useMemo((): {
    listCombinedIssues: readonly MultiInstanceIssue[];
    listTaskIssues: Map<TaskUrl, readonly EconomicIssue[]>;
  } => {
    const allTaskIssues = Array.from(economicTaskIssues.values()).flat();

    // combining will group all matching resource instances into a single resource
    const combinedIssues: readonly MultiInstanceIssue[] = [
      combineMachineIssues({issues: allTaskIssues, type: "missingRemoteUrl"}),
      combineMachineIssues({
        issues: allTaskIssues,
        type: "missingRemoteIdentifier",
      }),
      combineUserProfileIssues({
        issues: allTaskIssues,
        type: "barred",
      }),
      combineUserProfileIssues({
        issues: allTaskIssues,
        type: "missingRemoteIdentifier",
      }),
    ].filter(notUndefined);

    // issues that should only be displayed once regardless of repetition across taks
    const uniqueIssues: readonly SingleInstanceIssue[] = uniqueCustomerIssues({
      issues: allTaskIssues,
    });

    const includedUniqueIssues = new Set<SingleInstanceIssue>();
    const taskIssuesMap = new Map<TaskUrl, readonly EconomicIssue[]>();
    for (const [taskUrl, taskIssues] of economicTaskIssues) {
      const taskIssuesMatchingUniqueIssues = taskIssues.filter((taskIssue) =>
        uniqueIssues.find((uniqueIssue) => economicIssuesEqual(uniqueIssue, taskIssue)),
      );

      // include unique issue if not already previously added
      const includeTaskIssuesMatchingUnique = taskIssuesMatchingUniqueIssues.filter(
        (taskIssueMatchingUnique) =>
          !Array.from(includedUniqueIssues).some((includedUniqueIssue) =>
            economicIssuesEqual(taskIssueMatchingUnique, includedUniqueIssue),
          ),
      );

      includeTaskIssuesMatchingUnique.forEach((newlyIncludedTaskIssueMatchingUnique) => {
        const includedUniqueIssue = uniqueIssues.find((uniqueIssue) =>
          economicIssuesEqual(uniqueIssue, newlyIncludedTaskIssueMatchingUnique),
        );
        if (includedUniqueIssue) {
          includedUniqueIssues.add(includedUniqueIssue);
        }
      });

      // exclude combined issues as they are listed separately without task association
      // exclude unique issues as they are added via includeTaskIssuesMatchingUnique
      const taskIssuesExcludingCombinedAndUnique = taskIssues.filter(
        (taskIssue) =>
          !combinedIssues.some((combinedIssue) => economicIssuesEqual(taskIssue, combinedIssue)) &&
          !uniqueIssues.some((uniqueIssue) => economicIssuesEqual(taskIssue, uniqueIssue)),
      );

      const displayTaskIssues = [
        taskIssuesExcludingCombinedAndUnique,
        ...includeTaskIssuesMatchingUnique,
      ].flat();

      if (displayTaskIssues.length) {
        taskIssuesMap.set(taskUrl, displayTaskIssues);
      }
    }
    return {
      listCombinedIssues: combinedIssues,
      listTaskIssues: taskIssuesMap,
    };
  }, [economicTaskIssues]);

  return (
    <Card style={{marginTop: 10}}>
      <CardHeader
        subheader={intl.formatMessage(
          {
            defaultMessage:
              "{count, plural, one {En opgave} other {# opgaver}} kan ikke overføres grundet fejl",
          },
          {count: economicTaskIssues.size},
        )}
        title={intl.formatMessage({defaultMessage: "Overførsel blokeret"})}
      />
      <CardContent>
        <EconomicIssuesList context={context} issues={listCombinedIssues} />
        {Array.from(listTaskIssues.entries()).map(([taskUrl, taskIssues]) => (
          <EconomicIssuesList
            key={taskUrl}
            context={context}
            issues={taskIssues}
            taskUrl={taskUrl}
          />
        ))}
        <br />
        <FormattedMessage defaultMessage="Ved rettelser i e-conomic skal du huske at hente ændringerne til CustomOffice." />
        <br />
        <EconomicDownloadButton
          downloading={downloading}
          style={{marginTop: theme.spacing(SPACING.SMALL)}}
          onClick={onDownloadFromEconomic}
        >
          <FormattedMessage defaultMessage="Hent rettelser fra e-conomic" />
        </EconomicDownloadButton>
      </CardContent>
    </Card>
  );
});
