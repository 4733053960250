import {ButtonBaseActions, IconButton, IconButtonProps} from "@material-ui/core";
import React, {useCallback, useRef} from "react";

type AutoFocusedIconButtonProps = Omit<IconButtonProps, "action">;

export function AutoFocusedIconButton(props: AutoFocusedIconButtonProps): JSX.Element {
  const focusButtonRef = useRef<ButtonBaseActions | null>(null);

  const focusActionCallback = useCallback((instance: ButtonBaseActions | null) => {
    if (instance !== focusButtonRef.current) {
      focusButtonRef.current = instance;
      if (instance) {
        // autoFocus "works"; it does set focus;
        // but this is necessary to also show the visual indication...
        instance.focusVisible();
      }
    }
  }, []);
  return <IconButton action={focusActionCallback} {...props} />;
}
