import {Command, Contact} from "@co-common-libs/resources";
import {getCustomerActiveContactArray} from "../../complex-selectors";
import {ProvisionaryCommand} from "../../resources/actions";
import {ResourcesAuthenticationMiddlewareAPI} from "../types";

export function makeFirstCustomerContactDefault(
  newContact: Contact | null,
  oldContact: Contact | undefined,
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  _command: ProvisionaryCommand,
): {after?: Command[]; before?: Command[]} | null {
  if (!newContact || oldContact) {
    return null;
  }
  const state = middlewareApi.getState();
  const customerContacts = getCustomerActiveContactArray(newContact.customer)(state);
  if (customerContacts.length === 0 && !newContact.defaultContact) {
    return {
      after: [
        {action: "UPDATE", patch: [{member: "defaultContact", value: true}], url: newContact.url},
      ],
    };
  } else {
    return null;
  }
}
