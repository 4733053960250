import {CsvImportSpecification} from "@co-common-libs/csv-import-specifications";
import {
  getCustomerArray,
  getCustomerSettings,
  getLocationTypeArray,
  getUnitArray,
} from "@co-frontend-libs/redux";
import {Table, TableBody, TableContainer, TableHead} from "@material-ui/core";
import React, {useCallback, useEffect, useMemo} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {ValidationData} from "../parse-validate";
import {ImportColumnConfiguration, ImportColumnConfigurationWithoutUpdateExisting} from "../types";
import {ColumnNameRow} from "./column-name-row";
import {getColumnConfiguration} from "./get-column-configuration";
import {ImportDropDownRow} from "./import-drop-down-row";
import {ValidationRow} from "./import-validation-row";
import {StaticValueInputRow} from "./static-value-input-row";
import {UpdateExistingRow} from "./update-existing-row";
import {validateImportColumns} from "./validate-import-columns";

interface ImportConfigurationTableProps {
  createNotExisting: boolean;
  headers: readonly string[];
  importColumnConfigurations: ReadonlyMap<string, ImportColumnConfiguration>;
  rows: readonly (readonly string[])[];
  selectedImportSpecification: CsvImportSpecification;
  setImportColumnConfigurations: (value: ReadonlyMap<string, ImportColumnConfiguration>) => void;
  setValidatedStatus: (validated: boolean) => void;
}

export function ImportConfigurationTable(props: ImportConfigurationTableProps): JSX.Element {
  const {
    createNotExisting,
    headers,
    importColumnConfigurations,
    rows,
    selectedImportSpecification,
    setImportColumnConfigurations,
    setValidatedStatus,
  } = props;

  const intl = useIntl();

  const customerSettings = useSelector(getCustomerSettings);
  const locationTypeArray = useSelector(getLocationTypeArray);
  const customerArray = useSelector(getCustomerArray);
  const unitArray = useSelector(getUnitArray);

  const validationData = useMemo(
    (): ValidationData => ({
      config: customerSettings,
      customers: customerArray,
      locationTypes: locationTypeArray,
      units: unitArray,
    }),
    [customerArray, customerSettings, locationTypeArray, unitArray],
  );

  const handleImportColumnValueTypeChange = useCallback(
    (columnName: string, configurationChange: ImportColumnConfigurationWithoutUpdateExisting) => {
      const oldConfiguration = getColumnConfiguration(importColumnConfigurations, columnName);
      const newConfiguration: ImportColumnConfiguration = {
        ...configurationChange,
        updateExisting: oldConfiguration.updateExisting && configurationChange.valueType !== "none",
      };
      const alteredimportColumnConfigurations = new Map(importColumnConfigurations);
      alteredimportColumnConfigurations.set(columnName, newConfiguration);
      setImportColumnConfigurations(alteredimportColumnConfigurations);
    },
    [importColumnConfigurations, setImportColumnConfigurations],
  );

  const handleImportColumnUpdateExistingChange = useCallback(
    (columnName: string, configurationChange: {updateExisting: boolean}) => {
      const oldConfiguration = getColumnConfiguration(importColumnConfigurations, columnName);
      const newConfiguration: ImportColumnConfiguration = {
        ...oldConfiguration,
        ...configurationChange,
      };
      const alteredimportColumnConfigurations = new Map(importColumnConfigurations);
      alteredimportColumnConfigurations.set(columnName, newConfiguration);
      setImportColumnConfigurations(alteredimportColumnConfigurations);
    },
    [importColumnConfigurations, setImportColumnConfigurations],
  );

  useEffect(() => {
    const importColumnValidationStatuses = validateImportColumns(
      customerSettings,
      importColumnConfigurations,
      rows,
      selectedImportSpecification,
      createNotExisting,
      intl,
      validationData,
    );

    const validated = Array.from(importColumnValidationStatuses.values()).every(
      (columnErrors) => columnErrors.length === 0,
    );

    setValidatedStatus(validated || false);
  }, [
    createNotExisting,
    customerSettings,
    importColumnConfigurations,
    intl,
    rows,
    selectedImportSpecification,
    setValidatedStatus,
    validationData,
  ]);

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <ColumnNameRow selectedImportSpecification={selectedImportSpecification} />
        </TableHead>
        <TableBody>
          <ImportDropDownRow
            headers={headers}
            importColumnConfigurations={importColumnConfigurations}
            selectedImportSpecification={selectedImportSpecification}
            onImportColumnValueTypeChange={handleImportColumnValueTypeChange}
          />
          <StaticValueInputRow
            importColumnConfigurations={importColumnConfigurations}
            selectedImportSpecification={selectedImportSpecification}
            onImportColumnConfigurationChange={handleImportColumnValueTypeChange}
          />
          <UpdateExistingRow
            handleImportColumnConfigurationChange={handleImportColumnUpdateExistingChange}
            importColumnConfigurations={importColumnConfigurations}
            selectedImportSpecification={selectedImportSpecification}
          />
          <ValidationRow
            createNotExisting={createNotExisting}
            importColumnConfigurations={importColumnConfigurations}
            rows={rows}
            selectedImportSpecification={selectedImportSpecification}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
}
