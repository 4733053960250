import {PunchInOut} from "@co-common-libs/resources";
import {normalisedTimestamp, simpleCompare} from "@co-common-libs/utils";

export type PunchWorkPeriod = {punchIn: string; punchOut: string | null};

export function buildEmployeeTimeline(punchInOutArray: readonly PunchInOut[]): PunchWorkPeriod[] {
  const simplifiedNormalisedPunchInOutArray = punchInOutArray.map((entry) => ({
    out: entry.action === "PUNCH_OUT",
    timestamp: normalisedTimestamp(entry.punchTimestamp),
  }));
  simplifiedNormalisedPunchInOutArray.sort(
    (a, b) => simpleCompare(a.timestamp, b.timestamp) || simpleCompare(a.out, b.out),
  );
  const result: PunchWorkPeriod[] = [];
  let punchIn: string | null = null;
  for (let i = 0; i < simplifiedNormalisedPunchInOutArray.length; i += 1) {
    const {out, timestamp} = simplifiedNormalisedPunchInOutArray[i];
    if (punchIn != null && out) {
      // punch out; previous (when we disregard repetitions) was punch in
      result.push({punchIn, punchOut: timestamp});
      punchIn = null;
    } else if (punchIn == null && !out) {
      // punch in; previous (when we disregard repetitions) was punch out
      punchIn = timestamp;
    }
    // `punchIn != null && !out` means repeated punch in,
    // `punchIn == null && out` means repeated punch out;
    // we ignore those
  }
  if (punchIn) {
    // final punch in did not have matching punch out
    result.push({punchIn, punchOut: null});
  }
  return result;
}
