import {
  DaysAbsence,
  DaysAbsenceUrl,
  HoursAbsence,
  HoursAbsenceUrl,
} from "@co-common-libs/resources";
import {getCurrentRoleIsManager} from "@co-frontend-libs/redux";
import {IconButton} from "@material-ui/core";
import PencilIcon from "mdi-react/PencilIcon";
import React, {useCallback} from "react";
import {useSelector} from "react-redux";

interface AbsenceBlockEditButtonProps {
  absence: DaysAbsence | HoursAbsence;
  onEdit: (url: DaysAbsenceUrl | HoursAbsenceUrl) => void;
}

export const AbsenceBlockEditButton = React.memo(function AbsenceBlockEditButton(
  props: AbsenceBlockEditButtonProps,
) {
  const {absence, onEdit} = props;

  const isManager = useSelector(getCurrentRoleIsManager);

  const handleClick = useCallback(() => {
    onEdit(absence.url);
  }, [absence.url, onEdit]);

  if (!isManager) {
    return null;
  }

  return (
    <IconButton
      disableFocusRipple
      disableRipple
      size="small"
      style={{right: -2, top: -6}}
      onClick={handleClick}
    >
      <PencilIcon size={14} />
    </IconButton>
  );
});
