import {Config} from "@co-common-libs/config";
import {
  DaysAbsence,
  HoursAbsence,
  Machine,
  MachineUrl,
  Order,
  OrderUrl,
  PatchUnion,
  PriceGroup,
  PriceGroupUrl,
  ReportingSpecification,
  ReportingSpecificationUrl,
  ResourceTypeUnion,
  Role,
  RoutePlan,
  RoutePlanTask,
  RoutePlanTaskActivityOption,
  RoutePlanTaskActivityOptionUrl,
  RoutePlanTaskResult,
  RoutePlanTaskUrl,
  RoutePlanUrl,
  RouteTask,
  RouteTaskActivityOption,
  RouteTaskActivityOptionUrl,
  RouteTaskResult,
  RouteTaskUrl,
  Task,
  Timer,
  TimerStart,
  TimerUrl,
  User,
  UserProfile,
  UserUrl,
  WorkType,
  WorkTypeUrl,
  emptyRouteTask,
  emptyRouteTaskResult,
  urlToId,
} from "@co-common-libs/resources";
import {getNormalisedDeviceTimestamp} from "@co-common-libs/resources-utils";
import {formatDate} from "@co-common-libs/utils";
import {
  DateField,
  ErrorColorButton,
  MachineChip,
  TimeField,
  TrimTextField,
} from "@co-frontend-libs/components";
import {
  ConnectedMachineDialogWithoutSmallMachines,
  ConnectedMachineOperatorDialog,
  ConnectedRoutePlanDialog,
} from "@co-frontend-libs/connected-components";
import {
  AppState,
  PathTemplate,
  actions,
  getCurrentRole,
  getCurrentUserURL,
  getCustomerSettings,
  getDaysAbsenceArray,
  getHoursAbsenceArray,
  getMachineLookup,
  getOrderLookup,
  getPriceGroupLookup,
  getReportingSpecificationLookup,
  getRoutePlanLookup,
  getRoutePlanTaskActivityOptionArray,
  getRoutePlanTaskArray,
  getRoutePlanTaskResultArray,
  getRouteTaskActivityOptionArray,
  getRouteTaskArray,
  getRouteTaskResultArray,
  getTaskArray,
  getTaskLookup,
  getTimerArray,
  getTimerLookup,
  getTimerStartArray,
  getUserLookup,
  getUserUserProfileLookup,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {colorMap, matchingTextColor} from "@co-frontend-libs/utils";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  Chip,
  ChipProps,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import {
  DoLoadInstance,
  MachineRemovalBlockedDialog,
  PageLayout,
  RecordInC5Dialog,
  RemoveRecordedInC5Dialog,
  TaskCountMessage,
  getOtherTaskCount,
} from "app-components";
import {
  LoadInstanceRelated,
  MachineRemovalBlockedReason,
  PureComponent,
  checkUserAbsence,
  computeIntervalSums,
  computeIntervalsTruncated,
  machineAlreadySelected,
  machineRemovalBlocked,
  mergeIntervals,
  removeUnicodeCf,
  saveTimerStart,
} from "app-utils";
import {bind} from "bind-decorator";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import React from "react";
// Allowed for existing code...
// eslint-disable-next-line deprecate/import
import {Cell, Grid} from "react-flexr";
import {FormattedMessage, IntlContext, defineMessages} from "react-intl";
import {batch, connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {v4 as uuid} from "uuid";

const messages = defineMessages({
  completed: {
    defaultMessage: "Fuldført",
    id: "task-edit.label.completed",
  },
  date: {defaultMessage: "Dato", id: "task-edit.label.date"},
  deleteButton: {
    defaultMessage: "Slet",
    id: "task-edit.label.delete",
  },
  invoiceNote: {
    defaultMessage: "Faktura note",
    id: "task-edit.label.invoice-note",
  },
  notesFromAdministration: {
    defaultMessage: "Noter fra administrationen",
  },
  notesFromChauffeur: {
    defaultMessage: "Noter fra chauffør",
    id: "task-edit.label.notes-from-chauffeur",
  },
  notesFromEmployee: {
    defaultMessage: "Noter fra medarbejder",
    id: "task-edit.label.notes-from-employee",
  },
  notesFromMachineOperator: {
    defaultMessage: "Noter fra maskinfører",
  },
  recorded: {
    defaultMessage: "Bogført",
    id: "task-edit.label.recorded",
  },
  selectMachineButton: {
    defaultMessage: "Tilføj",
    id: "task-edit.label.select-machine",
  },
  selectMachineOperatorButton: {
    defaultMessage: "Vælg",
    id: "task-edit.label.select-machine-operator",
  },
  selectRoutePlanButton: {
    defaultMessage: "Vælg",
    id: "task-edit.label.select-route",
  },
  startButton: {
    defaultMessage: "Start",
    id: "task-edit.label.start",
  },
  taskInternalManagerNotes: {
    defaultMessage: "Interne noter",
  },
  time: {defaultMessage: "Klokkeslæt", id: "task-edit.label.time"},
  title: {defaultMessage: "Ruteopgave", id: "task-edit.title.task"},
  validated: {
    defaultMessage: "Godkendt",
    id: "task-edit.label.validated",
  },
});

interface TaskEditContentProps {
  create: (instance: ResourceTypeUnion) => void;
  currentRole: Role | null;
  currentUserURL: string | null;
  customerSettings: Config;
  daysAbsenceArray: readonly DaysAbsence[];
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  hasActivity: boolean;
  hoursAbsenceArray: readonly HoursAbsence[];
  isRouteChangeAllowed: boolean;
  machineLookup: (url: MachineUrl) => Machine | undefined;
  order: Order;
  orderLookup: (url: OrderUrl) => Order | undefined;
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
  registerTimerStartPosition: (timerStart: TimerStart) => void;
  remove: (url: string) => void;
  reportingSpecificationLookup: (
    url: ReportingSpecificationUrl,
  ) => ReportingSpecification | undefined;
  routePlan: RoutePlan;
  routePlanLookup: (url: RoutePlanUrl) => RoutePlan | undefined;
  routePlanTaskActivityOptionArray: readonly RoutePlanTaskActivityOption[];
  routePlanTaskArray: readonly RoutePlanTask[];
  routePlanTaskResultArray: readonly RoutePlanTaskResult[];
  routeTaskActivityOptionArray: readonly RouteTaskActivityOption[];
  routeTaskArray: readonly RouteTask[];
  routeTaskResultArray: readonly RouteTaskResult[];
  task: Task;
  taskArray: readonly Task[];
  taskRouteTaskArray: readonly RouteTask[];
  timerArray: readonly Timer[];
  timerLookup: (url: TimerUrl) => Timer | undefined;
  timerMinutesMap: ReadonlyMap<TimerUrl, number>;
  timerStartArray: readonly TimerStart[];
  update: (url: string, patch: PatchUnion) => void;
  userLookup: (url: UserUrl) => User | undefined;
  userUserProfileLookup: (url: UserUrl) => UserProfile | undefined;
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
}

interface TaskEditContentState {
  machineDialogOpen: boolean;
  machineOperatorDialogOpen: boolean;
  machineRemovalBlockedDialogOpen: boolean;
  machineRemovalBlockedReason: MachineRemovalBlockedReason | null;
  recordInC5DialogOpen: boolean;
  removeRecordedInC5DialogOpen: boolean;
  routePlanDialogOpen: boolean;
}

class TaskEditContent extends PureComponent<TaskEditContentProps, TaskEditContentState> {
  state: TaskEditContentState = {
    machineDialogOpen: false,
    machineOperatorDialogOpen: false,
    machineRemovalBlockedDialogOpen: false,
    machineRemovalBlockedReason: null,
    recordInC5DialogOpen: false,
    removeRecordedInC5DialogOpen: false,
    routePlanDialogOpen: false,
  };
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  @bind
  handleSelectRouteButton(): void {
    this.setState({routePlanDialogOpen: true});
  }
  @bind
  handleRoutePlanDialogOk(url: RoutePlanUrl): void {
    this.setState({routePlanDialogOpen: false});
    this.changeRoute(url);
  }
  @bind
  handleRoutePlanDialogCancel(): void {
    this.setState({routePlanDialogOpen: false});
  }
  @bind
  handleSelectMachineOperatorButton(): void {
    this.setState({machineOperatorDialogOpen: true});
  }
  @bind
  handleMachineOperatorDialogOk(url: UserUrl): void {
    this.setState({machineOperatorDialogOpen: false});
    const {task, update} = this.props;
    update(task.url, [{member: "machineOperator", value: url}]);
  }
  @bind
  handleMachineOperatorDialogCancel(): void {
    this.setState({machineOperatorDialogOpen: false});
  }
  @bind
  handleRemoveMachineOperator(): void {
    const {task, update} = this.props;
    update(task.url, [{member: "machineOperator", value: null}]);
  }
  @bind
  handleSelectMachineButton(): void {
    this.setState({machineDialogOpen: true});
  }
  @bind
  handleMachineDialogCancel(): void {
    this.setState({machineDialogOpen: false});
  }
  @bind
  handleMachineDialogOk(url: MachineUrl): void {
    this.setState({machineDialogOpen: false});
    const {task, update} = this.props;
    if (machineAlreadySelected(task, url)) {
      return;
    }
    const oldMachineUseSet = task.machineuseSet || [];
    const newEntry = {
      machine: url,
      priceGroup: null,
      transporter: false,
    };
    const newMachineUseSet = [...oldMachineUseSet, newEntry];
    update(task.url, [{member: "machineuseSet", value: newMachineUseSet}]);
  }
  @bind
  handleRemoveMachine(index: number): void {
    const {
      customerSettings,
      machineLookup,
      priceGroupLookup,
      reportingSpecificationLookup,
      task,
      timerArray,
      timerMinutesMap,
      workTypeLookup,
    } = this.props;
    const machineUse = task.machineuseSet[index];
    if (!machineUse) {
      return;
    }
    const machineUrl = machineUse.machine;
    const machineRemovalBlockedReason = machineRemovalBlocked(
      task,
      machineUrl,
      timerMinutesMap,
      customerSettings,
      {
        machineLookup,
        priceGroupLookup,
        reportingSpecificationLookup,
        timerArray,
        workTypeLookup,
      },
    );
    if (machineRemovalBlockedReason) {
      this.setState({
        machineRemovalBlockedDialogOpen: true,
        machineRemovalBlockedReason,
      });
    } else {
      const newMachineUseSet = [...task.machineuseSet];
      newMachineUseSet.splice(index, 1);
      this.props.update(task.url, [{member: "machineuseSet", value: newMachineUseSet}]);
    }
  }
  @bind
  handleMachineRemovalBlockedDialogClose(): void {
    this.setState({machineRemovalBlockedDialogOpen: false});
  }
  @bind
  handleStartButton(): void {
    const taskID = urlToId(this.props.task.url);
    window.setTimeout(() => {
      batch(() => {
        this.props.go("/task/:id", {id: taskID}, {}, "REPLACE");
      });
    });
  }
  @bind
  handleDeleteButton(): void {
    const {order, remove, task} = this.props;
    remove(task.url);
    remove(order.url);
    window.setTimeout(() => {
      this.props.go("/task");
    }, 0);
  }
  @bind
  changeRoute(routePlanURL: RoutePlanUrl): void {
    const {
      create,
      isRouteChangeAllowed,
      order,
      remove,
      routePlanTaskActivityOptionArray,
      routePlanTaskArray,
      routePlanTaskResultArray,
      routeTaskActivityOptionArray,
      routeTaskArray,
      routeTaskResultArray,
      task,
      update,
    } = this.props;
    if (order.routePlan === routePlanURL || !isRouteChangeAllowed) {
      return;
    }
    const taskURL = task.url;
    update(order.url, [{member: "routePlan", value: routePlanURL}]);
    const routeTaskURLSet = new Set<string>();
    routeTaskArray.forEach((routeTask) => {
      if (routeTask.route === taskURL) {
        routeTaskURLSet.add(routeTask.url);
      }
    });
    routeTaskActivityOptionArray.forEach((activityOption) => {
      if (routeTaskURLSet.has(activityOption.routeTask)) {
        remove(activityOption.url);
      }
    });
    routeTaskResultArray.forEach((result) => {
      if (routeTaskURLSet.has(result.routeTask)) {
        remove(result.url);
      }
    });
    routeTaskURLSet.forEach((url) => {
      remove(url);
    });
    const routePlanTaskToRouteTaskMap = new Map<RoutePlanTaskUrl, RouteTaskUrl>();
    routePlanTaskArray
      .filter((routePlanTask) => routePlanTask.routePlan === routePlanURL)
      .forEach((routePlanTask) => {
        const routePlanTaskURL = routePlanTask.url;
        const routeTaskID = uuid();
        const routeTaskURL = instanceURL("routeTask", routeTaskID);
        console.assert(typeof routeTaskURL === "string", routeTaskURL);
        console.assert(typeof routePlanTaskURL === "string", routePlanTaskURL);
        console.assert(typeof taskURL === "string", taskURL);
        const newRouteTask: RouteTask = {
          ...emptyRouteTask,
          customer: routePlanTask.customer,
          deadline: routePlanTask.deadline,
          description: routePlanTask.description,
          id: routeTaskID,
          notesFromManager: routePlanTask.notesFromManager,
          order: routePlanTask.order,
          project: routePlanTask.project,
          relatedLocation: routePlanTask.relatedLocation,
          route: taskURL,
          url: routeTaskURL,
        };
        create(newRouteTask);
        routePlanTaskToRouteTaskMap.set(routePlanTaskURL, routeTaskURL);
      });
    const routePlanTaskActivityOptionToRouteTaskActivityOptionMap = new Map<
      RoutePlanTaskActivityOptionUrl,
      RouteTaskActivityOptionUrl
    >();
    routePlanTaskActivityOptionArray.forEach((routePlanTaskActivityOption) => {
      const routeTaskURL = routePlanTaskToRouteTaskMap.get(
        routePlanTaskActivityOption.routePlanTask,
      );
      if (!routeTaskURL) {
        return;
      }
      const id = uuid();
      const url = instanceURL("routeTaskActivityOption", id);
      const newInstance = {
        activity: routePlanTaskActivityOption.activity,
        id,
        routeTask: routeTaskURL,
        url,
      };
      create(newInstance);
      routePlanTaskActivityOptionToRouteTaskActivityOptionMap.set(
        routePlanTaskActivityOption.url,
        url,
      );
    });
    routePlanTaskResultArray.forEach((routePlanTaskResult) => {
      const routeTaskURL = routePlanTaskToRouteTaskMap.get(routePlanTaskResult.routePlanTask);
      if (!routeTaskURL) {
        return;
      }
      const id = uuid();
      const url = instanceURL("routeTaskResult", id);
      const activityOptionURL = routePlanTaskActivityOptionToRouteTaskActivityOptionMap.get(
        routePlanTaskResult.activityOption,
      ) as RouteTaskActivityOptionUrl;
      const newInstance: RouteTaskResult = {
        ...emptyRouteTaskResult,
        activityOption: activityOptionURL,
        id,
        order: routePlanTaskResult.order,
        quoted: routePlanTaskResult.quoted,
        routeTask: routeTaskURL,
        specification: routePlanTaskResult.specification,
        url,
      };
      create(newInstance);
    });
  }
  @bind
  handleToggleCompleted(event: React.ChangeEvent<HTMLInputElement>): void {
    const {checked} = event.target;
    const {currentUserURL, customerSettings, registerTimerStartPosition, task} = this.props;
    if (checked) {
      const userURL = task.machineOperator;
      const taskURL = task.url;
      if (userURL) {
        saveTimerStart(
          this.props.create,
          null,
          taskURL,
          userURL,
          null,
          customerSettings.geolocation.registerPositionOnTimerClick &&
            currentUserURL === task.machineOperator
            ? registerTimerStartPosition
            : undefined,
        );
      }
    }
    if (checked) {
      this.props.update(task.url, [{member: "completed", value: true}]);
    } else {
      this.props.update(task.url, [
        {member: "cancelled", value: false},
        {member: "completed", value: false},
        {member: "completedAsInternal", value: false},
      ]);
    }
  }
  @bind
  handleToggleValidated(event: React.ChangeEvent<HTMLInputElement>): void {
    const {checked} = event.target;
    const {task} = this.props;
    if (!checked) {
      this.props.update(task.url, [
        {member: "archivable", value: false},
        {member: "reportApproved", value: false},
        {member: "validatedAndRecorded", value: false},
      ]);
    }
  }
  @bind
  handleToggleRecordedInC5(event: React.ChangeEvent<HTMLInputElement>): void {
    const {checked} = event.target;
    if (!checked) {
      this.setState({removeRecordedInC5DialogOpen: true});
    } else {
      this.setState({recordInC5DialogOpen: true});
    }
  }
  @bind
  handleRemoveRecordedInC5DialogOk(): void {
    this.setState({removeRecordedInC5DialogOpen: false});

    const {task} = this.props;
    this.props.update(task.url, [
      {member: "archivable", value: false},
      {member: "recordedInC5", value: null},
    ]);
  }
  @bind
  handleRemoveRecordedInC5DialogCancel(): void {
    this.setState({removeRecordedInC5DialogOpen: false});
  }

  @bind
  handleRecordInC5DialogOk(): void {
    this.setState({recordInC5DialogOpen: false});
    const {task} = this.props;
    this.props.update(task.url, [
      {member: "archivable", value: true},
      {member: "recordedInC5", value: new Date().toISOString()},
      {member: "validatedAndRecorded", value: true},
    ]);
  }
  @bind
  handleRecordInC5DialogCancel(): void {
    this.setState({recordInC5DialogOpen: false});
  }

  @bind
  handleDateChange(value: string | null): void {
    const {task, update} = this.props;
    update(task.url, [{member: "date", value}]);
  }

  @bind
  handleTimeChange(value: string | null): void {
    const {task, update} = this.props;
    update(task.url, [{member: "time", value}]);
  }

  @bind
  handleNotesFromManagerChange(value: string): void {
    const filteredValue = removeUnicodeCf(value);
    const {task, update} = this.props;
    update(task.url, [{member: "notesFromManager", value: filteredValue}]);
  }

  @bind
  handleInvoiceNoteChange(value: string): void {
    const filteredValue = removeUnicodeCf(value);
    const {task, update} = this.props;
    update(task.url, [{member: "invoiceNote", value: filteredValue}]);
  }
  @bind
  handleTaskInternalManagerNotesChange(value: string): void {
    const filteredValue = removeUnicodeCf(value);
    const {task, update} = this.props;
    update(task.url, [{member: "managerInternalNotes", value: filteredValue}]);
  }

  @bind
  handleNotesFromMachineOperatorChange(value: string): void {
    const filteredValue = removeUnicodeCf(value);
    const {task, update} = this.props;
    update(task.url, [{member: "notesFromMachineOperator", value: filteredValue}]);
  }

  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {
      currentUserURL,
      customerSettings,
      daysAbsenceArray,
      hasActivity,
      hoursAbsenceArray,
      machineLookup,
      orderLookup,
      priceGroupLookup,
      task,
      userLookup,
      userUserProfileLookup,
      workTypeLookup,
    } = this.props;
    const role = this.props.currentRole;
    const userIsOnlyMachineOperator = role && !role.manager;
    const userIsSeniorMachineOperator = role && role.seniorMachineOperator;
    const userIsManager = role && role.manager;
    const machineOperatorURL = task.machineOperator;
    const userIsOther = currentUserURL !== machineOperatorURL;
    const userIsOtherMachineOperator =
      userIsOther && userIsOnlyMachineOperator && !userIsSeniorMachineOperator;
    const canUnapproveTasks = role && role.canUnapproveTasks;
    const userIsConsultant = role && role.consultant;
    const validated = !!task.validatedAndRecorded || !!task.reportApproved;
    const completed = !!task.completed;
    const deletable =
      !validated && (!completed || !userIsOnlyMachineOperator) && !userIsOtherMachineOperator;
    const {isRouteChangeAllowed, routePlan} = this.props;
    let machineOperator = null;
    const allowMachineOperatorRemoval = deletable && !hasActivity;
    const disableMachineOperatorSelection =
      !customerSettings.allowCustomerTaskEmployeeChange || !allowMachineOperatorRemoval;
    if (machineOperatorURL && userLookup(machineOperatorURL)) {
      const profile = userUserProfileLookup(machineOperatorURL);

      const machineOperatorChipOptionalClickHandleProps: ChipProps = {};
      if (allowMachineOperatorRemoval) {
        machineOperatorChipOptionalClickHandleProps.onDelete = this.handleRemoveMachineOperator;
      }

      machineOperator = (
        <div>
          <Chip
            avatar={
              <Avatar
                style={{
                  backgroundColor: colorMap.MACHINE_OPERATOR_AVATAR_BACKGROUND,
                  color: matchingTextColor(colorMap.MACHINE_OPERATOR_AVATAR_BACKGROUND),
                }}
              >
                {profile ? profile.alias : ""}
              </Avatar>
            }
            label={profile && profile.name}
            {...machineOperatorChipOptionalClickHandleProps}
          />
        </div>
      );
    }
    let absent;
    const machineOperatorAbsent =
      task.machineOperator &&
      checkUserAbsence(
        task.machineOperator,
        task,
        orderLookup,
        daysAbsenceArray,
        hoursAbsenceArray,
        customerSettings.absenceWarningDisabledFor,
      );
    if (machineOperatorAbsent) {
      absent = (
        <div style={{color: "red"}}>
          {customerSettings.employeeLabelVariant === "MACHINEOPERATOR" ? (
            <FormattedMessage
              defaultMessage="Den valgte maskinfører har registreret fravær på den valgte dato"
              id="order-instance.header.machine-operator-absent"
              tagName="h4"
            />
          ) : customerSettings.employeeLabelVariant === "EMPLOYEE" ? (
            <FormattedMessage
              defaultMessage="Den valgte medarbejder har registreret fravær på den valgte dato"
              id="order-instance.header.employee-absent"
              tagName="h4"
            />
          ) : (
            <FormattedMessage
              defaultMessage="Den valgte chauffør har registreret fravær på den valgte dato"
              id="order-instance.header.chauffeur-absent"
              tagName="h4"
            />
          )}
        </div>
      );
    }

    let hasOtherTasks: JSX.Element | undefined;
    if (customerSettings.employeeSameDayTasksWarning) {
      const machineOperatorOtherTaskCount = getOtherTaskCount(task, this.props.taskArray);
      if (machineOperatorOtherTaskCount > 0) {
        hasOtherTasks = (
          <TaskCountMessage
            customerSettings={customerSettings}
            machineOperatorOtherTaskCount={machineOperatorOtherTaskCount}
          />
        );
      }
    }

    let machineChips = null;
    const entries = task.machineuseSet || [];
    const machineRemovalAllowed = !task.order || !!customerSettings.allowCustomerTaskMachineChange;
    const renderedEntries = entries.map((entry, index) => {
      const machineURL = entry.machine;
      let machineID = "";
      let machineName = "";
      const foundMachine = machineLookup(machineURL);
      if (foundMachine) {
        machineID = foundMachine.c5_machine;
        machineName = foundMachine.name;
      }
      const text = machineName + (machineID ? ` (${machineID})` : "");

      return (
        <MachineChip
          key={index}
          deletable={deletable && machineRemovalAllowed}
          index={index}
          text={text}
          onDelete={this.handleRemoveMachine}
        />
      );
    });
    machineChips = <div>{renderedEntries}</div>;
    const workTypeURL = task.workType;
    const workType = workTypeURL ? workTypeLookup(workTypeURL) : null;
    const priceGroup = task.priceGroup ? priceGroupLookup(task.priceGroup) : undefined;
    const dialogs = [
      <ConnectedRoutePlanDialog
        key="route-dialog"
        open={this.state.routePlanDialogOpen}
        onCancel={this.handleRoutePlanDialogCancel}
        onOk={this.handleRoutePlanDialogOk}
      />,
      <ConnectedMachineOperatorDialog
        key="machine-operator-dialog"
        open={this.state.machineOperatorDialogOpen}
        onCancel={this.handleMachineOperatorDialogCancel}
        onOk={this.handleMachineOperatorDialogOk}
      />,
      <ConnectedMachineDialogWithoutSmallMachines
        key="machine-dialog"
        open={this.state.machineDialogOpen}
        priceGroup={priceGroup}
        workType={workType || undefined}
        onCancel={this.handleMachineDialogCancel}
        onOk={this.handleMachineDialogOk}
      />,
      <MachineRemovalBlockedDialog
        key="machine-removal-blocked-dialog"
        blockedReason={this.state.machineRemovalBlockedReason}
        open={this.state.machineRemovalBlockedDialogOpen}
        onClose={this.handleMachineRemovalBlockedDialogClose}
      />,
    ];

    let timeBlock;
    if (customerSettings.taskShowTimeField) {
      timeBlock = (
        <Cell palm="12/12">
          <TimeField
            fullWidth
            disabled={
              validated ||
              (completed && !!userIsOnlyMachineOperator) ||
              !!userIsOtherMachineOperator
            }
            label={formatMessage(messages.time)}
            margin="dense"
            value={task.time || undefined}
            onChange={this.handleTimeChange}
          />
        </Cell>
      );
    }

    const routeString = routePlan ? routePlan.name : "";

    const allowTaskDelete = isRouteChangeAllowed && deletable && !hasActivity;

    return (
      <PageLayout dialogs={dialogs} toolbar={formatMessage(messages.title)}>
        <Card style={{margin: "1em"}}>
          <CardContent>
            <Grid>
              <Cell palm="12/12">
                <FormattedMessage
                  defaultMessage="Rute"
                  id="task-edit.header.route-plan"
                  tagName="h4"
                />
                <Button
                  color="secondary"
                  disabled={!isRouteChangeAllowed}
                  variant="contained"
                  onClick={this.handleSelectRouteButton}
                >
                  {formatMessage(messages.selectRoutePlanButton)}
                </Button>
                {routeString ? <br /> : null}
                {routeString}
              </Cell>
              <Cell palm="12/12">
                {customerSettings.employeeLabelVariant === "MACHINEOPERATOR" ? (
                  <FormattedMessage
                    defaultMessage="Maskinfører"
                    id="task-edit.header.machine-operator"
                    tagName="h4"
                  />
                ) : customerSettings.employeeLabelVariant === "EMPLOYEE" ? (
                  <FormattedMessage
                    defaultMessage="Medarbejder"
                    id="task-edit.header.employee"
                    tagName="h4"
                  />
                ) : (
                  <FormattedMessage
                    defaultMessage="Chauffør"
                    id="task-edit.header.chauffeur"
                    tagName="h4"
                  />
                )}
                <Button
                  color="secondary"
                  disabled={
                    validated ||
                    (userIsOnlyMachineOperator && !userIsSeniorMachineOperator) ||
                    disableMachineOperatorSelection
                  }
                  variant="contained"
                  onClick={this.handleSelectMachineOperatorButton}
                >
                  {formatMessage(messages.selectMachineOperatorButton)}
                </Button>
                {machineOperator}
                {absent}
                {hasOtherTasks}
              </Cell>
              <Cell palm="12/12">
                {customerSettings.machineLabelVariant === "MACHINE" ? (
                  <FormattedMessage
                    defaultMessage="Maskine"
                    id="task-edit.header.machine"
                    tagName="h4"
                  />
                ) : (
                  <FormattedMessage
                    defaultMessage="Køretøj"
                    id="task-edit.header.vehicle"
                    tagName="h4"
                  />
                )}
                <Button
                  color="secondary"
                  disabled={
                    validated ||
                    (completed && !!userIsOnlyMachineOperator) ||
                    !!userIsOtherMachineOperator
                  }
                  variant="contained"
                  onClick={this.handleSelectMachineButton}
                >
                  {formatMessage(messages.selectMachineButton)}
                </Button>
                {machineChips}
              </Cell>
            </Grid>
            <Grid>
              <Cell palm="12/12">
                {userIsOnlyMachineOperator && !(userIsSeniorMachineOperator && !hasActivity) ? (
                  <h4>Dato: {formatDate(new Date(task.date || new Date()))}</h4>
                ) : (
                  <DateField
                    autoOk
                    fullWidth
                    disabled={validated}
                    label={formatMessage(messages.date)}
                    margin="dense"
                    value={task.date}
                    onChange={this.handleDateChange}
                  />
                )}
              </Cell>
              {timeBlock}
            </Grid>
            {userIsManager ||
            (userIsSeniorMachineOperator &&
              customerSettings.seniorMachineOperatorCanEditNotesFromManager) ? (
              <Grid>
                <Cell palm="12/12">
                  <TrimTextField
                    fullWidth
                    multiline
                    disabled={validated}
                    label={formatMessage(messages.notesFromAdministration)}
                    margin="dense"
                    maxRows={30}
                    minRows={2}
                    value={task.notesFromManager}
                    variant="outlined"
                    onChange={this.handleNotesFromManagerChange}
                  />
                </Cell>
              </Grid>
            ) : null}
            {!userIsOnlyMachineOperator && customerSettings.enableTaskInternalManagerNotes ? (
              <Grid>
                <Cell palm="12/12">
                  <TrimTextField
                    fullWidth
                    multiline
                    disabled={validated}
                    label={formatMessage(messages.taskInternalManagerNotes)}
                    margin="dense"
                    maxRows={30}
                    minRows={2}
                    value={task.managerInternalNotes}
                    variant="outlined"
                    onChange={this.handleTaskInternalManagerNotesChange}
                  />
                </Cell>
              </Grid>
            ) : null}
            {(!userIsOnlyMachineOperator || customerSettings.machineOperaterCanEditInvoiceNote) &&
            customerSettings.showInvoiceNote ? (
              <Grid>
                <Cell palm="12/12">
                  <TrimTextField
                    fullWidth
                    multiline
                    disabled={validated}
                    label={formatMessage(messages.invoiceNote)}
                    margin="dense"
                    maxRows={30}
                    minRows={2}
                    value={task.invoiceNote}
                    variant="outlined"
                    onChange={this.handleInvoiceNoteChange}
                  />
                </Cell>
              </Grid>
            ) : null}
            <Grid>
              <Cell palm="12/12">
                <TrimTextField
                  fullWidth
                  multiline
                  disabled={validated}
                  label={
                    customerSettings.employeeLabelVariant === "MACHINEOPERATOR"
                      ? formatMessage(messages.notesFromMachineOperator)
                      : customerSettings.employeeLabelVariant === "EMPLOYEE"
                        ? formatMessage(messages.notesFromEmployee)
                        : formatMessage(messages.notesFromChauffeur)
                  }
                  margin="dense"
                  maxRows={30}
                  minRows={2}
                  value={task.notesFromMachineOperator}
                  variant="outlined"
                  onChange={this.handleNotesFromMachineOperatorChange}
                />
              </Cell>
            </Grid>
            {userIsOnlyMachineOperator ? null : (
              <Grid>
                <Cell palm="12/12" style={{paddingBottom: 12}}>
                  <FormControlLabel
                    control={
                      <Switch checked={task.completed} onChange={this.handleToggleCompleted} />
                    }
                    disabled={validated || !completed}
                    label={formatMessage(messages.completed)}
                    labelPlacement="end"
                  />
                </Cell>
                <Cell palm="12/12" style={{paddingBottom: 12}}>
                  <FormControlLabel
                    control={<Switch checked={validated} onChange={this.handleToggleValidated} />}
                    disabled={!canUnapproveTasks || !!task.recordedInC5 || !validated}
                    label={formatMessage(messages.validated)}
                    labelPlacement="end"
                  />
                </Cell>
                <Cell palm="12/12" style={{paddingBottom: 12}}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={!!task.recordedInC5}
                        onChange={this.handleToggleRecordedInC5}
                      />
                    }
                    disabled={!userIsConsultant || !task.completed || !validated}
                    label={formatMessage(messages.recorded)}
                    labelPlacement="end"
                  />
                </Cell>
              </Grid>
            )}
          </CardContent>
        </Card>
        <Grid style={{margin: "1em"}}>
          <Cell palm="12/12">
            <Button color="secondary" variant="contained" onClick={this.handleStartButton}>
              {formatMessage(messages.startButton)}
            </Button>
          </Cell>
          <Cell palm="12/12">
            <ErrorColorButton
              disabled={!allowTaskDelete}
              variant="contained"
              onClick={this.handleDeleteButton}
            >
              {formatMessage(messages.deleteButton)}
            </ErrorColorButton>
          </Cell>
        </Grid>
        <RemoveRecordedInC5Dialog
          open={this.state.removeRecordedInC5DialogOpen}
          onCancel={this.handleRemoveRecordedInC5DialogCancel}
          onOk={this.handleRemoveRecordedInC5DialogOk}
        />
        <RecordInC5Dialog
          open={this.state.recordInC5DialogOpen}
          onCancel={this.handleRecordInC5DialogCancel}
          onOk={this.handleRecordInC5DialogOk}
        />
      </PageLayout>
    );
  }
}

interface TaskEditContainerStateProps {
  currentRole: Role | null;
  currentUserURL: string | null;
  customerSettings: Config;
  daysAbsenceArray: readonly DaysAbsence[];
  hoursAbsenceArray: readonly HoursAbsence[];
  machineLookup: (url: MachineUrl) => Machine | undefined;
  orderLookup: (url: OrderUrl) => Order | undefined;
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
  reportingSpecificationLookup: (
    url: ReportingSpecificationUrl,
  ) => ReportingSpecification | undefined;
  routePlanLookup: (url: RoutePlanUrl) => RoutePlan | undefined;
  routePlanTaskActivityOptionArray: readonly RoutePlanTaskActivityOption[];
  routePlanTaskArray: readonly RoutePlanTask[];
  routePlanTaskResultArray: readonly RoutePlanTaskResult[];
  routeTaskActivityOptionArray: readonly RouteTaskActivityOption[];
  routeTaskArray: readonly RouteTask[];
  routeTaskResultArray: readonly RouteTaskResult[];
  taskArray: readonly Task[];
  timerArray: readonly Timer[];
  timerLookup: (url: TimerUrl) => Timer | undefined;
  timerStartArray: readonly TimerStart[];
  userLookup: (url: UserUrl) => User | undefined;
  userUserProfileLookup: (url: UserUrl) => UserProfile | undefined;
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
}

interface TaskEditContainerDispatchProps {
  create: (instance: ResourceTypeUnion) => void;
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  registerTimerStartPosition: (timerStart: TimerStart) => void;
  remove: (url: string) => void;
  update: (url: string, patch: PatchUnion) => void;
}

interface TaskEditContainerOwnProps {
  instance: Task;
}

type TaskEditContainerProps = TaskEditContainerDispatchProps &
  TaskEditContainerOwnProps &
  TaskEditContainerStateProps;

class TaskEditContainer extends PureComponent<TaskEditContainerProps> {
  getTimerStarts(): TimerStart[] {
    const task = this.props.instance;
    const taskURL = task.url;
    return _.sortBy(
      this.props.timerStartArray.filter((instance) => instance.task === taskURL),
      getNormalisedDeviceTimestamp,
    );
  }
  render(): JSX.Element {
    const {orderLookup, routePlanLookup, routeTaskArray} = this.props;
    const task = this.props.instance;
    const orderURL = task.order as OrderUrl;
    const order = orderLookup(orderURL);
    const routePlanURL = order?.routePlan;
    const routePlan = routePlanURL ? routePlanLookup(routePlanURL) : undefined;
    if (!order || !routePlan) {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
    }
    const taskURL = task.url;
    const taskRouteTaskArray = routeTaskArray.filter((routeTask) => routeTask.route === taskURL);
    const isRouteChangeAllowed = !taskRouteTaskArray.some((routeTask) => !!routeTask.completed);
    const now = new Date();
    now.setUTCMilliseconds(0);
    const computedIntervals = task.recordedInC5
      ? task.computedTimeSet || []
      : computeIntervalsTruncated(this.getTimerStarts());
    const correctionIntervals = task.machineOperatorTimeCorrectionSet || [];
    const managerCorrectionIntervals = task.managerTimeCorrectionSet || [];
    const intervals = mergeIntervals(
      computedIntervals,
      correctionIntervals,
      managerCorrectionIntervals,
    );
    const timerMinutesMap = computeIntervalSums(intervals, now);
    return (
      <TaskEditContent
        hasActivity={!!intervals.length}
        isRouteChangeAllowed={isRouteChangeAllowed}
        order={order}
        routePlan={routePlan}
        task={task}
        taskRouteTaskArray={taskRouteTaskArray}
        timerMinutesMap={timerMinutesMap}
        {...this.props}
      />
    );
  }
}

const ConnectedTaskEditContainer = connect<
  TaskEditContainerStateProps,
  TaskEditContainerDispatchProps,
  TaskEditContainerOwnProps,
  AppState
>(
  createStructuredSelector<AppState, TaskEditContainerStateProps>({
    currentRole: getCurrentRole,
    currentUserURL: getCurrentUserURL,
    customerSettings: getCustomerSettings,
    daysAbsenceArray: getDaysAbsenceArray,
    hoursAbsenceArray: getHoursAbsenceArray,
    machineLookup: getMachineLookup,
    orderLookup: getOrderLookup,
    priceGroupLookup: getPriceGroupLookup,
    reportingSpecificationLookup: getReportingSpecificationLookup,
    routePlanLookup: getRoutePlanLookup,
    routePlanTaskActivityOptionArray: getRoutePlanTaskActivityOptionArray,
    routePlanTaskArray: getRoutePlanTaskArray,
    routePlanTaskResultArray: getRoutePlanTaskResultArray,
    routeTaskActivityOptionArray: getRouteTaskActivityOptionArray,
    routeTaskArray: getRouteTaskArray,
    routeTaskResultArray: getRouteTaskResultArray,
    taskArray: getTaskArray,
    timerArray: getTimerArray,
    timerLookup: getTimerLookup,
    timerStartArray: getTimerStartArray,
    userLookup: getUserLookup,
    userUserProfileLookup: getUserUserProfileLookup,
    workTypeLookup: getWorkTypeLookup,
  }),
  {
    create: actions.create,
    go: actions.go,
    registerTimerStartPosition: actions.registerTimerStartPosition,
    remove: actions.remove,
    update: actions.update,
  },
)(TaskEditContainer);

const related: LoadInstanceRelated = [
  {
    memberName: "order",
    resourceType: "order",
    type: "targetOfForeignKey",
  },
] as const;

class LoadTaskEdit extends React.Component<undefined> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  render(): JSX.Element {
    const {formatMessage} = this.context;
    return (
      <DoLoadInstance
        Component={ConnectedTaskEditContainer}
        loadingTitle={formatMessage(messages.title)}
        lookupSelector={getTaskLookup}
        related={related}
        resourceName="task"
      />
    );
  }
}

export {LoadTaskEdit as TaskEdit};
