import {
  PricePercentFuelSurchargeSpecification,
  PricePercentFuelSurchargeSpecificationEntry,
  PricePercentFuelSurchargeSpecificationEntryUrl,
} from "@co-common-libs/resources";
import {
  getPricePercentFuelSurchargeSpecificationEntryArray,
  getPricePercentFuelSurchargeSpecificationEntryLookup,
} from "@co-frontend-libs/redux";
import {
  Card,
  CardHeader,
  Fab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import _ from "lodash";
import PlusIcon from "mdi-react/PlusIcon";
import React, {useCallback, useMemo, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {PricePercentFuelSurchargeSpecificationEntryDialog} from "../common/entry-dialog";
import {PricePercentFuelSurchargeSpecificationEntryRow} from "./price-percent-fuel-surcharge-specification-entry-row";

interface PricePercentFuelSurchargeSpecificationEntryTableProps {
  specification: PricePercentFuelSurchargeSpecification;
}

export const PricePercentFuelSurchargeSpecificationEntryTable = React.memo(
  function PricePercentFuelSurchargeSpecificationEntryTable(
    props: PricePercentFuelSurchargeSpecificationEntryTableProps,
  ): JSX.Element {
    const {specification} = props;

    const {conversionFactor, url: specificationUrl} = specification;

    const intl = useIntl();

    const specificationEntryArray = useSelector(
      getPricePercentFuelSurchargeSpecificationEntryArray,
    );
    const specificationEntryLookup = useSelector(
      getPricePercentFuelSurchargeSpecificationEntryLookup,
    );

    const sortedSpecificationEntries = useMemo(
      (): readonly PricePercentFuelSurchargeSpecificationEntry[] =>
        _.sortBy(
          specificationEntryArray.filter((entry) => entry.specification === specificationUrl),
          [(entry) => entry.fromDate, (entry) => entry.toDate],
        ),
      [specificationEntryArray, specificationUrl],
    );

    const [editingEntryUrl, setEditingEntryUrl] =
      useState<PricePercentFuelSurchargeSpecificationEntryUrl>();
    const [creatingOrEditingEntry, setCreatingOrEditingEntry] = useState(false);

    const handleCreateEntryClick = useCallback(() => {
      setEditingEntryUrl(undefined);
      setCreatingOrEditingEntry(true);
    }, []);

    const handleEditEntryClick = useCallback(
      (url: PricePercentFuelSurchargeSpecificationEntryUrl): void => {
        setEditingEntryUrl(url);
        setCreatingOrEditingEntry(true);
      },
      [],
    );

    const rows = sortedSpecificationEntries
      .map((entry) => (
        <PricePercentFuelSurchargeSpecificationEntryRow
          key={entry.url}
          entry={entry}
          specification={specification}
          onEntryClick={handleEditEntryClick}
        />
      ))
      .reverse();

    const handleEntryDialogClose = useCallback(() => {
      setCreatingOrEditingEntry(false);
    }, []);

    return (
      <>
        <PricePercentFuelSurchargeSpecificationEntryDialog
          conversionFactor={conversionFactor}
          editingUrl={editingEntryUrl}
          open={creatingOrEditingEntry}
          resourceName="pricePercentFuelSurchargeSpecificationEntry"
          sortedExistingEntries={sortedSpecificationEntries}
          specificationEntryLookup={specificationEntryLookup}
          specificationUrl={specification.url}
          onClose={handleEntryDialogClose}
        />
        <Card>
          <CardHeader
            action={
              <Fab size="small" onClick={handleCreateEntryClick}>
                <PlusIcon />
              </Fab>
            }
            title={intl.formatMessage({defaultMessage: "Brændstofpris"})}
          />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage defaultMessage="Fra dato" />
                </TableCell>
                <TableCell>
                  <FormattedMessage defaultMessage="Til dato" />
                </TableCell>
                <TableCell>
                  {conversionFactor === 1 ? (
                    <FormattedMessage defaultMessage="Pris (kr/L)" />
                  ) : (
                    <FormattedMessage
                      defaultMessage="Pris (kr{conversionFactor, number} L)"
                      values={{conversionFactor}}
                    />
                  )}
                </TableCell>
                <TableCell>
                  <FormattedMessage defaultMessage="Sats (%)" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{cursor: "pointer"}}>{rows}</TableBody>
          </Table>
        </Card>
      </>
    );
  },
);
