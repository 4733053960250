/* eslint-disable sort-keys-fix/sort-keys-fix */
import {CustomerUrl, PriceGroupUrl, WorkTypeUrl} from "@co-common-libs/resources";
import {StateMachine, assign, createMachine} from "@xstate/fsm";

interface WorkTypeSelectionContext {
  customer: CustomerUrl | null;
  potentialPriceGroups: PriceGroupUrl[] | null;
  priceGroup: PriceGroupUrl | null;
  workType: WorkTypeUrl | null;
}

export type WorkTypeSelectionEvent =
  | {
      potentialPriceGroups: PriceGroupUrl[];
      priceGroup: PriceGroupUrl | null;
      type: "WORK_TYPE_SELECTED";
      workType: WorkTypeUrl;
    }
  | {customer: CustomerUrl | null; type: "START"}
  | {type: "CANCEL"}
  | {type: "PRICE_GROUP_SELECTED"; url: PriceGroupUrl};

type WorkTypeSelectionTypeState =
  | {
      context: WorkTypeSelectionContext & {
        priceGroup: null;
        workType: WorkTypeUrl;
      };
      value: "selectPriceGroup";
    }
  | {
      context: WorkTypeSelectionContext & {priceGroup: null; workType: null};
      value: "selectWorkType";
    }
  | {
      context: WorkTypeSelectionContext;
      value: "initial";
    };

export type WorkTypeSelectionState = StateMachine.State<
  WorkTypeSelectionContext,
  WorkTypeSelectionEvent,
  WorkTypeSelectionTypeState
>;

export const workTypeSelectionStateMachine = createMachine<
  WorkTypeSelectionContext,
  WorkTypeSelectionEvent,
  WorkTypeSelectionTypeState
>({
  context: {
    priceGroup: null,
    workType: null,
    customer: null,
    potentialPriceGroups: null,
  },
  id: "workTypeSelection",
  initial: "initial",
  states: {
    initial: {
      on: {
        START: {
          actions: assign({
            workType: (_context, _event) => null,
            priceGroup: (_context, _event) => null,
            customer: (_context, event) => event.customer,
          }),
          target: "selectWorkType",
        },
      },
    },
    selectWorkType: {
      on: {
        WORK_TYPE_SELECTED: [
          {
            cond: (_context, event) => !!event.priceGroup,
            actions: [
              assign({
                workType: (_context, event) => event.workType,
                priceGroup: (_context, event) => event.priceGroup,
              }),
              "signalDone",
            ],
            target: "initial",
          },
          {
            cond: (_context, event) => event.potentialPriceGroups.length > 0,
            actions: assign({
              workType: (_context, event) => event.workType,
              potentialPriceGroups: (_context, event) => event.potentialPriceGroups,
            }),
            target: "selectPriceGroup",
          },
          {
            actions: [
              assign({
                workType: (_context, event) => event.workType,
              }),
              "signalDone",
            ],
            target: "initial",
          },
        ],
        CANCEL: {
          actions: "signalCancelled",
          target: "initial",
        },
      },
    },
    selectPriceGroup: {
      on: {
        PRICE_GROUP_SELECTED: {
          actions: [
            assign({
              priceGroup: (_context, event) => event.url,
            }),
            "signalDone",
          ],
          target: "initial",
        },
        CANCEL: {
          actions: "signalCancelled",
          target: "initial",
        },
      },
    },
  },
});
