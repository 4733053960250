import {Config} from "@co-common-libs/config";
import {
  PatchUnion,
  ResourceTypeUnion,
  Timer,
  TimerUrl,
  WorkType,
  emptyTimer,
  urlToId,
} from "@co-common-libs/resources";
import {
  ColorPickerDialog,
  VerticalStackingFloatingActionButton,
} from "@co-frontend-libs/components";
import {
  AppState,
  PathTemplate,
  actions,
  getCustomerSettings,
  getTimerArray,
  getTimerLookup,
} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import {instanceURL} from "frontend-global-config";
import PlusIcon from "mdi-react/PlusIcon";
import React from "react";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {v4 as uuid} from "uuid";
import {NewTimerDialog} from "./new-timer-dialog";
import {TimerTable} from "./timer-table";

interface TimerListStateProps {
  customerSettings: Config;
  timerArray: readonly Timer[];
  timerLookup: (url: TimerUrl) => Timer | undefined;
}

interface TimerListDispatchProps {
  create: (instance: ResourceTypeUnion) => void;
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  update: (url: string, patch: PatchUnion) => void;
}

type TimerListProps = TimerListDispatchProps & TimerListStateProps;

interface TimerListState {
  colorPickerDialogOpenedFor: TimerUrl | null;
  newTimerDialogOpen: boolean;
}

class TimerList extends PureComponent<TimerListProps, TimerListState> {
  state: TimerListState = {
    colorPickerDialogOpenedFor: null,
    newTimerDialogOpen: false,
  };

  @bind
  handleAddTimer(): void {
    this.setState({newTimerDialogOpen: true});
  }

  @bind
  handleNewTimerDialogOk(identifier: string, label: string, workType: WorkType): void {
    this.setState({newTimerDialogOpen: false});
    const id = uuid();
    const url = instanceURL("timer", id);
    const data: Timer = {
      ...emptyTimer,
      color: workType.internalTaskPrimary && workType.color ? workType.color : "#ffffff",
      id,
      identifier,
      includeForInternalTask: workType.internalTaskPrimary,
      label,
      url,
      workType: workType.url,
    };

    this.props.create(data);
    this.props.go("/systemSetup/timer/:id", {
      id,
    });
  }

  @bind
  handleNewTimerDialogCancel(): void {
    this.setState({newTimerDialogOpen: false});
  }

  @bind
  handleChangeColorClick(url: TimerUrl): void {
    this.setState({
      colorPickerDialogOpenedFor: url,
    });
  }

  @bind
  handleColorPickerDialogCancel(): void {
    this.setState({
      colorPickerDialogOpenedFor: null,
    });
  }

  @bind
  handleColorSelected(color: string): void {
    if (this.state.colorPickerDialogOpenedFor) {
      this.props.update(this.state.colorPickerDialogOpenedFor, [{member: "color", value: color}]);
    }
    this.setState({
      colorPickerDialogOpenedFor: null,
    });
  }

  @bind
  handleClick(timerURL: string): void {
    this.props.go("/systemSetup/timer/:id", {
      id: urlToId(timerURL),
    });
  }

  render(): JSX.Element {
    const {timerLookup} = this.props;

    const {colorPickerDialogOpenedFor} = this.state;

    const colorPickerDialogInitialColor = colorPickerDialogOpenedFor
      ? timerLookup(colorPickerDialogOpenedFor)?.color
      : undefined;

    const otherIdentifiers = new Set(this.props.timerArray.map((instance) => instance.identifier));

    return (
      <div style={{minHeight: "100%", position: "relative"}}>
        <TimerTable onClick={this.handleClick} onColorClick={this.handleChangeColorClick} />
        <NewTimerDialog
          open={this.state.newTimerDialogOpen}
          otherTimerIdentifiers={otherIdentifiers}
          onCancel={this.handleNewTimerDialogCancel}
          onOk={this.handleNewTimerDialogOk}
        />
        <VerticalStackingFloatingActionButton
          fabPosition="absolute"
          stackIndex={0}
          onClick={this.handleAddTimer}
        >
          <PlusIcon />
        </VerticalStackingFloatingActionButton>
        <ColorPickerDialog
          // Change in key, remounts the component and sets new initial value
          key={colorPickerDialogInitialColor || ""}
          required
          initialSelected={colorPickerDialogInitialColor}
          open={!!colorPickerDialogOpenedFor}
          onCancel={this.handleColorPickerDialogCancel}
          onOk={this.handleColorSelected}
        />
      </div>
    );
  }
}

const ConnectedTimerList = connect<TimerListStateProps, TimerListDispatchProps, object, AppState>(
  createStructuredSelector<AppState, TimerListStateProps>({
    customerSettings: getCustomerSettings,
    timerArray: getTimerArray,
    timerLookup: getTimerLookup,
  }),
  {
    create: actions.create,
    go: actions.go,
    update: actions.update,
  },
)(TimerList);

export {ConnectedTimerList as TimerList};
