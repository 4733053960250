import {Config} from "@co-common-libs/config";
import {
  Location,
  LocationUrl,
  Task,
  WorkType,
  WorkTypeUrl,
  YieldDelivery,
  YieldDeliveryLocation,
  YieldLog,
  YieldLogUrl,
  YieldPickup,
  YieldPickupLocation,
} from "@co-common-libs/resources";
import {getWorkTypeString} from "@co-common-libs/resources-utils";
import {formatDateNumeric, makeObjectFromMap, mapMap} from "@co-common-libs/utils";
import {FilePdfIcon} from "@co-frontend-libs/components";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {AjaxDownloadButton} from "app-components";
import {PureComponent} from "app-utils";
import {globalConfig} from "frontend-global-config";
import React from "react";
import {FormattedMessage, FormattedNumber, IntlContext, defineMessages} from "react-intl";

const messages = defineMessages({
  downloadPDF: {
    defaultMessage: "Download PDF",
    id: "yield-log-list.download-pdf",
  },
  noAddress: {
    defaultMessage: "Ingen adresse",
    id: "yield-log-list.no-address",
  },
  noUnit: {
    defaultMessage: "Ingen enhed",
    id: "yield-log-list.no-unit",
  },
  tonne: {
    defaultMessage: "ton",
    id: "yield-log-list.label.tonne",
  },
  total: {
    defaultMessage: "Total",
    id: "yield-log-list.total",
  },
  yieldLog: {
    defaultMessage: "Udbyttelog",
    id: "yield-log-list.label.yieldlog",
  },
});

interface WorkTypeRowProps {
  workType?: WorkType | undefined;
}

class WorkTypeRow extends PureComponent<WorkTypeRowProps> {
  render(): JSX.Element {
    const {workType} = this.props;
    return (
      <TableRow>
        <TableCell style={{fontWeight: "bold"}}>{getWorkTypeString(workType)}</TableCell>
        <TableCell />
        <TableCell />
      </TableRow>
    );
  }
}

interface UnitRowProps {
  unit: string;
}

class UnitRow extends PureComponent<UnitRowProps> {
  render(): JSX.Element {
    const {unit} = this.props;
    return (
      <TableRow>
        <TableCell style={{fontWeight: "bold"}}>{unit}</TableCell>
        <TableCell />
        <TableCell />
      </TableRow>
    );
  }
}

interface LocationSumRowProps {
  bold?: boolean;
  delivered: number;
  location: string;
  pickedUp: number;
  unit: string;
}

class LocationSumRow extends PureComponent<LocationSumRowProps> {
  render(): JSX.Element {
    const {bold, delivered, location, pickedUp, unit} = this.props;
    const extraStyles: React.CSSProperties = bold
      ? {borderBottom: "2px solid #999", fontWeight: "bold"}
      : {};
    return (
      <TableRow style={extraStyles}>
        <TableCell>{location}</TableCell>
        <TableCell>
          <FormattedNumber value={pickedUp} /> {unit}
        </TableCell>
        <TableCell>
          <FormattedNumber value={delivered} /> {unit}
        </TableCell>
      </TableRow>
    );
  }
}

interface YieldLogListProps {
  customerName: string;
  customerSettings: Config;
  fromDate?: string | undefined;
  locationLookup: (url: LocationUrl) => Location | undefined;
  taskList: readonly Task[];
  toDate?: string | undefined;
  token: string | null;
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
  yieldDeliveryArray: readonly YieldDelivery[];
  yieldDeliveryLocationArray: readonly YieldDeliveryLocation[];
  yieldLogArray: readonly YieldLog[];
  yieldLogLookup: (url: YieldLogUrl) => YieldLog | undefined;
  yieldPickupArray: readonly YieldPickup[];
  yieldPickupLocationArray: readonly YieldPickupLocation[];
}

export class YieldLogList extends PureComponent<YieldLogListProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  updateLocationSumMap(
    yieldPickupLocations: readonly YieldPickupLocation[],
    yieldDeliveryLocations: readonly YieldDeliveryLocation[],
    locationSumMap: Map<string, {delivered: number; pickedUp: number}>,
  ): void {
    const {formatMessage} = this.context;
    yieldPickupLocations.forEach((yieldPickupLocation) => {
      const yieldPickupLocationUrl = yieldPickupLocation.url;
      const yieldLog = this.props.yieldLogLookup(yieldPickupLocation.yieldlog);
      if (!yieldLog) {
        return;
      }
      const {unit, wagonWeight} = yieldLog;
      const yieldPickups = this.props.yieldPickupArray.filter(
        (p) => p.location === yieldPickupLocationUrl,
      );
      const locationURL = yieldPickupLocation.relatedLocation;
      const location = locationURL ? this.props.locationLookup(locationURL) : null;
      const address = location
        ? location.name || location.address
        : formatMessage(messages.noAddress);

      yieldPickups.forEach((yieldPickup) => {
        const pickedUp =
          unit === "tonne"
            ? (yieldPickup.amount || 0) - (wagonWeight || 0)
            : yieldPickup.amount || 0;
        const sumItemRow = locationSumMap.get(address);
        if (!sumItemRow) {
          locationSumMap.set(address, {
            delivered: 0,
            pickedUp,
          });
        } else {
          sumItemRow.pickedUp += pickedUp;
        }
      });
    });
    yieldDeliveryLocations.forEach((yieldDeliveryLocation) => {
      const yieldDeliveryLocationUrl = yieldDeliveryLocation.url;
      const yieldLog = this.props.yieldLogLookup(yieldDeliveryLocation.yieldlog);
      if (!yieldLog) {
        return;
      }
      const {unit, wagonWeight} = yieldLog;
      const deliveries = this.props.yieldDeliveryArray.filter(
        (p) => p.location === yieldDeliveryLocationUrl,
      );
      const locationURL = yieldDeliveryLocation.relatedLocation;
      const location = locationURL ? this.props.locationLookup(locationURL) : null;
      const address = location
        ? location.name || location.address
        : formatMessage(messages.noAddress);

      deliveries.forEach((yieldDelivery) => {
        const delivered =
          unit === "tonne"
            ? (yieldDelivery.amount || 0) - (wagonWeight || 0)
            : yieldDelivery.amount || 0;
        const sumItemRow = locationSumMap.get(address);
        if (!sumItemRow) {
          locationSumMap.set(address, {
            delivered,
            pickedUp: 0,
          });
        } else {
          sumItemRow.delivered += delivered;
        }
      });
    });
  }

  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {
      customerName,
      fromDate,
      taskList,
      toDate,
      workTypeLookup,
      yieldDeliveryLocationArray,
      yieldLogArray,
      yieldPickupLocationArray,
    } = this.props;
    const rowArray: JSX.Element[] = [];
    const pdfSum = new Map<
      string,
      {
        heading: string;
        logs: Map<
          string,
          {
            delivered: number;
            pickedUp: number;
          }
        >;
        sum: {
          delivered: number;
          pickedUp: number;
        };
        unit: string;
      }
    >();

    if (this.props.customerSettings.noExternalTaskWorkType) {
      const unitLocationMap = new Map<
        string,
        {
          unit: string;
          yieldDeliveryLocations: YieldDeliveryLocation[];
          yieldPickupLocations: YieldPickupLocation[];
        }
      >();
      const taskURLSet = new Set(taskList.map((task) => task.url));
      yieldLogArray.forEach((yieldLog) => {
        if (!taskURLSet.has(yieldLog.task)) {
          return;
        }

        const yieldLogUrl = yieldLog.url;

        const unitKey = yieldLog.unit;

        const yieldPickupLocations = yieldPickupLocationArray.filter((t) => {
          return t.yieldlog === yieldLogUrl;
        });
        const yieldDeliveryLocations = yieldDeliveryLocationArray.filter((t) => {
          return t.yieldlog === yieldLogUrl;
        });

        const unitTaskItem = unitLocationMap.get(unitKey);

        if (unitTaskItem) {
          unitTaskItem.yieldPickupLocations.push(...yieldPickupLocations);
          unitTaskItem.yieldDeliveryLocations.push(...yieldDeliveryLocations);
        } else {
          unitLocationMap.set(unitKey, {
            unit: unitKey,
            yieldDeliveryLocations,
            yieldPickupLocations,
          });
        }
      });

      const unitLocationMapKeys = Array.from(unitLocationMap.keys());
      unitLocationMapKeys.sort();
      unitLocationMapKeys.forEach((unitKey) => {
        const sum = new Map<string, {delivered: number; pickedUp: number}>();
        const {unit, yieldDeliveryLocations, yieldPickupLocations} = unitLocationMap.get(
          unitKey,
        ) as {
          unit: string;
          yieldDeliveryLocations: YieldDeliveryLocation[];
          yieldPickupLocations: YieldPickupLocation[];
        };
        const unitString = unit === "m3" ? unit : formatMessage(messages.tonne);
        this.updateLocationSumMap(yieldPickupLocations, yieldDeliveryLocations, sum);

        const sumKeys = Array.from(sum.keys());
        if (sumKeys.length > 0) {
          let pickedUpSum = 0;
          let deliveredSum = 0;
          rowArray.push(<UnitRow key={unitKey} unit={unitString} />);

          sumKeys.sort();
          sumKeys.forEach((ident) => {
            const sumItem = sum.get(ident) as {
              delivered: number;
              pickedUp: number;
            };
            pickedUpSum += sumItem.pickedUp;
            deliveredSum += sumItem.delivered;
            rowArray.push(
              <LocationSumRow
                key={`${ident}-${unitKey}`}
                delivered={sumItem.delivered}
                location={ident}
                pickedUp={sumItem.pickedUp}
                unit={unitString}
              />,
            );
          });
          pdfSum.set(unitKey, {
            heading: unitString,
            logs: sum,
            sum: {
              delivered: deliveredSum,
              pickedUp: pickedUpSum,
            },
            unit: unitString,
          });
          rowArray.push(
            <LocationSumRow
              key={`sum-${unitKey}`}
              bold
              delivered={deliveredSum}
              location={formatMessage(messages.total)}
              pickedUp={pickedUpSum}
              unit={unitString}
            />,
          );
        }
      });
    } else {
      const workTypeLocationMap = new Map<
        string,
        {
          unit: string;
          workType: WorkType | undefined;
          yieldDeliveryLocations: YieldDeliveryLocation[];
          yieldPickupLocations: YieldPickupLocation[];
        }
      >();
      this.props.taskList.forEach((task) => {
        const workTypeURL = task.workType;
        const workType = workTypeURL ? workTypeLookup(workTypeURL) : undefined;
        const yieldLog = yieldLogArray.find((log) => log.task === task.url);

        if (!yieldLog) {
          return;
        }

        const yieldLogUrl = yieldLog.url;

        const {unit} = yieldLog;
        const unitKey = unit;

        const yieldPickupLocations = yieldPickupLocationArray.filter((t) => {
          return t.yieldlog === yieldLogUrl;
        });
        const yieldDeliveryLocations = yieldDeliveryLocationArray.filter((t) => {
          return t.yieldlog === yieldLogUrl;
        });

        const workTypeKey = `${getWorkTypeString(workType)}-${unitKey}`;
        const workTypeTaskItem = workTypeLocationMap.get(workTypeKey);

        if (workTypeTaskItem) {
          workTypeTaskItem.yieldPickupLocations.push(...yieldPickupLocations);
          workTypeTaskItem.yieldDeliveryLocations.push(...yieldDeliveryLocations);
        } else {
          workTypeLocationMap.set(workTypeKey, {
            unit,
            workType,
            yieldDeliveryLocations,
            yieldPickupLocations,
          });
        }
      });

      const workTypeLocationMapKeys = Array.from(workTypeLocationMap.keys());
      workTypeLocationMapKeys.sort();

      workTypeLocationMapKeys.forEach((workTypeKey) => {
        const sum = new Map<string, {delivered: number; pickedUp: number}>();
        const {unit, workType, yieldDeliveryLocations, yieldPickupLocations} =
          workTypeLocationMap.get(workTypeKey) as {
            unit: string;
            workType: WorkType | undefined;
            yieldDeliveryLocations: YieldDeliveryLocation[];
            yieldPickupLocations: YieldPickupLocation[];
          };
        const unitString = unit === "m3" ? unit : formatMessage(messages.tonne);

        this.updateLocationSumMap(yieldPickupLocations, yieldDeliveryLocations, sum);

        const sumKeys = Array.from(sum.keys());
        if (sumKeys.length > 0) {
          let pickedUpSum = 0;
          let deliveredSum = 0;

          rowArray.push(<WorkTypeRow key={workTypeKey} workType={workType} />);

          sumKeys.sort();
          sumKeys.forEach((ident) => {
            const sumItem = sum.get(ident) as {
              delivered: number;
              pickedUp: number;
            };
            pickedUpSum += sumItem.pickedUp;
            deliveredSum += sumItem.delivered;
            rowArray.push(
              <LocationSumRow
                key={`${ident}-${workTypeKey}`}
                delivered={sumItem.delivered}
                location={ident}
                pickedUp={sumItem.pickedUp}
                unit={unitString}
              />,
            );
          });
          pdfSum.set(workTypeKey, {
            heading: workType ? workType.name : "",
            logs: sum,
            sum: {
              delivered: deliveredSum,
              pickedUp: pickedUpSum,
            },
            unit: unitString,
          });
          rowArray.push(
            <LocationSumRow
              key={`sum-${workTypeKey}`}
              bold
              delivered={deliveredSum}
              location={formatMessage(messages.total)}
              pickedUp={pickedUpSum}
              unit={unitString}
            />,
          );
        }
      });
    }

    const pdfDataObject = makeObjectFromMap(
      mapMap(pdfSum, (entry) => ({
        ...entry,
        logs: makeObjectFromMap(entry.logs),
      })),
    );
    const pdfUrl = `${globalConfig.baseURL}/download/yieldlog_report/pdf`;
    const pdfData = {
      customerName,
      data: pdfDataObject,
      deviceTime: Date.now(),
      fromDateString: formatDateNumeric(fromDate),
      toDateString: formatDateNumeric(toDate),
      withWorkType: !this.props.customerSettings.noExternalTaskWorkType,
    };

    return (
      <div>
        <AjaxDownloadButton
          data={pdfData}
          downloadURL={pdfUrl}
          filename={`${formatMessage(messages.yieldLog)}_${fromDate}---${toDate}_${
            this.props.customerName
          }.pdf`}
          Icon={FilePdfIcon}
          label={formatMessage(messages.downloadPDF)}
          token={this.props.token}
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Sted"
                  id="customer-instance.table-header.location"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Afhentet"
                  id="customer-instance.table-header.pickedUp"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Leveret"
                  id="customer-instance.table-header.delivered"
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{rowArray}</TableBody>
        </Table>
      </div>
    );
  }
}
