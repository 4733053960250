import {
  Customer,
  KrPerLiterDefaultFuelSurchargeUse,
  KrPerLiterFuelSurchargeSpecificationUrl,
} from "@co-common-libs/resources";
import {ConnectedKrPerLiterFuelSurchargeSpecificationDialog} from "@co-frontend-libs/connected-components";
import {
  actions,
  getKrPerLiterDefaultFuelSurchargeUseArray,
  getKrPerLiterFuelSurchargeSpecificationLookup,
} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {Button, Card, CardContent, CardHeader, IconButton} from "@material-ui/core";
import {instanceURL} from "frontend-global-config";
import CloseIcon from "mdi-react/CloseIcon";
import React, {useCallback, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuid} from "uuid";

interface CustomerDefaultKrPerLiterFuelSurchargeCardProps {
  customer: Customer;
}

export const CustomerDefaultKrPerLiterFuelSurchargeCard = React.memo(
  function CustomerDefaultKrPerLiterFuelSurchargeCard(
    props: CustomerDefaultKrPerLiterFuelSurchargeCardProps,
  ): JSX.Element {
    const {customer} = props;
    const customerUrl = customer.url;

    const intl = useIntl();

    const krPerLiterFuelSurchargeSpecificationLookup = useSelector(
      getKrPerLiterFuelSurchargeSpecificationLookup,
    );
    const krPerLiterDefaultFuelSurchargeUseArray = useSelector(
      getKrPerLiterDefaultFuelSurchargeUseArray,
    );

    const dispatch = useDispatch();

    const currentUse = krPerLiterDefaultFuelSurchargeUseArray.find(
      (surchargeUse) => surchargeUse.customer === customerUrl,
    );

    const handleClearButton = useCallback(() => {
      if (currentUse) {
        dispatch(actions.remove(currentUse.url));
      }
    }, [currentUse, dispatch]);

    const [fuelSurchargeDialogOpen, setFuelSurchargeDialogOpen] = useState(false);
    const setFuelSurchargeDialogOpenTrue = useCallWithTrue(setFuelSurchargeDialogOpen);
    const setFuelSurchargeDialogOpenFalse = useCallWithFalse(setFuelSurchargeDialogOpen);

    const handleFuelSurchargeDialogOk = useCallback(
      (fuelSurchargeUrl?: KrPerLiterFuelSurchargeSpecificationUrl): void => {
        setFuelSurchargeDialogOpen(false);
        const value = fuelSurchargeUrl ?? null;
        if (currentUse && value === currentUse.fuelSurcharge) {
          return;
        } else if (currentUse) {
          dispatch(actions.remove(currentUse.url));
        }
        const id = uuid();
        const url = instanceURL("krPerLiterDefaultFuelSurchargeUse", id);
        const instance: KrPerLiterDefaultFuelSurchargeUse = {
          customer: customerUrl,
          fuelSurcharge: value,
          id,
          url,
        };
        dispatch(actions.create(instance));
      },
      [currentUse, customerUrl, dispatch],
    );

    let text: string;
    if (currentUse) {
      if (!currentUse.fuelSurcharge) {
        text = intl.formatMessage({
          defaultMessage: "Ingen brændstoftillæg",
        });
      } else {
        const fuelSurcharge = krPerLiterFuelSurchargeSpecificationLookup(currentUse.fuelSurcharge);
        if (fuelSurcharge) {
          text = fuelSurcharge.name;
        } else {
          text = intl.formatMessage({defaultMessage: "Opslag fejlet"});
        }
      }
    } else {
      text = "";
    }

    return (
      <>
        <Card>
          <CardHeader
            title={intl.formatMessage({
              defaultMessage: "Kundespecifikt standard-brændstoftillæg",
            })}
          />
          <CardContent>
            <Button color="primary" variant="contained" onClick={setFuelSurchargeDialogOpenTrue}>
              <FormattedMessage defaultMessage="Vælg" />
            </Button>
            <br />
            {text}
            {currentUse ? (
              <IconButton onClick={handleClearButton}>
                <CloseIcon />
              </IconButton>
            ) : null}
          </CardContent>
        </Card>
        <ConnectedKrPerLiterFuelSurchargeSpecificationDialog
          open={fuelSurchargeDialogOpen}
          onCancel={setFuelSurchargeDialogOpenFalse}
          onNone={handleFuelSurchargeDialogOk}
          onOk={handleFuelSurchargeDialogOk}
        />
      </>
    );
  },
);
