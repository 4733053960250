import {useResettingState} from "@co-frontend-libs/utils";
import {CircularProgress} from "@material-ui/core";
import React from "react";
import {ResponsiveDialog, ResponsiveDialogProps} from "../responsive-dialog";

export type SpinnerDialogProps = Pick<ResponsiveDialogProps, "open" | "title">;

export function SpinnerDialog(props: SpinnerDialogProps): React.JSX.Element {
  const {open, title} = props;
  const [cachedTitle] = useResettingState(title, open);

  return (
    <ResponsiveDialog open={open} title={cachedTitle}>
      <div style={{textAlign: "center"}}>
        <CircularProgress />
      </div>
    </ResponsiveDialog>
  );
}
