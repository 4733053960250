import {
  Command,
  ResourceName,
  ResourceTypes,
  resourceNameFor,
  resourceNames,
} from "@co-common-libs/resources";
import {Selector, createSelector} from "reselect";
import {
  CommitEntry,
  CreateCommandPromise,
  ResourceInstanceRecords,
  StateWithResources,
} from "../types";
import {ResourceCommitCommands} from "../utils";

export function getCommitQueue(state: StateWithResources): readonly CommitEntry[] {
  return state.resources.commitQueue;
}

function getLength(arr: readonly unknown[]): number {
  return arr.length;
}

export const getCommitQueueLength: Selector<StateWithResources, number> = createSelector(
  getCommitQueue,
  getLength,
);

export function buildCommittingPerResource(
  commitQueue: readonly CommitEntry[],
): ResourceCommitCommands {
  const result = Object.fromEntries(
    resourceNames.map((resourceName): [ResourceName, (Command | CreateCommandPromise)[]] => [
      resourceName,
      [],
    ]),
  ) as {
    [P in keyof ResourceTypes]: (Command | CreateCommandPromise)[];
  };
  commitQueue.forEach((commitEntry) => {
    const {command} = commitEntry;
    const {url} = command;
    const resourceName = resourceNameFor(url);
    result[resourceName].push(command);
  });
  return result;
}

export const getCommittingPerResource: Selector<StateWithResources, ResourceCommitCommands> =
  createSelector(getCommitQueue, buildCommittingPerResource);

export function combineOfflineData<T extends ResourceName>(
  persistedData: ResourceInstanceRecords[T],
  temporaryData: ResourceInstanceRecords[T],
): ResourceInstanceRecords[T] {
  if (!Object.keys(temporaryData).length) {
    return persistedData;
  } else if (!Object.keys(persistedData).length) {
    return temporaryData;
  } else {
    return {...persistedData, ...temporaryData};
  }
}

function recordToArray<T>(
  record: Partial<{
    readonly [url: string]: T;
  }>,
): readonly T[] {
  const values = Object.values(record);
  console.assert(values.indexOf(undefined) === -1);
  return values as T[];
}

export function recordToArraySelector<T>(
  selector: Selector<
    StateWithResources,
    Partial<{
      readonly [url: string]: T;
    }>
  >,
): Selector<StateWithResources, readonly T[]> {
  return createSelector(selector, recordToArray);
}

function recordToLookup<T>(
  record: Partial<{
    readonly [url: string]: T;
  }>,
): (url: string) => T | undefined {
  return (url) => record[url];
}

export function recordToLookupSelector<T>(
  selector: Selector<
    StateWithResources,
    Partial<{
      readonly [url: string]: T;
    }>
  >,
): Selector<StateWithResources, (url: string) => T | undefined> {
  return createSelector(selector, recordToLookup);
}

function mapToLookup<K, V>(map: ReadonlyMap<K, V>): (url: K) => V | undefined {
  return map.get.bind(map);
}

export function mapToLookupSelector<K, V, S = any>(
  selector: Selector<S, ReadonlyMap<K, V>>,
): Selector<S, (url: K) => V | undefined> {
  return createSelector(selector, mapToLookup);
}
