import {isNoDefaultEnterPress} from "@co-frontend-libs/utils";
import {
  Button,
  ButtonBaseActions,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogProps,
  DialogTitle,
  IconButton,
  Theme,
  Toolbar,
  Typography,
  colors,
  createStyles,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import {TransitionHandlerProps} from "@material-ui/core/transitions";
import bowser from "bowser";
import CloseIcon from "mdi-react/CloseIcon";
import React, {useCallback, useEffect, useMemo, useRef} from "react";
import {FormattedMessage} from "react-intl";
import {ErrorColorButton} from "./error-color-button";
import {OfflineAwareAppBar} from "./offline-aware-app-bar";
import {SlideUpTransition} from "./slide-up-transition";

const closeTitleSpacing = 2;

export interface ResponsiveDialogProps extends TransitionHandlerProps {
  autoFocusCancel?: boolean;
  autoFocusOk?: boolean;
  cancelDisabled?: boolean;
  cancelLabel?: JSX.Element | string | undefined;
  children?: React.ReactNode;
  deleteDisabled?: boolean;
  deleteLabel?: JSX.Element | string;
  enterHandling?: boolean;
  errorColor?: boolean;
  errorMessage?: string | undefined;
  fullscreen?: boolean | undefined;
  fullWidth?: boolean;
  hideActions?: boolean;
  maxWidth?: "lg" | "md" | "sm" | "xl" | "xs" | false;
  okDisabled?: boolean;
  okLabel?: JSX.Element | string;
  onCancel?: (() => void) | undefined;
  onDelete?: (() => void) | undefined;
  onOk?: (() => void) | undefined;
  open: boolean;
  requiredFieldsHelperText?: boolean;
  title: JSX.Element | string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative",
    },
    error: {color: theme.palette.error.main},
    title: {
      flex: 1,
      marginLeft: theme.spacing(closeTitleSpacing),
    },
  }),
);

// NTS: React.memo here is pointless; expected use is with children
// specified with JSX, which always give new instances...
function ResponsiveDialog(props: ResponsiveDialogProps): JSX.Element {
  const {
    autoFocusCancel,
    autoFocusOk,
    cancelDisabled,
    cancelLabel,
    children,
    deleteDisabled,
    deleteLabel,
    enterHandling,
    errorColor,
    errorMessage,
    fullscreen,
    fullWidth,
    hideActions = false,
    maxWidth,
    okDisabled,
    okLabel,
    onCancel,
    onDelete,
    onEnter,
    onEntered,
    onEntering,
    onExit,
    onExited,
    onExiting,
    onOk,
    open,
    requiredFieldsHelperText,
    title,
  } = props;
  const classes = useStyles();

  const cachedContent = useRef<React.ReactNode>();

  const theme = useTheme();

  if (open) {
    cachedContent.current = children;
  }

  const handleKeyPress = useCallback(
    (event: KeyboardEvent): void => {
      if (isNoDefaultEnterPress(event)) {
        if (autoFocusCancel) {
          if (onCancel && !cancelDisabled) {
            event.preventDefault();
            onCancel();
          }
        } else {
          if (onOk && !okDisabled) {
            event.preventDefault();
            onOk();
          }
        }
      }
    },
    [autoFocusCancel, cancelDisabled, okDisabled, onCancel, onOk],
  );

  useEffect(() => {
    if (!open || enterHandling === false) {
      return undefined;
    }
    window.addEventListener("keypress", handleKeyPress);
    return () => {
      window.removeEventListener("keypress", handleKeyPress);
    };
  }, [enterHandling, handleKeyPress, open]);

  const focusButtonRef = useRef<ButtonBaseActions | null>(null);

  const focusActionCallback = useCallback((instance: ButtonBaseActions | null) => {
    if (instance !== focusButtonRef.current) {
      focusButtonRef.current = instance;
      if (instance) {
        // autoFocus "works"; it does set focus;
        // but this is necessary to also show the visual indication...
        instance.focusVisible();
      }
    }
  }, []);

  const optionalCallbacks: Partial<DialogProps> = {};
  if (onCancel) {
    optionalCallbacks.onClose = onCancel;
  }
  if (onEnter) {
    optionalCallbacks.onEnter = onEnter;
  }
  if (onEntered) {
    optionalCallbacks.onEntered = onEntered;
  }
  if (onEntering) {
    optionalCallbacks.onEntering = onEntering;
  }
  if (onExit) {
    optionalCallbacks.onExit = onExit;
  }
  if (onExited) {
    optionalCallbacks.onExited = onExited;
  }
  if (onExiting) {
    optionalCallbacks.onExiting = onExiting;
  }

  const handleOk = useCallback(() => {
    if (open && onOk) {
      onOk();
    }
  }, [onOk, open]);

  const requiredFieldsAndErrorMessages = useMemo(() => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",

          paddingLeft: theme.spacing(3),

          paddingRight: theme.spacing(3),
          width: "100%",
        }}
      >
        <div style={{flexGrow: 1}}>
          {requiredFieldsHelperText ? (
            <div
              style={{
                padding: theme.spacing(1),
              }}
            >
              <FormattedMessage defaultMessage="* Skal udfyldes" />
            </div>
          ) : null}
          {errorMessage ? (
            <div
              style={{
                color: colors.red[800],
                overflowWrap: "break-word",
                padding: theme.spacing(1),
              }}
            >
              {errorMessage}
            </div>
          ) : null}
        </div>
      </div>
    );
  }, [errorMessage, requiredFieldsHelperText, theme]);

  const fullscreenLayout = fullscreen ?? !!(bowser.mobile || bowser.tablet);
  if (fullscreenLayout) {
    return (
      <Dialog fullScreen open={open} TransitionComponent={SlideUpTransition} {...optionalCallbacks}>
        <OfflineAwareAppBar className={classes.appBar}>
          <Toolbar>
            {onCancel ? (
              <IconButton
                aria-label="close"
                color="inherit"
                disabled={cancelDisabled || false}
                edge="start"
                onClick={onCancel}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
            <Typography className={classes.title} variant="h6">
              {title}
            </Typography>
            {onOk ? (
              <Button
                key={
                  bowser.ios || bowser.safari
                    ? okDisabled
                      ? "okDisabled"
                      : "okNotDisabled"
                    : (undefined as any)
                } // Hack to get safari to rerender button
                color="inherit"
                disabled={okDisabled || false}
                onClick={handleOk}
              >
                {okLabel ?? <FormattedMessage defaultMessage="OK" id="dialog.label.ok" />}
              </Button>
            ) : null}
          </Toolbar>
        </OfflineAwareAppBar>
        {/* <div> is necessary to avoid minimum height */}
        <div>
          {cachedContent.current}
          {requiredFieldsAndErrorMessages}
        </div>
        {onDelete ? (
          <DialogActions>
            <ErrorColorButton
              key={
                bowser.ios || bowser.safari
                  ? deleteDisabled
                    ? "deleteDisabled"
                    : "deleteNotDisabled"
                  : (undefined as any)
              } // Hack to get safari to rerender button
              color="primary"
              disabled={deleteDisabled || false}
              onClick={onDelete}
            >
              {deleteLabel ?? <FormattedMessage defaultMessage="Slet" id="dialog.label.delete" />}
            </ErrorColorButton>
          </DialogActions>
        ) : null}
      </Dialog>
    );
  } else {
    const widthProps: Partial<DialogProps> = {};
    if (fullWidth !== undefined) {
      widthProps.fullWidth = fullWidth;
    }
    if (maxWidth !== undefined) {
      widthProps.maxWidth = maxWidth;
    }

    const cancelButtonFocusProps: Partial<ButtonProps> = {};
    if (autoFocusCancel) {
      cancelButtonFocusProps.action = focusActionCallback;
      cancelButtonFocusProps.autoFocus = true;
    }
    const okButtonFocusProps: Partial<ButtonProps> = {};
    if (autoFocusOk) {
      okButtonFocusProps.action = focusActionCallback;
      okButtonFocusProps.autoFocus = true;
    }

    return (
      <Dialog open={open} {...widthProps} {...optionalCallbacks}>
        <DialogTitle>
          <span className={errorColor ? classes.error : ""}>{title}</span>
        </DialogTitle>

        {cachedContent.current}
        {requiredFieldsAndErrorMessages}

        {!hideActions && (
          <DialogActions>
            {onDelete ? (
              <ErrorColorButton
                key={
                  bowser.ios || bowser.safari
                    ? deleteDisabled
                      ? "deleteDisabled"
                      : "deleteNotDisabled"
                    : (undefined as any)
                } // Hack to get safari to rerender button
                disabled={deleteDisabled || false}
                onClick={onDelete}
              >
                {deleteLabel ?? <FormattedMessage defaultMessage="Slet" id="dialog.label.delete" />}
              </ErrorColorButton>
            ) : null}
            {onCancel ? (
              <Button
                key={
                  bowser.ios || bowser.safari
                    ? cancelDisabled
                      ? "cancelDisabled"
                      : "cancelNotDisabled"
                    : (undefined as any)
                } // Hack to get safari to rerender button
                {...cancelButtonFocusProps}
                color="primary"
                disabled={cancelDisabled || false}
                onClick={onCancel}
              >
                {cancelLabel ?? (
                  <FormattedMessage defaultMessage="Fortryd" id="dialog.label.cancel" />
                )}
              </Button>
            ) : null}
            {onOk ? (
              <Button
                key={
                  bowser.ios || bowser.safari
                    ? okDisabled
                      ? "okDisabled"
                      : "okNotDisabled"
                    : (undefined as any)
                } // Hack to get safari to rerender button
                {...okButtonFocusProps}
                className={errorColor ? classes.error : ""}
                color="primary"
                disabled={okDisabled || false}
                onClick={handleOk}
              >
                {okLabel ?? <FormattedMessage defaultMessage="OK" id="dialog.label.ok" />}
              </Button>
            ) : null}
          </DialogActions>
        )}
      </Dialog>
    );
  }
}

export {ResponsiveDialog};
