import {actions, getQueryParameters, makePathParameterGetter} from "@co-frontend-libs/redux";
import {useCallback, useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";

export function useQueryParameterState<LegalValue extends string = string>(
  key: string,
  defaultValue: LegalValue,
  alwaysWriteToQueryParam: boolean = false,
): [LegalValue, (newValue: LegalValue) => void] {
  const dispatch = useDispatch();

  const queryParameters = useSelector(getQueryParameters);
  const currentValue = (queryParameters[key] ?? defaultValue) as LegalValue;

  const setQueryParameter = useCallback(
    (newValue: LegalValue) => {
      if (newValue === defaultValue && !alwaysWriteToQueryParam) {
        dispatch(actions.deleteQueryKey(key));
      } else {
        dispatch(actions.putQueryKey(key, newValue));
      }
    },
    [defaultValue, dispatch, key, alwaysWriteToQueryParam],
  );

  const triggerWriteDefault = alwaysWriteToQueryParam && queryParameters[key] === undefined;

  useEffect(() => {
    if (triggerWriteDefault) {
      setQueryParameter(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerWriteDefault]);

  return useMemo(
    (): [LegalValue, (newValue: LegalValue) => void] => [currentValue, setQueryParameter],
    [currentValue, setQueryParameter],
  );
}

export function useNullableQueryParameterState(
  key: string,
  defaultValue: string | null,
  alwaysWriteToQueryParam: boolean = false,
): [string | null, (newValue: string | null) => void] {
  const [baseValue, baseSetValue] = useQueryParameterState<string>(
    key,
    defaultValue || "",
    alwaysWriteToQueryParam,
  );
  const value = baseValue || null;
  const setValue = useCallback(
    (newValue: string | null): void => {
      baseSetValue(newValue || "");
    },
    [baseSetValue],
  );

  const result = useMemo(
    (): [string | null, (newValue: string | null) => void] => [value, setValue],
    [setValue, value],
  );
  return result;
}

export function useNumericQueryParameterState(
  key: string,
  defaultValue: number,
  alwaysWriteToQueryParam: boolean = false,
): [number, (newValue: number) => void] {
  const [baseValue, baseSetValue] = useQueryParameterState<string>(
    key,
    `${defaultValue}`,
    alwaysWriteToQueryParam,
  );
  const value = parseFloat(baseValue);
  const setValue = useCallback(
    (newValue: number): void => {
      baseSetValue(`${newValue}`);
    },
    [baseSetValue],
  );

  const result = useMemo(
    (): [number, (newValue: number) => void] => [value, setValue],
    [setValue, value],
  );
  return result;
}

export function useBooleanQueryParameterState(
  key: string,
  alwaysWriteToQueryParam: boolean = false,
): [boolean, (newValue: boolean) => void] {
  const [baseValue, baseSetValue] = useQueryParameterState<"" | "x">(
    key,
    "x",
    alwaysWriteToQueryParam,
  );
  const value = baseValue !== "x";
  const setValue = useCallback(
    (newValue: boolean): void => {
      baseSetValue(newValue ? "" : "x");
    },
    [baseSetValue],
  );
  const result = useMemo(
    (): [boolean, (newValue: boolean) => void] => [value, setValue],
    [setValue, value],
  );
  return result;
}

export function useIDListQueryParameterState(
  key: string,
  defaultValue: string[],
  alwaysWriteToQueryParam: boolean = false,
): [string[], (newValue: string[]) => void] {
  const [baseValue, baseSetValue] = useQueryParameterState<string>(
    key,
    defaultValue.join(","),
    alwaysWriteToQueryParam,
  );
  const value = useMemo(() => (baseValue ? baseValue.split(",") : []), [baseValue]);
  const setValue = useCallback(
    (newValue: string[]): void => {
      baseSetValue(newValue.join(","));
    },
    [baseSetValue],
  );

  const result = useMemo(
    (): [string[], (newValue: string[]) => void] => [value, setValue],
    [setValue, value],
  );
  return result;
}

export function usePathParameter(key: string): string {
  const parameterGetter = useMemo(() => makePathParameterGetter(key), [key]);
  return useSelector(parameterGetter);
}
