import {Product, ProductUrl, ProductUseLog, Task} from "@co-common-libs/resources";
import _ from "lodash";

export const readProductUseLog = (
  productUseLogArray: readonly ProductUseLog[],
  productLookup: (url: ProductUrl) => Product | undefined,
  task: Pick<Task, "machineOperator" | "priceGroup" | "workType">,
): ProductUrl[] => {
  const workTypeURL = task.workType;
  const priceGroupURL = task.priceGroup;
  const machineOperatorURL = task.machineOperator;
  const worktypeProductUseLogList = _.sortBy(
    productUseLogArray.filter((m) => m.worktype === workTypeURL && m.user === machineOperatorURL),
    (m) => m.lastChanged,
  );
  if (!worktypeProductUseLogList.length) {
    return [];
  }
  let productUseLog = _.findLast(worktypeProductUseLogList, (m) => m.priceGroup === priceGroupURL);
  if (!productUseLog) {
    productUseLog = _.findLast(worktypeProductUseLogList, (m) => m.priceGroup === null);
  }
  if (!productUseLog) {
    productUseLog = worktypeProductUseLogList[worktypeProductUseLogList.length - 1];
  }
  return productUseLog.products.filter((productURL) => {
    const product = productLookup(productURL);
    return product && product.active;
  });
};
