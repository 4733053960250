import {
  Customer,
  KrPerLiterFuelSurchargeSpecificationUrl,
  KrPerLiterMachineFuelSurchargeUse,
  Machine,
  MachineUrl,
} from "@co-common-libs/resources";
import {notNull} from "@co-common-libs/utils";
import {
  ConnectedKrPerLiterFuelSurchargeSpecificationDialog,
  ConnectedMachineDialog,
} from "@co-frontend-libs/connected-components";
import {
  actions,
  getKrPerLiterFuelSurchargeSpecificationLookup,
  getKrPerLiterMachineFuelSurchargeUseArray,
  getMachineLookup,
} from "@co-frontend-libs/redux";
import {
  Card,
  CardHeader,
  Fab,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import CloseIcon from "mdi-react/CloseIcon";
import PlusIcon from "mdi-react/PlusIcon";
import React, {useCallback, useMemo, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuid} from "uuid";

interface MachineRowProps {
  machine: Machine;
  surchargeUse: KrPerLiterMachineFuelSurchargeUse;
}

function MachineRow(props: MachineRowProps): JSX.Element {
  const {machine, surchargeUse} = props;

  const krPerLiterFuelSurchargeSpecificationLookup = useSelector(
    getKrPerLiterFuelSurchargeSpecificationLookup,
  );

  const dispatch = useDispatch();
  const intl = useIntl();

  const handleClearButton = useCallback(() => {
    dispatch(actions.remove(surchargeUse.url));
  }, [surchargeUse, dispatch]);

  let text: string;

  if (!surchargeUse.fuelSurcharge) {
    text = intl.formatMessage({
      defaultMessage: "Ingen brændstoftillæg for maskine",
    });
  } else {
    const fuelSurcharge = krPerLiterFuelSurchargeSpecificationLookup(surchargeUse.fuelSurcharge);
    if (fuelSurcharge) {
      text = fuelSurcharge.name;
    } else {
      text = intl.formatMessage({defaultMessage: "Opslag fejlet"});
    }
  }

  return (
    <TableRow>
      <TableCell>{machine.name}</TableCell>
      <TableCell>
        {text}
        <IconButton onClick={handleClearButton}>
          <CloseIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

interface CustomerMachineKrPerLiterFuelSurchargeCardProps {
  customer: Customer;
}

export const CustomerMachineKrPerLiterFuelSurchargeCard = React.memo(
  function CustomerMachineKrPerLiterFuelSurchargeCard(
    props: CustomerMachineKrPerLiterFuelSurchargeCardProps,
  ): JSX.Element {
    const {customer} = props;
    const customerUrl = customer.url;

    const intl = useIntl();

    const machineLookup = useSelector(getMachineLookup);

    const krPerLiterMachineFuelSurchargeUseArray = useSelector(
      getKrPerLiterMachineFuelSurchargeUseArray,
    );

    const dispatch = useDispatch();

    const [machineDialogOpen, setMachineDialogOpen] = useState(false);
    const [fuelSurchargeDialogOpenForMachine, setFuelSurchargeDialogOpenForMachine] =
      useState<MachineUrl>();

    const handleAddClick = useCallback(() => {
      setMachineDialogOpen(true);
    }, []);
    const handleMachineDialogCancel = useCallback(() => {
      setMachineDialogOpen(false);
    }, []);

    const handleMachineDialogOk = useCallback((url: MachineUrl) => {
      setMachineDialogOpen(false);
      setFuelSurchargeDialogOpenForMachine(url);
    }, []);

    const handleFuelSurchargeDialogCancel = useCallback(() => {
      setFuelSurchargeDialogOpenForMachine(undefined);
    }, []);

    const handleFuelSurchargeDialogOk = useCallback(
      (fuelSurchargeUrl?: KrPerLiterFuelSurchargeSpecificationUrl): void => {
        setFuelSurchargeDialogOpenForMachine(undefined);
        if (fuelSurchargeDialogOpenForMachine) {
          const value = fuelSurchargeUrl ?? null;
          const currentUse = krPerLiterMachineFuelSurchargeUseArray.find(
            (surchargeUse) =>
              surchargeUse.customer === customerUrl &&
              surchargeUse.machine === fuelSurchargeDialogOpenForMachine,
          );
          if (currentUse && value === currentUse.fuelSurcharge) {
            return;
          } else if (currentUse) {
            dispatch(actions.remove(currentUse.url));
          }
          const id = uuid();
          const url = instanceURL("krPerLiterMachineFuelSurchargeUse", id);
          const instance: KrPerLiterMachineFuelSurchargeUse = {
            customer: customerUrl,
            fuelSurcharge: value,
            id,
            machine: fuelSurchargeDialogOpenForMachine,
            url,
            variant: null,
          };
          dispatch(actions.create(instance));
        }
      },
      [
        customerUrl,
        dispatch,
        fuelSurchargeDialogOpenForMachine,
        krPerLiterMachineFuelSurchargeUseArray,
      ],
    );

    const customerMachineFuelSurchargeUses = useMemo(
      () =>
        krPerLiterMachineFuelSurchargeUseArray.filter(
          (surchargeUse) => surchargeUse.customer === customerUrl,
        ),
      [customerUrl, krPerLiterMachineFuelSurchargeUseArray],
    );

    const data = useMemo(
      () =>
        _.sortBy(
          customerMachineFuelSurchargeUses
            .map((surchargeUse) => {
              const machine = machineLookup(surchargeUse.machine);
              if (machine) {
                return {
                  machine,
                  surchargeUse,
                };
              } else {
                return null;
              }
            })
            .filter(notNull),
          [({machine}) => machine.name],
        ),
      [customerMachineFuelSurchargeUses, machineLookup],
    );

    const rows = data.map(({machine, surchargeUse}) => (
      <MachineRow key={surchargeUse.url} machine={machine} surchargeUse={surchargeUse} />
    ));

    return (
      <>
        <Card>
          <CardHeader
            action={
              <Fab size="small" onClick={handleAddClick}>
                <PlusIcon />
              </Fab>
            }
            title={intl.formatMessage({
              defaultMessage: "Kundespecifikt maskine-brændstoftillæg",
            })}
          />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage defaultMessage="Maskine" />
                </TableCell>
                <TableCell>
                  <FormattedMessage defaultMessage="Brændstoftillæg" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{rows}</TableBody>
          </Table>
        </Card>
        <ConnectedMachineDialog
          open={machineDialogOpen}
          onCancel={handleMachineDialogCancel}
          onOk={handleMachineDialogOk}
        />
        <ConnectedKrPerLiterFuelSurchargeSpecificationDialog
          open={!!fuelSurchargeDialogOpenForMachine}
          onCancel={handleFuelSurchargeDialogCancel}
          onNone={handleFuelSurchargeDialogOk}
          onOk={handleFuelSurchargeDialogOk}
        />
      </>
    );
  },
);
