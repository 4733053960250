import {Role} from "@co-common-libs/resources";

export function roleIsManager(role: Pick<Role, "manager" | "projectManager"> | null): boolean {
  return !!role && role.manager && !role.projectManager;
}

export function roleIsProjectManager(
  role: Pick<Role, "manager" | "projectManager"> | null,
): boolean {
  return !!role && role.manager && role.projectManager;
}
