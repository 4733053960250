import {LocationUrl} from "@co-common-libs/resources";
import {notUndefined, setRemove} from "@co-common-libs/utils";
import {MultiLocationDialog} from "@co-frontend-libs/components";
import {getCustomerLookup, getLocationLookup} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {Button, useTheme} from "@material-ui/core";
import _ from "lodash";
import React, {useCallback, useMemo, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {DeletableChip} from "./deletable-chip";

interface LocationFilteringProps {
  onSelectedChange: (urls: ReadonlySet<LocationUrl>) => void;
  options: readonly LocationUrl[];
  selected: ReadonlySet<LocationUrl>;
}

export function LocationFiltering(props: LocationFilteringProps): JSX.Element {
  const {onSelectedChange, options, selected} = props;

  const customerLookup = useSelector(getCustomerLookup);
  const locationLookup = useSelector(getLocationLookup);

  const [dialogOpen, setDialogOpen] = useState(false);
  const setDialogOpenTrue = useCallWithTrue(setDialogOpen);
  const setDialogOpenFalse = useCallWithFalse(setDialogOpen);

  const handleDialogOk = useCallback(
    (urls: ReadonlySet<LocationUrl>) => {
      onSelectedChange(urls);
      setDialogOpen(false);
    },
    [onSelectedChange],
  );
  const potentialLocationInstances = useMemo(
    () => options.map(locationLookup).filter(notUndefined),
    [locationLookup, options],
  );

  const handleDelete = useCallback(
    (url: LocationUrl): void => {
      onSelectedChange(setRemove(selected, url));
    },
    [selected, onSelectedChange],
  );

  const theme = useTheme();

  return (
    <>
      <div>
        <h4>
          <FormattedMessage defaultMessage="Steder" id="storage-journal.header.locations" />
        </h4>
        <Button color="primary" variant="contained" onClick={setDialogOpenTrue}>
          <FormattedMessage
            defaultMessage="Vælg steder"
            id="storage-journal.label.select-locations"
          />
        </Button>
        <div>
          {_.sortBy(
            Array.from(selected).map(locationLookup).filter(notUndefined),
            (location) => location.name,
          ).map((location) => (
            <span
              key={location.url}
              style={{
                marginRight: theme.spacing(0.5),
              }}
            >
              <DeletableChip
                deletionId={location.url}
                label={location.name || location.address || ""}
                onDelete={handleDelete}
              />
            </span>
          ))}
        </div>
      </div>
      <MultiLocationDialog
        includeLogOnlyLocations
        includeWorkplaceOnlyLocations
        locationCrossCustomerSelectionEnabled
        customerLookup={customerLookup}
        customerURL={null}
        includeSelectAll={false}
        locationArray={potentialLocationInstances}
        locationFavoritesEnabled={false}
        onlyActive={false}
        open={dialogOpen}
        selected={selected}
        titleVariant="LOCATION"
        onCancel={setDialogOpenFalse}
        onOk={handleDialogOk}
      />
    </>
  );
}
