interface FloatingActionButtonDataBase {
  buttonIcon: JSX.Element;
  name: string;
  tooltipTitle: string;
}

interface NormalFloatingActionButtonData extends FloatingActionButtonDataBase {
  onClick: () => void;
}

interface FileInputFloatingActionButtonData extends FloatingActionButtonDataBase {
  accept: string;
  id: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export type FloatingActionButtonData =
  | FileInputFloatingActionButtonData
  | NormalFloatingActionButtonData;

export function isNormalFloatingActionButtonData(
  buttonProps: FloatingActionButtonData,
): buttonProps is NormalFloatingActionButtonData {
  return (buttonProps as NormalFloatingActionButtonData).onClick !== undefined;
}
