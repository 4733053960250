export function simpleCompare(valueA: string, valueB: string): -1 | 0 | 1;
export function simpleCompare(valueA: boolean, valueB: boolean): -1 | 0 | 1;
export function simpleCompare(valueA: number, valueB: number): -1 | 0 | 1;
export function simpleCompare<T>(valueA: T, valueB: T): -1 | 0 | 1 {
  if (valueA < valueB) {
    return -1;
  } else if (valueB < valueA) {
    return 1;
  } else {
    return 0;
  }
}
