import {
  Machine,
  MachineGroup,
  MachineGroupUrl,
  MachineUrl,
  PriceGroup,
  WorkType,
} from "@co-common-libs/resources";
import {BarcodeFormat, memoizeForceReuse} from "@co-frontend-libs/utils";
import React, {useMemo} from "react";
import {defineMessages, useIntl} from "react-intl";
import {
  EntryData,
  GenericMultiSelectionSearchDialog,
  GenericSingleSelectionSearchDialog,
} from "../search-dialog";

const messages = defineMessages({
  group: {
    defaultMessage: "Gruppe",
    id: "machine-dialog.match-label.machine-group",
  },
  machineNumber: {
    defaultMessage: "Maskinnr.",
  },
  name: {
    defaultMessage: "Navn",
    id: "machine-dialog.match-label.machine-name",
  },
  searchMachine: {
    defaultMessage: "Søg maskine",
  },
  searchMachines: {
    defaultMessage: "Søg maskiner",
  },
  searchVehicle: {
    defaultMessage: "Søg køretøj",
  },
  searchVehicles: {
    defaultMessage: "Søg køretøjer",
  },
  selectMachine: {
    defaultMessage: "Vælg maskine",
  },
  selectMachines: {
    defaultMessage: "Vælg maskiner",
  },
  selectVehicle: {
    defaultMessage: "Vælg køretøj",
  },
  selectVehicles: {
    defaultMessage: "Vælg køretøjer",
  },
  vehicleNumber: {
    defaultMessage: "Køretøjsnr.",
  },
});

function computeBaseChoices(
  machineNumberLabel: string,
  nameLabel: string,
  groupLabel: string,
  workType: WorkType | undefined,
  machineArray: readonly Machine[],
  priceGroup: PriceGroup | undefined,
  machineGroupLookup?: (url: MachineGroupUrl) => MachineGroup | undefined,
): readonly EntryData<MachineUrl>[] {
  const preferredMachines = [...(workType?.machines || []), ...(priceGroup?.machines || [])];
  return machineArray.map((instance) => {
    const {name, url} = instance;
    const machineID = instance.c5_machine;
    const machineGroupURL = instance.machineGroup || undefined;
    const machineGroup =
      machineGroupURL && machineGroupLookup ? machineGroupLookup(machineGroupURL) : undefined;
    const machineGroupName = machineGroup?.name;
    const entry: EntryData<MachineUrl> = {
      category: preferredMachines?.includes(url) ? "favorite" : "standard",
      exactMatchValue: machineID,
      identifier: url,
      primaryText: name,
      searchFields: [
        {label: machineNumberLabel, priority: 10, text: machineID},
        {label: nameLabel, priority: 5, text: name},
        {label: groupLabel, priority: 2, text: machineGroupName || ""},
      ],
      secondaryText: machineGroupName ? `${machineID}, ${machineGroupName}` : machineID,
    };
    return entry;
  });
}

interface MachineDialogProps {
  barcodeScannerFormats?: readonly BarcodeFormat[] | null | undefined;
  machineArray: readonly Machine[];
  machineGroupLookup?: (url: MachineGroupUrl) => MachineGroup | undefined;
  machineLabelVariant: "MACHINE" | "VEHICLE";
  onCancel(): void;
  onOk(url: MachineUrl): void;
  open: boolean;
  priceGroup?: PriceGroup | undefined;
  workType?: WorkType | undefined;
}

export const MachineDialog = React.memo(function MachineDialog(props: MachineDialogProps) {
  const {
    barcodeScannerFormats,
    machineArray,
    machineGroupLookup,
    machineLabelVariant,
    onCancel,
    onOk,
    open,
    priceGroup,
    workType,
  } = props;
  const intl = useIntl();
  const title =
    machineLabelVariant === "MACHINE"
      ? intl.formatMessage(messages.selectMachine)
      : intl.formatMessage(messages.selectVehicle);
  const searchTitle =
    machineLabelVariant === "MACHINE"
      ? intl.formatMessage(messages.searchMachine)
      : intl.formatMessage(messages.searchVehicle);

  const [doComputeBaseChoices, reuseBaseChoices] = useMemo(
    () => memoizeForceReuse(computeBaseChoices, []),
    [],
  );
  const getBaseChoices = open ? doComputeBaseChoices : reuseBaseChoices;

  const machineNumberLabel =
    machineLabelVariant === "MACHINE"
      ? intl.formatMessage(messages.machineNumber)
      : intl.formatMessage(messages.vehicleNumber);
  const nameLabel = intl.formatMessage(messages.name);
  const groupLabel = intl.formatMessage(messages.group);

  const data = getBaseChoices(
    machineNumberLabel,
    nameLabel,
    groupLabel,
    workType,
    machineArray,
    priceGroup,
    machineGroupLookup,
  );

  return (
    <GenericSingleSelectionSearchDialog<MachineUrl>
      barcodeScannerFormats={barcodeScannerFormats}
      data={data}
      mobilePrimaryLines={1}
      mobileSearchPrimaryLines={1}
      mobileSearchSecondaryLines={1}
      mobileSecondaryLines={1}
      open={open}
      searchTitle={searchTitle}
      sorting="SECONDARY_IDENTIFIER"
      title={title}
      onCancel={onCancel}
      onOk={onOk}
    />
  );
});

interface MultipleMachinesDialogProps {
  barcodeScannerFormats?: readonly BarcodeFormat[] | null | undefined;
  includeSelectAll?: boolean;
  machineArray: readonly Machine[];
  machineGroupLookup?: (url: MachineGroupUrl) => MachineGroup | undefined;
  machineLabelVariant: "MACHINE" | "VEHICLE";
  onCancel(): void;
  onOk(urls: ReadonlySet<MachineUrl>): void;
  open: boolean;
  priceGroup?: PriceGroup;
  selected?: ReadonlySet<MachineUrl> | undefined;
  workType?: WorkType;
}

export const MultipleMachinesDialog = React.memo(function MultipleMachinesDialog(
  props: MultipleMachinesDialogProps,
) {
  const {
    barcodeScannerFormats,
    includeSelectAll,
    machineArray,
    machineGroupLookup,
    machineLabelVariant,
    onCancel,
    onOk,
    open,
    priceGroup,
    selected,
    workType,
  } = props;
  const intl = useIntl();
  const title =
    machineLabelVariant === "MACHINE"
      ? intl.formatMessage(messages.selectMachines)
      : intl.formatMessage(messages.selectVehicles);
  const searchTitle =
    machineLabelVariant === "MACHINE"
      ? intl.formatMessage(messages.searchMachines)
      : intl.formatMessage(messages.searchVehicles);

  const [doComputeBaseChoices, reuseBaseChoices] = useMemo(
    () => memoizeForceReuse(computeBaseChoices, []),
    [],
  );
  const getBaseChoices = open ? doComputeBaseChoices : reuseBaseChoices;

  const machineNumberLabel =
    machineLabelVariant === "MACHINE"
      ? intl.formatMessage(messages.machineNumber)
      : intl.formatMessage(messages.vehicleNumber);
  const nameLabel = intl.formatMessage(messages.name);
  const groupLabel = intl.formatMessage(messages.group);

  const data = getBaseChoices(
    machineNumberLabel,
    nameLabel,
    groupLabel,
    workType,
    machineArray,
    priceGroup,
    machineGroupLookup,
  );

  return (
    <GenericMultiSelectionSearchDialog<MachineUrl>
      barcodeScannerFormats={barcodeScannerFormats}
      data={data}
      includeSelectAll={includeSelectAll}
      mobilePrimaryLines={1}
      mobileSearchPrimaryLines={1}
      mobileSearchSecondaryLines={1}
      mobileSecondaryLines={1}
      open={open}
      searchTitle={searchTitle}
      selected={selected}
      sorting="SECONDARY_IDENTIFIER"
      title={title}
      onCancel={onCancel}
      onOk={onOk}
    />
  );
});
