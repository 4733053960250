import {createReducer} from "@reduxjs/toolkit";
import * as actions from "./actions";

export type UpdatesState = {
  readonly checkingForUpdates: boolean;
  readonly updateReady: boolean;
  readonly updateRequired: boolean;
};

const initialState: UpdatesState = {
  checkingForUpdates: false,
  updateReady: false,
  updateRequired: false,
};

export const reducer = createReducer(initialState, (builder) => {
  builder.addCase(actions.updateRequired, (state, _action) => {
    state.updateRequired = true;
  });
  builder.addCase(actions.updateReady, (state, _action) => {
    state.updateReady = true;
  });
  builder.addCase(actions.checkingForUpdates, (state, action) => {
    state.checkingForUpdates = action.payload;
  });
});
