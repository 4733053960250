import {actions, getDeviceConfigKey} from "@co-frontend-libs/redux";
import {useCallback, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";

export function useDeviceConfig<T>(key: string): [T | undefined, (newValue: T) => void, () => void];
export function useDeviceConfig<T>(
  key: string,
  defaultValue?: T,
): [T, (newValue: T) => void, () => void];
export function useDeviceConfig<T>(
  key: string,
  defaultValue?: T,
): [T, (newValue: T) => void, () => void] {
  const getConfig = useMemo(() => getDeviceConfigKey(key), [key]);
  const dispatch = useDispatch();
  const setValue = useCallback(
    (newValue: T) => {
      dispatch(
        actions.configPut({
          key,
          value: newValue,
        }),
      );
    },
    [dispatch, key],
  );
  const removeValue = useCallback(() => {
    dispatch(
      actions.configRemove({
        key,
      }),
    );
  }, [dispatch, key]);
  const value = useSelector(getConfig);

  const result = useMemo(
    (): [T | undefined, (newValue: T) => void, () => void] => [
      value === undefined ? defaultValue : value,
      setValue,
      removeValue,
    ],
    [defaultValue, removeValue, setValue, value],
  );

  return result as any;
}
