import {common as commonColors} from "@material-ui/core/colors";
import React, {useMemo} from "react";
import {MapMarker} from "../map-marker";

interface HomeMarkerProps {
  lat: number;
  lng: number;
  map: google.maps.Map;
}

const iconBaseSize = 24;

export function HomeMarker(props: HomeMarkerProps): JSX.Element {
  const {lat, lng, map} = props;

  const icon = useMemo(
    () => ({
      anchor: new google.maps.Point(iconBaseSize / 2, iconBaseSize / 2),
      fillColor: commonColors.white,
      fillOpacity: 1,
      path: "M10,20V14H14V20H19V12H22L12,3L2,12H5V20H10Z",
      scale: 1.5,
      strokeColor: commonColors.black,
      strokeOpacity: 1,
      strokeWeight: 1,
    }),
    [],
  );

  return <MapMarker icon={icon} map={map} position={{lat, lng}} />;
}
