import {
  PriceGroup,
  PriceGroupItem,
  PriceItem,
  PriceItemUrl,
  PriceItemUse,
  Product,
  ProductUrl,
  ProductUse,
  ProductUseWithOrder,
  ProductUsesDict,
  Unit,
  UnitUrl,
} from "@co-common-libs/resources";
import {identifierComparator, simpleComparator} from "@co-common-libs/utils";
import _ from "lodash";
import {getUnitString} from "./unit";

// TODO: remove/replace with @co-backend-libs/utils/recompute-price-item-use
/** @deprecated */
const sortPriceItemUseListByPriceItemField = <T extends {priceItem: PriceItemUrl}>(
  priceItemUseList: readonly T[],
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined,
  field: keyof PriceItem,
): T[] =>
  _.sortBy(priceItemUseList, (priceItemUse) => {
    const priceItem = priceItemLookup(priceItemUse.priceItem);
    return priceItem ? priceItem[field] : null;
  });

// TODO: remove/replace with @co-backend-libs/utils/recompute-price-item-use
/** @deprecated */
export const sortPriceItemUseListByRemoteURL = <T extends {priceItem: PriceItemUrl}>(
  priceItemUseList: readonly T[],
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined,
): T[] =>
  priceItemUseList.slice().sort((a, b) => {
    const aPriceItem = priceItemLookup(a.priceItem);
    const bPriceItem = priceItemLookup(b.priceItem);
    if (aPriceItem && bPriceItem) {
      return identifierComparator(aPriceItem.remoteUrl, bPriceItem.remoteUrl);
    } else {
      // the ones having price items loaded first
      if (aPriceItem) {
        return -1;
      } else if (bPriceItem) {
        return 1;
      } else {
        // neither priceitem loaded; sort by price item URL to give a
        // consistent result...
        return simpleComparator(a.priceItem, b.priceItem);
      }
    }
  });

// TODO: remove/replace with @co-backend-libs/utils/recompute-price-item-use
/** @deprecated */
export const sortPriceItemUseListByLineNumber = <T extends {priceItem: PriceItemUrl}>(
  priceItemUseList: readonly T[],
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined,
): T[] => sortPriceItemUseListByPriceItemField(priceItemUseList, priceItemLookup, "lineNumber");

// TODO: remove/replace with @co-backend-libs/utils/recompute-price-item-use
/** @deprecated */
export const sortPriceItemUseListByUnitAndName = <T extends {priceItem: PriceItemUrl}>(
  priceItemUseList: readonly T[],
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined,
  unitLookup: (url: UnitUrl) => Unit | undefined,
): T[] =>
  _.sortBy(priceItemUseList, [
    (priceItemUse: T): string => {
      const priceItem = priceItemLookup(priceItemUse.priceItem);
      return priceItem ? getUnitString(priceItem, unitLookup).toLowerCase() : "";
    },
    (priceItemUse: T): string => {
      const priceItem = priceItemLookup(priceItemUse.priceItem);
      return priceItem ? priceItem.name : "";
    },
  ]);

/** @deprecated */
export const sortLegacyProductUseListByCatalogNumber = (
  productUseList: readonly ProductUse[],
  productLookup: (url: ProductUrl) => Product | undefined,
): ProductUse[] =>
  productUseList.slice().sort((a, b) => {
    const aProduct = productLookup(a.product);
    const bProduct = productLookup(b.product);
    return identifierComparator(
      aProduct ? aProduct.catalogNumber : "",
      bProduct ? bProduct.catalogNumber : "",
    );
  });

export function updateProductUseEntriesOrder<T extends {readonly order: number}>(
  productUseEntries: (readonly [string, T])[],
): void {
  // intentionally skipping first
  for (let i = 1; i < productUseEntries.length; i += 1) {
    const [identifier, priceItemUse] = productUseEntries[i];
    const [, /* previousIdentifier */ previousPriceItemUse] = productUseEntries[i - 1];
    if (priceItemUse.order <= previousPriceItemUse.order) {
      productUseEntries[i] = [identifier, {...priceItemUse, order: previousPriceItemUse.order + 1}];
    }
  }
}

export function sortProductUseListByCatalogNumber(
  productUses: ProductUsesDict,
  productLookup: (url: ProductUrl) => Product | undefined,
): {[identifier: string]: ProductUseWithOrder} {
  const sortedEntries = Object.entries(productUses).sort(
    ([_aIdentifier, aProductUse], [_bIdentifier, bProductUse]) => {
      const aProduct = productLookup(aProductUse.product);
      const bProduct = productLookup(bProductUse.product);
      return identifierComparator(
        aProduct ? aProduct.catalogNumber : "",
        bProduct ? bProduct.catalogNumber : "",
      );
    },
  );
  updateProductUseEntriesOrder(sortedEntries);
  return Object.fromEntries(sortedEntries);
}

/** @deprecated */
export const sortLegacyProductUseListByUnitAndName = (
  productUseList: readonly ProductUse[],
  productLookup: (url: ProductUrl) => Product | undefined,
  unitLookup: (url: UnitUrl) => Unit | undefined,
): ProductUse[] =>
  _.sortBy(productUseList, [
    (productUse: ProductUse) => {
      const product = productLookup(productUse.product);
      return product ? getUnitString(product, unitLookup).toLowerCase() : "";
    },
    (productUse: ProductUse) => {
      const product = productLookup(productUse.product);
      return product ? product.name : "";
    },
  ]);

// TODO: remove/replace with @co-backend-libs/utils/recompute-price-item-use
/** @deprecated */
export const sortPriceItemUseListByManualOrder = <T extends {priceItem: string}>(
  priceItemUseList: readonly T[],
  priceGroupSet: ReadonlySet<{priceGroupItemSet: PriceGroupItem[]}>,
): T[] =>
  _.sortBy(priceItemUseList, (priceItemUse) => {
    let value = 0;
    let done = false;
    priceGroupSet.forEach((priceGroup) => {
      if (done) {
        return;
      }
      const priceItemURL = priceItemUse.priceItem;
      const {priceGroupItemSet} = priceGroup;
      if (priceGroupItemSet) {
        const item = Array.from(priceGroupItemSet).find(
          (priceGroupItem) => (priceGroupItem.priceItem as string) === priceItemURL,
        );
        if (item) {
          value = item.order as number;
          done = true;
          return;
        }
      }
      return;
    });
    return value;
  });

const getPriceGroupForPriceItemUse = (
  priceItemUse: PriceItemUse,
  priceGroupSet: ReadonlySet<PriceGroup>,
): PriceGroup | undefined => {
  const priceItemURL = priceItemUse.priceItem;
  return Array.from(priceGroupSet).find((priceGroup) => {
    const {priceGroupItemSet} = priceGroup;
    return (
      !!priceGroupItemSet &&
      priceGroupItemSet.some((priceGroupItem) => priceGroupItem.priceItem === priceItemURL)
    );
  });
};

// TODO: remove/replace with @co-backend-libs/utils/recompute-price-item-use
/** @deprecated */
export const sortPriceItemUseListByPriceGroupIdentifierAndName = (
  priceItemUseList: readonly PriceItemUse[],
  priceGroupSet: ReadonlySet<PriceGroup>,
): PriceItemUse[] =>
  _.sortBy(priceItemUseList, [
    (priceItemUse) => {
      const priceGroup = getPriceGroupForPriceItemUse(priceItemUse, priceGroupSet);
      return priceGroup ? priceGroup.name.toLowerCase() : "";
    },
  ]).sort((a, b) => {
    const aPriceGroup = getPriceGroupForPriceItemUse(a, priceGroupSet);
    const bPriceGroup = getPriceGroupForPriceItemUse(b, priceGroupSet);
    return identifierComparator(
      aPriceGroup ? aPriceGroup.identifier : "",
      bPriceGroup ? bPriceGroup.identifier : "",
    );
  });
