import {frontendVersion} from "@co-common-libs/frontend-version";
import {
  Command,
  Patch,
  PatchOperation,
  ResourceInstance,
  getListURL,
  isMemberPatchOperation,
} from "@co-common-libs/resources";
import {deserializeFile} from "./file-data";
import {ResponseWithData, StatusError, jsonFetch} from "./json-fetch";
import {getFrontendSentry} from "./sentry";

const FILE_KEY_PREFIX = "_file_";
const FILE_KEY_PREFIX_LENGTH = 6;

const hasFileMember = (instance: object): boolean =>
  Object.keys(instance).some((key) => key.startsWith(FILE_KEY_PREFIX));

function buildFormData(instance: any): FormData {
  const formData = new FormData();
  const keys = Object.keys(instance);
  const fileKeys = [];
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    if (key.startsWith(FILE_KEY_PREFIX)) {
      fileKeys.push(key);
    } else {
      formData.append(key, instance[key]);
    }
  }
  for (const key of fileKeys) {
    const {blob, name} = deserializeFile(instance[key]);
    const realKey = key.slice(FILE_KEY_PREFIX_LENGTH);
    formData.append(realKey, blob, name);
  }
  return formData;
}

interface JsonPatchAddOperation {
  op: "add";
  path: string;
  value: unknown;
}
interface JSONPatchRemoveOperation {
  op: "remove";
  path: string;
}

export function sendOnline(
  command: Command,
  apiVersion: string | null,
  createdByVersion: string | null,
): Promise<ResponseWithData> {
  const instanceUrl = command.url;
  if (command.action === "CREATE") {
    const {instance} = command;
    const listUrl = getListURL(instanceUrl);
    if (hasFileMember(instance)) {
      let formData: FormData;
      try {
        formData = buildFormData(instance);
      } catch (error) {
        const sentry = getFrontendSentry();
        sentry.withScope((scope) => {
          scope.setExtra("error", `${error}`);
          scope.setTag("instanceUrl", command.url);
          scope.setTag("action", command.action);
          scope.setExtra("instance", command.instance);
          const redactedInstance = Object.fromEntries(
            Object.entries(command.instance).map(([member, value]) => {
              if (member.startsWith(FILE_KEY_PREFIX) && typeof value === "object" && value) {
                return [member, {...value, data: "<<REDACTED>>"}];
              } else {
                return [member, value];
              }
            }),
          );
          scope.setExtra("redactedInstance", redactedInstance);
          sentry.captureMessage("buildFormData failed", "error");
        });
        const fakeResponse = new Response(null, {
          status: 409,
        }) as ResponseWithData;
        fakeResponse.data = null;
        return Promise.reject(new StatusError(fakeResponse));
      }
      return jsonFetch(listUrl, "POST", formData, undefined, undefined, {
        apiVersion,
        createdByVersion,
        frontendVersion,
      });
    } else {
      return jsonFetch(listUrl, "POST", instance, undefined, undefined, {
        apiVersion,
        createdByVersion,
        frontendVersion,
      });
    }
  } else if (command.action === "UPDATE") {
    const jsonPatch = (command.patch as Patch<ResourceInstance>).map(
      (
        patchOperation: PatchOperation<ResourceInstance>,
      ): JsonPatchAddOperation | JSONPatchRemoveOperation => {
        if (isMemberPatchOperation(patchOperation)) {
          const path = `/${(patchOperation as any).member}`;
          const {value} = patchOperation as any;
          return {op: "add", path, value};
        } else {
          const path = `/${patchOperation.path.join("/")}`;
          const {value} = patchOperation as any;
          if (value !== undefined) {
            return {op: "add", path, value};
          } else {
            return {op: "remove", path};
          }
        }
      },
    );
    return jsonFetch(
      instanceUrl,
      "PATCH",
      jsonPatch,
      undefined,
      undefined,
      {apiVersion, createdByVersion, frontendVersion},
      "application/json-patch+json",
    );
  } else if (command.action === "DELETE") {
    return jsonFetch(instanceUrl, "DELETE", undefined, undefined, undefined, {
      apiVersion,
      createdByVersion,
      frontendVersion,
    });
  } else {
    throw new Error(`unknown command action: ${JSON.stringify(command)}`);
  }
}
