import {ResourceName, resourceNameFor, resourceNames} from "@co-common-libs/resources";
import {GenericInstanceRecord, ResourceInstanceRecords, ResourceURLArrays} from "../../types";
import {
  FkRules,
  FkRulesForTarget,
  ReverseFkRules,
  ReverseFkRulesForSource,
  partialEntriesForEach,
  partialValuesForEach,
} from "../../utils";

// checked resource criteria may depend on having an FK to an instance
// fulfilling some criteria; needs to be rechecked if target of FK has
// just been removed
function updateResourceInstancesToCheckFromRemovalsAndFkRules(
  oldData: ResourceInstanceRecords,
  resourceName: ResourceName,
  resourceRemovedURLs: readonly string[],
  resourceFkRules: FkRulesForTarget,
  instancesToCheck: {
    [N in ResourceName]: Set<string>;
  },
): void {
  resourceFkRules.forEach(({checkedResourceName, memberName}) => {
    const otherResourceInstances = oldData[checkedResourceName] as GenericInstanceRecord;
    const resourceInstancesToCheck = instancesToCheck[resourceName];
    partialValuesForEach(otherResourceInstances, (otherResourceInstance) => {
      const fkValue = (otherResourceInstance as any)[memberName] as string | undefined;
      if (typeof fkValue === "string" && resourceRemovedURLs.includes(fkValue)) {
        resourceInstancesToCheck.add(otherResourceInstance.url);
      }
    });
  });
}

// checked resource criteria may depend on being target of FK from an
// instance fulfilling some criteria; needs to be rechecked if the source
// of such an FK has been removed
function updateResourceInstancesToCheckFromRemovalsAndReverseFkRules(
  oldData: ResourceInstanceRecords,
  resourceName: ResourceName,
  resourceRemovedURLs: readonly string[],
  resourceReverseFkRules: ReverseFkRulesForSource,
  instancesToCheck: {
    [N in ResourceName]: Set<string>;
  },
): void {
  const resourceInstances = oldData[resourceName] as GenericInstanceRecord;
  resourceReverseFkRules.forEach(({checkedResourceName, memberName}) => {
    resourceRemovedURLs.forEach((url) => {
      const oldInstance = resourceInstances[url];
      const memberValue = oldInstance && ((oldInstance as any)[memberName] as string | undefined);
      if (typeof memberValue === "string") {
        const fkTargetResourceName = resourceNameFor(memberValue);
        // in a sensible configuration, types match
        console.assert(checkedResourceName === fkTargetResourceName);
        const resourceInstancesToCheck = instancesToCheck[fkTargetResourceName];
        resourceInstancesToCheck.add(memberValue);
      }
    });
  });
}

// resource instances may have checks that depend on relations to instances
// that are now removed -- but as instances may have multiple checks with
// multiple criteria; performing checks "inline" would add a lot of complexity
// for a questionable performance gain
export function computeInstancesToCheckFromRemovals(
  oldData: ResourceInstanceRecords,
  removedInstances: Partial<ResourceURLArrays>,
  persistedFkRules: FkRules,
  persistedReverseFkRules: ReverseFkRules,
  temporaryFkRules: FkRules,
  temporaryReverseFkRules: ReverseFkRules,
): {readonly [N in ResourceName]: ReadonlySet<string>} {
  const instancesToCheck = Object.fromEntries(
    resourceNames.map((resourceName) => [resourceName, new Set<string>()]),
  ) as {
    [N in ResourceName]: Set<string>;
  };
  partialEntriesForEach(removedInstances, (resourceName, resourceRemovedURLs) => {
    const resourcePersistedFkRules = persistedFkRules[resourceName];
    const resourcePersistedReverseFkRules = persistedReverseFkRules[resourceName];
    const resourceTemporaryFkRules = temporaryFkRules[resourceName];
    const resourceTemporaryReverseFkRules = temporaryReverseFkRules[resourceName];
    if (resourcePersistedFkRules) {
      updateResourceInstancesToCheckFromRemovalsAndFkRules(
        oldData,
        resourceName,
        resourceRemovedURLs,
        resourcePersistedFkRules,
        instancesToCheck,
      );
    }
    if (resourceTemporaryFkRules) {
      updateResourceInstancesToCheckFromRemovalsAndFkRules(
        oldData,
        resourceName,
        resourceRemovedURLs,
        resourceTemporaryFkRules,
        instancesToCheck,
      );
    }
    if (resourcePersistedReverseFkRules) {
      updateResourceInstancesToCheckFromRemovalsAndReverseFkRules(
        oldData,
        resourceName,
        resourceRemovedURLs,
        resourcePersistedReverseFkRules,
        instancesToCheck,
      );
    }
    if (resourceTemporaryReverseFkRules) {
      updateResourceInstancesToCheckFromRemovalsAndReverseFkRules(
        oldData,
        resourceName,
        resourceRemovedURLs,
        resourceTemporaryReverseFkRules,
        instancesToCheck,
      );
    }
  });
  return instancesToCheck;
}
