import {EntryData, GenericMultiSelectionSearchDialog} from "@co-frontend-libs/components";
import {memoizeForceReuse} from "@co-frontend-libs/utils";
import React, {useMemo} from "react";
import {useIntl} from "react-intl";

function computeBaseChoices(formats: readonly string[]): readonly EntryData<string>[] {
  const result: EntryData<string>[] = formats.map((format) => {
    const entry: EntryData<string> = {
      category: "standard",
      exactMatchValue: format,
      identifier: format,
      primaryText: format,
      searchFields: [{label: "Format", priority: 1, text: format}],
    };
    return entry;
  });
  return result;
}

export {computeBaseChoices as convertFormatsToEntryDataArray};

interface MultipleBarcodeFormatsDialogProps {
  formats: readonly string[];
  onCancel(): void;
  onOk(formats: ReadonlySet<string>): void;
  open: boolean;
  selected?: ReadonlySet<string>;
}

export const MultipleBarcodeFormatsDialog = React.memo(function MultipleBarcodeFormatsDialog(
  props: MultipleBarcodeFormatsDialogProps,
): JSX.Element {
  const intl = useIntl();
  const {formats, onCancel, onOk, open, selected} = props;

  const [doComputeBaseChoices, reuseBaseChoices] = useMemo(
    () => memoizeForceReuse(computeBaseChoices, []),
    [],
  );
  const getBaseChoices = open ? doComputeBaseChoices : reuseBaseChoices;
  const data = getBaseChoices(formats);

  const title = intl.formatMessage({
    defaultMessage: "Vælg stregkodeformater",
  });
  const searchTitle = intl.formatMessage({defaultMessage: "Søg"});
  const selectedSet = selected;
  return (
    <GenericMultiSelectionSearchDialog<string>
      data={data}
      mobilePrimaryLines={1}
      mobileSearchPrimaryLines={1}
      mobileSearchSecondaryLines={1}
      mobileSecondaryLines={1}
      open={open}
      searchTitle={searchTitle}
      selected={selectedSet}
      sorting="PRIMARY_IDENTIFIER"
      title={title}
      onCancel={onCancel}
      onOk={onOk}
    />
  );
});
