import {Config} from "@co-common-libs/config";
import {
  Customer,
  CustomerUrl,
  PriceGroup,
  PriceGroupUrl,
  PriceItem,
  PriceItemUrl,
  RoutePlan,
  RoutePlanTask,
  RoutePlanTaskActivityOption,
  RoutePlanTaskResult,
  Unit,
  UnitUrl,
  WorkType,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {
  AppState,
  getCustomerLookup,
  getCustomerSettings,
  getPriceGroupLookup,
  getPriceItemLookup,
  getRoutePlanTaskActivityOptionArray,
  getRoutePlanTaskArray,
  getRoutePlanTaskResultArray,
  getUnitLookup,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {PureComponent} from "app-utils";
import _ from "lodash";
import React from "react";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {
  COUNT_COLUMN_WIDTH,
  PRICE_COLUMN_WIDTH,
  PRODUCT_NUMBER_COLUMN_WIDTH,
  UNIT_COLUMN_WIDTH,
  VARIANT_NUMBER_COLUMN_WIDTH,
} from "./constants";
import {RoutePlanTaskRows} from "./route-plan-task-rows";

interface RoutePlanOverviewTableStateProps {
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  customerSettings: Config;
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined;
  routePlanTaskActivityOptionArray: readonly RoutePlanTaskActivityOption[];
  routePlanTaskArray: readonly RoutePlanTask[];
  routePlanTaskResultArray: readonly RoutePlanTaskResult[];
  unitLookup: (url: UnitUrl) => Unit | undefined;
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
}

interface RoutePlanOverviewTableOwnProps {
  routePlan: RoutePlan;
}

type RoutePlanOverviewTableProps = RoutePlanOverviewTableOwnProps &
  RoutePlanOverviewTableStateProps;

class RoutePlanOverviewTable extends PureComponent<RoutePlanOverviewTableProps> {
  render(): JSX.Element {
    const {
      customerLookup,
      customerSettings,
      priceGroupLookup,
      priceItemLookup,
      routePlan,
      routePlanTaskActivityOptionArray,
      routePlanTaskArray,
      routePlanTaskResultArray,
      unitLookup,
      workTypeLookup,
    } = this.props;

    const routePlanURL = routePlan.url;
    const relevantTasks = _.sortBy(
      routePlanTaskArray.filter((task) => task.routePlan === routePlanURL),
      (routePlanTask) => routePlanTask.order,
    );
    const relevantTaskURLs = new Set(relevantTasks.map((task) => task.url));
    const activityOptionsPerTask = new Map<string, RoutePlanTaskActivityOption[]>();
    const relevantActivityOptionURLs = new Set<string>();
    routePlanTaskActivityOptionArray.forEach((activityOption) => {
      const taskURL = activityOption.routePlanTask;
      if (relevantTaskURLs.has(taskURL)) {
        const existing = activityOptionsPerTask.get(taskURL);
        if (existing) {
          existing.push(activityOption);
        } else {
          activityOptionsPerTask.set(taskURL, [activityOption]);
        }
        relevantActivityOptionURLs.add(activityOption.url);
      }
    });
    const resultsPerActivityOption = new Map<string, RoutePlanTaskResult[]>();
    routePlanTaskResultArray.forEach((result) => {
      const activityOptionURL = result.activityOption;
      if (relevantActivityOptionURLs.has(activityOptionURL)) {
        const existing = resultsPerActivityOption.get(activityOptionURL);
        if (existing) {
          existing.push(result);
        } else {
          resultsPerActivityOption.set(activityOptionURL, [result]);
        }
      }
    });

    const workTypeURL = routePlan.workType;
    const workType = workTypeURL ? workTypeLookup(workTypeURL) : undefined;
    const customersWithSpecificPriceGroups = new Set<string>();
    if (workType) {
      workType.pricegroups.forEach((priceGroupURL) => {
        const workTypePriceGroup = priceGroupLookup(priceGroupURL);
        const customers = workTypePriceGroup?.customers;
        const active = workTypePriceGroup?.active;
        if (active && customers && customers.length) {
          customers.forEach((c) => customersWithSpecificPriceGroups.add(c));
        }
      });
    }

    const rows = relevantTasks.map((task) => {
      return (
        <RoutePlanTaskRows
          key={task.url}
          activityOptionsPerTask={activityOptionsPerTask}
          customerLookup={customerLookup}
          customerSettings={customerSettings}
          customersWithSpecificPriceGroups={customersWithSpecificPriceGroups}
          priceGroupLookup={priceGroupLookup}
          priceItemLookup={priceItemLookup}
          resultsPerActivityOption={resultsPerActivityOption}
          routePlanTask={task}
          unitLookup={unitLookup}
          workType={workType}
        />
      );
    });
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage defaultMessage="Rutepunkt" id="routes.table-header.route-point" />
            </TableCell>
            <TableCell style={{width: VARIANT_NUMBER_COLUMN_WIDTH}}>
              <FormattedMessage
                defaultMessage="Variant nr."
                id="routes.table-header.variant-number"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage defaultMessage="Variant" id="routes.table-header.variant" />
            </TableCell>
            <TableCell style={{width: PRODUCT_NUMBER_COLUMN_WIDTH}}>
              <FormattedMessage defaultMessage="Varenr." id="routes.table-header.product-number" />
            </TableCell>
            <TableCell>
              <FormattedMessage defaultMessage="Varelinje" id="routes.table-header.product-line" />
            </TableCell>
            <TableCell style={{width: COUNT_COLUMN_WIDTH}}>
              <FormattedMessage defaultMessage="Antal" id="routes.table-header.count" />
            </TableCell>
            <TableCell style={{width: UNIT_COLUMN_WIDTH}}>
              <FormattedMessage defaultMessage="Enhed" id="routes.table-header.unit" />
            </TableCell>
            {customerSettings.overviewShowPrices ? (
              <TableCell style={{textAlign: "right", width: PRICE_COLUMN_WIDTH}}>
                <FormattedMessage defaultMessage="Pris (kr)" id="routes.table-header.price" />
              </TableCell>
            ) : null}
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    );
  }
}

const ConnectedRoutePlanOverviewTable: React.ComponentType<RoutePlanOverviewTableOwnProps> =
  connect<RoutePlanOverviewTableStateProps, object, RoutePlanOverviewTableOwnProps, AppState>(
    createStructuredSelector<AppState, RoutePlanOverviewTableStateProps>({
      customerLookup: getCustomerLookup,
      customerSettings: getCustomerSettings,
      priceGroupLookup: getPriceGroupLookup,
      priceItemLookup: getPriceItemLookup,
      routePlanTaskActivityOptionArray: getRoutePlanTaskActivityOptionArray,
      routePlanTaskArray: getRoutePlanTaskArray,
      routePlanTaskResultArray: getRoutePlanTaskResultArray,
      unitLookup: getUnitLookup,
      workTypeLookup: getWorkTypeLookup,
    }),
    {},
  )(RoutePlanOverviewTable);

export {ConnectedRoutePlanOverviewTable as RoutePlanOverviewTable};
