import {getWorkTypeLookup, makePathParameterGetter} from "@co-frontend-libs/redux";
import {PageLayout, WorkTypeEditCard} from "app-components";
import {instanceURL} from "frontend-global-config";
import React from "react";
// Allowed for existing code...
// eslint-disable-next-line deprecate/import
import {Cell, Grid} from "react-flexr";
import {defineMessages, useIntl} from "react-intl";
import {useSelector} from "react-redux";

const messages = defineMessages({
  editWorkType: {
    defaultMessage: "Redigér arbejdsområde",
  },
});

export const WorkTypeEditPage = React.memo(function EditWorkType(): JSX.Element {
  const {formatMessage} = useIntl();
  const workTypeLookup = useSelector(getWorkTypeLookup);

  const idSelector = makePathParameterGetter("id");
  const id = useSelector(idSelector);

  const workType = workTypeLookup(instanceURL("workType", id));

  // Grid/Cell types do not accept style prop ...?
  const GridNoType = Grid as any;
  const CellNoType = Cell as any;
  return (
    <PageLayout toolbar={formatMessage(messages.editWorkType)}>
      <GridNoType
        style={{
          margin: 5,
        }}
      >
        <CellNoType
          palm="12/12"
          size="6/12"
          style={{
            paddingBottom: 11,
            paddingTop: 11,
          }}
        >
          {workType ? <WorkTypeEditCard workType={workType} /> : null}
        </CellNoType>
      </GridNoType>
    </PageLayout>
  );
});
