import {Unit, urlToId} from "@co-common-libs/resources";
import {ResponsiveDialog, TrimTextField} from "@co-frontend-libs/components";
import {
  actions,
  diffResourceInstanceProperties,
  getCustomerSettings,
  getUnitArray,
} from "@co-frontend-libs/redux";
import {jsonFetch, translateNetworkError} from "@co-frontend-libs/utils";
import {
  CircularProgress,
  DialogContent,
  FormControlLabel,
  Switch,
  useTheme,
} from "@material-ui/core";
import {createUnit, useEventTargetCheckedCallback} from "app-utils";
import {globalConfig} from "frontend-global-config";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

export const UnitCreateEditDialog = React.memo(function UnitCreateEditDialog(props: {
  onCancel: () => void;
  onOk: (unitUrl: string) => void;
  open: boolean;
  unit?: Unit | undefined;
}): JSX.Element {
  const {onOk, open, unit} = props;
  const [name, setName] = useState(unit?.name || "");
  const [symbol, setSymbol] = useState(unit?.symbol || "");
  const [active, setActive] = useState<boolean>(unit?.active || true);
  const [saving, setSaving] = useState(false);
  const customerSettings = useSelector(getCustomerSettings);
  const unitArray = useSelector(getUnitArray);

  const unitNameDuplicate: boolean = useMemo(() => {
    if (!name) {
      return false;
    } else {
      return unitArray.some((w) => w.name === name && w.url !== unit?.url);
    }
  }, [name, unit?.url, unitArray]);
  const unitSymbolDuplicate: boolean = useMemo(() => {
    if (!symbol) {
      return false;
    } else {
      return unitArray.some((w) => w.symbol === symbol && w.url !== unit?.url);
    }
  }, [symbol, unit?.url, unitArray]);

  useEffect(() => {
    if (open) {
      setName(unit?.name || "");
      setSymbol(unit?.symbol || "");
    }
  }, [open, unit?.symbol, unit?.name]);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const intl = useIntl();

  const dispatch = useDispatch();
  const handleOk = useCallback(async () => {
    if (customerSettings.economicSync) {
      setSaving(true);
      const {baseURL} = globalConfig.resources;
      if (unit) {
        if (name !== unit.name || symbol !== unit.symbol || active !== unit.active) {
          try {
            const url = `${baseURL}economic/unit/${urlToId(unit.url)}`;
            const response = await jsonFetch(url, "POST", {
              active,
              name,
              symbol,
            });
            dispatch(actions.addToOffline(response.data));
            onOk(response.data.url);
          } catch (error) {
            setSaving(false);
            setErrorMessage(translateNetworkError(error, intl));
          }
        } else {
          onOk(unit.url);
        }
      } else {
        const url = `${baseURL}economic/unit/create`;
        try {
          const response = await jsonFetch(url, "POST", {
            name,
            symbol,
          });
          dispatch(actions.addToOffline(response.data));
          onOk(response.data.url);
        } catch (error) {
          setSaving(false);
          setErrorMessage(translateNetworkError(error, intl));
        }
      }
      setSaving(false);
    } else {
      if (unit) {
        const patch = diffResourceInstanceProperties({active, name, symbol}, unit);

        if (patch.length) {
          dispatch(actions.update(unit.url, patch));
        }
        onOk(unit.url);
      } else {
        const newUnit = createUnit({
          name,
          symbol,
        });

        dispatch(actions.create(newUnit));
        onOk(newUnit.url);
      }
    }
  }, [customerSettings.economicSync, unit, name, symbol, dispatch, onOk, intl, active]);

  const theme = useTheme();

  return (
    <ResponsiveDialog
      okDisabled={!name || unitNameDuplicate || unitSymbolDuplicate || saving}
      open={open}
      title={
        props.unit ? (
          <FormattedMessage defaultMessage="Redigér enhed" />
        ) : (
          <FormattedMessage defaultMessage="Opret enhed" />
        )
      }
      onCancel={props.onCancel}
      onOk={handleOk}
    >
      <DialogContent>
        <TrimTextField
          autoFocus
          fullWidth
          error={unitNameDuplicate}
          helperText={
            unitNameDuplicate
              ? intl.formatMessage({
                  defaultMessage: "Der eksisterer allerede en enhed med det navn",
                })
              : undefined
          }
          label={intl.formatMessage({
            defaultMessage: "Navn *",
          })}
          margin="dense"
          value={name}
          variant="outlined"
          onChange={setName}
        />

        <TrimTextField
          fullWidth
          error={unitSymbolDuplicate}
          helperText={
            unitSymbolDuplicate
              ? intl.formatMessage({
                  defaultMessage: "Der eksisterer allerede en enhed med det symbol",
                })
              : null
          }
          label={intl.formatMessage({
            defaultMessage: "Symbol",
          })}
          margin="dense"
          value={symbol}
          variant="outlined"
          onChange={setSymbol}
        />

        <FormControlLabel
          control={
            <Switch
              checked={!!active}
              onChange={useEventTargetCheckedCallback(setActive, [setActive])}
            />
          }
          disabled={!unit}
          label={intl.formatMessage({defaultMessage: "Aktiv"})}
        />

        <FormattedMessage defaultMessage="* Skal udfyldes" tagName="div" />
        {errorMessage ? (
          <h3
            style={{
              color: theme.palette.error.main,
            }}
          >
            {errorMessage}
          </h3>
        ) : null}
        {saving ? (
          <div style={{textAlign: "center"}}>
            <CircularProgress />
          </div>
        ) : null}
      </DialogContent>
    </ResponsiveDialog>
  );
});
