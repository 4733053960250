import React from "react";
import {HALF_HOUR_ROW_HEIGHT} from "./constants";

interface CalendarGridBlockProps {
  dropTaget?: boolean;
  text?: string;
  transitionMark?: boolean;
}

export const CalendarGridBlock = React.memo(function CalendarGridBlock(
  props: CalendarGridBlockProps,
): JSX.Element {
  const {text, transitionMark = false} = props;
  const outerStyle: React.CSSProperties = {
    borderColor: "rgb(221, 221, 221)",
    borderStyle: "solid",
    borderWidth: "0px 1px 0px 0px",
    userSelect: "none",
    WebkitUserSelect: "none",
  };
  const topStyle: React.CSSProperties = {
    borderColor: "rgb(221, 221, 221)",
    borderStyle: "solid",
    borderWidth: "1px 0px 0px 0px",
    height: HALF_HOUR_ROW_HEIGHT,
    paddingLeft: 4,
  };
  if (transitionMark) {
    topStyle.borderWidth = "5px 0px 0px 0px";
    topStyle.borderColor = "#000000";
  }
  const bottomStyle: React.CSSProperties = {
    borderColor: "rgb(247, 247, 247)",
    borderStyle: "solid",
    borderWidth: "1px 0px 0px 0px",
    height: HALF_HOUR_ROW_HEIGHT,
  };

  return (
    <div style={outerStyle}>
      <div className="gridBlock" style={topStyle}>
        {text}
      </div>
      <div className="gridBlock" style={bottomStyle} />
    </div>
  );
});
