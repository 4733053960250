import _ from "lodash";
import {isEconomicUserProfileIssue} from "../economic-issue-type-guards";
import {EconomicIssue, EconomicUserProfileIssue, EconomicUserProfilesIssue} from "../types";

export function combineUserProfileIssues({
  issues,
  type,
}: {
  issues: readonly EconomicIssue[];
  type: EconomicUserProfileIssue["issueType"];
}): EconomicUserProfilesIssue | undefined {
  const issuesMatchingResourceAndType = issues
    .filter(isEconomicUserProfileIssue)
    .filter(({issueType}) => issueType === type);

  if (issuesMatchingResourceAndType.length <= 1) {
    return undefined;
  }

  const uniqueInstances = _.uniq(issuesMatchingResourceAndType.map(({instance}) => instance));

  const {category, issueType} = issuesMatchingResourceAndType[0];

  return {
    category,
    instances: uniqueInstances,
    issueType,
    resourceName: "userProfile",
  };
}
