import {Config} from "@co-common-libs/config";
import {
  Machine,
  Task,
  TimerStart,
  UserProfile,
  UserUrl,
  WorkType,
  WorkTypeUrl,
  urlToId,
} from "@co-common-libs/resources";
import {formatDuration} from "@co-common-libs/resources-utils";
import {formatDateShort} from "@co-common-libs/utils";
import {PathTemplate} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {TaskStatusIcon} from "app-components";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import React from "react";
import {FormattedMessage, IntlContext, defineMessages} from "react-intl";
import {
  DATE_COLUMN_WIDTH,
  DURATION_COLUMN_WIDTH,
  EMPLOYEE_COLUMN_WIDTH,
  STATUS_ICON_COLUMN_WIDTH,
  TABLE_MIN_WIDTH,
  WORK_TYPE_MACHINE_COLUMN_WIDTH,
} from "./column-widths";

const messages = defineMessages({
  machine: {defaultMessage: "Maskine:", id: "workshop.label.machine"},
  vehicle: {defaultMessage: "Køretøj:", id: "workshop.label.vehicle"},
});

interface ServiceTaskRowProps {
  createdByUserProfile: UserProfile | undefined;
  customerSettings: Config;
  machineOperatorProfile: UserProfile | undefined;
  onClick: (taskURL: string) => void;
  task: Task;
  taskTimerStartArray: readonly TimerStart[];
  workType: WorkType | undefined;
}

class ServiceTaskRow extends PureComponent<ServiceTaskRowProps> {
  @bind
  handleClick(): void {
    this.props.onClick(this.props.task.url);
  }
  render(): JSX.Element {
    const {createdByUserProfile, customerSettings, machineOperatorProfile, task, workType} =
      this.props;
    const workTypeIdentifier = workType ? workType.identifier : null;
    const notes = task.notesFromManager || task.notesFromMachineOperator;
    const machineOperatorInitials = machineOperatorProfile ? machineOperatorProfile.alias : null;
    const duration = formatDuration(
      customerSettings.durationFormat,
      task.minutesExpectedTotalTaskDuration,
    );
    let createdByColumn;
    if (this.props.customerSettings.workshopCreatedByColumn) {
      const createdByInitials = createdByUserProfile ? createdByUserProfile.alias : null;
      createdByColumn = (
        <TableCell style={{width: EMPLOYEE_COLUMN_WIDTH}}>{createdByInitials}</TableCell>
      );
    }
    let createdDateColumn;
    if (this.props.customerSettings.workshopCreatedDateColumn) {
      createdDateColumn = (
        <TableCell style={{width: DATE_COLUMN_WIDTH}}>{formatDateShort(task.created)}</TableCell>
      );
    }
    return (
      <TableRow style={{cursor: "pointer"}} onClick={this.handleClick}>
        <TableCell
          style={{
            width: WORK_TYPE_MACHINE_COLUMN_WIDTH,
          }}
        >
          {workTypeIdentifier}
        </TableCell>
        <TableCell>{notes}</TableCell>
        <TableCell style={{width: EMPLOYEE_COLUMN_WIDTH}}>{machineOperatorInitials}</TableCell>
        <TableCell style={{width: DURATION_COLUMN_WIDTH}}>{duration}</TableCell>
        {createdByColumn}
        {createdDateColumn}
        <TableCell style={{width: STATUS_ICON_COLUMN_WIDTH}}>
          <TaskStatusIcon task={task} timerStartArray={this.props.taskTimerStartArray} />
        </TableCell>
      </TableRow>
    );
  }
}

interface ServiceMachineRowProps {
  customerSettings: Config;
  machine: Machine;
  onClick: (machineURL: string) => void;
  taskList: readonly Task[];
}

class ServiceMachineRow extends PureComponent<ServiceMachineRowProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  @bind
  handleClick(): void {
    this.props.onClick(this.props.machine.url);
  }
  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {customerSettings, machine, taskList} = this.props;
    const machineIdentifier = machine.c5_machine;
    const machineName = machine.name;
    let durationMinutes = 0;
    taskList.forEach((task) => {
      const {minutesExpectedTotalTaskDuration} = task;
      if (minutesExpectedTotalTaskDuration) {
        durationMinutes += minutesExpectedTotalTaskDuration;
      }
    });
    const duration = durationMinutes
      ? formatDuration(customerSettings.durationFormat, durationMinutes)
      : "";
    const machineLabel =
      this.props.customerSettings.machineLabelVariant === "MACHINE"
        ? formatMessage(messages.machine)
        : formatMessage(messages.vehicle);
    const style: React.CSSProperties = {
      cursor: "pointer",
      fontWeight: "bold",
    };
    let vinText;
    if (this.props.customerSettings.workshopVehicleIdentificationNumber) {
      const vinValue = machine.vehicleIdentificationNumber;
      if (vinValue) {
        vinText = (
          <span>
            {", "}
            <FormattedMessage
              defaultMessage="Stelnr.:"
              id="workshop.label.vehicle-identification-number-short"
            />{" "}
            {vinValue}
          </span>
        );
      }
    }
    let createdByColumn;
    if (this.props.customerSettings.workshopCreatedByColumn) {
      createdByColumn = <TableCell style={{width: EMPLOYEE_COLUMN_WIDTH}} />;
    }
    let createdDateColumn;
    if (this.props.customerSettings.workshopCreatedDateColumn) {
      createdDateColumn = <TableCell style={{width: DATE_COLUMN_WIDTH}} />;
    }
    return (
      <TableRow style={style} onClick={this.handleClick}>
        <TableCell
          style={{
            width: WORK_TYPE_MACHINE_COLUMN_WIDTH,
          }}
        >
          {machineLabel} {machineIdentifier}
        </TableCell>
        <TableCell>
          {machineName}
          {vinText}
        </TableCell>
        <TableCell style={{width: EMPLOYEE_COLUMN_WIDTH}} />
        <TableCell style={{width: DURATION_COLUMN_WIDTH}}>{duration}</TableCell>
        {createdByColumn}
        {createdDateColumn}
        <TableCell style={{width: STATUS_ICON_COLUMN_WIDTH}} />
      </TableRow>
    );
  }
}

interface ServiceTaskTableProps {
  customerSettings: Config;
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  machineArray: readonly Machine[];
  taskList: readonly Task[];
  timerStartArray: readonly TimerStart[];
  userUserProfileLookup: (url: UserUrl) => UserProfile | undefined;
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
}

export class ServiceTaskTable extends PureComponent<ServiceTaskTableProps> {
  @bind
  handleTaskRowClick(taskURL: string): void {
    const id = urlToId(taskURL);
    this.props.go("/task/:id", {id});
  }
  @bind
  handleMachineRowClick(machineURL: string): void {
    const id = urlToId(machineURL);
    this.props.go("/machine/:id", {id});
  }
  render(): JSX.Element {
    const {machineArray, taskList, userUserProfileLookup, workTypeLookup} = this.props;
    const taskTimerStartArrayMap = new Map<string, TimerStart[]>();
    this.props.timerStartArray.forEach((timerStart) => {
      const taskURL = timerStart.task;
      let taskTimerStartArray = taskTimerStartArrayMap.get(taskURL);
      if (!taskTimerStartArray) {
        taskTimerStartArray = [];
        taskTimerStartArrayMap.set(taskURL, taskTimerStartArray);
      }
      taskTimerStartArray.push(timerStart);
    });
    const machineTaskMap = new Map<string, Task[]>();
    taskList.forEach((task) => {
      const machineUseList = task.machineuseSet;
      if (!machineUseList || !machineUseList.length) {
        return;
      }
      machineUseList.forEach((machineUse) => {
        const machineURL = machineUse.machine;
        const existingForMachine = machineTaskMap.get(machineURL);
        if (existingForMachine) {
          existingForMachine.push(task);
        } else {
          machineTaskMap.set(machineURL, [task]);
        }
      });
    });
    const rows: JSX.Element[] = [];
    machineArray.forEach((machine) => {
      const machineURL = machine.url;
      const taskArray = machineTaskMap.get(machineURL);
      if (!taskArray) {
        return;
      }
      rows.push(
        <ServiceMachineRow
          key={machineURL}
          customerSettings={this.props.customerSettings}
          machine={machine}
          taskList={taskArray}
          onClick={this.handleMachineRowClick}
        />,
      );
      taskArray.forEach((task) => {
        const machineOperatorURL = task.machineOperator;
        const machineOperatorProfile = machineOperatorURL
          ? userUserProfileLookup(machineOperatorURL)
          : undefined;
        const createdByUserURL = task.createdBy;
        const createdByUserProfile = createdByUserURL
          ? userUserProfileLookup(createdByUserURL)
          : undefined;
        const workTypeURL = task.workType;
        const workType = workTypeURL ? workTypeLookup(workTypeURL) : undefined;
        const taskURL = task.url;
        const taskTimerStartArray = taskTimerStartArrayMap.get(taskURL) || [];
        rows.push(
          <ServiceTaskRow
            key={`${taskURL}:${machineURL}`}
            createdByUserProfile={createdByUserProfile}
            customerSettings={this.props.customerSettings}
            machineOperatorProfile={machineOperatorProfile}
            task={task}
            taskTimerStartArray={taskTimerStartArray}
            workType={workType}
            onClick={this.handleTaskRowClick}
          />,
        );
      });
    });
    let createdByHeaderColumn;
    if (this.props.customerSettings.workshopCreatedByColumn) {
      createdByHeaderColumn = (
        <TableCell style={{width: EMPLOYEE_COLUMN_WIDTH}}>
          <FormattedMessage defaultMessage="Opr. af" id="workshop.table-header.created-by-short" />
        </TableCell>
      );
    }
    let createdDateHeaderColumn;
    if (this.props.customerSettings.workshopCreatedDateColumn) {
      createdDateHeaderColumn = (
        <TableCell style={{width: DATE_COLUMN_WIDTH}}>
          <FormattedMessage defaultMessage="Opr. dato" id="workshop.table-header.created-date" />
        </TableCell>
      );
    }
    return (
      <Table stickyHeader style={{minWidth: TABLE_MIN_WIDTH}}>
        <TableHead>
          <TableRow>
            <TableCell
              style={{
                width: WORK_TYPE_MACHINE_COLUMN_WIDTH,
              }}
            >
              <FormattedMessage
                defaultMessage="Arbejdsområde"
                id="workshop.table-header.task-type"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage defaultMessage="Noter" id="workshop.table-header.notes" />
            </TableCell>
            <TableCell style={{width: EMPLOYEE_COLUMN_WIDTH}}>
              <FormattedMessage
                defaultMessage="Medarb."
                id="workshop.table-header.employee-short"
              />
            </TableCell>
            <TableCell style={{width: DURATION_COLUMN_WIDTH}}>
              <FormattedMessage defaultMessage="Varighed" id="workshop.table-header.duration" />
            </TableCell>
            {createdByHeaderColumn}
            {createdDateHeaderColumn}
            <TableCell
              style={{
                width: STATUS_ICON_COLUMN_WIDTH,
              }}
            />
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    );
  }
}
