import {PathParameters, QueryParameters} from "@co-frontend-libs/routing-sync-history";
import {AppState} from "../root-reducer";
import {PathTemplate} from "./path-templates";

export const getPathName = (state: AppState): string => state.routing.current.pathname;

export const getMatchingPathTemplate = (state: AppState): PathTemplate | null =>
  state.routing.current.matchingPathTemplate;

export const getPathParameters = (state: AppState): PathParameters =>
  state.routing.current.pathParameters;

export const getQueryParameters = (state: AppState): QueryParameters =>
  state.routing.current.queryParameters;

const queryParameterGetterFunctions = new Map<string, (state: AppState) => string>();

export function makeQueryParameterGetter(
  key: string,
  defaultValue: string = "",
): (state: AppState) => string {
  const fnKey = `${key}\0${defaultValue}`;
  const fn = queryParameterGetterFunctions.get(fnKey);
  if (fn) {
    return fn;
  } else {
    const getQueryParameterWithDefault = (state: AppState): string => {
      const value = getQueryParameters(state)[key];
      return value != null ? value : defaultValue;
    };
    queryParameterGetterFunctions.set(fnKey, getQueryParameterWithDefault);
    return getQueryParameterWithDefault;
  }
}

const pathParameterGetterFunctions = new Map<string, (state: AppState) => string>();

export function makePathParameterGetter(key: string): (state: AppState) => string {
  const fn = pathParameterGetterFunctions.get(key);
  if (fn) {
    return fn;
  } else {
    const getPathParameter = (state: AppState): string => {
      const value = getPathParameters(state)[key];
      if (value == null) {
        throw new Error(`path parameter not present: ${key}`);
      }
      return value;
    };
    pathParameterGetterFunctions.set(key, getPathParameter);
    return getPathParameter;
  }
}

const optionalPathParameterGetterFunctions = new Map<string, (state: AppState) => string | null>();

export function makeOptionalPathParameterGetter(key: string): (state: AppState) => string | null {
  const fn = optionalPathParameterGetterFunctions.get(key);
  if (fn) {
    return fn;
  } else {
    const getPathParameter = (state: AppState): string | null => {
      const value = getPathParameters(state)[key];
      if (value == null) {
        return null;
      }
      return value;
    };
    optionalPathParameterGetterFunctions.set(key, getPathParameter);
    return getPathParameter;
  }
}
