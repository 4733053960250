import {ResourceInstance, ResourceName} from "@co-common-libs/resources";
import {Draft, current} from "@reduxjs/toolkit";
import {performRelatedFetch} from "../actions";
import {ResourceInstanceRecords, ResourceURLArrays, ResourcesState} from "../types";
import {changeIsNewer} from "./change-is-newer";
import {getOfflineData} from "./selectors";
import {updateFromReceivedData} from "./update-from-received-data";

export function handlePerformRelatedFetchPending(
  state: Draft<ResourcesState>,
  action: ReturnType<typeof performRelatedFetch.pending>,
): void {
  const {requestId} = action.meta;
  const {resourceName} = action.meta.arg;
  console.assert(
    !state.fetchByRelationActive[resourceName],
    `already fetching by relation for: ${resourceName}`,
  );
  state.fetchByRelationActive[resourceName] = requestId;
}

function computeBaseChanges(
  currentOfflineResourcesData: ResourceInstanceRecords,
  resourceName: ResourceName,
  data: readonly ResourceInstance[],
): {
  unchangedOnServer: Partial<ResourceURLArrays>;
  updatedOnServer: Partial<ResourceInstanceRecords>;
} {
  const updatedForResource: {[url: string]: ResourceInstance} = {};
  const unchangedForResource: string[] = [];
  const currentData = currentOfflineResourcesData[resourceName];
  for (let i = 0; i < data.length; i += 1) {
    const instance = data[i];
    const {url} = instance;
    if (changeIsNewer(instance, currentData[url])) {
      updatedForResource[url] = instance;
    } else {
      unchangedForResource.push(url);
    }
  }

  const updatedOnServer = Object.keys(updatedForResource).length
    ? {[resourceName]: updatedForResource}
    : {};
  const unchangedOnServer = unchangedForResource.length
    ? {[resourceName]: unchangedForResource}
    : {};

  return {unchangedOnServer, updatedOnServer};
}

export function handlePerformRelatedFetchFulfilled(
  state: Draft<ResourcesState>,
  action: ReturnType<typeof performRelatedFetch.fulfilled>,
): void {
  const {requestId} = action.meta;
  const {memberName, resourceName, values} = action.meta.arg;

  const {results} = action.payload;

  const updatedState = current(state) as ResourcesState;
  const currentOfflineData = getOfflineData(updatedState);
  const {unchangedOnServer, updatedOnServer} = computeBaseChanges(
    currentOfflineData,
    resourceName,
    results,
  );

  updateFromReceivedData(state, currentOfflineData, unchangedOnServer, updatedOnServer);

  const resourcePersistedFetchByRelation = state.persistedFetchByRelation[resourceName];
  if (resourcePersistedFetchByRelation) {
    const resourceMemberPersistedFetchByRelation = resourcePersistedFetchByRelation[memberName];
    if (resourceMemberPersistedFetchByRelation) {
      const newResourceMemberPersistedFetchByRelation =
        resourceMemberPersistedFetchByRelation.filter((val) => !values.includes(val));
      if (newResourceMemberPersistedFetchByRelation.length) {
        resourcePersistedFetchByRelation[memberName] = newResourceMemberPersistedFetchByRelation;
      } else {
        delete resourcePersistedFetchByRelation[memberName];
        if (!Object.keys(resourcePersistedFetchByRelation).length) {
          delete state.persistedFetchByRelation[resourceName];
        }
      }
    }
  }

  const resourceTemporaryFetchByRelation = state.temporaryFetchByRelation[resourceName];
  if (resourceTemporaryFetchByRelation) {
    const resourceMemberTemporaryFetchByRelation = resourceTemporaryFetchByRelation[memberName];
    if (resourceMemberTemporaryFetchByRelation) {
      const newResourceMemberTemporaryFetchByRelation =
        resourceMemberTemporaryFetchByRelation.filter((val) => !values.includes(val));
      if (newResourceMemberTemporaryFetchByRelation.length) {
        resourceTemporaryFetchByRelation[memberName] = newResourceMemberTemporaryFetchByRelation;
      } else {
        delete resourceTemporaryFetchByRelation[memberName];
        if (!Object.keys(resourceTemporaryFetchByRelation).length) {
          delete state.temporaryFetchByRelation[resourceName];
        }
      }
    }
  }

  console.assert(
    state.fetchByRelationActive[resourceName] === requestId,
    `handlePerformRelatedFetchFulfilled: fetchByRelationActive for resource active with different request ID than expected; resourceName: ${resourceName} expected: ${requestId} was: ${state.fetchByRelationActive[resourceName]}`,
  );
  delete state.fetchByRelationActive[resourceName];
}

export function handlePerformRelatedFetchRejected(
  state: Draft<ResourcesState>,
  action: ReturnType<typeof performRelatedFetch.rejected>,
): void {
  const {requestId} = action.meta;
  const {resourceName} = action.meta.arg;
  console.assert(
    state.fetchByRelationActive[resourceName] === requestId,
    `handlePerformRelatedFetchRejected: fetchByRelationActive for resource active with different request ID than expected; resourceName: ${resourceName} expected: ${requestId} was: ${state.fetchByRelationActive[resourceName]}`,
  );
  delete state.fetchByRelationActive[resourceName];
}
