import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import _ from "lodash";
import React from "react";

export function SimpleDataTable(props: {
  headers: readonly string[];
  rows: readonly (readonly string[])[];
}): JSX.Element {
  const {headers, rows} = props;

  const headerCells = headers.map((header: string) => {
    return (
      <TableCell key={header} style={{minWidth: 220}}>
        {header}
      </TableCell>
    );
  });
  headerCells.push(<TableCell key="empty" />);

  const dataRows = rows.map((row, rowIndex) => {
    const cells = headers.map((key, cellIndex): JSX.Element => {
      return (
        <TableCell
          key={key}
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {row[cellIndex]}
        </TableCell>
      );
    });
    cells.push(<TableCell key="empty" />);

    return <TableRow key={rowIndex}>{cells}</TableRow>;
  });

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow style={{verticalAlign: "bottom"}}>{headerCells}</TableRow>
        </TableHead>
        <TableBody>{dataRows}</TableBody>
      </Table>
    </TableContainer>
  );
}
