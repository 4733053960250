import {getCurrentUserProfile, getTimerArray} from "@co-frontend-libs/redux";
import {IconButton, Theme, Toolbar, Typography, createStyles, makeStyles} from "@material-ui/core";
import bowser from "bowser";
import MenuIcon from "mdi-react/MenuIcon";
import React from "react";
import {useSelector} from "react-redux";
import {AlertBell} from "../current-alerts-bell/alert-bell";
import {TimerButton} from "./timer-button";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuButton:
      bowser.tablet || bowser.mobile
        ? {
            marginRight: theme.spacing(2),
          }
        : {
            marginRight: theme.spacing(2),
            [theme.breakpoints.up("md")]: {
              display: "none",
            },
          },
    root: {
      flexGrow: 1,
    },
    title: {
      flexGrow: 1,
    },
  }),
);

interface MenuToolbarProps {
  onMenuButton: (event: React.MouseEvent) => void;
  rightElement?: React.ReactElement | undefined;
  title: React.ReactNode;
}

export function MenuToolbar(props: MenuToolbarProps): JSX.Element {
  const classes = useStyles();
  const {onMenuButton, rightElement: rightElementFromProps, title} = props;
  const currentUserProfile = useSelector(getCurrentUserProfile);
  const timerArray = useSelector(getTimerArray);

  const hasNotifyUsersOnStart = timerArray.some(
    ({active, notifyUsersOnStart}) => active && notifyUsersOnStart,
  );

  const rightElement = (
    <>
      {rightElementFromProps}
      <TimerButton />
      {!bowser.tablet &&
        !bowser.mobile &&
        currentUserProfile?.receiveTimerStartNotifications &&
        hasNotifyUsersOnStart && <AlertBell />}
    </>
  );

  return (
    <Toolbar>
      <IconButton
        className={classes.menuButton}
        color="inherit"
        edge="start"
        onClick={onMenuButton}
      >
        <MenuIcon />
      </IconButton>
      <Typography className={classes.title} variant="h6">
        {title}
      </Typography>
      {rightElement}
    </Toolbar>
  );
}
