import {IntlShape} from "react-intl";
import {NetworkError, StatusError} from "./json-fetch";
import {getFrontendSentry} from "./sentry";

export function translateNetworkError(error: unknown, intl: IntlShape): string {
  let errorMsg = "";

  if (error instanceof NetworkError) {
    errorMsg = intl.formatMessage({
      defaultMessage:
        "Kunne ikke oprette forbindelse til CustomOffice. Denne funktion kan kun bruges når du er online.",
    });
  } else if (error instanceof StatusError) {
    if (error.data?.ORIGIN === "e-conomic") {
      if (error.data.ERROR_MESSAGE) {
        errorMsg = error.data.ERROR_MESSAGE;
      } else {
        errorMsg = intl.formatMessage({
          defaultMessage: "Kunne ikke oprette forbindelse til e-conomic, prøv igen senere.",
        });
      }
    } else if (error.data?.ERROR_MESSAGE) {
      errorMsg = error.data.ERROR_MESSAGE;
    }
  }

  const isUnhandledError = !errorMsg;
  if (isUnhandledError) {
    const sentry = getFrontendSentry();
    sentry.captureException(error);
  }

  return isUnhandledError
    ? intl.formatMessage({
        defaultMessage: "Der skete en fejl. Prøv igen senere eller kontakt support.",
      })
    : errorMsg;
}
