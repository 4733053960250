import {BatchOperation} from "app-utils";
import {useCallback, useState} from "react";

export interface BatchOperationState<T> {
  acceptCurrentBatchOperation: () => Promise<void>;
  currentBatch: T[];
  currentBatchOperation: BatchOperation<T> | null;
  openSelectBatchOperationMenu: () => void;
  setBatchOperation: (operation: BatchOperation<T> | null) => void;
  showSelectBatchOperationMenu: boolean;
  stopBatchOperation: () => void;
  updateCurrentBatch: (entity: T, selected: boolean) => void;
}

export const useBatchOperationState = <T>(): BatchOperationState<T> => {
  const [currentBatch, setCurrentBatch] = useState<T[]>([]);
  const updateCurrentBatch = useCallback(
    (entity: T, selected: boolean) => {
      if (selected) {
        setCurrentBatch([...currentBatch, entity]);
      } else {
        setCurrentBatch(currentBatch.filter((e) => e !== entity));
      }
    },
    [currentBatch, setCurrentBatch],
  );

  const [currentBatchOperation, setCurrentBatchOperation] = useState<BatchOperation<T> | null>(
    null,
  );
  const [showSelectBatchOperationMenu, setShowSelectBatchOperationMenu] = useState(false);

  const setBatchOperation = useCallback(
    (operation: BatchOperation<T> | null) => {
      setCurrentBatchOperation(operation);
      setCurrentBatch([]);
    },
    [setCurrentBatch],
  );

  const openSelectBatchOperationMenu = useCallback(() => {
    setShowSelectBatchOperationMenu(true);
  }, [setShowSelectBatchOperationMenu]);

  const stopBatchOperation = useCallback(() => {
    setCurrentBatchOperation(null);
    setShowSelectBatchOperationMenu(false);
  }, [setCurrentBatchOperation, setShowSelectBatchOperationMenu]);

  const acceptCurrentBatchOperation = useCallback(async () => {
    if (!currentBatchOperation) {
      throw new Error("Invalid batch operation state");
    }

    try {
      await currentBatchOperation.accept(currentBatch);
    } finally {
      stopBatchOperation();
    }
  }, [currentBatchOperation, currentBatch, stopBatchOperation]);

  return {
    acceptCurrentBatchOperation,
    currentBatch,
    currentBatchOperation,
    openSelectBatchOperationMenu,
    setBatchOperation,
    showSelectBatchOperationMenu,
    stopBatchOperation,
    updateCurrentBatch,
  };
};
