import {
  GenericTableProps,
  RowData,
  SortDirection,
  filterRows,
  sortRows,
} from "@co-frontend-libs/components";
import React, {useCallback, useMemo} from "react";
import {TableWithPagination} from "./table-with-pagination";
import {PaginationPageSize} from "./types";
import {useTableWithPagination} from "./use-table-with-pagination";

export interface TableDefaults<ColumnID extends string> {
  readonly defaultRowsPerPage: PaginationPageSize;
  readonly defaultSortDirection: SortDirection;
  readonly defaultSortKey: ColumnID;
}

export interface ConnectedTableWithPaginationProps<
  FieldID extends string,
  ColumnID extends string,
  KeyType extends string = string,
  DataType extends RowData<FieldID, KeyType> = RowData<FieldID, KeyType>,
> extends TableDefaults<ColumnID>,
    Pick<
      GenericTableProps<FieldID, ColumnID, KeyType, DataType>,
      | "columns"
      | "entries"
      | "filterString"
      | "groupBy"
      | "overrideSortBy"
      | "rowStyle"
      | "visibleColumns"
    > {
  filteringData: Record<string, unknown> | null;
  savePaginationIdentifier: string;
  saveSortingIdentifier: string;
}

/**
 * This table manages state internally effectively eliminating the need for
 * the calling component to keep track of column sorting and preferred rows
 * per page for the pagination.
 *
 * It is meant to be used where the client has all the data as it performs client-side
 * sorting, filtering and pagination.
 */
export const ConnectedTableWithPagination = <
  FieldID extends string,
  ColumnID extends string,
  KeyType extends string = string,
  DataType extends RowData<FieldID, KeyType> = RowData<FieldID, KeyType>,
>(
  props: ConnectedTableWithPaginationProps<FieldID, ColumnID, KeyType, DataType>,
): JSX.Element | null => {
  const {
    columns,
    defaultRowsPerPage,
    defaultSortDirection,
    defaultSortKey,
    entries,
    filteringData,
    filterString,
    groupBy,
    overrideSortBy,
    rowStyle,
    savePaginationIdentifier,
    saveSortingIdentifier,
    visibleColumns,
  } = props;

  const [page, rowsPerPage, sortBy, sortDirection, setPage, setRowsPerPage, toggleSortingKey] =
    useTableWithPagination(
      saveSortingIdentifier,
      savePaginationIdentifier,
      defaultSortKey,
      defaultSortDirection,
      defaultRowsPerPage,
      filteringData,
    );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setRowsPerPage(parseInt(event.target.value));
    },
    [setRowsPerPage],
  );

  const handleChangePage = useCallback(
    (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
      setPage(newPage);
    },
    [setPage],
  );

  const handleHeaderClick = useCallback(
    (key: ColumnID): void => {
      toggleSortingKey(key);
    },
    [toggleSortingKey],
  );

  const sortedEntries = useMemo(() => {
    return sortRows(columns, entries, sortBy, sortDirection, overrideSortBy, groupBy);
  }, [columns, entries, groupBy, overrideSortBy, sortBy, sortDirection]);

  const sortedAndFilteredEntries = useMemo(() => {
    return filterRows(columns, sortedEntries, filterString, visibleColumns);
  }, [columns, sortedEntries, filterString, visibleColumns]);

  const paginatedEntries = useMemo(() => {
    const sliceStart = page * rowsPerPage;
    const sliceEnd = sliceStart + rowsPerPage;
    return sortedAndFilteredEntries.slice(sliceStart, sliceEnd);
  }, [page, sortedAndFilteredEntries, rowsPerPage]);

  return (
    <TableWithPagination
      columns={columns}
      count={sortedAndFilteredEntries.length}
      entries={paginatedEntries}
      groupBy={groupBy}
      overrideSortBy={overrideSortBy}
      page={page}
      rowsPerPage={rowsPerPage}
      rowStyle={rowStyle}
      sortBy={sortBy}
      sortDirection={sortDirection}
      visibleColumns={visibleColumns}
      onHeaderClick={handleHeaderClick}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
};
