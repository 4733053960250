import {ComputedTime, Task, TimeCorrection, TimerStart, TimerUrl} from "@co-common-libs/resources";
import {MINUTE_MILLISECONDS} from "@co-common-libs/utils";
import _ from "lodash";
import {isNormalisedTimestamp, normaliseTimestamp} from "./timestamp-strings";

function combineSameTimerSequence(intervals: readonly ComputedTime[]): ComputedTime[] {
  if (!intervals.length) {
    return [];
  }
  const sequenceArray: ComputedTime[][] = [];
  let sequence = [intervals[0]];
  // intentionally skipping index 0
  for (let i = 1; i < intervals.length; i += 1) {
    const entry = intervals[i];
    const sequenceLast = sequence[sequence.length - 1];
    if (entry.timer === sequenceLast.timer && entry.fromTimestamp === sequenceLast.toTimestamp) {
      sequence.push(entry);
    } else {
      sequenceArray.push(sequence);
      sequence = [entry];
    }
  }
  sequenceArray.push(sequence);
  return sequenceArray.map((s) => ({
    fromTimestamp: s[0].fromTimestamp,
    timer: s[0].timer,
    toTimestamp: s[s.length - 1].toTimestamp,
  }));
}

function computeSortedTruncatedTimerstartIntervals(
  taskTimerStartArray: readonly {
    readonly deviceTimestamp: string;
    readonly timer: TimerUrl | null;
  }[],
  now?: string,
): [ComputedTime[], {fromTimestamp: string; timer: TimerUrl} | null] {
  const result: ComputedTime[] = [];
  let currentTimer: TimerUrl | null = null;
  let fromTimestamp: string | null = null;
  for (let i = 0; i < taskTimerStartArray.length; i += 1) {
    const entry = taskTimerStartArray[i];
    if (entry.timer === currentTimer) {
      continue;
    }
    if (currentTimer && fromTimestamp) {
      result.push({
        fromTimestamp,
        timer: currentTimer,
        toTimestamp: entry.deviceTimestamp,
      });
    }
    console.assert(currentTimer !== entry.timer);
    currentTimer = entry.timer;
    fromTimestamp = entry.deviceTimestamp;
  }
  const filtered = result.filter((interval) => interval.fromTimestamp !== interval.toTimestamp);
  const combined = combineSameTimerSequence(filtered);
  if (currentTimer && fromTimestamp) {
    if (now && now > fromTimestamp) {
      combined.push({fromTimestamp, timer: currentTimer, toTimestamp: now});
    }
    return [combined, {fromTimestamp, timer: currentTimer}];
  } else {
    return [combined, null];
  }
}

export function computeTimerstartIntervals(
  taskTimerStartArray: readonly {
    readonly deviceTimestamp: string;
    readonly timer: TimerUrl | null;
  }[],
  now?: string,
): [ComputedTime[], {fromTimestamp: string; timer: TimerUrl} | null] {
  const withDateObjects = _.sortBy(
    taskTimerStartArray.map(({deviceTimestamp, timer}) => ({
      timer,
      timestamp: new Date(deviceTimestamp),
    })),
    [(entry) => entry.timestamp.valueOf(), (entry) => !!entry.timer],
  );
  withDateObjects.forEach((entry) => entry.timestamp.setUTCSeconds(0, 0));
  const normalised = withDateObjects.map((entry) => ({
    deviceTimestamp: entry.timestamp.toISOString(),
    timer: entry.timer,
  }));
  return computeSortedTruncatedTimerstartIntervals(normalised, now);
}

function normaliseTimestamps<
  T extends {readonly fromTimestamp: string; readonly toTimestamp: string},
>(entry: T): T {
  const {fromTimestamp, toTimestamp} = entry;
  if (isNormalisedTimestamp(fromTimestamp) && isNormalisedTimestamp(toTimestamp)) {
    return entry;
  } else {
    return {
      ...entry,
      fromTimestamp: normaliseTimestamp(fromTimestamp),
      toTimestamp: normaliseTimestamp(toTimestamp),
    };
  }
}

function findInterval(
  intervals: readonly (ComputedTime | TimeCorrection)[],
  timestamp: string,
): ComputedTime | TimeCorrection | undefined {
  for (let i = 0; i < intervals.length; i += 1) {
    const interval = intervals[i];
    const {fromTimestamp, toTimestamp} = interval;
    if (fromTimestamp <= timestamp && timestamp < toTimestamp) {
      return interval;
    }
  }
  return undefined;
}

export function mergeIntervals(
  computedIntervals: readonly ComputedTime[],
  correctionIntervals: readonly TimeCorrection[],
  managerCorrectionIntervals: readonly TimeCorrection[],
): readonly ComputedTime[] {
  if (!correctionIntervals.length && !managerCorrectionIntervals.length) {
    return computedIntervals;
  }
  // Strategy:
  // * Find all timestamps where "something happens" --- any from/to timestamps
  // * For each of those, check what timer would start or be active here
  //   - In particular, we consider the input intervals half-closed; [from, to)
  //   - Have the corrections override the computed
  // * Format this like a sequence of "timerStart"-entries
  // * Use computeTimerstartIntervals() to turn this back into a sequence of
  //   intervals --- it handles repetitions and "null"-entries...

  const normalisedComputedIntervals = computedIntervals.map(normaliseTimestamps);
  const normalisedCorrectionIntervals = correctionIntervals.map(normaliseTimestamps);
  const normalisedManagerCorrectionIntervals = managerCorrectionIntervals.map(normaliseTimestamps);

  const timestampSet = new Set<string>();
  const addToTimestampSet = (entry: {
    readonly fromTimestamp: string;
    readonly toTimestamp: string;
  }): void => {
    const {fromTimestamp} = entry;
    const {toTimestamp} = entry;
    timestampSet.add(fromTimestamp);
    timestampSet.add(toTimestamp);
  };

  normalisedComputedIntervals.forEach(addToTimestampSet);
  normalisedCorrectionIntervals.forEach(addToTimestampSet);
  normalisedManagerCorrectionIntervals.forEach(addToTimestampSet);
  const timestampArray = Array.from(timestampSet);
  timestampArray.sort();

  // determine timer that would start/continue at each of the "interesting" timestamps
  const timerStarts = timestampArray.map((timestamp) => {
    const match =
      findInterval(normalisedManagerCorrectionIntervals, timestamp) ||
      findInterval(normalisedCorrectionIntervals, timestamp) ||
      findInterval(normalisedComputedIntervals, timestamp);
    return {deviceTimestamp: timestamp, timer: match ? match.timer : null};
  });
  const [mergedIntervals, activeInterval] = computeSortedTruncatedTimerstartIntervals(timerStarts);
  console.assert(
    !activeInterval,
    `Expected no active interval, got: ${JSON.stringify(activeInterval)}`,
  );
  return mergedIntervals;
}

export function getTaskTimersWithTime(
  task: Task,
  taskTimerStartArray: readonly Readonly<TimerStart>[],
): Set<TimerUrl> {
  const [computedIntervals, activeInterval] = computeTimerstartIntervals(taskTimerStartArray);
  const correctionIntervals = task.machineOperatorTimeCorrectionSet || [];
  const managerCorrectionIntervals = task.managerTimeCorrectionSet || [];

  if (!correctionIntervals.length && !managerCorrectionIntervals.length) {
    // simple calculation
    const result = new Set(computedIntervals.map((interval) => interval.timer));
    if (activeInterval) {
      result.add(activeInterval.timer);
    }
    return result;
  } else {
    // complex calculation
    const mergedIntervals = mergeIntervals(
      computedIntervals,
      correctionIntervals,
      managerCorrectionIntervals,
    );
    const result = new Set(mergedIntervals.map((interval) => interval.timer));
    if (activeInterval) {
      result.add(activeInterval.timer);
    }
    return result;
  }
}

export const injectUnregisteredBreaks = (
  intervals: {
    readonly fromTimestamp: string;
    readonly timer: TimerUrl | null;
    readonly toTimestamp: string;
  }[],
  unregisteredBreakAfterMinutes: number,
  periodSplitThresholdMinutes: number,
  breakTimerURL: TimerUrl | null,
): {
  readonly fromTimestamp: string;
  readonly timer: TimerUrl | null;
  readonly toTimestamp: string;
}[] => {
  if (!intervals.length) {
    return intervals;
  }
  const unregisteredBreakAfterMilliseconds = unregisteredBreakAfterMinutes * MINUTE_MILLISECONDS;
  const splitThresholdMilliseconds = periodSplitThresholdMinutes * MINUTE_MILLISECONDS;
  const mutable: {
    readonly fromTimestamp: string;
    readonly timer: TimerUrl | null;
    readonly toTimestamp: string;
  }[] = [];
  let previousInterval = intervals[0];
  mutable.push(previousInterval);
  intervals.slice(1).forEach((interval) => {
    const unregisteredMilliseconds =
      new Date(interval.fromTimestamp).valueOf() - new Date(previousInterval.toTimestamp).valueOf();
    if (
      unregisteredMilliseconds > unregisteredBreakAfterMilliseconds &&
      unregisteredMilliseconds < splitThresholdMilliseconds
    ) {
      mutable.push({
        fromTimestamp: previousInterval.toTimestamp,
        timer: breakTimerURL,
        toTimestamp: interval.fromTimestamp,
      });
    }
    mutable.push(interval);
    previousInterval = interval;
  });
  return mutable;
};

export const groupIntervals = <
  T extends {
    readonly fromTimestamp: string;
    readonly toTimestamp: string;
  },
>(
  intervals: readonly T[],
  splitThresholdMinutes: number,
): T[][] => {
  console.assert(Array.isArray(intervals));
  console.assert(typeof splitThresholdMinutes === "number");
  if (!intervals.length) {
    return [];
  }
  const mutableGroupedIntervals: T[][] = [];
  const splitThresholdMilliseconds = splitThresholdMinutes * MINUTE_MILLISECONDS;
  let i = 0;
  mutableGroupedIntervals[i] = [intervals[0]];
  let previousTo = new Date(intervals[0].toTimestamp);
  intervals.slice(1).forEach((interval) => {
    const intervalFrom = new Date(interval.fromTimestamp);
    const intervalTo = new Date(interval.toTimestamp);
    const difference = intervalFrom.valueOf() - previousTo.valueOf();
    if (difference < splitThresholdMilliseconds) {
      mutableGroupedIntervals[i].push(interval);
    } else {
      i += 1;
      mutableGroupedIntervals[i] = [interval];
    }
    if (intervalTo > previousTo) {
      previousTo = intervalTo;
    }
  });
  return mutableGroupedIntervals;
};
