import {ReportingSpecificationUrl} from "@co-common-libs/resources";
import {notUndefined} from "@co-common-libs/utils";
import {ConnectedMultipleReportingSpecificationDialog} from "@co-frontend-libs/connected-components";
import {
  actions,
  getCurrentUserURL,
  getReportingSpecificationArray,
  getReportingSpecificationLookup,
  getSettingsEntryLookupByIdentifier,
} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import React, {useCallback, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {SettingViewProps} from "../types";
import {DisplaySelected} from "./display-selected";

export function SelectReportingSpecifications(props: SettingViewProps): JSX.Element {
  const {settingID, settingMetaData} = props;
  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const settingEntry = settingsEntryLookupByIdentifier(settingID);
  const selectedReportingSpecifications: string[] = useMemo(
    () => settingEntry?.data || [],
    [settingEntry?.data],
  );
  const reportingSpecificationArray = useSelector(getReportingSpecificationArray);
  const reportingSpecificationLookup = useSelector(getReportingSpecificationLookup);
  const reportingSpecificationInstances = useMemo(() => {
    return selectedReportingSpecifications
      .map((identifier) =>
        reportingSpecificationArray
          .filter((spec) => spec.active)
          .find((w) => w.identifier === identifier),
      )
      .filter(notUndefined);
  }, [selectedReportingSpecifications, reportingSpecificationArray]);

  const reportingSpecificationLabels = useMemo(() => {
    const labels = reportingSpecificationInstances.map(
      (reportingSpecification) =>
        `${reportingSpecification.identifier}: ${reportingSpecification.name}`,
    );
    labels.sort();
    return labels;
  }, [reportingSpecificationInstances]);

  const [reportingSpecificationDialogOpen, setReportingSpecificationDialogOpen] = useState(false);
  const setReportingSpecificationDialogOpenTrue = useCallWithTrue(
    setReportingSpecificationDialogOpen,
  );
  const setReportingSpecificationDialogOpenFalse = useCallWithFalse(
    setReportingSpecificationDialogOpen,
  );

  const dispatch = useDispatch();
  const currentUserURL = useSelector(getCurrentUserURL);
  const handleReportingSpecificationDialogOk = useCallback(
    (urls: ReadonlySet<ReportingSpecificationUrl>) => {
      setReportingSpecificationDialogOpen(false);

      const newValue = [...urls].map((url) => reportingSpecificationLookup(url)?.identifier);

      if (settingEntry) {
        dispatch(
          actions.update(settingEntry.url, [
            {member: "changedBy", value: currentUserURL},
            {member: "data", value: newValue},
          ]),
        );
      }
    },
    [currentUserURL, dispatch, settingEntry, reportingSpecificationLookup],
  );

  const seletedReportingSpecifications = useMemo(() => {
    return new Set(
      reportingSpecificationInstances.map((reportingSpecification) => reportingSpecification?.url),
    );
  }, [reportingSpecificationInstances]);

  return (
    <>
      <DisplaySelected
        currentValue={reportingSpecificationLabels}
        settingEntry={settingEntry}
        settingID={settingID}
        settingMetaData={settingMetaData}
        onClick={setReportingSpecificationDialogOpenTrue}
      />
      <ConnectedMultipleReportingSpecificationDialog
        includeOnlyReportingSpecificationsWithIdentifiers
        open={reportingSpecificationDialogOpen}
        selected={seletedReportingSpecifications}
        onCancel={setReportingSpecificationDialogOpenFalse}
        onOk={handleReportingSpecificationDialogOk}
      />
    </>
  );
}
