import {Journal, LocationUrl, WorkType, WorkTypeUrl} from "@co-common-libs/resources";
import {getWorkTypeString} from "@co-common-libs/resources-utils";
import {caseAccentInsensitiveCollator} from "@co-common-libs/utils";
import {TrimTextField} from "@co-frontend-libs/components";
import {
  ConnectedMultiLocationDialog,
  ConnectedMultipleExternalWorkTypesDialog,
} from "@co-frontend-libs/connected-components";
import {
  actions,
  getCustomerSettings,
  getJournalLookup,
  getLocationLookup,
  getWorkTypeLookup,
  makePathParameterGetter,
} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {Button, Checkbox, FormControlLabel, Paper} from "@material-ui/core";
import {PageLayout, WorkTypeChip} from "app-components";
import {getLocationString, useEventTargetCheckedUpdater, useFieldUpdater} from "app-utils";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import React, {useCallback, useMemo, useState} from "react";
// Allowed for existing code...
// eslint-disable-next-line deprecate/import
import {Cell, Grid} from "react-flexr";
import {FormattedMessage, defineMessages, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {DeletableChip} from "./reports/deletable-chip";

const messages = defineMessages({
  editJournal: {
    defaultMessage: "Redigér journal",
  },
  note: {
    defaultMessage: "Note",
  },
  reminder: {
    defaultMessage: "Påmindelse",
  },
  selectWorkTypesButton: {
    defaultMessage: "Vælg arbejdsområder",
  },
});

export const JournalEntry = React.memo(function JournalEntry(): JSX.Element {
  const [workTypeDialogOpen, setWorkTypeDialogOpen] = useState(false);
  const setWorkTypeDialogOpenTrue = useCallWithTrue(setWorkTypeDialogOpen);
  const setWorkTypeDialogOpenFalse = useCallWithFalse(setWorkTypeDialogOpen);

  const [locationDialogOpen, setLocationDialogOpen] = useState(false);
  const setLocationDialogOpenTrue = useCallWithTrue(setLocationDialogOpen);
  const setLocationDialogOpenFalse = useCallWithFalse(setLocationDialogOpen);

  const customerSettings = useSelector(getCustomerSettings);
  const workTypeLookup = useSelector(getWorkTypeLookup);
  const locationLookup = useSelector(getLocationLookup);
  const idParameterGetter = useMemo(() => makePathParameterGetter("id"), []);
  const id = useSelector(idParameterGetter);
  const journalLookup = useSelector(getJournalLookup);
  const intl = useIntl();
  const journalURL = instanceURL("journal", id);
  const journal = journalLookup(journalURL);
  const dispatch = useDispatch();

  const handleWorkTypeDialogOk = useCallback(
    (urls: ReadonlySet<WorkTypeUrl>) => {
      dispatch(actions.update(journalURL, [{member: "workTypes", value: [...urls]}]));
      setWorkTypeDialogOpen(false);
    },
    [dispatch, journalURL],
  );

  const handleRemoveWorkType = useCallback(
    (workType: WorkType) => {
      if (journal) {
        const newWorktypeList = journal.workTypes.filter(
          (workTypeURL) => workTypeURL !== workType.url,
        );
        dispatch(actions.update(journalURL, [{member: "workTypes", value: newWorktypeList}]));
      }
    },
    [dispatch, journal, journalURL],
  );

  const handleLocationDialogOk = useCallback(
    (urls: ReadonlySet<LocationUrl>) => {
      dispatch(actions.update(journalURL, [{member: "locations", value: [...urls]}]));
      setLocationDialogOpen(false);
    },
    [dispatch, journalURL],
  );

  const handleRemoveLocation = useCallback(
    (url: LocationUrl) => {
      if (journal) {
        const newLocationList = (journal.locations || []).filter(
          (locationURL) => locationURL !== url,
        );
        dispatch(actions.update(journalURL, [{member: "locations", value: newLocationList}]));
      }
    },
    [dispatch, journal, journalURL],
  );

  let reminderFor;
  if (
    customerSettings.chooseWorkTypesOnCustomerJournal &&
    !customerSettings.noExternalTaskWorkType
  ) {
    const workTypes = ((journal && journal.workTypes) || [])
      .map(workTypeLookup)
      .sort((a, b) =>
        caseAccentInsensitiveCollator.compare(
          getWorkTypeString(a, true),
          getWorkTypeString(b, true),
        ),
      );
    reminderFor = (
      <div>
        <div style={{paddingBottom: 5}}>
          <Button color="secondary" variant="contained" onClick={setWorkTypeDialogOpenTrue}>
            {intl.formatMessage(messages.selectWorkTypesButton)}
          </Button>
        </div>
        {workTypes.map((workTypeInstance) => {
          if (!workTypeInstance) {
            return null;
          }
          return (
            <span key={workTypeInstance.url} style={{paddingRight: 5}}>
              <WorkTypeChip workType={workTypeInstance} onDeleteClick={handleRemoveWorkType} />
            </span>
          );
        })}
        <FormattedMessage
          defaultMessage="Såfremt der ingen områder er valgt, vises påmindelsen for alle områder"
          tagName="div"
        />
      </div>
    );
  }
  const selectedWorkTypes = useMemo(() => new Set(journal?.workTypes || []), [journal?.workTypes]);
  const selectedLocations = useMemo(() => new Set(journal?.locations || []), [journal?.locations]);
  const dialogs = [
    <ConnectedMultipleExternalWorkTypesDialog
      key="work-type-dialog"
      open={!!workTypeDialogOpen}
      selected={selectedWorkTypes}
      onCancel={setWorkTypeDialogOpenFalse}
      onOk={handleWorkTypeDialogOk}
    />,
    <ConnectedMultiLocationDialog
      key="location-dialog"
      includeLogOnlyLocations
      includeWorkplaceOnlyLocations
      customerURL={journal?.customer || null}
      open={locationDialogOpen}
      selected={selectedLocations}
      titleVariant="WORKPLACE"
      onCancel={setLocationDialogOpenFalse}
      onOk={handleLocationDialogOk}
    />,
  ];

  return (
    <PageLayout
      withBottomScrollPadding
      dialogs={dialogs}
      toolbar={intl.formatMessage(messages.editJournal)}
    >
      <Paper
        style={{
          margin: 5,
          padding: 10,
        }}
      >
        <Grid>
          <Cell palm="12/12">
            <TrimTextField
              fullWidth
              multiline
              id="note-field"
              label={intl.formatMessage(messages.note)}
              margin="dense"
              minRows={5}
              name="note"
              value={(journal && journal.entry) || ""}
              variant="outlined"
              onChange={useFieldUpdater<Journal, string>(journalURL, "entry")}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={(journal && journal.reminder) || false}
                  id="reminder-checkbox"
                  name="reminder"
                  onChange={useEventTargetCheckedUpdater<Journal>(journalURL, "reminder")}
                />
              }
              label={intl.formatMessage(messages.reminder)}
            />
          </Cell>
          <Cell>{reminderFor}</Cell>
          <Cell>
            {
              <div>
                <div style={{paddingBottom: 5}}>
                  <Button color="secondary" variant="contained" onClick={setLocationDialogOpenTrue}>
                    {intl.formatMessage({defaultMessage: "Vælg steder"})}
                  </Button>
                </div>
                {((journal && journal.locations) || [])
                  .map((locationURL) => locationLookup(locationURL))
                  .sort((a, b) =>
                    caseAccentInsensitiveCollator.compare(
                      a ? getLocationString(a) : "",
                      b ? getLocationString(b) : "",
                    ),
                  )
                  .map((locationInstance) => {
                    if (!locationInstance) {
                      return null;
                    }
                    return (
                      <span key={locationInstance.url} style={{paddingRight: 5}}>
                        <DeletableChip
                          deletionId={locationInstance.url}
                          label={getLocationString(locationInstance)}
                          onDelete={handleRemoveLocation}
                        />
                      </span>
                    );
                  })}
                <FormattedMessage
                  defaultMessage="Såfremt der ingen steder er valgt, vises påmindelsen for alle steder"
                  tagName="div"
                />
              </div>
            }
          </Cell>
        </Grid>
      </Paper>
    </PageLayout>
  );
});
