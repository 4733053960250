import React from "react";
import {SettingValueDisplayProps} from "../types";

export function DisplayColor(props: SettingValueDisplayProps): JSX.Element | null {
  const {settingEntry} = props;

  if (!settingEntry || !settingEntry.data) {
    return null;
  }

  return (
    <div
      style={{
        backgroundColor: settingEntry.data,
        height: 20,
        padding: 2,
        width: 100,
      }}
    />
  );
}
