import {dateFromString, formatDate} from "@co-common-libs/utils";
import {useTheme} from "@material-ui/core";
import {grey} from "@material-ui/core/colors";
import _ from "lodash";
import React from "react";
import {DAY_WIDTH} from "./constants";

interface DateCellProps {
  date: string;
  isToday: boolean;
}

export const DateCell = React.memo(function DateCell(props: DateCellProps): JSX.Element {
  const {date, isToday} = props;

  const theme = useTheme();

  let backgroundColor: string =
    (dateFromString(date) as Date).getDate() % 2 ? grey[100] : grey[200];
  const style: React.CSSProperties = {
    backgroundColor,
    display: "inline-block",
    textAlign: "center",
    width: DAY_WIDTH,
  };
  if (isToday) {
    backgroundColor = style.backgroundColor = theme.palette.primary.main;
    style.color = theme.palette.getContrastText(backgroundColor);
  }
  return <div style={style}>{_.capitalize(formatDate(date))}</div>;
});
