const IS_CORDOVA = typeof cordova !== "undefined";
const HAS_PUSHNOTIFICATION = typeof PushNotification !== "undefined";

let pushNotification: PhonegapPluginPush.PushNotification | null = null;

function initialize(): void {
  if (IS_CORDOVA && HAS_PUSHNOTIFICATION) {
    pushNotification = PushNotification.init({
      android: {},
      ios: {
        badge: true,
        sound: true,
      },
    });
  }
}

export function getPushNotification(): PhonegapPluginPush.PushNotification | null {
  if (!pushNotification) {
    initialize();
  }
  return pushNotification;
}
