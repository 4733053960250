import {SettingID, SettingMetaData} from "@co-common-libs/config";
import Ajv, {ErrorObject} from "ajv";
import {JSONSchema7} from "json-schema";
import _ from "lodash";
import type {DeepReadonly} from "ts-essentials";

const ajv = new Ajv({
  allErrors: true,
  validateSchema: false,
});

export function settingValueIsBlank(value: unknown): boolean {
  return (
    value === undefined ||
    value === null ||
    value === false ||
    value === "" ||
    (Array.isArray(value) && value.length === 0) ||
    (typeof value === "object" && value !== null && Object.keys(value).length === 0)
  );
}

export function getValidator(
  schema: DeepReadonly<JSONSchema7>,
): (data: unknown) => ErrorObject[] | null {
  const validate = ajv.compile(schema);
  return function validator(data: unknown): ErrorObject[] | null {
    validate(data);
    return validate.errors ?? null;
  };
}

export function checkConflictsWith(
  settingMetaData: SettingMetaData,
  nonBlankSettings: ReadonlySet<SettingID>,
  value: unknown,
): readonly SettingID[] | null {
  if (settingValueIsBlank(value)) {
    return null;
  }
  const {conflictsWith} = settingMetaData;
  if (conflictsWith.length && conflictsWith.some((other) => nonBlankSettings.has(other))) {
    return conflictsWith;
  } else {
    return null;
  }
}

export function checkRequiresAllOf(
  settingMetaData: SettingMetaData,
  nonBlankSettings: ReadonlySet<SettingID>,
  value: unknown,
): readonly SettingID[] | null {
  if (settingValueIsBlank(value)) {
    return null;
  }
  const {requiresAllOf} = settingMetaData;
  if (requiresAllOf.length && requiresAllOf.some((other) => !nonBlankSettings.has(other))) {
    return requiresAllOf;
  } else {
    return null;
  }
}

export function checkRequiresOneOf(
  settingMetaData: SettingMetaData,
  nonBlankSettings: ReadonlySet<SettingID>,
  value: unknown,
): readonly SettingID[] | null {
  if (settingValueIsBlank(value)) {
    return null;
  }
  const {requiresOneOf} = settingMetaData;
  if (requiresOneOf.length && requiresOneOf.every((other) => !nonBlankSettings.has(other))) {
    return requiresOneOf;
  } else {
    return null;
  }
}

export function checkNoopWithoutAllOf(
  settingMetaData: SettingMetaData,
  nonBlankSettings: ReadonlySet<SettingID>,
  value: unknown,
): readonly SettingID[] | null {
  if (settingValueIsBlank(value)) {
    return null;
  }
  const {noopWithoutAllOf} = settingMetaData;
  if (noopWithoutAllOf.length && noopWithoutAllOf.some((other) => !nonBlankSettings.has(other))) {
    return noopWithoutAllOf;
  } else {
    return null;
  }
}

export function checkNoopWithoutOneOf(
  settingMetaData: SettingMetaData,
  nonBlankSettings: ReadonlySet<SettingID>,
  value: unknown,
): readonly SettingID[] | null {
  if (settingValueIsBlank(value)) {
    return null;
  }
  const {noopWithoutOneOf} = settingMetaData;
  if (noopWithoutOneOf.length && noopWithoutOneOf.every((other) => !nonBlankSettings.has(other))) {
    return noopWithoutOneOf;
  } else {
    return null;
  }
}
