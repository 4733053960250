import {
  CustomerUrl,
  Project,
  ProjectAccess,
  ProjectUrl,
  TaskUrl,
  emptyProject,
  urlToId,
} from "@co-common-libs/resources";
import {formatAddress} from "@co-common-libs/utils";
import {useModal} from "@co-frontend-libs/components";
import {ConnectedProjectDialog} from "@co-frontend-libs/connected-components";
import {
  actions,
  diffResourceInstanceProperties,
  getContactArray,
  getCurrentUserURL,
  getCustomerLookup,
  getCustomerSettings,
  getOrderLookup,
  getProjectLookup,
  getTaskArray,
  getTaskLookup,
  getUnitLookup,
} from "@co-frontend-libs/redux";
import {Button, IconButton, Typography} from "@material-ui/core";
import {taskChangeCustomerCulture} from "app-utils";
import {instanceURL} from "frontend-global-config";
import CloseIcon from "mdi-react/CloseIcon";
import React, {Ref, forwardRef, useCallback, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import {v4 as uuid} from "uuid";
import {ProjectCreateEditDialog, ProjectData} from "../create-edit-dialogs";
import {CustomerSelectCreateDialog} from "../customer-select-create-dialog";
import {InformationBlockPropsBase} from "./information-block-props-base";
import {ProjectInfo} from "./project-info";

export function useHandleTaskProjectChange(
  onTaskMovedToNewOrder?: (orderId: string, taskId: string) => void,
): (url: ProjectUrl, taskUrl: TaskUrl) => void {
  const contactArray = useSelector(getContactArray);
  const customerLookup = useSelector(getCustomerLookup);
  const currentUserUrl = useSelector(getCurrentUserURL);
  const taskArray = useSelector(getTaskArray);
  const orderLookup = useSelector(getOrderLookup);
  const unitLookup = useSelector(getUnitLookup);
  const projectLookup = useSelector(getProjectLookup);
  const taskLookup = useSelector(getTaskLookup);
  const customerSettings = useSelector(getCustomerSettings);
  const dispatch = useDispatch();

  return useCallback(
    (url: ProjectUrl, taskUrl: TaskUrl): void => {
      const task = taskUrl && taskLookup(taskUrl);
      if (!task) {
        return;
      }

      const newProject = projectLookup(url);
      const projectCustomerUrl = newProject && newProject.customer;
      const orderId = task.order && urlToId(task.order);
      if (!projectCustomerUrl || !orderId) {
        return;
      }

      const possiblyNewOrderId = taskChangeCustomerCulture(
        projectCustomerUrl,
        null,
        {
          contactArray,
          create: bindActionCreators(actions.create, dispatch),
          customerLookup,
          orderLookup,
          task,
          taskArray,
          unitLookup,
          update: bindActionCreators(actions.update, dispatch),
        },
        customerSettings,
        currentUserUrl,
      );
      const customer = projectCustomerUrl && customerLookup(projectCustomerUrl);
      const address = customer ? formatAddress(customer) : "";

      const changes = diffResourceInstanceProperties({address, project: url}, task);
      dispatch(actions.update(taskUrl, changes));

      if (onTaskMovedToNewOrder && possiblyNewOrderId && possiblyNewOrderId !== orderId) {
        onTaskMovedToNewOrder(possiblyNewOrderId, urlToId(task.url));
      }
    },
    [
      contactArray,
      currentUserUrl,
      customerLookup,
      customerSettings,
      dispatch,
      onTaskMovedToNewOrder,
      orderLookup,
      projectLookup,
      taskArray,
      taskLookup,
      unitLookup,
    ],
  );
}

export function useProjectSetters(
  customerUrl: CustomerUrl | null,
  onProjectChanged: (projectUrl: ProjectUrl | null) => void,
  onCustomerChanged: (customerUrl: CustomerUrl | null) => void,
  defaultAccessOnCreate: ProjectAccess,
): [
  typeof selectProjectModal,
  typeof createProjectModal,
  typeof customerModal,
  typeof handleProjectSelectButton,
] {
  const dispatch = useDispatch();
  const customerSettings = useSelector(getCustomerSettings);
  const projectLookup = useSelector(getProjectLookup);
  const [projectSelectionAbortController] = useState<AbortController>(new AbortController());

  const {canAddProject} = customerSettings;

  const [customerModal, customerPrompt] = useModal(CustomerSelectCreateDialog);
  const [selectProjectModal, promptForSelectProject] = useModal(ConnectedProjectDialog);
  const [createProjectModal, promptForCreateProject] = useModal(ProjectCreateEditDialog);

  const handleProjectCreateEditDialogOk = useCallback(
    (data: ProjectData): void => {
      const id = uuid();
      const url = instanceURL("project", id);
      const newProject: Project = {
        ...emptyProject,
        ...data,
        id,
        url,
      };
      dispatch(actions.create(newProject));
      if (data.customer !== customerUrl) {
        onCustomerChanged(data.customer);
      }
      onProjectChanged(url);
      projectSelectionAbortController.abort();
    },
    [customerUrl, dispatch, onCustomerChanged, onProjectChanged, projectSelectionAbortController],
  );

  const handleAddProject = useCallback(async (): Promise<void> => {
    const selectedCustomerUrl = customerUrl || (await customerPrompt());
    if (!selectedCustomerUrl) {
      return;
    }
    const data = await promptForCreateProject({data: selectedCustomerUrl, defaultAccessOnCreate});
    if (data) {
      handleProjectCreateEditDialogOk(data);
    }
  }, [
    customerUrl,
    customerPrompt,
    promptForCreateProject,
    defaultAccessOnCreate,
    handleProjectCreateEditDialogOk,
  ]);

  const handleProjectSelectButton = useCallback(async (): Promise<void> => {
    const selectedProjectUrl = await promptForSelectProject(
      {
        customerURL: customerUrl,
        onAdd: canAddProject ? handleAddProject : undefined,
        suggestRecentlyUsed: true,
      },
      projectSelectionAbortController.signal,
    );
    if (selectedProjectUrl) {
      const newProject = projectLookup(selectedProjectUrl);
      if (newProject && newProject.customer !== customerUrl) {
        onCustomerChanged(newProject.customer);
      }
      onProjectChanged(selectedProjectUrl);
    }
  }, [
    promptForSelectProject,
    customerUrl,
    canAddProject,
    handleAddProject,
    projectSelectionAbortController.signal,
    projectLookup,
    onProjectChanged,
    onCustomerChanged,
  ]);

  return [selectProjectModal, createProjectModal, customerModal, handleProjectSelectButton];
}

interface ProjectBlockProps extends InformationBlockPropsBase {
  customerUrl: CustomerUrl | null;
  defaultAccessOnCreate: ProjectAccess;
  onCustomerChanged: (customerUrl: CustomerUrl | null) => void;
  onProjectChanged: (projectUrl: ProjectUrl | null) => void;
  projectUrl: ProjectUrl | null;
}

export const ProjectBlock = forwardRef(function ProjectBlock(
  {
    clearDisabled,
    customerUrl,
    defaultAccessOnCreate,
    hideButtons,
    onCustomerChanged,
    onProjectChanged,
    projectUrl,
    selectDisabled,
  }: ProjectBlockProps,
  selectButtonRef: Ref<HTMLButtonElement>,
): React.JSX.Element {
  const projectLookup = useSelector(getProjectLookup);
  const customerSettings = useSelector(getCustomerSettings);
  const {projectLabelVariant} = customerSettings;

  const [
    selectProjectDialog,
    createProjectDialog,
    selectCustomerDialog,
    handleProjectSelectButton,
  ] = useProjectSetters(customerUrl, onProjectChanged, onCustomerChanged, defaultAccessOnCreate);

  const handleRemoveProject = useCallback((): void => {
    onProjectChanged(null);
  }, [onProjectChanged]);

  const project = projectUrl && projectLookup(projectUrl);

  return (
    <>
      <Typography variant="body1">
        {projectLabelVariant === "PROJECT" ? (
          <FormattedMessage defaultMessage="Projekt" />
        ) : (
          <FormattedMessage defaultMessage="Sag" />
        )}
      </Typography>

      {!hideButtons && (
        <>
          <Button
            ref={selectButtonRef}
            color="secondary"
            disabled={selectDisabled}
            variant="contained"
            onClick={handleProjectSelectButton}
          >
            <FormattedMessage defaultMessage="Vælg" />
          </Button>
          <IconButton disabled={clearDisabled} onClick={handleRemoveProject}>
            <CloseIcon />
          </IconButton>
        </>
      )}
      {project && <ProjectInfo project={project} />}
      {selectProjectDialog}
      {createProjectDialog}
      {selectCustomerDialog}
    </>
  );
});
