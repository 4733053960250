import {MINUTE_MILLISECONDS} from "@co-common-libs/utils";
import * as actions from "../resources/actions";
import {partialEntriesForEach} from "../resources/utils";
import {ResourcesAuthenticationMiddlewareAPI} from "./types";

const MEMORY_CLEANUP_TIMEOUT_MINUTES = 5;
const MEMORY_CLEANUP_TIMEOUT = MEMORY_CLEANUP_TIMEOUT_MINUTES * MINUTE_MILLISECONDS;

// query-identifier -> timeout-id
const purgeTimeouts = new Map<string, number>();

export function startStopPurgeTimeouts(middlewareApi: ResourcesAuthenticationMiddlewareAPI): void {
  const {temporaryQueries} = middlewareApi.getState().resources;
  partialEntriesForEach(temporaryQueries, (queryIdentifier, queryQueryStateStruct) => {
    const {query, queryState} = queryQueryStateStruct;
    if (queryState.pendingRemoval) {
      if (!purgeTimeouts.has(queryIdentifier)) {
        const timeoutID = window.setTimeout(() => {
          middlewareApi.dispatch(actions.temporaryQueryPurged(query));
        }, MEMORY_CLEANUP_TIMEOUT);
        purgeTimeouts.set(queryIdentifier, timeoutID);
      }
    } else {
      console.assert(!queryState.pendingRemoval);
      const timeoutID = purgeTimeouts.get(queryIdentifier);
      if (timeoutID !== undefined) {
        window.clearTimeout(timeoutID);
        purgeTimeouts.delete(queryIdentifier);
      }
    }
  });
}
