import {Unit, UnitUrl} from "@co-common-libs/resources";
import {MultipleUnitDialog, SingleUnitDialog} from "@co-frontend-libs/components";
import {AppState, getUnitArray} from "@co-frontend-libs/redux";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";

interface UnitDialogStateProps {
  unitArray: readonly Unit[];
}

interface MultipleUnitDialogOwnProps {
  onCancel(): void;
  onOk(urls: ReadonlySet<UnitUrl>): void;
  open: boolean;
  selected?: ReadonlySet<UnitUrl> | undefined;
}

export const ConnectedMultipleUnitDialog: React.ComponentType<MultipleUnitDialogOwnProps> = connect<
  UnitDialogStateProps,
  object,
  MultipleUnitDialogOwnProps,
  AppState
>(
  createStructuredSelector<AppState, UnitDialogStateProps>({
    unitArray: getUnitArray,
  }),
  {},
)(MultipleUnitDialog);

interface SingleUnitDialogOwnProps {
  onCancel(): void;
  onOk(url: UnitUrl): void;
  open: boolean;
}

export const ConnectedSingleUnitDialog: React.ComponentType<SingleUnitDialogOwnProps> = connect<
  UnitDialogStateProps,
  object,
  SingleUnitDialogOwnProps,
  AppState
>(
  createStructuredSelector<AppState, UnitDialogStateProps>({
    unitArray: getUnitArray,
  }),
  {},
)(SingleUnitDialog);
