import {OrderUrl} from "@co-common-libs/resources";
import {CircularProgress} from "@material-ui/core";
import {PhotosCard, useAppendicesData} from "app-components";
import React, {useMemo} from "react";

interface OrderPhotoAppendicesProps {
  orderUrl: OrderUrl;
}

export const OrderPhotoAppendices = React.memo(function OrderPhotoAppendices(
  props: OrderPhotoAppendicesProps,
): React.JSX.Element {
  const {orderUrl} = props;
  const state = useAppendicesData(useMemo(() => ({order: orderUrl}), [orderUrl]));

  if (state.fetching) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  return <PhotosCard data={state.data?.photoTasks ?? null} />;
});
