import {IntlShape, defineMessages} from "react-intl";
import {InlinedTransportLog} from "../../inline-data";

const messages = defineMessages({
  transportLogMismatch: {
    defaultMessage:
      "Transportlog angiver at der er hentet {pickup} {unit} men leveret {delivery} {unit}.",
  },
});

const ROUND_NUMBER = 100;

export const getTransportlogIssues = (
  transportLog: InlinedTransportLog,
  intl: IntlShape,
): string | null => {
  const pickupList = transportLog._pickupList;
  const deliveryList = transportLog._deliveryList;
  let pickupSum = pickupList.reduce((acc, pickup) => {
    return acc + (pickup.amount || 0);
  }, 0);
  pickupSum = Math.round(pickupSum * ROUND_NUMBER) / ROUND_NUMBER;
  let deliverySum = deliveryList.reduce((acc, delivery) => {
    return acc + (delivery.amount || 0);
  }, 0);
  deliverySum = Math.round(deliverySum * ROUND_NUMBER) / ROUND_NUMBER;
  if (pickupSum !== deliverySum) {
    return intl.formatMessage(messages.transportLogMismatch, {
      delivery: deliverySum || "0",
      pickup: pickupSum || "0",
      unit: transportLog.unit,
    });
  } else {
    return null;
  }
};
