import {OrderUrl} from "@co-common-libs/resources";
import {CircularProgress} from "@material-ui/core";
import {LogsCard, useAppendicesData} from "app-components";
import React, {useMemo} from "react";

interface OrderPhotoAppendicesProps {
  orderUrl: OrderUrl;
}
export const OrderLogAppendices = React.memo(function OrderLogAppendices(
  props: OrderPhotoAppendicesProps,
): React.JSX.Element {
  const {orderUrl} = props;

  const state = useAppendicesData(useMemo(() => ({order: orderUrl}), [orderUrl]));

  if (state.fetching) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  return <LogsCard data={state.data?.logTasks ?? null} />;
});
