import {CropUrl} from "@co-common-libs/resources";
import {ColorPickerDialog} from "@co-frontend-libs/components";
import {actions, getCropArray, getCropLookup} from "@co-frontend-libs/redux";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import _ from "lodash";
import React, {useCallback, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

interface RowProps {
  color: string;
  name: string;
  onChangeColorClick: (url: CropUrl) => void;
  url: CropUrl;
}

const Row = React.memo(function Row({color, name, onChangeColorClick, url}: RowProps): JSX.Element {
  const handleChangeColorClick = useCallback(() => {
    onChangeColorClick(url);
  }, [onChangeColorClick, url]);

  return (
    <TableRow>
      <TableCell>
        <div
          style={{
            backgroundColor: color,
            border: "1px solid #000",
            height: 16,
            width: 16,
          }}
          onClick={handleChangeColorClick}
        />
      </TableCell>
      <TableCell>{name}</TableCell>
    </TableRow>
  );
});

export const Crops = React.memo(function Crops(): JSX.Element {
  const [colorPickerDialogOpenedFor, setColorPickerDialogOpenedFor] = useState<CropUrl | null>(
    null,
  );

  const handleChangeColorClick = useCallback((url: CropUrl) => {
    setColorPickerDialogOpenedFor(url);
  }, []);

  const handleColorPickerDialogCancel = useCallback(() => {
    setColorPickerDialogOpenedFor(null);
  }, []);
  const dispatch = useDispatch();
  const handleColorSelected = useCallback(
    (color: string) => {
      if (colorPickerDialogOpenedFor) {
        dispatch(actions.update(colorPickerDialogOpenedFor, [{member: "color", value: color}]));
      }
      setColorPickerDialogOpenedFor(null);
    },
    [colorPickerDialogOpenedFor, dispatch],
  );

  const cropsArray = useSelector(getCropArray);

  const rows = _.sortBy(cropsArray, (crop) => crop.name).map((crop) => {
    return (
      <Row
        key={crop.url}
        color={crop.color}
        name={crop.name}
        url={crop.url}
        onChangeColorClick={handleChangeColorClick}
      />
    );
  });

  const cropLookup = useSelector(getCropLookup);

  const colorPickerDialogInitialColor = colorPickerDialogOpenedFor
    ? cropLookup(colorPickerDialogOpenedFor)?.color
    : undefined;

  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{width: 48}} />
            <TableCell>
              <FormattedMessage defaultMessage="Navn" id="crops.table-header.name" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
      <ColorPickerDialog
        // Change in key, remounts the component and sets new initial value
        key={colorPickerDialogInitialColor || ""}
        initialSelected={colorPickerDialogInitialColor}
        open={!!colorPickerDialogOpenedFor}
        onCancel={handleColorPickerDialogCancel}
        onOk={handleColorSelected}
      />
    </div>
  );
});
