import React, {useCallback, useEffect, useRef} from "react";

export function useLongPress<T>(
  onLongPress: (event: React.TouchEvent<T>) => void,
  delay: number,
): {
  clearLongPressTimer: () => void;
  onTouchStart: (e: React.TouchEvent<T>) => void;
} {
  const timeout = useRef<number>();

  const clear = useCallback(() => {
    window.clearTimeout(timeout.current);
  }, []);

  const start = useCallback(
    (event: React.TouchEvent<T>) => {
      window.clearTimeout(timeout.current);
      timeout.current = window.setTimeout(() => {
        onLongPress(event);
      }, delay);
    },
    [delay, onLongPress],
  );

  useEffect(() => {
    return () => {
      window.clearTimeout(timeout.current);
    };
  }, []);

  return {
    clearLongPressTimer: clear,
    onTouchStart: start,
  };
}
