import {CustomerUrl} from "@co-common-libs/resources";
import {CustomerDialog} from "@co-frontend-libs/components";
import {
  getActiveContactArray,
  getCurrentUserSelectableProjectArray,
  getCurrentUserURL,
  getCustomerSettings,
  getOrderLookup,
  getTaskArray,
} from "@co-frontend-libs/redux";
import React from "react";
import {useSelector} from "react-redux";
import {getSavedInEconomicCustomerArray} from "./selectors";

interface EconomicCustomerSelectDialogProps {
  onCancel(): void;
  onOk(url: CustomerUrl): void;
  open: boolean;
}

export const EconomicCustomerSelectDialog = React.memo(function EconomicCustomerSelectDialog(
  props: EconomicCustomerSelectDialogProps,
): JSX.Element {
  const customerSettings = useSelector(getCustomerSettings);
  const activeContactArray = useSelector(getActiveContactArray);
  const currentUserURL = useSelector(getCurrentUserURL);
  const orderLookup = useSelector(getOrderLookup);
  const activeProjectArray = useSelector(getCurrentUserSelectableProjectArray);
  const taskArray = useSelector(getTaskArray);
  const savedInEconomicCustomerArray = useSelector(getSavedInEconomicCustomerArray);

  return (
    <CustomerDialog
      contactArray={activeContactArray}
      currentUserURL={currentUserURL}
      customerArray={savedInEconomicCustomerArray}
      customerSettings={customerSettings}
      orderLookup={orderLookup}
      projectArray={activeProjectArray}
      suggestRecentlyUsed={false}
      taskArray={taskArray}
      {...props}
    />
  );
});
