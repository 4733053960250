import {DebouncedAppbarSearchField} from "@co-frontend-libs/components";
import {MenuToolbar, PageLayout} from "app-components";
import {useQueryParameterState} from "app-utils";
import React, {useState} from "react";
import {useIntl} from "react-intl";
import {ShowInactiveToggle} from "../shared/show-inactive-toggle";
import {ProductGroupsList} from "./product-groups/product-groups-list";

interface ProductGroupsSettingsPageProps {
  onMenuButton: (event: React.MouseEvent) => void;
}

export const ProductGroupsSettingsPage = React.memo(function ProductGroupsSettingsPage(
  props: ProductGroupsSettingsPageProps,
): JSX.Element {
  const intl = useIntl();

  const [filter, setFilter] = useQueryParameterState<string>("q", "");
  const [showInactive, setShowInactive] = useState(false);

  return (
    <PageLayout
      withBottomScrollPadding
      toolbar={
        <MenuToolbar
          rightElement={
            <>
              <ShowInactiveToggle setShowInactive={setShowInactive} showInactive={showInactive} />
              <DebouncedAppbarSearchField
                debounceTimeout={200}
                value={filter}
                onChange={setFilter}
              />
            </>
          }
          title={intl.formatMessage({defaultMessage: "Varegrupper"})}
          onMenuButton={props.onMenuButton}
        />
      }
    >
      <ProductGroupsList showInactive={showInactive} />
    </PageLayout>
  );
});
