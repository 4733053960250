import {History, Location, Action as NavigationKind} from "history";
import _ from "lodash";
import {AnyAction, Dispatch, Middleware} from "redux";
import * as actions from "./actions";
import {HistoryState, RoutingAppDispatch, RoutingAppState, RoutingMiddlewareAPI} from "./types";
import {getNormalisedSearchParamsString, getSearchObject} from "./utils";

function getHistoryEventHandler<T extends string>(
  history: History<HistoryState>,
  middlewareApi: RoutingMiddlewareAPI<T>,
): (options: {action: NavigationKind; location: Location<HistoryState>}) => void {
  const eventHandler = (options: {
    action: NavigationKind;
    location: Location<HistoryState>;
  }): void => {
    const {action: navigationKind, location} = options;
    if (navigationKind !== "POP") {
      // triggered by pushState from code, so ignore...
      return;
    }
    const {pathname, search} = location;
    const queryParameters = getSearchObject(search);
    const routingState = middlewareApi.getState().routing;
    const resultingPosition = location.state?.position ?? null;
    if (
      routingState.current.position !== resultingPosition ||
      routingState.current.pathname !== pathname ||
      !_.isEqual(routingState.current.queryParameters, queryParameters)
    ) {
      middlewareApi.dispatch(
        actions.navigateFromBrowser(pathname, queryParameters, resultingPosition),
      );
      if (resultingPosition === null) {
        const newRoutingState = middlewareApi.getState().routing;
        history.replace(_.pick(history.location, ["pathname", "search", "hash"]), {
          position: newRoutingState.current.position,
        });
      }
    }
  };
  return eventHandler;
}

const HISTORY_REPLACE_DEBOUNCE_TIMEOUT = 50;

export function createMiddleware<T extends string>(
  history: History<HistoryState>,
): Middleware<undefined, RoutingAppState<T>, RoutingAppDispatch<T>> {
  return (middlewareApi: RoutingMiddlewareAPI<T>) => {
    if (typeof history.location.state?.position !== "number") {
      // set initial "position" in state; navigation in CO depends on
      // "position" always being in history state...
      history.replace(_.pick(history.location, ["pathname", "search", "hash"]), {position: 0});
    }
    history.listen(getHistoryEventHandler(history, middlewareApi));

    const debouncedHistoryReplace = _.debounce(
      (pathname: string, search: string, position: number): void => {
        history.replace({pathname, search}, {position});
      },
      HISTORY_REPLACE_DEBOUNCE_TIMEOUT,
    );

    let processingNavigationAction = 0;

    return (next: Dispatch) => (action: AnyAction) => {
      const oldRoutingState = middlewareApi.getState().routing;
      if (process.env.NODE_ENV !== "production") {
        if (typeof action.type === "string" && action.type.startsWith("routing-sync-history/")) {
          processingNavigationAction += 1;
          if (processingNavigationAction > 1) {
            // NOTE: React 18 autobatching should remove the problem entirely
            throw new Error(
              "navigation action during navigation action not supported -- missing explicit batch() where it wasn't implicit?",
            );
          }
        }
      }
      const result = next(action);
      if (process.env.NODE_ENV !== "production") {
        if (typeof action.type === "string" && action.type.startsWith("routing-sync-history/")) {
          processingNavigationAction -= 1;
        }
      }
      const newRoutingState = middlewareApi.getState().routing;
      if (newRoutingState !== oldRoutingState && !actions.navigateFromBrowser.match(action)) {
        if (newRoutingState.current.position >= oldRoutingState.current.position) {
          // PUSH OR REPLACE
          const searchParams = new URLSearchParams(
            Object.entries(newRoutingState.current.queryParameters) as [string, string][],
          );
          searchParams.sort();
          const newSearch = getNormalisedSearchParamsString(searchParams);
          if (newRoutingState.current.position > oldRoutingState.current.position) {
            debouncedHistoryReplace.flush();
            history.push(
              {pathname: newRoutingState.current.pathname, search: newSearch},
              {position: newRoutingState.current.position},
            );
          } else {
            debouncedHistoryReplace(
              newRoutingState.current.pathname,
              newSearch,
              newRoutingState.current.position,
            );
          }
        } else {
          debouncedHistoryReplace.flush();
          history.go(newRoutingState.current.position - oldRoutingState.current.position);
        }
      }
      return result;
    };
  };
}
