import {getCustomerSettings, getUserLookup, getUserProfileArray} from "@co-frontend-libs/redux";
import {FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import {getRemunerationGroupLabels} from "app-utils";
import _ from "lodash";
import React from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useSelector} from "react-redux";

export const RemunerationGroupSelection = React.memo(function RemunerationGroupSelection({
  onChange,
  valueSelected,
}: {
  onChange(event: React.ChangeEvent<HTMLInputElement>): void;
  valueSelected?: string;
}): JSX.Element {
  const customerSettings = useSelector(getCustomerSettings);
  const userLookup = useSelector(getUserLookup);
  const userProfileArray = useSelector(getUserProfileArray);
  const intl = useIntl();
  const groupLabels = getRemunerationGroupLabels(
    customerSettings,
    intl.formatMessage,
    userProfileArray,
    userLookup,
  );

  const alternatives = _.sortBy(
    Object.entries(customerSettings.remunerationGroups)
      .filter(([_identifier, options]) => !(options as any).hiddenFromGroupSelection)
      .map(([identifier, _options]) => {
        return {
          identifier,
          label: groupLabels.get(identifier) || identifier,
        };
      }),
    [(entry) => entry.label, (entry) => entry.identifier],
  ).map(({identifier, label}) => (
    <FormControlLabel key={identifier} control={<Radio />} label={label} value={identifier} />
  ));

  return (
    <div>
      <FormattedMessage
        defaultMessage="Løngruppe:"
        id="user-profile.label.remuneration-group"
        tagName="b"
      />

      <RadioGroup name="remuneration-group" value={valueSelected} onChange={onChange}>
        {alternatives}
      </RadioGroup>
    </div>
  );
});
