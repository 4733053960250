import {Order, urlToId} from "@co-common-libs/resources";
import {AwaitingBackendRequest, AwaitingBackendRequestProps} from "@co-frontend-libs/components";
import {sendOrderUrl} from "api-endpoint-urls";
import React, {useMemo} from "react";
import {useIntl} from "react-intl";

export interface ExecuteCreateSalesLinesForOrderProps
  extends Pick<AwaitingBackendRequestProps, "execute" | "onErrorDialogDismiss" | "onSuccess"> {
  order: Pick<Order, "url">;
}

export function ExecuteSendOrder(props: ExecuteCreateSalesLinesForOrderProps): React.JSX.Element {
  const {order} = props;

  const intl = useIntl();

  // TODO(mr): should get errorTitle and loadingTitle from props
  return (
    <AwaitingBackendRequest
      apiUrl={sendOrderUrl}
      data={useMemo(
        () => ({
          orderId: urlToId(order.url),
        }),
        [order.url],
      )}
      errorTitle={intl.formatMessage({defaultMessage: "Godkendelse fejlede"})}
      loadingTitle={intl.formatMessage({defaultMessage: "Godkender ordre"})}
      method="POST"
      {...props}
    />
  );
}
