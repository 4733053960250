import {Button, Theme, createStyles, darken, makeStyles} from "@material-ui/core";
import React, {useCallback, useState} from "react";
import {FormattedMessage} from "react-intl";
import ColorPickerDialog from "./color-picker-dialog";

export interface SelectColorProps {
  color?: string;
  disabled?: boolean;
  onColorSelected: (color: string) => void;
  required?: boolean;
}

const HOVER_DARKEN_FACTOR = 1.5;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "&:hover": {
        backgroundColor: (props: SelectColorProps) =>
          props.color
            ? darken(
                props.color,
                (typeof theme.palette.tonalOffset === "number"
                  ? theme.palette.tonalOffset
                  : theme.palette.tonalOffset[theme.palette.type]) * HOVER_DARKEN_FACTOR,
              )
            : undefined,
      },
      backgroundColor: (props: SelectColorProps) => props.color,
      color: (props: SelectColorProps) =>
        props.color ? theme.palette.getContrastText(props.color) : undefined,
    },
  }),
);

export const SelectColor = (props: SelectColorProps): JSX.Element => {
  const {color, disabled, onColorSelected, required} = props;

  const [colorPickerDialogOpen, setColorPickerDialogOpen] = useState(false);

  const handleColorPickerDialogCancel = useCallback(
    (): void => setColorPickerDialogOpen(false),
    [setColorPickerDialogOpen],
  );

  const handleSelectColorClick = useCallback(
    (): void => setColorPickerDialogOpen(true),
    [setColorPickerDialogOpen],
  );

  const handleColorSelected = useCallback(
    (selectedColor: string): void => {
      setColorPickerDialogOpen(false);
      onColorSelected(selectedColor);
    },
    [setColorPickerDialogOpen, onColorSelected],
  );

  const classes = useStyles(props);

  return (
    <>
      <Button
        className={classes.root}
        disabled={!!disabled}
        variant="contained"
        onClick={handleSelectColorClick}
      >
        <FormattedMessage defaultMessage="Skift Farve" id="select-color.label.change-color" />
      </Button>
      <ColorPickerDialog
        initialSelected={color}
        open={colorPickerDialogOpen}
        required={required}
        onCancel={handleColorPickerDialogCancel}
        onOk={handleColorSelected}
      />
    </>
  );
};
