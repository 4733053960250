import {TaskUrl, TransportLogUrl, UnitUrl} from "../resource-urls";
import {ResourceInstance} from "./base";

export interface TransportLog extends ResourceInstance<TransportLogUrl> {
  readonly amountPerTrip: number | null;
  readonly haRequired: boolean;
  readonly kmRequired: boolean;
  readonly relatedUnit: UnitUrl | null;
  readonly task: TaskUrl;
  readonly unit: string;
}

export const emptyTransportLog: Omit<TransportLog, "id" | "task" | "url"> = {
  amountPerTrip: null,
  haRequired: false,
  kmRequired: false,
  relatedUnit: null,
  unit: "",
};
