export const promiseChain = (
  callbacks: ((this: void, param: any) => any)[],
  initial: any = null,
): Promise<any> => {
  let promise = Promise.resolve(initial);
  callbacks.forEach((fn) => {
    promise = promise.then(fn);
  });
  return promise;
};
