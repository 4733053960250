// This file generated by rebuild-resource-names.mjs

import {
  AccomodationAllowanceUrl,
  AccumulatedCompensatoryUrl,
  AnniversaryTypeUrl,
  ArchiveFileUrl,
  ArchiveLinkUrl,
  AvailabilityUrl,
  CachedDailyAccumulatedCompensatoryUrl,
  CalendarOrderingUrl,
  CalendarWorkHoursUrl,
  ContactUrl,
  CropUrl,
  CultureUrl,
  CustomerFileUrl,
  CustomerUrl,
  DaysAbsenceUrl,
  DeliveryLocationUrl,
  DeliveryUrl,
  DinnerBookingUrl,
  EmployeeGroupUrl,
  ExpectedAmountUrl,
  HoursAbsenceUrl,
  InformationUrl,
  JournalUrl,
  KrPerLiterDefaultFuelSurchargeUseUrl,
  KrPerLiterFuelSurchargeBasisUrl,
  KrPerLiterFuelSurchargeSpecificationEntryUrl,
  KrPerLiterFuelSurchargeSpecificationUrl,
  KrPerLiterMachineFuelSurchargeUseUrl,
  KrPerLiterWorkTypeFuelSurchargeUseUrl,
  LocationStorageAdjustmentUrl,
  LocationStorageChangeUrl,
  LocationStorageStatusUrl,
  LocationTypeUrl,
  LocationUrl,
  LocationUseLogUrl,
  LunchBookingUrl,
  MachineGroupUrl,
  MachineLastUsedUrl,
  MachineUrl,
  MachineUseLogUrl,
  OrderFileUrl,
  OrderUrl,
  PickupLocationUrl,
  PickupUrl,
  PositionUrl,
  PriceGroupUrl,
  PriceItemUrl,
  PricePercentDefaultFuelSurchargeUseUrl,
  PricePercentFuelSurchargeBasisUrl,
  PricePercentFuelSurchargeSpecificationEntryUrl,
  PricePercentFuelSurchargeSpecificationUrl,
  PricePercentMachineFuelSurchargeUseUrl,
  PricePercentWorkTypeFuelSurchargeUseUrl,
  ProductGroupUrl,
  ProductUrl,
  ProductUseLogUrl,
  ProjectFileUrl,
  ProjectUrl,
  PunchInOutUrl,
  RemunerationReportUrl,
  ReportSetupUrl,
  ReportingPrintoutUrl,
  ReportingSpecificationUrl,
  ResourceUrls,
  RoleUrl,
  RouteLogReportUrl,
  RoutePlanTaskActivityOptionUrl,
  RoutePlanTaskResultUrl,
  RoutePlanTaskUrl,
  RoutePlanUrl,
  RouteTaskActivityOptionUrl,
  RouteTaskResultUrl,
  RouteTaskUrl,
  SettingEntryUrl,
  SettingsUrl,
  SprayLocationUrl,
  SprayLogReportUrl,
  SprayLogUrl,
  SprayUrl,
  SyncLogUrl,
  TaskFileUrl,
  TaskPhotoUrl,
  TaskUrl,
  TasksPrintoutUrl,
  TimerStartUrl,
  TimerUrl,
  TransportLogReportUrl,
  TransportLogUrl,
  UnitUrl,
  UserFileUrl,
  UserPhotoUrl,
  UserProfileUrl,
  UserUrl,
  WorkTypeUrl,
  WorkshopChecklistItemUrl,
  WorkshopChecklistUrl,
  YieldDeliveryLocationUrl,
  YieldDeliveryUrl,
  YieldLogReportUrl,
  YieldLogUrl,
  YieldPickupLocationUrl,
  YieldPickupUrl,
} from "./resource-urls";
import { ResourceName } from "./resource-names";
import { resourcesConfig } from "./resource-config";

export function instanceURL(baseURL: string, resourceName: "accomodationAllowance", id: string): AccomodationAllowanceUrl;

export function instanceURL(baseURL: string, resourceName: "accumulatedCompensatory", id: string): AccumulatedCompensatoryUrl;

export function instanceURL(baseURL: string, resourceName: "anniversaryType", id: string): AnniversaryTypeUrl;

export function instanceURL(baseURL: string, resourceName: "archiveFile", id: string): ArchiveFileUrl;

export function instanceURL(baseURL: string, resourceName: "archiveLink", id: string): ArchiveLinkUrl;

export function instanceURL(baseURL: string, resourceName: "availability", id: string): AvailabilityUrl;

export function instanceURL(baseURL: string, resourceName: "cachedDailyAccumulatedCompensatory", id: string): CachedDailyAccumulatedCompensatoryUrl;

export function instanceURL(baseURL: string, resourceName: "calendarOrdering", id: string): CalendarOrderingUrl;

export function instanceURL(baseURL: string, resourceName: "calendarWorkHours", id: string): CalendarWorkHoursUrl;

export function instanceURL(baseURL: string, resourceName: "contact", id: string): ContactUrl;

export function instanceURL(baseURL: string, resourceName: "crop", id: string): CropUrl;

export function instanceURL(baseURL: string, resourceName: "culture", id: string): CultureUrl;

export function instanceURL(baseURL: string, resourceName: "customer", id: string): CustomerUrl;

export function instanceURL(baseURL: string, resourceName: "customerFile", id: string): CustomerFileUrl;

export function instanceURL(baseURL: string, resourceName: "daysAbsence", id: string): DaysAbsenceUrl;

export function instanceURL(baseURL: string, resourceName: "delivery", id: string): DeliveryUrl;

export function instanceURL(baseURL: string, resourceName: "deliveryLocation", id: string): DeliveryLocationUrl;

export function instanceURL(baseURL: string, resourceName: "dinnerBooking", id: string): DinnerBookingUrl;

export function instanceURL(baseURL: string, resourceName: "employeeGroup", id: string): EmployeeGroupUrl;

export function instanceURL(baseURL: string, resourceName: "expectedAmount", id: string): ExpectedAmountUrl;

export function instanceURL(baseURL: string, resourceName: "hoursAbsence", id: string): HoursAbsenceUrl;

export function instanceURL(baseURL: string, resourceName: "information", id: string): InformationUrl;

export function instanceURL(baseURL: string, resourceName: "journal", id: string): JournalUrl;

export function instanceURL(baseURL: string, resourceName: "krPerLiterDefaultFuelSurchargeUse", id: string): KrPerLiterDefaultFuelSurchargeUseUrl;

export function instanceURL(baseURL: string, resourceName: "krPerLiterFuelSurchargeBasis", id: string): KrPerLiterFuelSurchargeBasisUrl;

export function instanceURL(baseURL: string, resourceName: "krPerLiterFuelSurchargeSpecification", id: string): KrPerLiterFuelSurchargeSpecificationUrl;

export function instanceURL(baseURL: string, resourceName: "krPerLiterFuelSurchargeSpecificationEntry", id: string): KrPerLiterFuelSurchargeSpecificationEntryUrl;

export function instanceURL(baseURL: string, resourceName: "krPerLiterMachineFuelSurchargeUse", id: string): KrPerLiterMachineFuelSurchargeUseUrl;

export function instanceURL(baseURL: string, resourceName: "krPerLiterWorkTypeFuelSurchargeUse", id: string): KrPerLiterWorkTypeFuelSurchargeUseUrl;

export function instanceURL(baseURL: string, resourceName: "location", id: string): LocationUrl;

export function instanceURL(baseURL: string, resourceName: "locationStorageAdjustment", id: string): LocationStorageAdjustmentUrl;

export function instanceURL(baseURL: string, resourceName: "locationStorageChange", id: string): LocationStorageChangeUrl;

export function instanceURL(baseURL: string, resourceName: "locationStorageStatus", id: string): LocationStorageStatusUrl;

export function instanceURL(baseURL: string, resourceName: "locationType", id: string): LocationTypeUrl;

export function instanceURL(baseURL: string, resourceName: "locationUseLog", id: string): LocationUseLogUrl;

export function instanceURL(baseURL: string, resourceName: "lunchBooking", id: string): LunchBookingUrl;

export function instanceURL(baseURL: string, resourceName: "machine", id: string): MachineUrl;

export function instanceURL(baseURL: string, resourceName: "machineGroup", id: string): MachineGroupUrl;

export function instanceURL(baseURL: string, resourceName: "machineLastUsed", id: string): MachineLastUsedUrl;

export function instanceURL(baseURL: string, resourceName: "machineUseLog", id: string): MachineUseLogUrl;

export function instanceURL(baseURL: string, resourceName: "order", id: string): OrderUrl;

export function instanceURL(baseURL: string, resourceName: "orderFile", id: string): OrderFileUrl;

export function instanceURL(baseURL: string, resourceName: "pickup", id: string): PickupUrl;

export function instanceURL(baseURL: string, resourceName: "pickupLocation", id: string): PickupLocationUrl;

export function instanceURL(baseURL: string, resourceName: "position", id: string): PositionUrl;

export function instanceURL(baseURL: string, resourceName: "priceGroup", id: string): PriceGroupUrl;

export function instanceURL(baseURL: string, resourceName: "priceItem", id: string): PriceItemUrl;

export function instanceURL(baseURL: string, resourceName: "pricePercentDefaultFuelSurchargeUse", id: string): PricePercentDefaultFuelSurchargeUseUrl;

export function instanceURL(baseURL: string, resourceName: "pricePercentFuelSurchargeBasis", id: string): PricePercentFuelSurchargeBasisUrl;

export function instanceURL(baseURL: string, resourceName: "pricePercentFuelSurchargeSpecification", id: string): PricePercentFuelSurchargeSpecificationUrl;

export function instanceURL(baseURL: string, resourceName: "pricePercentFuelSurchargeSpecificationEntry", id: string): PricePercentFuelSurchargeSpecificationEntryUrl;

export function instanceURL(baseURL: string, resourceName: "pricePercentMachineFuelSurchargeUse", id: string): PricePercentMachineFuelSurchargeUseUrl;

export function instanceURL(baseURL: string, resourceName: "pricePercentWorkTypeFuelSurchargeUse", id: string): PricePercentWorkTypeFuelSurchargeUseUrl;

export function instanceURL(baseURL: string, resourceName: "product", id: string): ProductUrl;

export function instanceURL(baseURL: string, resourceName: "productGroup", id: string): ProductGroupUrl;

export function instanceURL(baseURL: string, resourceName: "productUseLog", id: string): ProductUseLogUrl;

export function instanceURL(baseURL: string, resourceName: "project", id: string): ProjectUrl;

export function instanceURL(baseURL: string, resourceName: "projectFile", id: string): ProjectFileUrl;

export function instanceURL(baseURL: string, resourceName: "punchInOut", id: string): PunchInOutUrl;

export function instanceURL(baseURL: string, resourceName: "remunerationReport", id: string): RemunerationReportUrl;

export function instanceURL(baseURL: string, resourceName: "reportingPrintout", id: string): ReportingPrintoutUrl;

export function instanceURL(baseURL: string, resourceName: "reportingSpecification", id: string): ReportingSpecificationUrl;

export function instanceURL(baseURL: string, resourceName: "reportSetup", id: string): ReportSetupUrl;

export function instanceURL(baseURL: string, resourceName: "role", id: string): RoleUrl;

export function instanceURL(baseURL: string, resourceName: "routeLogReport", id: string): RouteLogReportUrl;

export function instanceURL(baseURL: string, resourceName: "routePlan", id: string): RoutePlanUrl;

export function instanceURL(baseURL: string, resourceName: "routePlanTask", id: string): RoutePlanTaskUrl;

export function instanceURL(baseURL: string, resourceName: "routePlanTaskActivityOption", id: string): RoutePlanTaskActivityOptionUrl;

export function instanceURL(baseURL: string, resourceName: "routePlanTaskResult", id: string): RoutePlanTaskResultUrl;

export function instanceURL(baseURL: string, resourceName: "routeTask", id: string): RouteTaskUrl;

export function instanceURL(baseURL: string, resourceName: "routeTaskActivityOption", id: string): RouteTaskActivityOptionUrl;

export function instanceURL(baseURL: string, resourceName: "routeTaskResult", id: string): RouteTaskResultUrl;

export function instanceURL(baseURL: string, resourceName: "settingEntry", id: string): SettingEntryUrl;

export function instanceURL(baseURL: string, resourceName: "settings", id: string): SettingsUrl;

export function instanceURL(baseURL: string, resourceName: "spray", id: string): SprayUrl;

export function instanceURL(baseURL: string, resourceName: "sprayLocation", id: string): SprayLocationUrl;

export function instanceURL(baseURL: string, resourceName: "sprayLog", id: string): SprayLogUrl;

export function instanceURL(baseURL: string, resourceName: "sprayLogReport", id: string): SprayLogReportUrl;

export function instanceURL(baseURL: string, resourceName: "syncLog", id: string): SyncLogUrl;

export function instanceURL(baseURL: string, resourceName: "task", id: string): TaskUrl;

export function instanceURL(baseURL: string, resourceName: "taskFile", id: string): TaskFileUrl;

export function instanceURL(baseURL: string, resourceName: "taskPhoto", id: string): TaskPhotoUrl;

export function instanceURL(baseURL: string, resourceName: "tasksPrintout", id: string): TasksPrintoutUrl;

export function instanceURL(baseURL: string, resourceName: "timer", id: string): TimerUrl;

export function instanceURL(baseURL: string, resourceName: "timerStart", id: string): TimerStartUrl;

export function instanceURL(baseURL: string, resourceName: "transportLog", id: string): TransportLogUrl;

export function instanceURL(baseURL: string, resourceName: "transportLogReport", id: string): TransportLogReportUrl;

export function instanceURL(baseURL: string, resourceName: "unit", id: string): UnitUrl;

export function instanceURL(baseURL: string, resourceName: "user", id: string): UserUrl;

export function instanceURL(baseURL: string, resourceName: "userFile", id: string): UserFileUrl;

export function instanceURL(baseURL: string, resourceName: "userPhoto", id: string): UserPhotoUrl;

export function instanceURL(baseURL: string, resourceName: "userProfile", id: string): UserProfileUrl;

export function instanceURL(baseURL: string, resourceName: "workshopChecklist", id: string): WorkshopChecklistUrl;

export function instanceURL(baseURL: string, resourceName: "workshopChecklistItem", id: string): WorkshopChecklistItemUrl;

export function instanceURL(baseURL: string, resourceName: "workType", id: string): WorkTypeUrl;

export function instanceURL(baseURL: string, resourceName: "yieldDelivery", id: string): YieldDeliveryUrl;

export function instanceURL(baseURL: string, resourceName: "yieldDeliveryLocation", id: string): YieldDeliveryLocationUrl;

export function instanceURL(baseURL: string, resourceName: "yieldLog", id: string): YieldLogUrl;

export function instanceURL(baseURL: string, resourceName: "yieldLogReport", id: string): YieldLogReportUrl;

export function instanceURL(baseURL: string, resourceName: "yieldPickup", id: string): YieldPickupUrl;

export function instanceURL(baseURL: string, resourceName: "yieldPickupLocation", id: string): YieldPickupLocationUrl;

export function instanceURL<Resource extends ResourceName>(baseURL: string, resourceName: Resource, id: string): ResourceUrls[Resource];
export function instanceURL(baseURL: string, resourceName: ResourceName, id: string): string {
  return `${baseURL}${resourcesConfig[resourceName]}/${id}/`;
}
