import {Task, TaskUrl} from "@co-common-libs/resources";
import {useMemo} from "react";
import {EconomicIssue, IssueContext} from "../issues/types";
import {filterByCategory} from "../issues/utils/filter-task-issues-by-category";
import {useEconomicTasksIssues} from "./use-economic-tasks-issues";

export function useEconomicTasksErrors(
  tasks: readonly Task[],
  context: IssueContext,
): ReadonlyMap<TaskUrl, readonly EconomicIssue[]> {
  const taskResults = useEconomicTasksIssues(tasks, context);
  return useMemo(() => filterByCategory(taskResults, "error"), [taskResults]);
}
