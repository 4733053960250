import {ReportingSpecificationTemplate} from "./types";

export const transportLog: ReportingSpecificationTemplate = {
  active: true,
  allowSkip: false,
  allowTotalsTable: true,
  allowTransportedMaterialCountMismatch: false,
  autoInsertAmountInDelivery: true,
  autoInsertLastPickupValues: true,
  autoInsertLastWorkplaceConversionValue: false,
  autoInsertLastWorkplaceValues: false,
  customerLogSums: true,
  displayProductsPerWorkplace: false,
  displayWorkplaceOverviewOnPDF: true,
  extraFooterText: "",
  fieldsUsedFor: "unused",
  hideMaterialsOnPrintout: false,
  horizontal: true,
  identifier: "transportlog",
  invoiceCustomerAddress: false,
  invoiceCustomerName: false,
  invoiceCustomerVatNumber: false,
  invoiceWorkplaceAddress: false,
  invoiceWorkplaceName: false,
  name: "Transportlog",
  reportTransport: true,
  requireAtLeastOneLogEntry: false,
  sendMailToPickupDeliveryCustomers: false,
  shared: false,
  showCustomerOnEntryTable: true,
  showLoadCounts: false,
  showLocationMaterialSums: true,
  showLocationProducts: true,
  showMachines: false,
  showSharedTotalsTableOnAllOrderTasks: false,
  showTaskTimeOnReport: true,
  simpleLocationSumLog: true,
  taskData: {},
  versionNote: "Første version",
  workplaceData: {
    delivery: {
      logEntries: "*",
      logInputs: [],
      logPriceItems: true,
      logProducts: true,
    },
    pickup: {
      logEntries: "*",
      logInputs: [],
      logPriceItems: true,
      logProducts: true,
    },
  },
  workplaceRegistration: 2,
};
