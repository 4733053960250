import {DayTypeHoliday} from "@co-common-libs/utils";
import {checkCriteria, getSortedSplitDaySeconds, splitOnDaySeconds} from "./interval-match";
import {RateOverrideSpecification, TaskIntervalWithRate, WorkDay, WorkPeriod} from "./types";

function noOverride(interval: TaskIntervalWithRate): TaskIntervalWithRate {
  return interval;
}

export function getRateOverrideFunction(
  checkHoliday: (dateString: string) => DayTypeHoliday,
  rateOverride: readonly RateOverrideSpecification[],
): (interval: TaskIntervalWithRate) => TaskIntervalWithRate {
  if (!rateOverride.length) {
    return noOverride;
  }
  const fn = (interval: TaskIntervalWithRate): TaskIntervalWithRate => {
    let result = interval;
    for (const override of rateOverride) {
      if (result.rate === override.resultingRate) {
        continue;
      }
      if (checkCriteria(checkHoliday, override, interval)) {
        result = {...result, rate: override.resultingRate};
      }
    }
    return result;
  };
  return fn;
}

export function processRateOverrides(
  checkHoliday: (dateString: string) => DayTypeHoliday,
  workDays: readonly WorkDay[],
  rateOverride: readonly RateOverrideSpecification[],
): readonly WorkDay[] {
  if (!rateOverride.length) {
    return workDays;
  }
  const rateOverrideFn = getRateOverrideFunction(checkHoliday, rateOverride);

  const splitDaySeconds = getSortedSplitDaySeconds(rateOverride);

  const attachWorkPeriodOverrideRates = (workPeriod: WorkPeriod): WorkPeriod => {
    const baseWork = splitDaySeconds.length
      ? splitOnDaySeconds(splitDaySeconds, workPeriod.work)
      : workPeriod.work;
    return {
      ...workPeriod,
      work: baseWork.map(rateOverrideFn),
    };
  };

  const attachWorkDayOverrideRates = (workDay: WorkDay): WorkDay => ({
    ...workDay,
    workPeriods: workDay.workPeriods.map(attachWorkPeriodOverrideRates),
  });

  return workDays.map(attachWorkDayOverrideRates);
}
