import {RemunerationGroup, buildGroupOptions} from "@co-common-libs/payroll";
import {
  AccomodationAllowance,
  MachineUrl,
  PriceGroupUrl,
  Task,
  User,
  UserProfile,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {formatDuration} from "@co-common-libs/resources-utils";
import {formatTime} from "@co-common-libs/utils";
import {
  actions,
  getAccomodationAllowanceArray,
  getCustomerSettings,
  getMachineArray,
  getPriceGroupArray,
  getWorkTypeArray,
} from "@co-frontend-libs/redux";
import {AccommodationAllowanceCheckbox} from "app-components";
import {getEmployeeRemunerationGroup, useTaskSums} from "app-utils";
import {instanceURL} from "frontend-global-config";
import React, {useCallback} from "react";
// Allowed for existing code.
// eslint-disable-next-line deprecate/import
import {Cell, Grid} from "react-flexr";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuid} from "uuid";

interface BookkeepingCompletedTasksSumsProps {
  date: string;
  taskList: readonly Task[];
  user: User;
  userProfile?: UserProfile | undefined;
}

export const BookkeepingCompletedTasksSums = React.memo(function BookkeepingCompletedTasksSums(
  props: BookkeepingCompletedTasksSumsProps,
): JSX.Element | null {
  const {date, taskList, user, userProfile} = props;

  const customerSettings = useSelector(getCustomerSettings);
  const accomodationAllowanceArray = useSelector(getAccomodationAllowanceArray);
  const workTypeArray = useSelector(getWorkTypeArray);
  const machineArray = useSelector(getMachineArray);
  const priceGroupArray = useSelector(getPriceGroupArray);

  const dispatch = useDispatch();

  const handleRegisterAccommodationChanged = useCallback(
    (registerAccommodation: boolean, remunerationGroup: string): void => {
      if (registerAccommodation) {
        const id = uuid();
        const url = instanceURL("accomodationAllowance", id);
        const instance: AccomodationAllowance = {
          date,
          employee: user.url,
          id,
          remunerationGroup,
          url,
        };
        dispatch(actions.create(instance));
      } else {
        const userURL = user.url;
        const accommodationAllowance = accomodationAllowanceArray.find(
          (a) =>
            a.date === date && a.employee === userURL && a.remunerationGroup === remunerationGroup,
        );
        if (accommodationAllowance) {
          dispatch(actions.remove(accommodationAllowance.url));
        }
      }
    },
    [accomodationAllowanceArray, date, dispatch, user.url],
  );

  const {
    breakMinutes,
    effectiveMinutes,
    efficiencyPercent,
    firstStart,
    internalMinutes,
    lastEnd,
    paidMinutes,
    totalWorkMinutes,
    totalWorkPeriodMinutes,
    unpaidMinutes,
  } = useTaskSums(taskList, userProfile);

  if (!taskList.length) {
    return null;
  }
  const employeeDefaultRemunerationGroupID = getEmployeeRemunerationGroup(
    customerSettings,
    userProfile,
  );
  const workTypeIDURLMapping: {
    [workTypeID: string]: WorkTypeUrl;
  } = {};
  workTypeArray.forEach((workType) => {
    workTypeIDURLMapping[workType.identifier] = workType.url;
  });
  const machineIDURLMapping: {[machineID: string]: MachineUrl} = {};
  machineArray.forEach((machine) => {
    machineIDURLMapping[machine.c5_machine] = machine.url;
  });
  const priceGroupIDURLMapping: {[priceGroupID: string]: PriceGroupUrl} = {};
  priceGroupArray.forEach((priceGroup) => {
    priceGroupIDURLMapping[priceGroup.identifier] = priceGroup.url;
  });

  const remunerationGroups = new Map<string, RemunerationGroup>();
  Object.entries(customerSettings.remunerationGroups).forEach(([identifier, options]) => {
    const x = buildGroupOptions(
      options as any,
      customerSettings,
      workTypeIDURLMapping,
      machineIDURLMapping,
      priceGroupIDURLMapping,
      [], //daysAbsenceList
    );
    remunerationGroups.set(identifier, x);
  });

  let registerAccommodationBlock: JSX.Element[] | undefined;

  const employeeDefaultRemunerationGroup =
    customerSettings.remunerationGroups[employeeDefaultRemunerationGroupID];
  const employeeRemunerationGroups =
    employeeDefaultRemunerationGroup &&
    employeeDefaultRemunerationGroup.switchGroups &&
    employeeDefaultRemunerationGroup.switchGroups.length
      ? [employeeDefaultRemunerationGroupID].concat(
          employeeDefaultRemunerationGroup.switchGroups.map((entry) => entry.identifier),
        )
      : [employeeDefaultRemunerationGroupID];

  const employeeAccomodationAllowanceRemunerationGroups = employeeRemunerationGroups.filter(
    (identifier) => customerSettings.remunerationGroups[identifier]?.accomodationAllowance,
  );

  if (employeeAccomodationAllowanceRemunerationGroups.length) {
    registerAccommodationBlock = employeeAccomodationAllowanceRemunerationGroups.map((groupID) => {
      return (
        <tr key={groupID}>
          <td style={{paddingTop: "1em"}}>
            <AccommodationAllowanceCheckbox
              date={date}
              employeeUrl={user.url}
              labelPlacement="start"
              remunerationGroup={groupID}
              onChange={handleRegisterAccommodationChanged}
            />
          </td>
          <td />
        </tr>
      );
    });
  }

  const textRight: React.CSSProperties = {textAlign: "right"};
  return (
    <Grid>
      <Cell palm="12/12">
        <table style={{maxWidth: "18em", width: "100%"}}>
          <tbody>
            <tr>
              <td>
                <FormattedMessage
                  defaultMessage="Start kl.:"
                  id="bookkeeping-day.label.start-time"
                />
              </td>
              <td style={textRight}>{formatTime(firstStart)}</td>
            </tr>
            <tr>
              <td>
                <FormattedMessage defaultMessage="Slut kl.:" id="bookkeeping-day.label.end-time" />
              </td>
              <td style={textRight}>{formatTime(lastEnd)}</td>
            </tr>
            <tr style={{borderBottom: "1px solid black"}}>
              <td>
                <FormattedMessage defaultMessage="Brutto" id="bookkeeping-day.label.total-time" />
              </td>
              <td style={textRight}>
                {formatDuration(customerSettings.durationFormat, totalWorkPeriodMinutes)}
              </td>
            </tr>
            <tr>
              <td>
                <FormattedMessage
                  defaultMessage="Pause/ubetalt"
                  id="bookkeeping-day.label.unpaid"
                />
              </td>
              <td style={textRight}>
                {formatDuration(customerSettings.durationFormat, breakMinutes + unpaidMinutes)}
              </td>
            </tr>
            <tr style={{outline: "solid thin"}}>
              <td>
                <strong>
                  <FormattedMessage defaultMessage="Betalt tid" id="bookkeeping-day.label.paid" />
                </strong>
              </td>
              <td style={textRight}>
                <strong>{formatDuration(customerSettings.durationFormat, paidMinutes)}</strong>
              </td>
            </tr>
            {registerAccommodationBlock}
          </tbody>
        </table>
      </Cell>
      <Cell palm="12/12">
        <table style={{maxWidth: "18em", width: "100%"}}>
          <tbody>
            <tr>
              <td>
                <FormattedMessage defaultMessage="Intern tid" id="bookkeeping-day.label.internal" />
              </td>
              <td style={{textAlign: "right"}}>
                {formatDuration(customerSettings.durationFormat, internalMinutes)}
              </td>
            </tr>
            <tr>
              <td>
                <FormattedMessage
                  defaultMessage="Effektiv tid"
                  id="bookkeeping-day.label.effective"
                />
              </td>
              <td style={{textAlign: "right"}}>
                {formatDuration(customerSettings.durationFormat, effectiveMinutes)}
              </td>
            </tr>
            <tr style={{borderBottom: "1px solid black"}}>
              <td>
                <FormattedMessage defaultMessage="Samlet tid" id="bookkeeping-day.label.total" />
              </td>
              <td style={{textAlign: "right"}}>
                {formatDuration(customerSettings.durationFormat, totalWorkMinutes)}
              </td>
            </tr>
            <tr style={{outline: "solid thin"}}>
              <td>
                <strong>
                  <FormattedMessage
                    defaultMessage="Effektivitet"
                    id="bookkeeping-day.label.efficiency"
                  />
                </strong>
              </td>
              <td style={{textAlign: "right"}}>
                <strong>{efficiencyPercent}%</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </Cell>
    </Grid>
  );
});
