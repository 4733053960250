import {
  Location,
  LocationStorageAdjustment,
  Product,
  ProductUrl,
  Unit,
  UnitUrl,
} from "@co-common-libs/resources";
import {getUnitString} from "@co-common-libs/resources-utils";
import {notUndefined} from "@co-common-libs/utils";
import {
  ColumnSpecifications,
  GenericTable,
  RowData,
  iconButtonColumnSpecification,
} from "@co-frontend-libs/components";
import {
  actions,
  getCurrentRole,
  getCustomerLookup,
  getProductLookup,
  getUnitLookup,
} from "@co-frontend-libs/redux";
import {Card, CardHeader, Collapse, IconButton, makeStyles} from "@material-ui/core";
import {getLocationString} from "app-utils";
import clsx from "clsx";
import {instanceURL} from "frontend-global-config";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import PencilIcon from "mdi-react/PencilIcon";
import React, {useCallback, useMemo, useState} from "react";
import {IntlShape, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuid} from "uuid";
import {LocationStorageStatusDialog} from "./location-storage-status-dialog";

type LocationStorageTableFieldID = "amount" | "catalogNumber" | "name" | "unit";

type LocationStorageTableColumnID = "amount" | "catalogNumber" | "edit" | "name" | "unit";

interface LocationStorageTableDataType extends RowData<LocationStorageTableFieldID, ProductUrl> {
  amount: number;
  catalogNumber: string;
  name: string;
  unit: string;
}

function getEditButtonRenderFunction(
  onEditClick: (locationURL: ProductUrl) => void,
): (data: LocationStorageTableDataType) => JSX.Element {
  // eslint-disable-next-line react/display-name
  return (data: LocationStorageTableDataType): JSX.Element => {
    const handleEditClick = (): void => {
      onEditClick(data.key);
    };
    return (
      // buildColumnSpecifications should probably take a
      // ComponentType<{data: ...}> parameter; but whitelist this until then
      // eslint-disable-next-line react/jsx-no-bind
      <IconButton onClick={handleEditClick}>
        <PencilIcon />
      </IconButton>
    );
  };
}

function buildColumnSpecifications(
  intl: IntlShape,
  onEditClick: (locationURL: ProductUrl) => void,
): ColumnSpecifications<
  LocationStorageTableFieldID,
  LocationStorageTableColumnID,
  ProductUrl,
  LocationStorageTableDataType
> {
  return {
    amount: {
      field: "amount",
      label: intl.formatMessage({defaultMessage: "Antal"}),
      width: 120,
    },
    catalogNumber: {
      field: "catalogNumber",
      label: intl.formatMessage({defaultMessage: "ID"}),
    },
    edit: iconButtonColumnSpecification({
      field: "amount",
      render: getEditButtonRenderFunction(onEditClick),
    }),
    name: {
      field: "name",
      label: intl.formatMessage({defaultMessage: "Navn"}),
    },
    unit: {
      field: "unit",
      label: intl.formatMessage({defaultMessage: "Enhed"}),
    },
  };
}

function buildRowData(
  productCounts: readonly {
    count: number;
    product: Readonly<Product>;
  }[],
  unitLookup: (url: UnitUrl) => Unit | undefined,
): {
  amount: number;
  catalogNumber: string;
  key: ProductUrl;
  name: string;
  unit: string;
}[] {
  return productCounts.map(({count, product}) => {
    return {
      amount: count,
      catalogNumber: product.catalogNumber,
      key: product.url,
      name: product.name,
      unit: getUnitString(product, unitLookup),
    };
  });
}

const useStyles = makeStyles((theme) => ({
  expand: {
    marginLeft: "auto",
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

interface LocationCardProps {
  location: Location;
  productCounts: ReadonlyMap<ProductUrl, number>;
}

export function LocationCard(props: LocationCardProps): JSX.Element {
  const intl = useIntl();
  const {location, productCounts} = props;

  const productLookup = useSelector(getProductLookup);

  const productCountArray = useMemo(
    (): readonly {
      count: number;
      product: Readonly<Product>;
    }[] =>
      Array.from(productCounts.entries())
        .map(([productURL, count]) => {
          const product = productLookup(productURL);
          if (!product) {
            return undefined;
          }
          return {count, product};
        })
        .filter(notUndefined),
    [productCounts, productLookup],
  );

  const unitLookup = useSelector(getUnitLookup);

  const [sortKey, setSortKey] = useState<LocationStorageTableColumnID>("name");
  const [sortDirection, setSortDirection] = useState<"ASC" | "DESC">("ASC");

  const [editingProductURL, setEditingProductURL] = useState<ProductUrl | null>(null);
  const editingProductAmount = (editingProductURL && productCounts?.get(editingProductURL)) || 0;
  const handleEditDialogCancel = useCallback(() => {
    setEditingProductURL(null);
  }, []);
  const dispatch = useDispatch();
  const handleEditDialogOk = useCallback(
    (amount: number) => {
      const change = amount - editingProductAmount;
      if (editingProductURL && change) {
        const id = uuid();
        const url = instanceURL("locationStorageAdjustment", id);
        const instance: LocationStorageAdjustment = {
          deviceTimestamp: new Date().toISOString(),
          id,
          location: location.url,
          product: editingProductURL,
          url,
          value: amount,
        };
        dispatch(actions.create(instance));
      }
      setEditingProductURL(null);
    },
    [dispatch, editingProductAmount, editingProductURL, location.url],
  );

  const columnSpecifications = useMemo(
    () => buildColumnSpecifications(intl, setEditingProductURL),
    [intl],
  );

  const rowData = useMemo(
    () => buildRowData(productCountArray, unitLookup),
    [unitLookup, productCountArray],
  );

  const handleHeaderClick = useCallback(
    (key: LocationStorageTableColumnID): void => {
      let direction: "ASC" | "DESC" = "ASC";
      if (sortKey === key && sortDirection === "ASC") {
        direction = "DESC";
      }
      setSortKey(key);
      setSortDirection(direction);
    },
    [sortDirection, sortKey],
  );

  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);
  const expandButton = (
    <IconButton
      className={clsx(classes.expand, {
        [classes.expandOpen]: expanded,
      })}
      onClick={handleExpandClick}
    >
      <ChevronDownIcon />
    </IconButton>
  );

  const currentRole = useSelector(getCurrentRole);

  const visibleColumns = useMemo(() => {
    const colums: (LocationStorageTableColumnID | undefined)[] = [
      "catalogNumber",
      "name",
      "unit",
      "amount",
      currentRole && currentRole.manager ? "edit" : undefined,
    ];
    return colums.filter(notUndefined);
  }, [currentRole]);

  const customerLookup = useSelector(getCustomerLookup);
  const customer = location.customer ? customerLookup(location.customer) : null;
  return (
    <>
      <Card style={{margin: 8}}>
        <CardHeader
          action={expandButton}
          title={[getLocationString(location), customer?.name].filter(Boolean).join(", ")}
        />
        <Collapse unmountOnExit in={expanded} timeout="auto">
          <GenericTable
            columns={columnSpecifications}
            entries={rowData}
            sortBy={sortKey}
            sortDirection={sortDirection}
            visibleColumns={visibleColumns}
            onHeaderClick={handleHeaderClick}
          />
        </Collapse>
      </Card>
      <LocationStorageStatusDialog
        amount={editingProductAmount}
        open={!!editingProductURL}
        onCancel={handleEditDialogCancel}
        onOk={handleEditDialogOk}
      />
    </>
  );
}
