import {
  ExpectedAmountUrl,
  Role,
  Unit,
  UnitUrl,
  WorkType,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {getWorkTypeString} from "@co-common-libs/resources-utils";
import {
  AppState,
  getCurrentRole,
  getUnitLookup,
  getWorkTypeLookup,
  makePathParameterGetter,
} from "@co-frontend-libs/redux";
import {jsonFetch} from "@co-frontend-libs/utils";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableRowProps,
} from "@material-ui/core";
import {ExpectedAmountDialog, PageLayout} from "app-components";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import {globalConfig, instanceURL} from "frontend-global-config";
import _ from "lodash";
import CheckIcon from "mdi-react/CheckIcon";
import React from "react";
import {FormattedMessage, FormattedNumber, IntlContext, defineMessages} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";

const COMPLETED_COLUMN_STYLE = {padding: 0, width: 60};

const messages = defineMessages({
  missing: {
    defaultMessage: "Mangler",
    id: "work-type-status-entry.label.missing",
  },
  notPlanned: {
    defaultMessage: "Udført, ej budgetlagt",
    id: "work-type-status-entry.label.not-planned",
  },
  onlineArchiveError: {
    defaultMessage: "Fejl ved adgang til arkiv",
    id: "customer-instance.label.archive-error",
  },
  onlineArchiveWaiting: {
    defaultMessage: "Henter fra arkiv",
    id: "customer-instance.label.archive-waiting",
  },
  total: {
    defaultMessage: "TOTAL",
    id: "work-type-status-entry.label.total",
  },
  worktypeStatus: {
    defaultMessage: "Status detaljer: {worktype}",
    id: "work-type-status-entry.label.worktypeStatus",
  },
});

interface ExpectedAmountRowProps {
  amount: number;
  completed: boolean;
  currentRole: Role | null;
  customer: string;
  expected: number;
  expectedAmount: string;
  onClick: (expectedAmountURL: ExpectedAmountUrl) => void;
  unit: string;
}

class ExpectedAmountRow extends PureComponent<ExpectedAmountRowProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  @bind
  handleClick(): void {
    const url = instanceURL("expectedAmount", this.props.expectedAmount);
    this.props.onClick(url);
  }

  render(): JSX.Element {
    const {amount, completed, customer, expected, unit} = this.props;
    const {formatNumber} = this.context;
    const {currentRole} = this.props;
    const userIsManager = !!(currentRole && currentRole.manager);

    const tableRowProps: Partial<TableRowProps> = {};
    if (userIsManager) {
      tableRowProps.onClick = this.handleClick;
      tableRowProps.style = {cursor: "pointer"};
    }

    return (
      <TableRow {...tableRowProps}>
        <TableCell>{customer}</TableCell>
        <TableCell>
          {formatNumber(expected, {maximumFractionDigits: 2})} {unit}
        </TableCell>
        <TableCell>
          {formatNumber(amount, {maximumFractionDigits: 2})} {unit}
        </TableCell>
        <TableCell>
          <FormattedNumber maximumFractionDigits={2} value={expected - amount} /> {unit}
        </TableCell>
        <TableCell style={COMPLETED_COLUMN_STYLE}>{completed ? <CheckIcon /> : null}</TableCell>
      </TableRow>
    );
  }
}

interface WorkTypeStatusListStateProps {
  currentRole: Role | null;
  id: string;
  unit: string;
  unitLookup: (url: UnitUrl) => Unit | undefined;
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
}

type WorkTypeStatusListProps = WorkTypeStatusListStateProps;

interface WorkTypeStatusListState {
  fetching: boolean;
  graphData: {
    [workTypeIdAndUnitId: string]: {
      [customerId: string]: {
        amount: number;
        completed: boolean;
        customer: string;
        expected: number;
        expectedAmount: string;
        unit: string;
        worktype: string;
      };
    };
  };
  open: boolean;
  selectedExpectedAmountURL: ExpectedAmountUrl | null;
  year: number;
}

class WorkTypeStatusList extends PureComponent<WorkTypeStatusListProps, WorkTypeStatusListState> {
  state: WorkTypeStatusListState = {
    fetching: false,
    graphData: {},
    open: false,
    selectedExpectedAmountURL: null,
    year: new Date().getFullYear(),
  };

  UNSAFE_componentWillMount(): void {
    this.fetch(this.state.year, [this.props.id]);
  }

  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  @bind
  handleExpectedAmountDialogOk(): void {
    this.setState({
      open: false,
      selectedExpectedAmountURL: null,
    });
    // To make sure the update is done before recalculating table
    setTimeout(() => {
      this.fetch(this.state.year, [this.props.id]);
    }, 300);
  }

  @bind
  handleExpectedAmountDialogCancel(): void {
    this.setState({
      open: false,
      selectedExpectedAmountURL: null,
    });
  }

  @bind
  handleRowEditClick(selectedExpectedAmountURL: ExpectedAmountUrl): void {
    this.setState({
      open: true,
      selectedExpectedAmountURL,
    });
  }

  fetch(year: number, workTypes: readonly string[]): void {
    const {baseURL} = globalConfig.resources;
    const url = `${baseURL}work_type_status`;
    this.setState({fetching: true});
    jsonFetch(url, "POST", {
      workTypes,
      year,
    })
      .then((response) => {
        this.setState({
          fetching: false,
          graphData: response.data,
        });
        return;
      })
      .catch(() => {
        this.setState({
          fetching: false,
        });
      });
  }

  render(): JSX.Element {
    const {formatMessage} = this.context;
    const worktypeURL = instanceURL("workType", this.props.id);
    const worktype = this.props.workTypeLookup(worktypeURL);
    const worktypeName = getWorkTypeString(worktype);

    let spinner;
    let rows: JSX.Element[] = [];
    if (this.state.fetching) {
      spinner = (
        <div style={{padding: 8, textAlign: "center"}}>
          <div>{formatMessage(messages.onlineArchiveWaiting)}</div>
          <CircularProgress />
        </div>
      );
    } else {
      const expectedAmounts = this.state.graphData[`${this.props.id} ${this.props.unit}`];

      rows = _.sortBy(
        _.sortBy(expectedAmounts, "customer"),
        (expectedAmount) => expectedAmount.expected - expectedAmount.amount,
      )
        .reverse()
        .map((expectedAmount, index) => {
          const unit = this.props.unitLookup(instanceURL("unit", expectedAmount.unit));
          return (
            <ExpectedAmountRow
              key={`${index}-${expectedAmount.customer}`}
              amount={expectedAmount.amount}
              completed={expectedAmount.completed}
              currentRole={this.props.currentRole}
              customer={expectedAmount.customer}
              expected={expectedAmount.expected}
              expectedAmount={expectedAmount.expectedAmount}
              unit={unit ? unit.name : ""}
              onClick={this.handleRowEditClick}
            />
          );
        });
    }

    const dialog = (
      <ExpectedAmountDialog
        key="expected-amount-dialog"
        expectedAmountURL={this.state.selectedExpectedAmountURL || undefined}
        open={this.state.open}
        onCancel={this.handleExpectedAmountDialogCancel}
        onOk={this.handleExpectedAmountDialogOk}
      />
    );

    return (
      <PageLayout
        withBottomScrollPadding
        dialogs={dialog}
        toolbar={formatMessage(messages.worktypeStatus, {
          worktype: worktypeName,
        })}
      >
        <Table size="small" style={{backgroundColor: "#fff"}}>
          <TableHead>
            <TableRow>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Kunde"
                  id="worktype-status-list.table-header.customer"
                />
              </TableCell>
              <TableCell>{this.state.year}</TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Udført"
                  id="worktype-status-list.table-header.completed-amount"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Mangler"
                  id="worktype-status-list.table-header.missing"
                />
              </TableCell>
              <TableCell style={COMPLETED_COLUMN_STYLE}>
                <FormattedMessage
                  defaultMessage="Afsluttet"
                  id="worktype-status-list.table-header.completed"
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{rows}</TableBody>
        </Table>
        {spinner}
      </PageLayout>
    );
  }
}

const ConnectedWorkTypeStatusList = connect<WorkTypeStatusListStateProps, object, object, AppState>(
  createStructuredSelector<AppState, WorkTypeStatusListStateProps>({
    currentRole: getCurrentRole,
    id: makePathParameterGetter("id"),
    unit: makePathParameterGetter("unit"),
    unitLookup: getUnitLookup,
    workTypeLookup: getWorkTypeLookup,
  }),
  {},
)(WorkTypeStatusList);

export {ConnectedWorkTypeStatusList as WorkTypeStatusList};
