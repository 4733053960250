import {schema} from "@co-common-libs/config";
import {identifierComparator} from "@co-common-libs/utils";
import {
  actions,
  getCurrentUserURL,
  getSettingsEntryLookupByIdentifier,
} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import React, {useCallback, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {SettingViewProps} from "../../types";
import {DisplaySelected} from "../display-selected";
import {MultipleBarcodeFormatsDialog} from "./multiple-barcode-formats-dialog";

export function SelectBarcodeFormats(props: SettingViewProps): JSX.Element {
  const {settingID, settingMetaData} = props;
  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const settingEntry = settingsEntryLookupByIdentifier(settingID);

  const formats = schema.definitions.barcodeFormats.items.enum;

  const selected: string[] = useMemo(() => settingEntry?.data || [], [settingEntry?.data]);

  const [selectionDialogOpen, setSelectionDialogOpen] = useState(false);
  const setSelectionDialogOpenTrue = useCallWithTrue(setSelectionDialogOpen);
  const setSelectionDialogOpenFalse = useCallWithFalse(setSelectionDialogOpen);

  const dispatch = useDispatch();
  const currentUserURL = useSelector(getCurrentUserURL);
  const handleSelectionDialogOk = useCallback(
    (newSelected: ReadonlySet<string>) => {
      setSelectionDialogOpen(false);

      const newValue = newSelected.size ? Array.from(newSelected) : null;

      if (settingEntry) {
        dispatch(
          actions.update(settingEntry.url, [
            {member: "changedBy", value: currentUserURL},
            {member: "data", value: newValue},
          ]),
        );
      }
    },
    [currentUserURL, dispatch, settingEntry],
  );

  const sortedSelected = useMemo(() => selected.slice().sort(identifierComparator), [selected]);

  return (
    <>
      <DisplaySelected
        currentValue={sortedSelected}
        settingEntry={settingEntry}
        settingID={settingID}
        settingMetaData={settingMetaData}
        onClick={setSelectionDialogOpenTrue}
      />
      <MultipleBarcodeFormatsDialog
        formats={formats}
        open={selectionDialogOpen}
        selected={new Set(selected)}
        onCancel={setSelectionDialogOpenFalse}
        onOk={handleSelectionDialogOk}
      />
    </>
  );
}
