import {Config} from "@co-common-libs/config";

export type EconomicProjectSyncEnabledCustomerSettings = Pick<
  Config,
  | "economicEnableProjectActivitiesImport"
  | "economicProjectsMachineHoursActivity"
  | "economicProjectsManHoursActivity"
  | "economicProjectsProductUseCostActivity"
  | "economicProjectsProductUseCostEmployee"
  | "economicSync"
  | "enableProjects"
>;

export function economicProjectImportEnabled(
  customerSettings: EconomicProjectSyncEnabledCustomerSettings,
): boolean {
  const {
    economicEnableProjectActivitiesImport,
    economicProjectsMachineHoursActivity,
    economicProjectsManHoursActivity,
    economicProjectsProductUseCostActivity,
    economicProjectsProductUseCostEmployee,
    economicSync,
    enableProjects,
  } = customerSettings;
  return Boolean(
    economicSync &&
      enableProjects &&
      (economicEnableProjectActivitiesImport ||
        economicProjectsManHoursActivity != null ||
        economicProjectsMachineHoursActivity != null ||
        (economicProjectsProductUseCostActivity != null &&
          economicProjectsProductUseCostEmployee != null)),
  );
}
