import {Customer} from "@co-common-libs/resources";
import {AppState, getActiveCustomerArray} from "@co-frontend-libs/redux";
import {createSelector} from "reselect";

function filterSavedInEconomic(customers: readonly Customer[]): readonly Customer[] {
  return customers.filter(({remoteUrl}) => !!remoteUrl);
}

export const getSavedInEconomicCustomerArray: (state: AppState) => readonly Customer[] =
  createSelector(getActiveCustomerArray, filterSavedInEconomic);
