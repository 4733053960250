import {WEEK_DAYS, dateFromString, dateToString} from "@co-common-libs/utils";
import {addDays, addMonths, subDays} from "date-fns";

/**
 * Try to suggest "next in sequence" values for `fromDate` and `toDate`,
 * in the following way:
 * * If the input appears to cover a calendar month (possibly offset), then
 *   suggest the next (possibly offset) calendar month.
 * * If the input appears to cover 1, 2, 3 or 4 weeks (possibly offset), then
 *   suggest the next (possibly offset) same number of weeks.
 * * Otherwise, suggest the following day.
 */
export function suggestNextDateInterval(current: {fromDate: string; toDate: string}): {
  fromDate: string;
  toDate: string | null;
} {
  const previousToDate = dateFromString(current.toDate) as Date;

  const fromDateDate = addDays(previousToDate, 1);

  const fromDate = dateToString(fromDateDate);
  if (current.fromDate === current.toDate) {
    return {fromDate, toDate: fromDate};
  }

  const previousFromDateDate = dateFromString(current.fromDate) as Date;

  const previousMonthToDate = dateToString(subDays(addMonths(previousFromDateDate, 1), 1));
  if (current.toDate === previousMonthToDate) {
    const toDate = dateToString(subDays(addMonths(fromDateDate, 1), 1));
    return {fromDate, toDate};
  }

  const MAX_WEEKS_MATCHED = 4;

  for (let i = 1; i <= MAX_WEEKS_MATCHED; i += 1) {
    const previousWeeksToDate = dateToString(addDays(previousFromDateDate, i * WEEK_DAYS - 1));
    if (current.toDate === previousWeeksToDate) {
      const toDate = dateToString(addDays(fromDateDate, i * WEEK_DAYS - 1));
      return {fromDate, toDate};
    }
  }

  return {fromDate, toDate: fromDate};
}
