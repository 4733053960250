import {SettingID, schema} from "@co-common-libs/config";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {
  actions,
  getCurrentUserURL,
  getCustomerSettings,
  getSettingsEntryLookupByIdentifier,
} from "@co-frontend-libs/redux";
import {DialogContent, List} from "@material-ui/core";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import React, {useCallback, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuid} from "uuid";
import {CustomerRequiredFieldListItem} from "./item";
import {CustomerRequiredFieldChoice} from "./types";

interface IndustriesDialogProps {
  onClose: () => void;
  open: boolean;
  settingID: SettingID;
}

export function CustomerRequiredFieldsDialog(props: IndustriesDialogProps): JSX.Element {
  const {onClose, open, settingID} = props;
  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const settingsEntry = settingsEntryLookupByIdentifier(settingID);
  const currentUserURL = useSelector(getCurrentUserURL);
  const dispatch = useDispatch();
  const customerSettings = useSelector(getCustomerSettings);
  const intl = useIntl();
  const customerRequiredFields = _.sortBy(schema.properties.customerRequiredFields.items.enum);

  const [selected, setSelected] = useState<readonly CustomerRequiredFieldChoice[]>(
    customerSettings.customerRequiredFields,
  );

  useEffect(() => {
    setSelected(customerSettings.customerRequiredFields);
  }, [customerSettings.customerRequiredFields]);

  const handleSave = useCallback(() => {
    if (settingsEntry) {
      dispatch(
        actions.update(settingsEntry.url, [
          {member: "changedBy", value: currentUserURL},
          {member: "data", value: selected},
        ]),
      );
    } else {
      const id = uuid();
      const url = instanceURL("settingEntry", id);
      dispatch(
        actions.create({
          changedBy: currentUserURL,
          data: selected,
          key: settingID,
          url,
        }),
      );
    }
    onClose();
  }, [currentUserURL, dispatch, onClose, selected, settingID, settingsEntry]);

  const handleToggle = useCallback(
    (industry: CustomerRequiredFieldChoice) => {
      if (selected.includes(industry)) {
        setSelected(selected.filter((x) => x !== industry));
      } else {
        setSelected([...selected, industry]);
      }
    },
    [selected],
  );
  return (
    <ResponsiveDialog
      okLabel={intl.formatMessage({defaultMessage: "Gem"})}
      open={open}
      title={intl.formatMessage({
        defaultMessage: "Påkrævede felter ved kundeoprettelse",
      })}
      onCancel={onClose}
      onOk={handleSave}
    >
      <DialogContent>
        <List>
          {customerRequiredFields.map((entry) => (
            <CustomerRequiredFieldListItem
              key={entry}
              checked={selected.includes(entry)}
              customerRequiredField={entry}
              onClick={handleToggle}
            />
          ))}
        </List>
      </DialogContent>
    </ResponsiveDialog>
  );
}
