import {Project} from "@co-common-libs/resources";
import {DeleteDialog} from "@co-frontend-libs/components";
import {
  actions,
  getCurrentRoleIsManager,
  getCurrentRoleIsProjectManager,
  getCustomerSettings,
  getTaskArray,
} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {IconButton} from "@material-ui/core";
import DeleteIcon from "mdi-react/DeleteIcon";
import React, {useCallback, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

export const ProjectDeleteButton = React.memo(function ProjectDeleteButton(props: {
  project: Project;
}): JSX.Element | null {
  const {project} = props;
  const dispatch = useDispatch();

  const {projectLabelVariant} = useSelector(getCustomerSettings);
  const projectHasTasks = useSelector(getTaskArray).some((t) => t.project === project.url);
  const projectIsOnRemoteSystem = !!project?.remoteUrl;

  const [deleteProjectDialogOpen, setDeleteProjectDialogOpen] = useState(false);
  const setDeleteProjectDialogOpenTrue = useCallWithTrue(setDeleteProjectDialogOpen);
  const setDeleteProjectDialogOpenFalse = useCallWithFalse(setDeleteProjectDialogOpen);

  const isManager = useSelector(getCurrentRoleIsManager);
  const isProjectManager = useSelector(getCurrentRoleIsProjectManager);
  const roleCanEditProjects = isManager || isProjectManager;

  const goBack = useCallback((): void => {
    dispatch(actions.back());
  }, [dispatch]);

  const handleProjectDeleteOk = useCallback((): void => {
    dispatch(actions.remove(project.url));
    setDeleteProjectDialogOpenFalse();
    goBack();
  }, [dispatch, project, goBack, setDeleteProjectDialogOpenFalse]);

  return roleCanEditProjects && !projectHasTasks && !projectIsOnRemoteSystem ? (
    <div>
      <IconButton onClick={setDeleteProjectDialogOpenTrue}>
        <DeleteIcon />
      </IconButton>

      <DeleteDialog
        open={deleteProjectDialogOpen}
        onCancel={setDeleteProjectDialogOpenFalse}
        onOk={handleProjectDeleteOk}
      >
        {projectLabelVariant === "PROJECT" ? (
          <FormattedMessage defaultMessage="Slet projekt?" />
        ) : (
          <FormattedMessage defaultMessage="Slet sag?" />
        )}
      </DeleteDialog>
    </div>
  ) : null;
});
