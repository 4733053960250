import {
  KrPerLiterFuelSurchargeBasis,
  KrPerLiterFuelSurchargeInvoiceLineTextChoice,
  KrPerLiterFuelSurchargeSpecification,
  ProductUrl,
} from "@co-common-libs/resources";
import {
  DecimalField,
  DialogContentHeader,
  ResponsiveDialog,
  TrimTextField,
} from "@co-frontend-libs/components";
import {ConnectedSingleProductDialog} from "@co-frontend-libs/connected-components";
import {getCustomerSettings, getProductLookup} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {
  Button,
  Checkbox,
  DialogContent,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import CloseIcon from "mdi-react/CloseIcon";
import React, {useCallback, useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useSelector} from "react-redux";

interface KrPerLiterFuelSurchargeSpecificationDialogProps {
  onCancel: () => void;
  onOk: (data: {
    basis: Omit<
      KrPerLiterFuelSurchargeBasis,
      "fromDate" | "id" | "lastChanged" | "specification" | "toDate" | "url"
    >;
    specification: Omit<
      KrPerLiterFuelSurchargeSpecification,
      "active" | "changedBy" | "id" | "lastChanged" | "url"
    >;
  }) => void;
  open: boolean;
}

export const KrPerLiterFuelSurchargeSpecificationDialog = React.memo(
  function KrPerLiterFuelSurchargeSpecificationDialog(
    props: KrPerLiterFuelSurchargeSpecificationDialogProps,
  ): JSX.Element {
    const {onCancel, onOk, open} = props;

    const intl = useIntl();

    const productLookup = useSelector(getProductLookup);

    const customerSettings = useSelector(getCustomerSettings);

    const [name, setName] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [basePriceKrPerLiter, setBasePriceKrPerLiter] = useState<number | null>(null);

    const [priceKrPerLiterIncreaseThreshold, setPriceKrPerLiterIncreaseThreshold] = useState<
      number | null
    >(null);

    const [truncateNegativeToZero, setTruncateNegativeToZero] = useState<boolean>(true);
    const [zeroSurchargeInvoiceLines, setZeroSurchargeInvoiceLines] = useState<boolean>(false);
    const [invoiceLineText, setInvoiceLineText] =
      useState<KrPerLiterFuelSurchargeInvoiceLineTextChoice>("product_text");

    const [conversionFactor, setConversionFactor] = useState<1 | 1000>(1);
    const [invoiceLineProduct, setInvoiceLineProduct] = useState<ProductUrl>();

    const [productDialogOpen, setProductDialogOpen] = useState(false);
    const setProductDialogOpenTrue = useCallWithTrue(setProductDialogOpen);
    const setProductDialogOpenFalse = useCallWithFalse(setProductDialogOpen);

    const handleProductDialogOk = useCallback((productUrl: ProductUrl) => {
      setInvoiceLineProduct(productUrl);
      setProductDialogOpen(false);
    }, []);

    const handleClearProductButton = useCallback((): void => {
      setInvoiceLineProduct(undefined);
    }, []);

    const handleTruncateNegativeToZeroChange = useCallback(() => {
      setTruncateNegativeToZero(!truncateNegativeToZero);
    }, [truncateNegativeToZero]);

    const handleZeroSurchargeInvoiceLinesChange = useCallback(() => {
      setZeroSurchargeInvoiceLines(!zeroSurchargeInvoiceLines);
    }, [zeroSurchargeInvoiceLines]);

    const handleInvoiceLineTextChange = useCallback(
      (_event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        if (value === "product_text" || value === "product_text_and_surcharge_details") {
          setInvoiceLineText(value);
        }
      },
      [],
    );

    const handleConversionFactorChange = useCallback(
      (_event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        if (value === "1000") {
          setConversionFactor(1000);
        } else if (value === "1") {
          setConversionFactor(1);
        }
      },
      [],
    );

    const maxDigits = conversionFactor === 1 ? 5 : 8;

    useEffect(() => {
      if (open) {
        setName("");
        setDescription("");
        setBasePriceKrPerLiter(null);
        setPriceKrPerLiterIncreaseThreshold(null);
        setTruncateNegativeToZero(true);
        setZeroSurchargeInvoiceLines(false);
        setInvoiceLineText("product_text");
        setConversionFactor(1);
        setInvoiceLineProduct(undefined);
      }
    }, [open]);

    const okDisabled =
      !name ||
      basePriceKrPerLiter === null ||
      (customerSettings.economicSync && !invoiceLineProduct);

    const handleOk = useCallback(() => {
      if (
        name &&
        basePriceKrPerLiter !== null &&
        (invoiceLineProduct || !customerSettings.economicSync)
      ) {
        onOk({
          basis: {
            basePriceKrPerLiter: basePriceKrPerLiter / conversionFactor,
            priceKrPerLiterIncreaseThreshold:
              (priceKrPerLiterIncreaseThreshold || 0) / conversionFactor,
            truncateNegativeToZero,
          },
          specification: {
            conversionFactor,
            description,
            invoiceLineProduct: invoiceLineProduct ?? null,
            invoiceLineText,
            name,
            zeroSurchargeInvoiceLines,
          },
        });
      }
    }, [
      basePriceKrPerLiter,
      conversionFactor,
      customerSettings.economicSync,
      description,
      invoiceLineProduct,
      invoiceLineText,
      name,
      onOk,
      priceKrPerLiterIncreaseThreshold,
      truncateNegativeToZero,
      zeroSurchargeInvoiceLines,
    ]);

    const product = invoiceLineProduct ? productLookup(invoiceLineProduct) : null;

    return (
      <>
        <ResponsiveDialog
          requiredFieldsHelperText
          okDisabled={okDisabled}
          open={open && !productDialogOpen}
          title={intl.formatMessage({defaultMessage: "Opret brændstoftillæg"})}
          onCancel={onCancel}
          onOk={handleOk}
        >
          <DialogContent>
            <TrimTextField
              autoFocus
              fullWidth
              label={intl.formatMessage({
                defaultMessage: "Navn *",
              })}
              margin="dense"
              value={name}
              variant="outlined"
              onChange={setName}
            />
            <TrimTextField
              fullWidth
              multiline
              label={intl.formatMessage({
                defaultMessage: "Beskrivelse",
              })}
              margin="dense"
              value={description}
              variant="outlined"
              onChange={setDescription}
            />
            <Divider />
            <DialogContentHeader>
              <FormattedMessage defaultMessage="Basis" />
            </DialogContentHeader>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={6}>
                <DecimalField
                  fullWidth
                  decimalPlaces={2}
                  label={intl.formatMessage({
                    defaultMessage: "Pris *",
                  })}
                  margin="dense"
                  maxDigits={maxDigits}
                  value={basePriceKrPerLiter}
                  onChange={setBasePriceKrPerLiter}
                />
              </Grid>
              <Grid item xs={6}>
                <RadioGroup
                  row
                  value={`${conversionFactor}`}
                  onChange={handleConversionFactorChange}
                >
                  <FormControlLabel
                    control={<Radio />}
                    label={intl.formatMessage({
                      defaultMessage: "kr/L",
                    })}
                    value="1"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label={intl.formatMessage(
                      {
                        defaultMessage: "kr/{conversionFactor, number} L",
                      },
                      {conversionFactor: 1000},
                    )}
                    value="1000"
                  />
                </RadioGroup>
              </Grid>
            </Grid>
            <Divider />
            <DialogContentHeader>
              <FormattedMessage defaultMessage="Grundlag" />
            </DialogContentHeader>
            <DecimalField
              fullWidth
              decimalPlaces={2}
              helperText={intl.formatMessage({
                defaultMessage: "Tilladt prisstigning før kunden skal have tillagt brændstoftillæg",
              })}
              label={
                conversionFactor === 1
                  ? intl.formatMessage({
                      defaultMessage: "Margin (kr/L)",
                    })
                  : intl.formatMessage(
                      {
                        defaultMessage: "Margin (kr/{conversionFactor, number} L)",
                      },
                      {conversionFactor},
                    )
              }
              margin="dense"
              maxDigits={maxDigits}
              value={priceKrPerLiterIncreaseThreshold}
              onChange={setPriceKrPerLiterIncreaseThreshold}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={truncateNegativeToZero}
                  onChange={handleTruncateNegativeToZeroChange}
                />
              }
              label={intl.formatMessage({
                defaultMessage:
                  "Ved brændstofpris under basisprisen gives der ikke negativt tillæg",
              })}
            />
            <Divider />
            <DialogContentHeader>
              <FormattedMessage defaultMessage="Faktureringsregel" />
            </DialogContentHeader>
            <FormControlLabel
              control={
                <Checkbox
                  checked={zeroSurchargeInvoiceLines}
                  onChange={handleZeroSurchargeInvoiceLinesChange}
                />
              }
              label={intl.formatMessage({
                defaultMessage:
                  "Opret fakturalinjer for brændstoftillæg, selv hvis tillægget er 0 kr/L",
              })}
            />
            <Divider />
            <DialogContentHeader>
              <FormattedMessage defaultMessage="Fakturalinjetekst" />
            </DialogContentHeader>
            <RadioGroup value={invoiceLineText ?? "-"} onChange={handleInvoiceLineTextChange}>
              <FormControlLabel
                control={<Radio />}
                label={
                  customerSettings.economicSync
                    ? intl.formatMessage({defaultMessage: "Varenavn"})
                    : intl.formatMessage({
                        defaultMessage: "Varenavn/tillægsnavn",
                      })
                }
                value="product_text"
              />
              <FormControlLabel
                control={<Radio />}
                label={
                  customerSettings.economicSync
                    ? intl.formatMessage({
                        defaultMessage: "Varenavn, indeks-periode, indeks-pris",
                      })
                    : intl.formatMessage({
                        defaultMessage: "Varenavn/tillægsnavn, indeks-periode, indeks-pris",
                      })
                }
                value="product_text_and_surcharge_details"
              />
            </RadioGroup>
            <Divider />
            <DialogContentHeader>
              <FormattedMessage defaultMessage="Fakturavare" />
            </DialogContentHeader>
            <Button color="primary" variant="contained" onClick={setProductDialogOpenTrue}>
              {customerSettings.economicSync ? (
                <FormattedMessage defaultMessage="Vælg vare *" />
              ) : (
                <FormattedMessage defaultMessage="Vælg vare" />
              )}
            </Button>
            {product ? (
              <div>
                {product.name}: ({product.catalogNumber})
                <IconButton onClick={handleClearProductButton}>
                  <CloseIcon />
                </IconButton>
              </div>
            ) : null}
          </DialogContent>
        </ResponsiveDialog>
        <ConnectedSingleProductDialog
          open={productDialogOpen}
          onCancel={setProductDialogOpenFalse}
          onOk={handleProductDialogOk}
        />
      </>
    );
  },
);
