import {getCustomerSettings} from "@co-frontend-libs/redux";
import {Card, CardContent, CardHeader} from "@material-ui/core";
import React from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {KrPerLiterDefaultFuelSurchargeBlock} from "./kr-per-liter-default-fuels-surchanrge-block";
import {PricePercentDefaultFuelSurchargeBlock} from "./price-percent-default-fuels-surchanrge-block";

export const DefaultFuelSurchargeCard = React.memo(function DefaultSurchargeCard(): JSX.Element {
  const intl = useIntl();
  const customerSettings = useSelector(getCustomerSettings);
  const {fuelSurcharge} = customerSettings;
  return (
    <Card style={{margin: 16}}>
      <CardHeader
        title={intl.formatMessage({
          defaultMessage: "Standard brændstoftillæg",
        })}
      />
      <CardContent>
        {fuelSurcharge === "PRICE_PERCENT" ? (
          <PricePercentDefaultFuelSurchargeBlock />
        ) : (
          <KrPerLiterDefaultFuelSurchargeBlock />
        )}
      </CardContent>
    </Card>
  );
});
