import {Patch, ResourceInstance, applyPatch} from "@co-common-libs/resources";
import {notNull} from "@co-common-libs/utils";
import _ from "lodash";
import {ProvisionaryCommand} from "../resources/actions";
import {QueryQueryStateStruct, ResourcesState} from "../resources/types";
import {partialValuesForEach, partialValuesSome} from "../resources/utils";

export function getInstances<T extends ResourceInstance, Url extends string>(
  lookup: (url: Url) => T | undefined,
  command: ProvisionaryCommand,
): {newInstance: T | null; oldInstance: T | undefined} {
  const oldInstance = lookup(command.url as Url);
  let newInstance: T | null;
  if (command.action === "DELETE") {
    newInstance = null;
  } else if (
    command.action === "CREATE" ||
    (command.action === "CREATE_OR_UPDATE" && !oldInstance)
  ) {
    console.assert(!oldInstance);
    newInstance = command.instance as unknown as T;
  } else {
    console.assert(
      command.action === "UPDATE" || (command.action === "CREATE_OR_UPDATE" && oldInstance),
    );
    console.assert(oldInstance);
    newInstance = applyPatch<T>(oldInstance as T, command.patch as Patch<T>);
  }
  return {newInstance, oldInstance};
}

function checkQueryFullFetchPending(queryQueryStateStruct: QueryQueryStateStruct): boolean {
  const {query, queryState} = queryQueryStateStruct;
  return (
    query.independentFetch &&
    !queryState.pendingRemoval &&
    !queryState.fullFetchDataComputedAtTimestamp
  );
}

export function fullFetchesPending(state: ResourcesState): boolean {
  const {persistedQueries, temporaryQueries} = state;
  return (
    partialValuesSome(persistedQueries, checkQueryFullFetchPending) ||
    partialValuesSome(temporaryQueries, checkQueryFullFetchPending)
  );
}

export function computeNextFetchChangesTimestamp(state: ResourcesState): string | null {
  let nextFetchChangesTimestamp = state.lastFetchChangesTimestamp;
  const updateNextFetchChangesTimestamp = (queryQueryStateStruct: QueryQueryStateStruct): void => {
    const {query: q, queryState} = queryQueryStateStruct;
    if (q.independentFetch && queryState.fullFetchDataComputedAtTimestamp) {
      const {fullFetchDataComputedAtTimestamp, takenIntoAccountForChangesComputedAtTimestamp} =
        queryState;
      const queryTimestamp = _.max(
        [fullFetchDataComputedAtTimestamp, takenIntoAccountForChangesComputedAtTimestamp].filter(
          notNull,
        ),
      ) as string;
      if (!nextFetchChangesTimestamp || queryTimestamp < nextFetchChangesTimestamp) {
        nextFetchChangesTimestamp = queryTimestamp;
      }
    }
  };
  partialValuesForEach(state.persistedQueries, updateNextFetchChangesTimestamp);
  partialValuesForEach(state.temporaryQueries, updateNextFetchChangesTimestamp);
  return nextFetchChangesTimestamp;
}
