import {Product, ProductUrl} from "@co-common-libs/resources";
import {
  Checkbox,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import _ from "lodash";
import ImageIcon from "mdi-react/ImageIcon";
import React from "react";
import {IntlContext, defineMessages} from "react-intl";

const messages = defineMessages({
  catalogNumber: {
    defaultMessage: "Varenr.: {catalogNumber}",
    id: "product-group-tree-dialog.label.catalogNumber",
  },
});

interface ProductListItemProps {
  isSelected: boolean;
  onClick: (
    event: React.ChangeEvent<HTMLInputElement> | React.MouseEvent,
    url: ProductUrl,
    isInputChecked?: boolean,
  ) => void;
  product: Product;
}

class ProductListItem extends PureComponent<ProductListItemProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  @bind
  handleClick(event: React.MouseEvent): void {
    const {onClick, product} = this.props;
    const {url} = product;
    onClick(event, url);
  }
  @bind
  handleCheck(event: React.ChangeEvent<HTMLInputElement>, isInputChecked: boolean): void {
    const {onClick, product} = this.props;
    const {url} = product;
    onClick(event, url, isInputChecked);
  }
  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {isSelected, product} = this.props;
    const {catalogNumber, name} = product;
    const catalogNumberString = catalogNumber
      ? formatMessage(messages.catalogNumber, {catalogNumber})
      : "";
    const photoURL = product.photoUrl;
    let image;
    let icon;
    if (photoURL) {
      image = <img alt="" height={80} src={photoURL} width={80} />;
    } else {
      icon = <ImageIcon />;
    }
    return (
      <ListItem button onClick={this.handleClick}>
        {image ? <ListItemAvatar>{image}</ListItemAvatar> : undefined}
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : undefined}
        <ListItemIcon>
          <Checkbox
            checked={isSelected}
            style={{display: "inline-block", width: undefined as any}}
            onChange={this.handleCheck}
          />
        </ListItemIcon>
        <ListItemText primary={name} secondary={catalogNumberString} />
      </ListItem>
    );
  }
}

interface ProductListProps {
  onClick: (
    event: React.ChangeEvent<HTMLInputElement> | React.MouseEvent,
    url: ProductUrl,
    isInputChecked?: boolean,
  ) => void;
  productArray: readonly Product[];
  selectedProducts: ReadonlySet<string>;
}

export class ProductList extends PureComponent<ProductListProps> {
  @bind
  handleProductListItemClick(
    event: React.ChangeEvent<HTMLInputElement> | React.MouseEvent,
    url: ProductUrl,
    checked?: boolean,
  ): void {
    this.props.onClick(event, url, checked);
  }
  render(): JSX.Element | null {
    const {productArray, selectedProducts} = this.props;

    const productListItemList = productArray.map((product) => {
      const {url} = product;
      return (
        <ProductListItem
          key={url}
          isSelected={selectedProducts.has(url)}
          product={product}
          onClick={this.handleProductListItemClick}
        />
      );
    });
    return <List>{productListItemList}</List>;
  }
}
