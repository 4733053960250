import {ResourceInstance} from "@co-common-libs/resources";
import _ from "lodash";

export function changeIsNewer(
  newInstance: ResourceInstance,
  oldInstance?: ResourceInstance,
): boolean {
  if (oldInstance) {
    const newLastChanged = newInstance.lastChanged;
    const oldLastChanged = oldInstance.lastChanged;
    return newLastChanged && oldLastChanged
      ? newLastChanged > oldLastChanged
      : !_.isEqual(newInstance, oldInstance);
  } else {
    return true;
  }
}
