import {Button, Dialog, DialogActions} from "@material-ui/core";
import {MuiPickersContext, TimePicker} from "@material-ui/pickers";
import {Locale} from "date-fns";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";

export interface TimeDialogProps {
  autoOk?: boolean;
  onCancel: () => void;
  onOk: (date: Date | null) => void;
  open: boolean;
  value?: Date;
}

export function TimeDialog(props: TimeDialogProps): JSX.Element {
  const {autoOk, onCancel, onOk, open, value} = props;
  const [time, setTime] = useState<Date | null>(value || new Date());
  const handleTimeChange = useCallback(
    (newDate: Date | null): void => {
      if (autoOk) {
        onOk(newDate);
      } else {
        setTime(newDate);
      }
    },
    [autoOk, onOk],
  );
  useEffect(() => {
    if (open) {
      setTime(value || new Date());
    }
  }, [value, open]);
  const handleOk = useCallback(() => {
    onOk(time);
  }, [time, onOk]);

  const locale = useContext(MuiPickersContext)?.locale as Locale | undefined;
  const formatTimeString = (locale?.formatLong as any)?.time() as string | undefined;
  const ampm = !!formatTimeString?.includes("a");

  return (
    <Dialog open={props.open} onClose={onCancel}>
      <TimePicker ampm={ampm} value={time} variant="static" onChange={handleTimeChange} />
      <DialogActions>
        <Button color="primary" onClick={onCancel}>
          <FormattedMessage defaultMessage="Fortryd" id="dialog.label.cancel" />
        </Button>
        {autoOk ? undefined : (
          <Button color="primary" onClick={handleOk}>
            <FormattedMessage defaultMessage="OK" id="dialog.label.ok" />
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
