import {PriceItem, PriceItemUrl, PriceItemUse, Unit, UnitUrl} from "@co-common-libs/resources";
import {getUnitCode} from "./unit";

export const ITEM_TYPE_MINIMUM = 0;
export const ITEM_TYPE_STARTPRIS = 1;
export const ITEM_TYPE_TIMER = 2;
export const ITEM_TYPE_DIFFERENT = 3;
export const ITEM_TYPE_TILLAEG = 4;
export const ITEM_TYPE_VARE = 5;
// export const ITEM_TYPE_MEDARBEJDER = 6;
// export const ITEM_TYPE_KILOMETER = 7;
// export const ITEM_TYPE_DIESELTILLAEG = 8;

export const HOUR_UNITS = ["tim", "time", "timer", "tim.", "time(r)"];

export function basePriceItemIsTime(
  priceItem: Pick<PriceItem, "genericEffectiveTimerTarget" | "itemtype">,
  unit: Pick<Unit, "name"> | null | undefined,
): boolean {
  const itemType = priceItem.itemtype;
  const genericEffectiveTimerTarget = !!priceItem.genericEffectiveTimerTarget;
  if (genericEffectiveTimerTarget) {
    return true;
  } else if (itemType === ITEM_TYPE_TIMER && unit) {
    const unitLower = unit.name.toLowerCase();
    return HOUR_UNITS.includes(unitLower);
  }
  return false;
}

export function priceItemIsTime(
  unitLookup: (url: UnitUrl) => Unit | undefined,
  priceItem: PriceItem,
): boolean {
  const unitUrl = priceItem.relatedUnit;
  const unit = unitUrl ? unitLookup(unitUrl) : undefined;
  return basePriceItemIsTime(priceItem, unit);
}

export const priceItemIsManualDistributionTime = (
  unitLookup: (url: UnitUrl) => Unit | undefined,
  priceItem: PriceItem,
): boolean => {
  return (
    priceItemIsTime(unitLookup, priceItem) &&
    (priceItem.genericEffectiveTimerTarget == null || !!priceItem.useManualDistribution)
  );
};

export const priceItemUseSetHasMultipleManualDistribution = (
  priceItemUseSet: readonly PriceItemUse[],
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined,
  unitLookup: (url: UnitUrl) => Unit | undefined,
): boolean => {
  return (
    priceItemUseSet.filter((instance) => {
      const priceItemURL = instance.priceItem;
      const priceItem = priceItemLookup(priceItemURL);
      return priceItem && priceItemIsManualDistributionTime(unitLookup, priceItem);
    }).length > 1
  );
};

type PartialInlinedPriceItemUse = {
  priceItem: PriceItem | null | undefined;
};

export function priceItemIsVisible(
  priceItem: PriceItem,
  duringInvoicing: boolean,
  taskPriceItemUseList: readonly PartialInlinedPriceItemUse[],
  unitLookup: (url: UnitUrl) => Unit | undefined,
): boolean;
export function priceItemIsVisible(
  priceItem: PriceItem,
  duringInvoicing: boolean,
  taskPriceItemUseList: readonly PriceItemUse[],
  unitLookup: (url: UnitUrl) => Unit | undefined,
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined,
): boolean;
export function priceItemIsVisible(
  priceItem: PriceItem,
  duringInvoicing: boolean,
  taskPriceItemUseList: readonly PartialInlinedPriceItemUse[] | readonly PriceItemUse[],
  unitLookup: (url: UnitUrl) => Unit | undefined,
  priceItemLookup?: (url: PriceItemUrl) => PriceItem | undefined,
): boolean {
  // taskPriceItemUseList may be a list of PriceItemUses with embedded data, in
  // which case priceItemLookup isn't used and is expected to be undefined.
  if (priceItem.onlyVisibleOnOverview) {
    return false;
  }
  const isTime = priceItemIsTime(unitLookup, priceItem);
  if (isTime && !duringInvoicing) {
    return false;
  }

  // itemType is only used by C5 customers
  const itemType = priceItem.itemtype;
  if (itemType === null) {
    // Not C5 Agromat
    return duringInvoicing || priceItem.relevantForExecution !== false;
  } else {
    // C5 Agromat
    // The infamous diff1-lines are always visible
    if (itemType === ITEM_TYPE_DIFFERENT && priceItem.defaultCount === 1) {
      return true;
    }
    const unitLower = getUnitCode(priceItem, unitLookup).toLowerCase();
    if (itemType === ITEM_TYPE_MINIMUM || itemType === ITEM_TYPE_STARTPRIS) {
      return false;
    } else if (itemType === ITEM_TYPE_TIMER) {
      return true;
    } else if (itemType === ITEM_TYPE_DIFFERENT && HOUR_UNITS.includes(unitLower)) {
      // If there exists an ITEM_TYPE_TIMER PriceItem (via PriceItemUse) with
      // hour units then that is the target for the measured effective time.
      return taskPriceItemUseList.some((entry: PartialInlinedPriceItemUse | PriceItemUse) => {
        const otherPriceItem =
          typeof entry.priceItem === "string"
            ? (priceItemLookup as (url: PriceItemUrl) => PriceItem | undefined)(entry.priceItem)
            : entry.priceItem;
        return (
          otherPriceItem &&
          otherPriceItem.itemtype === ITEM_TYPE_TIMER &&
          HOUR_UNITS.includes(getUnitCode(otherPriceItem, unitLookup).toLowerCase())
        );
      });
    } else {
      return true;
    }
  }
}

export function includePriceItemInLogs(
  unitLookup: (url: UnitUrl) => Unit | undefined,
  taskPriceItemUseList: readonly PriceItemUse[],
  priceItem: PriceItem,
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined,
): boolean;
export function includePriceItemInLogs(
  unitLookup: (url: UnitUrl) => Unit | undefined,
  taskPriceItemUseList: readonly PartialInlinedPriceItemUse[],
  priceItem: PriceItem,
): boolean;
export function includePriceItemInLogs(
  unitLookup: (url: UnitUrl) => Unit | undefined,
  taskPriceItemUseList: readonly PartialInlinedPriceItemUse[] | readonly PriceItemUse[],
  priceItem: PriceItem,
  priceItemLookup?: (url: PriceItemUrl) => PriceItem | undefined,
): boolean {
  return (
    priceItem.includeInLogs !== false &&
    (priceItemLookup
      ? priceItemIsVisible(
          priceItem,
          false,
          taskPriceItemUseList as readonly PriceItemUse[],
          unitLookup,
          priceItemLookup,
        )
      : priceItemIsVisible(
          priceItem,
          false,
          taskPriceItemUseList as readonly PartialInlinedPriceItemUse[],
          unitLookup,
        ))
  );
}
