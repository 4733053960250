import {Config} from "@co-common-libs/config";
import {PriceItem, PriceItemUrl, PriceItemUse, Unit, UnitUrl} from "@co-common-libs/resources";
import {formatUnitString, getUnitCode, priceItemIsVisible} from "@co-common-libs/resources-utils";
import {ThrottledTextField} from "@co-frontend-libs/components";
import {AppState, getPriceItemLookup, getUnitLookup} from "@co-frontend-libs/redux";
import {IconButton, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {red} from "@material-ui/core/colors";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import bowser from "bowser";
import _ from "lodash";
import PlusIcon from "mdi-react/PlusIcon";
import React from "react";
// Allowed for existing code.
// eslint-disable-next-line deprecate/import
import {Cell, Grid} from "react-flexr";
import {FormattedMessage, IntlContext, defineMessages} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {MaterialField} from "./material-field";
import {MobileMaterialEntry} from "./mobile-material-entry";

const MOBILE = bowser.mobile;

const UNIT_COLUMN_WIDTH = 78;
const CONVERSION_UNIT_COLUMN_WIDTH = 96;
const INPUT_FIELD_WIDTH = 96;
const PADDING = 48;
const COUNT_COLUMN_WIDTH = INPUT_FIELD_WIDTH + PADDING;

const messages = defineMessages({
  notesLabel: {
    defaultMessage: "Noter",
    id: "price-item-table.label.notes",
  },
});

interface LegacyPriceItemEntryProps {
  baseUnit?: string | undefined;
  baseValue?: number | undefined;
  booleanSelection?: boolean;
  customerSettings: Config;
  dangling?: boolean;
  disabled: boolean;
  hasConversions: boolean;
  lastEntry?: boolean | undefined;
  name: string;
  notes: string;
  onChange: (priceItemIndex: number, value: number | null) => void;
  onNotesChange?: (priceItemIndex: number, value: string) => void;
  onPriceItemUseDetailButtonClick?:
    | ((name: string, notes: string, disabled: boolean, priceItemIndex: number) => void)
    | undefined;
  plusButtonStartingValue?: number;
  priceItemIndex: number;
  showNotes?: boolean | undefined;
  unit: string;
  value?: number | undefined;
}

export class LegacyPriceItemEntry extends PureComponent<LegacyPriceItemEntryProps> {
  static defaultProps = {
    showNotes: true,
  };
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  @bind
  handleChange(value: number | null): void {
    const {onChange, priceItemIndex} = this.props;
    onChange(priceItemIndex, value);
  }
  @bind
  handleNotesChange(value: string): void {
    const {onNotesChange, priceItemIndex} = this.props;
    if (onNotesChange) {
      onNotesChange(priceItemIndex, value);
    }
  }
  @bind
  handleRelationChange(value: number | null): void {
    if (value == null) {
      return;
    }
    const {baseValue} = this.props;
    if (baseValue == null) {
      return;
    }
    const resultValue = baseValue * value;
    this.handleChange(resultValue);
  }
  @bind
  handlePlusClick(_event: React.MouseEvent<unknown, MouseEvent>): void {
    const {plusButtonStartingValue, value} = this.props;
    const newValue = value != null ? value + 1 : plusButtonStartingValue || 0;
    this.handleChange(newValue);
  }
  @bind
  handlePriceItemUseDetailButtonClick(): void {
    const {disabled, name, notes, onPriceItemUseDetailButtonClick, priceItemIndex} = this.props;
    if (onPriceItemUseDetailButtonClick) {
      onPriceItemUseDetailButtonClick(name, notes, disabled, priceItemIndex);
    }
  }

  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {
      baseUnit,
      baseValue,
      booleanSelection,
      customerSettings,
      dangling,
      disabled,
      hasConversions,
      name,
      notes,
      onPriceItemUseDetailButtonClick,
      unit,
      value,
    } = this.props;
    if (MOBILE) {
      return (
        <MobileMaterialEntry
          baseUnit={baseUnit}
          baseValue={baseValue}
          booleanSelection={booleanSelection}
          dangling={dangling}
          decimalPlaces={customerSettings.materialDecimals}
          disabled={disabled}
          hasConversions={hasConversions}
          includeNoteInput={
            customerSettings.alternativeMaterialsMobileLayout &&
            customerSettings.enableMaterialNoteFields
          }
          name={name}
          notes={notes}
          showDetails={!!onPriceItemUseDetailButtonClick}
          unit={unit}
          value={value}
          onChange={this.handleChange}
          onNotesChange={this.handleNotesChange}
          onPlusClick={this.handlePlusClick}
          onPriceItemUseDetailButtonClick={
            customerSettings.enableMaterialNoteFields
              ? this.handlePriceItemUseDetailButtonClick
              : undefined
          }
          onRelationChange={this.handleRelationChange}
        />
      );
    } else {
      let color;
      if (dangling && value) {
        color = red[500];
      }
      const inputField = (
        <MaterialField
          booleanSelection={booleanSelection}
          customerSettings={customerSettings}
          disabled={disabled}
          unit={unit}
          value={value}
          onChange={this.handleChange}
        />
      );

      let conversionInputField;
      let conversionUnit: string | undefined;
      if (hasConversions && baseUnit) {
        let conversionValue: number | undefined;
        if (baseValue) {
          if (value != null) {
            const computedValue = value / baseValue;
            const decimalPlaces = customerSettings.materialDecimals;
            conversionValue = _.round(computedValue, decimalPlaces);
          }
        }
        conversionUnit = `${unit}/${baseUnit}`;
        conversionInputField = (
          <MaterialField
            customerSettings={customerSettings}
            disabled={disabled || !baseValue}
            unit={conversionUnit}
            value={conversionValue}
            onChange={this.handleRelationChange}
          />
        );
      }
      let conversionColumns;
      if (hasConversions) {
        conversionColumns = [];
        conversionColumns.push(
          <TableCell key="conversion-field" style={{width: COUNT_COLUMN_WIDTH}}>
            {conversionInputField}
          </TableCell>,
        );
        conversionColumns.push(
          <TableCell key="conversion-unit" style={{width: CONVERSION_UNIT_COLUMN_WIDTH}}>
            {conversionUnit}
          </TableCell>,
        );
      }
      const plusColumnCellStyle: React.CSSProperties = {
        paddingLeft: 0,
        paddingRight: 0,
        width: 48,
      };
      if (color) {
        plusColumnCellStyle.color = color;
      }
      const plusColumn = (
        <TableCell style={plusColumnCellStyle}>
          {!booleanSelection ? (
            <IconButton disabled={disabled} onClick={this.handlePlusClick}>
              {color ? <PlusIcon color={color} /> : <PlusIcon />}
            </IconButton>
          ) : null}
        </TableCell>
      );
      let notesColumn;
      if (this.props.showNotes) {
        notesColumn = (
          <TableCell>
            <ThrottledTextField
              fullWidth
              multiline
              label={formatMessage(messages.notesLabel)}
              margin="dense"
              value={notes}
              variant="outlined"
              onChange={this.handleNotesChange}
            />
          </TableCell>
        );
      }

      const rowStyle: React.CSSProperties =
        this.props.lastEntry !== undefined
          ? {
              borderBottomWidth: this.props.lastEntry ? 1 : 0,
            }
          : {};
      const cellCommonStyle: React.CSSProperties = color ? {color} : {};

      return (
        <TableRow style={rowStyle}>
          <TableCell style={cellCommonStyle}>{name}</TableCell>
          <TableCell style={{...cellCommonStyle, width: COUNT_COLUMN_WIDTH}}>
            {inputField}
          </TableCell>
          {plusColumn}
          <TableCell style={{...cellCommonStyle, width: UNIT_COLUMN_WIDTH}}>
            {!booleanSelection ? unit : null}
          </TableCell>
          {conversionColumns}
          {notesColumn}
        </TableRow>
      );
    }
  }
}

interface LegacyPriceItemTableStateProps {
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined;
  unitLookup: (url: UnitUrl) => Unit | undefined;
}

interface LegacyPriceItemTableOwnProps {
  baseUnit?: string;
  baseValue?: number;
  customerSettings: Config;
  filterOutPriceItems?: ReadonlySet<string>;
  onNotesChange: (priceItemIndex: number, value: string) => void;
  onPriceItemUseCountChange: (priceItemIndex: number, value: number | null) => void;
  onPriceItemUseDetailButtonClick?: (
    name: string,
    notes: string,
    disabled: boolean,
    priceItemIndex: number,
  ) => void;
  priceItemUseList: readonly PriceItemUse[];
  readonly: boolean;
  readonlyItems: ReadonlySet<string> | null;
  showNotes?: boolean;
}

type LegacyPriceItemTableProps = LegacyPriceItemTableOwnProps & LegacyPriceItemTableStateProps;

class LegacyPriceItemTable extends PureComponent<LegacyPriceItemTableProps> {
  static defaultProps = {
    showNotes: true,
  };
  @bind
  handlePriceItemUseCountChange(index: number, value: number | null): void {
    const {onPriceItemUseCountChange, priceItemLookup, priceItemUseList} = this.props;
    if (!value) {
      // zero or null/clear
      onPriceItemUseCountChange(index, value);
      return;
    }

    const priceItemUse = priceItemUseList[index];
    if (!priceItemUse) {
      return;
    }
    const priceItemURL = priceItemUse.priceItem;
    const priceItem = priceItemLookup(priceItemURL);
    if (!priceItem) {
      return;
    }

    let baseValue = value;
    const conversionUnitURL = priceItem.conversionUnit;
    const {conversionFactor} = priceItem;
    if (conversionUnitURL && conversionFactor) {
      baseValue = value * conversionFactor;
    }
    const maxStoredDecimals = 5;
    const roundedValue = _.round(baseValue, maxStoredDecimals);
    onPriceItemUseCountChange(index, roundedValue);
  }

  render(): JSX.Element | null {
    const {
      customerSettings,
      filterOutPriceItems,
      onNotesChange,
      priceItemLookup,
      priceItemUseList,
      readonly,
      showNotes,
      unitLookup,
    } = this.props;
    const hasConversions = !!Object.keys(customerSettings.priceItemRelationUnitConversionHelpers);

    const outputToInputConversionMapping: {[unit: string]: string[]} = {};
    if (hasConversions) {
      Object.entries(customerSettings.priceItemRelationUnitConversionHelpers).forEach(
        ([baseUnit, unitList]) => {
          if (unitList) {
            unitList.forEach((unit) => {
              if (!outputToInputConversionMapping[unit]) {
                outputToInputConversionMapping[unit] = [baseUnit];
              } else {
                outputToInputConversionMapping[unit].push(baseUnit);
              }
            });
          }
        },
      );
    }

    const sortedItems: {
      baseUnit?: string | undefined;
      baseValue?: number | undefined;
      conversionFactor: number | null;
      index: number;
      priceItem: PriceItem;
      priceItemUse: PriceItemUse;
      unit: string;
    }[] = [];
    let showConversionColumns = false;
    priceItemUseList.forEach((priceItemUse, index) => {
      const priceItemURL = priceItemUse.priceItem;
      if (filterOutPriceItems && filterOutPriceItems.has(priceItemURL)) {
        return;
      }
      const priceItem = priceItemLookup(priceItemURL);
      if (
        !priceItem ||
        !priceItemIsVisible(priceItem, false, priceItemUseList, unitLookup, priceItemLookup)
      ) {
        return;
      }
      let unitName;
      let unitSymbol;
      const conversionUnitURL = priceItem.conversionUnit;
      const {conversionFactor} = priceItem;
      const usesConversion = !!conversionUnitURL && conversionFactor;
      const conversionUnit = conversionUnitURL ? unitLookup(conversionUnitURL) : undefined;
      if (conversionUnit && conversionFactor) {
        unitName = conversionUnit.name || "";
        unitSymbol = formatUnitString(conversionUnit);
      } else {
        const unit = priceItem.relatedUnit ? unitLookup(priceItem.relatedUnit) : undefined;
        unitName = unit?.name || "";
        unitSymbol = formatUnitString(unit);
      }
      let baseUnit;
      let baseValue;
      if (outputToInputConversionMapping[unitName]) {
        if (this.props.baseValue !== undefined && this.props.baseUnit !== undefined) {
          ({baseUnit, baseValue} = this.props);
        } else {
          const baseUnitArray = outputToInputConversionMapping[unitName];
          for (let i = 0; i < baseUnitArray.length; i += 1) {
            const baseUnitCandidate = baseUnitArray[i];
            // find entry with base unit
            const basePriceItemUse = priceItemUseList.find((basePriceItemUseCandidate) => {
              const basePriceItemCandidateURL = basePriceItemUseCandidate.priceItem;
              const basePriceItemCandidate = priceItemLookup(basePriceItemCandidateURL);
              return (
                basePriceItemCandidate &&
                getUnitCode(basePriceItemCandidate, unitLookup) === baseUnitCandidate &&
                priceItemIsVisible(
                  basePriceItemCandidate,
                  false,
                  priceItemUseList,
                  unitLookup,
                  priceItemLookup,
                )
              );
            });
            if (basePriceItemUse) {
              baseUnit = baseUnitCandidate;
              baseValue = basePriceItemUse.count || 0;
              break;
            }
          }
        }
        if (baseValue !== undefined && baseUnit) {
          showConversionColumns = true;
        }
      }
      sortedItems.push({
        baseUnit,
        baseValue,
        conversionFactor: usesConversion ? conversionFactor : null,
        index,
        priceItem,
        priceItemUse,
        unit: unitSymbol,
      });
    });
    // sortedItems.sort(compareEntries);
    const priceitemRows = sortedItems
      .map(({baseUnit, baseValue, conversionFactor, index, priceItem, priceItemUse, unit}) => {
        // index is the index in task.priceitemuseSet, not in the
        // sorted list; in order to give the right parameter to
        // onPriceItemUseCountChange
        const {count} = priceItemUse;
        const {notes} = priceItemUse;
        const value =
          count != null
            ? _.round(
                conversionFactor && count ? count / conversionFactor : count,
                customerSettings.materialDecimals,
              )
            : count;
        const {name} = priceItem;

        return (
          <LegacyPriceItemEntry
            key={index}
            baseUnit={baseUnit}
            baseValue={baseValue}
            booleanSelection={!!priceItem.booleanSelection}
            customerSettings={customerSettings}
            dangling={priceItemUse.dangling || !priceItem.active}
            disabled={
              !!(
                (this.props.readonlyItems &&
                  this.props.readonlyItems.has(priceItemUse.priceItem)) ||
                readonly
              )
            }
            hasConversions={showConversionColumns}
            name={name}
            notes={notes}
            priceItemIndex={index}
            showNotes={showNotes}
            unit={unit}
            value={value != null ? value : undefined}
            onChange={this.handlePriceItemUseCountChange}
            onNotesChange={onNotesChange}
            onPriceItemUseDetailButtonClick={this.props.onPriceItemUseDetailButtonClick}
          />
        );
      })
      .filter(_.identity);
    if (MOBILE) {
      if (!priceitemRows.length) {
        return null;
      } else {
        return (
          <Grid>
            <Cell size="12/12">{priceitemRows}</Cell>
          </Grid>
        );
      }
    } else {
      let conversionColumns;
      if (showConversionColumns) {
        conversionColumns = [];
        conversionColumns.push(
          <TableCell key="conversion-count" style={{width: COUNT_COLUMN_WIDTH}}>
            Antal
          </TableCell>,
        );
        conversionColumns.push(
          <TableCell key="conversion-unit" style={{width: CONVERSION_UNIT_COLUMN_WIDTH}}>
            Enhed
          </TableCell>,
        );
      }
      const plusColumn = <TableCell style={{paddingLeft: 0, paddingRight: 0, width: 48}} />;
      if (!priceitemRows.length) {
        return null;
      } else {
        return (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Tekst</TableCell>
                <TableCell style={{width: COUNT_COLUMN_WIDTH}}>Antal</TableCell>
                {plusColumn}
                <TableCell style={{width: UNIT_COLUMN_WIDTH}}>Enhed</TableCell>
                {conversionColumns}
                {showNotes ? (
                  <TableCell>
                    <FormattedMessage defaultMessage="Noter" id="price-item-table.label.notes" />
                  </TableCell>
                ) : null}
              </TableRow>
            </TableHead>
            <TableBody>{priceitemRows}</TableBody>
          </Table>
        );
      }
    }
  }
}

const ConnectedLegacyPriceItemTable: React.ComponentType<LegacyPriceItemTableOwnProps> = connect<
  LegacyPriceItemTableStateProps,
  object,
  LegacyPriceItemTableOwnProps,
  AppState
>(
  createStructuredSelector<AppState, LegacyPriceItemTableStateProps>({
    priceItemLookup: getPriceItemLookup,
    unitLookup: getUnitLookup,
  }),
  {},
)(LegacyPriceItemTable);

export {ConnectedLegacyPriceItemTable as LegacyPriceItemTable};
