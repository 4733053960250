import {PixelCrop} from "react-image-crop";

export function canvasPreview(
  image: HTMLImageElement,
  canvas: HTMLCanvasElement,
  crop?: PixelCrop | undefined,
  scale = 1,
): void {
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("No 2d context");
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  // devicePixelRatio slightly increases sharpness on retina devices
  // at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be
  // true to the images natural size.
  const pixelRatio = window.devicePixelRatio;

  const cropWidth = crop ? crop.width : image.width;
  const cropHeight = crop ? crop.height : image.height;

  canvas.width = Math.floor(cropWidth * scaleX * pixelRatio);
  canvas.height = Math.floor(cropHeight * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = "high";

  const cropX = crop ? crop.x * scaleX : 0;
  const cropY = crop ? crop.y * scaleY : 0;

  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  // Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY);
  // Move the origin to the center of the original position
  ctx.translate(centerX, centerY);
  // Scale the image
  ctx.scale(scale, scale);
  // Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
  );

  ctx.restore();
}
