import {Journal, LocationUrl} from "@co-common-libs/resources";
import {useWindowSize} from "@co-frontend-libs/utils";
import {Theme, useTheme} from "@material-ui/core";
import {FieldEntry} from "app-utils";
import React, {useCallback, useEffect, useMemo, useRef} from "react";
import {
  ListChildComponentProps,
  ListOnScrollProps,
  VariableSizeList,
  VariableSizeListProps,
} from "react-window";
import {DisplayAllFields} from "../display-all-fields-item";
import {AllItem} from "./all-item";
import {FieldMultiSelectionListItem} from "./field-multi-selection-list-item";

type SpecialEntry = "-ALL-" | "-DISPLAY-ALL-FIELDS-";

const listItemTopPadding = 8;
const listItemBottomPadding = 8;
const listItemTextTopMargin = 6;
const listItemTextBottomMargin = 6;
const checkboxHeight = 42;

const minHeight = listItemTopPadding + listItemBottomPadding + checkboxHeight;

const itemTotalMarginPadding =
  listItemTopPadding + listItemBottomPadding + listItemTextTopMargin + listItemTextBottomMargin;

const primaryLineHeight = 24; // uses theme.typography.body1
const secondaryLineHeight = 20; // uses theme.typography.body2

interface RenderRowData extends ListChildComponentProps {
  data: {
    readonly allSelected: boolean;
    readonly combinedEntries: readonly (FieldEntry | SpecialEntry)[];
    readonly displayAllFields: boolean;
    readonly entries: readonly FieldEntry[];
    readonly isSearchResult: boolean;
    readonly lastUsedLocations?: ReadonlySet<LocationUrl> | undefined;
    readonly locationJournalMap: ReadonlyMap<LocationUrl, readonly Journal[]>;
    readonly multiSelectMax: number | undefined;
    readonly onDisplayAllFieldsChange: ((displayAll: boolean) => void) | undefined;
    readonly onSelect: (identifier: LocationUrl, selected: boolean) => void;
    readonly onSelectMultiple: (identifiers: ReadonlySet<LocationUrl>, selected: boolean) => void;
    readonly readonlySet?: ReadonlySet<LocationUrl> | undefined;
    readonly selected: ReadonlySet<LocationUrl>;
    readonly theme: Theme;
    readonly withIcons: boolean;
  };
}

function renderRow(rowProps: RenderRowData): JSX.Element {
  const {data, index, style} = rowProps;
  const {
    allSelected,
    combinedEntries,
    displayAllFields,
    entries,
    isSearchResult,
    lastUsedLocations,
    locationJournalMap,
    multiSelectMax,
    onDisplayAllFieldsChange,
    onSelect,
    onSelectMultiple,
    readonlySet,
    selected,
    theme,
    withIcons,
  } = data;
  const entry = combinedEntries[index];
  if (entry === "-ALL-") {
    return (
      <AllItem
        allSelected={allSelected}
        entries={entries}
        isSearchResult={isSearchResult}
        multiSelectMax={multiSelectMax}
        style={{
          borderBottom: `1px solid ${theme.palette.divider}`,
          ...style,
        }}
        onSelectMultiple={onSelectMultiple}
      />
    );
  } else if (entry === "-DISPLAY-ALL-FIELDS-") {
    return (
      <DisplayAllFields
        displayAllFields={displayAllFields}
        style={{
          borderBottom: `1px solid ${theme.palette.divider}`,
          ...style,
        }}
        onDisplayAllFieldsChange={
          // -DISPLAY-ALL-FIELDS- only present with onDisplayAllFieldsChange
          onDisplayAllFieldsChange as (displayAll: boolean) => void
        }
      />
    );
  } else {
    const {field, matches} = entry;
    const {url} = field;
    return (
      <FieldMultiSelectionListItem
        key={url}
        currentlySelected={selected.has(url)}
        disabled={readonlySet?.has(url)}
        field={field}
        journalEntries={locationJournalMap.get(url)}
        matches={matches}
        recentlyUsed={lastUsedLocations?.has(url)}
        style={style}
        value={url}
        withIcons={withIcons}
        onSelect={onSelect}
      />
    );
  }
}

interface FieldMultiSelectionListProps {
  displayAllFields: boolean;
  entries: readonly FieldEntry[];
  fullscreenLayout?: boolean;
  isSearchResult: boolean;
  lastUsedLocations?: ReadonlySet<LocationUrl> | undefined;
  locationJournalMap: Map<LocationUrl, Journal[]>;
  multiSelectMax: number | undefined;
  onDisplayAllFieldsChange: ((displayAll: boolean) => void) | undefined;
  onScroll?: (props: ListOnScrollProps) => void;
  onSelect: (identifier: LocationUrl, selected: boolean) => void;
  onSelectMultiple: (identifiers: ReadonlySet<LocationUrl>, selected: boolean) => void;
  readonlySet?: ReadonlySet<LocationUrl> | undefined;
  selected: ReadonlySet<LocationUrl>;
  withIcons: boolean;
}

export const FieldMultiSelectionList = React.memo(function FieldMultiSelectionList(
  props: FieldMultiSelectionListProps,
) {
  const {
    displayAllFields,
    entries,
    fullscreenLayout,
    isSearchResult,
    lastUsedLocations,
    locationJournalMap,
    multiSelectMax,
    onDisplayAllFieldsChange,
    onScroll,
    onSelect,
    onSelectMultiple,
    readonlySet,
    selected,
    withIcons,
  } = props;

  const theme = useTheme();

  const variableSizeListRef = useRef<VariableSizeList | null>(null);

  useEffect(() => {
    if (variableSizeListRef.current) {
      variableSizeListRef.current.resetAfterIndex(0);
    }
  }, [isSearchResult]);

  const combinedEntries: readonly (FieldEntry | SpecialEntry)[] = useMemo(() => {
    const result: (FieldEntry | SpecialEntry)[] = entries.slice();
    result.unshift("-ALL-");
    if (onDisplayAllFieldsChange) {
      result.unshift("-DISPLAY-ALL-FIELDS-");
    }
    return result;
  }, [entries, onDisplayAllFieldsChange]);

  const getItemSize = useCallback(
    (index: number): number => {
      const entry = combinedEntries[index];
      if (entry === "-ALL-" || entry === "-DISPLAY-ALL-FIELDS-") {
        const dividerBorder = 1;
        return Math.max(itemTotalMarginPadding + primaryLineHeight + dividerBorder, minHeight);
      } else if (entry.matches) {
        return Math.max(
          itemTotalMarginPadding + primaryLineHeight + secondaryLineHeight,
          minHeight,
        );
      } else {
        return Math.max(itemTotalMarginPadding + primaryLineHeight, minHeight);
      }
    },
    [combinedEntries],
  );

  const getItemKey = useCallback(
    (index: number): string => {
      const entry = combinedEntries[index];
      if (entry === "-ALL-" || entry === "-DISPLAY-ALL-FIELDS-") {
        return entry;
      } else {
        return entry.field.url;
      }
    },
    [combinedEntries],
  );

  const allSelected = useMemo(
    () => entries.every((entry) => selected.has(entry.field.url)),
    [entries, selected],
  );

  let listWidth = 0;
  let listHeight = 0;

  const {windowInnerHeight, windowInnerWidth} = useWindowSize();

  if (fullscreenLayout) {
    listWidth = windowInnerWidth;
    const headerHeight = 56;
    listHeight = windowInnerHeight - headerHeight;
  } else {
    const dialogMargin = 32;
    const dialogMaxWidth = 960;
    listWidth = Math.min(dialogMaxWidth, windowInnerWidth - (dialogMargin + dialogMargin));
    const dialogMaxHeight = windowInnerHeight - (dialogMargin + dialogMargin);
    const headerHeight = 116;
    const footerHeight = 52;
    const dividerLineHeight = 1;
    const listMaxHeight =
      dialogMaxHeight - (headerHeight + footerHeight + dividerLineHeight + dividerLineHeight);
    for (let i = 0; i < combinedEntries.length && listHeight < listMaxHeight; i += 1) {
      listHeight += getItemSize(i);
    }
    listHeight = Math.min(listHeight, listMaxHeight);
  }

  const itemData: RenderRowData["data"] = {
    allSelected,
    combinedEntries,
    displayAllFields,
    entries,
    isSearchResult,
    lastUsedLocations,
    locationJournalMap,
    multiSelectMax,
    onDisplayAllFieldsChange,
    onSelect,
    onSelectMultiple,
    readonlySet,
    selected,
    theme,
    withIcons,
  };

  const optionalProps: Partial<VariableSizeListProps> = {};
  if (onScroll) {
    optionalProps.onScroll = onScroll;
  }

  return (
    <div>
      <VariableSizeList
        ref={variableSizeListRef}
        estimatedItemSize={Math.max(itemTotalMarginPadding + primaryLineHeight, minHeight)}
        height={listHeight}
        itemCount={combinedEntries.length}
        itemData={itemData}
        itemKey={getItemKey}
        itemSize={getItemSize}
        width={listWidth}
        {...optionalProps}
      >
        {renderRow}
      </VariableSizeList>
    </div>
  );
});
