/// <reference types="cordova-sqlite-storage" />
import {ResourceInstance, ResourceName} from "@co-common-libs/resources";
import _ from "lodash";
import {Query, QueryIDStruct, QueryParams, QueryTimestampsStruct, wrapSQLError} from "../types";
import {ResourceDBConnection} from "./types";

const EMPTY_VERSION = 0;
const DATA_QUERY_QUERYDATA_VERSION = 1;
const DATA_QUERY_QUERYDATA_QUERYTIMESTAMP_VERSION = 2;
const DATA_QUERY_QUERYTIMESTAMP_VERSION = 3;
const DATA_QUERY_QUERYTIMESTAMP_RELATED_FETCH_VERSION = 4;

const CURRENT_VERSION = DATA_QUERY_QUERYTIMESTAMP_RELATED_FETCH_VERSION;

const CHANGES_KEY = "CHANGES";

const getVersion = (db: SQLitePlugin.Database): Promise<number> =>
  new Promise(
    (resolve: SQLitePlugin.StatementSuccessCallback, reject: SQLitePlugin.ErrorCallback) => {
      db.executeSql("SELECT version FROM __sys__", [], resolve, reject);
    },
  )
    .then((result) => {
      const {rows} = result;
      if (rows.length > 0) {
        return rows.item(0).version;
      }
      return new Promise(
        (resolve: SQLitePlugin.StatementSuccessCallback, reject: SQLitePlugin.ErrorCallback) => {
          // not present; insert version 0 so that upgrade always sets version with UPDATE
          db.executeSql("INSERT INTO __sys__ (version) VALUES (0)", [], resolve, reject);
        },
        // eslint-disable-next-line promise/no-nesting
      ).then(() => 0);
    })
    .catch(wrapSQLError("getVersion"));

const upgrade = (db: SQLitePlugin.Database, oldVersion: number): Promise<SQLitePlugin.Database> => {
  if (oldVersion === CURRENT_VERSION) {
    return Promise.resolve(db);
  }
  const sqlStatements: (string | [string, any[]])[] = [];
  if (oldVersion <= EMPTY_VERSION) {
    sqlStatements.push(
      "CREATE TABLE IF NOT EXISTS data (url BLOB PRIMARY KEY, value BLOB)",
      "CREATE TABLE IF NOT EXISTS query (" +
        "id INTEGER PRIMARY KEY ASC, " +
        "value BLOB, " +
        "last_full_fetch_timestamp BLOB)",
    );
  }
  if (oldVersion <= DATA_QUERY_QUERYDATA_VERSION) {
    sqlStatements.push(
      "CREATE TABLE IF NOT EXISTS query_timestamp (" +
        "query_id INTEGER UNIQUE REFERENCES query (id) ON DELETE CASCADE, " +
        "full_timestamp BLOB, " +
        "update_timestamp BLOB)",
    );
  }
  if (
    oldVersion >= DATA_QUERY_QUERYDATA_VERSION &&
    oldVersion <= DATA_QUERY_QUERYDATA_QUERYTIMESTAMP_VERSION
  ) {
    sqlStatements.push("DROP TABLE query_data");
  }
  if (oldVersion <= DATA_QUERY_QUERYTIMESTAMP_VERSION) {
    sqlStatements.push(
      "CREATE TABLE IF NOT EXISTS fetch_by_id (resource_name BLOB, id BLOB)",
      "CREATE TABLE IF NOT EXISTS fetch_by_related (resource_name BLOB, member_name BLOB, id BLOB)",
      "CREATE TABLE IF NOT EXISTS changes_timestamp(key BLOB PRIMARY KEY, timestamp BLOB)",
    );
  }
  sqlStatements.push(["UPDATE __sys__ SET version = ?", [CURRENT_VERSION]]);
  return new Promise<void>(
    (resolve: SQLitePlugin.SuccessCallback, reject: SQLitePlugin.ErrorCallback) => {
      db.sqlBatch(sqlStatements, resolve, reject);
    },
  ).then(() => db);
};

const baseOpenConnection = (
  sqlitePlugin: SQLitePlugin.SQLite,
  dbName: string,
): Promise<SQLitePlugin.Database> => {
  return new Promise(
    (resolve: SQLitePlugin.DatabaseSuccessCallback, reject: SQLitePlugin.ErrorCallback) => {
      sqlitePlugin.openDatabase({location: "default", name: dbName}, resolve, reject);
    },
  ).catch(() => {
    return new Promise(
      (resolve: SQLitePlugin.DatabaseSuccessCallback, reject: SQLitePlugin.ErrorCallback) => {
        const RETRY_AFTER_MILLISECONDS = 2;
        setTimeout(() => {
          sqlitePlugin.openDatabase({location: "default", name: dbName}, resolve, reject);
        }, RETRY_AFTER_MILLISECONDS);
        sqlitePlugin.openDatabase({location: "default", name: dbName}, resolve, reject);
      },
    );
  });
};

const openConnection = async (
  sqlitePlugin: SQLitePlugin.SQLite,
  dbName: string,
): Promise<SQLitePlugin.Database> => {
  const db = await baseOpenConnection(sqlitePlugin, dbName);
  return new Promise(
    (resolve: SQLitePlugin.StatementSuccessCallback, reject: SQLitePlugin.ErrorCallback) => {
      db.executeSql("CREATE TABLE IF NOT EXISTS __sys__ (version INTEGER)", [], resolve, reject);
    },
  )
    .then(() => getVersion(db))
    .then((version) => {
      if (version < CURRENT_VERSION) {
        return upgrade(db, version);
      } else {
        return db;
      }
    })
    .catch(wrapSQLError("openConnection"));
};

class CordovaSQLiteStorageResourceDBConnection extends ResourceDBConnection {
  private connection: SQLitePlugin.Database;
  private sqlitePlugin: SQLitePlugin.SQLite;
  private dbName: string;
  constructor(
    connection: SQLitePlugin.Database,
    sqlitePlugin: SQLitePlugin.SQLite,
    dbName: string,
  ) {
    super();
    this.connection = connection;
    this.sqlitePlugin = sqlitePlugin;
    this.dbName = dbName;
  }
  private query(sql: string, params: any[] = []): Promise<SQLitePlugin.Results> {
    return new Promise(
      (resolve: SQLitePlugin.StatementSuccessCallback, reject: SQLitePlugin.ErrorCallback) => {
        this.connection.executeSql(sql, params, resolve, reject);
      },
    );
  }
  private batch(sql: (string | [string, any[]])[]): Promise<void> {
    return new Promise(
      (resolve: SQLitePlugin.SuccessCallback, reject: SQLitePlugin.ErrorCallback) => {
        this.connection.sqlBatch(sql, resolve, reject);
      },
    );
  }
  readQueries(): Promise<{[queryID: number]: QueryParams}> {
    return this.query("SELECT id, value FROM query", []).then(({rows}) => {
      const result: {[queryID: number]: QueryParams} = {};
      for (let i = 0; i < rows.length; i += 1) {
        const row = rows.item(i);
        const entry = JSON.parse(row.value) as QueryParams;
        const {id} = row;
        if (typeof id === "number") {
          result[id] = entry;
        }
      }
      return result;
    });
  }
  readData(): Promise<ResourceInstance[]> {
    const CHUNK_SIZE = 500;
    const result: ResourceInstance[] = [];
    const readMore = (): Promise<null> =>
      this.query(
        [
          "SELECT value FROM data",
          "ORDER BY rowid",
          "LIMIT",
          CHUNK_SIZE,
          "OFFSET",
          result.length,
        ].join(" "),
        [],
      ).then(({rows}) => {
        for (let i = 0; i < rows.length; i += 1) {
          const row = rows.item(i);
          const entry = JSON.parse(row.value) as ResourceInstance;
          result.push(entry);
        }
        if (rows.length === CHUNK_SIZE) {
          return readMore();
        } else {
          return null;
        }
      });
    return readMore().then(() => result);
  }
  addQueries(
    queries: ReadonlySet<Query>,
    queryIdAssociation: ReadonlyMap<string, QueryIDStruct>,
  ): Promise<Map<string, QueryIDStruct>> {
    let promise = Promise.resolve();
    const result = new Map(queryIdAssociation);
    queries.forEach((query: Query) => {
      const value = JSON.stringify(query);
      promise = promise
        .then(() => this.query("INSERT INTO query (value) VALUES (?)", [value]))
        .then(({insertId}) => {
          const id = insertId as number;
          console.assert(
            !result.has(query.keyString),
            `already has query with same key: ${query.keyString}`,
          );
          result.set(query.keyString, {id, query});
          return;
        });
    });
    return promise.then(() => result);
  }
  deleteQueries(
    queries: ReadonlySet<Query>,
    queryIdAssociation: ReadonlyMap<string, QueryIDStruct>,
  ): Promise<Map<string, QueryIDStruct>> {
    const result = new Map(queryIdAssociation);

    const sql: [string, [number]][] = [];
    queries.forEach((query: Query) => {
      const entry = result.get(query.keyString);
      if (!entry) {
        throw new Error(`unknown Query for deletion: ${query.queryString}`);
      }
      const {id} = entry;
      sql.push(["DELETE FROM query WHERE id = ?", [id]]);
      result.delete(query.keyString);
    });
    return this.batch(sql).then(() => result);
  }
  mergeData(instances: ReadonlyMap<string, ResourceInstance>): Promise<void> {
    let promise: Promise<any> = Promise.resolve();
    const baseSQL = "INSERT OR REPLACE INTO data (url, value) VALUES ";
    const paramsArray: [string, string][] = [];
    instances.forEach((instance: ResourceInstance) => {
      const value = JSON.stringify(instance);
      const {url} = instance;
      paramsArray.push([url, value]);
    });
    const CHUNK_SIZE = 100;
    _.chunk(paramsArray, CHUNK_SIZE).forEach((subArray) => {
      const n = subArray.length;
      const sql = `${baseSQL + _.repeat("(?, ?), ", n - 1)}(?, ?)`;
      promise = promise.then(() => this.query(sql, _.flatten(subArray)));
    });
    return promise;
  }
  deleteData(urls: ReadonlySet<string>): Promise<void> {
    let promise: Promise<any> = Promise.resolve();
    if (urls.size === 0) {
      return promise;
    }
    const urlArray = Array.from(urls);
    if (urlArray.length === 1) {
      const deleteSQL = "DELETE FROM data WHERE url = ?";
      const url = urlArray[0];
      promise = promise.then(() => this.query(deleteSQL, [url]));
    } else {
      console.assert(urlArray.length > 1);
      const baseSQL = "DELETE FROM data WHERE url IN ";
      const CHUNK_SIZE = 500;
      _.chunk(urlArray, CHUNK_SIZE).forEach((subArray) => {
        const n = subArray.length;
        const sql = `${baseSQL}(${_.repeat("?, ", n - 1)}?)`;
        promise = promise.then(() => this.query(sql, subArray));
      });
    }
    return promise;
  }
  vacuum(): Promise<void> {
    return this.query("VACUUM", []).then(() => undefined);
  }
  setQueryTimestamps(
    queriesFullFetchTimestamps: readonly QueryTimestampsStruct[],
    queryIdAssociation: ReadonlyMap<string, QueryIDStruct>,
  ): Promise<void> {
    let promise: Promise<any> = Promise.resolve();
    const baseSQL =
      "INSERT OR REPLACE INTO query_timestamp (query_id, full_timestamp, update_timestamp) VALUES ";
    const paramsArray: [number, string | null, string | null][] = [];
    queriesFullFetchTimestamps.forEach(({fullTimestamp, query, updateTimestamp}) => {
      const entry = queryIdAssociation.get(query.keyString);
      if (entry) {
        paramsArray.push([entry.id, fullTimestamp, updateTimestamp]);
      }
    });
    const CHUNK_SIZE = 100;
    _.chunk(paramsArray, CHUNK_SIZE).forEach((subArray) => {
      const n = subArray.length;
      const sql = `${baseSQL + _.repeat("(?, ?, ?), ", n - 1)}(?, ?, ?)`;
      promise = promise.then(() => this.query(sql, _.flatten(subArray)));
    });
    return promise;
  }
  readQueryTimestamps(): Promise<{
    [queryID: number]: {fullTimestamp: string; updateTimestamp: string};
  }> {
    return this.query(
      "SELECT query_id, full_timestamp, update_timestamp FROM query_timestamp",
      [],
    ).then(({rows}) => {
      const result: {
        [queryID: number]: {fullTimestamp: string; updateTimestamp: string};
      } = {};
      for (let i = 0; i < rows.length; i += 1) {
        const row = rows.item(i);
        const id = row.query_id;
        const fullTimestamp = row.full_timestamp;
        const updateTimestamp = row.update_timestamp;
        result[id] = {fullTimestamp, updateTimestamp};
      }
      return result;
    });
  }
  mergeIdFetch(ids: ReadonlyMap<ResourceName, ReadonlySet<string>>): Promise<void> {
    let promise: Promise<any> = Promise.resolve();
    const baseSQL = "INSERT OR REPLACE INTO fetch_by_id (resource_name, id) VALUES ";
    const paramsArray: [string, string][] = [];
    ids.forEach((resourceIds, resourceName) => {
      resourceIds.forEach((id) => {
        paramsArray.push([resourceName, id]);
      });
    });
    const CHUNK_SIZE = 100;
    _.chunk(paramsArray, CHUNK_SIZE).forEach((subArray) => {
      const n = subArray.length;
      const sql = `${baseSQL + _.repeat("(?, ?), ", n - 1)}(?, ?)`;
      promise = promise.then(() => this.query(sql, _.flatten(subArray)));
    });
    return promise;
  }
  deleteIdFetch(ids: ReadonlyMap<ResourceName, ReadonlySet<string>>): Promise<void> {
    let promise: Promise<any> = Promise.resolve();
    if (ids.size === 0) {
      return promise;
    }
    ids.forEach((resourceIdSet, resourceName) => {
      if (resourceIdSet.size === 0) {
        return;
      }
      const resourceIds = Array.from(resourceIdSet);
      if (resourceIds.length === 1) {
        const deleteSQL = "DELETE FROM fetch_by_id WHERE resource_name = ? AND id = ?";
        const id = resourceIds[0];
        promise = promise.then(() => this.query(deleteSQL, [resourceName, id]));
      } else {
        console.assert(resourceIds.length > 1);
        const baseSQL = "DELETE FROM fetch_by_id WHERE resource_name = ? AND id IN ";
        const CHUNK_SIZE = 100;
        _.chunk(resourceIds, CHUNK_SIZE).forEach((subArray) => {
          const n = subArray.length;
          const sql = `${baseSQL}(${_.repeat("?, ", n - 1)}?)`;
          promise = promise.then(() => this.query(sql, [resourceName, ...subArray]));
        });
      }
    });
    return promise;
  }
  readIdFetch(): Promise<ReadonlyMap<ResourceName, ReadonlySet<string>>> {
    return this.query("SELECT resource_name, id FROM fetch_by_id", []).then(({rows}) => {
      const result = new Map<ResourceName, Set<string>>();
      for (let i = 0; i < rows.length; i += 1) {
        const row = rows.item(i);
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const {id, resource_name: resourceName} = row as {
          id: string;
          // eslint-disable-next-line @typescript-eslint/naming-convention
          resource_name: ResourceName;
        };
        const existing = result.get(resourceName);
        if (existing) {
          existing.add(id);
        } else {
          result.set(resourceName, new Set([id]));
        }
      }
      return result;
    });
  }
  mergeRelatedFetch(
    values: ReadonlyMap<ResourceName, ReadonlyMap<string, ReadonlySet<string>>>,
  ): Promise<void> {
    let promise: Promise<any> = Promise.resolve();
    const baseSQL =
      "INSERT OR REPLACE INTO fetch_by_related (resource_name, member_name, id) VALUES ";
    const paramsArray: [string, string, string][] = [];
    values.forEach((resourceRelations, resourceName) => {
      resourceRelations.forEach((ids, memberName) => {
        ids.forEach((id) => {
          paramsArray.push([resourceName, memberName, id]);
        });
      });
    });
    const CHUNK_SIZE = 100;
    _.chunk(paramsArray, CHUNK_SIZE).forEach((subArray) => {
      const n = subArray.length;
      const sql = `${baseSQL + _.repeat("(?, ?, ?), ", n - 1)}(?, ?, ?)`;
      promise = promise.then(() => this.query(sql, _.flatten(subArray)));
    });
    return promise;
  }
  deleteRelatedFetch(
    values: ReadonlyMap<ResourceName, ReadonlyMap<string, ReadonlySet<string>>>,
  ): Promise<void> {
    let promise: Promise<any> = Promise.resolve();
    if (values.size === 0) {
      return promise;
    }
    values.forEach((resourceRelations, resourceName) => {
      if (!resourceRelations.size) {
        return;
      }
      resourceRelations.forEach((idSet, memberName) => {
        if (!idSet.size) {
          return;
        }
        const ids = Array.from(idSet);
        if (ids.length === 1) {
          const deleteSQL =
            "DELETE FROM fetch_by_related WHERE resource_name = ? AND member_name = ? AND id = ?";
          const id = ids[0];
          promise = promise.then(() => this.query(deleteSQL, [resourceName, memberName, id]));
        } else {
          console.assert(ids.length > 1);
          const baseSQL =
            "DELETE FROM fetch_by_related WHERE resource_name = ? AND member_name = ? AND id IN ";
          const CHUNK_SIZE = 100;
          _.chunk(ids, CHUNK_SIZE).forEach((subArray) => {
            const n = subArray.length;
            const sql = `${baseSQL}(${_.repeat("?, ", n - 1)}?)`;
            promise = promise.then(() => this.query(sql, [resourceName, memberName, ...subArray]));
          });
        }
      });
    });
    return promise;
  }
  readRelatedFetch(): Promise<ReadonlyMap<ResourceName, ReadonlyMap<string, ReadonlySet<string>>>> {
    return this.query("SELECT resource_name, member_name, id FROM fetch_by_related", []).then(
      ({rows}) => {
        const result = new Map<ResourceName, Map<string, Set<string>>>();
        for (let i = 0; i < rows.length; i += 1) {
          const row = rows.item(i);
          const {
            id,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            member_name: memberName,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            resource_name: resourceName,
          } = row as {
            id: string;
            // eslint-disable-next-line @typescript-eslint/naming-convention
            member_name: string;
            // eslint-disable-next-line @typescript-eslint/naming-convention
            resource_name: ResourceName;
          };
          const existingForResource = result.get(resourceName);
          if (existingForResource) {
            const existingForResourceMember = existingForResource.get(memberName);
            if (existingForResourceMember) {
              existingForResourceMember.add(id);
            } else {
              existingForResource.set(memberName, new Set([id]));
            }
          } else {
            result.set(resourceName, new Map([[memberName, new Set([id])]]));
          }
        }
        return result;
      },
    );
  }
  getChangesTimestamp(): Promise<string | null> {
    return this.query("SELECT key, timestamp from changes_timestamp", []).then(({rows}) => {
      console.assert(rows.length <= 1);
      if (rows.length) {
        const row = rows.item(0);
        const {key, timestamp} = row as {key: string; timestamp: string};
        console.assert(key === CHANGES_KEY);
        return timestamp;
      } else {
        return null;
      }
    });
  }
  setChangesTimestamp(timestamp: string): Promise<void> {
    const sql = "INSERT OR REPLACE INTO changes_timestamp (key, timestamp) VALUES (?, ?)";
    return this.query(sql, [CHANGES_KEY, timestamp]).then(() => {
      return;
    });
  }
  close(): Promise<void> {
    return new Promise<void>(
      (resolve: SQLitePlugin.SuccessCallback, reject: SQLitePlugin.ErrorCallback) => {
        this.connection.close(resolve, reject);
      },
    ).catch(() => {
      return new Promise<void>(
        (resolve: SQLitePlugin.SuccessCallback, reject: SQLitePlugin.ErrorCallback) => {
          const RETRY_AFTER_MILLISECONDS = 2;
          setTimeout(() => {
            this.connection.close(resolve, reject);
          }, RETRY_AFTER_MILLISECONDS);
        },
      );
    });
  }
  deleteDatabase(): Promise<void> {
    return new Promise(
      (resolve: SQLitePlugin.SuccessCallback, reject: SQLitePlugin.ErrorCallback) => {
        return this.sqlitePlugin.deleteDatabase(
          {location: "default", name: this.dbName},
          resolve,
          reject,
        );
      },
    );
  }
}

export const getCordovaSQLiteStorageConnection = (
  sqlitePlugin: SQLitePlugin.SQLite,
  dbName: string,
): Promise<ResourceDBConnection> =>
  openConnection(sqlitePlugin, dbName).then(
    (connection) => new CordovaSQLiteStorageResourceDBConnection(connection, sqlitePlugin, dbName),
  );
