import {
  Contact,
  ContactUrl,
  Customer,
  CustomerUrl,
  Delivery,
  DeliveryLocation,
  Machine,
  MachineUrl,
  Order,
  OrderUrl,
  Pickup,
  PickupLocation,
  PriceGroup,
  PriceGroupUrl,
  PriceItem,
  PriceItemUrl,
  PriceItemUse,
  Product,
  ProductUrl,
  Project,
  ProjectUrl,
  ReportingSpecification,
  ReportingSpecificationUrl,
  Task,
  Timer,
  TimerUrl,
  TransportLog,
  WorkType,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {getNormalisedDeviceTimestamp} from "@co-common-libs/resources-utils";
import {setMap} from "@co-common-libs/utils";
import _ from "lodash";
import {mergeIntervals} from "./task-timers";

export type InlinedTransportLog = TransportLog & {
  _deliveryList: Delivery[];
  _deliveryLocationSet: Set<DeliveryLocation>;
  _pickupList: Pickup[];
  _pickupLocationSet: Set<PickupLocation>;
};

export const inlineTransportLogData = (
  transportLog: TransportLog,
  data: {
    deliveryArray: readonly Delivery[];
    deliveryLocationArray: readonly DeliveryLocation[];
    pickupArray: readonly Pickup[];
    pickupLocationArray: readonly PickupLocation[];
  },
): InlinedTransportLog => {
  const {deliveryArray, deliveryLocationArray, pickupArray, pickupLocationArray} = data;
  const transportLogURL = transportLog.url;
  const pickupLocationSet = new Set(
    pickupLocationArray.filter((p) => p.transportlog === transportLogURL),
  );
  const pickupLocationURLSet = setMap(pickupLocationSet, (p) => p.url);
  const deliveryLocationSet = new Set(
    deliveryLocationArray.filter((d) => d.transportlog === transportLogURL),
  );
  const deliveryLocationURLSet = setMap(deliveryLocationSet, (d) => d.url);
  const pickupList = _.sortBy(
    pickupArray.filter((p) => pickupLocationURLSet.has(p.location)),
    getNormalisedDeviceTimestamp,
  ) as Pickup[];
  const deliveryList = _.sortBy(
    deliveryArray.filter((d) => deliveryLocationURLSet.has(d.location)),
    getNormalisedDeviceTimestamp,
  ) as Delivery[];

  const result: InlinedTransportLog = {
    ...transportLog,
    _deliveryList: deliveryList,
    _deliveryLocationSet: deliveryLocationSet,
    _pickupList: pickupList,
    _pickupLocationSet: pickupLocationSet,
  };
  return result;
};

export type InlinedOrder = Omit<Order, "contact" | "customer"> & {
  contact: Contact | null;
  customer: Customer | null;
};

export type InlinedPriceItemUse = Omit<PriceItemUse, "priceItem"> & {
  priceItem: PriceItem | null | undefined;
};

export type InlinedTask = Omit<
  Task,
  | "computedTimeSet"
  | "machineOperatorTimeCorrectionSet"
  | "machineuseSet"
  | "managerTimeCorrectionSet"
  | "order"
  | "priceGroup"
  | "priceitemuseSet"
  | "productuseSet"
  | "project"
  | "reportingSpecification"
  | "workType"
> & {
  _intervals: readonly {
    readonly fromTimestamp: string;
    readonly timer: Timer | null;
    readonly toTimestamp: string;
  }[];
  computedTimeSet: {
    fromTimestamp: string;
    timer: Timer | null;
    toTimestamp: string;
  }[];
  machineOperatorTimeCorrectionSet: {
    fromTimestamp: string;
    timer: Timer | null;
    toTimestamp: string;
  }[];
  machineuseSet: {
    machine: Machine | null;
    priceGroup: PriceGroup | null;
    transporter: boolean;
  }[];
  managerTimeCorrectionSet: {
    fromTimestamp: string;
    timer: Timer | null;
    toTimestamp: string;
  }[];
  order: InlinedOrder | null;
  priceGroup: PriceGroup | null;
  priceitemuseSet: InlinedPriceItemUse[];
  productuseSet: {
    correctedCount: number | null;
    count: number | null;
    notes: string;
    ours: boolean;
    product: Product | null;
  }[];
  project: Project | null;
  reportingSpecification: ReportingSpecification | null;
  workType: WorkType | null;
};

export const inlineTaskData = (
  task: Task,
  lookups: {
    contactLookup: (url: ContactUrl) => Contact | undefined;
    customerLookup: (url: CustomerUrl) => Customer | undefined;
    machineLookup: (url: MachineUrl) => Machine | undefined;
    orderLookup: (url: OrderUrl) => Order | undefined;
    priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
    priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined;
    productLookup: (url: ProductUrl) => Product | undefined;
    projectLookup: (url: ProjectUrl) => Project | undefined;
    reportingSpecificationLookup: (
      url: ReportingSpecificationUrl,
    ) => ReportingSpecification | undefined;
    timerLookup: (url: TimerUrl) => Timer | undefined;
    workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
  },
): InlinedTask => {
  const {
    contactLookup,
    customerLookup,
    machineLookup,
    orderLookup,
    priceGroupLookup,
    priceItemLookup,
    productLookup,
    projectLookup,
    reportingSpecificationLookup,
    timerLookup,
    workTypeLookup,
  } = lookups;
  // const mutable = {...task};
  const machineuseSet = (task.machineuseSet || []).map((entry) => {
    const machineURL = entry.machine;
    const machine = (machineURL && machineLookup(machineURL)) || null;
    const priceGroupURL = entry.priceGroup;
    const priceGroup = (priceGroupURL && priceGroupLookup(priceGroupURL)) || null;
    return {...entry, machine, priceGroup};
  });
  const computedTimeSet = (task.computedTimeSet || []).map((computedTime) => {
    const timerURL = computedTime.timer;
    const timer = timerLookup(timerURL) || null;
    return {...computedTime, timer};
  });
  const machineOperatorTimeCorrectionSet = (task.machineOperatorTimeCorrectionSet || []).map(
    (correction) => {
      const timerURL = correction.timer;
      const timer = (timerURL && timerLookup(timerURL)) || null;
      return {...correction, timer};
    },
  );
  const managerTimeCorrectionSet = (task.managerTimeCorrectionSet || []).map((correction) => {
    const timerURL = correction.timer;
    const timer = (timerURL && timerLookup(timerURL)) || null;
    return {...correction, timer};
  });
  const priceitemuseSet = _.sortBy(
    Object.values(task.priceItemUses || {}),
    (priceItemUse) => priceItemUse.order,
  ).map((entry) => {
    const priceItemURL = entry.priceItem;
    const priceItem = priceItemLookup(priceItemURL) || null;
    return {...entry, priceItem};
  });
  const productuseSet = _.sortBy(
    Object.values(task.productUses || {}),
    (productUse) => productUse.order,
  ).map((entry) => {
    const productURL = entry.product;
    const product = productLookup(productURL) || null;
    return {...entry, product};
  });
  const workTypeURL = task.workType;
  const workType = (workTypeURL && workTypeLookup(workTypeURL)) || null;
  const projectURL = task.project;
  const project = (projectURL && projectLookup(projectURL)) || null;
  const priceGroupURL = task.priceGroup;
  const priceGroup = (priceGroupURL && priceGroupLookup(priceGroupURL)) || null;
  const orderURL = task.order;
  const baseOrder = (orderURL && orderLookup(orderURL)) || null;
  const customerURL = baseOrder && baseOrder.customer;
  const customer = (customerURL && customerLookup(customerURL)) || null;
  const contactURL = baseOrder && baseOrder.contact;
  const contact = (contactURL && contactLookup(contactURL)) || null;
  const order: InlinedOrder | null = baseOrder ? {...baseOrder, contact, customer} : null;

  const computedIntervals = task.computedTimeSet || [];
  const correctionIntervals = task.machineOperatorTimeCorrectionSet || [];
  const managerCorrectionIntervals = task.managerTimeCorrectionSet || [];

  const intervals = mergeIntervals(
    computedIntervals,
    correctionIntervals,
    managerCorrectionIntervals,
  ).map((interval) => {
    const intervalTimerURL = interval.timer;
    const intervalTimer = (intervalTimerURL && timerLookup(intervalTimerURL)) || null;
    return {...interval, timer: intervalTimer};
  });

  const reportingSpecification = task.reportingSpecification
    ? reportingSpecificationLookup(task.reportingSpecification)
    : null;

  const result: InlinedTask = {
    ...task,
    _intervals: intervals,
    computedTimeSet,
    machineOperatorTimeCorrectionSet,
    machineuseSet,
    managerTimeCorrectionSet,
    order,
    priceGroup,
    priceitemuseSet,
    productuseSet,
    project,
    reportingSpecification: reportingSpecification || null,
    workType,
  };
  return result;
};
