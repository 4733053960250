import {UserProfile} from "@co-common-libs/resources";
import {Typography} from "@material-ui/core";
import React from "react";
import {FormattedMessage} from "react-intl";
import {EmailLine} from "./info-lines/email-line";
import {PhoneLine} from "./info-lines/phone-line";

export function UserInfo({
  user: {alias, cellphone, email, name, phone},
}: {
  user: UserProfile;
}): React.JSX.Element {
  const profileName = name ? `${name} (${alias})` : alias;
  return (
    <div>
      <Typography component="span" variant="body2">
        <FormattedMessage defaultMessage="Navn: {name}" values={{name: profileName}} />
      </Typography>
      {phone && <PhoneLine phone={phone} type="landline" />}
      {cellphone && <PhoneLine phone={cellphone} type="cellphone" />}
      {email && <EmailLine email={email} />}
    </div>
  );
}
