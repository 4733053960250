import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
  back as baseBack,
  backSkip as baseBackSkip,
  forwardBackSkip as baseForwardBackSkip,
  createNavigationCreator,
  navigate,
} from "@co-frontend-libs/routing-sync-history";
import {PathTemplate, pathTemplates} from "./path-templates";

export {
  deleteQueryKey,
  navigate,
  putQueryKey,
  putQueryKeys,
  replaceQuery,
} from "@co-frontend-libs/routing-sync-history";

export const go: (
  pathTemplate: PathTemplate,
  pathParameters?: PathParameters,
  queryParameters?: QueryParameters,
  navigationKind?: PartialNavigationKind,
) => ReturnType<typeof navigate> = createNavigationCreator(pathTemplates);

export const back: (count?: number, fallback?: PathTemplate) => ReturnType<typeof baseBack> =
  baseBack;

export const backSkip: (
  skip: readonly PathTemplate[],
  fallback?: PathTemplate,
) => ReturnType<typeof baseBackSkip> = baseBackSkip;

export const forwardBackSkip: (
  skip: readonly PathTemplate[],
  fallback?: PathTemplate,
) => ReturnType<typeof baseForwardBackSkip> = baseForwardBackSkip;
