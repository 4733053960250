import {BarcodeFormats} from "@co-common-libs/config";

const COMMON_BARCODE_SCANNER_OPTIONS: Omit<
  phonegapBarcode.BarcodeScanOptions,
  "formats" | "orientation" | "prompt"
> = {
  disableAnimations: true,
  disableSuccessBeep: true,
  preferFrontCamera: false,
  resultDisplayDuration: 0,
  saveHistory: false,
  showFlipCameraButton: true,
  showTorchButton: true,
  torchOn: false,
};

export type BarcodeFormat = BarcodeFormats[number];

export type BarcodeScanner = () => Promise<string>;

export function getBarcodeScanner(formats: readonly BarcodeFormat[]): BarcodeScanner | undefined {
  if (
    typeof cordova === "undefined" ||
    !navigator?.camera ||
    !cordova?.plugins?.barcodeScanner ||
    !formats.length
  ) {
    return undefined;
  }

  return function (): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      cordova.plugins.barcodeScanner.scan(
        ({text}) => {
          resolve(text);
        },
        reject,
        {
          ...COMMON_BARCODE_SCANNER_OPTIONS,
          formats: formats.join(","),
        },
      );
    });
  };
}
