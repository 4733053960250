import {
  LocationUrl,
  PriceItemUsesDict,
  ProductUsesDict,
  ReportingInputSpecification,
  ReportingWorkplaceTypeDataSpecification,
  Task,
  TimerUrl,
} from "@co-common-libs/resources";
import {ErrorEntry} from "app-utils";
import React from "react";
import {FormattedMessage} from "react-intl";
import {PriceItemBlock} from "./price-item-block";
import {ProductBlock} from "./product-block";
import {PriceItemUsesAction, ProductUsesAction} from "./utils";

interface MaterialsBlockProps {
  dispatchPriceItemUses: React.Dispatch<PriceItemUsesAction>;
  dispatchProductsUses: React.Dispatch<ProductUsesAction>;
  inputSpecificationsMap: Map<string, ReportingInputSpecification>;
  locationUrl: LocationUrl | undefined;
  materialError: ErrorEntry | null;
  priceItemUses: PriceItemUsesDict;
  productUses: ProductUsesDict;
  task: Task;
  timerMinutesMap: ReadonlyMap<TimerUrl, number>;
  valueMaps: readonly {
    readonly [identifier: string]: unknown;
  }[];
  workplaceTypeDataSpecification: ReportingWorkplaceTypeDataSpecification;
}

export function MaterialsBlock(props: MaterialsBlockProps): JSX.Element | null {
  const {
    dispatchPriceItemUses,
    dispatchProductsUses,
    inputSpecificationsMap,
    locationUrl,
    materialError,
    priceItemUses,
    productUses,
    task,
    timerMinutesMap,
    valueMaps,
    workplaceTypeDataSpecification,
  } = props;

  const includeProducts = workplaceTypeDataSpecification.logProducts;
  const includePriceItems = workplaceTypeDataSpecification.logPriceItems;

  let errors: string[] | undefined;
  if (!!materialError && typeof materialError !== "string") {
    errors =
      !!materialError && typeof materialError.children === "string"
        ? [materialError.children]
        : materialError?.children;
  }

  return (
    <>
      {includePriceItems ? (
        <PriceItemBlock
          dispatchPriceItemUses={dispatchPriceItemUses}
          inputSpecificationsMap={inputSpecificationsMap}
          priceItemUses={priceItemUses}
          task={task}
          valueMaps={valueMaps}
          workplaceTypeDataSpecification={workplaceTypeDataSpecification}
        />
      ) : null}
      {includeProducts ? (
        <ProductBlock
          dispatchProductsUses={dispatchProductsUses}
          inputSpecificationsMap={inputSpecificationsMap}
          locationUrl={locationUrl}
          priceItemUses={priceItemUses}
          productUses={productUses}
          task={task}
          timerMinutesMap={timerMinutesMap}
          valueMaps={valueMaps}
          workplaceTypeDataSpecification={workplaceTypeDataSpecification}
        />
      ) : null}
      {errors ? (
        <div>
          <FormattedMessage defaultMessage="Der er ikke angivet, eller fejl i angivelsen af:" />
          <ul>
            {errors.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        </div>
      ) : null}
    </>
  );
}
