import {WeekdayNumberType} from "@co-common-libs/utils";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {
  actions,
  getCurrentUserURL,
  getSettingsEntryLookupByIdentifier,
} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {DialogContent, List, ListItem, ListItemText} from "@material-ui/core";
import React, {useCallback, useMemo, useState} from "react";
import {FormattedMessage, defineMessages, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {SettingViewProps} from "../types";
import {DisplaySelected} from "./display-selected";

const messages = defineMessages({
  friday: {
    defaultMessage: "Fredag",
    id: "availability-dialog.label.friday",
  },
  monday: {
    defaultMessage: "Mandag",
    id: "availability-dialog.label.monday",
  },
  saturday: {
    defaultMessage: "Lørdag",
    id: "availability-dialog.label.saturday",
  },
  sunday: {
    defaultMessage: "Søndag",
    id: "availability-dialog.label.sunday",
  },
  thursday: {
    defaultMessage: "Torsdag",
    id: "availability-dialog.label.thursday",
  },
  tuesday: {
    defaultMessage: "Tirsdag",
    id: "availability-dialog.label.tuesday",
  },
  wednesday: {
    defaultMessage: "Onsdag",
    id: "availability-dialog.label.wednesday",
  },
});

function DayListItem({
  name,
  onClick,
  value,
}: {
  name: string;
  onClick: (value: WeekdayNumberType) => void;
  value: WeekdayNumberType;
}): JSX.Element {
  const handleClick = useCallback(() => {
    onClick(value);
  }, [onClick, value]);
  return (
    <ListItem button onClick={handleClick}>
      <ListItemText primary={name} />
    </ListItem>
  );
}

export const SelectWeekday = React.memo(function SelectWeekdays(
  props: SettingViewProps,
): JSX.Element {
  const {settingID, settingMetaData} = props;
  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const settingEntry = settingsEntryLookupByIdentifier(settingID);
  const currentWeekday: WeekdayNumberType | undefined = useMemo(
    () => settingEntry?.data,
    [settingEntry?.data],
  );

  const [weekdaysDialogOpen, setWeekdaysDialogOpen] = useState(false);
  const setWeekdaysDialogOpenTrue = useCallWithTrue(setWeekdaysDialogOpen);
  const setWeekdaysDialogOpenFalse = useCallWithFalse(setWeekdaysDialogOpen);

  const dispatch = useDispatch();
  const currentUserURL = useSelector(getCurrentUserURL);
  const handleDayClick = useCallback(
    (value: WeekdayNumberType) => {
      setWeekdaysDialogOpen(false);

      if (settingEntry) {
        dispatch(
          actions.update(settingEntry.url, [
            {member: "changedBy", value: currentUserURL},
            {member: "data", value},
          ]),
        );
      }
    },
    [currentUserURL, dispatch, settingEntry],
  );

  const {formatMessage} = useIntl();

  const weekdays = useMemo(
    () =>
      new Map<WeekdayNumberType, string>([
        [1, formatMessage(messages.monday)],
        [2, formatMessage(messages.tuesday)],
        [3, formatMessage(messages.wednesday)],
        [4, formatMessage(messages.thursday)],
        [5, formatMessage(messages.friday)],
        [6, formatMessage(messages.saturday)],
        [0, formatMessage(messages.sunday)],
      ]),
    [formatMessage],
  );

  const listItems = useMemo(
    () =>
      [...weekdays.entries()].map(([value, name]) => {
        return <DayListItem key={value} name={name} value={value} onClick={handleDayClick} />;
      }),
    [handleDayClick, weekdays],
  );

  const selectedWeekday = currentWeekday != null ? weekdays.get(currentWeekday) : null;

  return (
    <>
      <DisplaySelected
        currentValue={selectedWeekday ? selectedWeekday : ""}
        settingEntry={settingEntry}
        settingID={settingID}
        settingMetaData={settingMetaData}
        onClick={setWeekdaysDialogOpenTrue}
      />
      <ResponsiveDialog
        open={weekdaysDialogOpen}
        title={<FormattedMessage defaultMessage="Vælg dag" id="select-weekday.title" />}
        onCancel={setWeekdaysDialogOpenFalse}
      >
        <DialogContent dividers style={{padding: 0}}>
          <List>{listItems}</List>
        </DialogContent>
      </ResponsiveDialog>
    </>
  );
});
