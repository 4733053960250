import {CustomerUrl, Location, LocationUrl} from "@co-common-libs/resources";
import {useModal} from "@co-frontend-libs/components";
import {
  ConnectedLocationDialog,
  LocationDialogOwnProps,
} from "@co-frontend-libs/connected-components";
import {actions, getExtendedCustomerSettings, getLocationLookup} from "@co-frontend-libs/redux";
import {Button, IconButton, Typography} from "@material-ui/core";
import {createLocation} from "app-utils";
import CloseIcon from "mdi-react/CloseIcon";
import React, {Ref, forwardRef, useCallback, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {
  LocationCreateData,
  LocationCreateEditDialog,
  LocationCreateEditDialogEditProps,
  LocationCreateEditDialogLogCreateProps,
  LocationCreateEditDialogProps,
  LocationCreateEditDialogWorkplaceCreateProps,
} from "../location-create-edit-dialog";
import {InformationBlockPropsBase} from "./information-block-props-base";
import {LocationInfo} from "./location-info";

type UseLocationSettersParams = Omit<
  Partial<LocationCreateEditDialogEditProps> &
    Partial<LocationCreateEditDialogLogCreateProps> &
    Partial<LocationCreateEditDialogWorkplaceCreateProps> &
    LocationCreateEditDialogProps &
    LocationDialogOwnProps,
  | "customerURL"
  | "initialCustomer"
  | "initialSearch"
  | "onAdd"
  | "onCancel"
  | "onNone"
  | "onOk"
  | "open"
> & {
  customerUrl: CustomerUrl | null;
  onLocationChanged: (location: LocationUrl | null) => void;
};

export function useLocationSetters({
  onLocationChanged,
  ...params
}: UseLocationSettersParams): [
  typeof locationModal,
  typeof locationCreateModal,
  typeof handleLocationSelect,
] {
  const {
    locationFavoritesEnabled,
    locations: {canCreateWorkplace},
    setWorkplaceOnlyLocationOnCreate,
  } = useSelector(getExtendedCustomerSettings);

  const [locationSelectionAbortController] = useState<AbortController>(new AbortController());

  const dispatch = useDispatch();
  const [locationCreateModal, promptForCreateLocation] = useModal<
    LocationCreateData,
    LocationCreateEditDialogWorkplaceCreateProps
  >(LocationCreateEditDialog);
  const [locationModal, promptForLocation] = useModal(ConnectedLocationDialog);

  const handleNewWorkplaceSelect = useCallback(
    async (initialSearch: string) => {
      const locationCreateData = await promptForCreateLocation({
        customerLookup: params.customerLookup,
        initialCustomer: params.customerUrl,
        initialSearch,
        locationFavoritesEnabled: params.locationFavoritesEnabled ?? locationFavoritesEnabled,
        workplaceOnlyLocation: setWorkplaceOnlyLocationOnCreate,
      });
      if (locationCreateData) {
        const newInstance: Location = createLocation({
          ...locationCreateData,
        });
        dispatch(actions.create(newInstance));
        onLocationChanged(newInstance.url);
        locationSelectionAbortController.abort();
      }
    },
    [
      promptForCreateLocation,
      params.customerLookup,
      params.customerUrl,
      params.locationFavoritesEnabled,
      locationFavoritesEnabled,
      setWorkplaceOnlyLocationOnCreate,
      dispatch,
      onLocationChanged,
      locationSelectionAbortController,
    ],
  );

  const handleLocationSelect = useCallback(async (): Promise<void> => {
    const newLocationUrl = await promptForLocation(
      {
        customerURL: params.customerUrl || null,
        hideFieldLocations: params.hideFieldLocations,
        includeLogOnlyLocations: params.includeLogOnlyLocations,
        includeWorkplaceOnlyLocations: params.includeWorkplaceOnlyLocations,
        lastUsedLocations: params.lastUsedLocations,
        onAdd: canCreateWorkplace ? handleNewWorkplaceSelect : undefined,
        titleVariant: "WORKPLACE",
      },
      locationSelectionAbortController.signal,
    );
    if (newLocationUrl) {
      onLocationChanged(newLocationUrl);
    }
  }, [
    promptForLocation,
    params.customerUrl,
    params.hideFieldLocations,
    params.includeLogOnlyLocations,
    params.includeWorkplaceOnlyLocations,
    params.lastUsedLocations,
    canCreateWorkplace,
    handleNewWorkplaceSelect,
    onLocationChanged,
    locationSelectionAbortController,
  ]);

  return [locationModal, locationCreateModal, handleLocationSelect];
}

type LocationBlockProps = UseLocationSettersParams &
  InformationBlockPropsBase & {
    locationUrl: LocationUrl | null;
    title: string;
  };

export const LocationBlock = forwardRef(function LocationBlock(
  {clearDisabled, hideButtons, locationUrl, selectDisabled, title, ...props}: LocationBlockProps,
  selectButtonRef: Ref<HTMLButtonElement>,
): JSX.Element {
  const locationLookup = useSelector(getLocationLookup);
  const [locationModal, locationCreateModal, handleLocationSelect] = useLocationSetters(props);

  const {onLocationChanged} = props;
  const handleLocationClear = useCallback((): void => {
    onLocationChanged(null);
  }, [onLocationChanged]);

  const workplace = locationUrl && locationLookup(locationUrl);
  return (
    <>
      <Typography variant="body1">{title}</Typography>
      {!hideButtons && (
        <>
          <Button
            ref={selectButtonRef}
            color="secondary"
            disabled={selectDisabled}
            variant="contained"
            onClick={handleLocationSelect}
          >
            <FormattedMessage defaultMessage="Vælg" />
          </Button>
          <IconButton disabled={clearDisabled} onClick={handleLocationClear}>
            <CloseIcon />
          </IconButton>
        </>
      )}
      {workplace && <LocationInfo location={workplace} />}
      {locationModal}
      {locationCreateModal}
    </>
  );
});
