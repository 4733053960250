import {
  CustomerUrl,
  LocationUrl,
  MachineUrl,
  PriceGroupUrl,
  ProjectUrl,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {getCustomerSettings} from "@co-frontend-libs/redux";
import {useMachine} from "@xstate/react/lib/fsm";
import React, {forwardRef, useImperativeHandle} from "react";
import {useSelector} from "react-redux";
import {CustomerTaskCreationDisplay} from "./customer-task-creation-display";
import {customerTaskCreationStateMachine} from "./customer-task-creation-state-machine";

interface CustomerTaskCreationWrapperProps {
  onCustomerTaskCreation: (data: {
    customer: CustomerUrl;
    department: string;
    fields: readonly LocationUrl[];
    machines: readonly {
      readonly machine: MachineUrl;
      readonly priceGroup: PriceGroupUrl | null;
    }[];
    priceGroup: PriceGroupUrl | null;
    project: ProjectUrl | null;
    workPlace: LocationUrl | null;
    workType: WorkTypeUrl | null;
  }) => void;
  onCustomerTaskCreationCancelled?: () => void;
}

/**
 * Helper to use state machine and related UI from class-based components
 * in a somewhat hacky way via refs, without having to manage state machine
 * lifecycle in from the component lifecycle callbacks.
 *
 * To use from function components, do what this component does with
 * `useMachine()` and the related "display" component.
 */
export const CustomerTaskCreationWrapper = forwardRef(function CustomerTaskCreationWrapper(
  props: CustomerTaskCreationWrapperProps,
  ref: React.Ref<{
    start: (data?: {
      customer?: CustomerUrl;
      defaultDepartment?: string | null;
      skipMachineChoice?: boolean;
    }) => void;
  }>,
): JSX.Element {
  const {onCustomerTaskCreation, onCustomerTaskCreationCancelled} = props;
  const customerSettings = useSelector(getCustomerSettings);
  const [state, send] = useMachine(customerTaskCreationStateMachine, {
    actions: {
      signalCancelled: (_context, _event) => {
        if (onCustomerTaskCreationCancelled) {
          onCustomerTaskCreationCancelled();
        }
      },
      signalDone: (context, _event) => {
        const {customer, department, fields, location, machines, priceGroup, project, workType} =
          context;
        onCustomerTaskCreation({
          customer: customer as CustomerUrl,
          department: department || "",
          fields,
          machines,
          priceGroup,
          project,
          workPlace: location,
          workType,
        });
      },
    },
  });

  useImperativeHandle(
    ref,
    () => ({
      start: (data) => {
        send({
          customer: data?.customer ?? null,
          department: data?.defaultDepartment || null,
          departmentsEnabled: customerSettings.enableExternalTaskDepartmentField,
          projectsEnabled: customerSettings.enableProjects,
          skipMachineChoice: data?.skipMachineChoice ?? false,
          type: "START",
          workTypesEnabled: !customerSettings.noExternalTaskWorkType,
        });
      },
    }),
    [
      customerSettings.enableExternalTaskDepartmentField,
      customerSettings.enableProjects,
      customerSettings.noExternalTaskWorkType,
      send,
    ],
  );

  return <CustomerTaskCreationDisplay send={send} state={state} />;
});
