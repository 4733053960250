import {MINUTE_MILLISECONDS} from "@co-common-libs/utils";
import _ from "lodash";
import {BaseWorkDay, TaskIntervalWithRate, WorkDay, WorkPeriod} from "./types";

/** Group `WorkPeriod` instances into `WorkDay` instances.
 * @param sortedWorkPeriods The (sorted) work periods to work with.
 */
export function toWorkDaysPerDate(sortedWorkPeriods: readonly WorkPeriod[]): BaseWorkDay[] {
  if (sortedWorkPeriods.length === 0) {
    return [];
  }
  return Object.entries(_.groupBy(sortedWorkPeriods, ({date}) => date)).map(
    ([date, workPeriods]) => ({date, workPeriods}),
  );
}

export function toWorkDaysSplitThreshold(
  sortedWorkPeriods: readonly WorkPeriod[],
  workDaySplitThresholdMinutes: number,
): BaseWorkDay[] {
  if (sortedWorkPeriods.length === 0) {
    return [];
  }
  const result: BaseWorkDay[] = [];
  let currentDayEntry = {
    date: sortedWorkPeriods[0].date,
    workPeriods: [sortedWorkPeriods[0]],
  };
  // skip first; already in currentDayEntry
  for (const workPeriod of sortedWorkPeriods.slice(1)) {
    const currentDayLastWorkPeriod =
      currentDayEntry.workPeriods[currentDayEntry.workPeriods.length - 1];
    const millisecondsBetween =
      new Date(workPeriod.firstFromTimestamp).valueOf() -
      new Date(currentDayLastWorkPeriod.lastToTimestamp).valueOf();
    const minutesBetween = millisecondsBetween / MINUTE_MILLISECONDS;
    if (minutesBetween < workDaySplitThresholdMinutes) {
      currentDayEntry.workPeriods.push(workPeriod);
    } else {
      result.push(currentDayEntry);
      currentDayEntry = {
        date: workPeriod.date,
        workPeriods: [workPeriod],
      };
    }
  }
  result.push(currentDayEntry);
  return result;
}

export function mapWorkDaysWorkIntervals(
  workDays: readonly WorkDay[],
  fn: (work: readonly TaskIntervalWithRate[]) => TaskIntervalWithRate[],
): WorkDay[] {
  function perWorkPeriod(workPeriod: WorkPeriod): WorkPeriod {
    return {
      ...workPeriod,
      work: fn(workPeriod.work),
    };
  }
  function perWorkDay(workDay: WorkDay): WorkDay {
    return {
      ...workDay,
      workPeriods: workDay.workPeriods.map(perWorkPeriod),
    };
  }
  return workDays.map(perWorkDay);
}
