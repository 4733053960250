import {SettingID, settings} from "@co-common-libs/config";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {
  actions,
  getCurrentUserURL,
  getMachineArray,
  getSettingEntryArray,
} from "@co-frontend-libs/redux";
import {useCallWithTrue} from "@co-frontend-libs/utils";
import {Checkbox, DialogContent, FormControl} from "@material-ui/core";
import {useEventTargetCheckedCallback} from "app-utils";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {ErrorsBlock} from "../errors-block";
import {checkConflictsWith, checkRequiresAllOf, checkRequiresOneOf, getValidator} from "../utils";

export interface EnableSmallMachinesDialogProps {
  nonBlankSettings: ReadonlySet<SettingID>;
  onClose: () => void;
  open: boolean;
  settingID: SettingID;
}

export function EnableSmallMachinesDialog(
  props: EnableSmallMachinesDialogProps,
): JSX.Element | null {
  const {nonBlankSettings, onClose, open, settingID} = props;
  const intl = useIntl();

  const [warningDialogOpen, setWarningDialogOpen] = useState(false);
  const setWarningDialogOpenTrue = useCallWithTrue(setWarningDialogOpen);

  const settingEntryArray = useSelector(getSettingEntryArray);
  const settingEntry = useMemo(
    () => settingEntryArray.find((entry) => entry.key === settingID),
    [settingEntryArray, settingID],
  );

  const currentUserURL = useSelector(getCurrentUserURL);
  const settingMetaData = settings[settingID];

  const {schema} = settingMetaData;

  const savedValue: boolean = settingEntry?.data || settingMetaData.defaultValue;

  const [value, setValue] = useState(savedValue);

  useEffect(() => {
    if (open) {
      setValue(savedValue);
    }
  }, [open, savedValue]);

  const dispatch = useDispatch();

  const handleSave = useCallback(() => {
    if (value !== undefined) {
      if (settingEntry) {
        dispatch(
          actions.update(settingEntry.url, [
            {member: "changedBy", value: currentUserURL},
            {member: "data", value},
          ]),
        );
      }
    }
    onClose();
  }, [currentUserURL, dispatch, value, onClose, settingEntry]);

  const machineArray = useSelector(getMachineArray);
  const handleClearSmallMachineField = useCallback(() => {
    setWarningDialogOpen(false);
    machineArray
      .filter((m) => m.smallMachine)
      .forEach((m) => dispatch(actions.update(m.url, [{member: "smallMachine", value: false}])));
    handleSave();
  }, [dispatch, handleSave, machineArray]);

  const validator = useMemo(() => (schema ? getValidator(schema) : null), [schema]);
  const schemaErrors = useMemo(() => (validator ? validator(value) : null), [validator, value]);

  const handleCheckedChange = useEventTargetCheckedCallback(setValue, [setValue]);

  const {description} = settingMetaData;

  const conflictsWithIssue = checkConflictsWith(settingMetaData, nonBlankSettings, value);
  const requiresAllOfIssue = checkRequiresAllOf(settingMetaData, nonBlankSettings, value);
  const requiresOneOfIssue = checkRequiresOneOf(settingMetaData, nonBlankSettings, value);

  const handleWarningDialogClose = useCallback(() => {
    setWarningDialogOpen(false);
    onClose();
  }, [onClose]);

  return (
    <>
      <ResponsiveDialog
        fullWidth
        okDisabled={
          !!schemaErrors || !!conflictsWithIssue || !!requiresAllOfIssue || !!requiresOneOfIssue
        }
        okLabel={<FormattedMessage defaultMessage="Gem" />}
        open={open && !warningDialogOpen}
        title={description}
        onCancel={onClose}
        onOk={savedValue && !value ? setWarningDialogOpenTrue : handleSave}
      >
        <DialogContent>
          <FormControl>
            <Checkbox checked={value} onChange={handleCheckedChange} />
          </FormControl>
          <ErrorsBlock
            nonBlankSettings={nonBlankSettings}
            settingMetaData={settingMetaData}
            value={value}
          />
        </DialogContent>
      </ResponsiveDialog>
      <ResponsiveDialog
        fullWidth
        open={warningDialogOpen}
        title={intl.formatMessage({defaultMessage: "Deaktivér småmaskiner"})}
        onCancel={handleWarningDialogClose}
        onOk={handleClearSmallMachineField}
      >
        <DialogContent>
          <FormattedMessage defaultMessage="Deaktivering af småmaskiner vil ændre alle eksisterende småmaskiner til ikke længere at være dette. Er du sikker?" />
        </DialogContent>
      </ResponsiveDialog>
    </>
  );
}
