import {Journal, LocationUrl, WorkTypeUrl, emptyJournal, urlToId} from "@co-common-libs/resources";
import {getWorkTypeString} from "@co-common-libs/resources-utils";
import {caseAccentInsensitiveCollator, formatDateNumeric} from "@co-common-libs/utils";
import {DeleteDialog} from "@co-frontend-libs/components";
import {actions, getLocationLookup, getWorkTypeLookup} from "@co-frontend-libs/redux";
import {
  Card,
  CardHeader,
  Fab,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {getLocationString} from "app-utils";
import {instanceURL} from "frontend-global-config";
import DeleteIcon from "mdi-react/DeleteIcon";
import PencilIcon from "mdi-react/PencilIcon";
import PlusIcon from "mdi-react/PlusIcon";
import React, {useCallback, useState} from "react";
import {FormattedMessage, defineMessages, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuid} from "uuid";

const DATE_COLUMN_STYLE = {
  width: 80,
};
const REMINDER_COLUMN_STYLE = {
  width: 80,
};
const EDIT_DELETE_COLUMN_STYLE = {
  width: 128,
};

const messages = defineMessages({
  journal: {
    defaultMessage: "Journal",
    id: "customer-instance.card-title.journal",
  },
});

interface JournalRowProps {
  date: string;
  entry?: string;
  id: string;
  locations?: readonly LocationUrl[] | undefined;
  onDelete: (url: string) => void;
  reminder?: boolean;
  workTypes?: readonly WorkTypeUrl[];
}

const JournalRow = React.memo(function JournalRow(props: JournalRowProps): JSX.Element {
  const {date, entry, id, locations, onDelete, reminder, workTypes} = props;
  const workTypeLookup = useSelector(getWorkTypeLookup);
  const locationLookup = useSelector(getLocationLookup);

  const dispatch = useDispatch();
  const handleEditClick = useCallback(() => {
    dispatch(actions.go("/journal/:id", {id}));
  }, [dispatch, id]);

  const handleDeleteClick = useCallback(() => {
    onDelete(instanceURL("journal", id));
  }, [id, onDelete]);

  let workTypeString = "";
  if (workTypes && workTypes.length) {
    const workTypeStrings: string[] = [];
    workTypes.forEach((workTypeURL) => {
      const workType = workTypeLookup(workTypeURL);
      if (workType) {
        workTypeStrings.push(getWorkTypeString(workType));
      }
    });
    workTypeStrings.sort(caseAccentInsensitiveCollator.compare);
    workTypeString = workTypeStrings.join(", ");
  }
  let locationString = "";
  if (locations && locations.length) {
    const locationStrings: string[] = [];
    locations.forEach((locationURL) => {
      const location = locationLookup(locationURL);
      if (location) {
        locationStrings.push(getLocationString(location));
      }
    });
    locationStrings.sort(caseAccentInsensitiveCollator.compare);
    locationString = locationStrings.join(", ");
  }
  let hasReminder;
  if (reminder) {
    hasReminder = (
      <FormattedMessage defaultMessage="Ja" id="journal-entry.table-header.reminder-yes" />
    );
  } else {
    hasReminder = (
      <FormattedMessage defaultMessage="Nej" id="journal-entry.table-header.reminder-no" />
    );
  }

  return (
    <TableRow>
      <TableCell style={DATE_COLUMN_STYLE}>{formatDateNumeric(date)}</TableCell>
      <TableCell style={{whiteSpace: "pre-line"}}>{entry}</TableCell>
      <TableCell style={REMINDER_COLUMN_STYLE}>{hasReminder}</TableCell>
      <TableCell>{workTypeString}</TableCell>
      <TableCell>{locationString}</TableCell>
      <TableCell style={EDIT_DELETE_COLUMN_STYLE}>
        <IconButton onClick={handleEditClick}>
          <PencilIcon />
        </IconButton>
        <IconButton onClick={handleDeleteClick}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
});

interface JournalCardProps {
  customerURL: string;
  journalList: readonly Journal[];
}

export const JournalCard = React.memo(function JournalCard(props: JournalCardProps): JSX.Element {
  const {customerURL} = props;
  const dispatch = useDispatch();
  const handleAddJournalButton = useCallback(() => {
    const id = uuid();
    const url = instanceURL("journal", id);
    dispatch(
      actions.create({
        ...emptyJournal,
        customer: customerURL,
        deviceTimestamp: new Date().toISOString(),
        id,
        url,
      } as Journal),
    );
    const navigationTimeoutMS = 300;
    setTimeout(() => {
      dispatch(actions.go("/journal/:id", {id}));
    }, navigationTimeoutMS);
  }, [customerURL, dispatch]);

  const {formatMessage} = useIntl();
  const [deleteDialogOpenFor, setDeleteDialogOpenFor] = useState<string | null>(null);
  const handleDeleteDialogOk = useCallback(() => {
    if (deleteDialogOpenFor) {
      dispatch(actions.remove(deleteDialogOpenFor));
      setDeleteDialogOpenFor(null);
    }
  }, [deleteDialogOpenFor, dispatch]);

  const handleDeleteDialogCancel = useCallback(() => {
    setDeleteDialogOpenFor(null);
  }, []);

  const handleDelete = useCallback((url: string) => {
    setDeleteDialogOpenFor(url);
  }, []);

  return (
    <>
      <Card>
        <div style={{position: "relative"}}>
          <Fab
            size="small"
            style={{
              position: "absolute",
              right: 16,
              top: 16,
            }}
            onClick={handleAddJournalButton}
          >
            <PlusIcon />
          </Fab>
        </div>
        <CardHeader title={formatMessage(messages.journal)} />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={DATE_COLUMN_STYLE}>
                <FormattedMessage defaultMessage="Dato" id="journal-entry.table-header.date" />
              </TableCell>
              <TableCell>
                <FormattedMessage defaultMessage="Note" id="journal-entry.table-header.entry" />
              </TableCell>
              <TableCell style={REMINDER_COLUMN_STYLE}>
                <FormattedMessage
                  defaultMessage="Påmindelse"
                  id="journal-entry.table-header.reminder"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage defaultMessage="Områder" />
              </TableCell>
              <TableCell>
                <FormattedMessage defaultMessage="Steder" />
              </TableCell>
              <TableCell style={EDIT_DELETE_COLUMN_STYLE} />
            </TableRow>
          </TableHead>
          <TableBody>
            {props.journalList.map((journal) => {
              return (
                <JournalRow
                  key={urlToId(journal.url)}
                  date={journal.deviceTimestamp}
                  entry={journal.entry}
                  id={journal.id as string}
                  locations={journal.locations}
                  reminder={journal.reminder}
                  workTypes={journal.workTypes}
                  onDelete={handleDelete}
                />
              );
            })}
          </TableBody>
        </Table>
      </Card>
      <DeleteDialog
        open={!!deleteDialogOpenFor}
        onCancel={handleDeleteDialogCancel}
        onOk={handleDeleteDialogOk}
      >
        <FormattedMessage defaultMessage="Slet journal note?" />
      </DeleteDialog>
    </>
  );
});
