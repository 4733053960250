import {resourceNameFor} from "@co-common-libs/resources";
import {getFrontendSentry} from "@co-frontend-libs/utils";
import {Draft} from "@reduxjs/toolkit";
import {addToOffline} from "../actions";
import {ResourcesState} from "../types";

export function handleAddToOfflinePending(
  state: Draft<ResourcesState>,
  action: ReturnType<typeof addToOffline.pending>,
): void {
  const instanceOrInstances = action.meta.arg;
  const instances = Array.isArray(instanceOrInstances)
    ? instanceOrInstances
    : [instanceOrInstances];
  for (const instance of instances) {
    const {url} = instance;
    const resourceName = resourceNameFor(url);
    state.persistedData[resourceName][url] = instance as Draft<any>;
    if (state.temporaryData[resourceName][url]) {
      state.temporaryData[resourceName][url] = instance as Draft<any>;
    }
  }
}

export function handleAddToOfflineRejected(
  state: Draft<ResourcesState>,
  action: ReturnType<typeof addToOffline.rejected>,
): void {
  const sentry = getFrontendSentry();
  sentry.withScope((scope) => {
    scope.setTag("action", "handleAddToOfflineRejected");
    sentry.captureMessage("Local DB Error", "error");
  });
  state.localDBError = action.payload || (action.error as any);
}
