import {Config} from "@co-common-libs/config";
import {Card, CardContent, CardHeader, IconButton} from "@material-ui/core";
import {Linkify} from "app-components";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import PencilIcon from "mdi-react/PencilIcon";
import React from "react";
import {FormattedMessage, IntlContext, defineMessages} from "react-intl";
import VisibilitySensor from "react-visibility-sensor";

const messages = defineMessages({
  notPublished: {
    defaultMessage: "Ikke Publiseret",
    id: "information.information-item.not-published",
  },
  notSeenBy: {
    defaultMessage: "Ikke set af",
    id: "information.label.not-seen-by",
  },
  seenBy: {defaultMessage: "Set af", id: "information.label.seen-by"},
});

interface InformationItemProps {
  createdBy: string;
  customerSettings: Config;
  date?: string | undefined;
  id: string;
  isManager: boolean;
  isSeen: boolean;
  onEditButtonClick: (id: string) => void;
  onSeen: (id: string) => void;
  published: boolean;
  text: string;
  title: string;
  userInitials: readonly string[];
  visibleToInitials: readonly string[];
}

export class InformationItem extends PureComponent<InformationItemProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  @bind
  handleClick(): void {
    this.props.onEditButtonClick(this.props.id);
  }

  @bind
  handleVisibleChange(isVisible: boolean): void {
    if (isVisible && !this.props.isSeen) {
      this.props.onSeen(this.props.id);
    }
  }

  render(): JSX.Element {
    const {customerSettings} = this.props;
    const {formatMessage} = this.context;
    const {
      createdBy,
      date,
      isManager,
      isSeen,
      published,
      text,
      title,
      userInitials,
      visibleToInitials,
    } = this.props;

    let visibleToBlock;
    if (this.props.customerSettings.selectInformationvisibleTo && isManager) {
      visibleToBlock = (
        <div>
          <FormattedMessage
            defaultMessage="Synlig for"
            id="information.information-item.visible-for"
            tagName="strong"
          />
          :{" "}
          {!visibleToInitials.length ? (
            <FormattedMessage defaultMessage="Alle" id="information.information-item.all" />
          ) : (
            visibleToInitials.join(", ")
          )}
        </div>
      );
    }

    // official TypeScript type declarations say that
    // partialVisibility is a boolean; but according to documentation
    // (and implementation), "top"/"right"/"bottom"/"left" are also legal
    const VisibilitySensorNoCheck = VisibilitySensor as any;

    // We want to consider a post "seen" as soon as the bottom is
    // scrolled into view -- even if the resulting situation is only
    // partial visibility with the top scrolled away in the meantime...
    return (
      <VisibilitySensorNoCheck
        active={!isSeen && published}
        partialVisibility="bottom"
        onChange={this.handleVisibleChange}
      >
        <Card>
          <CardHeader subheader={date ? `${date} - ${createdBy}` : createdBy} title={title} />
          <CardContent>
            <div style={{whiteSpace: "pre-wrap"}}>
              <Linkify>{text}</Linkify>
            </div>

            {isManager && userInitials.length ? (
              <div>
                <strong>
                  {customerSettings.showWhoHasNotReadInformation
                    ? formatMessage(messages.notSeenBy)
                    : formatMessage(messages.seenBy)}
                </strong>
                : {userInitials.join(", ")}
              </div>
            ) : null}
            {visibleToBlock}
            {isManager ? (
              <IconButton onClick={this.handleClick}>
                <PencilIcon />
              </IconButton>
            ) : null}
          </CardContent>
        </Card>
      </VisibilitySensorNoCheck>
    );
  }
}
