import {replacementList as diacriticsReplacementList, remove as removeDiacritics} from "diacritics";

export const normalizeSearchText = (text: string): string => {
  if (text) {
    return removeDiacritics(text.trim()).toLowerCase();
  } else {
    return "";
  }
};

export const normalizeSearchTextArray = (text: string): string[] => {
  const base = normalizeSearchText(text).split(" ");
  if (base.length === 1) {
    return base;
  }
  return Array.from(new Set(base)).sort(longestFirst);
};

const check = (haystack: string, needleArray: string[]): boolean => {
  for (let i = 0; i < needleArray.length; i += 1) {
    const needle = needleArray[i];
    if (haystack.indexOf(needle) === -1) {
      return false;
    }
  }
  return true;
};

/**
 * @param haystack String to search for matches in.
 * @param needleArrayWithAlternatives Array of arrays of parts to match.
 *   For each element in the "outer" array, there should be a match on at
 *   least one of the sub-array elements for the result to be considered a
 *   match.
 */
const checkWithAlternatives = (
  haystack: string,
  needleArrayWithAlternatives: string[][],
): boolean => {
  for (let i = 0; i < needleArrayWithAlternatives.length; i += 1) {
    const alternatives = needleArrayWithAlternatives[i];
    let found = false;
    for (let j = 0; j < alternatives.length; j += 1) {
      const needle = alternatives[j];
      if (haystack.indexOf(needle) !== -1) {
        found = true;
        break;
      }
    }
    if (!found) {
      return false;
    }
  }
  return true;
};

const dateNativeFormat = /^\d{4}-\d{2}-\d{2}$/;
const dateWithoutSeparators = /^\d{4}(?:\d{4}|\d{2})?$/;
const dateWithSeparators = /^\d{1,2}([/,.-])\d{1,2}(?:\1\d{2}\d{2}?)?$/;

/**
 * Attempt to parse a date from input text.
 * Same input formats/using same regexps as in our `<DateField>`.
 * Returns the date, or `null` if input does not match our date-regexps.
 * @param text Text to parse date from.
 */
function dateFromString(text: string): Date | null {
  if (!text) {
    return null;
  }
  const trimmed = text.trim();
  let dayPart;
  let monthPart;
  let yearPart;
  if (dateNativeFormat.test(trimmed)) {
    [yearPart, monthPart, dayPart] = trimmed.split("-");
  } else if (dateWithoutSeparators.test(trimmed)) {
    dayPart = trimmed.substr(0, 2);

    monthPart = trimmed.substr(2, 2);

    yearPart = trimmed.substr(4);
  } else if (dateWithSeparators.test(trimmed)) {
    const parts = trimmed.split(/[/,.-]/);
    [dayPart, monthPart, yearPart] = parts;
  } else {
    return null;
  }
  const dayValue = parseInt(dayPart);
  const monthValue = parseInt(monthPart);
  let yearValue;

  if (yearPart && yearPart.length === 4) {
    yearValue = parseInt(yearPart);
  } else if (yearPart && yearPart.length === 2) {
    yearValue = 2000 + parseInt(yearPart);
  } else {
    yearValue = new Date().getFullYear();
  }
  return new Date(yearValue, monthValue - 1, dayValue);
}

/**
 * For any valid date-input-string in the input,
 * translate to [text, formatted date]; other elements to [text].
 * Intended for use with checkWithAlternatives.
 */
function extendSearchTextArrayWithAlternatives(
  texts: string[],
  dateFormatter: (date: Date) => string,
): string[][] {
  return texts.map((text) => {
    const date = dateFromString(text);
    if (date) {
      return [text, normalizeSearchText(dateFormatter(date))];
    } else {
      return [text];
    }
  });
}

/**
 *
 * @param needle String to search for parts of.
 * @param dateFormatter If provided, consider match on formatted date
 *   from parsed date from search string part to be a match on that search
 *   string part...
 */
export const makeContainsPredicate = (
  needle: string,
  dateFormatter?: (date: Date) => string,
): ((haystack: string) => boolean) => {
  const needleArray = normalizeSearchTextArray(needle);
  let needleArrayWithAlternatives: string[][] | undefined;
  if (dateFormatter) {
    needleArrayWithAlternatives = extendSearchTextArrayWithAlternatives(needleArray, dateFormatter);
  }
  if (needleArrayWithAlternatives) {
    const needleArrayWithAlternativesNotUndefined = needleArrayWithAlternatives;
    if (needleArrayWithAlternativesNotUndefined.some((part) => part.length > 1)) {
      return (haystack: string): boolean => {
        const normalizedHaystack = normalizeSearchText(haystack);
        return checkWithAlternatives(normalizedHaystack, needleArrayWithAlternativesNotUndefined);
      };
    }
  }
  return (haystack: string): boolean => {
    const normalizedHaystack = normalizeSearchText(haystack);
    return check(normalizedHaystack, needleArray);
  };
};

export const contains = (haystack: string, needle: string): boolean => {
  const needleArray = normalizeSearchTextArray(needle);
  const normalizedHaystack = normalizeSearchText(haystack);
  return check(normalizedHaystack, needleArray);
};

export const shortestFirst = (a: string, b: string): number => a.length - b.length;

export const longestFirst = (a: string, b: string): number => b.length - a.length;

const getScore = (
  needleWord: string,
  normalizedPrioritizedHaystacks: [number, string[]][],
): number | null => {
  for (let j = 0; j < normalizedPrioritizedHaystacks.length; j += 1) {
    const priority = normalizedPrioritizedHaystacks[j][0];
    const haystackWords = normalizedPrioritizedHaystacks[j][1];
    for (let k = 0; k < haystackWords.length; k += 1) {
      const haystackWord = haystackWords[k];
      if (haystackWord.indexOf(needleWord) !== -1) {
        return (priority * needleWord.length) / haystackWord.length;
      }
    }
  }
  return null;
};

const makeScoreMatch = (
  needle: string,
): ((prioritizedHaystacks: readonly Readonly<[number, string]>[]) => number) => {
  const needleArray = normalizeSearchTextArray(needle);
  // long words are less likely to match, so check those first...
  needleArray.sort(longestFirst);
  return (prioritizedHaystacks: readonly Readonly<[number, string]>[]): number => {
    // highest priority needs to be first in input for this to work sensibly
    const normalizedPrioritizedHaystacks: [number, string[]][] = [];
    for (let i = 0; i < prioritizedHaystacks.length; i += 1) {
      const priority = prioritizedHaystacks[i][0];
      const haystack = prioritizedHaystacks[i][1];
      const normalizedHaystack = normalizeSearchTextArray(haystack);
      // match on short word is supposed to give give higher score, so we need
      // those to check those first...
      normalizedHaystack.sort(shortestFirst);
      normalizedPrioritizedHaystacks.push([priority, normalizedHaystack]);
    }
    let score = 0;
    for (let i = 0; i < needleArray.length; i += 1) {
      const needleWord = needleArray[i];
      const scoreIncrement = getScore(needleWord, normalizedPrioritizedHaystacks);
      if (scoreIncrement) {
        score += scoreIncrement;
      } else {
        return 0;
      }
    }
    return score;
  };
};

export const filterSortMatch = <T>(
  filterString: string,
  extractMatchStrings: (data: T) => readonly Readonly<[number, string]>[],
  dataList: readonly T[],
): readonly T[] => {
  const needle = filterString.trim();
  if (!needle) {
    return dataList;
  }
  const scoreMatch = makeScoreMatch(needle);
  // high score implies good match; low number implies early in sort order;
  // all scores should be positive numbers; so negate to get right order

  const dataWithScores: Readonly<[number, T]>[] = [];
  for (let i = 0; i < dataList.length; i += 1) {
    const row = dataList[i];
    const score = scoreMatch(extractMatchStrings(row));
    if (score > 0) {
      dataWithScores.push([score, row]);
    }
  }
  // negative numbers when a has higher score;
  // a comes first if it has higher score
  dataWithScores.sort((a, b) => b[0] - a[0]);
  return dataWithScores.map(([_score, row]) => row);
};

const diacriticsMultiLengthMap = ((): {[key: string]: number | undefined} => {
  const result: {[key: string]: number | undefined} = {};
  for (let i = 0; i < diacriticsReplacementList.length; i += 1) {
    const entry = diacriticsReplacementList[i];
    const replacementLength = entry.base.length;
    if (replacementLength > 1) {
      const {chars} = entry;
      for (let j = 0; j < chars.length; j += 1) {
        const char = chars[j];
        result[char] = replacementLength;
      }
    }
  }
  return result;
})();

// convert position in what the given string would expand to with
// removeDiacritic() to position in the original string
export function diacriticsCorrectedPosition(str: string, pos: number): number {
  let n = 0;
  for (let i = 0; i < str.length; i += 1) {
    const char = str[i];
    const replacementLength = diacriticsMultiLengthMap[char] || 1;
    n += replacementLength;
    if (n === pos) {
      return i + 1;
    }
  }
  return pos;
}
