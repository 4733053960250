import {Config} from "@co-common-libs/config";
import {
  Contact,
  Customer,
  CustomerUrl,
  Order,
  OrderUrl,
  ReportingPrintout,
  Task,
  TaskUrl,
  UserProfile,
  UserUrl,
  urlToId,
} from "@co-common-libs/resources";
import {formatDate, formatTime} from "@co-common-libs/utils";
import {FilePdfIcon, ResponsiveDialog, SpinnerDialog} from "@co-frontend-libs/components";
import {
  AppState,
  getCustomerLookup,
  getCustomerSettings,
  getOrderLookup,
  getReportingPrintoutArray,
  getShareToken,
  getSortedActiveContactArrayPerCustomer,
  getTaskLookup,
  getUserUserProfileLookup,
} from "@co-frontend-libs/redux";
import {jsonFetch} from "@co-frontend-libs/utils";
import {
  Card,
  CardHeader,
  CardMedia,
  CircularProgress,
  Fab,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {MailReportDialog} from "app-components";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import {globalConfig} from "frontend-global-config";
import _ from "lodash";
import FileDelimitedIcon from "mdi-react/FileDelimitedIcon";
import FileDocumentIcon from "mdi-react/FileDocumentIcon";
import MailIcon from "mdi-react/MailIcon";
import PlusIcon from "mdi-react/PlusIcon";
import React from "react";
import {FormattedMessage, IntlContext, defineMessages} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {getCustomerMailAddress} from "../yield-log-card";

const messages = defineMessages({
  mailError: {
    defaultMessage: "Der skete en fejl under afsendelsen af emailen",
    id: "printout-card.label.mailerror",
  },
  mailMessage: {
    defaultMessage: `Denne mail er sendt til dig fra {companyName} via software leveret af CustomOffice ApS og kan derfor ikke besvares.
Ønsker du at svare på denne mail eller ønsker du ikke at modtage denne type mails fremover, skal du henvende dig til {companyName}.`,
    id: "printout-card.label.mail-default-message",
  },
  printouts: {
    defaultMessage: "Udskrifter, log",
    id: "task-instance.card-title.printouts",
  },
  sendingMail: {
    defaultMessage: "Sender mail",
    id: "printout-card.label.sending-mail",
  },
});

const addButtonStyle = {
  position: "absolute",
  right: 23,
  top: 23,
} as const;
const buttonPaddingWidth = 24;
const buttonWidth = 48;
const buttonColumnWidth =
  buttonPaddingWidth + buttonWidth + buttonWidth + buttonWidth + buttonPaddingWidth;

interface PrintoutRowProps {
  onMailButtonClick: (url: string) => void;
  reportingPrintout: ReportingPrintout;
  shareToken: string | null;
  userUserProfileLookup: (url: UserUrl) => UserProfile | undefined;
}

class PrintoutRow extends PureComponent<PrintoutRowProps> {
  @bind
  handleMailButtonClick(): void {
    this.props.onMailButtonClick(this.props.reportingPrintout.url);
  }
  render(): JSX.Element {
    const {reportingPrintout, shareToken, userUserProfileLookup} = this.props;
    const pdfDownloadURL = reportingPrintout.pdfDownload;
    const simplePdfDownloadURL = reportingPrintout.simplePdfDownload;
    const csvDownloadURL = reportingPrintout.csvDownload;
    let pendingSpinner;
    let pdfDownloadButton;
    let simplePdfDownloadButton;
    let csvDownloadButton;
    let mailButton: JSX.Element | undefined;
    if (!pdfDownloadURL && !csvDownloadURL) {
      pendingSpinner = (
        <div style={{position: "relative"}}>
          <CircularProgress />
        </div>
      );
    } else {
      pdfDownloadButton = (
        <IconButton color="primary" href={`${pdfDownloadURL}?token=${shareToken}`} target="_blank">
          <FilePdfIcon />
        </IconButton>
      );
      if (simplePdfDownloadURL) {
        simplePdfDownloadButton = (
          <IconButton
            color="primary"
            href={`${simplePdfDownloadURL}?token=${shareToken}`}
            target="_blank"
          >
            <FileDocumentIcon />
          </IconButton>
        );
      }
      csvDownloadButton = (
        <IconButton color="primary" href={`${csvDownloadURL}?token=${shareToken}`} target="_blank">
          <FileDelimitedIcon />
        </IconButton>
      );
      mailButton = (
        <IconButton color="primary" onClick={this.handleMailButtonClick}>
          <MailIcon />
        </IconButton>
      );
    }
    const createdByURL = reportingPrintout.createdBy;
    const createdByProfile = userUserProfileLookup(createdByURL);
    return (
      <TableRow key={reportingPrintout.url}>
        <TableCell>{createdByProfile ? createdByProfile.alias : null}</TableCell>
        <TableCell>
          {formatDate(reportingPrintout.deviceTimestamp)}
          &nbsp;
          {formatTime(reportingPrintout.deviceTimestamp)}
        </TableCell>
        <TableCell
          style={{
            width: buttonColumnWidth + (simplePdfDownloadURL ? buttonWidth : 0),
          }}
        >
          {pendingSpinner}
          {simplePdfDownloadButton}
          {pdfDownloadButton}
          {csvDownloadButton}
          {mailButton}
        </TableCell>
      </TableRow>
    );
  }
}

interface ReportingPrintoutCardStateProps {
  contactArrayPerCustomer: ReadonlyMap<string, readonly Contact[]>;
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  customerSettings: Config;
  orderLookup: (url: OrderUrl) => Order | undefined;
  reportingPrintoutArray: readonly ReportingPrintout[];
  shareToken: string | null;
  taskLookup: (url: TaskUrl) => Task | undefined;
  userUserProfileLookup: (url: UserUrl) => UserProfile | undefined;
}

interface ReportingPrintoutCardOwnProps {
  logTitle: string;
  onRequestBuildReports: () => void;
  task: Task;
}
type ReportingPrintoutCardProps = ReportingPrintoutCardOwnProps & ReportingPrintoutCardStateProps;

interface ReportingPrintoutCardState {
  mailError: boolean;
  mailReportDialogOpenFor: string | null;
  sendingMail: boolean;
}

class ReportingPrintoutCard extends PureComponent<
  ReportingPrintoutCardProps,
  ReportingPrintoutCardState
> {
  state = {
    mailError: false,
    mailReportDialogOpenFor: null,
    sendingMail: false,
  };
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  @bind
  handleMailClick(url: string): void {
    this.setState({mailReportDialogOpenFor: url});
  }
  @bind
  handleMailReportDialogCancel(): void {
    this.setState({mailReportDialogOpenFor: null});
  }
  _sending: boolean = false;

  @bind
  handleMailReportDialogOk(subject: string, message: string, recipient: string): void {
    const {mailReportDialogOpenFor} = this.state;
    if (this._sending || !mailReportDialogOpenFor) {
      return;
    }
    this._sending = true;

    const {baseURL} = globalConfig;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const reportId = urlToId(mailReportDialogOpenFor!);
    const url = `${baseURL}/api/mail/generic/${reportId}`;
    this.setState({mailReportDialogOpenFor: null, sendingMail: true});
    jsonFetch(url, "POST", {
      message,
      recipient,
      subject,
    })
      .then(() => {
        this._sending = false;
        this.setState({
          sendingMail: false,
        });
        return;
      })
      .catch(() => {
        this._sending = false;
        this.setState({
          mailError: true,
          sendingMail: false,
        });
      });
  }

  @bind
  handleMailErrorDialogOk(): void {
    this.setState({mailError: false});
  }

  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {
      contactArrayPerCustomer,
      customerLookup,
      orderLookup,
      reportingPrintoutArray,
      shareToken,
      task,
      taskLookup,
      userUserProfileLookup,
    } = this.props;
    const taskURL = task.url;
    const recipient = getCustomerMailAddress(taskURL, {
      contactArrayPerCustomer,
      customerLookup,
      orderLookup,
      taskLookup,
    });
    const tableRows = _.sortBy(
      reportingPrintoutArray.filter((p) => p.task === taskURL),
      (p) => p.serverTimestamp,
    )
      .reverse()
      .map((printout) => (
        <PrintoutRow
          key={printout.url}
          reportingPrintout={printout}
          shareToken={shareToken}
          userUserProfileLookup={userUserProfileLookup}
          onMailButtonClick={this.handleMailClick}
        />
      ));
    return (
      <>
        <Card style={{margin: "1em"}}>
          <div style={{position: "relative"}}>
            <Fab size="small" style={addButtonStyle} onClick={this.props.onRequestBuildReports}>
              <PlusIcon />
            </Fab>
          </div>
          <CardHeader title={formatMessage(messages.printouts)} />
          <CardMedia>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <FormattedMessage
                      defaultMessage="Udskrevet af"
                      id="log-printout.table-header.printed-by"
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage
                      defaultMessage="Tidspunkt"
                      id="log-printout.table-header.printed-on"
                    />
                  </TableCell>
                  <TableCell style={{width: buttonColumnWidth}}>
                    <FormattedMessage
                      defaultMessage="Download"
                      id="log-printout.table-header.download"
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{tableRows}</TableBody>
            </Table>
          </CardMedia>
        </Card>
        <MailReportDialog
          defaultMessage={formatMessage(messages.mailMessage, {
            companyName: this.props.customerSettings.companyName,
          })}
          logName={this.props.logTitle}
          open={!!this.state.mailReportDialogOpenFor}
          recipient={recipient}
          onCancel={this.handleMailReportDialogCancel}
          onOk={this.handleMailReportDialogOk}
        />
        <SpinnerDialog open={this.state.sendingMail} title={formatMessage(messages.sendingMail)} />
        <ResponsiveDialog
          open={this.state.mailError === true}
          title={formatMessage(messages.mailError)}
          onOk={this.handleMailErrorDialogOk}
        />
      </>
    );
  }
}

const ConnectedReportingPrintoutCard: React.ComponentType<ReportingPrintoutCardOwnProps> = connect<
  ReportingPrintoutCardStateProps,
  object,
  ReportingPrintoutCardOwnProps,
  AppState
>(
  createStructuredSelector<AppState, ReportingPrintoutCardStateProps>({
    contactArrayPerCustomer: getSortedActiveContactArrayPerCustomer,
    customerLookup: getCustomerLookup,
    customerSettings: getCustomerSettings,
    orderLookup: getOrderLookup,
    reportingPrintoutArray: getReportingPrintoutArray,
    shareToken: getShareToken,
    taskLookup: getTaskLookup,
    userUserProfileLookup: getUserUserProfileLookup,
  }),
  {},
)(ReportingPrintoutCard);

export {ConnectedReportingPrintoutCard as ReportingPrintoutCard};
