import {SettingID} from "@co-common-libs/config";
import {urlToId} from "@co-common-libs/resources";
import {ConnectedCustomerDialog} from "@co-frontend-libs/connected-components";
import {
  actions,
  getCurrentUserURL,
  getSettingsEntryLookupByIdentifier,
} from "@co-frontend-libs/redux";
import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";

interface SettingDialogProps {
  onClose: () => void;
  open: boolean;
  settingID: SettingID;
}

export function SelectCustomerId(props: SettingDialogProps): JSX.Element {
  const {onClose, open, settingID} = props;
  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const settingEntry = settingsEntryLookupByIdentifier(settingID);

  const dispatch = useDispatch();
  const currentUserURL = useSelector(getCurrentUserURL);
  const handleCustomerDialogOkOrNone = useCallback(
    (customerUrl: string | null = null) => {
      const newValue = customerUrl !== null ? urlToId(customerUrl) : null;
      if (newValue === settingEntry?.data) {
        onClose();
        return;
      }
      if (settingEntry) {
        dispatch(
          actions.update(settingEntry.url, [
            {member: "changedBy", value: currentUserURL},
            {member: "data", value: newValue},
          ]),
        );
      }
      onClose();
    },
    [settingEntry, onClose, dispatch, currentUserURL],
  );

  return (
    <ConnectedCustomerDialog
      open={open}
      onCancel={onClose}
      onNone={handleCustomerDialogOkOrNone}
      onOk={handleCustomerDialogOkOrNone}
    />
  );
}
