import {Role} from "@co-common-libs/resources";
import {MINUTE_MILLISECONDS, dateToString} from "@co-common-libs/utils";
import {
  getCurrentRole,
  getCustomerSettings,
  getDaysAbsenceArray,
  getExtraHalfHolidaysPerRemunerationGroup,
  getExtraHolidaysPerRemunerationGroup,
  getHoursAbsenceArray,
  getRoleArray,
  getUpdateReady,
  getUserArray,
  getUserUserProfileLookup,
} from "@co-frontend-libs/redux";
import {common as commonColors} from "@material-ui/core/colors";
import {updateAutoReloadCallback} from "app-utils";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import AbsenceTabContentWithTheme from "./task-calendar/absence-tab";

export const FullScreenAbsenceCalendar = React.memo(
  function FullScreenAbsenceCalendar(): JSX.Element {
    const updateReady = useSelector(getUpdateReady);

    useEffect(() => {
      if (updateReady) {
        updateAutoReloadCallback();
      }
    }, [updateReady]);

    const currentRole = useSelector(getCurrentRole);
    const mutablecurrentRole: Role | undefined = currentRole ?? undefined;
    const customerSettings = useSelector(getCustomerSettings);
    const daysAbsenceArray = useSelector(getDaysAbsenceArray);
    const extraHolidaysPerRemunerationGroup = useSelector(getExtraHolidaysPerRemunerationGroup);
    const extraHalfHolidaysPerRemunerationGroup = useSelector(
      getExtraHalfHolidaysPerRemunerationGroup,
    );
    const hoursAbsenceArray = useSelector(getHoursAbsenceArray);
    const roleArray = useSelector(getRoleArray);
    const userArray = useSelector(getUserArray);
    const userUserProfileLookup = useSelector(getUserUserProfileLookup);

    const [today, setToday] = useState(dateToString(new Date()));

    useEffect(() => {
      const intervalID = window.setInterval(
        () => setToday(dateToString(new Date())),
        MINUTE_MILLISECONDS,
      );
      return () => {
        window.clearInterval(intervalID);
      };
    }, []);

    return (
      <div style={{backgroundColor: commonColors.white, height: "100%"}}>
        <div className="scrollable" style={{height: "100%", width: "100%"}}>
          <AbsenceTabContentWithTheme
            fullscreen
            currentRole={mutablecurrentRole}
            customerSettings={customerSettings}
            daysAbsenceArray={daysAbsenceArray}
            extraHalfHolidaysPerRemunerationGroup={extraHalfHolidaysPerRemunerationGroup}
            extraHolidaysPerRemunerationGroup={extraHolidaysPerRemunerationGroup}
            hoursAbsenceArray={hoursAbsenceArray}
            roleArray={roleArray}
            selectedDate={today}
            userArray={userArray}
            userUserProfileLookup={userUserProfileLookup}
          />
        </div>
      </div>
    );
  },
);
