import {WorkType, WorkTypeUrl} from "@co-common-libs/resources";
import {getWorkTypeString} from "@co-common-libs/resources-utils";
import {MultipleWorkTypesDialog} from "@co-frontend-libs/components";
import {
  actions,
  getCurrentUserURL,
  getSettingsEntryLookupByIdentifier,
  getWorkTypeArray,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import React, {useCallback, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {SettingViewProps} from "../types";
import {DisplaySelected} from "./display-selected";

export function SelectAllWorktypes(props: SettingViewProps): JSX.Element {
  const {settingID, settingMetaData} = props;
  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const settingEntry = settingsEntryLookupByIdentifier(settingID);
  const workTypes: string[] = useMemo(() => settingEntry?.data || [], [settingEntry?.data]);
  const worktypeArray = useSelector(getWorkTypeArray);
  const worktypeLookup = useSelector(getWorkTypeLookup);
  const workTypeInstances = useMemo(() => {
    return workTypes
      .map((identifier) => worktypeArray.find((w) => w.identifier === identifier))
      .filter(Boolean);
  }, [workTypes, worktypeArray]) as WorkType[];

  const workTypeNames = useMemo(() => {
    const names = workTypeInstances.map((workType) => getWorkTypeString(workType));
    names.sort();
    return names;
  }, [workTypeInstances]);

  const [workTypeDialogOpen, setWorkTypeDialogOpen] = useState(false);
  const setWorkTypeDialogOpenTrue = useCallWithTrue(setWorkTypeDialogOpen);
  const setWorkTypeDialogOpenFalse = useCallWithFalse(setWorkTypeDialogOpen);

  const dispatch = useDispatch();
  const currentUserURL = useSelector(getCurrentUserURL);
  const handleWorkTypeDialogOk = useCallback(
    (urls: ReadonlySet<WorkTypeUrl>) => {
      setWorkTypeDialogOpen(false);

      const newValue = [...urls].map((url) => worktypeLookup(url)?.identifier);
      if (settingEntry) {
        dispatch(
          actions.update(settingEntry.url, [
            {member: "changedBy", value: currentUserURL},
            {member: "data", value: newValue},
          ]),
        );
      }
    },
    [currentUserURL, dispatch, settingEntry, worktypeLookup],
  );

  return (
    <>
      <DisplaySelected
        currentValue={workTypeNames}
        settingEntry={settingEntry}
        settingID={settingID}
        settingMetaData={settingMetaData}
        onClick={setWorkTypeDialogOpenTrue}
      />
      <MultipleWorkTypesDialog
        currentUserURL={null}
        disabledWorkTypes={[]}
        open={workTypeDialogOpen}
        selected={new Set(workTypeInstances.map((workType) => workType?.url))}
        suggestRecentlyUsedWorkTypes={false}
        workTypeArray={worktypeArray}
        onCancel={setWorkTypeDialogOpenFalse}
        onOk={handleWorkTypeDialogOk}
      />
    </>
  );
}
