import _ from "lodash";
import {
  isEconomicMultiInstanceIssue,
  isEconomicSingleInstanceIssue,
} from "./economic-issue-type-guards";
import {EconomicIssue} from "./types";

export function economicIssuesEqual(issueA: EconomicIssue, issueB: EconomicIssue): boolean {
  if (isEconomicSingleInstanceIssue(issueA) && isEconomicSingleInstanceIssue(issueB)) {
    return (
      issueA.category === issueB.category &&
      issueA.resourceName === issueB.resourceName &&
      issueA.issueType === issueB.issueType &&
      issueA.instance === issueB.instance
    );
  } else if (isEconomicMultiInstanceIssue(issueA) && isEconomicMultiInstanceIssue(issueB)) {
    if (issueA.instances.length !== issueB.instances.length) {
      return false;
    }
    const sortedInstancesA = _.sortBy(issueA.instances, (instance) => instance.url);
    const sortedInstancesB = _.sortBy(issueB.instances, (instance) => instance.url);
    return (
      issueA.category === issueB.category &&
      issueA.resourceName === issueB.resourceName &&
      issueA.issueType === issueB.issueType &&
      sortedInstancesA.every((instance, index) => instance === sortedInstancesB[index])
    );
  } else {
    return false;
  }
}
