import {Task, TaskUrl, TimerStart, TimerUrl} from "@co-common-libs/resources";
import {computeIntervalsTruncated} from "app-utils";

export function getComputedTime(
  task: Task,
  timerStartArray: readonly TimerStart[],
  nowTimestamp: string,
): readonly {
  readonly fromTimestamp: string;
  readonly task?: TaskUrl;
  readonly timer: TimerUrl;
  readonly toTimestamp: string;
}[] {
  if (task.recordedInC5) {
    return task.computedTimeSet;
  } else {
    const taskURL = task.url;
    const taskTimerStartSeq = timerStartArray.filter((instance) => instance.task === taskURL);
    return computeIntervalsTruncated(taskTimerStartSeq, nowTimestamp);
  }
}
