import {SettingID, settings} from "@co-common-libs/config";
import {emptyOrder} from "@co-common-libs/resources";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {
  actions,
  getCurrentUserURL,
  getSettingsEntryLookupByIdentifier,
} from "@co-frontend-libs/redux";
import {
  Checkbox,
  DialogContent,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {useEventTargetCheckedCallback} from "app-utils";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

export const orderCopyExcludes = ["validatedAndRecorded"];

type OrderCopyFieldsMember = keyof typeof emptyOrder;
type OrderCopyFields = {[member in OrderCopyFieldsMember]: boolean};

const OrderCopyFieldRow = ({
  fieldName,
  onChange,
  value,
}: {
  fieldName: OrderCopyFieldsMember;
  onChange: (value: boolean, field: OrderCopyFieldsMember) => void;
  value: boolean;
}): JSX.Element => {
  const handeEnableChanged = useCallback(
    (checked: boolean) => {
      onChange(checked, fieldName);
    },
    [fieldName, onChange],
  );

  return (
    <TableRow>
      <TableCell style={{width: 200}}>{fieldName}</TableCell>
      <TableCell>
        <FormControl>
          <Checkbox
            checked={value}
            onChange={useEventTargetCheckedCallback(handeEnableChanged, [handeEnableChanged])}
          />
        </FormControl>
      </TableCell>
    </TableRow>
  );
};

interface OrderCopyFieldsDialogProps {
  onClose: () => void;
  open: boolean;
  settingID: SettingID;
}

export function OrderCopyFieldsDialog(props: OrderCopyFieldsDialogProps): JSX.Element {
  const {onClose, open, settingID} = props;

  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const settingEntry = settingsEntryLookupByIdentifier(settingID);

  const settingMeta = settings[settingID];

  const initialOrderCopyFields: OrderCopyFields = useMemo(() => {
    const data: OrderCopyFieldsMember[] = settingEntry?.data || settingMeta.schema.default;
    const newOrderCopyFields: Partial<OrderCopyFields> = {};
    const orderKeys = Object.keys(emptyOrder) as OrderCopyFieldsMember[];
    data.forEach((key) => {
      if (!orderKeys.includes(key)) {
        // eslint-disable-next-line no-console
        console.error(`'${key}' not in emptyOrder object`);
      }
    });
    orderKeys.sort();
    orderKeys.forEach((key) => {
      if (!orderCopyExcludes.includes(key)) {
        newOrderCopyFields[key] = data.includes(key);
      }
    });
    return newOrderCopyFields as OrderCopyFields;
  }, [settingEntry?.data, settingMeta.schema.default]);

  const [orderCopyFields, setOrderCopyFields] = useState(initialOrderCopyFields);
  useEffect(() => {
    if (open) {
      setOrderCopyFields(initialOrderCopyFields);
    }
  }, [initialOrderCopyFields, open]);

  const dispatch = useDispatch();
  const currentUserURL = useSelector(getCurrentUserURL);

  const handleSave = useCallback(() => {
    const newValues: OrderCopyFieldsMember[] = [];
    Object.entries(orderCopyFields).forEach(([field, value]) => {
      if (value) {
        newValues.push(field as OrderCopyFieldsMember);
      }
    });
    if (settingEntry) {
      dispatch(
        actions.update(settingEntry.url, [
          {member: "changedBy", value: currentUserURL},
          {member: "data", value: newValues},
        ]),
      );
    }
    onClose();
  }, [currentUserURL, dispatch, onClose, settingEntry, orderCopyFields]);

  const handeEnableChanged = useCallback(
    (value: boolean, field: OrderCopyFieldsMember) => {
      setOrderCopyFields(() => ({...orderCopyFields, [field]: value}));
    },
    [orderCopyFields],
  );

  return (
    <ResponsiveDialog
      fullWidth
      okLabel={<FormattedMessage defaultMessage="Gem" id="setting-dialog.label.save" />}
      open={open}
      title={
        <FormattedMessage
          defaultMessage="Vælg felter"
          id="system-setup.dialog-title.select-fields"
        />
      }
      onCancel={onClose}
      onOk={handleSave}
    >
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{width: 200}}>
                <FormattedMessage defaultMessage="Navn" id="system-setup.dialog-title.name" />
              </TableCell>
              <TableCell>
                <FormattedMessage defaultMessage="Kopieres" id="system-setup.dialog-title.copy" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(Object.entries(orderCopyFields) as [OrderCopyFieldsMember, boolean][]).map(
              ([field, value]) => (
                <OrderCopyFieldRow
                  key={field}
                  fieldName={field}
                  value={value}
                  onChange={handeEnableChanged}
                />
              ),
            )}
          </TableBody>
        </Table>
      </DialogContent>
    </ResponsiveDialog>
  );
}
