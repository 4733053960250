import {Config} from "@co-common-libs/config";
import {frontendVersion} from "@co-common-libs/frontend-version";
import {
  ResourceTypeUnion,
  Role,
  Timer,
  TimerStart,
  TimerUrl,
  User,
  UserProfile,
  UserUrl,
} from "@co-common-libs/resources";
import {getActiveTimerStart, getNormalisedDeviceTimestamp} from "@co-common-libs/resources-utils";
import {
  AppState,
  actions,
  getCustomerSettings,
  getRoleArray,
  getTimerArray,
  getTimerLookup,
  getTimerStartArray,
  getUpdateReady,
  getUserArray,
  getUserUserProfileLookup,
} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {Button, Theme, createStyles, darken, makeStyles} from "@material-ui/core";
import {ClearAppDialog} from "app-components";
import {getBreakTimer, saveTimerStart, updateAutoReloadCallback} from "app-utils";
import {globalConfig} from "frontend-global-config";
import _ from "lodash";
import React, {useCallback, useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import {connect, useDispatch, useSelector} from "react-redux";
import {createStructuredSelector} from "reselect";

interface ButtonStylingProps {
  backgroundColor?: string;
}

const HOVER_DARKEN_FACTOR = 1.5;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:hover": {
        backgroundColor: (props: ButtonStylingProps) =>
          props.backgroundColor
            ? darken(
                props.backgroundColor,
                (typeof theme.palette.tonalOffset === "number"
                  ? theme.palette.tonalOffset
                  : theme.palette.tonalOffset[theme.palette.type]) * HOVER_DARKEN_FACTOR,
              )
            : undefined,
      },
      backgroundColor: (props: ButtonStylingProps) => props.backgroundColor,
      color: (props: ButtonStylingProps) =>
        props.backgroundColor ? theme.palette.getContrastText(props.backgroundColor) : undefined,
      height: 64,
      margin: 16,
    },
  }),
);

interface UserButtonProps {
  backgroundColor?: string | undefined;
  disabled: boolean;
  nextClickTimerURL?: TimerUrl | undefined;
  onSelect: (user: UserProfile, nextClickTimerURL?: TimerUrl) => void;
  userProfile: UserProfile;
}

function UserButton(props: UserButtonProps): JSX.Element {
  const {backgroundColor, disabled, nextClickTimerURL, onSelect, userProfile} = props;

  const handleClick = useCallback(() => {
    onSelect(userProfile, nextClickTimerURL);
  }, [nextClickTimerURL, onSelect, userProfile]);

  const classes = useStyles(backgroundColor ? {backgroundColor} : {});
  return (
    <Button className={classes.root} disabled={disabled} variant="contained" onClick={handleClick}>
      {userProfile.alias}
    </Button>
  );
}

interface CheckOutStateProps {
  customerSettings: Config;
  roleArray: readonly Role[];
  timerArray: readonly Timer[];
  timerLookup: (url: TimerUrl) => Timer | undefined;
  timerStartArray: readonly TimerStart[];
  userArray: readonly User[];
  userUserProfileLookup: (url: UserUrl) => UserProfile | undefined;
}

interface CheckOutDispatchProps {
  create: (instance: ResourceTypeUnion) => void;
}

type CheckOutProps = CheckOutDispatchProps & CheckOutStateProps;

const CheckOut = React.memo(function CheckOut(props: CheckOutProps): JSX.Element {
  const {create, timerStartArray, userUserProfileLookup} = props;
  const updateReady = useSelector(getUpdateReady);

  useEffect(() => {
    if (updateReady) {
      updateAutoReloadCallback();
    }
  }, [updateReady]);

  const handleUserSelect = useCallback(
    (userProfile: UserProfile, nextClickTimerURL?: TimerUrl) => {
      const userURL = userProfile.user;
      const timerStart = getActiveTimerStart(userURL, timerStartArray);
      if (timerStart && timerStart.timer !== nextClickTimerURL) {
        const taskURL = timerStart.task;
        saveTimerStart(create, nextClickTimerURL || null, taskURL, userURL, null);
      }
    },
    [create, timerStartArray],
  );

  const userRoleMap = new Map<string, Role>();
  props.roleArray.forEach((role) => {
    userRoleMap.set(role.user, role);
  });
  const userList = _.sortBy(
    props.userArray
      .filter((user) => user.active)
      .filter((user) => {
        const role = userRoleMap.get(user.url);
        return !role || (!role.breakRoom && !role.consultant);
      }),
    (user) => userUserProfileLookup(user.url)?.alias || user.loginIdentifier,
  );

  const sortedTimerStartArray = _.sortBy(props.timerStartArray, getNormalisedDeviceTimestamp);
  const lastActiveTaskPerUser = new Map<string, string>();
  sortedTimerStartArray.forEach((timerStart) => {
    if (timerStart.timer) {
      // the *last* task with activity will win
      lastActiveTaskPerUser.set(timerStart.employee, timerStart.task);
    }
  });
  const userLastTimerStarts = new Map<string, {current: TimerStart; previous: TimerStart | null}>();
  sortedTimerStartArray.reverse().forEach((instance) => {
    const employeeURL = instance.employee;
    if (lastActiveTaskPerUser.get(employeeURL) !== instance.task) {
      return;
    }
    const employeeTimerStarts = userLastTimerStarts.get(employeeURL);
    if (!employeeTimerStarts) {
      userLastTimerStarts.set(employeeURL, {
        current: instance,
        previous: null,
      });
    } else {
      if (!employeeTimerStarts.previous) {
        if (employeeTimerStarts.current.task === instance.task) {
          employeeTimerStarts.previous = instance;
        }
      }
    }
  });
  const breakTimer = getBreakTimer(props.timerArray) as Timer;
  const breakTimerURL = breakTimer.url;
  const buttonList = userList.map((user) => {
    const userURL = user.url;
    const employeeTimerStarts = userLastTimerStarts.get(userURL);
    const currentTimerStart = employeeTimerStarts ? employeeTimerStarts.current : null;
    const currentTimerURL = currentTimerStart ? currentTimerStart.timer : null;
    const currentTimer = currentTimerURL ? props.timerLookup(currentTimerURL) : null;
    const backgroundColor = currentTimer ? currentTimer.color : undefined;
    let nextClickTimerURL;
    if (currentTimerURL === breakTimerURL) {
      const previousTimerStart = employeeTimerStarts && employeeTimerStarts.previous;
      if (previousTimerStart) {
        nextClickTimerURL = previousTimerStart.timer;
      }
    } else {
      nextClickTimerURL = breakTimerURL;
    }
    const disabled = !currentTimerURL || !nextClickTimerURL;
    if (props.customerSettings.checkoutHideInactive && disabled) {
      return null;
    }
    const userProfile = userUserProfileLookup(user.url);
    if (!userProfile) {
      return null;
    }
    return (
      <UserButton
        key={userURL}
        backgroundColor={backgroundColor}
        disabled={disabled}
        nextClickTimerURL={nextClickTimerURL || undefined}
        userProfile={userProfile}
        onSelect={handleUserSelect}
      />
    );
  });

  const [clearAppDialogOpen, setClearAppDialogOpen] = useState(false);
  const setClearAppDialogOpenTrue = useCallWithTrue(setClearAppDialogOpen);
  const setClearAppDialogOpenFalse = useCallWithFalse(setClearAppDialogOpen);

  const dispatch = useDispatch();
  const handleLogoutClick = useCallback(() => dispatch(actions.logout(true)), [dispatch]);

  return (
    <div style={{backgroundColor: "#fff", height: "100%", padding: "1em"}}>
      <div style={{bottom: 10, left: 10, position: "fixed"}}>
        v. {frontendVersion}
        <Button variant="text" onClick={setClearAppDialogOpenTrue}>
          {window.cordova ? (
            <FormattedMessage defaultMessage="Nulstil app" />
          ) : (
            <FormattedMessage defaultMessage="Nulstil CustomOffice" />
          )}
        </Button>
      </div>
      <Button
        style={{bottom: 10, position: "fixed", right: 10}}
        variant="text"
        onClick={handleLogoutClick}
      >
        <FormattedMessage defaultMessage="Log ud" />
      </Button>

      <div style={{marginBottom: 36, marginTop: 24, textAlign: "center"}}>
        <img src={globalConfig.logoURL} style={{width: "256px"}} />
      </div>
      <div style={{marginBottom: 36, textAlign: "center"}}>{buttonList}</div>
      <div style={{textAlign: "center"}}>
        <FormattedMessage
          defaultMessage="Meld pause / færdig med pause"
          id="check-out.header.report-break"
          tagName="h1"
        />
      </div>
      <ClearAppDialog open={clearAppDialogOpen} onCancel={setClearAppDialogOpenFalse} />
    </div>
  );
});

const ConnectedCheckOut = connect<CheckOutStateProps, CheckOutDispatchProps, object, AppState>(
  createStructuredSelector<AppState, CheckOutStateProps>({
    customerSettings: getCustomerSettings,
    roleArray: getRoleArray,
    timerArray: getTimerArray,
    timerLookup: getTimerLookup,
    timerStartArray: getTimerStartArray,
    userArray: getUserArray,
    userUserProfileLookup: getUserUserProfileLookup,
  }),
  {
    create: actions.create,
  },
)(CheckOut);

export default ConnectedCheckOut;
