import {createReducer} from "@reduxjs/toolkit";
import * as actions from "./actions";

export type DialogState = {
  readonly content: string;
  readonly open: boolean;
  readonly title: string;
};

const initialState: DialogState = {
  content: "",
  open: false,
  title: "",
};

export const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(actions.showDialog, (_state, action) => ({
      ...action.payload,
      open: true,
    }))
    .addCase(actions.closeDialog, (state) => {
      state.open = false;
    }),
);
