import {Button, SvgIcon} from "@material-ui/core";
import CloudDownloadIcon from "mdi-react/CloudDownloadIcon";
import React, {useMemo} from "react";

export const EconomicDownloadButton = React.memo(function EconomicDownloadButton({
  children,
  downloading,
  onClick,
  style,
}: {
  children?: React.ReactNode;
  downloading: boolean;
  onClick: () => void;
  style?: React.CSSProperties;
}): JSX.Element {
  const downloadIconClassName = downloading ? "pulse" : "";
  const downloadIcon = (
    <SvgIcon className={downloadIconClassName}>
      <CloudDownloadIcon />
    </SvgIcon>
  );

  const styles = useMemo(() => style || {}, [style]);

  return (
    <Button
      color="primary"
      disabled={downloading}
      startIcon={downloadIcon}
      style={styles}
      variant="contained"
      onClick={onClick}
    >
      {children}
    </Button>
  );
});
