import {FieldUse, Location, LocationUrl} from "@co-common-libs/resources";

export const getFieldBoundingBox = (field: Location): [number, number, number, number] => {
  if (!field.geojson) {
    throw new Error("not a field");
  }
  return field.geojson.geometry.bbox;
};

const labelVisibilityThreshold = 14;
export const labelsVisible = (zoomLevel: number): boolean => zoomLevel >= labelVisibilityThreshold;

export const getUpdatedFieldUseList = (
  fieldURLSet: ReadonlySet<LocationUrl>,
  oldSelected: readonly FieldUse[],
  locationLookup: (url: LocationUrl) => Location | undefined,
): readonly FieldUse[] => {
  let oldSelectedList = oldSelected || [];
  fieldURLSet.forEach((url) => {
    const inList = oldSelectedList.some((selected) => selected.relatedField === url);
    if (!inList) {
      const location = locationLookup(url);
      oldSelectedList = [
        ...oldSelectedList,
        {
          fieldAreaHa: location?.fieldAreaHa ?? null,
          fieldCrop: location?.fieldCrop ?? "",
          geojson: location?.geojson ?? null,
          notes: "",
          relatedField: url,
        },
      ];
    }
  });
  let fieldUseList = oldSelectedList;
  oldSelectedList.forEach((fieldUse) => {
    const inList = fieldURLSet.has(fieldUse.relatedField);
    if (!inList) {
      fieldUseList = fieldUseList.filter(
        (selected) => selected.relatedField !== fieldUse.relatedField,
      );
    }
  });
  return fieldUseList;
};
