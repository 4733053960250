import {Patch, Task} from "@co-common-libs/resources";
import {ProvisionaryCommand} from "../../resources/actions";
import {getCustomerSettings, getMachineArray, getWorkTypeLookup} from "../../resources/selectors";
import {ResourcesAuthenticationMiddlewareAPI} from "../types";

export function addMachinesFromWorkTypeAutoMachines(
  newTask: Task | null,
  oldTask: Task | undefined,
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  _command: ProvisionaryCommand,
): Patch<Task> | null {
  if (!newTask) {
    return null;
  }
  if (
    newTask.recordedInC5 ||
    newTask.archivable ||
    !newTask.workType ||
    newTask.workType === oldTask?.workType
  ) {
    // only triggered on changes that set workType
    return null;
  }
  const state = middlewareApi.getState();
  const customerSettings = getCustomerSettings(state);
  if (!customerSettings.workTypeAutoMachines) {
    // feature not used
    return null;
  }
  const worktypeLookup = getWorkTypeLookup(state);
  const workType = worktypeLookup(newTask.workType);
  const autoMachineIDList = workType && customerSettings.workTypeAutoMachines[workType.identifier];
  if (!autoMachineIDList) {
    // not used for work type
    return null;
  }

  const machineArray = getMachineArray(state);
  const autoMachines = machineArray.filter(
    (machine) => machine.active && autoMachineIDList.includes(machine.c5_machine),
  );

  const oldMachineuseSet = newTask.machineuseSet || [];
  const machineuseSet = [...oldMachineuseSet];
  autoMachines.forEach((machine) => {
    if (!machineuseSet.some((machineUse) => machineUse.machine === machine.url)) {
      machineuseSet.push({
        machine: machine.url,
        priceGroup: null,
        transporter: false,
      });
    }
  });

  return [{member: "machineuseSet", value: machineuseSet}];
}
