import {Config} from "@co-common-libs/config";
import {
  DinnerBooking,
  LunchBooking,
  Patch,
  PatchUnion,
  ResourceTypeUnion,
  UserUrl,
} from "@co-common-libs/resources";
import {instanceURL} from "frontend-global-config";
import {v4 as uuid} from "uuid";

export function updateDinnerLunchBooking(
  customerSettings: Config,
  dinnerBookingArray: readonly DinnerBooking[],
  lunchBookingArray: readonly LunchBooking[],
  create: (instance: ResourceTypeUnion) => void,
  update: (url: string, patch: PatchUnion) => void,
  date: string,
  employeeURL: UserUrl,
  count: number,
  lunchCount: number | null,
  dinnerLocation: string | null,
  lunchLocation: string | null,
): void {
  const now = new Date();
  const time = now.toISOString();
  const existingBooking = dinnerBookingArray.find((entry) => {
    return entry.user === employeeURL && entry.date === date;
  });
  if (existingBooking) {
    if (count !== existingBooking.count || (dinnerLocation || "") !== existingBooking.location) {
      const patch: Patch<DinnerBooking> = [
        {member: "count", value: count},
        {member: "location", value: dinnerLocation || ""},
        {member: "time", value: time},
      ];
      update(existingBooking.url, patch);
    }
  } else {
    const id = uuid();
    const url = instanceURL("dinnerBooking", id);
    const obj = {
      count,
      date,
      id,
      location: dinnerLocation || "",
      time,
      url,
      user: employeeURL,
    };
    create(obj);
  }
  if (customerSettings.lunchBookings && lunchCount != null) {
    const lunchExistingBooking = lunchBookingArray.find((entry) => {
      return entry.user === employeeURL && entry.date === date;
    });
    if (lunchExistingBooking) {
      if (
        lunchCount !== lunchExistingBooking.count ||
        (lunchLocation || "") !== lunchExistingBooking.location
      ) {
        const patch: Patch<LunchBooking> = [
          {member: "count", value: lunchCount},
          {member: "location", value: lunchLocation || ""},
          {member: "time", value: time},
        ];
        update(lunchExistingBooking.url, patch);
      }
    } else {
      const id = uuid();
      const url = instanceURL("lunchBooking", id);
      const obj: LunchBooking = {
        count: lunchCount,
        date,
        id,
        location: lunchLocation || "",
        time,
        url,
        user: employeeURL,
      };
      create(obj);
    }
  }
}
