import {getCustomerSettings} from "@co-frontend-libs/redux";
import {Loader, LoaderStatus} from "@googlemaps/js-api-loader";
import {useRef, useState} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {LIBRARIES} from "./constants";
import {getGoogleMapsAPIKey, getLanguage} from "./state";

export function useLoadGoogleMaps(): {
  isLoaded: boolean;
  loadError: ErrorEvent | undefined;
} {
  const intl = useIntl();
  const customerSettings = useSelector(getCustomerSettings);

  // NOTE: will only read settings on first call; injecting Google Maps JS
  // again with different options disallowed by `Loader`.
  // (... and presumably a bad idea to do manually.)
  const apiKey = getGoogleMapsAPIKey(customerSettings);
  // NOTE: will only read language on first call.
  const language = getLanguage(intl);

  const loaderRef = useRef<Loader>();

  const initialRun = !loaderRef.current;

  if (!loaderRef.current) {
    loaderRef.current = new Loader({apiKey, language, libraries: LIBRARIES});
  }

  // `Loader` may have previously been used to load Google Maps JS;
  // in which case we report `isLoaded: true` immediately.
  const [isLoaded, setIsLoaded] = useState(loaderRef.current.status === LoaderStatus.SUCCESS);

  const [loadError, setLoadError] = useState<ErrorEvent>();

  if (initialRun && !isLoaded) {
    // Marked deprecated, but described in the README, and loading JS
    // as side effect from `.importLibrary()` seems messy.
    // Package types are wrong, README is right; callback will be
    // called without `ErrorEvent` on success.
    loaderRef.current.loadCallback((e: ErrorEvent | null | undefined) => {
      if (e) {
        setLoadError(e);
      } else {
        setIsLoaded(true);
      }
    });
  }

  return {isLoaded, loadError};
}
