import {LocationTypeUrl, LocationUrl, ProductUrl} from "@co-common-libs/resources";
import {Tab, Tabs} from "@material-ui/core";
import {
  LocationStorageCards,
  LocationStorageCardsByLocation,
  LocationStorageFilterCard,
  MenuToolbar,
  PageLayout,
} from "app-components";
import {useQueryParameter, useQueryParameterState} from "app-utils";
import {instanceURL} from "frontend-global-config";
import React, {useCallback, useState} from "react";
import {defineMessages, useIntl} from "react-intl";

const messages = defineMessages({
  title: {defaultMessage: "Lager", id: "location-storage.title.storage"},
});

interface LocationStorageProps {
  onMenuButton: (event: React.MouseEvent) => void;
}

export function LocationStorage(props: LocationStorageProps): JSX.Element {
  const [activeTab, setActiveTab] = useQueryParameterState<"locations" | "products">(
    "tab",
    "products",
  );

  const handleTabChange = useCallback(
    (_event: React.ChangeEvent<unknown>, value: string) => {
      setActiveTab(value as "locations" | "products");
    },
    [setActiveTab],
  );
  const intl = useIntl();
  const tabs = (
    <Tabs scrollButtons="auto" value={activeTab} variant="scrollable" onChange={handleTabChange}>
      <Tab label={intl.formatMessage({defaultMessage: "Varer"})} value="products" />
      <Tab label={intl.formatMessage({defaultMessage: "Steder"})} value="locations" />
    </Tabs>
  );

  const locationId = useQueryParameter("id");
  const locationURL = locationId ? instanceURL("location", locationId) : undefined;

  const {formatMessage} = useIntl();
  const [showZeroAmountLocations, setShowZeroAmountLocations] = useState(false);
  const [filterToConfiguredLocations, setFilterToConfiguredLocations] = useState(true);
  const [selectedProducts, setSelectedProducts] = useState<ReadonlySet<ProductUrl>>(
    new Set<ProductUrl>(),
  );
  const [selectedLocationTypes, setSelectedLocationTypes] = useState<ReadonlySet<LocationTypeUrl>>(
    new Set<LocationTypeUrl>(),
  );
  const [selectedLocations, setSelectedLocations] = useState<ReadonlySet<LocationUrl>>(
    locationURL ? new Set<LocationUrl>([locationURL]) : new Set<LocationUrl>(),
  );

  let content: JSX.Element | null = null;
  if (activeTab === "products") {
    content = (
      <LocationStorageCards
        filterToConfiguredLocations={!!selectedLocationTypes.size || filterToConfiguredLocations}
        selectedLocations={selectedLocations}
        selectedLocationTypes={selectedLocationTypes}
        selectedProducts={selectedProducts}
        showZeroAmountLocations={showZeroAmountLocations}
      />
    );
  } else {
    content = (
      <LocationStorageCardsByLocation
        filterToConfiguredLocations={!!selectedLocationTypes.size || filterToConfiguredLocations}
        selectedLocations={selectedLocations}
        selectedLocationTypes={selectedLocationTypes}
        selectedProducts={selectedProducts}
        showZeroAmountLocations={showZeroAmountLocations}
      />
    );
  }
  return (
    <PageLayout
      withBottomScrollPadding
      tabs={tabs}
      toolbar={
        <MenuToolbar title={formatMessage(messages.title)} onMenuButton={props.onMenuButton} />
      }
    >
      <LocationStorageFilterCard
        filterToConfiguredLocations={!!selectedLocationTypes.size || filterToConfiguredLocations}
        selectedLocations={selectedLocations}
        selectedLocationTypes={selectedLocationTypes}
        selectedProducts={selectedProducts}
        showZeroAmountLocations={showZeroAmountLocations}
        onFilterToConfiguredLocationsChange={setFilterToConfiguredLocations}
        onSelectedLocationsChange={setSelectedLocations}
        onSelectedLocationTypesChange={setSelectedLocationTypes}
        onSelectedProductsChange={setSelectedProducts}
        onShowZeroAmountLocationsChange={setShowZeroAmountLocations}
      />
      {content}
    </PageLayout>
  );
}
