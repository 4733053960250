import {useWindowSize} from "@co-frontend-libs/utils";
import {ListItem, ListItemIcon, ListItemText, Theme, useTheme} from "@material-ui/core";
import bowser from "bowser";
import PlusIcon from "mdi-react/PlusIcon";
import React, {useCallback, useEffect, useMemo, useRef} from "react";
import {
  ListChildComponentProps,
  ListOnScrollProps,
  VariableSizeList,
  VariableSizeListProps,
} from "react-window";
import {typedMemo} from "../../types";
import {useListStyles} from "../styles";
import {MaybeSearchResultEntryData} from "../types";
import {AllItem} from "./all-item";
import {MultiSelectionListItem} from "./multi-selection-list-item";

type SpecialEntry = "-ADD-" | "-ALL-";

const listItemTopPadding = 8;
const listItemBottomPadding = 8;
const listItemTextTopMargin = 6;
const listItemTextBottomMargin = 6;
const checkboxHeight = 42;

const minHeight = listItemTopPadding + listItemBottomPadding + checkboxHeight;

const itemTotalMarginPadding =
  listItemTopPadding + listItemBottomPadding + listItemTextTopMargin + listItemTextBottomMargin;

const primaryLineHeight = 24; // uses theme.typography.body1
const secondaryLineHeight = 20; // uses theme.typography.body2

interface RenderRowData<Identifier extends string> extends ListChildComponentProps {
  data: {
    readonly addLabel: string;
    readonly allSelected: boolean;
    readonly combinedEntries: readonly (MaybeSearchResultEntryData<Identifier> | SpecialEntry)[];
    readonly disableSecondaryText: boolean;
    readonly entries: readonly MaybeSearchResultEntryData<Identifier>[];
    readonly highlightClass: string;
    readonly isSearchResult: boolean;
    readonly multiSelectMax: number | undefined;
    readonly onAdd?: (() => void) | undefined;
    readonly onSelect: (identifier: Identifier, selected: boolean) => void;
    readonly onSelectMultiple?:
      | ((identifiers: ReadonlySet<Identifier>, selected: boolean) => void)
      | undefined;
    readonly primaryTextClass?: string | undefined;
    readonly secondaryTextClass?: string | undefined;
    readonly selected: ReadonlySet<Identifier>;
    readonly theme: Theme;
    readonly withIcons: boolean;
  };
}

function renderRow<Identifier extends string>(rowProps: RenderRowData<Identifier>): JSX.Element {
  const {data, index, style} = rowProps;
  const {
    addLabel,
    allSelected,
    combinedEntries,
    disableSecondaryText,
    entries,
    highlightClass,
    isSearchResult,
    multiSelectMax,
    onAdd,
    onSelect,
    onSelectMultiple,
    primaryTextClass,
    secondaryTextClass,
    selected,
    theme,
    withIcons,
  } = data;
  const entry = combinedEntries[index];

  if (entry === "-ALL-") {
    return (
      <AllItem
        allSelected={allSelected}
        entries={entries}
        isSearchResult={isSearchResult}
        multiSelectMax={multiSelectMax}
        style={{
          borderBottom: `1px solid ${theme.palette.divider}`,
          ...style,
        }}
        onSelectMultiple={onSelectMultiple}
      />
    );
  } else if (entry === "-ADD-") {
    return (
      <ListItem
        key="-ADD-"
        button
        style={{
          borderBottom: `1px solid ${theme.palette.divider}`,
          ...style,
        }}
        onClick={onAdd as () => void}
      >
        <ListItemIcon>
          <PlusIcon />
        </ListItemIcon>
        <ListItemText primary={addLabel} />
      </ListItem>
    );
  } else {
    const {
      category,
      color,
      conflict,
      disabled,
      highlight,
      identifier,
      matches,
      primaryText,
      readonly,
      secondaryText,
    } = entry;
    return (
      <MultiSelectionListItem<Identifier>
        key={identifier}
        category={category}
        color={color}
        conflict={conflict}
        currentlySelected={selected.has(identifier)}
        disabled={disabled || readonly || conflict}
        itemClass={highlight ? highlightClass : undefined}
        matches={matches}
        primaryText={primaryText}
        primaryTextClass={primaryTextClass}
        secondaryText={disableSecondaryText ? undefined : secondaryText}
        secondaryTextClass={secondaryTextClass}
        style={style}
        value={identifier}
        withIcons={withIcons}
        onSelect={onSelect}
      />
    );
  }
}

interface MultiSelectionListProps<Identifier extends string> {
  addLabel: string;
  entries: readonly MaybeSearchResultEntryData<Identifier>[];
  fullscreenLayout?: boolean;
  isSearchResult: boolean;

  mobilePrimaryLines: 1 | 2;
  mobileSearchPrimaryLines: 1 | 2;
  mobileSearchSecondaryLines: 1 | 2;
  mobileSecondaryLines: 0 | 1 | 2;

  multiSelectMax: number | undefined;
  onAdd?: (() => void) | undefined;
  onScroll?: (props: ListOnScrollProps) => void;
  onSelect(identifier: string, selected: boolean): void;
  onSelectMultiple?:
    | ((identifiers: ReadonlySet<Identifier>, selected: boolean) => void)
    | undefined;
  selected: ReadonlySet<Identifier>;
  withIcons: boolean;
}

export const MultiSelectionList = typedMemo(function MultiSelectionList<Identifier extends string>(
  props: MultiSelectionListProps<Identifier>,
) {
  const {
    addLabel,
    entries,
    fullscreenLayout,
    isSearchResult,
    mobilePrimaryLines,
    mobileSearchPrimaryLines,
    mobileSearchSecondaryLines,
    mobileSecondaryLines,
    multiSelectMax,
    onAdd,
    onScroll,
    onSelect,
    onSelectMultiple,
    selected,
    withIcons,
  } = props;

  const isMobile = !!bowser.mobile;

  const theme = useTheme();

  const allSelected = useMemo(
    () => entries.every((entry) => selected.has(entry.identifier)),
    [entries, selected],
  );

  const combinedEntries: readonly (MaybeSearchResultEntryData<Identifier> | SpecialEntry)[] =
    useMemo(() => {
      if (onSelectMultiple || (onAdd && addLabel)) {
        const result: (MaybeSearchResultEntryData<Identifier> | SpecialEntry)[] = entries.slice();
        if (onSelectMultiple) {
          result.unshift("-ALL-");
        }
        if (onAdd && addLabel) {
          result.unshift("-ADD-");
        }
        return result;
      } else {
        return entries;
      }
    }, [addLabel, entries, onAdd, onSelectMultiple]);

  const primaryLines = isMobile
    ? isSearchResult
      ? mobileSearchPrimaryLines
      : mobilePrimaryLines
    : 1;
  const secondaryLines = isMobile
    ? isSearchResult
      ? mobileSearchSecondaryLines
      : mobileSecondaryLines
    : 1;

  const variableSizeListRef = useRef<VariableSizeList | null>(null);

  useEffect(() => {
    if (variableSizeListRef.current) {
      variableSizeListRef.current.resetAfterIndex(0);
    }
  }, [primaryLines, secondaryLines, isSearchResult]);

  const getItemSize = useCallback(
    (index: number): number => {
      const entry = combinedEntries[index];
      if (entry === "-ALL-" || entry === "-ADD-") {
        const dividerBorder = 1;
        return Math.max(itemTotalMarginPadding + primaryLineHeight + dividerBorder, minHeight);
      } else if (entry.secondaryText || entry.matches) {
        return Math.max(
          itemTotalMarginPadding +
            primaryLines * primaryLineHeight +
            secondaryLines * secondaryLineHeight,
          minHeight,
        );
      } else {
        return Math.max(itemTotalMarginPadding + primaryLines * primaryLineHeight, minHeight);
      }
    },
    [combinedEntries, primaryLines, secondaryLines],
  );

  const getItemKey = useCallback(
    (index: number): string => {
      const entry = combinedEntries[index];
      if (entry === "-ALL-" || entry === "-ADD-") {
        return entry;
      } else {
        return entry.identifier;
      }
    },
    [combinedEntries],
  );

  const classes = useListStyles();

  /* eslint-disable @typescript-eslint/naming-convention */
  const linesClasses = {
    0: undefined,
    1: classes.overflowOne,
    2: classes.overflowTwo,
  } as const;
  /* eslint-enable @typescript-eslint/naming-convention */

  const primaryTextClass = isMobile
    ? isSearchResult
      ? linesClasses[mobileSearchPrimaryLines]
      : linesClasses[mobilePrimaryLines]
    : classes.overflowOne;

  const secondaryTextClass = isMobile
    ? isSearchResult
      ? linesClasses[mobileSearchSecondaryLines]
      : linesClasses[mobileSecondaryLines]
    : classes.overflowOne;

  const disableSecondaryText = isMobile && !isSearchResult && !mobileSecondaryLines;

  let listWidth = 0;
  let listHeight = 0;

  const {windowInnerHeight, windowInnerWidth} = useWindowSize();

  if (fullscreenLayout) {
    listWidth = windowInnerWidth;
    const headerHeight = 56;
    listHeight = windowInnerHeight - headerHeight;
  } else {
    const dialogMargin = 32;
    const dialogMaxWidth = 960;
    listWidth = Math.min(dialogMaxWidth, windowInnerWidth - (dialogMargin + dialogMargin));
    const dialogMaxHeight = windowInnerHeight - (dialogMargin + dialogMargin);
    const headerHeight = 116;
    const footerHeight = 52;
    const dividerLineHeight = 1;
    const listMaxHeight =
      dialogMaxHeight - (headerHeight + footerHeight + dividerLineHeight + dividerLineHeight);
    for (let i = 0; i < combinedEntries.length && listHeight < listMaxHeight; i += 1) {
      listHeight += getItemSize(i);
    }
    listHeight = Math.min(listHeight, listMaxHeight);
  }

  const itemData: RenderRowData<Identifier>["data"] = {
    addLabel,
    allSelected,
    combinedEntries,
    disableSecondaryText,
    entries,
    highlightClass: classes.highlight,
    isSearchResult,
    multiSelectMax,
    onAdd,
    onSelect,
    onSelectMultiple,
    primaryTextClass,
    secondaryTextClass,
    selected,
    theme,
    withIcons,
  };

  const optionalProps: Partial<VariableSizeListProps> = {};
  if (onScroll) {
    optionalProps.onScroll = onScroll;
  }

  return (
    <div>
      <VariableSizeList
        ref={variableSizeListRef}
        estimatedItemSize={Math.max(
          itemTotalMarginPadding +
            primaryLines * primaryLineHeight +
            secondaryLines * secondaryLineHeight,
          minHeight,
        )}
        height={listHeight}
        itemCount={combinedEntries.length}
        itemData={itemData}
        itemKey={getItemKey}
        itemSize={getItemSize}
        width={listWidth}
        {...optionalProps}
      >
        {renderRow}
      </VariableSizeList>
    </div>
  );
});
