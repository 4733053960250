import {createSelector} from "reselect";
import {AppState} from "../root-reducer";

function filterArray<T>(predicate: (value: T) => boolean): (array: readonly T[]) => readonly T[] {
  return (array) => array.filter(predicate);
}

export function arrayFilterSelector<T>(
  selector: (state: AppState) => readonly T[],
  predicate: (value: T) => boolean,
): (state: AppState) => readonly T[] {
  return createSelector(selector, filterArray(predicate));
}

interface WithActive {
  readonly active: boolean;
}

function isActive<T extends WithActive>(entry: T): boolean {
  return entry.active;
}

export function arrayFilterActiveSelector<T extends WithActive>(
  selector: (state: AppState) => readonly T[],
): (state: AppState) => readonly T[] {
  return arrayFilterSelector(selector, isActive);
}

function groupArray<T, U>(
  grouper: (value: T) => U,
): (array: readonly T[]) => ReadonlyMap<U, readonly T[]> {
  return (array: readonly T[]) => {
    const result = new Map<U, T[]>();
    array.forEach((entry) => {
      const groupKey = grouper(entry);
      const arrayForGroup = result.get(groupKey);
      if (arrayForGroup) {
        arrayForGroup.push(entry);
      } else {
        result.set(groupKey, [entry]);
      }
    });
    return result;
  };
}

export function arrayGroupedSelector<T, U>(
  selector: (state: AppState) => readonly T[],
  grouper: (value: T) => U,
): (state: AppState) => ReadonlyMap<U, readonly T[]> {
  return createSelector(selector, groupArray(grouper));
}

export function arrayFilterGroupSelector<T, U>(
  selector: (state: AppState) => readonly T[],
  predicate: (value: T) => boolean,
  grouper: (value: T) => U,
): (state: AppState) => ReadonlyMap<U, readonly T[]> {
  return arrayGroupedSelector(arrayFilterSelector(selector, predicate), grouper);
}

export function arrayFilterActiveGroupSelector<T extends WithActive, U>(
  selector: (state: AppState) => readonly T[],
  grouper: (value: T) => U,
): (state: AppState) => ReadonlyMap<U, readonly T[]> {
  return arrayGroupedSelector(arrayFilterActiveSelector(selector), grouper);
}
