import {Slide, useScrollTrigger} from "@material-ui/core";
import bowser from "bowser";
import React from "react";

interface MobileHideOnScrollProps {
  children: React.ReactElement;
}

export function MobileHideOnScroll(props: MobileHideOnScrollProps): JSX.Element {
  const {children} = props;
  const trigger = useScrollTrigger();

  if (bowser.tablet || bowser.mobile) {
    return (
      <Slide appear={false} direction="down" in={!trigger}>
        {children}
      </Slide>
    );
  } else {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }
}
