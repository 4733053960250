import _ from "lodash";

export function memoize<T, U>(func: (arg: U) => T): (arg: U) => T;
export function memoize<T, U, V>(func: (arg1: U, arg2: V) => T): (arg1: U, arg2: V) => T;
export function memoize<T, U, V, W>(
  func: (arg1: U, arg2: V, arg3: W) => T,
): (arg1: U, arg2: V, arg3: W) => T;
export function memoize<T, U, V, W, X>(
  func: (arg1: U, arg2: V, arg3: W, arg4: X) => T,
): (arg1: U, arg2: V, arg3: W, arg4: X) => T;
export function memoize<T, U, V, W, X, Y>(
  func: (arg1: U, arg2: V, arg3: W, arg4: X, arg5: Y) => T,
): (arg1: U, arg2: V, arg3: W, arg4: X, arg5: Y) => T;
export function memoize<T>(func: (...args: any[]) => T): (...args: any[]) => T {
  let cachedArgs: readonly any[] | undefined;
  let cachedResult: T;
  return (...args: any[]) => {
    const newArgs = Array.from(args);
    if (_.isEqual(newArgs, cachedArgs)) {
      return cachedResult;
    }
    // Call function and overwrite cachedResult before overwriting cachedArgs;
    // if function throws exception, the old cache is kept and still valid.
    cachedResult = func(...args);
    cachedArgs = newArgs;
    return cachedResult;
  };
}
