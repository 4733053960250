import {UnitUrl} from "../resource-urls";
import {ResourceInstance} from "./base";

export interface Unit extends ResourceInstance<UnitUrl> {
  readonly active: boolean;
  readonly name: string;
  readonly remoteUrl: string;
  readonly symbol: string;
}

export const emptyUnit: Omit<Unit, "id" | "url"> = {
  active: true,
  name: "",
  remoteUrl: "",
  symbol: "",
};
