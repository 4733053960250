import bowser from "bowser";
import {useEffect, useState} from "react";

export function useWindowSize(): {
  windowInnerHeight: number;
  windowInnerWidth: number;
} {
  const [size, setSize] = useState({
    windowInnerHeight: window.innerHeight,
    windowInnerWidth: window.innerWidth,
  });

  useEffect(() => {
    let timerId: number | undefined;
    const handleResize = (): void => {
      setSize({
        windowInnerHeight: window.innerHeight,
        windowInnerWidth: window.innerWidth,
      });
      if (bowser.ios) {
        timerId = window.setTimeout(() => {
          setSize({
            windowInnerHeight: window.innerHeight,
            windowInnerWidth: window.innerWidth,
          });
          timerId = undefined;
        }, 500);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      window.clearTimeout(timerId);
    };
  }, []);

  return size;
}
