import {Config} from "@co-common-libs/config";
import {ResourceName, Role} from "@co-common-libs/resources";
import {DAY_SECONDS, HOUR_MILLISECONDS, SECOND_MILLISECONDS} from "@co-common-libs/utils";
import {
  actions,
  getCurrentRole,
  getCurrentUser,
  getCurrentUserProfile,
  getCustomerSettings,
  getPersistedFetchByID,
  getQueriesSyncedState,
  getShareToken,
  partialValuesForEach,
} from "@co-frontend-libs/redux";
import {getFrontendSentry} from "@co-frontend-libs/utils";
import {computePersistedQueries, sendCommitQueueToSentry, useDeviceConfig} from "app-utils";
import {getGeolocationTracker, globalConfig} from "frontend-global-config";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import FetchingUserData from "../pages/fetching-user-data";
import {AppSnackbar} from "./app-snackbar";
import {initializeLegacyBackgroundFetch} from "./legacy-background-fetch";
import {setSentryUser} from "./set-sentry-user";
import {UpdatingLocalDB} from "./updating-local-db";

const RECOMPUTE_PERSISTED_QUERIES_MILLISECONDS = HOUR_MILLISECONDS;

// HACK: Perform a "force reload offline data" in every release where this is incremented...
const DESIRED_FORCE_RELOAD_COUNTER = 1;

const CHECK_REFRESH_SHARE_TOKEN_MILLISECONDS = HOUR_MILLISECONDS;
const SHARE_TOKEN_REFRESH_AGE_SECONDS = DAY_SECONDS;

const DJANGO_BASE62_CHARS = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
const BASE62_CHAR_COUNT = 62;

function base62Decode(text: string): number {
  let result = 0;
  text.split("").forEach((char) => {
    result = result * BASE62_CHAR_COUNT + DJANGO_BASE62_CHARS.indexOf(char);
  });
  return result;
}

function getCriticalResourceNames(customerSettings: Config, role: Role | null): ResourceName[] {
  const names: ResourceName[] = [
    "machine",
    "priceGroup",
    "priceItem",
    "product",
    "timer",
    "role",
    "workType",
    "user",
    "userProfile",
    "availability",
    "unit",
    "reportingSpecification",
    "settingEntry",
    "task",
  ];
  const {enableProjects, externalTaskCulture, externalTaskCustomer, routesEnabled} =
    customerSettings;
  if (externalTaskCustomer && role && !role.jobber) {
    names.push("customer");
  }
  if (externalTaskCulture) {
    names.push("culture");
  }
  if (enableProjects) {
    names.push("project");
  }
  if (routesEnabled) {
    names.push("routePlan");
  }
  return names;
}

interface AuthenticatedProps {
  children: React.ReactNode;
}

export function Authenticated(props: AuthenticatedProps): JSX.Element {
  const [needsDBWrite, setNeedsDBWrite] = useState(false);
  const [userDataFetchCompleted, setUserDataFetchCompleted] = useState(false);

  const currentRole = useSelector(getCurrentRole);
  const currentUser = useSelector(getCurrentUser);
  const currentUserProfile = useSelector(getCurrentUserProfile);
  const customerSettings = useSelector(getCustomerSettings);
  const queriesSyncedState = useSelector(getQueriesSyncedState);
  const pendingPersistedFetchById = useSelector(getPersistedFetchByID);
  const shareToken = useSelector(getShareToken);

  const pendingOrderFetches = pendingPersistedFetchById.order?.length || 0;

  const dispatch = useDispatch();

  const [forceReloadCounter, setForceReloadCounter] = useDeviceConfig<number>(
    "forceReloadCounter",
    0,
  );

  const fetchDone = useRef(false);

  const userDataHasBeenFetched = useMemo((): boolean => {
    if (fetchDone.current) {
      return true;
    }
    const userPartDone = !!(currentUser && currentUserProfile && currentRole);

    if (!userPartDone) {
      return false;
    }

    const names = getCriticalResourceNames(customerSettings, currentRole);

    const resourcesWithSyncedQueries = new Set<ResourceName>();
    partialValuesForEach(queriesSyncedState, ({query, queryState}) => {
      if (queryState.fullFetchDataComputedAtTimestamp) {
        const {resourceName} = query;
        resourcesWithSyncedQueries.add(resourceName);
      }
    });
    for (let i = 0; i < names.length; i += 1) {
      const name = names[i];
      if (!resourcesWithSyncedQueries.has(name)) {
        return false;
      }
    }
    if (pendingOrderFetches) {
      return false;
    }
    fetchDone.current = true;
    return true;
  }, [
    currentRole,
    currentUser,
    currentUserProfile,
    customerSettings,
    queriesSyncedState,
    pendingOrderFetches,
  ]);

  useEffect(() => {
    setNeedsDBWrite(!userDataHasBeenFetched);
    setUserDataFetchCompleted(userDataHasBeenFetched);

    const setupPersistedQueriesWithRoleFromState = (): void => {
      const queries = computePersistedQueries(customerSettings, currentRole || undefined);
      if (forceReloadCounter < DESIRED_FORCE_RELOAD_COUNTER && currentRole) {
        dispatch(actions.persistedQueriesRequested(queries, true));
        setForceReloadCounter(DESIRED_FORCE_RELOAD_COUNTER);
      } else {
        dispatch(actions.persistedQueriesRequested(queries));
      }
    };

    setupPersistedQueriesWithRoleFromState();

    const recomputedPersistedQueriesIntervalID = window.setInterval(
      setupPersistedQueriesWithRoleFromState,
      RECOMPUTE_PERSISTED_QUERIES_MILLISECONDS,
    );

    return () => {
      window.clearInterval(recomputedPersistedQueriesIntervalID);
    };
  }, [
    currentRole,
    customerSettings,
    dispatch,
    forceReloadCounter,
    setForceReloadCounter,
    userDataHasBeenFetched,
  ]);

  useEffect(() => {
    const checkRefreshShareToken = (): void => {
      if (!shareToken) {
        return;
      }
      const [userId, encodedSeconds, sign] = shareToken.split(":");
      if (!userId || !encodedSeconds || !sign) {
        return;
      }
      const seconds = base62Decode(encodedSeconds);
      const nowSeconds = new Date().valueOf() / SECOND_MILLISECONDS;
      const shareTokenAge = nowSeconds - seconds;

      if (shareTokenAge > SHARE_TOKEN_REFRESH_AGE_SECONDS) {
        dispatch(
          actions.refreshToken({
            refreshURL: globalConfig.authentication.refreshURL,
          }),
        );
      }
    };

    checkRefreshShareToken();

    const checkRefreshShareTokenIntervalId = window.setInterval(
      checkRefreshShareToken,
      CHECK_REFRESH_SHARE_TOKEN_MILLISECONDS,
    );

    return () => {
      window.clearInterval(checkRefreshShareTokenIntervalId);
    };
  }, [shareToken, dispatch]);

  const pollDBWriteIntervalID = useRef<number | null>(null);

  const pollDBWrite = useCallback((): void => {
    if (!userDataFetchCompleted) {
      return;
    }
    if ((window as any).CURRENT_INITIAL_DB_WRITES === 0) {
      (window as any).REQUIRED_INITIAL_DATA_IS_MISSING = false;
      setNeedsDBWrite(false);
      if (pollDBWriteIntervalID.current) {
        window.clearInterval(pollDBWriteIntervalID.current);
        pollDBWriteIntervalID.current = null;
      }
    }
  }, [userDataFetchCompleted]);

  useEffect(() => {
    if (needsDBWrite) {
      pollDBWriteIntervalID.current = window.setInterval(pollDBWrite, SECOND_MILLISECONDS);
    } else {
      (window as any).REQUIRED_INITIAL_DATA_IS_MISSING = false;
    }
    return () => {
      if (pollDBWriteIntervalID.current) {
        window.clearInterval(pollDBWriteIntervalID.current);
        pollDBWriteIntervalID.current = null;
      }
    };
  }, [currentUser, needsDBWrite, pollDBWrite]);

  useEffect(() => {
    if (currentUser) {
      setSentryUser(currentUser);
    }
  }, [currentUser]);

  useEffect(() => {
    if (!userDataFetchCompleted) {
      if (userDataHasBeenFetched) {
        setUserDataFetchCompleted(true);
      }
    }
  }, [userDataFetchCompleted, userDataHasBeenFetched]);

  useEffect(() => {
    if (currentUserProfile?.dumpSyncQueue && currentUserProfile?.url) {
      sendCommitQueueToSentry("sync dump by request", "debug");
      dispatch(actions.update(currentUserProfile.url, [{member: "dumpSyncQueue", value: false}]));
    }
  }, [currentUserProfile?.dumpSyncQueue, currentUserProfile?.url, dispatch]);

  const cordovaResumeHandler = useCallback(() => {
    window.setTimeout(() => {
      dispatch(actions.startOnlineSaves());
      dispatch(actions.requestChangesFetch());
    }, SECOND_MILLISECONDS);
    getGeolocationTracker()
      .then((legacyGeolocationTracker) => {
        if (legacyGeolocationTracker) {
          legacyGeolocationTracker.updateTrackingState();
        }
        return;
      })
      .catch(() => {
        getFrontendSentry().captureMessage("Legacy geolocation tracker error during resume");
      });
  }, [dispatch]);

  useEffect(() => {
    if (typeof cordova !== "undefined") {
      const startOnlineSaves = (): void => {
        dispatch(actions.startOnlineSaves());
      };
      initializeLegacyBackgroundFetch(startOnlineSaves);
      document.addEventListener("resume", cordovaResumeHandler, false);
    }
    return () => {
      if (typeof cordova !== "undefined") {
        document.removeEventListener("resume", cordovaResumeHandler, false);
      }
    };
  }, [cordovaResumeHandler, customerSettings.geolocation.enabled, dispatch]);

  if (!userDataFetchCompleted) {
    return <FetchingUserData />;
  }
  if (needsDBWrite) {
    return <UpdatingLocalDB />;
  }

  return (
    <div style={{height: "100%", minHeight: "100%"}}>
      <AppSnackbar />
      {React.Children.only(props.children)}
    </div>
  );
}
