import {dateToString} from "@co-common-libs/utils";
import {DateField, ResponsiveDialog, TimeField} from "@co-frontend-libs/components";
import {DialogContent, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import {PureComponent, dateFromDateAndTime} from "app-utils";
import {bind} from "bind-decorator";
import React from "react";
import {IntlContext, defineMessages} from "react-intl";

const messages = defineMessages({
  cancel: {
    defaultMessage: "Fortryd",
    id: "user-profile.label.cancel",
  },
  date: {
    defaultMessage: "Dato",
    id: "user-profile.label.date",
  },
  ok: {
    defaultMessage: "Ok",
    id: "user-profile.label.ok",
  },
  punchIn: {
    defaultMessage: "Stempl ind",
    id: "user-profile.label.punch-in",
  },
  punchOut: {
    defaultMessage: "Stempl ud",
    id: "user-profile.label.punch-out",
  },
  time: {
    defaultMessage: "Klokken",
    id: "user-profile.label.time",
  },
  title: {
    defaultMessage: "Tilføj ind/udstempling",
    id: "user-profile.dialog-title.punch-in-out",
  },
});

interface AddPunchInOutDialogProps {
  date?: string;
  onCancel: () => void;
  onOk: (timestamp: string, type: "PUNCH_IN" | "PUNCH_OUT") => void;
  open: boolean;
}

interface AddPunchInOutDialogState {
  date: string | null;
  time: string | null;
  type: "PUNCH_IN" | "PUNCH_OUT";
}

class AddPunchInOutDialog extends PureComponent<
  AddPunchInOutDialogProps,
  AddPunchInOutDialogState
> {
  state: AddPunchInOutDialogState = {
    date: this.props.date || dateToString(new Date()),
    time: null,
    type: "PUNCH_IN",
  };
  UNSAFE_componentWillReceiveProps(nextProps: AddPunchInOutDialogProps): void {
    if (nextProps.open && !this.props.open) {
      this.setState({
        date: nextProps.date || dateToString(new Date()),
        time: null,
        type: "PUNCH_IN",
      });
    }
  }
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  @bind
  handleTypeChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const {value} = event.target;
    console.assert(value === "PUNCH_IN" || value === "PUNCH_OUT");
    this.setState({type: value as "PUNCH_IN" | "PUNCH_OUT"});
  }
  @bind
  handleDateChange(value: string | null): void {
    this.setState({date: value});
  }
  @bind
  handleTimeChange(value: string | null): void {
    this.setState({time: value});
  }
  @bind
  handleCancel(): void {
    this.props.onCancel();
  }
  @bind
  handleOk(): void {
    const {date, time, type} = this.state;
    if (date && time) {
      const timestamp = dateFromDateAndTime(date, time).toISOString();
      this.props.onOk(timestamp, type);
    }
  }
  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {open} = this.props;
    const content = (
      <>
        <RadioGroup name="type" value={this.state.type} onChange={this.handleTypeChange}>
          <FormControlLabel
            control={<Radio />}
            label={formatMessage(messages.punchIn)}
            value="PUNCH_IN"
          />
          <FormControlLabel
            control={<Radio />}
            label={formatMessage(messages.punchOut)}
            value="PUNCH_OUT"
          />
        </RadioGroup>
        <DateField
          autoOk
          fullWidth
          label={formatMessage(messages.date)}
          margin="dense"
          value={this.state.date}
          onChange={this.handleDateChange}
        />
        <TimeField
          fullWidth
          label={formatMessage(messages.time)}
          margin="dense"
          value={this.state.time || undefined}
          onChange={this.handleTimeChange}
        />
      </>
    );
    return (
      <ResponsiveDialog
        okDisabled={!this.state.date || !this.state.time}
        open={open}
        title={formatMessage(messages.title)}
        onCancel={this.handleCancel}
        onOk={this.handleOk}
      >
        <DialogContent>{content}</DialogContent>
      </ResponsiveDialog>
    );
  }
}

export {AddPunchInOutDialog};
