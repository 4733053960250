import {PriceItemUsesDict, Task} from "@co-common-libs/resources";
import {priceItemIsManualDistributionTime} from "@co-common-libs/resources-utils";
import {notNull} from "@co-common-libs/utils";
import {getPriceItemLookup, getUnitLookup} from "@co-frontend-libs/redux";
import _ from "lodash";
import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import {TimeDistributionTable} from "./time-distribution-table";

interface OptionalTimeDistributionTableProps {
  disabled?: boolean;
  expectedMinutes?: number | undefined;
  task: Task & {readonly priceItemUses: PriceItemUsesDict};
}

export const OptionalTimeDistributionTable = React.memo(function OptionalTimeDistributionTable(
  props: OptionalTimeDistributionTableProps,
): JSX.Element | null {
  const {disabled = false, expectedMinutes = 0, task} = props;

  const priceItemLookup = useSelector(getPriceItemLookup);
  const unitLookup = useSelector(getUnitLookup);

  const timeEntries = useMemo(
    () =>
      _.sortBy(
        Object.entries(task.priceItemUses),
        ([_identifier, priceItemUse]) => priceItemUse.order,
      )
        .map(([identifier, priceItemUse]) => {
          const priceItem = priceItemLookup(priceItemUse.priceItem);
          if (!priceItem) {
            return null;
          }
          if (!priceItemIsManualDistributionTime(unitLookup, priceItem)) {
            return null;
          }
          return {identifier, priceItem, priceItemUse};
        })
        .filter(notNull),
    [priceItemLookup, task.priceItemUses, unitLookup],
  );
  if (timeEntries.length <= 1) {
    // no distribution if 0 or 1 items to distribute to
    return null;
  }
  return (
    <TimeDistributionTable
      disabled={disabled}
      expectedMinutes={expectedMinutes}
      taskUrl={task.url}
      timeEntries={timeEntries}
    />
  );
});
