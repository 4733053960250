import {WorkType} from "@co-common-libs/resources";
import {createSelector} from "reselect";
import {getWorkTypeArray} from "../resources/selectors";
import {AppState} from "../root-reducer";

function filterOnExternalTaskPrimary(workTypeArray: readonly WorkType[]): readonly WorkType[] {
  return workTypeArray.filter((workType) => workType.externalTaskPrimary);
}

function filterOnInternalTaskPrimary(workTypeArray: readonly WorkType[]): readonly WorkType[] {
  return workTypeArray.filter((workType) => workType.internalTaskPrimary);
}

function filterOnBothPrimary(workTypeArray: readonly WorkType[]): readonly WorkType[] {
  return workTypeArray.filter(
    (workType) => workType.externalTaskPrimary || workType.internalTaskPrimary,
  );
}

function filterOutHiddenWorkTypes(workTypeArray: readonly WorkType[]): readonly WorkType[] {
  return workTypeArray.filter((workType) => !workType.onlyForExtraTimers);
}

export const getExternalTaskPrimaryWorkTypeArray: (state: AppState) => readonly WorkType[] =
  createSelector(getWorkTypeArray, filterOnExternalTaskPrimary);

export const getInternalTaskPrimaryWorkTypeArray: (state: AppState) => readonly WorkType[] =
  createSelector(getWorkTypeArray, filterOnInternalTaskPrimary);

export const getBothPrimaryWorkTypeArray: (state: AppState) => readonly WorkType[] = createSelector(
  getWorkTypeArray,
  filterOnBothPrimary,
);

export const getFilteredExternalTaskPrimaryWorkTypeArray: (state: AppState) => readonly WorkType[] =
  createSelector(getExternalTaskPrimaryWorkTypeArray, filterOutHiddenWorkTypes);

export const getFilteredInternalTaskPrimaryWorkTypeArray: (state: AppState) => readonly WorkType[] =
  createSelector(getInternalTaskPrimaryWorkTypeArray, filterOutHiddenWorkTypes);

export const getFilteredWorkTypeArray: (state: AppState) => readonly WorkType[] = createSelector(
  getWorkTypeArray,
  filterOutHiddenWorkTypes,
);

export const getFilteredBothPrimaryWorkTypeArray: (state: AppState) => readonly WorkType[] =
  createSelector(getBothPrimaryWorkTypeArray, filterOutHiddenWorkTypes);
