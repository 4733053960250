import {Config} from "@co-common-libs/config";
import {RemunerationGroupInput} from "@co-common-libs/payroll";
import {UserProfile} from "@co-common-libs/resources";
import {getEmployeeRemunerationGroup} from "./remuneration-computations";

export function getExtraHolidaysForUser(
  customerSettings: Config,
  userProfile: UserProfile | null | undefined,
  getExtraHolidays: (remunerationGroup: string, date: string) => string | undefined,
  getExtraHalftHolidays: (remunerationGroup: string, date: string) => string | undefined,
):
  | {
      getUserHalfHoliday: (date: string) => string | undefined;
      getUserHoliday: (date: string) => string | undefined;
    }
  | undefined {
  if (!userProfile) {
    return undefined;
  }
  const defaultRemunerationGroupID = getEmployeeRemunerationGroup(customerSettings, userProfile);
  const defaultRemunerationGroup: RemunerationGroupInput | undefined =
    customerSettings.remunerationGroups[defaultRemunerationGroupID];
  if (!defaultRemunerationGroup) {
    return undefined;
  }
  return {
    getUserHalfHoliday: getExtraHalftHolidays.bind(null, defaultRemunerationGroupID),
    getUserHoliday: getExtraHolidays.bind(null, defaultRemunerationGroupID),
  };
}
