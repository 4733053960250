import {ResourceInstance} from "@co-common-libs/resources";

export function resourceInstanceDateTimeComparator<
  T extends ResourceInstance & {
    date: string | null;
    time: string | null;
  },
>(a: T, b: T): number {
  const aDate = a.date || "X";
  const bDate = b.date || "X";
  let result = aDate.localeCompare(bDate);
  if (result) {
    return result;
  } else {
    const aTime = a.time || "X";
    const bTime = b.time || "X";
    result = aTime.localeCompare(bTime);
    if (result) {
      return result;
    } else {
      // same date and time; sort by ID to ensure consistent order then
      const aID = a.id || "";
      const bID = b.id || "";
      return aID.localeCompare(bID);
    }
  }
}
