import {PureComponent} from "app-utils";
import React from "react";

export class PrivacyPolicy extends PureComponent<unknown, unknown> {
  // eslint-disable-next-line class-methods-use-this
  render(): JSX.Element {
    return (
      <div>
        <h1 style={{lineHeight: "48px", overflowWrap: "break-word"}}>
          CustomOffice Privatlivspolitik
        </h1>
        <p>
          Vi mener det er meget vigtigt, at du ved vi går op i, at beskytte dine personlige data;
          også når du er på arbejde. I samarbejde med din arbejdsgiver sikrer vi at kun
          arbejdsrelevante oplysninger opsamles via vores systemer og at behandlingen af disse
          oplysninger sker i overenstemmelse med europæisk lovgivning.
        </p>
        <h3>Anvendelse af geo-lokalisering</h3>
        <p>
          Vores app har behov for løbende adgang til enhedens (smartphone/tablet) GPS-position, selv
          når appen er i baggrunden. Positionen bruges kun så længe du registrerer tid på en
          arbejdsopgave. GPS-positionen anvendes ifm. dokumentation og kvalitetssikring under
          udførelse af arbejdet og brugen er godkendt af din arbejdsgiver.
        </p>
        <h3>Løfte om hemmeligholdelse</h3>
        <p>
          Alle vores medarbejdere har tavshedspligt med hensyn til alle typer af dokumenter og andet
          materiale.
        </p>
        <h3>Sikker forbindelse (SSL)</h3>
        <p>
          SSL secure connection: Alle data der opsamles af appen og kommunikeres til din
          arbejdsgivers online CustomOffice-løsning sker via en sikker forbindelse.
        </p>
      </div>
    );
  }
}
