import {Customer, CustomerUrl, Location, LocationUrl} from "@co-common-libs/resources";
import {memoizeForceReuse} from "@co-frontend-libs/utils";
import React, {useMemo} from "react";
import {IntlShape, useIntl} from "react-intl";
import type {Writable} from "ts-essentials";
import {
  EntryData,
  GenericMultiSelectionSearchDialog,
  GenericSingleSelectionSearchDialog,
} from "../search-dialog";

function getAddressText(entry: Location): string {
  const {address, city, postalCode} = entry;
  const singleLineAddress = address.replace("\n", ", ");
  if (singleLineAddress && postalCode && city) {
    return `${singleLineAddress}, ${postalCode} ${city}`;
  } else if (singleLineAddress && postalCode) {
    return `${singleLineAddress}, ${postalCode}`;
  } else if (singleLineAddress && city) {
    return `${singleLineAddress}, ${city}`;
  } else if (singleLineAddress) {
    return singleLineAddress;
  } else if (postalCode && city) {
    return `${postalCode} ${city}`;
  } else if (postalCode) {
    return postalCode;
  } else if (city) {
    return city;
  } else {
    return "";
  }
}

function makeChoice(
  intl: IntlShape,
  instance: Location,
  customerLookup: (url: CustomerUrl) => Customer | undefined,
  customerURL: string | null,
  lastUsedLocations: ReadonlySet<LocationUrl> | null,
  mayShowMultipleCustomers: boolean,
): EntryData<LocationUrl> {
  const {address, city, customer, favorite, name, postalCode, url} = instance;
  const recentlyUsedIndex = lastUsedLocations ? [...lastUsedLocations].indexOf(url) : -1;
  const recentlyUsedSortKey = recentlyUsedIndex > -1 ? -recentlyUsedIndex : undefined;
  const isSelectedCustomer = customer === customerURL;
  const category = favorite
    ? "favorite"
    : recentlyUsedSortKey !== undefined
      ? "recentlyUsed"
      : isSelectedCustomer
        ? "standard"
        : "otherCustomer";
  const customerName = customer ? customerLookup(customer)?.name || "" : "";
  const addressText = getAddressText(instance);

  const result: Writable<EntryData<LocationUrl>> = {
    category,
    identifier: url,
    primaryText: name || address || "",
    searchFields: [
      {label: intl.formatMessage({defaultMessage: "Navn"}), priority: 10, text: name},
      {label: intl.formatMessage({defaultMessage: "Kunde"}), priority: 7, text: customerName},
      {label: intl.formatMessage({defaultMessage: "Adresse"}), priority: 5, text: address},
      {label: intl.formatMessage({defaultMessage: "Postnr."}), priority: 5, text: postalCode},
      {label: intl.formatMessage({defaultMessage: "By"}), priority: 5, text: city},
    ],
    secondaryText: mayShowMultipleCustomers
      ? customerName && addressText
        ? `${customerName}; ${addressText}`
        : addressText || customerName
      : addressText,
  };
  if (recentlyUsedSortKey !== undefined) {
    result.recentlyUsedSortKey = recentlyUsedSortKey;
  }
  return result;
}

export function computeBaseChoicesForLocations(
  intl: IntlShape,
  locationArray: readonly Location[],
  customerURL: CustomerUrl | null,
  customerLookup: (url: CustomerUrl) => Customer | undefined,
  locationFavoritesEnabled: boolean,
  locationCrossCustomerSelectionEnabled: boolean,
  onlyActive: boolean,
  mayShowMultipleCustomers: boolean,
  hideFieldLocations?: boolean,
  lastUsedLocations?: ReadonlySet<LocationUrl>,
): readonly EntryData<LocationUrl>[] {
  const activeLocationArray = onlyActive
    ? locationArray.filter(
        (location) =>
          location.active && (!location.customer || customerLookup(location.customer)?.active),
      )
    : locationArray;
  const locations = hideFieldLocations
    ? activeLocationArray.filter((location) => !location.geojson)
    : activeLocationArray;

  const filteredCustomerLocations = customerURL
    ? locations.filter((location) => location.customer === customerURL)
    : [];

  const preferredResult = filteredCustomerLocations.map((instance) =>
    makeChoice(
      intl,
      instance,
      customerLookup,
      customerURL,
      lastUsedLocations || null,
      mayShowMultipleCustomers,
    ),
  );

  const otherLocations = locationCrossCustomerSelectionEnabled
    ? locations.filter((location) => location.customer !== customerURL)
    : locationFavoritesEnabled
      ? locations.filter((location) => location.favorite && location.customer !== customerURL)
      : [];

  const normalResult = otherLocations.map((instance) =>
    makeChoice(intl, instance, customerLookup, customerURL, null, mayShowMultipleCustomers),
  );
  return [...preferredResult, ...normalResult];
}

export type TitleVariant = "DELIVERY" | "LOCATION" | "PICKUP" | "WORKPLACE";

export interface LocationDialogProps {
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  customerURL: CustomerUrl | null;
  hideFieldLocations?: boolean | undefined;
  includeLogOnlyLocations: boolean;
  includeWorkplaceOnlyLocations: boolean;
  lastUsedLocations?: ReadonlySet<LocationUrl> | undefined;
  locationArray: readonly Location[];
  locationCrossCustomerSelectionEnabled: boolean;
  locationFavoritesEnabled: boolean;
  onAdd?: ((searchString: string) => void) | undefined;
  onCancel(): void;
  onlyActive?: boolean;
  onNone?: (() => void) | undefined;
  onOk(url: LocationUrl): void;
  open: boolean;
  titleVariant: TitleVariant;
}

export const getLabels = (
  intl: IntlShape,
  variant: TitleVariant,
): {
  noneLabel: string;
  searchLabel: string;
  title: string;
} => {
  if (variant === "WORKPLACE") {
    return {
      noneLabel: intl.formatMessage({defaultMessage: "Intet arbejdssted"}),
      searchLabel: intl.formatMessage({defaultMessage: "Søg arbejdssted"}),
      title: intl.formatMessage({defaultMessage: "Vælg arbejdssted"}),
    };
  }
  if (variant === "LOCATION") {
    return {
      noneLabel: intl.formatMessage({defaultMessage: "Intet sted"}),
      searchLabel: intl.formatMessage({defaultMessage: "Søg sted"}),
      title: intl.formatMessage({defaultMessage: "Vælg sted"}),
    };
  }
  if (variant === "DELIVERY") {
    return {
      noneLabel: intl.formatMessage({defaultMessage: "Intet leveringssted"}),
      searchLabel: intl.formatMessage({defaultMessage: "Søg leveringssted"}),
      title: intl.formatMessage({defaultMessage: "Vælg leveringssted"}),
    };
  }
  if (variant === "PICKUP") {
    return {
      noneLabel: intl.formatMessage({defaultMessage: "Intet afhentningssted"}),
      searchLabel: intl.formatMessage({defaultMessage: "Søg afhentningssted"}),
      title: intl.formatMessage({defaultMessage: "Vælg afhentningssted"}),
    };
  }
  return {noneLabel: "", searchLabel: "", title: ""};
};

export const LocationDialog = React.memo(function LocationDialog(
  props: LocationDialogProps,
): JSX.Element {
  const {
    customerLookup,
    customerURL,
    hideFieldLocations,
    includeLogOnlyLocations,
    includeWorkplaceOnlyLocations,
    lastUsedLocations,
    locationArray,
    locationCrossCustomerSelectionEnabled,
    locationFavoritesEnabled,
    onAdd,
    onCancel,
    onlyActive = true,
    onNone,
    onOk,
    open,
    titleVariant,
  } = props;
  const intl = useIntl();
  const {noneLabel, searchLabel, title} = useMemo(
    () => getLabels(intl, titleVariant),
    [intl, titleVariant],
  );

  const [doComputeBaseChoices, reuseBaseChoices] = useMemo(
    () => memoizeForceReuse(computeBaseChoicesForLocations, []),
    [],
  );
  const getBaseChoices = open ? doComputeBaseChoices : reuseBaseChoices;
  const filteredLocationArray = useMemo(() => {
    let result = locationArray;
    if (!includeLogOnlyLocations) {
      result = result.filter((location) => !location.logOnlyLocation);
    }
    if (!includeWorkplaceOnlyLocations) {
      result = result.filter((location) => !location.workplaceOnlyLocation);
    }
    return result;
  }, [includeLogOnlyLocations, includeWorkplaceOnlyLocations, locationArray]);

  const mayShowMultipleCustomers =
    locationFavoritesEnabled || locationCrossCustomerSelectionEnabled;

  const data = getBaseChoices(
    intl,
    filteredLocationArray,
    customerURL,
    customerLookup,
    locationFavoritesEnabled,
    locationCrossCustomerSelectionEnabled,
    onlyActive,
    mayShowMultipleCustomers,
    !!hideFieldLocations,
    lastUsedLocations,
  );

  return (
    <GenericSingleSelectionSearchDialog<LocationUrl>
      addUnnamedLabel={intl.formatMessage({defaultMessage: "Opret sted"})}
      data={data}
      mobilePrimaryLines={1}
      mobileSearchPrimaryLines={1}
      mobileSearchSecondaryLines={1}
      mobileSecondaryLines={1}
      noneLabel={onNone ? noneLabel : undefined}
      open={open}
      searchTitle={searchLabel}
      title={title}
      onAdd={onAdd}
      onCancel={onCancel}
      onNone={onNone}
      onOk={onOk}
    />
  );
});

interface MultiLocationDialogProps {
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  customerURL: CustomerUrl | null;
  includeLogOnlyLocations: boolean;
  includeSelectAll?: boolean;
  includeWorkplaceOnlyLocations: boolean;
  locationArray: readonly Location[];
  locationCrossCustomerSelectionEnabled: boolean;
  locationFavoritesEnabled: boolean;
  onCancel(): void;
  onlyActive?: boolean;
  onOk(urls: ReadonlySet<LocationUrl>): void;
  open: boolean;
  selected?: ReadonlySet<LocationUrl>;
  titleVariant: TitleVariant;
}

export const MultiLocationDialog = React.memo(function MultiLocationDialog(
  props: MultiLocationDialogProps,
): JSX.Element {
  const {
    customerLookup,
    customerURL,
    includeLogOnlyLocations,
    includeSelectAll,
    includeWorkplaceOnlyLocations,
    locationArray,
    locationCrossCustomerSelectionEnabled,
    locationFavoritesEnabled,
    onCancel,
    onlyActive = true,
    onOk,
    open,
    selected,
    titleVariant,
  } = props;
  const intl = useIntl();
  const {searchLabel, title} = useMemo(() => getLabels(intl, titleVariant), [intl, titleVariant]);
  const [doComputeBaseChoices, reuseBaseChoices] = useMemo(
    () => memoizeForceReuse(computeBaseChoicesForLocations, []),
    [],
  );
  const getBaseChoices = open ? doComputeBaseChoices : reuseBaseChoices;
  const filteredLocationArray = useMemo(() => {
    let result = locationArray;
    if (!includeLogOnlyLocations) {
      result = result.filter((location) => !location.logOnlyLocation);
    }
    if (!includeWorkplaceOnlyLocations) {
      result = result.filter((location) => !location.workplaceOnlyLocation);
    }
    return result;
  }, [includeLogOnlyLocations, includeWorkplaceOnlyLocations, locationArray]);

  const mayShowMultipleCustomers =
    locationFavoritesEnabled || locationCrossCustomerSelectionEnabled;

  const data = getBaseChoices(
    intl,
    filteredLocationArray,
    customerURL,
    customerLookup,
    locationFavoritesEnabled,
    locationCrossCustomerSelectionEnabled,
    onlyActive,
    mayShowMultipleCustomers,
  );
  return (
    <GenericMultiSelectionSearchDialog<LocationUrl>
      data={data}
      includeSelectAll={includeSelectAll}
      mobilePrimaryLines={1}
      mobileSearchPrimaryLines={1}
      mobileSearchSecondaryLines={1}
      mobileSecondaryLines={1}
      open={open}
      searchTitle={searchLabel}
      selected={selected}
      title={title}
      onCancel={onCancel}
      onOk={onOk}
    />
  );
});
