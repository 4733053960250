import {Command, Contact} from "@co-common-libs/resources";
import {getCustomerContactArray} from "../../complex-selectors";
import {ProvisionaryCommand} from "../../resources/actions";
import {ResourcesAuthenticationMiddlewareAPI} from "../types";

export function onlyOneDefaultContactPerCustomer(
  newContact: Contact | null,
  oldContact: Contact | undefined,
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  _command: ProvisionaryCommand,
): {after?: Command[]; before?: Command[]} | null {
  const state = middlewareApi.getState();
  if (!newContact || !oldContact) {
    return null;
  }
  const becameDefaultContact =
    newContact.active && newContact.defaultContact && !oldContact.defaultContact;
  if (!becameDefaultContact) {
    return null;
  }
  const customerContacts = getCustomerContactArray(newContact.customer)(state);
  const otherDefaultCustomerContacts = customerContacts.filter(
    (contact) => contact.url !== newContact.url && contact.defaultContact,
  );
  if (otherDefaultCustomerContacts.length) {
    return {
      before: otherDefaultCustomerContacts.map((contact) => ({
        action: "UPDATE",
        patch: [{member: "defaultContact", value: false}],
        url: contact.url,
      })),
    };
  } else {
    return null;
  }
}
