import {TimerUrl} from "@co-common-libs/resources";
import {SimpleTimerStart, TimerInterval} from "./types";

// implementation only handles case where intervals explicitly stop;
// i.e. last timerStart must be a "stop"/without timer
//
// for the special case of showing/computing "active",
// inject a stop with timestamp now, and for the special case of
// *not* filtering that interval out even if it's less than a minute,
// pop it before filtering, push it again afterwards...
export function intervalsFromTimerStarts(timerStarts: SimpleTimerStart[]): TimerInterval[] {
  const result: TimerInterval[] = [];
  const firstIndex = timerStarts.findIndex((t) => t.timer != null);
  if (firstIndex === -1) {
    return result;
  }
  console.assert(timerStarts[timerStarts.length - 1].timer === null);
  const firstTimerStart = timerStarts[firstIndex];
  let lastTimer: TimerUrl | null = firstTimerStart.timer;
  // we just skipped all those without timer set....
  console.assert(typeof lastTimer === "string");
  let lastDeviceTimestamp = firstTimerStart.deviceTimestamp;
  for (let i = firstIndex + 1; i < timerStarts.length; i += 1) {
    const timerStart = timerStarts[i];
    const {deviceTimestamp, timer} = timerStart;
    if (timer !== lastTimer) {
      if (lastTimer) {
        result.push({
          fromTimestamp: lastDeviceTimestamp,
          timer: lastTimer,
          toTimestamp: deviceTimestamp,
        });
      }
      lastTimer = timer;
      lastDeviceTimestamp = deviceTimestamp;
    }
  }
  return result;
}
