import {Chip} from "@material-ui/core";
import useMergedRef from "@react-hook/merged-ref";
import React from "react";
import {DropTargetMonitor, useDrag, useDrop} from "react-dnd";

interface IdentifierChipProps<IdentifierChoice extends string> {
  identifier: IdentifierChoice;
  itemType: string | symbol;
  onDrop: (source: IdentifierChoice, target: IdentifierChoice) => void;
}

export function IdentifierChip<IdentifierChoice extends string>(
  props: IdentifierChipProps<IdentifierChoice>,
): JSX.Element {
  const {identifier, itemType, onDrop} = props;

  const [, drop] = useDrop({
    accept: itemType,
    drop: (item: any, _monitor: DropTargetMonitor) => {
      onDrop(item.identifier, identifier);
    },
  });

  const [, drag, preview] = useDrag({
    item: {
      identifier,
    },
    type: itemType,
  });

  const multiRef = useMergedRef<HTMLDivElement>(drop, preview, drag);

  return <Chip ref={multiRef} label={identifier} />;
}
