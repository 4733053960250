import {ReportingSpecificationTemplate} from "./types";

export const sewerControlLog: ReportingSpecificationTemplate = {
  active: true,
  allowSkip: false,
  allowTotalsTable: false,
  allowTransportedMaterialCountMismatch: false,
  autoInsertAmountInDelivery: true,
  autoInsertLastPickupValues: true,
  autoInsertLastWorkplaceConversionValue: false,
  autoInsertLastWorkplaceValues: false,
  customerLogSums: false,
  displayProductsPerWorkplace: false,
  displayWorkplaceOverviewOnPDF: true,
  extraFooterText: "",
  fieldsUsedFor: "workplace",
  hideMaterialsOnPrintout: false,
  horizontal: true,
  identifier: "sewercontrol",
  invoiceCustomerAddress: false,
  invoiceCustomerName: false,
  invoiceCustomerVatNumber: false,
  invoiceWorkplaceAddress: false,
  invoiceWorkplaceName: false,
  name: "Kontrol for kloakeringsarbejder",
  reportTransport: true,
  requireAtLeastOneLogEntry: false,
  sendMailToPickupDeliveryCustomers: false,
  shared: false,
  showCustomerOnEntryTable: true,
  showLoadCounts: false,
  showLocationMaterialSums: false,
  showLocationProducts: false,
  showMachines: false,
  showSharedTotalsTableOnAllOrderTasks: false,
  showTaskTimeOnReport: true,
  simpleLocationSumLog: false,
  taskData: {
    enterBaseDataLabel: "Opgave-/anlægstype",
    inputs: [
      {
        format: {
          alternatives: [
            {
              identifier: "newinstall",
              label: "Nyinstallation",
            },
            {
              identifier: "Changeinstall",
              label: "Ændring af installation",
            },
            {
              identifier: "repair",
              label: "Reparationsarbejder",
            },
          ],
          type: "choice",
        },
        identifier: "tasktype",
        label: "Opgavetype",
        required: true,
      },
      {
        format: {
          alternatives: [
            {
              identifier: "sewerinstall",
              label: "Kloakinstallation",
            },
            {
              identifier: "drain",
              label: "Nedsivning",
            },
            {
              identifier: "ratblock",
              label: "Rottespærre",
            },
            {
              identifier: "higlevelclose",
              label: "Højvandslukke",
            },
            {
              identifier: "other",
              label: "Andet",
            },
          ],
          type: "choice",
        },
        identifier: "installtype",
        label: "Type af anlæg:",
        required: true,
      },
      {
        format: {
          multiline: true,
          type: "text",
        },
        identifier: "note-workplace",
        label: "Bemærkninger",
        required: false,
      },
    ],
  },
  versionNote: "Første version",
  workplaceData: {
    workplace: {
      logEntries: "*",
      logInputs: [
        {
          format: {
            alternatives: [
              {
                identifier: "pipe",
                label: "Rør/formstykker",
              },
              {
                identifier: "wells",
                label: "Brønde",
              },
              {
                identifier: "sand",
                label: "Grusmateriale",
              },
              {
                identifier: "specialcomp",
                label: "Særlige Komponenter",
              },
            ],
            type: "choice",
          },
          identifier: "recivercontrol-steps",
          label: "MODTAGERKONTROL:",
          required: false,
        },
        {
          format: {
            alternatives: [
              {
                identifier: "downfall",
                label: "Fald på ledninger",
              },
              {
                identifier: "kotes",
                label: "Koter på brønde/knudepunkter",
              },
              {
                identifier: "leveling",
                label: "Udjævning/omkringfyldning",
              },
              {
                identifier: "filling",
                label: "Tilfyldning/komprimering",
              },
              {
                identifier: "trans",
                label: "Overgange/tilslutninger",
              },
              {
                identifier: "reestablish",
                label: "Retablering",
              },
              {
                identifier: "location",
                label: "Placering som på tegning",
              },
            ],
            type: "choice",
          },
          identifier: "execontrol-steps",
          label: "UDFØRELSESKONTROL:",
          required: false,
        },
        {
          format: {
            alternatives: [
              {
                identifier: "test",
                label: "Funktionsafprøvning",
              },
              {
                identifier: "agreement",
                label: "Udført som aftalt",
              },
              {
                identifier: "rev-drawing",
                label: "Revideret tegning som udført",
              },
              {
                identifier: "pumpsalarm",
                label: "Pumper, alarmer, højvandslukker",
              },
              {
                identifier: "cleaning",
                label: "Oprydning  ",
              },
            ],
            type: "choice",
          },
          identifier: "endcontrol-steps",
          label: "SLUTKONTROL:",
          required: false,
        },
        {
          format: {
            alternatives: [
              {
                identifier: "instruction",
                label: "Driftsinstruktion",
              },
              {
                identifier: "maintaininstruction",
                label: "Vedligeholdsinstruktion",
              },
              {
                identifier: "manual",
                label: "Brugervejledning",
              },
              {
                identifier: "specialcomp2",
                label: "Særlige komponenter",
              },
            ],
            type: "choice",
          },
          identifier: "operationcontrol-steps",
          label: "DRIFT- OG VEDLIGEHOLD:",
          required: false,
        },
        {
          format: {
            multiline: true,
            type: "text",
          },
          identifier: "note-workplace",
          label: "Bemærkninger",
          required: false,
        },
      ],
    },
  },
  workplaceRegistration: 1,
};
