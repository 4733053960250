import {CustomerUrl} from "../resource-urls";
import {ResourceInstance} from "./base";

export interface Customer extends ResourceInstance<CustomerUrl> {
  readonly active: boolean;
  readonly address: string;
  readonly alias: string;
  readonly attention: string;
  readonly barred: boolean;
  readonly billingEmail: string;
  readonly c5_account: string | null;
  readonly c5_recid: number | null;
  readonly cellphone: string;
  readonly city: string;
  readonly createInvoices: boolean;
  readonly disallowTaskValidation: boolean | undefined;
  readonly favorite: boolean;
  readonly logEmail: string;
  readonly mergedTo: CustomerUrl | null | undefined;
  readonly name: string;
  readonly phone: string;
  readonly postalCode: string;
  readonly projectManagerName: string;
  readonly receiveLogMails: boolean;
  readonly remoteUrl: string;
  readonly vatNumber: string;
}

export const emptyCustomer: Omit<Customer, "id" | "url"> = {
  active: true,
  address: "",
  alias: "",
  attention: "",
  barred: false,
  billingEmail: "",
  // eslint-disable-next-line @typescript-eslint/naming-convention
  c5_account: null,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  c5_recid: null,
  cellphone: "",
  city: "",
  createInvoices: true,
  disallowTaskValidation: false,
  favorite: false,
  logEmail: "",
  mergedTo: null,
  name: "",
  phone: "",
  postalCode: "",
  projectManagerName: "",
  receiveLogMails: true,
  remoteUrl: "",
  vatNumber: "",
};
