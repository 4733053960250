import {getCropColorMap} from "@co-frontend-libs/redux";
import bowser from "bowser";
import _ from "lodash";
import React from "react";
import {useSelector} from "react-redux";
import {
  DEFAULT_DISPLAY_POLYGONS_ZOOM_THRESHOLD,
  FieldPolygons,
  LocationWithGeoJson,
} from "../../field-polygons";

interface FieldsProps {
  fields: readonly LocationWithGeoJson[];
  map: google.maps.Map;
}

export const Fields = React.memo(function Fields(props: FieldsProps): JSX.Element {
  const {fields: filteredLocationArray, map} = props;

  const cropColorMap = useSelector(getCropColorMap);

  // show polygons at another zoom-level; worth the performance
  // hit on overview map -- except for iOS, not worth crashing
  const displayPolygonsZoomThreshold = bowser.ios
    ? DEFAULT_DISPLAY_POLYGONS_ZOOM_THRESHOLD
    : DEFAULT_DISPLAY_POLYGONS_ZOOM_THRESHOLD - 1;
  return (
    <FieldPolygons
      cropColorMap={cropColorMap}
      displayPolygonsZoomThreshold={displayPolygonsZoomThreshold}
      locations={filteredLocationArray}
      map={map}
      readonlySet={undefined}
      selected={undefined}
      onSelect={undefined}
    />
  );
}, _.isEqual);
