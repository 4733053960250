import {
  Card,
  CardHeader,
  CardMedia,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import {FormattedMessage, FormattedNumber, defineMessages, useIntl} from "react-intl";

const messages = defineMessages({
  total: {
    defaultMessage: "Total",
    id: "spraylog-totals-card.card-title.total",
  },
});

interface LogRowProps {
  area: number;
  count: number;
  product: string;
  unit: string;
}

const LogRow = ({area, count, product, unit}: LogRowProps): JSX.Element => {
  return (
    <TableRow>
      <TableCell>{product}</TableCell>
      <TableCell>
        <FormattedNumber maximumFractionDigits={2} value={area} />{" "}
        <FormattedMessage defaultMessage="ha" id="spraylog-totals-card.label.ha" />
      </TableCell>
      <TableCell>
        <FormattedNumber maximumFractionDigits={2} value={count} /> {unit}
      </TableCell>
    </TableRow>
  );
};

interface SprayLogTotalsCardProps {
  totals: ReadonlyMap<string, {area: number; count: number; unit: string}>;
}

export const SprayLogTotalsCard = ({totals}: SprayLogTotalsCardProps): JSX.Element => {
  const {formatMessage} = useIntl();

  const taskRows: JSX.Element[] = [];
  const taskKeys = Array.from(totals.keys());
  taskKeys.sort();
  taskKeys.forEach((key) => {
    const total = totals.get(key) as {
      area: number;
      count: number;
      unit: string;
    };
    taskRows.push(
      <LogRow
        key={`task-${key}`}
        area={total.area}
        count={total.count}
        product={key}
        unit={total.unit}
      />,
    );
  });

  return (
    <Card style={{margin: "1em"}}>
      <CardHeader title={formatMessage(messages.total)} />
      <CardMedia>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Middel"
                  id="spraylog-totals-card.table-header.product"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Areal"
                  id="spraylog-totals-card.table-header.area"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Mængde"
                  id="spraylog-totals-card.table-header.count"
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{taskRows}</TableBody>
        </Table>
      </CardMedia>
    </Card>
  );
};
