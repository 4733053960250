import {Project} from "@co-common-libs/resources";
import {actions, getProjectLookup} from "@co-frontend-libs/redux";
import {Tab, Tabs} from "@material-ui/core";
import {BackToolbar, DoLoadInstance, PageLayout, ProjectDetails} from "app-components";
import {LoadInstanceRelated, useQueryParameter} from "app-utils";
import React, {useCallback} from "react";
import {useIntl} from "react-intl";
import {useDispatch} from "react-redux";
import {ProjectLogAppendices} from "./project-log-appendices";
import {ProjectPhotoAppendices} from "./project-photo-appendices";
import {ProjectTaskTable} from "./project-task-table";

export enum ProjectPageTab {
  Details = "details",
  Tasks = "tasks",
  Photos = "photos",
  Logs = "logs",
}

interface ProjectPageProps {
  instance: Project;
}

export function ProjectPage(props: ProjectPageProps): JSX.Element {
  const {instance: project} = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const activeTab = useQueryParameter<ProjectPageTab>("tab", ProjectPageTab.Details);

  const handleTabChange = useCallback(
    (_event: React.ChangeEvent<unknown>, value: string): void => {
      dispatch(actions.putQueryKey("tab", value));
    },
    [dispatch],
  );

  let withPadding;
  let withBottomScrollPadding;
  let content;
  if (activeTab === ProjectPageTab.Details) {
    withPadding = true;
    withBottomScrollPadding = false;
    content = (
      <div data-testid="project-details">
        <ProjectDetails project={project} />
      </div>
    );
  } else if (activeTab === ProjectPageTab.Tasks) {
    withPadding = false;
    withBottomScrollPadding = true;
    content = (
      <div data-testid="project-tasks">
        <ProjectTaskTable project={project} />
      </div>
    );
  } else if (activeTab === ProjectPageTab.Photos) {
    withPadding = true;
    withBottomScrollPadding = true;
    content = (
      <div data-testid="project-photos">
        <ProjectPhotoAppendices project={project} />
      </div>
    );
  } else if (activeTab === ProjectPageTab.Logs) {
    withPadding = true;
    withBottomScrollPadding = true;
    content = (
      <div data-testid="project-logs">
        <ProjectLogAppendices project={project} />
      </div>
    );
  }

  const tabs = (
    <Tabs scrollButtons="auto" value={activeTab} variant="scrollable" onChange={handleTabChange}>
      <Tab
        label={intl.formatMessage({defaultMessage: "Detaljer"})}
        value={ProjectPageTab.Details}
      />
      <Tab label={intl.formatMessage({defaultMessage: "Opgaver"})} value={ProjectPageTab.Tasks} />
      <Tab label={intl.formatMessage({defaultMessage: "Fotos"})} value={ProjectPageTab.Photos} />
      <Tab label={intl.formatMessage({defaultMessage: "Logs"})} value={ProjectPageTab.Logs} />
    </Tabs>
  );

  return (
    <PageLayout
      tabs={tabs}
      toolbar={
        <BackToolbar
          title={intl.formatMessage(
            {
              defaultMessage: "{projectNumber}: {name}",
            },
            {
              name: project?.name,
              projectNumber: project?.projectNumber,
            },
          )}
        />
      }
      withBottomScrollPadding={withBottomScrollPadding}
      withPadding={withPadding}
    >
      {content}
    </PageLayout>
  );
}

const related: LoadInstanceRelated = [
  {
    memberName: "customer",
    resourceType: "customer",
    type: "targetOfForeignKey",
  },
  {
    memberName: "project",
    resourceType: "task",
    type: "hasForeignKey",
  },
  {
    memberName: "project",
    resourceType: "projectFile",
    type: "hasForeignKey",
  },
];

export function LoadProjectPage(): JSX.Element {
  const intl = useIntl();
  return (
    <DoLoadInstance<"project">
      Component={ProjectPage}
      loadingTitle={intl.formatMessage({defaultMessage: "Projekt"})}
      lookupSelector={getProjectLookup}
      related={related}
      resourceName="project"
    />
  );
}
