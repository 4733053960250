import {
  CalculatorSpecification,
  PriceGroup,
  Product,
  ProductUrl,
  ProductUseWithOrder,
  Unit,
  WorkType,
} from "@co-common-libs/resources";
import {Grid} from "@material-ui/core";
import React, {useCallback, useMemo, useState} from "react";
import {DisplayGridItemWithEntryCallbacks} from "./display-grid-item";
import {ProductUseDetailsDialog} from "./product-use-details-dialog";

interface DisplayGridProps {
  conversionRelatedValues: {
    readonly [unit: string]: number | null;
  } | null;
  onCountChange: (identifier: string, value: number | null) => void;
  onDeleteClick: ((identifier: string) => void) | null;
  onNotesChange: ((identifier: string, value: string) => void) | null;
  onOursChange: (identifier: string, value: boolean) => void;
  onSwitchProduct: ((identifier: string, productUrl: ProductUrl) => void) | null;
  onSwitchProductClick: ((identifier: string) => void) | null;
  productDialogPreferredProductURLs: readonly ProductUrl[] | undefined;
  productDialogPriceGroups: readonly PriceGroup[] | undefined;
  productDialogWorkType: WorkType | undefined;
  showIconColumn: boolean;
  showNotes: boolean;
  showRelationConversionColumns: boolean;
  sortedProductUses: readonly {
    readonly autoProduct: boolean;
    readonly calculator: CalculatorSpecification | null;
    readonly conversionUnit: Unit | null;
    readonly editable: boolean;
    readonly identifier: string;
    readonly notesEditable: boolean;
    readonly product: Product;
    readonly productUse: ProductUseWithOrder;
    readonly removable: boolean;
    readonly switchable: boolean;
    readonly unit: Unit | null;
  }[];
}

export const DisplayGrid = React.memo(function DisplayGrid(props: DisplayGridProps): JSX.Element {
  const {
    conversionRelatedValues,
    onCountChange,
    onDeleteClick,
    onNotesChange,
    onOursChange,
    onSwitchProduct,
    productDialogPreferredProductURLs,
    productDialogPriceGroups,
    productDialogWorkType,
    showIconColumn,
    showNotes,
    showRelationConversionColumns,
    sortedProductUses,
  } = props;

  const [detailsDialogProductUseIdentifier, setDetailsDialogProductUseIdentifier] = useState<
    string | null
  >(null);
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);

  const detailsDialogData = useMemo(() => {
    if (!detailsDialogProductUseIdentifier) {
      return null;
    }
    const entry = sortedProductUses.find(
      (productUseWithData) => productUseWithData.identifier === detailsDialogProductUseIdentifier,
    );
    if (!entry) {
      return null;
    }
    return entry;
  }, [detailsDialogProductUseIdentifier, sortedProductUses]);

  const handleDetailButtonClick = useCallback((identifier: string): void => {
    setDetailsDialogProductUseIdentifier(identifier);
    setDetailsDialogOpen(true);
  }, []);

  const handleDetailsDialogCancel = useCallback((): void => {
    setDetailsDialogOpen(false);
  }, []);

  const handleDetailsDialogOk = useCallback(
    (changed: {notes?: string; ours?: boolean; product?: ProductUrl}): void => {
      if (!detailsDialogProductUseIdentifier) {
        return;
      }
      const {notes, ours, product} = changed;
      if (ours !== undefined) {
        onOursChange(detailsDialogProductUseIdentifier, ours);
      }
      if (onNotesChange && notes !== undefined) {
        onNotesChange(detailsDialogProductUseIdentifier, notes);
      }
      if (onSwitchProduct && product) {
        onSwitchProduct(detailsDialogProductUseIdentifier, product);
      }

      setDetailsDialogOpen(false);
    },
    [detailsDialogProductUseIdentifier, onNotesChange, onOursChange, onSwitchProduct],
  );

  const handleDetailsDelete = useCallback(() => {
    if (!detailsDialogProductUseIdentifier || !onDeleteClick) {
      return;
    }
    onDeleteClick(detailsDialogProductUseIdentifier);
    setDetailsDialogOpen(false);
  }, [detailsDialogProductUseIdentifier, onDeleteClick]);

  const items = sortedProductUses.map(
    ({
      autoProduct,
      calculator,
      conversionUnit,
      editable,
      identifier,
      notesEditable,
      product,
      productUse,
      removable,
      switchable,
      unit,
    }) => (
      <DisplayGridItemWithEntryCallbacks
        key={identifier}
        autoProduct={autoProduct}
        calculator={calculator}
        conversionRelatedValues={conversionRelatedValues}
        conversionUnit={conversionUnit}
        editable={editable}
        identifier={identifier}
        notesEditable={notesEditable}
        product={product}
        productUse={productUse}
        removable={removable}
        showIconColumn={showIconColumn}
        showNotes={showNotes}
        showRelationConversionColumns={showRelationConversionColumns}
        switchable={switchable}
        unit={unit}
        onCountChange={onCountChange}
        onDeleteClick={onDeleteClick}
        onDetailButtonClick={handleDetailButtonClick || null}
        onNotesChange={onNotesChange}
        onOursChange={onOursChange}
        onSwitchProduct={onSwitchProduct}
        onSwitchProductClick={null}
      />
    ),
  );

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {items}
        </Grid>
      </Grid>
      <ProductUseDetailsDialog
        autoProduct={!!detailsDialogData?.autoProduct}
        editable={!!detailsDialogData?.editable}
        notesEditable={!!detailsDialogData?.notesEditable}
        open={detailsDialogOpen}
        product={detailsDialogData?.product}
        productDialogPreferredProductURLs={productDialogPreferredProductURLs}
        productDialogPriceGroups={productDialogPriceGroups}
        productDialogWorkType={productDialogWorkType}
        productUse={detailsDialogData?.productUse}
        removable={!!detailsDialogData?.removable}
        showNotes={showNotes}
        switchable={!!detailsDialogData?.switchable}
        withSwitchProduct={!!onSwitchProduct}
        onCancel={handleDetailsDialogCancel}
        onDeleteClick={onDeleteClick ? handleDetailsDelete : null}
        onOk={handleDetailsDialogOk}
      />
    </>
  );
});
