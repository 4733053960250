import {
  PriceItem,
  PriceItemUrl,
  PriceItemUse,
  ReportingSpecification,
  Unit,
  UnitUrl,
} from "@co-common-libs/resources";
import {getUnitCode} from "../unit";
import {getAllTransferUnits} from "./get-all-transfer-units";
import {getPriceItemsThatCanBeUsedInLog} from "./get-price-items-that-can-be-used-in-log";

const getReadonlyPriceItemsFromUnits = (
  reportingSpecification: ReportingSpecification,
  priceItemUseList: readonly PriceItemUse[],
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined,
  unitLookup: (url: UnitUrl) => Unit | undefined,
): Set<string> => {
  const readonlyPriceItems = new Set<string>();
  const transferUnits = getAllTransferUnits(reportingSpecification);
  if (!transferUnits.size) {
    return readonlyPriceItems;
  }

  priceItemUseList.forEach((priceItemUse) => {
    const priceItem = priceItemLookup(priceItemUse.priceItem);
    if (
      priceItem &&
      priceItem.includeInLogs &&
      !readonlyPriceItems.has(priceItemUse.priceItem) &&
      transferUnits.has(getUnitCode(priceItem, unitLookup))
    ) {
      readonlyPriceItems.add(priceItemUse.priceItem);
    }
  });
  return readonlyPriceItems;
};

export const getReadonlyPriceItems = (
  reportingSpecification: ReportingSpecification,
  priceItemUseList: readonly PriceItemUse[],
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined,
  unitLookup: (url: UnitUrl) => Unit | undefined,
): Set<string> => {
  const readonlyPriceItems =
    reportingSpecification?.workplaceData.delivery?.logPriceItems ||
    reportingSpecification?.workplaceData.pickup?.logPriceItems ||
    reportingSpecification?.workplaceData.workplace?.logPriceItems
      ? getPriceItemsThatCanBeUsedInLog(unitLookup, priceItemUseList, priceItemLookup)
      : new Set<string>();

  return new Set([
    ...readonlyPriceItems,
    ...getReadonlyPriceItemsFromUnits(
      reportingSpecification,
      priceItemUseList,
      priceItemLookup,
      unitLookup,
    ),
  ]);
};
