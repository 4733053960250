import {LocationType, LocationTypeUrl} from "@co-common-libs/resources";
import {LocationTypeDialog} from "@co-frontend-libs/components";
import {AppState, getCustomerSettings, getLocationTypeArray} from "@co-frontend-libs/redux";
import {connect} from "react-redux";
import {createSelector, createStructuredSelector} from "reselect";

interface LocationTypeDialogStateProps {
  defaultFieldType: string;
  locationTypeArray: readonly LocationType[];
  showFieldType: boolean;
}

interface LocationTypeDialogOwnProps {
  onCancel(): void;
  onOk(url: LocationTypeUrl | null): void;
  open: boolean;
}

const getFieldDefaultLocationType: (state: AppState) => string = createSelector(
  getCustomerSettings,
  (customerSettings) => customerSettings.fieldDefaultLocationType || "",
);

const getCustomerFields: (state: AppState) => boolean = createSelector(
  getCustomerSettings,
  (customerSettings) => customerSettings.customerFields,
);

export const ConnectedLocationTypeDialog: React.ComponentType<LocationTypeDialogOwnProps> = connect<
  LocationTypeDialogStateProps,
  object,
  LocationTypeDialogOwnProps,
  AppState
>(
  createStructuredSelector<AppState, LocationTypeDialogStateProps>({
    defaultFieldType: getFieldDefaultLocationType,
    locationTypeArray: getLocationTypeArray,
    showFieldType: getCustomerFields,
  }),
  {},
)(LocationTypeDialog);
