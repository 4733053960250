export function findFuelSurchargeSpecificationEntry<
  SpecificationUrl extends string,
  Entry extends {
    readonly fromDate: string;
    readonly specification: SpecificationUrl;
    readonly toDate: string;
  },
>(specificationUrl: SpecificationUrl, entryArray: readonly Entry[], date: string): Entry | null {
  return (
    entryArray.find(
      (entry) =>
        entry.specification === specificationUrl && entry.fromDate <= date && entry.toDate >= date,
    ) ?? null
  );
}
