import {ResourceInstance} from "@co-common-libs/resources";
import {DBConnection} from "./db-connection";

export abstract class StoreWrapperBase {
  abstract fetchInstance(url: string): Promise<ResourceInstance>;
  abstract replaceInstance(instance: ResourceInstance): Promise<void>;
  abstract removeInstance(url: string): Promise<void>;
  abstract fetchAll(): Promise<ResourceInstance[]>;
}

export class StoreWrapper extends StoreWrapperBase {
  private dbConnection: DBConnection;
  private storeName: string;
  private activeCalls: number;
  private closing: boolean;
  private closeCallbacks: {
    reject(error: unknown): void;
    resolve(): void;
  } | null;

  constructor(dbConnection: DBConnection, storeName: string) {
    super();
    this.dbConnection = dbConnection;
    this.storeName = storeName;
    this.activeCalls = 0;
    this.closing = false;
    this.closeCallbacks = null;
  }

  private async trackActive<T>(label: string, callback: () => Promise<T>): Promise<T> {
    if (this.closing) {
      throw new Error(`StoreWrapper: closing; cannot perform: ${label}`);
    }
    this.activeCalls += 1;
    let result: Awaited<T>;
    try {
      result = await callback();
    } finally {
      this.activeCalls -= 1;
      if (this.closing && this.activeCalls === 0 && this.closeCallbacks) {
        const {reject, resolve} = this.closeCallbacks;
        // eslint-disable-next-line promise/catch-or-return
        this.dbConnection.close().then(resolve, reject);
      }
    }
    return result;
  }

  fetchInstance(url: string): Promise<ResourceInstance> {
    return this.trackActive("fetchInstance", () =>
      this.dbConnection.fetchInstance(this.storeName, url),
    );
  }

  replaceInstance(instance: ResourceInstance): Promise<void> {
    return this.trackActive("replaceInstance", () =>
      this.dbConnection.replaceInstance(this.storeName, instance),
    );
  }

  removeInstance(url: string): Promise<void> {
    return this.trackActive("removeInstance", () =>
      this.dbConnection.removeInstance(this.storeName, url),
    );
  }

  fetchAll(): Promise<ResourceInstance[]> {
    return this.trackActive("fetchAll", () => this.dbConnection.fetchAll(this.storeName));
  }

  _closeConnection(): Promise<void> {
    this.closing = true;
    if (this.activeCalls) {
      return new Promise<void>((resolve, reject) => {
        this.closeCallbacks = {reject, resolve};
      });
    } else {
      return this.dbConnection.close();
    }
  }

  _deleteDatabase(): Promise<void> {
    return this.dbConnection.deleteDatabase();
  }
}
