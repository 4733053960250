import {
  PriceGroup,
  PriceGroupUrl,
  PriceItem,
  PriceItemUrl,
  Unit,
  UnitUrl,
  WorkType,
} from "@co-common-libs/resources";
import {priceItemIsTime} from "@co-common-libs/resources-utils";
import {
  AppState,
  filterOnActive,
  getCustomerSettingsMember,
  getFilteredBothPrimaryWorkTypeArray,
  getFilteredExternalTaskPrimaryWorkTypeArray,
  getFilteredInternalTaskPrimaryWorkTypeArray,
  getPriceGroupLookup,
  getPriceItemLookup,
  getUnitLookup,
} from "@co-frontend-libs/redux";
import _ from "lodash";
import {createSelector} from "reselect";

export const getDisabledWorkTypes: (state: AppState) => readonly string[] =
  getCustomerSettingsMember("disabledWorkTypes");

export const getSuggestRecentlyUsedWorkTypes: (state: AppState) => boolean =
  getCustomerSettingsMember("suggestRecentlyUsedWorkTypes");

const getWorkshopWorkTypes: (state: AppState) => readonly string[] =
  getCustomerSettingsMember("workshopWorkTypes");

function workTypesWithIdentifiers(
  workTypeArray: readonly WorkType[],
  identifiers: readonly string[],
): readonly WorkType[] {
  if (!identifiers.length) {
    return workTypeArray;
  }
  return workTypeArray.filter((workType) => identifiers.includes(workType.identifier));
}

const getInternalTaskPrimaryWorkshopWorkTypeArray: (state: AppState) => readonly WorkType[] =
  createSelector(
    getFilteredInternalTaskPrimaryWorkTypeArray,
    getWorkshopWorkTypes,
    workTypesWithIdentifiers,
  );

export const getFilteredActiveInternalTaskPrimaryWorkTypeArray: (
  state: AppState,
) => readonly WorkType[] = createSelector(
  getFilteredInternalTaskPrimaryWorkTypeArray,
  filterOnActive,
);

export const getActiveInternalTaskPrimaryWorkshopWorkTypeArray: (
  state: AppState,
) => readonly WorkType[] = createSelector(
  getInternalTaskPrimaryWorkshopWorkTypeArray,
  filterOnActive,
);

export const getFilteredActiveExternalTaskPrimaryWorkTypeArray: (
  state: AppState,
) => readonly WorkType[] = createSelector(
  getFilteredExternalTaskPrimaryWorkTypeArray,
  filterOnActive,
);

export const alwaysFalse = _.constant(false);

function filterWorkTypesWithNonTimePriceItem(
  workTypeArray: readonly WorkType[],
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined,
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined,
  unitLookup: (url: UnitUrl) => Unit | undefined,
): readonly WorkType[] {
  return workTypeArray.filter((workType) => {
    return workType.pricegroups.some((priceGroupURL) => {
      const priceGroup = priceGroupLookup(priceGroupURL);
      if (!priceGroup) {
        return false;
      }
      return priceGroup.priceGroupItemSet.some((priceGroupItem) => {
        const priceItem = priceItemLookup(priceGroupItem.priceItem);
        if (!priceItem) {
          return false;
        }
        return !priceItemIsTime(unitLookup, priceItem);
      });
    });
  });
}

/** Work types with some price group with some price item which isn't time */
const getExternalTaskPrimaryWorkTypeWithNonTimePriceItemArray: (
  state: AppState,
) => readonly WorkType[] = createSelector(
  getFilteredExternalTaskPrimaryWorkTypeArray,
  getPriceGroupLookup,
  getPriceItemLookup,
  getUnitLookup,
  filterWorkTypesWithNonTimePriceItem,
);

export const getActiveExternalTaskPrimaryWorkTypeWithNonTimePriceItemArray: (
  state: AppState,
) => readonly WorkType[] = createSelector(
  getExternalTaskPrimaryWorkTypeWithNonTimePriceItemArray,
  filterOnActive,
);

export const alwaysEmptyArray = _.constant([]);

export const getActiveFilteredBothPrimaryWorkTypeArray: (state: AppState) => readonly WorkType[] =
  createSelector(getFilteredBothPrimaryWorkTypeArray, filterOnActive);
