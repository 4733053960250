import {SettingID, SettingMetaData} from "@co-common-libs/config";
import {SettingEntry} from "@co-common-libs/resources";
import {formatDateNumeric} from "@co-common-libs/utils";
import {IconButton, Theme, makeStyles, useTheme} from "@material-ui/core";
import InformationOutlineIcon from "mdi-react/InformationOutlineIcon";
import React, {useCallback} from "react";
import {ErrorsBlock} from "../errors-block";
import {StatusDot} from "../status-dot";
import {valueDisplayOverrides} from "../ui-overrides";
import {DefaultValueDisplayComponent} from "./default-value-display-component";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

interface SettingViewProps {
  nonBlankSettings: ReadonlySet<SettingID>;
  onOpenDialog: (settingsID: SettingID) => void;
  settingEntry: Readonly<SettingEntry>;
  settingID: SettingID;
  settingMetaData: SettingMetaData;
}

export function SettingView(props: SettingViewProps): JSX.Element {
  const {nonBlankSettings, onOpenDialog, settingEntry, settingID, settingMetaData} = props;

  const handleOpenDialog = useCallback(() => {
    onOpenDialog(settingID);
  }, [onOpenDialog, settingID]);

  const classes = useStyles();
  const theme = useTheme();

  const ValueDisplayComponent = valueDisplayOverrides[settingID] || DefaultValueDisplayComponent;

  return (
    <div className={classes.root}>
      <div style={{display: "flex"}}>
        <div style={{cursor: "pointer", flex: "0 0 50px"}} onClick={handleOpenDialog}>
          <StatusDot settingEntry={settingEntry} />
        </div>

        <div style={{cursor: "pointer", flex: 1}} onClick={handleOpenDialog}>
          <span>{settingMetaData.description}</span>
          <ValueDisplayComponent
            settingEntry={settingEntry}
            settingID={settingID}
            settingMetaData={settingMetaData}
          />
        </div>
        <div
          style={{
            color: theme.palette.grey[500],
            flex: "0 0 100px",
            textAlign: "right",
          }}
        >
          <small>
            <div>{settingID}</div>
            {settingEntry?.lastChanged ? (
              <div>{formatDateNumeric(settingEntry.lastChanged)}</div>
            ) : null}
          </small>
        </div>

        <div style={{flex: "0 0 50px", position: "relative", top: -12}}>
          {settingMetaData.documentationURL ? (
            <IconButton color="primary" href={settingMetaData.documentationURL} target="blank">
              <InformationOutlineIcon />
            </IconButton>
          ) : null}
        </div>
      </div>
      {settingEntry ? (
        <ErrorsBlock
          nonBlankSettings={nonBlankSettings}
          settingMetaData={settingMetaData}
          value={settingEntry.data}
        />
      ) : null}
    </div>
  );
}
