import {CsvImportColumnSpecificationValidatePart} from "@co-common-libs/csv-import-specifications";
import {IntlShape} from "react-intl";
import {ValidationData, runValidation} from "../parse-validate";
import {convertErrorsToStrings} from "../parse-validate-error-to-string";

export function validateImportColumnValues(
  column: CsvImportColumnSpecificationValidatePart,
  columnValues: readonly string[],
  intl: IntlShape,
  data: ValidationData,
): string[] {
  const errors = runValidation(column, columnValues, data);

  if (errors.length) {
    return convertErrorsToStrings(errors, column, intl);
  } else {
    return [];
  }
}
