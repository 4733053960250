import {PriceGroup, PriceGroupUrl} from "@co-common-libs/resources";
import {FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import React, {useCallback, useEffect} from "react";

interface PriceGroupSelectionPartProps {
  onSelect: (url: PriceGroupUrl) => void;
  priceGroupList: readonly PriceGroup[];
  selected?: PriceGroupUrl | undefined;
}

export const PriceGroupSelectionPart = React.memo(function PriceGroupSelectionPart(
  props: PriceGroupSelectionPartProps,
): JSX.Element {
  const {onSelect, priceGroupList, selected} = props;

  useEffect(() => {
    if (priceGroupList.length === 1) {
      const {url} = priceGroupList[0];
      if (selected !== url) {
        onSelect(url);
      }
    }
    // only once/on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelect = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const {value} = event.target;
      onSelect(value as PriceGroupUrl);
    },
    [onSelect],
  );

  const entries = priceGroupList.map((priceGroup) => (
    <FormControlLabel
      key={priceGroup.url}
      control={<Radio />}
      label={priceGroup.name}
      value={priceGroup.url}
    />
  ));
  return (
    <RadioGroup name="pricegroup" value={selected || ""} onChange={handleSelect}>
      {entries}
    </RadioGroup>
  );
});
