import {Customer} from "@co-common-libs/resources";
import {getActiveAndInactiveCustomerArray} from "@co-frontend-libs/redux";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {computeInputErrorText} from "./compute-input-error-text";
import {computeInputWarningText} from "./compute-input-warning-text";
import {CustomerFormErrorsAndWarnings, CustomerFormInputDefinitions} from "./customer-form";
import {CustomerFormPart} from "./types";

export function useCustomerFormErrorsAndWarnings(
  formCustomer: CustomerFormPart,
  editCustomer: Customer | null,
  fieldDefinitions: CustomerFormInputDefinitions,
): CustomerFormErrorsAndWarnings {
  const intl = useIntl();

  const {requiredInputFields, visibleInputFields} = fieldDefinitions;

  const customers = useSelector(getActiveAndInactiveCustomerArray);

  const errorText = computeInputErrorText(
    intl,
    visibleInputFields,
    requiredInputFields,
    customers,
    formCustomer,
    editCustomer,
  );

  const warningText = computeInputWarningText(
    intl,
    visibleInputFields,
    customers,
    formCustomer,
    editCustomer,
  );

  return {
    errorText,
    warningText,
  };
}
