import {formatDateTime} from "@co-common-libs/utils";
import {NetworkError} from "@co-frontend-libs/utils";
import {CircularProgress} from "@material-ui/core";
import React from "react";
import {FormattedMessage} from "react-intl";

interface LessorSyncProps {
  uploadDoneTimestamp?: string | undefined;
  uploadError?: NetworkError | string | undefined;
  uploadStartTimestamp?: string | undefined;
}

export const LessorSync = React.memo(
  ({uploadDoneTimestamp, uploadError, uploadStartTimestamp}: LessorSyncProps) => {
    let uploadErrorBlock;
    if (uploadError) {
      if (uploadError instanceof NetworkError) {
        uploadErrorBlock = (
          <FormattedMessage
            defaultMessage="Fejl: Forbindelse til CustomOffice-server tabt."
            id="lessor-sync.label.upload-offline"
            tagName="div"
          />
        );
      } else if (typeof uploadError === "string") {
        uploadErrorBlock = (
          <FormattedMessage
            defaultMessage="Fejl ved upload: {error}"
            id="lessor-sync.label.upload-error-string"
            tagName="div"
            values={{error: uploadError}}
          />
        );
      } else {
        uploadErrorBlock = (
          <FormattedMessage
            defaultMessage="Fejl ved upload."
            id="lessor-sync.label.upload-error"
            tagName="div"
          />
        );
      }
    }
    let uploadStatusBlock;
    let spinner;
    if (uploadStartTimestamp) {
      if (uploadDoneTimestamp) {
        uploadStatusBlock = (
          <FormattedMessage
            defaultMessage="Sidste upload startet: {start}, fuldført: {done}"
            id="lessor-sync.label.upload-start-done"
            tagName="div"
            values={{
              done: formatDateTime(uploadDoneTimestamp),
              start: formatDateTime(uploadStartTimestamp),
            }}
          />
        );
      } else {
        uploadStatusBlock = (
          <FormattedMessage
            defaultMessage="Sidste upload startet: {start}"
            id="lessor-sync.label.upload-start"
            tagName="div"
            values={{start: formatDateTime(uploadStartTimestamp)}}
          />
        );
      }
      if (!uploadDoneTimestamp && !uploadError) {
        spinner = <CircularProgress />;
      }
    }
    return (
      <div>
        {uploadStatusBlock}
        {uploadErrorBlock}
        {spinner}
      </div>
    );
  },
);

LessorSync.displayName = "LessorSync";
