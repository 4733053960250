import {LocationUrl, ProductUrl} from "@co-common-libs/resources";
import {getLocationProductNonZeroCounts} from "@co-common-libs/resources-utils";
import {identifierComparator, mapFilter, notUndefined} from "@co-common-libs/utils";
import {
  getLocationArray,
  getLocationLookup,
  getLocationStorageAdjustmentArray,
  getLocationStorageChangeArray,
  getLocationStorageStatusArray,
  getLocationTypeArray,
  getLocationTypeLookup,
  getProductArray,
} from "@co-frontend-libs/redux";
import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import {ProductCard} from "./product-card";

interface LocationStorageCardsProps {
  filterToConfiguredLocations: boolean;
  onlyLocationsForCustomer?: string;
  selectedLocations: ReadonlySet<string>;
  selectedLocationTypes: ReadonlySet<string>;
  selectedProducts: ReadonlySet<string>;
  showZeroAmountLocations: boolean;
}

export function LocationStorageCards(props: LocationStorageCardsProps): JSX.Element {
  const {
    filterToConfiguredLocations,
    onlyLocationsForCustomer,
    selectedLocations,
    selectedLocationTypes,
    selectedProducts,
    showZeroAmountLocations,
  } = props;

  const locationStorageStatusArray = useSelector(getLocationStorageStatusArray);
  const locationStorageChangeArray = useSelector(getLocationStorageChangeArray);
  const locationStorageAdjustmentArray = useSelector(getLocationStorageAdjustmentArray);
  const locationArray = useSelector(getLocationArray);
  const locationTypeLookup = useSelector(getLocationTypeLookup);
  const locationLookup = useSelector(getLocationLookup);
  const productArray = useSelector(getProductArray);
  const locationTypeArray = useSelector(getLocationTypeArray);

  // Determine relevant locations in several steps;
  // * only active locations
  // * potentially filtered on onlyLocationsForCustomer
  // * potentially filtered on selectedLocations
  // * potentially filtered on selectedLocationTypes
  const activeLocationArray = useMemo(
    () => locationArray.filter((location) => location.active),
    [locationArray],
  );
  const customerLocationArray = useMemo(() => {
    if (onlyLocationsForCustomer) {
      return activeLocationArray.filter(
        (location) => location.customer === onlyLocationsForCustomer,
      );
    } else {
      return activeLocationArray;
    }
  }, [activeLocationArray, onlyLocationsForCustomer]);
  const selectedLocationArray = useMemo(() => {
    if (selectedLocations.size) {
      return customerLocationArray.filter((location) => selectedLocations.has(location.url));
    } else {
      return customerLocationArray;
    }
  }, [customerLocationArray, selectedLocations]);
  const selectedTypeLocationArray = useMemo(() => {
    if (selectedLocationTypes.size) {
      return selectedLocationArray.filter(
        (location) => location.locationType && selectedLocationTypes.has(location.locationType),
      );
    } else {
      return selectedLocationArray;
    }
  }, [selectedLocationArray, selectedLocationTypes]);

  const selectedLocationURLSet = useMemo(
    () => new Set(selectedTypeLocationArray.map((location) => location.url)),
    [selectedTypeLocationArray],
  );

  // We will onyl display products that are "tracked" for some
  // locationType; on the assumption that those products are the ones
  // the customer cares about storage status for; independently of whether
  // filterToConfiguredLocations is given.
  const trackedProducts = useMemo(
    () => new Set(locationTypeArray.flatMap((locationType) => locationType.products)),
    [locationTypeArray],
  );

  // Construct the product -> location -> count mapping to display
  // in several steps;
  // * Get location -> product -> count mapping -- *without* zero-values:
  //   We need to perform some filtering/injection per location, where
  //   location in the outer level simplifies things. Zero-values should
  //   not normally be displayed.
  // * Filter based on the previously determined locations.
  // * On filterToConfiguredLocations, only keep location -> product entries
  //   where location has a locationType which includes the given products.
  // * On showZeroAmountLocations, ensure that entries for all products on the
  //   locationType for locations with locationType set are present by injecting
  //   zero-value-entries.
  // * Transform to product -> location -> count mapping.
  // * Filter based on selected (if any) or "tracked" products.
  const locationProductCounts = useMemo(
    () =>
      getLocationProductNonZeroCounts(
        locationStorageStatusArray,
        locationStorageAdjustmentArray,
        locationStorageChangeArray,
      ),
    [locationStorageAdjustmentArray, locationStorageChangeArray, locationStorageStatusArray],
  );
  const locationFilteredLocationProductCounts = useMemo(
    () =>
      mapFilter(locationProductCounts, (_productCounts, locationURL) =>
        selectedLocationURLSet.has(locationURL),
      ),
    [locationProductCounts, selectedLocationURLSet],
  );
  const configuredFilteredLocationProductCounts = useMemo(() => {
    if (filterToConfiguredLocations) {
      const result = new Map<LocationUrl, Map<ProductUrl, number>>();
      locationFilteredLocationProductCounts.forEach((productCounts, locationURL) => {
        const location = locationLookup(locationURL);
        const locationTypeURL = location?.locationType;
        const locationType = locationTypeURL && locationTypeLookup(locationTypeURL);
        if (!locationType || !locationType.products || !locationType.products.length) {
          return;
        }
        const filteredProductCounts = mapFilter(productCounts, (_count, productURL) =>
          locationType.products.includes(productURL),
        );
        if (filteredProductCounts.size) {
          result.set(locationURL, filteredProductCounts);
        }
      });
      return result;
    } else {
      return locationFilteredLocationProductCounts;
    }
  }, [
    filterToConfiguredLocations,
    locationFilteredLocationProductCounts,
    locationLookup,
    locationTypeLookup,
  ]);
  const locationProductCountsWithInjectedZeroes = useMemo(() => {
    if (showZeroAmountLocations) {
      const result = new Map(configuredFilteredLocationProductCounts);
      selectedTypeLocationArray.forEach((location) => {
        const locationTypeURL = location.locationType;
        const locationType = locationTypeURL && locationTypeLookup(locationTypeURL);
        if (!locationType || !locationType.products || !locationType.products.length) {
          return;
        }
        const productCounts = result.get(location.url);
        if (productCounts) {
          const newProductCounts = new Map(productCounts);
          locationType.products.forEach((productURL) => {
            if (!newProductCounts.has(productURL)) {
              newProductCounts.set(productURL, 0);
            }
          });
          result.set(location.url, newProductCounts);
        } else {
          result.set(
            location.url,
            new Map(locationType.products.map((productURL) => [productURL, 0])),
          );
        }
      });
      return result;
    } else {
      return configuredFilteredLocationProductCounts;
    }
  }, [
    configuredFilteredLocationProductCounts,
    locationTypeLookup,
    selectedTypeLocationArray,
    showZeroAmountLocations,
  ]);
  const productLocationCounts = useMemo(() => {
    const result = new Map<ProductUrl, Map<LocationUrl, number>>();
    function addToResult(productURL: ProductUrl, locationURL: LocationUrl, count: number): void {
      const locationCounts = result.get(productURL);
      if (locationCounts) {
        console.assert(!locationCounts.has(locationURL));
        locationCounts.set(locationURL, count);
      } else {
        result.set(productURL, new Map([[locationURL, count]]));
      }
    }
    locationProductCountsWithInjectedZeroes.forEach((productCounts, locationURL) => {
      productCounts.forEach((count, productURL) => {
        addToResult(productURL, locationURL, count);
      });
    });
    return result;
  }, [locationProductCountsWithInjectedZeroes]);
  const filteredProductLocationCounts = useMemo(() => {
    if (selectedProducts.size) {
      return mapFilter(productLocationCounts, (_locationCounts, productURL) =>
        selectedProducts.has(productURL),
      );
    } else {
      return mapFilter(productLocationCounts, (_locationCounts, productURL) =>
        trackedProducts.has(productURL),
      );
    }
  }, [productLocationCounts, selectedProducts, trackedProducts]);

  // "Global" product order; computed once and independent of which
  // are actually displayed with current filtering...
  const sortedProductArray = useMemo(
    () =>
      productArray.slice().sort((a, b) => identifierComparator(a.catalogNumber, b.catalogNumber)),
    [productArray],
  );

  return (
    <>
      {sortedProductArray
        .map((product) => {
          const locationCounts = filteredProductLocationCounts.get(product.url);
          if (locationCounts) {
            return (
              <ProductCard
                key={product.url}
                includeCustomerColumn={!onlyLocationsForCustomer}
                locationCounts={locationCounts}
                product={product}
              />
            );
          } else {
            return undefined;
          }
        })
        .filter(notUndefined)}
    </>
  );
}
