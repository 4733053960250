import {TableRow} from "@material-ui/core";
import _ from "lodash";
import React from "react";
import {GenericTableCell} from "./generic-table-cell";
import {ColumnSpecifications, RowData} from "./types";

interface GenericTableRowProps<
  FieldID extends string,
  ColumnID extends string,
  KeyType extends string = string,
  DataType extends RowData<FieldID, KeyType> = RowData<FieldID, KeyType>,
> {
  columns: ColumnSpecifications<FieldID, ColumnID, KeyType, DataType>;
  data: DataType;
  rowStyle?: React.CSSProperties | ((data: DataType) => React.CSSProperties) | undefined;
  visibleColumns: readonly ColumnID[];
}

export class GenericTableRow<
  FieldID extends string,
  ColumnID extends string,
  KeyType extends string = string,
  DataType extends RowData<FieldID, KeyType> = RowData<FieldID, KeyType>,
> extends React.Component<GenericTableRowProps<FieldID, ColumnID, KeyType, DataType>> {
  shouldComponentUpdate(
    nextProps: GenericTableRowProps<FieldID, ColumnID, KeyType, DataType>,
  ): boolean {
    return (
      nextProps.columns !== this.props.columns ||
      !_.isEqual(nextProps.data, this.props.data) ||
      !_.isEqual(nextProps.rowStyle, this.props.rowStyle) ||
      !_.isEqual(nextProps.visibleColumns, this.props.visibleColumns)
    );
  }
  render(): JSX.Element {
    const {columns, data, rowStyle, visibleColumns} = this.props;
    let style: React.CSSProperties = {verticalAlign: "top"};
    if (rowStyle) {
      if (typeof rowStyle === "function") {
        style = {...style, ...rowStyle(data)};
      } else {
        style = {...style, ...rowStyle};
      }
    }
    const columnEntries = visibleColumns.map((columnID) => {
      const column = columns[columnID];
      return <GenericTableCell key={columnID} column={column} data={data} rowStyle={style} />;
    });
    return <TableRow>{columnEntries}</TableRow>;
  }
}
