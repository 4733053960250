import {
  KrPerLiterFuelSurchargeSpecification,
  KrPerLiterFuelSurchargeSpecificationUrl,
} from "@co-common-libs/resources";
import {ColumnSpecifications, GenericTable, RowData} from "@co-frontend-libs/components";
import {
  actions,
  getKrPerLiterFuelSurchargeSpecificationArray,
  getTableSortingState,
} from "@co-frontend-libs/redux";
import _ from "lodash";
import CheckIcon from "mdi-react/CheckIcon";
import React, {useCallback, useMemo} from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

const TABLE_SORTING_IDENTIFIER = "KrPerLiterFuelSurchargeSpecificationTable";

type KrPerLiterFuelSurchargeSpecificationTableColumnId = "active" | "description" | "name";

type KrPerLiterFuelSurchargeSpecificationTableFieldId = "active" | "description" | "name";

interface KrPerLiterFuelSurchargeSpecificationTableDataType
  extends RowData<
    KrPerLiterFuelSurchargeSpecificationTableFieldId,
    KrPerLiterFuelSurchargeSpecificationUrl
  > {
  active: boolean;
  name: string;
}

function renderActive(data: KrPerLiterFuelSurchargeSpecificationTableDataType): JSX.Element {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return data.active ? <CheckIcon /> : <></>;
}

function buildColumnSpecifications(
  onClick: ((fuelSurchargeSpecification: string) => void) | undefined,
): ColumnSpecifications<
  KrPerLiterFuelSurchargeSpecificationTableFieldId,
  KrPerLiterFuelSurchargeSpecificationTableColumnId,
  KrPerLiterFuelSurchargeSpecificationUrl,
  KrPerLiterFuelSurchargeSpecificationTableDataType
> {
  return {
    active: {
      field: "active",
      label: <FormattedMessage defaultMessage="Aktiv" />,
      onClick,
      render: renderActive,
    },
    description: {
      field: "description",
      label: <FormattedMessage defaultMessage="Beskrivelse" />,
      onClick,
    },
    name: {
      field: "name",
      label: <FormattedMessage defaultMessage="Navn" />,
      onClick,
    },
  };
}

function buildRowData(
  fuelSurchargSpecificationArray: readonly KrPerLiterFuelSurchargeSpecification[],
): KrPerLiterFuelSurchargeSpecificationTableDataType[] {
  return fuelSurchargSpecificationArray.map((specification) => {
    const {active, description, name, url} = specification;
    return {active, description, key: url, name};
  });
}

interface KrPerLiterFuelSurchargeSpecificationTableProps {
  filterString: string;
  onClick: (specificationUrl: string) => void;
  showInactive: boolean;
}

export function KrPerLiterFuelSurchargeSpecificationTable(
  props: KrPerLiterFuelSurchargeSpecificationTableProps,
): JSX.Element {
  const {filterString, onClick, showInactive} = props;

  const krPerLiterFuelSurchargeSpecificationArray = useSelector(
    getKrPerLiterFuelSurchargeSpecificationArray,
  );

  const dispatch = useDispatch();

  const sortedFilteredSpecifications = useMemo(
    () =>
      _.sortBy(
        showInactive
          ? krPerLiterFuelSurchargeSpecificationArray
          : krPerLiterFuelSurchargeSpecificationArray.filter(({active}) => active),
        [({name}) => name, ({url}) => url],
      ),
    [krPerLiterFuelSurchargeSpecificationArray, showInactive],
  );

  const columnSpecifications = useMemo(() => buildColumnSpecifications(onClick), [onClick]);

  const rowData = useMemo(
    () => buildRowData(sortedFilteredSpecifications),
    [sortedFilteredSpecifications],
  );

  const visibleColumns =
    useMemo((): readonly KrPerLiterFuelSurchargeSpecificationTableColumnId[] => {
      if (showInactive) {
        return ["name", "description", "active"];
      } else {
        return ["name", "description"];
      }
    }, [showInactive]);

  const sortingStateSelector = useMemo(
    () => getTableSortingState(TABLE_SORTING_IDENTIFIER, "c5_machine", "ASC"),
    [],
  );
  const {sortDirection, sortKey} = useSelector(sortingStateSelector);

  const handleHeaderClick = useCallback(
    (key: KrPerLiterFuelSurchargeSpecificationTableColumnId): void => {
      let direction: "ASC" | "DESC" = "ASC";
      if (sortKey === key && sortDirection === "ASC") {
        direction = "DESC";
      }
      const action = actions.putTableSortingState(TABLE_SORTING_IDENTIFIER, key, direction);
      dispatch(action);
    },
    [dispatch, sortKey, sortDirection],
  );

  return (
    <GenericTable
      columns={columnSpecifications}
      entries={rowData}
      filterString={filterString}
      sortBy={sortKey as any}
      sortDirection={sortDirection}
      visibleColumns={visibleColumns}
      onHeaderClick={handleHeaderClick}
    />
  );
}
