import {
  CustomerUrl,
  ExpectedAmount,
  ExpectedAmountUrl,
  PatchOperation,
  UnitUrl,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {getUnitCode} from "@co-common-libs/resources-utils";
import {caseAccentInsensitiveCollator} from "@co-common-libs/utils";
import {DecimalField, IntegerField, ResponsiveDialog} from "@co-frontend-libs/components";
import {ConnectedExternalWorkTypeWithNonTimePriceItemDialog} from "@co-frontend-libs/connected-components";
import {
  actions,
  getExpectedAmountArray,
  getExpectedAmountLookup,
  getPriceGroupLookup,
  getPriceItemLookup,
  getUnitArray,
  getUnitLookup,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {
  Button,
  Checkbox,
  DialogContent,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import {useEventTargetCheckedCallback} from "app-utils";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {FormattedMessage, defineMessages, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuid} from "uuid";
import {WorkTypeChip} from "./work-type-chip";

const messages = defineMessages({
  addExpectedAmount: {
    defaultMessage: "Opret områdebudget",
  },
  amount: {
    defaultMessage: "Mængde",
  },
  completed: {
    defaultMessage: "Afsluttet",
  },
  editExpectedAmount: {
    defaultMessage: "Redigér områdebudget",
  },
  selectWorkTypeButton: {
    defaultMessage: "Vælg arbejdsområde",
  },
  unit: {
    defaultMessage: "Enhed",
  },
  year: {
    defaultMessage: "År",
  },
});

interface ExpectedAmountDialogProps {
  customer?: CustomerUrl;
  expectedAmountURL?: ExpectedAmountUrl | undefined;
  onCancel?: () => void;
  onOk: () => void;
  open: boolean;
}

export const ExpectedAmountDialog = React.memo(function ExpectedAmountDialog(
  props: ExpectedAmountDialogProps,
): JSX.Element {
  const {customer, expectedAmountURL, onCancel, onOk, open} = props;
  const [amount, setAmount] = useState<number | null>(null);
  const [completed, setCompleted] = useState<boolean>(false);
  const [unit, setUnit] = useState<UnitUrl | null>(null);
  const [workType, setWorkType] = useState<WorkTypeUrl | null>(null);
  const [year, setYear] = useState<number | null>(new Date().getFullYear());
  const [workTypeDialogOpen, setWorkTypeDialogOpen] = useState(false);

  const expectedAmountArray = useSelector(getExpectedAmountArray);
  const expectedAmountLookup = useSelector(getExpectedAmountLookup);
  const priceGroupLookup = useSelector(getPriceGroupLookup);
  const priceItemLookup = useSelector(getPriceItemLookup);
  const unitArray = useSelector(getUnitArray);
  const unitLookup = useSelector(getUnitLookup);
  const workTypeLookup = useSelector(getWorkTypeLookup);

  const setInitialFormState = useCallback(() => {
    setAmount(null);
    setCompleted(false);
    setUnit(null);
    setWorkType(null);
    setYear(new Date().getFullYear());
  }, []);
  useEffect(() => {
    if (open && expectedAmountURL) {
      const expectedAmount = expectedAmountLookup(expectedAmountURL);
      if (expectedAmount) {
        setAmount(expectedAmount.amount);
        setCompleted(expectedAmount.completed);
        setUnit(expectedAmount.unit);
        setWorkType(expectedAmount.workType);
        setYear(expectedAmount.year);
      } else {
        setInitialFormState();
      }
    }
  }, [expectedAmountLookup, expectedAmountURL, open, setInitialFormState]);

  const getUnitListForSelectedWorkType = useCallback(
    (worktypeURL: WorkTypeUrl | null): string[] => {
      if (!worktypeURL) {
        return [];
      }
      const selectedWorkType = workTypeLookup(worktypeURL);
      if (!selectedWorkType || !selectedWorkType.pricegroups) {
        return [];
      }
      const priceGroupURLSet = new Set(selectedWorkType.pricegroups);

      const result: string[] = [];

      priceGroupURLSet.forEach((priceGroupURL) => {
        const priceGroup = priceGroupLookup(priceGroupURL);
        if (!priceGroup) {
          return;
        }
        priceGroup.priceGroupItemSet.forEach((priceGroupItem) => {
          const priceItem = priceItemLookup(priceGroupItem.priceItem);
          if (!priceItem) {
            return;
          }
          const priceItemUnit = getUnitCode(priceItem, unitLookup);
          if (!result.includes(priceItemUnit) && !priceItemUnit.toLowerCase().includes("tim")) {
            result.push(priceItemUnit);
          }
        });
      });
      return result;
    },
    [priceGroupLookup, priceItemLookup, unitLookup, workTypeLookup],
  );

  const handleWorkTypeSelectButton = useCallback(() => setWorkTypeDialogOpen(true), []);

  const amountInput = useRef<HTMLInputElement>();

  const handleWorkTypeDialogOk = useCallback(
    (url: WorkTypeUrl) => {
      const units = getUnitListForSelectedWorkType(url);
      let unitURL: UnitUrl | undefined;

      if (units.length === 1) {
        const unitName = units[0];
        const unitInstance = unitArray.find((u) => u.name === unitName);
        if (unitInstance) {
          unitURL = unitInstance.url;
        }
      } else {
        const lastExpectedAmount = _.last(
          _.sortBy(
            expectedAmountArray.filter((e) => e.workType === url),
            (e) => e.lastChanged,
          ),
        );
        const lastUnit = lastExpectedAmount ? lastExpectedAmount.unit : null;
        if (lastUnit) {
          unitURL = lastUnit;
        }
      }
      setUnit(unitURL || null);
      setWorkType(url);
      setWorkTypeDialogOpen(false);
      setTimeout(() => amountInput.current?.focus());
    },
    [expectedAmountArray, getUnitListForSelectedWorkType, unitArray],
  );

  const handleWorkTypeDialogCancel = useCallback(() => setWorkTypeDialogOpen(false), []);

  const dispatch = useDispatch();
  const handleOk = useCallback(() => {
    if (expectedAmountURL) {
      const patch: PatchOperation<ExpectedAmount>[] = [
        {member: "amount", value: amount},
        {member: "completed", value: completed},
        {member: "unit", value: unit},
        {member: "workType", value: workType},
      ];
      if (year) {
        patch.push({member: "year", value: year});
      }
      dispatch(actions.update(expectedAmountURL, patch));
    } else {
      const id = uuid();
      const url = instanceURL("expectedAmount", id);
      if (!customer || !year) {
        return;
      }
      const instance: ExpectedAmount = {
        amount,
        completed,
        customer,
        id,
        unit,
        url,
        workType,
        year,
      };
      dispatch(actions.create(instance));
    }
    setInitialFormState();
    onOk();
  }, [
    amount,
    completed,
    customer,
    dispatch,
    expectedAmountURL,
    onOk,
    setInitialFormState,
    unit,
    workType,
    year,
  ]);

  const handleCancel = useCallback(() => {
    if (onCancel) {
      setInitialFormState();
      onCancel();
    }
  }, [onCancel, setInitialFormState]);

  const handleCompletedChanged = useEventTargetCheckedCallback(setCompleted, [setCompleted]);

  const handleUnitChanged = useCallback(
    (event: React.ChangeEvent<{name?: string | undefined; value: unknown}>) =>
      setUnit((event.target.value as UnitUrl) || null),
    [setUnit],
  );
  const {formatMessage} = useIntl();

  const workTypeInstance = workType ? workTypeLookup(workType) : null;
  const workTypeBlock = (
    <div>
      <Button color="secondary" variant="contained" onClick={handleWorkTypeSelectButton}>
        {formatMessage(messages.selectWorkTypeButton)}
      </Button>
      {workTypeInstance ? <WorkTypeChip workType={workTypeInstance} /> : null}
    </div>
  );

  const workTypeDialog = (
    <ConnectedExternalWorkTypeWithNonTimePriceItemDialog
      key="work-type-dialog"
      open={!!workTypeDialogOpen}
      onCancel={handleWorkTypeDialogCancel}
      onOk={handleWorkTypeDialogOk}
    />
  );

  const unitArrayForWorktype = getUnitListForSelectedWorkType(workType);
  const dropdownUnits = unitArray
    .filter((u) => unitArrayForWorktype.includes(u.name))
    .sort((a, b) => caseAccentInsensitiveCollator.compare(a.symbol || a.name, b.symbol || b.name));
  const unitSelect = (
    <FormControl fullWidth>
      <InputLabel id="unit-select-label">{formatMessage(messages.unit)}</InputLabel>

      <Select
        disabled={!dropdownUnits.length}
        id="unit-select"
        labelId="unit-select-label"
        value={unit || ""}
        onChange={handleUnitChanged}
      >
        {dropdownUnits.map((u) => (
          <MenuItem key={u.url} value={u.url}>
            {u.symbol || u.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  return (
    <>
      <ResponsiveDialog
        okDisabled={!year || !workType || !unit || !amount}
        open={open}
        title={
          expectedAmountURL
            ? formatMessage(messages.editExpectedAmount)
            : formatMessage(messages.addExpectedAmount)
        }
        onCancel={handleCancel}
        onOk={handleOk}
      >
        <DialogContent>
          <IntegerField
            autoFocus
            fullWidth
            id="year-field"
            label={formatMessage(messages.year)}
            margin="dense"
            maxDigits={4}
            name="year"
            value={year}
            onChange={setYear}
          />
          {workTypeBlock}
          <DecimalField
            fullWidth
            decimalPlaces={2}
            inputRef={amountInput}
            label={formatMessage(messages.amount)}
            margin="dense"
            maxDigits={9}
            value={amount}
            onChange={setAmount}
          />
          {unitSelect}
          <FormControlLabel
            control={
              <Checkbox
                checked={completed}
                id="completed-checkbox"
                name="completed"
                onChange={handleCompletedChanged}
              />
            }
            label={formatMessage(messages.completed)}
          />
          <FormattedMessage
            defaultMessage="Alle felter skal udfyldes"
            id="expected-amount-dialog.label.required"
            tagName="p"
          />
        </DialogContent>
      </ResponsiveDialog>
      {workTypeDialog}
    </>
  );
});
