import {PunchInOut, UserUrl} from "@co-common-libs/resources";
import {
  formatDateTime,
  getMidnights,
  normalisedTimestamp,
  simpleCompare,
} from "@co-common-libs/utils";
import {DeleteDialog} from "@co-frontend-libs/components";
import {AppState, actions, filterOnEmployee, getPunchInOutArray} from "@co-frontend-libs/redux";
import {IconButton, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import DeleteIcon from "mdi-react/DeleteIcon";
import React from "react";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {createSelector, createStructuredSelector} from "reselect";

const getPunchInOutArrayForUser = createSelector(
  (_state: AppState, props: {userURL: UserUrl}) => props.userURL,
  getPunchInOutArray,
  filterOnEmployee,
);

function buildEmployeeTimelineWithExtra(punchInOutArray: readonly PunchInOut[]): readonly {
  extraPunchIn: string[];
  extraPunchOut: string[];
  punchIn: string | null;
  punchOut: string | null;
}[] {
  const simplifiedNormalisedPunchInOutArray = punchInOutArray.map((entry) => ({
    out: entry.action === "PUNCH_OUT",
    timestamp: normalisedTimestamp(entry.punchTimestamp),
  }));
  simplifiedNormalisedPunchInOutArray.sort(
    (a, b) => simpleCompare(a.timestamp, b.timestamp) || simpleCompare(a.out, b.out),
  );
  const result: {
    extraPunchIn: string[];
    extraPunchOut: string[];
    punchIn: string | null;
    punchOut: string | null;
  }[] = [];
  let punchIn = null;
  let extraPunchIn = [];
  for (let i = 0; i < simplifiedNormalisedPunchInOutArray.length; i += 1) {
    const {out, timestamp} = simplifiedNormalisedPunchInOutArray[i];
    if (out) {
      // punch out...
      if (punchIn != null) {
        // ... and previous was punch in
        result.push({
          extraPunchIn,
          extraPunchOut: [],
          punchIn,
          punchOut: timestamp,
        });
        punchIn = null;
        extraPunchIn = [];
      } else {
        // ... but previous was also punch out
        if (result.length) {
          result[result.length - 1].extraPunchOut.push(timestamp);
        } else {
          result.push({
            extraPunchIn: [],
            extraPunchOut: [timestamp],
            punchIn: null,
            punchOut: null,
          });
        }
      }
    } else {
      // punch in...
      if (punchIn == null) {
        // ... and previous was punch out
        punchIn = timestamp;
      } else {
        // ... but previous was also punch in
        extraPunchIn.push(timestamp);
      }
    }
  }
  if (punchIn) {
    // final punch in did not have matching punch out
    result.push({
      extraPunchIn,
      extraPunchOut: [],
      punchIn,
      punchOut: null,
    });
  }
  return result;
}

const getPunchInOutPeriodsForUser = createSelector(
  getPunchInOutArrayForUser,
  buildEmployeeTimelineWithExtra,
);

interface PunchInOutRowProps {
  currentUserIsManager: boolean;
  extraPunchIn: string[];
  extraPunchOut: string[];
  onDeletePunchInClick: (event: React.MouseEvent<unknown, MouseEvent>, punchIn: string) => void;
  onDeletePunchOutClick: (event: React.MouseEvent<unknown, MouseEvent>, punchOut: string) => void;
  punchIn?: string | undefined;
  punchOut?: string | undefined;
}

class PunchInOutRow extends PureComponent<PunchInOutRowProps> {
  @bind
  handleDeletePunchInClick(event: React.MouseEvent<unknown, MouseEvent>): void {
    if (this.props.punchIn) {
      this.props.onDeletePunchInClick(event, this.props.punchIn);
    }
  }
  @bind
  handleDeletePunchOutClick(event: React.MouseEvent<unknown, MouseEvent>): void {
    if (this.props.punchOut) {
      this.props.onDeletePunchOutClick(event, this.props.punchOut);
    }
  }
  render(): JSX.Element {
    const {currentUserIsManager, extraPunchIn, extraPunchOut, punchIn, punchOut} = this.props;
    let extraPunchInText;
    let extraPunchOutText;
    if (extraPunchIn.length) {
      extraPunchInText = `(${extraPunchIn.map(formatDateTime).join(", ")})`;
    }
    if (extraPunchOut.length) {
      extraPunchOutText = `(${extraPunchOut.map(formatDateTime).join(", ")})`;
    }
    let deletePunchInButton;
    let deletePunchOutButton;
    if (currentUserIsManager) {
      if (punchIn) {
        deletePunchInButton = (
          <IconButton onClick={this.handleDeletePunchInClick}>
            <DeleteIcon />
          </IconButton>
        );
      }
      if (punchOut) {
        deletePunchOutButton = (
          <IconButton onClick={this.handleDeletePunchOutClick}>
            <DeleteIcon />
          </IconButton>
        );
      }
    }

    return (
      <TableRow>
        <TableCell>
          {formatDateTime(punchIn)} {deletePunchInButton} {extraPunchInText}
        </TableCell>
        <TableCell>
          {formatDateTime(punchOut)} {deletePunchOutButton} {extraPunchOutText}
        </TableCell>
      </TableRow>
    );
  }
}

interface PunchInOutListStateProps {
  punchInOutArray: readonly PunchInOut[];
  punchInOutPeriods: readonly {
    extraPunchIn: string[];
    extraPunchOut: string[];
    punchIn: string | null;
    punchOut: string | null;
  }[];
}

interface PunchInOutListDispatchProps {
  remove: (url: string) => void;
}

interface PunchInOutListOwnProps {
  currentUserIsManager: boolean;
  date: string;
  userURL: UserUrl;
}

type PunchInOutListProps = PunchInOutListDispatchProps &
  PunchInOutListOwnProps &
  PunchInOutListStateProps;

interface PunchInOutListState {
  entriesToDelete: readonly PunchInOut[];
}

class PunchInOutList extends React.PureComponent<PunchInOutListProps, PunchInOutListState> {
  state: PunchInOutListState = {
    entriesToDelete: [],
  };
  @bind
  handleDeletePunchInClick(_event: unknown, timestamp: string): void {
    const {punchInOutArray} = this.props;
    const entriesToDelete = punchInOutArray.filter(
      (entry) =>
        entry.action === "PUNCH_IN" && normalisedTimestamp(entry.punchTimestamp) === timestamp,
    );
    this.setState({entriesToDelete});
  }
  @bind
  handleDeletePunchOutClick(_event: unknown, timestamp: string): void {
    const {punchInOutArray} = this.props;
    const entriesToDelete = punchInOutArray.filter(
      (entry) =>
        entry.action === "PUNCH_OUT" && normalisedTimestamp(entry.punchTimestamp) === timestamp,
    );
    this.setState({entriesToDelete});
  }
  @bind
  handleDeleteOk(): void {
    const {remove} = this.props;
    const {entriesToDelete} = this.state;
    this.setState({entriesToDelete: []});
    for (let i = 0; i < entriesToDelete.length; i += 1) {
      const entry = entriesToDelete[i];
      remove(entry.url);
    }
  }
  @bind
  handleDeleteCancel(): void {
    this.setState({entriesToDelete: []});
  }
  render(): JSX.Element {
    const {currentUserIsManager, date, punchInOutPeriods} = this.props;
    const {startOfDay, startOfNextDay} = getMidnights(date);
    const filteredPunchInOutPeriods = punchInOutPeriods.filter(
      ({punchIn, punchOut}) =>
        // exclude if punchIn is after day or punchOut is before day;
        // otherwise there should be at least partial overlap...
        !(punchIn && punchIn >= startOfNextDay) && !(punchOut && punchOut <= startOfDay),
    );
    const tableRows = filteredPunchInOutPeriods.map(
      ({extraPunchIn, extraPunchOut, punchIn, punchOut}) => {
        return (
          <PunchInOutRow
            key={punchIn || ""}
            currentUserIsManager={currentUserIsManager}
            extraPunchIn={extraPunchIn}
            extraPunchOut={extraPunchOut}
            punchIn={punchIn || undefined}
            punchOut={punchOut || undefined}
            onDeletePunchInClick={this.handleDeletePunchInClick}
            onDeletePunchOutClick={this.handleDeletePunchOutClick}
          />
        );
      },
    );
    return (
      <>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Stemplet ind"
                  id="user-profile.table-header.punch-in"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Stemplet ud"
                  id="user-profile.table-header.punch-out"
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{cursor: "pointer"}}>{tableRows}</TableBody>
        </Table>
        <DeleteDialog
          open={!!this.state.entriesToDelete.length}
          onCancel={this.handleDeleteCancel}
          onOk={this.handleDeleteOk}
        >
          {this.state.entriesToDelete.length &&
          this.state.entriesToDelete[0].action === "PUNCH_IN" ? (
            <FormattedMessage
              defaultMessage="Slet indstempling?"
              id="user-profile.label.delete-punch-in"
            />
          ) : (
            <FormattedMessage
              defaultMessage="Slet udstempling?"
              id="user-profile.label.delete-punch-out"
            />
          )}
        </DeleteDialog>
      </>
    );
  }
}

const ConnectedPunchInOutList = connect<
  PunchInOutListStateProps,
  PunchInOutListDispatchProps,
  PunchInOutListOwnProps,
  AppState
>(
  createStructuredSelector({
    punchInOutArray: getPunchInOutArrayForUser,
    punchInOutPeriods: getPunchInOutPeriodsForUser,
  }),
  {
    remove: actions.remove,
  },
)(PunchInOutList);

export {ConnectedPunchInOutList as PunchInOutList};
