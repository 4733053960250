import {
  apiVersion as currentApiVersion,
  frontendVersion as currentFrontendVersion,
} from "@co-common-libs/frontend-version";
import {
  Command,
  Patch,
  PatchUnion,
  ResourceInstance,
  ResourceName,
  ResourceTypeUnion,
  TaskUrl,
  TimerStart,
  resourcesConfig,
  urlToId,
} from "@co-common-libs/resources";
import {SECOND_MILLISECONDS} from "@co-common-libs/utils";
import {Query, SerializableError, getCommitDB, getOfflineDB} from "@co-frontend-libs/db-resources";
import {
  ResponseWithData,
  StatusError,
  jsonFetch,
  limitConcurrentPromises,
  sendOnline,
} from "@co-frontend-libs/utils";
import {createAction, createAsyncThunk} from "@reduxjs/toolkit";
import {buildErrorObject} from "../types";
import {ResourcesState} from "./types";
import {diffResourceInstanceProperties} from "./utils";

const CONCURRENT_FETCH_QUERIES = 4;
const limitedJsonFetch = limitConcurrentPromises(CONCURRENT_FETCH_QUERIES, jsonFetch);

const CHANGED_SINCE_OVERSHOOT_MINUTES = 3;

export const loadOffline = createAsyncThunk("resources/loadOffline", async () => {
  const offlineDB = await getOfflineDB();
  return offlineDB.readInitialData();
});

/**
 * @see Query
 *
 * Offline/data-fetch strategy:
 *
 * `Query` instances specify some data to fetch/keep for a single resource
 * type. The same resource instances may be part of the results for multiple
 * queries. We only keep the newest instance for each instance URL.
 *
 * Each `Query` also includes a `Check` instance, used to determine whether
 * data should be kept and/or persisted: Data fetched in relation to a
 * temporary query might also fulfill criteria for some persisted query, and
 * data fetched with the changes-tracking might not fulfill any criteria to
 * be kept at all. Furthermore, when "old" queries are dropped/forgotten,
 * some of "their" data may be kept if it is also matched by some current
 * query.
 *
 * The `Check` specification is also used to determine when to fetch extra
 * "related" data from the server: The appearance of a instance fulfilling the
 * "target"-check for a "keep resource instances that have foreign keys to
 * resource instances fulfilling X" implies that we need to ask the server
 * for instances with such foreign keys. (0 or more may exist; we don't know
 * the number client-side.) The other kind of foreign key rule says to "keep
 * resource instances that some other resource instance fulfilling Y has
 * foreign keys to"; which is simpler to optimise; exactly one instance with
 * the given URL should exist, so either we already have it, or we should
 * fetch it.
 *
 * The set of queries/criteria for data to be kept persisted locally is
 * specified with action `persistedQueriesRequested`. On changes, data for
 * the new queries will be fetched, *before* old queries and their criteria
 * for keeping data objects around are discarded; this protects against edge
 * cases where we might otherwise discard data that fulfills some criteria
 * via foreign keys both in old and new data but not in their intersection...
 *
 * Non-persisted/temporary data to be fetche is specified with
 * `temporaryQueryRequestedForPath`, `temporaryQueryDiscardedForPath`,
 * `temporaryQueryRequestedForKey`, and `temporaryQueryDiscardedForKey`.
 * Queries associated with paths here are also implicitly discarded on
 * navigation. Temporary queries and their data is kept around for a few
 * minutes after being "discarded", so that they may be quickly reinstated,
 * e.g. on navigation back to an archive search result after visiting an
 * instance.
 */
export const persistedQueriesRequested = createAction(
  "resources/persistedQueriesRequested",
  (queries: readonly Query[], forceReload: boolean = false) => ({
    payload: {
      forceReload,
      queries,
    },
  }),
);

/** @see persistedQueriesRequested */
export const temporaryQueriesRequestedForPath = createAction(
  "resources/temporaryQueriesRequestedForPath",
  (
    queries: readonly Query[],
    pathName: string,
    key: string,
    forceReloadRelated: boolean = false,
  ) => ({
    payload: {
      forceReloadRelated,
      key,
      pathName,
      queries,
    },
  }),
);

/** @see persistedQueriesRequested */
export const temporaryQueriesDiscardedForPath = createAction(
  "resources/temporaryQueriesDiscardedForPath",
  (pathName: string, key: string) => ({
    payload: {
      key,
      pathName,
    },
  }),
);

/** @see persistedQueriesRequested */
export const temporaryQueriesRequestedForKey = createAction(
  "resources/temporaryQueriesRequestedForKey",
  (queries: readonly Query[], key: string, forceReloadRelated: boolean = false) => ({
    payload: {
      forceReloadRelated,
      key,
      queries,
    },
  }),
);

/** @see persistedQueriesRequested */
export const temporaryQueriesDiscardedForKey = createAction(
  "resources/temporaryQueriesDiscardedForKey",
  (key: string) => ({
    payload: {
      key,
    },
  }),
);

/**
 * Not intended as part of public API.
 * @see persistedQueriesRequested
 */
export const temporaryQueryPurged = createAction<Query>("resources/temporaryQueryPurged");

/**
 * Not intended as part of public API.
 * @see persistedQueriesRequested
 */
export const performFullFetch = createAsyncThunk<
  {
    readonly results: readonly ResourceInstance[];
    readonly timestamp: string;
  },
  {baseURL: string; query: Query},
  {
    rejectValue: {error: SerializableError; timestamp: string};
    state: {resources: ResourcesState};
  }
>("resources/performFullFetch", ({baseURL, query}, thunkApi) => {
  const {queryString, resourceName} = query;
  const urlPrefix = resourcesConfig[resourceName];
  const listURL = `${baseURL}${urlPrefix}/`;
  const url = queryString ? `${listURL}?${queryString}` : listURL;
  const requestPromise = limitedJsonFetch(url, "GET");
  return requestPromise
    .then((response) => response.data)
    .catch((error) =>
      thunkApi.rejectWithValue({
        error: buildErrorObject(error),
        timestamp: new Date().toISOString(),
      }),
    );
});

/**
 * Not intended as part of public API.
 * @see persistedQueriesRequested
 */
export const performChangesFetch = createAsyncThunk<
  {
    readonly results: {
      readonly changed: Partial<{
        readonly [resourceName in ResourceName]: readonly ResourceInstance[];
      }>;
      readonly deleted: Partial<{
        readonly [resourceName in ResourceName]: readonly string[];
      }>;
    };
    readonly timestamp: string;
  },
  {baseURL: string; lastFetchChangesTimestamp: string},
  {
    rejectValue: {error: SerializableError; timestamp: string};
    state: {resources: ResourcesState};
  }
>("resources/performChangesFetch", ({baseURL, lastFetchChangesTimestamp}, thunkApi) => {
  console.assert(lastFetchChangesTimestamp);
  const overShootDate = new Date(lastFetchChangesTimestamp as string);
  overShootDate.setUTCMinutes(overShootDate.getUTCMinutes() - CHANGED_SINCE_OVERSHOOT_MINUTES);
  const overShootTimestamp = overShootDate.toISOString();
  const updateParam = `changesSince=${overShootTimestamp}`;
  const url = `${baseURL}changes/?${updateParam}`;
  const requestPromise = limitedJsonFetch(url, "GET");
  return requestPromise
    .then((response) => response.data)
    .catch((error) =>
      thunkApi.rejectWithValue({
        error: buildErrorObject(error),
        timestamp: new Date().toISOString(),
      }),
    );
});

export const requestChangesFetch = createAction("resources/requestChangesFetch");

export const performIdFetch = createAsyncThunk<
  {readonly results: readonly ResourceInstance[]},
  {baseURL: string; ids: string[]; resourceName: ResourceName},
  {
    rejectValue: {error: SerializableError; timestamp: string};
    state: {resources: ResourcesState};
  }
>("resources/performIdFetch", ({baseURL, ids, resourceName}, thunkApi) => {
  const urlPrefix = resourcesConfig[resourceName];
  const listURL = `${baseURL}${urlPrefix}/`;
  console.assert(ids.length);
  const url = `${listURL}?${ids.map((id) => `id=${id}`).join("&")}`;
  const requestPromise = limitedJsonFetch(url, "GET");
  return requestPromise
    .then((response) => response.data)
    .catch((error) =>
      thunkApi.rejectWithValue({
        error: buildErrorObject(error),
        timestamp: new Date().toISOString(),
      }),
    );
});

export const performRelatedFetch = createAsyncThunk<
  {readonly results: readonly ResourceInstance[]},
  {
    baseURL: string;
    memberName: string;
    resourceName: ResourceName;
    values: string[];
  },
  {
    rejectValue: {error: SerializableError; timestamp: string};
    state: {resources: ResourcesState};
  }
>("resources/performRelatedFetch", ({baseURL, memberName, resourceName, values}, thunkApi) => {
  const urlPrefix = resourcesConfig[resourceName];
  const listURL = `${baseURL}${urlPrefix}/`;
  console.assert(values.length);
  const url = `${listURL}?${values.map((value) => `${memberName}Id=${value}`).join("&")}`;
  const requestPromise = limitedJsonFetch(url, "GET");
  return requestPromise
    .then((response) => response.data)
    .catch((error) =>
      thunkApi.rejectWithValue({
        error: buildErrorObject(error),
        timestamp: new Date().toISOString(),
      }),
    );
});

export const loadCommit = createAsyncThunk("resources/loadCommit", async () => {
  const commitDB = await getCommitDB();
  return commitDB.getAll();
});

export interface CreateOrUpdateProvisionaryCommand {
  readonly action: "CREATE_OR_UPDATE";
  readonly instance: ResourceTypeUnion;
  readonly patch: PatchUnion;
  readonly url: string;
}

export type ProvisionaryCommand = Command | CreateOrUpdateProvisionaryCommand;

export type ProvisionaryCommitAction = ProvisionaryCommand["action"];

export const save = createAction<ProvisionaryCommand>("resources/save");

const CREATE_PROMISE_TIMEOUT_SECONDS = 10;

export const createPromise = createAsyncThunk<
  ResourceInstance,
  {promise: Promise<ResourceInstance>; url: string},
  {
    rejectValue: {error: SerializableError; errorTimestamp: string};
    state: {resources: ResourcesState};
  }
>("resources/createPromise", async ({promise}, thunkApi) => {
  try {
    // eslint-disable-next-line promise/param-names
    const timeoutPromise = new Promise<ResourceInstance>((_resolve, reject) => {
      window.setTimeout(
        reject,
        CREATE_PROMISE_TIMEOUT_SECONDS * SECOND_MILLISECONDS,
        new Error("Timeout"),
      );
    });
    const instance = await Promise.race([promise, timeoutPromise]);
    return instance;
  } catch (error) {
    return thunkApi.rejectWithValue({
      error: buildErrorObject(error),
      errorTimestamp: new Date().toISOString(),
    });
  }
});

export const saveLocally = createAsyncThunk(
  "resources/saveLocally",
  async ({command, id}: {readonly command: Command; readonly id: number}) => {
    const commitDB = await getCommitDB();
    return commitDB.put(id, command, currentApiVersion, currentFrontendVersion);
  },
);

const HTTP_404_NOT_FOUND = 404;
const HTTP_409_CONFLICT = 409;

// `superseded` in result indicates that entry in commit queue was replaced
// while saving online; *before* updating local DB; so we didn't update local
// DB; may still have been replaced subsequently...
export const saveOnline = createAsyncThunk<
  {
    readonly onlineInstance: ResourceInstance | null;
    readonly superseded: boolean;
  },
  {
    readonly apiVersion: string | null;
    readonly command: Command;
    readonly frontendVersion: string | null;
    readonly id: number;
  },
  {
    rejectValue: {error: SerializableError; errorTimestamp: string};
    state: {resources: ResourcesState};
  }
>("resources/saveOnline", async ({apiVersion, command, frontendVersion, id}, thunkApi) => {
  let onlinePromise: Promise<ResponseWithData> | undefined;
  try {
    onlinePromise = sendOnline(command, apiVersion, frontendVersion);
  } catch (error) {
    return thunkApi.rejectWithValue({
      error: buildErrorObject(error),
      errorTimestamp: new Date().toISOString(),
    });
  }
  const onlinePromiseWithErrorSpecialCases = onlinePromise.catch((error) => {
    if (error instanceof StatusError) {
      if (error.status === HTTP_404_NOT_FOUND && command.action === "DELETE") {
        // Resulting state is as desired.
        // This might even be a retry of a delete command that actually
        // succeeded but with connection loss at an unfortunate point...
        return error.response;
      }
      if (error.status === HTTP_409_CONFLICT) {
        // Pretend success and let online state override local state.
        return error.response;
      }
    }
    throw error;
  });
  try {
    const response = await onlinePromiseWithErrorSpecialCases;
    const onlineInstance = (
      response.status === HTTP_404_NOT_FOUND ? null : response.data
    ) as ResourceInstance | null;
    const {commitQueue} = thunkApi.getState().resources;
    const index = commitQueue.findIndex((x) => x.id === id);
    console.assert(index !== -1);
    if (index !== -1) {
      const entry = commitQueue[index];
      if (entry.requestId === thunkApi.requestId) {
        const {url} = command;
        const updateData = onlineInstance
          ? {
              dataMerge: new Map([[url, onlineInstance] as [string, ResourceInstance]]),
            }
          : {dataDelete: new Set([url])};
        const offlineDB = await getOfflineDB();
        await offlineDB.update(updateData);
        const commitDB = await getCommitDB();
        const {commitQueue: commitQueueAfterAsync} = thunkApi.getState().resources;
        const indexAfterAsync = commitQueueAfterAsync.findIndex((x) => x.id === id);
        console.assert(indexAfterAsync !== -1);
        if (indexAfterAsync !== -1) {
          const entryAfterAsync = commitQueueAfterAsync[indexAfterAsync];
          // eslint-disable-next-line max-depth
          if (entryAfterAsync.requestId === thunkApi.requestId) {
            await commitDB.delete(id);
            return {onlineInstance, superseded: false};
          }
        }
      }
    }
    return {onlineInstance, superseded: true};
  } catch (error) {
    return thunkApi.rejectWithValue({
      error: buildErrorObject(error),
      errorTimestamp: new Date().toISOString(),
    });
  }
});

export const startOnlineSaves = createAction("resources/startOnlineSaves");

/** Clear sync error timestamps in Redux store.  NOTE: Not persisted to local
 * DB; so if you immediately reload, you're back to waiting two minutes *from
 * the time that the error occurred*. */
export const clearCommitErrorTimestamps = createAction("resources/clearCommitErrorTimestamps");

export function remove(url: string): ReturnType<typeof save> {
  return save({action: "DELETE", url});
}

export function create(instance: ResourceTypeUnion): ReturnType<typeof save> {
  return save({
    action: "CREATE",
    instance: instance.id ? instance : {...instance, id: urlToId(instance.url)},
    url: instance.url,
  });
}

export function update(url: string, patch: PatchUnion): ReturnType<typeof save> {
  return save({action: "UPDATE", patch: patch as Patch<ResourceInstance>, url});
}

export function updateDiff<T extends ResourceInstance>(
  ...props: Parameters<typeof diffResourceInstanceProperties<T>>
): ReturnType<typeof save> {
  const patch = diffResourceInstanceProperties(...props);
  return save({action: "UPDATE", patch: patch as Patch<ResourceInstance>, url: props[1].url});
}

/**
 * Acts like `create` if instance with given URL not present in commit queue;
 * `update` if it is.
 *
 * Intended as workaround for middleware creating legacy logs right before
 * task copy logic creates the same logs.
 *
 * *Do not use*.
 */
export function createOrUpdate(instance: ResourceTypeUnion): ReturnType<typeof save> {
  return save({
    action: "CREATE_OR_UPDATE",
    instance: instance.id ? instance : {...instance, id: urlToId(instance.url)},
    patch: Object.entries(instance)
      .filter(([member, _value]) => member !== "url" && member !== "id")
      .map(([member, value]) => ({member, value})) as any as PatchUnion,
    url: instance.url,
  });
}

export const addToOffline = createAsyncThunk<
  null,
  ResourceTypeUnion | ResourceTypeUnion[],
  {
    rejectValue: {error: SerializableError; errorTimestamp: string};
    state: {resources: ResourcesState};
  }
>("resources/addToOffline", async (instanceOrInstances, thunkApi) => {
  try {
    const instances = Array.isArray(instanceOrInstances)
      ? instanceOrInstances
      : [instanceOrInstances];
    const updateData = {
      dataMerge: new Map(
        instances.map((instance): [string, ResourceInstance] => [instance.url, instance]),
      ),
    };
    const offlineDB = await getOfflineDB();
    await offlineDB.update(updateData);
    return null;
  } catch (error) {
    return thunkApi.rejectWithValue({
      error: buildErrorObject(error),
      errorTimestamp: new Date().toISOString(),
    });
  }
});

export const offlineDBError = createAction("resources/offlineDBError", (error: Error) => ({
  payload: buildErrorObject(error),
}));

export const registerTimerStartPosition = createAction(
  "resources/registerTimerStartPosition",
  (timerStart: TimerStart) => ({payload: {timerStart}}),
);

export const registerTaskPosition = createAction(
  "resources/registerTaskPosition",
  (taskUrl: TaskUrl) => ({payload: {taskUrl}}),
);
