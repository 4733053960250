import {PriceItemUrl, ProductUrl} from "@co-common-libs/resources";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {getPriceItemLookup, getProductLookup} from "@co-frontend-libs/redux";
import {DialogContent, Divider, List, ListItem, ListItemText} from "@material-ui/core";
import React, {useCallback} from "react";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";

const TransferTargetListItem = ({
  onClick,
  title,
  url,
}: {
  onClick: (url: string) => void;
  title: string;
  url: string;
}): JSX.Element => {
  const handleClick = useCallback(() => {
    onClick(url);
  }, [onClick, url]);
  return (
    <ListItem button onClick={handleClick}>
      <ListItemText primary={title} />
    </ListItem>
  );
};

interface TransferTargetDialogProps {
  onCancel: () => void;
  onTransferTargetSelected: (url: string) => void;
  open: boolean;
  priceItemURLSet: ReadonlySet<PriceItemUrl>;
  productURLSet: ReadonlySet<ProductUrl>;
  unit: string;
  value: number | null;
}

export function TransferTargetDialog(props: TransferTargetDialogProps): JSX.Element {
  const {onCancel, onTransferTargetSelected, open, priceItemURLSet, productURLSet, unit, value} =
    props;
  const priceItemLookup = useSelector(getPriceItemLookup);
  const productLookup = useSelector(getProductLookup);
  return (
    <ResponsiveDialog
      open={open}
      title={
        <FormattedMessage
          defaultMessage="Overfør {value} {unit} til"
          id="transfer-target-dialog.dialog-title.transfer"
          values={{unit, value}}
        />
      }
      onCancel={onCancel}
    >
      <DialogContent dividers style={{padding: 0}}>
        <List>
          {Array.from(priceItemURLSet).map((url) => {
            const priceItem = priceItemLookup(url);
            return (
              <TransferTargetListItem
                key={url}
                title={priceItem?.name || ""}
                url={url}
                onClick={onTransferTargetSelected}
              />
            );
          })}
          {productURLSet.size && priceItemURLSet.size ? <Divider /> : null}
          {Array.from(productURLSet).map((url) => {
            const product = productLookup(url);
            return (
              <TransferTargetListItem
                key={url}
                title={product?.name || ""}
                url={url}
                onClick={onTransferTargetSelected}
              />
            );
          })}
        </List>
      </DialogContent>
    </ResponsiveDialog>
  );
}
