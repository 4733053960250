import {Checkbox} from "@material-ui/core";
import {useEventTargetCheckedUpdater} from "app-utils";
import React from "react";
import type {FileWithVisibleToAll} from "./types";

interface VisibleToAllCheckboxProps {
  file: FileWithVisibleToAll;
}

export const VisibleToAllCheckbox = function VisibleToAllCheckbox(
  props: VisibleToAllCheckboxProps,
): React.JSX.Element {
  const {file} = props;
  const handleChange = useEventTargetCheckedUpdater<FileWithVisibleToAll>(file.url, "visibleToAll");
  return <Checkbox checked={file.visibleToAll} onChange={handleChange} />;
};
