import {Check, Query, QueryIDStruct, QueryParams, QueryTimestampsStruct, makeQuery} from "../types";

const alwaysOkCheck: Check = {type: "alwaysOk"};

// temporary hack until data in local DB everywhere is in new format...
type QueryParamsPart = Partial<
  QueryParams | {check: {type: "foreignKey"}} | {check: {type: "reverseForeignKey"}}
> &
  Pick<QueryParams, "resourceName">;

export function makeQueryInstances(idQueryMap: {[queryID: number]: QueryParamsPart}): {
  [queryID: number]: Query;
} {
  const result: {[queryID: number]: Query} = {};
  Object.entries(idQueryMap).forEach(([queryIdString, params]) => {
    const queryId = parseInt(queryIdString);
    const check =
      params.check &&
      params.check.type !== "foreignKey" &&
      params.check.type !== "reverseForeignKey"
        ? params.check
        : alwaysOkCheck;
    result[queryId] = makeQuery({
      independentFetch: true,
      ...params,
      check,
    });
  });
  return result;
}

export function buildQueryIdAssociation(idQueryMap: {[queryID: number]: Query}): {
  // NOTE: invalidQueryIdAssociations is a (temporary) workaround/fix
  // for problematic data in local DB...
  invalidQueryIdAssociations: Map<string, QueryIDStruct>[];
  queryIdAssociation: Map<string, QueryIDStruct>;
} {
  const queryIdAssociation = new Map<string, QueryIDStruct>();
  let invalidQueryIdAssociation = new Map<string, QueryIDStruct>();
  const invalidQueryIdAssociations: Map<string, QueryIDStruct>[] = [];
  Object.entries(idQueryMap)
    .map(([queryIdString, query]) => ({id: parseInt(queryIdString), query}))
    // largest ID first
    .sort((a, b) => b.id - a.id)
    .forEach((entry) => {
      const {keyString} = entry.query;
      if (!queryIdAssociation.has(keyString)) {
        queryIdAssociation.set(keyString, entry);
      } else {
        if (!invalidQueryIdAssociation.has(keyString)) {
          invalidQueryIdAssociation.set(keyString, entry);
        } else {
          invalidQueryIdAssociations.push(invalidQueryIdAssociation);
          invalidQueryIdAssociation = new Map([[keyString, entry]]);
        }
      }
    });
  if (invalidQueryIdAssociation.size) {
    invalidQueryIdAssociations.push(invalidQueryIdAssociation);
  }
  return {invalidQueryIdAssociations, queryIdAssociation};
}

export function buildQueryTimestampsMap(
  idQueryMap: {[queryID: number]: Query},
  idTimestampsMap: {
    [queryID: number]: {fullTimestamp: string; updateTimestamp: string};
  },
): Partial<{[keyString: string]: QueryTimestampsStruct}> {
  const result: Partial<{[keyString: string]: QueryTimestampsStruct}> = {};
  const idArray = Object.keys(idTimestampsMap);
  for (let i = 0; i < idArray.length; i += 1) {
    const id = parseInt(idArray[i]);
    if (idTimestampsMap[id] && idQueryMap[id]) {
      const {fullTimestamp, updateTimestamp} = idTimestampsMap[id];
      const query = idQueryMap[id];
      result[query.keyString] = {fullTimestamp, query, updateTimestamp};
    }
  }
  return result;
}
