import {EmployeeGroupUrl, emptyEmployeeGroup} from "@co-common-libs/resources";
import {
  DebouncedAppbarSearchField,
  VerticalStackingFloatingActionButton,
  useModal,
} from "@co-frontend-libs/components";
import {
  getActiveEmployeeGroupArray,
  getEmployeeGroupArray,
  getEmployeeGroupLookup,
} from "@co-frontend-libs/redux";
import {
  EmployeeGroupCreateDialog,
  EmployeeGroupEditDialog,
  MenuToolbar,
  PageLayout,
} from "app-components";
import {useQueryParameterState} from "app-utils";
import PlusIcon from "mdi-react/PlusIcon";
import React, {useCallback, useState} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {ShowInactiveToggle} from "../shared/show-inactive-toggle";
import {EmployeeGroupTable} from "./employee-group-table";

interface EmployeeSettingsPageProps {
  onMenuButton: (event: React.MouseEvent) => void;
}
export const EmployeeGroupSettingsPage = React.memo(function EmployeeGroupSettingsPage(
  props: EmployeeSettingsPageProps,
): JSX.Element {
  const intl = useIntl();
  const [filter, setFilter] = useQueryParameterState<string>("q", "");
  const [showInactive, setShowInactive] = useState(false);
  const [createDialog, promptCreateDialog] = useModal(EmployeeGroupCreateDialog);
  const [editDialog, promptEditDialog] = useModal(EmployeeGroupEditDialog);
  const employeeGroupLookup = useSelector(getEmployeeGroupLookup);
  const activeEmployeeGroupArray = useSelector(getActiveEmployeeGroupArray);
  const employeeGroupArray = useSelector(getEmployeeGroupArray);

  const displayedEmployeeGroups = !showInactive ? activeEmployeeGroupArray : employeeGroupArray;

  const handleEditEmployeeGroup = useCallback(
    (employeeGroupURL: EmployeeGroupUrl) => {
      const employeeGroup = employeeGroupLookup(employeeGroupURL);
      if (employeeGroup) {
        promptEditDialog({employeeGroup});
      }
    },
    [employeeGroupLookup, promptEditDialog],
  );

  const handleCreatEmployeeGroup = useCallback(() => {
    promptCreateDialog({employeeGroupTemplate: emptyEmployeeGroup});
  }, [promptCreateDialog]);

  return (
    <PageLayout
      withBottomScrollPadding
      toolbar={
        <MenuToolbar
          rightElement={
            <>
              <ShowInactiveToggle setShowInactive={setShowInactive} showInactive={showInactive} />
              <DebouncedAppbarSearchField
                debounceTimeout={200}
                value={filter}
                onChange={setFilter}
              />
            </>
          }
          title={intl.formatMessage({defaultMessage: "Medarbejdergrupper"})}
          onMenuButton={props.onMenuButton}
        />
      }
    >
      <EmployeeGroupTable
        employeeGroups={displayedEmployeeGroups}
        filterString={filter}
        showInactive={showInactive}
        onEditEmployeeGroup={handleEditEmployeeGroup}
      />
      {createDialog}
      {editDialog}
      <VerticalStackingFloatingActionButton
        key="create-fab"
        stackIndex={0}
        onClick={handleCreatEmployeeGroup}
      >
        <PlusIcon />
      </VerticalStackingFloatingActionButton>
    </PageLayout>
  );
});
