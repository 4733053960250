import {Timer} from "@co-common-libs/resources";
import {hexToRGBA} from "@co-frontend-libs/utils";
import {PureComponent} from "app-utils";
import ImmutableDate from "bloody-immutable-date";
import React from "react";
import {COMPLETED_OPACITY, FADED_OPACITY, TIME_TRACKER_Z_INDEX} from "./constants";
import {calculateYPosition} from "./utils";

interface TimeTrackerBlockProps {
  calendarFromTimestamp: ImmutableDate;
  calendarToTimestamp: ImmutableDate;
  completed?: boolean;
  fade?: boolean | undefined;
  fromTimestamp: ImmutableDate;
  left: number;
  timer?: Timer | undefined;
  toTimestamp: ImmutableDate;
  width: number;
}

export class TimeTrackerBlock extends PureComponent<TimeTrackerBlockProps> {
  static defaultProps = {
    completed: false,
  };
  render(): JSX.Element | null {
    const {
      calendarFromTimestamp,
      calendarToTimestamp,
      completed,
      fade,
      fromTimestamp,
      left,
      timer,
      toTimestamp,
      width,
    } = this.props;
    if (!timer) {
      return null;
    }
    const startY = calculateYPosition(calendarFromTimestamp, calendarToTimestamp, fromTimestamp);
    const endY = calculateYPosition(calendarFromTimestamp, calendarToTimestamp, toTimestamp);
    const style: React.CSSProperties = {
      backgroundColor: hexToRGBA(timer.color || "#ffffff", completed ? COMPLETED_OPACITY : 1),
      height: endY - startY,
      left,
      opacity: fade ? FADED_OPACITY : 1,
      position: "absolute",
      top: startY,
      width,
      zIndex: TIME_TRACKER_Z_INDEX,
    };
    return <div style={style} />;
  }
}
