import {ProductGroupUrl, ProductUrl} from "@co-common-libs/resources";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {
  ConnectedProductDialog,
  ConnectedSingleProductGroupDialog,
} from "@co-frontend-libs/connected-components";
import {
  actions,
  getCurrentUserURL,
  getProductArray,
  getProductGroupArray,
  getProductGroupLookup,
  getProductLookup,
  getSettingsEntryLookupByIdentifier,
} from "@co-frontend-libs/redux";
import {
  Chip,
  DialogContent,
  Fab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import PlusIcon from "mdi-react/PlusIcon";
import React, {useCallback, useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {SettingDialogProps} from "../settings-view/setting-dialog";

const eConomicProductGroupPrefix = "https://restapi.e-conomic.com/product-groups/";

interface ProductChipProps {
  index: number;
  onRemoveProduct: (index: number) => void;
  productID: string;
}

function ProductChip(props: ProductChipProps): JSX.Element {
  const {index, onRemoveProduct, productID} = props;

  const productArray = useSelector(getProductArray);

  const handleDelete = useCallback((): void => {
    onRemoveProduct(index);
  }, [index, onRemoveProduct]);

  const product = productArray.find((p) => p.catalogNumber === productID);
  const label = product?.name || (
    <FormattedMessage
      defaultMessage="Ukendt vare {identifier}"
      id="system-setup.warning.unknown-product"
      values={{identifier: productID}}
    />
  );

  return <Chip label={label} onDelete={handleDelete} />;
}

interface AutoSupplementingProductsDialogProps {
  index: number;
  onAddProduct: (groupIndex: number) => void;
  onRemoveProduct: (groupIndex: number, productIndex: number) => void;
  productGroupID: string;
  productIDs: readonly string[];
}

function AutoSupplementingProductsDialogRow(
  props: AutoSupplementingProductsDialogProps,
): JSX.Element {
  const {index, onAddProduct, onRemoveProduct, productGroupID, productIDs} = props;
  const productGroupArray = useSelector(getProductGroupArray);
  const handleRemoveProduct = useCallback(
    (productIndex: number): void => {
      onRemoveProduct(index, productIndex);
    },
    [index, onRemoveProduct],
  );

  const handleAddProduct = useCallback((): void => {
    onAddProduct(index);
  }, [index, onAddProduct]);

  const productGroup = productGroupArray.find((g) => {
    if (!g.remoteUrl) {
      return false;
    }
    const id = g.remoteUrl.startsWith(eConomicProductGroupPrefix)
      ? g.remoteUrl.substring(eConomicProductGroupPrefix.length)
      : g.remoteUrl;
    return id === productGroupID;
  });

  const productGroupLabel = productGroup?.name || (
    <FormattedMessage
      defaultMessage="Ukendt varegruppe {identifier}"
      id="system-setup.warning.unknown-group"
      values={{identifier: productGroupID}}
    />
  );
  return (
    <TableRow>
      <TableCell>{productGroupLabel}</TableCell>
      <TableCell>
        {productIDs.map((productID, productIndex) => (
          <ProductChip
            key={productIndex}
            index={productIndex}
            productID={productID}
            onRemoveProduct={handleRemoveProduct}
          />
        ))}
        <Fab size="small" onClick={handleAddProduct}>
          <PlusIcon />
        </Fab>
      </TableCell>
    </TableRow>
  );
}

export function AutoSupplementingProductsDialog(props: SettingDialogProps): JSX.Element {
  const {onClose, open, settingID} = props;

  const [productDialogOpenFor, setProductDialogOpenFor] = useState<number | null>(null);

  const [productGroupDialogOpen, setProductGroupDialogOpen] = useState(false);

  const dispatch = useDispatch();

  const productLookup = useSelector(getProductLookup);
  const productGroupLookup = useSelector(getProductGroupLookup);

  const currentUserURL = useSelector(getCurrentUserURL);

  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const settingEntry = settingsEntryLookupByIdentifier(settingID);

  const savedData: {
    readonly [productGroupID: string]: readonly string[];
  } | null = settingEntry?.data || null;

  const [editedData, setEditedData] = useState<readonly (readonly [string, readonly string[]])[]>(
    savedData ? Object.entries(savedData) : [],
  );

  useEffect(() => {
    if (open) {
      setEditedData(savedData ? Object.entries(savedData) : []);
    }
  }, [open, savedData]);

  const handleProductGroupAddCancel = useCallback((): void => {
    setProductGroupDialogOpen(false);
  }, []);

  const handleProductGroupAddOk = useCallback(
    (url: ProductGroupUrl): void => {
      setProductGroupDialogOpen(false);
      const productGroup = productGroupLookup(url);
      if (productGroup) {
        const id = productGroup.remoteUrl.startsWith(eConomicProductGroupPrefix)
          ? productGroup.remoteUrl.substring(eConomicProductGroupPrefix.length)
          : productGroup.remoteUrl;
        setEditedData([...editedData, [id, []]]);
      }
    },
    [editedData, productGroupLookup],
  );

  const handleSave = useCallback(() => {
    const nonEmptyData = editedData.filter(
      ([groupID, productIDs]) => !!(groupID && productIDs.length),
    );
    const newValue = nonEmptyData.length ? Object.fromEntries(nonEmptyData) : null;
    if (settingEntry) {
      dispatch(
        actions.update(settingEntry.url, [
          {member: "changedBy", value: currentUserURL},
          {member: "data", value: newValue},
        ]),
      );
    }
    onClose();
  }, [currentUserURL, dispatch, editedData, onClose, settingEntry]);

  const handleAddProductGroup = useCallback(() => {
    setProductGroupDialogOpen(true);
  }, []);

  const handleAddProduct = useCallback((productGroupIndex: number): void => {
    setProductDialogOpenFor(productGroupIndex);
  }, []);

  const handleProductAddCancel = useCallback((): void => {
    setProductDialogOpenFor(null);
  }, []);

  const handleProductAddOk = useCallback(
    (urlOrURLs: ProductUrl | ReadonlySet<ProductUrl>): void => {
      if (productDialogOpenFor == null) {
        return;
      }
      const addedURLs = typeof urlOrURLs === "string" ? new Set([urlOrURLs]) : urlOrURLs;
      const productGroupIndex = productDialogOpenFor;
      const newData = editedData.slice();
      const [productGroupID, productIDs] = newData[productGroupIndex];
      const newProductIDs = new Set(productIDs);
      addedURLs.forEach((productURL) => {
        const product = productLookup(productURL);
        if (product?.catalogNumber) {
          newProductIDs.add(product.catalogNumber);
        }
      });
      newData[productGroupIndex] = [productGroupID, Array.from(newProductIDs)];
      setEditedData(newData);
      setProductDialogOpenFor(null);
    },
    [editedData, productDialogOpenFor, productLookup],
  );

  const handleRemoveProduct = useCallback(
    (productGroupIndex: number, productIndex: number): void => {
      const newData = editedData.slice();
      const [productGroupID, productIDs] = newData[productGroupIndex];
      const newProductIDs = productIDs.slice();
      newProductIDs.splice(productIndex, 1);
      newData[productGroupIndex] = [productGroupID, newProductIDs];
      setEditedData(newData);
    },
    [editedData],
  );

  return (
    <>
      <ResponsiveDialog
        fullWidth
        okLabel={<FormattedMessage defaultMessage="Gem" id="setting-dialog.label.save" />}
        open={open}
        title={
          <FormattedMessage
            defaultMessage="Auto-følge-varer"
            id="system-setup.dialog-title.auto-supplementing-products"
          />
        }
        onCancel={onClose}
        onOk={handleSave}
      >
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage
                    defaultMessage="Varegruppe"
                    id="system-setup.table-header.product-group"
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    defaultMessage="Varer"
                    id="system-setup.table-header.products"
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {editedData.map(([groupID, productIDs], index) => (
                <AutoSupplementingProductsDialogRow
                  key={index}
                  index={index}
                  productGroupID={groupID}
                  productIDs={productIDs}
                  onAddProduct={handleAddProduct}
                  onRemoveProduct={handleRemoveProduct}
                />
              ))}
            </TableBody>
          </Table>
          <Fab size="small" onClick={handleAddProductGroup}>
            <PlusIcon />
          </Fab>
        </DialogContent>
      </ResponsiveDialog>
      <ConnectedProductDialog
        open={productDialogOpenFor != null}
        onCancel={handleProductAddCancel}
        onOk={handleProductAddOk}
      />
      <ConnectedSingleProductGroupDialog
        open={productGroupDialogOpen}
        onCancel={handleProductGroupAddCancel}
        onOk={handleProductGroupAddOk}
      />
    </>
  );
}
