import {Config} from "@co-common-libs/config";
import {
  Customer,
  Location,
  LocationUrl,
  PriceItem,
  PriceItemUrl,
  PriceItemUse,
  Product,
  ProductGroup,
  ProductGroupUrl,
  ProductUrl,
  ProductUse,
  Role,
  SprayLocation,
  Task,
  Unit,
  UnitUrl,
} from "@co-common-libs/resources";
import {getUnitCode} from "@co-common-libs/resources-utils";
import {formatAddress} from "@co-common-libs/utils";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {Button, DialogContent} from "@material-ui/core";
import {PureComponent, addToLegacyProductUseSet} from "app-utils";
import {bind} from "bind-decorator";
import _ from "lodash";
import React from "react";
// Allowed for existing code...
// eslint-disable-next-line deprecate/import
import {Cell, Grid} from "react-flexr";
import {FormattedMessage, IntlContext, defineMessages} from "react-intl";
import {LegacyPriceItemTable} from "./legacy-price-item-table";
import {LegacyProductTable} from "./legacy-product-table";
import {Linkify} from "./linkify";
import {MaterialEntryDetailsDialog} from "./material-entry-details-dialog";

const messages = defineMessages({
  addProductButton: {
    defaultMessage: "Tilføj",
    id: "dialog.label.add-product",
  },
  cancel: {
    defaultMessage: "Fortryd",
    id: "dialog.label.cancel",
  },
  editField: {
    defaultMessage: "Ret mark",
    id: "spray-dialog.label.edit-field",
  },
  editReport: {
    defaultMessage: "Ret indberetning",
    id: "spray-dialog.title.edit-report",
  },
  ok: {
    defaultMessage: "OK",
    id: "dialog.label.ok",
  },
  report: {
    defaultMessage: "Indberetning",
    id: "spray-dialog.title.report",
  },
});

export const updateSprayArea = (
  area: number,
  priceItemUseList: readonly PriceItemUse[],
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined,
  unitLookup: (url: UnitUrl) => Unit | undefined,
): readonly PriceItemUse[] => {
  if (!area) {
    return priceItemUseList;
  }
  return priceItemUseList.map((priceItemUse) => {
    const priceItemURL = priceItemUse.priceItem;
    const priceItem = priceItemLookup(priceItemURL);
    const priceItemUnit = priceItem ? getUnitCode(priceItem, unitLookup).toLowerCase() : "";
    if (priceItemUnit === "ha" || priceItemUnit === "ha.") {
      return {...priceItemUse, count: area};
    }
    return priceItemUse;
  });
};

interface SprayDialogProps {
  currentRole: Role | null;
  customer?: Customer | undefined;
  customerSettings: Config;
  isNew: boolean;
  locationLookup: (url: LocationUrl) => Location | undefined;
  note?: string | undefined;
  onCancel: () => void;
  onOk: (data: {
    priceItemUseList: readonly PriceItemUse[];
    productUseList: readonly ProductUse[];
  }) => void;
  onRequestLocationDialog: (sprayLocation: SprayLocation) => void;
  onRequestProductSelectionDialog: (
    callback: (urlOrURLs: ProductUrl | ReadonlySet<ProductUrl>) => void,
  ) => void;
  open: boolean;
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined;
  priceItemUseList?: readonly PriceItemUse[];
  productArray: readonly Product[];
  productGroupLookup: (url: ProductGroupUrl) => ProductGroup | undefined;
  productLookup: (url: ProductUrl) => Product | undefined;
  productUseList?: readonly ProductUse[];
  sprayLocation?: SprayLocation | undefined;
  task: Task;
  unit?: string;
  unitLookup: (url: UnitUrl) => Unit | undefined;
  userIsOnlyMachineOperator: boolean;
  userIsOtherMachineOperator: boolean;
}

interface SprayDialogState {
  materialDetailsDialog: {
    deleteable: boolean;
    disabled: boolean | null;
    handleDeleteClick: (() => void) | null;
    index: number | null;
    name: string | null;
    notes: string | null;
    open: boolean;
    ours: boolean | null;
  };
  priceItemUseList: readonly PriceItemUse[] | undefined;
  productUseList: readonly ProductUse[] | undefined;
}

export class SprayDialog extends PureComponent<SprayDialogProps, SprayDialogState> {
  state: SprayDialogState = {
    materialDetailsDialog: {
      deleteable: false,
      disabled: null,
      handleDeleteClick: null,
      index: null,
      name: null,
      notes: null,
      open: false,
      ours: null,
    },
    priceItemUseList: this.props.priceItemUseList,
    productUseList: this.props.productUseList,
  };
  UNSAFE_componentWillReceiveProps(nextProps: SprayDialogProps): void {
    let nextPriceItemUseList = nextProps.priceItemUseList;
    if (
      !_.isEqual(nextPriceItemUseList, this.props.priceItemUseList) ||
      !_.isEqual(nextProps.sprayLocation, this.props.sprayLocation)
    ) {
      if (nextProps.isNew && nextProps.sprayLocation) {
        const {area} = nextProps.sprayLocation;
        if (area) {
          nextPriceItemUseList =
            nextPriceItemUseList &&
            updateSprayArea(
              area,
              nextPriceItemUseList,
              this.props.priceItemLookup,
              this.props.unitLookup,
            );
        }
      }
      this.setState({
        priceItemUseList: nextPriceItemUseList,
      });
    }
    if (nextProps.open && !this.props.open) {
      this.setState({
        priceItemUseList: nextPriceItemUseList,
        productUseList: nextProps.productUseList,
      });
    }
  }

  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  @bind
  handleEditLocation(): void {
    const {onRequestLocationDialog, sprayLocation} = this.props;
    if (sprayLocation) {
      onRequestLocationDialog(sprayLocation);
    }
  }

  @bind
  handleOk(): void {
    const {priceItemUseList, productUseList} = this.state;
    if (priceItemUseList && productUseList) {
      this.props.onOk({
        priceItemUseList,
        productUseList,
      });
    }
  }

  @bind
  handleCancel(): void {
    this.props.onCancel();
  }

  getTitle(): string {
    const {formatMessage} = this.context;
    const {isNew} = this.props;

    return isNew ? formatMessage(messages.report) : formatMessage(messages.editReport);
  }
  @bind
  handlePriceItemUseCountChange(index: number, value: number | null): void {
    const oldValue = this.state.priceItemUseList || [];
    const newValue = oldValue.slice();
    newValue[index] = {...newValue[index], count: value};
    this.setState({
      priceItemUseList: newValue,
    });
  }
  @bind
  handlePriceItemNotesChange(index: number, value: string): void {
    const oldValue = this.state.priceItemUseList || [];
    const newValue = oldValue.slice();
    newValue[index] = {...newValue[index], notes: value};
    this.setState({priceItemUseList: newValue});
  }
  @bind
  handleProductUseListChange(productUseList: readonly ProductUse[]): void {
    this.setState({productUseList});
  }

  @bind
  handleProductSelected(urlOrURLs: ProductUrl | ReadonlySet<ProductUrl>): void {
    const {productArray, productGroupLookup, productLookup} = this.props;
    const productUseList = addToLegacyProductUseSet(
      this.state.productUseList || [],
      urlOrURLs,
      productArray,
      productLookup,
      productGroupLookup,
      this.props.customerSettings,
      null,
    );
    this.setState({productUseList});
  }

  @bind
  handleProductSelectButton(): void {
    this.props.onRequestProductSelectionDialog(this.handleProductSelected);
  }

  @bind
  handleDetailButtonClick(
    notes: string,
    ours: boolean,
    name: string,
    disabled: boolean,
    index: number,
    handleDeleteClick: () => void,
    deleteable: boolean,
  ): void {
    this.setState({
      materialDetailsDialog: {
        deleteable,
        disabled,
        handleDeleteClick,
        index,
        name,
        notes,
        open: true,
        ours,
      },
    });
  }
  @bind
  handleMaterialDetailsDialogOk(ours: boolean, notes: string): void {
    const {index} = this.state.materialDetailsDialog;
    if (index == null) {
      return;
    }
    const oldValue = this.state.productUseList || [];
    const newValue = oldValue.slice();
    newValue[index] = {...newValue[index], notes, ours};
    this.setState({productUseList: newValue});

    this.setState({
      materialDetailsDialog: {
        deleteable: false,
        disabled: null,
        handleDeleteClick: null,
        index: null,
        name: null,
        notes: null,
        open: false,
        ours: null,
      },
    });
  }
  @bind
  handleMaterialDetailsDialogCancel(): void {
    this.setState({
      materialDetailsDialog: {
        deleteable: false,
        disabled: null,
        handleDeleteClick: null,
        index: null,
        name: null,
        notes: null,
        open: false,
        ours: null,
      },
    });
  }

  @bind
  handleDeleteClick(): void {
    if (this.state.materialDetailsDialog.handleDeleteClick) {
      this.state.materialDetailsDialog.handleDeleteClick();
    }
    this.handleMaterialDetailsDialogCancel();
  }

  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {customer, open, sprayLocation, task, unitLookup} = this.props;
    const customerName = customer ? customer.name : null;
    const location =
      sprayLocation && sprayLocation.relatedLocation
        ? this.props.locationLookup(sprayLocation.relatedLocation)
        : null;
    const sprayLocationAddress = location ? location.name || location.address : null;
    const sprayLocationNote = sprayLocation ? sprayLocation.note : null;
    const sprayLocationArea = sprayLocation ? sprayLocation.area : null;

    let baseUnit, baseValue;
    if (this.state.priceItemUseList) {
      this.state.priceItemUseList.forEach((priceItemUse) => {
        const priceItem = this.props.priceItemLookup(priceItemUse.priceItem);
        const unit = priceItem ? getUnitCode(priceItem, unitLookup).toLowerCase() : "";
        if (unit === "ha" || unit === "ha.") {
          baseValue = priceItemUse.count;
          baseUnit = unit;
        }
      });
    }

    const role = this.props.currentRole;

    const isManager = role && role.manager;

    const dialogContent = (
      <div>
        <Grid>
          <Cell palm="12/12">
            <FormattedMessage
              defaultMessage="Kunde: {name}"
              id="spray-dialog.label.customer-name"
              tagName="div"
              values={{name: customerName}}
            />
            <FormattedMessage
              defaultMessage="Kundens adresse: {address}"
              id="spray-dialog.label.address-address"
              tagName="div"
              values={{address: formatAddress(customer)}}
            />
            <FormattedMessage
              defaultMessage="Arbejdssted: {workplace}"
              id="spray-dialog.label.workplace"
              tagName="div"
              values={{workplace: sprayLocationAddress}}
            />
            <div>
              <FormattedMessage defaultMessage="Note:" id="spray-dialog.label.note" />{" "}
              <Linkify>{sprayLocationNote}</Linkify>
            </div>
            <FormattedMessage
              defaultMessage="Areal: {area} ha"
              id="spray-dialog.label.area"
              tagName="div"
              values={{area: sprayLocationArea}}
            />
            <Button color="secondary" variant="contained" onClick={this.handleEditLocation}>
              {formatMessage(messages.editField)}
            </Button>
            <hr />
            {!this.props.customerSettings.noProducts ? (
              <Button
                fullWidth
                color="secondary"
                disabled={task.validatedAndRecorded}
                variant="contained"
                onClick={this.handleProductSelectButton}
              >
                {formatMessage(messages.addProductButton)}
              </Button>
            ) : null}
            <LegacyPriceItemTable
              customerSettings={this.props.customerSettings}
              priceItemUseList={this.state.priceItemUseList || []}
              readonly={
                task.validatedAndRecorded ||
                (!isManager && (task.completed || this.props.userIsOtherMachineOperator))
              }
              readonlyItems={null}
              onNotesChange={this.handlePriceItemNotesChange}
              onPriceItemUseCountChange={this.handlePriceItemUseCountChange}
            />
            <LegacyProductTable
              baseUnit={baseUnit}
              baseValue={baseValue}
              currentRole={this.props.currentRole}
              currentUserURL={null}
              customerSettings={this.props.customerSettings}
              priceItemUseList={this.state.priceItemUseList || []}
              productUseList={this.state.productUseList || []}
              readonly={
                task.validatedAndRecorded ||
                (!isManager && (task.completed || this.props.userIsOtherMachineOperator))
              }
              readonlyUnits={null}
              onChange={this.handleProductUseListChange}
              onDetailButtonClick={this.handleDetailButtonClick}
            />
          </Cell>
        </Grid>
        <MaterialEntryDetailsDialog
          key="material-entry-dialog"
          customerSettings={this.props.customerSettings}
          deleteable={this.state.materialDetailsDialog.deleteable}
          disabled={
            this.state.materialDetailsDialog.disabled != null
              ? this.state.materialDetailsDialog.disabled
              : undefined
          }
          name={
            this.state.materialDetailsDialog.name != null
              ? this.state.materialDetailsDialog.name
              : undefined
          }
          notes={
            this.state.materialDetailsDialog.notes != null
              ? this.state.materialDetailsDialog.notes
              : undefined
          }
          open={this.state.materialDetailsDialog.open}
          ours={
            this.state.materialDetailsDialog.ours != null
              ? this.state.materialDetailsDialog.ours
              : undefined
          }
          onCancel={this.handleMaterialDetailsDialogCancel}
          onDeleteClick={this.handleDeleteClick}
          onOk={this.handleMaterialDetailsDialogOk}
        />
      </div>
    );
    // Currently this dialog box can not show all the columns;
    // we force fullscreen until there is time to fix it
    return (
      <ResponsiveDialog
        fullscreen
        open={open}
        title={this.getTitle()}
        onCancel={this.handleCancel}
        onOk={this.handleOk}
      >
        <DialogContent>{dialogContent}</DialogContent>
      </ResponsiveDialog>
    );
  }
}
