import {
  Product,
  ProductUrl,
  ProductUsesDict,
  ReportingSpecification,
  Unit,
  UnitUrl,
} from "@co-common-libs/resources";
import {getUnitCode} from "../unit";
import {getAllTransferUnits} from "./get-all-transfer-units";

export function getReadonlyProductsFromUnits(
  reportingSpecification: ReportingSpecification,
  productUses: ProductUsesDict,
  productLookup: (url: ProductUrl) => Product | undefined,
  unitLookup: (url: UnitUrl) => Unit | undefined,
): Set<ProductUrl> {
  const readonlyProducts = new Set<ProductUrl>();
  const transferUnits = getAllTransferUnits(reportingSpecification);
  if (!transferUnits.size) {
    return readonlyProducts;
  }
  Object.values(productUses).forEach(({product: productUrl}) => {
    const product = productLookup(productUrl);
    if (
      product &&
      !readonlyProducts.has(productUrl) &&
      transferUnits.has(getUnitCode(product, unitLookup))
    ) {
      readonlyProducts.add(productUrl);
    }
  });
  return readonlyProducts;
}
