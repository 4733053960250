import {SettingID, SettingMetaData} from "@co-common-libs/config";
import {SettingEntry} from "@co-common-libs/resources";
import {formatDateNumeric} from "@co-common-libs/utils";
import {IconButton, Theme, makeStyles, useTheme} from "@material-ui/core";
import InformationOutlineIcon from "mdi-react/InformationOutlineIcon";
import React from "react";
import {FormattedMessage} from "react-intl";
import {StatusDot} from "../status-dot";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

export function DisplaySelected({
  currentValue,
  onClick,
  settingEntry,
  settingID,
  settingMetaData,
}: {
  currentValue: string[] | string;
  onClick: () => void;
  settingEntry?: SettingEntry | undefined;
  settingID: SettingID;
  settingMetaData: SettingMetaData;
}): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();

  let displayValue: JSX.Element | null = null;
  if (typeof currentValue === "string") {
    if (currentValue) {
      displayValue = (
        <span style={{backgroundColor: theme.palette.grey[200], padding: 2}}>{currentValue}</span>
      );
    }
  } else {
    displayValue = (
      <>
        <FormattedMessage defaultMessage="Valgte" id="display-selected.selected" />
        :<br />
        {currentValue.length ? (
          <span style={{backgroundColor: theme.palette.grey[200], padding: 2}}>
            {currentValue.join("\n")}
          </span>
        ) : null}
      </>
    );
  }
  return (
    <div className={classes.root} style={{display: "flex"}}>
      <div style={{cursor: "pointer", flex: "0 0 50px"}} onClick={onClick}>
        <StatusDot settingEntry={settingEntry} />
      </div>
      <div style={{cursor: "pointer", flex: 1}} onClick={onClick}>
        {settingMetaData.description}
        <div style={{whiteSpace: "pre-wrap"}}>{displayValue}</div>
      </div>
      <div
        style={{
          color: theme.palette.grey[500],
          flex: "0 0 100px",
          textAlign: "right",
        }}
      >
        <small>
          <div>{settingID}</div>
          {settingEntry?.lastChanged ? (
            <div>{formatDateNumeric(settingEntry.lastChanged)}</div>
          ) : null}
        </small>
      </div>
      <div style={{flex: "0 0 50px", position: "relative", top: -12}}>
        {settingMetaData.documentationURL ? (
          <IconButton color="primary" href={settingMetaData.documentationURL} target="blank">
            <InformationOutlineIcon />
          </IconButton>
        ) : null}
      </div>
    </div>
  );
}
