import {Timer, TimerUrl} from "@co-common-libs/resources";
import {MultipleTimerDialog} from "@co-frontend-libs/components";
import {AppState, getTimerArray} from "@co-frontend-libs/redux";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";

interface MultipleTimerDialogStateProps {
  timerArray: readonly Timer[];
}

interface MultipleTimerDialogOwnProps {
  includeOnlyTimersWithIdentifiers?: boolean;
  onCancel(): void;
  onOk(urls: ReadonlySet<TimerUrl>): void;
  open: boolean;
  selected?: ReadonlySet<TimerUrl>;
}

export const ConnectedMultipleTimerDialog: React.ComponentType<MultipleTimerDialogOwnProps> =
  connect<MultipleTimerDialogStateProps, object, MultipleTimerDialogOwnProps, AppState>(
    createStructuredSelector<AppState, MultipleTimerDialogStateProps>({
      timerArray: getTimerArray,
    }),
    {},
  )(MultipleTimerDialog);
