function findBonusLabelSource(
  label: string,
  data: {
    calendarDayBonusLabels: readonly string[];
    countBonusLabels: readonly string[];
    intervalBonusLabels: readonly string[];
    projectDistanceBonusLabel: string | undefined;
    projectTravelTimeBonusLabel: string | undefined;
    taskBonusLabels: readonly string[];
    workDayBonusLabels: readonly string[];
  },
):
  | "calendar-day-bonus"
  | "count-bonus"
  | "interval-bonus"
  | "project-distance-bonus"
  | "project-travel-time-bonus"
  | "task-bonus"
  | "work-day-bonus"
  | null {
  const {
    calendarDayBonusLabels,
    countBonusLabels,
    intervalBonusLabels,
    projectDistanceBonusLabel,
    projectTravelTimeBonusLabel,
    taskBonusLabels,
    workDayBonusLabels,
  } = data;
  if (intervalBonusLabels.includes(label)) {
    return "interval-bonus";
  } else if (workDayBonusLabels.includes(label)) {
    return "work-day-bonus";
  } else if (calendarDayBonusLabels.includes(label)) {
    return "calendar-day-bonus";
  } else if (label === projectDistanceBonusLabel) {
    return "project-distance-bonus";
  } else if (label === projectTravelTimeBonusLabel) {
    return "project-travel-time-bonus";
  } else if (countBonusLabels.includes(label)) {
    return "count-bonus";
  } else if (taskBonusLabels.includes(label)) {
    return "task-bonus";
  } else {
    return null;
  }
}

interface RateBonusLabelSourceEntry {
  label: string;
  source:
    | "calendar-day-bonus"
    | "count-bonus"
    | "interval-bonus"
    | "project-distance-bonus"
    | "project-travel-time-bonus"
    | "rate"
    | "task-bonus"
    | "work-day-bonus";
}

export function findVisibleRateBonusLabelSources(data: {
  calendarDayBonusLabels: readonly string[];
  countBonusLabels: readonly string[];
  intervalBonusLabels: readonly string[];
  projectDistanceBonusLabel: string | undefined;
  projectTravelTimeBonusLabel: string | undefined;
  rateLabels: readonly string[];
  taskBonusLabels: readonly string[];
  visibleBonusLabels: readonly string[] | undefined;
  visibleLabels: readonly string[] | undefined;
  visibleRateLabels: readonly string[] | undefined;
  workDayBonusLabels: readonly string[];
}): RateBonusLabelSourceEntry[] {
  const {
    calendarDayBonusLabels,
    countBonusLabels,
    intervalBonusLabels,
    projectDistanceBonusLabel,
    projectTravelTimeBonusLabel,
    rateLabels,
    taskBonusLabels,
    visibleBonusLabels,
    visibleLabels,
    visibleRateLabels,
    workDayBonusLabels,
  } = data;
  if (visibleLabels) {
    const result: RateBonusLabelSourceEntry[] = [];
    visibleLabels.forEach((label) => {
      const source = findBonusLabelSource(label, {
        calendarDayBonusLabels,
        countBonusLabels,
        intervalBonusLabels,
        projectDistanceBonusLabel,
        projectTravelTimeBonusLabel,
        taskBonusLabels,
        workDayBonusLabels,
      });
      if (source) {
        result.push({label, source});
      } else if (rateLabels.includes(label)) {
        result.push({label, source: "rate"});
      } else {
        // eslint-disable-next-line no-console
        console.warn(`unknown label: ${label}`);
      }
    });
    return result;
  } else {
    const result: RateBonusLabelSourceEntry[] = [];
    (visibleRateLabels
      ? rateLabels.filter((label) => visibleRateLabels.includes(label))
      : rateLabels
    ).forEach((label) => result.push({label, source: "rate"}));
    (visibleBonusLabels
      ? intervalBonusLabels.filter((label) => visibleBonusLabels.includes(label))
      : intervalBonusLabels
    ).forEach((label) => result.push({label, source: "interval-bonus"}));
    (visibleBonusLabels
      ? workDayBonusLabels.filter((label) => visibleBonusLabels.includes(label))
      : workDayBonusLabels
    ).forEach((label) => result.push({label, source: "work-day-bonus"}));
    (visibleBonusLabels
      ? calendarDayBonusLabels.filter((label) => visibleBonusLabels.includes(label))
      : calendarDayBonusLabels
    ).forEach((label) => result.push({label, source: "calendar-day-bonus"}));
    (visibleBonusLabels
      ? taskBonusLabels.filter((label) => visibleBonusLabels.includes(label))
      : taskBonusLabels
    ).forEach((label) => result.push({label, source: "task-bonus"}));
    if (
      projectDistanceBonusLabel &&
      (!visibleBonusLabels || visibleBonusLabels.includes(projectDistanceBonusLabel))
    ) {
      result.push({
        label: projectDistanceBonusLabel,
        source: "project-distance-bonus",
      });
    }
    if (
      projectTravelTimeBonusLabel &&
      (!visibleBonusLabels || visibleBonusLabels.includes(projectTravelTimeBonusLabel))
    ) {
      result.push({
        label: projectTravelTimeBonusLabel,
        source: "project-travel-time-bonus",
      });
    }
    (visibleBonusLabels
      ? countBonusLabels.filter((label) => visibleBonusLabels.includes(label))
      : countBonusLabels
    ).forEach((label) => result.push({label, source: "count-bonus"}));
    return result;
  }
}
