import {Command, PatchUnion, ResourceTypeUnion} from "@co-common-libs/resources";
import {OldCommand} from "./types";

export function translateOldCommand(command: OldCommand): Command {
  const {action, instance} = command;
  const {url} = instance;
  if (action === "CREATE") {
    return {
      action,
      instance: instance as ResourceTypeUnion,
      url,
    };
  } else if (action === "UPDATE") {
    const patch: {member: string; value: unknown}[] = [];
    Object.entries(instance).forEach(([member, value]) => {
      if (member !== "url" && member !== "id") {
        patch.push({member, value});
      }
    });
    return {action, patch: patch as PatchUnion, url};
  } else {
    console.assert(action === "DELETE");
    return {
      action,
      url,
    };
  }
}
