import {
  CultureUrl,
  CustomerUrl,
  LocationUrl,
  MachineUrl,
  ProjectUrl,
  UserUrl,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {
  getMachineString,
  getProjectString,
  getWorkTypeString,
} from "@co-common-libs/resources-utils";
import {DateField, MultiLocationDialog, TrimTextField} from "@co-frontend-libs/components";
import {
  ConnectedMultipleCulturesDialog,
  ConnectedMultipleCustomersDialog,
  ConnectedMultipleExternalWorkTypesDialog,
  ConnectedMultipleMachinesDialog,
  ConnectedMultipleUsersDialog,
  ConnectedProjectDialog,
} from "@co-frontend-libs/connected-components";
import {
  getCultureLookup,
  getCustomerLookup,
  getCustomerSettings,
  getLocationArray,
  getLocationLookup,
  getMachineLookup,
  getProjectLookup,
  getUserUserProfileLookup,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {Button, Card, CardContent, Grid} from "@material-ui/core";
import SearchIcon from "mdi-react/SearchIcon";
import React, {useCallback, useMemo, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {ENTRIES_BEFORE_ELLIPSIS} from "./constants";

const truncateWithEllipsis = (strings: string[]): string[] => {
  if (strings.length > ENTRIES_BEFORE_ELLIPSIS) {
    return [...strings.slice(0, ENTRIES_BEFORE_ELLIPSIS), "..."];
  }
  return strings;
};

interface FilterCardProps {
  fromDate: string;
  onCulturesDialogOk: (urls: ReadonlySet<CultureUrl>) => void;
  onCustomersDialogOk: (urls: ReadonlySet<CustomerUrl>) => void;
  onFromDateChange: (value: string | null) => void;
  onLocationsDialogOk: (urls: ReadonlySet<LocationUrl>) => void;
  onMachinesDialogOk: (urls: ReadonlySet<MachineUrl>) => void;
  onProjectDialogOk: (url: string) => void;
  onReferenceNumberChange: (value: string) => void;
  onSearchClick: () => void;
  onToDateChange: (value: string | null) => void;
  onUsersDialogOk: (urls: ReadonlySet<UserUrl>) => void;
  onWorkTypesDialogOk: (urls: ReadonlySet<WorkTypeUrl>) => void;
  referenceNumber: string;
  searchDisabled: boolean;
  selectedCultures: readonly CultureUrl[];
  selectedCustomers: readonly CustomerUrl[];
  selectedLocations: readonly LocationUrl[];
  selectedMachines: readonly MachineUrl[];
  selectedProject: ProjectUrl | null;
  selectedUsers: readonly UserUrl[];
  selectedWorkTypes: readonly WorkTypeUrl[];
  toDate: string;
}

export const FilterCard = React.memo(function FilterCard(props: FilterCardProps): JSX.Element {
  const {
    fromDate,
    onCulturesDialogOk,
    onCustomersDialogOk,
    onFromDateChange,
    onLocationsDialogOk,
    onMachinesDialogOk,
    onProjectDialogOk,
    onReferenceNumberChange,
    onSearchClick,
    onToDateChange,
    onUsersDialogOk,
    onWorkTypesDialogOk,
    referenceNumber,
    searchDisabled,
    selectedCultures,
    selectedCustomers,
    selectedLocations,
    selectedMachines,
    selectedProject,
    selectedUsers,
    selectedWorkTypes,
    toDate,
  } = props;

  const {projectLabelVariant} = useSelector(getCustomerSettings);

  const handleFromDateBlur = useCallback(() => {
    if (fromDate && (!toDate || fromDate > toDate)) {
      onToDateChange(fromDate);
    }
  }, [fromDate, onToDateChange, toDate]);

  const handlePeriodStartSelectedInDialog = useCallback(
    (date: string | null) => {
      if (date) {
        if (!toDate || date > toDate) {
          onFromDateChange(date);
          onToDateChange(date);
        } else {
          onFromDateChange(date);
        }
      }
    },
    [onFromDateChange, onToDateChange, toDate],
  );

  const intl = useIntl();
  const customerSettings = useSelector(getCustomerSettings);
  const customerLookup = useSelector(getCustomerLookup);
  const locationArray = useSelector(getLocationArray);
  const locationLookup = useSelector(getLocationLookup);
  const cultureLookup = useSelector(getCultureLookup);
  const workTypeLookup = useSelector(getWorkTypeLookup);
  const machineLookup = useSelector(getMachineLookup);
  const projectLookup = useSelector(getProjectLookup);
  const userUserProfileLookup = useSelector(getUserUserProfileLookup);

  const [customersDialogOpen, setCustomersDialogOpen] = useState(false);
  const setCustomersDialogOpenTrue = useCallWithTrue(setCustomersDialogOpen);
  const setCustomersDialogOpenFalse = useCallWithFalse(setCustomersDialogOpen);

  const handleCustomersDialogOk = useCallback(
    (urls: ReadonlySet<CustomerUrl>) => {
      onCustomersDialogOk(urls);
      setCustomersDialogOpen(false);
    },
    [onCustomersDialogOk],
  );

  const selectedUsersSet = useMemo(() => new Set(selectedUsers), [selectedUsers]);
  const selectedCulturesSet = useMemo(() => new Set(selectedCultures), [selectedCultures]);
  const selectedCustomersSet = useMemo(() => new Set(selectedCustomers), [selectedCustomers]);
  const selectedLocationsSet = useMemo(() => new Set(selectedLocations), [selectedLocations]);
  const selectedMachinesSet = useMemo(() => new Set(selectedMachines), [selectedMachines]);
  const selectedWorkTypesSet = useMemo(() => new Set(selectedWorkTypes), [selectedWorkTypes]);

  let customersCell;
  if (customerSettings.externalTaskCustomer) {
    customersCell = (
      <Grid item xs>
        <ConnectedMultipleCustomersDialog
          open={customersDialogOpen}
          selected={selectedCustomersSet}
          onCancel={setCustomersDialogOpenFalse}
          onOk={handleCustomersDialogOk}
        />
        <Button
          color="secondary"
          style={{marginTop: 2}}
          variant="contained"
          onClick={setCustomersDialogOpenTrue}
        >
          <FormattedMessage defaultMessage="Vælg kunder" />
        </Button>
        <div style={{whiteSpace: "pre-line"}}>
          {!selectedCustomers.length ? (
            <FormattedMessage defaultMessage="Ingen filtrering" />
          ) : (
            truncateWithEllipsis(
              selectedCustomers
                .map((customerURL) => customerLookup(customerURL)?.name ?? "")
                .sort(),
            ).join(",\n")
          )}
        </div>
      </Grid>
    );
  }

  const [locationDialogOpen, setLocationDialogOpen] = useState(false);
  const setLocationDialogOpenTrue = useCallWithTrue(setLocationDialogOpen);
  const setLocationDialogOpenFalse = useCallWithFalse(setLocationDialogOpen);

  const handleLocationsDialogOk = useCallback(
    (urls: ReadonlySet<LocationUrl>) => {
      onLocationsDialogOk(urls);
      setLocationDialogOpen(false);
    },
    [onLocationsDialogOk],
  );

  let locationsCell;
  if (locationArray.length) {
    locationsCell = (
      <Grid item xs>
        <MultiLocationDialog
          includeLogOnlyLocations
          includeWorkplaceOnlyLocations
          locationCrossCustomerSelectionEnabled
          customerLookup={customerLookup}
          customerURL={null}
          locationArray={locationArray}
          locationFavoritesEnabled={false}
          onlyActive={false}
          open={locationDialogOpen}
          selected={selectedLocationsSet}
          titleVariant="LOCATION"
          onCancel={setLocationDialogOpenFalse}
          onOk={handleLocationsDialogOk}
        />
        <Button
          color="secondary"
          style={{marginTop: 2}}
          variant="contained"
          onClick={setLocationDialogOpenTrue}
        >
          <FormattedMessage defaultMessage="Vælg steder" />
        </Button>
        <div style={{whiteSpace: "pre-line"}}>
          {!selectedLocations.length ? (
            <FormattedMessage defaultMessage="Ingen filtrering" />
          ) : (
            truncateWithEllipsis(
              selectedLocations
                .map((locationURL) => {
                  const location = locationLookup(locationURL);
                  return location?.name || location?.address || "";
                })
                .sort(),
            ).join(",\n")
          )}
        </div>
      </Grid>
    );
  }

  const [culturesDialogOpen, setCulturesDialogOpen] = useState(false);
  const setCulturesDialogOpenTrue = useCallWithTrue(setCulturesDialogOpen);
  const setCulturesDialogOpenFalse = useCallWithFalse(setCulturesDialogOpen);

  const handleCulturesDialogOk = useCallback(
    (urls: ReadonlySet<CultureUrl>) => {
      onCulturesDialogOk(urls);
      setCulturesDialogOpen(false);
    },
    [onCulturesDialogOk],
  );

  let culturesCell;
  if (customerSettings.externalTaskCulture) {
    culturesCell = (
      <Grid item xs>
        <ConnectedMultipleCulturesDialog
          open={culturesDialogOpen}
          selected={selectedCulturesSet}
          onCancel={setCulturesDialogOpenFalse}
          onOk={handleCulturesDialogOk}
        />
        <Button
          color="secondary"
          style={{marginTop: 2}}
          variant="contained"
          onClick={setCulturesDialogOpenTrue}
        >
          <FormattedMessage defaultMessage="Vælg kulturer" />
        </Button>
        <div style={{whiteSpace: "pre-line"}}>
          {!selectedCultures.length ? (
            <FormattedMessage defaultMessage="Ingen filtrering" />
          ) : (
            truncateWithEllipsis(
              selectedCultures.map((cultureURL) => cultureLookup(cultureURL)?.name ?? "").sort(),
            ).join(",\n")
          )}
        </div>
      </Grid>
    );
  }

  const [workTypesDialogOpen, setWorkTypesDialogOpen] = useState(false);
  const setWorkTypesDialogOpenTrue = useCallWithTrue(setWorkTypesDialogOpen);
  const setWorkTypesDialogOpenFalse = useCallWithFalse(setWorkTypesDialogOpen);

  const handleWorkTypesDialogOk = useCallback(
    (urls: ReadonlySet<WorkTypeUrl>) => {
      onWorkTypesDialogOk(urls);
      setWorkTypesDialogOpen(false);
    },
    [onWorkTypesDialogOk],
  );

  let workTypesCell;
  if (!customerSettings.noExternalTaskWorkType) {
    workTypesCell = (
      <Grid item xs>
        <ConnectedMultipleExternalWorkTypesDialog
          open={workTypesDialogOpen}
          selected={selectedWorkTypesSet}
          onCancel={setWorkTypesDialogOpenFalse}
          onOk={handleWorkTypesDialogOk}
        />
        <Button
          color="secondary"
          style={{marginTop: 2}}
          variant="contained"
          onClick={setWorkTypesDialogOpenTrue}
        >
          <FormattedMessage defaultMessage="Vælg arbejdsområder" />
        </Button>
        <div style={{whiteSpace: "pre-line"}}>
          {!selectedWorkTypes.length ? (
            <FormattedMessage defaultMessage="Ingen filtrering" />
          ) : (
            truncateWithEllipsis(
              selectedWorkTypes
                .map((workTypeURL) => getWorkTypeString(workTypeLookup(workTypeURL)))
                .sort(),
            ).join(",\n")
          )}
        </div>
      </Grid>
    );
  }

  const [machinesDialogOpen, setMachinesDialogOpen] = useState(false);
  const setMachinesDialogOpenTrue = useCallWithTrue(setMachinesDialogOpen);
  const setMachinesDialogOpenFalse = useCallWithFalse(setMachinesDialogOpen);

  const handleMachinesDialogOk = useCallback(
    (urls: ReadonlySet<MachineUrl>) => {
      onMachinesDialogOk(urls);
      setMachinesDialogOpen(false);
    },
    [onMachinesDialogOk],
  );

  const machinesCell = (
    <Grid item xs>
      <ConnectedMultipleMachinesDialog
        open={machinesDialogOpen}
        selected={selectedMachinesSet}
        onCancel={setMachinesDialogOpenFalse}
        onOk={handleMachinesDialogOk}
      />
      <Button
        color="secondary"
        style={{marginTop: 2}}
        variant="contained"
        onClick={setMachinesDialogOpenTrue}
      >
        {customerSettings.machineLabelVariant === "MACHINE" ? (
          <FormattedMessage defaultMessage="Vælg maskiner" />
        ) : (
          <FormattedMessage defaultMessage="Vælg køretøjer" />
        )}
      </Button>
      <div style={{whiteSpace: "pre-line"}}>
        {!selectedMachines.length ? (
          <FormattedMessage defaultMessage="Ingen filtrering" />
        ) : (
          truncateWithEllipsis(
            selectedMachines
              .map((machineURL) => getMachineString(machineLookup(machineURL)))
              .sort(),
          ).join(",\n")
        )}
      </div>
    </Grid>
  );

  const [usersDialogOpen, setUsersDialogOpen] = useState(false);
  const setUsersDialogOpenTrue = useCallWithTrue(setUsersDialogOpen);
  const setUsersDialogOpenFalse = useCallWithFalse(setUsersDialogOpen);

  const handleUsersDialogOk = useCallback(
    (urls: ReadonlySet<UserUrl>) => {
      onUsersDialogOk(urls);
      setUsersDialogOpen(false);
    },
    [onUsersDialogOk],
  );

  const usersCell = (
    <Grid item xs>
      <ConnectedMultipleUsersDialog
        open={usersDialogOpen}
        selected={selectedUsersSet}
        onCancel={setUsersDialogOpenFalse}
        onOk={handleUsersDialogOk}
      />
      <Button
        color="secondary"
        style={{marginTop: 2}}
        variant="contained"
        onClick={setUsersDialogOpenTrue}
      >
        {customerSettings.employeeLabelVariant === "CHAUFFEUR" ? (
          <FormattedMessage defaultMessage="Vælg chaufører" />
        ) : customerSettings.employeeLabelVariant === "EMPLOYEE" ? (
          <FormattedMessage defaultMessage="Vælg medarbejdere" />
        ) : (
          <FormattedMessage defaultMessage="Vælg maskinførere" />
        )}
      </Button>
      <div style={{whiteSpace: "pre-line"}}>
        {!selectedUsers.length ? (
          <FormattedMessage defaultMessage="Ingen filtrering" />
        ) : (
          truncateWithEllipsis(
            selectedUsers.map((userURL) => userUserProfileLookup(userURL)?.alias || "").sort(),
          ).join(",\n")
        )}
      </div>
    </Grid>
  );

  const [projectDialogOpen, setProjectDialogOpen] = useState(false);
  const setProjectDialogOpenTrue = useCallWithTrue(setProjectDialogOpen);
  const setProjectDialogOpenFalse = useCallWithFalse(setProjectDialogOpen);

  const handleProjectDialogOk = useCallback(
    (url: string) => {
      onProjectDialogOk(url);
      setProjectDialogOpen(false);
    },
    [onProjectDialogOk],
  );

  let projectCell;
  if (customerSettings.enableProjects) {
    projectCell = (
      <Grid item xs>
        <ConnectedProjectDialog
          showAllProjects
          open={projectDialogOpen}
          suggestRecentlyUsed={false}
          onCancel={setProjectDialogOpenFalse}
          onOk={handleProjectDialogOk}
        />
        <Button
          color="secondary"
          style={{marginTop: 2}}
          variant="contained"
          onClick={setProjectDialogOpenTrue}
        >
          {projectLabelVariant === "PROJECT" ? (
            <FormattedMessage defaultMessage="Vælg projekt" />
          ) : (
            <FormattedMessage defaultMessage="Vælg sag" />
          )}
        </Button>
        <div style={{whiteSpace: "pre-line"}}>
          {selectedProject ? (
            getProjectString(projectLookup(selectedProject))
          ) : (
            <FormattedMessage defaultMessage="Ingen filtrering" />
          )}
        </div>
      </Grid>
    );
  }
  let referenceNumberCell;
  if (customerSettings.enableTaskReferenceNumber || customerSettings.enableOrderReferenceNumber) {
    const referenceNumberLabel =
      customerSettings.taskReferenceNumberLabel ||
      customerSettings.orderReferenceNumberLabel ||
      intl.formatMessage({defaultMessage: "Ref. nr."});
    referenceNumberCell = (
      <Grid item xs>
        <TrimTextField
          inputProps={{maxLength: 255}}
          label={referenceNumberLabel}
          margin="dense"
          style={{width: 256}}
          value={referenceNumber}
          variant="outlined"
          onChange={onReferenceNumberChange}
        />
      </Grid>
    );
  }

  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          {customersCell}
          {culturesCell}
          {workTypesCell}
          {machinesCell}
          {locationsCell}
          {usersCell}
        </Grid>
        <Grid container spacing={3}>
          {projectCell}
          {referenceNumberCell}
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs>
            <DateField
              autoOk
              fullWidth
              label={intl.formatMessage({
                defaultMessage: "Fra",
              })}
              margin="dense"
              referenceDate={toDate || undefined}
              value={fromDate}
              yearSuggestions="DATE_BEFORE"
              onBlur={handleFromDateBlur}
              onChange={onFromDateChange}
              onSelectedInDialog={handlePeriodStartSelectedInDialog}
            />
          </Grid>
          <Grid item xs>
            <DateField
              autoOk
              fullWidth
              label={intl.formatMessage({
                defaultMessage: "Til",
              })}
              margin="dense"
              referenceDate={fromDate || undefined}
              value={toDate}
              yearSuggestions={fromDate ? "DATE_AFTER" : "DATE_BEFORE"}
              onChange={onToDateChange}
            />
          </Grid>
          <Grid item xs>
            <Button
              disabled={searchDisabled}
              startIcon={<SearchIcon />}
              style={{float: "right", marginTop: 30}}
              onClick={onSearchClick}
            >
              <FormattedMessage defaultMessage="Søg" />
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
});
