import {
  CsvImportSpecification,
  getAvailableColumns,
} from "@co-common-libs/csv-import-specifications";
import {getCustomerSettings} from "@co-frontend-libs/redux";
import {TableCell, TableRow} from "@material-ui/core";
import React from "react";
import {useSelector} from "react-redux";

export function ColumnNameRow(props: {
  selectedImportSpecification: CsvImportSpecification;
}): JSX.Element {
  const {selectedImportSpecification} = props;

  const customerSettings = useSelector(getCustomerSettings);

  const HEADER_CELL_WIDTH = 90;
  const DATA_CELL_WIDTH = 220;

  return (
    <TableRow>
      <TableCell
        style={{
          minWidth: HEADER_CELL_WIDTH,
          width: HEADER_CELL_WIDTH,
        }}
      />
      {getAvailableColumns(customerSettings, selectedImportSpecification).map((column) => (
        <TableCell
          key={column.name}
          style={{
            minWidth: DATA_CELL_WIDTH,
            width: DATA_CELL_WIDTH,
          }}
        >
          {column.label}
        </TableCell>
      ))}
      <TableCell />
    </TableRow>
  );
}
