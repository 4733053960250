import {Config} from "@co-common-libs/config";
import {
  Customer,
  CustomerUrl,
  Location,
  LocationUrl,
  Machine,
  MachineUrl,
  Order,
  OrderUrl,
  PatchUnion,
  ResourceTypeUnion,
  Task,
  TaskUrl,
  User,
  WorkType,
  WorkTypeUrl,
  YieldDelivery,
  YieldDeliveryLocation,
  YieldDeliveryLocationUrl,
  YieldLog as YieldLogResource,
  YieldPickup,
  YieldPickupLocation,
  YieldPickupLocationUrl,
  resourceNameFor,
} from "@co-common-libs/resources";
import {getNormalisedDeviceTimestamp, getWorkTypeString} from "@co-common-libs/resources-utils";
import {notUndefined} from "@co-common-libs/utils";
import {DeleteDialog} from "@co-frontend-libs/components";
import {
  AppState,
  actions,
  getCurrentUserURL,
  getCustomerLookup,
  getCustomerSettings,
  getLocationLookup,
  getMachineLookup,
  getOrderLookup,
  getWorkTypeLookup,
  getYieldDeliveryArray,
  getYieldDeliveryLocationArray,
  getYieldDeliveryLocationLookup,
  getYieldLogArray,
  getYieldPickupArray,
  getYieldPickupLocationArray,
  getYieldPickupLocationLookup,
} from "@co-frontend-libs/redux";
import {
  CustomerSelectCreateDialog,
  YieldDialog,
  YieldLocationDialog,
  YieldLogDialog,
} from "app-components";
import {PureComponent, computePatch} from "app-utils";
import {bind} from "bind-decorator";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import React from "react";
import {FormattedMessage, IntlContext, defineMessages} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {v4 as uuid} from "uuid";
import {YieldLogCard} from "./yield-log-card";

const messages = defineMessages({
  contractorWork: {
    defaultMessage: "Entreprenørarbejde",
    id: "yield-log-card.label.contractor-work",
  },
});

interface YieldLogStateProps {
  currentUserURL: string | null;
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  customerSettings: Config;
  locationLookup: (url: LocationUrl) => Location | undefined;
  machineLookup: (url: MachineUrl) => Machine | undefined;
  orderLookup: (url: OrderUrl) => Order | undefined;
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
  yieldDeliveryArray: readonly YieldDelivery[];
  yieldDeliveryLocationArray: readonly YieldDeliveryLocation[];
  yieldDeliveryLocationLookup: (url: YieldDeliveryLocationUrl) => YieldDeliveryLocation | undefined;
  yieldLogArray: readonly YieldLogResource[];
  yieldPickupArray: readonly YieldPickup[];
  yieldPickupLocationArray: readonly YieldPickupLocation[];
  yieldPickupLocationLookup: (url: YieldPickupLocationUrl) => YieldPickupLocation | undefined;
}

interface YieldLogDispatchProps {
  create: (instance: ResourceTypeUnion) => void;
  registerTaskPosition: (taskUrl: TaskUrl) => void;
  remove: (url: string) => void;
  update: (url: string, patch: PatchUnion) => void;
}

interface YieldLogOwnProps {
  addDialogs: (dialogs: readonly JSX.Element[]) => void;
  completed: boolean;
  disabled: boolean;
  machineOperator?: User | undefined;
  onRequestBuildReports: () => void;
  task: Task;
  userIsOtherMachineOperator: boolean;
  validated: boolean;
  yieldLog: YieldLogResource;
}

type YieldLogProps = YieldLogDispatchProps & YieldLogOwnProps & YieldLogStateProps;

interface YieldLogState {
  customerDialogCallback: ((customer: Customer) => void) | null;
  deleteDeliveryLocation: string | null;
  deleteEntryRequest: YieldDelivery | YieldPickup | null;
  deletePickupLocation: string | null;
  deliveryDialogEdit: YieldDelivery | null;
  deliveryDialogLocation: YieldDeliveryLocation | null;
  deliveryLocationDialog: {
    amount: number | null;
    customer: Customer | null;
    location: Location | null;
    note: string;
    open: boolean;
    url: YieldDeliveryLocationUrl | null;
  };
  pickupDialogEdit: YieldPickup | null;
  pickupDialogLocation: YieldPickupLocation | null;
  pickupLocationDialog: {
    amount: number | null;
    customer: Customer | null;
    location: Location | null;
    note: string;
    open: boolean;
    url: YieldPickupLocationUrl | null;
  };
  yieldLogDialogOpen: boolean;
}

class YieldLog extends PureComponent<YieldLogProps, YieldLogState> {
  state: YieldLogState = {
    customerDialogCallback: null,
    deleteDeliveryLocation: null,
    deleteEntryRequest: null,
    deletePickupLocation: null,
    deliveryDialogEdit: null,
    deliveryDialogLocation: null,
    deliveryLocationDialog: {
      amount: null,
      customer: null,
      location: null,
      note: "",
      open: false,
      url: null,
    },
    pickupDialogEdit: null,
    pickupDialogLocation: null,
    pickupLocationDialog: {
      amount: null,
      customer: null,
      location: null,
      note: "",
      open: false,
      url: null,
    },
    yieldLogDialogOpen: false,
  };

  componentDidMount(): void {
    this.addDialogs(this.props, this.state);
  }

  UNSAFE_componentWillReceiveProps(nextProps: YieldLogProps): void {
    const {
      customerLookup,
      locationLookup,
      userIsOtherMachineOperator,
      yieldDeliveryArray,
      yieldDeliveryLocationArray,
      yieldDeliveryLocationLookup,
      yieldLog,
      yieldPickupArray,
      yieldPickupLocationArray,
      yieldPickupLocationLookup,
    } = nextProps;

    if (
      this.props.locationLookup === locationLookup &&
      this.props.yieldLog === yieldLog &&
      this.props.userIsOtherMachineOperator === userIsOtherMachineOperator &&
      this.props.customerLookup === customerLookup &&
      this.props.yieldDeliveryLocationArray === yieldDeliveryLocationArray &&
      this.props.yieldDeliveryLocationLookup === yieldDeliveryLocationLookup &&
      this.props.yieldPickupLocationArray === yieldPickupLocationArray &&
      this.props.yieldPickupLocationLookup === yieldPickupLocationLookup &&
      this.props.yieldPickupArray === yieldPickupArray &&
      this.props.yieldDeliveryArray === yieldDeliveryArray
    ) {
      return;
    }
    this.addDialogs(nextProps, this.state);
  }

  shouldComponentUpdate(nextProps: YieldLogProps, nextState: YieldLogState): boolean {
    if (this.state !== nextState) {
      this.addDialogs(nextProps, nextState);
    }
    return true;
  }

  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  addDialogs(props: YieldLogProps, state: YieldLogState): void {
    const {
      customerLookup,
      locationLookup,
      userIsOtherMachineOperator,
      yieldDeliveryArray,
      yieldDeliveryLocationArray,
      yieldLog,
      yieldPickupArray,
      yieldPickupLocationArray,
    } = props;

    const pickupDialogCustomer =
      state.pickupDialogLocation && state.pickupDialogLocation.customer
        ? customerLookup(state.pickupDialogLocation.customer)
        : undefined;
    const pickupDialogNote = state.pickupDialogEdit ? state.pickupDialogEdit.note : undefined;

    const deliveryDialogCustomer =
      state.deliveryDialogLocation && state.deliveryDialogLocation.customer
        ? customerLookup(state.deliveryDialogLocation.customer)
        : undefined;
    const deliveryDialogNote = state.deliveryDialogEdit ? state.deliveryDialogEdit.note : undefined;

    let deliveryDialogAmount: number | undefined;
    if (state.deliveryDialogEdit) {
      if (state.deliveryDialogEdit.amount != null) {
        deliveryDialogAmount = state.deliveryDialogEdit.amount;
      }
    } else if (yieldLog && state.deliveryDialogLocation) {
      const yieldLogURL = yieldLog.url;
      const pickupLocationURLSet = new Set(
        yieldPickupLocationArray.filter((p) => p.yieldlog === yieldLogURL).map((p) => p.url),
      );
      const deliveryLocationURLSet = new Set(
        yieldDeliveryLocationArray.filter((d) => d.yieldlog === yieldLogURL).map((d) => d.url),
      );
      const pickupList = _.sortBy(
        yieldPickupArray.filter((p) => pickupLocationURLSet.has(p.location)),
        getNormalisedDeviceTimestamp,
      ) as YieldPickup[];
      const deliveryList = _.sortBy(
        yieldDeliveryArray.filter((d) => deliveryLocationURLSet.has(d.location)),
        getNormalisedDeviceTimestamp,
      ) as YieldDelivery[];
      const pickupSum = pickupList.reduce((acc, pickup) => {
        return acc + (pickup.amount || 0);
      }, 0);
      const deliverySum = deliveryList.reduce((acc, delivery) => {
        return acc + (delivery.amount || 0);
      }, 0);
      const decimals = 2;
      deliveryDialogAmount = _.round(pickupSum - deliverySum, decimals);
    }

    let pickupDialogAmount: number | undefined;
    if (state.pickupDialogEdit) {
      if (state.pickupDialogEdit.amount != null) {
        pickupDialogAmount = state.pickupDialogEdit.amount;
      }
    } else if (yieldLog && state.pickupDialogLocation) {
      const yieldLogURL = yieldLog.url;
      const pickupLocationURLSet = new Set(
        yieldPickupLocationArray.filter((p) => p.yieldlog === yieldLogURL).map((p) => p.url),
      );
      const lastPickup = _.maxBy(
        yieldPickupArray.filter((p) => pickupLocationURLSet.has(p.location)),
        (yieldPickup: YieldPickup) =>
          (yieldPickup.deviceTimestamp && getNormalisedDeviceTimestamp) || "",
      );
      if (lastPickup && lastPickup.amount != null) {
        pickupDialogAmount = lastPickup.amount;
      }
    }
    const yieldLogURL = yieldLog.url;
    const yieldPickupLocationDialog = (
      <YieldLocationDialog
        key={`yieldlog-pickup-location-${yieldLogURL}`}
        isPickup
        canDelete={!userIsOtherMachineOperator}
        customer={state.pickupLocationDialog.customer || undefined}
        isNew={!state.pickupLocationDialog.url}
        location={state.pickupLocationDialog.location || undefined}
        note={state.pickupLocationDialog.note}
        open={state.pickupLocationDialog.open}
        onCancel={this.handlePickupLocationDialogCancel}
        onOk={this.handlePickupLocationDialogOk}
        onRequestCustomerDialog={this.handleRequestCustomerDialog}
        onRequestDelete={this.handlePickupLocationDelete}
      />
    );
    const yieldDeliveryLocationDialog = (
      <YieldLocationDialog
        key={`yieldlog-delivery-location-${yieldLogURL}`}
        canDelete={!userIsOtherMachineOperator}
        customer={state.deliveryLocationDialog.customer || undefined}
        isNew={!state.deliveryLocationDialog.url}
        isPickup={false}
        location={state.deliveryLocationDialog.location || undefined}
        note={state.deliveryLocationDialog.note}
        open={state.deliveryLocationDialog.open}
        onCancel={this.handleDeliveryLocationDialogCancel}
        onOk={this.handleDeliveryLocationDialogOk}
        onRequestCustomerDialog={this.handleRequestCustomerDialog}
        onRequestDelete={this.handleDeliveryLocationDelete}
      />
    );
    const deletePickupLocationDialog = (
      <DeleteDialog
        key={`yieldlog-delete-pickup-${yieldLogURL}`}
        open={!!state.deletePickupLocation}
        onCancel={this.handleDeletePickupLocationCancel}
        onOk={this.handleDeletePickupLocationOk}
      >
        <FormattedMessage
          defaultMessage="Slet afhentningssted?"
          id="task-instance.label.delete-pickup-location"
        />
      </DeleteDialog>
    );
    const deleteDeliveryLocationDialog = (
      <DeleteDialog
        key={`yieldlog-delete-delivery-${yieldLogURL}`}
        open={!!state.deleteDeliveryLocation}
        onCancel={this.handleDeleteDeliveryLocationCancel}
        onOk={this.handleDeleteDeliveryLocationOk}
      >
        <FormattedMessage
          defaultMessage="Slet leveringsted?"
          id="task-instance.label.delete-delivery-location"
        />
      </DeleteDialog>
    );
    const yieldPickupDialog = (
      <YieldDialog
        key={`yieldlog-pickup-${yieldLogURL}`}
        isPickup
        amount={pickupDialogAmount}
        customer={pickupDialogCustomer}
        isNew={!state.pickupDialogEdit}
        locationLookup={locationLookup}
        note={pickupDialogNote}
        open={!!state.pickupDialogLocation}
        unit={yieldLog ? yieldLog.unit : undefined}
        yieldLocation={state.pickupDialogLocation || undefined}
        onCancel={this.handlePickupDialogCancel}
        onOk={this.handlePickupDialogOk}
        onRequestLocationDialog={this.handleRequestPickupLocationDialog}
      />
    );
    const yieldDeliveryDialog = (
      <YieldDialog
        key={`yieldlog-delivery-${yieldLogURL}`}
        amount={deliveryDialogAmount}
        customer={deliveryDialogCustomer}
        isNew={!state.deliveryDialogEdit}
        isPickup={false}
        locationLookup={locationLookup}
        note={deliveryDialogNote}
        open={!!state.deliveryDialogLocation}
        unit={yieldLog ? yieldLog.unit : undefined}
        yieldLocation={state.deliveryDialogLocation || undefined}
        onCancel={this.handleDeliveryDialogCancel}
        onOk={this.handleDeliveryDialogOk}
        onRequestLocationDialog={this.handleRequestDeliveryLocationDialog}
      />
    );
    const yieldLogDialog = (
      <YieldLogDialog
        key={`yieldlog-dialog-${yieldLogURL}`}
        crop={yieldLog.crop}
        cuts={yieldLog.cuts != null ? yieldLog.cuts : undefined}
        density={yieldLog.density != null ? yieldLog.density : undefined}
        open={state.yieldLogDialogOpen}
        unit={yieldLog.unit}
        wagonWeight={yieldLog.wagonWeight != null ? yieldLog.wagonWeight : undefined}
        onCancel={this.handleYieldLogDialogCancel}
        onOk={this.handleYieldLogDialogOk}
      />
    );
    const customerDialog = (
      <CustomerSelectCreateDialog
        key={`yieldlog-customer-dialog-${yieldLogURL}`}
        open={!!state.customerDialogCallback}
        onCancel={this.handleCustomerDialogCancel}
        onOk={this.handleCustomerDialogOk}
      />
    );
    const deleteEntryDialog = (
      <DeleteDialog
        key={`yieldlog-delete-entry-${yieldLogURL}`}
        open={!!state.deleteEntryRequest}
        onCancel={this.handleDeleteEntryCancel}
        onOk={this.handleDeleteEntryOk}
      >
        <FormattedMessage
          defaultMessage="Slet postering?"
          id="yieldlog.label.delete-yieldlog-entry"
        />
      </DeleteDialog>
    );

    props.addDialogs([
      yieldPickupLocationDialog,
      yieldDeliveryLocationDialog,
      deletePickupLocationDialog,
      deleteDeliveryLocationDialog,
      yieldPickupDialog,
      yieldDeliveryDialog,
      yieldLogDialog,
      customerDialog,
      deleteEntryDialog,
    ]);
  }

  @bind
  handleRequestCustomerDialog(callback: (customer: Customer) => void): void {
    this.setState({customerDialogCallback: callback});
  }
  @bind
  handleCustomerDialogOk(url: CustomerUrl): void {
    const callback = this.state.customerDialogCallback;
    this.setState({customerDialogCallback: null});
    const customer = this.props.customerLookup(url);
    if (callback && customer) {
      callback(customer);
    }
  }
  @bind
  handleCustomerDialogCancel(): void {
    this.setState({customerDialogCallback: null});
  }

  @bind
  handleRequestPickupLocationDialog(
    pickupLocation: YieldDeliveryLocation | YieldPickupLocation | null,
  ): void {
    if (pickupLocation) {
      const pickupLocationDialog = {
        amount: null,
        customer:
          (pickupLocation.customer && this.props.customerLookup(pickupLocation.customer)) || null,
        location:
          (pickupLocation.relatedLocation &&
            this.props.locationLookup(pickupLocation.relatedLocation)) ||
          null,
        note: pickupLocation.note,
        open: true,
        unit: "",
        url: pickupLocation.url as YieldPickupLocationUrl,
      };
      this.setState({pickupDialogLocation: null, pickupLocationDialog});
    } else {
      const {task} = this.props;
      const orderURL = task && task.order;
      const order = orderURL ? this.props.orderLookup(orderURL) : null;
      const customerURL = order && order.customer;
      const customer = (customerURL && this.props.customerLookup(customerURL)) || null;
      const pickupLocationDialog = {
        address: "",
        amount: null,
        customer,
        location: null,
        note: "",
        open: true,
        unit: this.props.yieldLog.unit,
        url: null,
      };
      this.setState({pickupDialogLocation: null, pickupLocationDialog});
    }
  }
  @bind
  handlePickupLocationDialogOk(params: {
    address: string;
    customer: Customer | null;
    location: Location | null;
    note: string;
  }): void {
    const {customer, location, note} = params;
    const existingURL = this.state.pickupLocationDialog.url;
    const {task} = this.props;
    const taskURL = task.url;
    if (existingURL) {
      const oldPickupLocation = this.props.yieldPickupLocationLookup(existingURL);
      if (!oldPickupLocation) {
        return;
      }
      const newPickupLocation = {
        ...oldPickupLocation,
        amount: null,
        customer: customer ? customer.url : null,
        note,
        relatedLocation: location ? location.url : null,
      };
      const patch = computePatch(newPickupLocation, oldPickupLocation);
      if (patch) {
        this.props.update(oldPickupLocation.url, patch);
      }
    } else {
      const id = uuid();
      const url = instanceURL("yieldPickupLocation", id);
      const yieldLog = this.props.yieldLogArray.find((t) => t.task === taskURL);
      if (!yieldLog) {
        return;
      }
      const yieldLogURL = yieldLog.url;
      const order = this.props.yieldPickupLocationArray.filter(
        (d) => d.yieldlog === yieldLogURL,
      ).length;
      const newPickupLocation: YieldPickupLocation = {
        customer: customer ? customer.url : null,
        id,
        note,
        order,
        relatedLocation: location ? location.url : null,
        url,
        yieldlog: yieldLogURL,
      };
      this.props.create(newPickupLocation);
    }
    this.handlePickupLocationDialogCancel();
  }
  @bind
  handlePickupLocationDialogCancel(): void {
    const pickupLocationDialog = {
      amount: null,
      customer: null,
      location: null,
      note: "",
      open: false,
      url: null,
    };
    this.setState({pickupLocationDialog});
  }

  @bind
  handlePickupLocationDelete(): void {
    const existingURL = this.state.pickupLocationDialog.url;
    this.handlePickupLocationDialogCancel();
    this.setState({deletePickupLocation: existingURL});
  }

  @bind
  handleDeletePickupLocationCancel(): void {
    this.setState({deletePickupLocation: null});
  }

  @bind
  handleDeletePickupLocationOk(): void {
    const {deletePickupLocation} = this.state;
    if (deletePickupLocation) {
      this.setState({deletePickupLocation: null});
      this.props.remove(deletePickupLocation);
    }
  }

  @bind
  handleRequestPickupDialog(pickupLocation: YieldPickupLocation): void {
    this.setState({pickupDialogLocation: pickupLocation});
  }

  registerGeolocation(): void {
    const {customerSettings, registerTaskPosition, yieldLog} = this.props;
    if (customerSettings.geolocation.registerPositionOnTimerClick) {
      const taskURL = yieldLog.task;
      registerTaskPosition(taskURL);
    }
  }

  @bind
  handlePickupDialogOk(params: {amount: number | null; note: string}): void {
    const {amount, note} = params;
    const location = this.state.pickupDialogLocation;
    const existing = this.state.pickupDialogEdit;
    if (!location) {
      return;
    }
    this.setState({pickupDialogEdit: null, pickupDialogLocation: null});
    if (existing) {
      const pickup = {...existing, amount, note};
      const patch = computePatch(pickup, existing);
      if (patch) {
        this.props.update(existing.url, patch);
      }
    } else {
      const id = uuid();
      const url = instanceURL("yieldPickup", id);
      const pickup = {
        amount,
        deviceTimestamp: new Date().toISOString(),
        id,
        location: location.url,
        note,
        url,
      };
      this.props.create(pickup);
    }
    this.registerGeolocation();
  }

  @bind
  handlePickupDialogCancel(): void {
    this.setState({pickupDialogEdit: null, pickupDialogLocation: null});
  }

  @bind
  handleRequestDeliveryLocationDialog(deliveryLocation: YieldDeliveryLocation | null): void {
    if (deliveryLocation) {
      const deliveryLocationDialog = {
        amount: null,
        customer:
          (deliveryLocation.customer && this.props.customerLookup(deliveryLocation.customer)) ||
          null,
        location:
          (deliveryLocation.relatedLocation &&
            this.props.locationLookup(deliveryLocation.relatedLocation)) ||
          null,
        note: deliveryLocation.note,
        open: true,
        url: deliveryLocation.url,
      };
      this.setState({deliveryDialogLocation: null, deliveryLocationDialog});
    } else {
      const {task} = this.props;
      const orderURL = task && task.order;
      const order = orderURL ? this.props.orderLookup(orderURL) : null;
      const customerURL = order && order.customer;
      const customer = (customerURL && this.props.customerLookup(customerURL)) || null;
      const deliveryLocationDialog = {
        amount: null,
        customer,
        location: null,
        note: "",
        open: true,
        url: null,
      };
      this.setState({deliveryDialogLocation: null, deliveryLocationDialog});
    }
  }
  @bind
  handleDeliveryLocationDialogOk(params: {
    address: string;
    customer: Customer | null;
    location: Location | null;
    note: string;
  }): void {
    const {customer, location, note} = params;
    const existingURL = this.state.deliveryLocationDialog.url;
    const {task} = this.props;
    const taskURL = task.url;
    if (existingURL) {
      const oldDeliveryLocation = this.props.yieldDeliveryLocationLookup(existingURL);
      if (!oldDeliveryLocation) {
        return;
      }
      const newDeliveryLocation = {
        ...oldDeliveryLocation,
        amount: null,
        customer: customer ? customer.url : null,
        note,
        relatedLocation: location ? location.url : null,
      };
      const patch = computePatch(newDeliveryLocation, oldDeliveryLocation);
      if (patch) {
        this.props.update(oldDeliveryLocation.url, patch);
      }
    } else {
      const id = uuid();
      const url = instanceURL("yieldDeliveryLocation", id);
      const yieldLog = this.props.yieldLogArray.find((t) => t.task === taskURL);
      if (!yieldLog) {
        return;
      }
      const yieldLogURL = yieldLog.url;
      const order = this.props.yieldDeliveryLocationArray.filter(
        (d) => d.yieldlog === yieldLogURL,
      ).length;
      const newDeliveryLocation: YieldDeliveryLocation = {
        customer: customer ? customer.url : null,
        id,
        note,
        order,
        relatedLocation: location ? location.url : null,
        url,
        yieldlog: yieldLogURL,
      };
      this.props.create(newDeliveryLocation);
    }

    this.handleDeliveryLocationDialogCancel();
  }
  @bind
  handleDeliveryLocationDialogCancel(): void {
    const deliveryLocationDialog = {
      amount: null,
      customer: null,
      location: null,
      note: "",
      open: false,
      url: null,
    };
    this.setState({deliveryLocationDialog});
  }

  @bind
  handleDeliveryLocationDelete(): void {
    const existingURL = this.state.deliveryLocationDialog.url;
    this.handleDeliveryLocationDialogCancel();
    this.setState({deleteDeliveryLocation: existingURL});
  }

  @bind
  handleDeleteDeliveryLocationCancel(): void {
    this.setState({deleteDeliveryLocation: null});
  }

  @bind
  handleDeleteDeliveryLocationOk(): void {
    const {deleteDeliveryLocation} = this.state;
    if (deleteDeliveryLocation) {
      this.setState({deleteDeliveryLocation: null});
      this.props.remove(deleteDeliveryLocation);
    }
  }

  @bind
  handleRequestDeliveryDialog(deliveryLocation: YieldDeliveryLocation): void {
    this.setState({deliveryDialogLocation: deliveryLocation});
  }

  @bind
  handleDeliveryDialogOk(params: {amount: number | null; note: string}): void {
    const {amount, note} = params;
    const location = this.state.deliveryDialogLocation;
    const existing = this.state.deliveryDialogEdit;
    if (!location) {
      return;
    }
    this.setState({deliveryDialogEdit: null, deliveryDialogLocation: null});
    if (existing) {
      const delivery = {...existing, amount, note};
      const patch = computePatch(delivery, existing);
      if (patch) {
        this.props.update(existing.url, patch);
      }
    } else {
      const id = uuid();
      const url = instanceURL("yieldDelivery", id);
      const delivery = {
        amount,
        deviceTimestamp: new Date().toISOString(),
        id,
        location: location.url,
        note,
        url,
      };
      this.props.create(delivery);
    }
    this.registerGeolocation();
  }

  @bind
  handleDeliveryDialogCancel(): void {
    this.setState({deliveryDialogEdit: null, deliveryDialogLocation: null});
  }

  @bind
  handleRequestEditEntry(entry: YieldDelivery | YieldPickup): void {
    const locationURL = entry.location;
    const locationType = resourceNameFor(locationURL);
    if (locationType === "yieldPickupLocation") {
      this.setState({
        pickupDialogEdit: entry as YieldPickup,
        pickupDialogLocation:
          this.props.yieldPickupLocationLookup(locationURL as YieldPickupLocationUrl) || null,
      });
    } else if (locationType === "yieldDeliveryLocation") {
      this.setState({
        deliveryDialogEdit: entry as YieldDelivery,
        deliveryDialogLocation:
          this.props.yieldDeliveryLocationLookup(locationURL as YieldDeliveryLocationUrl) || null,
      });
    }
  }

  @bind
  handleRequestDeleteEntry(entry: YieldDelivery | YieldPickup): void {
    this.setState({deleteEntryRequest: entry});
  }
  @bind
  handleDeleteEntryCancel(): void {
    this.setState({deleteEntryRequest: null});
  }
  @bind
  handleDeleteEntryOk(): void {
    const entry = this.state.deleteEntryRequest;
    if (!entry) {
      return;
    }
    this.setState({deleteEntryRequest: null});
    this.props.remove(entry.url);
  }

  @bind
  handleRequestYieldLogDialog(): void {
    this.setState({yieldLogDialogOpen: true});
  }

  @bind
  handleYieldLogDialogOk(params: {
    crop: "" | "corn" | "grass" | "wholecrop";
    cuts: number | null;
    density: number | null;
    unit: "" | "m3" | "tonne";
    wagonWeight: number | null;
  }): void {
    this.setState({yieldLogDialogOpen: false});
    const {crop, cuts, density, unit, wagonWeight} = params;
    const {yieldLog} = this.props;
    const updated: YieldLogResource = {
      ...yieldLog,
      crop,
      cuts,
      density,
      unit,
      wagonWeight,
    };
    const patch = computePatch(updated, yieldLog);
    if (patch) {
      this.props.update(yieldLog.url, patch);
    }
  }

  @bind
  handleYieldLogDialogCancel(): void {
    this.setState({yieldLogDialogOpen: false});
  }

  render(): JSX.Element {
    const {
      completed,
      disabled,
      machineLookup,
      machineOperator,
      task,
      validated,
      workTypeLookup,
      yieldLog,
    } = this.props;
    const {formatMessage} = this.context;

    const machineList = ((task && task.machineuseSet) || [])
      .map((machineUse) => {
        const machineURL = machineUse.machine;
        return machineLookup(machineURL);
      })
      .filter(notUndefined);

    let workTypeString = "";
    const workTypeURL = task ? task.workType : null;
    const workTypeInstance = workTypeURL && workTypeLookup(workTypeURL);
    if (workTypeInstance) {
      workTypeString = getWorkTypeString(workTypeInstance);
    } else {
      if (
        this.props.customerSettings.enableExternalTaskDepartmentField &&
        task.department === "E"
      ) {
        workTypeString = formatMessage(messages.contractorWork);
      } else {
        let primaryMachine;
        if (machineList.length === 1) {
          primaryMachine = machineList[0];
        } else {
          primaryMachine = machineList.find((machine) => !machine.canPull);
          if (!primaryMachine) {
            primaryMachine = machineList.find((machine) => machine.selfPropelled);
          }
          if (!primaryMachine) {
            primaryMachine = machineList[0];
          }
        }
        if (primaryMachine) {
          workTypeString = `${primaryMachine.c5_machine}: ${primaryMachine.name}`;
        }
      }
    }

    return (
      <YieldLogCard
        completed={completed}
        disabled={disabled}
        machineOperator={machineOperator}
        validated={validated}
        workTypeString={workTypeString}
        yieldLog={yieldLog}
        onRequestBuildReports={this.props.onRequestBuildReports}
        onRequestDeleteEntry={this.handleRequestDeleteEntry}
        onRequestDeliveryDialog={this.handleRequestDeliveryDialog}
        onRequestDeliveryLocationDialog={this.handleRequestDeliveryLocationDialog}
        onRequestEditEntry={this.handleRequestEditEntry}
        onRequestPickupDialog={this.handleRequestPickupDialog}
        onRequestPickupLocationDialog={this.handleRequestPickupLocationDialog}
        onRequestYieldLogDialog={this.handleRequestYieldLogDialog}
      />
    );
  }
}

const ConnectedYieldLog: React.ComponentType<YieldLogOwnProps> = connect<
  YieldLogStateProps,
  YieldLogDispatchProps,
  YieldLogOwnProps,
  AppState
>(
  createStructuredSelector<AppState, YieldLogStateProps>({
    currentUserURL: getCurrentUserURL,
    customerLookup: getCustomerLookup,
    customerSettings: getCustomerSettings,
    locationLookup: getLocationLookup,
    machineLookup: getMachineLookup,
    orderLookup: getOrderLookup,
    workTypeLookup: getWorkTypeLookup,
    yieldDeliveryArray: getYieldDeliveryArray,
    yieldDeliveryLocationArray: getYieldDeliveryLocationArray,
    yieldDeliveryLocationLookup: getYieldDeliveryLocationLookup,
    yieldLogArray: getYieldLogArray,
    yieldPickupArray: getYieldPickupArray,
    yieldPickupLocationArray: getYieldPickupLocationArray,
    yieldPickupLocationLookup: getYieldPickupLocationLookup,
  }),
  {
    create: actions.create,
    registerTaskPosition: actions.registerTaskPosition,
    remove: actions.remove,
    update: actions.update,
  },
)(YieldLog);

export {ConnectedYieldLog as YieldLog};
