import {
  Customer,
  CustomerUrl,
  RoutePlan,
  RoutePlanTask,
  RoutePlanUrl,
  WorkType,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {notUndefined} from "@co-common-libs/utils";
import {ColumnSpecifications, GenericTable, RowData} from "@co-frontend-libs/components";
import {
  AppState,
  actions,
  getCustomerLookup,
  getRoutePlanArray,
  getRoutePlanTaskArray,
  getTableSortingState,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {bind} from "bind-decorator";
import _ from "lodash";
import memoize from "memoize-one";
import React from "react";
import {IntlContext, IntlShape, defineMessages} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";

const TABLE_SORTING_IDENTIFIER = "RoutePlanTable";
const MAX_LIST_ENTRIES_DISPLAYED = 100;

const messages = defineMessages({
  customers: {
    defaultMessage: "Kunder",
    id: "routes.route-table.header.customers",
  },
  name: {
    defaultMessage: "Kaldenavn",
    id: "routes.route-table.header.route-plan-name",
  },
  workType: {
    defaultMessage: "Arbejdsområde",
    id: "routes.route-table.header.work-type",
  },
});

type RoutePlanTableFieldID = "customers" | "name" | "workType";

type RoutePlanTableColumnID = "customers" | "name" | "workType";

interface RoutePlanTableDataType extends RowData<RoutePlanTableFieldID, RoutePlanUrl> {
  customers: string;
  name: string;
  workType: string;
}

function buildColumnSpecifications(
  formatMessage: IntlShape["formatMessage"],
  onClick: (orderURL: string) => void,
): ColumnSpecifications<
  RoutePlanTableFieldID,
  RoutePlanTableColumnID,
  RoutePlanUrl,
  RoutePlanTableDataType
> {
  return {
    customers: {
      field: "customers",
      label: formatMessage(messages.customers),
      onClick,
    },
    name: {
      field: "name",
      label: formatMessage(messages.name),
      onClick,
    },
    workType: {
      field: "workType",
      label: formatMessage(messages.workType),
      onClick,
    },
  };
}

function computeVisibleColumns(): readonly RoutePlanTableColumnID[] {
  return ["name", "workType", "customers"];
}

function filterRoutePlans(
  routePlanArray: readonly RoutePlan[],
  onlyActive: boolean,
): readonly RoutePlan[] {
  return onlyActive ? routePlanArray.filter((routePlan) => routePlan.active) : routePlanArray;
}

function sortRoutePlans(routePlanArray: readonly RoutePlan[]): readonly RoutePlan[] {
  return _.sortBy(routePlanArray, [(routePlan) => routePlan.name, (routePlan) => routePlan.url]);
}

function buildRowData(
  routePlanArray: readonly RoutePlan[],
  routePlanTaskArray: readonly RoutePlanTask[],
  customerLookup: (url: CustomerUrl) => Customer | undefined,
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined,
): readonly RoutePlanTableDataType[] {
  return routePlanArray.map((routePlan) => {
    const routePlanURL = routePlan.url;
    const customerURLs = new Set(
      routePlanTaskArray
        .filter((routePlanTask) => routePlanTask.routePlan === routePlanURL)
        .map((routePlanTask) => routePlanTask.customer),
    );
    const customerNames = Array.from(customerURLs)
      .map(customerLookup)
      .filter(notUndefined)
      .map((customer) => customer.name)
      .sort();
    const workType = routePlan.workType ? workTypeLookup(routePlan.workType) : null;
    const workTypeIdentifier = workType?.identifier;
    const workTypeName = workType?.name;
    const workTypeString =
      (workTypeIdentifier ? `${workTypeIdentifier}: ${workTypeName}` : workTypeName) || "";
    return {
      customers: customerNames.join(", "),
      key: routePlanURL,
      name: routePlan.name,
      workType: workTypeString,
    };
  });
}

interface RoutePlanTableStateProps {
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  routePlanArray: readonly RoutePlan[];
  routePlanTaskArray: readonly RoutePlanTask[];
  sortingState: {
    readonly sortDirection: "ASC" | "DESC";
    readonly sortKey: string | null;
  };
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
}

interface RoutePlanTableDispatchProps {
  putTableSortingState: (
    identifier: string,
    sortKey: string,
    sortDirection: "ASC" | "DESC",
  ) => void;
}

interface RoutePlanTableOwnProps {
  filterString: string;
  onClick: (routePlanURL: string) => void;
  onlyActive: boolean;
}

type RoutePlanTableProps = RoutePlanTableDispatchProps &
  RoutePlanTableOwnProps &
  RoutePlanTableStateProps;

class RoutePlanTable extends React.PureComponent<RoutePlanTableProps> {
  constructor(props: RoutePlanTableProps) {
    super(props);
    this.buildColumnSpecifications = memoize(buildColumnSpecifications);
    this.computeVisibleColumns = memoize(computeVisibleColumns);
    this.filterRoutePlans = memoize(filterRoutePlans);
    this.sortRoutePlans = memoize(sortRoutePlans);
    this.buildRowData = memoize(buildRowData);
  }
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  private buildColumnSpecifications: (
    formatMessage: IntlShape["formatMessage"],
    onClick: (orderURL: string) => void,
  ) => ColumnSpecifications<
    RoutePlanTableFieldID,
    RoutePlanTableColumnID,
    RoutePlanUrl,
    RoutePlanTableDataType
  >;
  private computeVisibleColumns: (onlyActive: boolean) => readonly RoutePlanTableColumnID[];
  private filterRoutePlans: (
    routePlanArray: readonly RoutePlan[],
    onlyActive: boolean,
  ) => readonly RoutePlan[];
  private sortRoutePlans: (routePlanArray: readonly RoutePlan[]) => readonly RoutePlan[];
  private buildRowData: (
    routePlanArray: readonly RoutePlan[],
    routePlanTaskArray: readonly RoutePlanTask[],
    customerLookup: (url: CustomerUrl) => Customer | undefined,
    workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined,
  ) => readonly RoutePlanTableDataType[];
  @bind
  handleHeaderClick(key: RoutePlanTableColumnID): void {
    const {putTableSortingState, sortingState} = this.props;
    let direction: "ASC" | "DESC" = "ASC";
    if (sortingState.sortKey === key && sortingState.sortDirection === "ASC") {
      direction = "DESC";
    }
    putTableSortingState(TABLE_SORTING_IDENTIFIER, key, direction);
  }
  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {
      customerLookup,
      filterString,
      onClick,
      onlyActive,
      routePlanArray,
      routePlanTaskArray,
      sortingState,
      workTypeLookup,
    } = this.props;
    const columnSpecifications = this.buildColumnSpecifications(formatMessage, onClick);
    const visibleColumns = this.computeVisibleColumns(onlyActive);
    const filteredRoutePlanArray = this.filterRoutePlans(routePlanArray, onlyActive);
    const filteredSortedRoutePlanArray = this.sortRoutePlans(filteredRoutePlanArray);
    const data = this.buildRowData(
      filteredSortedRoutePlanArray,
      routePlanTaskArray,
      customerLookup,
      workTypeLookup,
    );
    return (
      <GenericTable
        columns={columnSpecifications}
        entries={data}
        filterString={filterString}
        maxDisplayed={MAX_LIST_ENTRIES_DISPLAYED}
        sortBy={sortingState.sortKey as any}
        sortDirection={sortingState.sortDirection}
        visibleColumns={visibleColumns}
        onHeaderClick={this.handleHeaderClick}
      />
    );
  }
}

const ConnectedRoutePlanTable: React.ComponentType<RoutePlanTableOwnProps> = connect<
  RoutePlanTableStateProps,
  RoutePlanTableDispatchProps,
  RoutePlanTableOwnProps,
  AppState
>(
  createStructuredSelector<AppState, RoutePlanTableStateProps>({
    customerLookup: getCustomerLookup,
    routePlanArray: getRoutePlanArray,
    routePlanTaskArray: getRoutePlanTaskArray,
    sortingState: getTableSortingState(TABLE_SORTING_IDENTIFIER, "name", "ASC"),
    workTypeLookup: getWorkTypeLookup,
  }),
  {
    putTableSortingState: actions.putTableSortingState,
  },
)(RoutePlanTable);

export {ConnectedRoutePlanTable as RoutePlanTable};
