export const addDanishCountryPrefix = (phone: string): string => {
  if (!phone || phone === "") {
    return phone;
  }
  if (phone.startsWith("+") || phone.startsWith("00")) {
    return phone;
  } else {
    return `+45${phone}`;
  }
};
