import _ from "lodash";
import {isEconomicMachinesIssue} from "../economic-issue-type-guards";
import {EconomicIssue, EconomicMachinesIssue} from "../types";

export function combineMachineIssues({
  issues,
  type,
}: {
  issues: readonly EconomicIssue[];
  type: EconomicMachinesIssue["issueType"];
}): EconomicMachinesIssue | undefined {
  const issuesMatchingResourceAndType = issues
    .filter(isEconomicMachinesIssue)
    .filter(({issueType}) => issueType === type);

  if (issuesMatchingResourceAndType.length <= 1) {
    return undefined;
  }

  const uniqueInstances = _.uniq(issuesMatchingResourceAndType.flatMap(({instances}) => instances));

  const {category, issueType} = issuesMatchingResourceAndType[0];

  return {
    category,
    instances: uniqueInstances,
    issueType,
    resourceName: "machine",
  };
}
