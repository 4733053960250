import {UserUrl} from "@co-common-libs/resources";
import {getNextAnniversaryDate} from "@co-common-libs/resources-utils";
import {dateFromString, dateToString, formatDateNumeric, notNull} from "@co-common-libs/utils";
import {getAnniversaryTypeArray, getUserUserProfileLookup} from "@co-frontend-libs/redux";
import {Card, CardHeader, List, ListItem, ListItemText} from "@material-ui/core";
import _ from "lodash";
import CakeVariantIcon from "mdi-react/CakeVariantIcon";
import FlagVariantIcon from "mdi-react/FlagVariantIcon";
import MedalIcon from "mdi-react/MedalIcon";
import React, {useMemo} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";

export const getAnniversaryIcon = (
  iconName: "cake" | "flag" | "medal",
  color?: string,
  size?: number | string,
): JSX.Element => {
  const iconProps: {color?: string; size?: number | string} = {};
  if (color !== undefined) {
    iconProps.color = color;
  }
  if (size !== undefined) {
    iconProps.size = size;
  }
  switch (iconName) {
    case "cake":
      return <CakeVariantIcon {...iconProps} />;
    case "flag":
      return <FlagVariantIcon {...iconProps} />;
    case "medal":
      return <MedalIcon {...iconProps} />;
    default:
      throw new Error("Anniversary icon type not allowed");
  }
};

interface AnniversaryCardProps {
  userUrl: UserUrl;
}

export const AnniversaryCard = React.memo(function AnniversaryCard(props: AnniversaryCardProps) {
  const {userUrl} = props;
  const intl = useIntl();

  const toDay = dateToString(new Date());

  const userUserProfileLookup = useSelector(getUserUserProfileLookup);
  const anniversaryTypeArray = useSelector(getAnniversaryTypeArray);

  const userProfile = userUserProfileLookup(userUrl);

  const birthDateString = userProfile?.birthDate;
  const employmentDateString = userProfile?.employmentDate;

  const anniversaries = useMemo(() => {
    const birthDate = birthDateString ? dateFromString(birthDateString) : null;
    const employmentDate = employmentDateString ? dateFromString(employmentDateString) : null;
    const todayDate = dateFromString(toDay) as Date;
    const sortedAnniversaryTypes = _.sortBy(
      anniversaryTypeArray,
      (anniversaryType) => anniversaryType.label,
    );
    return sortedAnniversaryTypes
      .map((anniversaryType) => {
        if (anniversaryType.countFrom === "BIRTH_DATE" && birthDate) {
          const nextOccurence = getNextAnniversaryDate(anniversaryType, birthDate, todayDate);
          return {anniversaryType, nextOccurence};
        } else if (anniversaryType.countFrom === "EMPLOYMENT_DATE" && employmentDate) {
          const nextOccurence = getNextAnniversaryDate(anniversaryType, employmentDate, todayDate);
          return {anniversaryType, nextOccurence};
        } else {
          return null;
        }
      })
      .filter(notNull);
  }, [anniversaryTypeArray, birthDateString, employmentDateString, toDay]);

  return (
    <Card>
      <CardHeader title={intl.formatMessage({defaultMessage: "Mærkedage"})} />
      <List>
        {anniversaries.map(({anniversaryType, nextOccurence}) => (
          <ListItem key={anniversaryType.url}>
            <ListItemText
              primary={
                <span>
                  <span
                    style={{
                      position: "relative",
                      top: 6,
                    }}
                  >
                    {getAnniversaryIcon(anniversaryType.icon)}
                  </span>
                  {anniversaryType.label}
                </span>
              }
              secondary={formatDateNumeric(nextOccurence)}
            />
          </ListItem>
        ))}
      </List>
    </Card>
  );
});
