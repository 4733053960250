import {
  MachineUse,
  MachineUseLog,
  Patch,
  PriceGroupUrl,
  Task,
  UserUrl,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import _ from "lodash";
import {ProvisionaryCommand} from "../../resources/actions";
import {
  getCustomerSettings,
  getMachineLookup,
  getMachineUseLogArray,
} from "../../resources/selectors";
import {ResourcesState} from "../../resources/types";
import {ResourcesAuthenticationMiddlewareAPI} from "../types";

function findMachineUseLog(
  state: {resources: ResourcesState},
  machineOperator: UserUrl,
  workType: WorkTypeUrl,
  priceGroup: PriceGroupUrl | null,
): MachineUseLog | undefined {
  const machineUseLogArray = getMachineUseLogArray(state);
  const matchingLogEntries = machineUseLogArray.filter(
    (m) => m.user === machineOperator && m.worktype === workType && m.priceGroup === priceGroup,
  );
  if (!matchingLogEntries.length) {
    return undefined;
  }
  return _.last(_.sortBy(matchingLogEntries, (m) => m.lastChanged));
}

function getMachineuseSet(
  state: {resources: ResourcesState},
  machineOperator: UserUrl,
  workType: WorkTypeUrl,
  priceGroup: PriceGroupUrl | null,
): MachineUse[] {
  const machineUseLog = findMachineUseLog(state, machineOperator, workType, priceGroup);
  if (machineUseLog) {
    const machineLookup = getMachineLookup(state);
    return machineUseLog.machines
      .filter((machineURL) => {
        const machine = machineLookup(machineURL);
        return machine && machine.active && !machine.smallMachine;
      })
      .map(
        (machineURL): MachineUse => ({
          machine: machineURL,
          priceGroup: null,
          transporter: false,
        }),
      );
  } else {
    return [];
  }
}

export function loadMachinesFromMachineUseLog(
  newTask: Task | null,
  oldTask: Task | undefined,
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  command: ProvisionaryCommand,
): Patch<Task> | null {
  if (!newTask) {
    return null;
  }
  const {machineOperator, priceGroup, workType} = newTask;

  if (
    newTask.recordedInC5 ||
    newTask.archivable ||
    !workType ||
    !machineOperator ||
    ((!newTask.workType || newTask.workType === oldTask?.workType) &&
      (!newTask.priceGroup || newTask.priceGroup === oldTask?.priceGroup) &&
      (!newTask.machineOperator || newTask.machineOperator === oldTask?.machineOperator))
  ) {
    // change needs to lead to combination of workType and machineOperator being set
    // only triggered on changes that set workType, priceGroup or machineOperator
    return null;
  }

  if (
    (newTask.machineuseSet && newTask.machineuseSet.length) ||
    (command.action === "UPDATE" && !_.isEqual(newTask.machineuseSet, oldTask?.machineuseSet))
  ) {
    // keep whatever machines are already present when any,
    // don't try to auto-compute machineuseSet while explicitly setting blank in UPDATE
    // (... do compute if setting it blank on initial create where omitting it is illegal)
    return null;
  }

  const state = middlewareApi.getState();
  const customerSettings = getCustomerSettings(state);
  if (
    !customerSettings.customerTaskAutoWorkTypePreviousMachines &&
    !customerSettings.internalTaskAutoWorkTypePreviousMachines
  ) {
    // feature is not turned on
    return null;
  }

  if (
    newTask.order
      ? !customerSettings.customerTaskAutoWorkTypePreviousMachines
      : !customerSettings.internalTaskAutoWorkTypePreviousMachines
  ) {
    // feature is not turned on for this type of task
    return null;
  }
  const machineuseSet = getMachineuseSet(state, machineOperator, workType, priceGroup);
  return [{member: "machineuseSet", value: machineuseSet}];
}
