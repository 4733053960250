import {ReportingLog} from "@co-common-libs/resources";

export function computeLoads(reportingLog: ReportingLog): Map<string, number> {
  const loads = new Map<string, number>();

  for (const entry of Object.values(reportingLog)) {
    if (entry.type !== "delivery" && entry.type !== "workplace") {
      continue;
    }
    Object.values(entry.priceItemUses || []).forEach((priceItemUse) => {
      if (priceItemUse.count) {
        loads.set(priceItemUse.priceItem, (loads.get(priceItemUse.priceItem) || 0) + 1);
      }
    });
    Object.values(entry.productUses || []).forEach((productUse) => {
      if (productUse.count) {
        loads.set(productUse.product, (loads.get(productUse.product) || 0) + 1);
      }
    });
  }
  return loads;
}
