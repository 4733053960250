import {SECOND_MILLISECONDS} from "@co-common-libs/utils";
import * as actions from "../resources/actions";
import {ResourcesAuthenticationMiddlewareAPI} from "./types";

const SAVE_LOCALLY_DEBOUNCE_TIMEOUT_SECONDS = 0.2;
const SAVE_LOCALLY_DEBOUNCE_TIMEOUT = SAVE_LOCALLY_DEBOUNCE_TIMEOUT_SECONDS * SECOND_MILLISECONDS;

let saveTimeout: {readonly id: number; readonly timeout: number} | null = null;

function clearSaveTimeout(): void {
  if (saveTimeout) {
    window.clearTimeout(saveTimeout.timeout);
    saveTimeout = null;
  }
}

function setSaveTimeout(id: number, middlewareApi: ResourcesAuthenticationMiddlewareAPI): void {
  clearSaveTimeout();
  const timeout = window.setTimeout(() => {
    if (saveTimeout && saveTimeout.id === id) {
      clearSaveTimeout();
      startLocalSave(middlewareApi, false);
    }
  }, SAVE_LOCALLY_DEBOUNCE_TIMEOUT);
  saveTimeout = {id, timeout};
}

/*
  Updates may be merged into last entry if it's also an update.

  * If first unsaved is last element and this is triggered by update;
    debounce/delay actually saving.
  * If first unsaved is last element and this is triggered otherwise,
    but we still have a pending timeout from that, continue waiting.
  * Otherwise, save immediately -- and expect this to be triggered again
    after success.
*/
export function startLocalSave(
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  fromUpdate: boolean,
): void {
  const {commitQueue} = middlewareApi.getState().resources;
  if (!commitQueue.length || commitQueue.some(({status}) => status === "SAVING_LOCALLY")) {
    return;
  }
  const candidateIndex = commitQueue.findIndex(({status}) => status === "UNSAVED");
  if (candidateIndex < 0) {
    return;
  }

  const candidate = commitQueue[candidateIndex];

  if (!candidate.id) {
    // ID === 0; commit queue entry from before loadCommit.fulfilled;
    // all commit queue entries should be in that state...
    console.assert(
      commitQueue.every(
        (entry) => entry.id === 0 && (entry.status === "UNSAVED" || entry.status === "PROMISE"),
      ),
    );
    // delay DB save until loadCommit.fulfilled, when we get real IDs
    return;
  }

  const candidateIsLastEntry = candidateIndex === commitQueue.length - 1;

  if (candidateIsLastEntry && fromUpdate) {
    // debounce/set new timeout
    setSaveTimeout(candidate.id, middlewareApi);
    return;
  } else if (saveTimeout && saveTimeout.id === candidate.id) {
    // called before timeout
    return;
  }

  if (candidate.status === "UNSAVED") {
    const {command, id} = candidate;
    middlewareApi.dispatch(actions.saveLocally({command, id}));
  }
}
