import {useCallWithConstant} from "@co-frontend-libs/utils";
import {DialogContent} from "@material-ui/core";
import React from "react";
import {defineMessages, useIntl} from "react-intl";
import {ResponsiveDialog} from "./responsive-dialog";

const messages = defineMessages({
  cancel: {
    defaultMessage: "Fortryd",
    id: "dialog.label.cancel",
  },
  confirmDelete: {
    defaultMessage: "Slet",
    id: "dialog.label.confirm-delete",
  },
  deleteQuestion: {
    defaultMessage: "Slet?",
    id: "dialog.dialog-title.delete",
  },
});

export interface DeleteDialogProps {
  cancelLabel?: JSX.Element | string;
  children?: React.ReactNode;
  fullscreen?: boolean;
  okLabel?: JSX.Element | string;
  onCancel: () => void;
  onOk: (ok: boolean) => void;
  open: boolean;
  title?: JSX.Element | string;
}

export const DeleteDialog = React.memo(function DeleteDialog(
  props: DeleteDialogProps,
): JSX.Element {
  const intl = useIntl();
  const {cancelLabel, children, fullscreen, okLabel, onCancel, onOk, open, title} = props;

  const handleOk = useCallWithConstant(onOk, true);

  return (
    <ResponsiveDialog
      autoFocusCancel
      cancelLabel={cancelLabel}
      fullscreen={fullscreen}
      okLabel={okLabel || intl.formatMessage(messages.confirmDelete)}
      open={open}
      title={title || intl.formatMessage(messages.deleteQuestion)}
      onCancel={onCancel}
      onOk={handleOk}
    >
      <DialogContent>{children}</DialogContent>
    </ResponsiveDialog>
  );
});
