import {
  CustomerUrl,
  Location,
  LocationUrl,
  ReportingLocation,
  ReportingSpecification,
  ReportingWorkplaceInputSpecification,
} from "@co-common-libs/resources";
import {getLocationLookup} from "@co-frontend-libs/redux";
import {useFalseCallback} from "app-utils";
import _ from "lodash";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {FieldSingleSelectionDialog} from "../customer-field-dialog";
import {BaseFieldMultiSelectionDialog} from "../customer-field-dialog/multi-field-dialog/base-field-multi-selection-dialog";
import {EditReportingLocation, ValuesState} from "./edit-reporting-location-dialog";

function getLogDataFromLocationObject(
  inputSpecifications: readonly ReportingWorkplaceInputSpecification[],
  location: Location,
): Record<string, unknown> {
  const result: Record<string, unknown> = {};
  inputSpecifications.forEach((inputSpecification) => {
    if (inputSpecification.fieldAreaTarget) {
      const decimals = 2;
      if (location?.fieldAreaHa) {
        const value = _.floor(location.fieldAreaHa || 0, decimals);
        result[inputSpecification.identifier] = value;
      } else {
        if (Object.prototype.hasOwnProperty.call(result, inputSpecification.identifier)) {
          delete result[inputSpecification.identifier];
        }
      }
    } else if (inputSpecification.fieldCropTarget) {
      result[inputSpecification.identifier] = location?.fieldCrop;
    }
  });
  return result;
}

const noInputSpecifications: readonly ReportingWorkplaceInputSpecification[] = [];

interface FieldWithLogDataDialogProps {
  customerUrl: CustomerUrl | null;
  lastUsedLocations: ReadonlySet<LocationUrl> | undefined;
  logSpecification: ReportingSpecification | undefined;
  onCancel: () => void;
  onOk: (locationUrl: LocationUrl, data: ValuesState) => void;
  open: boolean;
  type: ReportingLocation["type"];
}

export const FieldWithLogDataDialog = React.memo(function FieldWithLogDataDialog(
  props: FieldWithLogDataDialogProps,
): JSX.Element {
  const {customerUrl, lastUsedLocations, logSpecification, onCancel, onOk, open, type} = props;
  const locationLookup = useSelector(getLocationLookup);

  const inputSpecifications =
    logSpecification?.workplaceData[type]?.inputs || noInputSpecifications;

  const [dataDialogLocation, setDataDialogLocation] = useState<LocationUrl>();
  const [dataDialogOpen, setDataDialogOpen] = useState(false);
  const [dataDialogData, setDataDialogData] = useState<ValuesState>({});

  const setDataDialogOpenFalse = useFalseCallback(setDataDialogOpen, [setDataDialogOpen]);

  const handleSelect = useCallback(
    (locationUrl: LocationUrl): void => {
      const location = locationLookup(locationUrl);

      const fieldWorkData =
        location && inputSpecifications
          ? getLogDataFromLocationObject(inputSpecifications, location)
          : {};

      const hasUnfilledRequiredInputs = inputSpecifications?.some(
        (input) => input.required && fieldWorkData[input.identifier] === undefined,
      );
      if (hasUnfilledRequiredInputs) {
        setDataDialogLocation(locationUrl);
        setDataDialogData(fieldWorkData);
        setDataDialogOpen(true);
      } else {
        onOk(locationUrl, fieldWorkData);
      }
    },
    [locationLookup, inputSpecifications, onOk],
  );

  const handleDataOk = useCallback(
    (data: ValuesState): void => {
      setDataDialogOpen(false);
      if (dataDialogLocation) {
        onOk(dataDialogLocation, data);
      }
    },
    [dataDialogLocation, onOk],
  );

  return (
    <>
      <FieldSingleSelectionDialog
        customerURL={customerUrl || undefined}
        lastUsedLocations={lastUsedLocations}
        open={open}
        onCancel={onCancel}
        onOk={handleSelect}
      />
      {logSpecification && dataDialogLocation ? (
        <EditReportingLocation
          currentData={dataDialogData}
          inputSpecifications={inputSpecifications}
          locationUrl={dataDialogLocation}
          logSpecification={logSpecification}
          open={dataDialogOpen}
          type={type}
          onCancel={setDataDialogOpenFalse}
          onOk={handleDataOk}
        />
      ) : null}
    </>
  );
});

interface MultiFieldWithLogDataDialogProps {
  customerUrl: CustomerUrl | null;
  lastUsedLocations: ReadonlySet<LocationUrl> | undefined;
  locationDataMap: ReadonlyMap<LocationUrl, ValuesState>;
  logSpecification: ReportingSpecification | undefined;
  multiSelectMax?: number;
  onCancel: () => void;
  onOk: (data: ReadonlyMap<LocationUrl, ValuesState>) => void;
  open: boolean;
  readonlySet?: ReadonlySet<LocationUrl> | undefined;
  type: ReportingLocation["type"];
}

export const MultiFieldWithLogDataDialog = React.memo(function MultiFieldWithLogDataDialog(
  props: MultiFieldWithLogDataDialogProps,
): JSX.Element {
  const {
    customerUrl,
    lastUsedLocations,
    locationDataMap: locationDataMapFromProps,
    logSpecification,
    multiSelectMax,
    onCancel,
    onOk,
    open,
    readonlySet,
    type,
  } = props;
  const locationLookup = useSelector(getLocationLookup);

  const inputSpecifications =
    logSpecification?.workplaceData[type]?.inputs || noInputSpecifications;

  const [dataDialogLocation, setDataDialogLocation] = useState<LocationUrl>();
  const [dataDialogOpen, setDataDialogOpen] = useState(false);
  const [dataDialogData, setDataDialogData] = useState<ValuesState>({});

  const setDataDialogOpenFalse = useFalseCallback(setDataDialogOpen, [setDataDialogOpen]);

  const [locationDataMap, setLocationDataMap] =
    useState<ReadonlyMap<LocationUrl, ValuesState>>(locationDataMapFromProps);

  useEffect(() => {
    if (open) {
      setLocationDataMap(locationDataMapFromProps);
    }
  }, [locationDataMapFromProps, open]);

  const setLocationData = useCallback(
    (location: LocationUrl, data: ValuesState): void => {
      const changed = new Map(locationDataMap);
      changed.set(location, data);
      setLocationDataMap(changed);
    },
    [locationDataMap],
  );

  const removeLocationData = useCallback(
    (location: LocationUrl): void => {
      const changed = new Map(locationDataMap);
      changed.delete(location);
      setLocationDataMap(changed);
    },
    [locationDataMap],
  );

  const handleOk = useCallback((): void => {
    onOk(locationDataMap);
  }, [locationDataMap, onOk]);

  const selectedLocations = useMemo(
    (): ReadonlySet<LocationUrl> => new Set(locationDataMap.keys()),
    [locationDataMap],
  );

  const handleSelect = useCallback(
    (locationUrl: LocationUrl, isSelected: boolean): void => {
      if (!isSelected) {
        removeLocationData(locationUrl);
      } else {
        const location = locationLookup(locationUrl);

        const fieldWorkData =
          location && inputSpecifications
            ? getLogDataFromLocationObject(inputSpecifications, location)
            : {};

        const hasUnfilledRequiredInputs = inputSpecifications?.some(
          (input) => input.required && fieldWorkData[input.identifier] === undefined,
        );
        if (hasUnfilledRequiredInputs) {
          setDataDialogLocation(locationUrl);
          setDataDialogData(fieldWorkData);
          setDataDialogOpen(true);
        } else {
          setLocationData(locationUrl, fieldWorkData);
        }
      }
    },
    [inputSpecifications, locationLookup, removeLocationData, setLocationData],
  );

  const handleSelectMultiple = useCallback(
    (identifiers: ReadonlySet<LocationUrl>, isSelected: boolean): void => {
      const changedLocationDataMap = new Map(locationDataMap);
      if (isSelected) {
        identifiers.forEach((locationUrl) => {
          if (!changedLocationDataMap.has(locationUrl)) {
            const location = locationLookup(locationUrl);

            const fieldWorkData =
              location && inputSpecifications
                ? getLogDataFromLocationObject(inputSpecifications, location)
                : {};
            changedLocationDataMap.set(locationUrl, fieldWorkData);
          }
        });
      } else {
        identifiers.forEach((locationUrl) => {
          changedLocationDataMap.delete(locationUrl);
        });
      }
      setLocationDataMap(changedLocationDataMap);
    },
    [inputSpecifications, locationDataMap, locationLookup],
  );

  const handleDataOk = useCallback(
    (data: ValuesState): void => {
      setDataDialogOpen(false);
      if (dataDialogLocation) {
        setLocationData(dataDialogLocation, data);
      }
    },
    [dataDialogLocation, setLocationData],
  );

  const handleToggleSelected = useCallback(
    (locationUrl: LocationUrl): void => {
      const wasSelected = locationDataMap.has(locationUrl);
      handleSelect(locationUrl, !wasSelected);
    },
    [handleSelect, locationDataMap],
  );

  return (
    <>
      <BaseFieldMultiSelectionDialog
        customerURL={customerUrl}
        lastUsedLocations={lastUsedLocations}
        multiSelectMax={multiSelectMax}
        open={open}
        readonlySet={readonlySet}
        selected={selectedLocations}
        onCancel={onCancel}
        onOk={handleOk}
        onSelect={handleSelect}
        onSelectMultiple={handleSelectMultiple}
        onToggleSelected={handleToggleSelected}
      />
      {logSpecification && dataDialogLocation ? (
        <EditReportingLocation
          currentData={dataDialogData}
          inputSpecifications={inputSpecifications}
          locationUrl={dataDialogLocation}
          logSpecification={logSpecification}
          open={dataDialogOpen}
          type={type}
          onCancel={setDataDialogOpenFalse}
          onOk={handleDataOk}
        />
      ) : null}
    </>
  );
});
