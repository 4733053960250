import {Locale} from "date-fns";
import daLocale from "date-fns/locale/da";

function buildFormatLongFn(args: any) {
  return function (dirtyOptions: any) {
    const options = dirtyOptions || {};
    const width = options.width ? String(options.width) : args.defaultWidth;
    const format = args.formats[width] || args.formats[args.defaultWidth];
    return format;
  };
}

const dateFormats = {
  full: "EEEE 'den' d. MMMM y",
  long: "d. MMMM y",
  medium: "d. MMM y",
  short: "dd.MM.y",
};

const timeFormats = {
  full: "HH.mm.ss zzzz",
  long: "HH.mm.ss z",
  medium: "HH.mm.ss",
  short: "HH.mm",
};

const dateTimeFormats = {
  full: "{{date}} 'kl'. {{time}}",
  long: "{{date}} 'kl'. {{time}}",
  medium: "{{date}} {{time}}",
  short: "{{date}} {{time}}",
};

const formatLong = {
  date: buildFormatLongFn({
    defaultWidth: "full",
    formats: dateFormats,
  }),

  dateTime: buildFormatLongFn({
    defaultWidth: "full",
    formats: dateTimeFormats,
  }),

  time: buildFormatLongFn({
    defaultWidth: "full",
    formats: timeFormats,
  }),
};

export const dateFnsLocale: Locale = {...daLocale, formatLong};
