import {Config} from "@co-common-libs/config";
import {Machine, MachineUrl} from "@co-common-libs/resources";
import {ConnectedMachineDialog} from "@co-frontend-libs/connected-components";
import {
  AppState,
  actions,
  getCustomerSettings,
  getDeviceConfigKey,
  getMachineLookup,
} from "@co-frontend-libs/redux";
import {Button, Card, CardContent, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import {MenuToolbar, PageLayout} from "app-components";
import {promiseChain} from "app-utils";
import {bind} from "bind-decorator";
import React from "react";
// Allowed for existing code...
// eslint-disable-next-line deprecate/import
import {Cell, Grid} from "react-flexr";
import {FormattedMessage, IntlContext, defineMessages} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";

const messages = defineMessages({
  checkout: {
    defaultMessage: "Check ud",
    id: "device-setup.label.checkout",
  },
  machine: {
    defaultMessage: "Monteret i maskine",
    id: "device-setup.label.machine-mounted",
  },
  normal: {
    defaultMessage: "Almindelig",
    id: "device-setup.label.normal",
  },
  saveAndLogout: {
    defaultMessage: "Gem og log ud",
    id: "device-setup.label.save-and-logout",
  },
  selectMachine: {
    defaultMessage: "Vælg maskine",
    id: "device-setup.label.select-machine",
  },
  selectVehicle: {
    defaultMessage: "Vælg vehicle",
    id: "device-setup.label.select-vehicle",
  },
  textFields: {
    defaultMessage: "Indtastningsfelter",
    id: "device-setup.label.text-fields",
  },
  title: {
    defaultMessage: "Opsætning af enhed",
    id: "device-setup.title.setup",
  },
  userList: {
    defaultMessage: "Brugerliste",
    id: "device-setup.label.user-list",
  },
  vehicle: {
    defaultMessage: "Monteret i køretøj",
    id: "device-setup.label.vehicle-mounted",
  },
});

interface DeviceSetupStateProps {
  customerSettings: Config;
  machine?: MachineUrl;
  machineLookup: (url: MachineUrl) => Machine | undefined;
  useMode?: "machine" | "normal";
}

interface DeviceSetupDispatchProps {
  configPut: (data: {key: string; value: any}) => void;
  logout: (explicit: boolean) => void;
}

interface DeviceSetupOwnProps {
  onMenuButton: (event: React.MouseEvent) => void;
}

type DeviceSetupProps = DeviceSetupDispatchProps & DeviceSetupOwnProps & DeviceSetupStateProps;

interface DeviceSetupState {
  machine: MachineUrl | null;
  machineDialogOpen: boolean;
  useMode: "machine" | "normal";
}

class DeviceSetup extends React.Component<DeviceSetupProps, DeviceSetupState> {
  state: DeviceSetupState = {
    machine: this.props.machine || null,
    machineDialogOpen: false,
    useMode: this.props.useMode || "normal",
  };
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  @bind
  handleMachineSelectButton(): void {
    this.setState({machineDialogOpen: true});
  }
  @bind
  handleMachineDialogOk(url: MachineUrl): void {
    this.setState({
      machine: url,
      machineDialogOpen: false,
    });
  }
  @bind
  handleMachineDialogCancel(): void {
    this.setState({machineDialogOpen: false});
  }
  @bind
  handleUseModeChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const {value} = event.target;
    console.assert(value === "normal" || value === "machine");
    if (value === "machine") {
      this.setState({useMode: value});
    } else {
      this.setState({
        machine: null,
        useMode: value as "machine" | "normal",
      });
    }
  }
  @bind
  handleSaveAndLogout(): void {
    const {machine, useMode} = this.state;
    const saveLogoutActions = [
      () => this.props.configPut({key: "useMode", value: useMode}),
      () => this.props.configPut({key: "machine", value: machine}),
      () => this.props.logout(true),
    ];
    promiseChain(saveLogoutActions);
  }
  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {customerSettings} = this.props;
    let machineBlock;
    if (this.state.machine) {
      const machine = this.props.machineLookup(this.state.machine);
      if (machine) {
        const machineName = machine.name;
        const machineID = machine.c5_machine;
        machineBlock = <div>{machineName + (machineID ? ` (${machineID})` : "")}</div>;
      }
    }
    return (
      <PageLayout
        withPadding
        dialogs={
          <ConnectedMachineDialog
            open={this.state.machineDialogOpen}
            onCancel={this.handleMachineDialogCancel}
            onOk={this.handleMachineDialogOk}
          />
        }
        toolbar={
          <MenuToolbar
            title={formatMessage(messages.title)}
            onMenuButton={this.props.onMenuButton}
          />
        }
      >
        <Grid>
          <Cell>
            <Card style={{width: "100%"}}>
              <CardContent>
                <FormattedMessage
                  defaultMessage="Anvendelse"
                  id="device-setup.header.use-mode"
                  tagName="h2"
                />
                <RadioGroup
                  name="useMode"
                  value={this.state.useMode}
                  onChange={this.handleUseModeChange}
                >
                  <FormControlLabel
                    control={<Radio />}
                    label={formatMessage(messages.normal)}
                    value="normal"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label={
                      customerSettings.machineLabelVariant === "MACHINE"
                        ? formatMessage(messages.machine)
                        : formatMessage(messages.vehicle)
                    }
                    value="machine"
                  />
                </RadioGroup>
                <Button
                  color="secondary"
                  disabled={this.state.useMode !== "machine"}
                  variant="contained"
                  onClick={this.handleMachineSelectButton}
                >
                  {customerSettings.machineLabelVariant === "MACHINE"
                    ? formatMessage(messages.selectMachine)
                    : formatMessage(messages.selectVehicle)}
                </Button>
                {machineBlock}
              </CardContent>
            </Card>
          </Cell>
        </Grid>
        <Grid>
          <Cell>
            <Button color="secondary" variant="contained" onClick={this.handleSaveAndLogout}>
              {formatMessage(messages.saveAndLogout)}
            </Button>
          </Cell>
        </Grid>
      </PageLayout>
    );
  }
}

const ConnectedDeviceSetup: React.ComponentType<DeviceSetupOwnProps> = connect<
  DeviceSetupStateProps,
  DeviceSetupDispatchProps,
  DeviceSetupOwnProps,
  AppState
>(
  createStructuredSelector<AppState, DeviceSetupStateProps>({
    customerSettings: getCustomerSettings,
    machine: getDeviceConfigKey("machine"),
    machineLookup: getMachineLookup,
    useMode: getDeviceConfigKey("useMode"),
  }),
  {
    configPut: actions.configPut,
    logout: actions.logout,
  },
)(DeviceSetup);

export default ConnectedDeviceSetup;
