import {
  ContactUrl,
  CultureUrl,
  CustomerUrl,
  LocationUrl,
  OrderUrl,
  ProjectUrl,
  RoutePlanUrl,
  UserUrl,
} from "../resource-urls";
import {ResourceInstance} from "./base";

export interface OrderFieldUse {
  readonly fieldAreaHa: number | null;
  readonly fieldCrop: string;
  readonly geojson: any | null;
  readonly notes: string;
  readonly relatedField: LocationUrl;
}

export interface Order extends ResourceInstance<OrderUrl> {
  readonly address: string;
  readonly billed: boolean;
  readonly contact: ContactUrl | null;
  readonly created?: string;
  readonly createdBy?: UserUrl | null;
  readonly culture: CultureUrl | null;
  readonly customer: CustomerUrl | null;
  readonly date: string | null;
  readonly department: string;
  readonly draft: boolean;
  readonly durationDays: number | null;
  readonly earliestDate: string | null;
  readonly expectedCubicMeters: number | null;
  readonly expectedHectares: number | null;
  readonly expectedTonne: number | null;
  readonly fieldIdentifier: string;
  readonly hasTasks?: boolean;
  readonly latestDate: string | null;
  readonly managerInternalNotes: string;
  readonly notes: string;
  readonly orderfielduseSet: readonly OrderFieldUse[];
  readonly priority: number | null;
  readonly project: ProjectUrl | null;
  readonly referenceNumber: string;
  readonly relatedWorkplace: LocationUrl | null;
  readonly remoteUrl: string;
  readonly routePlan: RoutePlanUrl | null;
  readonly time: string | null;
  readonly validatedAndRecorded: boolean;
}

export const emptyOrder: Omit<Order, "createdBy" | "id" | "url"> = {
  address: "",
  billed: false,
  contact: null,
  culture: null,
  customer: null,
  date: null,
  department: "",
  draft: false,
  durationDays: 1,
  earliestDate: null,
  expectedCubicMeters: null,
  expectedHectares: null,
  expectedTonne: null,
  fieldIdentifier: "",
  latestDate: null,
  managerInternalNotes: "",
  notes: "",
  orderfielduseSet: [],
  priority: null,
  project: null,
  referenceNumber: "",
  relatedWorkplace: null,
  remoteUrl: "",
  routePlan: null,
  time: null,
  validatedAndRecorded: false,
};
