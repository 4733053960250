import {Config} from "@co-common-libs/config";
import {Customer, Order, OrderUrl, Task, WorkType, urlToId} from "@co-common-libs/resources";
import {getWorkTypeLookup} from "@co-frontend-libs/redux";
import {colorMap, matchingTextColor} from "@co-frontend-libs/utils";
import {SvgIcon} from "@material-ui/core";
import {grey} from "@material-ui/core/colors";
import _ from "lodash";
import PencilIcon from "mdi-react/PencilIcon";
import React, {useCallback} from "react";
import {ConnectDragSource, DragSource} from "react-dnd";
import {useSelector} from "react-redux";
import {ORDER_CALENDAR_COLUMN_WIDTH, PARTIALLY_PLANNED_ORDER_HEIGHT} from "./constants";

const MARGIN = 4;
const TASKS_PER_COLUMN = 2;
const BORDER_WIDTH = 1;

interface UnplannedOrderBlockOwnProps {
  customer?: Customer | undefined;
  customerSettings: Config;
  dndMode: boolean;
  onEditTaskClick?: ((orderUrl: OrderUrl, taskId: string) => void) | undefined;
  onGoToOrder: (orderId: string) => void;
  onGoToTask?: (taskId: string) => void;
  order: Order;
  task?: Task;
  taskList: readonly Task[] | undefined;
}

interface UnplannedOrderBlockDragSourceProps {
  connectDragSource: ConnectDragSource;
  isDragging: boolean;
}

type UnplannedOrderBlockProps = UnplannedOrderBlockDragSourceProps & UnplannedOrderBlockOwnProps;

const UnplannedOrderBlock = React.memo(function UnplannedOrderBlock(
  props: UnplannedOrderBlockProps,
): JSX.Element {
  const {
    connectDragSource,
    customer,
    customerSettings,
    isDragging,
    onEditTaskClick,
    onGoToOrder,
    onGoToTask,
    order,
    task,
    taskList,
  } = props;

  const handleGoto = useCallback(() => {
    if (customerSettings.orderCalendarAsTaskCalendar) {
      if (!task || !onGoToTask) {
        return;
      }
      onGoToTask(urlToId(task.url));
    } else {
      onGoToOrder(urlToId(order.url));
    }
  }, [customerSettings.orderCalendarAsTaskCalendar, onGoToOrder, onGoToTask, order.url, task]);
  const handleEditTaskClick = useCallback(() => {
    if (!onEditTaskClick || !task) {
      return;
    }
    onEditTaskClick(order.url, urlToId(task.url));
  }, [onEditTaskClick, order.url, task]);

  const workTypeLookup = useSelector(getWorkTypeLookup);
  let workType: Readonly<WorkType> | undefined;
  if (task?.workType) {
    workType = workTypeLookup(task.workType);
  } else if (!customerSettings.noExternalTaskWorkType && taskList) {
    const oldestTaskWithWorkType = _.minBy(
      taskList.filter((t) => t.workType),
      (t) => t.created,
    );
    if (oldestTaskWithWorkType?.workType) {
      workType = workTypeLookup(oldestTaskWithWorkType.workType);
    }
  }

  const backgroundColor = (workType && workType.color) || grey[700];
  const color = matchingTextColor(backgroundColor);
  let workTypeStr = null;
  if (workType) {
    workTypeStr = `${workType.identifier}: ${workType.name}`;
  }
  let customerStr = null;
  if (customer) {
    customerStr = `, ${customer.name}`;
  }
  let editIcon: JSX.Element | null = null;
  if (onEditTaskClick) {
    editIcon = (
      <SvgIcon
        style={{
          cursor: "pointer",
          float: "right",
          height: 13,
          marginTop: 3,
          width: 13,
        }}
        onClick={handleEditTaskClick}
      >
        <PencilIcon />
      </SvgIcon>
    );
  }
  const style: React.CSSProperties = {
    backgroundColor,
    borderColor: color,
    borderStyle: "dashed",
    borderWidth: "1px",
    color,
    display: "inline-block",
    height: PARTIALLY_PLANNED_ORDER_HEIGHT,
    marginLeft: MARGIN,
    maxWidth: "100%",

    opacity: isDragging ? 0.2 : 1,
    overflow: "hidden",
    padding: "4px 4px 0px 4px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    // Wider when showing editicon/button
    width: editIcon
      ? ORDER_CALENDAR_COLUMN_WIDTH - (MARGIN + MARGIN)
      : (ORDER_CALENDAR_COLUMN_WIDTH - (MARGIN + MARGIN + BORDER_WIDTH + BORDER_WIDTH)) /
        TASKS_PER_COLUMN,
  };
  if (!editIcon) {
    style.cursor = "pointer";
  }
  const workTypeStyle = {
    color: workTypeStr ? color : colorMap.ERROR,
    cursor: "pointer",
  };
  const customerStyle = {
    color: customerStr ? color : colorMap.ERROR,
    cursor: "pointer",
  };
  const outerOptionalClickHandlerProps: React.HTMLAttributes<HTMLDivElement> = {};
  const innerOptionalClickHandlerProps: React.HTMLAttributes<HTMLSpanElement> = {};
  if (editIcon) {
    innerOptionalClickHandlerProps.onClick = handleGoto;
  } else {
    outerOptionalClickHandlerProps.onClick = handleGoto;
  }
  return connectDragSource(
    <div style={style} {...outerOptionalClickHandlerProps}>
      <span style={workTypeStyle} {...innerOptionalClickHandlerProps}>
        {workTypeStr || "???"}
      </span>
      <span style={customerStyle} {...innerOptionalClickHandlerProps}>
        {customerStr || "???"}
      </span>
      {editIcon}
    </div>,
  ) as JSX.Element;
});

const orderSource = {
  beginDrag(props: UnplannedOrderBlockOwnProps) {
    return {
      fromDate: null,
      orderUrl: props.order.url,
      taskUrl: props.task?.url,
    };
  },
  canDrag(props: UnplannedOrderBlockOwnProps) {
    return props.dndMode;
  },
};

const DragSourceUnplannedOrderBlock = DragSource("order", orderSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
}))(UnplannedOrderBlock);

export {DragSourceUnplannedOrderBlock as UnplannedOrderBlock};
