import {useEffect, useMemo} from "react";
import {SimpleObjectReducerDispatch} from "./types";

export function useResetObjectOnCondition<T>(
  initialState: T,
  formDispatch: SimpleObjectReducerDispatch<T>,
  resetCondition: boolean,
): T;
export function useResetObjectOnCondition<I, S>(
  initialState: I,
  formDispatch: SimpleObjectReducerDispatch<S>,
  resetCondition: boolean,
  transform: (value: I) => S,
): S;
export function useResetObjectOnCondition<I, S>(
  initialState: I | S,
  formDispatch: SimpleObjectReducerDispatch<S>,
  resetCondition: boolean,
  transform?: (value: I) => S,
): S {
  const transformedInitialState = useMemo(
    () => (transform ? transform(initialState as I) : (initialState as S)),
    [initialState, transform],
  );

  useEffect(() => {
    if (resetCondition) {
      formDispatch({type: "reset", value: transformedInitialState});
    }
  }, [formDispatch, resetCondition, transform, transformedInitialState]);

  return transformedInitialState;
}
