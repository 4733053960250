import {ImportPreviewProductWithGroupMeta} from "@co-common-libs/resources-utils";
import {ImportProductsPreviewDialog} from "@co-frontend-libs/components";
import {jsonFetch, translateNetworkError} from "@co-frontend-libs/utils";
import {useAsyncAbortable} from "@react-hookz/web";
import {globalConfig} from "frontend-global-config";
import React, {useCallback, useEffect} from "react";
import {useIntl} from "react-intl";

interface ImportProductsDialogProps {
  filter?: ReadonlySet<string>;
  onAdd?(searchString: string): void;
  onCancel(): void;
  onOk(products: ImportPreviewProductWithGroupMeta[]): void;
  open: boolean;
}

export const ImportProductsDialog = React.memo(function ImportProductsDialog(
  props: ImportProductsDialogProps,
): React.JSX.Element {
  const {filter, open} = props;

  const {baseURL} = globalConfig.resources;
  const [state, actions] = useAsyncAbortable<readonly ImportPreviewProductWithGroupMeta[] | null>(
    (signal) =>
      jsonFetch(`${baseURL}economic/economic-products/`).then(({data}) => {
        if (signal.aborted) {
          return null;
        } else {
          return filter
            ? (data as ImportPreviewProductWithGroupMeta[]).filter(
                (entry) => !filter.has(entry.remoteUrl),
              )
            : data;
        }
      }),
    null,
  );

  useEffect(() => {
    if (open) {
      actions.execute();
    } else {
      actions.reset();
    }
  }, [actions, open]);

  const handleOk = useCallback(
    (identities: ReadonlySet<string>) => {
      props.onOk((state.result || []).filter((item) => identities.has(item.remoteUrl)));
    },
    [state.result, props],
  );

  const intl = useIntl();

  return (
    <ImportProductsPreviewDialog
      {...props}
      error={state.status === "error" ? translateNetworkError(state.error, intl) : undefined}
      previewProducts={state.result}
      onOk={handleOk}
    />
  );
});
