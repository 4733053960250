export const FAB_MARGIN = 23;
export const FAB_HEIGHT = 56;

const floatingActionPixelsBottom = (stackIndex: number): number => {
  const bottomMargin = (stackIndex + 1) * FAB_MARGIN;
  const bottomHeight = stackIndex * FAB_HEIGHT;

  return bottomHeight + bottomMargin;
};

export const getVerticalStackingFabstyle = (
  stackIndex: number,
  fabPosition?: "absolute" | "fixed",
): React.CSSProperties => ({
  bottom: floatingActionPixelsBottom(stackIndex),
  position: fabPosition || "fixed",
  right: FAB_MARGIN,
  zIndex: 1000,
});
