import {getCustomerSettings} from "@co-frontend-libs/redux";
import {MenuToolbar, PageLayout} from "app-components";
import React from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import StandardMachines from "./standard-machines";

interface FavoriteMachinesSettingsPageProps {
  onMenuButton: (event: React.MouseEvent) => void;
}

export const FavoriteMachinesSettingsPage = React.memo(function FavoriteMachinesSettingsPage(
  props: FavoriteMachinesSettingsPageProps,
): JSX.Element {
  const intl = useIntl();
  const customerSettings = useSelector(getCustomerSettings);

  const title =
    customerSettings.machineLabelVariant === "MACHINE"
      ? intl.formatMessage({defaultMessage: "Favoritmaskiner"})
      : intl.formatMessage({defaultMessage: "Favoritkøretøjer"});

  return (
    <PageLayout
      withBottomScrollPadding
      toolbar={<MenuToolbar title={title} onMenuButton={props.onMenuButton} />}
    >
      <StandardMachines />
    </PageLayout>
  );
});
