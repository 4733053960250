import {DialogContent} from "@material-ui/core";
import {bind} from "bind-decorator";
import React from "react";
import {IntlContext, defineMessages} from "react-intl";
import {ColorPicker} from "./color-picker";
import {ResponsiveDialog} from "./responsive-dialog";

const messages = defineMessages({
  select: {
    defaultMessage: "Vælg",
    id: "color-picker-dialog.select",
  },
  selectColor: {
    defaultMessage: "Vælg farve",
    id: "color-picker-dialog.dialog-title",
  },
});

export interface ColorPickerDialogProps {
  fullscreen?: boolean;
  initialSelected?: string | undefined;
  onCancel: () => void;
  onOk: (color: string) => void;
  open: boolean;
  required?: boolean | undefined;
}

export class ColorPickerDialog extends React.PureComponent<ColorPickerDialogProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  @bind
  handleColorSelected(selectedColor: string): void {
    this.props.onOk(selectedColor ?? "");
  }

  @bind
  handleCancel(): void {
    this.props.onCancel();
  }

  @bind
  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {fullscreen, open, required} = this.props;
    return (
      <ResponsiveDialog
        fullscreen={fullscreen}
        okLabel={formatMessage(messages.select)}
        open={open}
        title={formatMessage(messages.selectColor)}
        onCancel={this.handleCancel}
      >
        <DialogContent>
          <ColorPicker
            required={required}
            selectedColor={this.props.initialSelected}
            onColorClick={this.handleColorSelected}
          />
        </DialogContent>
      </ResponsiveDialog>
    );
  }
}

export default ColorPickerDialog;
