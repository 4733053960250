import {Config} from "@co-common-libs/config";
import {
  Customer,
  CustomerUrl,
  Location,
  LocationUrl,
  Machine,
  MachineUrl,
  Order,
  OrderUrl,
  Project,
  ProjectUrl,
  Role,
  Task,
  TimerStart,
  UserProfile,
  UserUrl,
  WorkType,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {getMachineString, getWorkTypeString} from "@co-common-libs/resources-utils";
import {
  caseAccentInsensitiveCollator,
  formatDate,
  formatDateShort,
  formatTime,
  identifierComparator,
  notNull,
  notUndefined,
} from "@co-common-libs/utils";
import {
  ColorBox,
  ColumnSpecifications,
  RowData,
  WorkStatusIcon,
  colorBoxColumnSpecification,
  iconColumnSpecification,
} from "@co-frontend-libs/components";
import {
  AppState,
  getCurrentRole,
  getCustomerLookup,
  getCustomerSettings,
  getLocationLookup,
  getMachineLookup,
  getOrderArray,
  getOrderLookup,
  getProjectLookup,
  getSortedTimerStartArray,
  getTaskArray,
  getUserUserProfileLookup,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {Checkbox} from "@material-ui/core";
import {
  computeTaskStatus,
  computeTime,
  getLocationString,
  getReferenceNumberLabel,
  resourceInstanceDateTimeComparator,
} from "app-utils";
import bowser from "bowser";
import _ from "lodash";
import memoize from "memoize-one";
import React from "react";
import {IntlContext, IntlShape} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import type {PickProperties} from "ts-essentials";
import {ConnectedTableWithPagination} from "./table-with-pagination/connected-table-with-pagination";
import {PaginationPageSize} from "./table-with-pagination/types";

type WorkStatus =
  | "active"
  | "completed"
  | "error"
  | "paused"
  | "pending"
  | "recorded"
  | "validated";

export type OrderTableFieldID =
  | "address"
  | "createdDate"
  | "createdDateRaw"
  | "createdDateShort"
  | "customer"
  | "customerAddress"
  | "customerAddressShort"
  | "date"
  | "dateRaw"
  | "dateShort"
  | "deliveryLogLocations"
  | "department"
  | "employees"
  | "machineName"
  | "machineNumber"
  | "machineNumberAndName"
  | "managerInternalNotes"
  | "merge"
  | "note"
  | "numericStatus"
  | "pickupLogLocations"
  | "projectNumbers"
  | "referenceNumber"
  | "status"
  | "time"
  | "workType"
  | "workTypeColor"
  | "workTypes";

export type OrderTableColumnID =
  | "address"
  | "createdDate"
  | "createdDateShort"
  | "customer"
  | "customerAddress"
  | "customerAddressShort"
  | "customerShort"
  | "date"
  | "dateShort"
  | "deliveryLogLocations"
  | "department"
  | "employees"
  | "machineName"
  | "machineNumber"
  | "machineNumberAndName"
  | "managerInternalNotes"
  | "merge"
  | "note"
  | "pickupLogLocations"
  | "projectNumbers"
  | "referenceNumber"
  | "status"
  | "time"
  | "workType"
  | "workTypeColor"
  | "workTypes"
  | "workTypeShort";

export interface OrderTableDataType extends RowData<OrderTableFieldID, OrderUrl> {
  address: string;
  createdDate: string;
  createdDateShort: string;
  customer: string;
  customerURL: string;
  date: string;
  dateRaw: string;
  dateShort: string;
  deliveryLogLocations: string;
  employees: string;
  machineName: string;
  machineNumber: string;
  machineNumberAndName: string;
  managerInternalNotes: string;
  note: string;
  numericStatus: number;
  orderURL: OrderUrl;
  pickupLogLocations: string;
  projectNumbers: string;
  referenceNumber: string;
  status: WorkStatus;
  time: string;
  validatedAndRecorded: boolean;
  workType: string;
  workTypeColor: string | null;
  workTypes: string;
}

function renderWorkStatus(data: OrderTableDataType): JSX.Element {
  return <WorkStatusIcon status={data.status} />;
}

function makeCheckboxRender(
  onCheckChanged: (url: OrderUrl, checked: boolean) => void,
  selectedOrders: readonly string[],
  checkedCustomer?: string,
): (data: OrderTableDataType) => JSX.Element | null {
  const changeHandlerMap = new Map<string, (event: React.ChangeEvent<HTMLInputElement>) => void>();
  return function renderCheckbox(data: OrderTableDataType): JSX.Element {
    let handleChange = changeHandlerMap.get(data.orderURL);
    if (!handleChange) {
      handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        onCheckChanged(data.orderURL, event.target.checked);
      };
      changeHandlerMap.set(data.orderURL, handleChange);
    }
    return (
      <Checkbox
        checked={selectedOrders.includes(data.orderURL)}
        disabled={
          (!!checkedCustomer && checkedCustomer !== data.customerURL) || data.validatedAndRecorded
        }
        onChange={handleChange}
      />
    );
  };
}

function makeNoteRender(taskListeNoteLines: number): (data: OrderTableDataType) => JSX.Element {
  return function renderNotes(data: OrderTableDataType): JSX.Element {
    const style: React.CSSProperties = {
      display: "-webkit-box",
      overflow: "hidden",
      textOverflow: "ellipsis",
      WebkitBoxOrient: "vertical",

      whiteSpace: "pre-wrap",
    };
    if (taskListeNoteLines) {
      style.WebkitLineClamp = taskListeNoteLines;
    }
    return <div style={style}>{data.note}</div>;
  };
}

function renderColor(data: OrderTableDataType): JSX.Element {
  return <ColorBox color={data.workTypeColor} />;
}

type OrderTableStringFieldID = keyof PickProperties<OrderTableDataType, string>;

function getIdentifierFieldComparator(
  field: OrderTableStringFieldID,
): (a: OrderTableDataType, b: OrderTableDataType) => number {
  return (a: OrderTableDataType, b: OrderTableDataType) => identifierComparator(a[field], b[field]);
}

export function buildOrderColumnSpecifications(
  formatMessage: IntlShape["formatMessage"],
  onClick: (orderURL: string) => void,
  onCheckChanged: (url: OrderUrl, checked: boolean) => void,
  selectedOrders: readonly string[],
  enableOrderReferenceNumber: boolean,
  enableTaskReferenceNumber: boolean,
  orderReferenceNumberLabel: string | null,
  taskReferenceNumberLabel: string | null,
  orderListNoteLines: number,
  machineLabelVariant: Config["machineLabelVariant"],
  projectLabelVariant: Config["projectLabelVariant"],
  checkedCustomer?: string,
): ColumnSpecifications<OrderTableFieldID, OrderTableColumnID, OrderUrl, OrderTableDataType> {
  return {
    address: {
      field: "address",
      label: formatMessage({defaultMessage: "Arbejdssted"}),
      onClick,
      width: 260,
    },
    createdDate: {
      field: "createdDate",
      label: formatMessage({defaultMessage: "Oprettet"}),
      onClick,
      sortField: "createdDateRaw",
      style: {textAlign: "right"},
      width: 150,
    },
    createdDateShort: {
      field: "createdDateShort",
      label: formatMessage({defaultMessage: "Opr."}),
      onClick,
      sortField: "createdDateRaw",
      style: {textAlign: "right"},
      width: 56,
    },
    customer: {
      field: "customer",
      label: formatMessage({defaultMessage: "Kunde"}),
      onClick,
      width: 260,
    },
    customerAddress: {
      field: "customerAddress",
      label: formatMessage({defaultMessage: "Adresse"}),
      onClick,
      searchOnlyWhenVisible: true,
      width: 260,
    },
    customerAddressShort: {
      field: "customerAddressShort",
      label: formatMessage({defaultMessage: "Adr."}),
      onClick,
      searchOnlyWhenVisible: true,
    },
    customerShort: {
      field: "customer",
      label: formatMessage({defaultMessage: "Kunde"}),
      onClick,
      style: {width: "40%"},
    },
    date: {
      field: "date",
      label: formatMessage({defaultMessage: "Dato"}),
      onClick,
      sortField: "dateRaw",
      width: 150,
    },
    dateShort: {
      field: "dateShort",
      label: formatMessage({defaultMessage: "Dato"}),
      onClick,
      sortField: "dateRaw",
      style: {textAlign: "right", width: "20%"},
    },
    deliveryLogLocations: {
      field: "deliveryLogLocations",
      label: formatMessage({defaultMessage: "Lev."}),
      onClick,
      style: {minWidth: 100, whiteSpace: "pre-wrap"},
    },
    department: {
      field: "department",
      label: formatMessage({defaultMessage: "Afdeling"}),
      onClick,
    },
    employees: {
      field: "employees",
      label: formatMessage({defaultMessage: "Medarb."}),
      onClick,
      style: {whiteSpace: "normal"},
      width: 88,
    },
    machineName: {
      field: "machineName",
      label:
        machineLabelVariant === "MACHINE"
          ? formatMessage({defaultMessage: "Maskiner"})
          : formatMessage({defaultMessage: "Køretøjer"}),
      onClick,
    },
    machineNumber: {
      comparator: getIdentifierFieldComparator("machineNumber"),
      field: "machineNumber",
      label:
        machineLabelVariant === "MACHINE"
          ? formatMessage({defaultMessage: "Maskiner"})
          : formatMessage({defaultMessage: "Køretøjer"}),
      onClick,
    },
    machineNumberAndName: {
      field: "machineNumberAndName",
      label:
        machineLabelVariant === "MACHINE"
          ? formatMessage({defaultMessage: "Maskiner"})
          : formatMessage({defaultMessage: "Køretøjer"}),
      onClick,
    },
    managerInternalNotes: {
      field: "managerInternalNotes",
      label: formatMessage({defaultMessage: "Administrationens interne noter"}),
      onClick,
    },
    merge: {
      field: "merge",
      label: "",
      render: makeCheckboxRender(onCheckChanged, selectedOrders, checkedCustomer),
      width: 82,
    },
    note: {
      field: "note",
      label: formatMessage({defaultMessage: "Note"}),
      onClick,
      render: makeNoteRender(orderListNoteLines),
    },
    pickupLogLocations: {
      field: "pickupLogLocations",
      label: formatMessage({defaultMessage: "Afh."}),
      onClick,
      style: {minWidth: 100, whiteSpace: "pre-wrap"},
    },
    projectNumbers: {
      field: "projectNumbers",
      label:
        projectLabelVariant === "PROJECT"
          ? formatMessage({defaultMessage: "Projektnr."})
          : formatMessage({defaultMessage: "Sagsnr."}),
      onClick,
      width: 200,
    },
    referenceNumber: {
      comparator: getIdentifierFieldComparator("referenceNumber"),
      field: "referenceNumber",
      label: getReferenceNumberLabel(
        enableTaskReferenceNumber,
        taskReferenceNumberLabel,
        enableOrderReferenceNumber,
        orderReferenceNumberLabel,
        formatMessage,
      ),
      onClick,
      width: 200,
    },
    status: iconColumnSpecification({
      disableSorting: false,
      field: "status",
      render: renderWorkStatus,
      sortField: "numericStatus",
    }),
    time: {
      field: "time",
      label: formatMessage({defaultMessage: "Kl."}),
      onClick,
      width: 100,
    },
    workType: {
      field: "workType",
      label: formatMessage({defaultMessage: "Arbejdsomr."}),
      onClick,
      width: 260,
    },
    workTypeColor: colorBoxColumnSpecification({
      field: "workTypeColor",
      render: renderColor,
    }),
    workTypes: {
      field: "workTypes",
      label: formatMessage({defaultMessage: "Arbejdsomr."}),
      onClick,
      searchOnlyWhenVisible: true,
      width: 260,
    },
    workTypeShort: {
      field: "workType",
      label: formatMessage({defaultMessage: "Arbejdsomr."}),
      onClick,
      style: {width: "40%"},
    },
  };
}

export function computeOrderVisibleColumns(
  tab: "archive" | "drafts" | "open" | "readyForBilling" | "validation",
  mobile: boolean,
  tablet: boolean,
  userIsManager: boolean,
  orderListColumns: {
    readonly archive: {
      readonly employee: {
        readonly desktop: readonly OrderTableColumnID[];
        readonly mobile: readonly OrderTableColumnID[];
        readonly tablet: readonly OrderTableColumnID[];
      };
      readonly manager: {
        readonly desktop: readonly OrderTableColumnID[];
        readonly mobile: readonly OrderTableColumnID[];
        readonly tablet: readonly OrderTableColumnID[];
      };
    };
    readonly drafts: {
      readonly employee: {
        readonly desktop: readonly OrderTableColumnID[];
        readonly mobile: readonly OrderTableColumnID[];
        readonly tablet: readonly OrderTableColumnID[];
      };
      readonly manager: {
        readonly desktop: readonly OrderTableColumnID[];
        readonly mobile: readonly OrderTableColumnID[];
        readonly tablet: readonly OrderTableColumnID[];
      };
    };
    readonly open: {
      readonly employee: {
        readonly desktop: readonly OrderTableColumnID[];
        readonly mobile: readonly OrderTableColumnID[];
        readonly tablet: readonly OrderTableColumnID[];
      };
      readonly manager: {
        readonly desktop: readonly OrderTableColumnID[];
        readonly mobile: readonly OrderTableColumnID[];
        readonly tablet: readonly OrderTableColumnID[];
      };
    };
    readonly readyForBilling: {
      readonly employee: {
        readonly desktop: readonly OrderTableColumnID[];
        readonly mobile: readonly OrderTableColumnID[];
        readonly tablet: readonly OrderTableColumnID[];
      };
      readonly manager: {
        readonly desktop: readonly OrderTableColumnID[];
        readonly mobile: readonly OrderTableColumnID[];
        readonly tablet: readonly OrderTableColumnID[];
      };
    };
    readonly validation: {
      readonly employee: {
        readonly desktop: readonly OrderTableColumnID[];
        readonly mobile: readonly OrderTableColumnID[];
        readonly tablet: readonly OrderTableColumnID[];
      };
      readonly manager: {
        readonly desktop: readonly OrderTableColumnID[];
        readonly mobile: readonly OrderTableColumnID[];
        readonly tablet: readonly OrderTableColumnID[];
      };
    };
  },
): readonly OrderTableColumnID[] {
  const deviceType = mobile ? "mobile" : tablet ? "tablet" : "desktop";
  const userType = userIsManager ? "manager" : "employee";
  return orderListColumns[tab][userType][deviceType] as OrderTableColumnID[];
}

function validationRequiredFromWorkType(
  orderTasks: Task[] | undefined,
  orderValidationForWorkTypes: readonly string[],
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined,
): boolean {
  return (
    !!orderTasks &&
    orderTasks.some((task) => {
      const taskWorkType = task.workType && workTypeLookup(task.workType);
      return taskWorkType && orderValidationForWorkTypes.includes(taskWorkType.identifier);
    })
  );
}

function filterOrders(
  orderArray: readonly Order[],
  taskArray: readonly Task[],
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined,
  tab: "archive" | "drafts" | "open" | "readyForBilling" | "validation",
  orderValidation: boolean,
  orderValidationForWorkTypes: readonly string[],
  useApproveReport: boolean,
  selectedWorkTypeURLSet: ReadonlySet<string>,
  selectedDepartmentIdentifierSet: ReadonlySet<string>,
  selectedEmployeeGroupsURLSet: ReadonlySet<string> = new Set(),
  userUserProfileLookup: (userUrl: UserUrl) => UserProfile | undefined,
): readonly Order[] {
  const ordersWithIncompleteTasks = new Set<string>();
  const ordersWithCompletedTasks = new Set<string>();
  const ordersWithValidatedTasks = new Set<string>();
  const ordersWithRecordedTasks = new Set<string>();
  const ordersWithTasks = new Set<string>();
  const worktypeFilteredOrders = new Set<string>();
  const employeeGroupFilteredOrders = new Set<string>();
  const departmentFilteredOrders = new Set<string>();
  const tasksForOrders = new Map<string, Task[]>();
  const filtering =
    !!selectedDepartmentIdentifierSet.size ||
    !!selectedWorkTypeURLSet.size ||
    !!selectedEmployeeGroupsURLSet.size;
  taskArray.forEach((task) => {
    const orderURL = task.order;
    if (!orderURL) {
      return;
    }

    ordersWithTasks.add(orderURL);
    const existingTasksForOrder = tasksForOrders.get(orderURL);
    if (existingTasksForOrder) {
      existingTasksForOrder.push(task);
    } else {
      tasksForOrders.set(orderURL, [task]);
    }

    if (
      selectedWorkTypeURLSet.size &&
      (!task.workType || !selectedWorkTypeURLSet.has(task.workType))
    ) {
      return;
    }

    worktypeFilteredOrders.add(orderURL);
    if (
      selectedDepartmentIdentifierSet.size &&
      (!task.department || !selectedDepartmentIdentifierSet.has(task.department))
    ) {
      return;
    }
    departmentFilteredOrders.add(orderURL);

    if (!task.machineOperator && selectedEmployeeGroupsURLSet.size) {
      return;
    } else if (task.machineOperator && selectedEmployeeGroupsURLSet.size) {
      const taskUserProfile = userUserProfileLookup(task.machineOperator);
      if (!taskUserProfile?.employeeGroup) {
        return;
      } else if (
        taskUserProfile?.employeeGroup &&
        !selectedEmployeeGroupsURLSet.has(taskUserProfile?.employeeGroup)
      ) {
        return;
      }
    }

    employeeGroupFilteredOrders.add(orderURL);
    if (task.recordedInC5) {
      ordersWithRecordedTasks.add(orderURL);
    } else if ((useApproveReport && task.reportApproved) || task.validatedAndRecorded) {
      ordersWithValidatedTasks.add(orderURL);
    } else if (task.completed) {
      ordersWithCompletedTasks.add(orderURL);
    } else {
      ordersWithIncompleteTasks.add(orderURL);
    }
  });

  if (tab === "open") {
    if (orderValidation || orderValidationForWorkTypes.length) {
      return orderArray.filter((order) => {
        const orderURL = order.url;
        // orders with incomplete or not validated tasks,
        // as well as orders with no tasks
        return (
          !order.draft &&
          (ordersWithIncompleteTasks.has(orderURL) ||
            ordersWithCompletedTasks.has(orderURL) ||
            (!ordersWithTasks.has(orderURL) && !filtering))
        );
      });
    } else {
      return orderArray.filter((order) => {
        const orderURL = order.url;
        // orders with incomplete tasks,
        // as well as orders with no tasks
        return (
          !order.draft &&
          (ordersWithIncompleteTasks.has(orderURL) ||
            (!ordersWithTasks.has(orderURL) && !filtering))
        );
      });
    }
  } else if (tab === "validation") {
    if (orderValidation || orderValidationForWorkTypes.length) {
      return orderArray.filter((order) => {
        const orderURL = order.url;
        // orders that fulfill specific criteria to use validation,
        // that have not been validated but where all tasks have
        return (
          !order.draft &&
          !order.validatedAndRecorded &&
          !ordersWithIncompleteTasks.has(orderURL) &&
          !ordersWithCompletedTasks.has(orderURL) &&
          ordersWithValidatedTasks.has(orderURL) &&
          (orderValidation ||
            validationRequiredFromWorkType(
              tasksForOrders.get(orderURL),
              orderValidationForWorkTypes,
              workTypeLookup,
            ))
        );
      });
    } else {
      return orderArray.filter((order) => {
        const orderURL = order.url;
        // orders where some orders are completed but not validated
        return !order.draft && ordersWithCompletedTasks.has(orderURL);
      });
    }
  } else if (tab === "archive") {
    return orderArray.filter((order) => !order.draft);
  } else if (tab === "readyForBilling") {
    if (orderValidation || orderValidationForWorkTypes.length) {
      return orderArray.filter((order) => {
        const orderURL = order.url;
        // orders with validated tasks that have not been billed
        // and where *all* tasks are validated
        // and the order is validated if required
        return (
          !order.draft &&
          ordersWithValidatedTasks.has(orderURL) &&
          !ordersWithIncompleteTasks.has(orderURL) &&
          !ordersWithCompletedTasks.has(orderURL) &&
          (order.validatedAndRecorded ||
            (!orderValidation &&
              !validationRequiredFromWorkType(
                tasksForOrders.get(orderURL),
                orderValidationForWorkTypes,
                workTypeLookup,
              )))
        );
      });
    } else {
      return orderArray.filter((order) => {
        const orderURL = order.url;
        // orders with validated tasks that have not been billed
        return !order.draft && ordersWithValidatedTasks.has(orderURL);
      });
    }
  } else if (tab === "drafts") {
    const draftOrderArray = orderArray.filter((order) => order.draft);

    if (filtering) {
      return draftOrderArray.filter((draftorder) => {
        return (
          worktypeFilteredOrders.has(draftorder.url) &&
          departmentFilteredOrders.has(draftorder.url) &&
          employeeGroupFilteredOrders.has(draftorder.url)
        );
      });
    } else {
      return draftOrderArray;
    }
  } else {
    // eslint-disable-next-line no-console
    console.error(`unknown tab: ${tab}`);
    return [];
  }
}

function sortOrders(
  orderArray: readonly Order[],
  customerLookup: (url: CustomerUrl) => Customer | undefined,
): readonly Order[] {
  function orderComparator(a: Order, b: Order): number {
    const aAddress = a.address || "";
    const bAddress = b.address || "";
    let result = aAddress.localeCompare(bAddress);
    if (result) {
      return result;
    }
    const aCustomer = a.customer ? customerLookup(a.customer) : undefined;
    const bCustomer = b.customer ? customerLookup(b.customer) : undefined;
    const aCustomerName = aCustomer?.name || "";
    const bCustomerName = bCustomer?.name || "";
    result = aCustomerName.localeCompare(bCustomerName);
    if (result) {
      return result;
    }
    return resourceInstanceDateTimeComparator(a, b);
  }
  return orderArray.slice().sort(orderComparator);
}

const numericStatusMapping = {
  active: 0,
  completed: 3,
  error: 6,
  paused: 1,
  pending: 2,
  recorded: 5,
  validated: 4,
} as const;

function buildRowData(
  orderArray: readonly Order[],
  taskArray: readonly Task[],
  customerLookup: (url: CustomerUrl) => Customer | undefined,
  projectLookup: (url: ProjectUrl) => Project | undefined,
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined,
  userUserProfileLookup: (url: UserUrl) => UserProfile | undefined,
  locationLookup: (url: LocationUrl) => Location | undefined,
  machineLookup: (url: MachineUrl) => Machine | undefined,
  orderLookup: (url: OrderUrl) => Order | undefined,
  sortedTimerStartArray: readonly TimerStart[],
  noExternalTaskWorkType: boolean,
  customerSettings: Config,
): readonly OrderTableDataType[] {
  const taskLatestTimerStartMap = new Map<string, TimerStart>();
  const taskHasStartTimerStartSet = new Set<string>();
  sortedTimerStartArray.forEach((timerStart) => {
    const taskURL = timerStart.task;
    taskLatestTimerStartMap.set(taskURL, timerStart);
    if (timerStart.timer) {
      taskHasStartTimerStartSet.add(taskURL);
    }
  });
  const getDepartmentLabel = (departmentID: string): string =>
    customerSettings.departments[departmentID] || departmentID;

  const getTaskStatus = (task: Task): WorkStatus => {
    return computeTaskStatus(
      task,
      sortedTimerStartArray,
      orderLookup,
      customerLookup,
      customerSettings.economicSync,
    );
  };

  const tasksForOrders = new Map<string, Task[]>();
  taskArray.forEach((task) => {
    const orderURL = task.order;
    if (!orderURL) {
      return;
    }
    const existingTasksForOrder = tasksForOrders.get(orderURL);
    if (existingTasksForOrder) {
      existingTasksForOrder.push(task);
    } else {
      tasksForOrders.set(orderURL, [task]);
    }
  });
  return orderArray.map((order) => {
    const orderURL = order.url;
    const tasks = tasksForOrders.get(orderURL);
    const projectURLs = tasks ? tasks.map((task) => task.project).filter(notNull) : null;
    const projectNumbers =
      projectURLs && projectURLs.length
        ? Array.from(new Set(projectURLs))
            .map((projectURL) => projectLookup(projectURL)?.projectNumber)
            .filter(notUndefined)
            .sort()
            .join(", ")
        : "";
    const machineOperatorURLs = tasks
      ? tasks.map((task) => task.machineOperator).filter(notNull)
      : null;
    const employees =
      machineOperatorURLs && machineOperatorURLs.length
        ? Array.from(new Set(machineOperatorURLs))
            .map((userURL) => userUserProfileLookup(userURL)?.alias)
            .filter(notUndefined)
            .sort()
            .join(", ")
        : "";

    let status: WorkStatus = "pending";
    if (tasks && tasks.length) {
      const taskStatusArray = tasks.map(getTaskStatus);
      status = _.minBy(taskStatusArray, (s) => numericStatusMapping[s]) || "pending";
    }
    const numericStatus = numericStatusMapping[status];

    let workTypeURL: WorkTypeUrl | null = null;
    if (!noExternalTaskWorkType && tasks) {
      const oldestTask = _.minBy(tasks, (task) => task.created);
      if (oldestTask) {
        workTypeURL = oldestTask.workType;
      }
    }
    const workType = workTypeURL ? workTypeLookup(workTypeURL) : undefined;
    const workTypeString = getWorkTypeString(workType);
    const customer = order.customer ? customerLookup(order.customer) : undefined;
    const customerName = customer?.name || "";
    const customerAddress = [customer?.address, customer?.postalCode, customer?.city]
      .filter(notUndefined)
      .join(", ");
    const customerAddressShort = customer?.address || "";
    const orderTime = order.time;
    const time = orderTime || computeTime(tasks || []);

    const note = order.notes || "";
    const location = order.relatedWorkplace ? locationLookup(order.relatedWorkplace) : null;
    const address = location?.name || location?.address || order.address || "";
    const referenceNumber = order.referenceNumber || "";
    const dateRaw = order.date || "X";
    const date = formatDate(order.date);
    const dateShort = formatDateShort(order.date);
    const createdDate = formatDate(order.created);
    const createdDateShort = formatDateShort(order.created);
    const createdDateRaw = order.created || "";
    const taskDepartments = tasks?.map((task) => getDepartmentLabel(task.department)) || [];
    taskDepartments.sort();
    const department = [
      ...new Set([getDepartmentLabel(order.department), ...taskDepartments].filter(Boolean)),
    ].join(", ");

    const machineURLs = new Set<MachineUrl>();

    tasks?.forEach((task) => {
      task.machineuseSet.forEach((machineUse) => machineURLs.add(machineUse.machine));
    });

    const machines = [...machineURLs].map((url) => machineLookup(url)).filter(notUndefined);

    const machineName = machines.map((machine) => machine.name);
    machineName.sort();
    const machineNumber = machines.map((machine) => machine.c5_machine);
    machineNumber.sort();

    const machineNumberAndName = machines.map((machine) => getMachineString(machine));
    machineNumberAndName.sort();

    const workTypeUrls = tasks ? tasks.map((task) => task.workType).filter(notNull) : null;

    const workTypes =
      workTypeUrls && workTypeUrls.length
        ? Array.from(new Set(workTypeUrls))
            .map((workTypeUrl) => getWorkTypeString(workTypeLookup(workTypeUrl)))
            .filter(Boolean)
            .sort()
            .join(", ")
        : "";

    const deliveryLogLocationUrls = new Set<LocationUrl>();
    const pickupLogLocationUrls = new Set<LocationUrl>();
    if (tasks) {
      for (const task of tasks) {
        if (task.reportingLocations) {
          Object.values(task.reportingLocations).forEach((logLocation) => {
            if (logLocation.type === "delivery" && logLocation.location) {
              deliveryLogLocationUrls.add(logLocation.location);
            } else if (logLocation.type === "pickup" && logLocation.location) {
              pickupLogLocationUrls.add(logLocation.location);
            }
          });
        }
      }
    }

    const deliveryLogLocations = [...deliveryLogLocationUrls]
      .map((url) => {
        const deliveryLocation = locationLookup(url);
        return deliveryLocation ? getLocationString(deliveryLocation) : null;
      })
      .filter(notNull);
    const pickupLogLocations = [...pickupLogLocationUrls]
      .map((url) => {
        const pickupLocation = locationLookup(url);
        return pickupLocation ? getLocationString(pickupLocation) : null;
      })
      .filter(notNull);
    deliveryLogLocations.sort(caseAccentInsensitiveCollator.compare);
    pickupLogLocations.sort(caseAccentInsensitiveCollator.compare);
    return {
      address,
      createdDate,
      createdDateRaw,
      createdDateShort,
      customer: customerName,
      customerAddress,
      customerAddressShort,
      customerURL: order.customer ?? "",
      date,
      dateRaw,
      dateShort,
      deliveryLogLocations: deliveryLogLocations.join("\n"),
      department,
      employees,
      key: orderURL,
      machineName: machineName.join(", "),
      machineNumber: machineNumber.join(", "),
      machineNumberAndName: machineNumberAndName.join(", "),
      managerInternalNotes: order.managerInternalNotes,
      merge: "",
      note,
      numericStatus,
      orderURL,
      pickupLogLocations: pickupLogLocations.join("\n"),
      projectNumbers,
      referenceNumber,
      status,
      time: formatTime(time),
      validatedAndRecorded: !!order.validatedAndRecorded,
      workType: workTypeString,
      workTypeColor: workType?.color || null,
      workTypes,
    };
  });
}

function filteringData(
  filterString: string,
  tab: "archive" | "drafts" | "open" | "readyForBilling" | "validation",
  selectedWorkTypeURLSet: ReadonlySet<string>,
  selectedDepartmentIdentifierSet: ReadonlySet<string>,
): Record<string, unknown> {
  return {
    filterString,
    selectedDepartmentIdentifierSet,
    selectedWorkTypeURLSet,
    tab,
  };
}

interface OrderTableStateProps {
  currentRole: Role | null;
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  customerSettings: Config;
  locationLookup: (url: LocationUrl) => Location | undefined;
  machineLookup: (url: MachineUrl) => Machine | undefined;
  orderArray: readonly Order[];
  orderLookup: (url: OrderUrl) => Order | undefined;
  projectLookup: (url: ProjectUrl) => Project | undefined;
  sortedTimerStartArray: readonly TimerStart[];
  taskArray: readonly Task[];
  userUserProfileLookup: (userURL: UserUrl) => UserProfile | undefined;
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
}

interface OrderTableOwnProps {
  filterString: string;
  onCheckedChanged: (url: OrderUrl, checked: boolean) => void;
  onClick: (orderURL: string) => void;
  selectedCustomer?: string | undefined;
  selectedDepartmentIdentifierSet: ReadonlySet<string>;
  selectedEmployeeGroupsURLSet: ReadonlySet<string>;
  selectedOrders: readonly string[];
  selectedWorkTypeURLSet: ReadonlySet<string>;
  tab: "archive" | "drafts" | "open" | "readyForBilling" | "validation";
}

type OrderTableProps = OrderTableOwnProps & OrderTableStateProps;

class OrderTable extends React.PureComponent<OrderTableProps> {
  constructor(props: OrderTableProps) {
    super(props);
    this.buildColumnSpecifications = memoize(buildOrderColumnSpecifications);
    this.computeVisibleColumns = memoize(computeOrderVisibleColumns);
    this.filterOrders = memoize(filterOrders);
    this.sortOrders = memoize(sortOrders);
    this.buildRowData = memoize(buildRowData);
    this.filteringData = memoize(filteringData);
  }
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  private buildColumnSpecifications: (
    formatMessage: IntlShape["formatMessage"],
    onClick: (orderURL: string) => void,
    onCheckChanged: (url: OrderUrl, checked: boolean) => void,
    selectedOrders: readonly string[],
    enableOrderReferenceNumber: boolean,
    enableTaskReferenceNumber: boolean,
    orderReferenceNumberLabel: string | null,
    taskReferenceNumberLabel: string | null,
    orderListNoteLines: number,
    machineLabelVariant: Config["machineLabelVariant"],
    projectLabelVariant: Config["projectLabelVariant"],
    checkedCustomer?: string,
  ) => ColumnSpecifications<OrderTableFieldID, OrderTableColumnID, OrderUrl, OrderTableDataType>;
  private computeVisibleColumns: (
    tab: "archive" | "drafts" | "open" | "readyForBilling" | "validation",
    mobile: boolean,
    tablet: boolean,
    userIsManager: boolean,
    orderListColumns: {
      readonly archive: {
        readonly employee: {
          readonly desktop: readonly OrderTableColumnID[];
          readonly mobile: readonly OrderTableColumnID[];
          readonly tablet: readonly OrderTableColumnID[];
        };
        readonly manager: {
          readonly desktop: readonly OrderTableColumnID[];
          readonly mobile: readonly OrderTableColumnID[];
          readonly tablet: readonly OrderTableColumnID[];
        };
      };
      readonly drafts: {
        readonly employee: {
          readonly desktop: readonly OrderTableColumnID[];
          readonly mobile: readonly OrderTableColumnID[];
          readonly tablet: readonly OrderTableColumnID[];
        };
        readonly manager: {
          readonly desktop: readonly OrderTableColumnID[];
          readonly mobile: readonly OrderTableColumnID[];
          readonly tablet: readonly OrderTableColumnID[];
        };
      };
      readonly open: {
        readonly employee: {
          readonly desktop: readonly OrderTableColumnID[];
          readonly mobile: readonly OrderTableColumnID[];
          readonly tablet: readonly OrderTableColumnID[];
        };
        readonly manager: {
          readonly desktop: readonly OrderTableColumnID[];
          readonly mobile: readonly OrderTableColumnID[];
          readonly tablet: readonly OrderTableColumnID[];
        };
      };
      readonly readyForBilling: {
        readonly employee: {
          readonly desktop: readonly OrderTableColumnID[];
          readonly mobile: readonly OrderTableColumnID[];
          readonly tablet: readonly OrderTableColumnID[];
        };
        readonly manager: {
          readonly desktop: readonly OrderTableColumnID[];
          readonly mobile: readonly OrderTableColumnID[];
          readonly tablet: readonly OrderTableColumnID[];
        };
      };
      readonly validation: {
        readonly employee: {
          readonly desktop: readonly OrderTableColumnID[];
          readonly mobile: readonly OrderTableColumnID[];
          readonly tablet: readonly OrderTableColumnID[];
        };
        readonly manager: {
          readonly desktop: readonly OrderTableColumnID[];
          readonly mobile: readonly OrderTableColumnID[];
          readonly tablet: readonly OrderTableColumnID[];
        };
      };
    },
  ) => readonly OrderTableColumnID[];
  private filterOrders: (
    orderArray: readonly Order[],
    taskArray: readonly Task[],
    workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined,
    tab: "archive" | "drafts" | "open" | "readyForBilling" | "validation",
    orderValidation: boolean,
    orderValidationForWorkTypes: readonly string[],
    useApproveReport: boolean,
    selectedWorkTypeURLSet: ReadonlySet<string>,
    selectedDepartmentIdentifierSet: ReadonlySet<string>,
    selectedEmployeeGroupsURLSet: ReadonlySet<string>,
    userUserProfileLookup: (url: UserUrl) => UserProfile | undefined,
  ) => readonly Order[];
  private sortOrders: (
    orderArray: readonly Order[],
    customerLookup: (url: CustomerUrl) => Customer | undefined,
  ) => readonly Order[];
  private buildRowData: (
    orderArray: readonly Order[],
    taskArray: readonly Task[],
    customerLookup: (url: CustomerUrl) => Customer | undefined,
    projectLookup: (url: ProjectUrl) => Project | undefined,
    workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined,
    userUserProfileLookup: (url: UserUrl) => UserProfile | undefined,
    locationLookup: (url: LocationUrl) => Location | undefined,
    machineLookup: (url: MachineUrl) => Machine | undefined,
    orderLookup: (url: OrderUrl) => Order | undefined,
    sortedTimerStartArray: readonly TimerStart[],
    noExternalTaskWorkType: boolean,
    customerSettings: Config,
  ) => readonly OrderTableDataType[];
  private filteringData: (
    filterString: string,
    tab: "archive" | "drafts" | "open" | "readyForBilling" | "validation",
    selectedWorkTypeURLSet: ReadonlySet<string>,
    selectedDepartmentIdentifierSet: ReadonlySet<string>,
  ) => Record<string, unknown>;

  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {
      currentRole,
      customerLookup,
      customerSettings,
      filterString,
      locationLookup,
      machineLookup,
      onClick,
      orderArray,
      orderLookup,
      projectLookup,
      selectedDepartmentIdentifierSet,
      selectedEmployeeGroupsURLSet,
      selectedOrders,
      selectedWorkTypeURLSet,
      sortedTimerStartArray,
      tab,
      taskArray,
      userUserProfileLookup,
      workTypeLookup,
    } = this.props;
    const {
      enableOrderReferenceNumber,
      enableTaskReferenceNumber,
      machineLabelVariant,
      noExternalTaskWorkType,
      orderListColumns,
      orderListNoteLines,
      orderReferenceNumberLabel,
      orderValidation,
      orderValidationForWorkTypes,
      projectLabelVariant,
      showDateGroupsOnOrderLists,
      taskReferenceNumberLabel,
      useApproveReport,
    } = customerSettings;
    const userIsManager = !!currentRole?.manager;
    const columnSpecifications = this.buildColumnSpecifications(
      formatMessage,
      onClick,
      this.props.onCheckedChanged,
      selectedOrders,
      enableOrderReferenceNumber,
      enableTaskReferenceNumber,
      orderReferenceNumberLabel,
      taskReferenceNumberLabel,
      orderListNoteLines,
      machineLabelVariant,
      projectLabelVariant,
      this.props.selectedCustomer,
    );
    const visibleColumns = this.computeVisibleColumns(
      tab,
      !!bowser.mobile,
      !!bowser.tablet,
      userIsManager,
      orderListColumns,
    );
    const filteredOrderArray = this.filterOrders(
      orderArray,
      taskArray,
      workTypeLookup,
      tab,
      orderValidation,
      orderValidationForWorkTypes,
      useApproveReport,
      selectedWorkTypeURLSet,
      selectedDepartmentIdentifierSet,
      selectedEmployeeGroupsURLSet,
      userUserProfileLookup,
    );
    const filteredSortedOrderArray = this.sortOrders(filteredOrderArray, customerLookup);
    const data = this.buildRowData(
      filteredSortedOrderArray,
      taskArray,
      customerLookup,
      projectLookup,
      workTypeLookup,
      userUserProfileLookup,
      locationLookup,
      machineLookup,
      orderLookup,
      sortedTimerStartArray,
      noExternalTaskWorkType,
      customerSettings,
    );

    return (
      <ConnectedTableWithPagination
        columns={columnSpecifications}
        defaultRowsPerPage={PaginationPageSize.SMALL}
        defaultSortDirection="ASC"
        defaultSortKey="date"
        entries={data}
        filteringData={this.filteringData(
          filterString,
          tab,
          selectedWorkTypeURLSet,
          selectedDepartmentIdentifierSet,
        )}
        filterString={filterString}
        groupBy={showDateGroupsOnOrderLists ? "date" : undefined}
        savePaginationIdentifier="OrderTable"
        saveSortingIdentifier={`OrderTable${_.upperFirst(tab)}`}
        visibleColumns={visibleColumns}
      />
    );
  }
}

export const ConnectedOrderTable: React.ComponentType<OrderTableOwnProps> = connect<
  OrderTableStateProps,
  object,
  OrderTableOwnProps,
  AppState
>(
  createStructuredSelector<AppState, OrderTableStateProps>({
    currentRole: getCurrentRole,
    customerLookup: getCustomerLookup,
    customerSettings: getCustomerSettings,
    locationLookup: getLocationLookup,
    machineLookup: getMachineLookup,
    orderArray: getOrderArray,
    orderLookup: getOrderLookup,
    projectLookup: getProjectLookup,
    sortedTimerStartArray: getSortedTimerStartArray,
    taskArray: getTaskArray,
    userUserProfileLookup: getUserUserProfileLookup,
    workTypeLookup: getWorkTypeLookup,
  }),
)(OrderTable);
