import {Unit, UnitUrl} from "@co-common-libs/resources";
import {
  ColumnSpecifications,
  GenericTable,
  RowData,
  VerticalStackingFloatingActionButton,
} from "@co-frontend-libs/components";
import {
  actions,
  getExtendedCustomerSettings,
  getTableSortingState,
  getUnitArray,
  getUnitLookup,
} from "@co-frontend-libs/redux";
import {useCallWithTrue} from "@co-frontend-libs/utils";
import {useQueryParameter} from "app-utils";
import CheckIcon from "mdi-react/CheckIcon";
import PlusIcon from "mdi-react/PlusIcon";
import React, {useCallback, useMemo, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {UnitCreateEditDialog} from "./unit-create-edit-dialog";

const TABLE_SORTING_IDENTIFIER = "UnitTable";

type UnitTableColumnID = "active" | "name" | "symbol";

type UnitTableFieldID = "active" | "name" | "symbol";

interface UnitTableDataType extends RowData<UnitTableFieldID, UnitUrl> {
  active: boolean;
  name: string;
  symbol: string;
}

function renderActive(data: UnitTableDataType): JSX.Element {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return data.active ? <CheckIcon /> : <></>;
}

function buildColumnSpecifications(
  onClick: ((unitURL: UnitUrl) => void) | undefined,
): ColumnSpecifications<UnitTableFieldID, UnitTableColumnID, UnitUrl, UnitTableDataType> {
  return {
    active: {
      field: "active",
      label: <FormattedMessage defaultMessage="Aktiv" />,
      onClick,
      render: renderActive,
    },
    name: {
      field: "name",
      label: <FormattedMessage defaultMessage="Navn" />,
      onClick,
    },

    symbol: {
      field: "symbol",
      label: <FormattedMessage defaultMessage="Symbol" />,
      onClick,
    },
  };
}

function buildRowData(unitArray: readonly Unit[]): UnitTableDataType[] {
  return unitArray.map((unit) => {
    const {active, name, symbol, url} = unit;

    return {
      active,
      key: url,
      name,
      symbol,
    };
  });
}

interface UnitTableProps {
  canEdit: boolean;
  filterString: string;
  onClick: (url: UnitUrl) => void;
  showInactive: boolean;
}

function UnitTable(props: UnitTableProps): JSX.Element {
  const {canEdit, filterString, onClick, showInactive} = props;

  const unitArray = useSelector(getUnitArray);

  const columnSpecifications = useMemo(
    () => buildColumnSpecifications(canEdit ? onClick : undefined),
    [canEdit, onClick],
  );

  const rowData = useMemo(
    () => buildRowData(showInactive ? unitArray : unitArray.filter((u) => u.active)),
    [showInactive, unitArray],
  );

  const visibleColumns = useMemo((): readonly UnitTableColumnID[] => {
    if (showInactive) {
      return ["name", "symbol", "active"];
    } else {
      return ["name", "symbol"];
    }
  }, [showInactive]);

  const sortingStateSelector = useMemo(
    () => getTableSortingState(TABLE_SORTING_IDENTIFIER, "identifier", "ASC"),
    [],
  );
  const {sortDirection, sortKey} = useSelector(sortingStateSelector);
  const dispatch = useDispatch();

  const handleHeaderClick = useCallback(
    (key: UnitTableColumnID): void => {
      let direction: "ASC" | "DESC" = "ASC";
      if (sortKey === key && sortDirection === "ASC") {
        direction = "DESC";
      }
      const action = actions.putTableSortingState(TABLE_SORTING_IDENTIFIER, key, direction);
      dispatch(action);
    },
    [dispatch, sortKey, sortDirection],
  );

  return (
    <GenericTable
      columns={columnSpecifications}
      entries={rowData}
      filterString={filterString}
      sortBy={sortKey as any}
      sortDirection={sortDirection}
      visibleColumns={visibleColumns}
      onHeaderClick={handleHeaderClick}
    />
  );
}

export const UnitsList = React.memo(function UnitsList({
  showInactive,
}: {
  showInactive: boolean;
}): JSX.Element {
  const unitLookup = useSelector(getUnitLookup);

  const {units} = useSelector(getExtendedCustomerSettings);
  const {canCreate, canEdit} = units;

  const [unitDialogOpen, setUnitDialogOpen] = useState(false);
  const setUnitDialogOpenTrue = useCallWithTrue(setUnitDialogOpen);

  const [selectedUnit, setSelectedUnit] = useState<Unit | null>(null);
  const handleClick = useCallback(
    (unitUrl: UnitUrl) => {
      if (canEdit) {
        setSelectedUnit(unitLookup(unitUrl) || null);
        setUnitDialogOpen(true);
      }
    },
    [canEdit, unitLookup],
  );

  const handleClose = useCallback(() => {
    setSelectedUnit(null);
    setUnitDialogOpen(false);
  }, []);

  const filterString = useQueryParameter("q", "");

  return (
    <>
      <UnitTable
        canEdit={canEdit}
        filterString={filterString}
        showInactive={showInactive}
        onClick={handleClick}
      />
      {canCreate && (
        <VerticalStackingFloatingActionButton stackIndex={0} onClick={setUnitDialogOpenTrue}>
          <PlusIcon />
        </VerticalStackingFloatingActionButton>
      )}

      <UnitCreateEditDialog
        key="unit-dialog"
        open={unitDialogOpen}
        unit={selectedUnit || undefined}
        onCancel={handleClose}
        onOk={handleClose}
      />
    </>
  );
});
