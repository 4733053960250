import {
  CsvImportColumnSpecification,
  CsvImportRequestData,
  CsvImportSpecification,
  getAvailableColumns,
} from "@co-common-libs/csv-import-specifications";
import {
  getCustomerArray,
  getCustomerSettings,
  getLocationTypeArray,
  getUnitArray,
} from "@co-frontend-libs/redux";
import {NetworkError, StatusError, jsonFetch} from "@co-frontend-libs/utils";
import {Button, CardActions} from "@material-ui/core";
import {globalConfig} from "frontend-global-config";
import React, {useCallback, useMemo, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {ValidationData, getParseValidateFunction} from "../parse-validate";
import {ImportColumnConfiguration} from "../types";
import {blankColumnConfiguration, getColumnConfiguration} from "./get-column-configuration";
import {formatImportError} from "./import-errors";
import {ImportStatusDialog, UploadStatus} from "./import-status-dialog";

function parseAndValidateValue(
  inputvalue: string,
  column: CsvImportColumnSpecification,
  required: boolean,
  data: ValidationData,
): boolean | number | string | null {
  const validateFunction = getParseValidateFunction(column, data);
  const result = validateFunction(inputvalue, required);
  if (result.success) {
    return result.data;
  } else {
    throw new Error(result.error);
  }
}

export function ImportConfigurationCardActions(props: {
  createNotExisting: boolean;
  importColumnConfigurations: ReadonlyMap<string, ImportColumnConfiguration>;
  rows: readonly (readonly string[])[];
  selectedImportSpecification: CsvImportSpecification;
  validatedStatus: boolean;
}): JSX.Element {
  const {
    createNotExisting,
    importColumnConfigurations,
    rows,
    selectedImportSpecification,
    validatedStatus,
  } = props;

  const intl = useIntl();

  const customerSettings = useSelector(getCustomerSettings);
  const locationTypeArray = useSelector(getLocationTypeArray);
  const customerArray = useSelector(getCustomerArray);
  const unitArray = useSelector(getUnitArray);

  const validationData = useMemo(
    (): ValidationData => ({
      config: customerSettings,
      customers: customerArray,
      locationTypes: locationTypeArray,
      units: unitArray,
    }),
    [customerArray, customerSettings, locationTypeArray, unitArray],
  );

  const [modalOpen, setModalOpen] = useState(false);

  const [importResult, setImportResult] = useState<UploadStatus | null>(null);

  const columnLabels = useMemo(
    () => new Map(selectedImportSpecification.columns.map(({label, name}) => [name, label])),
    [selectedImportSpecification.columns],
  );

  const handleOKButtonClick = useCallback(() => {
    setModalOpen(false);
  }, []);

  const handleImportButtonClick = useCallback(async () => {
    if (!selectedImportSpecification) {
      return;
    }
    setImportResult(null);
    setModalOpen(true);

    try {
      // not filtering with "availableColumns" here; backend expects "optional"
      // values to still be present with `null` for create
      const columnsWithConfigurations = selectedImportSpecification.columns.map(
        (columnSpecification, index) => {
          const columnConfiguration = getColumnConfiguration(
            importColumnConfigurations,
            columnSpecification.name,
          );
          if (index === 0 || createNotExisting || columnConfiguration.updateExisting) {
            // column actually used
            return {columnConfiguration, columnSpecification};
          } else {
            // neither used for create nor update;
            // any validation errors will be hidden from UI;
            // so don't obtain/validate values
            return {
              columnConfiguration: blankColumnConfiguration,
              columnSpecification,
            };
          }
        },
      );

      const postUrl = `${globalConfig.resources.baseURL}csv_import/`;
      const data: CsvImportRequestData = {
        configuration: {
          createNotExisting,
          updateExistingColumns: getAvailableColumns(customerSettings, selectedImportSpecification)
            .map(({name}) => name)
            .filter((name) => importColumnConfigurations.get(name)?.updateExisting),
        },
        data: rows.map((row) => {
          const result: Record<string, boolean | number | string | null> = {};

          columnsWithConfigurations.forEach(({columnConfiguration, columnSpecification}) => {
            let value: string;
            switch (columnConfiguration.valueType) {
              case "sourceColumn":
                value = row[columnConfiguration.columnIndex];
                break;
              case "staticValue":
                ({value} = columnConfiguration);
                break;
              case "none":
                value = "";
                break;
            }
            const isRequired: boolean =
              createNotExisting || columnConfiguration.updateExisting
                ? columnSpecification.required
                : false;

            result[columnSpecification.name] = parseAndValidateValue(
              value,
              columnSpecification,
              isRequired,
              validationData,
            );
          });

          return result;
        }),
        specificationName: selectedImportSpecification.name,
      };

      const response = await jsonFetch(postUrl, "POST", data);

      const jobId = response.data?.jobId;

      setImportResult({jobId, sent: true});
    } catch (error) {
      let errorMessage = "";
      if (error instanceof NetworkError) {
        errorMessage = intl.formatMessage({
          defaultMessage:
            "Kunne ikke oprette forbindelse til CustomOffice. Denne funktion kan kun bruges når du er online.",
        });
      } else if (error instanceof StatusError) {
        if (typeof error.data === "object" && error.data) {
          try {
            errorMessage = formatImportError(intl, error.data, columnLabels);
          } catch {
            errorMessage = error.message;
          }
        } else {
          errorMessage = error.message;
        }
      } else if (error instanceof Error) {
        errorMessage = error.message;
      } else {
        errorMessage = `${error}`;
      }
      setImportResult({error: errorMessage, sent: false});
    }
  }, [
    columnLabels,
    createNotExisting,
    customerSettings,
    importColumnConfigurations,
    intl,
    rows,
    selectedImportSpecification,
    validationData,
  ]);

  return (
    <CardActions>
      <Button
        color="primary"
        disabled={!validatedStatus}
        variant="contained"
        onClick={handleImportButtonClick}
      >
        <FormattedMessage defaultMessage="Importer" />
      </Button>
      <ImportStatusDialog
        columnLabels={columnLabels}
        open={modalOpen}
        uploadStatus={importResult}
        onOk={handleOKButtonClick}
      />
    </CardActions>
  );
}
