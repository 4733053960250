import {Contact} from "@co-common-libs/resources";
import {Typography} from "@material-ui/core";
import React from "react";
import {FormattedMessage} from "react-intl";
import {EmailLine} from "./info-lines/email-line";
import {PhoneLine} from "./info-lines/phone-line";

export function ContactInfo({
  contact: {cellphone, email, fax, name, phone},
}: {
  contact: Contact;
}): React.JSX.Element {
  return (
    <div>
      <Typography component="span" variant="body2">
        <FormattedMessage defaultMessage="Navn: {name}" values={{name}} />
      </Typography>
      {phone && <PhoneLine phone={phone} type="landline" />}
      {cellphone && <PhoneLine phone={cellphone} type="cellphone" />}
      {fax && <PhoneLine phone={fax} type="fax" />}
      {email && <EmailLine email={email} />}
    </div>
  );
}
