import {CustomerUrl, MachineUrl, PriceGroupUrl, WorkTypeUrl} from "@co-common-libs/resources";
import {customerFilteredPriceGroups} from "@co-common-libs/resources-utils";
import {notUndefined} from "@co-common-libs/utils";
import {PriceGroupDialog} from "@co-frontend-libs/components";
import {
  getMachineLookup,
  getPriceGroupArray,
  getPriceGroupLookup,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import React, {useMemo} from "react";
import {useSelector} from "react-redux";

interface ConnectedPriceGroupDialogProps {
  customerUrl?: CustomerUrl | undefined;
  includeOnlyForExtraTimers?: boolean | undefined;
  machineURL?: MachineUrl | undefined;
  onCancel(): void;
  onlyPriceGroups?: readonly PriceGroupUrl[] | undefined;
  onOk(url: PriceGroupUrl): void;
  open: boolean;
  workTypeURL?: WorkTypeUrl | undefined;
}

export const ConnectedPriceGroupDialog: React.FunctionComponent<ConnectedPriceGroupDialogProps> =
  React.memo(function ConnectedPriceGroupDialog(
    props: ConnectedPriceGroupDialogProps,
  ): JSX.Element {
    const {
      customerUrl,
      includeOnlyForExtraTimers = false,
      machineURL,
      onCancel,
      onlyPriceGroups,
      onOk,
      open,
      workTypeURL,
    } = props;
    console.assert(!workTypeURL || !machineURL);

    const priceGroupArray = useSelector(getPriceGroupArray);
    const workTypeLookup = useSelector(getWorkTypeLookup);
    const machineLookup = useSelector(getMachineLookup);
    const priceGroupLookup = useSelector(getPriceGroupLookup);

    const filteredPriceGroupArray = useMemo(() => {
      if (onlyPriceGroups) {
        // don't filter on active if specific array given
        return onlyPriceGroups.map(priceGroupLookup).filter(notUndefined);
      }
      const limitedURLs = workTypeURL
        ? workTypeLookup(workTypeURL)?.pricegroups
        : machineURL
          ? machineLookup(machineURL)?.pricegroups
          : undefined;
      const base = limitedURLs
        ? limitedURLs.map(priceGroupLookup).filter(notUndefined)
        : priceGroupArray;
      return customerFilteredPriceGroups(base, customerUrl || null, includeOnlyForExtraTimers);
    }, [
      customerUrl,
      includeOnlyForExtraTimers,
      machineLookup,
      machineURL,
      onlyPriceGroups,
      priceGroupArray,
      priceGroupLookup,
      workTypeLookup,
      workTypeURL,
    ]);
    return (
      <PriceGroupDialog
        open={open}
        priceGroupArray={filteredPriceGroupArray}
        onCancel={onCancel}
        onOk={onOk}
      />
    );
  });
