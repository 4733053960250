import {MenuToolbar, PageLayout} from "app-components";
import React from "react";
import {useIntl} from "react-intl";
import {Overview} from "./overview";

interface OverviewSettingsPageProps {
  onMenuButton: (event: React.MouseEvent) => void;
}

export const OverviewSettingsPage = React.memo(function OverviewSettingsPage(
  props: OverviewSettingsPageProps,
): JSX.Element {
  const intl = useIntl();

  return (
    <PageLayout
      withBottomScrollPadding
      toolbar={
        <MenuToolbar
          title={intl.formatMessage({defaultMessage: "Oversigt"})}
          onMenuButton={props.onMenuButton}
        />
      }
    >
      <div
        style={{
          backgroundColor: "#fff",
          flex: 1,
          overflow: "auto",
        }}
      >
        <Overview />
      </div>
    </PageLayout>
  );
});
