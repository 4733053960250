import {
  MachineUrl,
  PriceGroupUrl,
  ProductUrl,
  ReportingSpecificationUrl,
  WorkTypeUrl,
} from "../resource-urls";
import {ResourceInstance} from "./base";

export interface WorkType extends ResourceInstance<WorkTypeUrl> {
  readonly active: boolean;
  readonly allowMaxOneMachine: boolean | undefined;
  readonly barred: boolean;
  readonly c5_recid: number | null;
  readonly color: string;
  readonly department: string;
  readonly disallowMachineUse: boolean | undefined;
  readonly enableSmallMachines: boolean | undefined;
  readonly externalTaskPrimary: boolean;
  readonly externalTaskSecondary: boolean;
  readonly identifier: string;
  readonly internalTaskPrimary: boolean;
  readonly internalTaskSecondary: boolean;
  readonly machines: readonly MachineUrl[];
  readonly name: string;
  readonly noProductUsesOrAtLeastOneProductUseGreaterThanZero: boolean | undefined;
  readonly onlyForExtraTimers: boolean | undefined;
  readonly pricegroups: readonly PriceGroupUrl[];
  readonly productive: boolean;
  readonly products: readonly ProductUrl[];
  readonly remoteUrl: string;
  readonly reportingSpecification: ReportingSpecificationUrl | null;
  readonly requireAtLeastOneOptionalPriceItemUseGreaterThanZero: boolean;
  readonly requireAtLeastOneProductUseGreaterThanZero: boolean;
  readonly requireAttachment: boolean | undefined;
  readonly requireEffectiveTime: boolean;
  readonly requireField: boolean | undefined;
  readonly requireMachineUse: boolean | undefined;
  readonly requireNotesFromMachineOperator: boolean | undefined;
  readonly requirePhotoOnTaskCompletion: boolean | undefined;
  readonly requireWorkplace: boolean | undefined;
  readonly salesAccount: number | null;
  readonly towingCostMultiplier: number | null;
}

export const emptyWorkType: Omit<WorkType, "id" | "url"> = {
  active: true,
  allowMaxOneMachine: false,
  barred: false,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  c5_recid: null,
  color: "",
  department: "",
  disallowMachineUse: false,
  enableSmallMachines: true,
  externalTaskPrimary: false,
  externalTaskSecondary: false,
  identifier: "",
  internalTaskPrimary: false,
  internalTaskSecondary: false,
  machines: [],
  name: "",
  noProductUsesOrAtLeastOneProductUseGreaterThanZero: false,
  onlyForExtraTimers: false,
  pricegroups: [],
  productive: false,
  products: [],
  remoteUrl: "",
  reportingSpecification: null,
  requireAtLeastOneOptionalPriceItemUseGreaterThanZero: false,
  requireAtLeastOneProductUseGreaterThanZero: false,
  requireAttachment: false,
  requireEffectiveTime: false,
  requireField: false,
  requireMachineUse: true,
  requireNotesFromMachineOperator: false,
  requirePhotoOnTaskCompletion: false,
  requireWorkplace: false,
  salesAccount: null,
  towingCostMultiplier: null,
};
