export const JS_TIMESTAMP_LENGTH = 24;

console.assert(JS_TIMESTAMP_LENGTH === "2018-10-26T11:35:43.278Z".length);
console.assert(JS_TIMESTAMP_LENGTH === new Date().toISOString().length);

/**
 * @deprecated Should be removed with Django backend.
 */
export function isNormalisedTimestamp(timestamp: string): boolean {
  return timestamp.length === JS_TIMESTAMP_LENGTH;
}

/**
 * Django backend may omit fractional seconds when zero;
 * i.e. 2000-01-01T12:00:00.000Z becomes 2000-01-01T12:00:00Z but when sorting
 * strings, 2000-01-01T12:00:00.000Z < 2000-01-01T12:00:00.001Z but
 * 2000-01-01T12:00:00Z > 2000-01-01T12:00:00.001Z
 * @deprecated Should be removed with Django backend.
 */
export function normaliseTimestamp(timestamp: string): string {
  if (timestamp.length === JS_TIMESTAMP_LENGTH) {
    return timestamp;
  } else {
    return new Date(timestamp).toISOString();
  }
}
