import {Task} from "@co-common-libs/resources";
import {useCallWithFalse, useCallWithTrue, useResettingState} from "@co-frontend-libs/utils";
import {useCustomerFromTask} from "app-utils";
import React from "react";
import {EconomicChangeCustomerForTaskWizard} from "./economic-change-customer-for-task-wizard";
import {EconomicCustomerApproveDialog} from "./economic-customer-approve-dialog";

interface EconomicCustomerApproveOrChangeDialogProps {
  allowChangeCurrentTaskOnly: boolean;
  onCancel: () => void;
  onSuccess: () => void;
  open: boolean;
  task: Task;
}

/**
 * Approve or change/merge customer for task
 */
export const EconomicCustomerApproveOrChangeDialog = React.memo(
  function EconomicCustomerApproveOrChangeDialog(
    props: EconomicCustomerApproveOrChangeDialogProps,
  ): JSX.Element | null {
    const {allowChangeCurrentTaskOnly, onCancel, onSuccess, open, task} = props;
    const [changeCustomerWizardOpen, setChangeCustomerWizardOpen] = useResettingState<boolean>(
      false,
      open,
    );
    const setChangeCustomerWizardOpenTrue = useCallWithTrue(setChangeCustomerWizardOpen);
    const setChangeCustomerWizardOpenFalse = useCallWithFalse(setChangeCustomerWizardOpen);

    const customer = useCustomerFromTask(task);

    console.assert(customer !== null);

    return customer ? (
      <>
        <EconomicChangeCustomerForTaskWizard
          allowChangeCurrentTaskOnly={allowChangeCurrentTaskOnly}
          open={open && changeCustomerWizardOpen}
          task={task}
          onCancel={onCancel}
          onFailure={setChangeCustomerWizardOpenFalse}
          onSuccess={onSuccess}
        />

        <EconomicCustomerApproveDialog
          customer={customer}
          open={open && !changeCustomerWizardOpen}
          onCancel={onCancel}
          onChange={setChangeCustomerWizardOpenTrue}
          onFailure={onCancel}
          onSuccess={onSuccess}
        />
      </>
    ) : null;
  },
);
