import {CsvExportColumnSpecification} from "@co-common-libs/csv-export-specifications";
import {Checkbox, FormControlLabel, useTheme} from "@material-ui/core";
import {SPACING} from "frontend-global-config";
import React, {useCallback} from "react";

export function ExportColumnSelectorCheckboxes(props: {
  checkedStates: ReadonlySet<string>;
  columns: readonly CsvExportColumnSpecification[];
  onChange: (checked: ReadonlySet<string>) => void;
}): JSX.Element {
  const {checkedStates, columns, onChange} = props;
  const theme = useTheme();

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
      const updatedCheckedStates = new Set(checkedStates);

      if (checked) {
        updatedCheckedStates.add(event.target.name);
      } else {
        updatedCheckedStates.delete(event.target.name);
      }

      onChange(updatedCheckedStates);
    },
    [checkedStates, onChange],
  );

  const columnCheckboxes = columns.map(({label, name}) => (
    <FormControlLabel
      key={name}
      control={
        <Checkbox
          checked={checkedStates.has(name)}
          name={name}
          style={{marginLeft: theme.spacing(SPACING.SMALL)}}
          onChange={handleChange}
        />
      }
      label={label}
    />
  ));

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{columnCheckboxes}</>;
}
