import {
  KrPerLiterFuelSurchargeBasis,
  KrPerLiterFuelSurchargeSpecification,
  PricePercentFuelSurchargeBasis,
  PricePercentFuelSurchargeSpecification,
  urlToId,
} from "@co-common-libs/resources";
import {actions, getCurrentUserURL, getCustomerSettings} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {Card, CardHeader, Fab} from "@material-ui/core";
import {useQueryParameter} from "app-utils";
import {instanceURL} from "frontend-global-config";
import PlusIcon from "mdi-react/PlusIcon";
import React, {useCallback, useState} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuid} from "uuid";
import {KrPerLiterFuelSurchargeSpecificationDialog} from "./kr-per-liter-fuel-surcharge-specification-creation-dialog";
import {KrPerLiterFuelSurchargeSpecificationTable} from "./kr-per-liter-fuel-surcharge-specification-table";
import {PricePercentFuelSurchargeSpecificationDialog} from "./price-percent-fuel-surcharge-specification-creation-dialog";
import {PricePercentFuelSurchargeSpecificationTable} from "./price-percent-fuel-surcharge-specification-table";

interface FuelSurchargeListProps {
  showInactive: boolean;
}

export const FuelSurchargeList = React.memo(function FuelSurchargeList(
  props: FuelSurchargeListProps,
): JSX.Element {
  const {showInactive} = props;
  const filterString = useQueryParameter("q", "");

  const customerSettings = useSelector(getCustomerSettings);
  const currentUserUrl = useSelector(getCurrentUserURL);

  const dispatch = useDispatch();
  const intl = useIntl();

  const {fuelSurcharge} = customerSettings;

  const handlePricePercentFuelSurchargeSpecificationTableClick = useCallback(
    (specificationUrl: string) => {
      dispatch(
        actions.go("/settings/pricePercentFuelSurcharge/:id", {
          id: urlToId(specificationUrl),
        }),
      );
    },
    [dispatch],
  );

  const [
    createPricePercentFuelSurchargeSpecificationDialogOpen,
    setCreatePricePercentFuelSurchargeSpecificationDialogOpen,
  ] = useState(false);
  const setCreatePricePercentFuelSurchargeSpecificationDialogOpenTrue = useCallWithTrue(
    setCreatePricePercentFuelSurchargeSpecificationDialogOpen,
  );
  const setCreatePricePercentFuelSurchargeSpecificationDialogOpenFalse = useCallWithFalse(
    setCreatePricePercentFuelSurchargeSpecificationDialogOpen,
  );

  const handlePricePercentFuelSurchargeSpecificationDialogOk = useCallback(
    (data: {
      basis: Omit<
        PricePercentFuelSurchargeBasis,
        "fromDate" | "id" | "lastChanged" | "specification" | "toDate" | "url"
      >;
      specification: Omit<
        PricePercentFuelSurchargeSpecification,
        "active" | "changedBy" | "id" | "lastChanged" | "url"
      >;
    }): void => {
      setCreatePricePercentFuelSurchargeSpecificationDialogOpen(false);
      if (currentUserUrl) {
        const specificationId = uuid();
        const specificationUrl = instanceURL(
          "pricePercentFuelSurchargeSpecification",
          specificationId,
        );
        const specification: PricePercentFuelSurchargeSpecification = {
          ...data.specification,
          active: true,
          changedBy: currentUserUrl,
          id: specificationId,
          url: specificationUrl,
        };
        const basisId = uuid();
        const basisUrl = instanceURL("pricePercentFuelSurchargeBasis", basisId);
        const basis: PricePercentFuelSurchargeBasis = {
          ...data.basis,
          fromDate: null,
          id: basisId,
          specification: specificationUrl,
          url: basisUrl,
        };
        dispatch(actions.create(specification));
        dispatch(actions.create(basis));
        dispatch(
          actions.go("/settings/pricePercentFuelSurcharge/:id", {
            id: specificationId,
          }),
        );
      }
    },
    [currentUserUrl, dispatch],
  );

  const handleKrPerLiterFuelSurchargeSpecificationTableClick = useCallback(
    (specificationUrl: string) => {
      dispatch(
        actions.go("/settings/krPerLiterFuelSurcharge/:id", {
          id: urlToId(specificationUrl),
        }),
      );
    },
    [dispatch],
  );

  const [
    createKrPerLiterFuelSurchargeSpecificationDialogOpen,
    setCreateKrPerLiterFuelSurchargeSpecificationDialogOpen,
  ] = useState(false);
  const setCreateKrPerLiterFuelSurchargeSpecificationDialogOpenTrue = useCallWithTrue(
    setCreateKrPerLiterFuelSurchargeSpecificationDialogOpen,
  );
  const setCreateKrPerLiterFuelSurchargeSpecificationDialogOpenFalse = useCallWithFalse(
    setCreateKrPerLiterFuelSurchargeSpecificationDialogOpen,
  );

  const handleKrPerLiterFuelSurchargeSpecificationDialogOk = useCallback(
    (data: {
      basis: Omit<
        KrPerLiterFuelSurchargeBasis,
        "fromDate" | "id" | "lastChanged" | "specification" | "toDate" | "url"
      >;
      specification: Omit<
        KrPerLiterFuelSurchargeSpecification,
        "active" | "changedBy" | "id" | "lastChanged" | "url"
      >;
    }): void => {
      setCreateKrPerLiterFuelSurchargeSpecificationDialogOpen(false);
      if (currentUserUrl) {
        const specificationId = uuid();
        const specificationUrl = instanceURL(
          "krPerLiterFuelSurchargeSpecification",
          specificationId,
        );
        const specification: KrPerLiterFuelSurchargeSpecification = {
          ...data.specification,
          active: true,
          changedBy: currentUserUrl,
          id: specificationId,
          url: specificationUrl,
        };
        const basisId = uuid();
        const basisUrl = instanceURL("krPerLiterFuelSurchargeBasis", basisId);
        const basis: KrPerLiterFuelSurchargeBasis = {
          ...data.basis,
          fromDate: null,
          id: basisId,
          specification: specificationUrl,
          url: basisUrl,
        };
        dispatch(actions.create(specification));
        dispatch(actions.create(basis));
        dispatch(
          actions.go("/settings/krPerLiterFuelSurcharge/:id", {
            id: specificationId,
          }),
        );
      }
    },
    [currentUserUrl, dispatch],
  );

  if (fuelSurcharge === "PRICE_PERCENT") {
    return (
      <Card style={{margin: 16}}>
        <CardHeader
          action={
            <Fab
              size="small"
              onClick={setCreatePricePercentFuelSurchargeSpecificationDialogOpenTrue}
            >
              <PlusIcon />
            </Fab>
          }
          title={intl.formatMessage({defaultMessage: "Brændstoftillæg"})}
        />
        <PricePercentFuelSurchargeSpecificationTable
          filterString={filterString}
          showInactive={showInactive}
          onClick={handlePricePercentFuelSurchargeSpecificationTableClick}
        />
        <PricePercentFuelSurchargeSpecificationDialog
          open={createPricePercentFuelSurchargeSpecificationDialogOpen}
          onCancel={setCreatePricePercentFuelSurchargeSpecificationDialogOpenFalse}
          onOk={handlePricePercentFuelSurchargeSpecificationDialogOk}
        />
      </Card>
    );
  } else if (fuelSurcharge === "KR_PER_LITER") {
    return (
      <Card style={{margin: 16}}>
        <CardHeader
          action={
            <Fab size="small" onClick={setCreateKrPerLiterFuelSurchargeSpecificationDialogOpenTrue}>
              <PlusIcon />
            </Fab>
          }
          title={intl.formatMessage({defaultMessage: "Brændstoftillæg"})}
        />
        <KrPerLiterFuelSurchargeSpecificationTable
          filterString={filterString}
          showInactive={showInactive}
          onClick={handleKrPerLiterFuelSurchargeSpecificationTableClick}
        />
        <KrPerLiterFuelSurchargeSpecificationDialog
          open={createKrPerLiterFuelSurchargeSpecificationDialogOpen}
          onCancel={setCreateKrPerLiterFuelSurchargeSpecificationDialogOpenFalse}
          onOk={handleKrPerLiterFuelSurchargeSpecificationDialogOk}
        />
      </Card>
    );
  } else {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }
});
