import {MenuToolbar, PageLayout} from "app-components";
import React from "react";
import {useIntl} from "react-intl";
import {AnniversaryTypes} from "./anniversary-types";

interface AnniversarySettingsPageProps {
  onMenuButton: (event: React.MouseEvent) => void;
}

export const AnniversarySettingsPage = React.memo(function AnniversarySettingsPage(
  props: AnniversarySettingsPageProps,
): JSX.Element {
  const intl = useIntl();

  return (
    <PageLayout
      withBottomScrollPadding
      toolbar={
        <MenuToolbar
          title={intl.formatMessage({defaultMessage: "Mærkedage"})}
          onMenuButton={props.onMenuButton}
        />
      }
    >
      <AnniversaryTypes />
    </PageLayout>
  );
});
