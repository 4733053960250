import {LocationTypeIcon} from "@co-common-libs/resources";
import {DialogContent} from "@material-ui/core";
import React, {useCallback} from "react";
import {useIntl} from "react-intl";
import {IconPicker} from "./icon-picker";
import {ResponsiveDialog} from "./responsive-dialog";

interface IconPickerDialogProps {
  fullscreen?: boolean;
  initialSelected: LocationTypeIcon;
  onCancel: () => void;
  onOk: (icon: LocationTypeIcon) => void;
  open: boolean;
}

export const IconPickerDialog = React.memo(function IconPickerDialog({
  fullscreen,
  initialSelected,
  onCancel,
  onOk,
  open,
}: IconPickerDialogProps): JSX.Element {
  const handleOk = useCallback((selected: LocationTypeIcon) => onOk(selected), [onOk]);

  const intl = useIntl();
  return (
    <ResponsiveDialog
      fullscreen={fullscreen}
      okLabel={intl.formatMessage({
        defaultMessage: "Vælg",
      })}
      open={open}
      title={intl.formatMessage({
        defaultMessage: "Vælg ikon",
      })}
      onCancel={onCancel}
    >
      <DialogContent>
        <IconPicker selectedIcon={initialSelected} onIconClick={handleOk} />
      </DialogContent>
    </ResponsiveDialog>
  );
});
