import {TableCell, TableCellProps} from "@material-ui/core";
import {bind} from "bind-decorator";
import ArrowDownIcon from "mdi-react/ArrowDownIcon";
import ArrowUpIcon from "mdi-react/ArrowUpIcon";
import React from "react";
import {ColumnSpecification, RowData, SortDirection} from "./types";

interface GenericTableHeaderCellProps<
  FieldID extends string,
  ColumnID extends string,
  KeyType extends string = string,
  DataType extends RowData<FieldID, KeyType> = RowData<FieldID, KeyType>,
> {
  column: ColumnSpecification<FieldID, KeyType, DataType>;
  columnID: ColumnID;
  onClick?: ((column: ColumnID) => void) | undefined;
  sortDirection?: SortDirection | undefined;
}

export class GenericTableHeaderCell<
  FieldID extends string,
  ColumnID extends string,
  KeyType extends string = string,
  DataType extends RowData<FieldID, KeyType> = RowData<FieldID, KeyType>,
> extends React.PureComponent<GenericTableHeaderCellProps<FieldID, ColumnID, KeyType, DataType>> {
  @bind
  handleClick(): void {
    const {columnID, onClick} = this.props;
    if (onClick) {
      onClick(columnID);
    }
  }
  render(): JSX.Element {
    const {column, onClick, sortDirection} = this.props;
    let style: React.CSSProperties = {};
    const columnStyle = column.headerStyle || column.style;
    if (columnStyle) {
      if (typeof columnStyle === "function") {
        style = {...style, ...columnStyle(null)};
      } else {
        style = {...style, ...columnStyle};
      }
    }
    if (column.width != null) {
      style = {...style, width: column.width};
    }
    if (!column.disableSorting && onClick) {
      style = {...style, cursor: "pointer"};
    }
    const icon =
      sortDirection &&
      (sortDirection === "ASC" ? <ArrowDownIcon size={12} /> : <ArrowUpIcon size={12} />);
    const tableCellClickProps: Partial<TableCellProps> = {};
    if (onClick && !column.disableSorting) {
      tableCellClickProps.onClick = this.handleClick;
    }
    return (
      <TableCell style={style} {...tableCellClickProps}>
        {column.label} {icon}
      </TableCell>
    );
  }
}
