import {Customer} from "@co-common-libs/resources";
import {IntlShape} from "react-intl";
import {CustomerFormMember, CustomerFormPart} from "./types";

export function computeInputWarningText(
  intl: IntlShape,
  inputFields: ReadonlySet<CustomerFormMember>,
  customers: readonly Customer[],
  formCustomer: CustomerFormPart,
  editCustomer: Customer | null,
): ReadonlyMap<CustomerFormMember, string> {
  const warnings = new Map<CustomerFormMember, string>();

  if (inputFields.has(CustomerFormMember.VAT_NUMBER)) {
    const customerVATInUse =
      !!formCustomer.vatNumber &&
      customers.some(
        ({url, vatNumber}) => vatNumber === formCustomer.vatNumber && url !== editCustomer?.url,
      );

    if (customerVATInUse) {
      warnings.set(
        CustomerFormMember.VAT_NUMBER,
        intl.formatMessage({
          defaultMessage: "Der eksisterer allerede en kunde med det CVR nummer",
        }),
      );
    }
  }

  return warnings;
}
