import {Config} from "@co-common-libs/config";
import {
  Information,
  InformationUrl,
  PatchUnion,
  ResourceTypeUnion,
  Role,
  User,
  UserProfile,
  UserUrl,
  emptyInformation,
  urlToId,
} from "@co-common-libs/resources";
import {numericDateFormat} from "@co-common-libs/utils";
import {VerticalStackingFloatingActionButton} from "@co-frontend-libs/components";
import {
  AppState,
  PathTemplate,
  actions,
  getCurrentRole,
  getCurrentUserURL,
  getCustomerSettings,
  getInformationArray,
  getInformationLookup,
  getRoleArray,
  getUserArray,
  getUserUserProfileLookup,
} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {MenuToolbar, PageLayout} from "app-components";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import {instanceURL} from "frontend-global-config";
import PlusIcon from "mdi-react/PlusIcon";
import React from "react";
import {IntlContext, defineMessages} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {v4 as uuid} from "uuid";
import {InformationItem} from "./item";

const containerStyle = {
  listStyle: "none",
  padding: "0",
};

const liStyle = {
  margin: 10,
};

const messages = defineMessages({
  notPublished: {
    defaultMessage: "Ikke synlig for medarbejdere",
    id: "information.information-item.not-published",
  },
  seen: {
    defaultMessage: "Set",
    id: "information.information-item.seen",
  },
  title: {defaultMessage: "Information", id: "information.title.information"},
});

function informationSort(a: Information, b: Information): number {
  const aPublished = a.published;
  const bPublished = b.published;

  // not published at the top
  if (aPublished && !bPublished) {
    return 1;
  }
  if (!aPublished && bPublished) {
    return -1;
  }
  // pinned at the top
  const aPinned = a.pinned;
  const bPinned = b.pinned;
  if (aPinned && !bPinned) {
    return -1;
  }
  if (!aPinned && bPinned) {
    return 1;
  }
  if (aPublished && bPublished) {
    const aPublishedDate = a.publishedDatetime;
    const bPublishedDate = b.publishedDatetime;
    if (aPublishedDate && bPublishedDate) {
      if (aPublishedDate > bPublishedDate) {
        return -1;
      }
      if (aPublishedDate < bPublishedDate) {
        return 1;
      }
    }
  }
  return (a.title || "").localeCompare(b.title || "");
}

export const informationVisibleToUser = (
  visibleTo: readonly string[],
  userURL: string,
): boolean => {
  return !visibleTo || visibleTo.length === 0 || visibleTo.includes(userURL);
};

interface InformationListPageStateProps {
  currentRole: Role | null;
  currentUserURL: UserUrl | null;
  customerSettings: Config;
  informationArray: readonly Information[];
  informationLookup: (url: InformationUrl) => Information | undefined;
  roleArray: readonly Role[];
  userArray: readonly User[];
  userUserProfileLookup: (url: UserUrl) => UserProfile | undefined;
}

interface InformationListPageDispatchProps {
  create: (instance: ResourceTypeUnion) => void;
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  update: (url: string, patch: PatchUnion) => void;
}

interface InformationListPageOwnProps {
  onMenuButton: (event: React.MouseEvent) => void;
}

type InformationListPageProps = InformationListPageDispatchProps &
  InformationListPageOwnProps &
  InformationListPageStateProps;

class InformationListPage extends PureComponent<InformationListPageProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  @bind
  handleOnClick(id: string): void {
    this.props.go("/information/:id", {id});
  }

  @bind
  handleSeen(id: string): void {
    const {currentUserURL, informationLookup, update} = this.props;
    const url = instanceURL("information", id);
    const information = informationLookup(url);
    if (information && currentUserURL) {
      const {seenBy} = information;
      if (!seenBy.includes(currentUserURL)) {
        update(url, [
          {
            member: "seenBy",
            value: [...information.seenBy, currentUserURL],
          },
        ]);
      }
    }
  }

  @bind
  handleFabButton(): void {
    if (!this.props.currentUserURL) {
      return;
    }
    const id = uuid();
    const url = instanceURL("information", id);
    const data: Information = {
      ...emptyInformation,
      createdBy: this.props.currentUserURL,
      id,
      pinned: false,
      published: false,
      seenBy: [this.props.currentUserURL],
      text: "",
      title: "",
      url,
    };
    this.props.create(data);
    setTimeout(() => {
      this.props.go("/information/:id", {id});
    });
  }

  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {
      currentRole,
      currentUserURL,
      customerSettings,
      informationArray,
      onMenuButton,
      roleArray,
      userArray,
      userUserProfileLookup,
    } = this.props;
    const userIsManager = !!(currentRole && currentRole.manager);
    const userIsProjectManager = !!(currentRole && currentRole.projectManager);

    const userRoleMap = new Map<string, Role>();
    roleArray.forEach((instance) => {
      const userURL = instance.user;
      userRoleMap.set(userURL, instance);
    });

    const informationList = informationArray
      .filter((info) => {
        const {published} = info;
        const {visibleTo} = info;
        return (
          userIsManager || (published && informationVisibleToUser(visibleTo, currentUserURL || ""))
        );
      })
      .sort(informationSort)
      .map((information) => {
        let formattedDateOrMessage;
        const publishedDate = information.publishedDatetime;
        if (publishedDate && information.published) {
          formattedDateOrMessage = numericDateFormat.format(new Date(publishedDate));
        } else if (!information.published) {
          formattedDateOrMessage = formatMessage(messages.notPublished);
        }
        const {createdBy} = information;
        const seenByList = information.seenBy;
        const isSeen = !!currentUserURL && seenByList.includes(currentUserURL);
        const visibleTo = information.visibleTo || [];
        let userInitials;
        if (customerSettings.showWhoHasNotReadInformation) {
          userInitials = userArray
            .filter((user) => {
              const userURL = user.url;
              const role = userRoleMap.get(userURL);
              return (
                user.active &&
                role &&
                !role.breakRoom &&
                !role.consultant &&
                !seenByList.includes(userURL) &&
                (role.manager || informationVisibleToUser(visibleTo, userURL))
              );
            })
            .map((user) => userUserProfileLookup(user.url)?.alias || user.loginIdentifier);
        } else {
          userInitials = seenByList
            .filter((userURL) => {
              const role = userRoleMap.get(userURL);
              return userURL !== createdBy && role && !role.breakRoom && !role.consultant;
            })
            .map((userURL) => userUserProfileLookup(userURL)?.alias || "");
        }
        const visibleToInitials = visibleTo
          .map((userURL) => userUserProfileLookup(userURL)?.alias || "")
          .sort();

        const createdByUserProfile = userUserProfileLookup(createdBy);
        const createdByInitials = createdByUserProfile ? createdByUserProfile.alias : "";

        return (
          <InformationItem
            key={information.url}
            createdBy={createdByInitials}
            customerSettings={customerSettings}
            date={formattedDateOrMessage}
            id={urlToId(information.url)}
            isManager={userIsManager}
            isSeen={isSeen}
            published={information.published}
            text={information.text}
            title={information.title}
            userInitials={userInitials}
            visibleToInitials={visibleToInitials}
            onEditButtonClick={this.handleOnClick}
            onSeen={this.handleSeen}
          />
        );
      });

    let floatingActionButton;
    if (userIsManager || userIsProjectManager) {
      floatingActionButton = (
        <VerticalStackingFloatingActionButton stackIndex={0} onClick={this.handleFabButton}>
          <PlusIcon />
        </VerticalStackingFloatingActionButton>
      );
    }

    return (
      <PageLayout
        withBottomScrollPadding
        floatingActionButton={floatingActionButton}
        toolbar={<MenuToolbar title={formatMessage(messages.title)} onMenuButton={onMenuButton} />}
      >
        <ul style={containerStyle}>
          {informationList.map((item, index) => {
            return (
              <li key={index} style={liStyle}>
                {item}
              </li>
            );
          })}
        </ul>
      </PageLayout>
    );
  }
}

const ConnectedInformationListPage: React.ComponentType<InformationListPageOwnProps> = connect<
  InformationListPageStateProps,
  InformationListPageDispatchProps,
  InformationListPageOwnProps,
  AppState
>(
  createStructuredSelector<AppState, InformationListPageStateProps>({
    currentRole: getCurrentRole,
    currentUserURL: getCurrentUserURL,
    customerSettings: getCustomerSettings,
    informationArray: getInformationArray,
    informationLookup: getInformationLookup,
    roleArray: getRoleArray,
    userArray: getUserArray,
    userUserProfileLookup: getUserUserProfileLookup,
  }),
  {
    create: actions.create,
    go: actions.go,
    update: actions.update,
  },
)(InformationListPage);

export {ConnectedInformationListPage as InformationListPage};
