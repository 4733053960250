import {jsonFetch} from "@co-frontend-libs/utils";
import {useCallback, useEffect, useRef, useState} from "react";

function doFetch(
  url: string,
  setFetching: (fetching: boolean) => void,
  setResult: (result: any) => void,
  setError: (error: Error | null) => void,
): () => void {
  console.assert(url);
  setFetching(true);
  // Use AbortController if available; cancelled-flag as fallback.
  let cancelled = false;
  const abortController = window.AbortController ? new AbortController() : null;
  jsonFetch(url, "GET", null, abortController?.signal)
    .then((response) => {
      // Check the flag to ignore result in case AbortController
      // not supported; then this is no longer the "active" request,
      // so its status and result is irrelevant.
      if (!cancelled) {
        setFetching(false);
        setResult(response.data);
      }
      return;
    })
    .catch((reason) => {
      // Check whether the "error" is that the request was aborted;
      // in that case, it's not really an error, and this is no longer
      // the "active" request, so its status is irrelevant.
      if (reason.cause?.name !== "AbortError") {
        setFetching(false);
        setError(reason);
      }
    });
  return () => {
    // Abort request, with fallback to just setting a flag if
    // AbortController not supported, and clear state.
    cancelled = true;
    if (abortController) {
      abortController.abort();
    }
    setFetching(false);
    setResult(undefined);
    setError(null);
  };
}

export function useFetchGet<T = unknown>(): readonly [
  (url: string) => void,
  () => void,
  T | undefined,
  boolean,
  Error | null,
] {
  const [fetching, setFetching] = useState(false);
  // null is a *possible* response JSON value; undefined is not, so we
  // represent "no data yet" with undefined to be able to discern this.
  const [result, setResult] = useState<T | undefined>(undefined);
  const [error, setError] = useState<Error | null>(null);

  const cancelRef = useRef<(() => void) | null>(null);

  const cancel = useCallback(() => {
    if (cancelRef.current) {
      cancelRef.current();
      cancelRef.current = null;
    }
    setResult(undefined);
    setError(null);
  }, []);

  const start = useCallback(
    (url: string) => {
      cancel();
      cancelRef.current = doFetch(url, setFetching, setResult, setError);
    },
    [cancel],
  );

  useEffect(() => {
    return cancel;
  }, [cancel]);

  return [start, cancel, result, fetching, error] as const;
}
