import {TaskUrl, TimerStart, UserUrl} from "@co-common-libs/resources";
import {normaliseTimestamp} from "./timestamp-strings";

export function timerStartNormalisedComparator(
  aTimerStart: TimerStart,
  bTimerStart: TimerStart,
): -1 | 0 | 1 {
  const aTimestampNormalised = normaliseTimestamp(aTimerStart.deviceTimestamp);
  const bTimestampNormalised = normaliseTimestamp(bTimerStart.deviceTimestamp);

  if (aTimestampNormalised < bTimestampNormalised) {
    return -1;
  } else if (aTimestampNormalised > bTimestampNormalised) {
    return 1;
  } else {
    const aIsStop = !aTimerStart.timer;
    const bIsStop = !bTimerStart.timer;
    if (aIsStop && !bIsStop) {
      return -1;
    } else if (!aIsStop && bIsStop) {
      return 1;
    } else {
      return 0;
    }
  }
}

export function getNormalisedDeviceTimestamp(obj: {readonly deviceTimestamp: string}): string {
  return normaliseTimestamp(obj.deviceTimestamp);
}

export const getLastTimerStart = (
  userURL: string,
  timerStartArray: readonly TimerStart[],
): TimerStart | undefined => {
  if (!timerStartArray.length) {
    return undefined;
  }
  const userTimerStarts = timerStartArray.filter((instance) => instance.employee === userURL);
  const sortedUserTimerStarts = userTimerStarts.sort(timerStartNormalisedComparator);
  return sortedUserTimerStarts[sortedUserTimerStarts.length - 1];
};

export const getActiveTimerStart = (
  userURL: UserUrl,
  timerStartArray: readonly TimerStart[],
): TimerStart | null => {
  const tasksLastTimerStarts = new Map<TaskUrl, TimerStart>();
  timerStartArray.forEach((timerStart: TimerStart) => {
    if (timerStart.employee === userURL) {
      const taskURL = timerStart.task;
      const oldTimerStart = tasksLastTimerStarts.get(taskURL);
      if (
        !oldTimerStart ||
        getNormalisedDeviceTimestamp(oldTimerStart) < getNormalisedDeviceTimestamp(timerStart)
      ) {
        tasksLastTimerStarts.set(taskURL, timerStart);
      }
    }
  });
  return Array.from(tasksLastTimerStarts.values()).find((timerstart) => timerstart.timer) ?? null;
};
