export const HALF_HOUR_ROW_HEIGHT = 25;
export const HALF_HOURS_PER_HOUR = 2;
export const QUARTER_HOUR_ROW_HEIGHT = 12.5;

export const NON_OVERLAPPED_WIDTH_DIVISOR = 3;
export const HOUR_ROW_HEIGHT = HALF_HOURS_PER_HOUR * HALF_HOUR_ROW_HEIGHT;

export const TASK_WIDTH = 80;
export const TIME_TRACKER_WIDTH = 8;
export const TIME_TRACKER_LEFT = 4;
export const NOT_OVERLAPPED_WIDTH = Math.floor(TASK_WIDTH / NON_OVERLAPPED_WIDTH_DIVISOR);
export const TRACKED_TASK_LEFT = TIME_TRACKER_LEFT + TIME_TRACKER_WIDTH;
export const PARTIALLY_PLANNED_TASK_HEIGHT = 30;
export const PLANNED_TASK_LEFT = TRACKED_TASK_LEFT + NOT_OVERLAPPED_WIDTH;

export const NOW_RULER_Z_INDEX = 8;
export const TIME_TRACKER_Z_INDEX = 3;
export const ERROR_Z_INDEX = 5;
export const TASK_FRONT_Z_INDEX = 3;
export const TASK_BACK_Z_INDEX = 2;
export const ARRIVAL_MARKER_Z_INDEX = 4;
export const NORMAL_HOURS_OVERLAY_Z_INDEX = 1;
export const UPDATE_NOW_RULER_INTERVAL_SECONDS = 10;
export const COMPLETED_OPACITY = 0.5;
export const FADED_OPACITY = 0.2;

export const HOUR_COLUMN_WIDTH = 46;
export const USER_COLUMN_WIDTH =
  TIME_TRACKER_LEFT + TIME_TRACKER_WIDTH + NOT_OVERLAPPED_WIDTH + TASK_WIDTH + 4;

export const NO_ESTIMATE_TASK_MINUTES = 36;
