import {ThrottledTextField} from "@co-frontend-libs/components";
import {getCurrentRole, getCurrentUserURL, getCustomerSettings} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Switch,
  TableCell,
  TableRow,
  useTheme,
} from "@material-ui/core";
import {useEventTargetCheckedCallback} from "app-utils";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import PlusIcon from "mdi-react/PlusIcon";
import React, {useRef, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {CalculatorButton} from "../calculator-button";
import {MaterialField} from "../material-field";
import {
  BUTTON_COLUMN_STYLE,
  CONVERSION_UNIT_COLUMN_WIDTH,
  COUNT_COLUMN_WIDTH,
  TOGGLE_COLUMN_WIDTH,
  UNIT_COLUMN_WIDTH,
} from "./constants";
import {EntryProps, withEntryCallbacks} from "./with-entry-callbacks";

const DisplayTableRow = React.memo(function DisplayTableRow(props: EntryProps): JSX.Element {
  const {
    addedBy,
    autoProduct,
    calculator,
    countValue,
    editable,
    inputUnitSymbol,
    notes,
    notesEditable,
    onCountChange,
    onDeleteClick,
    onNotesChange,
    onOursChange,
    onPlusClick,
    onRelationCountChange,
    onSwitchProductClick,
    ours,
    productName,
    productNumber,
    relationConversionUnitSymbol,
    relationConversionValue,
    relationCountValue,
    removable,
    showIconColumn,
    showNotes,
    showRelationConversionColumns,
    switchable,
  } = props;

  const intl = useIntl();
  const theme = useTheme();
  const customerSettings = useSelector(getCustomerSettings);
  const currentRole = useSelector(getCurrentRole);
  const isManager = !!currentRole?.manager;
  const currentUserURL = useSelector(getCurrentUserURL);

  const handleOursToggle = useEventTargetCheckedCallback(onOursChange, [onOursChange]);

  const actionMenuButtonRef = useRef<HTMLButtonElement>(null);
  const [actionMenuOpen, setActionMenuOpen] = useState(false);
  const setActionMenuOpenTrue = useCallWithTrue(setActionMenuOpen);
  const setActionMenuOpenFalse = useCallWithFalse(setActionMenuOpen);

  const switchProductActionMenuItem =
    onSwitchProductClick && !autoProduct ? (
      <MenuItem disabled={!switchable} onClick={onSwitchProductClick}>
        <FormattedMessage defaultMessage="Skift vare" />
      </MenuItem>
    ) : null;
  const deleteProductActionMenuItem =
    onDeleteClick &&
    !autoProduct &&
    (isManager || (currentUserURL && addedBy === currentUserURL)) ? (
      <MenuItem
        disabled={!removable}
        style={removable ? {color: theme.palette.error.main} : {}}
        onClick={onDeleteClick}
      >
        <FormattedMessage defaultMessage="Slet vare" />
      </MenuItem>
    ) : null;

  return (
    <TableRow>
      <TableCell>
        {productName} ({productNumber})
      </TableCell>
      <TableCell style={{width: COUNT_COLUMN_WIDTH}}>
        <MaterialField
          customerSettings={customerSettings}
          disabled={!editable}
          unit={inputUnitSymbol}
          value={countValue ?? undefined}
          onChange={onCountChange}
        />
      </TableCell>
      {showIconColumn ? (
        <TableCell style={BUTTON_COLUMN_STYLE}>
          {!autoProduct ? (
            calculator ? (
              <CalculatorButton
                disabled={!editable}
                specification={calculator}
                unit={inputUnitSymbol}
                onInsertValue={onCountChange}
              />
            ) : (
              <IconButton disabled={!editable} onClick={onPlusClick}>
                <PlusIcon />
              </IconButton>
            )
          ) : null}
        </TableCell>
      ) : null}
      <TableCell style={{width: UNIT_COLUMN_WIDTH}}>{inputUnitSymbol}</TableCell>
      {showRelationConversionColumns ? (
        <TableCell style={{width: COUNT_COLUMN_WIDTH}}>
          {relationConversionUnitSymbol ? (
            <MaterialField
              customerSettings={customerSettings}
              disabled={!editable || !relationConversionValue}
              unit={relationConversionUnitSymbol}
              value={relationCountValue ?? undefined}
              onChange={onRelationCountChange}
            />
          ) : null}
        </TableCell>
      ) : null}
      {showRelationConversionColumns ? (
        <TableCell style={{width: CONVERSION_UNIT_COLUMN_WIDTH}}>
          {relationConversionUnitSymbol}
        </TableCell>
      ) : null}
      {customerSettings.showOursToggle ? (
        <TableCell style={{width: TOGGLE_COLUMN_WIDTH}}>
          {!autoProduct ? (
            <FormControlLabel
              control={<Switch checked={ours} onChange={handleOursToggle} />}
              disabled={!editable}
              label={intl.formatMessage({defaultMessage: "Vores"})}
              labelPlacement="end"
            />
          ) : null}
        </TableCell>
      ) : null}
      {showNotes ? (
        <TableCell>
          <ThrottledTextField
            fullWidth
            disabled={!notesEditable}
            label={intl.formatMessage({defaultMessage: "Noter"})}
            margin="dense"
            value={notes}
            variant="outlined"
            onChange={onNotesChange}
          />
        </TableCell>
      ) : null}
      {onSwitchProductClick || onDeleteClick ? (
        <TableCell style={BUTTON_COLUMN_STYLE}>
          {switchProductActionMenuItem || deleteProductActionMenuItem ? (
            <>
              <IconButton
                ref={actionMenuButtonRef}
                style={{float: "right"}}
                onClick={setActionMenuOpenTrue}
              >
                <DotsHorizontalIcon />
              </IconButton>
              <Menu
                anchorEl={actionMenuButtonRef?.current}
                open={actionMenuOpen}
                onClick={setActionMenuOpenFalse}
                onClose={setActionMenuOpenFalse}
              >
                {switchProductActionMenuItem}
                {deleteProductActionMenuItem}
              </Menu>
            </>
          ) : null}
        </TableCell>
      ) : null}
    </TableRow>
  );
});

export const DisplayTableRowWithEntryCallbacks = withEntryCallbacks(DisplayTableRow);
