import {Location, LocationType, LocationUrl, ProductUrl} from "@co-common-libs/resources";
import React from "react";
import {LocationTypeMarkersWithoutMenu} from "./location-type-markers-without-menu";

interface LocationMarkersWithoutMenuProps {
  readonly filteredLocationArray: readonly Location[];
  readonly googleMapsLinks: boolean;
  readonly locationProductCounts?: ReadonlyMap<LocationUrl, ReadonlyMap<ProductUrl, number>>;
  readonly map: google.maps.Map;
  readonly selectedMarkerTypes: readonly string[];
  readonly showAsActive?: boolean;
  readonly usedLocationTypes: readonly LocationType[];
}

export function LocationMarkersWithoutMenu(props: LocationMarkersWithoutMenuProps): JSX.Element {
  const {
    filteredLocationArray,
    googleMapsLinks,
    locationProductCounts,
    map,
    selectedMarkerTypes,
    showAsActive,
    usedLocationTypes,
  } = props;

  return (
    <>
      {usedLocationTypes
        .filter((locationType) => selectedMarkerTypes.includes(locationType.identifier))
        .map((locationType) => (
          <LocationTypeMarkersWithoutMenu
            key={locationType.url}
            filteredLocationArray={filteredLocationArray}
            googleMapsLinks={googleMapsLinks}
            locationProductCounts={locationProductCounts}
            locationType={locationType}
            map={map}
            showAsActive={showAsActive}
          />
        ))}
    </>
  );
}
