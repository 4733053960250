import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import _ from "lodash";
import React from "react";
import {FormattedMessage} from "react-intl";
import {SettingValueDisplayProps} from "../types";

export function DisplayNumberRanges(props: SettingValueDisplayProps): JSX.Element | null {
  const {settingEntry} = props;

  if (!settingEntry || !settingEntry.data || !settingEntry.data.length) {
    return null;
  }

  const ranges: [number, number][] = _.orderBy(settingEntry.data, (range) => range[0]);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <FormattedMessage defaultMessage="Fra" id="display-number-ranges.from" />
          </TableCell>
          <TableCell>
            <FormattedMessage defaultMessage="Til" id="display-number-ranges.to" />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {ranges.map(([start, end], index) => (
          <TableRow key={index}>
            <TableCell>{start}</TableCell>
            <TableCell>{end}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
