import {
  KrPerLiterFuelSurchargeBasis,
  KrPerLiterFuelSurchargeSpecification,
} from "@co-common-libs/resources";
import {
  DateField,
  DecimalField,
  DialogContentHeader,
  ResponsiveDialog,
} from "@co-frontend-libs/components";
import {actions} from "@co-frontend-libs/redux";
import {Checkbox, DialogContent, Divider, FormControlLabel} from "@material-ui/core";
import {instanceURL} from "frontend-global-config";
import React, {useCallback, useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch} from "react-redux";
import {v4 as uuid} from "uuid";

interface KrPerLiterFuelSurchargeBasisDialogProps {
  onClose: () => void;
  open: boolean;
  specification: KrPerLiterFuelSurchargeSpecification;
}

export const KrPerLiterFuelSurchargeBasisDialog = React.memo(
  function KrPerLiterFuelSurchargeBasisDialog(
    props: KrPerLiterFuelSurchargeBasisDialogProps,
  ): JSX.Element {
    const {onClose, open, specification} = props;

    const {conversionFactor} = specification;

    const intl = useIntl();

    const dispatch = useDispatch();

    const [fromDate, setFromDate] = useState<string | null>(null);
    const [basePriceKrPerLiter, setBasePriceKrPerLiter] = useState<number | null>(null);

    const [priceKrPerLiterIncreaseThreshold, setPriceKrPerLiterIncreaseThreshold] = useState<
      number | null
    >(null);

    const [truncateNegativeToZero, setTruncateNegativeToZero] = useState<boolean>(true);

    const handleTruncateNegativeToZeroChange = useCallback(() => {
      setTruncateNegativeToZero(!truncateNegativeToZero);
    }, [truncateNegativeToZero]);

    const maxDigits = conversionFactor === 1 ? 5 : 8;

    useEffect(() => {
      if (open) {
        setFromDate(null);
        setBasePriceKrPerLiter(null);
        setPriceKrPerLiterIncreaseThreshold(null);
        setTruncateNegativeToZero(true);
      }
    }, [open]);

    const okDisabled = basePriceKrPerLiter === null || fromDate === null;

    const handleOk = useCallback(() => {
      if (basePriceKrPerLiter !== null && fromDate !== null) {
        const id = uuid();
        const url = instanceURL("krPerLiterFuelSurchargeBasis", id);
        const basis: KrPerLiterFuelSurchargeBasis = {
          basePriceKrPerLiter: basePriceKrPerLiter / conversionFactor,
          fromDate,
          id,
          priceKrPerLiterIncreaseThreshold:
            (priceKrPerLiterIncreaseThreshold || 0) / conversionFactor,
          specification: specification.url,
          truncateNegativeToZero,
          url,
        };
        dispatch(actions.create(basis));
        onClose();
      }
    }, [
      basePriceKrPerLiter,
      conversionFactor,
      dispatch,
      fromDate,
      onClose,
      priceKrPerLiterIncreaseThreshold,
      specification.url,
      truncateNegativeToZero,
    ]);

    return (
      <ResponsiveDialog
        requiredFieldsHelperText
        okDisabled={okDisabled}
        open={open}
        title={intl.formatMessage({defaultMessage: "Nyt grundlag"})}
        onCancel={onClose}
        onOk={handleOk}
      >
        <DialogContent>
          <DateField
            autoFocus
            autoOk
            fullWidth
            label={intl.formatMessage({defaultMessage: "Gældende fra"})}
            margin="dense"
            value={fromDate}
            onChange={setFromDate}
          />
          <Divider />
          <DialogContentHeader>
            <FormattedMessage defaultMessage="Basis" />
          </DialogContentHeader>
          <DecimalField
            fullWidth
            decimalPlaces={2}
            label={
              conversionFactor === 1
                ? intl.formatMessage({
                    defaultMessage: "Pris (kr/L) *",
                  })
                : intl.formatMessage(
                    {
                      defaultMessage: "Pris (kr/{conversionFactor, number} L) *",
                    },
                    {conversionFactor},
                  )
            }
            margin="dense"
            maxDigits={maxDigits}
            value={basePriceKrPerLiter}
            onChange={setBasePriceKrPerLiter}
          />
          <Divider />
          <DialogContentHeader>
            <FormattedMessage defaultMessage="Grundlag" />
          </DialogContentHeader>
          <DecimalField
            fullWidth
            decimalPlaces={2}
            helperText={intl.formatMessage({
              defaultMessage: "Tilladt prisstigning før kunden skal have tillagt brændstoftillæg",
            })}
            label={
              conversionFactor === 1
                ? intl.formatMessage({
                    defaultMessage: "Margin (kr/L)",
                  })
                : intl.formatMessage(
                    {
                      defaultMessage: "Margin (kr/{conversionFactor, number} L)",
                    },
                    {conversionFactor},
                  )
            }
            margin="dense"
            maxDigits={maxDigits}
            value={priceKrPerLiterIncreaseThreshold}
            onChange={setPriceKrPerLiterIncreaseThreshold}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={truncateNegativeToZero}
                onChange={handleTruncateNegativeToZeroChange}
              />
            }
            label={intl.formatMessage({
              defaultMessage: "Ved brændstofpris under basisprisen gives der ikke negativt tillæg",
            })}
          />
        </DialogContent>
      </ResponsiveDialog>
    );
  },
);
