import {Config} from "@co-common-libs/config";
import {
  DaysAbsence,
  DaysAbsenceUrl,
  HoursAbsence,
  HoursAbsenceUrl,
  Role,
} from "@co-common-libs/resources";
import {Grid} from "@material-ui/core";
import React, {useMemo} from "react";
import {useIntl} from "react-intl";
import {AbsenceBlockEditButton} from "./absence-block-edit-button";

function handleStopEventPropagation(event: React.MouseEvent<HTMLDivElement>): void {
  event.stopPropagation();
}

const titleText: Readonly<React.CSSProperties> = {
  flexGrow: 1,
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "14px",
  maxWidth: "100%",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "uppercase",
  whiteSpace: "nowrap",
};

interface AbsenceBlockTitleProps {
  absence: DaysAbsence | HoursAbsence;
  currentRole: Role | null;
  customerSettings: Config;
  onEdit?: ((url: DaysAbsenceUrl | HoursAbsenceUrl) => void) | undefined;
}

export const AbsenceBlockTitle = React.memo(function AbsenceBlockTitle(
  props: AbsenceBlockTitleProps,
) {
  const {absence, currentRole, customerSettings, onEdit} = props;

  const {absenceType} = absence;

  const intl = useIntl();

  const isManager = currentRole && currentRole.manager;

  const label = useMemo(() => {
    const absenceDisplayLabel = isManager ? null : customerSettings.absenceCalendarDisplayLabel;

    if (absenceDisplayLabel !== null) {
      return absenceDisplayLabel;
    }

    if (customerSettings.absenceTypeLabels[absenceType]) {
      return customerSettings.absenceTypeLabels[absenceType];
    }

    if (absenceType === "childsFirstSickDay") {
      return intl.formatMessage({defaultMessage: "Barns første sygedag"});
    } else if (absenceType === "compensatory") {
      return intl.formatMessage({defaultMessage: "Afspadsering"});
    } else if (absenceType === "daysOffWithoutPay") {
      return intl.formatMessage({defaultMessage: "Fridage uden løn"});
    } else if (absenceType === "floatingHoliday") {
      return intl.formatMessage({defaultMessage: "Ferie-fridage"});
    } else if (absenceType === "sickLeave") {
      return intl.formatMessage({defaultMessage: "Sygefravær"});
    } else if (absenceType === "timeOff") {
      return intl.formatMessage({defaultMessage: "FRI"});
    } else if (absenceType === "vacation") {
      return intl.formatMessage({defaultMessage: "Feriedage"});
    }

    return absenceType;
  }, [
    absenceType,
    customerSettings.absenceCalendarDisplayLabel,
    customerSettings.absenceTypeLabels,
    intl,
    isManager,
  ]);

  return (
    <Grid container direction="row" style={{maxHeight: 14}} wrap="nowrap">
      <Grid item style={titleText}>
        {label}
      </Grid>
      {onEdit ? (
        <Grid item style={{pointerEvents: "all"}} onMouseDown={handleStopEventPropagation}>
          <AbsenceBlockEditButton absence={absence} onEdit={onEdit} />
        </Grid>
      ) : null}
    </Grid>
  );
});
