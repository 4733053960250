import {MINUTE_MILLISECONDS} from "@co-common-libs/utils";
import {
  actions,
  getCommitQueueLength,
  getCurrentlyFetchingChanges,
  getLastFetchChangesErrorTimestamp,
  getLastFetchChangesTimestamp,
} from "@co-frontend-libs/redux";
import {getFrontendSentry} from "@co-frontend-libs/utils";
import type {default as BackgroundFetchPlugin} from "cordova-plugin-background-fetch";
import {globalConfig} from "frontend-global-config";
import _ from "lodash";
import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

declare const BackgroundFetch: typeof BackgroundFetchPlugin | undefined;

const CHANGES_BACKGROUND_FETCH_INTERVAL_MINUTES = 5;

let backgroundFetchConfigured = false;

export function BackgroundFetchManager(): null {
  const dispatch = useDispatch();
  const sentry = getFrontendSentry();

  const currentlyFetchingChanges = useSelector(getCurrentlyFetchingChanges);
  const lastFetchChangesTimestamp = useSelector(getLastFetchChangesTimestamp);
  const lastFetchChangesErrorTimestamp = useSelector(getLastFetchChangesErrorTimestamp);
  const lastFetchEndTimestamp =
    _.max([lastFetchChangesTimestamp, lastFetchChangesErrorTimestamp]) || null;
  const [lastFetchStartTimestamp, setLastFetchStartTimestamp] = useState<string | null>(null);
  const commitQueueLength = useSelector(getCommitQueueLength);

  const [onlineSavesCompleted, setOnlineSavesCompleted] = useState(false);
  const [fetchingChangesCompleted, setFetchingChangesCompleted] = useState(false);
  const [backgroundTaskId, setBackgroundTaskId] = useState<string | null>(null);
  const commitQueueLengthRef = useRef(commitQueueLength);
  const currentlyFetchingChangesRef = useRef(currentlyFetchingChanges);
  const lastFetchEndTimestampRef = useRef(lastFetchEndTimestamp);
  const onlineSavesCompletedRef = useRef(onlineSavesCompleted);
  const fetchingChangesCompletedRef = useRef(fetchingChangesCompleted);

  const appVersion = (window as any).APP_VERSION;
  const requiredAppVersion =
    globalConfig.cordova.pluginsRequiredAppVersion["cordova-plugin-background-fetch"];
  const pluginAvailable =
    typeof cordova !== "undefined" &&
    typeof BackgroundFetch !== "undefined" &&
    appVersion &&
    appVersion >= requiredAppVersion;

  useEffect(() => {
    commitQueueLengthRef.current = commitQueueLength;
    currentlyFetchingChangesRef.current = currentlyFetchingChanges;
    lastFetchEndTimestampRef.current = lastFetchEndTimestamp;
    onlineSavesCompletedRef.current = onlineSavesCompleted;
    fetchingChangesCompletedRef.current = fetchingChangesCompleted;
  }, [
    commitQueueLength,
    currentlyFetchingChanges,
    fetchingChangesCompleted,
    lastFetchEndTimestamp,
    onlineSavesCompleted,
  ]);

  useEffect(() => {
    if (!pluginAvailable) {
      return undefined;
    }
    if (!backgroundFetchConfigured) {
      const onEvent = (taskId: string): void => {
        setBackgroundTaskId(taskId);
        if (commitQueueLengthRef.current) {
          setOnlineSavesCompleted(false);
          dispatch(actions.startOnlineSaves());
        } else {
          setOnlineSavesCompleted(true);
        }
        const minutesSinceLastFetch = lastFetchEndTimestampRef.current
          ? (new Date().valueOf() - new Date(lastFetchEndTimestampRef.current).valueOf()) /
            MINUTE_MILLISECONDS
          : 0;
        if (
          currentlyFetchingChangesRef.current ||
          minutesSinceLastFetch > CHANGES_BACKGROUND_FETCH_INTERVAL_MINUTES
        ) {
          setFetchingChangesCompleted(false);
          if (currentlyFetchingChangesRef.current) {
            setLastFetchStartTimestamp(lastFetchEndTimestampRef.current);
          } else {
            setLastFetchStartTimestamp(new Date().toISOString());
            dispatch(actions.requestChangesFetch());
          }
        } else {
          setFetchingChangesCompleted(true);
        }
      };
      const onTimeout = (taskId: string): void => {
        setBackgroundTaskId(null);
        BackgroundFetch.finish(taskId);
      };
      backgroundFetchConfigured = true;
      BackgroundFetch.configure(
        {
          requiredNetworkType: BackgroundFetch.NETWORK_TYPE_ANY,
        },
        onEvent,
        onTimeout,
      ).catch((error) => sentry.captureException(error));
    }
    return undefined;
  }, [dispatch, pluginAvailable, sentry]);

  useEffect(() => {
    if (!pluginAvailable || !backgroundFetchConfigured) {
      return undefined;
    }
    if (backgroundTaskId) {
      if (commitQueueLength === 0) {
        setOnlineSavesCompleted(true);
      }
      if (
        lastFetchEndTimestamp &&
        (!lastFetchStartTimestamp || lastFetchStartTimestamp < lastFetchEndTimestamp)
      ) {
        setFetchingChangesCompleted(true);
      }
    }
    return undefined;
  }, [
    backgroundTaskId,
    commitQueueLength,
    lastFetchEndTimestamp,
    lastFetchStartTimestamp,
    pluginAvailable,
    sentry,
  ]);

  useEffect(() => {
    if (!pluginAvailable || !backgroundFetchConfigured) {
      return undefined;
    }
    if (backgroundTaskId && onlineSavesCompleted && fetchingChangesCompleted) {
      setBackgroundTaskId(null);
      BackgroundFetch.finish(backgroundTaskId);
    }
    return () => {
      if (backgroundTaskId) {
        setBackgroundTaskId(null);
        BackgroundFetch.finish(backgroundTaskId);
      }
    };
  }, [backgroundTaskId, fetchingChangesCompleted, onlineSavesCompleted, pluginAvailable]);
  return null;
}
