import {Config} from "@co-common-libs/config";
import {TableCell, TableRow, TableRowProps} from "@material-ui/core";
import React from "react";
import {FormattedNumber} from "react-intl";

interface ResultRowProps {
  correctedTotal: number;
  customerSettings: Config;
  name: string;
  total: number;
  unit: string;
  workplace: string;
}

const ResultRow = React.memo(function ResultRow(props: Partial<TableRowProps> & ResultRowProps) {
  const {correctedTotal, customerSettings, name, total, unit, workplace, ...others} = props;
  const workplaceCell = customerSettings.orderEntryResultsWorkplace ? (
    <TableCell>{workplace}</TableCell>
  ) : null;
  return (
    <TableRow {...others}>
      <TableCell>{name}</TableCell>
      {workplaceCell}
      <TableCell>
        <FormattedNumber maximumFractionDigits={2} value={total} />
      </TableCell>
      <TableCell>
        <FormattedNumber maximumFractionDigits={2} value={correctedTotal} />
      </TableCell>
      <TableCell>{unit}</TableCell>
    </TableRow>
  );
});

export default ResultRow;
