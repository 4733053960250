import {Command, Order, PatchOperation, Task} from "@co-common-libs/resources";
import {
  machinePotentialPriceGroups,
  recomputePriceGroup,
  workTypePotentialPriceGroups,
} from "@co-common-libs/resources-utils";
import _ from "lodash";
import {ProvisionaryCommand} from "../../resources/actions";
import {
  getMachineLookup,
  getPriceGroupLookup,
  getTaskArray,
  getWorkTypeLookup,
} from "../../resources/selectors";
import {ResourcesAuthenticationMiddlewareAPI} from "../types";

export function updateTaskPriceGroupsOnOrderCustomerChange(
  newOrder: Order | null,
  oldOrder: Order | undefined,
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  command: ProvisionaryCommand,
): {after?: Command[]; before?: Command[]} | null {
  if (!newOrder) {
    return null;
  }
  if (!newOrder.customer || newOrder.customer === oldOrder?.customer) {
    // only do things on *setting* customer
    return null;
  }
  const state = middlewareApi.getState();

  const taskArray = getTaskArray(state);
  const priceGroupLookup = getPriceGroupLookup(state);
  const workTypeLookup = getWorkTypeLookup(state);
  const machineLookup = getMachineLookup(state);

  const orderURL = command.url;

  const orderTaskArray = taskArray.filter((task) => task.order === orderURL);

  const taskChanges: Command[] = [];

  orderTaskArray.forEach((task) => {
    if (task.recordedInC5 || task.archivable) {
      return;
    }
    const patch: PatchOperation<Task>[] = [];
    if (task.workType) {
      const potentialPriceGroups = workTypePotentialPriceGroups(
        task.workType,
        newOrder.customer || null,
        workTypeLookup,
        priceGroupLookup,
      );
      const currentPriceGroup = task.priceGroup ? priceGroupLookup(task.priceGroup) : null;
      const priceGroup = recomputePriceGroup(potentialPriceGroups, currentPriceGroup || null);
      const priceGroupURL = priceGroup?.url || null;
      if (priceGroupURL !== task.priceGroup) {
        patch.push({member: "priceGroup", value: priceGroupURL});
      }
    }
    if (task.machineuseSet && task.machineuseSet.length) {
      const machineuseSet = task.machineuseSet.map((machineUse) => {
        const potentialPriceGroups = machinePotentialPriceGroups(
          machineUse.machine,
          newOrder.customer || null,
          machineLookup,
          priceGroupLookup,
        );
        const currentPriceGroup = machineUse.priceGroup
          ? priceGroupLookup(machineUse.priceGroup)
          : null;
        const priceGroup = recomputePriceGroup(potentialPriceGroups, currentPriceGroup || null);
        const priceGroupURL = priceGroup?.url || null;
        if (priceGroupURL === machineUse.priceGroup) {
          return machineUse;
        }
        return {...machineUse, priceGroup: priceGroupURL};
      });
      if (!_.isEqual(machineuseSet, task.machineuseSet)) {
        patch.push({member: "machineuseSet", value: machineuseSet});
      }
    }
    if (patch.length) {
      taskChanges.push({
        action: "UPDATE",
        patch,
        url: task.url,
      });
    }
  });
  if (taskChanges.length) {
    return {
      after: taskChanges,
    };
  } else {
    return null;
  }
}
