import {
  PricePercentFuelSurchargeSpecification,
  PricePercentFuelSurchargeSpecificationEntry,
  PricePercentFuelSurchargeSpecificationEntryUrl,
} from "@co-common-libs/resources";
import {findFuelSurchargeBasis} from "@co-common-libs/resources-utils";
import {dateFromString} from "@co-common-libs/utils";
import {getPricePercentFuelSurchargeBasisArray} from "@co-frontend-libs/redux";
import {TableCell, TableRow} from "@material-ui/core";
import React, {useCallback} from "react";
import {FormattedDate, FormattedNumber} from "react-intl";
import {useSelector} from "react-redux";

interface PricePercentFuelSurchargeSpecificationEntryRowProps {
  entry: PricePercentFuelSurchargeSpecificationEntry;
  onEntryClick: (url: PricePercentFuelSurchargeSpecificationEntryUrl) => void;
  specification: PricePercentFuelSurchargeSpecification;
}

export const PricePercentFuelSurchargeSpecificationEntryRow = React.memo(
  function PricePercentFuelSurchargeSpecificationEntryRow(
    props: PricePercentFuelSurchargeSpecificationEntryRowProps,
  ): JSX.Element {
    const {entry, onEntryClick, specification} = props;

    const {fromDate, priceKrPerLiter, toDate, url} = entry;
    const {conversionFactor} = specification;

    const pricePercentFuelSurchargeBasisArray = useSelector(getPricePercentFuelSurchargeBasisArray);

    const basis = findFuelSurchargeBasis(
      pricePercentFuelSurchargeBasisArray,
      specification.url,
      fromDate,
    );

    const basePriceKrPerLiter = basis?.basePriceKrPerLiter ?? 0;
    const fuelCostSharePercent = basis?.fuelCostSharePercent ?? 0;

    const relativeIncrease = (priceKrPerLiter - basePriceKrPerLiter) / basePriceKrPerLiter;
    const rate = fuelCostSharePercent * relativeIncrease;

    const handleEntryClick = useCallback(() => {
      onEntryClick(url);
    }, [onEntryClick, url]);

    return (
      <TableRow onClick={handleEntryClick}>
        <TableCell>
          <FormattedDate value={dateFromString(fromDate) as Date} />
        </TableCell>
        <TableCell>
          <FormattedDate value={dateFromString(toDate) as Date} />
        </TableCell>
        <TableCell>
          <FormattedNumber
            maximumFractionDigits={2}
            minimumFractionDigits={2}
            value={priceKrPerLiter * conversionFactor}
          />
        </TableCell>
        <TableCell>
          <FormattedNumber maximumFractionDigits={2} minimumFractionDigits={2} value={rate} />
        </TableCell>
      </TableRow>
    );
  },
);
