import {
  getActiveEmployeeGroupArray,
  getCustomerSettings,
  getWorkTypeArray,
} from "@co-frontend-libs/redux";
import {matchingTextColor} from "@co-frontend-libs/utils";
import {SvgIcon, useTheme} from "@material-ui/core";
import _ from "lodash";
import CloseIcon from "mdi-react/CloseIcon";
import React, {useMemo} from "react";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";

const ICON_PADDING = 2;
const ICON_SIZE = 24;

export const FILTER_BAR_HEIGHT = 24;
export const FilterBar = React.memo(function FilterBar({
  onRequestFilterClear,
  selectedDepartmentIdentifierSet,
  selectedEmployeeGroupURLSet,
  selectedWorkTypeURLSet,
}: {
  onRequestFilterClear: () => void;
  selectedDepartmentIdentifierSet?: ReadonlySet<string> | undefined;
  selectedEmployeeGroupURLSet?: ReadonlySet<string> | undefined;
  selectedWorkTypeURLSet?: ReadonlySet<string> | undefined;
}): JSX.Element {
  const workTypeArray = useSelector(getWorkTypeArray);
  const customerSettings = useSelector(getCustomerSettings);
  const activeEmployeeGroupArray = useSelector(getActiveEmployeeGroupArray);
  const theme = useTheme();
  const selectedWorkTypeSetString = selectedWorkTypeURLSet
    ? _.sortBy(
        workTypeArray.filter((instance) => selectedWorkTypeURLSet.has(instance.url)),
        (instance) => `${instance.identifier}: ${instance.name}`,
      )
        .map((instance) => `${instance.name} (${instance.identifier})`)
        .join(", ")
    : "";
  const selectedEmployeeGroupSetString = selectedEmployeeGroupURLSet
    ? _.sortBy(
        activeEmployeeGroupArray.filter((employeeGroup) =>
          selectedEmployeeGroupURLSet?.has(employeeGroup.url),
        ),
        (employeeGroup) => `${employeeGroup.name}`,
      )
        .map((employeeGroup) => `${employeeGroup.name}`)
        .join(", ")
    : "";

  const selectedDepartmentSetString = useMemo(() => {
    if (!selectedDepartmentIdentifierSet) {
      return "";
    }

    const names = [...selectedDepartmentIdentifierSet].map(
      (department) => customerSettings.departments[department],
    );
    names.sort();
    return names.join(", ");
  }, [customerSettings.departments, selectedDepartmentIdentifierSet]);

  const style: React.CSSProperties = {
    backgroundColor: theme.palette.warning.main,
    color: matchingTextColor(theme.palette.warning.main),
    height: FILTER_BAR_HEIGHT,
    overflow: "hidden",
    paddingLeft: 2,
    paddingRight: ICON_PADDING + ICON_SIZE + ICON_PADDING,
    position: "relative",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };
  return (
    <div style={style}>
      {selectedDepartmentSetString ? (
        <span
          style={selectedDepartmentSetString && selectedWorkTypeSetString ? {paddingRight: 5} : {}}
        >
          <FormattedMessage defaultMessage="Afd.:" />
          &nbsp;
          {selectedDepartmentSetString}.
        </span>
      ) : null}
      {selectedEmployeeGroupSetString && (
        <>
          <FormattedMessage defaultMessage="Medarb.gr.:" />
          &nbsp;
          {selectedEmployeeGroupSetString}.
        </>
      )}
      {selectedWorkTypeSetString ? (
        <>
          <FormattedMessage defaultMessage="Omr.:" />
          &nbsp;
          {selectedWorkTypeSetString}.
        </>
      ) : null}
      <SvgIcon
        style={{cursor: "pointer", position: "absolute", right: 2}}
        onClick={onRequestFilterClear}
      >
        <CloseIcon />
      </SvgIcon>
    </div>
  );
});
