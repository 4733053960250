import {Config} from "@co-common-libs/config";
import {
  ReportingInputSpecification,
  ReportingLocations,
  ReportingSpecification,
  Task,
} from "@co-common-libs/resources";
import {
  computeLoads,
  computeLogSums,
  getInputSpecificationsMap,
  getLogTotalsSpec,
  getValue,
} from "@co-common-libs/resources-utils";
import {sortByOrderMember} from "@co-common-libs/utils";
import {getCustomerSettings} from "@co-frontend-libs/redux";
import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import {DisplayWorkplaceTotalsTable} from "./display-workplace-totals-table";

const getLocationSummedIdentifierLabels = (
  logSpecification: ReportingSpecification,
): Map<string, string> => {
  const summedLabels = new Map<string, string>();
  (["workplace", "pickup", "delivery"] as const).forEach((type) => {
    const workplaceData = logSpecification.workplaceData[type];
    const worktypeLocationInputSpecifications = workplaceData && workplaceData.inputs;
    if (worktypeLocationInputSpecifications) {
      worktypeLocationInputSpecifications.forEach((inputSpecification) => {
        if (!inputSpecification.sum) {
          return;
        }
        const {identifier} = inputSpecification;
        let {label} = inputSpecification;
        const {unit} = inputSpecification;
        if (unit) {
          label = `${label}, ${unit}`;
        }
        summedLabels.set(identifier, label);
      });
    }
  });
  return summedLabels;
};

function computeLocationSums(
  customerSettings: Config,
  reportingLocations: ReportingLocations,
  locationSummedIdentifierLabels: ReadonlyMap<string, string>,
  inputSpecificationsMap: ReadonlyMap<string, ReportingInputSpecification>,
): Map<string, number> {
  const locationSums = new Map<string, number>();
  locationSummedIdentifierLabels.forEach((_label, identifier) => {
    locationSums.set(identifier, 0);
  });
  sortByOrderMember(
    Object.values(reportingLocations).filter((entry) => entry.type === "workplace"),
  ).forEach((data) => {
    const {values} = data;
    const valueMaps = values ? [values] : [];
    const localGetValue = getValue.bind(null, customerSettings, valueMaps, inputSpecificationsMap);
    locationSummedIdentifierLabels.forEach((_label, identifier) => {
      const value = localGetValue(identifier);
      if (typeof value === "number") {
        locationSums.set(identifier, (locationSums.get(identifier) || 0) + value);
      }
    });
  });
  return locationSums;
}

interface WorkplaceTotalsTableProps {
  logSpecification: ReportingSpecification;
  showLoadCounts: boolean;
  task: Task;
}

export const WorkplaceTotalsTable = React.memo(function WorkplaceTotalsTable(
  props: WorkplaceTotalsTableProps,
): JSX.Element {
  const {logSpecification, showLoadCounts, task} = props;

  const customerSettings = useSelector(getCustomerSettings);

  const inputSpecificationsMap = useMemo(
    () => getInputSpecificationsMap(logSpecification),
    [logSpecification],
  );

  const [logSummedIdentifierLabels /* logTransferUnits */] = useMemo(
    () => getLogTotalsSpec(logSpecification),
    [logSpecification],
  );
  const locationSummedIdentifierLabels = useMemo(
    () => getLocationSummedIdentifierLabels(logSpecification),
    [logSpecification],
  );

  let sums: Map<string, number>;
  let locationSums: Map<string, number>;
  if (task.reportingLocations && logSummedIdentifierLabels.size) {
    sums = computeLogSums(
      customerSettings,
      task.reportingLog || {},
      task.reportingLocations,
      logSummedIdentifierLabels,
      inputSpecificationsMap,
    );
  } else {
    sums = new Map();
  }
  if (task.reportingLocations && locationSummedIdentifierLabels.size) {
    locationSums = computeLocationSums(
      customerSettings,
      task.reportingLocations,
      locationSummedIdentifierLabels,
      inputSpecificationsMap,
    );
  } else {
    locationSums = new Map();
  }
  const productUseList = sortByOrderMember(Object.values(task.productUses || {}));
  const priceItemUseList = sortByOrderMember(Object.values(task.priceItemUses || {}));
  let loads: Map<string, number> | undefined;
  if (showLoadCounts) {
    loads = computeLoads(task.reportingLog);
  }
  return (
    <DisplayWorkplaceTotalsTable
      loads={loads}
      locationSummedIdentifierLabels={locationSummedIdentifierLabels}
      locationSums={locationSums}
      priceItemUseList={priceItemUseList}
      productUseList={productUseList}
      summedIdentifierLabels={logSummedIdentifierLabels}
      sums={sums}
    />
  );
});
