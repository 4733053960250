import {Role, UserProfile} from "@co-common-libs/resources";
import {
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  FormGroup,
  Switch,
  useTheme,
} from "@material-ui/core";
import {useEventTargetCheckedUpdater} from "app-utils";
import React from "react";
import {useIntl} from "react-intl";

export const ConsultantCard = React.memo(function ConsultantCard({
  userProfile,
  userRoles,
}: {
  userProfile: UserProfile;
  userRoles: Role;
}): JSX.Element {
  const {canUnapproveTasks, owner} = userRoles;
  const intl = useIntl();
  const theme = useTheme();
  return (
    <Card style={{backgroundColor: theme.palette.consultant.main}}>
      <CardHeader title={intl.formatMessage({defaultMessage: "Konsulent"})} />
      <CardContent>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={owner}
                onChange={useEventTargetCheckedUpdater<Role>(userRoles.url, "owner")}
              />
            }
            label={intl.formatMessage({
              defaultMessage: "Databehandler",
            })}
          />
          <FormControlLabel
            control={
              <Switch
                checked={canUnapproveTasks}
                onChange={useEventTargetCheckedUpdater<Role>(userRoles.url, "canUnapproveTasks")}
              />
            }
            label={intl.formatMessage({
              defaultMessage: "Kan afgodkende opgaver",
            })}
          />
          <FormControlLabel
            control={
              <Switch
                checked={userProfile.dumpSyncQueue}
                onChange={useEventTargetCheckedUpdater<UserProfile>(
                  userProfile.url,
                  "dumpSyncQueue",
                )}
              />
            }
            label={intl.formatMessage({
              defaultMessage: "Debug-upload af synkroniseringskø",
            })}
          />
        </FormGroup>
      </CardContent>
    </Card>
  );
});
