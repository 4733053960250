import {createAsyncThunk} from "@reduxjs/toolkit";
import {getAll, put, remove} from "./local-db";

export const configLoad = createAsyncThunk("device-config/configLoad", () => getAll());

export const configPut = createAsyncThunk(
  "device-config/configPut",
  ({key, value}: {key: string; value: any}) => put(key, value),
);

export const configRemove = createAsyncThunk("device-config/configRemove", ({key}: {key: string}) =>
  remove(key),
);

export const putTableSortingState = (
  identifier: string,
  sortKey: string,
  sortDirection: "ASC" | "DESC",
): ReturnType<typeof configPut> => {
  const key = `tableSort-${identifier}`;
  const value = {sortDirection, sortKey};
  return configPut({key, value});
};

export const putTablePaginationState = (
  identifier: string,
  rowsPerPage: number,
): ReturnType<typeof configPut> => {
  const key = `tablePagination-${identifier}`;
  const value = {rowsPerPage};
  return configPut({key, value});
};
