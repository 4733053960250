import {Customer, CustomerUrl} from "@co-common-libs/resources";
import {TranslatedError} from "@co-common-libs/utils";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {actions} from "@co-frontend-libs/redux";
import {translateNetworkError, useCallWithFalse, useResettingState} from "@co-frontend-libs/utils";
import {DialogContent} from "@material-ui/core";
import {simpleObjectReducer, useResetObjectOnCondition} from "app-utils";
import React, {useCallback, useReducer} from "react";
import {useIntl} from "react-intl";
import {batch, useDispatch} from "react-redux";
import {CustomerFormWithCvrAutocomplete} from "../../customer-form/customer-form-with-cvr-autocomplete";
import {hasMissingOrInvalidInputs} from "../../customer-form/has-missing-or-invalid-inputs";
import {transformToCustomerFormPart} from "../../customer-form/transform-to-customer-form-part";
import {CustomerFormPart} from "../../customer-form/types";
import {useCustomerFormErrorsAndWarnings} from "../../customer-form/use-customer-form-errors-and-warnings";
import {useCustomerFormInputDefinitions} from "../../customer-form/use-customer-form-input-definitions";
import {ProcessingDialog} from "../../processing-dialog";
import {
  requestCreateExistingCustomerInEconomic,
  requestCreateNewCustomerInEconomic,
} from "./economic-requests";

function isExistingCustomer(
  customer: Customer | Partial<CustomerFormPart> | undefined,
): customer is Customer {
  return !!customer && Object.hasOwn(customer, "url");
}

interface EconomicCustomerCreateDialogProps {
  customer?: Customer | Partial<CustomerFormPart>;
  onCancel: () => void;
  onSuccess: (customerURL: CustomerUrl) => void;
  open: boolean;
}

export const EconomicCustomerCreateDialog = React.memo(function EconomicCustomerCreateDialog(
  props: EconomicCustomerCreateDialogProps,
): JSX.Element {
  const {customer, onCancel, onSuccess, open} = props;

  const [savingPromise, setSavingPromise] = useResettingState<Promise<Customer> | null>(null, open);
  const [saveInProgress, setSaveInProgress] = useResettingState<boolean>(false, open);
  const setSaveInProgressFalse = useCallWithFalse(setSaveInProgress);

  const [formCustomer, formDispatch] = useReducer(
    simpleObjectReducer<CustomerFormPart>,
    customer,
    transformToCustomerFormPart,
  );
  useResetObjectOnCondition(customer, formDispatch, open, transformToCustomerFormPart);
  const editCustomer = isExistingCustomer(customer) ? customer : null;

  const inputDefinitions = useCustomerFormInputDefinitions(editCustomer);
  const errorsAndWarnings = useCustomerFormErrorsAndWarnings(
    formCustomer,
    editCustomer,
    inputDefinitions,
  );

  const dispatch = useDispatch();
  const intl = useIntl();

  const handleOk = useCallback(() => {
    const request = editCustomer
      ? requestCreateExistingCustomerInEconomic({
          ...formCustomer,
          url: editCustomer.url,
        })
      : requestCreateNewCustomerInEconomic(formCustomer);

    setSavingPromise(
      request
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          throw new TranslatedError(translateNetworkError(error, intl));
        }),
    );

    setSaveInProgress(true);
  }, [editCustomer, formCustomer, intl, setSaveInProgress, setSavingPromise]);

  const handleCreateSuccess = useCallback(
    (data: Customer) => {
      batch(() => {
        dispatch(actions.addToOffline(data));
        onSuccess(data.url);
      });
    },
    [dispatch, onSuccess],
  );

  return (
    <>
      {savingPromise ? (
        <ProcessingDialog
          processPromise={savingPromise}
          title={intl.formatMessage({defaultMessage: "Opretter kunde"})}
          onClose={setSaveInProgressFalse}
          onSuccess={handleCreateSuccess}
        />
      ) : null}
      <ResponsiveDialog
        okDisabled={hasMissingOrInvalidInputs(formCustomer, inputDefinitions, errorsAndWarnings)}
        open={open && !saveInProgress}
        title={
          customer
            ? intl.formatMessage({defaultMessage: "Redigér kunde"})
            : intl.formatMessage({defaultMessage: "Opret kunde"})
        }
        onCancel={onCancel}
        onOk={handleOk}
      >
        <DialogContent>
          <CustomerFormWithCvrAutocomplete
            formCustomer={formCustomer}
            formDispatch={formDispatch}
            {...inputDefinitions}
            {...errorsAndWarnings}
          />
        </DialogContent>
      </ResponsiveDialog>
    </>
  );
});
