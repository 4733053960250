import {useWindowSize} from "@co-frontend-libs/utils";
import {ListItem, ListItemIcon, ListItemText, Theme, useTheme} from "@material-ui/core";
import bowser from "bowser";
import CloseIcon from "mdi-react/CloseIcon";
import PlusIcon from "mdi-react/PlusIcon";
import React, {useCallback, useEffect, useMemo, useRef} from "react";
import {
  ListChildComponentProps,
  ListOnScrollProps,
  VariableSizeList,
  VariableSizeListProps,
} from "react-window";
import {typedMemo} from "../../types";
import {useListStyles} from "../styles";
import {MaybeSearchResultEntryData} from "../types";
import {SingleSelectionListItem} from "./single-selection-list-item";

type SpecialEntry = "-ADD-" | "-NONE-";

const listItemTopPadding = 8;
const listItemBottomPadding = 8;
const listItemTextTopMargin = 6;
const listItemTextBottomMargin = 6;

const itemTotalMarginPadding =
  listItemTopPadding + listItemBottomPadding + listItemTextTopMargin + listItemTextBottomMargin;

const primaryLineHeight = 24; // uses theme.typography.body1
const secondaryLineHeight = 20; // uses theme.typography.body2

interface RenderRowData<Identifier extends string> extends ListChildComponentProps {
  data: {
    readonly addLabel: string;
    readonly combinedEntries: readonly (MaybeSearchResultEntryData<Identifier> | SpecialEntry)[];
    readonly disableSecondaryText: boolean;
    readonly highlightClass: string;
    readonly noneLabel: string;
    readonly onAdd?: (() => void) | undefined;
    readonly onNone?: (() => void) | undefined;
    readonly onSelect: (identifier: Identifier) => void;
    readonly primaryTextClass?: string | undefined;
    readonly secondaryTextClass?: string | undefined;
    readonly theme: Theme;
    readonly withIcons: boolean;
  };
}

function renderRow<Identifier extends string>(rowProps: RenderRowData<Identifier>): JSX.Element {
  const {data, index, style} = rowProps;
  const {
    addLabel,
    combinedEntries,
    disableSecondaryText,
    highlightClass,
    noneLabel,
    onAdd,
    onNone,
    onSelect,
    primaryTextClass,
    secondaryTextClass,
    theme,
    withIcons,
  } = data;
  const entry = combinedEntries[index];
  if (entry === "-NONE-") {
    return (
      <ListItem key="-NONE-" button style={style} onClick={onNone as () => void}>
        <ListItemIcon>
          <CloseIcon />
        </ListItemIcon>
        <ListItemText primary={noneLabel} />
      </ListItem>
    );
  } else if (entry === "-ADD-") {
    return (
      <ListItem
        key="-ADD-"
        button
        style={{
          borderBottom: `1px solid ${theme.palette.divider}`,
          ...style,
        }}
        onClick={onAdd as () => void}
      >
        <ListItemIcon>
          <PlusIcon />
        </ListItemIcon>
        <ListItemText primary={addLabel} />
      </ListItem>
    );
  } else {
    const {category, color, disabled, highlight, identifier, matches, primaryText, secondaryText} =
      entry;
    return (
      <SingleSelectionListItem
        key={identifier}
        category={category}
        color={color}
        disabled={disabled}
        itemClass={highlight ? highlightClass : undefined}
        matches={matches}
        primaryText={primaryText}
        primaryTextClass={primaryTextClass}
        secondaryText={disableSecondaryText ? undefined : secondaryText}
        secondaryTextClass={secondaryTextClass}
        style={style}
        value={identifier}
        withIcons={withIcons}
        onSelect={onSelect}
      />
    );
  }
}

interface SingleSelectionListProps<Identifier extends string> {
  addLabel: string;
  entries: readonly MaybeSearchResultEntryData<Identifier>[];
  fullscreenLayout?: boolean;
  isSearchResult: boolean;

  mobilePrimaryLines: 1 | 2;
  mobileSearchPrimaryLines: 1 | 2;
  mobileSearchSecondaryLines: 1 | 2;
  mobileSecondaryLines: 0 | 1 | 2;

  noneLabel: string;
  onAdd?: (() => void) | undefined;
  onNone?: (() => void) | undefined;
  onScroll?: (props: ListOnScrollProps) => void;
  onSelect(identifier: string): void;
  withIcons: boolean;
}

export const SingleSelectionList = typedMemo(function SingleSelectionList<
  Identifier extends string,
>(props: SingleSelectionListProps<Identifier>): JSX.Element {
  const {
    addLabel,
    entries,
    fullscreenLayout,
    isSearchResult,
    mobilePrimaryLines,
    mobileSearchPrimaryLines,
    mobileSearchSecondaryLines,
    mobileSecondaryLines,
    noneLabel,
    onAdd,
    onNone,
    onScroll,
    onSelect,
    withIcons,
  } = props;

  const isMobile = !!bowser.mobile;

  const theme = useTheme();

  const combinedEntries: readonly (MaybeSearchResultEntryData<Identifier> | SpecialEntry)[] =
    useMemo(() => {
      if ((onNone && noneLabel) || (onAdd && addLabel)) {
        const result: (MaybeSearchResultEntryData<Identifier> | SpecialEntry)[] = entries.slice();
        if (onNone && noneLabel) {
          result.unshift("-NONE-");
        }
        if (onAdd && addLabel) {
          result.unshift("-ADD-");
        }
        return result;
      } else {
        return entries;
      }
    }, [addLabel, entries, noneLabel, onAdd, onNone]);

  const handleAdd = useCallback(() => {
    if (onAdd) {
      onAdd();
    }
  }, [onAdd]);

  const handleNone = useCallback(() => {
    if (onNone) {
      onNone();
    }
  }, [onNone]);

  const primaryLines = isMobile
    ? isSearchResult
      ? mobileSearchPrimaryLines
      : mobilePrimaryLines
    : 1;
  const secondaryLines = isMobile
    ? isSearchResult
      ? mobileSearchSecondaryLines
      : mobileSecondaryLines
    : 1;

  const variableSizeListRef = useRef<VariableSizeList | null>(null);

  useEffect(() => {
    if (variableSizeListRef.current) {
      variableSizeListRef.current.resetAfterIndex(0);
    }
  }, [primaryLines, secondaryLines, isSearchResult]);

  const getItemSize = useCallback(
    (index: number): number => {
      const entry = combinedEntries[index];
      if (entry === "-NONE-" || entry === "-ADD-") {
        const dividerBorder = entry === "-ADD-" ? 1 : 0;
        return itemTotalMarginPadding + primaryLineHeight + dividerBorder;
      } else if (entry.matches || entry.secondaryText) {
        return (
          itemTotalMarginPadding +
          primaryLines * primaryLineHeight +
          secondaryLines * secondaryLineHeight
        );
      } else {
        return itemTotalMarginPadding + primaryLines * primaryLineHeight;
      }
    },
    [combinedEntries, primaryLines, secondaryLines],
  );

  const getItemKey = useCallback(
    (index: number): string => {
      const entry = combinedEntries[index];
      if (entry === "-NONE-" || entry === "-ADD-") {
        return entry;
      } else {
        return entry.identifier;
      }
    },
    [combinedEntries],
  );

  const classes = useListStyles();

  /* eslint-disable @typescript-eslint/naming-convention */
  const linesClasses = {
    0: undefined,
    1: classes.overflowOne,
    2: classes.overflowTwo,
  } as const;
  /* eslint-enable @typescript-eslint/naming-convention */

  const primaryTextClass = isMobile
    ? isSearchResult
      ? linesClasses[mobileSearchPrimaryLines]
      : linesClasses[mobilePrimaryLines]
    : classes.overflowOne;

  const secondaryTextClass = isMobile
    ? isSearchResult
      ? linesClasses[mobileSearchSecondaryLines]
      : linesClasses[mobileSecondaryLines]
    : classes.overflowOne;

  const disableSecondaryText = isMobile && !isSearchResult && !mobileSecondaryLines;

  let listWidth = 0;
  let listHeight = 0;

  const {windowInnerHeight, windowInnerWidth} = useWindowSize();

  if (fullscreenLayout) {
    listWidth = windowInnerWidth;
    const headerHeight = 56;
    listHeight = windowInnerHeight - headerHeight;
  } else {
    const dialogMargin = 32;
    const dialogMaxWidth = 960;
    listWidth = Math.min(dialogMaxWidth, windowInnerWidth - (dialogMargin + dialogMargin));
    const dialogMaxHeight = windowInnerHeight - (dialogMargin + dialogMargin);
    const headerHeight = 116;
    const footerHeight = 52;
    const dividerLineHeight = 1;
    const listMaxHeight =
      dialogMaxHeight - (headerHeight + footerHeight + dividerLineHeight + dividerLineHeight);
    for (let i = 0; i < combinedEntries.length && listHeight < listMaxHeight; i += 1) {
      listHeight += getItemSize(i);
    }
    listHeight = Math.min(listHeight, listMaxHeight);
  }

  const itemData: RenderRowData<Identifier>["data"] = {
    addLabel,
    combinedEntries,
    disableSecondaryText,
    highlightClass: classes.highlight,
    noneLabel,
    onAdd: onAdd ? handleAdd : undefined,
    onNone: onNone ? handleNone : undefined,
    onSelect,
    primaryTextClass,
    secondaryTextClass,
    theme,
    withIcons,
  };

  const optionalProps: Partial<VariableSizeListProps> = {};
  if (onScroll) {
    optionalProps.onScroll = onScroll;
  }

  return (
    <div>
      <VariableSizeList
        ref={variableSizeListRef}
        estimatedItemSize={
          itemTotalMarginPadding +
          primaryLines * primaryLineHeight +
          secondaryLines * secondaryLineHeight
        }
        height={listHeight}
        itemCount={combinedEntries.length}
        itemData={itemData}
        itemKey={getItemKey}
        itemSize={getItemSize}
        width={listWidth}
        {...optionalProps}
      >
        {renderRow}
      </VariableSizeList>
    </div>
  );
});
