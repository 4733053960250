import {
  CustomerUrl,
  LocationUrl,
  MachineGroupUrl,
  MachineUrl,
  OrderUrl,
  PriceGroupUrl,
  PriceItemUrl,
  ProductUrl,
  ProjectUrl,
  ReportingSpecificationUrl,
  RouteTaskUrl,
  TaskUrl,
  TimerUrl,
  UserUrl,
  WorkTypeUrl,
  WorkshopChecklistItemUrl,
} from "../resource-urls";
import {ResourceInstance} from "./base";

export interface MachineUse {
  readonly machine: MachineUrl;
  readonly priceGroup: PriceGroupUrl | null;
  readonly transporter: boolean;
}

export interface ProductUse {
  readonly addedBy: UserUrl | null;
  readonly correctedCount: number | null;
  readonly count: number | null;
  readonly notes: string;
  readonly ours: boolean;
  readonly product: ProductUrl;
}

export interface ProductUseWithOrder extends ProductUse {
  readonly order: number;
}

export interface PriceItemUse {
  readonly correctedCount: number | null;
  readonly count: number | null;
  readonly dangling: boolean;
  readonly machine: MachineUrl | null;
  readonly notes: string;
  readonly priceGroup: PriceGroupUrl | null;
  readonly priceItem: PriceItemUrl;
  readonly timer: TimerUrl | null;
  readonly workType: WorkTypeUrl | null;
}

export interface PriceItemUseWithOrder extends PriceItemUse {
  readonly order: number;
}

export interface PriceItemUsesDict {
  readonly [identifier: string]: PriceItemUseWithOrder;
}

export interface ProductUsesDict {
  readonly [identifier: string]: ProductUseWithOrder;
}

export interface WorkshopChecklistAnswer {
  readonly checklistItem: WorkshopChecklistItemUrl;
  readonly value: number | null;
}

export interface FieldUse {
  readonly fieldAreaHa: number | null;
  readonly fieldCrop: string;
  readonly geojson: any | null;
  readonly notes: string;
  readonly relatedField: LocationUrl;
}

export interface ComputedTime {
  readonly fromTimestamp: string;
  readonly timer: TimerUrl;
  readonly toTimestamp: string;
}

export interface TimeCorrection {
  readonly fromTimestamp: string;
  readonly timer: TimerUrl | null;
  readonly toTimestamp: string;
}

export interface TimerNotes {
  readonly notes: string;
  readonly timer: TimerUrl;
}

// value types:
// boolean | text | integer (number) | decimal (number) |
// choice (identifier string) | multiple choice (identifier string array)
export interface ReportingData {
  readonly [identifier: string]: boolean | number | string | readonly string[] | undefined;
}

export interface ReportingLocation {
  readonly customer: CustomerUrl;
  // may be missing in old data; should always be provided for new data
  readonly location?: LocationUrl;
  readonly order: number;
  readonly productUses?: ProductUsesDict;
  readonly type: "delivery" | "pickup" | "workplace";
  readonly values?: {readonly [identifier: string]: unknown};
}
export interface ReportingLocations {
  readonly [identifier: string]: ReportingLocation;
}

export interface ReportingLogEntry {
  readonly deviceTimestamp: string;
  readonly location: string;
  readonly order: number;
  readonly priceItemUses?: PriceItemUsesDict;
  readonly productUses?: ProductUsesDict;
  readonly type: "delivery" | "pickup" | "workplace";
  readonly values: {readonly [identifier: string]: unknown};
}

export interface ReportingLog {
  readonly [identifier: string]: ReportingLogEntry;
}

export interface FuelSurchargePricePercentInvoiceData {
  readonly [priceItemOrProductUrl: PriceItemUrl | ProductUrl]: {
    /** not present on old instances from before this was introduced */
    readonly costResultingPercent: number;
    readonly product: string | null;
    readonly resultingPercent: number;
    readonly specification: {
      readonly basis: {
        readonly basePriceKrPerLiter: number;
        readonly fromDate: string | null;
        readonly fuelCostSharePercent: number;
        readonly priceKrPerLiterIncreaseThreshold: number;
        readonly truncateNegativeToZero: boolean;
      };
      readonly conversionFactor: number;
      readonly entry: {
        readonly fromDate: string;
        readonly priceKrPerLiter: number;
        readonly toDate: string;
      };
      readonly zeroSurchargeInvoiceLines: boolean;
    } | null;
    readonly text: string;
    readonly useRule: {
      readonly customer: string | null;
      readonly machine: string | null;
      readonly variant: string | null;
      readonly workType: string | null;
    };
  };
}
export interface FuelSurchargeKrPerLiterInvoiceData {
  readonly [machine: MachineUrl]: {
    /** not present on old instances from before this was introduced */
    readonly costKrPerLiter: number;
    readonly krPerLiter: number;
    readonly literPerHour: number;
    readonly product: string | null;
    readonly specification: {
      readonly basis: {
        readonly basePriceKrPerLiter: number;
        readonly fromDate: string | null;
        readonly priceKrPerLiterIncreaseThreshold: number;
        readonly truncateNegativeToZero: boolean;
      };
      readonly conversionFactor: number;
      readonly entry: {
        readonly fromDate: string;
        readonly priceKrPerLiter: number;
        readonly toDate: string;
      };
      readonly zeroSurchargeInvoiceLines: boolean;
    } | null;
    readonly text: string;
    readonly useRule: {
      readonly customer: string | null;
      readonly machine: string | null;
      readonly variant: string | null;
      readonly workType: string | null;
    };
  };
}

export interface InvoiceData {
  fuelSurcharge?: {
    krPerLiter?: FuelSurchargeKrPerLiterInvoiceData;
    pricePercent?: FuelSurchargePricePercentInvoiceData;
    route?: {
      [routeEntry: RouteTaskUrl]: {
        krPerLiter?: FuelSurchargeKrPerLiterInvoiceData;
        pricePercent?: FuelSurchargePricePercentInvoiceData;
      };
    };
  };
}

export interface Task extends ResourceInstance<TaskUrl> {
  readonly address: string;
  readonly archivable: boolean;
  readonly arrivalAtLocation: string | null;
  readonly billedPreparation: boolean | null;
  readonly billedTransportation: boolean | null;
  readonly calledIn: boolean | null;
  readonly cancelled: boolean;
  /** Completed by machine operator/employee? */
  readonly completed: boolean;
  readonly completedAsInternal: boolean;
  readonly computedTimeSet: readonly ComputedTime[];
  readonly continuationTask: TaskUrl | null;
  readonly created?: string;
  readonly createdBy?: UserUrl | null;
  readonly date: string | null;
  readonly department: string;
  readonly doneAtEstimate: string | null;
  readonly fielduseSet: readonly FieldUse[];
  readonly invoiceData: InvoiceData | null | undefined;
  readonly invoiceNote: string;
  readonly lastTransferError: string;
  readonly logSkipped: boolean;
  readonly machineGroup: MachineGroupUrl | null;
  readonly machineOperator: UserUrl | null;
  readonly machineOperatorTimeCorrectionSet: readonly TimeCorrection[];
  readonly machineuseSet: readonly MachineUse[];
  readonly managerInternalNotes: string;
  readonly managerTimeCorrectionSet: readonly TimeCorrection[];
  readonly minutesExpectedTotalTaskDuration: number | null;
  readonly notesFromMachineOperator: string;
  readonly notesFromManager: string;
  readonly order: OrderUrl | null;
  readonly priceGroup: PriceGroupUrl | null;
  // FIXME: should never be undefined, but apparently sometimes is in offline data
  readonly priceItemUses: PriceItemUsesDict | undefined;
  readonly priority: number | null;
  // FIXME: should never be undefined, but apparently sometimes is in offline data
  readonly productUses: ProductUsesDict | undefined;
  readonly project: ProjectUrl | null;
  /** Printed/uploaded/downloaded to billing system; timestamp. */
  readonly recordedInC5: string | null;
  readonly referenceNumber: string;
  readonly relatedPickupLocation: LocationUrl | null;
  readonly relatedWorkplace: LocationUrl | null;
  /** Work/data input validated by manager; might not be ready for transfer
   *  to billing system. */
  readonly reportApproved: boolean;
  readonly reportingData: ReportingData;
  readonly reportingLocations: ReportingLocations;
  readonly reportingLog: ReportingLog;
  readonly reportingSpecification: ReportingSpecificationUrl | null;
  readonly time: string | null;
  readonly timernotesSet: readonly TimerNotes[];
  /** Validated by manager and ready for transfer to billing system. */
  readonly validatedAndRecorded: boolean;
  readonly withoutSmallMachines: boolean;
  readonly workFromTimestamp: string | null;
  readonly workshopchecklistanswerSet: readonly WorkshopChecklistAnswer[];
  readonly workToTimestamp: string | null;
  readonly workType: WorkTypeUrl | null;
}

export const emptyTask: Omit<Task, "id" | "url"> = {
  address: "",
  archivable: false,
  arrivalAtLocation: null,
  billedPreparation: null,
  billedTransportation: null,
  calledIn: null,
  cancelled: false,
  completed: false,
  completedAsInternal: false,
  computedTimeSet: [],
  continuationTask: null,
  date: null,
  department: "",
  doneAtEstimate: null,
  fielduseSet: [],
  invoiceData: null,
  invoiceNote: "",
  lastTransferError: "",
  logSkipped: false,
  machineGroup: null,
  machineOperator: null,
  machineOperatorTimeCorrectionSet: [],
  machineuseSet: [],
  managerInternalNotes: "",
  managerTimeCorrectionSet: [],
  minutesExpectedTotalTaskDuration: null,
  notesFromMachineOperator: "",
  notesFromManager: "",
  order: null,
  priceGroup: null,
  priceItemUses: {},
  priority: null,
  productUses: {},
  project: null,
  recordedInC5: null,
  referenceNumber: "",
  relatedPickupLocation: null,
  relatedWorkplace: null,
  reportApproved: false,
  reportingData: {},
  reportingLocations: {},
  reportingLog: {},
  reportingSpecification: null,
  time: null,
  timernotesSet: [],
  validatedAndRecorded: false,
  withoutSmallMachines: false,
  workFromTimestamp: null,
  workshopchecklistanswerSet: [],
  workToTimestamp: null,
  workType: null,
};

export const emptyProductUseWithOrder: Omit<ProductUseWithOrder, "product"> = {
  addedBy: null,
  correctedCount: null,
  count: null,
  notes: "",
  order: 0,
  ours: false,
};
