import {Unit, UnitUrl} from "@co-common-libs/resources";
import {ImportPreviewProductPriceItems} from "@co-common-libs/resources-utils";
import {memoizeForceReuse} from "@co-frontend-libs/utils";
import React, {useMemo} from "react";
import {useIntl} from "react-intl";
import {EntryData, GenericMultiSelectionSearchDialog} from "../../search-dialog";

function computeBaseChoices(
  itemArrays: ImportPreviewProductPriceItems,
  unitLookup: (url: UnitUrl) => Unit | undefined,
): readonly EntryData<string>[] {
  const entries: EntryData<string>[] = [];

  itemArrays.previewProducts.forEach((product) => {
    const {identifier, name, remoteUrl} = product;
    const unit = product.unit?.name || "";
    const entry: EntryData<string> = {
      category: "standard",
      exactMatchValue: identifier,
      identifier: remoteUrl,
      primaryText: name,
      searchFields: [
        {label: "ID", priority: 10, text: identifier},
        {label: "Navn", priority: 7, text: name},
        {label: "Enhed", priority: 5, text: unit},
      ],
      secondaryText: identifier && unit ? `${identifier} - ${unit}` : identifier || unit || "",
    };
    entries.push(entry);
  });

  itemArrays.priceItems.forEach((priceItem) => {
    const {name, url} = priceItem;
    const unit = priceItem.relatedUnit ? unitLookup(priceItem.relatedUnit)?.name || "" : "";
    const entry: EntryData<string> = {
      category: "standard",
      exactMatchValue: "",
      identifier: url,
      primaryText: name,
      searchFields: [
        {label: "Navn", priority: 7, text: name},
        {label: "Enhed", priority: 5, text: unit},
      ],
      secondaryText: unit,
    };
    entries.push(entry);
  });

  return entries;
}

interface ImportProductPriceItemsPreviewDialogProps {
  addLabel?: string;
  error: string | undefined;
  itemArrays: ImportPreviewProductPriceItems | null;
  onAdd?(searchString: string): void;
  onCancel(): void;
  onOk(remoteUrls: ReadonlySet<string>): void;
  open: boolean;
  selected?: ReadonlySet<string>;
  unitLookup: (url: UnitUrl) => Unit | undefined;
}

export const ImportProductPriceItemsPreviewDialog = React.memo(
  function ImportProductPriceItemsPreviewDialog(props: ImportProductPriceItemsPreviewDialogProps) {
    const {addLabel, error, itemArrays, onAdd, onCancel, onOk, open, selected, unitLookup} = props;
    const intl = useIntl();
    const title = intl.formatMessage({defaultMessage: "Vælg prislinjer"});
    const searchTitle = intl.formatMessage({defaultMessage: "Søg prislinjer"});

    const [doComputeBaseChoices, reuseBaseChoices] = useMemo(
      () => memoizeForceReuse(computeBaseChoices, []),
      [],
    );
    const getBaseChoices = open ? doComputeBaseChoices : reuseBaseChoices;
    const data = itemArrays ? getBaseChoices(itemArrays, unitLookup) : null;

    return (
      <GenericMultiSelectionSearchDialog<string>
        addLabel={addLabel}
        data={data}
        error={error}
        mobilePrimaryLines={1}
        mobileSearchPrimaryLines={1}
        mobileSearchSecondaryLines={1}
        mobileSecondaryLines={1}
        open={open}
        searchTitle={searchTitle}
        selected={selected}
        sorting="SECONDARY_IDENTIFIER"
        title={title}
        onAdd={onAdd}
        onCancel={onCancel}
        onOk={onOk}
      />
    );
  },
);
