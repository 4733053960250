import {Config} from "@co-common-libs/config";
import {
  DaysAbsence,
  PatchUnion,
  ResourceTypeUnion,
  Role,
  UserProfile,
  UserUrl,
} from "@co-common-libs/resources";
import {
  WEEKDAY_SATURDAY,
  WEEKDAY_SUNDAY,
  dateFromString,
  dateToString,
  getHolidaySundayWeekday,
} from "@co-common-libs/utils";
import {instanceURL} from "frontend-global-config";
import {MessageDescriptor, defineMessages} from "react-intl";
import {v4 as uuid} from "uuid";
import {getExtraHolidaysForUser} from "./holidays";
import {getEmployeeHolidayCalendars} from "./remuneration-computations";

const messages = defineMessages({
  childsFirstSickDay: {
    defaultMessage: "Barns første sygedag",
  },
  compensatory: {
    defaultMessage: "Afspadsering",
  },
  daysOffWithoutPay: {
    defaultMessage: "Fridage uden løn",
  },
  floatingHoliday: {
    defaultMessage: "Ferie-fridage",
  },
  sickLeave: {
    defaultMessage: "Sygefravær",
  },
  vacation: {
    defaultMessage: "Feriedage",
  },
});

export const getAbsenceTypeLabel = (
  name: string,
  absenceTypeLabels: {[name: string]: string | undefined},
  formatMessage: (descriptor: MessageDescriptor) => string,
): string => {
  return (
    absenceTypeLabels[name] ||
    ((messages as any)[name] ? formatMessage((messages as any)[name]) : name)
  );
};

const dateAndTimeToTimestamp = (dateString: string, timeString: string): string => {
  const date = dateFromString(dateString) as Date;
  const [hours, minutes] = timeString.split(":");
  date.setHours(parseInt(hours), parseInt(minutes));
  return date.toISOString();
};

function registerHoursAbsence(
  {
    absenceType,
    employeeReason,
    fromDate,
    fromTime,
    note,
    toDate,
    toTime,
    user,
  }: {
    absenceType: string;
    employeeReason: string;
    fromDate: string;
    fromTime: string;
    note: string;
    toDate: string;
    toTime: string;
    user: UserUrl;
  },
  {create}: {create: (instance: ResourceTypeUnion) => void},
): void {
  const fromTimestamp = dateAndTimeToTimestamp(fromDate, fromTime);
  const toTimestamp = dateAndTimeToTimestamp(toDate, toTime);
  const id = uuid();
  const url = instanceURL("hoursAbsence", id);
  create({
    absenceType,
    employeeReason,
    fromTimestamp,
    id,
    note,
    toTimestamp,
    url,
    user,
  });
}

function registerDaysAbsence(
  {
    absenceType,
    employeeReason,
    fromDate,
    note,
    toDate,
    user,
  }: {
    absenceType: string;
    employeeReason: string;
    fromDate: string;
    note: string;
    profile?: string;
    toDate: string;
    user: UserUrl;
  },
  {
    create,
    remove,
    update,
  }: {
    create: (instance: ResourceTypeUnion) => void;
    remove: (url: string) => void;
    update: (url: string, patch: PatchUnion) => void;
  },
  potentiallyOverlappingDaysAbsenceList: readonly DaysAbsence[],
): DaysAbsence[] {
  const newPotentiallyOverlappingDaysAbsenceList: DaysAbsence[] = [];
  potentiallyOverlappingDaysAbsenceList.forEach((potentiallyOverlapping) => {
    const potentiallyOverlappingFromDate = potentiallyOverlapping.fromDate;
    const potentiallyOverlappingToDate = potentiallyOverlapping.toDate;
    if (potentiallyOverlappingToDate < fromDate || potentiallyOverlappingFromDate > toDate) {
      // No overlap; no change, relevant in the future.
      newPotentiallyOverlappingDaysAbsenceList.push(potentiallyOverlapping);
      return;
    }
    if (fromDate <= potentiallyOverlappingFromDate && toDate >= potentiallyOverlappingToDate) {
      // Old registration completely inside new; delete, not relevant in
      // the future.
      remove(potentiallyOverlapping.url);
    } else if (fromDate <= potentiallyOverlappingFromDate) {
      // Partial overlap at start; update on server and for future checks.
      // (Partial check; old registration inside new already handled.)
      const newFromDate = new Date(toDate);
      newFromDate.setDate(newFromDate.getDate() + 1);
      const newFromDateString = dateToString(newFromDate);
      update(potentiallyOverlapping.url, [{member: "fromDate", value: newFromDateString}]);
      newPotentiallyOverlappingDaysAbsenceList.push({
        ...potentiallyOverlapping,
        fromDate: newFromDateString,
      });
    } else if (toDate >= potentiallyOverlappingToDate) {
      // Partial overlap at end; update on server and for future checks.
      // (Partial check; old registration inside new already handled.)
      const newToDate = new Date(fromDate);
      newToDate.setDate(newToDate.getDate() - 1);
      const newToDateString = dateToString(newToDate);
      update(potentiallyOverlapping.url, [{member: "toDate", value: newToDateString}]);
      newPotentiallyOverlappingDaysAbsenceList.push({
        ...potentiallyOverlapping,
        toDate: newToDateString,
      });
    } else {
      // New registration completely inside old; split the old into
      // before/after parts.
      // Make the existing into a "before" part.
      const newToDate = new Date(fromDate);
      newToDate.setDate(newToDate.getDate() - 1);
      const newToDateString = dateToString(newToDate);
      update(potentiallyOverlapping.url, [{member: "toDate", value: newToDateString}]);
      newPotentiallyOverlappingDaysAbsenceList.push({
        ...potentiallyOverlapping,
        toDate: newToDateString,
      });
      // Create a new "after" part.
      const newId = uuid();
      const newUrl = instanceURL("daysAbsence", newId);
      const newFromDate = new Date(toDate);
      newFromDate.setDate(newFromDate.getDate() + 1);
      const newInstance = {
        ...potentiallyOverlapping,
        fromDate: dateToString(newFromDate),
        id: newId,
        url: newUrl,
      };
      create(newInstance);
      newPotentiallyOverlappingDaysAbsenceList.push(newInstance);
    }
  });
  const id = uuid();
  const url = instanceURL("daysAbsence", id);
  create({
    absenceType,
    employeeReason,
    fromDate,
    id,
    note,
    toDate,
    url,
    user,
  });
  return newPotentiallyOverlappingDaysAbsenceList;
}

export function registerAbsence(
  {
    absenceType,
    employeeReason,
    fromDate,
    fromTime,
    note,
    onlyWeekdays,
    profile,
    toDate,
    toTime,
    user,
  }: {
    absenceType: string;
    employeeReason: string;
    fromDate: string;
    fromTime: string | null;
    note: string;
    onlyWeekdays?: boolean | undefined;
    profile?: UserProfile | undefined;
    toDate: string;
    toTime: string | null;
    user: UserUrl;
  },
  {
    create,
    extraHalfHolidaysPerRemunerationGroup,
    extraHolidaysPerRemunerationGroup,
    remove,
    update,
  }: {
    create: (instance: ResourceTypeUnion) => void;
    extraHalfHolidaysPerRemunerationGroup: (
      remunerationGroup: string,
      date: string,
    ) => string | undefined;
    extraHolidaysPerRemunerationGroup: (
      remunerationGroup: string,
      date: string,
    ) => string | undefined;
    remove: (url: string) => void;
    update: (url: string, patch: PatchUnion) => void;
  },
  daysAbsenceArray: readonly DaysAbsence[],
  customerSettings: Config,
): void {
  if (!profile) {
    return;
  }
  if (customerSettings.hoursAbsenceTypes.includes(absenceType)) {
    console.assert(fromTime);
    console.assert(toTime);
    registerHoursAbsence(
      {
        absenceType,
        employeeReason,
        fromDate,
        fromTime: fromTime as string,
        note,
        toDate,
        toTime: toTime as string,
        user,
      },
      {create},
    );
  } else {
    let potentiallyOverlappingDaysAbsenceList = daysAbsenceArray.filter(
      (daysAbsence) =>
        daysAbsence.user === user &&
        !(daysAbsence.fromDate > toDate) &&
        !(daysAbsence.toDate < fromDate),
    );
    if (onlyWeekdays) {
      // build array of date strings for all selected individual dates
      const dateArray = [];
      {
        let dateString = fromDate;
        const date = dateFromString(dateString) as Date;
        while (dateString <= toDate) {
          dateArray.push(dateString);
          date.setDate(date.getDate() + 1);
          dateString = dateToString(date);
        }
      }
      const extraHolidays = getExtraHolidaysForUser(
        customerSettings,
        profile,
        extraHolidaysPerRemunerationGroup,
        extraHalfHolidaysPerRemunerationGroup,
      );
      const holidayCalendars = getEmployeeHolidayCalendars(customerSettings, profile);
      // replace weekends and holidays in array with null
      const dateArrayWithoutWeekendsHolidays = dateArray.map((dateString) => {
        const weekDay = getHolidaySundayWeekday(
          holidayCalendars,
          dateString,
          extraHolidays?.getUserHoliday,
        );
        if (weekDay === WEEKDAY_SUNDAY || weekDay === WEEKDAY_SATURDAY) {
          return null;
        }
        return dateString;
      });
      const periodPairs = [];
      {
        const len = dateArrayWithoutWeekendsHolidays.length;
        let i = 0;
        // skip blanks (holidays/weekends)
        while (i < len) {
          while (i < len && !dateArrayWithoutWeekendsHolidays[i]) {
            i += 1;
          }
          if (i === len) {
            break;
          }
          // Costruct from/to-pair as first/last in non-blank sequence:
          // Loop left on !dateArrayWithoutWeekendsHolidays[i] check failing;
          // so we know that it's not null...
          const periodFromDate = dateArrayWithoutWeekendsHolidays[i] as string;
          let periodToDate = periodFromDate;
          i += 1;
          while (i < len && dateArrayWithoutWeekendsHolidays[i]) {
            // Still in loop where dateArrayWithoutWeekendsHolidays[i] passes;
            // so we know that it's not null...
            periodToDate = dateArrayWithoutWeekendsHolidays[i] as string;
            i += 1;
          }
          periodPairs.push({periodFromDate, periodToDate});
        }
      }
      periodPairs.forEach(({periodFromDate, periodToDate}) => {
        potentiallyOverlappingDaysAbsenceList = registerDaysAbsence(
          {
            absenceType,
            employeeReason,
            fromDate: periodFromDate,
            note,
            toDate: periodToDate,
            user,
          },
          {create, remove, update},
          potentiallyOverlappingDaysAbsenceList,
        );
      });
    } else {
      registerDaysAbsence(
        {absenceType, employeeReason, fromDate, note, toDate, user},
        {create, remove, update},
        potentiallyOverlappingDaysAbsenceList,
      );
    }
  }
}

export function currentUserMayRegisterAbsenceFor(
  customerSettings: Pick<
    Config,
    "onlyManagersCanRegisterAbsence" | "projectManagerCanManageAbsence" | "registerAbsence"
  >,
  currentRole: Pick<Role, "manager" | "projectManager" | "user"> | null,
  registerForUserUrl: UserUrl,
): boolean {
  if (!customerSettings.registerAbsence) {
    // absence feature is disabled
    return false;
  }
  if (!currentRole) {
    // missing permission data; assume no permissions
    return false;
  }
  if (currentRole.user === registerForUserUrl && !customerSettings.onlyManagersCanRegisterAbsence) {
    // users can normally register their own absence
    return true;
  }
  if (currentRole.manager && !currentRole.projectManager) {
    // "normal" managers may always register absence
    return true;
  }
  if (currentRole.projectManager && customerSettings.projectManagerCanManageAbsence) {
    // project managers have "manager permissions"
    // from projectManagerCanManageAbsence
    return true;
  }
  return false;
}

export function currentUserMayRegisterOwnAbsence(
  customerSettings: Pick<
    Config,
    "onlyManagersCanRegisterAbsence" | "projectManagerCanManageAbsence" | "registerAbsence"
  >,
  currentRole: Pick<Role, "manager" | "projectManager" | "user"> | null,
): boolean {
  // users that can't register absence for themselves won't be able to register absence at all
  return (
    !!currentRole &&
    currentUserMayRegisterAbsenceFor(customerSettings, currentRole, currentRole.user)
  );
}

export function currentUserMayEditAbsence(
  customerSettings: Pick<
    Config,
    "onlyManagersCanRegisterAbsence" | "projectManagerCanManageAbsence" | "registerAbsence"
  >,
  currentRole: Pick<Role, "manager" | "projectManager"> | null,
): boolean {
  if (!customerSettings.registerAbsence) {
    // absence feature is disabled
    return false;
  }
  if (!currentRole) {
    // missing permission data; assume no permissions
    return false;
  }
  if (currentRole.manager && !currentRole.projectManager) {
    // "normal" managers may always edit absence
    return true;
  }
  if (currentRole.projectManager && customerSettings.projectManagerCanManageAbsence) {
    // project managers have "manager permissions"
    // from projectManagerCanManageAbsence
    return true;
  }
  // "normal" users may *not* edit absence
  return false;
}

export function currentUserMayRegisterOthersAbsence(
  customerSettings: Pick<
    Config,
    "onlyManagersCanRegisterAbsence" | "projectManagerCanManageAbsence" | "registerAbsence"
  >,
  currentRole: Pick<Role, "manager" | "projectManager" | "user"> | null,
): boolean {
  return currentUserMayEditAbsence(customerSettings, currentRole);
}
