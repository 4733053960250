import {Config} from "@co-common-libs/config";
import {IntlShape} from "react-intl";

let googleMapsAPIKey = "";

/** Read `googleMapsAPIKey` from `customerSettings`; but cache and reuse the
 * value, to avoid loading the Google Maps JS multiple times in case of
 * changes; that seems to crash.
 */
export function getGoogleMapsAPIKey(customerSettings: Config): string {
  if (!googleMapsAPIKey) {
    ({googleMapsAPIKey} = customerSettings);
  }
  return googleMapsAPIKey;
}

let language = "";

/** Read `locale` from `intl` (and pretend that it specifies language); but
 * cache and reuse the value, to avoid loading the Google Maps JS multiple
 * times in case of changes; that seems to crash.
 */
export function getLanguage(intl: IntlShape): string {
  if (!language) {
    language = intl.locale;
  }
  return language;
}
