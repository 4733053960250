import {MenuItem, Typography} from "@material-ui/core";
import React, {useCallback} from "react";
import {Alert} from "./alert";
import {FormattedAlert} from "./formatted-alert";

interface AlertMenuItemProps {
  alert: Alert;
  onClick: (alert: Alert) => void;
}

export const AlertMenuItem = React.forwardRef(function AlertMenuItem(
  props: AlertMenuItemProps,
  _ref,
) {
  const {alert, onClick} = props;

  const handleClick = useCallback(() => onClick(alert), [alert, onClick]);

  return (
    <MenuItem onClick={handleClick}>
      <Typography variant="body2">
        <FormattedAlert alert={alert} />
      </Typography>
    </MenuItem>
  );
});
