import {
  PricePercentFuelSurchargeBasis,
  PricePercentFuelSurchargeSpecification,
} from "@co-common-libs/resources";
import {findFuelSurchargeBasis} from "@co-common-libs/resources-utils";
import {dateFromString, dateToString} from "@co-common-libs/utils";
import {getPricePercentFuelSurchargeBasisArray} from "@co-frontend-libs/redux";
import {Card, CardContent, CardHeader} from "@material-ui/core";
import React from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useSelector} from "react-redux";

interface PricePercentFuelSurchargeBasisCurrentFoundProps {
  basis: PricePercentFuelSurchargeBasis;
  specification: PricePercentFuelSurchargeSpecification;
}

const PricePercentFuelSurchargeBasisCurrentFound = React.memo(
  function PricePercentFuelSurchargeBasisCurrentFound(
    props: PricePercentFuelSurchargeBasisCurrentFoundProps,
  ): JSX.Element {
    const {basis, specification} = props;

    const {conversionFactor} = specification;

    const {
      basePriceKrPerLiter,
      fromDate,
      fuelCostSharePercent,
      lastChanged,
      priceKrPerLiterIncreaseThreshold,
      truncateNegativeToZero,
    } = basis;

    const tableHeaderStyle: React.CSSProperties = {textAlign: "right"};

    return (
      <table>
        <tbody>
          <tr>
            <th scope="row" style={tableHeaderStyle}>
              <FormattedMessage defaultMessage="Gældende fra:" />
            </th>
            <td>
              {fromDate ? (
                <FormattedMessage
                  defaultMessage="{fromDate, date, long}"
                  values={{fromDate: dateFromString(fromDate)}}
                />
              ) : (
                <FormattedMessage defaultMessage="Uden grænse" />
              )}
            </td>
          </tr>
          <tr>
            <th scope="row" style={tableHeaderStyle}>
              <FormattedMessage defaultMessage="Oprettet:" />
            </th>
            <td>
              {lastChanged ? (
                <FormattedMessage
                  defaultMessage="{created, date, long}"
                  values={{created: new Date(lastChanged)}}
                />
              ) : null}
            </td>
          </tr>
          <tr>
            <th scope="row" style={tableHeaderStyle}>
              <FormattedMessage defaultMessage="Basispris:" />
            </th>
            <td>
              {conversionFactor === 1 ? (
                <FormattedMessage
                  defaultMessage="{price, number, ::.00} kr/L"
                  values={{price: basePriceKrPerLiter}}
                />
              ) : (
                <FormattedMessage
                  defaultMessage="{price, number, ::.00} kr/{conversionFactor, number} L"
                  values={{
                    conversionFactor,
                    price: basePriceKrPerLiter * conversionFactor,
                  }}
                />
              )}
            </td>
          </tr>
          <tr>
            <th scope="row" style={tableHeaderStyle}>
              <FormattedMessage defaultMessage="Margin:" />
            </th>
            <td>
              {conversionFactor === 1 ? (
                <FormattedMessage
                  defaultMessage="{priceKrPerLiterIncreaseThreshold, number, ::.00} kr/L (tilladt prisstigning før kunden skal have tillagt brændstoftillæg)"
                  values={{
                    priceKrPerLiterIncreaseThreshold,
                  }}
                />
              ) : (
                <FormattedMessage
                  defaultMessage="{priceKrPerLiterIncreaseThreshold, number, ::.00} kr/{conversionFactor, number} L (tilladt prisstigning før kunden skal have tillagt brændstoftillæg)"
                  values={{
                    conversionFactor,
                    priceKrPerLiterIncreaseThreshold:
                      priceKrPerLiterIncreaseThreshold * conversionFactor,
                  }}
                />
              )}
            </td>
          </tr>
          <tr>
            <th scope="row" style={tableHeaderStyle}>
              <FormattedMessage defaultMessage="Negativ tillæg:" />
            </th>
            <td>
              {truncateNegativeToZero ? (
                <FormattedMessage defaultMessage="Ved brændstofpris under basisprisen gives der ikke negativt tillæg" />
              ) : (
                <FormattedMessage defaultMessage="Ved brændstofpris under basisprisen gives der negativt tillæg" />
              )}
            </td>
          </tr>
          <tr>
            <th scope="row" style={tableHeaderStyle}>
              <FormattedMessage defaultMessage="Andel:" />
            </th>
            <td>
              <FormattedMessage
                defaultMessage="{fuelCostSharePercent, number, ::.00} % (brændstofs andel af salgpris)"
                values={{
                  fuelCostSharePercent,
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>
    );
  },
);

interface PricePercentFuelSurchargeBasisCurrentProps {
  specification: PricePercentFuelSurchargeSpecification;
}

export const PricePercentFuelSurchargeBasisCurrent = React.memo(
  function PricePercentFuelSurchargeBasisCurrent(
    props: PricePercentFuelSurchargeBasisCurrentProps,
  ): JSX.Element {
    const {specification} = props;

    const intl = useIntl();

    const pricePercentFuelSurchargeBasisArray = useSelector(getPricePercentFuelSurchargeBasisArray);

    const today = dateToString(new Date());

    const basis = findFuelSurchargeBasis(
      pricePercentFuelSurchargeBasisArray,
      specification.url,
      today,
    );

    return (
      <Card>
        <CardHeader
          title={intl.formatMessage({
            defaultMessage: "Grundlag, aktuelt",
          })}
        />
        <CardContent>
          {basis ? (
            <PricePercentFuelSurchargeBasisCurrentFound
              basis={basis}
              specification={specification}
            />
          ) : (
            <FormattedMessage defaultMessage="Grundlag ikke fundet for dags dato" />
          )}
        </CardContent>
      </Card>
    );
  },
);
