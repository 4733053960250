import {Task} from "@co-common-libs/resources";
import {formatDate, formatTime} from "@co-common-libs/utils";
import {FilePdfIcon} from "@co-frontend-libs/components";
import {getRouteLogReportArray, getUserUserProfileLookup} from "@co-frontend-libs/redux";
import {
  Card,
  CardHeader,
  CircularProgress,
  Fab,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import _ from "lodash";
import PlusIcon from "mdi-react/PlusIcon";
import React from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useSelector} from "react-redux";

const reportDownloadColumnWidth = 96;

interface ReportsCardProps {
  onRequestBuildReport: () => void;
  shareToken: string;
  task: Task;
}

export const ReportsCard = React.memo(function ReportsCard(props: ReportsCardProps): JSX.Element {
  const {onRequestBuildReport, shareToken, task} = props;

  const routeLogReportArray = useSelector(getRouteLogReportArray);
  const userUserProfileLookup = useSelector(getUserUserProfileLookup);

  const intl = useIntl();
  const tokenPart = `?token=${shareToken}`;
  const taskURL = task.url;
  const tableRows = _.sortBy(
    routeLogReportArray.filter((r) => r.route === taskURL),
    (r) => r.serverTimestamp,
  )
    .reverse()
    .map((report) => {
      const pdfDownloadURL = report.pdfDownload;
      let pendingSpinner;
      let pdfDownloadButton;
      if (!pdfDownloadURL) {
        pendingSpinner = (
          <div style={{position: "relative"}}>
            <CircularProgress />
          </div>
        );
      } else {
        pdfDownloadButton = (
          <IconButton color="primary" href={pdfDownloadURL + tokenPart} target="_blank">
            <FilePdfIcon />
          </IconButton>
        );
      }
      const createdByURL = report.createdBy;
      const createdByProfile = userUserProfileLookup(createdByURL);
      return (
        <TableRow key={report.url}>
          <TableCell>{createdByProfile ? createdByProfile.alias : null}</TableCell>
          <TableCell>
            {formatDate(report.deviceTimestamp)}
            &nbsp;
            {formatTime(report.deviceTimestamp)}
          </TableCell>
          <TableCell style={{width: reportDownloadColumnWidth}}>
            {pendingSpinner}
            {pdfDownloadButton}
          </TableCell>
        </TableRow>
      );
    });
  return (
    <Card style={{margin: "1em"}}>
      <div style={{position: "relative"}}>
        <Fab
          size="small"
          style={{
            position: "absolute",
            right: 23,
            top: 23,
          }}
          onClick={onRequestBuildReport}
        >
          <PlusIcon />
        </Fab>
      </div>
      <CardHeader title={intl.formatMessage({defaultMessage: "Udskrifter"})} />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage
                defaultMessage="Udskrevet af"
                id="transportlog-report.table-header.printed-by"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                defaultMessage="Tidspunkt"
                id="transportlog-report.table-header.printed-on"
              />
            </TableCell>
            <TableCell style={{width: reportDownloadColumnWidth}}>
              <FormattedMessage
                defaultMessage="Download"
                id="transportlog-report.table-header.download"
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{tableRows}</TableBody>
      </Table>
    </Card>
  );
});
