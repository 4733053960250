import {AnniversaryType, UserProfile} from "@co-common-libs/resources";
import {dateFromString} from "@co-common-libs/utils";

export const getNextAnniversaryDate = (
  type: AnniversaryType,
  anniversaryBaseDate: Date,
  checkDate: Date,
): Date => {
  const year = anniversaryBaseDate.getFullYear();
  const month = anniversaryBaseDate.getMonth();
  const day = anniversaryBaseDate.getDate();
  const {years} = type;
  if (years) {
    return new Date(year + years, month, day);
  } else {
    const checkDateStart = new Date(checkDate);
    checkDateStart.setHours(0, 0, 0, 0);
    const result = new Date(checkDateStart);
    result.setMonth(month, day);
    if (result < checkDateStart) {
      result.setFullYear(result.getFullYear() + 1);
    }
    return result;
  }
};

export const getUserAnniversaryMap = (
  userProfileArray: readonly UserProfile[],
  anniversaryTypeArray: readonly AnniversaryType[],
  selectedDateDate: Date,
): Map<string, AnniversaryType[]> => {
  const userAnniversaryMap = new Map<string, AnniversaryType[]>();
  const userProfilesWithBirthDates: UserProfile[] = [];
  const usersWithEmploymentDates: UserProfile[] = [];
  userProfileArray.forEach((userProfile) => {
    if (userProfile.birthDate) {
      userProfilesWithBirthDates.push(userProfile);
    }
    if (userProfile.employmentDate) {
      usersWithEmploymentDates.push(userProfile);
    }
  });
  anniversaryTypeArray.forEach((anniversaryType) => {
    if (anniversaryType.countFrom === "BIRTH_DATE") {
      userProfilesWithBirthDates.forEach((userProfile) => {
        const date = dateFromString(userProfile.birthDate as string) as Date;
        const nextOccurence = getNextAnniversaryDate(anniversaryType, date, selectedDateDate);
        if (
          nextOccurence.getDate() === selectedDateDate.getDate() &&
          nextOccurence.getMonth() === selectedDateDate.getMonth() &&
          nextOccurence.getFullYear() === selectedDateDate.getFullYear()
        ) {
          userAnniversaryMap.set(userProfile.user, [
            ...(userAnniversaryMap.get(userProfile.user) || []),
            anniversaryType,
          ]);
        }
      });
    }
    if (anniversaryType.countFrom === "EMPLOYMENT_DATE") {
      usersWithEmploymentDates.forEach((userProfile) => {
        const date = dateFromString(userProfile.employmentDate as string) as Date;
        const nextOccurence = getNextAnniversaryDate(anniversaryType, date, selectedDateDate);
        if (
          nextOccurence.getDate() === selectedDateDate.getDate() &&
          nextOccurence.getMonth() === selectedDateDate.getMonth() &&
          nextOccurence.getFullYear() === selectedDateDate.getFullYear()
        ) {
          userAnniversaryMap.set(userProfile.user, [
            ...(userAnniversaryMap.get(userProfile.user) || []),
            anniversaryType,
          ]);
        }
      });
    }
  });
  return userAnniversaryMap;
};
