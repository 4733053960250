import {Config} from "@co-common-libs/config";
import {
  PriceItemUse,
  ProductUse,
  ReportingInputSpecification,
  Unit,
  UnitUrl,
} from "@co-common-libs/resources";
import _ from "lodash";
import React, {useCallback, useEffect} from "react";
import {ReportingInputField} from "./reporting-input-field";

interface ReportingMultiplicationInputFieldProps {
  customerSettings: Config;
  inputSpecification: ReportingInputSpecification;
  multiplicandValue?: number;
  multiplierIdentifier: string;
  multiplierInputSpecification: ReportingInputSpecification;
  multiplierValue?: number;
  onChange: (identifier: string, value: number | null) => void;
  onTransferOk?: ((value: number | null, url: string, unit: string) => void) | undefined;
  priceItemUseList?: readonly PriceItemUse[] | undefined;
  productUseList?: readonly ProductUse[] | undefined;
  unitLookup: (url: UnitUrl) => Unit | undefined;
}

export const ReportingMultiplicationInputField = React.memo(
  function ReportingMultiplicationInputField(
    props: ReportingMultiplicationInputFieldProps,
  ): JSX.Element {
    const {
      inputSpecification,
      multiplicandValue,
      multiplierIdentifier: multiplierIdentifierFromProps,
      multiplierInputSpecification,
      multiplierValue,
      onChange,
      onTransferOk,
      priceItemUseList,
      productUseList,
      unitLookup,
    } = props;

    const handleChange = useCallback(
      (multiplierIdentifier: string, value: unknown): void => {
        if (value == null || multiplicandValue == null) {
          return;
        }
        const multiplierFormatSpecification = multiplierInputSpecification.format;
        const decimalPlaces = multiplierFormatSpecification.decimalPlaces || 0;
        const numberValue = value as number;
        const resultValue = numberValue
          ? _.round(multiplicandValue / numberValue, decimalPlaces)
          : 0;
        onChange(multiplierIdentifier, resultValue);
      },
      [multiplierInputSpecification.format, onChange, multiplicandValue],
    );

    let value: number | null = null;
    let disabled;
    if (multiplicandValue == null) {
      disabled = true;
    } else {
      disabled = false;
      if (multiplierValue != null) {
        const computedValue = multiplierValue * multiplicandValue;
        const decimalPlaces = props.customerSettings.materialDecimals;
        value = _.round(computedValue, decimalPlaces);
      }
    }
    useEffect(
      () => onChange(inputSpecification.identifier, value),
      [onChange, inputSpecification.identifier, value],
    );

    return (
      <ReportingInputField
        disabled={disabled}
        identifier={multiplierIdentifierFromProps}
        inputSpecification={inputSpecification}
        priceItemUseList={priceItemUseList}
        productUseList={productUseList}
        unitLookup={unitLookup}
        value={value || undefined}
        onChange={handleChange}
        onTransferOk={onTransferOk}
      />
    );
  },
);
