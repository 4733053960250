import {
  CustomerUrl,
  FuelSurchargeKrPerLiterInvoiceData,
  KrPerLiterDefaultFuelSurchargeUse,
  KrPerLiterFuelSurchargeBasis,
  KrPerLiterFuelSurchargeSpecification,
  KrPerLiterFuelSurchargeSpecificationEntry,
  KrPerLiterFuelSurchargeSpecificationUrl,
  KrPerLiterMachineFuelSurchargeUse,
  KrPerLiterWorkTypeFuelSurchargeUse,
  Machine,
  MachineUrl,
  Product,
  ProductUrl,
  Task,
} from "@co-common-libs/resources";
import {dateToString} from "@co-common-libs/utils";
import {IntlShape} from "@formatjs/intl";
import {checkMachineUseFuelSurcharge} from "./check-fuel-surcharge";
import {formatFuelSurchargeKrPerLiterInvoiceData} from "./format-fuel-surcharge-kr-per-liter-invoice-data";
import {getMachineUseMatchesSpecificationEntries} from "./get-fuel-surcharge-specification-entries";
import {getMachineFuelConsumption} from "./get-machine-fuel-consumption";
import {getMachineUseMatches} from "./get-machine-use-matches";

type TaskPart = Pick<Task, "machineuseSet" | "priceGroup" | "workType"> & {
  readonly workFromTimestamp: NonNullable<Task["workFromTimestamp"]>;
};

type MachinePart = Pick<Machine, "fuelConsumptionLiterPerHour">;

type ProductPart = Pick<Product, "name">;

type KrPerLiterFuelSurchargeSpecificationPart = Pick<
  KrPerLiterFuelSurchargeSpecification,
  | "active"
  | "conversionFactor"
  | "invoiceLineProduct"
  | "invoiceLineText"
  | "name"
  | "url"
  | "zeroSurchargeInvoiceLines"
>;
type KrPerLiterMachineFuelSurchargeUsePart = Pick<
  KrPerLiterMachineFuelSurchargeUse,
  "customer" | "fuelSurcharge" | "machine" | "variant"
>;
type KrPerLiterWorkTypeFuelSurchargeUsePart = Pick<
  KrPerLiterWorkTypeFuelSurchargeUse,
  "customer" | "fuelSurcharge" | "variant" | "workType"
>;
type KrPerLiterDefaultFuelSurchargeUsePart = Pick<
  KrPerLiterDefaultFuelSurchargeUse,
  "customer" | "fuelSurcharge"
>;
type KrPerLiterFuelSurchargeSpecificationEntryPart = Pick<
  KrPerLiterFuelSurchargeSpecificationEntry,
  "fromDate" | "priceKrPerLiter" | "specification" | "toDate"
>;

type KrPerLiterFuelSurchargeBasisPart = Pick<
  KrPerLiterFuelSurchargeBasis,
  | "basePriceKrPerLiter"
  | "fromDate"
  | "priceKrPerLiterIncreaseThreshold"
  | "specification"
  | "truncateNegativeToZero"
>;

export function getFuelSurchargeKrPerLiterInvoiceData<
  Specification extends KrPerLiterFuelSurchargeSpecificationPart,
  MachineFuelSurchargeUse extends KrPerLiterMachineFuelSurchargeUsePart,
  WorkTypeFuelSurchargeUse extends KrPerLiterWorkTypeFuelSurchargeUsePart,
  DefaultFuelSurchargeUse extends KrPerLiterDefaultFuelSurchargeUsePart,
  Entry extends KrPerLiterFuelSurchargeSpecificationEntryPart,
>(
  intl: Pick<IntlShape, "formatMessage">,
  machineLookup: (url: MachineUrl) => MachinePart | undefined,
  productLookup: (url: ProductUrl) => ProductPart | undefined,
  fuelSurchargeSpecificationLookup: (
    url: KrPerLiterFuelSurchargeSpecificationUrl,
  ) => Specification | undefined,
  machineFuelSurchargeUseArray: readonly MachineFuelSurchargeUse[],
  workTypeFuelSurchargeUseArray: readonly WorkTypeFuelSurchargeUse[],
  defaultFuelSurchargeUseArray: readonly DefaultFuelSurchargeUse[],
  entryArray: readonly Entry[],
  basisArray: readonly KrPerLiterFuelSurchargeBasisPart[],
  task: TaskPart,
  customerUrl: CustomerUrl | null,
): {
  issues: Map<
    MachineUrl,
    {
      fuelConsumptionLiterPerHour: number | null;
      specification: Specification;
      specificationEntry: Entry | null;
      use: DefaultFuelSurchargeUse | MachineFuelSurchargeUse | WorkTypeFuelSurchargeUse;
    }
  >;
  krPerLiter: FuelSurchargeKrPerLiterInvoiceData;
} {
  const taskDate = dateToString(new Date(task.workFromTimestamp));
  const matches = getMachineUseMatches(
    task,
    customerUrl,
    machineLookup,
    fuelSurchargeSpecificationLookup,
    machineFuelSurchargeUseArray,
    workTypeFuelSurchargeUseArray,
    defaultFuelSurchargeUseArray,
  );
  const matchesWithEntries = getMachineUseMatchesSpecificationEntries(
    entryArray,
    taskDate,
    matches,
  );
  const matchesWithEntriesAndConsumption = getMachineFuelConsumption(
    machineLookup,
    matchesWithEntries,
  );

  const {issues, withFuelSurcharge, withoutFuelSurcharge} = checkMachineUseFuelSurcharge(
    matchesWithEntriesAndConsumption,
  );

  const krPerLiter = formatFuelSurchargeKrPerLiterInvoiceData(
    intl,
    productLookup,
    basisArray,
    taskDate,
    withFuelSurcharge,
    withoutFuelSurcharge,
  );

  return {issues, krPerLiter};
}
