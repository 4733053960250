import {Config} from "./types";

type SettingID = keyof Config;

export const settingsDocumentationURLs: Partial<{
  readonly [S in SettingID]: string;
}> = {
  absenceCalendar: "https://customoffice.freshdesk.com/a/solutions/articles/30000041897",
  absenceCalendarBlockColor: "https://customoffice.freshdesk.com/a/solutions/articles/30000041964",
  absenceCalendarDisplayLabel:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000041900",
  absenceTypeColors: "https://customoffice.freshdesk.com/a/solutions/articles/30000042106",
  absenceTypeLabels: "https://customoffice.freshdesk.com/a/solutions/articles/30000042094",
  absenceTypeShortLabels: "https://customoffice.freshdesk.com/a/solutions/articles/30000042095",
  address: "https://customoffice.freshdesk.com/a/solutions/articles/30000041904",
  adjustBilledMinutes: "https://support.customoffice.dk/a/solutions/articles/30000042528",
  allowDuplicateProductUses: "https://customoffice.freshdesk.com/a/solutions/articles/30000042308",
  allowMachineOperatorChangeOnTaskInfoPage:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042066",
  allowMachineOperatorToEditTaskDate:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042033",
  allowSeniorMachineOperatorsToManageFoodBookings:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042618",
  allowTakingPendingTasksFromOthers:
    "https://support.customoffice.dk/a/solutions/articles/30000042414",
  alternativeMaterialsMobileLayout:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042309",
  alwaysAllowEditingOrderReferenceNumber:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000041991",
  alwaysAllowEditingOrderWorkplace:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000041986",
  alwaysAllowManagerTaskAndOrderNotesEdit:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000041992",
  alwaysAllowManagerTaskPhotosEdit:
    "https://support.customoffice.dk/a/solutions/articles/30000042110",
  alwaysUseLogTab: "https://customoffice.freshdesk.com/a/solutions/articles/30000042250",
  anniversariesEnabled: "https://customoffice.freshdesk.com/a/solutions/articles/30000042591",
  askForPriceGroupSelectionAfterWorkTypeSelection:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000041994",
  askRegardingMissingBreakOnExternalTaskCompletion:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042034",
  askRegardingMissingBreakOnInternalTaskCompletion:
    "https://support.customoffice.dk/a/solutions/articles/30000042422",
  autoComputedSalaryVouchers: "https://customoffice.freshdesk.com/a/solutions/articles/30000041928",
  autoCopyMaterialNoteToSupplementingProductNote:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042312",
  autoFillReferenceNumberWithCustomerAccount:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000041990",
  autoSupplementingProducts: "https://customoffice.freshdesk.com/a/solutions/articles/30000042310",
  availabilityWeekdays: "https://support.customoffice.dk/a/solutions/articles/30000041966",
  availabilityWeekdaysAskFromDay:
    "https://support.customoffice.dk/a/solutions/articles/30000041968",
  availabilityWeekdaysAskFromHour:
    "https://support.customoffice.dk/a/solutions/articles/30000041969",
  availabilityWeekends: "https://support.customoffice.dk/a/solutions/articles/30000041971",
  availabilityWeekendsAskFromDay:
    "https://support.customoffice.dk/a/solutions/articles/30000041972",
  backgroundPositionInterval: "https://support.customoffice.dk/a/solutions/articles/30000041963",
  bookkeepingDayLinePerTimer: "https://support.customoffice.dk/a/solutions/articles/30000042030",
  bookkeepingDayPDF: "https://support.customoffice.dk/a/solutions/articles/30000042587",
  bookkeepingDayRespectNoteLineBreaks:
    "https://support.customoffice.dk/a/solutions/articles/30000042109",
  bookkeepingListColumns:
    "https://support.customoffice.dk/support/solutions/articles/30000043392-dagsedler-generelt-#Kolonner-p%C3%A5-dagseddelslisterne",
  bookkeepingMachineNames: "https://support.customoffice.dk/a/solutions/articles/30000042028",
  bookkeepingWorkplaceAddressReplaceName:
    "https://support.customoffice.dk/a/solutions/articles/30000042031",
  bookkeepingWorkTypeAsWorkTypeAndVariant:
    "https://support.customoffice.dk/a/solutions/articles/30000042032",
  calendarForMachineOperators:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000041925",
  calendarPlannedTasksBehindMeasured:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000041926",
  calendarShowEmployeeAlias: "https://customoffice.freshdesk.com/a/solutions/articles/30000041927",
  calendarShowEmployeeName: "https://customoffice.freshdesk.com/a/solutions/articles/30000041929",
  calendarShowEmployeeNumber: "https://customoffice.freshdesk.com/a/solutions/articles/30000041930",
  calendarShowMaterialsWithUnits:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000041931",
  calendarShowWorkTypeNumber: "https://customoffice.freshdesk.com/a/solutions/articles/30000041932",
  canCreateCultures: "https://customoffice.freshdesk.com/a/solutions/articles/30000042635",
  canCreateExternalWorkType: "https://customoffice.freshdesk.com/a/solutions/articles/30000042624",
  canCreateMachines: "https://customoffice.freshdesk.com/a/solutions/articles/30000042642",
  canCreatePriceGroups: "https://customoffice.freshdesk.com/a/solutions/articles/30000042626",
  canEditAllUserDataOnProfilePage:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042592",
  canEditCultures: "https://customoffice.freshdesk.com/a/solutions/articles/30000042636",
  canEditCustomerVATNumber: "https://customoffice.freshdesk.com/a/solutions/articles/30000042084",
  canEditExternalWorkType: "https://customoffice.freshdesk.com/a/solutions/articles/30000042625",
  canEditInternalWorkTypes: "https://customoffice.freshdesk.com/a/solutions/articles/30000042629",
  canEditMachines: "https://customoffice.freshdesk.com/a/solutions/articles/30000042643",
  canEditPriceGroups: "https://customoffice.freshdesk.com/a/solutions/articles/30000042627",
  canEditPriceItemActive: "https://customoffice.freshdesk.com/a/solutions/articles/30000042652",
  canEditPriceItems: "https://customoffice.freshdesk.com/a/solutions/articles/30000042645",
  canEditPriceItemUseManualDistribution:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042653",
  chooseWorkTypesOnCustomerJournal:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042086",
  colorUnscheduledCalendar: "https://customoffice.freshdesk.com/a/solutions/articles/30000041956",
  colorWorkTypesOnEmployeeCalendar:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000041936",
  companyName: "https://customoffice.freshdesk.com/a/solutions/articles/30000041898",
  completeTaskAsInternal: "https://support.customoffice.dk/a/solutions/articles/30000042408",
  computedSalaryVoucherStartDay:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000041957",
  concurrentTasksAllowed: "https://support.customoffice.dk/a/solutions/articles/30000042053",
  concurrentTasksAllowedForDepartments:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000041949",
  concurrentTasksAllowedForWorkTypes:
    "https://support.customoffice.dk/a/solutions/articles/30000042140",
  consultantNotes: "https://customoffice.freshdesk.com/a/solutions/articles/30000041898",
  contactMail: "https://customoffice.freshdesk.com/a/solutions/articles/30000041909",
  contactPerson: "https://customoffice.freshdesk.com/a/solutions/articles/30000041907",
  contactPhone: "https://customoffice.freshdesk.com/a/solutions/articles/30000041908",
  contactsMayHaveCellphone: "https://customoffice.freshdesk.com/a/solutions/articles/30000042088",
  continuationCopyFields: "https://support.customoffice.dk/a/solutions/articles/30000042410",
  conversionFieldDecimals: "https://customoffice.freshdesk.com/a/solutions/articles/30000042313",
  createCustomer: "https://customoffice.freshdesk.com/a/solutions/articles/30000042013",
  customerAliasEdit: "https://customoffice.freshdesk.com/a/solutions/articles/30000042060",
  customerCreateInvoicesSetup:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042020",
  customerFields: "https://customoffice.freshdesk.com/a/solutions/articles/30000042440",
  customerFileUpload: "https://customoffice.freshdesk.com/a/solutions/articles/30000042424",
  customerJournal: "https://customoffice.freshdesk.com/a/solutions/articles/30000042085",
  customerOrderListNewestFirst:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042005",
  customerOrderOverview: "https://customoffice.freshdesk.com/a/solutions/articles/30000042433",
  customerProjectManager: "https://customoffice.freshdesk.com/a/solutions/articles/30000042087",
  customerStatsVisibleToAll: "https://customoffice.freshdesk.com/a/solutions/articles/30000042090",
  customerTaskAutoWorkTypePreviousMachines:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042021",
  customerTaskCreationWizard: "https://customoffice.freshdesk.com/a/solutions/articles/30000042145",
  customOfficeProductVariant: "https://customoffice.dk/priser",
  cvr: "https://customoffice.freshdesk.com/a/solutions/articles/new?translate=false",
  dayEndRounding: "https://customoffice.freshdesk.com/a/solutions/articles/30000042046",
  daysAbsenceTypes: "https://customoffice.freshdesk.com/a/solutions/articles/30000042093",
  daysIntoTheFutureVisibleToMachineOperator:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042245",
  dayStartRounding: "https://customoffice.freshdesk.com/a/solutions/articles/30000042029",
  dinnerBookingLocations: "https://customoffice.freshdesk.com/a/solutions/articles/30000042619",
  dinnerBookings: "https://customoffice.freshdesk.com/a/solutions/articles/30000042615",
  dinnerBookingsShowThumbDialog:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042617",
  disabledMachines: "https://customoffice.freshdesk.com/a/solutions/articles/30000042632",
  disabledWorkTypes: "https://customoffice.freshdesk.com/a/solutions/articles/30000042631",
  displayCustomerContacts: "https://customoffice.freshdesk.com/a/solutions/articles/30000042071",
  distributionTableAdjustTimeMethod:
    "https://support.customoffice.dk/a/solutions/articles/30000042529",
  economicAutogeneratedCustomerNumbers:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042015",
  economicCustomerGroup: "https://customoffice.freshdesk.com/a/solutions/articles/30000042014",
  economicMergLinesPerOrder: "https://customoffice.freshdesk.com/a/solutions/articles/30000041973",
  employeeCalendarManualOrderPerUser:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000041938",
  employeeCanAccessAppendicesPage:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042114",
  employeeSameDayTasksWarning:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042035",
  enableAppendicesPage: "https://customoffice.freshdesk.com/a/solutions/articles/30000042113",
  enableCalendarWorkTypeFilter:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000041945",
  enableConvertInternalToCustomerTask:
    "https://support.customoffice.dk/a/solutions/articles/30000042421",
  enableCustomerCellphone: "https://customoffice.freshdesk.com/a/solutions/articles/30000042089",
  enableDocuments: "https://customoffice.freshdesk.com/a/solutions/articles/30000042601",
  enableDoneAtEstimateField: "https://customoffice.freshdesk.com/a/solutions/articles/30000042036",
  enableEmployeeCalendarManualOrder:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000041937",
  enableExtraWorkButton: "https://support.customoffice.dk/a/solutions/articles/30000042419",
  enableFieldsSettingsPage: "https://customoffice.freshdesk.com/a/solutions/articles/30000042441",
  enableGPSList: "https://support.customoffice.dk/a/solutions/articles/30000042104",
  enableInvoiceCorrections: "https://support.customoffice.dk/a/solutions/articles/30000042103",
  enableJobberRole: "https://customoffice.freshdesk.com/a/solutions/articles/30000042593",
  enableLocationStorage: "https://customoffice.freshdesk.com/a/solutions/articles/30000042026",
  enableMachinePriceStructureOverviewSettingsPage:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042630",
  enableMechanicRole: "https://customoffice.freshdesk.com/a/solutions/articles/30000042594",
  enableOrderMerge: "https://customoffice.freshdesk.com/a/solutions/articles/30000041967",
  enableOrderReferenceNumber: "https://customoffice.freshdesk.com/a/solutions/articles/30000041978",
  enableOrders: "https://customoffice.freshdesk.com/a/solutions/articles/30000041984",
  enableProjectManagerRole: "https://customoffice.freshdesk.com/a/solutions/articles/30000042595",
  enableStandardMachinesSettingsPage:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042641",
  enableStandardProductsSettingsPage:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042640",
  enableTaskEstimation: "https://customoffice.freshdesk.com/a/solutions/articles/30000042037",
  enableTimeOverviewPage: "https://support.customoffice.dk/a/solutions/articles/30000042137",
  enableTransferTasks: "https://customoffice.freshdesk.com/a/solutions/articles/30000042038",
  enableUnscheduledCalendar: "https://customoffice.freshdesk.com/a/solutions/articles/30000041939",
  enableWorkshopChecklistsFor:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042612",
  enableWorkTypeSettingsPage: "https://customoffice.freshdesk.com/a/solutions/articles/30000042633",
  errorOnOnlyPuller: "https://customoffice.freshdesk.com/a/solutions/articles/30000042039",
  externalTaskCulture: "https://customoffice.freshdesk.com/a/solutions/articles/30000042638",
  externalTaskCustomer: "https://customoffice.freshdesk.com/a/solutions/articles/30000042011",
  externalTaskWithoutTaskWorkTypeVariantLegal:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042623",
  foodBookingNotificationTime:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042621",
  geolocation: "https://support.customoffice.dk/a/solutions/articles/30000036998",
  googleMapsCountryRestrictions: "https://support.customoffice.dk/a/solutions/articles/30000042019",
  hideCompleteAndMakeCopyButton: "https://support.customoffice.dk/a/solutions/articles/30000042405",
  hideCultureMenuFromMachineOperators:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042639",
  hideCustomerMenuFromMachineOperators:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042012",
  hideInternalTaskListWorkTypes:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042249",
  hideOrderCalendarTab: "https://customoffice.freshdesk.com/a/solutions/articles/30000041953",
  hideTasklistBillingTab: "https://support.customoffice.dk/a/solutions/articles/30000042322",
  hideUnscheduledCalendarForMachineOperators:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000041940",
  hideUnscheduledWorkshopTasks:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042613",
  holidaysVisibleToMachineOperators:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000041941",
  hoursAbsenceTypes: "https://customoffice.freshdesk.com/a/solutions/articles/30000042083",
  ifCountZeroRequireCorrectedCountZero:
    "https://support.customoffice.dk/a/solutions/articles/30000042589",
  includeTaskCancel: "https://support.customoffice.dk/a/solutions/articles/30000042406",
  includeTaskCopy: "https://support.customoffice.dk/a/solutions/articles/30000042415",
  informationPageEnabled: "https://customoffice.freshdesk.com/a/solutions/articles/30000041905",
  internalTaskAutoWorkTypePreviousMachines:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042141",
  internalTaskPhotos: "https://support.customoffice.dk/a/solutions/articles/30000042321",
  invoiceAllowRecordedWithoutInvoiceLines:
    "https://support.customoffice.dk/a/solutions/articles/30000042590",
  locationStoragePageVisibleToEveryone:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042027",
  lunchBookingLocations: "https://customoffice.freshdesk.com/a/solutions/articles/30000042620",
  lunchBookings: "https://customoffice.freshdesk.com/a/solutions/articles/30000042616",
  machineBeforeMachineOperator:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042040",
  machineCalendar: "https://customoffice.freshdesk.com/a/solutions/articles/30000042135",
  machineOperaterCanEditInvoiceNote:
    "https://support.customoffice.dk/a/solutions/articles/30000042323",
  machineOperatorCanDeleteAssignedTasks:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042041",
  machineOperatorMayAddTaskPhotosAfterCompletion:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042321",
  machineOperatorsCanChooseProject:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042149",
  machineOperatorsCanOnlySeeTheirOwnTasks:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042244",
  machineOperatorsCanOnlySeeThemselvesOnCalendar:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000041942",
  machineOperatorsCanSeeAbsenceCalendar:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000041903",
  machineOperatorsCanSeeTheirOwnTasksAndActiveTasks:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042042",
  mapVisibleToAll: "https://support.customoffice.dk/a/solutions/articles/30000041919",
  mapVisibleToJobberRole: "https://support.customoffice.dk/a/solutions/articles/30000041921",
  materialDecimals: "https://customoffice.freshdesk.com/a/solutions/articles/30000042079",
  materialIssuesErrors: "https://customoffice.freshdesk.com/a/solutions/articles/30000042076",
  materialZeroCausesError: "https://customoffice.freshdesk.com/a/solutions/articles/30000042320",
  missingEffectiveTimeIsError:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042044",
  noProducts: "https://customoffice.freshdesk.com/a/solutions/articles/30000042077",
  numberOfSalaryVouchersVisibleToEmployees:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000041920",
  oldTaskWarningAgeMinutes: "https://customoffice.freshdesk.com/a/solutions/articles/30000042047",
  oneDayLessVisibleToMachineOperatorsBefore:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042246",
  onlyAdminCanChangeDepartment: "https://support.customoffice.dk/a/solutions/articles/30000042112",
  onlyAllowEditingNameOnPriceGroupSettingsPage:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042628",
  onlyManagerCreatedTasksCalendarPlanned:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000041943",
  onlyManagersCanRegisterAbsence:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042078",
  orderCalendar: "https://customoffice.freshdesk.com/a/solutions/articles/30000041952",
  orderCalendarAsTaskCalendar:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000041961",
  orderCalendarFoldAllDefault:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000041955",
  orderCalendarMachineLinebreak:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000041958",
  orderCalendarShowCreatedDate:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000041960",
  orderCalendarShowOrderNoteLines:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000041959",
  orderCopyFields: "https://customoffice.freshdesk.com/a/solutions/articles/30000042002",
  orderDrafts: "https://customoffice.freshdesk.com/a/solutions/articles/30000041975",
  orderDraftsAllwaysCreateDraft:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000041977",
  orderDraftsStartOnDraft: "https://customoffice.freshdesk.com/a/solutions/articles/30000041979",
  orderEntryAutoOpenCustomerSelection:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000041985",
  orderEntryResultsEffective: "https://customoffice.freshdesk.com/a/solutions/articles/30000042000",
  orderEntryResultsWorkplace: "https://customoffice.freshdesk.com/a/solutions/articles/30000042001",
  orderEntryShowContact: "https://customoffice.freshdesk.com/a/solutions/articles/30000041988",
  orderEntryShowPriceGroup: "https://customoffice.freshdesk.com/a/solutions/articles/30000041993",
  orderEntryShowResults: "https://customoffice.freshdesk.com/a/solutions/articles/30000041999",
  orderEntryShowWorkPlace: "https://customoffice.freshdesk.com/a/solutions/articles/30000041989",
  orderEntryTaskTime: "https://customoffice.freshdesk.com/a/solutions/articles/30000041997",
  orderListPageEnabled: "https://customoffice.freshdesk.com/a/solutions/articles/30000034609",
  orderReferenceNumberLabel: "https://customoffice.freshdesk.com/a/solutions/articles/30000041982",
  orderValidation: "https://customoffice.freshdesk.com/a/solutions/articles/30000041974",
  orderValidationForWorkTypes:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000041981",
  overviewShowPrices: "https://customoffice.freshdesk.com/a/solutions/articles/30000042644",
  ownCompanyId: "https://customoffice.freshdesk.com/a/solutions/articles/30000042070",
  preparationWorkType: "https://customoffice.freshdesk.com/a/solutions/articles/30000042606",
  priceItemConversionSetup: "https://customoffice.freshdesk.com/a/solutions/articles/30000042646",
  priceItemRelationUnitConversionHelpers:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042651",
  productImageSelection: "https://customoffice.freshdesk.com/a/solutions/articles/30000042315",
  productMultiSelect: "https://customoffice.freshdesk.com/a/solutions/articles/30000042314",
  projectMissingCompleteError: "https://support.customoffice.dk/a/solutions/articles/30000042404",
  projectMissingCompleteWarning: "https://support.customoffice.dk/a/solutions/articles/30000042403",
  projectMissingValidateError: "https://support.customoffice.dk/a/solutions/articles/30000042118",
  projectMissingValidateWarning: "https://support.customoffice.dk/a/solutions/articles/30000042117",
  recordCultureTasks: "https://customoffice.freshdesk.com/a/solutions/articles/30000042634",
  recordCustomerTasks: "https://customoffice.freshdesk.com/a/solutions/articles/30000042024",
  recordInternalTasks: "https://customoffice.freshdesk.com/a/solutions/articles/30000042423",
  registerAbsence: "https://customoffice.freshdesk.com/a/solutions/articles/30000042072",
  repairWorkType: "https://customoffice.freshdesk.com/a/solutions/articles/30000042614",
  requireFieldForCultureTasks:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042048",
  requireWorkplaceIfExists: "https://customoffice.freshdesk.com/a/solutions/articles/30000042074",
  routesEnabled: "https://customoffice.freshdesk.com/a/solutions/articles/30000042602",
  routeTaskStartStop: "https://customoffice.freshdesk.com/a/solutions/articles/30000042604",
  salaryVouchersVisibleToEmployees:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000041918",
  salaryVouchersVisibleToManagers:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000041922",
  selectInformationvisibleTo: "https://customoffice.freshdesk.com/a/solutions/articles/30000041901",
  sendApprovedTransportlogToCustomers:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042435",
  sendValidatedGenericLogToCustomers:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042437",
  seniorMachineOperatorCanEditNotesFromManager:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042142",
  seniorMachineOperatorCanManageOrders:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042143",
  showAccountOnCustomerList: "https://customoffice.freshdesk.com/a/solutions/articles/30000035780",
  showArrivalAtLocationField: "https://customoffice.freshdesk.com/a/solutions/articles/30000042153",
  showAttOnCustomerList: "https://customoffice.freshdesk.com/a/solutions/articles/30000042017",
  showC5AccountOnTaskInstanceAnList:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042069",
  showContinueToNextCustomerButton:
    "https://support.customoffice.dk/a/solutions/articles/30000042418",
  showDateGroupsOnOrderLists: "https://customoffice.freshdesk.com/a/solutions/articles/30000042010",
  showFieldNumbersOnCalendar: "https://customoffice.freshdesk.com/a/solutions/articles/30000041933",
  showFieldsColumnOnOrderEntryTaskList:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000041995",
  showFieldsOnBookkeepingTables: "https://support.customoffice.dk/a/solutions/articles/30000042108",
  showFieldsOnGeolocationMap: "https://support.customoffice.dk/a/solutions/articles/30000041917",
  showGenericPeriodLogView: "https://customoffice.freshdesk.com/a/solutions/articles/30000042439",
  showInvoiceNote: "https://customoffice.freshdesk.com/a/solutions/articles/30000042154",
  showLandlinePhoneNumberOnTaskInfo:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042051",
  showLogEmailFieldOnCustomerInstance:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042438",
  showLogLocationsOnBookkeeping: "https://support.customoffice.dk/a/solutions/articles/30000042586",
  showMachineAsWorktypeForContratorTasks: "Maskine som arbejdsområde",
  showMachineLastUse: "https://customoffice.freshdesk.com/a/solutions/articles/30000042064",
  showMachineOperatorInitialsOnTaskInstance:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042052",
  showMachineOperatorNameOnTaskInstance:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042052",
  showManagerInternalOrderNoteLinesOnOrderCalendar:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000041970",
  showMaterialAutoLinesToEmployees:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042311",
  showNoteOnInvoiceLineTable: "https://support.customoffice.dk/a/solutions/articles/30000042548",
  showOrderCreatedDateTime: "https://customoffice.freshdesk.com/a/solutions/articles/30000041987",
  showOrderReferenceNumberOnTaskInfoTab:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042307",
  showOursToggle: "https://customoffice.freshdesk.com/a/solutions/articles/30000042080",
  showPhotoOnCalendar: "https://customoffice.freshdesk.com/a/solutions/articles/30000041951",
  showProjectInsteadOfCustomer:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000041950",
  showProjectNameOnTaskInfoTab:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042304",
  showProjectNameOnTimeOverviewPage:
    "https://support.customoffice.dk/a/solutions/articles/30000041924",
  showProjectNumberOnTaskInfoTab:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042305",
  showProjectNumberOnTimeOverviewPage:
    "https://support.customoffice.dk/a/solutions/articles/30000042138",
  showProjectOnTaskInstance: "https://customoffice.freshdesk.com/a/solutions/articles/30000042306",
  showSearchnameOnCustomerList:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042018",
  showStorageOnTaskFieldMap: "https://support.customoffice.dk/a/solutions/articles/30000042025",
  showTaskEffectiveTimeOnTaskInfoTab:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042063",
  showTaskNotesOnOrderEntryPage:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000041998",
  showTaskRefColumnOnOrderEntryTaskList:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000041996",
  showTimeColumnOnOrderEntryTaskList:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000041983",
  showUnscheduledInternalTaskCalendar:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000041935",
  showWhoHasNotReadInformation:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000041899",
  showWorkplaceOnTaskInstance:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042075",
  showWorkshopMachineName: "https://customoffice.freshdesk.com/a/solutions/articles/30000042609",
  singleDayAbsenceTypes: "https://customoffice.freshdesk.com/a/solutions/articles/30000042092",
  suggestRecentlyUsedWorkTypes:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042148",
  switchProjectAndCustomerButtonLocation:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042151",
  taskAndOrderDatesFromCalendarOnCreate:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000041946",
  taskCompleteOverview: "https://customoffice.freshdesk.com/a/solutions/articles/30000042055",
  taskCompletePrepareQuery: "https://customoffice.freshdesk.com/a/solutions/articles/30000042326",
  taskCompleteRepairQuery: "https://customoffice.freshdesk.com/a/solutions/articles/30000042324",
  taskCompleteRepairQueryRequireNote:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042325",
  taskCopyAlwaysOverridemachineOperator:
    "https://support.customoffice.dk/a/solutions/articles/30000042417",
  taskCopyFields: "https://support.customoffice.dk/a/solutions/articles/30000042416",
  taskInstancePDF: "https://customoffice.freshdesk.com/a/solutions/articles/30000042065",
  taskListsMachineOperatorHideCompleted:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042247",
  taskListsMachineOperatorHideCompletedExceptToday:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042248",
  taskNoteLinesOnTaskOrderCalender:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000041976",
  taskOrderAutoOpenCustomerSelection:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042049",
  taskOrderShowContact: "https://customoffice.freshdesk.com/a/solutions/articles/30000042056",
  taskOrderShowDate: "https://customoffice.freshdesk.com/a/solutions/articles/30000042057",
  taskOrderShowTime: "https://customoffice.freshdesk.com/a/solutions/articles/30000042058",
  taskOrderTaskShowWorkPlace: "https://customoffice.freshdesk.com/a/solutions/articles/30000042059",
  taskOverlapWarningAfterMinutes:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000041947",
  taskPageCreatedDate: "https://customoffice.freshdesk.com/a/solutions/articles/30000042067",
  taskPickupDelivery: "https://customoffice.freshdesk.com/a/solutions/articles/30000042303",
  taskShowTimeField: "https://customoffice.freshdesk.com/a/solutions/articles/30000042061",
  taskWizardAskForWorkPlace: "https://customoffice.freshdesk.com/a/solutions/articles/30000042146",
  taskWizardFieldSelection: "https://customoffice.freshdesk.com/a/solutions/articles/30000042147",
  treatTransportTimeAsEffectiveForDepartments:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042050",
  unregisteredWarningAfterMinutes:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000041948",
  unsetValidatedAndRecordedOnError:
    "https://support.customoffice.dk/a/solutions/articles/30000042527",
  useApproveReport: "https://support.customoffice.dk/a/solutions/articles/30000042101",
  useCalendarWorkHours: "https://customoffice.freshdesk.com/a/solutions/articles/30000041944",
  useEmployeeGroups: "https://customoffice.freshdesk.com/a/solutions/articles/30000042132",
  userCellphoneExtra: "https://customoffice.freshdesk.com/a/solutions/articles/30000042596",
  userProfileEmployeeNumber: "https://customoffice.freshdesk.com/a/solutions/articles/30000042597",
  userProfileNormalTransportKilometers:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042600",
  userProfileNormalTransportKilometersDefault:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042598",
  userProfileNormalTransportMinutesDefault:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042599",
  warnIfNoWorkplace: "https://customoffice.freshdesk.com/a/solutions/articles/30000042073",
  warnOnIncompleteRouteParts: "https://customoffice.freshdesk.com/a/solutions/articles/30000042603",
  workplaceButtonUnderCustomer:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042068",
  workshopCreatedByColumn: "https://customoffice.freshdesk.com/a/solutions/articles/30000042608",
  workshopCreatedDateColumn: "https://customoffice.freshdesk.com/a/solutions/articles/30000042607",
  workshopEnabled: "https://customoffice.freshdesk.com/a/solutions/articles/30000042605",
  workshopVehicleIdentificationNumber:
    "https://customoffice.freshdesk.com/a/solutions/articles/30000042611",
  workshopWorkTypes: "https://customoffice.freshdesk.com/a/solutions/articles/30000042610",
  workTypeAutoMachines: "https://customoffice.freshdesk.com/a/solutions/articles/30000042622",
};
