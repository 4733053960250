import {Location, LocationType, LocationUrl, ProductUrl, TaskUrl} from "@co-common-libs/resources";
import React, {useMemo} from "react";
import {isLocationWithGeoJson} from "../field-polygons";
import {Fields} from "./fields";
import {LocationMarkersWithMenu} from "./location-markers-with-menu";

interface ActiveLocationsProps {
  readonly locationProductCounts: ReadonlyMap<LocationUrl, ReadonlyMap<ProductUrl, number>>;
  readonly locations: readonly Location[];
  readonly locationTaskUrlsMap: ReadonlyMap<LocationUrl, ReadonlySet<TaskUrl>>;
  readonly locationTypes: readonly LocationType[];
  readonly map: google.maps.Map;
  readonly markerTypes: readonly string[];
}

export function ActiveLocations(props: ActiveLocationsProps): JSX.Element {
  const {locationProductCounts, locations, locationTaskUrlsMap, locationTypes, map, markerTypes} =
    props;

  const fields = useMemo(() => locations.filter(isLocationWithGeoJson), [locations]);
  return (
    <>
      <LocationMarkersWithMenu
        showAsActive
        locationArray={locations}
        locationProductCounts={locationProductCounts}
        locationTaskUrlsMap={locationTaskUrlsMap}
        map={map}
        selectedMarkerTypes={markerTypes}
        usedLocationTypes={locationTypes}
      />
      <Fields fields={fields} map={map} />
    </>
  );
}
