import {Command, ResourceInstance} from "@co-common-libs/resources";

interface SerializableErrorBase {
  message: string;
  name: string;
}

interface SerializableStatusError extends SerializableErrorBase {
  data: any;
  statusCode: number;
  type: "StatusError";
}

interface SerializableNetworkError extends SerializableErrorBase {
  type: "NetworkError";
}

interface SerializableOtherError extends SerializableErrorBase {
  type: "OtherError";
}

export type SerializableError =
  | SerializableNetworkError
  | SerializableOtherError
  | SerializableStatusError;

export interface OldCommand {
  readonly action: "CREATE" | "DELETE" | "UPDATE";
  readonly instance: ResourceInstance;
}

export abstract class CommitDBConnection {
  abstract getAll(): Promise<
    readonly {
      readonly apiVersion: string | null;
      readonly command: Command;
      readonly error: SerializableError | null;
      readonly errorTimestamp: string | null;
      readonly frontendVersion: string | null;
      readonly id: number;
    }[]
  >;
  abstract put(
    id: number,
    command: Command,
    apiVersion: string,
    frontendVersion: string,
  ): Promise<void>;
  abstract setError(id: number, error: SerializableError, errorTimestamp: string): Promise<void>;
  abstract delete(id: number): Promise<void>;
  abstract vacuum(): Promise<void>;
  abstract close(): Promise<void>;
  abstract deleteDatabase(): Promise<void>;
}
