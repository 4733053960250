import {ResourceInstance, resourceNames} from "@co-common-libs/resources";
import {Query, QueryTimestampsStruct} from "@co-frontend-libs/db-resources";
import {getFrontendSentry} from "@co-frontend-libs/utils";
import {Draft} from "@reduxjs/toolkit";
import _ from "lodash";
import type {DeepWritable, MarkWritable} from "ts-essentials";
import {loadOffline} from "../actions";
import {QueryQueryStateStruct, QueryState, ResourcesState, emptyQueryState} from "../types";

function handleLoadOfflineFulfilledQueries(
  state: Draft<ResourcesState>,
  querySet: Query[],
  queryTimestampsMap: Partial<{
    [keyString: string]: QueryTimestampsStruct;
  }>,
): void {
  console.assert(Object.keys(state.persistedQueries).length === 0);
  const persistedQueries: Partial<Record<string, QueryQueryStateStruct>> = {};
  querySet.forEach((query) => {
    const {keyString} = query;
    console.assert(persistedQueries[keyString] === undefined);
    const timestamps = queryTimestampsMap[keyString];
    const queryState: MarkWritable<
      QueryState,
      "fullFetchDataComputedAtTimestamp" | "takenIntoAccountForChangesComputedAtTimestamp"
    > = {...emptyQueryState};
    if (timestamps) {
      const {fullTimestamp, updateTimestamp} = timestamps;
      queryState.fullFetchDataComputedAtTimestamp = fullTimestamp;
      queryState.takenIntoAccountForChangesComputedAtTimestamp = updateTimestamp;
    }
    state.persistedQueries[keyString] = {
      query: _.cloneDeep(query) as DeepWritable<Query>,
      queryState,
    };
  });
}

function handleLoadOfflineFulfilledData(
  state: Draft<ResourcesState>,
  resourceInstanceMap: Partial<{
    [resourceName: string]: ResourceInstance[];
  }>,
): void {
  resourceNames.forEach((resourceName) => {
    const instanceArray = resourceInstanceMap[resourceName];
    if (instanceArray) {
      const instanceRecord: Partial<{[url: string]: ResourceInstance}> = {};
      instanceArray.forEach((instance) => {
        const {url} = instance;
        console.assert(
          instanceRecord[url] === undefined,
          `offline db contains multiple instances for same URL: ${url}`,
        );
        instanceRecord[url] = instance;
      });
      // Pretend to fulfill type checks...
      state.persistedData[resourceName] = instanceRecord as Draft<Partial<{[url: string]: any}>>;
    }
  });
}

export function handleLoadOfflineFulfilled(
  state: Draft<ResourcesState>,
  action: ReturnType<typeof loadOffline.fulfilled>,
): void {
  const {
    changesTimestamp,
    idFetch,
    querySet,
    queryTimestampsMap,
    relatedFetch,
    resourceInstanceMap,
  } = action.payload;
  console.assert(!state.offlineLoaded);
  state.offlineLoaded = true;
  handleLoadOfflineFulfilledQueries(state, querySet, queryTimestampsMap);
  handleLoadOfflineFulfilledData(state, resourceInstanceMap);
  console.assert(Object.keys(state.persistedFetchByID).length === 0);
  console.assert(Object.keys(state.persistedFetchByRelation).length === 0);
  state.persistedFetchByID = idFetch as Draft<typeof idFetch>;
  state.persistedFetchByRelation = relatedFetch as Draft<typeof relatedFetch>;
  if (changesTimestamp) {
    state.lastFetchChangesTimestamp = changesTimestamp;
  }
}

export function handleLoadOfflineRejected(
  state: Draft<ResourcesState>,
  action: ReturnType<typeof loadOffline.rejected>,
): void {
  const sentry = getFrontendSentry();
  sentry.withScope((scope) => {
    scope.setTag("action", "handleLoadOfflineRejected");
    sentry.captureMessage("Local DB Error", "error");
  });
  state.localDBError = action.payload || (action.error as any);
}
