import {diacriticsCorrectedPosition, normalizeSearchText} from "@co-common-libs/utils";
import _ from "lodash";
import {Match, SearchField} from "./types";

function getMatchScore(match: Match): number {
  return match.score;
}

const START_MATCH_SCORE_MULTIPLIER = 10;

// const needleArray = normalizeSearchTextArray(filterString);
export function findMatches(
  matchType: "AND" | "OR",
  needleArray: readonly string[],
  searchFields: readonly SearchField[],
): Match[] {
  // foundNeedleWords only relevant for "AND"-matches, but that's the usual
  // case, so we just track it anyway...
  const foundNeedleWords = new Set<string>();
  const matches: Match[] = [];
  for (const {label, priority, text} of searchFields) {
    // Outer loop on searchFields, inner loop on needleArray; to avoid repeated
    // normalizeSearchText() on the same text.  (Entries in needleArray are
    // normalised outside call to findMatches().)  ... this does however mean
    // that we can't early-return on failure to match a needleWord in
    // AND-mode...
    if (text) {
      let score = 0;
      const trimmedText = text.trim();
      const normalisedText = normalizeSearchText(text);
      const matching: {fromPos: number; toPos: number}[] = [];
      for (const needleWord of needleArray) {
        const index = normalisedText.indexOf(needleWord);
        if (index !== -1) {
          foundNeedleWords.add(needleWord);
          // eslint-disable-next-line max-depth
          if (trimmedText.length === normalisedText.length) {
            matching.push({fromPos: index, toPos: index + needleWord.length});
          } else {
            // e.g. "æ" in trimmedText becomes "ae" in normalisedText;
            // correct for this to visualise the correct offest for the input text
            const fromPos = diacriticsCorrectedPosition(trimmedText, index);
            const toPos = diacriticsCorrectedPosition(trimmedText, index + needleWord.length);
            // eslint-disable-next-line max-depth
            if (fromPos !== toPos) {
              matching.push({
                fromPos,
                toPos,
              });
            }
          }
          // Strong preference for match at start...
          score += index === 0 ? priority * START_MATCH_SCORE_MULTIPLIER : priority;
        }
      }
      if (matching.length) {
        matches.push({
          label,
          matching,
          score,
          text: trimmedText,
        });
      }
    }
  }
  if (matches.length && (matchType === "OR" || foundNeedleWords.size === needleArray.length)) {
    return _.sortBy(matches, getMatchScore);
  } else {
    return [];
  }
}
