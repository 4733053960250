import {resourceNames} from "@co-common-libs/resources";
import * as actions from "../resources/actions";
import {QueryQueryStateStruct} from "../resources/types";
import {ResourcesAuthenticationMiddlewareAPI} from "./types";

const MAX_FETCH_VALUES_PARAMETER = 100;

export function startMissingRelatedFetch(
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  baseURL: string,
): void {
  const {
    fetchByRelationActive,
    persistedFetchByRelation,
    persistedQueries,
    temporaryFetchByRelation,
    temporaryQueries,
  } = middlewareApi.getState().resources;
  if (
    (Object.values(persistedQueries) as QueryQueryStateStruct[]).some(
      ({queryState}) => queryState.currentlyFullFetching,
    )
  ) {
    return;
  }
  if (
    (Object.values(temporaryQueries) as QueryQueryStateStruct[]).some(
      ({queryState}) => queryState.currentlyFullFetching,
    )
  ) {
    return;
  }
  resourceNames.forEach((resourceName) => {
    if (fetchByRelationActive[resourceName]) {
      return;
    }
    const persistedForResource = persistedFetchByRelation[resourceName];
    const temporaryForResourcu = temporaryFetchByRelation[resourceName];
    const parametersForResource = persistedForResource || temporaryForResourcu;
    if (parametersForResource) {
      const entries = Object.entries(parametersForResource) as [string, readonly string[]][];
      console.assert(entries.length > 0);
      const [memberName, allValues] = entries[0];
      console.assert(allValues.length > 0);
      const values = allValues.slice(0, MAX_FETCH_VALUES_PARAMETER);
      middlewareApi.dispatch(
        actions.performRelatedFetch({
          baseURL,
          memberName,
          resourceName,
          values,
        }),
      );
    }
  });
}
