import {
  Machine,
  MachineGroup,
  MachineGroupUrl,
  MachineUrl,
  PriceGroup,
  WorkType,
} from "@co-common-libs/resources";
import {MachineDialog, MultipleMachinesDialog} from "@co-frontend-libs/components";
import {
  AppState,
  getCustomerSettings,
  getCustomerSettingsMember,
  getFilteredMachineArray,
  getMachineGroupLookup,
} from "@co-frontend-libs/redux";
import {BarcodeFormat} from "@co-frontend-libs/utils";
import {connect} from "react-redux";
import {createSelector, createStructuredSelector} from "reselect";

interface MachineDialogStateProps {
  barcodeScannerFormats: readonly BarcodeFormat[] | null;
  machineArray: readonly Machine[];
  machineGroupLookup?: (url: MachineGroupUrl) => MachineGroup | undefined;
  machineLabelVariant: "MACHINE" | "VEHICLE";
}

interface MachineDialogOwnProps {
  onCancel(): void;
  onOk(url: MachineUrl): void;
  open: boolean;
  priceGroup?: PriceGroup | undefined;
  workType?: WorkType | undefined;
}

const getMachineLabelVariant: (state: AppState) => "MACHINE" | "VEHICLE" = createSelector(
  getCustomerSettings,
  (customerSettings) => customerSettings.machineLabelVariant,
);

const filterOutSmallMachines = (machineArray: readonly Machine[]): readonly Machine[] => {
  return machineArray.filter((machine) => !machine.smallMachine);
};

const getSmallMachineFilteredMachineArray: (state: AppState) => readonly Machine[] = createSelector(
  getFilteredMachineArray,
  filterOutSmallMachines,
);

const getBarcodeScannerFormats = getCustomerSettingsMember("barcodeScannerMachineDialog");

export const ConnectedMachineDialog: React.ComponentType<MachineDialogOwnProps> = connect<
  MachineDialogStateProps,
  object,
  MachineDialogOwnProps,
  AppState
>(
  createStructuredSelector<AppState, MachineDialogStateProps>({
    barcodeScannerFormats: getBarcodeScannerFormats,
    machineArray: getFilteredMachineArray,
    machineGroupLookup: getMachineGroupLookup,
    machineLabelVariant: getMachineLabelVariant,
  }),
  {},
)(MachineDialog);

export const ConnectedMachineDialogWithoutSmallMachines: React.ComponentType<MachineDialogOwnProps> =
  connect<MachineDialogStateProps, object, MachineDialogOwnProps, AppState>(
    createStructuredSelector<AppState, MachineDialogStateProps>({
      barcodeScannerFormats: getBarcodeScannerFormats,
      machineArray: getSmallMachineFilteredMachineArray,
      machineGroupLookup: getMachineGroupLookup,
      machineLabelVariant: getMachineLabelVariant,
    }),
    {},
  )(MachineDialog);

interface MultipleMachinesDialogStateProps {
  machineArray: readonly Machine[];
  machineGroupLookup?: (url: MachineGroupUrl) => MachineGroup | undefined;
  machineLabelVariant: "MACHINE" | "VEHICLE";
}

interface MultipleMachinesDialogOwnProps {
  barcodeScannerFormats?: readonly BarcodeFormat[] | null | undefined;
  includeSelectAll?: boolean;
  onCancel(): void;
  onOk(urls: ReadonlySet<MachineUrl>): void;
  open: boolean;
  priceGroup?: PriceGroup;
  selected?: ReadonlySet<MachineUrl> | undefined;
  workType?: WorkType;
}

export const ConnectedMultipleMachinesDialog: React.ComponentType<MultipleMachinesDialogOwnProps> =
  connect<MultipleMachinesDialogStateProps, object, MultipleMachinesDialogOwnProps, AppState>(
    createStructuredSelector<AppState, MultipleMachinesDialogStateProps>({
      machineArray: getFilteredMachineArray,
      machineGroupLookup: getMachineGroupLookup,
      machineLabelVariant: getMachineLabelVariant,
    }),
    {},
  )(MultipleMachinesDialog);

export const ConnectedMultipleMachinesDialogWithoutSmallMachines: React.ComponentType<MultipleMachinesDialogOwnProps> =
  connect<MultipleMachinesDialogStateProps, object, MultipleMachinesDialogOwnProps, AppState>(
    createStructuredSelector<AppState, MultipleMachinesDialogStateProps>({
      machineArray: getSmallMachineFilteredMachineArray,
      machineGroupLookup: getMachineGroupLookup,
      machineLabelVariant: getMachineLabelVariant,
    }),
    {},
  )(MultipleMachinesDialog);
