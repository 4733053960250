import {PriceGroup, PriceGroupUrl, ReportingSpecificationUrl} from "@co-common-libs/resources";

export function computeLogLegalPriceGroups(
  priceGroupArray: readonly PriceGroup[],
  reportingSpecification: ReportingSpecificationUrl,
  reportingSpecificationRequired: boolean,
): Set<PriceGroupUrl> {
  return new Set(
    priceGroupArray
      .filter(
        (priceGroup) =>
          priceGroup.reportingSpecification === reportingSpecification ||
          (!reportingSpecificationRequired && priceGroup.reportingSpecification === null),
      )
      .map(({url}) => url),
  );
}
