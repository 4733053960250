import {createReducer} from "@reduxjs/toolkit";
import * as actions from "./actions";

export type DeviceConfigState = {
  readonly data: {
    readonly [key: string]: any;
  };
  readonly loaded: boolean;
};

const initialState: DeviceConfigState = {
  data: {
    loginRoute: "/login",
    machine: null,
    useMode: "normal",
  },
  loaded: false,
};

export const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(actions.configLoad.fulfilled, (state, action) => ({
      data: {
        ...state.data,
        ...action.payload,
      },
      loaded: true,
    }))
    .addCase(actions.configPut.pending, (state, action) => {
      const {key, value} = action.meta.arg;
      state.data[key] = value;
    })
    .addCase(actions.configRemove.pending, (state, action) => {
      const {key} = action.meta.arg;
      delete state.data[key];
    }),
);
