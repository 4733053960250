import {createReducer} from "@reduxjs/toolkit";
import * as actions from "./actions";

export type SnackbarState = {
  readonly message: string;
  readonly timestamp: string | null;
};

const initialState: SnackbarState = {
  message: "",
  timestamp: null,
};

export const reducer = createReducer(initialState, (builder) =>
  builder.addCase(actions.setMessage, (_state, action) => action.payload),
);
