import {SettingID} from "@co-common-libs/config";
import {ResponsiveDialog, SelectColor} from "@co-frontend-libs/components";
import {
  actions,
  getCurrentUserURL,
  getCustomerSettings,
  getSettingsEntryLookupByIdentifier,
} from "@co-frontend-libs/redux";
import {
  DialogContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import CloseIcon from "mdi-react/CloseIcon";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {defaultAbsenceTypeLabels} from "./absence-type-labels";

interface AbsenceTypeColorsDialogDialogProps {
  onClose: () => void;
  open: boolean;
  settingID: SettingID;
}

const ColorButton = ({
  color,
  identifier,
  onClick,
}: {
  color: string;
  identifier: string;
  onClick: (identifier: string, color: string) => void;
}): JSX.Element => {
  const handleClick = useCallback(
    (selectedColor: string) => {
      onClick(identifier, selectedColor);
    },
    [identifier, onClick],
  );

  return <SelectColor color={color} onColorSelected={handleClick} />;
};

const ResetButton = ({
  identifier,
  onClick,
}: {
  identifier: string;
  onClick: (identifier: string) => void;
}): JSX.Element => {
  const handleClick = useCallback(() => {
    onClick(identifier);
  }, [identifier, onClick]);

  return (
    <IconButton onClick={handleClick}>
      <CloseIcon />
    </IconButton>
  );
};

export function AbsenceTypeColorsDialog(props: AbsenceTypeColorsDialogDialogProps): JSX.Element {
  const {onClose, open, settingID} = props;

  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const settingEntry = settingsEntryLookupByIdentifier(settingID);
  const customerSettings = useSelector(getCustomerSettings);

  const absenceTypeLabels: {[label: string]: string} = useMemo(
    () => customerSettings.absenceTypeLabels || {},
    [customerSettings.absenceTypeLabels],
  );

  const absenceTypeColors: {[label: string]: string} = useMemo(
    () => settingEntry?.data || {},
    [settingEntry?.data],
  );

  const usedAbsenceTypeLabels: {[key: string]: string} = useMemo(
    () => ({
      ...defaultAbsenceTypeLabels,
      ...absenceTypeLabels,
    }),
    [absenceTypeLabels],
  );

  const [colors, setColors] = useState<
    Readonly<{
      [identifier: string]: Readonly<{color: string; label: string}>;
    }>
  >({});

  useEffect(() => {
    if (open) {
      const colorEntries: {
        [identifier: string]: {color: string; label: string};
      } = {};
      Object.entries(usedAbsenceTypeLabels).forEach(([identifier, label]) => {
        colorEntries[identifier] = {
          color: absenceTypeColors[identifier] || customerSettings.absenceCalendarBlockColor,
          label,
        };
      });
      setColors(colorEntries);
    }
  }, [absenceTypeColors, customerSettings.absenceCalendarBlockColor, usedAbsenceTypeLabels, open]);

  const dispatch = useDispatch();
  const currentUserURL = useSelector(getCurrentUserURL);

  const handleSave = useCallback(() => {
    const newValue: {[identifier: string]: string} = {};

    Object.entries(colors).forEach(([identifier, entry]) => {
      if (entry.color !== customerSettings.absenceCalendarBlockColor) {
        newValue[identifier] = entry.color;
      }
    });

    if (settingEntry) {
      dispatch(
        actions.update(settingEntry.url, [
          {member: "changedBy", value: currentUserURL},
          {member: "data", value: newValue},
        ]),
      );
    }
    onClose();
  }, [
    colors,
    currentUserURL,
    customerSettings.absenceCalendarBlockColor,
    dispatch,
    onClose,
    settingEntry,
  ]);

  const handleColorClick = useCallback(
    (identifier: string, color: string) => {
      const newColors = {...colors};
      newColors[identifier] = {...newColors[identifier], color};
      setColors(newColors);
    },
    [colors],
  );

  const handleResetClick = useCallback(
    (identifier: string) => {
      handleColorClick(identifier, customerSettings.absenceCalendarBlockColor);
    },
    [customerSettings.absenceCalendarBlockColor, handleColorClick],
  );

  return (
    <ResponsiveDialog
      fullWidth
      okLabel={<FormattedMessage defaultMessage="Gem" id="setting-dialog.label.save" />}
      open={open}
      title={
        <FormattedMessage
          defaultMessage="Vælg farver"
          id="system-setup.dialog-title.select-colors"
        />
      }
      onCancel={onClose}
      onOk={handleSave}
    >
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{width: 200}}>
                <FormattedMessage
                  defaultMessage="Fraværstype"
                  id="system-setup.dialog-title.absence-type"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage defaultMessage="Farve" id="system-setup.dialog-title.color" />
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(colors).map(([identifier, entry]) => {
              return (
                <TableRow key={identifier}>
                  <TableCell>{entry.label}</TableCell>
                  <TableCell>
                    <ColorButton
                      color={entry.color}
                      identifier={identifier}
                      onClick={handleColorClick}
                    />
                  </TableCell>
                  <TableCell>
                    {entry.color !== customerSettings.absenceCalendarBlockColor ? (
                      <ResetButton identifier={identifier} onClick={handleResetClick} />
                    ) : null}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </DialogContent>
    </ResponsiveDialog>
  );
}
