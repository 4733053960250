import {PriceGroup, PriceGroupUrl} from "@co-common-libs/resources";
import {memoizeForceReuse} from "@co-frontend-libs/utils";
import React, {useMemo} from "react";
import {useIntl} from "react-intl";
import {
  EntryData,
  GenericMultiSelectionSearchDialog,
  GenericSingleSelectionSearchDialog,
} from "../search-dialog";

export function computeBaseChoicesForPriceGroups(
  priceGroupArray: readonly PriceGroup[],
): readonly EntryData<PriceGroupUrl>[] {
  const result: EntryData<PriceGroupUrl>[] = priceGroupArray.map((instance) => {
    const {identifier, name, url} = instance;
    const entry: EntryData<PriceGroupUrl> = {
      category: "standard",
      exactMatchValue: identifier,
      identifier: url,
      primaryText: name,
      searchFields: [
        {label: "ID", priority: 10, text: identifier},
        {label: "Navn", priority: 5, text: name},
      ],
      secondaryText: identifier,
    };
    return entry;
  });
  return result;
}

interface PriceGroupDialogProps {
  noneLabel?: string;
  onCancel(): void;
  onNone?(): void;
  onOk(url: PriceGroupUrl): void;
  open: boolean;
  priceGroupArray: readonly PriceGroup[];
}

export const PriceGroupDialog = React.memo(function PriceGroupDialog(props: PriceGroupDialogProps) {
  const {noneLabel, onCancel, onNone, onOk, open, priceGroupArray} = props;
  const intl = useIntl();
  const title = intl.formatMessage({defaultMessage: "Vælg variant"});
  const searchTitle = intl.formatMessage({defaultMessage: "Søg variant"});

  const [doComputeBaseChoices, reuseBaseChoices] = useMemo(
    () => memoizeForceReuse(computeBaseChoicesForPriceGroups, []),
    [],
  );
  const getBaseChoices = open ? doComputeBaseChoices : reuseBaseChoices;
  const data = getBaseChoices(priceGroupArray);

  return (
    <GenericSingleSelectionSearchDialog<PriceGroupUrl>
      data={data}
      mobilePrimaryLines={1}
      mobileSearchPrimaryLines={1}
      mobileSearchSecondaryLines={1}
      mobileSecondaryLines={1}
      noneLabel={noneLabel}
      open={open}
      searchTitle={searchTitle}
      sorting="SECONDARY_IDENTIFIER"
      title={title}
      onCancel={onCancel}
      onNone={onNone}
      onOk={onOk}
    />
  );
});

interface MultiplePriceGroupsDialogProps {
  includeSelectAll?: boolean | undefined;
  onCancel(): void;
  onOk(urls: ReadonlySet<PriceGroupUrl>): void;
  open: boolean;
  priceGroupArray: readonly PriceGroup[];
  selected?: ReadonlySet<PriceGroupUrl> | undefined;
}

export const MultiplePriceGroupsDialog = React.memo(function MultiplePriceGroupsDialog(
  props: MultiplePriceGroupsDialogProps,
) {
  const {includeSelectAll, onCancel, onOk, open, priceGroupArray, selected} = props;
  const intl = useIntl();
  const title = intl.formatMessage({defaultMessage: "Vælg varianter"});
  const searchTitle = intl.formatMessage({defaultMessage: "Søg varianter"});

  const [doComputeBaseChoices, reuseBaseChoices] = useMemo(
    () => memoizeForceReuse(computeBaseChoicesForPriceGroups, []),
    [],
  );
  const getBaseChoices = open ? doComputeBaseChoices : reuseBaseChoices;
  const data = getBaseChoices(priceGroupArray);

  return (
    <GenericMultiSelectionSearchDialog<PriceGroupUrl>
      data={data}
      includeSelectAll={includeSelectAll}
      mobilePrimaryLines={1}
      mobileSearchPrimaryLines={1}
      mobileSearchSecondaryLines={1}
      mobileSecondaryLines={1}
      open={open}
      searchTitle={searchTitle}
      selected={selected}
      sorting="SECONDARY_IDENTIFIER"
      title={title}
      onCancel={onCancel}
      onOk={onOk}
    />
  );
});
