import {getEmployeeGroupArray} from "@co-frontend-libs/redux";
import {IntlShape} from "react-intl";
import {useSelector} from "react-redux";
import {z} from "zod";

// We want the return type from the Zod schema
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function getCreateFormValidator(intl: IntlShape) {
  return z.object({
    active: z.boolean(),
    name: z
      .string()
      .min(1, intl.formatMessage({defaultMessage: "Navn skal udfyldes"}))
      .refine(
        (name) =>
          !useSelector(getEmployeeGroupArray).some(
            (employeeGroup) =>
              employeeGroup.name.toLowerCase() === name.toLowerCase() && employeeGroup.active,
          ),
        intl.formatMessage({
          defaultMessage: "Der eksisterer allerede en medarbejdergruppe med det navn",
        }),
      ),
  });
}

export type CreateFormSchema = ReturnType<typeof getCreateFormValidator>;
