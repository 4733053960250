import {Journal, Location} from "@co-common-libs/resources";
import {emphasizeSubstrings} from "@co-frontend-libs/utils";
import {
  Checkbox,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import {Match} from "app-utils";
import HistoryIcon from "mdi-react/HistoryIcon";
import React, {useCallback} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {areEqual} from "react-window";
import {useFieldItemStyles} from "../utils";

function buildMatchesElement(matches: readonly Match[]): JSX.Element {
  const matchElements = matches.map((entry, index) => {
    const {label, matching, text} = entry;
    const textWithMatches = emphasizeSubstrings(text, matching);
    return (
      <span key={index}>
        <em>{label}:</em> {textWithMatches}{" "}
      </span>
    );
  });
  const matchElement = <span>{matchElements}</span>;
  return matchElement;
}

interface FieldMultiSelectionListItemProps {
  currentlySelected: boolean;
  disabled?: boolean | undefined;
  field: Location;
  journalEntries: readonly Journal[] | undefined;
  matches?: readonly Match[] | undefined;
  onSelect(identifier: string, selected: boolean): void;
  recentlyUsed?: boolean | undefined;
  style?: React.CSSProperties | undefined;
  value: string;
  withIcons: boolean;
}

const emptyStyle: React.CSSProperties = {};

export const FieldMultiSelectionListItem = React.memo(function FieldMultiSelectionListItem(
  props: FieldMultiSelectionListItemProps,
) {
  const {
    currentlySelected,
    disabled,
    field,
    journalEntries,
    matches,
    onSelect,
    recentlyUsed,
    style = emptyStyle,
    value,
    withIcons,
  } = props;

  const intl = useIntl();

  const Icon = recentlyUsed ? HistoryIcon : undefined;

  const handleClick = useCallback(
    () => onSelect(value, !currentlySelected),
    [currentlySelected, onSelect, value],
  );
  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>): void => {
      if (event.key === "Enter") {
        onSelect(value, !currentlySelected);
      }
    },
    [currentlySelected, onSelect, value],
  );

  const code = field.fieldNumber || intl.formatMessage({defaultMessage: "Intet marknr."});
  const crop = field.fieldCrop || "";
  const area = field.fieldAreaHa
    ? intl.formatNumber(field.fieldAreaHa, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })
    : null;
  const fieldRecordYear = field.fieldRecordYear ? ` (${field.fieldRecordYear})` : "";

  const primary = (
    <span>
      <b>{code}</b> {area ? `${area} ha` : null}
      {fieldRecordYear}
      {crop ? `: ${crop}` : null}
    </span>
  );
  const matchElements = matches ? buildMatchesElement(matches) : undefined;
  const secondary = (
    <>
      {matchElements}
      {journalEntries?.length ? (
        <strong>
          <FormattedMessage defaultMessage="Note:" />{" "}
          {journalEntries.map((journal) => journal.entry).join(". ")}
        </strong>
      ) : undefined}
    </>
  );

  const classes = useFieldItemStyles();

  if (withIcons && Icon) {
    return (
      <ListItem
        button
        ContainerComponent="div"
        ContainerProps={{style}}
        disabled={disabled || false}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
      >
        <ListItemIcon>
          <Checkbox disableRipple checked={currentlySelected} edge="start" tabIndex={-1} />
        </ListItemIcon>
        <ListItemText
          classes={{
            primary: classes.overflowOne,
            secondary: classes.overflowOne,
          }}
          primary={primary}
          secondary={secondary}
        />
        {withIcons && Icon ? (
          <ListItemSecondaryAction>
            <Icon />
          </ListItemSecondaryAction>
        ) : null}
      </ListItem>
    );
  } else {
    return (
      <ListItem
        button
        disabled={disabled || false}
        style={style}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
      >
        <ListItemIcon>
          <Checkbox disableRipple checked={currentlySelected} edge="start" tabIndex={-1} />
        </ListItemIcon>
        <ListItemText
          classes={{
            primary: classes.overflowOne,
            secondary: classes.overflowOne,
          }}
          primary={primary}
          secondary={secondary}
        />
      </ListItem>
    );
  }
}, areEqual);
