import {Unit, UnitUrl} from "@co-common-libs/resources";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {ConnectedMultipleUnitDialog} from "@co-frontend-libs/connected-components";
import {
  actions,
  getCurrentUserURL,
  getSettingsEntryLookupByIdentifier,
  getUnitArray,
  getUnitLookup,
} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {Button, DialogContent} from "@material-ui/core";
import React, {useCallback, useMemo, useState} from "react";
import {FormattedMessage, defineMessages, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {SettingViewProps} from "../types";
import {DisplaySelected} from "./display-selected";

const messages = defineMessages({
  all: {
    defaultMessage: "Alle",
    id: "select-units-or-all.label.all",
  },
  none: {
    defaultMessage: "Ingen",
    id: "select-units-or-all.label.none",
  },
});

export function SelectUnitsOrAll(props: SettingViewProps): JSX.Element {
  const {settingID, settingMetaData} = props;
  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const settingEntry = settingsEntryLookupByIdentifier(settingID);
  const selectedUnits: string[] | null = settingEntry?.data;
  const unitArray = useSelector(getUnitArray);
  const unitLookup = useSelector(getUnitLookup);
  const unitInstances = useMemo((): Unit[] | null => {
    if (!selectedUnits) {
      return null;
    }
    return selectedUnits
      .map((lowcaseUnit) => unitArray.find((w) => w.name.toLowerCase() === lowcaseUnit))
      .filter(Boolean) as Unit[];
  }, [selectedUnits, unitArray]);

  const unitNames = useMemo(() => {
    if (!unitInstances) {
      return null;
    }
    const names = unitInstances.map((unit) => unit.symbol || unit.name);
    names.sort();
    return names;
  }, [unitInstances]);

  const [unitDialogOpen, setUnitDialogOpen] = useState(false);
  const setUnitDialogOpenFalse = useCallWithFalse(setUnitDialogOpen);

  const [unitOrNullDialogOpen, setUnitOrNullDialogOpen] = useState(false);
  const setUnitOrNullDialogOpenTrue = useCallWithTrue(setUnitOrNullDialogOpen);
  const setUnitOrNullDialogOpenFalse = useCallWithFalse(setUnitOrNullDialogOpen);

  const dispatch = useDispatch();
  const currentUserURL = useSelector(getCurrentUserURL);
  const handleUnitDialogOk = useCallback(
    (urls: ReadonlySet<UnitUrl>) => {
      setUnitDialogOpen(false);

      const newValue = [...urls].map((url) => unitLookup(url)?.name.toLowerCase());

      if (settingEntry) {
        dispatch(
          actions.update(settingEntry.url, [
            {member: "changedBy", value: currentUserURL},
            {member: "data", value: newValue},
          ]),
        );
      }
    },
    [currentUserURL, dispatch, settingEntry, unitLookup],
  );

  const handleUnitSelect = useCallback(() => {
    setUnitOrNullDialogOpen(false);
    setUnitDialogOpen(true);
  }, []);

  const handleUnitNullSelect = useCallback(() => {
    setUnitOrNullDialogOpen(false);
    if (settingEntry) {
      dispatch(
        actions.update(settingEntry.url, [
          {member: "changedBy", value: currentUserURL},
          {member: "data", value: null},
        ]),
      );
    }
  }, [currentUserURL, dispatch, settingEntry]);

  const {formatMessage} = useIntl();
  let displayNames: string[] = [];
  if (unitNames) {
    if (unitNames.length) {
      displayNames = unitNames;
    } else {
      displayNames = [formatMessage(messages.none)];
    }
  } else {
    displayNames = [formatMessage(messages.all)];
  }

  return (
    <>
      <DisplaySelected
        currentValue={displayNames}
        settingEntry={settingEntry}
        settingID={settingID}
        settingMetaData={settingMetaData}
        onClick={setUnitOrNullDialogOpenTrue}
      />
      <ResponsiveDialog
        open={unitOrNullDialogOpen}
        title={<FormattedMessage defaultMessage="Vælg enheder" id="select-units-or-all.title" />}
        onCancel={setUnitOrNullDialogOpenFalse}
      >
        <DialogContent>
          <div style={{textAlign: "center"}}>
            <Button onClick={handleUnitNullSelect}>
              <FormattedMessage
                defaultMessage="Vælg alle nuværende/fremtidige enheder"
                id="select-units-or-all.select-all"
              />
            </Button>
            <FormattedMessage
              defaultMessage="eller"
              id="select-units-or-all.select-or"
              tagName="div"
            />
            <Button onClick={handleUnitSelect}>
              <FormattedMessage
                defaultMessage="Udvælg enheder"
                id="select-units-or-all.select-units"
              />
            </Button>
          </div>
        </DialogContent>
      </ResponsiveDialog>
      <ConnectedMultipleUnitDialog
        open={unitDialogOpen}
        selected={unitInstances ? new Set(unitInstances.map((unit) => unit?.url)) : undefined}
        onCancel={setUnitDialogOpenFalse}
        onOk={handleUnitDialogOk}
      />
    </>
  );
}
