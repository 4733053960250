import {actions} from "@co-frontend-libs/redux";
import {IconButton, Theme, Toolbar, Typography, createStyles, makeStyles} from "@material-ui/core";
import ArrowLeftIcon from "mdi-react/ArrowLeftIcon";
import React, {useCallback} from "react";
import {useDispatch} from "react-redux";
import {TimerButton} from "./timer-button";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backButton: {
      marginRight: theme.spacing(2),
    },
    root: {
      flexGrow: 1,
    },
    title: {
      flexGrow: 1,
    },
  }),
);

interface BackToolbarProps {
  rightElement?: React.ReactElement | undefined;
  title: JSX.Element | string;
}

export const BackToolbar = React.memo(function BackToolbar(props: BackToolbarProps): JSX.Element {
  const dispatch = useDispatch();
  const goBack = useCallback((): void => {
    dispatch(actions.back());
  }, [dispatch]);
  const classes = useStyles();
  const {rightElement: rightElementFromProps, title} = props;
  const rightElement = (
    <>
      {rightElementFromProps}
      <TimerButton />
    </>
  );
  return (
    <Toolbar>
      <IconButton className={classes.backButton} color="inherit" edge="start" onClick={goBack}>
        <ArrowLeftIcon />
      </IconButton>
      <Typography className={classes.title} variant="h6">
        {title}
      </Typography>
      {rightElement}
    </Toolbar>
  );
});
