import {
  Contact,
  Customer,
  CustomerUrl,
  Location,
  LocationTypeUrl,
  LocationUrl,
  PatchUnion,
  Project,
  ResourceTypeUnion,
  Role,
  RoutePlan as RoutePlanResource,
  RoutePlanTask,
  RoutePlanTaskUrl,
  WorkType,
  WorkTypeUrl,
  emptyLocation,
} from "@co-common-libs/resources";
import {getWorkTypeString} from "@co-common-libs/resources-utils";
import {formatAddress} from "@co-common-libs/utils";
import {CustomerDialog} from "@co-frontend-libs/components";
import {
  ConnectedDepartmentDialog,
  ConnectedExternalWorkTypeDialog,
  ConnectedLocationDialog,
} from "@co-frontend-libs/connected-components";
import {
  AppState,
  PathTemplate,
  actions,
  getContactArray,
  getCurrentRole,
  getCustomerArray,
  getCustomerLookup,
  getExtendedCustomerSettings,
  getLocationArray,
  getLocationLookup,
  getProjectArray,
  getRoutePlanLookup,
  getRoutePlanTaskArray,
  getWorkTypeLookup,
  makeQueryParameterGetter,
} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  IconButton,
  Switch,
  Tab,
  Tabs,
} from "@material-ui/core";
import {DoLoadInstance, LocationCreateEditDialog, PageLayout} from "app-components";
import {LoadInstanceRelated, PureComponent, getDepartmentName, memoize} from "app-utils";
import {bind} from "bind-decorator";
import bowser from "bowser";
import {ExtendedConfig} from "extended-config";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import PencilIcon from "mdi-react/PencilIcon";
import React from "react";
// Allowed for existing code...
// eslint-disable-next-line deprecate/import
import {Cell, Grid} from "react-flexr";
import {FormattedMessage, IntlContext, defineMessages} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {v4 as uuid} from "uuid";
import {NicknameDialog} from "../nickname-dialog";
import {RoutePlanOverviewTable} from "./route-plan-overview-table";
import {RoutePlanTaskTable} from "./route-plan-task-table";

const messages = defineMessages({
  active: {
    defaultMessage: "Aktiv",
    id: "route-plan.label.active",
  },
  overview: {defaultMessage: "Overblik", id: "route-plan.tab-label.overview"},
  selectDepartmentButton: {
    defaultMessage: "Vælg afdeling",
    id: "route-plan.label.select-department",
  },
  selectWorkTypeButton: {
    defaultMessage: "Vælg område",
    id: "route-plan.label.select-work-type",
  },
  setup: {defaultMessage: "Opsætning", id: "route-plan.tab-label.setup"},
  title: {defaultMessage: "Rute", id: "route-plan.title.route"},
});

const INITIAL = 2 ** 28;

const INCREMENT = 2 ** 14;

const THRESHOLD = 2 ** -14;

function isActive(obj: {active: boolean}): boolean {
  return obj.active;
}

function getCustomersWithWorkplaces(
  customerArray: readonly Customer[],
  locationArray: readonly Location[],
): readonly Customer[] {
  const customerURLs = new Set(locationArray.map((workplace) => workplace.customer));
  return customerArray.filter((customer) => customerURLs.has(customer.url));
}

interface RoutePlanContentProps {
  contactArray: readonly Contact[];
  create: (instance: ResourceTypeUnion) => void;
  currentRole: Role | null;
  customerArray: readonly Customer[];
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  customerSettings: ExtendedConfig;
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  locationArray: readonly Location[];
  locationLookup: (url: LocationUrl) => Location | undefined;
  projectArray: readonly Project[];
  putQueryKey: (key: string, value: string, navigationKind?: PartialNavigationKind) => void;
  routePlan: RoutePlanResource;
  routePlanTaskArray: readonly RoutePlanTask[];
  routePlanTaskList: readonly RoutePlanTask[];
  tab: string;
  update: (url: string, patch: PatchUnion) => void;
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
}

interface RoutePlanContentState {
  customerDialogOpen: boolean;
  departmentDialogOpen: boolean;
  locationCreateEditDialogInitialAddress: string;
  nameDialogOpen: boolean;
  taskCreateCustomerURL: CustomerUrl | null;
  workPlaceAddDialogOpen: boolean;
  workTypeDialogOpen: boolean;
}

class RoutePlanContent extends PureComponent<RoutePlanContentProps, RoutePlanContentState> {
  state: RoutePlanContentState = {
    customerDialogOpen: false,
    departmentDialogOpen: false,
    locationCreateEditDialogInitialAddress: "",
    nameDialogOpen: false,
    taskCreateCustomerURL: null,
    workPlaceAddDialogOpen: false,
    workTypeDialogOpen: false,
  };
  constructor(props: RoutePlanContentProps) {
    super(props);
    this.getCustomersWithWorkplaces = memoize(getCustomersWithWorkplaces);
  }
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  getCustomersWithWorkplaces: (
    customerArray: readonly Customer[],
    locationArray: readonly Location[],
  ) => readonly Customer[];
  @bind
  handleWorkTypeSelectButton(): void {
    this.setState({workTypeDialogOpen: true});
  }
  @bind
  handleWorkTypeDialogOk(url: WorkTypeUrl): void {
    this.setState({
      workTypeDialogOpen: false,
    });
    const {routePlan, update} = this.props;
    update(routePlan.url, [{member: "workType", value: url}]);
  }
  @bind
  handleWorkTypeDialogCancel(): void {
    this.setState({
      workTypeDialogOpen: false,
    });
  }
  @bind
  handleNameEditButton(): void {
    this.setState({nameDialogOpen: true});
  }
  @bind
  handleNameDialogOk(name: string): void {
    this.setState({nameDialogOpen: false});
    const {routePlan, update} = this.props;
    update(routePlan.url, [{member: "name", value: name}]);
  }
  @bind
  handleNameDialogCancel(): void {
    this.setState({nameDialogOpen: false});
  }
  @bind
  handleActiveToggle(event: React.ChangeEvent<HTMLInputElement>): void {
    const {checked} = event.target;
    const {routePlan, update} = this.props;
    update(routePlan.url, [{member: "active", value: checked}]);
  }
  @bind
  handleDepartmentSelectButton(): void {
    this.setState({departmentDialogOpen: true});
  }
  @bind
  handleDepartmentDialogOk(selected: string): void {
    const {routePlan, update} = this.props;
    update(routePlan.url, [{member: "department", value: selected}]);
    this.setState({departmentDialogOpen: false});
  }
  @bind
  handleDepartmentDialogCancel(): void {
    this.setState({departmentDialogOpen: false});
  }
  @bind
  handleRequestCustomerDialog(): void {
    this.setState({customerDialogOpen: true});
  }
  @bind
  handleCustomerDialogCancel(): void {
    this.setState({customerDialogOpen: false});
  }
  @bind
  handleCustomerDialogOk(url: CustomerUrl): void {
    this.setState({customerDialogOpen: false, taskCreateCustomerURL: url});
  }
  @bind
  handleWorkplaceDialogCancel(): void {
    this.setState({taskCreateCustomerURL: null});
  }

  @bind
  handleOnWorkPlaceAdd(address: string): void {
    this.setState({
      locationCreateEditDialogInitialAddress: address,
      workPlaceAddDialogOpen: true,
    });
  }

  @bind
  handleWorkplaceAddDialogCancel(): void {
    this.setState({workPlaceAddDialogOpen: false});
  }

  @bind
  handleWorkplaceAddDialogOk(data: {
    active: boolean;
    address: string;
    attention: string;
    city: string;
    coordinatesFromAddress: boolean;
    favorite: boolean;
    latitude: number | null;
    locationType: LocationTypeUrl | null;
    logOnlyLocation: boolean;
    longitude: number | null;
    name: string;
    phone: string;
    postalCode: string;
    workplaceOnlyLocation: boolean;
  }): void {
    this.setState({workPlaceAddDialogOpen: false});
    const {taskCreateCustomerURL} = this.state;
    if (!taskCreateCustomerURL) {
      return;
    }
    const {create} = this.props;
    const {
      address,
      attention,
      city,
      coordinatesFromAddress,
      favorite,
      latitude,
      locationType,
      logOnlyLocation,
      longitude,
      name,
      phone,
      postalCode,
      workplaceOnlyLocation,
    } = data;
    const id = uuid();
    const url = instanceURL("location", id);
    const instance: Location = {
      ...emptyLocation,
      address,
      attention,
      city,
      coordinatesFromAddress,
      customer: taskCreateCustomerURL,
      favorite,
      id,
      latitude,
      locationType,
      logOnlyLocation,
      longitude,
      name,
      phone,
      postalCode,
      url,
      workplaceOnlyLocation,
    };
    create(instance);
    this.handleWorkplaceDialogOk(url);
  }

  @bind
  handleWorkplaceDialogOk(workplaceURL: LocationUrl): void {
    const customerURL = this.state.taskCreateCustomerURL;
    if (!customerURL) {
      return;
    }
    this.setState({taskCreateCustomerURL: null});
    const {create, locationLookup, routePlan, routePlanTaskList} = this.props;
    const oldLastRoutePlanTask = routePlanTaskList[routePlanTaskList.length - 1];
    const order = oldLastRoutePlanTask ? oldLastRoutePlanTask.order + INCREMENT : INITIAL;
    const workplace = locationLookup(workplaceURL);
    const id = uuid();
    const url = instanceURL("routePlanTask", id);
    const obj: RoutePlanTask = {
      customer: customerURL,
      deadline: null,
      description: workplace ? workplace.name || formatAddress(workplace) : "",
      id,
      notesFromManager: "",
      order,
      project: null,
      relatedLocation: workplaceURL,
      routePlan: routePlan.url,
      url,
    };
    create(obj);
  }
  @bind
  handleMove(item: {url: RoutePlanTaskUrl}, target: RoutePlanTask): void {
    const {url: movedURL} = item;
    const targetURL = target.url;
    if (movedURL === targetURL) {
      return;
    }
    const {routePlanTaskList, update} = this.props;
    const movedIndex = routePlanTaskList.findIndex(
      (routePlanTask) => routePlanTask.url === movedURL,
    );
    const targetIndex = routePlanTaskList.findIndex(
      (routePlanTask) => routePlanTask.url === targetURL,
    );
    const targetOrder = target.order;
    let newOrder;
    if (movedIndex < targetIndex) {
      const afterTarget = routePlanTaskList[targetIndex + 1];
      if (afterTarget) {
        const afterTargetOrder = afterTarget.order;

        newOrder = targetOrder + (afterTargetOrder - targetOrder) / 2;
      } else {
        newOrder = targetOrder + INCREMENT;
      }
    } else {
      const beforeTarget = targetIndex > 0 ? routePlanTaskList[targetIndex - 1] : null;
      if (beforeTarget) {
        const beforeTargetOrder = beforeTarget.order;

        newOrder = targetOrder - (targetOrder - beforeTargetOrder) / 2;
      } else {
        newOrder = targetOrder / 2;
      }
    }
    if (Math.abs(targetOrder - newOrder) > THRESHOLD) {
      update(movedURL, [{member: "order", value: newOrder}]);
    } else {
      // renumbers everything now...
      const newList = routePlanTaskList.map((routePlanTask) => routePlanTask.url);
      // Remove the dragged item from old position.
      newList.splice(movedIndex, 1);
      // If moved was before target:
      // Removal has shifted target, so this inserts after it.
      // If moved was after target:
      // Removal has *not* shifted target, so this inserst before it.
      newList.splice(targetIndex, 0, movedURL);
      for (let i = newList.length - 1; i >= 0; i -= 1) {
        const url = newList[i];
        const order = INITIAL + i * INCREMENT;
        update(url, [{member: "order", value: order}]);
      }
    }
  }
  @bind
  handleTabChange(_event: React.ChangeEvent<unknown>, value: string): void {
    this.props.putQueryKey("tab", value || "");
  }

  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {taskCreateCustomerURL} = this.state;
    const {
      customerLookup,
      customerSettings,
      locationLookup,
      routePlan,
      routePlanTaskList,
      workTypeLookup,
    } = this.props;

    const {
      locations: {canCreateWorkplace},
    } = customerSettings;

    const {enableExternalTaskDepartmentField} = customerSettings;
    const {name} = routePlan;
    const {active} = routePlan;
    const workTypeURL = routePlan.workType;
    const workTypeData = workTypeURL ? workTypeLookup(workTypeURL) : undefined;
    const workTypeString = getWorkTypeString(workTypeData);
    let departmentBlock;
    if (enableExternalTaskDepartmentField) {
      const departmentID = routePlan.department;
      const department = <div>{getDepartmentName(departmentID, this.props.customerSettings)}</div>;
      departmentBlock = (
        <Cell palm="12/12">
          <FormattedMessage
            defaultMessage="Afdeling"
            id="route-plan.header.department"
            tagName="h4"
          />
          <Button
            color="secondary"
            style={{marginBottom: 8, width: "100%"}}
            variant="contained"
            onClick={this.handleDepartmentSelectButton}
          >
            {formatMessage(messages.selectDepartmentButton)}
          </Button>
          {department}
        </Cell>
      );
    }
    const dialogs = [
      <ConnectedExternalWorkTypeDialog
        key="work-type-dialog"
        disableSuggestions
        open={!!this.state.workTypeDialogOpen}
        onCancel={this.handleWorkTypeDialogCancel}
        onOk={this.handleWorkTypeDialogOk}
      />,
      <NicknameDialog
        key="route-name-dialog"
        nickname={name}
        open={this.state.nameDialogOpen}
        onCancel={this.handleNameDialogCancel}
        onOk={this.handleNameDialogOk}
      />,
      <CustomerDialog
        key="customer-dialog"
        contactArray={this.props.contactArray}
        customerArray={
          canCreateWorkplace
            ? this.props.customerArray.filter(isActive)
            : this.getCustomersWithWorkplaces(
                this.props.customerArray.filter(isActive),
                this.props.locationArray.filter(isActive),
              )
        }
        customerSettings={customerSettings}
        open={this.state.customerDialogOpen}
        projectArray={this.props.projectArray}
        suggestRecentlyUsed={false}
        onCancel={this.handleCustomerDialogCancel}
        onOk={this.handleCustomerDialogOk}
      />,
      <ConnectedLocationDialog
        key="workplace-dialog"
        includeWorkplaceOnlyLocations
        customerURL={taskCreateCustomerURL}
        includeLogOnlyLocations={false}
        open={!!this.state.taskCreateCustomerURL && !this.state.workPlaceAddDialogOpen}
        titleVariant="WORKPLACE"
        onAdd={canCreateWorkplace ? this.handleOnWorkPlaceAdd : undefined}
        onCancel={this.handleWorkplaceDialogCancel}
        onOk={this.handleWorkplaceDialogOk}
      />,
      <LocationCreateEditDialog
        key="workplace-add-dialog"
        initialSearch={this.state.locationCreateEditDialogInitialAddress}
        locationFavoritesEnabled={this.props.customerSettings.locationFavoritesEnabled}
        logOnlyLocation={false}
        open={this.state.workPlaceAddDialogOpen}
        workplaceOnlyLocation={customerSettings.setWorkplaceOnlyLocationOnCreate}
        onCancel={this.handleWorkplaceAddDialogCancel}
        onOk={this.handleWorkplaceAddDialogOk}
      />,
      <ConnectedDepartmentDialog
        key="department-dialog"
        open={this.state.departmentDialogOpen}
        onCancel={this.handleDepartmentDialogCancel}
        onOk={this.handleDepartmentDialogOk}
      />,
    ];
    return (
      <PageLayout
        dialogs={dialogs}
        tabs={
          <Tabs
            value={this.props.tab}
            variant={bowser.mobile ? "fullWidth" : "standard"}
            onChange={this.handleTabChange}
          >
            <Tab label={formatMessage(messages.setup)} value="setup" />
            <Tab label={formatMessage(messages.overview)} value="overview" />
          </Tabs>
        }
        toolbar={formatMessage(messages.title)}
      >
        {this.props.tab === "setup" ? (
          <Card style={{margin: "1em"}}>
            <CardContent>
              <h4>
                {name}{" "}
                <IconButton onClick={this.handleNameEditButton}>
                  <PencilIcon />
                </IconButton>
              </h4>
              <Grid>
                <Cell palm="12/12">
                  {workTypeString}
                  {workTypeString ? <br /> : null}
                  <Button
                    color="secondary"
                    disabled={!!routePlanTaskList.length}
                    variant="contained"
                    onClick={this.handleWorkTypeSelectButton}
                  >
                    {formatMessage(messages.selectWorkTypeButton)}
                  </Button>
                </Cell>
                {departmentBlock}
                <Cell palm="12/12">
                  <FormControlLabel
                    control={<Switch checked={active} onChange={this.handleActiveToggle} />}
                    label={formatMessage(messages.active)}
                  />
                </Cell>
              </Grid>
            </CardContent>
          </Card>
        ) : null}
        {this.props.tab === "setup" ? (
          <RoutePlanTaskTable
            customerLookup={customerLookup}
            go={this.props.go}
            locationLookup={locationLookup}
            routePlan={routePlan}
            routePlanTaskList={routePlanTaskList}
            onAdd={workTypeURL ? this.handleRequestCustomerDialog : undefined}
            onMove={this.handleMove}
          />
        ) : null}
        {this.props.tab === "overview" ? (
          <Card style={{margin: "1em"}}>
            <CardHeader title={name} />
            <RoutePlanOverviewTable routePlan={routePlan} />
          </Card>
        ) : null}
      </PageLayout>
    );
  }
}

interface RoutePlanContainerStateProps {
  contactArray: readonly Contact[];
  currentRole: Role | null;
  customerArray: readonly Customer[];
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  customerSettings: ExtendedConfig;
  locationArray: readonly Location[];
  locationLookup: (url: LocationUrl) => Location | undefined;
  projectArray: readonly Project[];
  routePlanTaskArray: readonly RoutePlanTask[];
  tab: string;
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
}

interface RoutePlanContainerDispatchProps {
  create: (instance: ResourceTypeUnion) => void;
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  putQueryKey: (key: string, value: string, navigationKind?: PartialNavigationKind) => void;
  update: (url: string, patch: PatchUnion) => void;
}

interface RoutePlanContainerOwnProps {
  instance: RoutePlanResource;
}

type RoutePlanContainerProps = RoutePlanContainerDispatchProps &
  RoutePlanContainerOwnProps &
  RoutePlanContainerStateProps;

class RoutePlanContainer extends PureComponent<RoutePlanContainerProps> {
  render(): JSX.Element {
    const {routePlanTaskArray} = this.props;
    const routePlan = this.props.instance;
    const routePlanURL = routePlan.url;
    const routePlanTaskList = _.sortBy(
      routePlanTaskArray.filter((routePlanTask) => routePlanTask.routePlan === routePlanURL),
      (routePlanTask) => routePlanTask.order,
    );
    return (
      <RoutePlanContent
        {...this.props}
        routePlan={routePlan}
        routePlanTaskList={routePlanTaskList}
      />
    );
  }
}

const ConnectedRoutePlanContainer = connect<
  RoutePlanContainerStateProps,
  RoutePlanContainerDispatchProps,
  RoutePlanContainerOwnProps,
  AppState
>(
  createStructuredSelector<AppState, RoutePlanContainerStateProps>({
    contactArray: getContactArray,
    currentRole: getCurrentRole,
    customerArray: getCustomerArray,
    customerLookup: getCustomerLookup,
    customerSettings: getExtendedCustomerSettings,
    locationArray: getLocationArray,
    locationLookup: getLocationLookup,
    projectArray: getProjectArray,
    routePlanTaskArray: getRoutePlanTaskArray,
    tab: makeQueryParameterGetter("tab", "setup"),
    workTypeLookup: getWorkTypeLookup,
  }),
  {
    create: actions.create,
    go: actions.go,
    putQueryKey: actions.putQueryKey,
    update: actions.update,
  },
)(RoutePlanContainer);

const related: LoadInstanceRelated = [
  {
    memberName: "routePlan",
    related: [
      {
        memberName: "routePlanTask",
        resourceType: "routePlanTaskActivityOption",
        type: "hasForeignKey",
      },
      {
        memberName: "routePlanTask",
        resourceType: "routePlanTaskResult",
        type: "hasForeignKey",
      },
    ],
    resourceType: "routePlanTask",
    type: "hasForeignKey",
  },
] as const;

class LoadRoutePlan extends React.Component<undefined> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  render(): JSX.Element {
    const {formatMessage} = this.context;
    return (
      <DoLoadInstance
        Component={ConnectedRoutePlanContainer}
        loadingTitle={formatMessage(messages.title)}
        lookupSelector={getRoutePlanLookup}
        related={related}
        resourceName="routePlan"
      />
    );
  }
}

export {LoadRoutePlan as RoutePlan};
