import {emphasizeSubstrings} from "@co-frontend-libs/utils";
import {ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import {Match} from "app-utils";
import React, {useCallback} from "react";
import {areEqual} from "react-window";
import {ColorBox} from "../../color-box";
import {typedMemo} from "../../types";
import {EntryCategoryCode, entryCategoryIcons} from "../types";

function buildMatchesElement(matches: readonly Match[]): JSX.Element {
  const matchElements = matches.map((entry, index) => {
    const {label, matching, text} = entry;
    const textWithMatches = emphasizeSubstrings(text, matching);
    return (
      <span key={index}>
        <em>{label}:</em> {textWithMatches}{" "}
      </span>
    );
  });
  const matchElement = <span>{matchElements}</span>;
  return matchElement;
}

interface SingleSelectionListItemProps<Identifier extends string> {
  category: EntryCategoryCode;
  color?: string | undefined;
  disabled: boolean | undefined;
  itemClass?: string | undefined;
  matches?: readonly Match[] | undefined;
  onSelect(value: Identifier): void;
  primaryText: string;
  primaryTextClass?: string | undefined;
  secondaryText?: string | undefined;
  secondaryTextClass?: string | undefined;
  style?: React.CSSProperties;
  value: Identifier;
  withIcons: boolean;
}

const emptyStyle: React.CSSProperties = {};

export const SingleSelectionListItem = typedMemo(function SingleSelectionListItem<
  Identifier extends string,
>(props: SingleSelectionListItemProps<Identifier>): JSX.Element {
  const {
    category,
    color,
    disabled,
    itemClass,
    matches,
    onSelect,
    primaryText,
    primaryTextClass,
    secondaryText,
    secondaryTextClass,
    style = emptyStyle,
    value,
    withIcons,
  } = props;
  const Icon = entryCategoryIcons[category];
  const handleClick = useCallback(() => onSelect(value), [onSelect, value]);

  const primaryString =
    matches && secondaryText ? `${primaryText} – ${secondaryText}` : primaryText;
  const secondary = matches ? buildMatchesElement(matches) : secondaryText;

  const primary = color ? (
    <span>
      <span
        style={{
          display: "inline-block",
          height: 16,
          paddingRight: 10,
          verticalAlign: "text-top",
        }}
      >
        <ColorBox inline color={color} />
      </span>
      {primaryString}
    </span>
  ) : (
    primaryString
  );

  const textClasses: {
    primary?: string;
    secondary?: string;
  } = {};
  if (primaryTextClass) {
    textClasses.primary = primaryTextClass;
  }
  if (secondaryTextClass) {
    textClasses.secondary = secondaryTextClass;
  }

  return (
    <ListItem
      button
      className={itemClass || ""}
      disabled={disabled || false}
      style={style}
      onClick={handleClick}
    >
      {withIcons && Icon ? (
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
      ) : null}
      <ListItemText
        classes={textClasses}
        inset={withIcons && !Icon}
        primary={primary}
        secondary={secondary}
      />
    </ListItem>
  );
}, areEqual);
