import {Project} from "@co-common-libs/resources";
import {CircularProgress} from "@material-ui/core";
import {LogsCard, useAppendicesData} from "app-components";
import React, {useMemo} from "react";

interface ProjectLogAppendicesProps {
  project: Pick<Project, "url">;
}

export const ProjectLogAppendices = React.memo(function ProjectLogAppendices({
  project,
}: ProjectLogAppendicesProps): React.JSX.Element {
  const {data, fetching} = useAppendicesData(
    useMemo(
      () => ({
        project: project.url,
      }),
      [project.url],
    ),
  );

  if (fetching) {
    return (
      <div data-testid="loading">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div data-testid="project-logs">
      <LogsCard data={data?.logTasks ?? null} />
    </div>
  );
});
