import type {SerializedImage} from "../other-types";
import {UserPhotoUrl, UserUrl} from "../resource-urls";
import {ResourceInstance} from "./base";

interface UserPhotoBase extends ResourceInstance<UserPhotoUrl> {
  readonly createdBy: UserUrl;
  readonly note: string;
  readonly taken: string;
  readonly user: UserUrl;
}

export interface OnlineUserPhoto extends UserPhotoBase {
  readonly displayDownload: string;
  readonly displayHeight: number;
  readonly displayWidth: number;
  readonly fullsizeDownload: string;
  readonly originalHeight: number;
  readonly originalWidth: number;
  readonly thumbnailDownload: string;
  readonly thumbnailHeight: number;
  readonly thumbnailWidth: number;
}

export interface LocalUserPhoto extends UserPhotoBase {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly _file_original: SerializedImage;
}

export type UserPhoto = LocalUserPhoto | OnlineUserPhoto;

export function isOnlineUserPhoto(photo: UserPhoto): photo is OnlineUserPhoto {
  return !(photo as any)._file_original;
}
