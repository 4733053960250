import {
  PriceItem,
  PriceItemUrl,
  Product,
  ProductUrl,
  ProductUseWithOrder,
  ReportingLogEntry,
} from "@co-common-libs/resources";
import {getAutoProductsMapping, getUnitString} from "@co-common-libs/resources-utils";
import {caseAccentInsensitiveCollator, notNull, sortByOrderMember} from "@co-common-libs/utils";
import {
  getCurrentRole,
  getCustomerSettings,
  getPriceItemLookup,
  getProductGroupLookup,
  getProductLookup,
  getUnitLookup,
} from "@co-frontend-libs/redux";
import React from "react";
import {FormattedNumber} from "react-intl";
import {useSelector} from "react-redux";

export const LocationEntryDataSumTable = React.memo(function LocationEntryDataSumTable({
  data,
}: {
  data: ReportingLogEntry[];
}): JSX.Element {
  const productGroupLookup = useSelector(getProductGroupLookup);
  const customerSettings = useSelector(getCustomerSettings);
  const unitLookup = useSelector(getUnitLookup);
  const role = useSelector(getCurrentRole);
  const isManager = role && role.manager;
  const productLookup = useSelector(getProductLookup);

  const sumMap = new Map<PriceItemUrl | ProductUrl, number>();
  data.forEach((entry) => {
    if (entry.productUses) {
      const {productUses} = entry;
      let productUseList: ProductUseWithOrder[];
      if (!isManager && !customerSettings.showMaterialAutoLinesToEmployees) {
        const {autoLinesToLines} = getAutoProductsMapping(
          productUses,
          productLookup,
          productGroupLookup,
          customerSettings,
        );
        if (autoLinesToLines?.size) {
          productUseList = sortByOrderMember(
            Object.entries(productUses)
              .filter(([identifier, _value]) => !autoLinesToLines.has(identifier))
              .map(([_identifier, value]) => value),
          );
        } else {
          productUseList = sortByOrderMember(Object.values(productUses));
        }
      } else {
        productUseList = sortByOrderMember(Object.values(productUses));
      }
      productUseList.forEach((productUse) => {
        if (productUse.count && productUse.product) {
          const count = sumMap.get(productUse.product);
          const newCount = (count || 0) + productUse.count;
          sumMap.set(productUse.product, newCount);
        }
      });
    }
    if (entry.priceItemUses) {
      sortByOrderMember(Object.values(entry.priceItemUses)).forEach((priceItemUse) => {
        if (priceItemUse.count && priceItemUse.priceItem) {
          const count = sumMap.get(priceItemUse.priceItem);
          const newCount = (count || 0) + priceItemUse.count;
          sumMap.set(priceItemUse.priceItem, newCount);
        }
      });
    }
  });
  const priceItemLookup = useSelector(getPriceItemLookup);
  const rows: JSX.Element[] = Array.from(sumMap.entries())
    .map(([url, value]) => {
      if (!value) {
        return null;
      }
      const instance: PriceItem | Product | undefined = url.includes("product")
        ? productLookup(url as ProductUrl)
        : priceItemLookup(url as PriceItemUrl);
      return {instance, url, value};
    })
    .filter(notNull)
    .sort((a, b) =>
      caseAccentInsensitiveCollator.compare(a.instance?.name || "", b.instance?.name || ""),
    )
    .map(({instance, url, value}) => {
      return (
        <tr key={url}>
          <td>{instance?.name}</td>
          <td style={{textAlign: "right"}}>
            <FormattedNumber maximumFractionDigits={2} minimumFractionDigits={2} value={value} />
          </td>
          <td style={{textAlign: "right"}}>
            {instance ? getUnitString(instance, unitLookup) : ""}
          </td>
        </tr>
      );
    });
  return (
    <table style={{width: "100%"}}>
      <tbody>{rows}</tbody>
    </table>
  );
});
