import {useCallback} from "react";

export function useCallWithConstant<T>(fn: (value: T) => void, value: T): () => void {
  const wrappedFn = useCallback((): void => {
    fn(value);
  }, [fn, value]);

  return wrappedFn;
}

export function useCallWithTrue(fn: (value: true) => void): () => void {
  return useCallWithConstant(fn, true);
}

export function useCallWithFalse(fn: (value: false) => void): () => void {
  return useCallWithConstant(fn, false);
}
