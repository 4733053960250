import {SettingID} from "@co-common-libs/config";
import {ResponsiveDialog, TimeField} from "@co-frontend-libs/components";
import {
  actions,
  getCurrentUserURL,
  getSettingsEntryLookupByIdentifier,
} from "@co-frontend-libs/redux";
import {DialogContent, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import React, {useCallback, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

interface NullableHoursRangeSettingDialogProps {
  onClose: () => void;
  open: boolean;
  settingID: SettingID;
}

export function NullableHoursRangeSettingDialog(
  props: NullableHoursRangeSettingDialogProps,
): JSX.Element {
  const {onClose, open, settingID} = props;

  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const settingEntry = settingsEntryLookupByIdentifier(settingID);

  const data: [string, string] | null | undefined = settingEntry?.data;

  const currentUserURL = useSelector(getCurrentUserURL);

  const dispatch = useDispatch();

  const [used, setUsed] = useState(!!data);

  const [rangeStart, setRangeStart] = useState<string | null>(data?.[0] || null);
  const [rangeEnd, setRangeEnd] = useState<string | null>(data?.[1] || null);

  const handleUsedChange = useCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
    setUsed(event.target.value === "USED");
  }, []);

  const handleSave = useCallback(() => {
    let newValue: [string, string] | null;
    if (used) {
      if (rangeStart && rangeEnd) {
        const HH_MM_STRING_LENGTH = "HH:mm".length;
        newValue = [
          rangeStart.substring(0, HH_MM_STRING_LENGTH),
          rangeEnd.substring(0, HH_MM_STRING_LENGTH),
        ];
      } else {
        return;
      }
    } else {
      newValue = null;
    }
    if (settingEntry) {
      dispatch(
        actions.update(settingEntry.url, [
          {member: "changedBy", value: currentUserURL},
          {member: "data", value: newValue},
        ]),
      );
    }
    onClose();
  }, [currentUserURL, dispatch, onClose, rangeEnd, rangeStart, settingEntry, used]);

  const okDisabled = used && (!rangeStart || !rangeEnd);

  return (
    <ResponsiveDialog
      fullWidth
      okDisabled={okDisabled}
      okLabel={<FormattedMessage defaultMessage="Gem" id="setting-dialog.label.save" />}
      open={open}
      title={
        <FormattedMessage
          defaultMessage="Angiv periode"
          id="system-setup.dialog-title.specify-period"
        />
      }
      onCancel={onClose}
      onOk={handleSave}
    >
      <DialogContent>
        <RadioGroup name="period-used" value={used ? "USED" : "UNUSED"} onChange={handleUsedChange}>
          <FormControlLabel
            control={<Radio />}
            label={
              <FormattedMessage defaultMessage="Ikke anvendt" id="setting-dialog.label.unused" />
            }
            value="UNUSED"
          />
          <FormControlLabel
            control={<Radio />}
            label={<FormattedMessage defaultMessage="Anvendt" id="setting-dialog.label.used" />}
            value="USED"
          />
        </RadioGroup>
        <TimeField
          disabled={!used}
          label={
            <FormattedMessage defaultMessage="Fra klokkeslæt" id="system-setup.label.from-time" />
          }
          style={{width: 200}}
          value={rangeStart || undefined}
          onChange={setRangeStart}
        />
        <TimeField
          disabled={!used}
          label={
            <FormattedMessage defaultMessage="Til klokkeslæt" id="system-setup.label.to-time" />
          }
          style={{width: 200}}
          value={rangeEnd || undefined}
          onChange={setRangeEnd}
        />
      </DialogContent>
    </ResponsiveDialog>
  );
}
