import {Patch, Task} from "@co-common-libs/resources";
import {machinePotentialPriceGroups, recomputePriceGroup} from "@co-common-libs/resources-utils";
import _ from "lodash";
import {ProvisionaryCommand} from "../../resources/actions";
import {getMachineLookup, getOrderLookup, getPriceGroupLookup} from "../../resources/selectors";
import {ResourcesAuthenticationMiddlewareAPI} from "../types";

export function autoMachinePriceGroups(
  newTask: Task | null,
  oldTask: Task | undefined,
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  _command: ProvisionaryCommand,
): Patch<Task> | null {
  if (!newTask) {
    return null;
  }
  if (
    newTask.recordedInC5 ||
    newTask.archivable ||
    !newTask.order ||
    !newTask.machineuseSet ||
    !newTask.machineuseSet.length ||
    !newTask.machineuseSet.some((machineUse) => !machineUse.priceGroup) ||
    _.isEqual(newTask.machineuseSet, oldTask?.machineuseSet)
  ) {
    // nothing to process
    return null;
  }
  const state = middlewareApi.getState();

  const orderLookup = getOrderLookup(state);
  const order = newTask.order ? orderLookup(newTask.order) : undefined;
  const machineLookup = getMachineLookup(state);
  const priceGroupLookup = getPriceGroupLookup(state);

  const machineuseSet = newTask.machineuseSet.map((machineUse) => {
    if (machineUse.priceGroup) {
      return machineUse;
    }
    const potentialPriceGroups = machinePotentialPriceGroups(
      machineUse.machine,
      order?.customer || null,
      machineLookup,
      priceGroupLookup,
    );
    const currentPriceGroup = machineUse.priceGroup
      ? priceGroupLookup(machineUse.priceGroup)
      : null;
    const priceGroup = recomputePriceGroup(potentialPriceGroups, currentPriceGroup || null);
    const priceGroupURL = priceGroup?.url || null;
    if (priceGroupURL === machineUse.priceGroup) {
      return machineUse;
    }
    return {...machineUse, priceGroup: priceGroupURL};
  });

  if (_.isEqual(machineuseSet, newTask.machineuseSet)) {
    return null;
  }

  return [{member: "machineuseSet", value: machineuseSet}];
}
