import {Dispatch, useCallback} from "react";
import {SetObjectPropertyAction} from "./types";

export function useUpdateObjectEntry<T, Member extends keyof T>(
  dispatch: Dispatch<SetObjectPropertyAction<T>>,
  propertyName: Member,
): (value: T[Member]) => void;
export function useUpdateObjectEntry<T, Member extends keyof T, InputValue>(
  dispatch: Dispatch<SetObjectPropertyAction<T>>,
  propertyName: Member,
  transform: (value: InputValue) => T[Member],
): (value: InputValue) => void;
export function useUpdateObjectEntry<T, Member extends keyof T, InputValue>(
  dispatch: Dispatch<SetObjectPropertyAction<T>>,
  propertyName: Member,
  transform?: (value: InputValue) => T[Member],
): (value: InputValue | T[Member]) => void {
  return useCallback(
    (value: InputValue | T[Member]) => {
      dispatch({
        propertyName,
        type: "set",
        value: transform ? transform(value as InputValue) : (value as T[Member]),
      });
    },
    [dispatch, propertyName, transform],
  );
}
