import {Config} from "@co-common-libs/config";
import {z} from "zod";
import {DataIssues} from "./errors";

interface BaseCsvImportColumnSpecification {
  readonly label: string;
  readonly name: string;
  readonly required: boolean;
}

export interface NormalCsvImportColumnSpecification extends BaseCsvImportColumnSpecification {
  readonly type: "boolean" | "color" | "cvr" | "date" | "decimal" | "email" | "integer";
}

export interface StringCsvImportColumnSpecification extends BaseCsvImportColumnSpecification {
  readonly references?:
    | "customerNumber"
    | "locationTypeIdentifier"
    | "remunerationGroupIdentifier"
    | "unitName";
  readonly type: "string";
}

export interface ChoicesCsvImportColumnSpecification extends BaseCsvImportColumnSpecification {
  readonly choices: readonly string[];
  readonly type: "choices";
}

export type CsvImportColumnSpecificationValidateTypePart =
  | Pick<ChoicesCsvImportColumnSpecification, "choices" | "type">
  | Pick<NormalCsvImportColumnSpecification, "type">
  | Pick<StringCsvImportColumnSpecification, "references" | "type">;

export type CsvImportColumnSpecificationValidatePart =
  | Pick<ChoicesCsvImportColumnSpecification, "choices" | "required" | "type">
  | Pick<NormalCsvImportColumnSpecification, "required" | "type">
  | Pick<StringCsvImportColumnSpecification, "references" | "required" | "type">;

export type CsvImportColumnSpecification =
  | ChoicesCsvImportColumnSpecification
  | NormalCsvImportColumnSpecification
  | StringCsvImportColumnSpecification;

export interface CsvImportSpecification {
  readonly availableColumns?: (
    config: Config,
    columns: readonly CsvImportColumnSpecification[],
  ) => readonly CsvImportColumnSpecification[];
  readonly columns: readonly CsvImportColumnSpecification[];
  readonly label: string;
  readonly name: string;
}

const csvImportConfigurationSchema = z.object({
  createNotExisting: z.boolean(),
  updateExistingColumns: z.array(z.string()),
});

export const csvImportRequestDataSchema = z.object({
  configuration: csvImportConfigurationSchema,
  data: z.array(z.record(z.union([z.string(), z.number(), z.boolean(), z.null()]))),
  specificationName: z.string(),
});

export type CsvImportRequestData = z.infer<typeof csvImportRequestDataSchema>;

export interface CsvImportResult<T> {
  createCount: number;
  errors?: DataIssues;
  instances: T[];
  updateCount: number;
}
