import {ResponsiveDialog, TrimTextField} from "@co-frontend-libs/components";
import {DialogContent} from "@material-ui/core";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import React from "react";
import {IntlContext, defineMessages} from "react-intl";

const messages = defineMessages({
  nickname: {
    defaultMessage: "Kaldenavn",
    id: "route-list.label.nickname",
  },
  title: {
    defaultMessage: "Rutens kaldenavn",
    id: "route-list.dialog-title.route-nickname",
  },
});

interface NicknameDialogProps {
  nickname?: string;
  onCancel: () => void;
  onOk: (nickname: string) => void;
  open: boolean;
}

interface NicknameDialogState {
  nickname: string;
}

export class NicknameDialog extends PureComponent<NicknameDialogProps, NicknameDialogState> {
  state: NicknameDialogState = {
    nickname: "",
  };
  UNSAFE_componentWillReceiveProps(nextProps: NicknameDialogProps): void {
    if ((nextProps.open && !this.props.open) || nextProps.nickname !== this.props.nickname) {
      this.setState({nickname: nextProps.nickname || ""});
    }
  }
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  @bind
  handleOk(): void {
    this.props.onOk(this.state.nickname);
  }
  @bind
  handleCancel(): void {
    this.props.onCancel();
  }
  @bind
  handleNicknameChange(value: string): void {
    this.setState({nickname: value});
  }
  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {open} = this.props;
    const {nickname} = this.state;

    return (
      <ResponsiveDialog
        okDisabled={!nickname}
        open={open}
        title={formatMessage(messages.title)}
        onCancel={this.handleCancel}
        onOk={this.handleOk}
      >
        <DialogContent>
          <TrimTextField
            fullWidth
            autoFocus={!nickname}
            label={formatMessage(messages.nickname)}
            margin="dense"
            name="nickname"
            value={nickname}
            variant="outlined"
            onChange={this.handleNicknameChange}
          />
        </DialogContent>
      </ResponsiveDialog>
    );
  }
}
