import {Contact, Customer, CustomerUrl, Location} from "@co-common-libs/resources";
import {formatAddress} from "@co-common-libs/utils";

export function getLocationString(
  location: Pick<Location, "address" | "city" | "name" | "postalCode">,
): string {
  const namePart = location.name || "";
  const address = formatAddress(location);
  if (namePart && address) {
    return `${namePart}, ${address}`;
  } else {
    return namePart || address || "";
  }
}

export function getLocationPhone(
  location: Location | null,
  contactArray: readonly Contact[],
  customerLookup: (url: CustomerUrl) => Customer | undefined,
): string | null {
  if (!location) {
    return null;
  }

  let phone: string | null;
  ({phone} = location);
  const {customer} = location;
  if (!phone && customer) {
    const defaultContact = contactArray.find(
      (contact) => contact.customer === customer && contact.defaultContact,
    );
    phone = defaultContact?.cellphone || defaultContact?.phone || null;
    if (!phone) {
      const customerInstance = customerLookup(customer);
      phone = customerInstance?.cellphone || customerInstance?.phone || null;
    }
  }
  return phone;
}

export function getGoogleMapsUrl(location: Location): string {
  const {address, city, coordinatesFromAddress, latitude, longitude, postalCode} = location;
  if (!coordinatesFromAddress && longitude && latitude) {
    return `https://maps.google.com/?q=${latitude},${longitude}`;
  } else {
    const query = encodeURIComponent(`${address} ${postalCode} ${city}`);
    return `https://maps.google.com/?q=${query}`;
  }
}
