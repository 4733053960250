import {SpeedDialAction, SpeedDialActionProps} from "@material-ui/lab";
import React from "react";

interface FileInputSpeedDialActionProps
  extends Partial<Pick<SpeedDialActionProps, "delay" | "FabProps" | "id" | "open">> {
  accept: string;
  buttonIcon: JSX.Element;
  inputId: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClose: () => void;
  tooltipPlacement: "left" | "right"; //other allowed tooltip placements in the speeddial component don't work with persistent tooltips (tooltipOpen).
  tooltipTitle: string;
}

//SpeedDial tries to inject FabProps, delay, open, and id, for its child elements,
//but in order to have input functionality by click of button we need to wrap SpeedDialAction in a label, which doesn't play nice with those injections.
//Thus we create this element to pass the injected properties to SpeedDialAction after we have wrapped it in a label.
//We could attempt to trigger click-event on the <input> element on click on the button from JavaScript, but this approach will not work on iOS.
export const FileInputSpeedDialAction = React.memo(function FileInputSpeedDialAction(
  props: FileInputSpeedDialActionProps,
): JSX.Element {
  const {
    accept,
    buttonIcon,
    FabProps,
    inputId,
    onChange,
    onClose,
    tooltipPlacement,
    tooltipTitle,
    ...rest
  } = props;

  return (
    <>
      <input
        accept={accept}
        id={inputId}
        style={{display: "none"}}
        type="file"
        onChange={onChange}
      />
      <label htmlFor={inputId} onClick={onClose}>
        <SpeedDialAction
          tooltipOpen
          FabProps={{component: "span", ...FabProps} as any} //The Fab that SpeedDialAction creates needs to have component: "span", to allow input functionality. But SpeedDialAction's type does not allow this and defaults to component: "button" unless we do this typehack.
          icon={buttonIcon}
          style={{cursor: "pointer"}}
          tooltipPlacement={tooltipPlacement}
          tooltipTitle={tooltipTitle}
          {...rest}
        />
      </label>
    </>
  );
});
