import {
  KrPerLiterFuelSurchargeSpecification,
  KrPerLiterFuelSurchargeSpecificationUrl,
} from "@co-common-libs/resources";
import {memoizeForceReuse} from "@co-frontend-libs/utils";
import React, {useMemo} from "react";
import {useIntl} from "react-intl";
import {EntryData, GenericSingleSelectionSearchDialog} from "../search-dialog";

function computeBaseChoices(
  krPerLiterFuelSurchargeSpecificationArray: readonly KrPerLiterFuelSurchargeSpecification[],
): readonly EntryData<KrPerLiterFuelSurchargeSpecificationUrl>[] {
  const data: EntryData<KrPerLiterFuelSurchargeSpecificationUrl>[] = [];
  krPerLiterFuelSurchargeSpecificationArray.forEach((instance) => {
    if (instance.active) {
      const {url} = instance;
      const name = instance.name || "";
      const entry: EntryData<KrPerLiterFuelSurchargeSpecificationUrl> = {
        category: "standard",
        exactMatchValue: name,
        identifier: url,
        primaryText: name,
        searchFields: [{label: "Navn", priority: 5, text: name}],
      };
      data.push(entry);
    }
  });
  return data;
}

interface KrPerLiterFuelSurchargeSpecificationDialogProps {
  krPerLiterFuelSurchargeSpecificationArray: readonly KrPerLiterFuelSurchargeSpecification[];
  onCancel(): void;
  onNone?(): void;
  onOk(url: KrPerLiterFuelSurchargeSpecificationUrl): void;
  open: boolean;
}

export const KrPerLiterFuelSurchargeSpecificationDialog = React.memo(
  function KrPerLiterFuelSurchargeSpecificationDialog(
    props: KrPerLiterFuelSurchargeSpecificationDialogProps,
  ) {
    const {krPerLiterFuelSurchargeSpecificationArray, onCancel, onNone, onOk, open} = props;
    const intl = useIntl();

    const [doComputeBaseChoices, reuseBaseChoices] = useMemo(
      () => memoizeForceReuse(computeBaseChoices, []),
      [],
    );
    const getBaseChoices = open ? doComputeBaseChoices : reuseBaseChoices;
    const data = getBaseChoices(krPerLiterFuelSurchargeSpecificationArray);

    return (
      <GenericSingleSelectionSearchDialog<KrPerLiterFuelSurchargeSpecificationUrl>
        data={data}
        mobilePrimaryLines={1}
        mobileSearchPrimaryLines={1}
        mobileSearchSecondaryLines={1}
        mobileSecondaryLines={0}
        noneLabel={
          onNone ? intl.formatMessage({defaultMessage: "Uden brændstoftillæg"}) : undefined
        }
        open={open}
        searchTitle={intl.formatMessage({
          defaultMessage: "Søg brændstoftillæg",
        })}
        title={intl.formatMessage({
          defaultMessage: "Vælg brændstoftillæg",
        })}
        onCancel={onCancel}
        onNone={onNone}
        onOk={onOk}
      />
    );
  },
);
