import {
  KrPerLiterFuelSurchargeSpecification,
  KrPerLiterFuelSurchargeSpecificationUrl,
  Machine,
  MachineUrl,
  PricePercentFuelSurchargeSpecification,
  PricePercentFuelSurchargeSpecificationUrl,
  urlToId,
} from "@co-common-libs/resources";
import {ResponsiveInfoDialog} from "@co-frontend-libs/components";
import {
  createHref,
  getKrPerLiterFuelSurchargeSpecificationLookup,
  getMachineLookup,
  getPricePercentFuelSurchargeSpecificationLookup,
} from "@co-frontend-libs/redux";
import {DialogContent, IconButton, useTheme} from "@material-ui/core";
import _ from "lodash";
import PencilIcon from "mdi-react/PencilIcon";
import React, {useMemo} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useSelector} from "react-redux";

interface PricePercentFuelSurchargeIssuesLineProps {
  specification: PricePercentFuelSurchargeSpecification | undefined;
  specificationUrl: string;
}

const PricePercentFuelSurchargeIssuesLine = React.memo(function PricePercentFuelSurchargeIssuesLine(
  props: PricePercentFuelSurchargeIssuesLineProps,
): JSX.Element {
  const {specification, specificationUrl} = props;

  const intl = useIntl();
  const theme = useTheme();

  const name = specification
    ? specification.name
    : intl.formatMessage({defaultMessage: "Ukendt brændstoftillæg"});

  const href = createHref("/settings/pricePercentFuelSurcharge/:id", {
    id: urlToId(specificationUrl),
  });

  return (
    <div style={{color: theme.palette.error.main}}>
      <FormattedMessage
        defaultMessage="{name} mangler pris for opgavens dato."
        values={{
          name,
        }}
      />
      <IconButton color="primary" href={href}>
        <PencilIcon />
      </IconButton>
    </div>
  );
});

interface KrPerLiterSpecificationMissingEntryLineProps {
  specification: KrPerLiterFuelSurchargeSpecification | undefined;
  specificationUrl: string;
}

const KrPerLiterSpecificationMissingEntryLine = React.memo(
  function KrPerLiterSpecificationMissingEntryLine(
    props: KrPerLiterSpecificationMissingEntryLineProps,
  ): JSX.Element {
    const {specification, specificationUrl} = props;

    const intl = useIntl();
    const theme = useTheme();

    const name = specification
      ? specification.name
      : intl.formatMessage({defaultMessage: "Ukendt brændstoftillæg"});

    const href = createHref("/settings/krPerLiterFuelSurcharge/:id", {
      id: urlToId(specificationUrl),
    });

    return (
      <div style={{color: theme.palette.error.main}}>
        <FormattedMessage
          defaultMessage="{name} mangler pris for opgavens dato"
          values={{
            name,
          }}
        />
        <IconButton color="primary" href={href}>
          <PencilIcon />
        </IconButton>
      </div>
    );
  },
);

interface MachineMissingConsumptionLineProps {
  machine: Machine | undefined;
  machineUrl: string;
}

const MachineMissingConsumptionLine = React.memo(function MachineMissingConsumptionLine(
  props: MachineMissingConsumptionLineProps,
): JSX.Element {
  const {machine, machineUrl} = props;

  const intl = useIntl();
  const theme = useTheme();

  const machineName = machine
    ? machine.name
    : intl.formatMessage({defaultMessage: "Ukendt maskine"});
  const machineNumber = machine ? machine.c5_machine : "";

  const href = createHref("/settings/machine/:id", {
    id: urlToId(machineUrl),
  });

  return (
    <div style={{color: theme.palette.error.main}}>
      <FormattedMessage
        defaultMessage="Maskine {machineName} ({machineNumber}) mangler brændstofforbrug for brændstoftillæg"
        values={{
          machineName,
          machineNumber,
        }}
      />
      <IconButton color="primary" href={href}>
        <PencilIcon />
      </IconButton>
    </div>
  );
});

interface TaskFuelSurchargeIssuesDialogProps {
  krPerLiterProblemSpecifications?: ReadonlySet<KrPerLiterFuelSurchargeSpecificationUrl> | null;
  onClose: () => void;
  open: boolean;
  pricePercentProblemSpecifications?: ReadonlySet<PricePercentFuelSurchargeSpecificationUrl> | null;
  problemMachines?: ReadonlySet<MachineUrl> | null;
}

export const TaskFuelSurchargeIssuesDialog = React.memo(function TaskFuelSurchargeIssuesDialog(
  props: TaskFuelSurchargeIssuesDialogProps,
): JSX.Element {
  const {
    krPerLiterProblemSpecifications,
    onClose,
    open,
    pricePercentProblemSpecifications,
    problemMachines,
  } = props;

  const intl = useIntl();

  const machineLookup = useSelector(getMachineLookup);
  const krPerLiterFuelSurchargeSpecificationLookup = useSelector(
    getKrPerLiterFuelSurchargeSpecificationLookup,
  );
  const pricePercentFuelSurchargeSpecificationLookup = useSelector(
    getPricePercentFuelSurchargeSpecificationLookup,
  );

  const machineEntries = useMemo(() => {
    if (problemMachines) {
      const unsortedEntries = Array.from(problemMachines).map((machineUrl) => ({
        machine: machineLookup(machineUrl),
        machineUrl,
      }));
      return _.sortBy(unsortedEntries, [
        ({machine}) => machine?.c5_machine || "",
        ({machine}) => machine?.name || "",
        ({machineUrl}) => machineUrl,
      ]);
    } else {
      return [];
    }
  }, [machineLookup, problemMachines]);

  const krPerLiterSpecificationEntries = useMemo(() => {
    if (krPerLiterProblemSpecifications) {
      const unsortedEntries = Array.from(krPerLiterProblemSpecifications).map(
        (specificationUrl) => ({
          specification: krPerLiterFuelSurchargeSpecificationLookup(specificationUrl),
          specificationUrl,
        }),
      );
      return _.sortBy(unsortedEntries, [
        ({specification}) => specification?.name || "",
        ({specificationUrl}) => specificationUrl,
      ]);
    } else {
      return [];
    }
  }, [krPerLiterFuelSurchargeSpecificationLookup, krPerLiterProblemSpecifications]);

  const pricePercentSpecificationEntries = useMemo(() => {
    if (pricePercentProblemSpecifications) {
      const unsortedEntries = Array.from(pricePercentProblemSpecifications).map(
        (specificationUrl) => ({
          specification: pricePercentFuelSurchargeSpecificationLookup(specificationUrl),
          specificationUrl,
        }),
      );
      return _.sortBy(unsortedEntries, [
        ({specification}) => specification?.name || "",
        ({specificationUrl}) => specificationUrl,
      ]);
    } else {
      return [];
    }
  }, [pricePercentFuelSurchargeSpecificationLookup, pricePercentProblemSpecifications]);

  const machineLines = machineEntries.map(({machine, machineUrl}) => (
    <MachineMissingConsumptionLine key={machineUrl} machine={machine} machineUrl={machineUrl} />
  ));

  const krPerLiterSpecificationLines = krPerLiterSpecificationEntries.map(
    ({specification, specificationUrl}) => (
      <KrPerLiterSpecificationMissingEntryLine
        key={specificationUrl}
        specification={specification}
        specificationUrl={specificationUrl}
      />
    ),
  );

  const pricePercentSpecificationLines = pricePercentSpecificationEntries.map(
    ({specification, specificationUrl}) => (
      <PricePercentFuelSurchargeIssuesLine
        key={specificationUrl}
        specification={specification}
        specificationUrl={specificationUrl}
      />
    ),
  );

  return (
    <ResponsiveInfoDialog
      open={open}
      title={intl.formatMessage({defaultMessage: "Brændstoftillæg"})}
      onClose={onClose}
    >
      <DialogContent>
        {pricePercentSpecificationLines}
        {krPerLiterSpecificationLines}
        {machineLines}
      </DialogContent>
    </ResponsiveInfoDialog>
  );
});
