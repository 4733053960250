import {ReportingSpecificationTemplate} from "./types";

export const sprayLog: ReportingSpecificationTemplate = {
  active: true,
  allowSkip: false,
  allowTotalsTable: true,
  allowTransportedMaterialCountMismatch: true,
  autoInsertAmountInDelivery: true,
  autoInsertLastPickupValues: true,
  autoInsertLastWorkplaceConversionValue: true,
  autoInsertLastWorkplaceValues: false,
  customerLogSums: true,
  displayProductsPerWorkplace: true,
  displayWorkplaceOverviewOnPDF: false,
  extraFooterText:
    "Den angivne sprøjteteknik er anvendt i en bredde på minimum 20 meter. Den maksimale køre hastighed er 8 km/t. En maksimal bomhøjde på 50 cm (fra jord eller afgrøde), med mindre andet er nævnt under særlige vilkår ifm. denne sprøjtning. Vejrforhold egner sig til bomsprøjtning.",
  fieldsUsedFor: "workplace",
  hideMaterialsOnPrintout: true,
  horizontal: false,
  identifier: "spray-log",
  invoiceCustomerAddress: false,
  invoiceCustomerName: false,
  invoiceCustomerVatNumber: false,
  invoiceWorkplaceAddress: false,
  invoiceWorkplaceName: false,
  name: "Sprøjterapport",
  reportTransport: false,
  requireAtLeastOneLogEntry: false,
  sendMailToPickupDeliveryCustomers: false,
  shared: false,
  showCustomerOnEntryTable: false,
  showLoadCounts: false,
  showLocationMaterialSums: false,
  showLocationProducts: true,
  showMachines: true,
  showSharedTotalsTableOnAllOrderTasks: false,
  showTaskTimeOnReport: true,
  simpleLocationSumLog: false,
  taskData: {
    inputs: [
      {
        format: {
          alternatives: [
            {
              identifier: "50",
              label: "50%",
            },
            {
              identifier: "75",
              label: "75%",
            },
            {
              identifier: "90",
              label: "90%",
            },
          ],
          type: "choice",
        },
        identifier: "afdriftsreduktion ",
        label: "Afdriftsreduktion",
        required: false,
      },
      {
        format: {
          alternatives: [
            {
              identifier: "albuz",
              label: "Albuz, CVI Twin 110-03  Max 1,5 bar",
            },
            {
              identifier: "hardi",
              label: "Hardi, Minidrift DUO 110-03, max 1,5 bar",
            },
            {
              identifier: "teejet",
              label: "TeeJet, TTI 110 03 VP, max 1,5 bar",
            },
          ],
          type: "choice",
        },
        identifier: "dysefabrikat",
        label: "Dysefabrikat og dysetyper",
        required: false,
      },
    ],
  },
  versionNote: "Første version",
  workplaceData: {
    workplace: {
      inputs: [
        {
          fieldAreaTarget: true,
          format: {
            decimalPlaces: 2,
            maxDigits: 9,
            type: "decimal",
          },
          identifier: "area",
          label: "Areal, ha",
          required: false,
        },
        {
          fieldCropTarget: true,
          format: {
            multiline: false,
            type: "text",
          },
          identifier: "crop",
          label: "Afgrøde",
          required: false,
        },
        {
          format: {
            multiline: true,
            type: "text",
          },
          identifier: "note",
          label: "Note",
          required: false,
        },
      ],
      logEntries: 1,
      logInputs: [
        {
          format: {
            decimalPlaces: 2,
            maxDigits: 9,
            type: "decimal",
          },
          identifier: "area",
          label: "Areal behandlet",
          required: true,
          sum: true,
          transfer: true,
          unit: "HA",
        },
        {
          format: {
            multiline: false,
            type: "text",
          },
          identifier: "crop",
          label: "Afgrøde",
          required: false,
        },
        {
          format: {
            type: "integer",
          },
          identifier: "water",
          label: "Afst. til vand, m",
          required: false,
        },
        {
          format: {
            type: "integer",
          },
          identifier: "para",
          label: "Afst. til §3, m",
          required: false,
        },
        {
          format: {
            multiline: true,
            type: "text",
          },
          identifier: "note",
          label: "Note",
          required: false,
        },
      ],
      logProducts: true,
      productConversion: {
        field: "area",
        label: "Dosering",
        unit: "HA",
      },
    },
  },
  workplaceRegistration: 1,
};
