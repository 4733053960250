import {Customer, urlToId} from "@co-common-libs/resources";
import {ResponseWithData, jsonFetch} from "@co-frontend-libs/utils";
import {globalConfig} from "frontend-global-config";
import {CustomerFormMember} from "../../customer-form/types";

const {baseURL} = globalConfig.resources;

function transformCustomerForRequest(
  customer: Pick<Customer, CustomerFormMember>,
): Pick<
  Customer,
  "active" | "address" | "billingEmail" | "city" | "name" | "phone" | "postalCode" | "vatNumber"
> & {c5Account: string | null} {
  const {
    active,
    address,
    billingEmail,
    c5_account: c5Account,
    city,
    name,
    phone,
    postalCode,
    vatNumber,
  } = customer;

  return {
    active,
    address,
    billingEmail,
    c5Account,
    city,
    name,
    phone,
    postalCode,
    vatNumber,
  };
}

export function requestEditCustomerInEconomic(
  customer: Pick<Customer, CustomerFormMember | "url">,
  abortSignal?: AbortSignal | undefined,
): Promise<ResponseWithData> {
  return jsonFetch(
    `${baseURL}economic/customer/${urlToId(customer.url)}`,
    "POST",
    transformCustomerForRequest(customer),
    abortSignal,
  );
}

export function requestCreateExistingCustomerInEconomic(
  customer: Pick<Customer, CustomerFormMember | "url">,
  abortSignal?: AbortSignal | undefined,
): Promise<ResponseWithData> {
  return jsonFetch(
    `${baseURL}economic/customer/create-in-economic/${urlToId(customer.url)}`,
    "POST",
    transformCustomerForRequest(customer),
    abortSignal,
  );
}

export function requestCreateNewCustomerInEconomic(
  customer: Pick<Customer, CustomerFormMember>,
  abortSignal?: AbortSignal | undefined,
): Promise<ResponseWithData> {
  return jsonFetch(
    `${baseURL}economic/customer/create`,
    "POST",
    transformCustomerForRequest(customer),
    abortSignal,
  );
}
