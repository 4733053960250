import {KrPerLiterFuelSurchargeSpecification} from "@co-common-libs/resources";
import {Grid} from "@material-ui/core";
import {PageLayout} from "app-components";
import React from "react";
import {useIntl} from "react-intl";
import {FuelSurchargeInvoiceProduct} from "../common/fuel-surcharge-invoice-product";
import {FuelSurchargeInvoicingRule} from "../common/fuel-surcharge-invoicing-rule";
import {FuelSurchargeSpecificationEdit} from "../common/fuel-surcharge-specification-edit";
import {KrPerLiterFuelSurchargeBasisCurrent} from "./kr-per-liter-fuel-surcharge-basis-current";
import {KrPerLiterFuelSurchargeBasisHistoric} from "./kr-per-liter-fuel-surcharge-basis-historic";
import {KrPerLiterFuelSurchargeInvoiceLineText} from "./kr-per-liter-fuel-surcharge-invoice-line-text";
import {KrPerLiterFuelSurchargeSpecificationEntryTable} from "./kr-per-liter-fuel-surcharge-specification-entry-table";

interface KrPerLiterFuelSurchargeSpecificationLoadedProps {
  instance: KrPerLiterFuelSurchargeSpecification;
}

export const KrPerLiterFuelSurchargeSpecificationLoaded = React.memo(
  function KrPerLiterFuelSurchargeSpecificationLoaded(
    props: KrPerLiterFuelSurchargeSpecificationLoadedProps,
  ): JSX.Element {
    const {instance: specification} = props;

    const intl = useIntl();

    return (
      <PageLayout withPadding toolbar={intl.formatMessage({defaultMessage: "Brændstoftillæg"})}>
        <Grid container spacing={2}>
          <Grid item lg={6} xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FuelSurchargeSpecificationEdit specification={specification} />
              </Grid>
              <Grid item xs={12}>
                <KrPerLiterFuelSurchargeBasisCurrent specification={specification} />
              </Grid>
              <Grid item xs={12}>
                <KrPerLiterFuelSurchargeBasisHistoric specification={specification} />
              </Grid>
              <Grid item xs={12}>
                <FuelSurchargeInvoicingRule specification={specification} />
              </Grid>
              <Grid item xs={12}>
                <KrPerLiterFuelSurchargeInvoiceLineText specification={specification} />
              </Grid>
              <Grid item xs={12}>
                <FuelSurchargeInvoiceProduct specification={specification} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={6} xs={12}>
            <KrPerLiterFuelSurchargeSpecificationEntryTable specification={specification} />
          </Grid>
        </Grid>
      </PageLayout>
    );
  },
);
