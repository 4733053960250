import {TableCell} from "@material-ui/core";
import {PureComponent} from "app-utils";
import React from "react";

export class RotatedTableHeaderColumn extends PureComponent<{
  children: React.ReactNode;
  style?: React.CSSProperties;
  width?: number;
}> {
  render(): JSX.Element {
    const {children, style, width, ...others} = this.props;
    const normalWidth = 25;
    return (
      <TableCell
        style={{
          height: 140,
          paddingLeft: 5,
          paddingRight: 5,
          whiteSpace: "nowrap",
          ...style,
        }}
        {...others}
      >
        <div
          style={{
            transform: `translate(${width ?? normalWidth}px, 51px) rotate(315deg)`,
            width: 30,
          }}
        >
          <span
            style={{
              borderBottom: "1px solid #ccc",
              padding: "5px 10px",
            }}
          >
            {children}
          </span>
        </div>
      </TableCell>
    );
  }
}
