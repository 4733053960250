import {
  IntervalTaskData,
  Rate,
  RemunerationGroup,
  buildCommonOptions,
  buildGroupOptions,
  computeDurationMilliseconds,
  computeIntervalDurationMilliseconds,
  computeWorkdaysFull,
} from "@co-common-libs/payroll";
import {MachineUrl, PriceGroupUrl, Task, TimerUrl, WorkTypeUrl} from "@co-common-libs/resources";
import {formatDuration, timerMayBeBilled} from "@co-common-libs/resources-utils";
import {MINUTE_MILLISECONDS} from "@co-common-libs/utils";
import {
  getCurrentRole,
  getCurrentUserProfile,
  getCustomerSettings,
  getMachineArray,
  getOrderArray,
  getPriceGroupArray,
  getPriceGroupLookup,
  getPriceItemArray,
  getPriceItemLookup,
  getProjectArray,
  getTimerArray,
  getTimerLookup,
  getWorkTypeArray,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {colorMap} from "@co-frontend-libs/utils";
import {getEmployeeRemunerationGroup, getGenericPrimaryTimer} from "app-utils";
import React, {useCallback, useMemo} from "react";
// Allowed for existing code...
// eslint-disable-next-line deprecate/import
import {Cell, Grid} from "react-flexr";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";

interface CompletedTasksSumsProps {
  tasks: readonly Task[];
}

export const CompletedTasksSums = React.memo(function CompletedTasksSums(
  props: CompletedTasksSumsProps,
): JSX.Element {
  const {tasks} = props;

  const currentUserRole = useSelector(getCurrentRole);
  const currentUserIsMechanic = currentUserRole && currentUserRole.mechanic;
  const currentUserProfile = useSelector(getCurrentUserProfile);
  const customerSettings = useSelector(getCustomerSettings);
  const workTypeArray = useSelector(getWorkTypeArray);
  const machineArray = useSelector(getMachineArray);
  const priceGroupArray = useSelector(getPriceGroupArray);
  const timerArray = useSelector(getTimerArray);
  const orderArray = useSelector(getOrderArray);
  const projectArray = useSelector(getProjectArray);
  const priceItemArray = useSelector(getPriceItemArray);
  const timerLookup = useSelector(getTimerLookup);
  const workTypeLookup = useSelector(getWorkTypeLookup);
  const priceGroupLookup = useSelector(getPriceGroupLookup);
  const priceItemLookup = useSelector(getPriceItemLookup);

  const employeeDefaultRemunerationGroup = getEmployeeRemunerationGroup(
    customerSettings,
    currentUserProfile,
  );
  const workTypeIDURLMapping: {
    [workTypeID: string]: WorkTypeUrl;
  } = {};
  workTypeArray.forEach((workType) => {
    workTypeIDURLMapping[workType.identifier] = workType.url;
  });
  const machineIDURLMapping: {[machineID: string]: MachineUrl} = {};
  machineArray.forEach((machine) => {
    machineIDURLMapping[machine.c5_machine] = machine.url;
  });
  const priceGroupIDURLMapping: {[priceGroupID: string]: PriceGroupUrl} = {};
  priceGroupArray.forEach((priceGroup) => {
    priceGroupIDURLMapping[priceGroup.identifier] = priceGroup.url;
  });

  const remunerationGroups = new Map<string, RemunerationGroup>();
  Object.entries(customerSettings.remunerationGroups).forEach(([identifier, options]) => {
    const x = buildGroupOptions(
      options as any,
      customerSettings,
      workTypeIDURLMapping,
      machineIDURLMapping,
      priceGroupIDURLMapping,
      [], //daysAbsenceList
    );
    remunerationGroups.set(identifier, x);
  });

  const commonRemunerationSettings = buildCommonOptions(customerSettings, workTypeIDURLMapping);

  const {remunerationNormalTransportKilometersCountBonusLabel} = customerSettings;

  const timerMap = useMemo(() => new Map(timerArray.map((t) => [t.url, t])), [timerArray]);
  const orderMap = useMemo(() => new Map(orderArray.map((o) => [o.url, o])), [orderArray]);
  const projectMap = useMemo(() => new Map(projectArray.map((o) => [o.url, o])), [projectArray]);
  const priceItemMap = useMemo(
    () => new Map(priceItemArray.map((o) => [o.url, o])),
    [priceItemArray],
  );

  const workDaysMap = computeWorkdaysFull(
    tasks,
    null, // punchedInOutArray
    timerMap,
    orderMap,
    projectMap,
    priceItemMap,
    [], //daysAbsenceList
    currentUserProfile && currentUserProfile.normalTransportMinutes != null
      ? currentUserProfile.normalTransportMinutes
      : undefined,
    employeeDefaultRemunerationGroup,
    remunerationGroups,
    commonRemunerationSettings,
    remunerationNormalTransportKilometersCountBonusLabel,
    currentUserProfile && currentUserProfile.normalTransportKilometers != null
      ? currentUserProfile.normalTransportKilometers
      : undefined,
  );

  let breakMilliseconds = 0;
  let workMilliseconds = 0;
  let effectiveMilliseconds = 0;
  let totalWorkPeriodMilliseconds = 0;

  const primaryTimer = getGenericPrimaryTimer(timerMap);
  const primaryTimerURL = primaryTimer ? primaryTimer.url : "";

  const intervalMayBeBilled = useCallback(
    (t: IntervalTaskData): boolean =>
      !!t.customerTask &&
      !!t.timerURL &&
      timerMayBeBilled(
        t.timerURL,
        primaryTimerURL as TimerUrl,
        timerLookup,
        workTypeLookup,
        priceGroupLookup,
        priceItemLookup,
      ),
    [priceGroupLookup, priceItemLookup, primaryTimerURL, timerLookup, workTypeLookup],
  );

  workDaysMap.forEach((workDays, _group) => {
    for (let i = 0; i < workDays.length; i += 1) {
      const workDay = workDays[i];
      const {workPeriods} = workDay;
      for (let j = 0; j < workPeriods.length; j += 1) {
        const workPeriod = workPeriods[j];
        totalWorkPeriodMilliseconds += computeDurationMilliseconds(
          new Date(workPeriod.firstFromTimestamp),
          new Date(workPeriod.lastToTimestamp),
        );
        const {breaks, work} = workPeriod;
        for (let k = 0; k < breaks.length; k += 1) {
          const breakInterval = breaks[k];
          breakMilliseconds += computeIntervalDurationMilliseconds(breakInterval);
        }
        for (let k = 0; k < work.length; k += 1) {
          const workInterval = work[k];
          const intervalMilliseconds = computeIntervalDurationMilliseconds(workInterval);
          if (workInterval.rate !== Rate.UNPAID) {
            workMilliseconds += intervalMilliseconds;
          }
          if (!workInterval.unregistered && workInterval.taskData.some(intervalMayBeBilled)) {
            effectiveMilliseconds += intervalMilliseconds;
          }
        }
      }
    }
  });

  const breakMinutes = Math.round(breakMilliseconds / MINUTE_MILLISECONDS);
  const workMinutes = Math.round(workMilliseconds / MINUTE_MILLISECONDS);
  const effectiveMinutes = Math.round(effectiveMilliseconds / MINUTE_MILLISECONDS);
  const internalMinutes = workMinutes - effectiveMinutes;

  const totalWorkPeriodMinutes = Math.round(totalWorkPeriodMilliseconds / MINUTE_MILLISECONDS);

  const paidMinutes = workMinutes;

  const efficiencyPercent = Math.round((effectiveMinutes / paidMinutes) * 100) || 0;

  return (
    <Grid>
      {!currentUserIsMechanic ? (
        <Cell palm="12/12">
          <table
            style={{
              maxWidth: "18em",
              width: "100%",
            }}
          >
            <tbody>
              <tr>
                <td>
                  <FormattedMessage defaultMessage="Intern" id="time-overview.label.iternal-time" />
                </td>
                <td style={{textAlign: "right"}}>
                  {formatDuration(customerSettings.durationFormat, internalMinutes)}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    color: colorMap.ACTIVITY_EFFECTIVE,
                    fontWeight: "bold",
                  }}
                >
                  <FormattedMessage
                    defaultMessage="Effektiv"
                    id="time-overview.label.effective-time"
                  />
                </td>
                <td
                  style={{
                    color: colorMap.ACTIVITY_EFFECTIVE,
                    fontWeight: "bold",
                    textAlign: "right",
                  }}
                >
                  {formatDuration(customerSettings.durationFormat, effectiveMinutes)}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    color: colorMap.ACTIVITY_EFFECTIVE,
                    fontWeight: "bold",
                  }}
                >
                  <FormattedMessage
                    defaultMessage="Effektivitet"
                    id="time-overview.label.efficiency"
                  />
                </td>
                <td
                  style={{
                    color: colorMap.ACTIVITY_EFFECTIVE,
                    fontWeight: "bold",
                    textAlign: "right",
                  }}
                >
                  {efficiencyPercent}%
                </td>
              </tr>
            </tbody>
          </table>
        </Cell>
      ) : null}
      <Cell palm="12/12">
        <table
          style={{
            maxWidth: "18em",
            width: "100%",
          }}
        >
          <tbody>
            <tr>
              <td>
                <FormattedMessage
                  defaultMessage="Total arbejdstid"
                  id="time-overview.label.total-time"
                />
              </td>
              <td style={{textAlign: "right"}}>
                {formatDuration(customerSettings.durationFormat, totalWorkPeriodMinutes)}
              </td>
            </tr>
            <tr>
              <td>
                <FormattedMessage defaultMessage="Pauser" id="time-overview.label.total-break" />
              </td>
              <td style={{textAlign: "right"}}>
                {formatDuration(customerSettings.durationFormat, breakMinutes)}
              </td>
            </tr>
            <tr>
              <td>
                <strong>
                  <FormattedMessage defaultMessage="Betalt tid" id="time-overview.label.paid" />
                </strong>
              </td>
              <td style={{textAlign: "right"}}>
                <strong>{formatDuration(customerSettings.durationFormat, paidMinutes)}</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </Cell>
    </Grid>
  );
});
