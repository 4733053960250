import {SettingID} from "@co-common-libs/config";
import {MachineUrl, WorkType, WorkTypeUrl} from "@co-common-libs/resources";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {
  ConnectedExternalWorkTypeDialog,
  ConnectedMachineDialog,
} from "@co-frontend-libs/connected-components";
import {
  actions,
  getCurrentUserURL,
  getMachineArray,
  getMachineLookup,
  getSettingsEntryLookupByIdentifier,
  getWorkTypeArray,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {Button, Chip, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {WorkTypeChip} from "app-components";
import _ from "lodash";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {DeletableChip} from "../../reports/deletable-chip";

interface WorkTypeAutoMachinesDialogDialogProps {
  onClose: () => void;
  open: boolean;
  settingID: SettingID;
}

const MachineChip = ({
  label,
  machineIdentifier,
  onDeleteClick,
  workTypeIdentifier,
}: {
  label: string;
  machineIdentifier: string;
  onDeleteClick: (workTypeIdentifier: string, machineIdentifier: string) => void;
  workTypeIdentifier: string;
}): JSX.Element => {
  const handleDelete = useCallback(() => {
    onDeleteClick(workTypeIdentifier, machineIdentifier);
  }, [onDeleteClick, machineIdentifier, workTypeIdentifier]);

  return (
    <div style={{margin: 2}}>
      <Chip label={label} onDelete={handleDelete} />
    </div>
  );
};

const RowButton = ({
  identifier,
  label,
  onClick,
}: {
  identifier: string;
  label: JSX.Element;
  onClick: (identifier: string) => void;
}): JSX.Element => {
  const handleClick = useCallback(() => {
    onClick(identifier);
  }, [identifier, onClick]);

  return (
    <Button color="primary" onClick={handleClick}>
      {label}
    </Button>
  );
};

export function WorkTypeAutoMachinesDialog(
  props: WorkTypeAutoMachinesDialogDialogProps,
): JSX.Element {
  const {onClose, open, settingID} = props;

  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const settingEntry = settingsEntryLookupByIdentifier(settingID);
  const workTypeArray = useSelector(getWorkTypeArray);
  const machineArray = useSelector(getMachineArray);
  const workTypeLookup = useSelector(getWorkTypeLookup);
  const machineLookup = useSelector(getMachineLookup);
  const primaryTimerLabels: {
    [worktypeIdentifier: string]: string[] | undefined;
  } = useMemo(() => settingEntry?.data || {}, [settingEntry?.data]);

  const [values, setValues] = useState(primaryTimerLabels);

  useEffect(() => {
    if (open) {
      setValues(primaryTimerLabels);
    }
  }, [open, primaryTimerLabels]);

  const dispatch = useDispatch();
  const currentUserURL = useSelector(getCurrentUserURL);

  const handleSave = useCallback(() => {
    if (settingEntry) {
      dispatch(
        actions.update(settingEntry.url, [
          {member: "changedBy", value: currentUserURL},
          {member: "data", value: values},
        ]),
      );
    }
    onClose();
  }, [currentUserURL, dispatch, onClose, settingEntry, values]);

  const [machineDialogOpenedFor, setMachineDialogOpenedFor] = useState<string | null>(null);
  const handleAddMachineClick = useCallback((workTypeIdentifier: string) => {
    setMachineDialogOpenedFor(workTypeIdentifier);
  }, []);

  const handleMachineDialogCancel = useCallback(() => {
    setMachineDialogOpenedFor(null);
  }, []);

  const handleMachineDialogOk = useCallback(
    (url: MachineUrl) => {
      setMachineDialogOpenedFor(null);

      const machine = machineLookup(url);
      if (!machine || !machineDialogOpenedFor) {
        return;
      }

      if (values[machineDialogOpenedFor]?.includes(machine.c5_machine)) {
        return;
      }

      const newValues = {
        ...values,
        [machineDialogOpenedFor]: [...(values[machineDialogOpenedFor] || []), machine.c5_machine],
      };
      setValues(newValues);
    },
    [machineDialogOpenedFor, machineLookup, values],
  );

  const [workTypeDialogOpen, setworkTypeDialogOpen] = useState(false);
  const setworkTypeDialogOpenTrue = useCallWithTrue(setworkTypeDialogOpen);
  const setworkTypeDialogOpenFalse = useCallWithFalse(setworkTypeDialogOpen);

  const handleWorkTypeDialogOk = useCallback(
    (url: WorkTypeUrl) => {
      setworkTypeDialogOpen(false);
      const workType = workTypeLookup(url);
      if (!workType) {
        return;
      }
      const newValues = {
        ...values,
        [workType.identifier]: undefined,
      };
      setValues(newValues);
    },
    [values, workTypeLookup],
  );

  const handleWorktypeDelete = useCallback(
    (workType: WorkType) => {
      setValues(_.omit(values, workType.identifier));
    },
    [values],
  );

  const handleNoneWorktypeIdDelete = useCallback(
    (identifier: string) => {
      setValues(_.omit(values, identifier));
    },
    [values],
  );

  const handleMachineDelete = useCallback(
    (worktypeIdentifier: string, machineIdentifier: string) => {
      const newValues = {
        ...values,
        [worktypeIdentifier]: (values[worktypeIdentifier] || []).filter(
          (m) => m !== machineIdentifier,
        ),
      };
      setValues(newValues);
    },
    [values],
  );

  const intl = useIntl();

  return (
    <>
      <ResponsiveDialog
        fullscreen
        okLabel={<FormattedMessage defaultMessage="Gem" id="setting-dialog.label.save" />}
        open={open}
        title={
          <FormattedMessage
            defaultMessage="Opsæt automaskinepåførsel"
            id="system-setup.dialog-title.automachine"
          />
        }
        onCancel={onClose}
        onOk={handleSave}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <FormattedMessage defaultMessage="Område" id="system-setup.work-type" />
              </TableCell>
              <TableCell>
                <FormattedMessage defaultMessage="Maskiner" id="system-setup.machines" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(values).map(([worktypeIdentifier, machines]) => {
              const workType = workTypeArray.find((w) => w.identifier === worktypeIdentifier);

              return (
                <TableRow key={worktypeIdentifier}>
                  <TableCell>
                    {workType ? (
                      <WorkTypeChip workType={workType} onDeleteClick={handleWorktypeDelete} />
                    ) : (
                      <DeletableChip
                        deletionId={worktypeIdentifier}
                        label={intl.formatMessage({
                          defaultMessage: "Ikke eksisterende område",
                        })}
                        onDelete={handleNoneWorktypeIdDelete}
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    <div>
                      <RowButton
                        identifier={worktypeIdentifier}
                        label={<FormattedMessage defaultMessage="Tilføj" id="system-setup.add" />}
                        onClick={handleAddMachineClick}
                      />
                    </div>
                    {machines
                      ? machines.map((machineIdentifier) => {
                          const machine = machineArray.find(
                            (m) => m.c5_machine === machineIdentifier,
                          );
                          return (
                            <MachineChip
                              key={machineIdentifier}
                              label={`${machineIdentifier} ${machine?.name}`}
                              machineIdentifier={machineIdentifier}
                              workTypeIdentifier={worktypeIdentifier}
                              onDeleteClick={handleMachineDelete}
                            />
                          );
                        })
                      : null}
                  </TableCell>
                  <TableCell />
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell>
                <Button color="primary" onClick={setworkTypeDialogOpenTrue}>
                  <FormattedMessage defaultMessage="Tilføj" id="system-setup.add" />
                </Button>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableBody>
        </Table>
      </ResponsiveDialog>
      <ConnectedExternalWorkTypeDialog
        open={workTypeDialogOpen}
        onCancel={setworkTypeDialogOpenFalse}
        onOk={handleWorkTypeDialogOk}
      />
      <ConnectedMachineDialog
        open={!!machineDialogOpenedFor}
        onCancel={handleMachineDialogCancel}
        onOk={handleMachineDialogOk}
      />
    </>
  );
}
