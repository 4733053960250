import {Machine, emptyMachine} from "@co-common-libs/resources";
import {instanceURL} from "frontend-global-config";
import {v4 as uuid} from "uuid";

export function createMachine(instance: Partial<Omit<Machine, "id" | "url">>): Machine {
  const id = uuid();
  const url = instanceURL("machine", id);

  return {
    id,
    url,
    ...emptyMachine,
    ...instance,
  };
}
