import {ProductUrl, Task} from "@co-common-libs/resources";

export function getProductsWithLogData(task: Task): Set<ProductUrl> {
  const productsWithLogData = new Set<ProductUrl>();
  const {reportingLocations, reportingLog} = task;
  [...Object.values(reportingLog || {}), ...Object.values(reportingLocations || {})].forEach(
    ({productUses}) => {
      if (!productUses) {
        return;
      }
      Object.values(productUses).forEach(({count, product: productUrl}) => {
        if (count !== null) {
          productsWithLogData.add(productUrl);
        }
      });
    },
  );
  return productsWithLogData;
}
