import {Checkbox} from "@material-ui/core";
import React, {useMemo} from "react";

export interface BatchOperation<TableDataType> {
  accept: (selected: readonly TableDataType[]) => Promise<void>;
  canCheck: (checkedTasks: readonly TableDataType[], task: TableDataType) => boolean;
  icon: JSX.Element;
  label: string;
  name: string;
}

function makeBatchOperationCheckboxRender<
  ResourceUrl extends string,
  TableDataType extends {key: ResourceUrl},
>({
  currentBatch,
  currentBatchOperation,
  updateCurrentBatch,
}: {
  currentBatch: readonly TableDataType[];
  currentBatchOperation: BatchOperation<TableDataType> | null;
  updateCurrentBatch: (resource: TableDataType, checked: boolean) => void;
}): (data: TableDataType) => JSX.Element | null {
  const changeHandlerMap = new Map<
    ResourceUrl,
    (event: React.ChangeEvent<HTMLInputElement>) => void
  >();
  return function renderCheckbox(data: TableDataType): JSX.Element | null {
    let handleChange = changeHandlerMap.get(data.key);
    if (!handleChange) {
      handleChange = (event: React.ChangeEvent<HTMLInputElement>): void =>
        updateCurrentBatch(data, event.target.checked);
      changeHandlerMap.set(data.key, handleChange);
    }
    return currentBatchOperation !== null ? (
      <Checkbox
        key={data.key}
        checked={currentBatch.includes(data)}
        disabled={!currentBatchOperation?.canCheck(currentBatch, data)}
        onChange={handleChange}
      />
    ) : null;
  };
}

export function useBatchCheckboxRenderer<
  ResourceUrl extends string,
  TableDataType extends {key: ResourceUrl},
>(batchOperationState: {
  currentBatch: readonly TableDataType[];
  currentBatchOperation: BatchOperation<TableDataType> | null;
  updateCurrentBatch: (resource: TableDataType, checked: boolean) => void;
}): (data: TableDataType) => JSX.Element | null {
  return useMemo(
    () => makeBatchOperationCheckboxRender(batchOperationState),
    [batchOperationState],
  );
}
