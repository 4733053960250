import _ from "lodash";
import React, {useEffect, useState} from "react";

interface MapMarkerProps {
  icon?: google.maps.Icon | google.maps.Symbol;
  map: google.maps.Map;
  position: google.maps.LatLngLiteral;
}

/**
 * Convenience React wrapper component for `Marker`.
 * Don't use this for large numbers of elements.
 */
export const MapMarker = React.memo(function MapMarker(props: MapMarkerProps): null {
  const {icon = null, map, position} = props;

  // state rather than ref to not repeatedly call constructor
  const [marker] = useState(
    () =>
      new google.maps.Marker({
        icon,
        map,
        position,
      }),
  );

  useEffect(() => {
    // remove marker from map on unmount
    return () => {
      marker.setMap(null);
    };
  }, [marker]);

  useEffect(() => {
    marker.setMap(map);
  }, [map, marker]);

  const {lat, lng} = position;

  useEffect(() => {
    marker.setPosition({lat, lng});
  }, [lat, lng, marker]);

  useEffect(() => {
    marker.setIcon(icon);
  }, [icon, marker]);

  return null;
}, _.isEqual);
