import {Button, Card, CardContent, useTheme} from "@material-ui/core";
import {DragAndDropUploadOverlay} from "app-components";
import {parse} from "csv-parse/sync";
import _ from "lodash";
import React, {useCallback, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {ImportDelimiter, supportedImportDelimiters} from "./import-preview-card";

interface CsvUploadCardProps {
  onFileRead: (
    delimitersAndRowsMap: ReadonlyMap<ImportDelimiter, readonly (readonly string[])[]>,
  ) => void;
}

export function CsvUploadCard(props: CsvUploadCardProps): JSX.Element {
  const {onFileRead} = props;

  const intl = useIntl();
  const theme = useTheme();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [filename, setFilename] = useState<string | null>(null);

  // HACK: Have React replace <input> DOM-element after each selection;
  // so selecting the "same" file again will still trigger change event
  const [fileChangeTimestamp, setFileChangeTimestamp] = useState("");

  const handleFile = useCallback(
    function (file: File) {
      setFileChangeTimestamp(new Date().toISOString());
      if (file.name.toLocaleLowerCase().endsWith(".csv")) {
        const reader = new FileReader();

        reader.onload = function (event) {
          const errors: unknown[] = [];
          const succesfullyParsed = new Map<ImportDelimiter, readonly (readonly string[])[]>();

          // Typecast below okay, onload is only fired on successful Load.
          const filetext = (event.target as FileReader).result as string;

          supportedImportDelimiters.forEach((possibleImportDelimiter) => {
            try {
              const parsedRows: string[][] = parse(filetext, {
                delimiter: possibleImportDelimiter,
                skipEmptyLines: true,
              });

              succesfullyParsed.set(possibleImportDelimiter, parsedRows);
            } catch (error) {
              errors.push(error);
            }
          });

          if (succesfullyParsed.size) {
            setFilename(file.name);
            setErrorMessage(null);
            onFileRead(succesfullyParsed);
          } else {
            const errortext = errors
              .map((error) => {
                if (error instanceof Error) {
                  return intl.formatMessage(
                    {defaultMessage: "Fejl: {message}."},
                    {message: error.message},
                  );
                } else {
                  return intl.formatMessage({
                    defaultMessage: "Fejl: Ukendt fejl.",
                  });
                }
              })
              .join(" ");

            setErrorMessage(errortext);
            setFilename(null);
            onFileRead(new Map());
          }
        };
        reader.readAsText(file);
      } else {
        setErrorMessage(intl.formatMessage({defaultMessage: "Fejl: Filen er ikke en CSV"}));
        setFilename(null);
        onFileRead(new Map());
      }
    },
    [onFileRead, intl],
  );

  const handleFileInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files;
      if (file !== null && file.length !== 0) {
        handleFile(file[0]);
      }
    },
    [handleFile],
  );

  const handleDroppedFiles = useCallback(
    (files: readonly File[]) => {
      if (files.length > 0) {
        handleFile(files[0]);
      }
    },
    [handleFile],
  );

  const csvUploadButton = (
    <>
      <input
        key={`csv-input-${fileChangeTimestamp}`}
        accept="text/csv"
        id="csv-upload-input"
        style={{display: "none"}}
        type="file"
        onChange={handleFileInputChange}
      />
      <label htmlFor="csv-upload-input">
        <Button color="primary" component="span" style={{margin: "0.5em"}} variant="contained">
          <FormattedMessage defaultMessage="Vælg CSV" />
        </Button>
      </label>
      <FormattedMessage defaultMessage="eller træk CSV hertil" />
    </>
  );

  return (
    <DragAndDropUploadOverlay onFiles={handleDroppedFiles}>
      <Card>
        <CardContent>
          {csvUploadButton}
          {errorMessage ? (
            <div style={{color: theme.palette.error.main}}>{errorMessage}</div>
          ) : null}
          {filename ? (
            <FormattedMessage
              defaultMessage="Fil indlæst: {filename}"
              tagName="div"
              values={{filename}}
            />
          ) : null}
        </CardContent>
      </Card>
    </DragAndDropUploadOverlay>
  );
}
