import {CustomerUrl, Task} from "@co-common-libs/resources";
import {TranslatedError} from "@co-common-libs/utils";
import {getCustomerLookup} from "@co-frontend-libs/redux";
import {jsonFetch, translateNetworkError, useResettingState} from "@co-frontend-libs/utils";
import {useCustomerFromTask} from "app-utils";
import {globalConfig} from "frontend-global-config";
import React, {useCallback, useEffect, useRef} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {ProcessingDialog} from "../processing-dialog";
import {ChangeOrMergeCustomerQuestionDialog} from "./change-or-merge-customer-question-dialog";

interface ChangeOrMergeTaskCustomerProps {
  allowChangeCurrentTaskOnly: boolean;
  onCancel: () => void;
  onChangeCustomer: () => void;
  onFailure: () => void;
  onMergeCustomer: () => void;
  open: boolean;
  task: Task;
  toCustomerUrl: CustomerUrl;
}

export const ChangeOrMergeTaskCustomer = React.memo(function ChangeOrMergeTaskCustomer(
  props: ChangeOrMergeTaskCustomerProps,
): JSX.Element | null {
  const {
    allowChangeCurrentTaskOnly,
    onCancel,
    onChangeCustomer,
    onFailure,
    onMergeCustomer,
    open,
    task,
    toCustomerUrl,
  } = props;

  const [checkAffectedTasksPromise, setCheckAffectedTasksPromise] =
    useResettingState<Promise<number> | null>(null, !open);

  const [affectedTasksCount, setAffectedTasksCount] = useResettingState<number>(0, open);

  const [changeOrMergeDialogOpen, setChangeOrMergeDialogOpen] = useResettingState<boolean>(
    false,
    open,
  );

  const abortController = useRef<AbortController>();

  const customerLookup = useSelector(getCustomerLookup);
  const intl = useIntl();

  const fromCustomer = useCustomerFromTask(task);
  const toCustomer = customerLookup(toCustomerUrl);

  useEffect(() => {
    if (open) {
      setCheckAffectedTasksPromise(async () => {
        const {baseURL} = globalConfig.resources;
        const url = `${baseURL}tasks_for_draft_customer`;

        if (!fromCustomer) {
          throw new TranslatedError(
            intl.formatMessage({
              defaultMessage: "Kunne ikke finde kunde på opgaven",
            }),
          );
        }

        if (window.AbortController) {
          abortController.current?.abort();
          abortController.current = new AbortController();
        }

        try {
          const {data} = await jsonFetch(
            url,
            "POST",
            {
              customer: fromCustomer.url,
            },
            abortController.current?.signal,
          );

          return Array.isArray(data) ? data.length : 0;
        } catch (error) {
          throw new TranslatedError(translateNetworkError(error, intl));
        }
      });
    }

    return () => {
      abortController.current?.abort();
      abortController.current = undefined;
    };
    // execute promise on open
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleProcessingSuccess = useCallback(
    (affectedTasks: number) => {
      setAffectedTasksCount(affectedTasks);

      if (affectedTasks > 1) {
        setChangeOrMergeDialogOpen(true);
      } else {
        onMergeCustomer();
      }
    },
    [onMergeCustomer, setAffectedTasksCount, setChangeOrMergeDialogOpen],
  );

  return checkAffectedTasksPromise ? (
    <>
      <ProcessingDialog
        processPromise={checkAffectedTasksPromise}
        title={intl.formatMessage({defaultMessage: "Arbejder..."})}
        onFailure={onFailure}
        onSuccess={handleProcessingSuccess}
      />
      {affectedTasksCount && fromCustomer && toCustomer ? (
        <ChangeOrMergeCustomerQuestionDialog
          allowChangeCurrentTaskOnly={allowChangeCurrentTaskOnly}
          fromCustomer={fromCustomer}
          open={changeOrMergeDialogOpen}
          taskCount={affectedTasksCount}
          toCustomer={toCustomer}
          onCancel={onCancel}
          onChange={onChangeCustomer}
          onMerge={onMergeCustomer}
        />
      ) : null}
    </>
  ) : null;
});
