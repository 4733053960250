import {Location} from "@co-common-libs/resources";
import {getCustomerLookup} from "@co-frontend-libs/redux";
import {Typography} from "@material-ui/core";
import React from "react";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {AddressLine} from "./info-lines/address-line";
import {PhoneLine} from "./info-lines/phone-line";

interface LocationInfoProps {
  location: Location;
}

export function LocationInfo({location}: LocationInfoProps): JSX.Element {
  const customerLookup = useSelector(getCustomerLookup);
  const {attention, cellphone, name, phone} = location;

  const customer = location.customer ? customerLookup(location.customer) : null;
  const customerName = customer?.name;
  return (
    <div>
      {customerName && (
        <Typography variant="body2">
          <i>{customerName}</i>
        </Typography>
      )}
      {attention && (
        <Typography variant="body2">
          <FormattedMessage defaultMessage="Att: {attention}" values={{attention}} />
        </Typography>
      )}
      {name && <Typography variant="body2">{name}</Typography>}
      {location.address && <AddressLine address={location} />}
      {cellphone && <PhoneLine phone={cellphone} type="cellphone" />}
      {phone && <PhoneLine phone={phone} type="landline" />}
    </div>
  );
}
