import {EmployeeGroupUrl, UserProfileUrl, UserUrl} from "../resource-urls";
import {ResourceInstance} from "./base";

export type StartPage =
  | ""
  | "ABSENCE_CALENDAR"
  | "CALENDAR_PAGE_ONE"
  | "CALENDAR_PAGE_TWO"
  | "CALENDAR"
  | "CHECKOUT"
  | "MAP"
  | "MINIMAL_ORDER_CALENDAR"
  | "ORDER_CALENDAR";

export interface UserProfile extends ResourceInstance<UserProfileUrl> {
  readonly address: string;
  readonly alias: string;
  readonly alwaysVisible: boolean;
  readonly askAboutAvailability: boolean;
  readonly averageHourlyCost: number | null;
  readonly barred: boolean;
  readonly birthDate: string | null;
  readonly c5_recid: number | null;
  readonly cellphone: string;
  readonly cellphoneExtra: string;
  readonly city: string;
  readonly dumpSyncQueue: boolean;
  readonly email: string;
  readonly employeeGroup: EmployeeGroupUrl | null;
  readonly employeeNumber: string;
  readonly employmentDate: string | null;
  readonly externalDataloenIdentifier: string;
  readonly externalEconomicIdentifier: string;
  readonly externalLessorIdentifier: string;
  readonly externalUnicontaIdentifier: string;
  readonly iceContact: string;
  readonly iceContactLandline: string;
  readonly iceContactMobile: string;
  readonly iceContactNote: string;
  readonly iceContactWorkNumber: string;
  readonly iceSecondaryContact: string;
  readonly iceSecondaryContactLandline: string;
  readonly iceSecondaryContactMobile: string;
  readonly iceSecondaryContactNote: string;
  readonly iceSecondaryContactWorkNumber: string;
  readonly machineCalendarOrder: readonly string[] | null;
  readonly name: string;
  readonly normalTransportKilometers: number | null;
  readonly normalTransportMinutes: number | null;
  readonly note: string;
  readonly phone: string;
  readonly policyApproved: string | null;
  readonly postalCode: string;
  readonly receiveTimerStartNotifications: boolean | undefined;
  readonly remoteUrl: string;
  readonly remunerationGroup: string;
  readonly requireGps: boolean;
  readonly showDinnerBookingPopup: boolean;
  readonly showLunchBookingPopup: boolean;
  readonly showNotePopupOnTask: boolean | undefined;
  readonly startPage: StartPage;
  readonly user: UserUrl;
  readonly visibleOnAbsenceCalendar?: boolean;
}

export const emptyUserProfile: Omit<UserProfile, "id" | "url" | "user"> = {
  address: "",
  alias: "",
  alwaysVisible: false,
  askAboutAvailability: false,
  averageHourlyCost: null,
  barred: false,
  birthDate: null,
  c5_recid: null,
  cellphone: "",
  cellphoneExtra: "",
  city: "",
  dumpSyncQueue: false,
  email: "",
  employeeGroup: null,
  employeeNumber: "",
  employmentDate: null,
  externalDataloenIdentifier: "",
  externalEconomicIdentifier: "",
  externalLessorIdentifier: "",
  externalUnicontaIdentifier: "",
  iceContact: "",
  iceContactLandline: "",
  iceContactMobile: "",
  iceContactNote: "",
  iceContactWorkNumber: "",
  iceSecondaryContact: "",
  iceSecondaryContactLandline: "",
  iceSecondaryContactMobile: "",
  iceSecondaryContactNote: "",
  iceSecondaryContactWorkNumber: "",
  machineCalendarOrder: null,
  name: "",
  normalTransportKilometers: null,
  normalTransportMinutes: null,
  note: "",
  phone: "",
  policyApproved: null,
  postalCode: "",
  receiveTimerStartNotifications: undefined,
  remoteUrl: "",
  remunerationGroup: "",
  requireGps: false,
  showDinnerBookingPopup: false,
  showLunchBookingPopup: false,
  showNotePopupOnTask: undefined,
  startPage: "",
};
