import {AnniversaryTypeUrl} from "../resource-urls";
import {ResourceInstance} from "./base";

export interface AnniversaryType extends ResourceInstance<AnniversaryTypeUrl> {
  readonly countFrom: "" | "BIRTH_DATE" | "EMPLOYMENT_DATE";
  readonly emailNotificationRecipients: string;
  readonly icon: "cake" | "flag" | "medal";
  readonly label: string;
  readonly reminderDays: number;
  readonly years: number | null;
}

export const emptyAnniversaryType: Omit<
  AnniversaryType,
  "countFrom" | "icon" | "id" | "label" | "reminderDays" | "url"
> = {
  emailNotificationRecipients: "",
  years: null,
};
