import {ListItemIcon, MenuItem, Typography} from "@material-ui/core";
import CheckIcon from "mdi-react/CheckIcon";
import React from "react";
import {FormattedMessage} from "react-intl";

interface UserDailyValidationMenuItemProps {
  disabled: boolean;
  onClick: () => void;
}

export function UserDailyValidationMenuItem(props: UserDailyValidationMenuItemProps): JSX.Element {
  const {disabled, onClick} = props;
  return (
    <MenuItem key={"user-daily-validation"} disabled={disabled} onClick={onClick}>
      <ListItemIcon>
        <CheckIcon fontSize="small" />
      </ListItemIcon>
      <Typography variant="inherit">
        <FormattedMessage defaultMessage="Godkend dagseddel" />
      </Typography>
    </MenuItem>
  );
}
