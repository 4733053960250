import {IconButton, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {green} from "@material-ui/core/colors";
import useMergedRef from "@react-hook/merged-ref";
import {PureComponent} from "app-utils";
import CloseCircleIcon from "mdi-react/CloseCircleIcon";
import DragHorizontalVariantIcon from "mdi-react/DragHorizontalVariantIcon";
import PencilIcon from "mdi-react/PencilIcon";
import React, {useCallback} from "react";
import {DragSourceMonitor, DropTargetMonitor, useDrag, useDrop} from "react-dnd";

const DRAGHANDLE_COLUMN_STYLE = {
  width: 80,
};

const ICON_COLUMN_STYLE = {
  padding: 0,
  width: 48,
};

interface RelationsTableRowProps<T extends string> {
  columns: readonly any[];
  identifier: T;
  onDrop?: ((source: any, target: any) => void) | undefined;
  onEdit: ((identifier: T) => void) | undefined;
  onRemove: (identifier: T) => void;
  readonly?: boolean | undefined;
}

const genericMemo: <T>(component: T) => T = React.memo;

const RelationsTableRow = genericMemo(function RelationsTableRow<T extends string>(
  props: RelationsTableRowProps<T>,
): JSX.Element {
  const {columns, identifier, onDrop, onEdit, onRemove, readonly} = props;

  const handleRemoveClick = useCallback(() => {
    onRemove(identifier);
  }, [identifier, onRemove]);

  const handleEditClick = useCallback(() => {
    if (onEdit) {
      onEdit(identifier);
    }
  }, [identifier, onEdit]);

  const [dropCollectedProps, drop] = useDrop({
    accept: "priceItem",
    canDrop: (_item: any, _monitor: DropTargetMonitor) => {
      return !!onDrop;
    },
    collect: (monitor: DropTargetMonitor) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
    }),
    drop: (item: any, _monitor: DropTargetMonitor) => {
      if (onDrop) {
        onDrop(item.identifier, identifier);
      }
    },
  });
  const {canDrop, isOver} = dropCollectedProps;

  const [dragCollectedProps, drag, preview] = useDrag({
    canDrag: (_monitor: DragSourceMonitor) => {
      return !!onDrop;
    },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: {
      identifier,
    },
    type: "priceItem",
  });
  const {isDragging} = dragCollectedProps;

  const style: React.CSSProperties = {backgroundColor: "white"};
  if (isDragging) {
    style.opacity = 0.2;
  }
  if (isOver && canDrop) {
    style.backgroundColor = green[100];
  }
  const dragHandleColumn = onDrop ? (
    <TableCell ref={drag} style={DRAGHANDLE_COLUMN_STYLE}>
      <DragHorizontalVariantIcon />
    </TableCell>
  ) : undefined;
  const removeButton = !readonly ? (
    <IconButton onClick={handleRemoveClick}>
      <CloseCircleIcon />
    </IconButton>
  ) : null;

  const editButton = onEdit ? (
    <IconButton onClick={handleEditClick}>
      <PencilIcon />
    </IconButton>
  ) : null;

  const multiRef = useMergedRef<HTMLTableRowElement>(drop, preview);

  return (
    <TableRow ref={multiRef} style={style}>
      {dragHandleColumn}
      {columns.map((column, index) => (
        <TableCell key={index} style={{whiteSpace: "pre-line"}}>
          {column}
        </TableCell>
      ))}
      {editButton ? <TableCell style={ICON_COLUMN_STYLE}>{editButton}</TableCell> : null}
      <TableCell style={ICON_COLUMN_STYLE}>{removeButton}</TableCell>
    </TableRow>
  );
});

interface RelationsTableProps<T extends string> {
  data: readonly {
    readonly columns: readonly any[];
    readonly identifier: T;
    readonly readonly?: boolean;
  }[];
  header: readonly any[];
  onDragDrop?: ((source: any, target: any) => void) | undefined;
  onEdit?: (identifer: T) => void;
  onRemove: (identifer: T) => void;
}

export class RelationsTable<T extends string> extends PureComponent<RelationsTableProps<T>> {
  render(): JSX.Element {
    let dragHandleColumnHeader;
    if (this.props.onDragDrop) {
      dragHandleColumnHeader = <TableCell style={DRAGHANDLE_COLUMN_STYLE} />;
    }

    return (
      <Table>
        <TableHead>
          <TableRow>
            {dragHandleColumnHeader}
            {this.props.header.map((title, index) => (
              <TableCell key={index}>{title}</TableCell>
            ))}
            {this.props.onEdit ? <TableCell style={ICON_COLUMN_STYLE} /> : null}
            <TableCell style={ICON_COLUMN_STYLE} />
          </TableRow>
        </TableHead>
        <TableBody>
          {this.props.data.map((relation) => {
            return (
              <RelationsTableRow<T>
                key={relation.identifier}
                columns={relation.columns}
                identifier={relation.identifier}
                readonly={relation.readonly}
                onDrop={this.props.onDragDrop}
                onEdit={this.props.onEdit}
                onRemove={this.props.onRemove}
              />
            );
          })}
        </TableBody>
      </Table>
    );
  }
}
