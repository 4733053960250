import {
  Machine,
  MachineUrl,
  MachineUse,
  PriceGroup,
  PriceGroupUrl,
} from "@co-common-libs/resources";

export interface InlinedMachineUse {
  machine: Machine;
  priceGroup: PriceGroup | null;
  transporter: boolean;
}

export const inlineMachineUse = (
  mappings: {
    machineLookup: (url: MachineUrl) => Machine | undefined;
    priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
  },
  machineUse: MachineUse,
): InlinedMachineUse | null => {
  const {machineLookup, priceGroupLookup} = mappings;
  const machine = machineLookup(machineUse.machine);
  if (!machine) {
    return null;
  }
  const priceGroup = (machineUse.priceGroup && priceGroupLookup(machineUse.priceGroup)) || null;
  return {
    machine,
    priceGroup,
    transporter: machineUse.transporter,
  };
};
