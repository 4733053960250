import {IconButton} from "@material-ui/core";
import React, {useCallback} from "react";

interface InstanceClickIconButtonProps<T> {
  children: JSX.Element;
  instance: T;
  onClick: (instance: T) => void;
}

function InstanceClickIconButton<T>({
  children,
  instance,
  onClick,
}: InstanceClickIconButtonProps<T>): JSX.Element {
  const handleClick = useCallback((): void => {
    onClick(instance);
  }, [instance, onClick]);

  return <IconButton onClick={handleClick}>{children}</IconButton>;
}

const MemoizedInstanceClickIconButton = React.memo(
  InstanceClickIconButton,
) as typeof InstanceClickIconButton;

export {MemoizedInstanceClickIconButton as InstanceClickIconButton};
