import {OfflineAwareAppBar} from "@co-frontend-libs/components";
import {actions} from "@co-frontend-libs/redux";
import {AppBarProps, createStyles, makeStyles} from "@material-ui/core";
import bowser from "bowser";
import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {useIsOnline} from "react-use-is-online";
import {DRAWER_WIDTH} from "../drawer/constants";

const useStyles = makeStyles((theme) =>
  createStyles({
    root:
      bowser.tablet || bowser.mobile
        ? {}
        : {
            [theme.breakpoints.up("md")]: {
              marginLeft: DRAWER_WIDTH,
              width: `calc(100% - ${DRAWER_WIDTH}px)`,
            },
          },
  }),
);

export const DrawerMarginAppBar = React.forwardRef(function DrawerMarginAppBar(
  props: AppBarProps,
  ref,
): JSX.Element {
  const dispatch = useDispatch();

  const {isOnline} = useIsOnline();

  useEffect(() => {
    if (isOnline) {
      dispatch(actions.startOnlineSaves());
    }
  }, [dispatch, isOnline]);

  const classes = useStyles();
  const {children, ...rest} = props;
  return (
    <OfflineAwareAppBar ref={ref} {...rest} className={classes.root}>
      {children}
    </OfflineAwareAppBar>
  );
});
