import {CountBonusFormat, RemunerationGroup} from "@co-common-libs/config";
import {AccomodationAllowance, PriceItem, Project, Task} from "@co-common-libs/resources";
import {
  DayTypeHoliday,
  dateFromString,
  dateToString,
  makeMapFromArray,
  mapSetAdd,
  mapSetHas,
} from "@co-common-libs/utils";
import {attachWorkPeriodIntervalBonuses, checkCriteria} from "./interval-match";
import {BonusSpecification, CountBonusSpecification, WorkDay, WorkDayWithBonus} from "./types";

function workDayNoBonus(_workDay: WorkDay): string[] {
  return [];
}

export function getWorkDayBonusFunction(
  checkHoliday: (dateString: string) => DayTypeHoliday,
  workDayBonus: readonly BonusSpecification[],
): (workDay: WorkDay) => string[] {
  if (!workDayBonus.length) {
    return workDayNoBonus;
  }
  const fn = (workDay: WorkDay): string[] => {
    const result: string[] = [];
    workDay.workPeriods.forEach((workPeriod) => {
      workPeriod.work.forEach((interval) => {
        for (const bonus of workDayBonus) {
          const {label} = bonus;
          if (result.includes(label)) {
            continue;
          }
          if (checkCriteria(checkHoliday, bonus, interval)) {
            result.push(label);
          }
        }
      });
    });
    return result;
  };
  return fn;
}

function calendarDayNoBonus(
  _usedCalendarDayBonuses: Map<string, Set<string>>,
  _workDay: WorkDay,
): Map<string, Set<string>> {
  return new Map();
}

export function getCalendarDayBonusFunction(
  checkHoliday: (dateString: string) => DayTypeHoliday,
  calendarDayBonus: readonly BonusSpecification[],
): (
  usedCalendarDayBonuses: Map<string, Set<string>>,
  workDay: WorkDay,
) => Map<string, Set<string>> {
  if (!calendarDayBonus.length) {
    return calendarDayNoBonus;
  }
  const fn = (
    usedCalendarDayBonuses: Map<string, Set<string>>,
    workDay: WorkDay,
  ): Map<string, Set<string>> => {
    const result = new Map<string, Set<string>>();
    workDay.workPeriods.forEach((workPeriod) => {
      workPeriod.work.forEach((interval) => {
        for (const bonus of calendarDayBonus) {
          if (checkCriteria(checkHoliday, bonus, interval)) {
            const {label} = bonus;
            const intervalFrom = new Date(interval.fromTimestamp);
            const intervalDate = dateToString(intervalFrom);
            if (!mapSetHas(usedCalendarDayBonuses, label, intervalDate)) {
              mapSetAdd(usedCalendarDayBonuses, label, intervalDate);
              mapSetAdd(result, label, intervalDate);
            }
          }
        }
      });
    });
    return result;
  };
  return fn;
}

function taskNoBonus(
  _usedTaskBonuses: Map<string, Set<string>>,
  _workDay: WorkDay,
): Map<string, Set<string>> {
  return new Map();
}

export function getTaskBonusFunction(
  checkHoliday: (dateString: string) => DayTypeHoliday,
  taskBonus: readonly BonusSpecification[],
): (usedTaskBonuses: Map<string, Set<string>>, workDay: WorkDay) => Map<string, Set<string>> {
  if (!taskBonus.length) {
    return taskNoBonus;
  }
  const fn = (
    usedTaskBonuses: Map<string, Set<string>>,
    workDay: WorkDay,
  ): Map<string, Set<string>> => {
    const result = new Map<string, Set<string>>();

    workDay.workPeriods.forEach((workPeriod) => {
      workPeriod.work.forEach((interval) => {
        for (const bonus of taskBonus) {
          if (checkCriteria(checkHoliday, bonus, interval)) {
            const {label} = bonus;
            if (interval.taskData.length === 1) {
              const intervalTaskData = interval.taskData[0];
              const {taskURL} = intervalTaskData;
              const taskId = urlToId(taskURL);
              if (!mapSetHas(usedTaskBonuses, label, taskId)) {
                mapSetAdd(usedTaskBonuses, label, taskId);
                mapSetAdd(result, label, taskId);
              }
            } else {
              for (const intervalTaskData of interval.taskData) {
                if (
                  checkCriteria(checkHoliday, bonus, {
                    ...interval,
                    taskData: [intervalTaskData],
                  })
                ) {
                  const {taskURL} = intervalTaskData;
                  const taskId = urlToId(taskURL);
                  if (!mapSetHas(usedTaskBonuses, label, taskId)) {
                    mapSetAdd(usedTaskBonuses, label, taskId);
                    mapSetAdd(result, label, taskId);
                  }
                }
              }
            }
          }
        }
      });
    });
    return result;
  };
  return fn;
}

function projectDistanceNoBonus(_workDay: WorkDay): number {
  return 0;
}

function getProjectDistanceBonusFunction(
  projectMap: ReadonlyMap<string, Project>,
  groupAccomodationAllowanceList?: readonly AccomodationAllowance[],
): (workDay: WorkDay) => number {
  if (!projectMap.size) {
    return projectDistanceNoBonus;
  }
  const accomodationAllowanceDays = groupAccomodationAllowanceList
    ? new Set(groupAccomodationAllowanceList.map((a) => a.date))
    : new Set<string>();
  const fn = (workDay: WorkDay): number => {
    const projectURLSet = new Set<string>();
    const {workPeriods} = workDay;
    for (let i = 0; i < workPeriods.length; i += 1) {
      const workPeriod = workPeriods[i];
      const {work} = workPeriod;
      for (let j = 0; j < work.length; j += 1) {
        const workInstance = work[j];
        const {taskData} = workInstance;
        for (let k = 0; k < taskData.length; k += 1) {
          const taskDataInstance = taskData[k];
          const {projectURL} = taskDataInstance;
          if (projectURL) {
            projectURLSet.add(projectURL);
          }
        }
      }
    }
    if (!projectURLSet.size) {
      return 0;
    }
    let result = 0;
    projectURLSet.forEach((projectURL) => {
      const project = projectMap.get(projectURL);
      console.assert(project, `cannot obtain distance for unknown project ${projectURL}`);
      if (project && project.distanceInKm) {
        const {distanceInKm} = project;
        const previousDateDate = dateFromString(workDay.date) as Date;
        previousDateDate.setDate(previousDateDate.getDate() - 1);
        const previousDate = dateToString(previousDateDate);
        if (!accomodationAllowanceDays.has(previousDate)) {
          result += distanceInKm;
        }
        if (!accomodationAllowanceDays.has(workDay.date)) {
          result += distanceInKm;
        }
      }
    });
    return result;
  };
  return fn;
}

function projectTravelTimeNoBonus(_workDay: WorkDay): number {
  return 0;
}

function getProjectTravelTimeBonusFunction(
  projectMap: ReadonlyMap<string, Project>,
  groupAccomodationAllowanceList?: readonly AccomodationAllowance[],
): (workDay: WorkDay) => number {
  if (!projectMap.size) {
    return projectTravelTimeNoBonus;
  }
  const accomodationAllowanceDays = groupAccomodationAllowanceList
    ? new Set(groupAccomodationAllowanceList.map((a) => a.date))
    : new Set<string>();
  const fn = (workDay: WorkDay): number => {
    const projectURLSet = new Set<string>();
    const {workPeriods} = workDay;
    for (let i = 0; i < workPeriods.length; i += 1) {
      const workPeriod = workPeriods[i];
      const {work} = workPeriod;
      for (let j = 0; j < work.length; j += 1) {
        const workInstance = work[j];
        const {taskData} = workInstance;
        for (let k = 0; k < taskData.length; k += 1) {
          const taskDataInstance = taskData[k];
          const {projectURL} = taskDataInstance;
          if (projectURL) {
            projectURLSet.add(projectURL);
          }
        }
      }
    }
    if (!projectURLSet.size) {
      return 0;
    }
    let result = 0;
    projectURLSet.forEach((projectURL) => {
      const project = projectMap.get(projectURL);
      if (!project) {
        throw new Error(`cannot obtain travel time for unknown project ${projectURL}`);
      }
      const {travelTimeInMinutes} = project;
      if (travelTimeInMinutes) {
        const previousDateDate = dateFromString(workDay.date) as Date;
        previousDateDate.setDate(previousDateDate.getDate() - 1);
        const previousDate = dateToString(previousDateDate);
        if (!accomodationAllowanceDays.has(previousDate)) {
          result += travelTimeInMinutes;
        }
        if (!accomodationAllowanceDays.has(workDay.date)) {
          result += travelTimeInMinutes;
        }
      }
    });
    return result;
  };
  return fn;
}

function urlToId(url: string): string {
  const parts = url.split("/");

  return parts[parts.length - 2];
}

function getTaskPriceItemCount(
  priceItemUUID: string,
  task: Task,
  priceItemMap: ReadonlyMap<string, PriceItem>,
): number | null {
  const priceitemuseSet = Object.values(task.priceItemUses || {});
  if (!priceitemuseSet.length) {
    return null;
  }
  for (let i = 0; i < priceitemuseSet.length; i += 1) {
    const priceItemUse = priceitemuseSet[i];
    const id = urlToId(priceItemUse.priceItem);
    if (id === priceItemUUID) {
      if (priceItemUse.correctedCount != null) {
        return priceItemUse.correctedCount;
      }
      const priceItem = priceItemMap.get(priceItemUse.priceItem);
      if (
        priceItem &&
        priceItem.minimumCount != null &&
        priceItem.minimumCount > (priceItemUse.count || 0)
      ) {
        return priceItem.minimumCount;
      }
      return priceItemUse.count;
    }
  }
  return null;
}

function makeCountCheck(
  countCriteria: CountBonusSpecification,
  priceItemMap: ReadonlyMap<string, PriceItem>,
): (task: Task) => number | null {
  const {multiplier, priceItemUUID} = countCriteria;
  if (!priceItemUUID) {
    throw new Error("countBonus currently only only defined for price items");
  }
  const fn = (task: Task): number | null => {
    const n = getTaskPriceItemCount(priceItemUUID, task, priceItemMap);
    if (n) {
      return n * multiplier;
    }
    return null;
  };
  return fn;
}

function countNoBonus(_workDay: WorkDay): ReadonlyMap<string, number> {
  return new Map();
}

export function getCountBonusFunction(
  countBonus: readonly CountBonusSpecification[],
  taskList: readonly Task[],
  priceItemMap: ReadonlyMap<string, PriceItem>,
  normalTransportKilometersCountBonusLabel?: string,
  normalTransportKilometers?: number,
): (workDay: WorkDay) => ReadonlyMap<string, number> {
  if (!countBonus.length) {
    return countNoBonus;
  }
  const taskMap = makeMapFromArray(taskList, (task) => task.url);
  const observedTaskURLs = new Set<string>();
  const checks = countBonus.map((countCriteria) => makeCountCheck(countCriteria, priceItemMap));
  const labels = countBonus.map((countCriteria) => countCriteria.label);
  const fn = (workDay: WorkDay): Map<string, number> => {
    const result = new Map<string, number>();
    workDay.workPeriods.forEach((workPeriod) => {
      workPeriod.work.forEach((interval) => {
        const taskURLs = interval.taskData.map((taskData) => taskData.taskURL);
        for (let i = 0; i < taskURLs.length; i += 1) {
          const taskURL = taskURLs[i];
          if (!observedTaskURLs.has(taskURL)) {
            observedTaskURLs.add(taskURL);
            const task = taskMap.get(taskURL) as Task;
            for (let j = 0; j < checks.length; j += 1) {
              const label = labels[j];
              const check = checks[j];
              const value = check(task);
              if (value) {
                const oldValue = result.get(label) || 0;
                const newValue = oldValue + value;
                result.set(label, newValue);
              }
            }
          }
        }
      });
    });
    if (
      normalTransportKilometersCountBonusLabel !== undefined &&
      normalTransportKilometers !== undefined &&
      result.has(normalTransportKilometersCountBonusLabel)
    ) {
      const value = result.get(normalTransportKilometersCountBonusLabel) as number;
      result.set(
        normalTransportKilometersCountBonusLabel,
        Math.max(value - normalTransportKilometers, 0),
      );
    }
    return result;
  };
  return fn;
}

export function getCalledInDaysFn(taskList: readonly Task[]): (workDay: WorkDay) => number {
  const taskMap = makeMapFromArray(taskList, (task) => task.url);
  const observedTaskURLs = new Set<string>();

  const fn = (workDay: WorkDay): number => {
    let result = 0;
    workDay.workPeriods.forEach((workPeriod) => {
      workPeriod.work.forEach((interval) => {
        const taskURLs = interval.taskData.map((taskData) => taskData.taskURL);
        for (let i = 0; i < taskURLs.length; i += 1) {
          const taskURL = taskURLs[i];
          if (!observedTaskURLs.has(taskURL)) {
            observedTaskURLs.add(taskURL);
            const task = taskMap.get(taskURL) as Task;
            if (task.calledIn) {
              result += 1;
            }
          }
        }
      });
    });
    return result;
  };
  return fn;
}

export function attachBonus(options: {
  calendarDayBonus: readonly BonusSpecification[];
  checkHoliday: (dateString: string) => DayTypeHoliday;
  countBonus: readonly CountBonusSpecification[];
  groupAccomodationAllowanceList: readonly AccomodationAllowance[] | undefined;
  intervalBonus: readonly BonusSpecification[];
  normalTransportKilometers: number | undefined;
  normalTransportKilometersCountBonusLabel: string | undefined;
  priceItemMap: ReadonlyMap<string, PriceItem>;
  projectMap: ReadonlyMap<string, Project>;
  taskBonus: readonly BonusSpecification[];
  taskList: readonly Task[];
  workDayBonus: readonly BonusSpecification[];
  workDays: readonly WorkDay[];
}): WorkDayWithBonus[] {
  const {
    calendarDayBonus,
    checkHoliday,
    countBonus,
    groupAccomodationAllowanceList,
    intervalBonus,
    normalTransportKilometers,
    normalTransportKilometersCountBonusLabel,
    priceItemMap,
    projectMap,
    taskBonus,
    taskList,
    workDayBonus,
    workDays,
  } = options;
  const workDayBonusFn = getWorkDayBonusFunction(checkHoliday, workDayBonus);
  const calendarDayBonusFn = getCalendarDayBonusFunction(checkHoliday, calendarDayBonus);
  const taskBonusFn = getTaskBonusFunction(checkHoliday, taskBonus);
  const projectDistanceBonusFn = getProjectDistanceBonusFunction(
    projectMap,
    groupAccomodationAllowanceList,
  );
  const projectTravelTimeBonusFn = getProjectTravelTimeBonusFunction(
    projectMap,
    groupAccomodationAllowanceList,
  );
  const countBonusFn = getCountBonusFunction(
    countBonus,
    taskList,
    priceItemMap,
    normalTransportKilometersCountBonusLabel,
    normalTransportKilometers,
  );
  const calledInFn = getCalledInDaysFn(taskList);

  const afterThresholdCalendarDayRemainingMinutes = new Map<
    BonusSpecification,
    Map<string, number>
  >();
  const afterThresholdWorkDayRemainingMinutes = new Map<BonusSpecification, Map<WorkDay, number>>();

  const usedCalendarDayBonuses = new Map<string, Set<string>>();
  const usedTaskBonuses = new Map<string, Set<string>>();

  const attachWorkDayBonus = (workDay: WorkDay): WorkDayWithBonus => {
    const resultingWorkDayBonus = workDayBonusFn(workDay);
    const resultingCalendarDayBonus = calendarDayBonusFn(usedCalendarDayBonuses, workDay);
    const resultingTaskBonus = taskBonusFn(usedTaskBonuses, workDay);
    const workPeriods = attachWorkPeriodIntervalBonuses(
      checkHoliday,
      intervalBonus,
      workDay,
      afterThresholdCalendarDayRemainingMinutes,
      afterThresholdWorkDayRemainingMinutes,
    );
    const dayCountBonus = countBonusFn(workDay);
    const calledIn = calledInFn(workDay);
    const projectDistance = projectDistanceBonusFn(workDay);
    const projectTravelTime = projectTravelTimeBonusFn(workDay);
    return {
      ...workDay,
      bonus: resultingWorkDayBonus,
      calendarDayBonus: resultingCalendarDayBonus,
      calledIn,
      countBonus: dayCountBonus,
      projectDistance,
      projectTravelTime,
      taskBonus: resultingTaskBonus,
      workPeriods,
    };
  };

  return workDays.map(attachWorkDayBonus);
}

export function getCountBonusLabelFormatMap(
  remunerationGroup: Pick<RemunerationGroup, "countBonus"> | undefined,
): ReadonlyMap<string, CountBonusFormat> {
  if (!remunerationGroup) {
    return new Map();
  } else {
    return new Map(
      remunerationGroup.countBonus?.map(({format, label}) => [label, format ?? "integer"]),
    );
  }
}
