import {Role, User, UserUrl} from "@co-common-libs/resources";
import {UserDialog, UserDialogProps, UserDialogSelectionProps} from "@co-frontend-libs/components";
import {AppState, getUserArray, getUserRoleLookup} from "@co-frontend-libs/redux";
import {connect} from "react-redux";
import {createSelector, createStructuredSelector} from "reselect";
import {MarkOptional} from "ts-essentials";

type UserDialogStateProps = Pick<UserDialogProps, "userArray">;
type UserDialogOwnProps = MarkOptional<UserDialogSelectionProps, "title">;

const filterUsersToMachineOperators = (
  users: readonly User[],
  userRoleLookup: (url: UserUrl) => Role | undefined,
): readonly User[] => {
  return users.filter((user) => {
    const role = userRoleLookup(user.url);
    return !!role && role.machineOperator;
  });
};

const getMachineOperatorUsers: (state: AppState) => readonly User[] = createSelector(
  getUserArray,
  getUserRoleLookup,
  filterUsersToMachineOperators,
);

export const ConnectedMachineOperatorDialog: React.ComponentType<UserDialogOwnProps> = connect<
  UserDialogStateProps,
  object,
  UserDialogOwnProps,
  AppState
>(
  createStructuredSelector<AppState, UserDialogStateProps>({
    userArray: getMachineOperatorUsers,
  }),
  {},
)(UserDialog);
