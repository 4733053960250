import {UserProfile} from "@co-common-libs/resources";
import {ResponsiveDialog, TimeField} from "@co-frontend-libs/components";
import {DialogContent} from "@material-ui/core";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import React from "react";
// Allowed for existing code...
// eslint-disable-next-line deprecate/import
import {Cell, Grid} from "react-flexr";
import {IntlContext, defineMessages} from "react-intl";

const messages = defineMessages({
  fromHour: {
    defaultMessage: "Fra klokken",
    id: "task-calendar.label.from-hour",
  },
  setWorkHours: {
    defaultMessage: "Standard arbejdstid for {initials}",
    id: "task-calendar.dialog-title.set-work-hours",
  },
  toHour: {
    defaultMessage: "Til klokken",
    id: "task-calendar.label.to-hour",
  },
});

// HH:MM | HH:MM:SS | HH:MM:SS.ssssss

interface SetCalendarWorkHoursDialogProps {
  fromHour: string | undefined;
  onCancel: () => void;
  onOk: (fromHour: string | null, toHour: string | null) => void;
  open: boolean;
  toHour: string | undefined;
  userProfile: UserProfile | undefined;
}

interface SetCalendarWorkHoursDialogState {
  fromHour: string | null;
  toHour: string | null;
}

class SetCalendarWorkHoursDialog extends PureComponent<
  SetCalendarWorkHoursDialogProps,
  SetCalendarWorkHoursDialogState
> {
  state: SetCalendarWorkHoursDialogState = {
    fromHour: this.props.fromHour ? this.props.fromHour.substr(0, 5) : null,

    toHour: this.props.toHour ? this.props.toHour.substr(0, 5) : null,
  };
  UNSAFE_componentWillReceiveProps(nextProps: SetCalendarWorkHoursDialogProps): void {
    if (nextProps.open !== this.props.open) {
      this.setState({
        fromHour: nextProps.fromHour ? nextProps.fromHour.substr(0, 5) : null,

        toHour: nextProps.toHour ? nextProps.toHour.substr(0, 5) : null,
      });
    }
  }

  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  @bind
  handleOk(): void {
    this.props.onOk(this.state.fromHour, this.state.toHour);
  }
  @bind
  handleFromHourChange(value: string | null): void {
    this.setState({fromHour: value});
  }
  @bind
  handleToHourChange(value: string | null): void {
    this.setState({toHour: value});
  }
  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {userProfile} = this.props;
    const initials = userProfile ? userProfile.alias : "";
    return (
      <ResponsiveDialog
        open={this.props.open}
        title={formatMessage(messages.setWorkHours, {initials})}
        onCancel={this.props.onCancel}
        onOk={this.handleOk}
      >
        <DialogContent>
          <Grid>
            <Cell palm="12/12">
              <TimeField
                fullWidth
                label={formatMessage(messages.fromHour)}
                margin="dense"
                value={this.state.fromHour || undefined}
                onChange={this.handleFromHourChange}
              />
            </Cell>
            <Cell palm="12/12">
              <TimeField
                fullWidth
                label={formatMessage(messages.toHour)}
                margin="dense"
                value={this.state.toHour || undefined}
                onChange={this.handleToHourChange}
              />
            </Cell>
          </Grid>
        </DialogContent>
      </ResponsiveDialog>
    );
  }
}

export default SetCalendarWorkHoursDialog;
