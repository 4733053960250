import {InformationUrl, UserUrl} from "../resource-urls";
import {ResourceInstance} from "./base";

export interface Information extends ResourceInstance<InformationUrl> {
  readonly createdBy: UserUrl;
  readonly pinned: boolean;
  readonly published: boolean;
  readonly publishedDatetime: string | null;
  readonly seenBy: readonly UserUrl[];
  readonly text: string;
  readonly title: string;
  readonly visibleTo: readonly UserUrl[];
}

export const emptyInformation: Omit<Information, "createdBy" | "id" | "url"> = {
  pinned: false,
  published: false,
  publishedDatetime: null,
  seenBy: [],
  text: "",
  title: "",
  visibleTo: [],
};
