import {Customer} from "@co-common-libs/resources";
import {CustomerFormMember} from "./types";

export function computeDisabledInputFields(
  inputFields: ReadonlySet<CustomerFormMember>,
  editCustomer: Customer | null,
): ReadonlySet<CustomerFormMember> {
  // all fields disabled if customer is barred
  if (editCustomer?.barred) {
    return new Set(inputFields);
  }
  if (inputFields.has(CustomerFormMember.CUSTOMER_NUMBER) && !!editCustomer?.remoteUrl) {
    return new Set([CustomerFormMember.CUSTOMER_NUMBER]);
  }

  return new Set();
}
