import {Config} from "@co-common-libs/config";
import {CustomerFormMember} from "./types";

export function computeVisibleInputFields(
  userIsManager: boolean,
  offlineOnlyInputs: boolean,
  customerSettings: Pick<
    Config,
    "canEditCustomerVATNumber" | "economicAutogeneratedCustomerNumbers" | "economicSync"
  >,
): ReadonlySet<CustomerFormMember> {
  const inputs: Set<CustomerFormMember> = new Set([
    CustomerFormMember.ADDRESS,
    CustomerFormMember.BILLING_EMAIL,
    CustomerFormMember.CITY,
    CustomerFormMember.NAME,
    CustomerFormMember.PHONE,
    CustomerFormMember.POSTAL_CODE,
  ]);
  if (!offlineOnlyInputs && userIsManager) {
    inputs.add(CustomerFormMember.ACTIVE);
  }
  if (userIsManager && customerSettings.canEditCustomerVATNumber) {
    inputs.add(CustomerFormMember.VAT_NUMBER);
  }
  if (
    !customerSettings.economicSync ||
    (userIsManager && !customerSettings.economicAutogeneratedCustomerNumbers)
  ) {
    inputs.add(CustomerFormMember.CUSTOMER_NUMBER);
  }
  return inputs;
}
