import {Config} from "@co-common-libs/config";
import {
  AccomodationAllowance,
  DaysAbsence,
  DinnerBooking,
  HoursAbsence,
  LunchBooking,
  MachineUrl,
  Order,
  PriceGroupUrl,
  PriceItem,
  Project,
  PunchInOut,
  Task,
  Timer,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {
  DAY_MINUTES,
  MINUTE_MILLISECONDS,
  dateFromString,
  dateToString,
  getHoliday,
  isWeekend,
  mapMap,
  midnightFromDateString,
  notNull,
} from "@co-common-libs/utils";
import * as _ from "lodash";
import {
  getAbsenceHolidayCheckFunction,
  getDayToDaysAbsenceMapping,
  getDayToHoursAbsenceMapping,
} from "./absence";
import {attachAccomodationAllowance} from "./accomodation-allowance";
import {attachBonus} from "./bonus";
import {applyDayEndRounding} from "./day-end-rounding";
import {applyDayStartRounding} from "./day-start-rounding";
import {
  computeIntervalDurationMilliseconds,
  computeIntervalRoundedDurationMinutes,
  roundMillisecondsToMinutes,
} from "./duration";
import {getDateHoursRatesFunction, setHoursRates} from "./hours-rates";
import {processIgnoreTimers} from "./ignore-timers";
import {buildCommonOptions, buildGroupOptions} from "./input-settings";
import {makeOtherGroupsIntervalsUnpaid} from "./other-group-intervals-unpaid";
import {processPaidTransportSpecialCase} from "./paid-transport";
import {
  fourWeekPoolPeriodEndFunction,
  getFourWeekPoolPeriodStartFunction,
  getMonthPoolPeriodStartFunction,
  getPoolDateAbsenceCompensatoryNormalHoursMinutesFunction,
  getPoolPeriodConstantsThresholdsFunction,
  getPoolPeriodOvertimeThresholdsFunction,
  getTwoWeekPoolPeriodStartFunction,
  getWeekPoolPeriodStartFunction,
  monthPoolPeriodEndFunction,
  setPoolsOvertime,
  twoWeekPoolPeriodEndFunction,
  weekPoolPeriodEndFunction,
} from "./pools";
import {potentialNormalHoursMinutes} from "./potential-normal-hours-minutes";
import {paidFromPunchedInOut} from "./punched-in-out";
import {processRateOverrides} from "./rate-override";
import {attachSpecialStartRate} from "./special-start-rate";
import {getGroupFn} from "./switch-groups";
import {normalisedTaskListIntervals} from "./task-intervals";
import {
  getDateOvertimeThresholdsFunction,
  getOvertimeThresholdsWithoutWeekendsFunction,
  setThresholdsOvertimeLevel,
} from "./thresholds";
import {
  BaseWorkDay,
  CommonRemunerationSettings,
  HolidayCheckFunction,
  HoursRatesFunction,
  MAX_OVERTIME_RATE,
  OvertimeThresholdsFunction,
  PoolPeriodEndFunction,
  PoolPeriodStartFunction,
  PoolPeriodThresholdsFunction,
  PoolSpecification,
  Rate,
  RemunerationGroup,
  SimpleInterval,
  SimplifiedPoolPeriodWithMeta,
  SimplifiedPoolPeriodWithMetaAndCompensatory,
  SimplifiedWorkDay,
  SimplifiedWorkDayWithAccomodation,
  SimplifiedWorkDayWithMeta,
  TaskInterval,
  TaskIntervalWithUnregistered,
  TaskIntervalWithUnregisteredAndPaid,
  WorkDay,
  WorkDayWithBonus,
  WorkPeriod,
} from "./types";
import {injectUnregistered} from "./unregistered";
import {toWorkDaysPerDate, toWorkDaysSplitThreshold} from "./work-days";
import {groupWorkPeriods, toWorkPeriods} from "./work-periods";

export {getAbsenceHolidayCheckFunction} from "./absence";
export {applyDayEndRounding} from "./day-end-rounding";
export {applyDayStartRounding} from "./day-start-rounding";
export {computeDurationMilliseconds, computeIntervalDurationMilliseconds} from "./duration";
export {getDateHoursRatesFunction} from "./hours-rates";
export {buildCommonOptions, buildGroupOptions} from "./input-settings";
export {
  fourWeekPoolPeriodEndFunction,
  getFourWeekPoolPeriodStartFunction,
  getTwoWeekPoolPeriodStartFunction,
  twoWeekPoolPeriodEndFunction,
} from "./pools";
export {minuteTruncatedTimestamp, normalisedTaskListIntervals} from "./task-intervals";
export {Rate} from "./types";
export type {
  BonusSpecification,
  DayHoursRatesSpecification,
  IntervalTaskData,
  RemunerationGroup,
  RemunerationGroupInput,
  SimplifiedWorkDayWithMeta,
  SwitchGroupSpecification,
  TaskIntervalWithUnregisteredAndPaid,
  UserData,
  SimplifiedData,
  WeekDayHoursRatesSpecification,
  WeekThresholdsSpecification,
  SimplifiedPoolPeriodWithMetaAndCompensatory,
  SimpleInterval,
} from "./types";
export {computePoolCompensatoryResults} from "./pool-compensatory-results";
export {findVisibleRateBonusLabelSources} from "./visible-rate-bonus-labels";

export function processPoolParameters(
  pools: PoolSpecification,
  checkHoliday: HolidayCheckFunction,
  getDateOvertimeThresholds: OvertimeThresholdsFunction,
  remunerationGroup: RemunerationGroup,
): {
  getPoolPeriodEnd: PoolPeriodEndFunction;
  getPoolPeriodStart: PoolPeriodStartFunction;
  getPoolPeriodThresholds: PoolPeriodThresholdsFunction;
} {
  if (pools.type === "week") {
    const {weekStart} = pools;
    const thresholds =
      pools.overtimeThresholds ||
      (pools.overtimeThreshold != null ? [pools.overtimeThreshold] : []);
    return {
      getPoolPeriodEnd: weekPoolPeriodEndFunction,
      getPoolPeriodStart: getWeekPoolPeriodStartFunction(weekStart),
      getPoolPeriodThresholds: getPoolPeriodConstantsThresholdsFunction(thresholds),
    };
  } else if (pools.type === "month") {
    const {periodStart} = pools;
    const thresholds =
      pools.overtimeThresholds ||
      (pools.overtimeThreshold != null ? [pools.overtimeThreshold] : []);
    return {
      getPoolPeriodEnd: monthPoolPeriodEndFunction,
      getPoolPeriodStart: getMonthPoolPeriodStartFunction(periodStart),
      getPoolPeriodThresholds: getPoolPeriodConstantsThresholdsFunction(thresholds),
    };
  } else if (pools.type === "normalisedWeek") {
    const {weekStart} = pools;
    let poolDateOvertimeThresholdsFunction: OvertimeThresholdsFunction;
    if (pools.dayThresholds) {
      poolDateOvertimeThresholdsFunction = getDateOvertimeThresholdsFunction(
        checkHoliday,
        remunerationGroup,
        pools.dayThresholds,
      );
    } else {
      const overtimeThresholdsWithoutWeekendsFunction =
        getOvertimeThresholdsWithoutWeekendsFunction(getDateOvertimeThresholds);
      // for backwards compatibility, only consider the NORMAL -> OVERTIME_1
      // threshold when inferring pool thresholds day thresholds
      poolDateOvertimeThresholdsFunction = (date: Date) => {
        const baseResult = overtimeThresholdsWithoutWeekendsFunction(date);
        const normalOvertimeThreshold = baseResult[0];
        if (normalOvertimeThreshold != null) {
          return [normalOvertimeThreshold];
        } else {
          return [];
        }
      };
    }
    return {
      getPoolPeriodEnd: weekPoolPeriodEndFunction,
      getPoolPeriodStart: getWeekPoolPeriodStartFunction(weekStart),
      getPoolPeriodThresholds: getPoolPeriodOvertimeThresholdsFunction(
        poolDateOvertimeThresholdsFunction,
      ),
    };
  } else if (pools.type === "twoWeeks") {
    const thresholds =
      pools.overtimeThresholds ||
      (pools.overtimeThreshold != null ? [pools.overtimeThreshold] : []);
    const {startDate} = pools;
    return {
      getPoolPeriodEnd: twoWeekPoolPeriodEndFunction,
      getPoolPeriodStart: getTwoWeekPoolPeriodStartFunction(startDate),
      getPoolPeriodThresholds: getPoolPeriodConstantsThresholdsFunction(thresholds),
    };
  } else {
    console.assert(pools.type === "fourWeeks");
    const thresholds =
      pools.overtimeThresholds ||
      (pools.overtimeThreshold != null ? [pools.overtimeThreshold] : []);
    const {startDate} = pools;
    return {
      getPoolPeriodEnd: fourWeekPoolPeriodEndFunction,
      getPoolPeriodStart: getFourWeekPoolPeriodStartFunction(startDate),
      getPoolPeriodThresholds: getPoolPeriodConstantsThresholdsFunction(thresholds),
    };
  }
}

export function getPeriodPoolThresholds(
  fromDate: string,
  toDate: string,
  daysAbsenceList: readonly DaysAbsence[],
  remunerationGroup: RemunerationGroup,
  commonRemunerationSettings: CommonRemunerationSettings,
):
  | {
      periodEnd: Date;
      periodStart: Date;
      periodThresholds: readonly number[];
    }[]
  | null {
  const {pools} = remunerationGroup;
  if (pools) {
    const checkHoliday = getAbsenceHolidayCheckFunction(
      daysAbsenceList,
      remunerationGroup,
      commonRemunerationSettings,
    );
    const getDateOvertimeThresholds = getDateOvertimeThresholdsFunction(
      checkHoliday,
      remunerationGroup,
      pools.type === "normalisedWeek" ? pools.dayThresholds : undefined,
    );
    const {getPoolPeriodEnd, getPoolPeriodStart, getPoolPeriodThresholds} = processPoolParameters(
      pools,
      checkHoliday,
      getDateOvertimeThresholds,
      remunerationGroup,
    );
    const result: {
      periodEnd: Date;
      periodStart: Date;
      periodThresholds: readonly number[];
    }[] = [];
    let dateString = fromDate;
    while (dateString < toDate) {
      const periodStart = getPoolPeriodStart(dateFromString(dateString) as Date);
      const periodEnd = getPoolPeriodEnd(periodStart);
      const periodThresholds = getPoolPeriodThresholds(periodStart, periodEnd);
      result.push({
        periodEnd,
        periodStart,
        periodThresholds,
      });
      dateString = dateToString(periodEnd);
    }
    return result;
  } else {
    return null;
  }
}

interface PayrollGroupSetup {
  checkHoliday: HolidayCheckFunction;
  getDateHoursRates: HoursRatesFunction;
  getDateOvertimeThresholds(date: Date): readonly (number | readonly [number, number])[];
  getPoolDateAbsenceCompensatoryNormalHoursMinutes(date: Date): number;
  getPoolPeriodEnd: PoolPeriodEndFunction | undefined;
  getPoolPeriodStart: PoolPeriodStartFunction | undefined;
  getPoolPeriodThresholds: PoolPeriodThresholdsFunction | undefined;
  processOvertimeThresholds(
    fromRate: Rate,
    workDays: readonly WorkDay[],
    updatedRates: Set<Rate>,
  ): WorkDay[];
  processPools(fromRate: Rate, workDays: readonly WorkDay[]): readonly WorkDay[];
  processSpecialStartRate(workDays: readonly WorkDay[]): readonly WorkDay[];
}

export function getGroupPayrollSetup(
  daysAbsenceList: readonly DaysAbsence[],
  remunerationGroup: RemunerationGroup,
  commonRemunerationSettings: CommonRemunerationSettings,
): PayrollGroupSetup {
  const checkHoliday = getAbsenceHolidayCheckFunction(
    daysAbsenceList,
    remunerationGroup,
    commonRemunerationSettings,
  );

  const {specialStartRateMinutes} = remunerationGroup;

  const processSpecialStartRate = specialStartRateMinutes
    ? (workDays: readonly WorkDay[]) => attachSpecialStartRate(workDays, specialStartRateMinutes)
    : (workDays: readonly WorkDay[]) => workDays;

  const {halfHolidayHoursRates, halfHolidaySundayAfterNoon, hoursRates} = remunerationGroup;

  const getDateHoursRates = getDateHoursRatesFunction(
    hoursRates,
    checkHoliday,
    halfHolidaySundayAfterNoon,
    halfHolidayHoursRates || undefined,
  );

  const getDateOvertimeThresholds = getDateOvertimeThresholdsFunction(
    checkHoliday,
    remunerationGroup,
  );

  const processOvertimeThresholds = (
    fromRate: Rate,
    workDays: readonly WorkDay[],
    updatedRates: Set<Rate>,
  ): WorkDay[] => {
    return setThresholdsOvertimeLevel(fromRate, workDays, getDateOvertimeThresholds, updatedRates);
  };

  let processPools = (_fromRate: Rate, workDays: readonly WorkDay[]): readonly WorkDay[] =>
    workDays;

  let optGetPoolPeriodStart: PoolPeriodStartFunction | undefined;
  let optGetPoolPeriodEnd: PoolPeriodEndFunction | undefined;
  let optGetPoolPeriodThresholds: PoolPeriodThresholdsFunction | undefined;

  const {pools} = remunerationGroup;

  if (pools && pools.type) {
    const {getPoolPeriodEnd, getPoolPeriodStart, getPoolPeriodThresholds} = processPoolParameters(
      pools,
      checkHoliday,
      getDateOvertimeThresholds,
      remunerationGroup,
    );
    processPools = (fromRate: Rate, workDays: readonly WorkDay[]) =>
      setPoolsOvertime(
        fromRate,
        workDays,
        getPoolPeriodStart,
        getPoolPeriodEnd,
        getPoolPeriodThresholds,
      );
    optGetPoolPeriodStart = getPoolPeriodStart;
    optGetPoolPeriodEnd = getPoolPeriodEnd;
    optGetPoolPeriodThresholds = getPoolPeriodThresholds;
  }

  const getPoolDateAbsenceCompensatoryNormalHoursMinutes =
    getPoolDateAbsenceCompensatoryNormalHoursMinutesFunction(
      checkHoliday,
      getDateHoursRates,
      getDateOvertimeThresholds,
      remunerationGroup,
    );

  const result: PayrollGroupSetup = {
    checkHoliday,
    getDateHoursRates,
    getDateOvertimeThresholds,
    getPoolDateAbsenceCompensatoryNormalHoursMinutes,
    getPoolPeriodEnd: optGetPoolPeriodEnd,
    getPoolPeriodStart: optGetPoolPeriodStart,
    getPoolPeriodThresholds: optGetPoolPeriodThresholds,
    processOvertimeThresholds,
    processPools,
    processSpecialStartRate,
  };
  return result;
}

type WorkPeriodGroupedIntervals = readonly (readonly TaskIntervalWithUnregisteredAndPaid[])[];

export function buildTimeline(
  baseIntervals: readonly TaskInterval[],
  punchedInOutArray: readonly PunchInOut[] | null,
  unregisteredBreakAfterMinutes: number,
  periodSplitThresholdMinutes: number,
  workDaySplitThresholdMinutes: number | null,
  performDayStartRounding: (
    workPeriodIntervals: WorkPeriodGroupedIntervals,
  ) => WorkPeriodGroupedIntervals,
  performDayEndRounding: (
    workPeriodIntervals: WorkPeriodGroupedIntervals,
  ) => WorkPeriodGroupedIntervals,
  paidBreaks: boolean,
): BaseWorkDay[] {
  // simplify timeline
  // * inject "break" intervals for unregistered time beyond threshold
  // * inject "active" intervals for unregistered time below threshold
  const intervalsWithUnregistered: readonly TaskIntervalWithUnregistered[] = injectUnregistered(
    baseIntervals,
    unregisteredBreakAfterMinutes,
    periodSplitThresholdMinutes,
  );
  const intervalsWithPaid: readonly TaskIntervalWithUnregisteredAndPaid[] = punchedInOutArray
    ? paidFromPunchedInOut(intervalsWithUnregistered, punchedInOutArray)
    : paidBreaks
      ? intervalsWithUnregistered.map((interval) => Object.assign({isPaid: true}, interval))
      : intervalsWithUnregistered.map((interval) =>
          Object.assign({isPaid: !interval.isBreak}, interval),
        );

  // group intervals for "work periods"
  // * sequences of unregistered/breaks over splitThresholdMinutes are split on
  const workPeriodIntervals: WorkPeriodGroupedIntervals = groupWorkPeriods(
    intervalsWithPaid,
    periodSplitThresholdMinutes,
    !!punchedInOutArray,
  );
  // if work period *starts* between configured N and M, start is moved to M
  const startRoundedWorkPeriodIntervals = performDayStartRounding(workPeriodIntervals);
  // if work period *ends* between configured N and M, end is moved to N
  const roundedWorkPeriodIntervals = performDayEndRounding(startRoundedWorkPeriodIntervals);
  // group into WorkPeriod instances...
  const workPeriods: WorkPeriod[] = toWorkPeriods(roundedWorkPeriodIntervals);
  // group into work days; as overtime computations depend on calendar days,
  // though work periods are associated with the day they start
  const workDays: BaseWorkDay[] =
    workDaySplitThresholdMinutes === null
      ? toWorkDaysPerDate(workPeriods)
      : toWorkDaysSplitThreshold(workPeriods, workDaySplitThresholdMinutes);
  return workDays;
}

export function computeRates(
  inputWorkDays: readonly WorkDay[],
  normalTransportMinutes: number | undefined,
  groupSetup: PayrollGroupSetup,
  remunerationGroup: RemunerationGroup,
  commonRemunerationSettings: CommonRemunerationSettings,
  taskList: readonly Task[],
  projectMap: ReadonlyMap<string, Project>,
  priceItemMap: ReadonlyMap<string, PriceItem>,
  normalTransportKilometersCountBonusLabel: string | undefined,
  normalTransportKilometers: number | undefined,
  groupAccomodationAllowanceList: readonly AccomodationAllowance[] | undefined,
  unpaidTimerURLs: ReadonlySet<string> | undefined,
  otherGroupsIntervals: readonly (readonly TaskInterval[])[],
): WorkDayWithBonus[] {
  const {
    calendarDayBonus,
    countBonus,
    intervalBonus,
    rateOverride,
    rateSwitch,
    taskBonus,
    workDayBonus,
  } = remunerationGroup;
  const {paidTransportWorkType} = commonRemunerationSettings;
  const {
    checkHoliday,
    getDateHoursRates,
    processOvertimeThresholds,
    processPools,
    processSpecialStartRate,
  } = groupSetup;

  let workDays = inputWorkDays;
  if (paidTransportWorkType && normalTransportMinutes) {
    workDays = processPaidTransportSpecialCase(
      workDays,
      paidTransportWorkType,
      normalTransportMinutes,
    );
  }
  workDays = processSpecialStartRate(workDays);
  if (unpaidTimerURLs && unpaidTimerURLs.size) {
    workDays = processIgnoreTimers(workDays, unpaidTimerURLs);
  }
  if (otherGroupsIntervals.length) {
    workDays = makeOtherGroupsIntervalsUnpaid(otherGroupsIntervals, workDays);
  }

  // * overtime/rate from time on day --- does not depend on other computations
  // * overtime from "after N normal hours" criteria --- depends on normal
  //   hours from previous step
  // * split wrt. pools; overtime from "after N normal hours in pool period"
  //   criteria --- depends on normal hours from previous steps
  // * overtime X -> Y from "after N hours" criteria --- depends on overtime
  //   from previous steps

  workDays = setHoursRates(workDays, getDateHoursRates);

  workDays = processRateOverrides(checkHoliday, workDays, rateSwitch);

  const ratesToCompute = new Set<Rate>();
  for (let fromRate = Rate.NORMAL; fromRate < MAX_OVERTIME_RATE; fromRate += 1) {
    ratesToCompute.add(fromRate);
  }
  while (ratesToCompute.size) {
    const fromRate = _.min(Array.from(ratesToCompute)) as Rate;
    ratesToCompute.delete(fromRate);
    workDays = processOvertimeThresholds(fromRate, workDays, ratesToCompute);
    workDays = processPools(fromRate, workDays);
  }

  workDays = processRateOverrides(checkHoliday, workDays, rateOverride);

  const workDaysWithBonus: WorkDayWithBonus[] = attachBonus({
    calendarDayBonus,
    checkHoliday,
    countBonus,
    groupAccomodationAllowanceList,
    intervalBonus,
    normalTransportKilometers,
    normalTransportKilometersCountBonusLabel,
    priceItemMap,
    projectMap,
    taskBonus,
    taskList,
    workDayBonus,
    workDays,
  });

  return workDaysWithBonus;
}

export function getPoolPeriodSequence(
  fromDate: Date,
  toDate: Date,
  getPoolPeriodStart: PoolPeriodStartFunction,
  getPoolPeriodEnd: PoolPeriodEndFunction,
): {fromDate: Date; toDate: Date}[] {
  const result: {fromDate: Date; toDate: Date}[] = [];
  let periodFromDate = getPoolPeriodStart(fromDate);
  let periodToDate = getPoolPeriodEnd(periodFromDate);
  while (periodToDate < toDate) {
    result.push({fromDate: periodFromDate, toDate: periodToDate});
    periodFromDate = periodToDate;
    periodToDate = getPoolPeriodEnd(periodFromDate);
  }
  result.push({fromDate: periodFromDate, toDate: periodToDate});
  return result;
}

export function performComputation(
  baseIntervals: readonly TaskInterval[],
  taskList: readonly Task[],
  punchedInOutArray: readonly PunchInOut[] | null,
  timerMap: ReadonlyMap<string, Timer>,
  projectMap: ReadonlyMap<string, Project>,
  priceItemMap: ReadonlyMap<string, PriceItem>,
  daysAbsenceList: readonly DaysAbsence[],
  performDayStartRounding: (
    workPeriodIntervals: WorkPeriodGroupedIntervals,
  ) => WorkPeriodGroupedIntervals,
  performDayEndRounding: (
    workPeriodIntervals: WorkPeriodGroupedIntervals,
  ) => WorkPeriodGroupedIntervals,
  normalTransportMinutes: number | undefined,
  groupSetup: PayrollGroupSetup,
  remunerationGroup: RemunerationGroup,
  commonRemunerationSettings: CommonRemunerationSettings,
  normalTransportKilometersCountBonusLabel: string | undefined,
  normalTransportKilometers: number | undefined,
  groupAccomodationAllowanceList: readonly AccomodationAllowance[] | undefined,
  otherGroupsIntervals: readonly (readonly TaskInterval[])[],
): WorkDayWithBonus[] {
  const baseWorkDays = buildTimeline(
    baseIntervals,
    punchedInOutArray,
    commonRemunerationSettings.unregisteredBreakAfterMinutes,
    commonRemunerationSettings.periodSplitThresholdMinutes,
    commonRemunerationSettings.workDaySplitThresholdMinutes,
    performDayStartRounding,
    performDayEndRounding,
    !!remunerationGroup.paidBreaks,
  );

  const {getPoolDateAbsenceCompensatoryNormalHoursMinutes} = groupSetup;

  const {paidDayAbsenceTypes} = commonRemunerationSettings;
  const paidDaysAbsenceList = daysAbsenceList.filter((absence) =>
    paidDayAbsenceTypes.includes(absence.absenceType),
  );
  const dayToDaysPaidAbsence = getDayToDaysAbsenceMapping(paidDaysAbsenceList);
  const daysWithPaidAbsence = new Set(Object.keys(dayToDaysPaidAbsence));
  const handledDaysWithPaidAbsence = new Set<string>();

  const workDays = baseWorkDays.map((workDay: BaseWorkDay): WorkDay => {
    const {date} = workDay;
    if (daysWithPaidAbsence.has(date)) {
      handledDaysWithPaidAbsence.add(date);
      const normalHoursMinutes = getPoolDateAbsenceCompensatoryNormalHoursMinutes(
        midnightFromDateString(date),
      );
      return {
        ...workDay,
        extraRateMinutes: new Map([[Rate.NORMAL, normalHoursMinutes ?? DAY_MINUTES]]),
      };
    } else {
      return {
        ...workDay,
        extraRateMinutes: new Map(),
      };
    }
  });
  if (daysWithPaidAbsence.size > handledDaysWithPaidAbsence.size) {
    daysWithPaidAbsence.forEach((date) => {
      if (handledDaysWithPaidAbsence.has(date)) {
        return;
      }
      handledDaysWithPaidAbsence.add(date);
      const normalHoursMinutes = getPoolDateAbsenceCompensatoryNormalHoursMinutes(
        midnightFromDateString(date),
      );
      workDays.push({
        date,
        extraRateMinutes: new Map([[Rate.NORMAL, normalHoursMinutes ?? DAY_MINUTES]]),
        workPeriods: [],
      });
    });
    workDays.sort((a, b) => a.date.localeCompare(b.date));
  }

  let unpaidTimerURLs: ReadonlySet<string> | undefined;
  if (remunerationGroup.ignoreTimerIDs.length) {
    unpaidTimerURLs = new Set(
      Array.from(timerMap.values())
        .filter((timer) => remunerationGroup.ignoreTimerIDs.includes(timer.identifier))
        .map((timer) => timer.url),
    );
  }

  return computeRates(
    workDays,
    normalTransportMinutes,
    groupSetup,
    remunerationGroup,
    commonRemunerationSettings,
    taskList,
    projectMap,
    priceItemMap,
    normalTransportKilometersCountBonusLabel,
    normalTransportKilometers,
    groupAccomodationAllowanceList,
    unpaidTimerURLs,
    otherGroupsIntervals,
  );
}

/**
 * @returns Remuneration group -> Workday data mapping
 */
export function computeWorkdaysFull(
  taskList: readonly Task[],
  punchedInOutArray: readonly PunchInOut[] | null,
  timerMap: ReadonlyMap<string, Timer>,
  orderMap: ReadonlyMap<string, Order>,
  projectMap: ReadonlyMap<string, Project>,
  priceItemMap: ReadonlyMap<string, PriceItem>,
  daysAbsenceList: readonly DaysAbsence[],
  normalTransportMinutes: number | undefined,
  employeeDefaultRemunerationGroup: string,
  remunerationGroups: ReadonlyMap<string, RemunerationGroup>,
  commonRemunerationSettings: CommonRemunerationSettings,
  normalTransportKilometersCountBonusLabel?: string,
  normalTransportKilometers?: number,
  accomodationAllowanceList?: readonly AccomodationAllowance[],
): Map<string, WorkDayWithBonus[]> {
  const tasksPerRemunerationGroup = new Map<string, Task[]>();
  const defaultRemunerationGroup = remunerationGroups.get(employeeDefaultRemunerationGroup);
  if (!defaultRemunerationGroup) {
    throw new Error(`no remunerationGroup ${employeeDefaultRemunerationGroup}`);
  }
  if (!defaultRemunerationGroup.switchGroups.length) {
    tasksPerRemunerationGroup.set(employeeDefaultRemunerationGroup, taskList.slice());
  } else {
    const getGroup = getGroupFn(
      employeeDefaultRemunerationGroup,
      defaultRemunerationGroup.switchGroups,
    );
    for (let i = 0; i < taskList.length; i += 1) {
      const task = taskList[i];
      const group = getGroup(task);
      const groupArray = tasksPerRemunerationGroup.get(group);
      if (groupArray) {
        groupArray.push(task);
      } else {
        tasksPerRemunerationGroup.set(group, [task]);
      }
    }
  }
  const tasksIntervalsPerRemunerationGroup = mapMap(
    tasksPerRemunerationGroup,
    (tasks, _groupName) => {
      // building timeline
      // * for each task, find intervals
      // * drop those whose rounded duration in minutes is 0
      // * attach metadata about task
      // * combine into sorted array --- potentially with overlap...
      const intervals: readonly TaskInterval[] = normalisedTaskListIntervals(
        tasks,
        timerMap,
        orderMap,
      );
      return {intervals, tasks};
    },
  );
  const result = mapMap(tasksIntervalsPerRemunerationGroup, ({intervals, tasks}, groupName) => {
    const otherGroupsIntervals: (readonly TaskInterval[])[] = [];
    if (
      groupName === employeeDefaultRemunerationGroup &&
      tasksIntervalsPerRemunerationGroup.size > 1
    ) {
      tasksIntervalsPerRemunerationGroup.forEach(({intervals: otherIntervals}, otherGroupName) => {
        if (otherIntervals.length && otherGroupName !== employeeDefaultRemunerationGroup) {
          otherGroupsIntervals.push(otherIntervals);
        }
      });
    }
    const remunerationGroup = remunerationGroups.get(groupName);
    if (!remunerationGroup) {
      throw new Error(`no remunerationGroup ${remunerationGroup}`);
    }

    const {dayEndRounding, dayStartRounding} = remunerationGroup;
    const performDayStartRounding = dayStartRounding
      ? (workPeriodIntervals: WorkPeriodGroupedIntervals) =>
          applyDayStartRounding(workPeriodIntervals, dayStartRounding)
      : (workPeriodIntervals: WorkPeriodGroupedIntervals) => workPeriodIntervals;
    const performDayEndRounding = dayEndRounding
      ? (workPeriodIntervals: WorkPeriodGroupedIntervals) =>
          applyDayEndRounding(workPeriodIntervals, dayEndRounding)
      : (workPeriodIntervals: WorkPeriodGroupedIntervals) => workPeriodIntervals;

    const groupAccomodationAllowanceList = accomodationAllowanceList
      ? accomodationAllowanceList.filter((a) => a.remunerationGroup === groupName)
      : [];

    const groupSetup = getGroupPayrollSetup(
      daysAbsenceList,
      remunerationGroup,
      commonRemunerationSettings,
    );

    return performComputation(
      intervals,
      tasks,
      punchedInOutArray,
      timerMap,
      projectMap,
      priceItemMap,
      daysAbsenceList,
      performDayStartRounding,
      performDayEndRounding,
      normalTransportMinutes,
      groupSetup,
      remunerationGroup,
      commonRemunerationSettings,
      normalTransportKilometersCountBonusLabel,
      normalTransportKilometers,
      groupAccomodationAllowanceList,
      otherGroupsIntervals,
    );
  });
  return result;
}

function simplifyWorkDay(
  workDay: WorkDayWithBonus,
  projectMap: ReadonlyMap<string, Project>,
): SimplifiedWorkDay {
  let breakMilliseconds = 0;
  const rateMilliseconds = new Map<Rate, number>();
  const bonusMilliseconds = new Map<string, number>();
  const projectURLs = new Set<string>();
  const orderReferenceNumbers = new Set<string>();
  const taskReferenceNumbers = new Set<string>();
  workDay.extraRateMinutes.forEach((minutes, rate) => {
    rateMilliseconds.set(rate, minutes * MINUTE_MILLISECONDS);
  });
  workDay.workPeriods.forEach((workPeriod) => {
    workPeriod.breaks.forEach((breakInterval) => {
      breakMilliseconds += computeIntervalDurationMilliseconds(breakInterval);
    });
    workPeriod.work.forEach((workInterval) => {
      const milliseconds = computeIntervalDurationMilliseconds(workInterval);
      const {rate} = workInterval;
      rateMilliseconds.set(rate, (rateMilliseconds.get(rate) || 0) + milliseconds);
      workInterval.bonus.forEach((bonus) => {
        bonusMilliseconds.set(bonus, (bonusMilliseconds.get(bonus) || 0) + milliseconds);
      });
      workInterval.taskData.forEach((taskData) => {
        if (taskData.projectURL) {
          projectURLs.add(taskData.projectURL);
        }
        if (taskData.taskReferenceNumber) {
          taskReferenceNumbers.add(taskData.taskReferenceNumber);
        }
        if (taskData.orderReferenceNumber) {
          orderReferenceNumbers.add(taskData.orderReferenceNumber);
        }
      });
    });
  });
  const breakMinutes = roundMillisecondsToMinutes(breakMilliseconds);
  const rateMinutes = new Map<Rate, number>();
  rateMilliseconds.forEach((milliseconds, rate) => {
    rateMinutes.set(rate, roundMillisecondsToMinutes(milliseconds));
  });
  const actualWorkMinutes = _.sum(Array.from(rateMinutes.values()));
  const bonusMinutes = new Map<string, number>();
  bonusMilliseconds.forEach((milliseconds, bonus) => {
    bonusMinutes.set(bonus, roundMillisecondsToMinutes(milliseconds));
  });
  const workPeriods: SimpleInterval[] = [];
  const breakPeriods: SimpleInterval[] = [];
  workDay.workPeriods.forEach(({breaks, firstFromTimestamp, lastToTimestamp}) => {
    workPeriods.push({
      fromTimestamp: firstFromTimestamp,
      toTimestamp: lastToTimestamp,
    });
    breakPeriods.push(...breaks);
  });
  const projects = projectURLs.size
    ? Array.from(projectURLs, (projectURL) => {
        const project = projectMap.get(projectURL);
        if (project) {
          const {alias, name, projectNumber} = project;
          return {alias, name, projectNumber};
        } else {
          return null;
        }
      }).filter(notNull)
    : [];
  return {
    actualWorkMinutes,
    bonusMinutes,
    breakMinutes,
    breakPeriods,
    calendarDayBonus: new Map(
      Array.from(workDay.calendarDayBonus)
        .map(([bonus, dates]): [string, number] => [bonus, dates.size])
        .filter(([_bonus, count]) => count > 0),
    ),
    calledIn: workDay.calledIn,
    countBonus: workDay.countBonus,
    date: workDay.date,
    orderReferenceNumbers: Array.from(orderReferenceNumbers),
    projectDistance: workDay.projectDistance,
    projects,
    projectTravelTime: workDay.projectTravelTime,
    rateMinutes,
    taskBonus: new Map(
      Array.from(workDay.taskBonus)
        .map(([bonus, taskIds]): [string, number] => [bonus, taskIds.size])
        .filter(([_bonus, count]) => count > 0),
    ),
    taskReferenceNumbers: Array.from(taskReferenceNumbers),
    workDayBonus: workDay.bonus,
    workPeriods,
  };
}

export function simplifyWorkDays(
  workDays: readonly WorkDayWithBonus[],
  projectMap: ReadonlyMap<string, Project>,
): SimplifiedWorkDay[] {
  return workDays.map((workDay) => simplifyWorkDay(workDay, projectMap));
}

const DATE_STRING_LENGTH = "YYYY-MM-DD".length;
const YEAR_AND_SEPARATOR_LENGTH = "YYYY-".length;

export function addMeta(
  workDays: readonly SimplifiedWorkDayWithAccomodation[],
  daysAbsenceList: readonly DaysAbsence[],
  hoursAbsenceList: readonly HoursAbsence[],
  dinnerBookingList: readonly DinnerBooking[],
  lunchBookingList: readonly LunchBooking[],
  dateHasNormalWorkHours: (date: string) => boolean,
  settings: Config,
  remunerationGroup: RemunerationGroup,
  outputFromDate: string,
  outputToDate: string,
): SimplifiedWorkDayWithMeta[] {
  const dayToDaysAbsence = getDayToDaysAbsenceMapping(daysAbsenceList);
  if (remunerationGroup.reportIgnoreAbsenceOnHolidays) {
    Object.keys(dayToDaysAbsence).forEach((dateString) => {
      if (!dateHasNormalWorkHours(dateString)) {
        delete dayToDaysAbsence[dateString];
      }
    });
  }
  const dayToHoursAbsence = getDayToHoursAbsenceMapping(hoursAbsenceList);
  if (remunerationGroup.reportIgnoreAbsenceOnHolidays) {
    Object.keys(dayToHoursAbsence).forEach((dateString) => {
      if (!dateHasNormalWorkHours(dateString)) {
        delete dayToHoursAbsence[dateString];
      }
    });
  }
  const dayToWorkDay: {
    [date: string]: SimplifiedWorkDayWithAccomodation[] | undefined;
  } = {};
  workDays.forEach((workDay) => {
    const existingForDay = dayToWorkDay[workDay.date];
    if (existingForDay) {
      existingForDay.push(workDay);
    } else {
      dayToWorkDay[workDay.date] = [workDay];
    }
  });
  const dayToDinnerBookings: {[date: string]: number | undefined} = {};
  const sortedDinnerBookingList = _.sortBy(dinnerBookingList, "lastChanged");
  sortedDinnerBookingList.forEach((dinnerBooking) => {
    const {date} = dinnerBooking;
    if (dinnerBooking.count) {
      dayToDinnerBookings[date] = dinnerBooking.count;
    }
  });
  const dayToLunchBookings: {[date: string]: number | undefined} = {};
  const sortedLunchBookingList = _.sortBy(lunchBookingList, "lastChanged");
  sortedLunchBookingList.forEach((lunchBooking) => {
    const {date} = lunchBooking;
    if (lunchBooking.count) {
      dayToLunchBookings[date] = lunchBooking.count;
    }
  });
  const dayToHoliday: {[date: string]: string | undefined} = {};
  if (settings.remunerationReportShowHolidays || remunerationGroup.countWeekdayHolidays) {
    const {extraHalfHolidays, extraHolidays} = remunerationGroup;
    let holidayDateString = outputFromDate;
    const holidayDate = dateFromString(outputFromDate) as Date;
    while (holidayDateString <= outputToDate) {
      console.assert(holidayDateString.length === DATE_STRING_LENGTH);
      const monthDayString = holidayDateString.substring(YEAR_AND_SEPARATOR_LENGTH);
      const holidayString =
        getHoliday(remunerationGroup.holidayCalendars, holidayDateString)?.join(", ") ||
        extraHolidays.get(holidayDateString) ||
        extraHolidays.get(monthDayString) ||
        extraHalfHolidays.get(holidayDateString) ||
        extraHalfHolidays.get(monthDayString);
      if (
        holidayString &&
        (settings.remunerationReportShowHolidays || !isWeekend(holidayDateString))
      ) {
        dayToHoliday[holidayDateString] = holidayString;
      }
      holidayDate.setDate(holidayDate.getDate() + 1);
      holidayDateString = dateToString(holidayDate);
    }
  }

  const hasNonHolidays =
    !!Object.keys(dayToWorkDay).length ||
    !!Object.keys(dayToDaysAbsence).length ||
    !!Object.keys(dayToHoursAbsence).length ||
    !!Object.keys(dayToDinnerBookings).length ||
    !!Object.keys(dayToLunchBookings).length;

  if (!hasNonHolidays) {
    return [];
  }

  const dates = Array.from(
    new Set(
      Object.keys(dayToWorkDay)
        .concat(Object.keys(dayToDaysAbsence))
        .concat(Object.keys(dayToHoursAbsence))
        .concat(Object.keys(dayToDinnerBookings))
        .concat(Object.keys(dayToLunchBookings))
        .concat(Object.keys(dayToHoliday)),
    ),
  );
  dates.sort();
  const result: SimplifiedWorkDayWithMeta[] = [];
  const emptyWorkDay: SimplifiedWorkDayWithAccomodation = {
    accomodationDay: false,
    accomodationMinutes: 0,
    actualWorkMinutes: 0,
    bonusMinutes: new Map(),
    breakMinutes: 0,
    breakPeriods: [],
    calendarDayBonus: new Map(),
    calledIn: 0,
    countBonus: new Map(),
    date: "",
    orderReferenceNumbers: [],
    projectDistance: 0,
    projects: [],
    projectTravelTime: 0,
    rateMinutes: new Map(),
    taskBonus: new Map(),
    taskReferenceNumbers: [],
    workDayBonus: [],
    workPeriods: [],
  };
  for (const date of dates) {
    for (const workDay of dayToWorkDay[date] || [emptyWorkDay]) {
      const dinnerBookings = dayToDinnerBookings[date] || 0;
      const lunchBookings = dayToLunchBookings[date] || 0;
      const daysAbsence: Map<string, {employeeReason: string; note: string}[]> = new Map();
      const minutesAbsence: Map<
        string,
        {
          employeeReason: string;
          fromTimestamp: string;
          note: string;
          toTimestamp: string;
        }[]
      > = new Map();
      const dateHoursAbsence = dayToHoursAbsence[date];
      if (dateHoursAbsence) {
        dateHoursAbsence.forEach((hoursAbsence) => {
          const {absenceType} = hoursAbsence;
          const entry = {
            employeeReason: hoursAbsence.employeeReason,
            fromTimestamp: hoursAbsence.fromTimestamp,
            note: hoursAbsence.note,
            toTimestamp: hoursAbsence.toTimestamp,
          };
          const absenceTypeEntries = minutesAbsence.get(absenceType);
          if (absenceTypeEntries) {
            absenceTypeEntries.push(entry);
          } else {
            minutesAbsence.set(absenceType, [entry]);
          }
        });
      }
      const dateDaysAbsence = dayToDaysAbsence[date];
      if (dateDaysAbsence) {
        dateDaysAbsence.forEach((daysAbsenceEntry) => {
          const {absenceType} = daysAbsenceEntry;
          const entry = {
            employeeReason: daysAbsenceEntry.employeeReason,
            note: daysAbsenceEntry.note,
          };
          const absenceTypeEntries = daysAbsence.get(absenceType);
          if (absenceTypeEntries) {
            absenceTypeEntries.push(entry);
          } else {
            daysAbsence.set(absenceType, [entry]);
          }
        });
      }
      const holiday = dayToHoliday[date] ?? null;
      const entry = Object.assign({}, workDay, {
        date,
        daysAbsence,
        dinnerBookings,
        holiday,
        lunchBookings,
        minutesAbsence,
        paidWeekdayHoliday:
          (remunerationGroup.countWeekdayHolidays && holiday !== null && !isWeekend(date)) ?? null,
      });
      result.push(entry);
    }
  }
  return result;
}

export function computePeriodSums(
  workDays: SimplifiedWorkDayWithMeta[],
  periods: {fromDate: Date; toDate: Date}[],
  getPoolDateAbsenceCompensatoryNormalHoursMinutes: (date: Date) => number,
): SimplifiedPoolPeriodWithMeta[] {
  interface ReadWriteSimplifiedPoolPeriodWithMeta {
    readonly bonusMinutes: Map<string, number>;
    breakMinutes: number;
    readonly daysAbsence: Map<string, number>;
    readonly daysMinutesAbsence: Map<string, number>;
    dinnerBookings: number;
    readonly fromDate: string;
    lunchBookings: number;
    readonly minutesAbsence: Map<string, number>;
    readonly rateMinutes: Map<Rate, number>;
    readonly toDate: string;
    readonly workDayBonus: Map<string, number>;
  }
  const computedValue: ReadWriteSimplifiedPoolPeriodWithMeta[] = periods.map(
    ({fromDate, toDate}) => {
      console.assert(fromDate < toDate);
      // toDate should be midnight at end of pediod.
      console.assert(toDate.getHours() === 0);
      console.assert(toDate.getMinutes() === 0);
      console.assert(toDate.getSeconds() === 0);
      console.assert(toDate.getMilliseconds() === 0);
      const beforeToDate = new Date(toDate);
      beforeToDate.setUTCMinutes(beforeToDate.getUTCMinutes() - 1);
      const entry: ReadWriteSimplifiedPoolPeriodWithMeta = {
        bonusMinutes: new Map<string, number>(),
        breakMinutes: 0,
        daysAbsence: new Map<string, number>(),
        daysMinutesAbsence: new Map<string, number>(),
        dinnerBookings: 0,
        fromDate: dateToString(fromDate),
        lunchBookings: 0,
        minutesAbsence: new Map<string, number>(),
        rateMinutes: new Map<Rate, number>(),
        toDate: dateToString(beforeToDate),
        workDayBonus: new Map<string, number>(),
      };
      return entry;
    },
  );
  for (let i = 0; i < workDays.length; i += 1) {
    const workDay = workDays[i];
    const {date} = workDay;
    let period: ReadWriteSimplifiedPoolPeriodWithMeta | undefined;
    for (let j = 0; j < computedValue.length; j += 1) {
      const potentialPeriod = computedValue[j];
      if (potentialPeriod.toDate >= date && potentialPeriod.fromDate <= date) {
        period = potentialPeriod;
        break;
      }
    }
    if (period) {
      if (workDay.breakMinutes) {
        period.breakMinutes += workDay.breakMinutes;
      }
      if (workDay.rateMinutes.size) {
        const periodRateMinutes = period.rateMinutes;
        workDay.rateMinutes.forEach((minutes, rate) => {
          const oldValue = periodRateMinutes.get(rate) || 0;
          periodRateMinutes.set(rate, oldValue + minutes);
        });
      }
      if (workDay.bonusMinutes.size) {
        const periodBonusMinutes = period.bonusMinutes;
        workDay.bonusMinutes.forEach((minutes, bonus) => {
          const oldValue = periodBonusMinutes.get(bonus) || 0;
          periodBonusMinutes.set(bonus, oldValue + minutes);
        });
      }
      if (workDay.workDayBonus.length) {
        const periodWorkDayBonus = period.workDayBonus;
        workDay.workDayBonus.forEach((bonus) => {
          const oldValue = periodWorkDayBonus.get(bonus) || 0;
          periodWorkDayBonus.set(bonus, oldValue + 1);
        });
      }
      if (workDay.dinnerBookings) {
        period.dinnerBookings += workDay.dinnerBookings;
      }
      if (workDay.lunchBookings) {
        period.lunchBookings += workDay.lunchBookings;
      }
      if (workDay.daysAbsence.size) {
        const periodDaysAbsence = period.daysAbsence;
        const periodDaysMinutesAbsence = period.daysMinutesAbsence;
        const potentialDayMinutes = getPoolDateAbsenceCompensatoryNormalHoursMinutes(
          midnightFromDateString(date),
        );
        workDay.daysAbsence.forEach((entries, absenceType) => {
          if (entries.length) {
            const oldCount = periodDaysAbsence.get(absenceType) || 0;
            periodDaysAbsence.set(absenceType, oldCount + 1);
            const oldMinutes = periodDaysMinutesAbsence.get(absenceType) || 0;
            periodDaysMinutesAbsence.set(absenceType, oldMinutes + potentialDayMinutes);
          }
        });
      }
      if (workDay.minutesAbsence.size) {
        const potentialDayMinutes = getPoolDateAbsenceCompensatoryNormalHoursMinutes(
          midnightFromDateString(date),
        );
        const periodMinutesAbsence = period.minutesAbsence;
        workDay.minutesAbsence.forEach((entries, absenceType) => {
          let increase = 0;
          for (let j = 0; j < entries.length; j += 1) {
            const entry = entries[j];
            increase += computeIntervalRoundedDurationMinutes(entry);
          }
          increase = Math.min(increase, potentialDayMinutes);
          const oldValue = periodMinutesAbsence.get(absenceType) || 0;
          periodMinutesAbsence.set(absenceType, oldValue + increase);
        });
      }
    }
  }
  const result: SimplifiedPoolPeriodWithMeta[] = computedValue;
  return result;
}

export function computeAccumulatedCompensatoryPotentialChanges(
  poolPeriods: SimplifiedPoolPeriodWithMeta[],
  getPoolPeriodThresholds: PoolPeriodThresholdsFunction,
  getPoolDateAbsenceCompensatoryNormalHoursMinutes: (date: Date) => number,
  computeCompensatoryMinutes: (minutes: number, rate: Rate) => number,
  compensatorySubtractOnly: readonly string[] | undefined,
  validAbsenceTypes: readonly string[] | undefined,
  remunerationGroup: RemunerationGroup,
): SimplifiedPoolPeriodWithMetaAndCompensatory[] {
  const result: SimplifiedPoolPeriodWithMetaAndCompensatory[] = [];
  for (let i = 0; i < poolPeriods.length; i += 1) {
    const poolPeriod = poolPeriods[i];
    const fromDate = midnightFromDateString(poolPeriod.fromDate);
    const toDate = midnightFromDateString(poolPeriod.toDate);
    toDate.setDate(toDate.getDate() + 1);
    const potentialMinutes = potentialNormalHoursMinutes(
      fromDate,
      toDate,
      getPoolPeriodThresholds,
      getPoolDateAbsenceCompensatoryNormalHoursMinutes,
      remunerationGroup,
    );
    let normalMinutes = 0;
    let compensatoryMinutes = 0;
    poolPeriod.rateMinutes.forEach((minutes, rate) => {
      switch (rate) {
        case Rate.UNPAID:
          break;
        case Rate.SPECIAL_START_RATE:
        case Rate.NORMAL:
          normalMinutes += minutes;
          break;
        default:
          compensatoryMinutes += computeCompensatoryMinutes(minutes, rate);
          break;
      }
    });
    let usedCompensatoryMinutes = 0;
    if (compensatorySubtractOnly && compensatorySubtractOnly.length) {
      for (let j = 0; j < compensatorySubtractOnly.length; j += 1) {
        const compensatorySubtractAbsenceType = compensatorySubtractOnly[j];
        const absenceMinutes = poolPeriod.minutesAbsence.get(compensatorySubtractAbsenceType);
        if (absenceMinutes) {
          usedCompensatoryMinutes += absenceMinutes;
        }
        const absenceDaysMinutes = poolPeriod.daysMinutesAbsence.get(
          compensatorySubtractAbsenceType,
        );
        if (absenceDaysMinutes) {
          usedCompensatoryMinutes += absenceDaysMinutes;
        }
      }
    } else {
      let unusedNormalMinutes = potentialMinutes - normalMinutes;
      if (validAbsenceTypes && validAbsenceTypes.length) {
        for (let j = 0; j < validAbsenceTypes.length; j += 1) {
          const validAbsenceType = validAbsenceTypes[j];
          const absenceMinutes = poolPeriod.minutesAbsence.get(validAbsenceType);
          // eslint-disable-next-line max-depth
          if (absenceMinutes) {
            unusedNormalMinutes -= absenceMinutes;
          }
        }
        if (unusedNormalMinutes < 0) {
          unusedNormalMinutes = 0;
        }
      }
      usedCompensatoryMinutes = unusedNormalMinutes;
    }
    const potentialChange = 0 - usedCompensatoryMinutes + compensatoryMinutes;
    const entry: SimplifiedPoolPeriodWithMetaAndCompensatory = Object.assign({}, poolPeriod, {
      depositedCompensatoryMinutes: compensatoryMinutes,
      potentialCompensatoryChange: potentialChange,
      potentialNormalMinutes: potentialMinutes,
      withdrawnCompensatoryMinutes: usedCompensatoryMinutes,
    });
    result.push(entry);
  }
  return result;
}

/**
 * @returns Remuneration group -> Workday data mapping
 */
export function computeWorkdaysSimplifiedWithMeta(
  taskList: readonly Task[],
  punchedInOutArray: readonly PunchInOut[] | null,
  timerMap: ReadonlyMap<string, Timer>,
  orderMap: ReadonlyMap<string, Order>,
  workTypeIDURLMapping: {readonly [id: string]: WorkTypeUrl},
  machineIDURLMapping: {readonly [id: string]: MachineUrl},
  priceGroupIDURLMapping: {readonly [id: string]: PriceGroupUrl},
  settings: Config,
  employeeDefaultRemunerationGroup: string,
  daysAbsenceList: readonly DaysAbsence[],
  hoursAbsenceList: readonly HoursAbsence[],
  dinnerBookingList: readonly DinnerBooking[],
  lunchBookingList: readonly LunchBooking[],
  normalTransportMinutes: number | undefined,
  accomodationAllowanceList: readonly AccomodationAllowance[],
  projectMap: ReadonlyMap<string, Project>,
  priceItemMap: ReadonlyMap<string, PriceItem>,
  normalTransportKilometers: number | undefined,
  outputFromDate: string,
  outputToDate: string,
): Map<string, SimplifiedWorkDayWithMeta[]> {
  const remunerationGroups = new Map<string, RemunerationGroup>();
  Object.entries(settings.remunerationGroups).forEach(([identifier, options]) => {
    const x = buildGroupOptions(
      options as any,
      settings,
      workTypeIDURLMapping,
      machineIDURLMapping,
      priceGroupIDURLMapping,
      daysAbsenceList,
    );
    remunerationGroups.set(identifier, x);
  });

  const commonRemunerationSettings = buildCommonOptions(settings, workTypeIDURLMapping);

  const {remunerationNormalTransportKilometersCountBonusLabel} = settings;

  const workDaysMap = computeWorkdaysFull(
    taskList,
    punchedInOutArray,
    timerMap,
    orderMap,
    projectMap,
    priceItemMap,
    daysAbsenceList,
    normalTransportMinutes,
    employeeDefaultRemunerationGroup,
    remunerationGroups,
    commonRemunerationSettings,
    remunerationNormalTransportKilometersCountBonusLabel,
    normalTransportKilometers,
    accomodationAllowanceList,
  );

  const groupsWorkDays = mapMap(workDaysMap, (workDays) => simplifyWorkDays(workDays, projectMap));

  if (!groupsWorkDays.size) {
    groupsWorkDays.set(employeeDefaultRemunerationGroup, []);
  }

  const groupsWorkDaysWithForcedBreaks = mapMap(
    groupsWorkDays,
    (simplifiedWorkDays, groupIdentifier) => {
      const remunerationGroup = remunerationGroups.get(groupIdentifier);
      if (!remunerationGroup) {
        throw new Error(`no remunerationGroup ${remunerationGroup}`);
      }
      const {forcedUnpaidBreakMinutes} = remunerationGroup;
      if (!forcedUnpaidBreakMinutes) {
        return simplifiedWorkDays;
      } else {
        return simplifiedWorkDays.map((simplifiedWorkDay) => {
          if (simplifiedWorkDay.breakMinutes >= forcedUnpaidBreakMinutes) {
            return simplifiedWorkDay;
          }
          let {breakMinutes} = simplifiedWorkDay;
          console.assert(breakMinutes < forcedUnpaidBreakMinutes);
          const rateMinutes = new Map(simplifiedWorkDay.rateMinutes);
          let {actualWorkMinutes} = simplifiedWorkDay;
          for (let rate: Rate = 0; rate <= MAX_OVERTIME_RATE; rate += 1) {
            console.assert(breakMinutes < forcedUnpaidBreakMinutes);
            const minutes = rateMinutes.get(rate);
            if (minutes) {
              const neededBreakMinutes = forcedUnpaidBreakMinutes - breakMinutes;
              console.assert(neededBreakMinutes);
              const change = Math.min(neededBreakMinutes, minutes);
              rateMinutes.set(rate, minutes - change);
              actualWorkMinutes -= change;
              breakMinutes += change;
              if (breakMinutes === forcedUnpaidBreakMinutes) {
                break;
              }
            }
          }
          return {
            ...simplifiedWorkDay,
            actualWorkMinutes,
            breakMinutes,
            rateMinutes,
          };
        });
      }
    },
  );

  const groupsWorkDaysWithAccomodation = attachAccomodationAllowance(
    groupsWorkDaysWithForcedBreaks,
    accomodationAllowanceList,
    workDaysMap,
    remunerationGroups,
  );

  const result = mapMap(groupsWorkDaysWithAccomodation, (workDays, groupIdentifier) => {
    let dateHasNormalWorkHours = (_date: string): boolean => true;
    const remunerationGroup = remunerationGroups.get(groupIdentifier);
    if (!remunerationGroup) {
      throw new Error(`no remunerationGroup ${groupIdentifier}`);
    }
    if (remunerationGroup.reportIgnoreAbsenceOnHolidays) {
      const {getPoolDateAbsenceCompensatoryNormalHoursMinutes} = getGroupPayrollSetup(
        daysAbsenceList,
        remunerationGroup,
        commonRemunerationSettings,
      );
      dateHasNormalWorkHours = (dateString: string) => {
        const normalHoursMinutes = getPoolDateAbsenceCompensatoryNormalHoursMinutes(
          midnightFromDateString(dateString),
        );
        return normalHoursMinutes > 0;
      };
    }
    const workDaysWithMeta = addMeta(
      workDays,
      daysAbsenceList,
      hoursAbsenceList,
      dinnerBookingList,
      lunchBookingList,
      dateHasNormalWorkHours,
      settings,
      remunerationGroup,
      outputFromDate,
      outputToDate,
    );
    return workDaysWithMeta;
  });
  return result;
}
