import {ProjectUrl, ResourceName, urlToId} from "@co-common-libs/resources";
import {resourceURL} from "frontend-global-config";
import {OrderTableColumnID} from "../connected-order-table";
import {TaskTableColumnID} from "../connected-task-table";
import {getSortOptionOrderArchive} from "./get-sort-option-order-archive";
import {getSortOptionTaskArchive} from "./get-sort-option-task-archive";

export type SortingState<ColumnID extends string> = {
  readonly sortDirection: "ASC" | "DESC";
  readonly sortKey: ColumnID;
};

interface ArchiveDataParams {
  enableTaskReferenceNumber?: boolean;
  fetchFromDate: string | null;
  fetchPage: number;
  fetchRowsPerPage: number;
  fetchTextSearch: string;
  fetchToDate: string | null;
  project?: ProjectUrl;
}

interface TaskArchiveDataParams extends ArchiveDataParams {
  archiveResource: Extract<ResourceName, "task">;
  fetchSortingState: SortingState<TaskTableColumnID>;
}

interface OrderArchiveDataParams extends ArchiveDataParams {
  archiveResource: Extract<ResourceName, "order">;
  fetchSortingState: SortingState<OrderTableColumnID>;
}

export function buildArchiveDataUrl(
  params: OrderArchiveDataParams | TaskArchiveDataParams,
): string {
  const {
    archiveResource,
    enableTaskReferenceNumber,
    fetchFromDate,
    fetchPage,
    fetchRowsPerPage,
    fetchSortingState,
    fetchTextSearch,
    fetchToDate,
    project,
  } = params;

  const taskListURL = resourceURL(archiveResource);

  const sortOptions =
    archiveResource === "task"
      ? getSortOptionTaskArchive(
          fetchSortingState.sortDirection,
          fetchSortingState.sortKey,
          enableTaskReferenceNumber,
        )
      : getSortOptionOrderArchive(fetchSortingState.sortDirection, fetchSortingState.sortKey);

  const queryParams: string[] = [
    `limit=${fetchRowsPerPage}`,
    `offset=${fetchPage * fetchRowsPerPage}`,
    ...sortOptions.map((sortOption) => `sortBy[]=${encodeURIComponent(sortOption)}`),
  ];
  if (fetchFromDate) {
    queryParams.push(`archiveFromDate=${fetchFromDate}`);
  }
  if (fetchToDate) {
    queryParams.push(`archiveToDate=${fetchToDate}`);
  }
  if (fetchTextSearch) {
    queryParams.push(`search=${encodeURIComponent(fetchTextSearch)}`);
  }
  if (project) {
    queryParams.push(`projectId=${urlToId(project)}`);
  }
  return `${taskListURL}archive_search/?${queryParams.join("&")}`;
}
