import {Config} from "@co-common-libs/config";
import {Unit, UnitUrl} from "@co-common-libs/resources";
import {DecimalField, ResponsiveDialog} from "@co-frontend-libs/components";
import {AppState, getCustomerSettings, getUnitLookup} from "@co-frontend-libs/redux";
import {DialogContent, FormControlLabel, Switch} from "@material-ui/core";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import React from "react";
// Allowed for existing code...
// eslint-disable-next-line deprecate/import
import {Cell, Grid} from "react-flexr";
import {FormattedMessage, IntlContext, defineMessages} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {UnitSelect} from "./unit-select";

const messages = defineMessages({
  amountPerTrip: {
    defaultMessage: "Forventet per tur",
    id: "transport-log-dialog.label.amount-per-trip",
  },
  cancel: {
    defaultMessage: "Fortryd",
    id: "dialog.label.cancel",
  },
  haRequired: {
    defaultMessage: "Hektar skal angives:",
    id: "order-instance.label.ha-required",
  },
  kmRequired: {
    defaultMessage: "Kilometer skal angives:",
    id: "order-instance.label.km-required",
  },
  ok: {
    defaultMessage: "OK",
    id: "dialog.label.ok",
  },
  transportLog: {
    defaultMessage: "Transportlog",
    id: "transport-log-dialog.title.transport-log",
  },
  unit: {
    defaultMessage: "Enhed",
    id: "transport-log-dialog.label.unit",
  },
  unitChoice: {
    defaultMessage: "Vælg enhed",
    id: "transport-log-dialog.label.unit-choice",
  },
});

interface TransportLogDialogStateProps {
  customerSettings: Config;
  unitLookup: (url: UnitUrl) => Unit | undefined;
}

interface TransportLogDialogOwnProps {
  amountPerTrip?: number | undefined;
  haRequired?: boolean | undefined;
  kmRequired?: boolean | undefined;
  onCancel: () => void;
  onOk: (data: {
    amountPerTrip: number | null;
    haRequired: boolean;
    kmRequired: boolean;
    relatedUnit: Unit | null;
    unit: string;
  }) => void;
  open: boolean;
  relatedUnit?: Unit | undefined;
  unit?: string | undefined;
}

type TransportLogDialogProps = TransportLogDialogOwnProps & TransportLogDialogStateProps;

interface TransportLogDialogState {
  amountPerTrip: number | null;
  haRequired: boolean;
  kmRequired: boolean;
  relatedUnit: Unit | null;
  unit: string;
}

class TransportLogDialogImpl extends PureComponent<
  TransportLogDialogProps,
  TransportLogDialogState
> {
  state: TransportLogDialogState = {
    amountPerTrip: this.props.amountPerTrip || null,
    haRequired: this.props.haRequired || false,
    kmRequired: this.props.kmRequired || false,
    relatedUnit: this.props.relatedUnit || null,
    unit: this.props.unit || "",
  };
  UNSAFE_componentWillReceiveProps(nextProps: TransportLogDialogProps): void {
    if (nextProps.open && !this.props.open) {
      this.setState({
        amountPerTrip: nextProps.amountPerTrip || null,
        haRequired: nextProps.haRequired || false,
        kmRequired: nextProps.kmRequired || false,
        relatedUnit: nextProps.relatedUnit || null,
        unit: nextProps.unit || "",
      });
    }
  }
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  @bind
  handleOk(): void {
    this.props.onOk({
      amountPerTrip: this.state.amountPerTrip,
      haRequired: this.state.haRequired,
      kmRequired: this.state.kmRequired,
      relatedUnit: this.state.relatedUnit,
      unit: this.state.unit,
    });
  }
  @bind
  handleCancel(): void {
    this.props.onCancel();
  }
  @bind
  handleAmountPerTripChange(newValue: number | null): void {
    this.setState({amountPerTrip: newValue});
  }
  @bind
  handleSelectUnitChange(newValue: UnitUrl): void {
    const unit = this.props.unitLookup(newValue);
    if (unit) {
      this.setState({
        relatedUnit: unit,
        unit: unit.name || unit.symbol,
      });
    }
  }
  @bind
  handleHaRequiredToggle(event: React.ChangeEvent<HTMLInputElement>): void {
    const {checked} = event.target;
    this.setState({haRequired: checked});
  }
  @bind
  handleKmRequiredToggle(event: React.ChangeEvent<HTMLInputElement>): void {
    const {checked} = event.target;
    this.setState({kmRequired: checked});
  }
  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {open} = this.props;
    const okDisabled =
      this.props.customerSettings.sharedTransportLog &&
      !this.props.customerSettings.transportLogUnitPerLocation &&
      !this.state.relatedUnit;
    let unitBlock;
    if (!this.props.customerSettings.transportLogUnitPerLocation) {
      unitBlock = (
        <Grid>
          <Cell palm="12/12">
            <DecimalField
              autoFocus
              fullWidth
              label={formatMessage(messages.amountPerTrip)}
              margin="dense"
              value={this.state.amountPerTrip}
              onChange={this.handleAmountPerTripChange}
            />
          </Cell>
          <UnitSelect
            useGrid
            relatedUnit={this.state.relatedUnit?.url}
            onSelectUnitChange={this.handleSelectUnitChange}
          />
        </Grid>
      );
    }
    const dialogContent = (
      <div>
        {unitBlock}
        <Grid>
          <Cell palm="12/12">
            <FormControlLabel
              control={
                <Switch checked={this.state.haRequired} onChange={this.handleHaRequiredToggle} />
              }
              label={formatMessage(messages.haRequired)}
            />
          </Cell>
          <Cell palm="12/12">
            <FormControlLabel
              control={
                <Switch checked={this.state.kmRequired} onChange={this.handleKmRequiredToggle} />
              }
              label={formatMessage(messages.kmRequired)}
            />
          </Cell>
        </Grid>
        {this.props.customerSettings.sharedTransportLog &&
        !this.props.customerSettings.transportLogUnitPerLocation ? (
          <FormattedMessage defaultMessage="* Krævet" id="transport-log-dialog.label.required" />
        ) : null}
      </div>
    );
    return (
      <ResponsiveDialog
        okDisabled={okDisabled}
        open={open}
        title={formatMessage(messages.transportLog)}
        onCancel={this.handleCancel}
        onOk={this.handleOk}
      >
        <DialogContent>{dialogContent}</DialogContent>
      </ResponsiveDialog>
    );
  }
}

export const TransportLogDialog: React.ComponentType<TransportLogDialogOwnProps> = connect<
  TransportLogDialogStateProps,
  object,
  TransportLogDialogOwnProps,
  AppState
>(
  createStructuredSelector<AppState, TransportLogDialogStateProps>({
    customerSettings: getCustomerSettings,
    unitLookup: getUnitLookup,
  }),
  {},
)(TransportLogDialogImpl);
