import LinkifyIt, {Match} from "linkify-it";
import React from "react";
import tlds from "tlds";

const linkify = new LinkifyIt();
linkify.tlds(tlds);

const phoneLinkDefinition = {
  normalize(match: Match): void {
    match.url = `tel:+45${match.url.replace(/ /g, "")}`;
  },
  validate(text: string, pos: number, _self: LinkifyIt.LinkifyIt): number {
    const moreDigitsMatch = text.substr(pos).match(/^[\d ]*\d/);
    if (moreDigitsMatch) {
      const moreDigitsString = moreDigitsMatch[0];
      const moreDigitsDigitsString = moreDigitsString.replace(/ /g, "");
      const phoneNumberDigits = 8;
      if (moreDigitsDigitsString.length + 1 === phoneNumberDigits) {
        return moreDigitsString.length;
      }
    }
    return 0;
  },
};

linkify.add("0", phoneLinkDefinition);
linkify.add("1", phoneLinkDefinition);
linkify.add("2", phoneLinkDefinition);
linkify.add("3", phoneLinkDefinition);
linkify.add("4", phoneLinkDefinition);
linkify.add("5", phoneLinkDefinition);
linkify.add("6", phoneLinkDefinition);
linkify.add("7", phoneLinkDefinition);
linkify.add("8", phoneLinkDefinition);
linkify.add("9", phoneLinkDefinition);

function stopPropagationOnly(event: React.SyntheticEvent): void {
  event.stopPropagation();
}

interface LinkifyProps {
  children?: React.ReactNode | React.ReactNode[];
}

function process(child: React.ReactNode, childIndex: number): React.ReactNode {
  if (typeof child === "string") {
    const str = child;
    const linkMatches = linkify.match(str);
    if (!linkMatches) {
      return str;
    }
    const result: (JSX.Element | string)[] = [];
    let previousIndex = 0;
    for (const match of linkMatches) {
      const {index: matchIndex, lastIndex: matchLastIndex, url: matchUrl} = match;
      if (matchIndex > previousIndex) {
        result.push(str.substring(previousIndex, matchIndex));
      }
      result.push(
        <a
          key={`linkify-child-#${childIndex}-match-#${matchIndex}`}
          href={matchUrl}
          rel="noreferrer"
          target="_blank"
          onClick={stopPropagationOnly}
        >
          {match.text}
        </a>,
      );
      previousIndex = matchLastIndex;
    }
    if (previousIndex < str.length) {
      result.push(str.substring(previousIndex));
    }
    if (result.length === 1) {
      return result;
    } else {
      return <React.Fragment key={`linkify-child-#${childIndex}`}>{result}</React.Fragment>;
    }
  } else if (
    React.isValidElement(child) &&
    child.props.children &&
    child.type !== "a" &&
    child.type !== "button"
  ) {
    return React.cloneElement(
      child,
      {key: `linkify-child-#${childIndex}`},
      React.Children.map(child.props.children, process),
    );
  } else {
    return child;
  }
}

export function Linkify(props: LinkifyProps): JSX.Element {
  return <React.Fragment>{React.Children.map(props.children, process)}</React.Fragment>;
}
