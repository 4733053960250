import {ReportingSpecification, Task} from "@co-common-libs/resources";

/** productUrl + \0 + ours as keys... */
export function getProductUseCountsFromLogEntries(
  logSpecification: ReportingSpecification,
  task: Pick<
    Task,
    "logSkipped" | "priceItemUses" | "productUses" | "reportingLocations" | "reportingLog"
  >,
): Map<string, number | null> | null {
  if (
    task.logSkipped ||
    (!logSpecification.workplaceData.pickup?.logProducts &&
      !logSpecification.workplaceData.workplace?.logProducts &&
      !logSpecification.workplaceData.delivery?.logProducts)
  ) {
    // product uses not from log
    return null;
  }
  /** productUrl + \0 + ours as keys... */
  const counts = new Map<string, number | null>();
  for (const logEntry of Object.values(task.reportingLog)) {
    if (!logEntry.productUses) {
      continue;
    }
    for (const productUse of Object.values(logEntry.productUses)) {
      const {count, ours, product: url} = productUse;
      const key = `${url}\0${ours}`;
      if (typeof count === "number" && logEntry.type !== "pickup") {
        const oldValue = counts.get(key);
        const newValue = (oldValue || 0) + count;
        if (newValue !== oldValue) {
          counts.set(key, newValue);
        }
      } else if (!counts.has(key)) {
        counts.set(key, null);
      }
    }
  }
  return counts;
}
