import {SettingID} from "@co-common-libs/config";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {
  actions,
  getCurrentUserURL,
  getCustomerSettings,
  getSettingsEntryLookupByIdentifier,
} from "@co-frontend-libs/redux";
import {DialogContent, IconButton} from "@material-ui/core";
import {instanceURL} from "frontend-global-config";
import PencilIcon from "mdi-react/PencilIcon";
import React, {useCallback, useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuid} from "uuid";
import {ColumnSelectionDialog} from "../column-selection-dialog";
import {DragDropReorderIdentifierChips} from "../drag-drop-reorder-identifier-chips";

interface SimpleListColumnsDialogProps<ColumnChoice extends string> {
  columnChoices: readonly ColumnChoice[];
  onClose: () => void;
  open: boolean;
  settingID: SettingID;
}

export function SimpleListColumnsDialog<ColumnChoice extends string>(
  props: SimpleListColumnsDialogProps<ColumnChoice>,
): JSX.Element {
  const {columnChoices, onClose, open, settingID} = props;
  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const customerSettings = useSelector(getCustomerSettings);
  const settingsEntry = settingsEntryLookupByIdentifier(settingID);
  const currentUserURL = useSelector(getCurrentUserURL);
  const dispatch = useDispatch();

  type CurrentSettingValueType = readonly ColumnChoice[];

  const [data, setData] = useState<CurrentSettingValueType>(
    customerSettings[settingID] as CurrentSettingValueType,
  );
  useEffect(() => {
    setData(customerSettings[settingID] as CurrentSettingValueType);
  }, [customerSettings, settingID]);

  const handleSave = useCallback(() => {
    if (settingsEntry) {
      dispatch(
        actions.update(settingsEntry.url, [
          {member: "changedBy", value: currentUserURL},
          {member: "data", value: data},
        ]),
      );
    } else {
      const id = uuid();
      const url = instanceURL("settingEntry", id);
      dispatch(
        actions.create({
          changedBy: currentUserURL,
          data,
          key: settingID,
          url,
        }),
      );
    }
    onClose();
  }, [settingsEntry, onClose, dispatch, currentUserURL, data, settingID]);

  const [columnSelectionDialogOpenWithData, setColumnSelectionDialogOpenWithData] = useState<{
    selected: readonly ColumnChoice[];
  } | null>(null);

  const handleColumnListEditButtonClick = useCallback(() => {
    setColumnSelectionDialogOpenWithData({
      selected: data,
    });
  }, [data]);

  const handleColumnSelectionDialogClose = useCallback(() => {
    setColumnSelectionDialogOpenWithData(null);
  }, []);

  const handleColumnSelectionDialogSave = useCallback(
    (selected: readonly ColumnChoice[]) => {
      setData(selected);
      handleColumnSelectionDialogClose();
    },
    [handleColumnSelectionDialogClose],
  );

  return (
    <>
      <ResponsiveDialog
        fullscreen
        okLabel={<FormattedMessage defaultMessage="Gem" />}
        open={open && !columnSelectionDialogOpenWithData}
        title={<FormattedMessage defaultMessage="Kolonner på listerne" />}
        onCancel={onClose}
        onOk={handleSave}
      >
        <DialogContent>
          <div>
            <DragDropReorderIdentifierChips
              identifiers={data as readonly ColumnChoice[]}
              itemType="simple-list-columns"
              onChange={setData}
            />
            <IconButton onClick={handleColumnListEditButtonClick}>
              <PencilIcon />
            </IconButton>
          </div>
        </DialogContent>
      </ResponsiveDialog>
      <ColumnSelectionDialog<ColumnChoice>
        columns={columnChoices}
        open={!!columnSelectionDialogOpenWithData}
        selected={columnSelectionDialogOpenWithData?.selected}
        onClose={handleColumnSelectionDialogClose}
        onSave={handleColumnSelectionDialogSave}
      />
    </>
  );
}
