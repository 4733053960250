import {Product} from "@co-common-libs/resources";
import {createSelector} from "reselect";
import {getProductArray} from "../resources/selectors";
import {AppState} from "../root-reducer";
import {filterOnActive} from "./utils";

export const getActiveProductArray: (state: AppState) => readonly Product[] = createSelector(
  getProductArray,
  filterOnActive,
);
