import {resourceNameToPath} from "./name";
import {ResourceName} from "./resource-names";

export const urlToId = (url: string): string => {
  // resource URLs end with /{id}/; extract the id...
  const lastCharacterPosition = url.length - 1;
  const index = url.lastIndexOf("/", lastCharacterPosition - 1);
  const afterSlash = index + 1;
  const id = url.substring(afterSlash, url.length - 1);
  return id;
};
const lastPart = new RegExp("[^/]*/$");
export const getListURL = (url: string): string =>
  // URLs end with /$ID/; cut off the $ID/ ...
  url.replace(lastPart, "");
export const resourceURL = (baseURL: string, resourceName: ResourceName): string =>
  `${baseURL}${resourceNameToPath[resourceName]}`;
