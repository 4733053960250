import {CommitEntry, getCommitQueue} from "@co-frontend-libs/redux";
import {getFrontendSentry} from "@co-frontend-libs/utils";
import {SeverityLevel} from "@sentry/browser";
import {store} from "frontend-global-config";

function sendCommitEntryToSentry(
  sentry: ReturnType<typeof getFrontendSentry>,
  message: string,
  severity: SeverityLevel,
  commitEntry: CommitEntry,
  index: number,
  pendingCount: number,
): void {
  sentry.withScope((scope) => {
    scope.setTag("type", commitEntry.status);
    scope.setTag("commitEntryId", `${commitEntry.id}`);
    scope.setTag("queuePosition", index);
    scope.setTag("pendingCount", `${pendingCount}`);
    if (commitEntry.error) {
      scope.setExtra("error", commitEntry.error);
    }
    const {command} = commitEntry;
    scope.setTag("instanceUrl", command.url);
    scope.setTag("action", command.action);
    if (command.action === "CREATE") {
      scope.setExtra("instance", command.instance);
    } else if (command.action === "UPDATE") {
      scope.setExtra("patch", command.patch);
    }
    sentry.captureMessage(message, severity);
  });
}

export function sendCommitQueueToSentry(message: string, severity: SeverityLevel): void {
  // HACK: Accessing Redux store outside system...
  const state = store.getState();
  const commitQueue = getCommitQueue(state);
  const sentry = getFrontendSentry();
  // Separate messages to Sentry per item; primarily to avoid
  // having to consider Sentry payload size limit...
  commitQueue.forEach((commitEntry, index) => {
    sendCommitEntryToSentry(sentry, message, severity, commitEntry, index, commitQueue.length);
  });
}
