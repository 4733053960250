import {useTheme} from "@material-ui/core";
import React from "react";
import {FormattedMessage} from "react-intl";
import {SettingValueDisplayProps} from "../types";

export function DayStartRounding(props: SettingValueDisplayProps): JSX.Element | null {
  const {settingEntry} = props;

  const theme = useTheme();

  if (!settingEntry || !settingEntry.data) {
    return null;
  }

  return (
    <div>
      <span style={{backgroundColor: theme.palette.grey[200], padding: 2}}>
        <FormattedMessage
          defaultMessage="Ved start mellem kl. {rangeStart} og {rangeEnd}, så rund op til {rangeEnd}"
          id="system-setup.label.day-end-rounding"
          values={{
            rangeEnd: settingEntry.data[1],
            rangeStart: settingEntry.data[0],
          }}
        />
      </span>
    </div>
  );
}
