import {PunchInOut} from "@co-common-libs/resources";
import {PunchWorkPeriod, buildEmployeeTimeline} from "@co-common-libs/resources-utils";
import {createSelector} from "reselect";
import {getPunchInOutArray} from "../../resources/selectors";
import {AppState} from "../../root-reducer";
import {getCurrentUserURL} from "../current-user";
import {filterOnEmployee, getWorkPeriodsPerEmployee} from "./helpers";

export type {PunchWorkPeriod};

export {filterOnEmployee};

export const getPunchInOutArrayForCurrentUser: (state: AppState) => readonly PunchInOut[] =
  createSelector(getCurrentUserURL, getPunchInOutArray, filterOnEmployee);

export const getPunchInOutPeriodsForCurrentUser: (state: AppState) => readonly PunchWorkPeriod[] =
  createSelector(getPunchInOutArrayForCurrentUser, buildEmployeeTimeline);

export const getPunchInOutPeriodsPerEmployee: (
  state: AppState,
) => ReadonlyMap<string, readonly PunchWorkPeriod[]> = createSelector(
  getPunchInOutArray,
  getWorkPeriodsPerEmployee,
);
