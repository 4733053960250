import {getProductArray, getProductGroupArray} from "@co-frontend-libs/redux";
import {useTheme} from "@material-ui/core";
import React from "react";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {SettingValueDisplayProps} from "../types";

export function AutoSupplementingProducts(props: SettingValueDisplayProps): JSX.Element | null {
  const {settingEntry} = props;

  const theme = useTheme();

  const productGroupArray = useSelector(getProductGroupArray);
  const productArray = useSelector(getProductArray);

  if (!settingEntry || !settingEntry.data) {
    return null;
  }

  const data = settingEntry.data as {
    readonly [productGroupID: string]: readonly string[];
  };

  const eConomicProductGroupPrefix = "https://restapi.e-conomic.com/product-groups/";

  const lines = Object.entries(data).map(([groupID, productIDs]) => {
    const group = productGroupArray.find((g) => {
      if (!g.remoteUrl) {
        return false;
      }
      const productGroupID = g.remoteUrl.startsWith(eConomicProductGroupPrefix)
        ? g.remoteUrl.substring(eConomicProductGroupPrefix.length)
        : g.remoteUrl;
      return productGroupID === groupID;
    });
    const groupLabel = group?.name || (
      <FormattedMessage
        defaultMessage="Ukendt varegruppe {identifier}"
        id="system-setup.warning.unknown-group"
        values={{identifier: groupID}}
      />
    );
    const productLabels = productIDs.map((productID, index) => {
      const product = productArray.find((p) => p.catalogNumber === productID);
      const productLabel = product?.name || (
        <FormattedMessage
          defaultMessage="Ukendt vare {identifier}"
          id="system-setup.warning.unknown-product"
          values={{identifier: productID}}
        />
      );
      return (
        <span key={productID}>
          {productLabel}
          {index < productIDs.length - 1 ? ", " : ""}
        </span>
      );
    });
    return (
      <span key={groupID}>
        {groupLabel}: {productLabels}
        <br />
      </span>
    );
  });

  return (
    <div>
      <span style={{backgroundColor: theme.palette.grey[200], padding: 2}}>{lines}</span>
    </div>
  );
}
