import {isNoDefaultEnterPress} from "@co-frontend-libs/utils";
import {
  AppBar,
  Button,
  ButtonBaseActions,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogTitle,
  Theme,
  Toolbar,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import bowser from "bowser";
import React, {useCallback, useEffect, useRef} from "react";
import {FormattedMessage} from "react-intl";
import {SlideUpTransition} from "./slide-up-transition";

const closeTitleSpacing = 2;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative",
    },
    title: {
      flex: 1,
      marginLeft: theme.spacing(closeTitleSpacing),
    },
  }),
);

interface ResponsiveInfoDialogProps {
  autoFocusClose?: boolean;
  children?: React.ReactNode;
  closeLabel?: JSX.Element | string;
  enterHandling?: boolean;
  fullscreen?: boolean;
  onClose: () => void;
  open: boolean;
  title: JSX.Element | string;
}

// NTS: React.memo here is pointless; expected use is with children
// specified with JSX, which always give new instances...
function ResponsiveInfoDialog(props: ResponsiveInfoDialogProps): JSX.Element {
  const {autoFocusClose, children, closeLabel, enterHandling, fullscreen, onClose, open, title} =
    props;

  const classes = useStyles();

  const handleKeyPress = useCallback(
    (event: KeyboardEvent): void => {
      if (isNoDefaultEnterPress(event)) {
        onClose();
      }
    },
    [onClose],
  );

  useEffect(() => {
    if (!open || enterHandling === false) {
      return undefined;
    }
    window.addEventListener("keypress", handleKeyPress);
    return () => {
      window.removeEventListener("keypress", handleKeyPress);
    };
  }, [enterHandling, handleKeyPress, open]);

  const focusButtonRef = useRef<ButtonBaseActions | null>(null);

  const focusActionCallback = useCallback((instance: ButtonBaseActions | null) => {
    if (instance !== focusButtonRef.current) {
      focusButtonRef.current = instance;
      if (instance) {
        // autoFocus "works"; it does set focus;
        // but this is necessary to also show the visual indication...
        instance.focusVisible();
      }
    }
  }, []);

  const fullscreenLayout = fullscreen ?? !!(bowser.mobile || bowser.tablet);
  if (fullscreenLayout) {
    return (
      <Dialog fullScreen open={open} TransitionComponent={SlideUpTransition} onClose={onClose}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography className={classes.title} variant="h6">
              {title}
            </Typography>
            <Button color="inherit" onClick={onClose}>
              {closeLabel ?? <FormattedMessage defaultMessage="OK" id="dialog.label.ok" />}
            </Button>
          </Toolbar>
        </AppBar>
        {children}
      </Dialog>
    );
  } else {
    const closeButtonFocusProps: Partial<ButtonProps> = {};
    if (autoFocusClose) {
      closeButtonFocusProps.action = focusActionCallback;
      closeButtonFocusProps.autoFocus = true;
    }

    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{title}</DialogTitle>
        {children}
        <DialogActions>
          <Button {...closeButtonFocusProps} color="primary" onClick={onClose}>
            {closeLabel ?? <FormattedMessage defaultMessage="OK" id="dialog.label.ok" />}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export {ResponsiveInfoDialog};
