import {IntlShape} from "react-intl";
import {EconomicCustomerIssue} from "../types";

// eslint-disable-next-line consistent-return
export function economicCustomerIssueTranslation(
  issue: EconomicCustomerIssue,
  intl: IntlShape,
): string {
  const {instance, issueType} = issue;
  const {name} = instance;

  switch (issueType) {
    case "missingRemoteUrl":
      return intl.formatMessage(
        {
          defaultMessage: "Kunden {name} er ny. Kræver godkendelse",
        },
        {name},
      );
    case "missingRemoteIdentifier":
      return intl.formatMessage(
        {
          defaultMessage: "Kunden {name} mangler kundenummer",
        },
        {name},
      );
    case "barred":
      return intl.formatMessage(
        {
          defaultMessage: "Kunden {name} er blevet slettet eller spærret i e-conomic",
        },
        {name},
      );
    case "inactive":
      return intl.formatMessage(
        {
          defaultMessage: "Kunden {name} er markeret inaktiv",
        },
        {name},
      );
  }
}
