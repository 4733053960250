import {TaskUrl, urlToId} from "@co-common-libs/resources";
import {SECOND_MILLISECONDS, notUndefined} from "@co-common-libs/utils";
import {Check, Query, makeQuery} from "@co-frontend-libs/db-resources";
import {
  actions,
  getCommitQueue,
  getLastFetchChangesTimestamp,
  getPathName,
  getQueriesSyncedState,
  getTaskArray,
  getTaskLookup,
} from "@co-frontend-libs/redux";
import {jsonFetch, setReducer} from "@co-frontend-libs/utils";
import {Tab, Tabs} from "@material-ui/core";
import {useIsMounted, usePrevious} from "@react-hookz/web";
import {MenuToolbar, PageLayout} from "app-components";
import {useDeviceConfig, useQueryParameter} from "app-utils";
import bowser from "bowser";
import {globalConfig} from "frontend-global-config";
import _ from "lodash";
import React, {useCallback, useEffect, useMemo, useReducer, useRef, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {useEconomicSyncTasks} from "../hooks/use-economic-sync-tasks";
import {useEconomicTasksErrors} from "../hooks/use-economic-tasks-errors";
import {EconomicDownloadTab} from "./economic-download-tab";
import {EconomicInvoicingTab} from "./economic-invoicing-tab";

const CHECK_SECONDS = 5;
const CHECK_MILLISECONDS = CHECK_SECONDS * SECOND_MILLISECONDS;

const TEMPORARY_QUERIES_KEY = "EconomicSync";
const TEMPORARY_DONE_QUERIES_KEY = "EconomicSyncDone";

function computeInitialToRecord(data: {
  legalToRecordFromTaskMembers: ReadonlySet<TaskUrl>;
  toRecordFromTaskMembers: ReadonlySet<TaskUrl>;
  toRecordStored: readonly string[];
}): ReadonlySet<TaskUrl> {
  const {legalToRecordFromTaskMembers, toRecordFromTaskMembers, toRecordStored} = data;

  const toRecord = new Set(toRecordStored as readonly TaskUrl[]);
  for (const taskUrl of toRecord) {
    if (!legalToRecordFromTaskMembers.has(taskUrl)) {
      toRecord.delete(taskUrl);
    }
  }
  for (const taskUrl of toRecordFromTaskMembers) {
    toRecord.add(taskUrl);
  }
  return toRecord;
}

interface EconomicInvoicingProps {
  onMenuButton: (event: React.MouseEvent) => void;
}

export const EconomicInvoicing = React.memo(function EconomicInvoicing(
  props: EconomicInvoicingProps,
): JSX.Element {
  const {onMenuButton} = props;

  const taskLookup = useSelector(getTaskLookup);
  const taskArray = useSelector(getTaskArray);

  const dispatch = useDispatch();
  const isMounted = useIsMounted();

  const lastFetchChangesTimestamp = useSelector(getLastFetchChangesTimestamp);

  const [downloadDoneTimestamp, setDownloadDoneTimestamp] = useState<string | null>(null);
  const [downloadError, setDownloadError] = useState<Error | null>(null);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [downloadStartTimestamp, setDownloadStartTimestamp] = useState<string | null>(null);
  const [query, setQuery] = useState<Query | null>(null);
  const [resultQuery, setResultQuery] = useState<Query | null>(null);

  const [uploadDoneTimestamp, setUploadDoneTimestamp] = useState<string | null>(null);
  const [uploadStartTimestamp, setUploadStartTimestamp] = useState<string | null>(null);
  const [uploadError, setUploadError] = useState<Error | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);
  const uploadingRef = useRef(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);

  const pathName = useSelector(getPathName);
  const queriesSyncedState = useSelector(getQueriesSyncedState);
  const resultQueryState = resultQuery && queriesSyncedState[resultQuery.keyString]?.queryState;

  const validatedTasks = useEconomicSyncTasks({
    includeReportApproved: false,
  });
  const validatedTasksWithErrors = useEconomicTasksErrors(validatedTasks, "bookkeeping");
  const [downloadAndInvalidateTasksWithErrors, setDownloadAndInvalidateTasksWithErrors] =
    useState<boolean>(false);
  const commitQueue = useSelector(getCommitQueue);
  const commitQueuePendingCount = commitQueue.filter(
    ({status}) =>
      status === "PROMISE" ||
      status === "UNSAVED" ||
      status === "SAVING_LOCALLY" ||
      status === "SAVED_LOCALLY",
  ).length;
  const commitQueueIsEmpty = commitQueuePendingCount === 0;

  const fetchingResultQuery = !!resultQueryState && resultQueryState.currentlyFullFetching;

  useEffect(() => {
    if (!fetchingResultQuery) {
      setResultQuery(null);
    }
  }, [fetchingResultQuery]);

  useEffect(() => {
    const taskCheck: Check = {
      checks: [
        {
          memberName: "recordedInC5",
          type: "memberFalsy",
        },
        {
          memberName: "validatedAndRecorded",
          type: "memberTruthy",
        },
      ],
      type: "and",
    };
    const tmpQuery = makeQuery({
      check: taskCheck,
      filter: {
        notRecordedInC5: "true",
        validatedAndRecordedOnly: "true",
      },
      independentFetch: true,
      resourceName: "task",
    });
    dispatch(actions.temporaryQueriesRequestedForPath([tmpQuery], pathName, TEMPORARY_QUERIES_KEY));
    setQuery(tmpQuery);
  }, [dispatch, pathName]);

  const [toRecordStored, setToRecordStored, clearToRecordStored] = useDeviceConfig<
    readonly string[]
  >("toRecordInEconomic", []);

  const toRecordFromTaskMembers = useMemo(
    () =>
      new Set(
        taskArray.filter(({validatedAndRecorded}) => validatedAndRecorded).map(({url}) => url),
      ),
    [taskArray],
  );

  const previousToRecordFromTaskMembers = usePrevious(toRecordFromTaskMembers);

  const legalToRecordFromTaskMembers = useMemo(
    () =>
      new Set(
        taskArray
          .filter(
            (task) =>
              !task.recordedInC5 &&
              !task.archivable &&
              // include validatedAndRecorded without reportApproved for transition
              (task.reportApproved || task.validatedAndRecorded),
          )
          .map(({url}) => url),
      ),
    [taskArray],
  );

  const [toRecord, dispatchToRecord] = useReducer(
    setReducer<TaskUrl>,
    {legalToRecordFromTaskMembers, toRecordFromTaskMembers, toRecordStored},
    computeInitialToRecord,
  );

  const legalToRecordFromTaskMembersTasks = useMemo(
    () => Array.from(legalToRecordFromTaskMembers).map(taskLookup).filter(notUndefined),
    [legalToRecordFromTaskMembers, taskLookup],
  );

  const economicTaskErrors = useEconomicTasksErrors(
    legalToRecordFromTaskMembersTasks,
    "bookkeeping",
  );

  // update local DB from changes to selection
  useEffect(() => {
    const newToRecordArray = Array.from(toRecord);
    if (!_.isEqual(newToRecordArray, toRecordStored)) {
      if (newToRecordArray.length) {
        setToRecordStored(newToRecordArray);
      } else {
        clearToRecordStored();
      }
    }
  }, [clearToRecordStored, setToRecordStored, toRecord, toRecordStored]);

  // cleanup after tasks are recorded or unapproved or observe errors
  useEffect(() => {
    const toDeselect = Array.from(toRecord).filter(
      (url) => !legalToRecordFromTaskMembers.has(url) || economicTaskErrors.has(url),
    );
    if (toDeselect.length) {
      dispatchToRecord({type: "removeMultiple", values: new Set(toDeselect)});
    }
  }, [economicTaskErrors, legalToRecordFromTaskMembers, toRecord]);

  // add to selected for sync from sync started on other device
  // (or `validatedAndRecorded` set from device not (yet) using two-step validation)
  useEffect(() => {
    if (!previousToRecordFromTaskMembers) {
      return;
    }
    const tasksBecameValidatedAndRecorded = Array.from(toRecordFromTaskMembers).filter(
      (url) => !previousToRecordFromTaskMembers.has(url),
    );
    if (tasksBecameValidatedAndRecorded.length) {
      // Tasks set validated and recorded from elsewhere; update checked state.
      dispatchToRecord({
        type: "addMultiple",
        values: new Set(tasksBecameValidatedAndRecorded),
      });
    }
  }, [previousToRecordFromTaskMembers, toRecordFromTaskMembers]);

  useEffect(() => {
    let uploadCheckTimeoutID: number | null = null;
    let cancelled = false;
    const handleCheckUpload = (): void => {
      if (cancelled) {
        return;
      }
      const {baseURL} = globalConfig.resources;
      const url = `${baseURL}economic/request_upload/`;
      jsonFetch(url, "GET")
        .then((response) => {
          if (cancelled) {
            return;
          }
          const {data} = response;
          const {doneTimestamp, error, progress, startTimestamp} = data;
          setUploadError(error);
          setUploadDoneTimestamp(doneTimestamp);
          setUploadStartTimestamp(startTimestamp);
          setUploadProgress(progress || 0);
          const uploadIsNotCompleted = !!startTimestamp && !doneTimestamp;
          const errorEncountered = !!error;
          if (
            uploadingRef.current &&
            uploadIsNotCompleted !== uploadingRef.current &&
            !errorEncountered
          ) {
            const tmpQuery = makeQuery({
              check: {
                memberName: "recordedInC5",
                type: "memberGte",
                value: startTimestamp,
              },
              filter: {
                withErrorOrRecordedInC5FromTimestamp: startTimestamp,
              },
              independentFetch: true,
              resourceName: "task",
            });
            dispatch(
              actions.temporaryQueriesRequestedForPath(
                [tmpQuery],
                pathName,
                TEMPORARY_DONE_QUERIES_KEY,
              ),
            );
            setResultQuery(tmpQuery);
          }
          uploadingRef.current = uploadIsNotCompleted;
          setUploading(uploadIsNotCompleted && !errorEncountered);
          return;
        })
        .catch((reason) => {
          if (cancelled) {
            return;
          }
          setUploadError(reason);
          setUploading(false);
          setUploadDoneTimestamp(null);
          setUploadStartTimestamp(null);
          return;
        })
        .then(() => {
          if (!cancelled) {
            uploadCheckTimeoutID = window.setTimeout(handleCheckUpload, CHECK_MILLISECONDS);
          }
          return;
        })
        .catch(() => {
          // impossible
        });
    };
    handleCheckUpload();
    return () => {
      if (uploadCheckTimeoutID) {
        window.clearTimeout(uploadCheckTimeoutID);
      }
      cancelled = true;
    };
  }, [dispatch, pathName]);

  useEffect(() => {
    let downloadCheckTimeoutID: number | null = null;
    let cancelled = false;
    const handleCheckDownload = (): void => {
      const {baseURL} = globalConfig.resources;
      const url = `${baseURL}economic/request_download/`;
      jsonFetch(url, "GET")
        .then((response) => {
          if (cancelled) {
            return;
          }
          const {data} = response;
          const {doneTimestamp, error, startTimestamp} = data;
          if (downloading && !!doneTimestamp) {
            dispatch(actions.requestChangesFetch());
          }
          setDownloadDoneTimestamp(doneTimestamp);
          setDownloadError(error);
          setDownloading(!!startTimestamp && !doneTimestamp);
          setDownloadStartTimestamp(startTimestamp);
          if (error) {
            setDownloadAndInvalidateTasksWithErrors(false);
          }
          return;
        })
        .catch((reason) => {
          if (cancelled) {
            return;
          }
          setDownloadDoneTimestamp(null);
          setDownloadError(reason);
          setDownloading(false);
          setDownloadStartTimestamp(null);
          setDownloadAndInvalidateTasksWithErrors(false);
          return;
        })
        .then(() => {
          if (!cancelled) {
            downloadCheckTimeoutID = window.setTimeout(handleCheckDownload, CHECK_MILLISECONDS);
          }
          return;
        })
        .catch(() => {
          // impossible
        });
    };
    handleCheckDownload();
    return () => {
      if (downloadCheckTimeoutID) {
        window.clearTimeout(downloadCheckTimeoutID);
      }
      cancelled = true;
    };
  }, [dispatch, downloading]);

  const handleUploadToEconomic = useCallback(() => {
    const {baseURL} = globalConfig.resources;
    const url = `${baseURL}economic/request_upload/ids/`;
    setUploadError(null);
    setUploading(true);
    uploadingRef.current = true;
    jsonFetch(url, "POST", {ids: Array.from(toRecord).map(urlToId)})
      .then((response) => {
        if (!isMounted()) {
          return;
        }

        const {data} = response;
        const {doneTimestamp, error, progress, startTimestamp} = data;

        setUploadError(error);
        setUploadDoneTimestamp(doneTimestamp);
        setUploadStartTimestamp(startTimestamp);
        setUploadProgress(progress || 0);
        const uploadIsNotCompleted = !!startTimestamp && !doneTimestamp;
        setUploading(uploadIsNotCompleted);
        uploadingRef.current = uploadIsNotCompleted;

        return;
      })
      .catch((reason) => {
        if (!isMounted()) {
          return;
        }
        setUploadDoneTimestamp(null);
        setUploadStartTimestamp(null);
        setUploadError(reason);
        setUploading(false);
        setDownloadAndInvalidateTasksWithErrors(false);
      });
  }, [isMounted, toRecord]);

  useEffect(() => {
    const doneDownloading = !downloading && !downloadError;

    const haveFetchedChangesAfterDownload =
      lastFetchChangesTimestamp &&
      downloadDoneTimestamp &&
      lastFetchChangesTimestamp > downloadDoneTimestamp;

    // There may have been errors detected on tasks after downloading from economic.
    // When errors are detected, a useEffect inside economic-invoicing-tab will
    // patch validatedAndRecorded to false on tasks with errors.
    // We need to ensure that those changes have been successfully saved on the server before
    // attempting to upload to economic.
    const doneCommittingTaskChanges = !validatedTasksWithErrors.size && commitQueueIsEmpty;

    if (
      downloadAndInvalidateTasksWithErrors &&
      doneDownloading &&
      haveFetchedChangesAfterDownload &&
      doneCommittingTaskChanges
    ) {
      setDownloadAndInvalidateTasksWithErrors(false);
      handleUploadToEconomic();
    }
  }, [
    commitQueueIsEmpty,
    downloadDoneTimestamp,
    downloadError,
    downloadStartTimestamp,
    downloadAndInvalidateTasksWithErrors,
    downloading,
    handleUploadToEconomic,
    lastFetchChangesTimestamp,
    validatedTasksWithErrors,
    validatedTasksWithErrors.size,
  ]);

  const handleDownloadFromEconomic = useCallback(() => {
    const {baseURL} = globalConfig.resources;
    const url = `${baseURL}economic/request_download/`;
    setDownloadError(null);
    setDownloading(true);
    jsonFetch(url, "POST")
      .then((response) => {
        if (!isMounted()) {
          return;
        }
        const {data} = response;
        const {doneTimestamp, startTimestamp} = data;
        setDownloadError(null);
        setDownloading(!!startTimestamp && !doneTimestamp);
        setDownloadStartTimestamp(startTimestamp);
        setDownloadDoneTimestamp(doneTimestamp);
        return;
      })
      .catch((reason) => {
        if (!isMounted()) {
          return;
        }
        setDownloadDoneTimestamp(null);
        setDownloadError(reason);
        setDownloading(false);
        setDownloadStartTimestamp(null);
        setDownloadAndInvalidateTasksWithErrors(false);
      });
  }, [isMounted]);

  const handleDownloadThenUploadToEconomic = useCallback(() => {
    setDownloadAndInvalidateTasksWithErrors(true);
    handleDownloadFromEconomic();
  }, [handleDownloadFromEconomic]);

  let content: JSX.Element | null = null;

  const activeTab = useQueryParameter("tab", "upload");
  const handleTabChange = useCallback(
    (_event: React.ChangeEvent<unknown>, value: string): void => {
      dispatch(actions.putQueryKey("tab", value));
    },
    [dispatch],
  );

  if (activeTab === "upload") {
    content = (
      <EconomicInvoicingTab
        dispatchToRecord={dispatchToRecord}
        downloadError={downloadError}
        downloading={downloading}
        query={query}
        toRecord={toRecord}
        uploadDoneTimestamp={uploadDoneTimestamp}
        uploadError={uploadError}
        uploading={
          downloading || downloadAndInvalidateTasksWithErrors || uploading || fetchingResultQuery
        }
        uploadProgress={uploadProgress}
        uploadStartTimestamp={uploadStartTimestamp}
        onDownloadFromEconomic={handleDownloadFromEconomic}
        onUploadToEconomic={handleDownloadThenUploadToEconomic}
      />
    );
  } else {
    content = (
      <EconomicDownloadTab
        downloadDoneTimestamp={downloadDoneTimestamp}
        downloadError={downloadError}
        downloading={downloading}
        downloadStartTimestamp={downloadStartTimestamp}
        onDownloadFromEconomic={handleDownloadFromEconomic}
      />
    );
  }

  return (
    <PageLayout
      withBottomScrollPadding
      tabs={
        <Tabs
          value={activeTab}
          variant={bowser.mobile ? "fullWidth" : "standard"}
          onChange={handleTabChange}
        >
          <Tab
            disabled={downloading}
            label={
              <FormattedMessage
                defaultMessage="Overfør til e-conomic"
                id="economic-invoicing.upload"
              />
            }
            value="upload"
          />
          <Tab
            disabled={uploading}
            label={
              <FormattedMessage
                defaultMessage="Hent data fra e-conomic"
                id="economic-invoicing.download"
              />
            }
            value="download"
          />
        </Tabs>
      }
      toolbar={
        <MenuToolbar
          title={
            <FormattedMessage
              defaultMessage="e-conomic sync"
              id="economic-invoicing.e-conomic-sync"
            />
          }
          onMenuButton={onMenuButton}
        />
      }
    >
      <div style={{padding: "1em"}}>{content}</div>
    </PageLayout>
  );
});
