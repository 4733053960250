import {urlToId} from "@co-common-libs/resources";
import {getNormalisedDeviceTimestamp} from "@co-common-libs/resources-utils";
import {SECOND_MILLISECONDS, getMinuteString} from "@co-common-libs/utils";
import {Check, Query, makeQuery} from "@co-frontend-libs/db-resources";
import {
  actions,
  getPathName,
  getPositionArray,
  getSyncedState,
  getTimerLookup,
  getTimerStartLookup,
} from "@co-frontend-libs/redux";
import {CircularProgress} from "@material-ui/core";
import _ from "lodash";
import React, {useEffect, useMemo, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {PositionTable} from "../position-list";

interface GeolocationTabProps {
  taskURL: string;
}

const TEMPORARY_QUERIES_KEY = "GeolocationTab";

export const GeolocationTab = React.memo(function GeolocationTab(
  props: GeolocationTabProps,
): JSX.Element {
  const {taskURL} = props;

  const [query, setQuery] = useState<Query | null>(null);

  const dispatch = useDispatch();

  const timerStartLookup = useSelector(getTimerStartLookup);
  const timerLookup = useSelector(getTimerLookup);
  const positionArray = useSelector(getPositionArray);

  const pathName = useSelector(getPathName);

  const getPositionSyncedState = useMemo(() => getSyncedState.bind(null, "position"), []);
  const positionSyncedState = useSelector(getPositionSyncedState);

  const [now, setNow] = useState(new Date());
  const minuteString = getMinuteString(now);

  useEffect(() => {
    const positionCheck: Check = {
      memberName: "task",
      type: "memberEq",
      value: taskURL,
    };
    const positionQuery = makeQuery({
      check: positionCheck,
      filter: {
        anticache: minuteString,
        task: urlToId(taskURL),
      },
      independentFetch: true,
      resourceName: "position",
    });
    const timerStartQuery = makeQuery({
      check: {
        check: positionCheck,
        fromResource: "position",
        memberName: "timerStart",
        type: "targetOfForeignKey",
      },
      independentFetch: false,
      resourceName: "timerStart",
    });
    dispatch(
      actions.temporaryQueriesRequestedForPath(
        [positionQuery, timerStartQuery],
        pathName,
        TEMPORARY_QUERIES_KEY,
      ),
    );
    setQuery(positionQuery);
  }, [dispatch, minuteString, pathName, taskURL]);

  useEffect(() => {
    const markerRefreshIntervalSeconds = 10;

    const refreshPositions = (): void => {
      setNow(new Date());
    };
    const intervalID = window.setInterval(
      refreshPositions,
      markerRefreshIntervalSeconds * SECOND_MILLISECONDS,
    );
    return () => {
      window.clearInterval(intervalID);
    };
  }, []);

  let message: JSX.Element | undefined;
  let spinner: JSX.Element | undefined;

  if (query) {
    const querySyncedState = positionSyncedState.get(query.keyString);
    if (!querySyncedState || querySyncedState.queryState.currentlyFullFetching) {
      message = <FormattedMessage defaultMessage="Henter fra arkiv" />;
      spinner = <CircularProgress />;
    } else {
      const lastTimestamp = querySyncedState.queryState.fullFetchDataComputedAtTimestamp as string;
      const {lastErrorTimestamp} = querySyncedState.queryState;
      if (lastErrorTimestamp && lastErrorTimestamp > lastTimestamp) {
        message = <FormattedMessage defaultMessage="Fejl ved adgang til arkiv" />;
      }
    }
  }

  const positions = _.sortBy(
    positionArray.filter((p) => p.task === taskURL),
    getNormalisedDeviceTimestamp,
  ).map((position) => {
    const timerStart = position.timerStart ? timerStartLookup(position.timerStart) : null;
    const timer = timerStart?.timer ? timerLookup(timerStart.timer) : undefined;
    return {
      latitude: position.latitude,
      longitude: position.longitude,
      precission: position.accuracy,
      timer,
      timestamp: position.deviceTimestamp,
      url: position.url,
    };
  });
  return (
    <>
      <PositionTable positions={positions} />
      {message ? (
        <div style={{padding: 8, textAlign: "center"}}>
          <div>{message}</div>
          {spinner}
        </div>
      ) : null}
    </>
  );
});
