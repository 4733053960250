import {MemberPatchOperation, Patch, ResourceInstance} from "@co-common-libs/resources";
import _ from "lodash";

export const computePatch = <T extends ResourceInstance>(
  instance: T,
  oldInstance: T,
): Patch<T> | null => {
  const patch: MemberPatchOperation<T>[] = [];
  (
    (Object.keys(instance) as (keyof T)[]).filter(
      (key) => key !== "url" && key !== "id",
    ) as Exclude<keyof T, "id" | "url">[]
  ).forEach((key) => {
    const newValue = instance[key];
    const oldValue = oldInstance[key];
    if (!_.isEqual(newValue, oldValue)) {
      patch.push({member: key, value: newValue});
    }
  });
  if (patch.length) {
    return patch;
  } else {
    return null;
  }
};
