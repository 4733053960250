export function limitConcurrentPromises<Result, Args extends any[]>(
  concurrentAllowed: number,
  promiseFactory: (...args: Args) => Promise<Result>,
): (...args: Args) => Promise<Result> {
  const pending: {
    args: Args;
    reject: (reason?: any) => void;
    resolve: (value: Result) => void;
  }[] = [];

  let active = 0;

  const startPendingIfBelowLimit = (): void => {
    if (active >= concurrentAllowed) {
      return;
    }
    const entry = pending.shift();
    if (!entry) {
      return;
    }
    const {args, reject, resolve} = entry;
    // eslint-disable-next-line promise/catch-or-return
    promiseFactory(...args).then(
      (value) => {
        active -= 1;
        startPendingIfBelowLimit();
        resolve(value);
        return;
      },
      (reason) => {
        active -= 1;
        startPendingIfBelowLimit();
        reject(reason);
        return;
      },
    );
    active += 1;
  };

  return (...args: Args): Promise<Result> => {
    return new Promise<Result>((resolve, reject) => {
      pending.push({
        args,
        reject,
        resolve,
      });
      startPendingIfBelowLimit();
    });
  };
}
