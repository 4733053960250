import {caseAccentInsensitiveCollator} from "@co-common-libs/utils";
import {ColumnSpecifications, RowData, SortDirection} from "./types";

function getComparator<
  FieldID extends string,
  ColumnID extends string,
  KeyType extends string = string,
  DataType extends RowData<FieldID, KeyType> = RowData<FieldID, KeyType>,
>(
  sortColumn: ColumnSpecifications<FieldID, ColumnID, KeyType, DataType>[ColumnID],
): (a: DataType, b: DataType) => number {
  if (sortColumn.comparator) {
    return sortColumn.comparator;
  } else {
    const sortByField = sortColumn.sortField || sortColumn.field;
    const comparator = (a: DataType, b: DataType): number => {
      const aValue = a[sortByField];
      const bValue = b[sortByField];
      if (aValue === bValue) {
        return 0;
      } else if (typeof aValue === "string" && typeof bValue === "string") {
        if (!aValue) {
          // aValue === bValue handled previously; they're not both blank...
          return 1;
        } else if (!bValue) {
          return -1;
        } else {
          return caseAccentInsensitiveCollator.compare(aValue, bValue);
        }
      } else if (aValue === null) {
        return -1;
      } else if (bValue === null) {
        return 1;
      } else if (aValue < bValue) {
        return -1;
      } else if (bValue < aValue) {
        return 1;
      } else {
        return 0;
      }
    };
    return comparator;
  }
}

function sortedByColumn<
  FieldID extends string,
  ColumnID extends string,
  KeyType extends string = string,
  DataType extends RowData<FieldID, KeyType> = RowData<FieldID, KeyType>,
>(
  data: readonly (DataType & {children?: readonly DataType[] | undefined})[],
  sortColumn: ColumnSpecifications<FieldID, ColumnID, KeyType, DataType>[ColumnID],
): (DataType & {children?: readonly DataType[] | undefined})[] {
  const comparator = getComparator(sortColumn);
  const sorted = data.slice();
  sorted.sort(comparator);
  return sorted;
}

export function sortRows<
  FieldID extends string,
  ColumnID extends string,
  KeyType extends string = string,
  DataType extends RowData<FieldID, KeyType> = RowData<FieldID, KeyType>,
>(
  columns: ColumnSpecifications<FieldID, ColumnID, KeyType, DataType>,
  data: readonly (DataType & {children?: readonly DataType[] | undefined})[],
  sortBy: ColumnID | null | undefined,
  sortDirection: SortDirection,
  overrideSortBy: ColumnID | null | undefined,
  groupBy: ColumnID | null | undefined,
): readonly (DataType & {children?: readonly DataType[] | undefined})[] {
  let sorted: typeof data;
  if (sortBy && columns[sortBy]) {
    if (sortDirection === "DESC") {
      sorted = sortedByColumn(data, columns[sortBy]).reverse();
    } else {
      sorted = sortedByColumn(data, columns[sortBy]);
    }
  } else {
    sorted = data;
  }
  if (overrideSortBy && overrideSortBy !== sortBy && columns[overrideSortBy]) {
    sorted = sortedByColumn(sorted, columns[overrideSortBy]);
  }
  if (groupBy && columns[groupBy]) {
    sorted = sortedByColumn(sorted, columns[groupBy]);
  }
  return sorted;
}
