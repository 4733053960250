import {addDays, formatISO, subDays} from "date-fns";

function findEaster(year: number): Date {
  // https://en.wikipedia.org/wiki/Computus#Anonymous_Gregorian_algorithm

  const a = year % 19;
  const b = Math.floor(year / 100);
  const c = year % 100;
  const d = Math.floor(b / 4);
  const e = b % 4;
  const f = Math.floor((b + 8) / 25);
  const g = Math.floor((b - f + 1) / 3);
  const h = (19 * a + b - d - g + 15) % 30;
  const i = Math.floor(c / 4);
  const k = c % 4;
  const l = (32 + 2 * e + 2 * i - h - k) % 7;
  const m = Math.floor((a + 11 * h + 22 * l) / 451);
  const month = Math.floor((h + l - 7 * m + 114) / 31) - 1;
  const day = ((h + l - 7 * m + 114) % 31) + 1;

  return new Date(year, month, day);
}

export function danishHolidaysForYear(year: number): Map<string, string> {
  const entries: {date: Date; text: string}[] = [];

  entries.push({date: new Date(year, 0, 1), text: "Nytårsdag"});
  entries.push({date: new Date(year, 11, 25), text: "Juledag"});
  entries.push({date: new Date(year, 11, 26), text: "2. juledag"});

  const easter = findEaster(year);
  entries.push({date: easter, text: "Påskedag"});
  entries.push({date: subDays(easter, 3), text: "Skærtorsdag"});
  entries.push({date: subDays(easter, 2), text: "Langfredag"});
  entries.push({date: addDays(easter, 1), text: "2. Påskedag"});
  if (year <= 2023) {
    entries.push({date: addDays(easter, 3 * 7 + 5), text: "St. Bededag"});
  }
  entries.push({date: addDays(easter, 5 * 7 + 4), text: "Kr. Himmelfart"});
  entries.push({date: addDays(easter, 7 * 7), text: "Pinsedag"});
  entries.push({date: addDays(easter, 7 * 7 + 1), text: "2. Pinsedag"});

  return new Map(entries.map(({date, text}) => [formatISO(date, {representation: "date"}), text]));
}
