import {Config} from "@co-common-libs/config";
import {Task} from "@co-common-libs/resources";
import {formatDate, formatDateTime} from "@co-common-libs/utils";
import {Card, CardContent, IconButton} from "@material-ui/core";
import {Linkify} from "app-components";
import {PureComponent} from "app-utils";
import PencilIcon from "mdi-react/PencilIcon";
import React from "react";
// Allowed for existing code...
// eslint-disable-next-line deprecate/import
import {Cell, Grid} from "react-flexr";
import {FormattedMessage} from "react-intl";

interface InternalInfoCardProps {
  completedText?: React.ReactNode;
  customerSettings: Config;
  date?: string | undefined;
  onGoToEdit: () => void;
  responsibleInitials?: string | undefined;
  task: Task;
  taskAssignedToUser: boolean;
  time?: string | undefined;
  userInitials?: string | undefined;
  userIsJobber: boolean;
  userIsOtherMachineOperator: boolean;
}

class InternalInfoCard extends PureComponent<InternalInfoCardProps> {
  render(): JSX.Element {
    const {
      completedText,
      customerSettings,
      date,
      responsibleInitials,
      task,
      time,
      userInitials,
      userIsJobber,
      userIsOtherMachineOperator,
    } = this.props;
    const disableTaskEditIconButton =
      userIsOtherMachineOperator ||
      (userIsJobber && !customerSettings.jobberRoleCanCreateInternalTasks);
    return (
      <Card style={{margin: "1em"}}>
        <CardContent>
          <div>
            <IconButton
              disabled={disableTaskEditIconButton}
              style={{float: "right"}}
              onClick={this.props.onGoToEdit}
            >
              <PencilIcon />
            </IconButton>
            <h5 style={{marginBottom: 24}}>
              Oprettet af: {userInitials}
              {customerSettings.taskPageCreatedDate ? `, ${formatDate(task.created)}` : null}
            </h5>
          </div>
          {completedText}
          <Grid>
            <Cell>
              <FormattedMessage defaultMessage="Dato" id="task-instance.label.date" />
              <h2>{formatDate(date)}</h2>
            </Cell>
          </Grid>
          <Grid>
            <Cell>
              <FormattedMessage defaultMessage="Forventet opstart" />
              <h1>{time}</h1>
            </Cell>
          </Grid>
          {customerSettings.enableTaskEstimation && task.arrivalAtLocation ? (
            <Grid>
              <Cell>
                <FormattedMessage
                  defaultMessage="Forventet opstart ved kunden"
                  id="task-instance.label.arrival_at_location"
                />
                <br />
                <h1>{task.arrivalAtLocation}</h1>
              </Cell>
            </Grid>
          ) : null}
          <Grid>
            <Cell>
              <FormattedMessage
                defaultMessage="Opgaveansvarlig"
                id="task-instance.header.responsible"
              />
              <h4>{responsibleInitials}</h4>
            </Cell>
          </Grid>
          <Grid>
            <Cell>
              <FormattedMessage
                defaultMessage="Noter fra administration:"
                id="task-instance.header.notes-from-administration"
                tagName="h4"
              />
              <div style={{whiteSpace: "pre-line"}}>
                <Linkify>{task.notesFromManager}</Linkify>
              </div>
            </Cell>
          </Grid>
          {customerSettings.showCreatedTimestampOnInternalTasks ? (
            <Grid>
              <Cell>
                <FormattedMessage defaultMessage="Oprettet" id="task-instance.label.created" />
                <div>{formatDateTime(task.created)}</div>
              </Cell>
            </Grid>
          ) : null}
        </CardContent>
      </Card>
    );
  }
}

export default InternalInfoCard;
