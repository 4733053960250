import {Patch, ReportingSpecification, WorkplaceRegistration} from "@co-common-libs/resources";
import {
  ConnectedMultipleExternalWorkTypesDialog,
  ConnectedMultipleMachinesDialog,
  ConnectedMultiplePriceGroupDialog,
} from "@co-frontend-libs/connected-components";
import {
  actions,
  getMachineArray,
  getPriceGroupArray,
  getReportingSpecificationArray,
  getWorkTypeArray,
} from "@co-frontend-libs/redux";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import {useEventTargetCheckedUpdater, useFalseCallback, useTrueCallback} from "app-utils";
import PencilIcon from "mdi-react/PencilIcon";
import React, {useCallback, useMemo, useState} from "react";
import {FormattedMessage, defineMessages, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {ReportingIdentifierNameDialog} from "./reporting-identifier-name-dialog";

const messages = defineMessages({
  allowTotalsTable: {
    defaultMessage: "Tillad at Total tabellen vises",
  },
  allowTransportedMaterialCountMismatch: {
    defaultMessage: "Tillad forskel i afhentede og leverede mængder",
    id: "reporting.entry.allow-transported-material-count-mismatch",
  },
  autoInsertAmountInDelivery: {
    defaultMessage: "Indsæt automatisk værdier ved levering",
  },
  autoInsertLastPickupValues: {
    defaultMessage: "Indsæt automatisk værdier fra sidste afhentning i næste afhentning",
    id: "reporting.entry.auto-insert-last-pickup-values",
  },
  autoInsertLastWorkplaceValues: {
    defaultMessage: "Indsæt automatisk værdier fra sidste logregistrering i næste logregistrering",
  },
  customerLogSums: {
    defaultMessage: 'Vis summeret periodisk udskrift under "Kunder"',
    id: "reporting.entry.customer-log-sums",
  },
  displayProductsPerWorkplace: {
    defaultMessage: "Vis produkter pr. arbejdssted",
  },
  hideMaterialsOnPrintout: {
    defaultMessage: "Skjul materiel tabellen på udskrift",
    id: "reporting.entry.hide-materials-on-printout",
  },
  horizontal: {
    defaultMessage: "Udskriv loggen horisontalt",
    id: "reporting.entry.horizontal",
  },
  name: {
    defaultMessage: "Navn",
    id: "reporting.entry.name",
  },
  requireAtLeastOneLogEntry: {
    defaultMessage: "Kræv mindst én logregistrering",
    id: "reporting.entry.require-at-least-one-log-entry",
  },
  sendMailToPickupDeliveryCustomers: {
    defaultMessage: "Send mail til arbejdssteds og mark -kunder",
    id: "reporting.entry.send-mail-to-pickup-delivery-customers",
  },
  shared: {
    defaultMessage: "Delt transportlog",
    id: "reporting.entry.shared",
  },
  showCustomerOnEntryTable: {
    defaultMessage: "Vis kundenavn på logtabbellen på udskrift",
    id: "reporting.entry.show-customer-on-entry-table",
  },
  showLocationMaterialSums: {
    defaultMessage: "Vis summeret materiel for hvert logsted",
    id: "reporting.entry.show-location-material-sums",
  },
  showLocationProducts: {
    defaultMessage: "Vis produkter pr. logsted i tabellen",
    id: "reporting.entry.show-location-products",
  },
  showSharedTotalsTableOnAllOrderTasks: {
    defaultMessage:
      "Delt transportlog: Vis Total tabellen på alle opgaver på ordren (også dem uden log)",
  },
  showTaskTimeOnReport: {
    defaultMessage: "Vis opgavetiden på logudskrift",
    id: "reporting.entry.show-task-time-on-report",
  },
  simpleLocationSumLog: {
    defaultMessage: "Vis simpel transportlog udskrift",
  },
});

export const ReportingSpecificationEditForm = React.memo(function ReportingSpecificationEditForm({
  reportingSpecification,
}: {
  reportingSpecification: ReportingSpecification;
}): JSX.Element {
  const intl = useIntl();
  const [identifierNameDialogOpen, setIdentifierNameDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const reportingIdentifierNameDialogOk = useCallback(
    (identifier: string, name: string): void => {
      setIdentifierNameDialogOpen(false);
      dispatch(
        actions.update(reportingSpecification.url, [
          {member: "identifier", value: identifier},
          {member: "name", value: name},
        ]),
      );
    },
    [dispatch, reportingSpecification.url],
  );

  const handleFieldsUsedForChange = useCallback(
    (
      event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>,
    ) => {
      const value = event.target.value as "delivery" | "pickup" | "unused" | "workplace";
      const patch: Patch<ReportingSpecification> = [{member: "fieldsUsedFor", value}];
      dispatch(actions.update(reportingSpecification.url, patch));
    },
    [dispatch, reportingSpecification.url],
  );

  const reportingSpecificationArray = useSelector(getReportingSpecificationArray);
  const otherReportingIdentifiers = useMemo(
    () =>
      new Set(
        reportingSpecificationArray
          .filter((spec) => spec.url !== reportingSpecification.url && spec.active)
          .map((spec) => spec.identifier),
      ),
    [reportingSpecification.url, reportingSpecificationArray],
  );

  const workTypeArray = useSelector(getWorkTypeArray);
  const machineArray = useSelector(getMachineArray);
  const priceGroupArray = useSelector(getPriceGroupArray);

  const workTypes = useMemo(
    () => workTypeArray.filter((w) => w.reportingSpecification === reportingSpecification.url),
    [reportingSpecification.url, workTypeArray],
  );
  const machines = useMemo(
    () => machineArray.filter((m) => m.reportingSpecification === reportingSpecification.url),
    [machineArray, reportingSpecification.url],
  );
  const priceGroups = useMemo(
    () => priceGroupArray.filter((pg) => pg.reportingSpecification === reportingSpecification.url),
    [priceGroupArray, reportingSpecification.url],
  );

  const [workTypeDialogOpen, setWorkTypeDialogOpen] = useState(false);

  const handleWorkTypeDialogOk = useCallback(
    (urls: ReadonlySet<string>) => {
      workTypeArray.forEach((w) => {
        const shouldUseReportingSpecification = urls.has(w.url);
        const usesReportingSpecification = w.reportingSpecification === reportingSpecification.url;
        if (!usesReportingSpecification && shouldUseReportingSpecification) {
          dispatch(
            actions.update(w.url, [
              {
                member: "reportingSpecification",
                value: reportingSpecification.url,
              },
            ]),
          );
        } else if (usesReportingSpecification && !shouldUseReportingSpecification) {
          dispatch(actions.update(w.url, [{member: "reportingSpecification", value: null}]));
        }
      });

      setWorkTypeDialogOpen(false);
    },
    [dispatch, reportingSpecification.url, workTypeArray],
  );

  const [priceGroupDialogOpen, setPriceGroupDialogOpen] = useState(false);

  const handlePriceGroupDialogOk = useCallback(
    (urls: ReadonlySet<string>) => {
      priceGroupArray.forEach((pg) => {
        const shouldUseReportingSpecification = urls.has(pg.url);
        const usesReportingSpecification = pg.reportingSpecification === reportingSpecification.url;
        if (!usesReportingSpecification && shouldUseReportingSpecification) {
          dispatch(
            actions.update(pg.url, [
              {
                member: "reportingSpecification",
                value: reportingSpecification.url,
              },
            ]),
          );
        } else if (usesReportingSpecification && !shouldUseReportingSpecification) {
          dispatch(actions.update(pg.url, [{member: "reportingSpecification", value: null}]));
        }
      });
      setPriceGroupDialogOpen(false);
    },

    [dispatch, reportingSpecification.url, priceGroupArray],
  );

  const [machineDialogOpen, setMachineDialogOpen] = useState(false);

  const handleMachineDialogOk = useCallback(
    (urls: ReadonlySet<string>) => {
      machineArray.forEach((m) => {
        const shouldUseReportingSpecification = urls.has(m.url);
        const usesReportingSpecification = m.reportingSpecification === reportingSpecification.url;
        if (!usesReportingSpecification && shouldUseReportingSpecification) {
          dispatch(
            actions.update(m.url, [
              {
                member: "reportingSpecification",
                value: reportingSpecification.url,
              },
            ]),
          );
        } else if (usesReportingSpecification && !shouldUseReportingSpecification) {
          dispatch(actions.update(m.url, [{member: "reportingSpecification", value: null}]));
        }
      });
      setMachineDialogOpen(false);
    },
    [dispatch, reportingSpecification.url, machineArray],
  );

  const selectedMachines = useMemo(
    () => new Set(machines.map((machine) => machine.url)),
    [machines],
  );
  const selectedPriceGroups = useMemo(
    () => new Set(priceGroups.map((pg) => pg.url)),
    [priceGroups],
  );
  const selectedWorkTypes = useMemo(() => new Set(workTypes.map((w) => w.url)), [workTypes]);

  return (
    <>
      <Card>
        <div style={{position: "relative"}}>
          <IconButton
            style={{
              position: "absolute",
              right: 16,
              top: 16,
            }}
            onClick={useTrueCallback(setIdentifierNameDialogOpen, [setIdentifierNameDialogOpen])}
          >
            <PencilIcon color="#000" />
          </IconButton>
        </div>
        <CardHeader title={reportingSpecification.name} />
        <CardContent>
          <div>
            <strong>
              <FormattedMessage defaultMessage="ID:" id="reporting.entry.id" />{" "}
            </strong>
            {reportingSpecification.identifier}
          </div>

          <FormControlLabel
            control={
              <Checkbox
                checked={reportingSpecification.requireAtLeastOneLogEntry}
                onChange={useEventTargetCheckedUpdater<ReportingSpecification>(
                  reportingSpecification.url,
                  "requireAtLeastOneLogEntry",
                )}
              />
            }
            disabled={reportingSpecification.allowSkip}
            label={intl.formatMessage(messages.requireAtLeastOneLogEntry)}
          />
          <br />
          <FormControlLabel
            control={
              <Checkbox
                checked={reportingSpecification.allowSkip}
                onChange={useEventTargetCheckedUpdater<ReportingSpecification>(
                  reportingSpecification.url,
                  "allowSkip",
                )}
              />
            }
            disabled={reportingSpecification.requireAtLeastOneLogEntry}
            label={intl.formatMessage({
              defaultMessage: "Tillad at loggen fjernes",
            })}
          />
          <br />
          <FormControlLabel
            control={
              <Checkbox
                checked={reportingSpecification.displayProductsPerWorkplace}
                onChange={useEventTargetCheckedUpdater<ReportingSpecification>(
                  reportingSpecification.url,
                  "displayProductsPerWorkplace",
                )}
              />
            }
            label={intl.formatMessage(messages.displayProductsPerWorkplace)}
          />
          <br />
          <FormControlLabel
            control={
              <Checkbox
                checked={reportingSpecification.showTaskTimeOnReport}
                onChange={useEventTargetCheckedUpdater<ReportingSpecification>(
                  reportingSpecification.url,
                  "showTaskTimeOnReport",
                )}
              />
            }
            label={intl.formatMessage(messages.showTaskTimeOnReport)}
          />
          <br />
          <FormControlLabel
            control={
              <Checkbox
                checked={reportingSpecification.horizontal}
                onChange={useEventTargetCheckedUpdater<ReportingSpecification>(
                  reportingSpecification.url,
                  "horizontal",
                )}
              />
            }
            label={intl.formatMessage(messages.horizontal)}
          />
          <br />
          <FormControlLabel
            control={
              <Checkbox
                checked={!!reportingSpecification.customerLogSums}
                onChange={useEventTargetCheckedUpdater<ReportingSpecification>(
                  reportingSpecification.url,
                  "customerLogSums",
                )}
              />
            }
            label={intl.formatMessage(messages.customerLogSums)}
          />
          <br />
          <FormControlLabel
            control={
              <Checkbox
                checked={!!reportingSpecification.hideMaterialsOnPrintout}
                onChange={useEventTargetCheckedUpdater<ReportingSpecification>(
                  reportingSpecification.url,
                  "hideMaterialsOnPrintout",
                )}
              />
            }
            label={intl.formatMessage(messages.hideMaterialsOnPrintout)}
          />
          <br />
          <FormControlLabel
            control={
              <Checkbox
                checked={!!reportingSpecification.showCustomerOnEntryTable}
                onChange={useEventTargetCheckedUpdater<ReportingSpecification>(
                  reportingSpecification.url,
                  "showCustomerOnEntryTable",
                )}
              />
            }
            label={intl.formatMessage(messages.showCustomerOnEntryTable)}
          />
          <br />
          <FormControlLabel
            control={
              <Checkbox
                checked={reportingSpecification.showMachines}
                onChange={useEventTargetCheckedUpdater<ReportingSpecification>(
                  reportingSpecification.url,
                  "showMachines",
                )}
              />
            }
            label={intl.formatMessage({
              defaultMessage: "Vis anvendte maskiner på sidehoved på log PDF",
            })}
          />
          <br />
          <FormControlLabel
            control={
              <Checkbox
                checked={!!reportingSpecification.showLocationProducts}
                onChange={useEventTargetCheckedUpdater<ReportingSpecification>(
                  reportingSpecification.url,
                  "showLocationProducts",
                )}
              />
            }
            label={intl.formatMessage(messages.showLocationProducts)}
          />
          <br />
          <FormControlLabel
            control={
              <Checkbox
                checked={!!reportingSpecification.sendMailToPickupDeliveryCustomers}
                onChange={useEventTargetCheckedUpdater<ReportingSpecification>(
                  reportingSpecification.url,
                  "sendMailToPickupDeliveryCustomers",
                )}
              />
            }
            label={intl.formatMessage(messages.sendMailToPickupDeliveryCustomers)}
          />
          <br />
          <FormControlLabel
            control={
              <Checkbox
                checked={!!reportingSpecification.showLoadCounts}
                onChange={useEventTargetCheckedUpdater<ReportingSpecification>(
                  reportingSpecification.url,
                  "showLoadCounts",
                )}
              />
            }
            label={intl.formatMessage({
              defaultMessage: "Vis antal læs pr. produkt",
            })}
          />
          <br />
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  reportingSpecification.allowTotalsTable != null
                    ? reportingSpecification.allowTotalsTable
                    : true
                }
                onChange={useEventTargetCheckedUpdater<ReportingSpecification>(
                  reportingSpecification.url,
                  "allowTotalsTable",
                )}
              />
            }
            label={intl.formatMessage(messages.allowTotalsTable)}
          />
          <br />
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  reportingSpecification.displayWorkplaceOverviewOnPDF != null
                    ? reportingSpecification.displayWorkplaceOverviewOnPDF
                    : true
                }
                onChange={useEventTargetCheckedUpdater<ReportingSpecification>(
                  reportingSpecification.url,
                  "displayWorkplaceOverviewOnPDF",
                )}
              />
            }
            label={intl.formatMessage({
              defaultMessage: "Vis tabellen med overblik over arbejdssteder/marker på PDFen",
            })}
          />
          <br />
          <FormControl fullWidth>
            <InputLabel>
              <FormattedMessage
                defaultMessage="Marker bruges til"
                id="reporting.entry.fields-used-for"
              />
            </InputLabel>
            <Select
              fullWidth
              value={reportingSpecification.fieldsUsedFor}
              onChange={handleFieldsUsedForChange}
            >
              <MenuItem value="unused">
                <FormattedMessage defaultMessage="Ubrugt" id="reporting.entry.unused" />
              </MenuItem>
              <MenuItem value="pickup">
                <FormattedMessage defaultMessage="Afhentning" id="reporting.entry.pickup" />
              </MenuItem>
              <MenuItem value="delivery">
                <FormattedMessage defaultMessage="Levering" id="reporting.entry.delivery" />
              </MenuItem>
              <MenuItem value="workplace">
                <FormattedMessage defaultMessage="Arbejdssted" id="reporting.entry.workplace" />
              </MenuItem>
            </Select>
          </FormControl>
        </CardContent>
      </Card>
      <Card style={{marginTop: 16}}>
        <CardHeader
          title={
            <FormattedMessage
              defaultMessage="Kun for transportlogs"
              id="reporting.entry.only-for-transportlog"
            />
          }
        />
        <CardContent>
          <FormControlLabel
            control={
              <Checkbox
                checked={!!reportingSpecification.allowTransportedMaterialCountMismatch}
                onChange={useEventTargetCheckedUpdater<ReportingSpecification>(
                  reportingSpecification.url,
                  "allowTransportedMaterialCountMismatch",
                )}
              />
            }
            disabled={
              reportingSpecification.workplaceRegistration !== WorkplaceRegistration.transportation
            }
            label={intl.formatMessage(messages.allowTransportedMaterialCountMismatch)}
          />
          <br />
          <FormControlLabel
            control={
              <Checkbox
                checked={reportingSpecification.autoInsertAmountInDelivery !== false}
                onChange={useEventTargetCheckedUpdater<ReportingSpecification>(
                  reportingSpecification.url,
                  "autoInsertAmountInDelivery",
                )}
              />
            }
            disabled={
              reportingSpecification.workplaceRegistration !== WorkplaceRegistration.transportation
            }
            label={intl.formatMessage(messages.autoInsertAmountInDelivery)}
          />
          <br />
          <FormControlLabel
            control={
              <Checkbox
                checked={!!reportingSpecification.autoInsertLastPickupValues}
                onChange={useEventTargetCheckedUpdater<ReportingSpecification>(
                  reportingSpecification.url,
                  "autoInsertLastPickupValues",
                )}
              />
            }
            disabled={
              reportingSpecification.workplaceRegistration !== WorkplaceRegistration.transportation
            }
            label={intl.formatMessage(messages.autoInsertLastPickupValues)}
          />
          <br />
          <FormControlLabel
            control={
              <Checkbox
                checked={!!reportingSpecification.shared}
                onChange={useEventTargetCheckedUpdater<ReportingSpecification>(
                  reportingSpecification.url,
                  "shared",
                )}
              />
            }
            disabled={
              reportingSpecification.workplaceRegistration !== WorkplaceRegistration.transportation
            }
            label={intl.formatMessage(messages.shared)}
          />
          <br />
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  reportingSpecification.shared &&
                  reportingSpecification.showSharedTotalsTableOnAllOrderTasks === true
                }
                onChange={useEventTargetCheckedUpdater<ReportingSpecification>(
                  reportingSpecification.url,
                  "showSharedTotalsTableOnAllOrderTasks",
                )}
              />
            }
            disabled={!reportingSpecification.shared}
            label={intl.formatMessage(messages.showSharedTotalsTableOnAllOrderTasks)}
          />
          <br />
          <FormControlLabel
            control={
              <Checkbox
                checked={!!reportingSpecification.showLocationMaterialSums}
                onChange={useEventTargetCheckedUpdater<ReportingSpecification>(
                  reportingSpecification.url,
                  "showLocationMaterialSums",
                )}
              />
            }
            disabled={
              reportingSpecification.workplaceRegistration !== WorkplaceRegistration.transportation
            }
            label={intl.formatMessage(messages.showLocationMaterialSums)}
          />
          <br />
          <FormControlLabel
            control={
              <Checkbox
                checked={!!reportingSpecification.simpleLocationSumLog}
                onChange={useEventTargetCheckedUpdater<ReportingSpecification>(
                  reportingSpecification.url,
                  "simpleLocationSumLog",
                )}
              />
            }
            disabled={
              reportingSpecification.workplaceRegistration !== WorkplaceRegistration.transportation
            }
            label={intl.formatMessage(messages.simpleLocationSumLog)}
          />
          <br />
          <FormControlLabel
            control={
              <Checkbox
                checked={!!reportingSpecification.reportTransport}
                onChange={useEventTargetCheckedUpdater<ReportingSpecification>(
                  reportingSpecification.url,
                  "reportTransport",
                )}
              />
            }
            disabled={
              reportingSpecification.workplaceRegistration !== WorkplaceRegistration.transportation
            }
            label={intl.formatMessage({
              defaultMessage: "Medtag i transport rapport",
            })}
          />
          <br />

          <FormControl component="fieldset">
            <FormLabel component="legend">
              <FormattedMessage
                defaultMessage='Følgende skal overføres til e-conomic faktura, når "Afhentnings- og leveringssted fra generisk log, påføres faktura" er tændt:'
                id="reporting.entry.select-invoice-info"
              />
            </FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!reportingSpecification.invoiceCustomerName}
                    onChange={useEventTargetCheckedUpdater<ReportingSpecification>(
                      reportingSpecification.url,
                      "invoiceCustomerName",
                    )}
                  />
                }
                disabled={
                  reportingSpecification.workplaceRegistration !==
                  WorkplaceRegistration.transportation
                }
                label={
                  <FormattedMessage
                    defaultMessage="Logstedets kunde navn"
                    id="reporting.entry.logentry-customer-name"
                  />
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!reportingSpecification.invoiceCustomerAddress}
                    onChange={useEventTargetCheckedUpdater<ReportingSpecification>(
                      reportingSpecification.url,
                      "invoiceCustomerAddress",
                    )}
                  />
                }
                disabled={
                  reportingSpecification.workplaceRegistration !==
                  WorkplaceRegistration.transportation
                }
                label={
                  <FormattedMessage
                    defaultMessage="Logstedets kunde adresse"
                    id="reporting.entry.logentry-customer-address"
                  />
                }
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!reportingSpecification.invoiceCustomerVatNumber}
                    onChange={useEventTargetCheckedUpdater<ReportingSpecification>(
                      reportingSpecification.url,
                      "invoiceCustomerVatNumber",
                    )}
                  />
                }
                disabled={
                  reportingSpecification.workplaceRegistration !==
                  WorkplaceRegistration.transportation
                }
                label={
                  <FormattedMessage
                    defaultMessage="Logstedets kunde CVR-nr."
                    id="reporting.entry.logentry-customer-vat-number"
                  />
                }
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!reportingSpecification.invoiceWorkplaceName}
                    onChange={useEventTargetCheckedUpdater<ReportingSpecification>(
                      reportingSpecification.url,
                      "invoiceWorkplaceName",
                    )}
                  />
                }
                disabled={
                  reportingSpecification.workplaceRegistration !==
                  WorkplaceRegistration.transportation
                }
                label={
                  <FormattedMessage
                    defaultMessage="Logstedets navn"
                    id="reporting.entry.logentry-name"
                  />
                }
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!reportingSpecification.invoiceWorkplaceAddress}
                    onChange={useEventTargetCheckedUpdater<ReportingSpecification>(
                      reportingSpecification.url,
                      "invoiceWorkplaceAddress",
                    )}
                  />
                }
                disabled={
                  reportingSpecification.workplaceRegistration !==
                  WorkplaceRegistration.transportation
                }
                label={
                  <FormattedMessage
                    defaultMessage="Logstedets adresse"
                    id="reporting.entry.logentry-address"
                  />
                }
              />
            </FormGroup>
          </FormControl>
        </CardContent>
      </Card>
      <Card style={{marginTop: 16}}>
        <CardHeader title={<FormattedMessage defaultMessage="Kun for arbejdsstedslog" />} />
        <CardContent>
          <FormControlLabel
            control={
              <Checkbox
                checked={!!reportingSpecification.autoInsertLastWorkplaceValues}
                onChange={useEventTargetCheckedUpdater<ReportingSpecification>(
                  reportingSpecification.url,
                  "autoInsertLastWorkplaceValues",
                )}
              />
            }
            disabled={
              reportingSpecification.workplaceRegistration !== WorkplaceRegistration.workplace
            }
            label={intl.formatMessage(messages.autoInsertLastWorkplaceValues)}
          />
          <br />
          <FormControlLabel
            control={
              <Checkbox
                checked={!!reportingSpecification.autoInsertLastWorkplaceConversionValue}
                onChange={useEventTargetCheckedUpdater<ReportingSpecification>(
                  reportingSpecification.url,
                  "autoInsertLastWorkplaceConversionValue",
                )}
              />
            }
            disabled={
              reportingSpecification.workplaceRegistration !== WorkplaceRegistration.workplace
            }
            label={intl.formatMessage({
              defaultMessage:
                "Indsæt automatisk omregningsfaktor fra sidste logregistrering i næste logregistrering",
            })}
          />
        </CardContent>
      </Card>
      <Card style={{marginTop: 16}}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Button
                color="secondary"
                variant="contained"
                onClick={useTrueCallback(setWorkTypeDialogOpen, [setWorkTypeDialogOpen])}
              >
                <FormattedMessage
                  defaultMessage="Vælg arbejdsområder"
                  id="reporting.entry.select-worktypes"
                />
              </Button>
              <div style={{whiteSpace: "pre-line"}}>
                {workTypes
                  .map((workType) => {
                    const workTypeIdentifier = workType.identifier;
                    const workTypeName = workType.name;
                    return workTypeIdentifier
                      ? `${workTypeIdentifier}: ${workTypeName}`
                      : workTypeName;
                  })
                  .sort()
                  .join(",\n")}
              </div>
            </Grid>
            <Grid item xs={4}>
              <Button
                color="secondary"
                variant="contained"
                onClick={useTrueCallback(setMachineDialogOpen, [setMachineDialogOpen])}
              >
                <FormattedMessage
                  defaultMessage="Vælg maskiner"
                  id="reporting.entry.select-machines"
                />
              </Button>
              <div style={{whiteSpace: "pre-line"}}>
                {machines
                  .map((machine) => {
                    const machineIdentifier = machine.c5_machine;
                    const machineName = machine.name;
                    return machineIdentifier ? `${machineIdentifier}: ${machineName}` : machineName;
                  })
                  .sort()
                  .join(",\n")}
              </div>
            </Grid>
            <Grid item xs={4}>
              <Button
                color="secondary"
                variant="contained"
                onClick={useTrueCallback(setPriceGroupDialogOpen, [setPriceGroupDialogOpen])}
              >
                <FormattedMessage
                  defaultMessage="Vælg varianter"
                  id="reporting.entry.select-pricegroups"
                />
              </Button>
              <div style={{whiteSpace: "pre-line"}}>
                {priceGroups
                  .map((priceGroup) => {
                    const priceGroupIdentifier = priceGroup.identifier;
                    const priceGroupName = priceGroup.name;
                    return priceGroupIdentifier
                      ? `${priceGroupIdentifier}: ${priceGroupName}`
                      : priceGroupName;
                  })
                  .sort()
                  .join(",\n")}
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <ConnectedMultipleMachinesDialog
        open={machineDialogOpen}
        selected={selectedMachines}
        onCancel={useFalseCallback(setMachineDialogOpen, [setMachineDialogOpen])}
        onOk={handleMachineDialogOk}
      />
      <ConnectedMultiplePriceGroupDialog
        open={priceGroupDialogOpen}
        selected={selectedPriceGroups}
        onCancel={useFalseCallback(setPriceGroupDialogOpen, [setPriceGroupDialogOpen])}
        onOk={handlePriceGroupDialogOk}
      />
      <ConnectedMultipleExternalWorkTypesDialog
        open={workTypeDialogOpen}
        selected={selectedWorkTypes}
        onCancel={useFalseCallback(setWorkTypeDialogOpen, [setWorkTypeDialogOpen])}
        onOk={handleWorkTypeDialogOk}
      />
      <ReportingIdentifierNameDialog
        identifier={reportingSpecification.identifier}
        name={reportingSpecification.name}
        open={identifierNameDialogOpen}
        otherReportingIdentifiers={otherReportingIdentifiers}
        onCancel={useFalseCallback(setIdentifierNameDialogOpen, [setIdentifierNameDialogOpen])}
        onOk={reportingIdentifierNameDialogOk}
      />
    </>
  );
});
