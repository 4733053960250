import {Customer, Task} from "@co-common-libs/resources";
import {getCustomerLookup, getOrderLookup} from "@co-frontend-libs/redux";
import {useSelector} from "react-redux";

export function useCustomerFromTask(task: Task): Customer | null {
  const orderLookup = useSelector(getOrderLookup);
  const customerLookup = useSelector(getCustomerLookup);

  const orderURL = task.order;
  const order = orderURL ? orderLookup(orderURL) : null;
  const customerURL = order ? order.customer : null;
  const customer = customerURL ? customerLookup(customerURL) : null;

  return customer || null;
}
