import {
  Location,
  LocationStorageAdjustment,
  LocationStorageChange,
  LocationStorageStatus,
  LocationType,
  LocationTypeUrl,
  LocationUrl,
  ProductUrl,
} from "@co-common-libs/resources";
import {getOrInsertDefault} from "@co-common-libs/utils";
import memoizeOne from "memoize-one";

/**
 * @param locationStorageStatusArray Current LocationStorageStatus array
 * @param locationStorageChangeArray Current LocationStorageChange array
 * @param locationStorageAdjustmentArray Current LocationStorageAdjustment array
 *
 * @returns Mapping location -> product -> count
 */
export function getLocationProductNonZeroCounts(
  locationStorageStatusArray: readonly Readonly<LocationStorageStatus>[],
  locationStorageAdjustmentArray: readonly Readonly<LocationStorageAdjustment>[],
  locationStorageChangeArray: readonly Readonly<LocationStorageChange>[],
  onlyForProducts?: ReadonlySet<string>,
): ReadonlyMap<LocationUrl, ReadonlyMap<ProductUrl, number>> {
  interface ProductEntry {
    adjustmentDeviceTimestamp: string | null;
    count: number;
    statusChangeTimestamp: string | null;
  }
  type ProductMap = Map<ProductUrl, ProductEntry>;
  // location -> product -> {count, statusChangeTimestamp, adjustmentDeviceTimestamp}
  const locationProductEntries = new Map<LocationUrl, ProductMap>();
  const makeEmptyProductMap = (): ProductMap => new Map<ProductUrl, ProductEntry>();
  const makeEmptyProductEntry = (): ProductEntry => ({
    adjustmentDeviceTimestamp: null,
    count: 0,
    statusChangeTimestamp: null,
  });

  locationStorageStatusArray.forEach((status) => {
    const {lastChanged, location, product, value} = status;
    if (onlyForProducts && !onlyForProducts.has(product)) {
      return;
    }
    const productEntries = getOrInsertDefault(
      locationProductEntries,
      location,
      makeEmptyProductMap,
    );
    const entry = {
      adjustmentDeviceTimestamp: null,
      count: value,
      statusChangeTimestamp: lastChanged || null,
    };
    console.assert(!productEntries.has(product));
    productEntries.set(product, entry);
  });

  locationStorageAdjustmentArray.forEach((adjustment) => {
    const {deviceTimestamp, lastChanged, location, product, value} = adjustment;
    if (onlyForProducts && !onlyForProducts.has(product)) {
      return;
    }
    const productEntries = getOrInsertDefault(
      locationProductEntries,
      location,
      makeEmptyProductMap,
    );
    const entry = getOrInsertDefault(productEntries, product, makeEmptyProductEntry);
    if (entry.statusChangeTimestamp && lastChanged && entry.statusChangeTimestamp >= lastChanged) {
      // existing value from LocationStorageStatus at least as recent
      return;
    }
    if (entry.adjustmentDeviceTimestamp && entry.adjustmentDeviceTimestamp > deviceTimestamp) {
      // existing value from LocationStorageAdjustment more recent
      return;
    }
    entry.adjustmentDeviceTimestamp = deviceTimestamp;
    entry.count = value;
  });

  function update(
    location: string,
    product: string,
    lastChanged: string | undefined,
    deviceTimestamp: string,
    value: number,
  ): void {
    const productEntries = getOrInsertDefault(
      locationProductEntries,
      location,
      makeEmptyProductMap,
    );
    const entry = getOrInsertDefault(productEntries, product, makeEmptyProductEntry);
    if (entry.statusChangeTimestamp && lastChanged && entry.statusChangeTimestamp >= lastChanged) {
      // existing value from LocationStorageStatus at least as recent
      return;
    }
    if (entry.adjustmentDeviceTimestamp && entry.adjustmentDeviceTimestamp > deviceTimestamp) {
      // existing value from LocationStorageAdjustment more recent
      return;
    }
    entry.count += value;
  }

  locationStorageChangeArray.forEach((storageChange) => {
    const {change, fromLocation, fromTimestamp, lastChanged, product, toLocation, toTimestamp} =
      storageChange;
    if (onlyForProducts && !onlyForProducts.has(product)) {
      return;
    }
    if (toLocation) {
      update(toLocation, product, lastChanged, toTimestamp || new Date().toISOString(), change);
    }
    if (fromLocation) {
      update(
        fromLocation,
        product,
        lastChanged,
        fromTimestamp || new Date().toISOString(),
        -change,
      );
    }
  });
  // location -> product -> count
  const result = new Map<LocationUrl, Map<ProductUrl, number>>();
  locationProductEntries.forEach((productEntries, locationURL) => {
    const productNumbers = new Map<ProductUrl, number>();
    productEntries.forEach(({count}, productURL) => {
      if (count) {
        productNumbers.set(productURL, count);
      }
    });
    if (productNumbers.size) {
      result.set(locationURL, productNumbers);
    }
  });
  return result;
}

/**
 * @param locationStorageStatusArray Current LocationStorageStatus array
 * @param locationStorageChangeArray Current LocationStorageChange array
 * @param locationStorageAdjustmentArray Current LocationStorageAdjustment array
 * @param locationUrl Location to get the product count for
 *
 * @returns Mapping product -> count
 */
export function getProductCountForLocation(
  locationStorageStatusArray: readonly Readonly<LocationStorageStatus>[],
  locationStorageAdjustmentArray: readonly Readonly<LocationStorageAdjustment>[],
  locationStorageChangeArray: readonly Readonly<LocationStorageChange>[],
  locationUrl: LocationUrl,
): ReadonlyMap<ProductUrl, number> {
  interface ProductEntry {
    add: LocationStorageChange[];
    adjust: LocationStorageAdjustment[];
    remove: LocationStorageChange[];
    status: LocationStorageStatus | null;
  }
  // product -> {status, remove, add}
  const productEntries = new Map<ProductUrl, ProductEntry>();

  locationStorageStatusArray
    .filter((status) => status.location === locationUrl)
    .forEach((status) => {
      const {product} = status;
      console.assert(!productEntries.has(product));
      productEntries.set(product, {
        add: [],
        adjust: [],
        remove: [],
        status,
      });
    });

  const makeEmptyEntry = (): ProductEntry => ({
    add: [],
    adjust: [],
    remove: [],
    status: null,
  });

  locationStorageAdjustmentArray
    .filter((adjustment) => adjustment.location === locationUrl)
    .forEach((adjustment) => {
      const {product} = adjustment;
      const productChanges = getOrInsertDefault(productEntries, product, makeEmptyEntry);
      productChanges.adjust.push(adjustment);
    });

  locationStorageChangeArray
    .filter((change) => change.fromLocation === locationUrl || change.toLocation === locationUrl)
    .forEach((change) => {
      const {fromLocation, product, toLocation} = change;
      const productChanges = getOrInsertDefault(productEntries, product, makeEmptyEntry);
      if (fromLocation && fromLocation === locationUrl) {
        productChanges.remove.push(change);
      }
      if (toLocation && toLocation === locationUrl) {
        productChanges.add.push(change);
      }
    });

  const result = new Map<ProductUrl, number>();
  productEntries.forEach(({add, adjust, remove, status}, productURL) => {
    let value = status?.value || 0;
    const statusTimestamp = status?.lastChanged;
    let adjustmentDeviceTimestamp: string | undefined;
    if (adjust.length) {
      adjust.forEach((adjustment) => {
        if (
          statusTimestamp &&
          adjustment.lastChanged &&
          statusTimestamp >= adjustment.lastChanged
        ) {
          // status is newer
          return;
        }
        if (adjustmentDeviceTimestamp && adjustmentDeviceTimestamp > adjustment.deviceTimestamp) {
          // current adjustment is newer
          return;
        }
        ({value} = adjustment);
        adjustmentDeviceTimestamp = adjustment.deviceTimestamp;
      });
    }
    if (add.length) {
      add.forEach((change) => {
        if (statusTimestamp && change.lastChanged && statusTimestamp >= change.lastChanged) {
          // status is newer
          return;
        }
        if (
          adjustmentDeviceTimestamp &&
          adjustmentDeviceTimestamp > (change.toTimestamp || new Date().toISOString())
        ) {
          // adjustment is newer
          return;
        }
        value += change.change;
      });
    }
    if (remove.length) {
      remove.forEach((change) => {
        if (statusTimestamp && change.lastChanged && statusTimestamp >= change.lastChanged) {
          // status is newer
          return;
        }
        if (
          adjustmentDeviceTimestamp &&
          adjustmentDeviceTimestamp > (change.fromTimestamp || new Date().toISOString())
        ) {
          // adjustment is newer
          return;
        }
        value -= change.change;
      });
    }
    result.set(productURL, value);
  });
  return result;
}

export const trackedStorageProductCounts = memoizeOne(
  (
    locationStorageStatusArray: readonly LocationStorageStatus[],
    locationStorageAdjustmentArray: readonly LocationStorageAdjustment[],
    locationStorageChangeArray: readonly LocationStorageChange[],
    locationTypeLookup: (url: LocationTypeUrl) => LocationType | undefined,
    location: Location,
  ): ReadonlyMap<ProductUrl, number> => {
    const locationType = location.locationType
      ? locationTypeLookup(location.locationType)
      : undefined;
    const result = new Map<ProductUrl, number>();
    if (!locationType || !locationType.products.length) {
      return result;
    }
    const base = getProductCountForLocation(
      locationStorageStatusArray,
      locationStorageAdjustmentArray,
      locationStorageChangeArray,
      location.url,
    );
    base.forEach((count, productURL) => {
      if (locationType.products.includes(productURL)) {
        result.set(productURL, count);
      }
    });
    return result;
  },
);
