import {
  KrPerLiterFuelSurchargeSpecification,
  KrPerLiterFuelSurchargeSpecificationEntry,
  KrPerLiterFuelSurchargeSpecificationEntryUrl,
} from "@co-common-libs/resources";
import {findFuelSurchargeBasis} from "@co-common-libs/resources-utils";
import {dateFromString} from "@co-common-libs/utils";
import {getKrPerLiterFuelSurchargeBasisArray} from "@co-frontend-libs/redux";
import {TableCell, TableRow} from "@material-ui/core";
import React, {useCallback} from "react";
import {FormattedDate, FormattedNumber} from "react-intl";
import {useSelector} from "react-redux";

interface KrPerLiterFuelSurchargeSpecificationEntryRowProps {
  entry: KrPerLiterFuelSurchargeSpecificationEntry;
  onEntryClick: (url: KrPerLiterFuelSurchargeSpecificationEntryUrl) => void;
  specification: KrPerLiterFuelSurchargeSpecification;
}

export const KrPerLiterFuelSurchargeSpecificationEntryRow = React.memo(
  function KrPerLiterFuelSurchargeSpecificationEntryRow(
    props: KrPerLiterFuelSurchargeSpecificationEntryRowProps,
  ): JSX.Element {
    const {entry, onEntryClick, specification} = props;

    const {fromDate, priceKrPerLiter, toDate, url} = entry;
    const {conversionFactor} = specification;

    const handleEntryClick = useCallback(() => {
      onEntryClick(url);
    }, [onEntryClick, url]);

    const krPerLiterFuelSurchargeBasisArray = useSelector(getKrPerLiterFuelSurchargeBasisArray);

    const basis = findFuelSurchargeBasis(
      krPerLiterFuelSurchargeBasisArray,
      specification.url,
      fromDate,
    );

    return (
      <TableRow onClick={handleEntryClick}>
        <TableCell>
          <FormattedDate value={dateFromString(fromDate) as Date} />
        </TableCell>
        <TableCell>
          <FormattedDate value={dateFromString(toDate) as Date} />
        </TableCell>
        <TableCell>
          <FormattedNumber
            maximumFractionDigits={2}
            minimumFractionDigits={2}
            value={priceKrPerLiter * conversionFactor}
          />
        </TableCell>
        <TableCell>
          <FormattedNumber
            maximumFractionDigits={2}
            minimumFractionDigits={2}
            value={
              priceKrPerLiter * conversionFactor -
              (basis?.basePriceKrPerLiter ?? 0) * conversionFactor
            }
          />
        </TableCell>
      </TableRow>
    );
  },
);
