import {
  CustomerUrl,
  PricePercentDefaultFuelSurchargeUse,
  PricePercentFuelSurchargeSpecification,
  PricePercentFuelSurchargeSpecificationUrl,
  PricePercentWorkTypeFuelSurchargeUse,
  Product,
  ProductGroup,
  ProductGroupUrl,
  ProductUrl,
  ProductUse,
  Task,
} from "@co-common-libs/resources";
import {findFuelSurchargeUseAndSpecification} from "./find-fuel-surcharge-use-and-specification";

type PricePercentFuelSurchargeSpecificationPart = Pick<
  PricePercentFuelSurchargeSpecification,
  "active"
>;
type PricePercentWorkTypeFuelSurchargeUsePart = Pick<
  PricePercentWorkTypeFuelSurchargeUse,
  "customer" | "fuelSurcharge" | "variant" | "workType"
>;
type PricePercentDefaultFuelSurchargeUsePart = Pick<
  PricePercentDefaultFuelSurchargeUse,
  "customer" | "fuelSurcharge"
>;

export function getProductUseMatches<
  Specification extends PricePercentFuelSurchargeSpecificationPart,
  WorkTypeFuelSurchargeUse extends PricePercentWorkTypeFuelSurchargeUsePart,
  DefaultFuelSurchargeUse extends PricePercentDefaultFuelSurchargeUsePart,
>(
  task: Pick<Task, "priceGroup" | "workType"> & {
    readonly productUses: {
      readonly [identifier: string]: Pick<ProductUse, "product">;
    };
  },
  customerUrl: CustomerUrl | null,
  productLookup: (url: ProductUrl) => Pick<Product, "group"> | undefined,
  productGroupLookup: (
    url: ProductGroupUrl,
  ) => Pick<ProductGroup, "contributesToFuelSurchargeSalesPrice"> | undefined,
  fuelSurchargeSpecificationLookup: (
    url: PricePercentFuelSurchargeSpecificationUrl,
  ) => Specification | undefined,
  workTypeFuelSurchargeUseArray: readonly WorkTypeFuelSurchargeUse[],
  defaultFuelSurchargeUseArray: readonly DefaultFuelSurchargeUse[],
): Map<
  ProductUrl,
  {
    specification: Specification | null;
    use: DefaultFuelSurchargeUse | WorkTypeFuelSurchargeUse;
  }
> {
  const result = new Map<
    ProductUrl,
    {
      specification: Specification | null;
      use: DefaultFuelSurchargeUse | WorkTypeFuelSurchargeUse;
    }
  >();

  if (task.productUses) {
    for (const productUse of Object.values(task.productUses)) {
      const product = productLookup(productUse.product);
      if (!product) {
        continue;
      }
      const productGroup = product.group ? productGroupLookup(product.group) : undefined;
      if (!productGroup || !productGroup.contributesToFuelSurchargeSalesPrice) {
        continue;
      }
      const match = findFuelSurchargeUseAndSpecification(
        {
          customerUrl,
          machinePriceGroupUrl: null,
          machineUrl: null,
          workTypePriceGroupUrl: task.priceGroup,
          workTypeUrl: task.workType,
        },
        [],
        workTypeFuelSurchargeUseArray,
        defaultFuelSurchargeUseArray,
        fuelSurchargeSpecificationLookup,
      );
      if (match) {
        result.set(productUse.product, match);
      }
    }
  }
  return result;
}
