import {
  CsvExportSpecification,
  getAvailableColumns,
  getExportSpecifications,
} from "@co-common-libs/csv-export-specifications";
import {getCustomerSettings, getShareToken} from "@co-frontend-libs/redux";
import {jsonFetch} from "@co-frontend-libs/utils";
import {Button, Card, CardContent, Grid} from "@material-ui/core";
import {globalConfig} from "frontend-global-config";
import React, {useCallback, useMemo, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {ExportSelectorCard} from "./export-column-selector-card";
import {ExportColumnSelectorCheckboxesCard} from "./export-column-selector-checkboxes-card";
import {ExportPreviewCard} from "./export-preview-card";

const PREVIEW_ROWS_LIMIT = 10;

export const ExportContent = React.memo(function ExportContent(): JSX.Element {
  const [selectedExportSpecification, setSelectedExportSpecification] = useState<
    CsvExportSpecification | undefined
  >(undefined);

  const intl = useIntl();

  const customerSettings = useSelector(getCustomerSettings);

  const availableExportSpecifications = useMemo(
    (): readonly CsvExportSpecification[] => getExportSpecifications(intl),
    [intl],
  );

  const [selectedColumnNames, setSelectedColumnNames] = useState<ReadonlySet<string>>(new Set());

  const [previewLoadErrorEncountered, setPreviewLoadErrorEncountered] = useState(false);

  const [exportSelectorDisabled, setExportSelectorDisabled] = useState<boolean>(false);

  const [rows, setRows] = useState<readonly (readonly string[])[]>([]);
  const shareToken = useSelector(getShareToken);

  const handleExportSelectorChange = useCallback(
    async (newSelectedExportSpecificationName: string) => {
      const newSelectedExportSpecification = availableExportSpecifications.find(
        (exportSpecification) => exportSpecification.name === newSelectedExportSpecificationName,
      );

      if (!newSelectedExportSpecification) {
        return;
      }

      setSelectedExportSpecification(newSelectedExportSpecification);
      setSelectedColumnNames(
        new Set(
          getAvailableColumns(customerSettings, newSelectedExportSpecification).map(
            (column) => column.name,
          ),
        ),
      );

      const url = new URL(globalConfig.baseURL);
      url.pathname = "api/csv_export/preview/";
      url.searchParams.append("specificationName", newSelectedExportSpecificationName);
      url.searchParams.append("limit", `${PREVIEW_ROWS_LIMIT}`);

      try {
        setExportSelectorDisabled(true);
        const response = await jsonFetch(`${url}`);

        setRows(response.data);
        setPreviewLoadErrorEncountered(false);
        setExportSelectorDisabled(false);
      } catch (error) {
        setPreviewLoadErrorEncountered(true);
        setExportSelectorDisabled(false);
      }
    },
    [availableExportSpecifications, customerSettings],
  );

  const handleReloadButtonClick = useCallback(async () => {
    const selectedExportSpecificationName = selectedExportSpecification?.name;

    if (selectedExportSpecificationName) {
      await handleExportSelectorChange(selectedExportSpecificationName);
    }
  }, [handleExportSelectorChange, selectedExportSpecification?.name]);

  return (
    <Grid container spacing={2}>
      <Grid item md={3} xs={12}>
        <ExportSelectorCard
          availableExportSpecifications={availableExportSpecifications}
          disabled={exportSelectorDisabled}
          handleExportSelectorChange={handleExportSelectorChange}
          selectedExportSpecification={selectedExportSpecification}
        />
      </Grid>

      {selectedExportSpecification ? (
        <Grid item md={9} xs={12}>
          <ExportColumnSelectorCheckboxesCard
            checkedStates={selectedColumnNames}
            selectedExportSpecification={selectedExportSpecification}
            setSelectedColumnNames={setSelectedColumnNames}
          />
        </Grid>
      ) : null}
      {previewLoadErrorEncountered && selectedExportSpecification ? (
        <Grid item xs={12}>
          <CardContent>
            <Card
              style={{
                textAlign: "center",
              }}
            >
              <FormattedMessage defaultMessage="Der gik noget galt under indlæsning af preview. Tryk her for at genindlæse" />
              <br />
              <Button color="primary" variant="contained" onClick={handleReloadButtonClick}>
                <FormattedMessage defaultMessage="Reload" />
              </Button>
            </Card>
          </CardContent>
        </Grid>
      ) : selectedExportSpecification && selectedColumnNames.size && shareToken ? (
        <Grid item xs={12}>
          <ExportPreviewCard
            rows={rows}
            selectedColumnNames={selectedColumnNames}
            selectedExportSpecification={selectedExportSpecification}
            shareToken={shareToken}
          />
        </Grid>
      ) : null}
    </Grid>
  );
});
