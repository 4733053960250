import {
  Dispatch,
  Reducer,
  SetStateAction,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from "react";

export type SetAction<T> =
  | {type: "addMultiple"; values: ReadonlySet<T>}
  | {type: "addOne"; value: T}
  | {type: "removeMultiple"; values: ReadonlySet<T>}
  | {type: "removeOne"; value: T}
  | {type: "replace"; values: ReadonlySet<T> | undefined}
  | {type: "toggle"; value: T};

/**
 * Reducer for use with `useReducer` to manage state for a `Set` without
 * having to rebind callbacks on every state change.
 */
// HACK: TypeScript validates that all valid input types are handled;
// it would be a type error if a path that did not return ReadonlySet<T>
// was possible...
// eslint-disable-next-line consistent-return
export function setReducer<T>(state: ReadonlySet<T>, action: SetAction<T>): ReadonlySet<T> {
  const newState = new Set(state);
  switch (action.type) {
    case "addOne":
      newState.add(action.value);
      return newState;
    case "removeOne":
      newState.delete(action.value);
      return newState;
    case "addMultiple":
      action.values.forEach(newState.add.bind(newState));
      return newState;
    case "removeMultiple":
      action.values.forEach(newState.delete.bind(newState));
      return newState;
    case "replace":
      return new Set(action.values);
    case "toggle":
      if (newState.has(action.value)) {
        newState.delete(action.value);
      } else {
        newState.add(action.value);
      }
      return newState;
  }
}

export const stringSetReducer: (
  state: ReadonlySet<string>,
  action: SetAction<string>,
) => ReadonlySet<string> = setReducer;

export const numberSetReducer: (
  state: ReadonlySet<number>,
  action: SetAction<number>,
) => ReadonlySet<number> = setReducer;

/**
 * Wrapper for/demo of use of `setReducer` with `useReducer`.
 * Probably has some overhead compared to using `[state, dispatch]` from
 * `useReducer`, but more convenient for replacing naive code using
 * `setState`...
 *
 * @param initial Initial state
 */
export function useSetState<T>(
  initial?: ReadonlySet<T>,
): [
  ReadonlySet<T>,
  (toAdd: T) => void,
  (toRemove: T) => void,
  (toAdd: ReadonlySet<T>) => void,
  (toRemove: ReadonlySet<T>) => void,
  (replacing?: ReadonlySet<T>) => void,
] {
  const [set, dispatch] = useReducer<Reducer<ReadonlySet<T>, SetAction<T>>>(
    setReducer,
    initial ?? new Set<T>(),
  );
  const addOne = useCallback((toAdd: T): void => {
    dispatch({type: "addOne", value: toAdd});
  }, []);
  const removeOne = useCallback((toRemove: T): void => {
    dispatch({type: "removeOne", value: toRemove});
  }, []);
  const addMultiple = useCallback((toAdd: ReadonlySet<T>): void => {
    dispatch({type: "addMultiple", values: toAdd});
  }, []);
  const removeMultiple = useCallback((toRemove: ReadonlySet<T>): void => {
    dispatch({type: "removeMultiple", values: toRemove});
  }, []);
  const replace = useCallback((replacing?: ReadonlySet<T>): void => {
    dispatch({type: "replace", values: replacing});
  }, []);
  return [set, addOne, removeOne, addMultiple, removeMultiple, replace];
}

export function useResettingState<S>(
  initialState: S,
  resetCondition: boolean,
): [S, Dispatch<SetStateAction<S>>] {
  const [state, setState] = useState<S>(initialState);

  useEffect(() => {
    if (resetCondition) {
      setState(initialState);
    }
  }, [initialState, resetCondition]);

  return [state, setState];
}
