import {CustomerUrl} from "@co-common-libs/resources";
import {useModal} from "@co-frontend-libs/components";
import {getCustomerLookup} from "@co-frontend-libs/redux";
import {Button, IconButton, Typography} from "@material-ui/core";
import CloseIcon from "mdi-react/CloseIcon";
import React, {Ref, forwardRef, useCallback} from "react";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {CustomerSelectCreateDialog} from "../customer-select-create-dialog";
import {CustomerInfo} from "./customer-info";
import {InformationBlockPropsBase} from "./information-block-props-base";

export function useCustomerSetters(
  onCustomerChanged: (customerUrl: CustomerUrl | null) => void,
): [typeof modal, typeof selectCustomerClicked] {
  const [modal, prompt] = useModal(CustomerSelectCreateDialog);
  const selectCustomerClicked = useCallback(async () => {
    const selectedCustomer = await prompt();
    if (selectedCustomer) {
      onCustomerChanged(selectedCustomer);
    }
  }, [prompt, onCustomerChanged]);

  return [modal, selectCustomerClicked];
}

interface CustomerBlockProps extends InformationBlockPropsBase {
  customerUrl: CustomerUrl | null | undefined;
  onCustomerChanged: (customerUrl: CustomerUrl | null) => void;
}

export const CustomerBlock = forwardRef(function CustomerBlock(
  {clearDisabled, customerUrl, hideButtons, onCustomerChanged, selectDisabled}: CustomerBlockProps,
  selectButtonRef: Ref<HTMLButtonElement>,
): JSX.Element {
  const customerLookup = useSelector(getCustomerLookup);
  const customer = customerUrl ? customerLookup(customerUrl) : undefined;

  const [modal, selectCustomerClicked] = useCustomerSetters(onCustomerChanged);

  const clearCustomerClicked = useCallback(() => {
    onCustomerChanged(null);
  }, [onCustomerChanged]);

  return (
    <>
      <Typography variant="body1">
        <FormattedMessage defaultMessage="Kunde" />
      </Typography>
      {!hideButtons && (
        <>
          <Button
            ref={selectButtonRef}
            autoFocus
            color="secondary"
            disabled={selectDisabled}
            variant="contained"
            onClick={selectCustomerClicked}
          >
            <FormattedMessage defaultMessage="Vælg" />
          </Button>
          <IconButton disabled={clearDisabled} onClick={clearCustomerClicked}>
            <CloseIcon />
          </IconButton>
        </>
      )}
      {customer && <CustomerInfo customer={customer} />}
      {modal}
    </>
  );
});
