import {PatchUnion, ResourceTypeUnion} from "@co-common-libs/resources";
import {actions} from "@co-frontend-libs/redux";
import {useCallback} from "react";
import {useDispatch} from "react-redux";

export function useBoundActions(): {
  boundCreate: (instance: ResourceTypeUnion) => void;
  boundRemove: (url: string) => void;
  boundUpdate: (url: string, patch: PatchUnion) => void;
} {
  const dispatch = useDispatch();

  const boundCreate = useCallback(
    (instance: ResourceTypeUnion): void => {
      dispatch(actions.create(instance));
    },
    [dispatch],
  );

  const boundRemove = useCallback(
    (url: string): void => {
      dispatch(actions.remove(url));
    },
    [dispatch],
  );

  const boundUpdate = useCallback(
    (url: string, patch: PatchUnion): void => {
      dispatch(actions.update(url, patch));
    },
    [dispatch],
  );

  return {
    boundCreate,
    boundRemove,
    boundUpdate,
  };
}
