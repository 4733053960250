import {actions} from "@co-frontend-libs/redux";
import {useQueryParameter} from "app-utils";
import React, {useCallback, useEffect, useRef} from "react";
import {useDispatch} from "react-redux";
import {useDebouncedCallback} from "use-debounce";

const DEBOUNCE_TIMER = 200;

interface ScrollHandlerProps {
  element: HTMLElement | "GLOBAL" | null;
}

/**
 * Navigation/tab-switch scroll logic:
 *
 * * Scroll to position found in `scrollX`, `scrollY` in query string when `element` first given.
 *   This *may* be after the initial render, for convenient use when `element` is the result of a `ref`.
 *   This should ensure that we "preserve" the remembered scroll position on back-navigation.
 * * Scroll to top on later changes to `tab` or `element`.
 *   This should ensure that we go to the top on tab-changes -- which may also involve an `element`
 *   obtained *after* the tab-change with a `ref`.
 * * Save current scroll position in `scrollX`, `scrollY` in query string on changes.
 */
export const ScrollHandler = function ScrollHandler(props: ScrollHandlerProps): JSX.Element {
  const scrollElement = props.element;

  const dispatch = useDispatch();
  const handleScroll = useCallback(() => {
    if (!scrollElement) {
      return;
    }
    const scrollX = scrollElement === "GLOBAL" ? window.scrollX : scrollElement.scrollLeft;
    const scrollY = scrollElement === "GLOBAL" ? window.scrollY : scrollElement.scrollTop;
    dispatch(
      actions.putQueryKeys({
        scrollX: `${scrollX}`,
        scrollY: `${scrollY}`,
      }),
    );
  }, [dispatch, scrollElement]);

  const debouncedHandleScroll = useDebouncedCallback(handleScroll, DEBOUNCE_TIMER);

  const scrollX = useQueryParameter("scrollX");
  const scrollY = useQueryParameter("scrollY");
  const currentTab = useQueryParameter("tab");
  const initialScrollDone = useRef<boolean>(false);
  const target = scrollElement === "GLOBAL" ? window : scrollElement;

  useEffect(() => {
    if (!target) {
      return undefined;
    }
    target.addEventListener("scroll", debouncedHandleScroll, {passive: true});
    return () => {
      target.removeEventListener("scroll", debouncedHandleScroll);
      debouncedHandleScroll.cancel();
    };
  }, [debouncedHandleScroll, scrollElement, target]);

  useEffect(() => {
    if (!target || !initialScrollDone.current) {
      return;
    }
    debouncedHandleScroll.cancel();
    target.scrollTo(0, 0);
    setTimeout(() => handleScroll());
    return;
    // Scroll to top when tab changed and we have a target
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab, target]);

  useEffect(() => {
    if (!target || initialScrollDone.current) {
      return;
    }
    if (scrollX && scrollY) {
      const left = parseFloat(scrollX);
      const top = parseFloat(scrollY);
      if (!isNaN(left) && !isNaN(top)) {
        target.scrollTo(left, top);
        setTimeout(() => {
          target.scrollTo(left, top);
        });
      }
    }
    initialScrollDone.current = true;
    return;
  }, [scrollX, scrollY, target]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};
