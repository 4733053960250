import {Config} from "@co-common-libs/config";
import {WorkType} from "@co-common-libs/resources";
import {IntlShape} from "react-intl";
import {InlinedTask} from "../../inline-data";

export const getOptionalRequiredProductUseNotFoundError = (
  customerOptions: Config,
  intl: IntlShape,
  task: Pick<InlinedTask, "productuseSet">,
  workType: WorkType,
  noProductUsesOrAtLeastOneProductUseGreaterThanZero: boolean | undefined,
): string | null => {
  const productUseList = task.productuseSet;
  const {identifier: workTypeIdentifier, name: workTypeName} = workType;

  if (
    noProductUsesOrAtLeastOneProductUseGreaterThanZero &&
    !!productUseList.length &&
    !productUseList.some((productUse) => productUse.count && productUse.count > 0)
  ) {
    if (customerOptions.materialUseAlternativeText) {
      return intl.formatMessage(
        {
          defaultMessage:
            "Hvis der er materiale-linjer, så skal mindst én af disse være udfyldt med større end 0, for opgaver med arbejdsområde {workTypeName} ({workTypeIdentifier}).",
        },
        {workTypeIdentifier, workTypeName},
      );
    } else {
      return intl.formatMessage(
        {
          defaultMessage:
            "Hvis der er materiel-linjer, så skal mindst én af disse være udfyldt med større end 0, for opgaver med arbejdsområde {workTypeName} ({workTypeIdentifier}).",
        },
        {workTypeIdentifier, workTypeName},
      );
    }
  }

  return null;
};
