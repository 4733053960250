import {isLandscapeMediaQuery} from "@co-frontend-libs/components";
import {useMediaQuery, useTheme} from "@material-ui/core";

// https://github.com/mui/material-ui/issues/10739#issuecomment-1001530270
export function useAppBarHeight(): number {
  const {
    breakpoints,
    mixins: {toolbar},
  } = useTheme();
  const toolbarDesktopQuery = breakpoints.up("sm");
  const toolbarLandscapeQuery = isLandscapeMediaQuery(breakpoints);
  const isDesktop = useMediaQuery(toolbarDesktopQuery);
  const isLandscape = useMediaQuery(toolbarLandscapeQuery);
  let currentToolbarMinHeight;
  if (isDesktop) {
    currentToolbarMinHeight = toolbar[toolbarDesktopQuery];
  } else if (isLandscape) {
    currentToolbarMinHeight = toolbar[toolbarLandscapeQuery];
  } else {
    currentToolbarMinHeight = toolbar;
  }
  return (
    currentToolbarMinHeight as {
      minHeight: number;
    }
  ).minHeight;
}
