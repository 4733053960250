import {Checkbox, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import _ from "lodash";
import React, {useMemo} from "react";
import {FormattedMessage} from "react-intl";
import {
  CHECKBOX_COLUMN_WIDTH,
  DATE_COLUMN_WIDTH,
  EMPLOYEE_ALIAS_COLUMN_WIDTH,
  TASK_LINK_COLUMN_WIDTH,
} from "./constants";
import {LogTableRow} from "./log-table-row";
import {LogTaskData} from "./types";

interface LogTableProps {
  checkedLogs: ReadonlySet<string>;
  logTasks: readonly LogTaskData[];
  onCheckAllCheck: () => void;
  onCheckboxCheck: (id: string, checked: boolean) => void;
}

export const AppendicesLogTable = React.memo(function LogTable(props: LogTableProps): JSX.Element {
  const {checkedLogs, logTasks, onCheckAllCheck, onCheckboxCheck} = props;

  const sortedLogTasks = useMemo(() => _.sortBy(logTasks, (task) => task.date), [logTasks]);
  const rows: JSX.Element[] = sortedLogTasks.map((taskData) => {
    const {id} = taskData;
    return (
      <LogTableRow key={id} {...taskData} checked={checkedLogs.has(id)} onCheck={onCheckboxCheck} />
    );
  });
  const allChecked = !!logTasks && !!checkedLogs.size && checkedLogs.size === sortedLogTasks.length;
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell style={{width: CHECKBOX_COLUMN_WIDTH}}>
            <Checkbox
              checked={allChecked}
              id="check-all-checkbox"
              name="check-all"
              onChange={onCheckAllCheck}
            />
          </TableCell>
          <TableCell>
            <FormattedMessage defaultMessage="Log navn" id="log-table.table-header.log-title" />
          </TableCell>
          <TableCell>
            <FormattedMessage defaultMessage="Kunde" id="log-table.table-header.customer" />
          </TableCell>
          <TableCell style={{width: EMPLOYEE_ALIAS_COLUMN_WIDTH}}>
            <FormattedMessage defaultMessage="Medarb." id="log-table.table-header.employee" />
          </TableCell>
          <TableCell style={{width: DATE_COLUMN_WIDTH}}>
            <FormattedMessage defaultMessage="Dato" id="log-table.table-header.date" />
          </TableCell>
          <TableCell style={{width: TASK_LINK_COLUMN_WIDTH}} />
        </TableRow>
      </TableHead>
      <TableBody>{rows}</TableBody>
    </Table>
  );
});
