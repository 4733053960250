import {ResourceInstance, ResourceName} from "@co-common-libs/resources";
import idbReady from "safari-14-idb-fix";
import {Query, QueryIDStruct, QueryParams, QueryTimestampsStruct} from "../types";
import {ResourceDBConnection} from "./types";

const EMPTY_VERSION = 0;
const DATA_QUERY_QUERYDATA_VERSION = 1;
const DATA_QUERY_QUERYDATA_QUERYTIMESTAMP_VERSION = 2;
const DATA_QUERY_QUERYTIMESTAMP_VERSION = 3;
const DATA_QUERY_QUERYTIMESTAMP_RELATED_FETCH_VERSION = 4;

const CURRENT_VERSION = DATA_QUERY_QUERYTIMESTAMP_RELATED_FETCH_VERSION;

const CHANGES_KEY = "CHANGES";

const createStores = (db: IDBDatabase, oldVersion: number, _newVersion: number | null): void => {
  if (oldVersion <= EMPTY_VERSION) {
    db.createObjectStore("data", {keyPath: "url"});
    db.createObjectStore("query", {autoIncrement: true, keyPath: "id"});
  }
  if (oldVersion <= DATA_QUERY_QUERYDATA_VERSION) {
    db.createObjectStore("queryTimestamp", {keyPath: "queryID"});
  }
  if (
    oldVersion >= DATA_QUERY_QUERYDATA_VERSION &&
    oldVersion <= DATA_QUERY_QUERYDATA_QUERYTIMESTAMP_VERSION
  ) {
    db.deleteObjectStore("queryData");
  }
  if (oldVersion <= DATA_QUERY_QUERYTIMESTAMP_VERSION) {
    db.createObjectStore("fetchById", {keyPath: ["resourceName", "id"]});
    db.createObjectStore("fetchByRelated", {
      keyPath: ["resourceName", "memberName", "id"],
    });
    db.createObjectStore("changesTimestamp", {keyPath: "key"});
  }
};

const openConnection = (indexedDB: IDBFactory, dbName: string): Promise<IDBDatabase> =>
  // Don't use hardcoded window.indexedDB, to support test with fakeIndexedDB.
  new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName, CURRENT_VERSION);
    request.onupgradeneeded = (event) => {
      const db = (event.target as any).result as IDBDatabase;
      const {oldVersion} = event;
      const {newVersion} = event;
      createStores(db, oldVersion, newVersion);
    };
    request.onsuccess = (event) => {
      const db = (event.target as any).result as IDBDatabase;
      resolve(db);
    };
    request.onerror = (event) => {
      reject(new Error(event.type));
    };
  });

// Wrapper to "trick" TypeScript into considering it possible that the value
// might be false.  In the type declaration, IDBObjectStore.getAll() exists;
// but on older browsers, it might not...
const hasGetAll = (store: IDBObjectStore): boolean => "getAll" in store;

class IndexedDBResourceDBConnection extends ResourceDBConnection {
  private connection: IDBDatabase;
  private indexedDB: IDBFactory;
  private dbName: string;
  constructor(connection: IDBDatabase, indexedDB: IDBFactory, dbName: string) {
    super();
    this.connection = connection;
    this.indexedDB = indexedDB;
    this.dbName = dbName;
  }
  private pendingTransactions: IDBTransaction[] = [];
  private setPending(transaction: IDBTransaction): void {
    // Safari workaround; apparently, transactions may be lost without
    // callbacks called unless some reference prevents GC from taking them...
    // https://bugs.webkit.org/show_bug.cgi?id=186180
    this.pendingTransactions.push(transaction);
  }
  private clearPending(transaction: IDBTransaction): void {
    const idx = this.pendingTransactions.indexOf(transaction);
    this.pendingTransactions.splice(idx, 1);
  }
  readQueries(): Promise<{[queryID: number]: QueryParams}> {
    return new Promise<{[queryID: number]: QueryParams}>((resolve, reject) => {
      const result: {[queryID: number]: QueryParams} = {};
      // console.log("readQueries start");
      const transaction = this.connection.transaction(["query"], "readonly");
      this.setPending(transaction);
      transaction.oncomplete = (_event) => {
        // console.log("readQueries complete");
        this.clearPending(transaction);
        resolve(result);
      };
      transaction.onerror = (event) => {
        this.clearPending(transaction);
        reject(new Error(event.type));
      };
      transaction.onabort = (event) => {
        this.clearPending(transaction);
        reject(new Error(event.type));
      };
      const store = transaction.objectStore("query");
      if (hasGetAll(store)) {
        (store.getAll() as IDBRequest).onsuccess = (event) => {
          const entryArray = (event.target as any).result as any[];
          for (let i = 0, len = entryArray.length; i < len; i += 1) {
            const entry = entryArray[i];
            const {id} = entry;
            result[id] = entry;
          }
        };
      } else {
        store.openCursor().onsuccess = (event) => {
          const cursor = (event.target as any).result as IDBCursorWithValue;
          if (cursor) {
            const entry = cursor.value;
            const {id} = entry;
            result[id] = entry;
            cursor.continue();
          }
        };
      }
    });
  }
  readData(): Promise<ResourceInstance[]> {
    return new Promise<ResourceInstance[]>((resolve, reject) => {
      const result: ResourceInstance[] = [];
      // console.log("readData start");
      const transaction = this.connection.transaction(["data"], "readonly");
      this.setPending(transaction);
      transaction.oncomplete = (_event) => {
        // console.log("readData complete");
        this.clearPending(transaction);
        resolve(result);
      };
      transaction.onerror = (event) => {
        this.clearPending(transaction);
        reject(new Error(event.type));
      };
      transaction.onabort = (event) => {
        this.clearPending(transaction);
        reject(new Error(event.type));
      };
      const store = transaction.objectStore("data");
      if (hasGetAll(store)) {
        (store.getAll() as IDBRequest).onsuccess = (event) => {
          const entryArray = (event.target as any).result as ResourceInstance[];
          for (let i = 0, len = entryArray.length; i < len; i += 1) {
            const entry = entryArray[i];
            result.push(entry);
          }
        };
      } else {
        store.openCursor().onsuccess = (event) => {
          const cursor = (event.target as any).result as IDBCursorWithValue;
          if (cursor) {
            const entry = cursor.value as ResourceInstance;
            result.push(entry);
            cursor.continue();
          }
        };
      }
    });
  }
  addQueries(
    queries: ReadonlySet<Query>,
    queryIdAssociation: ReadonlyMap<string, QueryIDStruct>,
  ): Promise<Map<string, QueryIDStruct>> {
    return new Promise<Map<string, QueryIDStruct>>((resolve, reject) => {
      const result = new Map(queryIdAssociation);
      const transaction = this.connection.transaction(["query"], "readwrite");
      this.setPending(transaction);
      transaction.oncomplete = (_event) => {
        this.clearPending(transaction);
        resolve(result);
      };
      transaction.onerror = (event) => {
        this.clearPending(transaction);
        reject(new Error(event.type));
      };
      transaction.onabort = (event) => {
        this.clearPending(transaction);
        reject(new Error(event.type));
      };
      const store = transaction.objectStore("query");
      queries.forEach((query: Query) => {
        const request = store.put(query);
        request.onsuccess = (event) => {
          const id = (event.target as any).result as number;
          console.assert(
            !result.has(query.keyString),
            `already has query with same key: ${query.keyString}`,
          );
          result.set(query.keyString, {id, query});
        };
      });
    });
  }
  deleteQueries(
    queries: ReadonlySet<Query>,
    queryIdAssociation: ReadonlyMap<string, QueryIDStruct>,
  ): Promise<Map<string, QueryIDStruct>> {
    return new Promise<Map<string, QueryIDStruct>>((resolve, reject) => {
      const result = new Map(queryIdAssociation);
      const transaction = this.connection.transaction(["query", "queryTimestamp"], "readwrite");
      this.setPending(transaction);
      transaction.oncomplete = (_event) => {
        this.clearPending(transaction);
        resolve(result);
      };
      transaction.onerror = (event) => {
        this.clearPending(transaction);
        reject(new Error(event.type));
      };
      transaction.onabort = (event) => {
        this.clearPending(transaction);
        reject(new Error(event.type));
      };
      const store = transaction.objectStore("query");
      const timestampStore = transaction.objectStore("queryTimestamp");
      queries.forEach((query: Query) => {
        const entry = result.get(query.keyString);
        if (!entry) {
          throw new Error(`unknown Query for deletion: ${query.queryString}`);
        }
        const {id} = entry;
        store.delete(id);
        timestampStore.delete(id);
        result.delete(query.keyString);
      });
    });
  }
  mergeData(instances: ReadonlyMap<string, ResourceInstance>): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const transaction = this.connection.transaction(["data"], "readwrite");
      this.setPending(transaction);
      transaction.oncomplete = (_event) => {
        this.clearPending(transaction);
        resolve();
      };
      transaction.onerror = (event) => {
        this.clearPending(transaction);
        reject(new Error(event.type));
      };
      transaction.onabort = (event) => {
        this.clearPending(transaction);
        reject(new Error(event.type));
      };
      const store = transaction.objectStore("data");
      instances.forEach((instance: ResourceInstance) => {
        store.put(instance);
      });
    });
  }
  deleteData(urls: ReadonlySet<string>): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const transaction = this.connection.transaction(["data"], "readwrite");
      this.setPending(transaction);
      transaction.oncomplete = (_event) => {
        this.clearPending(transaction);
        resolve();
      };
      transaction.onerror = (event) => {
        this.clearPending(transaction);
        reject(new Error(event.type));
      };
      transaction.onabort = (event) => {
        this.clearPending(transaction);
        reject(new Error(event.type));
      };
      const store = transaction.objectStore("data");
      urls.forEach((url: string) => {
        store.delete(url);
      });
    });
  }
  // we override an abstract method, it's OK...
  // eslint-disable-next-line class-methods-use-this
  vacuum(): Promise<void> {
    return Promise.resolve();
  }
  setQueryTimestamps(
    queriesFullFetchTimestamps: readonly QueryTimestampsStruct[],
    queryIdAssociation: ReadonlyMap<string, QueryIDStruct>,
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const entries: {
        fullTimestamp: string | null;
        queryID: number;
        updateTimestamp: string | null;
      }[] = [];
      queriesFullFetchTimestamps.forEach(({fullTimestamp, query, updateTimestamp}) => {
        const entry = queryIdAssociation.get(query.keyString);
        if (entry) {
          entries.push({fullTimestamp, queryID: entry.id, updateTimestamp});
        }
      });
      if (!entries.length) {
        resolve();
        return;
      }
      const transaction = this.connection.transaction(["queryTimestamp"], "readwrite");
      this.setPending(transaction);
      transaction.oncomplete = (_event) => {
        this.clearPending(transaction);
        resolve();
      };
      transaction.onerror = (event) => {
        this.clearPending(transaction);
        reject(new Error(event.type));
      };
      transaction.onabort = (event) => {
        this.clearPending(transaction);
        reject(new Error(event.type));
      };
      const store = transaction.objectStore("queryTimestamp");
      for (let i = 0, len = entries.length; i < len; i += 1) {
        const entry = entries[i];
        store.put(entry);
      }
    });
  }
  readQueryTimestamps(): Promise<{
    [queryID: number]: {fullTimestamp: string; updateTimestamp: string};
  }> {
    return new Promise((resolve, reject) => {
      const result: {
        [queryID: number]: {fullTimestamp: string; updateTimestamp: string};
      } = {};
      const transaction = this.connection.transaction(["queryTimestamp"], "readonly");
      this.setPending(transaction);
      transaction.oncomplete = (_event) => {
        this.clearPending(transaction);
        resolve(result);
      };
      transaction.onerror = (event) => {
        this.clearPending(transaction);
        reject(new Error(event.type));
      };
      transaction.onabort = (event) => {
        this.clearPending(transaction);
        reject(new Error(event.type));
      };
      const store = transaction.objectStore("queryTimestamp");
      if (hasGetAll(store)) {
        (store.getAll() as IDBRequest).onsuccess = (event) => {
          const entryArray = (event.target as any).result as any[];
          for (let i = 0, len = entryArray.length; i < len; i += 1) {
            const entry = entryArray[i];
            const id = entry.queryID as number;
            const fullTimestamp = entry.fullTimestamp as string;
            const updateTimestamp = entry.updateTimestamp as string;
            result[id] = {fullTimestamp, updateTimestamp};
          }
        };
      } else {
        store.openCursor().onsuccess = (event) => {
          const cursor = (event.target as any).result as IDBCursorWithValue;
          if (cursor) {
            const entry = cursor.value;
            const id = entry.queryID as number;
            const fullTimestamp = entry.fullTimestamp as string;
            const updateTimestamp = entry.updateTimestamp as string;
            result[id] = {fullTimestamp, updateTimestamp};
            cursor.continue();
          }
        };
      }
    });
  }
  mergeIdFetch(ids: ReadonlyMap<ResourceName, ReadonlySet<string>>): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const transaction = this.connection.transaction(["fetchById"], "readwrite");
      this.setPending(transaction);
      transaction.oncomplete = (_event) => {
        this.clearPending(transaction);
        resolve();
      };
      transaction.onerror = (event) => {
        this.clearPending(transaction);
        reject(new Error(event.type));
      };
      transaction.onabort = (event) => {
        this.clearPending(transaction);
        reject(new Error(event.type));
      };
      const store = transaction.objectStore("fetchById");
      ids.forEach((resourceIds, resourceName) => {
        resourceIds.forEach((id) => {
          store.put({id, resourceName});
        });
      });
    });
  }
  deleteIdFetch(ids: ReadonlyMap<ResourceName, ReadonlySet<string>>): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const transaction = this.connection.transaction(["fetchById"], "readwrite");
      this.setPending(transaction);
      transaction.oncomplete = (_event) => {
        this.clearPending(transaction);
        resolve();
      };
      transaction.onerror = (event) => {
        this.clearPending(transaction);
        reject(new Error(event.type));
      };
      transaction.onabort = (event) => {
        this.clearPending(transaction);
        reject(new Error(event.type));
      };
      const store = transaction.objectStore("fetchById");
      ids.forEach((resourceIds, resourceName) => {
        resourceIds.forEach((id) => {
          store.delete([resourceName, id]);
        });
      });
    });
  }
  readIdFetch(): Promise<ReadonlyMap<ResourceName, ReadonlySet<string>>> {
    return new Promise<ReadonlyMap<ResourceName, ReadonlySet<string>>>((resolve, reject) => {
      const result = new Map<ResourceName, Set<string>>();
      const transaction = this.connection.transaction(["fetchById"], "readonly");
      this.setPending(transaction);
      transaction.oncomplete = (_event) => {
        this.clearPending(transaction);
        resolve(result);
      };
      transaction.onerror = (event) => {
        this.clearPending(transaction);
        reject(new Error(event.type));
      };
      transaction.onabort = (event) => {
        this.clearPending(transaction);
        reject(new Error(event.type));
      };
      const store = transaction.objectStore("fetchById");
      if (hasGetAll(store)) {
        (store.getAll() as IDBRequest).onsuccess = (event) => {
          const entryArray = (event.target as any).result as any[];
          for (let i = 0, len = entryArray.length; i < len; i += 1) {
            const entry = entryArray[i] as {
              id: string;
              resourceName: ResourceName;
            };
            const {id, resourceName} = entry;
            const existing = result.get(resourceName);
            if (existing) {
              existing.add(id);
            } else {
              result.set(resourceName, new Set([id]));
            }
          }
        };
      } else {
        store.openCursor().onsuccess = (event) => {
          const cursor = (event.target as any).result as IDBCursorWithValue;
          if (cursor) {
            const entry = cursor.value as {
              id: string;
              resourceName: ResourceName;
            };
            const {id, resourceName} = entry;
            const existing = result.get(resourceName);
            if (existing) {
              existing.add(id);
            } else {
              result.set(resourceName, new Set([id]));
            }
            cursor.continue();
          }
        };
      }
    });
  }
  mergeRelatedFetch(
    values: ReadonlyMap<ResourceName, ReadonlyMap<string, ReadonlySet<string>>>,
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const transaction = this.connection.transaction(["fetchByRelated"], "readwrite");
      this.setPending(transaction);
      transaction.oncomplete = (_event) => {
        this.clearPending(transaction);
        resolve();
      };
      transaction.onerror = (event) => {
        this.clearPending(transaction);
        reject(new Error(event.type));
      };
      transaction.onabort = (event) => {
        this.clearPending(transaction);
        reject(new Error(event.type));
      };
      const store = transaction.objectStore("fetchByRelated");
      values.forEach((resourceRelations, resourceName) => {
        resourceRelations.forEach((ids, memberName) => {
          ids.forEach((id) => {
            store.put({id, memberName, resourceName});
          });
        });
      });
    });
  }
  deleteRelatedFetch(
    values: ReadonlyMap<ResourceName, ReadonlyMap<string, ReadonlySet<string>>>,
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const transaction = this.connection.transaction(["fetchByRelated"], "readwrite");
      this.setPending(transaction);
      transaction.oncomplete = (_event) => {
        this.clearPending(transaction);
        resolve();
      };
      transaction.onerror = (event) => {
        this.clearPending(transaction);
        reject(new Error(event.type));
      };
      transaction.onabort = (event) => {
        this.clearPending(transaction);
        reject(new Error(event.type));
      };
      const store = transaction.objectStore("fetchByRelated");
      values.forEach((resourceRelations, resourceName) => {
        resourceRelations.forEach((ids, memberName) => {
          ids.forEach((id) => {
            store.delete([resourceName, memberName, id]);
          });
        });
      });
    });
  }
  readRelatedFetch(): Promise<ReadonlyMap<ResourceName, ReadonlyMap<string, ReadonlySet<string>>>> {
    return new Promise<ReadonlyMap<ResourceName, ReadonlyMap<string, ReadonlySet<string>>>>(
      (resolve, reject) => {
        const result = new Map<ResourceName, Map<string, Set<string>>>();
        const transaction = this.connection.transaction(["fetchByRelated"], "readonly");
        this.setPending(transaction);
        transaction.oncomplete = (_event) => {
          this.clearPending(transaction);
          resolve(result);
        };
        transaction.onerror = (event) => {
          this.clearPending(transaction);
          reject(new Error(event.type));
        };
        transaction.onabort = (event) => {
          this.clearPending(transaction);
          reject(new Error(event.type));
        };
        const store = transaction.objectStore("fetchByRelated");
        if (hasGetAll(store)) {
          (store.getAll() as IDBRequest).onsuccess = (event) => {
            const entryArray = (event.target as any).result as any[];
            for (let i = 0, len = entryArray.length; i < len; i += 1) {
              const entry = entryArray[i] as {
                id: string;
                memberName: string;
                resourceName: ResourceName;
              };
              const {id, memberName, resourceName} = entry;
              const existingForResource = result.get(resourceName);
              if (existingForResource) {
                const existingForResourceMember = existingForResource.get(memberName);
                if (existingForResourceMember) {
                  existingForResourceMember.add(id);
                } else {
                  existingForResource.set(memberName, new Set([id]));
                }
              } else {
                result.set(resourceName, new Map([[memberName, new Set([id])]]));
              }
            }
          };
        } else {
          store.openCursor().onsuccess = (event) => {
            const cursor = (event.target as any).result as IDBCursorWithValue;
            if (cursor) {
              const entry = cursor.value as {
                id: string;
                memberName: string;
                resourceName: ResourceName;
              };
              const {id, memberName, resourceName} = entry;
              const existingForResource = result.get(resourceName);
              if (existingForResource) {
                const existingForResourceMember = existingForResource.get(memberName);
                if (existingForResourceMember) {
                  existingForResourceMember.add(id);
                } else {
                  existingForResource.set(memberName, new Set([id]));
                }
              } else {
                result.set(resourceName, new Map([[memberName, new Set([id])]]));
              }
              cursor.continue();
            }
          };
        }
      },
    );
  }
  getChangesTimestamp(): Promise<string | null> {
    return new Promise<string | null>((resolve, reject) => {
      let result: string | null = null;
      const transaction = this.connection.transaction(["changesTimestamp"], "readonly");
      this.setPending(transaction);
      transaction.oncomplete = (_event) => {
        this.clearPending(transaction);
        resolve(result);
      };
      transaction.onerror = (event) => {
        this.clearPending(transaction);
        reject(new Error(event.type));
      };
      transaction.onabort = (event) => {
        this.clearPending(transaction);
        reject(new Error(event.type));
      };
      const store = transaction.objectStore("changesTimestamp");
      store.get(CHANGES_KEY).onsuccess = (event) => {
        const entry = (event.target as any).result;
        if (entry) {
          result = entry.timestamp;
        }
      };
    });
  }
  setChangesTimestamp(timestamp: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const transaction = this.connection.transaction(["changesTimestamp"], "readwrite");
      this.setPending(transaction);
      transaction.oncomplete = (_event) => {
        this.clearPending(transaction);
        resolve();
      };
      transaction.onerror = (event) => {
        this.clearPending(transaction);
        reject(new Error(event.type));
      };
      transaction.onabort = (event) => {
        this.clearPending(transaction);
        reject(new Error(event.type));
      };
      const store = transaction.objectStore("changesTimestamp");
      store.put({key: CHANGES_KEY, timestamp});
    });
  }
  close(): Promise<void> {
    this.connection.close();
    return Promise.resolve(undefined);
  }
  deleteDatabase(): Promise<void> {
    return new Promise((resolve, reject) => {
      const request = this.indexedDB.deleteDatabase(this.dbName);
      request.onsuccess = (_event) => {
        resolve();
      };
      request.onerror = (event) => {
        reject(new Error(event.type));
      };
    });
  }
}

export const getIndexedDBConnection = (
  indexedDB: IDBFactory,
  dbName: string,
): Promise<ResourceDBConnection> =>
  // Workaround for Safari 14 IndexedDB
  idbReady()
    .then(() => openConnection(indexedDB, dbName))
    .then((connection) => new IndexedDBResourceDBConnection(connection, indexedDB, dbName));
