import {
  CustomerUrl,
  FuelSurchargeKrPerLiterInvoiceData,
  FuelSurchargePricePercentInvoiceData,
  KrPerLiterDefaultFuelSurchargeUse,
  KrPerLiterMachineFuelSurchargeUse,
  KrPerLiterWorkTypeFuelSurchargeUse,
  MachineUrl,
  PriceGroupUrl,
  PriceItem,
  PriceItemUrl,
  PriceItemUse,
  PricePercentDefaultFuelSurchargeUse,
  PricePercentMachineFuelSurchargeUse,
  PricePercentWorkTypeFuelSurchargeUse,
  RouteTask,
  RouteTaskResult,
  Task,
  Unit,
  UnitUrl,
  WorkTypeUrl,
  urlToId,
} from "@co-common-libs/resources";
import {
  getFuelSurchargeKrPerLiterInvoiceData,
  getFuelSurchargePricePercentInvoiceData,
  getUnitString,
} from "@co-common-libs/resources-utils";
import {caseAccentInsensitiveCollator} from "@co-common-libs/utils";
import {
  getCustomerLookup,
  getCustomerSettings,
  getKrPerLiterDefaultFuelSurchargeUseArray,
  getKrPerLiterFuelSurchargeBasisArray,
  getKrPerLiterFuelSurchargeSpecificationEntryArray,
  getKrPerLiterFuelSurchargeSpecificationLookup,
  getKrPerLiterMachineFuelSurchargeUseArray,
  getKrPerLiterWorkTypeFuelSurchargeUseArray,
  getLocationLookup,
  getMachineLookup,
  getPriceItemLookup,
  getPricePercentDefaultFuelSurchargeUseArray,
  getPricePercentFuelSurchargeBasisArray,
  getPricePercentFuelSurchargeSpecificationEntryArray,
  getPricePercentFuelSurchargeSpecificationLookup,
  getPricePercentMachineFuelSurchargeUseArray,
  getPricePercentWorkTypeFuelSurchargeUseArray,
  getProductGroupLookup,
  getProductLookup,
  getUnitLookup,
} from "@co-frontend-libs/redux";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import React from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {CORRECTED_COLUMN_WIDTH, COUNT_COLUMN_WIDTH, UNIT_COLUMN_WIDTH} from "./constants";
import {RouteTaskKrPerLiterSurchargeRow} from "./route-task-kr-per-liter-surcharge-row";
import {RouteTaskPricePercentSurchargeRow} from "./route-task-price-percent-surcharge-row";
import {RouteTaskResultRow} from "./route-task-result-row";
import {RouteTaskRow} from "./route-task-row";

interface FuelSurchargePricePercentIssueData {
  readonly [priceItemOrProductUrl: string]: {
    readonly text: string;
    readonly useRule: {
      readonly customer: CustomerUrl | null;
      readonly machine: MachineUrl | null;
      readonly variant: PriceGroupUrl | null;
      readonly workType: WorkTypeUrl | null;
    };
  };
}

type PricePercentFuelSurchargeUsePart = Partial<
  Pick<PricePercentMachineFuelSurchargeUse, "customer" | "machine" | "variant">
> &
  Partial<Pick<PricePercentWorkTypeFuelSurchargeUse, "customer" | "variant" | "workType">> &
  Pick<PricePercentDefaultFuelSurchargeUse, "customer">;

interface FuelSurchargeKrPerLiterIssueData {
  readonly [machine: MachineUrl]: {
    readonly missingFuelConsumptionLiterPerHour: boolean;
    readonly missingSpecificationEntry: boolean;
    readonly text: string;
    readonly useRule: {
      readonly customer: CustomerUrl | null;
      readonly machine: MachineUrl | null;
      readonly variant: PriceGroupUrl | null;
      readonly workType: WorkTypeUrl | null;
    };
  };
}

type KrPerLiterFuelSurchargeUsePart = Partial<
  Pick<KrPerLiterWorkTypeFuelSurchargeUse, "customer" | "variant" | "workType">
> &
  Partial<Pick<KrPerLiterMachineFuelSurchargeUse, "customer" | "machine" | "variant">> &
  Pick<KrPerLiterDefaultFuelSurchargeUse, "customer">;

function routeTaskResultData(
  routeTaskResult: RouteTaskResult,
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined,
  unitLookup: (url: UnitUrl) => Unit | undefined,
): {
  billable: boolean;
  corrected: number | null;
  order: number;
  priceItemName: string | null;
  quantity: number | null;
  unitString: string;
  url: string;
} {
  const {corrected, order, quantity, url} = routeTaskResult;
  const priceItemURL = routeTaskResult.specification;
  const priceItem = priceItemLookup(priceItemURL);
  const priceItemName = priceItem ? priceItem.name : null;
  const unitString = getUnitString(priceItem, unitLookup);
  const billable = !priceItem || priceItem.billable !== false;
  return {
    billable,
    corrected,
    order,
    priceItemName,
    quantity,
    unitString,
    url,
  };
}

interface RouteInvoiceLineTableContentProps {
  completedRouteTaskList: readonly RouteTask[];
  readonly: boolean;
  routeTaskList: readonly RouteTask[];
  routeTaskResultSet: ReadonlySet<RouteTaskResult>;
  task: Task;
}

export const RouteInvoiceLineTableContent = React.memo(function RouteInvoiceLineTableContent(
  props: RouteInvoiceLineTableContentProps,
): JSX.Element {
  const {completedRouteTaskList, readonly, routeTaskResultSet, task} = props;

  const intl = useIntl();

  const customerSettings = useSelector(getCustomerSettings);
  const customerLookup = useSelector(getCustomerLookup);
  const locationLookup = useSelector(getLocationLookup);
  const priceItemLookup = useSelector(getPriceItemLookup);
  const unitLookup = useSelector(getUnitLookup);
  const productLookup = useSelector(getProductLookup);
  const productGroupLookup = useSelector(getProductGroupLookup);

  const pricePercentFuelSurchargeSpecificationLookup = useSelector(
    getPricePercentFuelSurchargeSpecificationLookup,
  );
  const pricePercentMachineFuelSurchargeUseArray = useSelector(
    getPricePercentMachineFuelSurchargeUseArray,
  );
  const pricePercentWorkTypeFuelSurchargeUseArray = useSelector(
    getPricePercentWorkTypeFuelSurchargeUseArray,
  );
  const pricePercentDefaultFuelSurchargeUseArray = useSelector(
    getPricePercentDefaultFuelSurchargeUseArray,
  );
  const pricePercentFuelSurchargeSpecificationEntryArray = useSelector(
    getPricePercentFuelSurchargeSpecificationEntryArray,
  );
  const pricePercentFuelSurchargeBasisArray = useSelector(getPricePercentFuelSurchargeBasisArray);

  const machineLookup = useSelector(getMachineLookup);

  const krPerLiterFuelSurchargeSpecificationLookup = useSelector(
    getKrPerLiterFuelSurchargeSpecificationLookup,
  );
  const krPerLiterMachineFuelSurchargeUseArray = useSelector(
    getKrPerLiterMachineFuelSurchargeUseArray,
  );
  const krPerLiterWorkTypeFuelSurchargeUseArray = useSelector(
    getKrPerLiterWorkTypeFuelSurchargeUseArray,
  );
  const krPerLiterDefaultFuelSurchargeUseArray = useSelector(
    getKrPerLiterDefaultFuelSurchargeUseArray,
  );
  const krPerLiterFuelSurchargeSpecificationEntryArray = useSelector(
    getKrPerLiterFuelSurchargeSpecificationEntryArray,
  );
  const krPerLiterFuelSurchargeBasisArray = useSelector(getKrPerLiterFuelSurchargeBasisArray);

  const backendMaxDigits = 10;
  const backendDecimalPlaces = 3;
  const integerPartDigits = backendMaxDigits - backendDecimalPlaces;
  const decimalPlaces = customerSettings.transportLogDecimals;
  console.assert(decimalPlaces <= backendDecimalPlaces);
  const maxDigits = integerPartDigits + decimalPlaces;
  const entries: JSX.Element[] = [];
  const routeTaskResultsPerRouteTask = new Map<string, Set<RouteTaskResult>>();
  routeTaskResultSet.forEach((routeTaskResult) => {
    const key = routeTaskResult.routeTask;
    const resultsForTask = routeTaskResultsPerRouteTask.get(key);
    if (resultsForTask) {
      resultsForTask.add(routeTaskResult);
    } else {
      routeTaskResultsPerRouteTask.set(key, new Set([routeTaskResult]));
    }
  });

  const {fuelSurcharge} = customerSettings;

  completedRouteTaskList.forEach((routeTask) => {
    const routeTaskURL = routeTask.url;
    entries.push(
      <RouteTaskRow
        key={routeTaskURL}
        customerLookup={customerLookup}
        locationLookup={locationLookup}
        routeTask={routeTask}
      />,
    );
    const results = routeTaskResultsPerRouteTask.get(routeTaskURL);
    if (results) {
      const taskResults: {
        billable: boolean;
        corrected: number | null;
        order: number;
        priceItemName: string | null;
        quantity: number | null;
        unitString: string;
        url: string;
      }[] = [];
      results.forEach((routeTaskResult) => {
        taskResults.push(routeTaskResultData(routeTaskResult, priceItemLookup, unitLookup));
      });
      taskResults.sort(
        (a, b) =>
          a.order - b.order ||
          caseAccentInsensitiveCollator.compare(a.unitString, b.unitString) ||
          caseAccentInsensitiveCollator.compare(a.priceItemName || "", b.priceItemName || ""),
      );
      taskResults.forEach(({billable, corrected, priceItemName, quantity, unitString, url}) => {
        entries.push(
          <RouteTaskResultRow
            key={url}
            corrected={corrected != null ? corrected : undefined}
            customerSettings={customerSettings}
            decimalPlaces={decimalPlaces}
            maxDigits={maxDigits}
            priceItemName={priceItemName || ""}
            quantity={quantity != null ? quantity : undefined}
            readonly={readonly || !billable}
            unitString={unitString}
            url={url}
          />,
        );
      });
      let fuelSurchargePricePercentInvoiceData:
        | {
            issues: FuelSurchargePricePercentIssueData;
            pricePercent: FuelSurchargePricePercentInvoiceData;
          }
        | undefined;
      if (task.recordedInC5 && task.invoiceData?.fuelSurcharge) {
        if (task.invoiceData.fuelSurcharge.route) {
          const pricePercent = task.invoiceData.fuelSurcharge.route[routeTask.url]?.pricePercent;
          if (pricePercent) {
            fuelSurchargePricePercentInvoiceData = {
              issues: {},
              pricePercent,
            };
          }
        }
      } else if (task.workFromTimestamp && fuelSurcharge === "PRICE_PERCENT") {
        const taskPriceItemUseEntries = Array.from(results)
          .sort((a, b) => a.order - b.order)
          .map(
            (
              result,
            ): [
              string,
              Pick<PriceItemUse, "dangling" | "machine" | "priceGroup" | "priceItem" | "workType">,
            ] => {
              return [
                urlToId(result.url),
                {
                  dangling: false,
                  machine: null,
                  priceGroup: routeTask.activity,
                  priceItem: result.specification,
                  workType: task.workType,
                },
              ];
            },
          );
        const data = getFuelSurchargePricePercentInvoiceData(
          intl,
          priceItemLookup,
          productLookup,
          productGroupLookup,
          pricePercentFuelSurchargeSpecificationLookup,
          pricePercentMachineFuelSurchargeUseArray,
          pricePercentWorkTypeFuelSurchargeUseArray,
          pricePercentDefaultFuelSurchargeUseArray,
          pricePercentFuelSurchargeSpecificationEntryArray,
          pricePercentFuelSurchargeBasisArray,
          {
            priceGroup: routeTask.activity,
            priceItemUses: Object.fromEntries(taskPriceItemUseEntries),
            productUses: {},
            workFromTimestamp: task.workFromTimestamp,
            workType: task.workType,
          },
          routeTask.customer,
        );
        const issues: FuelSurchargePricePercentIssueData = Object.fromEntries(
          Array.from(data.issues).map(
            ([priceItemOrProductUrl, {specification, use}]): [
              string,
              FuelSurchargePricePercentIssueData[string],
            ] => {
              const usePart: PricePercentFuelSurchargeUsePart = use;
              const product = specification.invoiceLineProduct
                ? productLookup(specification.invoiceLineProduct)
                : null;
              const text = product ? product.name : specification.name;
              return [
                priceItemOrProductUrl,
                {
                  text,
                  useRule: {
                    customer: usePart.customer,
                    machine: usePart.machine || null,
                    variant: usePart.variant || null,
                    workType: usePart.workType || null,
                  },
                },
              ];
            },
          ),
        );
        fuelSurchargePricePercentInvoiceData = {
          issues,
          pricePercent: data.pricePercent,
        };
      }
      if (fuelSurchargePricePercentInvoiceData) {
        const specificationsUses = new Map<
          string,
          {
            readonly text: string;
            readonly uses: Map<
              string,
              {
                readonly customer: CustomerUrl | null;
                readonly machine?: MachineUrl | null;
                readonly variant?: PriceGroupUrl | null;
                readonly workType?: WorkTypeUrl | null;
              }
            >;
            readonly value: number | null;
          }
        >();
        Object.values(fuelSurchargePricePercentInvoiceData.pricePercent).forEach(
          ({resultingPercent: value, text, useRule}) => {
            const key = `${text}-${value}`;
            const useKey = `${useRule.customer}\0${useRule.machine}\0${useRule.variant}\0${useRule.workType}`;
            const existingEntry = specificationsUses.get(key);
            if (existingEntry) {
              existingEntry.uses.set(useKey, useRule);
            } else {
              specificationsUses.set(key, {
                text,
                uses: new Map([[useKey, useRule]]),
                value,
              });
            }
          },
        );
        Array.from(specificationsUses).forEach(([key, {text, uses, value}]) => {
          entries.push(
            <RouteTaskPricePercentSurchargeRow
              key={key}
              missingSpecificationEntry={false}
              text={text}
              uses={new Set(uses.values())}
              value={value}
            />,
          );
        });
        const issueSpecificationsUses = new Map<
          string,
          {
            readonly text: string;
            readonly uses: Map<
              string,
              {
                readonly customer: CustomerUrl | null;
                readonly machine?: MachineUrl | null;
                readonly variant?: PriceGroupUrl | null;
                readonly workType?: WorkTypeUrl | null;
              }
            >;
          }
        >();
        Object.values(fuelSurchargePricePercentInvoiceData.issues).forEach(({text, useRule}) => {
          const key = text;
          const useKey = `${useRule.customer}\0${useRule.machine}\0${useRule.variant}\0${useRule.workType}`;
          const existingEntry = issueSpecificationsUses.get(key);
          if (existingEntry) {
            existingEntry.uses.set(useKey, useRule);
          } else {
            issueSpecificationsUses.set(key, {
              text,
              uses: new Map([[useKey, useRule]]),
            });
          }
        });
        Array.from(issueSpecificationsUses).forEach(([key, {text, uses}]) => {
          entries.push(
            <RouteTaskPricePercentSurchargeRow
              key={key}
              missingSpecificationEntry
              text={text}
              uses={new Set(uses.values())}
              value={null}
            />,
          );
        });
      }
    }
    let fuelSurchargeKrPerLiterInvoiceData:
      | {
          issues: FuelSurchargeKrPerLiterIssueData;
          krPerLiter: FuelSurchargeKrPerLiterInvoiceData;
        }
      | undefined;
    if (task.recordedInC5 && task.invoiceData?.fuelSurcharge) {
      if (task.invoiceData.fuelSurcharge.route) {
        const krPerLiter = task.invoiceData.fuelSurcharge.route[routeTask.url]?.krPerLiter;
        if (krPerLiter) {
          fuelSurchargeKrPerLiterInvoiceData = {
            issues: {},
            krPerLiter,
          };
        }
      }
    } else if (task.workFromTimestamp && fuelSurcharge === "KR_PER_LITER") {
      const data = getFuelSurchargeKrPerLiterInvoiceData(
        intl,
        machineLookup,
        productLookup,
        krPerLiterFuelSurchargeSpecificationLookup,
        krPerLiterMachineFuelSurchargeUseArray,
        krPerLiterWorkTypeFuelSurchargeUseArray,
        krPerLiterDefaultFuelSurchargeUseArray,
        krPerLiterFuelSurchargeSpecificationEntryArray,
        krPerLiterFuelSurchargeBasisArray,
        {
          machineuseSet: task.machineuseSet,
          priceGroup: routeTask.activity,
          workFromTimestamp: task.workFromTimestamp,
          workType: task.workType,
        },
        routeTask.customer,
      );
      const issues: FuelSurchargeKrPerLiterIssueData = Object.fromEntries(
        Array.from(data.issues).map(
          ([
            priceItemOrProductUrl,
            {fuelConsumptionLiterPerHour, specification, specificationEntry, use},
          ]): [string, FuelSurchargeKrPerLiterIssueData[MachineUrl]] => {
            const usePart: KrPerLiterFuelSurchargeUsePart = use;
            const product = specification.invoiceLineProduct
              ? productLookup(specification.invoiceLineProduct)
              : null;
            const text = product ? product.name : specification.name;
            return [
              priceItemOrProductUrl,
              {
                missingFuelConsumptionLiterPerHour: fuelConsumptionLiterPerHour === null,
                missingSpecificationEntry: specificationEntry === null,
                text,
                useRule: {
                  customer: usePart.customer,
                  machine: usePart.machine || null,
                  variant: usePart.variant || null,
                  workType: usePart.workType || null,
                },
              },
            ];
          },
        ),
      );
      fuelSurchargeKrPerLiterInvoiceData = {
        issues,
        krPerLiter: data.krPerLiter,
      };
    }
    if (fuelSurchargeKrPerLiterInvoiceData) {
      const specificationsUses = new Map<
        string,
        {
          readonly text: string;
          readonly uses: Map<
            string,
            {
              readonly customer: CustomerUrl | null;
              readonly machine?: MachineUrl | null;
              readonly variant?: PriceGroupUrl | null;
              readonly workType?: WorkTypeUrl | null;
            }
          >;
          readonly value: number | null;
        }
      >();
      Object.values(fuelSurchargeKrPerLiterInvoiceData.krPerLiter).forEach(
        ({krPerLiter: value, text, useRule}) => {
          const key = `${routeTask.url}-${text}-${value}`;
          const useKey = `${useRule.customer}\0${useRule.machine}\0${useRule.variant}\0${useRule.workType}`;
          const existingEntry = specificationsUses.get(key);
          if (existingEntry) {
            existingEntry.uses.set(useKey, useRule);
          } else {
            specificationsUses.set(key, {
              text,
              uses: new Map([[useKey, useRule]]),
              value,
            });
          }
        },
      );
      Array.from(specificationsUses).forEach(([key, {text, uses, value}]) => {
        entries.push(
          <RouteTaskKrPerLiterSurchargeRow
            key={key}
            missingFuelConsumptionLiterPerHour={false}
            missingSpecificationEntry={false}
            text={text}
            uses={new Set(uses.values())}
            value={value}
          />,
        );
      });
      const issueSpecificationsUses = new Map<
        string,
        {
          readonly missingFuelConsumptionLiterPerHour: boolean;
          readonly missingSpecificationEntry: boolean;
          readonly text: string;
          readonly uses: Map<
            string,
            {
              readonly customer: CustomerUrl | null;
              readonly machine?: MachineUrl | null;
              readonly variant?: PriceGroupUrl | null;
              readonly workType?: WorkTypeUrl | null;
            }
          >;
        }
      >();
      Object.values(fuelSurchargeKrPerLiterInvoiceData.issues).forEach(
        ({missingFuelConsumptionLiterPerHour, missingSpecificationEntry, text, useRule}) => {
          const key = text;
          const useKey = `${useRule.customer}\0${useRule.machine}\0${useRule.variant}\0${useRule.workType}`;
          const existingEntry = issueSpecificationsUses.get(key);
          if (existingEntry) {
            existingEntry.uses.set(useKey, useRule);
          } else {
            issueSpecificationsUses.set(key, {
              missingFuelConsumptionLiterPerHour,
              missingSpecificationEntry,
              text,
              uses: new Map([[useKey, useRule]]),
            });
          }
        },
      );
      Array.from(issueSpecificationsUses).forEach(
        ([key, {missingFuelConsumptionLiterPerHour, missingSpecificationEntry, text, uses}]) => {
          entries.push(
            <RouteTaskKrPerLiterSurchargeRow
              key={key}
              missingFuelConsumptionLiterPerHour={missingFuelConsumptionLiterPerHour}
              missingSpecificationEntry={missingSpecificationEntry}
              text={text}
              uses={new Set(uses.values())}
              value={null}
            />,
          );
        },
      );
    }
  });
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell style={{width: COUNT_COLUMN_WIDTH}}>
            <FormattedMessage defaultMessage="Antal" id="route-task.table-header.count" />
          </TableCell>
          <TableCell style={{width: CORRECTED_COLUMN_WIDTH}}>
            <FormattedMessage defaultMessage="Faktureres" id="route-task.table-header.corrected" />
          </TableCell>
          <TableCell style={{width: UNIT_COLUMN_WIDTH}}>
            <FormattedMessage defaultMessage="Enhed" id="route-task.table-header.unit" />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{entries}</TableBody>
    </Table>
  );
});
