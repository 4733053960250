import {Config} from "@co-common-libs/config";
import {
  Customer,
  CustomerUrl,
  Delivery,
  DeliveryLocation,
  Location,
  LocationUrl,
  Pickup,
  PickupLocation,
  TransportLog,
} from "@co-common-libs/resources";
import {formatTime} from "@co-common-libs/utils";
import {
  AppState,
  getCustomerLookup,
  getCustomerSettings,
  getDeliveryArray,
  getDeliveryLocationArray,
  getLocationLookup,
  getPickupArray,
  getPickupLocationArray,
} from "@co-frontend-libs/redux";
import {Button, IconButton} from "@material-ui/core";
import {Linkify} from "app-components";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import _ from "lodash";
import DeleteIcon from "mdi-react/DeleteIcon";
import React from "react";
import {FormattedMessage, IntlContext, defineMessages} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";

const messages = defineMessages({
  addDeliveryLocation: {
    defaultMessage: "Tilføj sted",
    id: "order-instance.label.add-delivery-location",
  },
  addPickupLocation: {
    defaultMessage: "Tilføj sted",
    id: "order-instance.label.add-pickup-location",
  },
  editTransportLog: {
    defaultMessage: "Ret",
    id: "order-instance.label.edit-transport-log",
  },
});

const TIME_COLUMN_STYLE = {width: 86} as const;
const AMOUNT_COLUMN_STYLE = {width: 100} as const;
const DELETE_COLUMN_STYLE = {width: 48} as const;

interface LocationRowProps<L extends DeliveryLocation | PickupLocation> {
  address?: string | undefined;
  areaHa?: number | undefined;
  canDelete: boolean;
  customerName?: string | undefined;
  haRequired?: boolean;
  identifier?: string;
  location: L;
  note?: string;
  onEditLocation: (location: L) => void;
  onLocationDelete: (location: L) => void;
  total?: number | undefined;
  transported?: number;
  unit?: string;
}

class LocationRow<L extends DeliveryLocation | PickupLocation> extends PureComponent<
  LocationRowProps<L>
> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  @bind
  handleEditLocation(): void {
    this.props.onEditLocation(this.props.location);
  }

  @bind
  handleDeleteClick(event: React.MouseEvent<unknown, MouseEvent>): void {
    event.stopPropagation();
    this.props.onLocationDelete(this.props.location);
  }

  render(): JSX.Element {
    const {
      address,
      areaHa,
      canDelete,
      customerName,
      haRequired,
      identifier,
      note,
      total,
      transported,
      unit,
    } = this.props;
    const {formatNumber} = this.context;
    return (
      <tr onClick={this.handleEditLocation}>
        <td>{identifier}</td>
        <td>{address}</td>
        <td>{customerName}</td>
        <td>
          {transported != null ? formatNumber(transported) : ""}/
          {total != null ? formatNumber(total) : ""} {unit}
        </td>
        {haRequired ? (
          <td>
            {areaHa != null ? formatNumber(areaHa) : ""} {areaHa != null ? "ha" : null}
          </td>
        ) : null}
        <td>
          <Linkify>{note}</Linkify>
        </td>
        <td style={DELETE_COLUMN_STYLE}>
          <IconButton disabled={!canDelete || !!transported} onClick={this.handleDeleteClick}>
            <DeleteIcon />
          </IconButton>
        </td>
      </tr>
    );
  }
}

interface TransportLogBlockStateProps {
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  customerSettings: Config;
  deliveryArray: readonly Delivery[];
  deliveryLocationArray: readonly DeliveryLocation[];
  locationLookup: (url: LocationUrl) => Location | undefined;
  pickupArray: readonly Pickup[];
  pickupLocationArray: readonly PickupLocation[];
}

interface TransportLogBlockOwnProps {
  disabled: boolean;
  onRequestDeliveryLocationDialog: (deliveryLocation: DeliveryLocation | null) => void;
  onRequestEditTransportLogDialog: () => void;
  onRequestLocationDelete: (location: DeliveryLocation | PickupLocation) => void;
  onRequestPickupLocationDialog: (pickupLocation: PickupLocation | null) => void;
  transportLog: TransportLog;
}

type TransportLogBlockProps = TransportLogBlockOwnProps & TransportLogBlockStateProps;

class TransportLogBlock extends PureComponent<TransportLogBlockProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  @bind
  handleAddPickupLocation(): void {
    this.props.onRequestPickupLocationDialog(null);
  }
  @bind
  handleAddDeliveryLocation(): void {
    this.props.onRequestDeliveryLocationDialog(null);
  }
  @bind
  handleEditPickupLocation(pickupLocation: PickupLocation): void {
    this.props.onRequestPickupLocationDialog(pickupLocation);
  }
  @bind
  handleEditDeliveryLocation(deliveryLocation: DeliveryLocation): void {
    this.props.onRequestDeliveryLocationDialog(deliveryLocation);
  }
  @bind
  handleEditTransportLog(): void {
    this.props.onRequestEditTransportLogDialog();
  }
  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {
      deliveryArray,
      deliveryLocationArray,
      disabled,
      locationLookup,
      onRequestLocationDelete,
      pickupArray,
      pickupLocationArray,
      transportLog,
    } = this.props;

    const {unit} = transportLog;
    const transportLogURL = transportLog.url;
    const pickupLocations = _.sortBy(
      pickupLocationArray.filter((p) => p.transportlog === transportLogURL),
      (p) => p.order,
    );
    const deliveryLocations = _.sortBy(
      deliveryLocationArray.filter((p) => p.transportlog === transportLogURL),
      (p) => p.order,
    );
    const entryData: {
      amount: number;
      area: number | null;
      distance: number | null;
      note: string;
      place: string;
      timestamp: string | null;
      unit: string;
    }[] = [];
    const pickupLocationRows = pickupLocations.map((pickupLocation, _index) => {
      const identifier = `A${pickupLocation.order + 1}`;
      const location = pickupLocation.relatedLocation
        ? locationLookup(pickupLocation.relatedLocation)
        : null;
      const addressString = location ? location.name || location.address : pickupLocation.address;

      const customerURL = pickupLocation.customer;
      const customer = customerURL ? this.props.customerLookup(customerURL) : null;
      const customerName = customer ? customer.name : null;
      const pickupLocationURL = pickupLocation.url;
      const locationUnit = pickupLocation.unit;
      const transported = pickupArray
        .filter((p) => p.location === pickupLocationURL)
        .reduce((acc, pickup) => {
          entryData.push({
            amount: pickup.amount || 0,
            area: null,
            distance: null,
            note: pickup.note || "",
            place: identifier,
            timestamp: pickup.deviceTimestamp,
            unit: unit || locationUnit || "",
          });
          return acc + (pickup.amount || 0);
        }, 0);
      const total = pickupLocation.amount;
      const {note} = pickupLocation;
      return (
        <LocationRow
          key={pickupLocationURL}
          address={addressString ?? undefined}
          canDelete={!disabled}
          customerName={customerName || undefined}
          identifier={identifier}
          location={pickupLocation}
          note={note}
          total={total != null ? total : undefined}
          transported={transported}
          unit={unit || locationUnit}
          onEditLocation={this.handleEditPickupLocation}
          onLocationDelete={onRequestLocationDelete}
        />
      );
    });
    const deliveryLocationRows = deliveryLocations.map((deliveryLocation, _index) => {
      const identifier = `L${deliveryLocation.order + 1}`;
      const location = deliveryLocation.relatedLocation
        ? locationLookup(deliveryLocation.relatedLocation)
        : null;
      const addressString = location ? location.name || location.address : deliveryLocation.address;

      const {areaHa} = deliveryLocation;
      const customerURL = deliveryLocation.customer;
      const customer = customerURL ? this.props.customerLookup(customerURL) : null;
      const customerName = customer ? customer.name : null;
      const deliveryLocationURL = deliveryLocation.url;
      const locationUnit = deliveryLocation.unit;
      const transported = deliveryArray
        .filter((p) => p.location === deliveryLocationURL)
        .reduce((acc, delivery) => {
          entryData.push({
            amount: delivery.amount || 0,
            area: delivery.areaHa || 0,
            distance: delivery.distanceKm || 0,
            note: delivery.note || "",
            place: identifier,
            timestamp: delivery.deviceTimestamp,
            unit: unit || locationUnit || "",
          });
          return acc + (delivery.amount || 0);
        }, 0);
      const total = deliveryLocation.amount;
      const {note} = deliveryLocation;
      return (
        <LocationRow
          key={deliveryLocationURL}
          address={addressString ?? undefined}
          areaHa={areaHa != null ? areaHa : undefined}
          canDelete={!disabled}
          customerName={customerName || undefined}
          haRequired={transportLog.haRequired}
          identifier={identifier}
          location={deliveryLocation}
          note={note}
          total={total != null ? total : undefined}
          transported={transported}
          unit={unit || locationUnit}
          onEditLocation={this.handleEditDeliveryLocation}
          onLocationDelete={onRequestLocationDelete}
        />
      );
    });
    entryData.sort((entryA, entryB) => {
      // oldest first
      if (entryA.timestamp && entryB.timestamp) {
        return new Date(entryA.timestamp).valueOf() - new Date(entryB.timestamp).valueOf();
      } else if (entryA.timestamp) {
        return -1;
      } else if (entryB.timestamp) {
        return 1;
      } else {
        return 1;
      }
    });
    const {kmRequired} = transportLog;
    const {haRequired} = transportLog;
    const entries = entryData.map((entry, index) => {
      return (
        <tr key={index}>
          <td style={TIME_COLUMN_STYLE}>{formatTime(entry.timestamp || undefined)}</td>
          <td style={AMOUNT_COLUMN_STYLE}>
            {entry.amount} {entry.unit}
          </td>
          <td>{entry.place}</td>
          {kmRequired ? <td>{entry.distance}</td> : null}
          {haRequired ? <td>{entry.area}</td> : null}
          <td>{entry.note}</td>
        </tr>
      );
    });
    return (
      <div>
        <FormattedMessage
          defaultMessage="Transportlog"
          id="order-instance.header.transport-log"
          tagName="h2"
        />
        <table>
          <tbody>
            {this.props.customerSettings.transportLogUnitPerLocation ? null : (
              <tr>
                <td>
                  <FormattedMessage defaultMessage="Enhed:" id="order-instance.label.unit" />
                </td>
                <td>{transportLog.unit}</td>
              </tr>
            )}
            {this.props.customerSettings.transportLogUnitPerLocation ? null : (
              <tr>
                <td>
                  <FormattedMessage
                    defaultMessage="Forventet per tur:"
                    id="order-instance.label.expected-per-trip"
                  />
                </td>
                <td>{transportLog.amountPerTrip}</td>
              </tr>
            )}
            <tr>
              <td>
                <FormattedMessage
                  defaultMessage="Hektar skal angives:"
                  id="order-instance.label.ha-required"
                />
              </td>
              <td>
                {transportLog.haRequired ? (
                  <FormattedMessage defaultMessage="Ja" id="order-instance.label.yes" />
                ) : (
                  <FormattedMessage defaultMessage="Nej" id="order-instance.label.no" />
                )}
              </td>
            </tr>
            <tr>
              <td>
                <FormattedMessage
                  defaultMessage="Kilometer skal angives:"
                  id="order-instance.label.km-required"
                />
              </td>
              <td>
                {transportLog.kmRequired ? (
                  <FormattedMessage defaultMessage="Ja" id="order-instance.label.yes" />
                ) : (
                  <FormattedMessage defaultMessage="Nej" id="order-instance.label.no" />
                )}
              </td>
            </tr>
          </tbody>
        </table>
        <Button
          color="secondary"
          disabled={disabled}
          variant="contained"
          onClick={this.handleEditTransportLog}
        >
          {formatMessage(messages.editTransportLog)}
        </Button>
        <FormattedMessage
          defaultMessage="Afhentningssteder"
          id="order-instance.header.pickup-locations"
          tagName="h3"
        />
        <table style={{borderCollapse: "collapse", width: "100%"}}>
          <thead style={{borderBottom: "1px solid #e0e0e0"}}>
            <tr>
              <td />
              <td>
                <FormattedMessage
                  defaultMessage="Arbejdssted"
                  id="order-instance.table-header.workplace"
                />
              </td>
              <td>
                <FormattedMessage
                  defaultMessage="Kunde"
                  id="order-instance.table-header.customer"
                />
              </td>
              <td>
                <FormattedMessage defaultMessage="Mængde" id="order-instance.table-header.amount" />
              </td>
              <td>
                <FormattedMessage defaultMessage="Note" id="order-instance.table-header.note" />
              </td>
              <td style={DELETE_COLUMN_STYLE} />
            </tr>
          </thead>
          <tbody style={disabled ? {} : {cursor: "pointer"}}>{pickupLocationRows}</tbody>
        </table>
        <Button
          color="primary"
          disabled={disabled}
          variant="contained"
          onClick={this.handleAddPickupLocation}
        >
          {formatMessage(messages.addPickupLocation)}
        </Button>
        <FormattedMessage
          defaultMessage="Leveringssteder"
          id="order-instance.header.delivery-locations"
          tagName="h3"
        />
        <table style={{borderCollapse: "collapse", width: "100%"}}>
          <thead style={{borderBottom: "1px solid #e0e0e0"}}>
            <tr>
              <td />
              <td>
                <FormattedMessage
                  defaultMessage="Arbejdssted"
                  id="order-instance.table-header.workplace"
                />
              </td>
              <td>
                <FormattedMessage
                  defaultMessage="Kunde"
                  id="order-instance.table-header.customer"
                />
              </td>
              <td>
                <FormattedMessage defaultMessage="Mængde" id="order-instance.table-header.amount" />
              </td>
              {transportLog.haRequired ? (
                <td>
                  <FormattedMessage defaultMessage="Areal" id="order-instance.table-header.area" />
                </td>
              ) : null}
              <td>
                <FormattedMessage defaultMessage="Note" id="order-instance.table-header.note" />
              </td>
            </tr>
          </thead>
          <tbody style={disabled ? {} : {cursor: "pointer"}}>{deliveryLocationRows}</tbody>
        </table>
        <Button
          color="primary"
          disabled={disabled}
          variant="contained"
          onClick={this.handleAddDeliveryLocation}
        >
          {formatMessage(messages.addDeliveryLocation)}
        </Button>
        <FormattedMessage
          defaultMessage="Transportlog"
          id="order-instance.header.transport-log"
          tagName="h3"
        />
        <table style={{borderCollapse: "collapse", width: "100%"}}>
          <thead style={{borderBottom: "1px solid #e0e0e0"}}>
            <tr>
              <td style={TIME_COLUMN_STYLE}>
                <FormattedMessage defaultMessage="Kl." id="task-instance.table-header.time" />
              </td>
              <td style={AMOUNT_COLUMN_STYLE}>
                <FormattedMessage defaultMessage="Mængde" id="task-instance.table-header.amount" />
              </td>
              <td>
                <FormattedMessage defaultMessage="Sted" id="task-instance.table-header.place" />
              </td>
              {transportLog.kmRequired ? (
                <td>
                  <FormattedMessage
                    defaultMessage="km"
                    id="task-instance.table-header.distance-km"
                  />
                </td>
              ) : null}
              {transportLog.haRequired ? (
                <td>
                  <FormattedMessage defaultMessage="Areal" id="order-instance.table-header.area" />
                </td>
              ) : null}
              <td>
                <FormattedMessage defaultMessage="Note" id="task-instance.table-header.note" />
              </td>
            </tr>
          </thead>
          <tbody>{entries}</tbody>
        </table>
      </div>
    );
  }
}

const ConnectedTransportLogBlock: React.ComponentType<TransportLogBlockOwnProps> = connect<
  TransportLogBlockStateProps,
  object,
  TransportLogBlockOwnProps,
  AppState
>(
  createStructuredSelector<AppState, TransportLogBlockStateProps>({
    customerLookup: getCustomerLookup,
    customerSettings: getCustomerSettings,
    deliveryArray: getDeliveryArray,
    deliveryLocationArray: getDeliveryLocationArray,
    locationLookup: getLocationLookup,
    pickupArray: getPickupArray,
    pickupLocationArray: getPickupLocationArray,
  }),
  {},
)(TransportLogBlock);

export default ConnectedTransportLogBlock;
