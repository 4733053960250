import {Config} from "@co-common-libs/config";
import {
  Location,
  LocationStorageAdjustment,
  LocationStorageChange,
  LocationStorageStatus,
  LocationType,
  LocationTypeUrl,
  LocationUrl,
  Machine,
  MachineUrl,
  PriceGroup,
  PriceGroupUrl,
  PriceItem,
  PriceItemUrl,
  Product,
  ProductGroup,
  ProductGroupUrl,
  ProductUrl,
  Project,
  RouteTask,
  Timer,
  Unit,
  UnitUrl,
  WorkType,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {
  getMachineString,
  priceItemIsManualDistributionTime,
  priceItemIsTime,
  trackedStorageProductCounts,
} from "@co-common-libs/resources-utils";
import {notNull} from "@co-common-libs/utils";
import {IntlShape} from "react-intl";
import {InlinedTask, InlinedTransportLog} from "../inline-data";
import {computeIntervalSums} from "../task-timers";
import {adjustMinutes} from "../time-helpers";
import {getBreakTimer} from "../timers";
import {getMaterialsIssues} from "./get-issues/get-materials-issues";
import {getTransportlogIssues} from "./get-issues/get-transportlog-issues";
import {COMPLETE_ROUTE_ACTION, ErrorEntry} from "./types";

export const getWarnings = (
  data: {
    genericPrimaryTimer?: Timer | undefined;
    locationArray: readonly Location[];
    locationLookup: (url: LocationUrl) => Location | undefined;
    locationStorageAdjustmentArray: readonly LocationStorageAdjustment[];
    locationStorageChangeArray: readonly LocationStorageChange[];
    locationStorageStatusArray: readonly LocationStorageStatus[];
    locationTypeLookup: (url: LocationTypeUrl) => LocationType | undefined;
    machineArray: readonly Machine[];
    machineLookup: (url: MachineUrl) => Machine | undefined;
    priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
    priceItemArray: readonly PriceItem[];
    priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined;
    productGroupLookup: (url: ProductGroupUrl) => ProductGroup | undefined;
    productLookup: (url: ProductUrl) => Product | undefined;
    productsWithLogData: Set<ProductUrl> | undefined;
    projectArray: readonly Project[];
    readonlyProducts: Set<ProductUrl>;
    routeTaskArray?: readonly RouteTask[];
    secondaryTimers: ReadonlySet<Timer>;
    task: InlinedTask;
    timerArray: readonly Timer[];
    transportLog?: InlinedTransportLog | undefined;
    unitLookup: (url: UnitUrl) => Unit | undefined;
    userIsManager: boolean;
    workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
  },
  customerSettings: Config,
  intl: IntlShape,
): ErrorEntry[] => {
  const {
    adjustBilledMinutes,
    allowMoreThanTwoMachinesForDepartments,
    allowTransportlogAmountMismatch,
    alwaysAllowMoreThanTwoMachines,
    billedBreaks,
    brugerdataSync,
    c5Sync,
    distributionTableAdjustTimeMethod,
    economicSync,
    enableProjects,
    machineOperatorsCanChooseProject,
    materialIssuesErrors,
    navSync,
    onlyEnableProjectsForDepartments,
    projectLabelVariant,
    projectMissingCompleteWarning,
    projectMissingValidateWarning,
    requireWorkplaceIfExists,
    warnIfNoWorkplace,
  } = customerSettings;
  const {
    genericPrimaryTimer,
    locationArray,
    locationLookup,
    locationStorageAdjustmentArray,
    locationStorageChangeArray,
    locationStorageStatusArray,
    locationTypeLookup,
    productLookup,
    projectArray,
    routeTaskArray,
    secondaryTimers,
    task,
    unitLookup,
    userIsManager,
  } = data;
  const {relatedWorkplace} = task;
  const {order} = task;
  const customer = order ? order.customer : undefined;
  const customerURL = customer ? customer.url : undefined;
  const cancelled = !!task.cancelled;
  const {department} = task;
  const finalIntervals = task._intervals;

  const now = new Date();
  const finalSums = computeIntervalSums(finalIntervals, now);

  const warnings: ErrorEntry[] = [];
  if (warnIfNoWorkplace && order && !relatedWorkplace && !order.routePlan) {
    if (requireWorkplaceIfExists && customer) {
      // if requireWorkplaceIfExists check enabled, only show warning for
      // missing workplace if we don't show the error for missing workplace
      const hasRequireWorkplaceError = locationArray.some(
        (l) => l.active && l.customer === customerURL && !l.logOnlyLocation && !l.geojson,
      );

      if (!hasRequireWorkplaceError) {
        warnings.push(intl.formatMessage({defaultMessage: "Arbejdssted mangler"}));
      }
    } else {
      warnings.push(intl.formatMessage({defaultMessage: "Arbejdssted mangler"}));
    }
  }

  if (
    customerSettings.showStorageProductNotOnLocationWarning &&
    !task.logSkipped &&
    task.reportingSpecification &&
    customerSettings.enableLocationStorage
  ) {
    const {reportingLocations, reportingLog} = task;
    const productWarningsWritten: {
      [productURL: string]: string[] | undefined;
    } = {};
    if (reportingLog && reportingLocations) {
      Object.values(reportingLog).forEach((entry) => {
        if (entry.type !== "pickup") {
          return;
        }
        const productUseArray = entry.productUses ? Object.values(entry.productUses) : null;
        if (!productUseArray?.length) {
          return;
        }
        const locationUrl = reportingLocations[entry.location]?.location;
        const location = locationUrl ? locationLookup(locationUrl) : null;
        if (!location) {
          return;
        }
        const storageProductCounts = trackedStorageProductCounts(
          locationStorageStatusArray,
          locationStorageAdjustmentArray,
          locationStorageChangeArray,
          locationTypeLookup,
          location,
        );
        for (const productUse of productUseArray) {
          if (!productUse.count) {
            return;
          }
          if (!productWarningsWritten[productUse.product]?.includes(location.url)) {
            const storageProductCount = storageProductCounts.get(productUse.product);
            let warning: string | null = null;
            if (storageProductCounts.size && !storageProductCounts.has(productUse.product)) {
              const product = productLookup(productUse.product);
              warning = intl.formatMessage(
                {defaultMessage: "Der blev afhentet {product} som ikke findes ved {location}."},
                {
                  location: location.name || location.address,
                  product: product?.name,
                },
              );
            } else if (
              storageProductCount != null &&
              ((task.completed && storageProductCount < 0) ||
                (!task.completed && storageProductCount - productUse.count < 0))
            ) {
              const product = productLookup(productUse.product);
              warning = intl.formatMessage(
                {defaultMessage: "Der blev afhentet {product} som ikke findes ved {location}."},
                {
                  location: location.name || location.address,
                  product: product?.name,
                },
              );
            }
            if (warning) {
              const existing = productWarningsWritten[productUse.product];
              if (existing) {
                existing.push(location.url);
              } else {
                productWarningsWritten[productUse.product] = [location.url];
              }
              warnings.push(warning);
            }
          }
        }
      });
    }
  }

  secondaryTimers.forEach((timer) => {
    const {identifier} = timer;
    if (identifier && timer.warnIfNoTime && !finalSums.get(timer.url)) {
      warnings.push(
        intl.formatMessage(
          {defaultMessage: "Der er ikke registreret tid på: {workTypeName}."},
          {
            workTypeName: timer.label,
          },
        ),
      );
    }
  });
  if (!cancelled && !materialIssuesErrors) {
    const {workType} = task;
    const requireAtLeastOneOptionalPriceItemUseGreaterThanZero =
      workType?.requireAtLeastOneOptionalPriceItemUseGreaterThanZero;
    const requireAtLeastOneOptionalPriceItemUseGreaterThanZeroItemsByGroup = new Map<
      PriceGroupUrl,
      readonly PriceItemUrl[]
    >();
    const machineUseList = task.machineuseSet;
    const taskPriceGroup = task.priceGroup;
    const {priceItemArray} = data;
    machineUseList
      .map((mu) => mu.priceGroup)
      .concat([taskPriceGroup])
      .filter(notNull)
      .filter((pg) => pg.requireAtLeastOneOptionalPriceItemUseGreaterThanZero)
      .forEach((priceGroup) => {
        const {url} = priceGroup;
        requireAtLeastOneOptionalPriceItemUseGreaterThanZeroItemsByGroup.set(
          url,
          priceItemArray
            .filter(
              (priceItem) =>
                priceItem.required === false &&
                !priceItem.requiredGreaterThanZero &&
                !priceItem.genericEffectiveTimerTarget &&
                priceItem.priceGroups &&
                priceItem.priceGroups.includes(url),
            )
            .map((p) => p.url),
        );
      });
    if (
      customerSettings.materialZeroCausesError &&
      customerSettings.materialZeroCausesError.length
    ) {
      const materialIssues = getMaterialsIssues(
        data,
        customerSettings,
        intl,
        undefined,
        new Set(customerSettings.materialZeroCausesError),
        requireAtLeastOneOptionalPriceItemUseGreaterThanZero,
        requireAtLeastOneOptionalPriceItemUseGreaterThanZeroItemsByGroup,
      );
      if (materialIssues) {
        warnings.push(materialIssues);
      }
    } else {
      const materialIssues = getMaterialsIssues(
        data,
        customerSettings,
        intl,
        undefined,
        undefined,
        requireAtLeastOneOptionalPriceItemUseGreaterThanZero,
        requireAtLeastOneOptionalPriceItemUseGreaterThanZeroItemsByGroup,
      );
      if (materialIssues) {
        warnings.push(materialIssues);
      }
    }
  }
  if (allowTransportlogAmountMismatch) {
    const transportLogWarning = data.transportLog
      ? getTransportlogIssues(data.transportLog, intl)
      : null;
    if (transportLogWarning) {
      warnings.push(transportLogWarning);
    }
  }
  if (
    enableProjects &&
    customer &&
    (!onlyEnableProjectsForDepartments.length ||
      onlyEnableProjectsForDepartments.includes(task.department)) &&
    ((projectMissingCompleteWarning && (machineOperatorsCanChooseProject || userIsManager)) ||
      (projectMissingValidateWarning && task.completed))
  ) {
    if (
      !task.project &&
      projectArray.some((p) => p.customer === customerURL && p.access === "open")
    ) {
      warnings.push(
        projectLabelVariant === "PROJECT"
          ? intl.formatMessage({defaultMessage: "Projekt mangler"})
          : intl.formatMessage({defaultMessage: "Sag mangler"}),
      );
    }
  }

  if (customerSettings.warnOnIncompleteRouteParts && routeTaskArray && order && order.routePlan) {
    const taskURL = task.url;
    const someIncomplete = routeTaskArray.some(
      (routeTask) => routeTask.route === taskURL && !routeTask.completed,
    );
    if (someIncomplete) {
      warnings.push({
        action: COMPLETE_ROUTE_ACTION,
        text: intl.formatMessage({defaultMessage: "Ikke alle rutens opgaver er udført"}),
      });
    }
  }

  const allFavoriteMachines: ReadonlySet<MachineUrl> = new Set<MachineUrl>(
    (task.workType?.machines || []).concat(task.priceGroup?.machines || []),
  );
  if (
    task.order &&
    !task.completed &&
    customerSettings.requireFavoriteMachines &&
    allFavoriteMachines.size
  ) {
    const illegalMachines: Machine[] = [];
    task.machineuseSet.forEach((machineUse) => {
      if (machineUse.machine?.url && !allFavoriteMachines.has(machineUse.machine.url)) {
        illegalMachines.push(machineUse.machine);
      }
    });
    if (illegalMachines.length) {
      warnings.push({
        text: intl.formatMessage(
          {defaultMessage: "Følgende maskine(r) bør ikke anvendes på området: {machines}"},
          {
            machines: illegalMachines.map((machine) => getMachineString(machine)).join(", "),
          },
        ),
      });
    }
  }

  if (task.completed) {
    let usesDistributionTable = false;
    const allowMoreThanTwoMachines =
      alwaysAllowMoreThanTwoMachines || allowMoreThanTwoMachinesForDepartments.includes(department);
    if (allowMoreThanTwoMachines) {
      usesDistributionTable =
        (task.priceitemuseSet || []).filter((instance) => {
          const {priceItem} = instance;
          return priceItem && priceItemIsManualDistributionTime(unitLookup, priceItem);
        }).length > 1;
    }

    const genericPrimaryTimerURL = genericPrimaryTimer ? genericPrimaryTimer.url : "";
    const breakTimer = getBreakTimer(data.timerArray);
    const breakTimerURL = breakTimer ? breakTimer.url : "";

    const timerMinutes = computeIntervalSums(task._intervals, now);
    const usesSync = c5Sync || navSync || brugerdataSync || economicSync;

    if (
      !customerSettings.economicEnableProjectActivitiesImport &&
      (customerSettings.economicInvoicesForProjectTasks || !task.project) &&
      usesSync &&
      task.order &&
      !task.cancelled &&
      !task.completedAsInternal &&
      !order?.routePlan
    ) {
      const nothingToInvoiceForPriceItems = task.priceitemuseSet.every((priceItemUse) => {
        const {correctedCount, count, priceItem} = priceItemUse;
        if (
          !priceItem ||
          !priceItem.billable ||
          (!navSync && !priceItem.price && priceItem.remoteUrl)
        ) {
          return true;
        }
        if (correctedCount === 0) {
          return true;
        }
        if (correctedCount || priceItem.minimumCount) {
          return false;
        }
        if (priceItemIsTime(unitLookup, priceItem)) {
          if (usesDistributionTable && priceItemIsManualDistributionTime(unitLookup, priceItem)) {
            const minutes = count || 0;
            const adjustedMinutes = adjustMinutes(distributionTableAdjustTimeMethod, minutes);
            return !adjustedMinutes;
          } else {
            console.assert(count === null, "When using time from timer, count has to be null!");
            const timerUrl = priceItemUse.timer || genericPrimaryTimerURL;
            let minutes = (timerUrl && timerMinutes.get(timerUrl)) || 0;
            if (billedBreaks && breakTimerURL) {
              minutes += timerMinutes.get(breakTimerURL) || 0;
            }
            const adjustedMinutes = adjustMinutes(adjustBilledMinutes, minutes);
            return !adjustedMinutes;
          }
        } else {
          return !count;
        }
      });

      const nothingToInvoice =
        nothingToInvoiceForPriceItems &&
        task.productuseSet.every((productUse) => {
          const {product} = productUse;
          if (!product || (economicSync && !product.price)) {
            return true;
          }
          if (productUse.correctedCount === 0) {
            return true;
          }
          if (productUse.correctedCount) {
            return false;
          }
          return !productUse.count;
        });

      if (nothingToInvoice) {
        warnings.push({
          text: intl.formatMessage({defaultMessage: "Der er 0 kr fakturerbar på opgaven"}),
        });
      }
    }
  }

  return warnings;
};
