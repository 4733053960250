import {ReportingSpecification, ReportingSpecificationUrl} from "@co-common-libs/resources";
import {MultipleReportingSpecificationDialog} from "@co-frontend-libs/components";
import {AppState, getReportingSpecificationArray} from "@co-frontend-libs/redux";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";

interface MultipleReportingSpecificationDialogStateProps {
  reportingSpecificationArray: readonly ReportingSpecification[];
}

interface MultipleReportingSpecificationDialogOwnProps {
  includeOnlyReportingSpecificationsWithIdentifiers?: boolean;
  onCancel(): void;
  onOk(urls: ReadonlySet<ReportingSpecificationUrl>): void;
  open: boolean;
  selected?: ReadonlySet<ReportingSpecificationUrl>;
}

export const ConnectedMultipleReportingSpecificationDialog: React.ComponentType<MultipleReportingSpecificationDialogOwnProps> =
  connect<
    MultipleReportingSpecificationDialogStateProps,
    object,
    MultipleReportingSpecificationDialogOwnProps,
    AppState
  >(
    createStructuredSelector<AppState, MultipleReportingSpecificationDialogStateProps>({
      reportingSpecificationArray: getReportingSpecificationArray,
    }),
    {},
  )(MultipleReportingSpecificationDialog);
