import {Config} from "@co-common-libs/config";
import {Position, TimerStart, UserUrl} from "@co-common-libs/resources";
import {getActiveTimerStart} from "@co-common-libs/resources-utils";
import {MINUTE_MILLISECONDS, SECOND_MILLISECONDS} from "@co-common-libs/utils";
import {
  actions,
  getCommitQueueLength,
  getCustomerSettings,
  getDeviceConfigKey,
  getTimerStartArray,
  getUserId,
} from "@co-frontend-libs/redux";
import type {
  BackgroundGeolocationError,
  BackgroundGeolocationPlugin,
  Location,
  ServiceStatus,
} from "@mauron85/cordova-plugin-background-geolocation";
import _ from "lodash";
import {v4 as uuid} from "uuid";
import {instanceURL} from "./instance-url";
// NOTE: this is *not* the right approach; components should access Redux via
// React Redux; don't add more components like this; this *should* be moved to
// somewhere in the React tree...
import {store} from "./store";

/** @deprecated */
export enum AuthorizationStatus {
  NOT_AUTHORIZED,
  AUTHORIZED,
  AUTHORIZED_FOREGROUND,
}

declare const BackgroundGeolocation: BackgroundGeolocationPlugin;

const THIS_DEVICE_UUID = (window as any).device ? (window as any).device.uuid : "";

let backgroundServiceAuthorized = true;
let currentUserActiveTimerStart: TimerStart | null = null;
let currentUserURL: UserUrl | null = null;
let currentlyTracking = false;
let gpsEnabled: boolean | null = null;
let trackingPermissionGranted: boolean | null = null;
let backgroundPositionInterval = 0;
let consecutiveTimeoutErrors: string[] = [];

let initialized = false;
let initializationPromise: Promise<void> | undefined;

const BACKGROUND_TASK_MAX_DURATION_SECONDS = 25;
const MAX_TIMEOUT_ERRORS = 10;

function trackingAllowed(): boolean {
  return !!(
    gpsEnabled &&
    trackingPermissionGranted &&
    backgroundPositionInterval &&
    backgroundServiceAuthorized &&
    currentUserURL &&
    currentUserActiveTimerStart &&
    currentUserActiveTimerStart.timer &&
    currentUserActiveTimerStart.deviceUuid === THIS_DEVICE_UUID
  );
}

function updateTrackingState(): void {
  if (!initialized) {
    return;
  }
  if (currentlyTracking) {
    if (!trackingAllowed()) {
      BackgroundGeolocation.stop();
    }
  } else {
    if (trackingAllowed()) {
      BackgroundGeolocation.start();
    }
  }
}

function onTimerStartChange(timerStartArray: readonly TimerStart[]): void {
  currentUserActiveTimerStart = currentUserURL
    ? getActiveTimerStart(currentUserURL, timerStartArray)
    : null;
}

function onSettingsChange(customerSettings: Config): void {
  ({enabled: gpsEnabled} = customerSettings.geolocation);
  ({backgroundPositionInterval} = customerSettings);
}

function onTrackingPermissionChange(allowTracking: boolean | null): void {
  trackingPermissionGranted = allowTracking;
}

function onUserIDChange(userID: string | null): void {
  if (userID) {
    currentUserURL = instanceURL("user", userID);
  } else {
    currentUserURL = null;
  }
}

function locationErrorHandler({
  // message,
  code,
}: BackgroundGeolocationError): void {
  const {LOCATION_UNAVAILABLE, PERMISSION_DENIED, TIMEOUT} = BackgroundGeolocation;
  // let errorMessage;
  if (code === PERMISSION_DENIED || code === LOCATION_UNAVAILABLE) {
    backgroundServiceAuthorized = false;
    // const errorType =
    //   code === PERMISSION_DENIED ? "PERMISSION_DENIED" : "LOCATION_UNAVAILABLE";
    // errorMessage = `BackgroundGeolocation - ${errorType}: ${message}. Geolocation tracking stopped.`;
  } else if (code === TIMEOUT) {
    consecutiveTimeoutErrors.push(new Date().toISOString());
    if (consecutiveTimeoutErrors.length < MAX_TIMEOUT_ERRORS) {
      return;
    }
    // errorMessage = `BackgroundGeolocation - TIMEOUT. Got 10 timeouts: ${consecutiveTimeoutErrors}. Last error TIMEOUT error message: ${message}`;
    consecutiveTimeoutErrors = [];
  } else {
    // errorMessage = `BackgroundGeolocation - Unknown error code ${code}: ${message}`;
  }
  // if (window.onerror) {
  //   window.onerror(
  //     errorMessage,
  //     undefined,
  //     undefined,
  //     undefined,
  //     Error(errorMessage),
  //   );
  // }
  updateTrackingState();
}

const configure = (intervalMinutes: number): Promise<void> => {
  return new Promise((resolve, reject) =>
    BackgroundGeolocation.configure(
      {
        activitiesInterval: 10000,
        activityType: "OtherNavigation",
        desiredAccuracy: BackgroundGeolocation.MEDIUM_ACCURACY,
        distanceFilter: 50,
        fastestInterval: MINUTE_MILLISECONDS,
        interval: intervalMinutes * MINUTE_MILLISECONDS,
        locationProvider: BackgroundGeolocation.DISTANCE_FILTER_PROVIDER,
        maxLocations: 0,
        notificationText: "Sporer GPS-position",
        notificationTitle: "CustomOffice",
        pauseLocationUpdates: false,
        saveBatteryOnBackground: false,
        startForeground: true,
        stationaryRadius: 50,
        stopOnStillActivity: false,
      },
      resolve,
      reject,
    ),
  );
};

async function initializeGeolocationTracker(): Promise<void> {
  const initialState = store.getState();
  let customerSettings = getCustomerSettings(initialState);
  let userID = getUserId(initialState);
  let timerStartArray = getTimerStartArray(initialState);
  // NOTE: deviceconfig usually not yet loaded, so undefined...
  let isTrackingPermissionGranted = getDeviceConfigKey("trackingPermissionGranted")(
    initialState,
  ) as boolean | undefined;
  onUserIDChange(userID);
  onTimerStartChange(timerStartArray);
  onSettingsChange(customerSettings);
  onTrackingPermissionChange(isTrackingPermissionGranted ?? null);

  store.subscribe(() => {
    const state = store.getState();
    if (getUserId(state) !== userID) {
      userID = getUserId(state);
      onUserIDChange(userID);
      updateTrackingState();
    }
    if (!_.isEqual(getTimerStartArray(state), timerStartArray)) {
      timerStartArray = getTimerStartArray(state);
      onTimerStartChange(timerStartArray);
      updateTrackingState();
    }
    if (!_.isEqual(getCustomerSettings(state), customerSettings)) {
      customerSettings = getCustomerSettings(state);
      const oldBackgroundPositionInterval = backgroundPositionInterval;
      onSettingsChange(customerSettings);
      if (oldBackgroundPositionInterval !== backgroundPositionInterval) {
        configure(backgroundPositionInterval);
      }
      updateTrackingState();
    }
    if (getDeviceConfigKey("trackingPermissionGranted")(state) !== isTrackingPermissionGranted) {
      isTrackingPermissionGranted = getDeviceConfigKey("trackingPermissionGranted")(state) as
        | boolean
        | undefined;
      onTrackingPermissionChange(isTrackingPermissionGranted ?? null);
      updateTrackingState();
    }
  });

  await configure(backgroundPositionInterval);

  const onLocation = (location: Location): void => {
    consecutiveTimeoutErrors = [];
    if (!currentUserURL || !currentUserActiveTimerStart) {
      return;
    }
    const positionTime = new Date(location.time);
    const id = uuid();
    const url = instanceURL("position", id);
    const instance: Position = {
      accuracy: location.accuracy,
      deviceTimestamp: positionTime.toISOString(),
      employee: currentUserURL,
      id,
      latitude: location.latitude,
      longitude: location.longitude,
      task: currentUserActiveTimerStart.task,
      timerStart: currentUserActiveTimerStart ? currentUserActiveTimerStart.url : null,
      url,
    };
    // TS2349: This expression is not callable.
    //   Type 'never' has no call signatures.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    store.dispatch(actions.create(instance));
    BackgroundGeolocation.startTask(async (taskKey) => {
      const syncedPromise = new Promise<void>((resolve, reject) => {
        let timeoutID: number | null = null;
        const unsubscribe = store.subscribe(() => {
          if (!getCommitQueueLength(store.getState())) {
            unsubscribe();
            if (timeoutID !== null) {
              window.clearTimeout(timeoutID);
            }
            resolve();
          }
        });
        timeoutID = window.setTimeout(() => {
          unsubscribe();
          if (getCommitQueueLength(store.getState())) {
            reject(
              new Error(
                `BackgroundGeolocation - Sync wait timeout after ${BACKGROUND_TASK_MAX_DURATION_SECONDS} seconds.`,
              ),
            );
          }
        }, BACKGROUND_TASK_MAX_DURATION_SECONDS * SECOND_MILLISECONDS);
      });
      // TS2349: This expression is not callable.
      //   Type 'never' has no call signatures.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      store.dispatch(actions.startOnlineSaves());
      try {
        await syncedPromise;
      } catch (_error) {
        // This way of waiting for sync, in the backrgound, with this cordova
        // plugin doesn't work.
        // We do get locations in while in background we also get these timeout exceptions.
        // Uncommenting them again as this code is legacy now anyway.
        // if (window.onerror && error instanceof Error) {
        //   window.onerror(`${error}`, undefined, undefined, undefined, error);
        // }
      }
      BackgroundGeolocation.endTask(taskKey);
    });
  };
  BackgroundGeolocation.on("location", onLocation);
  BackgroundGeolocation.on("stationary", onLocation);
  BackgroundGeolocation.on("authorization", (status) => {
    backgroundServiceAuthorized = status === BackgroundGeolocation.AUTHORIZED;
    updateTrackingState();
  });
  BackgroundGeolocation.on("error", locationErrorHandler);
  BackgroundGeolocation.on("start", () => {
    currentlyTracking = true;
  });
  BackgroundGeolocation.on("stop", () => {
    currentlyTracking = false;
  });
  initialized = true;
  updateTrackingState();
}

interface GeolocationTracker {
  checkStatus:
    | ((
        success: (status: ServiceStatus) => void,
        fail?: (error: BackgroundGeolocationError) => void,
      ) => void)
    | undefined;
  updateTrackingState: () => void;
}

/** @deprecated */
export async function getGeolocationTracker(): Promise<GeolocationTracker | null> {
  const appVersion = (window as any).APP_VERSION;
  const allowInitialization =
    typeof cordova !== "undefined" &&
    typeof BackgroundGeolocation !== "undefined" &&
    appVersion &&
    appVersion >= "1.3.3" &&
    appVersion < "1.4.3";

  if (!allowInitialization) {
    return null;
  }
  if (!initialized) {
    if (!initializationPromise) {
      initializationPromise = initializeGeolocationTracker();
    }
    await initializationPromise;
  }
  return {
    checkStatus: BackgroundGeolocation.checkStatus.bind(BackgroundGeolocation),
    updateTrackingState,
  };
}
