import {ResponsiveDialog} from "@co-frontend-libs/components";
import {getCommitQueue} from "@co-frontend-libs/redux";
import {DialogContent, useTheme} from "@material-ui/core";
import {clearApp} from "app-utils";
import React, {useCallback, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useSelector} from "react-redux";

export const ClearAppDialog = React.memo(function ClearAppDialog({
  onCancel,
  open,
}: {
  onCancel: () => void;
  open: boolean;
}): JSX.Element {
  const intl = useIntl();
  const [clearingApp, setClearingApp] = useState(false);

  const handleClearAppDialogOk = useCallback((): Promise<void> => {
    setClearingApp(true);
    return clearApp();
  }, []);

  const commitQueue = useSelector(getCommitQueue);
  const count = commitQueue.length;
  const theme = useTheme();
  return (
    <ResponsiveDialog
      okDisabled={clearingApp}
      okLabel={intl.formatMessage({
        defaultMessage: "Nulstil",
      })}
      open={open}
      title={
        window.cordova
          ? intl.formatMessage({
              defaultMessage: "Nulstil app?",
            })
          : intl.formatMessage({
              defaultMessage: "Nulstil CustomOffice?",
            })
      }
      onCancel={onCancel}
      onOk={handleClearAppDialogOk}
    >
      <DialogContent>
        <FormattedMessage defaultMessage="Nulstil CustomOffice og log ud?" />
        {count ? (
          <div style={{color: theme.palette.warning.main, fontWeight: "bold"}}>
            <FormattedMessage
              defaultMessage="Der ligger {count} emner til synkronisering; disse vil gå tabt ved nulstilling."
              values={{count}}
            />
          </div>
        ) : null}
      </DialogContent>
    </ResponsiveDialog>
  );
});
