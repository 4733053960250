import {getCommitDB, getOfflineDB} from "@co-frontend-libs/db-resources";
import {closeAuthenticationDatabase, closeDeviceConfigDatabase} from "@co-frontend-libs/redux";
import {getFrontendSentry} from "@co-frontend-libs/utils";

/**
 * Attempt to close DB connections.
 * Continues past errors...
 */
export async function closeDatabases(): Promise<void> {
  const sentry = getFrontendSentry();
  try {
    // eslint-disable-next-line no-console
    console.log("closing commit database");
    const commitDB = await getCommitDB();
    await commitDB._closeConnection();
  } catch (error) {
    sentry.captureException(error);
  }
  try {
    // eslint-disable-next-line no-console
    console.log("closing authentication database");
    await closeAuthenticationDatabase();
  } catch (error) {
    sentry.captureException(error);
  }
  try {
    // eslint-disable-next-line no-console
    console.log("closing device-config database");
    await closeDeviceConfigDatabase();
  } catch (error) {
    sentry.captureException(error);
  }
  try {
    // eslint-disable-next-line no-console
    console.log("closing offline database");
    const offlineDB = await getOfflineDB();
    await offlineDB._closeConnection();
  } catch (error) {
    sentry.captureException(error);
  }
}
