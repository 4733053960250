import {MINUTE_MILLISECONDS} from "@co-common-libs/utils";
import * as actions from "../resources/actions";
import {ResourcesAuthenticationMiddlewareAPI} from "./types";

const CHANGES_FETCH_INTERVAL_MINUTES = 2;
const CHANGES_FETCH_INTERVAL_MILLISECONDS = CHANGES_FETCH_INTERVAL_MINUTES * MINUTE_MILLISECONDS;

let timeoutId: number | null = null;

export function startChangesFetch(
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  baseURL: string,
): void {
  const {authentication: authenticationState, resources: resourcesState} = middlewareApi.getState();
  const {currentlyFetchingChanges, lastFetchChangesTimestamp, offlineLoaded} = resourcesState;
  const {token} = authenticationState;
  if (currentlyFetchingChanges || !lastFetchChangesTimestamp || !offlineLoaded || !token) {
    return;
  }
  if (timeoutId) {
    window.clearTimeout(timeoutId);
    timeoutId = null;
  }
  middlewareApi.dispatch(actions.performChangesFetch({baseURL, lastFetchChangesTimestamp}));
}

export function startChangesFetchTimeout(
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  baseURL: string,
): void {
  if (timeoutId) {
    return;
  }
  timeoutId = window.setTimeout(() => {
    startChangesFetch(middlewareApi, baseURL);
  }, CHANGES_FETCH_INTERVAL_MILLISECONDS);
}
