import {SettingID} from "@co-common-libs/config";
import {Product, ProductUrl} from "@co-common-libs/resources";
import {ConnectedMultipleProductDialog} from "@co-frontend-libs/connected-components";
import {
  actions,
  getCurrentUserURL,
  getProductArray,
  getProductLookup,
  getSettingsEntryLookupByIdentifier,
} from "@co-frontend-libs/redux";
import React, {useCallback, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";

interface SettingDialogProps {
  onClose: () => void;
  open: boolean;
  settingID: SettingID;
}

export function SelectProducts(props: SettingDialogProps): JSX.Element {
  const {onClose, open, settingID} = props;
  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const settingEntry = settingsEntryLookupByIdentifier(settingID);
  const selectedProducts: string[] = useMemo(() => settingEntry?.data || [], [settingEntry?.data]);
  const productArray = useSelector(getProductArray);
  const productLookup = useSelector(getProductLookup);
  const productInstances = useMemo(() => {
    return selectedProducts
      .map((identifier) => productArray.find((w) => w.catalogNumber === identifier))
      .filter(Boolean);
  }, [selectedProducts, productArray]) as Product[];

  const dispatch = useDispatch();
  const currentUserURL = useSelector(getCurrentUserURL);
  const handleProductDialogOk = useCallback(
    (urls: ReadonlySet<ProductUrl>) => {
      const newValue = [...urls].map((url) => productLookup(url)?.catalogNumber);

      if (settingEntry) {
        dispatch(
          actions.update(settingEntry.url, [
            {member: "changedBy", value: currentUserURL},
            {member: "data", value: newValue},
          ]),
        );
      }
      onClose();
    },
    [settingEntry, onClose, productLookup, dispatch, currentUserURL],
  );

  return (
    <ConnectedMultipleProductDialog
      open={open}
      selected={new Set(productInstances.map((product) => product?.url))}
      onCancel={onClose}
      onOk={handleProductDialogOk}
    />
  );
}
