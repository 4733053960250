import {Config} from "@co-common-libs/config";
import {
  Customer,
  Location,
  LocationUrl,
  Machine,
  MachineUrl,
  Order,
  Task,
  Timer,
  TimerStart,
  UserProfile,
  UserUrl,
  WorkType,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {
  formatDuration,
  getNormalisedDeviceTimestamp,
  getWorkTypeString,
} from "@co-common-libs/resources-utils";
import {formatDate, mapFilter, notUndefined, setMap} from "@co-common-libs/utils";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {
  AppState,
  getCustomerSettings,
  getLocationLookup,
  getMachineLookup,
  getTimerArray,
  getTimerStartArray,
  getUserUserProfileLookup,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {Checkbox, DialogContent} from "@material-ui/core";
import {TaskStatusIcon} from "app-components";
import {
  PureComponent,
  computeIntervalSums,
  computeIntervalsTruncated,
  getDepartmentName,
  getExternalSecondaryTimers,
  mapSum,
  mergeIntervals,
} from "app-utils";
import {bind} from "bind-decorator";
import _ from "lodash";
import React from "react";
import {FormattedMessage, IntlContext, defineMessages} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";

const messages = defineMessages({
  cancel: {
    defaultMessage: "Fortryd",
    id: "dialog.label.cancel",
  },
  mergeOrders: {
    defaultMessage: "Sammenflet ordre",
    id: "order-merge.dialog-title.merge-orders",
  },
  ok: {
    defaultMessage: "OK",
    id: "dialog.label.ok",
  },
  referenceNumber: {
    defaultMessage: "Referencenummer",
    id: "order-merge.label.reference-number",
  },
});

interface OrderMergeDialogStateProps {
  customerSettings: Config;
  locationLookup: (url: LocationUrl) => Location | undefined;
  machineLookup: (url: MachineUrl) => Machine | undefined;
  timerArray: readonly Timer[];
  timerStartArray: readonly TimerStart[];
  userUserProfileLookup: (url: UserUrl) => UserProfile | undefined;
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
}

interface OrderMergeDialogOwnProps {
  customer?: Customer | undefined;
  onCancel: () => void;
  onOk: (selectedOrderList: readonly (Order & {taskSet: readonly Task[]})[]) => void;
  open: boolean;
  order: Order;
  orderMergeCandidateList: readonly (Order & {taskSet: readonly Task[]})[];
}

type OrderMergeDialogProps = OrderMergeDialogOwnProps & OrderMergeDialogStateProps;

interface OrderMergeDialogState {
  selectedCandidates: {[orderURL: string]: boolean | undefined};
}

class OrderMergeDialog extends PureComponent<OrderMergeDialogProps, OrderMergeDialogState> {
  state: OrderMergeDialogState = {
    selectedCandidates: {},
  };
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  @bind
  handleOk(): void {
    const selectedOrderList = this.props.orderMergeCandidateList.filter(
      (order) => this.state.selectedCandidates[order.url],
    );
    this.props.onOk(selectedOrderList);
  }

  @bind
  handleCancel(): void {
    this.props.onCancel();
  }

  _checkHandlers: {
    [orderURL: string]: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
  } = {};
  @bind
  handleCheckChange(orderURL: string): (event: React.ChangeEvent<HTMLInputElement>) => void {
    let handler = this._checkHandlers[orderURL];
    if (!handler) {
      handler = (event) => {
        const {checked} = event.target;
        this.setState({
          selectedCandidates: {
            ...this.state.selectedCandidates,
            [orderURL]: checked,
          },
        });
      };
      this._checkHandlers[orderURL] = handler;
    }
    return handler;
  }

  render(): JSX.Element | null {
    const {
      locationLookup,
      machineLookup,
      open,
      orderMergeCandidateList,
      timerArray,
      userUserProfileLookup,
      workTypeLookup,
    } = this.props;
    if (!open) {
      return null;
    }
    const {formatMessage} = this.context;
    const {customerSettings} = this.props;
    const now = new Date();
    now.setUTCMilliseconds(0);
    const externalSecondaryTimers = getExternalSecondaryTimers(timerArray);
    const externalSecondaryTimerURLs = setMap(externalSecondaryTimers, (w) => w.url);

    const tableRows = _.flatMap(
      _.sortBy(orderMergeCandidateList, (order) => order.date),
      (order) => {
        const createdByProfile =
          (order.createdBy && userUserProfileLookup(order.createdBy)) || undefined;
        const userInitials = createdByProfile && createdByProfile.alias;
        const taskList = order.taskSet.map((task) => {
          const machineOperatorProfile =
            task.machineOperator && userUserProfileLookup(task.machineOperator);
          const machineOperatorInitials = machineOperatorProfile && machineOperatorProfile.alias;
          let machines;
          let workTypeString;
          if (customerSettings.noExternalTaskWorkType) {
            machines = task.machineuseSet
              .map((machineUse) => machineUse.machine)
              .map((machineURL) => machineLookup(machineURL))
              .filter(notUndefined)
              .map((machine) => machine.c5_machine)
              .join(", ");
          } else {
            const workTypeURL = task.workType;
            const workType = (workTypeURL && workTypeLookup(workTypeURL)) || undefined;
            workTypeString = getWorkTypeString(workType);
          }
          const taskURL = task.url;
          const timerStarts = _.sortBy(
            this.props.timerStartArray.filter((instance) => instance.task === taskURL),
            getNormalisedDeviceTimestamp,
          );
          const computedIntervals = task.recordedInC5
            ? task.computedTimeSet
            : computeIntervalsTruncated(timerStarts);
          const correctionIntervals = task.machineOperatorTimeCorrectionSet || [];
          const managerCorrectionIntervals = task.managerTimeCorrectionSet || [];
          const intervals = mergeIntervals(
            computedIntervals,
            correctionIntervals,
            managerCorrectionIntervals,
          );
          const timerMinutes = computeIntervalSums(intervals, now);
          const effectiveMinutes = mapSum(
            mapFilter(timerMinutes, (_val, key) => !externalSecondaryTimerURLs.has(key)),
          );
          return (
            <tr key={task.url}>
              <td style={{width: "8ex"}} />
              <td>{machineOperatorInitials}</td>
              <td>
                {machines}
                {workTypeString}
              </td>
              <td>{formatDate(task.workFromTimestamp || task.date)}</td>
              <td>{formatDuration(customerSettings.durationFormat, effectiveMinutes)}</td>
              <td style={{width: "7ex"}}>
                <TaskStatusIcon task={task} timerStartArray={this.props.timerStartArray} />
              </td>
            </tr>
          );
        });
        const orderURL = order.url;
        return [
          <tr key={orderURL}>
            <td colSpan={5}>
              <div style={{display: "inline-block", marginBottom: -8}}>
                <Checkbox
                  checked={!!this.state.selectedCandidates[orderURL]}
                  onChange={this.handleCheckChange(orderURL)}
                />
              </div>
              <div style={{display: "inline-block"}}>
                <FormattedMessage
                  defaultMessage="Ordre oprettet af {userInitials}:"
                  id="order-entry.label.order-created-by"
                  tagName="h4"
                  values={{userInitials}}
                />
              </div>
            </td>
          </tr>,
          ...taskList,
        ];
      },
    );

    const {customer, order} = this.props;
    let referenceNumberBlock: JSX.Element | undefined;
    if (customerSettings.enableOrderReferenceNumber) {
      referenceNumberBlock = (
        <div>
          {customerSettings.orderReferenceNumberLabel || formatMessage(messages.referenceNumber)}:{" "}
          {order.referenceNumber}
        </div>
      );
    }
    let workPlaceBlock: JSX.Element | undefined;
    if (customerSettings.orderEntryShowWorkPlace) {
      const location = order.relatedWorkplace ? locationLookup(order.relatedWorkplace) : null;
      workPlaceBlock = (
        <FormattedMessage
          defaultMessage="Arbejdssted: {address}"
          id="order-merge.label.address-address"
          tagName="div"
          values={{
            address: location?.name || location?.address || order.address || "",
          }}
        />
      );
    }
    let departmentBlock: JSX.Element | undefined;
    if (customerSettings.enableExternalTaskDepartmentField) {
      const departmentID = order.department;
      const departmentName = getDepartmentName(departmentID, this.props.customerSettings);
      departmentBlock = (
        <FormattedMessage
          defaultMessage="Afdeling: {name}"
          id="order-merge.label.department-name"
          tagName="div"
          values={{name: departmentName}}
        />
      );
    }
    const dialogContent = (
      <div>
        {referenceNumberBlock}
        <FormattedMessage
          defaultMessage="Kunde: {name}"
          id="order-merge.label.customer-name"
          tagName="div"
          values={{name: customer ? customer.name : ""}}
        />
        {workPlaceBlock}
        {departmentBlock}
        <table style={{width: "100%"}}>
          <thead>
            <tr>
              <td style={{width: "8ex"}} />
              {customerSettings.employeeLabelVariant === "MACHINEOPERATOR" ? (
                <FormattedMessage
                  defaultMessage="Maskinfører"
                  id="order-entry.table-header.machine-operator"
                  tagName="td"
                />
              ) : customerSettings.employeeLabelVariant === "EMPLOYEE" ? (
                <FormattedMessage
                  defaultMessage="Medarbejder"
                  id="order-entry.table-header.employee"
                  tagName="td"
                />
              ) : (
                <FormattedMessage
                  defaultMessage="Chauffør"
                  id="order-entry.table-header.chauffeur"
                  tagName="td"
                />
              )}
              {customerSettings.noExternalTaskWorkType ? (
                customerSettings.machineLabelVariant === "MACHINE" ? (
                  <FormattedMessage
                    defaultMessage="Maskiner"
                    id="order-entry.table-header.machines"
                    tagName="td"
                  />
                ) : (
                  <FormattedMessage
                    defaultMessage="Køretøjer"
                    id="order-entry.table-header.vehicles"
                    tagName="td"
                  />
                )
              ) : (
                <FormattedMessage
                  defaultMessage="Område"
                  id="order-entry.table-header.work-type"
                  tagName="td"
                />
              )}
              <FormattedMessage
                defaultMessage="Dato"
                id="order-entry.table-header.date"
                tagName="td"
              />
              <FormattedMessage
                defaultMessage="Eff. tid"
                id="order-entry.table-header.effective-time"
                tagName="td"
              />
              <td style={{width: "7ex"}}>
                <FormattedMessage defaultMessage="Status" id="order-entry.table-header.completed" />
              </td>
            </tr>
          </thead>
          <tbody>{tableRows}</tbody>
        </table>
      </div>
    );
    return (
      <ResponsiveDialog
        okDisabled={!Object.keys(this.state.selectedCandidates).length}
        open={open}
        title={formatMessage(messages.mergeOrders)}
        onCancel={this.handleCancel}
        onOk={this.handleOk}
      >
        <DialogContent>{dialogContent}</DialogContent>
      </ResponsiveDialog>
    );
  }
}

const ConnectedOrderMergeDialog: React.ComponentType<OrderMergeDialogOwnProps> = connect<
  OrderMergeDialogStateProps,
  object,
  OrderMergeDialogOwnProps,
  AppState
>(
  createStructuredSelector<AppState, OrderMergeDialogStateProps>({
    customerSettings: getCustomerSettings,
    locationLookup: getLocationLookup,
    machineLookup: getMachineLookup,
    timerArray: getTimerArray,
    timerStartArray: getTimerStartArray,
    userUserProfileLookup: getUserUserProfileLookup,
    workTypeLookup: getWorkTypeLookup,
  }),
  {},
)(OrderMergeDialog);

export default ConnectedOrderMergeDialog;
