import {
  CustomerUrl,
  Patch,
  PatchOperation,
  ReportingLocation,
  Task,
} from "@co-common-libs/resources";
import _ from "lodash";
import {v4 as uuid} from "uuid";
import {ProvisionaryCommand} from "../../resources/actions";
import {getLocationLookup, getReportingSpecificationLookup} from "../../resources/selectors";
import {ResourcesAuthenticationMiddlewareAPI} from "../types";

export function addLogLocationsForFieldsOnAddingLog(
  newTask: Task | null,
  oldTask: Task | undefined,
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  _command: ProvisionaryCommand,
): Patch<Task> | null {
  if (!newTask) {
    return null;
  }
  if (
    !newTask.reportingSpecification ||
    newTask.reportingSpecification === oldTask?.reportingSpecification
  ) {
    // reportingSpecification cleared or unchanged
    return null;
  }
  if (newTask.reportingLocations && Object.keys(newTask.reportingLocations).length) {
    // some location data already present for log
    // ... should not happen; but it's safer not to overwrite then...?
    return null;
  }
  if (!newTask.fielduseSet || !newTask.fielduseSet.length) {
    // no fields to add locations for
    return null;
  }

  const state = middlewareApi.getState();

  const reportingSpecificationLookup = getReportingSpecificationLookup(state);
  const reportingSpecification = reportingSpecificationLookup(newTask.reportingSpecification);

  if (!reportingSpecification) {
    return null;
  }

  const {fieldsUsedFor} = reportingSpecification;
  if (!fieldsUsedFor || fieldsUsedFor === "unused") {
    // fields not automatically used for locations on this log type
    return null;
  }

  const workplaceDataForType = reportingSpecification.workplaceData[fieldsUsedFor];
  const worktypeInputSpecifications = workplaceDataForType && workplaceDataForType.inputs;

  const patch: PatchOperation<Task>[] = [];

  let nextOrder = 0;

  const locationLookup = getLocationLookup(state);
  newTask.fielduseSet.forEach((fieldUse) => {
    const fieldURL = fieldUse.relatedField;
    const field = locationLookup(fieldURL);
    if (!field) {
      return;
    }
    const {fieldAreaHa, fieldCrop} = field;
    const values: {[identifier: string]: number | string | undefined} = {};
    if (worktypeInputSpecifications) {
      worktypeInputSpecifications.forEach((inputSpecification) => {
        if (inputSpecification.fieldAreaTarget) {
          const decimals = 2;
          values[inputSpecification.identifier] = _.floor(fieldAreaHa || 0, decimals);
        }
        if (inputSpecification.fieldCropTarget) {
          values[inputSpecification.identifier] = fieldCrop;
        }
      });
    }
    const identifier = uuid();
    const entry: ReportingLocation = {
      customer: field.customer as CustomerUrl,
      location: fieldURL,
      order: nextOrder,
      productUses: {},
      type: fieldsUsedFor,
      values,
    };
    nextOrder += 1;
    patch.push({path: ["reportingLocations", identifier], value: entry});
  });

  if (patch.length) {
    return patch;
  } else {
    return null;
  }
}
