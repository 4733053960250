import {Contact, CustomerUrl} from "@co-common-libs/resources";
import {simpleCompare} from "@co-common-libs/utils";
import {createSelector} from "reselect";
import {getContactArray} from "../resources/selectors";
import {StateWithResources} from "../resources/types";
import {AppState} from "../root-reducer";
import {arrayFilterActiveGroupSelector} from "./resource-selector-helpers";
import {filterOnActive} from "./utils";

function contactComparator(contactA: Contact, contactB: Contact): -1 | 0 | 1 {
  const aDefault = contactA.defaultContact;
  const bDefault = contactB.defaultContact;
  if (aDefault && !bDefault) {
    return -1;
  } else if (!aDefault && bDefault) {
    return 1;
  } else {
    return simpleCompare(contactA.name, contactB.name) || simpleCompare(contactA.url, contactB.url);
  }
}

function sortedContactArray(contactArray: readonly Contact[]): readonly Contact[] {
  return contactArray.slice().sort(contactComparator);
}

export const getSortedContactArray: (state: StateWithResources) => readonly Contact[] =
  createSelector(getContactArray, sortedContactArray);

export const getSortedActiveContactArray: (state: StateWithResources) => readonly Contact[] =
  createSelector(getSortedContactArray, filterOnActive);

export const getSortedActiveContactArrayPerCustomer: (
  state: AppState,
) => ReadonlyMap<string, readonly Contact[]> = arrayFilterActiveGroupSelector(
  getSortedActiveContactArray,
  (contact) => contact.customer,
);

export const getActiveContactArray: (state: StateWithResources) => readonly Contact[] =
  createSelector(getContactArray, filterOnActive);

function filterOnCustomerUrl(
  contacts: readonly Contact[],
  customerUrl: CustomerUrl,
): readonly Contact[] {
  return contacts.filter((contact) => contact.customer === customerUrl);
}

export function getCustomerContactArray(
  customerUrl: CustomerUrl,
): (state: StateWithResources) => readonly Contact[] {
  return createSelector(getContactArray, (contacts: readonly Contact[]) =>
    filterOnCustomerUrl(contacts, customerUrl),
  );
}

export function getCustomerActiveContactArray(
  customerUrl: CustomerUrl,
): (state: StateWithResources) => readonly Contact[] {
  return createSelector(getCustomerContactArray(customerUrl), filterOnActive);
}
