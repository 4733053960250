import {dateToString, makeContainsPredicate} from "@co-common-libs/utils";
import {ColumnSpecification, ColumnSpecifications, RowData} from "./types";

export function filterRows<
  FieldID extends string,
  ColumnID extends string,
  KeyType extends string = string,
  DataType extends RowData<FieldID, KeyType> = RowData<FieldID, KeyType>,
>(
  columns: ColumnSpecifications<FieldID, ColumnID, KeyType, DataType>,
  data: readonly (DataType & {children?: readonly DataType[] | undefined})[],
  filterString: string | undefined,
  visibleColumns: readonly ColumnID[],
): readonly (DataType & {children?: readonly DataType[] | undefined})[] {
  if (filterString && filterString.trim()) {
    const check = makeContainsPredicate(filterString, dateToString);
    const columnArray = Object.entries(columns) as [
      ColumnID,
      ColumnSpecification<FieldID, KeyType, DataType>,
    ][];
    const fieldSet = new Set<FieldID>();
    columnArray.forEach(([columnId, column]) => {
      if (column.searchOnlyWhenVisible && !visibleColumns.includes(columnId)) {
        return;
      }
      fieldSet.add(column.field);
      if (column.sortField) {
        fieldSet.add(column.sortField);
      }
    });
    const fieldArray = Array.from(fieldSet);

    const filterFn = (
      rowData: DataType & {children?: readonly DataType[] | undefined},
    ): boolean => {
      const rowDataArray = fieldArray.map((fieldID) => {
        const rowColumnData = rowData[fieldID];
        return rowColumnData;
      });
      if (rowData.children && rowData.children.length) {
        const {children} = rowData;
        children.forEach((childRowData) => {
          fieldArray.forEach((fieldID) => {
            const childRowColumnData = childRowData[fieldID];
            rowDataArray.push(childRowColumnData);
          });
        });
      }
      return check(rowDataArray.join(" "));
    };
    return data.filter(filterFn);
  } else {
    return data;
  }
}
