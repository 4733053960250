import {MINUTE_MILLISECONDS} from "@co-common-libs/utils";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {DialogContent} from "@material-ui/core";
import {PureComponent} from "app-utils";
import React from "react";
import {IntlContext, defineMessages} from "react-intl";

const messages = defineMessages({
  no: {defaultMessage: "Nej", id: "auto-correction-dialog.label.no"},
  text: {
    defaultMessage:
      "Der er {minutes} minutters hul mellem forrige opgave og denne. Vil du udfylde disse?",
    id: "auto-correction-dialog.label.add-correction",
  },
  title: {
    defaultMessage: "Auto-udfyld uregistreret tid?",
    id: "auto-correction-dialog.title.add-correction",
  },
  yes: {defaultMessage: "Ja", id: "auto-correction-dialog.label.yes"},
});

interface AutoCorrectionDialogProps {
  fromTimestamp?: string | undefined;
  onCancel: () => void;
  onOk: () => void;
  open: boolean;
  toTimestamp?: string | undefined;
}

export class AutoCorrectionDialog extends PureComponent<AutoCorrectionDialogProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {fromTimestamp, onCancel, onOk, open, toTimestamp} = this.props;
    let text;
    if (open && fromTimestamp && toTimestamp) {
      const differenceMilliseconds =
        new Date(toTimestamp).valueOf() - new Date(fromTimestamp).valueOf();
      const differenceMinutes = Math.floor(differenceMilliseconds / MINUTE_MILLISECONDS);
      text = formatMessage(messages.text, {minutes: differenceMinutes});
    } else {
      text = "";
    }
    return (
      <ResponsiveDialog
        cancelLabel={formatMessage(messages.no)}
        okLabel={formatMessage(messages.yes)}
        open={open}
        title={formatMessage(messages.title)}
        onCancel={onCancel}
        onOk={onOk}
      >
        <DialogContent>{text}</DialogContent>
      </ResponsiveDialog>
    );
  }
}
