import {ReportingSpecification} from "@co-common-libs/resources";

export const getLogTotalsSpec = (
  logSpecification: ReportingSpecification,
): [Map<string, string>, Map<string, string>] => {
  const summedLabels = new Map<string, string>();
  const transferUnits = new Map<string, string>();
  for (const type of ["workplace", "pickup", "delivery"] as const) {
    const worktypeLogInputSpecifications = logSpecification.workplaceData?.[type]?.logInputs;
    if (worktypeLogInputSpecifications) {
      for (const inputSpecification of worktypeLogInputSpecifications) {
        const {identifier, label, unit} = inputSpecification;
        if (inputSpecification.sum) {
          const labelWithUnit = unit ? `${label}, ${unit}` : label;
          summedLabels.set(identifier, labelWithUnit);
        }
        if (inputSpecification.transfer && unit) {
          transferUnits.set(identifier, unit.toLowerCase());
        }
      }
    }
  }
  return [summedLabels, transferUnits];
};
