import {Location, LocationUrl, ProductUrl} from "@co-common-libs/resources";
import React, {useMemo} from "react";
import {LocationMarker} from "./location-marker";

interface VisibleLocationMarkersProps {
  readonly bounds: google.maps.LatLngBounds;
  readonly cropVisible: boolean;
  readonly fieldHaAndCustomerNameVisible: boolean;
  readonly labelsVisible: boolean;
  readonly locationProductCounts:
    | ReadonlyMap<LocationUrl, ReadonlyMap<ProductUrl, number>>
    | undefined;
  readonly locations: readonly Location[];
  readonly mapCanvasProjection: google.maps.MapCanvasProjection;
  readonly onClickOrGoogleMapsUrl:
    | "GOOGLE_MAPS"
    | ((ref: HTMLSpanElement, location: Location) => void)
    | undefined;
  readonly showAsActive: boolean;
}

interface LocationAndCoordinates {
  readonly location: Location;
  readonly x: number;
  readonly y: number;
}

export const VisibleLocationMarkers = React.memo(function VisibleLocationMarkers(
  props: VisibleLocationMarkersProps,
): JSX.Element {
  const {
    bounds,
    cropVisible,
    fieldHaAndCustomerNameVisible,
    labelsVisible,
    locationProductCounts,
    locations,
    mapCanvasProjection,
    onClickOrGoogleMapsUrl,
    showAsActive,
  } = props;

  const visibleLocationMarkers = useMemo((): readonly LocationAndCoordinates[] => {
    const result: LocationAndCoordinates[] = [];

    for (const location of locations) {
      if (!labelsVisible && !showAsActive && location.geojson) {
        // we display an icon on `!location.geojson || showAsActive`;
        // we display text on `labelsVisible`;
        // otherwise, we display nothing, so rendering component is a waste
        continue;
      }
      const {latitude, longitude} = location;
      if (latitude == null || longitude == null) {
        continue;
      }
      const latLng = new google.maps.LatLng(latitude, longitude);
      if (bounds.contains(latLng)) {
        const point = mapCanvasProjection.fromLatLngToDivPixel(latLng);
        if (point) {
          const {x, y} = point;
          result.push({location, x, y});
        }
      }
    }
    return result;
  }, [bounds, labelsVisible, locations, mapCanvasProjection, showAsActive]);

  return (
    <>
      {visibleLocationMarkers.map(({location, x, y}) => (
        <LocationMarker
          key={location.url}
          cropVisible={cropVisible}
          fieldHaAndCustomerNameVisible={fieldHaAndCustomerNameVisible}
          labelsVisible={labelsVisible}
          location={location}
          productCounts={locationProductCounts?.get(location.url)}
          showAsActive={showAsActive}
          x={x}
          y={y}
          onClickOrGoogleMapsUrl={onClickOrGoogleMapsUrl}
        />
      ))}
    </>
  );
});
