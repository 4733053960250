import {RoutePlan} from "@co-common-libs/resources";
import {
  DebouncedAppbarSearchField,
  VerticalStackingFloatingActionButton,
} from "@co-frontend-libs/components";
import {actions} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {MenuToolbar, PageLayout} from "app-components";
import {useQueryParameterState} from "app-utils";
import {instanceURL} from "frontend-global-config";
import PlusIcon from "mdi-react/PlusIcon";
import React, {useCallback, useState} from "react";
import {useIntl} from "react-intl";
import {useDispatch} from "react-redux";
import {v4 as uuid} from "uuid";
import {ShowInactiveToggle} from "../shared/show-inactive-toggle";
import {RoutePlanList} from "./routes";
import {NicknameDialog} from "./routes/nickname-dialog";

interface RoutesSettingsPageProps {
  onMenuButton: (event: React.MouseEvent) => void;
}

export const RoutesSettingsPage = React.memo(function RoutesSettingsPage(
  props: RoutesSettingsPageProps,
): JSX.Element {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [filter, setFilter] = useQueryParameterState<string>("q", "");
  const [showInactive, setShowInactive] = useState(false);

  const [routeNameDialogOpen, setRouteNameDialogOpen] = useState(false);
  const setRouteNameDialogOpenTrue = useCallWithTrue(setRouteNameDialogOpen);
  const setRouteNameDialogOpenFalse = useCallWithFalse(setRouteNameDialogOpen);

  const handleRouteNameDialogOk = useCallback(
    (name: string) => {
      setRouteNameDialogOpen(false);
      const id = uuid();
      const url = instanceURL("routePlan", id);
      const obj: RoutePlan = {
        active: false,
        department: "",
        id,
        name,
        url,
        workType: null,
      };
      dispatch(actions.create(obj));
      dispatch(actions.go("/settings/route/:id", {id}));
    },
    [dispatch],
  );

  return (
    <PageLayout
      withBottomScrollPadding
      dialogs={
        <NicknameDialog
          key="route-name-dialog"
          open={routeNameDialogOpen}
          onCancel={setRouteNameDialogOpenFalse}
          onOk={handleRouteNameDialogOk}
        />
      }
      floatingActionButton={
        <VerticalStackingFloatingActionButton stackIndex={0} onClick={setRouteNameDialogOpenTrue}>
          <PlusIcon />
        </VerticalStackingFloatingActionButton>
      }
      toolbar={
        <MenuToolbar
          rightElement={
            <>
              <ShowInactiveToggle setShowInactive={setShowInactive} showInactive={showInactive} />
              <DebouncedAppbarSearchField
                debounceTimeout={200}
                value={filter}
                onChange={setFilter}
              />
            </>
          }
          title={intl.formatMessage({defaultMessage: "Ruter"})}
          onMenuButton={props.onMenuButton}
        />
      }
    >
      <RoutePlanList showInactive={showInactive} />
    </PageLayout>
  );
});
