import {Project} from "@co-common-libs/resources";
import {CircularProgress} from "@material-ui/core";
import {PhotosCard, useAppendicesData} from "app-components";
import React, {useMemo} from "react";

interface ProjectPhotoAppendicesProps {
  project: Pick<Project, "url">;
}

export const ProjectPhotoAppendices = React.memo(function ProjectPhotoAppendices({
  project,
}: ProjectPhotoAppendicesProps): React.JSX.Element {
  const {data, fetching} = useAppendicesData(
    useMemo(
      () => ({
        project: project.url,
      }),
      [project.url],
    ),
  );

  if (fetching) {
    return (
      <div data-testid="loading">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div data-testid="project-photos">
      <PhotosCard data={data?.photoTasks ?? null} />
    </div>
  );
});
