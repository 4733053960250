import {CustomerFormErrorsAndWarnings, CustomerFormInputDefinitions} from "./customer-form";
import {CustomerFormPart} from "./types";

export function hasMissingOrInvalidInputs(
  formCustomer: CustomerFormPart,
  customerFormFields: CustomerFormInputDefinitions,
  customerFormErrorsAndWarnings: CustomerFormErrorsAndWarnings,
): boolean {
  const {requiredInputFields} = customerFormFields;

  return (
    customerFormErrorsAndWarnings.errorText.size > 0 ||
    Array.from(requiredInputFields).some((requiredField) => {
      const formValue = formCustomer[requiredField];
      return formValue === null || formValue === "";
    })
  );
}
