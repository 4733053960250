import {colorMap} from "@co-frontend-libs/utils";
import {PureComponent} from "app-utils";
import ImmutableDate from "bloody-immutable-date";
import React from "react";
import {ERROR_Z_INDEX} from "./constants";
import {calculateYPosition} from "./utils";

interface ErrorBlockProps {
  calendarFromTimestamp: ImmutableDate;
  calendarToTimestamp: ImmutableDate;
  fromTimestamp: ImmutableDate;
  toTimestamp: ImmutableDate;
  type: "missing" | "overlapping";
}

export class ErrorBlock extends PureComponent<ErrorBlockProps> {
  render(): JSX.Element {
    const {calendarFromTimestamp, calendarToTimestamp, fromTimestamp, toTimestamp, type} =
      this.props;
    const startY = calculateYPosition(calendarFromTimestamp, calendarToTimestamp, fromTimestamp);
    const endY = calculateYPosition(calendarFromTimestamp, calendarToTimestamp, toTimestamp);
    const style: React.CSSProperties = {
      backgroundColor: type === "missing" ? colorMap.WARNING : colorMap.ERROR,
      height: endY - startY,
      opacity: 0.2,
      pointerEvents: "none",
      position: "absolute",
      top: startY,
      width: "100%",
      zIndex: ERROR_Z_INDEX,
    };
    return <div style={style} />;
  }
}
