import {Config} from "@co-common-libs/config";
import {Customer, CustomerUrl, Order, OrderUrl, RouteTask, Task} from "@co-common-libs/resources";
import {notUndefined} from "@co-common-libs/utils";
import _ from "lodash";
import {EconomicCustomerIssue, IssueContext} from "../types";

export function economicCustomerIssues({
  context,
  customerLookup,
  customerSettings,
  orderLookup,
  routeTaskArray,
  task,
}: {
  context: IssueContext;
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  customerSettings: Pick<Config, "economicAutogeneratedCustomerNumbers">;
  orderLookup: (url: OrderUrl) => Pick<Order, "customer" | "routePlan"> | undefined;
  routeTaskArray: readonly Pick<RouteTask, "completed" | "customer" | "route">[];
  task: Pick<Task, "order" | "url">;
}): readonly EconomicCustomerIssue[] {
  const issues: EconomicCustomerIssue[] = [];

  const customers: Customer[] = [];
  const order = task.order ? orderLookup(task.order) : null;
  if (order?.routePlan) {
    const completedRouteTaskList = routeTaskArray.filter(
      (routeTask) => routeTask.route === task.url && routeTask.completed,
    );

    const uniqueRouteCustomers = _.uniq(completedRouteTaskList.map(({customer}) => customer))
      .map(customerLookup)
      .filter(notUndefined);

    customers.push(...uniqueRouteCustomers);
  } else {
    const customerInstance = order?.customer ? customerLookup(order.customer) : null;

    if (customerInstance) {
      customers.push(customerInstance);
    }
  }

  const isBarred = customers.filter(({barred}) => barred);
  if (isBarred.length) {
    isBarred.forEach((customer) => {
      issues.push({
        category: "error",
        instance: customer,
        issueType: "barred",
        resourceName: "customer",
      });
    });
  } else {
    customers
      .filter(({active}) => !active)
      .forEach((customer) => {
        issues.push({
          category: "warning",
          instance: customer,
          issueType: "inactive",
          resourceName: "customer",
        });
      });
  }

  customers
    .filter(({remoteUrl}) => !remoteUrl)
    .forEach((customer) => {
      issues.push({
        category: context === "bookkeeping" ? "error" : "warning",
        instance: customer,
        issueType: "missingRemoteUrl",
        resourceName: "customer",
      });
    });

  if (!customerSettings.economicAutogeneratedCustomerNumbers) {
    customers
      .filter(
        // eslint-disable-next-line @typescript-eslint/naming-convention
        ({c5_account, remoteUrl}) => remoteUrl && !c5_account,
      )
      .forEach((customer) => {
        issues.push({
          category: context === "bookkeeping" ? "error" : "warning",
          instance: customer,
          issueType: "missingRemoteIdentifier",
          resourceName: "customer",
        });
      });
  }

  return issues;
}
