import {Contact, ContactUrl, CustomerUrl, emptyContact} from "@co-common-libs/resources";
import {EntryData, GenericSingleSelectionSearchDialog} from "@co-frontend-libs/components";
import {getCustomerActiveContactArray, getExtendedCustomerSettings} from "@co-frontend-libs/redux";
import {useCallWithFalse, useConditionalMemo} from "@co-frontend-libs/utils";
import {identity} from "lodash";
import React, {useCallback, useState} from "react";
import {IntlShape, useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {CreateCustomerContactDialog} from "./create-customer-contact-dialog";

function formatSecondaryText(contact: Contact): string {
  const {cellphone, email, fax, phone} = contact;
  const parts: string[] = [];
  if (phone || cellphone) {
    parts.push([phone, cellphone].filter(identity).join("/"));
  }
  if (fax) {
    parts.push(fax);
  }
  if (email) {
    parts.push(email);
  }
  return parts.join(", ");
}

function computeChoices(
  intl: IntlShape,
  contactsMayHaveCellphone: boolean,
  contactsMayHaveFax: boolean,
  contacts: readonly Contact[],
): readonly EntryData<ContactUrl>[] {
  return contacts.map((contact) => {
    const {cellphone, defaultContact, email, fax, name, phone, url} = contact;
    return {
      category: defaultContact ? "favorite" : "standard",
      exactMatchValue: name,
      identifier: url,
      primaryText: name,
      searchFields: [
        {label: intl.formatMessage({defaultMessage: "Navn"}), priority: 10, text: name},
        {label: intl.formatMessage({defaultMessage: "Telefon"}), priority: 9, text: phone},
        ...(contactsMayHaveCellphone
          ? [{label: intl.formatMessage({defaultMessage: "Mobil"}), priority: 8, text: cellphone}]
          : []),
        ...(contactsMayHaveFax
          ? [{label: intl.formatMessage({defaultMessage: "Fax"}), priority: 7, text: fax}]
          : []),
        {label: intl.formatMessage({defaultMessage: "E-mail"}), priority: 6, text: email},
      ],
      secondaryText: formatSecondaryText(contact),
    };
  });
}

interface ContactSelectCreateDialogOwnProps {
  customerUrl: CustomerUrl;
  fullscreen?: boolean;
  onCancel(): void;
  onNone?: () => void;
  onOk(url: ContactUrl): void;
  open: boolean;
}

export function ContactSelectCreateDialog(props: ContactSelectCreateDialogOwnProps): JSX.Element {
  const {customerUrl, fullscreen, onCancel, onNone, onOk, open} = props;
  const intl = useIntl();

  const {
    contacts: {canManage, contactsMayHaveCellphone, contactsMayHaveFax},
  } = useSelector(getExtendedCustomerSettings);

  const activeCustomerContacts = useSelector(getCustomerActiveContactArray(customerUrl));

  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const setCreateDialogOpenFalse = useCallWithFalse(setCreateDialogOpen);
  const [initialName, setInitialName] = useState("");

  const handleAdd = useCallback((searchString: string) => {
    setInitialName(searchString);
    setCreateDialogOpen(true);
  }, []);

  const handleCreateOk = useCallback(
    (url: ContactUrl) => {
      setCreateDialogOpen(false);
      setTimeout(() => onOk(url));
    },
    [onOk],
  );

  const choices = useConditionalMemo(
    () =>
      computeChoices(intl, contactsMayHaveCellphone, contactsMayHaveFax, activeCustomerContacts),
    [activeCustomerContacts, contactsMayHaveCellphone, contactsMayHaveFax, intl],
    open,
    [],
  );

  return (
    <>
      <GenericSingleSelectionSearchDialog<ContactUrl>
        data={choices}
        fullscreen={fullscreen}
        mobilePrimaryLines={1}
        mobileSearchPrimaryLines={1}
        mobileSearchSecondaryLines={2}
        mobileSecondaryLines={2}
        open={open && !createDialogOpen}
        searchTitle={intl.formatMessage({defaultMessage: "Søg kontaktperson"})}
        title={intl.formatMessage({defaultMessage: "Vælg kontaktperson"})}
        onAdd={canManage ? handleAdd : undefined}
        onCancel={onCancel}
        onNone={onNone}
        onOk={onOk}
      />
      <CreateCustomerContactDialog
        contactTemplate={{...emptyContact, name: initialName}}
        customerUrl={customerUrl}
        open={createDialogOpen}
        onCancel={setCreateDialogOpenFalse}
        onOk={handleCreateOk}
      />
    </>
  );
}
