import {User, urlToId} from "@co-common-libs/resources";
import {getFrontendSentry} from "@co-frontend-libs/utils";

export function setSentryUser(user: User): void {
  const sentry = getFrontendSentry();
  sentry.configureScope((scope) => {
    if (user) {
      scope.setUser({
        id: urlToId(user.url),
        username: user.loginIdentifier,
      });
    }
  });
}
