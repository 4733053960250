import {ResourceTypeUnion, TasksPrintout, UserUrl, urlToId} from "@co-common-libs/resources";
import {getNormalisedDeviceTimestamp} from "@co-common-libs/resources-utils";
import {formatDateTime} from "@co-common-libs/utils";
import {VerticalStackingFloatingActionButton} from "@co-frontend-libs/components";
import {ConnectedDownloadButton} from "@co-frontend-libs/connected-components";
import {AppState, actions, getCurrentUserURL, getTasksPrintoutArray} from "@co-frontend-libs/redux";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {MenuToolbar, PageLayout} from "app-components";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import FileDelimitedIcon from "mdi-react/FileDelimitedIcon";
import PlusIcon from "mdi-react/PlusIcon";
import React from "react";
import {FormattedMessage, IntlContext, defineMessages} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {v4 as uuid} from "uuid";
import {StartPrintoutDialog} from "./start-printout-dialog";

const messages = defineMessages({
  title: {
    defaultMessage: "Opgaveudskrifter",
    id: "tasks-printout-list.title.tasks-printouts",
  },
});

interface TasksPrintoutRowProps {
  createdDate: string;
  downloadURL?: string | undefined;
}

class TasksPrintoutRow extends PureComponent<TasksPrintoutRowProps> {
  render(): JSX.Element {
    const {createdDate, downloadURL} = this.props;
    return (
      <TableRow>
        <TableCell>{formatDateTime(createdDate)}</TableCell>
        <TableCell>
          <ConnectedDownloadButton downloadURL={downloadURL} Icon={FileDelimitedIcon} />
        </TableCell>
      </TableRow>
    );
  }
}

interface TasksPrintoutListStateProps {
  currentUserURL: UserUrl | null;
  tasksPrintoutArray: readonly TasksPrintout[];
}

interface TasksPrintoutListDispatchProps {
  create: (instance: ResourceTypeUnion) => void;
}

interface TasksPrintoutListOwnProps {
  onMenuButton: (event: React.MouseEvent) => void;
}

type TasksPrintoutListProps = TasksPrintoutListDispatchProps &
  TasksPrintoutListOwnProps &
  TasksPrintoutListStateProps;

interface TasksPrintoutListState {
  dialogOpen: boolean;
}

class TasksPrintoutList extends PureComponent<TasksPrintoutListProps, TasksPrintoutListState> {
  state = {
    dialogOpen: false,
  };
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  @bind
  handleFabButton(): void {
    this.setState({dialogOpen: true});
  }

  @bind
  handleDialogOk(): void {
    this.setState({dialogOpen: false});
    const {currentUserURL} = this.props;
    const id = uuid();
    const url = instanceURL("tasksPrintout", id);
    const params: TasksPrintout = {
      createdBy: currentUserURL as UserUrl,
      deviceTimestamp: new Date().toISOString(),
      id,
      url,
    };
    this.props.create(params);
  }

  @bind
  handleDialogCancel(): void {
    this.setState({dialogOpen: false});
  }

  render(): JSX.Element {
    const {formatMessage} = this.context;

    const dialog = (
      <StartPrintoutDialog
        open={this.state.dialogOpen}
        onCancel={this.handleDialogCancel}
        onOk={this.handleDialogOk}
      />
    );
    const rows = _.sortBy(this.props.tasksPrintoutArray, getNormalisedDeviceTimestamp)
      .reverse()
      .map((tasksPrintout) => {
        return (
          <TasksPrintoutRow
            key={urlToId(tasksPrintout.url)}
            createdDate={tasksPrintout.deviceTimestamp}
            downloadURL={tasksPrintout.csvDownload}
          />
        );
      });
    const fab = (
      <VerticalStackingFloatingActionButton stackIndex={0} onClick={this.handleFabButton}>
        <PlusIcon />
      </VerticalStackingFloatingActionButton>
    );
    return (
      <PageLayout
        withBottomScrollPadding
        dialogs={dialog}
        floatingActionButton={fab}
        toolbar={
          <MenuToolbar
            title={formatMessage(messages.title)}
            onMenuButton={this.props.onMenuButton}
          />
        }
      >
        <Table size="small" style={{backgroundColor: "#fff"}}>
          <TableHead>
            <TableRow>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Oprettet"
                  id="tasks-printout-list.table-header.added"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Download"
                  id="tasks-printout-list.table-header.download"
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{rows}</TableBody>
        </Table>
      </PageLayout>
    );
  }
}

const ConnectedTasksPrintoutList: React.ComponentType<TasksPrintoutListOwnProps> = connect<
  TasksPrintoutListStateProps,
  TasksPrintoutListDispatchProps,
  TasksPrintoutListOwnProps,
  AppState
>(
  createStructuredSelector<AppState, TasksPrintoutListStateProps>({
    currentUserURL: getCurrentUserURL,
    tasksPrintoutArray: getTasksPrintoutArray,
  }),
  {
    create: actions.create,
  },
)(TasksPrintoutList);

export {ConnectedTasksPrintoutList as TasksPrintoutList};
