import {Config} from "@co-common-libs/config";
import {RemunerationReport, UserUrl, urlToId} from "@co-common-libs/resources";
import {getNormalisedDeviceTimestamp} from "@co-common-libs/resources-utils";
import {formatDate, formatDateShort, formatTime} from "@co-common-libs/utils";
import {Query, makeQuery} from "@co-frontend-libs/db-resources";
import {
  AppState,
  PathTemplate,
  actions,
  getCurrentUserURL,
  getCustomerSettings,
  getPathName,
  getRemunerationReportArray,
} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {MenuToolbar, PageLayout} from "app-components";
import {PureComponent} from "app-utils";
import bowser from "bowser";
import _ from "lodash";
import React, {useCallback} from "react";
import {FormattedMessage, IntlContext, defineMessages} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";

const messages = defineMessages({
  salaryVoucherList: {
    defaultMessage: "Lønbilag",
    id: "salary-voucher-list.title.salary-voucher-list",
  },
});

const dateFormatFunction = bowser.mobile ? formatDateShort : formatDate;
const fromToColumnStyle: React.CSSProperties = bowser.mobile ? {width: 90} : {};

interface SalaryVoucherListRowProps {
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  report: RemunerationReport;
}

function SalaryVoucherListRow(props: SalaryVoucherListRowProps): JSX.Element {
  const {go, report} = props;

  const reportURL = report.url;

  const handleClick = useCallback(() => {
    go("/salaryVoucher/:id", {id: urlToId(reportURL)});
  }, [go, reportURL]);

  return (
    <TableRow onClick={handleClick}>
      <TableCell style={fromToColumnStyle}>{dateFormatFunction(report.fromDate)}</TableCell>
      <TableCell style={fromToColumnStyle}>{dateFormatFunction(report.toDate)}</TableCell>
      <TableCell>
        {dateFormatFunction(report.deviceTimestamp)}
        &nbsp;
        {formatTime(report.deviceTimestamp)}
      </TableCell>
    </TableRow>
  );
}

interface SalaryVoucherListProps {
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  remunerationReportList: readonly RemunerationReport[];
}

const SalaryVoucherList = React.memo(function SalaryVoucherList(
  props: SalaryVoucherListProps,
): JSX.Element {
  const {go, remunerationReportList} = props;
  const rows = remunerationReportList.map((report) => (
    <SalaryVoucherListRow key={report.url} go={go} report={report} />
  ));
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell style={fromToColumnStyle}>
            <FormattedMessage
              defaultMessage="Fra"
              id="remuneration-report-list.table-header.period-start"
            />
          </TableCell>
          <TableCell style={fromToColumnStyle}>
            <FormattedMessage
              defaultMessage="Til"
              id="remuneration-report-list.table-header.period-end"
            />
          </TableCell>
          <TableCell>
            <FormattedMessage
              defaultMessage="Oprettet"
              id="remuneration-report-list.table-header.created"
            />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody style={{cursor: "pointer"}}>{rows}</TableBody>
    </Table>
  );
});

interface SalaryVoucherListContainerStateProps {
  currentUserURL: UserUrl | null;
  customerSettings: Config;
  pathName: string;
  remunerationReportArray: readonly RemunerationReport[];
}

interface SalaryVoucherListContainerDispatchProps {
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  temporaryQueriesRequestedForPath: (
    queries: readonly Query[],
    pathName: string,
    key: string,
  ) => void;
}

interface SalaryVoucherListContainerOwnProps {
  onMenuButton: (event: React.MouseEvent) => void;
}

type SalaryVoucherListContainerProps = SalaryVoucherListContainerDispatchProps &
  SalaryVoucherListContainerOwnProps &
  SalaryVoucherListContainerStateProps;

const TEMPORARY_QUERIES_KEY = "SalaryVoucherListContainer";

class SalaryVoucherListContainer extends PureComponent<SalaryVoucherListContainerProps> {
  UNSAFE_componentWillMount(): void {
    const {pathName} = this.props;
    const remunerationReportQuery = makeQuery({
      check: {type: "alwaysOk"},
      independentFetch: true,
      resourceName: "remunerationReport",
    });
    this.props.temporaryQueriesRequestedForPath(
      [remunerationReportQuery],
      pathName,
      TEMPORARY_QUERIES_KEY,
    );
  }
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {currentUserURL, remunerationReportArray} = this.props;
    let remunerationReportList = _.sortBy(
      remunerationReportArray.filter(
        (report) =>
          report.useForSalaryVouchers &&
          report.dataUrl &&
          report.includedEmployees &&
          currentUserURL &&
          report.includedEmployees.includes(currentUserURL),
      ),
      (report) => report.fromDate + report.toDate + getNormalisedDeviceTimestamp(report),
    ).reverse();
    const {numberOfSalaryVouchersVisibleToEmployees} = this.props.customerSettings;
    if (numberOfSalaryVouchersVisibleToEmployees) {
      remunerationReportList = remunerationReportList.slice(
        0,
        numberOfSalaryVouchersVisibleToEmployees,
      );
    }
    return (
      <PageLayout
        toolbar={
          <MenuToolbar
            title={formatMessage(messages.salaryVoucherList)}
            onMenuButton={this.props.onMenuButton}
          />
        }
      >
        <SalaryVoucherList go={this.props.go} remunerationReportList={remunerationReportList} />
      </PageLayout>
    );
  }
}

const ConnectedSalaryVoucherListContainer: React.ComponentType<SalaryVoucherListContainerOwnProps> =
  connect<
    SalaryVoucherListContainerStateProps,
    SalaryVoucherListContainerDispatchProps,
    SalaryVoucherListContainerOwnProps,
    AppState
  >(
    createStructuredSelector<AppState, SalaryVoucherListContainerStateProps>({
      currentUserURL: getCurrentUserURL,
      customerSettings: getCustomerSettings,
      pathName: getPathName,
      remunerationReportArray: getRemunerationReportArray,
    }),
    {
      go: actions.go,
      temporaryQueriesRequestedForPath: actions.temporaryQueriesRequestedForPath,
    },
  )(SalaryVoucherListContainer);

export default ConnectedSalaryVoucherListContainer;
