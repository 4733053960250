import {CsvImportColumnSpecificationValidatePart} from "@co-common-libs/csv-import-specifications";
import {IntlShape} from "react-intl";

export function validateNoValue(
  column: CsvImportColumnSpecificationValidatePart,
  intl: IntlShape,
): string[] {
  if (column.required) {
    return [
      intl.formatMessage({
        defaultMessage: "Denne kolonne skal have angivet en værdi.",
      }),
    ];
  } else {
    return [];
  }
}
