interface EditAllowedOptions {
  taskCompleted: boolean;
  taskValidated: boolean;
  userIsManager: boolean;
  userIsOther: boolean;
}

export function editTimeCorrectionsAllowed(options: EditAllowedOptions): boolean {
  const {taskCompleted, taskValidated, userIsManager, userIsOther} = options;
  return !taskValidated && (userIsManager || (!taskCompleted && !userIsOther));
}
