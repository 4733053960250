// Allowed for existing code...
// eslint-disable-next-line deprecate/import
import {Contact, Customer, ExpectedAmount} from "@co-common-libs/resources";
import {getNormalisedDeviceTimestamp} from "@co-common-libs/resources-utils";
import {
  identifierPartsComparator,
  identifierToComparisonParts,
  isValidEmail,
} from "@co-common-libs/utils";
import {TrimTextField} from "@co-frontend-libs/components";
import {
  actions,
  getCurrentRole,
  getCustomerSettings,
  getExpectedAmountArray,
  getExtendedCustomerSettings,
  getJournalArray,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Switch,
  useTheme,
} from "@material-ui/core";
import {
  ButtonStyle,
  CustomerContactTable,
  CustomerFileListCard,
  EditCustomerButton,
} from "app-components";
import _ from "lodash";
import React, {useCallback} from "react";
// Allowed for existing code...
// eslint-disable-next-line deprecate/import
import {Cell, Grid} from "react-flexr";
import {FormattedMessage, defineMessages, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {ExpectedAmountCard} from "./expected-amount-card";
import {JournalCard} from "./journal-card";

const messages = defineMessages({
  account: {
    defaultMessage: "Kontonr.",
    id: "customer-instance.label.account",
  },
  active: {
    defaultMessage: "Aktiv",
  },
  address: {
    defaultMessage: "Adresse",
  },
  alias: {
    defaultMessage: "Søgenavn",
    id: "customer-instance.label.alias",
  },
  attention: {
    defaultMessage: "Attention",
  },
  billingEmail: {
    defaultMessage: "Faktura-e-mail",
    id: "customer-instance.label.billing-email",
  },
  cellphone: {
    defaultMessage: "Mobil",
  },
  city: {
    defaultMessage: "By",
  },
  contact: {
    defaultMessage: "Kontaktperson: {name}",
  },
  contactInfo: {
    defaultMessage: "Kontaktinformation",
  },
  createInvoices: {
    defaultMessage: "Lav fakturakladder",
    id: "customer-instance.label.create-invoices",
  },
  customerCardTitle: {
    defaultMessage: "Kunde",
  },
  customerNumber: {
    defaultMessage: "Kundenr.",
    id: "customer-instance.label.customer-number",
  },
  defaultContact: {
    defaultMessage: "Standard kontaktperson",
    id: "customer-instance.card-title.default-contact",
  },
  email: {
    defaultMessage: "E-mail",
  },
  favorite: {
    defaultMessage: "Favorit",
    id: "customer-instance.label.favorite",
  },
  fax: {
    defaultMessage: "Fax",
    id: "customer-instance.label.fax",
  },
  invalidFile: {
    defaultMessage: "Ikke en tilladt filtype: {name}",
    id: "customer-instance.toast.invalid-file",
  },
  logEmail: {
    defaultMessage: "Log email",
    id: "customer-instance.label.log-email",
  },
  name: {
    defaultMessage: "Navn",
  },
  phone: {
    defaultMessage: "Telefon",
  },
  postalCode: {
    defaultMessage: "Postnr.",
  },
  projectManager: {
    defaultMessage: "Formand",
    id: "customer-instance.label.project-manager",
  },
  receiveLogMails: {
    defaultMessage: "Modtag log emails",
    id: "customer-instance.label.receive-log-mails",
  },
  uploadingFile: {
    defaultMessage: "Uploader: {name}",
    id: "customer-instance.toast.uploading-file",
  },
  vatId: {
    defaultMessage: "CVR",
  },
});

interface DefaultContactInstanceProps {
  contact: Contact;
}

const DefaultContactInstance = React.memo(function DefaultContactInstance(
  props: DefaultContactInstanceProps,
): JSX.Element {
  const {formatMessage} = useIntl();
  const {contact} = props;
  const titleCellStyle = {width: "14ex"};

  const {
    contacts: {contactsMayHaveCellphone, contactsMayHaveFax},
  } = useSelector(getExtendedCustomerSettings);

  return (
    <Grid>
      <Cell palm="12/12">
        <Card>
          <CardHeader title={formatMessage(messages.contactInfo)} />
          <CardContent>
            <Grid>
              <Cell palm="12/12" size="6/12">
                <table style={{width: "100%"}}>
                  <tbody>
                    <tr>
                      <td style={titleCellStyle}>{formatMessage(messages.email)}</td>
                      <td>{contact.email}</td>
                    </tr>
                    <tr>
                      <td style={titleCellStyle}>{formatMessage(messages.phone)}</td>
                      <td>{contact.phone}</td>
                    </tr>
                    {contactsMayHaveCellphone ? (
                      <tr>
                        <td style={titleCellStyle}>{formatMessage(messages.cellphone)}</td>
                        <td>{contact.cellphone}</td>
                      </tr>
                    ) : null}
                    {contactsMayHaveFax ? (
                      <tr>
                        <td style={titleCellStyle}>{formatMessage(messages.fax)}</td>
                        <td>{contact.fax}</td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
              </Cell>
            </Grid>
          </CardContent>
        </Card>
      </Cell>
    </Grid>
  );
});

interface CustomerCardProps {
  active: boolean;
  createInvoices: boolean;
  customer: Customer;
  disallowTaskValidation: boolean;
  favorite: boolean;
  onSetActive: (event: React.ChangeEvent<HTMLInputElement>, isInputChecked: boolean) => void;
  onSetCreateInvoices: (
    event: React.ChangeEvent<HTMLInputElement>,
    isInputChecked: boolean,
  ) => void;
  onSetDisallowTaskValidation: (
    event: React.ChangeEvent<HTMLInputElement>,
    isInputChecked: boolean,
  ) => void;
  onSetFavorite: (event: React.ChangeEvent<HTMLInputElement>, isInputChecked: boolean) => void;
  userIsOnlyMachineOperator: boolean;
}

const CustomerCard = React.memo(function CustomerCard(props: CustomerCardProps): JSX.Element {
  const dispatch = useDispatch();
  const customerSettings = useSelector(getExtendedCustomerSettings);
  const currentRole = useSelector(getCurrentRole);
  const {
    customers: {canManage},
  } = customerSettings;
  const {customer} = props;
  const theme = useTheme();

  const handleProjectManagerNameChange = useCallback(
    (value: string) => {
      dispatch(actions.update(customer.url, [{member: "projectManagerName", value}]));
    },
    [customer.url, dispatch],
  );

  const handleAliasChange = useCallback(
    (value: string) => {
      dispatch(actions.update(customer.url, [{member: "alias", value}]));
    },
    [customer.url, dispatch],
  );

  const handleLogEmailChange = useCallback(
    (value: string) => {
      dispatch(actions.update(customer.url, [{member: "logEmail", value}]));
    },
    [customer.url, dispatch],
  );

  const handleReceiveLogMailsChange = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      dispatch(actions.update(customer.url, [{member: "receiveLogMails", value: checked}]));
    },
    [customer.url, dispatch],
  );

  const {
    active,
    createInvoices,
    disallowTaskValidation,
    favorite,
    onSetActive,
    onSetCreateInvoices,
    onSetDisallowTaskValidation,
    onSetFavorite,
    userIsOnlyMachineOperator,
  } = props;
  const {formatMessage} = useIntl();
  const titleCellStyle = {width: "14ex"};
  let billingEmailRow;
  // we sync email from e-conomic and brugerdata...
  if (
    customerSettings.economicSync ||
    customerSettings.brugerdataSync ||
    customerSettings.navSync
  ) {
    billingEmailRow = (
      <tr>
        <td style={titleCellStyle}>{formatMessage(messages.billingEmail)}</td>
        <td>{customer.billingEmail}</td>
      </tr>
    );
  }
  let setActiveBlock;
  if (!userIsOnlyMachineOperator) {
    setActiveBlock = (
      <Grid>
        <Cell>
          <FormControlLabel
            control={<Switch checked={active} color="primary" onChange={onSetActive} />}
            disabled={userIsOnlyMachineOperator || customer.barred}
            label={formatMessage(messages.active)}
            labelPlacement="end"
          />
          {customer.barred ? (
            <FormattedMessage defaultMessage="Kunden er spærret i e-conomic" tagName="div" />
          ) : null}
        </Cell>
      </Grid>
    );
  }
  const setFavoriteBlock = (
    <Grid>
      <Cell>
        <FormControlLabel
          control={<Switch checked={favorite} color="primary" onChange={onSetFavorite} />}
          disabled={userIsOnlyMachineOperator}
          label={formatMessage(messages.favorite)}
          labelPlacement="end"
        />
      </Cell>
    </Grid>
  );
  let setDisallowTaskValidation;
  if (currentRole?.consultant) {
    setDisallowTaskValidation = (
      <Grid style={{backgroundColor: theme.palette.consultant.main}}>
        <Cell>
          <FormControlLabel
            control={
              <Switch
                checked={disallowTaskValidation}
                color="primary"
                onChange={onSetDisallowTaskValidation}
              />
            }
            label={
              <FormattedMessage
                defaultMessage="Opgaver til denne kunde kan ikke godkendes"
                id="customer-instance.label.disallow-task-validation"
              />
            }
            labelPlacement="end"
          />
        </Cell>
      </Grid>
    );
  }

  let createInvoicesBlock;
  if (!userIsOnlyMachineOperator && customerSettings.customerCreateInvoicesSetup) {
    createInvoicesBlock = (
      <Grid>
        <Cell>
          <FormControlLabel
            control={
              <Switch checked={createInvoices} color="primary" onChange={onSetCreateInvoices} />
            }
            disabled={userIsOnlyMachineOperator}
            label={formatMessage(messages.createInvoices)}
            labelPlacement="end"
          />
        </Cell>
      </Grid>
    );
  }

  let projectManagerTableRow;
  let projectManagerEditField;
  if (customerSettings.customerProjectManager) {
    projectManagerTableRow = (
      <tr>
        <td style={titleCellStyle}>{formatMessage(messages.projectManager)}</td>
        <td>{customer.projectManagerName}</td>
      </tr>
    );
    if (currentRole && currentRole.manager) {
      projectManagerEditField = (
        <div>
          <TrimTextField
            label={formatMessage(messages.projectManager)}
            margin="dense"
            value={customer.projectManagerName || ""}
            variant="outlined"
            onChange={handleProjectManagerNameChange}
          />
        </div>
      );
    }
  }
  let aliasEditField;
  if (customerSettings.customerAliasEdit && currentRole && currentRole.manager) {
    aliasEditField = (
      <div>
        <TrimTextField
          label={formatMessage(messages.alias)}
          margin="dense"
          value={customer.alias || ""}
          variant="outlined"
          onChange={handleAliasChange}
        />
      </div>
    );
  }

  let cellphoneRow;
  if (customerSettings.enableCustomerCellphone) {
    cellphoneRow = (
      <tr>
        <td style={titleCellStyle}>{formatMessage(messages.cellphone)}</td>
        <td>{customer.cellphone}</td>
      </tr>
    );
  }

  const logEmailValid = customer.logEmail ? isValidEmail(customer.logEmail) : true;
  return (
    <Card style={{width: "100%"}}>
      {canManage ? (
        <EditCustomerButton buttonStyle={ButtonStyle.FAB} customer={customer} variant="component" />
      ) : null}
      <CardHeader title={formatMessage(messages.customerCardTitle)} />
      <CardContent>
        {canManage &&
        customerSettings.economicSync &&
        !customerSettings.economicAutogeneratedCustomerNumbers &&
        !customer.c5_account ? (
          <div style={{color: theme.palette.error.main}}>
            <FormattedMessage defaultMessage="Kunden mangler kundenummer, tryk på rediger for at tilføje" />
          </div>
        ) : null}
        {canManage &&
        customerSettings.economicSync &&
        customerSettings.economicAutogeneratedCustomerNumbers &&
        !customer.remoteUrl ? (
          <div style={{color: theme.palette.error.main}}>
            <FormattedMessage defaultMessage="Kunden er ikke oprettet i e-conomic, tryk på rediger for at oprette" />
          </div>
        ) : null}
        {setActiveBlock}
        {setFavoriteBlock}
        {setDisallowTaskValidation}
        {createInvoicesBlock}
        <Grid>
          <Cell palm="12/12">
            <table style={{width: "100%"}}>
              <tbody>
                <tr>
                  <td style={titleCellStyle}>{formatMessage(messages.name)}</td>
                  <td>{customer.name}</td>
                </tr>
                <tr>
                  <td style={titleCellStyle}>{formatMessage(messages.vatId)}</td>
                  <td>{customer.vatNumber}</td>
                </tr>
                <tr>
                  <td style={titleCellStyle}>{formatMessage(messages.attention)}</td>
                  <td>{customer.attention}</td>
                </tr>
                <tr>
                  <td style={titleCellStyle}>{formatMessage(messages.address)}</td>
                  <td>{customer.address}</td>
                </tr>
                <tr>
                  <td style={titleCellStyle}>{formatMessage(messages.postalCode)}</td>
                  <td>{customer.postalCode}</td>
                </tr>
                <tr>
                  <td style={titleCellStyle}>{formatMessage(messages.city)}</td>
                  <td>{customer.city}</td>
                </tr>
                {billingEmailRow}
                <tr>
                  <td style={titleCellStyle}>{formatMessage(messages.phone)}</td>
                  <td>{customer.phone}</td>
                </tr>
                {cellphoneRow}
                <tr>
                  <td style={titleCellStyle}>
                    {customerSettings.c5Sync
                      ? formatMessage(messages.account)
                      : formatMessage(messages.customerNumber)}
                  </td>
                  <td>{customer.c5_account}</td>
                </tr>
                <tr>
                  <td style={titleCellStyle}>{formatMessage(messages.alias)}</td>
                  <td>{customer.alias}</td>
                </tr>
                {projectManagerTableRow}
              </tbody>
            </table>
          </Cell>
        </Grid>
        {aliasEditField}
        {projectManagerEditField}
        {customerSettings.showLogEmailFieldOnCustomerInstance ? (
          <>
            <div>
              <TrimTextField
                error={!logEmailValid}
                helperText={
                  !logEmailValid ? (
                    <FormattedMessage
                      defaultMessage="Dette er ikke en gyldig mail adresse"
                      id="customer-tab.mail-now-valid"
                    />
                  ) : undefined
                }
                label={formatMessage(messages.logEmail)}
                margin="dense"
                value={customer.logEmail || ""}
                variant="outlined"
                onChange={handleLogEmailChange}
              />
            </div>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={customer.receiveLogMails ?? true}
                    color="primary"
                    onChange={handleReceiveLogMailsChange}
                  />
                }
                label={formatMessage(messages.receiveLogMails)}
              />
            </div>
          </>
        ) : null}
      </CardContent>
    </Card>
  );
});

interface CustomerTabProps {
  customer: Customer;
  customerContacts: readonly Contact[];
}

export const CustomerTab = React.memo(function CustomerTab(props: CustomerTabProps): JSX.Element {
  const currentRole = useSelector(getCurrentRole);
  const customerSettings = useSelector(getCustomerSettings);
  const expectedAmountArray = useSelector(getExpectedAmountArray);
  const journalArray = useSelector(getJournalArray);
  const workTypeLookup = useSelector(getWorkTypeLookup);
  const dispatch = useDispatch();
  const {customer, customerContacts} = props;

  const handleSetActive = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
      dispatch(actions.update(customer.url, [{member: "active", value}]));
    },
    [customer.url, dispatch],
  );

  const handleSetFavorite = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
      dispatch(actions.update(customer.url, [{member: "favorite", value}]));
    },
    [customer.url, dispatch],
  );

  const handleSetDisallowTaskValidation = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
      dispatch(actions.update(customer.url, [{member: "disallowTaskValidation", value}]));
    },
    [customer.url, dispatch],
  );

  const handleSetCreateInvoices = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
      dispatch(actions.update(customer.url, [{member: "createInvoices", value}]));
    },
    [customer.url, dispatch],
  );

  const userIsOnlyMachineOperator = !currentRole || !currentRole.manager;

  const customerURL = customer.url;
  let contactsComponent: JSX.Element | undefined;

  if (customerSettings.enableCustomerContacts) {
    contactsComponent = (
      <CustomerContactTable customerContacts={customerContacts} customerUrl={customerURL} />
    );
  } else {
    const defaultContact = props.customerContacts.find((contact) => contact.defaultContact);
    if (defaultContact) {
      contactsComponent = <DefaultContactInstance contact={defaultContact} />;
    }
  }

  const active = !!customer.active;
  const favorite = !!customer.favorite;
  const createInvoices = customer.createInvoices !== false;

  const journalList = _.sortBy(
    journalArray.filter((journal) => journal.customer === customerURL),
    getNormalisedDeviceTimestamp,
  ).reverse();

  const getWorkTypeIdentifierName = (expectedAmount: ExpectedAmount): (number | string)[] => {
    const workTypeURL = expectedAmount.workType;
    const workType = workTypeURL ? workTypeLookup(workTypeURL) : null;
    return workType ? identifierToComparisonParts(`${workType.identifier}: ${workType.name}`) : [];
  };

  const expectedAmountList = !customerSettings.expectedAmountEnabled
    ? []
    : _.sortBy(
        expectedAmountArray
          .filter((expectedAmount) => expectedAmount.customer === customerURL)
          .sort((a, b) =>
            identifierPartsComparator(getWorkTypeIdentifierName(a), getWorkTypeIdentifierName(b)),
          )
          .reverse(),
        (expectedAmount) => expectedAmount.year,
      ).reverse();

  return (
    <div style={{padding: "1em"}}>
      <Grid>
        <Cell palm="12/12">
          <CustomerCard
            active={active}
            createInvoices={createInvoices}
            customer={customer}
            disallowTaskValidation={customer.disallowTaskValidation ?? false}
            favorite={favorite}
            userIsOnlyMachineOperator={userIsOnlyMachineOperator}
            onSetActive={handleSetActive}
            onSetCreateInvoices={handleSetCreateInvoices}
            onSetDisallowTaskValidation={handleSetDisallowTaskValidation}
            onSetFavorite={handleSetFavorite}
          />
        </Cell>
        <Cell palm="12/12">
          {customerSettings.customerFileUpload ? (
            <CustomerFileListCard customerURL={customerURL} editable={!userIsOnlyMachineOperator} />
          ) : null}
        </Cell>
      </Grid>
      {contactsComponent}
      {customerSettings.expectedAmountEnabled ? (
        <Grid>
          <Cell palm="12/12">
            <ExpectedAmountCard customerURL={customerURL} expectedAmountList={expectedAmountList} />
          </Cell>
        </Grid>
      ) : null}
      {customerSettings.customerJournal ? (
        <Grid>
          <Cell palm="12/12">
            <JournalCard customerURL={customerURL} journalList={journalList} />
          </Cell>
        </Grid>
      ) : null}
    </div>
  );
});
