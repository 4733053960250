import {TimerUrl} from "@co-common-libs/resources";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {useResettingState} from "@co-frontend-libs/utils";
import {DialogContent} from "@material-ui/core";
import React, {useCallback} from "react";
import {useIntl} from "react-intl";
import {TimeCorrectionForm, TimeCorrectionFormProps} from "./time-correction-form";
import {dateFromDateAndTime, selectionCheck} from "./time-correction-utils";

interface AddTimeCorrectionDialogProps
  extends Pick<
    TimeCorrectionFormProps,
    | "genericPrimaryTimer"
    | "genericPrimaryTimerLabel"
    | "hidePrimaryTimer"
    | "legalIntervals"
    | "secondaryTimers"
  > {
  defaultDate: string | null;
  onCancel: () => void;
  onOk: (fromTimestamp: string, toTimestamp: string, timer: TimerUrl | null) => void;
  open: boolean;
}

export const AddTimeCorrectionDialog = React.memo(function AddTimeCorrectionDialog(
  props: AddTimeCorrectionDialogProps,
) {
  const {defaultDate, legalIntervals, onCancel, onOk, open, ...other} = props;

  const intl = useIntl();

  const [fromDate, setFromDate] = useResettingState<string | null>(defaultDate, open);
  const [toDate, setToDate] = useResettingState<string | null>(defaultDate, open);

  const [fromTime, setFromTime] = useResettingState<string | null>(null, open);
  const [toTime, setToTime] = useResettingState<string | null>(null, open);

  const [timerUrl, setTimerUrl] = useResettingState<TimerUrl | null | undefined>(undefined, open);

  const {formValid} = selectionCheck({
    fromDate,
    fromTime,
    legalIntervals,
    timerUrl,
    toDate,
    toTime,
  });

  const handleOk = useCallback(() => {
    if (fromDate && fromTime && toDate && toTime && timerUrl !== undefined) {
      const fromDateTime = dateFromDateAndTime(fromDate, fromTime).toISOString();
      const toDateTime = dateFromDateAndTime(toDate, toTime).toISOString();
      onOk(fromDateTime, toDateTime, timerUrl);
    }
  }, [fromDate, fromTime, onOk, timerUrl, toDate, toTime]);

  return (
    <ResponsiveDialog
      okDisabled={!formValid}
      open={open}
      title={intl.formatMessage({defaultMessage: "Indfør korrektion"})}
      onCancel={onCancel}
      onOk={handleOk}
    >
      <DialogContent>
        <TimeCorrectionForm
          fromDate={fromDate}
          fromTime={fromTime}
          legalIntervals={legalIntervals}
          selectedTimer={timerUrl}
          toDate={toDate}
          toTime={toTime}
          onFromDateChange={setFromDate}
          onFromTimeChange={setFromTime}
          onTimerUrlChange={setTimerUrl}
          onToDateChange={setToDate}
          onToTimeChange={setToTime}
          {...other}
        />
      </DialogContent>
    </ResponsiveDialog>
  );
});
