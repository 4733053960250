import {MachineUrl} from "@co-common-libs/resources";
import {Chip} from "@material-ui/core";
import {InlinedMachineUse} from "app-utils";
import React, {useCallback} from "react";

interface MachineChipProps {
  deletable: boolean;
  machineUse: InlinedMachineUse;
  onDelete: (url: MachineUrl) => void;
}

export const MachineChip = React.memo(function MachineChip({
  deletable,
  machineUse,
  onDelete,
}: MachineChipProps): JSX.Element {
  const {machine} = machineUse;
  const handleDelete = useCallback(() => onDelete(machine.url), [machine.url, onDelete]);

  const machineID = machine.c5_machine;
  const machineName = machine.name;
  const {priceGroup} = machineUse;
  let priceGroupName;
  if (priceGroup) {
    priceGroupName = priceGroup.name;
  }

  const text =
    machineName +
    (machineID ? ` (${machineID})` : "") +
    (priceGroupName ? ` (${priceGroupName})` : "");
  const style: React.CSSProperties = {
    maxWidth: "100%",
    overflowX: "hidden",
    textOverflow: "ellipsis",
  };
  if (deletable) {
    return <Chip label={text} style={style} onDelete={handleDelete} />;
  } else {
    return <Chip label={text} style={style} />;
  }
});
