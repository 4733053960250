import {
  PriceGroup,
  PriceGroupUrl,
  PriceItem,
  PriceItemUrl,
  PriceItemUseWithOrder,
  PriceItemUsesDict,
  Unit,
  UnitUrl,
} from "@co-common-libs/resources";
import {getUnitString, priceItemIsTime} from "@co-common-libs/resources-utils";
import {identifierComparator, simpleComparator} from "@co-common-libs/utils";
import {ExtendedConfig} from "extended-config";
import _ from "lodash";

interface SortEntry {
  readonly identifier: string;
  readonly priceItemUse: PriceItemUseWithOrder;
}
type SortArray = readonly SortEntry[];

function sortEntriesByUnitAndName(
  entries: SortArray,
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined,
  unitLookup: (url: UnitUrl) => Unit | undefined,
): SortArray {
  return _.sortBy(entries, [
    ({priceItemUse}): string => {
      const priceItem = priceItemLookup(priceItemUse.priceItem);
      return priceItem ? getUnitString(priceItem, unitLookup).toLowerCase() : "";
    },
    ({priceItemUse}): string => {
      const priceItem = priceItemLookup(priceItemUse.priceItem);
      return priceItem ? priceItem.name : "";
    },
  ]);
}

function sortEntriesByManualOrder(
  entries: SortArray,
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined,
): SortArray {
  return _.sortBy(entries, ({priceItemUse}) => {
    if (priceItemUse.priceGroup) {
      const priceGroup = priceGroupLookup(priceItemUse.priceGroup);
      return (
        priceGroup?.priceGroupItemSet.find(
          (priceGroupItem) => priceGroupItem.priceItem === priceItemUse.priceItem,
        )?.order || 0
      );
    }
    return 0;
  });
}

function sortEntriesByLineNumber(
  entries: SortArray,
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined,
): SortArray {
  return _.sortBy(
    entries,
    ({priceItemUse}) => priceItemLookup(priceItemUse.priceItem)?.lineNumber || 0,
  );
}

function sortEntriesByRemoteURL(
  entries: SortArray,
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined,
): SortArray {
  return entries.slice().sort((a, b) => {
    const aPriceItem = priceItemLookup(a.priceItemUse.priceItem);
    const bPriceItem = priceItemLookup(b.priceItemUse.priceItem);
    if (aPriceItem && bPriceItem) {
      return identifierComparator(aPriceItem.remoteUrl, bPriceItem.remoteUrl);
    } else {
      // the ones having price items loaded first
      if (aPriceItem) {
        return -1;
      } else if (bPriceItem) {
        return 1;
      } else {
        // neither priceitem loaded; sort by price item URL to give a
        // consistent result...
        return simpleComparator(a.priceItemUse.priceItem, b.priceItemUse.priceItem);
      }
    }
  });
}

function sortEntriesByPriceGroupIdentifierAndName(
  entries: SortArray,
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined,
): SortArray {
  return _.sortBy(entries, [
    ({priceItemUse}) => {
      const priceGroup = priceItemUse.priceGroup
        ? priceGroupLookup(priceItemUse.priceGroup)
        : undefined;
      return priceGroup ? priceGroup.name.toLowerCase() : "";
    },
  ]).sort((a, b) => {
    const aPriceGroup = a.priceItemUse.priceGroup
      ? priceGroupLookup(a.priceItemUse.priceGroup)
      : undefined;
    const bPriceGroup = b.priceItemUse.priceGroup
      ? priceGroupLookup(b.priceItemUse.priceGroup)
      : undefined;

    return identifierComparator(
      aPriceGroup ? aPriceGroup.identifier : "",
      bPriceGroup ? bPriceGroup.identifier : "",
    );
  });
}

const priceItemUseListByPriceItemWorkTypeBeforeTimerBeforeMachineComparator = (
  a: SortEntry,
  b: SortEntry,
): number => {
  const priceItemUseA = a.priceItemUse;
  const priceItemUseB = b.priceItemUse;
  if (priceItemUseA.workType && !priceItemUseB.workType) {
    return -1;
  } else if (!priceItemUseA.workType && priceItemUseB.workType) {
    return 1;
  } else if (priceItemUseA.timer && !priceItemUseB.timer) {
    return -1;
  } else if (!priceItemUseA.timer && priceItemUseB.timer) {
    return 1;
  } else {
    return 0;
  }
};

const sortPriceItemUseListByWorkTypeBeforeTimerBeforeMachine = (entries: SortArray): SortArray =>
  entries.slice().sort(priceItemUseListByPriceItemWorkTypeBeforeTimerBeforeMachineComparator);

const priceItemUseListTimeBeforeOthersComparator = (
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined,
  unitLookup: (url: UnitUrl) => Unit | undefined,
  a: SortEntry,
  b: SortEntry,
): number => {
  const aPriceItem = priceItemLookup(a.priceItemUse.priceItem);
  const bPriceItem = priceItemLookup(b.priceItemUse.priceItem);
  const aIsTime = aPriceItem ? priceItemIsTime(unitLookup, aPriceItem) : null;
  const bIsTime = bPriceItem ? priceItemIsTime(unitLookup, bPriceItem) : null;

  if (aIsTime && !bIsTime) {
    return -1;
  } else if (!aIsTime && bIsTime) {
    return 1;
  } else {
    return 0;
  }
};

function sortPriceItemUseListTimeBeforeOthers(
  entries: SortArray,
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined,
  unitLookup: (url: UnitUrl) => Unit | undefined,
): SortArray {
  return entries
    .slice()
    .sort(priceItemUseListTimeBeforeOthersComparator.bind(null, priceItemLookup, unitLookup));
}

function sortPriceItemUseListCombinedTimeBeforeOthersAndWorkTypeBeforeMachine(
  entries: SortArray,
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined,
  unitLookup: (url: UnitUrl) => Unit | undefined,
): SortArray {
  return entries
    .slice()
    .sort(
      (a, b) =>
        priceItemUseListTimeBeforeOthersComparator(priceItemLookup, unitLookup, a, b) ||
        priceItemUseListByPriceItemWorkTypeBeforeTimerBeforeMachineComparator(a, b),
    );
}

export function sortPriceItemUses(
  priceItemUses: PriceItemUsesDict,
  customerSettings: ExtendedConfig,
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined,
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined,
  unitLookup: (url: UnitUrl) => Unit | undefined,
): PriceItemUsesDict {
  const unsorted: SortArray = Object.entries(priceItemUses).map(([identifier, priceItemUse]) => ({
    identifier,
    priceItemUse,
  }));

  if (!unsorted.length) {
    return priceItemUses;
  }

  // In order to sort primarily by price group, secondarily by
  // manual ordering/line number/recid depending on settings and
  // tertiarily by unit and name, we use stable sorts and sort by
  // these in inverse order, i.e. the most important criteria last,
  // while keeping e.g. the order of price items within the same price
  // group in the order defined by the other criteria.
  const baseSorted = sortEntriesByUnitAndName(unsorted, priceItemLookup, unitLookup);
  console.assert(baseSorted.length === unsorted.length, "unit/name sorting changed length?");

  const configSpecificSorted = customerSettings.priceItems.allowManualPriceGroupPriceItemOrdering
    ? sortEntriesByManualOrder(baseSorted, priceGroupLookup)
    : customerSettings.navSync
      ? sortEntriesByLineNumber(baseSorted, priceItemLookup)
      : sortEntriesByRemoteURL(baseSorted, priceItemLookup);

  console.assert(
    configSpecificSorted.length === unsorted.length,
    "config specific sorting changed length?",
  );

  const groupIdentifierSorted = sortEntriesByPriceGroupIdentifierAndName(
    configSpecificSorted,
    priceGroupLookup,
  );

  const finalSorted =
    customerSettings.workTypeBeforeTimerBeforeMachinePriceItems &&
    !customerSettings.timePriceItemsBeforeOtherPriceItems
      ? sortPriceItemUseListByWorkTypeBeforeTimerBeforeMachine(groupIdentifierSorted)
      : customerSettings.workTypeBeforeTimerBeforeMachinePriceItems &&
          customerSettings.timePriceItemsBeforeOtherPriceItems
        ? sortPriceItemUseListCombinedTimeBeforeOthersAndWorkTypeBeforeMachine(
            groupIdentifierSorted,
            priceItemLookup,
            unitLookup,
          )
        : customerSettings.timePriceItemsBeforeOtherPriceItems
          ? sortPriceItemUseListTimeBeforeOthers(groupIdentifierSorted, priceItemLookup, unitLookup)
          : groupIdentifierSorted;

  console.assert(finalSorted.length === unsorted.length, "pricegroup sorting changed length?");

  let minNextOrder = finalSorted[0].priceItemUse.order;
  const sortedWithOrder: SortEntry[] = [];
  for (const entry of finalSorted) {
    const {identifier, priceItemUse} = entry;
    if (priceItemUse.order < minNextOrder) {
      sortedWithOrder.push({
        identifier,
        priceItemUse: {...priceItemUse, order: minNextOrder},
      });
      minNextOrder += 1;
    } else {
      sortedWithOrder.push(entry);
      minNextOrder = priceItemUse.order + 1;
    }
  }

  const asEntries = sortedWithOrder.map(
    ({identifier, priceItemUse}) => [identifier, priceItemUse] as const,
  );

  if (_.isEqual(asEntries, unsorted)) {
    return priceItemUses;
  } else {
    return Object.fromEntries(asEntries);
  }
}
