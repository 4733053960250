import {Config} from "@co-common-libs/config";
import {
  Location,
  ReportingInputSpecification,
  ReportingLocation,
  ReportingLogEntry,
  ReportingSpecification,
  Task,
} from "@co-common-libs/resources";
import {getContactArray, getCurrentRole, getCustomerLookup} from "@co-frontend-libs/redux";
import {Button, ButtonProps, useTheme} from "@material-ui/core";
import {green} from "@material-ui/core/colors";
import useMergedRef from "@react-hook/merged-ref";
import {getGoogleMapsUrl, getLocationPhone, logLocationMissingRequired} from "app-utils";
import SwapVerticalIcon from "mdi-react/SwapVerticalIcon";
import React, {useCallback, useMemo} from "react";
import {DragSourceMonitor, DropTargetMonitor, useDrag, useDrop} from "react-dnd";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {getLocationDataColumns} from "../reporting-helpers";
import {LocationDataTable} from "../tables/location-data-table";
import {LocationEntryDataSumTable} from "../tables/location-entry-data-sum-table";
import {getLocationButtonString} from "../utils";

interface LocationButtonProps {
  count: number;
  customerSettings: Config;
  disabled: boolean;
  editable: boolean;
  fieldNotesPerLocation?: ReadonlyMap<string, [string | undefined, string | undefined]> | undefined;
  index: number;
  inputSpecificationsMap: ReadonlyMap<string, ReportingInputSpecification>;
  location?: Location | undefined;
  locationData: ReportingLogEntry[];
  logSpecification: ReportingSpecification;
  onClick?: ((type: "delivery" | "pickup" | "workplace", identifier: string) => void) | undefined;
  onDragDrop: (fromIdentifier: string, toIdentifier: string) => void;
  onEditLocationButtonClick: (
    logEntryType: "delivery" | "pickup" | "workplace",
    logEntryIdentifier: string,
  ) => void;
  reportingLocation: ReportingLocation;
  reportingLocationIdentifier: string;
  swappingLocations: boolean;
  task: Task;
  type: "delivery" | "pickup" | "workplace";
}

export const LocationButton = React.memo(function LocationButton(
  props: LocationButtonProps,
): JSX.Element {
  const {
    count,
    customerSettings,
    disabled,
    editable,
    fieldNotesPerLocation,
    index,
    inputSpecificationsMap,
    location,
    locationData,
    logSpecification,
    onClick,
    onDragDrop,
    onEditLocationButtonClick,
    reportingLocation,
    reportingLocationIdentifier,
    swappingLocations,
    task,
    type,
  } = props;

  const customerLookup = useSelector(getCustomerLookup);
  const contactArray = useSelector(getContactArray);
  const currentRole = useSelector(getCurrentRole);

  const theme = useTheme();

  const handleClick = useCallback((): void => {
    if (onClick) {
      onClick(type, reportingLocationIdentifier);
    }
  }, [reportingLocationIdentifier, onClick, type]);

  const mapsURL = location ? getGoogleMapsUrl(location) : null;

  const phone = getLocationPhone(location || null, contactArray, customerLookup);

  const handleEditClick = useCallback((): void => {
    onEditLocationButtonClick(type, reportingLocationIdentifier);
  }, [reportingLocationIdentifier, onEditLocationButtonClick, type]);

  const [dropCollectedProps, drop] = useDrop({
    accept: "location",
    canDrop: (item: any, _monitor: DropTargetMonitor) => {
      return (
        swappingLocations &&
        type === item.locationType &&
        reportingLocationIdentifier !== item.reportingLocationIdentifier
      );
    },
    collect: (monitor: DropTargetMonitor) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
    }),
    drop: (item: any, _monitor: DropTargetMonitor) => {
      if (onDragDrop) {
        onDragDrop(item.reportingLocationIdentifier, reportingLocationIdentifier);
      }
    },
  });
  const {canDrop, isOver} = dropCollectedProps;

  const [dragCollectedProps, drag] = useDrag({
    canDrag: (_monitor: DragSourceMonitor) => {
      return swappingLocations;
    },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: {
      locationType: type,
      reportingLocationIdentifier,
    },
    type: "location",
  });
  const {isDragging} = dragCollectedProps;

  let includeCount = false;
  let typeMarker = "";
  if (type === "pickup") {
    typeMarker = "A";
    includeCount = customerSettings.logPickupButtonDisplayCount;
  } else if (type === "delivery") {
    typeMarker = "L";
    includeCount = customerSettings.logDeliveryButtonDisplayCount;
  } else {
    includeCount = customerSettings.logWorkplaceDisplayCount;
  }
  const pos = index + 1;

  const countPart = includeCount && count ? ` (${count})` : "";
  const label = `${typeMarker}${pos}${countPart}, ${getLocationButtonString(
    customerSettings.logButtonText,
    location,
  )}`;
  let icon;
  if (swappingLocations) {
    icon = (
      <span style={{fill: "#fff", position: "absolute", right: 6, top: 6}}>
        <SwapVerticalIcon />
      </span>
    );
  }

  const [
    ,
    // dataHeaderColumns
    // note comma; dataColumnPositions is assigned second element
    dataColumnPositions,
  ] = useMemo(() => getLocationDataColumns(logSpecification), [logSpecification]);

  const multiRef = useMergedRef<HTMLDivElement>(drop, drag);

  let fieldNotesBlock;
  if (reportingLocation && fieldNotesPerLocation) {
    const fieldNotes =
      reportingLocation.location && fieldNotesPerLocation.get(reportingLocation.location);
    if (fieldNotes) {
      const [taskFieldNote, orderFieldNote] = fieldNotes;
      if (taskFieldNote || orderFieldNote) {
        fieldNotesBlock = (
          <div style={{paddingBottom: "1em"}}>
            {taskFieldNote}
            {taskFieldNote ? <br /> : null}
            {orderFieldNote}
          </div>
        );
      }
    }
  }

  const style: React.CSSProperties = {marginBottom: 8};
  if (isDragging) {
    style.opacity = 0.2;
  }
  if (isOver && canDrop) {
    style.backgroundColor = green[100];
  }

  const buttonOptionalClickHandlerProps: ButtonProps = {};
  if (!swappingLocations) {
    buttonOptionalClickHandlerProps.onClick = handleClick;
  }

  const missingRequiredError = useMemo(() => {
    if (!currentRole?.manager) {
      return null;
    }
    const missingRequired = logLocationMissingRequired(
      logSpecification.workplaceData,
      reportingLocation,
    );
    if (missingRequired) {
      return (
        <div style={{color: theme.palette.error.main}}>
          <FormattedMessage defaultMessage="Påkrævede felter for stedet mangler at blive udfyldt." />
        </div>
      );
    } else {
      return null;
    }
  }, [
    currentRole?.manager,
    logSpecification.workplaceData,
    reportingLocation,
    theme.palette.error.main,
  ]);

  return (
    <div ref={multiRef} style={style}>
      <Button
        fullWidth
        color="secondary"
        disabled={disabled || !onClick}
        disableTouchRipple={!!swappingLocations}
        endIcon={icon}
        variant="contained"
        {...buttonOptionalClickHandlerProps}
      >
        {label}
      </Button>
      {missingRequiredError}
      <LocationDataTable
        customerSettings={customerSettings}
        data={reportingLocation}
        dataColumnPositions={dataColumnPositions}
        disabled={!editable}
        entryCount={count}
        inputSpecificationsMap={inputSpecificationsMap}
        logLocationId={reportingLocationIdentifier}
        logSpecification={logSpecification}
        mapsURL={mapsURL}
        phone={phone}
        task={task}
        onEditClick={handleEditClick}
      />
      <LocationEntryDataSumTable data={locationData} />
      {fieldNotesBlock}
    </div>
  );
});
