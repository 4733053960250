import bowser from "bowser";
import React from "react";

const MOBILE = bowser.mobile || bowser.tablet;
export const focusButton = (
  buttonRef: HTMLButtonElement | React.RefObject<HTMLButtonElement> | null,
): void => {
  if (MOBILE || !buttonRef) {
    return;
  }
  window.setTimeout(() => {
    if ("current" in buttonRef) {
      buttonRef.current?.focus();
    } else {
      buttonRef.focus();
    }
  });
};
