import {
  Location,
  Patch,
  RoutePlanTask,
  RoutePlanTaskUrl,
  RouteTask,
  RouteTaskUrl,
  Task,
  TimerStart,
  emptyRouteTask,
} from "@co-common-libs/resources";
import {formatAddress, formatTime} from "@co-common-libs/utils";
import {DeleteDialog} from "@co-frontend-libs/components";
import {actions, getCustomerSettings} from "@co-frontend-libs/redux";
import {Button, Grid, IconButton, useTheme} from "@material-ui/core";
import {Linkify} from "app-components";
import {getGoogleMapsUrl, useFalseCallback, useTrueCallback} from "app-utils";
import bowser from "bowser";
import {instanceURL} from "frontend-global-config";
import DeleteIcon from "mdi-react/DeleteIcon";
import MapIcon from "mdi-react/MapIcon";
import React, {useCallback, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuid} from "uuid";

const COMPLETED_COLUMN_WIDTH_WITH_START_COMPLETED = 6;
const COMPLETED_COLUMN_WIDTH_WITH_START_NOT_COMPLETED = 4;
const START_COLUMN_WIDTH_WITH_START_COMPLETED = 6;
const START_COLUMN_WIDTH_WITH_START_NOT_COMPLETED = 8;
const COLUMN_WIDTH_WITHOUT_START = 12;

interface RouteEntryProps {
  currentUserURL: string | null;
  onRouteTaskCompleteClick?: (
    routePlanTaskUrl: RoutePlanTaskUrl,
    routeTaskUrl: RouteTaskUrl | undefined,
  ) => void;
  onStartClick?: () => void;
  otherTaskStarted: boolean;
  routePlanTask?: RoutePlanTask;
  routeTask?: RouteTask | undefined;
  task: Task;
  timerStartArray: readonly TimerStart[];
  workplace: Location;
}

export const RouteEntry = React.memo(function RouteEntry(props: RouteEntryProps): JSX.Element {
  const {
    onRouteTaskCompleteClick,
    onStartClick,
    otherTaskStarted,
    routePlanTask,
    routeTask,
    task,
    workplace,
  } = props;
  const dispatch = useDispatch();
  const handleCompletedClick = useCallback(
    (_event: unknown) => {
      if (!onRouteTaskCompleteClick || !routePlanTask) {
        return;
      }
      onRouteTaskCompleteClick(routePlanTask.url, routeTask?.url);
    },
    [onRouteTaskCompleteClick, routePlanTask, routeTask],
  );

  const {geolocation, routeTaskStartStop} = useSelector(getCustomerSettings);
  const {registerPositionOnTimerClick} = geolocation;

  const handleStartedClick = useCallback(() => {
    if (!onStartClick) {
      return;
    }
    onStartClick();
    if (registerPositionOnTimerClick) {
      dispatch(actions.registerTaskPosition(task.url));
    }
    if (routeTask) {
      const patch: Patch<RouteTask> = [{member: "started", value: new Date().toISOString()}];
      dispatch(actions.update(routeTask.url, patch));
    } else if (routePlanTask) {
      const routeTaskID = uuid();
      const routeTaskURL = instanceURL("routeTask", routeTaskID);
      const data: RouteTask = {
        ...emptyRouteTask,
        customer: routePlanTask.customer,
        deadline: routePlanTask.deadline,
        description: routePlanTask.description,
        id: routeTaskID,
        notesFromManager: routePlanTask.notesFromManager,
        order: routePlanTask.order,
        project: routePlanTask.project,
        relatedLocation: routePlanTask.relatedLocation,
        route: task.url,
        started: new Date().toISOString(),
        url: routeTaskURL,
      };

      dispatch(actions.create(data));
    }
  }, [onStartClick, registerPositionOnTimerClick, routeTask, routePlanTask, task.url, dispatch]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const handleDeleteClick = useTrueCallback(setDeleteDialogOpen, [setDeleteDialogOpen]);

  const handleDeleteDialogOk = useCallback(() => {
    setDeleteDialogOpen(false);
    if (routeTask) {
      dispatch(actions.update(routeTask.url, [{member: "started", value: null}]));
    }
  }, [dispatch, routeTask]);

  const description = routeTask?.description || routePlanTask?.description || "";
  const completed = routeTask?.completed || false;
  const deadline = routeTask?.deadline || routePlanTask?.deadline || null;
  const notesFromManager = routeTask?.notesFromManager || routePlanTask?.notesFromManager || "";
  const started = routeTask?.started || null;

  const theme = useTheme();
  const startedNotCompleted = started && !completed;

  const mapsURL = workplace ? getGoogleMapsUrl(workplace) : null;

  return (
    <div style={{borderBottom: "1px solid", paddingBottom: 8}}>
      {deadline ? <h4 style={{fontSize: "smaller"}}>{formatTime(deadline)}</h4> : null}
      <h4>
        {formatAddress(workplace)}
        <IconButton
          color="primary"
          disabled={!mapsURL}
          href={mapsURL || ""}
          style={{
            display: "inline-block",
            height: 24,
            marginLeft: 10,
            padding: 0,
            width: 24,
          }}
          target={bowser.ios && typeof cordova !== "undefined" ? "_system" : "_blank"}
        >
          <MapIcon size={24} />
        </IconButton>
      </h4>
      {description ? (
        <div>
          <Linkify>{description}</Linkify>
        </div>
      ) : null}
      {notesFromManager ? <Linkify>{notesFromManager}</Linkify> : null}
      <Grid container alignItems="center" style={{height: 48}}>
        {routeTaskStartStop ? (
          <Grid
            item
            xs={
              startedNotCompleted
                ? START_COLUMN_WIDTH_WITH_START_NOT_COMPLETED
                : START_COLUMN_WIDTH_WITH_START_COMPLETED
            }
          >
            <Button
              color="secondary"
              disabled={!!started || otherTaskStarted || task.completed || !onStartClick}
              variant="contained"
              onClick={handleStartedClick}
            >
              <FormattedMessage defaultMessage="Start" id="route-tab.label.start" />
            </Button>
            {started ? (
              <span style={{paddingLeft: 6}}>
                <FormattedMessage
                  defaultMessage="Kl. {time}"
                  id="route-tab.label.time"
                  values={{time: formatTime(started)}}
                />
              </span>
            ) : null}
            {startedNotCompleted ? (
              <IconButton onClick={handleDeleteClick}>
                <DeleteIcon color={theme.palette.error.main} />
              </IconButton>
            ) : null}
          </Grid>
        ) : null}

        <Grid
          item
          xs={
            routeTaskStartStop
              ? startedNotCompleted
                ? COMPLETED_COLUMN_WIDTH_WITH_START_NOT_COMPLETED
                : COMPLETED_COLUMN_WIDTH_WITH_START_COMPLETED
              : COLUMN_WIDTH_WITHOUT_START
          }
        >
          <div style={{textAlign: "right"}}>
            {started && completed ? (
              <span style={{paddingRight: 5}}>
                <FormattedMessage
                  defaultMessage="Kl. {time}"
                  id="route-tab.label.time"
                  values={{time: formatTime(completed)}}
                />
              </span>
            ) : null}

            <Button
              color="secondary"
              disabled={
                !!completed ||
                otherTaskStarted ||
                task.completed ||
                (routeTaskStartStop && !started) ||
                !routePlanTask ||
                !onRouteTaskCompleteClick
              }
              variant="contained"
              onClick={handleCompletedClick}
            >
              <FormattedMessage defaultMessage="Udført" />
            </Button>
          </div>
        </Grid>
      </Grid>
      <DeleteDialog
        okLabel={<FormattedMessage defaultMessage="Nulstil" id="route-tab.delete-dialog.reset" />}
        open={deleteDialogOpen}
        title={
          <FormattedMessage
            defaultMessage="Nulstil starttiden?"
            id="route-tab.delete-dialog.title"
          />
        }
        onCancel={useFalseCallback(setDeleteDialogOpen, [setDeleteDialogOpen])}
        onOk={handleDeleteDialogOk}
      />
    </div>
  );
});
