import {Contact, Customer, Location, OrderUrl, Role, TaskUrl} from "@co-common-libs/resources";
import {formatAddress} from "@co-common-libs/utils";
import {Button, Card, CardActions, CardContent} from "@material-ui/core";
import {
  AddressLine,
  ButtonStyle,
  EditCustomerButton,
  EmailLine,
  LocationInfo,
  PhoneLine,
} from "app-components";
import {ExtendedConfig} from "extended-config";
import React from "react";
import {FormattedMessage, defineMessages, useIntl} from "react-intl";
import {ChangeProjectButton} from "./change-project-button";

const messages = defineMessages({
  changeCustomer: {
    defaultMessage: "Skift kunde",
    id: "customer-card.label.change-customer",
  },
});

export const getCustomerInfo = (
  customer?: Customer,
  contact?: Contact,
  preferContact: boolean = false,
): {
  cellphone: string | undefined;
  contactName: string | undefined;
  email: string | undefined;
  phone: string | undefined;
} => {
  let contactPhone;
  let contactCellphone;
  let contactEmail;
  let contactName;
  let customerPhone;
  let customerCellphone;
  let customerEmail;
  if (customer) {
    customerPhone = customer.phone;
    customerCellphone = customer.cellphone;
    customerEmail = customer.billingEmail;
  }
  if (contact) {
    contactPhone = contact.phone;
    contactCellphone = contact.cellphone;
    contactEmail = contact.email;
    if (!customer || contact.name !== customer.name) {
      contactName = contact.name;
    }
  }
  let phone;
  let email;
  let cellphone;
  if (preferContact) {
    phone = contactPhone || customerPhone;
    cellphone = contactCellphone || customerCellphone;
    email = contactEmail || customerEmail;
  } else {
    phone = customerPhone || contactPhone;
    cellphone = customerCellphone || contactCellphone;
    email = customerEmail || contactEmail;
  }
  return {cellphone, contactName, email, phone};
};

interface CustomerCardProps {
  address?: string | undefined;
  completed: boolean;
  contact?: Contact | undefined;
  currentRole: Role | null;
  customer?: Customer | undefined;
  customerSettings: ExtendedConfig;
  department?: string;
  onChangeCustomerButton: () => void;
  orderURL: OrderUrl | null;
  pickupLocation?: Location | undefined;
  taskURL: TaskUrl;
  userIsOnlyMachineOperator: boolean;
  userIsOtherMachineOperator: boolean;
  userIsSeniorMachineOperator: boolean;
  validated: boolean;
  workplace?: Location | undefined;
}

export const CustomerCard = React.memo(function CustomerCard(
  props: CustomerCardProps,
): JSX.Element {
  const {formatMessage} = useIntl();
  const {
    completed,
    contact,
    currentRole,
    customer,
    customerSettings,
    onChangeCustomerButton,
    pickupLocation,
    userIsOnlyMachineOperator,
    userIsOtherMachineOperator,
    userIsSeniorMachineOperator,
    validated,
    workplace,
  } = props;

  const userIsJobber = currentRole && currentRole.jobber;

  const customerName = customer ? customer.name : null;
  const contactInfo = getCustomerInfo(customer, contact, true);
  const {contactName} = contactInfo;

  let contactNameBlock;
  if (contactName) {
    contactNameBlock = (
      <FormattedMessage
        defaultMessage="Kontaktperson: {contactName}"
        tagName="h4"
        values={{contactName}}
      />
    );
  }

  let workplaceBlock;
  if (customerSettings.taskPickupDelivery) {
    workplaceBlock = (
      <div>
        <FormattedMessage defaultMessage="Afhentningssted" tagName="h4" />
        {pickupLocation && <LocationInfo location={pickupLocation} />}

        <FormattedMessage defaultMessage="Arbejds-/leveringssted" tagName="h4" />
        {workplace && <LocationInfo location={workplace} />}
      </div>
    );
  } else if (workplace) {
    workplaceBlock = (
      <div>
        <FormattedMessage defaultMessage="Arbejdssted" tagName="h4" />
        {workplace && <LocationInfo location={workplace} />}
      </div>
    );
  }

  let projectManagerBlock;
  if (customerSettings.customerProjectManager && customer) {
    const customerProjectManager = customer.projectManagerName;
    if (customerProjectManager) {
      projectManagerBlock = (
        <div>
          <FormattedMessage defaultMessage="Formand" tagName="h4" />
          {customerProjectManager}
        </div>
      );
    }
  }

  let changeCustomerButton;
  const disabled =
    (userIsOtherMachineOperator && !userIsSeniorMachineOperator) ||
    (userIsOnlyMachineOperator && completed) ||
    validated;
  if (
    customerSettings.enableCustomerSwitch &&
    !userIsJobber &&
    !(userIsOnlyMachineOperator && !customerSettings.machineOperatorCanCreateCustomerTask)
  ) {
    changeCustomerButton = (
      <Button
        color="secondary"
        disabled={disabled}
        variant="contained"
        onClick={onChangeCustomerButton}
      >
        {formatMessage(messages.changeCustomer)}
      </Button>
    );
  }

  let changeProjectButton;
  if (
    customerSettings.enableProjects ||
    (props.department &&
      customerSettings.onlyEnableProjectsForDepartments.includes(props.department) &&
      customerSettings.machineOperatorsCanChooseProject &&
      !userIsJobber)
  ) {
    changeProjectButton = (
      <ChangeProjectButton
        customerURL={customer?.url ?? null}
        disabled={disabled}
        orderURL={props.orderURL}
        taskURL={props.taskURL}
      />
    );
  }

  const address = props.address || (customer && formatAddress(customer));
  const information = (
    <>
      {address && <AddressLine address={address} />}
      <br />
      {contactNameBlock}
      {contactInfo.email && <EmailLine email={contactInfo.email} />}
      {customerSettings.showLandlinePhoneNumberOnTaskInfo && contactInfo.phone && (
        <PhoneLine phone={contactInfo.phone} type="landline" />
      )}
      {contactInfo.cellphone && <PhoneLine phone={contactInfo.cellphone} type="cellphone" />}
      {projectManagerBlock}
    </>
  );

  const editCustomerButton = customer && customerSettings.customers.canManage && (
    <EditCustomerButton buttonStyle={ButtonStyle.FAB} customer={customer} variant="component" />
  );

  if (customerSettings.taskPickupDelivery) {
    return (
      <Card style={{margin: "1em"}}>
        {editCustomerButton}
        <CardContent>
          {workplaceBlock}
          <FormattedMessage defaultMessage="Kunde" id="task-instance.label.customer" tagName="h4" />
          {customerName}
          {information}
        </CardContent>
        {changeCustomerButton && <CardActions>{changeCustomerButton}</CardActions>}
      </Card>
    );
  } else {
    return (
      <Card style={{margin: "1em"}}>
        {editCustomerButton}
        <CardContent>
          <FormattedMessage defaultMessage="Kunde" id="task-instance.label.customer" />
          <h1>{customerName}</h1>
          {information}
          <br />
          {workplaceBlock}
        </CardContent>
        {(changeCustomerButton || changeProjectButton) && (
          <CardActions>
            {changeCustomerButton} {changeProjectButton}
          </CardActions>
        )}
      </Card>
    );
  }
});
