import {settingsModuleIdentifiers, settingsModules} from "@co-common-libs/config";
import {List, Paper} from "@material-ui/core";
import React from "react";
import {ModuleData} from "../types";
import {settingsModulesOverrides} from "../ui-overrides";
import {SettingsMenuModuleEntry} from "./settings-menu-module-entry";

interface SettingsMenuProps {
  settingsStructure: readonly ModuleData[];
}

const emptyArray = [] as const;

export const SettingsMenu = (props: SettingsMenuProps): JSX.Element => {
  const {settingsStructure} = props;
  return (
    <Paper
      square
      style={{
        height: "calc(100% - 64px)",
        overflow: "auto",
        position: "fixed",
        width: 240,
      }}
    >
      <List>
        {settingsStructure.map(({moduleGroups, moduleID, moduleTitle}) => (
          <SettingsMenuModuleEntry
            key={moduleID}
            moduleGroups={moduleGroups}
            moduleID={moduleID}
            moduleTitle={moduleTitle}
          />
        ))}
        {settingsModuleIdentifiers
          .filter((moduleID) => !!settingsModulesOverrides[moduleID])
          .map((moduleID) => {
            const data = settingsModules[moduleID];
            return (
              <SettingsMenuModuleEntry
                key={moduleID}
                resetSearch
                moduleGroups={emptyArray}
                moduleID={moduleID}
                moduleTitle={data.title}
              />
            );
          })}
      </List>
    </Paper>
  );
};
