import {useTheme} from "@material-ui/core";
import React from "react";

export const ErrorMessage = React.memo(function ErrorMessage({
  error,
}: {
  error: string;
}): JSX.Element {
  const theme = useTheme();
  return <h3 style={{color: theme.palette.error.main}}>{error}</h3>;
});
