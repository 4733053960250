import {getOfflineDB} from "@co-frontend-libs/db-resources";
import {AnyAction, Dispatch, Middleware} from "redux";
import * as authenticationActions from "../authentication/actions";
import * as connectivityActions from "../connectivity/actions";
import * as resourcesActions from "../resources/actions";
import {startChangesFetch, startChangesFetchTimeout} from "./changes-fetch";
import {checkNotFetching, startMissingFullFetch} from "./full-fetch";
import {startMissingIdFetch} from "./id-fetch";
import {startStopPurgeTimeouts} from "./purge-discarded-queries";
import {registerTaskPosition, registerTimerStartPosition} from "./register-position";
import {startMissingRelatedFetch} from "./related-fetch";
import {rewriteSaveCommand} from "./rewrite-save-command";
import {saveExtraCommands} from "./save-extra-commands";
import {startLocalSave} from "./save-locally";
import {startOnlineSave, storeOnlineSaveError} from "./save-online";
import {
  ResourcesAuthenticationAppDispatch,
  ResourcesAuthenticationAppState,
  ResourcesAuthenticationMiddlewareAPI,
} from "./types";
import {updateOfflineDB} from "./update-offline-db";
import {fullFetchesPending} from "./utils";

const HTTP_403_FORBIDDEN = 403;

function sessionReadySideEffect(
  baseURL: string,
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  next: Dispatch,
  action: AnyAction,
): AnyAction {
  const result = next(action);
  startChangesFetch(middlewareApi, baseURL);
  return result;
}

function loadOfflineFulfilledSideEffect(
  baseURL: string,
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  next: Dispatch,
  action: ReturnType<typeof resourcesActions.loadOffline.fulfilled>,
): ReturnType<typeof resourcesActions.loadOffline.fulfilled> {
  const result = next(action);
  startChangesFetch(middlewareApi, baseURL);
  return result;
}

function saveSideEffect(
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  next: Dispatch,
  action: ReturnType<typeof resourcesActions.save>,
): ReturnType<typeof resourcesActions.save> {
  const modifiedCommand = rewriteSaveCommand(middlewareApi, action.payload);
  const modifiedAction = resourcesActions.save(modifiedCommand);
  const extraCommands = saveExtraCommands(middlewareApi, modifiedAction.payload);
  const extraCommandsBefore = extraCommands && extraCommands.before;
  const extraCommandsAfter = extraCommands && extraCommands.after;
  if (extraCommandsBefore) {
    extraCommandsBefore.forEach((command) => {
      middlewareApi.dispatch(resourcesActions.save(command));
    });
  }
  const result = next(modifiedAction);
  startLocalSave(middlewareApi, modifiedCommand.action === "UPDATE");
  if (extraCommandsAfter) {
    extraCommandsAfter.forEach((command) => {
      middlewareApi.dispatch(resourcesActions.save(command));
    });
  }
  return result;
}

function createPromiseFulfilledSideEffect(
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  next: Dispatch,
  action: ReturnType<typeof resourcesActions.createPromise.fulfilled>,
): ReturnType<typeof resourcesActions.createPromise.fulfilled> {
  const result = next(action);
  startLocalSave(middlewareApi, false);
  return result;
}

function createPromiseRejectedSideEffect(
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  next: Dispatch,
  action: ReturnType<typeof resourcesActions.createPromise.rejected>,
): ReturnType<typeof resourcesActions.createPromise.rejected> {
  const result = next(action);
  startLocalSave(middlewareApi, false);
  return result;
}

function readyForOnlineSaveSideEffect(
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  next: Dispatch,
  action: AnyAction,
): AnyAction {
  const result = next(action);
  // we might have UNSAVED from before loadCommit.fulfilled,
  // or more entries added to commit queue while saving the entry now "ready"
  startLocalSave(middlewareApi, false);
  startOnlineSave(middlewareApi);
  if (resourcesActions.saveOnline.fulfilled.match(action)) {
    middlewareApi.dispatch(connectivityActions.online(new Date()));
  }
  return result;
}

function saveOnlineRejectedSideEffect(
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  next: Dispatch,
  action: ReturnType<typeof resourcesActions.saveOnline.rejected>,
): ReturnType<typeof resourcesActions.saveOnline.rejected> {
  const result = next(action);
  const error = action.payload?.error;
  if (error?.type === "NetworkError") {
    middlewareApi.dispatch(connectivityActions.offline(new Date()));
    return result;
  } else if (error?.type === "StatusError") {
    middlewareApi.dispatch(connectivityActions.online(new Date()));
    if (error.statusCode === HTTP_403_FORBIDDEN) {
      middlewareApi.dispatch(authenticationActions.logout(false));
      return result;
    }
  }
  // not NetworkError, not StatusError with code 403
  storeOnlineSaveError(action);
  startOnlineSave(middlewareApi);
  return result;
}

function queriesAddChangeSideEffect(
  baseURL: string,
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  next: Dispatch,
  action: AnyAction,
): AnyAction {
  const oldState = middlewareApi.getState();
  const result = next(action);
  updateOfflineDB(oldState, middlewareApi);
  startMissingFullFetch(middlewareApi, baseURL);
  if (
    resourcesActions.temporaryQueriesRequestedForKey.match(action) ||
    resourcesActions.temporaryQueriesRequestedForPath.match(action)
  ) {
    startStopPurgeTimeouts(middlewareApi);
    startMissingRelatedFetch(middlewareApi, baseURL);
    startMissingIdFetch(middlewareApi, baseURL);
  }
  if (
    fullFetchesPending(oldState.resources) &&
    !fullFetchesPending(middlewareApi.getState().resources)
  ) {
    startChangesFetch(middlewareApi, baseURL);
  }
  return result;
}

function queriesRemoveSideEffect(
  baseURL: string,
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  next: Dispatch,
  action: AnyAction,
): AnyAction {
  const oldState = middlewareApi.getState();
  const result = next(action);
  startStopPurgeTimeouts(middlewareApi);
  startMissingRelatedFetch(middlewareApi, baseURL);
  startMissingIdFetch(middlewareApi, baseURL);
  if (
    fullFetchesPending(oldState.resources) &&
    !fullFetchesPending(middlewareApi.getState().resources)
  ) {
    startChangesFetch(middlewareApi, baseURL);
  }
  return result;
}

function performFullFetchFulfilledSideEffect(
  baseURL: string,
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  next: Dispatch,
  action: ReturnType<typeof resourcesActions.performFullFetch.fulfilled>,
): ReturnType<typeof resourcesActions.performFullFetch.fulfilled> {
  const oldState = middlewareApi.getState();
  const result = next(action);
  updateOfflineDB(oldState, middlewareApi);
  middlewareApi.dispatch(connectivityActions.online(new Date()));
  startMissingRelatedFetch(middlewareApi, baseURL);
  startMissingIdFetch(middlewareApi, baseURL);
  if (
    fullFetchesPending(oldState.resources) &&
    !fullFetchesPending(middlewareApi.getState().resources)
  ) {
    startChangesFetch(middlewareApi, baseURL);
  }
  return result;
}

function performFullFetchRejectedSideEffect(
  baseURL: string,
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  next: Dispatch,
  action: ReturnType<typeof resourcesActions.performFullFetch.rejected>,
): ReturnType<typeof resourcesActions.performFullFetch.rejected> {
  const result = next(action);
  const error = action.payload?.error;
  if (error?.type === "NetworkError") {
    middlewareApi.dispatch(connectivityActions.offline(new Date()));
  } else if (error?.type === "StatusError") {
    middlewareApi.dispatch(connectivityActions.online(new Date()));
    if (error.statusCode === HTTP_403_FORBIDDEN) {
      middlewareApi.dispatch(authenticationActions.logout(false));
      return result;
    }
  }
  startChangesFetchTimeout(middlewareApi, baseURL);
  return result;
}

function performPartialFetchFulfilledSideEffect<T extends AnyAction>(
  baseURL: string,
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  next: Dispatch,
  action: T,
): T {
  const oldState = middlewareApi.getState();
  const result = next(action);
  updateOfflineDB(oldState, middlewareApi);
  middlewareApi.dispatch(connectivityActions.online(new Date()));
  startMissingRelatedFetch(middlewareApi, baseURL);
  startMissingIdFetch(middlewareApi, baseURL);
  startChangesFetchTimeout(middlewareApi, baseURL);
  if (resourcesActions.performChangesFetch.fulfilled.match(action)) {
    const {lastFetchChangesTimestamp} = middlewareApi.getState().resources;
    if (lastFetchChangesTimestamp) {
      getOfflineDB()
        .then((offlineDB) => offlineDB.setChangesTimestamp(lastFetchChangesTimestamp))
        .catch((error) => {
          middlewareApi.dispatch(resourcesActions.offlineDBError(error));
        });
    }
  }
  return result;
}

function performPartialFetchRejectedSideEffect<T extends AnyAction>(
  baseURL: string,
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  next: Dispatch,
  action: T,
): T {
  const result = next(action);
  const error = action.payload?.error;
  if (error?.type === "NetworkError") {
    middlewareApi.dispatch(connectivityActions.offline(new Date()));
  } else if (error?.type === "StatusError") {
    middlewareApi.dispatch(connectivityActions.online(new Date()));
    if (error.statusCode === HTTP_403_FORBIDDEN) {
      middlewareApi.dispatch(authenticationActions.logout(false));
      return result;
    }
  }
  startChangesFetchTimeout(middlewareApi, baseURL);
  return result;
}

function logoutPendingSideEffect(
  _middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  next: Dispatch,
  action: AnyAction,
): AnyAction {
  // TODO
  const result = next(action);
  return result;
}

function requestChangesFetchSideEffect(
  baseURL: string,
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  next: Dispatch,
  action: ReturnType<typeof resourcesActions.requestChangesFetch>,
): ReturnType<typeof resourcesActions.requestChangesFetch> {
  const result = next(action);
  startChangesFetch(middlewareApi, baseURL);
  return result;
}

export const createMiddleware: (
  baseURL: string,
  // Middleware<ThunkDispatch<AppState, void, AnyAction>, AppState>
) => Middleware<undefined, ResourcesAuthenticationAppState, ResourcesAuthenticationAppDispatch> =
  (baseURL: string) =>
  (middlewareApi: ResourcesAuthenticationMiddlewareAPI) =>
  (next: Dispatch) =>
  (action: AnyAction) => {
    if (
      authenticationActions.login.fulfilled.match(action) ||
      authenticationActions.loadSession.fulfilled.match(action)
    ) {
      return sessionReadySideEffect(baseURL, middlewareApi, next, action);
    } else if (resourcesActions.loadOffline.fulfilled.match(action)) {
      return loadOfflineFulfilledSideEffect(baseURL, middlewareApi, next, action);
    } else if (resourcesActions.save.match(action)) {
      return saveSideEffect(middlewareApi, next, action);
    } else if (resourcesActions.createPromise.fulfilled.match(action)) {
      return createPromiseFulfilledSideEffect(middlewareApi, next, action);
    } else if (resourcesActions.createPromise.rejected.match(action)) {
      return createPromiseRejectedSideEffect(middlewareApi, next, action);
    } else if (
      resourcesActions.saveLocally.fulfilled.match(action) ||
      resourcesActions.loadCommit.fulfilled.match(action) ||
      resourcesActions.saveOnline.fulfilled.match(action) ||
      resourcesActions.startOnlineSaves.match(action)
    ) {
      return readyForOnlineSaveSideEffect(middlewareApi, next, action);
    } else if (resourcesActions.saveOnline.rejected.match(action)) {
      return saveOnlineRejectedSideEffect(middlewareApi, next, action);
    } else if (
      resourcesActions.persistedQueriesRequested.match(action) ||
      resourcesActions.temporaryQueriesRequestedForKey.match(action) ||
      resourcesActions.temporaryQueriesRequestedForPath.match(action)
    ) {
      return queriesAddChangeSideEffect(baseURL, middlewareApi, next, action);
    } else if (
      resourcesActions.temporaryQueriesDiscardedForKey.match(action) ||
      resourcesActions.temporaryQueriesDiscardedForPath.match(action)
    ) {
      return queriesRemoveSideEffect(baseURL, middlewareApi, next, action);
    } else if (resourcesActions.performFullFetch.fulfilled.match(action)) {
      return performFullFetchFulfilledSideEffect(baseURL, middlewareApi, next, action);
    } else if (resourcesActions.performFullFetch.rejected.match(action)) {
      return performFullFetchRejectedSideEffect(baseURL, middlewareApi, next, action);
    } else if (
      resourcesActions.performChangesFetch.fulfilled.match(action) ||
      resourcesActions.performIdFetch.fulfilled.match(action) ||
      resourcesActions.performRelatedFetch.fulfilled.match(action)
    ) {
      return performPartialFetchFulfilledSideEffect(baseURL, middlewareApi, next, action);
    } else if (resourcesActions.performChangesFetch.rejected.match(action)) {
      return performPartialFetchRejectedSideEffect(baseURL, middlewareApi, next, action);
    } else if (authenticationActions.logout.pending.match(action)) {
      return logoutPendingSideEffect(middlewareApi, next, action);
    } else if (resourcesActions.requestChangesFetch.match(action)) {
      return requestChangesFetchSideEffect(baseURL, middlewareApi, next, action);
    } else if (resourcesActions.registerTimerStartPosition.match(action)) {
      return registerTimerStartPosition(baseURL, middlewareApi, next, action);
    } else if (resourcesActions.registerTaskPosition.match(action)) {
      return registerTaskPosition(baseURL, middlewareApi, next, action);
    } else {
      if (process.env.NODE_ENV !== "production") {
        if (resourcesActions.performFullFetch.pending.match(action)) {
          checkNotFetching(middlewareApi, action.meta.arg.query);
        }
      }
      return next(action);
    }
  };
