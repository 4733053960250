import {ResourceName, resourceNameFor, resourceNames} from "@co-common-libs/resources";
import {GenericInstanceRecord, ResourceInstanceRecords} from "../../types";
import {
  FkRules,
  FkRulesForTarget,
  ReverseFkRules,
  ReverseFkRulesForSource,
  partialEntriesForEach,
  partialValuesForEach,
} from "../../utils";

// checked resource criteria may depend on having an FK to an instance
// fulfilling some criteria; needs to be rechecked if target of FK has
// been changed and might not fulfill criteria any more
function updateResourceInstancesToCheckFromUpdatedAndFkRules(
  oldData: ResourceInstanceRecords,
  resourceName: ResourceName,
  resourceUpdatedInstances: GenericInstanceRecord,
  resourceFkRules: FkRulesForTarget,
  instancesToCheck: {
    [N in ResourceName]: Set<string>;
  },
): void {
  resourceFkRules.forEach(({checkedResourceName, memberName}) => {
    const otherResourceInstances = oldData[checkedResourceName] as GenericInstanceRecord;
    const resourceInstancesToCheck = instancesToCheck[resourceName];
    partialValuesForEach(otherResourceInstances, (otherResourceInstance) => {
      const fkValue = (otherResourceInstance as any)[memberName] as string | undefined;
      if (typeof fkValue === "string" && resourceUpdatedInstances[fkValue]) {
        resourceInstancesToCheck.add(otherResourceInstance.url);
      }
    });
  });
}

// checked resource criteria may depend on being target of FK from an
// instance fulfilling some criteria; needs to be rechecked if the source
// of such an FK has been changed and might not fulfill criteria any more
// (or might not have the same FK any more)
function updateResourceInstancesToCheckFromUpdatedAndReverseFkRules(
  oldData: ResourceInstanceRecords,
  resourceName: ResourceName,
  resourceUpdatedInstances: GenericInstanceRecord,
  resourceReverseFkRules: ReverseFkRulesForSource,
  instancesToCheck: {
    [N in ResourceName]: Set<string>;
  },
): void {
  const resourceInstances = oldData[resourceName] as GenericInstanceRecord;
  resourceReverseFkRules.forEach(({checkedResourceName, memberName}) => {
    partialEntriesForEach(resourceUpdatedInstances, (url, _newInstance) => {
      const oldInstance = resourceInstances[url];
      const oldMemberValue =
        oldInstance && ((oldInstance as any)[memberName] as string | undefined);
      if (typeof oldMemberValue === "string") {
        const fkTargetResourceName = resourceNameFor(oldMemberValue);
        // in a sensible configuration, types match
        console.assert(checkedResourceName === fkTargetResourceName);
        const resourceInstancesToCheck = instancesToCheck[fkTargetResourceName];
        resourceInstancesToCheck.add(oldMemberValue);
      }
    });
  });
}

// resource instances may have checks that depend on relations to instances
// that have now changed -- but as instances may have multiple checks with
// multiple criteria; performing checks "inline" would add a lot of complexity
// for a questionable performance gain
export function computeInstancesToCheckFromUpdated(
  oldData: ResourceInstanceRecords,
  updatedInstances: Partial<ResourceInstanceRecords>,
  persistedFkRules: FkRules,
  persistedReverseFkRules: ReverseFkRules,
  temporaryFkRules: FkRules,
  temporaryReverseFkRules: ReverseFkRules,
): {readonly [N in ResourceName]: ReadonlySet<string>} {
  const instancesToCheck = Object.fromEntries(
    resourceNames.map((resourceName) => [resourceName, new Set<string>()]),
  ) as {
    [N in ResourceName]: Set<string>;
  };
  partialEntriesForEach(updatedInstances, (resourceName, resourceUpdatedInstances) => {
    const resourcePersistedFkRules = persistedFkRules[resourceName];
    const resourcePersistedReverseFkRules = persistedReverseFkRules[resourceName];
    const resourceTemporaryFkRules = temporaryFkRules[resourceName];
    const resourceTemporaryReverseFkRules = temporaryReverseFkRules[resourceName];
    if (resourcePersistedFkRules) {
      updateResourceInstancesToCheckFromUpdatedAndFkRules(
        oldData,
        resourceName,
        resourceUpdatedInstances,
        resourcePersistedFkRules,
        instancesToCheck,
      );
    }
    if (resourceTemporaryFkRules) {
      updateResourceInstancesToCheckFromUpdatedAndFkRules(
        oldData,
        resourceName,
        resourceUpdatedInstances,
        resourceTemporaryFkRules,
        instancesToCheck,
      );
    }
    if (resourcePersistedReverseFkRules) {
      updateResourceInstancesToCheckFromUpdatedAndReverseFkRules(
        oldData,
        resourceName,
        resourceUpdatedInstances,
        resourcePersistedReverseFkRules,
        instancesToCheck,
      );
    }
    if (resourceTemporaryReverseFkRules) {
      updateResourceInstancesToCheckFromUpdatedAndReverseFkRules(
        oldData,
        resourceName,
        resourceUpdatedInstances,
        resourceTemporaryReverseFkRules,
        instancesToCheck,
      );
    }
  });
  return instancesToCheck;
}
