import {emptyCustomer} from "@co-common-libs/resources";
import _ from "lodash";
import type {Exact} from "ts-essentials";
import {CustomerFormPart, customerFormMembers} from "./types";

export function transformToCustomerFormPart(
  customer?: Partial<CustomerFormPart> | null,
): CustomerFormPart {
  const customerFormPart = _.pick({...emptyCustomer, ...customer}, customerFormMembers);

  const checked: Exact<
    typeof customerFormPart,
    Omit<CustomerFormPart, "_exact">
  > = customerFormPart;

  return checked as CustomerFormPart;
}
