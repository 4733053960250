import {EmployeeGroup, EmployeeGroupUrl, emptyEmployeeGroup} from "@co-common-libs/resources";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {actions} from "@co-frontend-libs/redux";
import {DialogContent} from "@material-ui/core";
import {simpleObjectReducer} from "app-utils";
import {instanceURL} from "frontend-global-config";
import React, {useCallback, useMemo, useReducer} from "react";
import {useIntl} from "react-intl";
import {useDispatch} from "react-redux";
import {v4 as uuid} from "uuid";
import {getCreateFormValidator} from "./create-form-validator";
import {EmployeeGroupForm} from "./employee-group-form";

export const EmployeeGroupCreateDialog = React.memo(function EmployeeGroupCreateDialog(props: {
  employeeGroupTemplate: typeof emptyEmployeeGroup;
  onCancel: () => void;
  onOk: (employeeGroupUrl: EmployeeGroupUrl) => void;
  open: boolean;
}): JSX.Element {
  const {employeeGroupTemplate, onCancel, onOk, open} = props;
  const [employeeGroup, reducerDispatch] = useReducer(
    simpleObjectReducer<typeof emptyEmployeeGroup>,
    employeeGroupTemplate,
  );

  const dispatch = useDispatch();
  const handleSaveClick = useCallback(() => {
    const id = uuid();
    const url = instanceURL("employeeGroup", id);
    const newEmployeeGroup: EmployeeGroup = {
      ...employeeGroup,
      id,
      url,
    };
    dispatch(actions.create(newEmployeeGroup));
    onOk(url);
  }, [employeeGroup, onOk, dispatch]);

  const intl = useIntl();

  const validator = useMemo(() => getCreateFormValidator(intl), [intl]);
  const validationResult = validator.safeParse(employeeGroup);
  const errors = validationResult.success ? null : validationResult.error;

  return (
    <ResponsiveDialog
      cancelLabel={intl.formatMessage({defaultMessage: "Annuller"})}
      okDisabled={!validationResult.success}
      okLabel={intl.formatMessage({defaultMessage: "Opret"})}
      open={open}
      title={intl.formatMessage({defaultMessage: "Opret medarbejdergruppe"})}
      onCancel={onCancel}
      onOk={handleSaveClick}
    >
      <DialogContent>
        <EmployeeGroupForm
          dispatch={reducerDispatch}
          employeeGroup={employeeGroup}
          validationError={errors}
        />
      </DialogContent>
    </ResponsiveDialog>
  );
});
