import {createSelectorCreator, defaultMemoize} from "reselect";

export function isActive<T extends {active: boolean}>(instance: T): boolean {
  return instance.active;
}

export function filterOnActive<T extends {active: boolean}>(array: readonly T[]): readonly T[] {
  return array.filter(isActive);
}

function arraysAreEqual(a: any, b: any): boolean {
  if (a === b) {
    return true;
  } else if (Array.isArray(a) && Array.isArray(b) && a.length === b.length) {
    for (let i = 0; i < a.length; i += 1) {
      if (a[i] !== b[i]) {
        return false;
      }
    }
    return true;
  } else {
    return false;
  }
}

export const createArraysEqualSelector = createSelectorCreator(defaultMemoize, arraysAreEqual);
