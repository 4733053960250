import {
  FieldUse,
  Location,
  LocationUrl,
  Order,
  ReportingLocation,
  Task,
  urlToId,
} from "@co-common-libs/resources";
import {
  getMachineString,
  getMachinesWorkTypeString,
  getWorkTypeString,
} from "@co-common-libs/resources-utils";
import {dateToString, notUndefined, sortByOrderMember} from "@co-common-libs/utils";
import {ColorBox} from "@co-frontend-libs/components";
import {
  actions,
  getContactLookup,
  getCultureLookup,
  getCurrentUserURL,
  getCustomerLookup,
  getCustomerSettings,
  getDeliveryLocationArray,
  getLocationLookup,
  getMachineLookup,
  getOrderLookup,
  getPickupLocationArray,
  getPriceGroupLookup,
  getProductLookup,
  getProjectLookup,
  getReportingSpecificationLookup,
  getRoutePlanLookup,
  getSprayLocationArray,
  getSprayLogArray,
  getTimerLookup,
  getTransportLogArray,
  getWorkTypeLookup,
  getYieldDeliveryLocationArray,
  getYieldLogArray,
  getYieldPickupLocationArray,
} from "@co-frontend-libs/redux";
import {Dialog, Grid, IconButton, Popover, makeStyles} from "@material-ui/core";
import {copyTaskWithLogsLocations, getFieldString, getWorkplaceString} from "app-utils";
import bowser from "bowser";
import {instanceURL} from "frontend-global-config";
import ClipboardTextIcon from "mdi-react/ClipboardTextIcon";
import CloseIcon from "mdi-react/CloseIcon";
import ContentCopyIcon from "mdi-react/ContentCopyIcon";
import CornIcon from "mdi-react/CornIcon";
import DomainIcon from "mdi-react/DomainIcon";
import MapMarkerIcon from "mdi-react/MapMarkerIcon";
import PencilIcon from "mdi-react/PencilIcon";
import RoutesIcon from "mdi-react/RoutesIcon";
import TagTextOutlineIcon from "mdi-react/TagTextOutlineIcon";
import TextIcon from "mdi-react/TextIcon";
import TimerIcon from "mdi-react/TimerIcon";
import TireIcon from "mdi-react/TireIcon";
import React, {useCallback} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import type {Writable} from "ts-essentials";
import {v4 as uuid} from "uuid";
import {GoToPathButton} from "../go-to-path-button";
import {Linkify} from "../linkify";

const IS_MOBILE = bowser.mobile;

const stopPropagation = (event: React.MouseEvent | React.TouchEvent): void => {
  event.stopPropagation();
};

function getShortLocationStrings(
  locationLookup: (url: LocationUrl) => Location | undefined,
  reportingLocations: readonly ReportingLocation[] | undefined,
): string[] {
  if (reportingLocations?.length) {
    const worklaceStrings = reportingLocations.map(({location}) =>
      getWorkplaceString(location, locationLookup),
    );
    if (worklaceStrings.length) {
      return worklaceStrings.filter(Boolean);
    }
  }
  return [];
}

function getFieldStrings(
  locationLookup: (url: LocationUrl) => Location | undefined,
  fieldUseSet: readonly FieldUse[],
): string[] {
  return fieldUseSet
    .map((entry) => {
      return getFieldString(entry.relatedField, locationLookup);
    })
    .filter(Boolean);
}

const useStyles = makeStyles(() => ({
  disablePointerEventsOnDesktop: {
    pointerEvents: bowser.mobile ? "all" : "none",
  },
  hideOverflowOnDesktop: {
    overflowY: bowser.mobile ? "auto" : "hidden",
  },
}));

export const TaskInfoPopOver = React.memo(function TaskInfoPopOver({
  onClose,
  position,
  task,
}: {
  onClose: () => void;
  position: {x: number; y: number} | null;
  task: Task | null;
}): JSX.Element | null {
  const classes = useStyles();
  const workTypeLookup = useSelector(getWorkTypeLookup);
  const orderLookup = useSelector(getOrderLookup);
  const customerLookup = useSelector(getCustomerLookup);
  const machineLookup = useSelector(getMachineLookup);
  const cultureLookup = useSelector(getCultureLookup);
  const locationLookup = useSelector(getLocationLookup);
  const timerLookup = useSelector(getTimerLookup);
  const routePlanLookup = useSelector(getRoutePlanLookup);
  //const theme = useTheme();
  const intl = useIntl();
  const customerSettings = useSelector(getCustomerSettings);
  const currentUserUrl = useSelector(getCurrentUserURL);

  const deliveryLocationArray = useSelector(getDeliveryLocationArray);
  const pickupLocationArray = useSelector(getPickupLocationArray);
  const priceGroupLookup = useSelector(getPriceGroupLookup);
  const productLookup = useSelector(getProductLookup);
  const projectLookup = useSelector(getProjectLookup);
  const reportingSpecificationLookup = useSelector(getReportingSpecificationLookup);
  const sprayLocationArray = useSelector(getSprayLocationArray);
  const sprayLogArray = useSelector(getSprayLogArray);
  const transportLogArray = useSelector(getTransportLogArray);
  const yieldDeliveryLocationArray = useSelector(getYieldDeliveryLocationArray);
  const yieldLogArray = useSelector(getYieldLogArray);
  const yieldPickupLocationArray = useSelector(getYieldPickupLocationArray);
  const contactLookup = useSelector(getContactLookup);
  const dispatch = useDispatch();

  const order = task?.order ? orderLookup(task.order) : null;

  const handleTaskCopy = useCallback(() => {
    if (!task || !currentUserUrl) {
      return;
    }

    const today = dateToString(new Date());
    let overrideOrderURL = order?.url || null;
    let orderCopy: Writable<Order> | undefined;
    if (!customerSettings.taskCopyFields.includes("order") && order) {
      const customerURL = order.customer;
      const orderCopyID = uuid();
      overrideOrderURL = instanceURL("order", orderCopyID);
      orderCopy = {
        ...order,
        billed: false,
        createdBy: currentUserUrl,
        customer: customerURL,
        date: today,
        id: orderCopyID,
        remoteUrl: "",
        url: overrideOrderURL,
      };
      const contact = orderCopy.contact ? contactLookup(orderCopy.contact) : null;
      if (contact && !contact.active) {
        orderCopy.contact = null;
      }
      delete orderCopy.created;
    }
    const overrides: Partial<Writable<Task>> = {
      createdBy: currentUserUrl,
      machineOperator: null,
      order: overrideOrderURL,
    };

    if (task.date == null || task.date < today) {
      overrides["date"] = today;
    }

    const copyResult = copyTaskWithLogsLocations(
      task,
      customerSettings.taskCopyFields,
      overrides,
      {
        deliveryLocationArray,
        locationLookup,
        machineLookup,
        pickupLocationArray,
        priceGroupLookup,
        productLookup,
        projectLookup,
        reportingSpecificationLookup,
        sprayLocationArray,
        sprayLogArray,
        transportLogArray,
        workTypeLookup,
        yieldDeliveryLocationArray,
        yieldLogArray,
        yieldPickupLocationArray,
      },
      dispatch,
      intl,
      customerSettings,
    );
    if (orderCopy) {
      dispatch(actions.create(orderCopy));
    }
    copyResult.forEach((instance) => {
      dispatch(actions.createOrUpdate(instance));
    });
    const taskCopyID = urlToId(copyResult[0].url);
    window.setTimeout(() => {
      if (copyResult[0].order) {
        dispatch(
          actions.go("/order/:id/:taskID", {
            id: urlToId(copyResult[0].order),
            taskID: taskCopyID,
          }),
        );
      } else {
        dispatch(actions.go("/internalTask/:id", {id: taskCopyID}));
      }
    }, 0);
  }, [
    contactLookup,
    currentUserUrl,
    customerSettings,
    deliveryLocationArray,
    dispatch,
    intl,
    locationLookup,
    machineLookup,
    order,
    pickupLocationArray,
    priceGroupLookup,
    productLookup,
    projectLookup,
    reportingSpecificationLookup,
    sprayLocationArray,
    sprayLogArray,
    task,
    transportLogArray,
    workTypeLookup,
    yieldDeliveryLocationArray,
    yieldLogArray,
    yieldPickupLocationArray,
  ]);

  if (!task || !position) {
    return null;
  }

  const sortedReportingLocations = task.reportingLocations
    ? sortByOrderMember(Object.values(task.reportingLocations))
    : undefined;
  const workplaceReportingLocations = sortedReportingLocations?.filter(
    (r) => r.type === "workplace",
  );
  const pickupReportingLocations = sortedReportingLocations?.filter((r) => r.type === "pickup");
  const deliveryReportingLocations = sortedReportingLocations?.filter((r) => r.type === "delivery");
  const workplaces = getShortLocationStrings(locationLookup, workplaceReportingLocations);
  const deliveryLocations = getShortLocationStrings(locationLookup, deliveryReportingLocations);
  const pickupLocations = getShortLocationStrings(locationLookup, pickupReportingLocations);

  const fields = getFieldStrings(locationLookup, task.fielduseSet);

  const customer = order?.customer ? customerLookup(order.customer) : null;
  const culture = order?.culture ? cultureLookup(order.culture) : null;
  const routePlan = order?.routePlan ? routePlanLookup(order.routePlan) : null;
  const workType = task.workType ? workTypeLookup(task.workType) : undefined;

  const machineList = task.machineuseSet
    .map((machineUse) => machineLookup(machineUse.machine))
    .filter(notUndefined);

  let workTypeString;
  if (workType) {
    workTypeString = getWorkTypeString(workType, customerSettings.calendarShowWorkTypeNumber);
  } else if (customerSettings.enableExternalTaskDepartmentField && task.department === "E") {
    workTypeString = intl.formatMessage({
      defaultMessage: "Entreprenørarbejde",
    });
  } else {
    workTypeString = getMachinesWorkTypeString(
      machineList,
      customerSettings.calendarShowWorkTypeNumber,
    );
  }

  const referenceBlock = (
    <>
      {customerSettings.enableOrderReferenceNumber ? (
        <div>
          {customerSettings.orderReferenceNumberLabel}: {order?.referenceNumber}
        </div>
      ) : null}
      {customerSettings.enableTaskReferenceNumber ? (
        <div>
          {customerSettings.taskReferenceNumberLabel}: {task.referenceNumber}
        </div>
      ) : null}
    </>
  );

  const noteBlockStyle: React.CSSProperties = {whiteSpace: "pre-line"};
  const {notesFromManager} = task;
  const orderNotes = order && order.notes;

  let notesFromManagerBlock;
  if (notesFromManager || orderNotes) {
    notesFromManagerBlock = (
      <span style={noteBlockStyle}>
        A:{" "}
        {notesFromManager ? (
          <>
            <Linkify>{notesFromManager}</Linkify>
            <br />
          </>
        ) : null}
        {orderNotes ? (
          <>
            <Linkify>{orderNotes}</Linkify>
            <br />
          </>
        ) : null}
      </span>
    );
  }

  const managerInternalNotes = [order?.managerInternalNotes, task.managerInternalNotes]
    .filter(Boolean)
    .join(". ");

  let managerInternalNotesBlock;
  if (managerInternalNotes) {
    managerInternalNotesBlock = (
      <span style={noteBlockStyle}>
        I: <Linkify>{managerInternalNotes}</Linkify>
        <br />
      </span>
    );
  }
  const {notesFromMachineOperator, timernotesSet} = task;
  let notesFromMachineOperatorBlock;
  if (notesFromMachineOperator) {
    notesFromMachineOperatorBlock = (
      <span style={noteBlockStyle}>
        M: <Linkify>{notesFromMachineOperator}</Linkify>
        <br />
      </span>
    );
  }

  let timerNotesBlock;
  if (timernotesSet && timernotesSet.length) {
    timerNotesBlock = (
      <span style={noteBlockStyle}>
        {timernotesSet.map((entry, index) => {
          const timer = timerLookup(entry.timer);
          const timerLabel = timer ? timer.label : "";
          return (
            <Linkify key={index}>
              {timerLabel}: {entry.notes}
              <br />
            </Linkify>
          );
        })}
      </span>
    );
  }

  let invoiceNoteBlock;
  if (customerSettings.showInvoiceNote) {
    const {invoiceNote} = task;
    if (invoiceNote) {
      invoiceNoteBlock = (
        <span style={noteBlockStyle}>
          F: <Linkify>{invoiceNote}</Linkify>
          <br />
        </span>
      );
    }
  }

  const content = (
    <div style={{pointerEvents: "all"}}>
      <div style={{display: "flex", justifyContent: "flex-end"}}>
        {order ? (
          <>
            {order.routePlan ? (
              <GoToPathButton
                parameters={{
                  id: urlToId(task.url),
                }}
                path="/taskEdit/:id"
              >
                <PencilIcon />
              </GoToPathButton>
            ) : (
              <GoToPathButton
                parameters={{
                  id: urlToId(order.url),
                  taskID: urlToId(task.url),
                }}
                path="/order/:id/:taskID"
              >
                <PencilIcon />
              </GoToPathButton>
            )}
            {customerSettings.orderListPageEnabled && !order.routePlan ? (
              <GoToPathButton parameters={{id: urlToId(order.url)}} path="/orderEntry/:id">
                <TagTextOutlineIcon />
              </GoToPathButton>
            ) : null}
          </>
        ) : (
          <GoToPathButton
            parameters={{
              id: urlToId(task.url),
            }}
            path="/internalTask/:id"
          >
            <PencilIcon />
          </GoToPathButton>
        )}
        <GoToPathButton
          parameters={{
            id: urlToId(task.url),
          }}
          path="/task/:id"
        >
          <TimerIcon />
        </GoToPathButton>
        <GoToPathButton
          disabled={!task.machineOperator || !task.workFromTimestamp}
          parameters={{
            date: task.workFromTimestamp ? dateToString(new Date(task.workFromTimestamp)) : "",
            machineOperatorId: task.machineOperator ? urlToId(task.machineOperator) : "",
          }}
          path="/bookkeepingDay/:date/:machineOperatorId"
        >
          <ClipboardTextIcon />
        </GoToPathButton>
        {(customerSettings.includeTaskCopy || customerSettings.enableOrders) &&
        !order?.routePlan ? (
          <IconButton
            color="primary"
            disabled={order?.validatedAndRecorded || false}
            onClick={handleTaskCopy}
          >
            <ContentCopyIcon />
          </IconButton>
        ) : null}
        <IconButton color="primary" edge="start" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <div style={{padding: "0 1em 1em 1em"}}>
        <Grid container spacing={1}>
          <Grid item xs={1}>
            <div style={{paddingLeft: 4, paddingTop: 3}}>
              <ColorBox inline color={workType?.color || null} />
            </div>
          </Grid>
          <Grid item xs={11}>
            <h3>{workTypeString}</h3>
          </Grid>
          <Grid item style={{height: "1em"}} xs={12} />
          {customerSettings.externalTaskCustomer && customer ? (
            <>
              <Grid item xs={1}>
                <DomainIcon />
              </Grid>
              <Grid item xs={11}>
                {customer.name}
                {referenceBlock}
              </Grid>
            </>
          ) : null}
          {customerSettings.externalTaskCulture && culture ? (
            <>
              <Grid item xs={1}>
                <CornIcon />
              </Grid>
              <Grid item xs={11}>
                {culture.name}
                {referenceBlock}
              </Grid>
            </>
          ) : null}
          {routePlan ? (
            <>
              <Grid item xs={1}>
                <RoutesIcon />
              </Grid>
              <Grid item xs={11}>
                {routePlan.name}
                {referenceBlock}
              </Grid>
            </>
          ) : null}
          {machineList.length ? (
            <>
              <Grid item xs={1}>
                <TireIcon />
              </Grid>
              <Grid item xs={11}>
                {machineList.map((machine) => (
                  <div key={machine.url}>{getMachineString(machine)}</div>
                ))}
              </Grid>
            </>
          ) : null}
          {task.relatedWorkplace ||
          fields.length ||
          workplaces.length ||
          pickupLocations.length ||
          deliveryLocations.length ? (
            <>
              <Grid item xs={1}>
                <MapMarkerIcon />
              </Grid>
              <Grid item xs={11}>
                {task.relatedWorkplace ? (
                  <div>{getWorkplaceString(task.relatedWorkplace, locationLookup)}</div>
                ) : null}
                {fields.map((field, index) => (
                  <div key={index}>{field}</div>
                ))}
                {workplaces.map((workplace, index) => (
                  <div key={index}>{workplace}</div>
                ))}
                {pickupLocations.map((workplace, index) => (
                  <div key={index}>
                    A{index + 1} {workplace}
                  </div>
                ))}
                {deliveryLocations.map((workplace, index) => (
                  <div key={index}>
                    L{index + 1} {workplace}
                  </div>
                ))}
              </Grid>
            </>
          ) : null}

          {notesFromManagerBlock ||
          managerInternalNotesBlock ||
          notesFromMachineOperatorBlock ||
          timerNotesBlock ||
          invoiceNoteBlock ? (
            <>
              <Grid item xs={1}>
                <TextIcon />
              </Grid>
              <Grid item xs={11}>
                {notesFromManagerBlock}
                {managerInternalNotesBlock}
                {notesFromMachineOperatorBlock}
                {timerNotesBlock}
                {invoiceNoteBlock}
              </Grid>
            </>
          ) : null}
        </Grid>
      </div>
    </div>
  );
  if (IS_MOBILE) {
    return (
      <Dialog fullScreen open={!!position}>
        {content}
      </Dialog>
    );
  }
  return (
    <Popover
      disableEnforceFocus
      anchorOrigin={{
        horizontal: "right",
        vertical: "bottom",
      }}
      anchorPosition={{left: position.x, top: position.y}}
      anchorReference="anchorPosition"
      classes={
        customerSettings.enableCalendarTaskClickWhenTaskPopupIsOpen !== false
          ? {
              paper: classes.hideOverflowOnDesktop,
              root: classes.disablePointerEventsOnDesktop,
            }
          : {}
      }
      open={!!position}
      PaperProps={{
        elevation: 10,
        onClick: stopPropagation,
        onTouchStart: stopPropagation,
        style: {
          maxWidth: 500,
          minWidth: 400,
        },
      }}
      style={{zIndex: 1200}}
      transformOrigin={{
        horizontal: "left",
        vertical: "top",
      }}
    >
      {content}
    </Popover>
  );
});
