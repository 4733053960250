import {ResourceInstance} from "@co-common-libs/resources";
import {StoreWrapper, getDBConnection} from "@co-frontend-libs/db-generic";
import {sharePromiseFactory} from "@co-frontend-libs/utils";

const dbName = "deviceConfig";
const storeName = "options";

const getDeviceConfigStoreWrapper: () => Promise<StoreWrapper> = sharePromiseFactory(() =>
  getDBConnection(dbName, [storeName]).then(
    (connection) => new StoreWrapper(connection, storeName),
  ),
);

export async function deleteDeviceConfigDatabase(): Promise<void> {
  const deviceConfigStoreWrapper = await getDeviceConfigStoreWrapper();
  // eslint-disable-next-line no-console
  console.log("closing device configuration database");
  await deviceConfigStoreWrapper._closeConnection();
  // eslint-disable-next-line no-console
  console.log("deleting device configuration database");
  await deviceConfigStoreWrapper._deleteDatabase();
}

export async function closeDeviceConfigDatabase(): Promise<void> {
  const deviceConfigStoreWrapper = await getDeviceConfigStoreWrapper();
  // eslint-disable-next-line no-console
  console.log("closing device configuration database");
  await deviceConfigStoreWrapper._closeConnection();
}

const baseURL = "config://";

interface ConfigInstance extends ResourceInstance {
  value: any;
}

export const getAll = (): Promise<{[key: string]: any}> =>
  getDeviceConfigStoreWrapper()
    .then((store) => store.fetchAll())
    .then((loaded) => {
      const prefixLength = baseURL.length;
      const result: {[key: string]: any} = {};
      for (let i = 0; i < loaded.length; i += 1) {
        const entry = loaded[i] as ConfigInstance;
        const key = entry.url.substr(prefixLength);
        result[key] = entry.value;
      }
      return result;
    });

export const put = (key: string, value: unknown): Promise<void> => {
  const entry = {
    url: baseURL + key,
    value,
  };
  return getDeviceConfigStoreWrapper().then((store) => store.replaceInstance(entry));
};

export const remove = (key: string): Promise<void> => {
  const url = baseURL + key;
  return getDeviceConfigStoreWrapper().then((store) => store.removeInstance(url));
};
