import {Product, ProductUrl, Task} from "@co-common-libs/resources";
import {notUndefined, valuesSortedByOrderMember} from "@co-common-libs/utils";
import {EconomicProductsIssue} from "../types";

export function economicProductIssues({
  productLookup,
  task,
}: {
  productLookup: (url: ProductUrl) => Product | undefined;
  task: Pick<Task, "productUses">;
}): readonly EconomicProductsIssue[] {
  const issues: EconomicProductsIssue[] = [];

  const usedProducts = valuesSortedByOrderMember(task.productUses || {})
    .map((productUse) => productLookup(productUse.product))
    .filter(notUndefined);

  const missingRemoteUrl = usedProducts.filter(({remoteUrl}) => !remoteUrl);
  if (missingRemoteUrl.length) {
    issues.push({
      category: "error",
      instances: missingRemoteUrl,
      issueType: "missingRemoteUrl",
      resourceName: "product",
    });
  }

  const isBarred = usedProducts.filter(({barred}) => barred);
  if (isBarred.length) {
    issues.push({
      category: "error",
      instances: isBarred,
      issueType: "barred",
      resourceName: "product",
    });
  } else {
    const inactive = usedProducts.filter(({active}) => !active);
    if (inactive.length) {
      issues.push({
        category: "warning",
        instances: inactive,
        issueType: "inactive",
        resourceName: "product",
      });
    }
  }

  return issues;
}
