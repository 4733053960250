import {Order, emptyOrder} from "@co-common-libs/resources";
import {instanceURL} from "frontend-global-config";
import type {Writable} from "ts-essentials";
import {v4 as uuid} from "uuid";

export function createOrder(instance: Partial<Writable<Omit<Order, "id" | "url">>>): Order {
  const id = uuid();
  const url = instanceURL("order", id);

  return {
    id,
    url,
    ...emptyOrder,
    ...instance,
  };
}
