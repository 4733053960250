import {resourcesConfig} from "./resource-config";
import {ResourceMappingObject} from "./resource-data-map";
import {ResourceName, resourceNames} from "./resource-names";

/** @deprecated */
export function resourceMappingObjectForEach<T>(
  obj: ResourceMappingObject<T>,
  fn: (value: T, resourceName: ResourceName) => void,
): void {
  for (let i = 0; i < resourceNames.length; i += 1) {
    const resourceName = resourceNames[i];
    const value = obj[resourceName];
    fn(value, resourceName);
  }
}

/** @deprecated */
function resourceMappingObjectMap<T, U>(
  obj: ResourceMappingObject<T>,
  fn: (value: T, resourceName: ResourceName) => U,
): ResourceMappingObject<U> {
  const result: Partial<ResourceMappingObject<U>> = {};
  for (let i = 0; i < resourceNames.length; i += 1) {
    const resourceName = resourceNames[i];
    const value = obj[resourceName];
    result[resourceName] = fn(value, resourceName);
  }
  return result as ResourceMappingObject<U>;
}

export const resourceNameToPath = resourceMappingObjectMap(
  resourcesConfig,
  (resourceConfig) => `${resourceConfig}/`,
);

const urlResorcePathPart = new RegExp("/api/([^/]*/)");

export const pathToResourceName: Partial<{[path: string]: ResourceName}> = {};

resourceMappingObjectForEach(resourceNameToPath, (path, resourceName) => {
  pathToResourceName[path] = resourceName;
});

export const resourceNameFor = (url: string): ResourceName => {
  if (!url) {
    throw new Error(`resourceNameFor called without URL string; got ${url}`);
  }
  const pathPartMatch = urlResorcePathPart.exec(url);
  if (!pathPartMatch) {
    throw new Error(`URL ${url} does not match /api/RESOURCE/`);
  }
  const pathPart = pathPartMatch[1];
  const result = pathToResourceName[pathPart];
  if (!result) {
    throw new Error(`URL ${url} indicates unknown resource ${pathPart}`);
  }
  return result;
};
