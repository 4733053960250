import {Config} from "@co-common-libs/config";
import {
  Machine,
  MachineGroup,
  MachineGroupUrl,
  MachineUrl,
  PatchUnion,
  PriceGroup,
  PriceGroupUrl,
  Product,
  ProductUrl,
  WorkType,
  WorkTypeUrl,
  resourceNameFor,
} from "@co-common-libs/resources";
import {identifierComparator} from "@co-common-libs/utils";
import {
  AppState,
  actions,
  getCustomerSettings,
  getMachineArray,
  getMachineGroupLookup,
  getMachineLookup,
  getPriceGroupLookup,
  getProductArray,
  getWorkTypeArray,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {EntryWithSubEntries, MultiSelectableListCard, SelectableListCard} from "app-components";
import {PureComponent, getPriceGroupsFromWorkTypeOrMachine, getSortedWorkTypes} from "app-utils";
import {bind} from "bind-decorator";
import bowser from "bowser";
import _ from "lodash";
import React from "react";
// Allowed for existing code...
// eslint-disable-next-line deprecate/import
import {Cell, Grid} from "react-flexr";
import {IntlContext, defineMessages} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";

const messages = defineMessages({
  chooseMachine: {
    defaultMessage: "Vælg først maskine.",
    id: "worktype-settings.label.choose-machine",
  },
  chooseVehicle: {
    defaultMessage: "Vælg først køretøj.",
    id: "worktype-settings.label.choose-vehicle",
  },
  chooseWorkType: {
    defaultMessage: "Vælg først arbejdsområde.",
    id: "worktype-settings.label.choose-work-type",
  },
  favoriteProducts: {
    defaultMessage: "Favoritvarer",
    id: "worktype-settings.header.favorite-products",
  },
  helpText: {
    defaultMessage:
      "De valgte varer bliver vist øverst på listen når der vælges varer på en opgave.",
    id: "worktype-settings.label.standard-products-help-text",
  },
  machines: {
    defaultMessage: "Maskiner",
    id: "worktype-settings.header.machines",
  },
  vehicles: {
    defaultMessage: "Køretøjer",
    id: "worktype-settings.header.vehicles",
  },
  worktypes: {
    defaultMessage: "Arbejdsområder",
    id: "worktype-settings.header.worktypes",
  },
});

function formatEntry(
  entry: Machine,
  machineGroupLookup: (url: MachineGroupUrl) => MachineGroup | undefined,
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined,
): EntryWithSubEntries<MachineUrl | PriceGroupUrl> {
  const machineID = entry.c5_machine;
  const machineGroup = entry.machineGroup && machineGroupLookup(entry.machineGroup);
  let machineGroupName;
  if (machineGroup) {
    machineGroupName = machineGroup.name;
  }
  const pricegroups = getPriceGroupsFromWorkTypeOrMachine(entry, priceGroupLookup);

  return {
    primaryText: entry.name,
    secondaryText: machineID + (machineGroupName ? `, ${machineGroupName}` : ""),
    subEntries: pricegroups.map((priceGroup) => ({
      primaryText: priceGroup.name,
      secondaryText: priceGroup.identifier,
      value: priceGroup.url,
    })),
    value: entry.url,
  };
}

function getInstance(
  url: MachineUrl | PriceGroupUrl | WorkTypeUrl,
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined,
  machineLookup: (url: MachineUrl) => Machine | undefined,
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined,
): Machine | PriceGroup | WorkType | undefined {
  const resourceName = resourceNameFor(url);
  if (resourceName === "workType") {
    return workTypeLookup(url as WorkTypeUrl);
  } else if (resourceName === "machine") {
    return machineLookup(url as MachineUrl);
  } else if (resourceName === "priceGroup") {
    return priceGroupLookup(url as PriceGroupUrl);
  } else {
    return undefined;
  }
}

interface StandardProductsStateProps {
  customerSettings: Config;
  machineArray: readonly Machine[];
  machineGroupLookup: (url: MachineGroupUrl) => MachineGroup | undefined;
  machineLookup: (url: MachineUrl) => Machine | undefined;
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
  productArray: readonly Product[];
  workTypeArray: readonly WorkType[];
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
}

interface StandardProductsDispatchProps {
  update: (url: string, patch: PatchUnion) => void;
}

type StandardProductsProps = StandardProductsDispatchProps & StandardProductsStateProps;

interface StandardProductsState {
  selectedURL: MachineUrl | PriceGroupUrl | WorkTypeUrl | null;
}

class StandardProducts extends PureComponent<StandardProductsProps> {
  state: StandardProductsState = {
    selectedURL: null,
  };

  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  @bind
  handleSelected(
    _event: React.SyntheticEvent<unknown>,
    selectedURL: MachineUrl | PriceGroupUrl | WorkTypeUrl | null,
  ): void {
    this.setState({
      selectedURL,
    });
  }

  @bind
  handleProductCheckedChanged(
    _event: React.SyntheticEvent<unknown>,
    newValue: ReadonlySet<ProductUrl>,
  ): void {
    const {machineLookup, priceGroupLookup, update, workTypeLookup} = this.props;
    const {selectedURL} = this.state;
    if (!selectedURL) {
      return;
    }
    const selectedInstance = getInstance(
      selectedURL,
      workTypeLookup,
      machineLookup,
      priceGroupLookup,
    );
    const oldValue = selectedInstance?.products
      ? new Set(selectedInstance.products.slice().sort())
      : new Set<string>();
    if (!_.isEqual(newValue, oldValue)) {
      const productList = Array.from(newValue);
      update(selectedURL, [{member: "products", value: productList}]);
    }
  }

  getSortedProducts(): Product[] {
    const {productArray} = this.props;
    return _.sortBy(
      productArray.filter((p) => p.active),
      (p) => p.catalogNumber.toLowerCase(),
    );
  }

  getSortedMachines(): Machine[] {
    const {machineArray} = this.props;
    return machineArray
      .filter((instance) => instance.active)
      .sort((a, b) => identifierComparator(a.c5_machine, b.c5_machine));
  }

  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {customerSettings, machineGroupLookup, machineLookup, priceGroupLookup, workTypeLookup} =
      this.props;
    const {selectedURL} = this.state;
    const selectedInstance = selectedURL
      ? getInstance(selectedURL, workTypeLookup, machineLookup, priceGroupLookup)
      : undefined;
    const productSetForSelected = selectedInstance?.products
      ? new Set(selectedInstance.products.slice().sort())
      : new Set<ProductUrl>();

    const sortedProducts = selectedURL ? this.getSortedProducts() : [];

    const productsData = sortedProducts.map((product) => {
      return {
        primaryText: product.name,
        secondaryText: product.catalogNumber,
        value: product.url,
      };
    });

    let selectionBlock;
    let productSelectionBlock;

    if (customerSettings.noExternalTaskWorkType) {
      const sortedMachines = this.getSortedMachines();
      const machinesData = sortedMachines.map((machine) => {
        return formatEntry(machine, machineGroupLookup, priceGroupLookup);
      });

      selectionBlock = (
        <SelectableListCard<MachineUrl | PriceGroupUrl>
          data={machinesData}
          title={
            customerSettings.machineLabelVariant === "MACHINE"
              ? formatMessage(messages.machines)
              : formatMessage(messages.vehicles)
          }
          value={this.state.selectedURL || undefined}
          onChange={this.handleSelected}
        />
      );

      productSelectionBlock = (
        <MultiSelectableListCard<ProductUrl>
          useRangeFilter
          data={productsData}
          emptyPrimaryText={
            this.state.selectedURL
              ? undefined
              : customerSettings.machineLabelVariant === "MACHINE"
                ? formatMessage(messages.chooseMachine)
                : formatMessage(messages.chooseVehicle)
          }
          subtitle={formatMessage(messages.helpText)}
          title={formatMessage(messages.favoriteProducts)}
          value={productSetForSelected}
          onChange={this.handleProductCheckedChanged}
        />
      );
    } else {
      const sortedworkTypes = getSortedWorkTypes(
        this.props.workTypeArray,
        customerSettings.disabledWorkTypes,
      );
      const worktypesData = sortedworkTypes.map(
        (workType): EntryWithSubEntries<PriceGroupUrl | WorkTypeUrl> => {
          const pricegroups = getPriceGroupsFromWorkTypeOrMachine(
            workType,
            this.props.priceGroupLookup,
          );

          return {
            primaryText: workType.name,
            secondaryText: workType.identifier,
            subEntries: pricegroups.map((priceGroup) => ({
              primaryText: priceGroup.name,
              secondaryText: priceGroup.identifier,
              value: priceGroup.url,
            })),
            value: workType.url,
          };
        },
      );

      selectionBlock = (
        <SelectableListCard<PriceGroupUrl | WorkTypeUrl>
          data={worktypesData}
          title={formatMessage(messages.worktypes)}
          value={this.state.selectedURL || undefined}
          onChange={this.handleSelected}
        />
      );

      productSelectionBlock = (
        <MultiSelectableListCard
          useRangeFilter
          data={productsData}
          emptyPrimaryText={
            this.state.selectedURL ? undefined : formatMessage(messages.chooseWorkType)
          }
          subtitle={formatMessage(messages.helpText)}
          title={formatMessage(messages.favoriteProducts)}
          value={productSetForSelected}
          onChange={this.handleProductCheckedChanged}
        />
      );
    }
    const mobileCellHeight = 360;
    // Grid/Cell types do not accept style prop ...?
    const GridNoType = Grid as any;
    const CellNoType = Cell as any;
    return (
      <GridNoType
        style={{
          height: bowser.mobile ? "auto" : "calc(100% - 64px)",
          margin: 5,
        }}
      >
        <CellNoType
          palm="12/12"
          style={{
            height: bowser.mobile ? mobileCellHeight : "100%",
            paddingBottom: 11,
            paddingTop: 11,
          }}
        >
          {selectionBlock}
        </CellNoType>
        <CellNoType
          palm="12/12"
          style={{
            height: bowser.mobile ? mobileCellHeight : "100%",
            paddingBottom: 11,
            paddingTop: 11,
          }}
        >
          {productSelectionBlock}
        </CellNoType>
      </GridNoType>
    );
  }
}

const ConnectedStandardProducts = connect<
  StandardProductsStateProps,
  StandardProductsDispatchProps,
  object,
  AppState
>(
  createStructuredSelector<AppState, StandardProductsStateProps>({
    customerSettings: getCustomerSettings,
    machineArray: getMachineArray,
    machineGroupLookup: getMachineGroupLookup,
    machineLookup: getMachineLookup,
    priceGroupLookup: getPriceGroupLookup,
    productArray: getProductArray,
    workTypeArray: getWorkTypeArray,
    workTypeLookup: getWorkTypeLookup,
  }),
  {
    update: actions.update,
  },
)(StandardProducts);

export default ConnectedStandardProducts;
