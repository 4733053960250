import {ArchiveLink} from "@co-common-libs/resources";
import {instanceURL} from "frontend-global-config";
import {v4 as uuid} from "uuid";

export function createArchiveLink(instance: Omit<ArchiveLink, "id" | "url">): ArchiveLink {
  const id = uuid();
  const url = instanceURL("archiveLink", id);

  return {
    id,
    url,
    ...instance,
  };
}
