import {Locale, parse} from "date-fns";

const dateFormats = [
  // ISO: "yyyy-MM-dd",
  "ddMM",
  "ddMMyy",
  "ddMMyyyy",
  "d.M",
  "d.M.yy",
  "d.M.yyyy",
  "d,M",
  "d,M,yy",
  "d,M,yyyy",
  "d-M-yy",
  "d-M-yyyy",
  "d/M",
  "d/M/yy",
  "d/M/yyyy",
  "P", // "Long localized date" -- last to not override our specific formats...
] as const;

/**
 * Attempt to parse a date string with several different supported formats.
 */
export function parseDate(
  dateString: string,
  referenceDate: Date,
  locale?: Locale,
): Date | undefined {
  const localeOption = locale ? {locale} : undefined;
  for (const formatString of dateFormats) {
    const result = parse(dateString, formatString, referenceDate, localeOption);
    if (!isNaN(result.valueOf())) {
      return result;
    }
  }
  return undefined;
}

export function parseDateRelative(
  dateString: string,
  referenceDate: Date,
  yearSuggestions: "DATE_AFTER" | "DATE_BEFORE" | "SAME",
  locale?: Locale,
): Date | undefined {
  // parse result will have 0, 0, 0, 0; so reference date should too
  // for correct comparison wrt. "DATE_BEFORE" and "DATE_AFTER"
  console.assert(referenceDate.getHours() === 0);
  console.assert(referenceDate.getMinutes() === 0);
  console.assert(referenceDate.getSeconds() === 0);
  console.assert(referenceDate.getMilliseconds() === 0);

  const result = parseDate(dateString, referenceDate, locale);
  if (!result) {
    return undefined;
  } else if (yearSuggestions === "DATE_AFTER" && result < referenceDate) {
    // year was specified, or using year from reference date resulting in
    // earlier date in same year, and we want later
    referenceDate.setFullYear(referenceDate.getFullYear() + 1);
    return parseDate(dateString, referenceDate, locale);
  } else if (yearSuggestions === "DATE_BEFORE" && result > referenceDate) {
    // year was specified, or using year from reference date resulting in
    // later date in same year, and we want earlier
    referenceDate.setFullYear(referenceDate.getFullYear() - 1);
    return parseDate(dateString, referenceDate, locale);
  } else {
    return result;
  }
}
