import {
  LocationUrl,
  Order,
  ReportingLocations,
  ReportingLog,
  Task,
} from "@co-common-libs/resources";
import {getUpdatedFieldUseList} from "@co-common-libs/resources-utils";
import {
  AppState,
  actions,
  getCustomerSettings,
  getLocationLookup,
  getReportingSpecificationLookup,
  makeSelectCustomerActiveFields,
  makeSelectUserCustomerLastUsedLocations,
} from "@co-frontend-libs/redux";
import React, {useCallback, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {FieldMultiSelectionDialog} from "./customer-field-dialog";
import {FieldsCard} from "./fields-card";
import {FieldUseWithField} from "./fields-notes-reordering";
import {SelectTaskFields} from "./reporting-locations/select-task-fields";

export function getUsedFieldUrlSet(
  reportingLocations: ReportingLocations,
  reportingLog: ReportingLog,
): Set<LocationUrl> {
  const usedFieldsUrls = new Set<LocationUrl>();

  for (const logEntry of Object.values(reportingLog)) {
    const locationEntry = reportingLocations[logEntry.location];
    if (locationEntry && locationEntry.location) {
      usedFieldsUrls.add(locationEntry.location);
    }
  }
  return usedFieldsUrls;
}

interface TaskFieldsProps {
  defaultEditMode?: boolean;
  onRequestNotesDialogClose?: () => void;
  order?: Order | undefined;
  showFieldMap?: boolean;
  task: Task;
}

export function TaskFields(props: TaskFieldsProps): JSX.Element {
  const {
    defaultEditMode = false,
    onRequestNotesDialogClose,
    order,
    showFieldMap = true,
    task,
  } = props;
  const locationLookup = useSelector(getLocationLookup);
  const customerSettings = useSelector(getCustomerSettings);
  const reportingSpecificationLookup = useSelector(getReportingSpecificationLookup);

  const customerUrl = order?.customer || null;
  const selectCustomerActiveFields = useMemo(makeSelectCustomerActiveFields, []);
  const customerActiveFields = useSelector((state: AppState) =>
    selectCustomerActiveFields(state, customerUrl),
  );

  const fieldExtraNotes = new Map<string, string>();
  const orderFieldUseList = order && order.orderfielduseSet;
  if (orderFieldUseList && !!orderFieldUseList.length) {
    orderFieldUseList.forEach((fieldUse) => {
      const {notes} = fieldUse;
      if (notes) {
        const fieldURL = fieldUse.relatedField;
        fieldExtraNotes.set(fieldURL, notes);
      }
    });
  }
  const {fielduseSet, url} = task;
  const taskFieldUseList = fielduseSet ?? [];
  const dispatch = useDispatch();

  const handleNotesChange = useCallback(
    (fieldUse: FieldUseWithField): void => {
      const newfieldUseList = [...fielduseSet];
      const index = newfieldUseList.findIndex((field) => field.relatedField === fieldUse.field.url);
      newfieldUseList[index] = {
        fieldAreaHa: fieldUse.field.fieldAreaHa,
        fieldCrop: fieldUse.field.fieldCrop,
        geojson: fieldUse.field.geojson,
        notes: fieldUse.notes,
        relatedField: fieldUse.field.url,
      };
      dispatch(actions.update(url, [{member: "fielduseSet", value: newfieldUseList}]));
      if (onRequestNotesDialogClose) {
        onRequestNotesDialogClose();
      }
    },
    [dispatch, fielduseSet, url, onRequestNotesDialogClose],
  );

  const handleRearrange = useCallback(
    (sourceField: FieldUseWithField, targetField: FieldUseWithField): void => {
      const fieldUseList = fielduseSet;
      const sourceFieldUse = {
        fieldAreaHa: sourceField.field.fieldAreaHa,
        fieldCrop: sourceField.field.fieldCrop,
        geojson: sourceField.field.geojson,
        notes: sourceField.notes,
        relatedField: sourceField.field.url,
      };
      const sourceIndex = fieldUseList.findIndex(
        (field) => field.relatedField === sourceFieldUse.relatedField,
      );
      const targetIndex = fieldUseList.findIndex(
        (field) => field.relatedField === targetField.field.url,
      );
      const newfieldUseList = [...fieldUseList];
      newfieldUseList.splice(sourceIndex, 1);
      newfieldUseList.splice(targetIndex, 0, sourceFieldUse);
      dispatch(actions.update(url, [{member: "fielduseSet", value: newfieldUseList}]));
    },
    [dispatch, fielduseSet, url],
  );

  const [fieldDialogOpen, setFieldDialogOpen] = useState(false);

  const handleSelectFieldsClick = useCallback((): void => {
    setFieldDialogOpen(true);
  }, []);

  const reportingSpecification =
    task.reportingSpecification && !task.logSkipped
      ? reportingSpecificationLookup(task.reportingSpecification)
      : undefined;
  const taskLogUsingFields = !!(
    reportingSpecification?.fieldsUsedFor && reportingSpecification?.fieldsUsedFor !== "unused"
  );

  const handleFieldDialogOk = useCallback(
    (fieldURLSet: ReadonlySet<LocationUrl>): void => {
      const oldSelected = fielduseSet;
      const fieldUseList = getUpdatedFieldUseList(fieldURLSet, oldSelected, locationLookup);
      dispatch(actions.update(url, [{member: "fielduseSet", value: fieldUseList}]));

      setFieldDialogOpen(false);
      if (onRequestNotesDialogClose) {
        onRequestNotesDialogClose();
      }
    },
    [fielduseSet, locationLookup, dispatch, url, onRequestNotesDialogClose],
  );

  const handleFieldDialogCancel = useCallback((): void => {
    setFieldDialogOpen(false);
  }, []);

  const usedFieldsUrls =
    taskLogUsingFields && task.reportingLocations && task.reportingLog
      ? getUsedFieldUrlSet(task.reportingLocations, task.reportingLog)
      : undefined;

  const customerAndUser = useMemo(
    () => ({customer: order?.customer || null, user: task.machineOperator}),
    [order?.customer, task.machineOperator],
  );
  const selectUserCustomerLastUsedLocations = useMemo(makeSelectUserCustomerLastUsedLocations, []);
  const lastUsedLocations = useSelector((state: AppState) =>
    selectUserCustomerLastUsedLocations(state, customerAndUser),
  );

  return (
    <>
      <FieldsCard
        changeDisabled={task.completed}
        customerActiveFieldList={customerActiveFields}
        customerURL={customerUrl}
        extraNotes={fieldExtraNotes}
        fieldUseList={taskFieldUseList}
        isDefaultEditable={defaultEditMode}
        locationLookup={locationLookup}
        withMap={showFieldMap}
        onNotesChange={handleNotesChange}
        onRearrange={handleRearrange}
        onSelectFieldsClick={handleSelectFieldsClick}
      />
      {customerSettings.addEditLogLocationSkipsCustomerSelection ? (
        <SelectTaskFields
          logSpecification={reportingSpecification}
          open={fieldDialogOpen}
          task={task}
          onClose={handleFieldDialogCancel}
        />
      ) : (
        <FieldMultiSelectionDialog
          customerURL={customerUrl}
          lastUsedLocations={lastUsedLocations}
          open={fieldDialogOpen}
          readonlySet={usedFieldsUrls}
          selected={taskFieldUseList}
          onCancel={handleFieldDialogCancel}
          onOk={handleFieldDialogOk}
        />
      )}
    </>
  );
}
