import {Location, ProductUrl} from "@co-common-libs/resources";
import {iconButtonOffset} from "@co-common-libs/resources-utils";
import {LocationIconButton} from "@co-frontend-libs/components";
import {getLocationTypeLookup} from "@co-frontend-libs/redux";
import {common as commonColors} from "@material-ui/core/colors";
import {getGoogleMapsUrl} from "app-utils";
import React, {useCallback, useRef} from "react";
import {useSelector} from "react-redux";
import {LocationMarkerText} from "./location-marker-text";

const ICON_SIZE = 36;

const HALF_ICON_SIZE = ICON_SIZE / 2;

interface LocationMarkerProps {
  cropVisible: boolean;
  fieldHaAndCustomerNameVisible: boolean;
  labelsVisible: boolean;
  location: Location;
  onClickOrGoogleMapsUrl:
    | "GOOGLE_MAPS"
    | ((ref: HTMLSpanElement, location: Location) => void)
    | undefined;
  productCounts: ReadonlyMap<ProductUrl, number> | undefined;
  showAsActive?: boolean | undefined;
  x: number;
  y: number;
}

export function LocationMarker(props: LocationMarkerProps): JSX.Element {
  const {
    cropVisible,
    fieldHaAndCustomerNameVisible,
    labelsVisible,
    location,
    onClickOrGoogleMapsUrl,
    productCounts,
    showAsActive,
    x,
    y,
  } = props;
  const locationTypeLookup = useSelector(getLocationTypeLookup);

  const onClick = typeof onClickOrGoogleMapsUrl === "function" ? onClickOrGoogleMapsUrl : undefined;

  const locationTypeURL = location.locationType;
  const locationType = locationTypeURL ? locationTypeLookup(locationTypeURL) : null;
  const color = (locationType && locationType.color) || commonColors.white;

  const ref = useRef<HTMLSpanElement>(null);
  const handleClick = useCallback(() => {
    if (ref.current && onClick) {
      onClick(ref.current, location);
    }
  }, [location, onClick]);

  const defaultOffset = {xOffset: 0, yOffset: 0};
  const {xOffset, yOffset} = locationType?.icon
    ? iconButtonOffset[locationType.icon] || defaultOffset
    : defaultOffset;

  let iconButton: JSX.Element | null = null;
  if (!location.geojson || showAsActive) {
    iconButton = (
      <span
        ref={ref}
        style={{
          display: "inline-block",
          left: x + xOffset,
          position: "absolute",
          top: y + yOffset,
        }}
      >
        <LocationIconButton
          active={!!showAsActive}
          color={color}
          icon={locationType?.icon || "map-marker-circle"}
          size={ICON_SIZE}
          stroke={commonColors.black}
          strokeOpacity={1}
          strokeWidth={1}
          onClick={handleClick}
        />
      </span>
    );
  }

  return (
    <>
      {iconButton}
      {labelsVisible ? (
        <LocationMarkerText
          cropVisible={cropVisible}
          fieldHaAndCustomerNameVisible={fieldHaAndCustomerNameVisible}
          location={location}
          productCounts={productCounts}
          x={x + (!location.geojson || showAsActive ? -xOffset : -HALF_ICON_SIZE)}
          y={y + (!location.geojson || showAsActive ? yOffset : -HALF_ICON_SIZE)}
          onClickOrGoogleMapsUrl={
            onClickOrGoogleMapsUrl === "GOOGLE_MAPS"
              ? getGoogleMapsUrl(location)
              : onClickOrGoogleMapsUrl
          }
        />
      ) : null}
    </>
  );
}
