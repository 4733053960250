import {PriceItemUse, ProductUse} from "@co-common-libs/resources";
import {getUnitString, priceItemIsTime} from "@co-common-libs/resources-utils";
import {getPriceItemLookup, getProductLookup, getUnitLookup} from "@co-frontend-libs/redux";
import {
  Card,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import {FormattedMessage, FormattedNumber, useIntl} from "react-intl";
import {useSelector} from "react-redux";

interface DisplayWorkplaceTotalsTableProps {
  loads: Map<string, number> | undefined;
  locationSummedIdentifierLabels: ReadonlyMap<string, string>;
  locationSums: ReadonlyMap<string, number>;
  priceItemUseList: readonly PriceItemUse[];
  productUseList: readonly ProductUse[];
  summedIdentifierLabels: ReadonlyMap<string, string>;
  sums: ReadonlyMap<string, number>;
}

export const DisplayWorkplaceTotalsTable = React.memo(function DisplayWorkplaceTotalsTable(
  props: DisplayWorkplaceTotalsTableProps,
): JSX.Element {
  const {
    loads,
    locationSummedIdentifierLabels,
    locationSums,
    priceItemUseList,
    productUseList,
    summedIdentifierLabels,
    sums,
  } = props;

  const priceItemLookup = useSelector(getPriceItemLookup);
  const productLookup = useSelector(getProductLookup);
  const unitLookup = useSelector(getUnitLookup);

  const intl = useIntl();

  const rows: JSX.Element[] = [];
  summedIdentifierLabels.forEach((label, identifier) => {
    const value = sums.get(identifier);
    rows.push(
      <TableRow key={identifier}>
        <TableCell>{label}</TableCell>
        <TableCell>
          <FormattedNumber maximumFractionDigits={2} value={value || 0} />
        </TableCell>
        {loads ? <TableCell /> : null}{" "}
      </TableRow>,
    );
  });
  locationSummedIdentifierLabels.forEach((label, identifier) => {
    const value = locationSums.get(identifier);
    rows.push(
      <TableRow key={`l-${identifier}`}>
        <TableCell>{label}</TableCell>
        <TableCell>
          <FormattedNumber maximumFractionDigits={2} value={value || 0} />
        </TableCell>
        {loads ? <TableCell /> : null}
      </TableRow>,
    );
  });
  priceItemUseList.forEach((entry) => {
    const priceItemURL = entry.priceItem;
    if (!priceItemURL) {
      return;
    }
    const priceItem = priceItemLookup(priceItemURL);
    if (
      !priceItem ||
      priceItem.relevantForExecution === false ||
      priceItemIsTime(unitLookup, priceItem)
    ) {
      return;
    }
    const {name} = priceItem;
    const unit = getUnitString(priceItem, unitLookup);
    const quantity = entry.count;
    const label = unit ? `${name}, ${unit}` : name;
    rows.push(
      <TableRow key={`priceitem-${priceItemURL}`}>
        <TableCell>{label}</TableCell>
        <TableCell>
          <FormattedNumber maximumFractionDigits={2} value={quantity || 0} />
        </TableCell>
        {loads ? <TableCell>{loads.get(priceItem.url)}</TableCell> : null}
      </TableRow>,
    );
  });
  productUseList.forEach((entry) => {
    const productURL = entry.product;
    if (!productURL) {
      return;
    }
    const product = productLookup(productURL);
    if (!product) {
      return;
    }
    const quantity = entry.count;
    const {name} = product;
    const unit = getUnitString(product, unitLookup);
    const label = unit ? `${name}, ${unit}` : name;
    rows.push(
      <TableRow key={`product-${productURL}`}>
        <TableCell>{label}</TableCell>
        <TableCell>
          <FormattedNumber maximumFractionDigits={2} value={quantity || 0} />
        </TableCell>
        {loads ? <TableCell>{loads.get(product.url)}</TableCell> : null}
      </TableRow>,
    );
  });
  return (
    <Card style={{margin: "1em"}}>
      <CardHeader title={intl.formatMessage({defaultMessage: "Total"})} />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>
              <FormattedMessage defaultMessage="Antal" id="log-card.table-header.count" />
            </TableCell>
            {loads ? (
              <TableCell>
                <FormattedMessage defaultMessage="Læs" />
              </TableCell>
            ) : null}
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    </Card>
  );
});
