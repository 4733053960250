import {Config} from "@co-common-libs/config";
import {Customer, CustomerUrl, Project, ProjectUrl, Task, UserUrl} from "@co-common-libs/resources";
import {MultipleProjectsDialog, ProjectDialog} from "@co-frontend-libs/components";
import {
  AppState,
  getCurrentUserURL,
  getCustomerLookup,
  getCustomerSettings,
  getProjectArray,
  getTaskArray,
} from "@co-frontend-libs/redux";
import {connect} from "react-redux";
import {createSelector, createStructuredSelector} from "reselect";

interface ProjectDialogStateProps {
  currentUserURL: UserUrl | null | undefined;
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  customerSettings: Pick<Config, "projectLabelVariant">;
  projectArray: readonly Project[];
  showProjectAlias: boolean;
  taskArray: readonly Task[];
}

interface ProjectDialogOwnProps {
  customerURL?: CustomerUrl | null | undefined;
  onAdd?: ((searchString: string) => void) | undefined;
  onCancel(): void;
  onNone?(): void;
  onOk(url: ProjectUrl): void;
  open: boolean;
  showAllProjects?: boolean;
  suggestRecentlyUsed: boolean;
}

const getShowProjectAlias: (state: AppState) => boolean = createSelector(
  getCustomerSettings,
  (customerSettings) => customerSettings.showProjectAlias,
);
export const ConnectedProjectDialog: React.ComponentType<ProjectDialogOwnProps> = connect<
  ProjectDialogStateProps,
  object,
  ProjectDialogOwnProps,
  AppState
>(
  createStructuredSelector<AppState, ProjectDialogStateProps>({
    currentUserURL: getCurrentUserURL,
    customerLookup: getCustomerLookup,
    customerSettings: getCustomerSettings,
    projectArray: getProjectArray,
    showProjectAlias: getShowProjectAlias,
    taskArray: getTaskArray,
  }),
  {},
)(ProjectDialog);

interface MultipleProjectsDialogStateProps {
  currentUserURL: UserUrl | null | undefined;
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  customerSettings: Pick<Config, "projectLabelVariant">;
  projectArray: readonly Project[];
  showProjectAlias: boolean;
  taskArray: readonly Task[];
}

interface MultipleProjectsDialogOwnProps {
  customerURL?: CustomerUrl | null;
  onCancel(): void;
  onOk(urls: ReadonlySet<ProjectUrl>): void;
  open: boolean;
  selected?: ReadonlySet<ProjectUrl>;
  showAllProjects?: boolean;
  suggestRecentlyUsed: boolean;
}

export const ConnectedMultipleProjectsDialog: React.ComponentType<MultipleProjectsDialogOwnProps> =
  connect<MultipleProjectsDialogStateProps, object, MultipleProjectsDialogOwnProps, AppState>(
    createStructuredSelector<AppState, MultipleProjectsDialogStateProps>({
      currentUserURL: getCurrentUserURL,
      customerLookup: getCustomerLookup,
      customerSettings: getCustomerSettings,
      projectArray: getProjectArray,
      showProjectAlias: getShowProjectAlias,
      taskArray: getTaskArray,
    }),
    {},
  )(MultipleProjectsDialog);
