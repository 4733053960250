import {CustomerUrl, LocationUrl, LocationUseLog, UserUrl} from "@co-common-libs/resources";
import {ParametricSelector, createSelector} from "reselect";
import {getLocationUseLogArray} from "../resources/selectors";
import {AppState} from "../root-reducer";

interface LocationUseLogCriteria {
  customer: CustomerUrl | null;
  user: UserUrl | null;
}

function findLocationUseLogData(
  locationUseLogArray: readonly LocationUseLog[],
  criteria: LocationUseLogCriteria,
): ReadonlySet<LocationUrl> | undefined {
  const {customer, user} = criteria;
  if (customer && user) {
    const matchingEntry = locationUseLogArray.find(
      (entry) => entry.customer === customer && entry.user === user,
    );
    if (matchingEntry && matchingEntry.locations.length) {
      return new Set(matchingEntry.locations);
    }
  }
  return undefined;
}

export function makeSelectUserCustomerLastUsedLocations(): ParametricSelector<
  AppState,
  LocationUseLogCriteria,
  ReadonlySet<LocationUrl> | undefined
> {
  return createSelector(
    getLocationUseLogArray,
    (_: AppState, criteria: LocationUseLogCriteria) => criteria,
    findLocationUseLogData,
  );
}
