import {
  ContactUrl,
  CustomerUrl,
  LocationUrl,
  Project,
  ProjectAccess,
  Task,
  UserUrl,
  emptyProject as projectDefaults,
} from "@co-common-libs/resources";
import {
  DecimalField,
  IntegerField,
  MinutesField,
  OfflineWarning,
  ProjectAccessSelect,
  ResponsiveDialog,
  ThrottledTextField,
  TrimTextField,
} from "@co-frontend-libs/components";
import {getCustomerLookup, getExtendedCustomerSettings} from "@co-frontend-libs/redux";
import {useResettingState} from "@co-frontend-libs/utils";
import {DialogContent, Grid} from "@material-ui/core";
import React, {useCallback, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {useIsOnline} from "react-use-is-online";
// TODO(mr): Cannot shorten these imports without getting a cyclic import because
//           project block imports ProjectCreateEditDialog, and is indirectly
//           imported when using the barrel index.
//           Perhaps we should drop a combined barrel for the user, contact, project, etc. blocks
import {ContactBlock} from "../info-blocks/contact-block";
import {LocationBlock} from "../info-blocks/location-block";
import {UserBlock} from "../info-blocks/user-block";

export type ProjectData = Readonly<
  Pick<
    Project,
    | "access"
    | "alias"
    | "contact"
    | "customer"
    | "distanceInKm"
    | "managerInternalNotes"
    | "name"
    | "projectNumber"
    | "relatedWorkplace"
    | "responsible"
    | "travelTimeInMinutes"
  >
>;

// TODO(mr): refactor
//            - <ResourceName>CreateDialog
//            - <ResourceName>EditDialog
//            - <ResourceName>CreateWithIntegrationDialog (add to lib in economy-system-integration feature folder)
//            - <ResourceName>EditWithIntegrationDialog (add to lib in economy-system-integration feature folder)

interface ProjectCreateEditDialogProps {
  data: CustomerUrl | Readonly<Project>;
  defaultAccessOnCreate: ProjectAccess;
  onCancel: () => void;
  onOk: (data: ProjectData) => void;
  open: boolean;
  task?: Task | undefined;
}

export const ProjectCreateEditDialog = React.memo(function ProjectCreateEditDialog(
  props: ProjectCreateEditDialogProps,
): JSX.Element | null {
  const {data, defaultAccessOnCreate, onCancel, onOk, open} = props;

  const intl = useIntl();
  const {
    projects: {
      canEditField: unboundCanEditField,
      enableProjectDistance,
      enableProjectTravelTime,
      projectLabelVariant,
      requireIntegerProjectNumber,
      showProjectAlias,
    },
  } = useSelector(getExtendedCustomerSettings);
  const customerLookup = useSelector(getCustomerLookup);

  const project = typeof data !== "string" ? data : undefined;
  const customerUrl = typeof data === "string" ? data : data.customer;
  const customer = customerLookup(customerUrl);

  const [access, setAccess] = useResettingState<ProjectAccess>(
    project?.access ?? defaultAccessOnCreate,
    open,
  );

  const [alias, setAlias] = useResettingState(project?.alias ?? projectDefaults.alias, open);
  const [distanceInKm, setDistanceInKm] = useResettingState(
    project?.distanceInKm ?? projectDefaults.distanceInKm,
    open,
  );
  const [name, setName] = useResettingState(project?.name ?? projectDefaults.name, open);
  const [projectNumber, setProjectNumber] = useResettingState(
    project?.projectNumber ?? projectDefaults.projectNumber,
    open,
  );

  const [managerInternalNotes, setManagerInternalNotes] = useResettingState(
    project?.managerInternalNotes ?? projectDefaults.managerInternalNotes,
    open,
  );

  const [travelTimeInMinutes, setTravelTimeInMinutes] = useResettingState(
    project?.travelTimeInMinutes ?? projectDefaults.travelTimeInMinutes,
    open,
  );

  const [contactUrl, setContactUrl] = useState<ContactUrl | null>(project?.contact ?? null);
  const [responsibleUserUrl, setResponsibleUserUrl] = useState<UserUrl | null>(
    project?.responsible ?? null,
  );

  const [relatedWorkplaceUrl, setRelatedWorkplaceUrl] = useState<LocationUrl | null>(
    project?.relatedWorkplace ?? null,
  );

  const canEditField = project ? unboundCanEditField.bind(null, project) : () => true;

  const createTitle =
    projectLabelVariant === "PROJECT"
      ? intl.formatMessage({defaultMessage: "Opret projekt"})
      : intl.formatMessage({defaultMessage: "Opret sag"});

  const editTitle =
    projectLabelVariant === "PROJECT"
      ? intl.formatMessage({defaultMessage: "Rediger projekt"})
      : intl.formatMessage({defaultMessage: "Rediger sag"});

  const title = project ? editTitle : createTitle;

  const handleOk = useCallback(() => {
    const payload = {
      access,
      alias,
      contact: contactUrl,
      customer: customerUrl,
      distanceInKm,
      managerInternalNotes,
      name,
      projectNumber,
      relatedWorkplace: relatedWorkplaceUrl,
      responsible: responsibleUserUrl,
      travelTimeInMinutes,
    };
    onOk(payload);
  }, [
    access,
    alias,
    contactUrl,
    customerUrl,
    distanceInKm,
    managerInternalNotes,
    name,
    projectNumber,
    relatedWorkplaceUrl,
    responsibleUserUrl,
    travelTimeInMinutes,
    onOk,
  ]);

  const handleProjectNumberChange = useCallback(
    (value: number | string | null): void => {
      setProjectNumber(`${value ?? ""}`);
    },
    [setProjectNumber],
  );

  const handleContactChanged = useCallback(
    (newContactUrl: ContactUrl | null): void => {
      setContactUrl(newContactUrl);
    },
    [setContactUrl],
  );

  const handleResponsibleUserChanged = useCallback(
    (newResponsibleUserUrl: UserUrl | null): void => {
      setResponsibleUserUrl(newResponsibleUserUrl);
    },
    [setResponsibleUserUrl],
  );

  const handleRelatedWorkplaceChanged = useCallback(
    (newRelatedWorkplaceUrl: LocationUrl | null): void => {
      setRelatedWorkplaceUrl(newRelatedWorkplaceUrl);
    },
    [setRelatedWorkplaceUrl],
  );

  const {isOnline} = useIsOnline();
  return (
    <ResponsiveDialog
      okDisabled={!projectNumber.trim() || !name.trim() || !isOnline}
      open={open}
      title={title}
      onCancel={onCancel}
      onOk={handleOk}
    >
      <DialogContent>
        <OfflineWarning />
        {customer ? (
          <FormattedMessage
            defaultMessage="Kunde: {customer}"
            tagName="h4"
            values={{customer: customer.name}}
          />
        ) : null}
        <ProjectAccessSelect
          access={access}
          disabled={!canEditField("access")}
          onChange={setAccess}
        />
        {requireIntegerProjectNumber ? (
          <IntegerField
            fullWidth
            required
            autoFocus={!project}
            label={
              projectLabelVariant === "PROJECT"
                ? intl.formatMessage({
                    defaultMessage: "Projektnummer",
                  })
                : intl.formatMessage({
                    defaultMessage: "Sagsnummer",
                  })
            }
            margin="dense"
            readonly={!canEditField("projectNumber")}
            value={projectNumber ? parseInt(projectNumber) : null}
            onChange={handleProjectNumberChange}
          />
        ) : (
          <TrimTextField
            fullWidth
            required
            autoFocus={!project}
            label={
              projectLabelVariant === "PROJECT"
                ? intl.formatMessage({
                    defaultMessage: "Projektnummer",
                  })
                : intl.formatMessage({
                    defaultMessage: "Sagsnummer",
                  })
            }
            margin="dense"
            readonly={!canEditField("projectNumber")}
            value={projectNumber}
            variant="outlined"
            onChange={handleProjectNumberChange}
          />
        )}
        <TrimTextField
          fullWidth
          required
          label={intl.formatMessage({defaultMessage: "Navn"})}
          margin="dense"
          readonly={!canEditField("name")}
          value={name}
          variant="outlined"
          onChange={setName}
        />
        {showProjectAlias ? (
          <TrimTextField
            fullWidth
            label={intl.formatMessage({defaultMessage: "Søgenavn"})}
            margin="dense"
            readonly={!canEditField("alias")}
            value={alias}
            variant="outlined"
            onChange={setAlias}
          />
        ) : null}
        {enableProjectDistance ? (
          <DecimalField
            fullWidth
            decimalPlaces={2}
            label={intl.formatMessage({defaultMessage: "Afstand (km)"})}
            margin="dense"
            maxDigits={6}
            readonly={!canEditField("distanceInKm")}
            value={distanceInKm}
            onChange={setDistanceInKm}
          />
        ) : null}
        {enableProjectTravelTime ? (
          <MinutesField
            fullWidth
            disabled={!canEditField("travelTimeInMinutes")}
            label={intl.formatMessage({
              defaultMessage: "Rejsetid, en vej (timer:minutter)",
            })}
            value={travelTimeInMinutes}
            onChange={setTravelTimeInMinutes}
          />
        ) : null}
        {!project && (
          <ThrottledTextField
            fullWidth
            multiline
            label={intl.formatMessage({defaultMessage: "Interne noter"})}
            margin="dense"
            maxRows={30}
            minRows={2}
            readonly={false}
            style={{marginBottom: 10}}
            value={managerInternalNotes}
            variant="outlined"
            onChange={setManagerInternalNotes}
          />
        )}
        <Grid container>
          <Grid item sm={6} xs={12}>
            <ContactBlock
              contactUrl={contactUrl}
              customerUrl={customerUrl}
              hideButtons={false}
              selectDisabled={false}
              onContactChanged={handleContactChanged}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <UserBlock
              hideButtons={false}
              selectDisabled={false}
              userUrl={responsibleUserUrl}
              onUserChanged={handleResponsibleUserChanged}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <LocationBlock
              includeWorkplaceOnlyLocations
              customerUrl={customerUrl}
              includeLogOnlyLocations={false}
              locationUrl={relatedWorkplaceUrl}
              title={intl.formatMessage({defaultMessage: "Arbejdssted"})}
              titleVariant="WORKPLACE"
              onLocationChanged={handleRelatedWorkplaceChanged}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </ResponsiveDialog>
  );
});
