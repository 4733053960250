import {Config} from "@co-common-libs/config";
import {
  Culture,
  CultureUrl,
  Customer,
  CustomerUrl,
  FieldUse,
  Location,
  LocationUrl,
  Project,
  ProjectUrl,
  Task,
} from "@co-common-libs/resources";
import {formatAddress} from "@co-common-libs/utils";
import {IntlShape} from "react-intl";

export const generateVariableBookeepingInfo = (
  {
    cultureURL,
    customerURL,
    fielduseSet,
    pickupLocationURL,
    projectURL,
    task,
    workplaceURL,
  }: {
    cultureURL: CultureUrl | null;
    customerURL: CustomerUrl | null;
    fielduseSet: readonly FieldUse[] | null;
    pickupLocationURL: LocationUrl | null;
    projectURL: ProjectUrl | null;
    task: Task;
    workplaceURL: LocationUrl | null;
  },
  {
    cultureLookup,
    customerLookup,
    customerSettings,
    locationLookup,
    projectLookup,
  }: {
    cultureLookup: (url: CultureUrl) => Culture | undefined;
    customerLookup: (url: CustomerUrl) => Customer | undefined;
    customerSettings: Config;
    locationLookup: (url: LocationUrl) => Location | undefined;
    projectLookup: (url: ProjectUrl) => Project | undefined;
  },
): string[] => {
  let projectString;
  const project = projectURL ? projectLookup(projectURL) : undefined;
  if (project) {
    const {projectNumber} = project;
    const projectName = project.name;
    if (projectNumber && projectName) {
      projectString = `${projectNumber}: ${projectName}`;
    } else {
      projectString = projectName || projectNumber;
    }
  }

  const workplace = workplaceURL ? locationLookup(workplaceURL) : undefined;
  const pickupLocation = pickupLocationURL ? locationLookup(pickupLocationURL) : undefined;
  const customer = customerURL ? customerLookup(customerURL) : undefined;
  const culture = cultureURL ? cultureLookup(cultureURL) : undefined;

  const customerName = customer ? customer.name : undefined;
  const cultureName = culture ? culture.name : undefined;

  let workplaceString;
  if (customerSettings.bookkeepingWorkplaceAddressReplaceName && workplace) {
    workplaceString = formatAddress(workplace);
  } else if (workplace) {
    workplaceString = workplace.name || formatAddress(workplace);
    if (workplace.customer && workplace.customer !== customerURL) {
      const locationCustomer = customerLookup(workplace.customer);
      workplaceString = `(${locationCustomer?.name}) ${workplaceString}`;
    }
  }
  let pickuplocationString;
  if (pickupLocation) {
    pickuplocationString = pickupLocation.name || pickupLocation.address;
  }
  let fieldsString;
  if (customerSettings.showFieldsOnBookkeepingTables && fielduseSet) {
    fieldsString = fielduseSet
      .map((fieldUse) => locationLookup(fieldUse.relatedField)?.fieldNumber)
      .join(" ");
  }

  let logLocationsString: string | undefined;
  if (customerSettings.showLogLocationsOnBookkeeping && task.reportingLog) {
    const usedLogEntryLocations = new Set<string>();
    const usedLocationNameOrAddressMap = new Map<string, string>();
    if (task.reportingLog) {
      for (const entry of Object.values(task.reportingLog)) {
        if (entry.location && !usedLogEntryLocations.has(entry.location)) {
          usedLogEntryLocations.add(entry.location);
          const logLocation = task.reportingLocations?.[entry.location];
          if (logLocation?.location) {
            const location = locationLookup(logLocation.location);
            if (location && !usedLocationNameOrAddressMap.has(location.url)) {
              usedLocationNameOrAddressMap.set(location.url, location.name || location.address);
            }
          }
        }
      }
    }
    const locationStrings = [...usedLocationNameOrAddressMap.values()];
    locationStrings.sort();

    logLocationsString = locationStrings.join(", ");
  }

  return [
    customerName,
    cultureName,
    projectString,
    workplaceString,
    pickuplocationString,
    fieldsString,
    logLocationsString,
  ].filter(Boolean) as string[];
};

export const generateVariableBookeepingInfoHeader = (
  formatMessage: IntlShape["formatMessage"],
  customerSettings: Config,
): string => {
  const {
    customerFields,
    enableProjects,
    externalTaskCulture,
    externalTaskCustomer,
    showFieldsOnBookkeepingTables,
    showLogLocationsOnBookkeeping,
    taskPickupDelivery,
  } = customerSettings;
  const variableHeaderParts = [];
  if (externalTaskCustomer && externalTaskCulture) {
    variableHeaderParts.push(
      `${formatMessage({defaultMessage: "kunde"})} / ${formatMessage({defaultMessage: "kultur"})}`,
    );
  } else if (externalTaskCustomer) {
    variableHeaderParts.push(formatMessage({defaultMessage: "kunde"}));
  } else {
    variableHeaderParts.push(formatMessage({defaultMessage: "kultur"}));
  }

  if (enableProjects) {
    variableHeaderParts.push(
      customerSettings.projectLabelVariant === "PROJECT"
        ? formatMessage({defaultMessage: "projekt"})
        : formatMessage({defaultMessage: "sag"}),
    );
  }

  if (taskPickupDelivery) {
    variableHeaderParts.push(formatMessage({defaultMessage: "afhentning, levering"}));
  } else {
    variableHeaderParts.push(formatMessage({defaultMessage: "arbejdssted"}));
  }

  if (customerFields && showFieldsOnBookkeepingTables) {
    variableHeaderParts.push(formatMessage({defaultMessage: "marker"}));
  }

  if (showLogLocationsOnBookkeeping) {
    variableHeaderParts.push(formatMessage({defaultMessage: "logsteder"}));
  }

  const variableHeaderText = variableHeaderParts.join(", ");
  return `${variableHeaderText.charAt(0).toUpperCase()}${variableHeaderText.substring(1)}`;
};
