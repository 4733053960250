import {RoutePlan} from "@co-common-libs/resources";
import {createSelector} from "reselect";
import {getRoutePlanArray} from "../resources/selectors";
import {AppState} from "../root-reducer";
import {filterOnActive} from "./utils";

export const getActiveRoutePlanArray: (state: AppState) => readonly RoutePlan[] = createSelector(
  getRoutePlanArray,
  filterOnActive,
);
