import {ComputedTime, Task, Timer, WorkType} from "@co-common-libs/resources";
import {InternalTaskCheckDialog} from "app-components";
import {ErrorAction, PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import React from "react";
import {IntlContext, defineMessages} from "react-intl";

const messages = defineMessages({
  validatedDialogTitle: {
    defaultMessage: "Godkend?",
    id: "task-instance.dialog-title.validate-and-record",
  },
});

interface InternalValidatedDialogProps {
  computedEndTime?: string | undefined;
  computedIntervals?: readonly ComputedTime[];
  computedStartTime?: string | undefined;
  continuation: boolean;
  finalEndTime?: string | undefined;
  finalIntervals?: readonly {
    readonly fromTimestamp: string;
    readonly timer: Timer | null;
    readonly toTimestamp: string;
  }[];
  finalStartTime?: string | undefined;
  genericPrimaryTimer: Timer;
  onAction?: (action: ErrorAction) => void;
  onCancel: (event: unknown) => void;
  onOk?: (event: unknown, continuation: boolean) => void;
  open: boolean;
  primaryWorkType?: WorkType | undefined;
  secondaryTimers: ReadonlySet<Timer>;
  task: Task;
}

class InternalValidatedDialog extends PureComponent<InternalValidatedDialogProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  @bind
  handleOk(): void {
    const {continuation, onOk} = this.props;
    if (onOk) {
      onOk(null, continuation);
    }
  }
  @bind
  handleCancel(): void {
    this.props.onCancel(null);
  }
  render(): JSX.Element | null {
    if (!this.props.task) {
      return null;
    }
    const {continuation, onCancel, onOk, ...others} = this.props;
    const {formatMessage} = this.context;
    const title = formatMessage(messages.validatedDialogTitle);
    return (
      <InternalTaskCheckDialog
        title={title}
        onCancel={this.handleCancel}
        onOk={this.handleOk}
        {...others}
      />
    );
  }
}

export default InternalValidatedDialog;
