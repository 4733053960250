import {PatchOperation, WorkType, urlToId} from "@co-common-libs/resources";
import {IntegerField, ResponsiveDialog, TrimTextField} from "@co-frontend-libs/components";
import {
  actions,
  getExtendedCustomerSettings,
  getProductGroupArray,
  getWorkTypeArray,
} from "@co-frontend-libs/redux";
import {
  jsonFetch,
  translateNetworkError,
  useCallWithFalse,
  useCallWithTrue,
} from "@co-frontend-libs/utils";
import {Button, CircularProgress, DialogContent, useTheme} from "@material-ui/core";
import {createWorkType} from "app-utils";
import {ImportAccountDialog} from "feat-import-resources";
import {globalConfig} from "frontend-global-config";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

// TODO(mr): refactor
//            - <ResourceName>CreateDialog
//            - <ResourceName>EditDialog
//            - <ResourceName>CreateWithIntegrationDialog (add to lib in economy-system-integration feature folder)
//            - <ResourceName>EditWithIntegrationDialog (add to lib in economy-system-integration feature folder)
export const WorkTypeCreateEditDialog = React.memo(function WorkTypeCreateEditDialog(props: {
  onCancel: () => void;
  onOk: (workTypeUrl: string) => void;
  open: boolean;
  workType?: WorkType | undefined;
}): JSX.Element {
  const {onOk, open, workType} = props;
  const [name, setName] = useState(workType?.name || "");
  const [salesAccount, setSalesAccount] = useState<number | null>(workType?.salesAccount ?? null);
  const [identifier, setIdentifier] = useState(workType?.identifier || "");

  const [saving, setSaving] = useState(false);

  const [accountDialogOpen, setAccountDialogOpen] = useState(false);
  const setAccountDialogOpenTrue = useCallWithTrue(setAccountDialogOpen);
  const setAccountDialogOpenFalse = useCallWithFalse(setAccountDialogOpen);

  const handleIdentifierChange = useCallback((value: number | null) => {
    setIdentifier(value?.toString() || "");
  }, []);

  const productGroupArray = useSelector(getProductGroupArray);

  const productGroupWithSameRemote = useMemo(
    () =>
      workType?.remoteUrl
        ? productGroupArray.find((pg) => pg.remoteUrl === workType.remoteUrl)
        : null,
    [productGroupArray, workType?.remoteUrl],
  );

  const customerSettings = useSelector(getExtendedCustomerSettings);
  const workTypeArray = useSelector(getWorkTypeArray);

  const canEditField = workType
    ? customerSettings.workTypes.canEditField.bind(null, workType)
    : () => true;

  const workTypeNumberDuplicate: boolean = useMemo(() => {
    if (!identifier) {
      return false;
    } else {
      return workTypeArray.some((w) => w.identifier === identifier && w.url !== w?.url);
    }
  }, [identifier, workTypeArray]);

  const productGroupNumberDuplicate: boolean = useMemo(() => {
    if (!customerSettings.economicSync || !identifier) {
      return false;
    } else {
      return productGroupArray.some(
        (productGroup) =>
          productGroup.identifier === identifier && productGroup.remoteUrl !== workType?.remoteUrl,
      );
    }
  }, [customerSettings.economicSync, identifier, productGroupArray, workType?.remoteUrl]);

  useEffect(() => {
    if (open) {
      setName(workType?.name || "");
      setIdentifier(workType?.identifier || "");
      setSalesAccount(workType?.salesAccount ?? null);
    }
  }, [open, workType?.identifier, workType?.name, workType?.salesAccount]);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const intl = useIntl();

  const economicSyncWorkTypesToProductGroups =
    customerSettings.economicSync && customerSettings.economicEnableWorkTypeFromProductGroupImport;

  const dispatch = useDispatch();
  const handleOk = useCallback(async () => {
    if (economicSyncWorkTypesToProductGroups) {
      setSaving(true);
      const {baseURL} = globalConfig.resources;
      if (workType) {
        if (name !== workType.name) {
          try {
            const url = `${baseURL}economic/worktype/${urlToId(workType.url)}`;
            const response = await jsonFetch(url, "POST", {
              name,
            });
            dispatch(actions.addToOffline(response.data));
            onOk(response.data.url);
          } catch (error) {
            setSaving(false);
            setErrorMessage(translateNetworkError(error, intl));
          }
        } else {
          onOk(workType.url);
        }
      } else {
        const url = `${baseURL}economic/worktype/create`;
        try {
          const response = await jsonFetch(url, "POST", {
            identifier,
            name,
            salesAccount,
          });
          dispatch(actions.addToOffline(response.data));
          onOk(response.data.url);
        } catch (error) {
          setSaving(false);
          setErrorMessage(translateNetworkError(error, intl));
        }
      }
      setSaving(false);
    } else {
      if (workType) {
        const patch: PatchOperation<WorkType>[] = [];

        if (name !== workType.name) {
          patch.push({member: "name", value: name});
        }
        if (identifier !== workType.identifier) {
          patch.push({member: "identifier", value: identifier});
        }

        if (patch.length) {
          dispatch(actions.update(workType.url, patch));
        }
        onOk(workType.url);
      } else {
        const newWorkType = createWorkType({
          externalTaskPrimary: true,
          identifier,
          name,
        });

        dispatch(actions.create(newWorkType));
        onOk(newWorkType.url);
      }
    }
  }, [
    economicSyncWorkTypesToProductGroups,
    workType,
    name,
    identifier,
    onOk,
    salesAccount,
    dispatch,
    intl,
  ]);

  const theme = useTheme();

  const duplicateNumberError = workTypeNumberDuplicate
    ? intl.formatMessage({
        defaultMessage: "Der eksisterer allerede et område med det ID",
      })
    : productGroupNumberDuplicate
      ? intl.formatMessage({
          defaultMessage:
            "Der eksisterer allerede en varegruppe (I e-conomic) med det ID. Du kan importere området istedet.",
        })
      : undefined;
  const handleAccountDialogOk = useCallback((account: string) => {
    setSalesAccount(parseInt(account));
    setAccountDialogOpen(false);
  }, []);
  const dublicateNumber = productGroupNumberDuplicate || workTypeNumberDuplicate;

  return (
    <>
      <ResponsiveDialog
        okDisabled={
          !name ||
          !identifier ||
          dublicateNumber ||
          saving ||
          (economicSyncWorkTypesToProductGroups && !salesAccount)
        }
        open={open && !accountDialogOpen}
        title={
          props.workType ? (
            <FormattedMessage defaultMessage="Redigér område" />
          ) : (
            <FormattedMessage defaultMessage="Opret område" />
          )
        }
        onCancel={props.onCancel}
        onOk={handleOk}
      >
        <DialogContent>
          {economicSyncWorkTypesToProductGroups ? (
            <>
              {productGroupWithSameRemote ? (
                <FormattedMessage
                  defaultMessage="Hvis du ændre navn her vil det også blive ændret på varegruppen {identifier}: {name}"
                  values={{
                    identifier: productGroupWithSameRemote.identifier,
                    name: productGroupWithSameRemote.name,
                  }}
                />
              ) : null}
              <IntegerField
                fullWidth
                autoFocus={!props.workType}
                disabled={saving || !canEditField("identifier")}
                error={dublicateNumber}
                helperText={duplicateNumberError}
                label={intl.formatMessage({
                  defaultMessage: "ID *",
                })}
                margin="dense"
                value={identifier ? parseInt(identifier) : null}
                onChange={handleIdentifierChange}
              />
            </>
          ) : (
            <TrimTextField
              fullWidth
              autoFocus={!props.workType}
              disabled={saving || !canEditField("identifier")}
              error={workTypeNumberDuplicate}
              helperText={duplicateNumberError}
              label={intl.formatMessage({
                defaultMessage: "ID *",
              })}
              margin="dense"
              value={identifier}
              variant="outlined"
              onChange={setIdentifier}
            />
          )}

          <TrimTextField
            fullWidth
            autoFocus={!!props.workType}
            disabled={saving || !canEditField("name")}
            label={intl.formatMessage({
              defaultMessage: "Navn *",
            })}
            margin="dense"
            value={name}
            variant="outlined"
            onChange={setName}
          />
          {economicSyncWorkTypesToProductGroups ? (
            <>
              <Button
                color="secondary"
                disabled={!!workType?.remoteUrl}
                style={{marginTop: 2}}
                variant="contained"
                onClick={setAccountDialogOpenTrue}
              >
                <FormattedMessage defaultMessage="Vælg konto" />
              </Button>
              <div style={{whiteSpace: "pre-line"}}>
                {salesAccount}{" "}
                {workType?.remoteUrl ? (
                  <FormattedMessage
                    defaultMessage="(Kontoen kan kun ændres i e-conomic)"
                    tagName="i"
                  />
                ) : null}
              </div>
            </>
          ) : null}
          <FormattedMessage defaultMessage="* Skal udfyldes" tagName="div" />
          {errorMessage ? (
            <h3
              style={{
                color: theme.palette.error.main,
                whiteSpace: "pre-line",
              }}
            >
              {errorMessage}
            </h3>
          ) : null}
          {saving ? (
            <div style={{textAlign: "center"}}>
              <CircularProgress />
            </div>
          ) : null}
        </DialogContent>
      </ResponsiveDialog>
      <ImportAccountDialog
        open={accountDialogOpen}
        onCancel={setAccountDialogOpenFalse}
        onOk={handleAccountDialogOk}
      />
    </>
  );
});
