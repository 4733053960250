export const REMOVED_SETTINGS_WHITELIST: string[] = [
  "canCreateLocations",
  "canEditLocations",
  "economicEnableMachineImport",
  "economicEnableUploadMachinesToDepartment",
  "economicEnableUploadMachinesToProjectEmployees",
  "logoPath",
  "menuLogoAlignment",
  "menuLogoPath",
  "showAddWorkplaceOnWorkplaceDialog",
  "projectButtonUnderCustomer",
  "unicontaInternalCostProject",
  "showMachineSalaryCostReport",
];
