import {Customer} from "@co-common-libs/resources";
import {isValidEmail} from "@co-common-libs/utils";
import {IntlShape} from "react-intl";
import {CustomerFormMember, CustomerFormPart} from "./types";

const checkInteger = /^\d+$/;

function customerNumberError(
  intl: IntlShape,
  requiredFields: ReadonlySet<CustomerFormMember>,
  customers: readonly Customer[],
  formCustomer: CustomerFormPart,
  editCustomer: Customer | null,
): string | null {
  if (!formCustomer.c5_account) {
    if (requiredFields.has(CustomerFormMember.CUSTOMER_NUMBER)) {
      return intl.formatMessage({
        defaultMessage: "Kunden kan ikke synkroniseres til e-conomic uden kundenummer",
      });
    } else {
      return null;
    }
  }

  if (!checkInteger.test(formCustomer.c5_account)) {
    return intl.formatMessage({
      defaultMessage: "Kundenummeret skal være et tal",
    });
  }

  const customerNumber = parseInt(formCustomer.c5_account);
  if (customerNumber != null && customerNumber < 1) {
    return intl.formatMessage({
      defaultMessage: "Kundenummeret skal være større end 0",
    });
  }
  const customerNumberString = customerNumber.toString();
  const customerNumberInUse =
    !!customerNumberString &&
    customers.some(
      // eslint-disable-next-line @typescript-eslint/naming-convention
      ({c5_account, url}) => c5_account === customerNumberString && url !== editCustomer?.url,
    );
  if (customerNumberInUse) {
    return intl.formatMessage({
      defaultMessage: "Der eksisterer allerede en kunde med det kundenummer",
    });
  }

  return null;
}

function billingEmailError(intl: IntlShape, customer: Partial<Customer>): string | null {
  if (!!customer.billingEmail && !isValidEmail(customer.billingEmail)) {
    return intl.formatMessage({
      defaultMessage: "Mailadressen er ikke gyldig",
    });
  }

  return null;
}

export function computeInputErrorText(
  intl: IntlShape,
  inputFields: ReadonlySet<CustomerFormMember>,
  requiredFields: ReadonlySet<CustomerFormMember>,
  customers: readonly Customer[],
  formCustomer: CustomerFormPart,
  editCustomer: Customer | null,
): ReadonlyMap<CustomerFormMember, string> {
  const errors = new Map<CustomerFormMember, string>();
  if (inputFields.has(CustomerFormMember.CUSTOMER_NUMBER)) {
    const customerNumberErrorMessage = customerNumberError(
      intl,
      requiredFields,
      customers,
      formCustomer,
      editCustomer,
    );
    if (customerNumberErrorMessage) {
      errors.set(CustomerFormMember.CUSTOMER_NUMBER, customerNumberErrorMessage);
    }
  }

  if (inputFields.has(CustomerFormMember.BILLING_EMAIL)) {
    const billingEmailErrorMessage = billingEmailError(intl, formCustomer);
    if (billingEmailErrorMessage) {
      errors.set(CustomerFormMember.BILLING_EMAIL, billingEmailErrorMessage);
    }
  }

  return errors;
}
