import _ from "lodash";

export function computeSums(
  taskEntries: readonly {
    readonly activity: string;
    readonly address: string;
    readonly completed: string | null;
    readonly notesFromTaskAssignee: string;
    readonly resultLines: readonly {
      readonly quantity: number | null;
      readonly resultDescription: string;
      readonly routeTaskResultURL: string;
      readonly unitString: string;
    }[];
    readonly routeTaskURL: string;
    readonly workplaceName: string;
  }[],
): Map<string, Map<string, {text: string; total: number; unitString: string}>> {
  const sums = new Map<string, Map<string, {text: string; total: number; unitString: string}>>();
  const precision = 3;
  for (let i = 0; i < taskEntries.length; i += 1) {
    const {resultLines} = taskEntries[i];
    for (let j = 0; j < resultLines.length; j += 1) {
      const {quantity, resultDescription, unitString} = resultLines[j];
      const unitKey = unitString.toLowerCase();
      const descriptionKey = resultDescription.toLowerCase();

      const unitSums = sums.get(unitKey);

      if (unitSums) {
        const existingEntry = unitSums.get(descriptionKey);
        if (existingEntry) {
          existingEntry.total += quantity || 0;
        } else {
          unitSums.set(descriptionKey, {
            text: resultDescription,
            total: quantity || 0,
            unitString,
          });
        }
      } else {
        sums.set(
          unitKey,
          new Map([
            [
              descriptionKey,
              {
                text: resultDescription,
                total: quantity || 0,
                unitString,
              },
            ],
          ]),
        );
      }
    }
  }
  sums.forEach((result) => {
    result.forEach((entry) => {
      entry.total = _.round(entry.total, precision);
    });
  });
  return sums;
}
