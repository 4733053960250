import {ImportPreviewAccount} from "@co-common-libs/resources-utils";
import {ImportAccountPreviewDialog} from "@co-frontend-libs/components";
import {jsonFetch, translateNetworkError} from "@co-frontend-libs/utils";
import {useAsyncAbortable} from "@react-hookz/web";
import {globalConfig} from "frontend-global-config";
import React, {useEffect} from "react";
import {useIntl} from "react-intl";

interface ImportAccountDialogProps {
  onCancel(): void;
  onOk(identifier: string): void;
  open: boolean;
}

export const ImportAccountDialog = React.memo(function ImportAccountDialog(
  props: ImportAccountDialogProps,
): React.JSX.Element {
  const {onOk, open} = props;

  const {baseURL} = globalConfig.resources;
  const [state, actions] = useAsyncAbortable<readonly ImportPreviewAccount[] | null>(
    (signal) =>
      jsonFetch(`${baseURL}economic/accounts/`).then(({data}) => (signal.aborted ? null : data)),
    null,
  );

  useEffect(() => {
    if (open) {
      actions.execute();
    } else {
      actions.reset();
    }
  }, [actions, open]);

  const intl = useIntl();

  return (
    <ImportAccountPreviewDialog
      {...props}
      error={state.status === "error" ? translateNetworkError(state.error, intl) : undefined}
      previewAccounts={state.result}
      onOk={onOk}
    />
  );
});
