import {ResponsiveDialog, TrimTextField} from "@co-frontend-libs/components";
import {DialogContent} from "@material-ui/core";
import React, {useCallback, useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";

interface TimerIdentifierDialogProps {
  identifier?: string;
  onCancel: () => void;
  onOk: (identifier: string, timerGroupURL?: string) => void;
  open: boolean;
  otherTimerIdentifiers?: ReadonlySet<string>;
}

export function TimerIdentifierDialog(props: TimerIdentifierDialogProps): JSX.Element {
  const {identifier: propsIdentifier, onCancel, onOk, open, otherTimerIdentifiers} = props;

  const [identifier, setIdentifier] = useState(propsIdentifier || "");
  useEffect(() => {
    if (open) {
      setIdentifier(propsIdentifier || "");
    }
  }, [propsIdentifier, open]);

  const handleOk = useCallback(() => {
    onOk(identifier);
  }, [onOk, identifier]);

  const identifierConflict =
    !!identifier && !!(otherTimerIdentifiers && otherTimerIdentifiers.has(identifier));
  const okDisabled = !identifier || identifierConflict;

  return (
    <ResponsiveDialog
      okDisabled={okDisabled}
      open={open}
      title={
        <FormattedMessage
          defaultMessage="Rediger timer id"
          id="timers.dialog-title.edit-timer-identifier"
        />
      }
      onCancel={onCancel}
      onOk={handleOk}
    >
      <DialogContent>
        <TrimTextField
          autoFocus
          fullWidth
          error={identifierConflict || !identifier}
          helperText={
            identifierConflict ? (
              <FormattedMessage defaultMessage="ID allerede i brug" />
            ) : !identifier ? (
              <FormattedMessage defaultMessage="Skal udfyldes" />
            ) : undefined
          }
          label={<FormattedMessage defaultMessage="ID" />}
          margin="dense"
          value={identifier}
          variant="outlined"
          onChange={setIdentifier}
        />
      </DialogContent>
    </ResponsiveDialog>
  );
}
