import {Command, MachineLastUsed, Task, instanceURL} from "@co-common-libs/resources";
import {v4 as uuid} from "uuid";
import {ProvisionaryCommand} from "../../resources/actions";
import {getCustomerSettings, getMachineLastUsedArray} from "../../resources/selectors";
import {ResourcesAuthenticationMiddlewareAPI} from "../types";
import {getBaseURL} from "./get-base-url";

export function updateMachineLastUsedBy(
  newTask: Task | null,
  oldTask: Task | undefined,
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  command: ProvisionaryCommand,
): {after?: Command[]; before?: Command[]} | null {
  if (!newTask) {
    return null;
  }
  if (!newTask.completed || oldTask?.completed) {
    // nothing to process
    return null;
  }

  const state = middlewareApi.getState();
  const customerSettings = getCustomerSettings(state);

  if (!customerSettings.showMachineLastUse) {
    return null;
  }

  const {machineOperator, machineuseSet, workToTimestamp, workType} = newTask;
  if (!machineuseSet || !machineuseSet.length || !machineOperator || !workToTimestamp) {
    return null;
  }

  const machineLastUsedArray = getMachineLastUsedArray(state);
  return {
    after: machineuseSet.map((machineUse) => {
      const existing = machineLastUsedArray.find(
        (lastUse) => lastUse.machine === machineUse.machine,
      );
      if (existing) {
        return {
          action: "UPDATE",
          patch: [
            {member: "user", value: machineOperator},
            {member: "date", value: workToTimestamp},
            {member: "workType", value: workType},
          ],
          url: existing.url,
        };
      } else {
        const id = uuid();
        const baseURL = getBaseURL(command.url);
        const url = instanceURL(baseURL, "machineLastUsed", id);
        const instance: MachineLastUsed = {
          date: workToTimestamp,
          id,
          machine: machineUse.machine,
          url,
          user: machineOperator,
          workType,
        };
        return {action: "CREATE", instance, url};
      }
    }),
  };
}
