import {Config} from "@co-common-libs/config";
import {
  Machine,
  PriceGroup,
  PriceGroupUrl,
  PriceItem,
  PriceItemUrl,
  Product,
  ProductGroup,
  ProductGroupUrl,
  ProductUrl,
  Unit,
  UnitUrl,
} from "@co-common-libs/resources";
import {getAutoProductsMapping, getUnitString} from "@co-common-libs/resources-utils";
import {IntlShape} from "react-intl";
import {InlinedTask} from "../../inline-data";
import {
  ProductUseWithData,
  makeSortedProductUsesWithData,
} from "../../make-sorted-product-uses-with-data";
import {EDIT_LOG_ACTION, ErrorEntry} from "../types";
import {getOptionalRequiredProductUseNotFoundError} from "./get-optional-required-product-use-not-found-error";
import {getPriceItemIssues} from "./get-price-item-issues";
import {getRequiredProductUseNotFoundError} from "./get-required-product-use-not-found-error";

export const getLogIssues = (
  data: {
    machineArray: readonly Machine[];
    priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
    priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined;
    productGroupLookup: (url: ProductGroupUrl) => ProductGroup | undefined;
    productLookup: (url: ProductUrl) => Product | undefined;
    productsWithLogData: Set<ProductUrl> | undefined;
    readonlyProducts: Set<string>;
    task: Pick<
      InlinedTask,
      | "completed"
      | "logSkipped"
      | "machineuseSet"
      | "order"
      | "priceGroup"
      | "priceitemuseSet"
      | "productUses"
      | "productuseSet"
      | "reportingSpecification"
      | "workType"
    >;
    unitLookup: (url: UnitUrl) => Unit | undefined;
  },
  customerOptions: Config,
  intl: IntlShape,
  // switched on for work type?
  requireAtLeastOneOptionalPriceItemUseGreaterThanZero = false,
  // URLs of price groups (from both work type and machines) with option turned
  // on as keys and optional price item URLs as values
  requireAtLeastOneOptionalPriceItemUseGreaterThanZeroItemsByGroup: ReadonlyMap<
    PriceGroupUrl,
    readonly PriceItemUrl[]
  >,
  logCheck: boolean = false,
): ErrorEntry | null => {
  const {
    machineArray,
    priceGroupLookup,
    priceItemLookup,
    productGroupLookup,
    productLookup,
    productsWithLogData,
    readonlyProducts,
    task,
    unitLookup,
  } = data;
  const productUseList = task.productuseSet;
  const {productUses} = task;

  let sortedProductUsesWithData: ProductUseWithData[] = [];
  if (productUses) {
    const {autoLinesToLines} = getAutoProductsMapping(
      productUses,
      productLookup,
      productGroupLookup,
      customerOptions,
    );

    let autoProductUses: Set<string> | null = null;
    if (autoLinesToLines) {
      autoProductUses = new Set(autoLinesToLines.keys());
    }

    sortedProductUsesWithData = makeSortedProductUsesWithData(
      productUses,
      productLookup,
      unitLookup,
      autoProductUses,
      undefined,
      readonlyProducts,
      customerOptions,
      productsWithLogData,
      undefined,
    );
  }

  const logErrors = getPriceItemIssues(
    customerOptions,
    undefined,
    intl,
    machineArray,
    undefined,
    priceGroupLookup,
    priceItemLookup,
    requireAtLeastOneOptionalPriceItemUseGreaterThanZero,
    requireAtLeastOneOptionalPriceItemUseGreaterThanZeroItemsByGroup,
    task,
    unitLookup,
    logCheck,
  ).logIssues;

  if (task.workType && task.order) {
    const {logSkipped, priceGroup, reportingSpecification, workType} = task;
    const noProductUsesOrAtLeastOneProductUseGreaterThanZero =
      priceGroup?.noProductUsesOrAtLeastOneProductUseGreaterThanZero ??
      workType?.noProductUsesOrAtLeastOneProductUseGreaterThanZero;
    const requireAtLeastOneProductUseGreaterThanZero =
      priceGroup?.requireAtLeastOneProductUseGreaterThanZero ??
      workType?.requireAtLeastOneProductUseGreaterThanZero;
    const logProducts = !!(
      reportingSpecification?.workplaceData?.pickup?.logProducts ||
      reportingSpecification?.workplaceData?.workplace?.logProducts ||
      reportingSpecification?.workplaceData?.delivery?.logProducts
    );
    const productsInLog = logProducts && !logSkipped;

    if (
      !logCheck &&
      productUseList.length &&
      !noProductUsesOrAtLeastOneProductUseGreaterThanZero &&
      !requireAtLeastOneProductUseGreaterThanZero &&
      customerOptions.navSyncProfile !== "hvt" &&
      reportingSpecification &&
      productsInLog
    ) {
      productUseList.forEach((instance) => {
        if (instance.count === null) {
          const productUse = sortedProductUsesWithData.find(
            (productUseWithData) => productUseWithData.productUse.product === instance.product?.url,
          );

          if (productUse && productUse.editable) {
            return;
          }

          const {ours} = instance;
          const {product} = instance;
          let productString = "";
          let unitString = "";
          if (product) {
            productString = `${product.name} (${product.catalogNumber})`;
            unitString = getUnitString(product, unitLookup);
          }
          const error = `${productString}: ? ${unitString}${
            customerOptions.showOursToggle
              ? ` (${
                  ours
                    ? intl.formatMessage({defaultMessage: "vores"})
                    : intl.formatMessage({defaultMessage: "deres"})
                })`
              : ""
          }`;
          logErrors.push(error);
        }
      });
    }

    const requiredProductUseNotFoundError = getRequiredProductUseNotFoundError(
      customerOptions,
      intl,
      task,
      workType,
      requireAtLeastOneProductUseGreaterThanZero,
    );

    if (requiredProductUseNotFoundError && productsInLog) {
      logErrors.push(requiredProductUseNotFoundError);
    }

    if (!logCheck) {
      const requiredMaterialNotFoundError = getOptionalRequiredProductUseNotFoundError(
        customerOptions,
        intl,
        task,
        workType,
        noProductUsesOrAtLeastOneProductUseGreaterThanZero,
      );

      if (requiredMaterialNotFoundError && productsInLog) {
        logErrors.push(requiredMaterialNotFoundError);
      }
    }
  }
  if (logErrors.length) {
    const logErrorHeader = intl.formatMessage({
      defaultMessage: "Der mangler at blive udfyldt følgende i loggen:",
    });
    return {
      action: EDIT_LOG_ACTION,
      children: logErrors,
      text: logErrorHeader,
    };
  } else {
    return null;
  }
};
