import {Config} from "@co-common-libs/config";
import {
  PatchOperation,
  PriceItem,
  PriceItemUrl,
  PriceItemUsesDict,
  Product,
  ProductUrl,
  ProductUseWithOrder,
  ProductUsesDict,
  ReportingInputSpecification,
  ReportingSpecification,
  Task,
  Unit,
  UnitUrl,
  UserUrl,
} from "@co-common-libs/resources";
import {identifierComparator} from "@co-common-libs/utils";
import _ from "lodash";
import {v4 as uuid} from "uuid";
import {updateProductUseEntriesOrder} from "../materials-sorting";
import {patchFromProductUsesChange} from "../task";
import {filterProductUsesFromLog} from "./filter-product-uses-from-log";
import {getPriceItemUsesCountsFromLogEntries} from "./get-price-item-uses-counts-from-log-entries";
import {getProductUseCountsFromLogEntries} from "./get-product-use-counts-from-log-entries";

export const getMaterialUsePatchFromLogEntries = (
  logSpecification: ReportingSpecification,
  customerSettings: Config,
  task: Task,
  priceItemUsesFromPatch: PriceItemUsesDict | undefined,
  inputSpecificationsMap: Map<string, ReportingInputSpecification>,
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined,
  unitLookup: (url: UnitUrl) => Unit | undefined,
  productLookup: (url: ProductUrl) => Readonly<Product> | undefined,
  currentUserURL: UserUrl | null,
): PatchOperation<Task>[] => {
  const newPriceItemUsesCounts = getPriceItemUsesCountsFromLogEntries(
    logSpecification,
    customerSettings,
    task,
    priceItemUsesFromPatch,
    inputSpecificationsMap,
    priceItemLookup,
    unitLookup,
  );
  const newProductUsesCounts = getProductUseCountsFromLogEntries(logSpecification, task);

  const patch: PatchOperation<Task>[] = [];

  // compute combined result from `transfer` and `logPriceItems`,
  // then apply resulting changes...
  if (newPriceItemUsesCounts && newPriceItemUsesCounts.size) {
    newPriceItemUsesCounts.forEach((count, identifier) => {
      if (count !== task.priceItemUses?.[identifier]?.count) {
        patch.push({
          path: ["priceItemUses", identifier, "count"],
          value: count,
        });
      }
    });
  }

  let newProductUses: ProductUsesDict | undefined;

  if (newProductUsesCounts != null) {
    const originalProductUses = Object.entries(task.productUses || {});

    const filteredProductUses = filterProductUsesFromLog(
      originalProductUses,
      newProductUsesCounts,
      ([_id, data]) => data.product,
    );

    const remainingProductUsesFromLogCounts = new Map(newProductUsesCounts);

    // NOTE: has side effect on remainingProductUsesFromLogCounts...
    const filteredProductUsesWithValues = filteredProductUses.map(
      ([id, data]): [string, ProductUseWithOrder] => {
        const url = data.product;
        const key = `${url}\0${data.ours}`;
        const value = remainingProductUsesFromLogCounts.get(key);
        if (value !== undefined) {
          // found
          remainingProductUsesFromLogCounts.delete(key);
          return [id, {...data, count: value}];
        }
        const otherKey = `${url}\0${!data.ours}`;
        const otherValue = remainingProductUsesFromLogCounts.get(otherKey);
        if (otherValue !== undefined) {
          // found for different ours
          remainingProductUsesFromLogCounts.delete(otherKey);
          return [
            id,
            {
              ...data,
              count: otherValue,
              ours: !data.ours,
            },
          ];
        }
        // not found
        return [id, {...data, count: null}];
      },
    );

    if (remainingProductUsesFromLogCounts.size === 0) {
      newProductUses = Object.fromEntries(filteredProductUsesWithValues);
    } else {
      const existingWithMaxOrder = _.maxBy(
        filteredProductUsesWithValues,
        ([_id, data]) => data.order,
      );
      const firstNewOrder = existingWithMaxOrder ? existingWithMaxOrder[1].order + 1 : 0;

      const newEntries = Array.from(remainingProductUsesFromLogCounts).map(
        ([productOurs, value], index): [string, ProductUseWithOrder] => {
          const [productUrl, oursString] = productOurs.split("\0");
          const ours = oursString === "true";
          return [
            uuid(),
            {
              addedBy: currentUserURL,
              correctedCount: null,
              count: value,
              notes: "",
              order: firstNewOrder + index,
              ours,
              product: productUrl as ProductUrl,
            },
          ];
        },
      );

      const sortedWithNewEntries = filteredProductUsesWithValues
        .concat(newEntries)
        .sort(([_aIdentifier, aProductUse], [_bIdentifier, bProductUse]) => {
          const aProduct = productLookup(aProductUse.product);
          const bProduct = productLookup(bProductUse.product);
          return identifierComparator(
            aProduct ? aProduct.catalogNumber : "",
            bProduct ? bProduct.catalogNumber : "",
          );
        });

      updateProductUseEntriesOrder(sortedWithNewEntries);
      newProductUses = Object.fromEntries(sortedWithNewEntries);
    }
  }

  if (newProductUses) {
    const existingProductPresenceCounts = new Map<ProductUrl, number>();
    for (const {product} of task.productUses ? Object.values(task.productUses) : []) {
      existingProductPresenceCounts.set(
        product,
        (existingProductPresenceCounts.get(product) || 0) + 1,
      );
    }
    const productUsePatch = patchFromProductUsesChange(task.productUses || {}, newProductUses);
    for (const patchOperation of productUsePatch) {
      if (patchOperation.path.length !== 2 || patchOperation.value !== undefined) {
        // not a productUse entry removal
        patch.push(patchOperation);
        continue;
      }
      // *is* a productUse entry removal
      // should normally be ignored, except for edge case where we have several
      // for the same product (due to ours/theirs variants)
      const [productUses, identifier] = patchOperation.path;
      console.assert(
        productUses === "productUses",
        `patch entry path start not productUses; was ${productUses}`,
      );
      const productUseEntry = task.productUses?.[identifier];
      if (productUseEntry) {
        const count = existingProductPresenceCounts.get(productUseEntry.product);
        if (count && count > 1) {
          // more than one present, so allow removal and decrement...
          patch.push(patchOperation);
          existingProductPresenceCounts.set(productUseEntry.product, count - 1);
        }
      }
    }
  }
  return patch;
};
