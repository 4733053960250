import keycode from "keycode";

// List from https://facebook.github.io/react/docs/events.html#syntheticevent
// "Every SyntheticEvent object has the following attributes:""
const properties = [
  "bubbles",
  "cancelable",
  "currentTarget",
  "defaultPrevented",
  "eventPhase",
  "isTrusted",
  "nativeEvent",
  "preventDefault",
  "isDefaultPrevented",
  "stopPropagation",
  "isPropagationStopped",
  "target",
  "timeStamp",
  "type",
];

export const isReactEvent = (obj: unknown): boolean => {
  if (typeof obj !== "object" || !obj) {
    return false;
  }
  for (let i = 0; i < properties.length; i += 1) {
    if (!(properties[i] in obj)) {
      return false;
    }
  }
  return true;
};

export const stopPropagationGetValue = (inputEvent: unknown, inputValue: unknown): any => {
  // MUI usually provide (event, value), but some parts of MUI (at 0.15.0)
  // still use the old (value, event), for compatibility outside MUI, we
  // should handle getting just the event, and for convenience otherwise, we
  // should handle the event not being present...
  let event = inputEvent;
  let value = inputValue;
  if (isReactEvent(value)) {
    const x = value;
    value = event;
    event = x;
  } else if (value === undefined) {
    ({value} = (event as any).target);
  }
  if (isReactEvent(event)) {
    // Avoid propagating onChange; it breks when the UI when applied to an outer
    // <Tabs>-component, and doesn't actually benefit us otherwise...
    (event as any).stopPropagation();
    // We do *no* want preventDefault, though --- that breaks the behavior of
    // MUI toggle/checkbox...
    // event.preventDefault();
  }
  return value;
};

export function isNoDefaultEnterPress(event: KeyboardEvent): boolean {
  if (keycode(event) === "enter") {
    const {target} = event;
    const targetType: string | undefined = target ? (target as any).type : undefined;
    return (
      targetType !== undefined &&
      targetType !== "button" &&
      targetType !== "textarea" &&
      (target as HTMLElement)?.getAttribute("aria-autocomplete") !== "list"
    );
  }
  return false;
}
