import {Config} from "@co-common-libs/config";
import {
  CsvImportColumnSpecificationValidatePart,
  CsvImportColumnSpecificationValidateTypePart,
  NormalCsvImportColumnSpecification,
  StringCsvImportColumnSpecification,
} from "@co-common-libs/csv-import-specifications";
import {Customer, LocationType, Unit} from "@co-common-libs/resources";
import {notNull} from "@co-common-libs/utils";
import {format, parse} from "date-fns";
import {z} from "zod";

export type ValueParseError = "REQIRED_MISSING" | "UNKNOWN_REFERENCE" | "WRONG_FORMAT";

export type ValueParseResult<T> =
  | {readonly data: T; readonly success: true}
  | {readonly error: ValueParseError; readonly success: false};

const requiredMissingError = {
  error: "REQIRED_MISSING",
  success: false,
} as const satisfies ValueParseResult<unknown>;

const wrongFormatError = {
  error: "WRONG_FORMAT",
  success: false,
} as const satisfies ValueParseResult<unknown>;

const unknownReferenceError = {
  error: "UNKNOWN_REFERENCE",
  success: false,
} as const satisfies ValueParseResult<unknown>;

function makeSuccess<T>(value: T): ValueParseResult<T> {
  return {data: value, success: true};
}

const nullValueResult = makeSuccess(null);

const emptyStringResult = makeSuccess("");

export function parseValidateString(value: string, required: boolean): ValueParseResult<string> {
  if (required && value === "") {
    return requiredMissingError;
  } else {
    return {data: value, success: true};
  }
}

function handleBlank(required: boolean): ValueParseResult<null> {
  if (required) {
    return requiredMissingError;
  } else {
    return nullValueResult;
  }
}

function handleBlankString(required: boolean): ValueParseResult<string> {
  if (required) {
    return requiredMissingError;
  } else {
    return emptyStringResult;
  }
}

export function parseValidateInteger(
  value: string,
  required: boolean,
): ValueParseResult<number | null> {
  if (value === "") {
    return handleBlank(required);
  }
  if (
    z
      .string()
      .regex(/^-?\d+$/)
      .safeParse(value).success
  ) {
    return makeSuccess(parseInt(value));
  } else {
    return wrongFormatError;
  }
}

export function parseValidateDecimal(
  value: string,
  required: boolean,
): ValueParseResult<number | null> {
  if (value === "") {
    return handleBlank(required);
  }
  if (
    z
      .string()
      .regex(/^-?\d+([,.]\d*)?$/)
      .safeParse(value).success
  ) {
    return makeSuccess(parseFloat(value.replace(",", ".")));
  } else {
    return wrongFormatError;
  }
}

export function parseValidateBoolean(
  value: string,
  required: boolean,
): ValueParseResult<boolean | null> {
  if (value === "") {
    return handleBlank(required);
  }

  if (
    z
      .string()
      .regex(/^(0|false|f|falsk|1|true|t|s|sand|sandt)$/)
      .safeParse(value).success
  ) {
    const result = !["0", "false", "f", "falsk"].includes(value);
    return makeSuccess(result);
  } else {
    return wrongFormatError;
  }
}

const DATE_FORMAT = "dd.MM.yyyy";

export function parseValidateDate(
  value: string,
  required: boolean,
): ValueParseResult<string | null> {
  if (value === "") {
    return handleBlank(required);
  }

  if (
    z
      .string()
      .regex(/^\d{2}\.\d{2}\.\d{4}$/)
      .safeParse(value).success
  ) {
    try {
      const date = parse(value, DATE_FORMAT, new Date());
      if (date && format(date, DATE_FORMAT) === value) {
        return makeSuccess(value);
      }
    } catch {
      return wrongFormatError;
    }
  }
  return wrongFormatError;
}

export function parseValidateColor(
  value: string,
  required: boolean,
): ValueParseResult<string | null> {
  if (value === "") {
    return handleBlankString(required);
  }
  if (
    z
      .string()
      .regex(/^#?([a-fA-F0-9]{6})$/)
      .safeParse(value).success
  ) {
    const ensuredHashTaggedValue = value.startsWith("#") ? value : `#${value}`;
    return {data: ensuredHashTaggedValue, success: true};
  } else {
    return wrongFormatError;
  }
}

export function parseValidateCvr(
  value: string,
  required: boolean,
): ValueParseResult<string | null> {
  if (value === "") {
    return handleBlankString(required);
  }
  if (
    z
      .string()
      .regex(/^\d{8}$/)
      .safeParse(value).success
  ) {
    return {data: value, success: true};
  } else {
    return wrongFormatError;
  }
}

export function parseValidateEmail(
  value: string,
  required: boolean,
): ValueParseResult<string | null> {
  if (value === "") {
    return handleBlankString(required);
  }
  if (z.string().email().safeParse(value).success) {
    return {data: value, success: true};
  } else {
    return wrongFormatError;
  }
}

export function getParseValidateChoiceFunction(
  choices: readonly string[],
): (value: string, required: boolean) => ValueParseResult<string | null> {
  function parseValidateChoiceFunction(
    value: string,
    required: boolean,
  ): ValueParseResult<string | null> {
    if (value === "") {
      return handleBlank(required);
    }
    if (choices.includes(value)) {
      return makeSuccess(value);
    } else {
      return wrongFormatError;
    }
  }
  return parseValidateChoiceFunction;
}

export interface ValidationData {
  readonly config: Config;
  readonly customers: readonly Customer[];
  readonly locationTypes: readonly LocationType[];
  readonly units: readonly Unit[];
}

function getParseValidateLegalStringSetFunction(
  legalValues: ReadonlySet<string>,
): (value: string, required: boolean) => ValueParseResult<string | null> {
  function parseValidateLegalStringSetFunction(
    value: string,
    required: boolean,
  ): ValueParseResult<string | null> {
    if (value === "") {
      return handleBlankString(required);
    }
    if (legalValues.has(value)) {
      return makeSuccess(value);
    } else {
      return unknownReferenceError;
    }
  }
  return parseValidateLegalStringSetFunction;
}

function getParseValidateRemunerationGroupIdentifierFunction(
  data: ValidationData,
): (value: string, required: boolean) => ValueParseResult<string | null> {
  const remunerationGroupIdentifiers = new Set(Object.keys(data.config.remunerationGroups));
  return getParseValidateLegalStringSetFunction(remunerationGroupIdentifiers);
}

function getParseValidateLocationTypeIdentifierFunction(
  data: ValidationData,
): (value: string, required: boolean) => ValueParseResult<string | null> {
  const locationTypeIdentifiers = new Set(data.locationTypes.map(({identifier}) => identifier));
  return getParseValidateLegalStringSetFunction(locationTypeIdentifiers);
}

function getParseValidateCustomerNumberFunction(
  data: ValidationData,
): (value: string, required: boolean) => ValueParseResult<string | null> {
  const customerNumbers = new Set(
    data.customers
      // eslint-disable-next-line @typescript-eslint/naming-convention
      .map(({c5_account}) => c5_account)
      .filter(notNull),
  );
  return getParseValidateLegalStringSetFunction(customerNumbers);
}

function getParseValidateUnitNameFunction(
  data: ValidationData,
): (value: string, required: boolean) => ValueParseResult<string | null> {
  const unitNames = new Set(data.units.map(({name}) => name));
  return getParseValidateLegalStringSetFunction(unitNames);
}

type StringReferencesAlternative = Exclude<
  StringCsvImportColumnSpecification["references"],
  undefined
>;

const referenceParseValidateFunctions = {
  customerNumber: getParseValidateCustomerNumberFunction,
  locationTypeIdentifier: getParseValidateLocationTypeIdentifierFunction,
  remunerationGroupIdentifier: getParseValidateRemunerationGroupIdentifierFunction,
  unitName: getParseValidateUnitNameFunction,
} as const satisfies {
  [key in StringReferencesAlternative]: (
    data: ValidationData,
  ) => (value: string, required: boolean) => ValueParseResult<unknown>;
};

function getParseValidateStringFunction(
  references: StringReferencesAlternative | undefined,
  data: ValidationData,
): (value: string, required: boolean) => ValueParseResult<string | null> {
  if (!references) {
    return parseValidateString;
  }
  return referenceParseValidateFunctions[references](data);
}

const normalParseValidateFunctions = {
  boolean: parseValidateBoolean,
  color: parseValidateColor,
  cvr: parseValidateCvr,
  date: parseValidateDate,
  decimal: parseValidateDecimal,
  email: parseValidateEmail,
  integer: parseValidateInteger,
} as const satisfies {
  [key in NormalCsvImportColumnSpecification["type"]]: (
    value: string,
    required: boolean,
  ) => ValueParseResult<unknown>;
};

export function getParseValidateFunction(
  column: CsvImportColumnSpecificationValidateTypePart,
  data: ValidationData,
): (value: string, required: boolean) => ValueParseResult<boolean | number | string | null> {
  if (column.type === "choices") {
    return getParseValidateChoiceFunction(column.choices);
  } else if (column.type === "string") {
    return getParseValidateStringFunction(column.references, data);
  } else {
    return normalParseValidateFunctions[column.type];
  }
}

export function runValidation(
  column: CsvImportColumnSpecificationValidatePart,
  values: readonly string[],
  data: ValidationData,
): {error: ValueParseError; errorIndex: number}[] {
  const errors: {error: ValueParseError; errorIndex: number}[] = [];

  const parseValidateFunction = getParseValidateFunction(column, data);

  values.forEach((value, index) => {
    const result = parseValidateFunction(value, column.required);
    if (!result.success) {
      errors.push({error: result.error, errorIndex: index});
    }
  });
  return errors;
}
