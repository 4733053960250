import {DateField, ResponsiveDialog} from "@co-frontend-libs/components";
import {DialogContent} from "@material-ui/core";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import React from "react";
// Allowed for existing code...
// eslint-disable-next-line deprecate/import
import {Cell, Grid} from "react-flexr";
import {IntlContext, defineMessages} from "react-intl";

const messages = defineMessages({
  cancel: {
    defaultMessage: "Fortryd",
    id: "dialog.label.cancel",
  },
  ok: {
    defaultMessage: "OK",
    id: "dialog.label.ok",
  },
  periodEnd: {
    defaultMessage: "Til",
    id: "bookkeeping.label.period-end",
  },
  periodStart: {
    defaultMessage: "Fra",
    id: "bookkeeping.label.period-start",
  },
});

interface PeriodDialogProps {
  onCancel: () => void;
  onOk: (period: {periodEnd: string; periodStart: string}) => void;
  open: boolean;
  title: string;
}

interface PeriodDialogState {
  periodEnd: string | null;
  periodStart: string | null;
}

export class PeriodDialog extends PureComponent<PeriodDialogProps, PeriodDialogState> {
  state: PeriodDialogState = {
    periodEnd: null,
    periodStart: null,
  };
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  @bind
  handlePeriodStartChange(newValue: string | null): void {
    this.setState({periodStart: newValue});
  }
  @bind
  handlePeriodEndChange(newValue: string | null): void {
    this.setState({periodEnd: newValue});
  }
  @bind
  handleOk(): void {
    const {periodEnd, periodStart} = this.state;
    if (periodStart && periodEnd) {
      this.setState({
        periodEnd: null,
        periodStart: null,
      });
      this.props.onOk({periodEnd, periodStart});
    }
  }
  @bind
  handleRequestClose(): void {
    this.setState({
      periodEnd: null,
      periodStart: null,
    });
    this.props.onCancel();
  }
  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {open, title} = this.props;
    const {periodEnd, periodStart} = this.state;
    const okDisabled = !periodStart || !periodEnd || periodStart > periodEnd;
    return (
      <ResponsiveDialog
        okDisabled={okDisabled}
        open={open}
        title={title}
        onCancel={this.handleRequestClose}
        onOk={this.handleOk}
      >
        <DialogContent>
          <Grid>
            <Cell palm="12/12">
              <DateField
                autoFocus
                autoOk
                fullWidth
                label={formatMessage(messages.periodStart)}
                margin="dense"
                value={periodStart}
                onChange={this.handlePeriodStartChange}
              />
            </Cell>
            <Cell palm="12/12">
              <DateField
                autoOk
                fullWidth
                label={formatMessage(messages.periodEnd)}
                margin="dense"
                value={periodEnd}
                onChange={this.handlePeriodEndChange}
              />
            </Cell>
          </Grid>
        </DialogContent>
      </ResponsiveDialog>
    );
  }
}
