import {combineReducers} from "@reduxjs/toolkit";
import {reducer as authenticationReducer} from "./authentication/reducer";
import {reducer as connectivityReducer} from "./connectivity/reducer";
import {reducer as deviceConfigReducer} from "./device-config/reducer";
import {reducer as deviceReducer} from "./device/reducer";
import {reducer as dialogReducer} from "./notification-dialog/reducer";
import {reducer as resourcesReducer} from "./resources/reducer";
import {routingReducer} from "./routing/reducer";
import {reducer as snackbarReducer} from "./snackbar/reducer";
import {reducer as updatesReducer} from "./updates/reducer";

export const rootReducer = combineReducers({
  authentication: authenticationReducer,
  connectivity: connectivityReducer,
  device: deviceReducer,
  deviceConfig: deviceConfigReducer,
  notificationDialog: dialogReducer,
  resources: resourcesReducer,
  routing: routingReducer,
  snackbar: snackbarReducer,
  updates: updatesReducer,
});

export type AppState = ReturnType<typeof rootReducer>;
