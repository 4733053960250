import {
  CustomerUrl,
  FuelSurchargePricePercentInvoiceData,
  PriceItem,
  PriceItemUrl,
  PriceItemUse,
  PricePercentDefaultFuelSurchargeUse,
  PricePercentFuelSurchargeBasis,
  PricePercentFuelSurchargeSpecification,
  PricePercentFuelSurchargeSpecificationEntry,
  PricePercentFuelSurchargeSpecificationUrl,
  PricePercentMachineFuelSurchargeUse,
  PricePercentWorkTypeFuelSurchargeUse,
  Product,
  ProductGroup,
  ProductGroupUrl,
  ProductUrl,
  ProductUse,
  Task,
} from "@co-common-libs/resources";
import {dateToString} from "@co-common-libs/utils";
import {IntlShape} from "@formatjs/intl";
import {checkPriceItemProductUseFuelSurcharge} from "./check-fuel-surcharge";
import {formatFuelSurchargePricePercentInvoiceData} from "./format-fuel-surcharge-price-percent-invoice-data";
import {getPriceItemProductUseMatchesSpecificationEntries} from "./get-fuel-surcharge-specification-entries";
import {getPriceItemUseMatches} from "./get-price-item-use-matches";
import {getProductUseMatches} from "./get-product-use-matches";

type TaskPart = Pick<Task, "priceGroup" | "workType"> & {
  readonly priceItemUses: {
    readonly [identifier: string]: Pick<
      PriceItemUse,
      "dangling" | "machine" | "priceGroup" | "priceItem" | "workType"
    >;
  };
  readonly productUses: {
    readonly [identifier: string]: Pick<ProductUse, "product">;
  };
  readonly workFromTimestamp: NonNullable<Task["workFromTimestamp"]>;
};

type PriceItemPart = Pick<PriceItem, "contributesToFuelSurchargeSalesPrice">;

type ProductPart = Pick<Product, "group" | "name">;

type ProductGroupPart = Pick<ProductGroup, "contributesToFuelSurchargeSalesPrice">;

type PricePercentFuelSurchargeSpecificationPart = Pick<
  PricePercentFuelSurchargeSpecification,
  | "active"
  | "conversionFactor"
  | "invoiceLineProduct"
  | "invoiceLineText"
  | "name"
  | "url"
  | "zeroSurchargeInvoiceLines"
>;
type PricePercentMachineFuelSurchargeUsePart = Pick<
  PricePercentMachineFuelSurchargeUse,
  "customer" | "fuelSurcharge" | "machine" | "variant"
>;
type PricePercentWorkTypeFuelSurchargeUsePart = Pick<
  PricePercentWorkTypeFuelSurchargeUse,
  "customer" | "fuelSurcharge" | "variant" | "workType"
>;
type PricePercentDefaultFuelSurchargeUsePart = Pick<
  PricePercentDefaultFuelSurchargeUse,
  "customer" | "fuelSurcharge"
>;
type PricePercentFuelSurchargeSpecificationEntryPart = Pick<
  PricePercentFuelSurchargeSpecificationEntry,
  "fromDate" | "priceKrPerLiter" | "specification" | "toDate"
>;

type PricePercentFuelSurchargeBasisPart = Pick<
  PricePercentFuelSurchargeBasis,
  | "basePriceKrPerLiter"
  | "fromDate"
  | "fuelCostSharePercent"
  | "priceKrPerLiterIncreaseThreshold"
  | "specification"
  | "truncateNegativeToZero"
>;

export function getFuelSurchargePricePercentInvoiceData<
  Specification extends PricePercentFuelSurchargeSpecificationPart,
  MachineFuelSurchargeUse extends PricePercentMachineFuelSurchargeUsePart,
  WorkTypeFuelSurchargeUse extends PricePercentWorkTypeFuelSurchargeUsePart,
  DefaultFuelSurchargeUse extends PricePercentDefaultFuelSurchargeUsePart,
  Entry extends PricePercentFuelSurchargeSpecificationEntryPart,
>(
  intl: Pick<IntlShape, "formatMessage">,
  priceItemLookup: (url: PriceItemUrl) => PriceItemPart | undefined,
  productLookup: (url: ProductUrl) => ProductPart | undefined,
  productGroupLookup: (url: ProductGroupUrl) => ProductGroupPart | undefined,
  fuelSurchargeSpecificationLookup: (
    url: PricePercentFuelSurchargeSpecificationUrl,
  ) => Specification | undefined,
  machineFuelSurchargeUseArray: readonly MachineFuelSurchargeUse[],
  workTypeFuelSurchargeUseArray: readonly WorkTypeFuelSurchargeUse[],
  defaultFuelSurchargeUseArray: readonly DefaultFuelSurchargeUse[],
  entryArray: readonly Entry[],
  basisArray: readonly PricePercentFuelSurchargeBasisPart[],
  task: TaskPart,
  customerUrl: CustomerUrl | null,
): {
  issues: Map<
    string,
    {
      specification: Specification;
      use: DefaultFuelSurchargeUse | MachineFuelSurchargeUse | WorkTypeFuelSurchargeUse;
    }
  >;
  pricePercent: FuelSurchargePricePercentInvoiceData;
} {
  const taskDate = dateToString(new Date(task.workFromTimestamp));
  const priceItemUseMatches = getPriceItemUseMatches(
    task,
    customerUrl,
    priceItemLookup,
    fuelSurchargeSpecificationLookup,
    machineFuelSurchargeUseArray,
    workTypeFuelSurchargeUseArray,
    defaultFuelSurchargeUseArray,
  );
  const productUseMatches = getProductUseMatches(
    task,
    customerUrl,
    productLookup,
    productGroupLookup,
    fuelSurchargeSpecificationLookup,
    workTypeFuelSurchargeUseArray,
    defaultFuelSurchargeUseArray,
  );

  const matches = new Map<
    PriceItemUrl | ProductUrl,
    {
      specification: Specification | null;
      use: DefaultFuelSurchargeUse | MachineFuelSurchargeUse | WorkTypeFuelSurchargeUse;
    }
  >();
  priceItemUseMatches.forEach((v, k) => {
    matches.set(k, v);
  });
  productUseMatches.forEach((v, k) => {
    matches.set(k, v);
  });
  const matchesWithEntries = getPriceItemProductUseMatchesSpecificationEntries(
    entryArray,
    taskDate,
    matches,
  );

  const {issues, withFuelSurcharge, withoutFuelSurcharge} =
    checkPriceItemProductUseFuelSurcharge(matchesWithEntries);

  const pricePercent = formatFuelSurchargePricePercentInvoiceData(
    intl,
    productLookup,
    basisArray,
    taskDate,
    withFuelSurcharge,
    withoutFuelSurcharge,
  );

  return {issues, pricePercent};
}
