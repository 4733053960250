import {
  Customer,
  CustomerUrl,
  Order,
  OrderUrl,
  Task,
  UserProfile,
  UserUrl,
  WorkType,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {dateFromString} from "@co-common-libs/utils";
import {PathTemplate} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {grey} from "@material-ui/core/colors";
import {PureComponent} from "app-utils";
import _ from "lodash";
import React from "react";
import {DAY_HEIGHT, DAY_WIDTH} from "./constants";
import {TaskBlock} from "./task-block";

interface ColumnProps {
  contentElementCount: number;
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  date: string;
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  machineTaskMap: ReadonlyMap<string, readonly Task[]>;
  machineTaskOffsetMap: ReadonlyMap<string, number>;
  orderLookup: (url: OrderUrl) => Order | undefined;
  userUserProfileLookup: (url: UserUrl) => UserProfile | undefined;
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
}

export class Column extends PureComponent<ColumnProps> {
  render(): JSX.Element {
    const {
      contentElementCount,
      customerLookup,
      date,
      machineTaskMap,
      machineTaskOffsetMap,
      orderLookup,
      userUserProfileLookup,
      workTypeLookup,
    } = this.props;
    const backgroundColor = (dateFromString(date) as Date).getDate() % 2 ? grey[100] : grey[200];
    const style: React.CSSProperties = {
      backgroundColor,
      display: "inline-block",
      height: contentElementCount * DAY_HEIGHT,
      position: "relative",
      width: DAY_WIDTH,
    };
    const taskBlocks: JSX.Element[] = [];
    const afterTimestampString = "X";
    machineTaskMap.forEach((taskList, machineURL) => {
      const baseOffset = machineTaskOffsetMap.get(machineURL) || 0;
      const sortedTaskList = _.sortBy(
        taskList,
        (task) => task.workFromTimestamp || afterTimestampString,
      );
      sortedTaskList.forEach((task, index) => {
        const offset = baseOffset + index;
        const orderURL = task.order;
        const order = orderURL ? orderLookup(orderURL) : undefined;
        const customerURL = order && order.customer;
        const customer = customerURL ? customerLookup(customerURL) : undefined;
        const userURL = task.machineOperator;
        const userProfile = userURL ? userUserProfileLookup(userURL) : undefined;
        const workTypeURL = task.workType;
        const workType = workTypeURL ? workTypeLookup(workTypeURL) : undefined;
        taskBlocks.push(
          <TaskBlock
            key={`${machineURL}-${task.url}`}
            customer={customer}
            date={date}
            go={this.props.go}
            offset={offset}
            order={order}
            task={task}
            userProfile={userProfile}
            workType={workType}
          />,
        );
      });
    });
    return <div style={style}>{taskBlocks}</div>;
  }
}
