/** Create a new object combining the properties of the parameters.
 * @param base Object with properties to potentially be overridden.
 * @param change Object with overriding properties.
 */
export function objectMerge<T extends object>(base: T, change: Partial<T>): T {
  return Object.assign({}, base, change);
}

/** Create a new object with one property replaced.
 * @param base Object providing all properties except the single override.
 * @param member Property to override.
 * @param value Value to override with.
 */
export function objectSet<T extends object, K extends keyof T>(base: T, member: K, value: T[K]): T {
  return Object.assign({}, base, {[member]: value});
}

/** Create new object with one property removed.
 * @param base Object providing all properties except the one removed.
 * @param member Property to remove.
 */
export function objectOmit<T extends object, K extends keyof T>(base: T, member: K): T {
  const result: T = Object.assign({}, base);
  delete result[member];
  return result;
}

/** Create read/write copy of potentialle readonly object.
 * @param base Object to copy.
 */
export function objectCopy<T extends object>(base: T): T {
  return Object.assign({}, base);
}
