export const DECIMALS = 2;

export const countColumnStyle = {
  textAlign: "right",
  width: 160,
} as const;

export const correctedCountColumnStyle = {
  width: 120,
} as const;

export const unitColumnStyle = {
  width: 96,
} as const;
