import {
  PriceItemUrl,
  PriceItemUse,
  ProductUrl,
  ProductUse,
  ReportingChoiceAlternative,
  ReportingInputSpecification,
  ReportingLogInputSpecification,
  Unit,
  UnitUrl,
} from "@co-common-libs/resources";
import {DecimalField, IntegerField, TrimTextField} from "@co-frontend-libs/components";
import {getPriceItemLookup, getProductLookup} from "@co-frontend-libs/redux";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import {
  PureComponent,
  getPotentialTargetTransferPriceItemUrls,
  getPotentialTargetTransferProductUrls,
  useFalseCallback,
} from "app-utils";
import {bind} from "bind-decorator";
import React, {useCallback, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {TransferTargetDialog} from "../transfer-target-dialog";

const PRICE_ITEM_USE_NON_DECIMAL_DIGITS = 7;

type Value = boolean | number | string | readonly string[];

interface ReportingInputFieldInputProps {
  disabled?: boolean | undefined;
  identifier: string;
  inputSpecification: ReportingInputSpecification;
  onChange: (identifier: string, value: Value | null) => void;
  value?: Value | undefined;
}

class ReportingInputFieldInput extends PureComponent<ReportingInputFieldInputProps> {
  @bind
  handleMultipleChoiceChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void {
    const {name} = event.target as any;
    const oldValue = (this.props.value || []) as string[];
    let newValue;
    if (checked) {
      if (!oldValue.includes(name)) {
        newValue = [...oldValue, name];
      } else {
        newValue = oldValue;
      }
    } else {
      newValue = oldValue.filter((choice) => choice !== name);
    }
    this.handleChange(event, newValue);
  }
  @bind
  handleChange(_event: React.SyntheticEvent<unknown> | null, value: Value | null): void {
    this.props.onChange(this.props.identifier, value);
  }
  @bind
  handleTextChange(value: string): void {
    this.props.onChange(this.props.identifier, value);
  }
  @bind
  handleSimpleChange(value: Value | null): void {
    this.props.onChange(this.props.identifier, value);
  }
  render(): JSX.Element | null {
    const {disabled, inputSpecification, value} = this.props;
    const formatSpecification = inputSpecification.format;
    const {required} = inputSpecification;
    let {label} = inputSpecification;
    if (required) {
      label = `${label}*`;
    }
    const {identifier} = inputSpecification;
    const {type} = formatSpecification;
    console.assert(
      ["boolean", "text", "integer", "decimal", "choice", "multiple_choice"].includes(type),
    );
    if (type === "boolean") {
      let checked = value;
      if (value == null) {
        checked = formatSpecification.booleanDefault;
      }
      return (
        <div>
          <FormControlLabel
            control={<Checkbox checked={checked as boolean} onChange={this.handleChange} />}
            disabled={!!disabled}
            label={label}
          />
        </div>
      );
    } else if (type === "text") {
      const {multiline} = formatSpecification;
      return (
        <TrimTextField
          fullWidth
          disabled={!!disabled}
          label={label}
          margin="dense"
          multiline={!!multiline}
          value={value != null ? (value as string) : ""}
          variant="outlined"
          onChange={this.handleTextChange}
        />
      );
    } else if (type === "integer") {
      return (
        <IntegerField
          fullWidth
          disabled={!!disabled}
          label={label}
          margin="dense"
          maxDigits={
            (inputSpecification as ReportingLogInputSpecification).transfer ||
            (inputSpecification as ReportingLogInputSpecification).transferToEntry
              ? PRICE_ITEM_USE_NON_DECIMAL_DIGITS
              : undefined
          }
          value={value != null ? (value as number) : null}
          onChange={this.handleSimpleChange}
        />
      );
    } else if (type === "decimal") {
      const {maxDigits} = formatSpecification;
      const {decimalPlaces} = formatSpecification;
      return (
        <DecimalField
          fullWidth
          decimalPlaces={decimalPlaces}
          disabled={!!disabled}
          label={label}
          margin="dense"
          maxDigits={maxDigits}
          value={value != null ? (value as number) : null}
          onChange={this.handleSimpleChange}
        />
      );
    } else if (type === "choice") {
      const alternatives =
        formatSpecification.alternatives as readonly ReportingChoiceAlternative[];
      const radioButtons = alternatives.map((alternative) => {
        const radioButtonLabel = alternative.label;
        const radioButtonIdentifier = alternative.identifier;
        return (
          <FormControlLabel
            key={radioButtonIdentifier}
            control={<Radio />}
            disabled={!!disabled}
            label={radioButtonLabel}
            value={radioButtonIdentifier}
          />
        );
      });
      return (
        <div>
          <FormControl component="fieldset">
            <div>{label}</div>
            <RadioGroup name={identifier} value={value} onChange={this.handleChange}>
              {radioButtons}
            </RadioGroup>
          </FormControl>
        </div>
      );
    } else if (type === "multiple_choice") {
      const choices = formatSpecification.choices as readonly ReportingChoiceAlternative[];
      const checkboxes = choices.map((choice) => {
        const checkboxLabel = choice.label;
        const checkboxIdentifier = choice.identifier;
        return (
          <div key={checkboxIdentifier}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={value != null ? (value as string[]).includes(checkboxIdentifier) : false}
                  name={checkboxIdentifier}
                  onChange={this.handleMultipleChoiceChange}
                />
              }
              disabled={!!disabled}
              label={checkboxLabel}
            />
          </div>
        );
      });
      return (
        <div>
          <div>{label}</div>
          {checkboxes}
        </div>
      );
    }
    return null;
  }
}

interface ReportingInputFieldProps {
  disabled?: boolean;
  identifier: string;
  inputSpecification: ReportingInputSpecification;
  onChange: (identifier: string, value: Value | null) => void;
  onTransferOk?: ((value: number | null, url: string, unit: string) => void) | undefined;
  priceItemUseList?: readonly PriceItemUse[] | undefined;
  productUseList?: readonly ProductUse[] | undefined;
  unitLookup: (url: UnitUrl) => Unit | undefined;
  value?: Value | undefined;
}

export const ReportingInputField = React.memo(function ReportingInputField(
  props: ReportingInputFieldProps,
): JSX.Element {
  const {
    disabled,
    identifier,
    inputSpecification,
    onChange,
    onTransferOk,
    priceItemUseList,
    productUseList,
    unitLookup,
    value,
  } = props;
  const unit = inputSpecification.unit || "";
  const input = (
    <ReportingInputFieldInput
      disabled={inputSpecification.readonly || disabled}
      identifier={identifier}
      inputSpecification={inputSpecification}
      value={value}
      onChange={onChange}
    />
  );
  const priceItemLookup = useSelector(getPriceItemLookup);
  const productLookup = useSelector(getProductLookup);
  const [transferDialogOpen, setTransferDialogOpen] = useState(false);
  const handleTransferClick = useCallback(() => {
    setTransferDialogOpen(true);
  }, []);

  const handleTransferTargetSelected = useCallback(
    (url: string) => {
      setTransferDialogOpen(false);
      if (onTransferOk && (typeof value === "number" || value == null)) {
        onTransferOk(value ?? null, url, unit);
      }
    },
    [onTransferOk, unit, value],
  );

  const transferDialogCancel = useFalseCallback(setTransferDialogOpen, [setTransferDialogOpen]);
  let transferBlock: JSX.Element | null = null;
  if (
    (inputSpecification as ReportingLogInputSpecification).transferToEntry &&
    unit &&
    (priceItemUseList || productUseList)
  ) {
    const priceItemTargets = priceItemUseList
      ? getPotentialTargetTransferPriceItemUrls(
          priceItemUseList.map((priceItemUse) => priceItemUse.priceItem),
          unit,
          priceItemLookup,
          unitLookup,
        )
      : new Set<PriceItemUrl>();
    const productTargets = productUseList
      ? getPotentialTargetTransferProductUrls(
          productUseList.map((productUse) => productUse.product),
          unit,
          productLookup,
          unitLookup,
        )
      : new Set<ProductUrl>();

    if (
      priceItemTargets.size + productTargets.size > 1 &&
      (typeof value === "number" || value == null)
    ) {
      transferBlock = (
        <div>
          <Button color="primary" onClick={handleTransferClick}>
            <FormattedMessage defaultMessage="Overfør" id="reporting-input-field.transfer" />
          </Button>
          <TransferTargetDialog
            open={transferDialogOpen}
            priceItemURLSet={priceItemTargets}
            productURLSet={productTargets}
            unit={unit}
            value={value || 0}
            onCancel={transferDialogCancel}
            onTransferTargetSelected={handleTransferTargetSelected}
          />
        </div>
      );
    }
  }
  if (unit) {
    return (
      <div>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <div style={{display: "inline-block", flex: 1}}>{input}</div>
          <div style={{display: "inline-block", marginLeft: "1ex"}}>{unit}</div>
        </div>
        {transferBlock}
      </div>
    );
  } else {
    return input;
  }
});
