import {Query} from "@co-frontend-libs/db-resources";
import * as actions from "../resources/actions";
import {QueryQueryStateStruct} from "../resources/types";
import {partialEntriesForEach} from "../resources/utils";
import {ResourcesAuthenticationMiddlewareAPI} from "./types";

export function startMissingFullFetch(
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  baseURL: string,
): void {
  const {persistedQueries, temporaryQueries} = middlewareApi.getState().resources;
  // NOTE: depends on reducer setting state from performFetch...
  const startSyncIfRequired = (
    _queryIdentifier: string,
    queryQueryStateStruct: QueryQueryStateStruct,
  ): void => {
    const {query, queryState} = queryQueryStateStruct;
    if (
      !query.independentFetch ||
      queryState.currentlyFullFetching ||
      queryState.fullFetchDataComputedAtTimestamp ||
      queryState.pendingRemoval
    ) {
      // * query should not be fetched separately, or
      // * currently fetching, or
      // * previously fetched, or
      // * currently inactive but data kept around for now...
      return;
    }
    middlewareApi.dispatch(actions.performFullFetch({baseURL, query}));
  };
  partialEntriesForEach(persistedQueries, startSyncIfRequired);
  partialEntriesForEach(temporaryQueries, startSyncIfRequired);
}

export function checkNotFetching(
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  query: Query,
): void {
  if (process.env.NODE_ENV !== "production") {
    const {keyString} = query;
    const {persistedQueries, temporaryQueries} = middlewareApi.getState().resources;
    const persistedQueryQueryState = persistedQueries[keyString];
    if (persistedQueryQueryState) {
      const {queryState} = persistedQueryQueryState;
      console.assert(
        !queryState.currentlyFullFetching,
        `currently full fetching; should not be; keyString: ${keyString}`,
      );
      console.assert(
        !queryState.pendingRemoval,
        `currently pending removal, should not be; keyString: ${keyString}`,
      );
    }
    const temporaryQueryQueryState = temporaryQueries[keyString];
    if (temporaryQueryQueryState) {
      const {queryState} = temporaryQueryQueryState;
      console.assert(
        !queryState.currentlyFullFetching,
        `currently full fetching; should not be; keyString: ${keyString}`,
      );
      console.assert(
        !queryState.pendingRemoval,
        `currently pending removal, should not be; keyString: ${keyString}`,
      );
    }
  }
}
