import {ResourceName, resourceNames} from "@co-common-libs/resources";
import {getFrontendSentry} from "@co-frontend-libs/utils";
import {Draft, createReducer} from "@reduxjs/toolkit";
import {navigate} from "../../routing/actions";
import {
  addToOffline,
  clearCommitErrorTimestamps,
  createPromise,
  loadCommit,
  loadOffline,
  offlineDBError,
  performChangesFetch,
  performFullFetch,
  performIdFetch,
  performRelatedFetch,
  persistedQueriesRequested,
  save,
  saveLocally,
  saveOnline,
  temporaryQueriesDiscardedForKey,
  temporaryQueriesDiscardedForPath,
  temporaryQueriesRequestedForKey,
  temporaryQueriesRequestedForPath,
  temporaryQueryPurged,
} from "../actions";
import {ResourcesState} from "../types";
import {handleAddToOfflinePending, handleAddToOfflineRejected} from "./handle-add-to-offline";
import {handleClearCommitErrorTimestamps} from "./handle-clear-commit-error-timestamps";
import {
  handleCreatePromiseFulfilled,
  handleCreatePromisePending,
  handleCreatePromiseRejected,
} from "./handle-create-promise";
import {handleLoadCommitFulfilled, handleLoadCommitRejected} from "./handle-load-commit";
import {handleLoadOfflineFulfilled, handleLoadOfflineRejected} from "./handle-load-offline";
import {
  handlePerformChangesFetchFulfilled,
  handlePerformChangesFetchPending,
  handlePerformChangesFetchRejected,
} from "./handle-perform-changes-fetch";
import {
  handlePerformFullFetchFulfilled,
  handlePerformFullFetchPending,
  handlePerformFullFetchRejected,
} from "./handle-perform-full-fetch";
import {
  handlePerformIdFetchFulfilled,
  handlePerformIdFetchPending,
  handlePerformIdFetchRejected,
} from "./handle-perform-id-fetch";
import {
  handlePerformRelatedFetchFulfilled,
  handlePerformRelatedFetchPending,
  handlePerformRelatedFetchRejected,
} from "./handle-perform-related-fetch";
import {handlePersistedQueriesRequested} from "./handle-persisted-queries-requested";
import {handleSave} from "./handle-save";
import {
  handleSaveLocallyFulfilled,
  handleSaveLocallyPending,
  handleSaveLocallyRejected,
} from "./handle-save-locally";
import {
  handleSaveOnlineFulfilled,
  handleSaveOnlinePending,
  handleSaveOnlineRejected,
} from "./handle-save-online";
import {
  handleTemporaryQueriesDiscardedForKey,
  handleTemporaryQueriesDiscardedForPath,
  handleTemporaryQueriesRequestedForKey,
  handleTemporaryQueriesRequestedForPath,
} from "./handle-temporary-queries-requested-discarded";
import {handleTemporaryQueryPurged} from "./handle-temporary-query-purged";
import {recomputeTemporaryQueriesPendingRemoval} from "./recompute-temporary-queries-pending-removal";

const emptyResourceRecords = Object.fromEntries(
  resourceNames.map((resourceName) => [resourceName, {}]),
) as {
  readonly [N in ResourceName]: Partial<{
    readonly [key: string]: any;
  }>;
};

export const initialResourcesState: ResourcesState = {
  commitLoaded: false,
  commitQueue: [],
  currentlyFetchingChanges: false,
  fetchByIdActive: {},
  fetchByRelationActive: {},
  lastFetchChangesError: null,
  lastFetchChangesErrorTimestamp: null,
  lastFetchChangesTimestamp: null,
  localDBError: null,
  offlineLoaded: false,
  persistedData: emptyResourceRecords,
  persistedFetchByID: {},
  persistedFetchByRelation: {},
  persistedQueries: {},
  temporaryData: emptyResourceRecords,
  temporaryFetchByID: {},
  temporaryFetchByRelation: {},
  temporaryQueries: {},
  temporaryQueriesForKeys: {},
  temporaryQueriesForPathNames: {},
};

function handleOfflineDBError(
  state: Draft<ResourcesState>,
  action: ReturnType<typeof offlineDBError>,
): void {
  const sentry = getFrontendSentry();
  sentry.withScope((scope) => {
    scope.setTag("action", "handleOfflineDBError");
    sentry.captureMessage("Local DB Error", "error");
  });
  state.localDBError = action.payload;
}

function handleNavigate(state: Draft<ResourcesState>, action: ReturnType<typeof navigate>): void {
  const activePathName = action.payload.pathname;
  let anyRemoved = false;
  Object.keys(state.temporaryQueriesForPathNames).forEach((pathName) => {
    if (pathName !== activePathName) {
      anyRemoved = true;
      delete state.temporaryQueriesForPathNames[pathName];
    }
  });
  if (anyRemoved) {
    recomputeTemporaryQueriesPendingRemoval(state);
  }
}

export const reducer = createReducer(initialResourcesState, (builder) =>
  builder
    .addCase(loadOffline.fulfilled, handleLoadOfflineFulfilled)
    .addCase(loadOffline.rejected, handleLoadOfflineRejected)
    .addCase(persistedQueriesRequested, handlePersistedQueriesRequested)
    .addCase(temporaryQueriesRequestedForPath, handleTemporaryQueriesRequestedForPath)
    .addCase(temporaryQueriesDiscardedForPath, handleTemporaryQueriesDiscardedForPath)
    .addCase(temporaryQueriesRequestedForKey, handleTemporaryQueriesRequestedForKey)
    .addCase(temporaryQueriesDiscardedForKey, handleTemporaryQueriesDiscardedForKey)
    .addCase(temporaryQueryPurged, handleTemporaryQueryPurged)
    .addCase(performFullFetch.pending, handlePerformFullFetchPending)
    .addCase(performFullFetch.fulfilled, handlePerformFullFetchFulfilled)
    .addCase(performFullFetch.rejected, handlePerformFullFetchRejected)
    .addCase(performChangesFetch.pending, handlePerformChangesFetchPending)
    .addCase(performChangesFetch.fulfilled, handlePerformChangesFetchFulfilled)
    .addCase(performChangesFetch.rejected, handlePerformChangesFetchRejected)
    .addCase(performIdFetch.pending, handlePerformIdFetchPending)
    .addCase(performIdFetch.fulfilled, handlePerformIdFetchFulfilled)
    .addCase(performIdFetch.rejected, handlePerformIdFetchRejected)
    .addCase(performRelatedFetch.pending, handlePerformRelatedFetchPending)
    .addCase(performRelatedFetch.fulfilled, handlePerformRelatedFetchFulfilled)
    .addCase(performRelatedFetch.rejected, handlePerformRelatedFetchRejected)
    .addCase(loadCommit.fulfilled, handleLoadCommitFulfilled)
    .addCase(loadCommit.rejected, handleLoadCommitRejected)
    .addCase(save, handleSave)
    .addCase(createPromise.pending, handleCreatePromisePending)
    .addCase(createPromise.fulfilled, handleCreatePromiseFulfilled)
    .addCase(createPromise.rejected, handleCreatePromiseRejected)
    .addCase(saveLocally.pending, handleSaveLocallyPending)
    .addCase(saveLocally.fulfilled, handleSaveLocallyFulfilled)
    .addCase(saveLocally.rejected, handleSaveLocallyRejected)
    .addCase(saveOnline.pending, handleSaveOnlinePending)
    .addCase(saveOnline.fulfilled, handleSaveOnlineFulfilled)
    .addCase(saveOnline.rejected, handleSaveOnlineRejected)
    .addCase(addToOffline.pending, handleAddToOfflinePending)
    .addCase(addToOffline.rejected, handleAddToOfflineRejected)
    .addCase(clearCommitErrorTimestamps, handleClearCommitErrorTimestamps)
    .addCase(offlineDBError, handleOfflineDBError)
    .addCase(navigate, handleNavigate),
);
