import {ImportPreviewProduct} from "@co-common-libs/resources-utils";
import {memoizeForceReuse} from "@co-frontend-libs/utils";
import React, {useMemo} from "react";
import {useIntl} from "react-intl";
import {EntryData, GenericMultiSelectionSearchDialog} from "../../search-dialog";

function computeBaseChoices(
  itemArray: readonly ImportPreviewProduct[],
): readonly EntryData<string>[] {
  return itemArray.map((product) => {
    const {identifier, name} = product;
    const unit = product.unit?.name || "";
    return {
      category: "standard",
      exactMatchValue: identifier,
      identifier: product.remoteUrl,
      primaryText: name,
      searchFields: [
        {label: "ID", priority: 10, text: identifier},
        {label: "Navn", priority: 7, text: name},
        {label: "Enhed", priority: 5, text: unit},
      ],
      secondaryText: identifier && unit ? `${identifier} - ${unit}` : identifier || unit || "",
    };
  });
}

interface ImportProductsPreviewDialogProps {
  addLabel?: string;
  error: string | undefined;
  onAdd?(searchString: string): void;
  onCancel(): void;
  onOk(remoteUrls: ReadonlySet<string>): void;
  open: boolean;
  previewProducts: readonly ImportPreviewProduct[] | null;
  selected?: ReadonlySet<string>;
}

export const ImportProductsPreviewDialog = React.memo(function ImportProductsPreviewDialog(
  props: ImportProductsPreviewDialogProps,
) {
  const {addLabel, error, onAdd, onCancel, onOk, open, previewProducts, selected} = props;
  const intl = useIntl();
  const title = intl.formatMessage({defaultMessage: "Vælg varer"});
  const searchTitle = intl.formatMessage({defaultMessage: "Søg varer"});

  const [doComputeBaseChoices, reuseBaseChoices] = useMemo(
    () => memoizeForceReuse(computeBaseChoices, []),
    [],
  );
  const getBaseChoices = open ? doComputeBaseChoices : reuseBaseChoices;
  const data = previewProducts ? getBaseChoices(previewProducts) : null;

  return (
    <GenericMultiSelectionSearchDialog<string>
      addLabel={addLabel}
      data={data}
      error={error}
      listEmptyMessage={intl.formatMessage({
        defaultMessage: "Alle varer er allerede importeret til CustomOffice",
      })}
      mobilePrimaryLines={1}
      mobileSearchPrimaryLines={1}
      mobileSearchSecondaryLines={1}
      mobileSecondaryLines={1}
      open={open}
      searchTitle={searchTitle}
      selected={selected}
      sorting="SECONDARY_IDENTIFIER"
      title={title}
      onAdd={onAdd}
      onCancel={onCancel}
      onOk={onOk}
    />
  );
});
