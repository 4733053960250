import {Location, Product, ProductUrl} from "@co-common-libs/resources";
import {getUnitString} from "@co-common-libs/resources-utils";
import {caseAccentInsensitiveCollator} from "@co-common-libs/utils";
import {
  getCustomerLookup,
  getLocationTypeLookup,
  getProductLookup,
  getUnitLookup,
} from "@co-frontend-libs/redux";
import bowser from "bowser";
import _ from "lodash";
import React, {useCallback, useMemo, useRef} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";

interface LocationMarkerTextProps {
  cropVisible: boolean;
  fieldHaAndCustomerNameVisible: boolean;
  location: Location;
  onClickOrGoogleMapsUrl: string | ((ref: HTMLSpanElement, location: Location) => void) | undefined;
  productCounts: ReadonlyMap<ProductUrl, number> | undefined;
  x: number;
  y: number;
}

export function LocationMarkerText(props: LocationMarkerTextProps): JSX.Element {
  const {
    cropVisible,
    fieldHaAndCustomerNameVisible,
    location,
    onClickOrGoogleMapsUrl,
    productCounts,
    x,
    y,
  } = props;
  const intl = useIntl();

  const onClick = typeof onClickOrGoogleMapsUrl === "function" ? onClickOrGoogleMapsUrl : undefined;
  const googleMapsUrl =
    typeof onClickOrGoogleMapsUrl === "string" ? onClickOrGoogleMapsUrl : undefined;

  const locationTypeLookup = useSelector(getLocationTypeLookup);
  const productLookup = useSelector(getProductLookup);
  const unitLookup = useSelector(getUnitLookup);
  const customerLookup = useSelector(getCustomerLookup);

  const locationTypeURL = location.locationType;
  const locationType = locationTypeURL ? locationTypeLookup(locationTypeURL) : null;

  const relevantProductValues = useMemo(() => {
    if (!locationType) {
      return [];
    }
    const locationTypeProductURLs = locationType.products;
    const result: {readonly product: Product; readonly value: number}[] = [];

    if (productCounts) {
      productCounts.forEach((value, productURL) => {
        if (value && locationTypeProductURLs.includes(productURL)) {
          const product = productLookup(productURL);
          if (product) {
            result.push({product, value});
          }
        }
      });
    }
    return result as readonly {
      readonly product: Product;
      readonly value: number;
    }[];
  }, [locationType, productCounts, productLookup]);

  const productEntries = useMemo(
    () =>
      relevantProductValues
        .slice()
        .sort((a, b) => caseAccentInsensitiveCollator.compare(a.product.name, b.product.name))
        .map(({product, value}) => {
          const unitString = getUnitString(product, unitLookup);
          return `${value} ${unitString}, ${product.name}`;
        }),
    [relevantProductValues, unitLookup],
  );

  const ref = useRef<HTMLAnchorElement>(null);
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      if (onClick && ref.current) {
        onClick(ref.current, location);
      }
    },
    [location, onClick],
  );

  let customerLabel: JSX.Element | null = null;
  let area: string | null = null;
  let fieldRecordYear: number | null = null;
  if (location.geojson && fieldHaAndCustomerNameVisible && location.fieldAreaHa !== null) {
    area = intl.formatNumber(location.fieldAreaHa, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
    ({fieldRecordYear} = location);
  }

  if (location.customer && fieldHaAndCustomerNameVisible) {
    const customer = customerLookup(location.customer);
    // eslint-disable-next-line react/jsx-no-useless-fragment
    customerLabel = customer ? <div>{customer.name}</div> : <></>;
  }

  const style: React.CSSProperties = {
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    borderRadius: 4,
    color: "#000",
    cursor: onClick ? "pointer" : "inherit",
    display: "inline-block",
    fontSize: 12,
    left: x,
    paddingLeft: 4,
    paddingRight: 4,
    position: "absolute",
    textDecoration: "none",
    top: y,
  };

  const content = (
    <>
      <div>
        <b>{location.name || location.address}</b>
        {area ? ` ${area} ha` : null}
        {fieldRecordYear ? ` (${fieldRecordYear})` : null}
      </div>
      {customerLabel}
      {cropVisible && location.fieldCrop ? <div>{location.fieldCrop}</div> : null}
      {productEntries.map((entry, index) => (
        <div key={index}>{entry}</div>
      ))}
    </>
  );

  if (googleMapsUrl) {
    return (
      <a
        href={googleMapsUrl || ""}
        rel="noopener noreferrer"
        style={style}
        target={window.cordova && bowser.ios ? "_system" : "_blank"}
      >
        {content}
      </a>
    );
  } else {
    return (
      <span ref={ref} style={style} onClick={handleClick}>
        {content}
      </span>
    );
  }
}
