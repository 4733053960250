import {Customer, CustomerUrl, Location, LocationUrl} from "@co-common-libs/resources";
import {LocationDialog, MultiLocationDialog, TitleVariant} from "@co-frontend-libs/components";
import {
  AppState,
  getCustomerLookup,
  getCustomerSettingsMember,
  getVisibleLocationArray,
} from "@co-frontend-libs/redux";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";

interface LocationDialogStateProps {
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  locationArray: readonly Location[];
  locationCrossCustomerSelectionEnabled: boolean;
  locationFavoritesEnabled: boolean;
}

export interface LocationDialogOwnProps {
  customerURL: CustomerUrl | null;
  hideFieldLocations?: boolean;
  includeLogOnlyLocations: boolean;
  includeWorkplaceOnlyLocations: boolean;
  lastUsedLocations?: ReadonlySet<LocationUrl>;
  onAdd?: (searchString: string) => void;
  onCancel(): void;

  onNone?: () => void;
  onOk(url: LocationUrl): void;
  open: boolean;
  titleVariant: TitleVariant;
}

export const ConnectedLocationDialog: React.ComponentType<LocationDialogOwnProps> = connect<
  LocationDialogStateProps,
  object,
  LocationDialogOwnProps,
  AppState
>(
  createStructuredSelector<AppState, LocationDialogStateProps>({
    customerLookup: getCustomerLookup,
    locationArray: getVisibleLocationArray,
    locationCrossCustomerSelectionEnabled: getCustomerSettingsMember(
      "locationCrossCustomerSelectionEnabled",
    ),
    locationFavoritesEnabled: getCustomerSettingsMember("locationFavoritesEnabled"),
  }),
  {},
)(LocationDialog);

interface ConnectedMultiLocationDialogStateProps {
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  locationArray: readonly Location[];
  locationCrossCustomerSelectionEnabled: boolean;
  locationFavoritesEnabled: boolean;
}

interface ConnectedMultiLocationDialogOwnProps {
  customerURL: CustomerUrl | null;
  includeLogOnlyLocations: boolean;
  includeSelectAll?: boolean;
  includeWorkplaceOnlyLocations: boolean;
  onCancel(): void;
  onOk(urls: ReadonlySet<LocationUrl>): void;
  open: boolean;
  selected?: ReadonlySet<LocationUrl>;
  titleVariant: TitleVariant;
}

export const ConnectedMultiLocationDialog: React.ComponentType<ConnectedMultiLocationDialogOwnProps> =
  connect<
    ConnectedMultiLocationDialogStateProps,
    object,
    ConnectedMultiLocationDialogOwnProps,
    AppState
  >(
    createStructuredSelector<AppState, ConnectedMultiLocationDialogStateProps>({
      customerLookup: getCustomerLookup,
      locationArray: getVisibleLocationArray,
      locationCrossCustomerSelectionEnabled: getCustomerSettingsMember(
        "locationCrossCustomerSelectionEnabled",
      ),
      locationFavoritesEnabled: getCustomerSettingsMember("locationFavoritesEnabled"),
    }),
    {},
  )(MultiLocationDialog);
