import {objectSet} from "@co-common-libs/utils";
import {TaskIntervalWithRate, WorkDay} from "./types";

function processWorkDayIgnoreTimers(
  workDay: WorkDay,
  unpaidTimerURLs: ReadonlySet<string>,
): WorkDay {
  const workPeriodsWithIgnoreTimersProcessed = workDay.workPeriods.map((workPeriod) => {
    const inputWorkIntervals = workPeriod.work;
    const outputWorkIntervals: TaskIntervalWithRate[] = [];
    for (let i = 0; i < inputWorkIntervals.length; i += 1) {
      const inputInterval = inputWorkIntervals[i];
      if (
        !inputInterval.taskData.length ||
        !inputInterval.taskData.every(
          (taskData) => taskData.timerURL && unpaidTimerURLs.has(taskData.timerURL),
        )
      ) {
        outputWorkIntervals.push(inputInterval);
      }
    }
    return objectSet(workPeriod, "work", outputWorkIntervals);
  });
  return {
    ...workDay,
    workPeriods: workPeriodsWithIgnoreTimersProcessed,
  };
}

export function processIgnoreTimers(
  workDays: readonly WorkDay[],
  unpaidTimerURLs: ReadonlySet<string>,
): readonly WorkDay[] {
  return workDays.map((workDay) => processWorkDayIgnoreTimers(workDay, unpaidTimerURLs));
}
