import {ReportingSpecificationTemplate} from "./types";

export const pressingLog: ReportingSpecificationTemplate = {
  active: true,
  allowSkip: false,
  allowTotalsTable: true,
  allowTransportedMaterialCountMismatch: false,
  autoInsertAmountInDelivery: true,
  autoInsertLastPickupValues: true,
  autoInsertLastWorkplaceConversionValue: false,
  autoInsertLastWorkplaceValues: false,
  customerLogSums: true,
  displayProductsPerWorkplace: false,
  displayWorkplaceOverviewOnPDF: true,
  extraFooterText: "",
  fieldsUsedFor: "workplace",
  hideMaterialsOnPrintout: false,
  horizontal: true,
  identifier: "pressing",
  invoiceCustomerAddress: false,
  invoiceCustomerName: false,
  invoiceCustomerVatNumber: false,
  invoiceWorkplaceAddress: false,
  invoiceWorkplaceName: false,
  name: "Presning",
  reportTransport: true,
  requireAtLeastOneLogEntry: false,
  sendMailToPickupDeliveryCustomers: false,
  shared: false,
  showCustomerOnEntryTable: true,
  showLoadCounts: false,
  showLocationMaterialSums: false,
  showLocationProducts: true,
  showMachines: false,
  showSharedTotalsTableOnAllOrderTasks: false,
  showTaskTimeOnReport: true,
  simpleLocationSumLog: false,
  taskData: {},
  versionNote: "Første version",
  workplaceData: {
    workplace: {
      inputs: [
        {
          fieldAreaTarget: true,
          format: {
            decimalPlaces: 2,
            maxDigits: 9,
            type: "decimal",
          },
          identifier: "area",
          label: "Areal",
          required: false,
          unit: "HA",
        },
        {
          fieldCropTarget: true,
          format: {
            multiline: false,
            type: "text",
          },
          identifier: "crop",
          label: "Afgrøde",
          required: false,
        },
      ],
      logEntries: 1,
      logInputs: [
        {
          fieldAreaTarget: true,
          format: {
            decimalPlaces: 2,
            maxDigits: 9,
            type: "decimal",
          },
          identifier: "area",
          label: "Areal",
          required: false,
          unit: "HA",
        },
        {
          fieldCropTarget: true,
          format: {
            multiline: false,
            type: "text",
          },
          identifier: "crop",
          label: "Afgrøde",
          required: false,
        },
      ],
      logPriceItems: true,
      logProducts: true,
    },
  },
  workplaceRegistration: 1,
};
