import {IconButton} from "@material-ui/core";
import {WorkTypeChangeBlockedDialog} from "app-components";
import {WorkTypeChangeBlockedReason} from "app-utils";
import PencilIcon from "mdi-react/PencilIcon";
import React, {useCallback, useState} from "react";

interface ChangeWorkTypeButtonProps {
  blockedReason: WorkTypeChangeBlockedReason | null;
  disabled: boolean;
  onWorkTypeSelectButton: () => void;
}

export function ChangeWorkTypeButton(props: ChangeWorkTypeButtonProps): JSX.Element {
  const {blockedReason, disabled, onWorkTypeSelectButton} = props;
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const handleWorkTypeSelectButton = useCallback(() => {
    if (!blockedReason) {
      onWorkTypeSelectButton();
    } else {
      setDialogOpen(true);
    }
  }, [blockedReason, onWorkTypeSelectButton]);
  const handleDialogClose = useCallback(() => {
    setDialogOpen(false);
  }, []);
  return (
    <>
      <IconButton disabled={disabled} onClick={handleWorkTypeSelectButton}>
        <PencilIcon />
      </IconButton>
      <WorkTypeChangeBlockedDialog
        blockedReason={blockedReason}
        open={dialogOpen}
        onClose={handleDialogClose}
      />
    </>
  );
}
