import {PriceItemUsesDict, ProductUsesDict, Task} from "@co-common-libs/resources";
import {Paper} from "@material-ui/core";
import {InvoiceLineTable} from "app-components";
import {PureComponent} from "app-utils";
import React from "react";
import {FormattedMessage} from "react-intl";

interface InvoicingTabProps {
  task: Task;
}

export class InvoicingTab extends PureComponent<InvoicingTabProps> {
  render(): JSX.Element {
    if (this.props.task.completed) {
      const editDisabled = this.props.task.validatedAndRecorded || this.props.task.reportApproved;
      return (
        <Paper style={{margin: 15}}>
          <InvoiceLineTable
            editDisabled={editDisabled}
            striped={false}
            task={
              this.props.task as Task & {
                readonly priceItemUses: PriceItemUsesDict;
                readonly productUses: ProductUsesDict;
              }
            }
          />
        </Paper>
      );
    } else {
      return (
        <Paper style={{margin: 15, padding: 10}}>
          <h3>
            <FormattedMessage
              defaultMessage="Du kan først rette i faktureringslinjerne når opgaven er udført."
              id="task.invoicing-tab.label.not-completed"
            />
          </h3>
        </Paper>
      );
    }
  }
}
