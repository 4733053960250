import {SettingID, SettingMetaData} from "@co-common-libs/config";
import {DeleteDialog, ResponsiveDialog, TrimTextField} from "@co-frontend-libs/components";
import {
  actions,
  getCurrentUserURL,
  getSettingsEntryLookupByIdentifier,
} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {
  Button,
  DialogContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  makeStyles,
} from "@material-ui/core";
import DeleteIcon from "mdi-react/DeleteIcon";
import React, {useCallback, useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {StatusDot} from "../status-dot";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

function KeyValueEditRow({
  index,
  keyEditDisabled,
  keyName,
  onKeyChange,
  onRequestDelete,
  onValueChange,
  value,
}: {
  index: number;
  keyEditDisabled: boolean;
  keyName: string;
  onKeyChange: (index: number, oldKeyName: string) => void;
  onRequestDelete: (index: number) => void;
  onValueChange: (index: number, value: string) => void;
  value: string;
}): JSX.Element {
  const handleKeyChange = useCallback(
    (newValue: string) => {
      onKeyChange(index, newValue);
    },
    [index, onKeyChange],
  );
  const handleValueChange = useCallback(
    (newValue: string) => {
      onValueChange(index, newValue);
    },
    [index, onValueChange],
  );

  const handleDeleteClick = useCallback(() => {
    onRequestDelete(index);
  }, [index, onRequestDelete]);
  return (
    <TableRow>
      <TableCell>
        <TrimTextField
          fullWidth
          disabled={keyEditDisabled}
          margin="dense"
          value={keyName}
          variant="outlined"
          onChange={handleKeyChange}
        />
      </TableCell>
      <TableCell>
        <TrimTextField
          fullWidth
          margin="dense"
          value={value}
          variant="outlined"
          onChange={handleValueChange}
        />
      </TableCell>
      <TableCell>
        <IconButton disabled={keyEditDisabled} onClick={handleDeleteClick}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

function KeyValueEditDialog({
  description,
  disableKeyEdit,
  keyLabel,
  onCancel,
  onOk,
  open,
  title,
  valueLabel,
  values: initialValues,
}: {
  description: string;
  disableKeyEdit?: string[] | undefined;
  keyLabel: string;
  onCancel: () => void;
  onOk: (newValue: {[key: string]: string}) => void;
  open: boolean;
  title: JSX.Element | string;
  valueLabel: string;
  values: {[key: string]: string};
}): JSX.Element {
  const [values, setValues] = useState<readonly (readonly [string, string])[]>(
    Object.entries(initialValues),
  );
  useEffect(() => {
    if (open) {
      setValues(Object.entries(initialValues));
    }
  }, [initialValues, open]);

  const handleKeyChange = useCallback(
    (index: number, key: string) => {
      const newValues = [...values];
      newValues[index] = [key, values[index][1]];
      setValues(newValues);
    },
    [values],
  );

  const handleValueChange = useCallback(
    (index: number, value: string) => {
      const newValues = [...values];
      const [key] = newValues[index];
      newValues[index] = [key, value];
      setValues(newValues);
    },
    [values],
  );

  const [newKeyValueDialogOpen, setNewKeyValueDialogOpen] = useState(false);
  const setNewKeyValueDialogOpenTrue = useCallWithTrue(setNewKeyValueDialogOpen);
  const setNewKeyValueDialogOpenFalse = useCallWithFalse(setNewKeyValueDialogOpen);

  const handleOk = useCallback(() => {
    const newValues = Object.fromEntries(values);
    onOk(newValues);
  }, [onOk, values]);
  const handleNewKeyValueDialogOk = useCallback(
    (key: string, value: string) => {
      setNewKeyValueDialogOpen(false);
      const newValues: readonly (readonly [string, string])[] = [...values, [key, value]];
      setValues(newValues);
    },
    [values],
  );

  const duplicateKeys = values.length !== new Set(values.map(([key]) => key)).size;

  const [deleteDialogOpenFor, setDeleteDialogOpenFor] = useState<number | null>(null);

  const handleDeleteClick = useCallback((index: number) => {
    setDeleteDialogOpenFor(index);
  }, []);

  const handleDeleteDialogOk = useCallback(() => {
    setDeleteDialogOpenFor(null);
    if (deleteDialogOpenFor === null) {
      return;
    }
    const newValues = [...values];
    newValues.splice(deleteDialogOpenFor, 1);
    setValues(newValues);
  }, [deleteDialogOpenFor, values]);

  const handleDeleteDialogCancel = useCallback(() => {
    setDeleteDialogOpenFor(null);
  }, []);

  const deleteDialogOpen = deleteDialogOpenFor !== null;

  return (
    <>
      <ResponsiveDialog
        okDisabled={duplicateKeys}
        open={open && !newKeyValueDialogOpen && !deleteDialogOpen}
        title={title}
        onCancel={onCancel}
        onOk={handleOk}
      >
        <DialogContent>
          {description}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{width: 200}}>{keyLabel}</TableCell>
                <TableCell>{valueLabel}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {values.map(([keyName, value], index) => {
                return (
                  <KeyValueEditRow
                    key={index}
                    index={index}
                    keyEditDisabled={(disableKeyEdit && disableKeyEdit.includes(keyName)) || false}
                    keyName={keyName}
                    value={value}
                    onKeyChange={handleKeyChange}
                    onRequestDelete={handleDeleteClick}
                    onValueChange={handleValueChange}
                  />
                );
              })}
            </TableBody>
          </Table>
          <Button onClick={setNewKeyValueDialogOpenTrue}>Tilføj</Button>
        </DialogContent>
      </ResponsiveDialog>
      <AddKeyValueDialog
        keyLabel={keyLabel}
        open={newKeyValueDialogOpen}
        valueLabel={valueLabel}
        onCancel={setNewKeyValueDialogOpenFalse}
        onOk={handleNewKeyValueDialogOk}
      />
      <DeleteDialog
        open={deleteDialogOpen}
        onCancel={handleDeleteDialogCancel}
        onOk={handleDeleteDialogOk}
      >
        <FormattedMessage
          defaultMessage="Hvis du sletter denne mens den er i brug, kan du ødelægge opsætningen!"
          id="key-value-setting.delete-message"
        />
      </DeleteDialog>
    </>
  );
}

function AddKeyValueDialog({
  keyLabel,
  onCancel,
  onOk,
  open,
  valueLabel,
}: {
  keyLabel: string;
  onCancel: () => void;
  onOk: (key: string, value: string) => void;
  open: boolean;
  valueLabel: string;
}): JSX.Element {
  const [key, setKey] = useState<string>("");
  const [value, setValue] = useState<string>("");

  useEffect(() => {
    if (open) {
      setKey("");
      setValue("");
    }
  }, [open]);

  const handleOk = useCallback(() => {
    onOk(key, value);
  }, [key, onOk, value]);
  return (
    <ResponsiveDialog
      okDisabled={!key || !value}
      open={open}
      title={<FormattedMessage defaultMessage="Tilføj" />}
      onCancel={onCancel}
      onOk={handleOk}
    >
      <DialogContent>
        <TrimTextField
          autoFocus
          fullWidth
          label={keyLabel}
          margin="dense"
          value={key}
          variant="outlined"
          onChange={setKey}
        />

        <TrimTextField
          fullWidth
          label={valueLabel}
          margin="dense"
          value={value}
          variant="outlined"
          onChange={setValue}
        />
      </DialogContent>
    </ResponsiveDialog>
  );
}

export function KeyValueSetting(props: {
  data?: {[label: string]: string};
  disableKeyEdit?: string[];
  keyLabel: string;
  settingID: SettingID;
  settingMetaData: SettingMetaData;
  title: JSX.Element | string;
  valueLabel: string;
}): JSX.Element {
  const {data, disableKeyEdit, keyLabel, settingID, settingMetaData, title, valueLabel} = props;

  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const settingEntry = settingsEntryLookupByIdentifier(settingID);
  const settingsData: {[label: string]: string} = data || settingEntry?.data || {};
  const classes = useStyles();

  const [keyValueEditDialogOpen, setKeyValueEditDialogOpen] = useState(false);
  const setKeyValueEditDialogOpenTrue = useCallWithTrue(setKeyValueEditDialogOpen);
  const setKeyValueEditDialogOpenFalse = useCallWithFalse(setKeyValueEditDialogOpen);

  const dispatch = useDispatch();
  const currentUserURL = useSelector(getCurrentUserURL);
  const handleKeyValueEditDialogOk = useCallback(
    (newValue: {[label: string]: string}) => {
      setKeyValueEditDialogOpen(false);

      if (settingEntry) {
        dispatch(
          actions.update(settingEntry.url, [
            {member: "changedBy", value: currentUserURL},
            {member: "data", value: newValue},
          ]),
        );
      }
    },
    [currentUserURL, dispatch, settingEntry],
  );

  return (
    <>
      <div className={classes.root} style={{display: "flex"}}>
        <div style={{cursor: "pointer", flex: "0 0 50px"}} onClick={setKeyValueEditDialogOpenTrue}>
          <StatusDot settingEntry={settingEntry} />
        </div>
        <div style={{cursor: "pointer", flex: 1}} onClick={setKeyValueEditDialogOpenTrue}>
          {settingMetaData.description}
          <div>
            <i>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{width: 200}}>{keyLabel}</TableCell>
                    <TableCell>{valueLabel}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(settingsData).map((keyName) => {
                    const value = settingsData[keyName];
                    return (
                      <TableRow key={keyName}>
                        <TableCell>{keyName}</TableCell>
                        <TableCell>{value}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </i>
          </div>
        </div>
        <div style={{flex: "0 0 100px", paddingRight: 50}}>
          <small>{settingID}</small>
        </div>
      </div>
      <KeyValueEditDialog
        description={settingMetaData.description}
        disableKeyEdit={disableKeyEdit}
        keyLabel={keyLabel}
        open={keyValueEditDialogOpen}
        title={title}
        valueLabel={valueLabel}
        values={settingsData}
        onCancel={setKeyValueEditDialogOpenFalse}
        onOk={handleKeyValueEditDialogOk}
      />
    </>
  );
}
