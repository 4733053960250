import {LocationTypeIcon} from "@co-common-libs/resources";

export const iconButtonOffset: {
  readonly [iconIdentifier in LocationTypeIcon]: {
    readonly xOffset: number;
    readonly yOffset: number;
  };
} = {
  /* eslint-disable @typescript-eslint/naming-convention */
  "account-hard-hat": {xOffset: -24, yOffset: -40},
  agenda: {xOffset: -24, yOffset: -40},
  barley: {xOffset: -24, yOffset: -42},
  barn: {xOffset: -24, yOffset: -40},
  bulldozer: {xOffset: -24, yOffset: -40},
  "car-estate": {xOffset: -24, yOffset: -36},
  "car-hatchback": {xOffset: -24, yOffset: -36},
  "content-copy": {xOffset: -24, yOffset: -42},
  "currency-usd": {xOffset: -24, yOffset: -40},
  database: {xOffset: -24, yOffset: -40},
  "emoticon-poop": {xOffset: -24, yOffset: -40},
  excavator: {xOffset: -24, yOffset: -40},
  factory: {xOffset: -24, yOffset: -40},
  "flag-variant": {xOffset: -22, yOffset: -38},
  "gas-station": {xOffset: -24, yOffset: -38},
  "hammer-wrench": {xOffset: -24, yOffset: -40},
  "hockey-puck": {xOffset: -24, yOffset: -38},
  "map-marker-circle": {xOffset: -24, yOffset: -42},
  pig: {xOffset: -24, yOffset: -38},
  "pine-tree": {xOffset: -24, yOffset: -40},
  semantic: {xOffset: -24, yOffset: -42},
  silo: {xOffset: -24, yOffset: -42},
  snowflake: {xOffset: -24, yOffset: -40},
  "sprinkler-variant": {xOffset: -24, yOffset: -40},
  sprout: {xOffset: -24, yOffset: -42},
  summit: {xOffset: -24, yOffset: -40},
  terrain: {xOffset: -24, yOffset: -36},
  "texture-box": {xOffset: -24, yOffset: -42},
  tractor: {xOffset: -24, yOffset: -38},
  truck: {xOffset: -24, yOffset: -38},
  "van-utility": {xOffset: -24, yOffset: -38},
  warehouse: {xOffset: -24, yOffset: -40},
  "water-pump": {xOffset: -24, yOffset: -40},
  "wheel-barrow": {xOffset: -24, yOffset: -40},
  /* eslint-enable @typescript-eslint/naming-convention */
};
