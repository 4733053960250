import {Command, MachineUseLog, Task, instanceURL, urlToId} from "@co-common-libs/resources";
import _ from "lodash";
import {v4 as uuid} from "uuid";
import {ProvisionaryCommand} from "../../resources/actions";
import {
  getCustomerSettings,
  getMachineLookup,
  getMachineUseLogArray,
} from "../../resources/selectors";
import {ResourcesAuthenticationMiddlewareAPI} from "../types";
import {getBaseURL} from "./get-base-url";

export function writeToMachineUseLog(
  newTask: Task | null,
  oldTask: Task | undefined,
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  command: ProvisionaryCommand,
): {after?: Command[]; before?: Command[]} | null {
  if (!newTask) {
    return null;
  }

  const {machineOperator, machineuseSet, priceGroup, workType} = newTask;
  if (
    !workType ||
    !machineOperator ||
    !machineuseSet ||
    !machineuseSet.length ||
    (workType === oldTask?.workType &&
      newTask.machineOperator === oldTask?.machineOperator &&
      newTask.priceGroup === oldTask?.priceGroup &&
      _.isEqual(newTask.machineuseSet, oldTask?.machineuseSet))
  ) {
    // nothing to save unless we have workType, machineOperator and machines
    // only triggered on changes
    return null;
  }

  const state = middlewareApi.getState();
  const customerSettings = getCustomerSettings(state);
  if (
    customerSettings.defaultTaskEmployee &&
    urlToId(machineOperator) === customerSettings.defaultTaskEmployee
  ) {
    // nothing to save if machineOperator is defaultTaskEmployee
    return null;
  }

  const machineLookup = getMachineLookup(state);

  const machineList = machineuseSet
    .map((machineUse) => machineUse.machine)
    .filter((machineURL) => {
      const machine = machineLookup(machineURL);
      return machine && !machine.smallMachine;
    });

  const machineUseLogArray = getMachineUseLogArray(state);
  const filteredMachineUseLogArray = machineUseLogArray.filter(
    (m) => m.user === machineOperator && m.worktype === workType && m.priceGroup === priceGroup,
  );
  const existingMachineUseLog = _.maxBy(
    filteredMachineUseLogArray,
    // if no lastChanged (local/not synced with server), use fallback
    // value which puts that entry "last" compared to timestamps
    (m) => m.lastChanged || "x",
  );

  if (existingMachineUseLog) {
    if (_.isEqual(machineList, existingMachineUseLog.machines)) {
      return null;
    }
    const patchMachineUseLog: Command = {
      action: "UPDATE",
      patch: [{member: "machines", value: machineList}],
      url: existingMachineUseLog.url,
    };
    return {
      after: [patchMachineUseLog],
    };
  } else {
    const id = uuid();
    const baseURL = getBaseURL(command.url);
    const url = instanceURL(baseURL, "machineUseLog", id);
    const newMachineUseLog: MachineUseLog = {
      id,
      machines: machineList,
      priceGroup,
      url,
      user: machineOperator,
      worktype: workType,
    };
    return {after: [{action: "CREATE", instance: newMachineUseLog, url}]};
  }
}
