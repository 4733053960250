import {Theme, createStyles, makeStyles} from "@material-ui/core";
import type {CSSProperties} from "@material-ui/styles";
import bowser from "bowser";
import clsx from "clsx";
import React, {useState} from "react";
import {BackToolbar, DrawerMarginAppBar, MobileHideOnScroll} from "../appbar";
import {TouchPullRefreshHandler} from "../touch-pull-refresh-handler";
import {BottomScrollPadding} from "./bottom-scroll-padding";
import {DragDropScrollHandler} from "./drag-drop-scroll-handler";
import {ScrollHandler} from "./scroll-handler";

function getNumber(value: number | string | undefined): number {
  if (typeof value === "number") {
    return value;
  } else if (typeof value === "string") {
    return parseInt(value);
  } else {
    return 0;
  }
}

const useStyles = makeStyles((theme: Theme) => {
  const DENSE_TOOLBAR_HEIGHT = 48;

  function toolbarMinHeightStyles(fn: (minHeight: number) => CSSProperties): CSSProperties {
    console.assert(theme.mixins.toolbar.minHeight);
    const result = fn(getNumber(theme.mixins.toolbar.minHeight));
    Object.entries(theme.mixins.toolbar).forEach(([key, value]) => {
      if (
        key.startsWith("@media") &&
        value &&
        typeof value === "object" &&
        (value as any).minHeight
      ) {
        result[key] = fn(getNumber((value as any).minHeight));
      }
    });
    return result;
  }

  return createStyles({
    innerScrollable: toolbarMinHeightStyles((toolbarHeight) => ({
      height: `calc(100% - ${toolbarHeight}px)`,
      overflow: "auto",
    })),
    innerScrollableTabs: toolbarMinHeightStyles((toolbarHeight) => ({
      height: `calc(100% - ${toolbarHeight + DENSE_TOOLBAR_HEIGHT}px)`,
      overflow: "auto",
    })),
    mobilePadding: {padding: "1em 11px 1em 11px"},
    outerNotScrollable: {
      height: "100%",
      overflow: "hidden",
    },
    pcPadding: {padding: "1em"},
    toolbarMargin: toolbarMinHeightStyles((toolbarHeight) => ({
      marginTop: toolbarHeight,
    })),
    toolbarTabsMargin: toolbarMinHeightStyles((toolbarHeight) => ({
      marginTop: toolbarHeight + DENSE_TOOLBAR_HEIGHT,
    })),
  });
});

interface PageLayoutProps {
  children: React.ReactNode;
  dialogs?: JSX.Element | readonly JSX.Element[] | undefined;
  disableTouchPullRefresh?: boolean;
  /** @deprecated */
  floatingActionButton?: JSX.Element | readonly JSX.Element[] | undefined;
  performScrolling?: boolean;
  speedDial?: JSX.Element | null;
  tabs?: JSX.Element | undefined;
  toolbar: JSX.Element | string;
  withBottomScrollPadding?: boolean;
  withDragScroll?: boolean;
  withPadding?: boolean;
}

export function PageLayout(props: PageLayoutProps): JSX.Element {
  const {
    children,
    dialogs,
    disableTouchPullRefresh,
    floatingActionButton,
    performScrolling = true,
    speedDial,
    tabs,
    toolbar,
    withBottomScrollPadding,
    withDragScroll,
    withPadding,
  } = props;
  const toolbarElement = typeof toolbar === "string" ? <BackToolbar title={toolbar} /> : toolbar;

  const classes = useStyles();

  const mobile = bowser.tablet || bowser.mobile;

  // state rather than ref; we want to trigger re-render to provide
  // <ScrollHandler /> with prop on change
  const [scrollElement, setScrollElement] = useState<HTMLDivElement | null>(null);

  return (
    <div className={clsx({[classes.outerNotScrollable]: !mobile})}>
      {performScrolling ? <ScrollHandler element={mobile ? "GLOBAL" : scrollElement} /> : null}
      {withDragScroll ? (
        <DragDropScrollHandler scrollElement={mobile ? "GLOBAL" : scrollElement} />
      ) : null}
      <MobileHideOnScroll>
        <DrawerMarginAppBar>
          {toolbarElement}
          {tabs}
        </DrawerMarginAppBar>
      </MobileHideOnScroll>
      {!disableTouchPullRefresh ? <TouchPullRefreshHandler /> : null}
      <div
        ref={setScrollElement}
        className={clsx({
          [classes.mobilePadding]: withPadding && mobile,
          [classes.pcPadding]: withPadding && !mobile,
          [classes.innerScrollable]: !mobile && !tabs,
          [classes.innerScrollableTabs]: !mobile && !!tabs,
          [classes.toolbarMargin]: !tabs,
          [classes.toolbarTabsMargin]: !!tabs,
        })}
      >
        {children}
        {withBottomScrollPadding ? <BottomScrollPadding /> : null}
      </div>
      {speedDial}
      {floatingActionButton}
      {dialogs ? <div className="momentum-scroll-fix">{dialogs}</div> : null}
    </div>
  );
}
