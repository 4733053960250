// for use with Array.filter; the "is" form lets TypeScript infer the right
// type for the resulting array
export function notUndefined<T>(value: T | undefined): value is T {
  return value !== undefined;
}

export function notNull<T>(value: T | null): value is T {
  return value !== null;
}

export function notNullOrUndefined<T>(value: T | null | undefined): value is T {
  return value != null;
}
