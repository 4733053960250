import {Button, Theme, withStyles} from "@material-ui/core";

export const MapButton = withStyles((theme: Theme) => ({
  root: {
    "&:hover": {
      backgroundColor: theme.palette.common.white,
    },
    backgroundColor: theme.palette.common.white,
    borderRadius: 2,
    color: theme.palette.getContrastText(theme.palette.common.white),
    height: 40,
    margin: 10,
    padding: 0,
    width: 40,
  },
}))(Button);
