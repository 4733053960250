import {Machine, Task, WorkType, urlToId} from "@co-common-libs/resources";
import {getMachinesWorkTypeString, getWorkTypeString} from "@co-common-libs/resources-utils";
import {formatDateShort, notUndefined} from "@co-common-libs/utils";
import {
  actions,
  getCustomerSettings,
  getMachineLookup,
  getUserUserProfileLookup,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {MenuItem} from "@material-ui/core";
import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";

const DEFAULT_TASK_FORMAT = "<DAY>.<MONTH> - <EMPLOYEE ALIAS>: <WORK TYPE NAME>";

function isMachineArray(
  machineOrWorktype: WorkType | readonly Machine[] | null,
): machineOrWorktype is readonly Machine[] {
  return Array.isArray(machineOrWorktype);
}

const formatDefaultTaskText = (
  date: string,
  alias: string | undefined,
  machineOrWorktype: WorkType | readonly Machine[] | null,
): string => {
  let machineOrWorktypeString = "";
  if (isMachineArray(machineOrWorktype)) {
    machineOrWorktypeString = getMachinesWorkTypeString(machineOrWorktype, false);
  } else {
    machineOrWorktypeString = getWorkTypeString(machineOrWorktype || undefined, false);
  }

  if (alias && machineOrWorktypeString) {
    return `${date} - ${alias}: ${machineOrWorktypeString}`;
  } else if (alias) {
    return `${date} - ${alias}`;
  } else if (machineOrWorktypeString) {
    return `${date}: ${machineOrWorktypeString}`;
  } else {
    return date;
  }
};

const formatAlternativeTaskText = (
  alias: string | undefined,
  machineOrWorktype: WorkType | readonly Machine[] | null,
): string => {
  let machineOrWorktypeString = "";
  if (isMachineArray(machineOrWorktype)) {
    machineOrWorktypeString = getMachinesWorkTypeString(machineOrWorktype, true);
  } else {
    machineOrWorktypeString = getWorkTypeString(machineOrWorktype || undefined, true);
  }
  return [alias, machineOrWorktypeString].filter(Boolean).join(", ");
};

export const TaskMenuItem = React.forwardRef<
  HTMLLIElement,
  {
    task: Task;
  }
>(function TaskMenuItem({task}, ref): JSX.Element | null {
  const workTypeLookup = useSelector(getWorkTypeLookup);
  const userUserProfileLookup = useSelector(getUserUserProfileLookup);
  const customerSettings = useSelector(getCustomerSettings);
  const machineLookup = useSelector(getMachineLookup);
  const useDefaultTaskFormat = customerSettings.mapTaskFormat === DEFAULT_TASK_FORMAT;
  const dispatch = useDispatch();
  const {url} = task;
  const handleClick = useCallback(() => {
    const id = urlToId(url);
    dispatch(actions.go("/task/:id", {id}));
  }, [dispatch, url]);

  const workTypeOrMachines = customerSettings.noExternalTaskWorkType
    ? task.machineuseSet.map((machineUse) => machineLookup(machineUse.machine)).filter(notUndefined)
    : task.workType
      ? workTypeLookup(task.workType)
      : null;

  const machineOperatorProfile = task?.machineOperator
    ? userUserProfileLookup(task.machineOperator)
    : null;

  if (!task) {
    return null;
  }
  return (
    <MenuItem ref={ref} onClick={handleClick}>
      {(useDefaultTaskFormat
        ? formatDefaultTaskText(
            formatDateShort(task.workFromTimestamp || task.date),
            machineOperatorProfile?.alias,
            workTypeOrMachines || null,
          )
        : formatAlternativeTaskText(machineOperatorProfile?.alias, workTypeOrMachines || null)) ||
        "???"}
    </MenuItem>
  );
});
