import {Config} from "@co-common-libs/config";
import {ResponsiveDialog, TrimTextField} from "@co-frontend-libs/components";
import {DialogContent, FormControlLabel, IconButton, Radio, RadioGroup} from "@material-ui/core";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import DeleteIcon from "mdi-react/DeleteIcon";
import React from "react";
// Allowed for existing code...
// eslint-disable-next-line deprecate/import
import {Cell, Grid} from "react-flexr";
import {IntlContext, defineMessages} from "react-intl";

const messages = defineMessages({
  alternativeTitle: {
    defaultMessage: "Materiale detaljer",
    id: "material-entry-details-dialog.alternative-title",
  },
  notes: {
    defaultMessage: "Noter",
    id: "material-entry-details-dialog.notes",
  },
  oursLabel: {
    defaultMessage: "Varen tilhører os og skal faktureres til kunden",
    id: "material-entry-details-dialog.ours-label",
  },
  thiersLabel: {
    defaultMessage: "Varen tilhører kunden og skal ikke faktureres",
    id: "material-entry-details-dialog.thiers-label",
  },
  title: {
    defaultMessage: "Materiel detaljer",
    id: "material-entry-details-dialog.title",
  },
});

interface MaterialEntryDetailsDialogProps {
  customerSettings: Config;
  deleteable?: boolean;
  disabled?: boolean | undefined;
  name?: string | undefined;
  notes?: string | undefined;
  onCancel: () => void;
  onDeleteClick: () => void;
  onOk: (ours: boolean, notes: string) => void;
  open: boolean;
  ours?: boolean | undefined;
  showNotes?: boolean;
}

interface MaterialEntryDetailsDialogState {
  notes: string;
  ours: boolean;
}

export class MaterialEntryDetailsDialog extends PureComponent<
  MaterialEntryDetailsDialogProps,
  MaterialEntryDetailsDialogState
> {
  static defaultProps = {
    open: false,
    showNotes: true,
  };

  state: MaterialEntryDetailsDialogState = {
    notes: this.props.notes || "",
    ours: this.props.ours != null ? this.props.ours : true,
  };

  UNSAFE_componentWillReceiveProps(nextProps: MaterialEntryDetailsDialogProps): void {
    if (nextProps.open && !this.props.open) {
      this.setState({
        notes: nextProps.notes || "",
        ours: nextProps.ours != null ? nextProps.ours : true,
      });
    } else {
      if (nextProps.notes !== this.props.notes) {
        this.setState({notes: nextProps.notes || ""});
      }
      if (nextProps.ours !== this.props.ours) {
        this.setState({ours: nextProps.ours != null ? nextProps.ours : true});
      }
    }
  }

  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  @bind
  handleOk(): void {
    this.props.onOk(this.state.ours, this.state.notes);
  }

  @bind
  handleOursChange(_event: unknown, value: string): void {
    this.setState({ours: value === "true"});
  }

  @bind
  handleNotesChange(value: string): void {
    this.setState({notes: value});
  }

  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {customerSettings} = this.props;
    const {deleteable, disabled, name, onCancel, onDeleteClick, open} = this.props;

    let oursToggle;
    if (customerSettings.showOursToggle) {
      oursToggle = (
        <Grid>
          <Cell>
            <RadioGroup
              name="ours"
              value={this.state.ours ? "true" : "false"}
              onChange={this.handleOursChange}
            >
              <FormControlLabel
                control={<Radio />}
                disabled={!!disabled}
                label={formatMessage(messages.oursLabel)}
                value="true"
              />
              <FormControlLabel
                control={<Radio />}
                disabled={!!disabled}
                label={formatMessage(messages.thiersLabel)}
                value="false"
              />
            </RadioGroup>
          </Cell>
        </Grid>
      );
    }

    return (
      <ResponsiveDialog
        open={open}
        title={
          customerSettings.materialUseAlternativeText
            ? formatMessage(messages.alternativeTitle)
            : formatMessage(messages.title)
        }
        onCancel={onCancel}
        onOk={this.handleOk}
      >
        <DialogContent>
          <Grid>
            <Cell>{name}</Cell>
          </Grid>
          {oursToggle}
          {this.props.showNotes ? (
            <Grid>
              <Cell size="12/12">
                <TrimTextField
                  fullWidth
                  multiline
                  disabled={!!disabled}
                  id="notes-field"
                  margin="dense"
                  placeholder={formatMessage(messages.notes)}
                  value={this.state.notes}
                  variant="outlined"
                  onChange={this.handleNotesChange}
                />
              </Cell>
            </Grid>
          ) : null}
          <Grid>
            <Cell>
              <IconButton disabled={!deleteable} onClick={onDeleteClick}>
                <DeleteIcon />
              </IconButton>
            </Cell>
          </Grid>
        </DialogContent>
      </ResponsiveDialog>
    );
  }
}
