import bowser from "bowser";
import React, {useCallback} from "react";
import {defineMessages, useIntl} from "react-intl";
import {CustomTextField, TextFieldPropsWithoutOnChange} from "./custom-text-field";

const messages = defineMessages({
  providePositiveInteger: {
    defaultMessage: "Indtast et positivt heltal ",
    id: "positive-integer-field.error.provide-positive-integer",
  },
  providePositiveIntegerDigits: {
    defaultMessage: "Indtast et positivt heltal med maksimalt {digits, number} cifre",
    id: "positive-integer-field.error.provide-positive-integer-digits",
  },
});

interface PositiveIntegerFieldProps extends TextFieldPropsWithoutOnChange {
  errorText?: string;
  hideError?: boolean;
  maxDigits?: number;
  onChange: (value: number | null) => void;
  pattern?: string;
  step?: string;
  value: number | null;
}

const DEFAULT_MAX_DIGITS = 9;

export function PositiveIntegerField(props: PositiveIntegerFieldProps): JSX.Element {
  const mobile = !!bowser.mobile || !!bowser.tablet;
  const {
    maxDigits = DEFAULT_MAX_DIGITS,
    pattern = mobile ? "[0-9]*" : undefined,
    step = mobile ? "any" : undefined,
    type = mobile ? "number" : "text",
    ...other
  } = props;

  const {formatMessage} = useIntl();

  const validationError = useCallback(
    (text: string, browserValid?: boolean): string | undefined => {
      if (mobile && browserValid === false) {
        return formatMessage(messages.providePositiveInteger);
      }
      if (text) {
        const checkDigits = new RegExp(`^[+]?\\d{1,${maxDigits}}$`);
        if (!checkDigits.test(text.trim())) {
          const checkInteger = new RegExp("^[+]?\\d+$");
          if (!checkInteger.test(text.trim())) {
            // simplified message if it's not even an integer...
            return formatMessage(messages.providePositiveInteger);
          } else {
            return formatMessage(messages.providePositiveIntegerDigits, {
              digits: maxDigits,
            });
          }
        }
      }
      return undefined;
    },
    [formatMessage, maxDigits, mobile],
  );

  const valueFrom = useCallback(
    (text: string): number | null => {
      if (text && !validationError(text)) {
        const base = text.trim();
        return parseFloat(base);
      } else {
        return null;
      }
    },
    [validationError],
  );

  const textFrom = useCallback(
    (value: number | null): string => (value != null ? `${value}` : ""),
    [],
  );

  const extra = {pattern, step, type} as any;

  return (
    <CustomTextField<number>
      {...other}
      textFrom={textFrom}
      validationError={validationError}
      valueFrom={valueFrom}
      {...extra}
    />
  );
}
