import {dateToString, objectMerge, objectSet} from "@co-common-libs/utils";
import {SimpleInterval} from "./types";

export function splitSingleIntervalOnMidnight<T extends SimpleInterval>(interval: T): T[] {
  const fromDate = new Date(interval.fromTimestamp);
  const toDate = new Date(interval.toTimestamp);
  const midnight = new Date(fromDate);
  midnight.setHours(0, 0, 0, 0);
  midnight.setDate(midnight.getDate() + 1);
  console.assert(fromDate < midnight);
  console.assert(midnight <= toDate);
  const nextMidnight = new Date(midnight);
  nextMidnight.setDate(nextMidnight.getDate() + 1);
  const result: T[] = [];
  result.push(objectSet(interval, "toTimestamp", midnight.toISOString()));
  while (nextMidnight.valueOf() < toDate.valueOf()) {
    const dayInterval: SimpleInterval = {
      fromTimestamp: midnight.toISOString(),
      toTimestamp: nextMidnight.toISOString(),
    };
    result.push(objectMerge(interval, dayInterval as Partial<T>));
    midnight.setDate(midnight.getDate() + 1);
    nextMidnight.setDate(nextMidnight.getDate() + 1);
  }
  if (midnight < toDate) {
    result.push(objectSet(interval, "fromTimestamp", midnight.toISOString()));
  }
  return result;
}

export function splitOnMidnights<T extends SimpleInterval>(intervals: T[]): T[];
export function splitOnMidnights<T extends SimpleInterval>(intervals: readonly T[]): readonly T[] {
  if (intervals.length === 0) {
    return intervals;
  }
  const firstFromDate = new Date(intervals[0].fromTimestamp);
  const lastToDate = new Date(intervals[intervals.length - 1].toTimestamp);
  if (dateToString(firstFromDate) === dateToString(lastToDate)) {
    return intervals;
  }
  const result: T[] = [];
  for (let i = 0; i < intervals.length; i += 1) {
    const interval = intervals[i];
    const {fromTimestamp, toTimestamp} = interval;
    const fromDate = new Date(fromTimestamp);
    const toDate = new Date(toTimestamp);
    if (
      fromDate.getFullYear() === toDate.getFullYear() &&
      fromDate.getMonth() === toDate.getMonth() &&
      fromDate.getDate() === toDate.getDate()
    ) {
      result.push(interval);
    } else {
      Array.prototype.push.apply(result, splitSingleIntervalOnMidnight(interval));
    }
  }
  return result;
}
