import {ProjectAccess} from "@co-common-libs/resources";
import {useIntl} from "react-intl";
import {match} from "ts-pattern";

export interface ProjectAccessTextProps {
  access: ProjectAccess;
}

export function ProjectAccessText({access}: ProjectAccessTextProps): string {
  const intl = useIntl();
  return match({access})
    .returnType<string>()
    .with({access: ProjectAccess.Barred}, () => intl.formatMessage({defaultMessage: "Spærret"}))
    .with({access: ProjectAccess.Closed}, () => intl.formatMessage({defaultMessage: "Afsluttet"}))
    .with({access: ProjectAccess.Hidden}, () => intl.formatMessage({defaultMessage: "Skjult"}))
    .with({access: ProjectAccess.Open}, () => intl.formatMessage({defaultMessage: "Åben"}))
    .exhaustive();
}
