import {ReportingSpecification, Task} from "@co-common-libs/resources";
import {getInputSpecificationsMap} from "@co-common-libs/resources-utils";
import {
  getCurrentRole,
  getCustomerSettings,
  getLocationLookup,
  getPriceItemLookup,
  getProductGroupLookup,
  getProductLookup,
  getUnitLookup,
} from "@co-frontend-libs/redux";
import {Card, CardMedia, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {legacyGetAutoProductsMapping} from "app-utils";
import _ from "lodash";
import React, {useMemo} from "react";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {getDataColumns, getMaterialColumns} from "../../reporting-helpers";
import {RotatedTableHeaderColumn} from "./rotated-table-header-column";
import {TableEntry} from "./table-entry";

interface LogTableProps {
  logSpecification: ReportingSpecification;
  onRequestEditEntry: (identifier: string) => void;
  readonly: boolean;
  task: Task;
}

export function LogTable(props: LogTableProps): JSX.Element | null {
  const {logSpecification, onRequestEditEntry, readonly, task} = props;
  const currentRole = useSelector(getCurrentRole);
  const customerSettings = useSelector(getCustomerSettings);
  const locationLookup = useSelector(getLocationLookup);
  const priceItemLookup = useSelector(getPriceItemLookup);
  const productGroupLookup = useSelector(getProductGroupLookup);
  const productLookup = useSelector(getProductLookup);
  const unitLookup = useSelector(getUnitLookup);

  const inputSpecificationsMap = useMemo(
    () => getInputSpecificationsMap(logSpecification),
    [logSpecification],
  );
  const isManager = currentRole && currentRole.manager;
  const [logHeaderColumns, logColumnPositions, includePriceItems] = useMemo(
    () => getDataColumns(logSpecification),
    [logSpecification],
  );

  let productUseList = _.sortBy(
    Object.values(task.productUses || {}),
    (productUse) => productUse.order,
  );
  if (!isManager && !customerSettings.showMaterialAutoLinesToEmployees) {
    const {autoLinesToLines} = legacyGetAutoProductsMapping(
      productUseList,
      productLookup,
      productGroupLookup,
      customerSettings,
    );
    if (autoLinesToLines.size) {
      productUseList = productUseList.filter((_value, index) => !autoLinesToLines.has(index));
    }
  }

  const priceItemUseSet = useMemo(
    () => _.sortBy(Object.values(task.priceItemUses || {}), (priceItemUse) => priceItemUse.order),
    [task.priceItemUses],
  );

  const {materialColumnPositions, materialHeaderColumns} = getMaterialColumns(
    priceItemLookup,
    unitLookup,
    includePriceItems,
    priceItemUseSet,
    logSpecification.showLocationProducts,
    productUseList,
    productLookup,
    task.reportingLog,
  );

  const sortedLogData = useMemo(
    () =>
      _.sortBy(Object.entries(task.reportingLog || {}), [
        ([_identifier, entry]) => entry.deviceTimestamp,
        ([_identifier, entry]) => entry.location,
        ([_identifier, entry]) => entry.order,
        ([identifier, _entry]) => identifier,
      ]).reverse(),
    [task.reportingLog],
  );

  const entries = sortedLogData.map(([identifier, data]) => (
    <TableEntry
      key={identifier}
      customerSettings={customerSettings}
      data={data}
      identifier={identifier}
      includePriceItems={includePriceItems}
      includeProducts={logSpecification.showLocationProducts}
      inputSpecificationsMap={inputSpecificationsMap}
      locationLookup={locationLookup}
      logColumnPositions={logColumnPositions}
      materialColumnPositions={materialColumnPositions}
      readonly={readonly}
      reportingLocations={task.reportingLocations || {}}
      showCustomer={logSpecification.showCustomerOnEntryTable}
      onRequestEditEntry={onRequestEditEntry}
    />
  ));

  return (
    <Card style={{margin: "1em"}}>
      <CardMedia>
        <Table style={{tableLayout: "auto"}}>
          <TableHead>
            <TableRow>
              <RotatedTableHeaderColumn>
                <FormattedMessage defaultMessage="Kl." id="task-instance.table-header.time" />
              </RotatedTableHeaderColumn>
              {logHeaderColumns}
              {materialHeaderColumns}
              <RotatedTableHeaderColumn width={60}>
                <FormattedMessage defaultMessage="Sted" />
              </RotatedTableHeaderColumn>
              {logSpecification.showCustomerOnEntryTable ? (
                <RotatedTableHeaderColumn width={60}>
                  <FormattedMessage defaultMessage="Kunde" />
                </RotatedTableHeaderColumn>
              ) : null}
              <TableCell style={{width: 48}} />
            </TableRow>
          </TableHead>
          <TableBody
            style={{
              cursor: !readonly ? "pointer" : "auto",
            }}
          >
            {entries}
          </TableBody>
        </Table>
      </CardMedia>
    </Card>
  );
}
