import {ResponsiveInfoDialog} from "@co-frontend-libs/components";
import {getCustomerSettings} from "@co-frontend-libs/redux";
import {DialogContent} from "@material-ui/core";
import React from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";

interface MaterialWarningDialogProps {
  onClose: () => void;
  open: boolean;
}

export const MaterialWarningDialog = React.memo(function MaterialWarningDialog(
  props: MaterialWarningDialogProps,
): JSX.Element {
  const {onClose, open} = props;
  const intl = useIntl();
  const customerSettings = useSelector(getCustomerSettings);

  return (
    <ResponsiveInfoDialog
      autoFocusClose
      open={open}
      title={
        customerSettings.materialUseAlternativeText
          ? intl.formatMessage({defaultMessage: "Materialer udfyldt"})
          : intl.formatMessage({defaultMessage: "Materiel udfyldt"})
      }
      onClose={onClose}
    >
      <DialogContent>
        {customerSettings.materialUseAlternativeText
          ? intl.formatMessage({
              defaultMessage:
                "Kan ikke aktivere log da der er indtastet værdier under materialer. Slet værdierne hvis du ønsker at aktivere loggen og prøv igen.",
            })
          : intl.formatMessage({
              defaultMessage:
                "Kan ikke aktivere log da der er indtastet værdier under materiel. Slet værdierne hvis du ønsker at aktivere loggen og prøv igen.",
            })}
      </DialogContent>
    </ResponsiveInfoDialog>
  );
});
