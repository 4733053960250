import {ResourceName, ResourceTypes, ResourceUrls} from "@co-common-libs/resources";
import {Check, Query, makeQuery} from "@co-frontend-libs/db-resources";
import {
  AppState,
  actions,
  getPathName,
  getSyncedState,
  makePathParameterGetter,
} from "@co-frontend-libs/redux";
import {instanceURL} from "frontend-global-config";
import {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";

const TEMPORARY_QUERIES_KEY = "useLoadInstance";

export type LoadInstanceRelatedChain = {
  readonly memberName: string;
  readonly related?: readonly LoadInstanceRelatedChain[];
  readonly resourceType: ResourceName;
  readonly type: "hasForeignKey" | "targetOfForeignKey";
};

export type LoadInstanceRelated = readonly LoadInstanceRelatedChain[];

export function useLoadInstance<Resource extends ResourceName>(
  resourceName: Resource,
  lookupSelector: (
    state: AppState,
  ) => (url: ResourceUrls[Resource]) => ResourceTypes[Resource] | undefined,
  idSelector?: (state: AppState) => string,
  related?: LoadInstanceRelated,
):
  | [null, "FETCHING" | "MISSING" | "OFFLINE", null]
  | [null, null, Error]
  | [ResourceTypes[Resource], null, null] {
  const idSelectorOrFallback = useMemo(
    () => idSelector ?? makePathParameterGetter("id"),
    [idSelector],
  );
  const id = useSelector(idSelectorOrFallback);
  const pathName = useSelector(getPathName);
  const lookup = useSelector(lookupSelector);

  const getResourceSyncedState = useMemo(
    () => getSyncedState.bind(null, resourceName),
    [resourceName],
  );
  const syncedState = useSelector(getResourceSyncedState);

  const url = instanceURL(resourceName, id);

  const instanceCheck = useMemo(
    (): Check => ({
      memberName: "url",
      type: "memberEq",
      value: url,
    }),
    [url],
  );

  const instanceQuery = useMemo((): Query => {
    return makeQuery({
      check: instanceCheck,
      independentFetch: true,
      instance: id,
      resourceName,
    });
  }, [id, instanceCheck, resourceName]);

  const queries = useMemo((): readonly Query[] => {
    const result = [instanceQuery];
    if (related) {
      const processRelated = (
        parentCheck: Check,
        parentType: ResourceName,
        entry: LoadInstanceRelatedChain,
      ): void => {
        const {memberName, related: innerRelated, resourceType, type} = entry;
        const check: Check =
          type === "hasForeignKey"
            ? {
                check: parentCheck,
                memberName,
                targetType: parentType,
                type: "hasForeignKey",
              }
            : {
                check: parentCheck,
                fromResource: parentType,
                memberName,
                type: "targetOfForeignKey",
              };
        result.push(
          makeQuery({
            check,
            independentFetch: false,
            resourceName: resourceType,
          }),
        );
        if (innerRelated) {
          innerRelated.forEach(processRelated.bind(null, check, resourceType));
        }
      };
      related.forEach(processRelated.bind(null, instanceCheck, resourceName));
    }
    return result;
  }, [instanceCheck, instanceQuery, related, resourceName]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      actions.temporaryQueriesRequestedForPath(queries, pathName, TEMPORARY_QUERIES_KEY, true),
    );
    dispatch(actions.requestChangesFetch());
    return () => {
      dispatch(actions.temporaryQueriesDiscardedForPath(pathName, TEMPORARY_QUERIES_KEY));
    };
  }, [dispatch, pathName, queries]);

  const instance = lookup(url);
  if (instance) {
    return [instance, null, null];
  }
  const querySyncedState = syncedState.get(instanceQuery.keyString);
  if (!querySyncedState || querySyncedState.queryState.currentlyFullFetching) {
    return [null, "FETCHING", null];
  }
  const error = querySyncedState.queryState.lastError;
  const notFoundStatusCode = 404;
  if (!error) {
    return [null, "MISSING", null];
  } else if (error.type === "StatusError" && error.statusCode === notFoundStatusCode) {
    return [null, "MISSING", null];
  } else if (error.type === "NetworkError") {
    return [null, "OFFLINE", null];
  } else {
    return [null, null, error];
  }
}
