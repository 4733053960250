import {Patch, PatchOperation, Task} from "@co-common-libs/resources";
import _ from "lodash";
import {ProvisionaryCommand} from "../../resources/actions";
import {ResourcesAuthenticationMiddlewareAPI} from "../types";

export function removeDanglingZeroPriceItemUses(
  newTask: Task | null,
  oldTask: Task | undefined,
  _middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  _command: ProvisionaryCommand,
): Patch<Task> | null {
  if (!newTask) {
    return null;
  }

  if (_.isEqual(newTask.priceItemUses, oldTask?.priceItemUses)) {
    // no price item use changes
    return null;
  }
  const priceItemUses = Object.values(newTask.priceItemUses || {});
  if (
    !priceItemUses.length ||
    priceItemUses.every(({correctedCount, count, dangling}) => !dangling || count || correctedCount)
  ) {
    // no dangling that can be removed
    return null;
  }
  const patch: PatchOperation<Task>[] = [];
  Object.entries(newTask.priceItemUses || {}).forEach(([identifier, priceItemUse]) => {
    const {correctedCount, count, dangling} = priceItemUse;
    if (dangling && !count && !correctedCount) {
      patch.push({path: ["priceItemUses", identifier], value: undefined});
    }
  });
  console.assert(patch.length);
  return patch;
}
