import {
  Command,
  Patch,
  ResourceName,
  ResourceTypes,
  applyPatch,
  resourceNames,
} from "@co-common-libs/resources";
import {CreateCommandPromise, ResourceInstanceRecords} from "../types";

export type ResourceCommitCommands = {
  readonly [P in keyof ResourceTypes]: readonly (Command | CreateCommandPromise)[];
};

function combineResourceOfflineData<T extends ResourceName>(
  persisted: ResourceInstanceRecords[T],
  temporary: ResourceInstanceRecords[T],
): ResourceInstanceRecords[T] {
  if (!Object.keys(temporary).length) {
    return persisted;
  } else if (!Object.keys(persisted).length) {
    return temporary;
  } else {
    return {...persisted, ...temporary};
  }
}

export function combineResourceOfflineCommittingData<T extends ResourceName>(
  offlineData: ResourceInstanceRecords[T],
  committing?: readonly (Command | CreateCommandPromise)[],
): ResourceInstanceRecords[T] {
  if (!committing || !committing.length) {
    return offlineData;
  }
  const result: Partial<{
    [url: string]: Readonly<ResourceTypes[T]>;
  }> = {
    ...(offlineData as Partial<{
      readonly [url: string]: Readonly<ResourceTypes[T]>;
    }>),
  };
  committing.forEach((command) => {
    const {url} = command;
    if (command.action === "CREATE") {
      result[url] = command.instance as ResourceTypes[T];
    } else if (command.action === "UPDATE") {
      const existing = (result[url] || {url}) as ResourceTypes[T];
      const combined = applyPatch(existing, command.patch as Patch<ResourceTypes[T]>);
      result[url] = combined as ResourceTypes[T];
    } else if (command.action === "DELETE") {
      delete result[url];
    }
  });
  return result as any;
}

export function combineAllResourceData(
  persistedData: ResourceInstanceRecords,
  temporaryData: ResourceInstanceRecords,
  committingData: Partial<{
    readonly [P in keyof ResourceTypes]: readonly (Command | CreateCommandPromise)[];
  }>,
): ResourceInstanceRecords {
  const result = Object.fromEntries(
    resourceNames.map((resourceName) => [
      resourceName,
      combineResourceOfflineCommittingData(
        combineResourceOfflineData(persistedData[resourceName], temporaryData[resourceName]),
        committingData[resourceName],
      ),
    ]),
  );
  return result as ResourceInstanceRecords;
}

export function combineOfflineData(
  persistedData: ResourceInstanceRecords,
  temporaryData: ResourceInstanceRecords,
): ResourceInstanceRecords {
  const result = Object.fromEntries(
    resourceNames.map((resourceName) => [
      resourceName,
      combineResourceOfflineData(persistedData[resourceName], temporaryData[resourceName]),
    ]),
  );
  return result as ResourceInstanceRecords;
}

export function combineOfflineCommittingData(
  offlineData: ResourceInstanceRecords,
  committingData: ResourceCommitCommands,
): ResourceInstanceRecords {
  const result = Object.fromEntries(
    resourceNames.map((resourceName) => [
      resourceName,
      combineResourceOfflineCommittingData(offlineData[resourceName], committingData[resourceName]),
    ]),
  );
  return result as ResourceInstanceRecords;
}
