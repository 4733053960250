import {
  MachineGroupUrl,
  MachineUrl,
  PriceGroupUrl,
  ProductUrl,
  ReportingSpecificationUrl,
} from "../resource-urls";
import {ResourceInstance} from "./base";

export interface Machine extends ResourceInstance<MachineUrl> {
  readonly active: boolean;
  readonly barred: boolean;
  readonly c5_machine: string;
  readonly c5_recid: number | null;
  readonly canPull: boolean | null;
  readonly department: string;
  readonly fuelConsumptionLiterPerHour: number | null | undefined;
  readonly hasHaMeter: boolean | null;
  readonly hourlyTowingCostEffective: number | null;
  readonly hourlyTowingCostTransport: number | null;
  readonly machineGroup: MachineGroupUrl | null;
  readonly name: string;
  readonly pricegroups: readonly PriceGroupUrl[];
  readonly products: readonly ProductUrl[];
  readonly remoteUrl: string;
  readonly reportingSpecification: ReportingSpecificationUrl | null;
  readonly requireAtLeastOneProductUseGreaterThanZero: boolean;
  readonly requirePhotoOnTaskCompletion: boolean | undefined;
  readonly selfPropelled: boolean | null;
  readonly smallMachine: boolean;
  readonly vehicleIdentificationNumber: string;
}

export const emptyMachine: Omit<Machine, "id" | "url"> = {
  active: true,
  barred: false,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  c5_machine: "",
  // eslint-disable-next-line @typescript-eslint/naming-convention
  c5_recid: null,
  canPull: null,
  department: "",
  fuelConsumptionLiterPerHour: null,
  hasHaMeter: null,
  hourlyTowingCostEffective: null,
  hourlyTowingCostTransport: null,
  machineGroup: null,
  name: "",
  pricegroups: [],
  products: [],
  remoteUrl: "",
  reportingSpecification: null,
  requireAtLeastOneProductUseGreaterThanZero: false,
  requirePhotoOnTaskCompletion: false,
  selfPropelled: null,
  smallMachine: false,
  vehicleIdentificationNumber: "",
};
