import {Config} from "@co-common-libs/config";
import {PatchUnion, Task, Timer, TimerUrl} from "@co-common-libs/resources";
import {HOUR_MINUTES, MINUTE_SECONDS, SECOND_MILLISECONDS} from "@co-common-libs/utils";
import {ThrottledTextField, TimeField} from "@co-frontend-libs/components";
import {PunchWorkPeriod} from "@co-frontend-libs/redux";
import {Card, CardContent, Grid} from "@material-ui/core";
import {bind} from "bind-decorator";
import React from "react";
import {FormattedMessage, IntlContext, defineMessages} from "react-intl";
import {TimeButton} from "./time-button";
import {TimerButtonWithNotes} from "./timer-button-with-notes";

const messages = defineMessages({
  doneEstimate: {
    defaultMessage: "Hvornår er du færdig?",
    id: "task-instance.label.done-estimate",
  },
  invoiceNote: {
    defaultMessage: "Faktura note",
    id: "task-instance.label.invoice-note",
  },
  notes: {defaultMessage: "Noter"},
  taskInternalManagerNotes: {
    defaultMessage: "Interne noter",
  },
});

interface TimerButtonCardProps {
  activeTimer?: TimerUrl | undefined;
  completed: boolean;
  customerSettings: Config;
  genericPrimaryTimer: Timer;
  genericPrimaryTimerLabel: string;
  hidePrimaryButton: boolean;
  onNotesChange: (timerURL: TimerUrl, notes: string) => void;
  onTimerButton: (timerURL: TimerUrl) => void;
  punchInOutPeriodsForCurrentUser: readonly PunchWorkPeriod[];
  secondaryTimers: ReadonlySet<Timer>;
  task: Task;
  timerMinutes: ReadonlyMap<string, number>;
  update: (url: string, patch: PatchUnion) => void;
  userIsOnlyMachineOperator: boolean;
  userIsOther: boolean;
  userIsOtherMachineOperator: boolean;
  validated: boolean;
}

export class TimerButtonCard extends React.Component<TimerButtonCardProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  getDoneAtEstimateHours(): string {
    const {doneAtEstimate} = this.props.task;
    if (!doneAtEstimate) {
      return "";
    }
    const now = new Date();
    const {workFromTimestamp} = this.props.task;
    const fromTimestamp = workFromTimestamp ? new Date(workFromTimestamp) : new Date(now);
    const estimate = new Date(fromTimestamp);
    const [estimateHours, estimateMinutes] = doneAtEstimate.split(":");
    estimate.setHours(parseInt(estimateHours), parseInt(estimateMinutes), 0, 0);
    if (estimate < fromTimestamp) {
      estimate.setDate(estimate.getDate() + 1);
    }
    const msDifference = estimate.valueOf() - now.valueOf();
    if (msDifference < 0) {
      return "00:00:00";
    }
    let seconds = Math.round(msDifference / SECOND_MILLISECONDS);
    let minutes = Math.floor(seconds / MINUTE_SECONDS);
    const hours = Math.floor(minutes / HOUR_MINUTES);

    const digits = 2;
    const hoursString = `${hours}`.padStart(digits, "0");
    minutes = minutes - hours * HOUR_MINUTES;
    const minutesString = `${minutes}`.padStart(digits, "0");
    seconds = Math.floor(seconds % MINUTE_SECONDS);
    const secondsString = `${seconds}`.padStart(digits, "0");
    return `${hoursString}:${minutesString}:${secondsString}`;
  }

  @bind
  handleDoneAtEstimateChange(value: string | null): void {
    const {task, update} = this.props;
    update(task.url, [{member: "doneAtEstimate", value}]);
  }
  @bind
  handleNotesFromMachineOperatorChange(value: string): void {
    const {task, update} = this.props;
    update(task.url, [{member: "notesFromMachineOperator", value}]);
  }
  @bind
  handleInvoiceNoteChange(value: string): void {
    const {task, update} = this.props;
    update(task.url, [{member: "invoiceNote", value}]);
  }

  @bind
  handleTaskInternalManagerNotesChange(value: string): void {
    const {task, update} = this.props;
    update(task.url, [{member: "managerInternalNotes", value}]);
  }

  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {
      activeTimer,
      completed,
      customerSettings,
      genericPrimaryTimer,
      genericPrimaryTimerLabel,
      hidePrimaryButton,
      onNotesChange,
      onTimerButton,
      secondaryTimers,
      task,
      timerMinutes,
      userIsOnlyMachineOperator,
      userIsOther,
      userIsOtherMachineOperator,
      validated,
    } = this.props;

    const primaryTimerURL = genericPrimaryTimer.url;

    let currentlyPunchedIn = false;
    if (customerSettings.usePunchInOut) {
      const {punchInOutPeriodsForCurrentUser} = this.props;
      if (punchInOutPeriodsForCurrentUser.length) {
        const lastPunchInOutPeriod =
          punchInOutPeriodsForCurrentUser[punchInOutPeriodsForCurrentUser.length - 1];
        currentlyPunchedIn = !lastPunchInOutPeriod.punchOut;
      }
    }
    const allTimersDisabled = completed || validated || userIsOther;
    const allCorrectionsDisabled =
      validated || (completed && userIsOnlyMachineOperator) || userIsOtherMachineOperator;

    let primaryTimerButton: JSX.Element | null = null;
    if (!hidePrimaryButton) {
      primaryTimerButton = (
        <Grid item xs={12}>
          <TimeButton
            correctionDisabled={allCorrectionsDisabled}
            isActive={activeTimer === primaryTimerURL}
            label={genericPrimaryTimerLabel}
            minutes={timerMinutes.get(primaryTimerURL) || 0}
            startDisabled={!!(customerSettings.usePunchInOut && !currentlyPunchedIn)}
            timer={genericPrimaryTimer}
            timerDisabled={allTimersDisabled}
            onTimerButton={onTimerButton}
          />
        </Grid>
      );
    }

    const secondaryTimerButtons = Array.from(secondaryTimers).map((timer) => {
      let timerNote;
      if (task.timernotesSet) {
        const timerNoteObject = task.timernotesSet.find((entry) => entry.timer === timer.url);
        timerNote = timerNoteObject ? timerNoteObject.notes : undefined;
      }
      return (
        <Grid key={timer.url} item xs={12}>
          <TimerButtonWithNotes
            activeTimer={activeTimer}
            allCorrectionsDisabled={allCorrectionsDisabled}
            allTimersDisabled={allTimersDisabled}
            startDisabled={!!(customerSettings.usePunchInOut && !currentlyPunchedIn)}
            timer={timer}
            timerMinutes={timerMinutes}
            timerNote={timerNote}
            onNotesChange={onNotesChange}
            onTimerButton={onTimerButton}
          />
        </Grid>
      );
    });

    let doneAtEstimateCells;
    if (this.props.customerSettings.enableDoneAtEstimateField) {
      doneAtEstimateCells = [
        <Grid key="doneAteEstimateField" item xs={12}>
          <TimeField
            fullWidth
            disabled={validated || userIsOtherMachineOperator}
            label={formatMessage(messages.doneEstimate)}
            margin="dense"
            value={task.doneAtEstimate || undefined}
            onChange={this.handleDoneAtEstimateChange}
          />
        </Grid>,
        <Grid key="doneAteEstimateMessage" item xs={12}>
          <FormattedMessage
            defaultMessage="Færdig om: {hours}"
            id="order-instance.task-instance.estimate"
            values={{
              hours: this.getDoneAtEstimateHours(),
            }}
          />
        </Grid>,
      ];
    }

    return (
      <Card style={{margin: "1em"}}>
        <CardContent>
          <Grid container spacing={3}>
            {primaryTimerButton}
            {secondaryTimerButtons}
            <Grid item xs={12}>
              <ThrottledTextField
                fullWidth
                multiline
                disabled={
                  (validated &&
                    (userIsOnlyMachineOperator ||
                      !this.props.customerSettings.alwaysAllowManagerTaskAndOrderNotesEdit)) ||
                  userIsOtherMachineOperator
                }
                label={formatMessage(messages.notes)}
                margin="dense"
                maxRows={30}
                minRows={2}
                value={task.notesFromMachineOperator}
                variant="outlined"
                onChange={this.handleNotesFromMachineOperatorChange}
              />
            </Grid>
            {!userIsOnlyMachineOperator && customerSettings.enableTaskInternalManagerNotes ? (
              <Grid item xs={12}>
                <ThrottledTextField
                  fullWidth
                  multiline
                  disabled={validated}
                  label={formatMessage(messages.taskInternalManagerNotes)}
                  margin="dense"
                  maxRows={30}
                  minRows={2}
                  value={task.managerInternalNotes}
                  variant="outlined"
                  onChange={this.handleTaskInternalManagerNotesChange}
                />
              </Grid>
            ) : null}
            {(!userIsOnlyMachineOperator || customerSettings.machineOperaterCanEditInvoiceNote) &&
            customerSettings.showInvoiceNote &&
            task.order ? (
              <Grid item xs={12}>
                <ThrottledTextField
                  fullWidth
                  multiline
                  disabled={validated}
                  label={formatMessage(messages.invoiceNote)}
                  margin="dense"
                  maxRows={30}
                  minRows={2}
                  value={task.invoiceNote}
                  variant="outlined"
                  onChange={this.handleInvoiceNoteChange}
                />
              </Grid>
            ) : null}
            {doneAtEstimateCells}
          </Grid>
        </CardContent>
      </Card>
    );
  }
}
