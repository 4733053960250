import {RoutePlan, RoutePlanUrl, WorkType, WorkTypeUrl} from "@co-common-libs/resources";
import {memoizeForceReuse} from "@co-frontend-libs/utils";
import React, {useMemo} from "react";
import {defineMessages, useIntl} from "react-intl";
import type {Writable} from "ts-essentials";
import {
  EntryData,
  GenericMultiSelectionSearchDialog,
  GenericSingleSelectionSearchDialog,
} from "../search-dialog";

const messages = defineMessages({
  searchRoute: {
    defaultMessage: "Søg rute",
    id: "route-dialog.dialog-title.search-route",
  },
  selectRoute: {
    defaultMessage: "Vælg rute",
    id: "route-dialog.dialog-title.select-route",
  },
});

function computeBaseChoices(
  routePlanArray: readonly RoutePlan[],
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined,
): readonly EntryData<RoutePlanUrl>[] {
  return routePlanArray.map((instance) => {
    const {url} = instance;
    const {name} = instance;
    const workTypeURL = instance.workType;
    const workType = workTypeURL ? workTypeLookup(workTypeURL) : null;
    const workTypeIdentifier = workType ? workType.identifier : "";
    const workTypeName = workType ? workType.name : "";
    const entry: Writable<EntryData<RoutePlanUrl>> = {
      category: "standard",
      exactMatchValue: name,
      identifier: url,
      primaryText: name,
      searchFields: [
        {label: "Navn", priority: 10, text: name},
        {label: "Arbejdsområde-ID", priority: 7, text: workTypeIdentifier},
        {label: "Arbejdsområde-navn", priority: 5, text: workTypeName},
      ],
    };
    if (workTypeIdentifier) {
      entry.secondaryText = `${workTypeIdentifier}: ${workTypeName}`;
    }
    return entry;
  });
}

interface RoutePlanDialogProps {
  onCancel(): void;
  onOk(url: RoutePlanUrl): void;
  open: boolean;
  routePlanArray: readonly RoutePlan[];
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
}

export const RoutePlanDialog = React.memo(function RoutePlanDialog(props: RoutePlanDialogProps) {
  const {onCancel, onOk, open, routePlanArray, workTypeLookup} = props;
  const intl = useIntl();
  const title = intl.formatMessage(messages.selectRoute);
  const searchTitle = intl.formatMessage(messages.searchRoute);

  const [doComputeBaseChoices, reuseBaseChoices] = useMemo(
    () => memoizeForceReuse(computeBaseChoices, []),
    [],
  );
  const getBaseChoices = open ? doComputeBaseChoices : reuseBaseChoices;
  const data = getBaseChoices(routePlanArray, workTypeLookup);

  return (
    <GenericSingleSelectionSearchDialog<RoutePlanUrl>
      data={data}
      mobilePrimaryLines={1}
      mobileSearchPrimaryLines={2}
      mobileSearchSecondaryLines={1}
      mobileSecondaryLines={1}
      open={open}
      searchTitle={searchTitle}
      title={title}
      onCancel={onCancel}
      onOk={onOk}
    />
  );
});

interface MutliRoutePlanDialogProps {
  includeSelectAll?: boolean;
  onCancel(): void;
  onOk(urls: ReadonlySet<RoutePlanUrl>): void;
  open: boolean;
  routePlanArray: readonly RoutePlan[];
  selected?: ReadonlySet<RoutePlanUrl>;
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
}

export function MultiRoutePlanDialog(props: MutliRoutePlanDialogProps): JSX.Element {
  const {includeSelectAll, onCancel, onOk, open, routePlanArray, selected, workTypeLookup} = props;
  const {formatMessage} = useIntl();
  const title = formatMessage(messages.selectRoute);
  const searchTitle = formatMessage(messages.searchRoute);
  const [doComputeBaseChoices, reuseBaseChoices] = useMemo(
    () => memoizeForceReuse(computeBaseChoices, []),
    [],
  );
  const getBaseChoices = open ? doComputeBaseChoices : reuseBaseChoices;
  const data = getBaseChoices(routePlanArray, workTypeLookup);
  return (
    <GenericMultiSelectionSearchDialog<RoutePlanUrl>
      data={data}
      includeSelectAll={includeSelectAll}
      mobilePrimaryLines={1}
      mobileSearchPrimaryLines={2}
      mobileSearchSecondaryLines={1}
      mobileSecondaryLines={1}
      open={open}
      searchTitle={searchTitle}
      selected={selected}
      title={title}
      onCancel={onCancel}
      onOk={onOk}
    />
  );
}
