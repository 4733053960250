import {User, UserProfile} from "@co-common-libs/resources";

export type UserCreateData = Pick<User, "loginIdentifier" | "password">;

export type UserEditData = Pick<User, "loginIdentifier">;

export const userProfileDataFields = [
  "address",
  "alias",
  "cellphone",
  "cellphoneExtra",
  "city",
  "email",
  "employeeNumber",
  "name",
  "note",
  "postalCode",
] as const satisfies readonly (keyof UserProfile)[];

type UserProfileDataFields = (typeof userProfileDataFields)[number];

export type UserProfileData = Pick<UserProfile, UserProfileDataFields>;
