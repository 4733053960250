import {Config} from "@co-common-libs/config";
import {MINUTE_MILLISECONDS, simpleComparator} from "@co-common-libs/utils";
import {PureComponent} from "app-utils";
import ImmutableDate from "bloody-immutable-date";
import _ from "lodash";
import React from "react";
import {ErrorBlock} from "./error-block";
import {TaskWithRelations} from "./utils";

interface TrackingIssuesProps {
  calendarFromTimestamp: ImmutableDate;
  calendarToTimestamp: ImmutableDate;
  completedTasks: readonly TaskWithRelations[];
  customerSettings: Config;
  incompleteTasks: readonly TaskWithRelations[];
  now: string;
}

export class TrackingIssues extends PureComponent<TrackingIssuesProps> {
  render(): JSX.Element | null {
    const {
      calendarFromTimestamp,
      calendarToTimestamp,
      completedTasks,
      customerSettings,
      incompleteTasks,
      now,
    } = this.props;

    const intervals: {
      fromTimestamp: string;
      toTimestamp: string;
    }[] = [];

    const tasks = completedTasks.concat(incompleteTasks);

    if (
      customerSettings.concurrentTasksAllowed ||
      customerSettings.concurrentTasksAllowedForDepartments.length ||
      customerSettings.concurrentTasksAllowedForWorkTypes.length
    ) {
      const overlapLegalIntervals: {
        fromTimestamp: string;
        toTimestamp: string;
      }[] = [];
      tasks.forEach((task) => {
        const workTypeIdentifier = task.workType?.identifier;
        const {department} = task.task;
        if (
          customerSettings.concurrentTasksAllowed ||
          (workTypeIdentifier &&
            customerSettings.concurrentTasksAllowedForWorkTypes.includes(workTypeIdentifier)) ||
          (!department &&
            customerSettings.concurrentTasksAllowedForDepartments.includes(department))
        ) {
          overlapLegalIntervals.push(...task.intervalsInPeriod);
        } else {
          intervals.push(...task.intervalsInPeriod);
        }
      });
      if (overlapLegalIntervals.length) {
        overlapLegalIntervals.sort((a, b) => simpleComparator(a.fromTimestamp, b.fromTimestamp));
        let {fromTimestamp: previousFrom, toTimestamp: previousTo} = overlapLegalIntervals[0];
        overlapLegalIntervals.slice(1).forEach(({fromTimestamp, toTimestamp}) => {
          if (fromTimestamp > previousTo) {
            intervals.push({
              fromTimestamp: previousFrom,
              toTimestamp: previousTo,
            });
            previousFrom = fromTimestamp;
            previousTo = toTimestamp;
          } else if (toTimestamp > previousTo) {
            previousTo = toTimestamp;
          }
        });
        intervals.push({
          fromTimestamp: previousFrom,
          toTimestamp: previousTo,
        });
      }
    } else {
      tasks.forEach(({intervalsInPeriod}) => intervals.push(...intervalsInPeriod));
    }
    intervals.sort((a, b) => simpleComparator(a.fromTimestamp, b.fromTimestamp));

    if (!intervals.length) {
      return null;
    }
    const {
      taskOverlapWarningAfterMinutes,
      unregisteredBreakAfterMinutes,
      unregisteredWarningAfterMinutes,
    } = this.props.customerSettings;
    const unregisteredBreakAfterMilliseconds = unregisteredBreakAfterMinutes * MINUTE_MILLISECONDS;
    const taskOverlapWarningAfterMilliseconds =
      taskOverlapWarningAfterMinutes * MINUTE_MILLISECONDS;
    const unregisteredWarningAfterMilliseconds =
      unregisteredWarningAfterMinutes * MINUTE_MILLISECONDS;
    const missing: {
      fromTimestamp: ImmutableDate;
      toTimestamp: ImmutableDate;
    }[] = [];
    const overlapping: {
      fromTimestamp: ImmutableDate;
      toTimestamp: ImmutableDate;
    }[] = [];
    let previousToTimestamp = new ImmutableDate(intervals[0].toTimestamp);
    intervals.slice(1).forEach((interval) => {
      const fromTimestamp = new ImmutableDate(interval.fromTimestamp);
      const toTimestamp = new ImmutableDate(interval.toTimestamp || now);
      const difference = fromTimestamp.valueOf() - previousToTimestamp.valueOf();
      if (
        difference > unregisteredWarningAfterMilliseconds &&
        difference < unregisteredBreakAfterMilliseconds
      ) {
        missing.push({
          fromTimestamp: previousToTimestamp,
          toTimestamp: fromTimestamp,
        });
      }
      if (difference < -taskOverlapWarningAfterMilliseconds) {
        const earliestToTimestamp =
          toTimestamp <= previousToTimestamp ? toTimestamp : previousToTimestamp;
        overlapping.push({fromTimestamp, toTimestamp: earliestToTimestamp});
      }
      if (toTimestamp > previousToTimestamp) {
        previousToTimestamp = toTimestamp;
      }
    });
    const missingBlocks = missing.map(({fromTimestamp, toTimestamp}, index) => {
      return (
        <ErrorBlock
          key={index}
          calendarFromTimestamp={calendarFromTimestamp}
          calendarToTimestamp={calendarToTimestamp}
          fromTimestamp={fromTimestamp}
          toTimestamp={toTimestamp}
          type="missing"
        />
      );
    });
    const overlappingBlocks = overlapping.map(({fromTimestamp, toTimestamp}, index) => {
      return (
        <ErrorBlock
          key={index}
          calendarFromTimestamp={calendarFromTimestamp}
          calendarToTimestamp={calendarToTimestamp}
          fromTimestamp={fromTimestamp}
          toTimestamp={toTimestamp}
          type="overlapping"
        />
      );
    });
    return (
      <div>
        {missingBlocks}
        {overlappingBlocks}
      </div>
    );
  }
}
