import {PriceItem, PriceItemUrl, PriceItemUse, Unit, UnitUrl} from "@co-common-libs/resources";
import {includePriceItemInLogs} from "../price-item";

export function getPriceItemsThatCanBeUsedInLog(
  unitLookup: (url: UnitUrl) => Unit | undefined,
  priceItemUseSet: readonly {priceItem: PriceItem}[],
): Set<string>;
export function getPriceItemsThatCanBeUsedInLog(
  unitLookup: (url: UnitUrl) => Unit | undefined,
  priceItemUseSet: readonly PriceItemUse[],
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined,
): Set<string>;
export function getPriceItemsThatCanBeUsedInLog(
  unitLookup: (url: UnitUrl) => Unit | undefined,
  priceItemUseSet: readonly {priceItem: PriceItem}[] | readonly PriceItemUse[],
  priceItemLookup?: (url: PriceItemUrl) => PriceItem | undefined,
): Set<string> {
  const priceItemSet = new Set<string>();
  priceItemUseSet.forEach((priceItemUse: PriceItemUse | {priceItem: PriceItem}) => {
    let priceItem: PriceItem | undefined;
    if (typeof priceItemUse.priceItem === "string" && priceItemLookup) {
      priceItem = priceItemLookup(priceItemUse.priceItem);
    } else {
      priceItem = priceItemUse.priceItem as PriceItem;
    }
    if (
      priceItem &&
      (priceItemLookup
        ? includePriceItemInLogs(
            unitLookup,
            priceItemUseSet as readonly PriceItemUse[],
            priceItem,
            priceItemLookup,
          )
        : includePriceItemInLogs(
            unitLookup,
            priceItemUseSet as readonly {priceItem: PriceItem}[],
            priceItem,
          ))
    ) {
      priceItemSet.add(priceItem.url);
    }
  });
  return priceItemSet;
}
