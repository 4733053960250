import {urlToId} from "@co-common-libs/resources";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {jsonFetch} from "@co-frontend-libs/utils";
import {CircularProgress, DialogContent} from "@material-ui/core";
import {globalConfig} from "frontend-global-config";
import React, {useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";

type LocationUsage = {
  count: number;
};

export async function fetchLocationUsage(locationURL: string): Promise<LocationUsage> {
  const {baseURL} = globalConfig.resources;
  const url = `${baseURL}location_usage/${urlToId(locationURL)}`;
  const response = await jsonFetch(url, "POST", {locationURL});
  return response.data;
}

async function fetchData(
  locationURL: string,
  setLocationUsage: (data: LocationUsage) => void,
): Promise<void> {
  const data = await fetchLocationUsage(locationURL);
  setLocationUsage(data);
}

interface ChangeLocationCustomerDialogProps {
  locationUrl: string;
  onCancel: () => void;
  onOk: () => void;
  open: boolean;
}

export function ChangeLocationCustomerDialog(
  props: ChangeLocationCustomerDialogProps,
): JSX.Element {
  const {locationUrl, onCancel, onOk, open} = props;
  const [locationUsage, setLocationUsage] = useState<LocationUsage | null>(null);
  useEffect(() => {
    setLocationUsage(null);
    if (open) {
      fetchData(locationUrl, setLocationUsage);
    }
  }, [locationUrl, open]);

  let content: JSX.Element | null = null;
  if (!locationUsage) {
    content = (
      <div style={{textAlign: "center"}}>
        <CircularProgress />
      </div>
    );
  } else {
    content = (
      <div>
        {locationUsage.count ? (
          <FormattedMessage
            defaultMessage="Stedet er anvendt på {count} opgaver. Al data vil blive flyttet!"
            id="change-location-customer.text.usage-count"
            values={{
              count: locationUsage.count,
            }}
          />
        ) : (
          <FormattedMessage
            defaultMessage="Stedet er ikke anvendt på opgaver"
            id="change-location-customer.text.no-usage"
          />
        )}
      </div>
    );
  }
  return (
    <ResponsiveDialog
      okDisabled={!locationUsage}
      open={open}
      title={
        <FormattedMessage
          defaultMessage="Flyt stedet til anden kunde"
          id="change-location-customer-dialog.dialog-title.move-location"
        />
      }
      onCancel={onCancel}
      onOk={onOk}
    >
      <DialogContent>{content}</DialogContent>
    </ResponsiveDialog>
  );
}
