import {CustomerUrl, LocationTypeUrl, LocationUrl, ProductUrl} from "@co-common-libs/resources";
import {LocationStorageCards, LocationStorageFilterCard} from "app-components";
import React, {useState} from "react";

interface LocationStorageTabProps {
  customerURL: CustomerUrl;
}

export function LocationStorageTab(props: LocationStorageTabProps): JSX.Element {
  const [showZeroAmountLocations, setShowZeroAmountLocations] = useState(false);
  const [filterToConfiguredLocations, setFilterToConfiguredLocations] = useState(true);
  const [selectedProducts, setSelectedProducts] = useState<ReadonlySet<ProductUrl>>(
    new Set<ProductUrl>(),
  );
  const [selectedLocationTypes, setSelectedLocationTypes] = useState<ReadonlySet<LocationTypeUrl>>(
    new Set<LocationTypeUrl>(),
  );
  const [selectedLocations, setSelectedLocations] = useState<ReadonlySet<LocationUrl>>(
    new Set<LocationUrl>(),
  );
  return (
    <>
      <LocationStorageFilterCard
        filterToConfiguredLocations={!!selectedLocationTypes.size || filterToConfiguredLocations}
        selectedLocations={selectedLocations}
        selectedLocationTypes={selectedLocationTypes}
        selectedProducts={selectedProducts}
        showZeroAmountLocations={showZeroAmountLocations}
        onFilterToConfiguredLocationsChange={setFilterToConfiguredLocations}
        onSelectedLocationsChange={setSelectedLocations}
        onSelectedLocationTypesChange={setSelectedLocationTypes}
        onSelectedProductsChange={setSelectedProducts}
        onShowZeroAmountLocationsChange={setShowZeroAmountLocations}
      />
      <LocationStorageCards
        filterToConfiguredLocations={!!selectedLocationTypes.size || filterToConfiguredLocations}
        onlyLocationsForCustomer={props.customerURL}
        selectedLocations={selectedLocations}
        selectedLocationTypes={selectedLocationTypes}
        selectedProducts={selectedProducts}
        showZeroAmountLocations={showZeroAmountLocations}
      />
    </>
  );
}
