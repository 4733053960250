import {LocationTypeIcon} from "@co-common-libs/resources";
import {Button} from "@material-ui/core";
import React, {useCallback, useState} from "react";
import {FormattedMessage} from "react-intl";
import {LocationIcon} from "./icon-picker";
import {IconPickerDialog} from "./icon-picker-dialog";

interface SelectIconProps {
  icon: LocationTypeIcon;
  onIconSelected: (icon: LocationTypeIcon) => void;
}

export const SelectIcon = (props: SelectIconProps): JSX.Element => {
  const {icon, onIconSelected} = props;

  const [iconPickerDialogOpen, setIconPickerDialogOpen] = useState(false);

  const handleIconPickerDialogCancel = useCallback(
    (): void => setIconPickerDialogOpen(false),
    [setIconPickerDialogOpen],
  );

  const handleSelectIconClick = useCallback(
    (): void => setIconPickerDialogOpen(true),
    [setIconPickerDialogOpen],
  );

  const handleIconSelected = useCallback(
    (selectedIcon: LocationTypeIcon): void => {
      setIconPickerDialogOpen(false);
      onIconSelected(selectedIcon);
    },
    [setIconPickerDialogOpen, onIconSelected],
  );

  return (
    <div>
      <Button
        color="primary"
        endIcon={<LocationIcon icon={icon} />}
        variant="contained"
        onClick={handleSelectIconClick}
      >
        <FormattedMessage defaultMessage="Skift ikon" />
      </Button>
      <IconPickerDialog
        initialSelected={icon}
        open={iconPickerDialogOpen}
        onCancel={handleIconPickerDialogCancel}
        onOk={handleIconSelected}
      />
    </div>
  );
};
