import {Config} from "@co-common-libs/config";
import {Role} from "@co-common-libs/resources";
import {economicProjectImportEnabled, notNull} from "@co-common-libs/utils";
import {createSelector} from "reselect";
import {getCustomerSettings} from "../resources/selectors";
import {AppState} from "../root-reducer";
import {getCurrentRole} from "./current-user";

// AFT: Temporary types until vi design a final structure and refactor but I needed this in order to write sane business logic.
//      Skipped `navSyncProfile: "dme" |  "rosgaard"` as they are to be removed.

type SystemIntegrationBilling = "economicBilling" | "unicontaBilling";

type SystemIntegrationProjectManagement = "economicProjectManagement";

type SystemIntegrationBillingLegacy =
  | "brugerdataBilling"
  | "c5AgromatBilling"
  | "navAgromatBilling" // Soon to be dead
  | "navBejstrupmBilling"
  | "navHvtBilling"
  | "navMaanssonsBilling"
  | "navRosgaardBilling" // Dead
  | "navVredoDanmarkBilling";

type SystemIntegrationProjectImportLegacy =
  | "c5AgromatProjectImport"
  | "navAgromatProjectImport"
  | "navHvtProjectImport";

export type SystemIntegration =
  | SystemIntegrationBilling
  | SystemIntegrationBillingLegacy
  | SystemIntegrationProjectImportLegacy
  | SystemIntegrationProjectManagement;

type SystemIntegrationEnabledCheck = (settings: Config) => SystemIntegration | null;

const isEconomicBillingEnabled: SystemIntegrationEnabledCheck = (settings) => {
  const {economicEnableProjectActivitiesImport, economicSync, enableProjects} = settings;
  if (
    economicSync &&
    enableProjects &&
    !(enableProjects && economicEnableProjectActivitiesImport)
  ) {
    return "economicBilling";
  } else {
    return null;
  }
};

const isEconomicProjectMangementEnabled: SystemIntegrationEnabledCheck = (settings) => {
  if (economicProjectImportEnabled(settings)) {
    return "economicProjectManagement";
  } else {
    return null;
  }
};

const isUnicontaBillingEnabled: SystemIntegrationEnabledCheck = ({unicontaSync}) => {
  return unicontaSync ? "unicontaBilling" : null;
};

const isC5AgromatBillingEnabled: SystemIntegrationEnabledCheck = ({c5Sync}) =>
  c5Sync ? "c5AgromatBilling" : null;

const isNavAgromatBillingEnabled: SystemIntegrationEnabledCheck = ({navSync, navSyncProfile}) =>
  navSync && navSyncProfile === "dme" ? "navAgromatBilling" : null;

const isNavBejstrupmBillingEnabled: SystemIntegrationEnabledCheck = ({navSync, navSyncProfile}) =>
  navSync && navSyncProfile === "bejstrupm" ? "navBejstrupmBilling" : null;

const isNavHvtBillingEnabled: SystemIntegrationEnabledCheck = ({navSync, navSyncProfile}) =>
  navSync && navSyncProfile === "hvt" ? "navHvtBilling" : null;

const isNavRosgaardBillingEnabled: SystemIntegrationEnabledCheck = ({navSync, navSyncProfile}) =>
  navSync && navSyncProfile === "rosgaard" ? "navRosgaardBilling" : null;

const isNavVredodanmarkBillingEnabled: SystemIntegrationEnabledCheck = ({
  navSync,
  navSyncProfile,
}) => (navSync && navSyncProfile === "vredodanmark" ? "navVredoDanmarkBilling" : null);

const isBrugerdataBillingEnabled: SystemIntegrationEnabledCheck = ({brugerdataSync}) =>
  brugerdataSync ? "brugerdataBilling" : null;

const isC5AgromatProjectImportEnabled: SystemIntegrationEnabledCheck = ({brugerdataSync}) =>
  brugerdataSync ? "c5AgromatProjectImport" : null;

const isNavAgromatProjectImportEnabled: SystemIntegrationEnabledCheck = ({brugerdataSync}) =>
  brugerdataSync ? "navAgromatProjectImport" : null;

const isNavHvtProjectImportEnabled: SystemIntegrationEnabledCheck = ({brugerdataSync}) =>
  brugerdataSync ? "navHvtProjectImport" : null;

export const getEnabledBillingSystemIntegrations: (state: AppState) => SystemIntegration[] =
  createSelector(getCustomerSettings, (settings: Config) =>
    [
      isEconomicBillingEnabled,
      isUnicontaBillingEnabled,
      isC5AgromatBillingEnabled,
      isNavAgromatBillingEnabled,
      isNavBejstrupmBillingEnabled,
      isNavHvtBillingEnabled,
      isNavRosgaardBillingEnabled,
      isNavVredodanmarkBillingEnabled,
      isBrugerdataBillingEnabled,
    ]
      .map((t) => t(settings))
      .filter(notNull),
  );

export const getBillingEnabled: (state: AppState) => boolean = createSelector(
  getEnabledBillingSystemIntegrations,
  (integrations) => integrations.length > 0,
);

export const getEnabledProjectManagementIntegrations: (state: AppState) => SystemIntegration[] =
  createSelector(getCustomerSettings, (settings: Config) =>
    [isEconomicProjectMangementEnabled].map((t) => t(settings)).filter(notNull),
  );

export const getProjectManagementEnabled: (state: AppState) => boolean = createSelector(
  getEnabledProjectManagementIntegrations,
  (integrations: SystemIntegration[]) => integrations.length > 0,
);

export const getEnabledLegacyProjectImportIntegrations: (state: AppState) => SystemIntegration[] =
  createSelector(getCustomerSettings, (settings: Config) =>
    [
      isC5AgromatProjectImportEnabled,
      isNavAgromatProjectImportEnabled,
      isNavHvtProjectImportEnabled,
    ]
      .map((t) => t(settings))
      .filter(notNull),
  );

export const getLegacyProjectImportEnabled: (state: AppState) => boolean = createSelector(
  getEnabledLegacyProjectImportIntegrations,
  (integrations) => integrations.length > 0,
);

export const getCurrentUserCanManageProjects: (state: AppState) => boolean = createSelector(
  getCustomerSettings,
  getCurrentRole,
  getProjectManagementEnabled,
  (
    {canAddProject, canEditProjects}: Config,
    role: Role | null,
    projectManagementEnabled: boolean,
  ) =>
    projectManagementEnabled &&
    canAddProject !== null &&
    canEditProjects &&
    role !== null &&
    (role.consultant || role.manager || role.projectManager),
);
