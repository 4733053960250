import {SECOND_MILLISECONDS} from "@co-common-libs/utils";
import {getCommitQueueLength} from "@co-frontend-libs/redux";
import {getGeolocationTracker, store} from "frontend-global-config";

type BackgroundFetchNetworkType = 0 | 1 | 2 | 3 | 4;

type BackgroundFetchStatus = 0 | 1 | 2;

declare const BackgroundFetch:
  | {
      configure(
        callback: (taskID: string) => void,
        failure: (error: BackgroundFetchStatus) => void,
        config: {
          requiredNetworkType: BackgroundFetchNetworkType;
        },
      ): void;
      finish: (taskID: string) => void;
      NETWORK_TYPE_ANY: BackgroundFetchNetworkType;
      NETWORK_TYPE_CELLULAR: BackgroundFetchNetworkType;
      NETWORK_TYPE_NONE: BackgroundFetchNetworkType;
      NETWORK_TYPE_NOT_ROAMING: BackgroundFetchNetworkType;
      NETWORK_TYPE_UNMETERED: BackgroundFetchNetworkType;
      STATUS_AVAILABLE: BackgroundFetchStatus;
      STATUS_DENIED: BackgroundFetchStatus;
      STATUS_RESTRICTED: BackgroundFetchStatus;
    }
  | undefined;

// According to the current plugin documentation the maximum
// allowed background task duration on iOS is 30. We stay safely
// below this.
const BACKGROUND_TASK_MAX_DURATION_SECONDS = 25;

const LAST_LEGACY_BACKGROUND_FETCH_REQUIRED_APP_VERSION = "1.4.2";

/** @deprecated */
export const initializeLegacyBackgroundFetch = (startOnlineSaves: () => void): void => {
  const appVersion = (window as any).APP_VERSION;
  if (
    typeof cordova !== "undefined" &&
    typeof BackgroundFetch !== "undefined" &&
    appVersion &&
    appVersion <= LAST_LEGACY_BACKGROUND_FETCH_REQUIRED_APP_VERSION
  ) {
    const failureCallback = (/* status: BackgroundFetchStatus */): void => {
      // Uncommented until we get BackgroundFetch running properly.
      // if (window.onerror) {
      //   const errorMessage = `BackgroundFetch failure. Status code: ${status}`;
      //   window.onerror(
      //     errorMessage,
      //     undefined,
      //     undefined,
      //     undefined,
      //     Error(errorMessage),
      //   );
      // }
    };
    const fetchCallback = (taskID: string): void => {
      const syncedPromise = new Promise<void>((resolve, reject) => {
        let timeoutID: number | null = null;
        const unsubscribe = store.subscribe(() => {
          if (!getCommitQueueLength(store.getState())) {
            unsubscribe();
            if (timeoutID !== null) {
              window.clearTimeout(timeoutID);
            }
            resolve();
          }
        });
        timeoutID = window.setTimeout(() => {
          unsubscribe();
          if (getCommitQueueLength(store.getState())) {
            reject(
              new Error(
                `BackgroundFetch - Sync wait timeout after ${BACKGROUND_TASK_MAX_DURATION_SECONDS} seconds.`,
              ),
            );
          }
        }, BACKGROUND_TASK_MAX_DURATION_SECONDS * SECOND_MILLISECONDS);
      });
      startOnlineSaves();
      syncedPromise
        .catch((/* error: Error */) => {
          // Uncommented until we get BackgroundFetch running properly.
          // if (window.onerror) {
          //   window.onerror(
          //     `${error.message}`,
          //     undefined,
          //     undefined,
          //     undefined,
          //     error,
          //   );
          // }
        })
        .finally(async () => {
          const geolocationTracker = await getGeolocationTracker();
          if (geolocationTracker) {
            geolocationTracker.updateTrackingState();
          }
          BackgroundFetch.finish(taskID);
        });
    };
    BackgroundFetch.configure(fetchCallback, failureCallback, {
      requiredNetworkType: BackgroundFetch.NETWORK_TYPE_ANY,
    });
  }
};
