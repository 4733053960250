import {useEffect, useReducer, useRef} from "react";

export function useMapContainer(
  map: google.maps.Map | null,
): readonly [HTMLElement | null, google.maps.OverlayView | null, number] {
  const containerElementRef = useRef<HTMLElement | null>(null);
  const overlayViewRef = useRef<google.maps.OverlayView | null>(null);

  const [drawCount, forceUpdate] = useReducer((x: number) => x + 1, 0);

  useEffect(() => {
    if (!map) {
      return undefined;
    }
    const overlayView = new google.maps.OverlayView();
    overlayView.onAdd = () => {
      const containerElement = document.createElement("div");
      containerElement.style.position = "relative";
      const mapPanes = overlayView.getPanes();
      if (mapPanes) {
        mapPanes.overlayMouseTarget.appendChild(containerElement);
        containerElementRef.current = containerElement;
      }
    };
    overlayView.onRemove = () => {
      const containerElement = containerElementRef.current;
      if (containerElement && containerElement.parentNode) {
        containerElement.parentNode.removeChild(containerElement);
        containerElementRef.current = null;
      }
    };
    overlayView.draw = () => {
      forceUpdate();
      return;
    };
    overlayViewRef.current = overlayView;
    overlayView.setMap(map);
    return () => {
      overlayView.setMap(null);
    };
  }, [map]);
  return [containerElementRef.current, overlayViewRef.current, drawCount] as const;
}
