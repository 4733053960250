import {
  Customer,
  CustomerUrl,
  Location,
  LocationUrl,
  Unit,
  UnitUrl,
} from "@co-common-libs/resources";
import {formatAddress} from "@co-common-libs/utils";
import {
  DecimalField,
  ErrorColorButton,
  ResponsiveDialog,
  TrimTextField,
} from "@co-frontend-libs/components";
import {
  getCustomerLookup,
  getCustomerSettings,
  getLocationLookup,
  getUnitLookup,
} from "@co-frontend-libs/redux";
import {Button, DialogContent} from "@material-ui/core";
import {usePrevious} from "@react-hookz/web";
import {plainObjectReducer} from "app-utils";
import React, {useCallback, useEffect, useMemo, useReducer, useState} from "react";
// Allowed for existing code.
// eslint-disable-next-line deprecate/import
import {Cell, Grid} from "react-flexr";
import {FormattedMessage, defineMessages, useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {useCustomerSetters} from "./info-blocks";
import {LocationSelectCreateDialog} from "./location-dialog";
import {UnitSelect} from "./unit-select";

const messages = defineMessages({
  address: {
    defaultMessage: "Arbejdssted",
    id: "pickup-location-dialog.label.task-address",
  },
  cancel: {
    defaultMessage: "Fortryd",
    id: "dialog.label.cancel",
  },
  delete: {
    defaultMessage: "Slet",
    id: "pickup-location-dialog.label.delete",
  },
  editPickupLocation: {
    defaultMessage: "Ret afhentningssted",
    id: "pickup-location-dialog.title.edit-pickup-location",
  },
  newPickupLocation: {
    defaultMessage: "Nyt afhentningssted",
    id: "pickup-location-dialog.title.new-pickup-location",
  },
  note: {
    defaultMessage: "Note",
    id: "pickup-location-dialog.label.note",
  },
  ok: {
    defaultMessage: "OK",
    id: "dialog.label.ok",
  },
  selectCustomer: {
    defaultMessage: "Vælg",
    id: "pickup-location-dialog.label.select-customer",
  },
  selectPickupLocation: {
    defaultMessage: "Afhentningssted",
    id: "pickup-location-dialog.title.select-pickup-location",
  },
  selectWorkplace: {
    defaultMessage: "Vælg",
    id: "pickup-location-dialog.label.select-workplace",
  },
  totalAmount: {
    defaultMessage: "Total mængde ({unit})",
    id: "pickup-location-dialog.label.total-amount",
  },
  unit: {
    defaultMessage: "Enhed",
    id: "pickup-location-dialog.label.unit",
  },
  unitChoice: {
    defaultMessage: "Vælg enhed",
    id: "pickup-location-dialog.label.unit-choice",
  },
});

interface OkPayload {
  amount: number | null;
  customer: Customer | null;
  location: Location | null;
  note: string;
  relatedUnit: Unit | null;
  unit: string;
}

interface PickupLocationDialogProps {
  amount?: number | undefined;
  canDelete?: boolean;
  customer?: Customer | undefined;
  isNew: boolean;
  location?: Location | undefined;
  note?: string;
  onCancel: () => void;
  onCustomerChanged: (Customer: CustomerUrl | null) => void;
  onOk: (data: OkPayload) => void;
  onRequestDelete?: () => void;
  open: boolean;
  relatedUnit?: Unit | undefined;
  unit?: string | undefined;
}

export function PickupLocationDialog(props: PickupLocationDialogProps): JSX.Element {
  const unitLookup = useSelector(getUnitLookup);
  const locationLookup = useSelector(getLocationLookup);
  const customerSettings = useSelector(getCustomerSettings);
  const customerLookup = useSelector(getCustomerLookup);
  const {canDelete, isNew, onCancel, onCustomerChanged, onOk, onRequestDelete, open} = props;

  const initial: OkPayload = useMemo(
    () => ({
      amount: props.amount || null,
      customer: props.customer || null,
      location: props.location || null,
      note: props.note || "",
      relatedUnit: props.relatedUnit || null,
      unit: props.unit || "",
    }),
    [props],
  );

  const [payload, setPayload] = useReducer(plainObjectReducer<OkPayload>, initial);
  const [customerLocationDialogOpen, setCustomerLocationDialogOpen] = useState(false);

  const wasOpen = usePrevious(open);
  useEffect(() => {
    if (!wasOpen && open) {
      setPayload(initial);
    }
  }, [wasOpen, open, initial]);

  const handleCustomerChanged = useCallback(
    (customerUrl: CustomerUrl | null): void => {
      const newCustomer = customerUrl && customerLookup(customerUrl);
      setPayload({customer: newCustomer || null, location: undefined});
      if (onCustomerChanged) {
        onCustomerChanged(customerUrl);
      }
    },
    [customerLookup, onCustomerChanged],
  );

  const [modal, selectCustomerClicked] = useCustomerSetters(handleCustomerChanged);

  const {formatMessage} = useIntl();

  const okDisabled =
    customerSettings.sharedTransportLog &&
    customerSettings.transportLogUnitPerLocation &&
    !payload.relatedUnit;

  const handleOk = useCallback((): void => onOk(payload), [onOk, payload]);

  const handleAmountChange = useCallback(
    (newValue: number | null): void => setPayload({amount: newValue}),
    [setPayload],
  );

  const handleNoteChange = useCallback(
    (value: string): void => setPayload({note: value}),
    [setPayload],
  );

  const handleSelectUnitChange = useCallback(
    (unitURL: UnitUrl): void => {
      const newUnit = unitLookup(unitURL);
      if (!newUnit) {
        return;
      }
      setPayload({relatedUnit: newUnit, unit: newUnit.name || newUnit.symbol});
    },
    [unitLookup, setPayload],
  );

  const handleLocationSelectButton = useCallback((): void => {
    setCustomerLocationDialogOpen(true);
  }, [setCustomerLocationDialogOpen]);

  const handleWorkPlaceDialogCancel = useCallback((): void => {
    setCustomerLocationDialogOpen(false);
  }, [setCustomerLocationDialogOpen]);

  const handleWorkPlaceDialogOk = useCallback(
    (url: LocationUrl): void => {
      const newLocation = locationLookup(url);
      const newCustomer = newLocation?.customer ? customerLookup(newLocation.customer) : null;

      setPayload({customer: newCustomer || undefined, location: newLocation});
      setCustomerLocationDialogOpen(false);
    },
    [locationLookup, customerLookup, setPayload, setCustomerLocationDialogOpen],
  );

  let customerBlock;
  if (payload.customer) {
    customerBlock = (
      <div>
        <FormattedMessage
          defaultMessage="Navn: {name}"
          id="pickup-location-dialog.label.customer-name"
          tagName="div"
          values={{name: payload.customer.name}}
        />
        <FormattedMessage
          defaultMessage="Adresse: {address}"
          id="pickup-location-dialog.label.customer-address"
          tagName="div"
          values={{address: formatAddress(payload.customer)}}
        />
      </div>
    );
  }
  let deleteButton;
  if (!isNew && onRequestDelete) {
    deleteButton = (
      <div>
        <ErrorColorButton disabled={!canDelete} variant="contained" onClick={onRequestDelete}>
          {formatMessage(messages.delete)}
        </ErrorColorButton>
      </div>
    );
  }
  let locationAddress;
  if (payload.location) {
    locationAddress = payload.location.address || payload.location.name;
  }
  const dialogContent = (
    <div>
      <Grid>
        <Cell palm="12/12">
          <div>
            <FormattedMessage
              defaultMessage="Kunde"
              id="pickup-location-dialog.label.customer"
              tagName="h4"
            />
            <Button color="secondary" variant="contained" onClick={selectCustomerClicked}>
              {formatMessage(messages.selectCustomer)}
            </Button>
            {customerBlock}
          </div>
          <div>
            <FormattedMessage
              defaultMessage="Arbejdssted"
              id="order-instance.label.workplace"
              tagName="h4"
            />
            <div>
              <Button color="secondary" variant="contained" onClick={handleLocationSelectButton}>
                {formatMessage(messages.selectPickupLocation)}
              </Button>
              <div>{locationAddress}</div>
            </div>
          </div>
          {deleteButton}
        </Cell>
        <Cell palm="12/12">
          {customerSettings.transportLogUnitPerLocation ? (
            <UnitSelect
              relatedUnit={payload.relatedUnit?.url}
              onSelectUnitChange={handleSelectUnitChange}
            />
          ) : null}
          <DecimalField
            fullWidth
            decimalPlaces={customerSettings.transportLogDecimals}
            label={formatMessage(messages.totalAmount, {
              unit: payload.relatedUnit
                ? payload.relatedUnit.symbol || payload.relatedUnit.name
                : payload.unit,
            })}
            margin="dense"
            maxDigits={9}
            value={payload.amount || null}
            onChange={handleAmountChange}
          />
          <TrimTextField
            fullWidth
            multiline
            label={formatMessage(messages.note)}
            margin="dense"
            maxRows={30}
            minRows={2}
            value={payload.note}
            variant="outlined"
            onChange={handleNoteChange}
          />
        </Cell>
      </Grid>
      {customerSettings.sharedTransportLog && customerSettings.transportLogUnitPerLocation ? (
        <FormattedMessage defaultMessage="* Krævet" id="pickup-location-dialog.label.required" />
      ) : null}
    </div>
  );
  return (
    <>
      <ResponsiveDialog
        okDisabled={okDisabled}
        open={open}
        title={
          isNew
            ? formatMessage(messages.newPickupLocation)
            : formatMessage(messages.editPickupLocation)
        }
        onCancel={onCancel}
        onOk={handleOk}
      >
        <DialogContent>{dialogContent}</DialogContent>
      </ResponsiveDialog>
      <LocationSelectCreateDialog
        customerURL={payload.customer?.url || null}
        includeWorkplaceOnlyLocations={false}
        logOnlyLocation={customerSettings.setLogOnlyLocationOnCreate}
        open={customerLocationDialogOpen}
        titleVariant="PICKUP"
        onCancel={handleWorkPlaceDialogCancel}
        onOk={handleWorkPlaceDialogOk}
      />
      {modal}
    </>
  );
}
