import {CustomerUrl, MachineUrl, PriceGroupUrl, WorkTypeUrl} from "@co-common-libs/resources";

export interface FuelSurchargeUseCriteria {
  customerUrl: CustomerUrl | null;
  machinePriceGroupUrl: PriceGroupUrl | null;
  machineUrl: MachineUrl | null;
  workTypePriceGroupUrl: PriceGroupUrl | null;
  workTypeUrl: WorkTypeUrl | null;
}

interface MachineFuelSurchargeUsePart {
  readonly customer: CustomerUrl | null;
  readonly machine: MachineUrl;
  readonly variant: PriceGroupUrl | null;
}

interface WorkTypeFuelSurchargeUsePart {
  readonly customer: CustomerUrl | null;
  readonly variant: PriceGroupUrl | null;
  readonly workType: WorkTypeUrl;
}

interface DefaultFuelSurchargeUsePart {
  readonly customer: CustomerUrl | null;
}

export function findMatchingFuelSurchargeUses<
  MachineFuelSurchargeUse extends MachineFuelSurchargeUsePart,
  WorkTypeFuelSurchargeUse extends WorkTypeFuelSurchargeUsePart,
  DefaultFuelSurchargeUse extends DefaultFuelSurchargeUsePart,
>(
  criteria: FuelSurchargeUseCriteria,
  machineFuelSurchargeUseArray: readonly MachineFuelSurchargeUse[],
  workTypeFuelSurchargeUseArray: readonly WorkTypeFuelSurchargeUse[],
  defaultFuelSurchargeUseArray: readonly DefaultFuelSurchargeUse[],
): (DefaultFuelSurchargeUse | MachineFuelSurchargeUse | WorkTypeFuelSurchargeUse)[] {
  const {customerUrl, machinePriceGroupUrl, machineUrl, workTypePriceGroupUrl, workTypeUrl} =
    criteria;
  const result: (DefaultFuelSurchargeUse | MachineFuelSurchargeUse | WorkTypeFuelSurchargeUse)[] =
    [];
  if (customerUrl && machineUrl && machinePriceGroupUrl) {
    result.push(
      ...machineFuelSurchargeUseArray.filter(
        ({customer, machine, variant}) =>
          customer === customerUrl && machine === machineUrl && variant === machinePriceGroupUrl,
      ),
    );
  }
  if (customerUrl && machineUrl) {
    result.push(
      ...machineFuelSurchargeUseArray.filter(
        ({customer, machine, variant}) =>
          customer === customerUrl && machine === machineUrl && variant === null,
      ),
    );
  }
  if (customerUrl && workTypeUrl && workTypePriceGroupUrl) {
    result.push(
      ...workTypeFuelSurchargeUseArray.filter(
        ({customer, variant, workType}) =>
          customer === customerUrl && workType === workTypeUrl && variant === workTypePriceGroupUrl,
      ),
    );
  }
  if (customerUrl && workTypeUrl) {
    result.push(
      ...workTypeFuelSurchargeUseArray.filter(
        ({customer, variant, workType}) =>
          customer === customerUrl && workType === workTypeUrl && variant === null,
      ),
    );
  }
  if (customerUrl) {
    result.push(...defaultFuelSurchargeUseArray.filter(({customer}) => customer === customerUrl));
  }
  if (machineUrl && machinePriceGroupUrl) {
    result.push(
      ...machineFuelSurchargeUseArray.filter(
        ({customer, machine, variant}) =>
          customer === null && machine === machineUrl && variant === machinePriceGroupUrl,
      ),
    );
  }
  if (machineUrl) {
    result.push(
      ...machineFuelSurchargeUseArray.filter(
        ({customer, machine, variant}) =>
          customer === null && machine === machineUrl && variant === null,
      ),
    );
  }
  if (workTypeUrl && workTypePriceGroupUrl) {
    result.push(
      ...workTypeFuelSurchargeUseArray.filter(
        ({customer, variant, workType}) =>
          customer === null && workType === workTypeUrl && variant === workTypePriceGroupUrl,
      ),
    );
  }
  if (workTypeUrl) {
    result.push(
      ...workTypeFuelSurchargeUseArray.filter(
        ({customer, variant, workType}) =>
          customer === null && workType === workTypeUrl && variant === null,
      ),
    );
  }
  result.push(...defaultFuelSurchargeUseArray.filter(({customer}) => customer === null));
  return result;
}
