import {
  Machine,
  PriceGroup,
  ReportingSpecification,
  ReportingSpecificationUrl,
  WorkType,
} from "@co-common-libs/resources";
import {ColumnSpecifications, GenericTable, RowData} from "@co-frontend-libs/components";
import {
  actions,
  getMachineArray,
  getPriceGroupArray,
  getReportingSpecificationArray,
  getTableSortingState,
  getWorkTypeArray,
} from "@co-frontend-libs/redux";
import {useQueryParameter} from "app-utils";
import _ from "lodash";
import React, {useCallback, useMemo} from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

const MAX_LOCATIONS_DISPLAYED = 100;

const TABLE_SORTING_IDENTIFIER = "ReportingSpecificationTable";

type ReportingSpecificationTableColumnID =
  | "identifier"
  | "machines"
  | "name"
  | "priceGroups"
  | "workTypes";

type ReportingSpecificationTableFieldID =
  | "identifier"
  | "machines"
  | "name"
  | "priceGroups"
  | "workTypes";

interface ReportingSpecificationTableDataType
  extends RowData<ReportingSpecificationTableFieldID, ReportingSpecificationUrl> {
  identifier: string;
  machines: string;
  name: string | null;
  priceGroups: string;
  workTypes: string;
}

function buildColumnSpecifications(
  onClick: (reportingSpecificationURL: string) => void,
): ColumnSpecifications<
  ReportingSpecificationTableFieldID,
  ReportingSpecificationTableColumnID,
  ReportingSpecificationUrl,
  ReportingSpecificationTableDataType
> {
  return {
    identifier: {
      field: "identifier",
      label: (
        <FormattedMessage
          defaultMessage="ID"
          id="reportingSpecification-list.table-header.identifier"
        />
      ),
      onClick,
      width: 150,
    },
    machines: {
      field: "machines",
      label: (
        <FormattedMessage
          defaultMessage="Maskiner"
          id="reportingSpecification-list.table-header.machines"
        />
      ),
      onClick,
    },
    name: {
      field: "name",
      label: (
        <FormattedMessage
          defaultMessage="Titel"
          id="reportingSpecification-list.table-header.name"
        />
      ),
      onClick,
    },
    priceGroups: {
      field: "priceGroups",
      label: (
        <FormattedMessage
          defaultMessage="Varianter"
          id="reportingSpecification-list.table-header.price-groups"
        />
      ),
      onClick,
    },
    workTypes: {
      field: "workTypes",
      label: (
        <FormattedMessage
          defaultMessage="Arbejdsområder"
          id="reportingSpecification-list.table-header.work-types"
        />
      ),
      onClick,
    },
  };
}

function buildRowData(
  reportingSpecificationArray: readonly ReportingSpecification[],
  workTypeArray: readonly WorkType[],
  machineArray: readonly Machine[],
  priceGroupArray: readonly PriceGroup[],
): ReportingSpecificationTableDataType[] {
  return reportingSpecificationArray.map((reportingSpecification) => {
    const workTypes = workTypeArray
      .filter((w) => w.reportingSpecification === reportingSpecification.url)
      .map((w) => w.identifier)
      .sort()
      .join(", ");
    const machines = machineArray
      .filter((m) => m.reportingSpecification === reportingSpecification.url)
      .map((m) => m.c5_machine)
      .sort()
      .join(", ");
    const priceGroups = priceGroupArray
      .filter((pg) => pg.reportingSpecification === reportingSpecification.url)
      .map((pg) => pg.identifier)
      .sort()
      .join(", ");
    return {
      identifier: reportingSpecification.identifier,
      key: reportingSpecification.url,
      machines,
      name: reportingSpecification.name,
      priceGroups,
      workTypes,
    };
  });
}

interface ReportingSpecificationTableProps {
  onClick: (reportingSpecificationURL: string) => void;
}

export function ReportingSpecificationTable(props: ReportingSpecificationTableProps): JSX.Element {
  const {onClick} = props;
  const reportingSpecificationArray = useSelector(getReportingSpecificationArray);

  const workTypeArray = useSelector(getWorkTypeArray);
  const machineArray = useSelector(getMachineArray);
  const priceGroupArray = useSelector(getPriceGroupArray);
  const filteredReportingSpecificationArray = useMemo(
    () => reportingSpecificationArray.filter((spec) => spec.active !== false),
    [reportingSpecificationArray],
  );
  const sortedReportingSpecifications = useMemo(() => {
    return _.sortBy(filteredReportingSpecificationArray, [
      (spec) => spec.name,
      (spec) => spec.identifier,
    ]);
  }, [filteredReportingSpecificationArray]);

  const dispatch = useDispatch();

  const columnSpecifications = useMemo(() => buildColumnSpecifications(onClick), [onClick]);

  const rowData = useMemo(
    () => buildRowData(sortedReportingSpecifications, workTypeArray, machineArray, priceGroupArray),
    [machineArray, priceGroupArray, sortedReportingSpecifications, workTypeArray],
  );

  const visibleColumns: readonly ReportingSpecificationTableFieldID[] = [
    "identifier",
    "name",
    "workTypes",
    "machines",
    "priceGroups",
  ];

  const filterString = useQueryParameter("q", "");

  const sortingStateSelector = useMemo(
    () => getTableSortingState(TABLE_SORTING_IDENTIFIER, "name", "ASC"),
    [],
  );
  const {sortDirection, sortKey} = useSelector(sortingStateSelector);

  const handleHeaderClick = useCallback(
    (key: ReportingSpecificationTableColumnID): void => {
      let direction: "ASC" | "DESC" = "ASC";
      if (sortKey === key && sortDirection === "ASC") {
        direction = "DESC";
      }
      const action = actions.putTableSortingState(TABLE_SORTING_IDENTIFIER, key, direction);
      dispatch(action);
    },
    [dispatch, sortKey, sortDirection],
  );

  return (
    <GenericTable
      columns={columnSpecifications}
      entries={rowData}
      filterString={filterString}
      maxDisplayed={MAX_LOCATIONS_DISPLAYED}
      sortBy={sortKey as any}
      sortDirection={sortDirection}
      visibleColumns={visibleColumns}
      onHeaderClick={handleHeaderClick}
    />
  );
}
