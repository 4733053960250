import {
  Customer,
  CustomerUrl,
  Location,
  LocationType,
  LocationTypeIcon,
  LocationTypeUrl,
  LocationUrl,
} from "@co-common-libs/resources";
import {
  caseAccentInsensitiveCollator,
  formatAddress,
  formatDateNumeric,
  notNull,
} from "@co-common-libs/utils";
import {
  ColorBox,
  ColumnSpecifications,
  LocationIcon,
  RowData,
  colorBoxColumnSpecification,
  iconColumnSpecification,
} from "@co-frontend-libs/components";
import {
  getActiveCustomerArray,
  getCustomerLookup,
  getExtendedCustomerSettings,
  getLocationTypeArray,
  getLocationTypeLookup,
  getVisibleLocationArray,
} from "@co-frontend-libs/redux";
import {Checkbox} from "@material-ui/core";
import {useQueryParameter} from "app-utils";
import CheckIcon from "mdi-react/CheckIcon";
import DomainOffIcon from "mdi-react/DomainOffIcon";
import StarIcon from "mdi-react/StarIcon";
import React, {useMemo} from "react";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {ConnectedTableWithPagination} from "../table-with-pagination/connected-table-with-pagination";
import {PaginationPageSize} from "../table-with-pagination/types";

type LocationTableColumnID =
  | "active"
  | "address"
  | "city"
  | "color"
  | "createdFormatted"
  | "customer"
  | "favorite"
  | "logOnlyLocation"
  | "name"
  | "postalCode"
  | "select"
  | "type"
  | "workplaceOnlyLocation";

type LocationTableFieldID =
  | "active"
  | "address"
  | "city"
  | "color"
  | "created"
  | "createdFormatted"
  | "customer"
  | "customerURL"
  | "favorite"
  | "icon"
  | "logOnlyLocation"
  | "name"
  | "postalCode"
  | "selectable"
  | "selected"
  | "type"
  | "workplaceOnlyLocation";

interface LocationTableDataType extends RowData<LocationTableFieldID, LocationUrl> {
  active: "ACTIVE" | "CUSTOMER_INACTIVE" | "LOCATION_INACTIVE";
  address: string;
  city: string;
  color: string | null;
  created: string;
  customer: string;
  customerURL: string | null;
  favorite: boolean;
  icon: LocationTypeIcon | null;
  logOnlyLocation: boolean;
  name: string;
  postalCode: string;
  selectable: boolean;
  selected: boolean;
  type: string;
  workplaceOnlyLocation: boolean;
}

function getSelectRender(
  onSelect: (locationURL: LocationUrl, selected: boolean) => void,
): (data: LocationTableDataType) => JSX.Element {
  const changeHandlerMap = new Map<string, (event: React.ChangeEvent<HTMLInputElement>) => void>();
  const renderSelect = (data: LocationTableDataType): JSX.Element => {
    const {key, selectable, selected} = data;
    let handleSelect = changeHandlerMap.get(key);
    if (!handleSelect) {
      handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        onSelect(key, event.target.checked);
      };
      changeHandlerMap.set(key, handleSelect);
    }
    return <Checkbox checked={selected} disabled={!selectable} onChange={handleSelect} />;
  };
  return renderSelect;
}

function renderActive(data: LocationTableDataType): JSX.Element {
  return data.active === "ACTIVE" ? (
    <CheckIcon />
  ) : data.active === "CUSTOMER_INACTIVE" ? (
    <DomainOffIcon />
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <></>
  );
}

function renderFavorite(data: LocationTableDataType): JSX.Element {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return data.favorite ? <StarIcon /> : <></>;
}

function renderColorIconBlock(data: LocationTableDataType): JSX.Element {
  return data.icon ? (
    data.color ? (
      <LocationIcon color={data.color} icon={data.icon} />
    ) : (
      <LocationIcon icon={data.icon} />
    )
  ) : (
    <ColorBox color={data.color} />
  );
}

function renderLogOnlyLocation(data: LocationTableDataType): JSX.Element {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return data.logOnlyLocation ? <CheckIcon /> : <></>;
}

function renderWorkplaceOnlyLocation(data: LocationTableDataType): JSX.Element {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return data.workplaceOnlyLocation ? <CheckIcon /> : <></>;
}

function buildColumnSpecifications(
  onSelect: (locationURL: LocationUrl, selected: boolean) => void,
  onClick?: (locationURL: LocationUrl) => void,
): ColumnSpecifications<
  LocationTableFieldID,
  LocationTableColumnID,
  LocationUrl,
  LocationTableDataType
> {
  return {
    active: iconColumnSpecification({
      disableSorting: false,
      field: "active",
      label: <FormattedMessage defaultMessage="Aktiv" id="location-list.table-header.active" />,
      onClick,
      render: renderActive,
      style: {textAlign: "center"},
    }),
    address: {
      field: "address",
      label: (
        <FormattedMessage defaultMessage="Adresse/navn" id="location-list.table-header.address" />
      ),
      onClick,
    },
    city: {
      field: "city",
      label: <FormattedMessage defaultMessage="By" id="location-list.table-header.city" />,
      onClick,
    },
    color: colorBoxColumnSpecification({
      field: "color",
      onClick,
      render: renderColorIconBlock,
    }),
    createdFormatted: {
      field: "createdFormatted",
      label: <FormattedMessage defaultMessage="Oprettet" />,
      onClick,
      sortField: "created",
    },
    customer: {
      field: "customer",
      label: <FormattedMessage defaultMessage="Kunde" id="location-list.table-header.customer" />,
      onClick,
    },
    favorite: iconColumnSpecification({
      disableSorting: false,
      field: "favorite",
      label: (
        <FormattedMessage defaultMessage="Fav." id="location-list.table-header.favorie-short" />
      ),
      onClick,
      render: renderFavorite,
    }),
    logOnlyLocation: iconColumnSpecification({
      disableSorting: false,
      field: "logOnlyLocation",
      label: <FormattedMessage defaultMessage="Kun til logs" />,
      onClick,
      render: renderLogOnlyLocation,
      style: {textAlign: "center"},
    }),
    name: {
      field: "name",
      label: <FormattedMessage defaultMessage="Søgenavn" id="location-list.table-header.name" />,
      onClick,
    },
    postalCode: {
      field: "postalCode",
      label: (
        <FormattedMessage defaultMessage="Postnummer" id="location-list.table-header.postal-code" />
      ),
      onClick,
      width: 140,
    },
    select: {
      field: "selected",
      label: "",
      render: getSelectRender(onSelect),
    },
    type: {
      field: "type",
      label: <FormattedMessage defaultMessage="Type" id="location-list.table-header.type" />,
      onClick,
      style: {paddingLeft: 8} as const,
      width: 150,
    },
    workplaceOnlyLocation: iconColumnSpecification({
      disableSorting: false,
      field: "workplaceOnlyLocation",
      label: <FormattedMessage defaultMessage="Kun til arbejdst." />,
      onClick,
      render: renderWorkplaceOnlyLocation,
      style: {textAlign: "center"},
    }),
  };
}

function buildBaseRowData(
  locationArray: readonly Location[],
  customerLookup: (url: CustomerUrl) => Customer | undefined,
  locationTypeLookup: (url: LocationTypeUrl) => LocationType | undefined,
  includeCustomerColumn: boolean,
  activeCustomerUrls: Set<string>,
): (Omit<LocationTableDataType, "selectable" | "selected"> & {
  selectable: boolean | undefined;
})[] {
  return locationArray.map((location) => {
    const locationType = location.locationType ? locationTypeLookup(location.locationType) : null;
    return {
      active:
        location.customer && !activeCustomerUrls.has(location.customer)
          ? "CUSTOMER_INACTIVE"
          : location.active
            ? "ACTIVE"
            : "LOCATION_INACTIVE",
      address: location.address,
      city: location.city,
      color: locationType?.color || null,
      created: location.created || "",
      createdFormatted: formatDateNumeric(location.created),
      customer:
        (includeCustomerColumn && location.customer && customerLookup(location.customer)?.name) ||
        "",
      customerURL: location.customer,
      favorite: location.favorite,
      icon: locationType?.icon || null,
      key: location.url,
      logOnlyLocation: location.logOnlyLocation,
      name: location.name,
      postalCode: location.postalCode,
      selectable: location.geojson ? false : undefined,
      type: locationType?.name || locationType?.identifier || "",
      workplaceOnlyLocation: location.workplaceOnlyLocation,
    };
  });
}

function buildRowData(
  baseRowData: readonly (Omit<LocationTableDataType, "selectable" | "selected"> & {
    selectable: boolean | undefined;
  })[],
  selected: ReadonlySet<string>,
  selectedCustomer?: string | null,
): LocationTableDataType[] {
  console.assert(selectedCustomer === undefined || selected.size > 0);
  if (!selected.size) {
    return baseRowData.map((entry) => ({
      ...entry,
      selectable: entry.selectable ?? true,
      selected: false,
    }));
  } else {
    return baseRowData.map((entry) => ({
      ...entry,
      selectable: entry.selectable ?? entry.customerURL === selectedCustomer,
      selected: selected.has(entry.key),
    }));
  }
}

interface LocationTableProps {
  customerURL?: string | undefined;
  onClick?: ((locationURL: LocationUrl) => void) | undefined;
  onSelect: (locationURL: LocationUrl, selected: boolean) => void;
  selectedCustomer: CustomerUrl | null | undefined;
  selectedLocations: ReadonlySet<LocationUrl>;
  showInactive: boolean;
}

export function LocationTable(props: LocationTableProps): JSX.Element {
  const {
    customerURL,
    onClick,
    onSelect: onSelectLocation,
    selectedCustomer,
    selectedLocations,
    showInactive,
  } = props;

  const locationArray = useSelector(getVisibleLocationArray);
  const customerLookup = useSelector(getCustomerLookup);
  const activeCustomerArray = useSelector(getActiveCustomerArray);
  const locationTypeLookup = useSelector(getLocationTypeLookup);
  const locationTypeArray = useSelector(getLocationTypeArray);
  const locationTypesExist = !!locationTypeArray.length;

  const activeCustomerUrls = useMemo(() => {
    return new Set(activeCustomerArray.map(({url}) => url));
  }, [activeCustomerArray]);

  const customerFilteredLocations = useMemo((): readonly Location[] => {
    if (customerURL) {
      return locationArray.filter((location) => location.customer === customerURL);
    } else {
      return showInactive
        ? locationArray
        : locationArray.filter(
            (location) => !location.customer || activeCustomerUrls.has(location.customer),
          );
    }
  }, [activeCustomerUrls, customerURL, locationArray, showInactive]);

  const filteredLocations = useMemo((): readonly Location[] => {
    if (showInactive) {
      return customerFilteredLocations;
    } else {
      return customerFilteredLocations.filter((location) => location.active);
    }
  }, [customerFilteredLocations, showInactive]);
  const sortedLocations = useMemo(
    (): readonly Location[] =>
      filteredLocations.slice().sort(
        (a, b) =>
          // active before inactive; positive number means sort b first...
          Number(b.active) - Number(a.active) ||
          caseAccentInsensitiveCollator.compare(a.name, b.name) ||
          caseAccentInsensitiveCollator.compare(formatAddress(a), formatAddress(b)),
      ),
    [filteredLocations],
  );

  const columnSpecifications = useMemo(
    () => buildColumnSpecifications(onSelectLocation, onClick),
    [onClick, onSelectLocation],
  );

  // Hide the customer column on the filtered lists -- would be same value for all rows.
  const includeCustomerColumn = !customerURL;

  const baseRowData = useMemo(
    () =>
      buildBaseRowData(
        sortedLocations,
        customerLookup,
        locationTypeLookup,
        includeCustomerColumn,
        activeCustomerUrls,
      ),
    [
      customerLookup,
      includeCustomerColumn,
      locationTypeLookup,
      sortedLocations,
      activeCustomerUrls,
    ],
  );

  const rowData = useMemo(
    () => buildRowData(baseRowData, selectedLocations, selectedCustomer),
    [baseRowData, selectedCustomer, selectedLocations],
  );

  const {
    locationFavoritesEnabled,
    locations: {canMergeLocations},
  } = useSelector(getExtendedCustomerSettings);

  const visibleColumns = useMemo(
    () =>
      (
        [
          canMergeLocations ? "select" : null,
          locationFavoritesEnabled ? "favorite" : null,
          locationTypesExist ? "color" : null,
          locationTypesExist ? "type" : null,
          includeCustomerColumn ? "customer" : null,
          "address",
          "name",
          "postalCode",
          "city",
          "createdFormatted",
          "logOnlyLocation",
          "workplaceOnlyLocation",
          "active",
        ] as (LocationTableColumnID | null)[]
      ).filter(notNull),
    [canMergeLocations, includeCustomerColumn, locationFavoritesEnabled, locationTypesExist],
  );

  const filterString = useQueryParameter("q", "");

  const filteringData = useMemo(() => ({filterString, showInactive}), [filterString, showInactive]);

  return (
    <ConnectedTableWithPagination
      columns={columnSpecifications}
      defaultRowsPerPage={PaginationPageSize.SMALL}
      defaultSortDirection="ASC"
      defaultSortKey="name"
      entries={rowData}
      filteringData={filteringData}
      filterString={filterString}
      savePaginationIdentifier="LocationTable"
      saveSortingIdentifier="LocationTable"
      visibleColumns={visibleColumns}
    />
  );
}
