import {
  PriceItem,
  PriceItemUrl,
  Product,
  ProductUrl,
  Unit,
  UnitUrl,
} from "@co-common-libs/resources";
import {getUnitCode} from "@co-common-libs/resources-utils";

export function getPotentialTargetTransferProductUrls(
  productUrls: readonly ProductUrl[],
  unit: string,
  productLookup: (url: ProductUrl) => Product | undefined,
  unitLookup: (url: UnitUrl) => Unit | undefined,
): Set<ProductUrl> {
  const productTargets = new Set(
    productUrls.filter((url) => {
      const product = productLookup(url);
      const productUnitLowerCase = product && getUnitCode(product, unitLookup).toLowerCase();
      return product && unit.toLowerCase() === productUnitLowerCase;
    }),
  );
  return productTargets;
}

export function getPotentialTargetTransferPriceItemUrls(
  priceItemUrls: readonly PriceItemUrl[],
  unit: string,
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined,
  unitLookup: (url: UnitUrl) => Unit | undefined,
): Set<PriceItemUrl> {
  const priceItemTargets = new Set(
    priceItemUrls.filter((url) => {
      const priceItem = priceItemLookup(url);
      const priceItemUnitLowerCase = priceItem && getUnitCode(priceItem, unitLookup).toLowerCase();
      return priceItem && unit.toLowerCase() === priceItemUnitLowerCase;
    }),
  );
  return priceItemTargets;
}
