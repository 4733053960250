import {ProductGroupUrl, ProductUrl, UnitUrl} from "../resource-urls";
import {ResourceInstance} from "./base";

export interface Product extends ResourceInstance<ProductUrl> {
  readonly active: boolean;
  readonly barred: boolean;
  readonly catalogNumber: string;
  readonly conversionFactor: number | null;
  readonly conversionUnit: UnitUrl | null;
  readonly dmeId: number | null;
  readonly group: ProductGroupUrl | null;
  readonly name: string;
  readonly photoUrl: string;
  readonly price: number | null;
  readonly relatedUnit: UnitUrl | null;
  readonly remoteUrl: string;
}

export const emptyProduct: Omit<Product, "id" | "url"> = {
  active: true,
  barred: false,
  catalogNumber: "",
  conversionFactor: null,
  conversionUnit: null,
  dmeId: null,
  group: null,
  name: "",
  photoUrl: "",
  price: null,
  relatedUnit: null,
  remoteUrl: "",
};
