import {Config} from "@co-common-libs/config";
import {ReportingInputSpecification} from "@co-common-libs/resources";
import _ from "lodash";

export function getValue(
  customerSettings: Config,
  valueMaps: readonly {[identifier: string]: unknown}[],
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  inputSpecificationMap: ReadonlyMap<string, ReportingInputSpecification>,
  identifier: string,
): unknown {
  const inputSpecification = inputSpecificationMap.get(identifier);
  if (!inputSpecification || !valueMaps.length) {
    return null;
  }

  for (const valueMap of valueMaps) {
    const value = valueMap[identifier];
    if (value != null) {
      return value;
    }
  }

  const innerGetValue = getValue.bind(null, customerSettings, valueMaps, inputSpecificationMap);

  const conversionSpecification = inputSpecification.conversion;
  if (conversionSpecification) {
    const numeratorIdentifier = conversionSpecification.numerator;
    const denominatorIdentifier = conversionSpecification.denominator;
    const numeratorValue = innerGetValue(numeratorIdentifier);
    const denominatorValue = innerGetValue(denominatorIdentifier);
    if (
      typeof denominatorValue === "number" &&
      typeof numeratorValue === "number" &&
      denominatorValue !== 0
    ) {
      const computedValue = numeratorValue / denominatorValue;
      const decimalPlaces = customerSettings.materialDecimals;
      return _.round(computedValue, decimalPlaces);
    } else {
      return null;
    }
  }
  const subtractionSpecification = inputSpecification.subtraction;
  if (subtractionSpecification) {
    const minuendIdentifier = subtractionSpecification.minuend;
    const subtrahendIdentifier = subtractionSpecification.subtrahend;
    const minuendValue = innerGetValue(minuendIdentifier);
    const subtrahendValue = innerGetValue(subtrahendIdentifier);
    if (typeof minuendValue === "number" && typeof subtrahendValue === "number") {
      const computedValue = minuendValue - subtrahendValue;
      const decimalPlaces = customerSettings.materialDecimals;
      return _.round(computedValue, decimalPlaces);
    } else {
      return null;
    }
  }

  const multiplicationSpecification = inputSpecification.multiplication;
  if (multiplicationSpecification) {
    const multiplierIdentifier = multiplicationSpecification.multiplier;
    const multiplicandIdentifier = multiplicationSpecification.multiplicand;
    const multiplierValue = innerGetValue(multiplierIdentifier);
    const multiplicandValue = innerGetValue(multiplicandIdentifier);
    if (typeof multiplierValue === "number" && typeof multiplicandValue === "number") {
      const computedValue = multiplierValue * multiplicandValue;
      const decimalPlaces = customerSettings.materialDecimals;
      return _.round(computedValue, decimalPlaces);
    } else {
      return null;
    }
  }

  return null;
}
