import {Config} from "@co-common-libs/config";
import {getFrontendSentry} from "@co-frontend-libs/utils";
import {IntlShape} from "react-intl";
import {
  isAnyEconomicUserProfileIssue,
  isEconomicCustomerIssue,
  isEconomicMachinesIssue,
  isEconomicMultiInstanceIssue,
  isEconomicProductsIssue,
  isEconomicProjectIssue,
  isEconomicTaskIssue,
} from "./economic-issue-type-guards";
import {economicCustomerIssueTranslation} from "./issue-translations/economic-customer-issue-translation";
import {economicMachineIssueTranslation} from "./issue-translations/economic-machine-issue-translation";
import {economicProductIssueTranslation} from "./issue-translations/economic-product-issue-translation";
import {economicProjectIssueTranslation} from "./issue-translations/economic-project-issue-translation";
import {economicUserProfileIssueTranslation} from "./issue-translations/economic-user-profile-issue-translation";
import {economicVehicleIssueTranslation} from "./issue-translations/economic-vehicle-issue-translation";
import {EconomicIssue} from "./types";

// eslint-disable-next-line consistent-return
export function translateEconomicIssue(
  economicIssue: EconomicIssue,
  intl: IntlShape,
  customerSettings: Config,
): string {
  switch (economicIssue.resourceName) {
    case "customer":
      if (isEconomicCustomerIssue(economicIssue)) {
        return economicCustomerIssueTranslation(economicIssue, intl);
      }
      break;
    case "product":
      if (isEconomicProductsIssue(economicIssue)) {
        return economicProductIssueTranslation(economicIssue, intl);
      }
      break;
    case "project":
      if (isEconomicProjectIssue(economicIssue)) {
        return economicProjectIssueTranslation(economicIssue, intl);
      }
      break;
    case "machine": {
      if (isEconomicMachinesIssue(economicIssue)) {
        if (customerSettings.machineLabelVariant === "MACHINE") {
          return economicMachineIssueTranslation(economicIssue, intl);
        } else {
          return economicVehicleIssueTranslation(economicIssue, intl);
        }
      }
      break;
    }
    case "userProfile":
      if (isAnyEconomicUserProfileIssue(economicIssue)) {
        return economicUserProfileIssueTranslation(economicIssue, intl);
      }
      break;
    case "task":
      if (isEconomicTaskIssue(economicIssue)) {
        return intl.formatMessage(
          {
            defaultMessage: "Fejl under overførsel: {error}. Ret fejlen og overfør igen",
          },
          {error: economicIssue.errorMsg},
        );
      }
  }

  if (process.env.NODE_ENV !== "production") {
    throw new Error(`Issue missing translation: ${JSON.stringify(economicIssue)}`);
  }

  const sentry = getFrontendSentry();
  const issueKind = isEconomicMultiInstanceIssue(economicIssue)
    ? "Multi instance"
    : "Single instance";
  sentry.captureMessage(
    `${issueKind} economic issue with resourceName ${economicIssue.resourceName} and issueType ${economicIssue.issueType} is missing translation`,
    "error",
  );

  return "";
}
