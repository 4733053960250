import {MemberPatchOperation, ResourceInstance} from "@co-common-libs/resources";
import _ from "lodash";

type PartialWithoutIdentifiers<T> = Partial<Omit<T, "id" | "url">>;

export function diffResourceInstanceProperties<T extends ResourceInstance<string>>(
  updated: PartialWithoutIdentifiers<T>,
  base: T,
  compareProps?: (keyof PartialWithoutIdentifiers<T>)[],
): MemberPatchOperation<T>[] {
  const changes: MemberPatchOperation<T>[] = [];
  const props = compareProps ?? (Object.keys(updated) as (keyof PartialWithoutIdentifiers<T>)[]);

  for (const property of props) {
    const updatedValue = updated[property] as T[keyof PartialWithoutIdentifiers<T>];
    if (!_.isEqual(updatedValue, base[property])) {
      changes.push({member: property, value: updatedValue});
    }
  }

  return changes;
}
