import {Journal, Order, Task} from "@co-common-libs/resources";
import {getNormalisedDeviceTimestamp} from "@co-common-libs/resources-utils";
import _ from "lodash";

export const getJournalsForTask = (
  task: Task,
  order: Order | undefined,
  journalArray: readonly Journal[],
): Journal[] => {
  const usedLocations = new Set<string>();
  const {reportingLocations} = task;
  if (reportingLocations) {
    Object.values(reportingLocations).forEach((entry) => {
      if (entry.location) {
        usedLocations.add(entry.location);
      }
    });
  }

  if (task.fielduseSet && task.fielduseSet.length) {
    task.fielduseSet.forEach((fieldUse) => usedLocations.add(fieldUse.relatedField));
  }

  const workTypeURL = task && task.workType;
  const locationURL = task && task.relatedWorkplace;
  const customerURL = order ? order.customer : "";

  return _.sortBy(
    journalArray.filter((journal) => {
      if (journal.customer !== customerURL || !journal.reminder) {
        return false;
      }
      const {locations, workTypes} = journal;
      return (
        (!workTypes.length || (workTypeURL && workTypes.includes(workTypeURL))) &&
        (!locations?.length ||
          (locationURL && locations.includes(locationURL)) ||
          locations.some((location) => usedLocations.has(location)))
      );
    }),
    getNormalisedDeviceTimestamp,
  ).reverse();
};
