import {getWorkTypeString} from "@co-common-libs/resources-utils";
import {formatTime} from "@co-common-libs/utils";
import {getUserUserProfileLookup, getWorkTypeLookup} from "@co-frontend-libs/redux";
import React from "react";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {Alert} from "./alert";

interface FormattedAlertProps {
  alert: Alert;
}

export function FormattedAlert({alert}: FormattedAlertProps): JSX.Element {
  const {fromTimestamp, task, timer, triggeredByUserUrl} = alert;

  const userProfileLookup = useSelector(getUserUserProfileLookup);
  const workTypeLookup = useSelector(getWorkTypeLookup);
  const employeeProfile = userProfileLookup(triggeredByUserUrl);
  const employeeAlias = employeeProfile?.alias;
  const timerLabel = timer.label;
  const workType = task.workType && workTypeLookup(task.workType);
  const workTypeString = workType && getWorkTypeString(workType);
  const timerDescription = workTypeString ? `${timerLabel} (${workTypeString})` : timerLabel;
  const formattedTime = formatTime(fromTimestamp);

  return (
    <FormattedMessage
      defaultMessage="{employeeAlias} har aktiveret {timerDescription} kl. {formattedTime}. Vil du gå til opgaven?"
      values={{employeeAlias, formattedTime, timerDescription}}
    />
  );
}
