import {ColumnSpecification, RowData} from "./types";

const iconButtonColumnDefaults = {
  disableSorting: true,
  label: "",
  style: {padding: 0, verticalAlign: "center"},
  width: 48,
} as const satisfies Pick<
  ColumnSpecification<string>,
  "disableSorting" | "label" | "style" | "width"
>;

export function iconButtonColumnSpecification<
  FieldID extends string,
  KeyType extends string = string,
  DataType extends RowData<FieldID, KeyType> = RowData<FieldID, KeyType>,
>(
  input: Partial<ColumnSpecification<FieldID, KeyType, DataType>> & {
    field: FieldID;
    render: (data: DataType) => JSX.Element | null;
  },
): ColumnSpecification<FieldID, KeyType, DataType> {
  return {...iconButtonColumnDefaults, ...input};
}

const iconColumnDefaults = {
  disableSorting: true,
  label: "",
  style: {paddingLeft: 2, paddingRight: 2, verticalAlign: "center"},
  width: 28,
} as const satisfies Pick<
  ColumnSpecification<string>,
  "disableSorting" | "label" | "style" | "width"
>;

export function iconColumnSpecification<
  FieldID extends string,
  KeyType extends string = string,
  DataType extends RowData<FieldID, KeyType> = RowData<FieldID, KeyType>,
>(
  input: Partial<ColumnSpecification<FieldID, KeyType, DataType>> & {
    field: FieldID;
    render: (data: DataType) => JSX.Element | null;
  },
): ColumnSpecification<FieldID, KeyType, DataType> {
  return {...iconColumnDefaults, ...input};
}

const colorBoxColumnDefaults = {
  disableSorting: true,
  label: "",
  style: {paddingLeft: 8, paddingRight: 8, verticalAlign: "top"},
  width: 32,
} as const satisfies Pick<
  ColumnSpecification<string>,
  "disableSorting" | "label" | "style" | "width"
>;

export function colorBoxColumnSpecification<
  FieldID extends string,
  KeyType extends string = string,
  DataType extends RowData<FieldID, KeyType> = RowData<FieldID, KeyType>,
>(
  input: Partial<ColumnSpecification<FieldID, KeyType, DataType>> & {
    field: FieldID;
    render: (data: DataType) => JSX.Element | null;
  },
): ColumnSpecification<FieldID, KeyType, DataType> {
  return {...colorBoxColumnDefaults, ...input};
}
