import {jsonFetch} from "@co-frontend-libs/utils";

export type BackendConfigurationSection = {
  name: string;
  properties: {name: string; type: string}[];
};

export async function getBackendConfigurationSections(): Promise<BackendConfigurationSection[]> {
  const response = await jsonFetch("api/backend_configuration_sections/");
  return response.data;
}

export async function getBackendConfiguration(key: string): Promise<Record<string, any>> {
  const response = await jsonFetch(`api/backend_configuration/${key}`);
  return response.data;
}

export async function setBackendConfiguration(
  key: string,
  update: Record<string, any>,
): Promise<void> {
  await jsonFetch(`api/backend_configuration/${key}`, "POST", update);
}
