import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {PureComponent} from "app-utils";
import _ from "lodash";
import React from "react";
import {FormattedMessage, FormattedNumber} from "react-intl";

interface ReportResultsTotalsRowProps {
  correctedValue: number;
  decimals: number;
  name: string;
  unit: string;
  value: number;
}

class ReportResultsTotalsRow extends PureComponent<ReportResultsTotalsRowProps> {
  render(): JSX.Element {
    const {correctedValue, decimals, name, unit, value} = this.props;
    return (
      <TableRow>
        <TableCell>{name}</TableCell>
        <TableCell style={{textAlign: "right"}}>
          <FormattedNumber
            maximumFractionDigits={decimals}
            minimumFractionDigits={decimals}
            value={value}
          />
        </TableCell>
        <TableCell style={{textAlign: "right"}}>
          <FormattedNumber
            maximumFractionDigits={decimals}
            minimumFractionDigits={decimals}
            value={correctedValue}
          />
        </TableCell>
        <TableCell>{unit}</TableCell>
      </TableRow>
    );
  }
}

interface ReportResultsTotalsTableProps {
  decimals: number;
  results: {
    [unit: string]: {
      [identifier: string]: {
        correctedValue: number;
        name: string;
        value: number;
      };
    };
  };
}

export class ReportResultsTotalsTable extends PureComponent<ReportResultsTotalsTableProps> {
  render(): JSX.Element {
    const {decimals, results} = this.props;

    const rows: JSX.Element[] = [];
    _.sortBy(Object.keys(results), (s) => s.toLowerCase()).forEach((unit) => {
      Object.keys(results[unit])
        .sort()
        .forEach((key) => {
          const priceItem = results[unit][key];

          rows.push(
            <ReportResultsTotalsRow
              key={key + unit}
              correctedValue={priceItem.correctedValue}
              decimals={decimals}
              name={priceItem.name}
              unit={unit}
              value={priceItem.value}
            />,
          );
        });
    });
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage defaultMessage="Navn" id="report-display-card.label.name" />
            </TableCell>
            <TableCell style={{textAlign: "right"}}>
              <FormattedMessage defaultMessage="Total" id="report-display-card.label.registrated" />
            </TableCell>
            <TableCell style={{textAlign: "right"}}>
              <FormattedMessage
                defaultMessage="Total, faktureres"
                id="report-display-card.label.corrected"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage defaultMessage="Enhed" id="report-display-card.label.unit" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    );
  }
}
