function compareFromPosMembers(a: {fromPos: number}, b: {fromPos: number}): number {
  return a.fromPos - b.fromPos;
}

export function mergeOverlappingFromToSequence(
  sequence: readonly {readonly fromPos: number; readonly toPos: number}[],
): readonly {readonly fromPos: number; readonly toPos: number}[] {
  if (sequence.length <= 1) {
    // zero or one entry, so nothing to merge
    return sequence;
  }
  const result: {fromPos: number; toPos: number}[] = [];
  const sortedSequence = sequence.slice().sort(compareFromPosMembers);
  let {fromPos, toPos} = sortedSequence[0];
  // intentionally skip first element here
  for (let i = 1; i < sortedSequence.length; i += 1) {
    const entry = sortedSequence[i];
    if (entry.fromPos > toPos) {
      result.push({fromPos, toPos});
      ({fromPos, toPos} = entry);
    } else {
      if (entry.toPos > toPos) {
        ({toPos} = entry);
      }
    }
  }
  result.push({fromPos, toPos});
  return result;
}
