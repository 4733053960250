import {ResponsiveDialog} from "@co-frontend-libs/components";
import {DialogContent, Fab} from "@material-ui/core";
import {DragAndDropUploadOverlay, useFileInputChangeHandler} from "app-components";
import RefreshIcon from "mdi-react/RefreshIcon";
import React, {useCallback, useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";

interface UploadImageDialogProps {
  onClose: () => void;
  onOk: (imageUrl: string) => void;
  open: boolean;
}

const ACCEPTED_FILE_TYPES = "image/jpeg,image/png";
export const UploadImageDialog = React.memo(function UploadImageDialog({
  onClose,
  onOk,
  open,
}: UploadImageDialogProps) {
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  const handleFiles = useCallback((files: File[]): void => {
    const [logoFile] = files;
    if (ACCEPTED_FILE_TYPES.split(",").includes(logoFile.type)) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageUrl(reader.result as string);
      };
      reader.readAsDataURL(logoFile);
    }
  }, []);

  const handleFileInputChange = useFileInputChangeHandler(
    handleFiles,
    (files) => {
      // eslint-disable-next-line no-console
      console.error("files rejected", files);
    },
    ACCEPTED_FILE_TYPES,
  );

  const intl = useIntl();

  const handleOk = useCallback(() => {
    if (imageUrl) {
      onOk(imageUrl);
    }
  }, [imageUrl, onOk]);

  const handleReset = useCallback(() => {
    setImageUrl(null);
  }, []);

  useEffect(() => {
    if (open) {
      setImageUrl(null);
    }
  }, [open]);

  return (
    <ResponsiveDialog
      fullWidth
      open={open}
      title={intl.formatMessage({defaultMessage: "Upload logo"})}
      onCancel={onClose}
      onOk={handleOk}
    >
      <DialogContent>
        {imageUrl ? (
          <div>
            <img
              src={imageUrl}
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                maxWidth: "100%",
              }}
            />
            <Fab
              component="span"
              style={{
                bottom: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
              onClick={handleReset}
            >
              <RefreshIcon />
            </Fab>
          </div>
        ) : (
          <DragAndDropUploadOverlay onFiles={handleFiles}>
            <input
              accept={ACCEPTED_FILE_TYPES}
              id="logo-upload"
              style={{display: "none"}}
              type="file"
              onChange={handleFileInputChange}
            />
            <label
              htmlFor="logo-upload"
              style={{
                alignItems: "center",
                backgroundColor: "lightgray",
                display: "flex",
                flexDirection: "column",
                height: 100,
                justifyContent: "center",
                minWidth: 200,
              }}
            >
              <FormattedMessage defaultMessage="Tilføj billede" tagName="h3" />

              <FormattedMessage defaultMessage="eller træk og slip" />
            </label>
          </DragAndDropUploadOverlay>
        )}
      </DialogContent>
    </ResponsiveDialog>
  );
});
