import {getCordovaSQLiteStorageConnection} from "./cordova-sqlite-storage";
import {DBConnection} from "./db-connection";
import {getIndexedDBConnection} from "./indexeddb";

export {StoreWrapper} from "./store-wrapper";

export const getDBConnection = (dbName: string, storeNames: string[]): Promise<DBConnection> => {
  if (typeof cordova !== "undefined" && window.sqlitePlugin) {
    return getCordovaSQLiteStorageConnection(window.sqlitePlugin, dbName, storeNames);
  }
  return getIndexedDBConnection(window.indexedDB, dbName, storeNames);
};
