// This file generated by rebuild-resource-names.mjs

import {
  AccomodationAllowance,
  AccumulatedCompensatory,
  AnniversaryType,
  ArchiveFile,
  ArchiveLink,
  Availability,
  CachedDailyAccumulatedCompensatory,
  CalendarOrdering,
  CalendarWorkHours,
  Contact,
  Crop,
  Culture,
  Customer,
  CustomerFile,
  DaysAbsence,
  Delivery,
  DeliveryLocation,
  DinnerBooking,
  EmployeeGroup,
  ExpectedAmount,
  HoursAbsence,
  Information,
  Journal,
  KrPerLiterDefaultFuelSurchargeUse,
  KrPerLiterFuelSurchargeBasis,
  KrPerLiterFuelSurchargeSpecification,
  KrPerLiterFuelSurchargeSpecificationEntry,
  KrPerLiterMachineFuelSurchargeUse,
  KrPerLiterWorkTypeFuelSurchargeUse,
  Location,
  LocationStorageAdjustment,
  LocationStorageChange,
  LocationStorageStatus,
  LocationType,
  LocationUseLog,
  LunchBooking,
  Machine,
  MachineGroup,
  MachineLastUsed,
  MachineUseLog,
  Order,
  OrderFile,
  Pickup,
  PickupLocation,
  Position,
  PriceGroup,
  PriceItem,
  PricePercentDefaultFuelSurchargeUse,
  PricePercentFuelSurchargeBasis,
  PricePercentFuelSurchargeSpecification,
  PricePercentFuelSurchargeSpecificationEntry,
  PricePercentMachineFuelSurchargeUse,
  PricePercentWorkTypeFuelSurchargeUse,
  Product,
  ProductGroup,
  ProductUseLog,
  Project,
  ProjectFile,
  PunchInOut,
  RemunerationReport,
  ReportingPrintout,
  ReportingSpecification,
  ReportSetup,
  Role,
  RouteLogReport,
  RoutePlan,
  RoutePlanTask,
  RoutePlanTaskActivityOption,
  RoutePlanTaskResult,
  RouteTask,
  RouteTaskActivityOption,
  RouteTaskResult,
  SettingEntry,
  Settings,
  Spray,
  SprayLocation,
  SprayLog,
  SprayLogReport,
  SyncLog,
  Task,
  TaskFile,
  TaskPhoto,
  TasksPrintout,
  Timer,
  TimerStart,
  TransportLog,
  TransportLogReport,
  Unit,
  User,
  UserFile,
  UserPhoto,
  UserProfile,
  WorkshopChecklist,
  WorkshopChecklistItem,
  WorkType,
  YieldDelivery,
  YieldDeliveryLocation,
  YieldLog,
  YieldLogReport,
  YieldPickup,
  YieldPickupLocation,
} from "./resource-types";

export interface ResourceTypes {
  accomodationAllowance: AccomodationAllowance;
  accumulatedCompensatory: AccumulatedCompensatory;
  anniversaryType: AnniversaryType;
  archiveFile: ArchiveFile;
  archiveLink: ArchiveLink;
  availability: Availability;
  cachedDailyAccumulatedCompensatory: CachedDailyAccumulatedCompensatory;
  calendarOrdering: CalendarOrdering;
  calendarWorkHours: CalendarWorkHours;
  contact: Contact;
  crop: Crop;
  culture: Culture;
  customer: Customer;
  customerFile: CustomerFile;
  daysAbsence: DaysAbsence;
  delivery: Delivery;
  deliveryLocation: DeliveryLocation;
  dinnerBooking: DinnerBooking;
  employeeGroup: EmployeeGroup;
  expectedAmount: ExpectedAmount;
  hoursAbsence: HoursAbsence;
  information: Information;
  journal: Journal;
  krPerLiterDefaultFuelSurchargeUse: KrPerLiterDefaultFuelSurchargeUse;
  krPerLiterFuelSurchargeBasis: KrPerLiterFuelSurchargeBasis;
  krPerLiterFuelSurchargeSpecification: KrPerLiterFuelSurchargeSpecification;
  krPerLiterFuelSurchargeSpecificationEntry: KrPerLiterFuelSurchargeSpecificationEntry;
  krPerLiterMachineFuelSurchargeUse: KrPerLiterMachineFuelSurchargeUse;
  krPerLiterWorkTypeFuelSurchargeUse: KrPerLiterWorkTypeFuelSurchargeUse;
  location: Location;
  locationStorageAdjustment: LocationStorageAdjustment;
  locationStorageChange: LocationStorageChange;
  locationStorageStatus: LocationStorageStatus;
  locationType: LocationType;
  locationUseLog: LocationUseLog;
  lunchBooking: LunchBooking;
  machine: Machine;
  machineGroup: MachineGroup;
  machineLastUsed: MachineLastUsed;
  machineUseLog: MachineUseLog;
  order: Order;
  orderFile: OrderFile;
  pickup: Pickup;
  pickupLocation: PickupLocation;
  position: Position;
  priceGroup: PriceGroup;
  priceItem: PriceItem;
  pricePercentDefaultFuelSurchargeUse: PricePercentDefaultFuelSurchargeUse;
  pricePercentFuelSurchargeBasis: PricePercentFuelSurchargeBasis;
  pricePercentFuelSurchargeSpecification: PricePercentFuelSurchargeSpecification;
  pricePercentFuelSurchargeSpecificationEntry: PricePercentFuelSurchargeSpecificationEntry;
  pricePercentMachineFuelSurchargeUse: PricePercentMachineFuelSurchargeUse;
  pricePercentWorkTypeFuelSurchargeUse: PricePercentWorkTypeFuelSurchargeUse;
  product: Product;
  productGroup: ProductGroup;
  productUseLog: ProductUseLog;
  project: Project;
  projectFile: ProjectFile;
  punchInOut: PunchInOut;
  remunerationReport: RemunerationReport;
  reportingPrintout: ReportingPrintout;
  reportingSpecification: ReportingSpecification;
  reportSetup: ReportSetup;
  role: Role;
  routeLogReport: RouteLogReport;
  routePlan: RoutePlan;
  routePlanTask: RoutePlanTask;
  routePlanTaskActivityOption: RoutePlanTaskActivityOption;
  routePlanTaskResult: RoutePlanTaskResult;
  routeTask: RouteTask;
  routeTaskActivityOption: RouteTaskActivityOption;
  routeTaskResult: RouteTaskResult;
  settingEntry: SettingEntry;
  settings: Settings;
  spray: Spray;
  sprayLocation: SprayLocation;
  sprayLog: SprayLog;
  sprayLogReport: SprayLogReport;
  syncLog: SyncLog;
  task: Task;
  taskFile: TaskFile;
  taskPhoto: TaskPhoto;
  tasksPrintout: TasksPrintout;
  timer: Timer;
  timerStart: TimerStart;
  transportLog: TransportLog;
  transportLogReport: TransportLogReport;
  unit: Unit;
  user: User;
  userFile: UserFile;
  userPhoto: UserPhoto;
  userProfile: UserProfile;
  workshopChecklist: WorkshopChecklist;
  workshopChecklistItem: WorkshopChecklistItem;
  workType: WorkType;
  yieldDelivery: YieldDelivery;
  yieldDeliveryLocation: YieldDeliveryLocation;
  yieldLog: YieldLog;
  yieldLogReport: YieldLogReport;
  yieldPickup: YieldPickup;
  yieldPickupLocation: YieldPickupLocation;
}

export type ResourceName = keyof ResourceTypes;

export const resourceNames: readonly ResourceName[] = [
  "accomodationAllowance",
  "accumulatedCompensatory",
  "anniversaryType",
  "archiveFile",
  "archiveLink",
  "availability",
  "cachedDailyAccumulatedCompensatory",
  "calendarOrdering",
  "calendarWorkHours",
  "contact",
  "crop",
  "culture",
  "customer",
  "customerFile",
  "daysAbsence",
  "delivery",
  "deliveryLocation",
  "dinnerBooking",
  "employeeGroup",
  "expectedAmount",
  "hoursAbsence",
  "information",
  "journal",
  "krPerLiterDefaultFuelSurchargeUse",
  "krPerLiterFuelSurchargeBasis",
  "krPerLiterFuelSurchargeSpecification",
  "krPerLiterFuelSurchargeSpecificationEntry",
  "krPerLiterMachineFuelSurchargeUse",
  "krPerLiterWorkTypeFuelSurchargeUse",
  "location",
  "locationStorageAdjustment",
  "locationStorageChange",
  "locationStorageStatus",
  "locationType",
  "locationUseLog",
  "lunchBooking",
  "machine",
  "machineGroup",
  "machineLastUsed",
  "machineUseLog",
  "order",
  "orderFile",
  "pickup",
  "pickupLocation",
  "position",
  "priceGroup",
  "priceItem",
  "pricePercentDefaultFuelSurchargeUse",
  "pricePercentFuelSurchargeBasis",
  "pricePercentFuelSurchargeSpecification",
  "pricePercentFuelSurchargeSpecificationEntry",
  "pricePercentMachineFuelSurchargeUse",
  "pricePercentWorkTypeFuelSurchargeUse",
  "product",
  "productGroup",
  "productUseLog",
  "project",
  "projectFile",
  "punchInOut",
  "remunerationReport",
  "reportingPrintout",
  "reportingSpecification",
  "reportSetup",
  "role",
  "routeLogReport",
  "routePlan",
  "routePlanTask",
  "routePlanTaskActivityOption",
  "routePlanTaskResult",
  "routeTask",
  "routeTaskActivityOption",
  "routeTaskResult",
  "settingEntry",
  "settings",
  "spray",
  "sprayLocation",
  "sprayLog",
  "sprayLogReport",
  "syncLog",
  "task",
  "taskFile",
  "taskPhoto",
  "tasksPrintout",
  "timer",
  "timerStart",
  "transportLog",
  "transportLogReport",
  "unit",
  "user",
  "userFile",
  "userPhoto",
  "userProfile",
  "workshopChecklist",
  "workshopChecklistItem",
  "workType",
  "yieldDelivery",
  "yieldDeliveryLocation",
  "yieldLog",
  "yieldLogReport",
  "yieldPickup",
  "yieldPickupLocation",
];
