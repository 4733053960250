import {grey} from "@material-ui/core/colors";

export const MINUTES_HEADER_STYLE = {
  paddingLeft: 8,
  paddingRight: 8,
  textAlign: "center",
  width: 56,
} as const;

export const MINUTES_STYLE = {
  backgroundColor: grey[200],
  ...MINUTES_HEADER_STYLE,
  textAlign: "right",
} as const;

export const TRUNCATE_STYLE = {
  maxWidth: "100%",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
} as const;

export const orderIconLinkColumnStyle = {
  paddingBottom: 0,
  paddingLeft: 24,
  paddingRight: 4,
  paddingTop: 0,
  width: 52,
} as const;

export const statusIconColumnStyle = {
  paddingLeft: 5,
  paddingRight: 5,
  width: 34,
} as const;

export const photoIconColumnStyle = {
  paddingLeft: 5,
  paddingRight: 5,
  width: 48,
} as const;
