import {ResetObjectAction, SetObjectPropertyAction} from "./types";

export function simpleObjectReducer<T>(
  state: T,
  action: ResetObjectAction<T> | SetObjectPropertyAction<T>,
): T {
  const {type, value} = action;
  switch (type) {
    case "set":
      return {...state, [action.propertyName]: value};
    case "reset":
      return {...value};
    default:
      throw new Error(`Unknown action type: ${type}`);
  }
}

export function plainObjectReducer<T>(state: T, newState: Partial<T>): T {
  return {...state, ...newState};
}
