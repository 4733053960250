const globalBase = {
  authentication: {
    loginURL: "{LOCATION_ORIGIN}/api/token-auth/",
    refreshURL: "{LOCATION_ORIGIN}/api/token-auth-refresh/",
  },
  baseURL: "{LOCATION_ORIGIN}",
  cordova: {
    pluginsRequiredAppVersion: {
      /* eslint-disable @typescript-eslint/naming-convention */
      "cordova-background-geolocation-lt": "1.4.3",
      "cordova-plugin-background-fetch": "1.4.3",
      /* eslint-enable @typescript-eslint/naming-convention */
    },
  },
  login: {
    defaultRoute: "/",
    fetchingUserDataRoute: "/fetching-user-data",
    loadingRoute: "/loading",
    loginRoute: "/login",
  },
  logoURL:
    process.env["NODE_ENV"] === "production"
      ? "{LOCATION_ORIGIN}/logo.png"
      : "{LOCATION_ORIGIN}/customoffice_logo.png",
  resources: {
    baseURL: "{LOCATION_ORIGIN}/api/",
    updateFetchMaxSeconds: 604800,
    updateFetchMinSeconds: 115,
  },
};

function getLocationOrigin(): string {
  let locationOrigin = location.origin;
  if (!locationOrigin) {
    locationOrigin = `${location.protocol}//${location.hostname}${
      location.port ? `:${location.port}` : ""
    }`;
  }
  if ((window as any).CUSTOMOFFICE_LOCATION_ORIGIN) {
    locationOrigin = (window as any).CUSTOMOFFICE_LOCATION_ORIGIN;
  }
  return locationOrigin;
}

function buildGlobalConfig(): typeof globalBase {
  const locationOrigin = getLocationOrigin();
  function replaceVars<T>(value: T): T;
  function replaceVars(value: any): any {
    if (typeof value === "string") {
      return value.replace("{LOCATION_ORIGIN}", locationOrigin);
    } else if (typeof value === "number") {
      return value;
    } else {
      const result = {...value};
      Object.keys(result).forEach((key) => {
        result[key] = replaceVars(result[key]);
      });
      return result;
    }
  }
  const result = {...globalBase};
  (Object.keys(result) as (keyof typeof result)[]).forEach((key) => {
    result[key] = replaceVars(result[key]) as any;
  });

  return result;
}

export const globalConfig = buildGlobalConfig();
