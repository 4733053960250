import {Config} from "@co-common-libs/config";
import {
  Customer,
  PriceGroup,
  PriceGroupUrl,
  PriceItem,
  PriceItemUrl,
  RoutePlanTask,
  RoutePlanTaskActivityOption,
  RoutePlanTaskResult,
  Unit,
  UnitUrl,
} from "@co-common-libs/resources";
import {getPriceItemIdentifierString, getUnitString} from "@co-common-libs/resources-utils";
import {TableCell, TableRow} from "@material-ui/core";
import {orange, red, yellow} from "@material-ui/core/colors";
import {PureComponent} from "app-utils";
import React, {CSSProperties} from "react";
import {FormattedNumber} from "react-intl";
import {
  COUNT_COLUMN_WIDTH,
  PRICE_COLUMN_WIDTH,
  PRODUCT_NUMBER_COLUMN_WIDTH,
  UNIT_COLUMN_WIDTH,
  VARIANT_NUMBER_COLUMN_WIDTH,
} from "./constants";

export interface ResultWithErrorChecks {
  priceItemMissingOnGroup: boolean;
  routePlanTaskResult: RoutePlanTaskResult;
}

export interface ActivityOptionWithErrorChecks {
  activityOption: RoutePlanTaskActivityOption;
  incorrectWorkType: boolean;
  priceItemsMissing: boolean;
  routeCustomerIsNotAllowedToUseSpecificPriceGroup: boolean;
  routeCustomerIsNotAllowedToUseUnspecificPriceGroup: boolean;
}

interface RoutePlanTaskRowProps {
  customer?: Customer | undefined;
  customerSettings: Config;
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined;
  routePlanTask?: RoutePlanTask | undefined;
  routePlanTaskActivityOptionWithErrorChecks?: ActivityOptionWithErrorChecks | undefined;
  routePlanTaskResultWithErrorChecks?: ResultWithErrorChecks | undefined;
  unitLookup: (url: UnitUrl) => Unit | undefined;
}

const border = "1px solid rgb(224, 224, 224)" as const;
const noBorder = "none" as const;

export class RoutePlanTaskRow extends PureComponent<RoutePlanTaskRowProps> {
  render(): JSX.Element {
    const {
      customer,
      customerSettings,
      priceGroupLookup,
      priceItemLookup,
      routePlanTask,
      routePlanTaskActivityOptionWithErrorChecks,
      routePlanTaskResultWithErrorChecks,
      unitLookup,
    } = this.props;
    const routePlanTaskActivityOption = routePlanTaskActivityOptionWithErrorChecks?.activityOption;
    const priceGroup =
      routePlanTaskActivityOption?.activity &&
      priceGroupLookup(routePlanTaskActivityOption.activity);
    const priceItemsMissing = routePlanTaskActivityOptionWithErrorChecks?.priceItemsMissing;
    const incorrectWorkType = routePlanTaskActivityOptionWithErrorChecks?.incorrectWorkType;
    const routeCustomerIsNotAllowedToUseSpecificPriceGroup =
      routePlanTaskActivityOptionWithErrorChecks?.routeCustomerIsNotAllowedToUseSpecificPriceGroup;
    const routeCustomerIsNotAllowedToUseUnspecificPriceGroup =
      routePlanTaskActivityOptionWithErrorChecks?.routeCustomerIsNotAllowedToUseUnspecificPriceGroup;
    const routePlanTaskResult = routePlanTaskResultWithErrorChecks?.routePlanTaskResult;
    const priceItemMissingOnGroup = routePlanTaskResultWithErrorChecks?.priceItemMissingOnGroup;
    const priceItem =
      routePlanTaskResult?.specification && priceItemLookup(routePlanTaskResult.specification);
    const price = priceItem ? priceItem.price : undefined;
    const formattedPrice =
      typeof price === "number" ? (
        <FormattedNumber maximumFractionDigits={2} minimumFractionDigits={2} value={price} />
      ) : price === null ? (
        "-"
      ) : null;
    const taskBorder = routePlanTask ? border : noBorder;
    const activityOptionBorder = routePlanTask || routePlanTaskActivityOption ? border : noBorder;
    const resultBorder =
      routePlanTask || routePlanTaskActivityOption || routePlanTaskResult ? border : noBorder;

    const priceItemBackgroundStyle: CSSProperties = {};
    if (priceItemMissingOnGroup || (priceItem && !priceItem.active)) {
      priceItemBackgroundStyle.backgroundColor = red[100];
    }
    const priceGroupBackgroundStyle: CSSProperties = {};
    if (
      incorrectWorkType ||
      routeCustomerIsNotAllowedToUseSpecificPriceGroup ||
      (priceGroup && !priceGroup.active)
    ) {
      priceGroupBackgroundStyle.backgroundColor = red[100];
    } else if (routeCustomerIsNotAllowedToUseUnspecificPriceGroup) {
      priceGroupBackgroundStyle.backgroundColor = orange[100];
    } else if (priceItemsMissing) {
      priceGroupBackgroundStyle.backgroundColor = yellow[100];
    }

    return (
      <TableRow style={{borderBottom: noBorder}}>
        <TableCell style={{borderBottom: "none", borderTop: taskBorder}}>
          {routePlanTask && routePlanTask.description}
          {customer && customer.name}
        </TableCell>
        <TableCell
          style={{
            borderBottom: "none",
            borderTop: activityOptionBorder,
            width: VARIANT_NUMBER_COLUMN_WIDTH,
            ...priceGroupBackgroundStyle,
          }}
        >
          {priceGroup && priceGroup.identifier}
        </TableCell>
        <TableCell
          style={{
            borderBottom: "none",
            borderTop: activityOptionBorder,
            ...priceGroupBackgroundStyle,
          }}
        >
          {priceGroup && priceGroup.name}
        </TableCell>
        <TableCell
          style={{
            borderBottom: "none",
            borderTop: resultBorder,
            width: PRODUCT_NUMBER_COLUMN_WIDTH,
            ...priceItemBackgroundStyle,
          }}
        >
          {priceItem && getPriceItemIdentifierString(priceItem)}
        </TableCell>
        <TableCell
          style={{
            borderBottom: "none",
            borderTop: resultBorder,
            ...priceItemBackgroundStyle,
          }}
        >
          {priceItem && priceItem.name}
        </TableCell>
        <TableCell
          style={{
            borderBottom: "none",
            borderTop: resultBorder,
            width: COUNT_COLUMN_WIDTH,
            ...priceItemBackgroundStyle,
          }}
        >
          {routePlanTaskResult && routePlanTaskResult.quoted != null ? (
            <FormattedNumber value={routePlanTaskResult.quoted} />
          ) : null}
        </TableCell>
        <TableCell
          style={{
            borderBottom: "none",
            borderTop: resultBorder,
            width: UNIT_COLUMN_WIDTH,
            ...priceItemBackgroundStyle,
          }}
        >
          {priceItem && getUnitString(priceItem, unitLookup)}
        </TableCell>
        {customerSettings.overviewShowPrices ? (
          <TableCell
            style={{
              borderBottom: "none",
              borderTop: resultBorder,
              textAlign: "right",
              width: PRICE_COLUMN_WIDTH,
              ...priceItemBackgroundStyle,
            }}
          >
            {formattedPrice}
          </TableCell>
        ) : null}
      </TableRow>
    );
  }
}
