import {TrimTextField} from "@co-frontend-libs/components";
import {SetObjectPropertyAction, useObjectPropertyOnChangeDispatcher} from "app-utils";
import React, {Dispatch} from "react";
import {FormattedMessage, IntlShape, useIntl} from "react-intl";
import {z} from "zod";

// We want the return type from the Zod schema
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function createFormContactValidation(intl: IntlShape) {
  return z.object({
    cellphone: z.string(),
    email: z
      .string()
      .email(intl.formatMessage({defaultMessage: "Mailadressen er ikke valid"}))
      .or(z.literal("")),
    fax: z.string(),
    name: z.string().min(1, intl.formatMessage({defaultMessage: "Navn skal udfyldes"})),
    phone: z.string(),
  });
}

type FormContactSchema = ReturnType<typeof createFormContactValidation>;

export type FormContact = z.output<FormContactSchema>;
export type FormContactError = z.ZodError<z.input<FormContactSchema>>;

export const CustomerContactForm = React.memo(function CustomerContactForm(props: {
  contact: FormContact;
  contactsMayHaveCellphone: boolean;
  contactsMayHaveFax: boolean;
  dispatch: Dispatch<SetObjectPropertyAction<FormContact>>;
  validationError: FormContactError | null;
}): JSX.Element {
  const {contact, contactsMayHaveCellphone, contactsMayHaveFax, dispatch, validationError} = props;

  const fieldErrors = validationError?.formErrors.fieldErrors;

  const intl = useIntl();
  const changePropagator = useObjectPropertyOnChangeDispatcher<FormContact>(dispatch);

  return (
    <>
      <TrimTextField
        autoFocus
        fullWidth
        required
        error={!!contact.name && !!fieldErrors?.name}
        label={intl.formatMessage({defaultMessage: "Navn"})}
        margin="dense"
        value={contact.name}
        variant="outlined"
        warningText={contact.name && fieldErrors?.name?.join(" ")}
        onChange={changePropagator.handleChange("name")}
      />
      {contactsMayHaveCellphone ? (
        <TrimTextField
          fullWidth
          label={intl.formatMessage({defaultMessage: "Mobil"})}
          margin="dense"
          type="tel"
          value={contact.cellphone}
          variant="outlined"
          onChange={changePropagator.handleChange("cellphone")}
        />
      ) : null}
      <TrimTextField
        fullWidth
        label={intl.formatMessage({defaultMessage: "Telefon"})}
        margin="dense"
        type="tel"
        value={contact.phone}
        variant="outlined"
        onChange={changePropagator.handleChange("phone")}
      />
      <TrimTextField
        fullWidth
        error={!!fieldErrors?.email}
        label={intl.formatMessage({defaultMessage: "Email"})}
        margin="dense"
        type="email"
        value={contact.email}
        variant="outlined"
        warningText={fieldErrors?.email?.join(" ")}
        onChange={changePropagator.handleChange("email")}
      />
      {contactsMayHaveFax ? (
        <TrimTextField
          fullWidth
          label={intl.formatMessage({defaultMessage: "Fax"})}
          margin="dense"
          type="tel"
          value={contact.fax}
          variant="outlined"
          onChange={changePropagator.handleChange("fax")}
        />
      ) : null}
      <FormattedMessage defaultMessage="* Skal udfyldes" />
    </>
  );
});
