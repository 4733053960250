import {Config} from "@co-common-libs/config";
import {
  AccomodationAllowance,
  ComputedTime,
  Machine,
  Task,
  TaskPhoto,
  Timer,
  User,
  UserProfile,
  WorkType,
  WorkTypeUrl,
  WorkshopChecklist,
  WorkshopChecklistAnswer,
  WorkshopChecklistItem,
} from "@co-common-libs/resources";
import {formatDuration} from "@co-common-libs/resources-utils";
import {MINUTE_MILLISECONDS, dateToString, formatTime} from "@co-common-libs/utils";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {
  AppState,
  getAccomodationAllowanceArray,
  getCustomerSettings,
  getMachineLookup,
  getTaskPhotoArray,
  getUserLookup,
  getUserUserProfileLookup,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {colorMap, useResettingState} from "@co-frontend-libs/utils";
import {DialogContent} from "@material-ui/core";
import {
  ErrorAction,
  ErrorEntry,
  PureComponent,
  computeIntervalSums,
  getEmployeeTaskRemunerationGroup,
  getInternalTaskErrors,
  isErrorEntryObjectWithAction,
  mapSum,
  userCanRegisterAccommodation,
} from "app-utils";
import {bind} from "bind-decorator";
import {SendTask} from "feat-send-task-data";
import React, {useCallback, useMemo, useState} from "react";
import {FormattedMessage, IntlContext} from "react-intl";
import {connect, useSelector} from "react-redux";
import {createStructuredSelector} from "reselect";
import {AccommodationAllowanceCheckbox} from "./accommodation-allowance-checkbox";
import {ErrorList} from "./error-list";
import {Linkify} from "./linkify";

interface MeasuredIfDifferentProps {
  entered?: number;
  measured?: number;
}

const MeasuredIfDifferent = React.memo(function MeasuredIfDifferent(
  props: MeasuredIfDifferentProps,
): JSX.Element {
  const customerSettings = useSelector(getCustomerSettings);
  const {entered, measured} = props;
  if (entered === measured) {
    return <td />;
  } else {
    return (
      <FormattedMessage
        defaultMessage="(Målt: {measuredTime})"
        tagName="td"
        values={{
          measuredTime: formatDuration(customerSettings.durationFormat, measured),
        }}
      />
    );
  }
});

interface TaskCheckDialogContentProps {
  accomodationAllowanceArray: readonly AccomodationAllowance[];
  autofocusErrorAction: boolean;
  autofocusWarningAction: boolean;
  computedEndTime?: string | undefined;
  computedIntervals?: readonly ComputedTime[] | undefined;
  computedStartTime?: string | undefined;
  customerSettings: Config;
  date?: string | undefined;
  errorHeaderText?: string;
  errors: readonly ErrorEntry[];
  finalEndTime?: string | undefined;
  finalIntervals?:
    | readonly {
        readonly fromTimestamp: string;
        readonly timer: Timer | null;
        readonly toTimestamp: string;
      }[]
    | undefined;
  finalStartTime?: string | undefined;
  genericPrimaryTimer: Timer;
  headerText?: string | undefined;
  machineOperator?: User | undefined;
  machineOperatorProfile?: UserProfile | undefined;
  machineUseList: {
    machine: Machine | undefined;
    priceGroup: string | null;
    transporter: boolean;
  }[];
  notesFromMachineOperator: string;
  okHeaderText?: string;
  onAction?: ((action: ErrorAction) => void) | undefined;
  onCancel: () => void;
  onlyCompletedChecks?: boolean;
  onRegisterAccommodationChanged?:
    | ((registerAccommodation: boolean, remunerationGroup: string) => void)
    | undefined;
  open: boolean;
  primaryWorkType?: WorkType | undefined;
  registerAccommodation: boolean;
  secondaryTimers: ReadonlySet<Timer>;
  task: Task;
  title: string;
  warnings: readonly ErrorEntry[];
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
}

class TaskCheckDialogContent extends PureComponent<TaskCheckDialogContentProps> {
  render(): JSX.Element {
    const {
      autofocusErrorAction,
      autofocusWarningAction,
      // computedEndTime,
      computedIntervals,
      // computedStartTime,
      customerSettings,
      // date,
      errors,
      finalEndTime,
      finalIntervals,
      finalStartTime,
      genericPrimaryTimer,
      headerText,
      machineOperator,
      machineOperatorProfile,
      machineUseList,
      notesFromMachineOperator,
      onlyCompletedChecks,
      onRegisterAccommodationChanged,
      open,
      primaryWorkType,
      secondaryTimers,
      task,
      warnings,
      workTypeLookup,
    } = this.props;
    const now = new Date();
    // TODO: completedText ?
    const machines = machineUseList.map((instance, index) => {
      const {machine} = instance;
      let machineID = "";
      let machineName = "";
      if (machine) {
        machineID = machine.c5_machine;
        machineName = machine.name;
      }
      const text = machineName + (machineID ? ` (${machineID})` : "");
      return (
        <tr key={index}>
          <td colSpan={3}>{text}</td>
        </tr>
      );
    });
    let workInterval = null;
    if (finalStartTime && finalEndTime) {
      workInterval = Math.round(
        (new Date(finalEndTime).valueOf() - new Date(finalStartTime).valueOf()) /
          MINUTE_MILLISECONDS,
      );
    }
    let warningBlock;
    if (warnings.length) {
      warningBlock = (
        <div style={{color: colorMap.WARNING}}>
          <FormattedMessage
            defaultMessage="Advarsler:"
            id="task-check-dialog.header.warnings"
            tagName="h4"
          />
          <ErrorList
            errors={warnings}
            onAction={autofocusWarningAction ? this.props.onAction : undefined}
          />
        </div>
      );
    }
    let errorBlock;
    if (errors.length) {
      errorBlock = (
        <div style={{color: colorMap.ERROR}}>
          <FormattedMessage
            defaultMessage="Fejl:"
            id="task-check-dialog.header.errors"
            tagName="h4"
          />
          <ErrorList
            errors={errors}
            onAction={autofocusErrorAction ? this.props.onAction : undefined}
          />
        </div>
      );
    }
    const machineOperatorData = machineOperatorProfile
      ? `${machineOperatorProfile.alias} — ${machineOperatorProfile.name}`
      : "";
    let headerBlock;
    if (headerText) {
      headerBlock = <h3>{headerText}</h3>;
    }
    let notesBlock;
    if (notesFromMachineOperator) {
      notesBlock = (
        <div>
          {customerSettings.employeeLabelVariant === "MACHINEOPERATOR" ? (
            <FormattedMessage
              defaultMessage="Noter fra maskinfører:"
              id="task-check-dialog.header.notes-from-machine-operator"
              tagName="h4"
            />
          ) : customerSettings.employeeLabelVariant === "EMPLOYEE" ? (
            <FormattedMessage
              defaultMessage="Noter fra medarbejder:"
              id="task-check-dialog.header.notes-from-employee"
              tagName="h4"
            />
          ) : (
            <FormattedMessage
              defaultMessage="Noter fra chauffør:"
              id="task-check-dialog.header.notes-from-chauffeur"
              tagName="h4"
            />
          )}
          <em>
            <Linkify>{notesFromMachineOperator}</Linkify>
          </em>
        </div>
      );
    }

    const finalStartDate = finalStartTime ? dateToString(new Date(finalStartTime)) : null;

    let registerAccommodationBlock;
    if (customerSettings.enableAccommodationAllowanceRegistrationOnInternalTasks) {
      let canRegisterAccommodation = false;
      let accommodationRemunerationGroup = "";
      if (open && onlyCompletedChecks && finalStartDate && machineOperatorProfile) {
        accommodationRemunerationGroup = getEmployeeTaskRemunerationGroup(
          customerSettings,
          machineOperatorProfile,
          task,
          workTypeLookup,
        );
        canRegisterAccommodation = userCanRegisterAccommodation(
          customerSettings,
          machineOperatorProfile,
        );
      }

      if (
        canRegisterAccommodation &&
        !!onRegisterAccommodationChanged &&
        finalStartDate &&
        machineOperator
      ) {
        registerAccommodationBlock = (
          <div style={{paddingBottom: "1em", paddingTop: "1em"}}>
            <AccommodationAllowanceCheckbox
              date={finalStartDate}
              employeeUrl={machineOperator.url}
              remunerationGroup={accommodationRemunerationGroup || ""}
              onChange={onRegisterAccommodationChanged}
            />
          </div>
        );
      }
    }

    const finalSums = computeIntervalSums(finalIntervals || [], now);
    const minutesTotal = mapSum(finalSums);
    const computedSums = computeIntervalSums(computedIntervals || [], now);

    const genericTimerURL = genericPrimaryTimer.url;

    const primaryComputedMinutes = computedSums.get(genericTimerURL) || 0;
    const primarryEnteredMinutes = finalSums.get(genericTimerURL) || 0;
    let primaryWorkTypeRow;
    if (primaryWorkType) {
      primaryWorkTypeRow = (
        <tr>
          <td>{primaryWorkType.name}</td>
          <td>{formatDuration(customerSettings.durationFormat, primarryEnteredMinutes)}</td>
          <MeasuredIfDifferent entered={primarryEnteredMinutes} measured={primaryComputedMinutes} />
        </tr>
      );
    }
    const secondaryWorkTypeRows = Array.from(secondaryTimers).map((timer) => {
      const timerURL = timer.url;
      const computedMinutes = computedSums.get(timerURL) || 0;
      const enteredMinutes = finalSums.get(timerURL) || 0;
      return (
        <tr key={timerURL}>
          <td>{timer.label}</td>
          <td>{formatDuration(customerSettings.durationFormat, enteredMinutes)}</td>
          <MeasuredIfDifferent entered={enteredMinutes} measured={computedMinutes} />
        </tr>
      );
    });
    return (
      <div>
        {headerBlock}
        {errorBlock}
        {warningBlock}
        {registerAccommodationBlock}
        {notesBlock}
        <table>
          <tbody>
            <tr>
              {customerSettings.employeeLabelVariant === "MACHINEOPERATOR" ? (
                <FormattedMessage
                  defaultMessage="Maskinfører:"
                  id="task-check-dialog.label.machine-operator"
                  tagName="td"
                />
              ) : customerSettings.employeeLabelVariant === "EMPLOYEE" ? (
                <FormattedMessage
                  defaultMessage="Medarbejder:"
                  id="task-check-dialog.label.employee"
                  tagName="td"
                />
              ) : (
                <FormattedMessage
                  defaultMessage="Chauffør:"
                  id="task-check-dialog.label.chauffeur"
                  tagName="td"
                />
              )}
              <td colSpan={2}>{machineOperatorData}</td>
            </tr>
            <tr>
              <td colSpan={3}>
                {customerSettings.machineLabelVariant === "MACHINE" ? (
                  <FormattedMessage
                    defaultMessage="Maskiner:"
                    id="task-check-dialog.label.machines"
                    tagName="h4"
                  />
                ) : (
                  <FormattedMessage
                    defaultMessage="Køretøjer:"
                    id="task-check-dialog.label.vehicles"
                    tagName="h4"
                  />
                )}
              </td>
            </tr>
            {machines}
            <tr>
              <td colSpan={3}>
                <FormattedMessage
                  defaultMessage="Registreret tid (timer:minutter):"
                  id="task-check-dialog.label.time-format"
                  tagName="h4"
                />
              </td>
            </tr>
            {primaryWorkTypeRow}
            {secondaryWorkTypeRows}
            <tr>
              <td>
                <strong>
                  <FormattedMessage
                    defaultMessage="Total registreret tid:"
                    id="task-check-dialog.label.total-registered-time"
                  />
                </strong>
              </td>
              <td>
                <strong>{formatDuration(customerSettings.durationFormat, minutesTotal)}</strong>
              </td>
              <td />
            </tr>
            <tr>
              <td colSpan={3}>
                <FormattedMessage
                  defaultMessage="Arbejdstid:"
                  id="task-check-dialog.label.worktime"
                />
              </td>
            </tr>
            <tr>
              <td>
                {finalStartTime ? formatTime(new Date(finalStartTime)) : null}–
                {finalEndTime ? formatTime(new Date(finalEndTime)) : null}
              </td>
              <td>{formatDuration(customerSettings.durationFormat, workInterval)}</td>
              <td />
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

interface BaseTaskCheckDialogStateProps {
  accomodationAllowanceArray: readonly AccomodationAllowance[];
  customerSettings: Config;
  taskPhotoArray: readonly TaskPhoto[];
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
}

interface BaseTaskCheckDialogOwnProps {
  computedEndTime?: string | undefined;
  computedIntervals?: readonly ComputedTime[] | undefined;
  computedStartTime?: string | undefined;
  date?: string | undefined;
  department?: string;
  errorHeaderText?: string | undefined;
  finalEndTime?: string | undefined;
  finalIntervals?:
    | readonly {
        readonly fromTimestamp: string;
        readonly timer: Timer | null;
        readonly toTimestamp: string;
      }[]
    | undefined;
  finalStartTime?: string | undefined;
  genericPrimaryTimer: Timer;
  headerText?: string | undefined;
  machineOperator?: User | undefined;
  machineOperatorProfile?: UserProfile | undefined;
  machineUseList: {
    machine: Machine | undefined;
    priceGroup: string | null;
    transporter: boolean;
  }[];
  notesFromMachineOperator: string;
  okHeaderText?: string | undefined;
  onAction?: ((action: ErrorAction) => void) | undefined;
  onCancel: () => void;
  onlyCompletedChecks?: boolean;
  onOk: (registerAccommodationGroup: string | null) => void;
  open: boolean;
  primaryWorkType?: WorkType | undefined;
  secondaryTimers: ReadonlySet<Timer>;
  task: Task;
  title: string;
  workshopChecklistAnswers: readonly WorkshopChecklistAnswer[];
  workshopChecklistItemArray?: readonly WorkshopChecklistItem[] | undefined;
  workshopChecklists?: readonly WorkshopChecklist[] | undefined;
}

type BaseTaskCheckDialogProps = BaseTaskCheckDialogOwnProps & BaseTaskCheckDialogStateProps;

interface BaseTaskCheckDialogState {
  registerAccommodationGroup: string | null;
}

class BaseTaskCheckDialog extends PureComponent<
  BaseTaskCheckDialogProps,
  BaseTaskCheckDialogState
> {
  state: BaseTaskCheckDialogState = {
    registerAccommodationGroup: null,
  };
  static defaultProps = {
    onlyCompletedChecks: false,
  };
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  @bind
  handleOk(): void {
    const {onOk} = this.props;
    if (onOk) {
      onOk(this.state.registerAccommodationGroup);
    }
  }

  @bind
  handleRegisterAccommodationChanged(
    registerAccommodation: boolean,
    remunerationGroup: string,
  ): void {
    if (registerAccommodation) {
      this.setState({registerAccommodationGroup: remunerationGroup});
    } else {
      this.setState({registerAccommodationGroup: null});
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getWarnings(_now: Date): readonly ErrorEntry[] {
    //const {formatMessage} = this.context;
    return [];
  }

  getErrors(now: Date): readonly ErrorEntry[] {
    const {
      customerSettings,
      department,
      finalIntervals,
      machineOperator,
      machineUseList,
      onlyCompletedChecks,
      primaryWorkType,
      task,
      taskPhotoArray,
      workshopChecklistAnswers,
      workshopChecklistItemArray,
      workshopChecklists,
      workTypeLookup,
    } = this.props;
    return getInternalTaskErrors(
      {
        customerSettings,
        department,
        finalIntervals,
        machineOperator,
        machineUseList,
        onlyCompletedChecks,
        primaryWorkType,
        task,
        taskPhotoArray,
        workshopChecklistAnswers,
        workshopChecklistItemArray,
        workshopChecklists,
        workTypeLookup,
      },
      now,
      this.context,
    );
  }

  render(): JSX.Element {
    const {errorHeaderText, headerText, okHeaderText, open, ...others} = this.props;
    let warnings: readonly ErrorEntry[] = [];
    let errors: readonly ErrorEntry[] = [];
    let autofocusWarningAction = false;
    let autofocusErrorAction = false;
    const now = new Date();
    if (open) {
      warnings = this.getWarnings(now);
      errors = this.getErrors(now);
      autofocusWarningAction = warnings.some(isErrorEntryObjectWithAction);
      autofocusErrorAction = !autofocusWarningAction && errors.some(isErrorEntryObjectWithAction);
    }
    const specificHeaderText = !errors.length ? okHeaderText : errorHeaderText;
    const realHeaderText = specificHeaderText || headerText;
    const dialogContent = (
      <TaskCheckDialogContent
        autofocusErrorAction={autofocusErrorAction}
        autofocusWarningAction={autofocusWarningAction}
        errors={errors}
        headerText={realHeaderText}
        open={open}
        registerAccommodation={!!this.state.registerAccommodationGroup}
        warnings={warnings}
        onRegisterAccommodationChanged={this.handleRegisterAccommodationChanged}
        {...others}
      />
    );

    const dialogTitle = this.props.title;
    return (
      <ResponsiveDialog
        autoFocusOk={!autofocusWarningAction && !autofocusErrorAction}
        okDisabled={!!errors.length}
        open={open}
        title={dialogTitle}
        onCancel={this.props.onCancel}
        onOk={this.handleOk}
      >
        <DialogContent>{dialogContent}</DialogContent>
      </ResponsiveDialog>
    );
  }
}

const ConnectedBaseTaskCheckDialog: React.ComponentType<BaseTaskCheckDialogOwnProps> = connect<
  BaseTaskCheckDialogStateProps,
  object,
  BaseTaskCheckDialogOwnProps,
  AppState
>(
  createStructuredSelector<AppState, BaseTaskCheckDialogStateProps>({
    accomodationAllowanceArray: getAccomodationAllowanceArray,
    customerSettings: getCustomerSettings,
    taskPhotoArray: getTaskPhotoArray,
    workTypeLookup: getWorkTypeLookup,
  }),
  {},
)(BaseTaskCheckDialog);

export {ConnectedBaseTaskCheckDialog as BaseTaskCheckDialog};

interface InternalTaskCheckDialogProps {
  computedEndTime?: string | undefined;
  computedIntervals?: readonly ComputedTime[];
  computedStartTime?: string | undefined;
  errorHeaderText?: string;
  finalEndTime?: string | undefined;
  finalIntervals?: readonly {
    readonly fromTimestamp: string;
    readonly timer: Timer | null;
    readonly toTimestamp: string;
  }[];
  finalStartTime?: string | undefined;
  genericPrimaryTimer: Timer;
  headerText?: string;
  okHeaderText?: string;
  onAction?: (action: ErrorAction) => void;
  onCancel: () => void;
  onlyCompletedChecks?: boolean;
  onOk: (registerAccommodationGroup: string | null) => void;
  open: boolean;
  primaryWorkType?: WorkType | undefined;
  secondaryTimers: ReadonlySet<Timer>;
  task: Task;
  title: string;
  workshopChecklistItemArray?: readonly WorkshopChecklistItem[];
  workshopChecklists?: readonly WorkshopChecklist[];
}

export const InternalTaskCheckDialog = React.memo(function InternalTaskCheckDialog(
  props: InternalTaskCheckDialogProps,
) {
  // computedMinutes ...
  const {
    computedEndTime,
    computedIntervals,
    computedStartTime,
    errorHeaderText,
    finalEndTime,
    finalIntervals,
    finalStartTime,
    genericPrimaryTimer,
    headerText,
    okHeaderText,
    onAction,
    onCancel,
    onlyCompletedChecks = false,
    onOk,
    open,
    primaryWorkType,
    secondaryTimers,
    task,
    title,
    workshopChecklistItemArray,
    workshopChecklists,
  } = props;
  const date = (task && task.date) || undefined;
  const machineOperatorURL = task.machineOperator;

  const [registerAccommodationGroup, setRegisterAccommodationGroup] = useState<string | null>(null);
  const userLookup = useSelector(getUserLookup);
  const userUserProfileLookup = useSelector(getUserUserProfileLookup);
  const machineLookup = useSelector(getMachineLookup);

  const machineOperator = (machineOperatorURL && userLookup(machineOperatorURL)) || undefined;

  const machineOperatorProfile = machineOperatorURL
    ? userUserProfileLookup(machineOperatorURL)
    : undefined;

  const machineUseList = (task.machineuseSet || []).map((entry) => {
    const machineURL = entry.machine;
    const machine = machineLookup(machineURL);
    return {...entry, machine};
  });
  const notesFromMachineOperator = task.notesFromMachineOperator || "";
  const workshopChecklistAnswers = task.workshopchecklistanswerSet || [];

  const [sendTask, setSendTask] = useResettingState(false, open);

  const handleTaskCheckOk = useCallback(
    (registerAccommodationGroupFromDialog: string | null) => {
      setRegisterAccommodationGroup(registerAccommodationGroupFromDialog);
      if (task.completed) {
        setSendTask(true);
      } else {
        onOk(registerAccommodationGroup);
      }
    },
    [onOk, registerAccommodationGroup, setSendTask, task.completed],
  );

  const handleSendTaskSuccess = useCallback(() => {
    onOk(registerAccommodationGroup);
  }, [onOk, registerAccommodationGroup]);

  return (
    <>
      <SendTask
        execute={sendTask}
        tasks={useMemo(() => [task], [task])}
        onErrorDialogDismiss={onCancel}
        onSuccess={handleSendTaskSuccess}
      />
      <ConnectedBaseTaskCheckDialog
        computedEndTime={computedEndTime}
        computedIntervals={computedIntervals}
        computedStartTime={computedStartTime}
        date={date}
        department={task.department}
        errorHeaderText={errorHeaderText}
        finalEndTime={finalEndTime}
        finalIntervals={finalIntervals}
        finalStartTime={finalStartTime}
        genericPrimaryTimer={genericPrimaryTimer}
        headerText={headerText}
        machineOperator={machineOperator}
        machineOperatorProfile={machineOperatorProfile}
        machineUseList={machineUseList}
        notesFromMachineOperator={notesFromMachineOperator}
        okHeaderText={okHeaderText}
        onlyCompletedChecks={onlyCompletedChecks}
        open={open}
        primaryWorkType={primaryWorkType}
        secondaryTimers={secondaryTimers}
        task={task}
        title={title}
        workshopChecklistAnswers={workshopChecklistAnswers}
        workshopChecklistItemArray={workshopChecklistItemArray}
        workshopChecklists={workshopChecklists}
        onAction={onAction}
        onCancel={onCancel}
        onOk={handleTaskCheckOk}
      />
    </>
  );
});
