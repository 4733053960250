import {SECOND_MILLISECONDS} from "@co-common-libs/utils";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {getCheckingForUpdates, getUpdateReady, getUpdateRequired} from "@co-frontend-libs/redux";
import {DialogContent, LinearProgress} from "@material-ui/core";
import {performUpdateCheck, updateAutoReloadCallback} from "app-utils";
import React, {useEffect, useReducer} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useSelector} from "react-redux";

const RESTART_TIMEOUT_SECONDS = 120;

export const UpdateRequiredPopup = React.memo(function UpdateRequiredPopup(): JSX.Element {
  const updateRequired = useSelector(getUpdateRequired);
  const checkingForUpdates = useSelector(getCheckingForUpdates);
  const updateReady = useSelector(getUpdateReady);

  const failed = updateRequired && !updateReady && !checkingForUpdates;

  const [secondsRemaining, decrementSecondsRemaining] = useReducer(
    (n: number) => (n > 0 ? n - 1 : 0),
    RESTART_TIMEOUT_SECONDS,
  );

  const timeoutDone = secondsRemaining === 0;

  let content: JSX.Element | undefined;

  useEffect(() => {
    if (updateReady) {
      setInterval(decrementSecondsRemaining, SECOND_MILLISECONDS);
    }
  }, [updateReady]);

  useEffect(() => {
    if (timeoutDone) {
      updateAutoReloadCallback();
    }
  }, [timeoutDone]);

  if (updateReady) {
    content = <FormattedMessage defaultMessage="Påkrævet opdatering klar" />;
  } else if (checkingForUpdates) {
    content = (
      <>
        <FormattedMessage defaultMessage="Henter påkrævet opdatering" />
        <br />
        <LinearProgress />
      </>
    );
  } else if (failed) {
    content = <FormattedMessage defaultMessage="Påkrævet opdatering ikke hentet" />;
  }

  const intl = useIntl();

  return (
    <ResponsiveDialog
      cancelDisabled={!failed}
      cancelLabel={intl.formatMessage({defaultMessage: "Forsøg igen"})}
      okDisabled={!updateReady}
      okLabel={intl.formatMessage(
        {defaultMessage: "Genstart ({timeout})"},
        {timeout: secondsRemaining},
      )}
      open={updateRequired}
      title={intl.formatMessage({defaultMessage: "Opdatering påkrævet"})}
      onCancel={performUpdateCheck}
      onOk={updateAutoReloadCallback}
    >
      <DialogContent>{content}</DialogContent>
    </ResponsiveDialog>
  );
});
