import {Config, PoolSpecification} from "@co-common-libs/config";
import {
  BonusSpecification,
  DayHoursRatesSpecification,
  SwitchGroupSpecification,
  WeekDayHoursRatesSpecification,
  WeekThresholdsSpecification,
  buildBonusSpecifications,
  getRemunerationGroupHolidayCalendars,
} from "@co-common-libs/payroll";
import {
  Machine,
  MachineUrl,
  PriceGroup,
  PriceGroupUrl,
  Task,
  User,
  UserProfile,
  UserUrl,
  WorkType,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {HolidayCalendarLabel} from "@co-common-libs/utils";
import {IntlShape, defineMessages} from "react-intl";

const messages = defineMessages({
  employeeSpecificReport: {
    defaultMessage: "Medarb.: {employees}",
    id: "utils-remuneration.label.employee-specific-report",
  },
  standardReport: {
    defaultMessage: "Standard",
    id: "utils-remuneration.label.standard-report",
  },
});

export function getRemunerationGroupLabels(
  customerSettings: Config,
  formatMessage: IntlShape["formatMessage"],
  userProfileArray: readonly UserProfile[],
  userLookup: (url: UserUrl) => User | undefined,
): ReadonlyMap<string, string> {
  const result = new Map<string, string>();
  Object.entries(customerSettings.remunerationGroups).forEach(([groupID, options]) => {
    if ((options as any).reportTitle) {
      result.set(groupID, (options as any).reportTitle);
    }
  });
  result.set(
    customerSettings.remunerationDefaultGroup,
    customerSettings.remunerationGroups[customerSettings.remunerationDefaultGroup].reportTitle,
  );
  const employeesPerGroup = new Map<string, Set<string>>();
  userProfileArray.forEach((userProfile) => {
    const user = userLookup(userProfile.user);
    if (!user?.active) {
      return;
    }
    const {alias, remunerationGroup} = userProfile;
    const existing = employeesPerGroup.get(remunerationGroup);
    if (existing) {
      existing.add(alias);
    } else {
      employeesPerGroup.set(remunerationGroup, new Set([alias]));
    }
  });
  employeesPerGroup.forEach((employees, groupID) => {
    if (!result.has(groupID)) {
      result.set(
        groupID,
        formatMessage(messages.employeeSpecificReport, {
          employees: employees.size ? Array.from(employees).sort().join(", ") : "",
        }),
      );
    }
  });
  return result;
}

export const getEmployeeRemunerationGroup = (
  customerSettings: Config,
  userProfile: UserProfile | null | undefined,
): string => {
  return userProfile?.remunerationGroup || customerSettings.remunerationDefaultGroup;
};

export function getEmployeeHolidayCalendars(
  customerSettings: Config,
  userProfile: UserProfile | null | undefined,
): readonly HolidayCalendarLabel[] {
  const remunerationGroupId = getEmployeeRemunerationGroup(customerSettings, userProfile);

  const remunerationGroup = customerSettings.remunerationGroups[remunerationGroupId];

  return getRemunerationGroupHolidayCalendars(remunerationGroup);
}

export const getEmployeeRemunerationSettings = (
  customerSettings: Config,
  workTypeArray: readonly WorkType[],
  machineArray: readonly Machine[],
  priceGroupArray: readonly PriceGroup[],
  userProfile: UserProfile | null | undefined,
  overrideGroupID?: string,
): {
  accumulateCompensatoryLimit: number | null;
  dayEndRounding: readonly [string, string] | null;
  dayStartRounding: readonly [string, string] | null;
  extraHalfHolidays: {
    [k: string]: string;
  } | null;
  extraHolidays: {
    [k: string]: string;
  } | null;
  halfHolidayHalfThresholds: boolean;
  halfHolidayHoursRates: DayHoursRatesSpecification | null;
  halfHolidaySundayAfterNoon: boolean;
  hoursRates: WeekDayHoursRatesSpecification | null;
  intervalBonus: readonly BonusSpecification[];
  overtimeThresholds: WeekThresholdsSpecification | null;
  overtimeThresholdsReset: boolean;
  paidBreaks: boolean;
  periodSplitThresholdMinutes: number;
  pools: PoolSpecification | null;
  specialStartRateMinutes: number | null;
  unregisteredBreakAfterMinutes: number;
  visibleBonusLabels: readonly string[] | null;
  visibleLabels: readonly string[] | null;
  visibleRateLabels: readonly string[] | null;
  workDayBonus: readonly BonusSpecification[];
  workDaySplitThresholdMinutes: number | null;
} => {
  const groupID = overrideGroupID || getEmployeeRemunerationGroup(customerSettings, userProfile);
  const groupSettings = customerSettings.remunerationGroups[groupID] || {};

  const {periodSplitThresholdMinutes, unregisteredBreakAfterMinutes, workDaySplitThresholdMinutes} =
    customerSettings;
  const intervalBonus = groupSettings.intervalBonus || [];
  const workDayBonus = groupSettings.workDayBonus || [];
  const {hoursRates, overtimeThresholds, pools, specialStartRateMinutes} = groupSettings;
  const overtimeThresholdsReset =
    groupSettings.overtimeThresholdsReset != null ? groupSettings.overtimeThresholdsReset : false;
  const {
    accumulateCompensatoryLimit,
    extraHalfHolidays,
    extraHolidays,
    halfHolidayHalfThresholds,
    halfHolidayHoursRates,
    halfHolidaySundayAfterNoon,
    visibleBonusLabels,
    visibleLabels,
    visibleRateLabels,
  } = groupSettings;
  const dayStartRounding =
    groupSettings.dayStartRounding !== undefined
      ? groupSettings.dayStartRounding
      : customerSettings.dayStartRounding;
  const dayEndRounding =
    groupSettings.dayEndRounding !== undefined
      ? groupSettings.dayEndRounding
      : customerSettings.dayEndRounding;

  const workTypeIDURLMapping: {
    [workTypeID: string]: WorkTypeUrl;
  } = {};
  workTypeArray.forEach((workType) => {
    workTypeIDURLMapping[workType.identifier] = workType.url;
  });
  const machineIDURLMapping: {[machineID: string]: MachineUrl} = {};
  machineArray.forEach((machine) => {
    machineIDURLMapping[machine.c5_machine] = machine.url;
  });
  const priceGroupIDURLMapping: {[priceGroupID: string]: PriceGroupUrl} = {};
  priceGroupArray.forEach((priceGroup) => {
    priceGroupIDURLMapping[priceGroup.identifier] = priceGroup.url;
  });

  return {
    accumulateCompensatoryLimit: accumulateCompensatoryLimit ?? null,
    dayEndRounding,
    dayStartRounding,
    extraHalfHolidays: extraHalfHolidays ?? null,
    extraHolidays: extraHolidays ?? null,
    halfHolidayHalfThresholds: !!halfHolidayHalfThresholds,
    halfHolidayHoursRates: halfHolidayHoursRates ?? null,
    halfHolidaySundayAfterNoon: !!halfHolidaySundayAfterNoon,
    hoursRates: hoursRates ?? null,
    intervalBonus: buildBonusSpecifications(
      intervalBonus,
      workTypeIDURLMapping,
      machineIDURLMapping,
      priceGroupIDURLMapping,
    ),
    overtimeThresholds: overtimeThresholds ?? null,
    overtimeThresholdsReset,
    paidBreaks: groupSettings.paidBreaks || false,
    periodSplitThresholdMinutes,
    pools: pools && Object.keys(pools).length ? (pools as PoolSpecification) : null,
    specialStartRateMinutes: specialStartRateMinutes ?? null,
    unregisteredBreakAfterMinutes,
    visibleBonusLabels: visibleBonusLabels ?? null,
    visibleLabels: visibleLabels ?? null,
    visibleRateLabels: visibleRateLabels ?? null,
    workDayBonus: buildBonusSpecifications(
      workDayBonus,
      workTypeIDURLMapping,
      machineIDURLMapping,
      priceGroupIDURLMapping,
    ),
    workDaySplitThresholdMinutes,
  };
};

export function getEmployeeTaskRemunerationGroup(
  customerSettings: Config,
  userProfile: UserProfile | null | undefined,
  task: Task,
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined,
): string {
  const defaultRemunerationGroupID = getEmployeeRemunerationGroup(customerSettings, userProfile);
  const remunerationGroup = customerSettings.remunerationGroups[defaultRemunerationGroupID];
  if (!remunerationGroup?.switchGroups) {
    return defaultRemunerationGroupID;
  }
  const taskDepartmentID = task.department || undefined;
  const taskWorkTypeID = task.workType ? workTypeLookup(task.workType)?.identifier : undefined;
  const {switchGroups} = remunerationGroup;
  for (let i = 0; i < switchGroups.length; i += 1) {
    const switchGroupSpecification = switchGroups[i];
    const departmentID =
      switchGroupSpecification.departmentID ?? switchGroupSpecification.departmentId;
    const workTypeID = switchGroupSpecification.workTypeID ?? switchGroupSpecification.workTypeId;
    const {identifier} = switchGroupSpecification;
    if (
      (departmentID === undefined || departmentID === taskDepartmentID) &&
      (workTypeID === undefined || workTypeID === taskWorkTypeID)
    ) {
      return identifier;
    }
  }
  return defaultRemunerationGroupID;
}

export function userCanRegisterAccommodation(
  customerSettings: Config,
  userProfile: UserProfile | null | undefined,
): boolean {
  const defaultRemunerationGroupID = getEmployeeRemunerationGroup(customerSettings, userProfile);
  const defaultRemunerationGroup = customerSettings.remunerationGroups[defaultRemunerationGroupID];
  if (!defaultRemunerationGroup) {
    return false;
  }
  if (defaultRemunerationGroup.accomodationAllowance) {
    return true;
  }
  if (defaultRemunerationGroup.switchGroups) {
    return defaultRemunerationGroup.switchGroups.some((x: SwitchGroupSpecification) => {
      const {identifier} = x;
      const remunerationGroup = customerSettings.remunerationGroups[identifier];
      return !!remunerationGroup.accomodationAllowance;
    });
  }
  return false;
}
