import {
  Command,
  Contact,
  ContactUrl,
  Order,
  OrderUrl,
  Task,
  TaskUrl,
  TimerStart,
  TimerStartUrl,
  resourceNameFor,
} from "@co-common-libs/resources";
import {ProvisionaryCommand} from "../../resources/actions";
import {
  getContactLookup,
  getOrderLookup,
  getTaskLookup,
  getTimerStartLookup,
} from "../../resources/selectors";
import {ResourcesAuthenticationMiddlewareAPI} from "../types";
import {getInstances} from "../utils";
import {addLegacyLogLocationsFromFields} from "./add-legacy-log-locations-from-fields";
import {createLegacyLogs} from "./create-legacy-logs";
import {makeFirstCustomerContactDefault} from "./make-first-customer-contact-default";
import {onlyOneDefaultContactPerCustomer} from "./only-one-default-contact-per-customer";
import {updateMachineLastUsedBy} from "./update-machine-last-used-by";
import {updateTaskPriceGroupsOnOrderCustomerChange} from "./update-task-price-groups-on-order-customer-change";
import {updateTaskPriceItemUsesFromTimerPriceGroups} from "./update-task-price-item-uses-from-timer-price-groups";
import {writeProductUseLog} from "./write-product-use-log";
import {writeLocationUseLog} from "./write-to-location-use-log";
import {writeToMachineUseLog} from "./write-to-machine-use-log";

function combineResults(
  ...results: ({after?: Command[]; before?: Command[]} | null)[]
): {after?: Command[]; before?: Command[]} | null {
  const before: Command[] = [];
  const after: Command[] = [];
  results.forEach((result) => {
    if (!result) {
      return;
    }
    if (result.before) {
      before.push(...result.before);
    }
    if (result.after) {
      after.push(...result.after);
    }
  });
  if (before.length && after.length) {
    return {after, before};
  } else if (before.length) {
    return {before};
  } else if (after.length) {
    return {after};
  } else {
    return null;
  }
}

export function saveExtraCommands(
  middlewareApi: ResourcesAuthenticationMiddlewareAPI,
  command: ProvisionaryCommand,
): {after?: Command[]; before?: Command[]} | null {
  // Location storage changes are now calculated in backend. We are leaving some
  // dead code in frontend to be used later when we rewrite the backend in TypeScript.
  const {url} = command;
  const resourceName = resourceNameFor(url);
  if (resourceName === "task") {
    const state = middlewareApi.getState();
    const taskLookup = getTaskLookup(state);
    const {newInstance: newTask, oldInstance: oldTask} = getInstances<Task, TaskUrl>(
      taskLookup,
      command,
    );
    return combineResults(
      writeToMachineUseLog(newTask, oldTask, middlewareApi, command),
      writeProductUseLog(newTask, oldTask, middlewareApi, command),
      createLegacyLogs(newTask, oldTask, middlewareApi, command),
      addLegacyLogLocationsFromFields(newTask, oldTask, middlewareApi, command),
      writeLocationUseLog(newTask, oldTask, middlewareApi, command),
      updateMachineLastUsedBy(newTask, oldTask, middlewareApi, command),
    );
  } else if (resourceName === "order") {
    const state = middlewareApi.getState();
    const orderLookup = getOrderLookup(state);
    const {newInstance: newOrder, oldInstance: oldOrder} = getInstances<Order, OrderUrl>(
      orderLookup,
      command,
    );
    return updateTaskPriceGroupsOnOrderCustomerChange(newOrder, oldOrder, middlewareApi, command);
  } else if (resourceName === "timerStart") {
    const state = middlewareApi.getState();
    const timerStartLookup = getTimerStartLookup(state);
    const {newInstance: newTimerStart, oldInstance: oldTimerStart} = getInstances<
      TimerStart,
      TimerStartUrl
    >(timerStartLookup, command);
    return updateTaskPriceItemUsesFromTimerPriceGroups(
      newTimerStart,
      oldTimerStart,
      middlewareApi,
      command,
    );
  } else if (resourceName === "contact") {
    const state = middlewareApi.getState();
    const contactLookup = getContactLookup(state);
    const {newInstance: newContact, oldInstance: oldContact} = getInstances<Contact, ContactUrl>(
      contactLookup,
      command,
    );
    return combineResults(
      makeFirstCustomerContactDefault(newContact, oldContact, middlewareApi, command),
      onlyOneDefaultContactPerCustomer(newContact, oldContact, middlewareApi, command),
    );
  }
  return null;
}
