import {PricePercentFuelSurchargeInvoiceLineTextChoice} from "@co-common-libs/resources";
import {IntlShape} from "@formatjs/intl";

// let TypeScript validate that we handle/return strings for all cases
// eslint-disable-next-line consistent-return
export function formatPricePercentString(
  intl: Pick<IntlShape, "formatMessage">,
  variant: PricePercentFuelSurchargeInvoiceLineTextChoice,
  name: string,
  indexPeriodStart: Date,
  indexPeriodEnd: Date,
  resultingPercent: number,
  price: number,
): string {
  switch (variant) {
    case "product_text":
      return intl.formatMessage(
        {
          defaultMessage: "{name} {resultingPercent, number, ::.00}%",
        },
        {
          name,
          resultingPercent,
        },
      );
    case "product_text_and_surcharge_details":
      return intl.formatMessage(
        {
          defaultMessage:
            "{name} {resultingPercent, number, ::.00}% ({indexPeriodStart, date, ::MMdd}-{indexPeriodEnd, date, ::MMdd}, {price, number} kr/L)",
        },
        {
          indexPeriodEnd,
          indexPeriodStart,
          name,
          price,
          resultingPercent,
        },
      );
    case "product_percent_period":
      return intl.formatMessage(
        {
          defaultMessage:
            "{name} {resultingPercent, number, ::.00}% ({indexPeriodStart, date, ::MMdd}-{indexPeriodEnd, date, ::MMdd})",
        },
        {
          indexPeriodEnd,
          indexPeriodStart,
          name,
          resultingPercent,
        },
      );
  }
}
