import {formatDateNumeric, locale} from "@co-common-libs/utils";
import _ from "lodash";
import React, {CSSProperties} from "react";
import {FormattedMessage} from "react-intl";

type SerializedRow = {
  readonly customerId: string | null;
  readonly customerName: string;
  readonly deliveredAmount: number;
  readonly deliveredLoads: number;
  readonly locationId: string;
  readonly locationName: string;
  readonly pickedUpAmount: number;
  readonly pickedUpLoads: number;
  readonly productName: string;
  readonly unit: string;
};

interface TransportReportTableProps {
  data: readonly SerializedRow[];
  showCustomerColumn: boolean;
}

function TransportReportTable(props: TransportReportTableProps): JSX.Element {
  const {data, showCustomerColumn} = props;

  const sortedData = _.sortBy(data, [
    (entry) => (showCustomerColumn ? entry.customerName : null),
    (entry) => (showCustomerColumn ? entry.customerId : null),
    (entry) => entry.locationName,
    (entry) => entry.locationId,
    (entry) => entry.productName,
    (entry) => entry.unit,
  ]);

  const sums = new Map<
    string,
    {
      deliveredAmount: number;
      deliveredLoads: number;
      pickedUpAmount: number;
      pickedUpLoads: number;
      productName: string;
      unit: string;
    }
  >();

  let previousCustomerId: string | null = null;
  let previousLocationId: string | null = null;

  const rows: JSX.Element[] = [];

  sortedData.forEach(
    ({
      customerId,
      customerName,
      deliveredAmount,
      deliveredLoads,
      locationId,
      locationName,
      pickedUpAmount,
      pickedUpLoads,
      productName,
      unit,
    }) => {
      const sumKey = `${productName.toLowerCase()}---${unit.toLowerCase()}`;
      const sumEntry = sums.get(sumKey);
      if (sumEntry) {
        sumEntry.deliveredAmount += deliveredAmount;
        sumEntry.deliveredLoads += deliveredLoads;
        sumEntry.pickedUpAmount += pickedUpAmount;
        sumEntry.pickedUpLoads += pickedUpLoads;
      } else {
        sums.set(sumKey, {
          deliveredAmount,
          deliveredLoads,
          pickedUpAmount,
          pickedUpLoads,
          productName,
          unit,
        });
      }
      const showCustomerText = customerId !== previousCustomerId;
      const showLocationText = showCustomerText || locationId !== previousLocationId;
      const customerChangeBorder: CSSProperties = showCustomerText
        ? {borderTop: "1px solid black"}
        : {};
      const locationChangeBorder: CSSProperties = showLocationText
        ? {borderTop: "1px solid black"}
        : {};
      rows.push(
        <tr
          key={`${customerId}-${customerName}-${locationId}-${locationName}-${productName}-${unit}`}
        >
          {showCustomerColumn ? (
            <td style={customerChangeBorder}>{showCustomerText ? customerName : null}</td>
          ) : null}
          <td style={{...locationChangeBorder, whiteSpace: "pre-wrap"}}>
            {showLocationText ? locationName : null}
          </td>
          <td style={{...locationChangeBorder}}>{productName}</td>
          <td style={{...locationChangeBorder, textAlign: "right"}}>
            {pickedUpAmount.toLocaleString(locale)}
          </td>
          <td style={{...locationChangeBorder}}>{unit}</td>
          <td style={{...locationChangeBorder, textAlign: "right"}}>
            {pickedUpLoads.toLocaleString(locale)}
          </td>
          <td style={{...locationChangeBorder, textAlign: "right"}}>
            {deliveredAmount.toLocaleString(locale)}
          </td>
          <td style={{...locationChangeBorder}}>{unit}</td>
          <td style={{...locationChangeBorder, textAlign: "right"}}>
            {deliveredLoads.toLocaleString(locale)}
          </td>
        </tr>,
      );
      previousCustomerId = customerId;
      previousLocationId = locationId;
    },
  );

  let showTotalText = true;
  const sumRows = _.sortBy(Array.from(sums.values()), [
    (entry) => entry.productName,
    (entry) => entry.unit,
  ]).map(({deliveredAmount, deliveredLoads, pickedUpAmount, pickedUpLoads, productName, unit}) => {
    const row = (
      <tr key={`${productName}-${unit}`} style={{fontWeight: "bold"}}>
        {showCustomerColumn ? <td /> : null}
        <td>
          {showTotalText ? (
            <FormattedMessage defaultMessage="Total" id="transport-report.label.total" />
          ) : null}
        </td>
        <td>{productName}</td>
        <td style={{textAlign: "right"}}>{pickedUpAmount.toLocaleString(locale)}</td>
        <td>{unit}</td>
        <td style={{textAlign: "right"}}>{pickedUpLoads.toLocaleString(locale)}</td>
        <td style={{textAlign: "right"}}>{deliveredAmount.toLocaleString(locale)}</td>
        <td>{unit}</td>
        <td style={{textAlign: "right"}}>{deliveredLoads.toLocaleString(locale)}</td>
      </tr>
    );
    showTotalText = false;
    return row;
  });

  const totalColumns = 9;

  const visiblecolumns = showCustomerColumn ? totalColumns : totalColumns - 1;

  return (
    <table style={{borderCollapse: "collapse", width: "100%"}}>
      <thead>
        <tr>
          {showCustomerColumn ? (
            <th style={{textAlign: "left"}}>
              <FormattedMessage
                defaultMessage="Afhentnings-/leveringskunde"
                id="transport-report.table-header.location-customer"
              />
            </th>
          ) : null}
          <th style={{textAlign: "left"}}>
            <FormattedMessage
              defaultMessage="Afhentnings-/leveringssted"
              id="transport-report.table-header.location"
            />
          </th>
          <th style={{textAlign: "left"}}>
            <FormattedMessage defaultMessage="Vare" id="transport-report.table-header.product" />
          </th>
          <th colSpan={2}>
            <FormattedMessage
              defaultMessage="Afhentet"
              id="transport-report.table-header.picked-up"
            />
          </th>
          <th style={{textAlign: "right"}}>
            <FormattedMessage
              defaultMessage="Læs, afh."
              id="transport-report.table-header.pick-up-count"
            />
          </th>
          <th colSpan={2}>
            <FormattedMessage
              defaultMessage="Leveret"
              id="transport-report.table-header.delivered"
            />
          </th>
          <th style={{textAlign: "right"}}>
            <FormattedMessage
              defaultMessage="Læs, lev."
              id="transport-report.table-header.delivered-count"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        {rows}
        <tr>
          <td
            colSpan={visiblecolumns}
            style={{
              borderBottom: "3px double black",
              borderTop: "1px solid black",
            }}
          >
            &nbsp;
          </td>
        </tr>
        {sumRows}
      </tbody>
    </table>
  );
}

interface TransportReportProps {
  data: readonly SerializedRow[];
  fromDate: string;
  invoiceCustomerNames: readonly string[];
  locationCustomerNames: readonly string[];
  machineWithPriceGroupLabels: readonly string[];
  showCustomerColumn: boolean;
  showFiltering: boolean;
  toDate: string;
  workTypeWithPriceGroupLabels?: readonly string[] | undefined;
}

export function TransportReport(props: TransportReportProps): JSX.Element {
  const {
    data,
    fromDate,
    invoiceCustomerNames,
    locationCustomerNames,
    machineWithPriceGroupLabels,
    showCustomerColumn,
    showFiltering,
    toDate,
    workTypeWithPriceGroupLabels,
  } = props;

  return (
    <div
      style={{
        fontFamily: "Linux Biolinum O, sans-serif",
        fontSize: "11pt",
        width: "100%",
      }}
    >
      <div>
        <FormattedMessage
          defaultMessage="Transporteret i perioden: {fromDate} - {toDate}"
          id="transport-report.title.transported-in-period"
          values={{
            fromDate: formatDateNumeric(fromDate),
            toDate: formatDateNumeric(toDate),
          }}
        />
      </div>
      {showFiltering ? (
        <>
          {workTypeWithPriceGroupLabels ? (
            <div>
              {workTypeWithPriceGroupLabels.length ? (
                <FormattedMessage
                  defaultMessage="Områder: {workTypes}"
                  id="transport-report.text.work-types"
                  values={{workTypes: workTypeWithPriceGroupLabels.join(", ")}}
                />
              ) : (
                <FormattedMessage
                  defaultMessage="Områder: Alle"
                  id="transport-report.text.all-work-types"
                />
              )}
            </div>
          ) : null}
          <div>
            {machineWithPriceGroupLabels.length ? (
              <FormattedMessage
                defaultMessage="Maskiner: {machines}"
                id="transport-report.text.machines"
                values={{machines: machineWithPriceGroupLabels.join(", ")}}
              />
            ) : (
              <FormattedMessage
                defaultMessage="Maskiner: Alle"
                id="transport-report.text.all-machines"
              />
            )}
          </div>
          <div>
            {locationCustomerNames.length ? (
              <FormattedMessage
                defaultMessage="Afhentnings-/leveringskunder: {customers}"
                id="transport-report.text.location-customers"
                values={{customers: locationCustomerNames.join(", ")}}
              />
            ) : (
              <FormattedMessage
                defaultMessage="Afhentnings-/leveringskunder: Alle"
                id="transport-report.text.all-location-customers"
              />
            )}
          </div>
          <div>
            {invoiceCustomerNames.length ? (
              <FormattedMessage
                defaultMessage="Fakturakunder: {customers}"
                id="transport-report.text.invoice-customers"
                values={{customers: invoiceCustomerNames.join(", ")}}
              />
            ) : (
              <FormattedMessage
                defaultMessage="Fakturakunder: Alle"
                id="transport-report.text.all-invoice-customers"
              />
            )}
          </div>
        </>
      ) : null}
      <TransportReportTable data={data} showCustomerColumn={showCustomerColumn} />
    </div>
  );
}
