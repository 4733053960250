import {simpleArraysEqual} from "@co-common-libs/utils";
import * as React from "react";

const setsEqual = (set: ReadonlySet<any>, otherSet: ReadonlySet<any>): boolean => {
  if (set.size !== otherSet.size) {
    return false;
  }
  let result = true;
  set.forEach((value) => {
    if (!result) {
      return;
    }
    if (!otherSet.has(value)) {
      result = false;
    }
  });
  return result;
};

const mapsEqual = (map: ReadonlyMap<any, any>, otherMap: ReadonlyMap<any, any>): boolean => {
  if (map.size !== otherMap.size) {
    return false;
  }
  let result = true;
  map.forEach((value, key) => {
    if (!result) {
      return;
    }
    if (otherMap.get(key) !== value || (value === undefined && !otherMap.has(key))) {
      result = false;
    }
  });
  return result;
};

const equal = (obj: any, otherObj: any): boolean => {
  if (obj === otherObj) {
    return true;
  }
  if (
    typeof obj !== "object" ||
    obj === null ||
    typeof otherObj !== "object" ||
    otherObj === null
  ) {
    return false;
  }
  const keys = Object.keys(obj);
  const otherKeys = Object.keys(otherObj);
  if (keys.length !== otherKeys.length) {
    return false;
  }
  keys.sort();
  otherKeys.sort();
  return keys.every((key, index) => {
    if (otherKeys[index] !== key) {
      return false;
    }
    const instance = obj[key];
    const otherInstance = otherObj[key];
    if (instance === otherInstance) {
      return true;
    }
    if (Array.isArray(instance)) {
      return Array.isArray(otherInstance) && simpleArraysEqual(instance, otherInstance);
    }
    if (instance instanceof Set) {
      return otherInstance instanceof Set && setsEqual(instance, otherInstance);
    }
    if (instance instanceof Map) {
      return otherInstance instanceof Map && mapsEqual(instance, otherInstance);
    }
    if (instance instanceof Date) {
      return otherInstance instanceof Date && instance.valueOf() === otherInstance.valueOf();
    }
    return false;
  });
};

export class PureComponent<P, S = object> extends React.Component<P, S> {
  shouldComponentUpdate(nextProps: P, nextState: S, nextContext: unknown): boolean {
    return (
      !equal(this.props, nextProps) ||
      !equal(this.state, nextState) ||
      (!!nextContext && !equal(this.context, nextContext))
    );
  }
}
