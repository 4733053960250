import {WorkType, emptyWorkType} from "@co-common-libs/resources";
import {instanceURL} from "frontend-global-config";
import {v4 as uuid} from "uuid";

export function createWorkType(instance: Partial<Omit<WorkType, "id" | "url">>): WorkType {
  const id = uuid();
  const url = instanceURL("workType", id);

  return {
    id,
    url,
    ...emptyWorkType,
    ...instance,
  };
}
